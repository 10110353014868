import {Card, FormControl, FormField} from 'platform/components';
import {Grid} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {AccountingDocumentFormValues} from '../types';

interface PaymentsNotesProps {
  control: FormControl<AccountingDocumentFormValues>;
}

export function PaymentsNotesDetail(props: PaymentsNotesProps) {
  return (
    <Card>
      <Grid columns={2}>
        <FormField
          control={props.control}
          name="footerNote"
          minRows={3}
          type="textarea"
          isDisabled
          label={i18n.t('entity.invoice.labels.textBehindItems')}
        />
        <FormField
          control={props.control}
          minRows={3}
          name="internalNote"
          type="textarea"
          isDisabled
          label={i18n.t('entity.invoice.labels.internalNote')}
        />
      </Grid>
    </Card>
  );
}
