import {useEffect} from 'react';

import i18n from '@omnetic-dms/i18n';
import {
  getTopMakes,
  getCustomTenantCatalogue,
  getVehicleMakes,
  useThunkDispatch,
} from '@omnetic-dms/teas';

import {useBoolean} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {TopMakes} from './components/TopMakes';

export function VehicleTopMakes() {
  const dispatch = useThunkDispatch();

  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  useEffect(() => {
    startLoading();
    Promise.all([
      dispatch(getCustomTenantCatalogue.action({})),
      dispatch(getVehicleMakes.action({vehicleType: 'VEHICLETYPE_PASSENGER_CAR'})),
      dispatch(getTopMakes.action({})),
    ]).finally(() => {
      stopLoading();
    });
  }, [dispatch, startLoading, stopLoading]);

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{title: i18n.t('page.salesSettings.labels.topMakesTitle')}}
      description={i18n.t('page.salesSettings.labels.topMakesDescription')}
      data-testid="integrations-top-makes-settings-modal"
    >
      <TopMakes />
    </SettingsTemplate>
  );
}
