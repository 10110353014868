import {Integer, Box, Show, Space, VStack} from 'platform/foundation';

import {RequiredTestIdProps, AllOrNone, suffixTestId} from 'shared';

import {Search} from '../Search/Search';
import {MenuGroup} from './components/MenuGroup';
import {MenuItem} from './components/MenuItem';
import {useActiveItems} from './hooks/useActiveItems';
import {useSearch} from './hooks/useSearch';
import {MenuItem as MenuItemType} from './types';
import {isGroup, isItem} from './utils';

export type MenuProps = {
  items: MenuItemType[];
  activeItemId?: string;
  isDark?: boolean;
  isSearchable?: boolean;
  onItemChange: (newActiveItemId: string) => void;
  areGroupsSelectable?: boolean;
  isDefaultOpen?: boolean;
  shouldTruncateLongText?: boolean;
} & AllOrNone<{backLinkLabel: string; onBackLinkClick: () => void}> &
  RequiredTestIdProps;

export function Menu(props: MenuProps) {
  const activeItemsIds = useActiveItems(props.items, props.activeItemId);
  const {searchQuery, setSearchQuery, matchingItemsIds} = useSearch(props.items);

  const renderItems = (items: MenuItemType[] | undefined, depth: Integer = 0) => {
    if (!items) {
      return null;
    }

    return (
      <VStack spacing={1}>
        {items.map((item) => (
          <Show when={!searchQuery || matchingItemsIds.includes(item.id)} key={item.id}>
            {isItem(item) && (
              <MenuItem
                id={item.id}
                label={item.label}
                hasSeparator={item.hasSeparator}
                onClick={() => props.onItemChange(item.id)}
                isDisabled={item.isDisabled}
                leftIcon={item.leftIcon}
                rightIcon={item.rightIcon}
                actions={item.actions}
                isDark={props.isDark}
                isActive={activeItemsIds.includes(item.id)}
                depth={depth}
                badge={item.badge}
                tooltip={item.tooltip}
                shouldTruncateLongText={props.shouldTruncateLongText}
                data-testid={suffixTestId('menuItem', {'data-testid': item['data-testid']})}
              />
            )}

            {isGroup(item) && (
              <MenuGroup
                id={item.id}
                label={item.label}
                hasSeparator={item.hasSeparator}
                leftIcon={item.leftIcon}
                isActive={activeItemsIds.includes(item.id) || Boolean(searchQuery)}
                isDark={props.isDark}
                depth={depth}
                badge={item.badge}
                items={renderItems(item.items, (depth + 1) as Integer)}
                actions={item.actions}
                onClick={props.areGroupsSelectable ? () => props.onItemChange(item.id) : undefined}
                isSelected={props.areGroupsSelectable && props.activeItemId === item.id}
                tooltip={item.tooltip}
                isDefaultOpen={props.isDefaultOpen}
                shouldTruncateLongText={props.shouldTruncateLongText}
                data-testid={suffixTestId('menuGroup', {'data-testid': item['data-testid']})}
              />
            )}
          </Show>
        ))}
      </VStack>
    );
  };

  return (
    <Box role="navigation" flex={1} data-testid={suffixTestId('menu', props)}>
      <Show when={props.backLinkLabel !== undefined || props.isSearchable}>
        <VStack spacing={4}>
          {props.backLinkLabel !== undefined && (
            <MenuItem
              id="backlink"
              label={props.backLinkLabel}
              onClick={props.onBackLinkClick}
              isDark={props.isDark}
              leftIcon="navigation/arrow_back"
              shouldTruncateLongText={props.shouldTruncateLongText}
              data-testid={suffixTestId('menuBacklink', props)}
            />
          )}

          <Show when={props.isSearchable}>
            <Search
              value={searchQuery}
              onChange={setSearchQuery}
              data-testid={suffixTestId('menuSearch', props)}
            />
          </Show>
        </VStack>
        <Space vertical={6} />
      </Show>

      {renderItems(props.items)}
    </Box>
  );
}
