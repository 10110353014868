import styled from 'styled-components';

import {Popper} from './Popper';

export const ContextMenuWrapper = styled.div`
  display: block;
`;

export const StyledPopper = styled(Popper)`
  z-index: ${({theme}) => theme.zIndices.MODAL_COMPONENT};
`;

export const OptionsWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  border: 1px solid ${({theme}) => theme.colors.palettes.neutral[700][100]};
  margin: -1px 0;
  border-radius: ${({theme}) => theme.radii.small};
  overflow: hidden;
  color: ${({theme}) => theme.colors.palettes.white[10][100]};
  box-shadow: 2px 2px 8px rgb(0 0 0 / 35%);
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const IconWrapper = styled.div`
  display: flex;
  padding-right: 8px;
  font-size: ${({theme}) => theme.fontSizes.text.base};
`;
