import type {CancelablePromise} from '../types/CancelablePromise';
import type {CreateCommentRequestBody} from '../types/CreateCommentRequestBody';
import type {GetComment} from '../types/GetComment';
import type {UpdateCommentRequestBody} from '../types/UpdateCommentRequestBody';
import {request as __request} from '../utils/request';

export class CommentService {
  /**
   * @returns GetComment
   * @throws ApiError
   */
  public static createComment({
    externalId,
    xBranch,
    authorization,
    requestBody,
  }: {
    externalId: string;
    xBranch: string;
    authorization?: string;
    requestBody?: CreateCommentRequestBody;
  }): CancelablePromise<GetComment> {
    return __request({
      method: 'POST',
      url: '/dms/v1/sourcing/comment/{externalId}',
      path: {
        externalId,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns GetComment
   * @throws ApiError
   */
  public static getComments({
    externalId,
    xBranch,
    authorization,
  }: {
    externalId: string;
    xBranch: string;
    authorization?: string;
  }): CancelablePromise<Array<GetComment>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sourcing/comment/{externalId}',
      path: {
        externalId,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      shouldClearCacheEntry: true,
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static deleteComment({
    externalId,
    commentUuid,
    xBranch,
    authorization,
  }: {
    externalId: string;
    commentUuid: string;
    xBranch: string;
    authorization?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'DELETE',
      url: '/dms/v1/sourcing/comment/{externalId}/{commentUuid}',
      path: {
        externalId,
        commentUuid,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
    });
  }

  /**
   * @returns GetComment
   * @throws ApiError
   */
  public static updateComment({
    externalId,
    commentUuid,
    xBranch,
    authorization,
    requestBody,
  }: {
    externalId: string;
    commentUuid: string;
    xBranch: string;
    authorization?: string;
    requestBody?: UpdateCommentRequestBody;
  }): CancelablePromise<GetComment> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/sourcing/comment/{externalId}/{commentUuid}',
      path: {
        externalId,
        commentUuid,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
