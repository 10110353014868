import {match} from 'ts-pattern';
import {Nullish} from 'utility-types';

import {isNil} from 'ramda';

import {formatPercentage} from './formatPercentage';

export function formatFuelTank(value: number | Nullish) {
  if (isNil(value)) {
    return;
  }

  const roundedValue = Math.round(value / 0.25) * 0.25;

  return match(roundedValue)
    .with(0, () => 'E')
    .with(0.25, () => '1/4')
    .with(0.5, () => '1/2')
    .with(0.75, () => '3/4')
    .with(1, () => 'F')
    .otherwise(formatPercentage);
}
