import {Card, Separator} from 'platform/components';
import {Box, Image, Text, Link, VStack} from 'platform/foundation';

import {FC} from 'react';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {KonzultaCredentialsAuth} from './KonzultaCredentialsAuth';

export const GatewayKonzulta: FC = () => (
  <VStack spacing={4}>
    <Card title={i18n.t('page.integrations.labels.serviceTermsAndConditions')}>
      {i18n.t('page.integrations.labels.serviceTermsAndConditionsContent')}
      <Separator />
      <Box paddingBottom={4}>
        <Text size="xSmall" color="secondary">
          {i18n.t('page.integrations.labels.termOfService')}
        </Text>
        <Text size="large" alternative>
          {i18n.t('page.integrations.labels.unlimitedUse')}
        </Text>
      </Box>
      <Box paddingBottom={4}>
        <Text size="xSmall" color="secondary">
          {i18n.t('general.labels.price')}
        </Text>
        <Text size="large" alternative>
          {i18n.t('general.labels.individual')}
        </Text>
      </Box>
    </Card>

    <Card title={i18n.t('page.integrations.labels.provider')}>
      <Box paddingBottom={4}>
        <Image
          src="assets/images/sms-gateway-konzulta-brno.png"
          alt="Konzulta Brno"
          maxWidth={50}
        />
      </Box>

      <Box paddingBottom={4}>
        <Text alternative>Konzulta Brno, a.s.</Text>
        <Text>Veveří 9</Text>
        <Text>602 00 Brno, Česká republika</Text>
      </Box>

      <Box paddingBottom={4}>
        <Text>{i18n.t('contractInformation.labels.registrationNumber')}: 1827763627</Text>
        <Text>{i18n.t('contractInformation.labels.vatNumber')}: CZ182837383</Text>
        <Text>{i18n.t('general.labels.bankAccount')}: 100-873637373/0300</Text>
      </Box>

      <Box paddingBottom={4}>
        <Text>
          <Link href="tel:+420720534335" title="+420 720 534 335" />
        </Text>
        <Text>
          <Link
            data-testid={testIds.settings.smsGateways('konzultaEmailLink')}
            href="mailto: konzulta@konzulta.cz"
            title="konzulta@konzulta.cz"
          />
        </Text>
        <Text>
          <Link
            data-testid={testIds.settings.smsGateways('konzultaWebLink')}
            href="http://www.konzulta.cz"
            title="www.konzulta.cz"
            target="_blank"
          />
        </Text>
      </Box>
    </Card>

    <KonzultaCredentialsAuth />
  </VStack>
);
