import {
  PostOrderActionApiResponse,
  PostOrderActionApiArg,
  GetOrderActionsApiResponse,
  GetOrderActionsApiArg,
  PostOrderActionApiResponseSchema,
  PostOrderActionApiArgSchema,
  GetOrderActionsApiResponseSchema,
  GetOrderActionsApiArgSchema,
} from '../types/metadaWorkshopServiceOrderActions';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceOrderActionsApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postOrderAction: build.mutation<PostOrderActionApiResponse, PostOrderActionApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/action/${queryArg.actionKey}`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        {type: 'orderActions', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'orderCustomerContract', id: queryArg.serviceOrderId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PostOrderActionApiResponseSchema,
        requestSchema: PostOrderActionApiArgSchema,
      },
    }),
    getOrderActions: build.query<GetOrderActionsApiResponse, GetOrderActionsApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/actions`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'orderActions', id: queryArg.serviceOrderId},
        {type: 'orderActions', id: 'ALL'},
      ],
      extraOptions: {
        responseSchema: GetOrderActionsApiResponseSchema,
        requestSchema: GetOrderActionsApiArgSchema,
      },
    }),
  }),
});

export const {usePostOrderActionMutation, useGetOrderActionsQuery} =
  metadaWorkshopServiceOrderActionsApi;
