import {omit} from 'ramda';

import {TestIdProps} from 'shared';

import {Chips, ChipsProps} from '../../Chip/Chips';

export interface ChipsActionProps extends Omit<ChipsProps, 'type'>, TestIdProps {
  type: 'chips';
}

export function ChipsAction(props: ChipsActionProps) {
  const chipProps = withoutType(props);
  return <Chips {...chipProps} data-testid={props['data-testid']} />;
}

const withoutType = omit(['type']);
