import {$generateHtmlFromNodes} from '@lexical/html';
import {
  AutoLinkPlugin,
  createLinkMatcherWithRegExp,
  LinkMatcher,
} from '@lexical/react/LexicalAutoLinkPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {EditorRefPlugin} from '@lexical/react/LexicalEditorRefPlugin';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {$getRoot, $getSelection, $setSelection, EditorState, LexicalEditor} from 'lexical';
import {$isBeautifulMentionNode, BeautifulMentionsPlugin} from 'lexical-beautiful-mentions';
import {Box, Show, Spinner} from 'platform/foundation';

import {useEffect, useRef} from 'react';

import {isNotNil, not} from 'ramda';

import {suffixTestId} from 'shared';

import {Dropdown} from '../Dropdown/Dropdown';
import {HelperText} from '../HelperText/HelperText';
import {Label} from '../Label/Label';
import {CustomMenuItem} from './components/CustomMenuItem';
import {Placeholder} from './components/Placeholder';
import {StyledContentEditable} from './components/StyledContentEditable';
import {NoParagraphPlugin} from './plugins/NoParagraphPlugin';
import {OpenLinkPlugin} from './plugins/OpenLinkPlugin';
import {RawPastePlugin} from './plugins/RawPastePlugin';
import {SuggestionInputProps} from './types/SuggestionInputProps';
import {getEditorConfig} from './utils/getEditorConfig';
import {EMAIL_REGEX, URL_REGEX} from './utils/getFormattedValue';

const MATCHERS: Array<LinkMatcher> = [
  createLinkMatcherWithRegExp(URL_REGEX, (text) =>
    text.startsWith('http') ? text : `https://${text}`
  ),
  createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => `mailto:${text}`),
];

export function SuggestionInput(props: SuggestionInputProps) {
  const editor = useRef<LexicalEditor>(null);
  const lastState = useRef<string>();

  const ContentEditable = props.ContentEditableComponent ?? StyledContentEditable;
  const MenuItem = props.MenuItemComponent ?? CustomMenuItem;

  const onChange = (state: EditorState, editor: LexicalEditor) => {
    state.read(() => {
      lastState.current = $generateHtmlFromNodes(editor);
      props.onChange?.(lastState.current);
    });
  };

  useEffect(() => {
    if (props.value === lastState.current) {
      return;
    }

    editor.current?.update(() => {
      $getRoot().clear();
      $getRoot().select();
      const selection = $getSelection();
      selection?.insertNodes(
        props
          .transformStringToNodes(props.value)
          .filter((node) => (props.isLoadingOptions ? not($isBeautifulMentionNode(node)) : true))
      );
      $setSelection(null);
    });
  }, [props.value, props.isLoadingOptions]);

  return (
    <div>
      <Label
        isRequired={props.isRequired}
        tooltip={props.tooltip}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <Box position="relative">
        <Show when={props.isLoadingOptions}>
          <Spinner variant="overlay" />
        </Show>
        <LexicalComposer initialConfig={getEditorConfig(props)}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                $isDisabled={props.isDisabled}
                $isInvalid={props.isInvalid || isNotNil(props.errorMessage)}
                data-testid={props['data-testid']}
              />
            }
            placeholder={
              <Show when={props.placeholder}>
                <Placeholder>{props.placeholder}</Placeholder>
              </Show>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <EditorRefPlugin editorRef={editor} />
          <LinkPlugin />
          <RawPastePlugin />
          <NoParagraphPlugin />
          <OpenLinkPlugin />
          <OnChangePlugin onChange={onChange} />
          <BeautifulMentionsPlugin
            items={{
              [props.prefix]: props.options,
            }}
            menuItemComponent={MenuItem}
            menuComponent={({children}) => {
              return (
                <Dropdown
                  isOpen={true}
                  dropdownControl={<div />}
                  data-testid={suffixTestId('dropdown', props)}
                >
                  <ul>{children}</ul>
                </Dropdown>
              );
            }}
            showMentionsOnDelete
          />

          <AutoLinkPlugin matchers={MATCHERS} />
        </LexicalComposer>
      </Box>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </div>
  );
}
