import {
  PersonRequestBody,
  PhoneNumberData,
  PersonResponseBodyV2,
  PhoneNumber,
} from '@omnetic-dms/api';

import {emptyPersonData, emptyEmail, emptyIdentityCard} from '../constants/emptyValues';

export const getContactPersonDefaultValues = (
  tenantPhoneInfo?: PhoneNumber,
  contact?: PersonResponseBodyV2
): PersonRequestBody => {
  const identityCards = contact?.identityCards?.map((i) => ({
    id: i.id,
    cardData: i,
  }));

  return {
    personData: contact ?? emptyPersonData,
    phoneNumbers: contact?.phoneNumbers?.length
      ? contact?.phoneNumbers.map((n) => ({
          type: n.type,
          phoneNumber: {countryCode: n.countryCode, prefix: n.prefix, number: n.number},
        }))
      : ([
          {
            type: null,
            phoneNumber: {
              prefix: tenantPhoneInfo?.prefix,
              number: '',
              countryCode: tenantPhoneInfo?.countryCode,
            },
          },
        ] as PhoneNumberData[]),
    emails: contact?.emails?.length ? contact?.emails : [emptyEmail],
    identityCards: identityCards?.length ? identityCards : [emptyIdentityCard],
    permanentAddressId: contact?.permanentAddress?.id ?? null,
  };
};
