import {DataStatus} from 'platform/components';

import {FC, PropsWithChildren, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {any, flatten, head, isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {Main} from '@omnetic-dms/shared';
import {
  loadVehicleDetailsData,
  useThunkDispatch,
  getCondition,
  getComplaintInspections,
  getValidationInspections,
  getNonValidationInspections,
  getAllAuditStructureTemplates,
  InspectionType,
  selectCarAudit,
  getCheckinInspections,
  getHandoverInspections,
  getAuditStructure,
  fetchAssignmentsRequest,
  selectStructures,
} from '@omnetic-dms/teas';

import {useFetchAuditStructure} from '../../hooks/useFetchAuditStructure';
import {Photos} from './components/Photos';

export const VehiclePhotos: FC<PropsWithChildren<any>> = () => {
  const dispatch = useThunkDispatch();

  const {id: vehicleId, tab} = useParams();

  const {fetchAuditStructure} = useFetchAuditStructure();
  const {loading} = useSelector(selectCarAudit);
  const structures = useSelector(selectStructures);

  const isAuditLoading = any(isTrue, [
    loading.getCondition,
    loading.getAuditStructure,
    loading.getComplaintInspections,
    loading.getValidationInspections,
    loading.getNonValidationInspections,
    loading.getCheckinInspections,
    loading.getHandoverInspections,
    loading.getAllAuditStructureTemplates,
    loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
    loading['sales/vehicleDetail/loadPremiumFeatures'],
    loading['vehicleCatalogue/getCustomTenantCatalogue'],
  ]);

  useEffect(() => {
    if (isNotNil(vehicleId) && !isAuditLoading) {
      loadVehicleDetailsData(vehicleId, dispatch).then(() => {
        if (tab === 'condition') {
          dispatch(
            getCondition.action({
              requestBody: {vehicleIds: [vehicleId]},
              inspectionType: InspectionType.vehicleCondition,
            })
          )
            .unwrap()
            .then((response) => {
              if (isNotNil(response)) {
                fetchAuditStructure(head(response));
              }
            });
        }

        if (tab === 'inspections') {
          Promise.all([
            dispatch(
              getComplaintInspections.action({
                requestBody: {vehicleIds: [vehicleId]},
                inspectionType: InspectionType.complaint,
              })
            ).unwrap(),
            dispatch(
              getValidationInspections.action({
                requestBody: {vehicleIds: [vehicleId]},
                inspectionType: InspectionType.validationInspection,
              })
            ).unwrap(),
            dispatch(
              getNonValidationInspections.action({
                requestBody: {vehicleIds: [vehicleId]},
                inspectionType: InspectionType.nonValidationInspection,
              })
            ).unwrap(),
            dispatch(
              getCheckinInspections.action({
                requestBody: {vehicleIds: [vehicleId]},
                inspectionType: InspectionType.checkin,
              })
            ).unwrap(),
            dispatch(
              getHandoverInspections.action({
                requestBody: {vehicleIds: [vehicleId]},
                inspectionType: InspectionType.handover,
              })
            ).unwrap(),
          ]).then((response) => {
            const inspections = flatten(response ?? []).sort((a, b) =>
              (a?.updateAt ?? 0) < (b?.updateAt ?? 0) ? 1 : -1
            );
            inspections.forEach((inspection) => {
              if (!structures?.[inspection.id]) {
                dispatch(
                  getAuditStructure.action({
                    auditStructureId: inspection.controlStructureId,
                  })
                )
                  .unwrap()
                  .then(() =>
                    dispatch(
                      fetchAssignmentsRequest({
                        assignmentIdentityType: 'vehicle_audit',
                        assignmentIdentityId: inspection.id,
                      })
                    )
                  );
              }
            });

            dispatch(getAllAuditStructureTemplates.action({}));
          });
        }
      });
    }
  }, [tab, vehicleId]);

  return (
    <Main>
      <DataStatus isLoading={isAuditLoading} minHeight={100}>
        <Photos />
      </DataStatus>
    </Main>
  );
};
