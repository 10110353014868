import {Hide, VStack} from 'platform/foundation';

import {useGetBusinessCaseQuery} from '@omnetic-dms/api';

import {useRequiredParams} from 'shared';

import {Upsell} from '../BusinessCaseSaleVehicleUpsell/Upsell';
import {BusinessCaseSaleVehicleDiscountForm} from './BusinessCaseSaleVehicleDiscountForm';

export function BusinessCaseSaleVehicleDiscount() {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});

  return (
    <VStack spacing={4}>
      <Hide when={businessCase?.brokerageBusinessCaseId}>
        <BusinessCaseSaleVehicleDiscountForm />
      </Hide>
      <Upsell />
    </VStack>
  );
}
