import {filter, length} from 'ramda';
import {isPositive} from 'ramda-adjunct';

import {DashboardLayout, DashboardBlock} from '../types/layoutBlocks';

export function splitBlocksToCols(blocks: DashboardBlock[]): DashboardLayout {
  const blocksCount = length(blocks);

  let leftBlocks = filter((block) => block.priority === 'left', blocks);
  let rightBlocks = filter((block) => block.priority === 'right', blocks);

  const leftCount = length(leftBlocks);
  const rightCount = length(rightBlocks);

  if (leftCount === 0 && blocksCount < 3) {
    leftBlocks = isPositive(rightCount) ? [rightBlocks.shift()!] : [];
  }

  if (rightCount === 0 && blocksCount < 3) {
    rightBlocks = isPositive(leftCount) ? [leftBlocks.pop()!] : [];
  }

  return {
    left: leftBlocks,
    right: rightBlocks,
  };
}
