import * as Yup from 'yup';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {
  ContactInfoFieldsFormType,
  ContactInfoFormType,
  PhoneNumberType,
  SelfEmployedFieldsFormType,
} from '../types';

const phoneNumberSchema = (): Yup.SchemaOf<PhoneNumberType> =>
  Yup.object().shape({
    countryCode: Yup.string().nullable().required(i18n.t('general.validations.fieldIsRequired')),
    number: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
    prefix: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
  });

const selfEmployedFormSchema = (): Yup.SchemaOf<
  SelfEmployedFieldsFormType['selfEmployedInformation']
> =>
  // eslint-disable-next-line no-restricted-syntax
  Yup.object().shape({
    titleBefore: Yup.string().nullable(),
    firstName: Yup.string().nullable().required(i18n.t('general.validations.fieldIsRequired')),
    lastName: Yup.string().nullable().required(i18n.t('general.validations.fieldIsRequired')),
    titleAfter: Yup.string().nullable(),
    emailAddress: Yup.string().nullable().email(i18n.t('general.validations.invalidEmail')),
    phoneNumber: Yup.lazy((value) => {
      if (isNil(value)) {
        return Yup.mixed().notRequired();
      }

      const res = Object.values(value).some((val) => {
        if (isNil(val)) {
          return false;
        } else if (val === '') {
          return false;
        } else {
          return true;
        }
      });

      if (!res) {
        return Yup.mixed().notRequired();
      } else {
        return phoneNumberSchema();
      }
    }),
  }) as unknown as Yup.SchemaOf<SelfEmployedFieldsFormType['selfEmployedInformation']>; // TODO: validate

const contactInfoAddressSchema = (): Yup.SchemaOf<ContactInfoFieldsFormType['address']> =>
  Yup.object().shape({
    city: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
    country: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
    state: Yup.string().ensure(),
    street: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
    zipCode: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
  });

export const contactInformationSchema = (): Yup.SchemaOf<ContactInfoFieldsFormType> =>
  Yup.object().shape({
    registrationNumber: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
    companyName: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
    fileNumber: Yup.string().ensure(),
    vatNumber: Yup.string().defined().nullable(),
    address: contactInfoAddressSchema().nullable(),
  });

export const contactInformationValidations = (): Yup.SchemaOf<
  ContactInfoFormType & {type: string}
> =>
  // eslint-disable-next-line no-restricted-syntax
  Yup.object()
    .shape({
      type: Yup.string().required(),
      selfEmployedInformation: Yup.object().when('type', {
        is: 'selfEmployed',
        then: selfEmployedFormSchema(),
        otherwise: Yup.object().nullable(),
      }),
    })
    .concat(contactInformationSchema()) as unknown as Yup.SchemaOf<
    ContactInfoFormType & {type: string}
  >;
