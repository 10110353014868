import {match} from 'ts-pattern';

import {always} from 'ramda';

import {Nullish} from 'shared';

type PaymentMethod = string | number | string[] | Nullish;

export const getDefaultCreateReceiptByPaymentMethod = (paymentMethod: PaymentMethod) =>
  match(paymentMethod)
    .with('BANK_TRANSFER', 'PAYMENT_CARD', always(false))
    .with('CASH', always(true))
    .otherwise(always(false));
