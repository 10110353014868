import {VStack} from 'platform/foundation';

import {FC} from 'react';

import {HeaderSection} from './HeaderSection';
import {ValiditySection} from './ValiditySection';

export const DocumentRoundingDefinitionForm: FC = () => (
  <VStack spacing={4}>
    <HeaderSection />
    <ValiditySection />
  </VStack>
);
