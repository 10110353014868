import {IFilter, IFilterParams} from '@ag-grid-community/core';
import {
  Chips,
  DatePicker,
  NumberInput,
  Separator,
  useTranslationContext,
} from 'platform/components';
import {HStack, Space, VStack} from 'platform/foundation';

import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {intersection, isNil} from 'ramda';
import {isString} from 'ramda-adjunct';

import {getApiDateString, suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {QuickFilters} from '../../types/Api';
import {parseDateFilter} from '../../utils/parseDateFilter';

type DateRangeType = {from: Date | null; to: Date | null};
type DateRangeTypeString = {from: string | null; to: string | null};
type RelativeInputType = {relativeInputValue: number; relativeInputKey: string};

export type DateRangeFilterProps = {
  min: string | null;
  max: string | null;
  relativeDates: Array<{value: string; label: string}>;
  relativeInputs: Array<{value: string; label: string}>;
  isDisabled: boolean;
} & IFilterParams &
  QuickFilters &
  TestIdProps;

function DateRangeFilterComponent(
  {
    filterChangedCallback,
    min,
    max,
    relativeDates,
    isDisabled,
    relativeInputs = [],
    quickFilters,
    ...props
  }: DateRangeFilterProps,
  ref: ForwardedRef<IFilter>
) {
  const t = useTranslationContext();
  const minDate = useMemo(() => parseDateFilter(min), [min]);
  const maxDate = useMemo(() => parseDateFilter(max), [max]);

  const isActive = useRef<boolean>(false);
  const [filterValue, _setFilterValue] = useState<
    RelativeInputType | DateRangeType | string | undefined
  >();
  const setFilterValue = useCallback(
    (value: RelativeInputType | DateRangeType | string | undefined) => {
      isActive.current = value !== undefined;
      _setFilterValue(value);
    },
    []
  );
  const {onChipsChange} = useFilterOnChipsChange({
    filterChangedCallback,
    setFilterValue,
    defaultValue: undefined,
  });

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return isActive.current;
    },

    doesFilterPass() {
      return true;
    },

    getModel() {
      if (!filterValue || typeof filterValue === 'string') {
        return filterValue;
      }

      if ('relativeInputKey' in filterValue) {
        return filterValue;
      } else {
        const from = filterValue.from ? getApiDateString(filterValue.from) : null;
        const to = filterValue.to ? getApiDateString(filterValue.to) : null;
        return {from, to};
      }
    },

    setModel(model: DateRangeTypeString | string | undefined) {
      if (isNil(model)) {
        setFilterValue(undefined);
      } else if (isString(model)) {
        setFilterValue(model);
      } else if ('relativeInputKey' in model) {
        //@ts-ignore
        setFilterValue(model);
      } else {
        setFilterValue({
          from: parseDateFilter(model.from) ?? null,
          to: parseDateFilter(model.to) ?? null,
        });
      }
      filterChangedCallback();
    },
  }));

  const [fromPickerValue, toPickerValue, chipsValue, inputValue] = useMemo(
    () => [
      typeof filterValue === 'object' && 'from' in filterValue ? filterValue.from : null,
      typeof filterValue === 'object' && 'to' in filterValue ? filterValue.to : null,
      typeof filterValue === 'string' ? [filterValue] : [],
      typeof filterValue === 'object' && 'relativeInputValue' in filterValue
        ? filterValue.relativeInputValue
        : 0,
    ],
    [filterValue]
  );

  const onDateFromChange = useCallback(
    (value: Date | null) => {
      setFilterValue({
        from: value,
        to: typeof filterValue === 'object' && 'to' in filterValue ? filterValue.to : null,
      });
      filterChangedCallback();
    },
    [filterValue]
  );
  const onDateToChange = useCallback(
    (value: Date | null) => {
      setFilterValue({
        to: value,
        from: typeof filterValue === 'object' && 'from' in filterValue ? filterValue.from : null,
      });
      filterChangedCallback();
    },
    [filterValue]
  );
  const onInputChange = useCallback((relativeInputValue: number, relativeInputKey: string) => {
    if (isNaN(relativeInputValue)) {
      setFilterValue(undefined);
    } else {
      setFilterValue({relativeInputValue, relativeInputKey});
    }
    filterChangedCallback();
  }, []);

  const isQuickFilterValue = intersection([filterValue], quickFiltersConst) as string[];

  return (
    <VStack spacing={2}>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('quickFilters-chips', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      {relativeDates && relativeDates.length > 0 && (
        <>
          <Chips
            isDisabled={isDisabled}
            value={chipsValue}
            options={relativeDates}
            onChange={onChipsChange}
            isMultiple={false}
            data-testid={suffixTestId('relativeDates-chips', props)}
            isDeselectable
          />
          <Separator />
        </>
      )}
      <DatePicker
        label={t('page.datagrid.filter.dateLabelFrom')}
        value={fromPickerValue}
        onChange={onDateFromChange}
        data-testid={suffixTestId('date-range-filter-from', props)}
      />
      <Space vertical={2} />
      <DatePicker
        label={t('page.datagrid.filter.dateLabelTo')}
        value={toPickerValue}
        onChange={onDateToChange}
        data-testid={suffixTestId('date-range-filter-to', props)}
      />
      {relativeInputs.map((relativeInput) => (
        <NumberInput
          key={relativeInput.value}
          label={relativeInput.label}
          isDisabled={isDisabled}
          onChange={(value) => onInputChange(value ?? 0, relativeInput.value)}
          value={inputValue}
          data-testid={suffixTestId(`relativeInput-${relativeInput.label}`, props)}
        />
      ))}
    </VStack>
  );
}

export const DateRangeFilter = forwardRef<IFilter, DateRangeFilterProps>(DateRangeFilterComponent);
