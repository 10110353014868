import {addMonths} from 'date-fns';
import {FormControl, FormField} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';

import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, getApiDateString, suffixTestId} from 'shared';

import {ServiceVehicleFormType} from '../../../types/ServiceVehicleFormType';

interface ReplacementFormProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
  groupName: 'engineOil' | 'gearboxOil' | 'fourByFourOilDistributor' | 'timingBelt';
}

export function ReplacementForm(props: ReplacementFormProps) {
  const {setValue} = props.formApi;

  const lastReplacementDate = props.formApi.watch(
    `serviceIntervals.${props.groupName}.lastReplacementDate`
  );

  const replacementAfterMonths = props.formApi.watch(
    `serviceIntervals.${props.groupName}.replacementAfterMonths`
  );

  const lastReplacementMileage = props.formApi.watch(
    `serviceIntervals.${props.groupName}.lastReplacementMileage`
  );

  const replacementAfterMileage = props.formApi.watch(
    `serviceIntervals.${props.groupName}.replacementAfterMileage`
  );

  useEffect(() => {
    if (!lastReplacementDate || !replacementAfterMonths) {
      return;
    }

    setValue(
      `serviceIntervals.${props.groupName}.nextReplacementDate`,
      getApiDateString(addMonths(lastReplacementDate, replacementAfterMonths))
    );
  }, [props.groupName, setValue, lastReplacementDate, replacementAfterMonths]);

  useEffect(() => {
    if (!lastReplacementMileage || !replacementAfterMileage) {
      return;
    }

    setValue(
      `serviceIntervals.${props.groupName}.nextReplacementMileage`,
      lastReplacementMileage + replacementAfterMileage
    );
  }, [props.groupName, setValue, lastReplacementMileage, replacementAfterMileage]);

  return (
    <>
      <Grid columns={4}>
        <GridItem>
          <FormField
            type="apiDate"
            label={i18n.t('entity.vehicle.labels.dateOfLastReplacement')}
            control={props.control}
            name={`serviceIntervals.${props.groupName}.lastReplacementDate`}
            data-testid={suffixTestId('lastReplacementDate', props)}
          />
        </GridItem>
        <GridItem>
          <FormField
            type="number"
            label={i18n.t('entity.vehicle.labels.mileageAtLastReplacement')}
            control={props.control}
            name={`serviceIntervals.${props.groupName}.lastReplacementMileage`}
            suffix={i18n.t('general.metric.km')}
            data-testid={suffixTestId('lastReplacementMileage', props)}
          />
        </GridItem>
        <GridItem>
          <FormField
            type="apiDate"
            label={i18n.t('entity.vehicle.labels.nextReplacementDate')}
            control={props.control}
            name={`serviceIntervals.${props.groupName}.nextReplacementDate`}
            data-testid={suffixTestId('nextReplacementDate', props)}
          />
        </GridItem>
        <GridItem>
          <FormField
            type="number"
            label={i18n.t('entity.vehicle.labels.nextReplacementMileage')}
            control={props.control}
            name={`serviceIntervals.${props.groupName}.nextReplacementMileage`}
            suffix={i18n.t('general.metric.km')}
            data-testid={suffixTestId('nextReplacementMileage', props)}
          />
        </GridItem>
      </Grid>
      <Grid columns={4}>
        <GridItem>
          <FormField
            type="number"
            label={i18n.t('entity.vehicle.labels.replacementAfterMonths')}
            control={props.control}
            name={`serviceIntervals.${props.groupName}.replacementAfterMonths`}
            suffix={i18n.t('general.metric.months')}
            data-testid={suffixTestId('replacementAfterMonths', props)}
          />
        </GridItem>
        <GridItem>
          <FormField
            type="number"
            label={i18n.t('entity.vehicle.labels.replacementAfterMileage')}
            control={props.control}
            name={`serviceIntervals.${props.groupName}.replacementAfterMileage`}
            suffix={i18n.t('general.metric.km')}
            data-testid={suffixTestId('replacementAfterMileage', props)}
          />
        </GridItem>
      </Grid>
    </>
  );
}
