import type {CancelablePromise} from '../types/CancelablePromise';
import type {PatchTemplateRequestBody} from '../types/PatchTemplateRequestBody';
import type {TemplateV2} from '../types/TemplateV2';
import {request as __request} from '../utils/request';

export class PatchTemplateService {
  /**
   * @returns TemplateV2
   * @throws ApiError
   */
  public static patchTemplate({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: PatchTemplateRequestBody;
  }): CancelablePromise<TemplateV2> {
    return __request({
      method: 'PATCH',
      url: '/dms/v2/document/template',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
