import {Card, DataStatus} from 'platform/components';
import {Box, Grid, GridItem, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {VehicleTypeEnumObject} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {FeatureSelector} from '../../../FeatureSelector/FeatureSelector';
import {ServiceVehicleFormType} from '../../types/ServiceVehicleFormType';
import {CebiaWidget} from './components/CebiaWidget';
import {FeatureMarketAnalysis} from './components/FeatureMarketAnalysis';

interface FeaturesProps extends RequiredTestIdProps {
  formApi: UseFormReturn<ServiceVehicleFormType>;
  isLoading: boolean;
}

export function Features(props: FeaturesProps) {
  const vehicleType = props.formApi.watch('type');

  return (
    <Box padding={4}>
      <Grid columns={3}>
        <GridItem span={2}>
          <Card title={i18n.t('general.labels.allFeatures')} isExpandable>
            <DataStatus isLoading={props.isLoading}>
              <FeatureSelector
                formApi={props.formApi}
                name="features"
                vehicleType={vehicleType.at(0) as VehicleTypeEnumObject}
                columns={3}
                data-testid={suffixTestId('featureSelector', props)}
              />
            </DataStatus>
          </Card>
        </GridItem>
        <GridItem>
          <VStack spacing={4}>
            <CebiaWidget
              isLoading={props.isLoading}
              formApi={props.formApi}
              data-testid={suffixTestId('cebiaWidget', props)}
            />
            <FeatureMarketAnalysis
              isLoading={props.isLoading}
              formApi={props.formApi}
              data-testid={suffixTestId('featureMarketAnalysis', props)}
            />
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
}
