import {Card, FormControl, FormField} from 'platform/components';
import {Grid, Hide, Show, VStack} from 'platform/foundation';

import {UseFormReturn, useWatch} from 'react-hook-form';

import {VehicleTypeEnumObject} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, handleFormReset, RequiredTestIdProps} from 'shared';

import {useVehicleCatalogue} from '../../../../../hooks/useVehicleCatalogue';
import {SecondaryFuelTypeField} from '../../../../SecondaryFuelTypeField/SecondaryFuelTypeField';
import {hasSecondaryFuelType} from '../../../../SecondaryFuelTypeField/utils/hasSecondaryFuelType';
import {ServiceVehicleFormType} from '../../../types/ServiceVehicleFormType';
import {ElectricFuelFields} from './ElectricFuelFields';

export interface FuelFieldProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
  vehicleType: VehicleTypeEnumObject;
}

export function FuelField(props: FuelFieldProps) {
  const fuelType = useWatch({control: props.control, name: 'fuelType'})?.[0];

  const [vehicleUtils] = useVehicleCatalogue(props.vehicleType);

  const isElectricFuelType = fuelType === 'FUELTYPE_ELECTRIC';
  const isHydrogenFuelType = fuelType === 'FUELTYPE_HYDROGEN';
  const isHybridFuelType = fuelType === 'FUELTYPE_HYBRID';

  return (
    <VStack spacing={4}>
      <FormField
        control={props.control}
        type="chips"
        name="fuelType"
        options={vehicleUtils.fuelTypeOptions ?? []}
        label={i18n.t('entity.vehicle.labels.fuel')}
        data-testid={suffixTestId('fuelType', props)}
        onChange={() =>
          handleFormReset<ServiceVehicleFormType>(props.formApi, [
            {name: 'chargingAC', value: null},
            {name: 'chargingTimeAC', value: null},
            {name: 'chargingDC', value: null},
            {name: 'chargingTimeDC', value: null},
            {name: 'batteryType', value: null},
            {name: 'batteryCapacityWh', value: null},
            {name: 'batteryCapacityAh', value: null},
            {name: 'batteryVoltage', value: null},
            {name: 'batteryCount', value: null},
            {name: 'chargingConnectorType', value: null},
            {name: 'electricRange', value: null},
            {name: 'batteryMileageLimit', value: null},
            {name: 'batteryWarrantyUntil', value: null},
            {name: 'batteryOwnershipType', value: null},
            {name: 'secondaryFuelType', value: null},
            {name: 'hybridType', value: null},
            {name: 'gasRevisionValidUntil', value: null},
            {name: 'pressureVesselValidUntil', value: null},
          ])
        }
      />
      <Show when={isElectricFuelType}>
        <ElectricFuelFields
          control={props.control}
          formApi={props.formApi}
          vehicleType={props.vehicleType}
          data-testid={suffixTestId('electric', props)}
        />
      </Show>
      <Show when={hasSecondaryFuelType(fuelType)}>
        <SecondaryFuelTypeField<ServiceVehicleFormType>
          fuelType={fuelType}
          vehicleType={props.vehicleType}
          control={props.control}
          name="secondaryFuelType"
          label={i18n.t('entity.vehicle.labels.secondaryFuelType')}
          data-testid={props['data-testid']}
          isDeselectable
        />
        <Card variant="inlineGrey">
          {isHybridFuelType ? (
            <FormField
              control={props.control}
              type="chips"
              name="hybridType"
              options={vehicleUtils.hybridTypeOptions ?? []}
              label={i18n.t('general.labels.hybridType')}
              data-testid={suffixTestId('hybridType', props)}
              onChange={() =>
                handleFormReset<ServiceVehicleFormType>(props.formApi, [
                  {name: 'gasRevisionValidUntil', value: null},
                  {name: 'pressureVesselValidUntil', value: null},
                  {name: 'engineName', value: null},
                  {name: 'engineCode', value: null},
                  {name: 'power', value: null},
                  {name: 'engineVolume', value: null},
                ])
              }
            />
          ) : (
            <Grid columns={2} spacing={4}>
              <Hide when={isHydrogenFuelType}>
                <FormField
                  control={props.control}
                  type="apiDate"
                  name="gasRevisionValidUntil"
                  label={i18n.t('entity.vehicle.labels.gasRevisionValidUntil')}
                  data-testid={suffixTestId('gasRevisionValidUntil', props)}
                />
              </Hide>
              <FormField
                control={props.control}
                type="apiDate"
                name="pressureVesselValidUntil"
                label={i18n.t('entity.vehicle.labels.pressureVesselExpires')}
                data-testid={suffixTestId('pressureVesselValidUntil', props)}
              />
            </Grid>
          )}
        </Card>
      </Show>
    </VStack>
  );
}
