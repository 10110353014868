import {Box} from 'platform/foundation';

import {ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

export interface SettingsSectionProps extends TestIdProps {
  children: ReactNode;
}

export function SettingsSection(props: SettingsSectionProps) {
  return (
    <Box maxWidth={200} data-testid={suffixTestId('SettingsSection-wrapper', props)}>
      {props.children}
    </Box>
  );
}
