import {forwardRef, Ref} from 'react';

import {Box, useMultiStyleConfig} from '@chakra-ui/react';

import {ICellRendererParams} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {MasterDetailRenderer} from '../types/MasterDetailRenderer';

export interface DetailRendererWrapperProps extends ICellRendererParams {
  gridProps: DataGridProps;
  component: MasterDetailRenderer;
}

export const DetailRendererWrapper = forwardRef<HTMLDivElement, DetailRendererWrapperProps>(
  DetailRendererWrapperComponent
);
function DetailRendererWrapperComponent(
  {node, gridProps, context, component: MasterDetailComponent, pinned}: DetailRendererWrapperProps,
  ref: Ref<HTMLDivElement>
) {
  const {detailRenderer: theme} = useMultiStyleConfig('DataGrid', context.gridProps);
  const rowData = node.data;

  if (pinned) {
    return null;
  }

  return (
    <Box ref={ref} __css={theme}>
      <div>
        <MasterDetailComponent rowData={rowData} gridProps={gridProps} />
      </div>
    </Box>
  );
}
