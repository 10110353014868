import {Card, DataStatus, Table} from 'platform/components';
import {Box, Text} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {isEmptyArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {selectCarAudit, selectInspections} from '../../../store/carAudit/selectors';
import {Inspection} from './Inspection';

interface InspectionsProps extends TestIdProps {
  getRedirectRoute: (id: string, inspectionId: string) => string;
  openLinksInNewTab?: boolean;
}

export const Inspections = ({getRedirectRoute, openLinksInNewTab, ...rest}: InspectionsProps) => {
  const {loading} = useSelector(selectCarAudit);
  const inspections = useSelector(selectInspections);

  const tableColumns = [
    {
      element: (
        <Box paddingLeft={4} paddingVertical={2}>
          <Text
            size="small"
            align="left"
            alternative
            data-testid={suffixTestId('inspections-th-inspectionName', rest)}
          >
            {i18n.t('entity.condition.labels.inspectionName')}
          </Text>
        </Box>
      ),
    },
    {
      element: (
        <Text
          size="small"
          align="left"
          alternative
          data-testid={suffixTestId('inspections-th-status', rest)}
        >
          {i18n.t('general.labels.status')}
        </Text>
      ),
    },
    {
      element: (
        <Text
          size="small"
          align="left"
          alternative
          data-testid={suffixTestId('inspections-th-lastUpdated', rest)}
        >
          {i18n.t('general.labels.lastUpdated')}
        </Text>
      ),
    },
    {
      element: (
        <Text
          size="small"
          align="left"
          alternative
          data-testid={suffixTestId('inspections-th-author', rest)}
        >
          {i18n.t('general.labels.author')}
        </Text>
      ),
    },
    {
      element: (
        <Text
          size="small"
          align="left"
          alternative
          data-testid={suffixTestId('inspections-th-assignee', rest)}
        >
          {i18n.t('general.labels.assignee')}
        </Text>
      ),
    },
    {
      element: (
        <Text
          size="small"
          align="left"
          alternative
          data-testid={suffixTestId('inspections-th-rating', rest)}
        >
          {i18n.t('general.labels.rating')}
        </Text>
      ),
    },
  ];

  return (
    <DataStatus
      isLoading={loading.getAudits}
      isEmpty={isEmptyArray(inspections)}
      data-testid={suffixTestId('inspections', rest)}
      emptyMessage={i18n.t('entity.condition.notifications.no.inspections')}
    >
      <Card>
        <Table columns={tableColumns} data-testid={suffixTestId('inspections', rest)}>
          {inspections.map((auditData, index) => (
            <Inspection
              key={`${auditData.controlStructureId}-${index}`}
              data-testid={suffixTestId(`inspections-[${index}]`, rest)}
              getRedirectRoute={getRedirectRoute}
              openLinksInNewTab={openLinksInNewTab}
              auditData={auditData}
            />
          ))}
        </Table>
      </Card>
    </DataStatus>
  );
};
