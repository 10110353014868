export enum NotificationCategory {
  CUSTOM = 'CUSTOM',
  CHECKIN = 'CHECKIN',
  HANDOVER = 'HANDOVER',
}

export interface FormDocument {
  rowId: string;
  file: FormDocumentFile;
  uuid: string;
}

export interface FormDocumentFile {
  value: FormDocumentFileDetail;
  style: never[];
}

export interface FormDocumentFileDetail {
  id: string;
  title: string;
  contentType: string;
  remoteUrl: string;
}

export interface EmailSmsNotificationForm {
  category: NotificationCategory[];
  email: EmailState;
  sms: SmsState;
}

export interface EmailState {
  isEnabled: boolean;
  data: EmailData;
}

export interface EmailData {
  person: string;
  email: string;
  subject: string;
  message: string;
  documents: FormDocument[];
}

export interface SmsState {
  isEnabled: boolean;
  data: SmsData;
}

export interface SmsData {
  person: string;
  phone: string;
  message: string;
}

export type ServiceCaseState =
  | 'CANCELLED'
  | 'PLANNED'
  | 'DRAFT'
  | 'DEMAND'
  | 'HANDED_OVER'
  | 'CHECKED_IN'
  | 'CREATED';
