import {Button} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {mergeAll} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

interface RequestsTabProps extends TestIdProps {
  directSaleItemId: string | Nullish;
}

export function RequestTabMaterialRequestsTab(props: RequestsTabProps) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([
      filter,
      {
        originEntityId: props.directSaleItemId,
      },
    ]);

  return (
    <VStack spacing={0}>
      <DataGrid
        ref={dataGridRef}
        gridCode="direct-sale-item-requests"
        queryModifier={queryModifier}
        autoHeight
        data-testid={suffixTestId('requests', props)}
      />
      <HStack justify="flex-end">
        <Button
          variant="link"
          title={i18n.t('general.actions.refresh')}
          leftIcon="navigation/refresh"
          onClick={refreshDataGrid}
          data-testid={suffixTestId('requestsAction.refresh', props)}
        />
      </HStack>
    </VStack>
  );
}
