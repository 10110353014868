import {Form, FormField, FormSubmitHandler} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import {
  useGetAuthorizationProfilesQuery,
  useGetLabourCatalogCategoryQuery,
  useGetTenantQuery,
  usePatchLabourCatalogCategoryMutation,
  usePostLabourCatalogCategoryMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {LabourCatalogueType} from './types/LabourCatalogueType';

export function LabourCataloguesDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {data: tenant} = useGetTenantQuery();

  const {data, isLoading, isError} = useGetLabourCatalogCategoryQuery(
    {labourCatalogId: id ?? ''},
    {skip: isNil(id)}
  );

  const [postLabourCatalogCategory] = usePostLabourCatalogCategoryMutation();
  const [patchLabourCatalogCategory] = usePatchLabourCatalogCategoryMutation();

  const {data: authorizationProfiles, isLoading: isAuthorizationProfilesLoading} =
    useGetAuthorizationProfilesQuery(
      {'x-tenant': tenant?.id ?? ''},
      {
        skip: isNil(tenant),
        selectFromResult: (result) => ({
          ...result,
          data:
            result.data?.map((profile) => ({
              label: profile?.name ?? '',
              value: profile?.id ?? '',
            })) ?? [],
        }),
      }
    );

  const handleSubmit: FormSubmitHandler<LabourCatalogueType> = async (data) => {
    const body = {
      name: data.name ?? '',
      code: data.code,
      authorizationProfiles: data.authorizationProfiles ?? [],
    };

    if (isNil(id)) {
      return await postLabourCatalogCategory({body})
        .unwrap()
        .then(() => navigate(settingsRoutes.labourCatalogues))
        .catch(handleApiError);
    }

    return await patchLabourCatalogCategory({labourCatalogId: id, body})
      .unwrap()
      .then(() => navigate(settingsRoutes.labourCatalogues))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.workshop.actions.newLabourCatalogue'),
        breadcrumbs: [
          {
            label: i18n.t('entity.workshop.labels.labourCatalogues'),
            href: settingsRoutes.labourCatalogues,
          },
        ],
      }}
      isCreating={isNil(id)}
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.labourCataloguesDetail('template')}
    >
      <SettingsSection>
        <Form<LabourCatalogueType> schema={FormSchema} onSubmit={handleSubmit} defaultValues={data}>
          {(control) => (
            <>
              <VStack spacing={4}>
                <FormField
                  control={control}
                  label={i18n.t('general.labels.name')}
                  type="text"
                  name="name"
                  isRequired
                  data-testid={testIds.settings.labourCataloguesDetail('name')}
                />
                <FormField
                  control={control}
                  label={i18n.t('entity.settings.labels.authorizationProfile')}
                  type="multiChoice"
                  name="authorizationProfiles"
                  options={authorizationProfiles}
                  isLoading={isAuthorizationProfilesLoading}
                  data-testid={testIds.settings.labourCataloguesDetail('authProfile')}
                  isNotClearable
                />
              </VStack>
              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    title: i18n.t('general.actions.discardChanges'),
                    variant: 'secondary',
                    onClick: () => navigate(settingsRoutes.labourCatalogues),
                  },
                  {
                    control,
                    type: 'form-button',
                    buttonType: 'submit',
                    title: i18n.t('general.actions.saveChanges'),
                    'data-testid': testIds.settings.labourCataloguesDetail('save'),
                  },
                ]}
              />
            </>
          )}
        </Form>
      </SettingsSection>
    </SettingsTemplate>
  );
}

const FormSchema = object({
  name: yupString.required(),
});
