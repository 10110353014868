import type {AddTemplateRequestBody} from '../types/AddTemplateRequestBody';
import type {CancelablePromise} from '../types/CancelablePromise';
import type {TemplateV2} from '../types/TemplateV2';
import {request as __request} from '../utils/request';

export class AddTemplateService {
  /**
   * @returns TemplateV2
   * @throws ApiError
   */
  public static addTemplate({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: AddTemplateRequestBody;
  }): CancelablePromise<TemplateV2> {
    return __request({
      method: 'POST',
      url: '/dms/v2/document/template',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
