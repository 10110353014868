import {BreadcrumbType, DataStatus, showNotification} from 'platform/components';

import {defaultTo, path, pipe} from 'ramda';

import {useGetCentralizedPricingQuery, usePostCentralizedPricingMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {
  SettingsStackItemProps,
  SettingsStackItems,
} from '../../components/SettingsStackItems/SettingsStackItems';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.salesSettingsBusinessCase.labels.businessCase'),
    href: settingsRoutes.businessCase,
  },
];

export function BusinessCaseCentralizedPricing() {
  const {data: centralizedPricing, isLoading, isError} = useGetCentralizedPricingQuery();
  const [updateCentralizedPricing] = usePostCentralizedPricingMutation();

  const value = pipe(path<boolean>([0, 'value']), defaultTo(false))(centralizedPricing);
  const onChange = (value: boolean) =>
    updateCentralizedPricing({value})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const items: SettingsStackItemProps[] = [
    {
      cyId: testIds.settings.businessCaseCentralizedPricing('allowCentralizedPricingProcess'),
      title: i18n.t('page.salesSettingsBusinessCase.labels.centralizedPricesProcess'),
      switchProps: {
        name: 'customerVerification-centralExecutionRecords',
        value,
        onChange,
      },
    },
  ];

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.salesSettingsBusinessCase.labels.centralizedPricing'),
        breadcrumbs,
      }}
      data-testid={testIds.settings.businessCaseCentralizedPricing('settingsPage')}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <SettingsStackItems items={items} />
      </DataStatus>
    </SettingsTemplate>
  );
}
