import {Button, closeCurrentDialog, openDeleteDialog, openDialog} from 'platform/components';
import {Heading, HStack, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useParams} from 'react-router-dom';

import {mergeAll} from 'ramda';
import {isArray, noop} from 'ramda-adjunct';

import {
  useDeleteCustomerContractIndividualPriceLabourMutation,
  useDeleteCustomerContractIndividualPriceMaterialMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {DataGrid, QueryFilterObject, ActionCallback, useRefreshDataGrid} from 'features/datagrid';

import {IndividualMaterialPriceForm} from './components/IndividualMaterialPriceForm';
import {IndividualWorkPriceForm} from './components/IndividualWorkPriceForm';

export function IndividualPricesTab() {
  const {id} = useParams();
  const [workDGRef, refreshWorkDG] = useRefreshDataGrid();
  const [materialDGRef, refreshMaterialDG] = useRefreshDataGrid();

  const [deleteMaterialPrice] = useDeleteCustomerContractIndividualPriceMaterialMutation();
  const [deleteLabourPrice] = useDeleteCustomerContractIndividualPriceLabourMutation();

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {customerContractId: id ?? ''}]);

  const handleAddWork = () =>
    openDialog(
      <IndividualWorkPriceForm
        customerContractId={id}
        onClose={closeCurrentDialog}
        afterSubmit={refreshWorkDG}
        data-testid={testIds.settings.customerContractsDetail('individualWorkPriceForm')}
      />,
      {
        title: i18n.t('entity.customerContract.labels.newIndividualPriceWork'),
        scrollBehavior: 'outside',
      }
    );

  const handleAddMaterial = () =>
    openDialog(
      <IndividualMaterialPriceForm
        customerContractId={id}
        onClose={closeCurrentDialog}
        afterSubmit={refreshMaterialDG}
        data-testid={testIds.settings.customerContractsDetail('individualMaterialPriceForm')}
      />,
      {
        title: i18n.t('entity.customerContract.labels.newIndividualPriceMaterial'),
        scrollBehavior: 'outside',
      }
    );

  const materialActionCallback: ActionCallback = ({actionKey, rowId}) => {
    const individualPriceMaterialId = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('edit', 'redirectDetail', () =>
        openDialog(
          <IndividualMaterialPriceForm
            customerContractId={id}
            individualPriceMaterialId={individualPriceMaterialId}
            onClose={closeCurrentDialog}
            afterSubmit={refreshMaterialDG}
          />,
          {
            title: i18n.t('entity.customerContract.labels.editIndividualPriceMaterial'),
            scrollBehavior: 'outside',
          }
        )
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteMaterialPrice({
              customerContractId: id ?? '',
              individualPriceMaterialId,
            })
              .unwrap()
              .then(refreshMaterialDG)
              .catch(handleApiError),
        })
      )
      .otherwise(noop);
  };

  const workActionCallback: ActionCallback = ({actionKey, rowId}) => {
    const individualPriceLabourId = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('edit', 'redirectDetail', () =>
        openDialog(
          <IndividualWorkPriceForm
            customerContractId={id}
            individualPriceLabourId={individualPriceLabourId}
            onClose={closeCurrentDialog}
            afterSubmit={refreshWorkDG}
          />,
          {
            title: i18n.t('entity.customerContract.labels.editIndividualPriceWork'),
            scrollBehavior: 'outside',
          }
        )
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteLabourPrice({
              customerContractId: id ?? '',
              individualPriceLabourId,
            })
              .unwrap()
              .then(refreshWorkDG)
              .catch(handleApiError),
        })
      )
      .otherwise(noop);
  };

  return (
    <VStack spacing={4}>
      <HStack justify="space-between" align="center">
        <Heading size={4}>{i18n.t('entity.order.labels.work')}</Heading>
        <Button
          title={i18n.t('general.actions.add')}
          variant="link"
          leftIcon="content/add_circle"
          size="small"
          onClick={handleAddWork}
          data-testid={testIds.settings.customerContractsDetail('addInvidualWork')}
        />
      </HStack>
      <DataGrid
        ref={workDGRef}
        gridCode="customer-contract-individual-price-labour"
        queryModifier={queryModifier}
        autoHeight
        actionCallback={workActionCallback}
        data-testid={testIds.settings.customerContractsDetail('individualPricesLabour')}
      />
      <HStack justify="space-between" align="center">
        <Heading size={4}>{i18n.t('entity.order.labels.material')}</Heading>
        <Button
          title={i18n.t('general.actions.add')}
          variant="link"
          leftIcon="content/add_circle"
          size="small"
          onClick={handleAddMaterial}
          data-testid={testIds.settings.customerContractsDetail('addInvidualMaterial')}
        />
      </HStack>
      <DataGrid
        ref={materialDGRef}
        gridCode="customer-contract-individual-price-material"
        queryModifier={queryModifier}
        autoHeight
        actionCallback={materialActionCallback}
        data-testid={testIds.settings.customerContractsDetail('individualPricesMaterial')}
      />
    </VStack>
  );
}
