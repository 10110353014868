import {Form, FormField, FormSubmitHandler, Separator, showNotification} from 'platform/components';
import {Grid, Heading, VStack} from 'platform/foundation';
import {object} from 'yup';

import {
  useGetTiresGeneralSettingsQuery,
  usePutTiresGeneralSettingsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError, TypedFormSchema} from '@omnetic-dms/shared';

import {yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {useSeriesOptions} from '../../hooks/useSeriesOptions';
import {TiresInventoryFormType} from './types/tiresInventoryFormType';
import {getDefaultValuesFromGeneralSettings} from './utils/getDefaultValuesFromGeneralSettings';

export function TiresInventory() {
  const {data, isLoading, isError} = useGetTiresGeneralSettingsQuery();
  const {groupedSeries, isSeriesLoading} = useSeriesOptions([
    'pneu-hotel/tire-movement',
    'pneu-hotel/tire-order',
    'pneu-hotel/tire-set',
  ]);

  const [putTiresSettings] = usePutTiresGeneralSettingsMutation();

  const handleSubmit: FormSubmitHandler<TiresInventoryFormType> = (data) =>
    putTiresSettings({body: data})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <SettingsTemplate
      header={{title: i18n.t('general.labels.general')}}
      description={i18n.t('entity.tiresInventory.labels.settingsDescription')}
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.tiresInventory('template')}
    >
      <Separator />
      <SettingsSection>
        <Form<TiresInventoryFormType>
          defaultValues={getDefaultValuesFromGeneralSettings(data)}
          schema={formSchema}
          onSubmit={handleSubmit}
        >
          {(control) => (
            <VStack spacing={6}>
              <Heading size={4}>{i18n.t('entity.documentSeries.labels.documentSeries')}</Heading>
              <Grid columns={3} spacing={4}>
                <FormField
                  control={control}
                  type="choice"
                  name="documentSettings.receiptNote.docSeriesId"
                  label={i18n.t('entity.tiresInventory.labels.receiptNote')}
                  isLoading={isSeriesLoading}
                  options={groupedSeries['pneu-hotel/tire-movement']}
                  data-testid={testIds.settings.tiresInventory('receiptNote')}
                />
                <FormField
                  control={control}
                  type="choice"
                  name="documentSettings.issueNote.docSeriesId"
                  label={i18n.t('entity.tiresInventory.labels.issueNote')}
                  isLoading={isSeriesLoading}
                  options={groupedSeries['pneu-hotel/tire-movement']}
                  data-testid={testIds.settings.tiresInventory('issueNote')}
                />
                <FormField
                  control={control}
                  type="choice"
                  name="documentSettings.archiveNote.docSeriesId"
                  label={i18n.t('entity.tiresInventory.labels.archiveNote')}
                  isLoading={isSeriesLoading}
                  options={groupedSeries['pneu-hotel/tire-movement']}
                  data-testid={testIds.settings.tiresInventory('archiveNote')}
                />
              </Grid>
              <Grid columns={3} spacing={4}>
                <FormField
                  control={control}
                  type="choice"
                  name="documentSettings.tireOrder.docSeriesId"
                  label={i18n.t('page.tiresInventory.labels.tireOrder')}
                  isLoading={isSeriesLoading}
                  options={groupedSeries['pneu-hotel/tire-order']}
                  data-testid={testIds.settings.tiresInventory('tireOrder')}
                />
                <FormField
                  control={control}
                  type="choice"
                  name="documentSettings.tireSet.docSeriesId"
                  label={i18n.t('entity.tireSet.labels.tireSet')}
                  isLoading={isSeriesLoading}
                  options={groupedSeries['pneu-hotel/tire-set']}
                  data-testid={testIds.settings.tiresInventory('tireSet')}
                />
              </Grid>
              <FormField
                control={control}
                type="checkbox"
                name="hasAutomaticStoreUntilSettings"
                label={i18n.t('page.tiresInventory.labels.hasAutomaticStoreUntilSettings')}
                data-testid={testIds.settings.tiresInventory('hasAutomaticStoreUntilSettings')}
              />
              <Grid columns={3} spacing={4}>
                <FormField
                  control={control}
                  type="dayMonth"
                  name="storeUntilSettings.0.receivedAt"
                  label={i18n.t('page.tiresInventory.labels.receivedAt')}
                  data-testid={testIds.settings.tiresInventory('storeUntilSettings-0-receivedAt')}
                />
                <FormField
                  control={control}
                  type="dayMonth"
                  name="storeUntilSettings.0.receivedTo"
                  label={i18n.t('page.tiresInventory.labels.receivedTo')}
                  data-testid={testIds.settings.tiresInventory('storeUntilSettings-0-receivedTo')}
                />
                <FormField
                  control={control}
                  type="dayMonth"
                  name="storeUntilSettings.0.preFillDate"
                  label={i18n.t('page.tiresInventory.labels.preFillDate')}
                  data-testid={testIds.settings.tiresInventory('storeUntilSettings-0-preFillDate')}
                />
              </Grid>
              <Grid columns={3} spacing={4}>
                <FormField
                  control={control}
                  type="dayMonth"
                  name="storeUntilSettings.1.receivedAt"
                  label={i18n.t('page.tiresInventory.labels.receivedAt')}
                  data-testid={testIds.settings.tiresInventory('storeUntilSettings-1-receivedAt')}
                />
                <FormField
                  control={control}
                  type="dayMonth"
                  name="storeUntilSettings.1.receivedTo"
                  label={i18n.t('page.tiresInventory.labels.receivedTo')}
                  data-testid={testIds.settings.tiresInventory('storeUntilSettings-1-receivedTo')}
                />
                <FormField
                  control={control}
                  type="dayMonth"
                  name="storeUntilSettings.1.preFillDate"
                  label={i18n.t('page.tiresInventory.labels.preFillDate')}
                  data-testid={testIds.settings.tiresInventory('storeUntilSettings-1-preFillDate')}
                />
              </Grid>
              <SettingsFooter
                actions={[
                  {
                    control,
                    type: 'form-button',
                    buttonType: 'submit',
                    title: i18n.t('general.actions.save'),
                  },
                ]}
              />
            </VStack>
          )}
        </Form>
      </SettingsSection>
    </SettingsTemplate>
  );
}

const docSeries = object({
  docSeriesId: yupString.required(),
}).required();

const formSchema = object<TypedFormSchema<TiresInventoryFormType>>({
  documentSettings: object({
    receiptNote: docSeries,
    issueNote: docSeries,
    archiveNote: docSeries,
    tireOrder: docSeries,
    tireSet: docSeries,
  }),
});
