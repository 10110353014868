import {useLocalStorage} from 'platform/components';

import {useEffect, useReducer} from 'react';

import {isEmpty} from 'ramda';

import {
  reducer,
  SourcingTableDispatch,
  SourcingTableName,
  SourcingTableState,
} from '@omnetic-dms/teas';

import {sourcingTableSessionStorage} from '../utils/sourcingTableSessionStorage';

export function useSourcingTable(
  tableName: SourcingTableName,
  initialOrderBy: string
): [SourcingTableState, SourcingTableDispatch] {
  const [orderByFromLocalStorage, saveOrderByToLocalStorage] = useLocalStorage(
    `sourcing-table--sorting/${tableName}`,
    initialOrderBy
  );

  const [state, dispatch] = useReducer(reducer, {
    tableName,
    orderBy: isEmpty(orderByFromLocalStorage) ? initialOrderBy : orderByFromLocalStorage,
    page: null,
    checkedRows: [],
    selectedRow: null,
    expandedRowIds: [],
  });

  useEffect(() => {
    saveOrderByToLocalStorage(state.orderBy);
  }, [state.orderBy, saveOrderByToLocalStorage]);

  useEffect(() => {
    sourcingTableSessionStorage.setLastVisitedPage(tableName, state.page ?? 1);
  }, [state.page, tableName]);

  return [state, dispatch];
}
