import i18n from '@omnetic-dms/i18n';
import {testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {Main, PageTab, usePricePermissions} from '@omnetic-dms/shared';

import {buildArray, composePath, useRequiredParams} from 'shared';

import {VehicleEarningsAndCosts} from './components/VehicleEarningsAndCosts';
import {VehiclePrices} from './components/VehiclePrices';

export function PricesAndFinances() {
  const {id: vehicleId} = useRequiredParams();

  const {canViewAnyOfVehiclePriceFields, canReadVehicleCosts, canReadVehicleEarnings} =
    usePricePermissions({vehicleRecordId: vehicleId, businessCaseRecordId: null});

  return (
    <Main
      data-testid={testIds.vehicles.finances('page')}
      tabs={buildArray<PageTab>()
        .when(canViewAnyOfVehiclePriceFields, {
          id: 'prices',
          title: i18n.t('entity.vehicle.labels.carPrices'),
          href: composePath(vehiclesRoutes.financesPrices, {params: {id: vehicleId}}),
          content: <VehiclePrices />,
        })
        .when(canReadVehicleCosts || canReadVehicleEarnings, {
          id: 'earningsCosts',
          title: i18n.t('page.vehicle.labels.earningsCosts'),
          href: composePath(vehiclesRoutes.financesEarningsCosts, {params: {id: vehicleId}}),
          content: <VehicleEarningsAndCosts />,
        })}
    />
  );
}
