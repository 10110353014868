import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

const VatDetailsSchema = z.object({
  amount: z.number().nullable(),
  currency: z.string().nullable(),
});

const PriceDetailsSchema = z.object({
  withoutVat: VatDetailsSchema,
  withVat: VatDetailsSchema,
  vat: VatDetailsSchema.nullable(),
});

const MechanicSchema = z.object({
  id: z.string().nullable(),
  name: z.string().nullable(),
  isDefault: z.boolean().nullable(),
  costCenterId: z.string().nullable(),
});

const JobStateSchema = z.enum([
  'ARCHIVED',
  'CANCELLED',
  'DRAFT',
  'REQUEST',
  'OFFER',
  'POSTPONED',
  'CLOSED',
  'OPEN',
  'INPROGRESS',
  'PAUSED',
  'WORKDONE',
  'LOCKED',
  'SVCPOSTPONEJOB_POSTPONE',
  'SVCPOSTPONEJOB_DONE',
  'SVCPOSTPONEJOB_CANCELLED',
]);

const JobDetailsSchema = z.object({
  id: z.string().nullable(),
  state: JobStateSchema.nullable(),
  name: z.string().nullable(),
  orderVariantId: z.string().nullable(),
  description: z.string().nullable(),
  solution: z.string().nullable(),
  workTeam: z.string().nullable(),
  workTimeEstimated: z.number().nullable(),
  totalPriceEstimated: z.number().nullable(),
  repeatedRepair: z.boolean().nullable(),
  partsReturn: z.boolean().nullable(),
  priority: z.number().nullable(),
});

export const PostServiceOrderJobApiResponseSchema = JobDetailsSchema.nullable();

export type PostServiceOrderJobApiResponse = z.infer<typeof PostServiceOrderJobApiResponseSchema>;

export const PostServiceOrderJobApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: JobDetailsSchema.partial().nullable(),
});

export type PostServiceOrderJobApiArg = z.infer<typeof PostServiceOrderJobApiArgSchema>;

export const GetServiceOrderJobApiResponseSchema = JobDetailsSchema.extend({
  neededAt: z.string().nullable(),
  assignMechanics: z.array(MechanicSchema).nullable(),
}).nullable();

export type GetServiceOrderJobApiResponse = z.infer<typeof GetServiceOrderJobApiResponseSchema>;

export const GetServiceOrderJobApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
});

export type GetServiceOrderJobApiArg = z.infer<typeof GetServiceOrderJobApiArgSchema>;

export const DeleteServiceOrderJobApiResponseSchema = z.unknown();

export type DeleteServiceOrderJobApiResponse = z.infer<
  typeof DeleteServiceOrderJobApiResponseSchema
>;

export const DeleteServiceOrderJobApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
});

export type DeleteServiceOrderJobApiArg = z.infer<typeof DeleteServiceOrderJobApiArgSchema>;

export const PatchServiceOrderJobApiResponseSchema = JobDetailsSchema.extend({
  partsRequest: z.boolean().nullable(),
  partsReady: z.boolean().nullable(),
}).nullable();

export type PatchServiceOrderJobApiResponse = z.infer<typeof PatchServiceOrderJobApiResponseSchema>;

export const PatchServiceOrderJobApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: JobDetailsSchema.extend({
    partsRequest: z.boolean().nullable(),
    partsReady: z.boolean().nullable(),
    neededAt: z.string().nullable(),
    withItemNeededAtUpdate: z.boolean().nullable(),
  })
    .partial()
    .nullable(),
});

export type PatchServiceOrderJobApiArg = z.infer<typeof PatchServiceOrderJobApiArgSchema>;

export const GetOrderJobItemsTotalAmountApiResponseSchema = z
  .object({
    success: z.boolean().nullable(),
    timestamp: z.string().nullable(),
    message: z.string().nullable(),
    discount: PriceDetailsSchema.partial().nullable(),
    totalPrice: PriceDetailsSchema.partial().nullable(),
  })
  .nullable();

export type GetOrderJobItemsTotalAmountApiResponse = z.infer<
  typeof GetOrderJobItemsTotalAmountApiResponseSchema
>;

export const GetOrderJobItemsTotalAmountApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
});

export type GetOrderJobItemsTotalAmountApiArg = z.infer<
  typeof GetOrderJobItemsTotalAmountApiArgSchema
>;

export const GetServiceCaseOrderJobsApiResponseSchema = z
  .array(
    JobDetailsSchema.extend({
      partsRequest: z.boolean().nullable(),
      partsReady: z.boolean().nullable(),
      isUncategorized: z.boolean().nullable(),
      isDoNotApplyDiscount: z.boolean().nullable(),
      hasItems: z.boolean().nullable(),
      assignMechanics: z.array(MechanicSchema).nullable(),
      servicePackageDetails: z
        .object({
          id: z.string().nullable(),
          name: z.string().nullable(),
          number: z.string().nullable(),
          isLock: z.boolean().nullable(),
        })
        .nullable(),
    })
  )
  .nullable();

export type GetServiceCaseOrderJobsApiResponse = z.infer<
  typeof GetServiceCaseOrderJobsApiResponseSchema
>;

export const GetServiceCaseOrderJobsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
});

export type GetServiceCaseOrderJobsApiArg = z.infer<typeof GetServiceCaseOrderJobsApiArgSchema>;

export const PostServiceOrderJobFromPostponeJobApiResponseSchema = z.unknown();

export type PostServiceOrderJobFromPostponeJobApiResponse = z.infer<
  typeof PostServiceOrderJobFromPostponeJobApiResponseSchema
>;

export const PostServiceOrderJobFromPostponeJobApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  postponeJobId: z.string(),
});

export type PostServiceOrderJobFromPostponeJobApiArg = z.infer<
  typeof PostServiceOrderJobFromPostponeJobApiArgSchema
>;

export const PutJobToOtherOrderApiResponseSchema = z.unknown();

export type PutJobToOtherOrderApiResponse = z.infer<typeof PutJobToOtherOrderApiResponseSchema>;

export const PutJobToOtherOrderApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z.object({
    serviceOtherOrderId: z.string(),
  }),
});

export type PutJobToOtherOrderApiArg = z.infer<typeof PutJobToOtherOrderApiArgSchema>;

export const PutServiceOrderJobMechanicsApiResponseSchema = BaseVoidResponseSchema;

export type PutServiceOrderJobMechanicsApiResponse = z.infer<
  typeof PutServiceOrderJobMechanicsApiResponseSchema
>;

export const PutServiceOrderJobMechanicsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      assignMechanics: z
        .array(
          z.object({
            id: z.string(),
            isDefault: z.boolean(),
            costCenterId: z.string().nullable().optional(),
          })
        )
        .nullable()
        .optional(),
    })
    .nullable(),
});

export type PutServiceOrderJobMechanicsApiArg = z.infer<
  typeof PutServiceOrderJobMechanicsApiArgSchema
>;

export const GetServiceJobActionsApiResponseSchema = z
  .object({
    jobActions: z
      .object({
        optionClick: z.string().nullable(),
        options: z.array(
          z.object({
            type: z.enum(['list-item', 'divider']).nullable(),
            key: z.string().nullable(),
            style: z.enum(['hidden', 'disabled', 'enable']).nullable(),
            position: z.number(),
          })
        ),
      })
      .nullable(),
    addMaterialActions: z
      .object({
        optionClick: z.string().nullable(),
        options: z.array(
          z.object({
            type: z.enum(['list-item', 'divider']).nullable(),
            key: z.string().nullable(),
            style: z.enum(['hidden', 'disabled', 'enable']).nullable(),
            position: z.number(),
          })
        ),
      })
      .nullable(),
  })
  .nullable();

export type GetServiceJobActionsApiResponse = z.infer<typeof GetServiceJobActionsApiResponseSchema>;

export const GetServiceJobActionsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
});

export type GetServiceJobActionsApiArg = z.infer<typeof GetServiceJobActionsApiArgSchema>;

export const PostServiceWorkItemCopyToServiceOrderApiResponseSchema = BaseVoidResponseSchema;

export type PostServiceWorkItemCopyToServiceOrderApiResponse = z.infer<
  typeof PostServiceWorkItemCopyToServiceOrderApiResponseSchema
>;

export const PostServiceWorkItemCopyToServiceOrderApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      ids: z.array(z.string()),
    })
    .nullable(),
});

export type PostServiceWorkItemCopyToServiceOrderApiArg = z.infer<
  typeof PostServiceWorkItemCopyToServiceOrderApiArgSchema
>;

export const PatchServiceCaseJobCustomPriceApiResponseSchema = BaseVoidResponseSchema;

export type PatchServiceCaseJobCustomPriceApiResponse = z.infer<
  typeof PatchServiceCaseJobCustomPriceApiResponseSchema
>;

export const PatchServiceCaseJobCustomPriceApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      isFixedPrice: z.boolean(),
      fixedPrice: z
        .object({
          isUniformVatType: z.boolean(),
          amount: z.number(),
          currency: z.string(),
          vatType: z.string().optional(),
          isPriceWithVat: z.boolean(),
        })
        .optional(),
    })
    .nullable(),
});

export type PatchServiceCaseJobCustomPriceApiArg = z.infer<
  typeof PatchServiceCaseJobCustomPriceApiArgSchema
>;
