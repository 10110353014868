import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';

export const useCheckPermissions = ({
  finishInspection,
  closeInspection,
  updateInspection,
}: {
  finishInspection: boolean;
  closeInspection: boolean;
  updateInspection: boolean;
}) => {
  const canCloseInspection = (auditState: LoadAuditDataResponseItemBody.state): boolean =>
    closeInspection &&
    auditState !== LoadAuditDataResponseItemBody.state.FINISHED_AUDIT &&
    auditState !== LoadAuditDataResponseItemBody.state.FOR_CHECK;

  const canSendInspectionToInRevision = (
    auditState: LoadAuditDataResponseItemBody.state | undefined
  ): boolean => finishInspection && auditState === LoadAuditDataResponseItemBody.state.TO_REVISION;

  const canSendInspectionToFinishing = (auditState: LoadAuditDataResponseItemBody.state): boolean =>
    finishInspection && auditState === LoadAuditDataResponseItemBody.state.IN_REVISION;

  const canSendInspectionToRevision = (auditState: LoadAuditDataResponseItemBody.state): boolean =>
    updateInspection &&
    (auditState === LoadAuditDataResponseItemBody.state.IN_PROGRESS ||
      auditState === LoadAuditDataResponseItemBody.state.TO_FINISHING);

  const canSeeToFinishingNote = (auditState: LoadAuditDataResponseItemBody.state): boolean =>
    finishInspection &&
    (auditState === LoadAuditDataResponseItemBody.state.TO_FINISHING ||
      auditState === LoadAuditDataResponseItemBody.state.IN_PROGRESS);

  const cantAccessInspection = (auditState: LoadAuditDataResponseItemBody.state): boolean =>
    !closeInspection && auditState === LoadAuditDataResponseItemBody.state.FINISHED_AUDIT;
  const canReopenInspection = (auditState: LoadAuditDataResponseItemBody.state): boolean =>
    updateInspection && auditState === LoadAuditDataResponseItemBody.state.FINISHED_AUDIT;

  return {
    canCloseInspection,
    canSendInspectionToInRevision,
    canSendInspectionToFinishing,
    canSendInspectionToRevision,
    canSeeToFinishingNote,
    cantAccessInspection,
    canReopenInspection,
  };
};
