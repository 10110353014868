import {
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {
  useCreateSectorMutation,
  useGetCurrentUserInfoQuery,
  useUpdateSectorMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

type ParkingSectorBase = {
  branchId: string;
  name: string;
  prefix: string;
  numberOfParkingSpaces: number;
};

export type ParkingSectorFormType = ParkingSectorBase;

export type ParkingSectorType = ParkingSectorBase & {
  sectorId: string;
};

export interface ParkingSectorFormProps {
  editData?: ParkingSectorType;
}

export function ParkingSectorForm({editData}: ParkingSectorFormProps) {
  const navigate = useNavigate();
  const [createNewParkingSector, {isLoading: createLoading}] = useCreateSectorMutation();
  const [updateParkingSector, {isLoading: updateLoading}] = useUpdateSectorMutation();
  const {sectorId, ...defaultValues} = editData ?? {};

  const {data: currentUser, isLoading} = useGetCurrentUserInfoQuery();
  const currentUserBranchesOptions = (currentUser?.branches ?? []).map((branch) => ({
    label: branch.name,
    value: branch.id,
  }));

  const handleCancel = () => navigate(settingsRoutes.vehicleParking);

  const handleSubmit = useCallback<FormSubmitHandler<ParkingSectorFormType>>(
    async (data, setErrors) => {
      if (editData) {
        await updateParkingSector({
          updateSectorRequestBody: {
            branchId: data.branchId,
            name: data.name,
            numberOfSpaces: data.numberOfParkingSpaces,
            prefix: data.prefix,
          },
          sectorId: editData?.sectorId,
        })
          .unwrap()
          .then(() => {
            showNotification.success(
              i18n.t('page.vehicleSettings.notifications.parkingSectorEdited')
            );
            navigate(settingsRoutes.vehicleParking);
          })
          .catch((e) =>
            handleApiError(e, {callback: ({validationErrors}) => setErrors(validationErrors)})
          );
      } else {
        await createNewParkingSector({
          createSectorRequestBody: {
            branchId: data.branchId,
            name: data.name,
            numberOfSpaces: data.numberOfParkingSpaces,
            prefix: data.prefix,
            type: 'VEHICLE',
          },
        })
          .unwrap()
          .then(() => {
            showNotification.success(
              i18n.t('page.vehicleSettings.notifications.parkingSectorCreated')
            );
            navigate(settingsRoutes.vehicleParking);
          })
          .catch((e) =>
            handleApiError(e, {callback: ({validationErrors}) => setErrors(validationErrors)})
          );
      }
    },
    [createNewParkingSector, updateParkingSector, navigate, editData]
  );

  return (
    <DataStatus isLoading={isLoading}>
      <Form<ParkingSectorFormType>
        onSubmit={handleSubmit}
        schema={ParkingSectorFormSchema}
        defaultValues={sectorId ? defaultValues : undefined}
      >
        {(control) => (
          <Card
            title={
              editData ? editData.name : i18n.t('page.vehicleSettings.labels.newParkingSector')
            }
          >
            <VStack spacing={4}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    type="text"
                    control={control}
                    label={i18n.t('entity.parkingSector.name')}
                    name="name"
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    type="choice"
                    control={control}
                    label={i18n.t('entity.parkingSector.branch')}
                    placeholder={i18n.t('general.labels.select')}
                    name="branchId"
                    options={currentUserBranchesOptions}
                  />
                </Box>
              </HStack>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    type="text"
                    control={control}
                    label={i18n.t('entity.parkingSector.prefix')}
                    name="prefix"
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    type="text"
                    control={control}
                    label={i18n.t('entity.parkingSector.numberOfParkingSpaces')}
                    name="numberOfParkingSpaces"
                  />
                </Box>
              </HStack>
              <HStack justify="flex-end">
                <ButtonGroup>
                  <Button
                    variant="secondary"
                    onClick={handleCancel}
                    title={i18n.t('general.actions.cancel')}
                  />
                  <FormButton
                    control={control}
                    type="submit"
                    variant="primary"
                    isLoading={createLoading || updateLoading}
                    title={i18n.t('general.actions.save')}
                  />
                </ButtonGroup>
              </HStack>
            </VStack>
          </Card>
        )}
      </Form>
    </DataStatus>
  );
}

const ParkingSectorFormSchema = Yup.object({
  branchId: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
  name: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
  prefix: Yup.string().max(5).required(i18n.t('general.validations.fieldIsRequired')),
  numberOfParkingSpaces: Yup.number()
    .typeError(i18n.t('general.validations.mustBeNumber'))
    .moreThan(0, i18n.t('general.errors.number.min', {min: 0}))
    .max(999)
    .required(i18n.t('general.validations.fieldIsRequired')),
});
