import {
  GetPurchaseFunnelApiResponse,
  GetPurchaseFunnelApiArg,
  GetSaleFunnelApiResponse,
  GetSaleFunnelApiArg,
  GetVehicleStockOverviewApiResponse,
  GetVehicleStockOverviewApiArg,
  ListLatestBusinessCasesApiResponse,
  ListLatestBusinessCasesApiArg,
  ListLatestInterestsApiResponse,
  ListLatestInterestsApiArg,
  ListLatestVehiclesApiResponse,
  ListLatestVehiclesApiArg,
} from '../types/dashboard';
import {omneticApi} from './baseApi/omneticApi';

const dashboardApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    listLatestBusinessCases: build.query<
      ListLatestBusinessCasesApiResponse,
      ListLatestBusinessCasesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/dashboard/latest-business-cases`,
        params: {branchId: queryArg.branchId},
      }),
      providesTags: (result, error, queryArg) => [
        {
          type: 'LatestBusinessCasesList',
          id: queryArg.branchId,
        },
      ],
    }),
    listLatestInterests: build.query<ListLatestInterestsApiResponse, ListLatestInterestsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/dashboard/latest-interests`,
        params: {branchId: queryArg.branchId},
      }),
      providesTags: (result, error, queryArg) => [
        {
          type: 'LatestInterestsList',
          id: queryArg.branchId,
        },
      ],
    }),
    listLatestVehicles: build.query<ListLatestVehiclesApiResponse, ListLatestVehiclesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/dashboard/latest-vehicles`,
        params: {branchId: queryArg.branchId},
      }),
      providesTags: (result, error, queryArg) => [
        {
          type: 'LatestVehiclesList',
          id: queryArg.branchId,
        },
      ],
    }),
    getPurchaseFunnel: build.query<GetPurchaseFunnelApiResponse, GetPurchaseFunnelApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/dashboard/purchase-funnel`,
        params: {branchId: queryArg.branchId, onlyMine: queryArg.onlyMine},
      }),
      providesTags: (result, error, queryArg) => [
        {
          type: 'PurchaseFunnel',
          id: queryArg.branchId,
        },
      ],
    }),
    getSaleFunnel: build.query<GetSaleFunnelApiResponse, GetSaleFunnelApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/dashboard/sale-funnel`,
        params: {branchId: queryArg.branchId, onlyMine: queryArg.onlyMine},
      }),
      providesTags: (result, error, queryArg) => [
        {
          type: 'SaleFunnel',
          id: queryArg.branchId,
        },
      ],
    }),
    getVehicleStockOverview: build.query<
      GetVehicleStockOverviewApiResponse,
      GetVehicleStockOverviewApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/dashboard/vehicle-stock-overview`,
        params: {branchId: queryArg.branchId},
      }),
      providesTags: (result, error, queryArg) => [
        {
          type: 'VehicleStockOverview',
          id: queryArg.branchId,
        },
      ],
    }),
  }),
});

export const {
  useListLatestBusinessCasesQuery,
  useListLatestInterestsQuery,
  useListLatestVehiclesQuery,
  useGetPurchaseFunnelQuery,
  useGetSaleFunnelQuery,
  useGetVehicleStockOverviewQuery,
} = dashboardApi;
