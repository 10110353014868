import {Tooltip} from 'platform/components';
import {Text, HStack, Icon, Show} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId} from 'shared';

import {PriceIndicatorProps} from '../types/PriceIndicatorProps';

export const PriceLabel = (
  props: Pick<PriceIndicatorProps, 'label' | 'labelTooltip' | 'data-testid'>
) => (
  <Show when={isNotNilOrEmpty(props.label)}>
    <HStack spacing={1} align="center">
      <Text data-testid={suffixTestId('label', props)} size="xSmall" color="secondary">
        {props.label}
      </Text>
      <Show when={isNotNilOrEmpty(props.labelTooltip)}>
        <Tooltip label={props.labelTooltip}>
          <Icon value="action/help" size={3} color="palettes.black.900.60" />
        </Tooltip>
      </Show>
    </HStack>
  </Show>
);
