import {Box, Center, Icon, Show, Spinner} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {forwardRef, ForwardRefRenderFunction, useState} from 'react';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {LightboxImage} from '../types/LightboxImage';

interface ZoomImageComponentProps extends RequiredTestIdProps {
  image: LightboxImage;
}

const ZoomImageComponent: ForwardRefRenderFunction<HTMLImageElement, ZoomImageComponentProps> = (
  props,
  ref
) => {
  const theme = useTheme();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);

  return (
    <>
      <img
        ref={ref}
        data-testid={suffixTestId('image', props)}
        draggable={false}
        src={props.image.url}
        alt={props.image.fileName ?? props.image.id}
        onLoad={() => {
          setLoading(false);
        }}
        onError={() => {
          setLoading(false);
          setError(true);
        }}
        loading="lazy"
        css={css`
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-width: none;
          max-height: none;
          will-change: transform;
          user-select: none;
          -webkit-user-select: none;
          overflow: hidden;
          user-drag: none;
          -webkit-user-drag: none;
          user-select: none;
          touch-action: none;
          -webkit-touch-action: none;
        `}
      />
      <Show when={isError}>
        <Box
          width="100%"
          height="100%"
          backgroundColor={theme.components.Image.emptyFill}
          ratio="4 / 3"
        >
          <Center width="100%" height="100%">
            <Icon color="palettes.neutral.70.100" value="image/photo" size={6} />
          </Center>
        </Box>
      </Show>
      <Show when={isLoading}>
        <Box position="absolute" top={0} left={0} right={0} bottom={0}>
          <Center width="100%" height="100%">
            <Spinner color="light" />
          </Center>
        </Box>
      </Show>
    </>
  );
};

export const ZoomImage = forwardRef(ZoomImageComponent);
