import {createSlice} from '@reduxjs/toolkit';

import {InterestSeriesDefinition} from '../../types/InterestSeriesDefinition';
import {AsyncThunkState, getAsyncThunkReducer} from '../../utils/reduxThunkUtils';
import {getInterestSeriesDefinitionList, getInterestSeriesDefinition} from './actions';
import {INTEREST_SERIES_DEFINITION_SLICE_NAME} from './constants';

type InterestDefinitionSliceType = AsyncThunkState<{
  detail?: InterestSeriesDefinition;
  list: InterestSeriesDefinition[];
}>;

const initialState: InterestDefinitionSliceType = {
  list: [],
  errors: {},
  loading: {},
};

const {reducer} = createSlice({
  initialState,
  name: INTEREST_SERIES_DEFINITION_SLICE_NAME,
  reducers: {},
  extraReducers: (builder) => {
    const {asyncThunkReducer, handleThunkStates} = getAsyncThunkReducer(builder);

    asyncThunkReducer(getInterestSeriesDefinitionList.action, (state, {payload}) => {
      state.list = payload;
    });

    asyncThunkReducer(getInterestSeriesDefinition.action, (state, {payload}) => {
      state.detail = payload;
    });

    handleThunkStates();
  },
});

export const interestSeriesDefinitionReducer = reducer;
