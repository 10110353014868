import {Avatar, Card, Flag, Label, Parameters} from 'platform/components';
import {Box, Heading, HStack, Show, Space, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {join, pipe, reject} from 'ramda';
import {isEmptyArray, isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {GetServiceCheckInApiResponse, GetServiceHandOverApiResponse} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';

import {parseDate, buildArray, TestIdProps, Nullish} from 'shared';

import {useStateCheckinHandoverFlag} from '../../../../../hooks/useStateCheckinHandoverFlag';

interface CheckinHandoverCardProps extends TestIdProps {
  checkin: GetServiceCheckInApiResponse | Nullish;
  handover: GetServiceHandOverApiResponse | Nullish;
}

export function CheckinHandoverCard(props: CheckinHandoverCardProps) {
  const formatDateTime = useDateTimeFormatter();

  const checkinStateFlagProps = useStateCheckinHandoverFlag('CHECKIN', props.checkin?.state);
  const handoverStateFlagProps = useStateCheckinHandoverFlag('HANDOVER', props.handover?.state);

  const checkinDate = props.checkin?.completedDate ?? props.checkin?.scheduledDate;
  const formatedCheckinDate = isNotNil(checkinDate)
    ? formatDateTime('dateTimeShort', parseDate(checkinDate))
    : EMPTY_PLACEHOLDER;
  const checkinParameters = buildArray<string>()
    .when(
      isNotNilOrEmpty(props.checkin?.driverFirstname) ||
        isNotNilOrEmpty(props.checkin?.driverLastname),
      joinValuesToString([props.checkin?.driverFirstname, props.checkin?.driverLastname])
    )
    .when(
      props.checkin?.driverPhoneNo,
      joinValuesToString([props.checkin?.driverPhonePrefix, props.checkin?.driverPhoneNo])
    );

  const handoverDate = props.handover?.completedDate ?? props.handover?.scheduledDate;
  const formatedHandoverDate = isNotNil(handoverDate)
    ? formatDateTime('dateTimeShort', parseDate(handoverDate))
    : EMPTY_PLACEHOLDER;
  const handoverParameters = buildArray<string>()
    .when(
      isNotNilOrEmpty(props.handover?.driverFirstname) ||
        isNotNilOrEmpty(props.handover?.driverLastname),
      joinValuesToString([props.handover?.driverFirstname, props.handover?.driverLastname])
    )
    .when(
      props.handover?.driverPhoneNo,
      joinValuesToString([props.handover?.driverPhonePrefix, props.handover?.driverPhoneNo])
    );

  return (
    <Card title={i18n.t('entity.workshop.labels.checkinAndHandover')}>
      <HStack spacing={4}>
        <Box flex={1}>
          <HStack spacing={4}>
            <Avatar variant="square" icon="navigation/arrow_upward" />
            <VStack>
              <Label>{i18n.t('entity.workshop.labels.checkIn')}</Label>
              <Space vertical={1} />
              <HStack spacing={2} align="center">
                <Heading size={4}>{formatedCheckinDate}</Heading>
                {checkinStateFlagProps && <Flag size="small" {...checkinStateFlagProps} />}
              </HStack>
              <Space vertical={1} />
              <Parameters parameters={checkinParameters} color="secondary" size="small" />
              <Show when={isEmptyArray(checkinParameters)}>{EMPTY_PLACEHOLDER}</Show>
            </VStack>
          </HStack>
        </Box>
        <Box flex={1}>
          <HStack spacing={4}>
            <Avatar variant="square" icon="navigation/arrow_downward" />
            <VStack>
              <Label>{i18n.t('entity.workshop.labels.handover')}</Label>
              <Space vertical={1} />
              <HStack spacing={2} align="center">
                <Heading size={4}>{formatedHandoverDate}</Heading>
                {handoverStateFlagProps && <Flag size="small" {...handoverStateFlagProps} />}
              </HStack>
              <Space vertical={1} />
              <Parameters parameters={handoverParameters} color="secondary" size="small" />
              <Show when={isEmptyArray(handoverParameters)}>{EMPTY_PLACEHOLDER}</Show>
            </VStack>
          </HStack>
        </Box>
      </HStack>
    </Card>
  );
}

const joinValuesToString = pipe(reject(isNilOrEmpty), join(' '));
