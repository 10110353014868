import {motion} from 'framer-motion';
import {Hide, Show} from 'platform/foundation';
import {css} from 'styled-components';

import {useState} from 'react';

import {dec, inc, isNil, isNotNil} from 'ramda';

import {buildArray, RequiredTestIdProps, suffixTestId, useKeyPress} from 'shared';

import {InternalLightboxControls} from '../types/InternalLightboxControls';
import {LightboxAction} from '../types/LightboxAction';
import {LightboxSlide} from '../types/LightboxSlide';
import {Actions} from './Actions';
import {Detail} from './Detail';
import {GridView} from './GridView';
import {Slideshow} from './Slideshow';
import {Thumbnails} from './Thumbnails';

interface BodyProps extends RequiredTestIdProps {
  controls: InternalLightboxControls;
  slides: LightboxSlide[];
}

export function Body(props: BodyProps) {
  /**
   * @description
   * We decided to use useState instead of controlling activeIndex in searchParams.
   * Each change in searchParams triggers a re-render of the application.
   * During scrolling, there were a large number of JS changes that started causing micro-lags of whole Lightbox.
   */
  const [activeIndex, setActiveIndex] = useState(props.controls.initialIndex);

  useKeyPress(['ArrowUp', 'ArrowLeft'], () => {
    if (activeIndex !== 0) {
      setActiveIndex(dec(activeIndex));
      if (isNotNil(props.controls.activeDetailIndex)) {
        props.controls.onDetailOpen(dec(activeIndex));
      }
    }
    if (activeIndex === 0) {
      setActiveIndex(dec(props.slides.length));
      if (isNotNil(props.controls.activeDetailIndex)) {
        props.controls.onDetailOpen(dec(props.slides.length));
      }
    }
  });

  useKeyPress(['ArrowDown', 'ArrowRight'], () => {
    if (activeIndex !== dec(props.slides.length)) {
      setActiveIndex(inc(activeIndex));
      if (isNotNil(props.controls.activeDetailIndex)) {
        props.controls.onDetailOpen(inc(activeIndex));
      }
    }
    if (activeIndex === dec(props.slides.length)) {
      setActiveIndex(0);
      if (isNotNil(props.controls.activeDetailIndex)) {
        props.controls.onDetailOpen(0);
      }
    }
  });

  useKeyPress('Enter', () => {
    if (isNil(props.controls.activeDetailIndex) && props.slides[activeIndex].type !== 'video') {
      props.controls.onDetailOpen(activeIndex);
    }
  });

  const handleDetailClose = () => {
    if (isNotNil(props.controls.activeDetailIndex)) {
      props.controls.onDetailClose(props.controls.activeDetailIndex);
    }
  };

  useKeyPress('Backspace', handleDetailClose);

  useKeyPress('Escape', () => {
    if (isNil(props.controls.activeDetailIndex)) {
      props.controls.onClose();
    } else {
      handleDetailClose();
    }
  });

  const detailSlide =
    isNotNil(props.controls.activeDetailIndex) &&
    isNotNil(props.slides?.[props.controls.activeDetailIndex])
      ? props.slides[props.controls.activeDetailIndex]
      : null;

  return (
    <motion.div
      data-testid={suffixTestId('lightbox-body', props)}
      css={css`
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        background-color: ${({theme}) => theme.colors.palettes.black[10][100]};
        z-index: ${({theme}) => theme.zIndices.LIGHTBOX_OVERLAY};
      `}
      initial={{opacity: 0, scale: 0.95}}
      animate={{opacity: 1, scale: 1}}
      exit={{opacity: 0, scale: 0.95}}
    >
      <Hide when={props.controls.isGridViewEnabled}>
        <Hide onMobile onTablet>
          <Thumbnails
            data-testid={suffixTestId('lightbox', props)}
            slides={props.slides}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </Hide>
        <Slideshow
          data-testid={suffixTestId('lightbox', props)}
          slides={props.slides}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          onDetailOpen={props.controls.onDetailOpen}
        />
      </Hide>

      <Show when={props.controls.isGridViewEnabled && isNil(detailSlide)}>
        <GridView
          data-testid={suffixTestId('lightbox', props)}
          controls={props.controls}
          slides={props.slides}
        />
      </Show>

      {isNotNil(detailSlide) && detailSlide?.type === 'image' ? (
        <Detail
          data-testid={suffixTestId('lightbox', props)}
          image={detailSlide}
          onDetailClose={handleDetailClose}
        />
      ) : null}

      <Actions
        data-testid={suffixTestId('lightbox', props)}
        actionPosition={{top: 4, right: 4, left: 'auto'}}
        zIndex="LIGHTBOX_ACTIONS"
        actions={buildArray<LightboxAction[]>()
          .when(isNil(props.controls.activeDetailIndex), [
            {
              icon: props.controls.isGridViewEnabled ? 'action/list' : 'action/dashboard',
              onClick: props.controls.onViewChange,
              id: 'viewChangeBtn',
            },
          ])
          .add([
            {
              icon: 'navigation/close',
              onClick: props.controls.onClose,
              id: 'closeBtn',
            },
          ])}
      />
    </motion.div>
  );
}
