import {FetchBaseQueryError} from '@reduxjs/toolkit/query';

import {useState} from 'react';

import {HTTP_STATUS_CODES} from 'shared';

import {handleApiError, ApiErrorOptions} from '../utils/handleApiError';

/**
 * @about Hook that is used to handle duplicate errors
 * @see WarehouseMarketingCodeDetail.tsx
 * @returns {boolean} that indicates if the error is a duplicate error
 * @returns {Function} custom error handler that can be used in the useMutation hook
 * @example
 * const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();
 *
 * Use duplicateErrorHandler in the catch block
 * .catch(duplicateErrorHandler);
 *
 * Show error message when duplicateError is true
 * <Show when={duplicateError}>
 *   <Alert type="error">Duplicate error</Alert>
 * </Show>
 *
 * */
export const useDuplicateErrorHandler = () => {
  const [duplicateError, setDuplicateError] = useState<boolean>(false);

  const duplicateErrorHandler = (error: FetchBaseQueryError, options?: ApiErrorOptions) => {
    if (error.status === HTTP_STATUS_CODES.CONFLICT) {
      setDuplicateError(true);

      return;
    }

    handleApiError(error, options);
  };

  return {duplicateError, duplicateErrorHandler};
};
