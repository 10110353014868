import {createApi} from '@reduxjs/toolkit/query/react';

import {REHYDRATE} from 'redux-persist';

import {
  GetAlphaCatalogueApiArg,
  GetAlphaCatalogueApiResponse,
} from '../types/cached/AlphaCatalogue';
import {GetBankListApiArg, GetBankListApiResponse} from '../types/cached/BankList';
import {
  GetCountriesApiArg,
  GetCountriesApiResponse,
  GetPrioritizedCountriesApiArg,
} from '../types/cached/Countries';
import {GetCurrenciesApiArg, GetCurrenciesApiResponse} from '../types/cached/Currencies';
import {GetGendersApiArg, GetGendersApiResponse} from '../types/cached/Genders';
import {
  GetIdentityCardTypesApiResponse,
  GetIdentityCardTypesArg,
} from '../types/cached/IdentityCardTypes';
import {GetPhoneNumbersApiArg, GetPhoneNumbersApiResponse} from '../types/cached/PhoneNumbers';
import {GetRoundTypeApiArg, GetRoundTypeApiResponse} from '../types/cached/RoundType';
import {
  GetVatRatesApiArg,
  GetVatRatesApiResponse,
  GetVatRatesForCountryApiArg,
  GetVatRatesForCountryApiResponse,
} from '../types/cached/VatRates';
import {teasBaseQuery} from './baseQuery/teasBaseQuery';

const ONE_DAY_IN_SECONDS = 24 * 60 * 60;

export const cachedApi = createApi({
  reducerPath: 'cachedApi',
  baseQuery: teasBaseQuery,
  keepUnusedDataFor: ONE_DAY_IN_SECONDS,
  /**
   * This is needed to prevent hydration of the store with pending
   * queries (it will be stuck in loading state forever then).
   *
   * When you provide the extractRehydrationInfo function to RTK Query,
   * it will filter pending queries by its' own.
   */
  extractRehydrationInfo(action): any {
    if (action.type === REHYDRATE) {
      return action.payload;
    }
  },
  endpoints: (build) => ({
    getAlphaCatalogue: build.query<GetAlphaCatalogueApiResponse, GetAlphaCatalogueApiArg>({
      query: (queryArg) => ({
        method: 'GET',
        url: `/vehicle-catalogue/api/enum`,
        params: {lang: queryArg.lang},
      }),
    }),
    getPhoneNumbers: build.query<GetPhoneNumbersApiResponse, GetPhoneNumbersApiArg>({
      query: () => ({
        url: `/common/v1/phone-numbers`,
      }),
    }),
    getCountries: build.query<GetCountriesApiResponse, GetCountriesApiArg>({
      query: () => ({
        url: `/common/v1/countries`,
      }),
    }),
    getPrioritizedCountries: build.query<GetCountriesApiResponse, GetPrioritizedCountriesApiArg>({
      query: (queryArgs: GetPrioritizedCountriesApiArg) => ({
        url: `/common/v1/countries`,
        params: {countryCode: queryArgs.countryCode},
      }),
    }),
    getCurrencies: build.query<GetCurrenciesApiResponse, GetCurrenciesApiArg>({
      query: () => ({
        url: `/common/v1/currencies`,
      }),
    }),
    getGenders: build.query<GetGendersApiResponse, GetGendersApiArg>({
      query: () => ({url: `/common/v1/genders`}),
    }),
    getBankList: build.query<GetBankListApiResponse, GetBankListApiArg>({
      query: (queryArg) => ({
        url: `/common/v2/bank-list`,
        params: {countryCode: queryArg.countryCode},
      }),
    }),
    getIdentityCardTypes: build.query<GetIdentityCardTypesApiResponse, GetIdentityCardTypesArg>({
      query: () => ({
        url: `/common/v1/identity-card-types`,
      }),
    }),
    getRoundType: build.query<GetRoundTypeApiResponse, GetRoundTypeApiArg>({
      query: () => ({
        url: `/common/v1/round-type`,
      }),
    }),
    getVatRates: build.query<GetVatRatesApiResponse, GetVatRatesApiArg>({
      query: () => ({
        url: `/common/v1/vat-rates`,
      }),
    }),
    getVatRatesForCountry: build.query<
      GetVatRatesForCountryApiResponse,
      GetVatRatesForCountryApiArg
    >({
      query: ({country, ...params}) => ({
        url: `/common/v1/vat-rates/${country}`,
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetVatRatesForCountryQuery,
  useLazyGetAlphaCatalogueQuery,
  useLazyGetCountriesQuery,
  useLazyGetCurrenciesQuery,
  useLazyGetGendersQuery,
  useLazyGetBankListQuery,
  useLazyGetIdentityCardTypesQuery,
  useLazyGetPhoneNumbersQuery,
  useLazyGetRoundTypeQuery,
  useLazyGetVatRatesQuery,
  useGetAlphaCatalogueQuery,
  useGetBankListQuery,
  useGetCountriesQuery,
  useGetPrioritizedCountriesQuery,
  useGetCurrenciesQuery,
  useGetGendersQuery,
  useGetIdentityCardTypesQuery,
  useGetPhoneNumbersQuery,
  useGetRoundTypeQuery,
  useGetVatRatesQuery,
  useGetVatRatesForCountryQuery,
} = cachedApi;
