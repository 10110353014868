import {complement, equals, isNil, omit} from 'ramda';

import {Nullish} from '../types/Nullish';

export const hasNonNullishValuesInObjectExcept = (
  obj: Record<string, unknown> | Nullish,
  keyToExclude: string
): boolean =>
  Object.values(omit<Record<string, unknown>, string>([keyToExclude], obj ?? {})).some(
    (value) => complement(isNil)(value) && !equals(null, value)
  );
