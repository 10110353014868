import {BreadcrumbType} from 'platform/components';

import {useEffect} from 'react';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {useThunkDispatch} from '@omnetic-dms/teas';

import {useBoolean} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DocumentRoundingDefinitionsAdd} from './components/DocumentRoundingDefinitionAdd';
import {dispatchRoundingDefinitionActions} from './utils';

export function DocumentRoundingDefinitionsNew() {
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const dispatch = useThunkDispatch();

  useEffect(() => {
    startLoading();
    Promise.all([...dispatchRoundingDefinitionActions(dispatch)]).finally(() => {
      stopLoading();
    });
  }, [dispatch, startLoading, stopLoading]);

  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('page.accounting.labels.documentRoundingDefinitions'),
      href: settingsRoutes.documentRoundingDefinitions,
    },
  ];

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.accounting.labels.documentRoundingDefinitions'), breadcrumbs}}
      isLoading={isLoading}
      data-testid="settings-document-rounding-definition-add-page"
    >
      <DocumentRoundingDefinitionsAdd />
    </SettingsTemplate>
  );
}
