/* eslint-disable no-restricted-syntax */
// Rule is disabled because this is the exact function you should use instead of new Date()
import {isValid, parse} from 'date-fns';

import {isEmpty, isNil} from 'ramda';

import {APIDate} from '../types/APIDate';
import {Nullish} from '../types/Nullish';
import {isApiDateString} from './isApiDateString';

/**
 * @about Parses a date and handles APIDate format correctly (2020-06-30)
 * @see https://carvago.atlassian.net/browse/T20-26462
 */
export function parseDate(value: any): any;
export function parseDate(value: Nullish): null;
export function parseDate(value: string | number | APIDate | Date): Date;
export function parseDate(value: Nullish | string | number | APIDate | Date): Date | null;

export function parseDate(value: Nullish | string | number | APIDate | Date): Date | null {
  if (isNil(value) || isEmpty(value)) {
    return null;
  }
  if (isApiDateString(value)) {
    return parseAPIDate(value) ?? new Date(value);
  }
  return new Date(value);
}

/**
 * @about Parses a date in API format (2020-06-30) to a native Date object
 */
const parseAPIDate = (value: string | APIDate) => {
  const date = parse(value, 'yyyy-MM-dd', new Date());
  return isValid(date) ? date : null;
};
