import {
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, HStack, Show, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';
import {isFalse, isNotNil} from 'ramda-adjunct';

import {
  BaseAuthorizationProfile,
  BaseSupplier,
  CurrencyResponseBody,
  GetDeliveryNoteResponse,
  GetReceiveNoteItemsCountResponse,
  GetReceiveNoteResponse,
  GetWarehousesResponse,
  PatchReceiveNoteManualRequest,
  PaymentType,
  PaymentTypeResponseBody,
  PostReceiveNoteManualRequest,
  Rate,
  useLazyGetWarehouseQuery,
  useLazyGetWarehousesQuery,
  usePatchReceiveNoteManualMutation,
  usePostReceiveNoteManualMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';
import {
  getOptionsFromAuthorizationProfiles,
  getOptionsFromCurrencies,
  getOptionsFromSuppliers,
  getOptionsFromWarehouses,
  handleApiError,
} from '@omnetic-dms/shared';

import {
  composePath,
  Nullish,
  RequiredTestIdProps,
  suffixTestId,
  useNavigate,
  yupString,
} from 'shared';

import {ReceiveNoteFormType} from '../../../../../types/ReceiveNote';
import {getOptionsFromPaymentTypes} from '../../../../../utils/getOptionsFromPaymentTypes';

const DEFAULT_PAYMENT_TYPE = 'bank_transfer';

export interface BasicInformationFormProps extends RequiredTestIdProps {
  suppliers: BaseSupplier[] | Nullish;
  warehouses: GetWarehousesResponse | Nullish;
  authorizationProfiles: BaseAuthorizationProfile[] | Nullish;
  paymentTypes: PaymentTypeResponseBody[] | Nullish;
  currencies?: CurrencyResponseBody[] | Nullish;
  vatRatesByCountry?: Rate[] | Nullish;
  receiveNote?: GetReceiveNoteResponse | Nullish;
  receiveNoteItemsCount?: GetReceiveNoteItemsCountResponse | Nullish;
  deliveryNote?: GetDeliveryNoteResponse | Nullish;
  onClose: () => void;
}

export function BasicInformationForm(props: BasicInformationFormProps) {
  const navigate = useNavigate();

  const [availableWarehousesToSupplier, setAvailableWarehousesToSupplier] = useState<
    GetWarehousesResponse | Nullish
  >(props.warehouses);

  const [postReceiveNoteManual] = usePostReceiveNoteManualMutation();
  const [patchReceiveNoteManual] = usePatchReceiveNoteManualMutation();
  const [getWarehouse, {isFetching: isWarehouseFetching}] = useLazyGetWarehouseQuery();
  const [getWarehouses, {isFetching: areWarehousesFetching}] = useLazyGetWarehousesQuery();

  const isFromReceiveNote = props.receiveNote?.isFromReceiveNote;
  const isReceiveNoteCompleted = props.receiveNote?.state === 'COMPLETED';
  const supplierId = props.receiveNote?.supplierId;
  const authorizationProfileId = props.receiveNote?.authorizationProfileId;

  useEffect(() => {
    if (supplierId && authorizationProfileId) {
      getWarehouses({
        supplierId,
        authorizationProfileId,
      })
        .unwrap()
        .then(setAvailableWarehousesToSupplier)
        .catch(handleApiError);
    } else {
      return;
    }
  }, [getWarehouses, supplierId, authorizationProfileId]);

  const defaultValues: Partial<ReceiveNoteFormType> = {
    supplierId: props.receiveNote?.supplierId,
    warehouseId: props.receiveNote?.warehouseId,
    authorizationProfileId: props.receiveNote?.authorizationProfileId,
    number: props.receiveNote?.receiveNoteNumber,
    date: isReceiveNoteCompleted ? props.receiveNote?.updated : null,
    invoiceNumber: props.receiveNote?.invoiceNumber,
    invoiceIssueDate: props.receiveNote?.invoiceIssueDate,
    paymentType: props.receiveNote?.paymentType ?? DEFAULT_PAYMENT_TYPE,
    currency: props.receiveNote?.currency,
    exchangeRate: props.receiveNote?.exchangeRate,
    ncConversion: props.receiveNote?.ncConversion,
    note: props.receiveNote?.note,
  };

  const handleSubmit: FormSubmitHandler<ReceiveNoteFormType> = async (formValues) => {
    if (props.receiveNote) {
      const receiveNoteBody: PatchReceiveNoteManualRequest['body'] = {
        supplierId: formValues.supplierId,
        warehouseId: formValues.warehouseId,
        authorizationProfileId: formValues.authorizationProfileId,
        invoiceNumber: formValues.invoiceNumber,
        invoiceIssueDate: formValues.invoiceIssueDate,
        paymentType: formValues.paymentType.toLowerCase() as PaymentType,
        currency: formValues.currency,
        exchangeRate: formValues.exchangeRate,
        ncConversion: formValues.ncConversion,
        note: formValues.note,
      };

      return await patchReceiveNoteManual({
        receiveNoteId: props.receiveNote.receiveNoteId,
        body: receiveNoteBody,
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
        })
        .then(props.onClose)
        .catch(handleApiError);
    }

    const receiveNoteBody: PostReceiveNoteManualRequest = {
      supplierId: formValues.supplierId,
      warehouseId: formValues.warehouseId,
      authorizationProfileId: formValues.authorizationProfileId,
      invoiceNumber: formValues.invoiceNumber,
      invoiceIssueDate: formValues.invoiceIssueDate,
      paymentType: formValues.paymentType.toLowerCase() as PaymentType,
      currency: formValues.currency,
      exchangeRate: formValues.exchangeRate,
      ncConversion: formValues.ncConversion,
      note: formValues.note,
    };

    await postReceiveNoteManual(receiveNoteBody)
      .unwrap()
      .then((response) => {
        showNotification.success(i18n.t('page.warehouse.notification.receiveNoteCreated'));
        props.onClose();
        navigate(
          composePath(warehouseRoutes.receiveNoteDetailOverview, {
            params: {receiveNoteId: response.receiveNoteId},
          })
        );
      })
      .catch(handleApiError);
  };

  return (
    <Form<ReceiveNoteFormType>
      schema={formSchema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      {(control, formApi) => {
        const supplierId = formApi.watch('supplierId');

        const handleWarehouseChange = (newWarehouseId: string | number | null) =>
          getWarehouse({warehouseId: newWarehouseId as string})
            .unwrap()
            .then((warehouse) => {
              formApi.setValue('authorizationProfileId', warehouse.authorizationProfileId);
              formApi.trigger('authorizationProfileId');
            })
            .catch(handleApiError);

        const handleSupplierChange = (newSupplierId: string | string[] | number | null) =>
          getWarehouses({supplierId: newSupplierId as string})
            .unwrap()
            .then(setAvailableWarehousesToSupplier)
            .catch(handleApiError);

        return (
          <>
            <VStack spacing={4}>
              <Grid columns={4}>
                <FormField
                  isRequired
                  isNotClearable
                  isDisabled={isFalse(isFromReceiveNote)}
                  control={control}
                  type="choice"
                  name="supplierId"
                  label={i18n.t('entity.warehouse.labels.supplier')}
                  options={getOptionsFromSuppliers(props.suppliers)}
                  onChange={handleSupplierChange}
                  data-testid={suffixTestId('inputs.supplierId', props)}
                />
                <FormField
                  isRequired
                  isNotClearable
                  isLoading={areWarehousesFetching}
                  isDisabled={isNil(supplierId)}
                  control={control}
                  type="choice"
                  name="warehouseId"
                  label={i18n.t('entity.warehouse.labels.warehouse')}
                  options={getOptionsFromWarehouses(availableWarehousesToSupplier)}
                  onChange={handleWarehouseChange}
                  data-testid={suffixTestId('inputs.warehouseId', props)}
                />
                <FormField
                  isRequired
                  isNotClearable
                  isLoading={isWarehouseFetching}
                  isDisabled
                  control={control}
                  type="choice"
                  name="authorizationProfileId"
                  label={i18n.t('entity.warehouse.labels.authorizationProfile')}
                  options={getOptionsFromAuthorizationProfiles(props.authorizationProfiles)}
                  data-testid={suffixTestId('inputs.authorizationProfileId', props)}
                />
              </Grid>

              <Grid columns={4}>
                <FormField
                  isDisabled
                  control={control}
                  type="text"
                  name="number"
                  label={i18n.t('entity.warehouse.labels.number')}
                  data-testid={suffixTestId('inputs.number', props)}
                />
                <FormField
                  isDisabled
                  control={control}
                  type="date"
                  name="date"
                  label={i18n.t('entity.warehouse.labels.date')}
                  data-testid={suffixTestId('inputs.date', props)}
                />
                <FormField
                  control={control}
                  type="text"
                  name="invoiceNumber"
                  label={i18n.t('entity.warehouse.labels.invoiceNumber')}
                  data-testid={suffixTestId('inputs.invoiceNumber', props)}
                />
                <FormField
                  control={control}
                  type="date"
                  name="invoiceIssueDate"
                  label={i18n.t('entity.warehouse.labels.invoiceIssueDate')}
                  data-testid={suffixTestId('inputs.invoiceIssueDate', props)}
                />
              </Grid>

              <Grid columns={4}>
                <FormField
                  isRequired
                  isDisabled={isFalse(isFromReceiveNote)}
                  isNotClearable
                  control={control}
                  type="choice"
                  name="paymentType"
                  label={i18n.t('entity.warehouse.labels.paymentType')}
                  options={getOptionsFromPaymentTypes(props.paymentTypes)}
                  data-testid={suffixTestId('inputs.paymentType', props)}
                />
                <FormField
                  control={control}
                  type="choice"
                  name="currency"
                  label={i18n.t('entity.warehouse.labels.currency')}
                  options={getOptionsFromCurrencies(props.currencies)}
                  data-testid={suffixTestId('inputs.currency', props)}
                />
                <FormField
                  control={control}
                  type="text"
                  name="exchangeRate"
                  label={i18n.t('entity.warehouse.labels.exchangeRate')}
                  isDisabled
                  helperText="Not implemented"
                  data-testid={suffixTestId('inputs.exchangeRate', props)}
                />
                <FormField
                  control={control}
                  type="choice"
                  name="ncConversion"
                  label={i18n.t('entity.warehouse.labels.ncConversion')}
                  options={[]}
                  isDisabled
                  helperText="Not implemented"
                  data-testid={suffixTestId('inputs.ncConversion', props)}
                />
              </Grid>

              <Grid columns={4}>
                <GridItem span={4}>
                  <FormField
                    control={control}
                    type="textarea"
                    name="note"
                    label={i18n.t('entity.warehouse.labels.note')}
                    data-testid={suffixTestId('inputs.note', props)}
                  />
                </GridItem>
              </Grid>

              <HStack justify="flex-end">
                <ButtonGroup>
                  <Show when={isNotNil(props.receiveNote)}>
                    <FormButton
                      control={control}
                      type="reset"
                      variant="secondary"
                      title={i18n.t('general.labels.discard')}
                      onClick={props.onClose}
                      data-testid={suffixTestId('actions.discard', props)}
                    />
                  </Show>
                  <FormButton
                    control={control}
                    type="submit"
                    variant="primary"
                    title={i18n.t('general.labels.saveChanges')}
                    data-testid={suffixTestId('actions.submit', props)}
                  />
                </ButtonGroup>
              </HStack>
            </VStack>
          </>
        );
      }}
    </Form>
  );
}

const formSchema = object({
  warehouseId: yupString.required(),
  supplierId: yupString.required(),
  paymentType: yupString.required(),
  authorizationProfileId: yupString.required(),
});
