import {Button, Textarea} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {useState} from 'react';
import {useDispatch} from 'react-redux';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {createCommentRequest} from '@omnetic-dms/teas';

interface NewCommentProps {
  readonly vehicleId: string;
}

export function NewComment(props: NewCommentProps) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState<string | null>('');

  const handleClick = () => {
    dispatch(
      createCommentRequest({
        entityId: props.vehicleId,
        body: {
          message: message ?? '',
          parentCommentUuid: null,
        },
      })
    );
    setMessage('');
  };

  return (
    <HStack spacing={4} justify="space-between">
      <Box flex={1}>
        <Textarea
          value={message}
          onChange={setMessage}
          placeholder={i18n.t('page.comments.actions.writeAComment')}
          data-testid={testIds.sourcing.vehicleDetail('newComment')}
        />
      </Box>
      <Button
        title={i18n.t('general.actions.send')}
        onClick={handleClick}
        isDisabled={isNilOrEmpty(message)}
        data-testid={testIds.sourcing.vehicleDetail('sendComment')}
      />
    </HStack>
  );
}
