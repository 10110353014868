import {Tooltip} from 'platform/components';
import {Text} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {forwardRef, Ref, useImperativeHandle, useState} from 'react';

import {equals, isNil} from 'ramda';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {ICellRenderer, ICellRendererParams} from '../../types/AgGridTypes';
import {Style} from '../../types/Api';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getCellClassName} from '../../utils/getCellClassName';
import {getStylesToRender} from '../../utils/getStylesToRender';
import {getUnformattedValue} from '../../utils/getUnformattedValue';
import {getValueToRender} from '../../utils/getValueToRender';

type MoneyCellValue = {
  amount: number;
  currency: string;
};

export interface MoneyCellRendererProps extends ICellRendererParams {
  getTooltipProps?: TooltipGetter;
}

function MoneyCellRendererComponent(props: MoneyCellRendererProps, ref: Ref<ICellRenderer>) {
  const {getTooltipProps, data, node, colDef} = props;
  const className = getCellClassName(colDef);
  const [cellValue, setCellValue] = useState<MoneyCellValue>(() => getUnformattedValue(props));
  const [cellStyles, setCellStyles] = useState<Style>(() => getStylesToRender(props));
  const formatCurrency = useFormatCurrency();

  useImperativeHandle(ref, () => ({
    refresh: (refreshProps) => {
      const newCellValue = getValueToRender(refreshProps);
      if (newCellValue !== cellValue) {
        setCellValue(newCellValue);
      }
      const newCellStyles = getStylesToRender(refreshProps);
      if (!equals(newCellStyles, cellStyles)) {
        setCellStyles(newCellStyles);
      }
      return true;
    },
  }));

  const tooltipProps = useTooltipProps(data, node, getTooltipProps);

  const value = isNil(cellValue?.amount)
    ? null
    : formatCurrency(cellValue?.amount, cellValue?.currency, 2);

  return (
    <Tooltip {...tooltipProps} label={value}>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className={className}>
        <Text color={cellStyles.textColor} noWrap={!colDef?.wrapText} size="small">
          {value}
        </Text>
      </div>
    </Tooltip>
  );
}

export const MoneyCellRenderer = forwardRef<ICellRenderer, MoneyCellRendererProps>(
  MoneyCellRendererComponent
);
