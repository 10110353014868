import {SliderTick} from '../types';

type getNonlinearTickValuesArgs = {
  min: number;
  max: number;
  nonlinearTicks: SliderTick[];
};

/**
 * @description
 * Returns the values 'min', 'max' and the values between, to achieve the same gap for non-linear ticks
 *
 * @param {number} param.min - min value for slider
 * @param {number} param.max - max value for slider
 * @param {SliderTick[]} param.nonlinearTicks - Non-linear ticks passed as param in Slider
 *
 * @example
 * // return [0, 1, 2, 5]
 * getNonlinearTickValues({max: 5, min: 0, nonlinearTicks: [{value: 1}, {value: 2}]})
 */

export const getNonlinearTickValues = ({min, max, nonlinearTicks}: getNonlinearTickValuesArgs) => {
  const filteredTicks = nonlinearTicks.reduce((memo: number[], tick) => {
    if (tick.value > min && tick.value < max) {
      memo.push(tick.value);
    }
    return memo;
  }, []);

  return [min, ...filteredTicks, max];
};
