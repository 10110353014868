import {useLocalStorage} from 'platform/components';

import {ReactNode, useEffect, useState} from 'react';

import {assocPath} from 'ramda';

import {FiltersContextProvider} from '../../pages/Classifieds/components/FiltersContext/FiltersContext';

export function SourcingPageWithFiltersContext(props: {children: ReactNode}) {
  const [initialFilters, saveFiltersToLocalStorage] = useLocalStorage('sourcingFilters', {});
  const [filters, setFilters] = useState(initialFilters);

  const serializedFilters = JSON.stringify(filters);
  useEffect(() => {
    saveFiltersToLocalStorage(JSON.parse(serializedFilters));
  }, [saveFiltersToLocalStorage, serializedFilters]);

  const onUpdateFilters = (path: string[], value: any) =>
    setFilters((filters) => assocPath(path, value, filters));

  const onClearFilters = () => setFilters({});

  return (
    <FiltersContextProvider
      value={{filters, onChange: setFilters, onUpdateFilters, onClearFilters}}
    >
      {props.children}
    </FiltersContextProvider>
  );
}
