import {
  GetInsuranceOfferApiResponse,
  GetInsuranceOfferApiArg,
  GetInsuranceComparisonUrlApiResponse,
  GetInsuranceComparisonUrlApiArg,
  PostCustomerTipApiArg,
  PostMailApiArg,
} from '../types/metadaInsurance';
import {metadaApi} from './baseApi/metadaApi';

export const metadaInsuranceApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getInsuranceOffer: build.query<GetInsuranceOfferApiResponse, GetInsuranceOfferApiArg>({
      query: (queryArg) => ({
        url: `insurance/v2/insuranceSpecs`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),

    getInsuranceComparisonUrl: build.query<
      GetInsuranceComparisonUrlApiResponse,
      GetInsuranceComparisonUrlApiArg
    >({
      query: (queryArg) => ({
        url: `insurance/v1/insuranceDraft`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),

    postCustomerTip: build.mutation<unknown, PostCustomerTipApiArg>({
      query: (queryArg) => ({
        url: `insurance/v1/tip`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),

    postMail: build.mutation<unknown, PostMailApiArg>({
      query: (queryArg) => ({
        url: `insurance/v1/mail`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
});

export const {
  usePostMailMutation,
  usePostCustomerTipMutation,
  useLazyGetInsuranceComparisonUrlQuery,
  useGetInsuranceOfferQuery,
} = metadaInsuranceApi;
