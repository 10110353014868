import {Image, Box, Center, Display, Space, VStack, Text} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

export function RotateOverlay() {
  return (
    <Box
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      backgroundColor="palettes.neutral.900.40"
      zIndex="ROTATE_OVERLAY"
    >
      <Center height="100%">
        <VStack>
          <Image src="assets/images/rotate.svg" />
          <Space vertical={8} />
          <Display size={1} color="white">
            {i18n.t('general.labels.rotateYourDevice')}
          </Display>
          <Space vertical={2} />
          <Text size="large" color="white">
            {i18n.t('general.labels.landscapeOptimized')}
          </Text>
        </VStack>
      </Center>
    </Box>
  );
}
