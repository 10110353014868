import {yupResolver} from '@hookform/resolvers/yup';
import {formatISO} from 'date-fns';
import {ButtonGroup} from 'platform/components';

import {FC, useMemo} from 'react';
import {
  DeepPartial,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {Button, TypographyCard} from '@omnetic-dms/teas';

import {parseDate} from 'shared';

import {DocumentRoundingDefinitionForm} from './DocumentRoundingDefinitionForm';
import {DocumentRoundingDefinitionFormType} from './types';
import {emptyValidityItem, roundingDefinitionCtx} from './utils';
import {documentRoundingDefinitionFormValidations} from './validations';

interface DocumentRoundingDefinitionProps {
  defaultValues?: DeepPartial<DocumentRoundingDefinitionFormType>;
}

export const DocumentRoundingDefinition: FC<DocumentRoundingDefinitionProps> = ({
  defaultValues,
}) => {
  const edit = roundingDefinitionCtx.useContextWithPath('edit');

  const form = useForm<DocumentRoundingDefinitionFormType>({
    defaultValues: {
      ...defaultValues,
      validities: defaultValues?.validities?.length
        ? defaultValues.validities
        : [emptyValidityItem()],
    },
    resolver: yupResolver(documentRoundingDefinitionFormValidations(edit, defaultValues)),
  });

  const {formState} = form;

  const {onSubmit, onSubmitError, onCancel} = roundingDefinitionCtx.useDefinitionMethodsCtx();

  const handleSubmit: SubmitHandler<DocumentRoundingDefinitionFormType> = async (values) => {
    const transformedValues: DocumentRoundingDefinitionFormType = {
      ...values,
      validities: values.validities.map((item) => ({
        ...item,
        validFrom: formatISO(parseDate(item.validFrom), {representation: 'date'}),
      })),
    };

    await onSubmit?.(transformedValues, form.setError);
  };
  const handleSubmitError: SubmitErrorHandler<DocumentRoundingDefinitionFormType> = (errors) => {
    onSubmitError?.(errors);
  };

  const Content = useMemo(() => <DocumentRoundingDefinitionForm />, []);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit, handleSubmitError)}>
        <TypographyCard
          title={
            edit
              ? i18n.t('page.accounting.labels.editDefinition')
              : i18n.t('page.accounting.labels.newDefinition')
          }
          footerContent={
            <ButtonGroup align="right">
              <Button
                data-testid={testIds.settings.documentRoundingDefinitionsEdit('cancel')}
                onClick={onCancel}
                type="button"
                secondary
              >
                {i18n.t('general.actions.cancel')}
              </Button>
              <Button
                primary
                data-testid={testIds.settings.documentRoundingDefinitionsEdit('submit')}
                disabled={formState.isSubmitting}
                type="submit"
              >
                {edit ? i18n.t('general.actions.edit') : i18n.t('general.actions.create')}
              </Button>
            </ButtonGroup>
          }
        >
          {Content}
        </TypographyCard>
      </form>
    </FormProvider>
  );
};
