import {Switch} from 'platform/components';

import {FC} from 'react';

type TSwitcher = {
  name?: string;
  value?: boolean;
  disabled?: boolean;
  label?: string;
  onChange: (value: boolean) => void;
};

export const Switcher: FC<TSwitcher> = ({label, onChange, value, ...rest}) => (
  <Switch
    label={label}
    value={!!value}
    onChange={onChange}
    isDisabled={rest.disabled}
    data-testid={`switcher-${rest.name}`}
  />
);
