import {map, pipe} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {ApiEnumKey} from '@omnetic-dms/api';

export const getTypeOptions = pipe(
  (keys: ApiEnumKey[]) => keys.filter((key) => isNotNilOrEmpty(key.labels)),
  map((item) => ({
    label: item.labels[0].label,
    value: item.const_key,
  }))
);
