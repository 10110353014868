import {useReadCodeQuery} from '@omnetic-dms/api';

import {Nullish} from 'shared';

type CodelistId =
  | 'interest_source'
  | 'unsuccessful_interest_reason'
  | 'unsuccessful_business_case_reason';

/**
 * @deprecated use only the query
 * @TODO: remove this useless piece of boilerplate code
 */
export function useCodelistCodeName(
  codelistId: CodelistId,
  codeId: string | Nullish
): string | null {
  const {data} = useReadCodeQuery({codelistId, codeId: codeId ?? ''}, {skip: !codeId});

  return data?.name ?? null;
}
