import {AxiosRequestConfig} from 'axios';
import {showNotification} from 'platform/components';

import {has} from 'ramda';

import {ApiRequestOptions} from '../types/ApiRequestOptions';
import {CancelablePromise} from '../types/CancelablePromise';
import {client} from './apiClient';

const getUrl = (options: ApiRequestOptions): string =>
  options.url.replace(/{(.*?)}/g, (substring: string, group: string) => {
    if (has(group)(options.path)) {
      return encodeURI(String(options.path[group]));
    }
    return substring;
  });

/**
 * Request using axios client
 * @param options The request options from the service
 * @result ApiResult
 * @throws ApiError
 */
export function request<T>(options: ApiRequestOptions): CancelablePromise<T> {
  const axiosOptions: AxiosRequestConfig = {
    method: options.method,
    url: getUrl(options),
    params: options.query,
    data: options.body,
    baseURL: options.baseURL,
    headers: options.headers,
  };

  return new CancelablePromise(async (resolve, reject, onCancel) => {
    try {
      if (!onCancel.isCancelled) {
        const result = await client.request({
          ...axiosOptions,
          cache: {
            override: options.shouldClearCacheEntry,
          },
        });
        resolve(result.data);
      }
    } catch (error: any) {
      if (options.url === '/car-audit/v1/audits/{auditId}' && error.response?.status === 409) {
        showNotification.error(
          error?.response.data?.errors?.[0]?.message ?? 'Forbidden state transition'
        );
      }
      reject(error);
    }
  });
}
