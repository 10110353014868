import {DataStatus, Segment, SegmentProps} from 'platform/components';
import {Box, Hide, HStack, Show, Text, useDevice, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';
import {Helmet} from 'react-helmet-async';

import {always, defaultTo, filter, length} from 'ramda';
import {isPositive} from 'ramda-adjunct';

import {useGetCurrentUserInfoQuery, useGetUserDashboardSettingsQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {myProfileRoutes, testIds} from '@omnetic-dms/routes';

import {useNavigate} from 'shared';

import {Clock} from './components/Clock';
import {LatestBusinessCases} from './components/LatestBusinessCases/LatestBusinessCases';
import {LatestInterests} from './components/LatestInterests/LatestInterests';
import {LatestVehicles} from './components/LatestVehicles/LatestVehicles';
import {MyNotifications} from './components/MyNotifications';
import {OpportunitiesFunnel} from './components/OpportunitiesFunnel';
import {Support} from './components/Support';
import {VehicleWarehouse} from './components/VehicleWarehouse';
import {SEGMENT_MIN_WIDTH} from './consts/segmentMinWidth';
import {DashboardLayoutConfig} from './types/layoutBlocks';
import {splitBlocksToCols} from './utils/splitBlocksToCols';

enum DashboardSource {
  activeBranch = 'activeBranch',
  tenant = 'tenant',
}

const getDashboardSourceOptions = (
  branchName?: string
): NonNullable<SegmentProps<DashboardSource>['options']> => [
  {
    value: DashboardSource.activeBranch,
    label: branchName ?? i18n.t('page.homepage.labels.myBranch'),
  },
  {
    value: DashboardSource.tenant,
    label: i18n.t('page.homepage.labels.all'),
  },
];

const defaultToFalse = defaultTo(false);

export function Dashboard() {
  const [dashboardSource, setDashboardSource] = useState(DashboardSource.activeBranch);
  const navigate = useNavigate();
  const device = useDevice();

  const {data: userInfo} = useGetCurrentUserInfoQuery();
  const {data: userDashboardSettings, isLoading: isUserDashboardSettingsLoading} =
    useGetUserDashboardSettingsQuery();

  const selectedBranchId = match(dashboardSource)
    .with(
      DashboardSource.activeBranch,
      // There is edge case, when user is new and has no branch set, you have to check defaultBranch
      () => userInfo?.settings?.branch || userInfo?.defaultBranch?.id
    )
    .otherwise(always(undefined));

  const branchName = userInfo?.branches?.find(
    (branch) => branch.id === userInfo?.settings?.branch
  )?.name;

  const sourceOptions = getDashboardSourceOptions(branchName);

  const isMobileResolution = device === 'mobile';

  const isFunnelInterestsEnabled = defaultToFalse(userDashboardSettings?.salesFunnelInterests);
  const isFunnelBusinessCasesEnabled = defaultToFalse(
    userDashboardSettings?.salesFunnelBusinessCases
  );

  const isFunnelVisible = defaultToFalse(isFunnelInterestsEnabled || isFunnelBusinessCasesEnabled);

  const blocksDefinition: DashboardLayoutConfig = [
    {
      component: <VehicleWarehouse branchId={selectedBranchId} />,
      priority: 'left',
      isVisible: defaultToFalse(userDashboardSettings?.vehicleStock),
    },
    {
      component: <LatestVehicles branchId={selectedBranchId} />,
      priority: 'left',
      isVisible: defaultToFalse(userDashboardSettings?.latestVehicles),
    },
    {
      component: <Support />,
      priority: 'right',
      isVisible: true,
    },
    {
      component: <MyNotifications />,
      priority: 'right',
      isVisible: defaultToFalse(userDashboardSettings?.notifications),
    },
    {
      component: <LatestBusinessCases branchId={selectedBranchId} />,
      priority: 'left',
      isVisible: defaultToFalse(userDashboardSettings?.latestBusinessCases),
    },
    {
      component: <LatestInterests branchId={selectedBranchId} />,
      priority: 'left',
      isVisible: defaultToFalse(userDashboardSettings?.latestInterests),
    },
  ];

  const visibleBlocks = filter((block) => block.isVisible, blocksDefinition);

  const blocksInColumns = splitBlocksToCols(visibleBlocks);

  return (
    <>
      <Helmet>
        <title>{i18n.t('page.homepage.labels.title')}</title>
      </Helmet>
      <Box padding={4} data-testid={testIds.dashboard.home('wrapper')}>
        <VStack spacing={4}>
          <HStack spacing={2} justify="space-between" align="center">
            <Segment
              value={dashboardSource}
              onChange={(value) => setDashboardSource(value)}
              options={sourceOptions}
              minWidth={SEGMENT_MIN_WIDTH}
            />
            <Text data-testid={testIds.dashboard.home('date')} size="small">
              <Clock />
            </Text>
          </HStack>

          <DataStatus
            isEmpty={!isFunnelVisible && !isPositive(length(visibleBlocks))}
            emptySubheadline={i18n.t('page.homepage.labels.noDataDashboard')}
            action={{
              title: i18n.t('general.labels.goToSettings'),
              variant: 'secondary',
              onClick: () => navigate(myProfileRoutes.dashboard),
            }}
            minHeight={80}
            isLoading={isUserDashboardSettingsLoading}
          >
            <Show when={isFunnelVisible}>
              <OpportunitiesFunnel
                branchId={selectedBranchId}
                isFunnelInterestsEnabled={isFunnelInterestsEnabled}
                isFunnelBusinessCasesEnabled={isFunnelBusinessCasesEnabled}
              />
            </Show>

            <Show when={isMobileResolution}>
              {blocksDefinition.map((block) => block.component)}
            </Show>

            <Hide when={isMobileResolution}>
              <HStack spacing={4}>
                <VStack spacing={4} basis="50%">
                  {blocksInColumns.left.map((block) => block.component)}
                </VStack>
                <VStack spacing={4} basis="50%">
                  {blocksInColumns.right.map((block) => block.component)}
                </VStack>
              </HStack>
            </Hide>
          </DataStatus>
        </VStack>
      </Box>
    </>
  );
}
