import {useEventListener} from 'platform/components';

import {useEffect, useRef, useState} from 'react';

import {splitAt} from 'ramda';

import {usePrevious} from 'shared';

export const MENU_ITEM_HEIGHT = 48;
export const OVERFLOW_BUTTON_SPOT = 1;

interface UseSidebarMenuItemsOverflowProps {
  pinnedMenuItems: string[];
}

function getMenuItemsCountDiff(previous: number, current: number) {
  if (previous < current) {
    return -1;
  }

  if (previous > current) {
    return 1;
  }

  return 0;
}

export function splitVisibleMenuItems({
  pinnedMenuItems,
  previousMenuItemsCount,
  overflowingMenuItemsCount,
}: {
  pinnedMenuItems: string[];
  previousMenuItemsCount: number;
  overflowingMenuItemsCount: number;
}) {
  // Avoid rendering added/removed item and then updating overflow (causing flickering)
  // by calculation diff between previous and current pinned items count
  // and adjusting overflowing items count accordingly.
  const menuItemsCountDiff = getMenuItemsCountDiff(previousMenuItemsCount, pinnedMenuItems.length);

  // overflowingMenuItemsCount includes the overflow button, which is displayed when there are 2 or more overflowing items
  if (overflowingMenuItemsCount - menuItemsCountDiff > 1) {
    const [visiblePinnedMenuItems, overflownPinnedMenuItems] = splitAt(
      Math.max(0, pinnedMenuItems.length - overflowingMenuItemsCount + menuItemsCountDiff),
      pinnedMenuItems
    );

    return {
      visiblePinnedMenuItems,
      overflownPinnedMenuItems,
    };
  }

  return {
    visiblePinnedMenuItems: pinnedMenuItems,
    overflownPinnedMenuItems: [],
  };
}

export function useSidebarMenuItemsOverflow(props: UseSidebarMenuItemsOverflowProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const additionalContentContainerRef = useRef<HTMLDivElement | null>(null);
  const [overflowingMenuItemsCount, setOverflowingMenuItemsCount] = useState(0); // Includes the overflow button
  const pinnedMenuItemsCount = props.pinnedMenuItems.length;
  const previous = usePrevious(pinnedMenuItemsCount);

  const handleSidebarOverflow = () => {
    if (!containerRef.current) {
      return;
    }

    const additionalMenuItemsCount = Math.floor(
      (additionalContentContainerRef.current?.clientHeight ?? 0) / MENU_ITEM_HEIGHT
    );
    const sidebarHeight = containerRef.current.clientHeight;
    const allMenuItemsCount =
      pinnedMenuItemsCount + additionalMenuItemsCount + OVERFLOW_BUTTON_SPOT;

    const availableSlots = Math.floor(sidebarHeight / MENU_ITEM_HEIGHT);

    const overflowCount = Math.max(0, allMenuItemsCount - availableSlots);
    setOverflowingMenuItemsCount(overflowCount);
  };

  useEventListener(window, 'resize', handleSidebarOverflow);

  useEffect(() => {
    handleSidebarOverflow();
  }, [pinnedMenuItemsCount]);

  const {visiblePinnedMenuItems, overflownPinnedMenuItems} = splitVisibleMenuItems({
    pinnedMenuItems: props.pinnedMenuItems,
    previousMenuItemsCount: previous ?? pinnedMenuItemsCount,
    overflowingMenuItemsCount,
  });

  return {
    containerRef,
    additionalContentContainerRef,
    visiblePinnedMenuItems,
    overflownPinnedMenuItems,
  };
}
