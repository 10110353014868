import {MutableRefObject, useEffect, useMemo, useState} from 'react';

export function useIsInViewport(ref: MutableRefObject<Element | null>, threshold?: number) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), {
        threshold: threshold ?? 1,
      }),
    [threshold]
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
