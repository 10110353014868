import {
  CompGetInfoV2ApiArg,
  CompGetInfoV2ApiResponse,
  CompGetInfoV3ApiArg,
  CompGetInfoV3ApiResponse,
  CompGetVatBankAccountsApiArg,
  CompGetVatBankAccountsApiResponse,
  CompGetVatInfoApiArg,
  CompGetVatInfoApiResponse,
  CompGetVatUnreliableInfoApiArg,
  CompGetVatUnreliableInfoApiResponse,
} from '../types/company';
import {metadaApi} from './baseApi/metadaApi';

export const companyApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    compGetInfo: build.query<CompGetInfoV2ApiResponse, CompGetInfoV2ApiArg>({
      query: (queryArg) => ({
        url: `/company/v1/getInfo_v2`,
        params: {id: queryArg.id, vatId: queryArg.vatId, country: queryArg.country},
      }),
    }),
    compGetVatBankAccounts: build.query<
      CompGetVatBankAccountsApiResponse,
      CompGetVatBankAccountsApiArg
    >({
      query: (queryArg) => ({url: `/company/v1/getVatBankAccounts`, params: {id: queryArg.id}}),
    }),
    compGetVatInfo: build.query<CompGetVatInfoApiResponse, CompGetVatInfoApiArg>({
      query: (queryArg) => ({url: `/company/v1/getVatInfo`, params: {id: queryArg.id}}),
    }),
    compGetVatUnreliableInfo: build.query<
      CompGetVatUnreliableInfoApiResponse,
      CompGetVatUnreliableInfoApiArg
    >({
      query: (queryArg) => ({url: `/company/v1/getVatUnreliableInfo`, params: {id: queryArg.id}}),
    }),
    compGetInfoV3: build.query<CompGetInfoV3ApiResponse, CompGetInfoV3ApiArg>({
      query: (queryArg) => ({
        url: `/company/v1/getInfo_v3`,
        params: {id: queryArg.id, vatId: queryArg.vatId, country: queryArg.country},
      }),
    }),
  }),
});

export const {
  useCompGetInfoQuery,
  useCompGetVatBankAccountsQuery,
  useCompGetVatInfoQuery,
  useCompGetVatUnreliableInfoQuery,
  useLazyCompGetInfoQuery,
  useCompGetInfoV3Query,
  useLazyCompGetInfoV3Query,
} = companyApi;
