import {CSSProperties, css} from 'styled-components';

import {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  VideoHTMLAttributes,
} from 'react';

import {isNotNil} from 'ramda';

import {RequiredTestIdProps} from 'shared';

import {useIsInViewport} from '../../hooks/useIsInViewport';
import {useResponsivePropValue} from '../../hooks/useResponsivePropValue';
import {CSSDimension} from '../../types/CSSDimension';
import {Integer} from '../../types/Integer';
import {ValueByDevice} from '../../types/ValueByDevice';
import {getCssSize} from '../../utils/getCssSize';
import {ImageFit} from '../Image/Image';

export type VideoProps = {
  volume?: number;
  width?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  height?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  minWidth?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  minHeight?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  maxWidth?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  maxHeight?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  fit?: ImageFit;
  ratio?: CSSProperties['aspectRatio'];
  position?: CSSProperties['objectPosition'];
} & Pick<
  VideoHTMLAttributes<HTMLVideoElement>,
  'src' | 'muted' | 'autoPlay' | 'onPause' | 'onPlay' | 'onVolumeChange'
> &
  RequiredTestIdProps;

export const Video = forwardRef((props: VideoProps, ref: ForwardedRef<HTMLVideoElement>) => {
  const innerRef = useRef<HTMLVideoElement>(null);
  const isInView = useIsInViewport(innerRef, 0.7);

  useEffect(() => {
    if (innerRef.current && !isInView) {
      innerRef.current.pause();
    }
  }, [isInView]);

  const width = useResponsivePropValue(props.width);
  const height = useResponsivePropValue(props.height);
  const minWidth = useResponsivePropValue(props.minWidth);
  const minHeight = useResponsivePropValue(props.minHeight);
  const maxWidth = useResponsivePropValue(props.maxWidth);
  const maxHeight = useResponsivePropValue(props.maxHeight);

  useImperativeHandle(ref, () => innerRef.current!);

  useEffect(() => {
    if (isNotNil(props.volume)) {
      innerRef.current!.volume = props.volume;
    }
  }, [props.volume]);

  return (
    <video
      ref={innerRef}
      css={css`
        width: ${getCssSize(width) ?? '100%'};
        height: ${getCssSize(height) ?? '100%'};
        min-width: ${getCssSize(minWidth) ?? 'initial'};
        min-height: ${getCssSize(minHeight) ?? 'initial'};
        max-width: ${getCssSize(maxWidth) ?? 'initial'};
        max-height: ${getCssSize(maxHeight) ?? 'initial'};
        object-fit: ${props.fit};
        object-position: ${props.position};
        aspect-ratio: ${props.ratio};
      `}
      src={props.src}
      muted={props.muted}
      autoPlay={props.autoPlay}
      controls
      controlsList="nodownload noremoteplayback noplaybackrate"
      disablePictureInPicture
      onPause={props.onPause}
      onPlay={props.onPlay}
      onVolumeChange={props.onVolumeChange}
    />
  );
});
