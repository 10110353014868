import {TimeType} from 'platform/components';

import {Nullish} from 'shared';

/**
 * Converts a time string to a number. Returns null if given invalid or Nullish value.
 */
export const timeStringToNumber = (timeString: TimeType | Nullish) => {
  const regex = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);

  if (!timeString || !regex.test(timeString)) {
    return null;
  }

  const [hours, minutes] = timeString.split(':').map(Number);
  return hours + minutes / 60;
};
