import {SystemStyleObject} from '@chakra-ui/react';

export const dgIndent: SystemStyleObject = {
  '.ag-ltr .ag-column-select-indent-1': {
    paddingLeft: '24px',
  },
  '.ag-rtl .ag-column-select-indent-1': {
    paddingRight: '24px',
  },
  '.ag-ltr .ag-column-select-indent-2': {
    paddingLeft: '32px',
  },
  '.ag-rtl .ag-column-select-indent-2': {
    paddingRight: '32px',
  },
  '.ag-ltr .ag-column-select-indent-3': {
    paddingLeft: '48px',
  },
  '.ag-rtl .ag-column-select-indent-3': {
    paddingRight: '48px',
  },
  '.ag-ltr .ag-column-select-indent-4': {
    paddingLeft: '64px',
  },
  '.ag-rtl .ag-column-select-indent-4': {
    paddingRight: '64px',
  },
  '.ag-ltr .ag-column-select-indent-5': {
    paddingLeft: '80px',
  },
  '.ag-rtl .ag-column-select-indent-5': {
    paddingRight: '80px',
  },
  '.ag-ltr .ag-column-select-indent-6': {
    paddingLeft: '96px',
  },
  '.ag-rtl .ag-column-select-indent-6': {
    paddingRight: '96px',
  },
  '.ag-ltr .ag-column-select-indent-7': {
    paddingLeft: '112px',
  },
  '.ag-rtl .ag-column-select-indent-7': {
    paddingRight: '112px',
  },
  '.ag-ltr .ag-column-select-indent-8': {
    paddingLeft: '128px',
  },
  '.ag-rtl .ag-column-select-indent-8': {
    paddingRight: '128px',
  },
  '.ag-ltr .ag-column-select-indent-9': {
    paddingLeft: '144px',
  },
  '.ag-rtl .ag-column-select-indent-9': {
    paddingRight: '144px',
  },

  '.ag-ltr .ag-column-select-add-group-indent': {
    marginLeft: '40px',
  },
  '.ag-rtl .ag-column-select-add-group-indent': {
    marginRight: '40px',
  },

  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-1': {
    paddingLeft: '46px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-1': {
    paddingRight: '46px',
  },
  '.ag-ltr .ag-row-group-indent-1': {paddingLeft: '28px'},
  '.ag-rtl .ag-row-group-indent-1': {paddingRight: '28px'},
  '.ag-ltr .ag-row-level-1 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-2': {
    paddingLeft: '74px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-2': {
    paddingRight: '74px',
  },
  '.ag-ltr .ag-row-group-indent-2': {paddingLeft: '56px'},
  '.ag-rtl .ag-row-group-indent-2': {paddingRight: '56px'},

  '.ag-column-select-virtual-list-viewport': {
    padding: '6px 0px',
  },
  '.ag-column-select-virtual-list-item': {margin: '0 12px'},
  '.ag-rtl': {textAlign: 'right'},
  '.ag-rtl .ag-row-level-1 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row-level-2 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-2 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-3': {
    paddingLeft: '102px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-3': {
    paddingRight: '102px',
  },
  '.ag-ltr .ag-row-group-indent-3': {paddingLeft: '84px'},
  '.ag-rtl .ag-row-group-indent-3': {paddingRight: '84px'},
  '.ag-ltr .ag-row-level-3 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-3 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-4': {
    paddingLeft: '130px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-4': {
    paddingRight: '130px',
  },
  '.ag-ltr .ag-row-group-indent-4': {paddingLeft: '112px'},
  '.ag-rtl .ag-row-group-indent-4': {paddingRight: '112px'},
  '.ag-ltr .ag-row-level-4 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-4 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-5': {
    paddingLeft: '158px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-5': {
    paddingRight: '158px',
  },
  '.ag-ltr .ag-row-group-indent-5': {paddingLeft: '140px'},
  '.ag-rtl .ag-row-group-indent-5': {paddingRight: '140px'},
  '.ag-ltr .ag-row-level-5 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-5 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-6': {
    paddingLeft: '186px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-6': {
    paddingRight: '186px',
  },
  '.ag-ltr .ag-row-group-indent-6': {paddingLeft: '168px'},
  '.ag-rtl .ag-row-group-indent-6': {paddingRight: '168px'},
  '.ag-ltr .ag-row-level-6 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-6 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-7': {
    paddingLeft: '214px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-7': {
    paddingRight: '214px',
  },
  '.ag-ltr .ag-row-group-indent-7': {paddingLeft: '196px'},
  '.ag-rtl .ag-row-group-indent-7': {paddingRight: '196px'},
  '.ag-ltr .ag-row-level-7 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-7 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-8': {
    paddingLeft: '242px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-8': {
    paddingRight: '242px',
  },
  '.ag-ltr .ag-row-group-indent-8': {paddingLeft: '224px'},
  '.ag-rtl .ag-row-group-indent-8': {paddingRight: '224px'},
  '.ag-ltr .ag-row-level-8 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-8 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-9': {
    paddingLeft: '270px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-9': {
    paddingRight: '270px',
  },
  '.ag-ltr .ag-row-group-indent-9': {paddingLeft: '252px'},
  '.ag-rtl .ag-row-group-indent-9': {paddingRight: '252px'},
  '.ag-ltr .ag-row-level-9 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-9 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-10': {
    paddingLeft: '298px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-10': {
    paddingRight: '298px',
  },
  '.ag-ltr .ag-row-group-indent-10': {paddingLeft: '280px'},
  '.ag-rtl .ag-row-group-indent-10': {paddingRight: '280px'},
  '.ag-ltr .ag-row-level-10 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-10 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-11': {
    paddingLeft: '326px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-11': {
    paddingRight: '326px',
  },
  '.ag-ltr .ag-row-group-indent-11': {paddingLeft: '308px'},
  '.ag-rtl .ag-row-group-indent-11': {paddingRight: '308px'},
  '.ag-ltr .ag-row-level-11 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-11 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-12': {
    paddingLeft: '354px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-12': {
    paddingRight: '354px',
  },
  '.ag-ltr .ag-row-group-indent-12': {paddingLeft: '336px'},
  '.ag-rtl .ag-row-group-indent-12': {paddingRight: '336px'},
  '.ag-ltr .ag-row-level-12 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-12 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-13': {
    paddingLeft: '382px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-13': {
    paddingRight: '382px',
  },
  '.ag-ltr .ag-row-group-indent-13': {paddingLeft: '364px'},
  '.ag-rtl .ag-row-group-indent-13': {paddingRight: '364px'},
  '.ag-ltr .ag-row-level-13 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-13 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-14': {
    paddingLeft: '410px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-14': {
    paddingRight: '410px',
  },
  '.ag-ltr .ag-row-group-indent-14': {paddingLeft: '392px'},
  '.ag-rtl .ag-row-group-indent-14': {paddingRight: '392px'},
  '.ag-ltr .ag-row-level-14 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-14 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-15': {
    paddingLeft: '438px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-15': {
    paddingRight: '438px',
  },
  '.ag-ltr .ag-row-group-indent-15': {paddingLeft: '420px'},
  '.ag-rtl .ag-row-group-indent-15': {paddingRight: '420px'},
  '.ag-ltr .ag-row-level-15 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-15 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-16': {
    paddingLeft: '466px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-16': {
    paddingRight: '466px',
  },
  '.ag-ltr .ag-row-group-indent-16': {paddingLeft: '448px'},
  '.ag-rtl .ag-row-group-indent-16': {paddingRight: '448px'},
  '.ag-ltr .ag-row-level-16 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-16 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-17': {
    paddingLeft: '494px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-17': {
    paddingRight: '494px',
  },
  '.ag-ltr .ag-row-group-indent-17': {paddingLeft: '476px'},
  '.ag-rtl .ag-row-group-indent-17': {paddingRight: '476px'},
  '.ag-ltr .ag-row-level-17 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-17 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-18': {
    paddingLeft: '522px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-18': {
    paddingRight: '522px',
  },
  '.ag-ltr .ag-row-group-indent-18': {paddingLeft: '504px'},
  '.ag-rtl .ag-row-group-indent-18': {paddingRight: '504px'},
  '.ag-ltr .ag-row-level-18 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-18 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-19': {
    paddingLeft: '550px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-19': {
    paddingRight: '550px',
  },
  '.ag-ltr .ag-row-group-indent-19': {paddingLeft: '532px'},
  '.ag-rtl .ag-row-group-indent-19': {paddingRight: '532px'},
  '.ag-ltr .ag-row-level-19 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-19 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-20': {
    paddingLeft: '578px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-20': {
    paddingRight: '578px',
  },
  '.ag-ltr .ag-row-group-indent-20': {paddingLeft: '560px'},
  '.ag-rtl .ag-row-group-indent-20': {paddingRight: '560px'},
  '.ag-ltr .ag-row-level-20 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-20 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-21': {
    paddingLeft: '606px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-21': {
    paddingRight: '606px',
  },
  '.ag-ltr .ag-row-group-indent-21': {paddingLeft: '588px'},
  '.ag-rtl .ag-row-group-indent-21': {paddingRight: '588px'},
  '.ag-ltr .ag-row-level-21 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-21 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-22': {
    paddingLeft: '634px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-22': {
    paddingRight: '634px',
  },
  '.ag-ltr .ag-row-group-indent-22': {paddingLeft: '616px'},
  '.ag-rtl .ag-row-group-indent-22': {paddingRight: '616px'},
  '.ag-ltr .ag-row-level-22 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-22 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-23': {
    paddingLeft: '662px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-23': {
    paddingRight: '662px',
  },
  '.ag-ltr .ag-row-group-indent-23': {paddingLeft: '644px'},
  '.ag-rtl .ag-row-group-indent-23': {paddingRight: '644px'},
  '.ag-ltr .ag-row-level-23 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-23 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-24': {
    paddingLeft: '690px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-24': {
    paddingRight: '690px',
  },
  '.ag-ltr .ag-row-group-indent-24': {paddingLeft: '672px'},
  '.ag-rtl .ag-row-group-indent-24': {paddingRight: '672px'},
  '.ag-ltr .ag-row-level-24 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-24 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-25': {
    paddingLeft: '718px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-25': {
    paddingRight: '718px',
  },
  '.ag-ltr .ag-row-group-indent-25': {paddingLeft: '700px'},
  '.ag-rtl .ag-row-group-indent-25': {paddingRight: '700px'},
  '.ag-ltr .ag-row-level-25 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-25 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-26': {
    paddingLeft: '746px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-26': {
    paddingRight: '746px',
  },
  '.ag-ltr .ag-row-group-indent-26': {paddingLeft: '728px'},
  '.ag-rtl .ag-row-group-indent-26': {paddingRight: '728px'},
  '.ag-ltr .ag-row-level-26 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-26 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-27': {
    paddingLeft: '774px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-27': {
    paddingRight: '774px',
  },
  '.ag-ltr .ag-row-group-indent-27': {paddingLeft: '756px'},
  '.ag-rtl .ag-row-group-indent-27': {paddingRight: '756px'},
  '.ag-ltr .ag-row-level-27 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-27 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-28': {
    paddingLeft: '802px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-28': {
    paddingRight: '802px',
  },
  '.ag-ltr .ag-row-group-indent-28': {paddingLeft: '784px'},
  '.ag-rtl .ag-row-group-indent-28': {paddingRight: '784px'},
  '.ag-ltr .ag-row-level-28 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-28 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-29': {
    paddingLeft: '830px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-29': {
    paddingRight: '830px',
  },
  '.ag-ltr .ag-row-group-indent-29': {paddingLeft: '812px'},
  '.ag-rtl .ag-row-group-indent-29': {paddingRight: '812px'},
  '.ag-ltr .ag-row-level-29 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-29 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-30': {
    paddingLeft: '858px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-30': {
    paddingRight: '858px',
  },
  '.ag-ltr .ag-row-group-indent-30': {paddingLeft: '840px'},
  '.ag-rtl .ag-row-group-indent-30': {paddingRight: '840px'},
  '.ag-ltr .ag-row-level-30 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-30 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-31': {
    paddingLeft: '886px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-31': {
    paddingRight: '886px',
  },
  '.ag-ltr .ag-row-group-indent-31': {paddingLeft: '868px'},
  '.ag-rtl .ag-row-group-indent-31': {paddingRight: '868px'},
  '.ag-ltr .ag-row-level-31 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-31 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-32': {
    paddingLeft: '914px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-32': {
    paddingRight: '914px',
  },
  '.ag-ltr .ag-row-group-indent-32': {paddingLeft: '896px'},
  '.ag-rtl .ag-row-group-indent-32': {paddingRight: '896px'},
  '.ag-ltr .ag-row-level-32 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-32 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-33': {
    paddingLeft: '942px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-33': {
    paddingRight: '942px',
  },
  '.ag-ltr .ag-row-group-indent-33': {paddingLeft: '924px'},
  '.ag-rtl .ag-row-group-indent-33': {paddingRight: '924px'},
  '.ag-ltr .ag-row-level-33 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-33 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-34': {
    paddingLeft: '970px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-34': {
    paddingRight: '970px',
  },
  '.ag-ltr .ag-row-group-indent-34': {paddingLeft: '952px'},
  '.ag-rtl .ag-row-group-indent-34': {paddingRight: '952px'},
  '.ag-ltr .ag-row-level-34 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-34 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-35': {
    paddingLeft: '998px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-35': {
    paddingRight: '998px',
  },
  '.ag-ltr .ag-row-group-indent-35': {paddingLeft: '980px'},
  '.ag-rtl .ag-row-group-indent-35': {paddingRight: '980px'},
  '.ag-ltr .ag-row-level-35 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-35 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-36': {
    paddingLeft: '1026px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-36': {
    paddingRight: '1026px',
  },
  '.ag-ltr .ag-row-group-indent-36': {paddingLeft: '1008px'},
  '.ag-rtl .ag-row-group-indent-36': {
    paddingRight: '1008px',
  },
  '.ag-ltr .ag-row-level-36 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-36 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-37': {
    paddingLeft: '1054px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-37': {
    paddingRight: '1054px',
  },
  '.ag-ltr .ag-row-group-indent-37': {paddingLeft: '1036px'},
  '.ag-rtl .ag-row-group-indent-37': {
    paddingRight: '1036px',
  },
  '.ag-ltr .ag-row-level-37 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-37 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-38': {
    paddingLeft: '1082px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-38': {
    paddingRight: '1082px',
  },
  '.ag-ltr .ag-row-group-indent-38': {paddingLeft: '1064px'},
  '.ag-rtl .ag-row-group-indent-38': {
    paddingRight: '1064px',
  },
  '.ag-ltr .ag-row-level-38 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-38 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-39': {
    paddingLeft: '1110px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-39': {
    paddingRight: '1110px',
  },
  '.ag-ltr .ag-row-group-indent-39': {paddingLeft: '1092px'},
  '.ag-rtl .ag-row-group-indent-39': {
    paddingRight: '1092px',
  },
  '.ag-ltr .ag-row-level-39 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-39 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-40': {
    paddingLeft: '1138px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-40': {
    paddingRight: '1138px',
  },
  '.ag-ltr .ag-row-group-indent-40': {paddingLeft: '1120px'},
  '.ag-rtl .ag-row-group-indent-40': {
    paddingRight: '1120px',
  },
  '.ag-ltr .ag-row-level-40 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-40 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-41': {
    paddingLeft: '1166px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-41': {
    paddingRight: '1166px',
  },
  '.ag-ltr .ag-row-group-indent-41': {paddingLeft: '1148px'},
  '.ag-rtl .ag-row-group-indent-41': {
    paddingRight: '1148px',
  },
  '.ag-ltr .ag-row-level-41 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-41 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-42': {
    paddingLeft: '1194px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-42': {
    paddingRight: '1194px',
  },
  '.ag-ltr .ag-row-group-indent-42': {paddingLeft: '1176px'},
  '.ag-rtl .ag-row-group-indent-42': {
    paddingRight: '1176px',
  },
  '.ag-ltr .ag-row-level-42 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-42 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-43': {
    paddingLeft: '1222px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-43': {
    paddingRight: '1222px',
  },
  '.ag-ltr .ag-row-group-indent-43': {paddingLeft: '1204px'},
  '.ag-rtl .ag-row-group-indent-43': {
    paddingRight: '1204px',
  },
  '.ag-ltr .ag-row-level-43 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-43 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-44': {
    paddingLeft: '1250px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-44': {
    paddingRight: '1250px',
  },
  '.ag-ltr .ag-row-group-indent-44': {paddingLeft: '1232px'},
  '.ag-rtl .ag-row-group-indent-44': {
    paddingRight: '1232px',
  },
  '.ag-ltr .ag-row-level-44 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-44 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-45': {
    paddingLeft: '1278px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-45': {
    paddingRight: '1278px',
  },
  '.ag-ltr .ag-row-group-indent-45': {paddingLeft: '1260px'},
  '.ag-rtl .ag-row-group-indent-45': {
    paddingRight: '1260px',
  },
  '.ag-ltr .ag-row-level-45 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-45 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-46': {
    paddingLeft: '1306px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-46': {
    paddingRight: '1306px',
  },
  '.ag-ltr .ag-row-group-indent-46': {paddingLeft: '1288px'},
  '.ag-rtl .ag-row-group-indent-46': {
    paddingRight: '1288px',
  },
  '.ag-ltr .ag-row-level-46 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-46 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-47': {
    paddingLeft: '1334px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-47': {
    paddingRight: '1334px',
  },
  '.ag-ltr .ag-row-group-indent-47': {paddingLeft: '1316px'},
  '.ag-rtl .ag-row-group-indent-47': {
    paddingRight: '1316px',
  },
  '.ag-ltr .ag-row-level-47 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-47 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-48': {
    paddingLeft: '1362px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-48': {
    paddingRight: '1362px',
  },
  '.ag-ltr .ag-row-group-indent-48': {paddingLeft: '1344px'},
  '.ag-rtl .ag-row-group-indent-48': {
    paddingRight: '1344px',
  },
  '.ag-ltr .ag-row-level-48 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-48 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-49': {
    paddingLeft: '1390px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-49': {
    paddingRight: '1390px',
  },
  '.ag-ltr .ag-row-group-indent-49': {paddingLeft: '1372px'},
  '.ag-rtl .ag-row-group-indent-49': {
    paddingRight: '1372px',
  },
  '.ag-ltr .ag-row-level-49 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-49 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-50': {
    paddingLeft: '1418px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-50': {
    paddingRight: '1418px',
  },
  '.ag-ltr .ag-row-group-indent-50': {paddingLeft: '1400px'},
  '.ag-rtl .ag-row-group-indent-50': {
    paddingRight: '1400px',
  },
  '.ag-ltr .ag-row-level-50 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-50 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-51': {
    paddingLeft: '1446px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-51': {
    paddingRight: '1446px',
  },
  '.ag-ltr .ag-row-group-indent-51': {paddingLeft: '1428px'},
  '.ag-rtl .ag-row-group-indent-51': {
    paddingRight: '1428px',
  },
  '.ag-ltr .ag-row-level-51 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-51 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-52': {
    paddingLeft: '1474px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-52': {
    paddingRight: '1474px',
  },
  '.ag-ltr .ag-row-group-indent-52': {paddingLeft: '1456px'},
  '.ag-rtl .ag-row-group-indent-52': {
    paddingRight: '1456px',
  },
  '.ag-ltr .ag-row-level-52 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-52 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-53': {
    paddingLeft: '1502px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-53': {
    paddingRight: '1502px',
  },
  '.ag-ltr .ag-row-group-indent-53': {paddingLeft: '1484px'},
  '.ag-rtl .ag-row-group-indent-53': {
    paddingRight: '1484px',
  },
  '.ag-ltr .ag-row-level-53 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-53 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-54': {
    paddingLeft: '1530px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-54': {
    paddingRight: '1530px',
  },
  '.ag-ltr .ag-row-group-indent-54': {paddingLeft: '1512px'},
  '.ag-rtl .ag-row-group-indent-54': {
    paddingRight: '1512px',
  },
  '.ag-ltr .ag-row-level-54 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-54 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-55': {
    paddingLeft: '1558px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-55': {
    paddingRight: '1558px',
  },
  '.ag-ltr .ag-row-group-indent-55': {paddingLeft: '1540px'},
  '.ag-rtl .ag-row-group-indent-55': {
    paddingRight: '1540px',
  },
  '.ag-ltr .ag-row-level-55 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-55 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-56': {
    paddingLeft: '1586px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-56': {
    paddingRight: '1586px',
  },
  '.ag-ltr .ag-row-group-indent-56': {paddingLeft: '1568px'},
  '.ag-rtl .ag-row-group-indent-56': {
    paddingRight: '1568px',
  },
  '.ag-ltr .ag-row-level-56 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-56 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-57': {
    paddingLeft: '1614px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-57': {
    paddingRight: '1614px',
  },
  '.ag-ltr .ag-row-group-indent-57': {paddingLeft: '1596px'},
  '.ag-rtl .ag-row-group-indent-57': {
    paddingRight: '1596px',
  },
  '.ag-ltr .ag-row-level-57 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-57 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-58': {
    paddingLeft: '1642px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-58': {
    paddingRight: '1642px',
  },
  '.ag-ltr .ag-row-group-indent-58': {paddingLeft: '1624px'},
  '.ag-rtl .ag-row-group-indent-58': {
    paddingRight: '1624px',
  },
  '.ag-ltr .ag-row-level-58 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-58 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-59': {
    paddingLeft: '1670px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-59': {
    paddingRight: '1670px',
  },
  '.ag-ltr .ag-row-group-indent-59': {paddingLeft: '1652px'},
  '.ag-rtl .ag-row-group-indent-59': {
    paddingRight: '1652px',
  },
  '.ag-ltr .ag-row-level-59 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-59 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-60': {
    paddingLeft: '1698px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-60': {
    paddingRight: '1698px',
  },
  '.ag-ltr .ag-row-group-indent-60': {paddingLeft: '1680px'},
  '.ag-rtl .ag-row-group-indent-60': {
    paddingRight: '1680px',
  },
  '.ag-ltr .ag-row-level-60 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-60 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-61': {
    paddingLeft: '1726px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-61': {
    paddingRight: '1726px',
  },
  '.ag-ltr .ag-row-group-indent-61': {paddingLeft: '1708px'},
  '.ag-rtl .ag-row-group-indent-61': {
    paddingRight: '1708px',
  },
  '.ag-ltr .ag-row-level-61 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-61 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-62': {
    paddingLeft: '1754px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-62': {
    paddingRight: '1754px',
  },
  '.ag-ltr .ag-row-group-indent-62': {paddingLeft: '1736px'},
  '.ag-rtl .ag-row-group-indent-62': {
    paddingRight: '1736px',
  },
  '.ag-ltr .ag-row-level-62 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-62 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-63': {
    paddingLeft: '1782px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-63': {
    paddingRight: '1782px',
  },
  '.ag-ltr .ag-row-group-indent-63': {paddingLeft: '1764px'},
  '.ag-rtl .ag-row-group-indent-63': {
    paddingRight: '1764px',
  },
  '.ag-ltr .ag-row-level-63 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-63 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-64': {
    paddingLeft: '1810px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-64': {
    paddingRight: '1810px',
  },
  '.ag-ltr .ag-row-group-indent-64': {paddingLeft: '1792px'},
  '.ag-rtl .ag-row-group-indent-64': {
    paddingRight: '1792px',
  },
  '.ag-ltr .ag-row-level-64 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-64 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-65': {
    paddingLeft: '1838px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-65': {
    paddingRight: '1838px',
  },
  '.ag-ltr .ag-row-group-indent-65': {paddingLeft: '1820px'},
  '.ag-rtl .ag-row-group-indent-65': {
    paddingRight: '1820px',
  },
  '.ag-ltr .ag-row-level-65 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-65 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-66': {
    paddingLeft: '1866px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-66': {
    paddingRight: '1866px',
  },
  '.ag-ltr .ag-row-group-indent-66': {paddingLeft: '1848px'},
  '.ag-rtl .ag-row-group-indent-66': {
    paddingRight: '1848px',
  },
  '.ag-ltr .ag-row-level-66 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-66 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-67': {
    paddingLeft: '1894px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-67': {
    paddingRight: '1894px',
  },
  '.ag-ltr .ag-row-group-indent-67': {paddingLeft: '1876px'},
  '.ag-rtl .ag-row-group-indent-67': {
    paddingRight: '1876px',
  },
  '.ag-ltr .ag-row-level-67 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-67 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-68': {
    paddingLeft: '1922px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-68': {
    paddingRight: '1922px',
  },
  '.ag-ltr .ag-row-group-indent-68': {paddingLeft: '1904px'},
  '.ag-rtl .ag-row-group-indent-68': {
    paddingRight: '1904px',
  },
  '.ag-ltr .ag-row-level-68 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-68 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-69': {
    paddingLeft: '1950px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-69': {
    paddingRight: '1950px',
  },
  '.ag-ltr .ag-row-group-indent-69': {paddingLeft: '1932px'},
  '.ag-rtl .ag-row-group-indent-69': {
    paddingRight: '1932px',
  },
  '.ag-ltr .ag-row-level-69 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-69 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-70': {
    paddingLeft: '1978px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-70': {
    paddingRight: '1978px',
  },
  '.ag-ltr .ag-row-group-indent-70': {paddingLeft: '1960px'},
  '.ag-rtl .ag-row-group-indent-70': {
    paddingRight: '1960px',
  },
  '.ag-ltr .ag-row-level-70 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-70 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-71': {
    paddingLeft: '2006px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-71': {
    paddingRight: '2006px',
  },
  '.ag-ltr .ag-row-group-indent-71': {paddingLeft: '1988px'},
  '.ag-rtl .ag-row-group-indent-71': {
    paddingRight: '1988px',
  },
  '.ag-ltr .ag-row-level-71 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-71 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-72': {
    paddingLeft: '2034px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-72': {
    paddingRight: '2034px',
  },
  '.ag-ltr .ag-row-group-indent-72': {paddingLeft: '2016px'},
  '.ag-rtl .ag-row-group-indent-72': {
    paddingRight: '2016px',
  },
  '.ag-ltr .ag-row-level-72 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-72 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-73': {
    paddingLeft: '2062px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-73': {
    paddingRight: '2062px',
  },
  '.ag-ltr .ag-row-group-indent-73': {paddingLeft: '2044px'},
  '.ag-rtl .ag-row-group-indent-73': {
    paddingRight: '2044px',
  },
  '.ag-ltr .ag-row-level-73 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-73 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-74': {
    paddingLeft: '2090px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-74': {
    paddingRight: '2090px',
  },
  '.ag-ltr .ag-row-group-indent-74': {paddingLeft: '2072px'},
  '.ag-rtl .ag-row-group-indent-74': {
    paddingRight: '2072px',
  },
  '.ag-ltr .ag-row-level-74 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-74 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-75': {
    paddingLeft: '2118px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-75': {
    paddingRight: '2118px',
  },
  '.ag-ltr .ag-row-group-indent-75': {paddingLeft: '2100px'},
  '.ag-rtl .ag-row-group-indent-75': {
    paddingRight: '2100px',
  },
  '.ag-ltr .ag-row-level-75 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-75 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-76': {
    paddingLeft: '2146px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-76': {
    paddingRight: '2146px',
  },
  '.ag-ltr .ag-row-group-indent-76': {paddingLeft: '2128px'},
  '.ag-rtl .ag-row-group-indent-76': {
    paddingRight: '2128px',
  },
  '.ag-ltr .ag-row-level-76 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-76 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-77': {
    paddingLeft: '2174px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-77': {
    paddingRight: '2174px',
  },
  '.ag-ltr .ag-row-group-indent-77': {paddingLeft: '2156px'},
  '.ag-rtl .ag-row-group-indent-77': {
    paddingRight: '2156px',
  },
  '.ag-ltr .ag-row-level-77 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-77 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-78': {
    paddingLeft: '2202px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-78': {
    paddingRight: '2202px',
  },
  '.ag-ltr .ag-row-group-indent-78': {paddingLeft: '2184px'},
  '.ag-rtl .ag-row-group-indent-78': {
    paddingRight: '2184px',
  },
  '.ag-ltr .ag-row-level-78 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-78 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-79': {
    paddingLeft: '2230px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-79': {
    paddingRight: '2230px',
  },
  '.ag-ltr .ag-row-group-indent-79': {paddingLeft: '2212px'},
  '.ag-rtl .ag-row-group-indent-79': {
    paddingRight: '2212px',
  },
  '.ag-ltr .ag-row-level-79 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-79 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-80': {
    paddingLeft: '2258px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-80': {
    paddingRight: '2258px',
  },
  '.ag-ltr .ag-row-group-indent-80': {paddingLeft: '2240px'},
  '.ag-rtl .ag-row-group-indent-80': {
    paddingRight: '2240px',
  },
  '.ag-ltr .ag-row-level-80 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-80 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-81': {
    paddingLeft: '2286px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-81': {
    paddingRight: '2286px',
  },
  '.ag-ltr .ag-row-group-indent-81': {paddingLeft: '2268px'},
  '.ag-rtl .ag-row-group-indent-81': {
    paddingRight: '2268px',
  },
  '.ag-ltr .ag-row-level-81 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-81 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-82': {
    paddingLeft: '2314px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-82': {
    paddingRight: '2314px',
  },
  '.ag-ltr .ag-row-group-indent-82': {paddingLeft: '2296px'},
  '.ag-rtl .ag-row-group-indent-82': {
    paddingRight: '2296px',
  },
  '.ag-ltr .ag-row-level-82 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-82 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-83': {
    paddingLeft: '2342px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-83': {
    paddingRight: '2342px',
  },
  '.ag-ltr .ag-row-group-indent-83': {paddingLeft: '2324px'},
  '.ag-rtl .ag-row-group-indent-83': {
    paddingRight: '2324px',
  },
  '.ag-ltr .ag-row-level-83 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-83 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-84': {
    paddingLeft: '2370px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-84': {
    paddingRight: '2370px',
  },
  '.ag-ltr .ag-row-group-indent-84': {paddingLeft: '2352px'},
  '.ag-rtl .ag-row-group-indent-84': {
    paddingRight: '2352px',
  },
  '.ag-ltr .ag-row-level-84 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-84 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-85': {
    paddingLeft: '2398px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-85': {
    paddingRight: '2398px',
  },
  '.ag-ltr .ag-row-group-indent-85': {paddingLeft: '2380px'},
  '.ag-rtl .ag-row-group-indent-85': {
    paddingRight: '2380px',
  },
  '.ag-ltr .ag-row-level-85 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-85 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-86': {
    paddingLeft: '2426px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-86': {
    paddingRight: '2426px',
  },
  '.ag-ltr .ag-row-group-indent-86': {paddingLeft: '2408px'},
  '.ag-rtl .ag-row-group-indent-86': {
    paddingRight: '2408px',
  },
  '.ag-ltr .ag-row-level-86 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-86 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-87': {
    paddingLeft: '2454px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-87': {
    paddingRight: '2454px',
  },
  '.ag-ltr .ag-row-group-indent-87': {paddingLeft: '2436px'},
  '.ag-rtl .ag-row-group-indent-87': {
    paddingRight: '2436px',
  },
  '.ag-ltr .ag-row-level-87 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-87 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-88': {
    paddingLeft: '2482px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-88': {
    paddingRight: '2482px',
  },
  '.ag-ltr .ag-row-group-indent-88': {paddingLeft: '2464px'},
  '.ag-rtl .ag-row-group-indent-88': {
    paddingRight: '2464px',
  },
  '.ag-ltr .ag-row-level-88 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-88 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-89': {
    paddingLeft: '2510px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-89': {
    paddingRight: '2510px',
  },
  '.ag-ltr .ag-row-group-indent-89': {paddingLeft: '2492px'},
  '.ag-rtl .ag-row-group-indent-89': {
    paddingRight: '2492px',
  },
  '.ag-ltr .ag-row-level-89 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-89 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-90': {
    paddingLeft: '2538px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-90': {
    paddingRight: '2538px',
  },
  '.ag-ltr .ag-row-group-indent-90': {paddingLeft: '2520px'},
  '.ag-rtl .ag-row-group-indent-90': {
    paddingRight: '2520px',
  },
  '.ag-ltr .ag-row-level-90 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-90 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-91': {
    paddingLeft: '2566px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-91': {
    paddingRight: '2566px',
  },
  '.ag-ltr .ag-row-group-indent-91': {paddingLeft: '2548px'},
  '.ag-rtl .ag-row-group-indent-91': {
    paddingRight: '2548px',
  },
  '.ag-ltr .ag-row-level-91 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-91 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-92': {
    paddingLeft: '2594px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-92': {
    paddingRight: '2594px',
  },
  '.ag-ltr .ag-row-group-indent-92': {paddingLeft: '2576px'},
  '.ag-rtl .ag-row-group-indent-92': {
    paddingRight: '2576px',
  },
  '.ag-ltr .ag-row-level-92 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-92 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-93': {
    paddingLeft: '2622px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-93': {
    paddingRight: '2622px',
  },
  '.ag-ltr .ag-row-group-indent-93': {paddingLeft: '2604px'},
  '.ag-rtl .ag-row-group-indent-93': {
    paddingRight: '2604px',
  },
  '.ag-ltr .ag-row-level-93 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-93 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-94': {
    paddingLeft: '2650px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-94': {
    paddingRight: '2650px',
  },
  '.ag-ltr .ag-row-group-indent-94': {paddingLeft: '2632px'},
  '.ag-rtl .ag-row-group-indent-94': {
    paddingRight: '2632px',
  },
  '.ag-ltr .ag-row-level-94 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-94 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-95': {
    paddingLeft: '2678px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-95': {
    paddingRight: '2678px',
  },
  '.ag-ltr .ag-row-group-indent-95': {paddingLeft: '2660px'},
  '.ag-rtl .ag-row-group-indent-95': {
    paddingRight: '2660px',
  },
  '.ag-ltr .ag-row-level-95 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-95 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-96': {
    paddingLeft: '2706px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-96': {
    paddingRight: '2706px',
  },
  '.ag-ltr .ag-row-group-indent-96': {paddingLeft: '2688px'},
  '.ag-rtl .ag-row-group-indent-96': {
    paddingRight: '2688px',
  },
  '.ag-ltr .ag-row-level-96 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-96 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-97': {
    paddingLeft: '2734px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-97': {
    paddingRight: '2734px',
  },
  '.ag-ltr .ag-row-group-indent-97': {paddingLeft: '2716px'},
  '.ag-rtl .ag-row-group-indent-97': {
    paddingRight: '2716px',
  },
  '.ag-ltr .ag-row-level-97 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-97 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-98': {
    paddingLeft: '2762px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-98': {
    paddingRight: '2762px',
  },
  '.ag-ltr .ag-row-group-indent-98': {paddingLeft: '2744px'},
  '.ag-rtl .ag-row-group-indent-98': {
    paddingRight: '2744px',
  },
  '.ag-ltr .ag-row-level-98 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-98 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-99': {
    paddingLeft: '2790px',
  },
  '.ag-rtl .ag-row > .ag-cell-wrapper.ag-row-group-indent-99': {
    paddingRight: '2790px',
  },
  '.ag-ltr .ag-row-group-indent-99': {paddingLeft: '2772px'},
  '.ag-rtl .ag-row-group-indent-99': {
    paddingRight: '2772px',
  },
  '.ag-ltr .ag-row-level-99 .ag-pivot-leaf-group': {
    marginLeft: '28px',
  },
  '.ag-rtl .ag-row-level-99 .ag-pivot-leaf-group': {
    marginRight: '28px',
  },
  '.ag-ltr .ag-row-group-leaf-indent': {marginLeft: '28px'},
  '.ag-rtl .ag-row-group-leaf-indent': {marginRight: '28px'},
};
