import {isFeatureEnabled} from 'feature-flags';
import {Alert, Button, TabProps, TabsHeader, showNotification} from 'platform/components';
import {Box, Grid, GridItem, Hide, Right, Show, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {Children, FC, PropsWithChildren, useCallback, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-final-form';
import {Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {last, values} from 'ramda';

import {flattenObj} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {FullScreenModal, formatDocumentTitle, usePermissions} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

import {useRouter} from '../../../hooks/useRouter';
import {selectSalesVehicleData} from '../../../store/carDetails/selectors';
import {useVehicleCreateContext} from '../../VehicleCreateContext/hooks/useVehicleCreateContext';
import {findErrorTabIndex} from '../utils/findIndexErrorTab';

const TABS = ['basic-info', 'vehicle-data', 'features', 'vehicle-photo'];

type VehicleFormModalProps = {
  makeSaleVehicleAvailableForSale?: boolean;
  handleSubmit: () => void;
  triggerScroll?: () => void;
};

export const VehicleFormModal: FC<PropsWithChildren<VehicleFormModalProps>> = ({
  children,
  handleSubmit,
  triggerScroll,
  ...props
}) => {
  const {getState} = useForm();
  const {submitting} = getState();
  const dispatch = useDispatch();
  const router = useRouter();
  const location = useLocation();
  const [makeSaleVehicleAvailableForSale, setMakeVehicleAvailableForSale] = useState(
    props.makeSaleVehicleAvailableForSale ?? false
  );

  const [canReadBusinessCase] = usePermissions({permissionKeys: ['businessCaseRead']});

  const contentRef = useRef<HTMLDivElement | null>(null);

  const vehicleDetail = useSelector(selectSalesVehicleData);
  const childrenElements = Children.toArray(children);

  const routerTab = router.params?.tab;
  const isEdit = Boolean(router.params?.id) && !/business-case/.test(location.pathname);
  const {tabIndex, setTabIndex, onClose, scrollingAreaRef} = useVehicleCreateContext();

  const getTabsDefaultIndex = useCallback(() => {
    if (!routerTab) {
      return 0;
    }
    const tabIdx = TABS.findIndex((tab) => tab === routerTab);
    return tabIdx > -1 ? tabIdx : 0;
  }, [routerTab]);

  const handleTabChange = useCallback(
    (id: string) => {
      setTabIndex(parseFloat(id));

      if (triggerScroll) {
        setTimeout(triggerScroll, 1);
      }
      // Timeout is a workaround so the trigger actually happens after the tabs are rendered.
      // For some reason it doesn't happen without the setTimeout.
    },
    [setTabIndex, triggerScroll]
  );

  const handleBackPreviousPage = () => {
    if (onClose) {
      onClose();
    } else {
      router.back();
    }
  };

  const handleSubmitEvent = useCallback(
    (errors: Record<string, string>) => {
      const error = findErrorTabIndex(errors, true);
      if (typeof error === 'string') {
        handleTabChange(parseInt(error).toString());
      }

      const errorElement = document.querySelector(
        `[data-testid="field-${Object.keys(errors)[0]}"]`
      );
      errorElement?.scrollIntoView({behavior: 'smooth'});

      if (Object.keys(errors).length) {
        showNotification.error(values(flattenObj(errors)).join(', '));
      }
      handleSubmit();
    },
    [dispatch, handleSubmit, handleTabChange]
  );

  useEffect(() => {
    const routerTabIndex = getTabsDefaultIndex();

    setTabIndex(routerTabIndex);
    if (triggerScroll) {
      setTimeout(triggerScroll, 1);
    }
    // Timeout is a workaround so the trigger actually happens after the tabs are rendered.
    // For some reason it doesn't happen without the setTimeout.
  }, [getTabsDefaultIndex]);

  const handleOnContinueClick = () => {
    handleTabChange((tabIndex + 1).toString());
    contentRef.current?.scrollIntoView();
  };

  const tabs = buildArray<TabProps>([
    {
      id: '0',
      title: i18n.t('general.labels.basicInformation'),
      content: childrenElements[0],
      'data-testid': testIds.vehicles.create('header-basicInformation'),
    },
    {
      id: '1',
      title: i18n.t('entity.vehicle.labels.vehicleData'),
      content: childrenElements[1],
      'data-testid': testIds.vehicles.create('header-vehicleData'),
    },
    {
      id: '2',
      title: i18n.t('entity.vehicle.labels.features'),
      content: childrenElements[2],
      'data-testid': testIds.vehicles.create('header-features'),
    },
  ])
    .whenNot(isEdit, {
      id: '3',
      title: i18n.t('entity.vehicle.labels.photos'),
      content: childrenElements[3],
      'data-testid': testIds.vehicles.create('header-photos'),
    })
    .when(!isEdit && isFeatureEnabled(featureFlags.ACL_SALES) && canReadBusinessCase, {
      id: '4',
      title: i18n.t('entity.vehicle.labels.vehicleCondition'),
      content: childrenElements[4],
      'data-testid': testIds.vehicles.create('header-condition'),
    });

  const isLastTabActive = last(tabs)?.id === tabIndex?.toString();

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle(
            isEdit ? (vehicleDetail?.title ?? '') : i18n.t('entity.vehicle.labels.new'),
            i18n.t('entity.vehicle.labels.pageTitle'),
            i18n.t('page.salesSettings.title')
          )}
        </title>
      </Helmet>

      <FullScreenModal
        headline={
          isEdit
            ? i18n.t('entity.vehicle.labels.editVehicle')
            : i18n.t('entity.vehicle.labels.createVehicle')
        }
        actions={[
          {
            variant: 'secondary',
            title: i18n.t('general.actions.discard'),
            onClick: handleBackPreviousPage,
            'data-testid': testIds.vehicles.create('header-discard-action'),
          },
          {
            variant: 'primary',
            isLoading: submitting,
            title: isEdit
              ? i18n.t('general.actions.save')
              : i18n.t('entity.vehicle.actions.createVehicle'),
            'data-testid': testIds.vehicles.create(isEdit ? 'save-action' : 'create-action'),
            onClick: () => handleSubmitEvent(getState().errors ?? {}),
          },
        ]}
      >
        <div ref={contentRef}>
          <VStack>
            <Header>
              <TabListWrapper>
                <TabsHeader
                  tabs={tabs}
                  activeTabId={tabIndex.toString()}
                  onChange={handleTabChange}
                  data-testid={testIds.vehicles.create('header-tabList')}
                />
              </TabListWrapper>
            </Header>
            <Show when={makeSaleVehicleAvailableForSale}>
              <Alert
                title={i18n.t('entity.vehicle.notifications.makeVehicleAvailableForSale')}
                onClose={() => setMakeVehicleAvailableForSale(false)}
                data-testid={testIds.vehicles.create('alert-makeVehicleAvailableForSale')}
              />
            </Show>
            <Box flex={1} padding={4} ref={scrollingAreaRef}>
              <Grid columns={12}>
                <GridItem span={12} data-testid={tabs[tabIndex]?.['data-testid']}>
                  {tabs[tabIndex]?.content}
                </GridItem>
              </Grid>

              <Hide when={isLastTabActive}>
                <Right>
                  <Box paddingTop={14}>
                    <Button
                      onClick={handleOnContinueClick}
                      data-testid={testIds.vehicles.create('header-createVehicle-continue')}
                      title={i18n.t('general.actions.continue')}
                    />
                  </Box>
                </Right>
              </Hide>
            </Box>
          </VStack>
        </div>
      </FullScreenModal>
    </>
  );
};

export const Header = styled.div`
  display: flex;
  width: 100%;
  background: ${({theme}) => theme.colors.palettes.white[10][100]};
  ${({theme}) => `border-bottom: 1px solid ${theme.colors.palettes.neutral[40][100]};`};
  align-items: center;
  justify-content: space-between;
`;

export const TabListWrapper = styled.div`
  padding: 0 ${({theme}) => theme.getSize(4)};
`;
