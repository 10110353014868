import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

import {getEvaluationTranslations} from '../constants/evaluation';

export function getEvaluationTooltipLabel(evaluation: string | Nullish): string {
  return evaluation
    ? getEvaluationTranslations()[Number(evaluation)]
    : i18n.t('entity.condition.labels.statusUnknown');
}
