import {Text, TextSize, ThemeColorTextPath} from 'platform/foundation';

import Highlighter from 'react-highlight-words';

import {isNotNil, not} from 'ramda';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {EMPTY_PLACEHOLDER} from '../../../constants/placeholders';

interface TextHighlighterProps extends TestIdProps {
  textToHighlight: string | Nullish;
  searchWords: string | Nullish;
  size: TextSize;
  color?: ThemeColorTextPath;
}

export function TextHighlighter(props: TextHighlighterProps) {
  const isMissmatch =
    isNotNil(props.textToHighlight) &&
    isNotNil(props.searchWords) &&
    not(props.textToHighlight.toUpperCase().includes(props.searchWords.toUpperCase()));

  const isExactMatch =
    isNotNil(props.textToHighlight) &&
    isNotNil(props.searchWords) &&
    props.searchWords?.toUpperCase() === props.textToHighlight.toUpperCase();

  return isMissmatch ? (
    <Text alternative size={props.size} color="warning" data-testid={suffixTestId('value', props)}>
      {props.textToHighlight || EMPTY_PLACEHOLDER}
    </Text>
  ) : (
    <Text color={props.color} size={props.size} data-testid={suffixTestId('value', props)}>
      <Highlighter
        textToHighlight={props.textToHighlight || EMPTY_PLACEHOLDER}
        autoEscape
        searchWords={[props.searchWords || '']}
        highlightTag={({children}) => (
          <Text
            size={props.size}
            alternative
            inline
            color={isExactMatch ? 'success' : undefined}
            data-testid={suffixTestId('highlighted-value', props)}
          >
            {children}
          </Text>
        )}
      />
    </Text>
  );
}
