import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Option,
  showNotification,
  Tooltip,
} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useRef, useState} from 'react';

import {isNil, isNotNil} from 'ramda';
import {isNotNilOrEmpty, isString} from 'ramda-adjunct';

import {
  BaseSupplierOrderVariantSupplier,
  GetSupplierOrderVariantsResponse,
  PostSupplierOrderRequest,
  useBulkPostSupplierOrderMutation,
  useGetSupplierOrderVariantsQuery,
  useGetSuppliersQuery,
  useLazyGetSupplierOrderTypeQuery,
  useLazyGetSupplierOrderVariantSuppliersQuery,
  usePostSupplierOrderMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, Nullish, suffixTestId, TestIdProps, useNavigate, yupString} from 'shared';

import {getOptionsFromSupplierOrderVariants} from '../../../utils/getOptionsFromSupplierOrderVariants';
import {getOptionsFromSuppliersByOrderVariant} from '../../../utils/getOptionsFromSuppliersByOrderVariant';

type PrevValuesType = {
  orderVariantId?: string;
  supplierId?: string;
};

type FetchSupplierOrderType = {
  supplierId: string;
  supplierOrderVariantId: string;
};

type SupplierOrderForm = {
  orderVariantId: string | Nullish;
  supplierId: string | Nullish;
  orderType: string | Nullish;
};

export interface CreateSupplierOrderFormProps extends TestIdProps {
  itemId?: string[];
  onClose: () => void;
}

export function CreateSupplierOrderForm(props: CreateSupplierOrderFormProps) {
  const navigate = useNavigate();
  const prevValues = useRef<PrevValuesType>({});

  const [supplierOrderVariantSuppliers, setSupplierOrderVariantSuppliers] = useState<
    BaseSupplierOrderVariantSupplier[] | Nullish
  >(null);

  const [orderVariant, setOrderVariant] = useState<GetSupplierOrderVariantsResponse | Nullish>(
    null
  );
  const [orderTypeOptions, setOrderTypeOptions] = useState<Option[]>([]);

  const [createSupplierOrderWithItems, {isLoading: isCreatingSupplierOrderWithItems}] =
    useBulkPostSupplierOrderMutation();
  const [createSupplierOrder, {isLoading: isCreatingSupplierOrder}] =
    usePostSupplierOrderMutation();
  const [getSupplierOrderVariantSuppliers, {isLoading: isSupplierOrderVariantSuppliersLoading}] =
    useLazyGetSupplierOrderVariantSuppliersQuery();
  const [getSupplierOrderType, {isLoading: isSupplierOrderTypeLoading}] =
    useLazyGetSupplierOrderTypeQuery();

  const {
    data: supplierOrderVariants,
    isLoading: areSupplierOrderVariantsLoading,
    isError: hasSupplierOrderVariantsLoading,
  } = useGetSupplierOrderVariantsQuery();

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const isCreateLoading = isCreatingSupplierOrderWithItems || isCreatingSupplierOrder;

  const isLoading = areSupplierOrderVariantsLoading || areSuppliersLoading;

  const isError = hasSupplierOrderVariantsLoading || hasSuppliersError;

  const isOrderTypeRequired = orderVariant?.isOrderUseIntegration
    ? isNotNilOrEmpty(orderTypeOptions)
    : false;

  const createSupplierOrderByFormData = (values: SupplierOrderForm) => {
    const request: PostSupplierOrderRequest = {
      orderVariantId: values.orderVariantId ?? '',
      supplierId: values.supplierId ?? '',
      orderType: values.orderType,
    };

    const hasItemId = isNotNil(props.itemId);

    return hasItemId
      ? createSupplierOrderWithItems({requestItemId: props.itemId ?? [], ...request})
      : createSupplierOrder(request);
  };

  const handleSubmit: FormSubmitHandler<SupplierOrderForm> = async (values) => {
    await createSupplierOrderByFormData(values)
      .unwrap()
      .then((data) => {
        navigate(
          composePath(warehouseRoutes.supplierOrderDetailOverview, {
            params: {
              id: data.orderId,
            },
          })
        );
      })
      .then(() => {
        showNotification.success(i18n.t('page.warehouse.notification.supplierOrderCreated'));
      })
      .catch((e) => {
        handleApiError(e);
      });
  };

  const getDefaultSupplierId = (suppliers: BaseSupplierOrderVariantSupplier[] | Nullish) =>
    suppliers?.find((supplier) => supplier.isDefault)?.supplierId;

  return (
    <DataStatus isLoading={isLoading} isError={isError} data-testid={suffixTestId('status', props)}>
      <Form<SupplierOrderForm> schema={formSchema(isOrderTypeRequired)} onSubmit={handleSubmit}>
        {(control, formApi) => {
          const orderVariantId = formApi.watch('orderVariantId');
          const supplierId = formApi.watch('supplierId');

          const fetchSupplierOrderType = (args: FetchSupplierOrderType) => {
            getSupplierOrderType({
              supplierId: args.supplierId,
              supplierOrderVariantId: args.supplierOrderVariantId,
            })
              .unwrap()
              .then((supplierOrderType) => {
                setOrderTypeOptions(supplierOrderType);
              })
              .catch(handleApiError);
          };

          const handleOrderVariantChange = (
            newOrderVariantId: string | string[] | number | null
          ) => {
            if (prevValues.current?.orderVariantId === newOrderVariantId) {
              return;
            }

            const newOrderVariant = supplierOrderVariants?.find(
              (supplierOrderVariant) => supplierOrderVariant.orderVariantId === newOrderVariantId
            );

            setOrderVariant(newOrderVariant);

            getSupplierOrderVariantSuppliers({orderVariantId: newOrderVariantId as string})
              .unwrap()
              .then((suppliers) => {
                setSupplierOrderVariantSuppliers(suppliers);
                const defaultSupplierId = getDefaultSupplierId(suppliers);
                formApi.setValue('supplierId', defaultSupplierId);
                formApi.setValue('orderType', undefined);

                prevValues.current = {
                  orderVariantId: newOrderVariantId as string,
                  supplierId: defaultSupplierId,
                };

                if (isString(newOrderVariantId) && isString(defaultSupplierId)) {
                  fetchSupplierOrderType({
                    supplierId: defaultSupplierId,
                    supplierOrderVariantId: newOrderVariantId,
                  });
                }
              })
              .catch(handleApiError);
          };

          const handleSupplierChange = (newSupplierId: string | string[] | number | null) => {
            if (prevValues.current?.supplierId === newSupplierId) {
              return;
            }

            prevValues.current = {
              supplierId: newSupplierId as string,
              orderVariantId: prevValues.current.orderVariantId,
            };
            formApi.setValue('orderType', undefined);
            const orderVariantId = formApi.getValues('orderVariantId');

            if (isString(orderVariantId) && isString(newSupplierId)) {
              fetchSupplierOrderType({
                supplierId: newSupplierId,
                supplierOrderVariantId: orderVariantId,
              });
            }
          };

          return (
            <>
              <VStack spacing={4}>
                <Grid columns={2}>
                  <FormField
                    control={control}
                    onChange={handleOrderVariantChange}
                    type="choice"
                    name="orderVariantId"
                    isRequired
                    label={i18n.t('entity.warehouse.labels.supplierOrderVariant')}
                    options={getOptionsFromSupplierOrderVariants(supplierOrderVariants)}
                    data-testid={suffixTestId('inputs.orderVariantId', props)}
                    isNotClearable
                  />
                  <Tooltip
                    isDisabled={isNotNil(orderVariantId)}
                    label={i18n.t('entity.warehouse.labels.firstlySelectSupplierOrderVariant')}
                  >
                    <FormField
                      control={control}
                      onChange={handleSupplierChange}
                      type="choice"
                      name="supplierId"
                      label={i18n.t('entity.warehouse.labels.supplier')}
                      isRequired
                      isDisabled={isNil(orderVariantId)}
                      isLoading={isSupplierOrderVariantSuppliersLoading}
                      options={getOptionsFromSuppliersByOrderVariant(
                        suppliers,
                        supplierOrderVariantSuppliers
                      )}
                      data-testid={suffixTestId('inputs.supplierId', props)}
                      isNotClearable
                    />
                  </Tooltip>
                  <Tooltip
                    isDisabled={isNotNil(supplierId)}
                    label={i18n.t(
                      'entity.warehouse.labels.firstlySelectSupplierOrderVariantAndSupplier'
                    )}
                  >
                    <FormField
                      control={control}
                      type="choice"
                      name="orderType"
                      label={i18n.t('entity.warehouse.labels.supplierOrderType')}
                      isRequired={isOrderTypeRequired}
                      isDisabled={isNil(supplierId)}
                      options={orderTypeOptions}
                      data-testid={suffixTestId('inputs.orderType', props)}
                      isNotClearable
                      isLoading={isSupplierOrderTypeLoading}
                    />
                  </Tooltip>
                </Grid>
              </VStack>
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.discard')}
                  variant="secondary"
                  onClick={props.onClose}
                  data-testid={suffixTestId('actions.discard', props)}
                />
                <FormButton
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.create')}
                  isLoading={isCreateLoading}
                  data-testid={suffixTestId('actions.create', props)}
                />
              </ButtonGroup>
            </>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const formSchema = (isOrderTypeRequired: boolean) =>
  object({
    orderVariantId: yupString.required(),
    supplierId: yupString.required(),
    orderType: yupString[isOrderTypeRequired ? 'required' : 'nullable'](),
  });
