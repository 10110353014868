import {useContext, createContext} from 'react';

export type TConditionDamageLocation = {
  onDamageLegendClick: (damageValue: string) => void;
  highlightedDamageType: string | null;
};

export const ConditionDamageLocation = createContext<TConditionDamageLocation>(
  {} as TConditionDamageLocation
);

export const useConditionDamageLocation = (): TConditionDamageLocation =>
  useContext(ConditionDamageLocation);
