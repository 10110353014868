import {css, useTheme} from 'styled-components';

import {CSSProperties, useState} from 'react';

import {isNil} from 'ramda';

import {Booleanish, Nullish, suffixTestId, TestIdProps} from 'shared';

import {useResponsivePropValue} from '../../hooks/useResponsivePropValue';
import {CSSDimension} from '../../types/CSSDimension';
import {Integer} from '../../types/Integer';
import {ValueByDevice} from '../../types/ValueByDevice';
import {ThemeRadiusPath} from '../../utils/foundationTheme';
import {getCssSize} from '../../utils/getCssSize';
import {Box} from '../Box/Box';
import {Center} from '../Center/Center';
import {Icon} from '../Icon/Icon';
import {Show} from '../Show/Show';
import {Spinner} from '../Spinner/Spinner';
import {SpinnerColor} from '../Spinner/types/SpinnerColor';

export type ImageFit = 'contain' | 'cover' | 'scale-down';

export interface ImageProps extends TestIdProps {
  src: string | Nullish;
  width?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  height?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  minWidth?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  minHeight?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  maxWidth?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  maxHeight?: Integer | CSSDimension | ValueByDevice<Integer> | ValueByDevice<CSSDimension>;
  draggable?: Booleanish;
  fit?: ImageFit;
  position?: CSSProperties['objectPosition'];
  ratio?: CSSProperties['aspectRatio'];
  alt?: string;
  borderRadius?: ThemeRadiusPath;
  isLazy?: boolean;
  hasSpinner?: boolean;
  spinnerColor?: SpinnerColor;
  onLoad?: () => void;
  onError?: () => void;
}

export function Image(props: ImageProps) {
  const theme = useTheme();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);

  const width = useResponsivePropValue(props.width);
  const height = useResponsivePropValue(props.height);
  const minWidth = useResponsivePropValue(props.minWidth);
  const minHeight = useResponsivePropValue(props.minHeight);
  const maxWidth = useResponsivePropValue(props.maxWidth);
  const maxHeight = useResponsivePropValue(props.maxHeight);

  return (
    <Box
      width={width}
      height={height}
      minWidth={minWidth ?? 'initial'}
      minHeight={minHeight ?? 'initial'}
      maxWidth={maxWidth ?? 'initial'}
      maxHeight={maxHeight ?? 'initial'}
      position="relative"
      overflow="hidden"
      ratio={props.ratio}
      borderRadius={props.borderRadius}
    >
      <img
        data-testid={suffixTestId('image', props)}
        css={css`
          width: ${getCssSize(width) ?? '100%'};
          height: ${getCssSize(height) ?? '100%'};
          min-width: ${getCssSize(minWidth) ?? 'initial'};
          min-height: ${getCssSize(minHeight) ?? 'initial'};
          max-width: ${getCssSize(maxWidth) ?? 'initial'};
          max-height: ${getCssSize(maxHeight) ?? 'initial'};
          object-fit: ${props.fit};
          object-position: ${props.position};
          aspect-ratio: ${props.ratio};
          opacity: ${isNil(props.src) || isLoading || isError ? 0 : 1};
        `}
        alt={props.alt}
        draggable={props.draggable ?? undefined}
        src={props.src ?? undefined}
        loading={props.isLazy ? 'lazy' : undefined}
        onLoad={() => {
          setLoading(false);
          props.onLoad?.();
        }}
        onError={() => {
          setLoading(false);
          setError(true);
          props.onError?.();
        }}
      />
      <Show when={isNil(props.src) || isError}>
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          backgroundColor={theme.components.Image.emptyFill}
        >
          <Center width="100%" height="100%">
            <Icon color="palettes.neutral.70.100" value="image/photo" size={6} />
          </Center>
        </Box>
      </Show>
      <Show when={isLoading && props.hasSpinner}>
        <Box position="absolute" top={0} left={0} right={0} bottom={0}>
          <Center width="100%" height="100%">
            <Spinner color={props.spinnerColor} />
          </Center>
        </Box>
      </Show>
    </Box>
  );
}
