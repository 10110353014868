import {isNil} from 'ramda';

import {Nullish} from 'shared';

export const composeRangeObject = (from: number | Nullish, to: number | Nullish) => {
  if (isNil(from) && isNil(to)) {
    return null;
  }
  return {
    from: from ?? null,
    to: to ?? null,
  };
};
