import {Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {Right, VStack} from 'platform/foundation';
import {object} from 'yup';

import {yupString} from 'shared';

export type ConnectionFormType = {
  gridCode: string;
  baseUrl: string;
  authorizationToken: string;
};

interface OverrideConnectionsProps {
  defaultValues?: ConnectionFormType;
  onConnectionChange: (modifier: ConnectionFormType) => void;
}

export function OverrideConnections(props: OverrideConnectionsProps) {
  const onSubmit: FormSubmitHandler<ConnectionFormType> = async (data) =>
    await props.onConnectionChange(data);

  return (
    <Form<ConnectionFormType>
      schema={schema}
      onSubmit={onSubmit}
      defaultValues={props.defaultValues}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField control={control} name="gridCode" type="text" label="Grid code" />
          <FormField control={control} name="baseUrl" type="text" label="Base url" />
          <FormField
            control={control}
            name="authorizationToken"
            type="textarea"
            label="Authorization token"
          />
          <Right>
            <FormButton control={control} title="Override" type="submit" />
          </Right>
        </VStack>
      )}
    </Form>
  );
}

const schema = object({
  gridCode: yupString.required(),
  baseUrl: yupString.required(),
  authorizationToken: yupString.required(),
});
