import {DefaultTheme} from 'styled-components';

import {isNil, path, split} from 'ramda';

import {Nullish} from 'shared';

import {ThemeColorPath} from './foundationTheme';

export function getColorByPath(
  color: ThemeColorPath | Nullish,
  theme: DefaultTheme
): string | undefined {
  if (isNil(color)) {
    return undefined;
  }
  return path(split('.', color), theme.colors);
}
