import {DeepPartial} from 'utility-types';

import {mergeDeepWith, concat, isNil} from 'ramda';

const concatValues = (l: any, r: any) => {
  if (!r) {
    return l;
  }

  if (isNil(l)) {
    return r;
  }

  if (Array.isArray(l)) {
    if (r.length !== 0) {
      return r;
    } else {
      return l;
    }
  }

  return ['string', 'number', 'boolean'].includes(typeof l) ? r : concat(l, r);
};

export const mergeFormValues = <T extends Record<string, unknown>>(
  defaultValues: DeepPartial<T>,
  overrideValues: DeepPartial<T>
): T => mergeDeepWith(concatValues, defaultValues, overrideValues);
