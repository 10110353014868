import {Box, Show, Space, Text, VStack} from 'platform/foundation';

import {trim} from 'ramda';

import {SellerResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

interface SellerProps {
  seller: SellerResponseBody;
}

export function Seller(props: SellerProps) {
  return (
    <Box backgroundColor="palettes.neutral.20.100" borderRadius="medium" padding={4}>
      <Text size="xSmall" color="secondary">
        {i18n.t('entity.sourcing.vehiclePreview.labels.dealer')}
      </Text>
      <Space vertical={2} />
      <Text alternative>{props.seller.name}</Text>
      <Space vertical={2} />
      <Text size="small" color="secondary">
        {props.seller.address}
        <br />
        {props.seller.city}
      </Text>
      <Space vertical={6} />
      <VStack>
        {props.seller.phone
          ?.split(',')
          .map(trim)
          .map((phone) => (
            <Text key={phone} size="small" alternative>
              {phone}
            </Text>
          ))}
      </VStack>
      <Show when={props.seller.email}>
        <Space vertical={2} />
        <Text size="small">{props.seller.email}</Text>
      </Show>
    </Box>
  );
}
