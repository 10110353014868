import {DataStatus, closeCurrentDialog, openDialog} from 'platform/components';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {CampaignData, Section, ServiceCampaigns} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';
import {AddRecallCampaign} from './components/AddRecallCampaign';

interface VehicleCampaignsProps {
  vehicleId: string | Nullish;
}

export function VehicleCampaigns(props: VehicleCampaignsProps) {
  const {serviceCaseId} = useWorkshopUrl();

  const handleAdd = (campaign: CampaignData) =>
    openDialog(
      <AddRecallCampaign
        campaign={campaign}
        serviceCaseId={serviceCaseId}
        onClose={closeCurrentDialog}
      />,
      {
        title: i18n.t('entity.campaigns.labels.dialogTitle', {name: campaign.name}),
      }
    );
  return (
    <Section data-testid={testIds.workshop.serviceCaseDetail('vehicleCampaigns')}>
      <DataStatus isLoading={isNil(props.vehicleId)}>
        <ServiceCampaigns
          vehicleId={props.vehicleId ?? ''}
          onAdd={handleAdd}
          data-testid={testIds.workshop.serviceCaseDetail('serviceCampaigns')}
        />
      </DataStatus>
    </Section>
  );
}
