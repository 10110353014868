import {Text} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber} from 'platform/locale';

import {isNotNil} from 'ramda';
import {isNegative} from 'ramda-adjunct';

import type {CellValues} from '../types/CellValues';
import {getCurrencyAcrossCells} from '../utils/getCurrencyAcrossCells';

type StatusValueProps = {
  value: number;
  label: string;
  selectedCells: CellValues;
};

const DECIMAL_PLACES = 2;

export function StatusValue(props: StatusValueProps) {
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  const currencyAcrossCells = getCurrencyAcrossCells(props.selectedCells);

  const getFormattedNumberOrMoney = (value: number) =>
    isNotNil(currencyAcrossCells)
      ? formatCurrency(value, currencyAcrossCells, DECIMAL_PLACES)
      : formatNumber(value, DECIMAL_PLACES);

  return (
    <>
      <Text size="small">{props.label}:</Text>
      <Text size="small" alternative color={isNegative(props.value) ? 'danger' : 'primary'}>
        {getFormattedNumberOrMoney(props.value)}
      </Text>
    </>
  );
}
