import {FC, useRef, useEffect, createContext} from 'react';

import {ContextMenuWrapper} from './styles';
import {ContextMenuContextType, ContextMenuProviderProps} from './types';

export const ContextMenuContext = createContext<ContextMenuContextType | null>(null);

/**
 * Context Provider for ContextMenu, Handles context state
 *
 * - `children` - content
 * - `menuState` - position of context menu
 * - `setMenuState` - called when menu state is changed - this function is called when `ContextMenuWithContext` tries to open context menu,
 * 	returns `null` when menu should be closed
 * - `...rest` - rest of props that are applied to `div` wrapper
 *
 * @example
 * const [menuState, setMenuState] = useState(null);
 *
 * <ContextMenuProvider menuState={menuState} setMenuState={setMenuState}>
 * 	...
 * 		<ContextMenuWithContext {..menuProps}>
 * 			<div>Right-click</div>
 * 		<ContextMenuWithContext>
 * 	...
 * </ContextMenuProvider>
 *
 */
export const ContextMenuProvider: FC<ContextMenuProviderProps> = ({
  children,
  menuState,
  setMenuState,
  ...rest
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    const _wrapper = wrapperRef.current;
    _wrapper?.addEventListener('contextmenu', handleContextMenu);

    return () => {
      _wrapper?.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <ContextMenuWrapper {...rest} ref={wrapperRef}>
      <ContextMenuContext.Provider value={{menuState, setMenuState}}>
        {children}
      </ContextMenuContext.Provider>
    </ContextMenuWrapper>
  );
};
