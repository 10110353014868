import {openConfirmDialog, openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {head} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  useBulkDeleteServiceOrderIssueVariantMutation,
  usePatchServiceOrderIssueVariantSetDefaultMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, noop, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function WarehouseServiceOrderIssueVariants() {
  const navigate = useNavigate();

  const [deleteServiceOrderIssueVariant] = useBulkDeleteServiceOrderIssueVariantMutation();
  const [setDefaultServiceOrderIssueVariant] = usePatchServiceOrderIssueVariantSetDefaultMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;
    match(actionKey)
      .with('redirectDetail', 'edit', () => {
        navigate(
          composePath(settingsRoutes.warehousesServiceOrderIssueVariantsDetail, {
            params: {id},
          })
        );
      })
      .with('setAsDefault', () => {
        openConfirmDialog({
          text: i18n.t('general.actions.setAsDefault'),
          onConfirm: () => {
            setDefaultServiceOrderIssueVariant({
              serviceOrderIssueVariantId: id ?? '',
              body: {isDefault: true},
            })
              .unwrap()
              .then(() => refreshData())
              .catch(handleApiError);
          },
        });
      })
      .with('delete', () => {
        const rowIds = isArray(rowId) ? rowId : [rowId];
        openDeleteDialog({
          text: i18n.t('entity.warehouse.labels.deleteSelectedItemsPrompt'),
          onConfirm: () => {
            deleteServiceOrderIssueVariant({serviceOrderIssueVariantId: rowIds})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError);
          },
        });
      })
      .otherwise(noop);
  };

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.warehouse.labels.serviceOrderIssueVariants'),
    actions: [
      {
        type: 'button',
        title: i18n.t('entity.warehouse.actions.newServiceOrderIssueVariant'),
        onClick: () => {
          navigate(settingsRoutes.warehousesServiceOrderIssueVariantsCreate);
        },
        'data-testid': testIds.settings.warehousesServiceOrderIssueVariants(
          'newServiceOrderIssueVariant'
        ),
      },
    ],
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.warehousesServiceOrderIssueVariants('template')}
      header={header}
    >
      <DataGrid
        gridCode="warehouse-service-order-issue-note-variants"
        actionCallback={actionCallback}
        data-testid={testIds.settings.warehousesServiceOrderIssueVariants(
          'serviceOrderIssueNoteVariants'
        )}
      />
    </SettingsTemplate>
  );
}
