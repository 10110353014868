import {css, useTheme} from 'styled-components';

import {ReactNode, useEffect, useState} from 'react';

import {Menu, MenuList} from '@chakra-ui/react';

import {useAnimatedPopper} from '../../AnimatedPopper/hooks/useAnimatedPopper';
import {Portal} from '../../Portal/components/Portal';
import {PortalManager} from '../../Portal/components/PortalManager';
import {OpenDropdownMenuProps} from '../types/openDropdownMenuProps';
import {CLOSE_DROPDOWN_EVENT} from '../utils/closeDropdownEvent';
import {OPEN_DROPDOWN_EVENT} from '../utils/openDropdownEvent';

export interface DropdownProviderProps {
  children: ReactNode;
}

type OpendDropdownData = {
  clientX: number;
  clientY: number;
  content: ReactNode;
  menuProps: OpenDropdownMenuProps;
};

export function DropdownProvider(props: DropdownProviderProps) {
  const theme = useTheme();

  const [opendDropdownData, setOpendDropdownData] = useState<OpendDropdownData | undefined>();
  const {openPopper, closePopper, popperProps, Popper} = useAnimatedPopper({
    placement: 'bottom-start',
  });

  useEffect(() => {
    const handleDropdownOpen = (event: Event) => {
      if (isCustomEvent(event)) {
        openPopper();
        setOpendDropdownData({
          ...event.detail.position,
          content: event.detail.content,
          menuProps: event.detail.options,
        });
      }
    };

    window.addEventListener(OPEN_DROPDOWN_EVENT, handleDropdownOpen);

    return () => {
      window.removeEventListener(OPEN_DROPDOWN_EVENT, handleDropdownOpen);
    };
  }, [openPopper, setOpendDropdownData]);

  useEffect(() => {
    const handleDropdownClose = (event: Event) => {
      if (isCustomEvent(event) && event.detail.id === opendDropdownData?.menuProps.id) {
        closePopper();
      }
    };
    window.addEventListener(CLOSE_DROPDOWN_EVENT, handleDropdownClose);
    return () => {
      window.removeEventListener(CLOSE_DROPDOWN_EVENT, handleDropdownClose);
    };
  }, [closePopper, opendDropdownData]);

  return (
    <>
      <PortalManager zIndex={theme.zIndices.DROPDOWN_COMPONENT}>
        <Portal>
          <div
            // ClientX and ClientY is getting from onClick event in px
            // eslint-disable-next-line eag/no-css-property
            css={css`
              top: ${opendDropdownData?.clientY}px;
              left: ${opendDropdownData?.clientX}px;
              position: absolute;
            `}
          >
            <Popper {...popperProps}>
              <Menu isOpen onClose={closePopper} {...opendDropdownData?.menuProps}>
                <MenuList>{opendDropdownData?.content}</MenuList>
              </Menu>
            </Popper>
          </div>
        </Portal>
      </PortalManager>
      {props.children}
    </>
  );
}

const isCustomEvent = (event: Event): event is CustomEvent => !!('detail' in event);
