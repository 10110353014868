import {FieldValues, useFormContext, useWatch} from 'react-hook-form';

import {useDebouncedCallback} from 'shared';

import {useDeepCompareEffect} from '../../hooks/useDeepCompareEffect';

type DebounceConfig = {
  delay: number;
  wait: number;
};

type AutoSaveProps<TFieldValues extends FieldValues = FieldValues> = {
  onSave: (values: TFieldValues) => void;
  debounceConfig?: DebounceConfig;
};

/**
 * AutoSave component for handling form auto-saving. This AutoSave should only be used as a one-way auto-save.
 * Only for saving data. Error handling logic is always implemented on the layer above this component.
 *
 * @template TFieldValues - Generic form type.
 * @param {AutoSaveProps<TFieldValues>} props - Simple input and output, `onSave` callback which is called after every change. DebounceConfig to configure the debounce that is used in the AutoSave component.
 * @returns {null} - Returns null as the AutoSave component doesn't render anything directly.
 */
export function AutoSave<TFieldValues extends FieldValues = FieldValues>(
  props: AutoSaveProps<TFieldValues>
): null {
  const methods = useFormContext<TFieldValues>();
  const watchedData = useWatch({
    control: methods.control,
  });

  const debouncedSave = useDebouncedCallback(
    props.onSave,
    props.debounceConfig?.delay ?? DEFAULT_DEBOUNCED_DELAY,
    props.debounceConfig?.wait ?? DEFAULT_DEBOUNCED_WAIT
  );

  useDeepCompareEffect(() => {
    if (methods.formState.isDirty) {
      debouncedSave(watchedData as TFieldValues);
    }
  }, [watchedData]);

  return null;
}

const DEFAULT_DEBOUNCED_DELAY = 700;
const DEFAULT_DEBOUNCED_WAIT = 60000;
