import styled from 'styled-components';

import {FC, PropsWithChildren} from 'react';

import {ExpandCell} from './ExpandCell';

export const ExpandHeader: FC<PropsWithChildren<any>> = () => <HiddenExpandCell />;

const HiddenExpandCell = styled(ExpandCell)`
  visibility: hidden;
`;
