import {Box} from 'platform/foundation';

import {ReactNode} from 'react';

interface InsuranceCardProps {
  children?: ReactNode | ReactNode[];
}

export function InsuranceCard(props: InsuranceCardProps) {
  return (
    <Box
      backgroundColor="general.white"
      borderRadius="medium"
      boxShadow="elevation_2"
      overflow="hidden"
    >
      {props.children}
    </Box>
  );
}
