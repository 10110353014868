import {StylesConfig} from 'react-select';

import {Nullish, noop} from 'shared';

import {OptionTypeBase} from '../../../types/OptionTypeBase';
import {DefaultComponentsTheme} from '../../../utils/componentsTheme';

export const reactSelectStyles = <ValueType, IsMulti extends boolean>(
  theme: DefaultComponentsTheme,
  horizontalMenuPlacement?: 'left' | 'right'
): StylesConfig<OptionTypeBase<ValueType | Nullish>, IsMulti> => ({
  input: (provided, props) => ({
    ...provided,
    color: 'inherit',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 2,
    marginRight: 2,
    paddingTop: 0,
    paddingBottom: 0,
    height: '100%',
    /**
     * This is a workaround for https://github.com/JedWatson/react-select/pull/5618
     */
    visibility: props.isDisabled ? 'hidden' : 'unset',
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: 'none',
    width: '100%',
    zIndex: theme.zIndices.CHOICE_MENU,
    ...(horizontalMenuPlacement === 'right' && {
      width: 'auto',
      minWidth: '100%',
      position: 'absolute',
      right: 0,
    }),
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    width: '100%',
  }),
  valueContainer: (provided, props) => ({
    ...provided,
    padding: 0,
    flexWrap: 'wrap',
    overflow: 'hidden',
    height: '100%',
    maxWidth: '100%',
    alignItems: 'center',
    alignSelf: 'center',
    ...(props.isDisabled && {
      pointerEvents: 'auto',
      cursor: 'not-allowed',
      borderColor: theme.colors.palettes.neutral[70][40],
      boxShadow: 'none',
    }),
  }),
  menuPortal: (provided) => ({...provided, zIndex: theme.zIndices.CHOICE_MENU}),
  control: noop,
  option: noop,
  multiValue: noop,
  multiValueLabel: noop,
  multiValueRemove: noop,
  group: noop,
  singleValue: (provided) => ({
    ...provided,
    color: 'inherit',
  }),
});
