import {parseDate, Nullish} from 'shared';

export function getDefaultCompletionAtTime(date: string | Nullish) {
  if (!date) {
    return;
  }

  // todo: Use 'cs-CZ' or 'en-US' based on 12 or 24 hour format in settings (wating for T20-57261) will be implemented in T20-57262
  return parseDate(date).toLocaleTimeString('cs-CZ', {
    hour: '2-digit',
    minute: '2-digit',
  });
}
