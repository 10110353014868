import {useParams} from 'react-router-dom';

import {defaultTo} from 'ramda';

type EmployeeIdParams = {id?: string};

export function useEmployeeUrl() {
  const params = useParams<EmployeeIdParams>();

  return {
    employeeId: defaultTo('', params.id),
  };
}
