import {useFormatNumber} from 'platform/locale';
import {Text} from 'recharts';

import {formatNumberNotation} from '@omnetic-dms/teas';

interface TickLabelProps {
  x: number;
  y: number;
  minValue: number;
  payload: {
    value: number;
  };
  currency: string;
}

export function PriceTickLabel({currency, x, y, payload, minValue, ...rest}: TickLabelProps) {
  const formatNumber = useFormatNumber();
  return (
    <Text {...rest} x={x} y={y} textAnchor="end">
      {`${formatNumberNotation(
        formatNumber,
        currency,
        payload.value === 0 ? minValue : payload.value
      )} ${currency}`}
    </Text>
  );
}
