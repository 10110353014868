import {BulkDownloadApiResponse, BulkDownloadApiArg} from '../types/api';
import {omneticApi} from './baseApi/omneticApi';

export const eventApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getEvent: build.query<BulkDownloadApiResponse, BulkDownloadApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/event/event/${queryArg.eventId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'BulkEvent', id: queryArg.eventId}],
    }),
  }),
});

export const {useLazyGetEventQuery} = eventApi;
