import {BreadcrumbType} from 'platform/components';
import {Box} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DefinitionDetail} from './components/DefinitionDetail';

export function CashLimitsAdd() {
  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('page.accounting.labels.cashRegisterLimitsTitle'),
      href: settingsRoutes.cashLimits,
    },
  ];

  return (
    <SettingsTemplate header={{breadcrumbs}} data-testid="settings-cashLimits-add">
      <Box maxWidth={206}>
        <DefinitionDetail />
      </Box>
    </SettingsTemplate>
  );
}
