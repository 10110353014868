import type {Assignment} from '../types/Assignment';
import type {CancelablePromise} from '../types/CancelablePromise';
import {request as __request} from '../utils/request';

export class GetAssignmentsByEntityService {
  /**
   * @returns Assignment
   * @throws ApiError
   */
  public static getAssignmentsByEntity({
    assignmentIdentityType,
    assignmentIdentityId,
    authorization,
  }: {
    assignmentIdentityType: 'vehicle' | 'vehicle_audit';
    assignmentIdentityId: string;
    authorization?: string;
  }): CancelablePromise<Array<Assignment>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/assignment/{assignmentIdentityType}/{assignmentIdentityId}',
      path: {
        assignmentIdentityType,
        assignmentIdentityId,
      },
      headers: {
        Authorization: authorization,
      },
      shouldClearCacheEntry: true,
    });
  }
}
