import {addMonths} from 'date-fns';
import {FormControl, FormField} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';

import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {getApiDateString, RequiredTestIdProps, suffixTestId} from 'shared';

import {ServiceVehicleFormType} from '../../../types/ServiceVehicleFormType';

interface FluidFormProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
  groupName: 'brakeFluid' | 'coolantFluid';
}

export function FluidForm(props: FluidFormProps) {
  const {setValue} = props.formApi;

  const lastReplacementDate = props.formApi.watch(
    `serviceIntervals.${props.groupName}.lastReplacementDate`
  );

  const nextReplacementMileage = props.formApi.watch(
    `serviceIntervals.${props.groupName}.nextReplacementMileage`
  );

  useEffect(() => {
    if (!lastReplacementDate || !nextReplacementMileage) {
      return;
    }

    setValue(
      `serviceIntervals.${props.groupName}.nextReplacementDate`,
      getApiDateString(addMonths(lastReplacementDate, nextReplacementMileage))
    );
  }, [setValue, props.groupName, lastReplacementDate, nextReplacementMileage]);

  return (
    <Grid columns={4}>
      <GridItem>
        <FormField
          type="apiDate"
          label={i18n.t('entity.vehicle.labels.dateOfLastReplacement')}
          control={props.control}
          name={`serviceIntervals.${props.groupName}.lastReplacementDate`}
          data-testid={suffixTestId('lastReplacementDate', props)}
        />
      </GridItem>
      <GridItem>
        <FormField
          type="number"
          label={i18n.t('entity.vehicle.labels.mileageAtLastReplacement')}
          control={props.control}
          name={`serviceIntervals.${props.groupName}.lastReplacementMileage`}
          suffix={i18n.t('general.metric.km')}
          data-testid={suffixTestId('lastReplacementMileage', props)}
        />
      </GridItem>
      <GridItem>
        <FormField
          type="number"
          label={i18n.t('entity.vehicle.labels.replacementAfterMonths')}
          control={props.control}
          name={`serviceIntervals.${props.groupName}.nextReplacementMileage`}
          suffix={i18n.t('general.metric.months')}
          data-testid={suffixTestId('replacementAfterMonths', props)}
        />
      </GridItem>
      <GridItem>
        <FormField
          type="apiDate"
          label={i18n.t('entity.vehicle.labels.nextReplacementDate')}
          control={props.control}
          name={`serviceIntervals.${props.groupName}.nextReplacementDate`}
          data-testid={suffixTestId('nextReplacementDate', props)}
        />
      </GridItem>
    </Grid>
  );
}
