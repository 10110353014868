import {Button} from 'platform/components';
import {Text, Show, Box, Center} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

export interface SnippetPlaceholderProps extends TestIdProps {
  title?: string;
  isDisabled?: boolean;
  onClick?: () => void;
}

export function SnippetPlaceholder(props: SnippetPlaceholderProps) {
  return (
    <Box
      width="100%"
      height="100%"
      borderRadius="small"
      borderColor="palettes.neutral.40.100"
      border={props.onClick && !props.isDisabled ? '1px dashed' : 'none'}
      data-testid={suffixTestId(`snippetPlaceholder`, props)}
    >
      <Center width="100%" height="100%">
        <Box padding={2}>
          <Show when={props.onClick}>
            <Button
              variant="link"
              title={props.title}
              onClick={props.onClick}
              isDisabled={props.isDisabled}
              leftIcon="content/add_circle"
              data-testid={suffixTestId('button', props)}
            />
          </Show>
          <Show when={!props.onClick}>
            <Text size="small" color="tertiary" data-testid={suffixTestId('title', props)}>
              {props.title}
            </Text>
          </Show>
        </Box>
      </Center>
    </Box>
  );
}
