const DEFAULT_WORKSPACE = 'carvago';
/**
 * Since review apps does not support workspace subdomains we need to detect it.
 * If app is review app we force workspace to use DEFAULT_WORKSPACE.
 */
export const isReviewApp = (hostname: string) => hostname.endsWith('.omnetic.review');

const getWorkspacePosition = (hostname: string) => {
  if (hostname.includes('localhost')) {
    return 2;
  }
  if (hostname.includes('stage.teasdev')) {
    return 4;
  }
  if (hostname.includes('dev.teasdev')) {
    return 4;
  }
  if (hostname.includes('stage.omnetic')) {
    return 4;
  }
  if (hostname.includes('dev.omnetic')) {
    return 4;
  }
  if (isReviewApp(hostname)) {
    return 5;
  }
  return 3;
};

/**
 * @deprecated - use const {workspace} = getWorkspaceFromUri() instead
 */
export const parseHostname = (hostname: string) => {
  const parts = hostname.split('.');
  const workspaceIndex = parts.length - getWorkspacePosition(hostname);

  const workspace = parts[workspaceIndex] || (isReviewApp(hostname) ? DEFAULT_WORKSPACE : null);

  const domain = parts.slice(workspaceIndex + 1).join('.');

  return {
    workspace,
    domain,
  };
};
