import styled from 'styled-components';

import {suffixTestId, TestIdProps} from 'shared';

import {getPageNumbers} from '../utils/getPageNumbers';
import {PaginationPage} from './PaginationPage';

interface PaginationPageGroupProps extends TestIdProps {
  activePage: number;
  changePage: (newPage: number) => void;
  isDisabled?: boolean;
  pagesQuantity?: number;
  siblingCount?: number;
  boundaryCount?: number;
  showFirstPage?: boolean;
  showLastPage?: boolean;
}

export function PaginationPageGroup(props: PaginationPageGroupProps) {
  const pages = getPageNumbers({
    activePage: props.activePage,
    siblingCount: props.siblingCount,
    boundaryCount: props.boundaryCount,
    pagesQuantity: props.pagesQuantity,
    showFirstPage: props.showFirstPage,
    showLastPage: props.showLastPage,
  });

  return (
    <StyledWrapper data-testid={props['data-testid']}>
      {pages.map((item, index) => (
        <PaginationPage
          key={`pagination_page_${item}_${index}`}
          page={item}
          activePage={props.activePage}
          changePage={props.changePage}
          isDisabled={props.isDisabled}
          data-testid={suffixTestId(`page-${item}`, props)}
        />
      ))}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  > *:not(style) ~ *:not(style) {
    margin-left: 4px;
  }
`;
