export const allowedCountryCodes = [
  'DEU',
  'FRA',
  'ITA',
  'ESP',
  'POL',
  'NLD',
  'CZE',
  'AUT',
  'BEL',
  'SWE',
  'HUN',
  'SVK',
  'LUX',
  'DNK',
  'ROU',
  'LTU',
  'LVA',
  'BGR',
  'EST',
  'CHE',
  'SVN',
  'MCO',
  'FIN',
  'GBR',
  'HRV',
  'PRT',
  'GRC',
  'SRB',
  'ALB',
  'UKR',
  'NOR',
  'BIH',
  'TUR',
  'LIE',
  'IRL',
  'CYP',
  'AND',
  'MNE',
  'MDA',
];
