import {FC} from 'react';
import {useSelector} from 'react-redux';

import {selectConditionStructureTemplate, selectStructureSetting} from '@omnetic-dms/teas';

import {AuditSettings} from '../../VehicleInspectionsDetail/components/AuditSettings';

export const ConditionSettings: FC = () => {
  const template = useSelector(selectConditionStructureTemplate);
  const structureSettingData = useSelector(selectStructureSetting);

  return (
    <AuditSettings
      type={template?.inspectionType}
      templateId={template?.id}
      isCreate={template?.key === null && !structureSettingData}
    />
  );
};
