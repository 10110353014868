import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import * as Yup from 'yup';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface CodeFormData {
  name: string;
}

export interface CodeFormProps extends RequiredTestIdProps {
  defaultValues?: CodeFormData;
  onSubmit: FormSubmitHandler<CodeFormData>;
}

const DEFAULT_VALUES: CodeFormData = {name: ''};

export function CodeForm(props: CodeFormProps) {
  return (
    <Form<CodeFormData>
      onSubmit={props.onSubmit}
      schema={codeFormSchema}
      defaultValues={props.defaultValues ?? DEFAULT_VALUES}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            label={i18n.t('entity.codelist.labels.codeName')}
            control={control}
            name="name"
            type="text"
            isRequired
            isAutoFocused
            data-testid={suffixTestId('code-name', props)}
          />
          <ButtonGroup align="right">
            <Button
              variant="secondary"
              onClick={closeCurrentDialog}
              title={i18n.t('general.actions.cancel')}
              data-testid={suffixTestId('cancel', props)}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('general.actions.save')}
              data-testid={suffixTestId('save', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const codeFormSchema = Yup.object().shape({
  name: Yup.string().required().max(255),
});
