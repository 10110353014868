import {match} from 'ts-pattern';

import {mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {workshopRoutes} from '@omnetic-dms/routes';
import {queryParams, workshopSearchParams} from '@omnetic-dms/shared';

import {composePath, noop, useNavigate} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

type ServiceOrderRowData = {serviceCaseId: {value: string}};

export function ServiceOrderOverview() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    const castedRowData = rowData as ServiceOrderRowData;
    const orderId = isArray(rowId) ? rowId[0] : rowId;
    const path = composePath(workshopRoutes.serviceCaseDetail, {
      params: {
        id: castedRowData?.serviceCaseId?.value,
      },
      queryParams: {
        source: 'service-orders',
        section: workshopSearchParams.serviceDetail.orders,
        [queryParams.SERVICE_CASE_ORDER_ID]: orderId,
      },
    });

    match(actionKey)
      .with('redirectDetail', () => navigate(path))
      .with('redirectDetail_newTab', () => window.open(path))
      .otherwise(noop);
  };

  const queryModifier = (filter: QueryFilterObject) => mergeAll([filter, {vehicleId: undefined}]);

  return (
    <DataGrid
      data-testid="service-order-overview"
      gridCode="service-order"
      actionCallback={actionCallback}
      queryModifier={queryModifier}
    />
  );
}
