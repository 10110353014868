import {FC, PropsWithChildren} from 'react';

import {FileIconWrapper} from '../styles';

export const FileIcon: FC<PropsWithChildren<any>> = () => (
  <FileIconWrapper>
    <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.2002 0C4.99106 0 3.2002 1.79086 3.2002 4V28C3.2002 30.2091 4.99106 32 7.2002 32H24.8002C27.0093 32 28.8002 30.2091 28.8002 28L28.8002 9.6L19.2002 0H7.2002Z"
        fill="#EBECF0"
      />
      <path
        d="M19.2002 7.6V0L28.8002 9.6H21.2002C20.0956 9.6 19.2002 8.70457 19.2002 7.6Z"
        fill="#C1C7D0"
      />
      <rect y="16" width="25.6" height="12.8" rx="2" fill="#0065FF" />
      <path
        d="M3.65186 25.6V18.775H5.66748C6.27061 18.775 6.80498 18.9094 7.27061 19.1781C7.73936 19.4469 8.10186 19.8281 8.35811 20.3219C8.61436 20.8156 8.74248 21.3813 8.74248 22.0188V22.361C8.74248 23.0078 8.61279 23.5766 8.35342 24.0672C8.09717 24.5578 7.72998 24.936 7.25186 25.2016C6.77686 25.4672 6.23154 25.6 5.61592 25.6H3.65186ZM4.83779 19.7313V24.6532H5.61123C6.23311 24.6532 6.70967 24.4594 7.04092 24.0719C7.37529 23.6813 7.54561 23.1219 7.55186 22.3938V22.0141C7.55186 21.2735 7.39092 20.7078 7.06904 20.3172C6.74717 19.9266 6.27998 19.7313 5.66748 19.7313H4.83779Z"
        fill="white"
      />
      <path
        d="M15.6266 22.3656C15.6266 23.0344 15.511 23.6219 15.2797 24.1281C15.0485 24.6313 14.7172 25.0188 14.286 25.2906C13.8579 25.5594 13.3641 25.6938 12.8047 25.6938C12.2516 25.6938 11.7579 25.5594 11.3235 25.2906C10.8922 25.0188 10.5579 24.6328 10.3204 24.1328C10.086 23.6328 9.96723 23.0563 9.96411 22.4031V22.0188C9.96411 21.3531 10.0813 20.7656 10.3157 20.2563C10.5532 19.7469 10.886 19.3578 11.3141 19.0891C11.7454 18.8172 12.2391 18.6813 12.7954 18.6813C13.3516 18.6813 13.8438 18.8156 14.2719 19.0844C14.7032 19.35 15.036 19.7344 15.2704 20.2375C15.5047 20.7375 15.6235 21.3203 15.6266 21.986V22.3656ZM14.4407 22.0094C14.4407 21.2531 14.2969 20.6735 14.0094 20.2703C13.725 19.8672 13.3204 19.6656 12.7954 19.6656C12.2829 19.6656 11.8813 19.8672 11.5907 20.2703C11.3032 20.6703 11.1563 21.2375 11.15 21.9719V22.3656C11.15 23.1156 11.2954 23.6953 11.586 24.1047C11.8797 24.5141 12.286 24.7188 12.8047 24.7188C13.3297 24.7188 13.7329 24.5188 14.0141 24.1188C14.2985 23.7188 14.4407 23.1344 14.4407 22.3656V22.0094Z"
        fill="white"
      />
      <path
        d="M22.2717 23.3781C22.2029 24.1063 21.9342 24.675 21.4654 25.0844C20.9967 25.4907 20.3732 25.6938 19.5951 25.6938C19.0514 25.6938 18.5717 25.5656 18.156 25.3094C17.7435 25.05 17.4248 24.6828 17.1998 24.2078C16.9748 23.7328 16.8576 23.1813 16.8482 22.5531V21.9156C16.8482 21.2719 16.9623 20.7047 17.1904 20.2141C17.4185 19.7235 17.7451 19.3453 18.1701 19.0797C18.5982 18.8141 19.092 18.6813 19.6514 18.6813C20.4045 18.6813 21.0107 18.886 21.4701 19.2953C21.9295 19.7047 22.1967 20.2828 22.2717 21.0297H21.0904C21.0342 20.5391 20.8904 20.186 20.6592 19.9703C20.431 19.7516 20.0951 19.6422 19.6514 19.6422C19.1357 19.6422 18.7389 19.8313 18.4607 20.2094C18.1857 20.5844 18.0451 21.136 18.0389 21.8641V22.4688C18.0389 23.2063 18.1701 23.7688 18.4326 24.1563C18.6982 24.5438 19.0857 24.7375 19.5951 24.7375C20.0607 24.7375 20.4107 24.6328 20.6451 24.4235C20.8795 24.2141 21.0279 23.8656 21.0904 23.3781H22.2717Z"
        fill="white"
      />
    </svg>
  </FileIconWrapper>
);
