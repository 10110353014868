import {match} from 'ts-pattern';

import {always} from 'ramda';

import {TypeOfSale} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

export const getPredefinedNote = (typeOfSale: TypeOfSale | Nullish) =>
  match(typeOfSale)
    .with('inland', always(i18n.t('entity.checkout.labels.specialRegimeUsedGoods')))
    .with('inEurope', always(i18n.t('entity.checkout.labels.taxFreeSaleToEurope')))
    .with('outOfEurope', always(i18n.t('entity.checkout.labels.taxFreeSaleOutsideEurope')))
    .otherwise(always(null));
