import {isNotNil} from 'ramda-adjunct';

import {FirstLineDetail} from '../types/FirstLineDetail';
import {getCarStyle} from './getCarStyle';
import {roundCubicCapacity} from './roundCubicCapacity';

export const detailFirstLine = ({
  make,
  model,
  variant,
  year,
  rowNumber,
  cubicCapacity,
  carStyle,
}: FirstLineDetail = {}) => {
  const result = `${rowNumber ? `${rowNumber}. ` : ''}${
    typeof make === 'string' ? `${make} ` : make?.translation ? `${make.translation} ` : ''
  }${typeof model === 'string' ? `${model} ` : model?.translation ? `${model.translation} ` : ''}${
    variant ? `${variant} ` : ''
  }${isNotNil(year) ? `${year} ` : ''}${
    isNotNil(cubicCapacity) ? `${roundCubicCapacity(cubicCapacity)} ` : ''
  }${getCarStyle(carStyle)}`;

  return result?.trim();
};
