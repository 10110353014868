import {MouseEvent, ReactNode} from 'react';

import {OpenDropdownMenuProps} from '../types/openDropdownMenuProps';
import {OPEN_DROPDOWN_EVENT} from './openDropdownEvent';

/**
 * @about Opens a dropdown with some custom JSX content.
 * Dropdown will be closed automatically when overlay
 *
 * @param event on click event
 * @param content Dropdown content
 * @param options Dropdown options (title, id, size, etc)
 *
 * @example
 * openDropdown(
 *   event,
 *   <>
 *     <DropdownItem label="item 1b" />
 *     <DropdownItem label="item 2b" />
 *   </>
 * );
 */
export function openDropdown(
  event: MouseEvent,
  content: ReactNode,
  options?: OpenDropdownMenuProps
) {
  const {clientX, clientY} = event;
  window.dispatchEvent(
    new CustomEvent(OPEN_DROPDOWN_EVENT, {
      detail: {position: {clientX, clientY}, content, options},
    })
  );
}
