import {useCallback} from 'react';

import {GridApi} from '../types/AgGridTypes';

export const useRefreshServerSideStore = (gridApi?: GridApi) =>
  useCallback(() => {
    if (gridApi) {
      gridApi.refreshServerSide({purge: true});
      gridApi.dispatchEvent({type: 'storeRefreshed'});
    }
  }, [gridApi]);
