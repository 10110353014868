import {Separator} from 'platform/components';
import {Grid, Heading, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {MoneyResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {DocumentSummary, ExchangeRateRatioDocumentSummary} from '../types';

interface AdditionalVatCalculationProps extends TestIdProps {
  summary: DocumentSummary | Nullish;
  exchangeRateRatioCalculation?: ExchangeRateRatioDocumentSummary | Nullish;
}

export function Summary(props: AdditionalVatCalculationProps) {
  const formatCurrency = useFormatCurrency();

  const formatPrice = (money: MoneyResponseBody | Nullish) =>
    formatCurrency(parseFloat(money?.amount ?? ''), money?.currency ?? '', 2);

  if (isNil(props.summary)) {
    return null;
  }

  const calculation = props.summary;
  const exchangeRateRatioCalculation = props.exchangeRateRatioCalculation;

  const isForeignCurrencyPresent = isNotNil(exchangeRateRatioCalculation);
  const attributeGridColumns = isForeignCurrencyPresent ? 3 : 2;

  return (
    <>
      <Heading size={4}>{i18n.t('general.labels.summary')}</Heading>

      <VStack spacing={2}>
        <Grid columns={attributeGridColumns}>
          <Text size="xSmall" color="secondary">
            {i18n.t('general.labels.vat')}
          </Text>
          <Text size="xSmall" color="secondary" data-testid={suffixTestId('vat', props)}>
            {formatPrice(calculation?.vat)}
          </Text>
          <Show when={isForeignCurrencyPresent}>
            <Text size="xSmall" color="tertiary">
              {formatPrice(exchangeRateRatioCalculation?.vat)}
            </Text>
          </Show>
        </Grid>
        <Separator spacing={0} />
        <Grid columns={attributeGridColumns}>
          <Text size="xSmall" color="secondary">
            {i18n.t('entity.lineItems.labels.totalPriceWithoutVat')}
          </Text>
          <Text
            size="xSmall"
            color="secondary"
            data-testid={suffixTestId('priceWithoutVat', props)}
          >
            {formatPrice(calculation?.priceWithoutVat)}
          </Text>
          <Show when={isForeignCurrencyPresent}>
            <Text
              size="xSmall"
              color="tertiary"
              data-testid={suffixTestId('priceWithoutVatForeignCurrency', props)}
            >
              {formatPrice(exchangeRateRatioCalculation?.priceWithoutVat)}
            </Text>
          </Show>
        </Grid>

        <Separator spacing={0} />
        <Grid columns={attributeGridColumns}>
          <Text size="xSmall" color="secondary">
            {i18n.t('entity.lineItems.labels.totalPriceWithVat')}
          </Text>
          <Heading size={2} data-testid={suffixTestId('priceWithVat', props)}>
            {formatPrice(calculation?.priceWithVat)}
          </Heading>

          <Show when={isForeignCurrencyPresent}>
            <Heading
              size={2}
              color="tertiary"
              data-testid={suffixTestId('priceWithVatForeignCurrency', props)}
            >
              {formatPrice(exchangeRateRatioCalculation?.priceWithVat)}
            </Heading>
          </Show>
        </Grid>
      </VStack>
    </>
  );
}
