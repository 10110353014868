import {ForwardRefRenderFunction, forwardRef, useImperativeHandle} from 'react';

import {ExternalFilter} from '../types/ExternalFilter';
import {InterestVehicleListDataGrid} from '../types/InterestVehicleListDataGrid';
import {makeDataGridFilter} from '../utils/makeDataGridFilter';

export type ExternalFilterConnectorRef = {
  onChange: (filter: ExternalFilter) => void;
};

interface ExternalFilterConnectorComponentProps {
  onDataGridFilterChange: (filter: InterestVehicleListDataGrid) => void;
}

/**
 * @description This component exists for a single purpose only: to change an external filter in the data grid from outside the component.
 * It's a workaround.
 * Unfortunately, external filters are not well-supported yet.
 * The filter component is external because some filters are not part of the data grid. Also, due to a large number of re-renders.
 */
export const ExternalFilterConnectorComponent: ForwardRefRenderFunction<
  ExternalFilterConnectorRef,
  ExternalFilterConnectorComponentProps
> = (props, ref) => {
  useImperativeHandle(ref, () => ({
    onChange: (filter) => {
      props.onDataGridFilterChange(makeDataGridFilter(filter));
    },
  }));
  return <></>;
};

export const ExternalFilterConnector = forwardRef(ExternalFilterConnectorComponent);
