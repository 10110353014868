import {isFeatureEnabled} from 'feature-flags';
import {
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Label,
  Separator,
  isCurrency,
  showNotification,
} from 'platform/components';
import {Box, Grid, Show, Space, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useSelector} from 'react-redux';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  selectTenant,
  useGetEmployeeWorkCompetencesListQuery,
  useGetServicePackageRequestDetailQuery,
  usePatchServicePackageRequestDetailMutation,
} from '@omnetic-dms/api';
import featureFlags from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  DEFAULT_CURRENCY,
  getActiveOptions,
  handleApiError,
  numberToTimeString,
  timeStringToNumber,
  useCurrencies,
  useGetVatRatesOptions,
} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId, useNavigate, yupNumber, yupString} from 'shared';

import {SettingsFooter} from '../../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../../components/SettingsSection/SettingsSection';
import {RequestItems} from './components/RequestItems';
import {RequestDetailFormType} from './types/requestDetailFormType';

interface RequestDetailFormProps extends TestIdProps {
  id: string;
}

export function RequestDetailForm(props: RequestDetailFormProps) {
  const navigate = useNavigate();

  const {getCurrencyName} = useCurrencies();
  const [vatOptions] = useGetVatRatesOptions();

  const [patchServicePackageRequestDetail] = usePatchServicePackageRequestDetailMutation();
  const {data, isLoading, isError} = useGetServicePackageRequestDetailQuery({
    servicePackageId: props.id,
  });
  const {data: competences, isLoading: isCompetenceLoading} =
    useGetEmployeeWorkCompetencesListQuery(undefined, {
      skip: !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    });
  const {data: selectedTenant} = useSelector(selectTenant);
  const currency = isCurrency(selectedTenant?.currency)
    ? selectedTenant?.currency
    : DEFAULT_CURRENCY;

  const handleNavigateBack = () => navigate(settingsRoutes.workshopServicePackages);

  const handleSubmit: FormSubmitHandler<RequestDetailFormType> = (data) =>
    patchServicePackageRequestDetail({
      servicePackageId: props.id,
      body: {
        competence: data.competence ?? null,
        description: data.description ?? null,
        estimatedTime: isNotNil(data.estimatedTime) ? timeStringToNumber(data.estimatedTime) : null,
        isFixedPrice: !!data.isFixedPrice,
        isPartsRequest: !!data.isPartsRequest,
        solution: data.solution ?? null,
        fixedPrice: !!data.isFixedPrice
          ? {
              amount: data.fixedPrice ?? 0,
              isPriceWithVat: false,
              currency: currency ?? DEFAULT_CURRENCY,
              vatType: data.vatType ?? '',
            }
          : undefined,
      },
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const competenceOptions = getActiveOptions(competences?.competences);

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<RequestDetailFormType>
        schema={schema}
        onSubmit={handleSubmit}
        defaultValues={{
          competence: data?.competence ?? undefined,
          description: data?.description ?? undefined,
          estimatedTime:
            isNotNil(data) && isNotNil(data.estimatedTime)
              ? numberToTimeString(data.estimatedTime)
              : undefined,
          isFixedPrice: data?.isFixedPrice ?? undefined,
          isPartsRequest: data?.isPartsRequest ?? undefined,
          solution: data?.solution ?? undefined,
          fixedPrice: data?.fixedPrice?.amount
            ? parseFloat(String(data.fixedPrice.amount))
            : undefined,
          vatType: data?.fixedPrice?.vatType ?? undefined,
        }}
      >
        {(control, formApi) => (
          <>
            <SettingsSection>
              <VStack spacing={4}>
                <FormField
                  control={control}
                  type="switch"
                  name="isFixedPrice"
                  label={i18n.t('entity.workshopServicePackages.labels.fixedPrice')}
                  data-testid={suffixTestId('isFixedPrice', props)}
                />
                <Show when={formApi.watch('isFixedPrice')}>
                  <Grid columns={4} spacing={4}>
                    <FormField
                      control={control}
                      type="number"
                      name="fixedPrice"
                      label={i18n.t('entity.workshopServicePackages.labels.fixedPriceWoVAT', {
                        currency: getCurrencyName(currency),
                      })}
                      data-testid={suffixTestId('fixedPrice', props)}
                    />
                    <FormField
                      control={control}
                      type="choice"
                      name="vatType"
                      options={vatOptions}
                      label={i18n.t('entity.workshopServicePackages.labels.fixedPriceWoVAT', {
                        currency: getCurrencyName(currency),
                      })}
                      data-testid={suffixTestId('vatType', props)}
                    />
                  </Grid>
                </Show>
                <FormField
                  control={control}
                  type="textarea"
                  name="description"
                  label={i18n.t('general.labels.description')}
                  data-testid={suffixTestId('description', props)}
                />
                <FormField
                  control={control}
                  type="textarea"
                  name="solution"
                  label={i18n.t('general.labels.solution')}
                  data-testid={suffixTestId('solution', props)}
                />
                <Grid columns={4} spacing={4}>
                  <FormField
                    control={control}
                    type="choice"
                    name="competence"
                    options={competenceOptions}
                    isLoading={isCompetenceLoading}
                    label={i18n.t('entity.workshopServicePackages.labels.competence')}
                    data-testid={suffixTestId('competence', props)}
                  />
                  <FormField
                    control={control}
                    type="time"
                    name="estimatedTime"
                    label={i18n.t('entity.workshopServicePackages.labels.estimatedTime')}
                    data-testid={suffixTestId('estimatedTime', props)}
                  />
                  <Box>
                    <Label>
                      {i18n.t('entity.workshopServicePackages.labels.additionalOptions')}
                    </Label>
                    <Space vertical={1} />
                    <FormField
                      control={control}
                      type="checkbox"
                      name="isPartsRequest"
                      label={i18n.t('entity.workshopServicePackages.labels.partsRequest')}
                      data-testid={suffixTestId('partsRequest', props)}
                    />
                  </Box>
                </Grid>
              </VStack>
            </SettingsSection>
            <Separator orientation="horizontal" spacing={6} />
            <RequestItems servicePackageId={props.id} />
            <Space vertical={20} />
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  variant: 'secondary',
                  title: isNotNilOrEmpty(data)
                    ? i18n.t('general.actions.discardChanges')
                    : i18n.t('general.actions.discard'),
                  onClick: handleNavigateBack,
                  'data-testid': suffixTestId('discard', props),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  variant: 'primary',
                  title: isNotNilOrEmpty(data)
                    ? i18n.t('general.actions.saveChanges')
                    : i18n.t('general.actions.save'),
                  'data-testid': suffixTestId('submit', props),
                },
              ]}
            />
          </>
        )}
      </Form>
    </DataStatus>
  );
}

const schema = object({
  fixedPrice: yupNumber.when('isFixedPrice', {
    is: true,
    then: yupNumber.required(),
    otherwise: yupNumber,
  }),
  vatType: yupString.when('isFixedPrice', {
    is: true,
    then: yupString.required(),
    otherwise: yupString,
  }),
});
