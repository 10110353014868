import {inc, isNil, mergeWith} from 'ramda';
import {isPositive} from 'ramda-adjunct';

import {range} from '../../../utils/range';
import {DEFAULT_PAGE} from '../constants/defaultPage';

export const pageConfig: PageNumbersConfig = {
  showFirstPage: true,
  showLastPage: true,
  activePage: DEFAULT_PAGE,
  siblingCount: 1,
  boundaryCount: 1,
  pagesQuantity: 10,
};

type PageNumbersConfig = {
  pagesQuantity: number;
  activePage: number;
  siblingCount: number;
  boundaryCount: number;
  showFirstPage: boolean;
  showLastPage: boolean;
};

const customMerge = (a: unknown, b: unknown) => (isNil(a) ? b : a);

export const getPageNumbers = (props: Partial<PageNumbersConfig>): number[] => {
  const config = mergeWith(customMerge, props, pageConfig);

  if (!isPositive(config.pagesQuantity)) {
    return [];
  }

  // Generate array of all pages.
  const allPages = Array.from(range(0, config.pagesQuantity)).map(inc);

  let startSegment: number[] = [];
  if (config.showFirstPage) {
    const boundaryLeftLimit = allPages.slice(0, 1 + config.boundaryCount);
    startSegment = startSegment.concat(boundaryLeftLimit);
  }

  let endSegment: number[] = [];
  if (config.showLastPage) {
    const boundaryRightLimit = allPages.slice(1).slice(-config.boundaryCount - 1);
    endSegment = endSegment.concat(boundaryRightLimit);
  }

  const firstIndexOfLeftSibling = config.activePage - 1 - config.siblingCount;
  const leftSiblingItems = allPages.slice(
    firstIndexOfLeftSibling > 0 ? firstIndexOfLeftSibling : undefined,
    config.activePage - 1
  );

  const firstIndexOfRightSibling = config.activePage - 1 + 1;
  const rightSiblingItems = allPages.slice(
    firstIndexOfRightSibling,
    config.activePage + config.siblingCount
  );

  const allLeftItems = Array.from(new Set([...startSegment, ...leftSiblingItems]));
  const allRightItems = Array.from(new Set([...rightSiblingItems, ...endSegment]));

  const unduplicatedPages = Array.from(
    new Set([...allLeftItems, config.activePage, ...allRightItems])
  );

  const addSeparator = (pages: number[]) =>
    pages.reduce<number[]>((acc: number[], page: number, index: number) => {
      const checkPageForSeparator = () => {
        const pagesLength = pages.length;
        if (index + 1 <= pagesLength - 1) {
          const checkValue = pages[index + 1];

          if (isPositive(checkValue) && checkValue - page === 1) {
            return [page];
          } else {
            return [page, 0];
          }
        }
        return [page];
      };

      return [...acc, ...checkPageForSeparator()];
    }, []);

  const pages = addSeparator(unduplicatedPages);

  return pages;
};
