import {Button} from 'platform/components';
import {Image, VStack, Box, Text, HStack, Space} from 'platform/foundation';

type ErrorProps = {
  message?: string;
  error?: unknown;
  componentStack?: string | null;
  eventId?: string | null;
  resetError?: () => void;
};

export function ErrorPage(props: ErrorProps) {
  const {resetError} = props;

  const onWelcomePage = () => {
    window.location.href = '/';
    resetError?.();
  };

  const onReloadPage = () => {
    window.location.href = window.location.pathname;
    resetError?.();
  };

  const message = props?.message || (props?.error as Error)?.message;

  return (
    <VStack align="center" justify="center" height="100vh" width="100vw">
      <Image
        src="/assets/images/logo_inverted.svg"
        alt="omnetic"
        maxWidth="9.7rem"
        maxHeight="1.2rem"
        data-testid="error-image"
      />
      <Box padding={40}>
        <VStack align="center" justify="center" height="100%">
          <Text color="primary" size="large">
            Ooops! Something went wrong.
          </Text>
          {message ? <Text color="secondary">{message}</Text> : null}
          <Box paddingTop={10}>
            <HStack align="center" justify="center">
              <Button
                variant="primary"
                onClick={onWelcomePage}
                title="Welcome page"
                data-testid="error-welcomePage"
              />
              <Space horizontal={4} />
              <Button
                variant="ghost"
                onClick={onReloadPage}
                title="Reload page"
                data-testid="error-reload"
              />
            </HStack>
          </Box>
        </VStack>
      </Box>
    </VStack>
  );
}
