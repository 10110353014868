export function DefaultLoadingCellRenderer() {
  return (
    // eslint-disable-next-line react/forbid-dom-props
    <div className="ag-loading">
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <span className="ag-loading-icon" itemRef="eLoadingIcon">
        {/* eslint-disable-next-line react/forbid-dom-props */}
        <span className="ag-icon ag-icon-loading" unselectable="on" role="presentation"></span>
      </span>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <span className="ag-loading-text" itemRef="eLoadingText">
        Loading
      </span>
    </div>
  );
}
