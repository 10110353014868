import {Card, RadioItem} from 'platform/components';
import {Clickable, HStack, Heading} from 'platform/foundation';

import {TestIdProps} from 'shared';

interface RadioCardProps extends TestIdProps {
  isSelected: boolean;
  onClick: VoidFunction;
  title: string;
}

export function RadioCard(props: RadioCardProps) {
  return (
    <Card variant="inlineGrey">
      <Clickable onClick={props.onClick} data-testid={props['data-testid']}>
        <HStack spacing={4}>
          <RadioItem value={props.isSelected} />
          <Heading size={4}>{props.title}</Heading>
        </HStack>
      </Clickable>
    </Card>
  );
}
