import {pipe, join, reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

type NaturalPersonAttributes = {
  titleBefore?: string | Nullish;
  firstName?: string | Nullish;
  middleName?: string | Nullish;
  lastName?: string | Nullish;
  titleAfter?: string | Nullish;
};

const getPersonAttributes = (personNameData: NaturalPersonAttributes) => [
  personNameData.titleBefore,
  personNameData.firstName,
  personNameData.middleName,
  personNameData.lastName,
  personNameData.titleAfter,
];

export function getNaturalPersonFullName<T extends NaturalPersonAttributes>(
  personNameData?: T | Nullish
) {
  if (!personNameData) {
    return null;
  }

  return pipe(getPersonAttributes, reject(isNilOrEmpty), join(' '))(personNameData);
}
