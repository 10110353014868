import {Card, FormControl, FormField, Separator} from 'platform/components';
import {Clickable, Heading, HStack, Show, Space, Text} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {AbroadSaleOption} from '../../../../../types/AbroadSaleOption';
import {DiscountType} from '../../../../../types/discountType';

const ABROAD_SALE_OPTIONS = [
  {
    label: i18n.t('entity.checkout.labels.abroadSaleEU'),
    value: AbroadSaleOption.SALE_TO_EU,
  },
  {
    label: i18n.t('entity.checkout.labels.abroadSaleOutsideEU'),
    value: AbroadSaleOption.SALE_OUTSIDE_EU,
  },
];

interface AbroadSaleCardProps extends RequiredTestIdProps {
  control: FormControl<DiscountType>;
  formApi: UseFormReturn<DiscountType>;
  isReadOnly?: boolean;
}

export function AbroadSaleCard(props: AbroadSaleCardProps) {
  const onAbroadSaleClick = () =>
    props.formApi.setValue(
      'abroadSale.isAbroadSale',
      !props.formApi.watch('abroadSale.isAbroadSale')
    );

  return (
    <div>
      <Heading size={4}>{i18n.t('entity.checkout.labels.abroadSale')}</Heading>
      <Space vertical={1} />
      <Text size="small" color="secondary">
        {i18n.t('entity.order.labels.abroadSaleInfo')}
      </Text>
      <Space vertical={4} />
      <Card variant="inlineGrey">
        <HStack align="center" spacing={3}>
          <FormField
            control={props.control}
            type="switch"
            name="abroadSale.isAbroadSale"
            isDisabled={props.isReadOnly}
            data-testid={suffixTestId('isAbroadSale', props)}
          />
          <Clickable onClick={onAbroadSaleClick} isDisabled={props.isReadOnly}>
            <Heading size={4}>{i18n.t('entity.checkout.labels.abroadSale')}</Heading>
          </Clickable>
        </HStack>
        <Show when={props.formApi.watch('abroadSale.isAbroadSale')}>
          <Separator />
          <FormField
            name="abroadSale.typeOfSale"
            type="chips"
            options={ABROAD_SALE_OPTIONS}
            control={props.control}
            isDisabled={props.isReadOnly}
            isRequired
            data-testid={suffixTestId('typeOfSale', props)}
          />
        </Show>
      </Card>
    </div>
  );
}
