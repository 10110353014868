import {Label} from 'platform/components';
import {Box, Grid, Space, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {MakeCheckboxList} from './components/MakeCheckboxList';
import {MakesModelsSearch} from './components/MakesModelsSearch';
import {ModelFamiliesCheckboxTree} from './components/ModelFamiliesCheckboxTree';
import {TopMakes} from './components/TopMakes';
import {TopSellingCheckboxList} from './components/TopSellingCheckboxList';

export function FilterMakesModels(props: TestIdProps) {
  return (
    <VStack spacing={4}>
      <MakesModelsSearch data-testid={suffixTestId('makesModelsSearch', props)} />
      <Grid columns={3}>
        <Box paddingHorizontal={4} maxHeight={150} overflow="auto">
          <VStack spacing={2}>
            <Label>{i18n.t('page.filters.labels.topMakes')}</Label>
            <TopMakes data-testid={suffixTestId('topMakes', props)} />
            <Label>{i18n.t('page.filters.labels.makes')}</Label>
            <MakeCheckboxList data-testid={suffixTestId('makes', props)} />
          </VStack>
        </Box>
        <Box paddingHorizontal={4} maxHeight={150} overflow="auto">
          <VStack spacing={2}>
            <Label>{i18n.t('page.filters.labels.models')}</Label>
            <ModelFamiliesCheckboxTree data-testid={suffixTestId('modelFamilies', props)} />
          </VStack>
        </Box>
        <Box paddingHorizontal={4} maxHeight={150} overflow="auto">
          <Label tooltip={i18n.t('page.filters.labels.topSellingTooltip')}>
            {i18n.t('page.filters.labels.topSelling')}
          </Label>
          <Space vertical={4} />
          <TopSellingCheckboxList data-testid={suffixTestId('topSelling', props)} />
        </Box>
      </Grid>
    </VStack>
  );
}
