import {testIds} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {VehicleInsightsCard} from './components/VehicleInsightsCard';

interface VehicleInsightsProps {
  vehicleId: string;
}

export function Insights(props: VehicleInsightsProps) {
  return (
    <Main data-testid={testIds.vehicles.insights('page')}>
      <VehicleInsightsCard vehicleId={props.vehicleId} />
    </Main>
  );
}
