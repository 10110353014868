import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

export const GetServiceOrderItemsDiscountApiResponseSchema = z
  .object({
    workDiscount: z
      .object({
        percentageDiscount: z.number().nullable().optional(),
        discountedWorkRate: z
          .array(
            z
              .object({
                workType: z.string().optional(),
                workRateWithoutVat: z
                  .object({
                    amount: z.number().nullable().optional(),
                    currency: z.string().nullable().optional(),
                  })
                  .nullable()
                  .optional(),
              })
              .nullable()
          )
          .optional(),
      })
      .nullable()
      .optional(),
    materialDiscount: z
      .object({
        discountToSalePrice: z.number().nullable().optional(),
        markupToPurchasePrice: z.number().nullable().optional(),
      })
      .nullable()
      .optional(),
  })
  .nullable();

export type GetServiceOrderItemsDiscountApiResponse = z.infer<
  typeof GetServiceOrderItemsDiscountApiResponseSchema
>;

export const GetServiceOrderItemsDiscountApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
});

export type GetServiceOrderItemsDiscountApiArg = z.infer<
  typeof GetServiceOrderItemsDiscountApiArgSchema
>;

export const PutServiceOrderItemsDiscountApiResponseSchema = BaseVoidResponseSchema;

export type PutServiceOrderItemsDiscountApiResponse = z.infer<
  typeof PutServiceOrderItemsDiscountApiResponseSchema
>;

export const PutServiceOrderItemsDiscountApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z
    .object({
      serviceOrderDiscount: z
        .object({
          workDiscount: z
            .object({
              percentageDiscount: z.number().nullable().optional(),
              discountedWorkRate: z
                .array(
                  z
                    .object({
                      workType: z.string(),
                      workRateWithoutVat: z
                        .object({
                          amount: z.number(),
                          currency: z.string(),
                        })
                        .nullable(),
                    })
                    .nullable()
                )
                .optional(),
            })
            .nullable()
            .optional(),
          materialDiscount: z
            .object({
              discountToSalePrice: z.number().nullable().optional(),
              markupToPurchasePrice: z.number().nullable().optional(),
            })
            .nullable()
            .optional(),
        })
        .nullable()
        .optional(),
    })
    .nullable(),
});

export type PutServiceOrderItemsDiscountApiArg = z.infer<
  typeof PutServiceOrderItemsDiscountApiArgSchema
>;

export const PostServiceOrderCustomDiscountsDeleteApiResponseSchema = BaseVoidResponseSchema;

export type PostServiceOrderCustomDiscountsDeleteApiResponse = z.infer<
  typeof PostServiceOrderCustomDiscountsDeleteApiResponseSchema
>;

export const PostServiceOrderCustomDiscountsDeleteApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z
    .object({
      isDiscountRemove: z.boolean(),
    })
    .nullable(),
});

export type PostServiceOrderCustomDiscountsDeleteApiArg = z.infer<
  typeof PostServiceOrderCustomDiscountsDeleteApiArgSchema
>;
