import {useMemo} from 'react';
import {FormSpy} from 'react-final-form';
import {useSelector} from 'react-redux';

import {isNotNil} from 'ramda-adjunct';

import {testIds} from '@omnetic-dms/routes';

import {parseDate} from 'shared';

import {
  selectInteriorMaterials,
  selectVehicleFeatures,
} from '../../../store/vehicleCatalogue/selectors';
import {VehicleCatalogueEnumOption} from '../../../types/VehicleCatalogueEnumOption';
import {FeatureMarketAnalysisCard} from '../../FeatureMarketAnalysisCard/FeatureMarketAnalysisCard';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';

export function FeaturesHighlights() {
  return (
    <FormSpy<VehicleCreateFormState>
      render={({values}) => {
        const {features, type, additionalInformation} = values;
        // Call to hook is ok, because render prop is a component
        /* eslint-disable react-hooks/rules-of-hooks */
        const featuresCatalogue = useSelector(selectVehicleFeatures(type));
        const interiorMaterials = useSelector(selectInteriorMaterials(type));

        const featureDefinitions = [
          ...flattenFeatures(featuresCatalogue),
          ...interiorMaterials.map((item) => ({
            value: item.value,
            label: item.label,
          })),
        ];

        const vehicleYear: number | null =
          (isNotNil(values?.manufacturedOnYear)
            ? parseInt(`${values?.manufacturedOnYear}`)
            : null) ??
          (isNotNil(values?.firstRegistrationDate)
            ? parseDate(values?.firstRegistrationDate).getFullYear()
            : null);

        const vehicleFeatures = useMemo(() => {
          const result = [...(features ?? [])];

          // Interior material pseudo-feature value (workaround based on Alpha implementation)
          const interiorMaterial = additionalInformation?.interiorMaterial;
          if (isNotNil(interiorMaterial)) {
            result.push(interiorMaterial);
          }

          return result;
        }, [features, additionalInformation?.interiorMaterial]);

        return (
          <FeatureMarketAnalysisCard
            data-testid={testIds.vehicles.create('features')}
            allFeatures={featureDefinitions}
            vehicleMake={values.make}
            vehicleModel={values.modelFamily}
            vehicleYear={vehicleYear}
            vehicleFeatures={vehicleFeatures}
          />
        );
      }}
    />
  );
}

function flattenFeatures(
  featureItems: readonly VehicleCatalogueEnumOption[]
): VehicleCatalogueEnumOption[] {
  return featureItems.flatMap((item) => {
    const {children, ...itemWithoutChildren} = item;
    return [...flattenFeatures(children || []), itemWithoutChildren];
  });
}
