export enum AuditCategoryUniqueKey {
  VEHICLE_DOCUMENTATION = 'VEHICLE_DOCUMENTATION',
  OVERALL_COMMENT = 'OVERALL_COMMENT',
  TESTDRIVE = 'TESTDRIVE',
  PHOTODOCUMENTATION = 'PHOTODOCUMENTATION',
  TECHNICAL_CONDITION = 'TECHNICAL_CONDITION',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  EXTERIOR_DAMAGE = 'EXTERIOR_DAMAGE',
  INTERIOR_DAMAGE = 'DAMAGE_TO_THE_INTERIOR',
  WHEELS_AND_TIRES = 'WHEELS_AND_TIRES',
  PAINT_THICKNESS = 'PAINT_THICKNESS',
  THE_SECOND_SET = 'THE_SECOND_SET',
  LF_WHEEL = 'LF_WHEEL',
  LR_WHEEL = 'LR_WHEEL',
  RR_WHEEL = 'RR_WHEEL',
  RF_WHEEL = 'RF_WHEEL',
  OVERALL_EVALUATION_OF_THE_EXTERIOR = 'OVERALL_EVALUATION_OF_THE_EXTERIOR',
  OVERALL_EVALUATION_OF_THE_INTERIOR = 'OVERALL_EVALUATION_OF_THE_INTERIOR',
  OVERALL_EVALUATION_OF_THE_TECHNICAL_CONDITION = 'OVERALL_EVALUATION_OF_THE_TECHNICAL_CONDITION',
  EQUIPMENT = 'EQUIPMENT',
  EQUIPMENT_VALIDATION = 'EQUIPMENT_VALIDATION',
  EQUIPMENT_INSPECTION = 'EQUIPMENT_INSPECTION',
  THE_DIMENSION_CORRESPONDS_TO_THE_REGISTRATION_CERTIFICATE_CERTIFICATE_OF_CONFORMITY = 'THE_DIMENSION_CORRESPONDS_TO_THE_REGISTRATION_CERTIFICATE_CERTIFICATE_OF_CONFORMITY',

  LF_LIGHT = 'LF_LIGHT',
  LF_DOOR = 'LF_DOOR',
  L_MIRROR = 'L_MIRROR',
  RF_DOOR = 'RF_DOOR',
  R_MIRROR = 'R_MIRROR',
  OTHER = 'OTHER',

  COLUMN_TRIM_ON_THE_LEFT = 'COLUMN_TRIM_ON_THE_LEFT',
  DRIVING_WHEEL = 'DRIVING_WHEEL',
  COLUMN_TRIM_ON_THE_RIGHT = 'COLUMN_TRIM_ON_THE_RIGHT',
  DRIVERS_SEAT = 'DRIVERS_SEAT',
  PASSENGER_SEAT = 'PASSENGER_SEAT',
  CEILINGS = 'CEILINGS',
  CARPET = 'CARPET',
  SUITCASE = 'SUITCASE', // TRUNK
  OTHERS = 'OTHERS',

  VEHICLE_NON_SMOKING = 'VEHICLE_NON_SMOKING',
  NO_ODOR_INDOORS = 'NO_ODOR_INDOORS',

  MORE_EQUIPMENT = 'MORE_EQUIPMENT',
  MECHANICS = 'MECHANICS',

  OTHER_DEFINE_IN_NOTE = 'OTHER_DEFINE_IN_NOTE',
  OTHER_DEFINE_IN_THE_NOTE = 'OTHER_DEFINE_IN_THE_NOTE',

  MORE_PHOTOS = 'MORE_PHOTOS',
  YEAR_OF_PRODUCTION = 'YEAR_OF_PRODUCTION',
  NUMBER_OF_VEHICLE_KEYS = 'NUMBER_OF_VEHICLE_KEYS',
  NUMBER_OF_KEYS_FROM_MECHANICAL_SECURITY = 'NUMBER_OF_KEYS_FROM_MECHANICAL_SECURITY',
  REGISTRATION_CERTIFICATE = 'REGISTRATION_CERTIFICATE',
  NUMBER_OF_KEYS_FROM_THE_TOWING_DEVICE = 'NUMBER_OF_KEYS_FROM_THE_TOWING_DEVICE',
  VEHICLE_CRASHED_IN_THE_PAST = 'VEHICLE_CRASHED_IN_THE_PAST',
  THE_VEHICLE_SUFFERED_HAIL_DAMAGE = 'THE_VEHICLE_SUFFERED_HAIL_DAMAGE',
  THE_VEHICLE_MODIFIED_AGAINST_PRODUCTION_TUNING = 'THE_VEHICLE_MODIFIED_AGAINST_PRODUCTION_TUNING',
  OPERATING_INSTRUCTIONS = 'OPERATING_INSTRUCTIONS',
  ADDITIONAL_VEHICLE_DOCUMENTATION = 'ADDITIONAL_VEHICLE_DOCUMENTATION',
  VAT_DEDUCTION = 'VAT_DEDUCTION',
  SERVICE_BOOK = 'SERVICE_BOOK',
  ALL_CHILDREN = 'ALL_CHILDREN',

  VIDEOS = 'VIDEOS',
}
