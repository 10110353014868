import {object, string} from 'yup';

import {ScopeValues} from '@omnetic-dms/api';

export const evaluateServiceCaseType = (scopes: string[], evaluatorParams: ScopeValues) => {
  const {serviceCaseType} = validateServiceCaseEvaluatorParams(evaluatorParams);

  return scopes.includes(serviceCaseType);
};

// export only for testing purposes
export const validateServiceCaseEvaluatorParams = (evaluatorParams: ScopeValues | null) =>
  serviceCaseParamsSchema.validateSync(evaluatorParams);

const serviceCaseParamsSchema = object({
  serviceCaseType: string().required(),
});
