import {isNotNil} from 'ramda-adjunct';

import {Nullish} from 'shared';

const percentage = (x: number, y: number) =>
  isNotNil(x) && isNotNil(y) ? `${Math.round((x / y) * 100)}%` : '';

export const getVehicleDetailPosition = (
  measureCarRank: number | Nullish,
  measureCountRelevantCar: number | Nullish
) => [
  `${measureCarRank ?? '?'}/${measureCountRelevantCar ?? '?'}`,
  isNotNil(measureCarRank) && isNotNil(measureCountRelevantCar)
    ? percentage(measureCarRank, measureCountRelevantCar)
    : '',
];
