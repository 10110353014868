import {DataStatus, Form, FormField} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';

import {
  useGetBranchListQuery,
  useGetManufacturersQuery,
  useGetWarehousesQuery,
} from '@omnetic-dms/api';
import {i18n} from '@omnetic-dms/i18n';
import {
  getOptionsFromBranches,
  getOptionsFromManufacturers,
  getOptionsFromWarehouses,
  getSearchTypeOptions,
} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {FormToDataGridConnectorProps} from 'features/datagrid';

type MaterialWarehouseTabExternalFiltersForm = Partial<{
  manufacturerNumber: string;
  searchType: string;
  branchId: string;
  warehouseId: string;
  manufacturerId: string;
  supplierId: string;
  isAvailable: boolean;
  isInStock: boolean;
}>;

type MaterialWarehouseTabExternalFiltersProps = FormToDataGridConnectorProps & TestIdProps;

export function MaterialWarehouseTabExternalFilters(
  props: MaterialWarehouseTabExternalFiltersProps
) {
  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery();

  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError,
  } = useGetManufacturersQuery();

  const {
    data: branches,
    isLoading: branchesLoading,
    isError: isBranchesError,
  } = useGetBranchListQuery();

  const isLoading = isWarehousesLoading || isManufacturersLoading || branchesLoading;
  const isError = isWarehousesError || isManufacturersError || isBranchesError;

  const searchTypeOptions = getSearchTypeOptions();
  const defaultSearchType = searchTypeOptions[0].value;

  const values: MaterialWarehouseTabExternalFiltersForm = {
    ...props.values,
    searchType: props.values.searchType ?? defaultSearchType,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<MaterialWarehouseTabExternalFiltersForm>
        values={values}
        defaultValues={{}}
        onChange={props.onChange}
      >
        {(control) => (
          <VStack spacing={4}>
            <Grid columns={3} align="flex-end">
              <GridItem span={2}>
                <FormField
                  control={control}
                  name="manufacturerNumber"
                  type="text"
                  label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                  data-testid={suffixTestId('catalogueNumber', props)}
                />
              </GridItem>
              <GridItem span={1}>
                <FormField
                  control={control}
                  name="searchType"
                  type="choice"
                  options={searchTypeOptions}
                  isNotClearable
                  data-testid={suffixTestId('filteringMethod', props)}
                />
              </GridItem>
            </Grid>
            <FormField
              control={control}
              name="branchId"
              type="choice"
              label={i18n.t('entity.branch.labels.branch')}
              options={getOptionsFromBranches(branches)}
              data-testid={suffixTestId('branch', props)}
            />
            <FormField
              control={control}
              name="warehouseId"
              type="multiChoice"
              label={i18n.t('entity.warehouse.labels.warehouse')}
              options={getOptionsFromWarehouses(warehouses)}
              data-testid={suffixTestId('warehouse', props)}
            />
            <FormField
              control={control}
              name="manufacturerId"
              type="multiChoice"
              label={i18n.t('entity.warehouse.labels.manufacturer')}
              options={getOptionsFromManufacturers(manufacturers)}
              data-testid={suffixTestId('manufacturer', props)}
            />
            <FormField
              control={control}
              name="supplierId"
              type="choice"
              label={i18n.t('entity.warehouse.labels.supplier')}
              options={[]}
              data-testid={suffixTestId('supplier', props)}
            />
            <FormField
              control={control}
              name="isAvailable"
              label={i18n.t('entity.warehouse.labels.availableOnly')}
              type="checkbox"
              data-testid={suffixTestId('availableOnly', props)}
            />
            <FormField
              control={control}
              name="isInStock"
              label={i18n.t('entity.warehouse.labels.inStock')}
              type="checkbox"
              data-testid={suffixTestId('inStock', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
