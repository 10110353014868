import {Active, Over} from '@dnd-kit/core';

import {isNil} from 'ramda';

import {Nullish} from 'shared';

export function getSortableContainerId(event: Active | Over | Nullish) {
  if (isNil(event)) {
    return null;
  }

  return (event.data?.current?.sortable?.containerId as string) ?? null;
}
