import {renameKeysWith} from 'ramda-adjunct';

import {
  CreateCustomerAddressApiArg,
  CreateCustomerAddressApiResponse,
  CreateCustomerAddressV2ApiArg,
  CreateCustomerAddressV2ApiResponse,
  CreateCustomerApiArg,
  CreateCustomerApiResponse,
  CreateCustomerCommentApiArg,
  CreateCustomerCommentApiResponse,
  CreateCustomerContactApiArg,
  CreateCustomerContactApiResponse,
  CreateCustomerContractInformationApiArg,
  CreateCustomerContractInformationApiResponse,
  CustomerAdvancedSearchApiArg,
  CustomerAdvancedSearchApiResponse,
  DeleteCustomerAddressApiArg,
  DeleteCustomerAddressApiResponse,
  DeleteCustomerApiArg,
  DeleteCustomerApiResponse,
  DeleteCustomerCommentApiArg,
  DeleteCustomerCommentApiResponse,
  DeleteCustomerContactApiArg,
  DeleteCustomerContactApiResponse,
  DeleteCustomerContractInformationApiArg,
  DeleteCustomerContractInformationApiResponse,
  GetCustomerAddressListV2ApiArg,
  GetCustomerAddressListV2ApiResponse,
  GetCustomerCommentApiArg,
  GetCustomerCommentApiResponse,
  GetCustomerCommentListApiArg,
  GetCustomerCommentListApiResponse,
  GetCustomerContactV2ApiArg,
  GetCustomerContactV2ApiResponse,
  GetCustomerNoteApiArg,
  GetCustomerNoteApiResponse,
  GetCustomerSeriesDefinitionApiArg,
  GetCustomerSeriesDefinitionApiResponse,
  GetCustomerSeriesDefinitionListApiArg,
  GetCustomerSeriesDefinitionListApiResponse,
  GetCustomersV2ApiArg,
  GetCustomersV2ApiResponse,
  GetCustomerV2ApiArg,
  GetCustomerV2ApiResponse,
  GetSimilarCustomersApiArg,
  GetSimilarCustomersApiResponse,
  GetVerificationRegistersApiArg,
  GetVerificationRegistersApiResponse,
  ParsePersonalIdApiArg,
  ParsePersonalIdApiResponse,
  PatchCustomerAddressV2ApiArg,
  PatchCustomerAddressV2ApiResponse,
  PatchCustomerApiArg,
  PatchCustomerApiResponse,
  PatchCustomerCommentApiArg,
  PatchCustomerCommentApiResponse,
  PatchCustomerContactApiArg,
  ListCustomerContractInformationsApiArg,
  ListCustomerContractInformationsApiResponse,
  PatchCustomerContactApiResponse,
  PatchCustomerContractInformationApiArg,
  PatchCustomerContractInformationApiResponse,
  PutCustomerNoteApiArg,
  PutCustomerNoteApiResponse,
  VerifyCustomerContractInformationApiArg,
  VerifyCustomerContractInformationApiResponse,
  PutCustomerPaymentSettingsApiResponse,
  PutCustomerPaymentSettingsApiArg,
} from '../types/customer';
import {omneticApi} from './baseApi/omneticApi';

export const customerApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getSimilarCustomers: build.query<GetSimilarCustomersApiResponse, GetSimilarCustomersApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/similar/${queryArg.customerDiscriminator}`,
        params: {
          size: queryArg.size,
          page: queryArg.page,
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          phoneNumber: queryArg.phoneNumber,
          email: queryArg.email,
          registrationNumber: queryArg.registrationNumber,
          tradeName: queryArg.tradeName,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getCustomerSeriesDefinitionList: build.query<
      GetCustomerSeriesDefinitionListApiResponse,
      GetCustomerSeriesDefinitionListApiArg
    >({
      query: () => ({
        url: `/dms/v1/customerV2/series-definition`,
      }),
    }),
    getCustomerSeriesDefinition: build.query<
      GetCustomerSeriesDefinitionApiResponse,
      GetCustomerSeriesDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/series-definition/${queryArg.seriesDefinitionId}`,
      }),
    }),
    createCustomerAddress: build.mutation<
      CreateCustomerAddressApiResponse,
      CreateCustomerAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/address`,
        method: 'POST',
        body: queryArg.addressRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    deleteCustomerAddress: build.mutation<
      DeleteCustomerAddressApiResponse,
      DeleteCustomerAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/address/${queryArg.addressId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    createCustomerComment: build.mutation<
      CreateCustomerCommentApiResponse,
      CreateCustomerCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/comment`,
        method: 'POST',
        body: queryArg.commentRequestBody,
      }),
      invalidatesTags: ['customerComments'],
    }),
    deleteCustomerComment: build.mutation<
      DeleteCustomerCommentApiResponse,
      DeleteCustomerCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/comment/${queryArg.commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['customerComments'],
    }),
    getCustomerComment: build.query<GetCustomerCommentApiResponse, GetCustomerCommentApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/comment/${queryArg.commentId}`,
      }),
    }),
    patchCustomerComment: build.mutation<
      PatchCustomerCommentApiResponse,
      PatchCustomerCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/comment/${queryArg.commentId}`,
        method: 'PATCH',
        body: queryArg.commentRequestBody,
      }),
      invalidatesTags: ['customerComments'],
    }),
    getCustomerCommentList: build.query<
      GetCustomerCommentListApiResponse,
      GetCustomerCommentListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/comments`,
      }),
      providesTags: ['customerComments'],
    }),
    createCustomerContact: build.mutation<
      CreateCustomerContactApiResponse,
      CreateCustomerContactApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/contact`,
        method: 'POST',
        body: queryArg.personRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    deleteCustomerContact: build.mutation<
      DeleteCustomerContactApiResponse,
      DeleteCustomerContactApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/contact/${queryArg.contactId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    patchCustomerContact: build.mutation<
      PatchCustomerContactApiResponse,
      PatchCustomerContactApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/contact/${queryArg.contactId}`,
        method: 'PATCH',
        body: queryArg.personRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'Customer', id: queryArg.customerId},
        'Checkout',
      ],
    }),
    createCustomerContractInformation: build.mutation<
      CreateCustomerContractInformationApiResponse,
      CreateCustomerContractInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/contracting-information`,
        method: 'POST',
        body: queryArg.contractInformationRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    deleteCustomerContractInformation: build.mutation<
      DeleteCustomerContractInformationApiResponse,
      DeleteCustomerContractInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/contracting-information/${queryArg.contractInformationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    patchCustomerContractInformation: build.mutation<
      PatchCustomerContractInformationApiResponse,
      PatchCustomerContractInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/contracting-information/${queryArg.contractInformationId}`,
        method: 'PATCH',
        body: queryArg.contractInformationRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'Customer', id: queryArg.customerId},
        'Checkout',
      ],
    }),
    createCustomer: build.mutation<CreateCustomerApiResponse, CreateCustomerApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2`,
        method: 'POST',
        body: queryArg.createCustomerRequestBody,
      }),
      invalidatesTags: ['Customers'],
    }),
    deleteCustomer: build.mutation<DeleteCustomerApiResponse, DeleteCustomerApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}`,
        method: 'DELETE',
      }),
    }),
    patchCustomer: build.mutation<PatchCustomerApiResponse, PatchCustomerApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}`,
        method: 'PATCH',
        body: queryArg.customerRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    getCustomerNote: build.query<GetCustomerNoteApiResponse, GetCustomerNoteApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/note`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'customerNote', id: queryArg.customerId}],
    }),
    putCustomerNote: build.mutation<PutCustomerNoteApiResponse, PutCustomerNoteApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/note`,
        method: 'PUT',
        body: queryArg.noteRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerNote', id: queryArg.customerId},
      ],
    }),
    parsePersonalId: build.mutation<ParsePersonalIdApiResponse, ParsePersonalIdApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/parse-personal-id`,
        method: 'PUT',
        body: queryArg.parsePersonalIdRequestBody,
      }),
    }),
    verifyCustomerContractInformation: build.mutation<
      VerifyCustomerContractInformationApiResponse,
      VerifyCustomerContractInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/contracting-information/${queryArg.contractInformationId}/verify`,
        method: 'PUT',
        body: queryArg.verifyContractInformationRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'CustomerVerificationRegisters', id: queryArg.contractInformationId},
      ],
    }),
    listCustomerContractInformations: build.query<
      ListCustomerContractInformationsApiResponse,
      ListCustomerContractInformationsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/contract-information`,
      }),
      providesTags: (_, __, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    getVerificationRegisters: build.query<
      GetVerificationRegistersApiResponse,
      GetVerificationRegistersApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/${queryArg.customerId}/contract-information/${queryArg.contractInformationId}/registers`,
        params: {recordId: queryArg.recordId, resourceId: queryArg.resourceId},
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'CustomerVerificationRegisters', id: queryArg.contractInformationId},
      ],
    }),
    createCustomerAddressV2: build.mutation<
      CreateCustomerAddressV2ApiResponse,
      CreateCustomerAddressV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/customer/${queryArg.customerId}/address`,
        method: 'POST',
        body: queryArg.addressRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    getCustomerAddressListV2: build.query<
      GetCustomerAddressListV2ApiResponse,
      GetCustomerAddressListV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/customer/${queryArg.customerId}/address`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    patchCustomerAddressV2: build.mutation<
      PatchCustomerAddressV2ApiResponse,
      PatchCustomerAddressV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/customer/${queryArg.customerId}/address/${queryArg.addressId}`,
        method: 'PUT',
        body: queryArg.addressRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    getCustomerContactV2: build.query<GetCustomerContactV2ApiResponse, GetCustomerContactV2ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/customer/${queryArg.customerId}/contact/${queryArg.contactId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    getCustomerV2: build.query<GetCustomerV2ApiResponse, GetCustomerV2ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/customer/${queryArg.customerId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
    getCustomersV2: build.query<GetCustomersV2ApiResponse, GetCustomersV2ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/customer`,
        // In order to send paramteres in correct format for our PHP BE
        // https://stackoverflow.com/questions/6243051/how-to-pass-an-array-within-a-query-string
        params: renameKeysWith((index) => `institutions[${index}]`, queryArg.institutions ?? []),
        providesTags: ['Customers'],
      }),
    }),
    customerAdvancedSearch: build.query<
      CustomerAdvancedSearchApiResponse,
      CustomerAdvancedSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/customerV2/advanced-search`,
        params: {
          name: queryArg.name,
          phoneNumber: queryArg.phoneNumber,
          email: queryArg.email,
          legalNumber: queryArg.legalNumber,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    putCustomerPaymentSettings: build.mutation<
      PutCustomerPaymentSettingsApiResponse,
      PutCustomerPaymentSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/customer/${queryArg.customerId}/payment-settings`,
        method: 'PUT',
        body: queryArg.paymentSettingsRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Customer', id: queryArg.customerId}],
    }),
  }),
});

export const {
  useGetSimilarCustomersQuery,
  useGetCustomerSeriesDefinitionListQuery,
  useGetCustomerSeriesDefinitionQuery,
  useCreateCustomerAddressMutation,
  useDeleteCustomerAddressMutation,
  useCreateCustomerCommentMutation,
  useDeleteCustomerCommentMutation,
  useGetCustomerCommentQuery,
  usePatchCustomerCommentMutation,
  useGetCustomerCommentListQuery,
  useCreateCustomerContactMutation,
  useDeleteCustomerContactMutation,
  usePatchCustomerContactMutation,
  useCreateCustomerContractInformationMutation,
  useDeleteCustomerContractInformationMutation,
  usePatchCustomerContractInformationMutation,
  useCreateCustomerMutation,
  useDeleteCustomerMutation,
  usePatchCustomerMutation,
  useGetCustomerNoteQuery,
  usePutCustomerNoteMutation,
  useParsePersonalIdMutation,
  useLazyGetCustomerV2Query,
  useListCustomerContractInformationsQuery,
  useVerifyCustomerContractInformationMutation,
  useGetVerificationRegistersQuery,
  useGetCustomerAddressListV2Query,
  useGetCustomerContactV2Query,
  useGetCustomerV2Query,
  useGetCustomersV2Query,
  usePatchCustomerAddressV2Mutation,
  useCreateCustomerAddressV2Mutation,
  useLazyGetCustomerAddressListV2Query,
  useLazyGetCustomerContactV2Query,
  useCustomerAdvancedSearchQuery,
  usePutCustomerPaymentSettingsMutation,
} = customerApi;
