import {BaseFlagProps, ColorSchemeType, Flags} from 'platform/components';
import {Box, Center, Clickable, HStack, Hide, Icon, Show, Text, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {
  DashboardBusinessCaseResponseBody,
  EntityResourceIds,
  useGetParticipationQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes} from '@omnetic-dms/routes';
import {
  getBusinessCaseStateFlag,
  getBusinessCaseTradeTypeFlag,
  getBusinessCaseTypeFlag,
  usePermissions,
} from '@omnetic-dms/shared';

import {TestIdProps, buildArray, composePath, suffixTestId, useNavigate} from 'shared';

import {usePriceFormatter} from '../../hooks/usePriceFormatter';

interface LatestBusinessCaseItemProps extends TestIdProps {
  businessCase: DashboardBusinessCaseResponseBody;
}

export const LatestBusinessCaseItem = (props: LatestBusinessCaseItemProps) => {
  const navigate = useNavigate();
  const {formatPrice} = usePriceFormatter();

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: props.businessCase.id,
    resourceId: EntityResourceIds.businessCase,
  });

  const [canReadTag, canViewBusinessCasePurchase, canViewBusinessCaseSelling] = usePermissions({
    permissionKeys: ['businessCaseTagRead', 'viewBusinessCasePurchase', 'viewBusinessCaseSelling'],
    scopes: {
      businessCaseTagRead: businessCaseParticipation,
      viewBusinessCasePurchase: businessCaseParticipation,
      viewBusinessCaseSelling: businessCaseParticipation,
    },
  });

  const canViewLatestBusinessCase = canViewBusinessCasePurchase || canViewBusinessCaseSelling;

  const onClickOpenBusinessCaseOverview = () => {
    navigate(composePath(businessCaseRoutes.overview, {params: {id: props.businessCase.id}}));
  };

  const flags = generateBusinessCaseFlags(props.businessCase, canReadTag);

  if (!canViewLatestBusinessCase) {
    return null;
  }

  return (
    <Clickable onClick={onClickOpenBusinessCaseOverview}>
      <HStack spacing={4} minWidth={100}>
        <Box
          minWidth={10}
          maxWidth={10}
          height={10}
          backgroundColor="palettes.neutral.30.100"
          borderRadius="small"
          overflow="hidden"
          flex={1}
        >
          <Center height="100%">
            <Icon value="places/business_center" size={6} color="palettes.neutral.800.100" />
          </Center>
        </Box>
        <Box flex={3}>
          <VStack spacing={1}>
            <HStack spacing={1}>
              <Flags
                spacing={1}
                flags={flags}
                shouldWrap
                data-testid={suffixTestId('latest-businessCase-item-flags', props)}
              />
            </HStack>
            <Text alternative>{props.businessCase.customer}</Text>
          </VStack>
        </Box>
        <Box flex={3}>
          <VStack spacing={1} grow={1}>
            <HStack>
              <Show when={isNotNil(props.businessCase.owner)}>
                <Text size="xSmall">{props.businessCase.owner}</Text>
              </Show>
              <Hide when={isNotNil(props.businessCase.owner)}>
                <Text size="xSmall">{i18n.t('general.labels.unassigned')}</Text>
              </Hide>
            </HStack>
            <Text alternative>{props.businessCase.vehicle}</Text>
          </VStack>
        </Box>
        <Box flex={3} paddingTop={4}>
          <VStack align="flex-end">
            <HStack grow={1} align="flex-end">
              <Text alternative>{formatPrice(props.businessCase.price)}</Text>
            </HStack>
          </VStack>
        </Box>
      </HStack>
    </Clickable>
  );
};

function generateBusinessCaseFlags(
  businessCase: DashboardBusinessCaseResponseBody,
  canReadTag?: boolean
): BaseFlagProps[] {
  const typeFlag = getBusinessCaseTypeFlag(businessCase.type);
  const stateFlag = getBusinessCaseStateFlag(businessCase.state);
  const stockTypeFlag = getBusinessCaseTradeTypeFlag(businessCase.tradeType);

  const flags = buildArray<BaseFlagProps>()
    .when(isNotNil(typeFlag), typeFlag as BaseFlagProps)
    .when(isNotNil(stockTypeFlag), stockTypeFlag as BaseFlagProps)
    .when(isNotNil(stateFlag), stateFlag as BaseFlagProps);

  flags.forEach((flag) => (flag.size = 'small'));

  if (canReadTag) {
    businessCase.tags.map((tag) =>
      flags.add({
        label: tag.label,
        size: 'small',
        isSubtle: true,
        colorScheme: tag.color as ColorSchemeType,
      })
    );
  }

  return flags;
}
