import {Action, ButtonVariant} from 'platform/components';

import {isEmpty, isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {HeaderActionType, HeaderDynamicActionsResponse} from '@omnetic-dms/api';

import {buildArray, Nullish} from 'shared';

type HeaderActionCallback = (key: string, type?: HeaderActionType) => void;

type CreateHeaderActionsConfig = {
  actions: HeaderDynamicActionsResponse['actions'] | Nullish;
  callback: HeaderActionCallback;
  isLoading?: boolean;
};

const DEFAULT_ACTION_TYPE: HeaderActionType = 'BE';

/**
 * Creates header actions based on the provided configuration.
 *
 * @param {CreateHeaderActionsConfig} props - The configuration object for creating header actions.
 * @param {Object} props.actions - Actions configuration.
 * @param {Array<Object>} props.actions.options - List of action options available for the header.
 * @param {string} [props.actions.optionClick] - Key of the action that represents the primary option to click.
 * @param {boolean} [props.isLoading] - Indicates whether the actions are in a loading state.
 * @param {Function} props.callback - Callback function invoked when an action is clicked.
 *
 * @returns {Action[]} An array of header actions. Each action represents a button, dropdown, or other actionable UI elements.
 *
 * @example
 * Example usage of createHeaderActions:
 * const config = {
 *   actions: {
 *     options: [
 *       { key: 'edit', title: 'Edit', style: 'primary', position: 1 },
 *       { key: 'delete', title: 'Delete', style: 'secondary', position: 2 },
 *       { key: 'share', title: 'Share', style: 'hidden', position: 3 },
 *     ],
 *     optionClick: 'edit',
 *   },
 *   isLoading: false,
 *   callback: (key, type) => {
 *     console.log(`Action clicked: ${key}, Type: ${type}`);
 *   },
 * };
 *
 * const headerActions = createHeaderActions(config);
 */
export const createHeaderActions = (props: CreateHeaderActionsConfig): Action[] => {
  if (isNil(props.actions) || isEmpty(props.actions.options)) {
    return [];
  }

  const optionClick = props.actions.options.find(
    (option) => option.key === props.actions?.optionClick
  );

  const contextOptions = props.actions.options
    .filter((option) => option.key !== props.actions?.optionClick && option.style !== 'hidden')
    .sort((a, b) => a.position - b.position);

  return buildArray<Action>()
    .when(isNotNilOrEmpty(contextOptions), {
      type: 'dropdown-iconButton',
      icon: 'navigation/more_vert',
      priority: 'secondary',
      menuItems: contextOptions.map((contextOption) => ({
        variant: 'primary',
        isDisabled: contextOption.style === 'disabled' ?? false,
        isLoading: props.isLoading ?? false,
        label: contextOption.title ?? contextOption.key,
        tooltip: {
          label: contextOption.tooltip?.label,
          description: contextOption.tooltip?.description,
        },
        onClick: (event) => {
          event.stopPropagation();
          props.callback(contextOption.key, contextOption.type ?? DEFAULT_ACTION_TYPE);
        },
      })),
    })
    .when(props.actions?.optionClick, {
      type: 'buttonWithTooltip',
      title: optionClick?.title ?? optionClick?.key ?? '',
      variant: (optionClick?.variant as ButtonVariant) ?? 'primary',
      isDisabled: optionClick?.style === 'disabled' ?? false,
      isLoading: props.isLoading ?? false,
      tooltip: {
        label: optionClick?.tooltip?.label,
        description: optionClick?.tooltip?.description,
      },
      onClick: (event) => {
        event.stopPropagation();
        props.callback(
          props.actions?.optionClick as string,
          optionClick?.type ?? DEFAULT_ACTION_TYPE
        );
      },
    });
};
