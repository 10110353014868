import {ThemeIconKey, Icon, HStack, Space, Text, VStack} from 'platform/foundation';

import {ReactNode} from 'react';

import {toUpper} from 'ramda';

import {EMPTY_PLACEHOLDER} from 'shared';

interface VehicleSpecificationColumnProps {
  label: string;
  icon: ThemeIconKey;
  value: ReactNode;
}

export function VehicleSpecificationColumn(props: VehicleSpecificationColumnProps) {
  return (
    <VStack minWidth={32}>
      <Text size="small" color="tertiary">
        {toUpper(props.label)}
      </Text>
      <Space vertical={2} />
      <HStack align="center" spacing={2}>
        <Icon value={props.icon} color="palettes.neutral.800.100" size={3} />
        <Text>{props.value ?? EMPTY_PLACEHOLDER}</Text>
      </HStack>
    </VStack>
  );
}
