import type {PermissionsRecordType} from '../permissions';

export const warehousePermissions = {
  vehicleWarehouseRead: {
    actionId: 'READ',
    resourceId: 'VEHICLE_WAREHOUSE',
    scopes: [],
  },
  vehicleWarehouseCreate: {
    actionId: 'CREATE',
    resourceId: 'VEHICLE_WAREHOUSE',
    scopes: [],
  },
  vehicleWarehouseEdit: {
    actionId: 'UPDATE',
    resourceId: 'VEHICLE_WAREHOUSE',
    scopes: [],
  },
  pneuhotelRead: {
    resourceId: 'TIRE_ORDER',
    actionId: 'READ',
    scopes: [],
  },
  articleRead: {
    resourceId: 'ARTICLE',
    actionId: 'READ',
    scopes: [],
  },
} satisfies PermissionsRecordType;
