import {Box, HStack} from 'platform/foundation';

import {FC, PropsWithChildren} from 'react';
import {useFormContext} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';
import {FormLabel, useFields} from '@omnetic-dms/teas';

import {DocumentRoundingDefinitionFormType} from './types';
import {roundingDefinitionCtx} from './utils';

export const HeaderSection: FC<PropsWithChildren<any>> = () => {
  const form = useFormContext<DocumentRoundingDefinitionFormType>();

  const edit = roundingDefinitionCtx.useContextWithPath('edit');
  const branchList = roundingDefinitionCtx.useContextWithPath('branches');
  const paymentTypeList = roundingDefinitionCtx.useContextWithPath('paymentTypes');

  const fields = useFields<DocumentRoundingDefinitionFormType>(form);

  const isSystem = form.watch('isSystem');
  const [branchId, paymentType] = form.watch(['branchId', 'paymentType']);

  return (
    <HStack spacing={4}>
      {edit || isSystem ? (
        <>
          <Box flex={1}>
            {fields.hidden({name: ['branchId'], defaultValue: ''})}
            <div>
              <FormLabel>{i18n.t('entity.branch.labels.branch')}</FormLabel>
              {(branchId && branchList.find((item) => item.key === branchId)?.value) || '-'}
            </div>
          </Box>
          <Box flex={1}>
            {fields.hidden({name: ['paymentType'], defaultValue: ''})}
            <div>
              <FormLabel>{i18n.t('entity.bank.labels.paymentType')}</FormLabel>
              {(paymentType && paymentTypeList.find((item) => item.key === paymentType)?.value) ||
                '-'}
            </div>
          </Box>
        </>
      ) : (
        <>
          <Box flex={1}>
            {fields.dropdown({
              name: ['branchId'],
              defaultValue: '',
              options: branchList,
              preventTrigger: true,
              getOptionLabel: (opt) => opt.value,
              getOptionValue: (opt) => opt?.key ?? '',
              dropdownProps: {
                placeholder: i18n.t('general.labels.selectFromList'),
                label: i18n.t('entity.branch.labels.branch'),
              },
            })}
          </Box>
          <Box flex={1}>
            {fields.dropdown({
              name: ['paymentType'],
              defaultValue: '',
              options: paymentTypeList,
              preventTrigger: true,
              getOptionLabel: (opt) => opt.value,
              getOptionValue: (opt) => opt?.key ?? '',
              dropdownProps: {
                placeholder: i18n.t('general.labels.selectFromList'),
                label: i18n.t('entity.bank.labels.paymentType'),
              },
            })}
          </Box>
        </>
      )}
    </HStack>
  );
};
