import {Button, ButtonGroup, closeDialog} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {SUPPLIER_ARTICLE_PROMPT} from '../constants/supplierArticlePrompt';

interface SupplierArticlePromptProps extends RequiredTestIdProps {
  onDontSave: () => Promise<void>;
  onSave: () => Promise<void>;
}

export function SupplierArticlePrompt(props: SupplierArticlePromptProps) {
  const [isLoading, startLoading] = useBoolean(false);

  const handleDontSave = async () => {
    startLoading();

    await props.onDontSave();
    closeDialog(SUPPLIER_ARTICLE_PROMPT);
  };

  const handleSave = async () => {
    startLoading();

    await props.onSave();
    closeDialog(SUPPLIER_ARTICLE_PROMPT);
  };

  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <Heading size={4}>{i18n.t('entity.warehouse.labels.supplierArticlePromptMessage')}</Heading>
      <ButtonGroup align="right">
        <Button
          type="button"
          variant="secondary"
          title={i18n.t('general.actions.dontSave')}
          onClick={handleDontSave}
          isLoading={isLoading}
          data-testid={suffixTestId('actions.dontSave', props)}
        />
        <Button
          type="button"
          variant="primary"
          title={i18n.t('general.actions.save')}
          onClick={handleSave}
          isLoading={isLoading}
          data-testid={suffixTestId('actions.save', props)}
        />
      </ButtonGroup>
    </VStack>
  );
}
