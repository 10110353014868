import {DataStatus} from 'platform/components';

import {useGetAdditionalNoteQuery, useGetVehicleV2Query} from '@omnetic-dms/api';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ServiceVehicleFormLogic} from './components/ServiceVehicleFormLogic';
import {ServiceVehicleFormMode} from './types/ServiceVehicleFormMode';
import {ServiceVehicleFormType} from './types/ServiceVehicleFormType';
import {ServiceVehicleFormVariant} from './types/ServiceVehicleFormVariant';
import {convertVehicleToFormData} from './utils/convertVehicleToFormData';

interface ServiceVehicleFormProps extends RequiredTestIdProps {
  mode: ServiceVehicleFormMode;
  variant: ServiceVehicleFormVariant;
  vehicleId?: string;
  extendedDefaultFormData?: Partial<ServiceVehicleFormType>;
  onSuccess: (newVehicleId: string) => void;
  onDiscard: () => void;
}

export function ServiceVehicleForm(props: ServiceVehicleFormProps) {
  const fetchVehicleDetail = props.mode !== ServiceVehicleFormMode.NEW;

  const vehicleDetailQuery = useGetVehicleV2Query(
    {vehicleId: props.vehicleId || ''},
    {skip: !fetchVehicleDetail}
  );

  const additionalNoteQuery = useGetAdditionalNoteQuery(
    {vehicleId: props.vehicleId || ''},
    {skip: !fetchVehicleDetail}
  );

  const isLoading = vehicleDetailQuery.isLoading || additionalNoteQuery.isLoading;
  const isError = vehicleDetailQuery.isError; // we don't need note to success. If it's empty BE returns 404 anyway

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <ServiceVehicleFormLogic
        mode={props.mode}
        variant={props.variant}
        vehicleData={vehicleDetailQuery.data!}
        defaultFormData={convertVehicleToFormData(
          vehicleDetailQuery.data,
          additionalNoteQuery.data?.note
        )}
        extendedDefaultFormData={props.extendedDefaultFormData}
        onSuccess={props.onSuccess}
        onDiscard={props.onDiscard}
        data-testid={suffixTestId('seve', props)}
      />
    </DataStatus>
  );
}
