import {FormControl, FormField} from 'platform/components';
import {match, Pattern} from 'ts-pattern';

import {FieldValues, Path} from 'react-hook-form';
import {MenuPlacement} from 'react-select';

import {always} from 'ramda';
import {isNilOrEmpty, isTrue} from 'ramda-adjunct';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {useVehicleModel} from '../../hooks/useVehicleModel';

type ModelSelectProps<TFieldValues extends FieldValues = FieldValues> = {
  vehicleType: string | Nullish;
  makes: string | string[] | Nullish;
  control: FormControl<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isNotClearable?: boolean;
  isMenuInPortal?: boolean;
  menuPlacement?: MenuPlacement;
  onChange?: (value: string | number | string[] | null) => void;
} & RequiredTestIdProps;

export function ModelSelect<TFieldValues extends FieldValues = FieldValues>(
  props: ModelSelectProps<TFieldValues>
) {
  const makes = match(props.makes)
    .with(Pattern.string, (makes) => [makes])
    .with(Pattern.array(Pattern.string), (makes) => makes)
    .otherwise(always(null));

  const [
    {groupedModelOptionsByMake, groupedModelOptionsBySeries},
    {isLoading: isVehicleMakeLoading},
  ] = useVehicleModel(props.vehicleType, makes);

  const options = props.isMultiple ? groupedModelOptionsByMake : groupedModelOptionsBySeries;

  return (
    <FormField
      data-testid={suffixTestId('modelSelect', props)}
      control={props.control}
      name={props.name}
      label={props.label}
      type={props.isMultiple ? 'multiChoice' : 'choice'}
      options={options ?? []}
      menuPlacement={props.menuPlacement}
      isRequired={props.isRequired}
      isNotClearable={props.isNotClearable}
      menuInPortal={props.isMenuInPortal}
      isLoading={isVehicleMakeLoading}
      isDisabled={isTrue(props.isDisabled) || isNilOrEmpty(options)}
      onChange={props.onChange}
    />
  );
}
