import type {AuditAssetsUpload} from '../types/AuditAssetsUpload';
import type {CancelablePromise} from '../types/CancelablePromise';
import {request as __request} from '../utils/request';

export class CarAuditFileStorageService {
  /**
   * File Storage Upload Request V2
   *
   * @returns AuditAssetsUpload
   * @throws ApiError
   */
  public static uploadFileV2({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<AuditAssetsUpload> {
    return __request({
      method: 'POST',
      url: '/car-audit/v2/file-storage/upload',
      headers: {
        Authorization: authorization,
      },
    });
  }
}
