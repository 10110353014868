import {Hide, Icon} from 'platform/foundation';
import styled from 'styled-components';

import {ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

export interface BreadcrumbItemProps extends TestIdProps {
  children: ReactNode;
  isCurrentPage?: boolean;
  isLastChild?: boolean;
}

export function BreadcrumbItem(props: BreadcrumbItemProps) {
  return (
    <StyledBreadcrumbItem data-testid={suffixTestId('breadcrumbItem', props)}>
      {props.children}
      <Hide when={props.isLastChild}>
        <StyledIconWrapper>
          <StyledIcon
            value="navigation/arrow_forward_ios"
            size={3}
            color="palettes.neutral.40.100"
          />
        </StyledIconWrapper>
      </Hide>
    </StyledBreadcrumbItem>
  );
}

const StyledBreadcrumbItem = styled.li`
  display: inline-flex;
  align-items: center;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  line-height: ${({theme}) => theme.lineHeights.text.xSmall};
  font-weight: normal;
  letter-spacing: 0;
  color: ${({theme}) => theme.colors.general.accent};
`;

const StyledIconWrapper = styled.div`
  margin-right: ${({theme}) => theme.getSize(1)};
  margin-left: ${({theme}) => theme.getSize(1)};
`;

export const StyledIcon = styled(Icon)`
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  line-height: ${({theme}) => theme.lineHeights.text.xSmall};
  font-weight: normal;
  letter-spacing: 0;
  pointer-events: none;
  width: ${({theme}) => theme.getSize(3)};
  height: ${({theme}) => theme.getSize(3)};
`;
