import {DeepPath, DeepPathArray} from '@hookform/strictly-typed';

import {ReactElement} from 'react';
import {Control, Controller, FieldValues, UseFormReturn} from 'react-hook-form';

import {path} from 'ramda';
import {isString} from 'ramda-adjunct';

import {Switcher} from '../../Switcher';
import {FieldProps} from '../FieldProps';
import {ValidationMessage} from '../styles';

export interface SwitcherFieldProps<
  TValues extends FieldValues,
  TPath = DeepPath<TValues, DeepPathArray<TValues, keyof TValues>>,
> extends FieldProps<TValues, TPath> {
  defaultValue?: string extends keyof TValues ? TValues[keyof TValues & string] : unknown;
  onChange?: (value: boolean) => void;
}
/**
 * @deprecated - use platform instead
 */
export function useSwitcherFields<
  TValues extends Record<string, boolean | undefined>,
  TPath = DeepPath<TValues, DeepPathArray<TValues, keyof TValues>>,
>({
  control,
  formState: {errors},
}: UseFormReturn<TValues>): (fieldProps: SwitcherFieldProps<TValues, TPath>) => ReactElement {
  return ({label, name, defaultValue, onChange}: SwitcherFieldProps<TValues, TPath>) => {
    const stringifiedName = Array.isArray(name) ? name.join('.') : String(name);

    return (
      <>
        <Controller
          name={stringifiedName}
          defaultValue={defaultValue as any}
          control={control as Control<Record<string, any>>}
          render={(props) => (
            <Switcher
              {...props.field}
              label={label}
              onChange={(value) => {
                props.field.onChange(value);
                onChange && onChange(value);
              }}
            />
          )}
        />
        {
          // eslint-disable-next-line no-restricted-syntax
          path(isString(name) ? [name] : Array.from(name as unknown as string[]), errors) && (
            <ValidationMessage>
              {path(
                isString(name)
                  ? [name, 'message']
                  : // eslint-disable-next-line no-restricted-syntax
                    [...Array.from(name as unknown as string[]), 'message'],
                errors
              )}
            </ValidationMessage>
          )
        }
      </>
    );
  };
}
