import styled from 'styled-components';

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin: 0;
    background-color: ${({theme}) => theme.colors.palettes.white[10][100]};

    &:nth-child(even) {
      background-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  height: 24px;
  padding: 0 8px;

  > h5 {
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
