import {createContext} from 'react';

import {AssignmentIdentity} from '../../../types/AssignmentIdentity';

interface AssignmentContextType {
  type: AssignmentIdentity['type'];
  external: boolean | null;
  assignmentIdentityId: string;
}

export const AssignmentContext = createContext<AssignmentContextType>({
  type: 'vehicle' as AssignmentIdentity['type'],
  external: null,
  assignmentIdentityId: '',
});
