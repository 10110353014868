import {SystemStyleObject} from '@chakra-ui/react';

export const dgInputs: SystemStyleObject = {
  "input[class^='ag-']": {
    margin: '0',
    backgroundColor: ['#fff', 'var(--ag-background-color, #fff)'],
  },
  "textarea[class^='ag-'],\n  select[class^='ag-']": {
    backgroundColor: ['#fff', 'var(--ag-background-color, #fff)'],
  },
  "input[class^='ag-']:not([type]),\n  input[class^='ag-'][type='text'],\n  input[class^='ag-'][type='number'],\n  input[class^='ag-'][type='tel'],\n  input[class^='ag-'][type='date'],\n  input[class^='ag-'][type='datetime-local'],\n  textarea[class^='ag-']":
    {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      color: 'inherit',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: ['#babfc7', 'var(--ag-input-border-color, var(--ag-border-color, #babfc7))'],
      minHeight: '24px',
      borderRadius: '3px',
    },
  "input[class^='ag-']:not([type]):disabled,\n    input[class^='ag-'][type='text']:disabled,\n    input[class^='ag-'][type='number']:disabled,\n    input[class^='ag-'][type='tel']:disabled,\n    input[class^='ag-'][type='date']:disabled,\n    input[class^='ag-'][type='datetime-local']:disabled,\n    textarea[class^='ag-']:disabled":
    {
      color: [
        'rgba(24, 29, 31, 0.5)',
        'var(--ag-disabled-foreground-color, rgba(24, 29, 31, 0.5))',
      ],
      backgroundColor: ['#f1f2f4', 'var(--ag-input-disabled-background-color, #f1f2f4)'],
      borderColor: [
        'rgba(186, 191, 199, 0.3)',
        'var(--ag-input-disabled-border-color, rgba(186, 191, 199, 0.3))',
      ],
    },
  "input[class^='ag-']:not([type]):focus,\n    input[class^='ag-'][type='text']:focus,\n    input[class^='ag-'][type='number']:focus,\n    input[class^='ag-'][type='tel']:focus,\n    input[class^='ag-'][type='date']:focus,\n    input[class^='ag-'][type='datetime-local']:focus,\n    textarea[class^='ag-']:focus":
    {
      outline: 'none',
      boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
      borderColor: [
        'rgba(33, 150, 243, 0.4)',
        'var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4))',
      ],
    },
  "input[class^='ag-'][type='number']": {
    MozAppearance: 'textfield',
  },
  "input[class^='ag-'][type='number']::-webkit-outer-spin-button, input[class^='ag-'][type='number']::-webkit-inner-spin-button":
    {
      WebkitAppearance: 'none',
      margin: '0',
    },
  "input[class^='ag-'][type='range']": {
    padding: '0',
    WebkitAppearance: 'none',
    width: '100%',
    height: '100%',
    background: 'none',
    overflow: 'visible',
  },
  "input[class^='ag-'][type='button']:focus, button[class^='ag-']:focus": {
    boxShadow: '0 0 2px 0.1rem rgba(33, 150, 243, 0.4)',
  },
};
