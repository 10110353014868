import {useSelector} from 'react-redux';

import {reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {selectCurrentUserInfo} from '@omnetic-dms/api';

export function useUserFullName(): string | null {
  const {data} = useSelector(selectCurrentUserInfo);

  return reject(isNilOrEmpty, [data?.firstName, data?.lastName]).join(' ') || null;
}
