import {Button, showNotification} from 'platform/components';

import {useSelector} from 'react-redux';

import {useToInRevisionInspectionMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {getAudit} from '../../../store/carAudit/actions';
import {selectAudit} from '../../../store/carAudit/selectors';
import {getStringErrorMessage} from '../../../utils/getStringErrorMessage';

export function InRevision(props: TestIdProps) {
  const [toInRevision, {isLoading: isLoadingToInRevision}] = useToInRevisionInspectionMutation();
  const audit = useSelector(selectAudit);
  const dispatch = useThunkDispatch();

  const pushToInRevision = async () => {
    if (!audit) {
      return;
    }
    try {
      await toInRevision({
        inspectionId: audit.id,
        toInRevisionInspectionRequestBody: {updatedAt: new Date().toISOString()},
      }).unwrap();
      dispatch(
        getAudit.action({
          auditId: audit.id,
        })
      );
    } catch (error: any) {
      showNotification.error(getStringErrorMessage(error));
    }
  };

  return (
    <Button
      onClick={pushToInRevision}
      isLoading={isLoadingToInRevision}
      data-testid={suffixTestId('to_in_revision', props)}
      title={i18n.t('general.actions.in_revision')}
    />
  );
}
