import styled from 'styled-components';

export const SwitchWrapper = styled.div`
  margin-right: 20px;
`;

export const Subtext = styled.div`
  color: ${({theme}) => theme.colors.palettes.neutral[200][100]};
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  font-weight: normal;
  margin-top: ${({theme}) => theme.getSize(1)};
`;
