import {BuyingFormType} from '../types/BuyingFormType';
import {moneyToString} from './moneyToString';

export const transformBuyingFormToMinisale = (buyingForm: BuyingFormType) => ({
  minisaleId: buyingForm.id,
  customerBuyingStatement: buyingForm.customerBuyingStatement ?? false,
  priceRequested: moneyToString(buyingForm.priceRequested),
  priceOfferedBase: moneyToString(buyingForm.priceOfferedWithoutVat),
  priceOfferedWithVat: moneyToString(buyingForm.priceOfferedWithVat),
  priceOfferedWithoutVat: moneyToString(buyingForm.priceOfferedWithoutVat),
  expectedCosts: moneyToString(buyingForm.expectedCosts),
  reason: buyingForm.reason,
  costReason: buyingForm.costReason,
  isVatDeductible: buyingForm.isVatDeductible?.[0] === 'true',
  interestRate: buyingForm.interestRate,
  vehicleData: {
    ...buyingForm.vehicleData,
    type: buyingForm.vehicleData?.type?.[0] ?? null,
    fuelType: buyingForm.vehicleData?.fuelType?.[0] ?? null,
    secondaryFuelType: buyingForm.vehicleData?.secondaryFuelType?.[0] ?? null,
    transmission: buyingForm.vehicleData?.transmission?.[0] ?? null,
    bodyStyle: buyingForm.vehicleData?.bodyStyle?.[0] ?? null,
    drive: buyingForm.vehicleData?.drive?.[0] ?? null,
  },
  vatType: buyingForm.vatType,
  vatDirection: buyingForm.vatDirection,
});
