import {AnimatePresence, motion, AnimatePresenceProps} from 'framer-motion';
import {Box} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {FC, useMemo, useRef, PropsWithChildren, ReactNode} from 'react';

import {useDidUpdate, useForceUpdate} from 'shared';

import {Portal} from '../../Portal/components/Portal';
import {PortalManager} from '../../Portal/components/PortalManager';
import {NotificationContextProvider} from '../hooks/useNotifications';
import {useNotificationsState} from '../hooks/useNotificationsState';
import {Positions} from '../Notification.types';
import {useNotificationsEvents} from '../utils/events';
import {getAnimationStyle} from '../utils/getAnimationStyle';
import {getPositionStyles} from '../utils/getPositionStyles';
import {NotificationContainer} from './NotificationContainer';

export interface NotificationProviderProps {
  /** Notifications position */
  position?: Positions;

  /** Auto close timeout for all notifications, false to disable auto close, can be overwritten for individual notifications by showNotification function */
  autoClose?: number | false;

  /** Maximum amount of notifications displayed at a time, other new notifications will be added to queue */
  limit?: number;

  /** Children to render */
  children: ReactNode;
}

export function NotificationsProvider({
  position = 'top-right',
  autoClose = 5000,
  limit = 4,
  children,
}: NotificationProviderProps) {
  const theme = useTheme();
  const forceUpdate = useForceUpdate();
  const previousLength = useRef<number>(0);
  const {
    notifications,
    queue,
    showNotification,
    updateNotification,
    hideNotification,
    clean,
    cleanQueue,
  } = useNotificationsState({limit});

  const ctx = {
    notifications,
    queue,
    showNotification,
    hideNotification,
    updateNotification,
    clean,
    cleanQueue,
  };

  useNotificationsEvents(ctx);

  useDidUpdate(() => {
    if (notifications.length > previousLength.current) {
      setTimeout(() => forceUpdate(), 0);
    }
    previousLength.current = notifications.length;
  }, [notifications]);

  const itemStyle = useMemo(
    () => ({
      paddingTop: 16,
      paddingRight: 16,
    }),
    []
  );

  const items = useMemo(
    () =>
      notifications.map((notification) => (
        <motion.div key={notification.id} layout {...getAnimationStyle(position)} style={itemStyle}>
          <NotificationContainer
            notification={notification}
            onHide={hideNotification}
            autoClose={autoClose}
          />
        </motion.div>
      )),
    [notifications, hideNotification, autoClose, position]
  );

  // workaround https://github.com/framer/motion/issues/1509
  const AnimatePresenceWrapper: FC<PropsWithChildren<AnimatePresenceProps>> = AnimatePresence;

  return (
    <NotificationContextProvider value={ctx}>
      <PortalManager zIndex={theme.zIndices.TOAST_NOTIFICATION}>
        <Portal>
          <Box
            width={110}
            maxHeight={125}
            position="fixed"
            overflowY="auto"
            overflowX="hidden"
            {...getPositionStyles(position)}
          >
            <AnimatePresenceWrapper initial={false}>{items}</AnimatePresenceWrapper>
          </Box>
        </Portal>
      </PortalManager>
      {children}
    </NotificationContextProvider>
  );
}
