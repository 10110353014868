import {ActionDefinition, ActionDefinitionObject} from '../types/ActionDefinition';
import {DataGridProps} from '../types/DataGridProps';
import {RowData} from '../types/RowData';

export const useRowActions = (row: RowData, gridProps: DataGridProps): ActionDefinitionObject => {
  if (!row || !row.actions) {
    return {};
  }
  const rowActions = row.actions;
  // @ts-ignore
  const actionsFilter = gridProps.actionsFilter;
  // @ts-ignore
  const actionDefs = gridProps.actionsDefinition || {};

  const primaryAndSecondaryActions = Object.keys(actionDefs)
    .filter((actionDefKey) => actionDefs[actionDefKey]?.singleAction)
    .filter((actionDefKey) =>
      [...rowActions.primary, ...rowActions.secondary, rowActions.rowClick].includes(actionDefKey)
    )
    .filter((actionDefKey) => {
      if (actionsFilter) {
        return actionsFilter(actionDefKey, row);
      }
      return true;
    })
    .reduce((acc, actionDefKey) => {
      acc[actionDefKey] = actionDefs[actionDefKey] as ActionDefinition;
      return acc;
    }, {} as ActionDefinitionObject);

  const rowClickAction = Object.keys(actionDefs)
    .filter((actionDefKey) => rowActions.rowClick === actionDefKey)
    .reduce((acc, actionDefKey) => {
      acc[actionDefKey] = actionDefs[actionDefKey] as ActionDefinition;
      return acc;
    }, {} as ActionDefinitionObject);

  return Object.assign({}, primaryAndSecondaryActions, rowClickAction);
};
