import {Option} from 'platform/components';

import i18n from '@omnetic-dms/i18n';

export const getSearchTypeOptions = (): Option[] => [
  {
    value: 'EQUALS',
    label: i18n.t('entity.warehouse.labels.equals'),
  },
  {
    value: 'LIKE',
    label: i18n.t('entity.warehouse.labels.contains'),
  },
  {
    value: 'STARTS_WITH',
    label: i18n.t('entity.warehouse.labels.startsWith'),
  },
  {
    value: 'ENDS_WITH',
    label: i18n.t('entity.warehouse.labels.endsWith'),
  },
];
