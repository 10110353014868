import {Action, Actions, TabsHeader} from 'platform/components';
import {Heading, HStack, Show, Space} from 'platform/foundation';

import {ReactNode} from 'react';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

export type PageTab = {
  id: string;
  title: string;
  href: string;
  content: ReactNode;
} & TestIdProps;

export interface PageHeadingProps extends TestIdProps {
  title?: string;
  actions?: Action[];
  activeTabIndex?: number;
  tabs?: PageTab[];
  onTabChange?: (index: number) => void;
}

export function PageHeading(props: PageHeadingProps) {
  if (!props.title && !props.tabs?.length && !props.actions?.length) {
    return null;
  }

  const activeTabId = props.activeTabIndex?.toString();
  const handleOnChange = (val: string) => props.onTabChange?.(parseFloat(val));

  const tabs = (props.tabs ?? []).map((item, index) => ({...item, id: index.toString()}));

  return (
    <>
      <HStack spacing={4} justify="space-between" align="center">
        <Show when={props.title}>
          <Heading size={3} headingLevel={2} alternative data-testid={props['data-testid']}>
            {props.title}
          </Heading>
        </Show>
        <Show when={isNotNilOrEmpty(props.tabs)}>
          <TabsHeader
            tabs={tabs}
            activeTabId={activeTabId}
            onChange={handleOnChange}
            variant="condensed"
          />
        </Show>
        <Space fillAvailable />
        <Actions actions={props.actions} data-testid={suffixTestId('actions', props)} />
      </HStack>
      <Space vertical={4} />
    </>
  );
}
