import {Chips} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {
  CustomerResponseBodyV2,
  PurchaseVehicleType,
  useCreatePurchaseBusinessCaseMutation,
  useGetVehicleQuery,
  useLazyGetBusinessCaseQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, testIds} from '@omnetic-dms/routes';
import {
  CustomerMatchOrCreate,
  handleApiError,
  useLazyGetAuthorizationProfilesAndCKKPermissions,
} from '@omnetic-dms/shared';

import {composePath, Nullish} from 'shared';

const purchaseVehicleTypeOptions: {label: string; value: PurchaseVehicleType}[] = [
  {
    label: i18n.t`entity.vehicle.labels.typeInStock`,
    value: 'IN_STOCK',
  },
  {
    label: i18n.t`entity.vehicle.labels.typeBrokerage`,
    value: 'BROKERAGE',
  },
];

interface VehicleBuyFormProps {
  vehicleId: string;
}

export function VehicleBuyForm(props: VehicleBuyFormProps) {
  const navigate = useNavigate();
  const [createPurchaseBusinessCase] = useCreatePurchaseBusinessCaseMutation();

  const [purchaseVehicleType, setPurchaseVehicleType] = useState<PurchaseVehicleType>('IN_STOCK');
  const onChangePurchaseVehicleType = (purchaseVehicleType: string[] | Nullish) =>
    setPurchaseVehicleType(purchaseVehicleType?.[0] as PurchaseVehicleType);
  const {handleSearchAuthorizationProfiles} = useLazyGetAuthorizationProfilesAndCKKPermissions();
  const {data: vehicleData} = useGetVehicleQuery({vehicleId: props.vehicleId});
  const [getBusinessCase] = useLazyGetBusinessCaseQuery();

  const onCreate = (customer: CustomerResponseBodyV2) => {
    createPurchaseBusinessCase({
      createPurchaseBusinessCaseRequestBody: {
        customerId: customer.id,
        vehicleToPurchaseId: props.vehicleId,
        purchaseType: purchaseVehicleType,
      },
    })
      .unwrap()
      .then((businessCase) => {
        navigate(composePath(businessCaseRoutes.buying, {params: {id: businessCase?.id}}));
        getBusinessCase({businessCaseId: businessCase?.id})
          .unwrap()
          .then((businessCase) => {
            handleSearchAuthorizationProfiles(
              {
                vehicleMake: vehicleData?.make ?? '',
                vehicleType: vehicleData?.type ?? null,
                vehicleModelFamily: vehicleData?.vehicle?.modelFamily ?? null,
                vehicleModelFamilyGroup: vehicleData?.vehicle?.modelFamilyGroup ?? null,
              },
              customer.id,
              {bcNumber: businessCase?.code, bcCreatedAt: businessCase?.createdAt}
            );
          })
          .catch(handleApiError);
      })
      .catch(handleApiError);
  };

  return (
    <VStack spacing={4}>
      <Chips
        label={i18n.t('page.businessCase.labels.selectPurchaseVehicleType')}
        data-testid={testIds.businessCase.detail('purchaseVehicleType')}
        onChange={onChangePurchaseVehicleType}
        value={[purchaseVehicleType as string]}
        options={purchaseVehicleTypeOptions}
      />
      <Heading size={4}>{i18n.t('entity.customer.labels.customer')}</Heading>
      <CustomerMatchOrCreate
        secondStepComponentType="BUSINESS_CASE"
        onCustomer={onCreate}
        vehicleId={props.vehicleId}
        data-testid={testIds.businessCase.detail('finder')}
      />
    </VStack>
  );
}
