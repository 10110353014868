import {RendererProps} from 'platform/components';
import {Box, Show, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {useOnMount} from 'shared';

const defaultInputValue = {
  name: 'row',
  renderer: 'row',
  columns: 1,
  columnSpan: null,
  elements: [
    {
      name: 'field-1',
      onBlur: null,
      onChange: null,
      onFocus: null,
      tooltip: 'Tooltip text',
      rules: {required: {value: true, message: 'jsem povinej'}},
      showWhen: null,
      label: 'Label',
      renderer: 'dateRange',
      placeholder: null,
      columnSpan: null,
    },
  ],
};

type JsonInputProps = {
  setJsonValue: (value: RendererProps) => void;
};

export function JsonInput(props: JsonInputProps) {
  const [isJsonError, setIsJsonError] = useState<boolean>(false);
  const [jsonString, setJsonString] = useState<string>(JSON.stringify(defaultInputValue, null, 2));

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setJsonString(value);
    updateDynamicUiValue(value);
  };

  const updateDynamicUiValue = (value: string) => {
    try {
      const parsedValue = JSON.parse(value);
      props.setJsonValue(parsedValue);
      setIsJsonError(false);
    } catch (error) {
      setIsJsonError(true);
    }
  };

  const formatJsonInput = () => {
    try {
      setJsonString(JSON.stringify(JSON.parse(jsonString), null, 2));
    } catch (error) {
      return jsonString;
    }
  };

  useOnMount(() => updateDynamicUiValue(jsonString));

  return (
    <Box
      backgroundColor={isJsonError ? 'palettes.red.20.100' : 'general.separator'}
      height="100%"
      width="50%"
    >
      <Box padding={10} height="100%">
        <VStack spacing={2} height="100%">
          <textarea
            value={jsonString}
            onChange={handleInputChange}
            placeholder="Enter JSON here..."
            onBlur={formatJsonInput}
            rows={40}
            style={{width: '100%', height: '100%', fontFamily: 'monospace'}}
          />
          <Show when={isJsonError}>
            <Text color="danger">Invalid JSON structure</Text>
          </Show>
        </VStack>
      </Box>
    </Box>
  );
}
