import {isNil} from 'ramda';

import {Nullish} from 'shared';

export const formatPriceString =
  (useHighligtedFormatting?: boolean, isPositive?: boolean) => (value: string | Nullish) => {
    if (isNil(value)) {
      return undefined;
    }
    if (useHighligtedFormatting) {
      return isPositive ? `+${value}` : value;
    }
    return value;
  };
