import {Actions, Choice, Separator, showNotification, Switch} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import {useState} from 'react';
import {useSelector} from 'react-redux';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  OccupiedSpaceRequestBody,
  SectorType,
  selectActiveBranchId,
  selectUserBranchOptions,
  useGetOccupiedSpaceQuery,
  useGetSectorsQuery,
  useUpdateOccupiedSpaceMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, suffixTestId, TestIdProps, useToggle} from 'shared';

import {handleApiError} from '../../utils/handleApiError';
import {Sector} from './components/Sector';

interface StorageSectorsDialogProps extends TestIdProps {
  recordId: string | Nullish;
  spaceType: SectorType;
  onClose: () => void;
  isDefaultShowingFree?: boolean;
  selectedSpace?: OccupiedSpaceRequestBody | undefined;
  onSelectStorageLocationWithoutId?: (selectedSpace: OccupiedSpaceRequestBody | undefined) => void;
}

export function StorageSectorsDialog(props: StorageSectorsDialogProps) {
  const branches = useSelector(selectUserBranchOptions);
  const activeBranchId = useSelector(selectActiveBranchId);

  const [selectedBranch, setSelectedBranch] = useState(activeBranchId);
  const [isShowingFree, toggleShowFree] = useToggle(props.isDefaultShowingFree);
  const [selectedSpace, setSelectedSpace] = useState<OccupiedSpaceRequestBody | undefined>(
    props.selectedSpace
  );

  const {data: sectors} = useGetSectorsQuery({branchId: selectedBranch, type: props.spaceType});
  const {data: occupiedSpace} = useGetOccupiedSpaceQuery(
    {recordId: props.recordId ?? '', spaceType: props.spaceType},
    {skip: isNilOrEmpty(props.recordId)}
  );
  const [updateOccupiedSpace, {isLoading: isUpdateLoading}] = useUpdateOccupiedSpaceMutation();

  const handleUpadeteSpace = () => {
    if (isNil(props.recordId)) {
      props.onClose();
      return props.onSelectStorageLocationWithoutId?.(selectedSpace);
    }

    updateOccupiedSpace({
      recordId: props.recordId,
      spaceType: props.spaceType,
      updateOccupiedSpaceRequestBody: {space: selectedSpace ?? null},
    })
      .unwrap()
      .then(() => {
        showNotification.success();
        props.onClose();
      })
      .catch(handleApiError);
  };

  const onSpaceClick = (number: number, sectorId: string) => {
    if (selectedSpace?.number === number && selectedSpace?.sectorId === sectorId) {
      return setSelectedSpace(undefined);
    }

    setSelectedSpace({number, sectorId});
  };

  return (
    <VStack>
      <HStack justify="space-between" align="center">
        <Switch
          label={i18n.t('entity.vehicle.labels.showFreeOnly')}
          value={isShowingFree}
          onChange={toggleShowFree}
          data-testid={suffixTestId('showFreeOnly', props)}
        />
        <Box width={70}>
          <Choice
            value={selectedBranch}
            onChange={(branchId) => setSelectedBranch(branchId ?? activeBranchId)}
            options={branches ?? []}
            menuInPortal
            isNotClearable
            data-testid={suffixTestId('branches', props)}
          />
        </Box>
      </HStack>
      <Separator />
      <VStack spacing={4} data-testid={suffixTestId('sectors', props)}>
        {sectors?.map((sector, index) => (
          <Sector
            key={sector.sectorId}
            sector={sector}
            isShowingFree={isShowingFree}
            selectedNumber={
              sector.sectorId === selectedSpace?.sectorId ? selectedSpace.number : undefined
            }
            occupiedSpace={occupiedSpace}
            onSpaceClick={(number) => onSpaceClick(number, sector.sectorId)}
            data-testid={suffixTestId(`sector-${index}`, props)}
          />
        ))}
      </VStack>
      <Separator />
      <Actions
        align="right"
        actions={[
          {
            type: 'button',
            onClick: props.onClose,
            title: i18n.t('general.actions.cancel'),
            variant: 'secondary',
            'data-testid': suffixTestId(`parkingPlace-cancel`, props),
          },
          {
            type: 'button',
            onClick: handleUpadeteSpace,
            title: i18n.t('general.actions.assign'),
            isLoading: isUpdateLoading,
            'data-testid': suffixTestId(`parkingPlace-assign`, props),
          },
        ]}
        data-testid={suffixTestId(`actions`, props)}
      />
    </VStack>
  );
}
