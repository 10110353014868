import {AnyObjectSchema} from 'yup';

import {FC, createContext, ReactElement, ComponentClass} from 'react';

import type {PossibleObject} from '../../types/PossibleObject';
import type {ConditionProps} from './components/Condition';
import type {FormFieldArrayProps} from './components/FormFieldArray';
import type {SubscribeProps} from './components/Subscribe';
import type {FormFieldType} from './types/FormFieldType';

export type FormContextType = {
  validateAfterSubmit?: boolean;
  noValidate?: boolean;
  schema?: AnyObjectSchema;
  formId?: string;
  submitCount: number;
  setSubmitCount?: (count: number) => void;
  /** Field component with property names of the FormValues object */
  Field: FormFieldType<Record<string, unknown>, undefined>;
  /** Subscribe component is used to listen to other fields value */
  Subscribe: FC<SubscribeProps<PossibleObject>>;
  /** Condition component with property names of the FormValues object */
  Condition: ComponentClass<ConditionProps<Record<string, unknown>>>;
  /** FieldArray component with property names of the FormValues object */
  FieldArray: <T extends PossibleObject = undefined>(
    props: FormFieldArrayProps<T, Record<string, unknown>>
  ) => ReactElement<FormFieldArrayProps<T, Record<string, unknown>>>;
};

export const FormContext = createContext<FormContextType>({submitCount: 0} as FormContextType);
