import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  showNotification,
} from 'platform/components';
import {Text, VStack} from 'platform/foundation';
import {object} from 'yup';

import {isNotNil} from 'ramda-adjunct';

import {useDeleteWatermarkMutation, useGetWatermarkListQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {yupString} from 'shared';

import {openWatermarkRepublishInfoDialog} from '../utils/openWatermarkRepublishInfoDialog';

interface WatermarkDeleteDialogProps {
  watermarkId: string;
  inAdvertisementCount?: number;
}

export function WatermarkDeleteDialog(props: WatermarkDeleteDialogProps) {
  const {data: watermarkList, isLoading: isLoadingWatermarks} = useGetWatermarkListQuery();
  const [deleteWatermark, {isLoading: isDeleting}] = useDeleteWatermarkMutation();

  const correctWatermarkOptions = watermarkList?.filter(
    (watermark) => watermark.id !== props.watermarkId
  );
  const watermarkOptionsShape = correctWatermarkOptions?.map((watermark) => ({
    label: watermark.name,
    value: watermark.id,
  }));

  // Choice option value cannot be null or empty string so I used "'null'" and check it later onSubmit
  const watermarkOptions = [
    {label: i18n.t('entity.watermark.deleteDialog.none'), value: 'null'},
    ...(watermarkOptionsShape ?? []),
  ];

  return (
    <VStack spacing={4}>
      <Text>{i18n.t('entity.watermark.deleteDialog.description')}</Text>
      <Form<{watermark: string}>
        onSubmit={(data) =>
          deleteWatermark({
            watermarkId: props.watermarkId,
            deleteWatermarkRequestBody: {
              republishAds: true,
              replacementWatermarkId: data.watermark === 'null' ? null : data.watermark,
            },
          })
            .unwrap()
            .then(() => {
              closeCurrentDialog();
              if (isNotNil(props.inAdvertisementCount) && props.inAdvertisementCount > 0) {
                openWatermarkRepublishInfoDialog();
              } else {
                showNotification.success();
              }
            })
            .catch(handleApiError)
        }
        schema={schema}
        defaultValues={{
          watermark: watermarkOptions?.[0].value,
        }}
      >
        {(control) => (
          <VStack spacing={4}>
            <FormField
              name="watermark"
              control={control}
              type="choice"
              options={watermarkOptions}
              label={i18n.t('entity.watermark.deleteDialog.newWatermark')}
              isLoading={isLoadingWatermarks}
              data-testid="watermark-deleteDialog-selectWatermark"
              menuInPortal
            />
            <ButtonGroup align="right" data-testid="watermark-deleteDialog-buttons">
              <Button
                data-testid="watermark-deleteDialog-cancel"
                isDisabled={isDeleting}
                variant="secondary"
                onClick={closeCurrentDialog}
                title={i18n.t('general.actions.discard')}
              />
              <FormButton
                data-testid="watermark-deleteDialog-submit"
                control={control}
                type="submit"
                isLoading={isDeleting}
                isDisabled={isDeleting}
                title={i18n.t('general.labels.delete')}
                variant="danger"
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </VStack>
  );
}

const schema = object().shape({
  watermark: yupString.required(),
});
