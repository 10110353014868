import {createAction, PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {showNotification} from 'platform/components';
import {call as typedCall, select as typedSelect} from 'typed-redux-saga';

import {all, call, put, takeEvery, takeLatest, takeLeading} from 'redux-saga/effects';

import {CostService} from '../../services/CostService';
import {CostsMapType, Preset, PresetItem} from '../../types/CommonTypes';
import {CostDetailItem} from '../../types/CostDetailItem';
import {CostItem} from '../../types/CostItem';
import type {TeasState} from '../../types/TeasState';
import {callApiSaga} from '../../utils/api';
import {CustomError, isAxiosError} from '../../utils/customError';
import {reduceArrayToObject} from '../../utils/reduceArrayToObject';
import {selectActiveBranch} from '../user/selectors';
import {
  submitSourcingVehicleSummaryChange,
  updateVehicleDetailCosts,
} from '../vehicleDetail/reducer';
import {selectVehicleDetailVehicle} from '../vehicleDetail/selectors';
import {COSTS_SLICE_NAME} from './constants';
import {
  loadNote,
  loadPreset,
  removeCost,
  setCostsTotalPrice,
  setDefaultPreset,
  setIsSaving,
  submitNoteChange,
  submitRowChange,
  UpdateTotalVehicleCostsPayload,
  VehicleAndPresetIds,
  VehicleId,
} from './reducer';

const handleNotFoundError = (error: CustomError | AxiosError | Error) => {
  if (isAxiosError(error) && error.response?.status === 404) {
    return;
  }
};

export const removeCostRequest = createAction<VehicleAndPresetIds>(
  `${COSTS_SLICE_NAME}/removeCostRequest`
);

export const loadPresetRequest = createAction<VehicleId>(`${COSTS_SLICE_NAME}/loadPresetRequest`);

export const setDefaultCosts = createAction<VehicleId>(`${COSTS_SLICE_NAME}/setDefaultCosts`);

export const submitRowChangeRequest = createAction<VehicleAndPresetIds>(
  `${COSTS_SLICE_NAME}/submitRowChangeRequest`
);

export const loadNoteRequest = createAction<VehicleId>(`${COSTS_SLICE_NAME}/loadNoteRequest`);

export const updateTotalVehicleCosts = createAction<UpdateTotalVehicleCostsPayload>(
  `${COSTS_SLICE_NAME}/updateTotalVehicleCosts`
);

export const submitNoteChangeRequest = createAction<VehicleId>(
  `${COSTS_SLICE_NAME}/submitNoteChangeRequest`
);

const setCostTitle = (p: PresetItem): CostItem => {
  const copyPreset = {...p};

  return copyPreset as CostItem;
};

const convertCostToString = (c: CostItem): CostItem => ({
  ...c,
  cost: c?.cost?.toString(),
});

function* getDefaultPresetGen({vehicleId}: {vehicleId: string}) {
  try {
    const xBranch = (yield* typedSelect(selectActiveBranch)) as string;

    const data = yield* callApiSaga(CostService.detailDefaultUserPresetCost, {xBranch});
    // eslint-disable-next-line no-restricted-syntax
    const presetItems = reduceArrayToObject(data.presetItems, [
      'uuid',
    ]) as unknown as CostsMapType<PresetItem>;

    yield put(
      setDefaultPreset({
        defaultPreset: {
          ...data,
          presetItems,
        },
        vehicleId,
      })
    );

    // eslint-disable-next-line no-restricted-syntax
    return presetItems as unknown as Record<string, CostDetailItem>;
  } catch (exception: any) {
    handleNotFoundError(exception);
  }
}

function* getCostPresetGen({payload: {vehicleId}}: PayloadAction<VehicleId>): Generator {
  const xBranch = (yield* typedSelect(selectActiveBranch)) as string;
  let defaultPresetItems = yield* typedSelect((store: TeasState) => {
    if (store.sourcing?.costs[vehicleId]) {
      return store.sourcing?.costs[vehicleId]?.defaultPreset?.presetItems || {};
    }

    return {};
  });

  const data = yield* callApiSaga(CostService.userCostVehicleList, {
    xBranch,
    sourcingVehicleId: vehicleId,
  });

  if (!Object.keys(defaultPresetItems).length) {
    const res = yield* typedCall(getDefaultPresetGen, {vehicleId});

    // eslint-disable-next-line no-restricted-syntax
    defaultPresetItems = (res as unknown as CostsMapType<PresetItem>) || {};
  }

  // load default preset
  let presetItems = {};

  if (Array.isArray(data) && data.length) {
    presetItems = reduceArrayToObject(data, ['uuid']);
  } else {
    presetItems = defaultPresetItems;
  }

  yield put(
    loadPreset({
      vehicleId,
      preset: {
        presetItems,
        default: !(
          // TODO Check if this is error, CostStatType hasn't such a field, only defaultPreset
          (Array.isArray(data) && data.length)
        ),
      },
    })
  );

  yield put(setCostsTotalPrice({vehicleId}));
}

export function* getVehicleCostNoteGen({
  payload: {vehicleId},
}: PayloadAction<VehicleId>): Generator {
  try {
    const xBranch = (yield* typedSelect(selectActiveBranch)) as string;
    const data = yield* callApiSaga(CostService.detailUserCostVehicleNote, {
      xBranch,
      sourcingVehicleId: vehicleId,
    });

    yield put(
      loadNote({
        note: data.note,
        vehicleId,
      })
    );
  } catch (exception: any) {
    handleNotFoundError(exception);
  }
}

export function* submitRowChangeGen({
  payload: {id, vehicleId},
}: PayloadAction<VehicleAndPresetIds>): Generator {
  // recalculate total price
  yield put(setCostsTotalPrice({vehicleId}));

  try {
    const xBranch = (yield* typedSelect(selectActiveBranch)) as string;
    const preset: Preset = yield* typedSelect(
      (store: TeasState) => store.sourcing?.costs[vehicleId] || {}
    );
    const {summary, vehicleSummary} = yield* typedSelect(selectVehicleDetailVehicle);

    const presetItems = preset.presetItems;
    const presetItem = {...presetItems?.[id]};

    yield put(
      updateTotalVehicleCosts({
        id: vehicleId,
        totalCosts: preset?.totalPrice,
      })
    );

    if (summary) {
      yield put(
        updateVehicleDetailCosts({
          retail:
            (summary.retail ?? 0) + ((preset?.totalPrice ?? 0) - (summary?.vehicleCosts ?? 0)),
          vehicleCosts: preset?.totalPrice,
        })
      );
      yield put(
        submitSourcingVehicleSummaryChange({
          vehicleId,
          summary: vehicleSummary ?? undefined,
          vehicleCosts: preset?.totalPrice,
        })
      );
    }

    if (presetItem.__dirty) {
      // disable inputs (prevent rows duplication)
      yield put(setIsSaving({vehicleId, isLoading: true}));

      // remove __dirty field from row/cost
      yield put(submitRowChange({id: presetItem.uuid as string, vehicleId}));

      delete presetItem.__dirty;

      if (presetItem?.uuid?.startsWith('temp_')) {
        // do not send temp id to server
        delete presetItem.uuid;
      }

      if (preset.default) {
        // create or update all costs
        yield* callApiSaga(CostService.updateUserVehicleCosts, {
          xBranch,
          sourcingVehicleId: vehicleId,
          requestBody: {
            costItems: Object.values(presetItems ?? {})
              .filter((item) => !!item.cost)
              .map((x) => convertCostToString(setCostTitle(x))),
          },
        });

        // get created/updated costs
        yield call(getCostPresetGen, loadPresetRequest({vehicleId}));
      } else {
        presetItem.cost = presetItem?.cost?.toString();

        if (presetItem.uuid) {
          yield put(setIsSaving({vehicleId, isLoading: false}));

          if (presetItem.cost) {
            // update existing cost
            yield* callApiSaga(CostService.updateUserVehicleCost, {
              xBranch,
              sourcingVehicleId: vehicleId,
              itemUuid: presetItem.uuid,
              requestBody: {costItem: presetItem as CostItem},
            });
          }
        } else {
          if (presetItem.cost) {
            // create newly added cost
            yield* callApiSaga(CostService.createUserVehicleCost, {
              xBranch,
              sourcingVehicleId: vehicleId,
              requestBody: {costItem: presetItem as CostItem},
            });
          }

          // TODO: remove after BE is fixed and returns ID with newly created items
          yield call(getCostPresetGen, loadPresetRequest({vehicleId}));
        }
      }
    }
  } catch (exception: any) {
    yield call(() =>
      showNotification.error(exception.response?.data?.error?.message || exception.toString())
    );
  }

  yield put(setIsSaving({vehicleId, isLoading: false}));
}

export function* submitNoteChangeGen({payload: {vehicleId}}: PayloadAction<VehicleId>): Generator {
  const xBranch = (yield* typedSelect(selectActiveBranch)) as string;
  const {note, hasNoteChanged} = yield* typedSelect(
    (store: TeasState) => store.sourcing?.costs[vehicleId]
  );

  if (!hasNoteChanged) {
    return;
  }

  yield* callApiSaga(CostService.setUserVehicleCostNote, {
    xBranch,
    requestBody: {note} as {note: string},
    sourcingVehicleId: vehicleId,
  });

  yield put(submitNoteChange({vehicleId}));
}

export function* removeCostGen({
  payload: {vehicleId, id},
}: PayloadAction<VehicleAndPresetIds>): Generator {
  const xBranch = (yield* typedSelect(selectActiveBranch)) as string;

  yield put(removeCost({id, vehicleId}));

  if (!id.startsWith('temp_')) {
    yield* callApiSaga(CostService.deleteUserVehicleCost, {
      xBranch,
      itemUuid: id,
      sourcingVehicleId: vehicleId,
    });
  }

  yield put(setCostsTotalPrice({vehicleId}));
}

export function* costsSaga(): Generator {
  yield all([
    takeLeading(loadPresetRequest, getCostPresetGen),
    takeEvery(submitRowChangeRequest, submitRowChangeGen),
    takeEvery(removeCostRequest, removeCostGen),
    takeLeading(loadNoteRequest, getVehicleCostNoteGen),
    takeLatest(submitNoteChangeRequest, submitNoteChangeGen),
  ]);
}
