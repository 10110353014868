import i18n from 'i18next';
import {DataStatus, Dialog, DialogProps, Separator} from 'platform/components';
import {Box, Show} from 'platform/foundation';

import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {split} from 'ramda';

import {
  useRenderCustomerEmailTemplateExampleMutation,
  useRenderCustomerSmsTemplateExampleMutation,
} from '@omnetic-dms/api';
import {testIds} from '@omnetic-dms/routes';

import {getRandomId, Nullish} from 'shared';

import {MessageTemplateFormValues} from '../types/MessageTemplateFormValues';

type Props = Omit<DialogProps, 'children'> & {
  templateType: 'sms' | 'email';
  formApi: UseFormReturn<MessageTemplateFormValues>;
};

export const MessagePreviewDialog = (props: Props) => {
  const [
    renderCustomerEmailTemplate,
    {isLoading: isRenderEmailInProgress, data: renderDataEmail, isError: isRenderEmailError},
  ] = useRenderCustomerEmailTemplateExampleMutation();
  const [
    renderCustomerSmsTemplate,
    {isLoading: isRenderSmsInProgress, data: renderDataSms, isError: isRenderSmsError},
  ] = useRenderCustomerSmsTemplateExampleMutation();

  const renderMultiLine = (value: string | Nullish) => {
    if (!value) {
      return '';
    }
    return split('\n', value).map((line) => <Box key={`${getRandomId()}_${value}`}>{line}</Box>);
  };

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    const {body, subject} = props.formApi.getValues();

    if (props.templateType === 'sms') {
      renderCustomerSmsTemplate({
        renderSmsTemplateRequestBody: {
          template: body,
        },
      });
    } else {
      renderCustomerEmailTemplate({
        renderEmailTemplateRequestBody: {
          body,
          subject,
        },
      });
    }
  }, [
    props.isOpen,
    props.formApi,
    props.templateType,
    renderCustomerEmailTemplate,
    renderCustomerSmsTemplate,
  ]);

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={i18n.t('page.messageTemplates.labels.previewDialog')}
      data-testid={testIds.settings.messageTemplates('previewDialog')}
    >
      <DataStatus
        isLoading={isRenderEmailInProgress || isRenderSmsInProgress}
        isError={isRenderEmailError || isRenderSmsError}
      >
        <Show when={props.templateType === 'sms'}>
          <Box data-testid={testIds.settings.messageTemplates('previewDialog-smsBody')}>
            {renderMultiLine(renderDataSms?.body)}
          </Box>
        </Show>
        <Show when={props.templateType === 'email'}>
          <Box data-testid={testIds.settings.messageTemplates('previewDialog-emailSubject')}>
            {renderMultiLine(renderDataEmail?.subject)}
          </Box>
          <Separator spacing={3} />
          <Box data-testid={testIds.settings.messageTemplates('previewDialog-emailBody')}>
            {renderMultiLine(renderDataEmail?.body)}
          </Box>
        </Show>
      </DataStatus>
    </Dialog>
  );
};
