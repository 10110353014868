import {ReceiveJobItemRowData} from '../types/ReceiveJobItemRowData';

export function getDialogTitle(castedRowData: ReceiveJobItemRowData) {
  const {tireManufacturer, tireSeason, setNumber} = castedRowData;

  const getSetNumber = () => {
    if (setNumber?.value) {
      return `${setNumber.value}, `;
    }

    return '';
  };

  const getTitleData = () => {
    if (!tireManufacturer?.value && !tireSeason?.value) {
      return '';
    }

    if (!tireManufacturer?.value) {
      return tireSeason?.value.label;
    }

    if (!tireSeason?.value) {
      return tireManufacturer?.value.label;
    }

    return `${tireManufacturer?.value.label}, ${tireSeason?.value.label}`;
  };

  return `${getSetNumber()}${getTitleData()}`;
}
