import {match, Pattern} from 'ts-pattern';

import {always, not} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {Nullish, precisionCalculation} from 'shared';

/**
 * @param number Value to increment.
 * @param min Minimum value.
 * @param max Maximum value.
 * @param step Increment step. Default is 1.
 * @param shouldRoundStepsByDifference Calculates the next valid step based on the difference between
 *  the current value and the step value. Default is false.
 * @returns Incremeted number, `max` if it is greater than `max` or `min` if `number` is null.
 */
export function getIncrementedNumber(
  number: number | Nullish,
  min: number | Nullish,
  max: number | Nullish,
  step = 1,
  shouldRoundStepsByDifference = false
) {
  // Number(value) is used to handle cases where the number param is a string.
  const newValue = match([number, min])
    .with(
      [Pattern.union(Pattern.number, Pattern.string), Pattern.optional(Pattern.number)],
      ([value]) => {
        const currentValue = Number(value);

        if (not(shouldRoundStepsByDifference)) {
          return precisionCalculation.add(currentValue, step);
        }

        // Get the difference between current value and step value
        const difference = precisionCalculation.modulo(currentValue, step);
        const nextValidStep = precisionCalculation.subtract(step, difference);
        return precisionCalculation.add(currentValue, nextValidStep);
      }
    )
    .with([null, Pattern.number], ([, min]) => min)
    .otherwise(always(step));

  return isNotNil(max) && newValue > max ? max : newValue;
}
