import {z} from 'zod';

import {BaseIdSchema, CreatedAndUpdatedBySchema} from './base';

const BaseDiscountGroupSchema = z.object({
  warehouseId: z.string().optional(),
  name: z.string(),
  regularOrderDiscount: z.number(),
  expressOrderDiscount: z.number(),
});

/**
 * GET requests
 */
export const GetDiscountGroupsRequestSchema = z.union([
  z.object({
    warehouseId: z.string().optional(),
  }),
  z.void(),
]);
export type GetDiscountGroupsRequest = z.infer<typeof GetDiscountGroupsRequestSchema>;

export const GetDiscountGroupsResponseSchema = z.array(BaseDiscountGroupSchema.merge(BaseIdSchema));
export type GetDiscountGroupsResponse = z.infer<typeof GetDiscountGroupsResponseSchema>;

export const GetDiscountGroupRequestSchema = z.object({
  discountGroupId: z.string(),
});
export type GetDiscountGroupRequest = z.infer<typeof GetDiscountGroupRequestSchema>;

export const GetDiscountGroupResponseSchema =
  BaseDiscountGroupSchema.merge(CreatedAndUpdatedBySchema).merge(BaseIdSchema);
export type GetDiscountGroupResponse = z.infer<typeof GetDiscountGroupResponseSchema>;

/**
 * POST requests
 */
export const PostDiscountGroupRequestSchema = BaseDiscountGroupSchema;
export type PostDiscountGroupRequest = z.infer<typeof PostDiscountGroupRequestSchema>;

export const PostDiscountGroupResponseSchema = BaseDiscountGroupSchema;
export type PostDiscountGroupResponse = z.infer<typeof PostDiscountGroupResponseSchema>;

/**
 * PATCH requests
 */
export const PatchDiscountGroupRequestSchema = z.object({
  discountGroupId: z.string(),
  body: BaseDiscountGroupSchema,
});
export type PatchDiscountGroupRequest = z.infer<typeof PatchDiscountGroupRequestSchema>;

export const PatchDiscountGroupResponseSchema = BaseDiscountGroupSchema;
export type PatchDiscountGroupResponse = z.infer<typeof PatchDiscountGroupResponseSchema>;
