import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {isNotNil} from 'ramda-adjunct';

import {getIdFromQueryString} from './utils/getIdFromQueryString';
import {getModuleFromQueryString} from './utils/getModuleFromQueryString';
import {getPathToRedirect} from './utils/getPathToRedirect';
import {getSubmoduleFromQueryString} from './utils/getSubmoduleFromQueryString';
import {getSubmoduleIdFromQueryString} from './utils/getSubmoduleIdFromQueryString';
import {getViewFromQueryString} from './utils/getViewFromQueryString';

export function Redirect() {
  const navigate = useNavigate();
  const urlSearchString = window.location.search;

  const module = getModuleFromQueryString(urlSearchString);
  const view = getViewFromQueryString(urlSearchString);
  const id = getIdFromQueryString(urlSearchString);
  const submodule = getSubmoduleFromQueryString(urlSearchString);
  const submoduleId = getSubmoduleIdFromQueryString(urlSearchString);

  const path = getPathToRedirect({
    module,
    view,
    id,
    submodule,
    submoduleId,
  });

  useEffect(() => {
    if (isNotNil(path)) {
      navigate(path, {replace: true});
    }
  }, [navigate, path]);

  return null;
}
