import {FC, useMemo, useRef, useEffect, useState, cloneElement} from 'react';

import {Portal} from '../Portal/Portal';
import {useDnDContext} from './hooks/useDnDContext';
import {DraggableProps, DraggedContentProps, HoverPositions} from './types';

/**
 * Draggable component -
 * Needs to be wrapped with `Droppable` component - required
 * Needs to be wrapped with `DragAndDropProvider` for drag to work
 *
 * - `props` - options of `Draggable` - described in detail in `DraggableProps` type
 *
 * @example
 * const dragHandler = (dragProps) => {
 * 	if(dragProps.draggable){
 * 		return <div>DRAGGABLE ITEM</div>
 * 	}
 *
 * 	return <div>rendered item</div>
 * }
 *
 * <DragAndDropProvider {...providerProps}>
 * 	...
 * 		<Droppable droppableId="0">
 * 			...
 * 				<Draggable>{(elProps, dragProps) => (<div {...elProps}>Draggable item</div>)}</Draggable>
 * 				<Draggable>{(elProps, dragProps) => (<div {...elProps}>{dragHandler(dragProps)}</div>)}</Draggable>
 * 			...
 * 		</Droppable>
 * 	...
 * </DragAndDropProvider>
 */
export function Draggable<T>({
  children,
  draggableId,
  data,
  disablePositions,
  disable,
}: DraggableProps<T>) {
  const {
    isDragged,
    selectedItems,
    contextState,
    droppableId,
    draggedItem,
    providerId,
    globalDragging,
  } = useDnDContext<T>(draggableId);
  // Used to skip animation when drop is succesful
  const [reset, setReset] = useState(true);
  // To keep track when animation finishes
  const [finishedDragging, setFinishedDragging] = useState(true);
  // To keep track on which side drag is happening
  const [hoverPosition, setHoverPosition] = useState<null | HoverPositions>(null);
  // Ref of wrapped component
  const wrapperRef = useRef<HTMLDivElement>(null);

  const getBounds = () => wrapperRef?.current?.getBoundingClientRect?.();

  const handleDragStart = (e: DragEvent) => {
    contextState.current.onDragStart?.(draggableId);
    contextState.current.setDraggedItem({
      dragId: draggableId,
      dropId: droppableId,
    });
    e.preventDefault();
  };

  const handleDragOver = (e: MouseEvent) => {
    const width = getBounds()?.width ?? 0;

    if (disablePositions) {
      contextState.current.setHoveredItem(draggableId, HoverPositions.Left);
      setHoverPosition(HoverPositions.Left);
      return;
    }

    if (width / 2 > e.offsetX) {
      contextState.current.setHoveredItem(draggableId, HoverPositions.Left);
      setHoverPosition(HoverPositions.Left);
    } else {
      contextState.current.setHoveredItem(draggableId, HoverPositions.Right);
      setHoverPosition(HoverPositions.Right);
    }
  };

  const handleDragLeave = () => {
    contextState.current.setHoveredItem();
    setHoverPosition(null);
  };

  const handleDragEnd = () => {
    if (isDragged) {
      if (contextState.current.lastHoveredItem.current || contextState.current.activeDroppable) {
        setReset(false);
      }
      contextState.current.setDraggedItem({dragId: null});
      contextState.current.onDragEnd?.(draggableId);
    }
    setHoverPosition(null);
  };

  // @todo add touch listeners
  useEffect(() => {
    const _el = wrapperRef.current;

    if (disable) {
      return;
    }

    _el?.addEventListener('dragstart', handleDragStart);
    if (draggedItem) {
      _el?.addEventListener('mousemove', handleDragOver);
      _el?.addEventListener('mouseleave', handleDragLeave);
      document.addEventListener('mouseup', handleDragEnd);
    }

    return () => {
      _el?.removeEventListener('dragstart', handleDragStart);
      _el?.removeEventListener('mousemove', handleDragOver);
      _el?.removeEventListener('mouseleave', handleDragLeave);
      document.removeEventListener('mouseup', handleDragEnd);
    };
  }, [disable, isDragged, draggedItem, draggableId, disablePositions]);

  useEffect(() => {
    const _context = contextState.current;
    _context.register(draggableId, data, droppableId);

    return () => {
      setHoverPosition(null);
      _context.unregister(draggableId);
    };
  }, [data, draggableId, contextState, droppableId]);

  useEffect(() => {
    if (!reset) {
      setReset(true);
    }
  }, [reset]);

  // Rendered element
  const renderedEl = useMemo(
    () =>
      children(
        {
          ref: wrapperRef,
          id: draggableId,
          'data-draggable': providerId,
          'data-draggable-context': droppableId,
          draggable: true,
        },
        {
          isSelected: selectedItems.isSelected,
          draggable: false,
          hoveredSide: hoverPosition,
          isDragging: (!!draggedItem && selectedItems.isSelected) || isDragged,
          globalDragging,
        }
      ),
    [
      children,
      draggableId,
      providerId,
      draggedItem,
      droppableId,
      hoverPosition,
      isDragged,
      selectedItems.isSelected,
    ]
  );

  // Element used for dragging
  const draggedElement = children(
    {
      ref: null,
    },
    {
      isSelected: selectedItems.isSelected,
      draggable: true,
      isDragging: (!!draggedItem && selectedItems.isSelected) || isDragged,
    }
  );

  const getTotalCount = () => {
    let counter = selectedItems.count;

    if (!finishedDragging && !selectedItems.isSelected) {
      counter++;
    }

    return counter;
  };

  const showCustomComponent = () => {
    if (!finishedDragging) {
      if (getTotalCount() >= 2) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {renderedEl}
      {reset && (
        <DraggedContent
          isDragging={isDragged}
          bounds={getBounds}
          finishedCallback={setFinishedDragging}
        >
          {showCustomComponent() && contextState.current.multipleSelectionComponent
            ? contextState.current.multipleSelectionComponent?.(getTotalCount())
            : draggedElement}
        </DraggedContent>
      )}
    </>
  );
}

const DraggedContent: FC<DraggedContentProps> = ({
  isDragging,
  bounds,
  children,
  finishedCallback,
}) => {
  const positionRef = useRef<null | {left: number; top: number}>(null);
  const [position, setPosition] = useState<null | {left: number; top: number}>(null);
  const [isOn, setIsOn] = useState(false);

  const handleMouseMove = (e: MouseEvent) => {
    positionRef.current = {top: e.clientY - 6, left: e.clientX - 12};
  };

  useEffect(() => {
    finishedCallback(!isOn);
  }, [isOn]);

  useEffect(() => {
    let interval: number;

    if (isDragging) {
      setIsOn(true);
      document.addEventListener('mousemove', handleMouseMove);
      document.documentElement.style.setProperty('cursor', 'grabbing', 'important');

      // Updating position of dragged item with interval
      interval = window.setInterval(() => {
        if (positionRef.current) {
          setPosition({top: positionRef.current.top, left: positionRef.current.left});
        } else {
          setPosition(null);
        }
      }, 75);
    } else {
      document.documentElement.style.setProperty('cursor', '');

      setPosition(null);

      setTimeout(() => {
        setIsOn(false);
      }, 200);
    }

    return () => {
      clearInterval(interval);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging]);

  return isOn ? (
    <Portal selector="#modals">
      {cloneElement(children, {
        ...children.props,
        draggable: false,
        ref: null,
        style: {
          pointerEvents: 'none',
          position: 'absolute',
          top: position?.top ?? bounds()?.top,
          left: position?.left ?? bounds()?.left,
          zIndex: 1000,
          width: bounds()?.width,
          height: bounds()?.height,
          transition: 'top ease 150ms, left ease 150ms',
          ...children.props.style,
        },
      })}
    </Portal>
  ) : null;
};
