import styled from 'styled-components';

import {ReactElement} from 'react';

import {BadgeWrapper} from '../styles';

export const BadgeComponent = ({title, length}: {title: string; length: number}): ReactElement => (
  <>
    {title ?? ''}{' '}
    {Boolean(length) && (
      <BadgeWrapper>
        <Circle>{length + 1}</Circle>
      </BadgeWrapper>
    )}
  </>
);

const Circle = styled.span`
  color: ${({theme}) => theme.colors.palettes.white[10][100]};
  font-size: ${({theme}) => theme.fontSizes.text.xxSmall};
  line-height: 0.7;
  letter-spacing: 0.32px;
  font-weight: 700;
  height: 16px;
  min-width: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4.5px 5px;
  border-radius: 50%;
  background: ${({theme}) => theme.colors.palettes.blue[60][100]};
`;
