import {
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Search,
  showNotification,
} from 'platform/components';
import {Box, Heading, VStack} from 'platform/foundation';

import {useMemo, useState} from 'react';

import {isEmpty} from 'ramda';

import {
  PermissionScopeResponseBody,
  useGetRoleQuery,
  useListPermissionsV2Query,
  useListProtectedUnitsQuery,
  usePatchRoleMutation,
  usePutPermissionsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {useDebouncedValue, useNavigate, useRequiredParams} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {AccessControlList} from '../../AccessControlList/AccessControlList';
import {getAllProtectedUnitsDefaultValues} from '../utils/getAllProtectedUnitsDefaultValues';
import {getGrantedProtectedUnits} from '../utils/getGrantedProtectedUnits';
import {getSubmitPermissionsData} from '../utils/getSubmitPermissionsData';

type FormValues = {
  title: string;
  protectedUnits: Record<string, {value: boolean; scopes: PermissionScopeResponseBody[]}>;
};

export function RoleDetail() {
  const {roleId} = useRequiredParams();
  const [searchString, setSearchString] = useState<string | null>(null);

  const navigate = useNavigate();

  const {data: role, isLoading: isLoadingRole, isError: isRoleError} = useGetRoleQuery({roleId});

  const {
    data: permissions,
    isLoading: isLoadingPermissions,
    isError: isErrorPermissions,
  } = useListPermissionsV2Query({
    roleId,
  });

  const {
    data: resources,
    isLoading: isLoadingResources,
    isError: isResourcesError,
  } = useListProtectedUnitsQuery();

  const isLoading = isLoadingPermissions || isLoadingResources || isLoadingRole;
  const isError = isErrorPermissions || isResourcesError || isRoleError;

  const [updateRoleMutation] = usePatchRoleMutation();
  const [updatePermissionsMutation] = usePutPermissionsMutation();

  const defaultValues: FormValues = useMemo(
    () => ({
      title: role?.title || '',
      protectedUnits: {
        ...getAllProtectedUnitsDefaultValues(resources),
        ...getGrantedProtectedUnits(permissions),
      },
    }),
    [role?.title, resources, permissions]
  );

  const deferredSearch = useDebouncedValue(searchString, 2000);

  const redirectToList = () => navigate(settingsRoutes.roleManagement);

  const handleUpdateRole: FormSubmitHandler<FormValues> = async ({title, protectedUnits}) => {
    const updateRole = updateRoleMutation({
      roleId,
      patchRoleRequestBody: {title},
    })
      .unwrap()
      .catch(handleApiError);

    const updatePermissions = updatePermissionsMutation({
      roleId,
      permissions: getSubmitPermissionsData(protectedUnits),
    })
      .unwrap()
      .catch(handleApiError);

    await Promise.all([updateRole, updatePermissions])
      .then(() => showNotification.success())
      .then(redirectToList);
  };

  return (
    <DataStatus
      isLoading={isLoading}
      isError={isError}
      isEmpty={isEmpty(resources)}
      minHeight={200}
    >
      <Form<FormValues> defaultValues={defaultValues} onSubmit={handleUpdateRole}>
        {(control) => (
          <VStack spacing={5}>
            <Box maxWidth={100}>
              <FormField
                control={control}
                name="title"
                type="text"
                isDisabled={role?.system}
                label={i18n.t('page.settings.labels.roleName')}
                data-testid={testIds.settings.roleManagementDetail('roleName')}
              />
            </Box>

            <Heading size={4}>{i18n.t('page.settings.role.tabs.label.permissions')}</Heading>
            <Box width={60}>
              <Search value={searchString} onChange={setSearchString} />
            </Box>

            <AccessControlList
              roleId={roleId}
              searchString={deferredSearch}
              resources={resources}
            />

            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discard'),
                  variant: 'secondary',
                  onClick: redirectToList,
                },
                {
                  type: 'form-button',
                  control,
                  title: i18n.t('general.actions.saveChanges'),
                  buttonType: 'submit',
                  isDisabled: role?.system,
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
