import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {GetComment} from '../../types/GetComment';
import {NAME} from './constants';

export type CommentsStateType = {
  readonly commentsList: Array<GetComment>;
  readonly fetched: boolean;
};

const initialState: CommentsStateType = {
  commentsList: [],
  fetched: false,
};

const commentsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    loadComments: (state, {payload}: PayloadAction<Array<GetComment>>) => ({
      ...state,
      commentsList: payload,
      fetched: true,
    }),
    resetComments: (state) => ({
      ...state,
      commentsList: [],
      fetched: false,
    }),
    createComment: (state, {payload}: PayloadAction<GetComment>) => ({
      ...state,
      commentsList: [...state.commentsList, payload],
      fetched: true,
    }),

    updateComment: (state, {payload}: PayloadAction<Pick<GetComment, 'uuid' | 'message'>>) => {
      const updatedList = state.commentsList.map((item) =>
        item.uuid === payload.uuid ? {...item, message: payload.message} : item
      );
      return {...state, commentsList: updatedList, fetched: true};
    },
    deleteComment: (state, {payload}: PayloadAction<Pick<GetComment, 'uuid'>>) => {
      const updatedList = state.commentsList.filter((item) => item.uuid !== payload.uuid);
      return {...state, commentsList: updatedList};
    },
  },
});

const {reducer, actions} = commentsSlice;

export const commentsReducer = reducer;

export const {loadComments, createComment, resetComments, updateComment, deleteComment} = actions;
