import {z} from 'zod';

/**
 * GET
 */
export const GetArticleStockAvailabilityRequestSchema = z.object({articleId: z.string()});
export type GetArticleStockAvailabilityRequest = z.infer<
  typeof GetArticleStockAvailabilityRequestSchema
>;

export const GetArticleStockAvailabilityResponseSchema = z.object({
  articleId: z.string(),
  stockQuantity: z.number(),
  reservedQuantity: z.number(),
  availableQuantity: z.number(),
  inTransitQuantity: z.number(),
});
export type GetArticleStockAvailabilityResponse = z.infer<
  typeof GetArticleStockAvailabilityResponseSchema
>;

export const GetArticleStockItemOverviewRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
});
export type GetArticleStockItemOverviewRequest = z.infer<
  typeof GetArticleStockItemOverviewRequestSchema
>;

export const GetArticleStockItemOverviewResponseSchema = z.object({
  articleId: z.string(),
  totalValue: z.number(),
  totalInventory: z.number(),
  lastReception: z.string().optional(),
});
export type GetArticleStockItemOverviewResponse = z.infer<
  typeof GetArticleStockItemOverviewResponseSchema
>;

export const GetArticleStockAvailabilityOverviewRequestSchema = z.object({
  warehouseId: z.string(),
  articleId: z.string(),
});
export type GetArticleStockAvailabilityOverviewRequest = z.infer<
  typeof GetArticleStockAvailabilityOverviewRequestSchema
>;

export const GetArticleStockAvailabilityOverviewResponseSchema = z.object({
  articleId: z.string(),
  stockQuantity: z.number(),
  reservedQuantity: z.number(),
  availableQuantity: z.number(),
  inTransitOnStockQuantity: z.number(),
  inTransitForRequestsQuantity: z.number(),
  inTransitQuantity: z.number(),
  pendingCreditNoteQuantity: z.number(),
  lastOrder: z.string().optional(),
  lastReception: z.string().optional(),
  lastDispatch: z.string().optional(),
});
export type GetArticleStockAvailabilityOverviewResponse = z.infer<
  typeof GetArticleStockAvailabilityOverviewResponseSchema
>;

/**
 * POST
 */

/**
 * PATCH
 */

/**
 * DELETE
 */
