import styled from 'styled-components';
import styledMap from 'styled-map';

import {FC} from 'react';

import {isNotNil} from 'ramda-adjunct';

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 28px;
`;

const backgroundColor = styledMap`
	default: ${({theme}: any) => theme.colors.palettes.neutral[40][100]};
	rank1: ${({theme}: any) => theme.colors.palettes.red[60][100]};
	rank2: ${({theme}: any) => theme.colors.palettes.orange[60][100]};
	rank3: ${({theme}: any) => theme.colors.palettes.yellow[60][100]};
	rank4: ${({theme}: any) => theme.colors.palettes.green[60][100]};
	rank5: ${({theme}: any) => theme.colors.palettes.blue[60][100]};
`;

type ColumnProps = {
  $showRank?: boolean;
};

const Column = styled.div<ColumnProps>`
  background-color: ${({$showRank, theme}) =>
    $showRank ? backgroundColor : theme.colors.palettes.neutral[40][100]};
  width: 4px;
  height: 16px;
  border-radius: 2px;
`;

export type RankProps = {
  rank?: number;
};

export const Rank: FC<RankProps> = ({rank}) => {
  let countOfColoredColumns = !isNotNil(rank) ? 0 : Math.ceil(rank / 0.2);
  if (isNotNil(rank) && [20, 40, 60, 80].includes(rank * 100)) {
    countOfColoredColumns = countOfColoredColumns + 1;
  }

  const rankColor = {[`rank${countOfColoredColumns}`]: true};

  return (
    <Wrapper>
      <Column $showRank {...rankColor} />
      <Column $showRank={countOfColoredColumns > 1} {...rankColor} />
      <Column $showRank={countOfColoredColumns > 2} {...rankColor} />
      <Column $showRank={countOfColoredColumns > 3} {...rankColor} />
      <Column $showRank={countOfColoredColumns > 4} {...rankColor} />
    </Wrapper>
  );
};
