import {useParams} from 'react-router-dom';

import {isNilOrEmpty, isString} from 'ramda-adjunct';

/**
 * @returns validated object with non-nullish type
 * @example const {id, type} = useRequiredParams()
 * // id and type are both validated and type string
 */
export function useRequiredParams<T extends Record<string, string> = Record<string, string>>() {
  const params = useParams();
  const initialParams = params as T;

  const proxiedArray = new Proxy(initialParams, {
    get: (_, prop) => {
      if (!isString(prop)) {
        throw new Error(`requested property must be a string`);
      }

      const targetedParam = params[prop];

      if (isNilOrEmpty(targetedParam)) {
        throw new Error(`missing parameter ${prop} in url`);
      }

      return targetedParam;
    },
  });

  return proxiedArray;
}
