import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteVatTotalAndCalculationSettingMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function VatTotalAndCalculationOverview() {
  const navigate = useNavigate();
  const [deleteConfiguration] = useDeleteVatTotalAndCalculationSettingMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData, rowData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;
    const branchName = path<string>(['branch', 'value', 'label'], rowData);

    match(actionKey)
      .with('DETAIL', 'EDIT', () =>
        navigate(composePath(settingsRoutes.vatTotalAndCalculationDetail, {params: {id}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteConfiguration({vatTotalAndCalculationId: id})
              .unwrap()
              .then(refreshData)
              .then(() => showNotification.success())
              .catch(handleApiError),
          text: i18n.t('entity.accounting.labels.deleteDefinitionDeleteText', {
            name: branchName,
          }),
        })
      );
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.accountingSettings.labels.vatTotalAndCalculation'),
        actions: [
          {
            type: 'button',
            title: i18n.t('page.accounting.labels.newDefinition'),
            onClick: () => navigate(settingsRoutes.vatTotalAndCalculationNew),
          },
        ],
      }}
      description={i18n.t('page.accountingSettings.labels.vatTotalAndCalculationDescription')}
      data-testid={testIds.settings.vatTotalAndCalculationOverview('page')}
    >
      <DataGrid
        gridCode="vat-total-and-calculation"
        actionCallback={actionCallback}
        data-testid={testIds.settings.vatTotalAndCalculationOverview('data-grid')}
      />
    </SettingsTemplate>
  );
}
