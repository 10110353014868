import {Integer, HStack} from 'platform/foundation';

import {suffixTestId, TestIdProps, getRandomId} from 'shared';

import {Flag, FlagProps} from '../Flag/Flag';

export interface FlagsProps extends Omit<FlagProps, 'label'>, TestIdProps {
  flags?: FlagProps[] | null;
  spacing?: Integer;
  shouldWrap?: boolean;
}

export function Flags(props: FlagsProps) {
  const {flags, spacing, ...flagOverrides} = props;

  if (!flags?.length) {
    return null;
  }

  return (
    <HStack
      spacing={spacing ?? 2}
      data-testid={suffixTestId('flags', props)}
      wrap={props.shouldWrap}
      align="center"
    >
      {flags.map((flag: FlagProps, index) => (
        <Flag
          {...flag}
          {...flagOverrides}
          key={`${flag.label}-${getRandomId()}`}
          data-testid={suffixTestId(`flag-[${index}]`, props)}
        />
      ))}
    </HStack>
  );
}
