import {createSelector} from '@reduxjs/toolkit';

import type {TeasState} from '../../types/TeasState';

export const selectGeneralSettings = (state: TeasState) => state.generalSettings;

export const selectIntegrationsSettings = createSelector(
  selectGeneralSettings,
  (state) => state?.integrationsSettings
);
