import {Button, ButtonGroup, Dialog} from 'platform/components';
import {Space} from 'platform/foundation';

import {FC} from 'react';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

interface ComponentProps {
  isOpen?: boolean;
  isLoading?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}

export const DeleteDialog: FC<ComponentProps> = ({onClose, onConfirm, isOpen, isLoading}) => (
  <Dialog size="small" isOpen={!!isOpen} onClose={() => onClose?.()}>
    {i18n.t('page.vehicleSeriesDefinition.notifications.confirmDeleteDialog')}
    <Space vertical={4} />
    <ButtonGroup align="right">
      <Button
        onClick={onClose}
        variant="secondary"
        data-testid={testIds.settings.vehicleSeriesDefinition('cancelDeletion')}
        title={i18n.t('general.actions.cancel')}
      />
      <Button
        isLoading={isLoading}
        onClick={onConfirm}
        data-testid={testIds.settings.vehicleSeriesDefinition('confirmDeletion')}
        title={i18n.t('general.actions.delete')}
      />
    </ButtonGroup>
  </Dialog>
);
