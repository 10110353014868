import {isEmpty, isNil} from 'ramda';

import {SourcingTableName} from '@omnetic-dms/teas';

function getIdKey(tableName: SourcingTableName) {
  return `sourcing-table--last-visited-vehicle-id/${tableName}`;
}

function getPageKey(tableName: SourcingTableName) {
  return `sourcing-table--last-visited-page/${tableName}`;
}

export const sourcingTableSessionStorage = {
  setLastVisitedVehicleId(tableName: SourcingTableName, adId: string) {
    sessionStorage.setItem(getIdKey(tableName), adId);
  },
  getLastVisitedVehicleId(tableName: SourcingTableName) {
    return sessionStorage.getItem(getIdKey(tableName)) || null;
  },
  setLastVisitedPage(tableName: SourcingTableName, page: number) {
    sessionStorage.setItem(getPageKey(tableName), page.toString());
  },
  getLastVisitedPage(tableName: SourcingTableName): number | null {
    const page = sessionStorage.getItem(getPageKey(tableName));
    return isNil(page) || isEmpty(page) ? null : parseInt(page);
  },
};
