import {Show, Stack, VStack} from 'platform/foundation';

import {union, without} from 'ramda';

import {convertStringToCamelCase, suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {Checkbox} from '../Checkbox/Checkbox';
import {CheckboxOption, CheckboxPropsBase} from '../Checkbox/types';
import {HelperText} from '../HelperText/HelperText';
import {Label} from '../Label/Label';

export interface CheckboxesProps
  extends CheckboxPropsBase,
    FormControlProps<string[] | null>,
    TestIdProps {
  options: CheckboxOption[];
  direction?: 'horizontal' | 'vertical';
}

export function Checkboxes(props: CheckboxesProps) {
  const isInvalid = props.isInvalid || !!props.errorMessage;

  const handleChange = (selectedValue: string, isChecked: boolean) => {
    const nextValue = isChecked
      ? union(props.value ?? [], [selectedValue])
      : without([selectedValue], props.value ?? []);

    props.onChange?.(nextValue);
  };

  return (
    <VStack>
      <Label
        isRequired={props.isRequired}
        tooltip={props.tooltip}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <Stack direction={'vertical' === props.direction ? 'column' : 'row'} spacing={4}>
        {props.options.map(({label, value, checkedIcon}) => (
          <Checkbox
            key={value}
            label={label}
            isInvalid={isInvalid}
            checkedIcon={checkedIcon ?? props.checkedIcon}
            value={!!props.value?.includes(value)}
            onChange={(isChecked) => handleChange(value, isChecked)}
            data-testid={suffixTestId(convertStringToCamelCase(value), props)}
          />
        ))}
      </Stack>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
