import {Flag} from 'platform/components';
import {Box, Clickable, Image, VStack} from 'platform/foundation';
import {useTheme} from 'styled-components';

type ImageItemProps = {
  value: string;
  name: string;
  isActive: boolean;
  onClick: () => void;
};

export const ImageItem = (props: ImageItemProps) => {
  const theme = useTheme();

  return (
    <VStack justify="center">
      <Box
        borderRadius="medium"
        overflow="hidden"
        border={`3px solid ${props.isActive ? theme.colors.severity.informational : 'transparent'}`}
        position="relative"
      >
        <Clickable onClick={props.onClick}>
          <Box position="absolute" top={1} left={1} zIndex="CONTENT">
            <Flag
              data-testid={`background-removal-choose-image-${props.name}-${props.value}`}
              colorScheme={props.isActive ? 'blue' : 'neutral'}
              {...(!props.isActive && {isSubtle: true})}
              label={props.name}
            />
          </Box>
          <Image
            src={`assets/images/background-replacement/${props.value}.jpg`}
            data-testid={`background-removal-choose-image-${props.name}-${props.value}`}
          />
        </Clickable>
      </Box>
    </VStack>
  );
};
