import {useBoolean} from './useBoolean';
import {useDidUpdate} from './useDidUpdate';

/*
 * we want to keep track of if error on RTK Query loading occurred
 * which happens when first loading data e.g. note that does not exist (returns 404)
 * to prevent isLoading to happen again (e.g. for DataStatus) when first get for existing data happens
 * */
export function useIsLoadingWithInitialError(isLoading: boolean, isError: boolean) {
  const [didErrorOccur, setDidErrorOccurTrue] = useBoolean(false);

  useDidUpdate(() => {
    if (isError) {
      setDidErrorOccurTrue();
    }
  }, [isError]);

  return !didErrorOccur && isLoading;
}
