import {ReactNode} from 'react';

import {PortalManagerContextProvider} from './PortalContext';

export interface PortalManagerProps {
  children?: ReactNode;
  /**
   * [Z-Index war] If your has multiple elements
   * with z-index clashing, you might need to apply a z-index to the Portal manager
   */
  zIndex?: number;
}

export function PortalManager(props: PortalManagerProps) {
  const {children, zIndex} = props;
  return <PortalManagerContextProvider value={{zIndex}}>{children}</PortalManagerContextProvider>;
}
