import {FC, ReactNode} from 'react';

import {TestIdProps} from 'shared';

import {ChoiceContextProvider} from '../hooks/choiceContext';

interface ChoiceProviderProps {
  options: TestIdProps & {hasOptionCheckbox?: boolean; 'data-name'?: string};
  children: ReactNode;
}

export const ChoiceProvider: FC<ChoiceProviderProps> = ({children, options}) => {
  const {hasOptionCheckbox} = options;

  return (
    <ChoiceContextProvider
      value={{
        'data-testid': options['data-testid'],
        'data-name': options['data-name'],
        hasOptionCheckbox,
      }}
    >
      {children}
    </ChoiceContextProvider>
  );
};
