import {Dispatch, useEffect, useState} from 'react';

import {usePrevious} from '@chakra-ui/react';

import {useDataGridContext} from '../context/useDataGridContext';
import {ColumnApi, GridApi} from '../types/AgGridTypes';
import {FeGridSettings} from '../types/Api';
import {LineHeightMappingToPx} from '../utils/lineHeightToPx';

/**
 * Returns memorized settings and callback to change settings.
 * Also handles changes of defaultSettings and api calls when specific settings is changed to specific values.
 * EG.:
 *  - settings.masterDetail was changed to false, therefore we dont want user to be able to show master detail
 *  - we have to call gridApi to hide any detail that was shown before settings change
 */
export const useDefaultSettings = (
  gridApi?: GridApi,
  columnApi?: ColumnApi
): [FeGridSettings, Dispatch<FeGridSettings>] => {
  const context = useDataGridContext();
  const [settings, setSettings] = useState<FeGridSettings>(() => context.settings);

  const prevSettings = usePrevious(settings);
  useEffect(() => {
    if (!gridApi || !columnApi) {
      return;
    }
    const changes = {
      pageSize: settings.itemsPerPage !== prevSettings.itemsPerPage,
      rowHeight: settings.rowHeight !== prevSettings.rowHeight,
      horizontalSeparators:
        settings.horizontalSeparatorsVisible !== prevSettings.horizontalSeparatorsVisible,
      verticalSeparators:
        settings.verticalSeparatorsVisible !== prevSettings.verticalSeparatorsVisible,
      zebra: settings.stripedRows !== prevSettings.stripedRows,
    };

    if (changes.pageSize) {
      gridApi.paginationSetPageSize(settings.itemsPerPage ?? 90e90);
      gridApi.refreshServerSide({purge: true});
    }
    if (changes.rowHeight) {
      gridApi.forEachNode((rowNode) =>
        rowNode.setRowHeight(LineHeightMappingToPx[settings.rowHeight])
      );
      gridApi.onRowHeightChanged();
    }
    if (
      changes.rowHeight ||
      changes.zebra ||
      changes.verticalSeparators ||
      changes.horizontalSeparators
    ) {
      gridApi.redrawRows();
    }
  }, [prevSettings, settings, gridApi, columnApi]);

  return [settings, setSettings];
};
