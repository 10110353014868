import i18n from '@omnetic-dms/i18n';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {CustomerVerificationList} from './components/CustomerVerificationList';

export function CustomerVerification() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('page.integrations.labels.customerVerification')}}
      data-testid="integrations-customerVerification-settings"
    >
      <CustomerVerificationList />
    </SettingsTemplate>
  );
}
