import {Text} from 'platform/foundation';

import {forwardRef, Ref} from 'react';

import {ICellRenderer, ICellRendererParams} from '../../types/AgGridTypes';

export type FileType = string;
export interface FilePlainCellRendererProps extends ICellRendererParams {
  id: string;
  title: string;
  type: FileType;
  remoteUrl: string;
}

function FilePlainCellRendererComponent(
  props: FilePlainCellRendererProps,
  ref: Ref<ICellRenderer>
) {
  const values = props?.value;
  return <Text size="small">{values?.title}</Text>;
}

export const FilePlainCellRenderer = forwardRef<ICellRenderer, FilePlainCellRendererProps>(
  FilePlainCellRendererComponent
);
