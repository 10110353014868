import styled from 'styled-components';

interface HeaderButtonWrapperProps {
  $isOpen: boolean;
  $rounded?: boolean;
  $size?: 'small' | 'default';
}

export const HeaderButtonWrapper = styled.div<HeaderButtonWrapperProps>`
  background-color: ${({theme, $isOpen}) => $isOpen && theme.colors.palettes.neutral[30][100]};
  height: ${({theme, $size}) => theme.getSize($size === 'small' ? 6 : 12)};
  width: ${({theme, $size}) => theme.getSize($size === 'small' ? 6 : 12)};
  border-radius: ${({$rounded}) => ($rounded ? '50%' : '0')};
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  }
  cursor: pointer;
`;
