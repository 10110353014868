import {
  Action,
  Card,
  closeCurrentDialog,
  DataStatus,
  openDialog,
  Separator,
} from 'platform/components';
import {Show, VStack, Grid, Hide} from 'platform/foundation';
import {Currency, formatCurrencySymbol} from 'platform/locale';

import {subtract} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetCurrenciesQuery, useGetSaleVehicleQuery, useGetTenantQuery} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  usePricePermissions,
  useSaleVehicleActions,
  EditVehiclePriceForm,
} from '@omnetic-dms/shared';

import {buildArray, CurrencyCodeType, Nullish, TestIdProps} from 'shared';

import {PriceColumn} from './PriceColumn';

interface OverviewCardProps extends TestIdProps {
  vehicleId: string;
  onEdit: VoidFunction;
}

export function OverviewCard(props: OverviewCardProps) {
  const {data: tenant, isLoading, isError} = useGetTenantQuery();
  const {data: saleVehicle} = useGetSaleVehicleQuery({vehicleId: props.vehicleId});
  const {data: currencies} = useGetCurrenciesQuery();

  const {isSaleActionEnabled} = useSaleVehicleActions(props.vehicleId);

  const {
    canViewAnyOfSalePriceFields,
    canViewAnyOfPurchasePriceFields,
    canEditVehicleSalePrice: hasVehicleEditSalePricePermission,
    canEditVehiclePurchasePrice: hasVehicleEditPurchasePricePermission,
    canReadVehicleEarnings: canReadEarnings,
    canReadVehicleCosts: canReadCosts,
    ...pricePermissions
  } = usePricePermissions({
    vehicleRecordId: props.vehicleId,
    businessCaseRecordId: null,
  });

  const canEditPrice = hasVehicleEditSalePricePermission || hasVehicleEditPurchasePricePermission;

  const isBrokerage = saleVehicle?.type === 'BROKERAGE';

  const currency = (saleVehicle?.currency || tenant?.currency) as CurrencyCodeType | Nullish;
  const sellingPrice = saleVehicle?.sellingPrice;
  const purchasePrice = saleVehicle?.purchasePrice;
  const realSellingPrice = saleVehicle?.financialIndicators?.realSellingPrice;
  const realSellingPriceWithoutVat = saleVehicle?.financialIndicators?.realSellingPriceWithoutVat;
  const vehicleGrossMargin = saleVehicle?.financialIndicators?.vehicleGrossMargin;
  const vehicleGrossMarginPercentage = saleVehicle?.financialIndicators?.vehicleGrossMarginPercent;
  const totalGrossMargin = saleVehicle?.financialIndicators?.totalGrossMargin;
  const totalGrossMarginPercentage = saleVehicle?.financialIndicators?.totalGrossMarginPercent;
  const profit = saleVehicle?.financialIndicators?.profit;
  const profitPercentage = saleVehicle?.financialIndicators?.profitPercent;

  const handleEditPriceClick = () => {
    openDialog(
      <EditVehiclePriceForm
        businessCaseId={saleVehicle?.saleBusinessCaseId ?? null}
        saleVehicle={saleVehicle}
        onClose={closeCurrentDialog}
        onSubmit={props.onEdit}
        data-testid={props['data-testid']}
      />,
      {
        title: i18n.t('entity.vehicle.actions.editPrice'),
        'data-testid': testIds.vehicles.finances('editPriceDialog'),
      }
    );
  };
  const purchaseForeignCurrency = isNotNilOrEmpty(currencies)
    ? formatCurrencySymbol(
        currencies as Currency[],
        saleVehicle?.purchasePriceIsForeignCurrency?.foreignCurrencyCode as CurrencyCodeType
      )
    : undefined;

  const sellingForeignCurrency = isNotNilOrEmpty(currencies)
    ? formatCurrencySymbol(
        currencies as Currency[],
        saleVehicle?.sellingPriceIsForeignCurrency?.foreignCurrencyCode as CurrencyCodeType
      )
    : undefined;

  const purchaseForeignPrice = isNotNilOrEmpty(
    saleVehicle?.purchasePriceIsForeignCurrency?.priceWithVat
  )
    ? `${saleVehicle?.purchasePriceIsForeignCurrency?.priceWithVat} ${purchaseForeignCurrency}`
    : undefined;
  const sellingForeignPrice = isNotNilOrEmpty(
    saleVehicle?.sellingPriceIsForeignCurrency?.priceWithVat
  )
    ? `${saleVehicle?.sellingPriceIsForeignCurrency?.priceWithVat} ${sellingForeignCurrency}`
    : undefined;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Show when={canViewAnyOfSalePriceFields || (canViewAnyOfPurchasePriceFields && !isBrokerage)}>
        <Card
          title={i18n.t('entity.vehicle.labels.overview.header')}
          actions={buildArray<Action>().when(
            isSaleActionEnabled('SALE_VEHICLE_UPDATE') && canEditPrice,
            {
              type: 'button',
              variant: 'link',
              leftIcon: 'image/edit',
              onClick: handleEditPriceClick,
              'data-testid': testIds.vehicles.finances('overview-purchasingPrice'),
              title: i18n.t('entity.vehicle.actions.editPrice'),
            }
          )}
        >
          <VStack>
            <Grid columns={[1, 2, 4]}>
              <Show when={pricePermissions.canReadVehiclePurchasePrice && !isBrokerage}>
                <PriceColumn
                  title={i18n.t('entity.vehicle.labels.purchasingPrice')}
                  currency={currency}
                  priceWithVat={purchasePrice?.withVat?.amount}
                  priceWithoutVat={purchasePrice?.withoutVat?.amount}
                  isNonDeductible={!Boolean(saleVehicle?.isVatDeductible)}
                  data-testid={testIds.vehicles.finances('overview-purchasingPrice')}
                  foreignPrice={purchaseForeignPrice}
                />
              </Show>
              <Show when={pricePermissions.canReadVehicleSellingPrice}>
                <PriceColumn
                  title={i18n.t('entity.vehicle.labels.expectedSellingPrice')}
                  currency={currency}
                  priceWithVat={sellingPrice?.withVat?.amount}
                  priceWithoutVat={sellingPrice?.withoutVat?.amount}
                  isNonDeductible={!Boolean(saleVehicle?.isVatDeductible)}
                  data-testid={testIds.vehicles.finances('overview-expectedSellingPrice')}
                  foreignPrice={sellingForeignPrice}
                />
              </Show>
              <Show
                when={pricePermissions.canReadBusinessCaseSaleVehicleSalePrice}
                whenFeatureEnabled={featureFlags.SALES_VEHICLE_ORIGINAL_PRICE}
              >
                <PriceColumn
                  currency={
                    (saleVehicle?.originalCarPrice?.currency as CurrencyCodeType | undefined) ??
                    currency
                  }
                  title={i18n.t('general.labels.vehicleOriginalPrice')}
                  priceWithVat={saleVehicle?.originalCarPrice?.amount}
                  isNonDeductible
                  data-testid={testIds.vehicles.finances('vehicleOriginalPrice')}
                />
              </Show>
              <Show when={pricePermissions.canReadBusinessCaseSaleVehicleSalePrice}>
                <PriceColumn
                  currency={
                    (saleVehicle?.b2bSellingPrice?.withVat?.currency as
                      | CurrencyCodeType
                      | undefined) ?? currency
                  }
                  title={i18n.t('entity.vehicle.labels.b2bPrice')}
                  priceWithVat={saleVehicle?.b2bSellingPrice?.withVat?.amount}
                  priceWithoutVat={saleVehicle?.b2bSellingPrice?.withoutVat?.amount}
                  isNonDeductible={!Boolean(saleVehicle?.isVatDeductible)}
                  data-testid={testIds.vehicles.finances('vehicleB2BPrice')}
                />
              </Show>
            </Grid>
            <Hide
              when={
                pricePermissions.canReadVehiclePurchasePrice &&
                !pricePermissions.canReadVehicleSellingPrice
              }
            >
              <Separator spacing={4} />
            </Hide>
            <Grid columns={[1, 2, 4]}>
              <Show when={pricePermissions.canReadVehicleSellingPrice}>
                <PriceColumn
                  title={i18n.t('entity.vehicle.labels.realSellingPrice')}
                  currency={currency}
                  difference={subtract(
                    Number(realSellingPrice?.amount) ?? 0,
                    Number(sellingPrice?.withVat?.amount) ?? 0
                  ).toString()}
                  priceWithVat={realSellingPrice?.amount}
                  priceWithoutVat={realSellingPriceWithoutVat?.amount}
                  isNonDeductible={!Boolean(saleVehicle?.isVatDeductible)}
                  data-testid={testIds.vehicles.finances('overview-realSellingPrice')}
                />
              </Show>
              <Show
                when={
                  pricePermissions.canReadVehicleSellingPrice &&
                  pricePermissions.canReadVehiclePurchasePrice
                }
              >
                <PriceColumn
                  colored
                  currency={currency}
                  title={i18n.t('entity.vehicle.labels.profit')}
                  tooltipText={i18n.t('entity.vehicle.labels.profitTooltip')}
                  priceWithVat={profit?.amount}
                  percentage={Number(profitPercentage) || undefined}
                  isNonDeductible
                  data-testid={testIds.vehicles.finances('overview-profit')}
                />
              </Show>
              <Show when={pricePermissions.canReadVehicleProfit}>
                <PriceColumn
                  currency={
                    (vehicleGrossMargin?.currency as CurrencyCodeType | undefined) ?? currency
                  }
                  colored
                  title={i18n.t('entity.vehicle.labels.vehicleGrossMargin')}
                  priceWithVat={vehicleGrossMargin?.amount}
                  percentage={Number(vehicleGrossMarginPercentage) || undefined}
                  isNonDeductible
                  data-testid={testIds.vehicles.finances('vehicleGrossMargin')}
                  tooltipText={i18n.t('entity.businessCase.labels.vehicleGrossMarginTooltip')}
                />
              </Show>

              <Show
                when={
                  pricePermissions.canReadVehicleSellingPrice &&
                  pricePermissions.canReadVehiclePurchasePrice &&
                  canReadCosts &&
                  canReadEarnings
                }
              >
                <PriceColumn
                  currency={
                    (totalGrossMargin?.currency as CurrencyCodeType | undefined) ?? currency
                  }
                  colored
                  title={i18n.t('entity.vehicle.labels.totalGrossMargin')}
                  priceWithVat={totalGrossMargin?.amount}
                  percentage={Number(totalGrossMarginPercentage) || undefined}
                  isNonDeductible
                  data-testid={testIds.vehicles.finances('totalGrossMargin')}
                  tooltipText={i18n.t('entity.businessCase.labels.totalGrossMarginTooltip')}
                />
              </Show>
            </Grid>
          </VStack>
        </Card>
      </Show>
    </DataStatus>
  );
}
