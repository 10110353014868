import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

const PriceSchema = z.object({
  amount: z.number(),
  currency: z.string(),
});

const UnitPriceSchema = z.object({
  withoutVat: PriceSchema,
  withVat: PriceSchema,
});

const DiscountSchema = z.object({
  rate: z.number().nullable().optional(),
  withoutVat: PriceSchema,
  withVat: PriceSchema,
});

const MaterialBasketItemSchema = z.object({
  id: z.string(),
  itemEditingAllowed: z.boolean(),
  warehouseId: z.string().nullable().optional(),
  warehouseArticleId: z.string().nullable().optional(),
  externalId: z.string().nullable().optional(),
  priceType: z.string(),
  name: z.string(),
  number: z.string(),
  quantity: z.number(),
  dispensingUnit: z.number().nullable().optional(),
  unit: z.string().nullable().optional(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: UnitPriceSchema,
  discount: DiscountSchema,
  totalPrice: UnitPriceSchema,
  purchasePrice: UnitPriceSchema,
  warehouseAction: z.string().nullable().optional(),
  neededAt: z.string().nullable().optional(),
  requestType: z.string(),
  tooltip: z.array(
    z.object({
      label: z.string(),
      value: z.string(),
    })
  ),
});

const MaterialBasketTotalPriceSchema = z.object({
  withoutVat: PriceSchema,
  withVat: PriceSchema,
});

export const GetMaterialBasketItemsApiResponseSchema = z
  .object({
    materialBasketItem: z.array(MaterialBasketItemSchema).nullable(),
    materialBasketTotalPrice: MaterialBasketTotalPriceSchema,
    materialBasketTotalPurchasePrice: MaterialBasketTotalPriceSchema,
  })
  .nullable();

export type GetMaterialBasketItemsApiResponse = z.infer<
  typeof GetMaterialBasketItemsApiResponseSchema
>;

export const GetMaterialBasketItemsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
});

export type GetMaterialBasketItemsApiArg = z.infer<typeof GetMaterialBasketItemsApiArgSchema>;

export type DeleteAllMaterialBasketItemsApiResponse = z.infer<typeof BaseVoidResponseSchema>;
export type PostMaterialBasketItemsToOrderApiResponse = z.infer<typeof BaseVoidResponseSchema>;

export const DeleteAllMaterialBasketItemsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
});
export type DeleteAllMaterialBasketItemsApiArg = z.infer<
  typeof DeleteAllMaterialBasketItemsApiArgSchema
>;

export const PostMaterialBasketItemsToOrderApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      assignMechanics: z.array(
        z
          .object({
            id: z.string(),
            isDefault: z.boolean(),
            costCenterId: z.string().nullable().optional(),
          })
          .nullable()
      ),
    })
    .optional(),
});
export type PostMaterialBasketItemsToOrderApiArg = z.infer<
  typeof PostMaterialBasketItemsToOrderApiArgSchema
>;

export type DeleteMaterialBasketItemsApiResponse = z.infer<typeof BaseVoidResponseSchema>;

export const DeleteMaterialBasketItemsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      serviceItemId: z.array(z.string()),
    })
    .nullable(),
});

export type DeleteMaterialBasketItemsApiArg = z.infer<typeof DeleteMaterialBasketItemsApiArgSchema>;

export const PatchMaterialBasketItemQuantityApiResponseSchema = BaseVoidResponseSchema;
export type PatchMaterialBasketItemQuantityApiResponse = z.infer<
  typeof PatchMaterialBasketItemQuantityApiResponseSchema
>;

export const PatchMaterialBasketItemQuantityApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  serviceItemId: z.string(),
  body: z
    .object({
      quantity: z.number(),
    })
    .nullable(),
});

export type PatchMaterialBasketItemQuantityApiArg = z.infer<
  typeof PatchMaterialBasketItemQuantityApiArgSchema
>;

export const PostMaterialBasketItemForPriceListFeApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      warehouseArticleId: z.string(),
    })
    .nullable(),
});
export type PostMaterialBasketItemForPriceListFeApiArg = z.infer<
  typeof PostMaterialBasketItemForPriceListFeApiArgSchema
>;

export const PostMaterialBasketItemApiResponseSchema = z
  .object({
    materialBasketItem: z
      .object({
        id: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
  })
  .nullable();

export type PostMaterialBasketItemForPriceListFeApiResponse = z.infer<
  typeof PostMaterialBasketItemApiResponseSchema
>;

export type PostMaterialBasketItemForWarehouseApiResponse = z.infer<
  typeof PostMaterialBasketItemApiResponseSchema
>;

export const warehouseActionSchema = z.enum(['non-binding', 'request', 'dispatch-request']);

export type WarehouseAction = z.infer<typeof warehouseActionSchema>;

export const PostMaterialBasketItemForWarehouseApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  warehouseAction: warehouseActionSchema,
  body: z
    .object({
      warehouseArticleId: z.string(),
    })
    .nullable(),
});
export type PostMaterialBasketItemForWarehouseApiArg = z.infer<
  typeof PostMaterialBasketItemForWarehouseApiArgSchema
>;
