import {isFeatureEnabled} from 'feature-flags';

import {useGetVehiclePhotosQuery, useListSaleVehiclePhotosByVehicleQuery} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';

import {useVehiclePhotosPermissions} from './useVehiclePhotosPermissions';

export function useVehicleCoverPhoto(vehicleId: string) {
  const {canReadVehicleAlbumPhotos} = useVehiclePhotosPermissions({
    vehicleId,
  });

  const {data: vehiclePhotos} = useGetVehiclePhotosQuery(
    {vehicleId},
    {
      skip: isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS) || !canReadVehicleAlbumPhotos,
    }
  );

  const {data: saleVehiclePhotos} = useListSaleVehiclePhotosByVehicleQuery(
    {
      vehicleId,
    },
    {
      skip: !isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS) || !canReadVehicleAlbumPhotos,
    }
  );

  const photos = saleVehiclePhotos?.photos ?? vehiclePhotos ?? [];

  const coverPhoto = photos?.find((photo) => photo.isCoverPhoto);

  return {
    photos,
    coverPhoto,
  };
}
