import {Spinner} from 'platform/foundation';
import styled from 'styled-components';

import {FC, ReactElement} from 'react';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {Button} from '@omnetic-dms/teas';

interface TFooterProps {
  readonly onClose?: () => void;
  readonly loading?: boolean;
  readonly onOkEvent?: () => void;
  readonly isDisabled?: boolean;
}

export const FooterComponent: FC<TFooterProps> = ({loading, isDisabled = false}): ReactElement => (
  <ModalFooter>
    {loading ? (
      <Button>
        <Spinner size="small" />
      </Button>
    ) : (
      <Button
        data-testid={testIds.myProfile.security('set-password')}
        disabled={isDisabled}
        type="submit"
        primary
      >
        {i18n.t('page.myProfile.labels.change')}
      </Button>
    )}
  </ModalFooter>
);

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -1px -12px;
  border-top: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  padding: 16px 10px 0;

  button {
    margin-left: 10px;
  }
`;
