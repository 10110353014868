import {
  GetTenantSettingValuesApiArg,
  GetTenantSettingValuesApiResponse,
  SetTenantSettingValueApiArg,
  SetTenantSettingValueApiResponse,
} from '../types/generalSettings';
import {omneticApi} from './baseApi/omneticApi';

export const generalSettingsApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getTenantSettingValues: build.query<
      GetTenantSettingValuesApiResponse,
      GetTenantSettingValuesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/${queryArg.settingId}/tenant`,
      }),
      providesTags: ['GeneralSettingsTenant'],
    }),
    setTenantSettingValue: build.mutation<
      SetTenantSettingValueApiResponse,
      SetTenantSettingValueApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/${queryArg.settingId}/tenant`,
        method: 'POST',
        body: queryArg.valueRequestBody,
      }),
      invalidatesTags: ['GeneralSettingsTenant'],
    }),
  }),
});

export const {useGetTenantSettingValuesQuery, useSetTenantSettingValueMutation} =
  generalSettingsApi;
