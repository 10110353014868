import {Button, RestrictedStatus} from 'platform/components';
import {Center, Hide, Space, VStack} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';

interface RestrictedAreaProps {
  shouldHideRedirectButton?: boolean;
}

export function RestrictedArea(props: RestrictedAreaProps) {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate('/');
  };

  return (
    <Center width="100%" height="100%">
      <VStack justify="center">
        <RestrictedStatus
          headline={i18n.t('page.restrictedArea.headline')}
          subheadline={i18n.t('page.restrictedArea.subheadline')}
        />
        <Hide when={props.shouldHideRedirectButton}>
          <Space vertical={4} />
          <Button onClick={redirectToHome} title={i18n.t('page.restrictedArea.redirectButton')} />
        </Hide>
      </VStack>
    </Center>
  );
}
