import {ICellRenderer} from '@ag-grid-community/core';
import {Tooltip} from 'platform/components';

import {useState, forwardRef, Ref, useImperativeHandle} from 'react';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {ICellRendererParams} from '../../types/AgGridTypes';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getCellClassName} from '../../utils/getCellClassName';
import {getValueToRender} from '../../utils/getValueToRender';

export interface PlainCellRendererProps extends ICellRendererParams {
  getTooltipProps?: TooltipGetter;
}

function PlainCellRendererComponent(props: PlainCellRendererProps, ref: Ref<ICellRenderer>) {
  const {getTooltipProps, data, node, colDef} = props;
  const className = getCellClassName(colDef);
  const [cellValue, setCellValue] = useState<string>(() => getValueToRender(props));

  useImperativeHandle(ref, () => ({
    refresh: (refreshProps) => {
      const newCellValue = getValueToRender(refreshProps);
      if (newCellValue !== cellValue) {
        setCellValue(newCellValue);
      }
      return true;
    },
  }));

  const tooltipProps = useTooltipProps(data, node, getTooltipProps);

  return (
    <Tooltip {...tooltipProps}>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className={className}>{cellValue}</div>
    </Tooltip>
  );
}

export const PlainCellRenderer = forwardRef<ICellRenderer, PlainCellRendererProps>(
  PlainCellRendererComponent
);
