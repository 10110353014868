import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Box, Heading, Text, VStack} from 'platform/foundation';
import {object} from 'yup';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId, yupString} from 'shared';

const CLEAR = 'CLEAR';
const DONT_CLEAR = 'DONT_CLEAR';

type FormType = {clear: string};

interface DeactivateContractDialogProps extends RequiredTestIdProps {
  onClose: () => void;
  onDeactivate: (isClear: boolean) => Promise<unknown>;
}

export function DeactivateContractDialog(props: DeactivateContractDialogProps) {
  const handleSubmit: FormSubmitHandler<FormType> = async (data) => {
    await props.onDeactivate(data.clear === CLEAR).then(props.onClose);
  };

  return (
    <Form<FormType> defaultValues={{clear: CLEAR}} schema={schema} onSubmit={handleSubmit}>
      {(control) => (
        <VStack spacing={4}>
          <Heading size={4}>{i18n.t('entity.order.labels.removeContractConfirm')}</Heading>
          <Box paddingLeft={4}>
            <Text color="secondary" size="small">
              <ul>
                <li>{i18n.t('entity.order.labels.deactivateContractSelectingClear')}</li>
                <li>{i18n.t('entity.order.labels.deactivateContractSelectingDoNotClear')}</li>
              </ul>
            </Text>
          </Box>
          <FormField
            control={control}
            name="clear"
            type="radio"
            options={[
              {value: CLEAR, label: i18n.t('general.labels.clear')},
              {value: DONT_CLEAR, label: i18n.t('general.labels.doNotClear')},
            ]}
            data-testid={suffixTestId('clear', props)}
          />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discard')}
              variant="secondary"
              onClick={props.onClose}
              data-testid={suffixTestId('discard', props)}
            />
            <FormButton
              control={control}
              variant="danger"
              type="submit"
              title={i18n.t('general.actions.deactivate')}
              data-testid={suffixTestId('deactivate', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = object({clear: yupString.required()});
