import {AnyAction, AsyncThunk, ThunkDispatch} from '@reduxjs/toolkit';

import {useDispatch} from 'react-redux';

import type {TeasState} from '../types/TeasState';
import type {CallApiFunc} from '../utils/api';

export interface ThunkExtra {
  callApi: CallApiFunc;
}

export type ThunkApiConfig = {
  state: TeasState;
  extra: ThunkExtra;
};

export type Thunk<Returned = void, ThunkArg = void> = AsyncThunk<
  Returned,
  ThunkArg,
  ThunkApiConfig
>;

export type AppDispatch = ThunkDispatch<TeasState, ThunkExtra, AnyAction>;

/**
 * @deprecated You should use RTK query
 */
export const useThunkDispatch = (): AppDispatch => useDispatch<AppDispatch>();
