import {NumberFormatter, useFormatNumber} from 'platform/locale';

import {useTranslation} from 'react-i18next';

import {isNil} from 'ramda';

import {FiltersType} from '../../../types/FiltersType';
import {useFilters} from '../../FiltersContext/FiltersContext';

export type NumberBasedFilterName =
  | 'mileageRange'
  | 'power'
  | 'cubicCapacity'
  | 'margin'
  | 'priceCZK'
  | 'liquidity'
  | 'daysOnDisplay'
  | 'year'
  | 'numberOfSeats'
  | 'avgNumberAds'
  | 'featureScore';

export const getNumberBasedTags = (
  filterName: NumberBasedFilterName,
  translation: string,
  fromTranslation: string,
  toTranslation: string,
  filters: FiltersType,
  onUpdateFilters: (path: string[], value: any) => void,
  formatNumber: NumberFormatter,
  max?: number,
  unit: string = ''
) => {
  const filterValue = filters[filterName];
  const formatter = filterName === 'year' ? (value: number) => value : formatNumber;

  if (isNil(filterValue)) {
    return [];
  }

  const from = ` ${fromTranslation} ${formatter(filterValue[0])}${unit}`;
  const to = filterValue[1] === max ? '' : ` ${toTranslation} ${formatter(filterValue[1])}${unit}`;

  return [
    {
      label: `${translation}${from}${to}`,
      onDelete: () => onUpdateFilters([filterName], undefined),
    },
  ];
};

export const useGetNumberBasedTags = () => {
  const {filters, onUpdateFilters} = useFilters();
  const formatNumber = useFormatNumber();
  const {t} = useTranslation();

  return (filterName: NumberBasedFilterName, translation: string, max?: number, unit?: string) =>
    getNumberBasedTags(
      filterName,
      translation,
      t('general.labels.from'),
      t('general.labels.to'),
      filters,
      onUpdateFilters,
      formatNumber,
      max,
      unit
    );
};
