import {Upload, UploadType} from 'platform/components';
import {RcFile} from 'rc-upload/es/interface';
import {v4 as uuidV4} from 'uuid';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useConditionContext} from '../hooks/useConditionContext';
import {useConditionUploadContext} from '../hooks/useConditionUploadContext';

interface UploadControlProps extends TestIdProps {
  isMandatory?: boolean;
  hasError?: boolean;
  isMultiple?: boolean;
  type: UploadType;
}

export function UploadControl(props: UploadControlProps) {
  const {pushToUploadImages} = useConditionUploadContext();
  const {isDisabledForUser} = useConditionContext();

  const onUploadStart = (file: RcFile) => {
    pushToUploadImages({
      file,
      imageId: uuidV4(),
      title: file.name,
      src: URL.createObjectURL(file),
    });
  };

  return (
    <Upload
      data-testid={suffixTestId('uploadControl', props)}
      customRequest={({onSuccess}) => {
        onSuccess?.('', new XMLHttpRequest());
      }}
      onStart={onUploadStart}
      type={props.type}
      size="default"
      accept="image/png, image/jpg, image/jpeg"
      uploadIcon="image/photo_camera"
      uploadText={`${props.isMandatory ? '*' : ''} ${i18n.t('general.actions.addPhotos')}`}
      errorIcon="navigation/cancel"
      isMultiple={props.isMultiple}
      buttonVariant={props.hasError ? 'errorLink' : 'ghostLink'}
      isDisabled={isDisabledForUser}
    />
  );
}
