import {useCallback} from 'react';

import {map, mergeAll, pipe} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {BusinessCaseAction, useGetBusinessCaseActionsQuery} from '@omnetic-dms/api';

export function useBusinessCaseActions(businessCaseId: string) {
  const {data} = useGetBusinessCaseActionsQuery(
    {businessCaseId},
    {
      skip: isNilOrEmpty(businessCaseId),
      refetchOnFocus: true,
    }
  );

  const actions = pipe<
    [BusinessCaseAction[]],
    Partial<Record<BusinessCaseAction, true>>[],
    Partial<Record<BusinessCaseAction, true>>
  >(
    map((key) => ({[key]: true})),
    mergeAll
  )(data?.actions ?? []);

  const isActionEnabled = useCallback(
    (actionKey: BusinessCaseAction) => actions[actionKey] ?? false,
    [actions]
  );

  return {isActionEnabled, actions};
}
