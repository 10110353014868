import {DataStatus, Search, Separator} from 'platform/components';
import {Box, Heading, Hide, Space, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {FieldValues, Path, useController, UseFormReturn} from 'react-hook-form';

import {isNil} from 'ramda';

import {VehicleTypeEnumObject} from '@omnetic-dms/api';

import {Nullish, RequiredTestIdProps, sortByAsciiNumberLast, suffixTestId} from 'shared';

import {useVehicleFeaturesV2} from '../../hooks/useVehicleFeaturesV2/useVehicleFeaturesV2';
import {FeatureTree} from './components/FeatureTree';

interface FeatureSelectorProps<TFieldValues extends FieldValues = FieldValues>
  extends RequiredTestIdProps {
  formApi: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  vehicleType?: VehicleTypeEnumObject;
  columns?: number;
}

export function FeatureSelector<TFieldValues extends FieldValues>(
  props: FeatureSelectorProps<TFieldValues>
) {
  const {categorizedFeaturesTree, search, setSearch, isLoading, isError} = useVehicleFeaturesV2(
    props.vehicleType
  );

  const {field} = useController({control: props.formApi.control, name: props.name});
  const formFeatures: string[] = field.value || [];

  const handleTreeFeatureChange = (oldFeature: string | Nullish, newFeature: string) => {
    if (isNil(oldFeature)) {
      field.onChange([...formFeatures, newFeature]);
      return;
    }

    if (oldFeature === newFeature) {
      field.onChange(formFeatures.filter((feature) => feature !== oldFeature));
      return;
    }

    field.onChange([...formFeatures.filter((feature) => feature !== oldFeature), newFeature]);
  };

  const saneSections = categorizedFeaturesTree.filter((item) => item.features.length);
  const alphabeticallyOrderedSections = saneSections.map((section) => ({
    ...section,
    features: section.features.sort((a, b) =>
      sortByAsciiNumberLast(a.labels[0].label, b.labels[0].label)
    ),
  }));
  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack>
        <Search
          value={search || null}
          onChange={(newValue) => setSearch(newValue || undefined)}
          data-testid={suffixTestId('search', props)}
        />
        <Space vertical={4} />
        {alphabeticallyOrderedSections.map((section, index) => (
          <Box key={section.key}>
            <Heading alternative size={4}>
              {section.category?.labels[0].label}
            </Heading>
            <Space vertical={4} />
            <ColumnsDiv columns={props.columns}>
              {section.features.map((topLevelFeature, index) => {
                const treeSelectedFeature = formFeatures.find((selected) => {
                  const treeOwner = section.treeOwnerMap.get(selected);
                  return treeOwner === topLevelFeature.const_key;
                });

                return (
                  <FeatureTree
                    key={topLevelFeature.const_key}
                    feature={topLevelFeature}
                    selectedFeature={treeSelectedFeature}
                    onChange={handleTreeFeatureChange}
                    data-testid={suffixTestId(`tree-${index}`, props)}
                  />
                );
              })}
            </ColumnsDiv>
            <Hide when={alphabeticallyOrderedSections.length - 1 === index}>
              <Separator />
            </Hide>
          </Box>
        ))}
      </VStack>
    </DataStatus>
  );
}

const ColumnsDiv = styled.div<{columns?: number}>`
  columns: ${({columns}) => columns || 3};
`;
