import {
  DeleteEmployeeCompetenceApiArg,
  DeleteEmployeeCompetenceApiResponse,
  GetEmployeeCompetenceApiArg,
  GetEmployeeCompetenceApiResponse,
  GetEmployeeWorkCompetencesListApiResponse,
  PatchEmployeeCompetenceApiArg,
  PatchEmployeeCompetenceApiResponse,
  PostEmployeeCompetenceApiArg,
  PostEmployeeCompetenceApiResponse,
  PostEmployeeCompetenceSetAsActiveApiArg,
  PostEmployeeCompetenceSetAsActiveApiResponse,
  PostEmployeeCompetenceSetAsInactiveApiArg,
  PostEmployeeCompetenceSetAsInactiveApiResponse,
} from '../types/employeeCompetence';
import {metadaApi} from './baseApi/metadaApi';

export const metadaEmployeeCompetenceApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postEmployeeCompetence: build.mutation<
      PostEmployeeCompetenceApiResponse,
      PostEmployeeCompetenceApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['employeeCompetences'],
    }),
    getEmployeeCompetence: build.query<
      GetEmployeeCompetenceApiResponse,
      GetEmployeeCompetenceApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.id}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'employeeCompetence', projectId: queryArg.id},
      ],
    }),
    deleteEmployeeCompetence: build.mutation<
      DeleteEmployeeCompetenceApiResponse,
      DeleteEmployeeCompetenceApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCompetences',
        {type: 'employeeCompetence', projectId: queryArg.id},
      ],
    }),
    patchEmployeeCompetence: build.mutation<
      PatchEmployeeCompetenceApiResponse,
      PatchEmployeeCompetenceApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCompetences',
        {type: 'employeeCompetence', projectId: queryArg.id},
      ],
    }),
    postEmployeeCompetenceSetAsActive: build.mutation<
      PostEmployeeCompetenceSetAsActiveApiResponse,
      PostEmployeeCompetenceSetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.competenceId}/set-as-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCompetences',
        {type: 'employeeCompetence', projectId: queryArg.competenceId},
      ],
    }),
    postEmployeeCompetenceSetAsInactive: build.mutation<
      PostEmployeeCompetenceSetAsInactiveApiResponse,
      PostEmployeeCompetenceSetAsInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.competenceId}/set-as-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCompetences',
        {type: 'employeeCompetence', projectId: queryArg.competenceId},
      ],
    }),
    getEmployeeWorkCompetencesList: build.query<GetEmployeeWorkCompetencesListApiResponse, void>({
      query: () => ({
        url: `/core/v1/employee-competences`,
      }),
      providesTags: ['employeeCompetences'],
    }),
  }),
});

export const {
  usePostEmployeeCompetenceMutation,
  useGetEmployeeCompetenceQuery,
  useDeleteEmployeeCompetenceMutation,
  usePatchEmployeeCompetenceMutation,
  usePostEmployeeCompetenceSetAsActiveMutation,
  usePostEmployeeCompetenceSetAsInactiveMutation,
  useGetEmployeeWorkCompetencesListQuery,
} = metadaEmployeeCompetenceApi;
