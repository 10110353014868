import {FormControl, FormField} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';

import {UseFormReturn, useWatch} from 'react-hook-form';

import {isNilOrEmpty, toNumber} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  VehicleStyleSelect,
  MakeSelect,
  ModelSelect,
  useVehicleCatalogue,
  BodyColorSelect,
  FuelTypeSelect,
  TransmissionSelect,
  DriveSelect,
  yearOptions,
} from '@omnetic-dms/shared';

import {handleFormReset, RequiredTestIdProps, suffixTestId, useToggle} from 'shared';

import {FeaturesPicker} from '../../../components/FeaturesPicker';
import {RequestSpecification} from '../../../components/RequestSpecification';
import {ExternalFilter} from '../types/ExternalFilter';
import {transformExternalFilterToMinipurchaseSpecification} from '../utils/transformExternalFilterToMinipurchaseSpecification';

interface ExternalFilterFormProps extends RequiredTestIdProps {
  control: FormControl<ExternalFilter>;
  formApi: UseFormReturn<ExternalFilter>;
  onChange: (values: ExternalFilter) => void;
}

export function ExternalFilterForm(props: ExternalFilterFormProps) {
  const [vehicleUtils] = useVehicleCatalogue(null);
  const [isFeaturesOpen, toggleFeaturesOpen] = useToggle();

  const [type, make] = useWatch({control: props.control, name: ['type', 'make']});
  const formValues = useWatch({control: props.control});

  return (
    <Grid columns={8} verticalSpacing={4} align="flex-end">
      <GridItem span={8}>
        <FormField
          data-testid={suffixTestId('externalFilter', props)}
          control={props.control}
          isRequired
          type="chips"
          name="type"
          options={vehicleUtils.vehicleTypeOptions ?? []}
          label={i18n.t('entity.vehicle.labels.vehicleType')}
        />
      </GridItem>
      <GridItem span={2}>
        <MakeSelect
          data-testid={suffixTestId('externalFilter', props)}
          vehicleType={type?.[0]}
          control={props.control}
          isRequired
          isMultiple
          name="make"
          label={i18n.t('entity.vehicle.labels.make')}
          onChange={(value) => {
            if (isNilOrEmpty(value)) {
              handleFormReset<ExternalFilter>(props.formApi, [
                {name: 'make', value: null},
                {name: 'modelFamily', value: null},
              ]);
            }
          }}
        />
      </GridItem>
      <GridItem span={2}>
        <ModelSelect
          data-testid={suffixTestId('externalFilter', props)}
          vehicleType={type?.[0]}
          makes={make}
          control={props.control}
          isRequired
          isMultiple
          name="modelFamily"
          label={i18n.t('entity.vehicle.labels.model')}
        />
      </GridItem>
      <GridItem span={2}>
        <VehicleStyleSelect
          data-testid={suffixTestId('externalFilter', props)}
          vehicleType={type?.[0]}
          control={props.control}
          isMultiple
          name="bodyStyle"
          label={i18n.t('entity.vehicle.labels.bodyType')}
        />
      </GridItem>
      <GridItem span={2}>
        <BodyColorSelect
          data-testid={suffixTestId('externalFilter', props)}
          vehicleType={type?.[0]}
          control={props.control}
          isMultiple
          name="bodyColor"
          label={i18n.t('entity.vehicle.labels.bodyColor')}
        />
      </GridItem>
      <GridItem span={2}>
        <FuelTypeSelect
          data-testid={suffixTestId('externalFilter', props)}
          vehicleType={type?.[0]}
          control={props.control}
          isMultiple
          name="fuelType"
          label={i18n.t('entity.vehicle.labels.fuelType')}
        />
      </GridItem>
      <GridItem span={2}>
        <TransmissionSelect
          data-testid={suffixTestId('externalFilter', props)}
          vehicleType={type?.[0]}
          control={props.control}
          isMultiple
          name="transmission"
          label={i18n.t('entity.vehicle.labels.transmission')}
        />
      </GridItem>
      <GridItem span={2}>
        <DriveSelect
          data-testid={suffixTestId('externalFilter', props)}
          vehicleType={type?.[0]}
          control={props.control}
          isMultiple
          name="drive"
          label={i18n.t('entity.vehicle.labels.drive')}
        />
      </GridItem>
      <GridItem span={2}>
        <FormField
          data-testid={suffixTestId('externalFilter-vatDeductible', props)}
          control={props.control}
          type="chips"
          name="vatDeductible"
          label={i18n.t('general.labels.vatDeductible')}
          options={[
            {label: i18n.t('general.labels.yes'), value: 'true'},
            {label: i18n.t('general.labels.no'), value: 'false'},
          ]}
          isDeselectable
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          data-testid={suffixTestId('externalFilter-priceFrom', props)}
          control={props.control}
          name="priceFrom"
          label={i18n.t('general.labels.price')}
          placeholder={i18n.t('entity.documentSeries.item.from')}
          type="numberInputWithOptions"
          isClearable
          options={priceOptions}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          data-testid={suffixTestId('externalFilter-priceTo', props)}
          control={props.control}
          name="priceTo"
          placeholder={i18n.t('entity.documentSeries.item.to')}
          type="numberInputWithOptions"
          isClearable
          options={priceOptions}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          data-testid={suffixTestId('externalFilter-yearFrom', props)}
          control={props.control}
          name="yearFrom"
          label={i18n.t('entity.vehicle.labels.year')}
          placeholder={i18n.t('entity.documentSeries.item.from')}
          type="numberInputWithOptions"
          isClearable
          isFormatNumbersDisabled
          options={yearsOptions}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          data-testid={suffixTestId('externalFilter-yearTo', props)}
          control={props.control}
          name="yearTo"
          placeholder={i18n.t('entity.documentSeries.item.to')}
          type="numberInputWithOptions"
          isClearable
          isFormatNumbersDisabled
          options={yearsOptions}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          data-testid={suffixTestId('externalFilter-mileageFrom', props)}
          control={props.control}
          name="mileageFrom"
          label={i18n.t('entity.vehicle.labels.mileage')}
          placeholder={i18n.t('entity.documentSeries.item.from')}
          type="numberInputWithOptions"
          isClearable
          options={mileageOptions}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          data-testid={suffixTestId('externalFilter-mileageTo', props)}
          control={props.control}
          name="mileageTo"
          placeholder={i18n.t('entity.documentSeries.item.to')}
          type="numberInputWithOptions"
          isClearable
          options={mileageOptions}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          data-testid={suffixTestId('externalFilter-powerFrom', props)}
          control={props.control}
          name="powerFrom"
          label={i18n.t('entity.vehicle.labels.power')}
          placeholder={i18n.t('entity.documentSeries.item.from')}
          type="numberInputWithOptions"
          isClearable
          isFormatNumbersDisabled
          options={powerOptions}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          data-testid={suffixTestId('externalFilter-powerTo', props)}
          control={props.control}
          name="powerTo"
          placeholder={i18n.t('entity.documentSeries.item.to')}
          type="numberInputWithOptions"
          isClearable
          isFormatNumbersDisabled
          options={powerOptions}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          data-testid={suffixTestId('externalFilter-cubicCapacityFrom', props)}
          control={props.control}
          name="cubicCapacityFrom"
          label={i18n.t('entity.vehicle.labels.cubicCapacity')}
          placeholder={i18n.t('entity.documentSeries.item.from')}
          type="numberInputWithOptions"
          isFormatNumbersDisabled
          isClearable
          options={cubicCapacityOptions}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          data-testid={suffixTestId('externalFilter-cubicCapacityTo', props)}
          control={props.control}
          name="cubicCapacityTo"
          placeholder={i18n.t('entity.documentSeries.item.to')}
          type="numberInputWithOptions"
          isFormatNumbersDisabled
          isClearable
          options={cubicCapacityOptions}
        />
      </GridItem>
      <GridItem span={8}>
        <FormField
          data-testid={suffixTestId('externalFilter-additionalInformation', props)}
          control={props.control}
          name="additionalInformation"
          label={i18n.t('general.labels.additionalInformation')}
          type="textarea"
          minRows={1}
        />
      </GridItem>
      <GridItem span={8}>
        <RequestSpecification
          data-testid={suffixTestId('externalFilter', props)}
          filteredSpecifications={transformExternalFilterToMinipurchaseSpecification(formValues)}
        />
      </GridItem>
      <GridItem span={8}>
        <FeaturesPicker<ExternalFilter>
          data-testid={suffixTestId('externalFilter', props)}
          control={props.control}
          name="features"
          vehicleType={type?.[0]}
          toggleFeaturesOpen={toggleFeaturesOpen}
          isFeaturesOpen={isFeaturesOpen}
        />
      </GridItem>
    </Grid>
  );
}

const yearsOptions = yearOptions.map((option) => toNumber(option.value));

const priceOptions = [
  15000, 30000, 50000, 75000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 500000,
  750000, 1000000, 2000000, 4000000, 5000000,
];

const mileageOptions = [
  0, 5000, 15000, 30000, 50000, 75000, 100000, 125000, 150000, 200000, 300000, 500000, 700000,
  1000000,
];

const powerOptions = [0, 40, 50, 60, 70, 80, 90, 100, 125, 150, 175, 200, 250, 300, 400];

const cubicCapacityOptions = [
  100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800,
  1900, 2000, 2100, 2200, 2300, 2400, 2500,
];
