export type TSetPasswordProps = {
  open?: boolean;
  userId: string;
  onClose: () => void;
  title?: string;
};

export enum ERoleType {
  blank = 'blank',
  existing = 'existing',
}

export type TFooterProps = {
  onClose?: () => void;
  loading?: boolean;
  onOkEvent?: () => void;
};

export type ResetPasswordTuple = {
  password: string;
  passwordNewConfirm: string;
};
