/**
 * @about Converts Severity type to theme color token path
 */
import {Nullish} from 'shared';

import {Severity} from '../types/Severity';
import {ThemeColorPath} from './foundationTheme';

export function getColorFromSeverity(severity: Severity | Nullish): ThemeColorPath {
  return `severity.${severity ?? 'informational'}`;
}
