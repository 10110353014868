import {composePath} from 'shared';

export const buildSharePresetUrl = (
  buildAbsoluteUrl: (url: string) => string,
  code: string,
  sharedPresetId: string,
  currentLocation: string
) =>
  buildAbsoluteUrl(
    composePath('/share-preset/:code/:sharedPresetId', {
      params: {code, sharedPresetId},
      queryParams: {
        q: encodeURIComponent(currentLocation),
      },
    })
  );
