import {isObject} from 'ramda-adjunct';

type PhoneNumber = {countryCode: string; number: string; prefix: string};
export const isPhoneNumber = (value: unknown): value is PhoneNumber => {
  if (!isObject(value)) {
    return false;
  }

  return 'countryCode' in value && 'number' in value && 'prefix' in value;
};
