import {object, SchemaOf, string} from 'yup';

import {BankAccountData} from '../types/BankAccountData';

export const $BankAccountData: SchemaOf<BankAccountData> = object({
  countryCode: string().defined().nullable(),
  bankCode: string().defined().nullable(),
  currency: string().defined().nullable(),
  iban: string().defined().nullable(),
  name: string().defined().nullable(),
  number: string().defined().nullable(),
  ownerName: string().defined().nullable(),
  swiftBic: string().defined().nullable(),
});
