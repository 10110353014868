import {Chips, Separator} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {ForwardedRef, forwardRef} from 'react';

import {intersection} from 'ramda';
import {isArray, isEmptyArray} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useChoiceFilter} from '../../hooks/useChoiceFilter';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {IFilter, IFilterParams} from '../../types/AgGridTypes';
import {QuickFilters} from '../../types/Api';

type Option = {value: string; label: string};

export type ChipsFilterProps = {
  options: Array<Option>;
  isDisabled: boolean;
} & IFilterParams &
  QuickFilters &
  TestIdProps;

function ChipsFilterComponent(
  {filterChangedCallback, options, isDisabled, quickFilters, ...props}: ChipsFilterProps,
  ref: ForwardedRef<IFilter>
) {
  const [values, setValues] = useChoiceFilter(filterChangedCallback, ref);
  const {onChipsChange} = useFilterOnChipsChange({
    filterChangedCallback,
    setFilterValue: setValues,
    defaultValue: undefined,
  });
  const onChange = (newValues: string[] | Nullish) => {
    setValues(newValues);
    filterChangedCallback();
  };

  const isQuickFilterValue = intersection(isArray(values) ? values : [values], quickFiltersConst);

  return (
    <VStack>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('chips-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <Chips
        isDisabled={isDisabled}
        options={options}
        value={isEmptyArray(isQuickFilterValue) ? (isArray(values) ? values : null) : null}
        onChange={onChange}
        isMultiple
        isDeselectable
        data-testid={suffixTestId('chips-filter', props)}
      />
    </VStack>
  );
}

export const ChipsFilter = forwardRef<IFilter, ChipsFilterProps>(ChipsFilterComponent);
