import {Button, IconButton, openDialog} from 'platform/components';
import {Heading, HStack, Show, Space} from 'platform/foundation';

import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes} from '@omnetic-dms/routes';
import {usePermissions, DigitalCertificateLink} from '@omnetic-dms/shared';

import {composePath, suffixTestId, TestIdProps} from 'shared';

import {useRouter} from '../../../hooks/useRouter';
import {selectAudit, selectAuditState, selectStructure} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {useCheckPermissions} from '../hooks/useCheckPermissions';
import {useConditionContext} from '../hooks/useConditionContext';
import {ReopenInspection} from '../ReopenInspection';
import {getTranslation} from '../utils/getTranslation';
import {AutoFillTemplates} from './AutoFillTemplates';
import {ConditionStatus} from './ConditionStatus';
import {DeleteInspection} from './DeleteInspection';
import {ExportPDF} from './ExportPDF';
import {FinishInspection} from './FinishInspection';
import {InRevision} from './InRevision';
import {ToFinishingForm} from './ToFinishingForm';
import {ToRevisionInspection} from './ToRevisionInspection';
import {UpdateCondition} from './UpdateCondition';

export function InspectionHeader(props: TestIdProps) {
  const router = useRouter();
  const {id: vehicleId, inspectionId = ''} = useParams();
  const audit = useSelector(selectAudit);
  const auditState = useSelector(selectAuditState);
  const structure = useSelector(selectStructure);
  const locale = useSelector(selectUserSelectedLanguage);

  const [finishInspection, closeInspection, updateInspection] = usePermissions({
    permissionKeys: ['finishInspection', 'closeInspection', 'updateInspection'],
    scopes: {
      closeInspection: {inspectionType: audit?.inspectionType},
      finishInspection: {inspectionType: audit?.inspectionType},
      updateInspection: {inspectionType: audit?.inspectionType},
    },
  });

  const {isDisabledForUser} = useConditionContext();

  const {
    canSendInspectionToRevision,
    canSendInspectionToFinishing,
    canCloseInspection,
    canSendInspectionToInRevision,
    canReopenInspection,
  } = useCheckPermissions({finishInspection, closeInspection, updateInspection});
  const handleBackButton = () => {
    router.push(
      composePath(vehiclesRoutes.conditionsInspections, {params: {id: vehicleId as string}})
    );
  };

  return (
    <>
      <HStack data-testid={suffixTestId('inspectionHeader', props)} justify="space-between">
        <HStack align="center" spacing={2}>
          <IconButton
            size="small"
            onClick={handleBackButton}
            icon="navigation/arrow_back_ios"
            data-testid={suffixTestId('inspectionHeader', props)}
          />
          <Heading size={3} data-testid={suffixTestId('inspectionHeader-title', props)}>
            {structure ? getTranslation(locale, structure.name) : ''}
          </Heading>
          <ConditionStatus />
        </HStack>
        <HStack align="center" spacing={2}>
          <Show when={audit?.inspectionType && removableInspections.includes(audit.inspectionType)}>
            <DeleteInspection />
          </Show>

          <DigitalCertificateLink inspectionId={inspectionId} />

          <ExportPDF
            inspectionType={audit?.inspectionType}
            data-testid={suffixTestId('inspectionHeader', props)}
          />
          <UpdateCondition data-testid={suffixTestId('inspectionHeader', props)} />

          <AutoFillTemplates
            data-testid={suffixTestId('inspectionHeader', props)}
            shouldUseAuditIdForUpdateTemplate={false}
          />
          <Show when={!isDisabledForUser && auditState && canSendInspectionToRevision(auditState)}>
            <ToRevisionInspection />
          </Show>
          <Show when={auditState && canSendInspectionToFinishing(auditState)}>
            <Button
              data-testid={suffixTestId('inspectionHeader', props)}
              onClick={() =>
                openDialog(<ToFinishingForm />, {
                  title: i18n.t('general.actions.toFinishing'),
                })
              }
              title={i18n.t('general.actions.toFinishing')}
            />
          </Show>
          <Show when={canSendInspectionToInRevision(auditState)}>
            <InRevision data-testid={suffixTestId('inspectionHeader', props)} />
          </Show>
          <Show when={auditState && canCloseInspection(auditState)}>
            <FinishInspection data-testid={suffixTestId('inspectionHeader', props)} />
          </Show>
          <Show when={auditState && canReopenInspection(auditState)}>
            <ReopenInspection
              inspectionId={inspectionId}
              handleBackButton={handleBackButton}
              data-testid={suffixTestId('inspectionHeader', props)}
            />
          </Show>
        </HStack>
      </HStack>
      <Space vertical={5} />
    </>
  );
}

const removableInspections = [
  LoadAuditDataResponseItemBody.inspectionType.VALIDATION_INSPECTION,
  LoadAuditDataResponseItemBody.inspectionType.NON_VALIDATION_INSPECTION,
  LoadAuditDataResponseItemBody.inspectionType.COMPLAINT,
];
