import * as Yup from 'yup';

import i18n from '@omnetic-dms/i18n';

import {PriceValue} from '../../../types/PriceValue';
import {MinMaxAmountLimitType} from '../../../utils/$Money';

export const $PriceValue = (limit?: MinMaxAmountLimitType): Yup.SchemaOf<PriceValue> => {
  const minAmount = Number(limit?.min?.amount ?? 0);
  const maxAmount = Number(limit?.max?.amount ?? 1000000000);

  const MIN_MESSAGE =
    limit?.min?.errorMessage || i18n.t('general.notifications.priceMin', {min: minAmount});
  const MAX_MESSAGE =
    limit?.max?.errorMessage || i18n.t('general.notifications.priceMax', {max: maxAmount});

  return Yup.object({
    withoutVat: Yup.mixed()
      .test('priceMax', MAX_MESSAGE, function (value) {
        return (
          !value?.amount ||
          (this.parent.withVat.amount || this.parent.withoutVat.amount) <= maxAmount
        );
      })
      .test('priceMin', MIN_MESSAGE, function (value) {
        return (
          !value?.amount ||
          (this.parent.withVat.amount || this.parent.withoutVat.amount) >= minAmount
        );
      })
      .test(
        'positive',
        i18n.t('general.notifications.numberPositive'),
        (value) => !value?.amount || value?.amount >= 0
      )
      .test(
        'typeError',
        i18n.t('general.notifications.numberValidationMessage'),
        (value) => !value?.amount || !isNaN(value?.amount)
      )
      .test('fieldIsRequired', i18n.t`general.notifications.fieldIsRequired`, function (value) {
        return (
          (this as any).from[0]?.schema?.tests.findIndex(
            ({OPTIONS}: any) => OPTIONS.name === 'required'
          ) === -1 ||
          !!this.parent.withVat?.amount ||
          !!this.parent.withoutVat?.amount
        );
      }),
    withVat: Yup.mixed()
      .test('priceMax', MAX_MESSAGE, function (value) {
        return (
          !value?.amount ||
          (this.parent.withVat.amount || this.parent.withoutVat.amount) <= maxAmount
        );
      })
      .test('priceMin', MIN_MESSAGE, function (value) {
        return (
          !value?.amount ||
          (this.parent.withVat.amount || this.parent.withoutVat.amount) >= minAmount
        );
      })
      .test(
        'positive',
        i18n.t('general.notifications.numberPositive'),
        (value) => !value?.amount || value?.amount >= 0
      )
      .test(
        'typeError',
        i18n.t('general.notifications.numberValidationMessage'),
        (value) => !value?.amount || !isNaN(value?.amount)
      )
      .test('fieldIsRequired', i18n.t`general.notifications.fieldIsRequired`, function (value) {
        return (
          (this as any).from[0]?.schema?.tests.findIndex(
            ({OPTIONS}: any) => OPTIONS.name === 'required'
          ) === -1 ||
          !!this.parent.withVat?.amount ||
          !!this.parent.withoutVat?.amount
        );
      }),
  }) as Yup.SchemaOf<PriceValue>;
};
