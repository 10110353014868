import {Button, closeDialog, Radio, RadioOption, Switch, Tooltip} from 'platform/components';
import {Box, Heading, HStack, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNil, isNotNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {BaseDirectSale} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId, useBoolean, useToggle} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, RowData} from 'features/datagrid';

const confirmationOptions: RadioOption[] = [
  {
    value: 'true',
    label: i18n.t('general.labels.applyDiscount'),
  },
  {
    value: 'false',
    label: i18n.t('general.labels.doNotApplyDiscount'),
  },
];

export type CustomerContractMode = 'assign' | 'change';

interface DiscountsTabCustomerContractProps extends RequiredTestIdProps {
  onHandleAssignContract: (customerContractId: string, isDiscountApply: boolean) => void;
  assignContractDialogId: string;
  mode: CustomerContractMode;
  onContractSelect: (customerContractId: string) => void;
  directSale?: BaseDirectSale;
}

export function DiscountsTabCustomerContract(props: DiscountsTabCustomerContractProps) {
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [confirmation, setConfirmation] = useState<string>('true');
  const [isAssigningContract, startAssigningContract, stopAssigningContract] = useBoolean();
  const [isShowingAllContracts, toggleIsShowingAllContracts] = useToggle();

  const isRowSelected = isNotNil(selectedRow);

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([
      filter,
      {customerId: isShowingAllContracts ? undefined : props.directSale?.customerId},
    ]);

  const handleConfirmationChange = (value: string | null) => {
    if (isNil(value)) {
      return;
    }

    setConfirmation(value);
  };

  const handleRowsSelect = (rows: RowData[]) => {
    const firstRow = rows[0];
    setSelectedRow(firstRow?.id);
  };

  const handleDiscard = () => {
    closeDialog(props.assignContractDialogId);
  };

  // TODO https://carvago.atlassian.net/browse/T20-59560
  const handleAssignContract = () => {
    const _confirmation = confirmation === 'true' ? true : false;

    try {
      startAssigningContract();
      props.onHandleAssignContract(selectedRow as string, _confirmation);
    } catch {
      stopAssigningContract();
    }
  };

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    if (actionKey !== 'detail') {
      return;
    }

    const id = isArray(rowId) ? rowId[0] : rowId;

    props.onContractSelect(id);
  };

  return (
    <VStack spacing={4}>
      <Switch
        value={isShowingAllContracts}
        onChange={toggleIsShowingAllContracts}
        label={i18n.t('entiry.customerContract.labels.allCustomerContracts')}
        data-testid={suffixTestId('allContracts', props)}
      />
      <Box height={135}>
        <DataGrid
          // DG must be re-rendered on state change to update the query modifier
          // eslint-disable-next-line no-restricted-syntax
          key={isShowingAllContracts.toString()}
          gridCode="customer-contract-selection"
          actionCallback={actionCallback}
          onRowSelectionChange={handleRowsSelect}
          queryModifier={queryModifier}
          data-testid={suffixTestId('customerContractList', props)}
        />
      </Box>
      <Heading size={4}>{i18n.t('entity.warehouse.labels.assignContractConfirmation')}</Heading>
      <Box paddingLeft={4}>
        <Text color="secondary" size="small">
          <ul>
            <li>{i18n.t('warehouse.labels.deactivateContractSelectingApply')}</li>
          </ul>
        </Text>
        <Text color="secondary" size="small">
          <ul>
            <li>{i18n.t('warehouse.labels.deactivateContractDoNotSelectingApply')}</li>
          </ul>
        </Text>
      </Box>
      <Radio
        options={confirmationOptions}
        value={confirmation}
        onChange={handleConfirmationChange}
        data-testid={suffixTestId('actions.confirmation', props)}
      />
      <HStack spacing={2} justify="flex-end">
        <Button
          isDisabled={!isRowSelected}
          title={i18n.t('general.labels.discard')}
          onClick={handleDiscard}
          isLoading={isAssigningContract}
          data-testid={suffixTestId('actions.discard', props)}
        />
        <Tooltip
          isDisabled={isRowSelected}
          description={i18n.t('entity.warehouse.labels.firstlySelectContract')}
        >
          <Button
            isDisabled={!isRowSelected}
            title={i18n.t('entity.warehouse.labels.assignContract')}
            onClick={handleAssignContract}
            isLoading={isAssigningContract}
            data-testid={suffixTestId('actions.assign', props)}
          />
        </Tooltip>
      </HStack>
    </VStack>
  );
}
