import {DataStatus} from 'platform/components';

import {FC, useEffect} from 'react';
import {connect} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {
  deleteCommentRequest,
  GetComment,
  LegacyComment,
  loadCommentsListRequest,
  resetComments,
  selectCommentsList,
  selectFetchComments,
  TeasState,
  updateCommentRequest,
} from '@omnetic-dms/teas';

import {CommentItem, CommentItemProps} from './CommentItem';

const mapStateToProps = (state: TeasState): StateProps => ({
  comments: selectCommentsList(state),
  fetched: selectFetchComments(state),
});

const mapDispatchToProps = {
  loadCommentsListRequest,
  resetComments,
  updateCommentRequest,
  deleteCommentRequest,
};

export type StateProps = {
  fetched: boolean;
  comments: GetComment[];
};

export type DispatchProps = typeof mapDispatchToProps;

interface CommentsListProps extends StateProps, DispatchProps {
  readonly vehicleId: string;
}

interface UnconnectedCommentsListProps {
  readonly fetched: boolean;
  readonly comments?: LegacyComment[];
  readonly handleDeleteComment: CommentItemProps['onDelete'];
  readonly handleEditComment: CommentItemProps['onEdit'];
}

function UnconnectedCommentsList({
  fetched,
  comments,
  handleDeleteComment,
  handleEditComment,
}: UnconnectedCommentsListProps) {
  return (
    <DataStatus
      isLoading={!fetched}
      isEmpty={fetched && !comments?.length}
      emptyMessage={i18n.t('page.comments.labels.noOneCommented')}
    >
      {comments?.map((item, index) => (
        <CommentItem
          comment={item}
          key={item.uuid || index}
          onDelete={handleDeleteComment}
          onEdit={handleEditComment}
        />
      ))}
    </DataStatus>
  );
}

const CommentsListComponent: FC<CommentsListProps> = ({
  fetched,
  comments,
  loadCommentsListRequest,
  resetComments,
  updateCommentRequest,
  deleteCommentRequest,
  vehicleId,
}) => {
  useEffect(() => {
    loadCommentsListRequest({
      entityId: vehicleId,
    });

    return () => {
      resetComments();
    };
  }, [loadCommentsListRequest, resetComments, vehicleId]);

  const handleDeleteComment = (comment: LegacyComment) => {
    deleteCommentRequest({
      entityId: vehicleId,
      uuid: comment.uuid,
    });
  };

  const handleEditComment = (comment: LegacyComment, newMessage: string) => {
    updateCommentRequest({
      entityId: vehicleId,
      uuid: comment.uuid,
      body: {
        message: newMessage,
        parentCommentUuid: (comment as GetComment).parent?.uuid || null,
      },
    });
  };

  return (
    <UnconnectedCommentsList
      fetched={fetched}
      comments={comments}
      handleDeleteComment={handleDeleteComment}
      handleEditComment={handleEditComment}
    />
  );
};

export const CommentsList = connect(mapStateToProps, mapDispatchToProps)(CommentsListComponent);
