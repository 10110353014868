import {useEffect, useState} from 'react';

import {includes} from 'ramda';

/**
 * @returns True if the device is in landscape orientation, otherwise false
 */
export const useOrientation = () => {
  const [isLandscape, setIsLandscape] = useState(getIsLandscapeOrientation());

  useEffect(() => {
    const handleOrientationChange = () => setIsLandscape(getIsLandscapeOrientation());

    window.screen.orientation.addEventListener('change', handleOrientationChange);

    return () => window.screen.orientation.removeEventListener('change', handleOrientationChange);
  }, []);

  return isLandscape;
};

const getIsLandscapeOrientation = () =>
  includes(window.screen.orientation.type, ['landscape-primary', 'landscape-secondary']);
