import {AnySchema} from 'yup/lib/schema';

import {FieldType} from '../types/FieldType';

export const getFieldType = (property?: AnySchema): FieldType => {
  let as: FieldType = 'text';
  if (property) {
    if (property.type === 'string') {
      /* 	if (property.enum) {
				as = 'select';
			} else if (property.format === 'email') {
				as = 'email';
			} */
    } else if (property.type === 'date') {
      as = 'date';
    } else if (property.type === 'number') {
      as = 'number';
    } else if (property.type === 'array') {
      as = 'select';
    } else if (property.type === 'boolean') {
      as = 'checkbox';
    }
  }

  return as;
};
