import {FC, PropsWithChildren} from 'react';

import {
  VehicleSeriesDefinitionContextType,
  VehicleSeriesDefinitionFormWrapperProps,
} from '../types';
import {useVehicleSeriesDefinitionSelectors} from '../utils';
import {VehicleSeriesDefinitionForm} from './VehicleSeriesDefinitionForm';

export const VehicleSeriesDefinitionFormWithLists: FC<
  PropsWithChildren<
    VehicleSeriesDefinitionFormWrapperProps & Pick<VehicleSeriesDefinitionContextType, 'isSystem'>
  >
> = (props) => {
  const {branchList, seriesList, vehicleTypes} = useVehicleSeriesDefinitionSelectors();

  return (
    <VehicleSeriesDefinitionForm
      branchList={branchList}
      seriesList={seriesList}
      vehicleTypes={vehicleTypes}
      {...props}
    />
  );
};
