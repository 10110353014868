import {showNotification} from 'platform/components';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {isArray} from 'ramda-adjunct';

import {useDeleteDirectSaleMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {catchUnhandledDataGridActions, handleApiError, Main} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

export function DirectSalesList() {
  const navigate = useNavigate();

  const [deleteDirectSale] = useDeleteDirectSaleMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, sourceSearchParams, refreshData}) => {
    match(actionKey)
      .with('redirectDetail', () => {
        navigate(
          composePath(warehouseRoutes.directSalesDetailOverview, {
            queryParams: sourceSearchParams,
            params: {
              id: rowId as string,
            },
            isSearchParamsPreserved: false,
          })
        );
      })
      .with('delete', () => {
        const directSaleId = isArray(rowId) ? rowId : [rowId];

        deleteDirectSale({directSaleId})
          .unwrap()
          .then(() => showNotification.success(i18n.t('general.notifications.successfullyDeleted')))
          .then(refreshData)
          .catch(handleApiError);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const handleCreateDirectSaleRedirect = () => {
    navigate(warehouseRoutes.directSalesCreate);
  };

  return (
    <>
      <Helmet title={i18n.t('module.warehouse.title')} />
      <Main
        heading={i18n.t('page.warehouse.labels.directSales')}
        actions={[
          {
            type: 'button',
            title: i18n.t('page.warehouse.labels.newDirectSale'),
            onClick: handleCreateDirectSaleRedirect,
            'data-testid': testIds.warehouse.directSalesList('newDirectSale'),
          },
        ]}
      >
        <Box height="86vh" width="100%">
          <DataGrid
            gridCode="direct-sale"
            actionCallback={actionCallback}
            data-testid="directSaleDatagrid"
          />
        </Box>
      </Main>
    </>
  );
}
