import {formatIncompletePhoneNumber} from 'libphonenumber-js';

import {Alpha3CountryCode, getAlpha2CodeByAlpha3code} from './countryCodes';

/**
 * @about Returns formatted phone number.
 * @param number
 * @param country
 */
export const getFormattedNumber = (number: string, country?: Alpha3CountryCode) => {
  if (!country) {
    return formatIncompletePhoneNumber(number);
  }
  return formatIncompletePhoneNumber(number, getAlpha2CodeByAlpha3code(country));
};
