import {
  BreadcrumbType,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object} from 'yup';

import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  CreateDueDateSettingApiArg,
  useCreateDueDateSettingMutation,
  useGetDueDateSettingQuery,
  useUpdateDueDateSettingMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError, useBranches} from '@omnetic-dms/shared';

import {buildObject, useNavigate, yupNumber, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const DEFAULT_DUE_VALUE = '14';
const breadcrumbs: BreadcrumbType[] = [
  {href: settingsRoutes.businessCaseDueDates, label: i18n.t('page.settings.labels.dueDates')},
];
type FormValues = {
  branchId: string;
  proformaInvoiceDueDate: number;
  invoiceDueDate: number;
};

export function BusinessCaseDueDatesDetail() {
  const navigate = useNavigate();
  const {id} = useParams();

  const isCreating = isNilOrEmpty(id);

  const {branchOptions, data: branches} = useBranches();
  const [createDueDate] = useCreateDueDateSettingMutation();
  const [updateDueDate] = useUpdateDueDateSettingMutation();

  const {
    data: dueDateSetting,
    isLoading,
    isError,
  } = useGetDueDateSettingQuery({id: id ?? ''}, {skip: isCreating});

  const isSystem = dueDateSetting?.isSystem ?? false;

  const title = isCreating
    ? i18n.t('page.accounting.labels.newDefinition')
    : (branches?.branchListItems.find((branch) => branch.id === dueDateSetting?.branchId)
        ?.marketingName ?? i18n.t('page.accounting.labels.tenantDefinition'));

  const defaultValues: Partial<FormValues> = {
    branchId: dueDateSetting?.branchId ?? undefined,
    invoiceDueDate: Number(dueDateSetting?.invoiceDueDate ?? DEFAULT_DUE_VALUE),
    proformaInvoiceDueDate: Number(dueDateSetting?.proformaInvoiceDueDate ?? DEFAULT_DUE_VALUE),
  };

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    const submitValues = buildObject<CreateDueDateSettingApiArg>()
      .branchId(values.branchId ?? null)
      .invoiceDueDate(values.invoiceDueDate.toString())
      .proformaInvoiceDueDate(values.proformaInvoiceDueDate.toString())
      .build();

    const submitAction = isNil(id)
      ? () => createDueDate(submitValues)
      : () => updateDueDate({...submitValues, id});

    await submitAction()
      .unwrap()
      .then(() => showNotification.success(i18n.t('general.notifications.successfullyCompleted')))
      .then(() => navigate(settingsRoutes.businessCaseDueDates))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        title,
        breadcrumbs,
      }}
      data-testid={testIds.settings.businessCaseDueDatesDetail('settingsTemplate')}
      isLoading={isLoading}
      isError={isError}
    >
      <Form<FormValues>
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        schema={getSchema(isSystem)}
      >
        {(control) => (
          <>
            <SettingsSection>
              <FormField
                control={control}
                type="choice"
                name="branchId"
                isDisabled={isSystem}
                data-testid={testIds.settings.businessCaseDueDatesDetail('branch')}
                options={branchOptions}
                label={i18n.t('entity.cashRegister.parameters.branch')}
                placeholder={
                  isSystem ? i18n.t('page.accounting.labels.tenantDefinition') : undefined
                }
                isRequired
                isNotClearable
              />

              <Separator />
              <VStack spacing={2} width="50%">
                <FormField
                  control={control}
                  type="number"
                  isStepperVisible
                  minStepperValue={1}
                  name="invoiceDueDate"
                  label={i18n.t('entity.checkout.labels.invoice')}
                  data-testid={testIds.settings.businessCaseDueDatesDetail('invoice')}
                  isRequired
                />
                <FormField
                  control={control}
                  type="number"
                  isStepperVisible
                  minStepperValue={1}
                  name="proformaInvoiceDueDate"
                  label={i18n.t('entity.checkout.labels.proformaInvoice')}
                  data-testid={testIds.settings.businessCaseDueDatesDetail(
                    'proformaInvoiceDueDate'
                  )}
                  isRequired
                />
              </VStack>
            </SettingsSection>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: isCreating
                    ? i18n.t('general.actions.discard')
                    : i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.businessCaseDueDates),
                  variant: 'secondary',
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: isCreating
                    ? i18n.t('general.actions.save')
                    : i18n.t('general.actions.saveChanges'),
                },
              ]}
            />
          </>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const getSchema = (isSystem: boolean) =>
  object({
    branchId: yupString.when([], {
      is: () => isSystem,
      then: (schema) => schema.default(null),
      otherwise: (schema) => schema.required(),
    }),
    invoiceDueDate: yupNumber.min(1).required(),
    proformaInvoiceDueDate: yupNumber.min(1).required(),
  });
