import {z} from 'zod';

const FileDataTypeSchema = z.enum([
  'PURCHASE_CONTRACT',
  'PURCHASE_POWER_OF_ATTORNEY',
  'PURCHASE_HANDOVER_PROTOCOL',
  'PURCHASE_BROKERAGE_HANDOVER_PROTOCOL',
  'SALE_OFFER',
  'SALE_CONTRACT',
  'SALE_POWER_OF_ATTORNEY',
  'SALE_HANDOVER_PROTOCOL',
  'SALE_BROKERAGE_CONTRACT',
  'PAYMENT_ATTACHMENT',
  'PAYMENT_CASH_RECEIPT',
  'PAYMENT_CORRECTIVE_CASH_RECEIPT',
  'PAYMENT_INVOICE',
  'PAYMENT_PROFORMA_INVOICE',
  'PAYMENT_TAX_DOCUMENT',
  'PURCHASE_BROKERAGE_CONTRACT',
  'VEHICLE_CONDITION_PROTOCOL',
  'GDPR_MARKETING_CONSENT',
  'PURCHASE_BROKERAGE_POWER_OF_ATTORNEY',
  'CORRECTIVE_TAX_DOCUMENT',
  'OFFSETTING_OF_LIABILITIES_AND_RECEIVABLES',
  'INTERNAL_INVOICE_DOCUMENT',
]);

export const FileDataSchema = z.object({
  fileId: z.string(),
  name: z.string(),
  fileUri: z.string(),
  remoteId: z.string().nullable(),
  createdAt: z.string().nullable(),
  createdBy: z.string().nullable(),
  documentType: FileDataTypeSchema.nullable(),
  fileFileId: z.string().nullish(),
});

export type FileData = z.infer<typeof FileDataSchema>;
