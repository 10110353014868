import {isString} from 'ramda-adjunct';

import {isDefined} from 'shared';

import {GetBase64Fn} from '../types/GetBase64Fn';
import {QueueVideoTask} from '../types/QueueVideoTask';
import {QueueVideoTaskJob} from '../types/QueueVideoTaskJob';
import {getFileChunk} from './getFileChunk';

export async function handleUploadChunk(
  task: QueueVideoTask,
  job: QueueVideoTaskJob,
  getBase64: GetBase64Fn
): Promise<{etag: string}> {
  const chunk = await getFileChunk(task, job, getBase64);

  const response = await fetch(job.url, {
    body: chunk,
    method: 'PUT',
    headers: {'content-type': task.mimeType},
  });

  const etag = response.headers.get('etag');

  if (isDefined(etag) && isString(etag)) {
    return {etag};
  }
  throw Error('No etag');
}
