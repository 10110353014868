import {nanoid} from '@reduxjs/toolkit';
import {Dialog, showNotification, Switch, useDialog} from 'platform/components';

import {useCallback, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet-async';

import {isString} from 'ramda-adjunct';

import {vehiclesRoutes} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {composePath, useBoolean} from 'shared';

import {ActionCallback, DataGrid, DataGridBehavior, DataGridSettings} from 'features/datagrid';

const PAGE_TITLE = 'Demo page - DataGrid';

export function OpenSearchDataGrid() {
  const [isOverridesOpen, openOverrides, closeOverrides] = useDialog();
  const [isGridOpen, openGrid, closeGrid] = useDialog();
  const [isOverridesEnabled, _on, _off, toggleOverridesEnabled] = useBoolean(true);
  const [settingsOverrides, _setSettingsOverrides] = useState<DataGridBehavior>(FullDGSettings);

  const overrides = isOverridesEnabled ? settingsOverrides : {};

  const setSettingsOverrides = useCallback(
    (name: keyof DataGridSettings, value: any) => {
      _setSettingsOverrides({
        ...settingsOverrides,
        [name]: value,
      });
    },
    [settingsOverrides, _setSettingsOverrides]
  );

  const actionCallback: ActionCallback = useCallback(({actionKey, rowId}) => {
    switch (actionKey) {
      case 'detail':
        if (isString(rowId)) {
          const path = composePath(vehiclesRoutes.detail, {params: {id: rowId}});
          window.open(path, '_blank')?.focus();
        }
        break;
      default:
        showNotification.error(`Action callback was not specified for action ${actionKey}`);
        break;
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
      </Helmet>

      <Main
        isFullHeight
        heading={PAGE_TITLE}
        actions={[
          {
            type: 'button',
            onClick: openGrid,
            title: 'Open Simple DG in Dialog',
          },
          {
            type: 'button',
            onClick: openOverrides,
            title: 'Override Server Settings',
          },
        ]}
      >
        <DataGrid
          gridCode="vehicle-opensearch"
          data-testid="demo-grid"
          _useAsLastResort_definitionBehaviorOverrides={overrides}
          actionCallback={actionCallback}
        />
      </Main>

      <Dialog
        data-testid="openSearch-inside"
        title="DGv4 in Dialog"
        scrollBehavior="inside"
        isOpen={isGridOpen}
        onClose={closeGrid}
      >
        <div style={{height: 450}}>
          <DataGrid
            data-testid="openSearch-grid"
            gridCode="vehicle-opensearch"
            _useAsLastResort_definitionBehaviorOverrides={SimpleDGSettings}
          />
        </div>
      </Dialog>

      <Dialog
        data-testid="simple-grid"
        title="Override Server Settings"
        scrollBehavior="inside"
        isOpen={isOverridesOpen}
        onClose={closeOverrides}
      >
        Disclaimer: This dialog is for demo page purposes only and will not be part of any future
        datagrid.
        <hr style={{marginTop: '1rem', borderColor: '#c1c1c1'}} />
        <div style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-around'}}>
          <button
            style={{padding: '5px 10px', border: '1px solid #c1c1c1', borderRadius: '4px'}}
            onClick={() => _setSettingsOverrides(SimpleDGSettings)}
          >
            Simple Grid
          </button>
          <button
            style={{padding: '5px 10px', border: '1px solid #c1c1c1', borderRadius: '4px'}}
            onClick={() => _setSettingsOverrides(FullDGSettings)}
          >
            Full Grid
          </button>
          <Switch
            data-testid="simple-grid"
            label="Overrides"
            value={isOverridesEnabled}
            onChange={toggleOverridesEnabled}
          />
        </div>
        <hr style={{marginTop: '1rem', borderColor: '#c1c1c1'}} />
        <Checkbox
          data-testid="simple-grid-pinning"
          label="Enable Column Pinning"
          value={settingsOverrides.columnPinningAllowed}
          onChange={(v) => setSettingsOverrides('columnPinning', v)}
        />
        <Checkbox
          data-testid="simple-grid-moving"
          label="Enable Column Moving"
          value={settingsOverrides.columnMovingAllowed}
          onChange={(v) => setSettingsOverrides('columnMoving', v)}
        />
        <Checkbox
          data-testid="simple-grid-visibility-change"
          label="Enable Column Visibility Change"
          value={settingsOverrides.columnHidingAllowed}
          onChange={(v) => setSettingsOverrides('columnHiding', v)}
        />
        <Checkbox
          data-testid="simple-grid-context-menu"
          label="Enable Context Menu"
          value={settingsOverrides.contextMenuEnabled}
          onChange={(v) => setSettingsOverrides('contextMenu', v)}
        />
        <Checkbox
          data-testid="simple-grid-column-menu"
          label="Enable Column Menu"
          value={settingsOverrides.columnMenuEnabled}
          onChange={(v) => setSettingsOverrides('columnMenu', v)}
        />
        <Select
          data-testid="simple-grid-column"
          label="Column Sorting"
          value={settingsOverrides.columnSortMode}
          onChange={(v) => setSettingsOverrides('columnSorting', v)}
          options={[
            {label: 'Multiple', value: 'MULTIPLE_ROWS'},
            {label: 'Single', value: 'SINGLE_SORT'},
            {label: 'None', value: 'NONE'},
          ]}
        />
        <Checkbox
          data-testid="simple-grid-enable-column-resizing"
          label="Enable Column Auto Resizing"
          value={settingsOverrides.columnAutoSizingAllowed}
          onChange={(v) => setSettingsOverrides('columnAutoResizing', v)}
        />
        <Checkbox
          data-testid="simple-grid-enable-fit-to-screen"
          label="Enable Fit To Screen"
          value={settingsOverrides.fitToScreenAllowed}
          onChange={(v) => setSettingsOverrides('columnFitToScreen', v)}
        />
        <Checkbox
          data-testid="simple-grid-show-filter-toolpanel"
          label="Show Filter Toolpanel"
          value={settingsOverrides.filterToolPanelVisible}
          onChange={(v) => setSettingsOverrides('toolPanelFilter', v)}
        />
        <Checkbox
          data-testid="simple-grid-show-preset-panel"
          label="Show Preset Panel"
          value={settingsOverrides.presetsEnabled}
          onChange={(v) => setSettingsOverrides('presets', v)}
        />
        <Checkbox
          data-testid="simple-grid-show-smart-search"
          label="Show Smart Search"
          value={settingsOverrides.smartSearchEnabled}
          onChange={(v) => setSettingsOverrides('smartSearch', v)}
        />
        <Checkbox
          data-testid="simple-grid-show-settings-panel"
          label="Show Settings Toolpanel"
          value={settingsOverrides.settingsToolPanelVisible}
          onChange={(v) => setSettingsOverrides('toolPanelSettings', v)}
        />
        <Checkbox
          data-testid="simple-grid-show-column-toolpanel"
          label="Show Column Toolpanel"
          value={settingsOverrides.columnToolPanelVisible}
          onChange={(v) => setSettingsOverrides('toolPanelColumn', v)}
        />
        <Checkbox
          data-testid="simple-grid-enable-cell-resizing"
          label="Enable Cell Resizing"
          value={settingsOverrides.columnResizingAllowed}
          onChange={(v) => setSettingsOverrides('columnResizing', v)}
        />
        <Checkbox
          data-testid="simple-grid-enable-range-selection"
          label="Enable Range Selection"
          value={settingsOverrides.rangeSelectAllowed}
          onChange={(v) => setSettingsOverrides('rangeSelection', v)}
        />
        <Select
          data-testid="simple-grid-wrapper-style"
          label="Wrapper Style"
          value={settingsOverrides.wrapperStyle}
          onChange={(v) => setSettingsOverrides('datagridWrapperStyle', v)}
          options={[
            {label: 'Inline', value: 'INLINE'},
            {label: 'Card', value: 'CARD'},
          ]}
        />
        <Select
          data-testid="simple-grid-row-selection"
          label="Row Selection"
          value={settingsOverrides.rowSelectMode}
          onChange={(v) => setSettingsOverrides('rowSelection', v)}
          options={[
            {label: 'Single', value: 'SINGLE'},
            {label: 'Multiple', value: 'MULTIPLE'},
            {label: 'None', value: 'NONE'},
          ]}
        />
        <Checkbox
          data-testid="simple-grid-enable-pagination"
          label="Enable Pagination"
          value={settingsOverrides.paginationEnabled}
          onChange={(v) => setSettingsOverrides('pagination', v)}
        />
        <Checkbox
          data-testid="simple-grid-enable-actions"
          label="Enable Actions"
          value={settingsOverrides.actionColumnEnabled}
          onChange={(v) => setSettingsOverrides('actions', v)}
        />
        <Select
          data-testid="simple-grid-filter-type"
          label="Filter Type"
          value={settingsOverrides.filterMode}
          onChange={(v) => setSettingsOverrides('filterType', v)}
          options={[
            {label: 'Native', value: 'NATIVE'},
            {label: 'Custom', value: 'CUSTOM'},
          ]}
        />
      </Dialog>
    </>
  );
}

type CheckboxProps = {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

const Checkbox = (props: CheckboxProps) => {
  const id = useMemo<string>(() => nanoid(), []);
  return (
    <div style={{marginTop: '1rem'}}>
      <label style={{display: 'inline-block', width: '30%', minWidth: '100px'}} htmlFor={id}>
        {props.label}
      </label>
      <div style={{display: 'inline-block', width: '49%'}}>
        <input
          type="checkbox"
          name={id}
          id={id}
          checked={props.value}
          onChange={(e) => props.onChange(e.target.checked)}
        />
      </div>
    </div>
  );
};

type SelectProps = {
  label: string;
  value: string;
  options: Array<{label: string; value: string}>;
  onChange: (value: string) => void;
};

const Select = (props: SelectProps) => {
  const id = useMemo<string>(() => nanoid(), []);
  return (
    <div style={{marginTop: '1rem'}}>
      <label style={{display: 'inline-block', width: '30%', minWidth: '100px'}} htmlFor={id}>
        {props.label}
      </label>
      <div style={{display: 'inline-block', width: '49%'}}>
        <select name={id} id={id} onChange={(e) => props.onChange(e.target.value)}>
          {props.options.map((option) => (
            <option key={option.value} selected={option.value === props.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const SimpleDGSettings: DataGridBehavior = {
  summaryRowEnabled: false,
  columnResizingAllowed: false,
  rangeSelectAllowed: false,
  rowSelectMode: 'NONE',
  columnToolPanelVisible: false,
  settingsToolPanelVisible: false,
  filterToolPanelVisible: false,
  presetsEnabled: false,
  wrapperStyle: 'INLINE',
  smartSearchEnabled: false,
  columnPinningAllowed: false,
  columnMovingAllowed: false,
  columnMenuEnabled: false,
  fitToScreenAllowed: false,
  clipboardExportAllowed: false,
  columnSortMode: 'NONE',
  filterMode: 'CUSTOM',
  statusBarVisible: false,
  contextMenuEnabled: false,
  columnAutoSizingAllowed: false,
  columnHidingAllowed: false,
  paginationEnabled: false,
  actionColumnEnabled: false,
};

const FullDGSettings: DataGridBehavior = {
  summaryRowEnabled: true,
  columnResizingAllowed: true,
  rangeSelectAllowed: true,
  rowSelectMode: 'MULTIPLE',
  columnToolPanelVisible: true,
  settingsToolPanelVisible: true,
  filterToolPanelVisible: true,
  presetsEnabled: true,
  wrapperStyle: 'CARD',
  smartSearchEnabled: true,
  columnPinningAllowed: true,
  columnMovingAllowed: true,
  columnMenuEnabled: true,
  fitToScreenAllowed: true,
  clipboardExportAllowed: true,
  columnSortMode: 'MULTI_SORT',
  filterMode: 'CUSTOM',
  statusBarVisible: true,
  contextMenuEnabled: true,
  columnAutoSizingAllowed: true,
  columnHidingAllowed: true,
  paginationEnabled: true,
  actionColumnEnabled: true,
};
