import {SendServiceNotificationEmail} from '../types/serviceNotification';
import {omneticApi} from './baseApi/omneticApi';

export const serviceInspectionNotificationApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    postSendCheckinEmailNotification: build.mutation<void, SendServiceNotificationEmail>({
      query: (queryArgs) => ({
        url: `/dms/v1/workshop/service-case/${queryArgs.serviceCaseId}/check-in/notification`,
        method: 'POST',
        body: queryArgs.body,
      }),
    }),
    postSendHandoverEmailNotification: build.mutation<void, SendServiceNotificationEmail>({
      query: (queryArgs) => ({
        url: `/dms/v1/workshop/service-case/${queryArgs.serviceCaseId}/handover/notification`,
        method: 'POST',
        body: queryArgs.body,
      }),
    }),
  }),
});
