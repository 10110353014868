import {Button, ButtonGroup} from 'platform/components';
import {Space, Text} from 'platform/foundation';

import {useState} from 'react';

import i18n from '@omnetic-dms/i18n';

interface UpgradeFrontendDialogProps {
  onPostpone: VoidFunction;
  onUpdate: () => Promise<string | void>;
}

export function UpdateApplicationDialog(props: UpgradeFrontendDialogProps) {
  /**
   * RTK has a problem to handle loading state of mutations, due to redux persist library
   * for this reason we leave the useState here
   */
  const [isLoading, setLoading] = useState<boolean>(false);

  return (
    <>
      <Text size="small">
        {i18n.t('general.checkApplicationVersion.updateAvailableDescription')}
      </Text>
      <Space vertical={2} />
      <ButtonGroup align="right">
        <Button
          variant="secondary"
          onClick={props.onPostpone}
          isDisabled={isLoading}
          title={i18n.t('general.checkApplicationVersion.postponeButton')}
        />
        <Button
          variant="primary"
          onClick={() => {
            setLoading(true);
            props.onUpdate().catch(() => setLoading(false));
          }}
          isLoading={isLoading}
          title={i18n.t('general.checkApplicationVersion.updateButton')}
        />
      </ButtonGroup>
    </>
  );
}
