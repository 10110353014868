import {defaultTo} from 'ramda';

import {Slider} from '../../Slider/Slider';
import {useFieldProps} from '../hooks/useFieldProps';
import {SliderRendererResponseBody} from '../types/modelSchema';

export function SliderRenderer(props: SliderRendererResponseBody) {
  const {onChange, value, isRequired, error} = useFieldProps(props);

  return (
    <Slider
      label={props.label}
      isRange={props.isRange}
      tooltip={props.tooltip}
      isRequired={isRequired}
      errorMessage={error?.message}
      min={defaultTo(0, props.min)}
      max={defaultTo(100, props.max)}
      step={defaultTo(1, props.step)}
      value={defaultTo(props.isRange ? [props.min, props.max] : props.min, value)}
      data-testid={props.name}
      onChange={onChange}
      onlyEdges={true}
    />
  );
}
