import {isNil, isNotNil, uniq} from 'ramda';

import {useGetAllMakesModelsQuery} from '@omnetic-dms/api';

import {useFilters} from '../../FiltersContext/FiltersContext';
import {getOptionType} from '../utils/getOptionType';
import {isModelLastMakeSelected} from '../utils/isModelLastMakeSelected';

export function useMakeModelFilter() {
  const {filters, onUpdateFilters} = useFilters();
  const {data, isLoading} = useGetAllMakesModelsQuery({vehicleType: 'VEHICLETYPE_PASSENGER_CAR'});

  /**
   * Get a model by its key
   * @param modelKey
   */
  const getModel = (modelKey?: string) => (isNotNil(modelKey) ? data?.models[modelKey] : null);

  /**
   * Get a model family by its key
   * @param modelFamilyKey
   */
  const getModelFamily = (modelFamilyKey?: string) =>
    isNotNil(modelFamilyKey) ? data?.modelFamilies[modelFamilyKey] : null;

  /**
   * Get a make by its key
   * @param makeKey
   */
  const getMake = (makeKey?: string) => (isNotNil(makeKey) ? data?.makes[makeKey] : null);

  /**
   * All selected makes (no makes excluded)
   */
  const allSelectedMakes = Object.entries(filters.make ?? {})
    .filter(([_key, value]) => value)
    .map(([key]) => key);

  /**
   * All selected models (no models excluded)
   */
  const allSelectedModels = Object.entries(filters.model ?? {})
    .filter(([_key, value]) => value)
    .map(([key]) => key);

  /**
   * Selected model families (those which have all their models selected)
   */
  const selectedModelFamilies = Object.entries(data?.modelFamilies ?? {})
    .filter(([_key, family]) => family.models.every((model) => allSelectedModels.includes(model)))
    .map(([key]) => key);

  /**
   * Makes with some models selected
   */
  const makesWithSelectedModels = uniq(
    allSelectedModels.map((model) => getModel(model)?.make).filter(isNotNil)
  );

  /**
   * Selected makes minus those with some models selected
   */
  const selectedMakes = allSelectedMakes.filter((make) => !makesWithSelectedModels.includes(make));

  /**
   * Models from selected model families
   */
  const modelsFromSelectedModelFamilies = allSelectedModels.filter((model) => {
    const family = getModel(model)?.modelFamily;
    return isNotNil(family) && selectedModelFamilies.includes(family);
  });

  /**
   * Selected models excluding those from selected families
   */
  const selectedModels = allSelectedModels.filter(
    (model) => !modelsFromSelectedModelFamilies.includes(model)
  );

  /**
   * Selects a make
   * @param makeKey
   */
  const selectMake = (makeKey: string) => {
    onUpdateFilters(['make', makeKey], true);
  };

  /**
   * Unselects a make
   * @param makeKey
   */
  const unselectMake = (makeKey: string) => {
    onUpdateFilters(['make', makeKey], false);
  };

  /**
   * Selects a model without touching makes
   * @param modelKey
   */
  const simpleSelectModel = (modelKey: string) => {
    onUpdateFilters(['model', modelKey], true);
  };

  /**
   * Selects a model and also the make of the model
   * @param modelKey
   */
  const selectModel = (modelKey: string) => {
    simpleSelectModel(modelKey);
    const model = getModel(modelKey);
    isNotNil(model) && onUpdateFilters(['make', model.make], true);
  };

  /**
   * Unselects a model without touching makes
   * @param modelKey
   */
  const simpleUnselectModel = (modelKey: string) => {
    onUpdateFilters(['model', modelKey], false);
  };

  /**
   * Unselects a model, if it is the last model of the make selected, unselects the make
   * @param modelKey
   */
  const unselectModel = (modelKey: string) => {
    simpleUnselectModel(modelKey);
    const make = getMake(getModel(modelKey)?.make);
    if (isNotNil(make) && isModelLastMakeSelected(modelKey, selectedModels, make)) {
      onUpdateFilters(['make', make.name], false);
    }
  };

  /**
   * Selects a model family
   * @param modelFamilyKey
   */
  const selectModelFamily = (modelFamilyKey: string) => {
    const modelFamily = getModelFamily(modelFamilyKey);
    isNotNil(modelFamily) && onUpdateFilters(['make', modelFamily.make], true);
    modelFamily?.models.forEach((model) => onUpdateFilters(['model', model], true));
  };

  /**
   * Unselects a model family
   * @param modelFamilyKey
   */
  const unselectModelFamily = (modelFamilyKey: string) => {
    const modelFamily = getModelFamily(modelFamilyKey);
    const make = getMake(modelFamily?.make);
    modelFamily?.models.forEach((modelKey) => {
      onUpdateFilters(['model', modelKey], false);
      if (isModelLastMakeSelected(modelKey, selectedModels, make)) {
        onUpdateFilters(['make', modelFamily.make], false);
      }
    });
  };

  /**
   * Selects a make, model or model family
   *   (type detected based on prefix of the code of make/model/model family)
   * @param value
   */
  const selectMakeModel = (value: string | null) => {
    if (isNil(value)) {
      return;
    }

    const optionType = getOptionType(value);
    if (optionType === 'make') {
      selectMake(value);
    }
    if (optionType === 'model') {
      selectModel(value);
    }
    if (optionType === 'modelFamily') {
      selectModelFamily(value);
    }
  };

  /**
   * Set a new set (values) of models, but does not touch the makes
   *  - Selects those in values
   *  - Unselects those in the current filter but missing in values
   * @param values
   */
  const simpleUpdateModels = (values: string[] | null) => {
    values?.forEach((value) => {
      simpleSelectModel(value);
    });

    selectedModels.forEach((modelKey) => {
      if (!values?.includes(modelKey)) {
        simpleUnselectModel(modelKey);
      }
    });
  };

  /**
   * Set a new set (values) of makes, models and model families
   *  - Selects those in values
   *  - Unselects those in the current filter but missing in values
   * @param values
   */
  const updateMakesModels = (values: string[] | null) => {
    values?.forEach((value) => {
      selectMakeModel(value);
    });

    selectedMakes.forEach((makeKey) => {
      if (!values?.includes(makeKey)) {
        unselectMake(makeKey);
      }
    });
    selectedModels.forEach((modelKey) => {
      if (!values?.includes(modelKey)) {
        unselectModel(modelKey);
      }
    });
    selectedModelFamilies.forEach((modelFamilyKey) => {
      if (!values?.includes(modelFamilyKey)) {
        unselectModelFamily(modelFamilyKey);
      }
    });
  };

  return {
    models: data?.models,
    makes: data?.makes,
    modelFamilies: data?.modelFamilies,
    allSelectedModels,
    allSelectedMakes,
    selectedModels,
    selectedMakes,
    selectedModelFamilies,
    isLoading,
    getMake,
    getModel,
    getModelFamily,
    selectMake,
    unselectMake,
    selectModel,
    unselectModel,
    selectModelFamily,
    unselectModelFamily,
    selectMakeModel,
    simpleUpdateModels,
    updateMakesModels,
  };
}
