import {Box, HStack, Icon, Integer, Show, Text} from 'platform/foundation';
import styled from 'styled-components';

import {ReactNode} from 'react';

import {MenuItemProps as DefaultMenuItemProps} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {composePath, useBoolean, useToggle} from 'shared';

import {MenuItemPin} from '../components/MenuItemPin';
import {MenuItemWithPin} from '../utils/addPinsToMenuItems';
import {Collapse} from './Collapse';
import {CollapseToggle} from './CollapseToggle';
import {MenuButton} from './MenuButton';
import {MenuItemActionButton} from './MenuItemActionButton';
import {MenuLink} from './MenuLink';

function getDepthPadding(depth: Integer): Integer {
  if (depth > 4) {
    return 16;
  }
  return (depth * 4) as Integer;
}

interface MenuItemProps extends MenuItemWithPin<DefaultMenuItemProps> {
  depth: Integer;
  onClick: () => void;
}

function MenuItemWrapper(props: {
  to: string;
  isGroup: boolean;
  children: ReactNode;
  onClick: () => void;
}) {
  if (props.isGroup) {
    return <MenuButton onClick={props.onClick}>{props.children}</MenuButton>;
  }

  return (
    <MenuLink to={props.to} onClick={props.onClick}>
      {props.children}
    </MenuLink>
  );
}

export function MenuItem(props: MenuItemProps) {
  const [isOpen, toggle] = useToggle(false);
  const [isPinVisible, setPinVisible, hidePin] = useBoolean(false);

  const isGroup = Boolean(props.content && props.content.length > 1);

  const handleMenuItemClick = () => {
    if (isGroup) {
      return toggle();
    }
    return props.onClick();
  };

  const isPinned = props.isPinned || Boolean(props.legacyPinId);

  return (
    <>
      <StyledMenuItem onMouseEnter={setPinVisible} onMouseLeave={hidePin}>
        <MenuItemWrapper
          isGroup={isGroup}
          to={composePath(props.path, {isSearchParamsPreserved: false})}
          onClick={handleMenuItemClick}
        >
          <Box overflow="hidden" paddingLeft={getDepthPadding(props.depth)}>
            <HStack spacing={2} justify="space-between" align="center">
              <Icon
                size={4}
                color="text.white"
                value={props.layoutProps.iconV2 ?? props.layoutProps.icon}
              />
              <Box width="100%" overflow="hidden">
                <Text size="small" color="white" noWrap>
                  {i18n.t(props.layoutProps.title)}
                </Text>
              </Box>
            </HStack>
          </Box>
        </MenuItemWrapper>
        <HStack spacing={0}>
          <Show when={(isPinVisible || isPinned) && !isGroup}>
            <MenuItemPin
              id={props.id}
              path={props.path}
              isPinned={props.isPinned}
              legacyPinId={props.legacyPinId}
            />
          </Show>
          <Show when={isGroup}>
            <MenuItemActionButton onClick={toggle}>
              <CollapseToggle isOpen={isOpen} />
            </MenuItemActionButton>
          </Show>
        </HStack>
      </StyledMenuItem>
      <Collapse isOpen={isOpen && isGroup}>
        {props.content?.map((module) => (
          <MenuItem
            key={module.id}
            depth={(props.depth + 1) as Integer}
            onClick={props.onClick}
            {...module}
          />
        ))}
      </Collapse>
    </>
  );
}

const StyledMenuItem = styled.div`
  display: flex;
  max-width: 100%;
  height: '100%';
  overflow: hidden;
  border-radius: ${({theme}) => theme.getSize(1)};
  color: ${({theme}) => theme.colors.text.white};
  &:hover {
    background-color: ${(props) => props.theme.colors.palettes.neutral[500]['100']};
  }
`;
