import {match} from 'ts-pattern';

import {always} from 'ramda';

import {InvoiceDocumentTypes} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

export const getAccountingDocumentTitle = (invoiceType: InvoiceDocumentTypes | Nullish) =>
  match(invoiceType)
    .with('balance_invoice', always(i18n.t('entity.balanceInvoice.labels.balanceInvoice')))
    .with('invoice_proforma', always(i18n.t('entity.checkout.labels.proformaInvoice')))
    .with(
      'corrective_tax_document',
      always(i18n.t('entity.accounting.labels.correctiveTaxDocument'))
    )
    .with(
      'tax_document_for_payment',
      always(i18n.t('entity.accounting.labels.taxDocumentForPayment'))
    )
    .otherwise(always(i18n.t('entity.businessCase.labels.invoice')));
