import {BreadcrumbType} from 'platform/components';

import {useEffect} from 'react';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {useThunkDispatch} from '@omnetic-dms/teas';

import {useBoolean} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {vehicleSeriesDefinitionCommonActions} from '../VehicleSeriesDefinition/utils';
import {VehicleSeriesDefinitionNew} from './components/VehicleSeriesDefinitionNew';

export function VehicleNewSeriesDefinition() {
  const dispatch = useThunkDispatch();

  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  useEffect(() => {
    startLoading();
    Promise.all(vehicleSeriesDefinitionCommonActions.map((action) => dispatch(action))).finally(
      () => {
        stopLoading();
      }
    );
  }, [dispatch, startLoading, stopLoading]);

  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('page.salesSettings.labels.vehicleSeriesDefinitionTitle'),
      href: settingsRoutes.vehicleSeriesDefinition,
    },
  ];

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{title: i18n.t('page.salesSettings.labels.vehicleDefinitionTitle'), breadcrumbs}}
      data-testid={testIds.settings.vehicleNewSeriesDefinition('modal')}
    >
      <VehicleSeriesDefinitionNew />
    </SettingsTemplate>
  );
}
