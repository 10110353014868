import {isFeatureEnabled} from 'feature-flags';
import {
  Actions,
  closeCurrentDialog,
  DataStatus,
  showNotification,
  Tooltip,
} from 'platform/components';
import {Box, Heading, Hide, HStack, Image, Link, Show, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNonPositive, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetBackgroundRemovalListQuery,
  useGetBackgroundRemovalSettingsQuery,
  useRemoveSaleVehiclePhotosBackgroundMutation,
  useRemoveVehiclePhotosBackgroundMutation,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {suffixTestId, TestIdProps} from 'shared';

import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {handleApiError} from '../../utils/handleApiError';
import {BackgroundSelector} from '../BackgroundSelector/BackgroundSelector';

interface BackgroundRemovalDialogPropsBase extends TestIdProps {
  vehiclePhotoIds: string[];
  onConfirm?: () => void;
}

interface WithVehicleId extends BackgroundRemovalDialogPropsBase {
  vehicleId: string;
  saleVehicleId?: never;
  saleVehicleAlbumId?: never;
}

interface WithSaleVehicleId extends BackgroundRemovalDialogPropsBase {
  saleVehicleId: string;
  saleVehicleAlbumId: string;
  vehicleId?: never;
}

export function BackgroundRemovalDialog(props: WithVehicleId | WithSaleVehicleId) {
  const [canConfigureBackgroundRemoval] = usePermissions({
    permissionKeys: ['configureBackgroundRemoval'],
  });
  const {data, isLoading: isBackgroundRemovalSettingsListLoading} =
    useGetBackgroundRemovalListQuery(undefined, {
      skip: !isFeatureEnabled(featureFlags.SETTINGS_BACKGROUND_REMOVAL),
    });
  const [activeBgSettingsIndex, setActiveBgSettingsIndex] = useState(0);

  const {data: settings, isLoading, isError} = useGetBackgroundRemovalSettingsQuery();

  const [removePhotosBackground, {isLoading: isRemoveBackgroundsLoading}] =
    useRemoveVehiclePhotosBackgroundMutation();

  const [removeSaleVehiclePhotosBackground, {isLoading: isSaleVehiclePhotosBackgroundsLoading}] =
    useRemoveSaleVehiclePhotosBackgroundMutation();

  const handleBackgroundRemoval = () => {
    const promise = props.saleVehicleId
      ? removeSaleVehiclePhotosBackground({
          saleVehicleId: props.saleVehicleId,
          saleVehicleAlbumId: props.saleVehicleAlbumId,
          removeSaleVehiclePhotosBackgroundRequestBody: {
            saleVehiclePhotoIds: props.vehiclePhotoIds,
            ...(isNotNilOrEmpty(data?.[activeBgSettingsIndex]) &&
              isFeatureEnabled(featureFlags.SETTINGS_BACKGROUND_REMOVAL) && {
                backgroundRemovalSettingId: data![activeBgSettingsIndex].id,
              }),
          },
        })
      : removePhotosBackground({
          vehicleId: props.vehicleId!,
          body: {
            vehiclePhotoIds: props.vehiclePhotoIds,
          },
        });

    promise
      .unwrap()
      .then(props.onConfirm)
      .then(closeCurrentDialog)
      .then(() => {
        showNotification.success(i18n.t('entity.photo.backgroundRemoval.scheduledNotification'));
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus
      isLoading={
        isLoading ||
        (isFeatureEnabled(featureFlags.SETTINGS_BACKGROUND_REMOVAL)
          ? isBackgroundRemovalSettingsListLoading
          : false)
      }
      isError={isError}
    >
      <HStack spacing={6}>
        <Hide
          when={
            isFeatureEnabled(featureFlags.SETTINGS_BACKGROUND_REMOVAL) &&
            isNotNilOrEmpty(props.saleVehicleId)
          }
        >
          <Box width={70}>
            <Image
              data-testid={suffixTestId('background-image', props)}
              src={`assets/images/background-replacement/${settings?.backgroundId}.jpg`}
            />
          </Box>
        </Hide>
        <VStack spacing={6}>
          <Heading size={3}>{i18n.t('entity.photo.backgroundRemoval.dialogHeadline')}</Heading>
          <Text size="small" alternative>
            {i18n.t('entity.photo.backgroundRemoval.countInformation', {
              count: props.vehiclePhotoIds.length,
            })}
          </Text>
          <Show
            when={
              isFeatureEnabled(featureFlags.SETTINGS_BACKGROUND_REMOVAL) &&
              isNotNilOrEmpty(props.saleVehicleId)
            }
          >
            <BackgroundSelector
              activeOptionIndex={activeBgSettingsIndex}
              columns={3}
              options={data}
              onClick={setActiveBgSettingsIndex}
            />
          </Show>
          <Text size="xSmall" color="secondary">
            {i18n.t('entity.photo.backgroundRemoval.description')}
          </Text>
          <Tooltip
            label={
              !canConfigureBackgroundRemoval ? i18n.t('general.labels.noPermission') : undefined
            }
          >
            <Link
              data-testid={suffixTestId('configure-removal', props)}
              href={
                isFeatureEnabled(featureFlags.SETTINGS_BACKGROUND_REMOVAL)
                  ? settingsRoutes.backgroundRemoval
                  : settingsRoutes.backgroundRemovalOld
              }
              title={i18n.t('entity.photo.backgroundRemoval.changeSettings')}
              leftIcon="action/magic_wand"
              isDisabled={!canConfigureBackgroundRemoval}
              size="small"
            />
          </Tooltip>
          <Actions
            data-testid={suffixTestId('actions', props)}
            align="right"
            actions={[
              {
                title: i18n.t('general.actions.cancel'),
                type: 'button',
                variant: 'secondary',
                onClick: closeCurrentDialog,
              },
              {
                title: i18n.t('general.actions.confirm'),
                type: 'button',
                isDisabled: isNonPositive(props.vehiclePhotoIds.length),
                isLoading: isRemoveBackgroundsLoading || isSaleVehiclePhotosBackgroundsLoading,
                onClick: handleBackgroundRemoval,
              },
            ]}
          />
        </VStack>
      </HStack>
    </DataStatus>
  );
}
