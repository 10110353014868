import i18n from 'i18next';
import {Button, IconButton, useAnimatedPopper} from 'platform/components';
import {Box, HStack, Icon, Text, ThemeIconKey, VStack} from 'platform/foundation';

type PopoverHeaderProps = {
  icon: ThemeIconKey;
  title: string;
  data: {title: string; describe: string; icon: ThemeIconKey}[] | null;
  refetchData: () => void;
};
export const PopoverHeader = (props: PopoverHeaderProps) => {
  const {openPopper, referenceRef, popperProps, Popper, closePopper} = useAnimatedPopper({
    placement: 'bottom-start',
  });

  const contentElement =
    props.data?.map((item) => (
      <HStack key={`${item.title}-${item.describe}`} spacing={4} align="center">
        <Box backgroundColor="palettes.neutral.30.100" borderRadius="circular">
          <HStack justify="center" width={10} height={10} align="center">
            <Icon value={item.icon} />
          </HStack>
        </Box>
        <Box>
          <Text alternative size="xSmall">
            {item.title}
          </Text>
          <Text size="xSmall" color="secondary">
            {item.describe}
          </Text>
        </Box>
      </HStack>
    )) ?? null;

  return (
    <Box>
      <span ref={referenceRef}>
        <IconButton
          icon={props.icon}
          severity="informational"
          data-testid="popover-header-button"
          onClick={async () => {
            await props?.refetchData();
            openPopper();
          }}
        />
      </span>
      <Popper {...popperProps}>
        <Box
          height={78}
          width={100}
          borderRadius="medium"
          backgroundColor="general.white"
          border="1px solid"
          borderColor="palettes.neutral.40.100"
        >
          <Box
            paddingVertical={3}
            paddingHorizontal={4}
            borderBottom="1px solid"
            borderColor="general.separator"
            position="relative"
          >
            <HStack width="100%" justify="space-between" align="center">
              <Text alternative>{props.title}</Text>
              <IconButton
                icon="navigation/close"
                onClick={closePopper}
                data-testid="popover-header-closeIcon"
              />
            </HStack>
          </Box>

          <Box padding={4} paddingBottom={0} height="81%" position="relative" overflow="auto">
            <VStack justify="space-between" height="100%">
              <VStack spacing={4}>{contentElement}</VStack>
              <Box paddingBottom={4}>
                <HStack justify="flex-end">
                  <Button
                    variant="secondary"
                    data-testid="popover-header-close"
                    title={i18n.t('general.actions.close')}
                    onClick={closePopper}
                  />
                </HStack>
              </Box>
            </VStack>
          </Box>
        </Box>
      </Popper>
    </Box>
  );
};
