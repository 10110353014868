import {ProgressBar, showNotification} from 'platform/components';
import {Box, Center, Show} from 'platform/foundation';

import {CSSProperties, FC, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps, uploadFileWithProgress} from 'shared';

import {useApiDispatch} from '../../../hooks/useApiDispatch';
import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {getAuditAsset, imagesUpload, uploadFileV2} from '../../../store/carAudit/actions';
import {addAssets} from '../../../store/carAudit/reducer';
import {selectActiveAuditId} from '../../../store/carAudit/selectors';
import {selectUserName} from '../../../store/user/selectors';
import {AuditAssetsUpload} from '../../../types/AuditAssetsUpload';
import {AuditDataAssetsFilesBody} from '../../../types/AuditDataAssetsFilesBody';
import {useVehicleCreateContext} from '../../VehicleCreateContext/hooks/useVehicleCreateContext';
import {useConditionContext} from '../hooks/useConditionContext';
import {useConditionUploadContext} from '../hooks/useConditionUploadContext';
import {TUploadImage} from '../types/TUploadImage';
import {ImagePreview} from './ImagePreview';

type UploadImageProps = {
  image: TUploadImage;
  isImageRotationInProgress?: boolean;
  ratio: CSSProperties['aspectRatio'];
  onDelete?: () => void;
  onOpenClick: () => void;
};

export const UploadImage: FC<UploadImageProps & TestIdProps> = ({
  image,
  isImageRotationInProgress,
  onDelete,
  onOpenClick,
  ratio,
  ...rest
}) => {
  const dispatch = useThunkDispatch();
  const apiDispatch = useApiDispatch();

  const auditId = useSelector(selectActiveAuditId);
  const userName = useSelector(selectUserName);

  const [uploadPercent, setUploadPercent] = useState<number | null>(null);
  const currentlyUploadingId = useRef<string | null>(null);

  const {isCreateVehicle, handleChangeCategory} = useConditionContext();

  const {
    categoryId,
    paramDefinitionId,
    damageId,
    onUpload,
    uploadedImagesIds,
    setUploadedImage,
    uploadImages,
  } = useConditionUploadContext();

  const {pushConditionAsset} = useVehicleCreateContext();

  const {file, imageId} = image;
  const metaValue = damageId ? JSON.stringify({damageId}) : '';

  const addAsset = async (assetBody: AuditDataAssetsFilesBody): Promise<void> => {
    onUpload?.(assetBody);
    setUploadedImage(imageId);

    pushConditionAsset?.(imageId, {
      imageId,
      categoryId,
      paramDefinitionId,
      metaValue,
      uploadedAssetId: assetBody.uploadedAssetsId,
      updatedAt: new Date().toISOString(),
      directoryId: null,
    });

    if (!damageId) {
      dispatch(
        addAssets({
          categoryId,
          paramDefinitionId,
          assets: [assetBody],
        })
      );
      await handleChangeCategory();
    }
  };

  const getUploadUriAndAssetId = (): Promise<AuditAssetsUpload> => {
    if (isCreateVehicle) {
      return apiDispatch(uploadFileV2.action, {
        userName,
        withoutReducer: Boolean(damageId),
      });
    }

    return apiDispatch(imagesUpload.action, {
      requestBody: {
        auditId: auditId ?? '',
        categoryId,
        paramDefinitionId,
        imageId,
        updatedAt: new Date().toISOString(),
        metaValue,
      },
      userName,
      withoutReducer: Boolean(damageId),
    });
  };

  const uploadImage = async () => {
    setUploadPercent(1);

    const {url: uploadUri, uploadedAssetId} = await getUploadUriAndAssetId();

    setUploadPercent(5);

    await uploadFileWithProgress({
      file,
      url: uploadUri,
      shouldOmitSharedHeaders: true,
      onProgress: (percentage) => setUploadPercent(Math.max(5, percentage)),
    });

    const {url, resizeUrl} = await apiDispatch(getAuditAsset.action, {
      assetId: uploadedAssetId,
    });

    await addAsset({
      uploadedAssetsId: uploadedAssetId,
      imageId,
      url,
      resizeUrl,
      meta: metaValue,
      directory: null,
    });
  };

  const isUploaded = uploadedImagesIds.includes(image.imageId);

  useEffect(() => {
    const index = uploadImages.findIndex((m) => m.imageId === image.imageId);
    const isPreviousImageUploaded = uploadedImagesIds.includes(uploadImages?.[index - 1]?.imageId);
    if (
      isUploaded ||
      (index > 0 && !isPreviousImageUploaded) ||
      currentlyUploadingId.current === image.imageId
    ) {
      return;
    }

    currentlyUploadingId.current = image.imageId;

    uploadImage()
      .then(() => setUploadPercent(null))
      .catch((error) => {
        if (!isCreateVehicle) {
          showNotification.error(error.response?.data?.errors?.[0]?.message || error.toString());
        }
        setUploadPercent(null);
      })
      .finally(() => {
        currentlyUploadingId.current = null;
      });
  }, [uploadedImagesIds, uploadImages, image]);

  return (
    <Box
      width="100%"
      height="auto"
      position="relative"
      ratio={ratio}
      borderRadius="xSmall"
      overflow="hidden"
    >
      <Show when={isNotNilOrEmpty(image.src)}>
        <Box
          width="100%"
          height="100%"
          position="relative"
          opacity={isUploaded ? 1 : 0.35}
          transition="opacity 0.3 ease-out"
        >
          <Center width="100%" height="100%" justify="center">
            <ImagePreview
              isImageRotationInProgress={isImageRotationInProgress}
              resizeUrl={image.src}
              onDelete={onDelete}
              onOpenClick={onOpenClick}
              ratio={ratio}
              data-testid={suffixTestId('uploadImage', rest)}
            />
          </Center>
        </Box>
        <Show when={isNotNil(uploadPercent)}>
          <Box position="absolute" left={0} bottom={0} right={0}>
            <ProgressBar percentage={uploadPercent! / 100} />
          </Box>
        </Show>
      </Show>
    </Box>
  );
};
