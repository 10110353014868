import {FlagProps, Parameter, showNotification} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {always, defaultTo, isNil} from 'ramda';
import {concatAll, isNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useGetDeliveryNoteQuery,
  useGetUserQuery,
  useGetWarehouseHeaderDynamicActionsQuery,
  usePutWarehouseHeaderDynamicActionsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {
  DetailTemplate,
  DetailTemplateHeader,
  getUserName,
  handleApiError,
  NavigationItem,
} from '@omnetic-dms/shared';

import {
  buildArray,
  composePath,
  EMPTY_PLACEHOLDER,
  generateHashFromObjects,
  Nullish,
  parseDate,
} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {createHeaderActions} from '../../utils/createHeaderActions';
import {createHeaderFlags} from '../../utils/createHeaderFlags';
import {Overview} from './(sections)/Overview/Overview';

export function DeliveryNoteDetail() {
  const {deliveryNoteId} = useWarehouseParams();

  const dateTimeFormatter = useDateTimeFormatter();

  const {
    data: deliveryNote,
    isLoading: isDeliveryNoteLoading,
    isError: isDeliveryNoteError,
  } = useGetDeliveryNoteQuery({deliveryNoteId}, {skip: isNilOrEmpty(deliveryNoteId)});

  const {
    data: deliveryNoteActions,
    isLoading: isDeliveryNoteActionsLoading,
    isError: isDeliveryNoteActionsError,
  } = useGetWarehouseHeaderDynamicActionsQuery(
    {id: deliveryNoteId, resource: 'delivery-note'},
    {skip: isNilOrEmpty(deliveryNoteId)}
  );

  const {data: createdByUser, isLoading: isCreatedByUserLoading} = useGetUserQuery(
    {id: deliveryNote?.createdBy!},
    {skip: isNil(deliveryNote?.createdBy)}
  );

  const {data: updatedByUser, isLoading: isUpdatedByUserLoading} = useGetUserQuery(
    {id: deliveryNote?.updatedBy!},
    {skip: isNil(deliveryNote?.updatedBy)}
  );

  const [putDeliveryNoteAction, {isLoading: isPutDeliveryNoteActionLoading}] =
    usePutWarehouseHeaderDynamicActionsMutation();

  const isLoading =
    isDeliveryNoteLoading ||
    isDeliveryNoteActionsLoading ||
    isCreatedByUserLoading ||
    isUpdatedByUserLoading;
  const hasError = isDeliveryNoteError || isDeliveryNoteActionsError;

  const isDraftDeliveryNote = isNil(deliveryNote);
  const isPendingDeliveryNote = deliveryNote?.state === 'PENDING';
  const isReceivedDeliveryNote = deliveryNote?.state === 'RECEIVED';
  const isCompletedDeliveryNote = deliveryNote?.state === 'COMPLETED';

  const formatToShortDate = (date: string | Nullish) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return dateTimeFormatter('dateShort', parseDate(date));
  };

  const createdBy = `${i18n.t('general.labels.createdBy')}: ${getUserName(createdByUser) ?? EMPTY_PLACEHOLDER}`;
  const createdAt = `${i18n.t('general.labels.createdAt')}: ${formatToShortDate(deliveryNote?.created)}`;

  const updatedBy = `${i18n.t('general.labels.updatedBy')}: ${getUserName(updatedByUser) ?? EMPTY_PLACEHOLDER}`;
  const updatedAt = `${i18n.t('general.labels.updatedAt')}: ${formatToShortDate(deliveryNote?.updated)}`;

  const completedBy = `${i18n.t('general.labels.completedBy')}: ${isCompletedDeliveryNote ? getUserName(updatedByUser) : EMPTY_PLACEHOLDER}`;
  const completedAt = `${i18n.t('general.labels.completedAt')}: ${isCompletedDeliveryNote ? formatToShortDate(deliveryNote?.updated) : EMPTY_PLACEHOLDER}`;

  const headerTitle = `${i18n.t('entity.warehouse.labels.deliveryNote')} ${defaultTo(
    '',
    deliveryNote?.deliveryNoteNumber
  )}`;

  const headerParameters = match(isDraftDeliveryNote)
    .returnType<Parameter[]>()
    .with(true, always([]))
    .otherwise(always([createdBy, createdAt, updatedBy, updatedAt, completedBy, completedAt]));

  const markersFlags = createHeaderFlags(deliveryNote?.markers);

  const staticFlags = buildArray<FlagProps>()
    .when(isPendingDeliveryNote, {colorScheme: 'blue', label: i18n.t('general.labels.pending')})
    .when(isReceivedDeliveryNote, {colorScheme: 'blue', label: i18n.t('general.labels.received')})
    .when(isCompletedDeliveryNote, {
      colorScheme: 'green',
      label: i18n.t('general.labels.completed'),
    });

  const flags = concatAll<FlagProps[]>([staticFlags, markersFlags]);

  const handleActionClick = (actionKey: string) => {
    putDeliveryNoteAction({id: deliveryNoteId, resource: 'delivery-note', body: {actionKey}})
      .unwrap()
      .then(() => {
        showNotification.success();
      })
      .catch(handleApiError);
  };

  const headerActions = createHeaderActions({
    actions: deliveryNoteActions?.actions,
    callback: handleActionClick,
    isLoading: isPutDeliveryNoteActionLoading,
  });

  const header: DetailTemplateHeader = {
    title: headerTitle,
    icon: 'action/deployed',
    parameters: headerParameters,
    recordId: deliveryNote?.deliveryNoteId,
    resourceId: EntityResourceIds.deliveryNote,
    flags,
    actions: headerActions,
    controls: ['ASSIGNEE'],
  };

  const navigation: NavigationItem[] = [
    {
      id: 'deliveryNoteDetail',
      label: i18n.t('page.warehouse.labels.overview'),
      href: composePath(warehouseRoutes.deliveryNoteDetailOverview, {
        params: {id: deliveryNoteId},
      }),
      content: (
        <Overview
          deliveryNote={deliveryNote}
          isLoading={isDeliveryNoteLoading}
          hasError={isDeliveryNoteError}
          data-testid={testIds.warehouse.deliveryNoteDetailOverview('sections.overview')}
        />
      ),
      'data-testid': testIds.warehouse.deliveryNoteDetailOverview('navigation.overview'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('entity.warehouse.labels.deliveryNote')} />
      <DetailTemplate
        key={generateHashFromObjects(deliveryNote)}
        isLoading={isLoading}
        isError={hasError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.deliveryNoteDetailOverview('page')}
      />
    </>
  );
}
