import {Link, Text} from 'platform/foundation';
import styled, {css} from 'styled-components';
import {match} from 'ts-pattern';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {getHighlightsFromText} from './utils/getHighlightsFromText';

interface MentionsTextProps extends TestIdProps {
  value: string | Nullish;
}

export function MentionsText(props: MentionsTextProps) {
  const textHighlights = getHighlightsFromText(props.value ?? '');

  return (
    <TextWrapper>
      <Text size="small" data-testid={suffixTestId('text', props)}>
        {textHighlights?.map((value, index) =>
          match(value.type)
            .with('text', () => value.text)
            .with('user', () => (
              <span
                key={value.text}
                css={css`
                  background-color: ${({theme}) => theme.colors.palettes.blue[30][100]};
                `}
                data-testid={suffixTestId(`user-${index}`, props)}
              >
                {value.text}
              </span>
            ))
            .with('link', () => (
              <Link
                key={value.text}
                size="small"
                href={value.text}
                target="_blank"
                title={value.text}
                data-testid={suffixTestId(`link-${index}`, props)}
              />
            ))
            .with('email', () => (
              <Link
                key={value.text}
                size="small"
                href={`mailto:${value.text}`}
                title={value.text}
                data-testid={suffixTestId(`email-${index}`, props)}
              />
            ))
            .exhaustive()
        )}
      </Text>
    </TextWrapper>
  );
}

const TextWrapper = styled.div`
  white-space: pre-line;
`;
