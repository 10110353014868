import {Alert, FormControl} from 'platform/components';

import {useWatch} from 'react-hook-form';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetCashRegisterQuery} from '@omnetic-dms/api';
import {testIds} from '@omnetic-dms/routes';

import {useDocumentAlertProps} from '../hooks/useDocumentLimitAlertProps';
import {AccountingDocumentFormValues} from '../types';

interface CashRegisterAlertsProps {
  type: 'income' | 'expense';
  control: FormControl<AccountingDocumentFormValues>;
}

export function CashRegisterAlerts(props: CashRegisterAlertsProps) {
  const cashRegisterId = useWatch({control: props.control, name: 'cashRegisterId'});
  const {data: cashRegister} = useGetCashRegisterQuery(
    {cashRegisterId: cashRegisterId ?? ''},
    {skip: isNilOrEmpty(cashRegisterId)}
  );

  const [documentLimitAlertProps] = useDocumentAlertProps(props.type, cashRegister?.currency);

  if (isNil(documentLimitAlertProps)) {
    return null;
  }

  return (
    <Alert
      {...documentLimitAlertProps}
      data-testid={testIds.accounting.createCashReceiptIncome(
        `alert-${documentLimitAlertProps.variant}`
      )}
    />
  );
}
