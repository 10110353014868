import {startOfToday} from 'date-fns';
import {object} from 'yup';

import {defaultTo} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {precisionCalculation, yupDate, yupNumber, yupString} from 'shared';

export const reservationFormSchema = (isNoteRequired: boolean, dispensingUnit: number) =>
  object({
    quantity: yupNumber
      .min(0.0001, (params) => i18n.t('general.errors.number.greaterOrEqual', {min: params.min}))
      .test(
        'isQuantityDivisibleByDispensingUnit',
        `${i18n.t(
          'entity.warehouse.labels.quantityMustBeMultipleOfDispensingUnit'
        )} (${dispensingUnit})`,
        (value) => precisionCalculation.modulo(defaultTo(0, value), dispensingUnit) === 0
      )
      .required(),
    requestExpiresAtDate: yupDate.min(
      startOfToday(),
      i18n.t('entity.warehouse.notifications.dateCantBeSetToPast')
    ),
    requestExpiresAtTime: yupString,
    note: isNoteRequired ? yupString.required() : yupString,
  });
