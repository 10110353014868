import {useCallback, useEffect, useRef} from 'react';

import {BaseFunction} from '../types/BaseFunction';

const getRemainingTime = (lastTriggeredTime: number, throttleMs: number) => {
  const elapsedTime = Date.now() - lastTriggeredTime;
  const remainingTime = throttleMs - elapsedTime;

  return remainingTime < 0 ? 0 : remainingTime;
};

/**
 * Creates a throttled version of a callback function that will only be invoked once
 * within a specified period defined by `throttleMs`. This is useful for limiting
 * the rate at which a function can be called.
 *
 * @template T - A function type that extends from `BaseFunction`.
 * @param {T} callbackFn - The callback function to throttle. This function will be
 *                         called with the arguments provided to the throttled function.
 * @param {number} throttleMs - The time, in milliseconds, to throttle calls to `callbackFn`.
 * @returns {T} A throttled version of `callbackFn` that adheres to the specified `throttleMs`
 *              timing. Calling this returned function multiple times within the throttle
 *              period will only result in a single invocation of `callbackFn` at the end
 *              of the period.
 *
 * @example
 * const handleScroll = useThrottledCallback((e) => {
 *   console.log('Scroll event', e);
 * }, 100);
 *
 * useEffect(() => {
 *   window.addEventListener('scroll', handleScroll);
 *   return () => window.removeEventListener('scroll', handleScroll);
 * }, [handleScroll]);
 */
export function useThrottledCallback<T extends BaseFunction>(callbackFn: T, throttleMs: number): T {
  const lastTriggered = useRef<number>(Date.now());
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const cancel = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  useEffect(() => cancel, [cancel]);

  return useCallback(
    <T>(args?: T) => {
      let remainingTime = getRemainingTime(lastTriggered.current, throttleMs);

      if (remainingTime === 0) {
        lastTriggered.current = Date.now();
        callbackFn(args);
        cancel();
      } else if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(() => {
          remainingTime = getRemainingTime(lastTriggered.current, throttleMs);

          if (remainingTime === 0) {
            lastTriggered.current = Date.now();
            callbackFn(args);
            cancel();
          }
        }, remainingTime);
      }
    },
    [callbackFn, cancel]
  ) as T;
}
