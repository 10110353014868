import {MouseEventHandler} from 'react';

import {omit} from 'ramda';

import {TestIdProps} from 'shared';

import {Button, ButtonProps} from '../../Button/Button';
import {Tooltip, TooltipProps} from '../../Tooltip/Tooltip';

export interface ButtonWithTooltipActionProps extends Omit<ButtonProps, 'type'>, TestIdProps {
  type: 'buttonWithTooltip';
  title: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buttonType?: ButtonProps['type'];
  tooltip: Omit<TooltipProps, 'children'>;
}

export function ButtonWithTooltipAction(props: ButtonWithTooltipActionProps) {
  const buttonProps = withoutNonButtonProps(props);
  const tooltipProps = props.tooltip;

  return (
    <Tooltip data-testid={props['data-testid']} {...tooltipProps}>
      <Button {...buttonProps} data-testid={props['data-testid']} type={props.buttonType} />
    </Tooltip>
  );
}

const withoutNonButtonProps = omit(['type', 'tooltip']);
