import {IRowNode} from '@ag-grid-community/core';
import {TooltipProps} from 'platform/components';

import {useMemo} from 'react';

import {RowData} from '../types/RowData';
import {TooltipGetter} from '../types/TooltipGetter';

export const useTooltipProps = (
  data: RowData,
  node: IRowNode,
  propsGetter?: TooltipGetter
): TooltipProps => {
  const tooltipProps = useMemo<TooltipProps>(
    () => (propsGetter ? propsGetter({data, node}) : ({} as TooltipProps)),
    [propsGetter, data, node]
  );

  const isDisabled = useMemo<boolean>(() => {
    if (tooltipProps.isDisabled) {
      return true;
    }
    const haveContent = tooltipProps.label !== undefined || tooltipProps.description !== undefined;
    return !haveContent;
  }, [tooltipProps]);

  return {
    openDelay: 500,
    ...tooltipProps,
    isDisabled,
  };
};
