import {ThemeIconKey, Icon, isTouchable, Show, Hide, Spinner, Center} from 'platform/foundation';
import {css} from 'styled-components';
import {match} from 'ts-pattern';

import {MouseEventHandler} from 'react';

import {always} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

const OPACITY = {
  DEFAULT: 0.6,
  LOADING: 0.4,
  DISABLED: 0.2,
};

export interface IconButtonProps extends RequiredTestIdProps {
  icon: ThemeIconKey;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export function IconButton(props: IconButtonProps) {
  const isTouchableDevice = isTouchable();

  const isDisabledOrLoading = props.isDisabled || props.isLoading;

  return (
    <button
      data-testid={suffixTestId('iconButton', props)}
      onClick={props.onClick}
      disabled={isDisabledOrLoading}
      type="button"
      css={css`
        gap: ${({theme}) => theme.getSize(2)};
        padding: ${({theme}) => theme.getSize(2)};
        height: 100%;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: ${isDisabledOrLoading ? 'not-allowed' : 'pointer'};
        pointer-events: ${isDisabledOrLoading ? 'none' : 'all'};
        color: ${({theme}) => theme.colors.general.white};
        background-color: ${({theme}) => theme.colors.palettes.black['900']['70']};
        border: none;
        opacity: ${match({isDisabled: props.isDisabled, isLoading: props.isLoading})
          .with({isDisabled: true}, always(OPACITY.DISABLED))
          .with({isLoading: true}, always(OPACITY.LOADING))
          .otherwise(always(OPACITY.DEFAULT))};
        transition: opacity 0.3s ease-out;

        ${!isTouchableDevice &&
        `
          &:hover {
            opacity: 1;
          }
        `}
      `}
    >
      <Show when={props.isLoading}>
        <Center height={5} width={5}>
          <Spinner color="light" size="small" />
        </Center>
      </Show>
      <Hide when={props.isLoading}>
        <Icon color="general.white" value={props.icon} size={5} />
      </Hide>
    </button>
  );
}
