import {Action, Attributes, AttributesRow, Card, DataStatus, Separator} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetManufacturerQuery,
  useGetTreeFolderPathQuery,
  useGetWarehouseAccountQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';

import {Nullish, TestIdProps, composePath, suffixTestId} from 'shared';

import {ArticleDetailCardPrices} from '../../../../../components/ArticleDetailCardPrices';
import {ArticlePricingDetails} from '../../../../../types/ArticlePricingDetails';
import {ReceiveNoteItemDetails} from '../../../../../types/ReceiveNote';

interface ArticleDetailCardProps extends TestIdProps {
  articleDetail: ReceiveNoteItemDetails['articleDetail'] | Nullish;
  prices: ArticlePricingDetails | null;
  currency: string | undefined;
  isDisabled: boolean;
  isExpanded: boolean;
  isRefreshingDetails: boolean;
  onRefresh: VoidFunction;
}

export function ArticleDetailCard(props: ArticleDetailCardProps) {
  const warehouseId = props.articleDetail?.warehouseId;

  const articleId = props.articleDetail?.articleId;

  const name = props.articleDetail?.name;

  const manufacturerNumber = props.articleDetail?.manufacturerNumber;

  const manufacturerId = props.articleDetail?.manufacturerId;

  const warehouseAccountId = props.articleDetail?.warehouseAccountId;

  const storageLocation = props.articleDetail?.storageLocation;

  const leafId = props.articleDetail?.leafId;

  const {data: manufacturer, isLoading: isManufacturerLoading} = useGetManufacturerQuery(
    {manufacturerId: manufacturerId as string},
    {skip: isNilOrEmpty(manufacturerId)}
  );

  const {data: warehouseAccount, isLoading: isWarehouseAccountLoading} =
    useGetWarehouseAccountQuery(
      {warehouseAccountId: warehouseAccountId as string},
      {skip: isNilOrEmpty(warehouseAccountId)}
    );

  const {data: category, isLoading: isCategoryLoading} = useGetTreeFolderPathQuery(
    {leafId: leafId as string},
    {skip: isNilOrEmpty(leafId)}
  );

  const isLoading =
    props.isRefreshingDetails ||
    isManufacturerLoading ||
    isWarehouseAccountLoading ||
    isCategoryLoading;

  const isItemExisting = isNotNil(articleId);

  const handleEditArticle = () => {
    window.open(
      composePath(warehouseRoutes.articleDetailOverview, {
        params: {warehouseId, id: articleId},
      }),
      '_blank'
    );
  };

  const actions: Action[] = [
    {
      type: 'button',
      variant: 'link',
      title: i18n.t('general.actions.refresh'),
      leftIcon: 'navigation/refresh',
      onClick: props.onRefresh,
    },
    {
      type: 'button',
      variant: 'link',
      title: i18n.t('entity.warehouse.actions.editArticle'),
      leftIcon: 'action/open_in_new',
      onClick: handleEditArticle,
    },
  ];

  const rows: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.name'),
      value: name,
    },
    {
      label: i18n.t('entity.warehouse.labels.catalogueNumber'),
      value: manufacturerNumber,
    },
    {
      label: i18n.t('entity.warehouse.labels.manufacturer'),
      value: manufacturer?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.warehouseAccount'),
      value: warehouseAccount?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.storageLocation'),
      value: storageLocation,
    },
    {
      label: i18n.t('entity.warehouse.labels.category'),
      value: category?.treeFolder?.pathName,
    },
  ];

  return (
    <Card
      variant="inlineGrey"
      title={i18n.t('entity.warehouse.labels.articleDetail')}
      actions={isItemExisting ? actions : undefined}
      isClosedByDefault={props.isDisabled}
      isExpandable={props.isExpanded}
      data-testid={props['data-testid']}
    >
      <VStack>
        <DataStatus isLoading={isLoading} minHeight={70}>
          <Attributes rows={rows} data-testid={suffixTestId('articleDetailAttributes', props)} />
          <Separator spacing={3} />
          <ArticleDetailCardPrices prices={props.prices} currency={props.currency} />
        </DataStatus>
      </VStack>
    </Card>
  );
}
