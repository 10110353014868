import {
  DeletePostponeJobApiArg,
  DeletePostponeJobApiArgSchema,
  DeletePostponeJobApiResponse,
  DeletePostponeJobApiResponseSchema,
  DeletePostponeJobsApiApiArg,
  DeletePostponeJobsApiApiArgSchema,
  DeletePostponeJobsApiApiResponse,
  DeletePostponeJobsApiApiResponseSchema,
  GetPostponeJobApiArg,
  GetPostponeJobApiArgSchema,
  GetPostponeJobApiResponse,
  GetPostponeJobApiResponseSchema,
  GetPostponeJobsByVehicleIdApiArg,
  GetPostponeJobsByVehicleIdApiArgSchema,
  GetPostponeJobsByVehicleIdApiResponse,
  GetPostponeJobsByVehicleIdApiResponseSchema,
  PatchPostponeJobApiArg,
  PatchPostponeJobApiArgSchema,
  PatchPostponeJobApiResponse,
  PatchPostponeJobApiResponseSchema,
  PostPostponeJobApiArg,
  PostPostponeJobApiArgSchema,
  PostPostponeJobApiResponse,
  PostPostponeJobApiResponseSchema,
  PostPostponeJobPostponeUntilApiApiArg,
  PostPostponeJobPostponeUntilApiApiArgSchema,
  PostPostponeJobPostponeUntilApiApiResponse,
  PostPostponeJobPostponeUntilApiApiResponseSchema,
} from '../types/metadaWorkshopPostponeJob';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopPostponeJobApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postPostponeJob: build.mutation<PostPostponeJobApiResponse, PostPostponeJobApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/postpone-job/job/${queryArg.serviceJobId}`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'postponeJobs', id: queryArg.serviceCaseId},
      ],
      extraOptions: {
        responseSchema: PostPostponeJobApiResponseSchema,
        requestSchema: PostPostponeJobApiArgSchema,
      },
    }),
    getPostponeJobsByVehicleId: build.query<
      GetPostponeJobsByVehicleIdApiResponse,
      GetPostponeJobsByVehicleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/postpone-job/vehicle/${queryArg.vehicleId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'postponeJobs', id: queryArg.vehicleId}],
      extraOptions: {
        responseSchema: GetPostponeJobsByVehicleIdApiResponseSchema,
        requestSchema: GetPostponeJobsByVehicleIdApiArgSchema,
      },
    }),
    getPostponeJob: build.query<GetPostponeJobApiResponse, GetPostponeJobApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/postpone-job/${queryArg.postponeJobId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'postponeJob', id: queryArg.postponeJobId},
      ],
      extraOptions: {
        responseSchema: GetPostponeJobApiResponseSchema,
        requestSchema: GetPostponeJobApiArgSchema,
      },
    }),
    deletePostponeJob: build.mutation<DeletePostponeJobApiResponse, DeletePostponeJobApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/postpone-job/${queryArg.postponeJobId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'postponeJob', id: queryArg.postponeJobId},
        {type: 'postponeJobs', id: queryArg.serviceCaseId || queryArg.vehicleId},
      ],
      extraOptions: {
        responseSchema: DeletePostponeJobApiResponseSchema,
        requestSchema: DeletePostponeJobApiArgSchema,
      },
    }),
    patchPostponeJob: build.mutation<PatchPostponeJobApiResponse, PatchPostponeJobApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/postpone-job/${queryArg.postponeJobId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'postponeJob', id: queryArg.postponeJobId},
        {type: 'postponeJobs', id: queryArg.serviceCaseId || queryArg.vehicleId},
      ],
      extraOptions: {
        responseSchema: PatchPostponeJobApiResponseSchema,
        requestSchema: PatchPostponeJobApiArgSchema,
      },
    }),
    deletePostponeJobs: build.mutation<
      DeletePostponeJobsApiApiResponse,
      DeletePostponeJobsApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/postpone-job/delete`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: DeletePostponeJobsApiApiResponseSchema,
        requestSchema: DeletePostponeJobsApiApiArgSchema,
      },
    }),
    postPostponeJobPostponeUntil: build.mutation<
      PostPostponeJobPostponeUntilApiApiResponse,
      PostPostponeJobPostponeUntilApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/postpone-job/postpone-until`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'postponeJob', id: queryArg.body?.postponeJobId[0]},
        {type: 'postponeJobs', id: queryArg.serviceCaseId || queryArg.vehicleId},
      ],
      extraOptions: {
        responseSchema: PostPostponeJobPostponeUntilApiApiResponseSchema,
        requestSchema: PostPostponeJobPostponeUntilApiApiArgSchema,
      },
    }),
  }),
});

export const {
  usePostPostponeJobMutation,
  useGetPostponeJobsByVehicleIdQuery,
  useGetPostponeJobQuery,
  useDeletePostponeJobMutation,
  usePatchPostponeJobMutation,
  useDeletePostponeJobsMutation,
  usePostPostponeJobPostponeUntilMutation,
} = metadaWorkshopPostponeJobApi;
