import {Card} from 'platform/components';
import {Box} from 'platform/foundation';
import {css} from 'styled-components';

import {FC} from 'react';

import i18n from '@omnetic-dms/i18n';
import {useFormRenderer} from '@omnetic-dms/teas';

export const AuditSettingsInspectionHeader: FC = () => {
  const {Field} = useFormRenderer();

  return (
    <Box paddingBottom={4}>
      <Card>
        <Field
          name="validationInspection"
          label={i18n.t`page.vehicleSettings.labels.validationInspection`}
          as="checkbox"
        />
        <Field
          name="showEmptyFields"
          label={i18n.t`page.vehicleSettings.labels.dontDisplayInDC`}
          as="checkbox"
        />
        <div
          css={css`
            margin-bottom: -16px;
          `}
        >
          <Field label={i18n.t`entity.condition.labels.inspectionName`} name="name" required />
        </div>
      </Card>
    </Box>
  );
};
