import {endOfYesterday, isSameDay} from 'date-fns';
import {
  Card,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  TextInput,
  showNotification,
} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';
import {date, object} from 'yup';

import {UseFormReturn} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {defaultTo, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  useCreateCashRegisterMutation,
  useGetCashRegisterQuery,
  useGetTenantQuery,
  usePatchCashRegisterMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {handleApiError, useBranches, useCurrencies} from '@omnetic-dms/shared';

import {
  Nullish,
  RequiredTestIdProps,
  getApiDateTimeString,
  parseDate,
  suffixTestId,
  yupString,
} from 'shared';

import {useSeriesOptions} from '../../hooks/useSeriesOptions';
import {SettingsFooter} from '../SettingsFooter/SettingsFooter';
import {SettingsSection} from '../SettingsSection/SettingsSection';

type FormValues = {
  name: string;
  code: string;
  currency: string;
  expenseSeriesId: string;
  incomeSeriesId: string;
  branchId: string;
  activeFrom: Date;
  cashRegisterAccount: string | null;
  eligibleForCardPayments: boolean | null;
  initialValue: string;
};

interface CashRegisterFormProps extends RequiredTestIdProps {
  cashRegisterId?: string | Nullish;
}

export function CashRegisterForm(props: CashRegisterFormProps) {
  const isCreating = isNil(props.cashRegisterId);
  const yesterdayDate = endOfYesterday();
  const navigate = useNavigate();
  const {groupedSeries, isSeriesError, isSeriesLoading} = useSeriesOptions([
    'accounting/cash_register_document',
  ]);

  const [patchCashRegister] = usePatchCashRegisterMutation();
  const [createCashRegister] = useCreateCashRegisterMutation();

  const {data: tenant} = useGetTenantQuery();
  const {
    data: cashRegister,
    isLoading: isCashRegisterLoading,
    isError: isCashRegisterError,
  } = useGetCashRegisterQuery({cashRegisterId: props.cashRegisterId ?? ''}, {skip: isCreating});
  const {branchOptions, isLoading: isBranchesLoading, isError: isBranchesError} = useBranches();

  const {currencyOptions} = useCurrencies();

  const isLoading = isCashRegisterLoading || isBranchesLoading || isSeriesLoading;
  const isError = isCashRegisterError || isBranchesError || isSeriesError;

  const goToCashRegisterList = () => navigate(settingsRoutes.cashRegisters);

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    const eligibleForCardPayments = defaultTo(false, data.eligibleForCardPayments);
    const activeFrom = getApiDateTimeString(data.activeFrom);

    if (isCreating) {
      await createCashRegister({
        createCashRegisterRequestBody: {
          ...data,
          eligibleForCardPayments,
          activeFrom,
        },
      })
        .unwrap()
        .then(goToCashRegisterList)
        .then(() => showNotification.success())
        .catch(handleApiError);
    } else {
      patchCashRegister({
        cashRegisterId: props.cashRegisterId ?? '',
        patchCashRegisterRequestBody: {
          ...data,
          eligibleForCardPayments,
          activeFrom: !isSameDay(data.activeFrom, parseDate(cashRegister?.activeFrom ?? ''))
            ? activeFrom
            : undefined,
        },
      })
        .unwrap()
        .then(goToCashRegisterList)
        .then(() => showNotification.success())
        .catch(handleApiError);
    }
  };

  const defaultValues = isNotNil(cashRegister)
    ? {
        ...cashRegister,
        activeFrom: parseDate(cashRegister?.activeFrom ?? ''),
      }
    : {
        currency: tenant?.currency,
        activeFrom: new Date(),
      };

  const onEligibleForCardPaymentsChange = (formApi: UseFormReturn<FormValues>) => (val: boolean) =>
    formApi.setValue('eligibleForCardPayments', val);

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<FormValues> defaultValues={defaultValues} schema={schema} onSubmit={handleSubmit}>
        {(control, formApi) => {
          const value = formApi.watch('eligibleForCardPayments') ?? false;

          return (
            <SettingsSection>
              <Grid columns={2}>
                <GridItem span={2}>
                  <FormField
                    control={control}
                    type="text"
                    name="name"
                    label={i18n.t('entity.cashRegister.parameters.description')}
                    isRequired
                    data-testid={suffixTestId('name', props)}
                  />
                </GridItem>

                <FormField
                  control={control}
                  type="text"
                  name="code"
                  label={i18n.t('entity.cashRegister.parameters.code')}
                  isRequired
                  data-testid={suffixTestId('code', props)}
                />

                <FormField
                  control={control}
                  type="choice"
                  name="currency"
                  isNotClearable
                  options={currencyOptions}
                  label={i18n.t('entity.cashRegister.parameters.currency')}
                  isRequired
                  data-testid={suffixTestId('currency', props)}
                />

                <TextInput
                  value="0"
                  isDisabled
                  label={i18n.t('entity.cashRegister.parameters.initialState')}
                  data-testid={suffixTestId('initialState', props)}
                />

                <FormField
                  control={control}
                  type="date"
                  name="activeFrom"
                  minDate={yesterdayDate}
                  label={i18n.t('entity.cashRegister.parameters.asOf')}
                  isRequired
                  data-testid={suffixTestId('date', props)}
                />

                <GridItem span={2}>
                  <FormField
                    control={control}
                    type="choice"
                    name="branchId"
                    isNotClearable
                    label={i18n.t('entity.cashRegister.parameters.branch')}
                    options={branchOptions}
                    isRequired
                    data-testid={suffixTestId('branch', props)}
                  />
                </GridItem>

                <GridItem span={2}>
                  <FormField
                    control={control}
                    type="text"
                    name="cashRegisterAccount"
                    label={i18n.t('entity.bankAccount.labels.number')}
                    maxLength={9}
                    data-testid={suffixTestId('cashRegisterAccount', props)}
                  />
                </GridItem>

                <FormField
                  control={control}
                  type="choice"
                  name="expenseSeriesId"
                  isRequired
                  isNotClearable
                  options={groupedSeries['accounting/cash_register_document']}
                  label={i18n.t('entity.cashRegister.parameters.expenseSeries')}
                  data-testid={suffixTestId('expenseSeries', props)}
                />

                <FormField
                  control={control}
                  type="choice"
                  name="incomeSeriesId"
                  isRequired
                  isNotClearable
                  options={groupedSeries['accounting/cash_register_document']}
                  label={i18n.t('entity.cashRegister.parameters.incomeSeries')}
                  data-testid={suffixTestId('incomeSeries', props)}
                />

                <GridItem span={2}>
                  <Card
                    title={i18n.t('entity.cashRegister.parameters.eligibleForCardPayments')}
                    control={{
                      type: 'switch',
                      value,
                      onChange: onEligibleForCardPaymentsChange(formApi),
                    }}
                    isExpanded={false}
                  />
                </GridItem>
                <SettingsFooter
                  actions={[
                    {
                      type: 'button',
                      title: i18n.t('general.actions.close'),
                      variant: 'secondary',
                      onClick: goToCashRegisterList,
                      'data-testid': suffixTestId('discard', props),
                    },
                    {
                      type: 'form-button',
                      control,
                      buttonType: 'submit',
                      title: isCreating
                        ? i18n.t('general.actions.create')
                        : i18n.t('general.actions.edit'),
                      'data-testid': suffixTestId('save', props),
                    },
                  ]}
                />
              </Grid>
            </SettingsSection>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const schema = object({
  name: yupString.required(),
  code: yupString
    .min(3, i18n.t('general.validations.minimumLength', {codeMinLength: 3}))
    .max(24, i18n.t('general.validations.maximumLength', {codeMaxLength: 24}))
    .required(),
  expenseSeriesId: yupString.required(),
  cashRegisterAccount: yupString
    .matches(/^\d+$/, i18n.t('general.validations.mustBeNumber'))
    .min(6, i18n.t('general.validations.minimumLength', {codeMinLength: 6}))
    .max(9, i18n.t('general.validations.maximumLength', {codeMaxLength: 9})),
  incomeSeriesId: yupString.required(),
  branchId: yupString.required(),
  activeFrom: date().required(),
  currency: yupString.required(),
});
