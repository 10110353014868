import {isNil} from 'ramda';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/api';

export const getVehiclePrice = ({
  vehicle,
}: {
  vehicle?: SourcingVehicleDetailResponseBody;
  forSimilarityListing?: boolean;
}) => {
  if (isNil(vehicle)) {
    return 0;
  }

  return vehicle.sourcingVehicle.price?.withVat ?? 0;
};
