import {
  Button,
  ButtonGroup,
  Dialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Space, Text} from 'platform/foundation';
import * as Yup from 'yup';

import {useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {CreateExternalUserApiArg, useCreateExternalUserMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {
  assignUsersRequest,
  clearExternalUser,
  createExternalUserSuccess,
} from '../../../store/assignment/reducer';
import {selectExternalUserLoading} from '../../../store/assignment/selectors';
import {CreateExternalUserRequestBody} from '../../../types/CreateExternalUserRequestBody';
import {User} from '../../../types/User';
import {TExternalEvent} from '../types/AssignmentType';
import {AssignmentContext} from './AssignmentContext';

export const AssignmentCreatePopup = ({
  email,
  modalEvent,
  ...rest
}: Partial<CreateExternalUserRequestBody> & TExternalEvent & TestIdProps) => {
  const [createExternalUser] = useCreateExternalUserMutation();
  const context = useContext(AssignmentContext);
  const loading: boolean = useSelector(selectExternalUserLoading);
  const dispatch = useDispatch();

  const onSubmit: FormSubmitHandler<{email: string}> = async (submitValue) => {
    const arg = {
      createExternalUserRequestBody: {
        ...submitValue,
        identity: {id: context.assignmentIdentityId, type: context.type},
      },
    } as CreateExternalUserApiArg;

    await createExternalUser(arg)
      .unwrap()
      .then((res) => {
        if (res && res?.id) {
          showNotification.success(i18n.t('general.notifications.success'));

          // eslint-disable-next-line no-restricted-syntax
          dispatch(createExternalUserSuccess(res as unknown as User));
          dispatch(
            assignUsersRequest({
              userId: res?.id,
              identity: {
                id: context.assignmentIdentityId,
                type: context.type,
              },
            })
          );

          closeModal();
        }
        closeModal();
      })
      .catch((error) => {
        if (error.status === 409) {
          showNotification.error(i18n.t('general.notifications.duplicateEmail'));
        } else {
          handleApiError(error);
        }
      });
  };

  const closeModal = () => {
    dispatch(clearExternalUser());
    modalEvent(false);
  };

  return (
    <>
      <Dialog
        isOpen
        onClose={closeModal}
        title={i18n.t('general.labels.assignTitle')}
        size="small"
        data-testid={suffixTestId('assignModal', rest)}
      >
        <Form<{email: string}>
          defaultValues={{email}}
          onSubmit={onSubmit}
          schema={FormSchema}
          shouldWatchForUnsavedChanges
        >
          {(control) => (
            <>
              <Text size="xSmall" color="tertiary">
                {i18n.t('page.assignment.notifications.assignDescription')}
              </Text>
              <Space vertical={4} />
              <FormField
                control={control}
                name="email"
                type="email"
                isRequired
                label={i18n.t('page.assignment.notifications.enterEmail')}
                data-testid={suffixTestId('email', rest)}
              />
              <Space vertical={4} />
              <ButtonGroup align="right">
                <Button
                  variant="secondary"
                  onClick={() => modalEvent()}
                  data-testid={suffixTestId('cancel', rest)}
                  title={i18n.t('general.actions.cancel')}
                />
                <FormButton
                  control={control}
                  type="submit"
                  isLoading={loading}
                  data-testid={suffixTestId('confirm', rest)}
                  title={i18n.t('general.actions.confirm')}
                />
              </ButtonGroup>
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};

const FormSchema = Yup.object({
  email: Yup.string().email().required(),
});
