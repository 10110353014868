import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {OrderResponseBody} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getCanAddAnotherPayment = (order: OrderResponseBody | Nullish) => {
  if (isNil(order)) {
    return false;
  }

  const payments = order.payments.filter((payment) => payment.paymentDiscriminator !== 'DEPOSIT');

  const isPurchaseOrder =
    order?.orderDiscriminator === 'PURCHASE' ||
    order?.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE';

  const paymentsInConceptState = payments.filter((payment) => payment.paymentState === 'CONCEPT');

  const canAddAnotherPurchasePayment =
    order?.addPurchasePaymentAllowed ||
    (isNotNilOrEmpty(payments) && paymentsInConceptState.length === 1);

  return isPurchaseOrder && canAddAnotherPurchasePayment;
};
