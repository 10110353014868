import {
  CancelOrderApiArg,
  CancelOrderApiRes,
  CreatePaymentFileApiArg,
  CreatePaymentFileApiRes,
  DeletePaymentFileApiArg,
  DeletePaymentFileApiRes,
  GetOrderApiArg,
  GetOrderApiRes,
  PostDepositPaymentApiArg,
  PostDepositPaymentApiRes,
  RecordPaymentApiArg,
  RecordPaymentApiRes,
  WithdrawalPaymentApiArg,
  WithdrawalPaymentApiRes,
  WithdrawalPurchasePaymentApiArg,
} from '../types/businessCase';
import {
  AddCheckoutOrderPurchasePaymentApiArg,
  AddCheckoutOrderPurchasePaymentApiResponse,
  AllowDepositApiArg,
  AllowDepositApiRes,
  ChangeTypeOfSaleCheckoutOrderApiArg,
  ChangeTypeOfSaleCheckoutOrderApiResponse,
  CreateBrokerageInternalInvoiceDocumentDocumentApiArg,
  CreateBrokerageInternalInvoiceDocumentDocumentApiResponse,
  CreateBrokerageInternalInvoiceDocumentWithVatDocumentApiArg,
  CreateBrokerageInternalInvoiceDocumentWithVatDocumentApiResponse,
  CreateBusinessCaseProformaInvoiceSettingApiArg,
  CreateBusinessCaseProformaInvoiceSettingApiResponse,
  CreateCheckoutOrderAdditionalCustomerApiArg,
  CreateCheckoutOrderAdditionalCustomerApiResponse,
  CreateDueDateSettingApiArg,
  CreateDueDateSettingApiResponse,
  CreateInternalCorrectiveTaxDocumentDocumentApiArg,
  CreateInternalCorrectiveTaxDocumentDocumentApiResponse,
  CreateInternalInvoiceDocumentDocumentApiArg,
  CreateInternalInvoiceDocumentDocumentApiResponse,
  CreateInternalInvoiceDocumentWithVatDocumentApiArg,
  CreateInternalInvoiceDocumentWithVatDocumentApiResponse,
  DeleteBusinessCaseProformaInvoiceSettingApiArg,
  DeleteBusinessCaseProformaInvoiceSettingApiResponse,
  DeleteCheckoutOrderAdditionalCustomerApiArg,
  DeleteCheckoutOrderAdditionalCustomerApiResponse,
  DeleteCheckoutOrderPurchasePaymentApiArg,
  DeleteCheckoutOrderPurchasePaymentApiResponse,
  DeleteDepositApiArg,
  DeleteDepositApiRes,
  DeleteDueDateSettingApiArg,
  DeleteDueDateSettingApiResponse,
  ForbitDepositApiArg,
  ForbitDepositApiRes,
  GetBrokerageInternalInvoiceDocumentTemplatesApiArg,
  GetBrokerageInternalInvoiceDocumentTemplatesApiResponse,
  GetBrokerageInternalInvoiceDocumentWithVatTemplatesApiArg,
  GetBrokerageInternalInvoiceDocumentWithVatTemplatesApiResponse,
  GetBusinessCaseProformaInvoiceSettingApiArg,
  GetBusinessCaseProformaInvoiceSettingApiResponse,
  GetCheckoutApiArg,
  GetCheckoutApiResponse,
  GetCheckoutDocumentNoteApiArg,
  GetCheckoutDocumentNoteApiResponse,
  GetCheckoutOffsettingOfLiabilitiesAndReceivablesApiArg,
  GetCheckoutOffsettingOfLiabilitiesAndReceivablesApiResponse,
  GetCheckoutPurchaseBrokerageTemplatesApiArg,
  GetCheckoutPurchaseBrokerageTemplatesApiResponse,
  GetCheckoutPurchaseTemplatesApiResponse,
  GetCheckoutSaleBrokerageContractTemplatesApiArg,
  GetCheckoutSaleBrokerageContractTemplatesApiResponse,
  GetCheckoutSaleTemplatesApiResponse,
  GetDueDateSettingApiArg,
  GetDueDateSettingApiResponse,
  GetInternalCorrectiveTaxDocumentTemplatesApiResponse,
  GetInternalInvoiceDocumentTemplatesApiArg,
  GetInternalInvoiceDocumentTemplatesApiResponse,
  GetInternalInvoiceDocumentWithVatTemplatesApiArg,
  GetInternalInvoiceDocumentWithVatTemplatesApiResponse,
  GetListBusinessCaseProformaInvoiceSettingApiArg,
  GetListBusinessCaseProformaInvoiceSettingApiResponse,
  GetListDueDateSettingApiArg,
  GetListDueDateSettingApiResponse,
  GetOffsettingOfLiabilitiesAndReceivablesTemplatesApiArg,
  GetOffsettingOfLiabilitiesAndReceivablesTemplatesApiResponse,
  GetPurchaseTemplatesApiArg,
  GetSaleTemplatesApiArg,
  IssueCheckoutOrderPaymentApiArg,
  IssueCheckoutOrderPaymentApiResponse,
  MergeCheckoutOrderApiArg,
  MergeCheckoutOrderApiResponse,
  PatchCheckoutOrderAdditionalCustomerApiArg,
  PatchCheckoutOrderAdditionalCustomerApiResponse,
  PatchCheckoutOrderForeignCurrencyPaymentApiArg,
  PatchCheckoutOrderForeignCurrencyPaymentApiResponse,
  PatchCheckoutOrderPaymentApiArg,
  PatchCheckoutOrderPaymentApiResponse,
  PutCheckoutDocumentNoteApiArg,
  PutCheckoutDocumentNoteApiResponse,
  PutCheckoutDocumentsApiArg,
  PutCheckoutDocumentsApiRes,
  RecordPaidCheckoutOrderPaymentApiArg,
  RecordPaidCheckoutOrderPaymentApiResponse,
  SplitCheckoutOrderApiArg,
  SplitCheckoutOrderApiResponse,
  UpdateBusinessCaseInvoiceSettingApiArg,
  UpdateBusinessCaseInvoiceSettingApiResponse,
  UpdateDueDateSettingApiArg,
  UpdateDueDateSettingApiResponse,
  WithdrawCheckoutOrderPaymentByCashRegisterDocumentApiArg,
  WithdrawCheckoutOrderPaymentByCashRegisterDocumentApiResponse,
} from '../types/checkout';
import {omneticApi} from './baseApi/omneticApi';

export const checkoutApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    splitCheckoutOrder: build.mutation<SplitCheckoutOrderApiResponse, SplitCheckoutOrderApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/split`,
        method: 'PATCH',
        body: queryArg.orderSplitRequestBody,
      }),
      invalidatesTags: ['Checkout', 'offer'],
    }),
    getCheckout: build.query<GetCheckoutApiResponse, GetCheckoutApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}`,
      }),
      providesTags: ['Checkout'],
    }),
    mergeCheckoutOrder: build.mutation<MergeCheckoutOrderApiResponse, MergeCheckoutOrderApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/merge/${queryArg.targetOrderId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Checkout', 'offer'],
    }),
    patchCheckoutOrderForeignCurrencyPayment: build.mutation<
      PatchCheckoutOrderForeignCurrencyPaymentApiResponse,
      PatchCheckoutOrderForeignCurrencyPaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/foreign-currency-payment`,
        method: 'PATCH',
        body: queryArg.orderForeignCurrencyPaymentRequestBody,
      }),
      invalidatesTags: (result, error, queryArgs) => [
        'Checkout',
        {type: 'offer', id: queryArgs.orderId},
      ],
    }),
    patchCheckoutOrderPayment: build.mutation<
      PatchCheckoutOrderPaymentApiResponse,
      PatchCheckoutOrderPaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/payment/${queryArg.paymentId}`,
        method: 'PATCH',
        body: queryArg.orderPaymentRequestBody,
      }),
      invalidatesTags: (result, error, queryArgs) => [
        'Checkout',
        {type: 'offer', id: queryArgs.orderId},
      ],
    }),
    issueCheckoutOrderPayment: build.mutation<
      IssueCheckoutOrderPaymentApiResponse,
      IssueCheckoutOrderPaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/payment/${queryArg.paymentId}/issue`,
        method: 'PUT',
        body: queryArg.issueCheckoutOrderPaymentRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        {type: 'Checkout'},
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        {type: 'documentsCount', id: queryArg.businessCaseId},
      ],
    }),
    recordPaidCheckoutOrderPayment: build.mutation<
      RecordPaidCheckoutOrderPaymentApiResponse,
      RecordPaidCheckoutOrderPaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/payment/${queryArg.paymentId}/recordPaid`,
        method: 'POST',
        body: queryArg.orderPaymentRecordPaidRequestBody,
      }),
      invalidatesTags: (result, error, queryArgs) => [
        {type: 'offer', id: queryArgs.orderId},
        'Checkout',
        'invoiceProformaPaymentList',
        'BusinessCaseDetail',
        'BusinessCaseActions',
        'documentsCount',
      ],
    }),
    addCheckoutOrderPurchasePayment: build.mutation<
      AddCheckoutOrderPurchasePaymentApiResponse,
      AddCheckoutOrderPurchasePaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/purchase-payment`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArgs) => [
        {type: 'offer', id: queryArgs.orderId},
        'Checkout',
      ],
    }),
    deleteCheckoutOrderPurchasePayment: build.mutation<
      DeleteCheckoutOrderPurchasePaymentApiResponse,
      DeleteCheckoutOrderPurchasePaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/purchase-payment/${queryArg.paymentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        {type: 'Checkout'},
      ],
    }),
    createCheckoutOrderAdditionalCustomer: build.mutation<
      CreateCheckoutOrderAdditionalCustomerApiResponse,
      CreateCheckoutOrderAdditionalCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/additional-customer`,
        method: 'POST',
        body: queryArg.createAdditionalCustomerRequestBody,
      }),
      invalidatesTags: ['Checkout', 'documentsCount'],
    }),
    deleteCheckoutOrderAdditionalCustomer: build.mutation<
      DeleteCheckoutOrderAdditionalCustomerApiResponse,
      DeleteCheckoutOrderAdditionalCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/additional-customer/${queryArg.additionalCustomerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Checkout'],
    }),
    patchCheckoutOrderAdditionalCustomer: build.mutation<
      PatchCheckoutOrderAdditionalCustomerApiResponse,
      PatchCheckoutOrderAdditionalCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/additional-customer/${queryArg.additionalCustomerId}`,
        method: 'PATCH',
        body: queryArg.patchAdditionalCustomerRequestBody,
      }),
      invalidatesTags: ['Checkout'],
    }),
    createBrokerageInternalInvoiceDocumentDocument: build.mutation<
      CreateBrokerageInternalInvoiceDocumentDocumentApiResponse,
      CreateBrokerageInternalInvoiceDocumentDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/document/brokerage-internal-invoice-document`,
        method: 'PUT',
        body: queryArg.internalInvoiceDocumentRequestBody,
      }),
      invalidatesTags: (result, error, queryArgs) => [
        {type: 'offer', id: queryArgs.internalInvoiceDocumentRequestBody.orderId},
        'Checkout',
        'documentsCount',
      ],
    }),
    createBrokerageInternalInvoiceDocumentWithVatDocument: build.mutation<
      CreateBrokerageInternalInvoiceDocumentWithVatDocumentApiResponse,
      CreateBrokerageInternalInvoiceDocumentWithVatDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/document/brokerage-internal-invoice-document-with-vat`,
        method: 'PUT',
        body: queryArg.internalInvoiceDocumentRequestBody,
      }),
      invalidatesTags: (result, error, queryArgs) => [
        {type: 'offer', id: queryArgs.internalInvoiceDocumentRequestBody.orderId},
        'Checkout',
        'documentsCount',
      ],
    }),
    createInternalInvoiceDocumentDocument: build.mutation<
      CreateInternalInvoiceDocumentDocumentApiResponse,
      CreateInternalInvoiceDocumentDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/document/internal-invoice-document`,
        method: 'PUT',
        body: queryArg.internalInvoiceDocumentRequestBody,
      }),
      invalidatesTags: (result, error, queryArgs) => [
        {type: 'offer', id: queryArgs.internalInvoiceDocumentRequestBody.orderId},
        'Checkout',
        'documentsCount',
      ],
    }),
    createInternalInvoiceDocumentWithVatDocument: build.mutation<
      CreateInternalInvoiceDocumentWithVatDocumentApiResponse,
      CreateInternalInvoiceDocumentWithVatDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/document/internal-invoice-document-with-vat`,
        method: 'PUT',
        body: queryArg.internalInvoiceDocumentRequestBody,
      }),
      invalidatesTags: (result, error, queryArgs) => [
        {type: 'offer', id: queryArgs.internalInvoiceDocumentRequestBody.orderId},
        'Checkout',
        'documentsCount',
      ],
    }),
    getBrokerageInternalInvoiceDocumentTemplates: build.query<
      GetBrokerageInternalInvoiceDocumentTemplatesApiResponse,
      GetBrokerageInternalInvoiceDocumentTemplatesApiArg
    >({
      query: () => ({
        url: `/dms/v1/checkout/document/sale/brokerage-internal-invoice-document/templates`,
      }),
    }),
    getBrokerageInternalInvoiceDocumentWithVatTemplates: build.query<
      GetBrokerageInternalInvoiceDocumentWithVatTemplatesApiResponse,
      GetBrokerageInternalInvoiceDocumentWithVatTemplatesApiArg
    >({
      query: () => ({
        url: `/dms/v1/checkout/document/sale/brokerage-internal-invoice-document-with-vat/templates`,
      }),
    }),
    getInternalInvoiceDocumentTemplates: build.query<
      GetInternalInvoiceDocumentTemplatesApiResponse,
      GetInternalInvoiceDocumentTemplatesApiArg
    >({
      query: () => ({
        url: `/dms/v1/checkout/document/sale/internal-invoice-document/templates`,
      }),
    }),
    getInternalInvoiceDocumentWithVatTemplates: build.query<
      GetInternalInvoiceDocumentWithVatTemplatesApiResponse,
      GetInternalInvoiceDocumentWithVatTemplatesApiArg
    >({
      query: () => ({
        url: `/dms/v1/checkout/document/sale/internal-invoice-document-with-vat/templates`,
      }),
    }),
    withdrawCheckoutOrderPaymentByCashRegisterDocument: build.mutation<
      WithdrawCheckoutOrderPaymentByCashRegisterDocumentApiResponse,
      WithdrawCheckoutOrderPaymentByCashRegisterDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/cash-register-document/${queryArg.cashRegisterDocumentId}/withdraw-payment`,
        method: 'PUT',
      }),
    }),

    allowDeposit: build.mutation<AllowDepositApiRes, AllowDepositApiArg>({
      query: ({checkoutId, orderId}) => ({
        url: `/dms/v1/checkout/${checkoutId}/order/${orderId}/allow-deposit`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'offer', id: queryArg.orderId}],
    }),
    forbidDeposit: build.mutation<ForbitDepositApiRes, ForbitDepositApiArg>({
      query: ({checkoutId, orderId}) => ({
        url: `/dms/v1/checkout/${checkoutId}/order/${orderId}/forbid-deposit`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'offer', id: queryArg.orderId}],
    }),
    deleteDeposit: build.mutation<DeleteDepositApiRes, DeleteDepositApiArg>({
      query: ({checkoutId, orderId, depositId}) => ({
        url: `/dms/v1/checkout/${checkoutId}/order/${orderId}/deposit/${depositId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        'documentsCount',
        'BusinessCaseActions',
        'BusinessCaseDetail',
        'Checkout',
      ],
    }),
    createDepositPayment: build.mutation<PostDepositPaymentApiRes, PostDepositPaymentApiArg>({
      query: ({checkoutId, orderId}) => ({
        url: `/dms/v1/checkout/${checkoutId}/order/${orderId}/deposit`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        'documentsCount',
      ],
    }),
    getOrder: build.query<GetOrderApiRes, GetOrderApiArg>({
      query: ({checkoutId, orderId}) => ({
        url: `/dms/v1/checkout/${checkoutId}/order/${orderId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'offer', id: queryArg.orderId}],
    }),
    cancelOrder: build.mutation<CancelOrderApiRes, CancelOrderApiArg>({
      query: ({checkoutId, orderId, paymentId}) => ({
        url: `/dms/v1/checkout/${checkoutId}/order/${orderId}/payment/${paymentId}/cancel`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        'BusinessCaseDetail',
        'documentsCount',
        'BusinessCaseActions',
      ],
    }),
    recordPayment: build.mutation<RecordPaymentApiRes, RecordPaymentApiArg>({
      query: ({checkoutId, orderId, paymentId, recordPayment}) => ({
        url: `/dms/v2/checkout/${checkoutId}/order/${orderId}/payment/${paymentId}/recordPaid`,
        method: 'POST',
        body: recordPayment,
      }),
      invalidatesTags: (result, error, queryArgs) => [
        {type: 'offer', id: queryArgs.orderId},
        'Checkout',
        'invoicePayment',
        'invoiceProformaPaymentList',
        'BusinessCaseDetail',
        'BusinessCaseActions',
        'documentsCount',
        'paymentPrescription',
      ],
    }),
    withdrawalPayment: build.mutation<WithdrawalPaymentApiRes, WithdrawalPaymentApiArg>({
      query: ({checkoutId, orderId, paymentId, prescriptionId}) => ({
        url: `/dms/v1/checkout/${checkoutId}/order/${orderId}/payment/${paymentId}/withdraw/${prescriptionId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        'Checkout',
        'invoicePayment',
        'invoiceProformaPaymentList',
        'BusinessCaseDetail',
        'BusinessCaseActions',
        'documentsCount',
        'paymentPrescription',
      ],
    }),
    withdrawalPurchasePayment: build.mutation<
      WithdrawalPaymentApiRes,
      WithdrawalPurchasePaymentApiArg
    >({
      query: ({checkoutId, orderId, paymentId}) => ({
        url: `/dms/v1/checkout/${checkoutId}/order/${orderId}/payment/${paymentId}/withdraw`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        'invoicePayment',
        'invoiceProformaPaymentList',
        'paymentPrescription',
        'BusinessCaseDetail',
        'BusinessCaseActions',
        'documentsCount',
      ],
    }),
    createPaymentFile: build.mutation<CreatePaymentFileApiRes, CreatePaymentFileApiArg>({
      query: ({checkoutId, orderId, paymentId, fileData}) => ({
        url: `/dms/v1/checkout/${checkoutId}/order/${orderId}/payment/${paymentId}/file`,
        method: 'POST',
        body: {fileData},
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'offer', id: queryArg.orderId}],
    }),
    deletePaymentFile: build.mutation<DeletePaymentFileApiRes, DeletePaymentFileApiArg>({
      query: ({checkoutId, orderId, paymentId, fileId}) => ({
        url: `/dms/v1/checkout/${checkoutId}/order/${orderId}/payment/${paymentId}/file/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        'documentsCount',
      ],
    }),
    createBusinessCaseProformaInvoiceSetting: build.mutation<
      CreateBusinessCaseProformaInvoiceSettingApiResponse,
      CreateBusinessCaseProformaInvoiceSettingApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/checkout/proforma-invoice-setting`,
        method: 'POST',
        body,
      }),
    }),
    deleteBusinessCaseProformaInvoiceSetting: build.mutation<
      DeleteBusinessCaseProformaInvoiceSettingApiResponse,
      DeleteBusinessCaseProformaInvoiceSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/proforma-invoice-setting/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getBusinessCaseProformaInvoiceSetting: build.query<
      GetBusinessCaseProformaInvoiceSettingApiResponse,
      GetBusinessCaseProformaInvoiceSettingApiArg
    >({
      query: ({id}) => ({
        url: `/dms/v1/checkout/proforma-invoice-setting/${id}`,
      }),
      keepUnusedDataFor: 0,
    }),
    updateBusinessCaseProformaInvoiceSetting: build.mutation<
      UpdateBusinessCaseInvoiceSettingApiResponse,
      UpdateBusinessCaseInvoiceSettingApiArg
    >({
      query: ({id, ...body}) => ({
        url: `/dms/v1/checkout/proforma-invoice-setting/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getListBusinessCaseProformaInvoiceSetting: build.query<
      GetListBusinessCaseProformaInvoiceSettingApiResponse,
      GetListBusinessCaseProformaInvoiceSettingApiArg
    >({
      query: () => ({
        url: `/dms/v1/checkout/proforma-invoice-setting/list`,
      }),
    }),
    changeTypeOfSaleCheckoutOrder: build.mutation<
      ChangeTypeOfSaleCheckoutOrderApiResponse,
      ChangeTypeOfSaleCheckoutOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/order/${queryArg.orderId}/type-of-sale`,
        method: 'PUT',
        body: queryArg.orderTypeOfSaleRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        'Checkout',
        'BusinessCaseDetail',
      ],
    }),
    createDocument: build.mutation<PutCheckoutDocumentsApiRes, PutCheckoutDocumentsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/document`,
        method: 'PUT',
        body: queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        'documentsCount',
        'Checkout',
      ],
    }),
    getCheckoutOffsettingOfLiabilitiesAndReceivables: build.query<
      GetCheckoutOffsettingOfLiabilitiesAndReceivablesApiResponse,
      GetCheckoutOffsettingOfLiabilitiesAndReceivablesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/${queryArg.checkoutId}/offsetting-of-liabilities-and-receivables`,
      }),
    }),
    getOffsettingOfLiabilitiesAndReceivablesTemplates: build.query<
      GetOffsettingOfLiabilitiesAndReceivablesTemplatesApiResponse,
      GetOffsettingOfLiabilitiesAndReceivablesTemplatesApiArg
    >({
      query: () => ({
        url: `/dms/v1/checkout/document/purchase-brokerage/offsetting-of-liabilities-and-receivables/templates`,
      }),
    }),
    getCheckoutDocumentNote: build.query<
      GetCheckoutDocumentNoteApiResponse,
      GetCheckoutDocumentNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/document/${queryArg.orderId}/${queryArg.subEntityClass}/note`,
      }),
    }),
    putCheckoutDocumentNote: build.mutation<
      PutCheckoutDocumentNoteApiResponse,
      PutCheckoutDocumentNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/document/${queryArg.orderId}/${queryArg.subEntityClass}/note`,
        method: 'PUT',
        body: queryArg.noteRequestBody,
      }),
    }),
    getSaleBrokerageContractTemplates: build.query<
      GetCheckoutSaleBrokerageContractTemplatesApiResponse,
      GetCheckoutSaleBrokerageContractTemplatesApiArg
    >({
      query: () => ({
        url: `/dms/v1/checkout/document/sale/sale-brokerage-contract/templates`,
      }),
    }),
    getCheckoutPurchaseTemplates: build.query<
      GetCheckoutPurchaseTemplatesApiResponse,
      GetPurchaseTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/document/purchase/templates`,
      }),
    }),
    getCheckoutPurchaseBrokerageTemplates: build.query<
      GetCheckoutPurchaseBrokerageTemplatesApiResponse,
      GetCheckoutPurchaseBrokerageTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/document/purchase-brokerage/templates`,
      }),
    }),
    getCheckoutSaleTemplates: build.query<
      GetCheckoutSaleTemplatesApiResponse,
      GetSaleTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/document/sale/templates`,
      }),
    }),
    createInternalCorrectiveTaxDocumentDocument: build.mutation<
      CreateInternalCorrectiveTaxDocumentDocumentApiResponse,
      CreateInternalCorrectiveTaxDocumentDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/document/internal-corrective-tax-document`,
        method: 'PUT',
        body: queryArg.internalCorrectiveTaxDocumentRequestBody,
      }),
      invalidatesTags: (result, error, queryArgs) => [
        {type: 'offer', id: queryArgs.internalCorrectiveTaxDocumentRequestBody.orderId},
        'Checkout',
        'documentsCount',
      ],
    }),
    getInternalCorrectiveTaxDocumentTemplates: build.query<
      GetInternalCorrectiveTaxDocumentTemplatesApiResponse,
      void
    >({
      query: () => ({
        url: `/dms/v1/checkout/document/sale/internal-corrective-tax-document/templates`,
      }),
    }),
    createDueDateSetting: build.mutation<
      CreateDueDateSettingApiResponse,
      CreateDueDateSettingApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/checkout/due-date-setting`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['dueDates'],
    }),
    deleteDueDateSetting: build.mutation<
      DeleteDueDateSettingApiResponse,
      DeleteDueDateSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/due-date-setting/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'dueDatesDetail', id: queryArg.id},
        'dueDates',
      ],
    }),
    getDueDateSetting: build.query<GetDueDateSettingApiResponse, GetDueDateSettingApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/checkout/due-date-setting/${queryArg.id}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'dueDatesDetail', id: queryArg.id}],
    }),
    updateDueDateSetting: build.mutation<
      UpdateDueDateSettingApiResponse,
      UpdateDueDateSettingApiArg
    >({
      query: ({id, ...body}) => ({
        url: `/dms/v1/checkout/due-date-setting/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'dueDatesDetail', id: queryArg.id},
        'dueDates',
      ],
    }),
    getListDueDateSetting: build.query<
      GetListDueDateSettingApiResponse,
      GetListDueDateSettingApiArg
    >({
      query: () => ({
        url: `/dms/v1/checkout/due-date-setting/list`,
      }),
      providesTags: ['dueDates'],
    }),
  }),
});

export const {
  useGetListDueDateSettingQuery,
  useUpdateDueDateSettingMutation,
  useGetDueDateSettingQuery,
  useDeleteDueDateSettingMutation,
  useCreateDueDateSettingMutation,
  useGetCheckoutPurchaseBrokerageTemplatesQuery,
  useGetCheckoutSaleTemplatesQuery,
  useGetCheckoutPurchaseTemplatesQuery,
  useGetSaleBrokerageContractTemplatesQuery,
  useGetCheckoutDocumentNoteQuery,
  usePutCheckoutDocumentNoteMutation,
  useGetOffsettingOfLiabilitiesAndReceivablesTemplatesQuery,
  useGetCheckoutOffsettingOfLiabilitiesAndReceivablesQuery,
  useCreateDocumentMutation,
  useChangeTypeOfSaleCheckoutOrderMutation,
  useGetBusinessCaseProformaInvoiceSettingQuery,
  useGetListBusinessCaseProformaInvoiceSettingQuery,
  useCreateBusinessCaseProformaInvoiceSettingMutation,
  useUpdateBusinessCaseProformaInvoiceSettingMutation,
  useDeleteBusinessCaseProformaInvoiceSettingMutation,
  useWithdrawalPurchasePaymentMutation,
  useLazyGetOrderQuery,
  useCreatePaymentFileMutation,
  useDeletePaymentFileMutation,
  useCancelOrderMutation,
  useDeleteDepositMutation,
  useGetOrderQuery,
  useWithdrawalPaymentMutation,
  useRecordPaymentMutation,
  useAllowDepositMutation,
  useForbidDepositMutation,
  useSplitCheckoutOrderMutation,
  useGetCheckoutQuery,
  useMergeCheckoutOrderMutation,
  useCreateDepositPaymentMutation,
  useIssueCheckoutOrderPaymentMutation,
  useRecordPaidCheckoutOrderPaymentMutation,
  useAddCheckoutOrderPurchasePaymentMutation,
  useDeleteCheckoutOrderPurchasePaymentMutation,
  useCreateCheckoutOrderAdditionalCustomerMutation,
  usePatchCheckoutOrderAdditionalCustomerMutation,
  useDeleteCheckoutOrderAdditionalCustomerMutation,
  useGetBrokerageInternalInvoiceDocumentTemplatesQuery,
  useLazyGetBrokerageInternalInvoiceDocumentTemplatesQuery,
  useGetBrokerageInternalInvoiceDocumentWithVatTemplatesQuery,
  useLazyGetBrokerageInternalInvoiceDocumentWithVatTemplatesQuery,
  useGetInternalInvoiceDocumentTemplatesQuery,
  useLazyGetInternalInvoiceDocumentTemplatesQuery,
  useGetInternalInvoiceDocumentWithVatTemplatesQuery,
  useLazyGetInternalInvoiceDocumentWithVatTemplatesQuery,
  usePatchCheckoutOrderForeignCurrencyPaymentMutation,
  usePatchCheckoutOrderPaymentMutation,
  useCreateBrokerageInternalInvoiceDocumentDocumentMutation,
  useCreateBrokerageInternalInvoiceDocumentWithVatDocumentMutation,
  useCreateInternalInvoiceDocumentDocumentMutation,
  useCreateInternalInvoiceDocumentWithVatDocumentMutation,
  useCreateInternalCorrectiveTaxDocumentDocumentMutation,
  useLazyGetInternalCorrectiveTaxDocumentTemplatesQuery,
} = checkoutApi;
