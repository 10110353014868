import {
  Box,
  Clickable,
  Heading,
  HStack,
  Icon,
  Text,
  ThemeColorPath,
  ThemeIconKey,
  VStack,
} from 'platform/foundation';

type ModeBoxProps = {
  isSelected: boolean;
  title: string;
  description: string;
  icon: ThemeIconKey;
  onClick: () => void;
};

export function ModeBox(props: ModeBoxProps) {
  const backgroundColor: ThemeColorPath = props.isSelected
    ? 'palettes.blue.10.100'
    : 'general.white';

  const borderColor: ThemeColorPath = props.isSelected
    ? 'palettes.blue.60.100'
    : 'palettes.neutral.40.100';

  const iconColor: ThemeColorPath = props.isSelected ? 'palettes.blue.60.100' : 'general.white';
  const icon: ThemeIconKey = props.isSelected
    ? 'action/icon_check_filled'
    : 'action/icon_check_blank';

  return (
    <Clickable onClick={props.onClick}>
      <Box
        border="1px solid"
        borderRadius="small"
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        padding={4}
      >
        <VStack spacing={4}>
          <HStack spacing={4}>
            <Icon value={icon} size={5} color={iconColor} />
            <Heading size={4}>{props.title}</Heading>
          </HStack>

          <Icon value={props.icon} size={20} color={backgroundColor} />

          <Text size="small" color="secondary">
            {props.description}
          </Text>
        </VStack>
      </Box>
    </Clickable>
  );
}
