import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {additionalFieldFormType, AdditionalFieldsKeys, CustomFieldValue} from '@omnetic-dms/api';

import {Nullish} from 'shared';

const additionalFields = {
  affiliatedEntity: {
    definitionId: 'affiliatedEntity',
    value: {
      type: 'boolean',
      value: null,
    },
  },
} as const;

export const getCustomFieldPayload = (
  additionalFieldsFromForm: Partial<additionalFieldFormType> | Nullish
) =>
  Object.entries(additionalFieldsFromForm ?? {}).map(([key, value]) =>
    getFieldValues(key as keyof typeof additionalFields, value)
  );

const getFieldValues = (
  fieldKey: AdditionalFieldsKeys,
  value: additionalFieldFormType[AdditionalFieldsKeys]
) => {
  const field = additionalFields[fieldKey];

  const typeSafeValue = match(field.value.type)
    .with('boolean', always(isNotNil(value) ? value : false))
    .otherwise(always(value));

  const fieldWithValue = {
    definitionId: fieldKey,
    value: {
      type: field.value.type,
      value: typeSafeValue,
    },
  };

  return fieldWithValue;
};

export const getFormAdditionalField = (customFields: CustomFieldValue[] | Nullish) => {
  const affiliatedEntityObject: Partial<Record<AdditionalFieldsKeys, boolean | Nullish>> = {};

  if (isNil(customFields)) {
    return undefined;
  }

  customFields.forEach((additionalField) => {
    affiliatedEntityObject[additionalField.definitionId] = additionalField.value.value;
  });

  return affiliatedEntityObject;
};
