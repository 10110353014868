import {addHours} from 'date-fns';

import {isDefined} from 'shared';

import {RawFileWithCountParts} from '../types/RawFileWithCountParts';
import {UploadApi} from '../types/UploadApi';
import {UploadPartsInfo} from '../types/UploadPartsInfo';

export function usePreSignLinksManager(
  sendMultiPartUploadRequest: UploadApi['sendMultiPartUploadRequest']
) {
  const getPreSignLinks = (rawFile: RawFileWithCountParts): Promise<UploadPartsInfo> =>
    sendMultiPartUploadRequest({
      body: {files: [{filename: rawFile.name, parts: rawFile.countOfParts}]},
    })
      .unwrap()
      .then((response) => response.files?.at(0))
      .then((connection) => {
        if (!isDefined(connection)) {
          throw new Error('There is no file');
        }

        const now = new Date().getTime();

        return {
          fileId: connection.fileId,
          expiredAt: addHours(now, 12).getTime(),
          parts: connection.parts.map((p) => ({...p, etag: null})),
        } satisfies UploadPartsInfo;
      });

  return {getPreSignLinks};
}
