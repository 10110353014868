import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {head, isNil, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeletePreAccountingProcessingSettingsMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@omnetic-dms/shared';

import {Nullish, composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function PreAccountingProcessingOverview() {
  const navigate = useNavigate();
  const navigateToNewDefinition = () => navigate(settingsRoutes.preAccountingProcessingNew);
  const navigateToDetail = (id: string) =>
    navigate(composePath(settingsRoutes.preAccountingProcessingDetail, {params: {id}}));

  const [deleteProcessingItem] = useDeletePreAccountingProcessingSettingsMutation();

  const handleDeleteProcessingItem = (
    id: string,
    branchName: string | Nullish,
    refreshData: () => void
  ) =>
    openDeleteDialog({
      onConfirm: () =>
        deleteProcessingItem({id})
          .unwrap()
          .then(() => showNotification.success())
          .then(refreshData)
          .catch(handleApiError),
      text: i18n.t('entity.accounting.labels.deleteDefinitionDeleteText', {
        name: branchName,
      }),
    });

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;
    const branchName = path<string>(['branch', 'value', 'label'], rowData);

    if (isNil(id)) {
      showNotification.error();
      return;
    }

    match(actionKey)
      .with('EDIT', () => navigateToDetail(id))
      .with('DETAIL', () => navigateToDetail(id))
      .with('DELETE', () => handleDeleteProcessingItem(id, branchName, refreshData))
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.accounting.labels.preProcessingCalculation'),
        actions: [
          {
            type: 'button',
            onClick: navigateToNewDefinition,
            title: i18n.t('page.accounting.actions.addDefinition'),
          },
        ],
      }}
      data-testid={testIds.settings.preAccountingProcessingOverview('settings-template')}
    >
      <DataGrid
        gridCode="accounting-pre-accounting-processing-settings"
        actionCallback={actionCallback}
        data-testid={testIds.settings.preAccountingProcessingOverview('datagrid')}
      />
    </SettingsTemplate>
  );
}
