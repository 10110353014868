import {Label} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

interface DataPreviewProps extends TestIdProps {
  label: string;
  value: string | Nullish;
}

export function DataPreview(props: DataPreviewProps) {
  return (
    <VStack>
      <Label data-testid={suffixTestId('label', props)}>{props.label}</Label>
      <Heading size={4} alternative data-testid={suffixTestId('value', props)}>
        {props.value ?? EMPTY_PLACEHOLDER}
      </Heading>
    </VStack>
  );
}
