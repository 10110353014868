import {nth, pipe, split} from 'ramda';

/**
 * Extracts the module segment from a given URL path.
 *
 * @param {string} path - The URL path from which to extract the module segment.
 * @return {string} The module segment from the URL path.
 *
 * @example
 * getCustomerFromPath('/customer/7b25f324-8caa-421f-86e3-3f104edf70ae') => 'customer';
 */
export const getModuleNameFromPathname = pipe(split('/'), nth(1));
