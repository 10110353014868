import {Search, useAnimatedPopper} from 'platform/components';
import {Space, VStack, Text, Icon, HStack} from 'platform/foundation';
import styled from 'styled-components';

import {useEffect, useRef, useState} from 'react';

import {filter, isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {TreeFolder} from '@omnetic-dms/api';

import {Nullish, RequiredTestIdProps, useOutsideClick} from 'shared';

import {FolderCallback} from '../types/FolderCallback';
import {getAllFolders} from '../utils/getAllFolders';

interface FolderSearchProps extends RequiredTestIdProps {
  folders?: TreeFolder[];
  onSelect: FolderCallback;
}

export function FolderSearch(props: FolderSearchProps) {
  const [search, setSearch] = useState<string | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const clickRef = useRef<HTMLDivElement>(null);

  const {Popper, popperProps, referenceRef, openPopper, closePopper} = useAnimatedPopper({
    placement: 'bottom-start',
    strategy: 'fixed',
  });

  const allFolders = getAllFolders(props.folders ?? []);
  const searchedFolders = filter((folder) => doesQueryMatch(folder.label, search), allFolders);

  useOutsideClick({
    ref: clickRef,
    handler: () => setIsFocused(false),
  });

  useEffect(() => {
    if (!isFocused) {
      return closePopper();
    }
    if (isNotNilOrEmpty(searchedFolders)) {
      return openPopper();
    }

    closePopper();
  }, [searchedFolders]);

  return (
    <div ref={clickRef}>
      <div ref={referenceRef}>
        <Search
          value={search}
          onChange={setSearch}
          onFocus={() => setIsFocused(true)}
          data-testid={props['data-testid']}
        />
      </div>
      <Popper {...popperProps}>
        <Space vertical={1} />
        <SearchDropdown>
          <VStack spacing={1}>
            {searchedFolders.map((folder) => (
              <SearchItem
                key={folder.id}
                onClick={() => {
                  props.onSelect(folder.id ?? undefined, folder.contextId);
                  setSearch(null);
                }}
              >
                {folder.parents.map((parent) => (
                  <>
                    <CustomLink
                      onClick={(event) => {
                        event.stopPropagation();
                        props.onSelect(parent.id ?? undefined, parent.contextId);
                        setSearch(null);
                      }}
                    >
                      <HStack spacing={1} align="center">
                        <Icon value="file/folder_open" size={4} />
                        <Text size="small">{parent.label}</Text>
                      </HStack>
                    </CustomLink>
                    <Icon
                      value="navigation/arrow_forward_ios"
                      size={3}
                      color="palettes.neutral.60.100"
                    />
                  </>
                ))}
                <Icon value="file/folder_open" size={4} color="general.accent" />
                <Text size="small" color="link">
                  {folder.label}
                </Text>
              </SearchItem>
            ))}
          </VStack>
        </SearchDropdown>
      </Popper>
    </div>
  );
}

const doesQueryMatch = (value: string | Nullish, query: string | Nullish) => {
  if (isNil(value) || isNil(query)) {
    return false;
  }

  value = value.toLowerCase();
  query = query.toLowerCase();

  return value.includes(query);
};

const SearchItem = styled.div`
  height: ${({theme}) => theme.getSize(9)};
  padding-left: ${({theme}) => theme.getSize(2)};
  padding-right: ${({theme}) => theme.getSize(2)};
  border-radius: ${({theme}) => theme.radii.small};
  display: flex;
  align-items: center;
  gap: ${({theme}) => theme.getSize(2)};
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
  }
`;

const SearchDropdown = styled.div`
  background-color: ${({theme}) => theme.colors.general.white};
  box-shadow: ${({theme}) => theme.shadows.elevation_3};
  border-radius: ${({theme}) => theme.radii.medium};
  border: 1px solid ${({theme}) => theme.colors.general.separator};
  padding: ${({theme}) => theme.getSize(2)};
`;

const CustomLink = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
