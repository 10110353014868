import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

interface DeliveriesSegmentProps extends RequiredTestIdProps {
  orderItemId: string | Nullish;
}

export function DeliveriesSegment(props: DeliveriesSegmentProps) {
  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {orderItemId: props.orderItemId}]),
    [props.orderItemId]
  );

  return (
    <DataGrid
      gridCode="warehouse-supplier-order-item-deliveries"
      queryModifier={queryModifier}
      autoHeight
      data-testid={suffixTestId('requests', props)}
    />
  );
}
