import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {VehicleWarehouseForm} from './components/VehicleWarehouseForm';

export function VehicleWarehouseDetail() {
  const {id} = useParams();

  const header: SettingsTemplateHeader = {
    title: isNil(id)
      ? i18n.t('page.salesSettings.labels.vehicleWarehouseCreate')
      : i18n.t('page.salesSettings.labels.vehicleWarehouseEdit'),
    breadcrumbs: [
      {
        label: i18n.t('page.salesSettings.labels.vehicleWarehouseManagement'),
        href: settingsRoutes.vehicleWarehouseManagement,
      },
    ],
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.vehicleWarehouseManagement('template')}
      header={header}
    >
      <VehicleWarehouseForm />
    </SettingsTemplate>
  );
}
