import {useMemo, useRef, useEffect} from 'react';

// eslint-disable-next-line no-restricted-syntax
import {toString} from 'ramda';

type UseEffectParams = Parameters<typeof useEffect>;
type EffectCallback = UseEffectParams[0];
type DependencyList = UseEffectParams[1];
type UseEffectReturn = ReturnType<typeof useEffect>;

export function useDeepCompareEffect(
  callback: EffectCallback,
  dependencies: DependencyList
): UseEffectReturn {
  return useEffect(callback, useDeepCompareMemoize(dependencies));
}

/**
 * @param value the value to be memoized (usually a dependency list)
 * @returns a memoized version of the value as long as it remains deeply equal
 */
export function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>(value);
  const signalRef = useRef<number>(0);

  if (!deepComparison(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  return useMemo(() => ref.current, [signalRef.current]);
}

function deepComparison(prev: any, next: any) {
  // We use this function instead of JSON.stringify which caused issues in this case https://carvago-cn.sentry.io/issues/4547193878].
  // We need to convert any value to string and the form of the string doesn't matter
  // eslint-disable-next-line no-restricted-syntax
  return toString(prev) === toString(next);
}
