import {Box, CSSDimension, Hide, HStack, Show, Text} from 'platform/foundation';
import {match} from 'ts-pattern';

import {ReactNode} from 'react';

import {always, concat, isNotNil} from 'ramda';

import {EMPTY_PLACEHOLDER, Nullish, suffixTestId, TestIdProps} from 'shared';

type AttributesSize = 'quarter' | 'third' | 'half' | Nullish;

export type AttributesRow = {
  label: string;
  testId?: string;
} & (
  | {
      value: number | string | string[] | false | Nullish;
      content?: never;
    }
  | {
      content: ReactNode;
      value?: never;
    }
);

export interface AttributesProps extends TestIdProps {
  size?: AttributesSize;
  rows?: AttributesRow[];
}

export function Attributes(props: AttributesProps) {
  const labelWidth = match<AttributesSize, CSSDimension>(props.size)
    .with('quarter', always('25%'))
    .with('third', always('37%'))
    .otherwise(always('50%'));

  return (
    <Box width="100%" data-testid={suffixTestId('attributes', props)}>
      {props.rows?.map((row, index) => {
        const rowSuffix = row?.testId ?? `[${index}-row]`;

        return (
          <Box
            paddingVertical={2}
            borderColor="palettes.neutral.30.100"
            borderBottom={index + 1 !== props.rows?.length ? '1px solid' : undefined}
            key={concat(row?.label, index.toString())}
          >
            <HStack spacing={4}>
              <Box width={labelWidth}>
                <Text
                  size="xSmall"
                  color="secondary"
                  data-testid={suffixTestId(`${rowSuffix}-label`, props)}
                >
                  {row?.label}
                </Text>
              </Box>

              <Box flex={1}>
                <Show when={isNotNil(row.content)}>
                  <Box data-testid={suffixTestId(`${rowSuffix}-content`, props)}>{row.content}</Box>
                </Show>
                <Hide when={isNotNil(row.content)}>
                  <Text size="xSmall" data-testid={suffixTestId(`${rowSuffix}-value`, props)}>
                    {row?.value ?? EMPTY_PLACEHOLDER}
                  </Text>
                </Hide>
              </Box>
            </HStack>
          </Box>
        );
      })}
    </Box>
  );
}
