import {Card, FormControl, FormField} from 'platform/components';
import {Grid} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {VehicleTypeEnumObject} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {TestIdProps, suffixTestId} from 'shared';

import {useVehicleCatalogue} from '../../../../../hooks/useVehicleCatalogue';
import {ServiceVehicleFormType} from '../../../types/ServiceVehicleFormType';

export interface ElectricFuelFieldsProps extends TestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType, object>;
  vehicleType: VehicleTypeEnumObject;
}

export function ElectricFuelFields(props: ElectricFuelFieldsProps) {
  const [vehicleUtils] = useVehicleCatalogue(props.vehicleType);

  return (
    <Card variant="inlineGrey" title={i18n.t('entity.vehicle.labels.battery')}>
      <Grid columns={4} spacing={4}>
        <FormField
          control={props.control}
          type="number"
          name="chargingAC"
          label={i18n.t('entity.vehicle.labels.batteryChargingAc')}
          suffix={i18n.t('general.metric.kW')}
          data-testid={suffixTestId('batteryChargingAc', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name="chargingTimeAC"
          label={i18n.t('entity.vehicle.labels.batteryChargingTimeAc')}
          suffix={i18n.t('general.metric.h')}
          data-testid={suffixTestId('batteryChargingTimeAc', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name="chargingDC"
          label={i18n.t('entity.vehicle.labels.batteryChargingDc')}
          suffix={i18n.t('general.metric.kW')}
          data-testid={suffixTestId('batteryChargingDc', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name="chargingTimeDC"
          label={i18n.t('entity.vehicle.labels.batteryChargingTimeDc')}
          suffix={i18n.t('general.metric.min')}
          data-testid={suffixTestId('batteryChargingTimeDc', props)}
        />
        <FormField
          control={props.control}
          type="choice"
          name="batteryType"
          options={vehicleUtils.batteryTypeOptions ?? []}
          menuInPortal
          label={i18n.t('entity.vehicle.labels.batteryType')}
          data-testid={suffixTestId('batteryType', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name="batteryCapacityWh"
          label={i18n.t('entity.vehicle.labels.batteryCapacity')}
          suffix={i18n.t('general.metric.kWh')}
          data-testid={suffixTestId('batteryCapacityWh', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name="batteryCapacityAh"
          label={i18n.t('entity.vehicle.labels.batteryCapacity')}
          suffix={i18n.t('general.metric.Ah')}
          data-testid={suffixTestId('batteryCapacityAh', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name="batteryVoltage"
          label={i18n.t('entity.vehicle.labels.batteryVoltage')}
          suffix={i18n.t('general.metric.V')}
          data-testid={suffixTestId('batteryVoltage', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name="batteryCount"
          label={i18n.t('entity.vehicle.labels.batteryCount')}
          isStepperVisible
          data-testid={suffixTestId('batteryCount', props)}
        />
        <FormField
          control={props.control}
          type="choice"
          name="chargingConnectorType"
          options={vehicleUtils.chargingConnectorTypeOptions ?? []}
          menuInPortal
          label={i18n.t('entity.vehicle.labels.batteryChargingConnectoryType')}
          data-testid={suffixTestId('chargingConnectorType', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name="electricRange"
          label={i18n.t('entity.vehicle.labels.batteryElectricRange')}
          suffix={i18n.t('general.metric.km')}
          data-testid={suffixTestId('electricRange', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name="batteryMileageLimit"
          label={i18n.t('entity.vehicle.labels.batteryMileageLimit')}
          suffix={i18n.t('general.metric.km')}
          data-testid={suffixTestId('batteryMileageLimit', props)}
        />
        <FormField
          control={props.control}
          type="apiDate"
          name="batteryWarrantyUntil"
          label={i18n.t('entity.vehicle.labels.batteryWarrantyUntil')}
          data-testid={suffixTestId('batteryWarrantyUntil', props)}
        />
        <FormField
          control={props.control}
          type="choice"
          name="batteryOwnershipType"
          options={vehicleUtils.batteryOwnershipTypeOptions ?? []}
          menuInPortal
          label={i18n.t('entity.vehicle.labels.batteryOwnershipType')}
          data-testid={suffixTestId('batteryOwnershipType', props)}
        />
      </Grid>
    </Card>
  );
}
