import {GridItem, Grid, Heading, VStack} from 'platform/foundation';

import {FC, Fragment} from 'react';
import {useSelector} from 'react-redux';

import {suffixTestId, TestIdProps} from 'shared';

import {selectPaintAndThickness} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {getTranslation} from '../utils/getTranslation';
import {AuditFormField} from './AuditFormField';

export const PaintAndThickness: FC<TestIdProps> = (props) => {
  const locale = useSelector(selectUserSelectedLanguage);
  const paintAndThickness = useSelector(selectPaintAndThickness);

  if (!paintAndThickness) {
    return null;
  }

  const paintThicknessChildCategories = paintAndThickness?.childCategories;
  const paintThicknessParamDefinitions = paintAndThickness?.paramDefinitions;

  return (
    <VStack spacing={4}>
      <Heading data-testid={suffixTestId('paintAndThickness-title', props)} size={4}>
        {getTranslation(locale, paintAndThickness.name)}
      </Heading>
      <Grid
        columns={4}
        verticalSpacing={0}
        data-testid={suffixTestId('paintAndThickness-wrapper', props)}
      >
        {paintThicknessChildCategories?.length ? (
          paintThicknessChildCategories.map((category) =>
            category.paramDefinitions?.relatedActions?.map((action, index) => (
              <GridItem span={1} key={`paintAndThickness-${category.id}-${action.id}`}>
                <AuditFormField
                  categoryId={category.id}
                  paramDefinition={action}
                  uniqueKey={category.uniqueKey}
                  data-testid={suffixTestId(`paintAndThickness-[${index}]`, props)}
                />
              </GridItem>
            ))
          )
        ) : (
          <Fragment key={paintAndThickness.id}>
            {paintThicknessParamDefinitions?.relatedActions?.map((action, index) => (
              <GridItem span={1} key={`paintAndThickness-${paintAndThickness.id}-${action.id}`}>
                <AuditFormField
                  categoryId={paintAndThickness.id}
                  paramDefinition={action}
                  uniqueKey={paintAndThickness.uniqueKey}
                  data-testid={suffixTestId(`paintAndThickness-[${index}]`, props)}
                />
              </GridItem>
            ))}
          </Fragment>
        )}
      </Grid>
    </VStack>
  );
};
