import {ThemeIconKey, Show} from 'platform/foundation';

import {Fragment, MouseEventHandler} from 'react';

import {useBoolean, suffixTestId, TestIdProps} from 'shared';

import {DropdownItem} from '../Dropdown/components/DropdownItem';
import {Dropdown} from '../Dropdown/Dropdown';
import {Flag} from '../Flag/Flag';
import {BaseFlagProps} from '../Flag/types';
import {Separator} from '../Separator/Separator';
import {DropdownControl} from './DropdownControl';

export interface DropdownTagMenuItem extends TestIdProps {
  label: string;
  leftIcon?: ThemeIconKey;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  flag?: Omit<BaseFlagProps, 'size'>;
  hasSeparator?: boolean;
}

export interface DropdownTagProps extends BaseFlagProps {
  dropdownItems: DropdownTagMenuItem[];
  isDisabled?: boolean;
}

export function DropdownTag(props: DropdownTagProps) {
  const [isOpen, open, close] = useBoolean();

  return (
    <Dropdown
      dropdownControl={
        <DropdownControl
          isOpen={isOpen}
          label={props.label}
          colorScheme={props.colorScheme}
          size={props.size}
          isDisabled={props.isDisabled}
          isSubtle={props.isSubtle}
          data-testid={props['data-testid']}
        />
      }
      isOpen={!props.isDisabled && isOpen}
      onClose={close}
      onOpen={open}
      data-testid={suffixTestId('dropdown', props)}
    >
      {props.dropdownItems.map((menuItem, index) => (
        <Fragment key={`dropdownActionItem-${menuItem.label}-${index}`}>
          <DropdownItem
            suffix={menuItem.flag ? <Flag {...menuItem.flag} /> : undefined}
            label={menuItem.label}
            isDisabled={menuItem.isDisabled}
            onClick={menuItem.onClick}
            leftIcon={menuItem.leftIcon}
            data-testid={suffixTestId(`menuItem-${menuItem['data-testid'] ?? index}`, props)}
          />
          <Show when={menuItem.hasSeparator}>
            <Separator spacing={2} />
          </Show>
        </Fragment>
      ))}
    </Dropdown>
  );
}
