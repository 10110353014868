import {Card, Separator} from 'platform/components';
import {Box, Image, Link, Text, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

export function Distraints() {
  return (
    <VStack spacing={4}>
      <Card title={i18n.t('page.integrations.labels.serviceTermsAndConditions')}>
        <VStack spacing={4}>
          <Text size="small">
            {i18n.t('page.integrations.labels.distraintsTermsAndConditionsContent')}
          </Text>
          <Text size="small">
            {i18n.t('page.integrations.labels.distraintsTermsAndConditionsContent2')}
          </Text>
        </VStack>
        <Separator />
        <Box paddingBottom={4}>
          <Text size="xSmall" color="secondary">
            {i18n.t('page.integrations.labels.termOfService')}
          </Text>
          <Text size="large" alternative>
            {i18n.t('page.integrations.labels.payPerUse')}
          </Text>
        </Box>
        <Box paddingBottom={4}>
          <Text size="xSmall" color="secondary">
            {i18n.t('general.labels.price')}
          </Text>
          <Text size="large" alternative>
            {i18n.t('page.integrations.labels.distraintsPrice')}
          </Text>
        </Box>
      </Card>

      <Card title={i18n.t('page.integrations.labels.provider')}>
        <Box paddingBottom={4}>
          <Image src="assets/images/teas-30years.png" alt="Teas" maxWidth={36} />
        </Box>

        <Box paddingBottom={4}>
          <Text alternative>Teas, spol. s.r.o.</Text>
          <Text>Platnéřská 88/9</Text>
          <Text>110 00 Praha, Česká republika</Text>
        </Box>

        <Box paddingBottom={4}>
          <Text>{i18n.t('contractInformation.labels.registrationNumber')}: 48906565</Text>
          <Text>{i18n.t('contractInformation.labels.vatNumber')}: CZ48906565</Text>
        </Box>

        <Box paddingBottom={4}>
          <Text>
            <Link href="tel:+420608727906" title="+420 608 727 906" />
          </Text>
          <Text>
            <Link
              data-testid={testIds.settings.customerVerificationDistraints('teasWebLink')}
              href="https://www.teas.cz"
              title="www.teas.cz"
              target="_blank"
            />
          </Text>
        </Box>
      </Card>
    </VStack>
  );
}
