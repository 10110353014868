import {CancelablePromise} from '../types/CancelablePromise';
import {InterestSeriesDefinition} from '../types/InterestSeriesDefinition';
import {request as __request} from '../utils/request';

export class SeriesDefinitionService {
  /**
   * @returns InterestSeriesDefinition
   * @throws ApiError
   */
  public static getInterestSeriesDefinitionList({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Array<InterestSeriesDefinition>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/interest/series-definition',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns InterestSeriesDefinition
   * @throws ApiError
   */
  public static getInterestSeriesDefinition({
    seriesDefinitionId,
    authorization,
  }: {
    seriesDefinitionId: string;
    authorization?: string;
  }): CancelablePromise<InterestSeriesDefinition> {
    return __request({
      method: 'GET',
      url: '/dms/v1/interest/series-definition/{seriesDefinitionId}',
      path: {
        seriesDefinitionId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
}
