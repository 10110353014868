import {useEffect, useRef} from 'react';

import {
  useCalculateFromWithoutVatQuery,
  useCalculateFromWithVatQuery,
  VatRateType,
} from '@omnetic-dms/api';

interface CalculatePriceProps {
  amount: string;
  countryCode?: string;
  vatCode: VatRateType;
  type?: string;
  onChange?: (res: {withVat?: string} | {withoutVat?: string}) => void;
}

export function CalculatePrice(props: CalculatePriceProps) {
  const isMounted = useRef<boolean>(false);

  const areFetchingPropsValid = !!props.amount && !!props.vatCode;

  const {data: fromWithVatData} = useCalculateFromWithVatQuery(
    {
      amount: props.amount,
      vatRateType: props.vatCode,
      countryCode: props.countryCode ?? '',
    },
    {
      skip: props.type !== 'withVat' || !areFetchingPropsValid,
    }
  );

  const {data: fromWithoutVatData} = useCalculateFromWithoutVatQuery(
    {
      amount: props.amount,
      vatRateType: props.vatCode,
      countryCode: props.countryCode ?? '',
    },
    {
      skip: props.type !== 'withoutVat' || !areFetchingPropsValid,
    }
  );

  // eslint-disable-next-line eag/no-effect-without-cleanup
  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (!(isMounted.current && fromWithVatData)) {
      return;
    }

    props.onChange?.({withoutVat: fromWithVatData.withoutVat});
  }, [fromWithVatData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!(isMounted.current && fromWithoutVatData)) {
      return;
    }

    props.onChange?.({withVat: fromWithoutVatData.withVat});
  }, [fromWithoutVatData]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
