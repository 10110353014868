import {FormApi} from 'final-form';

import {useCallback} from 'react';

import {ifElse, is, isNil, keys, map, pick, values, zipObj} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {EquipmentResponseBody, VehicleTypeEnumObject} from '@omnetic-dms/api';

import {getApiDateString} from 'shared';

import {useApiDispatch} from '../../../hooks/useApiDispatch';
import {VinDecoderService} from '../../../services/VinDecoderService';
import {getVehicleMakeModels, getVehicleMakes} from '../../../store/vehicleCatalogue/actions';
import {VinDecoder} from '../../../types/VinDecoder';
import {useCallApi} from '../../../utils/api';
import {TYPE_DEPENDENT_FIELDS} from '../types/TYPE_DEPENDENT_FIELDS';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';

const seatCountOptions = Array.from(Array(9)).map((_, index) => ({
  id: index + 1,
  label: index + 1,
}));

type UseDecodedFields = {
  onDecode: (
    vin: string | undefined | null,
    form: FormApi<VehicleCreateFormState>
  ) => Promise<void | VinDecoder>;
};

const formatNumbers = (value: string) => {
  if (isNil(value)) {
    return value;
  }

  return !isNaN(Number(value.replace(/ /g, ''))) ? value.replace(/ /g, '') : value;
};

const format = (decodeObject: any): any => {
  if (isNil(decodeObject)) {
    return null;
  }

  return typeof decodeObject === 'object' && !Array.isArray(decodeObject)
    ? zipObj(
        keys(decodeObject) as any,
        map(ifElse(is(String), formatNumbers, format), values(decodeObject))
      )
    : decodeObject;
};

export const getRegistrationDate = (data: {
  firstRegistrationOnDay?: string | number | null;
  firstRegistrationOnMonth?: string | number | null;
  firstRegistrationOnYear?: string | number | null;
}) =>
  isNotNil(data?.firstRegistrationOnDay) &&
  isNotNil(data?.firstRegistrationOnMonth) &&
  isNotNil(data?.firstRegistrationOnYear)
    ? getApiDateString(
        new Date(
          Number(data.firstRegistrationOnYear),
          Number(data.firstRegistrationOnMonth) - 1,
          Number(data.firstRegistrationOnDay)
        )
      )
    : undefined;

export const useDecodedFields = (): UseDecodedFields => {
  const decodeVin = useCallApi(VinDecoderService.vinDecoderDecode);
  const apiDispatch = useApiDispatch();

  const onDecode = useCallback(
    async (
      vin: string | undefined | null,
      form: FormApi<VehicleCreateFormState>
    ): Promise<VinDecoder | void> => {
      if (vin) {
        const decoded = await decodeVin({vin});

        const formValues = form.getState().values;

        const {make: newMake, modelFamily: newModelFamily} = decoded;
        const vehicleType = decoded.vehicleType || formValues.type; // TODO: ???

        const newFields: VinDecoder = format(decoded);

        if (newMake) {
          const makes = await apiDispatch(getVehicleMakes.action, {vehicleType});
          const foundMake = makes.find(({make}) => make === newMake);

          if (foundMake) {
            newFields.make = foundMake.make;
            newFields.vehicleType = vehicleType;

            const makeModels = await apiDispatch(getVehicleMakeModels.action, {
              vehicleType,
              make: newMake,
            });

            const makeModel = makeModels?.find(({make}) => make === newMake);

            const foundModel = makeModel?.models?.find(({model}) => model === newModelFamily);

            if (!foundModel) {
              newFields.modelFamily = null;
            } else {
              newFields.modelFamily = foundModel.model;
            }
          } else {
            newFields.make = null;
          }
        }

        const allEquipment: EquipmentResponseBody[] = [
          ...(decoded?.seriesEquipment ?? []),
          ...(decoded?.specialEquipment ?? []),
          ...(decoded?.otherEquipment ?? []),
        ];
        const featuresByManufacturer =
          allEquipment.length > 0
            ? allEquipment
                .filter(
                  (equipment) =>
                    isNotNil(equipment.manufacturerCode) || isNotNil(equipment.description)
                )
                .sort((a, b) => {
                  if (isNil(a?.description)) {
                    return 1;
                  }
                  if (isNil(b?.description)) {
                    return -1;
                  }
                  return a.description.localeCompare(b.description);
                })
                .map((equipment) => {
                  if (isNil(equipment.manufacturerCode) || isNil(equipment.description)) {
                    return equipment.manufacturerCode ?? equipment.description;
                  }
                  return `${equipment.manufacturerCode} – ${equipment.description}`;
                })
                .join('\n')
            : undefined;

        let decodedResult: VehicleCreateFormState = {
          ...formValues,
          secondaryFuelType: newFields.secondaryFuelType,
          trim: newFields.trim,
          variant: newFields.variant,
          doorCountCategory: newFields.doorCount,
          modelSpecification: {
            ...formValues.modelSpecification,
            seatCount:
              seatCountOptions.find(({id}) => id === parseInt(newFields.seatCount ?? '', 10))?.id ??
              null,
            isRegistered: newFields.isRegistered,
          },
          fuelType: newFields.fuelType,
          power: newFields.power ? Number(newFields.power) : null,
          state: {
            ...formValues.state,
            otherRecords: newFields.otherRecords,
            condition: newFields.condition,
          },
          engine: {
            engineCode: newFields.engineCode,
            engineVolume: newFields.engineVolume ? Number(newFields.engineVolume) : null,
            carbonDioxideEmission: newFields.carbonDioxideEmission
              ? Number(newFields.carbonDioxideEmission)
              : null,
            fuelConsumptionCombined: newFields.fuelConsumptionCombined
              ? Number(newFields.fuelConsumptionCombined)
              : null,
            fuelConsumptionUrban: newFields.fuelConsumptionUrban
              ? Number(newFields.fuelConsumptionUrban)
              : null,
            fuelConsumptionExtraUrban: newFields.fuelConsumptionExtraUrban
              ? Number(newFields.fuelConsumptionExtraUrban)
              : null,
            emissionClass: newFields.emissionClass,
          },
          dimensions: {
            dimensions: newFields.dimensions
              ? {
                  width: newFields.dimensions.width ? Number(newFields.dimensions.width) : null,
                  height: newFields.dimensions.height ? Number(newFields.dimensions.height) : null,
                  length: newFields.dimensions.length ? Number(newFields.dimensions.length) : null,
                }
              : null,
            operatingWeight: newFields.dimensions?.operatingWeight
              ? Number(newFields.dimensions?.operatingWeight)
              : null,
            maximalLoadCapacity: newFields.dimensions?.maximalLoadCapacity
              ? Number(newFields.dimensions?.maximalLoadCapacity)
              : null,
            weight: newFields.weight ? Number(newFields.weight) : null,
            wheelBase: newFields.dimensions?.wheelBase
              ? Number(newFields.dimensions?.wheelBase)
              : null,
          },
          drive: newFields.drive,
          transmission: newFields.transmission,
          additionalInformation: {
            exteriorColor: newFields.exteriorColor,
          },
          firstRegistrationDate: getRegistrationDate(newFields),
          manufacturedOnMonth: newFields.manufacturedOnMonth
            ? // TBD: TS Typecast because there is a problem setting a value in select
              // eslint-disable-next-line no-restricted-syntax
              (Number(newFields.manufacturedOnMonth).toString() as unknown as number)
            : undefined,
          manufacturedOnYear:
            newFields.manufacturedOnYear === '0' || !newFields.manufacturedOnYear
              ? undefined
              : // eslint-disable-next-line no-restricted-syntax
                (newFields.manufacturedOnYear as unknown as number),
          features: newFields.features ?? undefined,
          featuresByManufacturer,
        };

        const createMode = !form.getState().initialValues.id;
        const changeAllowed =
          Boolean(newFields.vehicleType) && newFields.vehicleType === formValues.type;
        if (newFields.vehicleType && (createMode || changeAllowed)) {
          //@ts-expect-error invalid types of VehicleCreateFormState from pick
          decodedResult = {
            ...decodedResult,
            ...pick([...TYPE_DEPENDENT_FIELDS], newFields),
            type: newFields.vehicleType as VehicleTypeEnumObject,
          };
        }

        form.batch(() => {
          Object.entries(decodedResult).forEach(([key, value]) => {
            form.change(key as keyof VehicleCreateFormState, value);
          });
        });

        return decoded;
      }
    },

    []
  );

  return {
    onDecode,
  };
};
