import {isSameDay} from 'date-fns';
import {HStack, VStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../../types/FormControlProps';
import {Chip} from '../../Chip/Chip';
import {RelativeDateRange} from '../types';

interface DateRangePickerRelativeDateRangesProps
  extends FormControlProps<null | [Date, Date]>,
    TestIdProps {
  relativeDateRanges: RelativeDateRange[];
}

export function DateRangePickerRelativeDateRanges(props: DateRangePickerRelativeDateRangesProps) {
  return (
    <VStack spacing={3} data-testid={suffixTestId('relativeDateRangesContainer', props)}>
      {props.relativeDateRanges.map((relativeDateRange) => (
        <HStack
          key={`${relativeDateRange.value[0].toISOString()}-${relativeDateRange.value[1].toISOString()}`}
        >
          <Chip
            label={relativeDateRange.label}
            value={
              !!props.value &&
              isSameDay(props.value[0], relativeDateRange.value[0]) &&
              isSameDay(props.value[1], relativeDateRange.value[1])
            }
            size="small"
            onChange={() => props.onChange?.(relativeDateRange.value)}
          />
        </HStack>
      ))}
    </VStack>
  );
}
