import {Card, DataStatus} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {catchUnhandledDataGridActions, useWarehouseDataGridTreeFolder} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {LabourFolderTree} from '../../../../components/LabourFolderTree';

interface LabourListProps extends TestIdProps {
  directSaleId: string;
  onAddItemToBasket: (labourCatalogId: string) => Promise<void>;
}

export function RequestTabLabourList(props: LabourListProps) {
  const [folderTreeRef, dataGridModifier] = useWarehouseDataGridTreeFolder();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const labourCatalogId = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(labourCatalogId)) {
      throw new Error('Row id is not defined');
    }

    match(actionKey)
      .with('addToBasket', () => props.onAddItemToBasket(labourCatalogId).then(refreshData))
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => {
      const modifiedFilter = mergeAll([filter, {directSaleId: props.directSaleId}]);

      return dataGridModifier ? dataGridModifier?.queryModifier(modifiedFilter) : modifiedFilter;
    },
    [props.directSaleId, dataGridModifier]
  );

  return (
    <Card title={i18n.t('entity.warehouse.labels.itemsList')} isFullHeight>
      <HStack height="100%" spacing={4}>
        <Box
          flex={1}
          borderRight="1px solid"
          borderColor="palettes.neutral.40.100"
          paddingRight={4}
          height="100%"
          overflowY="auto"
        >
          <DataStatus>
            <VStack spacing={1} height="100%">
              <LabourFolderTree
                ref={folderTreeRef}
                data-testid={suffixTestId('articleFolderTree', props)}
              />
            </VStack>
          </DataStatus>
        </Box>

        <Box flex={3}>
          <DataGrid
            // DG must be re-rendered on folder change to update the query modifier
            // eslint-disable-next-line no-restricted-syntax
            key={dataGridModifier?.key}
            gridCode="labour-catalog-ds"
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            data-testid={suffixTestId('datagrid', props)}
          />
        </Box>
      </HStack>
    </Card>
  );
}
