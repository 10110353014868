import {AnyObjectSchema} from 'yup';
import {AnySchema} from 'yup/lib/schema';

import {path} from 'ramda';

export const getFieldDefaultValue = (
  _name: string | string[],
  schema?: AnyObjectSchema
): unknown => {
  const pathInSchema: string[] = [];
  let parts: string[] = [];

  if (typeof _name === 'string') {
    parts = _name.split('.');

    parts.forEach((part, i) => {
      if (i < parts.length) {
        pathInSchema.push('fields');
      }
      pathInSchema.push(part);
    });
  } else {
    _name.forEach((item) => {
      parts.push(...item.split(/\.|\[|\]/g).filter((i) => i !== ''));
    });

    parts.forEach((part, i) => {
      if (i < parts.length) {
        if (!isNaN(parseInt(parts[i]))) {
          pathInSchema.splice(i + 1, 0, 'innerType');
        } else if (parts[i - 1] !== 'innerType') {
          pathInSchema.push('fields');
        }
      }
      if (isNaN(parseInt(parts[i]))) {
        pathInSchema.push(part);
      }
    });
  }

  const getFieldSchema = path(pathInSchema);

  const property = getFieldSchema(schema) as AnySchema;
  if (!(property as any)?.exclusiveTests.defined && !(property as any)?.exclusiveTests.required) {
    return undefined;
  } else if (property?.spec?.nullable) {
    return null;
  } else if (property.type === 'string') {
    return '';
  } else {
    return undefined;
  }
};
