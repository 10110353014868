import type {BatchDeleteSeriesListRequestBody} from '../types/BatchDeleteSeriesListRequestBody';
import type {CancelablePromise} from '../types/CancelablePromise';
import type {CreateSeriesRequestBody} from '../types/CreateSeriesRequestBody';
import type {PatchSeriesRequestBody} from '../types/PatchSeriesRequestBody';
import type {Series} from '../types/Series';
import type {SeriesTypeEnum} from '../types/SeriesTypeEnum';
import {request as __request} from '../utils/request';

export class SeriesService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static batchDeleteSeries({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: BatchDeleteSeriesListRequestBody;
  }): CancelablePromise<void> {
    return __request({
      method: 'DELETE',
      url: '/dms/v1/series',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Series
   * @throws ApiError
   */
  public static createSeries({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: CreateSeriesRequestBody;
  }): CancelablePromise<Series> {
    return __request({
      method: 'POST',
      url: '/dms/v1/series',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Series
   * @throws ApiError
   */
  public static getSeriesList({
    search,
    type,
    authorization,
    xBranch,
  }: {
    search?: string;
    type?: Array<SeriesTypeEnum>;
    authorization?: string;
    xBranch?: string;
  }): CancelablePromise<Array<Series>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/series',
      headers: {
        Authorization: authorization,
        ...(xBranch ? {'X-Branch': xBranch} : {}),
      },
      query: {
        search,
        type,
      },
    });
  }

  /**
   * @returns Series
   * @throws ApiError
   */
  public static getSeries({
    seriesId,
    authorization,
  }: {
    seriesId: string;
    authorization?: string;
  }): CancelablePromise<Series> {
    return __request({
      method: 'GET',
      url: '/dms/v1/series/{seriesId}',
      path: {
        seriesId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns Series
   * @throws ApiError
   */
  public static patchSeries({
    seriesId,
    authorization,
    requestBody,
  }: {
    seriesId: string;
    authorization?: string;
    requestBody?: PatchSeriesRequestBody;
  }): CancelablePromise<Series> {
    return __request({
      method: 'PATCH',
      url: '/dms/v1/series/{seriesId}',
      path: {
        seriesId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
