import {
  GetServiceHandOverApiResponse,
  GetServiceHandOverApiArg,
  PostServiceHandOverApiResponse,
  PostServiceHandOverApiArg,
  PatchServiceHandOverApiResponse,
  PatchServiceHandOverApiArg,
  GetServiceHandOverApiResponseSchema,
  GetServiceHandOverApiArgSchema,
  PostServiceHandOverApiResponseSchema,
  PostServiceHandOverApiArgSchema,
  PatchServiceHandOverApiResponseSchema,
  PatchServiceHandOverApiArgSchema,
  GetServiceHandOverDefaultDriverApiApiResponse,
  GetServiceHandOverDefaultDriverApiApiArg,
} from '../types/metadaWorkshopHandover';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopHandoverApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceHandOver: build.query<GetServiceHandOverApiResponse, GetServiceHandOverApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/hand-over`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'handOver', id: queryArg.serviceCaseId}],
      extraOptions: {
        responseSchema: GetServiceHandOverApiResponseSchema,
        requestSchema: GetServiceHandOverApiArgSchema,
      },
    }),
    postServiceHandOver: build.mutation<PostServiceHandOverApiResponse, PostServiceHandOverApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/hand-over`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'handOver', id: queryArg.serviceCaseId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
      ],
      extraOptions: {
        responseSchema: PostServiceHandOverApiResponseSchema,
        requestSchema: PostServiceHandOverApiArgSchema,
      },
    }),
    patchServiceHandOver: build.mutation<
      PatchServiceHandOverApiResponse,
      PatchServiceHandOverApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/hand-over`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'handOver', id: queryArg.serviceCaseId},
      ],
      extraOptions: {
        responseSchema: PatchServiceHandOverApiResponseSchema,
        requestSchema: PatchServiceHandOverApiArgSchema,
      },
    }),
    getServiceHandOverDefaultDriverApi: build.query<
      GetServiceHandOverDefaultDriverApiApiResponse,
      GetServiceHandOverDefaultDriverApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service-case/${queryArg.serviceCaseId}/hand-over/default-driver`,
      }),
    }),
  }),
});

export const {
  useLazyGetServiceHandOverQuery,
  useGetServiceHandOverQuery,
  usePostServiceHandOverMutation,
  usePatchServiceHandOverMutation,
} = metadaWorkshopHandoverApi;
