import type {PermissionsRecordType} from '../permissions';

export const carAuditPermissions = {
  createInspection: {
    actionId: 'CREATE',
    resourceId: 'CAR_AUDIT_INSPECTION',
    scopes: [],
  },
  updateInspection: {
    actionId: 'UPDATE',
    resourceId: 'CAR_AUDIT_INSPECTION',
    scopes: ['CAR_AUDIT_INSPECTION.INSPECTION_TYPE'],
  },
  readInspection: {
    actionId: 'READ',
    resourceId: 'CAR_AUDIT_INSPECTION',
    scopes: ['CAR_AUDIT_INSPECTION.INSPECTION_TYPE'],
  },
  closeInspection: {
    actionId: 'CLOSE',
    resourceId: 'CAR_AUDIT_INSPECTION',
    scopes: ['CAR_AUDIT_INSPECTION.INSPECTION_TYPE'],
  },
  finishInspection: {
    actionId: 'FINISH',
    resourceId: 'CAR_AUDIT_INSPECTION',
    scopes: ['CAR_AUDIT_INSPECTION.INSPECTION_TYPE'],
  },
  carAuditShareLink: {
    resourceId: 'CAR_AUDIT_INSPECTION',
    actionId: 'SHARE',
    scopes: [],
  },
} satisfies PermissionsRecordType;
