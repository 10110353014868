import {HStack, Show, Text} from 'platform/foundation';

import {Fragment, ReactNode} from 'react';

import {EntityResourceIds} from '@omnetic-dms/api';

import {buildArray, DOT_CHARACTER, suffixTestId, TestIdProps} from 'shared';

import {AssigneeV2} from '../../Assignment/AssigneeV2';
import {BusinessCaseBranch} from './BusinessCaseBranch';
import {BusinessCaseCustomId} from './BusinessCaseCustomId';
import {InterestBranch} from './InterestBranch';
import {KeyLocation} from './KeyLocation';
import {Location} from './Location';
import {ParkingPlace} from './ParkingPlace';
import {ServiceBranch} from './ServiceBranch';
import {VehicleCustomId} from './VehicleCustomId';
import {VehicleLocation} from './VehicleLocation';
import {VehicleWarehouse} from './VehicleWarehouse';

export type EntityHeaderControl =
  | 'LOCATION'
  | 'VEHICLE_LOCATION'
  | 'SERVICE_BRANCH'
  | 'VEHICLE_WAREHOUSE'
  | 'PARKING'
  | 'KEY'
  | 'ASSIGNEE'
  | 'BC_CUSTOM_ID'
  | 'VH_CUSTOM_ID'
  | 'BC_BRANCH'
  | 'INTEREST_BRANCH';

export interface EntityHeaderControlsProps extends TestIdProps {
  controls: EntityHeaderControl[];
  recordId: string;
  resourceId?: EntityResourceIds;
}

export function EntityHeaderControls(props: EntityHeaderControlsProps) {
  if (!props.controls.length) {
    return null;
  }

  const controlsComponent = buildArray<{component: ReactNode; key: string}>()
    .when(props.controls.includes('BC_BRANCH'), {
      key: 'BC_BRANCH',
      component: (
        <BusinessCaseBranch
          recordId={props.recordId}
          data-testid={suffixTestId('control', props)}
        />
      ),
    })
    .when(props.controls.includes('INTEREST_BRANCH'), {
      key: 'INTEREST_BRANCH',
      component: (
        <InterestBranch recordId={props.recordId} data-testid={suffixTestId('control', props)} />
      ),
    })
    .when(props.controls.includes('LOCATION'), {
      key: 'LOCATION',
      component: (
        <Location recordId={props.recordId} data-testid={suffixTestId('control', props)} />
      ),
    })
    .when(props.controls.includes('VEHICLE_LOCATION'), {
      key: 'VEHICLE_LOCATION',
      component: (
        <VehicleLocation recordId={props.recordId} data-testid={suffixTestId('control', props)} />
      ),
    })
    .when(props.controls.includes('SERVICE_BRANCH'), {
      key: 'SERVICE_BRANCH',
      component: (
        <ServiceBranch recordId={props.recordId} data-testid={suffixTestId('control', props)} />
      ),
    })
    .when(props.controls.includes('VEHICLE_WAREHOUSE'), {
      key: 'VEHICLE_WAREHOUSE',
      component: (
        <VehicleWarehouse vehicleId={props.recordId} data-testid={suffixTestId('control', props)} />
      ),
    })
    .when(props.controls.includes('PARKING'), {
      key: 'PARKING',
      component: (
        <ParkingPlace recordId={props.recordId} data-testid={suffixTestId('control', props)} />
      ),
    })
    .when(props.controls.includes('KEY'), {
      key: 'KEY',
      component: (
        <KeyLocation recordId={props.recordId} data-testid={suffixTestId('control', props)} />
      ),
    })
    .when(props.controls.includes('ASSIGNEE') && props.resourceId, {
      key: 'ASSIGNEE',
      component: (
        <AssigneeV2
          recordId={props.recordId}
          resourceId={props.resourceId!}
          data-testid={suffixTestId('control', props)}
        />
      ),
    })
    .when(props.controls.includes('BC_CUSTOM_ID'), {
      key: 'BC_CUSTOM_ID',
      component: (
        <BusinessCaseCustomId
          recordId={props.recordId}
          data-testid={suffixTestId('control', props)}
        />
      ),
    })
    .when(props.controls.includes('VH_CUSTOM_ID'), {
      key: 'VH_CUSTOM_ID',
      component: (
        <VehicleCustomId recordId={props.recordId} data-testid={suffixTestId('control', props)} />
      ),
    });

  return (
    <HStack spacing={1} align="center" wrap>
      {controlsComponent.map(({key, component}, index) => (
        <Fragment key={key}>
          <Show when={index !== 0}>
            <Text color="tertiary" size="small">
              {DOT_CHARACTER}
            </Text>
          </Show>
          {component}
        </Fragment>
      ))}
    </HStack>
  );
}
