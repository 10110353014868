export const EMPTY_BRANCH = {
  authorizations: [],
  branchId: undefined,
  professionId: undefined,
  workshopData: {
    costCenterId: undefined,
    hourlyRate: undefined,
    performancePercentage: undefined,
    workTeamId: undefined,
  },
};
