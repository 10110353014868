import {ZodArray, ZodNullable, ZodObject, ZodOptional, ZodTuple, ZodTypeAny} from 'zod';

import {ZodObjectMapper} from '../types/ZodObjectMapper';

/**
 * Applies a transformation function recursively to all components of a Zod schema.
 * This function traverses a Zod schema and applies a provided mapper function to each
 * ZodObject found within the schema. It supports transformations across different
 * types of Zod schemas such as ZodObject, ZodArray, ZodOptional, ZodNullable, and ZodTuple.
 *
 * @param {ZodTypeAny} schema - The Zod schema to which the transformation is to be applied.
 * The schema can be of any type derived from Zod's base types.
 * @param {ZodObjectMapper<any, any>} map - A function that takes a ZodObject and returns
 * a transformed ZodObject. This function is applied to each ZodObject encountered in the schema.
 * @example
 * // Define a Zod schema
 * const userSchema = ZodObject({
 *   name: ZodString(),
 *   age: ZodNumber(),
 *   address: ZodObject({
 *     street: ZodString(),
 *     city: ZodString(),
 *   })
 * });
 *
 * // Define a mapper function to make all fields optional
 * const optionalMapper = (schema) => schema.optional();
 *
 * // Apply the mapper function recursively
 * const optionalUserSchema = zodDeepApplyObject(userSchema, optionalMapper);
 */
export const zodDeepApplyObject = (schema: ZodTypeAny, map: ZodObjectMapper<any, any>): any => {
  if (schema instanceof ZodObject) {
    const newShape: Record<string, ZodTypeAny> = {};
    for (const key in schema.shape) {
      const fieldSchema = schema.shape[key];
      newShape[key] = zodDeepApplyObject(fieldSchema, map);
    }
    const newObject = new ZodObject({
      ...schema._def,
      shape: () => newShape,
    });
    return map(newObject);
  } else if (schema instanceof ZodArray) {
    return ZodArray.create(zodDeepApplyObject(schema.element, map));
  } else if (schema instanceof ZodOptional) {
    return ZodOptional.create(zodDeepApplyObject(schema.unwrap(), map));
  } else if (schema instanceof ZodNullable) {
    return ZodNullable.create(zodDeepApplyObject(schema.unwrap(), map));
  } else if (schema instanceof ZodTuple) {
    return ZodTuple.create(schema.items.map((item: any) => zodDeepApplyObject(item, map)));
  } else {
    return schema;
  }
};
