import {isDate} from 'date-fns';
import {
  Chips,
  Separator,
  DatePicker,
  NumberInput,
  useTranslationContext,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {equals, intersection} from 'ramda';
import {isString} from 'ramda-adjunct';

import {getApiDateString, suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {IFilter, IFilterParams} from '../../types/AgGridTypes';
import {QuickFilters} from '../../types/Api';
import {parseDateFilter} from '../../utils/parseDateFilter';

type RelativeInputType = {relativeInputValue: number; relativeInputKey: string};

export type DateFilterProps = {
  min: string | null;
  max: string | null;
  relativeDates: Array<{value: string; label: string}>;
  relativeInputs: Array<{value: string; label: string}>;
  isDisabled: boolean;
} & IFilterParams &
  QuickFilters &
  TestIdProps;

function DateFilterComponent(
  {
    filterChangedCallback,
    min,
    max,
    relativeDates,
    column,
    isDisabled,
    relativeInputs = [],
    quickFilters,
    ...props
  }: DateFilterProps,
  ref: ForwardedRef<IFilter>
) {
  const t = useTranslationContext();
  const minDate = useMemo(() => parseDateFilter(min), [min]);
  const maxDate = useMemo(() => parseDateFilter(max), [max]);

  const isActive = useRef<boolean>(false);
  const [filterValue, _setFilterValue] = useState<RelativeInputType | Date | string | undefined>();
  const setFilterValue = useCallback((value: RelativeInputType | Date | string | undefined) => {
    isActive.current = value !== undefined;
    _setFilterValue(value);
  }, []);
  const {onChipsChange} = useFilterOnChipsChange({
    filterChangedCallback,
    setFilterValue,
    defaultValue: undefined,
  });

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return isActive.current;
    },

    doesFilterPass() {
      return true;
    },

    getModel() {
      if (!filterValue || typeof filterValue === 'string') {
        return filterValue;
      }

      if ('relativeInputKey' in filterValue) {
        return filterValue;
      } else {
        return getApiDateString(filterValue);
      }
    },

    setModel(model: Date | string | undefined | null) {
      if (equals(model, filterValue)) {
        return;
      }

      if (isString(model)) {
        const parsedDate = parseDateFilter(model);
        setFilterValue(parsedDate ?? model);
      } else {
        setFilterValue(model ?? undefined);
      }

      filterChangedCallback();
    },
  }));

  const [datePickerValue, chipsValue, inputValue] = useMemo(
    () => [
      /**
       * @about filterValue as Date
       * isDate function is not typed correctly in date-fns
       * It's missing the `value is Date` type guard
       * https://github.com/date-fns/date-fns/issues/1907
       */
      isDate(filterValue) ? (filterValue as Date) : null,
      typeof filterValue === 'string' ? [filterValue] : [],
      typeof filterValue === 'object' && 'relativeInputValue' in filterValue
        ? filterValue.relativeInputValue
        : 0,
    ],
    [filterValue]
  );

  const onDateChange = useCallback((value: Date | null) => {
    setFilterValue(value === null ? undefined : value);
    filterChangedCallback();
  }, []);
  const onInputChange = useCallback((relativeInputValue: number, relativeInputKey: string) => {
    if (isNaN(relativeInputValue)) {
      setFilterValue(undefined);
    } else {
      setFilterValue({relativeInputValue, relativeInputKey});
    }
    filterChangedCallback();
  }, []);

  const isQuickFilterValue = intersection([filterValue], quickFiltersConst) as string[];

  return (
    <VStack spacing={4}>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('date-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator spacing={0} />
        </>
      )}
      {relativeDates && relativeDates.length > 0 && (
        <Chips
          isDisabled={isDisabled}
          value={chipsValue}
          options={relativeDates}
          onChange={onChipsChange}
          data-testid={suffixTestId('date-filter-relativeDates', props)}
          isDeselectable
        />
      )}
      <DatePicker
        isDisabled={isDisabled}
        placeholder={t('page.datagrid.filter.datePlaceholder', {
          field: column.getColDef().headerName,
        })}
        value={datePickerValue}
        onChange={onDateChange}
        minDate={minDate}
        maxDate={maxDate}
        data-testid={suffixTestId('date-filter', props)}
      />
      {relativeInputs.map((relativeInput) => (
        <Box key={relativeInput.value} width="100%" paddingBottom={4} position="relative">
          <NumberInput
            label={relativeInput.label}
            isDisabled={isDisabled}
            onChange={(value) => onInputChange(value ?? 0, relativeInput.value)}
            value={inputValue}
            data-testid={suffixTestId(`date-filter-relativeInput-${relativeInput.label}`, props)}
          />
        </Box>
      ))}
    </VStack>
  );
}

export const DateFilter = forwardRef<IFilter, DateFilterProps>(DateFilterComponent);
