import {IconButton, openDialog, Tooltip} from 'platform/components';
import {Box, HStack, Icon, Text, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {EMPTY_PLACEHOLDER, numberToTimeString} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useJobTime} from '../../../hooks/useJobTime';
import {JobTrackedInfo} from '../../JobTrackedInfo/JobTrackedInfo';

interface JobTrackedTimeProps extends TestIdProps {
  serviceCaseId?: string;
  serviceOrderId?: string;
  serviceCaseJobId?: string | Nullish;
  jobName: string;
  estimatedTime?: number | Nullish;
  isDisabled?: boolean;
}

export function JobTrackedTime(props: JobTrackedTimeProps) {
  const jobTime = useJobTime({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
    serviceCaseJobId: props.serviceCaseJobId,
  });

  return (
    <VStack align="flex-end" minWidth={32}>
      <HStack align="center">
        <Text size="small" alternative data-testid={suffixTestId('trackedTime', props)}>
          {jobTime?.totalTimeTracked?.substring(0, 5) ?? '00:00'}
        </Text>
        <IconButton
          isDisabled={props.isDisabled}
          size="small"
          icon="devices/access_time"
          severity="informational"
          onClick={() =>
            openDialog(
              <JobTrackedInfo
                jobName={props.jobName}
                estimatedTime={props.estimatedTime}
                serviceCaseId={props.serviceCaseId}
                serviceOrderId={props.serviceOrderId}
                serviceCaseJobId={props.serviceCaseJobId}
              />,
              {title: i18n.t('entity.orderRequest.labels.workReport')}
            )
          }
          data-testid={suffixTestId('workReport', props)}
        />
      </HStack>
      <HStack align="center" spacing={1}>
        <Text size="xSmall" color="secondary" data-testid={suffixTestId('estimatedTime', props)}>
          {isNotNil(props.estimatedTime)
            ? numberToTimeString(props.estimatedTime)
            : EMPTY_PLACEHOLDER}
        </Text>
        <Box paddingRight={1}>
          <Tooltip label={i18n.t('entity.orderRequest.labels.estimatedTime')} placement="top">
            <Icon value="image/timelapse" color="palettes.neutral.400.100" size={4} />
          </Tooltip>
        </Box>
      </HStack>
    </VStack>
  );
}
