import {isFeatureEnabled} from 'feature-flags';

import {isEmpty, isNil} from 'ramda';

import {Nullish} from 'shared';

export const resolveFeatureFlag = (featureFlag: string | Nullish) => {
  if (isNil(featureFlag) || isEmpty(featureFlag)) {
    return true;
  }

  return isFeatureEnabled(featureFlag);
};
