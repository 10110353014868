import {FC, useCallback, useState} from 'react';

import {TestIdProps} from 'shared';

import {SMART_SEARCH_KEY} from '../constants/smartSearchKey';
import {useDataGridFiltersModel} from '../context/useDataGridFiltersModel';
import {GridApi} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {QuickFilterRenderer} from './QuickFilterRenderer';

export interface QuickFilterWrapperProps extends TestIdProps {
  gridProps: DataGridProps;
  gridApi?: GridApi;
}

export const QuickFilterWrapper: FC<QuickFilterWrapperProps> = (props) => {
  const {filterValues} = useDataGridFiltersModel();
  const {gridProps, gridApi} = props;

  const [quickFilterValue, setQuickFilterValue] = useState<string>(
    (filterValues[SMART_SEARCH_KEY] as string) ?? ''
  );

  const submitQuickFilter = useCallback(
    (quickFilter?: string) => {
      if (!gridApi) {
        return;
      }

      const filterModel = gridApi.getFilterModel() ?? {};
      if (quickFilter) {
        filterModel.smartSearch = quickFilter;
        gridApi.setFilterModel(filterModel);
      } else {
        filterModel.smartSearch = null;
        gridApi.setFilterModel(filterModel);
      }
    },
    [gridApi]
  );

  return (
    <QuickFilterRenderer
      submitQuickFilter={submitQuickFilter}
      cancelQuickFilter={submitQuickFilter}
      quickFilterValue={quickFilterValue}
      onQuickFilterValueChange={setQuickFilterValue}
      gridCode={gridProps.gridCode as string}
      data-testid={props['data-testid']}
      gridApi={gridApi}
    />
  );
};
