import {Nullish} from '../types/Nullish';

export function isURLDangerous(url: string | Nullish) {
  if (!url) {
    return false;
  }

  if (typeof url !== 'string') {
    return false;
  }

  const lowercased = String(url).toLowerCase();

  return lowercased.includes('javascript:') || lowercased.includes('html;base64');
}
