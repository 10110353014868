import {captureException} from '@sentry/browser';
import i18n from 'i18next';
import {setLocale} from 'yup';

import {initReactI18next} from 'react-i18next';

import {browserStorageKey} from '@omnetic-dms/config';

import {CI_MODE, CI_MODE_ON} from 'shared';

import cs_CZ from './locales/cs-CZ.json';
import de_DE from './locales/de-DE.json';
import en_US from './locales/en-GB.json';
import es_ES from './locales/es-ES.json';
import fr_FR from './locales/fr-FR.json';
import it_IT from './locales/it-IT.json';
import pl_PL from './locales/pl-PL.json';
import sk_SK from './locales/sk-SK.json';

type DetectCallback = (language: string | null) => void;

i18n
  .use({
    type: 'languageDetector',
    async: true,
    async detect(callback: DetectCallback): Promise<void> {
      const isCiModeOn = localStorage.getItem(browserStorageKey.CI_MODE) === CI_MODE_ON;

      if (isCiModeOn) {
        await callback(CI_MODE);
      } else {
        await callback(localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE));
      }
    },
    init: () => undefined,
    cacheUserLanguage: () => undefined,
  })
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'en',
      defaultNS: 'translation',
      missingKeyHandler: (lngs, ns, key) => {
        captureException(new Error(`Missing translation key: ${key} in ${window.location.href}`));
        return key;
      },
      resources: {
        cs: {
          translation: cs_CZ,
        },
        de: {
          translation: de_DE,
        },
        en: {
          translation: en_US,
        },
        es: {
          translation: es_ES,
        },
        fr: {
          translation: fr_FR,
        },
        it: {
          translation: it_IT,
        },
        pl: {
          translation: pl_PL,
        },
        sk: {
          translation: sk_SK,
        },
      },
      partialBundledLanguages: true,
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
        prefix: '{',
        suffix: '}',
      },
      react: {
        useSuspense: true,
      },
      pluralSeparator: '.',
    },
    (_error, t) => {
      setLocale({
        mixed: {
          default: ({label}) => t('general.errors.mixed.invalid', {label}),
          required: ({label}) => t('general.errors.mixed.required', {label}),
        },
        string: {
          email: ({label}) => t('general.errors.string.email', {label}),
          length: ({label, length}) => t('general.errors.string.length', {label, length}),
          min: ({label, min}) => t('general.errors.string.min', {label, min}),
          max: ({label, max}) => t('general.errors.string.max', {label, max}),
          lowercase: ({label}) => t('general.errors.string.lowercase', {label}),
          uppercase: ({label}) => t('general.errors.string.uppercase', {label}),
          matches: ({label, regex}) => t('general.errors.string.matches', {label, regex}),
          url: ({label}) => t('general.errors.string.url', {label}),
        },
        number: {
          min: ({label, min}) => t('general.errors.number.min', {label, min}),
          max: ({label, max}) => t('general.errors.number.max', {label, max}),
          negative: ({label}) => t('general.errors.number.negative', {label}),
          positive: ({label}) => t('general.errors.number.positive', {label}),
          integer: ({label}) => t('general.errors.number.integer', {label}),
        },
      });
    }
  );

export {i18n};
