import {redirectLink} from '@omnetic-dms/config';
import {loginRoutes} from '@omnetic-dms/routes';

export const redirectToLoginPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(redirectLink);

  const query = searchParams.toString();

  const redirectUrl = `${window.location.pathname}${query ? `?${query}` : ''}${
    window.location.hash
  }`;

  sessionStorage.setItem(redirectLink, redirectUrl);

  return `${loginRoutes.login}`;
};
