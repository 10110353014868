import type {CancelablePromise} from '../types/CancelablePromise';
import type {VehiclePhotoCopyRequestBody} from '../types/VehiclePhotoCopyRequestBody';
import type {VehiclePhotoDeleteRequestBody} from '../types/VehiclePhotoDeleteRequestBody';
import type {VehiclePhotoUpdateAlbumRequestBody} from '../types/VehiclePhotoUpdateAlbumRequestBody';
import {request as __request} from '../utils/request';

export class PhotoService {
  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static copyPhoto({
    vehicleId,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    authorization?: string;
    requestBody?: VehiclePhotoCopyRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/vehicle/photo/copy/{vehicleId}',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static deletePhoto({
    vehicleId,
    albumId,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    albumId: string;
    authorization?: string;
    requestBody?: VehiclePhotoDeleteRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'DELETE',
      url: '/dms/v1/vehicle/photo/{vehicleId}/{albumId}',
      path: {
        vehicleId,
        albumId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static updatePhotoAlbum({
    vehicleId,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    authorization?: string;
    requestBody?: VehiclePhotoUpdateAlbumRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/vehicle/photo/{vehicleId}',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
