import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object} from 'yup';

import {useGetEsignoServiceQuery, usePatchEsignoServiceMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {yupString} from 'shared';

type FormValues = {
  credentials: {
    apiKey: 'string';
  };
};

export function ApiKeyForm() {
  const {data: esigno} = useGetEsignoServiceQuery();

  const [updateEsigno, {isLoading: isPatchingEsigno}] = usePatchEsignoServiceMutation();

  const handleUpdateEsignoCredentials: FormSubmitHandler<FormValues> = async (data) =>
    await updateEsigno(data)
      .unwrap()
      .then(() => showNotification.success(i18n.t('general.notifications.successfullyCompleted')))
      .then(closeCurrentDialog)
      .catch(handleApiError);

  return (
    <Form<FormValues>
      schema={schema}
      onSubmit={handleUpdateEsignoCredentials}
      defaultValues={{credentials: {apiKey: esigno?.credentials?.apiKey ?? undefined}}}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            type="text"
            name="credentials.apiKey"
            label={i18n.t('page.settings.labels.esignoApiKey')}
            data-testid={testIds.settings.esignoDetail('apiKey')}
          />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.close')}
              onClick={closeCurrentDialog}
              variant="secondary"
              data-testid={testIds.settings.esignoDetail('cancel-apiKey')}
            />
            <FormButton
              control={control}
              type="submit"
              isLoading={isPatchingEsigno}
              title={i18n.t('general.actions.save')}
              data-testid={testIds.settings.esignoDetail('submit-apiKey')}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = object({
  credentials: object({
    apiKey: yupString.required(),
  }).required(),
});
