import {closeCurrentDialog} from 'platform/components';

import {useUpdateVehicleFinancialInstitutionMutation} from '@omnetic-dms/api';
import {handleApiError} from '@omnetic-dms/shared';

import {
  VehicleFinancialInstitutionForm,
  VehicleFinancialInstitutionFormProps,
} from './VehicleFinancialInstitutionForm';

interface VehicleFinancialInstitutionEditDialogProps {
  vehicleId: string;
  defaultValues: VehicleFinancialInstitutionFormProps['defaultValues'];
}

export function VehicleFinancialInstitutionEditDialog(
  props: VehicleFinancialInstitutionEditDialogProps
) {
  const [update] = useUpdateVehicleFinancialInstitutionMutation();

  const onSubmit: VehicleFinancialInstitutionFormProps['onSubmit'] = (values) =>
    update({
      vehicleId: props.vehicleId,
      body: {
        isFinanced: values.isFinanced,
        institution: values.isFinanced ? values.institution : null,
      },
    })
      .unwrap()
      .then(() => closeCurrentDialog())
      .catch(handleApiError);

  return (
    <VehicleFinancialInstitutionForm onSubmit={onSubmit} defaultValues={props.defaultValues} />
  );
}
