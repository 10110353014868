import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {Packages} from './(sections)/Packages/Packages';
import {PackagesCategory} from './(sections)/PackagesCategory/PackagesCategory';

export function WorkshopServicePackages() {
  const navigate = useNavigate();

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.workshopServicePackages.labels.title'),
    actions: [
      {
        type: 'button',
        title: i18n.t('entity.workshopServicePackages.actions.newServicePackage'),
        onClick: () => navigate(settingsRoutes.workshopCreateServicePackages),
      },
    ],
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.workshopServicePackages('template')}
      header={header}
      tabs={[
        {
          queryId: 'packages',
          title: i18n.t('entity.workshopServicePackages.labels.packages'),
          content: <Packages data-testid={testIds.settings.workshopServicePackages('packages')} />,
        },
        {
          queryId: 'categories',
          title: i18n.t('entity.workshopServicePackages.labels.categories'),
          content: (
            <PackagesCategory data-testid={testIds.settings.workshopServicePackages('category')} />
          ),
        },
      ]}
    />
  );
}
