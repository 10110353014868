import {format} from 'date-fns';

import {isNil} from 'ramda';

import {Nullish} from 'shared';

const testDate = new Date();

export function validateDateTimeFormat(value: string | Nullish) {
  if (isNil(value)) {
    return false;
  }
  try {
    format(testDate, value);
    return true;
  } catch (e) {
    return false;
  }
}
