import {head, isNotNil} from 'ramda';

export type FetchErrorWithMessage = {data: {errors: {message: string; code: string}[]}};
export const getFirstErrorMessage = (error: unknown) => {
  if (!isFetchErrorWithMessage(error)) {
    return null;
  }

  const firstError = head(error.data.errors);

  return `${firstError?.code}: ${firstError?.message}`;
};

const isFetchErrorWithMessage = (error: unknown): error is FetchErrorWithMessage => {
  const firstErrorMessage = (error as FetchErrorWithMessage)?.data?.errors?.[0]?.message;

  return isNotNil(firstErrorMessage);
};
