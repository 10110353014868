import {path} from 'ramda';

import {testIds} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

export function AuctionsList() {
  const actionCallback: ActionCallback = ({actionKey, rowData}) => {
    const urlDetail =
      path<string>(['urlDetail', 'value'], rowData) ?? path<string>(['urlDetail'], rowData);

    switch (actionKey) {
      case 'detail':
        if (!urlDetail) {
          return;
        }
        window.open(urlDetail, '_blank');
        return;
    }
  };

  return (
    <Main data-testid={testIds.vehicles.customers('page')} isFullHeight>
      <DataGrid
        data-testid="quick-purchase"
        gridCode="vehicle-auction"
        actionCallback={actionCallback}
      />
    </Main>
  );
}
