import {Card, DataStatus} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {useState} from 'react';

import {defaultTo, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  GetReceiveNoteCorrectionResponse,
  GetReceiveNoteResponse,
  useGetAuthorizationProfilesQuery,
  useGetCurrenciesQuery,
  useGetPaymentTypeEnumQuery,
  useGetSuppliersQuery,
  useGetTenantQuery,
  useGetWarehouseQuery,
  useGetWarehousesQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {DEFAULT_CURRENCY, Section} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {BasicInformationForm} from './components/BasicInformationForm';
import {DeliveryNoteDetailCard} from './components/DeliveryNoteDetailCard';
import {ReceiveNoteCorrectionItemsList} from './components/ReceiveNoteCorrectionItemsList';
import {ReceiveNoteDetailCard} from './components/ReceiveNoteDetailCard';

interface OverviewProps extends RequiredTestIdProps {
  isReceiveNoteCorrectionLoading: boolean;
  receiveNote?: GetReceiveNoteResponse;
  receiveNoteCorrection?: GetReceiveNoteCorrectionResponse;
}

export function Overview(props: OverviewProps) {
  const {receiveNoteCorrectionId} = useWarehouseParams();

  const receiveNoteId = defaultTo('', props.receiveNoteCorrection?.receiveNote?.receiveNoteId);

  const [isCreatingReceiveNoteCorrection, setIsCreatingReceiveNoteCorrection] = useState<boolean>(
    isNilOrEmpty(receiveNoteCorrectionId)
  );

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const {
    data: warehouses,
    isLoading: areWarehousesLoading,
    isError: hasWarehousesError,
  } = useGetWarehousesQuery();

  const {
    data: warehouseByReceiveNoteCorrection,
    isLoading: isWarehouseByReceiveNoteCorrectionLoading,
    isError: hasWarehouseByReceiveNoteCorrectionError,
  } = useGetWarehouseQuery(
    {warehouseId: props.receiveNoteCorrection?.creditNote?.warehouseId!},
    {skip: isNil(props.receiveNoteCorrection?.creditNote?.warehouseId)}
  );

  const {data: tenant, isLoading: isTenantLoading, isError: hasTenantError} = useGetTenantQuery();

  const {
    data: authorizationProfiles,
    isLoading: areAuthorizationProfilesLoading,
    isError: hasAuthorizationProfilesError,
  } = useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id!}, {skip: isNil(tenant)});

  const {
    data: paymentTypes,
    isLoading: arePaymentTypesLoading,
    isError: hasPaymentTypesError,
  } = useGetPaymentTypeEnumQuery();

  const {
    data: currencies,
    isLoading: areCurrenciesLoading,
    isError: hasCurrenciesError,
  } = useGetCurrenciesQuery();

  const isLoading =
    areSuppliersLoading ||
    areWarehousesLoading ||
    isWarehouseByReceiveNoteCorrectionLoading ||
    isTenantLoading ||
    areAuthorizationProfilesLoading ||
    arePaymentTypesLoading ||
    areCurrenciesLoading;

  const hasError =
    hasSuppliersError ||
    hasWarehousesError ||
    hasWarehouseByReceiveNoteCorrectionError ||
    hasTenantError ||
    hasAuthorizationProfilesError ||
    hasPaymentTypesError ||
    hasCurrenciesError;

  const isReceiveNoteCorrectionPending =
    props.receiveNoteCorrection?.creditNote?.state === 'PENDING';

  const currencyByReceiveNoteCorrectionWarehouse = defaultTo(
    DEFAULT_CURRENCY,
    warehouseByReceiveNoteCorrection?.currency
  );

  return (
    <Section data-testid={props['data-testid']}>
      <DataStatus
        isLoading={isLoading}
        isError={hasError}
        minHeight="100vh"
        data-testid={suffixTestId('status', props)}
      >
        <Box>
          <VStack spacing={4}>
            {isCreatingReceiveNoteCorrection ? (
              <Card
                title={i18n.t('general.labels.basicInformation')}
                data-testid={suffixTestId('cards.basicInformation', props)}
              >
                <BasicInformationForm
                  isCreatingReceiveNoteCorrection={isCreatingReceiveNoteCorrection}
                  receiveNote={props.receiveNote}
                  receiveNoteCorrection={props.receiveNoteCorrection}
                  suppliers={suppliers}
                  warehouses={warehouses}
                  authorizationProfiles={authorizationProfiles}
                  paymentTypes={paymentTypes}
                  currencies={currencies}
                  onClose={() => setIsCreatingReceiveNoteCorrection(false)}
                  data-testid={suffixTestId('forms.basicInformation', props)}
                />
              </Card>
            ) : (
              <DataStatus
                isLoading={props.isReceiveNoteCorrectionLoading}
                isError={isNil(props.receiveNoteCorrection)}
                minHeight={380}
              >
                <VStack spacing={4}>
                  <ReceiveNoteDetailCard
                    receiveNoteCorrection={props.receiveNoteCorrection!}
                    suppliers={suppliers}
                    warehouses={warehouses}
                    authorizationProfiles={authorizationProfiles}
                    paymentTypes={paymentTypes}
                    currencies={currencies}
                    data-testid={suffixTestId('cards.receiveNoteDetail', props)}
                  />
                  <DeliveryNoteDetailCard
                    receiveNoteCorrection={props.receiveNoteCorrection!}
                    suppliers={suppliers}
                    warehouses={warehouses}
                    authorizationProfiles={authorizationProfiles}
                    paymentTypes={paymentTypes}
                    currencies={currencies}
                    data-testid={suffixTestId('cards.deliveryNoteDetail', props)}
                  />
                </VStack>
              </DataStatus>
            )}

            <ReceiveNoteCorrectionItemsList
              receiveNoteId={receiveNoteId}
              isCreatingReceiveNoteCorrection={isCreatingReceiveNoteCorrection}
              isReceiveNoteCorrectionPending={isReceiveNoteCorrectionPending}
              totalPrice={props.receiveNoteCorrection?.total}
              totalPriceCurrency={currencyByReceiveNoteCorrectionWarehouse}
              data-testid={suffixTestId('cards.receiveNoteCorrectionItems', props)}
            />
          </VStack>
        </Box>
      </DataStatus>
    </Section>
  );
}
