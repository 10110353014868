import {
  Avatar,
  Choice,
  Dropdown,
  DropdownItem,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Center, HStack, Heading, Show, Space, Text, VStack} from 'platform/foundation';

import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import {
  SetUserSettingsItemApiArg,
  buildUrl,
  selectActiveBranchId,
  selectCurrentUserInfo,
  selectUserBranchOptions,
  setActiveBranchId,
  useSetUserSettingsItemMutation,
} from '@omnetic-dms/api';
import {browserStorageKey} from '@omnetic-dms/config';
import i18n from '@omnetic-dms/i18n';
import {loginRoutes, myProfileRoutes, settingsRoutes} from '@omnetic-dms/routes';
import {
  EMPTY_PLACEHOLDER,
  handleApiError,
  usePermissions,
  useUserFullName,
} from '@omnetic-dms/shared';

import {useBoolean} from 'shared';

import {HeaderButtonWrapper} from '../../HeaderButtonWrapper/HeaderButtonWrapper';

export function MyProfileAndSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [hasReadSettingsPermission] = usePermissions({permissionKeys: ['readSettings']});

  const {data: userInfo} = useSelector(selectCurrentUserInfo);
  const userFullName = useUserFullName();
  const activeBranchId = useSelector(selectActiveBranchId);
  const branchOptions = useSelector(selectUserBranchOptions);
  const [updateUserSettings] = useSetUserSettingsItemMutation();

  const [isOpen, setOpen, setClosed] = useBoolean();

  const handleBranchChange = (value: string | number | string[] | null) => {
    if (typeof value !== 'string') {
      return;
    }

    const arg: SetUserSettingsItemApiArg = {
      settingKey: 'active_branch',
      userSettingRequestBody: {value},
    };

    updateUserSettings(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .then(() => {
        dispatch(setActiveBranchId(value));
      })
      .catch(handleApiError);
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem(browserStorageKey.LAST_KNOWN_LANGUAGE, i18n.language);
    window.location.href = buildUrl(loginRoutes.login);
  };

  const onMenuItemClick = (url: string) => {
    localStorage.setItem(browserStorageKey.BACKUP_URL, location.pathname + location.search);
    navigate(url);
  };

  const getBranchByActiveId = useMemo(
    () => branchOptions?.find((b) => b.value === activeBranchId) || null,
    [branchOptions, activeBranchId]
  );

  if (!userInfo) {
    return null;
  }

  return (
    <Dropdown
      onOpen={setOpen}
      onClose={setClosed}
      closeOnBlur
      closeOnSelect
      dropdownControl={<AvatarIconControl isOpen={isOpen} />}
      data-testid="layout-header-myProfileAndSettings-dropdown"
    >
      <Box padding={2}>
        <Box minWidth={75}>
          <HStack align="center">
            <Avatar
              data-testid="layout-header-myProfileAndSettings"
              name={userFullName ?? EMPTY_PLACEHOLDER}
              size="default"
              variant="circle"
            />
            <Space horizontal={4} />
            <VStack>
              <Heading
                alternative
                headingLevel={2}
                size={4}
                data-testid="layout-header-myProfileAndSettings-name"
              >
                {userFullName ?? EMPTY_PLACEHOLDER}
              </Heading>
              <Text
                size="small"
                color="secondary"
                data-testid="layout-header-myProfileAndSettings-email"
              >
                {userInfo.email}
              </Text>
            </VStack>
          </HStack>

          <Space vertical={4} />

          {branchOptions && branchOptions.length > 1 ? (
            <Choice
              label={i18n.t('entity.branch.labels.selectedBranch')}
              data-testid="layout-header-myProfileAndSettings-activeBranch"
              name="activeBranch"
              value={activeBranchId || null}
              options={branchOptions}
              onChange={handleBranchChange}
              closeMenuOnScroll={false}
              isNotClearable
              maxMenuHeight={33}
            />
          ) : (
            <Heading
              data-testid="layout-header-myProfileAndSettings-activeBranch"
              headingLevel={2}
              size={4}
            >
              {getBranchByActiveId?.label ?? EMPTY_PLACEHOLDER}
            </Heading>
          )}
        </Box>
        <Space vertical={4} />
        <DropdownItem
          label={i18n.t('entity.user.labels.myProfile')}
          onClick={() => onMenuItemClick(myProfileRoutes.personalInformation)}
          data-testid="layout-header-myProfileAndSettings-personalInformation"
          leftIcon="social/person"
        />
        <Show when={hasReadSettingsPermission}>
          <DropdownItem
            label={i18n.t('page.settings.labels.title')}
            onClick={() => onMenuItemClick(settingsRoutes.userManagement)}
            data-testid="layout-header-myProfileAndSettings-userManagement"
            leftIcon="action/settings"
          />
        </Show>
        <Separator spacing={2} />

        <DropdownItem
          label={i18n.t('general.actions.logout')}
          onClick={handleLogout}
          data-testid="layout-header-myProfileAndSettings-logout"
          leftIcon="action/exit_to_app"
        />
      </Box>
    </Dropdown>
  );
}

interface AvatarIconControlProps {
  isOpen: boolean;
}
const AvatarIconControl = (props: AvatarIconControlProps) => {
  const userFullName = useUserFullName();

  return (
    <HeaderButtonWrapper
      $isOpen={props.isOpen}
      data-testid="layout-header-myProfileAndSettings-button"
    >
      <Center width="100%" height="100%">
        <Avatar size="small" variant="circle" name={userFullName ?? EMPTY_PLACEHOLDER} />
      </Center>
    </HeaderButtonWrapper>
  );
};
