import {Flag, Tooltip} from 'platform/components';
import {HStack, Text, TextSize, ThemeColorTextPath} from 'platform/foundation';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {DOT_CHARACTER, Nullish, suffixTestId, TestIdProps} from 'shared';

import {VehicleConditionEvaluationType} from '../../../hooks/useVehicleConditionEvaluation';
import {getEvaluationFlagColor} from '../../../utils/getEvaluationFlagColor';
import {getEvaluationFlagLabel} from '../../../utils/getEvaluationFlagLabel';
import {getEvaluationTooltipLabel} from '../../../utils/getEvaluationTooltipLabel';

interface VehicleConditionEvaluationProps extends TestIdProps {
  size: TextSize;
  color?: ThemeColorTextPath | Nullish;
  vehicleCondition: VehicleConditionEvaluationType;
}

export function VehicleConditionEvaluation(props: VehicleConditionEvaluationProps) {
  if (isNil(props.vehicleCondition)) {
    return null;
  }

  return (
    <HStack spacing={1} align="flex-start">
      <Text color="tertiary" size={props.size}>
        {DOT_CHARACTER}
      </Text>
      <Text size={props.size} color={props.color} noWrap>
        {i18n.t('entity.vehicle.labels.conditionEvaluation')}
      </Text>
      <HStack spacing={1} align="center">
        <Tooltip
          data-testid={suffixTestId(`exteriorState`, props)}
          key="entity.vehicle.labels.exteriorState"
          label={`${i18n.t('entity.vehicle.labels.exteriorState')}: ${getEvaluationTooltipLabel(
            props.vehicleCondition.exteriorState
          )}`}
        >
          <Flag
            size="small"
            colorScheme={getEvaluationFlagColor(props.vehicleCondition.exteriorState)}
            data-testid={suffixTestId(`exteriorState`, props)}
            label={getEvaluationFlagLabel(props.vehicleCondition.exteriorState)}
          ></Flag>
        </Tooltip>
        <Tooltip
          data-testid={suffixTestId(`interiorState`, props)}
          key="entity.vehicle.labels.interiorState"
          label={`${i18n.t('entity.vehicle.labels.interiorState')}: ${getEvaluationTooltipLabel(
            props.vehicleCondition.interiorState
          )}`}
        >
          <Flag
            size="small"
            colorScheme={getEvaluationFlagColor(props.vehicleCondition.interiorState)}
            data-testid={suffixTestId(`interiorState`, props)}
            label={getEvaluationFlagLabel(props.vehicleCondition.interiorState)}
          />
        </Tooltip>
        <Tooltip
          data-testid={suffixTestId(`mechanicalState`, props)}
          key="entity.vehicle.labels.mechanicalState"
          label={`${i18n.t('entity.vehicle.labels.mechanicalState')}: ${getEvaluationTooltipLabel(
            props.vehicleCondition.mechanicalState
          )}`}
        >
          <Flag
            size="small"
            colorScheme={getEvaluationFlagColor(props.vehicleCondition.mechanicalState)}
            data-testid={suffixTestId(`mechanicalState`, props)}
            label={getEvaluationFlagLabel(props.vehicleCondition.mechanicalState)}
          />
        </Tooltip>
      </HStack>
    </HStack>
  );
}
