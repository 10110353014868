import {Nullish} from 'shared';

import {Hour} from '../types/Hour';
import {Minute} from '../types/Minute';
import {TimeType} from '../types/TimeType';

/**
 * Splits TimeType value into hours and minutes. Returns null if given invalid or Nullish value.
 */
export const getHoursAndMinutesFromTime = (time: TimeType | Nullish) => {
  const regex = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);

  if (!time || !regex.test(time)) {
    return null;
  }

  const [hour, minute] = time.split(':');

  return {hour, minute} as {hour: Hour; minute: Minute};
};
