import {Option} from 'platform/components';

import {isNilOrEmpty} from 'ramda-adjunct';

import {BaseSupplier, BaseSupplierOrderVariantSupplier} from '@omnetic-dms/api';

import {Nullish} from 'shared';

import {getOptionsFromSuppliers} from './getOptionsFromSuppliers';

export const getOptionsFromSuppliersByOrderVariant = (
  suppliers: BaseSupplier[] | Nullish,
  supplierOrderVariantSuppliers: BaseSupplierOrderVariantSupplier[] | Nullish
): Option[] => {
  if (isNilOrEmpty(suppliers)) {
    return [];
  }

  const filteredSuppliers = suppliers?.filter((supplier) => {
    const availableSuppliers = supplierOrderVariantSuppliers?.map(
      (availableSupplier) => availableSupplier.supplierId
    );
    return availableSuppliers?.includes(supplier.id);
  });

  return getOptionsFromSuppliers(filteredSuppliers);
};
