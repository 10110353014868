import {AttributesRow} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {defaultTo} from 'ramda';

import {useGetVehicleV2Query} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {parseDate, Nullish} from 'shared';

export function useServiceInspectionParameters(vehicleId: string | Nullish): AttributesRow[] {
  const formatDateTime = useDateTimeFormatter();

  const {data: vehicle} = useGetVehicleV2Query(
    {vehicleId: defaultTo('', vehicleId)},
    {skip: !vehicleId}
  );

  return [
    {
      label: i18n.t('entity.vehicle.labels.lastService'),
      value:
        vehicle?.lastServiceDate &&
        formatDateTime('dateShort', parseDate(vehicle?.lastServiceDate)),
      testId: 'lastServiceDate',
    },
    {
      label: `${i18n.t('entity.vehicle.labels.lastService')} (${String(
        i18n.t('general.metric.km')
      )})`,
      value: vehicle?.lastServiceMileage,
      testId: 'lastServiceMileage',
    },
    {
      label: i18n.t('entity.vehicle.labels.nextService'),
      value:
        vehicle?.nextServiceDate &&
        formatDateTime('dateShort', parseDate(vehicle?.nextServiceDate)),
      testId: 'nextServiceDate',
    },
    {
      label: `${i18n.t('entity.vehicle.labels.nextService')} (${i18n.t('general.metric.km')})`,
      value: vehicle?.nextServiceMileage,
      testId: 'nextServiceMileage',
    },
  ];
}
