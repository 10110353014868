import {DropdownItem} from 'platform/components';
import {Text} from 'platform/foundation';

import {getFixedTranslation} from '../utils/getFixedTranslation';

export function ContentEditable() {
  const isContentEditable = document.body.contentEditable === 'true';

  const handleToggleContentEditable = () => {
    document.body.contentEditable = isContentEditable ? 'false' : 'true';
  };

  return (
    <DropdownItem
      label={
        <Text size="small" color={isContentEditable ? 'warning' : undefined}>
          {isContentEditable
            ? getFixedTranslation('page.settings.labels.hideContentEditable')
            : getFixedTranslation('page.settings.labels.showContentEditable')}
        </Text>
      }
      onClick={handleToggleContentEditable}
      severity={isContentEditable ? 'warning' : undefined}
      data-testid="layout-header-devSettings-contentEditable"
      leftIcon="content/text_format"
    />
  );
}
