import {Icon, Space, Box, HStack, Text, Center, Show, Clickable} from 'platform/foundation';

import {useCallback, MouseEvent} from 'react';
import {useLocation} from 'react-router-dom';

import {isNil} from 'ramda';

import {
  MenuItemPin,
  usePinUserMenuItemMutation,
  useUnpinUserMenuItemMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {composePath, suffixTestId, TestIdProps, useNavigate} from 'shared';

type TitleProps = {
  title: string;
  isHamburgerMenuItem?: boolean;
  path?: string;
} & TestIdProps;

function Title(props: TitleProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleRedirect = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    const isSamePath = location.pathname === props.path;

    if (isNil(props.path) || isSamePath) {
      return;
    }

    navigate(composePath(props.path, {isSearchParamsPreserved: false}));
  };

  return (
    <Clickable onClick={handleRedirect}>
      <Center height="3rem" width="100%" justify="flex-start">
        <Text
          size="small"
          color={props.isHamburgerMenuItem ? 'primary' : 'white'}
          data-testid={suffixTestId('title', props)}
        >
          {i18n.t(props.title)}
        </Text>
      </Center>
    </Clickable>
  );
}

type ContentProps = {
  path: string;
  withChevronRightIcon?: boolean;
} & TitleProps;

function Content(props: ContentProps) {
  return (
    <Box flex={1}>
      {props.withChevronRightIcon ? (
        <Title
          title={props.title}
          isHamburgerMenuItem={props.isHamburgerMenuItem}
          data-testid={props['data-testid']}
        />
      ) : (
        <Title
          title={props.title}
          isHamburgerMenuItem={props.isHamburgerMenuItem}
          path={props.path}
          data-testid={props['data-testid']}
        />
      )}
    </Box>
  );
}

interface MenuItemInnerProps extends TestIdProps {
  id: string;
  title: string;
  path: string;
  isPinned?: boolean;
  icon?: string;
  isHamburgerMenuItem?: boolean;
  withChevronRightIcon?: boolean;
}

export function MenuItemContent(props: MenuItemInnerProps) {
  const [pinUserMenuItem] = usePinUserMenuItemMutation();
  const [unpinUserMenuItem] = useUnpinUserMenuItemMutation();

  const handleMenuItemClick = useCallback(() => {
    const arg = {item: props.id} satisfies MenuItemPin;
    props.isPinned ? unpinUserMenuItem(arg) : pinUserMenuItem(arg);
  }, [props.id, props.isPinned, pinUserMenuItem, unpinUserMenuItem]);

  const canShowPinIcon = props.isHamburgerMenuItem && !isNil(props.isPinned);

  return (
    <HStack
      align="center"
      justify="space-between"
      data-testid={suffixTestId('innerWrapper', props)}
    >
      <Content
        path={props.path}
        title={props?.title}
        isHamburgerMenuItem={props.isHamburgerMenuItem}
        withChevronRightIcon={props.withChevronRightIcon}
        data-testid={props['data-testid']}
      />
      <Show when={canShowPinIcon}>
        <Icon
          size={4}
          value={props.isPinned ? 'custom/push_pin' : 'custom/push_pin_outlined'}
          onClick={handleMenuItemClick}
          data-testid={suffixTestId('pin', props)}
        />
      </Show>
      <Show when={canShowPinIcon && props.withChevronRightIcon}>
        <Space horizontal={2} />
      </Show>
      <Show when={props.withChevronRightIcon}>
        <Icon color="palettes.neutral.900.100" value="navigation/chevron_right" size={4} />
      </Show>
      <Show when={!props.withChevronRightIcon}>
        <Space horizontal={6} />
      </Show>
    </HStack>
  );
}
