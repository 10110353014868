import {IconButton, Tooltip, useTranslationContext} from 'platform/components';

import {useMemo} from 'react';

import {suffixTestId} from 'shared';

import {ToolpanelToggleRendererProps} from '../types/ToolpanelToggleRenderer';
import {ToolpanelType} from '../types/ToolpanelType';

export function ToolpanelToggleRenderer(props: ToolpanelToggleRendererProps) {
  const t = useTranslationContext();

  const label = useMemo(() => {
    if (props.toolpanel === ToolpanelType.filter) {
      return t('page.datagrid.labels.filters');
    }
    if (props.toolpanel === ToolpanelType.settings) {
      return t('page.datagrid.labels.settings');
    }
    if (props.toolpanel === ToolpanelType.column) {
      return t('page.datagrid.labels.columnSetting');
    }
    return null;
  }, [props.toolpanel]);

  const icon = useMemo(() => {
    if (props.toolpanel === ToolpanelType.filter) {
      return 'content/filter_list';
    }
    if (props.toolpanel === ToolpanelType.settings) {
      return 'action/settings';
    }
    if (props.toolpanel === ToolpanelType.column) {
      return 'editor/table_chart';
    }
    return null;
  }, [props.toolpanel]);

  if (!icon || !label) {
    return null;
  }

  return (
    <Tooltip placement="left" label={label}>
      <IconButton
        onClick={props.toggleToolpanel}
        priority={
          props.hasActiveFilter && props.toolpanel === ToolpanelType.filter ? 'primary' : undefined
        }
        data-testid={suffixTestId(props.toolpanel.toString(), props)}
        icon={icon}
      />
    </Tooltip>
  );
}
