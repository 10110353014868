import {
  AdaptiveImage,
  CommonImageProps,
  RatioImageProps,
  SizedImageProps,
} from '../AdaptiveImage/AdaptiveImage';
import {makeStorageUrl} from './makeStorageUrl';

export type StorageImageProps = (RatioImageProps | SizedImageProps) &
  Omit<CommonImageProps, 'makeUrl'>;

export function StorageImage(props: StorageImageProps) {
  return <AdaptiveImage {...props} makeUrl={makeStorageUrl} />;
}
