import {Box, VStack} from 'platform/foundation';

import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {path} from 'ramda';
import {isArray, isNotString} from 'ramda-adjunct';

import {businessCaseRoutes, testIds} from '@omnetic-dms/routes';

import {composePath} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

const EXTERNAL_FILTER_ID = 'complaintsFilter';

export function BusinessCaseComplaintsList() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = useCallback(
    ({actionKey, rowId, rowData}) => {
      if (isNotString(rowId) || isArray(rowData)) {
        return;
      }

      if (actionKey === 'complaintDetail') {
        const businessCaseId = path<string>(['businessCase', 'value', 'id'], rowData);

        navigate(composePath(businessCaseRoutes.complaints, {params: {id: businessCaseId}}));
      }
    },
    [navigate]
  );

  return (
    <VStack spacing={4} height="100%">
      <Box>
        <div id={EXTERNAL_FILTER_ID} />
      </Box>
      <Box height="100%">
        <DataGrid
          gridCode="complaint"
          actionCallback={actionCallback}
          externalFilterId={EXTERNAL_FILTER_ID}
          data-testid={testIds.businessCase.home('businessCaseComplaintsList')}
        />
      </Box>
    </VStack>
  );
}
