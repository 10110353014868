import {FormControl, FormField} from 'platform/components';
import {HStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {FieldValues, Path} from 'react-hook-form';

import {RequiredTestIdProps, suffixTestId} from 'shared';

type RangeFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  type: 'date' | 'number';
  control: FormControl<TFieldValues>;
  name: Path<TFieldValues>;
  labelFrom: string;
  labelTo: string;
} & RequiredTestIdProps;

export function RangeField<TFieldValues extends FieldValues = FieldValues>(
  props: RangeFieldProps<TFieldValues>
) {
  return match(props.type)
    .with('date', () => (
      <HStack spacing={4}>
        <FormField
          control={props.control}
          type="date"
          name={`${props.name}.from` as Path<TFieldValues>}
          label={props.labelFrom}
          data-testid={suffixTestId('from', props)}
        />
        <FormField
          control={props.control}
          type="date"
          name={`${props.name}.to` as Path<TFieldValues>}
          label={props.labelTo}
          data-testid={suffixTestId('to', props)}
        />
      </HStack>
    ))
    .with('number', () => (
      <HStack spacing={4}>
        <FormField
          control={props.control}
          type="number"
          name={`${props.name}.from` as Path<TFieldValues>}
          label={props.labelFrom}
          data-testid={suffixTestId('from', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name={`${props.name}.to` as Path<TFieldValues>}
          label={props.labelTo}
          data-testid={suffixTestId('to', props)}
        />
      </HStack>
    ))
    .otherwise(() => null);
}
