import {Buffer} from 'buffer';

import {GetBase64Fn} from '../types/GetBase64Fn';
import {QueueVideoTask} from '../types/QueueVideoTask';
import {QueueVideoTaskJob} from '../types/QueueVideoTaskJob';

export async function getFileChunk(
  task: QueueVideoTask,
  job: QueueVideoTaskJob,
  getBase64: GetBase64Fn
): Promise<ArrayBufferLike> {
  const index = job.partNumber - 1; // partNumber starts from 1
  const chunk = await getBase64({
    index,
    fileSize: task.size,
    chunkSize: task.chunkSize,
    uri: task.localUri ?? task.uri,
  });

  return Buffer.from(chunk, 'base64').buffer;
}
