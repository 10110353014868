import i18n from 'i18next';
import {
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, Space} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {useToFinishingInspectionMutation} from '@omnetic-dms/api';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {getAudit} from '../../../store/carAudit/actions';
import {selectAudit} from '../../../store/carAudit/selectors';
import {getStringErrorMessage} from '../../../utils/getStringErrorMessage';

type ToFinishingFormType = {
  note: string | null;
};

export function ToFinishingForm() {
  const dispatch = useThunkDispatch();
  const audit = useSelector(selectAudit);

  const [toFinishingInspection, {isLoading: isLoadingToFinishingInspection}] =
    useToFinishingInspectionMutation();

  const handleSubmit: FormSubmitHandler<ToFinishingFormType> = async (values) => {
    if (!audit) {
      return;
    }

    await toFinishingInspection({
      inspectionId: audit.id,
      note: values.note ?? '',
    })
      .unwrap()
      .then(() => {
        dispatch(
          getAudit.action({
            auditId: audit.id,
          })
        );

        closeCurrentDialog();
      })
      .catch((error) => showNotification.error(getStringErrorMessage(error)));
  };

  return (
    <>
      <Form<ToFinishingFormType> onSubmit={handleSubmit}>
        {(control) => (
          <>
            <Box>
              <FormField
                type="textarea"
                name="note"
                minRows={1}
                label={i18n.t('general.labels.note')}
                control={control}
              />
            </Box>

            <Space vertical={4} />

            <ButtonGroup align="right">
              <FormButton
                control={control}
                variant="secondary"
                onClick={closeCurrentDialog}
                title={i18n.t('general.actions.cancel')}
              />

              <FormButton
                type="submit"
                control={control}
                isLoading={isLoadingToFinishingInspection}
                title={i18n.t('general.actions.confirm')}
              />
            </ButtonGroup>
          </>
        )}
      </Form>
    </>
  );
}
