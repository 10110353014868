import {Nullish} from 'shared';

import {LightboxImage} from '../types/LightboxImage';
import {LightboxSlide} from '../types/LightboxSlide';
import {LightboxVideo} from '../types/LightboxVideo';

type ComposeSlidesParams = {
  images?: LightboxImage[] | Nullish;
  videos?: LightboxVideo[] | Nullish;
};

export function composeSlides(params: ComposeSlidesParams): LightboxSlide[] | null {
  const processedImages: ({type: 'image'} & LightboxImage)[] =
    params.images?.map((image) => ({...image, type: 'image'})) || [];
  const processedVideos: ({type: 'video'} & LightboxVideo)[] =
    params.videos?.map((video) => ({...video, type: 'video'})) || [];
  const combinedSlides = [...processedImages, ...processedVideos];
  return combinedSlides.length > 0 ? combinedSlides : null;
}
