import {SliderTick} from 'platform/components';

import {useMemo} from 'react';

export const useSliderTicks = (
  min: number,
  max: number,
  markerSize: number | null,
  markers?: Array<number>
): undefined | Array<SliderTick> =>
  useMemo<undefined | SliderTick[]>(() => {
    if (markers && markers.length > 0) {
      return markers?.map((marker) => ({label: marker.toString(), value: marker}));
    }
    if (markerSize !== 0) {
      const calculatedMarkers: Array<SliderTick> = [];
      let lastMarkerValue: number = min;

      while (lastMarkerValue <= max) {
        calculatedMarkers.push({
          value: lastMarkerValue,
        });
        // @ts-ignore
        lastMarkerValue = lastMarkerValue + markerSize;
      }

      return calculatedMarkers;
    }
    return undefined;
  }, [markers, markerSize, max, min]);
