import {DataStatus} from 'platform/components';
import {Box, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {ReactNode} from 'react';

import {queryParams, SectionTab} from '@omnetic-dms/shared';

import {suffixTestId, RequiredTestIdProps, useQueryState} from 'shared';

import {SettingsTemplateHeader} from '../../types';
import {SettingsSection} from '../SettingsSection/SettingsSection';
import {SettingsHeader} from './components/SettingsHeader';
import {SettingsNavigation} from './components/SettingsNavigation';

interface SettingsTemplateProps extends RequiredTestIdProps {
  isLoading?: boolean;
  isError?: boolean;
  tabs?: SectionTab[];
  header: SettingsTemplateHeader;
  description?: string;
  children?: ReactNode;
  isCreating?: boolean;
}

export function SettingsTemplate(props: SettingsTemplateProps) {
  const [tabId] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);

  const activeTab = props.tabs?.find((tab) => tab.queryId === tabId)?.content;
  const children = activeTab ?? props.tabs?.[0]?.content ?? props.children;

  return (
    <HStack height="100%">
      <Box
        position="fixed"
        overflowY="auto"
        width={80}
        top={12}
        bottom={0}
        padding={4}
        borderRight="1px solid"
        borderColor="general.separator"
        backgroundColor="general.white"
      >
        <SettingsNavigation />
      </Box>
      <Box paddingLeft={80} width="100%" backgroundColor="general.white">
        <DataStatus isLoading={props.isLoading} isError={props.isError} minHeight={60}>
          <VStack height="100%">
            <SettingsHeader
              title={props.header.title}
              subtitle={props.header.subtitle}
              breadcrumbs={props.header.breadcrumbs}
              actions={props.header.actions}
              tabs={props.tabs}
              isTitleTeriary={props.isCreating}
              data-testid={suffixTestId('detail', props)}
            />
            <Box padding={6} flex={1} position="relative" backgroundColor="general.white">
              <VStack height="100%">
                <Show when={props.description}>
                  <SettingsSection>
                    <Text
                      color="secondary"
                      size="small"
                      data-testid={suffixTestId('detail-description', props)}
                    >
                      {props.description}
                    </Text>
                  </SettingsSection>
                  <Space vertical={6} />
                </Show>
                <Box flex={1}>{children}</Box>
              </VStack>
            </Box>
          </VStack>
        </DataStatus>
      </Box>
    </HStack>
  );
}
