import {Button, ButtonGroup, DataStatus} from 'platform/components';
import {Hide, Show, Space, VStack} from 'platform/foundation';

import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetServiceCaseScheduleRepairQuery,
  usePostServiceCaseScheduleRepairMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError, queryParams} from '@omnetic-dms/shared';

import {useQueryState} from 'shared';

import {RepairScheduledCard} from '../../../../components/RepairScheduledCard/RepairScheduledCard';
import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';
import {EmptySchedule} from './components/EmptySchedule';

interface ScheduleSelectionProps {
  onBackClick: () => void;
}

export function ScheduleSelection(props: ScheduleSelectionProps) {
  const {serviceCaseId} = useWorkshopUrl();
  const [repairId] = useQueryState(queryParams.SERVICE_CASE_REPAIR_ID);

  const [postServiceCaseScheduleRepair, {isLoading: isPostServiceCaseScheduleRepairLoading}] =
    usePostServiceCaseScheduleRepairMutation();
  const {data, isLoading, isError} = useGetServiceCaseScheduleRepairQuery(
    {
      serviceCaseId,
      extRepairId: isNotNilOrEmpty(repairId) ? repairId : undefined,
    },
    {refetchOnMountOrArgChange: true}
  );

  const handleOpenPlanner = () => {
    postServiceCaseScheduleRepair({
      serviceCaseId,
      redirectUrl: window.location.href,
    })
      .unwrap()
      .then((data) => {
        const redirectUrl = data?.url;
        if (isNotNil(redirectUrl)) {
          window.open(redirectUrl, '_self');
        }
      })
      .catch(handleApiError);
  };

  const handleOpenEditPlanner = () => {
    if (isNotNil(data?.url)) {
      window.open(data?.url, '_self');
    }
  };

  return (
    <VStack spacing={2} height="100%">
      <DataStatus isLoading={isLoading} isError={isError} minHeight={100}>
        <Show when={isNilOrEmpty(data)}>
          <EmptySchedule
            onOpenPlannerClick={handleOpenPlanner}
            isLoading={isPostServiceCaseScheduleRepairLoading}
            data-testid={testIds.workshop.createServiceCase('emptySchedule')}
          />
        </Show>
        <Hide when={isNilOrEmpty(data)}>
          <RepairScheduledCard
            data={data}
            cardActions={[
              {
                type: 'button',
                title: i18n.t('entity.serviceScheduler.actions.editSchedule'),
                leftIcon: 'action/open_in_new',
                onClick: handleOpenEditPlanner,
                variant: 'link',
                isDisabled: isNilOrEmpty(data?.url),
              },
            ]}
          />
        </Hide>
      </DataStatus>
      <Space fillAvailable />
      <ButtonGroup align="right">
        <Button
          onClick={props.onBackClick}
          variant="outlined"
          data-testid={testIds.workshop.createServiceCase('back')}
          title={i18n.t('general.actions.back')}
        />
      </ButtonGroup>
    </VStack>
  );
}
