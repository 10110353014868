import {
  Button,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {HStack, Space} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

export interface SourcingFilterPresetFormData {
  name: string;
}

interface SourcingFilterPresetDialogProps {
  initialValues: SourcingFilterPresetFormData;
  onSubmit?: FormSubmitHandler<SourcingFilterPresetFormData>;
}

export function FilterPresetForm(props: SourcingFilterPresetDialogProps) {
  return (
    <Form<SourcingFilterPresetFormData>
      onSubmit={props.onSubmit}
      defaultValues={props.initialValues}
    >
      {(control) => (
        <>
          <FormField
            control={control}
            name="name"
            label={i18n.t('entity.sourcing.filterPresets.labels.filterName')}
            helperText={i18n.t('entity.sourcing.filterPresets.labels.maxLengthOfName')}
            maxLength={30}
            type="text"
            data-testid={testIds.sourcing.classifieds('filterPresetName')}
          />
          <Space vertical={4} />
          <HStack justify="flex-end" spacing={4}>
            <Button
              variant="outlined"
              title={i18n.t('general.actions.close')}
              onClick={closeCurrentDialog}
              data-testid={testIds.sourcing.classifieds('filterPresetClose')}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('general.actions.save')}
              data-testid={testIds.sourcing.classifieds('filterPresetSave')}
            />
          </HStack>
        </>
      )}
    </Form>
  );
}
