import {RefObject, useEffect, useRef} from 'react';

export interface UseOutsideClickProps {
  /**
   * Whether the hook is enabled
   */
  enabled?: boolean;
  /**
   * The reference to a DOM element.
   */
  ref: RefObject<HTMLElement>;
  /**
   * Function invoked when a click is triggered outside the referenced element.
   */
  handler?: (e: Event) => void;
}

/**
 * Example, used in components like Dialogs and Popovers so they can close
 * when a user clicks outside them.
 */
export function useOutsideClick(props: UseOutsideClickProps) {
  const stateRef = useRef({
    isPointerDown: false,
    ignoreEmulatedMouseEvents: false,
  });

  const state = stateRef.current;

  useEffect(() => {
    if (!props.enabled) {
      return;
    }
    const onPointerDown: any = (e: PointerEvent) => {
      if (isValidEvent(e, props.ref)) {
        state.isPointerDown = true;
      }
    };

    const onMouseUp: any = (event: MouseEvent) => {
      if (state.ignoreEmulatedMouseEvents) {
        state.ignoreEmulatedMouseEvents = false;
        return;
      }

      if (state.isPointerDown && props.handler && isValidEvent(event, props.ref)) {
        state.isPointerDown = false;
        props.handler(event);
      }
    };

    const onTouchEnd = (event: TouchEvent) => {
      state.ignoreEmulatedMouseEvents = true;
      if (props.handler && state.isPointerDown && isValidEvent(event, props.ref)) {
        state.isPointerDown = false;
        props.handler(event);
      }
    };

    document.addEventListener('mousedown', onPointerDown, true);
    document.addEventListener('mouseup', onMouseUp, true);
    document.addEventListener('touchstart', onPointerDown, true);
    document.addEventListener('touchend', onTouchEnd, true);

    return () => {
      document.removeEventListener('mousedown', onPointerDown, true);
      document.removeEventListener('mouseup', onMouseUp, true);
      document.removeEventListener('touchstart', onPointerDown, true);
      document.removeEventListener('touchend', onTouchEnd, true);
    };
  }, [props.handler, props.ref, state, props.enabled]);
}

function isValidEvent(event: any, ref: RefObject<HTMLElement>) {
  const target = event.target as HTMLElement;
  if (event.button > 0) {
    return false;
  }
  // if the event target is no longer in the document
  if (target) {
    if (!document.body.contains(target)) {
      return false;
    }
  }

  return !ref.current?.contains(target);
}
