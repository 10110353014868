import {isFeatureEnabled} from 'feature-flags';

import {isNil} from 'ramda';

import {ProtectedGroupResponseBody} from '@omnetic-dms/api';

import {featureFlagsByAcl} from '../hooks/featureFlagsByAcl';

export const filterResourcesByFeatureFlag = (data: ProtectedGroupResponseBody[]) => {
  const isResourceEnabled = (resource: ProtectedGroupResponseBody) => {
    const featureFlag = featureFlagsByAcl[resource.id];
    if (isNil(featureFlag)) {
      return true;
    }

    return isFeatureEnabled(featureFlag);
  };

  const filterResources = (resources: ProtectedGroupResponseBody[]): ProtectedGroupResponseBody[] =>
    resources
      .filter((resource) => isResourceEnabled(resource))
      .map((resource) => ({
        ...resource,
        protectedUnits: resource.protectedUnits,
        children: filterResources(resource.children), // Recursively filter children
      }));

  return filterResources(data);
};
