import {head} from 'ramda';

import {
  DeleteServiceOrderReturnRequest,
  DeleteServiceOrderReturnRequestSchema,
  DeleteServiceOrderReturnResponse,
  DeleteServiceOrderReturnResponseSchema,
  GetServiceOrderReturnRequest,
  GetServiceOrderReturnRequestSchema,
  GetServiceOrderReturnResponse,
  GetServiceOrderReturnResponseSchema,
  PostServiceOrderReturnRequest,
  PostServiceOrderReturnRequestSchema,
  PostServiceOrderReturnResponse,
  PostServiceOrderReturnResponseSchema,
} from '../types/metadaWarehouseServiceOrderReturn';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseServiceOrderReturnApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceOrderReturn: build.query<GetServiceOrderReturnResponse, GetServiceOrderReturnRequest>(
      {
        query: (queryArg) => ({
          url: `warehouse/v1/service-order-return/${queryArg.serviceOrderReturnId}`,
          method: 'GET',
          queryArg,
        }),
        providesTags: (result, error, queryArg) => [
          {type: 'serviceOrderReturns', id: queryArg.serviceOrderReturnId},
        ],
        extraOptions: {
          requestSchema: GetServiceOrderReturnRequestSchema,
          responseSchema: GetServiceOrderReturnResponseSchema,
        },
      }
    ),
    postServiceOrderReturn: build.mutation<
      PostServiceOrderReturnResponse,
      PostServiceOrderReturnRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/service-order-return',
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        requestSchema: PostServiceOrderReturnRequestSchema,
        responseSchema: PostServiceOrderReturnResponseSchema,
      },
    }),
    deleteServiceOrderReturn: build.mutation<
      DeleteServiceOrderReturnResponse,
      DeleteServiceOrderReturnRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/service-order-return/delete',
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderReturns', id: head(queryArg.body.serviceOrderReturnId)},
        {type: 'partsRequestReturnBasket', id: head(queryArg.body.serviceOrderReturnId)},
      ],
      extraOptions: {
        requestSchema: DeleteServiceOrderReturnRequestSchema,
        responseSchema: DeleteServiceOrderReturnResponseSchema,
      },
    }),
  }),
});

export const {
  useGetServiceOrderReturnQuery,
  usePostServiceOrderReturnMutation,
  useDeleteServiceOrderReturnMutation,
} = metadaWarehouseServiceOrderReturnApi;
