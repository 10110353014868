import {FlagProps, Parameter, Parameters} from 'platform/components';
import {
  ThemeIconKey,
  Box,
  Heading,
  Hide,
  HStack,
  Link,
  Show,
  Space,
  Text,
  VStack,
} from 'platform/foundation';

import {HTMLAttributeAnchorTarget} from 'react';

import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {ActionParameters} from '../../ActionParameters/ActionParameters';
import {DynamicFlagList} from './DynamicFlagList';
import {SnippetPlaceholder, SnippetPlaceholderProps} from './SnippetPlaceholder';

export interface SnippetProps extends TestIdProps {
  label: string;
  title?: string;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  flags?: FlagProps[] | null;
  icon?: ThemeIconKey;
  parameters?: Parameter[];
  placeholder?: SnippetPlaceholderProps;
  isDisabled?: boolean;
  onClick?: () => void;
  customerId?: string;
  selectedContactId?: string;
}

export function Snippet(props: SnippetProps) {
  const hasContent = !!(props.title || isNotNilOrEmpty(props.flags));
  const shouldShowActionParameters =
    props.label === i18n.t('entity.businessCase.labels.contactPerson') ||
    props.label === i18n.t('entity.customer.labels.customer');

  return (
    <Box data-testid={props['data-testid']}>
      <Text size="xSmall" data-testid={suffixTestId('label', props)}>
        {props.label}
      </Text>

      <Space vertical={1} />

      <Box
        borderRadius="small"
        backgroundColor="palettes.neutral.10.100"
        opacity={props.isDisabled ? 0.4 : 1}
        overflow="hidden"
        height={14}
      >
        <Show when={hasContent}>
          <Box width="100%" height="100%" padding={2}>
            <VStack
              width="100%"
              height="100%"
              justify={isNil(props.title) ? 'flex-start' : 'center'}
              spacing={1}
            >
              <HStack spacing={2} align="center">
                <Show when={props.title}>
                  <Show when={props.onClick ?? props.href}>
                    <Box flexShrink={0}>
                      <Link
                        size="small"
                        href={props.href}
                        title={props.title}
                        onClick={props.onClick}
                        isDisabled={props.isDisabled}
                        leftIcon={props.icon}
                        data-testid={suffixTestId(`link`, props)}
                        target={props.target}
                      />
                    </Box>
                  </Show>
                  <Show when={!props.onClick && !props.href}>
                    <Heading size={5} headingLevel={6} data-testid={suffixTestId(`heading`, props)}>
                      {props.title}
                    </Heading>
                  </Show>
                </Show>

                <Show when={isNotNilOrEmpty(props.flags)}>
                  <DynamicFlagList data={props.flags ?? []} />
                </Show>
              </HStack>
              <Show when={shouldShowActionParameters}>
                <ActionParameters
                  parameters={props.parameters}
                  customerId={props.customerId}
                  color="secondary"
                  tooltipLabelSms={i18n.t('general.actions.sendMessage')}
                  tooltipLabelEmail={i18n.t('general.actions.sendEmail')}
                  selectedContactId={props.selectedContactId}
                />
              </Show>
              <Hide when={shouldShowActionParameters}>
                <Parameters
                  color="secondary"
                  size="xSmall"
                  parameters={props.parameters}
                  data-testid={suffixTestId(`parameters`, props)}
                />
              </Hide>
            </VStack>
          </Box>
        </Show>

        <Hide when={hasContent}>
          <SnippetPlaceholder
            isDisabled={props.isDisabled}
            {...props.placeholder}
            data-testid={suffixTestId(`placeholder`, props)}
          />
        </Hide>
      </Box>
    </Box>
  );
}
