import {CurrencyCodeType} from 'shared';

import {DocumentRestrictionFormType} from './types';

export const emptyRestrictionItem = (currency: CurrencyCodeType): DocumentRestrictionFormType => ({
  amount: {
    amount: '',
    currency,
  },
  documentTypes: [],
  type: 'off',
  validFrom: null,
});
