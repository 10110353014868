import {
  BreadcrumbType,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, Space, VStack} from 'platform/foundation';
import {Pattern, match} from 'ts-pattern';
import {boolean, object} from 'yup';

import {useParams} from 'react-router-dom';

import {always, isNil} from 'ramda';
import {isFalse, isTrue} from 'ramda-adjunct';

import {
  useGetConfigurationInvoiceCostItemQuery,
  usePatchConfigurationInvoiceCostItemMutation,
  usePostConfigurationInvoiceCostItemMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError, useBranches} from '@omnetic-dms/shared';

import {NullishBoolean, useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type FormValues = {
  branchId: string;
  generateCostItem: boolean;
};

const SYSTEM_BRANCH_ID = 'undefinedBranches';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.accountingSettings.labels.invoiceCostGeneration'),
    href: settingsRoutes.invoiceCostOverview,
  },
];

export function InvoiceCostDetail() {
  const {id: configurationId} = useParams();
  const isCreating = isNil(configurationId);
  const navigate = useNavigate();

  const {
    data: issuedTypeDefinition,
    isLoading: isLoadingDefinition,
    isError: isDefinitionError,
  } = useGetConfigurationInvoiceCostItemQuery({id: configurationId ?? ''}, {skip: isCreating});
  const [updateInvoiceCostDefinition, {isLoading: isFetchingPatchConfiguration}] =
    usePatchConfigurationInvoiceCostItemMutation();
  const [createInvoiceCostDefinition, {isLoading: isFetchingCreateConfiguration}] =
    usePostConfigurationInvoiceCostItemMutation();

  const isFetching = isFetchingPatchConfiguration || isFetchingCreateConfiguration;
  const isSystem = issuedTypeDefinition?.branchId === SYSTEM_BRANCH_ID;

  const {
    data: branches,
    branchOptions,
    isLoading: isLoadingBranches,
    isError: isBranchesError,
  } = useBranches();

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    const requestBody = {
      ...data,
      branchId: isSystem ? undefined : data.branchId,
      id: configurationId ?? null,
    };
    const mutation = isCreating ? createInvoiceCostDefinition : updateInvoiceCostDefinition;

    await mutation(requestBody)
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.invoiceCostOverview))
      .catch(handleApiError);
  };

  const isLoading = isLoadingBranches || isLoadingDefinition;
  const isError = isBranchesError || isDefinitionError;

  const branchName = branches?.branchListItems.find(
    (branch) => branch.id === issuedTypeDefinition?.branchId
  )?.marketingName;

  const title = match([isCreating, branchName, isSystem])
    .with(
      [true, Pattern.any, false],
      always(i18n.t('page.accountingSettings.labels.newDefinition'))
    )
    .with(
      [false, Pattern.string, false],
      always(
        `${branchName} - ${isTrue(issuedTypeDefinition?.generateCostItem) ? i18n.t('general.labels.yes') : i18n.t('general.labels.no')}`
      )
    )
    .with([false, Pattern.any, true], always(i18n.t('page.accounting.labels.tenantDefinition')))
    .otherwise(always(i18n.t('page.accounting.labels.definition')));

  const defaultValues = {
    branchId: issuedTypeDefinition?.branchId ?? undefined,
    generateCostItem: issuedTypeDefinition?.generateCostItem ?? false,
  };

  return (
    <SettingsTemplate
      header={{
        title,
        breadcrumbs,
      }}
      data-testid={testIds.settings.taxDocumentIssuedTypeDetail('page')}
      isLoading={isLoading}
      isError={isError}
      isCreating={isCreating}
    >
      <Form<FormValues>
        onSubmit={handleSubmit}
        schema={getSchema(isSystem)}
        defaultValues={defaultValues}
      >
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <Grid columns={2} align="flex-end">
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  isNotClearable
                  options={branchOptions}
                  label={i18n.t('entity.cashRegister.parameters.branch')}
                  isRequired
                  data-testid={testIds.settings.invoiceCostDetail('branchId')}
                  isDisabled={isSystem}
                  placeholder={
                    isSystem ? i18n.t('page.accounting.labels.tenantDefinition') : undefined
                  }
                />

                <VStack>
                  <FormField
                    control={control}
                    type="switch"
                    name="generateCostItem"
                    data-testid={testIds.settings.invoiceCostDetail('generateCostItem')}
                    label={i18n.t('page.accountingSettings.labels.GenerateCostItem')}
                  />
                  <Space vertical={1} />
                </VStack>
              </Grid>
            </SettingsSection>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.invoiceCostOverview),
                  variant: 'secondary',
                },
                {
                  type: 'form-button',
                  control,
                  isLoading: isFetching,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const getSchema = (isSystem: NullishBoolean) =>
  object({
    branchId: yupString.when([], {
      is: () => isFalse(isSystem),
      then: (schema) => schema.required(),
    }),
    generateCostItem: boolean().default(false),
  });
