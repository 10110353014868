import {useFormatCurrency} from 'platform/locale';

import {Money} from '@omnetic-dms/api';

export function usePriceFormatter() {
  const formatCurrency = useFormatCurrency();

  const formatPrice = (price: Money | null) =>
    !isNaN(Number(price?.amount)) &&
    price?.currency &&
    formatCurrency(Number(price.amount), price?.currency);

  return {formatPrice};
}
