import {z} from 'zod';

export const GetDatagridSynchronizationServiceItemsExternalHistoryApiResponseSchema = z.union([
  z.object({
    synchronizedAt: z.string(),
    synchronizedBy: z.string(),
  }),
  z.null(),
  z.undefined(),
]);

export type GetDatagridSynchronizationServiceItemsExternalHistoryApiResponse = z.infer<
  typeof GetDatagridSynchronizationServiceItemsExternalHistoryApiResponseSchema
>;

export const GetDatagridSynchronizationServiceItemsExternalHistoryApiArgSchema = z.object({
  vehicleId: z.string(),
});

export type GetDatagridSynchronizationServiceItemsExternalHistoryApiArg = z.infer<
  typeof GetDatagridSynchronizationServiceItemsExternalHistoryApiArgSchema
>;

export const PostDatagridSynchronizeServiceItemsExternalHistoryApiResponseSchema = z.null();

export type PostDatagridSynchronizeServiceItemsExternalHistoryApiResponse = z.infer<
  typeof PostDatagridSynchronizeServiceItemsExternalHistoryApiResponseSchema
>;

export const PostDatagridSynchronizeServiceItemsExternalHistoryApiArgSchema = z.object({
  body: z
    .object({
      vehicleId: z.string(),
    })
    .nullable(),
});

export type PostDatagridSynchronizeServiceItemsExternalHistoryApiArg = z.infer<
  typeof PostDatagridSynchronizeServiceItemsExternalHistoryApiArgSchema
>;
