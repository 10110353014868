import {Card} from 'platform/components';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {Section} from '@omnetic-dms/shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

export function Movements() {
  const params = useWarehouseParams();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {articleId: params.articleId}]),
    [params]
  );

  return (
    <Section>
      <Card title={i18n.t('entity.warehouse.labels.movements')}>
        <Box height="67vh">
          <DataGrid
            gridCode="stock-movements"
            queryModifier={queryModifier}
            data-testid={testIds.warehouse.articleDetailMovements('stock-movements')}
          />
        </Box>
      </Card>
    </Section>
  );
}
