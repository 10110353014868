import {Card, DataStatus, Label} from 'platform/components';
import {Box, Center, Grid, Heading, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';

import {useCallback} from 'react';

import {defaultTo, mergeAll} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  GetArticleResponse,
  TenantResponseBody,
  useGetArticleStockItemOverviewQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {Section} from '@omnetic-dms/shared';

import {parseDate} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

interface InventoriesProps {
  article: GetArticleResponse;
  tenant: TenantResponseBody;
  handlingUnitLabel: string | null;
}

export function Inventories(props: InventoriesProps) {
  const params = useWarehouseParams();
  const currencyFormatter = useFormatCurrency();
  const dateFormatter = useDateTimeFormatter();

  const {
    data: articleStockItemOverview,
    isLoading: isArticleStockItemOverviewLoading,
    isError: isArticleStockItemError,
  } = useGetArticleStockItemOverviewQuery({
    warehouseId: params.warehouseId,
    articleId: params.articleId,
  });

  const isLoading = isArticleStockItemOverviewLoading;
  const isError = isArticleStockItemError;

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {articleId: params.articleId}]),
    [params]
  );

  if (!articleStockItemOverview) {
    return (
      <Center width="100%">
        <DataStatus isLoading={isLoading} isError={isError} minHeight="100vh" />
      </Center>
    );
  }

  const totalValueCurrency = currencyFormatter(
    defaultTo(0, Number(articleStockItemOverview.totalValue)),
    props.tenant.currency
  );
  const lastReceptionDate = isNotNilOrEmpty(articleStockItemOverview.lastReception)
    ? dateFormatter('dateTimeShort', parseDate(articleStockItemOverview.lastReception))
    : '-';

  return (
    <Section>
      <Card title={i18n.t('entity.warehouse.labels.inventories')}>
        <VStack spacing={4}>
          <Grid columns={4}>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.totalInventory')}</Label>
              <Heading size={1}>{`${articleStockItemOverview.totalInventory ?? 0} ${
                props.handlingUnitLabel
              }`}</Heading>
            </VStack>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.totalValue')}</Label>
              <Heading size={1}>{totalValueCurrency}</Heading>
            </VStack>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.lastReception')}</Label>
              <Heading size={1}>{lastReceptionDate}</Heading>
            </VStack>
          </Grid>
          <Box height="60vh">
            <DataGrid gridCode="warehouse-article-inventory" queryModifier={queryModifier} />
          </Box>
        </VStack>
      </Card>
    </Section>
  );
}
