import {Module} from '@omnetic-dms/api';
import {moduleKeys} from '@omnetic-dms/config';
import {featureFlags} from '@omnetic-dms/feature-flags';
import {analyticsRoutes} from '@omnetic-dms/routes';
import {permissions} from '@omnetic-dms/shared';

import {Analytics} from './pages/Analytics/Analytics';

export const AnalyticsModule: Module = {
  id: moduleKeys.analytics,
  layoutProps: {
    title: 'analytics.label.title',
    icon: 'action/timeline',
    iconV2: 'sidebar/trending_up',
  },
  routerProps: {
    path: analyticsRoutes.overview,
    element: <Analytics />,
  },
  requiredFeatureFlag: featureFlags.ACL_REPORTING,
  requiredPermission: permissions.reportingRead,
  content: [
    {
      id: 'analytics-overview',
      routerProps: {
        path: analyticsRoutes.overview,
        element: <Analytics />,
      },
      layoutProps: {
        title: 'analytics.label.title',
        icon: 'sidebar/trending_up',
      },
    },
    {
      id: 'analytics-dashboard',
      routerProps: {
        path: analyticsRoutes.dashboard,
        element: <Analytics />,
      },
    },
  ],
};
