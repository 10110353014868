import {Tooltip} from 'platform/components';
import {HStack} from 'platform/foundation';

import {useMemo, useState, forwardRef, Ref, useImperativeHandle} from 'react';
import {Link, LinkProps} from 'react-router-dom';

import {isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {ICellRenderer, ICellRendererParams} from '../../types/AgGridTypes';
import {LinkPropsGetter} from '../../types/LinkPropsGetter';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getCellClassName} from '../../utils/getCellClassName';

export interface LinkCellRendererProps extends ICellRendererParams {
  getTooltipProps?: TooltipGetter;
  getLinkProps?: LinkPropsGetter;
}

function LinkCellRendererComponent(props: LinkCellRendererProps, ref: Ref<ICellRenderer>) {
  const {getValue, value, getTooltipProps, data, node, getLinkProps, colDef} = props;
  const className = getCellClassName(colDef);

  const [cellValue, setCellValue] = useState<string>(() => (getValue ? getValue() : value));

  useImperativeHandle(ref, () => ({
    refresh: ({getValue: getNewValue, value: newValue}) => {
      const newCellValue = getNewValue ? getNewValue() : newValue;
      if (newCellValue !== newValue) {
        setCellValue(newCellValue);
      }
      return true;
    },
  }));

  const tooltipProps = useTooltipProps(data, node, getTooltipProps);

  const linkProps = useMemo<LinkProps | undefined>(() => {
    if (getLinkProps && data) {
      return getLinkProps({data});
    }
    return undefined;
  }, [data, getLinkProps]);

  if (isNil(cellValue)) {
    return null;
  }

  if (!isArray(linkProps)) {
    return (
      <Tooltip {...tooltipProps}>
        {/* eslint-disable-next-line react/forbid-dom-props */}
        <div className={className}>
          {linkProps?.to ? (
            <Link {...linkProps} ref={stopPropagationOnRef}>
              {cellValue}
            </Link>
          ) : null}
        </div>
      </Tooltip>
    );
  }

  return (
    <HStack wrap={colDef?.wrapText} width="100%">
      {linkProps.map((linkProp, index) => {
        const isLast = index === linkProps.length - 1;
        return (
          <Tooltip {...tooltipProps} key={index}>
            {linkProp.to ? (
              // eslint-disable-next-line react/forbid-dom-props
              <div className={className}>
                <Link {...linkProp} ref={stopPropagationOnRef}>{`${cellValue[index]}${
                  isLast ? '' : ' / '
                }`}</Link>
              </div>
            ) : null}
          </Tooltip>
        );
      })}
    </HStack>
  );
}

export const LinkCellRenderer = forwardRef<ICellRenderer, LinkCellRendererProps>(
  LinkCellRendererComponent
);

const stopPropagationOnRef = (ref: HTMLAnchorElement) => {
  if (!ref) {
    return;
  }

  ref.onclick = (event) => event.stopPropagation();
};
