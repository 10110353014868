import type {CancelablePromise} from '../types/CancelablePromise';
import type {DocumentKind} from '../types/DocumentKind';
import type {DocumentKindGroupEnum} from '../types/DocumentKindGroupEnum';
import type {TemplateGroupV2} from '../types/TemplateGroupV2';
import type {TemplateV2} from '../types/TemplateV2';
import {request as __request} from '../utils/request';

export class GetTemplatesService {
  /**
   * @returns DocumentKind
   * @throws ApiError
   */
  public static getDocumentKinds({
    documentKindGroup,
    authorization,
  }: {
    documentKindGroup?: DocumentKindGroupEnum;
    authorization?: string;
  }): CancelablePromise<Array<DocumentKind>> {
    return __request({
      method: 'GET',
      url: '/dms/v2/document/document-kinds',
      headers: {
        Authorization: authorization,
      },
      query: {
        documentKindGroup,
      },
      shouldClearCacheEntry: true,
    });
  }

  /**
   * @returns TemplateGroupV2
   * @throws ApiError
   */
  public static getTemplateGroups({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Array<TemplateGroupV2>> {
    return __request({
      method: 'GET',
      url: '/dms/v2/document/template-group',
      headers: {
        Authorization: authorization,
      },
      shouldClearCacheEntry: true,
    });
  }

  /**
   * @returns TemplateV2
   * @throws ApiError
   */
  public static getTemplates({
    documentKindCode,
    authorization,
  }: {
    documentKindCode?: string;
    authorization?: string;
  }): CancelablePromise<Array<TemplateV2>> {
    return __request({
      method: 'GET',
      url: '/dms/v2/document/templates',
      headers: {
        Authorization: authorization,
      },
      query: {
        documentKindCode,
      },
      shouldClearCacheEntry: true,
    });
  }
}
