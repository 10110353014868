import {Label, Textarea, TextareaProps} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';

import {useState} from 'react';
import {FieldValues, Path, PathValue, UseFormReturn} from 'react-hook-form';

import {TestIdProps, suffixTestId} from 'shared';

import {PredefinedNotes} from '../PredefinedNotes/PredefinedNotes';

interface PredefinedTextAreaProps<TFieldValues extends FieldValues = FieldValues>
  extends Pick<TextareaProps, 'label' | 'onChange' | 'rows' | 'isResizable'>,
    TestIdProps {
  name: Path<TFieldValues>;
  resource: string;
  context: string;
  formApi: UseFormReturn<TFieldValues>;
}

export function PredefinedTextArea<TFieldValues extends FieldValues = FieldValues>(
  props: PredefinedTextAreaProps<TFieldValues>
) {
  const [text, setText] = useState<string | null>(props.formApi.getValues(props.name));

  const onChange = (text: string | null) => {
    props.formApi.setValue(props.name, text as PathValue<TFieldValues, Path<TFieldValues>>);
    setText(text);
  };

  return (
    <VStack>
      <HStack align="flex-end">
        <Box flex={1}>
          <Show when={props.label}>
            <Label>{props.label}</Label>
          </Show>
        </Box>
        <PredefinedNotes
          note={text}
          resource={props.resource}
          context={props.context}
          onPrefill={onChange}
          isLinkVariant
          data-testid={suffixTestId('predefinedNotes', props)}
        />
      </HStack>
      <Textarea
        name={props.name}
        value={text}
        onChange={onChange}
        isResizable={props.isResizable}
        rows={props.rows ?? 1}
        data-testid={suffixTestId('note', props)}
      />
    </VStack>
  );
}
