import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {getFormFieldName} from './getFormFieldName';

export type FieldsForReset = {
  name: string;
  type: string;
};

export const createFieldForReset = (
  categoryId: string,
  paramDefinition: AuditParamDefinition
): FieldsForReset => ({
  name: getFormFieldName(categoryId, paramDefinition.id),
  type: paramDefinition.type,
});
