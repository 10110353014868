import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import {workshopRoutes} from '@omnetic-dms/routes';

import {RequiredTestIdProps, composePath, noop, suffixTestId, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

interface ServiceCampaingsHyundaiProps extends RequiredTestIdProps {
  gridCode: 'service-campaign-hyundai' | 'service-campaign-hyundai-unfinished';
}

export function ServiceCampaingsHyundai(props: ServiceCampaingsHyundaiProps) {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const serviceCampaignId = isArray(rowId) ? rowId[0] : rowId;
    const path = composePath(workshopRoutes.serviceCampaignDetail, {
      params: {
        id: serviceCampaignId,
      },
    });

    match(actionKey)
      .with('redirectDetail', 'detail', () => navigate(path))
      .with('redirectDetail_newTab', 'detail_newTab', () => window.open(path))
      .otherwise(noop);
  };

  return (
    <DataGrid
      gridCode={props.gridCode}
      actionCallback={actionCallback}
      data-testid={suffixTestId('service-campaign-hyundai', props)}
    />
  );
}
