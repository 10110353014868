import {ZodError, ZodTypeAny} from 'zod';

import {ParseZodSchemaQueryInfo} from '../types/ParseZodSchemaQueryInfo';
import {handleSchemaParsingError} from './handleSchemaParsingError';
import {zodDeepStrict} from './zodDeepStrict';

type ParseZodSchemaArgs = {
  data: unknown;
  schema: ZodTypeAny;
  queryInfo: ParseZodSchemaQueryInfo;
};

export const parseZodSchema = (args: ParseZodSchemaArgs) => {
  const strictSchema = zodDeepStrict(args.schema);

  try {
    strictSchema.parse(args.data);
  } catch (error) {
    handleSchemaParsingError(error as ZodError, args.queryInfo);
  }
};
