import {
  GetItemsBasketActionsApiArg,
  GetItemsBasketActionsApiArgSchema,
  GetItemsBasketActionsApiResponse,
  GetItemsBasketActionsApiResponseSchema,
  PutItemsBasketActionApiArg,
  PutItemsBasketActionApiArgSchema,
  PutItemsBasketActionApiResponse,
  PutItemsBasketActionApiResponseSchema,
} from '../types/metadaWorkshopBasketAction';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopBasketActionApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    putItemsBasketAction: build.mutation<
      PutItemsBasketActionApiResponse,
      PutItemsBasketActionApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/${queryArg.basketType}/action`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialBasketItems', id: queryArg.serviceJobId},
        {type: 'itemsBasketActions', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: PutItemsBasketActionApiResponseSchema,
        requestSchema: PutItemsBasketActionApiArgSchema,
      },
    }),
    getItemsBasketActions: build.query<
      GetItemsBasketActionsApiResponse,
      GetItemsBasketActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/${queryArg.basketType}/actions`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'itemsBasketActions', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: GetItemsBasketActionsApiResponseSchema,
        requestSchema: GetItemsBasketActionsApiArgSchema,
      },
    }),
  }),
});

export const {usePutItemsBasketActionMutation, useGetItemsBasketActionsQuery} =
  metadaWorkshopBasketActionApi;
