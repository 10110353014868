import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {ParamType} from '../../../types/ParamType';
import {AuditParamType} from '../types/AuditParamType';
import {findByParamType} from './findByParamType';

export const getParamFromCategory = (
  category: AuditCategoryOfStructure | undefined | null,
  type: ParamType | AuditParamType | AuditParamType[]
): AuditParamDefinition | undefined => {
  if (Array.isArray(type)) {
    return type
      .map((auditParamType) => getParamFromCategory(category, auditParamType))
      .find((paramDefinition) => !!paramDefinition);
  }

  const paramDefinition = findByParamType(category?.paramDefinitions?.relatedActions, type);

  if (paramDefinition) {
    return paramDefinition;
  }

  return findByParamType(category?.paramDefinitions?.additionalInformation, type);
};
