import {ThemeIconKey} from 'platform/foundation';
import {match} from 'ts-pattern';

/**
 * Returns correct icon based on file's contentType property. Based on https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 * @param contentType File content type. If not provided fallbacks to default icon
 * @returns ThemeIconKey
 */
export function getFileIcon(contentType?: string) {
  return match<string | undefined, ThemeIconKey>(contentType)
    .with('application/pdf', () => 'files_default/file_type_PDF')
    .with('application/x-zip-compressed', () => 'files_default/file_type_ZIP')
    .with('image/jpeg', () => 'files_default/file_type_JPG')
    .with('image/png', () => 'files_default/file_type_PNG')
    .with('text/csv', () => 'files_default/file_type_CSV')
    .with('text/plain', () => 'files_default/file_type_TXT')
    .with(
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      () => 'files_default/file_type_DOC'
    )
    .with(
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      () => 'files_default/file_type_XLS'
    )
    .otherwise(() => 'editor/insert_drive_file');
}
