import {Text} from 'platform/foundation';
import styled from 'styled-components';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {suffixTestId, TestIdProps} from 'shared';

import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditParamValue} from '../../../types/AuditParamValue';
import {useConditionDamageLocation} from '../hooks/useConditionDamageLocation';
import {getDamageValueColor} from '../utils/getDamageValueColor';
import {getTranslation} from '../utils/getTranslation';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
`;
const LegendColorFlag = styled.div<{$color: string}>`
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 8px;
  background: ${({$color}) => $color};
`;

type LegendOfDamageProps = {
  value: AuditParamValue;
};

export const LegendOfDamage: FC<LegendOfDamageProps & TestIdProps> = ({
  value: paramValue,
  ...rest
}) => {
  const locale = useSelector(selectUserSelectedLanguage);

  const {onDamageLegendClick} = useConditionDamageLocation();

  if (!paramValue.label) {
    return null;
  }

  return (
    <Wrapper
      data-testid={suffixTestId(`legendOfDamage-${paramValue.label.toString()}-wrapper`, rest)}
      onClick={() => {
        onDamageLegendClick(paramValue.value);
      }}
    >
      <LegendColorFlag
        $color={getDamageValueColor(paramValue.value)}
        data-testid={suffixTestId(
          `legendOfDamage-${paramValue.label.toString()}-color-${getDamageValueColor(
            paramValue.value
          )}`,
          rest
        )}
      />
      <Text
        size="xSmall"
        color="secondary"
        data-testid={suffixTestId(`legendOfDamage-${paramValue.label.toString()}`, rest)}
      >
        {getTranslation(locale, paramValue.label)}
      </Text>
    </Wrapper>
  );
};
