import {ButtonGroup} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {TestIdProps, suffixTestId, useBoolean} from 'shared';

import {OwnershipType} from '../../CustomerVehicleSelection/types/ownershipType';
import {getOwnershipTitle} from '../utils/getOwnershipTitle';

interface OwnershipReplaceConfirmProps extends TestIdProps {
  onConfirm: () => Promise<void>;
  onClose: VoidFunction;
  ownershipType: OwnershipType;
}

export function OwnershipReplaceConfirm(props: OwnershipReplaceConfirmProps) {
  const [isLoading, startLoading, stopLoading] = useBoolean();

  const handleConfirm = async () => {
    startLoading();
    await props.onConfirm();
    stopLoading();
  };

  return (
    <VStack spacing={4}>
      <Heading size={4}>
        {i18n.t('entity.ownership.labels.replaceWarning', {
          OWNERSHIP: getOwnershipTitle(props.ownershipType).toLowerCase(),
        })}
      </Heading>
      <ButtonGroup
        align="right"
        buttons={[
          {
            title: i18n.t('general.labels.discard'),
            variant: 'secondary',
            onClick: props.onClose,
          },
          {
            title: i18n.t('general.actions.replace'),
            variant: 'danger',
            onClick: handleConfirm,
            isLoading,
            'data-testid': suffixTestId('confirm', props),
          },
        ]}
      />
    </VStack>
  );
}
