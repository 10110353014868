import {Show} from 'platform/foundation';

import {featureFlags} from '@omnetic-dms/feature-flags';
import {AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

import {PlatformPhotoSettings} from './components/PlatformPhotoSettings';

export function PlatformSettingsOlx() {
  return (
    <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
      <PlatformPhotoSettings platformCode={AdvertisingPlatformCodeEnum.OLX} />
    </Show>
  );
}
