import {Action, Card, openDialog} from 'platform/components';
import {Box, Show} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {TotalPriceWithAndWithoutVat, useDeleteSupplierOrderItemsMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {catchUnhandledDataGridActions, DEFAULT_CURRENCY} from '@omnetic-dms/shared';

import {buildArray, Nullish, suffixTestId, TestIdProps, useBoolean} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {OverviewTotalprice} from '../../../../../components/OverviewTotalPrice';
import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';
import {EDIT_SUPPLIER_ORDER_ITEM_DIALOG} from '../constants/editSupplierOrderItemDialog';
import {EditItem} from './EditItem';
import {MaterialModal} from './MaterialModal';

interface SupplierOrderItemListProps extends TestIdProps {
  totalPrice: TotalPriceWithAndWithoutVat | Nullish;
  isPending: boolean;
}

export function SupplierOrderItemList(props: SupplierOrderItemListProps) {
  const {supplierOrderId} = useWarehouseParams();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();
  const [isAddMaterialModalVisible, , , toggleAddMaterialModal] = useBoolean();

  const formatCurrency = useFormatCurrency();

  const [deleteSupplierOrderItems] = useDeleteSupplierOrderItemsMutation();

  const handleAddItem = () => {
    toggleAddMaterialModal();
  };

  const handleDiscardAddMaterialModal = () => {
    toggleAddMaterialModal();
    refreshDataGrid();
  };

  const handleEditItem = (supplierOrderItemId: string, refreshDatagrid: VoidFunction) => {
    openDialog(
      <EditItem
        supplierOrderId={supplierOrderId}
        supplierOrderItemId={supplierOrderItemId}
        onSubmit={refreshDatagrid}
        data-testid={testIds.warehouse.supplierOrderDetailOverview('forms.editItem')}
      />,
      {
        id: EDIT_SUPPLIER_ORDER_ITEM_DIALOG,
        title: i18n.t('general.labels.edit'),
        size: 'large',
        withAdditionalFooter: true,
        'data-testid': testIds.warehouse.supplierOrderDetailOverview('dialogs.editItem'),
      }
    );
  };

  const supplierListOfItemsActions = buildArray<Action>().when(props.isPending, {
    type: 'button',
    variant: 'link',
    leftIcon: 'content/add_circle',
    title: i18n.t('general.labels.add'),
    onClick: handleAddItem,
    'data-testid': suffixTestId('actions.add', props),
  });

  const totalPriceWithoutVat = formatCurrency(
    props.totalPrice?.totalWithoutVat?.amount ?? 0,
    props.totalPrice?.totalWithoutVat?.currency ?? DEFAULT_CURRENCY,
    2
  );

  const totalPriceWithVat = formatCurrency(
    props.totalPrice?.totalWithVat?.amount ?? 0,
    props.totalPrice?.totalWithVat?.currency ?? DEFAULT_CURRENCY,
    2
  );

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    match(actionKey)
      .with('redirectDetail', 'edit', () => {
        const supplierOrderItemId = isArray(rowId) ? head(rowId) : rowId;

        if (isNil(supplierOrderItemId)) {
          throw new Error('Item id is not defined!');
        }

        handleEditItem(supplierOrderItemId, refreshData);
      })
      .with('delete', () => {
        const itemId = isArray(rowId) ? rowId : [rowId];

        deleteSupplierOrderItems({orderId: supplierOrderId, body: {itemId}})
          .unwrap()
          .then(refreshData);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {orderId: supplierOrderId}]),
    [supplierOrderId]
  );

  return (
    <>
      <Card title={i18n.t('general.labels.listOfItems')} actions={supplierListOfItemsActions}>
        <Box height={85}>
          <DataGrid
            ref={dataGridRef}
            gridCode="warehouse-supplier-order-items"
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            data-testid={suffixTestId('list', props)}
          />
        </Box>
        <OverviewTotalprice
          totalPriceWithoutVat={totalPriceWithoutVat}
          totalPriceWithVat={totalPriceWithVat}
          data-testid={suffixTestId('totalPrice', props)}
        />
      </Card>
      <Show when={isAddMaterialModalVisible}>
        <MaterialModal
          orderId={supplierOrderId}
          onDiscard={handleDiscardAddMaterialModal}
          data-testid={suffixTestId('dialogs.addMaterial', props)}
        />
      </Show>
    </>
  );
}
