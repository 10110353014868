import {z} from 'zod';

import {BasketTooltipSchema, PriceWithAndWithoutVatSchema} from './aftersalesCommon';
import {BaseVoidResponseSchema} from './base';

const ReceiveNoteCorrectionBasketItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  warehouse_id: z.string(),
  manufacturerNumber: z.string(),
  manufacturerId: z.string().optional(),
  quantity: z.number(),
  unit: z.string(),
  dispensingUnit: z.number(),
  itemEditingAllowed: z.boolean(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: z.array(BasketTooltipSchema),
  minMaxQuantity: z.object({
    minQuantity: z.number(),
    maxQuantity: z.number(),
  }),
});

export type ReceiveNoteCorrectionBasketItem = z.infer<typeof ReceiveNoteCorrectionBasketItemSchema>;

/**
 * GET
 */
export const GetReceiveNoteCorrectionBasketRequestSchema = z.object({
  creditNoteId: z.string(),
});
export type GetReceiveNoteCorrectionBasketRequest = z.infer<
  typeof GetReceiveNoteCorrectionBasketRequestSchema
>;

export const GetReceiveNoteCorrectionBasketResponseSchema = z
  .object({
    creditNoteBasketItem: z.array(ReceiveNoteCorrectionBasketItemSchema).optional(),
    creditNoteBasketTotalPrice: PriceWithAndWithoutVatSchema.optional(),
  })
  .nullish();
export type GetReceiveNoteCorrectionBasketResponse = z.infer<
  typeof GetReceiveNoteCorrectionBasketResponseSchema
>;

/**
 * POST
 */
export const PostReceiveNoteCorrectionBasketCheckoutRequestSchema = z.object({
  creditNoteId: z.string(),
});
export type PostReceiveNoteCorrectionBasketCheckoutRequest = z.infer<
  typeof PostReceiveNoteCorrectionBasketCheckoutRequestSchema
>;

export const PostReceiveNoteCorrectionBasketCheckoutResponseSchema = BaseVoidResponseSchema;
export type PostReceiveNoteCorrectionBasketCheckoutResponse = z.infer<
  typeof PostReceiveNoteCorrectionBasketCheckoutResponseSchema
>;

/**
 * PATCH
 */
export const PatchReceiveNoteCorrectionBasketItemQuantityRequestSchema = z.object({
  creditNoteId: z.string(),
  creditNoteItemId: z.string(),
  body: z.object({
    correctionQuantity: z.number(),
  }),
});
export type PatchReceiveNoteCorrectionBasketItemQuantityRequest = z.infer<
  typeof PatchReceiveNoteCorrectionBasketItemQuantityRequestSchema
>;

export const PatchReceiveNoteCorrectionBasketItemQuantityResponseSchema = BaseVoidResponseSchema;
export type PatchReceiveNoteCorrectionBasketItemQuantityResponse = z.infer<
  typeof PatchReceiveNoteCorrectionBasketItemQuantityResponseSchema
>;

/**
 * DELETE
 */

/**
 * PUT
 */
