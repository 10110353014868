import {DropdownItem, openDeleteDialog, openDropdown} from 'platform/components';
import {Box, Center, Clickable, Icon, Image, Show, Spinner} from 'platform/foundation';
import styled from 'styled-components';

import {isNotNil} from 'ramda';

import {AuditDataVideoAssetsFilesBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

interface AuditVideoProps extends TestIdProps {
  videoAsset: AuditDataVideoAssetsFilesBody;
  ratio?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onOpenPreview: () => void;
  onDeleteVideo: (videoAsset: AuditDataVideoAssetsFilesBody) => void;
}

export function AuditVideo(props: AuditVideoProps) {
  const [isHovered, handleHover, handleLeave] = useBoolean();
  const [isPreviewLoaded, handlePreviewLoad] = useBoolean();
  const [isPreviewError, handlePreviewError] = useBoolean();

  const getDropdownItems = (videoAsset: AuditDataVideoAssetsFilesBody) => (
    <DropdownItem
      label={i18n.t('general.actions.delete')}
      leftIcon="action/delete"
      onClick={() => openDeleteDialog({onConfirm: () => props.onDeleteVideo(videoAsset)})}
    />
  );

  return (
    <Box
      position="relative"
      borderRadius="small"
      overflow="hidden"
      backgroundColor="palettes.neutral.30.100"
      ratio={props.ratio}
    >
      <Clickable
        onClick={props.onOpenPreview}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
        onContextMenu={(event) => {
          event.preventDefault();
          if (!props.isDisabled) {
            openDropdown(event, getDropdownItems(props.videoAsset));
          }
        }}
        isDisabled={props.isLoading}
        data-testid={suffixTestId('video', props)}
      >
        <Show when={isNotNil(props.videoAsset.coverImageUrl)}>
          <Image
            src={props.videoAsset.coverImageUrl}
            ratio={props.ratio}
            fit="cover"
            data-testid={suffixTestId('coverImage', props)}
          />
        </Show>
        <Show when={isHovered && !isPreviewError && isNotNil(props.videoAsset.previewUrl)}>
          {/* We need to add image to DOM, so browser can load it, but not show it until it is loaded */}
          <Hideable $hidden={!isPreviewLoaded}>
            <Box position="absolute" top={0} left={0} width="100%" height="100%">
              <Image
                src={props.videoAsset.previewUrl}
                ratio={props.ratio}
                fit="cover"
                onLoad={handlePreviewLoad}
                onError={handlePreviewError}
                data-testid={suffixTestId('coverImage', props)}
              />
            </Box>
          </Hideable>
        </Show>
        <Box position="absolute" top={0} left={0} width="100%" height="100%">
          <Center width="100%" height="100%" justify="center">
            {props.isLoading ? (
              <Spinner />
            ) : (
              <Icon data-testid={props['data-testid']} value="AV/play_arrow" size={8} />
            )}
          </Center>
        </Box>
        <Box
          opacity={isHovered ? 1 : 0}
          transition="opacity 0.2s"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          backgroundColor="palettes.black.900.20"
          borderRadius="small"
        />
      </Clickable>
    </Box>
  );
}

const Hideable = styled(Box)<{$hidden?: boolean}>`
  ${({$hidden}) => $hidden && 'display: none;'}
`;
