import {Integer, Show, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {ChangeEvent, ForwardedRef, forwardRef, useId, useLayoutEffect, useRef} from 'react';

import {Nullish, suffixTestId, TestIdProps, useComposedRef} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {HelperText} from '../HelperText/HelperText';
import {Label} from '../Label/Label';
import {LabelActions} from '../Label/LabelActions';
import {useFontsLoadedListener} from './hooks/useFontsLoadedListener';
import {useWindowResizeListener} from './hooks/useWindowResizeListener';
import {calculateNodeHeight} from './utils/calculateNodeHeight';
import {getSizingData, SizingData} from './utils/getSizingData';

export interface TextareaProps
  extends FormControlProps<string | null, HTMLTextAreaElement>,
    TestIdProps {
  isResizable?: boolean;
  isScrollable?: boolean;
  placeholder?: string;
  maxLength?: number;
  maxRows?: Integer | Nullish;
  minRows?: Integer | Nullish;
  rows?: Integer;
  isRecommended?: boolean;
  labelActions?: LabelActions;
  isCounterVisible?: boolean;
}

export const Textarea = forwardRef(
  (props: TextareaProps, userRef: ForwardedRef<HTMLTextAreaElement>) => {
    const id = useId();
    const libRef = useRef<HTMLTextAreaElement | null>(null);
    const ref = useComposedRef(libRef, userRef);
    const heightRef = useRef(0);
    const measurementsCacheRef = useRef<SizingData>();

    const resizeTextarea = () => {
      const node = libRef.current!;
      const nodeSizingData = getSizingData(node);

      if (!nodeSizingData) {
        return;
      }

      measurementsCacheRef.current = nodeSizingData;

      const [height] = calculateNodeHeight(
        nodeSizingData,
        node.value || node.placeholder,
        props.minRows ?? props.rows,
        props.maxRows ?? props.rows
      );

      if (heightRef.current !== height) {
        heightRef.current = height;
        node.style.setProperty('height', `${height}px`, 'important');
      }
    };

    useLayoutEffect(resizeTextarea);
    useWindowResizeListener(resizeTextarea);
    useFontsLoadedListener(resizeTextarea);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      props.onChange?.(event.target.value, event);
    };
    return (
      <VStack>
        <Label
          id={id}
          isRequired={props.isRequired}
          tooltip={props.tooltip}
          isRecommended={props.isRecommended}
          data-testid={suffixTestId('label', props)}
          actions={props.labelActions}
          maxLength={props.maxLength}
          isCounterVisible={props.isCounterVisible}
          currentLength={props.value?.length}
        >
          {props.label}
        </Label>
        <StyledTextarea
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          $isResizable={props.isResizable}
          $isScrollable={props.isScrollable}
          $isInvalid={props.isInvalid}
          $isRecommended={props.isRecommended}
          value={props.value ?? ''}
          onChange={handleChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          name={props.name}
          disabled={props.isDisabled}
          ref={ref}
          data-testid={suffixTestId('textareaInput', props)}
        />
        <Show when={props.errorMessage ?? props.helperText}>
          <HelperText
            errorMessage={props.errorMessage}
            helperText={props.helperText}
            data-testid={suffixTestId('helper', props)}
          />
        </Show>
      </VStack>
    );
  }
);

export type StyledTextareaProps = {
  $isResizable?: TextareaProps['isResizable'];
  $isScrollable?: TextareaProps['isScrollable'];
  $isInvalid?: TextareaProps['isInvalid'];
  $isRecommended?: TextareaProps['isRecommended'];
};

const StyledTextarea = styled.textarea<StyledTextareaProps>`
  display: block;
  width: 100%;
  border: 1px solid;
  border-color: ${({theme, $isInvalid}) =>
    $isInvalid ? theme.colors.severity.danger : theme.colors.palettes.neutral[70][100]};
  border-radius: ${({theme}) => theme.getSize(1)};
  color: ${({theme}) => theme.colors.text.primary};
  background-color: ${({theme, $isRecommended}) =>
    $isRecommended ? theme.colors.palettes.orange[10][100] : theme.colors.palettes.white[10][100]};
  font-size: ${({theme}) => theme.fontSizes.text.small};
  line-height: ${({theme}) => theme.lineHeights.text.small};
  font-weight: ${({theme}) => theme.fontWeights.text.default};
  resize: ${({$isResizable}) => ($isResizable ? 'vertical' : 'none')};
  overflow-y: ${({$isScrollable}) => ($isScrollable ? 'auto' : 'hidden')};
  outline: none;
  letter-spacing: 0;
  padding-left: ${({theme}) => theme.getSize(2)};
  padding-right: ${({theme}) => theme.getSize(2)};
  padding-top: 5px;
  padding-bottom: 5px;

  &:-webkit-autofill {
    background-color: ${({theme}) => theme.colors.palettes.yellow[20][100]};
  }

  &::placeholder {
    color: ${({theme}) => theme.colors.text.tertiary};
  }

  &:hover {
    border: 1px solid;
    border-color: ${({theme}) => theme.colors.severity.informational};
    background-color: ${({theme}) => theme.colors.palettes.white[10][100]};

    &:disabled {
      border-color: ${({theme}) => theme.colors.palettes.neutral[70][40]};
    }
  }

  &:active {
    border-color: ${({theme}) => theme.colors.severity.informational};
    box-shadow: ${({theme}) => theme.shadows.active};
  }

  &:focus {
    border-color: ${({theme}) => theme.colors.severity.informational};
    box-shadow: ${({theme}) => theme.shadows.active};
    background-color: ${({theme}) => theme.colors.palettes.white[10][100]};

    &:disabled {
      box-shadow: none;
      border-color: ${({theme}) => theme.colors.palettes.neutral[70][40]};
    }
  }

  &:disabled {
    border-color: ${({theme}) => theme.colors.palettes.neutral[70][40]};
    background-color: ${({theme}) => theme.colors.palettes.white[10][40]};
    cursor: not-allowed;
    -webkit-text-fill-color: ${({theme}) => theme.colors.text.primary};
    box-shadow: none;

    &:focus {
      border-color: ${({theme}) => theme.colors.palettes.neutral[70][40]};
    }
  }
`;
