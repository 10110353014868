import {HStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {TextInput} from '../../TextInput/TextInput';
import {useTranslationContext} from '../../TranslationProvider/TranslationContext';

interface InputSegmentProps extends TestIdProps {
  changePage: (newPage: number) => void;
  activePage: number;
  pagesQuantity: number;
  isDisabled?: boolean;
}

export function InputSegment(props: InputSegmentProps) {
  const t = useTranslationContext();

  const handleChange = (value: string | null) => {
    if (!value) {
      return;
    }

    props.changePage(Number(value));
  };

  return (
    <HStack spacing={2} align="center" data-testid={suffixTestId('inputSegmentWrapper', props)}>
      <TextInput
        value={props.activePage.toString()}
        onChange={handleChange}
        type="number"
        isDisabled={props.isDisabled}
        data-testid={suffixTestId('inputSegment', props)}
      />
      {t('general.labels.of')}
      <HStack height={6} align="center" data-testid={suffixTestId('inputSegmentQuantity', props)}>
        {props.pagesQuantity}
      </HStack>
    </HStack>
  );
}
