import {useRef, useState, useCallback, ForwardedRef, Dispatch, useImperativeHandle} from 'react';

import {equals} from 'ramda';
import {isArray, isEmptyArray} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {IFilter} from '../types/AgGridTypes';

export const useChoiceFilter = (
  filterChangeCallback: () => void,
  ref: ForwardedRef<IFilter>
): [string[] | string, Dispatch<string[] | string | Nullish>] => {
  const isActive = useRef<boolean>(false);
  const [values, _setValues] = useState<string[] | string | Nullish>();

  const setValues = useCallback((valuesToSet: string[] | string | Nullish) => {
    isActive.current = isArray(valuesToSet) ? (valuesToSet ?? []).length > 0 : !!valuesToSet;

    _setValues(valuesToSet);
  }, []);

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return isActive.current;
    },

    doesFilterPass() {
      return true;
    },

    getModel() {
      if (isEmptyArray(values)) {
        return null;
      }

      return values;
    },

    setModel(model: Array<string> | undefined) {
      if (equals(model, values)) {
        return;
      }

      if (model) {
        setValues(model);
      } else {
        setValues(undefined);
      }
      filterChangeCallback();
    },
  }));

  return [values ?? [], setValues];
};
