import styled from 'styled-components';

export const FieldLabel = styled.label`
  display: flex;
  font-family: ${({theme}) => theme.fonts.body};
  font-style: normal;
  font-weight: normal;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  line-height: 16px;
  margin-bottom: ${({theme}) => theme.getSize(1)};
`;

export const ValidationMessage = styled.div<{children: unknown}>`
  color: ${({theme}) => theme.colors.palettes.red[60][100]};
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  line-height: 16px;
  margin-top: ${({theme}) => theme.getSize(1)};
`;

export const GrayBox = styled.div`
  background: ${({theme}) => theme.colors.palettes.neutral[20][100]};
  border-radius: 8px;
  padding: ${({theme}) => theme.getSize(4)};
  margin-top: ${({theme}) => theme.getSize(4)};
`;
