import {isFeatureEnabled} from 'feature-flags';
import {
  Button,
  Card,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  FlagProps,
  Flags,
  IconButton,
  openDeleteDialog,
  Parameters,
  Separator,
} from 'platform/components';
import {
  Box,
  Clickable,
  Heading,
  Hide,
  HStack,
  Icon,
  Show,
  Space,
  Text,
  VStack,
} from 'platform/foundation';
import {CurrencyFormat, useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {defaultTo, find, isNil} from 'ramda';
import {isNotNil, isNotNilOrEmpty, isPositive} from 'ramda-adjunct';

import {
  useGetOrderJobItemsTotalAmountQuery,
  useGetServiceCaseJobItemsCountQuery,
  useGetServiceCaseOrderVariantsQuery,
  useGetServiceJobActionsQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {
  DEFAULT_CURRENCY,
  DEFAULT_PRICE,
  JobForm,
  JobFormProps,
  JobType,
  queryParams,
  usePermissions,
} from '@omnetic-dms/shared';

import {
  buildArray,
  DOT_CHARACTER,
  Nullish,
  parseDate,
  suffixTestId,
  TestIdProps,
  useQueryState,
} from 'shared';

import {getSparePartStateFlag} from '../../utils/getSparePartStateFlag';
import {getTimeTrackingStateFlag} from '../../utils/getTimeTrackingStateFlag';
import {AssignMechanic} from './components/AssignMechanic';
import {JobItemsDataGrid} from './components/JobItemsDataGrid';
import {JobTrackedTime} from './components/JobTrackedTime';
import {ServicePackageDataGrid} from './components/ServicePackageDataGrid';
import {JobAction} from './types/JobAction';

interface JobProps extends TestIdProps {
  serviceCaseId: string;
  jobData?: JobType | Nullish;
  orderId?: string;
  actions?: JobAction[];
  onRemove?: () => void;
  onSubmit: JobFormProps['onSubmit'];
  isReadOnly?: boolean;
  datagridKey?: string;
  isReportButtonDisabled?: boolean;
}

export function Job(props: JobProps) {
  const formatDateTime = useDateTimeFormatter();

  const [addWorkJobId, setAddWorkJobId] = useQueryState(queryParams.SERVICE_CASE_ADD_WORK_JOB_ID);
  const [addMaterialRequestJobId, setAddMaterialRequestJobId] = useQueryState(
    queryParams.SERVICE_CASE_ADD_MATERIAL_REQUEST_JOB_ID
  );

  const [isChronosEnabled] = usePermissions({permissionKeys: ['chronosUser']});

  const {orderVariant} = useGetServiceCaseOrderVariantsQuery(
    {serviceCaseId: props.serviceCaseId},
    {
      selectFromResult: ({data}) => ({
        orderVariant: find((variant) => variant?.id === props.jobData?.orderVariantId, data ?? []),
      }),
      skip: !props.jobData?.orderVariantId || !!props.orderId,
    }
  );
  const {data: totalAmount} = useGetOrderJobItemsTotalAmountQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceJobId: props.jobData?.id ?? '',
      serviceOrderId: props.orderId ?? '',
    },
    {
      skip: !props.jobData?.id || !props.orderId,
    }
  );
  const {data: actions} = useGetServiceJobActionsQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceJobId: props.jobData?.id ?? '',
      serviceOrderId: props.orderId ?? '',
    },
    {
      skip: !props.jobData?.id || !props.orderId,
    }
  );
  const {data: jobItemsCount} = useGetServiceCaseJobItemsCountQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceJobId: props.jobData?.id ?? '',
    },
    {
      skip: !props.jobData?.id,
    }
  );

  const [opendJobId, setOpendJobId, clearOpenJobId] = useQueryState(
    queryParams.SERVICE_CASE_OPEN_JOB_ID
  );

  const isOpen = isNotNilOrEmpty(props.jobData?.id) && opendJobId === props.jobData?.id;

  const dropdownIcon = isOpen ? 'navigation/expand_less' : 'navigation/expand_more';

  const toggleOpen = () => {
    if (isOpen) {
      return clearOpenJobId();
    }
    setOpendJobId(props.jobData?.id ?? '');
  };

  const handleOpenDeleteDialog = () => {
    if (!props.onRemove) {
      return;
    }

    openDeleteDialog({
      onConfirm: props.onRemove,
      text: i18n.t('entity.orderRequest.actions.delete', {name: props.jobData?.name}),
      'data-testid': suffixTestId('removeJob', props),
    });
  };

  const handleOpenAddWork = () => {
    if (props.jobData?.id) {
      setAddWorkJobId(props.jobData?.id);
    }
  };

  const handleOpenAddMaterialRequest = () => {
    if (props.jobData?.id) {
      setAddMaterialRequestJobId(props.jobData?.id);
    }
  };

  const sparePartFlag = getSparePartStateFlag(props.jobData?.sparePartWarehouseState);
  const timeTrackingFlag = getTimeTrackingStateFlag(props.jobData?.timeTrackingState);
  const flags = buildArray<FlagProps>()
    .when(
      isNotNil(timeTrackingFlag) && isFeatureEnabled(featureFlags.ACL_CHRONOS) && isChronosEnabled,
      timeTrackingFlag!
    )
    .when(isNotNil(sparePartFlag), sparePartFlag!)
    .when(props.jobData?.isServicePackage, {
      label: i18n.t('entity.workshopServicePackages.labels.servicePackage'),
      colorScheme: 'blue',
    })
    .when(props.jobData?.isServiceCampaign, {
      label: i18n.t('entity.campaigns.labels.serviceCampaign'),
      colorScheme: 'yellow',
    })
    .when(props.jobData?.repeatedRepair, {
      label: i18n.t('entity.workshop.labels.repeatedRepair'),
      colorScheme: 'red',
      isSubtle: true,
    })
    .when(props.jobData?.partsRequest && props.jobData.partsReady, {
      label: i18n.t('entity.workshop.labels.partsReady'),
      colorScheme: 'blue',
      isSubtle: true,
    })
    .when(props.jobData?.partsRequest && !props.jobData.partsReady, {
      label: i18n.t('entity.workshop.labels.partsRequest'),
      colorScheme: 'orange',
      isSubtle: true,
    });

  const parameters = [
    props.jobData?.serviceCampaignDetails?.defectCode,
    props.jobData?.serviceCampaignDetails?.startDate &&
      `${i18n.t('general.labels.from')}: ${formatDateTime(
        'dateShort',
        parseDate(props.jobData?.serviceCampaignDetails?.startDate)
      )}`,
    props.jobData?.serviceCampaignDetails?.dueDate &&
      `${i18n.t('general.labels.to')}: ${formatDateTime(
        'dateShort',
        parseDate(props.jobData?.serviceCampaignDetails?.dueDate)
      )}`,
    props.jobData?.servicePackageDetails?.number,
    orderVariant?.name,
  ];

  const totalPriceWithVat = defaultTo(
    DEFAULT_PRICE,
    match(props.jobData?.isFixedPrice)
      .with(
        true,
        () =>
          props.jobData?.fixedPrice?.withVat?.amount ??
          totalAmount?.totalPrice?.withVat?.amount ??
          DEFAULT_PRICE
      )
      .otherwise(() => (props.orderId ? totalAmount?.totalPrice?.withVat?.amount : DEFAULT_PRICE))
  );

  const totalPriceWithVatCurrency = defaultTo(
    DEFAULT_CURRENCY,
    match(props.jobData?.isFixedPrice)
      .with(
        true,
        () =>
          props.jobData?.fixedPrice?.withVat?.currency ??
          totalAmount?.totalPrice?.withVat?.currency ??
          DEFAULT_CURRENCY
      )
      .otherwise(() =>
        props.orderId ? totalAmount?.totalPrice?.withVat?.currency : DEFAULT_CURRENCY
      )
  );

  const totalPriceWithoutVat = defaultTo(
    DEFAULT_PRICE,
    match(props.jobData?.isFixedPrice)
      .with(
        true,
        () =>
          props.jobData?.fixedPrice?.withoutVat?.amount ??
          totalAmount?.totalPrice?.withoutVat?.amount ??
          DEFAULT_PRICE
      )
      .otherwise(() =>
        props.orderId ? totalAmount?.totalPrice?.withoutVat?.amount : DEFAULT_PRICE
      )
  );

  const totalPriceWithoutVatCurrency = defaultTo(
    DEFAULT_CURRENCY,
    match(props.jobData?.isFixedPrice)
      .with(
        true,
        () =>
          props.jobData?.fixedPrice?.withoutVat?.currency ??
          totalAmount?.totalPrice?.withoutVat?.currency ??
          DEFAULT_CURRENCY
      )
      .otherwise(() =>
        props.orderId ? totalAmount?.totalPrice?.withoutVat?.currency : DEFAULT_CURRENCY
      )
  );

  return (
    <Card variant={props.jobData?.isUncategorized ? 'inlineWhite' : 'inlineGrey'}>
      <Box data-testid={suffixTestId('head', props)}>
        <HStack align="center" height="100%">
          <Box flex={1}>
            <Clickable onClick={toggleOpen}>
              <VStack align="flex-start">
                <HStack spacing={2} align="center">
                  <Heading size={5} data-testid={suffixTestId('name', props)}>
                    {props.jobData?.name}
                  </Heading>
                  <Flags size="small" flags={flags} data-testid={suffixTestId('state', props)} />
                </HStack>
                <Hide when={props.jobData?.isUncategorized}>
                  <HStack spacing={1} align="center" height={6}>
                    <Show when={isNotNil(props.orderId)}>
                      <AssignMechanic
                        assignMechanics={props.jobData?.assignMechanics}
                        jobId={props.jobData?.id ?? ''}
                        data-testid={suffixTestId('assignMechanic', props)}
                      />
                    </Show>
                    <Show when={isNotNil(props.orderId) && parameters.some(isNotNil)}>
                      <Text color="tertiary" size="xSmall">
                        {DOT_CHARACTER}
                      </Text>
                    </Show>
                    <Parameters
                      color="tertiary"
                      parameters={parameters}
                      data-testid={suffixTestId('parameters', props)}
                    />
                    <Show when={props.jobData?.isDoNotApplyDiscount || props.jobData?.isFixedPrice}>
                      <Text size="xSmall" color="tertiary">
                        {DOT_CHARACTER}
                      </Text>
                      <Icon value="action/info" size={4} color="severity.warning" />
                      <Text size="xSmall" color="warning">
                        {props.jobData?.isFixedPrice
                          ? i18n.t('entity.workshopServicePackages.labels.customPrice')
                          : i18n.t('entity.orderRequest.labels.noDiscounts')}
                      </Text>
                    </Show>
                    <Show when={props.jobData?.servicePackageDetails?.isLock}>
                      <Text size="xSmall" color="tertiary">
                        {DOT_CHARACTER}
                      </Text>
                      <Icon value="action/lock" size={4} color="severity.danger" />
                      <Text size="xSmall" color="danger">
                        {i18n.t('entity.serviceOrder.states.locked')}
                      </Text>
                    </Show>
                  </HStack>
                </Hide>
                <Show when={props.jobData?.isUncategorized}>
                  <Text size="xSmall" color="secondary">
                    {i18n.t('entity.order.labels.uncategorizedDescription')}
                  </Text>
                </Show>
              </VStack>
            </Clickable>
          </Box>
          <HStack align="center" height="100%" spacing={4}>
            <Show when={props.orderId || props.jobData?.isFixedPrice}>
              <VStack spacing={1} align="flex-end">
                <Text
                  size="small"
                  alternative
                  data-testid={suffixTestId('totalPriceWithVat', props)}
                >
                  <CurrencyFormat
                    decimals={2}
                    number={totalPriceWithVat}
                    currency={totalPriceWithVatCurrency}
                  />
                </Text>
                <Text
                  size="xSmall"
                  color="secondary"
                  data-testid={suffixTestId('totalPriceWithoutVat', props)}
                >
                  <CurrencyFormat
                    decimals={2}
                    number={totalPriceWithoutVat}
                    currency={totalPriceWithoutVatCurrency}
                  />
                  {` ${i18n.t('general.labels.withoutVat')}`}
                </Text>
              </VStack>
            </Show>
            <JobTrackedTime
              data-testid={suffixTestId('jobTrackedTime', props)}
              serviceCaseId={props.serviceCaseId}
              serviceOrderId={props.orderId}
              serviceCaseJobId={props.jobData?.id}
              estimatedTime={props.jobData?.workTimeEstimated}
              jobName={props.jobData?.name ?? ''}
              isDisabled={props.isReportButtonDisabled}
            />
            <HStack align="center" height="100%" spacing={2}>
              <Show when={actions && !props.isReadOnly}>
                <Dropdown
                  data-testid={suffixTestId('actions', props)}
                  dropdownControl={
                    <IconButton
                      icon="navigation/more_vert"
                      size="small"
                      data-testid={suffixTestId('more-actions', props)}
                    />
                  }
                >
                  {actions?.jobActions?.options?.filter(isNotNil).map((option, index) => {
                    const action = props.actions?.find((action) => action?.key === option?.key);

                    if (isNil(action)) {
                      return null;
                    }

                    return (
                      <Hide when={option.style === 'hidden'} key={option.key}>
                        <Show when={option.type === 'list-item' && isNotNil(action)}>
                          <DropdownItem
                            {...action}
                            isDisabled={option.style === 'disabled'}
                            data-testid={suffixTestId(`action-[${index}]`, props)}
                          />
                        </Show>
                        <Show when={option.type === 'divider'}>
                          <DropdownDivider />
                        </Show>
                      </Hide>
                    );
                  })}
                </Dropdown>
              </Show>
              <Show when={props.onRemove && !props.isReadOnly}>
                <IconButton
                  icon="content/remove_circle"
                  onClick={handleOpenDeleteDialog}
                  severity="danger"
                  size="small"
                  data-testid={suffixTestId('remove', props)}
                />
              </Show>
              <IconButton
                icon={dropdownIcon}
                onClick={toggleOpen}
                size="small"
                data-testid={suffixTestId('expand', props)}
              />
            </HStack>
          </HStack>
        </HStack>
      </Box>
      <Show when={isOpen}>
        <Box paddingTop={4}>
          <Hide when={props.jobData?.isUncategorized}>
            <JobForm
              serviceCaseId={props.serviceCaseId}
              jobData={props.jobData}
              orderId={props.orderId}
              onSubmit={props.onSubmit}
              isReadOnly={props.isReadOnly}
              isLockedServicePackage={!!props.jobData?.servicePackageDetails?.isLock}
              data-testid={suffixTestId('jobForm', props)}
            />
          </Hide>
          <Show when={isNil(props.orderId) && isPositive(jobItemsCount?.itemCount)}>
            <Separator />
            <ServicePackageDataGrid
              jobId={props.jobData?.id ?? ''}
              data-testid={suffixTestId('items', props)}
            />
          </Show>
          <Show when={props.orderId}>
            <Separator />
            <HStack justify="space-between" align="center">
              <Heading size={4}>{i18n.t('entity.orderRequest.labels.workItems')}</Heading>
              <Hide when={props.isReadOnly}>
                <HStack justify="space-between" align="center" spacing={2}>
                  <Button
                    variant="ghostLink"
                    leftIcon="content/add_circle"
                    onClick={handleOpenAddWork}
                    data-testid={suffixTestId('addWork', props)}
                    isDisabled={!!props.jobData?.servicePackageDetails?.isLock}
                    title={i18n.t('entity.addWork.actions.addWork')}
                  />
                  <Button
                    variant="ghostLink"
                    leftIcon="content/add_circle"
                    onClick={handleOpenAddMaterialRequest}
                    data-testid={suffixTestId('addMaterial', props)}
                    isDisabled={!!props.jobData?.servicePackageDetails?.isLock}
                    title={i18n.t('entity.addMaterial.actions.addMaterial')}
                  />
                </HStack>
              </Hide>
            </HStack>
            <Space vertical={4} />
            <JobItemsDataGrid
              key={props.datagridKey}
              jobId={props.jobData?.id}
              orderId={props.orderId}
              serviceCaseId={props.serviceCaseId}
              datagridKey={addWorkJobId ?? addMaterialRequestJobId}
              requestName={props.jobData?.name}
              data-testid={suffixTestId('items', props)}
            />
          </Show>
        </Box>
      </Show>
    </Card>
  );
}
