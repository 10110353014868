import {Alert, FormControl, FormField, Separator} from 'platform/components';
import {Show, VStack} from 'platform/foundation';
import {ValidationError} from 'yup';

import {UseFormReturn, useWatch} from 'react-hook-form';

import {defaultTo} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {Nullish, precisionCalculation, suffixTestId, TestIdProps, yupNumber} from 'shared';

export type ReservationForm = {
  quantity: number;
  requestExpiresAtDate: Date | Nullish;
  requestExpiresAtTime: string | Nullish;
  note?: string;
};

interface ReservationInfoProps extends TestIdProps {
  formApi: UseFormReturn<ReservationForm>;
  control: FormControl<ReservationForm>;
  dispensingUnit: number;
  duplicateError?: boolean;
  isNoteRequired?: boolean;
  isQuantityDisabled?: boolean;
  reservationItemId?: string;
}

export function ReservationInfo(props: ReservationInfoProps) {
  const requestExpiresAtDate = useWatch({name: 'requestExpiresAtDate'});

  const handleReservationDateChange = (
    formApi: UseFormReturn<ReservationForm>,
    newReservationDate: Date | null
  ) => {
    if (isNotNil(newReservationDate)) {
      return;
    }
    formApi.setValue('requestExpiresAtTime', null);
  };

  const validateQuantity = (value: number | null, formApi: UseFormReturn<ReservationForm>) => {
    const valueToValidate = defaultTo(0, value);

    try {
      formApi.clearErrors('quantity');

      quantitySchema(props.dispensingUnit).validateSync(valueToValidate);

      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        formApi.setError('quantity', {message: error.errors.join(' ')});
      }

      return false;
    }
  };

  return (
    <VStack spacing={4}>
      <Show when={props.duplicateError}>
        <Alert
          variant="error"
          title={i18n.t('entity.warehouse.notifications.reservationAlreadyExists')}
          data-testid={suffixTestId('duplicitReservation', props)}
        />
      </Show>

      <FormField
        control={props.control}
        name="quantity"
        type="number"
        label={i18n.t('entity.warehouse.labels.quantity')}
        step={props.dispensingUnit}
        isStepperVisible
        shouldRoundStepsByDifference
        decimalPlaces={4}
        onChange={(value) => validateQuantity(value, props.formApi)}
        isDisabled={props.isQuantityDisabled}
        isRequired
        data-testid={suffixTestId('quantity', props)}
      />

      <Separator />

      <FormField
        control={props.control}
        name="requestExpiresAtDate"
        type="date"
        label={i18n.t('entity.warehouse.labels.reservationDate')}
        onChange={(newReservationDate) =>
          handleReservationDateChange(props.formApi, newReservationDate)
        }
        data-testid={suffixTestId('reservationDate', props)}
      />

      <FormField
        control={props.control}
        name="requestExpiresAtTime"
        type="time"
        label={i18n.t('entity.warehouse.labels.reservationTime')}
        isDisabled={!requestExpiresAtDate}
        data-testid={suffixTestId('reservationTime', props)}
      />

      <FormField
        control={props.control}
        name="note"
        type="textarea"
        label={i18n.t('entity.warehouse.labels.note')}
        rows={8}
        isRequired={props.isNoteRequired}
        data-testid={suffixTestId('note', props)}
      />
    </VStack>
  );
}

const quantitySchema = (dispensingUnit: number) =>
  yupNumber
    .min(0.0001, (params) => i18n.t('general.errors.number.greaterOrEqual', {min: params.min}))
    .test(
      'isQuantityDivisibleByDispensingUnit',
      `${i18n.t(
        'entity.warehouse.labels.quantityMustBeMultipleOfDispensingUnit'
      )} (${dispensingUnit})`,
      (value) => precisionCalculation.modulo(defaultTo(0, value), dispensingUnit) === 0
    );
