import {Button, DataStatus, useDialog} from 'platform/components';
import {Box, HStack, Heading, VStack} from 'platform/foundation';

import {RefObject, useCallback} from 'react';

import {find, isNil, mergeAll} from 'ramda';

import {useGetMetadaServiceCaseQuery, useGetVehicleCustomerQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {useGetDocumentDatagridActions, UploadDocumentsDialog} from '@omnetic-dms/shared';

import {DataGrid, DataGridRef, QueryFilterObject} from 'features/datagrid';

import {useRefreshDocumentCount} from '../../../../../hooks/useRefreshDocumentCount';
import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';

interface ServiceOrderDocumentsProps {
  dataGridRef: RefObject<DataGridRef>;
  refreshDataGrid: () => void;
}

export function ServiceOrderDocuments(props: ServiceOrderDocumentsProps) {
  const {orderId, serviceCaseId} = useWorkshopUrl();
  const refreshDocumentCount = useRefreshDocumentCount(serviceCaseId);

  const {data: serviceCase} = useGetMetadaServiceCaseQuery({serviceCaseId});

  const {selectedOwnership, isSelectedOwnershipLoading, isSelectedOwnershipError} =
    useGetVehicleCustomerQuery(
      {vehicleId: serviceCase?.vehicleId ?? ''},
      {
        skip: isNil(serviceCase?.vehicleId),
        selectFromResult: ({data, isLoading, isError, isFetching}) => ({
          selectedOwnership: find(
            (ownership) => ownership?.customerType === 'CORVEHICLECUSTOMERTYPE_OPERATOR',
            data ?? []
          ),
          isSelectedOwnershipLoading: isLoading,
          isSelectedOwnershipError: isError,
          isSelectedOwnershipFetching: isFetching,
        }),
      }
    );
  const [actionCallback] = useGetDocumentDatagridActions(
    props.refreshDataGrid,
    selectedOwnership?.customerId
  );

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceCaseOrderId: orderId}]),
    [orderId]
  );

  const handleDocumentCreated = () => {
    props.refreshDataGrid();
    refreshDocumentCount();
  };

  return (
    <VStack>
      <HStack justify="space-between">
        <Heading size={4}>{i18n.t('general.labels.documents')}</Heading>
        <Button
          variant="link"
          onClick={openUploadDialog}
          leftIcon="action/backup"
          title={i18n.t('entity.document.actions.uploadDocument')}
          data-testid={testIds.workshop.serviceCases('uploadDocument')}
        />
      </HStack>
      <DataStatus
        isLoading={isSelectedOwnershipLoading}
        isError={isSelectedOwnershipError}
        minHeight={33}
      >
        <Box minHeight={40}>
          <DataGrid
            autoHeight
            ref={props.dataGridRef}
            gridCode="document-context-list-service-case-order"
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            data-testid={testIds.workshop.serviceCases('documentDataGrid')}
          />
        </Box>
      </DataStatus>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={handleDocumentCreated}
        contextTarget="service-case"
        contextId={serviceCaseId}
        bulkContext={[
          {target: 'service-case-order', targetId: orderId},
          {target: 'service-case', targetId: serviceCaseId},
        ]}
      />
    </VStack>
  );
}
