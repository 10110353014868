import type {CancelablePromise} from '../types/CancelablePromise';
import type {VinDecoder} from '../types/VinDecoder';
import {request as __request} from '../utils/request';

export class VinDecoderService {
  /**
   * @returns VinDecoder
   * @throws ApiError
   */
  public static vinDecoderDecode({
    vin,
    authorization,
  }: {
    vin: string;
    authorization?: string;
  }): CancelablePromise<VinDecoder> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vin-decoder/{vin}',
      path: {
        vin,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
}
