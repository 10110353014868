import styled from 'styled-components';

import {DatePickerInputContainer} from './baseStyles';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  ${DatePickerInputContainer} {
    &.range {
      width: 283px;
    }

    &.time-range {
      width: 177px;
    }
  }

  ${DatePickerInputContainer}:nth-child(2), ${DatePickerInputContainer}:nth-child(3) {
    margin-left: ${({theme}) => theme.getSize(3)};
  }
`;

export const TimePickersWrapper = styled.div`
  display: flex;
  align-items: flex-start !important;
`;

export const CalendarsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  position: absolute;
  top: 56px;
  z-index: 2;
  background-color: #fff;
  padding: ${({theme}) => theme.getSize(3)};
  box-shadow: 0 8px 16px rgb(0 0 0 / 12%);
  border: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  border-radius: ${({theme}) => theme.radii.small};

  ${TimePickersWrapper} {
    width: 100%;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: flex-end;
  }
`;
