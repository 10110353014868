import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';

import {createContext} from 'shared';

type Context = {
  onApiError: (error: FetchBaseQueryError) => void;
};

const [DataGridErrorContextProvider, useDataGridErrorContext] = createContext<Context>({
  strict: false,
  name: 'DatagridErrorProvider',
});

export {DataGridErrorContextProvider, useDataGridErrorContext};
