import {FormControl, FormField} from 'platform/components';
import {Grid, Show} from 'platform/foundation';

import {useEffect, useMemo} from 'react';
import {UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {FormContactPerson} from '../../../hooks/useContactPersons';
import {EmailSmsNotificationForm} from '../types/EmailSmsNotificationForm';

interface SmsSectionProps extends RequiredTestIdProps {
  control: FormControl<EmailSmsNotificationForm>;
  formApi: UseFormReturn<EmailSmsNotificationForm>;
  personsWithPhones: FormContactPerson[];
}

export function SmsSection(props: SmsSectionProps) {
  const {watch, setValue} = props.formApi;

  const enableSms = watch('sms.isEnabled');
  const selectedPerson = watch('sms.data.person');

  const availablePhones = useMemo(
    () => props.personsWithPhones.find((item) => item.value === selectedPerson)?.phoneNumbers || [],
    [props.personsWithPhones, selectedPerson]
  );

  useEffect(() => {
    setValue('sms.data.phone', availablePhones[0]?.value || '');
  }, [availablePhones, setValue]);

  return (
    <>
      <FormField
        control={props.control}
        type="switch"
        name="sms.isEnabled"
        label={i18n.t('entity.workshop.labels.enableSmsNotifications')}
        data-testid={suffixTestId('sms.isEnabled', props)}
      />
      <Show when={enableSms}>
        <Grid columns={2}>
          <FormField
            control={props.control}
            name="sms.data.person"
            type="choice"
            label={i18n.t('entity.businessCase.labels.contactPerson')}
            options={props.personsWithPhones}
            onChange={() => setValue('sms.data.phone', '')}
            data-testid={suffixTestId('sms.data.person', props)}
            isNotClearable
            isRequired
          />
          <FormField
            control={props.control}
            name="sms.data.phone"
            type="choice"
            label={i18n.t('entity.person.labels.phoneNumber')}
            data-testid={suffixTestId('sms.data.phone', props)}
            options={availablePhones}
            isNotClearable
            isRequired
          />
        </Grid>
        <FormField
          control={props.control}
          type="textarea"
          name="sms.data.message"
          rows={5}
          label={i18n.t('general.labels.message')}
          data-testid={suffixTestId('sms.data.message', props)}
          isRequired
        />
      </Show>
    </>
  );
}
