import {boolean, object} from 'yup';

import {yupString} from 'shared';

export const $AddressData = object({
  address: object().shape({
    prefix: yupString,
    street: yupString.required(),
    descriptiveNumber: yupString,
    orientationNumber: yupString,
    city: yupString.required(),
    district: yupString,
    zip: yupString.required(),
    country: yupString.required(),
    addressComplement: yupString,
  }),
  type: yupString,
  invalid: boolean().nullable(),
});
