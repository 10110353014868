import {isEmpty, isNil, clone} from 'ramda';

import {DeviceState} from '@omnetic-dms/api';

import {Nullish} from 'shared';

const STATE_ORDER: Record<DeviceState, number> = {
  paired: 1,
  unpaired: 2,
  inactive: 3,
};

export const sortDevicesByState = <T extends {state: DeviceState}>(
  pairedDevices: T[] | Nullish
): T[] => {
  const devices = clone(pairedDevices);

  if (isNil(devices) || isEmpty(devices)) {
    return [];
  }

  return devices?.sort((a, b) => STATE_ORDER[a.state] - STATE_ORDER[b.state]);
};
