import {levenshteinDistance} from './levenshteinDistance';

export const makeModelInputFilter: ((option: any, rawInput: string) => boolean) | null = (
  option,
  rawInput
) => {
  if (!rawInput) {
    return true;
  } // if input is empty, display all options
  if (!option.label) {
    return false;
  }
  if (typeof option.label !== 'string') {
    return true;
  }

  const optionNormalized = normalizeMakeModelInput(option.label);
  const inputNormalized = normalizeMakeModelInput(rawInput);

  return (
    optionNormalized.includes(inputNormalized) ||
    (inputNormalized.length > 3 && levenshteinDistance(optionNormalized, inputNormalized) <= 1)
  );
};

const normalizeMakeModelInput = (input: string) =>
  removeAccents(input.replace(/-| |_/g, '').toLocaleLowerCase());

const convertStringToUpperCase = (s: string, indexes: number[]) => {
  let newString = s;
  indexes.forEach((num) => {
    newString = newString.substr(0, num) + newString[num].toUpperCase() + newString.substr(num + 1);
  });

  return newString;
};

const removeAccents = (s: string, preventLowerCase?: boolean) => {
  const indexes: number[] = getUpperCaseIndexes(s);
  let r = s.toLowerCase();
  r = r.replace(new RegExp('[àáâãäå]', 'gi'), 'a');
  r = r.replace(new RegExp('æ', 'g'), 'ae');
  r = r.replace(new RegExp('[čç]', 'g'), 'c');
  r = r.replace(new RegExp('ď', 'gi'), 'd');
  r = r.replace(new RegExp('[èéêëě]', 'g'), 'e');
  r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
  r = r.replace(new RegExp('[ñň]', 'g'), 'n');
  r = r.replace(new RegExp('[òóôõö]', 'g'), 'o');
  r = r.replace(new RegExp('ř', 'g'), 'r');
  r = r.replace(new RegExp('š', 'g'), 's');
  r = r.replace(new RegExp('œ', 'g'), 'oe');
  r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
  r = r.replace(new RegExp('ť', 'g'), 't');
  r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
  r = r.replace(new RegExp('ž', 'g'), 'z');

  if (preventLowerCase) {
    return convertStringToUpperCase(r, indexes);
  }

  return r;
};

const getUpperCaseIndexes = (s: string): number[] => {
  const indexes: number[] = [];
  Array.from(Array(s.length)).forEach((_, index) => {
    if (s[index] === s[index].toUpperCase()) {
      indexes.push(index);

      return;
    }
  });

  return indexes;
};
