import {
  DeletePredefinedNoteApiArg,
  DeletePredefinedNoteApiResponse,
  GetListPredefinedNoteApiArg,
  GetListPredefinedNoteApiResponse,
  GetPredefinedNoteApiArg,
  GetPredefinedNoteApiResponse,
  PostPredefinedNoteApiArg,
  PostPredefinedNoteApiResponse,
} from '../types/predefinedNote';
import {omneticApi} from './baseApi/omneticApi';

export const predefinedNoteApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    deletePredefinedNote: build.mutation<
      DeletePredefinedNoteApiResponse,
      DeletePredefinedNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/predefined-note/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PredefinedNotes'],
    }),
    getPredefinedNote: build.query<GetPredefinedNoteApiResponse, GetPredefinedNoteApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/predefined-note/${queryArg.id}`,
      }),
      providesTags: (result, error, queryArgs) => [{type: 'PredefinedNotes', id: queryArgs.id}],
    }),
    getListPredefinedNote: build.query<
      GetListPredefinedNoteApiResponse,
      GetListPredefinedNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/predefined-note/${queryArg.resourceId}/${queryArg.context}`,
      }),
      providesTags: ['PredefinedNotes'],
    }),
    postPredefinedNote: build.mutation<PostPredefinedNoteApiResponse, PostPredefinedNoteApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/predefined-note`,
        method: 'POST',
        body: queryArg.predefinedNoteRequestBody,
      }),
      invalidatesTags: ['PredefinedNotes'],
    }),
  }),
});

export const {
  useDeletePredefinedNoteMutation,
  useGetPredefinedNoteQuery,
  useGetListPredefinedNoteQuery,
  usePostPredefinedNoteMutation,
} = predefinedNoteApi;
