import type {CancelablePromise} from '../types/CancelablePromise';
import {request as __request} from '../utils/request';

export class DefaultTemplateService {
  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static hideDefaultTemplate({
    templateId,
    authorization,
  }: {
    templateId: string;
    authorization?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/document/template/default/{templateId}/hide',
      path: {
        templateId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static unhideDefaultTemplate({
    templateId,
    authorization,
  }: {
    templateId: string;
    authorization?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/document/template/default/{templateId}/unhide',
      path: {
        templateId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
}
