import {splitEvery, values} from 'ramda';

import {
  DATA_GRID_SEARCH_PARAMS_ID,
  DataGridSearchParams,
  DEFAULT_ROW_INDEX,
} from '../../hooks/useUrlSync';

/**
 * @about Utility function to get dataGrid info from URL
 * @returns DataGridSearchParams
 * @example
 *  URL = http://metada.localhost:4200/vehicles&datagrids=vehicle%2C6f5fc539-05cd-40c4-abd4-21bf9a9e9c7d%2C1
 *
 *  const returnValue = getSearchParamsByGridCode('vehicle);
 *  /=>{gridCode: 'vehicle', queryId: '6f5fc539-05cd-40c4-abd4-21bf9a9e9c7d', rowIndex: '1'}
 */
export const getSearchParamsByGridCode = (gridCode: string): DataGridSearchParams => {
  const searchParams = getFormattedSearchParams();

  return searchParams[gridCode];
};

/**
 * @about Utility function to get dataGrid info from URL
 * @returns DataGridSearchParams
 * @example
 *  URL = http://metada.localhost:4200/vehicles?datagrids=vehicle%2C6f5fc539-05cd-40c4-abd4-21bf9a9e9c7d%2C1&datagrids=customers%2C6f5fc539-05cd-40c4-abd4-21bf9a9e9c7d%2C1
 *
 *  const returnValue = getFormattedSearchParams();
 *  /=> {
 *         vehicle : {gridCode: 'vehicle', queryId: '6f5fc539-05cd-40c4-abd4-21bf9a9e9c7d', rowIndex: '1'},
 *         customers : {gridCode: 'customers', queryId: '6f5fc539-05cd-40c4-abd4-21bf9a9e9c7d', rowIndex: '1'}
 *      }
 */
export const getFormattedSearchParams = (): Record<string, DataGridSearchParams> => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;

  const rawDataGridSearchParams = splitEvery(3)(
    searchParams.getAll(DATA_GRID_SEARCH_PARAMS_ID)[0]?.split(',') ?? []
  );

  const formattedSearchParams: Record<string, DataGridSearchParams> = {};

  rawDataGridSearchParams.forEach((searchParam) => {
    formattedSearchParams[searchParam[0]] = {
      gridCode: searchParam[0],
      queryId: searchParam[1],
      rowIndex: searchParam[2],
    };
  });

  return formattedSearchParams;
};

export const convertToSearchParams = (
  newSearchParams: Record<string, DataGridSearchParams>,
  omitSearchParams?: boolean
): Record<string, string> => {
  let params = '';

  values(newSearchParams).forEach((searchParam, index) => {
    const separator = index > 0 ? ',' : '';

    params =
      params +
      `${separator}${searchParam.gridCode},${searchParam.queryId},${
        searchParam.rowIndex ?? DEFAULT_ROW_INDEX
      }`;
  });

  if (omitSearchParams) {
    return {
      [DATA_GRID_SEARCH_PARAMS_ID]: params,
    };
  }

  return {
    ...Object.fromEntries(new URLSearchParams(window.location.search)),
    [DATA_GRID_SEARCH_PARAMS_ID]: params,
  };
};
