import {propOr} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  ActiveBusinessCaseResponseBody,
  VehiclePurchaseBrokerageResponseBody,
  VehiclePurchaseResponseBody,
  VehicleSaleResponseBody,
} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getVehicle = (
  activeBusinessCase: ActiveBusinessCaseResponseBody
):
  | VehicleSaleResponseBody
  | VehiclePurchaseResponseBody
  | VehiclePurchaseBrokerageResponseBody
  | null => {
  if (isNotNilOrEmpty(activeBusinessCase.vehiclesSale)) {
    return propOr<
      VehicleSaleResponseBody | Nullish,
      VehicleSaleResponseBody[] | Nullish,
      VehicleSaleResponseBody
    >(null, '0', activeBusinessCase.vehiclesSale);
  }

  if (isNotNilOrEmpty(activeBusinessCase.vehiclesPurchase)) {
    return propOr<
      VehiclePurchaseResponseBody | Nullish,
      VehiclePurchaseResponseBody[] | Nullish,
      VehiclePurchaseResponseBody
    >(null, '0', activeBusinessCase.vehiclesPurchase);
  }

  if (isNotNilOrEmpty(activeBusinessCase.vehiclesPurchaseBrokerage)) {
    return propOr<
      VehiclePurchaseBrokerageResponseBody | Nullish,
      VehiclePurchaseBrokerageResponseBody[] | Nullish,
      VehiclePurchaseBrokerageResponseBody
    >(null, '0', activeBusinessCase.vehiclesPurchaseBrokerage);
  }

  return null;
};

export const getVehicleTitle = (
  vehicle:
    | VehicleSaleResponseBody
    | VehiclePurchaseResponseBody
    | VehiclePurchaseBrokerageResponseBody
    | null
): string | undefined => vehicle?.title;
