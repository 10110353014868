import {
  DeleteServiceOrderIssueNoteBasketRequest,
  DeleteServiceOrderIssueNoteBasketRequestSchema,
  DeleteServiceOrderIssueNoteBasketResponse,
  DeleteServiceOrderIssueNoteBasketResponseSchema,
  GetServiceOrderIssueNoteBasketRequest,
  GetServiceOrderIssueNoteBasketRequestSchema,
  GetServiceOrderIssueNoteBasketResponse,
  GetServiceOrderIssueNoteBasketResponseSchema,
  PatchServiceOrderIssueNoteBasketCheckoutRequest,
  PatchServiceOrderIssueNoteBasketCheckoutRequestSchema,
  PatchServiceOrderIssueNoteBasketCheckoutResponse,
  PatchServiceOrderIssueNoteBasketCheckoutResponseSchema,
  PatchServiceOrderIssueNoteBasketItemQuantityRequest,
  PatchServiceOrderIssueNoteBasketItemQuantityRequestSchema,
  PatchServiceOrderIssueNoteBasketItemQuantityResponse,
  PatchServiceOrderIssueNoteBasketItemQuantityResponseSchema,
} from '../types/metadaWarehouseServiceOrderIssueNoteBasket';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseServiceOrderIssueNoteBasketApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceOrderIssueNoteBasket: build.query<
      GetServiceOrderIssueNoteBasketResponse,
      GetServiceOrderIssueNoteBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}/spare-parts-cart`,
        method: 'GET',
        queryArg,
      }),
      providesTags: ['serviceOrderIssueNoteBasket'],
      extraOptions: {
        requestSchema: GetServiceOrderIssueNoteBasketRequestSchema,
        responseSchema: GetServiceOrderIssueNoteBasketResponseSchema,
      },
    }),
    patchServiceOrderIssueNoteBasketItemQuantity: build.mutation<
      PatchServiceOrderIssueNoteBasketItemQuantityResponse,
      PatchServiceOrderIssueNoteBasketItemQuantityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}/item/${queryArg.itemId}/quantity`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['serviceOrderIssueNoteBasket'],
      extraOptions: {
        requestSchema: PatchServiceOrderIssueNoteBasketItemQuantityRequestSchema,
        responseSchema: PatchServiceOrderIssueNoteBasketItemQuantityResponseSchema,
      },
    }),
    patchServiceOrderIssueNoteBasketCheckout: build.mutation<
      PatchServiceOrderIssueNoteBasketCheckoutResponse,
      PatchServiceOrderIssueNoteBasketCheckoutRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}/spare-parts-cart/checkout`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['serviceOrderIssueNoteBasket', 'serviceOrderIssueNotes'],
      extraOptions: {
        requestSchema: PatchServiceOrderIssueNoteBasketCheckoutRequestSchema,
        responseSchema: PatchServiceOrderIssueNoteBasketCheckoutResponseSchema,
      },
    }),
    deleteServiceOrderIssueNoteBasket: build.mutation<
      DeleteServiceOrderIssueNoteBasketResponse,
      DeleteServiceOrderIssueNoteBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}/spare-parts-cart`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'serviceOrderIssueNoteBasket',
        {type: 'serviceOrderIssueNotes', id: queryArg.serviceOrderIssueNoteId},
      ],
      extraOptions: {
        requestSchema: DeleteServiceOrderIssueNoteBasketRequestSchema,
        responseSchema: DeleteServiceOrderIssueNoteBasketResponseSchema,
      },
    }),
  }),
});

export const {
  useGetServiceOrderIssueNoteBasketQuery,
  usePatchServiceOrderIssueNoteBasketItemQuantityMutation,
  usePatchServiceOrderIssueNoteBasketCheckoutMutation,
  useDeleteServiceOrderIssueNoteBasketMutation,
} = metadaWarehouseServiceOrderIssueNoteBasketApi;
