import {ButtonProps, FormControl, TabsHeader} from 'platform/components';
import {Box, Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {SubmitHandler, UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {buildArray, RequiredTestIdProps, suffixTestId} from 'shared';

import {BasicInformation} from '../(sections)/BasicInformation/BasicInformation';
import {Features} from '../(sections)/Features/Features';
import {ServiceIntervals} from '../(sections)/ServiceIntervals/ServiceIntervals';
import {VehicleData} from '../(sections)/VehicleData/VehicleData';
import {FullScreenModal} from '../../FullScreenModal/FullScreenModal';
import {useTabs} from '../hooks/useTabs';
import {ServiceVehicleFormMode} from '../types/ServiceVehicleFormMode';
import {ServiceVehicleFormTabs} from '../types/ServiceVehicleFormTabs';
import {ServiceVehicleFormType} from '../types/ServiceVehicleFormType';
import {ServiceVehicleFormVariant} from '../types/ServiceVehicleFormVariant';

interface ExtendedViewProps extends RequiredTestIdProps {
  mode: ServiceVehicleFormMode;
  variant: ServiceVehicleFormVariant;
  isVehicleCreating: boolean;
  isVehicleUpdating: boolean;
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
  onVehicleCreation: SubmitHandler<ServiceVehicleFormType>;
  onVehicleUpdate: SubmitHandler<ServiceVehicleFormType>;
  onDiscard: () => void;
}

export function ExtendedView(props: ExtendedViewProps) {
  const {selectedTab, isPending, setSelectedTab} = useTabs();

  return (
    <FullScreenModal
      headline={match(props.mode)
        .with(ServiceVehicleFormMode.NEW, () =>
          i18n.t('entity.vehicle.labels.createServiceVehicle')
        )
        .with(ServiceVehicleFormMode.EDIT, () => i18n.t('entity.vehicle.labels.editServiceVehicle'))
        .with(ServiceVehicleFormMode.CLONE, () =>
          i18n.t('entity.vehicle.labels.cloneServiceVehicle')
        )
        .exhaustive()}
      actions={buildArray<ButtonProps>()
        .add({
          variant: 'secondary',
          title: i18n.t('general.actions.discard'),
          onClick: props.onDiscard,
          'data-testid': suffixTestId('main-discard', props),
        })
        .when(props.mode === ServiceVehicleFormMode.NEW, {
          variant: 'primary',
          title: i18n.t('general.actions.create'),
          type: 'submit',
          isLoading: props.isVehicleCreating,
          onClick: props.formApi.handleSubmit(props.onVehicleCreation),
          'data-testid': suffixTestId('main-create', props),
        })
        .when(props.mode === ServiceVehicleFormMode.EDIT, {
          variant: 'primary',
          title: i18n.t('general.actions.save'),
          type: 'submit',
          isLoading: props.isVehicleUpdating,
          onClick: props.formApi.handleSubmit(props.onVehicleUpdate),
          'data-testid': suffixTestId('main-save', props),
        })
        .when(props.mode === ServiceVehicleFormMode.CLONE, {
          variant: 'primary',
          title: i18n.t('entity.vehicle.labels.clone'),
          type: 'submit',
          isLoading: props.isVehicleCreating,
          onClick: props.formApi.handleSubmit(props.onVehicleCreation),
          'data-testid': suffixTestId('main-clone', props),
        })}
    >
      <Box
        backgroundColor="palettes.white.10.100"
        borderColor="palettes.neutral.40.100"
        borderBottom="1px solid"
        paddingHorizontal={4}
      >
        <TabsHeader
          activeTabId={selectedTab}
          onChange={(newTab: string) => setSelectedTab(newTab as ServiceVehicleFormTabs)}
          tabs={[
            {
              id: ServiceVehicleFormTabs.BasicInformation,
              title: i18n.t('general.labels.basicInformation'),
              'data-testid': suffixTestId('basicInformation', props),
            },
            {
              id: ServiceVehicleFormTabs.VehicleData,
              title: i18n.t('entity.vehicle.labels.vehicleData'),
              'data-testid': suffixTestId('vehicleData', props),
            },
            {
              id: ServiceVehicleFormTabs.Features,
              title: i18n.t('entity.vehicle.labels.features'),
              'data-testid': suffixTestId('features', props),
            },
            {
              id: ServiceVehicleFormTabs.ServiceIntervals,
              title: i18n.t('entity.vehicle.labels.serviceIntervals'),
              'data-testid': suffixTestId('serviceIntervals', props),
            },
          ]}
        />
      </Box>
      <Show when={selectedTab === ServiceVehicleFormTabs.BasicInformation}>
        <BasicInformation
          control={props.control}
          formApi={props.formApi}
          variant={props.variant}
          data-testid={suffixTestId('basicInformationPage', props)}
          isLoading={isPending}
        />
      </Show>
      <Show when={selectedTab === ServiceVehicleFormTabs.VehicleData}>
        <VehicleData
          control={props.control}
          formApi={props.formApi}
          data-testid={suffixTestId('vehicleDataPage', props)}
          isLoading={isPending}
        />
      </Show>
      <Show when={selectedTab === ServiceVehicleFormTabs.Features}>
        <Features
          formApi={props.formApi}
          data-testid={suffixTestId('featuresPage', props)}
          isLoading={isPending}
        />
      </Show>
      <Show when={selectedTab === ServiceVehicleFormTabs.ServiceIntervals}>
        <ServiceIntervals
          control={props.control}
          formApi={props.formApi}
          data-testid={suffixTestId('serviceIntervalsPage', props)}
          isLoading={isPending}
        />
      </Show>
    </FullScreenModal>
  );
}
