import {
  PostMarketingCodeRequest,
  PostMarketingCodeRequestSchema,
  PostMarketingCodeResponse,
  PostMarketingCodeResponseSchema,
  GetMarketingCodeRequest,
  GetMarketingCodeRequestSchema,
  GetMarketingCodeResponse,
  GetMarketingCodeResponseSchema,
  GetMarketingCodesRequest,
  GetMarketingCodesRequestSchema,
  GetMarketingCodesResponse,
  GetMarketingCodesResponseSchema,
  PatchMarketingCodeRequest,
  PatchMarketingCodeRequestSchema,
  PatchMarketingCodeResponse,
  PatchMarketingCodeResponseSchema,
} from '../types/metadaWarehouseMarketingCode';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseMarketingCodeApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getMarketingCodes: build.query<GetMarketingCodesResponse, GetMarketingCodesRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/marketing-codes`,
        method: 'GET',
        queryArg,
        invalidatesTags: ['marketingCodes'],
      }),
      extraOptions: {
        requestSchema: GetMarketingCodesRequestSchema,
        responseSchema: GetMarketingCodesResponseSchema,
      },
    }),
    getMarketingCode: build.query<GetMarketingCodeResponse, GetMarketingCodeRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/marketing-code/${queryArg.marketingCodeId}`,
        method: 'GET',
        queryArg,
        invalidatesTags: ['marketingCode'],
      }),
      extraOptions: {
        requestSchema: GetMarketingCodeRequestSchema,
        responseSchema: GetMarketingCodeResponseSchema,
      },
    }),
    postMarketingCode: build.mutation<PostMarketingCodeResponse, PostMarketingCodeRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/marketing-code`,
        method: 'POST',
        body: queryArg,
        queryArg,
        invalidatesTags: ['marketingCodes'],
      }),
      extraOptions: {
        requestSchema: PostMarketingCodeRequestSchema,
        responseSchema: PostMarketingCodeResponseSchema,
      },
    }),
    patchMarketingCode: build.mutation<PatchMarketingCodeResponse, PatchMarketingCodeRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/marketing-code/${queryArg.marketingCodeId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
        invalidatesTags: ['marketingCodes'],
      }),
      extraOptions: {
        requestSchema: PatchMarketingCodeRequestSchema,
        responseSchema: PatchMarketingCodeResponseSchema,
      },
    }),
  }),
});

export const {
  useGetMarketingCodesQuery,
  useGetMarketingCodeQuery,
  usePostMarketingCodeMutation,
  usePatchMarketingCodeMutation,
} = metadaWarehouseMarketingCodeApi;
