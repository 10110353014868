import {isNotNil} from 'ramda';

import {BasicStructure} from '@omnetic-dms/teas';

export const getStructureVersion = (
  isVehicleCondition: boolean,
  structure: BasicStructure,
  newestStructureSettings: BasicStructure | null
) => {
  const versionToUse =
    isNotNil(newestStructureSettings) &&
    isNotNil(newestStructureSettings?.version) &&
    newestStructureSettings?.version > structure?.version
      ? newestStructureSettings?.version
      : structure?.version;

  return isVehicleCondition ? versionToUse : structure?.version;
};
