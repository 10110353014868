import {equals} from 'ramda';

import {PromoPhotosFormType} from '../types/PromoPhotosFormType';
import {PromoPhotosType} from '../types/PromoPhotosType';

export const dataChanged = (editData: PromoPhotosType, data: PromoPhotosFormType) => {
  const defaultValues: PromoPhotosFormType = {
    title: editData.title,
    position: editData.position,
    fileId: {
      id: editData.fileId,
      url: editData.url,
    },
  };

  return equals(defaultValues, data);
};
