import {
  HTMLMotionProps,
  Target,
  TargetAndTransition,
  Transition,
  Variants as _Variants,
} from 'framer-motion';

import {isNumber} from 'ramda-adjunct';

export type TransitionEndConfig = WithMotionState<Target>;

export type TransitionConfig = WithMotionState<Transition>;

type TargetResolver<P = NonNullable<unknown>> = (
  props: P & {
    transition?: TransitionConfig;
    transitionEnd?: TransitionEndConfig;
    delay?: number | DelayConfig;
  }
) => TargetAndTransition;

type Variant<P = NonNullable<unknown>> = TargetAndTransition | TargetResolver<P>;

type WithMotionState<P> = Partial<Record<'enter' | 'exit', P>>;

export type DelayConfig = WithMotionState<number>;
export const withDelay = {
  enter: (transition: Transition, delay?: number | DelayConfig) => ({
    ...transition,
    delay: isNumber(delay) ? delay : delay?.enter,
  }),
  exit: (transition: Transition, delay?: number | DelayConfig) => ({
    ...transition,
    delay: isNumber(delay) ? delay : delay?.exit,
  }),
};

export type Variants<P = NonNullable<unknown>> = {
  enter: Variant<P>;
  exit: Variant<P>;
  initial?: Variant<P>;
};

export const TransitionEasings = {
  ease: [0.25, 0.1, 0.25, 1],
  easeIn: [0.4, 0, 1, 1],
  easeOut: [0, 0, 0.2, 1],
  easeInOut: [0.4, 0, 0.2, 1],
} as const;

export const TransitionDefaults = {
  enter: {
    duration: 0.2,
    ease: TransitionEasings.easeOut,
  },
  exit: {
    duration: 0.1,
    ease: TransitionEasings.easeIn,
  },
} as const;

interface SlideFadeOptions {
  /**
   * The offset on the horizontal or `x` axis
   * @default 0
   */
  offsetX?: string | number;
  /**
   * The offset on the vertical or `y` axis
   * @default 8
   */
  offsetY?: string | number;
  /**
   * If `true`, the element will be transitioned back to the offset when it leaves.
   * Otherwise, it'll only fade out
   * @default true
   */
  reverse?: boolean;
}

const variants: Variants<SlideFadeOptions> = {
  initial: ({offsetX, offsetY, transition, transitionEnd, delay}) => ({
    opacity: 0,
    x: offsetX,
    y: offsetY,
    transition: transition?.exit ?? withDelay.exit(TransitionDefaults.exit, delay),
    transitionEnd: transitionEnd?.exit,
  }),
  enter: ({transition, transitionEnd, delay}) => ({
    opacity: 1,
    x: 0,
    y: 0,
    transition: transition?.enter ?? withDelay.enter(TransitionDefaults.enter, delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({offsetY, offsetX, transition, transitionEnd, reverse, delay}) => {
    const offset = {x: offsetX, y: offsetY};
    return {
      opacity: 0,
      transition: transition?.exit ?? withDelay.exit(TransitionDefaults.exit, delay),
      ...(reverse
        ? {...offset, transitionEnd: transitionEnd?.exit}
        : {transitionEnd: {...offset, ...transitionEnd?.exit}}),
    };
  },
};

export const scaleFadeConfig: HTMLMotionProps<'div'> = {
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
  variants: variants as _Variants,
};

export const slideFadeConfig: HTMLMotionProps<'div'> = {
  initial: 'initial',
  animate: 'enter',
  exit: 'exit',
  variants: variants as _Variants,
};

export const dialogTransitions = {
  slideInBottom: {
    ...slideFadeConfig,
    custom: {offsetY: 16, reverse: true},
  },
  slideInRight: {
    ...slideFadeConfig,
    custom: {offsetX: 16, reverse: true},
  },
  scale: {
    ...scaleFadeConfig,
    custom: {initialScale: 0.95, reverse: true},
  },
  none: {},
};
