import {css} from 'styled-components';

import {FC, Fragment, ReactElement, ReactNode} from 'react';

import {isNotNil, isNotNilOrEmpty, isNumber, noop} from 'ramda-adjunct';

import {TestIdProps, suffixTestId} from 'shared';

import {SimpleRow, SimpleRowProps} from './SimpleRow';
import {SectionDivider, SectionName, Table, Tbody, Td, TdPadding, Tfoot, Thead, Tr} from './styles';
import {ColumnType, DataType, ExpandType, RowPropsType, SimpleTableRowType} from './types';

export type SimpleTableProps<TDataType extends DataType = DataType> = {
  rows: SimpleTableRowType<TDataType>[];
  columns: ColumnType<TDataType>[];
  rowProps?: (row: TDataType, index: number) => RowPropsType;
  className?: string;
  footerRows?: any[];
  disableDivider?: boolean;
  footerRenderer?: (footer: {
    colSpan: number;
    Tr: ReactNode | JSX.ElementType;
    Td: ReactNode | JSX.ElementType;
  }) => ReactElement;
  onRowExpanded?: SimpleRowProps<TDataType>['onRowExpanded'];
  onRowClick?: (row: TDataType) => void;
  ExpandContent?: FC<ExpandType<TDataType>>;
  noZebra?: boolean;
  tdPadding?: TdPadding;
  showCellDivider?: boolean;
  highlightRowOnHover?: boolean;
  getRowId?: (row: TDataType) => string | number | undefined;
  showRowDivider?: boolean;
} & TestIdProps;

export const SimpleTable = <TDataType extends DataType = DataType>({
  rows,
  columns,
  rowProps,
  className,
  footerRows,
  disableDivider,
  footerRenderer,
  onRowExpanded,
  onRowClick = noop,
  ExpandContent,
  showCellDivider,
  noZebra,
  getRowId,
  showRowDivider,
  tdPadding = 'middle',
  highlightRowOnHover = false,
  ...props
}: SimpleTableProps<TDataType>): ReactElement => {
  const colSpan = columns.length;

  return (
    <Table
      cellSpacing="0"
      tdPadding={tdPadding}
      className={className}
      hover={highlightRowOnHover}
      data-testid={suffixTestId('table', props)}
    >
      <Thead data-testid={suffixTestId('thead', props)} showCellDivider={showCellDivider}>
        <Tr data-testid={suffixTestId('thead-tr', props)}>
          {columns.map(({id, Header, width, align, className}) => (
            <th
              data-testid={suffixTestId(`thead-${id}`, props)}
              className={classNames(['simple-table-th', className])}
              key={id}
              css={css`
                ${isNotNil(width) &&
                `width: ${isNumber(width) ? `${width}px` : width};
                `}
                ${`text-align: ${align ?? 'left'};`}
              `}
            >
              {typeof Header === 'function' ? <Header /> : Header}
            </th>
          ))}
        </Tr>
      </Thead>

      <Tbody data-testid={suffixTestId('tbody', props)}>
        {rows.map(({sectionName = null, data}, index) => (
          <Fragment key={index}>
            {index > 0 && !disableDivider ? (
              <Tr data-testid={suffixTestId('section-divider-tr', props)}>
                <Td
                  data-testid={suffixTestId('section-divider-td', props)}
                  css={css`
                    padding: 0;
                  `}
                  colSpan={colSpan}
                >
                  <SectionDivider data-testid={suffixTestId('section-divider', props)} />
                </Td>
              </Tr>
            ) : null}

            {isNotNilOrEmpty(sectionName) ? (
              <Tr data-testid={suffixTestId(`section-${sectionName}-tr`, props)}>
                <Td
                  data-testid={suffixTestId(`section-${sectionName}-td`, props)}
                  css={css`
                    padding: 0 16px;
                  `}
                  colSpan={colSpan}
                >
                  <SectionName data-testid={suffixTestId(`section-${sectionName}`, props)}>
                    {sectionName}
                  </SectionName>
                </Td>
              </Tr>
            ) : null}

            {data.map((row, rowIndex) => {
              const {className: rowClassName} = rowProps?.(row, rowIndex) || {};

              return (
                <SimpleRow<TDataType>
                  columns={columns}
                  index={rowIndex}
                  row={row}
                  key={getRowId ? (getRowId(row) ?? rowIndex) : rowIndex}
                  className={classNames(['row', rowClassName])}
                  onRowClick={() => onRowClick(row)}
                  onRowExpanded={onRowExpanded}
                  ExpandContent={ExpandContent}
                  showCellDivider={showCellDivider}
                  noZebra={noZebra}
                  showRowDivider={showRowDivider}
                  data-testid={suffixTestId(`row[${rowIndex}]`, props)}
                />
              );
            })}
          </Fragment>
        ))}
      </Tbody>

      {footerRows?.length || footerRenderer ? (
        <Tfoot data-testid={suffixTestId('tfoot', props)}>
          <Tr data-testid={suffixTestId('tfoot-tr', props)}>
            <Td
              colSpan={colSpan}
              css={css`
                padding: 8px 0;
              `}
              data-testid={suffixTestId('tfoot-td', props)}
            >
              <SectionDivider data-testid={suffixTestId('tfoot-section-divider', props)} />
            </Td>
          </Tr>

          {typeof footerRenderer === 'function' ? footerRenderer({colSpan, Tr, Td}) : null}

          {footerRows?.length
            ? footerRows.map((row, index) => (
                <Tr key={index} data-testid={suffixTestId(`tfoot-row[${index}]`, props)}>
                  {columns.map((column) => (
                    <Td
                      key={column.id}
                      data-testid={suffixTestId(`tfoot-row[${index}]-${column.id}`, props)}
                    >
                      {row[column.id]}
                    </Td>
                  ))}
                </Tr>
              ))
            : null}
        </Tfoot>
      ) : null}
    </Table>
  );
};

const classNames = (arr: Array<string | undefined>) => arr.filter(Boolean).join(' ');
