import {VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useGetCurrentUserInfoQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

export function ListComponent() {
  const {data: user} = useGetCurrentUserInfoQuery();

  return (
    <VStack spacing={4}>
      <ListWrapper data-testid={testIds.myProfile.personalInformation('roles')}>
        <p>{i18n.t('page.settings.labels.assignedRole')}:</p>
        {user?.roles?.length ? user?.roles?.map((role) => role.title).toString() : '-'}
      </ListWrapper>

      <ListWrapper data-testid={testIds.myProfile.personalInformation('branches')}>
        <p>{i18n.t('page.settings.labels.assignedBranches')}:</p>
        {user?.branches?.length ? user?.branches?.map((branch) => branch.name).toString() : '-'}
      </ListWrapper>
    </VStack>
  );
}

const ListWrapper = styled.div`
  span {
    font-size: 15px;
    color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
    letter-spacing: -0.3px;
  }

  p {
    margin: 0;
    font-size: ${({theme}) => theme.fontSizes.text.xSmall};
    color: ${({theme}) => theme.colors.palettes.neutral[300][100]};
  }
`;
