import {Nullish} from 'shared';

import type {CancelablePromise} from '../types/CancelablePromise';
import type {RedirectUrlToAdServer} from '../types/RedirectUrlToAdServer';
import {request as __request} from '../utils/request';

export class RedirectService {
  /**
   * @returns RedirectUrlToAdServer
   * @throws ApiError
   */
  public static redirectUrlToAdServer({
    externalId,
    serverName,
    buyerCountry,
    authorization,
    sellerCountry,
  }: {
    externalId?: string;
    serverName?: string;
    buyerCountry?: string;
    authorization?: string;
    sellerCountry: string | Nullish;
  }): CancelablePromise<RedirectUrlToAdServer> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sourcing/change-url/sales-server-url',
      headers: {
        Authorization: authorization,
      },
      query: {
        externalId,
        serverName,
        buyerCountry,
        sellerCountry: sellerCountry ?? 'CZE',
      },
    });
  }
}
