import type {CancelablePromise} from '../types/CancelablePromise';
import type {CarFeatures} from '../types/CarFeatures';
import type {CarFeaturesRequestBody} from '../types/CarFeaturesRequestBody';
import {request as __request} from '../utils/request';

export class CarFeaturesService {
  /**
   * @returns CarFeatures
   * @throws ApiError
   */
  public static getCarFeatures({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: CarFeaturesRequestBody;
  }): CancelablePromise<CarFeatures> {
    return __request({
      method: 'POST',
      url: '/dms/v1/car-features',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
