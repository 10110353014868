import {addDays} from 'date-fns';
import {Icon, Hide, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {FC, ReactNode} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {LinkButton} from '@omnetic-dms/teas';

import {parseDate} from 'shared';

import {DocumentRoundingDefinitionFormType} from './types';
import {emptyValidityItem} from './utils';
import {ValidityItem} from './ValidityItem';

const BaseGreyBox = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
  padding: ${({theme}) => theme.getSize(3)};
  border-radius: ${({theme}) => theme.radii.small};
`;

const GreyBox: FC<{children: ReactNode}> = ({children}) => <BaseGreyBox>{children}</BaseGreyBox>;

export const StyledGreyBox = styled(GreyBox)`
  padding: ${({theme}) => theme.getSize(4)};
  border-radius: ${({theme}) => theme.radii.medium};
`;

export const ValiditySection: FC = () => {
  const {getValues, watch} = useFormContext<DocumentRoundingDefinitionFormType>();
  const {fields, append, remove} = useFieldArray({
    name: 'validities',
    keyName: 'idKey',
  });

  const isSystem = watch('isSystem');

  const handleCreateNewItem = () => {
    const {validities} = getValues();
    const lastItem = validities[validities?.length - 1]?.validFrom;
    const newDate = lastItem && addDays(parseDate(lastItem), 1).toISOString();

    append(emptyValidityItem(newDate));
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  return (
    <VStack spacing={4}>
      <VStack spacing={2}>
        {fields.map(({idKey, ...validity}, i) => (
          <StyledGreyBox key={`${idKey}_${i}`}>
            <ValidityItem
              allowRemove={isSystem ? false : fields.length > 1}
              item={validity}
              index={i}
              onRemove={() => handleRemoveItem(i)}
            />
          </StyledGreyBox>
        ))}
      </VStack>
      <Hide when={isSystem}>
        <LinkButton
          onClick={handleCreateNewItem}
          Icon={<Icon value="content/add_circle" />}
          data-testid={testIds.settings.documentRoundingDefinitions('addValidity')}
        >
          {i18n.t('entity.accounting.actions.addValidity')}
        </LinkButton>
      </Hide>
    </VStack>
  );
};
