import {DefinitionResponseBody, TransformedDefinitionApiResponse} from '../types/Api';

export const transformDefinitionBehavior = (
  definition: TransformedDefinitionApiResponse,
  definitionBehaviorOverrides?: Partial<DefinitionResponseBody['behavior']>
): TransformedDefinitionApiResponse => ({
  ...definition,
  behavior: {
    ...definition.behavior,
    ...definitionBehaviorOverrides,
  },
});
