import {AppLanguage, formatDateTime, LocaleConfig} from 'platform/locale';

import {ReactNode} from 'react';

import i18n from '@omnetic-dms/i18n';
import {getNaturalPersonFullName} from '@omnetic-dms/shared';

import {parseDate} from 'shared';

import {MenuAction} from './components/Actions';
import {TemplateFileName} from './components/TemplateFileName';
import {TemplateText} from './components/TemplateText';
import {Alternative, Primary} from './styles';
import {TemplateRow, TExtraTemplateFields} from './types';

const titleWidth = 150;
const noteWidth = 240;
const getTemplateDate = (
  date: string,
  locale: LocaleConfig,
  language: AppLanguage
): ReactNode | null => {
  if (!date) {
    return null;
  }

  return <>{formatDateTime(locale, language, 'dateTimeShort', parseDate(date))}</>;
};

const getDefaultProperties = (
  {
    code,
    templateTitle,
    defaultTitle,
    templateId,
    system,
    primary,
    hidden,
    url,
    customTemplates,
    updatedAt,
    updatedBy,
    note,
  }: TExtraTemplateFields,
  locale: LocaleConfig,
  language: AppLanguage
): TemplateRow => ({
  templateTitle: (
    <TemplateText width={titleWidth} text={templateTitle || i18n.t('general.labels.default')} />
  ),
  templateFile: defaultTitle, // TBD: this is confusing, file = title, need to change
  state: getTemplateState(primary as boolean),
  lastUpdate: updatedAt ? getTemplateDate(updatedAt, locale, language) : '-',
  addedBy: (updatedBy && getNaturalPersonFullName(updatedBy)) ?? '-',
  note: <TemplateText width={noteWidth} text={note ?? ''} />,
  actions: (
    <MenuAction
      downloadUrl={url}
      customTemplateId={templateId}
      templateCode={code}
      isPrimary={!!primary}
      isSystem={!!system}
      isHideable={!!system && !!customTemplates.length}
      isHidden={hidden}
    />
  ),
});

export const getCustomTemplateData = (
  row: TExtraTemplateFields,
  locale: LocaleConfig,
  language: AppLanguage
): TemplateRow[] => {
  const customTemplates = row.customTemplates;
  const data = [getDefaultProperties(row, locale, language)];

  customTemplates?.forEach(
    ({id, note, primary, system, hidden, fileName, url, title, updatedAt, updatedBy}) =>
      data[primary ? 'unshift' : 'push']({
        templateTitle: <TemplateText width={titleWidth} text={title} />,
        templateFile: <TemplateFileName title={fileName} isWarn={false} />,
        state: getTemplateState(primary),
        lastUpdate: updatedAt ? getTemplateDate(updatedAt, locale, language) : '-',
        addedBy: (updatedBy && getNaturalPersonFullName(updatedBy)) ?? '-',
        warning: false,
        note: <TemplateText width={noteWidth} text={note ?? ''} />,
        actions: (
          <MenuAction
            downloadUrl={url}
            fileName={fileName}
            customTemplateId={id}
            templateCode={row.code}
            isPrimary={primary}
            isSystem={system}
            isHideable={system && !!customTemplates.length}
            isHidden={hidden}
          />
        ),
      })
  );
  return data;
};

const getTemplateState = (isPrimary: boolean): ReactNode =>
  isPrimary ? (
    <Primary>{i18n.t('entity.document.labels.primary')}</Primary>
  ) : (
    <Alternative>{i18n.t('entity.document.labels.alternative')}</Alternative>
  );
