import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';

export type onProgressType = (percentage: number, status: 'UPLOADING' | 'UPLOAD_COMPLETED') => void;

/**
 * Parameters for uploading a file with progress.
 */
type UploadFileWithProgressParams = {
  /** The file to be uploaded. */
  file: File;
  /** The URL where the file will be uploaded. */
  url: string;
  /**
   * Callback function to track upload progress.
   * @param percentage The percentage of the file uploaded.
   * @param status The status of the upload process.
   */
  onProgress?: onProgressType;
  /**
   * In our projects, we have an Axios instance set up globally with predefined headers.
   * For uploading to S3, it's necessary that the headers do not include authorization or custom x headers.
   */
  shouldOmitSharedHeaders?: boolean;
};

/**
 * Uploads a file with progress tracking.
 * @template T The type of data expected in the response.
 * @template R The type of the AxiosResponse.
 * @param {UploadFileWithProgressParams} params Parameters for file upload.
 * @returns {Promise<R>} A promise that resolves with the Axios response.
 */
export async function uploadFileWithProgress<T = any, R = AxiosResponse<T>>(
  params: UploadFileWithProgressParams
): Promise<R> {
  const config: AxiosRequestConfig = {
    headers: {'Content-Type': params.file.type},
    onUploadProgress: (progressEvent_1) => {
      if (progressEvent_1.total) {
        const percentage = Math.round((progressEvent_1.loaded / progressEvent_1.total) * 100);
        params.onProgress?.(percentage, 'UPLOADING');
      }
    },
  };

  let response: Awaited<R>;

  if (params.shouldOmitSharedHeaders) {
    const instance = axios.create();
    response = await instance.put<T, R>(params.url, params.file, config);
  } else {
    response = await axios.put<T, R>(params.url, params.file, config);
  }

  params.onProgress?.(100, 'UPLOAD_COMPLETED');
  return response;
}
