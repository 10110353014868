import {
  Button,
  ButtonGroup,
  Card,
  Choice,
  DataStatus,
  Label,
  RadioItem,
  showNotification,
} from 'platform/components';
import {Clickable, Heading, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {filter, includes, not} from 'ramda';
import {isString} from 'ramda-adjunct';

import {
  getOptionsFromServiceCaseOrderVariants,
  useGetServiceCaseOrdersQuery,
  useGetServiceCaseOrderVariantsQuery,
  usePostServiceOrderMutation,
  usePostServiceRequestCopyToServiceOrderMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {LiteralUnionAutocomplete, RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestOrder} from '../../../components/RequestOrder';

interface CopyRequestProps extends RequiredTestIdProps {
  serviceCaseId: string;
  items: {
    serviceJobId: string;
    serviceOrderId: string;
  }[];
  onClose: () => void;
}

export function CopyRequest(props: CopyRequestProps) {
  const [selectedOrder, setSelectedOrder] = useState<LiteralUnionAutocomplete<'NEW'> | null>(null);
  const [selectedSC, setSelectedSC] = useState<string | null>(null);
  const [variant, setVariant] = useState<number | string[] | string | null>(null);

  const {
    data: serviceCaseOrders,
    isLoading,
    isError,
  } = useGetServiceCaseOrdersQuery({
    serviceCaseId: props.serviceCaseId,
  });
  const {data: orderVariants} = useGetServiceCaseOrderVariantsQuery({
    serviceCaseId: props.serviceCaseId,
  });
  const [copyJobsToOtherOrder, {isLoading: isCopyJobsToOtherOrderLoading}] =
    usePostServiceRequestCopyToServiceOrderMutation();
  const [postServiceOrder, {isLoading: isPostServiceOrderLoading}] = usePostServiceOrderMutation();

  const handleCopyRequest = () => {
    if (!selectedOrder) {
      return;
    }

    if (selectedOrder === 'NEW') {
      if (!isString(variant)) {
        return;
      }

      postServiceOrder({serviceCaseId: props.serviceCaseId, body: {orderVariantId: variant}})
        .unwrap()
        .then((order) => {
          copyJobsToOtherOrder({
            serviceCaseId: order?.serviceCaseId ?? '',
            serviceOrderId: order?.id ?? '',
            body: props.items,
          })
            .unwrap()
            .then(showNotification.success)
            .then(props.onClose)
            .catch(handleApiError);
        })
        .catch(handleApiError);
      return;
    }
    copyJobsToOtherOrder({
      serviceCaseId: selectedSC ?? '',
      serviceOrderId: selectedOrder,
      body: props.items,
    })
      .unwrap()
      .then(showNotification.success)
      .then(props.onClose)
      .catch(handleApiError);
  };

  const serviceCaseOrdersToShow = filter(
    (order) =>
      not(props.items.some((item) => item.serviceOrderId === order?.id)) &&
      not(includes(order?.state, ['LOCKED', 'ARCHIVED', 'CLOSED', 'CANCELLED'])),
    serviceCaseOrders ?? []
  );

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={23}>
      <VStack spacing={4}>
        <Text color="tertiary" size="small">
          {i18n.t('entity.order.labels.copyRequestDescription')}
        </Text>
        {serviceCaseOrdersToShow?.map(
          (order, index) =>
            order && (
              <RequestOrder
                key={order?.id}
                order={order}
                isSelected={selectedOrder === order?.id}
                onSelect={() => {
                  setSelectedOrder(order?.id ?? null);
                  setSelectedSC(order?.serviceCaseId ?? null);
                }}
                data-testid={suffixTestId(`requestOrder-[${index}]`, props)}
              />
            )
        )}
        <Card variant="inlineWhite">
          <Clickable
            onClick={() => setSelectedOrder('NEW')}
            data-testid={suffixTestId('selectNew', props)}
          >
            <HStack spacing={3} align="center">
              <RadioItem
                value={selectedOrder === 'NEW'}
                data-testid={suffixTestId('selected', props)}
              />
              <Heading size={4}>{i18n.t('entity.order.labels.newServiceOrder')}</Heading>
            </HStack>
          </Clickable>
          <Show when={selectedOrder === 'NEW'}>
            <Space vertical={4} />
            <Label>{i18n.t('entity.order.labels.orderVariant')}</Label>
            <Choice
              value={variant}
              onChange={setVariant}
              options={getOptionsFromServiceCaseOrderVariants(orderVariants)}
              placeholder={i18n.t('general.labels.select')}
              menuInPortal
              data-testid={suffixTestId('variant', props)}
            />
          </Show>
        </Card>
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            onClick={props.onClose}
            data-testid={suffixTestId('cancel', props)}
            title={i18n.t('general.actions.cancel')}
          />
          <Button
            onClick={handleCopyRequest}
            isDisabled={!selectedOrder || (selectedOrder === 'NEW' && !Boolean(variant))}
            isLoading={isCopyJobsToOtherOrderLoading || isPostServiceOrderLoading}
            data-testid={suffixTestId('confirm', props)}
            title={i18n.t('general.actions.confirm')}
          />
        </ButtonGroup>
      </VStack>
    </DataStatus>
  );
}
