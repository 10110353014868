import {AttributesRow} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {defaultTo} from 'ramda';
import {isFalse, isTrue} from 'ramda-adjunct';

import {useGetVehicleV2Query} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {parseDate, Nullish} from 'shared';

export function useTechnicalInspectionParameters(vehicleId: string | Nullish): AttributesRow[] {
  const formatDateTime = useDateTimeFormatter();

  const {data: vehicle} = useGetVehicleV2Query(
    {vehicleId: defaultTo('', vehicleId)},
    {skip: !vehicleId}
  );

  return [
    {
      label: i18n.t('entity.vehicle.labels.inspectionValidUntil'),
      value:
        vehicle?.technicalInspectionValidUntil &&
        formatDateTime('dateShort', parseDate(vehicle?.technicalInspectionValidUntil)),

      testId: 'inspectionValidUntil',
    },
    {
      label: i18n.t('entity.vehicle.labels.acceptableTechnicalCondition'),
      value:
        (isTrue(vehicle?.isAcceptableForTechnicalInspection) && i18n.t('general.labels.yes')) ||
        (isFalse(vehicle?.isAcceptableForTechnicalInspection) && i18n.t('general.labels.no')) ||
        null,
      testId: 'acceptableTechnicalCondition',
    },
    {
      label: i18n.t('entity.vehicle.labels.technicalInspectionNote'),
      value: vehicle?.technicalInspectionNote,
      testId: 'technicalInspectionNote',
    },
  ];
}
