import {
  GetServiceCheckInApiResponse,
  GetServiceCheckInApiArg,
  PostServiceCheckInApiResponse,
  PostServiceCheckInApiArg,
  PatchServiceCheckInApiResponse,
  PatchServiceCheckInApiArg,
  GetServiceCheckInApiResponseSchema,
  GetServiceCheckInApiArgSchema,
  PostServiceCheckInApiResponseSchema,
  PostServiceCheckInApiArgSchema,
  PatchServiceCheckInApiResponseSchema,
  PatchServiceCheckInApiArgSchema,
  GetServiceCheckInDefaultDriverApiApiResponse,
  GetServiceCheckInDefaultDriverApiApiArg,
} from '../types/metadaWorkshopCheckin';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopCheckinApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceCheckIn: build.query<GetServiceCheckInApiResponse, GetServiceCheckInApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/check-in`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'checkIn', id: queryArg.serviceCaseId}],
      extraOptions: {
        responseSchema: GetServiceCheckInApiResponseSchema,
        requestSchema: GetServiceCheckInApiArgSchema,
      },
    }),
    postServiceCheckIn: build.mutation<PostServiceCheckInApiResponse, PostServiceCheckInApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/check-in`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'checkIn', id: queryArg.serviceCaseId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: 'ALL'},
      ],
      extraOptions: {
        responseSchema: PostServiceCheckInApiResponseSchema,
        requestSchema: PostServiceCheckInApiArgSchema,
      },
    }),
    patchServiceCheckIn: build.mutation<PatchServiceCheckInApiResponse, PatchServiceCheckInApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/check-in`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'checkIn', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: 'ALL'},
      ],
      extraOptions: {
        responseSchema: PatchServiceCheckInApiResponseSchema,
        requestSchema: PatchServiceCheckInApiArgSchema,
      },
    }),
    getServiceCheckInDefaultDriverApi: build.query<
      GetServiceCheckInDefaultDriverApiApiResponse,
      GetServiceCheckInDefaultDriverApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/check-in/default-driver`,
      }),
    }),
  }),
});

export const {
  useGetServiceCheckInQuery,
  useLazyGetServiceCheckInQuery,
  usePostServiceCheckInMutation,
  usePatchServiceCheckInMutation,
  useGetServiceCheckInDefaultDriverApiQuery,
} = metadaWorkshopCheckinApi;
