import {Action} from 'platform/components';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {buildArray} from 'shared';

export const getHeaderActions = (isDetail: boolean, editable: boolean, toggleDetail: () => void) =>
  buildArray<Action>().when(editable && isDetail, {
    type: 'button',
    variant: 'link',
    title: i18n.t('general.actions.edit'),
    leftIcon: 'image/edit',
    onClick: toggleDetail,
    'data-testid': testIds.businessCase.buying('pricing-edit'),
  });
