import {GridItem, Grid, Heading, Box} from 'platform/foundation';
import {css} from 'styled-components';

import {ReactElement} from 'react';

import i18n from '@omnetic-dms/i18n';

import {CreateVehicleRequestBody} from '../../../types/CreateVehicleRequestBody';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';

export const TechnicalInspection = (): ReactElement => {
  const {Field} = useFormRenderer<CreateVehicleRequestBody>();

  return (
    <Grid columns={1}>
      <Heading size={4}>{i18n.t('entity.vehicle.labels.technicalInspection')}</Heading>
      <div
        css={css`
          margin-bottom: -16px;
        `}
      >
        <Grid columns={4}>
          <Field
            as="date"
            name="state.technicalInspectionValidUntil"
            label={i18n.t('entity.vehicle.labels.inspectionValidUntil')}
          />
          <GridItem span={3}>
            <Box paddingTop={6}>
              <Field
                as="checkbox"
                name="state.isAcceptableForTechnicalInspection"
                label={i18n.t('entity.vehicle.labels.acceptableTechnicalCondition')}
              />
            </Box>
          </GridItem>
        </Grid>
        <Field
          name="state.technicalInspectionNote"
          multiline
          label={i18n.t('entity.vehicle.labels.technicalInspectionNote')}
        />
      </div>
    </Grid>
  );
};
