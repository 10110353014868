import {pipe, splitEvery, split, last, length, max, replace, includes} from 'ramda';

import {Nullish} from 'shared';

import {DECIMAL_DOT} from '../../../constants/numberConstants';
import {DEFAULT_STEP_VALUE} from '../constants/defaultStepValue';

/**
 * Gets the number of decimal places needed for precision based on the value and step.
 *
 * @param delimiter The decimal delimiter.
 * @param value The input value as a string.
 * @param step The step value as number.
 * @returns The number of decimal places.
 */
export const getDecimalPlaces = (
  delimiter: string,
  value: string | Nullish,
  step: number = DEFAULT_STEP_VALUE
) => {
  const stepAsString = String(step);
  const stepWithCorrectDelimiter = replace(DECIMAL_DOT, delimiter, stepAsString);

  const valueDecimalPlaces = getDecimalLength(delimiter, value);
  const stepDecimalPlaces = getDecimalLength(delimiter, stepWithCorrectDelimiter);

  // Ensure precision by using the greater number of decimal places between value and step.
  // This is important when the step value has decimal places but the value does not.
  return max(valueDecimalPlaces, stepDecimalPlaces);
};

const getDecimalLength = (delimiter: string, value: string | Nullish) =>
  value && includes(delimiter, value)
    ? pipe(split(delimiter), last, splitEvery(1), length)(value)
    : 0;
