import {Image, Box, Clickable} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {Path, UseFormReturn} from 'react-hook-form';

import {BackgroundRemovalFormValues} from '../types/BackgroundRemovalFormValues';

interface ImageOptionItemProps {
  formApi: UseFormReturn<BackgroundRemovalFormValues>;
  name: Path<BackgroundRemovalFormValues>;
  value: string;
  activeItem?: string | null;
  externalOnClick?: (value: string) => void;
}

export function ImageOptionItem(props: ImageOptionItemProps) {
  const theme = useTheme();

  return (
    <Box
      borderRadius="medium"
      overflow="hidden"
      border={`3px solid ${
        props.value === props.activeItem ? theme.colors.severity.informational : 'transparent'
      }`}
    >
      <Clickable
        onClick={() => {
          props.formApi.setValue(props.name, props.value);
          props.externalOnClick?.(props.value);
        }}
      >
        <Image
          src={`assets/images/background-replacement/${props.value}.jpg`}
          data-testid="background-removal-settings-image"
        />
      </Clickable>
    </Box>
  );
}
