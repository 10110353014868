import {Chips, Separator, TextInput, useTranslationContext} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {intersection} from 'ramda';
import {isEmptyArray, isString} from 'ramda-adjunct';

import {debounce, suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {IFilter, IFilterParams} from '../../types/AgGridTypes';
import {QuickFilters} from '../../types/Api';

export type TextFilterProps = {
  isDisabled: boolean;
} & IFilterParams &
  QuickFilters &
  TestIdProps;

function TextFilterComponent(
  {filterChangedCallback, column, isDisabled, quickFilters, ...props}: TextFilterProps,
  ref: ForwardedRef<IFilter>
) {
  const t = useTranslationContext();
  const [value, _setValue] = useState<string | null>(null);
  const isActive = useRef<boolean>(false);
  const setValue = useCallback((valueToSet: string | null) => {
    isActive.current = isString(valueToSet);
    _setValue(valueToSet);
  }, []);
  const {onChipsChange} = useFilterOnChipsChange({
    filterChangedCallback,
    setFilterValue: setValue,
    defaultValue: null,
  });

  const debouncedFilterChangedCallback = useMemo(
    () => debounce(filterChangedCallback, 700),
    [filterChangedCallback]
  );

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return isActive.current;
    },

    doesFilterPass() {
      return true;
    },

    getModel() {
      return value;
    },

    setModel(model: string | undefined | null) {
      if (isString(model)) {
        setValue(model);
      } else {
        setValue(null);
      }
      filterChangedCallback();
    },
  }));

  const onChange = (changeValue: string | null) => {
    setValue(changeValue);
    debouncedFilterChangedCallback();
  };

  const isQuickFilterValue = intersection([value], quickFiltersConst) as string[];

  return (
    <VStack>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('text-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <TextInput
        placeholder={t('page.datagrid.filter.textPlaceholder', {
          field: column.getColDef().headerName,
        })}
        value={isEmptyArray(isQuickFilterValue) ? value : null}
        onChange={onChange}
        isDisabled={isDisabled}
        data-testid={suffixTestId('text-filter', props)}
      />
    </VStack>
  );
}

export const TextFilter = forwardRef<IFilter, TextFilterProps>(TextFilterComponent);
