import {
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, HStack, Show, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  BaseAuthorizationProfile,
  BaseSupplier,
  CurrencyResponseBody,
  GetDeliveryNoteResponse,
  GetWarehousesResponse,
  PatchDeliveryNoteRequest,
  PaymentType,
  PaymentTypeResponseBody,
  useLazyGetWarehouseQuery,
  useLazyGetWarehousesQuery,
  usePatchDeliveryNoteMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  getOptionsFromAuthorizationProfiles,
  getOptionsFromCurrencies,
  getOptionsFromSuppliers,
  getOptionsFromWarehouses,
  handleApiError,
} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId, yupNumber, yupString} from 'shared';

import {getOptionsFromPaymentTypes} from '../../../../../utils/getOptionsFromPaymentTypes';

export interface BasicInformationFormProps extends RequiredTestIdProps {
  deliveryNote: GetDeliveryNoteResponse | Nullish;
  suppliers: BaseSupplier[] | Nullish;
  warehouses: GetWarehousesResponse | Nullish;
  authorizationProfiles: BaseAuthorizationProfile[] | Nullish;
  paymentTypes?: PaymentTypeResponseBody[] | Nullish;
  currencies?: CurrencyResponseBody[] | Nullish;
  onClose: () => void;
}

type DeliveryNoteForm = {
  warehouseId: string;
  supplierId: string | null;
  invoiceNumber: string;
  paymentType: PaymentType;
  currency: string;
  exchangeRate: number;
  ncConversion: string;
  authorizationProfileId: string;
};

export function BasicInformationForm(props: BasicInformationFormProps) {
  const [availableWarehousesToSupplier, setAvailableWarehousesToSupplier] = useState<
    GetWarehousesResponse | Nullish
  >(props.warehouses);

  const [patchDeliveryNote] = usePatchDeliveryNoteMutation();
  const [getWarehouse, {isFetching: isWarehouseFetching}] = useLazyGetWarehouseQuery();
  const [getWarehouses, {isFetching: areWarehousesFetching}] = useLazyGetWarehousesQuery();

  const isDeliveryNoteReceived = props.deliveryNote?.state === 'RECEIVED';
  const isDeliveryNotePending = props.deliveryNote?.state === 'PENDING';
  const isFromReceiveNote = props.deliveryNote?.isFromReceiveNote;

  const supplierId = props.deliveryNote?.supplierId;
  const authorizationProfileId = props.deliveryNote?.authorizationProfileId;

  useEffect(() => {
    if (isDeliveryNotePending && supplierId && authorizationProfileId) {
      getWarehouses({
        supplierId,
        authorizationProfileId,
      })
        .unwrap()
        .then(setAvailableWarehousesToSupplier)
        .catch(handleApiError);
    } else {
      return;
    }
  }, [isDeliveryNotePending, supplierId, authorizationProfileId, getWarehouses]);

  const defaultValues: Partial<DeliveryNoteForm> = {
    supplierId: props.deliveryNote?.supplierId,
    warehouseId: props.deliveryNote?.warehouseId,
    authorizationProfileId: props.deliveryNote?.authorizationProfileId,
    invoiceNumber: props.deliveryNote?.invoiceNumber,
    paymentType: props.deliveryNote?.paymentType,
    currency: props.deliveryNote?.currency,
    exchangeRate: props.deliveryNote?.exchangeRate,
    ncConversion: props.deliveryNote?.ncConversion,
  };

  const handleSubmit: FormSubmitHandler<DeliveryNoteForm> = async (formValues) => {
    if (isNil(props.deliveryNote)) {
      return;
    }

    const requestBody: PatchDeliveryNoteRequest['body'] = formValues;

    await patchDeliveryNote({deliveryNoteId: props.deliveryNote.deliveryNoteId, body: requestBody})
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('entity.warehouse.notifications.deliveryNoteUpdated'))
      )
      .then(props.onClose)
      .catch(handleApiError);
  };

  return (
    <Form<DeliveryNoteForm>
      schema={formSchema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      shouldWatchForUnsavedChanges
    >
      {(control, formApi) => {
        const handleWarehouseChange = (newWarehouseId: string | string[] | number | null) =>
          getWarehouse({warehouseId: newWarehouseId as string})
            .unwrap()
            .then((warehouse) => {
              formApi.setValue('authorizationProfileId', warehouse.authorizationProfileId);
              formApi.trigger('authorizationProfileId');
            })
            .catch(handleApiError);

        return (
          <>
            <VStack spacing={4}>
              <Grid columns={4}>
                <FormField
                  isRequired
                  isNotClearable
                  isDisabled
                  control={control}
                  type="choice"
                  name="supplierId"
                  label={i18n.t('entity.warehouse.labels.supplier')}
                  options={getOptionsFromSuppliers(props.suppliers)}
                  data-testid={suffixTestId('inputs.supplierId', props)}
                />
                <FormField
                  isRequired
                  isNotClearable
                  isDisabled={isDeliveryNoteReceived && isFromReceiveNote}
                  isLoading={areWarehousesFetching}
                  control={control}
                  type="choice"
                  name="warehouseId"
                  label={i18n.t('entity.warehouse.labels.warehouse')}
                  options={getOptionsFromWarehouses(availableWarehousesToSupplier)}
                  onChange={handleWarehouseChange}
                  data-testid={suffixTestId('inputs.warehouseId', props)}
                />
                <FormField
                  isRequired
                  isNotClearable
                  isDisabled
                  isLoading={isWarehouseFetching}
                  control={control}
                  type="choice"
                  name="authorizationProfileId"
                  label={i18n.t('entity.warehouse.labels.authorizationProfile')}
                  options={getOptionsFromAuthorizationProfiles(props.authorizationProfiles)}
                  data-testid={suffixTestId('inputs.authorizationProfileId', props)}
                />
                <FormField
                  isDisabled={isDeliveryNoteReceived && isFromReceiveNote}
                  control={control}
                  type="text"
                  name="invoiceNumber"
                  label={i18n.t('entity.warehouse.labels.invoiceNumber')}
                  data-testid={suffixTestId('inputs.invoiceNumber', props)}
                />
                <FormField
                  isRequired
                  isNotClearable
                  isDisabled={isDeliveryNoteReceived && isFromReceiveNote}
                  type="choice"
                  name="paymentType"
                  label={i18n.t('entity.warehouse.labels.paymentType')}
                  options={getOptionsFromPaymentTypes(props.paymentTypes)}
                  control={control}
                  data-testid={suffixTestId('inputs.paymentType', props)}
                />
                <FormField
                  isDisabled={isDeliveryNoteReceived && isFromReceiveNote}
                  control={control}
                  type="choice"
                  name="currency"
                  label={i18n.t('entity.warehouse.labels.currency')}
                  options={getOptionsFromCurrencies(props.currencies)}
                  data-testid={suffixTestId('inputs.currency', props)}
                />
                <FormField
                  isDisabled
                  control={control}
                  type="text"
                  name="exchangeRate"
                  label={i18n.t('entity.warehouse.labels.exchangeRate')}
                  helperText="Not implemented"
                  data-testid={suffixTestId('inputs.exchangeRate', props)}
                />
                <FormField
                  isDisabled
                  control={control}
                  type="choice"
                  name="ncConversion"
                  label={i18n.t('entity.warehouse.labels.ncConversion')}
                  options={[]}
                  helperText="Not implemented"
                  data-testid={suffixTestId('inputs.ncConversion', props)}
                />
              </Grid>
            </VStack>

            <Separator />

            <HStack justify="flex-end">
              <ButtonGroup>
                <Show when={isNotNil(props.deliveryNote)}>
                  <FormButton
                    control={control}
                    type="reset"
                    variant="secondary"
                    title={i18n.t('general.labels.discard')}
                    onClick={props.onClose}
                    data-testid={suffixTestId('actions.discard', props)}
                  />
                </Show>
                <FormButton
                  control={control}
                  type="submit"
                  variant="primary"
                  title={i18n.t('general.labels.saveChanges')}
                  data-testid={suffixTestId('actions.submit', props)}
                />
              </ButtonGroup>
            </HStack>
          </>
        );
      }}
    </Form>
  );
}

const formSchema = object({
  supplierId: yupString.required(),
  warehouseId: yupString.required(),
  authorizationProfileId: yupString.required(),
  invoiceNumber: yupString.optional(),
  paymentType: yupString.required(),
  currency: yupString.optional(),
  exchangeRate: yupNumber.optional(),
  ncConversion: yupString.optional(),
});
