import {Action, DataStatus, Form, showNotification} from 'platform/components';
import {Box, Show, VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {isNil, reject} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  PaymentInfoResponseBody,
  useGetBalanceInvoiceQuery,
  useGetInvoicePaymentListQuery,
  useSendInvoiceMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError, useCurrencies} from '@omnetic-dms/shared';

import {buildArray, useRequiredParams} from 'shared';

import {InvoiceDetailHeader} from '../../components/InvoiceDetailHeader';
import {InvoiceDetailSubHeader} from '../../components/InvoiceDetailSubHeader';
import {PaymentsListDetail} from '../../components/PaymentsListDetail';
import {SectionsWithCenteredContent} from '../../components/SectionsWithCenteredContent';
import {invoicePaymentOptions} from '../../constants';
import {InvoiceVatRateProvider} from '../../context/InvoiceVatRateProvider';
import {useDocumentActions} from '../../hooks/useDocumentActions';
import {AccountingDocumentFormValues} from '../../types';
import {getInvoiceStateFlagProps} from '../../utils/getInvoiceStateFlagProps';
import {getPaymentStateFlagProps} from '../../utils/getPaymentStateFlagProps';
import {isCanceledOrCanceling} from '../../utils/isInvoiceCanceledOrCanceling';
import {BalanceInvoiceDetails} from './BalanceInvoiceDetails';

export function BalanceInvoiceDetail() {
  const {id: balanceInvoiceId} = useRequiredParams();

  const [subheaderActions] = useDocumentActions('BALANCE_INVOICE', balanceInvoiceId);
  const {getCurrencyName} = useCurrencies();
  const [sendInvoice, {isLoading: isSendingDocument}] = useSendInvoiceMutation();

  const {
    data: balanceInvoice,
    isLoading: isLoadingInvoice,
    isError: isInvoiceError,
  } = useGetBalanceInvoiceQuery({balanceInvoiceId});
  const invoice = balanceInvoice?.invoice;

  const {
    data: paymentList,
    isLoading: isLoadingPaymentList,
    isError: isPaymentListError,
  } = useGetInvoicePaymentListQuery({invoiceId: balanceInvoiceId});

  const bankAccountValue = reject(isNil, [
    invoice?.paymentInfo?.bankAccount,
    invoice?.paymentInfo?.bankName,
    invoice?.currency,
  ]).join(', ');

  const showNonDownloadButtons =
    !isCanceledOrCanceling(invoice?.paymentState) && isNil(invoice?.cancelledAt);
  const shouldDisplaySendButton = isNil(invoice?.sentAt) && showNonDownloadButtons;
  const paymentMethodLabel =
    (invoicePaymentOptions.find((item) => item.value === invoice?.paymentInfo?.paymentMethod)
      ?.label as PaymentInfoResponseBody['paymentMethod']) ?? 'BANK_TRANSFER';

  const initialValues = invoice
    ? {
        ...invoice,
        paymentInfo: {
          ...invoice.paymentInfo,
          bankAccount: bankAccountValue,
          paymentMethod: paymentMethodLabel,
        },
        exchangeRateRatio: {
          ...invoice.exchangeRateRatio,
          amount: invoice.exchangeRateRatio?.amount?.toString() ?? null,
          currency: getCurrencyName(invoice.exchangeRateRatio?.currency),
          type: i18n.t('general.actions.recalculate'),
        },
      }
    : undefined;

  const isLoading = isLoadingInvoice || isLoadingPaymentList;
  const isError = isInvoiceError || isPaymentListError;

  const handleSendInvoice = () => {
    if (isNil(invoice)) {
      showNotification.error();
      return;
    }

    sendInvoice({invoiceId: invoice.id})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const invoiceHeaderActions = buildArray<Action>().when(shouldDisplaySendButton, {
    type: 'button',
    title: i18n.t('general.actions.send'),
    onClick: handleSendInvoice,
    isLoading: isSendingDocument,
  });

  const pageTitle = i18n.t('entity.balanceInvoice.labels.balanceInvoiceNumber', {
    documentNumber: invoice?.number,
  });

  return (
    <Box backgroundColor="palettes.neutral.20.100" minHeight="100%">
      <Show when={isNotNil(invoice)}>
        <Helmet title={pageTitle} />

        <DataStatus isLoading={isLoading} isError={isError} minHeight="80vh">
          <Form<AccountingDocumentFormValues> defaultValues={initialValues}>
            {(control) => (
              <InvoiceVatRateProvider control={control} listItemType="invoicing-documents">
                <VStack>
                  <Box backgroundColor="general.white">
                    <InvoiceDetailHeader title={pageTitle} actions={invoiceHeaderActions} />

                    <InvoiceDetailSubHeader
                      data-testid={testIds.accounting.balanceInvoiceDetail(
                        'invoiceDetailSubHeader'
                      )}
                      author={invoice!.author}
                      actions={subheaderActions}
                      createdAt={invoice!.createdAt}
                      flags={reject(isNil, [
                        getInvoiceStateFlagProps(invoice),
                        getPaymentStateFlagProps(invoice!.paymentState),
                      ])}
                    />
                  </Box>

                  <SectionsWithCenteredContent
                    data-testid="accounting-sections"
                    sections={[
                      {
                        content: <BalanceInvoiceDetails control={control} invoice={invoice!} />,
                        id: 'detail',
                        label: i18n.t('general.labels.details'),
                      },
                      {
                        content: (
                          <PaymentsListDetail
                            paymentItems={paymentList}
                            invoiceType="balance"
                            data-testid={testIds.accounting.balanceInvoiceDetail(
                              'payments-list-detail'
                            )}
                          />
                        ),
                        id: 'payments',
                        label: i18n.t('page.accounting.invoiceDetailPayments.title'),
                      },
                    ]}
                  />
                </VStack>
              </InvoiceVatRateProvider>
            )}
          </Form>
        </DataStatus>
      </Show>
    </Box>
  );
}
