import {DataStatus, Form} from 'platform/components';
import {Box, Show, VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {indexBy, isNil, reject} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  PaymentInfoResponseBody,
  useGetInvoiceProformaPaymentListQuery,
  useGetInvoiceProformaQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {useRequiredParams} from 'shared';

import {InvoiceDetailHeader} from '../../components/InvoiceDetailHeader';
import {InvoiceDetailSubHeader} from '../../components/InvoiceDetailSubHeader';
import {PaymentsListDetail} from '../../components/PaymentsListDetail';
import {SectionsWithCenteredContent} from '../../components/SectionsWithCenteredContent';
import {invoicePaymentOptions} from '../../constants';
import {InvoiceVatRateProvider} from '../../context/InvoiceVatRateProvider';
import {useDocumentActions} from '../../hooks/useDocumentActions';
import {AccountingDocumentFormValues} from '../../types';
import {getInvoiceStateFlagProps} from '../../utils/getInvoiceStateFlagProps';
import {getPaymentStateFlagProps} from '../../utils/getPaymentStateFlagProps';
import {ProformaInvoiceDetails} from './components/ProformaInvoiceDetails';

export function ProformaInvoiceDetail() {
  const {id: invoiceProformaId} = useRequiredParams();

  const [subheaderActions] = useDocumentActions('PROFORMA', invoiceProformaId);

  const {
    data: invoice,
    isLoading: isLoadingInvoice,
    isError: isInvoiceError,
  } = useGetInvoiceProformaQuery({invoiceProformaId});
  const {
    data: paymentList,
    isLoading: isLoadingPaymentList,
    isError: isPaymentListError,
  } = useGetInvoiceProformaPaymentListQuery({invoiceId: invoiceProformaId});

  const bankAccountValue = reject(isNil, [
    invoice?.paymentInfo?.bankAccount,
    invoice?.paymentInfo?.bankName,
    invoice?.currency,
  ]).join(', ');

  const paymentTypesByType = indexBy((item) => item.value, invoicePaymentOptions);
  const paymentMethodLabel = invoice
    ? (paymentTypesByType[invoice.paymentInfo.paymentMethod]
        .label as PaymentInfoResponseBody['paymentMethod'])
    : 'BANK_TRANSFER';

  const initialValues = invoice
    ? {
        ...invoice,
        paymentInfo: {
          ...invoice.paymentInfo,
          bankAccount: bankAccountValue,
          paymentMethod: paymentMethodLabel,
        },
        exchangeRateRatio: {
          amount: null,
          currency: null,
          type: i18n.t('general.actions.recalculate'),
        },
      }
    : undefined;

  const isLoading = isLoadingInvoice || isLoadingPaymentList;
  const isError = isInvoiceError || isPaymentListError;

  const pageTitle = i18n.t('entity.proforma.labels.proformaNumber', {
    invoiceNumber: invoice?.number,
  });

  return (
    <Box backgroundColor="palettes.neutral.20.100" minHeight="100%">
      <DataStatus isLoading={isLoading} isError={isError} minHeight="80vh">
        <Show when={isNotNil(invoice)}>
          <Helmet title={pageTitle} />
          <Form<AccountingDocumentFormValues> defaultValues={initialValues}>
            {(control) => (
              <InvoiceVatRateProvider control={control} listItemType="invoicing-documents">
                <VStack>
                  <Box backgroundColor="general.white">
                    <InvoiceDetailHeader
                      title={pageTitle}
                      data-testid={testIds.accounting.proformaInvoiceDetail('invoiceDetailHeader')}
                    />

                    <InvoiceDetailSubHeader
                      data-testid={testIds.accounting.proformaInvoiceDetail(
                        'invoiceDetailSubHeader'
                      )}
                      author={invoice!.author}
                      actions={subheaderActions}
                      createdAt={invoice!.createdAt}
                      flags={reject(isNil, [
                        getInvoiceStateFlagProps(invoice),
                        getPaymentStateFlagProps(invoice!.paymentState),
                      ])}
                    />
                  </Box>

                  <SectionsWithCenteredContent
                    data-testid="accounting-sections"
                    sections={[
                      {
                        content: <ProformaInvoiceDetails control={control} invoice={invoice!} />,
                        id: 'detail',
                        label: i18n.t('general.labels.details'),
                      },
                      {
                        content: (
                          <PaymentsListDetail paymentItems={paymentList} invoiceType="proforma" />
                        ),
                        id: 'payments',
                        label: i18n.t('page.accounting.invoiceDetailPayments.title'),
                      },
                    ]}
                  />
                </VStack>
              </InvoiceVatRateProvider>
            )}
          </Form>
        </Show>
      </DataStatus>
    </Box>
  );
}
