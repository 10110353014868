import {DataStatus} from 'platform/components';

import {useParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetSalePresetSettingsQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SalePresetForm} from './components/SalePresetForm';

export function SalePresetDetail() {
  const {id} = useParams();

  const {data, isError, isFetching} = useGetSalePresetSettingsQuery(
    {salePresetId: id ?? ''},
    {skip: isNilOrEmpty(id), refetchOnMountOrArgChange: true}
  );

  return (
    <SettingsTemplate
      header={{
        title: data?.name
          ? i18n.t('page.salesSettings.labels.salePresetDetailName', {name: data?.name})
          : i18n.t('page.salesSettings.salePreset.newConfiguration'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
          {
            label: i18n.t('page.salesSettings.labels.salePreset'),
            href: settingsRoutes.salePreset,
          },
        ],
      }}
      data-testid="settings-salePresetDetail"
      description={i18n.t('page.salesSettings.salePreset.descriptionDetail')}
    >
      <DataStatus isLoading={isFetching} isError={isError}>
        <SalePresetForm settings={data} />
      </DataStatus>
    </SettingsTemplate>
  );
}
