import {CounterContainer} from './CounterContainer';

interface CounterProps {
  count: number;
  color: string;
}

export function Counter({count, color}: CounterProps) {
  return (
    <CounterContainer $color={color} $extended={`${count}`.length > 2}>
      {count}
    </CounterContainer>
  );
}
