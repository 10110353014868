import {flip, offset, shift, useFloating, useTransitionStyles} from '@floating-ui/react';

import {useRef} from 'react';

import {useBoolean, useOutsideClick} from 'shared';

import {Placement} from '../../../types/Placement';
import {AnimatedPopper, AnimatedPopperProps} from '../AnimatedPopper';

interface UseAnimatedPopperProps {
  placement?: Placement;
  gutter?: number;
  /**
   * Prevents the popper from closing on outside click
   */
  isOutsideClickIgnored?: boolean;
  strategy?: 'absolute' | 'fixed';
}

interface UseAnimatedPopperReturnType {
  isOpen: boolean;
  popperProps: AnimatedPopperProps;
  Popper: typeof AnimatedPopper;
  openPopper: () => void;
  closePopper: () => void;
  togglePopper: () => void;
  referenceRef: <T extends Element>(node: T | null) => void;
}

const DEFAULT_PROPS: UseAnimatedPopperProps = {
  placement: 'top-start',
  gutter: 0,
  strategy: 'absolute',
};

export const useAnimatedPopper = (
  props: UseAnimatedPopperProps = DEFAULT_PROPS
): UseAnimatedPopperReturnType => {
  const [isOpen, onOpen, onClose, onToggle] = useBoolean(false);
  const {refs, floatingStyles, context} = useFloating({
    placement: props.placement,
    middleware: [offset(props.gutter), shift(), flip()],
    strategy: props.strategy,
    open: isOpen,
  });
  const {styles: transitionStyles} = useTransitionStyles(context);
  const clickRef = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: clickRef,
    handler: onClose,
    enabled: !props.isOutsideClickIgnored,
  });

  return {
    isOpen,
    openPopper: onOpen,
    closePopper: onClose,
    togglePopper: onToggle,
    referenceRef: refs.setReference,
    Popper: AnimatedPopper,
    popperProps: {
      isOpen,
      floatingRef: refs.setFloating,
      clickRef,
      floatingStyles,
      transitionStyles,
    },
  };
};
