import {Dropdown, DropdownItem, FormField, IconButton, Label} from 'platform/components';
import {Grid, GridItem, Hide, Show, Space, VStack} from 'platform/foundation';

import {useWatch} from 'react-hook-form';

import {isNil} from 'ramda';
import {isNilOrEmpty, isPositiveZero} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId} from 'shared';

import {cashReceiptItemUnits, invoiceItemUnits} from '../../constants';
import {useGetInvoiceCurrency} from './hooks/useGetInvoiceCurrency';
import {InvoiceItemRowProps} from './types/InvoiceItemRowProps';

export function InvoiceItemRow(props: InvoiceItemRowProps) {
  const isCashReceipts = props.listItemsType === 'cash-receipts';

  const field = props.fields[props.index];

  const [quantity] = useWatch({name: [`invoiceItems.${props.index}.quantity`]});

  const isFirstItem = isPositiveZero(props.index);
  const isOnlyItem = props.fields.filter((item) => item.type === 'standard').length <= 1;

  const marginItem = props.fields.find(
    (item) => field.itemId === item?.relatedItemId && item.type === 'margin'
  );

  const isQuantityValidForMargin = quantity?.toString() === '1';
  const isWithoutMargin = isNil(marginItem);
  const isQuantityInvalid = isNilOrEmpty(quantity);

  const shouldShowMargin = isWithoutMargin && (isQuantityValidForMargin || isQuantityInvalid);

  const hasDiscount = props.fields.some(
    (item) => field.itemId === item?.relatedItemId && item.type === 'discount'
  );

  const [currency] = useGetInvoiceCurrency(props.control, props.listItemsType);

  const isDropdownDisabled = hasDiscount && !shouldShowMargin && isFirstItem;

  const handleRecalculateItems = () => props.callbacks.onRecalculate(field.itemId);

  const shouldDisableMarginsAndDiscount = isCashReceipts || props.isCorrectiveTaxDocument;

  const unitOptions = isCashReceipts ? cashReceiptItemUnits : invoiceItemUnits;

  return (
    <Grid columns={35} align="flex-end">
      <GridItem span={8}>
        <FormField
          name={`invoiceItems.${props.index}.description`}
          control={props.control}
          isDisabled={props.isCorrectiveTaxDocument}
          type="text"
          label={isFirstItem ? i18n.t('general.labels.name') : undefined}
          isRequired
          data-testid={suffixTestId(`name-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={3}>
        <FormField
          name={`invoiceItems.${props.index}.quantity`}
          control={props.control}
          onChange={handleRecalculateItems}
          type="number"
          label={isFirstItem ? i18n.t('entity.order.itemQuantity') : undefined}
          decimalPlaces={2}
          isDisabled={
            (isQuantityValidForMargin && !isWithoutMargin) || props.isCorrectiveTaxDocument
          }
          isRequired
          data-testid={suffixTestId(`quantity-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={3}>
        <FormField
          name={`invoiceItems.${props.index}.unit`}
          control={props.control}
          isNotClearable
          type={props.isCorrectiveTaxDocument ? 'text' : 'choice'}
          label={isFirstItem ? i18n.t('entity.lineItems.labels.unit') : undefined}
          options={unitOptions}
          data-testid={suffixTestId(`unitType-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={4}>
        <FormField
          name={`invoiceItems.${props.index}.pricePerUnit`}
          control={props.control}
          onChange={handleRecalculateItems}
          label={isFirstItem ? i18n.t('general.labels.unitPrice') : undefined}
          type="currency"
          isRequired
          decimalPlaces={2}
          currency={currency}
          data-testid={suffixTestId(`pricePerUnit-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={2}>
        <VStack>
          <Show when={isPositiveZero(props.index)}>
            <Label>{i18n.t('general.labels.withVat')}</Label>
            <Space vertical={1} />
          </Show>
          <FormField
            name={`invoiceItems.${props.index}.isUnitPriceWithVat`}
            control={props.control}
            onChange={handleRecalculateItems}
            type="checkbox"
            data-testid={suffixTestId(`isUnitPriceWithVat-${props.index}`, props)}
          />
          <Space vertical={2} />
        </VStack>
      </GridItem>
      <GridItem span={5}>
        <FormField
          name={`invoiceItems.${props.index}.priceWithoutVat`}
          control={props.control}
          type="currency"
          currency={currency}
          label={
            isPositiveZero(props.index)
              ? i18n.t('entity.lineItems.labels.totalPriceWithoutVat')
              : undefined
          }
          isDisabled
          data-testid={suffixTestId(`totalPriceWithoutVat-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={3}>
        <FormField
          name={`invoiceItems.${props.index}.vatType`}
          control={props.control}
          type="choice"
          label={isPositiveZero(props.index) ? i18n.t('general.labels.vatType') : undefined}
          onChange={() => {
            props.formApi.clearErrors(`invoiceItems.${props.index}.vatType`);
            handleRecalculateItems();
          }}
          isNotClearable
          options={props.vatRatesOptions}
          data-testid={suffixTestId(`vatType-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={5}>
        <FormField
          name={`invoiceItems.${props.index}.priceWithVat`}
          control={props.control}
          currency={currency}
          isDisabled
          label={
            isPositiveZero(props.index)
              ? i18n.t('entity.lineItems.labels.totalPriceWithVat')
              : undefined
          }
          type="currency"
          data-testid={suffixTestId(`totalPriceWithVat-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={2}>
        <Hide when={shouldDisableMarginsAndDiscount}>
          <Dropdown
            isOpen={isDropdownDisabled ? false : undefined}
            dropdownControl={
              <IconButton
                data-testid={suffixTestId(`deleteItem-${props.index}`, props)}
                icon="navigation/more_vert"
                isDisabled={isDropdownDisabled}
              />
            }
          >
            <Hide when={hasDiscount}>
              <DropdownItem
                label={i18n.t('page.accounting.labels.addDiscount')}
                leftIcon="content/add_circle"
                onClick={() => props.callbacks.onCreateDiscount(props.index)}
              />
            </Hide>
            <Show when={shouldShowMargin}>
              <DropdownItem
                label={i18n.t('page.accounting.labels.addMargin')}
                leftIcon="content/add_circle"
                onClick={() => props.callbacks.onCreateMargin(props.index)}
              />
            </Show>
            <Hide when={isOnlyItem}>
              <DropdownItem
                label={i18n.t('page.accounting.labels.removeItem')}
                leftIcon="action/delete"
                severity="danger"
                onClick={() => props.callbacks.onDeleteRow(props.index)}
              />
            </Hide>
          </Dropdown>
        </Hide>
        <Show when={shouldDisableMarginsAndDiscount}>
          <IconButton
            data-testid={suffixTestId(`deleteItem-${props.index}`, props)}
            icon="action/delete"
            severity="danger"
            isDisabled={isOnlyItem}
            onClick={() => props.callbacks.onDeleteRow(props.index)}
          />
        </Show>
      </GridItem>
    </Grid>
  );
}
