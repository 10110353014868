import {match} from 'ts-pattern';

import {always} from 'ramda';

import {Nullish} from 'shared';

import {Resolution} from '../types/resolution';

export function getResolutionArrayFromValue(value: boolean | Nullish): Resolution[] {
  return match<boolean | Nullish, Resolution[]>(value)
    .with(true, always(['YES']))
    .with(false, always(['NO']))
    .otherwise(always([]));
}
