import {Checkbox, showNotification} from 'platform/components';
import {Space, Text, VStack} from 'platform/foundation';

import {isTrue} from 'ramda-adjunct';

import {
  useGetBusinessCaseSettingsQuery,
  usePutBusinessCaseSettingsMutation,
} from '@omnetic-dms/api';
import {i18n} from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

export function MaximumAcceptedPriceSection() {
  const {data: businessCaseSettingsData, isLoading: isBusinessCaseSettingsLoading} =
    useGetBusinessCaseSettingsQuery();
  const [putBusinessCaseSettings] = usePutBusinessCaseSettingsMutation();

  const onCheckboxChange = (checkboxValue: boolean) => {
    putBusinessCaseSettings({
      businessCaseSettingsRequestBody: {
        maximalPurchasePriceRequired: checkboxValue,
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  return (
    <VStack spacing={5}>
      <Text size="small" color="secondary">
        {i18n.t('page.salesSettingsBusinessCase.labels.maximumPrice.title')}
      </Text>
      <Checkbox
        isDisabled={isBusinessCaseSettingsLoading}
        value={isTrue(businessCaseSettingsData?.maximalPurchasePriceRequired)}
        onChange={onCheckboxChange}
        label={i18n.t('page.salesSettingsBusinessCase.labels.maximumPrice.checkboxLabel')}
        helperText={i18n.t('page.salesSettingsBusinessCase.labels.maximumPrice.checkboxHelperText')}
      />
      <Space vertical={3} />
    </VStack>
  );
}
