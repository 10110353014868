import {openDeleteDialog} from 'platform/components';

import {mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteBasketItemApiMutation} from '@omnetic-dms/api';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

interface ServicePackageDataGridProps extends TestIdProps {
  jobId: string;
}

export function ServicePackageDataGrid(props: ServicePackageDataGridProps) {
  const [deleteBasketItem] = useDeleteBasketItemApiMutation();

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {contextTarget: 'service-case-job', contextId: props.jobId}]);

  const actionCallback: ActionCallback = ({actionKey, refreshData, rowId}) => {
    const basketItemId = isArray(rowId) ? rowId[0] : rowId;

    if (actionKey === 'delete') {
      openDeleteDialog({
        onConfirm: () =>
          deleteBasketItem({basketItemId}).unwrap().then(refreshData).catch(handleApiError),
      });
    }
  };

  return (
    <DataGrid
      autoHeight
      gridCode="service-case-service-request-items"
      actionCallback={actionCallback}
      queryModifier={queryModifier}
      data-testid={suffixTestId('service-package-dg', props)}
    />
  );
}
