import {ThemeColorTextPath} from 'platform/foundation';

import {isPositiveValue} from './isPositiveValue';

interface GetPriceColorParams {
  isHighlighted?: boolean;
  invert?: boolean;
  value: number;
}

export const getPriceColor = ({
  isHighlighted,
  invert,
  value,
}: GetPriceColorParams): ThemeColorTextPath | undefined => {
  if (isHighlighted) {
    return isPositiveValue(value, invert) ? 'success' : 'danger';
  }
  return undefined;
};
