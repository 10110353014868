import {z} from 'zod';

const EmployeeSchema = z.object({
  name: z.string().nullable().optional(),
  competence: z.string().nullable().optional(),
});

const EstimatedTimeSchema = z.object({
  quantity: z.number().nullable().optional(),
  unit: z.string().nullable().optional(),
});

const CheckinSchema = z.object({
  acceptanceDate: z.string().nullable().optional(),
  from: z.string().nullable().optional(),
  till: z.string().nullable().optional(),
  estimatedTime: EstimatedTimeSchema.nullable().optional(),
  employee: EmployeeSchema.nullable().optional(),
});

const HandoverSchema = z.object({
  releaseDate: z.string().nullable().optional(),
});

const RepairScheduleSchema = z.object({
  checkin: CheckinSchema.nullable().optional(),
  handover: HandoverSchema.nullable().optional(),
});

const ServiceWorkSchema = z.object({
  workType: z.string().nullable().optional(),
  from: z.string().nullable().optional(),
  till: z.string().nullable().optional(),
  estimatedTime: EstimatedTimeSchema.nullable().optional(),
  employee: EmployeeSchema.nullable().optional(),
});

export const PostServiceCaseScheduleRepairApiResponseSchema = z
  .object({
    url: z.string().nullable().optional(),
  })
  .nullable();

export type PostServiceCaseScheduleRepairApiResponse = z.infer<
  typeof PostServiceCaseScheduleRepairApiResponseSchema
>;

export const PostServiceCaseScheduleRepairApiArgSchema = z.object({
  serviceCaseId: z.string(),
  redirectUrl: z.string().nullable().optional(),
});

export type PostServiceCaseScheduleRepairApiArg = z.infer<
  typeof PostServiceCaseScheduleRepairApiArgSchema
>;

export const GetServiceCaseScheduleRepairApiResponseSchema = z
  .object({
    repairSchedule: RepairScheduleSchema.nullable().optional(),
    serviceWork: z.array(ServiceWorkSchema.nullable()).optional(),
    url: z.string().nullable().optional(),
  })
  .nullable();

export type GetServiceCaseScheduleRepairApiResponse = z.infer<
  typeof GetServiceCaseScheduleRepairApiResponseSchema
>;

export const GetServiceCaseScheduleRepairApiArgSchema = z.object({
  serviceCaseId: z.string(),
  extRepairId: z.string().nullable().optional(),
});

export type GetServiceCaseScheduleRepairApiArg = z.infer<
  typeof GetServiceCaseScheduleRepairApiArgSchema
>;
