import {CSSDimension, Box, HStack, getSize} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {Snippet, SnippetProps} from './Snippet';

export interface SnippetPanelProps extends TestIdProps {
  snippets?: SnippetProps[];
}

const MAX_SNIPPET_WIDTH: CSSDimension = `calc(25% - (${getSize(3)}))`; // 3/4 (3 - spaces between 4 snippets) of snippet spacing (4) -> 3

export function SnippetPanel(props: SnippetPanelProps) {
  if (!props.snippets?.length) {
    return null;
  }

  return (
    <Box backgroundColor="palettes.white.10.100" data-testid={suffixTestId('snippetPanel', props)}>
      <HStack spacing={4} justify="flex-start">
        {props.snippets.map((snippet, index) => (
          <Box key={snippet.label} flex={1} maxWidth={MAX_SNIPPET_WIDTH}>
            <Snippet
              data-testid={suffixTestId(`snippetPanel-item-[${index}]`, props)}
              {...snippet}
            />
          </Box>
        ))}
      </HStack>
    </Box>
  );
}
