export function CheckIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <g clipPath="url(#clip0_23415_8269)">
        <g filter="url(#filter0_dd_23415_8269)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 22C17.4183 22 21 18.4183 21 14C21 9.58172 17.4183 6 13 6C8.58172 6 5 9.58172 5 14C5 18.4183 8.58172 22 13 22ZM13 25C19.0751 25 24 20.0751 24 14C24 7.92487 19.0751 3 13 3C6.92487 3 2 7.92487 2 14C2 20.0751 6.92487 25 13 25Z"
            fill="white"
          />
        </g>
        <path
          d="M13.0002 15.1698L8.83016 10.9998L7.41016 12.4098L13.0002 17.9998L25.0002 5.99984L23.5902 4.58984L13.0002 15.1698Z"
          fill="#172B4D"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_23415_8269"
          x="-54"
          y="-33"
          width="142"
          height="142"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="15" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_23415_8269" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="24" />
          <feGaussianBlur stdDeviation="30" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.427451 0 0 0 0 0.552941 0 0 0 0 0.678431 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_23415_8269"
            result="effect2_dropShadow_23415_8269"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_23415_8269"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_23415_8269">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
