import {AnyObjectSchema} from 'yup';
import {AnySchema} from 'yup/lib/schema';

import {path} from 'ramda';

export const getIsRequired = (_name: string | string[], schema?: AnyObjectSchema) => {
  const pathInSchema: string[] = [];
  let parts: string[] = [];

  if (typeof _name === 'string') {
    parts = _name.split('.');

    parts.forEach((part, i) => {
      if (i < parts.length) {
        pathInSchema.push('fields');
      }
      pathInSchema.push(part);
    });
  } else {
    _name.forEach((item) => {
      parts.push(...item.split(/\.|\[|\]/g).filter((i) => i !== ''));
    });

    parts.forEach((part, i) => {
      if (i < parts.length) {
        if (!isNaN(parseInt(parts[i]))) {
          pathInSchema.splice(i + 1, 0, 'innerType');
        } else if (parts[i - 1] !== 'innerType') {
          pathInSchema.push('fields');
        }
      }
      if (isNaN(parseInt(parts[i]))) {
        pathInSchema.push(part);
      }
    });
  }

  const getRequired = path(pathInSchema);

  const required =
    (getRequired(schema) as AnySchema)?.tests.findIndex(
      ({OPTIONS}) => OPTIONS.name === 'required'
    ) >= 0;

  return required;
};
