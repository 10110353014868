import type {PermissionsRecordType} from '../permissions';

export const serviceCasePermissions = {
  serviceCaseRead: {
    resourceId: 'SERVICE_CASE',
    actionId: 'READ',
    scopes: [],
  },
  serviceOrderCreate: {
    resourceId: 'SERVICE_ORDER',
    actionId: 'CREATE',
    scopes: [],
  },
  serviceOrderEdit: {
    resourceId: 'SERVICE_ORDER',
    actionId: 'EDIT',
    scopes: [],
  },
  editPartsListReady: {
    resourceId: 'SERVICE_REQUEST',
    actionId: 'PARTS_LIST_READY_EDIT',
    scopes: [],
  },
  editPartsListRequest: {
    resourceId: 'SERVICE_REQUEST',
    actionId: 'PARTS_LIST_REQUEST_EDIT',
    scopes: [],
  },
  editSolution: {
    resourceId: 'SERVICE_REQUEST',
    actionId: 'SOLUTION_EDIT',
    scopes: [],
  },
  serviceCaseCustomFields: {
    actionId: 'CUSTOM_FIELDS_PAYLOAD_UPDATE',
    resourceId: 'SERVICE_CASE',
    scopes: ['BRANCH', 'PARTICIPATION'],
  },
} satisfies PermissionsRecordType;
