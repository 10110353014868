import {BreadcrumbType} from 'platform/components';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {CebiaEquipmentAuthentication} from './components/CebiaEquipmentAuthentication';

function Cebia() {
  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('page.integrations.labels.vehicleServices'),
      href: settingsRoutes.vehicleServices,
    },
  ];

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.integrations.labels.cebiaEquipment'), breadcrumbs}}
      data-testid="integrations-cebia-settings-modal"
    >
      <CebiaEquipmentAuthentication />
    </SettingsTemplate>
  );
}

export const Features = Cebia;
