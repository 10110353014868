export enum ApiEnumPropertyName {
  BODY_COLOR = 'body_color',
  CHARGING_CONNECTOR_TYPE = 'charging_connector_type',
  TIRE_ASPECT_RATIO = 'tire_aspect_ratio',
  FUEL_TYPE = 'fuel_type',
  CATEGORIES = 'categories',
  MOTORCYCLE_STYLE = 'motorcycle_style',
  SERVICE_BOOK_TYPE = 'service_book_type',
  TIRE_SEASON = 'tire_season',
  EMISSION_CLASS = 'emission_class',
  CONDITION = 'condition',
  BATTERY_OWNERSHIP_TYPE = 'battery_ownership_type',
  COST = 'cost',
  DRIVE = 'drive',
  COUNTRY = 'country',
  FUEL_UNIT = 'fuel_unit',
  TIRE_MAKE = 'tire_make',
  FEATURE_CATEGORY = 'feature_category',
  SELLER_TYPE = 'seller_type',
  TIRE_RIM_DIAMETER = 'tire_rim_diameter',
  FEATURE = 'feature',
  TIRE_MODEL = 'tire_model',
  HAND_DRIVE_TYPE = 'hand_drive_type',
  CARAVAN_STYLE = 'caravan_style',
  COLOR_TYPE = 'color_type',
  CAR_STYLE = 'car_style',
  TRANSMISSION = 'transmission',
  SERVICE_BOOK_STATE = 'service_book_state',
  INTERIOR_COLOR = 'interior_color',
  BATTERY_TYPE = 'battery_type',
  DOOR_COUNT = 'door_count',
  HYBRID_TYPE = 'hybrid_type',
  BUS_STYLE = 'bus_style',
  VEHICLE_TYPE = 'vehicle_type',
  VAN_STYLE = 'van_style',
  SEMI_TRAILER_STYLE = 'semi_trailer_style',
  TRAILER_STYLE = 'trailer_style',
  CONSTRUCTION_MACHINE_STYLE = 'construction_machine_style',
  AGRICIULTURE_MACHINE_STYLE = 'agriculture_machine_style',
  INTERIOR_MATERIAL = 'interior_material',
  TRUCK_STYLE = 'truck_style',
  SEMI_TRUCK_STYLE = 'semi_truck_style',
  TIRE_WIDTH = 'tire_width',
  CURRENCY = 'currency',
}
