export const utilityColumnDefinition = {
  hide: true,
  suppressFiltersToolPanel: true,
  suppressColumnsToolPanel: true,
  lockVisible: true,
  width: 0,
  resizable: false,
  sortable: false,
  suppressMenu: true,
};
