import {getRandomId} from 'shared';

import {ItemType} from '../types/ItemType';

export const getEmptyItem = (): ItemType => ({
  prefix: '',
  startingNumber: 1,
  length: '8',
  from: null,
  state: 'not_used',
  id: getRandomId(),
});
