import {useContext} from 'react';

import {textFieldCtx} from '../textFieldCtx';
import {TextFieldContext} from '../types';

export const useTextFieldContext = (): TextFieldContext => {
  const ctx = useContext(textFieldCtx);

  return ctx;
};
