import {Dispatch} from 'redux';

import {uploadFile} from '../utils/uploadFile';

export const changeExistingFile = (
  dispatch: Dispatch,
  file: File,
  code: string,
  title: string,
  customTemplateId: string,
  note?: string
) => uploadFile(dispatch, file, code, title, note, customTemplateId);
