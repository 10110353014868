import {css, useTheme} from 'styled-components';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useResponsivePropValue} from '../../hooks/useResponsivePropValue';
import {ValueByDevice} from '../../types/ValueByDevice';
import {ThemeColorTextPath} from '../../utils/foundationTheme';
import {useDevice} from '../DeviceProvider/useDevice';

export type DisplayAlign = 'left' | 'center' | 'right';

export interface DisplayProps extends TestIdProps {
  size: 1;
  alternative?: true;
  color?: ThemeColorTextPath;
  children?: Nullish | string;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  align?: DisplayAlign | ValueByDevice<DisplayAlign>;
}

export function Display(props: DisplayProps) {
  const theme = useTheme();
  const device = useDevice();
  const align = useResponsivePropValue(props.align);

  return (
    <div
      role="heading"
      aria-level={props.headingLevel ?? props.size}
      data-testid={suffixTestId('display', props)}
      css={css`
        color: ${theme.colors.text[props.color ?? 'primary']};
        font-family: ${theme.fonts.heading};
        font-size: ${theme.fontSizes.display[device][props.size]};
        line-height: ${theme.lineHeights.display[device][props.size]};
        font-weight: ${theme.fontWeights.display[props.alternative ? 'alternative' : 'default']};
        letter-spacing: ${theme.letterSpacing.display[device][props.size]};
        text-align: ${align};
      `}
    >
      {props.children}
    </div>
  );
}
