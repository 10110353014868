import {Nullish} from 'shared';

export function extractParamsFromString(string: string | Nullish) {
  if (!string) {
    return [];
  }

  // eslint-disable-next-line no-lookahead-lookbehind-regexp/no-lookahead-lookbehind-regexp
  return string.match(/[^{}]*(?=\})/g)?.filter((match) => match !== '') || [];
}
