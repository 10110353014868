import {range} from 'ramda';

import {Hour} from '../types/Hour';
import {Minute} from '../types/Minute';

/**
 * @param step Minutes step. Defaults to 1.
 * @returns Object with minutes and hours arrays.
 */
export const getDropdownTimes = (step = 1) => {
  const hours = range(0, 24).map((hour) => String(hour).padStart(2, '0') as Hour);
  const minutes = range(0, Math.ceil(60 / step)).map(
    (minute) => String(minute * step).padStart(2, '0') as Minute
  );

  return {hours, minutes};
};
