import {Pagination} from 'platform/components';

import {PaginationRendererProps} from '../types/PaginationRenderer';

export function DefaultPaginationRenderer(props: PaginationRendererProps) {
  const {currentPage, totalPages, onPageChange} = props;

  return (
    <Pagination
      data-testid={props['data-testid']}
      page={currentPage}
      pagesQuantity={totalPages}
      onPageChange={onPageChange}
      showFirstButton
      showLastButton
      showNextButton
      showPrevButton
    />
  );
}
