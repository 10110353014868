import type {StructureItemBody} from './StructureItemBody';

export type CreateTypeOfControlSettingsRequestBody = {
  name: string;
  key: string | null;
  inspectionType: CreateTypeOfControlSettingsRequestBody.inspectionType;
  showEmptyFields?: boolean;
  validation: CreateTypeOfControlSettingsRequestBody.validation;
  items: Array<StructureItemBody>;
  version?: number | null;
};

export namespace CreateTypeOfControlSettingsRequestBody {
  export enum inspectionType {
    VEHICLE_CONDITION = 'vehicleCondition',
    VALIDATION_INSPECTION = 'validationInspection',
    NON_VALIDATION_INSPECTION = 'nonValidationInspection',
  }

  export enum validation {
    VALIDATE = 'VALIDATE',
    NON_VALIDATE = 'NON_VALIDATE',
  }
}
