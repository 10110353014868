import {closeDialog, openDialog} from 'platform/components';
import {Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {isArray, isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {catchUnhandledDataGridActions, useWarehouseDataGridTreeFolder} from '@omnetic-dms/shared';

import {useBoolean} from 'shared';

import {ActionCallback, DataGrid, RowData} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../components/FullscreenDataGridWrapper';
import {AddToSupplierOrderModal} from './AddToSupplierOrderModal';
import {CreateSupplierOrderForm} from './CreateSupplierOrderForm';
import {PurchaseListExternalFilter} from './PurchaseListExternalFilter';

const PURCHASE_LIST_EXTERNAL_FILTER_ID = 'purchase-list-external-filter';

export function PurchaseList() {
  const [folderTreeRef, dataGridModifier] = useWarehouseDataGridTreeFolder();
  const [isAddToSupplierModalVisible, , , toggleAddToSupplierModal] = useBoolean();
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const rowsIds = isArray(rowId) ? rowId : [rowId];

    match(actionKey)
      .with('createSupplierOrder', () => {
        openDialog(
          <CreateSupplierOrderForm
            itemId={rowsIds}
            onClose={() => closeDialog('createSupplierOrderDialog')}
            data-testid={testIds.warehouse.purchaseList('createNewSupplierOrder')}
          />,
          {
            id: 'createSupplierOrderDialog',
            title: i18n.t('general.labels.createNew'),
            scrollBehavior: 'outside',
          }
        );
      })
      .with('addToSupplierOrder', () => {
        setSelectedRowIds(rowsIds);
        toggleAddToSupplierModal();
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const extractOrderId = (rows: RowData[]) =>
    rows.filter((row) => 'id' in row && isString(row.id)).map((row) => row.id);

  const handleRowSelectionChange = (rows: RowData[]) => {
    setSelectedRowIds(extractOrderId(rows));
  };

  return (
    <>
      <FullscreenDataGridWrapper
        externalFilterId={PURCHASE_LIST_EXTERNAL_FILTER_ID}
        folderTreeRef={folderTreeRef}
        externalFilterHeight={100}
        data-testid={testIds.warehouse.purchaseList('externalFilterWithFolderTree')}
      >
        <DataGrid
          // DG must be re-rendered on folder change to update the query modifier
          // eslint-disable-next-line no-restricted-syntax
          key={dataGridModifier?.key}
          gridCode="warehouse-purchase-list"
          externalFilterId={PURCHASE_LIST_EXTERNAL_FILTER_ID}
          actionCallback={actionCallback}
          onRowSelectionChange={handleRowSelectionChange}
          queryModifier={dataGridModifier?.queryModifier}
          _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'NATIVE'}}
          filterComponent={PurchaseListExternalFilter}
          data-testid={testIds.warehouse.purchaseList('list')}
        />
      </FullscreenDataGridWrapper>
      <Show when={isAddToSupplierModalVisible}>
        <AddToSupplierOrderModal
          itemId={selectedRowIds}
          onClose={toggleAddToSupplierModal}
          data-testid={testIds.warehouse.purchaseList('addItems')}
        />
      </Show>
    </>
  );
}
