import {themeIcons, ThemeIconKey} from 'platform/foundation';

import {Alpha3CountryCode} from './utils/countryCodes';

export type FlagIcons = {
  [key in Alpha3CountryCode]?: ThemeIconKey;
};

export const dialCodePrefix = '+';

export const flagIcons = Object.fromEntries(
  Object.keys(themeIcons)
    .filter((k) => k.startsWith('flags/'))
    .map((k) => [k.substring('flags/'.length), k] as const)
) as FlagIcons;
