import {Avatar, DataStatus, LayoutNotificationBackgroud} from 'platform/components';
import {Box, Clickable, HStack, Text, VStack} from 'platform/foundation';

import {useLazyGetDocument360RedirectUrlQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {OmneticReleaseNote} from '@omnetic-dms/releaseNotes';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

export function Release(props: OmneticReleaseNote & TestIdProps) {
  const [getDocument360RedirectUrl, {isLoading}] = useLazyGetDocument360RedirectUrlQuery();

  const handleRedirectToDoc360 = () =>
    getDocument360RedirectUrl({redirectTo: props.url})
      .unwrap()
      .then((res) => res.redirectUrl)
      .then(window.open)
      .catch(handleApiError);

  return (
    <DataStatus isLoading={isLoading}>
      <Clickable onClick={handleRedirectToDoc360}>
        <LayoutNotificationBackgroud>
          <Box minHeight={18} padding={2} overflow="hidden">
            <HStack spacing={2} justify="stretch">
              <Avatar icon={props.type === 'feature' ? 'action/stars' : 'action/bug_report'} />
              <VStack spacing={1} grow={1}>
                <HStack spacing={2}>
                  <Text
                    alternative
                    size="xSmall"
                    color="secondary"
                    inline
                    data-testid={suffixTestId('title', props)}
                  >
                    {props.title || i18n.t('general.labels.titleNotAvailable')}
                  </Text>
                </HStack>
                <Text size="xSmall" color="link" data-testid={suffixTestId('description', props)}>
                  {props.description || i18n.t('general.labels.descriptionNotAvailable')}
                </Text>
                <Text size="xSmall" color="secondary" data-testid={suffixTestId('dateTime', props)}>
                  {props.dateTime}
                </Text>
              </VStack>
            </HStack>
          </Box>
        </LayoutNotificationBackgroud>
      </Clickable>
    </DataStatus>
  );
}
