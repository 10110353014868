import {useEffect} from 'react';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {loadDocumentSeriesList, SeriesTypeEnum, useThunkDispatch} from '@omnetic-dms/teas';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {BusinessCaseSeriesDefinitionList} from './components/BusinessCaseSeriesDefinitionList';

export function BusinessCaseDefinition() {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(
      loadDocumentSeriesList({
        type: [SeriesTypeEnum.NEW_AND_USED_CARS_BUSINESS_CASE, SeriesTypeEnum.ACCOUNTING_INVOICE],
      })
    );
  }, [dispatch]);

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.salesSettingsBusinessCase.labels.businessCaseDefinition'),
        breadcrumbs: [
          {
            label: i18n.t('page.businessCases.title'),
            href: settingsRoutes.businessCase,
          },
        ],
      }}
      data-testid="settings-businessCases-definition"
      description={i18n.t('page.salesSettings.labels.businessCaseDefinitionDescription')}
    >
      <BusinessCaseSeriesDefinitionList />
    </SettingsTemplate>
  );
}
