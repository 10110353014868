import {Switch, SwitchProps} from 'platform/components';
import {Icon, Box, HStack, Show, Text} from 'platform/foundation';
import styled from 'styled-components';

import {ReactNode} from 'react';
import {Link} from 'react-router-dom';

import {TestIdProps} from 'shared';

export interface SettingsStackItemProps {
  title: string;
  url?: string;
  cyId?: string;
  switchProps?: SwitchProps;
}

export type SettingsStackItemsProps = {
  items: SettingsStackItemProps[];
};

export function SettingsStackItems(props: SettingsStackItemsProps) {
  return (
    <Wrapper>
      {props.items.map(({title, url, cyId, switchProps}) => (
        <StackItem key={url} to={url} data-testid={cyId}>
          <HStack align="center" width="100%" spacing={3}>
            <Show when={switchProps}>
              <div onClick={(e) => e.stopPropagation()}>
                <Switch value={!!switchProps?.value} {...switchProps} />
              </div>
            </Show>

            <Box flex={1}>
              <Text size="small">{title}</Text>
            </Box>

            <Show when={url}>
              <Icon value="navigation/chevron_right" data-testid={cyId} />
            </Show>
          </HStack>
        </StackItem>
      ))}
    </Wrapper>
  );
}

interface StackItemComponentProps extends TestIdProps {
  to?: string;
  children?: ReactNode;
}

function StackItemComponent(props: StackItemComponentProps) {
  if (!!props.to) {
    return <Link {...props} to={props.to} />;
  }
  return <div {...props} />;
}

export const StackItem = styled(StackItemComponent)`
  cursor: pointer;
  font-size: ${({theme}) => theme.fontSizes.text.small};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({theme}) => theme.radii.small};
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.35);
  padding: ${({theme}) => theme.getSize(3)} ${({theme}) => theme.getSize(4)};
  margin-bottom: ${({theme}) => theme.getSize(4)};

  a svg {
    width: 24px;
    height: 24px;
    color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: ${({theme}) => theme.radii.small};

  & > ${StackItem} {
    position: relative;
    box-shadow: none;
    margin-bottom: 0;
    border-radius: 0;

    &:not(:last-child)::before {
      position: absolute;
      margin: auto;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      content: '';
      border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
    }
  }
`;
