const getCssColor = (color: string) => `var(--eag-colors-palettes-${color})`;
const damageValueColorMap: Record<string, string> = {
  CRACKED: getCssColor('neutral-300-100'),
  FADED_HEADLIGHTS: getCssColor('blue-70-100'),
  MOISTURE_IN_THE_HEADLIGHT: getCssColor('green-60-100'),
  LOOSE_IN_THE_HOLDER: getCssColor('red-60-100'),
  FROM_STONES: getCssColor('orange-60-100'),
  OTHER_DEFINE_IN_THE_NOTE: getCssColor('neutral-300-100'),
  SCRATCHES: getCssColor('magenta-60-100'),
  DENTS: getCssColor('purple-60-100'),
  BAD_VARNISH: getCssColor('blue-30-100'),
  CORROSION: getCssColor('teal-30-100'),
  BAD_SAVING: getCssColor('green-30-100'),
  AFTER_UNPROFESSIONAL_REPAIR: getCssColor('red-30-100'),
  PEELED: getCssColor('yellow-60-100'),
  SCRATCHED: getCssColor('teal-60-100'),
  OTHER: getCssColor('magenta-30-100'),
  EXCESSIVE_WEAR: getCssColor('neutral-300-100'),
  EXCESSIVE_SOILING: getCssColor('blue-70-100'),
  DAMAGE: getCssColor('green-60-100'),
  SCRATCH: getCssColor('red-60-100'),
  FIRE_DAMAGE: getCssColor('orange-60-100'),
  FLUID_DAMAGE: getCssColor('yellow-60-100'),
  OTHER_DEFINE_IN_NOTE: getCssColor('magenta-30-100'),
};

export const getDamageValueColor = (damageValue?: string) =>
  damageValue ? damageValueColorMap[damageValue] : damageValueColorMap.OTHER;
