import type {CancelablePromise} from '../types/CancelablePromise';
import type {CostDetailItem} from '../types/CostDetailItem';
import type {CostPresetDetail} from '../types/CostPresetDetail';
import type {CostVehicleNote} from '../types/CostVehicleNote';
import type {UpdateCostVehicleNoteRequestBody} from '../types/UpdateCostVehicleNoteRequestBody';
import type {UserVehicleCostRequestBody} from '../types/UserVehicleCostRequestBody';
import type {UserVehicleCostsRequestBody} from '../types/UserVehicleCostsRequestBody';
import {request as __request} from '../utils/request';

export class CostService {
  /**
   * @returns CostPresetDetail
   * @throws ApiError
   */
  public static detailDefaultUserPresetCost({
    xBranch,
    authorization,
  }: {
    xBranch: string;
    authorization?: string;
  }): CancelablePromise<CostPresetDetail> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sourcing/cost/preset/_default',
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static createUserVehicleCost({
    sourcingVehicleId,
    xBranch,
    authorization,
    requestBody,
  }: {
    sourcingVehicleId: string;
    xBranch: string;
    authorization?: string;
    requestBody?: UserVehicleCostRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'POST',
      url: '/dms/v1/sourcing/cost/vehicle/{sourcingVehicleId}/item',
      path: {
        sourcingVehicleId,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static updateUserVehicleCosts({
    sourcingVehicleId,
    xBranch,
    authorization,
    requestBody,
  }: {
    sourcingVehicleId: string;
    xBranch: string;
    authorization?: string;
    requestBody?: UserVehicleCostsRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/sourcing/cost/vehicle/{sourcingVehicleId}',
      path: {
        sourcingVehicleId,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CostDetailItem
   * @throws ApiError
   */
  public static userCostVehicleList({
    sourcingVehicleId,
    xBranch,
    authorization,
  }: {
    sourcingVehicleId: string;
    xBranch: string;
    authorization?: string;
  }): CancelablePromise<Array<CostDetailItem>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sourcing/cost/vehicle/{sourcingVehicleId}',
      path: {
        sourcingVehicleId,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      shouldClearCacheEntry: true,
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static deleteUserVehicleCost({
    sourcingVehicleId,
    itemUuid,
    xBranch,
    authorization,
  }: {
    sourcingVehicleId: string;
    itemUuid: string;
    xBranch: string;
    authorization?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'DELETE',
      url: '/dms/v1/sourcing/cost/vehicle/{sourcingVehicleId}/item/{itemUuid}',
      path: {
        sourcingVehicleId,
        itemUuid,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static updateUserVehicleCost({
    sourcingVehicleId,
    itemUuid,
    xBranch,
    authorization,
    requestBody,
  }: {
    sourcingVehicleId: string;
    itemUuid: string;
    xBranch: string;
    authorization?: string;
    requestBody?: UserVehicleCostRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/sourcing/cost/vehicle/{sourcingVehicleId}/item/{itemUuid}',
      path: {
        sourcingVehicleId,
        itemUuid,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CostVehicleNote
   * @throws ApiError
   */
  public static detailUserCostVehicleNote({
    sourcingVehicleId,
    xBranch,
    authorization,
  }: {
    sourcingVehicleId: string;
    xBranch: string;
    authorization?: string;
  }): CancelablePromise<CostVehicleNote> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sourcing/cost/vehicle/{sourcingVehicleId}/note',
      path: {
        sourcingVehicleId,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static setUserVehicleCostNote({
    sourcingVehicleId,
    xBranch,
    authorization,
    requestBody,
  }: {
    sourcingVehicleId: string;
    xBranch: string;
    authorization?: string;
    requestBody?: UpdateCostVehicleNoteRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/sourcing/cost/vehicle/{sourcingVehicleId}/note',
      path: {
        sourcingVehicleId,
      },
      headers: {
        Authorization: authorization,
        'X-Branch': xBranch,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
