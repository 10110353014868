export enum FuelTypeEnum {
  FUELTYPE_PETROL = 'FUELTYPE_PETROL',
  FUELTYPE_DIESEL = 'FUELTYPE_DIESEL',
  FUELTYPE_ELECTRIC = 'FUELTYPE_ELECTRIC',
  FUELTYPE_LPG = 'FUELTYPE_LPG',
  FUELTYPE_CNG = 'FUELTYPE_CNG',
  FUELTYPE_HYBRID = 'FUELTYPE_HYBRID',
  FUELTYPE_HYDROGEN = 'FUELTYPE_HYDROGEN',
  FUELTYPE_ETHANOL = 'FUELTYPE_ETHANOL',
  FUELTYPE_OTHER = 'FUELTYPE_OTHER',
  FUELTYPE_LNG = 'FUELTYPE_LNG',
}
