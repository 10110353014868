/* eslint-disable filename-export/match-named-export */
import {createContext} from 'shared';

export type TranslationInterpreter = (key: string, ...args: unknown[]) => string;

export const [TranslationContextProvider, useTranslationContext] =
  createContext<TranslationInterpreter>({
    strict: true,
    name: 'TranslationContext',
  });
