import {createSearchParams} from 'react-router-dom';

import {isNotNil, pickBy} from 'ramda';
import {isEmptyString} from 'ramda-adjunct';

export function createSearchParamsString(params: Record<string, string | undefined>) {
  const searchParamsString = createSearchParams(pickBy(isNotNil, params)).toString();

  if (isEmptyString(searchParamsString)) {
    return '';
  }

  return `?${createSearchParams(pickBy(isNotNil, params)).toString()}`;
}
