import {any, isNil, not, pick, pipe, values} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {AddressRequestBodyV2} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const isAddressCompleted = (addressData: AddressRequestBodyV2 | Nullish) => {
  if (isNil(addressData)) {
    return false;
  }
  const pickedValues = {
    ...pick(['street', 'city', 'zip', 'country'])(addressData.address),
  };
  return pipe<[typeof pickedValues], (string | Nullish)[], boolean, boolean>(
    values,
    any(isNilOrEmpty),
    not
  )(pickedValues);
};
