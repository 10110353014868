import {OptionTypeBase} from 'platform/components';
import {Center} from 'platform/foundation';

import {FC, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {isEmpty, slice} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {getNaturalPersonFullName} from '@omnetic-dms/shared';

import {TestIdProps} from 'shared';

import {SearchUnassignedUsersService} from '../../../services/SearchUnassignedUsersService';
import {fetchAssignmentsRequest} from '../../../store/assignment/reducer';
import {getListOfAssignments} from '../../../store/assignment/selectors';
import {UsersRequestType} from '../../../store/assignment/types';
import {AssignmentUser} from '../../../types/AssignmentUser';
import {useCallApi} from '../../../utils/api';
import {external} from '../types/AssignmentType';
import {AssignmentButton} from './AssignButton';
import {AssignmentContext} from './AssignmentContext';
import {AssignmentCreatePopup} from './AssignmentCreatePopup';
import {AssignmentOption} from './AssignOption';
import {AssignmentUsers} from './AssignUsers';

export const AssignmentContainer: FC<TestIdProps> = (props) => {
  const context = useContext(AssignmentContext);
  const [showCreatePopup, setCreatePopup] = useState(false);
  const dispatch = useDispatch();
  const searchUnassignedUsers = useCallApi(SearchUnassignedUsersService.searchUnassignedUsers);

  const assignments = useSelector(getListOfAssignments(context.type, context.assignmentIdentityId));

  useEffect(() => {
    if (context.assignmentIdentityId) {
      dispatch(
        fetchAssignmentsRequest({
          assignmentIdentityType: context.type,
          assignmentIdentityId: context.assignmentIdentityId,
        })
      );
    }
  }, [dispatch, context.assignmentIdentityId]);

  const makeOptions = (users: AssignmentUser[]): OptionTypeBase<any>[] => {
    const data = users.map((user) => ({
      label: getNaturalPersonFullName(user) || user.email || '',
      value: user.id,
      data: user,
    }));
    if (context.external === true || context.external === null) {
      data.unshift({
        label: `+ ${i18n.t('general.labels.createExternalUser')}`,
        value: external,
        data: {} as AssignmentUser,
      });
    }
    return data;
  };

  const makeSearch = (inputValue: string): Promise<OptionTypeBase<any>[]> => {
    let queryOptions: UsersRequestType;
    const searchableValue: string | undefined = isEmpty(inputValue) ? undefined : inputValue;

    if (context.external === null) {
      // External + Core users
      queryOptions = {
        search: searchableValue,
        assignmentIdentityType: context.type,
        assignmentIdentityId: context.assignmentIdentityId,
      };
    } else {
      // External or Core users (depends on value)
      queryOptions = {
        search: searchableValue,
        assignmentIdentityType: context.type,
        assignmentIdentityId: context.assignmentIdentityId,
        external: context.external,
      };
    }

    return new Promise((resolve) => {
      searchUnassignedUsers(queryOptions).then((res) => resolve(makeOptions(res)));
    });
  };

  return (
    <Center>
      {/*<AssignmentOwner data-testid={props['data-testid']} />*/}
      {showCreatePopup && (
        <AssignmentCreatePopup
          data-testid={props['data-testid']}
          modalEvent={(value) => setCreatePopup(value ?? false)}
        />
      )}
      {!!assignments?.length && (
        <AssignmentUsers data-testid={props['data-testid']} assignment={assignments?.[0]} />
      )}
      {/*TODO if user list more then 2 show "AssignmentOption" */}
      {assignments?.length > 1 && (
        <AssignmentOption
          data-testid={props['data-testid']}
          assignments={slice(1, Infinity, assignments)}
        />
      )}
      <AssignmentButton
        data-testid={props['data-testid']}
        modalEvent={(value) => setCreatePopup(value ?? false)}
        loadOptions={makeSearch}
      />
    </Center>
  );
};
