import * as Yup from 'yup';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotDate, isNotNumber, isNotString} from 'ramda-adjunct';

export const yupString = Yup.string()
  .transform((currentValue, originalValue) => {
    if (isNotString(originalValue) || isNilOrEmpty(originalValue)) {
      return null;
    }
    return currentValue;
  })
  .nullable()
  .trim();

export const yupNumber = Yup.number()
  .transform((currentValue, originalValue) => {
    if (isNotNumber(originalValue) || isNil(originalValue)) {
      return null;
    }
    return currentValue;
  })
  .nullable();

export const yupDate = Yup.date()
  .transform((currentValue, originalValue) => {
    if (isNotDate(originalValue) || isNilOrEmpty(originalValue)) {
      return null;
    }
    return currentValue;
  })
  .nullable();
