export const tireInventorySearchParams = {
  tireOrderDetial: {
    setOfTires: 'setOfTires',
    setOfTireTabs: {
      tires: 'tires',
      tiresMovement: 'tiresMovement',
      archived: 'archived',
    },
    customer: 'customer',
    vehicle: 'vehicle',
    documents: 'documents',
  },
};
