import type {BillingInformation} from '../types/BillingInformation';
import type {BillingInformationList} from '../types/BillingInformationList';
import type {CancelablePromise} from '../types/CancelablePromise';
import type {CreateBillingInformationRequestBody} from '../types/CreateBillingInformationRequestBody';
import type {PutBillingInformationRequestBody} from '../types/PutBillingInformationRequestBody';
import {request as __request} from '../utils/request';

export class BillingInformationService {
  /**
   * @returns BillingInformation
   * @throws ApiError
   */
  public static createBillingInformation({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: CreateBillingInformationRequestBody;
  }): CancelablePromise<BillingInformation> {
    return __request({
      method: 'POST',
      url: '/dms/v1/tenant/billing-information',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns BillingInformationList
   * @throws ApiError
   */
  public static getBillingInformationList({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<BillingInformationList> {
    return __request({
      method: 'GET',
      url: '/dms/v1/tenant/billing-information',
      headers: {
        Authorization: authorization,
      },
      shouldClearCacheEntry: true,
    });
  }

  /**
   * @returns BillingInformation
   * @throws ApiError
   */
  public static getBillingInformation({
    billingInformationId,
    authorization,
  }: {
    billingInformationId: string;
    authorization?: string;
  }): CancelablePromise<BillingInformation> {
    return __request({
      method: 'GET',
      url: '/dms/v1/tenant/billing-information/{billingInformationId}',
      path: {
        billingInformationId,
      },
      headers: {
        Authorization: authorization,
      },
      shouldClearCacheEntry: true,
    });
  }

  /**
   * @returns BillingInformation
   * @throws ApiError
   */
  public static putBillingInformation({
    billingInformationId,
    authorization,
    requestBody,
  }: {
    billingInformationId: string;
    authorization?: string;
    requestBody?: PutBillingInformationRequestBody;
  }): CancelablePromise<BillingInformation> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/tenant/billing-information/{billingInformationId}',
      path: {
        billingInformationId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
