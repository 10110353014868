/**
 * @deprecated Only for sourcing
 */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {MakeVehicleType as DmsVehicleType} from '../../types/MakeVehicleType';

export type CatalogType = {
  carFeatersGreatestImpact?: string[];
};

export type CatalogSliceType = CatalogType & {
  customMakes: Record<string, DmsVehicleType>;
};

const initialState: CatalogSliceType = {
  carFeatersGreatestImpact: [],
  customMakes: {},
};

const catalogSlice = createSlice({
  name: 'catalogue',
  initialState,
  reducers: {
    createCustomMake: (state, {payload}: PayloadAction<{makeKey: string; vehicleType: string}>) => {
      const {makeKey, vehicleType} = payload;

      if (!state.customMakes[vehicleType]) {
        state.customMakes[vehicleType] = {
          makes: {},
        };
      }

      state.customMakes[vehicleType].makes[makeKey] = {
        isCustom: true,
        models: {},
      };
    },
    createCustomModel: (
      state,
      {payload}: PayloadAction<{makeKey: string; modelKey: string; vehicleType: string}>
    ) => {
      const {modelKey, vehicleType, makeKey} = payload;

      if (!state.customMakes[vehicleType].makes[makeKey]) {
        return;
      }

      state.customMakes[vehicleType].makes[makeKey].models = {
        ...state.customMakes[vehicleType].makes[makeKey].models,
        [modelKey]: {isCustom: true},
      };
    },
    setCarFeatures: (state, {payload}: PayloadAction<string[]>) => {
      state.carFeatersGreatestImpact = payload;
    },
  },
});

const {reducer, actions} = catalogSlice;
export const catalogueReducer = reducer;
export const catalogueActions = actions;
