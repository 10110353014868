import {useEffect} from 'react';

import {useBoolean} from 'shared';

export function useIsActiveBrowserTab() {
  const [isActive, onActive, onInactive] = useBoolean(document.visibilityState === 'visible');

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        onActive();
      } else {
        onInactive();
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, [onActive, onInactive]);

  return isActive;
}
