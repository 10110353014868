import {TimeType} from 'platform/components';

function padZero(number: number) {
  return number.toString().padStart(2, '0');
}

export const numberToTimeString = (timeNumber: number) => {
  const hours = Math.floor(timeNumber);
  const minutes = Math.round((timeNumber - hours) * 60);

  return `${padZero(hours)}:${padZero(minutes)}` as TimeType;
};
