import {yupResolver} from '@hookform/resolvers/yup';
import {Button, ButtonGroup, Radio, Tooltip, showNotification} from 'platform/components';
import {Box, Clickable, Icon} from 'platform/foundation';
import styled, {css} from 'styled-components';
import * as Yup from 'yup';

import {FC, PropsWithChildren, ReactElement, useEffect, useRef} from 'react';
import {Controller, SubmitErrorHandler, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {isEmpty} from 'ramda';

import {CreateUserApiArg, selectBranchList, useCreateUserMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {userPinItems} from '@omnetic-dms/shared';
import {
  ADMINISTRATOR_ROLE,
  CUSTOM_ROLE,
  Checkbox,
  CheckboxTree,
  InfoCard,
  RowType,
  TextField,
  adminRoleSelector,
  allRolesListWithoutAdmin,
  fetchFullRoleRequest,
  selectUserDefaultBranch,
} from '@omnetic-dms/teas';

import {composePath} from 'shared';

const BranchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

interface AddUserForm {
  admin?: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  password2: string;
  roles: string[];
  branchIds: string[];
  defaultBranchId: string;
}

export const AddUserForm: FC<PropsWithChildren<any>> = (): ReactElement => {
  const isDefaultSet = useRef<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUserDefaultBranch = useSelector(selectUserDefaultBranch);
  const roleList = useSelector(allRolesListWithoutAdmin);
  const adminRole = useSelector(adminRoleSelector);
  const {data: branchList} = useSelector(selectBranchList);
  const [createUser] = useCreateUserMutation();

  const {
    register,
    control,
    watch,
    formState: {errors},
    handleSubmit,
    setValue,
  } = useForm<AddUserForm>({
    resolver: yupResolver(
      // eslint-disable-next-line no-restricted-syntax
      Yup.lazy((values: AddUserForm) => {
        if (values.admin === ADMINISTRATOR_ROLE) {
          return addUserValidationSchema(false);
        }
        return addUserValidationSchema(true);
      }) as unknown as Yup.AnyObjectSchema // TODO: validate
    ),
    reValidateMode: 'onBlur',
    defaultValues: {
      roles: [],
      branchIds: [],
    },
    shouldFocusError: false,
  });

  const roleStatus = watch('admin');
  const selected = watch('roles');
  const selectedBranches = watch('branchIds');
  const defaultBranch = watch('defaultBranchId');

  const onSubmit = (data: AddUserForm) => {
    let submitData = data;

    if (roleStatus === ADMINISTRATOR_ROLE) {
      submitData = {...data, roles: [adminRole?.[0]?.id]};
    }

    if ('admin' in submitData) {
      delete submitData.admin;
    }

    const arg = {
      createUserRequestBody: {...submitData, pins: userPinItems},
    } as CreateUserApiArg;

    createUser(arg)
      .unwrap()
      .then((res) => {
        if (res && res?.id) {
          showNotification.success();
          navigate(composePath(settingsRoutes.userManagementDetail, {params: {id: res?.id}}), {
            state: {ignoreUnmountEvents: true},
          });
        }
      })
      .catch((error) => {
        showNotification.error(error?.data?.error?.message);
      });
  };

  const onSubmitError: SubmitErrorHandler<AddUserForm> = (errs) => {
    const errorMessages = Object.values(errs).map((field) => field.message);
    errorMessages.forEach((message) => showNotification.warning(message));
  };

  const footerContent = (
    <ButtonGroup align="right">
      <Button
        variant="secondary"
        data-testid={testIds.settings.userManagementAddUser('footer-discardAction')}
        onClick={() => {
          navigate(settingsRoutes.userManagement);
        }}
        title={i18n.t('general.actions.back')}
      />
      <Button
        variant="primary"
        type="submit"
        data-testid={testIds.settings.userManagementAddUser('footer-createAction')}
        title={i18n.t('general.actions.create')}
      />
    </ButtonGroup>
  );

  useEffect(() => {
    dispatch(fetchFullRoleRequest);
  }, [dispatch]);

  const nodes = roleList?.map((item) => ({
    label: item.title,
    value: item.id,
    children: [],
  }));

  const toggleListItem = (a: string[], v: string) => {
    const i = a.indexOf(v);
    if (i === -1) {
      a.push(v);
    } else {
      a.splice(i, 1);
    }

    return a;
  };

  const handleBranchSelect = (id: string) => {
    let _defaultBranch: undefined | string = defaultBranch;

    const newArray = toggleListItem(selectedBranches, id);

    if (!newArray.includes(_defaultBranch)) {
      _defaultBranch =
        branchList?.branchListItems?.find((b) => newArray.includes(b.id))?.id ?? undefined;
    }

    _defaultBranch && setValue('defaultBranchId', _defaultBranch);

    return newArray;
  };

  const addUserContent: RowType[] = [
    {
      key: 'firstName',
      label: <>{i18n.t('entity.person.labels.firstName')} *</>,
      render: (
        <Controller
          render={({field}) => (
            <TextField
              {...field}
              variant="inline"
              data-testid={testIds.settings.userManagementAddUser('firstName')}
              type="text"
              error={Boolean(errors.firstName?.message)}
              placeholder={`${i18n.t('entity.user.labels.typeFirstName')}... `}
            />
          )}
          control={control}
          name="firstName"
        />
      ),
    },
    {
      key: 'lastName',
      label: <>{i18n.t('entity.person.labels.lastName')} *</>,
      render: (
        <Controller
          render={({field}) => (
            <TextField
              {...field}
              variant="inline"
              data-testid={testIds.settings.userManagementAddUser('lastName')}
              error={Boolean(errors.lastName?.message)}
              type="text"
              placeholder={`${i18n.t('entity.user.labels.typeLastName')}... `}
            />
          )}
          control={control}
          name="lastName"
        />
      ),
    },
    {
      key: 'email',
      label: <>{i18n.t('entity.user.labels.emailUsername')} *</>,
      render: (
        <Controller
          render={({field}) => (
            <TextField
              {...field}
              variant="inline"
              error={Boolean(errors.email?.message)}
              data-testid={testIds.settings.userManagementAddUser('email')}
              placeholder={`${i18n.t('entity.user.labels.typeUserEmail')}... `}
              type="text"
            />
          )}
          control={control}
          name="email"
        />
      ),
    },
    {
      key: 'password',
      label: <>{i18n.t('general.labels.password')} *</>,
      render: (
        <Controller
          render={({field}) => (
            <TextField
              {...field}
              variant="inline"
              error={Boolean(errors.password?.message)}
              data-testid={testIds.settings.userManagementAddUser('password')}
              placeholder={`${i18n.t('general.labels.setPassword')}... `}
              type="password"
            />
          )}
          control={control}
          name="password"
        />
      ),
    },
    {
      key: 'confirmPassword',
      label: <>{i18n.t('general.labels.confirmPassword')} *</>,
      render: (
        <Controller
          render={({field}) => (
            <TextField
              {...field}
              variant="inline"
              type="password"
              data-testid={testIds.settings.userManagementAddUser('password2')}
              error={Boolean(errors.password2?.message)}
              placeholder={`${i18n.t('general.labels.confirmSetPassword')}... `}
            />
          )}
          control={control}
          name="password2"
        />
      ),
      divider: true,
    },
    {
      key: 'userType',
      label: i18n.t('entity.user.labels.userType'),
      render: (
        <MainRolesWrapper>
          <Controller
            name="admin"
            render={({field}) => (
              <Radio
                value={field.value ?? null}
                options={[
                  {
                    value: CUSTOM_ROLE,
                    label: i18n.t('entity.user.labels.regularUser'),
                  },
                  {
                    value: ADMINISTRATOR_ROLE,
                    label: i18n.t('page.settings.labels.administrator'),
                  },
                ]}
                onChange={field.onChange}
                onBlur={field.onBlur}
                data-testid={testIds.settings.userManagementAddUser('roleTypeRatio')}
              />
            )}
            control={control}
            defaultValue={CUSTOM_ROLE}
            data-testid={testIds.settings.userManagementAddUser('roleRadio')}
          />
        </MainRolesWrapper>
      ),
    },
  ];

  useEffect(() => {
    if (branchList?.branchListItems.length && !isDefaultSet.current) {
      isDefaultSet.current = true;

      const foundBranch = branchList?.branchListItems.find(
        (br) => br.id === currentUserDefaultBranch?.id
      );

      if (foundBranch) {
        handleBranchSelect(foundBranch.id);
      }
    }
  }, [branchList]);

  /*
		Please pay ATTENTION
		In user creation we use roles as string[]
		But in all other cases it's UserRole[]
	*/
  if (roleList?.length && roleStatus !== ADMINISTRATOR_ROLE) {
    addUserContent.push({
      key: 'assignedRole',
      label: <>{i18n.t('page.settings.labels.assignedRole')} *</>,
      alignItems: 'flex-start',
      render: (
        <Controller
          name="roles"
          render={({field}) => (
            <CheckboxTree
              {...field}
              data-testid={testIds.settings.userManagementAddUser('assignedRole')}
              margin={8}
              selected={selected}
              nodes={nodes}
              onSelect={(value: string) =>
                setValue('roles', toggleListItem(selected, value), {shouldDirty: true})
              }
            />
          )}
          control={control}
        />
      ),
    });
  }

  if (branchList?.branchListItems?.length) {
    addUserContent.push({
      key: 'branches',
      label: i18n.t('entity.branch.labels.branches'),
      alignItems: 'flex-start',
      render: (
        <Controller
          name="branchIds"
          control={control}
          render={(props) => (
            <div
              className="branchList"
              data-testid={testIds.settings.userManagementAddUser('assignedBranch')}
            >
              <input type="hidden" {...register('defaultBranchId')} />
              {branchList?.branchListItems.map((branch, index) => (
                <BranchWrapper
                  key={branch.id}
                  data-testid={testIds.settings.userManagementAddUser(
                    `branchWrapper-[${String(index)}]`
                  )}
                >
                  {Object.values(selectedBranches).filter((b) => b).length >= 2 && (
                    <div
                      css={css`
                        min-width: 24px;
                      `}
                    >
                      {selectedBranches.includes(branch.id) && (
                        <Tooltip
                          label={i18n.t('entity.branch.labels.defaultBranch')}
                          placement="top"
                        >
                          <Clickable onClick={() => setValue('defaultBranchId', branch.id)}>
                            <BranchStarIcon
                              data-testid={testIds.settings.userManagementAddUser(
                                `branchDefault-[${index}]`
                              )}
                              active={branch.id === defaultBranch}
                            />
                          </Clickable>
                        </Tooltip>
                      )}
                    </div>
                  )}
                  <div
                    data-testid={testIds.settings.userManagementAddUser(
                      `checkboxWrapper-[${index}]`
                    )}
                  >
                    <Checkbox
                      css={css`
                        margin: 2px;
                      `}
                      checked={selectedBranches.includes(branch.id)}
                      onChange={() => {
                        props.field?.onChange?.(handleBranchSelect(branch.id));
                      }}
                      data-testid={testIds.settings.userManagementAddUser(`checkbox-[${index}]`)}
                      name={`branch-checkbox-[${index}]`}
                      label={branch.marketingName}
                    />
                  </div>
                </BranchWrapper>
              ))}
            </div>
          )}
        />
      ),
    });
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onSubmitError)}
      data-testid={testIds.settings.userManagementAddUser('addForm')}
    >
      <InfoCard
        title={i18n.t('entity.user.actions.addUser')}
        footerContent={footerContent}
        rows={addUserContent}
        loading={isEmpty(roleList)}
      />
    </form>
  );
};

const firstNameLastNameValidation = (field: string, error_message: string) =>
  Yup.string()
    .required(`${field} ${i18n.t('general.notifications.errorSpecFieldRequired')}`)
    .matches(/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF-\s.]+$/i, {
      message: error_message,
    })
    .max(60, error_message);

const SPECIFIC_FIELD_REQUIRED_MESSAGE = (fieldName: string) =>
  `${fieldName} ${i18n.t('general.notifications.errorSpecFieldRequired')}`;

const stringRequiredValidation = (field: string): Yup.StringSchema =>
  Yup.string().required(SPECIFIC_FIELD_REQUIRED_MESSAGE(field) as string);

const confirmPassword = stringRequiredValidation('Confirm password').oneOf(
  [Yup.ref('password'), null],
  i18n.t('general.notifications.errorPasswordsNotMatch')
);

const passwordValidation = Yup.string()
  .required(i18n.t('general.notifications.errorPasswordsRequired'))
  .min(8, i18n.t('general.notifications.errorPasswordsLength'))
  .matches(/^(?:[A-Z]|[a-z]|[0-9]|.){8,}$/, {
    message: i18n.t('general.notifications.errorPasswordsFormat'),
  })
  .matches(/[A-Z]/, {
    message: i18n.t('general.notifications.errorPasswordsUppercase'),
  })
  .matches(/[a-z]/, {
    message: i18n.t('general.notifications.errorPasswordsLowercase'),
  })
  .matches(/[0-9]+/, {
    message: i18n.t('general.notifications.errorPasswordsDigit'),
  });

const addUserValidationSchema = (isRolesRequired: boolean): Yup.SchemaOf<AddUserForm> =>
  // eslint-disable-next-line no-restricted-syntax
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(i18n.t('general.notifications.errorEmailInvalid'))
      .required(i18n.t('general.notifications.errorEmailRequired'))
      .max(320, i18n.t('general.notifications.errorEmailMax'))
      .nullable(),
    firstName: firstNameLastNameValidation(
      'First name',
      i18n.t('general.notifications.invalidFirstName')
    ),
    lastName: firstNameLastNameValidation(
      'Last name',
      i18n.t('general.notifications.invalidLastName')
    ),
    password: passwordValidation.nullable(),
    password2: confirmPassword,
    ...(isRolesRequired ? {roles: Yup.array(Yup.string()).min(1)} : {}),
    branchIds: Yup.array().min(1, i18n.t('entity.user.validation.requiredBranch')),
    defaultBranchId: Yup.string().required(i18n.t('entity.user.validation.requiredDefaultBranch')),
  }) as unknown as Yup.SchemaOf<AddUserForm>;

const BranchStarIcon = ({active}: {active: boolean}) => (
  <Clickable>
    <Box paddingRight={1}>
      <Icon
        value="toggle/star"
        color={active ? 'palettes.blue.60.100' : 'palettes.neutral.70.40'}
      />
    </Box>
  </Clickable>
);

const MainRolesWrapper = styled.div`
  padding: 4px;
`;
