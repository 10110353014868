/* eslint-disable no-nested-ternary */
import {OptionTypeBase} from 'platform/components';
import {Box, Icon} from 'platform/foundation';
import styled, {DefaultTheme} from 'styled-components';

import {Fragment, ReactElement} from 'react';
import {
  components,
  ControlProps,
  GroupBase,
  InputProps,
  MenuListProps,
  MenuProps,
  OptionProps,
  Props,
} from 'react-select';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {convertStringToCamelCase, suffixTestId, TestIdProps} from 'shared';

export const SearchDropdownWrapper = styled.div`
  width: 100%;
  background: ${({theme}) => theme.colors.palettes.white[10][100]};
  border: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  border-radius: ${({theme}) => theme.radii.small};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
`;

const SearchDropdownOptionWrapper = styled.div`
  color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  width: 95%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const LightPart = styled.span`
  color: ${({theme}) => theme.colors.palettes.neutral[90][100]};
`;

export const SearchDropdownInput = (props: InputProps): ReactElement => (
  <components.Input {...props} data-testid="searchDropdown-dropdownInput" />
);

export const SearchDropdownMenu = (props: MenuProps<OptionTypeBase<any>, false>) => (
  <components.Menu {...props} data-testid="searchDropdown-dropdownMenu" />
);

export const SearchDropdownMenuList =
  (testProps: {'data-testid'?: string}) =>
  (
    props: MenuListProps<
      SearchDropdownDropdownPropsTypeOption,
      false,
      GroupBase<SearchDropdownDropdownPropsTypeOption>
    >
  ) => (
    <components.MenuList {...props}>
      <div data-testid={suffixTestId(`options-wrapper`, testProps)}>{props.children}</div>
    </components.MenuList>
  );

type ControlPropsWithTheme = ControlProps<
  OptionTypeBase<any>,
  false,
  GroupBase<SearchDropdownDropdownPropsTypeOption>
> & {
  theme?: DefaultTheme;
};

export const SearchDropdownControl = (props: ControlPropsWithTheme): ReactElement => (
  <Box padding={2}>
    <components.Control {...props}>
      <Box paddingHorizontal={2}>
        <Icon value="action/search" />
      </Box>
      {props.children}
    </components.Control>
  </Box>
);

export const SearchDropdownOption =
  (testProps: {'data-testid'?: string}) =>
  ({
    label,
    selectProps,
    ...rest
  }: OptionProps<SearchDropdownDropdownPropsTypeOption, false>): ReactElement => {
    const {data, getValue} = rest;
    const {inputValue, searchKey, formatOptionLabel} = selectProps as typeof selectProps & {
      searchKey?: string;
    };
    const searchIn = typeof searchKey === 'string' ? data[searchKey] : label;
    const parts = (searchIn?.split && searchIn.split(new RegExp(`(${inputValue})`, 'gi'))) || [];

    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <div data-testid={suffixTestId(`option-${convertStringToCamelCase(label)}`, testProps)}>
        <components.Option {...rest} selectProps={selectProps} label={label}>
          <SearchDropdownOptionWrapper>
            {isNotNilOrEmpty(inputValue)
              ? parts.map((part: string) => {
                  const str = part.replace(/ /g, '\u00a0');

                  return (
                    <Fragment key={part}>
                      {part.toLowerCase() === (inputValue as string).toLowerCase() ? (
                        str
                      ) : (
                        <LightPart>{str}</LightPart>
                      )}
                    </Fragment>
                  );
                })
              : formatOptionLabel
                ? formatOptionLabel?.(data, {
                    inputValue: inputValue ?? '',
                    context: 'value',
                    // eslint-disable-next-line no-restricted-syntax
                    selectValue: getValue(),
                  })
                : label}
          </SearchDropdownOptionWrapper>
        </components.Option>
      </div>
    );
  };

export type SearchDropdownDropdownPropsTypeOption =
  | {label: string; value: string; [key: string]: string}
  | {[key: string]: string};

export type SearchDropdownSearchDropdownProps = {
  enterPreventDefault?: boolean;
  name?: string;
  options: Array<OptionTypeBase<any> | GroupBase<OptionTypeBase<any>>>;
  searchKey?: string;
} & Omit<Props<OptionTypeBase<any>>, 'options'> &
  TestIdProps;
