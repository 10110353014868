import {number, object, SchemaOf, string} from 'yup';

import {DimensionsRequestBody} from '../types/DimensionsRequestBody';
import {$DimensionsSpecificationRequestBody} from './$DimensionsSpecificationRequestBody';

export const $DimensionsRequestBody: SchemaOf<DimensionsRequestBody> = object().shape({
  dimensions: $DimensionsSpecificationRequestBody.nullable(),
  dimensionsLoadingSpace: $DimensionsSpecificationRequestBody.nullable(),
  loadingArea: number().nullable(),
  maximalLoadCapacity: number().nullable(),
  operatingWeight: number().nullable(),
  trunkSpace: number().nullable(),
  weight: number().nullable(),
  wheelBase: number().nullable(),
  heightCategory: string().nullable(),
  lengthCategory: string().nullable(),
});
