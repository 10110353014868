import {DataStatus, showNotification} from 'platform/components';

import {
  usePatchDistraintRegisterConfigMutation,
  usePatchEuViesConfigMutation,
  usePatchInsolvencyRegisterConfigMutation,
  usePatchInvalidDocumentRegisterConfigMutation,
  usePatchUnreliableVatPayerRegisterConfigMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError, useAvailableVerificationRegisters} from '@omnetic-dms/shared';

import {
  SettingsStackItemProps,
  SettingsStackItems,
} from '../../../components/SettingsStackItems/SettingsStackItems';

export function CustomerVerificationList() {
  const {registers, isLoading, isError} = useAvailableVerificationRegisters();
  const [patchDistraintRegister] = usePatchDistraintRegisterConfigMutation();
  const [patchInsolvencyRegister] = usePatchInsolvencyRegisterConfigMutation();
  const [patchInvalidDocumentRegister] = usePatchInvalidDocumentRegisterConfigMutation();
  const [patchUnreliableVatPayerRegister] = usePatchUnreliableVatPayerRegisterConfigMutation();
  const [patchEuVies] = usePatchEuViesConfigMutation();

  const saveSwitchPosition = (mutation: typeof patchEuVies, enabled: boolean) => {
    mutation({body: {enabled}})
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  const items: SettingsStackItemProps[] = [
    {
      cyId: testIds.settings.customerVerificationServices('unreliablePayersRegister'),
      title: i18n.t('entity.customerVerification.labels.registers.unreliable_vat_payer'),
      switchProps: {
        name: 'customerVerification-unreliablePayersRegister',
        value: registers.includes('unreliable_vat_payer'),
        onChange: (enabled) => saveSwitchPosition(patchUnreliableVatPayerRegister, enabled),
      },
    },
    {
      cyId: testIds.settings.customerVerificationServices('insolvencyRegister'),
      title: i18n.t('entity.customerVerification.labels.registers.insolvency'),
      switchProps: {
        name: 'customerVerification-insolvencyRegister',
        value: registers.includes('insolvency'),
        onChange: (enabled) => saveSwitchPosition(patchInsolvencyRegister, enabled),
      },
    },
    {
      cyId: testIds.settings.customerVerificationServices('invalidDocumentRegister'),
      title: i18n.t('entity.customerVerification.labels.registers.invalid_document'),
      switchProps: {
        name: 'customerVerification-invalidDocumentRegister',
        value: registers.includes('invalid_document'),
        onChange: (enabled) => saveSwitchPosition(patchInvalidDocumentRegister, enabled),
      },
    },
    {
      cyId: testIds.settings.customerVerificationServices('euViesRegister'),
      title: i18n.t('entity.customerVerification.labels.registers.eu_vies'),
      switchProps: {
        name: 'customerVerification-euViesRegister',
        value: registers.includes('eu_vies'),
        onChange: (enabled) => saveSwitchPosition(patchEuVies, enabled),
      },
    },
    {
      cyId: testIds.settings.customerVerificationServices('centralExecutionRecords'),
      title: i18n.t('entity.customerVerification.labels.registers.distraint'),
      url: settingsRoutes.customerVerificationDistraints,
      switchProps: {
        name: 'customerVerification-centralExecutionRecords',
        value: registers.includes('distraint'),
        onChange: (enabled) => saveSwitchPosition(patchDistraintRegister, enabled),
      },
    },
  ];

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <SettingsStackItems items={items} />
    </DataStatus>
  );
}
