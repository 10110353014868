import {Label} from 'platform/components';
import {Hide, HStack, Show, VStack} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {isTrue} from 'ramda-adjunct';

import {TestIdProps} from 'shared';

import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {getTranslation} from '../utils/getTranslation';
import {AuditVideos} from './AuditVideos';
import {CardImageUpload} from './CardImageUpload';

interface PhotoDocumentationCardProps extends TestIdProps {
  label?: Record<string, string> | null;
  additionalLabel?: string;
  multiple?: boolean;
  categoryId: string;
  paramDefinition: AuditParamDefinition;
  isVideo?: boolean;
}

export function PhotoDocumentationCard(props: PhotoDocumentationCardProps) {
  const locale = useSelector(selectUserSelectedLanguage);

  const label = props.additionalLabel
    ? props.additionalLabel
    : getTranslation(locale, props.label ?? undefined);

  return (
    <VStack spacing={2}>
      <HStack spacing={2} align="center">
        <Label
          currentLength={label?.length ?? 0}
          isRequired={props.paramDefinition.mandatory}
          data-testid={props['data-testid']}
        >
          {label}
        </Label>
      </HStack>
      <Hide when={isTrue(props.isVideo)}>
        <CardImageUpload
          multiple={props.multiple}
          isAlwaysShowUpload={props.multiple}
          categoryId={props.categoryId}
          paramDefinitionId={props.paramDefinition.id}
          mandatory={props.paramDefinition.mandatory}
          ratio="1"
          data-testid={props['data-testid']}
        />
      </Hide>
      <Show when={isTrue(props.isVideo)}>
        <AuditVideos
          categoryId={props.categoryId}
          paramDefinitionId={props.paramDefinition.id}
          isMandatory={props.paramDefinition.mandatory}
          ratio="1"
          data-testid={props['data-testid']}
        />
      </Show>
    </VStack>
  );
}
