import {Button, ButtonGroup, Dialog, Label, showNotification} from 'platform/components';
import {Box, HStack, Show, Space} from 'platform/foundation';

import {ReactElement, useContext, useState} from 'react';
import {useSelector} from 'react-redux';

import {equals, isNil} from 'ramda';

import {
  IdentityCardResponseBodyV2,
  PersonRequestBody,
  PersonResponseBodyV2,
  usePatchCustomerContactMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';
import {
  FieldName,
  FormContext,
  IdentityCard,
  IdentityCardData,
  PossibleObject,
  getPersonIdentityCard,
  selectCountries,
  selectIdentityCardTypes,
  useFormRenderer,
} from '@omnetic-dms/teas';

import {TestIdProps} from 'shared';

import {IdentityCardForm} from './IdentityCardForm';

type IdentityCardSelectProps<T extends PossibleObject> = {
  name: FieldName<T, undefined>;
  label: string;
  identityCards?: IdentityCardResponseBodyV2[];
  initialValue?: IdentityCardResponseBodyV2 | null;
  showRemove?: boolean;
  handleRemoveIdentityCard?: () => void;
  person: PersonResponseBodyV2;
  customerId: string;
} & TestIdProps;

export const IdentityCardSelect = <T extends PossibleObject>({
  label,
  identityCards,
  initialValue,
  showRemove,
  handleRemoveIdentityCard,
  ...props
}: IdentityCardSelectProps<T>): ReactElement => {
  const [patchCustomerContact, {isLoading}] = usePatchCustomerContactMutation();
  const {formId} = useContext(FormContext);

  // eslint-disable-next-line no-restricted-syntax
  const _name = props.name as unknown as string | string[];
  const name = typeof _name === 'string' ? _name : _name.join('.');

  const testId = props['data-testid'] ?? [formId, name.replace('.', '-')].join('-');

  const countries = useSelector(selectCountries);
  const identityCardTypes = useSelector(selectIdentityCardTypes);

  const [identityCardDialog, setIdentityCardDialog] = useState<{
    open: boolean;
    id: string | null;
  }>({
    open: false,
    id: null,
  });

  const closeIdentityCardDialog = () => setIdentityCardDialog({open: false, id: null});

  const {form, Field, Subscribe} = useFormRenderer();

  const handleSubmitIdentityCard = (identityCardData: IdentityCardResponseBodyV2) => {
    const requestBody = getPersonRequestBody(
      identityCardData.id ?? null,
      identityCardData,
      props.person
    );

    if (isNil(identityCardData.id)) {
      requestBody.identityCards.push({id: null, cardData: identityCardData});
    }

    return patchCustomerContact({
      customerId: props.customerId,
      contactId: props.person.id,
      personRequestBody: requestBody,
    })
      .unwrap()
      .then((response) => {
        const identityCard = response.identityCards.find((identityCard) =>
          equals(
            {
              type: identityCardData.type,
              cardNumber: identityCardData.cardNumber,
              issuedOn: identityCardData.issuedOn,
              validUntil: identityCardData.validUntil,
              issuer: identityCardData.issuer,
              issuedInCountryCode: identityCardData.issuedInCountryCode,
              note: identityCardData.note,
            },
            {
              type: identityCard.identityCardData.type,
              cardNumber: identityCard.identityCardData.cardNumber,
              issuedOn: identityCard.identityCardData.issuedOn,
              validUntil: identityCard.identityCardData.validUntil,
              issuer: identityCard.identityCardData.issuer,
              issuedInCountryCode: identityCard.identityCardData.issuedInCountryCode,
              note: identityCard.identityCardData.note,
            }
          )
        );
        form.change(name, identityCard?.id ?? null);
      })
      .then(() => {
        showNotification.success();
        closeIdentityCardDialog();
      })
      .catch(handleApiError);
  };

  return (
    <>
      <HStack spacing={3}>
        <Box flex={1}>
          <Field<string, IdentityCard>
            data-testid={testId}
            name={name}
            as="select"
            options={identityCards}
            placeholder={
              initialValue
                ? `${getPersonIdentityCard(initialValue, countries, identityCardTypes)} (${i18n.t(
                    'general.labels.deleted'
                  )})`
                : undefined
            }
            getOptionLabel={(identityCard) =>
              getPersonIdentityCard(identityCard, countries, identityCardTypes)
            }
            getOptionValue={(identityCard) => identityCard.id}
            label={label}
          />
        </Box>
        <Box flex={1}>
          <Subscribe
            name={name}
            component={({input: {value}}) => (
              <>
                <Label>&nbsp;</Label>
                {value && identityCards?.find((card) => card.id === value) ? (
                  <ButtonGroup data-testid={testId}>
                    <Button
                      data-testid={`${testId}-edit`}
                      variant="ghostLink"
                      leftIcon="image/edit"
                      isLoading={isLoading}
                      isDisabled={isLoading}
                      onClick={() =>
                        setIdentityCardDialog({
                          open: true,
                          id: value,
                        })
                      }
                      title={i18n.t('general.actions.edit')}
                    />
                    <Show when={showRemove}>
                      <Button
                        data-testid={`${testId}-delete`}
                        variant="dangerGhost"
                        leftIcon="action/delete"
                        isDisabled={isLoading}
                        onClick={handleRemoveIdentityCard}
                        title={i18n.t('general.actions.remove')}
                      />
                    </Show>
                  </ButtonGroup>
                ) : (
                  <Show when={showRemove}>
                    <Button
                      data-testid={`${testId}-delete`}
                      variant="dangerGhost"
                      leftIcon="action/delete"
                      onClick={handleRemoveIdentityCard}
                      title={i18n.t('general.actions.remove')}
                    />
                  </Show>
                )}
              </>
            )}
          />
        </Box>
      </HStack>
      <Dialog
        data-testid={`${testId}-form`}
        isOpen={identityCardDialog.open}
        onClose={closeIdentityCardDialog}
        scrollBehavior="outside"
        title={i18n.t('general.labels.identityCard')}
      >
        <IdentityCardForm
          data-testid={`${testId}-form`}
          initialValues={identityCards?.find(
            (identityCard) => identityCard.id === identityCardDialog.id
          )}
          onSubmit={handleSubmitIdentityCard}
          WrapperComponent={({children, handleSubmit}) => (
            <>
              {children}
              <Space vertical={4} />
              <ButtonGroup align="right" data-testid={`${testId}-form`}>
                <Button
                  data-testid={`${testId}-form-discard`}
                  variant="secondary"
                  onClick={closeIdentityCardDialog}
                  title={i18n.t('general.actions.discard')}
                />
                <Button
                  data-testid={`${testId}-save`}
                  variant="primary"
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  onClick={handleSubmit}
                  title={i18n.t('general.actions.save')}
                />
              </ButtonGroup>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const getPersonRequestBody = (
  identityCardId: string | null,
  cardData: IdentityCardData,
  person: PersonResponseBodyV2
): PersonRequestBody => ({
  personData: {
    firstName: person.firstName,
    lastName: person.lastName,
    middleName: person.middleName,
    titleBefore: person.titleBefore,
    titleAfter: person.titleAfter,
    genderKey: person.genderKey,
    roles: person.roles,
    citizenshipCode: person.citizenshipCode,
    birthdate: person.birthdate,
    personalIdentifier: person.personalIdentifier,
  },
  phoneNumbers: person.phoneNumbers.map((item) => ({
    type: item.type,
    phoneNumber: {
      countryCode: item.countryCode,
      prefix: item.prefix,
      number: item.number,
    },
  })),
  emails: person.emails,
  permanentAddressId: person.permanentAddress?.id ?? null,
  identityCards: person.identityCards.map((identityCard) =>
    identityCard.id === identityCardId
      ? {
          id: identityCardId,
          cardData: {
            type: cardData.type,
            cardNumber: cardData.cardNumber,
            issuedOn: cardData.issuedOn,
            validUntil: cardData.validUntil,
            issuer: cardData.issuer,
            issuedInCountryCode: cardData.issuedInCountryCode,
            note: cardData.note,
          },
        }
      : {
          id: identityCard.id,
          cardData: {
            type: identityCard.type,
            cardNumber: identityCard.cardNumber,
            issuedOn: identityCard.issuedOn,
            validUntil: identityCard.validUntil,
            issuer: identityCard.issuer,
            issuedInCountryCode: identityCard.issuedInCountryCode,
            note: identityCard.note,
          },
        }
  ),
});
