import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {TRANSLATED_LANGUAGES} from '../../../types/CommonAppTypes';
import {TConditionForm} from '../../../types/ConditionTypes';
import {FormFieldProps} from '../../FinalForm/components/FormField';
import {getFormFieldName} from './getFormFieldName';
import {getTranslation} from './getTranslation';

export const getBaseFormFieldProps = (
  categoryId: string,
  paramDefinition: AuditParamDefinition,
  locale: TRANSLATED_LANGUAGES
): Pick<
  FormFieldProps<TConditionForm, undefined, boolean>,
  'label' | 'name' | 'required' | 'suffix'
> => {
  const label = paramDefinition?.label ? getTranslation(locale, paramDefinition?.label) : undefined;

  return {
    label,
    name: getFormFieldName(categoryId, paramDefinition?.id),
    required: paramDefinition.mandatory,
    suffix: paramDefinition.unit ?? undefined,
  };
};
