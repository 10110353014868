import {Action, closeDialog, openDialog} from 'platform/components';

import {Helmet} from 'react-helmet-async';

import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {Main, PageTab} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

import {CreateSupplierOrderForm} from './components/CreateSupplierOrderForm';
import {PurchaseList} from './components/PurchaseList';
import {SupplierOrderList} from './components/SupplierOrderList';

interface SupplierOrdersProps {
  hasNewOrderAction?: boolean;
}

export function SupplierOrders(props: SupplierOrdersProps) {
  const tabs: PageTab[] = [
    {
      id: 'purchaseList',
      title: i18n.t('page.warehouse.labels.purchaseList'),
      content: <PurchaseList />,
      href: warehouseRoutes.purchaseList,
    },
    {
      id: 'supplierOrderList',
      title: i18n.t('page.warehouse.labels.supplierOrderList'),
      content: <SupplierOrderList />,
      href: warehouseRoutes.supplierOrderList,
    },
  ];

  const handleCreateSupplierOrder = () => {
    openDialog(
      <CreateSupplierOrderForm
        onClose={() => closeDialog('createSupplierOrderDialog')}
        data-testid={testIds.warehouse.supplierOrders('createNewSupplierOrder')}
      />,
      {
        id: 'createSupplierOrderDialog',
        title: i18n.t('general.labels.createNew'),
        scrollBehavior: 'outside',
      }
    );
  };

  const actions = buildArray<Action>().when(props.hasNewOrderAction, {
    type: 'button',
    title: i18n.t('page.warehouse.labels.newSupplierOrder'),
    onClick: handleCreateSupplierOrder,
    'data-testid': testIds.warehouse.supplierOrders('newSupplierOrder'),
  });

  return (
    <>
      <Helmet title={i18n.t('module.warehouse.title')} />
      <Main
        isFullHeight
        tabs={tabs}
        actions={actions}
        data-testid={testIds.warehouse.supplierOrders('supplierOrders')}
      />
    </>
  );
}
