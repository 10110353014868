import {Choice} from 'platform/components';

import {FC} from 'react';
import {Field as FinalFormField, FieldMetaState, UseFieldConfig} from 'react-final-form';
import {useSelector} from 'react-redux';

import {selectTenant} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, TestIdProps} from 'shared';

import {selectVatRatesByCountryCode} from '../../store/common/selectors';
import {PriceValue} from '../../types/PriceValue';
import {FormFieldWrapper} from '../FinalForm/components/FormFieldWrapper';

type VatSelectProps = {
  name: string;
  label?: string | Nullish;
  allowZeroRate?: boolean;
  parse?: UseFieldConfig<PriceValue>['parse'];
  getIsValid: (meta: FieldMetaState<unknown>) => boolean;
  getErrorMessage: (meta: FieldMetaState<unknown>) => string | undefined;
  commonFieldProps?: {
    isRequired?: boolean;
    isDisabled?: boolean;
  };
} & TestIdProps;

/**
 * @deprecated - use platform instead
 */
export const VatSelect: FC<VatSelectProps> = ({
  name,
  label,
  commonFieldProps = {},
  allowZeroRate,
  getIsValid,
  getErrorMessage,
  ...props
}) => {
  const {data} = useSelector(selectTenant);

  const vatRatesOptions =
    useSelector(selectVatRatesByCountryCode(data?.country))
      ?.rates.filter((r) => r.type !== 'Z' || allowZeroRate)
      .map((vatRate) => ({
        ...vatRate,
        fieldLabel: `${vatRate.rate} %`,
        label: `${vatRate.rate} % ${vatRate.name}`,
      })) ?? [];

  return (
    <FinalFormField<string> name={name}>
      {({input, meta}) => (
        <FormFieldWrapper data-testid={props['data-testid']}>
          <Choice<number | string>
            {...input}
            {...commonFieldProps}
            value={input.value}
            label={label}
            name={name}
            options={vatRatesOptions}
            onChange={input.onChange}
            placeholder={i18n.t('general.labels.select')}
            noOptionsMessage={() => i18n.t('general.labels.noOptions')}
            isDisabled={commonFieldProps.isDisabled}
            data-testid={props['data-testid']}
            errorMessage={getErrorMessage(meta)}
            isNotClearable
          />
        </FormFieldWrapper>
      )}
    </FinalFormField>
  );
};
