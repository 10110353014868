import type {CancelablePromise} from '../types/CancelablePromise';
import type {CreateAuditRequestBody} from '../types/CreateAuditRequestBody';
import type {LoadAuditDataResponseItemBody} from '../types/LoadAuditDataResponseItemBody';
import type {RequestAuditDataFieldsBody} from '../types/RequestAuditDataFieldsBody';
import type {SpecificallyAuditDataItem} from '../types/SpecificallyAuditDataItem';
import type {SpecificallyAuditDataRequestBody} from '../types/SpecificallyAuditDataRequestBody';
import type {SyncInspectionToConditionRequestBody} from '../types/SyncInspectionToConditionRequestBody';
import type {UpdateAuditDataResponseItemBody} from '../types/UpdateAuditDataResponseItemBody';
import type {VehicleAuditCreated} from '../types/VehicleAuditCreated';
import type {VehicleFinished} from '../types/VehicleFinished';
import type {VehiclesRequestBody} from '../types/VehiclesRequestBody';
import {request as __request} from '../utils/request';

export class CarAuditVehicleAuditService {
  /**
   * Finish audit.
   *
   * @returns VehicleFinished
   * @throws ApiError
   */
  public static finishAudit({
    auditId,
    finishedAt,
    authorization,
  }: {
    auditId: string;
    userName?: string;
    finishedAt?: string | null;
    authorization?: string;
  }): CancelablePromise<VehicleFinished> {
    return __request({
      method: 'PUT',
      url: '/car-audit/v2/audits/{auditId}/finish',
      path: {
        auditId,
      },
      query: {
        finishedAt,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * Get specifically audit data by comparison key
   *
   * @returns SpecificallyAuditDataItem
   * @throws ApiError
   */
  public static getSpecificallyAuditData({
    inspectionType,
    requestBody,
    authorization,
  }: {
    inspectionType: 'vehicleCondition' | 'validationInspection' | 'nonValidationInspection';
    requestBody?: SpecificallyAuditDataRequestBody;
    authorization?: string;
  }): CancelablePromise<Array<SpecificallyAuditDataItem>> {
    return __request({
      method: 'POST',
      url: '/car-audit/v1/search/audit-data/{inspectionType}',
      path: {
        inspectionType,
      },
      body: requestBody,
      headers: {
        Authorization: authorization,
      },
      mediaType: 'application/json',
    });
  }

  /**
   * Get car audit control data.
   *
   * @returns LoadAuditDataResponseItemBody
   * @throws ApiError
   */
  public static loadAuditDataByAuditId({
    auditId,
    authorization,
  }: {
    auditId: string;
    withoutReducer?: boolean;
    authorization?: string;
  }): CancelablePromise<Array<LoadAuditDataResponseItemBody>> {
    return __request({
      method: 'GET',
      url: '/car-audit/v1/audits/{auditId}',
      path: {
        auditId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns UpdateAuditDataResponseItemBody
   * @throws ApiError
   */
  public static updateAuditData({
    auditId,
    requestBody,
    authorization,
  }: {
    auditId: string;
    userName?: string;
    withoutReducer?: boolean;
    requestBody?: Array<RequestAuditDataFieldsBody>;
    authorization?: string;
  }): CancelablePromise<UpdateAuditDataResponseItemBody> {
    return __request({
      method: 'PUT',
      url: '/car-audit/v1/audits/{auditId}',
      path: {
        auditId,
      },
      body: requestBody,
      headers: {
        Authorization: authorization,
      },
      mediaType: 'application/json',
    });
  }

  /**
   * Get car audit control data.
   *
   * @returns LoadAuditDataResponseItemBody
   * @throws ApiError
   */
  public static loadAuditDataByVehicleId({
    inspectionType,
    requestBody,
    authorization,
  }: {
    inspectionType: string;
    withoutReducer?: boolean;
    requestBody?: VehiclesRequestBody;
    authorization?: string;
  }): CancelablePromise<Array<LoadAuditDataResponseItemBody>> {
    return __request({
      method: 'POST',
      url: '/car-audit/v1/search/audits/{inspectionType}',
      path: {
        inspectionType,
      },
      body: requestBody,
      headers: {
        Authorization: authorization,
      },
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static syncInspectionAndVehicleCondition({
    requestBody,
    authorization,
  }: {
    requestBody?: SyncInspectionToConditionRequestBody;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/car-audit/v1/sync/inspection-to-condition',
      body: requestBody,
      headers: {
        Authorization: authorization,
      },
      mediaType: 'application/json',
    });
  }

  /**
   * Create car audit v2.
   *
   * @returns VehicleAuditCreated
   * @throws ApiError
   */
  public static createAuditV2({
    vehicleId,
    requestBody,
    authorization,
  }: {
    vehicleId: string;
    requestBody?: CreateAuditRequestBody;
    authorization?: string;
  }): CancelablePromise<VehicleAuditCreated> {
    return __request({
      method: 'POST',
      url: '/car-audit/v2/audits/{vehicleId}',
      path: {
        vehicleId,
      },
      body: requestBody,
      headers: {
        Authorization: authorization,
      },
      mediaType: 'application/json',
    });
  }
}
