import {
  GetServiceOrderGroupApiResponse,
  GetServiceOrderGroupApiArg,
  DeleteServiceOrderGroupApiArg,
  DeleteServiceOrderGroupApiResponse,
  GetServiceOrderGroupByIdApiArg,
  GetServiceOrderGroupByIdApiResponse,
  PatchServiceOrderGroupApiArg,
  PatchServiceOrderGroupApiResponse,
  PostServiceOrderGroupApiArg,
  PostServiceOrderGroupApiResponse,
  PostServiceOrderGroupSetAsActiveApiArg,
  PostServiceOrderGroupSetAsActiveApiResponse,
  PostServiceOrderGroupSetAsInactiveApiArg,
  PostServiceOrderGroupSetAsInactiveApiResponse,
  GetServiceOrderGroupApiResponseSchema,
  GetServiceOrderGroupApiArgSchema,
  PostServiceOrderGroupApiResponseSchema,
  PostServiceOrderGroupApiArgSchema,
  DeleteServiceOrderGroupApiArgSchema,
  DeleteServiceOrderGroupApiResponseSchema,
  GetServiceOrderGroupByIdApiArgSchema,
  GetServiceOrderGroupByIdApiResponseSchema,
  PatchServiceOrderGroupApiArgSchema,
  PatchServiceOrderGroupApiResponseSchema,
  PostServiceOrderGroupSetAsActiveApiArgSchema,
  PostServiceOrderGroupSetAsActiveApiResponseSchema,
  PostServiceOrderGroupSetAsInactiveApiArgSchema,
  PostServiceOrderGroupSetAsInactiveApiResponseSchema,
} from '../types/metadaWorkshopServiceOrderGroup';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceOrderGroupApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceOrderGroup: build.query<
      GetServiceOrderGroupApiResponse,
      GetServiceOrderGroupApiArg | void
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-group`,
        params: {
          isActive: queryArg?.isActive,
          validityFrom: queryArg?.validityFrom,
          validityTo: queryArg?.validityTo,
          authorizationProfileId: queryArg?.authorizationProfileId,
          serviceOrderTypeId: queryArg?.serviceOrderTypeId,
          serviceOrderVariantId: queryArg?.serviceOrderVariantId,
        },
      }),
      providesTags: [{type: 'serviceOrderGroups'}],
      extraOptions: {
        responseSchema: GetServiceOrderGroupApiResponseSchema,
        requestSchema: GetServiceOrderGroupApiArgSchema,
      },
    }),
    postServiceOrderGroup: build.mutation<
      PostServiceOrderGroupApiResponse,
      PostServiceOrderGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-group`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'serviceOrderGroups'}],
      extraOptions: {
        responseSchema: PostServiceOrderGroupApiResponseSchema,
        requestSchema: PostServiceOrderGroupApiArgSchema,
      },
    }),
    getServiceOrderGroupById: build.query<
      GetServiceOrderGroupByIdApiResponse,
      GetServiceOrderGroupByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-group/${queryArg.serviceOrderGroupId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderGroup', id: queryArg.serviceOrderGroupId},
      ],
      extraOptions: {
        responseSchema: GetServiceOrderGroupByIdApiResponseSchema,
        requestSchema: GetServiceOrderGroupByIdApiArgSchema,
      },
    }),
    deleteServiceOrderGroup: build.mutation<
      DeleteServiceOrderGroupApiResponse,
      DeleteServiceOrderGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-group/${queryArg.serviceOrderGroupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderGroups'},
        {type: 'serviceOrderGroup', id: queryArg.serviceOrderGroupId},
      ],
      extraOptions: {
        responseSchema: DeleteServiceOrderGroupApiResponseSchema,
        requestSchema: DeleteServiceOrderGroupApiArgSchema,
      },
    }),
    patchServiceOrderGroup: build.mutation<
      PatchServiceOrderGroupApiResponse,
      PatchServiceOrderGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-group/${queryArg.serviceOrderGroupId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderGroups'},
        {type: 'serviceOrderGroup', id: queryArg.serviceOrderGroupId},
      ],
      extraOptions: {
        responseSchema: PatchServiceOrderGroupApiResponseSchema,
        requestSchema: PatchServiceOrderGroupApiArgSchema,
      },
    }),
    postServiceOrderGroupSetAsActive: build.mutation<
      PostServiceOrderGroupSetAsActiveApiResponse,
      PostServiceOrderGroupSetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-group/${queryArg.serviceOrderGroupId}/set-as-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderGroups'},
        {type: 'serviceOrderGroup', id: queryArg.serviceOrderGroupId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderGroupSetAsActiveApiResponseSchema,
        requestSchema: PostServiceOrderGroupSetAsActiveApiArgSchema,
      },
    }),
    postServiceOrderGroupSetAsInactive: build.mutation<
      PostServiceOrderGroupSetAsInactiveApiResponse,
      PostServiceOrderGroupSetAsInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-group/${queryArg.serviceOrderGroupId}/set-as-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderGroups'},
        {type: 'serviceOrderGroup', id: queryArg.serviceOrderGroupId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderGroupSetAsInactiveApiResponseSchema,
        requestSchema: PostServiceOrderGroupSetAsInactiveApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetServiceOrderGroupQuery,
  useDeleteServiceOrderGroupMutation,
  useGetServiceOrderGroupByIdQuery,
  usePatchServiceOrderGroupMutation,
  usePostServiceOrderGroupMutation,
  usePostServiceOrderGroupSetAsActiveMutation,
  usePostServiceOrderGroupSetAsInactiveMutation,
} = metadaWorkshopServiceOrderGroupApi;
