import {ThemeColorPath} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {defaultTo, path, pipe} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

type TextSize = 'large' | 'base' | 'small' | 'xSmall' | 'xxSmall';

interface FlagLabelProps extends TestIdProps {
  size: TextSize;
  color: ThemeColorPath;
  label: string;
  isBold?: boolean;
}

export function FlagLabel(props: FlagLabelProps) {
  const theme = useTheme();

  return (
    <div
      data-testid={suffixTestId('flagLabel', props)}
      // platform text doesn't support palettes of colors that we want to use for label text
      // eslint-disable-next-line eag/no-css-property
      css={css`
        display: block;
        color: ${pipe(
          path<string>(['colors', ...props.color?.split('.')]),
          defaultTo('transparent')
        )(theme)};
        font-family: ${theme.fonts.body};
        font-size: ${theme.fontSizes.text[props.size]};
        line-height: ${theme.lineHeights.text[props.size]};
        font-weight: ${props.isBold ? theme.fontWeights.bold : theme.fontWeights.text.default};
        white-space: nowrap;
        overflow: hidden;
        letter-spacing: 0;
        text-overflow: ellipsis;
      `}
    >
      {props.label}
    </div>
  );
}
