import {Tooltip} from 'platform/components';

import {FC, MutableRefObject, useCallback, useRef, useState} from 'react';

import {Nullish} from 'shared';

export const PlatformUpdatedBy: FC<{text?: string | Nullish}> = ({text}) => {
  const {onMouseOver, ref, tooltipText} = useToolTipHook(text);

  return (
    <Tooltip placement="top" label={tooltipText}>
      <span ref={ref} onMouseOver={onMouseOver}>
        {text || <>-</>}
      </span>
    </Tooltip>
  );
};

type TooltipEventProps = {
  tooltipText: string;
  onMouseOver: () => void;
  ref: MutableRefObject<HTMLSpanElement | null>;
};

const useToolTipHook = (text?: string | Nullish): TooltipEventProps => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const [tooltipText, setText] = useState('');

  const onMouseOver = useCallback(() => {
    if (ref?.current && ref.current.offsetWidth < ref.current.scrollWidth) {
      setText(text ?? '-');
    }
  }, [text, ref]);

  return {
    onMouseOver,
    tooltipText,
    ref,
  };
};
