import {Button, ButtonGroup, showNotification} from 'platform/components';
import {Box, Space} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useAddUpsellFromCatalogMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

interface EditPriceDialogProps extends TestIdProps {
  offerId: string;
  businessCaseId: string;
  offerSaleVehicleId: string;
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
}

export function AddProductFromCatalog(props: EditPriceDialogProps) {
  const navigate = useNavigate();

  const [addUpsellItem] = useAddUpsellFromCatalogMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    const productId = isArray(rowId) ? rowId[0] : rowId;
    const name = path(['name', 'value'], rowData) ?? '';

    if (actionKey === 'select') {
      addUpsellItem({
        offerId: props.offerId,
        businessCaseId: props.businessCaseId,
        offerSaleVehicleId: props.offerSaleVehicleId,
        body: {productId},
      })
        .unwrap()
        .then(props.onSubmit)
        .then(() =>
          showNotification.success(i18n.t('entity.productCatalog.notifications.itemAdded', {name}))
        )
        .catch(handleApiError);
    }
  };

  return (
    <>
      <Box height={70}>
        <DataGrid
          gridCode="select_from_product_catalog"
          actionCallback={actionCallback}
          emptyState={{
            action: {
              onClick: () => navigate(settingsRoutes.productCatalog),
              title: i18n.t('entity.productCatalog.actions.addProduct'),
            },
          }}
          data-testid={suffixTestId('grid', props)}
        />
      </Box>

      <Space vertical={4} />

      <ButtonGroup align="right">
        <Button
          variant="secondary"
          onClick={props.onCancel}
          title={i18n.t('general.actions.cancel')}
          data-testid={suffixTestId('cancel', props)}
        />
      </ButtonGroup>
    </>
  );
}
