import {createSelector} from '@reduxjs/toolkit';

import {selectGeneralSettings} from '../generalSettings/selectors';

export const selectInterestSettings = createSelector(
  selectGeneralSettings,
  (state) => state.interestSettings
);

export const selectInterestSeriesDefinitionSlice = createSelector(
  selectInterestSettings,
  (state) => state.interestSeriesDefinition
);

export const selectInterestSeriesDefinitionList = createSelector(
  selectInterestSeriesDefinitionSlice,
  (state) => state.list
);

export const selectInterestSeriesDefinitionDetail = createSelector(
  selectInterestSeriesDefinitionSlice,
  (state) => state.detail
);
