import {complement, findIndex, whereEq} from 'ramda';

import {WizardStepConfig} from '../WizardStepConfig';

const isStepAvailable = complement(whereEq({isDisabled: true}));

export const getFirstEnabledStepPosition = (steps: WizardStepConfig[]): number | null => {
  const foundIndex = findIndex<WizardStepConfig>(isStepAvailable, steps);
  return foundIndex < 0 ? null : foundIndex;
};
