import type {CancelablePromise} from '../types/CancelablePromise';
import type {CreateRoundingDefinitionRequestBody} from '../types/CreateRoundingDefinitionRequestBody';
import type {Enum} from '../types/Enum';
import type {RoundingDefinition} from '../types/RoundingDefinition';
import type {UpdateRoundingDefinitionRequestBody} from '../types/UpdateRoundingDefinitionRequestBody';
import {request as __request} from '../utils/request';

export class RoundingDefinitionService {
  /**
   * @returns RoundingDefinition
   * @throws ApiError
   * @deprecated
   */
  public static createRoundingDefinition({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: CreateRoundingDefinitionRequestBody;
  }): CancelablePromise<RoundingDefinition> {
    return __request({
      method: 'POST',
      url: `/dms/v1/rounding-definition`,
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
    });
  }

  /**
   * @returns RoundingDefinition
   * @throws ApiError
   * @deprecated
   */
  public static getRoundingDefinitionList({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Array<RoundingDefinition>> {
    return __request({
      method: 'GET',
      url: `/dms/v1/rounding-definition`,
      shouldClearCacheEntry: true,
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   * @deprecated
   */
  public static deleteRoundingDefinition({
    id,
    authorization,
  }: {
    id: string;
    authorization?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'DELETE',
      url: `/dms/v1/rounding-definition/{id}`,
      path: {id},
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns RoundingDefinition
   * @throws ApiError
   * @deprecated
   */
  public static getRoundingDefinition({
    id,
    authorization,
  }: {
    id: string;
    authorization?: string;
  }): CancelablePromise<RoundingDefinition> {
    return __request({
      method: 'GET',
      url: `/dms/v1/rounding-definition/{id}`,
      shouldClearCacheEntry: true,
      path: {id},
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns RoundingDefinition
   * @throws ApiError
   * @deprecated
   */
  public static updateRoundingDefinition({
    id,
    authorization,
    requestBody,
  }: {
    id: string;
    authorization?: string;
    requestBody?: UpdateRoundingDefinitionRequestBody;
  }): CancelablePromise<RoundingDefinition> {
    return __request({
      method: 'PUT',
      url: `/dms/v1/rounding-definition/{id}`,
      path: {id},
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
    });
  }

  // FIXME: renamed from getPaymentTypeEnumEnum => getPaymentTypeEnum
  /**
   * @returns Enum
   * @throws ApiError
   * @deprecated
   */
  public static getPaymentTypeEnum({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Array<Enum>> {
    return __request({
      method: 'GET',
      url: `/dms/v1/rounding-definition/payment-type`,
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns Enum
   * @throws ApiError
   * @deprecated
   */
  public static getRoundStepEnum({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Array<Enum>> {
    return __request({
      method: 'GET',
      url: `/dms/v1/rounding-definition/round-step`,
      headers: {
        Authorization: authorization,
      },
    });
  }
}
