import {Button, DataStatus, openDialog} from 'platform/components';
import {Grid, GridItem, HStack, Space, VStack} from 'platform/foundation';

import {useGetVehicleV2Query} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {Section, VehicleNoteWidget, VehicleServiceIntervalsCard} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {UpdateDialogForm} from './components/UpdateDialogForm';

interface ServiceIntervalsProps {
  serviceCaseId: string | Nullish;
  vehicleId: string | Nullish;
}

export function ServiceIntervals(props: ServiceIntervalsProps) {
  const vehicleV2Query = useGetVehicleV2Query(
    {vehicleId: props.vehicleId || ''},
    {skip: !props.vehicleId}
  );

  const handleEditVehicle = () => {
    openDialog(
      <UpdateDialogForm
        vehicleId={props.vehicleId || ''}
        serviceCaseId={props.serviceCaseId || ''}
        dialogId="update-service-intervals"
        data-testid={testIds.workshop.serviceCaseDetail('updateDialogBoundary')}
      />,
      {
        title: i18n.t('entity.vehicle.labels.updateServiceIntervals'),
        id: 'update-service-intervals',
      }
    );
  };

  return (
    <Section data-testid={testIds.workshop.serviceCaseDetail('serviceIntervals')}>
      <VStack spacing={4}>
        <HStack>
          <Space fillAvailable />
          <Button
            title={i18n.t('entity.vehicle.labels.updateServiceIntervals')}
            leftIcon="navigation/refresh"
            onClick={handleEditVehicle}
            data-testid={testIds.workshop.serviceCaseDetail('updateServiceIntervals')}
          />
        </HStack>
        <DataStatus isLoading={vehicleV2Query.isLoading} isError={vehicleV2Query.isError}>
          <Grid columns={2}>
            <GridItem>
              <VehicleServiceIntervalsCard
                vehicleData={vehicleV2Query.data}
                data-testid={testIds.workshop.serviceCaseDetail('serviceIntervals')}
              />
            </GridItem>
            <GridItem>
              <VehicleNoteWidget vehicleId={props.vehicleId || ''} />
            </GridItem>
          </Grid>
        </DataStatus>
      </VStack>
    </Section>
  );
}
