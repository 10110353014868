import {useDialog} from 'platform/components';
import {Box, Icon} from 'platform/foundation';
import styled from 'styled-components';

import {ReactElement, useCallback, useState} from 'react';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {useRouter} from '@omnetic-dms/teas';

import {ERouterTabNames} from '../types/ERouterTabNames';
import {NewRoleDialog} from './NewRoleDialog';
import {Roles} from './Roles';
import {Users} from './Users';

export const UserManagementTabs = (): ReactElement => {
  const router = useRouter();
  const [isNewRoleDialogOpened, openNewRoleDialog, closeNewRoleDialog] = useDialog();
  const [isActiveUserTab, setTab] = useState<boolean>(
    router.query?.tab?.toString() === ERouterTabNames.users || !router.query?.tab
  );
  const newUser = useCallback(() => {
    router.push(settingsRoutes.userManagementAddUser);
  }, [router]);

  const createButton = isActiveUserTab ? (
    <AddNewButton onClick={newUser} data-testid={testIds.settings.userManagement('newUserButton')}>
      <Icon value="content/add_circle" />
      {i18n.t('page.settings.actions.newUser')}
    </AddNewButton>
  ) : (
    <AddNewButton
      onClick={openNewRoleDialog}
      data-testid={testIds.settings.userManagement('newRoleButton')}
    >
      <Icon value="content/add_circle" size={4} />
      {i18n.t('page.settings.actions.newCustomRole')}
    </AddNewButton>
  );

  const content = isActiveUserTab ? <Users /> : <Roles />;

  const activeClass = (value: boolean) => (value ? 'active' : '');

  const changeTab = (value: boolean) => {
    setTab(value);
    const tab = value ? ERouterTabNames.users : ERouterTabNames.roles;

    router.push(settingsRoutes.userManagement, {query: {tab}});
  };

  /**
   * Initial Users and Roles by tab
   * */
  return (
    <>
      <Box boxShadow="elevation_1" borderRadius="small" backgroundColor="general.white">
        <ItemTab>
          <Tabs data-testid={testIds.settings.userManagement('userTab')}>
            <span
              data-testid={testIds.settings.userManagement('users')}
              className={activeClass(isActiveUserTab)}
              onClick={() => changeTab(true)}
            >
              {i18n.t('entity.user.labels.users')}
            </span>
            <span
              data-testid={testIds.settings.userManagement('roles')}
              className={activeClass(!isActiveUserTab)}
              onClick={() => changeTab(false)}
            >
              {i18n.t('general.labels.roles')}
            </span>
          </Tabs>
          {createButton}
        </ItemTab>
        {content}
      </Box>
      <NewRoleDialog isOpen={isNewRoleDialogOpened} onClose={closeNewRoleDialog} />
    </>
  );
};

const AddNewButton = styled.span`
  font-size: ${({theme}) => theme.fontSizes.text.small};
  display: flex;
  cursor: pointer;
  align-items: center;
  color: ${({theme}) => theme.colors.palettes.blue[60][100]};

  svg {
    margin-right: 3px;
    color: ${({theme}) => theme.colors.palettes.blue[60][100]};
  }
`;

const Tabs = styled.div`
  display: flex;

  span {
    display: block;
    padding: 6px 9px;
    cursor: pointer;
    margin: 0 6px 0 0;
    border-radius: 3px;
  }

  .active {
    background: ${({theme}) => theme.colors.palettes.blue[20][100]};
    color: ${({theme}) => theme.colors.palettes.blue[80][100]};
  }
`;

const Item = styled.div`
  display: flex;
  height: 46px;
  padding: 0 8px;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
`;

const ItemTab = styled(Item)`
  justify-content: space-between;
`;
