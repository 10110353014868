import {ZodTypeAny} from 'zod';
import {errorUtil} from 'zod/lib/helpers/errorUtil';

import {ZodDeepUnknownKeys} from '../types/ZodDeepUnknownKeys';
import {zodDeepApplyObject} from './zodDeepApplyObject';

type DeepStrict<T extends ZodTypeAny> = ZodDeepUnknownKeys<T, 'strict'>;

/**
 * Applies the `strict` method recursively to all Zod schema components. This function
 * ensures that only the exact shape of objects as described in the schema are accepted,
 * discarding any additional properties. The `strict` transformation is applied using the
 * `zodDeepApplyObject` function which handles recursive application across complex schemas
 * like ZodObject, ZodArray, etc.
 *
 * @template T - A subtype of ZodTypeAny, representing the Zod schema type.
 * @param {T} schema - The Zod schema on which to enforce strict validation.
 * @param {errorUtil.ErrMessage} [error] - Optional custom error message to apply if
 * strict validation fails.
 * @returns {DeepStrict<T>} - A new schema of type `DeepStrict<T>` with strict validation
 * rules applied recursively. This ensures that objects matching this schema do not contain
 * properties that are not explicitly specified in the schema itself.
 * @example
 * // Define a Zod schema
 * const userSchema = ZodObject({
 *   name: ZodString(),
 *   age: ZodNumber(),
 *   hobbies: ZodArray(ZodString())
 * });
 *
 * // Make the schema strict recursively
 * const strictUserSchema = zodDeepStrict(userSchema);
 *
 * // This will succeed
 * strictUserSchema.parse({
 *   name: 'Alice',
 *   age: 30,
 *   hobbies: ['gaming', 'reading']
 * });
 *
 * // This will fail because 'location' is not defined in the schema
 * strictUserSchema.parse({
 *   name: 'Alice',
 *   age: 30,
 *   hobbies: ['gaming', 'reading'],
 *   location: 'Wonderland'
 * });
 */
export const zodDeepStrict = <T extends ZodTypeAny>(
  schema: T,
  error?: errorUtil.ErrMessage
): DeepStrict<T> => zodDeepApplyObject(schema, (s) => s.strict(error)) as DeepStrict<T>;
