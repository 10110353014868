import {ReactNode} from 'react';

import type {ManagedDialogProps} from '../ManagedDialogProps';
import {DIALOGS_EVENTS} from './dialogsEvents';

/**
 * @about Opens a dialog with some custom JSX content.
 * Dialog will be closed automatically when overlay or
 * close button is clicked. Can be closed programmatically
 * with closeCurrentDialog or closeDialog('dialog-id') utilities.
 *
 * @param content Dialog content
 * @param options Dialog options (title, id, size, etc)
 *
 * @example
 * openDialog(
 *   <EditVehicleForm
 *     vehicleId={props.vehicleId}
 *     onCancel={closeCurrentDialog}
 *   />,
 *   {
 *     title: i18n.t('entity.vehicle.edit'),
 *     'data-testid': testIds.vehicleDetail('editForm')
 *   }
 * );
 */
export function openDialog(content: ReactNode, options: ManagedDialogProps) {
  window.dispatchEvent(new CustomEvent(DIALOGS_EVENTS.open, {detail: {options, content}}));
}
