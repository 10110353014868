import {isNil} from 'ramda';

import {GetEmployeeCostCentersListApiResponse} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getOptionsFromActiveCostCenters = (
  costCenters: GetEmployeeCostCentersListApiResponse | Nullish
) => {
  if (isNil(costCenters?.costCenters)) {
    return [];
  }

  return costCenters?.costCenters
    ?.filter((costCenter) => costCenter?.isActive)
    .map((activeConstCenter) => ({
      label: activeConstCenter?.description,
      value: activeConstCenter?.id,
    }));
};
