import {Parameter} from 'platform/components';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetBusinessCaseQuery, useGetSaleVehicleFromSaleVehicleIdQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, customerRoutes, testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {
  EMPTY_PLACEHOLDER,
  getCustomerEmail,
  getCustomerName,
  getCustomerPhoneNumber,
  isCustomerNaturalPerson,
  queryParams,
  SnippetProps,
  useGetVehicleTitle,
  interestSearchParams,
  useGetFlags,
} from '@omnetic-dms/shared';

import {buildArray, composePath, useQueryState} from 'shared';

import {getLastActiveBusinessCase} from '../utils/getLastActiveBusinessCase';
import {useInterestData} from './useInterestData';

export function useInterestSnippets(interestId: string) {
  const [_activeSectionId, setActiveSectionId] = useQueryState(
    queryParams.COMPONENT_SECTIONS_SECTION
  );

  const {interest, customer, miniSale, offeredVehiclesList} = useInterestData(interestId);

  const lastActiveBusinessCase = getLastActiveBusinessCase(interest?.activeBusinessCases);
  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: lastActiveBusinessCase?.businessCaseId ?? ''},
    {skip: isNilOrEmpty(lastActiveBusinessCase?.businessCaseId)}
  );
  const {data: saleVehicle} = useGetSaleVehicleFromSaleVehicleIdQuery(
    {vehicleId: businessCase?.saleVehicleId ?? ''},
    {skip: isNilOrEmpty(businessCase?.saleVehicleId)}
  );
  const [flags] = useGetFlags(businessCase, saleVehicle);

  const [buyingVehicleTitle] = useGetVehicleTitle({
    make: miniSale?.vehicleData?.make,
    vehicleType: miniSale?.vehicleData?.type?.toString(),
    modelFamily: miniSale?.vehicleData?.modelFamily,
    variant: miniSale?.vehicleData?.variant,
    trim: miniSale?.vehicleData?.trim,
  });
  const [sellingVehicleTitle] = useGetVehicleTitle(offeredVehiclesList?.[0]);
  const [businessCaseVehicleTitle] = useGetVehicleTitle(lastActiveBusinessCase?.vehicleInfo);

  return buildArray<SnippetProps>()
    .add({
      'data-testid': testIds.interest.detail('customer'),
      label: i18n.t('entity.customer.labels.customer'),
      placeholder: {
        title: i18n.t('entity.customer.labels.customer'),
      },
      title: getCustomerName(customer) ?? EMPTY_PLACEHOLDER,
      parameters: buildArray<string | null>()
        .when(
          isNotNil(customer) && !isCustomerNaturalPerson(customer),
          getCustomerPhoneNumber(customer)
        )
        .when(isNotNil(customer) && !isCustomerNaturalPerson(customer), getCustomerEmail(customer))
        .when(
          isNotNil(customer) && isCustomerNaturalPerson(customer),
          getCustomerPhoneNumber(customer)
        )
        .when(isNotNil(customer) && isCustomerNaturalPerson(customer), getCustomerEmail(customer)),
      href: customer?.id
        ? composePath(customerRoutes.detail, {
            params: {id: customer?.id},
            isSearchParamsPreserved: false,
          })
        : undefined,
      customerId: customer?.id,
      selectedContactId: customer?.foundingPerson?.id,
    })
    .add({
      'data-testid': testIds.interest.detail('selling'),
      label: i18n.t('entity.interest.labels.sellingTab'),
      placeholder: {
        title: i18n.t('entity.interest.actions.addSellingRequest'),
        onClick: () => setActiveSectionId(interestSearchParams.interestDetail.selling),
      },
      title: sellingVehicleTitle ?? EMPTY_PLACEHOLDER,
      parameters: [
        offeredVehiclesList?.[0]?.vin ?? i18n.t('entity.vehicle.labels.noVin'),
        offeredVehiclesList?.[0]?.vehicle?.registrationPlate ??
          i18n.t('entity.vehicle.notifications.noLicencePlate'),
      ],
      href: offeredVehiclesList?.[0]
        ? composePath(vehiclesRoutes.detail, {
            params: {id: offeredVehiclesList?.[0]?.id},
            isSearchParamsPreserved: false,
          })
        : undefined,
    })
    .add({
      'data-testid': testIds.interest.detail('buying'),
      label: i18n.t('entity.interest.labels.buyingTab'),
      placeholder: {
        title: i18n.t('entity.interest.actions.addBuyingRequest'),
        onClick: () => setActiveSectionId(interestSearchParams.interestDetail.buying),
      },
      title: buyingVehicleTitle ?? EMPTY_PLACEHOLDER,
      parameters: [
        miniSale?.vehicleData?.vin ?? i18n.t('entity.vehicle.labels.noVin'),
        miniSale?.vehicleData?.licencePlate ??
          i18n.t('entity.vehicle.notifications.noLicencePlate'),
      ],
    })
    .when(isNotNil(lastActiveBusinessCase), {
      flags,
      'data-testid': testIds.interest.detail('activeBusinessCase'),
      label: i18n.t('entity.interest.labels.activeBusinessCase'),
      title: businessCaseVehicleTitle ?? EMPTY_PLACEHOLDER,
      parameters: buildArray<Parameter>()
        .when(lastActiveBusinessCase?.code, lastActiveBusinessCase?.code)
        .when(
          lastActiveBusinessCase?.ownerFirstName && lastActiveBusinessCase?.ownerLastName,
          `${lastActiveBusinessCase?.ownerFirstName} ${lastActiveBusinessCase?.ownerLastName}`
        ),
      icon: 'places/business_center',
      href: lastActiveBusinessCase?.businessCaseId
        ? composePath(businessCaseRoutes.overview, {
            params: {id: lastActiveBusinessCase?.businessCaseId},
            isSearchParamsPreserved: false,
          })
        : undefined,
    });
}
