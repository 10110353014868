import {match} from 'ts-pattern';

import {mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {workshopRoutes} from '@omnetic-dms/routes';
import {queryParams, workshopSearchParams} from '@omnetic-dms/shared';

import {composePath, noop, useNavigate} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, SOURCE_DATA_GRID} from 'features/datagrid';

type ReceiveJobItemRowData = {state: {value: {key: string}}};

export function ServiceCaseOverview() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, sourceSearchParams}) => {
    const URLSourceSearchParams = new URLSearchParams(sourceSearchParams);
    const castedRowData = rowData as ReceiveJobItemRowData;
    const serviceCaseState = castedRowData?.state?.value?.key;
    const serviceCaseId = isArray(rowId) ? rowId[0] : rowId;
    const path =
      serviceCaseState === 'DRAFT'
        ? composePath(workshopRoutes.draftServiceCase, {
            params: {id: serviceCaseId},
            queryParams: {
              tab: workshopSearchParams.create.serviceJobs,
              [SOURCE_DATA_GRID]: URLSourceSearchParams.get(SOURCE_DATA_GRID),
            },
          })
        : composePath(workshopRoutes.serviceCaseDetail, {
            params: {id: serviceCaseId},
            queryParams: {
              [queryParams.REDIRECT_SOURCE]: 'service-cases',
              [SOURCE_DATA_GRID]: URLSourceSearchParams.get(SOURCE_DATA_GRID),
            },
          });

    match(actionKey)
      .with('redirectDetail', 'detail', () => navigate(path))
      .with('redirectDetail_newTab', 'detail_newTab', () => window.open(path))
      .otherwise(noop);
  };

  const queryModifier = (filter: QueryFilterObject) => mergeAll([filter, {vehicleId: undefined}]);

  return (
    <DataGrid
      data-testid="service-case-overview"
      gridCode="service-case-opensearch"
      actionCallback={actionCallback}
      queryModifier={queryModifier}
    />
  );
}
