import {z} from 'zod';

const ServiceCheckInCommonSchema = z.object({
  id: z.string().nullable().optional(),
  state: z.enum(['SCHEDULED', 'COMPLETED']).nullable().optional(),
  scheduledDate: z.string().nullable().optional(),
  completedDate: z.string().nullable().optional(),
  driverFirstname: z.string().nullable().optional(),
  driverLastname: z.string().nullable().optional(),
  driverPhonePrefix: z.string().nullable().optional(),
  driverPhoneNo: z.string().nullable().optional(),
  driverEmail: z.string().nullable().optional(),
  driverIdentityCard: z.string().nullable().optional(),
  mileage: z.number().nullable().optional(),
  fuelTank: z.number().nullable().optional(),
  technicalInspection: z.string().nullable().optional(),
  emissions: z.string().nullable().optional(),
  note: z.string().nullable().optional(),
  consentToTestDrive: z.boolean().nullable().optional(),
  isVehicleNotInService: z.boolean().nullable().optional(),
  inspectionId: z.array(z.string()).nullable().optional(),
});

export const GetServiceCheckInApiResponseSchema = ServiceCheckInCommonSchema.nullable();

export type GetServiceCheckInApiResponse = z.infer<typeof GetServiceCheckInApiResponseSchema>;

export const GetServiceCheckInApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type GetServiceCheckInApiArg = z.infer<typeof GetServiceCheckInApiArgSchema>;

const CheckinCreateEditResponse = z
  .object({
    isMileageValid: z.boolean(),
    highestMileage: z.number().nullable().optional(),
  })
  .nullable();

export const PostServiceCheckInApiResponseSchema = CheckinCreateEditResponse;
export type PostServiceCheckInApiResponse = z.infer<typeof PostServiceCheckInApiResponseSchema>;

const PostServiceCheckInBodySchema = ServiceCheckInCommonSchema.extend({
  id: z.string().nullable().optional(),
  withFuelTankMileageUpdate: z.boolean().nullable().optional(),
});

export const PostServiceCheckInApiArgSchema = z.object({
  serviceCaseId: z.string(),
  body: PostServiceCheckInBodySchema.nullable(),
});

export type PostServiceCheckInApiArg = z.infer<typeof PostServiceCheckInApiArgSchema>;

export const PatchServiceCheckInApiResponseSchema = CheckinCreateEditResponse;
export type PatchServiceCheckInApiResponse = z.infer<typeof PatchServiceCheckInApiResponseSchema>;

const PatchServiceCheckInBodySchema = PostServiceCheckInBodySchema.omit({
  id: true,
});

export const PatchServiceCheckInApiArgSchema = z.object({
  serviceCaseId: z.string(),
  body: PatchServiceCheckInBodySchema.nullable(),
});

export type PatchServiceCheckInApiArg = z.infer<typeof PatchServiceCheckInApiArgSchema>;

const GetServiceCheckInDefaultDriverApiApiResponseSchema = z
  .object({
    driverFirstname: z.string().nullish(),
    driverLastname: z.string().nullish(),
    driverPhonePrefix: z.string().nullish(),
    driverPhoneNo: z.string().nullish(),
    driverEmail: z.string().nullish(),
    driverIdentityCard: z.string().nullish(),
  })
  .nullable();

export type GetServiceCheckInDefaultDriverApiApiResponse = z.infer<
  typeof GetServiceCheckInDefaultDriverApiApiResponseSchema
>;

const GetServiceCheckInDefaultDriverApiApiSchema = z.object({
  serviceCaseId: z.string(),
});

export type GetServiceCheckInDefaultDriverApiApiArg = z.infer<
  typeof GetServiceCheckInDefaultDriverApiApiSchema
>;
