import {showNotification} from 'platform/components';

export const printFile = (urlData: string) => {
  const url = new URL(urlData);
  const hasInlineTypeInUrl = url.searchParams.get('type') === 'inline';

  if (!hasInlineTypeInUrl) {
    url.searchParams.append('type', 'inline');
  }

  fetch(url)
    .then((res) => res.blob())
    .then((file) => {
      const iframe = document.createElement('iframe');
      document.body.appendChild(iframe);

      const fileUrl = URL.createObjectURL(file);

      const cleanup = () => {
        if (document.body.contains(iframe)) {
          document.body.removeChild(iframe);
          URL.revokeObjectURL(fileUrl);
          window.removeEventListener('focus', cleanup);
        }
      };

      iframe.id = 'printFrame';
      iframe.style.display = 'none';
      iframe.src = fileUrl;
      iframe.onload = () => {
        iframe.focus();
        iframe.contentWindow?.print();

        window.addEventListener('focus', cleanup);
      };
    })
    .catch(showNotification.error);
};
