import {DisplayValue} from '../types';

export const getThumbLabelDisplay = (displayValue: DisplayValue, forHover = false) => {
  if (displayValue === 'on') {
    return 'block';
  }
  if (displayValue === 'auto' && forHover) {
    return 'block';
  }
  return 'none';
};
