import {SchemaOf, ValidationError} from 'yup';

import {useEffect, useState} from 'react';

import {defaultTo, equals, isNil, isNotNil, not} from 'ramda';

import {Nullish, useDebouncedValue} from 'shared';

import {EitherQuantityOrError} from '../types/basket/EitherQuantityOrError';

interface UseBasketItemQuantityProps {
  itemId: string;
  currentQuantity: number | null;
  validationSchema: SchemaOf<unknown>;
  onQuantityChange: (itemId: string, newQuantity: EitherQuantityOrError) => void;
}

const DEBOUNCE_DELAY = 500;

export function useBasketItemQuantity(props: UseBasketItemQuantityProps) {
  const [quantity, setQuantity] = useState<number | null>(defaultTo(null, props.currentQuantity));
  const [quantityError, setQuantityError] = useState<string | Nullish>(null);

  const debouncedQuantity = useDebouncedValue(quantity, DEBOUNCE_DELAY);

  // Controls the local changes of quantity value
  useEffect(() => {
    const hasValidQuantity = validateQuantity(quantity);
    const shouldNotChangeQuantity = not(hasValidQuantity) || isNil(debouncedQuantity);

    if (shouldNotChangeQuantity) {
      return;
    }

    props.onQuantityChange(props.itemId, {newQuantity: debouncedQuantity});
    // We want to handle the debouncedQuantity separately from props.item.quantity
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuantity]);

  // Controls the changes that happened outside of the basket item quantity input
  // e.g. edit dialog
  useEffect(() => {
    const hasValidQuantity = isNotNil(props.currentQuantity);
    const shouldQuantityChange = not(equals(props.currentQuantity, debouncedQuantity));

    if (hasValidQuantity && shouldQuantityChange) {
      setQuantity(props.currentQuantity);
    }
    // We want to handle the props.item.quantity separately from debouncedQuantity
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentQuantity]);

  const validateQuantity = (value: number | null) => {
    const valueToValidate = defaultTo(0, value);

    try {
      setQuantityError(null);

      props.validationSchema.validateSync(valueToValidate);

      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        setQuantityError(error.errors.join(' '));
      }

      return false;
    }
  };

  const handleQuantityChange = (quantity: number | null) => {
    const hasValidQuantity = validateQuantity(quantity);

    if (hasValidQuantity) {
      return setQuantity(quantity);
    }

    props.onQuantityChange(props.itemId, {hasError: true});
    setQuantity(quantity);
  };

  return {
    quantity,
    quantityError,
    handleQuantityChange,
  };
}
