import {gql} from '@apollo/client';

export const SEND_NOTIFICATION_SUBSCRIPTION = gql`
  subscription OnSendNotification {
    onSendNotification {
      id
      tenantId
      userId
      toastNotificationId
      backgroundNotificationId
    }
  }
`;
