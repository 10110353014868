import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  closeCurrentDialog,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {isNotNil, not} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  BulkPatchDeliveryNoteRequest,
  useGetCurrenciesQuery,
  useGetWarehousesQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {getOptionsFromCurrencies, getOptionsFromWarehouses} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId, yupString} from 'shared';

interface DeliveryNoteListBulkEditProps extends RequiredTestIdProps {
  rowId: string | string[];
  onSubmit: (requestBody: BulkPatchDeliveryNoteRequest) => Promise<void>;
}

type BulkEditForm = {
  warehouse: {
    isWarehouseEnabled: boolean;
    warehouseId: string;
  };
  currency: {
    isCurrencyEnabled: boolean;
    currency: string;
  };
};

export function DeliveryNoteListBulkEdit(props: DeliveryNoteListBulkEditProps) {
  const {data: warehouses, isLoading: areWarehousesLoading} = useGetWarehousesQuery();
  const {data: currencies, isLoading: areCurrenciesLoading} = useGetCurrenciesQuery();

  const handleDiscard = () => closeCurrentDialog();

  const handleSubmit: FormSubmitHandler<BulkEditForm> = (formValues) =>
    props.onSubmit({
      deliveryNoteId: isArray(props.rowId) ? props.rowId : [props.rowId],
      warehouseId: formValues.warehouse.warehouseId,
      currency: formValues.currency.currency,
    });

  return (
    <Form<BulkEditForm> schema={formSchema} onSubmit={handleSubmit}>
      {(control, formApi) => {
        const isWarehouseEnabled = formApi.watch('warehouse.isWarehouseEnabled');
        const isCurrencyEnabled = formApi.watch('currency.isCurrencyEnabled');
        const warehouseId = formApi.watch('warehouse.warehouseId');
        const currency = formApi.watch('currency.currency');
        const hasChanges = isNotNil(warehouseId) || isNotNil(currency);

        return (
          <VStack spacing={4}>
            <HStack spacing={4} align="flex-end">
              <FormField
                name="warehouse.isWarehouseEnabled"
                type="switch"
                control={control}
                data-testid={suffixTestId('inputs.isWarehouseEnabled', props)}
              />
              <Box flex={2}>
                <FormField
                  name="warehouse.warehouseId"
                  type="choice"
                  control={control}
                  label={i18n.t('entity.warehouse.labels.warehouse')}
                  options={getOptionsFromWarehouses(warehouses)}
                  isDisabled={not(isWarehouseEnabled)}
                  isLoading={areWarehousesLoading}
                  menuInPortal
                  data-testid={suffixTestId('inputs.warehouseId', props)}
                />
              </Box>
            </HStack>

            <HStack spacing={4} align="flex-end">
              <FormField
                name="currency.isCurrencyEnabled"
                type="switch"
                control={control}
                data-testid={suffixTestId('inputs.isCurrencyEnabled', props)}
              />
              <Box flex={2}>
                <FormField
                  name="currency.currency"
                  type="choice"
                  control={control}
                  label={i18n.t('entity.warehouse.labels.currency')}
                  options={getOptionsFromCurrencies(currencies)}
                  isDisabled={not(isCurrencyEnabled)}
                  isLoading={areCurrenciesLoading}
                  menuInPortal
                  data-testid={suffixTestId('inputs.currency', props)}
                />
              </Box>
            </HStack>

            <ButtonGroup align="right">
              <Button
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                onClick={handleDiscard}
                data-testid={suffixTestId('actions.discard', props)}
              />
              <FormButton
                type="submit"
                control={control}
                title={i18n.t('general.actions.apply')}
                isDisabled={not(hasChanges)}
                data-testid={suffixTestId('actions.submit', props)}
              />
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}

const formSchema = object({
  warehouse: object({
    isWarehouseEnabled: boolean().default(false),
    warehouseId: yupString,
  }),
  currency: object({
    isCurrencyEnabled: boolean().default(false),
    currency: yupString,
  }),
});
