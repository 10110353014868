import {
  Image,
  Box,
  Text,
  VStack,
  HStack,
  Hide,
  Link,
  Center,
  Align,
  Show,
} from 'platform/foundation';
import styled from 'styled-components';
import {Pattern, match} from 'ts-pattern';

import {ReactNode, useEffect, useRef} from 'react';

import {always} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {useBoolean} from 'shared';

const SUPPORT_URL = 'https://www.omnetic.com/contact-us/';

type AuthContainerProps = {
  children: ReactNode;
};

export function AuthContainer(props: AuthContainerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isVideoLoaded, setIsVideoLoaded] = useBoolean();

  useEffect(() => {
    const ref = videoRef.current;

    ref?.addEventListener('loadeddata', setIsVideoLoaded);

    return () => ref?.removeEventListener('loadeddata', setIsVideoLoaded);
  }, [setIsVideoLoaded]);

  const videoSrc = match(window.screen.height * window.devicePixelRatio)
    .with(Pattern.number.lte(720), always('assets/videos/login_banner_720p.mp4'))
    .with(Pattern.number.lte(1080), always('assets/videos/login_banner_1080p.mp4'))
    .with(Pattern.number.lte(1440), always('assets/videos/login_banner_1440p.mp4'))
    .otherwise(always('assets/videos/login_banner_4k.mp4'));

  return (
    <HStack height="100%">
      <Box
        paddingTop={20}
        paddingBottom={[10, 20, 20, 20]}
        paddingHorizontal={[6, 0, 0, 0]}
        flex={1}
      >
        <VStack align="center" justify="space-between" height="100%">
          <Image height={6} src="assets/images/logo_alt.svg" fit="contain" />
          <Box width="100%" maxWidth={90}>
            {props.children}
          </Box>
          <HStack spacing={1}>
            <Text size="small" color="tertiary">
              {i18n.t('page.login.labels.helpText')}
            </Text>
            <Link
              size="small"
              title={i18n.t('page.login.labels.supportPage')}
              href={SUPPORT_URL}
              target="_blank"
            />
          </HStack>
        </VStack>
      </Box>
      <Hide onMobile>
        <Box flex={1} position="relative">
          <VideoContainer ref={videoRef} autoPlay muted loop>
            <source src={videoSrc} type="video/mp4" />
          </VideoContainer>
          <Show when={isVideoLoaded}>
            <Box position="absolute" top={0} bottom={0} left={0} right={0}>
              <Center height="100%">
                <Box padding={20}>
                  <Align center>
                    <LargeHeading>{i18n.t('page.login.labels.bannerText')}</LargeHeading>
                  </Align>
                </Box>
              </Center>
            </Box>
          </Show>
        </Box>
      </Hide>
    </HStack>
  );
}

// Specific style is needed here
// eslint-disable-next-line eag/no-css-property
const LargeHeading = styled.div`
  font-size: ${({theme}) => theme.getSize(12)};
  font-family: ${({theme}) => theme.fonts.heading};
  font-weight: 600;
  color: ${({theme}) => theme.colors.general.white};
`;

const VideoContainer = styled.video`
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
`;
