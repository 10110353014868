import {UniqueIdentifier} from '@dnd-kit/core';

import {ID_DELIMITER} from './ID_DELIMITER';

export const parseDndItemId = (dndItemId: UniqueIdentifier) => {
  if (typeof dndItemId !== 'string') {
    throw new Error('Invalid item id - not a string');
  }

  const [prefix, id, containerId, url, position] = dndItemId.split(ID_DELIMITER);

  if (prefix !== 'sortable' && prefix !== 'droppable') {
    throw new Error('Invalid item id - invalid prefix');
  }

  if (!id) {
    throw new Error('Invalid item id');
  }

  if (prefix === 'sortable' && (!url || !position || !containerId)) {
    throw new Error('Invalid sortable item id - missing url, position or containerId');
  }

  if (prefix === 'sortable' && position && isNaN(parseInt(position, 10))) {
    throw new Error('Invalid sortable item id - position is not a number');
  }

  if (prefix === 'droppable') {
    return {id, isSortable: false};
  }
  return {id, url, position: parseInt(position, 10), containerId, isSortable: true};
};
