import {path} from 'ramda';

export const reduceArrayToObject = <T,>(array: T[], keyPath: string[]): Record<string, T> =>
  array.reduce((acc, value) => {
    const key = path(keyPath, value) as string;
    return {
      ...acc,
      [key]: value,
    };
  }, {});
