import {Option} from 'platform/components';

import {Nullish} from 'shared';

interface CreateVehicleTitleProps {
  formMake: string | Nullish;
  formModel: string | Nullish;
  /** Group of makes where to find make name. Usually combined with useVehicleMake() hook */
  flatMakes: Option[];
  /** Group of models where to find model name. Usually combined with useVehicleModel() hook */
  flatModels: Option[];
}

export function createVehicleTitle(props: CreateVehicleTitleProps) {
  const makeName =
    props.flatMakes.find((item) => item.value === props.formMake)?.label || props.formMake || null;

  if (!makeName) {
    return null;
  }

  const modelName =
    props.flatModels.find((item) => item.value === props.formModel)?.label ||
    props.formModel ||
    null;

  if (makeName && !modelName) {
    return `${makeName}`;
  }

  return `${makeName} ${modelName}`;
}
