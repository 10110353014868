import {isEmpty, reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

export const getUserName = <T extends {lastName?: string | Nullish; firstName?: string | Nullish}>(
  user: T | Nullish
) => {
  const nameArray = reject(isNilOrEmpty, [user?.lastName, user?.firstName]);

  if (isEmpty(nameArray)) {
    return null;
  }

  return nameArray.join(' ');
};
