import {Button, Card, GridPhoto, PhotoGrid} from 'platform/components';
import {HStack, Heading, Show, VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';
import {useDateTimeFormatter} from 'platform/locale';

import {defaultTo, isEmpty, isNil, isNotEmpty} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  useGetAuditDataQuery,
  useGetMetadaServiceCaseQuery,
  useGetServiceCheckInQuery,
  useGetServiceHandOverQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {workshopRoutes} from '@omnetic-dms/routes';
import {EMPTY_PLACEHOLDER, getGridPhotosFromAudit} from '@omnetic-dms/shared';

import {parseDate, suffixTestId, composePath, TestIdProps, Nullish} from 'shared';

interface HistoryPhotosCardProps extends TestIdProps {
  photos: GridPhoto[];
  serviceCaseId: string | null;
  onDownloadAlbum: VoidFunction;
  onDownloadInspection: (auditId?: string) => void;
}

export function HistoryPhotosCard(props: HistoryPhotosCardProps) {
  const formatDateTime = useDateTimeFormatter();

  const {data} = useGetMetadaServiceCaseQuery(
    {serviceCaseId: props.serviceCaseId ?? ''},
    {skip: !props.serviceCaseId}
  );
  const {data: checkin} = useGetServiceCheckInQuery(
    {
      serviceCaseId: props.serviceCaseId ?? '',
    },
    {skip: !props.serviceCaseId}
  );
  const {data: handover} = useGetServiceHandOverQuery(
    {
      serviceCaseId: props.serviceCaseId ?? '',
    },
    {skip: !props.serviceCaseId}
  );
  const {data: checkinAudit} = useGetAuditDataQuery(
    {
      auditId: checkin?.inspectionId?.[0] ?? '',
    },
    {
      skip: isNil(checkin?.inspectionId?.[0]),
      refetchOnMountOrArgChange: true,
    }
  );
  const {data: handoverAudit} = useGetAuditDataQuery(
    {
      auditId: handover?.inspectionId?.[0] ?? '',
    },
    {
      skip: isNil(handover?.inspectionId?.[0]),
      refetchOnMountOrArgChange: true,
    }
  );

  const hasAllRequiredData = isNotNil(data) && isNotNil(data.createdAt) && isNotNil(data.number);

  const checkinPhotos = getGridPhotosFromAudit(checkinAudit?.[0]?.categories);
  const handoverPhotos = getGridPhotosFromAudit(handoverAudit?.[0]?.categories);
  const [serviceCasePhotosLightboxControls, {onOpen: onServiceCasePhotoOpen}] =
    useLightbox('serviceCasePhotos');
  const [checkinPhotosLightboxControls, {onOpen: onCheckinPhotoOpen}] =
    useLightbox('checkinPhotos');
  const [handoverPhotosLightboxControls, {onOpen: onHandoverPhotoOpen}] =
    useLightbox('handoverPhotos');

  if (isEmpty(props.photos) && isEmpty(checkinPhotos) && isEmpty(handoverPhotos)) {
    return null;
  }

  return (
    <Card
      title={
        hasAllRequiredData
          ? `${formatDateTime('dateShort', parseDate(data.createdAt!))} - ${data.number}`
          : EMPTY_PLACEHOLDER
      }
      variant="inlineWhite"
      isExpandable
      isClosedByDefault
      actions={[
        {
          type: 'button',
          variant: 'link',
          title: i18n.t('entity.workshop.actions.openServiceCase'),
          leftIcon: 'action/launch',
          onClick: () =>
            window.open(
              composePath(workshopRoutes.serviceCaseDetail, {
                params: {id: defaultTo(undefined, data?.id)},
              }),
              '_blank'
            ),
        },
      ]}
      data-testid={props['data-testid']}
    >
      <VStack spacing={4}>
        <Show when={isNotEmpty(props.photos)}>
          <HStack justify="space-between">
            <Heading size={4} alternative>
              {i18n.t('entity.photo.labels.photos')}
            </Heading>
            <Button
              data-testid={suffixTestId('serviceCasePhotos-downloadAll', props)}
              variant="link"
              title={i18n.t('general.actions.downloadAll')}
              leftIcon="file/download"
              onClick={props.onDownloadAlbum}
              size="small"
            />
          </HStack>
          <PhotoGrid
            images={props.photos}
            onSlideClick={(_, index) => onServiceCasePhotoOpen(index)}
            data-testid={suffixTestId('serviceCasePhotos', props)}
          />
          <Lightbox
            data-testid={suffixTestId('serviceCasePhotos', props)}
            controls={serviceCasePhotosLightboxControls}
            images={makeLightboxPhotos(props.photos)}
          />
        </Show>
        <Show when={isNotEmpty(checkinPhotos)}>
          <HStack justify="space-between">
            <Heading size={4} alternative>
              {i18n.t('entity.workshop.labels.checkIn')}
            </Heading>
            <Button
              data-testid={suffixTestId('checkin-downloadAll', props)}
              variant="link"
              title={i18n.t('general.actions.downloadAll')}
              leftIcon="file/download"
              onClick={() => props.onDownloadInspection(checkinAudit?.[0]?.id)}
              size="small"
            />
          </HStack>
          <PhotoGrid
            images={checkinPhotos}
            onSlideClick={(_, index) => onCheckinPhotoOpen(index)}
            data-testid={suffixTestId('checkinPhotos', props)}
          />
          <Lightbox
            data-testid={suffixTestId('checkinPhotos', props)}
            controls={checkinPhotosLightboxControls}
            images={makeLightboxPhotos(checkinPhotos)}
          />
        </Show>
        <Show when={isNotEmpty(handoverPhotos)}>
          <HStack justify="space-between">
            <Heading size={4} alternative>
              {i18n.t('entity.workshop.labels.handover')}
            </Heading>
            <Button
              data-testid={suffixTestId('handover-downloadAll', props)}
              variant="link"
              title={i18n.t('general.actions.downloadAll')}
              leftIcon="file/download"
              onClick={() => props.onDownloadInspection(handoverAudit?.[0]?.id)}
              size="small"
            />
          </HStack>
          <PhotoGrid
            images={handoverPhotos}
            onSlideClick={(_, index) => onHandoverPhotoOpen(index)}
            data-testid={suffixTestId('handoverPhotos', props)}
          />
          <Lightbox
            data-testid={suffixTestId('handoverPhotos', props)}
            controls={handoverPhotosLightboxControls}
            images={makeLightboxPhotos(handoverPhotos)}
          />
        </Show>
      </VStack>
    </Card>
  );
}

const makeLightboxPhotos = (photos: GridPhoto[] | Nullish) =>
  photos?.map((image) => ({
    id: image.id,
    url: image.originalUrl?.replace('resize', 'get') ?? image.url.replace('resize', 'get'),
    resizeUrl: image.resizeUrl,
    title: image.title,
    fileName: image.title || image.id ? `${image.title || image.id}.jpg` : undefined,
  }));
