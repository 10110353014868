import type {CancelablePromise} from '../types/CancelablePromise';
import type {SetPrimaryTemplateRequestBody} from '../types/SetPrimaryTemplateRequestBody';
import {request as __request} from '../utils/request';

export class SetPrimaryTemplateService {
  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static setPrimaryTemplate({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: SetPrimaryTemplateRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/document/template/set-primary',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
