import {isEmpty, isNotEmpty, isNotNil} from 'ramda';
import {isFalse} from 'ramda-adjunct';

import {TableDataType} from '../hooks/useGetACLData';

export const getResourceCheckboxValues = (resource: TableDataType) => {
  const hasSomePermissions = (data: TableDataType): boolean => {
    const hasSomeActionSelected = data.protectedUnits.some((action) => isNotNil(action.permission));
    if (hasSomeActionSelected) {
      return true;
    }

    const hasAnySubResourcePermission = data.children?.some(hasSomePermissions) || false;

    return hasAnySubResourcePermission;
  };

  const hasAllPermission = (data: TableDataType): boolean => {
    const hasAllActionSelected = data.protectedUnits.every((action) => isNotNil(action.permission));

    if (isEmpty(data.protectedUnits) && isEmpty(data.children)) {
      return false;
    }
    if (isEmpty(data.children)) {
      return hasAllActionSelected;
    }

    const hasAllSubResourcePermission = data.children?.every(hasAllPermission);
    return hasAllSubResourcePermission && hasAllActionSelected;
  };

  const hasAllPermissionsGranted = resource.protectedUnits.every((action) =>
    isNotNil(action.permission)
  );
  const hasAllSubResourcePermissionsGranted = resource.children.every(hasAllPermission);
  const hasSomePermissionsGranted = resource.protectedUnits.some((action) =>
    isNotNil(action.permission)
  );

  const hasSomeSubResourcePermissionsGranted =
    isNotEmpty(resource.children) && resource.children.some(hasSomePermissions);

  const isChecked = hasAllPermissionsGranted && hasAllSubResourcePermissionsGranted;

  const isIndeterminate =
    isFalse(isChecked) && (hasSomePermissionsGranted || hasSomeSubResourcePermissionsGranted);

  return {isChecked, isIndeterminate};
};
