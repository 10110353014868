import {Box, HStack, Heading} from 'platform/foundation';

export const TableHeader = (props: {title: string}) => (
  <Box paddingVertical={2}>
    <Box paddingHorizontal={2} borderLeft="solid 1px" borderColor="general.separator">
      <HStack>
        <Heading color="secondary" size={5}>
          {props.title}
        </Heading>
      </HStack>
    </Box>
  </Box>
);
