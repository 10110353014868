import {HStack} from 'platform/foundation';

import {times} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {Level} from './components/Level';
import {getRankValue} from './utils/getRankValue';

interface RankProps extends TestIdProps {
  /**
   * @about Accepting values in range 0 - 100
   */
  percentage: number | null;
}

export function Rank(props: RankProps) {
  const rankValue = getRankValue(props.percentage);

  return (
    <HStack width={6} justify="space-between" data-testid={suffixTestId('battery', props)}>
      {times(
        (currentValue) => (
          <Level key={currentValue} value={rankValue > currentValue ? rankValue : undefined} />
        ),
        5
      )}
    </HStack>
  );
}
