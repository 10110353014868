import {CheckboxTreeOptionType} from 'platform/components';

/**
 * Checks if a selected value exists in the tree nodes or any of its descendant nodes.
 *
 * @param selectedValue The value to search for.
 * @param nodes The tree nodes to search in.
 * @returns True if the selected value is found in any node or its descendants.
 */
export const findRecursiveChild = (
  selectedValue: string,
  nodes: readonly CheckboxTreeOptionType[]
): boolean =>
  nodes?.some(
    (node) =>
      node.value === selectedValue ||
      (node.options && findRecursiveChild(selectedValue, node.options))
  ) ?? false;

/**
 * Finds the topmost item in the tree that contains the selected value either directly or in its descendants.
 *
 * @param selectedValue The value to search for.
 * @param workingOptionsRow The tree nodes to search in.
 * @returns The topmost tree node that contains the selected value.
 */
export const getTopMostItem = (
  selectedValue: string,
  workingOptionsRow: readonly CheckboxTreeOptionType[]
) =>
  workingOptionsRow?.find(
    (row) =>
      row.value === selectedValue || (row.options && findRecursiveChild(selectedValue, row.options))
  ) ?? null;

/**
 * Retrieves all descendant keys of a tree node, including the node's own key.
 *
 * @param node The tree node.
 * @returns An array of strings containing all the keys of the node and its descendants.
 */
export const getAllChildKeys = (node: CheckboxTreeOptionType): string[] =>
  node.options
    ? node.options.reduce((acc, child) => [...acc, ...getAllChildKeys(child)], [node.value])
    : [node.value];
