import {Image, Box, Center} from 'platform/foundation';
import styled from 'styled-components';

import {suffixTestId, TestIdProps} from 'shared';

export function PriceReportWidgetLogo(props: TestIdProps) {
  return (
    <ImageBackground>
      <Box height="100%" paddingLeft={9} paddingRight={9}>
        <Center height="100%">
          <Image
            data-testid={suffixTestId('priceReportWidgetLogo', props)}
            src="assets/images/price_report_logo.svg"
            width={20}
          />
        </Center>
      </Box>
    </ImageBackground>
  );
}

const ImageBackground = styled.div`
  background: linear-gradient(0deg, rgb(62, 71, 221) 0%, rgb(38, 42, 152) 100%);
  min-height: 100%;
`;
