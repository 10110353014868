import {addDays, subDays} from 'date-fns';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {getApiDateString, isApiDateString, Nullish} from 'shared';

export enum DateShortcut {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  YESTERDAY = 'YESTERDAY',
  NEXT_WEEK = 'NEXT_WEEK',
  NEXT_MONTH = 'NEXT_MONTH',
  NEXT_MONDAY = 'NEXT_MONDAY',
}

export const getApiDateFromRelativeDate = (date: string | Nullish): string | null => {
  if (isApiDateString(date)) {
    return date;
  }

  const relativeDate = match<string | Nullish, Date | null>(date)
    .with(DateShortcut.YESTERDAY, always(subDays(new Date(), 1)))
    .with(DateShortcut.TOMORROW, always(addDays(new Date(), 1)))
    .with(DateShortcut.TODAY, 'QUICK_FILTER_EMPTY', 'QUICK_FILTER_FILLED', always(new Date()))
    .otherwise(always(null));

  return isNotNil(relativeDate) ? getApiDateString(relativeDate) : null;
};
