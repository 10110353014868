import {css} from 'styled-components';

import {ReactNode} from 'react';

import {TestIdProps} from 'shared';

import {useResponsivePropValue} from '../../hooks/useResponsivePropValue';
import {FlexboxAlign} from '../../types/Flexbox';
import {Integer} from '../../types/Integer';
import {ValueByDevice} from '../../types/ValueByDevice';
import {getSize} from '../../utils/getSize';

export interface GridProps extends TestIdProps {
  columns: number | ValueByDevice<number>;
  spacing?: Integer | ValueByDevice<Integer>;
  verticalSpacing?: Integer | ValueByDevice<Integer>;
  horizontalSpacing?: Integer | ValueByDevice<Integer>;
  children?: ReactNode;
  align?: FlexboxAlign;
}

export function Grid(props: GridProps) {
  const columns = useResponsivePropValue(props.columns);
  const spacing = useResponsivePropValue(props.spacing);
  const verticalSpacing = useResponsivePropValue(props.verticalSpacing);
  const horizontalSpacing = useResponsivePropValue(props.horizontalSpacing);

  const verticalGap = verticalSpacing ?? spacing ?? DEFAULT_GAP;
  const horizontalGap = horizontalSpacing ?? spacing ?? DEFAULT_GAP;

  return (
    <div
      data-testid={props['data-testid']}
      css={css`
        display: grid;
        grid-template-columns: repeat(${columns}, 1fr);
        align-items: ${props.align ?? 'initial'};
        gap: ${getSize(verticalGap)} ${getSize(horizontalGap)};
        min-width: 0;
      `}
    >
      {props.children}
    </div>
  );
}

const DEFAULT_GAP = 4;
