import {MouseEventHandler} from 'react';

import {omit} from 'ramda';

import {TestIdProps} from 'shared';

import {Button, ButtonProps} from '../../Button/Button';

export interface ButtonActionProps extends Omit<ButtonProps, 'type'>, TestIdProps {
  type: 'button';
  title: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buttonType?: ButtonProps['type'];
}

export function ButtonAction(props: ButtonActionProps) {
  const buttonProps = withoutType(props);

  return <Button {...buttonProps} data-testid={props['data-testid']} type={props.buttonType} />;
}

const withoutType = omit(['type']);
