import {TRANSLATED_LANGUAGES} from '../../../types/CommonAppTypes';

export const getTranslation = (
  locale: TRANSLATED_LANGUAGES,
  translatable?: Record<string, string>
) => {
  if (!translatable) {
    return '';
  }
  switch (locale) {
    case TRANSLATED_LANGUAGES.czech:
      return translatable.cs;
    case TRANSLATED_LANGUAGES.slovak:
      return translatable.sk;
    case TRANSLATED_LANGUAGES.germany:
      return translatable.de;
    case TRANSLATED_LANGUAGES.france:
      return translatable.fr;
    case TRANSLATED_LANGUAGES.spain:
      return translatable.es;
    case TRANSLATED_LANGUAGES.poland:
      return translatable.pl;
    case TRANSLATED_LANGUAGES.italy:
      return translatable.it;
    default:
      return translatable.en;
  }
};
