import {useEffect} from 'react';

import i18n from '@omnetic-dms/i18n';
import {loadDocumentSeriesList, SeriesTypeEnum, useThunkDispatch} from '@omnetic-dms/teas';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {CustomerSeriesDefinitionList} from './components/CustomerSeriesDefinitionList';

export function CustomerCodeDefinition() {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(
      loadDocumentSeriesList({
        type: [SeriesTypeEnum.GENERAL_CUSTOMER],
        config: {sendBranch: false},
      })
    );
  }, [dispatch]);

  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.seriesDefinition.labels.customerCodeDefinition')}}
      data-testid="integrations-customer-definitions-settings-modal"
      description={i18n.t('entity.seriesDefinition.labels.customerDefinitionDescription')}
    >
      <CustomerSeriesDefinitionList />
    </SettingsTemplate>
  );
}
