import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function AccountingActivityLog() {
  return (
    <SettingsTemplate
      data-testid={testIds.settings.accountingActivityLog('header')}
      header={{
        title: i18n.t('page.accounting.labels.accountingActivityLog'),
      }}
    >
      <DataGrid gridCode="documents-pre-accounting" />
    </SettingsTemplate>
  );
}
