const tabFields: Record<number, string[]> = {
  0: [
    'make',
    'firstRegistrationOnDay',
    'firstRegistrationOnMonth',
    'firstRegistrationOnYear',
    'manufacturedOnMonth',
    'manufacturedOnYear',
    'transmission',
    'drive',
    'doorCountCategory',
    'type',
    'bodyStyle',
    'modelFamily',
    'trim',
    'variant',
    'title',
    'engine',
    'dimensions',
  ],
  1: ['state', 'modelSpecification'],
  2: ['features'],
  3: ['photos'],
  4: ['conditions'],
};

export function findErrorTabIndex(errors: Record<string, string>, returnIndex?: boolean) {
  const keys = [];

  for (const error of Object.keys(errors)) {
    for (const key in tabFields) {
      if (tabFields[key].includes(error)) {
        if (returnIndex) {
          return key;
        }
        keys.push(key);
      }
    }
  }

  return keys;
}
