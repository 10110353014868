import {useState, useTransition} from 'react';
import {useSearchParams} from 'react-router-dom';

import {ServiceVehicleFormTabs} from '../types/ServiceVehicleFormTabs';

const QUERY_TAB_KEY = 'tab';

export function useTabs() {
  const [queryParams, setQueryParams] = useSearchParams();
  const query = new URLSearchParams(queryParams);
  const [isPending, startTransition] = useTransition();

  const [selectedInnerTab, setSelectedInnerTab] = useState<ServiceVehicleFormTabs>(
    (query.get(QUERY_TAB_KEY) || ServiceVehicleFormTabs.BasicInformation) as ServiceVehicleFormTabs
  );

  const setSelectedTab = (newTab: ServiceVehicleFormTabs) => {
    setSelectedInnerTab(newTab);
    startTransition(() => {
      setQueryParams({[QUERY_TAB_KEY]: newTab}, {replace: true});
    });
  };

  return {selectedTab: selectedInnerTab, isPending, setSelectedTab};
}
