import {AttributesRow} from 'platform/components';

import {defaultTo} from 'ramda';

import {useGetVehicleQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, buildArray} from 'shared';

import {useVehicleCatalogue} from '../../../hooks/useVehicleCatalogue';

export function useSpecificationVehicleParameters(vehicleId: string | Nullish) {
  const {data: vehicle} = useGetVehicleQuery(
    {vehicleId: defaultTo('', vehicleId)},
    {skip: !vehicleId}
  );

  const [vehicleUtils] = useVehicleCatalogue(vehicle?.type);

  const bodyStyle = vehicleUtils.getBodyStyle(vehicle?.bodyStyle);
  const transmission = vehicleUtils.getTransmission(vehicle?.transmission);
  const drive = vehicleUtils.getDrive(vehicle?.drive);
  const doorCountCategory = vehicleUtils.getDoorCount(vehicle?.doorCountCategory);
  const handDriveType = vehicleUtils.getHandDrive(vehicle?.handDriveType);

  return buildArray<AttributesRow>()
    .add({
      label: i18n.t('entity.vehicle.labels.body'),
      value: bodyStyle,
      testId: 'body',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.transmission'),
      value: transmission,
      testId: 'transmission',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.drive'),
      value: drive,
      testId: 'drive',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.numberOfDoors'),
      value: doorCountCategory,
      testId: 'numberOfDoors',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.numberOfSeats'),
      value: vehicle?.modelSpecification?.seatCount,
      testId: 'numberOfSeats',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.handDriveType'),
      value: handDriveType,
      testId: 'handDriveType',
    });
}
