import {SeriesDefinitionService} from '../../services/SeriesDefinitionService';
import {asyncThunkAction} from '../../utils/reduxThunkUtils';
import {INTEREST_SERIES_DEFINITION_SLICE_NAME} from './constants';

/**
 * Get list of definitions
 */
export const getInterestSeriesDefinitionList = asyncThunkAction(
  `${INTEREST_SERIES_DEFINITION_SLICE_NAME}/getSeriesDefinitionList`,
  SeriesDefinitionService.getInterestSeriesDefinitionList
);

/**
 * Get definition detail
 */
export const getInterestSeriesDefinition = asyncThunkAction(
  `${INTEREST_SERIES_DEFINITION_SLICE_NAME}/getSeriesDefinition`,
  SeriesDefinitionService.getInterestSeriesDefinition
);
