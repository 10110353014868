import {DataStatus, showNotification} from 'platform/components';

import {useCallback, useMemo} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetAutostacjaSvConnectorConfigQuery,
  usePatchAutostacjaSvConnectorConfigMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {
  SettingsStackItemProps,
  SettingsStackItems,
} from '../../../components/SettingsStackItems/SettingsStackItems';

export type FormValues = {
  subscriptionKey: string;
};

export function AutostacjaSettingsList() {
  const {
    data: autostacjaQueryData,
    isLoading: isAutostacjaQueryLoading,
    isError: isAutostacjaQueryError,
    refetch: autostacjaQueryRefetch,
  } = useGetAutostacjaSvConnectorConfigQuery();

  const [patchAutostacjaMutation] = usePatchAutostacjaSvConnectorConfigMutation();

  const handleSwitchChange = useCallback(
    (
      mutation: typeof patchAutostacjaMutation,
      enabled: boolean,
      refetch?: typeof autostacjaQueryRefetch
    ) => {
      mutation({body: {enabled}})
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
          refetch?.();
        })
        .catch(handleApiError);
    },
    []
  );

  const items = useMemo(
    () =>
      [
        {
          cyId: testIds.settings.autostacjaSettings('link'),
          title: i18n.t('page.integrations.labels.autostacja'),
          url: settingsRoutes.autostacjaSettingsSubscriptionKey,
          switchProps: {
            name: 'autostacja-subscription-key',
            // This condition was required by the original acceptance criteria
            // isDisabled: !autostacjaQueryData?.credentials?.subscriptionKey,
            isDisabled: false,
            value: autostacjaQueryData?.enabled,
            onChange: (enabled) =>
              handleSwitchChange(patchAutostacjaMutation, enabled, autostacjaQueryRefetch),
          },
        },
      ] as SettingsStackItemProps[],
    [
      autostacjaQueryData?.enabled,
      autostacjaQueryRefetch,
      handleSwitchChange,
      patchAutostacjaMutation,
    ]
  );

  return (
    <DataStatus
      isLoading={isAutostacjaQueryLoading}
      isError={isAutostacjaQueryError}
      isEmpty={isNilOrEmpty(items)}
      minHeight={50}
    >
      <SettingsStackItems items={items} />
    </DataStatus>
  );
}
