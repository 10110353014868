import {
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
  Separator,
} from 'platform/components';
import {Grid, VStack, Space} from 'platform/foundation';
import {boolean, object, string} from 'yup';

import {UseFormReturn} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isFalse, isNotNil} from 'ramda-adjunct';

import {
  useGetArticleCategoryQuery,
  usePatchArticleCategoryMutation,
  usePostArticleCategoryMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export type WarehousesArticleCategoryForm = {
  name?: string;
  code?: string | null;
  active: boolean;
  isDefault: boolean;
};

export function WarehouseArticleCategoryDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const isCreating = isNil(params.id);

  const {
    data: articleCategory,
    isLoading,
    isError,
  } = useGetArticleCategoryQuery({articleCategoryId: params.id ?? ''}, {skip: isCreating});

  const [createArticleCategory] = usePostArticleCategoryMutation();
  const [updateArticleCategory] = usePatchArticleCategoryMutation();

  const handleSubmit: FormSubmitHandler<WarehousesArticleCategoryForm> = async (data) => {
    const body = {...data, name: data.name ?? '', system: false};
    const promise = isNotNil(params.id)
      ? updateArticleCategory({
          body,
          articleCategoryId: params.id,
        })
          .unwrap()
          .then(() => {
            showNotification.success(
              i18n.t('entity.warehouse.notifications.warehouseArticleCategoryUpdated')
            );
          })
      : createArticleCategory({body})
          .unwrap()
          .then(() => {
            showNotification.success(
              i18n.t('entity.warehouse.notifications.warehouseArticleCategoryCreated')
            );
          });

    await promise.then(() => handleNavigateBack()).catch(handleApiError);
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesArticleCategory);
  };

  const defaultValues: WarehousesArticleCategoryForm = {
    name: articleCategory?.name,
    code: articleCategory?.code,
    active: articleCategory?.active ?? true,
    isDefault: articleCategory?.isDefault ?? false,
  };

  const header: SettingsTemplateHeader = {
    title: isCreating
      ? i18n.t('entity.warehouse.labels.newWarehouseCategory')
      : (articleCategory?.name ?? ''),
    breadcrumbs: [
      {
        label: i18n.t('entity.warehouse.labels.warehouseCategory'),
        href: settingsRoutes.warehousesArticleCategory,
      },
    ],
  };

  const changeActiveHandle = (
    value: boolean,
    formApi: UseFormReturn<WarehousesArticleCategoryForm>
  ) => {
    if (value) {
      return;
    }

    formApi.setValue('isDefault', false);
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<WarehousesArticleCategoryForm>
        schema={formSchema}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        isFullHeight
      >
        {(control, formApi) => {
          const active = formApi.watch('active');
          return (
            <>
              <SettingsTemplate
                header={header}
                data-testid={testIds.settings.warehousesArticleCategoryDetail('template')}
              >
                <VStack spacing={4}>
                  <FormField
                    control={control}
                    type="switch"
                    name="isDefault"
                    label={i18n.t('general.actions.setAsDefault')}
                    isDisabled={isFalse(active)}
                    data-testid={testIds.settings.warehousesArticleCategoryDetail('isDefault')}
                  />
                  <FormField
                    control={control}
                    type="switch"
                    name="active"
                    onChange={(value) => changeActiveHandle(value, formApi)}
                    label={i18n.t('entity.warehouse.labels.setAsActive')}
                    data-testid={testIds.settings.warehousesArticleCategoryDetail('active')}
                  />

                  <Grid columns={2}>
                    <Separator />
                  </Grid>

                  <Grid columns={4}>
                    <FormField
                      control={control}
                      type="text"
                      name="name"
                      label={i18n.t('entity.warehouse.labels.name')}
                      isRequired
                      data-testid={testIds.settings.warehousesArticleCategoryDetail('name')}
                    />
                    <FormField
                      control={control}
                      type="text"
                      name="code"
                      label={i18n.t('entity.warehouse.labels.code')}
                      data-testid={testIds.settings.warehousesArticleCategoryDetail('code')}
                    />
                  </Grid>
                  <Space fillAvailable />
                  <SettingsFooter
                    actions={[
                      {
                        type: 'button',
                        variant: 'secondary',
                        title: isCreating
                          ? i18n.t('general.actions.discard')
                          : i18n.t('general.actions.discardChanges'),
                        onClick: handleNavigateBack,
                        'data-testid': testIds.settings.warehousesArticleCategoryDetail('discard'),
                      },
                      {
                        type: 'form-button',
                        control,
                        buttonType: 'submit',
                        variant: 'primary',
                        title: isCreating
                          ? i18n.t('general.actions.save')
                          : i18n.t('general.actions.saveChanges'),
                        'data-testid': testIds.settings.warehousesArticleCategoryDetail('submit'),
                      },
                    ]}
                  />
                </VStack>
              </SettingsTemplate>
            </>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const formSchema = object({
  name: string().nullable().required(),
  code: string().nullable(),
  active: boolean().default(true),
});
