import {Card, DataStatus, Separator, TrendProps} from 'platform/components';
import {Grid, Heading, Show, Text, ThemeColorTextPath, VStack} from 'platform/foundation';
import {useFormatPercentage} from 'platform/locale';
import {match} from 'ts-pattern';

import {always, gt, isNil, lt, toPairs} from 'ramda';

import {useGetHistoryOrderProfitabilityQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {HistoryPriceSummaryItem} from './components/HistoryPriceSummaryItem';

interface ProfitabilityHistoryProps extends RequiredTestIdProps {
  vehicleId: string | Nullish;
}

export function ProfitabilityHistory(props: ProfitabilityHistoryProps) {
  const formatPercentage = useFormatPercentage();

  const {data, isLoading, isError} = useGetHistoryOrderProfitabilityQuery(
    {
      vehicleId: props.vehicleId ?? '',
    },
    {skip: isNil(props.vehicleId)}
  );

  const getTrendProps = (value: number | Nullish): TrendProps => ({
    label: formatPercentage(value ?? 0, 'nearest'),
    isPositive: (value ?? 0) > 0,
    isNegative: (value ?? 0) < 0,
    isSubtle: true,
  });

  const orders = toPairs(data?.serviceOrders ?? {});

  return (
    <DataStatus isLoading={isLoading} isError={isError} isEmpty={isNil(data)}>
      <VStack spacing={4}>
        <Text size="small" color="secondary">
          {i18n.t('entity.profitability.labels.profitabilityDescription')}
        </Text>
        <Card title={i18n.t('general.labels.summary')}>
          <Grid columns={4}>
            <HistoryPriceSummaryItem
              title={i18n.t('entity.profitability.labels.totalTurnover')}
              price={data?.summary?.totalTurnover?.amount}
              currency={data?.summary?.totalTurnover?.currency}
              data-testid={suffixTestId('totalTurnover', props)}
            />
            <HistoryPriceSummaryItem
              title={i18n.t('entity.profitability.labels.averageTurnoverPerServiceCase')}
              price={data?.summary?.averageTurnoverPerServiceCase?.amount}
              currency={data?.summary?.averageTurnoverPerServiceCase?.currency}
              data-testid={suffixTestId('averageTurnoverPerServiceCase', props)}
            />
            <HistoryPriceSummaryItem
              title={i18n.t('entity.profitability.labels.averageTurnoverPerServiceOrder')}
              price={data?.summary?.averageTurnoverPerServiceOrder?.amount}
              currency={data?.summary?.averageTurnoverPerServiceOrder?.currency}
              data-testid={suffixTestId('averageTurnoverPerServiceOrder', props)}
            />
            <HistoryPriceSummaryItem
              title={i18n.t('entity.profitability.labels.totalProfit')}
              price={data?.summary?.totalProfit?.amount}
              currency={data?.summary?.totalProfit?.currency}
              color={getTextColor(data?.summary?.totalProfit?.amount ?? 0)}
              trend={getTrendProps(data?.summary?.totalProfit?.margin)}
              data-testid={suffixTestId('totalProfit', props)}
            />
          </Grid>
        </Card>
        <Card title={i18n.t('entity.order.labels.serviceOrders')}>
          <VStack spacing={4}>
            {orders.map((orderPair, index) => {
              const orderType = orderPair?.[0];
              const data = orderPair?.[1];
              return (
                <>
                  <Show when={index > 0}>
                    <Separator spacing={0} />
                  </Show>
                  <Heading size={4}>{i18n.t(`entity.order.labels.${orderType}`)}</Heading>
                  <Grid columns={4}>
                    <HistoryPriceSummaryItem
                      title={i18n.t('entity.profitability.labels.salesBasePrice')}
                      price={data?.salesBasePrice?.amount}
                      currency={data?.salesBasePrice?.currency}
                      data-testid={suffixTestId(`${orderType}-salesBasePrice`, props)}
                    />
                    <HistoryPriceSummaryItem
                      title={i18n.t('general.labels.discount')}
                      price={data?.discount?.amount}
                      currency={data?.discount?.currency}
                      data-testid={suffixTestId(`${orderType}-discount`, props)}
                    />
                    <HistoryPriceSummaryItem
                      title={i18n.t('entity.profitability.labels.sellingPrice')}
                      price={data?.sellingPrice?.amount}
                      currency={data?.sellingPrice?.currency}
                      data-testid={suffixTestId(`${orderType}-sellingPrice`, props)}
                    />
                    <HistoryPriceSummaryItem
                      title={i18n.t('page.businessCase.labels.profit')}
                      price={data?.profit?.amount}
                      currency={data?.profit?.currency}
                      color={getTextColor(data?.profit?.amount ?? 0)}
                      trend={getTrendProps(data?.profit?.margin)}
                      data-testid={suffixTestId(`${orderType}-profit`, props)}
                    />
                  </Grid>
                </>
              );
            })}
          </VStack>
        </Card>
      </VStack>
    </DataStatus>
  );
}

const getTextColor = (value: number) =>
  match<number, ThemeColorTextPath>(value)
    .when(lt(0), always('success'))
    .when(gt(0), always('danger'))
    .otherwise(always('primary'));
