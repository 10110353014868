import {DateTimeFormat} from 'platform/locale';
import {DeepReadonly} from 'utility-types';

import {FC} from 'react';

import {isNil} from 'ramda';

import {EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';

import {Nullish, parseDate} from 'shared';

interface PlatformDateProps {
  platformCode: string;
  updatedAt?: string | Nullish;
}

export const PlatformDate: FC<DeepReadonly<PlatformDateProps>> = ({platformCode, updatedAt}) => {
  if (isNil(updatedAt)) {
    return EMPTY_PLACEHOLDER;
  }

  return (
    <DateTimeFormat
      value={parseDate(updatedAt)}
      format="dateTimeShort"
      data-testid={`date-advertisement-platforms-${platformCode}`}
    />
  );
};
