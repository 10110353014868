import {useDroppable} from '@dnd-kit/core';

interface DroppableProps {
  id: string;
  children: React.ReactElement;
  disabled?: boolean;
}

export function Droppable(props: DroppableProps) {
  const {setNodeRef} = useDroppable({
    id: props.id,
    disabled: props.disabled,
  });

  return <div ref={setNodeRef}>{props.children}</div>;
}
