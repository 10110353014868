import {
  PostServicePackageApiResponse,
  PostServicePackageApiArg,
  GetServicePackageBasicInformationApiResponse,
  GetServicePackageBasicInformationApiArg,
  DeleteServicePackageDeleteApiResponse,
  DeleteServicePackageDeleteApiArg,
  PatchServicePackageBasicInformationApiResponse,
  PatchServicePackageBasicInformationApiArg,
  GetServicePackageRequestDetailApiResponse,
  GetServicePackageRequestDetailApiArg,
  PatchServicePackageRequestDetailApiResponse,
  PatchServicePackageRequestDetailApiArg,
  PostServicePackageSetActiveApiResponse,
  PostServicePackageSetActiveApiArg,
  PostServicePackageSetInActiveApiResponse,
  PostServicePackageSetInActiveApiArg,
  GetServicePackageHoverInformationApiResponse,
  GetServicePackageHoverInformationApiArg,
  GetBasicInformationServicePackageApiResponse,
  GetBasicInformationServicePackageApiArg,
  GetServicePackageItemLabourApiResponse,
  GetServicePackageItemLabourApiArg,
  PatchServicePackageItemLabourApiArg,
  PatchServicePackageItemLabourApiResponse,
  GetServicePackageItemMaterialApiResponse,
  GetServicePackageItemMaterialApiArg,
  PatchServicePackageItemMaterialApiResponse,
  PatchServicePackageItemMaterialApiArg,
  DeleteServicePackageItemsApiResponse,
  DeleteServicePackageItemsApiArg,
  PostServicePackageApiResponseSchema,
  PostServicePackageApiArgSchema,
  GetServicePackageBasicInformationApiResponseSchema,
  GetServicePackageBasicInformationApiArgSchema,
  DeleteServicePackageDeleteApiResponseSchema,
  DeleteServicePackageDeleteApiArgSchema,
  PatchServicePackageBasicInformationApiResponseSchema,
  PatchServicePackageBasicInformationApiArgSchema,
  GetServicePackageRequestDetailApiResponseSchema,
  GetServicePackageRequestDetailApiArgSchema,
  PatchServicePackageRequestDetailApiResponseSchema,
  PatchServicePackageRequestDetailApiArgSchema,
  PostServicePackageSetActiveApiResponseSchema,
  PostServicePackageSetActiveApiArgSchema,
  PostServicePackageSetInActiveApiResponseSchema,
  PostServicePackageSetInActiveApiArgSchema,
  GetServicePackageHoverInformationApiResponseSchema,
  GetServicePackageHoverInformationApiArgSchema,
  GetBasicInformationServicePackageApiResponseSchema,
  GetBasicInformationServicePackageApiArgSchema,
  DeleteServicePackageItemsApiResponseSchema,
  DeleteServicePackageItemsApiArgSchema,
  GetServicePackageItemLabourApiResponseSchema,
  GetServicePackageItemLabourApiArgSchema,
  PatchServicePackageItemLabourApiResponseSchema,
  PatchServicePackageItemLabourApiArgSchema,
  GetServicePackageItemMaterialApiResponseSchema,
  GetServicePackageItemMaterialApiArgSchema,
  PatchServicePackageItemMaterialApiResponseSchema,
  PatchServicePackageItemMaterialApiArgSchema,
} from '../types/metadaWorkshopServicePackage';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServicePackageApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postServicePackage: build.mutation<PostServicePackageApiResponse, PostServicePackageApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-package`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['servicePackages'],
      extraOptions: {
        responseSchema: PostServicePackageApiResponseSchema,
        requestSchema: PostServicePackageApiArgSchema,
      },
    }),
    getServicePackageBasicInformation: build.query<
      GetServicePackageBasicInformationApiResponse,
      GetServicePackageBasicInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'servicePackage', id: queryArg.servicePackageId},
      ],
      extraOptions: {
        responseSchema: GetServicePackageBasicInformationApiResponseSchema,
        requestSchema: GetServicePackageBasicInformationApiArgSchema,
      },
    }),
    deleteServicePackageDelete: build.mutation<
      DeleteServicePackageDeleteApiResponse,
      DeleteServicePackageDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'servicePackages',
        {type: 'servicePackage', id: queryArg.servicePackageId},
      ],
      extraOptions: {
        responseSchema: DeleteServicePackageDeleteApiResponseSchema,
        requestSchema: DeleteServicePackageDeleteApiArgSchema,
      },
    }),
    patchServicePackageBasicInformation: build.mutation<
      PatchServicePackageBasicInformationApiResponse,
      PatchServicePackageBasicInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'servicePackages',
        {type: 'servicePackage', id: queryArg.servicePackageId},
      ],
      extraOptions: {
        responseSchema: PatchServicePackageBasicInformationApiResponseSchema,
        requestSchema: PatchServicePackageBasicInformationApiArgSchema,
      },
    }),
    getServicePackageRequestDetail: build.query<
      GetServicePackageRequestDetailApiResponse,
      GetServicePackageRequestDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}/request-detail`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceRequestDetail', id: queryArg.servicePackageId},
      ],
      extraOptions: {
        responseSchema: GetServicePackageRequestDetailApiResponseSchema,
        requestSchema: GetServicePackageRequestDetailApiArgSchema,
      },
    }),
    patchServicePackageRequestDetail: build.mutation<
      PatchServicePackageRequestDetailApiResponse,
      PatchServicePackageRequestDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}/request-detail`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceRequestDetail', id: queryArg.servicePackageId},
      ],
      extraOptions: {
        responseSchema: PatchServicePackageRequestDetailApiResponseSchema,
        requestSchema: PatchServicePackageRequestDetailApiArgSchema,
      },
    }),
    postServicePackageSetActive: build.mutation<
      PostServicePackageSetActiveApiResponse,
      PostServicePackageSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}/set-active`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'servicePackages',
        {type: 'servicePackage', id: queryArg.servicePackageId},
      ],
      extraOptions: {
        responseSchema: PostServicePackageSetActiveApiResponseSchema,
        requestSchema: PostServicePackageSetActiveApiArgSchema,
      },
    }),
    postServicePackageSetInActive: build.mutation<
      PostServicePackageSetInActiveApiResponse,
      PostServicePackageSetInActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}/set-inactive`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'servicePackages',
        {type: 'servicePackage', id: queryArg.servicePackageId},
      ],
      extraOptions: {
        responseSchema: PostServicePackageSetInActiveApiResponseSchema,
        requestSchema: PostServicePackageSetInActiveApiArgSchema,
      },
    }),
    getServicePackageHoverInformation: build.query<
      GetServicePackageHoverInformationApiResponse,
      GetServicePackageHoverInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}/hover-information`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'servicePackage', id: queryArg.servicePackageId},
      ],
      extraOptions: {
        responseSchema: GetServicePackageHoverInformationApiResponseSchema,
        requestSchema: GetServicePackageHoverInformationApiArgSchema,
      },
    }),
    getBasicInformationServicePackage: build.query<
      GetBasicInformationServicePackageApiResponse,
      GetBasicInformationServicePackageApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}/basic-information`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'servicePackage', id: queryArg.servicePackageId},
      ],
      extraOptions: {
        responseSchema: GetBasicInformationServicePackageApiResponseSchema,
        requestSchema: GetBasicInformationServicePackageApiArgSchema,
      },
    }),
    deleteServicePackageItems: build.mutation<
      DeleteServicePackageItemsApiResponse,
      DeleteServicePackageItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}/delete-items`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: DeleteServicePackageItemsApiResponseSchema,
        requestSchema: DeleteServicePackageItemsApiArgSchema,
      },
    }),
    getServicePackageItemLabour: build.query<
      GetServicePackageItemLabourApiResponse,
      GetServicePackageItemLabourApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}/item/${queryArg.servicePackageItemId}/labour`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'servicePackageLabourItem', id: queryArg.servicePackageItemId},
      ],
      extraOptions: {
        responseSchema: GetServicePackageItemLabourApiResponseSchema,
        requestSchema: GetServicePackageItemLabourApiArgSchema,
      },
    }),
    patchServicePackageItemLabour: build.mutation<
      PatchServicePackageItemLabourApiResponse,
      PatchServicePackageItemLabourApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}/item/${queryArg.servicePackageItemId}/labour`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageLabourItem', id: queryArg.servicePackageItemId},
      ],
      extraOptions: {
        responseSchema: PatchServicePackageItemLabourApiResponseSchema,
        requestSchema: PatchServicePackageItemLabourApiArgSchema,
      },
    }),
    getServicePackageItemMaterial: build.query<
      GetServicePackageItemMaterialApiResponse,
      GetServicePackageItemMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}/item/${queryArg.servicePackageItemId}/material`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'servicePackageMaterialItem', id: queryArg.servicePackageItemId},
      ],
      extraOptions: {
        responseSchema: GetServicePackageItemMaterialApiResponseSchema,
        requestSchema: GetServicePackageItemMaterialApiArgSchema,
      },
    }),
    patchServicePackageItemMaterial: build.mutation<
      PatchServicePackageItemMaterialApiResponse,
      PatchServicePackageItemMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-package/${queryArg.servicePackageId}/item/${queryArg.servicePackageItemId}/material`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'servicePackageMaterialItem', id: queryArg.servicePackageItemId},
      ],
      extraOptions: {
        responseSchema: PatchServicePackageItemMaterialApiResponseSchema,
        requestSchema: PatchServicePackageItemMaterialApiArgSchema,
      },
    }),
  }),
});

export const {
  usePostServicePackageMutation,
  useGetServicePackageBasicInformationQuery,
  useDeleteServicePackageDeleteMutation,
  usePatchServicePackageBasicInformationMutation,
  useGetServicePackageRequestDetailQuery,
  usePatchServicePackageRequestDetailMutation,
  usePostServicePackageSetActiveMutation,
  usePostServicePackageSetInActiveMutation,
  useGetServicePackageHoverInformationQuery,
  useGetBasicInformationServicePackageQuery,
  useDeleteServicePackageItemsMutation,
  useGetServicePackageItemLabourQuery,
  usePatchServicePackageItemLabourMutation,
  useGetServicePackageItemMaterialQuery,
  usePatchServicePackageItemMaterialMutation,
} = metadaWorkshopServicePackageApi;
