import {addDays, format} from 'date-fns';
import {Action, Card, DataStatus, Form, FormSubmitHandler} from 'platform/components';
import {VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';
import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isFalsy, isNotNil} from 'ramda-adjunct';

import {
  useGetBillingInformationV2Query,
  useGetCurrentUserInfoQuery,
  useGetDocumentSeriesDefinitionListQuery,
  useGetInvoiceDraftQuery,
  useGetTenantQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {getDocumentSystemSeriesId, useGetCurrentBranch} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

import {CustomerAndSupplier} from '../../components/CustomerAndSupplier';
import {DocumentSeriesSelect} from '../../components/DocumentSeriesSelect';
import {FormHeader} from '../../components/FormHeader';
import {InvoicePage} from '../../components/InvoicePage';
import {ListOfItemsForm} from '../../components/listOfItems/ListOfItemsForm';
import {PaymentDates} from '../../components/PaymentDates';
import {PaymentDetails} from '../../components/PaymentDetails';
import {PaymentsNotes} from '../../components/PaymentsNotes';
import {DATE_FORMAT, getEmptyItem, STANDARD_INVOICE_DUE} from '../../constants';
import {InvoiceVatRateProvider} from '../../context/InvoiceVatRateProvider';
import {AccountingDocumentFormValues} from '../../types';
import {$CreateInvoiceOrDraftSchema} from './$CreateInvoiceOrDraftSchema';
import {useSubmitDraft} from './hooks/useSubmitDraft';
import {useSubmitInvoice} from './hooks/useSubmitInvoice';
import {getInitialValuesFromDraft} from './utils';

export function CreateInvoice() {
  const {id: draftId} = useParams();

  const [handleDraftSubmit] = useSubmitDraft();
  const [handleInvoiceSubmit] = useSubmitInvoice();

  const {
    data: draftInvoice,
    isLoading: isLoadingDraftInvoice,
    isError: isDraftInvoiceError,
  } = useGetInvoiceDraftQuery({invoiceDraftId: draftId ?? ''}, {skip: isNil(draftId)});

  const {isLoading: isLoadingCurrentUser, isError: isGetCurrentUserError} =
    useGetCurrentUserInfoQuery();
  const {isLoading: isLoadingTenant, isError: isGetTenantError} = useGetTenantQuery();
  const {
    data: documentSeries,
    isLoading: isLoadingDocumentSeries,
    isError: isGetDocumentSeriesError,
  } = useGetDocumentSeriesDefinitionListQuery();
  const {data: currentBranch} = useGetCurrentBranch();
  const {isLoading: isLoadingBillingInfo} = useGetBillingInformationV2Query(
    {id: currentBranch?.billingInformation?.id ?? ''},
    {skip: isNil(currentBranch?.billingInformation?.id)}
  );

  const handleCreateInvoiceOrDraft: FormSubmitHandler<AccountingDocumentFormValues> = async (
    formData
  ) => {
    const shouldSubmitDraft = formData.submitType === 'CREATE_DRAFT';
    const submitAction = shouldSubmitDraft ? handleDraftSubmit : handleInvoiceSubmit;

    await submitAction(formData);
  };

  const initialDocumentSeriesId = getDocumentSystemSeriesId(documentSeries, 'invoice');
  const draftInvoiceDefaultValues = getInitialValuesFromDraft(draftInvoice, documentSeries);

  const initialValues: Partial<AccountingDocumentFormValues> = {
    issueDate: format(new Date(), DATE_FORMAT),
    dueSinceIssueDateInDays: STANDARD_INVOICE_DUE,
    documentSeriesId: initialDocumentSeriesId,
    paymentInfo: {
      paymentMethod: 'BANK_TRANSFER',
    },
    dueDate: format(addDays(new Date(), STANDARD_INVOICE_DUE), DATE_FORMAT),
    dateOfTaxableSupply: format(new Date(), DATE_FORMAT),
    invoiceItems: [getEmptyItem()],
    exchangeRateRatio: {
      currency: 'EUR',
      type: i18n.t('general.actions.recalculate'),
      amount: '1',
      ratio: null,
    },
    isExchangeRateRatioEnabled: false,
  };

  const isLoading =
    isLoadingDocumentSeries ||
    isLoadingCurrentUser ||
    isLoadingTenant ||
    isLoadingDraftInvoice ||
    isLoadingBillingInfo;

  const isError =
    isDraftInvoiceError || isGetCurrentUserError || isGetTenantError || isGetDocumentSeriesError;

  const defaultValues = isNotNil(draftInvoice) ? draftInvoiceDefaultValues : initialValues;

  return (
    <DataStatus isLoading={isLoading} minHeight="80vh" isError={isError}>
      <Helmet title={i18n.t('entity.invoice.labels.newInvoice')} />
      <Form<AccountingDocumentFormValues>
        onSubmit={handleCreateInvoiceOrDraft}
        defaultValues={defaultValues}
        schema={$CreateInvoiceOrDraftSchema}
      >
        {(control, formApi) => {
          const [paymentMethod, isInternal] = formApi.watch([
            'paymentInfo.paymentMethod',
            'isInternal',
          ]);
          const canCreateDraft = isFalsy(isInternal) && paymentMethod !== 'FREE_OF_CHARGE';

          return (
            <InvoiceVatRateProvider control={control} listItemType="invoicing-documents">
              <VStack>
                <FormHeader
                  title={i18n.t('entity.invoice.labels.newInvoice')}
                  actions={buildArray<Action>()
                    .when(canCreateDraft, {
                      type: 'form-button',
                      control,
                      onClick: () => formApi.setValue('submitType', 'CREATE_DRAFT'),
                      buttonType: 'submit',
                      title: i18n.t('entity.invoice.labels.saveDraft'),
                      variant: 'secondary',
                      'data-testid': testIds.accounting.invoiceNew('create-invoice-draft'),
                    })
                    .add({
                      type: 'form-button',
                      title: i18n.t('general.actions.create'),
                      onClick: () => formApi.setValue('submitType', 'CREATE_NEW'),
                      control,
                      buttonType: 'submit',
                      'data-testid': testIds.accounting.invoiceNew('create-invoice-create'),
                    })}
                />
                <DocumentSeriesSelect
                  control={control}
                  canBeSend
                  documentType="invoice"
                  data-testid={testIds.accounting.invoiceNew('selectDocumentSeries')}
                />

                <InvoicePage>
                  <Card>
                    <VStack spacing={4}>
                      <CustomerAndSupplier
                        formApi={formApi}
                        control={control}
                        allowAdditionalCustomer
                        supplier={draftInvoice?.supplier}
                        data-testid={testIds.accounting.invoiceNew('customer-and-supplier')}
                      />
                      <PaymentDates
                        formApi={formApi}
                        control={control}
                        data-testid={testIds.accounting.invoiceNew('paymentDates')}
                      />
                    </VStack>
                  </Card>

                  <PaymentDetails
                    control={control}
                    formApi={formApi}
                    data-testid={testIds.accounting.invoiceNew('payment-details')}
                  />

                  <ListOfItemsForm
                    control={control}
                    formApi={formApi}
                    listItemType="invoices"
                    data-testid={testIds.accounting.invoiceNew('list-of-items')}
                  />

                  <PaymentsNotes
                    control={control}
                    data-testid={testIds.accounting.invoiceNew('notes')}
                  />
                </InvoicePage>
              </VStack>
            </InvoiceVatRateProvider>
          );
        }}
      </Form>
    </DataStatus>
  );
}
