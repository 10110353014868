import {Button, ButtonGroup} from 'platform/components';
import {Box, Heading, Text, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, useBoolean} from 'shared';

interface SavingCustomDiscountsProps extends RequiredTestIdProps {
  onSavingCustomDiscounts: (isCustomDiscountApplied: boolean) => void;
  onDiscard: () => void;
}

export function DiscountsTabSavingCustomDiscounts(props: SavingCustomDiscountsProps) {
  const [isLoading, startLoading] = useBoolean();
  const handleDiscard = () => {
    props.onDiscard();
  };

  const handleDontApply = () => {
    startLoading();
    props.onSavingCustomDiscounts(false);
  };

  const handleApply = () => {
    startLoading();
    props.onSavingCustomDiscounts(true);
  };

  return (
    <VStack spacing={4}>
      <Heading size={4}>{i18n.t('warehouse.labels.savingCustomDiscountsHeading')}</Heading>

      <Box padding={4}>
        <Text color="secondary" size="small">
          <ul>
            <li>{i18n.t('warehouse.labels.savingCustomDiscountsDontApply')}</li>
          </ul>
        </Text>
        <Text color="secondary" size="small">
          <ul>
            <li>{i18n.t('warehouse.labels.savingCustomDiscountsApply')}</li>
          </ul>
        </Text>
      </Box>

      <ButtonGroup align="right">
        <Button
          isLoading={isLoading}
          variant="secondary"
          title={i18n.t('general.actions.discard')}
          onClick={handleDiscard}
        />
        <Button
          isLoading={isLoading}
          variant="secondary"
          title={i18n.t('general.actions.dontApply')}
          onClick={handleDontApply}
        />
        <Button
          isLoading={isLoading}
          variant="primary"
          title={i18n.t('general.actions.apply')}
          onClick={handleApply}
        />
      </ButtonGroup>
    </VStack>
  );
}
