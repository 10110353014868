import styled from 'styled-components';
import styledMap from 'styled-map';

export type TdPadding = 'dense' | 'middle' | 'default';

type TableProps = {
  hover?: boolean;
  tdPadding?: TdPadding;
};

type TrProps = {
  hasBackground?: boolean;
};

type CellProps = {
  showCellDivider?: boolean;
  showRowDivider?: boolean;
  align?: 'left' | 'right' | 'center';
};

// @ts-expect-error styleMap used as function is not typed
const tdPaddingMap = styledMap('tdPadding', {
  dense: '4px 16px',
  middle: '6px 0 6px 16px',
  default: '10px 8px',
});

export const Tr = styled.tr<TrProps>`
  ${({hasBackground, theme}) =>
    hasBackground ? `background-color: ${theme.colors.palettes.neutral[10][100]};` : null}

  &:last-child td {
    border-bottom-color: transparent;
  }
`;

export const Td = styled.td<CellProps>`
  border-right: 1px solid
    ${({theme, showCellDivider}) =>
      showCellDivider ? theme.colors.palettes.neutral[40][100] : 'transparent'};
  border-bottom: 1px solid
    ${({theme, showCellDivider, showRowDivider}) =>
      showCellDivider || showRowDivider ? theme.colors.palettes.neutral[40][100] : 'transparent'};
  text-align: ${({align}) => align ?? 'left'};

  &:last-child {
    border-right: 0;
    padding: 0;
  }
`;

export const ExpandTd = styled.td`
  padding: 0;
  max-width: 380px;
`;

export const Table = styled.table<TableProps>`
  width: 100%;
  border-collapse: collapse;
  position: relative;

  th,
  td {
    padding: ${tdPaddingMap};
  }

  ${({theme, hover}) =>
    hover
      ? `
			tr {
				&:hover {
          &:not(.disabled) {
					  cursor: pointer;
          }
          &.disabled {
					  cursor: not-allowed;
          }
					background-color: ${theme.colors.palettes.neutral[20][100]};
				}
			}
		`
      : null}
`;

export const Thead = styled.thead<CellProps>`
  white-space: nowrap;

  th {
    background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
    color: ${({theme}) => theme.colors.palettes.neutral[400][100]};
    font-size: ${({theme}) => theme.fontSizes.text.xSmall};
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.16px;
    position: sticky;
    top: 0;
    border-right: ${({theme, showCellDivider}) =>
      showCellDivider ? `1px solid ${theme.colors.palettes.neutral[40][100]}` : null};

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
    }
  }
`;

export const Tbody = styled.tbody``;

export const Tfoot = styled.tfoot`
  tr:last-child {
    td {
      padding-bottom: 16px;
    }
  }
`;

export const SectionName = styled.span`
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  font-weight: 400;
  padding: 0;
  margin: 0;
  letter-spacing: 0;
  line-height: 16px;
  color: ${({theme}) => theme.colors.palettes.neutral[200][100]};
  padding: 16px 8px;
`;

export const SectionDivider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 9px;
  background-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
`;
