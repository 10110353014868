import {Alert} from 'platform/components';
import {Box} from 'platform/foundation';

import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AdvertisingPlatformCodeEnum, useGetSupportedPlatformsQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {fetchAdSettingsRequest, getAdPlatformsSettingsSelector} from '@omnetic-dms/teas';

export function UnsuportedPlatformsNotification() {
  const {data: supportedPlatforms} = useGetSupportedPlatformsQuery();
  const dispatch = useDispatch();
  const platforms = useSelector(getAdPlatformsSettingsSelector);

  useEffect(() => {
    dispatch(fetchAdSettingsRequest({}));
  }, [dispatch]);

  const unsuportedPlatforms = useMemo(
    () =>
      platforms
        .reduce((memo: string[], platform) => {
          if (
            supportedPlatforms?.codes &&
            !supportedPlatforms.codes.includes(platform.code as AdvertisingPlatformCodeEnum)
          ) {
            memo.push(platform.title);
          }
          return memo;
        }, [])
        .toString(),
    [platforms, supportedPlatforms]
  );

  return unsuportedPlatforms ? (
    <Box paddingBottom={4}>
      <Alert
        type="inline"
        variant="info"
        title={`${i18n.t('entity.promoPhotos.notifications.unsuportedPlatforms')}:`}
        message={unsuportedPlatforms}
      />
    </Box>
  ) : null;
}
