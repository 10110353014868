import styled, {css} from 'styled-components';
import styledMap from 'styled-map';

import {forwardRef, ReactNode, ForwardRefRenderFunction, ComponentPropsWithoutRef} from 'react';

import {isNotNil} from 'ramda-adjunct';

import {TestIdProps, suffixTestId} from 'shared';

const defaultBackground = styledMap`
	default: ${({theme}) => theme.colors.palettes.neutral[20][100]};
	red: ${({theme}) => theme.colors.palettes.red[20][100]};
	redDark: ${({theme}) => theme.colors.palettes.red[60][100]};
	magenta: ${({theme}) => theme.colors.palettes.magenta[20][100]};
	purple: ${({theme}) => theme.colors.palettes.purple[20][100]};
	blue: ${({theme}) => theme.colors.palettes.blue[20][100]};
	blueDark: ${({theme}) => theme.colors.palettes.blue[60][100]};
	teal: ${({theme}) => theme.colors.palettes.teal[20][100]};
	tealDark: ${({theme}) => theme.colors.palettes.teal[60][100]};
	gray: ${({theme}) => theme.colors.palettes.neutral[80][100]};
	grayDark: ${({theme}) => theme.colors.palettes.neutral[300][100]};
	green: ${({theme}) => theme.colors.palettes.green[20][100]};
	greenDark: ${({theme}) => theme.colors.palettes.green[60][100]};
	yellow: ${({theme}) => theme.colors.palettes.yellow[20][100]};
	orange: ${({theme}) => theme.colors.palettes.orange[20][100]};
	orangeDark: ${({theme}) => theme.colors.palettes.orange[60][100]};
	primary: ${({theme}) => theme.colors.palettes.blue[60][100]};
`;

const defaultColor = styledMap`
	default: ${({theme}) => theme.colors.palettes.neutral[900][100]};
	red: ${({theme}) => theme.colors.palettes.red[80][100]};
	redDark: ${({theme}) => theme.colors.palettes.white[10][100]};
	magenta: ${({theme}) => theme.colors.palettes.magenta[80][100]};
	purple: ${({theme}) => theme.colors.palettes.purple[80][100]};
	blue: ${({theme}) => theme.colors.palettes.blue[80][100]};
	blueDark: ${({theme}) => theme.colors.palettes.white[10][100]};
	teal: ${({theme}) => theme.colors.palettes.teal[80][100]};
	tealDark: ${({theme}) => theme.colors.palettes.white[10][100]};
	gray: ${({theme}) => theme.colors.palettes.white[10][100]};
	grayDark: ${({theme}) => theme.colors.palettes.white[10][100]};
	green: ${({theme}) => theme.colors.palettes.green[80][100]};
	greenDark: ${({theme}) => theme.colors.palettes.white[10][100]};
	yellow: ${({theme}) => theme.colors.palettes.neutral[900][100]};
	orange: ${({theme}) => theme.colors.palettes.orange[80][100]};
	orangeDark: ${({theme}) => theme.colors.palettes.white[10][100]};
	primary: ${({theme}) => theme.colors.palettes.white[10][100]};
`;

const padding = styledMap`
	default: 2px 6px;
	compact: 1px 4px;
`;

const lineHeight = styledMap`
	default: 1.34;
	compact: 1.4;
`;

const fontSize = styledMap`
	default: ${({theme}) => theme.fontSizes.text.xSmall};
	compact: ${({theme}) => theme.fontSizes.text.xxSmall};
`;

export type DefaultColors = {
  gray?: boolean;
  grayDark?: boolean;
  green?: boolean;
  greenDark?: boolean;
  red?: boolean;
  redDark?: boolean;
  magenta?: boolean;
  purple?: boolean;
  blue?: boolean;
  blueDark?: boolean;
  teal?: boolean;
  tealDark?: boolean;
  yellow?: boolean;
  orange?: boolean;
  orangeDark?: boolean;
  primary?: boolean;
};

export type TagProps = {
  onClick?: () => void;
  background?: string;
  color?: string;
  icon?: ReactNode;
  compact?: boolean;
  iconRight?: boolean;
  cyId?: string;
} & DefaultColors &
  TestIdProps;

const TagStyled = styled.span<TagProps>`
  display: inline-flex;
  align-items: center;
  color: ${({color}) => color || defaultColor};
  background: ${({background}) => background || defaultBackground};
  font-weight: normal;
  border: none;
  box-sizing: border-box;
  padding: ${padding};
  font-size: ${fontSize};
  border-radius: ${({theme}) => theme.radii.small};
  line-height: ${lineHeight};
`;

type IconProps = {
  icon?: boolean;
  iconRight?: boolean;
  onlyIcon?: boolean;
  gray?: boolean;
  grayDark?: boolean;
  green?: boolean;
  greenDark?: boolean;
  red?: boolean;
  redDark?: boolean;
  magenta?: boolean;
  purple?: boolean;
  blue?: boolean;
  blueDark?: boolean;
  teal?: boolean;
  tealDark?: boolean;
  yellow?: boolean;
  orange?: boolean;
  orangeDark?: boolean;
  primary?: boolean;
};

const Icon = styled.span<IconProps>`
  margin-right: 5px;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  display: flex;
  align-items: center;
  font-size: ${fontSize};
  order: 0;

  svg {
    font-size: inherit;
  }

  ${({iconRight}) =>
    iconRight &&
    css`
      margin-right: 0;
      margin-left: 5px;
      order: 1;
    `}
`;

type FullTagProps = ComponentPropsWithoutRef<'span'> & TagProps;

const TagComponent: ForwardRefRenderFunction<HTMLSpanElement, FullTagProps> & TestIdProps = (
  {icon, iconRight = false, children, ...rest},
  ref
) => {
  const {
    gray,
    grayDark,
    green,
    greenDark,
    red,
    redDark,
    magenta,
    purple,
    blue,
    blueDark,
    teal,
    tealDark,
    yellow,
    orange,
    orangeDark,
    cyId,
  } = rest;

  return (
    <TagStyled {...rest} ref={ref} data-testid={suffixTestId('tag', rest)}>
      {isNotNil(icon) && (
        <Icon
          className="tag-icon"
          iconRight={iconRight}
          gray={gray}
          grayDark={grayDark}
          green={green}
          greenDark={greenDark}
          red={red}
          redDark={redDark}
          magenta={magenta}
          purple={purple}
          blue={blue}
          blueDark={blueDark}
          teal={teal}
          tealDark={tealDark}
          yellow={yellow}
          orange={orange}
          orangeDark={orangeDark}
        >
          {icon}
        </Icon>
      )}
      <>{children}</>
    </TagStyled>
  );
};

export const Tag = forwardRef(TagComponent);
