import {TabProps} from 'platform/components';

import {EProgressType} from '../types/ProgressType';

const getTabIcon = (type: EProgressType, isCondition?: boolean): TabProps['icon'] => {
  if (isCondition) {
    return;
  }
  switch (type) {
    case EProgressType.progress:
      return 'pending';
    case EProgressType.success:
      return 'success';
    case EProgressType.error:
      return 'error';
    default:
      return 'default';
  }
};

export {getTabIcon};
