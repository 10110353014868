import {BreadcrumbType} from 'platform/components';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {Distraints} from './components/Distraints';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.integrations.labels.customerVerification'),
    href: settingsRoutes.customerVerificationServices,
  },
];

export function CustomerVerificationDistraints() {
  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.integrations.labels.customerVerificationDistraints'),
        breadcrumbs,
      }}
      data-testid="customer-verification-distraints"
    >
      <Distraints />
    </SettingsTemplate>
  );
}
