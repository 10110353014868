import {Button, ButtonProps} from 'platform/components';
import {
  Box,
  Display,
  Heading,
  HStack,
  Image,
  Severity,
  Show,
  Space,
  Text,
} from 'platform/foundation';

import {Nullish} from 'shared';

interface InsuranceActionCardProps {
  title: string;
  image: string;
  amount: string;
  description: string;
  footer?: string | Nullish;
  button?: ButtonProps;
  severity?: Severity;
}

export function InsuranceActionCard(props: InsuranceActionCardProps) {
  const isSuccess = props.severity === 'success';

  return (
    <Box
      flex={1}
      padding={6}
      backgroundColor={isSuccess ? 'palettes.green.10.100' : 'palettes.neutral.20.100'}
      borderRadius="medium"
      overflow="hidden"
    >
      <HStack spacing={2} align="flex-start">
        <Image width={35} src={props.image} />
        <Box>
          <Heading size={3}>{props.title}</Heading>
          <Space vertical={4} />
          <Display size={1} color={props.severity ?? 'primary'}>
            {props.amount}
          </Display>
          <Space vertical={1} />
          <Heading size={5} color="secondary" alternative>
            {props.description}
          </Heading>
          <Show when={props.button}>
            <Space vertical={6} />
            <Button {...props.button} />
          </Show>
          <Space vertical={2} />
          <Text size="xxSmall" color="secondary">
            {props.footer}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
}
