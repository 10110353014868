/**
 * Type of Param Definition.
 */

export enum ParamType {
  COUNTER = 'counter',
  TEXT = 'text',
  TOGGLE = 'toggle',
  DROPDOWN = 'dropdown',
  RADIO_BUTTONS = 'radioButtons',
  CHECKBOX_BUTTON = 'checkboxButton',
  PILL_RADIO = 'pillRadio',
  PILL_CHECKBOX = 'pillCheckbox',
  DATE = 'date',
  DATE_YEAR = 'date_year',
  NUMBER = 'number',
  ADDITIONAL_PHOTOS = 'additional_photos',
  ADDITIONAL_COMMENT = 'additional_comment',
}
