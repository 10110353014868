import {OptionType} from 'platform/components';
import {match} from 'ts-pattern';

import {useState, useCallback, useEffect} from 'react';

import {always, isNil, isNotNil} from 'ramda';
import {isFalse} from 'ramda-adjunct';

type UseHandleKeyDownEventProps = {
  suggestions: OptionType[] | undefined;
  isOpen: boolean;
  handleSubmitAddress: (placeId: string) => void;
};

export const useHandleKeyDownEvent = ({
  handleSubmitAddress,
  isOpen,
  suggestions,
}: UseHandleKeyDownEventProps) => {
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number | null>(null);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (isNil(suggestions) || isFalse(isOpen)) {
        return;
      }

      const lastIndex = suggestions.length - 1;

      if (e.key === 'ArrowDown') {
        setFocusedOptionIndex((prevState) =>
          match(prevState)
            .with(null, always(0))
            .with(lastIndex, always(0))
            .otherwise(always((prevState as number) + 1))
        );
      }

      if (e.key === 'ArrowUp') {
        setFocusedOptionIndex((prevState) =>
          match(prevState)
            .with(null, always(lastIndex))
            .with(0, always(lastIndex))
            .otherwise(always((prevState as number) - 1))
        );
      }

      if (e.key === 'Enter') {
        setFocusedOptionIndex((prevState) => {
          if (isNil(prevState)) {
            return null;
          }

          const focusedSuggestionValue = suggestions?.[prevState]?.value;

          if (isNotNil(focusedSuggestionValue)) {
            handleSubmitAddress(focusedSuggestionValue);
          }

          return null;
        });
      }
    },
    [suggestions, isOpen, handleSubmitAddress]
  );

  useEffect(() => {
    setFocusedOptionIndex(null);
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return [focusedOptionIndex] as const;
};
