import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

export const PostServicePackageCategoryApiResponseSchema = z
  .object({
    id: z.string().nullable().optional(),
  })
  .nullable();

export type PostServicePackageCategoryApiResponse = z.infer<
  typeof PostServicePackageCategoryApiResponseSchema
>;

export const PostServicePackageCategoryApiArgSchema = z.object({
  body: z.object({
    name: z.string(),
    description: z.string().nullable().optional(),
  }),
});

export type PostServicePackageCategoryApiArg = z.infer<
  typeof PostServicePackageCategoryApiArgSchema
>;

export const GetServicePackageCategoryApiResponseSchema = z
  .object({
    id: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    description: z.string().nullable().optional(),
  })
  .nullable();

export type GetServicePackageCategoryApiResponse = z.infer<
  typeof GetServicePackageCategoryApiResponseSchema
>;

export const GetServicePackageCategoryApiArgSchema = z.object({
  categoryId: z.string(),
});

export type GetServicePackageCategoryApiArg = z.infer<typeof GetServicePackageCategoryApiArgSchema>;

export const DeleteServicePackageCategoryApiResponseSchema = BaseVoidResponseSchema;

export type DeleteServicePackageCategoryApiResponse = z.infer<
  typeof DeleteServicePackageCategoryApiResponseSchema
>;

export const DeleteServicePackageCategoryApiArgSchema = z.object({
  categoryId: z.string(),
});

export type DeleteServicePackageCategoryApiArg = z.infer<
  typeof DeleteServicePackageCategoryApiArgSchema
>;

export const PatchServicePackageCategoryApiResponseSchema = BaseVoidResponseSchema;

export type PatchServicePackageCategoryApiResponse = z.infer<
  typeof PatchServicePackageCategoryApiResponseSchema
>;

export const PatchServicePackageCategoryApiArgSchema = z.object({
  categoryId: z.string(),
  body: z
    .object({
      name: z.string(),
      description: z.string().nullable().optional(),
    })
    .nullable(),
});

export type PatchServicePackageCategoryApiArg = z.infer<
  typeof PatchServicePackageCategoryApiArgSchema
>;

export const GetServicePackageCategoriesApiResponseSchema = z
  .object({
    servicePackagesCategory: z
      .array(
        z
          .object({
            id: z.string().nullable().optional(),
            name: z.string().nullable().optional(),
            description: z.string().nullable().optional(),
          })
          .nullable()
      )
      .optional(),
  })
  .nullable();

export type GetServicePackageCategoriesApiResponse = z.infer<
  typeof GetServicePackageCategoriesApiResponseSchema
>;

export const GetServicePackageCategoriesApiArgSchema = z.void();

export type GetServicePackageCategoriesApiArg = z.infer<
  typeof GetServicePackageCategoriesApiArgSchema
>;
