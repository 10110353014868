export const HUNDRED_PERCENT = 100;

const isNaNorZero = (value: number) => isNaN(value) || value === 0;

export const getPercentage = (amount = '0', totalPrice = '0') => {
  const amountNumber = parseFloat(amount);
  const totalPriceNumber = parseFloat(totalPrice);

  if (isNaNorZero(amountNumber) || isNaNorZero(totalPriceNumber)) {
    return 0;
  }

  return Number(((amountNumber / totalPriceNumber) * HUNDRED_PERCENT).toFixed(2));
};
