import {
  ButtonGroup,
  DataStatus,
  Dialog,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box} from 'platform/foundation';
import {object, string} from 'yup';

import {Helmet} from 'react-helmet-async';

import {useGetDirectSaleVariantsQuery, usePostDirectSaleMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {DirectSaleCreateForm} from '../../types/DirectSaleCreateForm';
import {getOptionsFromDirectSaleVariants} from '../../utils/getOptionsFromDirectSaleVariants';

export function DirectSalesCreate() {
  const navigate = useNavigate();

  const {
    data: directSaleVariants,
    isLoading: isDirectSaleVariantsLoading,
    isError: hasDirectSaleVariantsError,
  } = useGetDirectSaleVariantsQuery();

  const [createDirectSale] = usePostDirectSaleMutation();

  const handleSubmit: FormSubmitHandler<DirectSaleCreateForm> = async (formValues) => {
    await createDirectSale(formValues)
      .unwrap()
      .then((response) => {
        showNotification.success(i18n.t('entity.warehouse.notifications.directSaleCreated'));

        navigate(
          composePath(warehouseRoutes.directSalesDetailOverview, {
            params: {
              id: response.id,
            },
          })
        );
      })
      .catch(handleApiError);
  };

  const handleDiscard = () => {
    navigate(warehouseRoutes.directSalesList);
  };

  return (
    <>
      <Helmet title={i18n.t('page.warehouse.labels.newDirectSale')} />
      <DataStatus
        minHeight={60}
        isLoading={isDirectSaleVariantsLoading}
        isError={hasDirectSaleVariantsError}
      >
        <Dialog
          isOpen
          title={i18n.t('page.warehouse.labels.newDirectSale')}
          size="small"
          withAdditionalFooter
          scrollBehavior="outside"
          onClose={handleDiscard}
          data-testid={testIds.warehouse.directSalesCreate('createDialog')}
        >
          <Form<DirectSaleCreateForm> schema={formSchema} onSubmit={handleSubmit}>
            {(control) => (
              <>
                <Box height={20}>
                  <FormField
                    isRequired
                    isNotClearable
                    control={control}
                    name="directSaleVariantId"
                    type="choice"
                    label={i18n.t('page.warehouse.labels.directSaleVariant')}
                    options={getOptionsFromDirectSaleVariants(directSaleVariants)}
                    data-testid={testIds.warehouse.directSalesCreate('directSaleVariantId')}
                  />
                </Box>

                <DialogFooter>
                  <ButtonGroup align="right">
                    <FormButton
                      type="button"
                      title={i18n.t('general.actions.discard')}
                      variant="secondary"
                      control={control}
                      onClick={handleDiscard}
                      data-testid={testIds.warehouse.directSalesCreate('discard')}
                    />
                    <FormButton
                      type="submit"
                      title={i18n.t('general.actions.create')}
                      variant="primary"
                      control={control}
                      data-testid={testIds.warehouse.directSalesCreate('submit')}
                    />
                  </ButtonGroup>
                </DialogFooter>
              </>
            )}
          </Form>
        </Dialog>
      </DataStatus>
    </>
  );
}

const formSchema = object({
  directSaleVariantId: string().nullable().required(),
});
