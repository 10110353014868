import {Positions} from '../Notification.types';

export const getPositionStyles = (position: Positions) => {
  switch (position) {
    case 'top-left':
      return {
        top: 0,
        left: 0,
        right: 'auto',
        bottom: 'auto',
      } as const;
    case 'top-right':
      return {
        top: 0,
        left: 'auto',
        right: 0,
        bottom: 'auto',
      } as const;
    case 'bottom-left':
      return {
        top: 'auto',
        left: 0,
        right: 'auto',
        bottom: 0,
      } as const;
    case 'bottom-right':
      return {
        top: 'auto',
        left: 'auto',
        right: 0,
        bottom: 0,
      } as const;
    default:
      return {
        top: 0,
        left: 0,
        right: 'auto',
        bottom: 'auto',
      } as const;
  }
};
