import {Tooltip} from 'platform/components';

import {FC, MutableRefObject, useCallback, useRef, useState} from 'react';

import {Nullish} from 'shared';

import {SpanText} from '../styles';

export const TemplateText: FC<{width: number; text: string}> = ({width, text}) => {
  const {onMouseOver, ref, tooltipText} = useToolTipHook(text);

  return (
    <Tooltip placement="top" label={tooltipText}>
      <SpanText ref={ref} onMouseOver={onMouseOver} $width={width}>
        {text}
      </SpanText>
    </Tooltip>
  );
};

type TooltipEventProps = {
  tooltipText: string;
  onMouseOver: () => void;
  ref: MutableRefObject<HTMLSpanElement | null>;
};

const useToolTipHook = (text?: string | Nullish): TooltipEventProps => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const [tooltipText, setText] = useState('');

  const onMouseOver = useCallback(() => {
    if (ref?.current && ref.current.offsetWidth < ref.current.scrollWidth) {
      setText(text ?? '-');
    }
  }, [text, ref]);

  return {
    onMouseOver,
    tooltipText,
    ref,
  };
};
