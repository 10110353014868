import {z} from 'zod';

import {BaseIdSchema, BaseVoidRequest, BaseVoidResponse} from './base';
import {GetTreeFolderApiResponse, TreeFolderContextTarget} from './metadaTreeFolder';

const ArticleCategoryIdSchema = z.object({
  articleCategoryId: z.string(),
});

const ArticleCategoryBaseSchema = z.object({
  name: z.string(),
  code: z.union([z.string(), z.null()]).optional(),
  active: z.boolean(),
  system: z.boolean(),
  isDefault: z.boolean(),
});
export type ArticleCategoryBase = z.infer<typeof ArticleCategoryBaseSchema>;

export const DeleteArticleCategoryRequestSchema = ArticleCategoryIdSchema;
export type DeleteArticleCategoryRequest = z.infer<typeof DeleteArticleCategoryRequestSchema>;

export type DeleteArticleCategoryResponse = BaseVoidResponse;

export const GetArticleCategoryRequestSchema = ArticleCategoryIdSchema;
export type GetArticleCategoryRequest = z.infer<typeof GetArticleCategoryRequestSchema>;

export const GetArticleCategoryResponseSchema = ArticleCategoryBaseSchema;
export type GetArticleCategoryResponse = z.infer<typeof GetArticleCategoryResponseSchema>;

export const PostArticleCategoryRequestSchema = z.object({
  body: ArticleCategoryBaseSchema,
});
export type PostArticleCategoryRequest = z.infer<typeof PostArticleCategoryRequestSchema>;

export const PostArticleCategoryResponseSchema = BaseIdSchema;
export type PostArticleCategoryResponse = z.infer<typeof PostArticleCategoryResponseSchema>;

export type PatchArticleCategoryResponse = BaseVoidResponse;

export const PatchArticleCategoryRequestSchema = z
  .object({
    body: ArticleCategoryBaseSchema,
  })
  .merge(ArticleCategoryIdSchema);
export type PatchArticleCategoryRequest = z.infer<typeof PatchArticleCategoryRequestSchema>;

export type GetArticleCategoryListRequest = BaseVoidRequest;

export const GetArticleCategoryListResponseSchema = z.object({
  categories: z.array(ArticleCategoryBaseSchema.merge(BaseIdSchema)),
});
export type GetArticleCategoryListResponse = z.infer<typeof GetArticleCategoryListResponseSchema>;

// TODO: extract to tree folder
export type GetDefaultTreeFolderRequest = {contextTarget: TreeFolderContextTarget};
export type GetDefaultTreeFolderResponse = GetTreeFolderApiResponse;
