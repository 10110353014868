import {Icon} from 'platform/foundation';

import {ReactElement} from 'react';

import {WarnWrapper} from '../styles';

export const TemplateFileName = ({
  title,
  isWarn,
}: {
  title: string;
  isWarn?: boolean;
}): ReactElement => {
  const icon = (
    <WarnWrapper>
      <Icon value="alert/warning" color="severity.warning" />
    </WarnWrapper>
  );
  return (
    <>
      {title} {isWarn && icon}
    </>
  );
};
