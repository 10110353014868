import {showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {accountingRoutes} from '@omnetic-dms/routes';

import {Nullish, composePath, useNavigate} from 'shared';

export const useRedirectToInvoice = () => {
  const navigate = useNavigate();

  const handleRedirectToInvoice = (invoiceType: string | Nullish, invoiceId: string | Nullish) => {
    const redirectTo = (route: `${string}/:id`) => () =>
      navigate(composePath(route, {params: {id: invoiceId}}));

    match(invoiceType)
      .with('DRAFT', redirectTo(accountingRoutes.invoiceDraft))
      .with('ISSUED', redirectTo(accountingRoutes.invoiceDetail))
      .with('BALANCE_INVOICE', redirectTo(accountingRoutes.balanceInvoiceDetail))
      .with('CORRECTIVE_TAX_DOCUMENT', redirectTo(accountingRoutes.correctiveTaxDocumentDetail))
      .with('TAX_DOCUMENT_FOR_PAYMENT', redirectTo(accountingRoutes.taxDocumentForPaymentDetail))
      .with('PROFORMA', redirectTo(accountingRoutes.proformaInvoiceDetail))
      .otherwise(() => showNotification.error());
  };

  return [handleRedirectToInvoice] as const;
};
