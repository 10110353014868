import {
  Dropdown,
  DropdownSubmenu,
  IconButton,
  openDialog,
  Tooltip,
  useTranslationContext,
} from 'platform/components';

import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {has, isNil, mergeAll, omit} from 'ramda';
import {isNotNil, noop} from 'ramda-adjunct';

import {Box} from '@chakra-ui/react';

import {suffixTestId, useBoolean} from 'shared';

import {useGridApiEventListener} from '../../hooks/useGridApiEventListener';
import {Preset} from '../../types/Api';
import {PresetsPanelRendererProps} from '../../types/PresetsPanelRendererType';
import {EditPresetDialog} from './EditPresetDialog';
import {PresetApiStateEnum} from './PresetApiStateEnum';
import {PresetButton} from './PresetButton';
import {PresetButtonActions} from './PresetButtonActions';

const PRESET_WRAPPER_HEIGHT = 72;

export function PresetsPanelRenderer(props: PresetsPanelRendererProps) {
  const t = useTranslationContext();

  const wrapperTheme = {
    gap: '4px',
    flexGrow: '1',
    zIndex: '2',
    overflow: 'hidden',
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: '5px',
    marginLeft: '-5px',
  };

  const buttonWrapperTheme = {
    maxHeight: `${PRESET_WRAPPER_HEIGHT}px`,
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    width: '100%',
    whiteSpace: 'nowrap',
    flexShrink: '1 !important',
    padding: '5px 0',
    marginRight: 0,
    gap: '2px 4px',
  };

  const [apiState, setApiState] = useState<Record<string, PresetApiStateEnum>>({});
  const [isPresetChangeDisabled, disabledPresetChange, allowPresetChange] = useBoolean();

  const buttonsWrapperRef = useRef<HTMLDivElement>(null);
  const buttonsGhostRef = useRef<HTMLDivElement>(null);
  const [visibleCount, setVisibleCount] = useState<number>();
  const [presetsRowCount, setPresetsRowCount] = useState<Record<string, number | undefined>>({});
  const visibleItems = useMemo<Preset[] | undefined>(
    () => props.presets?.slice(0, visibleCount),
    [visibleCount, props.presets]
  );
  const hiddenItems = useMemo<Preset[] | undefined>(
    () => props.presets?.slice(visibleCount, props.presets.length),
    [visibleCount, props.presets]
  );

  const calculateVisibleItemCount = useCallback(
    (ghostParent: HTMLElement | null) => {
      if (isNil(ghostParent)) {
        return;
      }
      let overflowDetected = false;

      for (const [index, child] of (Array.from(ghostParent.children) as HTMLElement[]).entries()) {
        const childBottom = child.offsetTop + child.offsetHeight;

        if (childBottom > PRESET_WRAPPER_HEIGHT) {
          setVisibleCount(index - 1);
          overflowDetected = true;
          break;
        }
      }
      if (!overflowDetected) {
        setVisibleCount(props.presets?.length);
      }
    },
    [props.presets]
  );

  useEffect(() => {
    const wrapperObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        if (buttonsGhostRef.current && buttonsWrapperRef.current) {
          buttonsGhostRef.current.style.width = `${buttonsWrapperRef.current.offsetWidth}px`;
        }
      });
    });
    if (buttonsWrapperRef.current) {
      wrapperObserver.observe(buttonsWrapperRef.current);
    }

    const ghostObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        calculateVisibleItemCount(entries[0].target as HTMLElement);
      });
    });
    if (buttonsGhostRef.current) {
      ghostObserver.observe(buttonsGhostRef.current);
    }
    return () => {
      ghostObserver.disconnect();
      wrapperObserver.disconnect();
    };
  }, [buttonsWrapperRef, buttonsGhostRef, setVisibleCount, props.presets]);

  useEffect(() => {
    if (props.rowCountGetter && visibleItems) {
      const dataQueryIdsToFetch = visibleItems
        ?.filter((preset) => !has(preset.dataQueryId, presetsRowCount))
        ?.map((preset) => preset.dataQueryId);

      Promise.all(dataQueryIdsToFetch.map((dataQueryId) => props.rowCountGetter(dataQueryId))).then(
        (result) => {
          setPresetsRowCount((prev) => ({
            ...prev,
            ...mergeAll(result.map((count, index) => ({[dataQueryIdsToFetch[index]]: count}))),
          }));
          calculateVisibleItemCount(buttonsGhostRef.current);
        }
      );
    }
  }, [visibleItems, props.rowCountGetter]);

  const updatePresetProxy = useCallback(
    (presetId: string) => {
      disabledPresetChange();
      setApiState({...apiState, [presetId]: PresetApiStateEnum.loading});

      const updateResult = props.updatePreset(presetId);

      if (isNil(updateResult)) {
        return setTimeout(
          () => setApiState({...apiState, [presetId]: PresetApiStateEnum.idle}),
          1200
        );
      }

      updateResult
        .then(() => setApiState({...apiState, [presetId]: PresetApiStateEnum.success}))
        .catch(() => setApiState({...apiState, [presetId]: PresetApiStateEnum.error}))
        .finally(() =>
          setTimeout(() => setApiState({...apiState, [presetId]: PresetApiStateEnum.idle}), 1200)
        );
    },
    [setApiState, apiState, props.updatePreset]
  );
  const duplicatePresetProxy = useCallback(
    (presetId: string) => {
      disabledPresetChange();
      setApiState({...apiState, [presetId]: PresetApiStateEnum.loading});
      props
        .duplicatePreset(presetId)
        .then(() => setApiState({...apiState, [presetId]: PresetApiStateEnum.success}))
        .catch(() => setApiState({...apiState, [presetId]: PresetApiStateEnum.error}))
        .finally(() =>
          setTimeout(() => setApiState({...apiState, [presetId]: PresetApiStateEnum.idle}), 1200)
        );
    },
    [setApiState, apiState, props.duplicatePreset]
  );
  const deletePresetProxy = useCallback(
    (presetId: string) => {
      disabledPresetChange();
      setApiState({...apiState, [presetId]: PresetApiStateEnum.loading});
      props
        .deletePreset(presetId)
        .then(() => setApiState({...apiState, [presetId]: PresetApiStateEnum.success}))
        .catch(() => setApiState({...apiState, [presetId]: PresetApiStateEnum.error}))
        .finally(() => setTimeout(() => setApiState(omit([presetId], apiState)), 1200));
    },
    [setApiState, apiState, props.deletePreset]
  );
  const updatePresetPositionProxy = useCallback(
    (presetId: string, from: number, to: number) => {
      disabledPresetChange();
      setApiState({...apiState, [presetId]: PresetApiStateEnum.loading});
      props
        .updatePresetPosition(from, to)
        .then(() => setApiState({...apiState, [presetId]: PresetApiStateEnum.success}))
        .catch(() => setApiState({...apiState, [presetId]: PresetApiStateEnum.error}))
        .finally(() => {
          setTimeout(() => setApiState({...apiState, [presetId]: PresetApiStateEnum.idle}), 1200);
          allowPresetChange();
        });
    },
    [setApiState, apiState, props.updatePresetPosition]
  );

  const {addListeners} = useGridApiEventListener(
    props.gridApi,
    ['storeRefreshed'],
    allowPresetChange
  );
  addListeners();

  return (
    <Box sx={wrapperTheme}>
      <Tooltip label={t('page.actions.createPreset')} placement="top">
        <Box borderRadius="50%" overflow="hidden" backgroundColor="palettes.neutral.30.100">
          <IconButton
            onClick={() =>
              openDialog(
                <EditPresetDialog
                  onSubmit={props.createPreset}
                  data-testid={suffixTestId('createPreset', props)}
                />,
                {
                  id: 'createPreset',
                  title: t('general.presets.createPreset'),
                  'data-testid': suffixTestId('createPreset', props),
                  size: 'small',
                  scrollBehavior: 'outside',
                }
              )
            }
            data-testid={suffixTestId('createPresetButton', props)}
            icon="content/add"
          />
        </Box>
      </Tooltip>
      <Box __css={{display: 'flex'}} ref={buttonsWrapperRef} sx={buttonWrapperTheme}>
        {visibleItems?.map((preset) => {
          const isActive = preset.id === (props.activePreset as Preset).id;
          const state = isNotNil(apiState[preset.id])
            ? apiState[preset.id]
            : PresetApiStateEnum.idle;

          const globalIndex = props.presets?.findIndex((p) => p.id === preset.id) ?? -1;

          return (
            <PresetButton
              rowCount={presetsRowCount[preset.dataQueryId]}
              unsaved={props.isPresetChanged && isActive}
              key={preset.id}
              preset={preset}
              state={state}
              active={isActive}
              isDisabled={isPresetChangeDisabled}
              onClick={() => {
                disabledPresetChange();
                if (isActive) {
                  props.deactivatePreset();
                } else {
                  props.activatePreset(preset);
                }
              }}
              data-testid={suffixTestId(`preset-${preset.title}`, props)}
              actions={
                <PresetButtonActions
                  preset={preset}
                  index={globalIndex}
                  presetChanged={props.isPresetChanged}
                  activePresetId={(props.activePreset as Preset)?.id}
                  updatePreset={updatePresetProxy}
                  deletePreset={deletePresetProxy}
                  duplicatePreset={duplicatePresetProxy}
                  resetOverrides={props.resetOverrides}
                  updatePresetPosition={updatePresetPositionProxy}
                  data-testid={suffixTestId(`preset-${preset.title}`, props)}
                  openEditModal={() =>
                    openDialog(
                      <EditPresetDialog
                        presetToEdit={preset}
                        data-testid={suffixTestId('editPreset', props)}
                        onSubmit={(title, description) =>
                          props.renamePreset(preset.id, title, description)
                        }
                      />,
                      {
                        id: 'editPreset',
                        title: t('general.presets.editPreset', {presetName: preset.title}),
                        'data-testid': suffixTestId('editPreset', props),
                        size: 'small',
                        scrollBehavior: 'outside',
                      }
                    )
                  }
                  presetsLength={props.presets?.length || 0}
                />
              }
            />
          );
        })}
        <Box __css={{display: 'inline-flex'}}>
          {isNotNil(hiddenItems?.length) && hiddenItems.length > 0 && (
            <Dropdown
              isLazy
              strategy="fixed"
              dropdownControl={
                <IconButton icon="navigation/more_vert" data-testid="button-preset.more" />
              }
              data-testid={suffixTestId('dropDownPresetMore', props)}
            >
              {hiddenItems?.map((preset) => {
                const globalIndex = props.presets?.findIndex((p) => p.id === preset.id) || -1;
                const isActive = preset.id === (props.activePreset as Preset).id;
                return (
                  <DropdownSubmenu
                    isLazy
                    isActive={isActive}
                    key={preset.id}
                    onClick={() => {
                      if (isActive) {
                        props.deactivatePreset();
                      } else {
                        props.activatePreset(preset);
                      }
                    }}
                    label={preset.title}
                    data-id={`menu-preset.${preset.title}`}
                  >
                    <PresetButtonActions
                      preset={preset}
                      index={globalIndex}
                      presetChanged={props.isPresetChanged}
                      activePresetId={(props.activePreset as Preset)?.id}
                      updatePreset={updatePresetProxy}
                      deletePreset={deletePresetProxy}
                      duplicatePreset={duplicatePresetProxy}
                      resetOverrides={props.resetOverrides}
                      updatePresetPosition={updatePresetPositionProxy}
                      data-testid={`menu-preset.${preset.title}`}
                      openEditModal={() =>
                        openDialog(
                          <EditPresetDialog
                            presetToEdit={preset}
                            data-testid="button-createPreset"
                            onSubmit={(title, description) =>
                              props.renamePreset(preset.id, title, description)
                            }
                          />,
                          {
                            title: t('general.presets.editPreset', {presetName: preset.title}),
                            'data-testid': 'editPreset',
                            size: 'small',
                            scrollBehavior: 'outside',
                          }
                        )
                      }
                      presetsLength={props.presets?.length || 0}
                    />
                  </DropdownSubmenu>
                );
              })}
            </Dropdown>
          )}
        </Box>
      </Box>
      <Box
        ref={buttonsGhostRef}
        sx={{
          ...buttonWrapperTheme,
          maxHeight: 'unset',
          position: 'absolute',
          zIndex: '-999',
          opacity: 0,
          visibility: 'hidden',
          width: `${buttonsWrapperRef.current?.offsetWidth ?? 0}px`,
        }}
      >
        {props.presets?.map((preset, index) => (
          <PresetButton
            rowCount={presetsRowCount[preset.dataQueryId]}
            unsaved={false}
            key={`ghost-${preset.id}`}
            preset={preset}
            state={PresetApiStateEnum.idle}
            active={false}
            onClick={noop}
            data-id={`menu-preset.${preset.title}`}
          />
        ))}
      </Box>
    </Box>
  );
}
