import {FormControl, FormField, isCurrency} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import {useWatch} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {PutPurchaseVehiclePricingCentralizedApiArg, selectTenant} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {DEFAULT_CURRENCY} from '@omnetic-dms/teas';

import {FinanceSummary} from './FinanceSummary';

interface VatNotDeductibleFormProps {
  control: FormControl<PutPurchaseVehiclePricingCentralizedApiArg>;
  vehicleId: string;
}

export function VatNotDeductibleForm(props: VatNotDeductibleFormProps) {
  const {data: selectedTenant} = useSelector(selectTenant);
  const currency = isCurrency(selectedTenant?.currency)
    ? selectedTenant?.currency
    : DEFAULT_CURRENCY;

  const [sellingPrice, maxBuyingPrice] = useWatch({
    control: props.control,
    name: ['sellingPrice.priceNotDeductible.amount', 'maxBuyingPrice.priceNotDeductible.amount'],
  });

  return (
    <VStack spacing={3}>
      <HStack spacing={4}>
        <Box flexGrow={1}>
          <FormField
            control={props.control}
            name="maxBuyingPrice.priceNotDeductible.amount"
            type="currency"
            currency={currency}
            data-testid={testIds.businessCase.buying('pricing-maxBuyingPrice')}
            label={i18n.t('page.businessCase.labels.maxBuyingPrice')}
          />
        </Box>
        <Box flexGrow={1}>
          <FormField
            control={props.control}
            name="sellingPrice.priceNotDeductible.amount"
            type="currency"
            currency={currency}
            data-testid={testIds.businessCase.buying('pricing-sellingPrice')}
            label={i18n.t('general.labels.sellingPrice')}
          />
        </Box>
      </HStack>
      <FinanceSummary
        vehicleId={props.vehicleId}
        maxBuyingPrice={maxBuyingPrice}
        sellingPrice={sellingPrice}
      />
    </VStack>
  );
}
