import {IFilter} from '@ag-grid-community/core';
import {Checkboxes, Chips, Separator} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {ForwardedRef, forwardRef} from 'react';

import {intersection} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useChoiceFilter} from '../../hooks/useChoiceFilter';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {IFilterParams} from '../../types/AgGridTypes';
import {QuickFilters} from '../../types/Api';

type Option = {value: string; label: string};

export type CheckboxFilterProps = {
  options: Array<Option>;
  isDisabled: boolean;
} & IFilterParams &
  QuickFilters &
  TestIdProps;

function CheckboxFilterComponent(
  {filterChangedCallback, options, isDisabled, quickFilters, ...props}: CheckboxFilterProps,
  ref: ForwardedRef<IFilter>
) {
  const [values, setValues] = useChoiceFilter(filterChangedCallback, ref);
  const {onChipsChange} = useFilterOnChipsChange({
    filterChangedCallback,
    setFilterValue: setValues,
    defaultValue: undefined,
  });

  const isQuickFilterValue = intersection(isArray(values) ? values : [values], quickFiltersConst);

  const onChange = (values: string[] | null) => {
    setValues(values ?? undefined);
    filterChangedCallback();
  };

  return (
    <VStack>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('checkbox-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <Checkboxes
        options={options}
        value={isArray(values) ? values : null}
        onChange={onChange}
        isDisabled={isDisabled}
        checkedIcon="navigation/check"
        data-testid={suffixTestId('checkbox-filter', props)}
      />
    </VStack>
  );
}

export const CheckboxFilter = forwardRef<IFilter, CheckboxFilterProps>(CheckboxFilterComponent);
