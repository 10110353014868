import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNaN} from 'ramda-adjunct';

import {Getter} from '../types/Getter';
import {DependentOptionResponseBody, OptionResponseBody} from '../types/modelSchema';

type ActionType = (
  config: DependentOptionResponseBody,
  getter: Getter,
  options: OptionResponseBody[]
) => OptionResponseBody[];

/**
 * Dependent option handlers
 */
export const handleDependentOptions = (
  dependentOptions: DependentOptionResponseBody[],
  getter: Getter,
  options: OptionResponseBody[]
): OptionResponseBody[] => {
  const actionHandler = (
    filteredOptions: OptionResponseBody[],
    dependentOption: DependentOptionResponseBody
  ): OptionResponseBody[] => {
    const action = match(dependentOption.action)
      .with('lt', always(lowerAction))
      .with('lte', always(lowerAndEqualAction))
      .with('gt', always(greatherAction))
      .with('gte', always(greatherAndEqualAction))
      .exhaustive();

    return action(dependentOption, getter, filteredOptions);
  };

  return dependentOptions.reduce(actionHandler, options);
};

/**
 * Utils
 */

const comparableHelper = (
  config: DependentOptionResponseBody,
  getter: Getter,
  options: OptionResponseBody[],
  comparableOptionsActions: DependentOptionResponseBody['action']
): OptionResponseBody[] => {
  const valueAsNumber = Number(getter(config.name));

  if (isNaN(valueAsNumber)) {
    return options;
  }

  return options.filter((option) => {
    switch (comparableOptionsActions) {
      case 'gt': {
        return Number(option.value) > valueAsNumber;
      }
      case 'gte': {
        return Number(option.value) >= valueAsNumber;
      }
      case 'lt': {
        return Number(option.value) < valueAsNumber;
      }
      case 'lte': {
        return Number(option.value) <= valueAsNumber;
      }
    }
  });
};

/**
 * Actions
 */

const lowerAction: ActionType = (config, getter, options) =>
  comparableHelper(config, getter, options, 'lt');

const lowerAndEqualAction: ActionType = (config, getter, options) =>
  comparableHelper(config, getter, options, 'lte');

const greatherAction: ActionType = (config, getter, options) =>
  comparableHelper(config, getter, options, 'gt');

const greatherAndEqualAction: ActionType = (config, getter, options) =>
  comparableHelper(config, getter, options, 'gte');
