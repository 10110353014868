import {useMemo, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {selectAudit} from '../../../store/carAudit/selectors';
import {selectSalesVehicleDetail} from '../../../store/carDetails/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditParamType} from '../types/AuditParamType';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {findCategoryByUniqueKey} from '../utils/findCategoryByUniqueKey';
import {getAuditFieldData} from '../utils/getAuditFieldData';

export const useVehicleFeatures = (
  categoryStructure?: AuditCategoryOfStructure,
  newVehicleFeatures?: string[]
) => {
  const vehicleDetail = useSelector(selectSalesVehicleDetail);
  const auditData = useSelector(selectAudit);
  const featureCategoryExists = !!categoryStructure;

  const vehicleFeatures = useMemo(
    () =>
      (vehicleDetail?.data
        ? vehicleDetail?.data?.features?.map((feature) => feature.key)
        : newVehicleFeatures) || [],
    [vehicleDetail, newVehicleFeatures]
  );

  const equipmentCategoryId = categoryStructure?.id || '';

  const equipmentValidationCategory: AuditCategoryOfStructure | undefined = useMemo(() => {
    const equipmentInspection = findCategoryByUniqueKey(
      categoryStructure,
      AuditCategoryUniqueKey.EQUIPMENT_INSPECTION
    );

    if (!equipmentInspection) {
      return findCategoryByUniqueKey(
        categoryStructure,
        AuditCategoryUniqueKey.EQUIPMENT_VALIDATION
      );
    }

    return findCategoryByUniqueKey(
      equipmentInspection,
      AuditCategoryUniqueKey.EQUIPMENT_VALIDATION
    );
  }, [categoryStructure]);

  const featuresCategories = equipmentValidationCategory?.childCategories;

  const featuresCategoriesId = featuresCategories?.map((category) => category.id);
  const featuresAssetsCategoriesIds = featuresCategories?.map(
    (feature) =>
      feature?.paramDefinitions?.additionalInformation?.find(
        (item) => item.type === AuditParamType.ADDITIONAL_PHOTOS
      )?.id
  );

  const moreFeaturesCategories = useMemo(
    () =>
      featuresCategories?.find((cat) => cat.uniqueKey === AuditCategoryUniqueKey.MORE_EQUIPMENT)
        ?.childCategories,
    [featuresCategories]
  );

  const addedFeatures = useMemo(() => {
    const featureKeys: string[] = [];
    moreFeaturesCategories?.forEach((category) =>
      category.paramDefinitions?.relatedActions?.forEach((paramDefinition) => {
        const data = getAuditFieldData(category.id, paramDefinition.id, auditData ?? undefined);

        if (isNotNilOrEmpty(data) && Array.isArray(data) && data.length === 1) {
          featureKeys.push(data[0] as string);
        }
      })
    );

    return featureKeys;
  }, [moreFeaturesCategories, auditData]);

  const hasFeature = useCallback(
    (featureKey?: string | null) => {
      if (!featureKey) {
        return false;
      }
      return addedFeatures.includes(featureKey) || vehicleFeatures.includes(featureKey);
    },
    [addedFeatures, vehicleFeatures]
  );

  const selectedFeatures = featuresCategories?.filter(
    (category) =>
      category.uniqueKey !== AuditCategoryUniqueKey.MORE_EQUIPMENT &&
      hasFeature(category.featureKey)
  );

  const selectedFeaturesCategoryIds = selectedFeatures?.map((feature) => feature.id);
  const selectedFeaturesAssetCategoryIds = selectedFeatures?.map(
    (feature) =>
      feature?.paramDefinitions?.additionalInformation?.find(
        (item) => item.type === AuditParamType.ADDITIONAL_PHOTOS
      )?.id
  );

  return useMemo(
    () => ({
      featureCategoryExists,
      vehicleFeatures,
      addedFeatures,
      selectedFeatures,
      selectedFeaturesCategoryIds,
      selectedFeaturesAssetCategoryIds,
      hasFeature,
      moreFeaturesCategories,
      featuresCategories,
      featuresCategoriesId,
      featuresAssetsCategoriesIds,
      featuresMainCategoriesIds: [equipmentCategoryId, equipmentValidationCategory?.id || ''],
    }),
    [
      featureCategoryExists,
      vehicleFeatures,
      addedFeatures,
      selectedFeatures,
      selectedFeaturesCategoryIds,
      selectedFeaturesAssetCategoryIds,
      hasFeature,
      moreFeaturesCategories,
      featuresCategories,
      featuresCategoriesId,
      featuresAssetsCategoriesIds,
      equipmentCategoryId,
      equipmentValidationCategory?.id,
    ]
  );
};
