export type VatRate = {
  type: string;
  name: string;
  rate: string;
  activeFrom: string;
};

export namespace VatRate {
  export enum type {
    S = 'S',
    Z = 'Z',
    E = 'E',
    R = 'R',
    MR = 'MR',
    SR = 'SR',
    P = 'P',
  }
}
