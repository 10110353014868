import {format} from 'date-fns';
import {DayOfWeek, convertStringLocaleToDateFns} from 'platform/locale';

import i18n from '@omnetic-dms/i18n';

import {parseDate} from 'shared';

const exampleDate = parseDate('2020-12-03T09:30:00.000Z');
const formatExample = (dateFormat: string) => format(exampleDate, dateFormat, {locale});

const locale = convertStringLocaleToDateFns(i18n.language);

export const localeOptions = {
  dateTimeFormat: {
    timeShort: [
      {
        fieldLabel: `${formatExample('H:mm')}`,
        label: `${formatExample('H:mm')} (H:mm)`,
        value: 'H:mm',
      },
      {
        fieldLabel: `${formatExample('h:mm aa')}`,
        label: `${formatExample('h:mm aa')} (h:mm aa)`,
        value: 'h:mm aa',
      },
    ],
    timeMedium: [
      {
        fieldLabel: `${formatExample('H:mm:ss')}`,
        label: `${formatExample('H:mm:ss')} (H:mm:ss)`,
        value: 'H:mm:ss',
      },
      {
        fieldLabel: `${formatExample('h:mm:ss aa')}`,
        label: `${formatExample('h:mm:ss aa')} (h:mm:ss aa)`,
        value: 'h:mm:ss aa',
      },
    ],
    timeLong: [
      {
        fieldLabel: `${formatExample('H:mm:ss z')}`,
        label: `${formatExample('H:mm:ss z')} (H:mm:ss z)`,
        value: 'H:mm:ss z',
      },
      {
        fieldLabel: `${formatExample('h:mm:ss aa z')}`,
        label: `${formatExample('h:mm:ss aa z')} (h:mm:ss aa z)`,
        value: 'h:mm:ss aa z',
      },
    ],
    timeFull: [
      {
        fieldLabel: `${formatExample('H:mm:ss zzzz')}`,
        label: `${formatExample('H:mm:ss zzzz')} (H:mm:ss zzzz)`,
        value: 'H:mm:ss zzzz',
      },
      {
        fieldLabel: `${formatExample('H:mm:ss aa zzzz')}`,
        label: `${formatExample('H:mm:ss aa zzzz')} (H:mm:ss aa zzzz)`,
        value: 'H:mm:ss aa zzzz',
      },
    ],
    dateShort: [
      {
        fieldLabel: `${formatExample('d. M. yyyy')}`,
        label: `${formatExample('d. M. yyyy')} (d. M. yyyy)`,
        value: 'd. M. yyyy',
      },
      {
        fieldLabel: `${formatExample('d.M.yyyy')}`,
        label: `${formatExample('d.M.yyyy')} (d.M.yyyy)`,
        value: 'd.M.yyyy',
      },
      {
        fieldLabel: `${formatExample('d.MM.yyyy')}`,
        label: `${formatExample('d.MM.yyyy')} (d.MM.yyyy)`,
        value: 'd.MM.yyyy',
      },
      {
        fieldLabel: `${formatExample('d/M/yyyy')}`,
        label: `${formatExample('d/M/yyyy')} (d/M/yyyy)`,
        value: 'd/M/yyyy',
      },
      {
        fieldLabel: `${formatExample('dd/MM/yyyy')}`,
        label: `${formatExample('dd/MM/yyyy')} (dd/MM/yyyy)`,
        value: 'dd/MM/yyyy',
      },
      {
        fieldLabel: `${formatExample('M/d/yyyy')}`,
        label: `${formatExample('M/d/yyyy')} (M/d/yyyy)`,
        value: 'M/d/yyyy',
      },
    ],
    dateMedium: [
      {
        fieldLabel: `${formatExample('d. MMM yyyy')}`,
        label: `${formatExample('d. MMM yyyy')} (d. MMM yyyy)`,
        value: 'd. MMM yyyy',
      },
      {
        fieldLabel: `${formatExample('d. MMMM yyyy')}`,
        label: `${formatExample('d. MMMM yyyy')} (d. MMMM yyyy)`,
        value: 'd. MMMM yyyy',
      },
    ],
    dateLong: [
      {
        fieldLabel: `${formatExample('EE, d. MMM yyyy')}`,
        label: `${formatExample('EE, d. MMM yyyy')} (EE, d. MMM yyyy)`,
        value: 'EE, d. MMM yyyy',
      },
      {
        fieldLabel: `${formatExample('EE, d. MMMM yyyy')}`,
        label: `${formatExample('EE, d. MMMM yyyy')} (EE, d. MMMM yyyy)`,
        value: 'EE, d. MMMM yyyy',
      },
    ],
    dateFull: [
      {
        fieldLabel: `${formatExample('EEEE, d. MMM yyyy')}`,
        label: `${formatExample('EEEE, d. MMM yyyy')} (EEEE, d. MMM yyyy)`,
        value: 'EEEE, d. MMM yyyy',
      },
      {
        fieldLabel: `${formatExample('EEEE, d. MMMM yyyy')}`,
        label: `${formatExample('EEEE, d. MMMM yyyy')} (EEEE, d. MMMM yyyy)`,
        value: 'EEEE, d. MMMM yyyy',
      },
    ],
    dateTimeShort: [
      {
        fieldLabel: `${formatExample('dd/MM/yyyy H:mm')}`,
        label: `${formatExample('dd/MM/yyyy H:mm')} (dd/MM/yyyy H:mm)`,
        value: 'dd/MM/yyyy H:mm',
      },
      {
        fieldLabel: `${formatExample('d/M/yyyy H:mm')}`,
        label: `${formatExample('d/M/yyyy H:mm')} (d/M/yyyy H:mm)`,
        value: 'd/M/yyyy H:mm',
      },
      {
        fieldLabel: `${formatExample('d.MM.yyyy H:mm')}`,
        label: `${formatExample('d.MM.yyyy H:mm')} (d.MM.yyyy H:mm)`,
        value: 'd.MM.yyyy H:mm',
      },
      {
        fieldLabel: `${formatExample('d. M. yyyy H:mm')}`,
        label: `${formatExample('d. M. yyyy H:mm')} (d. M. yyyy H:mm)`,
        value: 'd. M. yyyy H:mm',
      },
      {
        fieldLabel: `${formatExample('M/d/yyyy h:mm aa')}`,
        label: `${formatExample('M/d/yyyy h:mm aa')} (M/d/yyyy h:mm aa)`,
        value: 'M/d/yyyy h:mm aa',
      },
      {
        fieldLabel: `${formatExample('d.M.yyyy H:mm')}`,
        label: `${formatExample('d.M.yyyy H:mm')} (d.M.yyyy H:mm)`,
        value: 'd.M.yyyy H:mm',
      },
    ],
    dateTimeMedium: [
      {
        fieldLabel: `${formatExample('d. MMM yyyy H:mm')}`,
        label: `${formatExample('d. MMM yyyy H:mm')} (d. MMM yyyy H:mm)`,
        value: 'd. MMM yyyy H:mm',
      },
      {
        fieldLabel: `${formatExample('d. MMMM yyyy H:mm')}`,
        label: `${formatExample('d. MMMM yyyy H:mm')} (d. MMMM yyyy H:mm)`,
        value: 'd. MMMM yyyy H:mm',
      },
      {
        fieldLabel: `${formatExample('d. MMMM yyyy h:mm aa')}`,
        label: `${formatExample('d. MMMM yyyy h:mm aa')} (d. MMMM yyyy h:mm aa)`,
        value: 'd. MMMM yyyy h:mm aa',
      },
    ],
    dateTimeLong: [
      {
        fieldLabel: `${formatExample('d. MMM yyyy H:mm:ss')}`,
        label: `${formatExample('d. MMM yyyy H:mm:ss')} (d. MMM yyyy H:mm:ss)`,
        value: 'd. MMM yyyy H:mm:ss',
      },
      {
        fieldLabel: `${formatExample('d. MMMM yyyy H:mm:ss')}`,
        label: `${formatExample('d. MMMM yyyy H:mm:ss')} (d. MMMM yyyy H:mm:ss)`,
        value: 'd. MMMM yyyy H:mm:ss',
      },
      {
        fieldLabel: `${formatExample('d. MMMM yyyy h:mm:ss aa')}`,
        label: `${formatExample('d. MMMM yyyy h:mm:ss aa')} (d. MMMM yyyy h:mm:ss aa)`,
        value: 'd. MMMM yyyy h:mm:ss aa',
      },
    ],
    dateTimeFull: [
      {
        fieldLabel: `${formatExample('EEEE, d. MMM yyyy H:mm:ss')}`,
        label: `${formatExample('EEEE, d. MMM yyyy H:mm:ss')} (EEEE, d. MMM yyyy H:mm:ss)`,
        value: 'EEEE, d. MMM yyyy H:mm:ss',
      },
      {
        fieldLabel: `${formatExample('EEEE, d. MMMM yyyy H:mm:ss')} (EEEE, d. MMMM yyyy H:mm:ss)`,
        label: `${formatExample('EEEE, d. MMMM yyyy H:mm:ss')} (EEEE, d. MMMM yyyy H:mm:ss)`,
        value: 'EEEE, d. MMMM yyyy H:mm:ss',
      },
      {
        fieldLabel: `${formatExample(
          'EEEE, d. MMMM yyyy H:mm:ss aa'
        )} (EEEE, d. MMMM yyyy H:mm:ss aa)`,
        label: `${formatExample('EEEE, d. MMMM yyyy H:mm:ss aa')}`,
        value: 'EEEE, d. MMMM yyyy H:mm:ss aa',
      },
    ],
  },
  calendar: {
    firstDayOfTheWeek: [
      {
        value: DayOfWeek.MONDAY,
        label: i18n.t('general.labels.calendar.monday'),
      },
      {
        value: DayOfWeek.TUESDAY,
        label: i18n.t('general.labels.calendar.tuesday'),
      },
      {
        value: DayOfWeek.WEDNESDAY,
        label: i18n.t('general.labels.calendar.wednesday'),
      },
      {
        value: DayOfWeek.THURSDAY,
        label: i18n.t('general.labels.calendar.thursday'),
      },
      {
        value: DayOfWeek.FRIDAY,
        label: i18n.t('general.labels.calendar.friday'),
      },
      {
        value: DayOfWeek.SATURDAY,
        label: i18n.t('general.labels.calendar.saturday'),
      },
      {
        value: DayOfWeek.SUNDAY,
        label: i18n.t('general.labels.calendar.sunday'),
      },
    ],
  },
  number: {
    thousandsSeparator: [
      {label: i18n.t('general.localeConfig.dot'), value: '.'},
      {label: i18n.t('general.localeConfig.comma'), value: ','},
      {label: i18n.t('general.localeConfig.space'), value: ' '},
    ],
    decimalSeparator: [
      {label: i18n.t('general.localeConfig.comma'), value: ','},
      {label: i18n.t('general.localeConfig.dot'), value: '.'},
    ],
  },
  currency: {
    thousandsSeparator: [
      {label: i18n.t('general.localeConfig.dot'), value: '.'},
      {label: i18n.t('general.localeConfig.comma'), value: ','},
      {label: i18n.t('general.localeConfig.space'), value: ' '},
    ],
    decimalSeparator: [
      {label: i18n.t('general.localeConfig.comma'), value: ','},
      {label: i18n.t('general.localeConfig.dot'), value: '.'},
    ],

    position: [
      {
        value: 'before',
        label: i18n.t('general.localeConfig.currencyPositionBefore'),
      },
      {
        value: 'after',
        label: i18n.t('general.localeConfig.currencyPositionAfter'),
      },
    ],
  },
};
