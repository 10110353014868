import {Button} from 'platform/components';
import {Box, Center, HStack, Space, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {Trans} from 'react-i18next';

import {i18n} from '@omnetic-dms/i18n';

import {CheckIcon} from './assets/icons/CheckIcon';
import {Circles} from './assets/icons/Circles';
import backgroundImage from './assets/photos/background.png';
import {ComparisonSlider} from './ComparisonSlider';

export const StyledContainer = styled.div`
  background-image: url(${backgroundImage});
  padding: 24px;
  background-size: cover;
  border-radius: 8px;
  svg {
    min-width: 27px;
  }
`;

export const StyledHeading = styled.p`
  color: #091e42;
  font-family: EuclidCircularB, Roboto, sans-serif;
  font-size: 21px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: -0.08px;
  font-weight: 500;
`;

export const StyledText = styled.p`
  color: #091e42;
  font-family: EuclidCircularB, Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  button {
    color: #091e42;
  }
`;

export const StyledFlag = styled.div`
  border-radius: 4px;
  background-color: #091e42;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  color: #fff;
  font-family: EuclidCircularB, Roboto, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
`;

export const StyledLabel = styled.p`
  color: #42526e;
  text-align: center;
  font-family: EuclidCircularB, Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  max-width: 78px;
`;

export const StyledNumber = styled.p`
  color: #091e42;
  text-align: center;
  font-family: EuclidCircularB, Roboto, sans-serif;
  font-weight: 700;
  font-size: 50.703px;
  font-style: normal;
  line-height: 57.946px; /* 114.286% */
  letter-spacing: -0.724px;
`;
export const StyledPercent = styled.p`
  color: #091e42;
  font-family: EuclidCircularB, Roboto, sans-serif;
  font-size: 28.973px;
  font-style: normal;
  line-height: 57.946px;
  letter-spacing: -0.724px;
  font-weight: 700;
`;

// when deleting this component, remove also styles importing EuclidCircular fonts from apps/omnetic-dms/src/index.html
export function BannerBgRemoval() {
  return (
    <StyledContainer>
      <HStack justify="space-between" align="center">
        <VStack spacing={4}>
          <HStack spacing={4} align="center">
            <StyledHeading>{i18n.t('entity.photo.backgroundRemoval.title')}</StyledHeading>
            <StyledFlag>{i18n.t('entity.photo.backgroundRemoval.news')}</StyledFlag>
          </HStack>
          <VStack spacing={3}>
            <HStack spacing={4} align="center">
              <CheckIcon />
              <StyledText>
                <Trans
                  i18nKey="entity.photo.backgroundRemoval.sellingPointBackground"
                  components={{bold: <strong />}}
                />
              </StyledText>
            </HStack>
            <HStack spacing={4} align="center">
              <CheckIcon />
              <StyledText>
                <Trans
                  i18nKey="entity.photo.backgroundRemoval.sellingPointPlateCover"
                  components={{bold: <strong />}}
                />
              </StyledText>
            </HStack>
            <HStack spacing={4} align="center">
              <CheckIcon />
              <StyledText>
                <Trans
                  i18nKey="entity.photo.backgroundRemoval.sellingPointGlare"
                  components={{bold: <strong />}}
                />
              </StyledText>
            </HStack>
          </VStack>
          <StyledText>
            <Button
              isFullWidth={false}
              title={i18n.t('entity.photo.backgroundRemoval.more')}
              onClick={() => window.open('https://www.omnetic.com/cs/fotografie')}
              variant="link"
            />
          </StyledText>
        </VStack>
        <Space horizontal={3} />
        <Center>
          <Circles />
          <Box position="absolute" paddingTop={3}>
            <StyledLabel>{i18n.t('entity.photo.backgroundRemoval.conversions')}</StyledLabel>
            <Box paddingLeft={1}>
              <HStack align="baseline" justify="center">
                <StyledNumber>15</StyledNumber>
                <StyledPercent>%</StyledPercent>
              </HStack>
            </Box>
          </Box>
        </Center>
        <Space horizontal={10} />
        <ComparisonSlider />
      </HStack>
    </StyledContainer>
  );
}
