import {match} from 'ts-pattern';

import {always} from 'ramda';

import {useTranslationContext} from '../../TranslationProvider/TranslationContext';

export function useMonthName() {
  const t = useTranslationContext();

  const getMonthName = (month: number) =>
    match(month)
      .with(1, always(t('general.monthNames.january')))
      .with(2, always(t('general.monthNames.february')))
      .with(3, always(t('general.monthNames.march')))
      .with(4, always(t('general.monthNames.april')))
      .with(5, always(t('general.monthNames.may')))
      .with(6, always(t('general.monthNames.june')))
      .with(7, always(t('general.monthNames.july')))
      .with(8, always(t('general.monthNames.august')))
      .with(9, always(t('general.monthNames.september')))
      .with(10, always(t('general.monthNames.october')))
      .with(11, always(t('general.monthNames.november')))
      .with(12, always(t('general.monthNames.december')))
      .otherwise(always(undefined));

  return {getMonthName};
}
