import i18n from 'i18next';

import {showNotification} from '../components/Notification/utils/events';

export const copyToClipboard = (id: string) => {
  navigator.clipboard
    .writeText(id)
    .then(() => {
      showNotification.info(i18n.t('general.notifications.copyToClipboard'));
    })
    .catch(() => {
      showNotification.error(i18n.t('general.notifications.copyToClipboardFailed'));
    });
};
