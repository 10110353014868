import {Action, Card, DataStatus, DropdownActionMenuItem} from 'platform/components';
import {Hide, Show} from 'platform/foundation';

import {isNil, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {CustomerResponseBodyV2, useGetCustomerV2Query} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {customerRoutes} from '@omnetic-dms/routes';

import {buildArray, composePath, Nullish, suffixTestId, TestIdProps} from 'shared';

import {useEditCustomer} from '../../hooks/useEditCustomer';
import {CustomerInformation} from './components/CustomerInformation';
import {CustomerMatchOrCreate} from './components/CustomerMatchOrCreate';
import {SecondStepComponentType} from './types/SecondStepComponentType';

interface CustomerWidgetCardProps extends TestIdProps {
  customer?: CustomerResponseBodyV2 | null;
  customTitle?: string;
  customActions?: DropdownActionMenuItem[];
  secondStepComponentType: SecondStepComponentType;
  onCustomer: (customer: CustomerResponseBodyV2) => void;
  isLoadingOnCustomer?: boolean;
  onChange?: VoidFunction;
  resourceId?: string;
  recordId?: string;
  isDetailLinkVisible?: boolean;
  isContactPersonHidden?: boolean;
  hasVerification?: boolean;
  vehicleId?: string | Nullish;
  tooltip?: string;
}

export function CustomerWidgetCard(props: CustomerWidgetCardProps) {
  const {
    data: customer,
    isLoading,
    isError,
  } = useGetCustomerV2Query(
    {customerId: props.customer?.id ?? ''},
    {skip: isNil(props.customer?.id)}
  );
  const editCustomer = useEditCustomer(customer, props.onCustomer);

  const actions = buildArray<Action>()
    .when(isNotNilOrEmpty(props.customActions), {
      type: 'dropdown-iconButton',
      icon: 'navigation/more_vert',
      severity: 'informational',
      menuItems: props.customActions ?? [],
    })
    .when(props.isDetailLinkVisible, {
      title: i18n.t('general.labels.detail'),
      leftIcon: 'action/open_in_new',
      variant: 'link',
      type: 'button',
      onClick: () =>
        window.open(
          composePath(customerRoutes.detail, {
            params: {id: props.customer?.id},
            isSearchParamsPreserved: false,
          })
        ),
    })
    .when(isNotNil(props.onChange), {
      variant: 'link',
      type: 'button',
      title: i18n.t('general.actions.change'),
      leftIcon: 'action/autorenew',
      onClick: props.onChange,
    })
    .add({
      variant: 'link',
      type: 'button',
      title: i18n.t('general.actions.edit'),
      leftIcon: 'image/edit',
      isLoading: props.isLoadingOnCustomer,
      onClick: editCustomer,
    });

  return (
    <Card
      actions={isNotNil(props.customer) ? actions : undefined}
      title={props.customTitle || i18n.t('entity.customer.labels.customer')}
      tooltip={props.tooltip}
      data-testid={suffixTestId('header', props)}
    >
      <Hide when={props.customer}>
        <CustomerMatchOrCreate
          secondStepComponentType={props.secondStepComponentType}
          onCustomer={props.onCustomer}
          data-testid={suffixTestId('finder', props)}
          vehicleId={props.vehicleId}
        />
      </Hide>
      <DataStatus isLoading={isLoading} isError={isError}>
        <Show when={customer}>
          <CustomerInformation
            secondStepComponentType={props.secondStepComponentType}
            isContactPersonHidden={props.isContactPersonHidden}
            customer={customer!}
            resourceId={props.resourceId}
            recordId={props.recordId}
            hasVerification={props.hasVerification}
            data-testid={suffixTestId('information', props)}
          />
        </Show>
      </DataStatus>
    </Card>
  );
}
