/**
 * Generates a hash for the provided objects using a simple hashing algorithm.
 * @param {...object} objects - One or more objects to be hashed.
 * @returns {string} The generated hash as a string.
 */
export const generateHashFromObjects = (...objects: (object | undefined)[]): string => {
  const stringifiedObjects = objects
    .filter((obj) => obj !== undefined)
    .map((obj) => JSON.stringify(obj))
    .join('');
  let hash = 0;

  if (stringifiedObjects.length === 0) {
    return hash.toString();
  }

  for (let i = 0; i < stringifiedObjects.length; i++) {
    const char = stringifiedObjects.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }

  return hash.toString();
};
