import {Dropdown, DropdownItem} from 'platform/components';
import styled from 'styled-components';

import {useDispatch} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {getNaturalPersonFullName} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {fetchUnAssignRequest} from '../../../store/assignment/reducer';
import {TAssignmentUserProps} from '../../../store/assignment/types';

export function AssignmentUsers({assignment, ...rest}: TAssignmentUserProps & TestIdProps) {
  const dispatch = useDispatch();
  const menuList = [
    {
      label: i18n.t('general.labels.unassign'),
      callBack: () => {
        if (assignment) {
          dispatch(fetchUnAssignRequest(assignment));
        }
      },
    },
  ];

  return (
    <Dropdown
      dropdownControl={
        <AssignWrapper>
          <AssignLabel aria-haspopup="true" data-testid={suffixTestId('asignedToUsername', rest)}>
            {assignment?.user &&
              (getNaturalPersonFullName({
                firstName: assignment?.user.firstName ?? undefined,
                lastName: assignment?.user.lastName ?? undefined,
              }) ||
                assignment?.user?.email ||
                '')}
          </AssignLabel>
        </AssignWrapper>
      }
    >
      {menuList?.map(({callBack, label}) => (
        <DropdownItem label={label} key={`menu-item-${label}`} onClick={callBack} />
      ))}
    </Dropdown>
  );
}

const AssignLabel = styled.span`
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  color: ${({theme}) => theme.colors.palettes.blue[60][100]};
  display: inline-block;
  cursor: pointer;
`;

const AssignWrapper = styled.div`
  margin-right: 16px;
`;
