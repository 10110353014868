import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';

import {useRequiredParams} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsCodelist} from './components/SettingsCodelist';

const breadcrumbs = [
  {
    label: i18n.t('page.codelists.pageTitle'),
    href: settingsRoutes.codelists,
  },
];

function SettingsSalesCodelistDetail() {
  const {id} = useRequiredParams();

  return (
    <SettingsTemplate
      header={{breadcrumbs}}
      data-testid={testIds.settings.codelistDetail('layout')}
    >
      <SettingsCodelist codelistId={id} />
    </SettingsTemplate>
  );
}

export const CodelistDetail = SettingsSalesCodelistDetail;
