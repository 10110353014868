import {isNil} from 'ramda';

import {TreeFolder} from '@omnetic-dms/api';

import {TreeFolderWithParents} from '../types/TreeFolderWithParents';

export const getAllFolders = (
  folders: (TreeFolder | null)[],
  parents: TreeFolder[] = []
): TreeFolderWithParents[] => {
  const result: TreeFolderWithParents[] = [];

  for (const folder of folders) {
    if (isNil(folder)) {
      continue;
    }

    const currentFolderWithParents: TreeFolderWithParents = {...folder, parents};
    result.push(currentFolderWithParents);

    const subfolders = folder.subfolders ?? [];
    result.push(...getAllFolders(subfolders, [...parents, folder]));
  }

  return result;
};
