import {TextSize, Integer, ThemeRadiusPath} from 'platform/foundation';
import {match} from 'ts-pattern';

import {path, pipe, defaultTo} from 'ramda';

import {BaseFlagProps, ColorSchemeType, FlagColorScheme} from './types';

export const getColorsFromScheme = (key: ColorSchemeType = 'neutral', isSubtle = false) => {
  const colorType = isSubtle ? 'subtle' : 'primary';

  return pipe(
    path<(typeof FlagColorScheme)[typeof colorType][ColorSchemeType]>([colorType, key]),
    defaultTo(FlagColorScheme.primary.neutral)
  )(FlagColorScheme);
};

type SizeType = BaseFlagProps['size'];
export const getFlagElementSizes = (size: SizeType = 'default') => {
  const textSize: TextSize = size === 'small' ? 'xxSmall' : 'small';
  const borderRadius: ThemeRadiusPath = size === 'small' ? 'xSmall' : 'small';

  const height = match(size)
    .with('large', () => 8 as Integer)
    .with('default', () => 6 as Integer)
    .with('small', () => 4 as Integer)
    .exhaustive();

  const labelHorizontalPadding = match(size)
    .with('large', () => 3 as Integer)
    .with('default', () => 2 as Integer)
    .with('small', () => 1 as Integer)
    .exhaustive();

  return {textSize, borderRadius, height, labelHorizontalPadding};
};
