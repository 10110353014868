import {Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {Right, VStack} from 'platform/foundation';
import {object} from 'yup';

import {isNil} from 'ramda';

import {yupString} from 'shared';

type QueryModifierFormType = {
  query: string;
};

interface OverrideQueryModifierProps {
  onQueryModifierChange: (modifier: object | null) => void;
}

export function OverrideQueryModifier(props: OverrideQueryModifierProps) {
  const onSubmit: FormSubmitHandler<QueryModifierFormType> = async ({query}) => {
    const modifier: object | null = validateJsonString(query) ? JSON.parse(query) : null;

    await props.onQueryModifierChange(modifier);
  };

  return (
    <Form<QueryModifierFormType> schema={schema} onSubmit={onSubmit}>
      {(control) => (
        <VStack spacing={4}>
          <FormField control={control} name="query" type="textarea" label="JSON" />
          <Right>
            <FormButton control={control} title="Override" type="submit" />
          </Right>
        </VStack>
      )}
    </Form>
  );
}

const validateJsonString = (json: string) => {
  try {
    JSON.parse(json);
  } catch (error) {
    return false;
  }
  return true;
};

const schema = object({
  query: yupString.test(
    'isValidJSON',
    'Is not valid JSON',
    (value) => isNil(value) || validateJsonString(value)
  ),
});
