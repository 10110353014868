import {ConfirmDialog, IconButton, showNotification, useDialog} from 'platform/components';
import {HStack, Show} from 'platform/foundation';

import {ReactElement} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {hasPath, isNil, isNotNil} from 'ramda';

import {
  SourcingVehicleDetailResponseBody,
  useSourcingBulkCreateUserComparisonMutation,
  useSourcingBulkCreateUserFavouriteMutation,
  useSourcingBulkCreateUserHiddenMutation,
  useSourcingBulkCreateUserToBuyMutation,
  useSourcingBulkDeleteUserComparisonMutation,
  useSourcingBulkDeleteUserFavouriteMutation,
  useSourcingBulkDeleteUserHiddenMutation,
  useSourcingBulkDeleteUserToBuyMutation,
} from '@omnetic-dms/api';
import {i18n} from '@omnetic-dms/i18n';
import {sourcingRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';
import {
  afterLastSlash,
  copyDataForDetail,
  PREV_URL_COOKIE,
  removeDuplicateFromAdId,
  UserListPage,
  useThunkDispatch,
} from '@omnetic-dms/teas';

import {composePath, noop, Nullish} from 'shared';

type ActionButtonsVehicleProps = {
  actionButton: ReactElement | Nullish;
  adId?: string;
  index?: number;
  isDisabled?: boolean;
  vehicle?: SourcingVehicleDetailResponseBody;
  setLastVisitedVehicle?: (adId: string, index: number) => void;
};

type ActionButtonsConnectedProps = {
  isDetail: boolean;
  isHidden: boolean;
  copyDataForDetail: (vehicle: SourcingVehicleDetailResponseBody) => void;
  onDetailButtonClick?: () => void;
};

export type ActionButtonsVehicleComponentProps = ActionButtonsVehicleProps &
  ActionButtonsConnectedProps;

export function ActionButtonsVehicleComponent({
  actionButton,
  adId,
  index,
  setLastVisitedVehicle,
  onDetailButtonClick = noop,
  vehicle,
  isDetail,
  isHidden,
  isDisabled = false,
  copyDataForDetail,
}: ActionButtonsVehicleComponentProps) {
  const [createUserFavourite] = useSourcingBulkCreateUserFavouriteMutation();
  const [deleteUserFavourite] = useSourcingBulkDeleteUserFavouriteMutation();
  const [createUserComparison] = useSourcingBulkCreateUserComparisonMutation();
  const [deleteUserComparison] = useSourcingBulkDeleteUserComparisonMutation();
  const [createUserHidden] = useSourcingBulkCreateUserHiddenMutation();
  const [deleteUserHidden] = useSourcingBulkDeleteUserHiddenMutation();
  const [createUserToBuy] = useSourcingBulkCreateUserToBuyMutation();
  const [deleteUserToBuy] = useSourcingBulkDeleteUserToBuyMutation();
  const [isOpen, open, close] = useDialog();

  const handleDetailButtonClick = () => {
    if (isNil(vehicle) || isNil(adId)) {
      return;
    }

    copyDataForDetail(vehicle);
    setLastVisitedVehicle?.(adId, index ?? 0);

    onDetailButtonClick();
  };

  const isVehicleHidden = isHidden || vehicle?.hidden || (isDetail && vehicle?.hidden);

  const handleHideButtonClick = () => {
    isVehicleHidden
      ? isNotNil(adId) &&
        deleteUserHidden({body: {externalIds: [adId]}})
          .unwrap()
          .then(() => showNotification.success())
          .catch(handleApiError)
      : open();
  };

  const handleConfirmHide = () => {
    isNotNil(adId) &&
      createUserHidden({body: {externalIds: [adId]}})
        .unwrap()
        .then(() => showNotification.success())
        .catch(handleApiError);
  };

  const handleComparisonButtonClick = () => {
    isNotNil(adId) &&
      (vehicle?.comparison ? deleteUserComparison : createUserComparison)({
        body: {externalIds: [adId]},
      })
        .unwrap()
        .then(() => showNotification.success())
        .catch(handleApiError);
  };

  const handleFavouriteButtonClick = () => {
    isNotNil(adId) &&
      (vehicle?.favourite ? deleteUserFavourite : createUserFavourite)({
        body: {externalIds: [adId]},
      })
        .unwrap()
        .then(() => showNotification.success())
        .catch(handleApiError);
  };

  const handleShopButtonClick = () => {
    isNotNil(adId) &&
      (vehicle?.inCart ? deleteUserToBuy : createUserToBuy)({body: {externalIds: [adId]}})
        .unwrap()
        .then(() => showNotification.success())
        .catch(handleApiError);
  };

  return (
    <HStack spacing={1}>
      <IconButton
        size="small"
        icon="action/visibility"
        severity={vehicle?.hidden ? 'danger' : undefined}
        isDisabled={vehicle?.hidden ? false : isDisabled}
        data-testid="action-buttons-car-unhide-button"
        onClick={handleHideButtonClick}
      />

      <ConfirmDialog
        text={i18n.t('entity.vehicle.labels.reallyHide')}
        onConfirm={handleConfirmHide}
        onClose={close}
        isOpen={isOpen}
        data-testid={testIds.sourcing.classifieds('actionButtonsVehicle-hideConfirmDialog')}
      />

      <IconButton
        size="small"
        severity={vehicle?.comparison ? 'success' : undefined}
        icon="action/compare_arrows"
        isDisabled={vehicle?.comparison ? false : isDisabled}
        data-testid="action-buttons-car-comparison-button"
        onClick={handleComparisonButtonClick}
      />

      <IconButton
        size="small"
        severity={vehicle?.favourite ? 'warning' : undefined}
        icon="toggle/star"
        isDisabled={vehicle?.favourite ? false : isDisabled}
        data-testid="action-buttons-car-favourite-button"
        onClick={handleFavouriteButtonClick}
      />

      <IconButton
        size="small"
        severity={vehicle?.inCart ? 'informational' : undefined}
        icon="action/shopping_cart"
        isDisabled={vehicle?.inCart ? false : isDisabled}
        data-testid="action-buttons-car-cart-button"
        onClick={handleShopButtonClick}
      />

      <HStack spacing={1} align="flex-start">
        {actionButton}

        <Show when={!isDetail}>
          <Link
            to={
              isNotNil(adId)
                ? composePath(sourcingRoutes.vehicleDetail, {
                    params: {adId},
                  })
                : ''
            }
            data-testid="action-buttons-car-detail-link"
          >
            <IconButton
              size="small"
              priority="primary"
              icon="hardware/keyboard_arrow_right"
              onClick={handleDetailButtonClick}
              data-testid={`actionButtons-vehicleDetailButton-${index}`}
            />
          </Link>
        </Show>
      </HStack>
    </HStack>
  );
}

export function ActionButtonsVehicle(props: ActionButtonsVehicleProps) {
  const {pathname} = useLocation();
  const dispatch = useThunkDispatch();

  const isDetail =
    pathname ===
    composePath(sourcingRoutes.vehicleDetail, {
      params: {adId: props.adId ?? ':adId'},
      isSearchParamsPreserved: false,
    });

  const route = afterLastSlash(pathname);

  const isHidden = route === UserListPage.HIDDEN_VEHICLE_PAGE;

  const connectedProps: ActionButtonsConnectedProps = {
    isDetail,
    isHidden,
    copyDataForDetail: (vehicle) => {
      let dataKey = 'sourcingVehicle';
      if (hasPath(['auctionVehicle'], vehicle)) {
        dataKey = 'auctionVehicle';
      }
      const vehicleData = vehicle.sourcingVehicle;
      if (vehicleData?.adId === undefined) {
        return;
      }
      dispatch(
        copyDataForDetail({
          ...vehicle,
          [dataKey]: {
            ...vehicleData,
            adId: removeDuplicateFromAdId(vehicleData.adId),
          },
        })
      );
    },
    onDetailButtonClick: () => {
      localStorage.setItem(PREV_URL_COOKIE, pathname);
    },
  };

  return <ActionButtonsVehicleComponent {...props} {...connectedProps} />;
}
