import {concat, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {ColDef, ColGroupDef} from '../types/AgGridTypes';

export type ColDefWithIndex = (ColDef<any, any> | ColGroupDef<any>) & {index: number};
export type GroupDefWithIndex = ColGroupDef<any> & {index: number};

export const getGroupedColDefs = (
  items: (ColDef<any, any> | ColGroupDef<any>)[]
): (ColDefWithIndex | GroupDefWithIndex)[] => {
  const groupedItemsMap = new Map<string, ColDefWithIndex>();

  items.forEach((col, i) => {
    const groupId = isGroupDefinition(col) ? (col.groupId ?? i.toString()) : i.toString();
    const group = groupedItemsMap.get(groupId);

    if (isNilOrEmpty(group)) {
      groupedItemsMap.set(groupId, {...col, index: groupedItemsMap.size});
    } else if (isGroupDefinition(group) && isGroupDefinition(col)) {
      groupedItemsMap.set(groupId, {
        ...group,
        children: concat(group.children, col.children),
      });
    }
  });

  return Array.from(groupedItemsMap.values()).sort((a, b) => a.index - b.index);
};

export const isGroupDefinition = (
  val: ColDef<any, any> | ColGroupDef<any> | undefined
): val is GroupDefWithIndex => isNotNil(val) && 'children' in val;
