import {defaultTo} from 'ramda';

import {Nullish, parseDate} from 'shared';

export function getCompletionAt(date?: Date | Nullish, time?: string | Nullish) {
  if (!date) {
    return;
  }

  const minutes = defaultTo(0, parseInt(defaultTo('', time).substring(3)));
  const hours = defaultTo(0, parseInt(defaultTo('', time).substring(0, 2)));

  const completionAt = date && parseDate(date);

  completionAt.setHours(hours);
  completionAt.setMinutes(minutes);

  return completionAt.toISOString();
}
