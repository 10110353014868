import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  useTranslationContext,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {suffixTestId, TestIdProps} from 'shared';

import {Preset} from '../../types/Api';

type PartialPresetType = {
  title: string;
  description: string | null;
};

export type EditPresetDialogProps = TestIdProps & {
  presetToEdit?: Preset;
  onSubmit: (title: string, desc: string) => Promise<void>;
};

export function EditPresetDialog(props: EditPresetDialogProps) {
  const t = useTranslationContext();

  const PartialPresetTypeSchema = Yup.object({
    title: Yup.string().trim().required(t('general.notifications.errorFieldRequired')),
    description: Yup.string(),
  });

  const handleSubmit = (values: PartialPresetType) =>
    props.onSubmit(values.title, values.description ?? '').then(() => closeCurrentDialog());

  return (
    <Form<PartialPresetType>
      schema={PartialPresetTypeSchema}
      onSubmit={handleSubmit}
      defaultValues={{
        title: props.presetToEdit?.title,
        description: props.presetToEdit?.description,
      }}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            isRequired
            type="text"
            name="title"
            label={t('general.presets.presetName')}
            placeholder={t('general.presets.presetName')}
            data-testid={suffixTestId('presetName', props)}
          />

          <FormField
            control={control}
            type="textarea"
            name="description"
            label={t('general.presets.descriptionPlaceholder')}
            placeholder={t('general.presets.descriptionPlaceholder')}
          />

          <ButtonGroup align="right">
            <Button
              variant="secondary"
              title={t('general.actions.discard')}
              onClick={closeCurrentDialog}
            />
            <FormButton
              control={control}
              type="submit"
              title={t('general.actions.save')}
              data-testid={suffixTestId('submitButton', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
