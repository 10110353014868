export const SECOND_SET_VALUES: Record<string, string> = {
  secondSetComplete: 'COMPLETE_SET',
  secondSetOnlyRims: 'RIMS_ONLY',
  secondSetOnlyPneus: 'TIRES_ONLY',
};

export enum CategoryUniqueKey {
  LR_WHEEL = 'LR_WHEEL',
  RR_WHEEL = 'RR_WHEEL',
  RF_WHEEL = 'RF_WHEEL',
  LF_WHEEL = 'LF_WHEEL',
  THE_SECOND_SET = 'THE_SECOND_SET',
  TESTDRIVE = 'TESTDRIVE',
  PHOTODOCUMENTATION = 'PHOTODOCUMENTATION',
  EXTERIOR_DAMAGE = 'EXTERIOR_DAMAGE',
  DAMAGE_TO_THE_INTERIOR = 'DAMAGE_TO_THE_INTERIOR',
  EQUIPMENT = 'EQUIPMENT',
}
