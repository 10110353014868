import {IconButton} from 'platform/components';

import {useCallback} from 'react';

import {useExpandContext} from './hooks/useExpandContext';

export function ExpandCell() {
  const [isExpanded, setExpanded] = useExpandContext();
  const toggleExpand = useCallback(() => {
    setExpanded((e) => !e);
  }, [setExpanded]);

  return (
    <IconButton
      size="small"
      data-testid="simple-table-expand-cell"
      icon={isExpanded ? 'navigation/expand_less' : 'navigation/expand_more'}
      onClick={toggleExpand}
    />
  );
}
