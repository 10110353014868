import {Card, IconButton, Upload, UploadState} from 'platform/components';
import {Hide, Right, Show, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {useState} from 'react';

import {always} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {Section} from '@omnetic-dms/shared';

import {useBoolean} from 'shared';

const spynaUrl =
  'https://www.spyne.ai/category/hotspot-view?sku_id=48414ea8d1714467b8c56fc8c08297de&image_category=3D_Model';

export function ThreeHundredAndSixtyPhotos() {
  const [isUploading, setIsUploading, stopIsUploading] = useBoolean();
  const [hasUploadError, _, hideUploadError] = useBoolean();
  const [url, setUrl] = useState<string | null>(null);

  const handleUpload = () => {
    setTimeout(() => {
      setUrl(spynaUrl);
      stopIsUploading();
    }, 2500);
  };

  const uploadState = match<[boolean, boolean], UploadState | undefined>([
    hasUploadError,
    isUploading,
  ])
    .with([true, Pattern.boolean], always(UploadState.Error))
    .with([Pattern.boolean, true], always(UploadState.Uploading))
    .with([Pattern.boolean, false], always(UploadState.Success))
    .otherwise(always(undefined));

  return (
    <Section>
      <Card
        data-testid={testIds.vehicles.detail('threeHundredAndSixtyPhotos')}
        title={i18n.t('entity.vehicle.labels.threeHundredAndSixtyPhotos')}
      >
        <Hide when={url}>
          <Upload
            accept="video/*"
            name="document"
            size="minHeight"
            type="card"
            onStart={() => {
              setUrl(null);
              hideUploadError();
              setIsUploading();
            }}
            uploadState={uploadState}
            uploadText={i18n.t('general.actions.upload')}
            customRequest={handleUpload}
            data-testid={testIds.vehicles.detail('threeHundredAndSixtyPhotos')}
          />
        </Hide>
        <Show when={url}>
          <VStack>
            <iframe
              data-testid={testIds.vehicles.detail('threeHundredAndSixtyPhotos')}
              width="100%"
              height={600}
              title="threeHundredAndSixtyPhotos"
              src={url!}
            />
            <Right>
              <IconButton
                data-testid={testIds.vehicles.detail('threeHundredAndSixtyPhotos')}
                icon="action/delete"
                onClick={() => setUrl(null)}
              />
            </Right>
          </VStack>
        </Show>
      </Card>
    </Section>
  );
}
