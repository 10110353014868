import {showNotification} from 'platform/components';

import {any, isNotNil, last} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {
  UtmParameters,
  useConvertForBuyingVehicleMutation,
  useDeleteMinisaleMutation,
  useGetCustomerV2Query,
  useGetInterestQuery,
  useGetMinipurchaseQuery,
  useGetMinisaleQuery,
  useGetOfferedVehiclesListQuery,
  useReadCodeQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes} from '@omnetic-dms/routes';
import {handleApiError, queryParams} from '@omnetic-dms/shared';

import {composePath, useNavigate, useQueryState} from 'shared';

import {getLastActiveBusinessCase} from '../utils/getLastActiveBusinessCase';

export function useInterestData(interestId: string) {
  const navigate = useNavigate();

  const [_activeSectionId] = useQueryState(queryParams.COMPONENT_SECTIONS_SECTION);

  const {
    data: interest,
    isLoading: isInterestLoading,
    isError: isInterestErrored,
  } = useGetInterestQuery({id: interestId});

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: isCustomerErrored,
  } = useGetCustomerV2Query(
    {customerId: interest?.customerId ?? ''},
    {
      skip: isNilOrEmpty(interest?.customerId),
    }
  );

  const {data: readCode} = useReadCodeQuery(
    {codelistId: 'interest_source', codeId: interest?.sourceCodeId ?? ''},
    {skip: !interest?.sourceCodeId}
  );

  const minisaleId = interest?.minisalesIds ? last(interest.minisalesIds) : null;
  const {data: miniSale, isFetching: isMiniSaleFetching} = useGetMinisaleQuery(
    {
      minisaleId: minisaleId!,
    },
    {
      skip: isNilOrEmpty(minisaleId),
      // ensures correct functionality of one-way autoSave - after arriving at this page we force a re-fetch
      refetchOnMountOrArgChange: true,
    }
  );

  const miniPurchaseId = interest?.minipurchasesIds ? last(interest.minipurchasesIds) : null;
  const {data: miniPurchase, isFetching: isMinipurchaseFetching} = useGetMinipurchaseQuery(
    {minipurchaseId: miniPurchaseId!},
    {
      skip: isNilOrEmpty(miniPurchaseId),
      // ensures correct functionality of one-way autoSave - after arriving at this page we force a re-fetch
      refetchOnMountOrArgChange: true,
    }
  );

  const {data: offeredVehiclesList} = useGetOfferedVehiclesListQuery(
    {body: {vehicleIds: miniPurchase?.offeredVehiclesIds ?? ['']}},
    {skip: isNilOrEmpty(miniPurchase?.offeredVehiclesIds)}
  );

  const [convertForBuyingVehicle, {isLoading: isConvertForBuyingVehicleLoading}] =
    useConvertForBuyingVehicleMutation();

  const [_deleteMinisale, {isLoading: isDeleteMinisaleLoading}] = useDeleteMinisaleMutation();

  const onCreateBuyingBusinessCase = () => {
    convertForBuyingVehicle({interestId})
      .unwrap()
      .then((response) => {
        const lastActiveBusinessCase = getLastActiveBusinessCase(response?.activeBusinessCases);

        if (!lastActiveBusinessCase?.businessCaseId) {
          showNotification.error();
          return;
        }

        navigate(
          composePath(businessCaseRoutes.overview, {
            params: {
              id: lastActiveBusinessCase.businessCaseId,
            },
          })
        );

        showNotification.success(i18n.t('general.notifications.success'));
      })
      .catch(handleApiError);
  };

  const isLoading = any(isTrue, [
    isInterestLoading,
    isCustomerLoading,
    isConvertForBuyingVehicleLoading,
  ]);

  const isActionsDisabled = any(isTrue, [
    isInterestLoading,
    isCustomerLoading,
    isConvertForBuyingVehicleLoading,
    isMiniSaleFetching,
    isMinipurchaseFetching,
    isDeleteMinisaleLoading,
  ]);

  const isError = any(isTrue, [isInterestErrored, isCustomerErrored]);

  return {
    interest,
    customer,
    utm:
      interest?.utm ??
      ({
        source: null,
        medium: null,
        campaign: null,
        term: null,
        content: null,
      } satisfies UtmParameters),
    readCode,
    miniSale: isNotNil(minisaleId) && isNotNil(miniSale) ? miniSale : null,
    miniPurchase: isNotNil(miniPurchaseId) && isNotNil(miniPurchase) ? miniPurchase : null,
    offeredVehiclesList:
      isNotNilOrEmpty(miniPurchase?.offeredVehiclesIds) && isNotNil(offeredVehiclesList)
        ? offeredVehiclesList
        : null,
    onCreateBuyingBusinessCase,
    isLoading,
    isError,
    isActionsDisabled,
  };
}
