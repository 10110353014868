import {Box, VStack} from 'platform/foundation';

import {FC} from 'react';

import {TopMakeRow, TopMakeRowProps} from './TopMakeRow';
import {MakeAndChecked} from './types';

interface TopMakesListProps {
  readonly makeOptions: MakeAndChecked[];
  readonly handleRowToggle: TopMakeRowProps['handleRowToggle'];
}

export const TopMakesList: FC<TopMakesListProps> = ({makeOptions, handleRowToggle}) => (
  <Box paddingTop={4}>
    <VStack spacing={4}>
      {makeOptions.map((make, i) => (
        <TopMakeRow
          data-testid={`${make.value}-row`}
          key={`${make.value}-${i}`}
          make={make}
          handleRowToggle={handleRowToggle}
        />
      ))}
    </VStack>
  </Box>
);
