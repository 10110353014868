import {MultiChoice, showNotification} from 'platform/components';
import {Grid} from 'platform/foundation';

import {useDispatch} from 'react-redux';

import {isEmpty, isNil} from 'ramda';

import {
  RoleResponseBody,
  useGetCurrentUserInfoQuery,
  useGetRolesQuery,
  useGetUserQuery,
  usePatchUserMutation,
  userApi,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface UserRolesProps extends RequiredTestIdProps {
  userId: string;
}
export function UserRoles(props: UserRolesProps) {
  const {data: user} = useGetUserQuery({id: props.userId});
  const {data: roles, isLoading: isLoadingRoles} = useGetRolesQuery({});
  const {data: currentUser} = useGetCurrentUserInfoQuery();

  const isUserCurrentUser = props.userId === currentUser?.id;
  const dispatch = useDispatch();

  const [patchUser, {isLoading: isPatchingUser}] = usePatchUserMutation();

  const handlePatchUserBranches = (roles: string[] | null) => {
    if (isNil(roles) || isEmpty(roles)) {
      showNotification.error(i18n.t('general.notifications.errorRoleIsRequired'));
      return;
    }

    patchUser({userId: props.userId, updateUserRequestBody: {roles}})
      .unwrap()
      .then(() => {
        if (isUserCurrentUser) {
          dispatch(userApi.util.invalidateTags(['CurrentUser']));
        }
      })
      .catch(handleApiError);
  };

  const userRoles = user?.roles?.map((item) => item.id) ?? [];
  const roleOptions = roles?.map(rolesToOptions) ?? [];

  return (
    <Grid columns={2}>
      <MultiChoice
        value={userRoles}
        isLoading={isPatchingUser || isLoadingRoles}
        isRequired
        onChange={handlePatchUserBranches}
        isSearchable
        isNotClearable
        menuInPortal
        options={roleOptions}
        label={i18n.t('page.settings.labels.assignedRole')}
        helperText={i18n.t('entity.branch.labels.roleHelperText')}
        closeMenuOnSelect={false}
        data-testid={suffixTestId('userRoles-roles', props)}
      />
    </Grid>
  );
}

const rolesToOptions = (role: RoleResponseBody) => ({
  value: role.id,
  label: role.title,
});
