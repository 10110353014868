import {not} from 'ramda';

import {ColDefWithIndex, GroupDefWithIndex} from './getGroupedColDefs';
import {isColDef} from './isColDef';

export const addTestIdsToAgGridElements = (
  filteredGroupedDefs: (ColDefWithIndex | GroupDefWithIndex)[]
) => {
  document
    .querySelectorAll('.ag-filter-list-panel .ag-filter-toolpanel-group-wrapper')
    .forEach((element, groupIndex) => {
      const groupDefinition = filteredGroupedDefs[groupIndex];
      if (not(groupDefinition)) {
        return;
      }

      if (isColDef(groupDefinition)) {
        element.setAttribute('data-testid', groupDefinition.filterParams?.['data-testid']);
      } else {
        element
          .querySelectorAll('.ag-filter-toolpanel-instance')
          .forEach((childElement, childIndex) => {
            const childDefinition = groupDefinition.children[childIndex];

            if (
              childDefinition &&
              isColDef(childDefinition) &&
              childDefinition.filterParams?.['data-testid']
            ) {
              childElement.setAttribute('data-testid', childDefinition.filterParams['data-testid']);
            }
          });
      }
    });
};
