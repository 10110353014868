import {DataStatus} from 'platform/components';

import {useGetCustomerNoteQuery, usePutCustomerNoteMutation} from '@omnetic-dms/api';
import {handleApiError, NoteCard as NoteCardComponent, NoteCardForm} from '@omnetic-dms/shared';

import {TestIdProps, useIsLoadingWithInitialError} from 'shared';

interface NoteCardProps extends TestIdProps {
  customerId: string;
}

export function NoteCard(props: NoteCardProps) {
  const {
    data,
    isLoading: isNoteLoading,
    isError,
  } = useGetCustomerNoteQuery({customerId: props.customerId});
  const [saveCustomerNote, {isLoading: isSaveLoading}] = usePutCustomerNoteMutation();

  const isLoading = useIsLoadingWithInitialError(isNoteLoading, isError);

  const onChange = (values?: NoteCardForm) =>
    saveCustomerNote({
      customerId: props.customerId,
      noteRequestBody: {
        note: values?.note ?? '',
      },
    })
      .unwrap()
      .catch(handleApiError);
  return (
    <DataStatus isLoading={isLoading} minHeight={52}>
      <NoteCardComponent
        authorId={data?.authorId}
        authorName={data?.authorName}
        note={data?.note ?? null}
        datetime={data?.datetime}
        isLoading={isSaveLoading}
        onChange={onChange}
        data-testid={props['data-testid']}
      />
    </DataStatus>
  );
}
