import {DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {FC, PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useSelector} from 'react-redux';

import {isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {useGetVehicleQuery, useGetVehicleV2Query} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  getCebiaAutotraceAndReport,
  getCebiaEquipmentCredentials,
  getCustomTenantCatalogue,
  getTopMakes,
  getVehicleMakeModels,
  loadHighlightsList,
  loadShortcomingsList,
  loadVehicleDetailsData,
  selectCarAudit,
  useThunkDispatch,
  useVehicleTitle,
} from '@omnetic-dms/teas';

import {useDebouncedCallback, useRequiredParams} from 'shared';

import {VehicleDetailContent} from './components/VehicleDetailContent';

const VehicleDetail: FC<PropsWithChildren<any>> = () => {
  const {id} = useRequiredParams();

  const {data: vehicle} = useGetVehicleV2Query({vehicleId: id});
  const vehicleTitle = useVehicleTitle(vehicle);

  return (
    <>
      <Helmet title={i18n.t('page.vehicle.title', {title: vehicleTitle})} />
      <VehicleDetailContent vehicleId={id} />
    </>
  );
};

export const VehicleDetails: FC<PropsWithChildren<any>> = (props) => {
  const dispatch = useThunkDispatch();

  const {id: vehicleId} = useRequiredParams();
  const {data: vehicle} = useGetVehicleQuery({vehicleId});

  const [isAuditLoading, setIsAuditLoading] = useState(false);

  const {loading} = useSelector(selectCarAudit);

  // Sometimes the loading states changes from false to true (probably because request was not fired yet and initial value is false, not true), which results in double loading
  // so we introduced a debounce to mitigate this random changes
  const checkIfAuditIsLoading = useCallback((states: boolean[]) => {
    const isLoading = states.some(isTrue);
    if (!isLoading) {
      setIsAuditLoading(false);
    }
  }, []);

  const debouncedCheckIfAuditIsLoading = useDebouncedCallback(checkIfAuditIsLoading, 150);

  useEffect(() => {
    const auditLoadingStates = [
      loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
      loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
      loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
      loading['sales/vehicleDetail/loadPremiumFeatures'],
      loading['vehicleCatalogue/getCustomTenantCatalogue'],
    ];
    debouncedCheckIfAuditIsLoading(auditLoadingStates);
  }, [debouncedCheckIfAuditIsLoading, loading]);

  useEffect(() => {
    if (isNotNil(vehicleId) && isNotNil(vehicle) && !isAuditLoading) {
      loadVehicleDetailsData(vehicleId, dispatch).then(async () => {
        await dispatch(
          getVehicleMakeModels.action({
            vehicleType: vehicle.type!,
            make: vehicle.make,
          })
        );
        await Promise.all([
          dispatch(getTopMakes.action({})),
          dispatch(getCustomTenantCatalogue.action({})),
          dispatch(loadHighlightsList()),
          dispatch(loadShortcomingsList()),
          dispatch(getCebiaEquipmentCredentials()),
          dispatch(getCebiaAutotraceAndReport()),
        ]);
      });
    }
  }, [vehicle, vehicleId]);

  return (
    <VStack width="100%">
      <DataStatus isLoading={isAuditLoading} minHeight={100}>
        <VehicleDetail {...props} />
      </DataStatus>
    </VStack>
  );
};
