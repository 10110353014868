import {ButtonGroup, closeCurrentDialog, DataStatus} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {find, isNil, isNotNil} from 'ramda';

import {
  FileResponseBody,
  TemplateListItemResponseBody,
  useCreateAgreementOnFutureContractMutation,
  useGetSaleTemplatesQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {convertStringToCamelCase, Nullish} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {DocumentTemplateBox} from '../../DocumentTemplateBox/DocumentTemplateBox';

interface FutureAgreementDialogProps {
  businessCaseId: string;
  customerId?: string | Nullish;
  onDocumentCreation: (document: FileResponseBody) => void;
}

const agreementDocumentType = 'agreement-on-future';

export function FutureAgreementDialog(props: FutureAgreementDialogProps) {
  const [isDocumentSelected, setIsDocumentSelected] = useState(true);
  const [documentNote, setDocumentNote] = useState<string | null>();

  const [pickedDocumentTemplate, setPickedDocumentTemplate] =
    useState<TemplateListItemResponseBody | null>(null);

  const [createFutureAgreement, {isLoading: isCreateFutureAgreementLoading}] =
    useCreateAgreementOnFutureContractMutation();

  const {data, isLoading, isError} = useGetSaleTemplatesQuery();

  const agreementDocument = find((document) => document.type === agreementDocumentType, data ?? []);
  const defaultDocumentTemplate =
    find((template) => template.primary, agreementDocument?.templates ?? []) ??
    agreementDocument?.templates?.[0];

  const handleSubmit = () => {
    if (!isDocumentSelected && isNotNil(defaultDocumentTemplate)) {
      return;
    }

    return createFutureAgreement({
      agreementOnFutureRequestBody: {
        templateId: pickedDocumentTemplate?.id ?? defaultDocumentTemplate!.id,
        businessCaseId: props.businessCaseId,
        note: documentNote ?? null,
      },
    })
      .unwrap()
      .then(props.onDocumentCreation)
      .catch(handleApiError);
  };

  return (
    <DataStatus isEmpty={isNil(agreementDocument)} isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        <Text size="small" color="secondary">
          {i18n.t('entity.vehicle.labels.futureAgreementText')}
        </Text>
        <DocumentTemplateBox
          type={agreementDocument?.type}
          title={i18n.t(
            `entity.document.labels.${convertStringToCamelCase(agreementDocumentType)}`
          )}
          templates={agreementDocument?.templates}
          selectedTemplate={pickedDocumentTemplate ?? defaultDocumentTemplate}
          isSelected={isDocumentSelected || isNotNil(defaultDocumentTemplate)}
          onSelect={setIsDocumentSelected}
          onTemplateChange={setPickedDocumentTemplate}
          onNoteChange={setDocumentNote}
          isWithNote
        />
        <ButtonGroup
          align="right"
          buttons={[
            {
              title: i18n.t('general.actions.cancel'),
              onClick: closeCurrentDialog,
              type: 'button',
              variant: 'secondary',
            },
            {
              title: i18n.t('general.actions.confirm'),
              onClick: handleSubmit,
              type: 'button',
              isLoading: isCreateFutureAgreementLoading,
            },
          ]}
        />
      </VStack>
    </DataStatus>
  );
}
