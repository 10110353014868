import {Label, Alert} from 'platform/components';
import {Box, Center, HStack, Show, Space, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {selectAuditUpdateAt, selectAuditUser} from '../../../store/carAudit/selectors';
import {AssignmentIdentity} from '../../../types/AssignmentIdentity';
import {useConditionContext} from '../hooks/useConditionContext';
import {useHeaderPopoverData} from '../hooks/useHeaderPopoverData';
import {Assignment} from './Assignment';
import {OverallEvaluations} from './OverallEvaluations';
import {PopoverHeader} from './PopoverHeader';

interface AuditHeaderProps extends TestIdProps {
  isInspection?: boolean;
  customHeaderContent?: ReactNode;
  isInNotEditableState: boolean;
}

export const AuditHeader = (props: AuditHeaderProps) => {
  const {inspectionId} = useParams();
  const headerPopoverData = useHeaderPopoverData(inspectionId);
  const popoverHeaderData = headerPopoverData?.popoverHeaderData;
  const formatDateTime = useDateTimeFormatter();

  const user = useSelector(selectAuditUser);
  const updateAt = useSelector(selectAuditUpdateAt);
  const {isCreateVehicle} = useConditionContext();

  const isInspection = props.isInspection || Boolean(inspectionId);

  const lastUpdate = updateAt ? formatDateTime('dateTimeLong', parseDate(updateAt)) : undefined;

  return (
    <HStack spacing={3}>
      <OverallEvaluations data-testid={props['data-testid']} />
      <Show when={isInspection}>
        <VStack>
          <Label>{i18n.t('general.labels.author')}</Label>
          <Center height="100%">
            <Text size="xSmall" data-testid={suffixTestId('user', props)}>
              {user || EMPTY_PLACEHOLDER}
            </Text>
          </Center>
        </VStack>
        <VStack>
          <Label>{i18n.t('general.labels.assignee')}</Label>
          <Center height="100%">
            <Assignment
              data-testid={props['data-testid']}
              type={AssignmentIdentity.type.VEHICLE_AUDIT}
              external={null}
            />
          </Center>
        </VStack>
      </Show>
      <Show when={!isCreateVehicle}>
        <VStack>
          <Label>{i18n.t('general.labels.lastUpdate')}</Label>
          <Center height="100%">
            <Text data-testid={suffixTestId('lastUpdate', props)} size="xSmall">
              {lastUpdate || EMPTY_PLACEHOLDER}
            </Text>

            <Show when={isInspection && popoverHeaderData}>
              <PopoverHeader
                icon="action/restore"
                title={i18n.t('page.inspection.changeHistory')}
                data={popoverHeaderData!}
                refetchData={() => headerPopoverData?.refetchData()}
              />
            </Show>
          </Center>
        </VStack>
      </Show>
      <Space fillAvailable />
      <Show when={props.isInNotEditableState}>
        <Box>
          <Alert
            data-testid={testIds.businessCase.buying('no_edit_state')}
            type="inline"
            variant="warning"
            title={i18n.t('general.labels.no_edit_state')}
          />
        </Box>
      </Show>
      {props.customHeaderContent}
    </HStack>
  );
};
