import {isArray} from 'ramda-adjunct';

import {Choice} from '../../Choice/Choice';
import {MultiChoice} from '../../MultiChoice/MultiChoice';
import {useFieldProps} from '../hooks/useFieldProps';
import {useOptionsManager} from '../hooks/useOptionsManager';
import {SelectRendererResponseBody} from '../types/modelSchema';

export function SelectRenderer(props: SelectRendererResponseBody) {
  const {defaultOptions, url, dependentOptions, tooltip} = props;
  const {onChange, onBlur, onFocus, value, name, isRequired, error} = useFieldProps(props);
  const options = useOptionsManager(defaultOptions, dependentOptions, url);

  return props.isMulti ? (
    <MultiChoice
      label={props.label}
      placeholder={props.placeholder}
      isNotClearable={!props.isClearable}
      menuInPortal
      isRequired={isRequired}
      errorMessage={error?.message}
      name={name}
      value={isArray(value) ? value : [value]}
      options={options}
      onChange={onChange}
      tooltip={tooltip}
      onFocus={onFocus}
      onBlur={onBlur}
      data-testid={props.name}
    />
  ) : (
    <Choice
      label={props.label}
      placeholder={props.placeholder}
      isNotClearable={!props.isClearable}
      menuInPortal
      isRequired={isRequired}
      errorMessage={error?.message}
      name={name}
      tooltip={tooltip}
      value={isArray(value) ? value[0] : value}
      options={options}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      data-testid={props.name}
    />
  );
}
