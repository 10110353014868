import {assoc, insert} from 'ramda';

import {UnifiedAlbumImage} from '../types/UnifiedAlbumImage';

export const mergeAlbumAndPromoImages = (
  albumImages: UnifiedAlbumImage[],
  promoPhotos: UnifiedAlbumImage[]
) =>
  promoPhotos.reduce(
    (acc, promoPhoto) =>
      insert(promoPhoto.position! - 1, assoc('isPromoPhoto', true, promoPhoto), acc),
    albumImages
  );
