import {PayloadAction} from '@reduxjs/toolkit';
import {all, put, takeLeading} from 'typed-redux-saga';

import {PublishingService} from '../../services/PublishingService';
import {callApiSaga} from '../../utils/api';
import {fetchAdSettingsRequest, fetchAdSettingsSuccess} from './reducer';

function* fetchAdSettingsGen({payload: {branchId}}: PayloadAction<{branchId?: string}>): Generator {
  try {
    const adPlatformData = yield* callApiSaga(PublishingService.getList, {branchId});
    yield* put(fetchAdSettingsSuccess(adPlatformData || []));
  } catch (exception: any) {
    yield* put(fetchAdSettingsSuccess([]));
  }
}

export function* advertisementSettingsSaga(): Generator {
  yield* all([takeLeading(fetchAdSettingsRequest, fetchAdSettingsGen)]);
}
