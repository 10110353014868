import {testIds} from '@omnetic-dms/routes';

import {DataGrid} from 'features/datagrid';

import {useBusinessCasesActionCallback} from '../../../hooks/useBusinessCasesActionCallback';

export function BusinessCaseListOS() {
  const [actionCallback] = useBusinessCasesActionCallback();

  return (
    <DataGrid
      // It is not prepared on BE more: T20-50957
      gridCode="business-case-opensearch"
      actionCallback={actionCallback}
      data-testid={testIds.businessCase.home('bussinessCaseListOS')}
    />
  );
}
