import {HStack, Icon} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {openInNew, SourcingVehicleType} from '@omnetic-dms/teas';

export const createAdUrlButton = (vehicle?: SourcingVehicleType) => {
  let server = (vehicle?.server || '').replace('_', '.');
  server = server[0]?.toUpperCase() + server.slice(1, server.length);

  const url = vehicle?.url?.full ?? vehicle?.proxyUrl?.full;

  if (url) {
    return {
      action: () => (url ? openInNew(url) : void 0),
      label: (
        <HStack spacing={2} align="center">
          <Icon value="action/open_in_new" size={4} />
          {i18n.t('general.actions.openResultOn')}
          &nbsp;{server}
        </HStack>
      ),
    };
  }

  return {};
};
