import {createContext} from 'shared';

import {NotificationsContextProps} from '../Notification.types';

const [NotificationContextProvider, useNotificationContext] =
  createContext<NotificationsContextProps>({
    strict: false,
    name: 'NotificationContext',
  });

export {NotificationContextProvider, useNotificationContext};
