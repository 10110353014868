import {css} from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {ForwardedRef, forwardRef, ReactNode, UIEventHandler} from 'react';

import {always} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {CSSDimension} from '../../types/CSSDimension';
import {Integer} from '../../types/Integer';
import {getCssSize} from '../../utils/getCssSize';

export interface ScrollProps extends TestIdProps {
  auto?: true;
  always?: true;
  never?: true;
  children?: ReactNode;
  width?: CSSDimension | Integer;
  height?: CSSDimension | Integer;
  maxHeight?: CSSDimension | Integer;
  maxWidth?: CSSDimension | Integer;
  minHeight?: CSSDimension | Integer;
  minWidth?: CSSDimension | Integer;
  flex?: number;
  hasHiddenScrollBars?: true;
  onScroll?: UIEventHandler<HTMLDivElement>;
}

export const Scroll = forwardRef((props: ScrollProps, ref: ForwardedRef<HTMLDivElement>) => {
  const overflow = match([props.auto, props.always, props.never])
    .with([true, Pattern.any, Pattern.any], always('auto'))
    .with([Pattern.any, true, Pattern.any], always('scroll'))
    .with([Pattern.any, Pattern.any, true], always('hidden'))
    .otherwise(always('initial'));

  return (
    <div
      ref={ref}
      onScroll={props.onScroll}
      css={css`
        width: ${getCssSize(props.width)};
        height: ${getCssSize(props.height)};
        max-height: ${getCssSize(props.maxHeight)};
        min-height: ${getCssSize(props.minHeight)};
        max-width: ${getCssSize(props.maxWidth)};
        min-width: ${getCssSize(props.minWidth)};
        flex: ${props.flex ?? 'initial'};
        overflow: ${overflow};
        ${props.hasHiddenScrollBars ? `::-webkit-scrollbar { display: none; }` : null}
      `}
      data-testid={suffixTestId('scroll', props)}
    >
      {props.children}
    </div>
  );
});
