import {isFeatureEnabled} from 'feature-flags';
import {Parameter} from 'platform/components';
import {useDateTimeFormatter, useFormatNumber} from 'platform/locale';

import {
  FullVehicleResponseBody,
  GetMetadaServiceCaseApiResponse,
  GetServiceCheckInApiResponse,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {EMPTY_PLACEHOLDER, useGetVehicleHeaderV2} from '@omnetic-dms/shared';

import {Nullish, buildArray, parseDate} from 'shared';

import {formatFuelTank} from '../../../utils/formatFuelTank';

export function useServiceCaseVehicleHeaderParameters(
  serviceCase: GetMetadaServiceCaseApiResponse | Nullish,
  vehicle: FullVehicleResponseBody | Nullish,
  checkin: GetServiceCheckInApiResponse | Nullish
) {
  const formatDateTime = useDateTimeFormatter();
  const formatNumber = useFormatNumber();

  const formatMileage = (mileage?: number | null) => {
    const formatedMileage = formatNumber(mileage);

    if (!formatedMileage) {
      return EMPTY_PLACEHOLDER;
    }

    return `${formatedMileage} ${i18n.t('general.metric.km')}`;
  };

  const newVehicleHeader = useGetVehicleHeaderV2({
    serviceCaseNumber: serviceCase?.number,
    publicId: vehicle?.publicId,
    vin: vehicle?.vehicle.vin,
    registrationPlate: vehicle?.vehicle.registrationPlate,
    firstRegistrationOn: vehicle?.vehicle.firstRegistrationOn,
    warrantyDate: vehicle?.vehicle.warrantyDate,
    isNotInService: checkin?.isVehicleNotInService,
    isVehicleDetail: false,
    mileage: formatMileage(checkin?.mileage),
    fuelTank: formatFuelTank(checkin?.fuelTank),
  });

  if (isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)) {
    return newVehicleHeader;
  }

  return buildArray<Parameter>()
    .add(serviceCase?.number)
    .when(vehicle?.vin, vehicle?.vin)
    .when(vehicle?.vehicle.registrationPlate, vehicle?.vehicle.registrationPlate)
    .when(vehicle?.vehicle.firstRegistrationOn, () =>
      formatDateTime('dateShort', parseDate(vehicle?.vehicle.firstRegistrationOn ?? ''))
    )
    .when(vehicle?.modelSpecification?.warrantyDate, () =>
      formatDateTime('dateShort', parseDate(vehicle?.modelSpecification?.warrantyDate ?? ''))
    )
    .when(
      vehicle?.modelSpecification?.warrantyMileage,
      formatNumber(vehicle?.modelSpecification?.warrantyMileage ?? 0)
    );
}
