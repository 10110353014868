import {forwardRef, Ref, useCallback, useImperativeHandle, useState} from 'react';

import {Box, useMultiStyleConfig} from '@chakra-ui/react';

import {ICellRenderer, ICellRendererParams} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {DefaultRowSelectCheckboxRenderer} from './DefaultRowSelectCheckboxRenderer';

export interface RowSelectHeaderRendererProps extends ICellRendererParams {
  gridProps: DataGridProps;
}

export const RowSelectHeaderRenderer = forwardRef<ICellRenderer, RowSelectHeaderRendererProps>(
  RowSelectHeaderRendererComponent
);
export function RowSelectHeaderRendererComponent(
  {api, context, gridProps}: RowSelectHeaderRendererProps,
  ref: Ref<ICellRenderer>
) {
  const {rowSelectRenderer: theme} = useMultiStyleConfig('DataGrid', context.gridProps);
  const getSelectedStateFromNode = () => {
    const selectionArray: Array<boolean | undefined> = [];
    api.forEachNode((node) => selectionArray.push(node.isSelected()));
    if (selectionArray.every((i) => i === true)) {
      return true;
    }
    if (selectionArray.every((i) => i === false)) {
      return false;
    }
    return undefined;
  };

  const [selected, setSelected] = useState<boolean | undefined>(getSelectedStateFromNode);

  const change = useCallback(() => {
    const newlySelected = !selected;
    setSelected(newlySelected);
    if (newlySelected) {
      api.forEachNode((node) => node.setSelected(true));
    } else {
      api.deselectAll();
    }
    api.refreshCells({
      suppressFlash: true,
      columns: ['eag-col-select'],
    });
  }, [api, selected]);

  useImperativeHandle(ref, () => ({
    refresh: () => {
      const newlySelected = getSelectedStateFromNode();
      if (newlySelected !== selected) {
        setSelected(newlySelected);
      }
      return true;
    },
  }));

  return (
    <Box __css={theme}>
      <DefaultRowSelectCheckboxRenderer selected={selected} changeHandler={change} />
    </Box>
  );
}
