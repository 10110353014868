import {useTranslationContext, Button} from 'platform/components';

import {suffixTestId} from 'shared';

import {RESET_FILTERS} from '../const/events';
import {ResetFilterButtonRendererProps} from '../types/ResetFilterButtonRenderer';

export function DefaultResetFilterButtonRenderer(props: ResetFilterButtonRendererProps) {
  const t = useTranslationContext();

  const handleOnClick = () => {
    window.dispatchEvent(new CustomEvent(RESET_FILTERS));
    props.resetFilter();
  };

  return (
    <div id="datagrid_reset">
      <Button
        variant="ghost"
        leftIcon="content/clear"
        onClick={handleOnClick}
        title={t('page.datagrid.button.resetFilters')}
        data-testid={suffixTestId('resetFilters', props)}
      />
    </div>
  );
}
