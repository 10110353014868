import {match} from 'ts-pattern';

import {always} from 'ramda';

import i18n from '@omnetic-dms/i18n';

export const getLabel = (type: string) =>
  match(type)
    .with('additional_photos', always(i18n.t('entity.photo.labels.photos')))
    .with('additional_comment', 'generalComment', always(i18n.t('general.labels.note')))
    .with('toggle', always(i18n.t('page.vehicleSettings.labels.state')))
    .with('photo', always(i18n.t('entity.photo.labels.photo')))
    .otherwise(always(type));
