import {DeepPath, DeepPathArray} from '@hookform/strictly-typed';

import {ReactElement} from 'react';
import {Control, Controller, UseFormReturn} from 'react-hook-form';

import {FieldProps} from '../FieldProps';

export type UseHiddenFieldsDefaultValue<TValues> = string extends keyof TValues
  ? TValues[keyof TValues & string]
  : string | boolean;
/**
 * @deprecated - use platform instead
 */
export function useHiddenFields<
  TValues extends Record<string, unknown>,
  TPath = DeepPath<TValues, DeepPathArray<TValues, keyof TValues>>,
>({
  control,
}: UseFormReturn<TValues>): (
  fieldProps: FieldProps<TValues, TPath> & {
    defaultValue?: UseHiddenFieldsDefaultValue<TValues>;
  }
) => ReactElement {
  return ({name, defaultValue}) => {
    const stringifiedName = Array.isArray(name) ? name.join('.') : String(name);

    return (
      <Controller
        name={stringifiedName}
        control={control as Control<Record<string, any>>}
        defaultValue={defaultValue as any}
        render={(props) => <input {...props.field} value={`${props.field.value}`} type="hidden" />}
      />
    );
  };
}
