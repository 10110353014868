import Axios, {CancelTokenSource} from 'axios';
import {all, put, takeLatest} from 'typed-redux-saga';

import {VehicleService} from '../../services/VehicleService';
import {callApiSaga} from '../../utils/api';
import {
  LOAD_HIGHLIGHTS_LIST_REQUEST,
  LOAD_SHORTCOMINGS_LIST_REQUEST,
  LoadHighlightsListRequestAction,
  LoadShortcomingsListRequestAction,
  loadShortcomingsListAction,
  loadShortcomingsListErrorAction,
  loadShortcomingsListWithData,
  loadHighlightsListAction,
  loadHighlightsListErrorAction,
  loadHighlightsListWithData,
} from './reducer';

const PAGE_SIZE = 100;

let cancelGetHighlightsListToken: null | CancelTokenSource = null;
let cancelGetShortcomingsListToken: null | CancelTokenSource = null;

function* loadHighlightsListGen({page = 0}: LoadHighlightsListRequestAction): Generator {
  try {
    if (cancelGetHighlightsListToken?.token) {
      cancelGetHighlightsListToken.cancel();
    }

    cancelGetHighlightsListToken = Axios.CancelToken.source();

    const data = yield* callApiSaga(VehicleService.listHighlightsForTenant, {
      size: PAGE_SIZE,
      offset: page * PAGE_SIZE,
    });

    if (data?.length === PAGE_SIZE) {
      yield put(loadHighlightsListWithData(data, page + 1));
    } else {
      yield put(loadHighlightsListAction(data));
    }
  } catch (error: any) {
    yield put(loadHighlightsListErrorAction());
  }
}

function* loadShortcomingsListGen({
  data = [],
  page = 0,
}: LoadShortcomingsListRequestAction): Generator {
  try {
    if (cancelGetShortcomingsListToken?.token) {
      cancelGetShortcomingsListToken.cancel();
    }

    cancelGetShortcomingsListToken = Axios.CancelToken.source();

    const response = yield* callApiSaga(VehicleService.listShortcomingsForTenant, {
      size: PAGE_SIZE,
      offset: page * PAGE_SIZE,
    });

    const _data = data.concat(response);

    if (response?.length === PAGE_SIZE) {
      yield put(loadShortcomingsListWithData(_data, page + 1));
    } else {
      yield put(loadShortcomingsListAction(_data));
    }
  } catch (error: any) {
    yield put(loadShortcomingsListErrorAction());
  }
}

export function* listsSaga() {
  yield all([
    takeLatest(LOAD_HIGHLIGHTS_LIST_REQUEST, loadHighlightsListGen),
    takeLatest(LOAD_SHORTCOMINGS_LIST_REQUEST, loadShortcomingsListGen),
  ]);
}
