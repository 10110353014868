import {ReactNode} from 'react';

import {defaultHttpClient, HttpClient} from '../utils/httpClient';
import {Provider} from './DynamicUiContext';

type DynamicUiProviderProps = {children: ReactNode; httpClient?: HttpClient};

export function DynamicUiProvider(props: DynamicUiProviderProps) {
  return (
    <Provider value={{httpClient: props.httpClient || defaultHttpClient}}>
      {props.children}
    </Provider>
  );
}
