import {formatISO} from 'date-fns';

import {Nullish} from 'shared';

export function formatIsoDate(date: string | number | Date | Nullish) {
  if (!date) {
    return null;
  }

  return formatISO(date, {representation: 'date'});
}
