import {showNotification, useTranslationContext} from 'platform/components';
import {
  getSize,
  getValueByDevice,
  Image,
  Show,
  useDevice,
  useIsInViewport,
  useWindowDimensions,
} from 'platform/foundation';
import {css} from 'styled-components';

import {useEffect, useRef} from 'react';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {buildArray, Nullish, RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {platformLightboxQueryParams} from '../consts/platformLightboxQueryParams';
import {spaceBetweenSlides} from '../consts/spaceBetweenSlides';
import {LightboxAction} from '../types/LightboxAction';
import {LightboxImage} from '../types/LightboxImage';
import {downloadImageFile} from '../utils/files/downloadImageFile';
import {Actions} from './Actions';
import {SlideshowActionsWrapper} from './SlideshowActionsWrapper';

interface SlideshowImageProps extends RequiredTestIdProps {
  activeIndex: number;
  index: number;
  image: LightboxImage;
  onSlideshowItemInView: () => void;
  onDetailOpen: () => void;
}

export function SlideshowImage(props: SlideshowImageProps) {
  const device = useDevice();
  const windowDimensions = useWindowDimensions();
  const t = useTranslationContext();

  const boxRef = useRef<HTMLDivElement>(null);
  const isInView = useIsInViewport(boxRef, 0.85);

  const [isHovered, onMouseEnter, onMouseLeave] = useBoolean();
  const [isDownloading, setIsDownloading, setIsNotDownloading] = useBoolean();

  const isBase64Image = props.image.url.startsWith('data:');

  /**
   * @description
   * Whenever an image item is inView, we trigger a callback to level up,
   * which sets the pointer to this element for comparison.
   * If the pointer is not equal to the activeIndex from controls,
   * then scrollIntoView is triggered to this element
   */
  useEffect(() => {
    if (isInView && device !== 'mobile' && device !== 'tablet') {
      props.onSlideshowItemInView();
    }
  }, [device, isInView, props]);

  const actions = buildArray<LightboxAction>(props.image.actions ?? [])
    .whenNot(isBase64Image, {
      icon: 'social/share',
      onClick: async () => {
        const url =
          window.location.href +
          `&${platformLightboxQueryParams.LIGHTBOX_DETAIL_INDEX}=${props.activeIndex}`;
        if (navigator.share) {
          try {
            await navigator.share({
              title: document.title,
              url,
            });
          } catch (err: unknown) {
            if (!(err instanceof Error && err.toString().includes('AbortError'))) {
              showNotification.error(t('platform.lightbox.share-error'));
            }
          }
        } else {
          await navigator.clipboard.writeText(url);
          showNotification.info(t('platform.lightbox.copy-to-clipboard'));
        }
      },
      id: 'shareBtn',
    })
    .add({
      icon: 'content/save_alt',
      onClick: async () => {
        setIsDownloading();
        await downloadImageFile(props.image.url).catch(() => {
          showNotification.error(t('platform.lightbox.downloadError'));
        });
        setIsNotDownloading();
      },
      id: 'downloadBtn',
      isLoading: isDownloading,
    });

  const handleMouseEnter = () => {
    if (isNilOrEmpty(actions) || device === 'mobile' || device === 'tablet') {
      return;
    }
    onMouseEnter();
  };

  const handleMouseLeave = () => {
    if (isNilOrEmpty(actions) || device === 'mobile' || device === 'tablet') {
      return;
    }
    onMouseLeave();
  };

  return (
    <div
      ref={boxRef}
      data-testid={suffixTestId('slideshowItem', props)}
      css={css`
        position: relative;
        height: ${getValueByDevice(
          device,
          'auto',
          'auto',
          `calc(${windowDimensions.height}px - ${getSize(spaceBetweenSlides)} - ${getSize(spaceBetweenSlides)})` // T20-48421 - Slideshow inserts a space between slides, we need to calculate the height of the window without these spaces at the top and bottom
        )};
        width: 100%;
        aspect-ratio: ${getValueByDevice(device, 4 / 3, 4 / 3, undefined)};
        background-color: ${({theme}) => theme.colors.palettes.neutral['800']['100']};
        border-radius: ${({theme}) => theme.radii.small};
      `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Show when={isNotNilOrEmpty(actions)}>
        <SlideshowActionsWrapper>
          <Actions
            data-testid={suffixTestId('slideshowItem', props)}
            disablePosition
            zIndex="LIGHTBOX_ACTIONS"
            actions={[actions!]}
            isHidden={!isHovered}
          />
        </SlideshowActionsWrapper>
      </Show>

      <div
        css={css`
          cursor: pointer;
          width: 100%;
          height: 100%;
          flex-direction: column;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        `}
        onClick={props.onDetailOpen}
      >
        <Image
          data-testid={props['data-testid']}
          src={getResizeUrl(props.image.resizeUrl, windowDimensions.height) ?? props.image.url}
        />
      </div>
    </div>
  );
}

function getResizeUrl(src: string | Nullish, height: number) {
  if (isNil(src)) {
    return null;
  }
  return `${src}&height=${height}`;
}
