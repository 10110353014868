import {createSearchParams} from 'react-router-dom';

import {getFormattedSourceSearchParams} from './getFormattedSourceSearchParams';
import {getSearchParamsByGridCode} from './getSearchParamsByGridCode';
import {SOURCE_DATA_GRID} from './getSourceSearchParams';

export const setSourceSearchParamsByGridCode = (
  gridCode: string,
  rowIndex: string,
  useSourceSearchParams = false
): string => {
  const searchParamsByGridCode2 = useSourceSearchParams
    ? getFormattedSourceSearchParams()
    : getSearchParamsByGridCode(gridCode);

  const newSearchParams = {
    [SOURCE_DATA_GRID]: [`${gridCode},${searchParamsByGridCode2?.queryId},${rowIndex}`],
  };

  return createSearchParams(newSearchParams).toString();
};
