import {VehicleCatalogueEnumOption} from '../types/VehicleCatalogueEnumOption';

/**
 * Function will return the highest parent of the given key
 * @param key key of feature, we are looking it's the highest parent
 * @param features list of all features from catalogue
 */
export const findTopMostItem = (
  key: string,
  features?: VehicleCatalogueEnumOption[]
): VehicleCatalogueEnumOption | undefined => {
  const findRecursive = (nodes: readonly VehicleCatalogueEnumOption[]): boolean =>
    nodes?.some((item) => item.value === key || (item.children && findRecursive(item.children)));

  return features?.find(
    (feat) => feat.value === key || (feat.children && findRecursive(feat.children))
  );
};
