import {$createAutoLinkNode} from '@lexical/link';
import {$createTextNode, TextNode} from 'lexical';
import {BeautifulMentionNode} from 'lexical-beautiful-mentions';

import {OptionType} from '../../types/FormControlProps';
import {getHighlightsFromText} from '../MentionsText/utils/getHighlightsFromText';
import {SuggestionInput} from '../SuggestionInput/SuggestionInput';
import {MentionsInputProps} from './types/MentionsInputProps';

export function MentionsInput<T extends OptionType>(props: MentionsInputProps<T>) {
  const transformStringToNodes = (value: string | null) => {
    if (!value) {
      return [];
    }
    return getHighlightsFromText(value).map((textHighlight) => {
      switch (textHighlight.type) {
        case 'email':
        case 'link':
          return $createAutoLinkNode(textHighlight.text || '').append(
            $createTextNode(textHighlight.text || '')
          );
        case 'user':
          return new BeautifulMentionNode('@', textHighlight.text || '');
        default:
          return new TextNode(textHighlight.text || '');
      }
    });
  };

  return (
    <SuggestionInput
      data-testid={props['data-testid']}
      transformStringToNodes={transformStringToNodes}
      prefix="@"
      {...props}
      options={
        props.options?.map(({value, label, ...option}) => ({
          value: label,
          id: value,
          ...option,
        })) || []
      }
    />
  );
}
