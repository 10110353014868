import {Box, Center, VStack} from 'platform/foundation';

import {PropsWithChildren} from 'react';

import {INVOICE_FORM_MAX_WIDTH} from '../constants';

export const InvoicePage = (props: PropsWithChildren) => (
  <Box backgroundColor="palettes.neutral.20.100" width="100%" minHeight="100%">
    <Center>
      <Box width={INVOICE_FORM_MAX_WIDTH} padding={4}>
        <VStack spacing={4}>{props.children}</VStack>
      </Box>
    </Center>
  </Box>
);
