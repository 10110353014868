import {forwardRef, Ref, useImperativeHandle, useState} from 'react';

import {IFilter, IFilterParams} from '../types/AgGridTypes';

type QuickFilterProps = IFilterParams & {
  gridCode: string;
};

export const QuickFilter = forwardRef<IFilter, QuickFilterProps>(QuickFilterComponent);
function QuickFilterComponent({filterChangedCallback}: QuickFilterProps, ref: Ref<IFilter>) {
  const [filterValue, setFilterValue] = useState<string>();

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return typeof filterValue === 'string';
    },

    doesFilterPass() {
      return true;
    },

    getModel() {
      return filterValue;
    },

    setModel(model: string | undefined) {
      setFilterValue(model);
      filterChangedCallback();
    },
  }));

  return null;
}
