import {FormatOptionLabelMeta} from 'react-select';

import {OptionTypeBase} from '../../../types/OptionTypeBase';

export function formatOptionFieldLabel<ValueType>(
  option: OptionTypeBase<ValueType>,
  {context}: FormatOptionLabelMeta<OptionTypeBase<ValueType>>
) {
  return context === 'value' ? option?.fieldLabel || option.label : option.label;
}
