import {useState, useCallback, useEffect} from 'react';

type DialogReturnType<Storage = unknown> = [
  boolean,
  (data?: Storage | null) => void,
  () => void,
  {isMounted: boolean; key: string; storage: Storage | null},
];

/**
 * @param isOpenByDefault
 * @param initialStorage
 * @returns {boolean} isOpen
 * @returns {Function} onOpen - you can also insert a storage parameter
 * @returns {Function} onClose
 * @returns {object} options { isMounted, key, storage }
 * isMounted - True if dialog was opened at least once. Use to defer mounting of the dialog.
 * key - Generates a new key every time dialog starts to open. Use the key to reset component state/hooks/validation.
 * storage - Custom data provided via openDialog('someCustomData'). May be used to set ID of entity being edited in dialog.
 *
 * @example
 * const [isOpen, onOpen, onClose, { key, storage }] = useDialog<string>()
 *
 * <Button onClick={() => onOpen('someVehicleId-1234')} />
 * <VehicleDialog key={key} isOpen={isOpen} vehicleId={storage} />
 */

export function useDialog<Storage = unknown>(
  isOpenByDefault = false,
  initialStorage = null
): DialogReturnType<Storage> {
  const [isOpen, setOpen] = useState<boolean>(isOpenByDefault);
  const [isMounted, setMounted] = useState<boolean>(isOpenByDefault);
  const [storage, setStorage] = useState<Storage | null>(initialStorage);
  const [id, setId] = useState<number>(() => getDialogId());

  const onOpen = useCallback(
    (dataToStorage?: Storage | null) => {
      setStorage(dataToStorage ?? null);
      setOpen(true);
    },
    [setOpen, setStorage]
  );
  const onClose = useCallback(() => setOpen(false), [setOpen]);

  /**
   * The key dependency is safely omitted
   * The code should run only when isOpen changes
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void (isOpen && setId(getDialogId())), [isOpen]);
  useEffect(() => void (isOpen && setMounted(true)), [isOpen]);

  const key = `useDialog-${id}`;

  return [isOpen, onOpen, onClose, {isMounted, key, storage}];
}

function* dialogIdGenerator(): Generator<number, number, number> {
  let id = 0;
  while (true) {
    yield ++id;
  }
}

const dialogIdIterator = dialogIdGenerator();
const getDialogId = () => dialogIdIterator.next().value;
