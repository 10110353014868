import {Button} from 'platform/components';
import {Box, Show} from 'platform/foundation';

import {equals, isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {SourcingFilterPresetsControl} from '../../../hooks/useSourcingFilterPresets';
import {hasSomeFilters} from '../../../utils/hasSomeFilters';
import {useFilters} from '../../FiltersContext/FiltersContext';

interface FilterHeaderButtonsProps extends TestIdProps {
  filterPresetsControl: SourcingFilterPresetsControl;
  showSaveButton?: boolean;
}

export function FilterHeaderButtons(props: FilterHeaderButtonsProps) {
  const {filters, onChange} = useFilters();

  const handleResetFilterPreset = () => {
    const selectedFilterPreset = props.filterPresetsControl?.selectedFilterPreset;
    if (!selectedFilterPreset) {
      return;
    }

    onChange(selectedFilterPreset.filters);
  };

  const isFilterPresetSelected = isNotNil(props.filterPresetsControl?.selectedFilterPreset);
  const isFilterOutdated =
    isNotNil(props.filterPresetsControl?.selectedFilterPreset) &&
    !equals(filters, props.filterPresetsControl.selectedFilterPreset.filters);
  const shouldShowSaveButton =
    props.showSaveButton &&
    hasSomeFilters(filters) &&
    (!isFilterPresetSelected || isFilterOutdated);

  return (
    <>
      <Show when={isFilterOutdated}>
        <Box paddingLeft={2}>
          <Button
            variant="link"
            leftIcon="action/cached"
            onClick={handleResetFilterPreset}
            size="small"
            title={i18n.t('general.actions.setToDefault')}
            data-testid={suffixTestId('setToDefault', props)}
          />
        </Box>
      </Show>
      <Show when={shouldShowSaveButton}>
        <Box paddingLeft={2}>
          <Button
            variant="link"
            leftIcon="content/save"
            onClick={props.filterPresetsControl.onCreateFilterPreset}
            size="small"
            title={i18n.t('entity.sourcing.filterPresets.actions.saveFilter')}
            data-testid={suffixTestId('saveFilter', props)}
          />
        </Box>
      </Show>
    </>
  );
}
