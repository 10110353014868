import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {AuditParamType} from '../types/AuditParamType';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {getFormFieldName} from './getFormFieldName';

export type RequiredParams = {
  fields: Record<string, string[]>;
  assets: Record<string, string[]>;
};

const getRequiredParamsFromParamDefinitions = (
  params: RequiredParams,
  paramDefinitions: AuditParamDefinition[],
  categoryIdsTree: Record<string, string>,
  categoryId: string
): RequiredParams => {
  paramDefinitions.forEach(({id, type, mandatory}) => {
    if (!mandatory) {
      return;
    }

    const paramName = getFormFieldName(
      type === AuditParamType.PHOTO_FULLWIDTH ? categoryIdsTree[0] : categoryId,
      id
    );
    const categoryIds = Object.values(categoryIdsTree);

    switch (type) {
      case AuditParamType.PHOTO:
      case AuditParamType.PHOTO_FULLWIDTH:
      case AuditParamType.MORE_PHOTOS:
      case AuditParamType.ADDITIONAL_PHOTOS:
        params.assets[paramName] = categoryIds;
        break;
      case AuditParamType.TOGGLE:
      case AuditParamType.PILL_CHECKBOX:
        break;
      case AuditParamType.PILL_RADIO:
        // mapRequiredParamsFromRelatedActions(id, categoryId, categoryIds, paramName, params, values); //TODO: Temporary Disabled
        break;
      default:
        params.fields[paramName] = categoryIds;
    }
  });

  return params;
};

export const getRequiredFieldsFromCategories = (
  categories?: AuditCategoryOfStructure[],
  params: RequiredParams = {
    fields: {},
    assets: {},
  },
  categoryIdsTree: Record<string, string> = {},
  level = 0
): RequiredParams => {
  categories?.forEach((category) => {
    if (
      !category.visible ||
      category.uniqueKey === AuditCategoryUniqueKey.OVERALL_COMMENT ||
      category.uniqueKey === AuditCategoryUniqueKey.TESTDRIVE
    ) {
      return;
    }

    if (!category.childCategories?.length) {
      const lastIndex = Object.keys(categoryIdsTree).length - 1;

      for (let i = level; i < lastIndex; i++) {
        delete categoryIdsTree[i + 1];
      }
    }

    categoryIdsTree[level] = category.id;

    const {relatedActions, additionalInformation} = category.paramDefinitions ?? {};
    const paramDefinitions = [...(relatedActions ?? []), ...(additionalInformation ?? [])];

    params = getRequiredParamsFromParamDefinitions(
      params,
      paramDefinitions,
      categoryIdsTree,
      category.id
    );

    if (category.childCategories) {
      params = getRequiredFieldsFromCategories(
        category.childCategories,
        params,
        categoryIdsTree,
        level + 1
      );
    }
  });

  return params;
};
