import {
  AssignTagFromEntityArg,
  AssignTagFromEntityRes,
  CreateTagArg,
  CreateTagRes,
  EditTagArg,
  EditTagRes,
  ExpireTagArg,
  ExpireTagRes,
  GetTagArg,
  GetTagRes,
  GetTagsArg,
  GetTagsForEntityArg,
  GetTagsForEntityRes,
  GetTagsRes,
  RestoreTagArg,
  RestoreTagRes,
  UnassignTagFromEntityArg,
  UnassignTagFromEntityRes,
} from '../types/api';
import {omneticApi} from './baseApi/omneticApi';

export const tagApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    unassignTag: build.mutation<UnassignTagFromEntityRes, UnassignTagFromEntityArg>({
      query: ({recordId, resourceId, ...tagToDelete}) => ({
        url: `/dms/v1/tag/record-tag-values/${resourceId}/${recordId}`,
        method: 'DELETE',
        body: [tagToDelete],
      }),
      invalidatesTags: ['EntityTagList'],
    }),

    assignTag: build.mutation<AssignTagFromEntityRes, AssignTagFromEntityArg>({
      query: ({recordId, resourceId, ...tagToAssign}) => ({
        url: `/dms/v1/tag/record-tag-values/${resourceId}/${recordId}`,
        method: 'POST',
        body: [tagToAssign],
      }),
      invalidatesTags: ['EntityTagList'],
    }),

    editTag: build.mutation<EditTagRes, EditTagArg>({
      query: ({tagValueId, tagId, ...body}) => ({
        url: `/dms/v1/tag/${tagId}/value/${tagValueId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['EntityTagList'],
    }),

    createTag: build.mutation<CreateTagRes, CreateTagArg>({
      query: ({tagId, ...body}) => ({
        url: `/dms/v1/tag/${tagId}/value`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['EntityTagList'],
    }),

    expireTag: build.mutation<ExpireTagRes, ExpireTagArg>({
      query: ({tagValueId, tagId, ...body}) => ({
        url: `/dms/v1/tag/${tagId}/value/${tagValueId}/expiration`,
        body,
        method: 'PUT',
      }),
    }),

    restoreTag: build.mutation<RestoreTagRes, RestoreTagArg>({
      query: ({tagValueId, tagId}) => ({
        url: `/dms/v1/tag/${tagId}/value/${tagValueId}/expiration`,
        method: 'DELETE',
      }),
    }),

    getTag: build.query<GetTagRes, GetTagArg>({
      query: ({tagValueId, tagId}) => ({
        url: `/dms/v1/tag/${tagId}/value/${tagValueId}`,
      }),
    }),

    getTags: build.query<GetTagsRes, GetTagsArg>({
      query: (params) => ({
        url: `/dms/v1/tag`,
        params,
      }),
      transformResponse: (response: GetTagsRes) =>
        /**
         * there are branches of tags that can be assignable to more than one resource (vehicle, BC, etc.)
         * in this EP, we want tags that are only assignable to one entity
         */
        response.filter((resource) => resource.allowedResources.length === 1),
      keepUnusedDataFor: 0,
      providesTags: ['tenantTagList'],
    }),

    getTagsForEntity: build.query<GetTagsForEntityRes, GetTagsForEntityArg>({
      query: ({recordId, resourceId}) => ({
        url: `/dms/v1/tag/record-tag-values/${resourceId}/${recordId}`,
      }),
      providesTags: ['EntityTagList'],
    }),

    deleteTag: build.mutation<void, GetTagArg>({
      query: ({tagValueId, tagId}) => ({
        url: `/dms/v1/tag/${tagId}/value/${tagValueId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EntityTagList', 'tenantTagList'],
    }),
  }),
});

export const {
  useEditTagMutation,
  useUnassignTagMutation,
  useGetTagQuery,
  useCreateTagMutation,
  useAssignTagMutation,
  useGetTagsForEntityQuery,
  useGetTagsQuery,
  useDeleteTagMutation,
  useExpireTagMutation,
  useRestoreTagMutation,
} = tagApi;
