import {useMemo, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {useLocation} from 'react-router-dom';

import {UseScrollToSectionType} from '../types/CommonVehicleTypes';

export const useScrollToSection = (): UseScrollToSectionType => {
  const location = useLocation();
  const slug = location.hash ? location.hash.slice(1) : null;
  const sectionRef = useRef<HTMLDivElement>(null);
  const sectionIsRendered = ReactDOM.findDOMNode(sectionRef.current);

  const refs: UseScrollToSectionType['refs'] = useMemo(
    () => (slug ? {[slug]: sectionRef} : {}),
    [slug, sectionRef]
  );

  const triggerScroll = () => {
    if (sectionRef?.current?.scrollIntoView) {
      sectionRef.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  useEffect(triggerScroll, [sectionIsRendered]);

  return {
    refs,
    triggerScroll,
  };
};
