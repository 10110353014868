import type {AssignmentUser} from '../types/AssignmentUser';
import type {CancelablePromise} from '../types/CancelablePromise';
import {request as __request} from '../utils/request';

export class SearchUnassignedUsersService {
  /**
   * @returns AssignmentUser
   * @throws ApiError
   */
  public static searchUnassignedUsers({
    assignmentIdentityType,
    assignmentIdentityId,
    search,
    external,
    limit = 20,
    authorization,
  }: {
    assignmentIdentityType: 'vehicle' | 'vehicle_audit';
    assignmentIdentityId: string;
    search?: string | null;
    external?: boolean | null;
    limit?: number | null;
    authorization?: string;
  }): CancelablePromise<Array<AssignmentUser>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/assignment/{assignmentIdentityType}/{assignmentIdentityId}/user-search',
      path: {
        assignmentIdentityType,
        assignmentIdentityId,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        search,
        external,
        limit,
      },
    });
  }
}
