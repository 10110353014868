import {z} from 'zod';

import {BaseVoidResponseSchema, CreatedAndUpdatedBySchema, PaymentTypeSchema} from './base';

const ReceiveNoteCorrectionStateSchema = z.enum(['PENDING', 'COMPLETED']);

const ReceiveNoteCorrectionTotalPriceSchema = z.object({
  totalPriceWithoutVat: z.number(),
  totalPriceWithVat: z.number(),
});

export type ReceiveNoteCorrectionTotalPrice = z.infer<typeof ReceiveNoteCorrectionTotalPriceSchema>;

/**
 * GET
 */
export const GetReceiveNoteCorrectionRequestSchema = z.object({
  creditNoteId: z.string(),
});
export type GetReceiveNoteCorrectionRequest = z.infer<typeof GetReceiveNoteCorrectionRequestSchema>;

export const GetReceiveNoteCorrectionResponseSchema = z.object({
  creditNote: z
    .object({
      creditNoteId: z.string(),
      creditNoteNumber: z.string().optional(),
      supplierId: z.string(),
      warehouseId: z.string(),
      authorizationProfileId: z.string(),
      correctionInvoiceNumber: z.string().optional(),
      correctionInvoiceDate: z.string().optional(),
      paymentType: PaymentTypeSchema,
      currency: z.string().optional(),
      exchangeRate: z.number().optional(),
      ncConversion: z.string().optional(),
      state: ReceiveNoteCorrectionStateSchema,
      note: z.string().optional(),
      correctedReceiveNoteCorrectionId: z.string().optional(),
    })
    .merge(CreatedAndUpdatedBySchema),
  deliveryNote: z
    .object({
      deliveryNoteId: z.string(),
      deliveryNoteNumber: z.string().optional(),
      supplierId: z.string(),
      warehouseId: z.string().optional(),
      authorizationProfileId: z.string(),
      state: z.enum(['PENDING', 'RECEIVED', 'COMPLETED']),
      invoiceNumber: z.string().optional(),
      invoiceIssueDate: z.string().optional(),
      paymentType: PaymentTypeSchema,
      currency: z.string().optional(),
      exchangeRate: z.number().optional(),
      referenceNumber: z.string().optional(),
      ncConversion: z.string().optional(),
      note: z.string().optional(),
      issuedAt: z.string().optional(),
      isFromReceiveNote: z.boolean(),
    })
    .merge(CreatedAndUpdatedBySchema),
  receiveNote: z
    .object({
      receiveNoteId: z.string(),
      deliveryNoteId: z.string(),
      receiveNoteNumber: z.string().optional(),
      supplierId: z.string(),
      warehouseId: z.string(),
      authorizationProfileId: z.string(),
      state: z.enum(['PENDING', 'COMPLETED']),
      invoiceNumber: z.string().optional(),
      invoiceDate: z.string().optional(),
      paymentType: PaymentTypeSchema,
      currency: z.string().optional(),
      exchangeRate: z.number().optional(),
      ncConversion: z.string().optional(),
      note: z.string().optional(),
      issuedAt: z.string().optional(),
      invoiceIssuedAt: z.string().optional(),
      invoiceIssueDate: z.string().optional(),
      isFromReceiveNote: z.boolean(),
    })
    .merge(CreatedAndUpdatedBySchema),
  total: ReceiveNoteCorrectionTotalPriceSchema.optional(),
});
export type GetReceiveNoteCorrectionResponse = z.infer<
  typeof GetReceiveNoteCorrectionResponseSchema
>;

/**
 * POST requests
 */
export const PostReceiveNoteCorrectionRequestSchema = z.object({
  body: z.object({
    receiveNoteId: z.string(),
    supplierId: z.string(),
    warehouseId: z.string(),
    correctionInvoiceNumber: z.string().optional(),
    invoiceIssueDate: z.string().optional(),
    paymentType: PaymentTypeSchema,
    currency: z.string().optional(),
    exchangeRate: z.number().optional(),
    ncConversion: z.string().optional(),
    note: z.string().optional(),
  }),
});
export type PostReceiveNoteCorrectionRequest = z.infer<
  typeof PostReceiveNoteCorrectionRequestSchema
>;

export const PostReceiveNoteCorrectionResponseSchema = z.object({
  id: z.string(),
});
export type PostReceiveNoteCorrectionResponse = z.infer<
  typeof PostReceiveNoteCorrectionResponseSchema
>;

/**
 * PATCH requests
 */
export const PatchReceiveNoteCorrectionRequestSchema = z.object({
  creditNoteId: z.string(),
  body: z.object({
    warehouseId: z.string(),
    supplierId: z.string(),
    correctionInvoiceNumber: z.string().optional(),
    invoiceIssueDate: z.string().optional(),
    paymentType: PaymentTypeSchema,
    currency: z.string().optional(),
    exchangeRate: z.number().optional(),
    ncConversion: z.string().optional(),
    note: z.string().optional(),
  }),
});
export type PatchReceiveNoteCorrectionRequest = z.infer<
  typeof PatchReceiveNoteCorrectionRequestSchema
>;

export const PatchReceiveNoteCorrectionResponseSchema = BaseVoidResponseSchema;
export type PatchReceiveNoteCorrectionResponse = z.infer<
  typeof PatchReceiveNoteCorrectionResponseSchema
>;

export const PatchReceiveNoteCorrectionDeleteRequestSchema = z.object({
  body: z.object({
    creditNoteId: z.array(z.string()),
  }),
});
export type PatchReceiveNoteCorrectionDeleteRequest = z.infer<
  typeof PatchReceiveNoteCorrectionDeleteRequestSchema
>;

export const PatchReceiveNoteCorrectionDeleteResponseSchema = BaseVoidResponseSchema;
export type PatchReceiveNoteCorrectionDeleteResponse = z.infer<
  typeof PatchReceiveNoteCorrectionDeleteResponseSchema
>;

/**
 * PUT requests
 */

export const PutReceiveNoteCorrectionActionRequestSchema = z.object({
  id: z.string(),
  body: z.object({
    actionKey: z.string(),
  }),
});
export type PutReceiveNoteCorrectionActionRequest = z.infer<
  typeof PutReceiveNoteCorrectionActionRequestSchema
>;

export const PutReceiveNoteCorrectionActionResponseSchema = BaseVoidResponseSchema;
export type PutReceiveNoteCorrectionActionResponse = z.infer<
  typeof PutReceiveNoteCorrectionActionResponseSchema
>;
