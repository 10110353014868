import {BreadcrumbType, Card} from 'platform/components';

import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {AddGdprConsentTypeForm} from './components/AddGdprConsentTypeForm';

export function AddNewGdprConsentType() {
  const navigate = useNavigate();

  function handleSuccess() {
    navigate(settingsRoutes.gdprConsentTypes);
  }

  function handleCancel() {
    navigate(settingsRoutes.gdprConsentTypes);
  }

  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('entity.gdpr.labels.gdprConsentTypes'),
      href: settingsRoutes.gdprConsentTypes,
    },
  ];

  return (
    <SettingsTemplate header={{breadcrumbs}} data-testid="add-gdpr-consent-type-page">
      <Card title={i18n.t('page.settings.labels.newConsentType')}>
        <AddGdprConsentTypeForm onSuccess={handleSuccess} onCancel={handleCancel} />
      </Card>
    </SettingsTemplate>
  );
}
