import {Avatar, Button} from 'platform/components';
import {Box, HStack, Show, Space, Text, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {CustomerDiscriminator, CustGetExternalCustomersApiResponse} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {DOT_CHARACTER, EMPTY_PLACEHOLDER, Nullish, suffixTestId, TestIdProps} from 'shared';

import {useAddress} from '../../../hooks/useAddress';
import {CreateCustomerProps} from '../types/CreateCustomerProps';
import {TagReplacer} from './TagReplacer';

export interface OnExternalCustomerSelectData
  extends Omit<CreateCustomerProps, 'customerGroups' | 'isSelfEmployed' | 'phoneNumber'> {
  phoneNumber?: string | null;
}

export interface MatchingCustomerProps extends TestIdProps {
  customer: NonNullable<NonNullable<CustGetExternalCustomersApiResponse>[number]>;
  onSelect: (customer: OnExternalCustomerSelectData) => void;
}

export function MatchingExternalCustomer(props: MatchingCustomerProps) {
  const {composeShortAddress} = useAddress();

  const defaultContact = props.customer.contacts?.[0];

  const address = match({
    businessAddress: props.customer.businessAddress,
    foundingPersonAddress: props.customer.foundingPerson?.address,
  })
    .with({businessAddress: Pattern.not(Pattern.nullish)}, (c) => c.businessAddress)
    .with({foundingPersonAddress: Pattern.not(Pattern.nullish)}, (c) => c.foundingPersonAddress)
    .otherwise(() => null);

  const shortAddress = address && composeShortAddress({id: '', address, type: null, invalid: null});

  const customerName = match(props.customer)
    .with({tradeName: Pattern.string}, (c) => c.tradeName)
    .with(
      {foundingPerson: {lastName: Pattern.string, firstName: Pattern.string}},
      (c) => `${c.foundingPerson?.firstName} ${c.foundingPerson?.lastName}`
    )
    .otherwise(() => EMPTY_PLACEHOLDER);

  const customerPhone = props.customer.foundingPerson?.phone ?? defaultContact?.phone;

  const customerEmail = props.customer.foundingPerson?.email ?? defaultContact?.email;

  return (
    <Box
      height="100%"
      borderColor="palettes.neutral.40.100"
      borderBottom="1px solid"
      backgroundColor="palettes.white.10.100"
      overflow="hidden"
      paddingVertical={3}
    >
      <HStack align="center" spacing={3}>
        <Avatar colorVariant="external" size="default" icon="social/person" />
        <VStack spacing={1} align="flex-start" justify="center">
          <HStack spacing={2} wrap align="center">
            <Text data-testid={suffixTestId('name', props)}>
              <TagReplacer text={customerName} />
            </Text>
          </HStack>
          <HStack spacing={1} wrap>
            <Text
              size="xSmall"
              color="secondary"
              noWrap
              data-testid={suffixTestId('address', props)}
            >
              {shortAddress || EMPTY_PLACEHOLDER}
            </Text>
          </HStack>
          <HStack spacing={1} wrap>
            <Show when={isNotNilOrEmpty(props.customer.registrationNumber)}>
              <Text
                size="xSmall"
                color="secondary"
                noWrap
                data-testid={suffixTestId('registrationNumber', props)}
              >
                {`${i18n.t('entity.customer.labels.registrationNumber')}: `}
                <TagReplacer text={props.customer.registrationNumber!} />
              </Text>
            </Show>
            <Show
              when={
                isNotNilOrEmpty(props.customer.registrationNumber) &&
                isNotNilOrEmpty(props.customer.vatNumber)
              }
            >
              <Text color="tertiary" size="xSmall">
                {DOT_CHARACTER}
              </Text>
            </Show>
            <Show when={isNotNilOrEmpty(props.customer.vatNumber)}>
              <Text
                size="xSmall"
                color="secondary"
                noWrap
                data-testid={suffixTestId('vatNumber', props)}
              >
                {`${i18n.t('entity.customer.labels.vatNumber')}: `}
                <TagReplacer text={props.customer.vatNumber!} />
              </Text>
            </Show>
          </HStack>
        </VStack>
        <Space fillAvailable />
        <VStack spacing={1} align="flex-end" justify="center">
          <Text data-testid={suffixTestId('phone', props)}>
            <TagReplacer text={customerPhone || EMPTY_PLACEHOLDER} />
          </Text>
          <Text color="secondary" size="xSmall" noWrap data-testid={suffixTestId('email', props)}>
            <TagReplacer text={customerEmail || EMPTY_PLACEHOLDER} />
          </Text>
        </VStack>
        <Button
          title={i18n.t('general.labels.select')}
          onClick={() => {
            props.onSelect({
              phoneNumber: customerPhone,
              vatNumber: props.customer.vatNumber,
              registrationNumber: props.customer.registrationNumber,
              tradeName: props.customer.tradeName,
              lastName: props.customer.foundingPerson?.lastName,
              firstName: props.customer.foundingPerson?.firstName,
              email: customerEmail,
              customerDiscriminator:
                (props.customer.discriminator as Nullish | CustomerDiscriminator) ||
                'NATURAL_PERSON',
              ...(address && {
                address: {
                  street: address.street,
                  descriptiveNumber: address.descriptiveNumber,
                  orientationNumber: address.orientationNumber,
                  city: address.city,
                  zip: address.zip,
                  addressComplement: address.addressComplement,
                  country: address.country,
                  prefix: address.prefix,
                  district: address.district,
                  state: address.state,
                  postal: address.postal,
                  ...(address.coordinates && {
                    coordinates: {
                      latitude: address.coordinates.latitude,
                      longitude: address.coordinates.longitude,
                    },
                  }),
                },
              }),
            });
          }}
          variant="secondary"
          data-testid={suffixTestId('select', props)}
        />
      </HStack>
    </Box>
  );
}
