import {createContext} from 'shared';

import {FiltersType} from '../../types/FiltersType';

interface FiltersContext {
  filters: FiltersType;
  onChange: (filters: FiltersType) => void;
  onUpdateFilters: (path: string[], value: any) => void;
  onClearFilters: () => void;
}

export const [FiltersContextProvider, useFilters] = createContext<FiltersContext>();
