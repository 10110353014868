import {BreadcrumbType} from 'platform/components';

import {useEffect} from 'react';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {getKonzultaConfig, useThunkDispatch} from '@omnetic-dms/teas';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {GatewayKonzulta} from './components/GatewayKonzulta';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.integrations.labels.smsGateways'),
    href: settingsRoutes.smsGateways,
  },
];

export function SmsGatewaysKonzulta() {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(getKonzultaConfig.action({}));
  }, [dispatch]);

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.integrations.labels.smsGatewayKonzulta'), breadcrumbs}}
      data-testid="integrations-smsGateways-settings"
    >
      <GatewayKonzulta />
    </SettingsTemplate>
  );
}
