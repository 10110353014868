import {
  DropdownDivider,
  DropdownItem,
  DropdownSubmenu,
  openDialog,
  useTranslationContext,
} from 'platform/components';
import {Icon, Show} from 'platform/foundation';

import {isNil, isNotNil} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useDataGridConfig} from '../../context/useDatagridConfig';
import {useDataGridContext} from '../../context/useDataGridContext';
import {Preset} from '../../types/Api';
import {DataGridPermissionKeysEnum} from '../../types/DataGridPermissionKeysEnum';
import {SharePresetForUserDialog} from './SharePresetForUserDialog';

export interface PresetButtonActionsProps extends RequiredTestIdProps {
  index: number;
  preset: Preset;
  presetChanged: boolean;
  presetsLength: number;
  activePresetId: string | undefined;
  updatePreset: (presetId: string) => void;
  resetOverrides: () => void;
  duplicatePreset: (presetId: string) => void;
  updatePresetPosition: (presetId: string, from: number, to: number) => void;
  deletePreset: (presetId: string) => void;
  openEditModal: () => void;
}

export function PresetButtonActions(props: PresetButtonActionsProps) {
  const t = useTranslationContext();
  const {gridCode} = useDataGridContext();
  const {useGetUserOptions, usePermissions} = useDataGridConfig();

  const permissions = usePermissions({
    permissionKeys: [DataGridPermissionKeysEnum.CUSTOM_SHARE_PRESET],
  });

  const onPresetShare = () => {
    if (isNil(useGetUserOptions)) {
      throw new Error('useGetUserOptions - is not defined in DG provider');
    }

    openDialog(
      <SharePresetForUserDialog
        presetId={props.preset.id}
        gridCode={gridCode}
        useGetUserOptionsQuery={useGetUserOptions}
        data-testid={suffixTestId('sharePresetToUser', props)}
      />,
      {
        title: t('page.datagrid.labels.sharePresetDialogTitle'),
        scrollBehavior: 'outside',
        size: 'small',
        'data-testid': suffixTestId('sharePresetToUser', props),
      }
    );
  };

  return (
    <>
      <DropdownItem
        key="update"
        isDisabled={!(props.presetChanged && props.preset.id === props.activePresetId)}
        onClick={() => props.updatePreset(props.preset.id)}
        prefix={<Icon value="action/update" />}
        label={t('general.actions.update')}
        data-testid={suffixTestId('updateAction', props)}
      />
      <DropdownItem
        key="reset"
        isDisabled={!(props.presetChanged && props.preset.id === props.activePresetId)}
        onClick={() => props.resetOverrides()}
        prefix={<Icon value="action/restore" />}
        label={t('general.actions.resetOverrides')}
        data-testid={suffixTestId('resetAction', props)}
      />
      <DropdownItem
        key="edit"
        onClick={props.openEditModal}
        prefix={<Icon value="image/edit" />}
        label={t('general.actions.edit')}
        data-testid={suffixTestId('editAction', props)}
      />
      <DropdownItem
        key="copy"
        onClick={() => props.duplicatePreset(props.preset.id)}
        prefix={<Icon value="content/copy" />}
        label={t('general.actions.duplicate')}
        data-testid={suffixTestId('duplicateAction', props)}
      />
      <Show
        whenFeatureEnabled="dg_share_custom_preset_copy"
        when={isNotNil(useGetUserOptions) && permissions?.[0]}
      >
        <DropdownItem
          key="share"
          onClick={onPresetShare}
          prefix={<Icon value="social/share" />}
          label={t('general.actions.share')}
          data-testid={suffixTestId('shareAction', props)}
        />
      </Show>
      <DropdownSubmenu
        key="reorder"
        prefix={<Icon value="action/reorder" />}
        label={t('general.actions.moveTo')}
        data-testid={suffixTestId('reorderActionGroup', props)}
        isActive={false}
      >
        <DropdownItem
          key="toFront"
          isDisabled={props.index === 0}
          prefix={<Icon value="navigation/first_page" />}
          label={t('general.actions.bringToFront')}
          onClick={() => props.updatePresetPosition(props.preset.id, props.index, 0)}
          data-testid={suffixTestId('bringToFrontAction', props)}
        />
        <DropdownItem
          key="forward"
          isDisabled={props.index === 0}
          prefix={<Icon value="navigation/arrow_left" />}
          label={t('general.actions.bringForward')}
          onClick={() => props.updatePresetPosition(props.preset.id, props.index, props.index - 1)}
          data-testid={suffixTestId('bringForwardAction', props)}
        />
        <DropdownItem
          key="toBack"
          isDisabled={props.index === props.presetsLength - 1}
          prefix={<Icon value="navigation/arrow_right" />}
          label={t('general.actions.sendBackward')}
          onClick={() => props.updatePresetPosition(props.preset.id, props.index, props.index + 1)}
          data-testid={suffixTestId('sendBackwardAction', props)}
        />
        <DropdownItem
          key="backward"
          isDisabled={props.index === props.presetsLength - 1}
          prefix={<Icon value="navigation/last_page" />}
          label={t('general.actions.sendToBack')}
          onClick={() =>
            props.updatePresetPosition(props.preset.id, props.index, props.presetsLength - 1)
          }
          data-testid={suffixTestId('sendToBackAction', props)}
        />
      </DropdownSubmenu>
      <DropdownDivider />
      <DropdownItem
        key="delete"
        prefix={<Icon value="action/delete" />}
        label={t('general.actions.delete')}
        onClick={() => props.deletePreset(props.preset.id)}
        data-testid={suffixTestId('deleteAction', props)}
      />
    </>
  );
}
