import {addMonths} from 'date-fns';

import {getApiDateString} from 'shared';

import {Fluid} from '../types/Fluid';
import {UpdateServiceIntervalForm} from '../types/UpdateServiceIntervalForm';

export function fluidUpdate(act: Fluid, formData: UpdateServiceIntervalForm) {
  const copy = structuredClone(act);

  copy.lastReplacementDate = formData.date;
  copy.lastReplacementMileage = formData.mileage ?? 0;

  if (copy.nextReplacementMileage) {
    copy.nextReplacementDate = getApiDateString(
      addMonths(copy.lastReplacementDate, copy.nextReplacementMileage)
    );
  }

  return copy;
}
