import {useContext} from 'react';

import {InvoiceVatRateContext} from './InvoiceVatRateContext';

export function useInvoiceVatRatesContext() {
  const context = useContext(InvoiceVatRateContext);

  if (!context) {
    const error = new Error('useInvoiceVatRate must be used within a InvoiceVatRateProvider');
    error.name = 'ContextError';
    Error.captureStackTrace?.(error, useContext);
    throw error;
  }

  return context;
}
