import {Button, DataStatus, Form, FormButton, FormSubmitHandler} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';
import {array, object} from 'yup';

import {useState} from 'react';

import {always, isNotNil, times} from 'ramda';

import {
  OccupiedSpaceRequestBody,
  useGetGeneralSettingsStorageLocationQuery,
  useGetOrderSetDefaultContactPersonQuery,
  usePostTireSetMutation,
  useUpdateOccupiedSpaceMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  TireSetFormHeader,
  TireSetFormType,
  WheelArray,
  WheelType,
  handleApiError,
  printFile,
  useTenant,
  useTireSetOptions,
} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId, yupNumber, yupString} from 'shared';

import {useDefaultStoredUntilDate} from '../hooks/useDefaultStoredUntilDate';

interface TireSetFormProps extends TestIdProps {
  tireOrderId: string;
  onClose: () => void;
  onCreate: () => void;
}

const EMPTY_WHEEL: Partial<WheelType> = {};

export function TireSetForm(props: TireSetFormProps) {
  const [selectedSpace, setSelectedSpace] = useState<OccupiedSpaceRequestBody | undefined>(
    undefined
  );
  const {isLoading: isGeneralSettingsStorageLocationLoading} =
    useGetGeneralSettingsStorageLocationQuery();
  const {locationOptions, isLoading: areOptionsLoading, defaultManufacturer} = useTireSetOptions();
  const {isLoading: isSettingsLoading, storedUntil: defaultStoredUntil} =
    useDefaultStoredUntilDate();

  const {data: defaultContact, isLoading: isDefaultContactLoading} =
    useGetOrderSetDefaultContactPersonQuery(
      {orderId: props.tireOrderId},
      {
        selectFromResult: (result) => ({
          ...result,
          data: result.data?.contactPerson,
        }),
      }
    );

  const [postTireSet] = usePostTireSetMutation();
  const [updateOccupiedSpace] = useUpdateOccupiedSpaceMutation();

  const handleSubmit: FormSubmitHandler<TireSetFormType> = async (data) => {
    const {wheels, ...other} = data;

    await postTireSet({
      orderId: props.tireOrderId,
      body: {
        ...other,
        wheels: wheels.map((wheelDetails, index) => ({
          number: String(index + 1),
          wheelDetails,
        })),
      },
    })
      .unwrap()
      .then((response) => {
        if (isNotNil(selectedSpace) && isNotNil(response?.setId)) {
          updateOccupiedSpace({
            recordId: response?.setId ?? '',
            spaceType: 'TIRE_ORDER',
            updateOccupiedSpaceRequestBody: {space: selectedSpace},
          })
            .unwrap()
            .catch(handleApiError);
        }

        props.onClose();
        props.onCreate();
        printFile(response?.pdfUrl ?? '');
      })
      .catch(handleApiError);
  };

  const isLoading =
    areOptionsLoading ||
    isSettingsLoading ||
    isDefaultContactLoading ||
    isGeneralSettingsStorageLocationLoading;

  const {tenantPhoneInfo} = useTenant();

  return (
    <DataStatus isLoading={isLoading} minHeight={60}>
      <Form<TireSetFormType>
        schema={schema}
        onSubmit={handleSubmit}
        mode="onChange"
        defaultValues={{
          numberOfTires: 4,
          storedUntil: defaultStoredUntil,
          wheels: times(always(EMPTY_WHEEL), 4).map((wheel, index) => ({
            ...wheel,
            manufacturer: defaultManufacturer?.id ?? undefined,
            location: locationOptions?.[index % locationOptions.length]?.value,
          })),
          contactPerson: {
            name: defaultContact?.name ?? undefined,
            email: defaultContact?.email ?? undefined,
            phoneNumber: {
              countryCode: defaultContact?.phoneNumber?.countryCode ?? tenantPhoneInfo.countryCode,
              number: defaultContact?.phoneNumber?.number ?? parseInt(tenantPhoneInfo.number),
              prefix: defaultContact?.phoneNumber?.prefix ?? tenantPhoneInfo.prefix,
            },
          },
        }}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <TireSetFormHeader
              control={control}
              formApi={formApi}
              selectStorageLocationWithoutId={selectedSpace}
              onSelectStorageLocationWithoutId={setSelectedSpace}
              data-testid={suffixTestId('header', props)}
            />
            <WheelArray
              control={control}
              formApi={formApi}
              data-testid={suffixTestId('wheelArray', props)}
            />
            <HStack justify="space-between">
              <Button
                leftIcon="action/delete"
                title={i18n.t('general.actions.discard')}
                variant="dangerLink"
                onClick={props.onClose}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                control={control}
                title={i18n.t('entity.tireSet.actions.stockingTires')}
                variant="primary"
                type="submit"
                data-testid={suffixTestId('stockingTires', props)}
              />
            </HStack>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const schema = object({
  numberOfTires: yupNumber.required(),
  wheels: array()
    .of(
      object().shape({
        manufacturer: yupString.required(),
        location: yupString.required(),
      })
    )
    .required(),
});
