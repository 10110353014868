import {ImageFit} from '../Image/Image';

export const makeStorageUrl = (url: string, width: number, height: number, fit: ImageFit) => {
  const storageUrl = new URL(url, window.location.href);
  storageUrl.searchParams.set('width', width.toString());
  storageUrl.searchParams.set('height', height.toString());
  storageUrl.searchParams.set('fit', storageFitMap[fit]);

  return storageUrl.toString();
};

const storageFitMap = {
  contain: 'inside',
  'scale-down': 'inside',
  cover: 'cover',
} as const;
