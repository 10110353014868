import {Tooltip} from 'platform/components';
import {Heading} from 'platform/foundation';
import styled from 'styled-components';
import styledMap from 'styled-map';

import {
  forwardRef,
  ReactElement,
  ForwardedRef,
  HTMLAttributes,
  DetailedHTMLProps,
  ForwardRefRenderFunction,
} from 'react';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${({theme}) => theme.radii.small};
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
`;

const Header = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
`;

const TitleContainer = styled.div`
  flex: 0 0 auto;
`;

const TitleContainerWithTooltip = styled.div`
  display: flex;
`;

const padding = styledMap`
	default: 16px;
	noPadding: 0px;
`;

type ContentProps = {
  $noPadding?: boolean;
};

const Content = styled.div<ContentProps>`
  width: 100%;
  padding: ${padding};
  flex: 1 1 auto;
`;

const Footer = styled.div`
  width: 100%;
  padding: 16px;
  border-top: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
`;

export type CardProps = {
  title?: string | ReactElement;
  name?: string;
  headerContent?: ReactElement | null;
  footerContent?: ReactElement;
  noPadding?: boolean;
  infoTooltip?: string | ReactElement;
} & Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'title' | 'ref'>;
/**
 * @deprecated - use platform instead
 */
const TypographyCardComponent: ForwardRefRenderFunction<HTMLDivElement, CardProps> = (
  {
    title,
    name,
    headerContent = null,
    children,
    footerContent,
    noPadding = false,
    infoTooltip,
    ...rest
  },
  ref: ForwardedRef<HTMLDivElement>
) => (
  <Container {...rest} ref={ref} data-testid={name}>
    {title && (
      <Header className="card-header">
        {!infoTooltip ? (
          <TitleContainer>
            {/** @ts-expect-error It should not break anything, ignoring on legacy code only */}
            <Heading size={5}>{title}</Heading>
          </TitleContainer>
        ) : (
          <TitleContainerWithTooltip>
            <Tooltip label={infoTooltip}>
              {/** @ts-expect-error It should not break anything, ignoring on legacy code only */}
              <Heading size={5}>{title}</Heading>
            </Tooltip>
          </TitleContainerWithTooltip>
        )}
        {headerContent}
      </Header>
    )}

    <Content className="card-content" $noPadding={noPadding}>
      {children}
    </Content>
    {footerContent && <Footer>{footerContent}</Footer>}
  </Container>
);

export const TypographyCard = forwardRef(TypographyCardComponent);
