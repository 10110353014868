import {Option} from 'platform/components';

import {isNotNil} from 'ramda';
import {isFalse, isNilOrEmpty} from 'ramda-adjunct';

import {useGetCustomTenantCatalogueQuery} from '@omnetic-dms/api';

import {Nullish} from 'shared';

type ModelOption = {
  make: string;
  makeLabel: string;
} & Option;

export function useCustomTenantCatalogue(vehicleType: string | Nullish) {
  const {
    data: customTenantCatalogue,
    isLoading,
    isError,
  } = useGetCustomTenantCatalogueQuery(undefined, {skip: isNilOrEmpty(vehicleType)});

  const customMakes =
    isNotNil(customTenantCatalogue) && vehicleType
      ? customTenantCatalogue?.[vehicleType]?.makes
      : null;
  const customMakesKeys = customMakes ? Object.keys(customMakes) : null;

  const customMakeOptions =
    customMakes && customMakesKeys
      ? customMakesKeys.reduce((makes: Option[], make) => {
          if (!customMakes[make] || isFalse(customMakes[make]?.isCustom)) {
            return makes;
          }
          return [...makes, {label: make, value: make}];
        }, [])
      : null;

  const customModelOptions =
    customMakes && customMakesKeys
      ? customMakesKeys.reduce((models: ModelOption[], make) => {
          if (!customMakes[make] || isFalse(customMakes[make]?.isCustom)) {
            return models;
          }
          const options = Object.keys(customMakes[make].models)
            .filter((model) => customMakes[make].models[model].isCustom)
            .map((model) => ({
              label: model,
              value: model,
              makeLabel: make,
              make,
            }));

          return [...models, ...options];
        }, [])
      : null;

  return {customMakeOptions, customModelOptions, isLoading, isError};
}
