import {CardRenderer} from '../renderers/CardRenderer';
import {CheckboxesRenderer} from '../renderers/CheckboxesRenderer';
import {CheckboxRenderer} from '../renderers/CheckboxRenderer';
import {ChipsRenderer} from '../renderers/ChipsRenderer';
import {DateRangeRenderer} from '../renderers/DateRangeRenderer';
import {DateRenderer} from '../renderers/DateRenderer';
import {DateTimeRenderer} from '../renderers/DateTimeRenderer';
import {DividerRenderer} from '../renderers/DividerRenderer';
import {EmailRenderer} from '../renderers/EmailRenderer';
import {MoneyRenderer} from '../renderers/MoneyRenderer';
import {NumberRenderer} from '../renderers/NumberRenderer';
import {PhoneRenderer} from '../renderers/PhoneRenderer';
import {RowRenderer} from '../renderers/RowRenderer';
import {SelectRenderer} from '../renderers/SelectRenderer';
import {SliderRenderer} from '../renderers/SliderRenderer';
import {SwitchRenderer} from '../renderers/SwitchRenderer';
import {TextRenderer} from '../renderers/TextRenderer';
import {RendererRegistry} from '../types/RendererRegistry';

export const rendererRegistry: RendererRegistry = {
  row: RowRenderer,
  card: CardRenderer,
  text: TextRenderer,
  number: NumberRenderer,
  phone: PhoneRenderer,
  slider: SliderRenderer,
  select: SelectRenderer,
  chips: ChipsRenderer,
  checkbox: CheckboxRenderer,
  checkboxes: CheckboxesRenderer,
  switch: SwitchRenderer,
  divider: DividerRenderer,
  date: DateRenderer,
  dateRange: DateRangeRenderer,
  email: EmailRenderer,
  dateTime: DateTimeRenderer,
  money: MoneyRenderer,
} as const;
