import {Show} from 'platform/foundation';

import {featureFlags} from '@omnetic-dms/feature-flags';
import {AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

import {PlatformAuthenticationFormTipCars} from './components/PlatformAuthenticationFormTipCars';
import {PlatformPhotoSettings} from './components/PlatformPhotoSettings';
import {PlatformTipCarsInfo} from './components/PlatformTipCarsInfo';
import {PlatformTipCarsVin} from './components/PlatformTipCarsVin';

export function PlatformSettingsTipCars() {
  return (
    <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
      <PlatformAuthenticationFormTipCars />
      <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
        <PlatformPhotoSettings platformCode={AdvertisingPlatformCodeEnum.TIPCARS} />
      </Show>
      <PlatformTipCarsVin />
      <PlatformTipCarsInfo />
    </Show>
  );
}
