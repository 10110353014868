/**
 * This file is a copy of the MUI Slider v4 component with some modifications.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/no-array-index-key */

/* eslint-disable react/forbid-dom-props */
import {
  LegacyRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

function asc(a: number, b: number) {
  return a - b;
}

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(min, value), max);
}

function findClosest(values: any[], currentValue: number) {
  const {index: closestIndex} = values.reduce(
    (acc: {distance: number} | null, value: number, index: any) => {
      const distance = Math.abs(currentValue - value);

      if (acc === null || distance < acc.distance || distance === acc.distance) {
        return {
          distance,
          index,
        };
      }

      return acc;
    },
    null
  );
  return closestIndex;
}

function trackFinger(event: any, touchId: MutableRefObject<undefined>) {
  if (touchId.current !== undefined && event.changedTouches) {
    for (let i = 0; i < event.changedTouches.length; i += 1) {
      const touch = event.changedTouches[i];
      if (touch.identifier === touchId.current) {
        return {
          x: touch.clientX,
          y: touch.clientY,
        };
      }
    }

    return false;
  }

  return {
    x: event.clientX,
    y: event.clientY,
  };
}

function valueToPercent(value: number, min: number, max: number) {
  return ((value - min) * 100) / (max - min);
}

function percentToValue(percent: number, min: number, max: number) {
  return (max - min) * percent + min;
}

function getDecimalPrecision(num: number) {
  // This handles the case when num is very small (0.00000001), js will turn this into 1e-8.
  // When num is bigger than 1 or less than -1 it won't get converted to this notation so it's fine.
  if (Math.abs(num) < 1) {
    const parts = num.toExponential().split('e-');
    const matissaDecimalPart = parts[0].split('.')[1];
    return (matissaDecimalPart ? matissaDecimalPart.length : 0) + parseInt(parts[1], 10);
  }

  const decimalPart = num.toString().split('.')[1];
  return decimalPart ? decimalPart.length : 0;
}

function roundValueToStep(value: number, step: number, min: number) {
  const nearest = Math.round((value - min) / step) * step + min;
  return Number(nearest.toFixed(getDecimalPrecision(step)));
}

function setValueIndex({values, source, newValue, index}: any) {
  // Performance shortcut
  if (values[index] === newValue) {
    return source;
  }

  const output = values.slice();
  output[index] = newValue;
  return output;
}

function focusThumb({sliderRef, activeIndex}: any) {
  if (
    !sliderRef.current.contains(document.activeElement) ||
    Number(document.activeElement?.getAttribute('data-index')) !== activeIndex
  ) {
    sliderRef.current.querySelector(`[role="slider"][data-index="${activeIndex}"]`).focus();
  }
}

const axisProps = {
  offset: (percent: any) => ({left: `${percent}%`}),
  leap: (percent: any) => ({width: `${percent}%`}),
};

const styles = () => ({
  root: {
    height: 2,
    width: '100%',
    boxSizing: 'content-box',
    padding: '13px 0',
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    touchAction: 'none',
    color: 'rgba(25, 118, 210, 1)',
    WebkitTapHighlightColor: 'transparent',
  },
  /* Styles applied to the rail element. */
  rail: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: 2,
    borderRadius: 1,
    backgroundColor: 'currentColor',
    opacity: 0.38,
  },
  /* Styles applied to the track element. */
  track: {
    display: 'block',
    position: 'absolute',
    height: 2,
    borderRadius: 1,
    backgroundColor: 'currentColor',
  },
  /* Styles applied to the thumb element. */
  thumb: {
    position: 'absolute',
    width: 12,
    height: 12,
    marginLeft: -6,
    marginTop: -5,
    boxSizing: 'border-box',
    borderRadius: '50%',
    outline: 0,
    backgroundColor: 'currentColor',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::after': {
      position: 'absolute',
      content: '""',
      borderRadius: '50%',
      // reach 42px hit target (2 * 15 + thumb diameter)
      left: -15,
      top: -15,
      right: -15,
      bottom: -15,
    },
    '&:hover': {
      boxShadow: `0px 0px 0px 8px rgba(25, 118, 210, 0.16)`,
      '@media (hover: none)': {
        boxShadow: 'none',
      },
    },
    '&$active': {
      boxShadow: `0px 0px 0px 14px rgba(25, 118, 210, 0.16)`,
    },
  },
  /* Pseudo-class applied to the thumb element if it's active. */
  active: {},
  /* Styles applied to the thumb label element. */
  valueLabel: {
    // IE 11 centering bug, to remove from the customization demos once no longer supported
    left: 'calc(-50% - 4px)',
  },
  /* Styles applied to the mark element. */
  mark: {
    position: 'absolute',
    width: 2,
    height: 2,
    borderRadius: 1,
    backgroundColor: 'currentColor',
  },
  /* Styles applied to the mark element if active (depending on the value). */
  markActive: {
    backgroundColor: '#fff',
    opacity: 0.8,
  },
  /* Styles applied to the mark label element. */
  markLabel: {
    position: 'absolute',
    top: 26,
    transform: 'translateX(-50%)',
    whiteSpace: 'nowrap',
  },
  /* Styles applied to the mark label element if active (depending on the value). */
  markLabelActive: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
});

interface Mark {
  value: number;
  label?: React.ReactNode;
}

type MUISliderProps = {
  min?: number;
  max?: number;
  showMaxPlus?: boolean;
  unit?: string;
  marks?: Mark[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange?: any;
  step?: number | null;
  defaultValue?: number[];
  value?: number[];
};

/**
 * @deprecated - use platform instead
 */
export function MUISlider(props: MUISliderProps) {
  const {
    defaultValue,
    marks: marksProp = false,
    max = 100,
    min = 0,
    onChange,
    step = 1,
    value: valueProp,
    ...other
  } = props;
  const touchId = useRef();
  const [valueDerived, setValueState] = useControlled({
    controlled: valueProp,
    default: defaultValue,
  });

  const range = Array.isArray(valueDerived);
  let values = range ? valueDerived.slice().sort(asc) : [valueDerived];
  values = values.map((value) => clamp(value, min, max));
  const marks = marksProp || [];

  const sliderRef = useRef<HTMLElement>();

  const handleKeyDown = useEventCallback(
    (event: {
      currentTarget: {getAttribute: (arg0: string) => any};
      key: any;
      preventDefault: () => void;
    }) => {
      const index = Number(event.currentTarget.getAttribute('data-index'));
      const value = values[index];
      const marksValues = marks.map((mark: {value: any}) => mark.value);
      const marksIndex = marksValues.indexOf(value);
      let newValue;
      const increaseKey = 'ArrowRight';
      const decreaseKey = 'ArrowLeft';

      switch (event.key) {
        case increaseKey:
        case 'ArrowUp':
          if (step) {
            newValue = value + step;
          } else {
            newValue = marksValues[marksIndex + 1] || marksValues[marksValues.length - 1];
          }
          break;
        case decreaseKey:
        case 'ArrowDown':
          if (step) {
            newValue = value - step;
          } else {
            newValue = marksValues[marksIndex - 1] || marksValues[0];
          }
          break;
        default:
          return;
      }

      // Prevent scroll of the page
      event.preventDefault();

      if (step) {
        newValue = roundValueToStep(newValue, step, min);
      }

      newValue = clamp(newValue, min, max);

      if (range) {
        const previousValue = newValue;
        newValue = setValueIndex({
          values,
          source: valueDerived,
          newValue,
          index,
        }).sort(asc);
        focusThumb({sliderRef, activeIndex: newValue.indexOf(previousValue)});
      }

      setValueState(newValue);

      if (onChange) {
        onChange(event, newValue);
      }
    }
  );

  const previousIndex = useRef<number | undefined>();

  const getFingerNewValue = ({finger, move = false, values: values2, source}: any) => {
    const {current: slider} = sliderRef;
    const rect = slider?.getBoundingClientRect();
    const percent = (finger.x - (rect?.left ?? 0)) / (rect?.width ?? 1);

    let newValue;
    newValue = percentToValue(percent, min, max);
    if (step) {
      newValue = roundValueToStep(newValue, step, min);
    } else {
      const marksValues = marks.map((mark: {value: any}) => mark.value);
      const closestIndex = findClosest(marksValues, newValue);
      newValue = marksValues[closestIndex];
    }

    newValue = clamp(newValue, min, max);
    let activeIndex: number | undefined = 0;

    if (range) {
      if (!move) {
        activeIndex = findClosest(values2, newValue);
      } else {
        activeIndex = previousIndex.current;
      }

      const previousValue = newValue;
      newValue = setValueIndex({
        values: values2,
        source,
        newValue,
        index: activeIndex,
      }).sort(asc);
      activeIndex = newValue.indexOf(previousValue);
      previousIndex.current = activeIndex;
    }

    return {newValue, activeIndex};
  };

  const handleTouchMove = useEventCallback((event: any) => {
    const finger = trackFinger(event, touchId);

    if (!finger) {
      return;
    }

    const {newValue, activeIndex} = getFingerNewValue({
      finger,
      move: true,
      values,
      source: valueDerived,
    });

    focusThumb({sliderRef, activeIndex});
    setValueState(newValue);

    if (onChange) {
      onChange(event, newValue);
    }
  });

  const handleTouchEnd = useEventCallback((event: any) => {
    const finger = trackFinger(event, touchId);

    if (!finger) {
      return;
    }

    touchId.current = undefined;

    const doc = document;
    doc.removeEventListener('mousemove', handleTouchMove);
    doc.removeEventListener('mouseup', handleTouchEnd);
    doc.removeEventListener('touchmove', handleTouchMove);
    doc.removeEventListener('touchend', handleTouchEnd);
  });

  const handleTouchStart = useEventCallback(
    (event: {preventDefault: () => void; changedTouches: any[]}) => {
      // Workaround as Safari has partial support for touchAction: 'none'.
      event.preventDefault();
      const touch = event.changedTouches[0];
      if (touch != null) {
        // A number that uniquely identifies the current finger in the touch session.
        touchId.current = touch.identifier;
      }
      const finger = trackFinger(event, touchId);
      const {newValue, activeIndex} = getFingerNewValue({finger, values, source: valueDerived});
      focusThumb({sliderRef, activeIndex});

      setValueState(newValue);

      if (onChange) {
        onChange(event, newValue);
      }

      const doc = document;
      doc.addEventListener('touchmove', handleTouchMove);
      doc.addEventListener('touchend', handleTouchEnd);
    }
  );

  useEffect(() => {
    const {current: slider} = sliderRef;
    slider?.addEventListener('touchstart', handleTouchStart);
    const doc = document;

    return () => {
      slider?.removeEventListener('touchstart', handleTouchStart);
      doc.removeEventListener('mousemove', handleTouchMove);
      doc.removeEventListener('mouseup', handleTouchEnd);
      doc.removeEventListener('touchmove', handleTouchMove);
      doc.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchEnd, handleTouchMove, handleTouchStart]);

  const handleMouseDown = useEventCallback((event: {preventDefault: () => void}) => {
    event.preventDefault();
    const finger = trackFinger(event, touchId);
    const {newValue, activeIndex} = getFingerNewValue({finger, values, source: valueDerived});
    focusThumb({sliderRef, activeIndex});

    setValueState(newValue);

    if (onChange) {
      onChange(event, newValue);
    }

    const doc = document;
    doc.addEventListener('mousemove', handleTouchMove);
    doc.addEventListener('mouseup', handleTouchEnd);
  });

  const trackOffset = valueToPercent(range ? values[0] : min, min, max);
  const trackLeap = valueToPercent(values[values.length - 1], min, max) - trackOffset;
  const trackStyle = {
    ...axisProps.offset(trackOffset),
    ...axisProps.leap(trackLeap),
  };

  return (
    <>
      <style>
        {`
      .MuiSlider-rail {
        width: 100%;
        height: 2px;
        display: block;
        opacity: 0.38;
        position: absolute;
        border-radius: 1px;
        background-color: currentColor;
      }
      .MuiSlider-track {
        height: 2px;
        display: block;
        position: absolute;
        border-radius: 1px;
        background-color: currentColor;
      }
      .MuiSlider-thumb {
        width: 12px;
        height: 12px;
        display: flex;
        outline: 0;
        position: absolute;
        box-sizing: border-box;
        margin-top: -5px;
        transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        align-items: center;
        margin-left: -6px;
        border-radius: 50%;
        justify-content: center;
        background-color: currentColor;
      }
      .MuiSlider-thumb::after {
        top: -15px;
        left: -15px;
        right: -15px;
        bottom: -15px;
        content: "";
        position: absolute;
        border-radius: 50%;
      }
      .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
        box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
      }
      @media (hover: none) {
        .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
          box-shadow: none;
        }
      }
      .MuiSlider-mark {
        width: 2px;
        height: 2px;
        position: absolute;
        border-radius: 1px;
        background-color: currentColor;
      }
      .MuiSlider-markActive {
        opacity: 0.8;
        background-color: #fff;
      }
      .MuiSlider-markLabel {
        top: 26px;
        color: rgba(0, 0, 0, 0.54);
        position: absolute;
        font-size: 0.875rem;
        transform: translateX(-50%);
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.43;
        white-space: nowrap;
        letter-spacing: 0.01071em;
      }
      @media (pointer: coarse) {
        .MuiSlider-markLabel {
          top: 40px;
        }
      }
      .MuiSlider-markLabelActive {
        color: rgba(0, 0, 0, 0.87);
      }
      `}
      </style>
      <span
        ref={sliderRef as LegacyRef<HTMLSpanElement>}
        className="MuiSlider-root"
        onMouseDown={handleMouseDown}
        {...other}
      >
        <span className="MuiSlider-rail" />
        <span className="MuiSlider-track" style={trackStyle} />
        <input value={values.join(',')} type="hidden" />
        {values.map((value, index) => {
          const percent = valueToPercent(value, min, max);
          const style = axisProps.offset(percent);

          return (
            <span
              key={index}
              className="MuiSlider-thumb"
              tabIndex={0}
              // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
              role="slider"
              style={style}
              data-index={index}
              onKeyDown={handleKeyDown}
            />
          );
        })}
      </span>
    </>
  );
}

function useControlled({controlled, default: defaultProp}: any) {
  // isControlled is ignored in the hook dependency lists as it should never change.
  const {current: isControlled} = useRef(controlled !== undefined);
  const [valueState, setValue] = useState(defaultProp);
  const value = isControlled ? controlled : valueState;

  const setValueIfUncontrolled = useCallback((newValue: any) => {
    if (!isControlled) {
      setValue(newValue);
    }
  }, []);

  return [value, setValueIfUncontrolled];
}

function useEventCallback(fn: any) {
  const ref = useRef(fn);
  useLayoutEffect(() => {
    ref.current = fn;
  });
  return useCallback((...args: any) => (0, ref.current)(...args), []);
}
