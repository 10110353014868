import {Card, FormControl, FormField, isCurrency} from 'platform/components';
import {Grid, Heading, Show, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {useGetTenantQuery} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {AccountingDocumentFormValues} from '../types';

interface CreateInvoicePaymentDetailsProps extends TestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
}

export function PaymentDetailsDetail(props: CreateInvoicePaymentDetailsProps) {
  const {data: tenant} = useGetTenantQuery();

  const currency = isNotNil(tenant) && isCurrency(tenant?.currency) ? tenant?.currency : undefined;

  return (
    <Card title={i18n.t('entity.invoice.labels.paymentDetails')}>
      <VStack spacing={4}>
        <Grid columns={4}>
          <FormField
            name="paymentInfo.paymentMethod"
            control={props.control}
            type="text"
            label={i18n.t('entity.accounting.labels.paymentMethod')}
            isRequired
            isDisabled
            data-testid={suffixTestId('paymentMethod', props)}
          />
          <FormField
            name="paymentInfo.bankAccount"
            control={props.control}
            type="text"
            label={i18n.t('general.labels.bankAccount')}
            isRequired
            isDisabled
            data-testid={suffixTestId('bankAccount', props)}
          />
        </Grid>
        <Grid columns={4}>
          <FormField
            name="paymentInfo.variableSymbol"
            control={props.control}
            type="text"
            label={i18n.t('entity.accounting.labels.variableSymbol')}
            isDisabled
            data-testid={suffixTestId('variableSymbol', props)}
          />
          <FormField
            name="paymentInfo.constantSymbol"
            control={props.control}
            type="text"
            label={i18n.t('entity.accounting.labels.constantSymbol')}
            isDisabled
            data-testid={suffixTestId('constantSymbol', props)}
          />
          <FormField
            name="paymentInfo.specificSymbol"
            control={props.control}
            type="text"
            label={i18n.t('entity.accounting.labels.specificSymbol')}
            isDisabled
            data-testid={suffixTestId('specificSymbol', props)}
          />
        </Grid>
        <Card
          variant="inlineGrey"
          isExpandable
          isClosedByDefault
          title={i18n.t('entity.invoice.labels.additionalPaymentInformation')}
          data-testid={suffixTestId('additional-information', props)}
        >
          <VStack spacing={4}>
            <Grid columns={4}>
              <FormField
                name="paymentInfo.iban"
                control={props.control}
                type="text"
                label={i18n.t('entity.bank.labels.iban')}
                isDisabled
                data-testid={suffixTestId('iban', props)}
              />
              <FormField
                name="paymentInfo.swift"
                control={props.control}
                type="text"
                label={i18n.t('entity.bank.labels.swift')}
                isDisabled
                data-testid={suffixTestId('swift', props)}
              />
            </Grid>
            <Show whenFeatureEnabled={featureFlags.SALES_FOREIGN_CURRENCY}>
              <Heading size={4}>{i18n.t('general.labels.foreignCurrency')}</Heading>
              <Grid columns={4}>
                <FormField
                  name="exchangeRateRatio.currency"
                  control={props.control}
                  type="text"
                  label={i18n.t('general.labels.currency')}
                  data-testid={suffixTestId('foreign-currency-currency', props)}
                  isDisabled
                />
                <FormField
                  name="exchangeRateRatio.amount"
                  control={props.control}
                  type="number"
                  label={i18n.t('general.labels.amount')}
                  data-testid={suffixTestId('foreign-currency-amount', props)}
                  minStepperValue={0}
                  isDisabled
                />
                <FormField
                  name="exchangeRateRatio.ratio"
                  control={props.control}
                  type="currency"
                  label={i18n.t('general.labels.exchangeRate')}
                  currency={currency}
                  isDisabled
                  data-testid={suffixTestId('foreign-currency-ratio', props)}
                />
                <FormField
                  name="exchangeRateRatio.type"
                  control={props.control}
                  type="text"
                  label={i18n.t('general.labels.accountingType')}
                  isDisabled
                />
              </Grid>
            </Show>
          </VStack>
        </Card>
      </VStack>
    </Card>
  );
}
