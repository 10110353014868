import {defaultTo} from 'ramda';

import {PhoneNumber} from '../types/PhoneNumber';
import {Alpha3CountryCode} from './countryCodes';
import {getFormattedNumber} from './getFormattedNumber';

/**
 * @about Returns default inner value for phone number input.
 * @param innerCountry
 * @param value
 * @param isNational
 */
export const getDefaultInnerValue = (
  innerCountry: Alpha3CountryCode,
  value?: PhoneNumber,
  isNational?: true
): string =>
  defaultTo(
    '',
    value &&
      getFormattedNumber(
        `${defaultTo('', !isNational && value.prefix)}${defaultTo('', value.number)}`,
        innerCountry
      )
  );
