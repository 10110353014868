import {isArray} from 'ramda-adjunct';

/**
 * Returns the single element from an array if it contains exactly one element,
 * otherwise returns `undefined`. If the input is not an array, returns the input value itself.
 *
 * @param {unknown | unknown[]} value - The value to check. It can be either a single element or an array of elements.
 * @returns {unknown | undefined} - If the input is an array with one element, returns that element.
 *                                  If the array has more or less than one element, returns `undefined`.
 *                                  If the input is not an array, returns the input as is.
 */
export const getSingleOrUndefined = (value: unknown | unknown[]) => {
  if (isArray(value)) {
    return value.length === 1 ? value[0] : undefined;
  }

  return value;
};
