import {isNil} from 'ramda';

import {GetArticleResponse} from '@omnetic-dms/api';

import {Nullish} from 'shared';

import {ArticlePricingDetails} from '../types/ArticlePricingDetails';

export const getArticleDetailCardPrices = (
  article: GetArticleResponse | Nullish
): ArticlePricingDetails | null => {
  if (isNil(article)) {
    return null;
  }

  return {
    averagePurchasePriceWithoutVat: article?.pricesSettings?.avgPurchacePriceWithoutVat ?? 0,
    averagePurchasePriceWithVat: article?.pricesSettings?.avgPurchacePriceWithVat ?? 0,
    lastPurchasePriceWithoutVat: article?.pricesSettings?.lastPurchacePriceWithoutVat ?? 0,
    lastPurchasePriceWithVat: article?.pricesSettings?.lastPurchacePriceWithVat ?? 0,
    recommendedPriceWithoutVat: article?.pricesSettings?.recommendedPurchacePriceWithoutVat ?? 0,
    recommendedPriceWithVat: article?.pricesSettings?.recommendedPurchacePriceWithVat ?? 0,
    saleBasePriceWithoutVat: article?.dispensingPrices?.saleBasePriceWithoutVat ?? 0,
    saleBasePriceWithVat: article?.dispensingPrices?.saleBasePriceWithVat ?? 0,
    warrantyPriceWithoutVat: article?.dispensingPrices?.warrantyPriceWithoutVat ?? 0,
    warrantyPriceWithVat: article?.dispensingPrices?.warrantyPriceWithVat ?? 0,
  };
};
