import {match, Pattern} from 'ts-pattern';

import {always, not} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {Nullish, precisionCalculation} from 'shared';

/**
 * @param number Value to decrement.
 * @param min Minimum value.
 * @param step Decrement step. Default is 1.
 * @param shouldRoundStepsByDifference Calculates the next valid step based on the difference between
 *  the current value and the step value. Default is false.
 * @returns Decremented number, `min` if it is less than `min` or if `number` is null.
 */
export function getDecrementedNumber(
  number: number | Nullish,
  min: number | Nullish,
  step = 1,
  shouldRoundStepsByDifference = false
) {
  // Number(value) is used to handle cases where the number param is a string.
  const newValue = match([number, min])
    .with(
      [Pattern.union(Pattern.number, Pattern.string), Pattern.optional(Pattern.number)],
      ([value]) => {
        const currentValue = Number(value);

        if (not(shouldRoundStepsByDifference)) {
          return precisionCalculation.subtract(currentValue, step);
        }

        // Get the difference between current value and step value
        const difference = precisionCalculation.modulo(currentValue, step);
        const nextValidStep = difference === 0 ? step : difference;
        return precisionCalculation.subtract(currentValue, nextValidStep);
      }
    )
    .with([null, Pattern.number], ([, min]) => min)
    .otherwise(always(-step));

  return isNotNil(min) && newValue < min ? min : newValue;
}
