import {Text, VStack} from 'platform/foundation';
import {CurrencySymbolFormat, useCurrencySymbolFormatter} from 'platform/locale';

import i18n from '@omnetic-dms/i18n';

import {CurrencyCodeType, Nullish} from 'shared';

export type PriceColumnProps = {
  title: string;
  priceWithoutVat: number | Nullish;
  priceWithVat: number | Nullish;
  currency: string | Nullish;
};

export function PriceColumn(props: PriceColumnProps) {
  const currencyFormatter = useCurrencySymbolFormatter();

  return (
    <VStack>
      <Text size="xSmall" color="secondary">
        {props.title}
      </Text>
      <Text alternative size="large">
        {props.priceWithoutVat ?? '-'}&nbsp;
        <CurrencySymbolFormat currencyCode={props.currency as CurrencyCodeType} />
      </Text>
      <Text size="xSmall" color="secondary">
        {`${props.priceWithVat ?? '-'}
        ${currencyFormatter(props.currency as CurrencyCodeType)}
        ${i18n.t('general.labels.withVat')} `}
      </Text>
    </VStack>
  );
}
