import {showNotification, useDialog} from 'platform/components';
import {HStack, Link, Show} from 'platform/foundation';

import {useMemo, useState} from 'react';

import {defaultTo, difference, indexBy, pathOr, prop} from 'ramda';
import {isArray, isNotString} from 'ramda-adjunct';

import {
  EntityResourceIds,
  GetParticipationApiResponse,
  useAssignUserV2Mutation,
  useGetParticipationQuery,
  useGetUsersQuery,
  useTransferOwnershipToUserV2Mutation,
  useUnassignUserV2Mutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {getNaturalPersonFullName} from '../../utils/getNaturalPersonFullName';
import {handleApiError} from '../../utils/handleApiError';
import {NoPermissionTooltip} from '../NoPermissionTooltip/NoPermissionTooltip';
import {AssigneeButton} from './components/AssigneeButton';
import {EditUserDialog} from './components/EditUserDialog';
import {AssignmentType} from './types/AssignmentType';

interface AssigneeV2Props extends TestIdProps {
  resourceId: EntityResourceIds;
  recordId: string;
}

export function AssigneeV2(props: AssigneeV2Props) {
  const {data: participation, refetch: refetchParticipation} = useGetParticipationQuery(props);
  const {data: users} = useGetUsersQuery();

  const [transferOwnership] = useTransferOwnershipToUserV2Mutation();
  const [assignUser] = useAssignUserV2Mutation();
  const [unAssignUser] = useUnassignUserV2Mutation();

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      recordId: props.recordId,
      resourceId: EntityResourceIds.vehicle,
    },
    {skip: props.resourceId !== EntityResourceIds.vehicle}
  );

  const [canChangeVehicleOwner, canAssignVehicleUser, canUnassignVehicleUser] = usePermissions({
    permissionKeys: ['vehicleChangeOwner', 'vehicleAssignUser', 'vehicleUnassignUser'],
    scopes: {
      vehicleChangeOwner: vehicleParticipation,
      vehicleAssignUser: vehicleParticipation,
      vehicleUnassignUser: vehicleParticipation,
    },
  });

  const canChangeVehicleAssignee = canAssignVehicleUser && canUnassignVehicleUser;

  const usersById = useMemo(() => indexBy(prop('id'), users ?? []), [users]);

  const [assignmentType, setAssignmentType] = useState<AssignmentType | null>(null);
  const [isOpen, setOpen, setClose] = useDialog();
  const [isValidationError, setValidationErrorTrue, setValidationErrorFalse] = useBoolean();

  const handleOpenDialog = (type: keyof GetParticipationApiResponse) => {
    const ParticipationType = participation?.[type];
    const stateBody: AssignmentType = {
      value: isArray(ParticipationType)
        ? ParticipationType.map(({userId}) => userId)
        : [ParticipationType?.userId ?? ''],
      type,
    };

    setAssignmentType(stateBody);
    setOpen();
  };

  const saveAssignments = () => {
    if (!assignmentType) {
      return;
    }

    if (assignmentType.type === 'owner') {
      if (isNotString(assignmentType.value)) {
        setValidationErrorTrue();
        return;
      } else {
        transferOwnership({
          recordId: props.recordId,
          resourceId: props.resourceId,
          userIdToTransferOwnership: assignmentType.value as string,
        })
          .unwrap()
          .then(() => {
            showNotification.success();
          })
          .catch(handleApiError);
      }
    }

    setClose();

    if (assignmentType.type === 'assignees' && isArray(assignmentType.value)) {
      const legacyParticipationIds = participation?.assignees.map(({userId}) => userId) ?? [];

      const updates = difference(assignmentType.value, legacyParticipationIds);
      const removals = difference(legacyParticipationIds, assignmentType.value);

      const updatePromises = updates.map((userIdToAssign) =>
        assignUser({recordId: props.recordId, resourceId: props.resourceId, userIdToAssign})
      );
      const removalPromises = removals.map((userIdToUnassign) =>
        unAssignUser({recordId: props.recordId, resourceId: props.resourceId, userIdToUnassign})
      );

      Promise.all([...updatePromises, ...removalPromises])
        .then(() => {
          refetchParticipation();
          showNotification.success();
        })
        .catch(handleApiError);
    }
  };

  const owner = usersById[pathOr('', ['owner', 'userId'], participation)];

  const assigneesNames =
    participation?.assignees.map((assignee) =>
      //@ts-ignore UserResponseBody has corresponding attributes
      defaultTo('', getNaturalPersonFullName(usersById[assignee.userId]))
    ) ?? [];

  return (
    <HStack align="center" spacing={1}>
      <NoPermissionTooltip shouldShowTooltip={!canChangeVehicleOwner}>
        <Link
          size="small"
          onClick={() => handleOpenDialog('owner')}
          leftIcon="maps/person_pin"
          title={defaultTo(
            i18n.t('general.labels.participation.systemOwner'),
            //@ts-ignore UserResponseBody has corresponding attributes
            getNaturalPersonFullName(owner)
          )}
          isDisabled={!canChangeVehicleOwner}
          data-testid={suffixTestId('systemOwner', props)}
        />
      </NoPermissionTooltip>

      <AssigneeButton
        assigneesNames={assigneesNames}
        handleEdit={handleOpenDialog}
        isDisabled={!canChangeVehicleAssignee}
        data-testid={suffixTestId('assignees', props)}
      />

      <Show when={assignmentType}>
        <EditUserDialog
          isOpen={isOpen}
          onClose={setClose}
          onSave={saveAssignments}
          assignmentType={assignmentType!}
          updateAssignmentType={setAssignmentType}
          users={users}
          data-testid={suffixTestId('editUser', props)}
          deleteErroMessage={setValidationErrorFalse}
          isErrorMessage={isValidationError}
        />
      </Show>
    </HStack>
  );
}
