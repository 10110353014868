import {ReactElement} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

export const getHighlightedText = (text: string, highlight: string): ReactElement => {
  if (isNilOrEmpty(highlight)) {
    return <>{text}</>;
  }

  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <>
      {parts.map((part) => (
        <span
          key={part}
          style={part.toLowerCase() === highlight.toLowerCase() ? {fontWeight: 'bold'} : {}}
        >
          {part}
        </span>
      ))}
    </>
  );
};
