import {getDateValueFromDayMonth} from 'platform/locale';

import {isValidDate} from 'ramda-adjunct';

/**
 * Check if selected day is in selected month
 */
export const isDayInMonth = (day: number, month: number) => {
  const date = getDateValueFromDayMonth(month, day);

  return isValidDate(date);
};
