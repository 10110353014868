import {TabProps, Tabs} from 'platform/components';
import {VStack} from 'platform/foundation';

import {FC, ReactNode, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {vehiclesRoutes} from '@omnetic-dms/routes';

import {composePath, suffixTestId, TestIdProps} from 'shared';

import {useRouter} from '../../../hooks/useRouter';
import {
  selectActiveAuditId,
  selectNavigationCategories,
  selectStatesOfNavigationCategories,
} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {TConditionFormValue} from '../../../types/ConditionTypes';
import {getNameAccordingToLocale} from '../../../utils/getNameAccordingToLocale';
import {useConditionContext} from '../hooks/useConditionContext';
import {NavigationState} from '../types/NavigationState';
import {EProgressType} from '../types/ProgressType';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {getTabIcon} from '../utils/getTooltipState';
import {getUriFromCategoryKey} from '../utils/getUriFromCategoryKey';
import {CategoryCard} from './CategoryCard';
import {Documentation} from './Documentation';
import {Exterior} from './Exterior';
import {Features} from './Features';
import {Interior} from './Interior';
import {PhotoDocumentation} from './PhotoDocumentation';
import {TechnicalCondition} from './TechnicalCondition';
import {TestDrive} from './TestDrive';
import {WheelsAndTires} from './WheelsAndTires';

interface AuditTabsProps {
  createVehicleFeatures?: string[];
  onTabChange?: (id: string) => void;
  activeTab?: string;
  isInspection?: boolean;
}

export const AuditTabs: FC<AuditTabsProps & TestIdProps> = ({createVehicleFeatures, ...rest}) => {
  const router = useRouter();
  const auditId = useSelector(selectActiveAuditId);
  const navigationCategories = useSelector(selectNavigationCategories);
  const statesOfNavigationCategories = useSelector(selectStatesOfNavigationCategories);
  const locale = useSelector(selectUserSelectedLanguage);

  const {isCreateVehicle} = useConditionContext();
  const {id, inspectionTab, conditionTab, inspectionId} = router.params;

  const tab = conditionTab ?? inspectionTab ?? rest.activeTab;

  const [activeTabId, setActiveTabId] = useState<string | null>(null);

  const isInspection = Boolean(inspectionId) || rest.isInspection;

  const handleChangeTab = (tabId: string) => {
    setActiveTabId(tabId);
    const category = navigationCategories?.find((item) => item.uniqueKey === tabId);

    if (isCreateVehicle || isNil(category)) {
      return;
    }

    if (rest.onTabChange) {
      return rest.onTabChange(category.uniqueKey);
    }

    const tabUri = getUriFromCategoryKey(category.uniqueKey);
    const url = isInspection
      ? composePath(vehiclesRoutes.inspectionDetailTab, {
          params: {
            id: id as string,
            inspectionId: inspectionId as string,
            inspectionTab: tabUri,
          },
        })
      : composePath(vehiclesRoutes.conditionDetailTab, {
          params: {id: id as string, conditionTab: tabUri},
        });

    router.replace(url, {shallow: true});
  };

  const getTabPanelComponent = (index: number): ReactNode => {
    switch (navigationCategories?.[index]?.uniqueKey) {
      case AuditCategoryUniqueKey.VEHICLE_DOCUMENTATION:
        return (
          <Documentation
            data-testid={suffixTestId('vehicleDocumentation', rest)}
            data={navigationCategories[index]}
          />
        );
      case AuditCategoryUniqueKey.TECHNICAL_CONDITION:
      case AuditCategoryUniqueKey.MECHANICS:
        return (
          <TechnicalCondition
            data-testid={suffixTestId(
              navigationCategories?.[index]?.uniqueKey ===
                AuditCategoryUniqueKey.TECHNICAL_CONDITION
                ? 'technicalCondition'
                : 'mechanics',
              rest
            )}
            data={navigationCategories[index]}
          />
        );
      case AuditCategoryUniqueKey.EQUIPMENT:
        return (
          <Features
            data-testid={suffixTestId('equipment', rest)}
            data={navigationCategories[index]}
            vehicleFeatures={createVehicleFeatures}
          />
        );
      case AuditCategoryUniqueKey.WHEELS_AND_TIRES:
        return (
          <WheelsAndTires
            data-testid={suffixTestId('wheelsAndTires', rest)}
            data={navigationCategories[index]}
          />
        );
      case AuditCategoryUniqueKey.EXTERIOR:
        return (
          <Exterior
            data-testid={suffixTestId('exterior', rest)}
            data={navigationCategories[index]}
          />
        );
      case AuditCategoryUniqueKey.INTERIOR:
        return (
          <Interior
            data-testid={suffixTestId('interior', rest)}
            data={navigationCategories[index]}
          />
        );
      case AuditCategoryUniqueKey.TESTDRIVE:
        return <TestDrive data-testid={suffixTestId('testDrive', rest)} />;
      case AuditCategoryUniqueKey.PHOTODOCUMENTATION:
        return <PhotoDocumentation data-testid={suffixTestId('photosDocumentation', rest)} />;
      default:
        return (
          <VStack spacing={3}>
            {navigationCategories?.[index]?.childCategories?.map((category) => (
              <CategoryCard
                data-testid={suffixTestId(category.id, rest)}
                key={`categoryCard-${category.id}-${category.name}`}
                data={category}
                cardVariant="inlineGrey"
                photoGrid={8}
              />
            ))}
          </VStack>
        );
    }
  };

  const isCondition = !isInspection || isCreateVehicle;

  const tabs: TabProps[] = (navigationCategories ?? []).map((category, index) => {
    const categoryState = getCategoryState(statesOfNavigationCategories[category.id]);
    const icon = getTabIcon(categoryState, isCondition);

    return {
      id: category.uniqueKey,
      title: getNameAccordingToLocale(locale, category.name),
      icon,
      content: getTabPanelComponent(index),
      'data-testid': suffixTestId(category.uniqueKey, rest),
    };
  });

  useEffect(() => {
    // Wait for actual audit to be loaded (ID from redux has to be the same as the one in the URL)
    if (auditId !== inspectionId) {
      return;
    }

    const firstCategory = navigationCategories?.[0];
    if (!firstCategory || tab !== '_') {
      return;
    }

    const tabUri = getUriFromCategoryKey(firstCategory.uniqueKey);
    const url = isInspection
      ? composePath(vehiclesRoutes.inspectionDetailTab, {
          params: {
            id: id as string,
            inspectionId: inspectionId as string,
            inspectionTab: tabUri,
          },
        })
      : composePath(vehiclesRoutes.conditionDetailTab, {
          params: {id: id as string, conditionTab: tabUri},
        });

    router.replace(url, {shallow: true});
  }, [auditId, id, inspectionId, isInspection, navigationCategories, router, tab]);

  if (isNilOrEmpty(tabs)) {
    return null;
  }

  return (
    <Tabs
      isLazy
      lazyBehavior="unmount"
      variant="condensed"
      onChange={handleChangeTab}
      tabs={tabs}
      activeTabId={activeTabId ?? tabs[0].id}
      data-testid={rest['data-testid']}
      shouldWrap
    />
  );
};

const getCategoryState = (stateValue?: TConditionFormValue): EProgressType => {
  if (
    stateValue === NavigationState.notFinishedError ||
    stateValue === NavigationState.notStartedError
  ) {
    return EProgressType.error;
  }

  if (typeof stateValue === 'string' && stateValue?.split('-')?.[0] === NavigationState.finished) {
    return EProgressType.success;
  }

  return EProgressType.progress;
};
