import styled from 'styled-components';

import {TextFieldWithValueFormat} from '../TextFieldWithValueFormat/TextFieldWithValueFormat';
import {MUISlider} from './MUISlider';

export const TeasRangeSlider = styled(MUISlider)`
  height: 4px;
  color: #3f51b5;
  width: 100%;
  cursor: pointer;
  display: inline-block;
  padding: 13px 0;
  position: relative;
  box-sizing: content-box;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  & .MuiSlider-rail {
    width: 100%;
    display: block;
    position: absolute;
    background-color: currentColor;
    border-radius: 2px;
    color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
    height: 4px;
    opacity: 1;
  }

  & .MuiSlider-track {
    color: ${({theme}) => theme.colors.palettes.blue[60][100]};
    height: 4px;
  }

  & .MuiSlider-markLabel {
    color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
    font-size: 14px;
  }

  & .MuiSlider-thumb {
    color: ${({theme}) => theme.colors.palettes.blue[60][100]};
    height: 16px;
    margin-top: -6px;
    width: 16px;

    &:hover {
      box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[30][100]};
    }
  }
`;

export const InputsWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: -6px;
  width: 100%;
`;

export const TextFieldFormatWithRightAlign = styled(TextFieldWithValueFormat)`
  input {
    text-align: right;
  }
`;
