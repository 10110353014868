import {
  Button,
  ButtonGroup,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  closeCurrentDialog,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, Space, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {UtmParameters, useUpdateInterestUtmMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface UTMFormProps extends RequiredTestIdProps {
  interestId: string;
  parameters: UtmParameters;
}

export const UTMForm = (props: UTMFormProps) => {
  const [updateInterestUtm, {isLoading: isUpdateInterestUtmLoading}] =
    useUpdateInterestUtmMutation();

  const handleSubmit: FormSubmitHandler<UtmParameters> = (values) =>
    updateInterestUtm({
      id: props.interestId,
      updateInterestUtmRequestBody: values,
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
        closeCurrentDialog();
      })
      .catch(handleApiError);

  return (
    <Form
      schema={schema}
      onSubmit={handleSubmit}
      defaultValues={props.parameters}
      data-testid={props['data-testid']}
    >
      {(control) => (
        <VStack spacing={4}>
          <Grid columns={2} spacing={4}>
            <GridItem>
              <FormField
                control={control}
                type="text"
                name="source"
                label={i18n.t('entity.interest.utm.source')}
                data-testid={suffixTestId('utm_source', props)}
              />
              <Space vertical={2} />
              <FormField
                control={control}
                type="text"
                name="campaign"
                label={i18n.t('entity.interest.utm.campaign')}
                data-testid={suffixTestId('utm_campaign', props)}
              />
              <Space vertical={2} />
              <FormField
                control={control}
                type="text"
                name="content"
                label={i18n.t('entity.interest.utm.content')}
                data-testid={suffixTestId('utm_content', props)}
              />
            </GridItem>
            <GridItem>
              <FormField
                control={control}
                type="text"
                name="medium"
                label={i18n.t('entity.interest.utm.medium')}
                data-testid={suffixTestId('utm_medium', props)}
              />
              <Space vertical={2} />
              <FormField
                control={control}
                type="text"
                name="term"
                label={i18n.t('entity.interest.utm.term')}
                data-testid={suffixTestId('utm_term', props)}
              />
            </GridItem>
          </Grid>
          <DialogFooter>
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={closeCurrentDialog}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('actions.discard', props)}
              />
              <FormButton
                control={control}
                type="submit"
                isLoading={isUpdateInterestUtmLoading}
                variant="primary"
                title={i18n.t('general.actions.saveChanges')}
                data-testid={suffixTestId('actions.submit', props)}
              />
            </ButtonGroup>
          </DialogFooter>
        </VStack>
      )}
    </Form>
  );
};

const schema = Yup.object({
  source: Yup.string().nullable().defined().max(255),
  medium: Yup.string().nullable().defined().max(255),
  campaign: Yup.string().nullable().defined().max(255),
  term: Yup.string().nullable().defined().max(255),
  content: Yup.string().nullable().defined().max(255),
} satisfies Record<keyof UtmParameters, unknown>);
