import type {BasicStructure} from '../types/BasicStructure';
import type {CancelablePromise} from '../types/CancelablePromise';
import type {CreateTypeOfControlSettings} from '../types/CreateTypeOfControlSettings';
import type {CreateTypeOfControlSettingsRequestBody} from '../types/CreateTypeOfControlSettingsRequestBody';
import type {Template} from '../types/Template';
import {request as __request} from '../utils/request';

export class CarAuditStructureSettingsService {
  /**
   * Get basic structure for settings.
   *
   * @returns BasicStructure
   * @throws ApiError
   */
  public static getBasicStructureForSettings({
    templateId,
    inspectionType,
    authorization,
  }: {
    templateId?: string;
    inspectionType?: 'vehicleCondition' | 'validationInspection' | 'nonValidationInspection';
    authorization?: string;
  }): CancelablePromise<Array<BasicStructure>> {
    return __request({
      method: 'GET',
      url: '/car-audit/v1/audit-structure-settings/structure',
      query: {
        templateId,
        inspectionType,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * Update Structure Settings
   *
   * @returns CreateTypeOfControlSettings
   * @throws ApiError
   */
  public static createTypeOfControlSettings({
    requestBody,
    authorization,
  }: {
    requestBody?: CreateTypeOfControlSettingsRequestBody;
    authorization?: string;
  }): CancelablePromise<CreateTypeOfControlSettings> {
    return __request({
      method: 'POST',
      url: '/car-audit/v1/audit-structure-settings',
      body: requestBody,
      headers: {
        Authorization: authorization,
      },
      mediaType: 'application/json',
    });
  }

  /**
   * Get All Templates
   *
   * @returns Template
   * @throws ApiError
   */
  public static getAllTemplates({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Array<Template>> {
    return __request({
      method: 'GET',
      url: '/car-audit/v1/audit-structure-templates',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * Get Structure Settings
   *
   * @returns CreateTypeOfControlSettings
   * @throws ApiError
   */
  public static getTypeOfControlSettings({
    typeOfControlId,
    authorization,
  }: {
    typeOfControlId: string;
    authorization?: string;
  }): CancelablePromise<CreateTypeOfControlSettings> {
    return __request({
      method: 'GET',
      url: '/car-audit/v1/audit-structure-settings/{typeOfControlId}',
      path: {
        typeOfControlId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
}
