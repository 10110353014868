import {useEffect, useRef} from 'react';

type FunctionArgument = () => void;

export const useOnMount = (func?: FunctionArgument) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current === false) {
      func?.();
      didMountRef.current = true;
    }

    return () => {
      didMountRef.current = false;
    };
  }, []);
};
