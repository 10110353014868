import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {FullSchema} from '../types/FullSchema';
import {Schema} from '../types/Schema';
import {isPhoneNumber} from './isPhoneNumber';

export const getFieldRules = (schema: FullSchema | Schema) => {
  const definitionRules = (schema as FullSchema)?.rules;
  const {required, ...rules} = definitionRules ?? {};

  const isRequiredPresent = required?.value ?? false;
  const requiredErrorMessage = required?.message;

  if (isNilOrEmpty(definitionRules)) {
    return {};
  }

  if (!isRequiredPresent) {
    return definitionRules;
  }

  const isValueInvalid = (value: unknown) =>
    match(schema.renderer)
      .when(() => isNilOrEmpty(value), always(requiredErrorMessage))
      .with('phone', () => {
        if (!isPhoneNumber(value) || (isNilOrEmpty(value.number) && isNilOrEmpty(value.prefix))) {
          return requiredErrorMessage;
        }
      })
      .otherwise(always(undefined));

  return {
    ...rules,
    validate: isValueInvalid,
  };
};
