import {Card, DataStatus, Table, TableRow} from 'platform/components';
import {Box, Link, Text} from 'platform/foundation';
import {DateTimeFormat} from 'platform/locale';

import {TestDriveResponseBody, useListTestDriveQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, customerRoutes} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {composePath, parseDate, useRequiredParams} from 'shared';

export function TestDrives() {
  const {id: vehicleId} = useRequiredParams();
  const {
    data: testDrives,
    isLoading,
    isError,
  } = useListTestDriveQuery({
    vehicleId,
  });

  return (
    <Main>
      <Card title={i18n.t('entity.testDrive.labels.testDrives')}>
        <DataStatus
          isLoading={isLoading}
          isError={isError}
          isEmpty={!Boolean(testDrives?.length)}
          emptyMessage={i18n.t('entity.testDrive.labels.testDrivesEmpty')}
        >
          <Table>
            <TableRow>
              <Box paddingVertical={2} paddingHorizontal={3}>
                <Text alternative>{i18n.t('general.labels.date')}</Text>
              </Box>
              <Box paddingVertical={2} paddingHorizontal={3}>
                <Text alternative>{i18n.t('module.businessCase.title')}</Text>
              </Box>
              <Box paddingVertical={2} paddingHorizontal={3}>
                <Text alternative>{i18n.t('module.customer.title')}</Text>
              </Box>
              <Box paddingVertical={2} paddingHorizontal={3}>
                <Text alternative>{i18n.t('general.labels.employee')}</Text>
              </Box>
              <Box paddingVertical={2} paddingHorizontal={3}>
                <Text alternative>{i18n.t('general.labels.note')}</Text>
              </Box>
            </TableRow>

            {(testDrives as TestDriveResponseBody[])?.map((testDrive) => (
              <TableRow key={testDrive.id}>
                <Box paddingVertical={2} paddingHorizontal={4}>
                  <Text>
                    <DateTimeFormat
                      value={parseDate(testDrive.scheduledAt)}
                      format="dateTimeMedium"
                    />
                  </Text>
                </Box>
                <Box paddingVertical={2} paddingHorizontal={4}>
                  <Link
                    href={composePath(businessCaseRoutes.overview, {
                      params: {
                        id: testDrive.businessCaseId,
                      },
                    })}
                    leftIcon="action/open_in_new"
                    title={testDrive.businessCaseCode}
                  />
                </Box>
                <Box paddingVertical={2} paddingHorizontal={4}>
                  <Link
                    href={composePath(customerRoutes.detail, {
                      params: {
                        id: testDrive.customerId,
                      },
                    })}
                    leftIcon="action/open_in_new"
                    title={`${testDrive.customerFoundingPersonFirstName} ${testDrive.customerFoundingPersonLastName}`}
                  />
                </Box>
                <Box paddingVertical={2} paddingHorizontal={4}>
                  <Text>
                    {testDrive.userFirstName} {testDrive.userLastName}
                  </Text>
                </Box>
                <Box maxWidth={120} paddingVertical={2} paddingHorizontal={4}>
                  <Text noWrap>{testDrive.note}</Text>
                </Box>
              </TableRow>
            ))}
          </Table>
        </DataStatus>
      </Card>
    </Main>
  );
}
