import {TConditionForm} from '../../../types/ConditionTypes';
import {getFormFieldName} from './getFormFieldName';

export const getInitialFormValues = (
  auditFieldsData?: TConditionForm, //todo unify types
  categoryStateParams?: Record<string, string>
): TConditionForm | undefined => {
  if (!auditFieldsData) {
    return undefined;
  }

  const initialValues: TConditionForm = {...(auditFieldsData ?? {})};

  Object.entries(categoryStateParams ?? {}).forEach(([categoryId, paramDefinitionId]) => {
    const name = getFormFieldName(categoryId, paramDefinitionId);
    if (!Boolean(initialValues?.[name])) {
      return;
    }

    delete initialValues[name];
  });

  return initialValues;
};
