import {NumberFormatter} from 'platform/locale';

import i18n from '@omnetic-dms/i18n';
import {Translation} from '@omnetic-dms/teas';

interface SecondLineDetail {
  power?: number | null;
  transmission?: Translation | string | null;
  driveType?: Translation | string | null;
  fuelType?: Translation | string | null;
  mileage?: number | string | null;
}

export const detailSecondLine = (
  formatNumber: NumberFormatter,
  {power, transmission, driveType, fuelType, mileage}: SecondLineDetail = {}
) => {
  const metricKM = i18n.t('general.metric.km');
  const metricKW = i18n.t('general.metric.kW');

  return [
    power != null ? `${power} ${metricKW}` : null,
    (typeof transmission === 'string' ? transmission : transmission?.translation) || null,
    (typeof driveType === 'string' ? driveType : driveType?.translation) || null,
    (typeof fuelType === 'string' ? fuelType : fuelType?.translation) || null,
    mileage != null
      ? typeof mileage === 'string'
        ? `${mileage} ${metricKM}`
        : `${formatNumber(mileage ?? 0, 0)} ${metricKM}`
      : null,
  ].filter(Boolean) as Array<string>;
};
