import {DataStatus, DeleteDialog, Dropdown, DropdownItem, IconButton} from 'platform/components';
import {Box, Hide, HStack, Icon, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import styled from 'styled-components';

import {useCallback, useRef, useState} from 'react';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {TextField, LegacyComment} from '@omnetic-dms/teas';

import {parseDate} from 'shared';

export interface CommentItemProps {
  comment: LegacyComment;
  onDelete: (comment: LegacyComment) => void;
  onEdit: (comment: LegacyComment, newMessage: string) => void;
}

export function CommentItem({comment, onDelete, onEdit}: CommentItemProps) {
  const formatDateTime = useDateTimeFormatter();
  const inputRef = useRef<HTMLInputElement>(null);

  const [editMode, toggleEditMode] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const closeConfirmModal = useCallback(() => setIsConfirmModalOpen(false), []);

  const handleEdit = useCallback(
    (newMessage: string) => {
      onEdit && onEdit(comment, newMessage);
      toggleEditMode(false);
    },
    [comment, onEdit]
  );

  const handleOnEditMode = useCallback(() => {
    toggleEditMode(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, []);

  return (
    <>
      <DataStatus isLoading={!comment.uuid}>
        <HStack justify="space-between" spacing={2}>
          <HStack spacing={2}>
            <Box>
              <Avatar>
                <Icon value="social/person" size={10} />
              </Avatar>
            </Box>
            <VStack>
              <Text alternative>
                {comment?.author?.firstName} {comment?.author?.lastName}
              </Text>
              <Text size="small" color="secondary">
                {comment.createdAt
                  ? formatDateTime('dateTimeLong', parseDate(comment.createdAt))
                  : null}
              </Text>
              {editMode ? (
                <TextField
                  ref={inputRef}
                  defaultValue={comment.message}
                  onBlur={(e) => handleEdit(e.target.value)}
                />
              ) : (
                <Text size="small" color="secondary">
                  {comment.message}
                </Text>
              )}
            </VStack>
          </HStack>
          <Hide when={editMode}>
            <Dropdown
              dropdownControl={
                <IconButton
                  icon="navigation/more_vert"
                  data-testid={testIds.sourcing.vehicleDetail('commentItem-more-dropdownIcon')}
                />
              }
              data-testid={testIds.sourcing.vehicleDetail('commentItem-more')}
            >
              <DropdownItem
                onClick={handleOnEditMode}
                label={i18n.t('general.actions.edit')}
                leftIcon="image/edit"
              />
              <DropdownItem
                onClick={() => setIsConfirmModalOpen(true)}
                label={i18n.t('general.actions.delete')}
                leftIcon="action/delete"
              />
            </Dropdown>
          </Hide>
        </HStack>
      </DataStatus>

      <DeleteDialog
        isOpen={isConfirmModalOpen}
        data-testid={`commentItem-${comment.uuid}`}
        text={i18n.t('page.comments.notifications.deleteComment')}
        onConfirm={() => {
          onDelete(comment);
          closeConfirmModal();
        }}
        onClose={closeConfirmModal}
      />
    </>
  );
}

const Avatar = styled.div`
  background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
