import {createSelector, Selector} from '@reduxjs/toolkit';

import {AssignmentIdentity} from '../../types/AssignmentIdentity';
import type {TeasState} from '../../types/TeasState';
import {AssignmentState} from './types';

export const selectAssignmentState: Selector<TeasState, AssignmentState> = (state) =>
  state.assignment;

export const selectExternalUserLoading = createSelector(
  selectAssignmentState,
  (state) => state.externalUser.loading
);

export const selectAssignments = createSelector(
  selectAssignmentState,
  (state) => state?.assignments
);

export const getListOfAssignments = (type: AssignmentIdentity['type'], id: string) =>
  createSelector(selectAssignments, (assignments) => assignments?.[type]?.[id]);
