import {captureException} from '@sentry/browser';
import styled from 'styled-components';

import {MouseEventHandler, ReactNode, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {isNotNil} from 'ramda-adjunct';

import {TestIdProps, isURLDangerous} from 'shared';

const Button = styled.button`
  display: flex;
  align-items: center;
  border: none;
  color: ${({theme}) => theme.colors.palettes.blue[60][100]};
  cursor: pointer;
  outline: none;
  font-weight: normal;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  line-height: 16px;
  text-decoration: none;
  background: transparent;
  appearance: none !important;

  & > svg {
    margin-right: ${({theme}) => theme.getSize(1)};
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  font-size: ${({theme}) => theme.fontSizes.text.base};
  margin-right: ${({theme}) => theme.getSize(1)};
`;

interface LinkButtonProps extends TestIdProps {
  href?: string;
  Icon?: ReactNode;
  onClick?: MouseEventHandler;
  children?: ReactNode;
}
/**
 * @deprecated - use platform instead
 */
export function LinkButton(props: LinkButtonProps) {
  useEffect(() => {
    if (isURLDangerous(props.href)) {
      captureException(new Error(`Link component got potentially dangerous URL: ${props.href}`));
      throw new Error('Invalid URL');
    }
  }, [props.href]);

  return props.href ? (
    <Link to={props.href} data-testid={`${props['data-testid']}-link`}>
      <Button as="button" type="button" data-testid={props['data-testid']} onClick={props.onClick}>
        <>
          {isNotNil(props.Icon) && <IconWrapper>{props.Icon}</IconWrapper>}
          {props.children}
        </>
      </Button>
    </Link>
  ) : (
    <Button type="button" data-testid={props['data-testid']} onClick={props.onClick}>
      <>
        {isNotNil(props.Icon) && <IconWrapper>{props.Icon}</IconWrapper>}
        {props.children}
      </>
    </Button>
  );
}
