import validatePhone from 'phone';
import {array, bool, mixed, object, SchemaOf, string, StringSchema} from 'yup';

import i18n from '@omnetic-dms/i18n';

import {CommonMileageAttribute} from '../types/CommonMileageAttribute';
import {$Money} from './$Money';

export const titleExtensionValidation = (): StringSchema =>
  string()
    .transform((value) => value.replace(whitespaceRegex, ' '))
    .max(30, i18n.t('general.notifications.titleExtensionMax'));

export const mileageValidation = (): SchemaOf<CommonMileageAttribute['value']> =>
  object({
    unit: mixed().oneOf(['km']),
    value: mixed()
      .required(i18n.t('general.validations.fieldIsRequired'))
      .test(
        'integer',
        i18n.t('general.notifications.mileageInteger'),
        (value) => !value || Number.isInteger(Number(value))
      )
      .test(
        'positive',
        i18n.t('general.notifications.mileagePositive'),
        (value) => !value || value > 0
      )
      .test(
        'max',
        i18n.t('general.notifications.numberMaxValidationMessage'),
        (value) => !value || value <= 999999999
      )
      .test(
        'typeError',
        i18n.t('general.notifications.numberValidationMessage'),
        (value) => !value || !isNaN(value)
      ),
  });

export const priceValidation = () =>
  object({
    vatDeductible: bool(),
    withVat: bool().defined(),
    vatRate: string().nullable(),
    value: $Money(undefined, true),
    vatCode: string(),
  });

export const financingPriceValidation = () =>
  object({
    vatDeductible: bool(),
    withVat: bool().defined(),
    vatRate: string().nullable(),
    value: $Money(undefined, true),
    vatCode: string(),
  });

const vinRegex = /^[A-Ha-hJ-Nj-nPpR-Zr-z0-9]{17}$/;

export const vinValidation = (): StringSchema =>
  string()
    .label('VIN')
    .defined()
    .required(i18n.t('general.notifications.vinValidationMessage'))
    .min(17, i18n.t('general.notifications.vinMin'))
    .max(17, i18n.t('general.notifications.vinMax'))
    .transform((value) => value.toUpperCase())
    .transform((value) => value.replace(whitespaceRegex, ' '))
    .matches(vinRegex, {message: i18n.t('general.notifications.vinValidationMessage')});

export const $AdvertisingAttributeRequestBody = object({
  code: mixed().oneOf([
    'title_extension',
    'vin',
    'mileage',
    'price',
    'description',
    'price_note',
    'model_info',
    'contact_email',
    'contact_phone',
    'financing_price',
    'url',
    'condition',
    'version',
    'generation',
    'digital_certificate',
    'video_url',
  ]),
  type: mixed().oneOf([
    'string',
    'mileage',
    'price',
    'text',
    'email',
    'phone',
    'financing_price',
    'price_note',
    'condition',
    'digital_certificate',
  ]),
  title: string(),
  value: mixed()
    .label('Field')
    .when('override', {
      is: true,
      then: mixed()
        .when('code', {
          is: 'mileage',
          then: mileageValidation().defined().nullable(),
          otherwise: mixed().when('code', {
            is: 'price',
            then: priceValidation().nullable(),
            otherwise: mixed().when('code', {
              is: 'vin',
              then: vinValidation().nullable(),
              otherwise: mixed().when('code', {
                is: 'title_extension',
                then: titleExtensionValidation(),
                otherwise: mixed().when('code', {
                  is: 'contact_phone',
                  then: mixed()
                    .test('isPhone', i18n.t('general.validations.invalidPhoneNumber'), valid)
                    .test(
                      'required',
                      i18n.t('general.notifications.errorFieldRequired'),
                      (value: any) => !(!value || !value.number || value.number.length === 0)
                    )
                    .nullable(),
                  otherwise: mixed().when('code', {
                    is: 'contact_email',
                    then: string().email().required(i18n.t('general.validations.fieldIsRequired')),
                    otherwise: mixed().when('code', {
                      is: 'financing_price',
                      then: financingPriceValidation().nullable(),
                      otherwise: mixed().when('code', {
                        is: 'url',
                        then: string().url().nullable(),
                        otherwise: string().nullable(),
                      }),
                    }),
                  }),
                }),
              }),
            }),
          }),
        })
        .nullable(),
      otherwise: mixed(),
    }),
  displayValue: mixed().nullable().defined(),
  extra: mixed().nullable().defined(),
  required: bool(),
  override: bool().required(),
  hasPublishControl: bool(),
  publish: bool().required(),
}).defined();

export const $AdvertisingSettingsRequestBody = object({
  attributes: array().defined().required().of($AdvertisingAttributeRequestBody.defined()),
});

function valid(value: PhoneNumber | null) {
  if (value === null) {
    return false;
  }
  if (!value || !value.number || value?.number.length === 0) {
    return true;
  }
  return !!validatePhone(value.number, {
    country: value.countryCode,
    validateMobilePrefix: false,
  }).isValid;
}

type PhoneNumber = {
  number: string;
  countryCode: string;
  prefix: string;
};

const whitespaceRegex = /\s+/g;
