import {addDays, differenceInCalendarDays, subDays} from 'date-fns';

import {startTransition} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {isNil, isNotNil} from 'ramda';

import {getApiDateString, parseDate} from 'shared';

import {FormValuesType} from '../types/FormValuesType';

export const getDepositPaymentFormHandlers = (formApi: UseFormReturn<FormValuesType>) => {
  const handleOnIssueDateChange = (value: string | null) =>
    startTransition(() => {
      const {issuedDate, dueDate, dueSinceIssueDateInDays} = formApi.getValues();

      if (isNil(value)) {
        return;
      }

      if (isNotNil(dueSinceIssueDateInDays)) {
        formApi.setValue(
          'dueDate',
          getApiDateString(addDays(parseDate(value), dueSinceIssueDateInDays))
        );
      } else if (isNotNil(issuedDate)) {
        formApi.setValue(
          'dueSinceIssueDateInDays',
          differenceInCalendarDays(parseDate(value), parseDate(dueDate))
        );
      }
    });

  const handleOnDueDateChange = (value: string | null) =>
    startTransition(() => {
      const {issuedDate, dueSinceIssueDateInDays} = formApi.getValues();

      if (isNil(value)) {
        return;
      }

      if (isNotNil(dueSinceIssueDateInDays)) {
        formApi.setValue(
          'issuedDate',
          getApiDateString(subDays(parseDate(value), dueSinceIssueDateInDays))
        );
      } else if (isNotNil(issuedDate)) {
        formApi.setValue(
          'dueSinceIssueDateInDays',
          differenceInCalendarDays(parseDate(issuedDate), parseDate(value))
        );
      }
    });

  const handleOnDueChange = (value: number | null) =>
    startTransition(() => {
      const {issuedDate, dueDate} = formApi.getValues();

      if (isNil(value)) {
        return;
      }

      if (isNil(issuedDate) && isNotNil(dueDate)) {
        formApi.setValue('issuedDate', getApiDateString(subDays(parseDate(dueDate), value)));
      } else if (isNotNil(issuedDate)) {
        formApi.setValue('dueDate', getApiDateString(addDays(parseDate(issuedDate), value)));
      }
    });

  return {handleOnIssueDateChange, handleOnDueChange, handleOnDueDateChange};
};
