import {ListPermissionsV2ApiResponse} from '@omnetic-dms/api';

export const getGrantedProtectedUnits = (permissions: ListPermissionsV2ApiResponse | undefined) =>
  (permissions ?? []).reduce(
    (acc, permission) => ({
      ...acc,
      [permission.protectedUnit.id]: {
        value: true,
        scopes: permission.scopes,
      },
    }),
    {}
  );
