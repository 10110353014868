import {isEmpty, isNil} from 'ramda';
import {isDate, isNumber} from 'ramda-adjunct';

import {APIDate} from '../types/APIDate';
import {Nullish} from '../types/Nullish';

export function isApiDateString(
  value: string | number | APIDate | Nullish | Date
): value is APIDate {
  if (isNil(value) || isEmpty(value)) {
    return false;
  }
  if (isNumber(value)) {
    return false;
  }
  if (isDate(value)) {
    return false;
  }
  if (value.length !== API_DATE_LENGTH) {
    return false;
  }

  return API_DATE_REGEX.test(value);
}

const API_DATE_LENGTH = 10;
const API_DATE_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
