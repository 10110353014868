import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import {useDeleteSupplierOrderVariantMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, noop, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function WarehouseSupplierOrderVariantsList() {
  const navigate = useNavigate();

  const [deleteSupplierOrderVariant] = useDeleteSupplierOrderVariantMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    match(actionKey)
      .with('redirectDetail', 'edit', () => {
        const singleRowId = isArray(rowId) ? rowId[0] : rowId;
        navigate(
          composePath(settingsRoutes.warehousesSupplierOrderVariantsDetail, {
            params: {id: singleRowId},
          })
        );
      })
      .with('delete', () => {
        const rowIds = isArray(rowId) ? rowId : [rowId];
        openDeleteDialog({
          text: i18n.t('entity.warehouse.labels.deleteSelectedItemsPrompt'),
          onConfirm: () => {
            deleteSupplierOrderVariant({body: {orderVariantId: rowIds}})
              .unwrap()
              .then(() =>
                showNotification.success(
                  i18n.t('entity.warehouse.notifications.supplierOrderVariantDeleted')
                )
              )
              .then(refreshData)
              .catch(handleApiError);
          },
        });
      })
      .otherwise(noop);
  };

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.warehouse.labels.warehouseSupplierOrderVariants'),
    actions: [
      {
        type: 'button',
        title: i18n.t('entity.warehouse.actions.newWarehouseSupplierOrderVariant'),
        onClick: () => navigate(settingsRoutes.warehousesSupplierOrderVariantsCreate),
      },
    ],
  };

  return (
    <SettingsTemplate
      header={header}
      data-testid={testIds.settings.warehousesSupplierOrderVariants('template')}
    >
      <DataGrid
        gridCode="warehouse-supplier-order-variants"
        actionCallback={actionCallback}
        data-testid={testIds.settings.warehousesSupplierOrderVariants('supplier-order-variants')}
      />
    </SettingsTemplate>
  );
}
