import {CarAuditFileStorageService} from '../../services/CarAuditFileStorageService';
import {CarAuditStructureService} from '../../services/CarAuditStructureService';
import {CarAuditStructureSettingsService} from '../../services/CarAuditStructureSettingsService';
import {CarAuditVehicleAuditAssetService} from '../../services/CarAuditVehicleAuditAssetService';
import {CarAuditVehicleAuditService} from '../../services/CarAuditVehicleAuditService';
import {DocumentService} from '../../services/DocumentService';
import {GetTemplatesService} from '../../services/GetTemplatesService';
import {SpecificallyAuditDataItem} from '../../types/SpecificallyAuditDataItem';
import {asyncThunkAction} from '../../utils/reduxThunkUtils';
import {
  EXTERIOR_CONDITION_PARAM_DEFINITION,
  INTERIOR_CONDITION_PARAM_DEFINITION,
  MECHANICAL_CONDITION_PARAM_DEFINITION,
} from './constants';

export const getComplaintInspections = asyncThunkAction(
  'getComplaintInspections',
  CarAuditVehicleAuditService.loadAuditDataByVehicleId
);

export const getValidationInspections = asyncThunkAction(
  'getValidationInspections',
  CarAuditVehicleAuditService.loadAuditDataByVehicleId
);

export const getNonValidationInspections = asyncThunkAction(
  'getNonValidationInspections',
  CarAuditVehicleAuditService.loadAuditDataByVehicleId
);

export const getCheckinInspections = asyncThunkAction(
  'getCheckinInspections',
  CarAuditVehicleAuditService.loadAuditDataByVehicleId
);

export const getHandoverInspections = asyncThunkAction(
  'getHandoverInspections',
  CarAuditVehicleAuditService.loadAuditDataByVehicleId
);

export const getCondition = asyncThunkAction(
  'getCondition',
  CarAuditVehicleAuditService.loadAuditDataByVehicleId
);

export const getAudit = asyncThunkAction(
  'getAudit',
  CarAuditVehicleAuditService.loadAuditDataByAuditId
);
export const getActualAuditData = asyncThunkAction(
  'getActualAuditData',
  CarAuditVehicleAuditService.loadAuditDataByAuditId
);

/**
 * Get Audit Structure
 */
export const getAuditStructure = asyncThunkAction(
  'getAuditStructure',
  CarAuditStructureService.getAuditStructure
);

export const getNewestAuditStructure = asyncThunkAction(
  'getNewestAuditStructure',
  CarAuditStructureService.getNewestStructureByInspectionType
);

export const updateAudit = asyncThunkAction(
  'updateAudit',
  CarAuditVehicleAuditService.updateAuditData
);
export const imagesUpload = asyncThunkAction(
  'imageUpload',
  CarAuditVehicleAuditAssetService.auditAssetsUpload
);
export const getAuditAsset = asyncThunkAction(
  'getAuditAsset',
  CarAuditVehicleAuditAssetService.getAuditAsset
);
export const deleteAuditAsset = asyncThunkAction(
  'deleteAuditAsset',
  CarAuditVehicleAuditAssetService.auditAssetsDelete
);

export const deleteAuditAssets = asyncThunkAction(
  'deleteAuditAssets',
  CarAuditVehicleAuditAssetService.temporaryAuditAssetsDelete
);

export const auditAssetsUploadV2 = asyncThunkAction(
  'auditAssetsUploadV2',
  CarAuditVehicleAuditAssetService.auditAssetsUploadV2
);

export const uploadFileV2 = asyncThunkAction(
  'uploadFileV2',
  CarAuditFileStorageService.uploadFileV2
);

export const createAuditV2 = asyncThunkAction(
  'createAuditV2',
  CarAuditVehicleAuditService.createAuditV2
);

export const finishAudit = asyncThunkAction('finishAudit', CarAuditVehicleAuditService.finishAudit);
export const syncInspectionToCondition = asyncThunkAction(
  'syncInspectionToCondition',
  CarAuditVehicleAuditService.syncInspectionAndVehicleCondition
);

// Structure settings
export const getAuditStructureForStructureSettings = asyncThunkAction(
  'getAuditStructureForStructureSettings',
  CarAuditStructureSettingsService.getBasicStructureForSettings
);
export const getAuditStructureSettings = asyncThunkAction(
  'getAuditStructureSettings',
  CarAuditStructureSettingsService.getTypeOfControlSettings
);
export const updateAuditStructureSettings = asyncThunkAction(
  'updateAuditStructureSettings',
  CarAuditStructureSettingsService.createTypeOfControlSettings
);
export const getAllAuditStructureTemplates = asyncThunkAction(
  'getAllAuditStructureTemplates',
  CarAuditStructureSettingsService.getAllTemplates
);

export const createVehicleConditionDocument = asyncThunkAction(
  'createVehicleConditionDocument',
  DocumentService.createVehicleConditionDocument
);

export const createInspectionDocument = asyncThunkAction(
  'createInspectionDocument',
  DocumentService.createInspectionDocument
);

export const getAuditExportTemplates = asyncThunkAction(
  'getAuditExportTemplates',
  GetTemplatesService.getTemplates
);

export const getVehicleOverallConditions = asyncThunkAction(
  'getVehicleOverallCondition',
  async ({vehicleIds}: {vehicleIds: string[]}): Promise<Array<SpecificallyAuditDataItem>> =>
    await CarAuditVehicleAuditService.getSpecificallyAuditData({
      inspectionType: 'vehicleCondition',
      requestBody: {
        vehicleIds,
        keys: [
          EXTERIOR_CONDITION_PARAM_DEFINITION,
          INTERIOR_CONDITION_PARAM_DEFINITION,
          MECHANICAL_CONDITION_PARAM_DEFINITION,
        ],
      },
    })
);
