/**
 * Copy pasted from material-ui v4
 */

/* eslint-disable no-restricted-syntax */

/* eslint-disable @typescript-eslint/no-explicit-any */
import PopperJs from 'popper.js';

import {
  useState,
  useRef,
  useMemo,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useCallback,
  isValidElement,
  cloneElement,
  useImperativeHandle,
} from 'react';

function getAnchorEl(anchorEl: any) {
  return typeof anchorEl === 'function' ? anchorEl() : anchorEl;
}

function setRef(ref: any, value: any) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

function useForkRef(refA: any, refB: any) {
  /**
   * This will create a new function if the ref props change and are defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior
   */
  return useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }
    return (refValue: any) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
}

/**
 * @deprecated
 */
export const Popper = forwardRef((props: any, ref: any) => {
  const {
    anchorEl,
    children,
    modifiers,
    popperRef: popperRefProp,
    open,
    placement: initialPlacement = 'bottom',
    ...other
  } = props;

  const tooltipRef = useRef<any>(null);
  const ownRef = useForkRef(tooltipRef, ref);

  const popperRef = useRef<any>(null);
  const handlePopperRef = useForkRef(popperRef, popperRefProp);
  const handlePopperRefRef = useRef<any>(handlePopperRef);
  useLayoutEffect(() => {
    handlePopperRefRef.current = handlePopperRef;
  }, [handlePopperRef]);
  useImperativeHandle(popperRefProp, () => popperRef.current, []);

  const rtlPlacement = initialPlacement;
  /**
   * placement initialized from prop but can change during lifetime if modifiers.flip.
   * modifiers.flip is essentially a flip for controlled/uncontrolled behavior
   */
  const [placement, setPlacement] = useState(rtlPlacement);

  useEffect(() => {
    if (popperRef.current) {
      // @ts-ignore
      popperRef.current.update();
    }
  });

  const handleOpen = useCallback(() => {
    if (!tooltipRef.current || !anchorEl || !open) {
      return;
    }

    if (popperRef.current) {
      popperRef.current.destroy();
      handlePopperRefRef.current(null);
    }

    const handlePopperUpdate = (data: any) => {
      setPlacement(data.placement);
    };

    const popper = new PopperJs(getAnchorEl(anchorEl), tooltipRef.current, {
      placement: rtlPlacement,
      modifiers: {
        ...modifiers,
      },
      // We could have been using a custom modifier like react-popper is doing.
      // But it seems this is the best public API for this use case.
      onCreate: handlePopperUpdate,
      onUpdate: handlePopperUpdate,
    });

    handlePopperRefRef.current(popper);
  }, [anchorEl, modifiers, open, rtlPlacement]);

  const handleRef = useCallback(
    (node: any) => {
      setRef(ownRef, node);
      handleOpen();
    },
    [ownRef, handleOpen]
  );

  const handleClose = () => {
    if (!popperRef.current) {
      return;
    }

    popperRef.current.destroy();
    handlePopperRefRef.current(null);
  };

  useEffect(
    () => () => {
      handleClose();
    },
    []
  );

  useEffect(() => {
    if (!open) {
      // Otherwise handleExited will call this.
      handleClose();
    }
  }, [open]);

  if (!open) {
    return null;
  }

  const childProps = {placement};

  return (
    <Portal disablePortal>
      <div
        ref={handleRef}
        role="tooltip"
        {...other}
        style={{
          // Prevents scroll issue, waiting for Popper.js to add this style once initiated.
          position: 'fixed',
          // Fix Popper.js display issue
          top: 0,
          left: 0,
          display: null,
        }}
      >
        {typeof children === 'function' ? children(childProps) : children}
      </div>
    </Portal>
  );
});

const Portal = forwardRef((props: any, ref: any) => {
  const {children} = props;
  const handleRef = useForkRef(isValidElement(children) ? (children as any).ref : null, ref);

  if (isValidElement(children)) {
    return cloneElement(children, {
      // @ts-ignore
      ref: handleRef,
    });
  }
  return children;
});
