import {VStack, Grid} from 'platform/foundation';

import {FC} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {MainCategoryProps} from '../../../types/ConditionTypes';
import {CategoryCard} from './CategoryCard';

export const Documentation: FC<MainCategoryProps & TestIdProps> = ({
  data: categoryStructure,
  ...rest
}) => {
  const childCategories = categoryStructure?.childCategories;
  const halfOffset = Math.round((childCategories?.length ?? 0) / 1.7);
  const leftColumnCategories = childCategories?.slice(0, halfOffset);
  const rightColumnCategories = childCategories?.slice(halfOffset, childCategories.length);

  return (
    <Grid columns={2}>
      <VStack spacing={4}>
        {leftColumnCategories?.map((category, index) => (
          <CategoryCard
            key={`left-${category.id}-${category.uniqueKey}`}
            data={category}
            cardVariant="inlineGrey"
            data-testid={suffixTestId(`left-[${index}]`, rest)}
          />
        ))}
      </VStack>
      <VStack spacing={4}>
        {rightColumnCategories?.map((category, index) => (
          <CategoryCard
            key={`right-${category.id}-${category.uniqueKey}`}
            data={category}
            cardVariant="inlineGrey"
            data-testid={suffixTestId(`right-${index}`, rest)}
          />
        ))}
      </VStack>
    </Grid>
  );
};
