import {Option} from 'platform/components';
import {Nullish} from 'utility-types';

import {
  useGetCustomTenantCatalogueQuery,
  useGetMakeModelWithMakeQuery,
  VehicleTypeEnumObject,
} from '@omnetic-dms/api';
import i18n, {FALLBACK_LANGUAGE} from '@omnetic-dms/i18n';

import {vehicleModelParser} from '../utils/vehicleModelParser';

interface UseVehicleModelProps {
  vehicleType: VehicleTypeEnumObject;
  make: string | Nullish;
  unknownModels?: Option[];
}

export function useVehicleModel(props: UseVehicleModelProps) {
  const vehicleModelQuery = useGetMakeModelWithMakeQuery(
    {
      vehicleType: props.vehicleType,
      make: props.make || '',
      lang: [i18n.resolvedLanguage ?? FALLBACK_LANGUAGE],
    },
    {skip: !props.vehicleType || !props.make}
  );

  const customTenantMakeModelsQuery = useGetCustomTenantCatalogueQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const isLoading = vehicleModelQuery.isLoading || customTenantMakeModelsQuery.isLoading;
  const isError = vehicleModelQuery.isError || customTenantMakeModelsQuery.isError;

  const parsedData = vehicleModelParser({
    vehicleType: props.vehicleType,
    make: props.make,
    unknownModels: props.unknownModels || [],
    vehicleModelData: vehicleModelQuery.data,
    customTenantMakeModelData: customTenantMakeModelsQuery.data,
  });

  return {
    ...parsedData,
    isLoading,
    isError,
  };
}
