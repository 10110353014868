import {useState} from 'react';

import {useGetAlphaCatalogueQuery, Key, FeatureKey, VehicleTypeEnumObject} from '@omnetic-dms/api';
import i18n, {FALLBACK_LANGUAGE} from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

import {buildTree} from './utils/buildTree';

interface UseVehicleFeaturesV2Descriptor {
  /**
   * Data representation of a tree structure of features for a given vehicle type. Useful for rendering UI.
   */
  categorizedFeaturesTree: {
    /**
     * Category ID
     */
    key: string;
    /**
     * Category descriptor
     */
    category: Key | undefined;
    /**
     * Features tree in this category
     */
    features: FeatureKey[];
    /**
     * Top level features map
     */
    treeOwnerMap: Map<string, string>;
  }[];
  /**
   * Flat array of all features for given vehicle type. Usefull when filtering highlighted features.
   */
  flatFeaturesArray: Key[];
  /**
   * Error when loading product catalog.
   */
  isError: boolean;
  /**
   * When product catalog is still loading.
   */
  isLoading: boolean;
  /**
   * Set up query for searching through features.
   */
  search: string | undefined;
  /**
   * Function to set up search query.
   * @param search Just plain string. If you want to remove search just pass undefined.
   */
  setSearch: (search?: string) => void;
}

/**
 * Searches through product catalog and collects all categories and features for given vehicle type. It comes with complimentary component called "FeatureSelector".
 * @param rawVehicleType Vehicle type to build tree for
 */
export function useVehicleFeaturesV2(
  rawVehicleType: VehicleTypeEnumObject | Nullish
): UseVehicleFeaturesV2Descriptor {
  const [search, setSearch] = useState<string>();

  const {data, isError, isLoading} = useGetAlphaCatalogueQuery({
    lang: i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE,
  });

  const vehicleType = rawVehicleType || VehicleTypeEnumObject.VEHICLETYPE_PASSENGER_CAR;

  const featureCategories = data?.find(
    (catalogue) =>
      catalogue.vehicle_type === vehicleType && catalogue.property_name === 'feature_category'
  );

  const justFeatures = data?.find(
    (catalogue) => catalogue.vehicle_type === vehicleType && catalogue.property_name === 'feature'
  )?.keys;

  const groupedFeatures = justFeatures
    ? Object.groupBy(justFeatures, (item) => item.categories[0])
    : {};

  const categorizedFeaturesTree = Object.keys(groupedFeatures).map((key) => {
    const {features, treeOwnerMap} = buildTree(groupedFeatures[key], search);

    return {
      key,
      category: featureCategories?.keys.find((category) => category.const_key === key),
      features,
      treeOwnerMap,
    };
  });

  return {
    categorizedFeaturesTree,
    flatFeaturesArray: justFeatures || [],
    isError,
    isLoading,
    search,
    setSearch,
  };
}
