import {useCallback, useContext} from 'react';
import {FieldValues, useFormContext, UseFormReturn} from 'react-hook-form';

import {FormContextWithSubmit} from '../utils/FormContextWithSubmit';

interface UseFormContextWithSubmitReturnType<
  FormType extends FieldValues = Record<string, unknown>,
> {
  form: UseFormReturn<FormType>;
  onSubmit: () => void;
  onChange: () => void;
}

export function useFormContextWithSubmit<
  T extends FieldValues,
>(): UseFormContextWithSubmitReturnType<T> {
  const methods = useFormContext<T>();
  const context = useContext(FormContextWithSubmit);

  const handleOnSubmit = () => {
    context?.current?.onSubmit && context.current.onSubmit();
  };

  const handleOnChange = useCallback(() => {
    context.current?.onChange && context.current.onChange();
  }, [context]);

  return {
    form: methods,
    onSubmit: handleOnSubmit,
    onChange: handleOnChange,
  };
}
