import {Card} from '../../Card/Card';
import {ElementRenderer} from '../components/ElementRenderer';
import {FullSchema} from '../types/FullSchema';
import {CardRendererResponseBody} from '../types/modelSchema';

export function CardRenderer(props: CardRendererResponseBody) {
  return (
    <Card
      variant={props.variant}
      isExpandable={props.isExpandable}
      isClosedByDefault={props.isClosedByDefault}
      title={props.header}
    >
      {props.elements?.map((element) => (
        <ElementRenderer
          key={element.name}
          rendererRegistry={props.rendererRegistry}
          schema={element as FullSchema}
        />
      ))}
    </Card>
  );
}
