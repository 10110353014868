import {createSlice} from '@reduxjs/toolkit';

import {AuctionServer} from '../../types/AuctionServer';
import {Enum} from '../../types/Enum';
import {SourcingServer} from '../../types/SourcingServer';
import {AsyncThunkState, getAsyncThunkReducer} from '../../utils/reduxThunkUtils';
import {
  calculateFromWithoutVat,
  calculateFromWithVat,
  getPaymentTypes,
  getSellerServers,
} from './actions';

export interface CommonState extends AsyncThunkState {
  paymentTypes: Enum[];
  adServers: SourcingServer[];
  auctionServers: AuctionServer[];
}

const initialState: CommonState = {
  loading: {},
  errors: {},
  paymentTypes: [],
  adServers: [],
  auctionServers: [],
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const {asyncThunkReducer, handleThunkStates} = getAsyncThunkReducer(builder);

    asyncThunkReducer(getPaymentTypes.action, (state, {payload}) => {
      state.paymentTypes = payload;
    });

    asyncThunkReducer(getSellerServers.action, (state, {payload}) => {
      state.adServers = payload;
    });

    asyncThunkReducer(calculateFromWithVat.action);
    asyncThunkReducer(calculateFromWithoutVat.action);

    handleThunkStates();
  },
});

const {reducer} = slice;
export const commonReducer = reducer;
