import {Card, Label, Textarea} from 'platform/components';
import {Box, HStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {always} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {TemplateListItemResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {AllOrNone, Nullish, suffixTestId, TestIdProps} from 'shared';

import {PredefinedNotes} from '../PredefinedNotes/PredefinedNotes';

type DocumentTemplateBoxProps = {
  title: string;
  type?: string | Nullish;
  isSelected?: boolean;
  selectedTemplate?: TemplateListItemResponseBody | Nullish;
  templates?: TemplateListItemResponseBody[] | Nullish;
  onSelect: (isSelected: boolean) => void;
  onTemplateChange: (document: TemplateListItemResponseBody) => void;
  isDisabled?: boolean;
} & AllOrNone<{
  isWithNote: boolean;
  onNoteChange: (note: string | null) => void;
  noteLabel?: string;
}> &
  TestIdProps;

export function DocumentTemplateBox(props: DocumentTemplateBoxProps) {
  const [note, setNote] = useState<string | null>(null);

  if (isNilOrEmpty(props.templates)) {
    return null;
  }

  const noteType = match(props.type)
    .with('purchase-brokerage-contract', () => 'brokerage-contract')
    .with('sale-brokerage-contract', () => 'brokerage-contract')
    .otherwise(always(props.type));

  const templateOptions = props.templates!.map((document, optionIndex) => ({
    label: document.title,
    'data-testid': suffixTestId(`template[${optionIndex}]`, props),
    onClick: () => props.onTemplateChange(document),
  }));

  const handleNoteChange = (note: string | null) => {
    setNote(note);
    props.onNoteChange?.(note);
  };

  return (
    <Card
      title={props.title}
      parameters={[props.selectedTemplate?.title]}
      variant="inlineGrey"
      isExpanded={props.isWithNote}
      control={{
        type: 'checkbox',
        value: Boolean(props.isSelected),
        onChange: props.onSelect,
        isDisabled: props.isDisabled,
        'data-testid': suffixTestId('isSelected', props),
      }}
      actions={[
        {
          type: 'dropdown-button',
          variant: 'secondary',
          rightIcon: 'navigation/expand_more',
          title: i18n.t('general.labels.template'),
          menuItems: templateOptions,
          'data-testid': suffixTestId('select-template', props),
        },
      ]}
      data-testid={props['data-testid']}
    >
      {props.isWithNote && (
        <>
          <HStack align="flex-end">
            <Box flex={1}>
              <Label>{props.noteLabel || i18n.t('general.labels.note')}</Label>
            </Box>
            {noteType && (
              <PredefinedNotes
                note={note}
                onPrefill={handleNoteChange}
                context={noteType}
                resource="BUSINESS_CASE"
                isLinkVariant
                data-testid={suffixTestId('predefinedNotes', props)}
              />
            )}
          </HStack>
          <Textarea
            name="note"
            onChange={handleNoteChange}
            value={note}
            minRows={2}
            data-testid={suffixTestId('note', props)}
          />
        </>
      )}
    </Card>
  );
}
