import {
  ButtonGroup,
  DataStatus,
  Dialog,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box} from 'platform/foundation';
import {object} from 'yup';

import {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import {head, isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetServiceOrderIssueVariantsQuery,
  usePostServiceOrderIssueNoteMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, Nullish, yupString} from 'shared';

import {DataGrid, RowData} from 'features/datagrid';

import {getOptionsFromIssueNoteVariants} from '../../utils/getOptionsFromIssueNoteVariants';

export type IssueNoteVariantsForm = {
  issueNoteVariant: string;
};

export function ServiceOrderIssueCreate() {
  const navigate = useNavigate();

  const [issueNoteVariant, setIssueNoteVariant] = useState<string | Nullish>(null);
  const [selectedServiceOrder, setSelectedServiceOrder] = useState<string | Nullish>(null);

  const {
    data: serviceOrderIssueVariants,
    isLoading: isServiceOrderIssueVariantsLoading,
    isError: hasServiceOrderIssueVariantsError,
  } = useGetServiceOrderIssueVariantsQuery();

  const [postServiceOrderIssueNote] = usePostServiceOrderIssueNoteMutation();

  const handleIssueNoteVariantSubmit: FormSubmitHandler<IssueNoteVariantsForm> = async (
    formValues
  ) => {
    await setIssueNoteVariant(formValues.issueNoteVariant);
  };

  const handleCreateServiceOrderIssueNote = () => {
    if (isNil(issueNoteVariant) || isNil(selectedServiceOrder)) {
      throw new Error('IssueNoteVariantId or selectedServiceOrderId not found');
    }

    postServiceOrderIssueNote({
      serviceOrderIssueVariantId: issueNoteVariant,
      serviceOrderId: selectedServiceOrder,
    })
      .unwrap()
      .then((res) => {
        showNotification.success(i18n.t('entity.warehouse.notifications.serviceOrderIssueCreated'));
        navigate(
          composePath(warehouseRoutes.serviceOrderIssueNew, {
            params: {serviceOrderIssueNoteId: res.id},
          })
        );
      })
      .catch(handleApiError);
  };

  const handleOnRowSelectionChange = (rows: RowData[]) => {
    setSelectedServiceOrder(head(rows)?.id);
  };

  const handleDiscard = () => {
    navigate(warehouseRoutes.serviceOrderIssues);
  };

  return (
    <>
      <Helmet title={i18n.t('page.warehouse.labels.newServiceOrderIssue')} />
      <DataStatus
        minHeight="100%"
        isLoading={isServiceOrderIssueVariantsLoading}
        isError={hasServiceOrderIssueVariantsError}
      >
        <Dialog
          isOpen={!issueNoteVariant}
          title={i18n.t('page.warehouse.labels.newServiceOrderIssue')}
          size="small"
          withAdditionalFooter
          scrollBehavior="outside"
          onClose={handleDiscard}
          data-testid={testIds.warehouse.serviceOrderIssueCreate('dialog')}
        >
          <Form<IssueNoteVariantsForm> schema={formSchema} onSubmit={handleIssueNoteVariantSubmit}>
            {(control) => (
              <>
                <Box height={20}>
                  <FormField
                    isRequired
                    isNotClearable
                    control={control}
                    name="issueNoteVariant"
                    type="choice"
                    label={i18n.t('page.warehouse.labels.serviceOrderIssueVariant')}
                    options={getOptionsFromIssueNoteVariants(serviceOrderIssueVariants)}
                    data-testid={testIds.warehouse.serviceOrderIssueCreate(
                      'serviceOrderIssueVariant'
                    )}
                  />
                </Box>

                <DialogFooter>
                  <ButtonGroup align="right">
                    <FormButton
                      type="button"
                      title={i18n.t('general.actions.discard')}
                      variant="secondary"
                      control={control}
                      onClick={handleDiscard}
                      data-testid={testIds.warehouse.serviceOrderIssueCreate('discard')}
                    />
                    <FormButton
                      type="submit"
                      title={i18n.t('general.actions.create')}
                      variant="primary"
                      control={control}
                      data-testid={testIds.warehouse.serviceOrderIssueCreate('submit')}
                    />
                  </ButtonGroup>
                </DialogFooter>
              </>
            )}
          </Form>
        </Dialog>

        <Dialog
          isOpen={isNotNilOrEmpty(issueNoteVariant)}
          title={i18n.t('page.warehouse.labels.newServiceOrderIssue')}
          size="large"
          onClose={handleDiscard}
          data-testid={testIds.warehouse.serviceOrderIssueCreate('assignDialog')}
          buttons={[
            {
              type: 'button',
              variant: 'secondary',
              title: i18n.t('general.labels.discard'),
              onClick: handleDiscard,
            },
            {
              type: 'button',
              title: i18n.t('general.labels.assign'),
              onClick: handleCreateServiceOrderIssueNote,
              isDisabled: isNil(selectedServiceOrder),
              'data-testid': testIds.warehouse.serviceOrderIssueCreate('assign'),
            },
          ]}
        >
          <DataGrid
            gridCode="issue-note-service-orders"
            onRowSelectionChange={handleOnRowSelectionChange}
            autoHeight
            data-testid={testIds.warehouse.serviceOrderIssueCreate('service-orders')}
          />
        </Dialog>
      </DataStatus>
    </>
  );
}

const formSchema = object({
  issueNoteVariant: yupString.required(),
});
