import {yupResolver} from '@hookform/resolvers/yup';
import {showNotification} from 'platform/components';
import {Icon} from 'platform/foundation';
import styled from 'styled-components';
import {SchemaOf, object, ref, string} from 'yup';

import {FC, useState, PropsWithChildren, ReactElement, ChangeEvent} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {
  ChangeCurrentUserPasswordApiArg,
  useChangeCurrentUserPasswordMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {TextField, TypographyCard} from '@omnetic-dms/teas';

import {FooterComponent} from './FooterComponent';

type ResetPasswordTuple = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
};

export const MyProfilePassword: FC<PropsWithChildren<any>> = (): ReactElement => {
  const [showPassword, setViewPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [changePassword, {isLoading}] = useChangeCurrentUserPasswordMutation();

  const {
    formState: {errors},
    handleSubmit,
    control,
    formState,
    reset,
    setValue,
  } = useForm<ResetPasswordTuple>({
    resolver: yupResolver(ResetPasswordValidationSchema),
    reValidateMode: 'onChange',
    mode: 'onBlur',
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  const submit = async ({password, currentPassword}: ResetPasswordTuple): Promise<void> => {
    try {
      const arg = {
        changeCurrentUserPasswordRequestBody: {currentPassword, newPassword: password},
      } as ChangeCurrentUserPasswordApiArg;

      await changePassword(arg).unwrap();
      showNotification.success(i18n.t(`general.notifications.passwordChanged`));

      reset();
    } catch (error: any) {
      setValue('currentPassword', '');
      showNotification.error(error?.data?.errors?.[0]?.message);
    }
  };

  return (
    <ProfileCard
      data-testid={testIds.myProfile.security('password-card')}
      title={i18n.t('entity.user.labels.changePassword')}
    >
      <Wrapper onSubmit={handleSubmit(submit)}>
        <ControllerWrapper>
          <Controller
            name="currentPassword"
            control={control}
            render={({field: {onChange, ...rest}}) => (
              <StyledTextField
                {...rest}
                data-testid={testIds.myProfile.security('current-password')}
                type={showPassword ? 'text' : 'password'}
                label={i18n.t('page.myProfile.labels.currentPassword')}
                error={Boolean(errors.currentPassword?.message)}
                helperText={errors.currentPassword?.message}
                endAdornment={{
                  variant: 'button',
                  disableFocusToInput: true,
                  Icon: showNewPassword ? (
                    <Icon value="action/visibility" size={4} />
                  ) : (
                    <Icon value="action/visibility_off" size={4} />
                  ),
                  onClick: () => setViewPassword(!showPassword),
                }}
                onChange={(e) => onChange(removeSpace(e))}
              />
            )}
          />
        </ControllerWrapper>

        <ControllerWrapper>
          <Controller
            name="password"
            control={control}
            render={({field: {onChange, ...rest}}) => (
              <StyledTextField
                {...rest}
                data-testid={testIds.myProfile.security('new-password')}
                type={showNewPassword ? 'text' : 'password'}
                label={i18n.t('page.myProfile.labels.newPassword')}
                error={Boolean(errors.password?.message)}
                helperText={errors.password?.message}
                endAdornment={{
                  variant: 'button',
                  disableFocusToInput: true,
                  Icon: showNewPassword ? (
                    <Icon value="action/visibility" size={4} />
                  ) : (
                    <Icon value="action/visibility_off" size={4} />
                  ),
                  onClick: () => setShowNewPassword(!showNewPassword),
                }}
                onChange={(e) => onChange(removeSpace(e))}
              />
            )}
          />

          <Controller
            name="confirmPassword"
            control={control}
            render={({field: {onChange, ...rest}}) => (
              <StyledTextField
                {...rest}
                data-testid={testIds.myProfile.security('confirm-password')}
                type={showNewPassword ? 'text' : 'password'}
                label={i18n.t('page.myProfile.labels.confirmPassword')}
                error={Boolean(errors.confirmPassword?.message)}
                helperText={errors.confirmPassword?.message}
                endAdornment={{
                  variant: 'button',
                  disableFocusToInput: true,
                  Icon: showNewPassword ? (
                    <Icon value="action/visibility" size={4} />
                  ) : (
                    <Icon value="action/visibility_off" size={4} />
                  ),
                  onClick: () => setShowNewPassword(!showNewPassword),
                }}
                onChange={(e) => onChange(removeSpace(e))}
              />
            )}
          />
        </ControllerWrapper>
        <FooterComponent isDisabled={!formState.isValid} loading={isLoading} />
      </Wrapper>
    </ProfileCard>
  );
};

const removeSpace = (e: ChangeEvent<HTMLInputElement>) => e.target.value.replace(/\s+/g, '');

const ProfileCard = styled(TypographyCard)`
  width: 583px;
  margin-bottom: ${({theme}) => theme.getSize(4)};
`;

const passwordValidation = string()
  .required(i18n.t('general.notifications.errorPasswordsRequired'))
  .min(8, i18n.t('general.notifications.errorPasswordsLength'))
  .matches(/^(?:[A-Z]|[a-z]|[0-9]|.){8,}$/, {
    message: i18n.t('general.notifications.errorPasswordsFormat'),
  })
  .matches(/[A-Z]/, {
    message: i18n.t('general.notifications.errorPasswordsUppercase'),
  })
  .matches(/[a-z]/, {
    message: i18n.t('general.notifications.errorPasswordsLowercase'),
  })
  .matches(/[0-9]+/, {
    message: i18n.t('general.notifications.errorPasswordsDigit'),
  });

const ResetPasswordValidationSchema = object().shape({
  currentPassword: string().required(
    `${i18n.t('page.myProfile.labels.currentPassword')} ${i18n.t('general.notifications.errorSpecFieldRequired')}`
  ),
  password: passwordValidation,
  confirmPassword: string()
    .required(`Confirm password ${i18n.t('general.notifications.errorSpecFieldRequired')}`)
    .oneOf([ref('password'), null], i18n.t('general.notifications.errorPasswordsNotMatch')),
}) as SchemaOf<ResetPasswordTuple>;

const StyledTextField = styled(TextField)`
  margin-bottom: ${({theme}) => theme.getSize(3)};
`;

const Wrapper = styled.form`
  label {
    font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  }
`;

const ControllerWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .form-field-wrapper {
    width: 268px;
    margin-bottom: ${({theme}) => theme.getSize(4)};
  }
`;
