import {z} from 'zod';

import {BaseVoidRequestSchema, BaseVoidResponseSchema, CreatedAndUpdatedBySchema} from './base';

const BaseDirectSaleTypeSchema = z.object({
  directSaleTypeId: z.string(),
  name: z.string(),
});
export type BaseDirectSaleType = z.infer<typeof BaseDirectSaleTypeSchema>;

const BaseDirectSaleVariantSchema = z.object({
  directSaleVariantId: z.string(),
  name: z.string(),
});
export type BaseDirectSaleVariant = z.infer<typeof BaseDirectSaleVariantSchema>;

const DefaultPaymentTypeSchema = z.enum(['CASH', 'CARD', 'BANK_TRANSFER', 'INTERNAL']);
export type DefaultPaymentType = z.infer<typeof DefaultPaymentTypeSchema>;

const BasePayment = z.object({
  docSeriesId: z.string().optional(),
  invoiceTemplateId: z.string().optional(),
  isEnable: z.boolean().optional(),
});

const DirectSaleVariantDetailSchema = z.object({
  general: z.object({
    directSaleVariantId: z.string(),
    directSaleTypeId: z.string(),
    name: z.string(),
    note: z.string().optional(),
    priceType: z.string(),
    rateType: z.string(),
    priceTypeRatio: z.number(),
    rateTypeRatio: z.number(),
    minimalMaterialMargin: z.number().optional(),
    minimalWorkMargin: z.number().optional(),
    defaultCustomerId: z.string().optional(),
    sufix: z.string(),
    authorizationProfileId: z.string(),
    docSeriesId: z.string(),
    isUnitPriceWithVat: z.boolean(),
    isChangeableDefaultCustomer: z.boolean().optional(),
  }),
  documents: z.object({
    defaultPaymentType: DefaultPaymentTypeSchema.nullable().optional(),
    orderOffer: z
      .object({
        docSeriesId: z.string().optional(),
        templateId: z.string().optional(),
      })
      .optional(),
    cashPayment: BasePayment.optional(),
    cardPayment: BasePayment.optional(),
    bankPayment: BasePayment.optional(),
    internalPayment: BasePayment.optional(),
  }),
});
export type DirectSaleVariantDetail = z.infer<typeof DirectSaleVariantDetailSchema>;

/**
 * GET requests
 */
export const GetDirectSaleTypesRequestSchema = BaseVoidRequestSchema;
export type GetDirectSaleTypesRequest = z.infer<typeof GetDirectSaleTypesRequestSchema>;

export const GetDirectSaleTypesResponseSchema = z.array(BaseDirectSaleTypeSchema);
export type GetDirectSaleTypesResponse = z.infer<typeof GetDirectSaleTypesResponseSchema>;

export const GetDirectSaleVariantsRequestSchema = BaseVoidRequestSchema;
export type GetDirectSaleVariantsRequest = z.infer<typeof GetDirectSaleVariantsRequestSchema>;

export const GetDirectSaleVariantsResponseSchema = z.array(BaseDirectSaleVariantSchema);
export type GetDirectSaleVariantsResponse = z.infer<typeof GetDirectSaleVariantsResponseSchema>;

export const GetDirectSaleVariantRequestSchema = z.object({
  directSaleVariantId: z.string(),
});
export type GetDirectSaleVariantRequest = z.infer<typeof GetDirectSaleVariantRequestSchema>;

export const GetDirectSaleVariantResponseSchema =
  DirectSaleVariantDetailSchema.merge(CreatedAndUpdatedBySchema);
export type GetDirectSaleVariantResponse = z.infer<typeof GetDirectSaleVariantResponseSchema>;

/**
 * POST requests
 */
export const PostDirectSaleVariantRequestSchema = DirectSaleVariantDetailSchema;
export type PostDirectSaleVariantRequest = z.infer<typeof PostDirectSaleVariantRequestSchema>;

export const PostDirectSaleVariantResponseSchema = z.object({directSaleVariantId: z.string()});
export type PostDirectSaleVariantResponse = z.infer<typeof PostDirectSaleVariantResponseSchema>;

/**
 * PATCH requests
 */
export const PatchDirectSaleVariantRequestSchema = z.object({
  directSaleVariantId: z.string(),
  body: DirectSaleVariantDetailSchema,
});
export type PatchDirectSaleVariantRequest = z.infer<typeof PatchDirectSaleVariantRequestSchema>;

export const PatchDirectSaleVariantResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleVariantResponse = z.infer<typeof PatchDirectSaleVariantResponseSchema>;

/**
 * DELETE requests
 */
export const BulkDeleteDirectSaleVariantRequestSchema = z.object({
  directSaleVariantId: z.array(z.string()),
});
export type BulkDeleteDirectSaleVariantRequest = z.infer<
  typeof BulkDeleteDirectSaleVariantRequestSchema
>;

export const BulkDeleteDirectSaleVariantResponseSchema = BaseVoidResponseSchema;
export type BulkDeleteDirectSaleVariantResponse = z.infer<
  typeof BulkDeleteDirectSaleVariantResponseSchema
>;
