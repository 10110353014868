export const userPinItems = [
  'core',
  'vehicles',
  'business-case',
  'customers',
  'settings',
  'interest',
  'sourcing',
  'quickPurchase',
  'demo-module',
  'workshop',
  'warehouse',
  'accounting',
  'inspection',
  'analytics',
  'employee',
  'tires-inventory',
];
