import {createContext} from 'react';

import {VehicleSeriesDefinitionContextType} from '../types';

export const VehicleSeriesDefinitionFormContext = createContext<VehicleSeriesDefinitionContextType>(
  {
    branchList: [],
    seriesList: [],
    vehicleTypes: [],
  }
);
