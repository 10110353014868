import {FormControl, FormField} from 'platform/components';
import {Grid, Show, Hide, Text} from 'platform/foundation';

import {useEffect, useMemo} from 'react';
import {UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, isDefined, suffixTestId} from 'shared';

import {FormContactPerson} from '../../../hooks/useContactPersons';
import {EmailSmsNotificationForm} from '../types/EmailSmsNotificationForm';
import {DocumentsView} from './DocumentsView';

interface EmailSectionProps extends RequiredTestIdProps {
  control: FormControl<EmailSmsNotificationForm>;
  formApi: UseFormReturn<EmailSmsNotificationForm>;
  orderId?: string;
  serviceCaseId: string;
  personsWithEmails: FormContactPerson[];
  isCategoryCustom: boolean;
}

export function EmailSection(props: EmailSectionProps) {
  const {watch, setValue} = props.formApi;

  const enableEmail = watch('email.isEnabled');
  const selectedPerson = watch('email.data.person');

  const availableEmails = useMemo(
    () => props.personsWithEmails.find((item) => item.value === selectedPerson)?.emails || [],
    [props.personsWithEmails, selectedPerson]
  );

  useEffect(() => {
    setValue('email.data.email', availableEmails[0]?.value || '');
  }, [availableEmails, setValue]);

  return (
    <>
      <FormField
        control={props.control}
        type="switch"
        name="email.isEnabled"
        label={i18n.t('entity.workshop.labels.enableEmailNotifications')}
        data-testid={suffixTestId('email.isEnabled', props)}
      />
      <Show when={enableEmail}>
        <Hide when={props.isCategoryCustom}>
          <Text color="tertiary">{i18n.t('entity.workshop.label.linkToDigitalCertificate')}</Text>
        </Hide>
        <Grid columns={2}>
          <FormField
            control={props.control}
            name="email.data.person"
            type="choice"
            label={i18n.t('entity.businessCase.labels.contactPerson')}
            options={props.personsWithEmails}
            onChange={() => setValue('email.data.email', '')}
            data-testid={suffixTestId('email.data.person', props)}
            isNotClearable
            isRequired
          />
          <FormField
            control={props.control}
            name="email.data.email"
            type="choice"
            label={i18n.t('general.labels.emailAddress')}
            options={availableEmails}
            isDisabled={!selectedPerson}
            data-testid={suffixTestId('email.data.email', props)}
            isNotClearable
            isRequired
          />
        </Grid>
        <Show when={props.isCategoryCustom}>
          <FormField
            control={props.control}
            label={i18n.t('general.labels.subject')}
            name="email.data.subject"
            type="text"
            data-testid={suffixTestId('email.data.subject', props)}
            isRequired
          />
          <FormField
            control={props.control}
            name="email.data.message"
            type="textarea"
            label={i18n.t('general.labels.message')}
            rows={5}
            data-testid={suffixTestId('email.data.message', props)}
            isResizable
            isRequired
          />
        </Show>
        {isDefined(props.orderId) && (
          <DocumentsView
            orderId={props.orderId}
            serviceCaseId={props.serviceCaseId}
            data-testid={suffixTestId('document', props)}
          />
        )}
      </Show>
    </>
  );
}
