import {useState} from 'react';

import {concat, flatten, pipe, pluck, uniq} from 'ramda';

import {MenuItem} from '../types';
import {MenuFlatItem, MenuFlatList, menuItemsTreeToFlatList} from '../utils';

function doesItemMatchSearchQuery(item: MenuFlatItem, searchQuery: string) {
  const itemLabel = item.label.toLowerCase();
  const query = searchQuery.toLowerCase();

  // Simple matching.
  return itemLabel.includes(query);
}

const emptyArrayConst: string[] = [];
const getGroupsIds = pipe(pluck('parentGroupsIds'), flatten, uniq);
const getItemsIds = pluck('id');

const searchMatchingItemsIds = (index: MenuFlatList, searchQuery: string | null): string[] => {
  if (searchQuery === null) {
    return emptyArrayConst;
  }
  const searchedItems = index.filter((item) => doesItemMatchSearchQuery(item, searchQuery));

  return concat(getGroupsIds(searchedItems), getItemsIds(searchedItems)) as string[];
};

export function useSearch(items: MenuItem[]) {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const itemsFlatList = menuItemsTreeToFlatList(items);
  const matchingItemsIds = searchMatchingItemsIds(itemsFlatList, searchQuery);

  return {searchQuery, setSearchQuery, matchingItemsIds};
}
