import styled from 'styled-components';

import {Button} from '@omnetic-dms/teas';

const AssignButton = styled.span`
  cursor: pointer;
  background: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  justify-content: center;
  margin-top: 3px;
  height: 24px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  padding: 0 8px;
  color: ${({theme}) => theme.colors.palettes.neutral[700][100]};
  margin-left: 8px;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
`;

export const SpanText = styled.span<{$width: number}>`
  width: ${({$width}) => $width}px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  div.MuiTooltip-popper {
    top: 10px;
  }

  .document-tooltip {
    background: red;
  }
`;

export const Container = styled.div`
  flex: calc(100% - 240px);
  margin: 0 auto;
  overflow: auto;

  .custom-template-table {
    table-layout: auto;
  }

  table {
    table-layout: fixed;
  }

  div.MuiAccordion-root {
    margin-bottom: 16px;
  }

  div.MuiCollapse-container {
    border-top: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  }

  div.MuiTableContainer-root {
    border-radius: 0;
    box-shadow: none;
  }

  .simple-table-th {
    padding: 12px 8px;
    color: ${({theme}) => theme.colors.palettes.neutral[300][100]};
    font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  }

  tr {
    background: ${({theme}) => theme.colors.palettes.white[10][100]};
  }

  th,
  td {
    &[data-testid='actions'] {
      width: 30px;
    }

    &[data-testid='expand'] {
      padding: 0;
      width: 10px;
      border-right: none;

      .MuiSvgIcon-root {
        font-size: 1.2rem;
      }
    }

    &[data-testid='title'] {
      width: 50px;
    }

    .MuiButtonBase-root {
      margin-left: 1px;
      padding: 0;
      margin: 0;
      width: 24px;
      height: 24px;
    }
  }

  td.simple-table-td {
    padding: 6px 5px;
    height: 38px;
    position: relative;
  }

  th.simple-table-th {
    padding: 8px 3px 7px 5px;
    position: static;
    border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  }

  td {
    letter-spacing: 0;
    color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
    font-size: ${({theme}) => theme.fontSizes.text.small};
    border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  }

  td.collapsed {
    display: none;
  }

  td.expanded {
    border: none;
    padding: 16px 19px;
    background: ${({theme}) => theme.colors.palettes.neutral[10][100]};
    display: table-cell;

    div.MuiCollapse-container {
      border: transparent;
    }

    .simple-table-th {
      padding: 12px 16px;
    }

    td,
    th {
      background: transparent;
      border: none;
      background: ${({theme}) => theme.colors.palettes.neutral[10][100]};
    }

    th.simple-table-th {
      padding: 6px 5px;

      &:after {
        display: none;
      }

      &[data-testid='templateFile'] {
        width: 269px;
      }

      &[data-testid='templateTitle'] {
        width: 174px;
      }

      &[data-testid='state'] {
        width: 107px;
      }

      &[data-testid='lastUpdate'] {
        width: 140px;
      }

      &[data-testid='addedBy'] {
        width: 134px;
      }

      &[data-testid='note'] {
        width: 240px;
      }
    }

    tr {
      height: 37px;
      background: transparent;
    }

    td.simple-table-td {
      padding: 6px 5px;
      background: transparent;
      border-top: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
    }

    .warning {
      background: ${({theme}) => theme.colors.palettes.orange[20][100]};
    }
  }

  .MuiSvgIcon-root {
    font-size: ${({theme}) => theme.fontSizes.text.base};
    cursor: pointer;
    margin: 0 7px;
  }

  i {
    font-style: normal;
    color: ${({theme}) => theme.colors.palettes.neutral[400][100]};
    font-size: ${({theme}) => theme.fontSizes.text.small};
  }

  div {
    &[role='tooltip'] {
      width: 200px;
    }
  }
`;

const AssignButtonLabel = styled(AssignButton)`
  display: inline-block;
  cursor: default;
  padding: 3px 6px;
  margin: 0;
`;

export const Alternative = styled(AssignButtonLabel)`
  background: ${({theme}) => theme.colors.palettes.neutral[20][100]};
  color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
`;

export const Primary = styled(AssignButtonLabel)`
  background: ${({theme}) => theme.colors.palettes.green[20][100]};
  color: ${({theme}) => theme.colors.palettes.green[80][100]};
`;

export const IconContainer = styled.p`
  margin-right: 13px;
  display: flex;

  .spinner-wrapper {
    margin-left: 20px;
  }
`;

export const TemplateUpdating = styled.div`
  display: flex;
  div {
    margin: 0 5px;
  }
`;

export const WarnWrapper = styled.span`
  position: absolute;
  top: 11px;
`;

export const BadgeWrapper = styled.div`
  display: inline-block;
  width: 16px;
`;

export const FileIconWrapper = styled.div`
  svg {
    margin-left: 2px;
  }
`;

export const ErrorText = styled.p`
  color: ${({theme}) => theme.colors.palettes.red[60][100]};
  text-align: center;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
`;

export const FormFooter = styled.div`
  border-top: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  display: flex;
  justify-content: flex-end;
  margin-left: -${({theme}) => theme.getSize(4)};
  margin-right: -${({theme}) => theme.getSize(4)};
  padding: 16px 16px 0 0;
`;

export const UploadStyledArea = styled.div`
  background-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
  padding: ${({theme}) => theme.getSize(6)};
  border-radius: ${({theme}) => theme.radii.small};
  display: flex;
  flex-direction: column;
  justify-content: center;

  div,
  button {
    margin: auto;
  }

  div:not(:first-child),
  button {
    margin-top: ${({theme}) => theme.getSize(4)};
  }
`;

export const FileFieldWrapper = styled.div``;

export const FileArea = styled.div`
  border: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  border-radius: ${({theme}) => theme.radii.small};
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  display: flex;
  padding: ${({theme}) => theme.getSize(3)};
  ${({theme}) => theme.getSize(4)};

  > * {
    margin-top: auto;
    margin-bottom: auto;
  }

  > span {
    margin-left: ${({theme}) => theme.getSize(3)};
    max-width: 75%;
    max-height: 36px; // Based on paddings and container height
    overflow: hidden;
  }
`;

export const FileRemoveButton = styled(Button)`
  margin-right: 0px;
  margin-left: auto;
  color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
`;

export const SecondaryFooterButton = styled(Button)`
  margin-right: ${({theme}) => theme.getSize(2)};
`;

export const SubmitButton = styled(Button)`
  &:focus,
  &:active {
    box-shadow: none;
  }
`;

export const UploadFormStyled = styled.form`
  > *:not(:first-child) {
    margin-top: ${({theme}) => theme.getSize(4)};
  }
`;
