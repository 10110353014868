import {
  getColorFromSeverity,
  Severity,
  ThemeIconKey,
  Box,
  Center,
  Hide,
  Icon,
  Show,
  Text,
  ThemeColorPath,
} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {always, inc} from 'ramda';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

interface WizardStepBadgeProps extends TestIdProps {
  position: number;
  isCurrent: boolean;
  severity?: Severity | Nullish;
}

export function WizardStepBadge(props: WizardStepBadgeProps) {
  const borderColor = match<[Severity | Nullish, boolean | Nullish], ThemeColorPath>([
    props.severity,
    props.isCurrent,
  ])
    .with([Pattern.string, Pattern.any], ([severity]) => getColorFromSeverity(severity))
    .with([Pattern.nullish, true], always('general.accent'))
    .otherwise(always('palettes.neutral.50.100'));

  const icon = match<Severity | Nullish, ThemeIconKey | null>(props.severity)
    .with('danger', always('wizard/danger'))
    .with('success', always('wizard/success'))
    .with('warning', always('wizard/warning'))
    .with('informational', always('wizard/informational'))
    .with(Pattern.nullish, always(null))
    .exhaustive();

  return (
    <Box
      width={5}
      height={5}
      overflow="hidden"
      borderRadius="circular"
      borderColor={borderColor}
      border={`${props.isCurrent ? 2 : 1}px solid`}
      backgroundColor={icon ? getColorFromSeverity(props.severity) : 'general.white'}
      data-testid={suffixTestId(`stepBadge-[${props.position}]`, props)}
    >
      <Center width="100%" height="100%">
        <Show when={icon}>
          <Icon
            size={6}
            value={icon!}
            color="general.white"
            data-testid={suffixTestId(`stepIcon-[${props.position}]`, props)}
          />
        </Show>
        <Hide when={icon}>
          <Text size="xxSmall" color={props.isCurrent ? 'informational' : 'tertiary'}>
            {inc(props.position)}
          </Text>
        </Hide>
      </Center>
    </Box>
  );
}
