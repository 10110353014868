import {createSearchParams} from 'react-router-dom';

import {isNil} from 'ramda';

export const SOURCE_DATA_GRID = 'sourceDataGrid';

export const getSourceSearchParams = (): string | undefined => {
  const searchParams = new URL(window.location.href).searchParams;
  const sourceParams = searchParams.get(SOURCE_DATA_GRID);

  if (isNil(sourceParams)) {
    return undefined;
  }

  const newSearchParams = {
    [SOURCE_DATA_GRID]: [sourceParams?.toString()],
  };

  return createSearchParams(newSearchParams).toString();
};
