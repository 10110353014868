import {ContextMenuContextType} from '../types';

export const openMenu = (event: MouseEvent, contextMenu: ContextMenuContextType, id: string) => {
  if (contextMenu?.menuState) {
    return;
  }
  event.preventDefault();
  if (contextMenu?.setMenuState) {
    contextMenu.setMenuState({
      id,
      top: event.offsetY,
      left: event.offsetX,
    });
  }
};
