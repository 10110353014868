import {Module} from '@omnetic-dms/api';
import {moduleKeys} from '@omnetic-dms/config';
import {featureFlags} from '@omnetic-dms/feature-flags';
import {employeeRoutes} from '@omnetic-dms/routes';
import {permissions} from '@omnetic-dms/shared';

import {CreateEmployee} from './pages/CreateEmployee/CreateEmployee';
import {EmployeeDetail} from './pages/EmployeeDetail/EmployeeDetail';
import {EmployeeOverview} from './pages/EmployeeOverview/EmployeeOverview';

export const EmployeeModule: Module = {
  id: moduleKeys.employee,
  layoutProps: {
    title: 'module.employee.title',
    icon: 'custom/employee',
    iconV2: 'sidebar/employee',
  },
  routerProps: {
    path: employeeRoutes.overview,
    element: <EmployeeOverview />,
  },
  requiredPermission: permissions.employeeRead,
  requiredFeatureFlag: featureFlags.ACL_EMPLOYEE_MANAGEMENT,
  content: [
    {
      id: 'employee-overview',
      routerProps: {
        path: employeeRoutes.overview,
        element: <EmployeeOverview />,
      },
    },
    {
      id: 'employee-new',
      routerProps: {
        path: employeeRoutes.createEmployee,
        element: <CreateEmployee />,
      },
      layoutProps: {
        title: 'page.createEmployee.labels.title',
        isPartOfCreateMenuItem: true,
      },
    },
    {
      id: 'employee-detail',
      routerProps: {
        path: employeeRoutes.detail,
        element: <EmployeeDetail />,
      },
    },
    {
      id: 'employee-personalInfo',
      routerProps: {
        path: employeeRoutes.personalInfo,
        element: <EmployeeDetail />,
      },
    },
    {
      id: 'employee-organizationalPlacement',
      routerProps: {
        path: employeeRoutes.organizationalPlacement,
        element: <EmployeeDetail />,
      },
    },
    {
      id: 'employee-userDataAndRoles',
      routerProps: {
        path: employeeRoutes.userDataAndRole,
        element: <EmployeeDetail />,
      },
    },
  ],
};
