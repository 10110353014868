import {openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  useDeleteVehicleGroupMutation,
  usePostVehicleGroupSetAsActiveMutation,
  usePostVehicleGroupSetAsInactiveMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function VehicleGroups() {
  const navigate = useNavigate();

  const [deleteVehicleGroup] = useDeleteVehicleGroupMutation();
  const [postVehicleGroupSetAsActive] = usePostVehicleGroupSetAsActiveMutation();
  const [postVehicleGroupSetAsInactive] = usePostVehicleGroupSetAsInactiveMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', () =>
        navigate(composePath(settingsRoutes.vehicleGroupsDetail, {params: {id}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteVehicleGroup({vehicleGroupId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('setAsActive', () =>
        postVehicleGroupSetAsActive({vehicleGroupId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('setAsInactive', () =>
        postVehicleGroupSetAsInactive({vehicleGroupId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .otherwise(always(undefined));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.workshop.labels.vehicleGroups'),
        actions: [
          {
            type: 'button',
            title: i18n.t('entity.workshop.actions.newVehicleGroup'),
            onClick: () => navigate(settingsRoutes.vehicleGroupsCreate),
          },
        ],
      }}
      data-testid={testIds.settings.vehicleGroups('template')}
    >
      <DataGrid
        gridCode="vehicle-group"
        actionCallback={actionCallback}
        data-testid={testIds.settings.vehicleGroups('datagrid')}
      />
    </SettingsTemplate>
  );
}
