import {BeautifulMentionsMenuItemProps} from 'lexical-beautiful-mentions';
import styled, {css} from 'styled-components';

import {forwardRef} from 'react';

export const StyledMenuItem = styled.li<{
  $isSelected?: boolean;
}>`
  list-style-type: none;
  padding: 8px;
  border-radius: ${({theme}) => theme.radii.small};
  font-size: ${({theme}) => theme.fontSizes.text.small};

  ${({$isSelected}) =>
    $isSelected &&
    css`
      background-color: ${({theme}) => theme.colors.palettes.blue[10][100]};
      color: ${({theme}) => theme.colors.palettes.blue[80][100]};
    `}
`;

export const CustomMenuItem = forwardRef<HTMLLIElement, BeautifulMentionsMenuItemProps>(
  ({selected, item, ...props}, ref) => {
    return <StyledMenuItem {...props} ref={ref} $isSelected={selected} />;
  }
);
