import {isNilOrEmpty} from 'ramda-adjunct';

import {ExternalFilter} from '../types/ExternalFilter';
import {InterestVehicleListDataGrid} from '../types/InterestVehicleListDataGrid';
import {composeRangeObject} from './composeRangeObject';

export const makeDataGridFilter = (values: ExternalFilter): InterestVehicleListDataGrid => ({
  vehicleType: values.type ?? ['VEHICLETYPE_PASSENGER_CAR'],
  make: isNilOrEmpty(values.make) ? undefined : values.make,
  model: isNilOrEmpty(values.modelFamily) ? undefined : values.modelFamily,
  fuelType: isNilOrEmpty(values.fuelType) ? undefined : values.fuelType,
  transmission: isNilOrEmpty(values.transmission) ? undefined : values.transmission,
  body: isNilOrEmpty(values.bodyStyle) ? undefined : values.bodyStyle,
  vatDeductible: isNilOrEmpty(values.vatDeductible?.[0])
    ? undefined
    : values.vatDeductible![0]! === 'true'
      ? ['1']
      : ['0'],
  bodyColor: isNilOrEmpty(values.bodyColor) ? undefined : values.bodyColor,
  sellingPriceWithVat: composeRangeObject(values.priceFrom, values.priceTo),
  mileage: composeRangeObject(values.mileageFrom, values.mileageTo),
  realProduction: composeRangeObject(values.yearFrom, values.yearTo),
  enginePower: composeRangeObject(values.powerFrom, values.powerTo),
  engineCapacity: composeRangeObject(values.cubicCapacityFrom, values.cubicCapacityTo),
});
