import {BaseFlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {GetInvoiceApiResponse} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

type InvoiceCategory = GetInvoiceApiResponse['invoiceCategory'] | Nullish;

export const getInvoiceCategoryFlagProps = (
  invoiceCategory: InvoiceCategory
): BaseFlagProps | null => {
  const flagProps = match<InvoiceCategory, BaseFlagProps | null>(invoiceCategory)
    .with('internal', always({label: i18n.t('general.labels.internal'), colorScheme: 'yellow'}))
    .otherwise(always(null));

  return flagProps;
};
