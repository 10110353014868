import {TabProps, Tabs} from 'platform/components';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always, inc, last} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {BulkContext, ContextTarget, OrderDiscriminator, OrderResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {convertStringToCamelCase, suffixTestId, TestIdProps, useQueryState} from 'shared';

import {CheckoutOrder} from './CheckoutOrder';
import {CheckoutProps} from './types/CheckoutProps';

export interface CheckoutOrderCard extends CheckoutProps {
  orders: OrderResponseBody[];
  orderType: OrderDiscriminator;
  customerId: string;
  documentContextId?: string;
  documentContextTarget?: ContextTarget;
  isDeductibleBrokerage?: boolean;
  documentBulkContext?: BulkContext;
  shouldHidePaymentTab?: boolean;
}

export function CheckoutOrderCard(props: CheckoutOrderCard & TestIdProps) {
  const [activeTabId, setActiveTabId] = useQueryState('order-tabs');

  return (
    <Box backgroundColor="palettes.white.10.100" borderRadius="small" boxShadow="elevation_1">
      <Tabs
        data-testid={suffixTestId(
          `checkout-${convertStringToCamelCase(props.orderType)}-orders`,
          props
        )}
        variant="container"
        activeTabId={activeTabId ?? last(props.orders)?.id}
        onChange={setActiveTabId}
        tabs={props.orders.map((order, orderIndex) => ({
          'data-testid': suffixTestId(
            `checkout-${convertStringToCamelCase(props.orderType)}-orders[${orderIndex}]`,
            props
          ),
          id: order.id,
          icon: match<boolean, TabProps['icon']>(isPaymentPending(order))
            .with(false, always('success'))
            .with(true, always('pending'))
            .exhaustive(),
          title: `${i18n.t('entity.checkout.labels.order')} ${
            isNotNilOrEmpty(props.orders) ? inc(orderIndex) : ''
          }`,
          content: (
            <CheckoutOrder
              checkoutId={props.checkoutInfo.id}
              order={order}
              allOrders={props.orders}
              documentContextId={props.documentContextId}
              documentContextTarget={props.documentContextTarget}
              documentBulkContext={props.documentBulkContext}
              shouldHidePaymentTab={props.shouldHidePaymentTab}
              {...props}
              data-testid={suffixTestId(
                `checkout-${convertStringToCamelCase(props.orderType)}-orders[${orderIndex}]`,
                props
              )}
            />
          ),
        }))}
      />
    </Box>
  );
}

const isPaymentPending = (order: OrderResponseBody) =>
  order.payments.some((payment) => payment.paymentState !== 'PAID');
