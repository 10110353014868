import {isNotNil} from 'ramda';

import {FiltersType} from '../../../types/FiltersType';
import {useFilters} from '../../FiltersContext/FiltersContext';

export type BooleanBasedFilterName = 'reclaimableVat' | 'carvagoAvailability';

export const getBooleanBasedTags = (
  filterName: BooleanBasedFilterName,
  translation: string,
  filters: FiltersType,
  onUpdateFilters: (path: string[], value: any) => void
) => {
  const filterValue = filters[filterName];
  return isNotNil(filterValue)
    ? [{label: translation, onDelete: () => onUpdateFilters([filterName], undefined)}]
    : [];
};

export const useGetBooleanBasedTags = () => {
  const {filters, onUpdateFilters} = useFilters();

  return (filterName: BooleanBasedFilterName, translation: string) =>
    getBooleanBasedTags(filterName, translation, filters, onUpdateFilters);
};
