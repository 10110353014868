import {unwrapResult} from '@reduxjs/toolkit';

import {isNil} from 'ramda';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {calculateFromWithoutVat, calculateFromWithVat} from '../../../store/common/actions';
import {PossibleObject} from '../../../types/PossibleObject';
import {VatCalculation} from '../../../types/VatCalculation';
import {VatRate} from '../../../types/VatRate';
import {FieldName} from '../types/FieldName';
import {FormApi} from '../types/FormApi';

export type UsePriceCalculation = (
  amount: string | undefined | null,
  withVat?: boolean,
  vatRateType?: VatRate['type'],
  countryCode?: string
) => Promise<void>;
/**
 * @deprecated - use platform instead
 */
export const usePriceCalculation = <
  FormValues extends PossibleObject = undefined,
  InitialFormValues extends PossibleObject = undefined,
>(
  form: FormApi<FormValues, InitialFormValues>,
  name: FieldName<FormValues>
): UsePriceCalculation => {
  const dispatch = useThunkDispatch();

  const calculatePrice = async (
    amount: string | undefined | null,
    withVat: boolean,
    vatRateType: VatRate['type'],
    countryCode = 'CZE' // Note: BE does not support other countries
  ): Promise<VatCalculation | null> => {
    let res: VatCalculation | null;

    if (!isNaN(Number(amount))) {
      const query = {
        vatRateType: vatRateType || VatRate.type.S,
        countryCode,
        amount: Number(amount).toString(),
      };

      if (withVat) {
        res = unwrapResult(await dispatch(calculateFromWithVat.action(query)));
      } else {
        res = unwrapResult(await dispatch(calculateFromWithoutVat.action(query)));
      }

      return res;
    } else {
      return null;
    }
  };

  return async (amount, withVat, vatRateType, countryCode): Promise<void> => {
    if (isNil(amount) || amount === '') {
      if (withVat === undefined || withVat) {
        form.change(`${name}.withoutVat.amount` as FieldName<FormValues>, '');
      } else {
        form.change(`${name}.withVat.amount` as FieldName<FormValues>, '');
      }

      return;
    }

    const vatCalculation = await calculatePrice(
      amount,
      withVat === undefined || withVat,
      vatRateType || VatRate.type.Z,
      countryCode
    );

    if (withVat === undefined || withVat) {
      form.change(
        `${name}.withoutVat.amount` as FieldName<FormValues>,
        vatCalculation?.withoutVat || null
      );
      form.change(`${name}.vat` as FieldName<FormValues>, vatCalculation?.vat || null);
    } else {
      form.change(
        `${name}.withVat.amount` as FieldName<FormValues>,
        vatCalculation?.withVat || null
      );
      form.change(`${name}.vat` as FieldName<FormValues>, vatCalculation?.vat || null);
    }
  };
};
