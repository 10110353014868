import {IFilter, IFilterParams} from '@ag-grid-community/core';
import {AsyncMultiChoice, Chips, Separator, useTranslationContext} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {ForwardedRef, forwardRef, useCallback, useImperativeHandle, useRef, useState} from 'react';

import {equals} from 'ramda';
import {isArray, isNilOrEmpty, isNotNilOrEmpty, isString} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {useHttpCalls} from '../../hooks/useHttpCalls';
import {QuickFilters} from '../../types/Api';

const EMPTY_VALUE = decodeURI('%00');

export type SuggestionFilterProps = {
  suggestId: string;
  isDisabled: boolean;
} & IFilterParams &
  QuickFilters &
  TestIdProps;

function SuggestionFilterComponent(
  {
    filterChangedCallback,
    suggestId,
    column,
    isDisabled,
    quickFilters,
    ...props
  }: SuggestionFilterProps,
  ref: ForwardedRef<IFilter>
) {
  const http = useHttpCalls();
  const t = useTranslationContext();
  const isActive = useRef<boolean>(false);
  const [selectedIds, _setSelectedIds] = useState<string[] | string>([]);
  const setSelectedIds = useCallback((values: typeof selectedIds) => {
    isActive.current = isNotNilOrEmpty(values);
    _setSelectedIds(values);
  }, []);
  const {onChipsChange} = useFilterOnChipsChange({
    filterChangedCallback,
    setFilterValue: setSelectedIds,
    defaultValue: [],
  });

  const fetchOptions = async (query: string) => {
    const getOptionsArgs = {
      suggestId,
      inputValue: isNilOrEmpty(query) ? EMPTY_VALUE : query,
      limit: 20,
    };
    const result = await http.getSuggestionOptions(getOptionsArgs);
    return result ?? [];
  };

  const fetchLabels = async (values: Array<string>) => {
    const getLabelsArgs = {
      suggestId,
      keys: values,
    };
    const result = await http.getSuggestionLabels(getLabelsArgs);
    return result ?? [];
  };
  const onChange = (values: string[] | null) => {
    if (Array.isArray(values)) {
      setSelectedIds(values);
    } else {
      setSelectedIds([]);
    }
    filterChangedCallback();
  };

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return isActive.current;
    },

    doesFilterPass() {
      return true;
    },

    getModel() {
      if (selectedIds?.length === 0) {
        return undefined;
      }
      return selectedIds;
    },

    setModel(model: Array<string> | undefined) {
      if (equals(model, selectedIds)) {
        return;
      }
      if (isString(model)) {
        return onChipsChange([model]);
      }
      onChange(model ?? null);
    },
  }));

  const isQuickFilterValue = isString(selectedIds) ? [selectedIds] : undefined;

  return (
    <VStack>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              data-testid="quick-filters-suggestion-filter"
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <AsyncMultiChoice<string>
        value={isArray(selectedIds) ? selectedIds : [selectedIds]}
        onChange={onChange}
        loadOptions={fetchOptions}
        loadLabels={(values) => fetchLabels(values as string[])}
        defaultOptions
        noOptionsMessage={() => t('page.datagrid.labels.noSuggestFilterOptions')}
        placeholder={t('page.datagrid.filter.suggestPlaceholder', {
          field: column.getColDef().headerName,
        })}
        isDisabled={isDisabled}
        isNotClearable
        data-testid={suffixTestId('multi-choice-suggestion-filter', props)}
      />
    </VStack>
  );
}

export const SuggestionFilter = forwardRef<IFilter, SuggestionFilterProps>(
  SuggestionFilterComponent
);
