import {$isLinkNode} from '@lexical/link';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$findMatchingParent} from '@lexical/utils';
import {$getSelection, $isRangeSelection, CLICK_COMMAND, COMMAND_PRIORITY_LOW} from 'lexical';

import {useEffect} from 'react';

import {getSelectedNode} from '../utils/getSelectedNode';

export const OpenLinkPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      CLICK_COMMAND,
      (payload) => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const node = getSelectedNode(selection);
          const linkNode = $findMatchingParent(node, $isLinkNode);
          if ($isLinkNode(linkNode) && (payload.metaKey || payload.ctrlKey)) {
            const url = linkNode.getURL();

            if (url.startsWith('mailto:')) {
              window.location.href = linkNode.getURL();
            } else {
              window.open(linkNode.getURL(), '_blank');
            }
            return true;
          }
        }
        return false;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor]);

  return null;
};
