import {
  CreateDataQueryApiResponse,
  CreateDataQueryApiArg,
  ApplySharedPresetApiArg,
  ApplySharedPresetApiResponse,
} from '../types/datagridV6';
import {omneticApi} from './baseApi/omneticApi';

export const datagridApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getGridCodesList: build.query<string[], void>({
      query: () => ({
        url: `/dms/v5/data-grid`,
      }),
    }),
    createDataQuery: build.query<CreateDataQueryApiResponse, CreateDataQueryApiArg>({
      query: (queryArg) => ({
        url: `/dms/v6/data-grid/${queryArg.code}/data-query`,
        method: 'POST',
        body: queryArg.createDataQueryRequestBody,
      }),
    }),
    applySharedPreset: build.mutation<ApplySharedPresetApiResponse, ApplySharedPresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v6/data-grid/${queryArg.code}/apply-shared-preset`,
        method: 'PUT',
        body: queryArg.applySharedPresetRequestBody,
        params: {instance: queryArg.instance},
      }),
    }),
  }),
});

export const {useGetGridCodesListQuery, useCreateDataQueryQuery, useApplySharedPresetMutation} =
  datagridApi;
