import type {CancelablePromise} from '../types/CancelablePromise';
import type {MakeVehicleType} from '../types/MakeVehicleType';
import {request as __request} from '../utils/request';

export class CustomTenantCatalogueService {
  /**
   * @returns MakeVehicleType
   * @throws ApiError
   */
  public static getCustomTenantCatalogue({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Record<string, MakeVehicleType>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle/custom-tenant-catalogue',
      headers: {
        Authorization: authorization,
      },
    });
  }
}
