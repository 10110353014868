import {complement, findLastIndex, take, whereEq} from 'ramda';

import {WizardStepConfig} from '../WizardStepConfig';

const isStepAvailable = complement(whereEq({isDisabled: true}));

export const getPreviousStep = (steps: WizardStepConfig[], currentStep: number): number | null => {
  const previousSteps = take(currentStep, steps);
  const foundIndex = findLastIndex<WizardStepConfig>(isStepAvailable, previousSteps);
  if (foundIndex < 0) {
    return null;
  }
  return foundIndex;
};
