import {Card, FormControl, FormField} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';

import {BaseArticle, basePriceSourceObject, salesPriceCalculationObject} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

interface OverviewPricesSettingsFormProps {
  control: FormControl<BaseArticle>;
}

export function OverviewPricesSettingsForm(props: OverviewPricesSettingsFormProps) {
  return (
    <Card title={i18n.t('entity.warehouse.labels.pricesSettings')}>
      <Grid columns={4} spacing={8}>
        <VStack spacing={4}>
          <FormField
            control={props.control}
            isDisabled
            type="currency"
            name="pricesSettings.avgPurchacePriceWithoutVat"
            label={i18n.t('warehouse.labels.avgPurchasePriceWithoutVat')}
            data-testid={testIds.warehouse.articleDetailOverview('avgPurchasePriceWithoutVat')}
          />
          <FormField
            control={props.control}
            isDisabled
            type="currency"
            name="pricesSettings.lastPurchacePriceWithoutVat"
            label={i18n.t('warehouse.labels.lastPurchasePriceWithoutVat')}
            data-testid={testIds.warehouse.articleDetailOverview('lastPurchasePriceWithoutVat')}
          />
          <FormField
            control={props.control}
            isDisabled
            type="currency"
            name="pricesSettings.recommendedPurchacePriceWithoutVat"
            label={i18n.t('warehouse.labels.recommendedPurchasePriceWithoutVat')}
            data-testid={testIds.warehouse.articleDetailOverview(
              'recommendedPurchasePriceWithoutVat'
            )}
          />
        </VStack>
        <VStack spacing={4}>
          <FormField
            control={props.control}
            isDisabled
            type="currency"
            name="pricesSettings.avgPurchacePriceWithVat"
            label={i18n.t('warehouse.labels.avgPurchasePriceWithVat')}
            data-testid={testIds.warehouse.articleDetailOverview('avgPurchasePriceWithVat')}
          />
          <FormField
            control={props.control}
            isDisabled
            type="currency"
            name="pricesSettings.lastPurchacePriceWithVat"
            label={i18n.t('warehouse.labels.lastPurchasePriceWithVat')}
            data-testid={testIds.warehouse.articleDetailOverview('lastPurchasePriceWithVat')}
          />
          <FormField
            control={props.control}
            isDisabled
            type="currency"
            name="pricesSettings.recommendedPurchacePriceWithVat"
            label={i18n.t('warehouse.labels.recommendedPurchasePriceWithVat')}
            data-testid={testIds.warehouse.articleDetailOverview('recommendedPurchasePriceWithVat')}
          />
        </VStack>
        <VStack spacing={4}>
          <FormField
            control={props.control}
            type="radio"
            direction="column"
            spacing={2}
            name="pricesSettings.salesPriceCalculation"
            label={i18n.t('warehouse.labels.salesPriceCalculation')}
            options={salesPriceCalculationOptions}
            data-testid={testIds.warehouse.articleDetailOverview('salesPriceCalculation')}
          />
          <FormField
            control={props.control}
            type="switch"
            name="pricesSettings.unitSalesPriceWithVat"
            label={i18n.t('warehouse.labels.unitSalesPriceWithVat')}
            data-testid={testIds.warehouse.articleDetailOverview('unitSalesPriceWithVat')}
          />
        </VStack>
        <VStack spacing={4}>
          <FormField
            control={props.control}
            type="radio"
            direction="column"
            spacing={2}
            name="pricesSettings.basePriceSource"
            label={i18n.t('warehouse.labels.basePriceSource')}
            options={basePriceCalculationOptions}
            data-testid={testIds.warehouse.articleDetailOverview('basePriceSource')}
          />
        </VStack>
      </Grid>
    </Card>
  );
}

const salesPriceCalculationOptions = [
  {
    label: i18n.t('general.labels.salesPriceCalculationMargin'),
    value: salesPriceCalculationObject.MARGIN_ON_BASE,
  },
  {
    label: i18n.t('general.labels.salesPriceCalculationMarkup'),
    value: salesPriceCalculationObject.MARKUP_ON_BASE,
  },
  {
    label: i18n.t('general.labels.salesPriceCalculationProfitOnBase'),
    value: salesPriceCalculationObject.PROFIT_ON_BASE,
  },
  {
    label: i18n.t('general.labels.salesPriceCalculationManual'),
    value: salesPriceCalculationObject.MANUAL,
  },
  {
    label: i18n.t('general.labels.salesPriceCalculationRecommendedPrice'),
    value: salesPriceCalculationObject.RECOMMENDED_PRICE,
  },
  {
    label: i18n.t('general.labels.salesPriceCalculationMarketingCode'),
    value: salesPriceCalculationObject.MARKETING_CODE,
  },
];

const basePriceCalculationOptions = [
  {
    label: i18n.t('general.labels.basePriceCalculationAveragePurchasePrice'),
    value: basePriceSourceObject.AVG_PURCHASE,
  },
  {
    label: i18n.t('general.labels.basePriceCalculationLastPurchasePrice'),
    value: basePriceSourceObject.LAST_PURCHASE,
  },
  {
    label: i18n.t('general.labels.basePriceCalculationLastRecommendedPrice'),
    value: basePriceSourceObject.RECOMMENDED,
  },
  {
    label: i18n.t('general.labels.basePriceCalculationManual'),
    value: basePriceSourceObject.MANUAL,
  },
];
