import {number, object, string, SchemaOf, boolean} from 'yup';

import i18n from '@omnetic-dms/i18n';

import {StateRequestBody} from '../types/StateRequestBody';

const REGISTRATION_INPUT_REGEX = /^[A-Z0-9]*$/;

export const $StateRequestBody: SchemaOf<StateRequestBody> = object().shape({
  mileage: number().nullable(),
  condition: string().nullable(),
  gasRevisionValidUntil: string().nullable(),
  hasCoc: boolean().nullable(),
  isAcceptableForTechnicalInspection: boolean().nullable(),
  lastServiceDate: string().nullable(),
  lastServiceMileage: number().nullable(),
  nextServiceDate: string().nullable(),
  nextServiceMileage: number().nullable(),
  ownerCount: number().nullable(),
  pressureVesselValidUntil: string().nullable(),
  primaryKeyCount: number().nullable(),
  realMileage: number().nullable(),
  registrationPlate: string().nullable(),
  serviceBookState: string().nullable(),
  serviceBookType: string().nullable(),
  technicalInspectionNote: string().nullable(),
  technicalInspectionValidUntil: string().nullable(),
  vehicleRegistrationNumber: string()
    .nullable()
    .matches(REGISTRATION_INPUT_REGEX, i18n.t('general.validations.invalidFormat')),
  registrationCertificateNumber: string().matches(
    REGISTRATION_INPUT_REGEX,
    i18n.t('general.validations.invalidFormat')
  ),
  otherRecords: string().nullable(),
});
