import {GridItem, Grid} from 'platform/foundation';

import {FC} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {TConditionForm} from '../../../types/ConditionTypes';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {hasWheelSetData} from '../utils/hasWheelSetData';
import {Wheel} from './Wheel';

type WheelsSetProps = {
  categories: AuditCategoryOfStructure[];
  categoryKey: string;
};

export const WheelsSet: FC<WheelsSetProps & TestIdProps> = ({categories, categoryKey, ...rest}) => {
  const {
    form: {getState},
  } = useFormRenderer<TConditionForm>();

  return (
    <Grid columns={4} data-testid={suffixTestId('wheelsSet', rest)}>
      {categories.map((category) => (
        <GridItem key={`wheelSet-${category.id}`} span={1}>
          <Wheel
            data={category}
            categoryKey={categoryKey}
            hasWheelSetData={hasWheelSetData(getState().values, categories)}
            data-testid={suffixTestId(`wheelsSet-${categoryKey}`, rest)}
          />
        </GridItem>
      ))}
    </Grid>
  );
};
