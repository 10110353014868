import {DefaultComponentsTheme, OptionTypeBase} from 'platform/components';
import {Spinner, Center} from 'platform/foundation';
import styled, {useTheme} from 'styled-components';

import {FC, KeyboardEvent} from 'react';
import {GroupBase, OptionsOrGroups, StylesConfig} from 'react-select';
import AsyncSelect from 'react-select/async';
import {SelectComponents} from 'react-select/dist/declarations/src/components';

import {isNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {customStyles} from '../Dropdown/customStyles';
import {ClearIndicator, MultiValue} from '../Dropdown/Dropdown';
import {
  SearchDropdownControl,
  SearchDropdownInput,
  SearchDropdownMenu,
  SearchDropdownMenuList,
  SearchDropdownOption,
  SearchDropdownSearchDropdownProps,
  SearchDropdownWrapper,
} from '../SearchDropdown/SearchDropdown';

type LoadAsyncSearch<TOptionType extends OptionTypeBase<any> = OptionTypeBase<any>> = {
  loadOptions?: (
    inputValue: string,
    callback: (options: OptionsOrGroups<TOptionType, GroupBase<TOptionType>>) => void
  ) => Promise<OptionTypeBase<any>[]> | void;
};

export type AsyncSearchDropdownProps = unknown &
  Partial<SearchDropdownSearchDropdownProps> &
  LoadAsyncSearch;

export const AsyncWrapper = styled(SearchDropdownWrapper)`
  .react-select__control {
    margin: 8px;
  }
`;

export const AsyncSearchDropdown: FC<AsyncSearchDropdownProps & TestIdProps> = (props) => {
  const {noOptionsMessage, loadOptions, onChange, enterPreventDefault} = props;
  const theme = useTheme();

  return (
    <AsyncWrapper>
      <AsyncSelect
        classNamePrefix="react-select"
        menuPlacement={props.menuPlacement || 'auto'}
        {...props}
        menuIsOpen
        isClearable
        noOptionsMessage={noOptionsMessage}
        cacheOptions
        onKeyDown={(e: KeyboardEvent) => {
          if (enterPreventDefault) {
            if (
              e.code === 'Enter' ||
              (!isNilOrEmpty((e.target as HTMLInputElement).value) && e.code === 'Space')
            ) {
              e.preventDefault();
            }
          }
        }}
        defaultOptions
        loadOptions={loadOptions}
        onChange={onChange}
        styles={
          {
            ...customStyles(theme as DefaultComponentsTheme),
            ...(props.styles || {}),
            valueContainer: (provided) => ({
              ...provided,
              paddingLeft: 0,
            }),
          } as StylesConfig<OptionTypeBase<any>, boolean, GroupBase<OptionTypeBase<any>>>
        }
        loadingMessage={() => (
          <Center>
            <Spinner />
          </Center>
        )}
        components={
          {
            LoadingIndicator: undefined,
            DropdownIndicator: undefined,
            MultiValue,
            Menu: SearchDropdownMenu,
            Input: SearchDropdownInput,
            ClearIndicator,
            Option: SearchDropdownOption({
              'data-testid': props['data-testid'],
            }),
            Control: SearchDropdownControl,
            MenuList: SearchDropdownMenuList({
              'data-testid': props['data-testid'],
            }),
            ...(props.components || {}),
          } as Partial<
            SelectComponents<OptionTypeBase<any>, boolean, GroupBase<OptionTypeBase<any>>>
          >
        }
        data-testid={suffixTestId('asyncSelect', props)}
      />
    </AsyncWrapper>
  );
};
