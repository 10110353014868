import {Space, Text} from 'platform/foundation';

import {FieldValues, UseFormReturn} from 'react-hook-form';
import {Blocker} from 'react-router-dom';

import {Button} from '../../Button/Button';
import {ButtonGroup} from '../../ButtonGroup/ButtonGroup';
import {Dialog} from '../../Dialog/Dialog';
import {useTranslationContext} from '../../TranslationProvider/TranslationContext';

interface UnsavedChangesNotificationProps<TFieldValues extends FieldValues> {
  blocker: Blocker;
  formApi: UseFormReturn<TFieldValues, unknown, undefined>;
}

export function UnsavedChangesNotification<TFieldValues extends FieldValues>(
  props: UnsavedChangesNotificationProps<TFieldValues>
) {
  const t = useTranslationContext();

  const handleLeave = () => {
    props.formApi.reset();
    props.blocker.proceed?.();
  };

  const handleStayOnPage = () => {
    props.blocker.reset?.();
  };

  return (
    <Dialog
      isOpen={props.blocker.state === 'blocked'}
      onClose={handleStayOnPage}
      size="small"
      title={t('general.notifications.unsavedChanges.title')}
      data-testid="formSaver-dialog"
    >
      <Text size="small">{t('general.notifications.unsavedChanges.description')}</Text>

      <Space vertical={4} />

      <ButtonGroup align="right">
        <Button
          variant="secondary"
          title={t('general.labels.leavePage')}
          onClick={handleLeave}
          data-testid="formSaverModal-button-yes"
        />
        <Button
          title={t('general.labels.stayOnPage')}
          onClick={handleStayOnPage}
          data-testid="formSaverModal-button-no"
        />
      </ButtonGroup>
    </Dialog>
  );
}
