import type {AuditAssetsDeleteRequestBody} from '../types/AuditAssetsDeleteRequestBody';
import type {AuditAssetsUpload} from '../types/AuditAssetsUpload';
import type {AuditAssetsUploadRequestBody} from '../types/AuditAssetsUploadRequestBody';
import type {AuditAssetsUploadV2RequestBody} from '../types/AuditAssetsUploadV2RequestBody';
import type {CancelablePromise} from '../types/CancelablePromise';
import type {ControlDataImageDeletion} from '../types/ControlDataImageDeletion';
import type {GetAuditAsset} from '../types/GetAuditAsset';
import {request as __request} from '../utils/request';

export class CarAuditVehicleAuditAssetService {
  /**
   * Delete image from car audit control data.
   *
   * @returns ControlDataImageDeletion
   * @throws ApiError
   */
  public static auditAssetsDelete({
    imageId,
    authorization,
  }: {
    withoutReducer?: boolean;
    userName?: string;
    imageId: string;
    authorization?: string;
  }): CancelablePromise<ControlDataImageDeletion> {
    return __request({
      method: 'DELETE',
      url: '/car-audit/v1/audits/{imageId}/images',
      path: {
        imageId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * Image upload to car audit control data.
   *
   * @returns AuditAssetsUpload
   * @throws ApiError
   */
  public static auditAssetsUpload({
    requestBody,
    authorization,
  }: {
    userName?: string;
    withoutReducer?: boolean;
    requestBody?: AuditAssetsUploadRequestBody;
    authorization?: string;
  }): CancelablePromise<AuditAssetsUpload> {
    return __request({
      method: 'POST',
      url: '/car-audit/v1/audits/images/upload',
      body: requestBody,
      headers: {
        Authorization: authorization,
      },
      mediaType: 'application/json',
    });
  }

  /**
   * Image upload to car audit control data V2.
   *
   * @returns void
   * @throws ApiError
   */
  public static auditAssetsUploadV2({
    requestBody,
    authorization,
  }: {
    requestBody?: AuditAssetsUploadV2RequestBody;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'POST',
      url: '/car-audit/v2/audits/images/upload',
      body: requestBody,
      headers: {
        Authorization: authorization,
      },
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve Urls For Uploaded Asset
   *
   * @returns GetAuditAsset
   * @throws ApiError
   */
  public static getAuditAsset({
    assetId,
    authorization,
  }: {
    assetId: string;
    authorization?: string;
  }): CancelablePromise<GetAuditAsset> {
    return __request({
      method: 'GET',
      url: '/car-audit/v1/audit/asset/{assetId}',
      headers: {
        Authorization: authorization,
      },
      path: {
        assetId,
      },
    });
  }

  /**
   * Delete images from car audit control data.
   *
   * @returns ControlDataImageDeletion
   * @throws ApiError
   */
  public static temporaryAuditAssetsDelete({
    requestBody,
    authorization,
  }: {
    requestBody?: AuditAssetsDeleteRequestBody;
    authorization?: string;
  }): CancelablePromise<ControlDataImageDeletion> {
    return __request({
      method: 'DELETE',
      url: '/car-audit/v1/audits/images',
      body: requestBody,
      headers: {
        Authorization: authorization,
      },
      mediaType: 'application/json',
    });
  }
}
