import {css} from 'styled-components';

import {ReactElement} from 'react';

import {testIds} from '@omnetic-dms/routes';

import {UsersTable} from './UsersTable';

export const Users = (): ReactElement => (
  <div
    css={css`
      width: 100%;
      overflow-x: auto;
    `}
    data-testid={testIds.settings.userManagement('tableContainer')}
  >
    <UsersTable />
  </div>
);
