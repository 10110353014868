import {getFilePreviewUrl, showNotification} from 'platform/components';
import {Link} from 'platform/foundation';

import {forwardRef, MouseEventHandler, Ref} from 'react';

import {isNil} from 'ramda';

import {openFile} from 'shared';

import {ICellRenderer, ICellRendererParams} from '../../types/AgGridTypes';

export type FileType = string;

export interface FileCellRendererProps extends ICellRendererParams {
  is: string;
  title: string;
  type: FileType;
  remoteUrl: string;
}

function FileCellRendererComponent(props: FileCellRendererProps, ref: Ref<ICellRenderer>) {
  const values = props?.value;

  const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    const url = getFilePreviewUrl(props?.value?.remoteUrl);

    if (isNil(url)) {
      showNotification.error();
      return;
    }

    openFile(url);
  };

  return <Link title={values?.title} onClick={handleOnClick} size="small" download />;
}

export const FileCellRenderer = forwardRef<ICellRenderer, FileCellRendererProps>(
  FileCellRendererComponent
);
