import {FormControl, FormField} from 'platform/components';

import {FieldValues, Path} from 'react-hook-form';

import {isNilOrEmpty, isTrue} from 'ramda-adjunct';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {useVehicleCatalogue} from '../../hooks/useVehicleCatalogue';
import {getSecondaryFuelTypeOptionsByFuelType} from './utils/getSecondaryFuelTypeOptionsByFuelType';

type SecondaryFuelTypeFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  fuelType: string | Nullish;
  vehicleType: string | Nullish;
  control: FormControl<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isDeselectable?: boolean;
  onChange?: (value: string[] | Nullish) => void;
} & RequiredTestIdProps;

export function SecondaryFuelTypeField<TFieldValues extends FieldValues = FieldValues>(
  props: SecondaryFuelTypeFieldProps<TFieldValues>
) {
  const [vehicleUtils] = useVehicleCatalogue(props.vehicleType);

  const secondaryFuelTypeOptions = getSecondaryFuelTypeOptionsByFuelType(
    vehicleUtils.fuelTypeOptions,
    props.fuelType
  );

  return (
    <FormField
      data-testid={suffixTestId('secondaryFuelType', props)}
      control={props.control}
      isRequired={props.isRequired}
      isDeselectable={props.isDeselectable}
      isDisabled={isTrue(props.isDisabled) || isNilOrEmpty(vehicleUtils.fuelTypeOptions)}
      name={props.name}
      label={props.label}
      type="chips"
      options={secondaryFuelTypeOptions ?? []}
      onChange={props.onChange}
    />
  );
}
