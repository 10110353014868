import {Country} from '../types/Country';
import {Alpha3CountryCode} from './countryCodes';

const DEFAULT_COUNTRY = 'CZE';

/**
 * @about Returns default country code. If specified, defaultCountry will be returned. Otherwise, the first country in the list will be returned.
 * @param defaultCountry
 * @param preferredCountries
 * @param countries
 */
export const getDefaultCountryCode = (
  defaultCountry: Alpha3CountryCode | undefined,
  preferredCountries: Alpha3CountryCode[] | undefined,
  countries: Country[] | undefined
): Alpha3CountryCode => {
  if (defaultCountry) {
    return defaultCountry;
  }

  if (preferredCountries?.[0]) {
    return preferredCountries[0];
  }

  if (countries?.[0]) {
    return countries[0].countryCode;
  }

  return DEFAULT_COUNTRY;
};
