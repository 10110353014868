import {
  NewOfferPurchaseBrokerageVehicleResponseBody,
  NewOfferPurchaseVehicleResponseBody,
  OfferSaleVehicleResponseBody,
} from '@omnetic-dms/api';

export const isBrokerageVehicle = (
  offerVehicle?:
    | NewOfferPurchaseVehicleResponseBody
    | NewOfferPurchaseBrokerageVehicleResponseBody
    | OfferSaleVehicleResponseBody
): offerVehicle is NewOfferPurchaseBrokerageVehicleResponseBody => {
  if (!offerVehicle) {
    return false;
  }
  return Object.prototype.hasOwnProperty.call(offerVehicle, 'brokerageFees');
};
