import {Box, Center, HStack} from 'platform/foundation';

import {suffixTestId} from 'shared';

import {FlagLabel} from './components/FlagLabel';
import {BaseFlagProps} from './types';
import {getColorsFromScheme, getFlagElementSizes} from './utils';

export type FlagProps = BaseFlagProps & {
  isFullWidth?: boolean;
  isBoldText?: boolean;
};

export function Flag(props: FlagProps) {
  const {height, labelHorizontalPadding, textSize, borderRadius} = getFlagElementSizes(props.size);
  const {backgroundColor, color} = getColorsFromScheme(props.colorScheme, props.isSubtle);

  const isFullWidth = props.isFullWidth ?? false;

  return (
    <HStack justify="flex-start" maxWidth="100%" data-testid={suffixTestId('flagWrapper', props)}>
      <Box
        height={height}
        width={isFullWidth ? '100%' : undefined}
        paddingHorizontal={labelHorizontalPadding}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        maxWidth="100%"
      >
        <Center height="100%">
          <FlagLabel
            color={color}
            size={textSize}
            isBold={props.isBoldText}
            label={props.label}
            data-testid={props['data-testid']}
          />
        </Center>
      </Box>
    </HStack>
  );
}
