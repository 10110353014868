import {openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteMeasurementUnitLabourOperationMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function WorkshopMeasurementUnits() {
  const navigate = useNavigate();

  const [deleteMeasurementUnitLabourOperation] = useDeleteMeasurementUnitLabourOperationMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', 'edit', () =>
        navigate(composePath(settingsRoutes.workshopMeasurementUnitDetail, {params: {id}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteMeasurementUnitLabourOperation({muloId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .otherwise(always(undefined));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.settings.labels.measurementUnits'),
        actions: [
          {
            type: 'button',
            title: i18n.t('page.settings.actions.newMeasurementUnit'),
            onClick: () => navigate(settingsRoutes.workshopMeasurementUnitCreate),
          },
        ],
      }}
      data-testid={testIds.settings.workshopMeasurementUnits('template')}
    >
      <DataGrid gridCode="measurement-unit-labour-operation" actionCallback={actionCallback} />
    </SettingsTemplate>
  );
}
