import {CurrencyInput, FormControl, FormField, isCurrency} from 'platform/components';
import {Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useEffect} from 'react';
import {UseFormReturn, useWatch} from 'react-hook-form';

import {isNil, isNotNil} from 'ramda';

import {
  OrderPaymentResponseBody,
  useGetOrderQuery,
  usePatchCheckoutOrderForeignCurrencyPaymentMutation,
  usePatchCheckoutOrderPaymentMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {DEFAULT_CURRENCY, handleApiError} from '@omnetic-dms/shared';

import {isNilOrZero, suffixTestId, TestIdProps, useDebouncedCallback} from 'shared';

import {useGetSubmitBody} from '../hooks/useGetSubmitBody';
import {FormValuesType} from '../types/FormValuesType';

interface ForeignCurrencyFieldsProps extends TestIdProps {
  payment: OrderPaymentResponseBody;
  orderId: string;
  checkoutId: string;
  formApi: UseFormReturn<FormValuesType>;
  control: FormControl<FormValuesType>;
}

export const ForeignCurrencyFields = (props: ForeignCurrencyFieldsProps) => {
  const [patchOrderPayment] = usePatchCheckoutOrderPaymentMutation();
  const [getPaymentBody] = useGetSubmitBody(props.payment.paymentDiscriminator);
  const [, {isLoading: isPatchingForeignCurrency}] =
    usePatchCheckoutOrderForeignCurrencyPaymentMutation({fixedCacheKey: 'foreignCurrencyPayment'});

  const [amount, exchangeRate, paymentMethod] = useWatch({
    name: ['amount', 'exchangeRate', 'paymentMethod'],
    control: props.control,
  });
  const {data: order} = useGetOrderQuery({
    checkoutId: props.checkoutId,
    orderId: props.orderId,
  });

  const depositCurrency =
    isNotNil(order) && isCurrency(order.totalPrice.withVat.currency)
      ? order.totalPrice.withVat.currency
      : undefined;

  const foreignCurrencyValue = amount / parseFloat(exchangeRate ?? '1');

  const handleUpdateForeignCurrency = useDebouncedCallback(() => {
    const data = props.formApi.getValues();
    const orderPaymentRequestBody = getPaymentBody(data);

    if (isNil(props.payment.exchangeRate)) {
      return;
    }
    if (isNilOrZero(data.amount) || isNilOrZero(parseFloat(data.exchangeRate ?? '0'))) {
      props.formApi.setValue('exchangeRateAmount', '0');
      return;
    }

    patchOrderPayment({
      checkoutId: props.checkoutId,
      orderId: props.orderId,
      paymentId: props.payment.id,
      orderPaymentRequestBody,
    })
      .unwrap()
      .then((data) => {
        const payment = data.payments.find((item) => item.id === props.payment.id);

        props.formApi.setValue('amount', parseFloat(payment?.amount?.amount ?? '0'));
        props.formApi.setValue('exchangeRate', payment?.exchangeRate || null);
        props.formApi.setValue(
          'exchangeRateAmount',
          payment?.foreignCurrencyAmount?.amount || null
        );
      })
      .catch(handleApiError);
  }, 1000);

  useEffect(() => {
    const hasForeignCurrencyPayment = isNotNil(props.payment.exchangeRate);

    match(hasForeignCurrencyPayment)
      .with(true, () => {
        props.formApi.setValue('exchangeRate', props.payment.exchangeRate);
        props.formApi.setValue(
          'exchangeRateAmount',
          props.payment.foreignCurrencyAmount?.amount || null
        );
      })
      .otherwise(() => {
        props.formApi.setValue('exchangeRate', null);
        props.formApi.setValue('exchangeRateAmount', null);
      });
  }, [props.formApi, props.payment.exchangeRate, props.payment.foreignCurrencyAmount?.amount]);

  const isPaymentByBankTransfer = paymentMethod === 'BANK_TRANSFER';
  const isCustomerBankAccountIdError = isNotNil(
    props.formApi.getFieldState('customerBankAccountId')?.error
  );

  const shouldDisableExchangeRateEdit = isPaymentByBankTransfer && isCustomerBankAccountIdError;

  return (
    <Show when={order?.foreignCurrencyPayment}>
      <FormField
        control={props.control}
        name="exchangeRate"
        type="currency"
        currency={depositCurrency}
        isDisabled={isPatchingForeignCurrency || shouldDisableExchangeRateEdit}
        onChange={handleUpdateForeignCurrency}
        label={i18n.t('general.labels.exchangeRate')}
        data-testid={suffixTestId('exchangeRate', props)}
      />
      <CurrencyInput
        isDisabled
        value={Number(foreignCurrencyValue.toFixed(2))}
        currency={
          isCurrency(order?.currency) ? order?.currency || DEFAULT_CURRENCY : DEFAULT_CURRENCY
        }
        label={i18n.t('entity.checkout.labels.amountInForeignCurrency')}
        data-testid={suffixTestId('amountInForeignCurrency', props)}
      />
    </Show>
  );
};
