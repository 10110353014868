import {FormControl, FormField, Separator} from 'platform/components';
import {GridItem, Grid, Space, VStack} from 'platform/foundation';

import {UseFormReturn, useWatch} from 'react-hook-form';

import {isNil} from 'ramda';

import {
  BaseAuthorizationProfile,
  BaseDirectSaleType,
  DirectSaleVariantDetail,
  GetCodeListResponse,
  SeriesResponseBody,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {getOptionsFromAuthorizationProfiles} from '@omnetic-dms/shared';

import {suffixTestId, RequiredTestIdProps} from 'shared';

import {DefaultCustomer} from '../../../../components/DefaultCustomer/DefaultCustomer';
import {SettingsFooter} from '../../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../../components/SettingsSection/SettingsSection';
import {getOptionsFromSeriesList} from '../../../../utils/getOptionsFromSeriesList';
import {getOptionsFromDirectSaleTypes} from './utils/getOptionsFromDirectSaleTypes';
import {getOptionsFromMaterialPriceTypes} from './utils/getOptionsFromMaterialPriceTypes';
import {getOptionsFromWorkRateTypes} from './utils/getOptionsFromWorkRateTypes';

interface GeneralTabProps extends RequiredTestIdProps {
  control: FormControl<DirectSaleVariantDetail>;
  formApi: UseFormReturn<DirectSaleVariantDetail>;
  onDiscard: VoidFunction;
  directSaleVariantId?: string;
  authorizationProfiles?: BaseAuthorizationProfile[];
  directSaleTypes?: BaseDirectSaleType[];
  workRateTypes?: GetCodeListResponse;
  materialPriceTypes?: GetCodeListResponse;
  seriesList?: SeriesResponseBody[];
}

export function GeneralTab(props: GeneralTabProps) {
  const defaultCustomerId = useWatch({name: 'general.defaultCustomerId'});

  const handleCustomerChange = (customerId: string | null) => {
    if (isNil(customerId)) {
      props.formApi.resetField('general.defaultCustomerId');
    }

    // TODO: check in T20-57329
    props.formApi.setValue('general.defaultCustomerId', customerId as string);
  };

  return (
    <>
      <SettingsSection>
        <VStack spacing={4}>
          <Grid columns={1}>
            <FormField
              isRequired
              control={props.control}
              name="general.name"
              type="text"
              label={i18n.t('entity.warehouse.labels.name')}
              data-testid={suffixTestId('name', props)}
            />
          </Grid>

          <Grid columns={2}>
            <GridItem span={1}>
              <FormField
                isRequired
                isNotClearable
                control={props.control}
                name="general.authorizationProfileId"
                type="choice"
                label={i18n.t('entity.warehouse.labels.authorizationProfile')}
                options={getOptionsFromAuthorizationProfiles(props.authorizationProfiles)}
                data-testid={suffixTestId('authorizationProfileId', props)}
              />
            </GridItem>
            <GridItem span={1}>
              <FormField
                isRequired
                control={props.control}
                name="general.docSeriesId"
                type="choice"
                label={i18n.t('entity.warehouse.labels.docSeriesId')}
                options={getOptionsFromSeriesList(props.seriesList, 'warehouse/direct-sale')}
                data-testid={suffixTestId('docSeriesId', props)}
              />
            </GridItem>
          </Grid>

          <Separator />

          <Grid columns={2} align="center">
            <GridItem span={1}>
              <FormField
                isRequired
                isNotClearable
                control={props.control}
                name="general.directSaleTypeId"
                type="choice"
                label={i18n.t('entity.warehouse.labels.directSaleType')}
                options={getOptionsFromDirectSaleTypes(props.directSaleTypes)}
                data-testid={suffixTestId('directSaleTypeId', props)}
              />
            </GridItem>
            <GridItem span={1}>
              <FormField
                control={props.control}
                name="general.isUnitPriceWithVat"
                type="switch"
                label={i18n.t('entity.warehouse.labels.unitPriceWithVAT')}
                data-testid={suffixTestId('isUnitPriceWithVat', props)}
              />
            </GridItem>
          </Grid>

          <Grid columns={4}>
            <GridItem span={2}>
              <FormField
                isRequired
                isNotClearable
                control={props.control}
                name="general.priceType"
                type="choice"
                label={i18n.t('entity.warehouse.labels.materialPriceType')}
                options={getOptionsFromMaterialPriceTypes(props.materialPriceTypes)}
                data-testid={suffixTestId('priceType', props)}
              />
            </GridItem>
            <GridItem span={1}>
              <FormField
                control={props.control}
                name="general.priceTypeRatio"
                type="number"
                decimalPlaces={2}
                label={i18n.t('entity.warehouse.labels.materialPriceTypeRatio')}
                data-testid={suffixTestId('priceTypeRatio', props)}
              />
            </GridItem>
            <GridItem span={1}>
              <FormField
                control={props.control}
                name="general.minimalMaterialMargin"
                type="number"
                label={i18n.t('entity.warehouse.labels.minimumMaterialMargin')}
                data-testid={suffixTestId('minimalMaterialMargin', props)}
              />
            </GridItem>
          </Grid>

          <Grid columns={4}>
            <GridItem span={2}>
              <FormField
                isRequired
                isNotClearable
                control={props.control}
                name="general.rateType"
                type="choice"
                label={i18n.t('entity.warehouse.labels.labourRateType')}
                options={getOptionsFromWorkRateTypes(props.workRateTypes)}
                data-testid={suffixTestId('rateType', props)}
              />
            </GridItem>
            <GridItem span={1}>
              <FormField
                control={props.control}
                name="general.rateTypeRatio"
                type="number"
                decimalPlaces={2}
                label={i18n.t('entity.warehouse.labels.labourRatio')}
                data-testid={suffixTestId('workRatio', props)}
              />
            </GridItem>
            <GridItem span={1}>
              <FormField
                control={props.control}
                name="general.minimalWorkMargin"
                type="number"
                label={i18n.t('entity.warehouse.labels.minimumLabourMargin')}
                data-testid={suffixTestId('minimalWorkMargin', props)}
              />
            </GridItem>
          </Grid>

          <Grid columns={2}>
            <GridItem span={1}>
              <FormField
                isRequired
                control={props.control}
                name="general.sufix"
                type="text"
                label={i18n.t('entity.warehouse.labels.suffix')}
                data-testid={suffixTestId('sufix', props)}
              />
            </GridItem>
          </Grid>

          <Grid columns={1}>
            <FormField
              control={props.control}
              name="general.note"
              type="textarea"
              label={i18n.t('entity.warehouse.labels.note')}
              data-testid={suffixTestId('note', props)}
            />
          </Grid>
        </VStack>
      </SettingsSection>

      <VStack spacing={4}>
        <Separator />
        <DefaultCustomer
          customerId={defaultCustomerId}
          onCustomerChange={(customerId) => handleCustomerChange(customerId)}
          data-testid={suffixTestId('defaultCustomerId', props)}
        />
        <Separator />
        <FormField
          control={props.control}
          name="general.isChangeableDefaultCustomer"
          type="switch"
          label={i18n.t('entity.warehouse.labels.changeDefaultCustomerIsAllowed')}
          data-testid={suffixTestId('isChangeableDefaultCustomer', props)}
        />
      </VStack>

      <Space vertical={18} />

      <SettingsFooter
        actions={[
          {
            type: 'button',
            variant: 'secondary',
            title: isNil(props.directSaleVariantId)
              ? i18n.t('general.actions.discard')
              : i18n.t('general.actions.discardChanges'),
            onClick: props.onDiscard,
            'data-testid': suffixTestId('discard', props),
          },
          {
            type: 'form-button',
            control: props.control,
            buttonType: 'submit',
            variant: 'primary',
            title: isNil(props.directSaleVariantId)
              ? i18n.t('general.actions.save')
              : i18n.t('general.actions.saveChanges'),
            'data-testid': suffixTestId('submit', props),
          },
        ]}
      />
    </>
  );
}
