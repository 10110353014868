import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import axios, {AxiosRequestConfig} from 'axios';
import i18n from 'i18next';
import {showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {isNotNil} from 'ramda';

import {useDataGridContext} from '../context/useDataGridContext';
import {useDataGridErrorContext} from '../context/useDataGridErrorContext';
import {
  ActivePreset,
  BeVirtualPreset,
  GetDataIdsByQuery,
  GetDataQueryRequest,
  GetDataQueryResponse,
  GetFilterSchemaParams,
  GetPresetResponse,
  GetPresetsResponse,
  GetSuggestionLabelsParams,
  GetSuggestionOptionsParams,
  PostPresetRequest,
  PostPresetResponse,
  Preset,
  PutPresetRenameRequest,
  PutPresetRenameResponse,
  PutPresetRequest,
  PutPresetResponse,
  QueryIdRequestBody,
  RowV5ResponseBody,
  SharePresetRequestBody,
  SharePresetResponseBody,
  SharePresetToUseRequestBody,
  SharePresetToUseResponseBody,
  VirtualPreset,
} from '../types/Api';
import {ConnectionOverride} from '../types/ConnectionOverride';
import {isApiException} from '../utils/isApiException';
import {transformations} from '../utils/transformationLayer';

export type UseHttpCalls = {
  gridCode?: string;
  presetInstanceId?: string;
  connectionOverride?: ConnectionOverride;
  onApiError?: (error: FetchBaseQueryError) => void;
};

export const useHttpCalls = (settings?: UseHttpCalls) => {
  const context = useDataGridContext();
  const errorContext = useDataGridErrorContext();

  const handleApiError = (error: any) => {
    settings?.onApiError?.(error.response);
    errorContext?.onApiError(error.response);
  };

  const gridCode = settings?.gridCode ?? context?.gridCode;
  const presetInstanceId = settings?.presetInstanceId ?? context?.presetInstanceId;

  const BASE_V4_DATAGRID_URL = `/v4/data-grid/`;

  const baseURL = settings?.connectionOverride?.baseUrl ?? context?.connectionOverride?.baseUrl;
  const authorizationToken =
    settings?.connectionOverride?.authorizationToken ??
    context?.connectionOverride?.authorizationToken;

  const axiosBaseConfig: AxiosRequestConfig = {
    baseURL,
    headers: isNotNil(authorizationToken) ? {Authorization: authorizationToken} : undefined,
  };

  const axiosConfig = {
    ...axiosBaseConfig,
    params: {instance: presetInstanceId ?? null},
  };

  const getPreset = (presetId: string) =>
    axios
      .get(`/v6/data-grid/${gridCode}/preset/${presetId}`, axiosConfig)
      .then((response) => response.data as GetPresetResponse)
      .catch(handleApiError);

  const getVirtualPreset = () =>
    axios
      .get(`/v6/data-grid/${gridCode}/virtual-preset`, axiosConfig)
      .then((response) => response.data as BeVirtualPreset)
      .catch(handleApiError);

  const deleteVirtualPreset = () =>
    axios
      .delete(`/v6/data-grid/${gridCode}/virtual-preset`, axiosBaseConfig)
      .then(() => getVirtualPreset())
      .catch(handleApiError);

  const getActivePreset = (
    virtualPreset: VirtualPreset,
    customPresets: Preset[]
  ): Promise<ActivePreset | void> => {
    const activeCustomPreset = customPresets.find((customPreset) => customPreset.isActive);

    if (!activeCustomPreset) {
      return Promise.resolve(virtualPreset);
    }

    return getPreset(activeCustomPreset.id);
  };

  return {
    getActivePreset,
    getPreset,
    getVirtualPreset,
    deleteVirtualPreset,
    createPreset: (presetRequest: PostPresetRequest) =>
      axios
        .post(
          `/v6/data-grid/${gridCode}/preset`,
          {
            ...presetRequest,
            instance: presetInstanceId ?? null,
          },
          axiosBaseConfig
        )
        .then((response) => response.data as PostPresetResponse)
        .catch(handleApiError),
    updatePreset: (presetId: string, presetRequest: PutPresetRequest) =>
      axios
        .put(
          `/v6/data-grid/${gridCode}/preset/${presetId}`,
          {
            ...presetRequest,
            instance: presetInstanceId ?? null,
          },
          axiosBaseConfig
        )
        .then((response) => response.data as PutPresetResponse)
        .catch(handleApiError),
    updateRenamePreset: (presetId: string, presetRequest: PutPresetRenameRequest) =>
      axios
        .put(
          `/v6/data-grid/${gridCode}/preset/${presetId}/rename`,
          {
            ...presetRequest,
            instance: presetInstanceId ?? null,
          },
          axiosBaseConfig
        )
        .then((response) => response.data as PutPresetRenameResponse)
        .catch(handleApiError),
    deletePreset: (presetId: string) =>
      axios
        .delete(`/v6/data-grid/${gridCode}/preset/${presetId}`, axiosBaseConfig)
        .catch(handleApiError),
    presetOrder: (presetsOrder: string[]) =>
      axios
        .put(`/v6/data-grid/${gridCode}/preset/order`, {presetsOrder}, axiosConfig)
        .catch(handleApiError),
    activatePreset: (activePresetId: string | null) =>
      axios
        .put(`/v6/data-grid/${gridCode}/preset/active`, {activePreset: activePresetId}, axiosConfig)
        .catch(handleApiError),
    getPresets: () =>
      axios
        .get(`/v6/data-grid/${gridCode}/preset`, axiosConfig)
        .then((response) => response.data as GetPresetsResponse)
        .catch(handleApiError),

    updateVirtualPreset: (virtualPreset: VirtualPreset) =>
      axios
        .put(`/v6/data-grid/${gridCode}/virtual-preset`, virtualPreset, axiosConfig)
        .catch(handleApiError),
    getDefinition: () =>
      axios
        .get(`${BASE_V4_DATAGRID_URL}${gridCode}/definition`, axiosBaseConfig)
        .then((response) => transformations.transformDefinitionResponse(response.data))
        .catch(handleApiError),
    getSuggestionOptions: (params: GetSuggestionOptionsParams) =>
      axios
        .get(`${BASE_V4_DATAGRID_URL}suggestion/${params.suggestId}/options`, {
          ...axiosBaseConfig,
          params,
        })
        .then((response) => transformations.transformSuggestionOptionsResponse(response.data))
        .catch(handleApiError),
    getSuggestionLabels: (params: GetSuggestionLabelsParams) =>
      axios
        .get(
          `/v4/data-grid/suggestion/${params.suggestId}/labels?${params.keys
            .map((item, index) => `keys[${index}]=${item}`)
            .join('&')}`,
          axiosBaseConfig
        )
        .then((response) => transformations.transformSuggestionLabelsResponse(response.data))
        .catch(handleApiError),
    getFilterSchema: (params: GetFilterSchemaParams) =>
      axios
        .get(`/v2/model-schema/datagrid-${gridCode}`, {
          ...axiosBaseConfig,
          params: {
            recordId: params.externalFilterRecordId,
            resourceId: params.externalFilterResourceId,
          },
        })
        .then((response) => response.data)
        .catch(handleApiError),
    getSmartSearch: () =>
      axios
        .get(`${BASE_V4_DATAGRID_URL}${gridCode}/smart-search`, axiosBaseConfig)
        .then((response) => transformations.transformSmartSearchResponse(response.data))
        .catch(handleApiError),
    createDataQuery: (params: QueryIdRequestBody) =>
      axios
        .post(`/v6/data-grid/${gridCode}/data-query`, params, axiosBaseConfig)
        .then((response) => response.data)
        .catch(handleApiError),
    sharePreset: (params: SharePresetRequestBody) =>
      axios
        .post(`/v6/data-grid/${gridCode}/share-preset`, params, axiosConfig)
        .then<SharePresetResponseBody>((response) => response.data), // Error is handled in useSharePreset hook
    sharePresetToUser: (presetId: string, params: SharePresetToUseRequestBody) =>
      axios
        .post(`/v6/data-grid/${gridCode}/preset/${presetId}/share`, params, axiosConfig)
        .then<SharePresetToUseResponseBody>((response) => response.data), // Error is handled in useSharePresetToUser hook
    getDataByQuery: (dataQueryId: string, offset: number, limit: number, hasSmartSearch: boolean) =>
      axios
        .get(
          `/v6/data-grid/${gridCode}/data/${dataQueryId}?offset=${offset}&limit=${limit}${hasSmartSearch ? '&smartSearch=true' : ''}`,
          axiosBaseConfig
        )
        .then((response) => response.data?.rows as RowV5ResponseBody[])
        .catch(handleApiError),
    getSummaryRow: (dataQueryId: string) =>
      axios
        .get(`/v6/data-grid/${gridCode}/data/${dataQueryId}/summary`, axiosBaseConfig)
        .then((response) => response.data?.summaryRow?.cells as RowV5ResponseBody['cells'])
        .catch(handleApiError),
    getCountByQuery: (dataQueryId: string, hasSmartSearch: boolean) =>
      axios
        .get(
          `/v6/data-grid/${gridCode}/data/${dataQueryId}/count${hasSmartSearch ? '?smartSearch=true' : ''}`,
          axiosBaseConfig
        )
        .then<number>((response) => response.data?.totalRows)
        .catch(handleApiError),
    getDataIdsByQuery: (
      dataQueryId: string,
      offset: number,
      limit: number,
      hasSmartSearch: boolean
    ) =>
      axios
        .get(
          `/v6/data-grid/${gridCode}/data/${dataQueryId}/id?offset=${offset}&limit=${limit}${hasSmartSearch ? '&smartSearch=true' : ''}`,
          axiosBaseConfig
        )
        .then((response) => response.data as GetDataIdsByQuery[])
        .catch(handleApiError),
    getDataQuery: (dataQueryId: GetDataQueryRequest) =>
      axios
        .get(`/v6/data-grid/${gridCode}/data-query/${dataQueryId}`, axiosBaseConfig)
        .then((response) => response?.data as GetDataQueryResponse)
        .catch((err) => {
          const errorData = err?.response?.data;
          const isErrorDG0010 =
            isApiException(errorData) &&
            errorData?.errors?.some((error) => error.code === 'PKG-DG-0010');

          if (isErrorDG0010) {
            throw err;
          }

          handleApiError(err);
        }),
    getExport: (fileType: string, queryParams: string) =>
      axios
        .get(`/v6/data-grid/${gridCode}/export/${fileType}${queryParams}`, {
          responseType: 'arraybuffer',
        })
        .then((response) => response.data)
        .catch((err) =>
          match(err?.response?.status as number)
            .with(403, () => showNotification.error(i18n.t('general.notifications.noPermission')))
            .otherwise(() => showNotification.error())
        ),
  };
};
