import {createSelector, Selector} from '@reduxjs/toolkit';

import type {TeasState} from '../../types/TeasState';
import {NAME} from './constants';
import {CommentsStateType} from './reducer';

const selectComments: Selector<TeasState, CommentsStateType> = (state) => state[NAME];

export const selectCommentsList = createSelector(
  selectComments,
  (comments) => comments.commentsList
);

export const selectFetchComments = createSelector(selectComments, (comments) => comments.fetched);
