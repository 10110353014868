import {Pattern, match} from 'ts-pattern';

import {always} from 'ramda';

import {OrderResponseBody, PaymentState, useGetBusinessCaseQuery} from '@omnetic-dms/api';

import {useRequiredParams} from 'shared';

interface GetShowDepositPaymentFormArgs {
  order: OrderResponseBody | undefined;
  checkoutId: string;
}

export const useGetShowPaymentForm = ({order}: GetShowDepositPaymentFormArgs) => {
  const {id} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId: id});
  const isReadOnly = !businessCase?.actions.checkoutEditPaymentDetails;

  const isUnsuccessfulBrokeragePurchase = match([
    businessCase?.businessCaseInternalType,
    businessCase?.businessCaseState,
  ])
    .with(['PURCHASE_BROKERAGE', 'UNSUCCESSFUL'], always(true))
    .otherwise(always(false));

  const getShowPaymentForm = (paymentState: PaymentState) =>
    match([paymentState, order?.orderDiscriminator, isReadOnly, isUnsuccessfulBrokeragePurchase])
      .with(
        ['CONCEPT', Pattern.any, false, Pattern.any],
        ['CONCEPT', 'PURCHASE_BROKERAGE_FEES', Pattern.any, true],
        always(true)
      )
      .otherwise(always(false));

  return [getShowPaymentForm] as const;
};
