import {ApiMakeModel} from '../types/ApiMakeModel';
import {ApiMakeObject} from '../types/ApiMakeObject';
import {VehicleCatalogueMakeItem} from '../types/VehicleCatalogueMakeItem';
import {VehicleCatalogueMakeModelItem} from '../types/VehicleCatalogueMakeModelItem';

export const getMakeOptions = (item: ApiMakeObject): VehicleCatalogueMakeItem => ({
  label: item.labels?.[0]?.label || '' || item.default_label,
  shortLabel: item.labels?.[0]?.label_short || '',
  value: item.make,
});

export const getMakeModelOptions = (item: ApiMakeModel): VehicleCatalogueMakeModelItem => ({
  label: item.labels[0]?.label || item.default_label || '',
  value: item.make,
  models:
    item.models?.map((model) => ({
      value: model.model,
      label: model.labels?.[0]?.label || model.default_label,

      modelGroup: model.model_group
        ? {
            label: model.model_group?.labels?.[0]?.label || model.model_group?.default_label,
            value: model.model_group?.group || '',
          }
        : undefined,
    })) || [],
});
