import {createSelector} from '@reduxjs/toolkit';

import {selectVehicleSettings} from '../vehicleSettings/selectors';

export const selectVehicleSeriesDefinitionSlice = createSelector(
  selectVehicleSettings,
  (state) => state.vehicleSeriesDefinition
);

export const selectVehicleSeriesDefinitionList = createSelector(
  selectVehicleSeriesDefinitionSlice,
  (state) => state.list
);

export const selectVehicleSeriesDefinitionDetail = createSelector(
  selectVehicleSeriesDefinitionSlice,
  (state) => state.detail
);
