import {useMemo} from 'react';

import {filter, groupBy, head, indexBy, map, mapObjIndexed, pipe} from 'ramda';

import {GetAllApiEnumsApiResponse, useGetAllApiEnumsQuery, VehicleType} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

export const useGetVehicleEnums = (vehicleType: VehicleType | Nullish) => {
  const {data: catalogue} = useGetAllApiEnumsQuery({lang: [i18n.language]});

  const enums = useMemo(
    (): Record<
      EnumPropertyNames,
      {
        [x: string]: {
          value: string;
          label: string;
        };
      }
    > =>
      pipe(
        filter((item: GetAllApiEnumsApiResponse[number]) => item.vehicle_type === vehicleType),
        map((item) => ({
          property_name: item.property_name,
          options: item.keys.map((key) => ({
            value: key.const_key,
            label: head(key.labels)?.label ?? key.const_key,
          })),
        })),
        groupBy((item) => item.property_name),
        mapObjIndexed((value) => indexBy((item) => item.value, head(value ?? [])?.options ?? []))
      )(catalogue ?? []),
    [catalogue, vehicleType]
  );

  return enums;
};

type EnumPropertyNames =
  | 'fuel_type'
  | 'feature'
  | 'drive'
  | 'car_style'
  | 'emission_standard'
  | 'service_book_type'
  | 'battery_ownership_type'
  | 'battery_type'
  | 'body_color'
  | 'carvago_feature'
  | 'charging_connector_type'
  | 'color_type'
  | 'condition'
  | 'cost'
  | 'country'
  | 'currency'
  | 'door_count'
  | 'emission_class'
  | 'feature_category'
  | 'fuel_unit'
  | 'hand_drive_type'
  | 'hybrid_type'
  | 'interior_color'
  | 'interior_material'
  | 'seller_type'
  | 'service_book_state'
  | 'tire_aspect_ratio'
  | 'tire_make'
  | 'tire_model'
  | 'tire_rim_diameter'
  | 'tire_season'
  | 'tire_width'
  | 'transmission'
  | 'vehicle_type'
  | 'dimensions_height'
  | 'dimensions_length';
