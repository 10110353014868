import {FC, useRef, useEffect, PropsWithChildren} from 'react';
import {createPortal} from 'react-dom';

type PortalType = {
  selector?: string;
};

export const Portal: FC<PropsWithChildren<PortalType>> = ({children, selector}) => {
  const elementRef = useRef<Element>(document.createElement('div'));

  useEffect(() => {
    const _el = elementRef.current;

    _el.setAttribute('id', Date.now().toString());

    const wrapper = selector ? document.querySelector(selector) : document.body;

    wrapper?.appendChild(_el);

    return () => {
      wrapper?.removeChild(_el);
    };
  }, []);

  return createPortal(children, elementRef.current);
};
