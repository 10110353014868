import {
  GetDirectSaleMaterialItemRequest,
  GetDirectSaleMaterialItemRequestSchema,
  GetDirectSaleMaterialItemResponse,
  GetDirectSaleMaterialItemResponseSchema,
  PatchDirectSaleMaterialItemRequest,
  PatchDirectSaleMaterialItemRequestSchema,
  PatchDirectSaleMaterialItemResponse,
  PatchDirectSaleMaterialItemResponseSchema,
  PostDirectSaleMaterialItemCalculatePriceRequest,
  PostDirectSaleMaterialItemCalculatePriceRequestSchema,
  PostDirectSaleMaterialItemCalculatePriceResponse,
  PostDirectSaleMaterialItemCalculatePriceResponseSchema,
} from '../types/metadaWarehouseDirectSaleItemSparePart';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseDirectSaleItemSparePart = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDirectSaleMaterialItem: build.query<
      GetDirectSaleMaterialItemResponse,
      GetDirectSaleMaterialItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-spare-part/${queryArg.itemId}`,
        method: 'GET',
      }),
      providesTags: ['directSaleMaterialItems'],
      extraOptions: {
        requestSchema: GetDirectSaleMaterialItemRequestSchema,
        responseSchema: GetDirectSaleMaterialItemResponseSchema,
      },
    }),
    patchDirectSaleMaterialItem: build.mutation<
      PatchDirectSaleMaterialItemResponse,
      PatchDirectSaleMaterialItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-spare-part/${queryArg.itemId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSales', 'directSaleMaterialBasket'],
      extraOptions: {
        requestSchema: PatchDirectSaleMaterialItemRequestSchema,
        responseSchema: PatchDirectSaleMaterialItemResponseSchema,
      },
    }),
    postDirectSaleMaterialItemCalculatePrice: build.query<
      PostDirectSaleMaterialItemCalculatePriceResponse,
      PostDirectSaleMaterialItemCalculatePriceRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-spare-part/${queryArg.itemId}/calculate-price`,
        method: 'POST',
        body: queryArg.body,
      }),
      extraOptions: {
        requestSchema: PostDirectSaleMaterialItemCalculatePriceRequestSchema,
        responseSchema: PostDirectSaleMaterialItemCalculatePriceResponseSchema,
      },
    }),
  }),
});

export const {
  useGetDirectSaleMaterialItemQuery,
  useLazyGetDirectSaleMaterialItemQuery,
  usePatchDirectSaleMaterialItemMutation,
  usePostDirectSaleMaterialItemCalculatePriceQuery,
} = metadaWarehouseDirectSaleItemSparePart;
