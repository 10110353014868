import {Clickable, HStack, Text, VStack, getSize} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {useCallback, useState} from 'react';

import {append, isEmpty, without} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {FormErrorContext, SetFormErrorCallback} from '../../Form/FormErrorContext';
import {WizardStepConfig} from '../WizardStepConfig';
import {OffscreenContent} from './OffscreenContent';
import {WizardStepBadge} from './WizardStepBadge';

interface WizardProps extends WizardStepConfig, TestIdProps {
  isCurrent: boolean;
  position: number;
  onClick: (position: number) => void;
}

export function WizardStep(props: WizardProps) {
  const theme = useTheme();

  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const formErrorContextValue = useCallback<SetFormErrorCallback>(
    (name, error) => setInvalidFields(isNilOrEmpty(error) ? without([name]) : append(name)),
    [setInvalidFields]
  );
  return (
    <>
      <Clickable
        isDisabled={props.isDisabled || props.isCurrent}
        onClick={() => props.onClick(props.position)}
        data-testid={suffixTestId(`wizardStep-[${props.position}]`, props)}
      >
        <div
          role="tab"
          aria-current={props.isCurrent ? 'step' : undefined}
          aria-selected={props.isCurrent ? 'true' : undefined}
          css={css`
            overflow: hidden;
            height: ${getSize(18)};
            padding: ${getSize(4)} ${getSize(6)};
            border-left: ${getSize(1)} solid;
            border-color: ${props.isCurrent
              ? theme.colors.general.accent
              : theme.colors.general.transparent};
            background-color: ${props.isCurrent
              ? theme.colors.general.white
              : theme.colors.general.transparent};
            &:hover {
              background-color: ${props.isCurrent
                ? theme.colors.palettes.white
                : theme.colors.palettes.neutral[30][100]};
            }
          `}
        >
          <HStack spacing={2}>
            <WizardStepBadge
              position={props.position}
              isCurrent={props.isCurrent}
              severity={isEmpty(invalidFields) ? props.severity : 'danger'}
              data-testid={props['data-testid']}
            />
            <VStack spacing={1}>
              <Text size="small" color={props.isDisabled ? 'tertiary' : 'primary'} role="heading">
                {props.title}
              </Text>
              <Text size="xSmall" color="tertiary">
                {props.subtitle}
              </Text>
            </VStack>
          </HStack>
        </div>
      </Clickable>
      <FormErrorContext.Provider value={formErrorContextValue}>
        <OffscreenContent children={props.content} />
      </FormErrorContext.Provider>
    </>
  );
}
