import {IStatusPanelParams} from '@ag-grid-community/core';
import i18n from 'i18next';
import {Box, HStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useEffect, useState} from 'react';

import {always, gt, isNil, max, mean, min, pluck, range, reject, sum} from 'ramda';
import {isNumber} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {ConditionalDisplay} from './components/ConditionalDisplay';
import {StatusValue} from './components/StatusValue';
import {CellValues} from './types/CellValues';
import {isCurrencyCellValue} from './utils/isCurrencyCellValue';

export function AggregationStatusBar(props: IStatusPanelParams) {
  const [selectedCells, setSelectedCells] = useState<CellValues>([]);

  useEffect(() => {
    const handleRangeSelectionChanged = () => {
      const cellRanges = props.api.getCellRanges();

      const cellValues = cellRanges?.flatMap((cellRange) => {
        const startRow = min(cellRange.startRow?.rowIndex ?? 0, cellRange.endRow?.rowIndex ?? 0);
        const endRow = max(cellRange.startRow?.rowIndex ?? 0, cellRange.endRow?.rowIndex ?? 0);

        return range(startRow, endRow + 1).flatMap((rowIndex) =>
          cellRange.columns?.flatMap((column) => {
            const rowModel = props.api.getModel();
            const rowNode = rowModel.getRow(rowIndex);

            if (isNil(rowNode)) {
              return null;
            }

            const currency: string | Nullish = rowNode.data[column.getColId()]?.value?.currency;
            const value = props.api.getValue(column, rowNode);

            return match(value)
              .when(isNumber, always({amount: value, currency}))
              .when(isCurrencyCellValue, always(value))
              .otherwise(always(null));
          })
        );
      });

      setSelectedCells(reject(isNil, cellValues ?? []));
    };

    props.api.addEventListener('rangeSelectionChanged', handleRangeSelectionChanged);

    return () => {
      props.api.removeEventListener('rangeSelectionChanged', handleRangeSelectionChanged);
    };
  }, [props.api]);

  const averageValue = mean(pluckValue(selectedCells));
  const minValue = Math.min(...pluckValue(selectedCells));
  const maxValue = Math.max(...pluckValue(selectedCells));
  const sumValue = sum(pluckValue(selectedCells));

  return (
    <ConditionalDisplay $isDisplayed={gt(selectedCells.length, 1)}>
      <Box paddingVertical={3}>
        <HStack spacing={2}>
          <StatusValue
            value={averageValue}
            label={i18n.t('page.datagrid.labels.average')}
            selectedCells={selectedCells}
          />
          <StatusValue
            value={minValue}
            label={i18n.t('page.datagrid.labels.min')}
            selectedCells={selectedCells}
          />
          <StatusValue
            value={maxValue}
            label={i18n.t('page.datagrid.labels.max')}
            selectedCells={selectedCells}
          />
          <StatusValue
            value={sumValue}
            label={i18n.t('page.datagrid.labels.sum')}
            selectedCells={selectedCells}
          />
        </HStack>
      </Box>
    </ConditionalDisplay>
  );
}

const pluckValue = pluck('amount');

// Show component doesn't work here so the visibility must be handled with display prop
