import {TableRow} from 'platform/components';
import {Box, Center, HStack, Icon, Right, Show, Spinner, Text} from 'platform/foundation';
import {NumberFormat, useFormatNumber} from 'platform/locale';

import {RefObject, useEffect, useRef} from 'react';

import {head, isEmpty, isNotNil, once, tail, times} from 'ramda';
import {round} from 'ramda-adjunct';

import {
  DrillDownLevel,
  DrillDownLevelName,
  DrillDownResponse,
  DrillDownStatistics,
  useSourcingDrillDownQuery,
} from '@omnetic-dms/api';

import {suffixTestId, TestIdProps, useToggle} from 'shared';

import {LevelConfiguration} from '../types';
import {getFirstColumnText} from '../utils/getFirstColumnText';
import {getGapColor} from '../utils/getGapColor';
import {getKeyFromLevels} from '../utils/getKeyFromLevels';
import {isRowSelected} from '../utils/isRowSelected';

export interface DrillDownRowProps extends TestIdProps {
  depth: number;
  levelConfigurations: LevelConfiguration[];
  data: DrillDownStatistics;
  translations: DrillDownResponse['translations'];
  currency: string;
  regions: string[];
  selectedRow?: Record<DrillDownLevelName, string>;
  focusOnSelectedRow?: boolean;
}

const SCROLL_OFFSET = '20px';

const scrollOnSelectedRowOnce = once((ref: RefObject<HTMLDivElement>) => {
  if (isNotNil(ref.current)) {
    ref.current.style.scrollMargin = SCROLL_OFFSET;
    ref.current.scrollIntoView({behavior: 'smooth'});
  }
});

export function DrillDownRow(props: DrillDownRowProps) {
  const formatNumber = useFormatNumber();

  const [isExpanded, toggleIsExpanded] = useToggle(isRowSelected(props.data, props.selectedRow));

  const queryLevels: DrillDownLevel[] = props.data.levels.map((level, index) => ({
    level: level.name,
    values: [level.value],
    priority: index + 1,
  }));

  const currentLevelConfiguration = head(props.levelConfigurations);
  const nextLevelConfigurations = tail(props.levelConfigurations);
  const isLastLevel = isEmpty(nextLevelConfigurations);

  const {data, isLoading} = useSourcingDrillDownQuery(
    {
      levels: [
        ...queryLevels,
        {
          level: head(nextLevelConfigurations)?.name ?? 'make',
          values: [],
          priority: queryLevels.length + 1,
        },
      ],
      currency: props.currency,
      regions: props.regions,
    },
    {skip: isEmpty(props.levelConfigurations) || !isExpanded}
  );

  const sold = props.data.statistics.find((item) => item.type === 'sold');
  const stock = props.data.statistics.find((item) => item.type === 'stock');

  const gap = (sold?.amount ?? 0) - (stock?.amount ?? 0);

  const soldMileage = round((sold?.mileage ?? 0) / 1000);
  const stockMileage = round((stock?.mileage ?? 0) / 1000);
  const soldPrice = round((sold?.price ?? 0) / 1000);
  const stockPrice = round((stock?.price ?? 0) / 1000);

  const isLastExpandedLevel = data?.statisticsAggregated.every(
    (item) => !isRowSelected(item, props.selectedRow)
  );
  const isSelected = isLastExpandedLevel && isRowSelected(props.data, props.selectedRow);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.focusOnSelectedRow && isSelected) {
      scrollOnSelectedRowOnce(ref);
    }
  }, [props.focusOnSelectedRow, isSelected]);

  return (
    <>
      <TableRow
        color={isSelected ? 'palettes.teal.20.100' : undefined}
        hoverColor={isSelected ? 'palettes.teal.30.100' : undefined}
        onClick={!isLastLevel ? toggleIsExpanded : undefined}
        data-testid={suffixTestId('row', props)}
      >
        <HStack key="label" align="center" spacing={2} height={6}>
          <Box ref={ref} />
          <Show when={props.depth > 0}>
            {times(
              (index) => (
                <Box key={index} paddingLeft={2}>
                  <Box height={6} borderRight="1px solid" borderColor="palettes.neutral.40.100" />
                </Box>
              ),
              props.depth
            )}
          </Show>
          <Show when={!isLastLevel}>
            <Center height={6} width={4}>
              {isLoading ? (
                <Spinner size="small" />
              ) : (
                <Icon
                  value={isExpanded ? 'navigation/arrow_drop_down' : 'navigation/arrow_right'}
                  size={6}
                  color={isExpanded ? 'palettes.blue.60.100' : 'palettes.neutral.50.100'}
                />
              )}
            </Center>
          </Show>
          <Text size="xSmall" alternative noWrap>
            {getFirstColumnText(props.data, currentLevelConfiguration, props.translations)}
          </Text>
        </HStack>
        <Box paddingHorizontal={1}>
          <Right>
            <Text size="xSmall">
              <NumberFormat number={sold?.amount} />
            </Text>
          </Right>
        </Box>
        <Box paddingHorizontal={1}>
          <Right>
            <Text size="xSmall">
              <NumberFormat number={stock?.amount} />
            </Text>
          </Right>
        </Box>
        <Box paddingHorizontal={1}>
          <Right>
            <Text size="xSmall">{soldPrice === 0 ? '0' : `${formatNumber(soldPrice, 0)}k`}</Text>
          </Right>
        </Box>
        <Box paddingHorizontal={1}>
          <Right>
            <Text size="xSmall">{stockPrice === 0 ? '0' : `${formatNumber(stockPrice, 0)}k`}</Text>
          </Right>
        </Box>
        <Box paddingHorizontal={1}>
          <Right>
            <Text size="xSmall">
              {soldMileage === 0 ? '0' : `${formatNumber(soldMileage, 0)}k`}
            </Text>
          </Right>
        </Box>
        <Box paddingHorizontal={1}>
          <Right>
            <Text size="xSmall">
              {stockMileage === 0 ? '0' : `${formatNumber(stockMileage, 0)}k`}
            </Text>
          </Right>
        </Box>
        <Box paddingHorizontal={1}>
          <Right>
            <Text size="xSmall" color={getGapColor(gap)}>
              <NumberFormat number={gap} decimals={0} />
            </Text>
          </Right>
        </Box>
      </TableRow>
      <Show when={isExpanded}>
        {data?.statisticsAggregated.map((item) => (
          <DrillDownRow
            key={getKeyFromLevels(item.levels)}
            depth={props.depth + 1}
            levelConfigurations={nextLevelConfigurations}
            data={item}
            translations={data?.translations}
            currency={props.currency}
            regions={props.regions}
            selectedRow={props.selectedRow}
            focusOnSelectedRow={props.focusOnSelectedRow}
          />
        ))}
      </Show>
    </>
  );
}
