import {Heading, Text, ThemeColorTextPath, VStack} from 'platform/foundation';

import {EMPTY_PLACEHOLDER, Nullish, suffixTestId, TestIdProps} from 'shared';

interface SummaryItemProps extends TestIdProps {
  title: string;
  value: string | Nullish;
  color?: ThemeColorTextPath;
  isAlternative?: true;
}

export function SummaryItem(props: SummaryItemProps) {
  return (
    <VStack spacing={1}>
      <Text size="xxSmall" color="secondary" data-testid={suffixTestId('title', props)}>
        {props.title}
      </Text>
      <Heading
        size={4}
        color={props.color}
        alternative={props.isAlternative}
        data-testid={suffixTestId('value', props)}
      >
        {props.value ?? EMPTY_PLACEHOLDER}
      </Heading>
    </VStack>
  );
}
