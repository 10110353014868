import {
  Attributes,
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, Heading, Show, Space, Text, VStack} from 'platform/foundation';
import {array, object, string} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {isNil} from 'ramda';

import {AddrDetailApiResponse, useGetTenantV2Query, usePutTenantV2Mutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  AddressSearch,
  handleApiError,
  useCountry,
  useCurrencies,
  usePhoneNumbers,
} from '@omnetic-dms/shared';

import {Nullish, yupString} from 'shared';

import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {BillingInformationSelect} from './components/BillingInformationSelect';
import {ContactPersonList} from './components/ContactPersonList';
import {FormValues} from './types';

export function TenantProfile() {
  const {countriesOptions} = usePhoneNumbers();
  const {getCountryName} = useCountry();
  const {getCurrencyName, getCurrencySymbol} = useCurrencies();

  const {data: tenant, isLoading, isError} = useGetTenantV2Query();
  const [updateTenant] = usePutTenantV2Mutation();

  const handleSubmit: FormSubmitHandler<FormValues> = async (values: FormValues) => {
    const {type, ...submitData} = values;

    await updateTenant({type: type[0], ...submitData})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const setAddressFormSearch =
    (formApi: UseFormReturn<FormValues>) => (address: AddrDetailApiResponse) => {
      if (isNil(tenant)) {
        showNotification.error();
        return;
      }

      formApi.setValue(
        'contactInformation.address',
        {
          city: address?.city ?? '',
          street: address?.street ?? '',
          zip: address?.zipCode ?? '',
          state: address?.state ?? '',
          prefix: address?.prefix ?? '',
          descriptiveNumber: address?.descriptiveNumber ?? null,
          orientationNumber: address?.orientationNumber ?? null,
          addressComplement: null,
          district: null,
          country: tenant.contactInformation.address.country,
          coordinates: null,
          postal: null,
        },
        {shouldValidate: true}
      );
    };

  const defaultValues = {
    ...tenant,
    type: [tenant?.type ?? 'selfEmployed'],
  };

  const handleOnTypeChange = (formApi: UseFormReturn<FormValues>) => (val: string[] | Nullish) => {
    if (isNil(val)) {
      return null;
    }

    if (val.includes('selfEmployed')) {
      formApi.setValue('contactInformation.selfEmployedInformation', null);
    }
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<FormValues> onSubmit={handleSubmit} defaultValues={defaultValues} schema={schema}>
        {(control, formApi) => {
          const isTenantSelfEmployed = formApi.watch('type')?.includes('selfEmployed') ?? true;
          const handleDiscardChanges = () => formApi.reset(defaultValues);

          return (
            <VStack>
              <SettingsSection>
                <Grid columns={12}>
                  <GridItem span={12}>
                    <FormField
                      name="type"
                      control={control}
                      type="chips"
                      options={chipsOptions}
                      onChange={handleOnTypeChange(formApi)}
                    />
                  </GridItem>

                  <Show when={isTenantSelfEmployed}>
                    <GridItem span={2}>
                      <FormField
                        name="contactInformation.selfEmployedInformation.titleBefore"
                        label={i18n.t('entity.person.labels.titleBefore')}
                        control={control}
                        type="text"
                      />
                    </GridItem>
                    <GridItem span={4}>
                      <FormField
                        name="contactInformation.selfEmployedInformation.firstName"
                        label={i18n.t('entity.person.labels.firstName')}
                        isRequired={isTenantSelfEmployed}
                        control={control}
                        type="text"
                      />
                    </GridItem>

                    <GridItem span={4}>
                      <FormField
                        name="contactInformation.selfEmployedInformation.lastName"
                        label={i18n.t('entity.person.labels.lastName')}
                        control={control}
                        isRequired={isTenantSelfEmployed}
                        type="text"
                      />
                    </GridItem>
                    <GridItem span={2}>
                      <FormField
                        name="contactInformation.selfEmployedInformation.titleAfter"
                        label={i18n.t('entity.person.labels.titleAfter')}
                        control={control}
                        type="text"
                      />
                    </GridItem>

                    <GridItem span={6}>
                      <FormField
                        name="contactInformation.selfEmployedInformation.phoneNumber"
                        label={i18n.t('entity.person.labels.phoneNumber')}
                        control={control}
                        type="phone"
                        countries={countriesOptions}
                      />
                    </GridItem>
                    <GridItem span={6}>
                      <FormField
                        name="contactInformation.selfEmployedInformation.emailAddress"
                        label={i18n.t('entity.email.labels.email')}
                        control={control}
                        type="email"
                      />
                    </GridItem>
                  </Show>
                  <GridItem span={6}>
                    <FormField
                      name="contactInformation.registrationNumber"
                      label={i18n.t('entity.invoice.labels.registrationNumber')}
                      control={control}
                      type="text"
                    />
                  </GridItem>
                  <GridItem span={6}>
                    <FormField
                      name="contactInformation.vatNumber"
                      label={i18n.t('entity.invoice.labels.vatNumber')}
                      control={control}
                      type="text"
                    />
                  </GridItem>

                  <GridItem span={6}>
                    <FormField
                      name="contactInformation.companyName"
                      label={i18n.t('entity.customer.labels.companyName')}
                      control={control}
                      type="text"
                    />
                  </GridItem>
                  <GridItem span={6}>
                    <FormField
                      name="contactInformation.fileNumber"
                      label={i18n.t('entity.customer.labels.fileNumber')}
                      control={control}
                      type="text"
                    />
                  </GridItem>
                </Grid>
              </SettingsSection>
              <Separator spacing={5} />

              <SettingsSection>
                <Heading size={4}>{i18n.t('entity.address.labels.address')}</Heading>

                <Space vertical={5} />

                <Grid columns={12}>
                  <GridItem span={12}>
                    <AddressSearch onSelect={setAddressFormSearch(formApi)} />
                  </GridItem>

                  <GridItem span={6}>
                    <FormField
                      name="contactInformation.address.street"
                      label={i18n.t('entity.address.labels.street')}
                      control={control}
                      type="text"
                      isRequired
                    />
                  </GridItem>
                  <GridItem span={3}>
                    <FormField
                      name="contactInformation.address.descriptiveNumber"
                      label={i18n.t('entity.address.labels.descriptiveNumber')}
                      control={control}
                      type="text"
                    />
                  </GridItem>
                  <GridItem span={3}>
                    <FormField
                      name="contactInformation.address.orientationNumber"
                      label={i18n.t('entity.address.labels.orientationNumber')}
                      control={control}
                      type="text"
                    />
                  </GridItem>
                  <GridItem span={6}>
                    <FormField
                      name="contactInformation.address.city"
                      label={i18n.t('entity.address.labels.city')}
                      control={control}
                      type="text"
                      isRequired
                    />
                  </GridItem>

                  <GridItem span={3}>
                    <FormField
                      name="contactInformation.address.district"
                      label={i18n.t('entity.address.labels.district')}
                      control={control}
                      type="text"
                    />
                  </GridItem>
                  <GridItem span={3}>
                    <FormField
                      name="contactInformation.address.zip"
                      label={i18n.t('entity.address.labels.zipCode')}
                      control={control}
                      type="text"
                      isRequired
                    />
                  </GridItem>
                </Grid>
              </SettingsSection>

              <Separator spacing={5} />
              <SettingsSection>
                <VStack spacing={5}>
                  <Heading size={4}>{i18n.t('entity.customer.labels.publicContacts')}</Heading>
                  <Text size="small" color="secondary">
                    {i18n.t('entity.customer.labels.publicContactsHelperText')}
                  </Text>
                  <Grid columns={2}>
                    <FormField
                      name="publicContacts.phoneNumber"
                      label={i18n.t('entity.person.labels.phoneNumber')}
                      control={control}
                      countries={countriesOptions}
                      type="phone"
                    />
                    <FormField
                      name="publicContacts.emailAddress"
                      label={i18n.t('general.labels.emailAddress')}
                      control={control}
                      type="email"
                    />
                    <GridItem span={2}>
                      <FormField
                        name="publicContacts.website"
                        label={i18n.t('general.labels.website')}
                        control={control}
                        type="text"
                      />
                    </GridItem>
                  </Grid>
                </VStack>
              </SettingsSection>

              <Separator spacing={5} />

              <ContactPersonList control={control} formApi={formApi} />

              <Separator spacing={5} />

              <BillingInformationSelect control={control} formApi={formApi} />

              <Separator spacing={5} />
              <SettingsSection>
                <VStack spacing={5}>
                  <Heading size={4}>{i18n.t('page.settings.labels.systemInformation')}</Heading>
                  <Attributes
                    size="quarter"
                    rows={[
                      {
                        label: i18n.t('page.settings.labels.tenantLocation'),
                        value: getCountryName(tenant?.country),
                      },
                      {
                        label: i18n.t('page.settings.labels.primaryCurrency'),
                        value: getCurrencyName(tenant?.currency),
                      },
                      {
                        label: i18n.t('general.labels.currencySymbol'),
                        value: getCurrencySymbol(tenant?.currency),
                      },
                      {label: i18n.t('page.settings.labels.tenantId'), value: tenant?.id},
                    ]}
                  />
                </VStack>
              </SettingsSection>

              <Space vertical={5} />

              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.discardChanges')}
                  onClick={handleDiscardChanges}
                  variant="secondary"
                />
                <FormButton
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.save')}
                />
              </ButtonGroup>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const schema = object({
  type: array()
    .of(string().oneOf(['selfEmployed', 'company']))
    .required(),
  contactInformation: object({
    selfEmployedInformation: object({
      titleBefore: yupString,
      firstName: yupString.required(),
      lastName: yupString.required(),
      titleAfter: yupString,
      emailAddress: yupString,
      phoneNumber: object().nullable(),
    })
      .default(null)
      .nullable(),
    registrationNumber: yupString.required(),
    vatNumber: yupString,
    companyName: yupString.required(),
    fileNumber: yupString,
    address: object({
      street: yupString.required(),
      descriptiveNumber: yupString,
      orientationNumber: yupString,
      city: yupString.required(),
      district: yupString,
      zip: yupString.required(),
      coordinates: object({
        latitude: yupString,
        longitude: yupString,
      })
        .optional()
        .nullable(),
      postal: yupString,
    }),
  }),
});

const chipsOptions = [
  {
    value: 'selfEmployed',
    label: i18n.t('entity.customer.labels.isSelfEmployed'),
  },
  {
    value: 'company',
    label: i18n.t('general.labels.company'),
  },
];
