import {
  DeleteDirectSaleLabourBasketItemRequest,
  DeleteDirectSaleLabourBasketItemRequestSchema,
  DeleteDirectSaleLabourBasketItemResponse,
  DeleteDirectSaleLabourBasketItemResponseSchema,
  DeleteDirectSaleLabourBasketItemsRequest,
  DeleteDirectSaleLabourBasketItemsRequestSchema,
  DeleteDirectSaleLabourBasketItemsResponse,
  DeleteDirectSaleLabourBasketItemsResponseSchema,
  GetDirectSaleLabourBasketItemsRequest,
  GetDirectSaleLabourBasketItemsRequestSchema,
  GetDirectSaleLabourBasketItemsResponse,
  GetDirectSaleLabourBasketItemsResponseSchema,
  PatchDirectSaleLabourBasketItemQuantityRequest,
  PatchDirectSaleLabourBasketItemQuantityRequestSchema,
  PatchDirectSaleLabourBasketItemQuantityResponse,
  PatchDirectSaleLabourBasketItemQuantityResponseSchema,
  PostDirectSaleLabourItemsMechanicRequest,
  PostDirectSaleLabourItemsMechanicRequestSchema,
  PostDirectSaleLabourItemsMechanicResponse,
  PostDirectSaleLabourItemsMechanicResponseSchema,
  PostDirectSaleLabourItemToBasketRequest,
  PostDirectSaleLabourItemToBasketRequestSchema,
  PostDirectSaleLabourItemToBasketResponse,
  PostDirectSaleLabourItemToBasketResponseSchema,
} from '../types/metadaWarehouseDirectSaleLabourBasket';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseDirectSaleLabourBasketApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDirectSaleLabourBasketItems: build.query<
      GetDirectSaleLabourBasketItemsResponse,
      GetDirectSaleLabourBasketItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart`,
        method: 'GET',
      }),
      providesTags: ['directSaleLabourBasket'],
      extraOptions: {
        requestSchema: GetDirectSaleLabourBasketItemsRequestSchema,
        responseSchema: GetDirectSaleLabourBasketItemsResponseSchema,
      },
    }),
    deleteDirectSaleLabourBasketItems: build.mutation<
      DeleteDirectSaleLabourBasketItemsResponse,
      DeleteDirectSaleLabourBasketItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'directSaleLabourBasket',
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: DeleteDirectSaleLabourBasketItemsRequestSchema,
        responseSchema: DeleteDirectSaleLabourBasketItemsResponseSchema,
      },
    }),
    postDirectSaleLabourBasketCheckout: build.mutation<
      PostDirectSaleLabourItemsMechanicResponse,
      PostDirectSaleLabourItemsMechanicRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart/checkout`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'directSaleLabourBasket',
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: PostDirectSaleLabourItemsMechanicRequestSchema,
        responseSchema: PostDirectSaleLabourItemsMechanicResponseSchema,
      },
    }),
    postDirectSaleLabourItemToBasket: build.mutation<
      PostDirectSaleLabourItemToBasketResponse,
      PostDirectSaleLabourItemToBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart/item/labour-catalog`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleLabourBasket'],
      extraOptions: {
        requestSchema: PostDirectSaleLabourItemToBasketRequestSchema,
        responseSchema: PostDirectSaleLabourItemToBasketResponseSchema,
      },
    }),
    patchDirectSaleLabourBasketItemQuantity: build.mutation<
      PatchDirectSaleLabourBasketItemQuantityResponse,
      PatchDirectSaleLabourBasketItemQuantityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart/item/${queryArg.itemId}/quantity`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleLabourBasket', 'directSaleLabourItems'],
      extraOptions: {
        requestSchema: PatchDirectSaleLabourBasketItemQuantityRequestSchema,
        responseSchema: PatchDirectSaleLabourBasketItemQuantityResponseSchema,
      },
    }),
    deleteDirectSaleLabourBasketItem: build.mutation<
      DeleteDirectSaleLabourBasketItemResponse,
      DeleteDirectSaleLabourBasketItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/labour-cart/item/${queryArg.itemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['directSaleLabourBasket'],
      extraOptions: {
        requestSchema: DeleteDirectSaleLabourBasketItemRequestSchema,
        responseSchema: DeleteDirectSaleLabourBasketItemResponseSchema,
      },
    }),
  }),
});

export const {
  useGetDirectSaleLabourBasketItemsQuery,
  useDeleteDirectSaleLabourBasketItemsMutation,
  usePostDirectSaleLabourBasketCheckoutMutation,
  usePostDirectSaleLabourItemToBasketMutation,
  usePatchDirectSaleLabourBasketItemQuantityMutation,
  useDeleteDirectSaleLabourBasketItemMutation,
} = metadaWarehouseDirectSaleLabourBasketApi;
