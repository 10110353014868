import {format} from 'date-fns';
import {Avatar, MentionsText} from 'platform/components';
import {Box, HStack, Show, Space, Text} from 'platform/foundation';

import {useEffect, useRef} from 'react';

import {reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {CommentResponseBody, useGetCurrentUserInfoQuery, useGetUserQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {parseDate, suffixTestId, TestIdProps, useQueryState} from 'shared';

import {queryParams} from '../../../utils/queryParams';
import {CommentCardItemActions} from './CommentCardItemActions';

export interface CommentCardItemProps extends TestIdProps {
  comment: CommentResponseBody;
  isDeleteCommentLoading?: boolean;
  isEditCommentLoading?: boolean;
  onDelete?: (id: string) => void;
  isDeleteDisabled?: boolean;
}

export function CommentCardItem(props: CommentCardItemProps) {
  const [commentIdParam] = useQueryState(queryParams.COMMENT_ID);
  const ref = useRef<HTMLDivElement>(null);

  const {data: user} = useGetUserQuery({id: props.comment.createdBy});
  const {data: currentUser} = useGetCurrentUserInfoQuery();

  const canDelete = currentUser?.id === props.comment.createdBy;

  const isHighlighted = props.comment.id === commentIdParam;

  useEffect(() => {
    if (isHighlighted) {
      ref.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [isHighlighted]);

  const authorName = user && reject(isNilOrEmpty, [user.firstName, user.lastName]).join(' ');
  const createdAt = `${i18n.t('page.comments.labels.created', {
    created: format(parseDate(props.comment.createdAt), 'dd/MM/yyyy p'),
  })}`;

  return (
    <Box
      borderRadius="small"
      backgroundColor={isHighlighted ? 'palettes.neutral.20.100' : undefined}
      padding={3}
      ref={ref}
    >
      <HStack spacing={3} align="flex-start">
        <Avatar name={authorName} data-testid={suffixTestId('authorName', props)} />
        <Box flex={1}>
          <Text size="small" alternative data-testid={suffixTestId('authorName', props)}>
            {authorName}
          </Text>
          <Text size="xSmall" color="tertiary" data-testid={suffixTestId('createdAt', props)}>
            {createdAt}
          </Text>
          <Space vertical={2} />
          <MentionsText value={props.comment.text} data-testid={suffixTestId('text', props)} />
        </Box>
        <Show when={props.onDelete && canDelete}>
          <CommentCardItemActions
            id={props.comment.id}
            isDeleteCommentLoading={props.isDeleteCommentLoading}
            onDelete={props.onDelete}
            isDeleteDisabled={props.isDeleteDisabled}
            data-testid={suffixTestId('actions', props)}
          />
        </Show>
      </HStack>
    </Box>
  );
}
