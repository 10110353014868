import {
  Integer,
  ThemeIconKey,
  Center,
  Hide,
  Icon,
  Show,
  ThemeColorPath,
  getColorByPath,
} from 'platform/foundation';
import styled, {css} from 'styled-components';
import {match} from 'ts-pattern';

import {always, isNotEmpty} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {getInitials} from './getInitials';

type AvatarSize = 'small' | 'default' | 'large';

export interface AvatarProps extends TestIdProps {
  size?: AvatarSize;
  variant?: 'square' | 'circle';
  colorVariant?: 'default' | 'external';
  icon?: ThemeIconKey;
  name?: string;
  isActive?: boolean;
  isClickable?: boolean;
}

export function Avatar(props: AvatarProps) {
  const showName = isNotNil(props.name) && isNotEmpty(props.name);
  const size = match<AvatarSize, Integer>(props.size ?? 'default')
    .with('small', always(6))
    .with('default', always(10))
    .with('large', always(18))
    .exhaustive();

  const iconSize = match<AvatarSize, Integer>(props.size ?? 'default')
    .with('small', always(3))
    .with('default', always(6))
    .with('large', always(10))
    .exhaustive();

  const textSize = match<AvatarSize, string>(props.size ?? 'default')
    .with('small', always('0.625rem'))
    .with('default', always('1rem'))
    .with('large', always('1.75rem'))
    .exhaustive();

  const backgroundColor = match<Pick<AvatarProps, 'colorVariant' | 'isActive'>, ThemeColorPath>(
    props
  )
    .with({colorVariant: 'external'}, always('palettes.yellow.30.100'))
    .with({colorVariant: 'default', isActive: true}, always('palettes.neutral.40.100'))
    .otherwise(always('palettes.neutral.30.100'));

  return (
    <StyledWrapper
      size={size}
      backgroundColor={backgroundColor}
      isClickable={props.isClickable}
      borderRadius={props.variant === 'square' ? 'medium' : 'circular'}
      data-testid={suffixTestId('avatar', props)}
    >
      <Center width={size} height={size}>
        <Show when={showName}>
          <div
            css={css`
              color: ${({theme}) => theme.colors.text.primary};
              font-family: ${({theme}) => theme.fonts.body};
              font-size: ${textSize};
              font-weight: ${({theme}) => theme.fontWeights.medium};
              white-space: nowrap;
              line-height: 1;
            `}
          >
            {getInitials(props.name ?? '')}
          </div>
        </Show>
        <Hide when={showName}>
          <Icon value={props.icon ?? 'social/person'} size={iconSize} />
        </Hide>
      </Center>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{
  size: Integer;
  backgroundColor: ThemeColorPath;
  borderRadius: 'medium' | 'circular';
  isClickable?: boolean;
}>`
  width: ${({theme, size}) => theme.getSize(size)};
  height: ${({theme, size}) => theme.getSize(size)};
  border-radius: ${({theme, borderRadius}) => theme.radii[borderRadius]};
  background-color: ${({theme, backgroundColor}) => getColorByPath(backgroundColor, theme)};
  cursor: pointer;
  ${({isClickable}) =>
    isClickable &&
    css`
      &:hover {
        background-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
      }
      &:active {
        background-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
      }
      cursor: pointer;
    `}
`;
