import {object, SchemaOf, string, number, mixed} from 'yup';

import i18n from '@omnetic-dms/i18n';

import {SmtpCredentials} from '../types/SmtpCredentials';

export const $EmailSmtpCredentials: SchemaOf<SmtpCredentials> = object({
  host: string().required(i18n.t('general.notifications.errorFieldRequired')),
  user: string().required(i18n.t('general.notifications.errorFieldRequired')),
  password: string().required(i18n.t('general.notifications.errorFieldRequired')),
  port: number().required(i18n.t('general.notifications.errorFieldRequired')),
  encryption: mixed().oneOf(['tls', 'ssl', null]),
  defaultEmail: string().required(i18n.t('general.notifications.errorFieldRequired')),
});
