import {isNilOrEmpty} from 'ramda-adjunct';

import {ExternalFilter} from '../types/ExternalFilter';
import {composeRangeArray} from './composeRangeArray';

export const transformExternalFilterToMinipurchaseSpecification = (filter: ExternalFilter) => ({
  type: filter.type?.[0] ?? null,
  make: filter.make ?? null,
  modelFamily: filter.modelFamily ?? null,
  fuelType: filter.fuelType ?? null,
  transmission: filter.transmission ?? null,
  bodyStyle: filter.bodyStyle ?? null,
  drive: filter.drive ?? null,
  features: filter.features ?? [],
  vatDeductible: isNilOrEmpty(filter.vatDeductible?.[0])
    ? null
    : filter.vatDeductible![0] === 'true',
  bodyColor: filter.bodyColor ?? null,
  cubicCapacity: composeRangeArray(filter.cubicCapacityFrom, filter.cubicCapacityTo),
  price: composeRangeArray(filter.priceFrom, filter.priceTo),
  year: composeRangeArray(filter.yearFrom, filter.yearTo),
  mileage: composeRangeArray(filter.mileageFrom, filter.mileageTo),
  power: composeRangeArray(filter.powerFrom, filter.powerTo),
});
