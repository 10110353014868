import {Card, Label, Preview, PreviewAction} from 'platform/components';
import {Box, Grid, GridItem, Heading, makeStorageUrl, Show, VStack} from 'platform/foundation';
import {Lightbox, useLightbox, LightboxImage} from 'platform/lightbox';

import {isNotNil} from 'ramda';
import {concatRight, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {PhotosCategoryItemFile, PhotosChildCategory} from '../types/types';

interface AuditPhotosCategoryProps extends TestIdProps {
  category: PhotosChildCategory;
}

export function AuditPhotosCategory({category, ...rest}: AuditPhotosCategoryProps) {
  const photos = category.items.reduce((results: PhotosCategoryItemFile[], categoryPhotoItem) => {
    const files = categoryPhotoItem.files.map((file) => file);
    return concatRight(results, files);
  }, []);

  const [lightboxControls, {onOpen}] = useLightbox(`${category.id}-apc`);

  const lightboxImages = photos.reduce((lightboxImages: LightboxImage[], image) => {
    const imageUrl = image.url || image.resizeUrl;
    if (isNotNil(imageUrl)) {
      return [
        ...lightboxImages,
        {
          id: image.id,
          url: imageUrl.replace('resize', 'get'),
          resizeUrl: image.resizeUrl,
          title: image.name,
          fileName: image.name,
        },
      ];
    }
    return lightboxImages;
  }, []);

  const handleOpenPreview = (imageOriginalUrl: string | null) => {
    const detailIndex = lightboxImages.findIndex((image) => image.url === imageOriginalUrl);
    onOpen(detailIndex ?? 0);
    return;
  };

  return (
    <>
      <Card variant="inlineGrey" title={category.name}>
        <Grid columns={6}>
          {category.items.map((categoryPhotoItem) => (
            <GridItem
              key={`${categoryPhotoItem.label}_${category.id}`}
              span={
                isNotNilOrEmpty(categoryPhotoItem?.label) && isNilOrEmpty(categoryPhotoItem.title)
                  ? 1
                  : 6
              }
            >
              <VStack spacing={2}>
                <Box>
                  <Show when={isNotNilOrEmpty(categoryPhotoItem?.label)}>
                    <Label>{categoryPhotoItem.label}</Label>
                  </Show>
                  <Show when={isNotNilOrEmpty(categoryPhotoItem?.title)}>
                    <Heading
                      size={4}
                      data-testid={suffixTestId(`category-title-${categoryPhotoItem.title}`, rest)}
                    >
                      {categoryPhotoItem.title}
                    </Heading>
                  </Show>
                </Box>

                <Grid
                  columns={
                    isNotNilOrEmpty(categoryPhotoItem?.label) &&
                    isNilOrEmpty(categoryPhotoItem.title)
                      ? 1
                      : 6
                  }
                >
                  {categoryPhotoItem?.files?.map((file, index) => (
                    <Box
                      key={`${file.damageId ?? file.id}-${index}`}
                      width="100%"
                      height="100%"
                      ratio="4 / 3"
                      overflow="hidden"
                      position="relative"
                      borderRadius="small"
                    >
                      <Preview
                        url={file.resizeUrl}
                        makeUrl={makeStorageUrl}
                        width="100%"
                        height="100%"
                        fit="cover"
                        actions={buildArray<PreviewAction>().add({
                          icon: 'action/visibility',
                          onClick: () => handleOpenPreview(file.url),
                        })}
                        data-testid={suffixTestId(`category-auditPhotoItem-[${index}]`, rest)}
                      />
                    </Box>
                  ))}
                </Grid>
              </VStack>
            </GridItem>
          ))}
        </Grid>
      </Card>
      <Lightbox
        data-testid={suffixTestId('category', rest)}
        controls={lightboxControls}
        images={lightboxImages}
      />
    </>
  );
}
