import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {usePostServiceOrderCustomerContractDeleteMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {DISCOUNT_CHANGE_OPTIONS} from '../../../constants/discountChangeOptions';
import {DiscountFormType} from '../types/discountFormType';

interface DeactivateContractProps extends TestIdProps {
  serviceCaseId: string;
  orderId: string;
  onClose: VoidFunction;
}

export function DeactivateContract(props: DeactivateContractProps) {
  const [deactivateContract] = usePostServiceOrderCustomerContractDeleteMutation();

  const handleSubmit: FormSubmitHandler<DiscountFormType> = (data) =>
    deactivateContract({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.orderId,
      body: {
        isDiscountRemove: data.discount === 'remove',
      },
    })
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);

  return (
    <Form<DiscountFormType> defaultValues={{discount: 'remove'}} onSubmit={handleSubmit}>
      {(control) => (
        <VStack spacing={4}>
          <Heading size={4}>
            {i18n.t('entity.customerContract.labels.deactivateDescription')}
          </Heading>
          <FormField
            control={control}
            type="radio"
            name="discount"
            options={DISCOUNT_CHANGE_OPTIONS}
            data-testid={suffixTestId('discount', props)}
          />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discard')}
              variant="secondary"
              onClick={props.onClose}
              data-testid={suffixTestId('discard', props)}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('general.actions.deactivate')}
              variant="danger"
              data-testid={suffixTestId('deactivate', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
