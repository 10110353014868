import {getImageFileNameWithFormat} from './getImageFileNameWithFormat';

/**
 * Downloads a file from a given URL.
 * Works with standard URLs and base64 images.
 *
 * For any future changes, make sure it works with base64 images, or that the base64 images are not used anymore.
 */
export async function downloadImageFile(url: string) {
  // Initial request is redirected to the actual file.
  // By using the 'follow' option, we can get the final response.
  const response = await fetch(url, {redirect: 'follow'});

  const fileName = getImageFileNameWithFormat(url, response.headers);

  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);

  const temporaryLink = document.createElement('a');
  temporaryLink.href = blobUrl;
  temporaryLink.download = fileName;

  temporaryLink.click();

  URL.revokeObjectURL(blobUrl);
}
