import {Icon} from 'platform/foundation';

import {FC} from 'react';

import {CalendarHandle, CalendarHandlesWrapper} from './styles';

/**
 * @internal
 */
interface CalendarHandlesProps {
  onPreviousClicked: () => void;
  onNextClicked: () => void;
}

export const CalendarHandles: FC<CalendarHandlesProps> = ({onPreviousClicked, onNextClicked}) => (
  <CalendarHandlesWrapper>
    <CalendarHandle
      data-testid="button-calendar-prev-month"
      className="previous"
      onClick={onPreviousClicked}
    >
      <Icon value="navigation/chevron_left" />
    </CalendarHandle>
    <CalendarHandle
      data-testid="button-calendar-next-month"
      className="next"
      onClick={onNextClicked}
    >
      <Icon value="navigation/chevron_right" />
    </CalendarHandle>
  </CalendarHandlesWrapper>
);
