import {Icon} from 'platform/foundation';

import {FC} from 'react';

import {testIds} from '@omnetic-dms/routes';

import {FileArea, FileRemoveButton} from '../styles';
import {FileIcon} from './FileIcon';

export interface FileProps {
  name: string;
  onRemove: VoidFunction;
}

export const File: FC<FileProps> = ({name, onRemove}) => (
  <FileArea data-testid={testIds.settings.documentTemplates('fileArea')}>
    <FileIcon />
    <span>{name}</span>
    <FileRemoveButton
      data-testid={testIds.settings.documentTemplates('removeButton')}
      compact
      onlyIcon
      transparent
      type="button"
      icon={<Icon value="navigation/close" />}
      onClick={onRemove}
    />
  </FileArea>
);
