import {closeCurrentDialog, showNotification} from 'platform/components';

import {SharePresetToUseRequestBody} from '../types/Api';
import {useHttpCalls} from './useHttpCalls';

export const useSharePresetToUser = (presetId: string, gridCode: string) => {
  const {sharePresetToUser} = useHttpCalls({
    gridCode,
  });

  const onSubmit = (values: SharePresetToUseRequestBody) =>
    sharePresetToUser(presetId, values)
      .then(() => {
        showNotification.success();
        closeCurrentDialog();
      })
      .catch(() => showNotification.error());

  return {
    onSubmit,
  };
};
