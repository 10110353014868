import {
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  OptionType,
  Separator,
} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter} from 'platform/locale';
import {boolean, object} from 'yup';

import {defaultTo, head, isNil, omit} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  LabourDiscountType,
  PostCustomerContractLabourDiscountApiArg,
  useGetCustomerContractLabourDiscountQuery,
  useGetTenantQuery,
  usePatchCustomerContractLabourDiscountMutation,
  usePostCustomerContractLabourDiscountMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  getDecimalFromPercentage,
  getPercentageFromDecimal,
  handleApiError,
  useWorkTypeOptions,
} from '@omnetic-dms/shared';

import {CurrencyCodeType, suffixTestId, TestIdProps, yupNumber, yupString} from 'shared';

import {DiscountAllowedVariant} from '../../../components/DiscountAllowedVariant';
import {VehicleFields} from '../../../components/VehicleFields';
import {ageTypeOptions} from '../../../constants/ageTypeOptions';
import {DiscountFormType} from '../../../types/DiscountFormType';

interface WorkDiscountFormProps extends TestIdProps {
  labourDiscountId?: string;
  customerContractId?: string;
  onClose: VoidFunction;
  afterSubmit: VoidFunction;
}

export function WorkDiscountForm(props: WorkDiscountFormProps) {
  const formatCurrencySymbol = useCurrencySymbolFormatter();

  const {data: tenant} = useGetTenantQuery();
  const {data, isLoading, isError} = useGetCustomerContractLabourDiscountQuery(
    {
      customerContractId: props.customerContractId ?? '',
      labourDiscountId: props.labourDiscountId ?? '',
    },
    {skip: isNil(props.labourDiscountId)}
  );

  const [postLabourDiscount] = usePostCustomerContractLabourDiscountMutation();
  const [patchLabourDiscount] = usePatchCustomerContractLabourDiscountMutation();

  const {getOptionsWithSelectedValues, isLoading: isWorkTypesLoading} = useWorkTypeOptions();

  const handleSubmit: FormSubmitHandler<DiscountFormType> = async (data) => {
    const body: PostCustomerContractLabourDiscountApiArg['body'] = {
      ...omit(
        ['vehicleType', 'vehicleMake', 'directSaleVariantIds', 'serviceOrderVariantIds'],
        data
      ),
      vehicleTypes: data.vehicleType ? [data.vehicleType] : undefined,
      vehicleMakes: data.vehicleMake ? [data.vehicleMake] : undefined,
      directSaleVariantIds: data.isDirectSaleVariant ? data.directSaleVariantIds : undefined,
      serviceOrderVariantIds: data.isServiceOrderVariant ? data.serviceOrderVariantIds : undefined,
      discountSettingsValue:
        data.discountSettingsType === 'PERCENTAGE_DISCOUNT' && isNotNil(data.discountSettingsValue)
          ? getDecimalFromPercentage(data.discountSettingsValue)
          : data.discountSettingsValue,
    };

    await (
      isNotNil(props.labourDiscountId)
        ? patchLabourDiscount({
            customerContractId: props.customerContractId ?? '',
            labourDiscountId: props.labourDiscountId,
            body,
          })
        : postLabourDiscount({
            customerContractId: props.customerContractId ?? '',
            body,
          })
    )
      .unwrap()
      .then(() => {
        props.onClose();
        props.afterSubmit();
      })
      .catch(handleApiError);
  };

  const workTypeOptions = getOptionsWithSelectedValues(data?.workTypes);

  const defaultValues: DiscountFormType = {
    ...data,
    name: data?.name ?? '',
    vehicleType: head(data?.vehicleTypes ?? []),
    vehicleMake: head(data?.vehicleMakes ?? []),
    vehicleAgeType: data?.vehicleAgeType ?? 'VEHICLE_AGE_UNDECIDED',
    isServiceOrderVariant: defaultTo(true)(data?.isServiceOrderVariant),
    isDirectSaleVariant: defaultTo(true)(data?.isDirectSaleVariant),
    discountSettingsType: data?.discountSettingsType ?? 'DISCOUNTED_FIXED_RATE',
    discountSettingsValue:
      data?.discountSettingsType === 'PERCENTAGE_DISCOUNT' && isNotNil(data?.discountSettingsValue)
        ? getPercentageFromDecimal(data?.discountSettingsValue)
        : data?.discountSettingsValue,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={206}>
      <Form<DiscountFormType>
        onSubmit={handleSubmit}
        schema={formSchema}
        defaultValues={defaultValues}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="name"
              type="text"
              label={i18n.t('general.labels.name')}
              isRequired
              data-testid={suffixTestId('name', props)}
            />
            <Separator spacing={0} />
            <VehicleFields control={control} formApi={formApi} data-testid={props['data-testid']} />
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  name="vehicleAgeType"
                  type="radio"
                  options={ageTypeOptions}
                  label={i18n.t('entity.vehicle.labels.vehicleAge')}
                  onChange={() => {
                    formApi.setValue('vehicleAgeFrom', undefined);
                    formApi.setValue('vehicleAgeTo', undefined);
                  }}
                  data-testid={suffixTestId('vehicleAgeType', props)}
                />
              </Box>
              <Box flex={1}>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="vehicleAgeFrom"
                      type="integer"
                      label={i18n.t('entity.customerContract.labels.ageFrom')}
                      isDisabled={formApi.watch('vehicleAgeType') !== 'VEHICLE_AGE_IN_MONTHS'}
                      data-testid={suffixTestId('vehicleAgeFrom', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="vehicleAgeTo"
                      type="integer"
                      label={i18n.t('entity.customerContract.labels.ageTo')}
                      isDisabled={formApi.watch('vehicleAgeType') !== 'VEHICLE_AGE_IN_MONTHS'}
                      data-testid={suffixTestId('vehicleAgeTo', props)}
                    />
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <Separator spacing={0} />
            <FormField
              control={control}
              name="workTypes"
              type="multiChoice"
              label={i18n.t('entity.addWork.lables.workCategory')}
              options={workTypeOptions}
              isLoading={isWorkTypesLoading}
              data-testid={suffixTestId('workTypes', props)}
              isNotClearable
            />
            <DiscountAllowedVariant
              control={control}
              data-testid={suffixTestId('discountAllowedVariant', props)}
            />
            <Card
              variant="inlineGrey"
              title={i18n.t('entity.workshopCustomerGroup.labels.discountSettings')}
            >
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="discountSettingsType"
                    type="radio"
                    options={getDiscountOptions(
                      formatCurrencySymbol(tenant?.currency as CurrencyCodeType)
                    )}
                    direction="column"
                    spacing={4}
                    data-testid={suffixTestId('discountSettingsType', props)}
                  />
                  <Space vertical={4} />
                  <FormField
                    control={control}
                    name="discountSettingsValue"
                    type="number"
                    isStepperVisible
                    minStepperValue={0}
                    maxStepperValue={
                      formApi.watch('discountSettingsType') === 'PERCENTAGE_DISCOUNT'
                        ? 100
                        : undefined
                    }
                    decimalPlaces={2}
                    helperText={i18n.t('entity.customerContract.labels.discountDescription')}
                    data-testid={suffixTestId('discountSettingsValue', props)}
                  />
                </Box>
                <Space fillAvailable />
              </HStack>
            </Card>
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                variant="secondary"
                onClick={props.onClose}
                data-testid={suffixTestId('close', props)}
              />
              <FormButton
                control={control}
                type="submit"
                title={
                  props.labourDiscountId
                    ? i18n.t('general.actions.saveChanges')
                    : i18n.t('general.actions.create')
                }
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const getDiscountOptions = (currency: string): OptionType<LabourDiscountType>[] => [
  {
    label: i18n.t('entity.customerContract.labels.discountedRate', {currency}),
    value: 'DISCOUNTED_FIXED_RATE',
  },
  {
    label: i18n.t('entity.customerContract.labels.percentageDiscount'),
    value: 'PERCENTAGE_DISCOUNT',
  },
];

const formSchema = object({
  name: yupString.required(),
  discountSettingsValue: yupNumber
    .when('discountSettingsType', {
      is: 'PERCENTAGE_DISCOUNT',
      then: yupNumber.min(0).max(100),
      otherwise: yupNumber.min(0),
    })
    .required(),
  isServiceOrderVariant: boolean()
    .required()
    .test(
      'atLeastOneTrueServiceOrderVariant',
      i18n.t('entity.customerContract.labels.variantValidation'),
      (value, context) => value || context.parent.isDirectSaleVariant
    ),
  isDirectSaleVariant: boolean()
    .required()
    .test(
      'atLeastOneTrueDirectSaleVariant',
      i18n.t('entity.customerContract.labels.variantValidation'),
      (value, context) => value || context.parent.isServiceOrderVariant
    ),
});
