import {addMonths} from 'date-fns';

import {getApiDateString} from 'shared';

import {Replacement} from '../types/Replacement';
import {UpdateServiceIntervalForm} from '../types/UpdateServiceIntervalForm';

export function replacementUpdate(act: Replacement, formData: UpdateServiceIntervalForm) {
  const copy = structuredClone(act);

  copy.lastReplacementDate = formData.date;
  copy.lastReplacementMileage = formData.mileage ?? 0;

  if (copy.replacementAfterMonths) {
    copy.nextReplacementDate = getApiDateString(
      addMonths(copy.lastReplacementDate, copy.replacementAfterMonths)
    );
  }

  if (copy.replacementAfterMileage) {
    copy.nextReplacementMileage = copy.lastReplacementMileage + copy.replacementAfterMileage;
  }

  return copy;
}
