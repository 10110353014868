import {ColumnApi} from '@ag-grid-community/core';
import {showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {useMemo, useState} from 'react';

import {head, isNil, isNotNil} from 'ramda';
import {isArray, notEqual} from 'ramda-adjunct';

import {Nullish, sanitizeObject, TestIdProps} from 'shared';

import {useDataGridContext} from '../context/useDataGridContext';
import {useHttpCalls} from '../hooks/useHttpCalls';
import {GridApi, GridOptions} from '../types/AgGridTypes';
import {ColumnResponseBody, FeGridSettings, GetDataQueryResponse} from '../types/Api';
import {DataGridProps} from '../types/DataGridProps';
import {ExportState} from '../types/ExportOptionsRenderer';
import {buildExportQueryParams} from '../utils/buildExportQueryParams';
import {downloadBlobFile} from '../utils/downloadBlobFile';
import {extractColumnStateProperties} from '../utils/extractColumnStateProperties';
import {getColumnState} from '../utils/getColumnState';
import {isPresetCustom} from '../utils/isPresetCustom';
import {DefaultExportOptionsRenderer, ExportFormType} from './DefaultExportOptionsRenderer';

export interface ExportOptionsRendererWrapperProps extends TestIdProps {
  isOpen: boolean;
  onClose: () => void;
  gridProps: DataGridProps;
  gridApi?: GridApi;
  colApi?: ColumnApi;
  gridOptions: GridOptions;
  dataGridSettings: FeGridSettings;
  columns: ColumnResponseBody[];
  dataQuery: GetDataQueryResponse | Nullish;
  count: number;
}

const DEFAULT_FILE_NAME = 'export';
export function ExportOptionsRendererWrapper({
  gridProps,
  gridApi,
  isOpen,
  onClose,
  count,
  dataQuery,
  dataGridSettings,
  ...props
}: ExportOptionsRendererWrapperProps) {
  const http = useHttpCalls();
  const [exportState, setExportState] = useState<ExportState>('idle');

  const {activePreset} = useDataGridContext();

  const isPresetLocallyChanged = useMemo(() => {
    if (!isOpen) {
      return false;
    }

    if (!isPresetCustom(activePreset) || isNil(dataQuery) || isNil(gridApi)) {
      return false;
    }
    const sanitizedFilters = sanitizeObject(isArray(dataQuery.filters) ? {} : dataQuery.filters);

    const didFiltersChange = notEqual(sanitizedFilters, gridApi?.getFilterModel());
    const didGridSettingsChange = notEqual(activePreset.gridSettings, dataGridSettings);

    const didColumnsSettingsChange = notEqual(
      extractColumnStateProperties(gridApi.getColumnState()),
      extractColumnStateProperties(getColumnState(activePreset, dataQuery))
    );

    return didFiltersChange || didColumnsSettingsChange || didGridSettingsChange;
  }, [activePreset, dataGridSettings, dataQuery, gridApi, isOpen]);

  const exportToFile = async (exportOptions: ExportFormType) => {
    setExportState('exporting');
    const fileType = isArray(exportOptions.fileType)
      ? head(exportOptions.fileType)
      : exportOptions.fileType;

    const options = match(fileType)
      .with('excel', () => ({
        queryParams: {
          decimalDelimiter: exportOptions.decimalSeparator,
          instanceId: gridProps.presetInstanceId,
        },
        fileExtension: 'xlsx' as const,
      }))
      .with('csv', () => ({
        queryParams: {
          decimalDelimiter: exportOptions.decimalSeparator,
          columnDelimiter: exportOptions.columnSeparator,
          instanceId: gridProps.presetInstanceId,
        },
        fileExtension: 'csv' as const,
      }))
      .otherwise(() => undefined);

    if (fileType && options?.queryParams) {
      const blob = await http.getExport(fileType, buildExportQueryParams(options.queryParams));

      try {
        if (isNotNil(blob)) {
          downloadBlobFile(blob, {fileName: DEFAULT_FILE_NAME, fileType: options.fileExtension});
        }
      } catch (e) {
        showNotification.error();
      }
    }

    setExportState('idle');
    onClose();
  };

  return (
    <DefaultExportOptionsRenderer
      totalRows={count}
      exportState={exportState}
      isOpen={isOpen}
      onClose={onClose}
      isPresetChanged={isPresetLocallyChanged}
      exportToFile={exportToFile}
      data-testid={props['data-testid']}
    />
  );
}
