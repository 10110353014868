import {VehicleType} from './VehicleType';

export const permanentVehicleType: VehicleType = 'VEHICLETYPE_PASSENGER_CAR';

export enum SourcingSubPage {
  classifieds = 'classifieds',
}

export enum SourcingSubPageRoute {
  classifieds = '/sourcing/classifieds',
}

export enum UserListPage {
  COMPARISON_PAGE = 'comparison',
  CART_PAGE = 'user-to-buy',
  HIDDEN_VEHICLE_PAGE = 'user-hidden',
  FAVORITES_VEHICLE_PAGE = 'user-favourite',
  DEFAULT = '',
}

export const SOURCING_USER_LISTS = (root: SourcingSubPageRoute, listType = UserListPage.DEFAULT) =>
  `${root}${listType && `/${listType}`}`;
