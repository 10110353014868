import {ApiEnumKey, GetAllApiEnumsApiResponse} from '@omnetic-dms/api';

export type PropertyName =
  | 'car_style'
  | 'fuel_type'
  | 'drive'
  | 'feature'
  | 'emission_standard'
  | 'service_book_type'
  | 'battery_ownership_type'
  | 'battery_type'
  | 'body_color'
  | 'carvago_feature'
  | 'charging_connector_type'
  | 'color_type'
  | 'condition'
  | 'cost'
  | 'country'
  | 'currency'
  | 'door_count'
  | 'emission_class'
  | 'feature_category'
  | 'fuel_unit'
  | 'hand_drive_type'
  | 'hybrid_type'
  | 'interior_color'
  | 'interior_material'
  | 'seller_type'
  | 'service_book_state'
  | 'tire_aspect_ratio'
  | 'tire_make'
  | 'tire_model'
  | 'tire_rim_diameter'
  | 'tire_season'
  | 'tire_width'
  | 'transmission'
  | 'vehicle_type';

export const getEnumByProperty = (
  propertyName: PropertyName,
  enums?: GetAllApiEnumsApiResponse
): ApiEnumKey[] => enums?.find((item) => item.property_name === propertyName)?.keys ?? [];
