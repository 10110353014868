import {
  AddressRequestBodyV2,
  ContractInformationResponseBody,
  IdentityCardResponseBodyV2,
  OrderAdditionalCustomerResponseBody,
  OrderResponseBody,
  PersonRequestBody,
  PersonResponseBodyV2,
} from '@omnetic-dms/api';
import {IdentityCardData} from '@omnetic-dms/teas';

import {Nullish, TestIdProps} from 'shared';

import {CheckoutAdditionalCustomer} from './CheckoutAdditionalCustomer';
import {CheckoutContractInformationFormState} from './CheckoutContractInformationForm';

interface CheckoutAdditionalCustomersProps extends TestIdProps {
  additionalCustomers: OrderAdditionalCustomerResponseBody[];
  counterStartAt: number;
  order: OrderResponseBody;
  customerId: string;
  checkoutId: string;
  permanentPersonId?: string;
  refreshBusinessCaseCheckoutInfo: (customerId: string | Nullish) => void;
  handleAdditionalPersonIdentityCardSubmit: (
    customerId: string,
    person: PersonResponseBodyV2,
    cardData: IdentityCardData,
    id: string | null
  ) => Promise<IdentityCardResponseBodyV2 | null>;
  handlePersonSubmit: (
    requestBody: PersonRequestBody,
    personId: string | null
  ) => Promise<PersonResponseBodyV2 | null>;
  handleAdditionalPersonSubmit: (
    customerId: string,
    requestBody: PersonRequestBody,
    personId: string | null
  ) => Promise<PersonResponseBodyV2 | null>;
  handleAdditionalPersonAddressSubmit: (
    customerId: string,
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ) => Promise<string | null>;
  handleEditCheckoutContractInformation: (
    customerId: string,
    contractInformationId: string,
    orderId: string,
    isContractChange?: boolean
  ) => (
    values: CheckoutContractInformationFormState
  ) => Promise<ContractInformationResponseBody | null>;
  handleCreateAdditionalCheckoutContractInformation: (
    customerId: string
  ) => (
    values: CheckoutContractInformationFormState
  ) => Promise<ContractInformationResponseBody | null>;
}

export function CheckoutAdditionalCustomers(props: CheckoutAdditionalCustomersProps) {
  return (
    <>
      {props.additionalCustomers.map((additionalCustomer, index) => (
        <CheckoutAdditionalCustomer
          customerId={additionalCustomer.customerId}
          key={additionalCustomer.id}
          index={index}
          additionalCustomer={additionalCustomer}
          counterStartAt={props.counterStartAt}
          order={props.order}
          checkoutId={props.checkoutId}
          permanentPersonId={props.permanentPersonId}
          refreshBusinessCaseCheckoutInfo={props.refreshBusinessCaseCheckoutInfo}
          handleAdditionalPersonIdentityCardSubmit={props.handleAdditionalPersonIdentityCardSubmit}
          handlePersonSubmit={props.handlePersonSubmit}
          handleAdditionalPersonSubmit={props.handleAdditionalPersonSubmit}
          handleAdditionalPersonAddressSubmit={props.handleAdditionalPersonAddressSubmit}
          handleEditCheckoutContractInformation={props.handleEditCheckoutContractInformation}
          handleCreateAdditionalCheckoutContractInformation={
            props.handleCreateAdditionalCheckoutContractInformation
          }
          data-testid={props['data-testid']}
        />
      ))}
    </>
  );
}
