import type {PermissionsRecordType} from '../permissions';

export const accountingPermissions = {
  readCashRegisterDocument: {
    actionId: 'READ',
    resourceId: 'CASH_REGISTER_DOCUMENT',
    scopes: ['CASH_REGISTER_DOCUMENT.BRANCH'],
  },
  readInvoice: {
    actionId: 'READ',
    resourceId: 'INVOICE',
    scopes: ['INVOICE.BRANCH'],
  },
} satisfies PermissionsRecordType;
