import {Box, Grid} from 'platform/foundation';

import {FC} from 'react';

import {not} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {useVehicleCatalogue} from '@omnetic-dms/shared';

import {TestIdProps} from 'shared';

import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {MakeSelect} from '../../MakeSelect/MakeSelect';
import {ModelSelect} from '../../ModelSelect/ModelSelect';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';
import {VehicleTitleField} from './VehicleTitleField';

interface InfoFormProps extends TestIdProps {
  createMode?: boolean;
}

export const InfoForm: FC<InfoFormProps> = () => {
  const {Field, form, Subscribe} = useFormRenderer<VehicleCreateFormState>();
  const {
    mutators: {createCustomMake, createCustomModel, clearModel, handleTypeChange},
  } = form;

  const [{vehicleTypeOptions}, {isLoading}] = useVehicleCatalogue(null);

  return (
    <Box>
      <Grid columns={1}>
        <Field
          name="type"
          as="chips"
          isLoading={isLoading}
          label={i18n.t('entity.vehicle.labels.vehicleType')}
          options={vehicleTypeOptions ?? []}
          onChange={handleTypeChange}
        />
      </Grid>
      <Grid columns={4}>
        <Subscribe
          name="type"
          component={({input: {value: vehicleType}}) => (
            <>
              <MakeSelect<VehicleCreateFormState>
                name="make"
                label={i18n.t('entity.vehicle.labels.make')}
                vehicleType={vehicleType}
                handleCreateOption={createCustomMake}
                onChange={clearModel}
              />
              <Subscribe<string>
                name="make"
                component={({input: {value: make}}) => (
                  <ModelSelect<VehicleCreateFormState>
                    name="modelFamily"
                    label={i18n.t('entity.vehicle.labels.model')}
                    vehicleType={vehicleType}
                    make={make}
                    handleCreateOption={createCustomModel}
                  />
                )}
              />
            </>
          )}
        />
        <Field name="trim" label={i18n.t('entity.vehicle.labels.trim')} />
        <Field name="variant" label={i18n.t('entity.vehicle.labels.variantName')} />
      </Grid>
      <Grid columns={2}>
        <VehicleTitleField />
      </Grid>
      <Grid columns={4}>
        <Field
          as="date"
          name="firstRegistrationDate"
          label={i18n.t('entity.vehicle.labels.firstRegistration')}
        />
        <Field
          name="manufacturedOnYear"
          as="select"
          label={i18n.t('entity.vehicle.labels.realProductionYear')}
          options={yearOptions}
          getOptionValue={({id}) => id}
        />
        <Field
          name="manufacturedOnMonth"
          as="select"
          label={i18n.t('entity.vehicle.labels.realProductionMonth')}
          options={prodMonthOptions}
          getOptionValue={({id}) => id}
        />
      </Grid>
      <Subscribe
        name="type"
        component={({input: {value: vehicleType}}) => {
          if (not(['VEHICLETYPE_TRAILER', 'VEHICLETYPE_SEMI_TRAILER'].includes(vehicleType))) {
            return null;
          }
          return (
            <Grid columns={1}>
              <Field
                as="chips"
                name="axleCount"
                options={[
                  {label: 1, value: 1},
                  {label: 2, value: 2},
                  {label: 3, value: 3},
                ]}
                label={i18n.t('entity.vehicle.labels.axleCount')}
              />
            </Grid>
          );
        }}
      />
    </Box>
  );
};

const prodMonthOptions = [
  {
    id: '1',
    label: i18n.t('general.months.January'),
  },
  {
    id: '2',
    label: i18n.t('general.months.February'),
  },
  {
    id: '3',
    label: i18n.t('general.months.March'),
  },
  {
    id: '4',
    label: i18n.t('general.months.April'),
  },
  {
    id: '5',
    label: i18n.t('general.months.May'),
  },
  {
    id: '6',
    label: i18n.t('general.months.June'),
  },
  {
    id: '7',
    label: i18n.t('general.months.July'),
  },
  {
    id: '8',
    label: i18n.t('general.months.August'),
  },
  {
    id: '9',
    label: i18n.t('general.months.September'),
  },
  {
    id: '10',
    label: i18n.t('general.months.October'),
  },
  {
    id: '11',
    label: i18n.t('general.months.November'),
  },
  {
    id: '12',
    label: i18n.t('general.months.December'),
  },
];

const yearOptions = Array.from(Array(new Date().getFullYear() - 1899)).map((_num, index) => {
  const currentYear = new Date().getFullYear() - index;
  return {
    id: currentYear.toString(),
    label: currentYear.toString(),
  };
});
