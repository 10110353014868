import {FieldValues, Path, PathValue, UseFormReturn} from 'react-hook-form';

type ResetValue<TFieldValues extends FieldValues = FieldValues> = {
  name: Path<TFieldValues>;
  value: PathValue<TFieldValues, Path<TFieldValues>>;
};

export const handleFormReset = <TFieldValues extends FieldValues = FieldValues>(
  formApi: UseFormReturn<TFieldValues>,
  resetValues: ResetValue<TFieldValues>[]
) => {
  resetValues.forEach((item) => {
    formApi.setValue(item.name, item.value);
  });
};
