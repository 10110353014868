import {useEffect, useRef, useState} from 'react';

export const useIsElementOverflowing = () => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateVisibility = () =>
      setIsOverflowing((ref.current?.scrollWidth ?? 0) > (ref.current?.clientWidth ?? 0));

    window.addEventListener('resize', updateVisibility);
    updateVisibility();

    return () => window.removeEventListener('resize', updateVisibility);
  }, []);

  return [ref, isOverflowing] as const;
};
