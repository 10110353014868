import {useCallback, useEffect, useRef} from 'react';

import {AgGridEvent, GridApi} from '../types/AgGridTypes';

/**
 * Returns functions that will add or remove event listener to ag grid api.
 * This should also clear listeners when component unmounts
 * RESOURCES:
 * - https://www.ag-grid.com/react-data-grid/grid-events/
 * - https://github.com/ag-grid/ag-grid/blob/latest/community-modules/core/src/ts/eventService.ts
 */
export const useGridApiEventListener = (
  gridApi: GridApi | undefined,
  eventName: Array<string> | string,
  handler: (event: AgGridEvent) => void
) => {
  const savedHandler = useRef(handler);
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  const eventListener = useCallback((event: AgGridEvent) => {
    savedHandler.current(event);
  }, []);

  const addListeners = useCallback(() => {
    const eventNameAsArray = Array.isArray(eventName) ? eventName : [eventName];
    eventNameAsArray.forEach((name) => gridApi?.addEventListener(name, eventListener));
  }, [gridApi, eventListener, eventName]);

  const removeListeners = useCallback(() => {
    const eventNameAsArray = Array.isArray(eventName) ? eventName : [eventName];
    eventNameAsArray.forEach((name) => gridApi?.removeEventListener(name, eventListener));
  }, [gridApi, eventListener]);

  useEffect(() => removeListeners, [removeListeners]);

  return {addListeners, removeListeners};
};
