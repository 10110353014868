import {Chips, Separator, Switch} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {ForwardedRef, forwardRef, useCallback, useImperativeHandle, useRef, useState} from 'react';

import {equals} from 'ramda';
import {isBoolean, isString} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {IFilter, IFilterParams} from '../../types/AgGridTypes';
import {QuickFilters} from '../../types/Api';

export type SwitchFilterProps = {
  isDisabled: boolean;
} & IFilterParams &
  QuickFilters &
  TestIdProps;

function SwitchFilterComponent(
  {filterChangedCallback, isDisabled, quickFilters, ...props}: SwitchFilterProps,
  ref: ForwardedRef<IFilter>
) {
  const [value, _setValue] = useState<boolean | string>(false);
  const isActive = useRef<boolean>(false);
  const setValue = useCallback((v: typeof value) => {
    isActive.current = !!v;
    _setValue(v);
  }, []);
  const {onChipsChange} = useFilterOnChipsChange({
    filterChangedCallback,
    setFilterValue: setValue,
    defaultValue: false,
  });

  const onChange = (value: boolean) => {
    setValue(value);
    filterChangedCallback();
  };

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return isActive.current;
    },

    doesFilterPass() {
      return true;
    },

    getModel() {
      return value === true ? true : undefined;
    },

    setModel(model: boolean | undefined) {
      if (equals(model, value)) {
        return;
      }
      onChange(model ?? false);
    },
  }));

  const isQuickFilterValue = isString(value) ? [value] : undefined;

  const isValue = isBoolean(value) ? value : false;

  return (
    <VStack>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('switch-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <Switch
        isDisabled={isDisabled}
        value={isValue}
        onChange={onChange}
        data-testid={suffixTestId('switch-filter', props)}
      />
    </VStack>
  );
}

export const SwitchFilter = forwardRef<IFilter, SwitchFilterProps>(SwitchFilterComponent);
