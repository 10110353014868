import {
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Heading, HStack, Inline, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {fromPairs, keys, map, pickBy, pluck, values} from 'ramda';

import {
  useDeprecatedGetVerificationRegistersQuery,
  useDeprecatedVerifyMutation,
  VerificationRegisterResponseBody,
  VerificationRegisterType,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

interface CustomerVerificationProps extends TestIdProps {
  contractInformationId: string;
  businessCaseId: string;
  onSuccess: VoidFunction;
  onCancel: VoidFunction;
}

export function VerificationServicesForm(props: CustomerVerificationProps) {
  const formatDateTime = useDateTimeFormatter();
  const [verify] = useDeprecatedVerifyMutation();
  const {
    data: registers,
    isLoading,
    isError,
  } = useDeprecatedGetVerificationRegistersQuery({
    contractInformationId: props.contractInformationId,
    businessCaseId: props.businessCaseId,
  });

  const availableRegisters = pluck(
    'verificationRegisterType',
    (registers ?? []).filter((value: VerificationRegisterResponseBody) => value.isAvailable)
  );
  const defaultValues = fromPairs(map((register) => [register, true], availableRegisters));

  const onSubmit: FormSubmitHandler<Record<VerificationRegisterType, boolean>> = async (values) => {
    const verificationRegisterTypes = keys(pickBy(Boolean, values)) as VerificationRegisterType[];

    await verify({
      body: {verificationRegisterTypes},
      businessCaseId: props.businessCaseId,
      contractInformationId: props.contractInformationId,
    })
      .unwrap()
      .then(props.onSuccess)
      .catch(handleApiError);
  };

  return (
    <DataStatus
      isError={isError}
      isLoading={isLoading}
      isEmpty={!availableRegisters?.length}
      emptyMessage={i18n.t('entity.customerVerification.labels.noAvailableRegisters')}
      spacing={8}
    >
      <Form<Record<VerificationRegisterType, boolean>>
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        data-testid={suffixTestId('form', props)}
      >
        {(control, api) => (
          <VStack spacing={4}>
            {registers?.map((register) => {
              const name = register.verificationRegisterType;
              const hasHistory =
                register.verificationInfo?.requestedAt && register.verificationInfo?.requestedBy;

              return (
                <Show key={name} when={availableRegisters.includes(name)}>
                  <Card variant="inlineGrey">
                    <HStack spacing={4}>
                      <Inline>
                        <FormField
                          name={name}
                          type="checkbox"
                          control={control}
                          data-testid={suffixTestId(`checkbox-${name}`, props)}
                        />
                      </Inline>
                      <VStack spacing={2}>
                        <Heading size={5}>
                          {i18n.t(
                            `entity.customerVerification.labels.registers.${register.verificationRegisterType}`
                          )}
                        </Heading>
                        <Text size="small" color="tertiary">
                          {hasHistory
                            ? i18n.t('entity.customerVerification.labels.checkedOnBy', {
                                date: formatDateTime(
                                  'dateShort',
                                  parseDate(register.verificationInfo?.requestedAt ?? '')
                                ),
                                name: register.verificationInfo?.requestedBy,
                              })
                            : i18n.t('entity.customerVerification.labels.notChecked')}
                        </Text>
                      </VStack>
                    </HStack>
                  </Card>
                </Show>
              );
            })}
            <ButtonGroup align="right">
              <FormButton
                control={control}
                variant="secondary"
                onClick={props.onCancel}
                title={i18n.t('general.actions.cancel')}
                data-testid={suffixTestId('cancel', props)}
              />
              <FormButton
                control={control}
                type="submit"
                variant="primary"
                title={i18n.t('entity.customerVerification.actions.verify')}
                isDisabled={!isAnyRegisterSelected(api.watch())}
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const isAnyRegisterSelected = (registers: Record<VerificationRegisterType, boolean>) =>
  values(registers).some(Boolean);
