import {MutableRefObject, RefObject, useCallback, useRef} from 'react';

type UserRef<T> = ((instance: T | null) => void) | RefObject<T> | null | undefined;

type Writable<T> = {-readonly [P in keyof T]: T[P]};

/**
 * Custom React hook that composes a library-managed ref with a user-provided ref.
 * Both refs will be updated to refer to the same instance of a DOM element.
 *
 * @param {MutableRefObject<T | null>} libRef A ref object managed by the library that needs to be updated.
 * @param {UserRef<T>} userRef A ref provided by the user of the library, which needs to be composed with `libRef`.
 * @returns {React.RefCallback<T>} A callback ref that can be attached to a React element.
 * @template T The HTML element type that the refs will refer to.
 */
export const useComposedRef = <T extends HTMLElement>(
  libRef: MutableRefObject<T | null>,
  userRef: UserRef<T>
) => {
  const prevUserRef = useRef<UserRef<T>>();

  return useCallback(
    (instance: T | null) => {
      libRef.current = instance;

      if (prevUserRef.current) {
        updateRef(prevUserRef.current, null);
      }

      prevUserRef.current = userRef;

      if (!userRef) {
        return;
      }

      updateRef(userRef, instance);
    },
    [userRef]
  );
};

const updateRef = <T>(ref: NonNullable<UserRef<T>>, value: T | null) => {
  if (typeof ref === 'function') {
    ref(value);
    return;
  }

  (ref as Writable<typeof ref>).current = value;
};
