import {Actions, closeCurrentDialog, openDeleteDialog, openDialog} from 'platform/components';
import {HStack, Heading, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteServicePackageItemsMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId, useToggle} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {AddMaterialItemsDialog} from './AddMaterialItemsDialog';
import {AddWorkItemsDialog} from './AddWorkItemsDialog';
import {EditMaterialItem} from './EditMaterialItem';
import {EditWorkItem} from './EditWorkItem';

type CastedRowData = {
  itemType?: {value?: {key?: 'SVCITEMTYPE_PART' | 'SVCITEMTYPE_WORK'}};
  itemName?: {value?: string};
};

interface RequestItemsProps extends TestIdProps {
  servicePackageId: string;
}

export function RequestItems(props: RequestItemsProps) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [isAddWorkOpen, toggleWorkOpen] = useToggle(false);
  const [isAddMaterialOpen, toggleMaterialOpen] = useToggle(false);

  const [deleteServicePackageItems] = useDeleteServicePackageItemsMutation();

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {servicePackageId: props.servicePackageId}]);

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData, rowData}) => {
    const servicePackageItemId = isArray(rowId) ? rowId[0] : rowId;
    const servicePackageItemIds = isArray(rowId) ? rowId : [rowId];
    const castedRowData = (isArray(rowData) ? rowData[0] : rowData) as CastedRowData;
    const type = castedRowData.itemType?.value?.key;

    match(actionKey)
      .with('edit', 'redirectDetail', () =>
        openDialog(
          type === 'SVCITEMTYPE_WORK' ? (
            <EditWorkItem
              context="request"
              itemId={servicePackageItemId}
              servicePackageId={props.servicePackageId}
              onClose={closeCurrentDialog}
              onAfterSubmit={refreshData}
            />
          ) : (
            <EditMaterialItem
              context="request"
              itemId={servicePackageItemId}
              servicePackageId={props.servicePackageId}
              onClose={closeCurrentDialog}
              onAfterSubmit={refreshData}
            />
          ),
          {
            title: castedRowData?.itemName?.value ?? i18n.t('general.labels.edit'),
          }
        )
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteServicePackageItems({
              servicePackageId: props.servicePackageId,
              body: {servicePackageItemIds},
            })
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .otherwise(always(undefined));
  };

  return (
    <VStack spacing={4}>
      <HStack justify="space-between" align="center">
        <Heading size={4}>{i18n.t('entity.workshopServicePackages.labels.workItems')}</Heading>
        <Actions
          data-testid={suffixTestId('actions', props)}
          actions={[
            {
              type: 'button',
              variant: 'link',
              leftIcon: 'content/add_circle',
              title: i18n.t('entity.workshopServicePackages.actions.addWork'),
              onClick: toggleWorkOpen,
            },
            {
              type: 'button',
              variant: 'link',
              leftIcon: 'content/add_circle',
              title: i18n.t('entity.workshopServicePackages.actions.addMaterial'),
              onClick: toggleMaterialOpen,
            },
          ]}
        />
      </HStack>
      <DataGrid
        ref={dataGridRef}
        gridCode="service-package-items"
        actionCallback={actionCallback}
        queryModifier={queryModifier}
        data-testid={suffixTestId('dataGrid', props)}
        autoHeight
      />
      <Show when={isAddWorkOpen}>
        <AddWorkItemsDialog
          packageId={props.servicePackageId}
          onClose={toggleWorkOpen}
          onAfterSubmit={refreshDataGrid}
        />
      </Show>
      <Show when={isAddMaterialOpen}>
        <AddMaterialItemsDialog
          packageId={props.servicePackageId}
          onClose={toggleMaterialOpen}
          onAfterSubmit={refreshDataGrid}
        />
      </Show>
    </VStack>
  );
}
