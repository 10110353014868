import {
  AdaptiveImage,
  AdaptiveImageProps,
  Hide,
  Image,
  Show,
  Box,
  Clickable,
  Center,
} from 'platform/foundation';

import {omit} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {Nullish, RequiredTestIdProps, suffixTestId, TestIdProps, useBoolean} from 'shared';

import {Actions} from '../Actions/Actions';
import {IconButtonActionProps} from '../Actions/components/IconButtonAction';
import {ButtonGroup} from '../ButtonGroup/ButtonGroup';
import {Overlay} from '../Overlay/Overlay';

export type PreviewAction = Pick<IconButtonActionProps, 'icon' | 'isDisabled' | 'onClick'> &
  TestIdProps;

type PreviewProps = {
  actions?: PreviewAction[] | Nullish;
} & RequiredTestIdProps &
  AdaptiveImageProps;

export function Preview(props: PreviewProps) {
  const [isMouseEnter, setIsMouseEnter, setIsMouseLeave] = useBoolean(false);

  /**
   * @description It covers a use case typical for vehicle inspection, where an image is first inserted as a preview and then,
   * after inspection submit, it is uploaded. Both UC in Preview component.
   */
  const isLocalPreview = isTrue(props.url?.startsWith('blob'));

  return (
    <Clickable
      width={props.width}
      height={props.height}
      maxWidth={props.maxWidth}
      maxHeight={props.maxHeight}
      onMouseEnter={setIsMouseEnter}
      onMouseLeave={setIsMouseLeave}
    >
      <Box
        data-testid={suffixTestId('preview', props)}
        width="100%"
        height="100%"
        position="relative"
      >
        <Show when={isLocalPreview}>
          <Image
            data-testid={suffixTestId('localPreviewImage', props)}
            width="100%"
            height="100%"
            src={props.url}
            ratio={props.ratio}
            fit={props.fit}
            alt={props.alt}
          />
        </Show>
        <Hide when={isLocalPreview}>
          <AdaptiveImage
            {...(omit(['data-testid'], props) as AdaptiveImageProps)}
            data-testid={suffixTestId('previewImage', props)}
          />
        </Hide>
        <Show when={isMouseEnter}>
          <Overlay variant="neutral" density="high">
            <Center width="100%" height="100%" justify="center">
              <ButtonGroup data-testid={suffixTestId('preview', props)}>
                <Actions
                  data-testid={suffixTestId('preview', props)}
                  actions={props.actions?.map((action) => ({
                    'data-testid':
                      action['data-testid'] ?? suffixTestId(`preview-action-${action.icon}`, props),
                    type: 'iconButton',
                    size: 'default',
                    priority: 'secondary',
                    icon: action.icon,
                    isDisabled: action.isDisabled,
                    onClick: action.onClick,
                  }))}
                />
              </ButtonGroup>
            </Center>
          </Overlay>
        </Show>
      </Box>
    </Clickable>
  );
}
