import {Actions, Alert, Radio} from 'platform/components';
import {Box, Heading, HStack, Image, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {useState} from 'react';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {useInsuranceComparison} from '../../../hooks/useInsuranceComparison';
import {InsuranceData} from '../../../hooks/useInsuranceData';

interface SellReminderFormProps {
  insuranceData: InsuranceData;
  businessCaseId: string;
  onDone: VoidFunction;
  onCancel: VoidFunction;
}

export function SellReminderForm(props: SellReminderFormProps) {
  const [selected, setSelected] = useState<string | null>(INTERESTED_OPTION);
  const formatCurrency = useFormatCurrency();

  const {compareOffers, isComparisonLoading} = useInsuranceComparison(
    props.businessCaseId,
    props.insuranceData
  );

  const onSubmit = async () => {
    props.onDone();
    if (selected === INTERESTED_OPTION) {
      await compareOffers();
    }
  };

  return (
    <HStack spacing={6} justify="stretch" align="stretch">
      <Box padding={6} backgroundColor="palettes.neutral.20.100">
        <Image width={58} src="assets/images/insurance_graph.png" />
      </Box>
      <VStack spacing={6}>
        <Heading size={3}>{i18n.t('entity.insurance.labels.doesCustomerHaveInsurance')}</Heading>
        <Text size="small">{i18n.t('entity.insurance.labels.sellerReminderDescription')}</Text>

        <Alert
          type="banner"
          variant="warning"
          title={i18n.t('entity.insurance.labels.sellInsuranceReminder', {
            amount: formatCurrency(props.insuranceData?.insuranceOffer?.commissionFull ?? 0, 'CZK'),
          })}
        />

        <Radio direction="column" value={selected} options={options} onChange={setSelected} />
        <Actions
          align="right"
          actions={[
            {
              type: 'button',
              title: i18n.t('general.actions.cancel'),
              onClick: props.onCancel,
              variant: 'secondary',
              isDisabled: isComparisonLoading,
            },
            {
              type: 'button',
              title: i18n.t('general.actions.confirm'),
              isDisabled: isNil(selected),
              onClick: onSubmit,
              isLoading: isComparisonLoading,
            },
          ]}
        />
      </VStack>
    </HStack>
  );
}

const INTERESTED_OPTION = 'interested';

const options = [
  {label: i18n.t('entity.insurance.actions.offerInsurance'), value: INTERESTED_OPTION},
  {label: i18n.t('entity.insurance.labels.alreadyInsured'), value: 'insured'},
  {label: i18n.t('entity.insurance.labels.notInterested'), value: 'notInterested'},
];
