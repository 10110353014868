import {
  GetLocalActionNoteRequest,
  GetLocalActionNoteResponse,
} from '../types/metadaVehicleIntegrations';
import {metadaApi} from './baseApi/metadaApi';

export const metadaVehicleIntegrations = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getVehicleLocalActionNote: build.query<GetLocalActionNoteResponse, GetLocalActionNoteRequest>({
      query: (queryArg) => ({
        url: `/service/v1/vehicle/${queryArg.vehicleId}/integration/local-action-note`,
      }),
    }),
  }),
});

export const {useGetVehicleLocalActionNoteQuery} = metadaVehicleIntegrations;
