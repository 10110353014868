import {IconButton, useTranslationContext} from 'platform/components';
import {Stack} from 'platform/foundation';
import styled from 'styled-components';

import {ToolPanelHeaderRendererProps} from '../types/ToolPanelHeaderRenderer';
import {ToolpanelType} from '../types/ToolpanelType';

export function ToolPanelHeaderRenderer({
  toolPanelType,
  closeToolpanel,
}: ToolPanelHeaderRendererProps) {
  const t = useTranslationContext();
  let title;
  switch (toolPanelType) {
    case ToolpanelType.settings:
      title = t('page.datagrid.labels.settings');
      break;
    case ToolpanelType.filter:
      title = t('page.datagrid.labels.filters');
      break;
    case ToolpanelType.column:
      title = t('page.datagrid.labels.columnSetting');
      break;
    default:
      title = toolPanelType;
      break;
  }

  return (
    <ToolPanelHeaderWrapper>
      <span>{title}</span>
      <Stack spacing={0} direction="column">
        <IconButton
          size="small"
          icon="navigation/close"
          onClick={closeToolpanel}
          data-testid={`button-${toolPanelType}.close`}
        />
      </Stack>
    </ToolPanelHeaderWrapper>
  );
}

const ToolPanelHeaderWrapper = styled.div`
  display: flex;
  height: 57px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;
