import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {MutationLifecycleApi} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {BaseQueryFn} from '@reduxjs/toolkit/query';

import {BaseQueryExtraOptions} from 'shared';

import {
  SourcingVehicleListResponseBody,
  CreateUserVehicleSummaryRequestBody,
} from '../../types/sourcing';
import {FetchBaseQueryArgs} from '../baseQuery/utils/FetchBaseQueryArgs';

export type OmneticMutationLifecycleApi<T> = MutationLifecycleApi<
  T,
  BaseQueryFn<FetchBaseQueryArgs, unknown, FetchBaseQueryError, BaseQueryExtraOptions>,
  unknown,
  'rtk_omneticApi'
>;

export type List = 'favourite' | 'comparison' | 'inCart' | 'hidden';

const LIST_QUERY_NAMES = [
  'sourcingVehicleList',
  'sourcingListUserFavourite',
  'sourcingListUserToBuy',
  'sourcingListUserHidden',
  'sourcingListUserComparison',
] as const;

export function isSourcingListEndpointName(
  endpointName: string
): endpointName is (typeof LIST_QUERY_NAMES)[number] {
  return LIST_QUERY_NAMES.includes(endpointName as any);
}

export function setUserVehicleSummary(
  vehicleId: string,
  body: CreateUserVehicleSummaryRequestBody,
  response: SourcingVehicleListResponseBody
) {
  response.data.forEach((vehicle) => {
    if (vehicle.sourcingVehicle.adId === vehicleId) {
      vehicle.vehicleSummary = {
        comment: body.comment,
        purchasePrice: body.purchasePrice,
        sellingPrice: body.sellingPrice,
      };
    }
  });
}
