import {showNotification} from 'platform/components';

import {
  useCarkPostInsuranceDraftMutation,
  useOfferBusinessCaseInsuranceMutation,
} from '@omnetic-dms/api';
import {handleApiError} from '@omnetic-dms/shared';

import {isInsuranceVehicleV2} from '../utils/isInsuranceVehicleV2';
import {InsuranceData} from './useInsuranceData';

export function useInsuranceComparison(
  businessCaseId: string,
  {insurancePerson, insuranceVehicle, insurerNumber, tenantInfo, vehicleId}: InsuranceData
) {
  const [loadComparisonUrl, {isLoading}] = useCarkPostInsuranceDraftMutation();
  const [setInsuranceToOffered] = useOfferBusinessCaseInsuranceMutation();

  const compareOffers = async () => {
    if (!insurerNumber || !vehicleId || !isInsuranceVehicleV2(insuranceVehicle)) {
      return showNotification.error();
    }

    await loadComparisonUrl({
      body: {
        insurerNumber,
        tenantId: tenantInfo.id,
        insuranceDraftId: vehicleId,
        vehicle: insuranceVehicle,
        person: insurancePerson,
      },
    })
      .unwrap()
      .then(async (data) => {
        data?.url && window.open(data.url, '_blank');
        if (businessCaseId) {
          await setInsuranceToOffered({businessCaseId});
        }
      })
      .catch(handleApiError);
  };

  return {compareOffers, isComparisonLoading: isLoading};
}
