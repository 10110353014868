import {
  GetServiceOrderAbroadSalesApiArg,
  GetServiceOrderAbroadSalesApiArgSchema,
  GetServiceOrderAbroadSalesApiResponse,
  GetServiceOrderAbroadSalesApiResponseSchema,
  PutServiceOrderAbroadSalesApiArg,
  PutServiceOrderAbroadSalesApiArgSchema,
  PutServiceOrderAbroadSalesApiResponse,
  PutServiceOrderAbroadSalesApiResponseSchema,
} from '../types/metadaWorkshopAbroadSales';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopAbroadSalesApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceOrderAbroadSales: build.query<
      GetServiceOrderAbroadSalesApiResponse,
      GetServiceOrderAbroadSalesApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/abroad-sales`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'abroadSale', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: GetServiceOrderAbroadSalesApiResponseSchema,
        requestSchema: GetServiceOrderAbroadSalesApiArgSchema,
      },
    }),
    putServiceOrderAbroadSales: build.mutation<
      PutServiceOrderAbroadSalesApiResponse,
      PutServiceOrderAbroadSalesApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/abroad-sales`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'abroadSale', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PutServiceOrderAbroadSalesApiResponseSchema,
        requestSchema: PutServiceOrderAbroadSalesApiArgSchema,
      },
    }),
  }),
});

export const {useGetServiceOrderAbroadSalesQuery, usePutServiceOrderAbroadSalesMutation} =
  metadaWorkshopAbroadSalesApi;
