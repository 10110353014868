import {GetWatermarkDetailResponseBody, WatermarkResponseBody} from '@omnetic-dms/api';

import {WatermarkFormType, WatermarkFormTypeOld} from '../types/watermarkForm';

export const getDefaultValue = (
  data?: GetWatermarkDetailResponseBody
): Partial<WatermarkFormType> => ({
  name: data?.name ?? '',
  size: data?.size ?? 100,
  position: data?.position ?? 'center_center',
  fitToImage: data?.fitToImage ?? false,
  addOnlyForFirstPhoto: data?.addOnlyForFirstPhoto ? 'true' : 'false',
  fileId:
    data?.fileId && data?.imageUri
      ? {
          id: data.fileId,
          url: data.imageUri,
        }
      : undefined,
});

// TODO - delete component after featureFlag "settings_watermarks" is removed
export const getDefaultOldValue = (
  data?: WatermarkResponseBody
): Partial<WatermarkFormTypeOld> => ({
  size: data?.size ?? 100,
  position: data?.position ?? 'center_center',
  fitToImage: data?.fitToImage ? ['fitToImage'] : [],
  addOnlyForFirstPhoto: data?.addOnlyForFirstPhoto ? 'true' : 'false',
  fileId:
    data?.fileId && data?.imageUri
      ? {
          id: data.fileId,
          url: data.imageUri,
        }
      : undefined,
});
