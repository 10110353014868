import {Option} from 'platform/components';

import {defaultTo, isNil} from 'ramda';

import {BillingInformationResponseBody} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getOptionsFromBillingInformations = (
  billingInformations: BillingInformationResponseBody[] | Nullish
): Option[] => {
  if (isNil(billingInformations)) {
    return [];
  }

  return billingInformations.map((billingInformation) => ({
    label: defaultTo('', billingInformation.billingName),
    value: billingInformation.id,
  }));
};
