import {Button, TextInput, closeCurrentDialog, openDialog} from 'platform/components';
import {HStack, Hide, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isNotNil} from 'ramda';
import {isFalse, isNotNilOrEmpty} from 'ramda-adjunct';

import {useLazyGetTreeFolderPathQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, RequiredTestIdProps} from 'shared';

import {ActiveFolder} from '../types/ActiveFolder';
import {SelectFolder} from './SelectFolder';

interface TreeFolderPathProps extends RequiredTestIdProps {
  leafId?: string | null;
  isEditMode?: boolean;
  isRequired?: boolean;
  onChange?: (activeFolder: ActiveFolder) => void;
  invalidMessage?: string;
}

export function TreeFolderPath(props: TreeFolderPathProps) {
  const [pathName, setPathName] = useState('');
  const [getTreeFolderPath] = useLazyGetTreeFolderPathQuery();

  useEffect(() => {
    if (isNotNil(props.leafId)) {
      getTreeFolderPath({leafId: props.leafId ?? ''})
        .unwrap()
        .then((data) => setPathName(data.treeFolder?.pathName ?? ''));
    } else {
      setPathName('');
    }
  }, [props.leafId]);

  const handleSelect = (activeFolder?: ActiveFolder) => {
    props.onChange?.(activeFolder);
    closeCurrentDialog();
  };

  const handleSelectFolder = () => {
    openDialog(
      <SelectFolder
        onSelect={handleSelect}
        onClose={closeCurrentDialog}
        data-testid={suffixTestId('articleCreateMoveToFolder', props)}
      />,
      {
        size: 'small',
        title: i18n.t('entity.addWork.labels.moveToFolder'),
      }
    );
  };

  return (
    <VStack spacing={2}>
      <TextInput
        isRequired={props.isRequired}
        isInvalid={isNotNilOrEmpty(props.invalidMessage)}
        errorMessage={props.invalidMessage}
        value={pathName}
        isDisabled
        label={i18n.t('general.labels.category')}
        data-testid={suffixTestId('treeFolderPathInput', props)}
      />
      <Hide when={isFalse(props.isEditMode)}>
        <HStack>
          <Button
            variant="link"
            size="small"
            leftIcon="image/edit"
            onClick={handleSelectFolder}
            title={i18n.t('general.labels.editCategory')}
            data-testid={suffixTestId('editCategory', props)}
          />
        </HStack>
      </Hide>
    </VStack>
  );
}
