import {CheckboxTreeOptionType} from 'platform/components';

import {sum} from 'ramda';

export const getFeaturesPickerOptionsCount = (items: CheckboxTreeOptionType[]): number =>
  sum(
    items.map((item) =>
      item.options?.length ? getFeaturesPickerOptionsCount(item.options) + 1 : 1
    )
  );
