import {yupResolver} from '@hookform/resolvers/yup';
import {HStack, Icon, Right, Space} from 'platform/foundation';

import {FC, useRef, useEffect, useState, PropsWithChildren, ReactElement, ChangeEvent} from 'react';
import {SubmitHandler, Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  Button,
  TextField,
  selectIntegrationsSettings,
  getCebiaAutotraceAndReport,
  patchCebiaAutotraceAndReport,
  CebiaAutotracerAndReportCredentials,
} from '@omnetic-dms/teas';

import {WrapperCard} from './styles';
import {cebiaAuthValidations} from './validations';

export const CebiaAuthentication: FC<PropsWithChildren<any>> = (): ReactElement => {
  const dispatch = useDispatch();
  const [showPassword, setViewPassword] = useState(false);
  const integrationsSettings = useSelector(selectIntegrationsSettings);
  const form = useForm<CebiaAutotracerAndReportCredentials>({
    resolver: yupResolver(cebiaAuthValidations()),
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const {
    formState,
    reset,
    control,
    formState: {errors},
  } = form;

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const {hash} = window.location;

    if (hash === '#cebia-login') {
      wrapperRef?.current?.scrollIntoView();
      (wrapperRef?.current?.querySelector('input[name="username"]') as HTMLInputElement).focus();
    }

    dispatch(getCebiaAutotraceAndReport());
  }, [dispatch]);

  useEffect(() => {
    reset({
      username: integrationsSettings.cebiaAutotraceAndReport?.credentials?.username ?? '',
      password: '',
    });
  }, [integrationsSettings.cebiaAutotraceAndReport]);

  const onValid: SubmitHandler<CebiaAutotracerAndReportCredentials> = (credentials) => {
    dispatch(patchCebiaAutotraceAndReport({credentials}));
  };

  const onSubmit = () => {
    form.handleSubmit(onValid)();
  };

  return (
    <WrapperCard
      ref={wrapperRef}
      id="cebia-authentication"
      title={i18n.t('general.labels.accessDataAuth')}
    >
      <HStack spacing={4}>
        <Controller
          name="username"
          control={control}
          render={({field: {onChange, value, ...rest}}) => (
            <TextField
              {...rest}
              type="text"
              value={value ?? ''}
              label={i18n.t('entity.user.labels.userName')}
              error={Boolean(errors.username?.message)}
              helperText={errors.username?.message}
              onChange={(e) => onChange(removeSpace(e))}
              data-testid="cebia-autotracer-auth-form-field-username"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({field: {onChange, value, ...rest}}) => (
            <TextField
              {...rest}
              type={showPassword || !formState.isDirty ? 'text' : 'password'}
              label={i18n.t('general.labels.password')}
              placeholder="•••••"
              value={value ?? ''}
              error={Boolean(errors.password?.message)}
              helperText={errors.password?.message}
              endAdornment={{
                variant: 'button',
                disableFocusToInput: true,
                Icon: showPassword ? (
                  <Icon value="action/visibility" />
                ) : (
                  <Icon value="action/visibility_off" />
                ),
                onClick: () => setViewPassword(!showPassword),
              }}
              onChange={(e) => onChange(removeSpace(e))}
              data-testid="cebia-autotracer-auth-form-field-password"
            />
          )}
        />
      </HStack>
      <Space vertical={4} />
      <Right>
        <Button
          primary
          disabled={!formState.isValid}
          onClick={onSubmit}
          data-testid={testIds.settings.vehicleServicesCebia('authSaveButton')}
        >
          {i18n.t('general.actions.save')}
        </Button>
      </Right>
    </WrapperCard>
  );
};

const removeSpace = (e: ChangeEvent<HTMLInputElement>) => e.target.value.replace(/\s+/g, '');
