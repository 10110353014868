import {forwardRef, Ref} from 'react';

import {ILoadingCellRendererParams} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {LoadingCellRenderer} from '../types/LoadingCellRenderer';

export interface LoadingCellRendererWrapperProps extends ILoadingCellRendererParams {
  component: LoadingCellRenderer;
  gridProps: DataGridProps;
}

export const LoadingCellRendererWrapper = forwardRef<
  HTMLDivElement,
  LoadingCellRendererWrapperProps
>(LoadingCellRendererWrapperComponent);
function LoadingCellRendererWrapperComponent(
  {gridProps, component: LoadingRenderer}: LoadingCellRendererWrapperProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <div ref={ref}>
      <LoadingRenderer gridProps={gridProps} />
    </div>
  );
}
