import {Option} from 'platform/components';

import {defaultTo, isNil} from 'ramda';

import {BranchListResponseBody} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getOptionsFromBranches = (branches: BranchListResponseBody | Nullish): Option[] => {
  if (isNil(branches)) {
    return [];
  }

  return branches.branchListItems.map((branch) => ({
    label: defaultTo('', branch.marketingName),
    value: branch.id,
  }));
};
