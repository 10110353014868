import {isNil} from 'ramda';

import {Nullish} from '../types/Nullish';

/**
 * Function: isNilOrZero
 *
 * This function checks whether the provided input 'x' is either null, undefined, or zero.
 * It leverages the 'isNil' function from the 'ramda' library to check for null or undefined values.
 *
 * Parameters:
 * x: The input which can be of type 'number', 'undefined', or 'null'.
 *
 * Returns:
 * This function returns 'true' if 'x' is null, undefined, or zero, and 'false' otherwise.
 */
export function isNilOrZero(x: number | Nullish): x is 0 | Nullish {
  return isNil(x) || x === 0;
}
