import styled from 'styled-components';
import styledMap from 'styled-map';

import {FormLabel} from '../Field/components/FormLabel';
import {InputPropsTypes} from './types';

const cursor = styledMap`
	default: pointer;
	disabled: default;
`;

export const Label = styled(FormLabel)<{hasLabel?: boolean}>`
  padding-left: ${({hasLabel}) => (hasLabel ? '22px' : '14px')};
  cursor: ${cursor};
  position: relative;
  text-transform: none;
  margin-bottom: 0;
  font-weight: 400;
  text-align: left;
  display: inline-flex;
  font-size: ${({theme}) => theme.fontSizes.text.small};
  height: 18px;
  white-space: nowrap;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 12px;
    height: 12px;
    border: 1px solid ${({theme}) => theme.colors.palettes.neutral[200][100]};
    background: ${({theme}) => theme.colors.palettes.white[10][100]};
    border-radius: ${({theme}) => theme.radii.small};
    background-size: 10px;
  }

  &:hover::after {
    box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[20][100]};
  }
`;

export const Input = styled.input<InputPropsTypes>`
  height: 0;
  width: 0;
  opacity: 0;
  position: absolute;

  &:checked {
    + ${Label} {
      &::after {
        color: white;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        content: '✔';
        background: ${({isAllRowsSelected}) =>
          isAllRowsSelected
            ? `url('/static/images/icons/half-check.svg') no-repeat center`
            : `url('/static/images/icons/check.svg') no-repeat center`};
        background-color: ${({theme}) => theme.colors.palettes.blue[60][100]};
        background-size: 8px;
        border-color: ${({theme}) => theme.colors.palettes.blue[60][100]};
      }
    }
  }
`;

export const Wrapper = styled.div<{margin?: string; disabled?: boolean}>`
  width: 100%;
  height: 20px;
  line-height: 20px;
  position: relative;
  ${({disabled}) => disabled && `pointer-events: none;`}
  margin: ${({margin}) => margin || 0};
`;
