import {
  GetLabourCatalogApiArg,
  GetLabourCatalogApiResponse,
  PatchLabourCatalogCalculationPriceApiArg,
  PatchLabourCatalogCalculationPriceApiResponse,
  PatchLabourCatalogCooperationApiArg,
  PatchLabourCatalogCooperationApiResponse,
  PatchLabourCatalogDirectPriceApiArg,
  PatchLabourCatalogDirectPriceApiResponse,
  PatchLabourCatalogTimeNormApiArg,
  PatchLabourCatalogTimeNormApiResponse,
  PostLabourCatalogCalculationPriceApiArg,
  PostLabourCatalogCalculationPriceApiResponse,
  PostLabourCatalogCooperationApiArg,
  PostLabourCatalogCooperationApiResponse,
  PostLabourCatalogDirectPriceApiArg,
  PostLabourCatalogDirectPriceApiResponse,
  PostLabourCatalogTimeNormApiArg,
  PostLabourCatalogTimeNormApiResponse,
  GetLabourCatalogCategoryApiArg,
  GetLabourCatalogCategoryApiResponse,
  PatchLabourCatalogCategoryApiArg,
  PatchLabourCatalogCategoryApiResponse,
  PostLabourCatalogCategoryApiArg,
  PostLabourCatalogCategoryApiResponse,
  GetLabourCatalogItemByCatalogNumberApiArg,
  GetLabourCatalogItemByCatalogNumberApiResponse,
  GetLabourCatalogItemApiArg,
  GetLabourCatalogItemApiResponse,
  DeleteLabourCatalogCategoryApiResponse,
  DeleteLabourCatalogCategoryApiArg,
  DeleteLabourCatalogItemsApiArg,
  DeleteLabourCatalogItemsApiResponse,
  PostLabourCatalogCategorySetAsActiveApiResponse,
  PostLabourCatalogCategorySetAsActiveApiArg,
  PostLabourCatalogCategorySetAsInactiveApiResponse,
  PostLabourCatalogCategorySetAsInactiveApiArg,
  PostLabourCatalogSubcontractApiResponse,
  PostLabourCatalogSubcontractApiArg,
  PatchLabourCatalogSubcontractApiResponse,
  PatchLabourCatalogSubcontractApiArg,
  PostLabourCatalogResponseSchema,
  PostLabourCatalogCalculationPriceApiArgSchema,
  PostLabourCatalogCooperationApiArgSchema,
  PostLabourCatalogDirectPriceApiArgSchema,
  PostLabourCatalogTimeNormApiArgSchema,
  GetLabourCatalogApiResponseSchema,
  GetLabourCatalogApiArgSchema,
  DeleteLabourCatalogItemsApiResponseSchema,
  DeleteLabourCatalogItemsApiArgSchema,
  PatchLabourCatalogCalculationPriceApiArgSchema,
  PatchLabourCatalogResponseSchema,
  PatchLabourCatalogCooperationApiArgSchema,
  PatchLabourCatalogDirectPriceApiArgSchema,
  PatchLabourCatalogTimeNormApiArgSchema,
  GetLabourCatalogCategoryApiArgSchema,
  GetLabourCatalogCategoryApiResponseSchema,
  PatchLabourCatalogCategoryApiResponseSchema,
  PatchLabourCatalogCategoryApiArgSchema,
  PostLabourCatalogCategoryApiResponseSchema,
  PostLabourCatalogCategoryApiArgSchema,
  GetLabourCatalogItemByCatalogNumberApiResponseSchema,
  GetLabourCatalogItemByCatalogNumberApiArgSchema,
  GetLabourCatalogItemApiResponseSchema,
  GetLabourCatalogItemApiArgSchema,
  DeleteLabourCatalogCategoryApiResponseSchema,
  DeleteLabourCatalogCategoryApiArgSchema,
  PostLabourCatalogCategorySetAsActiveApiResponseSchema,
  PostLabourCatalogCategorySetAsActiveApiArgSchema,
  PostLabourCatalogCategorySetAsInactiveApiResponseSchema,
  PostLabourCatalogCategorySetAsInactiveApiArgSchema,
  PostLabourCatalogSubcontractApiResponseSchema,
  PostLabourCatalogSubcontractApiArgSchema,
  PatchLabourCatalogSubcontractApiResponseSchema,
  PatchLabourCatalogSubcontractApiArgSchema,
} from '../types/metadaWorkshopLabourCatalog';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopLabourCatalogApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postLabourCatalogCalculationPrice: build.mutation<
      PostLabourCatalogCalculationPriceApiResponse,
      PostLabourCatalogCalculationPriceApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/item/calculation-price`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostLabourCatalogResponseSchema,
        requestSchema: PostLabourCatalogCalculationPriceApiArgSchema,
      },
    }),
    postLabourCatalogCooperation: build.mutation<
      PostLabourCatalogCooperationApiResponse,
      PostLabourCatalogCooperationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/item/cooperation`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostLabourCatalogResponseSchema,
        requestSchema: PostLabourCatalogCooperationApiArgSchema,
      },
    }),
    postLabourCatalogDirectPrice: build.mutation<
      PostLabourCatalogDirectPriceApiResponse,
      PostLabourCatalogDirectPriceApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/item/direct-price`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostLabourCatalogResponseSchema,
        requestSchema: PostLabourCatalogDirectPriceApiArgSchema,
      },
    }),
    postLabourCatalogTimeNorm: build.mutation<
      PostLabourCatalogTimeNormApiResponse,
      PostLabourCatalogTimeNormApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/item/time-norm`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostLabourCatalogResponseSchema,
        requestSchema: PostLabourCatalogTimeNormApiArgSchema,
      },
    }),
    getLabourCatalog: build.query<GetLabourCatalogApiResponse, GetLabourCatalogApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/item/${queryArg.labourCatalogId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'labourCatalogItem', id: queryArg.labourCatalogId},
      ],
      extraOptions: {
        responseSchema: GetLabourCatalogApiResponseSchema,
        requestSchema: GetLabourCatalogApiArgSchema,
      },
    }),
    deleteLabourCatalogItems: build.mutation<
      DeleteLabourCatalogItemsApiResponse,
      DeleteLabourCatalogItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/delete-items`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) =>
        queryArg.body.labourCatalogItemIds.map((item) => ({type: 'labourCatalogItem', id: item})),
      extraOptions: {
        responseSchema: DeleteLabourCatalogItemsApiResponseSchema,
        requestSchema: DeleteLabourCatalogItemsApiArgSchema,
      },
    }),
    patchLabourCatalogCalculationPrice: build.mutation<
      PatchLabourCatalogCalculationPriceApiResponse,
      PatchLabourCatalogCalculationPriceApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/item/${queryArg.labourCatalogId}/calculation-price`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourCatalogItem', id: queryArg.labourCatalogId},
      ],
      extraOptions: {
        responseSchema: PatchLabourCatalogResponseSchema,
        requestSchema: PatchLabourCatalogCalculationPriceApiArgSchema,
      },
    }),
    patchLabourCatalogCooperation: build.mutation<
      PatchLabourCatalogCooperationApiResponse,
      PatchLabourCatalogCooperationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/item/${queryArg.labourCatalogId}/cooperation`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourCatalogItem', id: queryArg.labourCatalogId},
      ],
      extraOptions: {
        responseSchema: PatchLabourCatalogResponseSchema,
        requestSchema: PatchLabourCatalogCooperationApiArgSchema,
      },
    }),
    patchLabourCatalogDirectPrice: build.mutation<
      PatchLabourCatalogDirectPriceApiResponse,
      PatchLabourCatalogDirectPriceApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/item/${queryArg.labourCatalogId}/direct-price`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourCatalogItem', id: queryArg.labourCatalogId},
      ],
      extraOptions: {
        responseSchema: PatchLabourCatalogResponseSchema,
        requestSchema: PatchLabourCatalogDirectPriceApiArgSchema,
      },
    }),
    patchLabourCatalogTimeNorm: build.mutation<
      PatchLabourCatalogTimeNormApiResponse,
      PatchLabourCatalogTimeNormApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/item/${queryArg.labourCatalogId}/time-norm`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourCatalogItem', id: queryArg.labourCatalogId},
      ],
      extraOptions: {
        responseSchema: PatchLabourCatalogResponseSchema,
        requestSchema: PatchLabourCatalogTimeNormApiArgSchema,
      },
    }),
    getLabourCatalogCategory: build.query<
      GetLabourCatalogCategoryApiResponse,
      GetLabourCatalogCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/${queryArg.labourCatalogId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'labourCatalogCategory', id: queryArg.labourCatalogId},
      ],
      extraOptions: {
        responseSchema: GetLabourCatalogCategoryApiResponseSchema,
        requestSchema: GetLabourCatalogCategoryApiArgSchema,
      },
    }),
    patchLabourCatalogCategory: build.mutation<
      PatchLabourCatalogCategoryApiResponse,
      PatchLabourCatalogCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/${queryArg.labourCatalogId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourCatalogCategory', id: queryArg.labourCatalogId},
      ],
      extraOptions: {
        responseSchema: PatchLabourCatalogCategoryApiResponseSchema,
        requestSchema: PatchLabourCatalogCategoryApiArgSchema,
      },
    }),
    postLabourCatalogCategory: build.mutation<
      PostLabourCatalogCategoryApiResponse,
      PostLabourCatalogCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostLabourCatalogCategoryApiResponseSchema,
        requestSchema: PostLabourCatalogCategoryApiArgSchema,
      },
    }),
    getLabourCatalogItemByCatalogNumber: build.query<
      GetLabourCatalogItemByCatalogNumberApiResponse,
      GetLabourCatalogItemByCatalogNumberApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/labour-catalog/${queryArg.labourCatalogId}/item`,
        params: {catalogNumber: queryArg.catalogNumber},
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetLabourCatalogItemByCatalogNumberApiResponseSchema,
        requestSchema: GetLabourCatalogItemByCatalogNumberApiArgSchema,
      },
    }),
    getLabourCatalogItem: build.query<GetLabourCatalogItemApiResponse, GetLabourCatalogItemApiArg>({
      query: (queryArg) => ({
        url: `service/v1/labour-catalog/item/${queryArg.labourCatalogItemId}`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetLabourCatalogItemApiResponseSchema,
        requestSchema: GetLabourCatalogItemApiArgSchema,
      },
    }),
    deleteLabourCatalogCategory: build.mutation<
      DeleteLabourCatalogCategoryApiResponse,
      DeleteLabourCatalogCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/labour-catalog/${queryArg.labourCatalogCategoryId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourCatalogCategory', id: queryArg.labourCatalogCategoryId},
      ],
      extraOptions: {
        responseSchema: DeleteLabourCatalogCategoryApiResponseSchema,
        requestSchema: DeleteLabourCatalogCategoryApiArgSchema,
      },
    }),
    postLabourCatalogCategorySetAsActive: build.mutation<
      PostLabourCatalogCategorySetAsActiveApiResponse,
      PostLabourCatalogCategorySetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/${queryArg.labourCatalogId}/set-as-active`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourCatalogCategory', id: queryArg.labourCatalogId},
      ],
      extraOptions: {
        responseSchema: PostLabourCatalogCategorySetAsActiveApiResponseSchema,
        requestSchema: PostLabourCatalogCategorySetAsActiveApiArgSchema,
      },
    }),
    postLabourCatalogCategorySetAsInactive: build.mutation<
      PostLabourCatalogCategorySetAsInactiveApiResponse,
      PostLabourCatalogCategorySetAsInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/${queryArg.labourCatalogId}/set-as-inactive`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourCatalogCategory', id: queryArg.labourCatalogId},
      ],
      extraOptions: {
        responseSchema: PostLabourCatalogCategorySetAsInactiveApiResponseSchema,
        requestSchema: PostLabourCatalogCategorySetAsInactiveApiArgSchema,
      },
    }),
    postLabourCatalogSubcontract: build.mutation<
      PostLabourCatalogSubcontractApiResponse,
      PostLabourCatalogSubcontractApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/item/subcontract`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostLabourCatalogSubcontractApiResponseSchema,
        requestSchema: PostLabourCatalogSubcontractApiArgSchema,
      },
    }),
    patchLabourCatalogSubcontract: build.mutation<
      PatchLabourCatalogSubcontractApiResponse,
      PatchLabourCatalogSubcontractApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-catalog/item/${queryArg.labourCatalogItemId}/subcontract`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourCatalogItem', id: queryArg.labourCatalogItemId},
      ],
      extraOptions: {
        responseSchema: PatchLabourCatalogSubcontractApiResponseSchema,
        requestSchema: PatchLabourCatalogSubcontractApiArgSchema,
      },
    }),
  }),
});

export const {
  usePostLabourCatalogCalculationPriceMutation,
  usePostLabourCatalogCooperationMutation,
  usePostLabourCatalogDirectPriceMutation,
  usePostLabourCatalogTimeNormMutation,
  useGetLabourCatalogQuery,
  usePatchLabourCatalogCalculationPriceMutation,
  usePatchLabourCatalogCooperationMutation,
  usePatchLabourCatalogDirectPriceMutation,
  usePatchLabourCatalogTimeNormMutation,
  useGetLabourCatalogCategoryQuery,
  usePostLabourCatalogCategoryMutation,
  usePatchLabourCatalogCategoryMutation,
  useLazyGetLabourCatalogItemByCatalogNumberQuery,
  useGetLabourCatalogItemQuery,
  useDeleteLabourCatalogCategoryMutation,
  usePostLabourCatalogCategorySetAsActiveMutation,
  usePostLabourCatalogCategorySetAsInactiveMutation,
  useDeleteLabourCatalogItemsMutation,
  usePostLabourCatalogSubcontractMutation,
  usePatchLabourCatalogSubcontractMutation,
} = metadaWorkshopLabourCatalogApi;
