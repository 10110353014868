import {useCallback} from 'react';

export const useHandleLink = () =>
  useCallback((link: string, event: MouseEvent) => {
    const anchor = document.createElement('a');
    anchor.href = link;

    if (event.ctrlKey) {
      anchor.target = '_blank';
    }

    if (event.shiftKey) {
      anchor.target = '_top';
    }

    anchor.click();
  }, []);
