import {z} from 'zod';

const PartsListSchema = z.object({
  partId: z.string().nullable().optional(),
  partNumber: z.string().nullable().optional(),
  es1: z.string().nullable().optional(),
  es2: z.string().nullable().optional(),
  partName: z.string().nullable().optional(),
  quantity: z.number().nullable().optional(),
  xhpiUrl: z.string().nullable().optional(),
  origin: z.string().nullable().optional(),
});

const PartsGroupSchema = z.object({
  groupId: z.string().nullable().optional(),
  identNumber: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  xhpiUrl: z.string().nullable().optional(),
  partslist: z.array(PartsListSchema.nullable()).optional(),
});

const XhpiInquirySchema = z.object({
  jobId: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  xhpiUrl: z.string().nullable().optional(),
  partsGroups: z.array(PartsGroupSchema.nullable()).optional(),
});

const XhpiCartSchema = z.object({
  jobId: z.string().nullable().optional(),
  xhpiUrl: z.string().nullable().optional(),
});

export const PutItemsBasketActionApiResponseSchema = z
  .object({
    xentry_xhpi_cart: XhpiCartSchema.nullable().optional(),
    xentry_xhpi_inquiry: XhpiInquirySchema.nullable().optional(),
  })
  .nullable();

export type PutItemsBasketActionApiResponse = z.infer<typeof PutItemsBasketActionApiResponseSchema>;

const PutItemsBasketActionBodySchema = z
  .object({
    actionKey: z.enum(['xentry-xhpi-cart', 'xentry-xhpi-inquiry']),
  })
  .nullable();

export const PutItemsBasketActionApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  basketType: z.enum(['material-basket', 'work-basket']),
  body: PutItemsBasketActionBodySchema,
});

export type PutItemsBasketActionApiArg = z.infer<typeof PutItemsBasketActionApiArgSchema>;

const OptionsSchema = z.object({
  type: z.enum(['list-item', 'divider']).nullable().optional(),
  key: z.string().nullable().optional(),
  style: z.enum(['hidden', 'disabled', 'enable']).nullable().optional(),
  position: z.number().nullable().optional(),
});

const ItemsBasketActionsSchema = z.object({
  optionClick: z.string().nullable().optional(),
  options: z.array(OptionsSchema.nullable()).optional(),
});

export const GetItemsBasketActionsApiResponseSchema = z
  .object({
    itemsBasketActions: ItemsBasketActionsSchema.nullable().optional(),
  })
  .nullable();

export type GetItemsBasketActionsApiResponse = z.infer<
  typeof GetItemsBasketActionsApiResponseSchema
>;

export const GetItemsBasketActionsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  basketType: z.enum(['material-basket', 'work-basket']),
});

export type GetItemsBasketActionsApiArg = z.infer<typeof GetItemsBasketActionsApiArgSchema>;
