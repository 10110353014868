import {Textarea, Card, Label} from 'platform/components';
import {Grid, Heading, GridItem, VStack, Show, Space} from 'platform/foundation';

import {FC, useMemo, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {selectAudit} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditDamageValueBody} from '../../../types/ConditionTypes';
import {useConditionContext} from '../hooks/useConditionContext';
import {useConditionDamagesContext} from '../hooks/useConditionDamagesContext';
import {AuditParamType} from '../types/AuditParamType';
import {getAuditFieldData} from '../utils/getAuditFieldData';
import {getDamageTypeLabel} from '../utils/getDamageTypeLabel';
import {getFormFieldName} from '../utils/getFormFieldName';
import {getParamFromCategory} from '../utils/getParamFromCategory';
import {getTranslation} from '../utils/getTranslation';
import {DamagePhotos} from './DamagePhotos';

type DamageItemOfList = {
  location: string;
  type: string;
  commentParamId: string;
  locationPhotoParamId: string;
  detailPhotoParamId: string;
  category: AuditCategoryOfStructure;
  damageId: string;
  order: number;
};

type ListOfDamagesProps = {
  category?: AuditCategoryOfStructure;
};

export const ListOfDamages: FC<ListOfDamagesProps & TestIdProps> = ({category, ...rest}) => {
  const locale = useSelector(selectUserSelectedLanguage);
  const auditData = useSelector(selectAudit);
  const {onEditDamage, setLastOrderNumber} = useConditionDamagesContext();
  const {isDisabledForUser} = useConditionContext();

  const getDamagesFromCategories = (
    listOfDamages: DamageItemOfList[],
    lastOrderNumber: number,
    categories?: AuditCategoryOfStructure[]
  ): {
    listOfDamages: DamageItemOfList[];
    lastOrderNumber: number;
  } => {
    categories?.forEach((category) => {
      if (!category.visible) {
        return;
      }

      if (category.childCategories) {
        ({listOfDamages, lastOrderNumber} = getDamagesFromCategories(
          listOfDamages,
          lastOrderNumber,
          category.childCategories
        ));
      }

      const damageTypeParam = getParamFromCategory(category, AuditParamType.SELECTABLE_BUTTON);
      const damageCommentParam = getParamFromCategory(category, AuditParamType.ENHANCED_COMMENT);
      const [locationPhotoParam, detailPhotoParam] =
        category?.paramDefinitions?.relatedActions?.filter(
          (param) => param.type === AuditParamType.PHOTO
        ) ?? [];
      const fieldValue = getAuditFieldData(
        category.id,
        damageTypeParam?.id,
        auditData ?? undefined
      );

      if (!Boolean(fieldValue) || !damageTypeParam || !damageCommentParam || !locationPhotoParam) {
        return;
      }

      const location = getTranslation(locale, category.name);

      if (Array.isArray(fieldValue)) {
        (fieldValue as AuditDamageValueBody[]).forEach(({damageId, order, type}) => {
          listOfDamages.push({
            category,
            location,
            damageId,
            order,
            commentParamId: damageCommentParam.id,
            locationPhotoParamId: locationPhotoParam.id,
            detailPhotoParamId: detailPhotoParam.id,
            type: getDamageTypeLabel(type, damageTypeParam.values, locale),
          });

          if (order > lastOrderNumber) {
            lastOrderNumber = order;
          }
        });
      }
    });

    return {
      listOfDamages,
      lastOrderNumber,
    };
  };

  const listOfDamages = useMemo((): DamageItemOfList[] => {
    const {listOfDamages, lastOrderNumber} = getDamagesFromCategories(
      [],
      0,
      category?.childCategories ?? undefined
    );

    setLastOrderNumber(lastOrderNumber);

    return listOfDamages.sort((a, b) => b.order - a.order);
  }, [auditData, locale]);

  if (!listOfDamages.length) {
    return null;
  }

  return (
    <VStack spacing={4}>
      <Heading size={3}>{i18n.t('entity.condition.labels.listOfDamages')}</Heading>
      <VStack spacing={3}>
        {listOfDamages.map((damage, index) => {
          const noteFieldName = getFormFieldName(damage.category.id, damage.commentParamId);

          return (
            <VStack
              key={`list-of-damage-item-${damage.damageId}`}
              data-testid={suffixTestId(`listOfDamages-[${index}]`, rest)}
              spacing={3}
            >
              <Card
                variant="inlineGrey"
                title={damage.type}
                actions={[
                  {
                    leftIcon: 'image/edit',
                    type: 'button',
                    variant: 'ghostLink',
                    onClick: () => onEditDamage(damage.category, damage.damageId),
                    isDisabled: isDisabledForUser,
                    title: i18n.t('general.actions.edit'),
                    'data-testid': suffixTestId(
                      `listOfDamages-${damage.damageId}-editAction`,
                      rest
                    ),
                  },
                ]}
                data-testid={suffixTestId(`listOfDamages-${damage.damageId}`, rest)}
              >
                <Label>{i18n.t('general.labels.location')}</Label>
                <Heading size={5}>{damage.location}</Heading>
                <Space vertical={3} />
                <Show when={isNotNil(noteFieldName)}>
                  <Grid columns={2}>
                    <GridItem span={1}>
                      <DamageNoteFormField
                        name={noteFieldName!}
                        damageId={damage.damageId}
                        order={damage.order}
                        data-testid={suffixTestId(`listOfDamages-${damage.damageId}-note`, rest)}
                      />
                    </GridItem>
                  </Grid>
                </Show>

                <Space vertical={3} />

                <DamagePhotos
                  categoryId={damage.category.id}
                  damageId={damage.damageId}
                  columns={8}
                  locationPhotoParamId={damage.locationPhotoParamId}
                  detailPhotoParamId={damage.detailPhotoParamId}
                  data-testid={suffixTestId(`listOfDamages-${damage.damageId}-photos`, rest)}
                />
              </Card>
            </VStack>
          );
        })}
      </VStack>
    </VStack>
  );
};

let timeout: NodeJS.Timeout | undefined;

type DamageNoteFormFieldProps = {
  damageId: string;
  name: string;
  order: number;
};

const DamageNoteFormField: FC<DamageNoteFormFieldProps & TestIdProps> = ({
  damageId,
  name,
  order,
  ...rest
}) => {
  const {
    formRenderProps: {Field: FormField},
  } = useConditionContext();

  return (
    <FormField
      name={name}
      data-testid={suffixTestId('damageNoteForm', rest)}
      component={({input}) => {
        const inputValue = input.value as AuditDamageValueBody[];
        if (!Array.isArray(inputValue)) {
          return null;
        }
        const value = inputValue.find((value) => value.damageId === damageId);

        return (
          <>
            <Label>{i18n.t('general.labels.note')}</Label>
            <DamageNoteTextarea
              value={value?.type ?? ''}
              name={name}
              order={order}
              damageId={damageId}
              data-testid={rest['data-testid']}
            />
          </>
        );
      }}
    />
  );
};

type DamageNoteTextareaProps = DamageNoteFormFieldProps & {
  value: string;
};
const DamageNoteTextarea: FC<DamageNoteTextareaProps & TestIdProps> = ({
  value,
  name,
  damageId,
  order,
  ...rest
}) => {
  const [innerValue, setInnerValue] = useState<string>('');
  const {isDisabledForUser} = useConditionContext();

  const {
    formRenderProps: {
      form: {
        mutators: {setFieldValue},
        getFieldState,
      },
    },
  } = useConditionContext();

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const setDamageFieldValue = (value: string) => {
    const inputValue = [...((getFieldState(name)?.value as AuditDamageValueBody[]) ?? [])];
    const valueIndex = inputValue.findIndex((value) => value.damageId === damageId);

    if (valueIndex < 0) {
      inputValue.push({
        damageId,
        order,
        type: value,
      });
    } else {
      inputValue[valueIndex] = {
        ...inputValue[valueIndex],
        type: value,
      };
    }

    setFieldValue(name, inputValue);
  };

  return (
    <Textarea
      value={innerValue}
      minRows={1}
      onChange={(value) => {
        if (value === null) {
          return;
        }
        setInnerValue(value);

        if (timeout) {
          clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
          setDamageFieldValue(value);
        }, 500);
      }}
      isDisabled={isDisabledForUser}
      data-testid={rest['data-testid']}
    />
  );
};
