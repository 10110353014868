import {differenceInDays, format} from 'date-fns';
import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  GetInvoiceApiResponse,
  InvoiceTypes,
  useGetBalanceInvoiceQuery,
  useGetCorrectiveTaxDocumentQuery,
  useGetDocumentSeriesDefinitionListQuery,
  useGetInvoiceProformaQuery,
  useGetInvoiceQuery,
  useGetTaxDocumentForPaymentQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {LiteralUnionAutocomplete, Nullish, parseDate} from 'shared';

import {getDocumentSystemSeriesId} from '../utils/getDocumentSystemSeriesId';

export const useGetDocumentData = (invoiceType: string | Nullish, id: string | Nullish) => {
  const isParametersError = isNil(id) || isNil(invoiceType);
  const getSkipArg = (type: LiteralUnionAutocomplete<InvoiceTypes>[]) =>
    isParametersError || (!type?.includes(invoiceType) && isNotNil(type));

  const {
    data: invoiceData,
    isLoading: isLoadingInvoice,
    isError: isInvoiceError,
  } = useGetInvoiceQuery({invoiceId: id!}, {skip: getSkipArg(['ISSUED'])});
  const {
    data: balanceInvoiceData,
    isLoading: isLoadingBalanceInvoice,
    isError: isBalanceInvoiceError,
  } = useGetBalanceInvoiceQuery({balanceInvoiceId: id!}, {skip: getSkipArg(['BALANCE_INVOICE'])});
  const {
    data: proformaInvoiceData,
    isLoading: isLoadingProformaInvoice,
    isError: isProformaInvoiceError,
  } = useGetInvoiceProformaQuery({invoiceProformaId: id!}, {skip: getSkipArg(['PROFORMA'])});

  const {
    data: correctiveTaxDocument,
    isLoading: isLoadingCorrectiveTaxDocument,
    isError: isCorrectiveTaxDocumentError,
  } = useGetCorrectiveTaxDocumentQuery(
    {correctiveTaxDocumentId: id!},
    {skip: getSkipArg(['CORRECTIVE_TAX_DOCUMENT'])}
  );

  const {
    data: taxDocumentForPayment,
    isLoading: isLoadingTaxDocumentForPayment,
    isError: isTaxDocumentForPaymentError,
  } = useGetTaxDocumentForPaymentQuery({id: id!}, {skip: getSkipArg(['TAX_DOCUMENT_FOR_PAYMENT'])});

  const {
    data: documentSeries,
    isLoading: isLoadingDocumentSeries,
    isError: isDocumentSeriesError,
  } = useGetDocumentSeriesDefinitionListQuery();

  const isFetchingError =
    isBalanceInvoiceError ||
    isInvoiceError ||
    isProformaInvoiceError ||
    isDocumentSeriesError ||
    isCorrectiveTaxDocumentError ||
    isTaxDocumentForPaymentError;

  const isError = isParametersError || isFetchingError;
  const isLoading =
    isLoadingInvoice ||
    isLoadingBalanceInvoice ||
    isLoadingProformaInvoice ||
    isLoadingDocumentSeries ||
    isLoadingCorrectiveTaxDocument ||
    isLoadingTaxDocumentForPayment;

  const dataByType = match(invoiceType)
    .with('ISSUED', always(invoiceData))
    .with('BALANCE_INVOICE', always(balanceInvoiceData?.invoice))
    .with('PROFORMA', always(proformaInvoiceData))
    .with('CORRECTIVE_TAX_DOCUMENT', always(correctiveTaxDocument))
    .with('TAX_DOCUMENT_FOR_PAYMENT', always(taxDocumentForPayment))
    .otherwise(always(null));

  const documentSeriesId = getDocumentSystemSeriesId(documentSeries, 'corrective_tax_document');

  const data = isNil(dataByType)
    ? null
    : {
        ...dataByType,
        customFields: (dataByType as GetInvoiceApiResponse)?.customFields ?? [],
        cancellable: (dataByType as GetInvoiceApiResponse)?.cancellable ?? false,
        paymentState: (dataByType as GetInvoiceApiResponse)?.paymentState ?? null,
        invoiceCategory: (dataByType as GetInvoiceApiResponse)?.invoiceCategory,
        dueSinceIssueDateInDays: isNotNil(dataByType)
          ? differenceInDays(parseDate(dataByType.dueDate), parseDate(dataByType.issueDate))
          : undefined,
        paymentInfo: {
          bankName: dataByType.paymentInfo.bankName ?? undefined,
          paymentMethod: dataByType.paymentInfo.paymentMethod ?? 'BANK_TRANSFER',
          bankAccount: dataByType.paymentInfo.bankAccount ?? undefined,
          iban: dataByType.paymentInfo.iban ?? undefined,
          swift: dataByType.paymentInfo.swift ?? undefined,
          constantSymbol: dataByType.paymentInfo.constantSymbol ?? undefined,
          specificSymbol: dataByType.paymentInfo.specificSymbol ?? undefined,
          variableSymbol: dataByType.paymentInfo.variableSymbol ?? undefined,
        },
        dateOfReception: format(new Date(), 'yyyy-MM-dd'),
        footerNote: (dataByType as GetInvoiceApiResponse)?.footerNote ?? '',
        documentSeriesId,
        isExchangeRateRatioEnabled: isNotNil(dataByType?.exchangeRateRatio),
        exchangeRateRatio: {
          amount: dataByType?.exchangeRateRatio?.amount.toString() ?? '1',
          currency: dataByType?.exchangeRateRatio?.currency ?? 'EUR',
          ratio: dataByType?.exchangeRateRatio?.ratio ?? null,
          type: i18n.t('general.actions.recalculate'),
        },
        additionalCustomer: hadAdditionalCustomer(dataByType)
          ? {
              customer: {
                ...dataByType.additionalCustomer,
                id: dataByType.additionalCustomer?.customerId,
              },
              type: dataByType.additionalCustomer?.type,
              contractInformationId: dataByType.additionalCustomer?.contractInformationId,
            }
          : null,
      };

  return {data, isError, isLoading} as const;
};

type ItemWithAdditionalCustomer<Item> = Item & {
  additionalCustomer: GetInvoiceApiResponse['additionalCustomer'];
};
const hadAdditionalCustomer = <T extends Record<string, unknown>>(
  val: T
): val is ItemWithAdditionalCustomer<T> =>
  isNotNil((val as ItemWithAdditionalCustomer<T>)?.additionalCustomer);
