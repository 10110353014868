import {Button, Card, DataStatus, Segment} from 'platform/components';
import {Box, HStack, Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useState} from 'react';

import {isNil, mergeAll} from 'ramda';

import {
  useGetDatagridSynchronizationServiceItemsExternalHistoryQuery,
  usePostDatagridSynchronizeServiceItemsExternalHistoryMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {EMPTY_PLACEHOLDER, Nullish, RequiredTestIdProps, parseDate, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {handleApiError} from '../../utils/handleApiError';

enum History {
  Local = 'localHistory',
  External = 'externalHistory',
}

interface WorkItemsProps extends RequiredTestIdProps {
  vehicleId: string | Nullish;
  localHistoryDatagridCode: string;
  externalHistoryDatagridCode: string;
  actionCallback?: ActionCallback;
}

export function WorkItems(props: WorkItemsProps) {
  const [history, setHistory] = useState<History>(History.Local);

  const formatDateTime = useDateTimeFormatter();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [postDatagridSynchronizeServiceItemsExternalHistory, {isLoading: isSyncRunning}] =
    usePostDatagridSynchronizeServiceItemsExternalHistoryMutation();

  const {data, isLoading, isError} = useGetDatagridSynchronizationServiceItemsExternalHistoryQuery(
    {vehicleId: props.vehicleId ?? ''},
    {skip: isNil(props.vehicleId)}
  );

  const handleSync = () =>
    postDatagridSynchronizeServiceItemsExternalHistory({
      body: {vehicleId: props.vehicleId ?? ''},
    })
      .unwrap()
      .then(refreshDataGrid)
      .catch(handleApiError);

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {vehicleId: props.vehicleId ?? null}]);

  return (
    <DataStatus
      isLoading={isNil(props.vehicleId) || isLoading}
      isError={isError}
      key="historyOfRepairs-work-items"
      minHeight={175}
    >
      <Card>
        <HStack align="center" justify="space-between">
          <Segment
            minWidth={35}
            value={history}
            onChange={(syncType) => {
              setHistory(syncType);
              if (syncType === History.External) {
                handleSync();
              }
            }}
            options={[
              {
                value: History.Local,
                label: i18n.t('entity.workItems.labels.localHistory'),
              },
              {
                value: History.External,
                label: i18n.t('entity.workItems.labels.externalHistory'),
              },
            ]}
          />
          {history === History.External && (
            <HStack align="center" spacing={2}>
              <Text size="small" color="secondary">
                {`${i18n.t('entity.workItems.labels.lastSync')}: ${data ? formatDateTime('dateTimeLong', parseDate(data?.synchronizedAt)) : EMPTY_PLACEHOLDER}`}
              </Text>

              <Button
                onClick={handleSync}
                isLoading={isSyncRunning}
                variant="ghostLink"
                size="small"
                title={i18n.t('entity.workItems.actions.synchronize')}
                leftIcon="navigation/refresh"
                data-testid={suffixTestId('syncButton', props)}
              />
            </HStack>
          )}
        </HStack>
        <Box height={175}>
          {history === 'localHistory' && (
            <DataGrid
              gridCode={props.localHistoryDatagridCode}
              queryModifier={queryModifier}
              actionCallback={props.actionCallback}
              data-testid={suffixTestId('workItems-local', props)}
            />
          )}
          {history === 'externalHistory' && (
            <DataGrid
              ref={dataGridRef}
              gridCode={props.externalHistoryDatagridCode}
              queryModifier={queryModifier}
              actionCallback={props.actionCallback}
              data-testid={suffixTestId('workItems-external', props)}
            />
          )}
        </Box>
      </Card>
    </DataStatus>
  );
}
