import {Checkbox} from 'platform/components';
import {Box, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {FC, useCallback, useEffect, useMemo, useRef} from 'react';

import {convertStringToCamelCase, isNotNilOrZero, suffixTestId, TestIdProps} from 'shared';

interface ComponentProps {
  useV2?: boolean;
  name?: string;
  nodes?: CheckBoxTreeNode[];
  selected: readonly string[];
  onSelect: (key: string, isSelected?: boolean) => void;
  margin?: number;
}

interface NodeProps {
  name?: string;
  node: CheckBoxTreeNode;
  selected: readonly string[];
  onSelect: (key: string, isSelected?: boolean) => void;
}

export interface CheckBoxTreeNode {
  label: string;
  value: string;
  children?: CheckBoxTreeNode[];
}

const CheckboxTreeStyled = styled.div<{margin?: number}>`
  break-inside: avoid;

  ol {
    margin: 0;
    padding: 0;

    ol {
      display: block;
      padding: 0 0 0 20px;
    }

    li {
      list-style: none;
      line-height: 22px;
      margin-bottom: ${({margin}) => (isNotNilOrZero(margin) ? `${margin}px` : '10px')};
    }
  }
`;
/**
 * @deprecated - use platform instead
 */
const CheckboxTreeStyledV2 = styled.div`
  break-inside: avoid;

  li {
    list-style: none;
  }

  ol {
    margin: 0;
    padding: 0;

    ol {
      display: block;
      padding-left: ${({theme}) => theme.getSize(6)};
    }
  }
`;

const isSelected = (node: CheckBoxTreeNode, selected: readonly string[]): boolean =>
  selected?.includes(node.value);

const hasSelectedChildren = (node: CheckBoxTreeNode, selected: readonly string[]): boolean =>
  node.children?.length
    ? node.children.some(
        (child) =>
          isSelected(child, selected) ||
          (child.children?.length && hasSelectedChildren(child, selected))
      )
    : false;

const someChildrenNotSelected = (node: CheckBoxTreeNode, selected: readonly string[]): boolean =>
  node.children?.length
    ? node.children.some(
        (child) =>
          !isSelected(child, selected) ||
          (child.children?.length && someChildrenNotSelected(child, selected))
      )
    : false;

const CheckboxNode: FC<NodeProps & TestIdProps> = ({name, node, selected, onSelect, ...props}) => (
  <VStack spacing={2}>
    <Checkbox
      value={isSelected(node, selected)}
      isIndeterminate={hasSelectedChildren(node, selected)}
      label={node.label}
      onChange={() =>
        onSelect(node.value, !(isSelected(node, selected) || hasSelectedChildren(node, selected)))
      }
    />
    {!!node.children?.length && (
      <Box paddingLeft={4}>
        <VStack spacing={2}>
          {node.children.map((child, index) => (
            <CheckboxNode
              name={`${name}-${index}`}
              key={index}
              node={child}
              selected={selected}
              onSelect={onSelect}
              data-testid={suffixTestId(`[${index}]`, props)}
            />
          ))}
        </VStack>
      </Box>
    )}
  </VStack>
);

// TODO: Refactor
const CheckboxNodeV2: FC<NodeProps & TestIdProps> = ({
  name,
  node,
  selected,
  onSelect,
  ...props
}) => {
  const currentOnSelect = useRef<typeof onSelect>(onSelect);
  const currentNode = useRef<CheckBoxTreeNode>(node);
  const currentSelected = useRef<readonly string[]>(selected);

  let checkboxProps = useMemo(
    () => ({
      value: false,
      isIndeterminate: false,
    }),
    []
  );

  if (!someChildrenNotSelected(node, selected) && node.children?.length) {
    checkboxProps = {
      value: false,
      isIndeterminate: false,
    };
  } else if (hasSelectedChildren(node, selected)) {
    checkboxProps = {
      value: false,
      isIndeterminate: true,
    };
  } else if (isSelected(node, selected)) {
    checkboxProps = {
      value: true,
      isIndeterminate: false,
    };
  }

  const handleOnSelect = useCallback(() => {
    currentOnSelect.current(
      currentNode.current.value,
      !(
        isSelected(currentNode.current, currentSelected.current) ||
        hasSelectedChildren(currentNode.current, currentSelected.current)
      )
    );
  }, []);

  useEffect(() => {
    currentNode.current = node;
    currentSelected.current = selected;
    currentOnSelect.current = onSelect;
  }, [node, selected, onSelect]);

  return (
    <li data-testid={suffixTestId(`list-item-${convertStringToCamelCase(node.label)}`, props)}>
      <Box paddingBottom={1}>
        <Box width="100%" paddingBottom={2} position="relative">
          <Checkbox
            {...checkboxProps}
            label={node.label}
            onChange={() => {
              handleOnSelect();
            }}
            data-testid={suffixTestId(convertStringToCamelCase(node.label), props)}
          />
        </Box>
      </Box>
      {!!node.children?.length && (
        <ol data-testid={suffixTestId(`${convertStringToCamelCase(node.label)}-wrapper`, props)}>
          {node.children.map((child, index) => (
            <CheckboxNodeV2
              name={`${name}-${index}`}
              key={child.value}
              node={child}
              selected={selected}
              onSelect={onSelect}
              data-testid={props['data-testid']}
            />
          ))}
        </ol>
      )}
    </li>
  );
};

// TODO: Refactor
export const CheckboxTree: FC<ComponentProps & TestIdProps> = ({
  useV2,
  name,
  nodes,
  selected,
  onSelect,
  margin,
  ...props
}) => {
  if (useV2) {
    return (
      <CheckboxTreeStyledV2 data-testid={props['data-testid']}>
        <ol data-testid={suffixTestId('list', props)}>
          {nodes?.map((node, index) => (
            <CheckboxNodeV2
              name={`${name}-${index}`}
              key={node.value}
              node={node}
              selected={selected}
              onSelect={onSelect}
              data-testid={props['data-testid']}
            />
          ))}
        </ol>
      </CheckboxTreeStyledV2>
    );
  }

  return (
    <CheckboxTreeStyled margin={margin}>
      <VStack spacing={2} data-testid={suffixTestId('list', props)}>
        {nodes?.map((node, index) => (
          <CheckboxNode
            name={`${name}-${index}`}
            key={index}
            node={node}
            selected={selected}
            onSelect={onSelect}
            data-testid={props['data-testid']}
          />
        ))}
      </VStack>
    </CheckboxTreeStyled>
  );
};
