import {
  closeCurrentDialog,
  FormSubmitHandler,
  openDialog,
  showNotification,
} from 'platform/components';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  CustomerRequestBody,
  CustomerResponseBodyV2,
  PatchCustomerApiArg,
  useLazyGetCustomerV2Query,
  usePatchCustomerMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {Nullish} from 'shared';

import {EditLegalPersonForm} from '../components/EditLegalPersonForm/EditLegalPersonForm';
import {EditPhysicalPersonForm} from '../components/EditPhysicalPersonForm/EditPhysicalPersonForm';
import {handleApiError} from '../utils/handleApiError';
import {isCustomerNaturalPerson} from '../utils/isCustomerNaturalPerson';

export const useEditCustomer = (
  customer: CustomerResponseBodyV2 | Nullish,
  afterEdit?: (customer: CustomerResponseBodyV2) => void
) => {
  const [getCustomer, {isLoading: isCustomerLoading}] = useLazyGetCustomerV2Query();
  const [patchCustomer, {isLoading: isCustomerPatchLoading}] = usePatchCustomerMutation();

  if (isNil(customer)) {
    return;
  }

  const onSubmit: FormSubmitHandler<CustomerRequestBody> = async (values) => {
    if (isNilOrEmpty(values.foundingPerson?.phoneNumbers?.[0]?.phoneNumber?.number)) {
      values.foundingPerson!.phoneNumbers = [];
    }

    if (isNilOrEmpty(values.foundingPerson?.emails?.[0]?.email)) {
      values.foundingPerson!.emails = [];
    }

    if (isNilOrEmpty(values.foundingPerson?.identityCards?.[0]?.cardData?.cardNumber)) {
      values.foundingPerson!.identityCards = [];
    }

    const args: PatchCustomerApiArg = {
      customerId: customer.id,
      customerRequestBody: values,
    };

    await patchCustomer(args)
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

    await getCustomer({customerId: customer.id})
      .unwrap()
      .then(afterEdit)
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  return () =>
    openDialog(
      isCustomerNaturalPerson(customer) ? (
        <EditPhysicalPersonForm
          isLoading={isCustomerPatchLoading || isCustomerLoading}
          customer={customer}
          onSubmit={onSubmit}
          data-testid={testIds.customer.detail('physicalPersonForm')}
        />
      ) : (
        <EditLegalPersonForm
          isLoading={isCustomerPatchLoading || isCustomerLoading}
          customer={customer}
          onSubmit={onSubmit}
          data-testid={testIds.customer.detail('legalPersonForm')}
        />
      ),
      {
        title: i18n.t('entity.customer.labels.editCustomerInformation'),
        withAdditionalFooter: true,
        scrollBehavior: isCustomerNaturalPerson(customer) ? 'inside' : 'outside',
        'data-testid': testIds.customer.detail('editCustomerInformation'),
      }
    );
};
