import {v4 as uuidV4} from 'uuid';

import {FC, useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {testIds} from '@omnetic-dms/routes';

import {useApiDispatch} from '../../../hooks/useApiDispatch';
import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {getCondition, getNewestAuditStructure} from '../../../store/carAudit/actions';
import {setAuditForCreate} from '../../../store/carAudit/reducer';
import {
  AuditAssets,
  AuditFields,
  AuditVideoAssets,
  getInitialAuditData,
} from '../../../store/carAudit/reducerUtils';
import {InspectionType} from '../../../types/InspectionType';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {Audit} from '../../VehicleInspection/components/Audit';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';

interface ConditionTabProps {
  vehicleId?: string;
}

export const ConditionTab: FC<ConditionTabProps> = ({vehicleId}) => {
  const dispatch = useDispatch();
  const apiDispatch = useApiDispatch();
  const thunkDispatch = useThunkDispatch();
  const {form: vehicleCreateForm} = useFormRenderer<VehicleCreateFormState>();

  const loadConditionStructureAndPrepareAudit = useCallback(async () => {
    let assets: AuditAssets = {};
    let fields: AuditFields = {};
    let videoAssets: AuditVideoAssets = {};
    let controlStructureId = null;

    const [structure] = await apiDispatch(getNewestAuditStructure.action, {
      inspectionType: InspectionType.vehicleCondition,
    });

    try {
      const [condition] = await thunkDispatch(
        getCondition.action({
          requestBody: {vehicleIds: [vehicleId as string]},
          inspectionType: InspectionType.vehicleCondition,
          withoutReducer: true,
        })
      ).unwrap();

      const initData = getInitialAuditData(condition);

      assets = initData?.assets ?? {};
      fields = initData?.fields ?? {};
      videoAssets = initData?.videoAssets ?? {};
    } catch {
      controlStructureId = structure?.id;
    }

    dispatch(
      setAuditForCreate({
        id: uuidV4(),
        assets,
        videoAssets,
        fields,
        isNew: true,
        controlStructureId,
      })
    );
  }, [vehicleId, apiDispatch, dispatch, thunkDispatch]);

  useEffect(() => {
    loadConditionStructureAndPrepareAudit();
  }, [loadConditionStructureAndPrepareAudit]);

  return (
    <Audit
      data-testid={testIds.vehicles.create('condition')}
      isCreate
      createVehicleFeatures={vehicleCreateForm?.getState()?.values?.features}
    />
  );
};
