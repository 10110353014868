import type {PermissionsRecordType} from '../permissions';

export const corePermissions = {
  chronosUser: {
    resourceId: 'CHRONOS_USER',
    actionId: 'READ',
    scopes: [],
  },
  quickPurchaseRead: {
    resourceId: 'QUICK_PURCHASE',
    actionId: 'READ',
    scopes: [],
  },
  reportingRead: {
    resourceId: 'REPORTING',
    actionId: 'READ',
    scopes: [],
  },
  downloadDocuments: {
    resourceId: 'DOCUMENT',
    actionId: 'DOWNLOAD',
    scopes: [],
  },
  readDocuments: {
    resourceId: 'DOCUMENT',
    actionId: 'READ',
    scopes: [],
  },
  linkDocuments: {
    resourceId: 'DOCUMENT',
    actionId: 'LINK',
    scopes: [],
  },
} satisfies PermissionsRecordType;
