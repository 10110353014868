import {Alpha3CountryCode, PhoneInput, PhoneNumber} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import styled from 'styled-components';
import {DeepNonNullable} from 'utility-types';

import {FC, useMemo, PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';

import {selectTenant} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  useFields,
  useInlineEditing,
  selectPhoneNumbers,
  PutTenantRequestBody,
  noop,
} from '@omnetic-dms/teas';

import {withInlineEditing} from '../../../utils/inlineEditingTransformation';
import {useFormContextWithSubmit} from '../hooks/useFormContextWithSubmit';
import {TenantCache, SelfEmployedFieldsFormType} from '../types';

const HorizontalDividerStyled = styled.div`
  margin: ${({theme}) => theme.getSize(3)} 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  box-sizing: border-box;
  height: 1px;
  width: 100%;
`;

export const SelfEmployedFields: FC<PropsWithChildren<any>> = () => {
  const {form, onSubmit} = useFormContextWithSubmit<DeepNonNullable<SelfEmployedFieldsFormType>>();
  const {data: {country: countryCode} = {}} = useSelector(selectTenant);
  const phoneList = useSelector(selectPhoneNumbers);
  const inlineEditing = useInlineEditing<Partial<PutTenantRequestBody>, TenantCache>();
  const phoneNumber = useMemo(
    () =>
      inlineEditing.editedValue?.data?.contactInformation?.selfEmployedInformation?.phoneNumber ?? {
        countryCode,
      },
    [
      inlineEditing.editedValue?.data?.contactInformation?.selfEmployedInformation?.phoneNumber,
      countryCode,
    ]
  );

  const fields = useFields<
    DeepNonNullable<SelfEmployedFieldsFormType>,
    ['selfEmployedInformation']
  >(form);

  return (
    <VStack>
      <HStack spacing={4}>
        <Box flex={1}>
          {fields.text({
            name: ['selfEmployedInformation', 'titleBefore'],
            defaultValue: '',
            onBlur: onSubmit,
            inputProps: {
              label: i18n.t('entity.person.labels.titleBefore'),
              placeholder: '–',
            },
          })}
        </Box>
        <Box flex={2}>
          {fields.text({
            name: ['selfEmployedInformation', 'firstName'],
            defaultValue: '',
            onBlur: onSubmit,
            inputProps: {
              required: true,
              label: i18n.t('entity.person.labels.firstName'),
              placeholder: '–',
            },
          })}
        </Box>
        <Box flex={2}>
          {fields.text({
            name: ['selfEmployedInformation', 'lastName'],
            defaultValue: '',
            onBlur: onSubmit,
            inputProps: {
              required: true,
              label: i18n.t('entity.person.labels.lastName'),
              placeholder: '–',
            },
          })}
        </Box>
        <Box flex={1}>
          {fields.text({
            name: ['selfEmployedInformation', 'titleAfter'],
            defaultValue: '',
            onBlur: onSubmit,
            inputProps: {
              label: i18n.t('entity.person.labels.titleAfter'),
              placeholder: '–',
            },
          })}
        </Box>
      </HStack>
      <Space vertical={4} />

      <HStack spacing={4}>
        <Box flex={1}>
          {withInlineEditing(
            <PhoneInput
              label={i18n.t('entity.person.labels.phoneNumber')}
              countries={phoneList.map((phone) => ({
                countryCode: phone.code as Alpha3CountryCode,
                dialCode: phone.prefix,
                name: phone.name,
              }))}
              preferredCountries={phoneList
                .filter((phone) => phone.preferred)
                .map((phone) => phone.code as Alpha3CountryCode)}
              value={phoneNumber as PhoneNumber}
              onChange={noop}
            />,
            {
              onChangePropPath: ['onChange'],
              inlineEditingTransformation: (_phoneNumber) => ({
                contactInformation: {
                  selfEmployedInformation: {phoneNumber: _phoneNumber},
                },
              }),
            }
          )}
        </Box>
        <Box flex={1}>
          {fields.text({
            name: ['selfEmployedInformation', 'emailAddress'],
            defaultValue: '',
            onBlur: onSubmit,
            inputProps: {
              label: i18n.t('general.labels.email'),
              placeholder: '–',
            },
          })}
        </Box>
      </HStack>
      <HorizontalDividerStyled />
    </VStack>
  );
};
