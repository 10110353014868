import {Checkbox, Tooltip} from 'platform/components';
import {Icon, Box, Hide, HStack, Show} from 'platform/foundation';
import {css} from 'styled-components';

import {FC, useRef, useState, useCallback, SyntheticEvent} from 'react';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {Draggable} from '../CustomDragAndDrop';
import {useFullDnDContext} from '../CustomDragAndDrop/hooks/useFullDnDContext';
import {
  CoverPhotoLabelWrapper,
  FileOperationStatus,
  Image,
  MuiGridStyled,
  SelectionBorder,
  StyledContextMenu,
} from './styles';
import {ImageRendererProps} from './types';

export const ImageRenderer: FC<ImageRendererProps> = ({
  image,
  index,
  showPointer,
  handleImageSelection,
  disableDrag,
  selectionEnabled,
  contextMenuOptions,
  getSelectedImages,
  tooltipRenderer = null,
  selectedPhotos = [],
}) => {
  const {selectedItems} = useFullDnDContext();

  const [currentImage, setCurrentImage] = useState<string>();
  const imageRef = useRef<HTMLImageElement>(null);

  const onImageLoaded = useCallback((e: SyntheticEvent<HTMLImageElement, Event>) => {
    setCurrentImage(e.currentTarget.src);
  }, []);

  const isSelected = (draggable: boolean) => {
    if (draggable) {
      return true;
    }
    return !selectionEnabled || image.isSelected || selectedPhotos.includes(image.id);
  };

  const isImageProcessingInProgress = image.latestFileOperation?.state === 'IN_PROGRESS';

  const Component = (
    <MuiGridStyled
      data-testid={`images-card-content-items-${index}`}
      key={image.id}
      onClick={() => handleImageSelection(image.id)}
    >
      <img
        ref={imageRef}
        css={css`
          display: none;
        `}
        onLoad={onImageLoaded}
        src={`${image.url || image.originalUri}&width=150`}
      />

      <Draggable draggableId={image.id} data={image} disable={disableDrag}>
        {(
          elProps: any,
          {isDragging, draggable, hoveredSide, isSelected: _isContentSelected, globalDragging}: any
        ) => (
          <div
            {...elProps}
            css={css`
              display: flex;
              flex: 1 1;
              cursor: ${showPointer ? 'pointer' : 'auto'};
            `}
          >
            <SelectionBorder
              isDragging={globalDragging}
              borderSide={hoveredSide ?? undefined}
              selectionEnabled={selectionEnabled || draggable}
              isSelected={isSelected(draggable) || _isContentSelected}
            >
              <StyledContextMenu
                id={image.id}
                contextMenuProps={{
                  options:
                    contextMenuOptions?.(getSelectedImages(selectedItems.concat(image.id))) || [],
                }}
              >
                <Image
                  src={currentImage}
                  isDragged={draggable ? false : isDragging}
                  isSelected={isSelected(draggable)}
                  isChecked={_isContentSelected}
                >
                  <Show when={image.isPromoPhoto}>
                    <CoverPhotoLabelWrapper grayDark>
                      {i18n.t('entity.photo.labels.promoPhoto')}
                    </CoverPhotoLabelWrapper>
                  </Show>
                  {image.isCoverPhoto && (
                    <CoverPhotoLabelWrapper blue>
                      {i18n.t('entity.photo.labels.coverPhoto')}
                    </CoverPhotoLabelWrapper>
                  )}
                  {selectionEnabled && (
                    <Box paddingBottom={1} height="100%" flex={1}>
                      <HStack align="flex-end" justify="flex-end" height="100%">
                        <Checkbox
                          onChange={() => handleImageSelection(image.id)}
                          value={isSelected(draggable) || _isContentSelected}
                        />
                      </HStack>
                    </Box>
                  )}
                  <Hide
                    when={
                      isNil(image.latestFileOperation?.state) ||
                      image.latestFileOperation?.acknowledged
                    }
                  >
                    <FileOperationStatus>
                      <Box padding={2}>
                        <Show
                          when={
                            isImageProcessingInProgress &&
                            image.latestFileOperation?.type === 'REMOVE_BACKGROUND'
                          }
                        >
                          <Tooltip label={i18n.t('entity.photo.labels.backgroundRemoval.progress')}>
                            <Icon value="action/watch_later" color="severity.informational" />
                          </Tooltip>
                        </Show>
                        <Show
                          when={
                            isImageProcessingInProgress &&
                            image.latestFileOperation?.type === 'ROTATE'
                          }
                        >
                          <Tooltip label={i18n.t('entity.photo.labels.image.processing')}>
                            <Icon value="action/watch_later" color="severity.informational" />
                          </Tooltip>
                        </Show>
                        <Show when={image.latestFileOperation?.state === 'FAILED'}>
                          <Tooltip
                            label={
                              image.latestFileOperation?.type === 'ROTATE'
                                ? i18n.t('entity.photo.labels.rotation.error')
                                : i18n.t('entity.photo.labels.backgroundRemoval.error')
                            }
                          >
                            <Icon value="alert/warning" color="severity.danger" />
                          </Tooltip>
                        </Show>
                      </Box>
                    </FileOperationStatus>
                  </Hide>
                </Image>
              </StyledContextMenu>
            </SelectionBorder>
          </div>
        )}
      </Draggable>
    </MuiGridStyled>
  );

  if (tooltipRenderer) {
    return tooltipRenderer(Component);
  }

  return Component;
};
