/**
 * Type of vehicle. (VEHICLETYPE_CAR is deprecated)
 */
export enum VehicleTypeEnumObject {
  VEHICLETYPE_CARAVAN = 'VEHICLETYPE_CARAVAN',
  VEHICLETYPE_PASSENGER_CAR = 'VEHICLETYPE_PASSENGER_CAR',
  VEHICLETYPE_VAN = 'VEHICLETYPE_VAN',
  VEHICLETYPE_MOTORCYCLE = 'VEHICLETYPE_MOTORCYCLE',
  VEHICLETYPE_TRUCK = 'VEHICLETYPE_TRUCK',
  VEHICLETYPE_SEMI_TRAILER = 'VEHICLETYPE_SEMI_TRAILER',
  VEHICLETYPE_TRAILER = 'VEHICLETYPE_TRAILER',
  VEHICLETYPE_BUS = 'VEHICLETYPE_BUS',
  VEHICLETYPE_OTHER = 'VEHICLETYPE_OTHER',
  VEHICLETYPE_AGRICULTURAL_MACHINE = 'VEHICLETYPE_AGRICULTURAL_MACHINE',
  VEHICLETYPE_CONSTRUCTION_MACHINE = 'VEHICLETYPE_CONSTRUCTION_MACHINE',
}
