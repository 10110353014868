import {match} from 'ts-pattern';

import {always, T, F} from 'ramda';

import {Nullish} from 'shared';

import {Resolution} from '../types/resolution';

export function getValueFromResolution(resolution: Resolution | Nullish): boolean | null {
  return match<Resolution | Nullish, boolean | null>(resolution)
    .with('YES', T)
    .with('NO', F)
    .otherwise(always(null));
}
