import {object, boolean, date, string} from 'yup';

export const vehicleInsuranceFormSchema = object({
  isCustomerInsuranceEnabled: boolean().required(),
  liabilityInsuranceCompany: string().nullable(),
  liabilityInsuranceDate: date().nullable(),
  liabilityInsuranceNumber: string().nullable(),
  collisionInsuranceCompany: string().nullable(),
  collisionInsuranceDate: date().nullable(),
  collisionInsuranceNumber: string().nullable(),
  customerInsuranceNote: string().nullable().max(255),
  isFleetInsuranceEnabled: boolean().required(),
  fleetInsuranceNote: string().nullable(),
  fleetInsuranceDate: date().nullable(),
});
