import styled from 'styled-components';
import styledMap from 'styled-map';

const mBott = styledMap`
	default: 4px;
	alone: 0px;
`;

const mTop = styledMap`
	default: 0;
	alone: 0;
`;
const color = styledMap`
	default: ${({theme}) => theme.colors.palettes.neutral[300][100]};
	checkbox: ${({theme}) => theme.colors.palettes.neutral[900][100]};
`;

const opacity = styledMap`
	default: 1;
	disabled: 0.6;
`;

type LabelProps = {
  htmlFor?: string;
  disabled?: boolean;
  checkbox?: boolean;
};

export const FormLabel = styled.label<LabelProps>`
  line-height: 1.34;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  color: ${color};
  margin-bottom: ${mBott};
  margin-top: ${mTop};
  display: block;
  opacity: ${opacity};
`;
