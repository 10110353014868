import {ThemeIconKey, Icon, Show} from 'platform/foundation';
import styled, {css} from 'styled-components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

export type BadgeVariantType = 'bold' | 'subtle';
export type BadgeSizeType = 'default' | 'small';
export type BadgeColorScheme =
  | 'neutral'
  | 'red'
  | 'magenta'
  | 'purple'
  | 'blue'
  | 'teal'
  | 'green'
  | 'yellow'
  | 'orange';

export type BadgeProps = {
  size?: BadgeSizeType;
  variant?: BadgeVariantType;
  colorScheme?: BadgeColorScheme;
} & (
  | {
      value: string | number;
      icon?: never;
    }
  | {icon: ThemeIconKey; value?: never}
) &
  TestIdProps;

export function Badge(props: BadgeProps) {
  return (
    <StyledBadgeWrapper
      $size={props.size}
      $variant={props.variant}
      $colorScheme={props.colorScheme}
      data-testid={suffixTestId('badge', props)}
    >
      <Show when={props.value}>{props.value}</Show>
      <Show when={props.icon}>
        <Icon value={props.icon} />
      </Show>
    </StyledBadgeWrapper>
  );
}

const getSizeStyles = ($size: BadgeSizeType = 'default') =>
  match($size)
    .with(
      'default',
      // Using forbidden css props on purpose
      // eslint-disable-next-line eag/no-css-property
      always(css`
        min-width: ${({theme}) => theme.getSize(6)};
        height: ${({theme}) => theme.getSize(6)};
        padding-block: 2px;
        padding-inline: ${({theme}) => theme.getSize(2)};
        font-size: ${({theme}) => theme.fontSizes.text.small};
        line-height: ${({theme}) => theme.lineHeights.text.small};

        svg {
          width: ${({theme}) => theme.getSize(4)};
          height: ${({theme}) => theme.getSize(4)};
        }
      `)
    )
    .with(
      'small',
      // Using forbidden css props on purpose
      // eslint-disable-next-line eag/no-css-property
      always(css`
        min-width: ${({theme}) => theme.getSize(4)};
        height: ${({theme}) => theme.getSize(4)};
        padding-block: 0;
        padding-inline: ${({theme}) => theme.getSize(1)};
        font-size: ${({theme}) => theme.fontSizes.text.xSmall};
        line-height: ${({theme}) => theme.lineHeights.text.xSmall};

        svg {
          width: ${({theme}) => theme.getSize(3)};
          height: ${({theme}) => theme.getSize(3)};
        }
      `)
    )
    .exhaustive();

const getColorScheme = (
  $variant: BadgeVariantType = 'bold',
  $colorScheme: BadgeColorScheme = 'green'
) =>
  match([$variant, $colorScheme])
    .with(
      ['bold', 'blue'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.blue[70][100]};
        color: ${({theme}) => theme.colors.palettes.white[10][100]};
      `)
    )
    .with(
      ['bold', 'green'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.green[60][100]};
        color: ${({theme}) => theme.colors.palettes.white[10][100]};
      `)
    )
    .with(
      ['bold', 'magenta'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.magenta[60][100]};
        color: ${({theme}) => theme.colors.palettes.white[10][100]};
      `)
    )
    .with(
      ['bold', 'neutral'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.neutral[300][100]};
        color: ${({theme}) => theme.colors.palettes.white[10][100]};
      `)
    )
    .with(
      ['bold', 'orange'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.orange[60][100]};
        color: ${({theme}) => theme.colors.palettes.white[10][100]};
      `)
    )
    .with(
      ['bold', 'purple'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.purple[60][100]};
        color: ${({theme}) => theme.colors.palettes.white[10][100]};
      `)
    )
    .with(
      ['bold', 'red'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.red[60][100]};
        color: ${({theme}) => theme.colors.palettes.white[10][100]};
      `)
    )
    .with(
      ['bold', 'teal'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.teal[60][100]};
        color: ${({theme}) => theme.colors.palettes.white[10][100]};
      `)
    )
    .with(
      ['bold', 'yellow'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.yellow[60][100]};
        color: ${({theme}) => theme.colors.palettes.neutral[800][100]};
      `)
    )
    .with(
      ['subtle', 'blue'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.blue[20][100]};
        color: ${({theme}) => theme.colors.palettes.blue[80][100]};
      `)
    )
    .with(
      ['subtle', 'green'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.green[20][100]};
        color: ${({theme}) => theme.colors.palettes.green[80][100]};
      `)
    )
    .with(
      ['subtle', 'magenta'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.magenta[20][100]};
        color: ${({theme}) => theme.colors.palettes.magenta[80][100]};
      `)
    )
    .with(
      ['subtle', 'neutral'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
        color: ${({theme}) => theme.colors.text.primary};
      `)
    )
    .with(
      ['subtle', 'orange'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.orange[20][100]};
        color: ${({theme}) => theme.colors.palettes.orange[80][100]};
      `)
    )
    .with(
      ['subtle', 'purple'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.purple[20][100]};
        color: ${({theme}) => theme.colors.palettes.purple[80][100]};
      `)
    )
    .with(
      ['subtle', 'red'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.red[20][100]};
        color: ${({theme}) => theme.colors.palettes.red[80][100]};
      `)
    )
    .with(
      ['subtle', 'teal'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.teal[20][100]};
        color: ${({theme}) => theme.colors.palettes.teal[80][100]};
      `)
    )
    .with(
      ['subtle', 'yellow'],
      always(css`
        background-color: ${({theme}) => theme.colors.palettes.yellow[20][100]};
        color: ${({theme}) => theme.colors.text.primary};
      `)
    )
    .exhaustive();

type StyledBadgeWrapperProps = {
  $size?: BadgeSizeType;
  $variant?: BadgeVariantType;
  $colorScheme?: BadgeColorScheme;
};

// Using forbidden css props on purpose
// We set 9999px on purpose, because we don't have this value set in the theme
// eslint-disable-next-line eag/no-css-property
const StyledBadgeWrapper = styled.div<StyledBadgeWrapperProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  border-radius: 9999px;
  font-weight: ${({theme}) => theme.fontWeights.medium};
  text-align: center;
  letter-spacing: 0;

  svg {
    margin-inline: -4px;
  }

  ${({$size}) => getSizeStyles($size)}
  ${({$variant, $colorScheme}) => getColorScheme($variant, $colorScheme)}
`;
