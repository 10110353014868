import {AutoSave, Card, DataStatus, Form} from 'platform/components';
import {Grid, GridItem, Show, VStack} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  PriceReportWidget,
  Section,
  interestSearchParams,
  queryParams,
  usePermissions,
  useTenant,
} from '@omnetic-dms/shared';

import {useQueryState, useToggle} from 'shared';

import {FeaturesPicker} from '../../components/FeaturesPicker';
import {RequestSpecification} from '../../components/RequestSpecification';
import {useInterestData} from '../../hooks/useInterestData';
import {BuyingVehicleForm} from './components/BuyingVehicleForm';
import {BuyingVehicleWidget} from './components/BuyingVehicleWidget';
import {Offer} from './components/Offer';
import {useMinisaleUpdate} from './hooks/useMinisaleUpdate';
import {BuyingFormType} from './types/BuyingFormType';
import {transformMinisaleToBuyingForm} from './utils/transformMinisaleToBuyingForm';

interface BuyingProps {
  interestId: string;
}

export function Buying(props: BuyingProps) {
  const [_activeSectionId, setActiveSectionId] = useQueryState(
    queryParams.COMPONENT_SECTIONS_SECTION
  );

  const [canReadPriceReport] = usePermissions({permissionKeys: ['createVehiclePriceReport']});

  const [isFeaturesOpen, toggleFeaturesOpen] = useToggle();

  const {miniSale, isLoading, isError, isActionsDisabled} = useInterestData(props.interestId);
  const {tenantCurrency} = useTenant();

  const {onBuyingUpdate} = useMinisaleUpdate(props.interestId);

  return (
    <Section
      heading={i18n.t('entity.interest.labels.buyingTab')}
      actions={[
        {
          type: 'button',
          variant: 'outlined',
          title: i18n.t('general.actions.continue'),
          'data-testid': testIds.interest.detail('continue'),
          isDisabled: !miniSale || isActionsDisabled,
          onClick: () => setActiveSectionId(interestSearchParams.interestDetail.overview),
        },
      ]}
    >
      <DataStatus isLoading={isLoading} isError={isError} minHeight={200}>
        <Form<BuyingFormType> values={transformMinisaleToBuyingForm(miniSale, tenantCurrency)}>
          {(control, formApi) => {
            const features = formApi.watch('vehicleData.features');
            const vehicleType = formApi.watch('vehicleData.type')?.[0];
            return (
              <Grid columns={12} verticalSpacing={3}>
                <AutoSave<BuyingFormType> onSave={onBuyingUpdate} />
                <GridItem span={12}>
                  <Card variant="inlineWhite">
                    <BuyingVehicleWidget
                      data-testid={testIds.interest.detail('buying')}
                      interestId={props.interestId}
                      control={control}
                      formApi={formApi}
                    />
                  </Card>
                </GridItem>
                <GridItem span={7}>
                  <VStack spacing={3}>
                    <Card
                      variant="inlineWhite"
                      title={i18n.t('entity.vehicle.labels.vehicleSpecification')}
                    >
                      <BuyingVehicleForm
                        data-testid={testIds.interest.detail('buying')}
                        control={control}
                        formApi={formApi}
                      />
                    </Card>

                    <Card variant="inlineWhite" title={i18n.t('entity.vehicle.labels.features')}>
                      <VStack spacing={4}>
                        {isNotNilOrEmpty(features) ? (
                          <RequestSpecification
                            data-testid={testIds.interest.detail('buying')}
                            filteredSpecifications={{features}}
                          />
                        ) : null}
                        <FeaturesPicker<BuyingFormType>
                          data-testid={testIds.interest.detail('buying')}
                          control={control}
                          name="vehicleData.features"
                          vehicleType={vehicleType}
                          isFeaturesOpen={isFeaturesOpen}
                          toggleFeaturesOpen={toggleFeaturesOpen}
                          isLabelHidden
                        />
                      </VStack>
                    </Card>
                  </VStack>
                </GridItem>

                <GridItem span={5}>
                  <VStack spacing={3}>
                    <Offer
                      data-testid={testIds.interest.detail('buying')}
                      interestId={props.interestId}
                      control={control}
                      formApi={formApi}
                    />

                    <Show
                      whenFeatureEnabled={featureFlags.PRICE_REPORT_WIDGET}
                      when={canReadPriceReport}
                    >
                      <PriceReportWidget resourceId={props.interestId} context="interest" />
                    </Show>
                  </VStack>
                </GridItem>
              </Grid>
            );
          }}
        </Form>
      </DataStatus>
    </Section>
  );
}
