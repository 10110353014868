import {FlagProps} from 'platform/components';

import i18n from '@omnetic-dms/i18n';

import {Nullish, buildArray, LiteralUnionAutocomplete} from 'shared';

type MarkerType = LiteralUnionAutocomplete<
  | 'SVCTAGVALUE_REPEATED_REPAIR'
  | 'SVCTAGVALUE_PART_REQUEST_STATE'
  | 'SVCTAGVALUE_PART_READY_STATE'
  | 'SVCTAGVALUE_COMPLETION_OVERDUE'
  | 'SVCTAGVALUE_ORDER_OVERPRICED'
>;

export function getMarkersFlags(markers: MarkerType[] | Nullish) {
  return buildArray<FlagProps>()
    .when(markers?.includes('SVCTAGVALUE_COMPLETION_OVERDUE'), {
      label: i18n.t('general.labels.overdue'),
      colorScheme: 'red',
    })
    .when(markers?.includes('SVCTAGVALUE_ORDER_OVERPRICED'), {
      label: i18n.t('general.labels.overpriced'),
      colorScheme: 'red',
    })
    .when(markers?.includes('SVCTAGVALUE_REPEATED_REPAIR'), {
      label: i18n.t('entity.workshop.labels.repeatedRepair'),
      colorScheme: 'red',
      isSubtle: true,
    })
    .when(markers?.includes('SVCTAGVALUE_PART_REQUEST_STATE'), {
      label: i18n.t('entity.workshop.labels.partsRequest'),
      colorScheme: 'orange',
      isSubtle: true,
    })
    .when(markers?.includes('SVCTAGVALUE_PART_READY_STATE'), {
      label: i18n.t('entity.workshop.labels.partsReady'),
      colorScheme: 'blue',
      isSubtle: true,
    });
}
