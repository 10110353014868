import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';

import {findIndex, propEq} from 'ramda';

import {Assignment} from '../../types/Assignment';
import {CreateAssignmentRequestBody} from '../../types/CreateAssignmentRequestBody';
import {User} from '../../types/User';
import {AssignmentState, UsersRequestType, GetAssignmentsType} from './types';

const STATE_NAME = '@assignment';

const initialState: AssignmentState = {
  searchText: '',
  usersList: {
    loading: false,
    error: null,
    data: {},
    order: [],
  },
  assignments: {
    vehicle: {},
    vehicle_audit: {},
  },
  externalUser: {
    loading: false,
    error: null,
    data: undefined,
  },
};

const assignment = createSlice({
  name: STATE_NAME,
  initialState,
  reducers: {
    clearExternalUser(state, _action: PayloadAction) {
      state.externalUser.loading = false;
      state.externalUser.data = undefined;
    },
    createExternalUserSuccess(state, {payload}: PayloadAction<User>) {
      state.externalUser.loading = false;
      state.externalUser.data = payload;
    },
    fetchUnAssignSuccess(state, {payload}: PayloadAction<Assignment>) {
      const {identity, id} = payload;
      const data = state.assignments[identity.type][identity.id];
      const index = findIndex(propEq(id, 'id'))(data);

      // remove from assigned list
      state.assignments[identity.type][identity.id].splice(index, 1);
    },
    fetchAssignmentsSuccess(
      state,
      {
        payload: {assignmentIdentityType, assignmentIdentityId, response},
      }: PayloadAction<GetAssignmentsType>
    ) {
      state.assignments[assignmentIdentityType][assignmentIdentityId] = response;
    },
  },
});

export const fetchUnAssignRequest = createAction<Assignment>(`${STATE_NAME}/fetchUnAssignRequest`);
export const assignUsersRequest = createAction<CreateAssignmentRequestBody>(
  `${STATE_NAME}/assignUsersRequest`
);
export const assignUsersSuccess = createAction<AxiosError>(`${STATE_NAME}/assignUsersSuccess`);
export const assignUsersError = createAction<AxiosError>(`${STATE_NAME}/assignUsersError`);
export const fetchAssignmentsRequest = createAction<UsersRequestType>(
  `${STATE_NAME}/fetchAssignmentsRequest`
);

const {reducer, actions} = assignment;
export const assignmentReducer = reducer;

export const {
  fetchAssignmentsSuccess,
  fetchUnAssignSuccess,
  createExternalUserSuccess,
  clearExternalUser,
} = actions;
