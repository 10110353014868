import {match} from 'ts-pattern';

import {always} from 'ramda';

import {Device} from '../types/Device';

export function getValueByDevice<M>(device: Device, mobileValue: M): M;
export function getValueByDevice<M, T>(device: Device, mobileValue: M, tabletValue: T): M | T;
export function getValueByDevice<M, T, N>(
  device: Device,
  mobileValue: M,
  tabletValue: T,
  notebookValue: N
): M | T | N;
export function getValueByDevice<M, T, N, D>(
  device: Device,
  mobileValue: M,
  tabletValue: T,
  notebookValue: N,
  desktopValue: D
): M | T | N | D;
export function getValueByDevice<M, T, N, D, P>(
  device: Device,
  mobileValue: M,
  tabletValue: T,
  notebookValue: N,
  desktopValue: D,
  printValue: P
): M | T | N | D | P;

export function getValueByDevice<M, T, N, D, P>(
  device: Device,
  mobileValue: M,
  ...restDeviceValues: [] | [T] | [T, N] | [T, N, D] | [T, N, D, P]
) {
  const tabletValue = restDeviceValues.length >= 1 ? restDeviceValues[0] : mobileValue;
  const notebookValue = restDeviceValues.length >= 2 ? restDeviceValues[1] : tabletValue;
  const desktopValue = restDeviceValues.length >= 3 ? restDeviceValues[2] : notebookValue;
  const printerValue = restDeviceValues.length >= 4 ? restDeviceValues[3] : desktopValue;

  return match(device)
    .with('mobile', always(mobileValue))
    .with('tablet', always(tabletValue))
    .with('notebook', always(notebookValue))
    .with('desktop', always(desktopValue))
    .with('printer', always(printerValue))
    .exhaustive();
}
