import {ButtonGroup} from 'platform/components';
import {Heading, Hide, Show, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import i18n from '@omnetic-dms/i18n';

import {TestIdProps, suffixTestId, useBoolean} from 'shared';

import {OwnershipType} from '../../CustomerVehicleSelection/types/ownershipType';
import {RadioCard} from './RadioCard';

interface CopyCustomerProps extends TestIdProps {
  onCopy: (ownershipType: OwnershipType) => Promise<void> | void;
  onClose: VoidFunction;
  excludedOwnership?: OwnershipType;
  isMove?: boolean;
}

export function CopyCustomer(props: CopyCustomerProps) {
  const [selectedOwnership, setSelectedOwnership] = useState<OwnershipType>(
    props.isMove ? 'CORVEHICLECUSTOMERTYPE_OPERATOR' : 'CORVEHICLECUSTOMERTYPE_OWNER'
  );
  const [isLoading, startLoading, stopLoading] = useBoolean();

  const handleClick = async () => {
    startLoading();
    await props.onCopy(selectedOwnership);
    stopLoading();
  };

  return (
    <VStack spacing={4}>
      <Text size="small" color="tertiary">
        {i18n.t('entity.ownership.labels.copyCustomerInfo')}
      </Text>
      <Heading size={4}>{i18n.t('entity.serviceCase.labels.ownership')}</Heading>
      <Show when={props.isMove}>
        <RadioCard
          isSelected={selectedOwnership === 'CORVEHICLECUSTOMERTYPE_OPERATOR'}
          title={i18n.t('entity.ownership.label.vehicleOperator')}
          onClick={() => setSelectedOwnership('CORVEHICLECUSTOMERTYPE_OPERATOR')}
          data-testid={suffixTestId('operator', props)}
        />
      </Show>
      <Hide when={props.excludedOwnership === 'CORVEHICLECUSTOMERTYPE_OWNER'}>
        <RadioCard
          isSelected={selectedOwnership === 'CORVEHICLECUSTOMERTYPE_OWNER'}
          title={i18n.t('entity.ownership.label.vehicleOwner')}
          onClick={() => setSelectedOwnership('CORVEHICLECUSTOMERTYPE_OWNER')}
          data-testid={suffixTestId('owner', props)}
        />
      </Hide>
      <Hide when={props.excludedOwnership === 'CORVEHICLECUSTOMERTYPE_USER'}>
        <RadioCard
          isSelected={selectedOwnership === 'CORVEHICLECUSTOMERTYPE_USER'}
          title={i18n.t('entity.ownership.label.vehicleUser')}
          onClick={() => setSelectedOwnership('CORVEHICLECUSTOMERTYPE_USER')}
          data-testid={suffixTestId('user', props)}
        />
      </Hide>
      <Hide when={props.excludedOwnership === 'CORVEHICLECUSTOMERTYPE_MANAGER'}>
        <RadioCard
          isSelected={selectedOwnership === 'CORVEHICLECUSTOMERTYPE_MANAGER'}
          title={i18n.t('entity.ownership.label.vehicleManager')}
          onClick={() => setSelectedOwnership('CORVEHICLECUSTOMERTYPE_MANAGER')}
          data-testid={suffixTestId('manager', props)}
        />
      </Hide>
      <Hide when={props.excludedOwnership === 'CORVEHICLECUSTOMERTYPE_INVOICE'}>
        <Heading size={4}>{i18n.t('entity.customer.labels.invoiceTo')}</Heading>
        <RadioCard
          isSelected={selectedOwnership === 'CORVEHICLECUSTOMERTYPE_INVOICE'}
          title={i18n.t('entity.ownership.label.defaultRecepient')}
          onClick={() => setSelectedOwnership('CORVEHICLECUSTOMERTYPE_INVOICE')}
          data-testid={suffixTestId('invoice', props)}
        />
      </Hide>
      <ButtonGroup
        align="right"
        buttons={[
          {
            title: i18n.t('general.actions.cancel'),
            onClick: props.onClose,
            variant: 'secondary',
          },
          {
            title: props.isMove
              ? i18n.t('entity.order.actions.moveHere')
              : i18n.t('general.actions.copyHere'),
            onClick: handleClick,
            isLoading,
            'data-testid': suffixTestId('copy', props),
          },
        ]}
      />
    </VStack>
  );
}
