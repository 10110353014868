import {createSelector, Selector} from '@reduxjs/toolkit';

import type {TeasState} from '../../types/TeasState';
import {CostsStateType} from './reducer';

export const selectCosts: Selector<TeasState, CostsStateType> = (state) => state.sourcing.costs;

export const selectCostWithId = (vehicleId: string) =>
  createSelector(selectCosts, (state) => state?.[vehicleId]);

export const selectPresetItems = (vehicleId: string) =>
  createSelector(selectCostWithId(vehicleId), (state) => Object.values(state?.presetItems || {}));
