import {Alert} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {parseDate} from 'shared';

interface IAdditionalInfoStatusProps {
  inAdvertisement: boolean;
  latestUpdate?: string;
}

export function AdditionalInfoStatus(props: IAdditionalInfoStatusProps) {
  const formatDateTime = useDateTimeFormatter();

  return (
    <Alert
      title={
        props.inAdvertisement
          ? i18n.t('page.advertisement.notifications.publish')
          : i18n.t('page.advertisement.notifications.unPublish')
      }
      message={
        isNotNilOrEmpty(props.latestUpdate)
          ? `${i18n.t('page.advertisement.labels.lastUpdate')} ${formatDateTime(
              'dateTimeShort',
              parseDate(props.latestUpdate ?? '')
            )}`
          : undefined
      }
      variant={props.inAdvertisement ? 'success' : 'warning'}
      type="inline"
    />
  );
}
