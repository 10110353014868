import {isValid} from 'date-fns';
import {isFeatureEnabled} from 'feature-flags';
import {Card, DataStatus, openConfirmDialog, openDialog, Separator} from 'platform/components';
import {Box, Grid, GridItem, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {FC, ForwardedRef, PropsWithChildren, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {always, defaultTo, isNil, map, or, pick, reduce, uniq, values} from 'ramda';
import {isFalse, isNilOrEmpty, isNotNil, isTrue} from 'ramda-adjunct';

import {
  FullVehicle,
  FullVehicleResponseBody,
  selectTenant,
  useCreateVehicleMutation,
  useCreateVehicleToBuyMutation,
  useGetCebiaAutotracerAndReportConfigQuery,
  useGetCebiaRokvyConfigQuery,
  useLazySourcingGetCarFeaturesQuery,
  useSetFeaturesHighlightedMutation,
  useUpdateVehicleMutation,
  VehicleTypeEnumObject,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {handleApiError, useAdPlatformsVehicleUpdateDialog} from '@omnetic-dms/shared';

import {composePath, parseDate, suffixTestId, TestIdProps} from 'shared';

import {useApiDispatch} from '../../hooks/useApiDispatch';
import {useThunkDispatch} from '../../hooks/useThunkDispatch';
import {vehicleTitle} from '../../hooks/useVehicleTitle';
import {auditAssetsUploadV2, getCondition, updateAudit} from '../../store/carAudit/actions';
import {clearAuditAssets, clearAuditFields} from '../../store/carAudit/reducer';
import {loadCarDetailsVehicleDetailRequest} from '../../store/carDetails/actions';
import {createCustomMake, createCustomModel} from '../../store/vehicleCatalogue/reducer';
import {selectVehicleMakeModels} from '../../store/vehicleCatalogue/selectors';
import {CreateVehicleRequestBody} from '../../types/CreateVehicleRequestBody';
import {FuelTypeEnum} from '../../types/FuelTypeEnum';
import {InspectionType} from '../../types/InspectionType';
import {SimilarVehicle} from '../../types/SimilarVehicle';
import {VinDecoder} from '../../types/VinDecoder';
import {$DimensionsRequestBody} from '../../utils/$DimensionsRequestBody';
import {getUpdateRequestBodyFromFormData} from '../../utils/getUpdateRequestBodyFromFormData';
import {noop} from '../../utils/someTeasUtils';
import {CebiaAutotracerWidget} from '../CebiaWidget/CebiaAutotracerWidget';
import {CebiaProductionYearWidget} from '../CebiaWidget/CebiaProductionYearWidget';
import {Form} from '../FinalForm/Form';
import {FormApi} from '../FinalForm/types/FormApi';
import {useVehicleCreateContext} from '../VehicleCreateContext/hooks/useVehicleCreateContext';
import {AdditionalInformation} from './components/AdditionalInformation';
import {Cebia} from './components/Cebia';
import {ConditionTab} from './components/ConditionTab';
import {ConfirmDialog} from './components/ConfirmDialog';
import {Features} from './components/Features';
import {FeaturesAdditionalInfo} from './components/FeaturesAdditionalInfo';
import {FeaturesHighlights} from './components/FeaturesHighlights';
import {InfoForm} from './components/InfoForm';
import {ModelSpecification} from './components/ModelSpecification';
import {PhotosTab} from './components/PhotosTab';
import {VehicleDataSection} from './components/VehicleDataSection';
import {VehicleFormModal} from './components/VehicleFormModal';
import {VehicleTypeFailedNotification} from './components/VehicleTypeFailedNotification';
import {VinAndLicensePlate} from './components/VinAndLicensePlate';
import {useDecodedFields} from './hooks/useDecodedFields';
import {useScrollToSection} from './hooks/useScrollToSection';
import {useUpdateVehicleType} from './hooks/useUpdateVehicleType';
import {$VehicleBatterySection, $VehicleCreateFormState} from './types/$VehicleCreateFormState';
import {TYPE_DEPENDENT_FIELDS} from './types/TYPE_DEPENDENT_FIELDS';
import {VehicleCreateFormState} from './types/VehicleCreateFormState';
import {MAKE_OTHER, MODEL_FAMILY_OTHER, MODEL_FAMILY_OTHER_END} from './utils/consts';
import {getRegistrationDate} from './utils/getRegistrationDate';

enum RefsKeys {
  ENGINE = 'engine',
  VIN = 'vin',
  INFO = 'info',
  MODEL = 'model',
  FUEL = 'fuel',
  ADD_INFO = 'add-info',
  DIMENSIONS = 'dimensions',
  TECHNICAL_INSPECTION = 'technical-inspection',
  WARRANTY = 'warranty',
}

interface VehicleCreateFormProps extends TestIdProps {
  onVehicleSelect?: (vehicle: SimilarVehicle, makeSaleVehicleAvailableForSale?: boolean) => void;
  vehicle?: FullVehicle;
  cyId?: string;
  source?: string | null;
  clonedVehicleId?: string;
  selectVehicleToPurchase?: boolean;
  makeSaleVehicleAvailableForSale?: boolean;
}

export function VehicleCreateForm(props: VehicleCreateFormProps) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const isToBuy = searchParams.get('toBuy');

  const vehicleId = params.id;
  const vehicleTab = params.tab;
  const isCreateMode = isNil(vehicleId) || /business-case/.test(location.pathname);

  const dispatch = useThunkDispatch();
  const apiDispatch = useApiDispatch();

  const currency = useSelector(selectTenant)?.data?.currency;
  const makeModels = useSelector(selectVehicleMakeModels);

  const {data: cebiaAutotracerAndReportConfig, isLoading: isCebiaAutotracerAndReportConfigLoading} =
    useGetCebiaAutotracerAndReportConfigQuery();
  const {data: cebiaRokvyConfig, isLoading: isCebiaRokvyConfigLoading} =
    useGetCebiaRokvyConfigQuery();

  const [decoding, setDecoding] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const {getConditionForm, onCreate, getConditionAssets} = useVehicleCreateContext();

  const [createVehicleMutation] = useCreateVehicleMutation();
  const [createVehicleToBuyMutation] = useCreateVehicleToBuyMutation();
  const [updateVehicleMutation] = useUpdateVehicleMutation();
  const [updateVehicleAds] = useAdPlatformsVehicleUpdateDialog();
  const [updateVehicleFeatures] = useSetFeaturesHighlightedMutation();
  const [getSourcingFeatures] = useLazySourcingGetCarFeaturesQuery();
  const [updateVehicleType] = useUpdateVehicleType(vehicleId);

  const {onDecode} = useDecodedFields();
  const {refs, triggerScroll} = useScrollToSection();

  const getFormValues = (vehicle: FullVehicle): VehicleCreateFormState => {
    const features = uniq(vehicle?.features?.map((feature) => feature.key) ?? []);
    const {vehicle: vehicleAttribute, ...oldVehicleResponseData} = vehicle;

    return {
      ...oldVehicleResponseData,
      battery: vehicle.vehicle.battery,
      dimensions: vehicle.dimensions || {},
      engine: vehicle.engine || {},
      modelSpecification: vehicle.modelSpecification || {},
      state: vehicle?.state || {},
      sellingPrice: vehicle.sellingPrice.withoutVat?.amount,
      sellingPriceWithVat: vehicle.sellingPrice.withVat?.amount,
      sellingPriceVatRate: vehicle.sellingPrice.vatRate,
      purchasePrice: vehicle.purchasePrice.withoutVat?.amount,
      purchasePriceWithVat: vehicle.purchasePrice.withVat?.amount,
      purchasePriceVatRate: vehicle.purchasePrice.vatRate,
      currencyCode: currency,
      type: vehicle.type as VehicleTypeEnumObject,
      features,
      make: (vehicle.make === MAKE_OTHER ? vehicle.customMake : vehicle.make) as string,
      modelFamily: (vehicle.modelFamily.includes(MODEL_FAMILY_OTHER_END)
        ? vehicle.customModelFamily
        : vehicle.modelFamily) as string,
      firstRegistrationDate: getRegistrationDate(vehicle),
      manufacturedOnYear: isNotNil(vehicle.manufacturedOnYear)
        ? // eslint-disable-next-line no-restricted-syntax
          (`${vehicle.manufacturedOnYear}` as unknown as number) // TODO fix types with BE
        : null,
      manufacturedOnMonth: isNotNil(vehicle.manufacturedOnMonth)
        ? // eslint-disable-next-line no-restricted-syntax
          (`${vehicle.manufacturedOnMonth}` as unknown as number) // TODO fix types with BE
        : null,
      featuresByManufacturer: vehicle.featuresByManufacturer,
      vehicleSourceCodeId: vehicle.vehicleSourceCodeId,
    };
  };

  const getRequestBody = (values: VehicleCreateFormState): CreateVehicleRequestBody => {
    const makeModel = makeModels?.[values.make];
    const modelFamily = makeModel?.models.find((model) => model.value === values.modelFamily);

    const parsedDate = parseDate(values.firstRegistrationDate);
    const validParsedDate = parsedDate && isValid(parsedDate) ? parsedDate : null;

    const _title = vehicleTitle({
      make: makeModel,
      modelFamily: values.modelFamily,
      trim: values.trim,
      variant: values.variant,
    });
    const isTitleCustom = _title !== values.title;

    return {
      ...values,
      axleCount: isNotNil(values.axleCount) ? Number(values.axleCount) : values.axleCount,
      battery:
        values.battery &&
        (values.fuelType === FuelTypeEnum.FUELTYPE_ELECTRIC ||
          values.fuelType === FuelTypeEnum.FUELTYPE_HYBRID)
          ? {
              ...$VehicleBatterySection.getDefault(),
              ...values.battery,
            }
          : null,
      features: uniq(defaultTo([], values.features)),
      make: makeModel?.isCustom ? MAKE_OTHER : makeModel?.value,
      title: isTitleCustom ? values.title || null : null,
      modelFamily: match([Boolean(modelFamily?.isCustom), Boolean(makeModel?.isCustom)])
        .with([true, true], always(MODEL_FAMILY_OTHER))
        .with([true, false], always(`${makeModel?.value}${MODEL_FAMILY_OTHER_END}`))
        .with([false, false], always(modelFamily?.value || ''))
        .otherwise(always('')),
      engine: {
        ...(values.engine ?? {}),
        fuelConsumptionCombined: isNotNil(values.engine?.fuelConsumptionCombined)
          ? Number(values.engine?.fuelConsumptionCombined)
          : null,
        fuelConsumptionExtraUrban: isNotNil(values.engine?.fuelConsumptionExtraUrban)
          ? Number(values.engine?.fuelConsumptionExtraUrban)
          : null,
        fuelConsumptionUrban: isNotNil(values.engine?.fuelConsumptionUrban)
          ? Number(values.engine?.fuelConsumptionUrban)
          : null,
      },
      source: props.source,
      customMake: makeModel?.isCustom ? makeModel?.value : null,
      customModelFamily: modelFamily?.isCustom ? (modelFamily?.value as string) : null,
      dimensions: $DimensionsRequestBody.cast(values?.dimensions),
      firstRegistrationOnDay: validParsedDate ? validParsedDate.getDate() : null,
      firstRegistrationOnMonth: validParsedDate ? validParsedDate.getMonth() + 1 : null,
      firstRegistrationOnYear: validParsedDate ? validParsedDate.getFullYear() : null,
      manufacturedOnMonth: isNotNil(values.manufacturedOnMonth)
        ? Number(values.manufacturedOnMonth)
        : null,
      manufacturedOnYear: isNotNil(values.manufacturedOnYear)
        ? Number(values.manufacturedOnYear)
        : null,
      modelSpecification: {
        ...(values.modelSpecification ?? {}),
        seatCount: isNotNil(values?.modelSpecification?.seatCount)
          ? Number(values?.modelSpecification?.seatCount)
          : null,
      },
      vehicleSourceCodeId: values.vehicleSourceCodeId,
    };
  };

  const saveConditionData = async (vehicleId?: string): Promise<void> => {
    if (!vehicleId) {
      throw new Error('Vehicle not found');
    }

    const [condition] = await apiDispatch(getCondition.action, {
      requestBody: {vehicleIds: [vehicleId]},
      inspectionType: InspectionType.vehicleCondition,
      withoutReducer: true,
    });

    const conditionFormValues = getConditionForm()?.getState()?.values;

    if (!isNilOrEmpty(conditionFormValues)) {
      await apiDispatch(updateAudit.action, {
        auditId: condition.id,
        requestBody: getUpdateRequestBodyFromFormData(conditionFormValues),
        withoutReducer: true,
      }).then(() => dispatch(clearAuditFields()));
    }

    if (isNotNil(condition?.id)) {
      await apiDispatch(auditAssetsUploadV2.action, {
        requestBody: {
          auditId: condition.id,
          assets: Object.values(getConditionAssets()),
        },
      }).then(() => dispatch(clearAuditAssets()));
    }
  };

  const updateVehicleHighlightedFeatures = async (vehicle: FullVehicleResponseBody) => {
    const year = vehicle.firstRegistrationOnYear ?? vehicle.manufacturedOnYear;

    if (isNilOrEmpty(vehicle.features) || isNil(year)) {
      return;
    }

    const {data: sourcingFeatures} = await getSourcingFeatures({
      body: {
        make: vehicle.make,
        model: vehicle.modelFamily,
        features: vehicle.features?.map((item) => item.key) ?? [],
        year,
      },
    });

    const features = (vehicle.features ?? []).map((item) => ({
      key: item.key,
      isHighlighted: isTrue(sourcingFeatures?.highlightedFeatures?.includes(item.key)),
      isPremium: isTrue(sourcingFeatures?.premiumFeatures?.includes(item.key)),
    }));

    await updateVehicleFeatures({vehicleId: vehicle.id, features});
  };

  const navigateBackToVehicle = (id: string) => {
    const route = match(vehicleTab)
      .with('vehicle-data', always(vehiclesRoutes.vehicleData))
      .with('features', always(vehiclesRoutes.features))
      .otherwise(always(vehiclesRoutes.detail));

    navigate(composePath(route, {params: {id}}), {
      state: {ignoreUnmountEvents: true},
    });
  };

  const onAfterSubmit = async (vehicleId: string) => {
    if (onCreate) {
      await onCreate(vehicleId);
    } else {
      navigateBackToVehicle(vehicleId);
    }
  };

  const handleCreateVehicle = async (values: CreateVehicleRequestBody): Promise<void> => {
    try {
      if (isCreateMode) {
        const create = isToBuy ? createVehicleToBuyMutation : createVehicleMutation;
        const vehicle = await create({vehicleRequestBody: values}).unwrap();
        await saveConditionData(vehicle?.id);

        if (isFeatureEnabled(featureFlags.VEHICLE_HIGHLIGHTED_FEATURES)) {
          await updateVehicleHighlightedFeatures(vehicle);
        }

        await onAfterSubmit(vehicle.id);
      } else {
        const didVehicleTypeChanged = props.vehicle?.type !== values.type;

        const updateVehicle = async () => {
          await updateVehicleMutation({
            vehicleRequestBody: values,
            vehicleId,
          })
            .unwrap()
            .then(() =>
              dispatch(loadCarDetailsVehicleDetailRequest({vehicleId, shouldClearCacheEntry: true}))
            )
            .then(async () => {
              await onAfterSubmit(vehicleId);
              await updateVehicleAds(vehicleId);
            })
            .catch(handleApiError);
        };

        if (!didVehicleTypeChanged) {
          await updateVehicle();
          return;
        }

        openConfirmDialog({
          text: i18n.t('entity.vehicle.labels.vehicleTypeUpdatedSuccessfully'),
          'data-testid': testIds.vehicles.edit('updateMakeConfirmDialog'),
          onConfirm: async () => {
            const {isSkipped, isSuccess} = await updateVehicleType(values);

            if (isFalse(isSuccess) && isFalse(isSkipped)) {
              openDialog(<VehicleTypeFailedNotification vehicleId={vehicleId} />, {
                size: 'small',
              });
            } else {
              await updateVehicle();
            }
          },
        });
      }
    } catch (error: any) {
      handleApiError(error);
    }
  };

  const handleDecode =
    (form: FormApi<VehicleCreateFormState, Partial<VehicleCreateFormState>>) =>
    (vin: string): Promise<void | VinDecoder> => {
      setDecoding(true);
      return onDecode(vin, form)
        .then((decoded) => decoded)
        .catch((error) => handleApiError(error.response))
        .finally(() => setDecoding(false));
    };

  const isWidgetEmpty = !cebiaAutotracerAndReportConfig?.enabled && !cebiaRokvyConfig?.enabled;
  const isWidgetLoading = isCebiaAutotracerAndReportConfigLoading || isCebiaRokvyConfigLoading;

  return (
    <Form<VehicleCreateFormState, FullVehicle, CreateVehicleRequestBody>
      formId={testIds.vehicles.create('form')}
      // decorators={[persistDecorator]}
      restartOnSubmit={false}
      initialValues={props.vehicle}
      defaultValues={{
        features: [],
        isArchived: false,
        type: VehicleTypeEnumObject.VEHICLETYPE_PASSENGER_CAR,
      }}
      getRequestBody={getRequestBody}
      getFormValues={getFormValues}
      mutators={{
        setVehicleTitle: ([value], state, {changeValue}) => {
          changeValue(state, 'title', () => value);
        },
        clearModel: (_, state, {changeValue}) => {
          changeValue(state, 'modelFamily', always(undefined));
        },
        handleTypeChange: ([newType], state, {changeValue}) => {
          const formStateValues = state?.formState?.values as VehicleCreateFormState;
          const dependentValues = values(pick(TYPE_DEPENDENT_FIELDS, formStateValues));
          const somethingChanged = reduce<boolean, boolean>(
            or,
            false,
            map(Boolean, dependentValues)
          );

          changeValue(state, 'modelFamily', always(undefined));
          changeValue(state, 'make', always(undefined));
          changeValue(state, 'features', always([]));

          setConfirmOpen(formStateValues.type !== newType && somethingChanged);
        },
        changeType: ([type], state, {changeValue}) => {
          if (isCreateMode) {
            changeValue(state, 'type', () => type);
          }
        },
        resetTypeDependentFields: (_, state, {changeValue}) => {
          for (const field of TYPE_DEPENDENT_FIELDS) {
            changeValue(state, field, always(undefined));
          }
        },
        createCustomMake: ([vehicleType, customMake], state, {changeValue}) => {
          const makeKey = customMake;
          openConfirmDialog({
            text: i18n.t('entity.vehicle.labels.createCustomMakeConfirm', {make: customMake}),
            onConfirm: () => {
              dispatch(createCustomMake({makeKey, vehicleType}));
              changeValue(state, 'make', () => makeKey);
              changeValue(state, 'modelFamily', always(undefined));
            },
            'data-testid': testIds.vehicles.create('createCustomMakeConfirmDialog'),
          });
        },
        createCustomModel: (
          [vehicleType, makeKey, customModelOptionName],
          state,
          {changeValue}
        ) => {
          openConfirmDialog({
            text: i18n.t('entity.vehicle.labels.createCustomModelConfirm', {
              model: customModelOptionName,
            }),
            onConfirm: () => {
              dispatch(
                createCustomModel({
                  makeKey,
                  modelKey: customModelOptionName,
                  vehicleType,
                })
              );
              changeValue(state, 'modelFamily', () => customModelOptionName);
            },
            'data-testid': testIds.vehicles.create('createCustomModelConfirmDialog'),
          });
        },
        setManufactureDate: (
          [manufacturedOnYear, manufacturedOnMonth]: [number | null, number | null],
          state,
          {changeValue}
        ) => {
          changeValue(
            state,
            'manufacturedOnYear',
            () => manufacturedOnYear?.toString() ?? undefined
          );
          changeValue(
            state,
            'manufacturedOnMonth',
            () => manufacturedOnMonth?.toString() ?? undefined
          );
        },
      }}
      onSubmit={handleCreateVehicle}
      schema={$VehicleCreateFormState}
      validateAfterSubmit
      render={({handleSubmit, form, Subscribe}) => (
        <form onSubmit={noop}>
          <VehicleFormModal
            handleSubmit={handleSubmit}
            triggerScroll={triggerScroll}
            makeSaleVehicleAvailableForSale={props.makeSaleVehicleAvailableForSale}
          >
            <Grid columns={12}>
              <GridItem span={8}>
                <Grid columns={1}>
                  <Section
                    cyId={testIds.vehicles.create('vin-section')}
                    scrollRef={refs[RefsKeys.VIN]}
                  >
                    <VinAndLicensePlate
                      excludeId={props.vehicle?.id}
                      onDecode={handleDecode(form)}
                      createMode={isCreateMode}
                      onVehicleSelect={props.onVehicleSelect}
                      decoding={decoding}
                      selectVehicleToPurchase={props.selectVehicleToPurchase}
                      data-testid={suffixTestId('vinAndLicensePlate', props)}
                    />

                    <InfoForm data-testid={testIds.vehicles.create('info-section')} />

                    <ModelSpecification />
                  </Section>

                  <Section
                    title={i18n.t('general.labels.additionalInformation')}
                    cyId={testIds.vehicles.create('additional-info-section')}
                    scrollRef={refs[RefsKeys.ADD_INFO]}
                  >
                    <AdditionalInformation />
                  </Section>
                </Grid>
              </GridItem>
              <GridItem span={4}>
                <Box position="sticky" top={0}>
                  <Card
                    title={i18n.t('entity.vehicle.labels.integrations')}
                    actions={[
                      {
                        variant: 'link',
                        type: 'button',
                        leftIcon: 'action/settings',
                        onClick: () => navigate(settingsRoutes.vehicleServices),
                        'data-testid': testIds.vehicles.create('integrationSettingsButton'),
                        title: i18n.t('general.labels.settings'),
                      },
                    ]}
                    isExpandable
                    data-testid={testIds.vehicles.create('integrations-expanded-card')}
                  >
                    <VStack spacing={3}>
                      <DataStatus
                        isEmpty={isWidgetEmpty}
                        isLoading={isWidgetLoading}
                        emptyMessage={i18n.t('entity.vehicle.emptyIntegration.noServicesAvailable')}
                        emptySubheadline={i18n.t(
                          'entity.vehicle.emptyIntegration.enableInSettings'
                        )}
                      >
                        <Show when={cebiaAutotracerAndReportConfig?.enabled}>
                          <Subscribe
                            name="vin"
                            component={({input: vin}) => (
                              <Subscribe
                                name="make"
                                component={({input: make}) => (
                                  <Subscribe
                                    name="modelFamily"
                                    component={({input: modelFamily}) => (
                                      <CebiaAutotracerWidget
                                        data-testid={testIds.vehicles.create(
                                          'cebiaAutotracerWidget'
                                        )}
                                        vehicle={{
                                          id: vehicleId,
                                          vin: vin.value,
                                          make: make.value,
                                          modelFamily: modelFamily.value,
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            )}
                          />
                        </Show>
                        <Show when={cebiaRokvyConfig?.enabled}>
                          <Subscribe
                            name="vin"
                            component={({input: vin}) => (
                              <Subscribe
                                name="manufacturedOnYear"
                                component={({input: manufacturedOnYear}) => (
                                  <Subscribe
                                    name="manufacturedOnMonth"
                                    component={({input: manufacturedOnMonth}) => (
                                      <CebiaProductionYearWidget
                                        data-testid={testIds.vehicles.create(
                                          'cebiaProductionYearWidget'
                                        )}
                                        vehicle={{
                                          id: vehicleId,
                                          vin: vin.value,
                                          manufacturedOnYear: Number(manufacturedOnYear.value),
                                          manufacturedOnMonth: Number(manufacturedOnMonth.value),
                                        }}
                                        onSuccess={(response) => {
                                          form.mutators.setManufactureDate(
                                            response?.manufacturedOnYear,
                                            response?.manufacturedOnMonth
                                          );
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            )}
                          />
                        </Show>
                      </DataStatus>
                    </VStack>
                  </Card>
                </Box>
              </GridItem>
            </Grid>
            <VehicleDataSection refs={refs} />
            <Grid columns={12}>
              <GridItem span={8}>
                <Section
                  data-testid={testIds.vehicles.create('features-section')}
                  title={i18n.t('general.labels.allFeatures')}
                >
                  <Features data-testid={testIds.vehicles.create('features')} />
                  <Show whenFeatureEnabled={featureFlags.SALES_FEATURES_ADDITIONAL_INFO}>
                    <Separator />
                    <FeaturesAdditionalInfo />
                  </Show>
                </Section>
              </GridItem>
              <GridItem span={4}>
                <Box>
                  <VStack spacing={4}>
                    <Subscribe
                      name="vin"
                      component={({input}) => (
                        <Cebia
                          data-testid={testIds.vehicles.create('vin')}
                          vin={input.value}
                          onSuccess={(features) => form.change('features', features)}
                        />
                      )}
                    />
                    <FeaturesHighlights />
                  </VStack>
                </Box>
              </GridItem>
            </Grid>
            <PhotosTab vehicleId={!isCreateMode ? vehicleId : undefined} />
            {isCreateMode && <ConditionTab vehicleId={props.clonedVehicleId} />}
          </VehicleFormModal>
          {isCreateMode && (
            <Subscribe
              name="type"
              component={({input: {value}}) => (
                <ConfirmDialog
                  onConfirm={() => {
                    setConfirmOpen(false);
                  }}
                  onClose={() => {
                    setConfirmOpen(false);
                  }}
                  isOpen={confirmOpen}
                  type={value}
                  data-testid={testIds.vehicles.create('createVehicleTypeChangeDialog')}
                />
              )}
            />
          )}
        </form>
      )}
    />
  );
}

const Section: FC<
  PropsWithChildren<{
    readonly title?: string;
    readonly scrollRef?: ForwardedRef<HTMLDivElement>;
    cyId?: string;
  }>
> = ({children, title, scrollRef, cyId}) => (
  <Card title={title} data-testid={`${cyId}-${title}-wrapper`}>
    <div ref={scrollRef} />
    {children}
  </Card>
);
