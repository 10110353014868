import {css} from 'styled-components';

import {ReactNode} from 'react';

import {Nullish} from 'shared';

import {useResponsivePropValue} from '../../../hooks/useResponsivePropValue';
import {Integer} from '../../../types/Integer';
import {ValueByDevice} from '../../../types/ValueByDevice';

export interface GridItemProps {
  span?: Integer | ValueByDevice<Integer> | Nullish;
  children?: ReactNode;
}

export function GridItem(props: GridItemProps) {
  const span = useResponsivePropValue(props.span);

  // Prevent content from expanding grid items
  // https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
  return (
    <div
      css={css`
        grid-column-end: ${span ? `span ${span}` : 'initial'};
        min-width: 0;
      `}
    >
      {props.children}
    </div>
  );
}
