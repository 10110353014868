import {generateFileName} from './generateFileName';
import {getFileNameFromUrl} from './getFileNameFromUrl';
import {getImageFileFormatFromHeaders} from './getImageFileFormatFromHeaders';
import {hasFileFormatInFileName} from './hasFileFormatInFileName';

export const getImageFileNameWithFormat = (url: string, responseHeaders: Headers) => {
  const urlFileName = getFileNameFromUrl(url);

  if (urlFileName && hasFileFormatInFileName(urlFileName)) {
    return urlFileName;
  }

  const fileFormat = getImageFileFormatFromHeaders(responseHeaders);

  if (!fileFormat) {
    throw new Error('File format could not be determined.');
  }

  const fileName = urlFileName ? `${urlFileName}.${fileFormat}` : generateFileName(fileFormat);

  return fileName;
};
