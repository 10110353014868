import {parseDate, Nullish} from 'shared';

/**
 * @about Utility function to get locale time in HH:MM from ISO string
 * @returns Locale time in HH:MM format
 * @example
 *  const date = 2023-06-30T15:30:00Z
 *
 *  const requestExpiresAtTime = getDefaultRequestExpiresAtTime(date);
 *  /=>'17:30'
 */
export const getDefaultRequestExpiresAtTime = (date: string | Nullish) => {
  if (!date) {
    return;
  }

  return parseDate(date).toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
};
