import {FC, PropsWithChildren} from 'react';

import {testIds} from '@omnetic-dms/routes';

import {ProfileLayout} from '../../../components/ProfileLayout/ProfileLayout';
import {Profile} from './components/Profile';

export const PersonalInformation: FC<PropsWithChildren<any>> = () => (
  <ProfileLayout data-testid={testIds.myProfile.personalInformation('wrapper')}>
    <Profile />
  </ProfileLayout>
);
