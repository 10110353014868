export enum FileDataTypeEnum {
  PURCHASE_CONTRACT = 'PURCHASE_CONTRACT',
  PURCHASE_POWER_OF_ATTORNEY = 'PURCHASE_POWER_OF_ATTORNEY',
  PURCHASE_HANDOVER_PROTOCOL = 'PURCHASE_HANDOVER_PROTOCOL',
  PURCHASE_BROKERAGE_HANDOVER_PROTOCOL = 'PURCHASE_BROKERAGE_HANDOVER_PROTOCOL',
  SALE_OFFER = 'SALE_OFFER',
  SALE_CONTRACT = 'SALE_CONTRACT',
  SALE_POWER_OF_ATTORNEY = 'SALE_POWER_OF_ATTORNEY',
  SALE_HANDOVER_PROTOCOL = 'SALE_HANDOVER_PROTOCOL',
  SALE_BROKERAGE_CONTRACT = 'SALE_BROKERAGE_CONTRACT',
  PAYMENT_ATTACHMENT = 'PAYMENT_ATTACHMENT',
  PAYMENT_CASH_RECEIPT = 'PAYMENT_CASH_RECEIPT',
  PAYMENT_CORRECTIVE_CASH_RECEIPT = 'PAYMENT_CORRECTIVE_CASH_RECEIPT',
  PAYMENT_INVOICE = 'PAYMENT_INVOICE',
  PAYMENT_PROFORMA_INVOICE = 'PAYMENT_PROFORMA_INVOICE',
  PAYMENT_TAX_DOCUMENT = 'PAYMENT_TAX_DOCUMENT',
  PURCHASE_BROKERAGE_CONTRACT = 'PURCHASE_BROKERAGE_CONTRACT',
  VEHICLE_CONDITION_PROTOCOL = 'VEHICLE_CONDITION_PROTOCOL',
  GDPR_MARKETING_CONSENT = 'GDPR_MARKETING_CONSENT',
  PURCHASE_BROKERAGE_POWER_OF_ATTORNEY = 'PURCHASE_BROKERAGE_POWER_OF_ATTORNEY',
  CORRECTIVE_TAX_DOCUMENT = 'CORRECTIVE_TAX_DOCUMENT',
  OFFSETTING_OF_LIABILITIES_AND_RECEIVABLES = 'OFFSETTING_OF_LIABILITIES_AND_RECEIVABLES',
  INTERNAL_INVOICE_DOCUMENT = 'INTERNAL_INVOICE_DOCUMENT',
}
