import {match} from 'ts-pattern';
import {array, object, string} from 'yup';

import {always, isNil} from 'ramda';

import {ScopeValues} from '@omnetic-dms/api';

export const evaluateParticipation = (
  scopes: string[],
  evaluatorParams: {
    participation: ScopeValues | undefined;
    currentUserId: string | undefined;
  }
) => {
  if (isNil(evaluatorParams.currentUserId) || isNil(evaluatorParams.participation)) {
    return false;
  }

  const {assignees, author, owner} = validateParticipationEvaluatorParams(
    evaluatorParams.participation
  );

  const currentUserId = evaluatorParams.currentUserId;

  return scopes.some((scope) =>
    match(scope)
      .with('AUTHOR', always(author.userId === currentUserId))
      .with('ASSIGNEE', always(assignees?.some(({userId}) => userId === currentUserId)))
      .with('OWNER', always(owner.userId === currentUserId))
      .otherwise(always(false))
  );
};

export const validateParticipationEvaluatorParams = (
  evaluatorParams: Record<string, unknown> | undefined
) => participationParamsSchema.validateSync(evaluatorParams);

const participationParamsSchema = object({
  assignees: array().of(
    object({
      type: string(),
      userId: string().nullable(),
      organizationalUnitId: string().nullable(),
    })
  ),
  owner: object({
    type: string(),
    userId: string().nullable(),
    organizationalUnitId: string().nullable(),
  }),
  author: object({
    type: string(),
    userId: string().nullable(),
  }),
});
