import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {
  Button,
  ButtonGroup,
  Card,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {HStack, VStack, Grid} from 'platform/foundation';

import {useState} from 'react';

import {isNotNil} from 'ramda';

import {
  useGetCustomersV2Query,
  useGetVehicleInsuranceQuery,
  useSetVehicleInsuranceMutation,
} from '@omnetic-dms/api';
import {i18n} from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {VehicleInsuranceFormValues} from '../types/VehicleInsuranceFormValues';
import {mapApiValues, mapFormValues} from '../utils/mapFormValues';
import {vehicleInsuranceFormSchema} from '../utils/vehicleInsuranceFormSchema';

interface VehicleInsuranceEditModalProps {
  vehicleId: string;
}

export function VehicleInsuranceEditModal({vehicleId}: VehicleInsuranceEditModalProps) {
  const {data: insuranceCompanies, isLoading: isInsuranceCompaniesLoading} = useGetCustomersV2Query(
    {
      institutions: ['INSURANCE'],
    }
  );
  const insuranceOptions = insuranceCompanies?.map((company) => ({
    label: company?.businessInfo?.businessInfo?.tradeName,
    value: company.id,
  }));
  const {
    data: insuranceData,
    isLoading: isInsuranceDataLoading,
    isError,
    error,
  } = useGetVehicleInsuranceQuery({vehicleId});
  const [setInsuranceData] = useSetVehicleInsuranceMutation();
  const [hasCustomerInsurance, setCustomerInsurance] = useState(
    isNotNil(insuranceData?.customerInsurance)
  );
  const [hasFleetInsurance, setFleetInsurance] = useState(isNotNil(insuranceData?.fleetInsurance));

  const onSubmit: FormSubmitHandler<VehicleInsuranceFormValues> = async (values) => {
    await setInsuranceData({
      vehicleId,
      data: mapApiValues(values),
    })
      .unwrap()
      .then(() => {
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus
      isLoading={isInsuranceDataLoading}
      isError={isError && (error as FetchBaseQueryError)?.status !== 404}
    >
      <Form<VehicleInsuranceFormValues>
        onSubmit={onSubmit}
        schema={vehicleInsuranceFormSchema}
        defaultValues={mapFormValues(insuranceData)}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <Card
              variant="inlineGrey"
              title={i18n.t('entity.vehicle.labels.customerInsurance')}
              control={{
                type: 'switch',
                onChange: (value) => {
                  setCustomerInsurance(value);
                  formApi.setValue('isCustomerInsuranceEnabled', value);
                },
                value: hasCustomerInsurance,
              }}
              isExpanded={hasCustomerInsurance}
            >
              <VStack spacing={4}>
                <HStack spacing={4}>
                  <FormField
                    label={i18n.t('entity.vehicle.labels.liabilityInsuranceCompany')}
                    name="liabilityInsuranceCompany"
                    type="choice"
                    control={control}
                    options={insuranceOptions}
                    isLoading={isInsuranceCompaniesLoading}
                  />
                  <FormField
                    label={i18n.t('entity.vehicle.labels.liabilityInsuranceDate')}
                    name="liabilityInsuranceDate"
                    type="date"
                    control={control}
                  />
                  <FormField
                    label={i18n.t('entity.vehicle.labels.liabilityInsuranceNumber')}
                    name="liabilityInsuranceNumber"
                    type="text"
                    control={control}
                  />
                </HStack>
                <HStack spacing={4}>
                  <FormField
                    label={i18n.t('entity.vehicle.labels.collisionInsuranceCompany')}
                    name="collisionInsuranceCompany"
                    type="choice"
                    control={control}
                    options={insuranceOptions}
                    isLoading={isInsuranceCompaniesLoading}
                  />
                  <FormField
                    label={i18n.t('entity.vehicle.labels.collisionInsuranceDate')}
                    name="collisionInsuranceDate"
                    type="date"
                    control={control}
                  />
                  <FormField
                    label={i18n.t('entity.vehicle.labels.collisionInsuranceNumber')}
                    name="collisionInsuranceNumber"
                    type="text"
                    control={control}
                  />
                </HStack>
                <FormField
                  label={i18n.t('general.labels.note')}
                  name="customerInsuranceNote"
                  control={control}
                  type="textarea"
                />
              </VStack>
            </Card>
            <Card
              variant="inlineGrey"
              title={i18n.t('entity.vehicle.labels.fleetInsurance')}
              control={{
                type: 'switch',
                onChange: (value) => {
                  setFleetInsurance(value);
                  formApi.setValue('isFleetInsuranceEnabled', value);
                },
                value: hasFleetInsurance,
              }}
              isExpanded={hasFleetInsurance}
            >
              <VStack spacing={4}>
                <Grid columns={3}>
                  <FormField
                    label={i18n.t('entity.vehicle.labels.fleetInsuranceDate')}
                    name="fleetInsuranceDate"
                    control={control}
                    type="date"
                  />
                </Grid>
                <FormField
                  label={i18n.t('general.labels.note')}
                  name="fleetInsuranceNote"
                  control={control}
                  type="textarea"
                  isResizable={true}
                />
              </VStack>
            </Card>
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                onClick={closeCurrentDialog}
                variant="secondary"
              />
              <FormButton title={i18n.t('general.actions.save')} type="submit" control={control} />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
