import {z} from 'zod';

import {BaseVoidRequestSchema, BaseVoidResponseSchema} from './base';

const IdResponseSchema = z.object({
  id: z.string().nullable().optional(),
});

export const PostMaterialDiscountGroupApiResponseSchema = IdResponseSchema.nullable();

export type PostMaterialDiscountGroupApiResponse = z.infer<
  typeof PostMaterialDiscountGroupApiResponseSchema
>;
export type PostMaterialDiscountGroupItemApiResponse = z.infer<
  typeof PostMaterialDiscountGroupApiResponseSchema
>;

export const PostMaterialDiscountGroupApiArgSchema = z.object({
  body: z
    .object({
      name: z.string(),
      code: z.string().nullable().optional(),
    })
    .nullable(),
});

export type PostMaterialDiscountGroupApiArg = z.infer<typeof PostMaterialDiscountGroupApiArgSchema>;

export const GetMaterialDiscountGroupByIdApiResponseSchema = z
  .object({
    id: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    code: z.string().nullable().optional(),
  })
  .nullable();

export type GetMaterialDiscountGroupByIdApiResponse = z.infer<
  typeof GetMaterialDiscountGroupByIdApiResponseSchema
>;

export const GetMaterialDiscountGroupByIdApiArgSchema = z.object({
  groupId: z.string(),
});

export type GetMaterialDiscountGroupByIdApiArg = z.infer<
  typeof GetMaterialDiscountGroupByIdApiArgSchema
>;

export type DeleteMaterialDiscountGroupApiResponse = z.infer<typeof BaseVoidResponseSchema>;
export type PatchMaterialDiscountGroupApiResponse = z.infer<typeof BaseVoidResponseSchema>;
export type DeleteMaterialDiscountGroupItemApiResponse = z.infer<typeof BaseVoidResponseSchema>;
export type PatchMaterialDiscountGroupItemApiResponse = z.infer<typeof BaseVoidResponseSchema>;

export const DeleteMaterialDiscountGroupApiArgSchema = z.object({
  groupId: z.string(),
});

export type DeleteMaterialDiscountGroupApiArg = z.infer<
  typeof DeleteMaterialDiscountGroupApiArgSchema
>;

export const PatchMaterialDiscountGroupApiArgSchema = z.object({
  groupId: z.string(),
  body: z
    .object({
      name: z.string(),
      code: z.string().nullable().optional(),
    })
    .nullable(),
});

export type PatchMaterialDiscountGroupApiArg = z.infer<
  typeof PatchMaterialDiscountGroupApiArgSchema
>;

export const GetMaterialDiscountGroupItemByIdApiResponseSchema = z
  .object({
    id: z.string().nullable().optional(),
    groupId: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    warehouseId: z.string().nullable().optional(),
    discountGroupIds: z.array(z.string().nullable()).optional(),
    discountType: z.enum(['DISCOUNT_SALE_PRICE', 'DISCOUNT_PURCHASE_PRICE']).nullable().optional(),
    discountRatio: z.number().nullable().optional(),
  })
  .nullable();

export type GetMaterialDiscountGroupItemByIdApiResponse = z.infer<
  typeof GetMaterialDiscountGroupItemByIdApiResponseSchema
>;

export const DeleteMaterialDiscountGroupItemApiArgSchema = z.object({
  groupId: z.string(),
  discountId: z.string(),
});

export type DeleteMaterialDiscountGroupItemApiArg = z.infer<
  typeof DeleteMaterialDiscountGroupItemApiArgSchema
>;

export const GetMaterialDiscountGroupsApiArgSchema = BaseVoidRequestSchema;

export type GetMaterialDiscountGroupsApiArg = z.infer<typeof GetMaterialDiscountGroupsApiArgSchema>;

export const GetMaterialDiscountGroupItemByIdApiArgSchema = z.object({
  groupId: z.string(),
  discountId: z.string(),
});

export type GetMaterialDiscountGroupItemByIdApiArg = z.infer<
  typeof GetMaterialDiscountGroupItemByIdApiArgSchema
>;

export const PatchMaterialDiscountGroupItemApiArgSchema = z.object({
  groupId: z.string(),
  discountId: z.string(),
  body: z
    .object({
      name: z.string().optional(),
      warehouseId: z.string().nullable().optional(),
      discountGroupIds: z.array(z.string().nullable()).optional(),
      discountType: z.string().nullable().optional(),
      discountRatio: z.number().nullable().optional(),
    })
    .nullable(),
});

export type PatchMaterialDiscountGroupItemApiArg = z.infer<
  typeof PatchMaterialDiscountGroupItemApiArgSchema
>;

export const PostMaterialDiscountGroupItemApiArgSchema = z.object({
  materialDiscountGroupId: z.string().nullable(),
  body: z
    .object({
      name: z.string(),
      warehouseId: z.string().optional(),
      discountGroupIds: z.array(z.string().nullable()).optional(),
      discountType: z.enum(['DISCOUNT_SALE_PRICE', 'DISCOUNT_PURCHASE_PRICE']),
      discountRatio: z.number(),
    })
    .nullable(),
});

export type PostMaterialDiscountGroupItemApiArg = z.infer<
  typeof PostMaterialDiscountGroupItemApiArgSchema
>;

export const GetMaterialDiscountGroupsApiResponseSchema = z.array(
  z
    .object({
      id: z.string().nullable().optional(),
      name: z.string().nullable().optional(),
      code: z.string().nullable().optional(),
      isActive: z.boolean().nullable().optional(),
    })
    .nullable()
);

export type GetMaterialDiscountGroupsApiResponse = z.infer<
  typeof GetMaterialDiscountGroupsApiResponseSchema
>;

export const GetMaterialDiscountGroupDiscountsApiResponseSchema = z
  .object({
    materialDiscountGroup: z
      .array(
        z
          .object({
            id: z.string().nullable().optional(),
            name: z.string().nullable().optional(),
            code: z.string().nullable().optional(),
            discounts: z
              .array(
                z
                  .object({
                    id: z.string().nullable().optional(),
                    name: z.string().nullable().optional(),
                    warehouseId: z.string().nullable().optional(),
                    discountGroupIds: z.array(z.string().nullable()).optional(),
                    discountType: z
                      .enum(['DISCOUNT_SALE_PRICE', 'DISCOUNT_PURCHASE_PRICE'])
                      .nullable()
                      .optional(),
                    discountRatio: z.number().nullable().optional(),
                  })
                  .nullable()
              )
              .optional(),
          })
          .nullable()
      )
      .optional(),
  })
  .nullable();

export type GetMaterialDiscountGroupDiscountsApiResponse = z.infer<
  typeof GetMaterialDiscountGroupDiscountsApiResponseSchema
>;

export const GetMaterialDiscountGroupDiscountsApiArgSchema = z.object({
  groupId: z.string(),
});

export type GetMaterialDiscountGroupDiscountsApiArg = z.infer<
  typeof GetMaterialDiscountGroupDiscountsApiArgSchema
>;

export type PostMaterialDiscountGroupSetActiveApiResponse = z.infer<typeof BaseVoidResponseSchema>;

export type PostMaterialDiscountGroupSetInActiveApiResponse = z.infer<
  typeof BaseVoidResponseSchema
>;

export const PostMaterialDiscountGroupSetActiveApiArgSchema = z.object({
  groupId: z.string(),
});
export type PostMaterialDiscountGroupSetActiveApiArg = z.infer<
  typeof PostMaterialDiscountGroupSetActiveApiArgSchema
>;

export const PostMaterialDiscountGroupSetInActiveApiArgSchema = z.object({
  groupId: z.string(),
});
export type PostMaterialDiscountGroupSetInActiveApiArg = z.infer<
  typeof PostMaterialDiscountGroupSetInActiveApiArgSchema
>;
