import {format} from 'date-fns';
import {Tooltip} from 'platform/components';
import {Heading} from 'platform/foundation';
import {NumberFormatter} from 'platform/locale';
import {css} from 'styled-components';

import {path} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';
import {
  ColorizedText,
  decimalToPercentRank,
  FeaturesType,
  FlexContainer,
  Rank,
  SourcingColor,
  SourcingVehicleType,
  VatReclaimable,
} from '@omnetic-dms/teas';

import {parseDate} from 'shared';

import {FeatureList} from '../../../components/FeatureList/FeatureList';
import {ComparisonSection, ComparisonSectionItem} from '../../../types/ComparisonSection';
import {MinMaxVehicles} from '../../../types/MinMaxVehicles';
import {getLID} from '../../../utils/getLID';
import {getVehicleDetailPosition} from '../../../utils/getVehicleDetailPosition';
import {getVehiclePrice} from '../../../utils/getVehiclePrice';

export const getComparisonSections = (
  formatNumber: NumberFormatter,
  {
    minMaxVehicles: {
      minOriginalPrice,
      maxOriginalPrice,
      minMileage,
      maxMileage,
      minManufactureYear,
      maxManufactureYear,
    },
  }: {
    minMaxVehicles: Partial<MinMaxVehicles<SourcingVehicleDetailResponseBody>>;
  }
): ComparisonSection[] => [
  {
    key: 'essentials',
    Header: <Heading size={6}>{i18n.t('entity.vehicle.labels.essentials')}</Heading>,
    items: [
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.position')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehiclePosition = getVehicleDetailPosition(
            vehicle.summary?.position ?? vehicle.sourcingVehicle?.extraData?.marketPosition ?? 0,
            vehicle.summary?.positionFrom ??
              vehicle.sourcingVehicle?.extraData?.marketPositionCount ??
              0
          );

          return (
            <>
              {vehiclePosition[0]} {vehiclePosition[1]}
            </>
          );
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.adPrice')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehicleData = vehicle.sourcingVehicle;

          return (
            <FlexContainer>
              {vehicleData?.price?.vatReclaimable ? (
                <Tooltip
                  placement="top"
                  label={i18n.t('entity.vehicle.labels.tooltipVatReclaimable')}
                >
                  <VatReclaimable>{i18n.t('entity.warehouse.labels.articleVat')}</VatReclaimable>
                </Tooltip>
              ) : null}

              <Tooltip
                placement="top"
                label={
                  <span>
                    {formatNumber(
                      (vehicleData as SourcingVehicleType)?.price?.originalWithVat ?? 0, // PO has to solve for auctions,
                      0
                    )}{' '}
                    {vehicleData?.price?.originalCurrency?.translation}
                  </span>
                }
              >
                <ColorizedText
                  red={vehicleData?.id === maxOriginalPrice?.sourcingVehicle?.id}
                  green={vehicleData?.id === minOriginalPrice?.sourcingVehicle?.id}
                >
                  {formatNumber(
                    getVehiclePrice({
                      vehicle,
                    }),
                    0
                  )}{' '}
                  {vehicleData?.price?.currency?.translation}
                </ColorizedText>
              </Tooltip>
            </FlexContainer>
          );
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.rankEquipment')}
          </Heading>
        ),
        Cell: ({summary}: SourcingVehicleDetailResponseBody) => (
          <FlexContainer>
            <Rank
              rank={summary?.rank ?? undefined}
              data-testid={testIds.sourcing.vehicleDetail('comparison-rankEquipment')}
            />
            <span
              css={css`
                margin-left: 8px;
              `}
            >
              {decimalToPercentRank(summary?.rank ?? undefined)}
            </span>
          </FlexContainer>
        ),
      },
    ],
  },
  {
    key: 'documents',
    Header: <Heading size={6}>{i18n.t('general.labels.documents')}</Heading>,
    items: [
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.registration')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehicleData = vehicle.sourcingVehicle;
          return vehicleData?.occurrence?.first
            ? format(parseDate(vehicleData?.occurrence?.first), 'MM/yy')
            : EMPTY_PLACEHOLDER;
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.STK')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehicleData = vehicle.sourcingVehicle as SourcingVehicleType; // PO has to solve for auctions;
          return vehicleData?.technicalInspectionValidTo
            ? format(parseDate(vehicleData?.technicalInspectionValidTo), 'MM/yy')
            : EMPTY_PLACEHOLDER;
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.serviceBook')}
          </Heading>
        ),
        Cell: ({features}: SourcingVehicleDetailResponseBody) =>
          features?.FEATURE_SERVICE_BOOK
            ? i18n.t('general.labels.yes')
            : i18n.t('general.labels.no'),
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.mileage')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehicleData = vehicle.sourcingVehicle;

          return (
            <ColorizedText
              red={vehicleData?.id === maxMileage?.sourcingVehicle?.id}
              green={vehicleData?.id === minMileage?.sourcingVehicle?.id}
            >
              {vehicleData?.mileage != undefined && formatNumber(vehicleData?.mileage, 0)}{' '}
              {isNotNil(vehicleData?.mileage) ? i18n.t('general.metric.km') : null}
            </ColorizedText>
          );
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.manufactureYear')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehicleData = vehicle.sourcingVehicle;
          return (
            <ColorizedText
              red={vehicleData?.id === minManufactureYear?.sourcingVehicle?.id}
              green={vehicleData?.id === maxManufactureYear?.sourcingVehicle?.id}
            >
              {vehicleData?.year ?? EMPTY_PLACEHOLDER}
            </ColorizedText>
          );
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.countryOfOrigin')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehicleData = vehicle.sourcingVehicle;
          return (
            path(['originCountry', 'translation'], vehicleData) ??
            path(['seller', 'country', 'translation'], vehicleData) ??
            EMPTY_PLACEHOLDER
          );
        },
      },
    ],
  },
  {
    key: 'performance',
    Header: <Heading size={6}>{i18n.t('entity.vehicle.labels.performance')}</Heading>,
    items: [
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.fuelType')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) =>
          vehicle.sourcingVehicle?.fuelType?.translation,
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.power')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) =>
          vehicle.sourcingVehicle?.power ?? EMPTY_PLACEHOLDER,
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.engineCapacity')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehicleData = vehicle.sourcingVehicle as SourcingVehicleType; // PO has to solve for auctions
          return formatNumber(vehicleData?.cubicCapacity, 0);
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.transmission')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) =>
          vehicle.sourcingVehicle?.transmission?.translation ?? EMPTY_PLACEHOLDER,
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.driveType')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) =>
          vehicle.sourcingVehicle?.driveType?.translation ?? EMPTY_PLACEHOLDER,
      },
    ],
  },
  {
    key: 'purchaseInfo',
    Header: <Heading size={6}>{i18n.t('entity.vehicle.labels.purchaseInfo')}</Heading>,
    items: [
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.originalPrice')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const displayPrice = vehicle.sourcingVehicle.price?.withVat;
          return <div>{formatNumber(displayPrice, 0)}</div>;
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.purchasePrice')}
          </Heading>
        ),
        Cell: ({vehicleSummary}: SourcingVehicleDetailResponseBody) => (
          <div>{formatNumber(vehicleSummary?.purchasePrice, 0)}</div>
        ),
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.margin')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehicleData = vehicle.sourcingVehicle as SourcingVehicleType;

          return (
            <div>
              {formatNumber(
                vehicleData?.maximalPossibleMargin ?? -(vehicle?.summary?.original ?? 0),
                0
              )}
            </div>
          );
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.retailPrice')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehiclePrice = getVehiclePrice({
            vehicle,
          });

          return <div>{formatNumber(vehiclePrice, 0)}</div>;
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.costsPriceCZK')}
          </Heading>
        ),
        Cell: ({summary}: SourcingVehicleDetailResponseBody) => {
          const displayCosts = summary?.vehicleCosts;

          return <div>{formatNumber(displayCosts, 0)}</div>;
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.LID')}
          </Heading>
        ),
        Cell: ({summary}: SourcingVehicleDetailResponseBody) =>
          isNotNil(summary?.lid) ? getLID(summary?.lid) : 'N/A',
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.DOD')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const displayDays = path<number>(['sourcingVehicle', 'daysOnStock'], vehicle);

          return <div>{formatNumber(displayDays, 0) ?? EMPTY_PLACEHOLDER}</div>;
        },
      },
    ].filter((x) => x) as ComparisonSectionItem[],
  },
  {
    key: 'carLook',
    Header: <Heading size={6}>{i18n.t('entity.vehicle.labels.carLook')}</Heading>,
    items: [
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.bodyColor')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehicleData = vehicle.sourcingVehicle;
          return (
            <FlexContainer>
              {vehicleData?.color?.color?.key ? (
                <SourcingColor
                  color={vehicleData?.color?.color?.key.split('COLOR_')[1].toLowerCase()}
                />
              ) : null}

              {vehicleData?.color?.color?.translation ?? EMPTY_PLACEHOLDER}
            </FlexContainer>
          );
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.numberOfSeats')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) => {
          const vehicleData = vehicle.sourcingVehicle as SourcingVehicleType; // PO has to solve for auctions
          return vehicleData?.numberOfSeats ?? EMPTY_PLACEHOLDER;
        },
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.carStyle')}
          </Heading>
        ),
        Cell: (vehicle: SourcingVehicleDetailResponseBody) =>
          vehicle.sourcingVehicle?.carStyle?.translation ?? EMPTY_PLACEHOLDER,
      },
    ],
  },
  {
    key: 'features',
    Header: <Heading size={6}>{i18n.t('entity.vehicle.labels.features')}</Heading>,
    items: [
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('entity.vehicle.labels.highlighted')}
          </Heading>
        ),
        Cell: ({features}: SourcingVehicleDetailResponseBody) => {
          const highlightedOrPremiumFeatures = Object.values(features || {}).filter(
            ({isAvailable, isHighlight, isPremium}: FeaturesType) =>
              isAvailable && (isHighlight || isPremium)
          );

          return (
            <FeatureList
              css={css`
                overflow: auto;
                height: calc(10 * 24px);
              `}
              features={highlightedOrPremiumFeatures as FeaturesType[]}
            />
          );
        },
        tdCss: css`
          padding: 0;
          vertical-align: top;
        `,
        subtitleCss: css`
          vertical-align: top;

          > h5 {
            padding: 4px 0;
          }
        `,
      },
      {
        Header: (
          <Heading size={6} alternative>
            {i18n.t('general.labels.allFeatures')}
          </Heading>
        ),
        Cell: ({features}: SourcingVehicleDetailResponseBody) => (
          <FeatureList highlightOnlyPremium={false} features={Object.values(features || {})} />
        ),
        tdCss: css`
          padding: 0;
          vertical-align: top;
          background-color: ${({theme}) => theme.colors.palettes.white[10][100]} !important;
        `,
        subtitleCss: css`
          vertical-align: top;

          > h5 {
            padding: 4px 0;
          }
        `,
      },
    ],
  },
];
