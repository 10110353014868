import {
  Button,
  FormControl,
  FormField,
  IconButton,
  UnsafeFieldAssertionPath,
} from 'platform/components';
import {Align, Box, HStack} from 'platform/foundation';

import {ArrayPath, FieldValues, Path, useFieldArray, UseFormReturn} from 'react-hook-form';

import {any} from 'ramda';
import {isNilOrEmpty, lengthLt} from 'ramda-adjunct';

import {EmailData} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

type EmailListProps<TFieldValues extends FieldValues = FieldValues> = {
  control: FormControl<TFieldValues>;
  formApi: UseFormReturn<TFieldValues>;
  name: ArrayPath<TFieldValues>;
  isRequired?: boolean;
  isDisabled?: boolean;
} & TestIdProps;

export function EmailList<TFieldValues extends FieldValues = FieldValues>(
  props: EmailListProps<TFieldValues>
) {
  const {fields, remove, append} = useFieldArray({
    control: props.control,
    name: props.name,
  });

  const emptyEmail: EmailData = {
    type: null,
    email: '',
  };

  const isAddAnotherDisabled = any((item: EmailData) => isNilOrEmpty(item.email))(
    props.formApi.watch(props.name as Path<TFieldValues>)
  );

  return (
    <>
      {fields.map((field, index) => (
        <HStack key={field.id} spacing={4}>
          <Box flex={1}>
            <FormField
              control={props.control}
              type="email"
              isRequired={props.isRequired}
              isDisabled={props.isDisabled}
              name={
                `${props.name}.${index}.email` as UnsafeFieldAssertionPath<TFieldValues, string>
              }
              label={i18n.t('entity.email.labels.email')}
              data-testid={suffixTestId(`email-[${index}]`, props)}
            />
          </Box>
          <Box flex={1}>
            <HStack spacing={4} align="flex-end">
              <Box flex={1}>
                <FormField
                  control={props.control}
                  type="text"
                  isDisabled={props.isDisabled}
                  name={`${props.name}.${index}.type` as Path<TFieldValues>}
                  label={i18n.t('entity.email.labels.type')}
                  data-testid={suffixTestId(`emailType-[${index}]`, props)}
                />
              </Box>
              <IconButton
                icon="action/delete"
                isDisabled={lengthLt(2, fields)}
                severity="danger"
                onClick={() => remove(index)}
                data-testid={suffixTestId(`emailRemove-[${index}]`, props)}
              />
            </HStack>
          </Box>
        </HStack>
      ))}
      <Align left>
        <Button
          leftIcon="content/add_circle"
          title={i18n.t('general.actions.addAnother')}
          size="small"
          variant="link"
          isDisabled={isAddAnotherDisabled}
          onClick={() => append(emptyEmail as any)}
          data-testid={suffixTestId('emailAddAnother', props)}
        />
      </Align>
    </>
  );
}
