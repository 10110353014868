import {
  useEffect,
  useState,
  forwardRef,
  ChangeEvent,
  ForwardRefRenderFunction,
  FocusEvent,
} from 'react';

import {TextField} from '../TextField/TextField';
import {TextFieldProps} from '../TextField/types';

export interface TextFieldWithValueFormatProps
  extends Omit<TextFieldProps, 'value' | 'defaultValue'> {
  format: (value: string) => string;
  value?: string;
  defaultValue?: string;
}

const TextFieldWithValueFormatComponent: ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldWithValueFormatProps
> = ({format, type = 'text', onBlur, onChange, onFocus, value, defaultValue, ...rest}, ref) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue || value || '');
  const [hasFocus, setFocus] = useState(false);

  const isControlled = value != null;

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    onFocus?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    onBlur?.(e);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (hasFocus && !isControlled) {
      setInputValue(e.target.value);
    }
    onChange?.(e);
  };

  useEffect(() => {
    if (isControlled) {
      setInputValue(value);
    }
  }, [value]);

  const realValue = isControlled ? value : inputValue;
  const visibleValue = hasFocus ? realValue : format(realValue ?? '');

  return (
    <TextField
      {...rest}
      ref={ref}
      type={type}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      value={visibleValue}
    />
  );
};

export const TextFieldWithValueFormat = forwardRef(TextFieldWithValueFormatComponent);
