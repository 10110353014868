import {Box} from 'platform/foundation';

import {FC} from 'react';

import i18n from '@omnetic-dms/i18n';
import {useFormRenderer} from '@omnetic-dms/teas';

export const AuditSettingsValidateField: FC = () => {
  const {Field} = useFormRenderer();

  return (
    <Box paddingBottom={2}>
      <Field
        as="checkbox"
        label={i18n.t`page.vehicleSettings.labels.validateMandatoryItems`}
        name="validation"
      />
    </Box>
  );
};
