import {createSelector, Selector} from '@reduxjs/toolkit';

import type {TeasState} from '../../types/TeasState';
import {VehicleDetailState} from './types';

export const selectVehicleDetail: Selector<TeasState, VehicleDetailState> = (state) =>
  state.sourcing.vehicleDetail;

export const selectVehicleDetailVehicle = createSelector(
  selectVehicleDetail,
  (state) => state.vehicle
);
