import {BuyingFormType} from '../types/BuyingFormType';

export type CalculateUpdate = {
  form: BuyingFormType;
  vatType: ('S' | 'R' | 'MR' | 'E' | 'Z' | 'SR' | 'P') | null;
  withVat: string;
  withoutVat: string;
  defaultCurrency: string;
};

export const handleCalculateUpdate = (params: CalculateUpdate): BuyingFormType => ({
  ...params.form,
  vatType: params.vatType,
  priceOfferedBase: {
    currency: params.form.priceOfferedWithoutVat?.currency ?? params.defaultCurrency,
    amount: params.withoutVat,
  },
  priceOfferedWithoutVat: {
    currency: params.form.priceOfferedWithoutVat?.currency ?? params.defaultCurrency,
    amount: params.withoutVat,
  },
  priceOfferedWithVat: {
    currency: params.form.priceOfferedWithVat?.currency ?? params.defaultCurrency,
    amount: params.withVat,
  },
});
