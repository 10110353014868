import {Button, TabProps, Tabs} from 'platform/components';
import {Box, HStack, Right, Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  CustomerResponseBodyV2,
  useGetBusinessCaseQuery,
  usePatchBusinessCaseMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, testIds} from '@omnetic-dms/routes';
import {CustomerWidgetCard, GdprConsentsList, Main, handleApiError} from '@omnetic-dms/shared';

import {buildArray, composePath, useBoolean, useRequiredParams, useNavigate} from 'shared';

import {CustomerPanel} from './components/CustomerPanel';

export function BusinessCaseCustomerDetail() {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});

  const [isCustomerWidgetOpened, openCustomerWidget, closeCustomerWidget] = useBoolean();

  const navigate = useNavigate();
  const [patchBusinessCase] = usePatchBusinessCaseMutation();

  const onCustomerSelected = (customer: CustomerResponseBodyV2) => {
    patchBusinessCase({
      businessCaseId,
      patchBusinessCaseRequestBody: {
        customerId: customer.id,
      },
    })
      .unwrap()
      .then(closeCustomerWidget)
      .catch(handleApiError);
  };

  const onForward = () => {
    const path = match(businessCase?.businessCaseType)
      .with('BUYING', always(businessCaseRoutes.buying))
      .otherwise(always(businessCaseRoutes.selling));

    navigate(composePath(path, {params: {id: businessCaseId}}));
  };

  const tabs = buildArray<TabProps>()
    .add({
      id: 'customer',
      title: i18n.t('entity.customer.labels.customer'),
      content: (
        <CustomerPanel businessCaseId={businessCaseId} openCustomerWidget={openCustomerWidget} />
      ),
      'data-testid': testIds.businessCase.customer('tabs-customer'),
    })
    .when(isNotNil(businessCase?.customerId), {
      id: 'consents',
      title: i18n.t('entity.gdpr.labels.consents'),
      content: (
        <GdprConsentsList
          businessCaseId={businessCaseId}
          customerId={businessCase?.customerId ?? ''}
          data-testid={testIds.businessCase.customer('tabs')}
        />
      ),
      'data-testid': testIds.businessCase.customer('tabs-consents'),
    });

  return (
    <Main>
      <Show when={isCustomerWidgetOpened}>
        <HStack justify="center">
          <Box width={188}>
            <CustomerWidgetCard
              onCustomer={onCustomerSelected}
              secondStepComponentType="BUSINESS_CASE"
              data-testid={testIds.businessCase.customer('selectCustomer')}
            />
          </Box>
        </HStack>
      </Show>
      <Show when={!isCustomerWidgetOpened}>
        <Box position="relative" width="100%">
          <Tabs
            data-testid={testIds.businessCase.customer('tabs')}
            variant="condensed"
            tabs={tabs}
          />
        </Box>
      </Show>
      <Right>
        <Button
          data-testid={testIds.businessCase.buying('continue')}
          onClick={onForward}
          variant="outlined"
          rightIcon="navigation/chevron_right"
          title={i18n.t('general.actions.continue')}
        />
      </Right>
    </Main>
  );
}
