import styled from 'styled-components';
import styledMap from 'styled-map';

export const SourcingContent = styled.div`
  display: flex;
  overflow: hidden;
`;

export const TilesWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 16px 48px;
  flex-direction: column;
  overflow: auto;
  gap: 16px;
`;

const textColor = styledMap`
	red: ${({theme}) => theme.colors.palettes.red[60][100]};
	green: ${({theme}) => theme.colors.palettes.green[60][100]};
	default: inherit;
`;

type ColorizedTextProps = {
  red?: boolean;
  green?: boolean;
};

export const VatReclaimable = styled.div`
  font-size: ${({theme}) => theme.fontSizes.text.xxSmall};
  line-height: 1.34;
  font-weight: 400;
  text-align: left;
  margin: 0;
  letter-spacing: 0;
  color: ${({theme}) => theme.colors.palettes.white[10][100]};
  border-radius: ${({theme}) => theme.radii.small};
  background-color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  margin-right: 8px;
  padding: 2px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ColorizedText = styled.span<ColorizedTextProps>`
  color: ${textColor};
`;

type ColorProps = {
  color: string;
};

type VehicleTitleProps = {
  $disabled?: boolean;
};

export const SourcingColor = styled.div<ColorProps>`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${({color}) => color};
  border: ${({color, theme}) =>
    color === 'white' ? `1px solid ${theme.colors.palettes.neutral[70][100]}` : 'none'};
`;

export const VehicleTooltipWrapper = styled.div`
  > div {
    white-space: nowrap;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const SoldCar = styled.span`
  margin-right: 3px;
  padding: 0 4px;
  font-size: ${({theme}) => theme.fontSizes.text.xxSmall};
  background: ${({theme}) => theme.colors.palettes.neutral[60][100]};
  color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
  border-radius: ${({theme}) => theme.radii.small};
  line-height: 14px;
`;

export const VehicleTitle = styled.div<VehicleTitleProps>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  & > span {
    &:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      max-width: 100%;
    }

    & > div > svg {
      width: 100%;
      height: 100%;
    }
  }

  ${({$disabled}) =>
    $disabled &&
    `
		display: flex;
		align-items: center;
	`}
`;
