import {createContext, MutableRefObject} from 'react';

import {noop} from '@omnetic-dms/teas';

export const FormContextWithSubmit = createContext<
  MutableRefObject<{
    onSubmit: () => void;
    onChange: () => void;
  }>
>({
  current: {
    onChange: noop,
    onSubmit: noop,
  },
});
