import {isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function WarehouseMarketingCode() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    switch (actionKey) {
      case 'redirectDetail':
        if ('id' in rowData && isString(rowData.id)) {
          navigate(
            composePath(settingsRoutes.warehousesMarketingCodeDetail, {params: {id: rowData.id}})
          );
        }
        break;
    }
  };

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.warehouse.labels.marketingCode'),
    actions: [
      {
        type: 'button',
        title: i18n.t('entity.warehouse.actions.newMarketingCode'),
        onClick: () => navigate(settingsRoutes.warehousesMarketingCodeCreate),
      },
    ],
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.workshopPresetsCategory('template')}
      header={header}
    >
      <DataGrid
        gridCode="marketing-code"
        actionCallback={actionCallback}
        emptyState={{
          headline: i18n.t('page.warehouseDetail.labels.emptyAssortment'),
        }}
      />
    </SettingsTemplate>
  );
}
