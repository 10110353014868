import {FC, useRef, useEffect, useState, ReactNode} from 'react';

import {AuditDataAssetsV2Body} from '../../types/AuditDataAssetsV2Body';
import {TConditionForm} from '../../types/ConditionTypes';
import {FormApi} from '../FinalForm/types/FormApi';
import {ConditionAssets, OnVehicleCreate} from './types/TVehicleCreateContext';
import {VehicleCreate} from './VehicleCreateCtx';

type VehicleCreateContextProps = {
  children: ReactNode;
  onCreate?: OnVehicleCreate;
  onClose?: () => void;
};
export const VehicleCreateContext: FC<VehicleCreateContextProps> = ({
  children,
  onCreate,
  onClose,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const scrollingAreaRef = useRef<HTMLDivElement>(null);
  const [conditionAssets, setConditionAssets] = useState<ConditionAssets>({});

  const pushConditionAsset = (imageId: string, asset: AuditDataAssetsV2Body) => {
    conditionAssets[imageId] = asset;
    setConditionAssets(conditionAssets);
  };

  const deleteConditionAsset = (imageId: string) => {
    delete conditionAssets[imageId];
    setConditionAssets(conditionAssets);
  };

  const getConditionAssets = (): ConditionAssets => conditionAssets;

  let conditionForm: FormApi<TConditionForm>;

  const setConditionForm = (_conditionForm: FormApi<TConditionForm>) => {
    conditionForm = _conditionForm;
  };

  const getConditionForm = (): FormApi<TConditionForm> => conditionForm;

  useEffect(() => {
    if (scrollingAreaRef.current) {
      scrollingAreaRef.current.scroll(0, 0);
    }
  }, [tabIndex]);

  return (
    <VehicleCreate.Provider
      value={{
        getConditionForm,
        setConditionForm,

        onCreate,
        onClose,

        pushConditionAsset,
        deleteConditionAsset,
        getConditionAssets,

        tabIndex,
        setTabIndex,
        scrollingAreaRef,
      }}
    >
      {children}
    </VehicleCreate.Provider>
  );
};
