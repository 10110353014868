import {suffixTestId, TestIdProps} from 'shared';

import {Breadcrumb} from '../Breadcrumb/Breadcrumb';
import {BreadcrumbItem} from '../Breadcrumb/components/BreadcrumbItem';
import {BreadcrumbLink} from '../Breadcrumb/components/BreadcrumbLink';

export type BreadcrumbType = {
  label: string;
  href?: string;
  isCurrentPage?: boolean;
};

export interface BreadcrumbsProps extends TestIdProps {
  breadcrumbs?: BreadcrumbType[];
}

export function Breadcrumbs(props: BreadcrumbsProps) {
  return (
    <Breadcrumb data-testid={suffixTestId('breadcrumbs', props)}>
      {props.breadcrumbs?.map((breadcrumb, index) => (
        <BreadcrumbItem
          key={breadcrumb.label}
          isCurrentPage={breadcrumb.isCurrentPage}
          data-testid={suffixTestId(`breadcrumbs-breadcrumb-[${index}]`, props)}
        >
          <BreadcrumbLink href={breadcrumb.href} isCurrentPage={breadcrumb.isCurrentPage}>
            {breadcrumb.label}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}
