import {Box, HStack, Space} from 'platform/foundation';
import styled from 'styled-components';

import {FC} from 'react';

import {or} from 'ramda';

import {RowType} from './types/RowType';

const RowLabel = styled.div`
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  line-height: 1.34;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0;
  text-align: right;
  padding: 2px 4px;
  margin-right: 8px;
  color: ${({theme}) => theme.colors.palettes.neutral[300][100]};
`;

const RowValue = styled.div`
  font-size: ${({theme}) => theme.fontSizes.text.small};
  line-height: 1.34;
  font-weight: 400;
  color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  margin: 0;
  letter-spacing: 0;
  padding: 2px 4px;
`;

const Divider = styled.div`
  margin: 8px 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
`;

export const RowRenderer: FC<{row: RowType; index: number}> = ({row, index}) => (
  <div
    data-testid={`info-card-content-row-${index}`}
    style={{display: row.hidden ? 'none' : 'block'}}
  >
    <HStack data-testid={`info-card-content-row-${index}-wrapper`} align={row.alignItems}>
      <Box flex={1}>
        <RowLabel data-testid={`info-card-content-row-${index}-label`}>
          {or(row.label, false)}
        </RowLabel>
      </Box>
      <Box flex={2}>
        {row.render ?? (
          <RowValue data-testid={`info-card-content-row-${index}-value`}>
            {or(row.value, '–')}
          </RowValue>
        )}
      </Box>
    </HStack>
    <Space vertical={row.divider ? 0 : 1} />
    {row.divider && <Divider data-testid={`info-card-content-row-${index}-divider`} />}
  </div>
);
