import {Box, useMultiStyleConfig} from '@chakra-ui/react';

import {useTimeout} from 'shared';

import {useActionCallbackProxy} from '../hooks/useActionCallbackProxy';
import {useRowActions} from '../hooks/useRowActions';
import {ICellRendererParams} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {ActionButtonsRenderer} from './ActionButtonsRenderer';

export interface ActionsCellRendererWrapperProps extends ICellRendererParams {
  gridProps: DataGridProps;
}

export function ActionsCellRendererWrapper({
  context,
  node,
  gridProps,
  columnApi,
  api,
}: ActionsCellRendererWrapperProps) {
  const {actionCallback, gridCode} = gridProps;

  const actions = useRowActions(node.data, context.gridProps);
  const actionCallbackProxy = useActionCallbackProxy(
    actionCallback,
    api,
    node.id!,
    node.data,
    gridCode
  );

  const {actionsCellRenderer: theme} = useMultiStyleConfig('DataGrid', context.gridProps);

  useTimeout(() => columnApi.autoSizeColumn('eag-col-actions', true), 0);

  if (Object.keys(actions).length === 0) {
    return null;
  }

  return (
    <Box __css={theme}>
      <ActionButtonsRenderer
        rowActionData={node.data.actions}
        actions={actions}
        actionCallback={actionCallbackProxy}
      />
    </Box>
  );
}
