import {DataStatus, IconButton} from 'platform/components';
import {Align, Box, Hide, Text} from 'platform/foundation';
import {useFormatNumber, useLocale} from 'platform/locale';
import styled, {css} from 'styled-components';

import {ChangeEvent, useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  Checkbox,
  SimpleTable,
  SimpleTableRowType,
  TextField,
  handleCostChange,
  removeCostRequest,
  selectCostWithId,
  submitRowChangeRequest,
  PresetItem,
} from '@omnetic-dms/teas';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTextField = styled(TextField)`
  padding: 0 4px;

  input:nth-of-type(even) {
    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px ${({theme}) => theme.colors.palettes.neutral[10][100]} inset;
    }
  }

  input:nth-of-type(odd) {
    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px ${({theme}) => theme.colors.palettes.white[10][100]} inset;
    }
  }
`;

type CostsTableProps = {
  id: string;
};

export function CostsTable({id}: CostsTableProps) {
  const dispatch = useDispatch();
  const costs = useSelector(selectCostWithId(id)) ?? {isLoading: true, isSaving: true};
  const formatNumber = useFormatNumber();
  const {localeConfig} = useLocale();

  const {isLoading, isSaving, presetItems: items = {}} = costs;
  const presetItems = Object.keys(items).length ? Object.values(items) : [];

  const rows: SimpleTableRowType<PresetItem>[] = [
    {
      sectionName: null,
      data: presetItems.filter(({type}) => type === 'fixed' || type === 'other'),
    },
  ];

  const handleChange = useCallback(
    (row: PresetItem) => (event: ChangeEvent<HTMLInputElement>) => {
      const {name, checked, type} = event.target;
      const value = event.target?.value?.toString() || '';
      const costRegExp = value.match(/^(0|([1-9]\d*\.?\d*)|(0\.\d*)|[1-9]*)$/g);

      if (name === 'cost' && !costRegExp) {
        return;
      }

      if (type === 'checkbox') {
        dispatch(
          handleCostChange({
            vehicleId: id,
            id: row.uuid,
            updatedKey: name,
            value: checked,
          })
        );
        dispatch(submitRowChangeRequest({vehicleId: id, id: row.uuid}));

        return;
      }

      dispatch(handleCostChange({vehicleId: id, id: row.uuid, updatedKey: name, value}));
    },
    [dispatch, id]
  );

  const handleBlur = useCallback(
    (row: PresetItem) => () => dispatch(submitRowChangeRequest({vehicleId: id, id: row.uuid})),
    [dispatch, id]
  );

  const handleRemoveCost = useCallback(
    (row: PresetItem) => () => {
      dispatch(removeCostRequest({vehicleId: id, id: row.uuid}));
    },
    [dispatch, id]
  );

  const inputRef = useRef<string[]>([]);

  // Scroll newly added item into viewport and focus input
  // TODO: this needs refactor
  useEffect(() => {
    const queryTemp = 'temp_';
    const filterItems = presetItems.filter((item) => item?.uuid?.includes(queryTemp));

    if (filterItems.length) {
      const inputId = filterItems[filterItems.length - 1].uuid as string;
      const el = document.getElementById(inputId);

      if (!inputRef.current.includes(inputId)) {
        el?.focus();
        inputRef.current.push(inputId);
      }
    }
  }, [costs?.presetItems]);

  return (
    <DataStatus isLoading={isLoading}>
      <SimpleTable<PresetItem>
        tdPadding="dense"
        css={css`
          .simple-table-th {
            z-index: 1;
          }

          & tbody > tr:first-child > td {
            padding-top: 8px;
          }
        `}
        columns={[
          {
            id: 'title',
            Header: i18n.t('entity.vehicle.labels.item'),
            Cell: ({row}) => (
              <TitleContainer
                css={css`
                  opacity: ${row.enabled ? '1' : '0.5'};
                `}
              >
                <Checkbox
                  name="enabled"
                  label={
                    <StyledTextField
                      id={row.uuid as string}
                      defaultValue={i18n.t('entity.vehicle.labels.costItemTitleOther')}
                      variant="inline"
                      name="title"
                      type="text"
                      disabled={isSaving}
                      value={row.title}
                      onBlur={handleBlur(row as PresetItem)}
                      onChange={handleChange(row as PresetItem)}
                    />
                  }
                  css={css`
                    text-overflow: ellipsis;
                    display: block;
                  `}
                  disabled={isSaving}
                  id={`checkbox-${row.uuid as string}`}
                  checked={row.enabled as boolean}
                  onChange={handleChange(row as PresetItem)}
                  data-testid={testIds.sourcing.classifieds(`costs-table-checkbox-${row.uuid}`)}
                />
              </TitleContainer>
            ),
          },
          {
            id: 'cost',
            Header: (
              <Box paddingRight={4}>
                <Align right>{i18n.t('entity.vehicle.labels.expectedCostCzk')}</Align>
              </Box>
            ),
            Cell: ({row}) => (
              <StyledTextField
                useNumberFormat
                variant="inline"
                css={css`
                  opacity: ${row.enabled ? '1' : '0.5'};

                  input {
                    text-align: right;
                  }
                `}
                numberFormatProps={{
                  isNumericString: true,
                  thousandSeparator: localeConfig.number.thousandsSeparator,
                  decimalSeparator: localeConfig.number.decimalSeparator,
                }}
                name="cost"
                type="text"
                disabled={isSaving}
                value={row.cost as string}
                onBlur={handleBlur(row as PresetItem)}
                onChange={handleChange(row as PresetItem)}
              />
            ),
          },
          {
            id: 'note',
            Header: i18n.t('general.labels.note'),
            Cell: ({row}) => (
              <StyledTextField
                variant="inline"
                css={css`
                  opacity: ${row.enabled ? '1' : '0.5'};

                  & > input {
                    text-overflow: ellipsis;
                  }
                `}
                name="note"
                disabled={isSaving}
                value={row.note as string}
                onBlur={handleBlur(row as PresetItem)}
                onChange={handleChange(row as PresetItem)}
              />
            ),
          },
          {
            id: 'action',
            Header: null,
            Cell: ({row}) => (
              <Hide when={row.type === 'fixed'}>
                <IconButton
                  isDisabled={isSaving}
                  icon="action/delete_outline"
                  onClick={handleRemoveCost(row as PresetItem)}
                  size="small"
                  data-testid={testIds.sourcing.classifieds(`costs-table-remove-${row.uuid}`)}
                />
              </Hide>
            ),
            width: 50,
          },
        ]}
        rows={rows}
        footerRows={[
          {
            title: i18n.t('general.labels.total'),
            cost: (
              <Box paddingRight={3}>
                <Align right>
                  <Text alternative size="small">
                    {formatNumber(costs.totalPrice, 0)}
                  </Text>
                </Align>
              </Box>
            ),
          },
        ]}
      />
    </DataStatus>
  );
}
