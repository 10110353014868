export const businessCaseSearchParams = {
  create: 'create',
  businessCaseDetail: {
    overview: 'overview',
    customer: 'customer',
    buying: 'buying',
    selling: 'selling',
    testDrive: 'test-drive',
    checkout: 'checkout',
    documents: 'documents',
    reservations: 'reservations',
  },
};
