import {openDeleteDialog} from 'platform/components';

import {useState} from 'react';

import {defaultTo, equals, isEmpty, isNil, not, reject} from 'ramda';

import {Nullish} from 'shared';

import {MappedBasketItem} from '../types/basket/MappedBasketItem';
import {mergeBasketItems} from '../utils/mergeBasketItems';

interface UseBasketItemsProps<T extends {id: string}> {
  basketItems: T[] | Nullish;
  /**
   *
   * @description Optional since labour basket delete items with bulk action defined within the parent of the basket.
   */
  onDeleteBasketItems?: (itemsIds: string[]) => Promise<void>;
}

export function useBasketItems<T extends {id: string}>(props: UseBasketItemsProps<T>) {
  const newBasketItems = defaultTo([], props.basketItems);

  const [basketItems, setBasketItems] = useState<MappedBasketItem<T>[]>(() => {
    if (isEmpty(newBasketItems)) {
      return [];
    }

    const initialState = reject(isNil, newBasketItems).map((basketItem) => ({
      ...basketItem,
      isSelected: false,
    }));

    return initialState;
  });

  const mergedBasketItems = mergeBasketItems<T, MappedBasketItem<T>>(newBasketItems, basketItems);

  if (not(equals(basketItems, mergedBasketItems))) {
    setBasketItems(mergedBasketItems);
  }

  const basketItemsCount = defaultTo(0, basketItems.length);

  const hasSomeBasketItemsSelected = basketItems.some((basketItem) => basketItem.isSelected);

  const hasAllBasketItemsSelected = basketItems.every((basketItem) => basketItem.isSelected);

  const handleSelect = (id: string, isSelected: boolean) => {
    setBasketItems((prevBasketItems) =>
      prevBasketItems?.map((basketItem) =>
        basketItem.id === id ? {...basketItem, isSelected} : basketItem
      )
    );
  };

  const handleSelectAll = () => {
    setBasketItems((prevBasketItems) =>
      prevBasketItems?.map((basketItem) => ({
        ...basketItem,
        isSelected: !hasAllBasketItemsSelected,
      }))
    );
  };

  const handleBulkDelete = () => {
    const itemsIds = reject(isNil, basketItems ?? [])
      .filter((basketItem) => basketItem.isSelected)
      .map((selectedBasketItem) => selectedBasketItem.id);

    openDeleteDialog({
      onConfirm: () => props.onDeleteBasketItems && props.onDeleteBasketItems(itemsIds),
    });
  };

  return {
    basketItems,
    basketItemsCount,
    hasSomeBasketItemsSelected,
    hasAllBasketItemsSelected,
    onSelectBasketItem: handleSelect,
    onSelectAllBasketItems: handleSelectAll,
    onBulkDeleteBasketItems: handleBulkDelete,
  };
}
