import {DeepPath, DeepPathArray} from '@hookform/strictly-typed';

import {ReactElement} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {FieldProps} from './FieldProps';
import {useDatePickerFields, DatePickerFieldProps} from './hooks/useDatePickerField';
import {DropdownFieldProps, useDropdownFields} from './hooks/useDropdownField';
import {useHiddenFields, UseHiddenFieldsDefaultValue} from './hooks/useHiddenFields';
import {useSwitcherFields, SwitcherFieldProps} from './hooks/useSwitcherField';
import {useTextFields, TextFieldProps} from './hooks/useTextField';

interface FieldEditors<
  TValues extends Record<string, unknown>,
  TPath = DeepPath<TValues, DeepPathArray<TValues, keyof TValues>>,
> {
  text: (fieldProps: TextFieldProps<TValues, TPath>) => ReactElement;
  dropdown: <TOptionType extends Record<string, unknown>>(
    fieldProps: DropdownFieldProps<TValues, TPath, TOptionType>
  ) => ReactElement;
  date: (fieldProps: DatePickerFieldProps<TValues, TPath>) => ReactElement;
  switcher: (fieldProps: SwitcherFieldProps<TValues, TPath>) => ReactElement;
  hidden: (
    fieldProps: FieldProps<TValues, TPath> & {
      defaultValue?: UseHiddenFieldsDefaultValue<TValues>;
    }
  ) => ReactElement;
}
/**
 * @deprecated - use platform instead
 */
export function useFields<
  // TODO Fix `use.*Fields` methods extend conditions for TValues argument,
  // Conditions should be the same as here, but for some reason, they differ
  // And after that change `any` to `unknown` as it truly must be
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TValues extends Partial<Record<keyof TValues, any>>,
  TPath = DeepPath<TValues, DeepPathArray<TValues, keyof TValues>>,
>(form: UseFormReturn<TValues>): FieldEditors<TValues, TPath> {
  return {
    text: useTextFields<TValues, TPath>(form),
    // @ts-expect-error ts doubts about user responsibility to pass proper type as TOptionType
    // in practice there will be no such situations, when TOptionType is something unexpected
    dropdown: useDropdownFields<TValues, TPath>(form),
    date: useDatePickerFields<TValues, TPath>(form),
    switcher: useSwitcherFields<TValues, TPath>(form),
    hidden: useHiddenFields<TValues, TPath>(form),
  };
}
