import {useState} from 'react';

import {without} from 'ramda';

type UseRowSelectReturnValue = {
  deselectAll: () => void;
  toggle: (key: string) => void;
  toggleAll: () => void;
  isSelected: (key: string) => boolean;
  allSelected: () => boolean;
  someSelected: () => boolean;
  selectedCount: number;
};

export const useRowSelect = (allKeys: string[]): UseRowSelectReturnValue => {
  const allKeysSet = new Set(allKeys);
  const [selectedKeys, setSelectedKeys] = useState<Set<string>>(new Set());

  const selectAll = () => {
    setSelectedKeys(allKeysSet);
  };

  const deselectAll = () => {
    setSelectedKeys(new Set());
  };

  const toggle = (key: string) => {
    if (selectedKeys.has(key)) {
      deselectOne(key);
    } else {
      selectOne(key);
    }
  };

  const allSelected = () => allKeysSet.size === selectedKeys.size;

  const someSelected = () => selectedKeys.size > 0 && selectedKeys.size < allKeysSet.size;

  const toggleAll = () => {
    if (allSelected()) {
      deselectAll();
    } else {
      selectAll();
    }
  };

  const select = (keys: string[]) => {
    const correctKeys = keys.filter((key) => allKeys.includes(key));
    setSelectedKeys((state) => new Set([...state, ...correctKeys]));
  };

  const deselect = (keys: string[]) => {
    setSelectedKeys(([...state]) => new Set(without(keys, state)));
  };

  const selectOne = (key: string) => {
    select([key]);
  };

  const deselectOne = (key: string) => {
    deselect([key]);
  };

  const isSelected = (key: string) => selectedKeys.has(key);

  const selected = () => [...selectedKeys];

  return {
    deselectAll,
    toggle,
    toggleAll,
    isSelected,
    allSelected,
    someSelected,
    selectedCount: selected().length,
  };
};
