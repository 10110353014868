import {GroupBase, OptionsOrGroups} from 'react-select';

import {has} from 'ramda';

import {OptionTypeBase} from '../../../types/OptionTypeBase';

export const getFlattenedOptions = <ValueType>(
  options?: OptionsOrGroups<OptionTypeBase<ValueType>, GroupBase<OptionTypeBase<ValueType>>>
): ReadonlyArray<OptionTypeBase<ValueType>> => {
  if (!options?.length) {
    return [];
  }

  // For grouped options we have to get flatten structure to find selected values
  const isGrouped = options?.some((o) => has('options')(o));

  return isGrouped
    ? options?.flatMap(
        (option) =>
          (option as OptionTypeBase<ValueType> & {options: OptionTypeBase<ValueType>[]})?.options ||
          []
      )
    : (options as ReadonlyArray<OptionTypeBase<ValueType>>);
};
