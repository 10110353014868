import {
  PostServiceOrderIssueVariantRequest,
  PostServiceOrderIssueVariantResponse,
  BulkDeleteServiceOrderIssueVariantRequest,
  GetServiceOrderIssueVariantRequest,
  GetServiceOrderIssueVariantResponse,
  GetServiceOrderIssueVariantsResponse,
  PatchServiceOrderIssueVariantRequest,
  PatchServiceOrderIssueVariantSetDefaultRequest,
  GetServiceOrderIssueVariantRequestSchema,
  GetServiceOrderIssueVariantResponseSchema,
  PostServiceOrderIssueVariantRequestSchema,
  PostServiceOrderIssueVariantResponseSchema,
  PatchServiceOrderIssueVariantRequestSchema,
  BulkDeleteServiceOrderIssueVariantRequestSchema,
  PatchServiceOrderIssueVariantSetDefaultRequestSchema,
  GetServiceOrderIssueVariantsResponseSchema,
  PatchServiceOrderIssueVariantResponse,
  PatchServiceOrderIssueVariantResponseSchema,
  BulkDeleteServiceOrderIssueVariantResponse,
  BulkDeleteServiceOrderIssueVariantResponseSchema,
  PatchServiceOrderIssueVariantSetDefaultResponse,
  PatchServiceOrderIssueVariantSetDefaultResponseSchema,
  GetServiceOrderIssueVariantsRequest,
  GetServiceOrderIssueVariantsRequestSchema,
} from '../types/metadaWarehouseServiceOrderIssueVariants';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseServiceOrderIssueVariantsApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceOrderIssueVariant: build.query<
      GetServiceOrderIssueVariantResponse,
      GetServiceOrderIssueVariantRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-variant/${queryArg.serviceOrderIssueVariantId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderIssueVariants', id: queryArg.serviceOrderIssueVariantId},
      ],
      extraOptions: {
        requestSchema: GetServiceOrderIssueVariantRequestSchema,
        responseSchema: GetServiceOrderIssueVariantResponseSchema,
      },
    }),
    postServiceOrderIssueVariant: build.mutation<
      PostServiceOrderIssueVariantResponse,
      PostServiceOrderIssueVariantRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-variant`,
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: ['serviceOrderIssueVariants'],
      extraOptions: {
        requestSchema: PostServiceOrderIssueVariantRequestSchema,
        responseSchema: PostServiceOrderIssueVariantResponseSchema,
      },
    }),
    patchServiceOrderIssueVariant: build.mutation<
      PatchServiceOrderIssueVariantResponse,
      PatchServiceOrderIssueVariantRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-variant/${queryArg.serviceOrderIssueVariantId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderIssueVariants', id: queryArg.serviceOrderIssueVariantId},
      ],
      extraOptions: {
        requestSchema: PatchServiceOrderIssueVariantRequestSchema,
        responseSchema: PatchServiceOrderIssueVariantResponseSchema,
      },
    }),
    bulkDeleteServiceOrderIssueVariant: build.mutation<
      BulkDeleteServiceOrderIssueVariantResponse,
      BulkDeleteServiceOrderIssueVariantRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-variant/delete`,
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: ['serviceOrderIssueVariants'],
      extraOptions: {
        requestSchema: BulkDeleteServiceOrderIssueVariantRequestSchema,
        responseSchema: BulkDeleteServiceOrderIssueVariantResponseSchema,
      },
    }),
    patchServiceOrderIssueVariantSetDefault: build.mutation<
      PatchServiceOrderIssueVariantSetDefaultResponse,
      PatchServiceOrderIssueVariantSetDefaultRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-variant/${queryArg.serviceOrderIssueVariantId}/set-default`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['serviceOrderIssueVariants'],
      extraOptions: {
        requestSchema: PatchServiceOrderIssueVariantSetDefaultRequestSchema,
        responseSchema: PatchServiceOrderIssueVariantSetDefaultResponseSchema,
      },
    }),
    getServiceOrderIssueVariants: build.query<
      GetServiceOrderIssueVariantsResponse,
      GetServiceOrderIssueVariantsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-variants`,
        method: 'GET',
        queryArg,
      }),
      providesTags: ['serviceOrderIssueVariants'],
      extraOptions: {
        requestSchema: GetServiceOrderIssueVariantsRequestSchema,
        responseSchema: GetServiceOrderIssueVariantsResponseSchema,
      },
    }),
  }),
});

export const {
  useGetServiceOrderIssueVariantsQuery,
  useGetServiceOrderIssueVariantQuery,
  usePostServiceOrderIssueVariantMutation,
  usePatchServiceOrderIssueVariantMutation,
  useBulkDeleteServiceOrderIssueVariantMutation,
  usePatchServiceOrderIssueVariantSetDefaultMutation,
} = metadaWarehouseServiceOrderIssueVariantsApi;
