import {AuditData} from '../../../store/carAudit/reducerUtils';
import {TConditionFormValue} from '../../../types/ConditionTypes';
import {getFormFieldName} from './getFormFieldName';

export const getAuditFieldData = (
  categoryId: string,
  paramDefinitionId?: string,
  auditData?: AuditData
): TConditionFormValue | undefined =>
  auditData?.fields?.[getFormFieldName(categoryId, paramDefinitionId)];
