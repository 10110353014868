import {getYear} from 'date-fns';

import {range} from 'ramda';

import {Nullish} from 'shared';

import {OptionType} from '../types/FormControlProps';

export function getApiYearOptions(minDate: Date | Nullish, maxDate: Date | Nullish): OptionType[] {
  const today = new Date();
  const startYear = minDate ? getYear(minDate) : 1950;
  const endYear = maxDate ? getYear(maxDate) : getYear(today);

  return range(startYear, endYear + 1).map((i): OptionType => ({label: `${i}`, value: `${i}`}));
}
