export const getBrandImageUrl = (makeKey: string) => {
  const baseUrl = '/assets/images/brands';

  switch (makeKey) {
    case 'MAKE_AUDI': {
      return `${baseUrl}/audi.png`;
    }
    case 'MAKE_BMW': {
      return `${baseUrl}/bmw.png`;
    }
    case 'MAKE_CITROEN': {
      return `${baseUrl}/citroen.png`;
    }
    case 'MAKE_DACIA': {
      return `${baseUrl}/dacia.png`;
    }
    case 'MAKE_FIAT': {
      return `${baseUrl}/fiat.png`;
    }
    case 'MAKE_FORD': {
      return `${baseUrl}/ford.png`;
    }
    case 'MAKE_HYUNDAI': {
      return `${baseUrl}/hyundai.png`;
    }
    case 'MAKE_KIA': {
      return `${baseUrl}/kia.png`;
    }
    case 'MAKE_MERCEDES_BENZ': {
      return `${baseUrl}/mercedes_benz.png`;
    }
    case 'MAKE_PEUGEOT': {
      return `${baseUrl}/peugeot.png`;
    }
    case 'MAKE_SKODA': {
      return `${baseUrl}/skoda.png`;
    }
    case 'MAKE_VOLKSWAGEN': {
      return `${baseUrl}/volkswagen.png`;
    }
    default: {
      return `${baseUrl}/empty.png`;
    }
  }
};
