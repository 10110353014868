export const TOP_MAKES = [
  'MAKE_AUDI',
  'MAKE_BMW',
  'MAKE_CITROEN',
  'MAKE_DACIA',
  'MAKE_FIAT',
  'MAKE_FORD',
  'MAKE_HYUNDAI',
  'MAKE_KIA',
  'MAKE_MERCEDES_BENZ',
  'MAKE_PEUGEOT',
  'MAKE_SKODA',
  'MAKE_VOLKSWAGEN',
];
