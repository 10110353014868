import {map, pickBy, pipe} from 'ramda';
import {isNotNilOrEmpty, isPlainObj} from 'ramda-adjunct';

/**
 * Removes keys from an object that are not listed in the `keysToPreserve` array. This operation is
 * performed recursively on any nested objects. The removal decision depends on the condition provided
 * by the `shouldPreserveKey` function.
 *
 * @param {Record<string, any>} obj - The source object from which keys will be removed.
 * @param {string[]} keysToPreserve - An array of strings representing the keys that should be preserved in the object.
 * @returns {Record<string, any>} A new object with only the keys that were intended to be preserved.
 *
 * @example
 * // returns { a: { b: "keep this" } }
 * removeKeys({ a: { b: "keep this", c: "remove this" }, d: "remove this" }, ['a', 'b']);
 **/
export const removeKeys = (
  obj: Record<string, any>,
  keysToPreserve: string[]
): Record<string, any> => {
  // This is a helper function that we will use to decide whether to keep a key or not.
  const shouldPreserveKey = (key: string) => keysToPreserve.includes(key) && isNotNilOrEmpty(key);

  // This is a recursive function that will be applied to each value in the object.
  const filterRecursively = (value: any): any => {
    if (isPlainObj(value)) {
      return removeKeys(value, keysToPreserve);
    }
    return value;
  };

  // Create a new object with only the keys that we want to preserve
  // and apply the recursive filter to each value.
  return pipe(
    pickBy((_, key) => shouldPreserveKey(key)), // Pick only the keys to preserve
    map(filterRecursively) // Apply recursive filter to each value
  )(obj);
};
