import {Heading, Show, VStack} from 'platform/foundation';

import {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {MainCategoryProps} from '../../../types/ConditionTypes';
import {getNameAccordingToLocale} from '../../../utils/getNameAccordingToLocale';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {isOverallEvaluationCategory} from '../utils/isOverallEvaluationCategory';
import {CategoryCard} from './CategoryCard';
import {Features} from './Features';

export const TechnicalCondition: FC<MainCategoryProps & TestIdProps> = ({
  data: categoryStructure,
  ...rest
}) => {
  const locale = useSelector(selectUserSelectedLanguage);

  const memoizedCategories = useMemo(() => {
    let equipmentCategory: AuditCategoryOfStructure | undefined;
    const restCategories: AuditCategoryOfStructure[] = [];

    categoryStructure?.childCategories?.forEach((category) => {
      if (isOverallEvaluationCategory(category)) {
        return;
      }

      if (category.uniqueKey === AuditCategoryUniqueKey.EQUIPMENT) {
        equipmentCategory = category;

        return;
      }

      restCategories.push(category);
    });

    return {equipmentCategory, restCategories};
  }, [categoryStructure]);

  const {equipmentCategory, restCategories} = memoizedCategories;

  return (
    <VStack spacing={4}>
      {restCategories.map((childCategory, index) => (
        <VStack spacing={4} key={`restCategories-${childCategory.id}`}>
          <Heading data-testid={suffixTestId(`restCategories-[${index}]`, rest)} size={3}>
            {getNameAccordingToLocale(locale, childCategory.name)}
          </Heading>
          <VStack spacing={3}>
            {childCategory.childCategories?.map((category, categoryIndex) => (
              <CategoryCard
                key={`restCategories-child-${category.id}`}
                data={category}
                additionalInformationBeside
                cardVariant="inlineWhite"
                data-testid={suffixTestId(
                  `restCategories-[${index}]-child-[${categoryIndex}]`,
                  rest
                )}
              />
            ))}
          </VStack>
        </VStack>
      ))}
      <Show when={isNotNil(equipmentCategory)}>
        <Features data-testid={rest['data-testid']} data={equipmentCategory!} />
      </Show>
    </VStack>
  );
};
