import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {CurrencyResponseBody} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getOptionsFromCurrencies = (
  currencies: CurrencyResponseBody[] | Nullish
): Option[] => {
  if (isNil(currencies)) {
    return [];
  }

  return currencies.map((currency) => ({
    label: currency.name,
    value: currency.code,
  }));
};
