import {DeepPartial} from 'utility-types';
import {AnyObjectSchema} from 'yup';

import {isNil} from 'ramda';

export const getDefaultValue = (
  fields: any,
  defaultValues?: Record<string, unknown>
): Record<string, unknown> => {
  const values = defaultValues || {};
  Object.keys(fields).forEach((field) => {
    const property = fields[field];
    const defined = property?.exclusiveTests.defined;
    const nullable = property?.spec?.nullable === true;
    const required =
      property?.tests.findIndex(({OPTIONS}: any) => OPTIONS.name === 'required') >= 0;

    if (property?.type === 'array') {
      if (isNil(values[field as string])) {
        if (!defined && !required) {
          values[field as string] = undefined;
        } else if (nullable) {
          values[field as string] = null;
        } else {
          values[field as string] = [];
        }
      } else {
        (values[field as string] as Array<Record<string, unknown>>) =
          (values[field as string] as Array<Record<string, unknown>>).map((item) =>
            getDefaultValue(property.innerType.fields, item)
          ) || [];
      }
    }

    if (property?.type === 'object') {
      if (isNil(values[field as string])) {
        if (!defined && !required) {
          values[field as string] = undefined;
        } else if (nullable) {
          values[field as string] = null;
        } else {
          values[field as string] = getDefaultValue(
            property.fields,
            values[field as string] as Record<string, unknown>
          );
        }
      } else {
        values[field as string] = getDefaultValue(
          property.fields,
          values[field as string] as Record<string, unknown>
        );
      }
    }

    if (isNil(values[field as string])) {
      if (!defined && !required) {
        values[field as string] = undefined;
      } else if (nullable) {
        values[field as string] = null;
      } else if (property.type === 'string') {
        values[field as string] = '';
      } else if (property.type === 'boolean') {
        values[field as string] = false;
      }
    }
  });

  return values;
};

export const getDefaultValuesFromSchema = <
  T extends Record<string, unknown> = Record<string, unknown>,
>(
  schema: AnyObjectSchema,
  defaultValues?: DeepPartial<T>
): T => getDefaultValue(schema?.fields, defaultValues ? {...defaultValues} : {}) as T;
