import {isNil} from 'ramda';

import {Nullish} from 'shared';

export const getPercentageFromDecimal = (num: number | Nullish) => {
  if (isNil(num)) {
    return;
  }

  return Math.round(num * 10000) / 100;
};
