import {createGlobalStyle} from 'styled-components';
import {Normalize} from 'styled-normalize';

const BasicStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
    font-family: ${({theme}) => theme.fonts.body};
    font-size: ${({theme}) => theme.fontSizes.text.small};
    font-weight: normal;
    line-height: ${({theme}) => theme.lineHeights.text.base};
    background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
    font-style: normal;
    height: 100%;
    overflow: hidden;
    overscroll-behavior-y: none;

    @media print {
      background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
    }
  }

  #root {
    height: 100%;
    position: relative;
    overflow: auto;
    overscroll-behavior: contain;
  }
`;

export function GlobalStyles() {
  return (
    <>
      <Normalize />
      <BasicStyles />
    </>
  );
}
