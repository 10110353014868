import styled from 'styled-components';

import {cloneElement, ReactElement, ReactNode, useMemo} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {flattenReactNode} from './flattenReactNode';

export interface BreadcrumbProps extends TestIdProps {
  children: ReactNode;
}

export function Breadcrumb(props: BreadcrumbProps) {
  const flatChildren = useMemo<ReactNode[]>(
    () => flattenReactNode(props.children),
    [props.children]
  );

  const count = flatChildren.length;

  const clones = flatChildren.map((child, index) =>
    cloneElement(child as ReactElement, {
      isLastChild: count === index + 1,
    })
  );

  return (
    <StyledBreadcrumbList data-testid={suffixTestId('breadcrumb', props)}>
      {clones}
    </StyledBreadcrumbList>
  );
}

const StyledBreadcrumbList = styled.ol`
  display: flex;
`;
