import {
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {array, object} from 'yup';

import {isNil} from 'ramda';

import {
  useGetEmployeeOrgranisationalDataQuery,
  useGetTenantQuery,
  usePatchEmployeeOrganisationalDataMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {DEFAULT_CURRENCY, handleApiError, Section} from '@omnetic-dms/shared';

import {suffixTestId, yupString, TestIdProps} from 'shared';

import {EMPTY_BRANCH} from '../../constants/emptyBranchObject';
import {useEmployeeUrl} from '../../hooks/useEmployeeUrl';
import {EmployeeAllocation} from './components/EmployeeAllocation';
import {useBillingInformation} from './hooks/useBillingInformation';
import {EmployeeOrganizationalForm} from './types/employeeOrganizationalFormType';
import {getOrganizationPlacementFormDefaultValues} from './utils/getOrganizationPlacementFormDefaultValues';

interface OrganizationalPlacementProps extends TestIdProps {}

export function OrganizationalPlacement(props: OrganizationalPlacementProps) {
  const {employeeId} = useEmployeeUrl();

  const {data: {currency: defaultCurrency} = {}} = useGetTenantQuery();

  const {options: billingInformationOptions} = useBillingInformation();

  const {data, isLoading, isError} = useGetEmployeeOrgranisationalDataQuery({employeeId});
  const [patchEmployeeOrganisationalData] = usePatchEmployeeOrganisationalDataMutation();

  const onSubmit: FormSubmitHandler<EmployeeOrganizationalForm> = async (data) => {
    await patchEmployeeOrganisationalData({
      employeeId,
      body: {
        note: data?.note,
        organisationalData: {
          companyId: data.companyId ?? '',
          branches: data?.branches.map((branch) => ({
            ...branch,
            branchId: branch.branchId ?? '',
            professionId: branch?.professionId,
            authorizations: branch.authorizations.map((authorization) => ({
              ...authorization,
              authorizationProfileId: authorization.authorizationProfileId ?? '',
            })),
            workshopData: {
              ...branch.workshopData,
              performancePercentage: isNil(branch.workshopData.hourlyRate)
                ? undefined
                : (branch.workshopData?.performancePercentage ?? 0) / 100,
              hourlyRate: isNil(branch.workshopData.hourlyRate)
                ? undefined
                : {
                    amount: branch.workshopData.hourlyRate ?? 0,
                    currency: defaultCurrency ?? DEFAULT_CURRENCY,
                  },
            },
          })),
        },
      },
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .catch(handleApiError);
  };

  return (
    <Section>
      <DataStatus isLoading={isLoading} isError={isError} minHeight={140}>
        <Form<EmployeeOrganizationalForm>
          schema={schema}
          onSubmit={onSubmit}
          defaultValues={getOrganizationPlacementFormDefaultValues(data)}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <Card title={i18n.t('page.employeeDetail.labels.organizationalClassification')}>
                    <VStack spacing={4}>
                      <FormField
                        type="choice"
                        name="companyId"
                        control={control}
                        label={i18n.t('general.labels.company')}
                        isRequired
                        options={billingInformationOptions}
                        onChange={() => formApi.setValue('branches', [EMPTY_BRANCH])}
                        isNotClearable
                        data-testid={suffixTestId('company', props)}
                      />
                      <EmployeeAllocation
                        control={control}
                        formApi={formApi}
                        data-testid={suffixTestId('allocation', props)}
                      />
                    </VStack>
                  </Card>
                </Box>
                <Box flex={1}>
                  <Card title={i18n.t('general.labels.notes')}>
                    <FormField
                      type="textarea"
                      name="note"
                      control={control}
                      minRows={5}
                      data-testid={suffixTestId('note', props)}
                    />
                  </Card>
                </Box>
              </HStack>
              <ButtonGroup align="right">
                <Button
                  variant="secondary"
                  title={i18n.t('general.actions.discard')}
                  onClick={() => formApi.reset(getOrganizationPlacementFormDefaultValues(data))}
                  data-testid={suffixTestId('discard', props)}
                />
                <FormButton
                  control={control}
                  type="submit"
                  variant="primary"
                  title={i18n.t('general.actions.saveChanges')}
                  data-testid={suffixTestId('save', props)}
                />
              </ButtonGroup>
            </VStack>
          )}
        </Form>
      </DataStatus>
    </Section>
  );
}

const schema = object({
  companyId: yupString.required(),
  branches: array().of(
    object({
      branchId: yupString.required(),
    })
  ),
});
