import {match} from 'ts-pattern';

import {always} from 'ramda';

import {OrderDiscriminator, PaymentDiscriminator} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {buildArray} from 'shared';

export const getPaymentMethodOptions = (
  orderDiscriminator: OrderDiscriminator,
  paymentDiscriminator: PaymentDiscriminator
) => {
  const canOrderByPaidByOffset = match(orderDiscriminator)
    .with('SALE', 'PURCHASE', 'PURCHASE_BROKERAGE_SALE', always(true))
    .otherwise(always(false));

  const canPaymentBePaidByOffset = match(paymentDiscriminator)
    .with('BALANCE', 'PURCHASE', always(true))
    .otherwise(always(false));

  return buildArray([
    {value: 'CASH', label: i18n.t('entity.invoice.paymentMethod.cash')},
    {value: 'PAYMENT_CARD', label: i18n.t('entity.invoice.paymentMethod.card')},
    {value: 'BANK_TRANSFER', label: i18n.t('entity.invoice.paymentMethod.bankTransfer')},
  ]).when(canOrderByPaidByOffset && canPaymentBePaidByOffset, {
    value: 'OFFSET',
    label: i18n.t('entity.invoice.paymentMethod.offset'),
  });
};
