import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isBoolean, isString} from 'ramda-adjunct';

import {DefaultColWidth} from '../constants/defaultColWidth';
import {ColDef} from '../types/AgGridTypes';
import {
  ColumnResponseBody,
  GetDataQueryResponse,
  TransformedDefinitionApiResponse,
} from '../types/Api';
import {ColumnState, SortState} from '../types/Preset';

export const columnToBasicColDefs = (
  dataQuery: GetDataQueryResponse,
  definition: TransformedDefinitionApiResponse,
  column: ColumnResponseBody,
  defaultPresetColumn?: ColumnState,
  defaultPresetOrder?: SortState,
  defaultPresetOrderIndex?: number
): Partial<ColDef> => {
  // Hide columns that are included in definition but not in preset. Only exception is “simple grid“ (grid with “toolPanelColumn : false“)
  const isNewlyAddedColumnVisible = !definition.behavior.columnToolPanelVisible;

  const sort = dataQuery.order
    .find((ord) => ord.columnKey === column.key)
    ?.order?.toLocaleLowerCase() as 'asc' | 'desc';

  const pinned = match(defaultPresetColumn?.pinned)
    .when(isBoolean, (pinned) => pinned)
    .when(isString, (pinned) => pinned.toLowerCase() as ColDef['pinned'])
    .otherwise(always(undefined));

  return {
    headerName: column.title,
    field: column.key,
    cellClass: 'ellipsis',
    pinned,
    hide: !(defaultPresetColumn?.visible ?? isNewlyAddedColumnVisible),
    width: defaultPresetColumn?.width ? parseInt(defaultPresetColumn?.width) : DefaultColWidth,
    initialWidth: defaultPresetColumn?.width
      ? parseInt(defaultPresetColumn?.width)
      : DefaultColWidth,
    sort: sort ?? null,
    sortIndex: defaultPresetOrderIndex,
    sortable: column.sortable,
    toolPanelClass: `columnSettings-${column.key}`,
  };
};
