import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {captureException} from '@sentry/browser';
import flagsmith from 'flagsmith';

import {isString} from 'ramda-adjunct';

import {appWorkspaceKey, browserStorageKey, redirectLink} from '@omnetic-dms/config';
import {environment} from '@omnetic-dms/environment';
import {loginRoutes} from '@omnetic-dms/routes';

import {BaseQueryExtraOptions, parseZodSchema, ParseZodSchemaQueryInfo} from 'shared';

import {selectActiveBranchId} from '../../features/branchSelectors';
import {RefreshTokenApiArg, TenantResponseBody} from '../../types/api';
import {publicApi} from '../publicApi';
import {selectTenant} from '../tenantApi';
import {buildUrl} from './utils/buildUrl';
import {getWorkspaceFromUri} from './utils/getWorkspaceFromUri';
import {handleClientNotification} from './utils/handleClientNotification';

const isDev = environment.ENV_TYPE === 'dev';

const getArgsWithAuthorization = (
  args: FetchArgs,
  branch?: string,
  tenantData?: TenantResponseBody
) => {
  const accessToken = sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);
  const {workspace} = getWorkspaceFromUri();

  return {
    ...args,
    headers: {
      ...args.headers,
      'accept-language': localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE) || 'en',
      ...(accessToken ? {authorization: `Bearer ${accessToken}`} : {}),
      // TODO - Blocked by CORS on METADA
      // 'x-country-format': 'iso', // https://carvago.slack.com/archives/G01AN5XCXGX/p1634202539053900
      ...(branch ? {'X-Branch': branch} : {}),
      ...(workspace ? {'X-Workspace': workspace} : {}),
      ...(tenantData ? {'X-Country': tenantData.country} : {}),
      ...(tenantData ? {'X-Currency': tenantData.currency} : {}),
    },
  };
};

const redirectToLoginPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(redirectLink);

  const query = searchParams.toString();

  const redirectUrl = `${window.location.pathname}${query ? `?${query}` : ''}${
    window.location.hash
  }`;

  sessionStorage.setItem(redirectLink, redirectUrl);

  return `${loginRoutes.login}`;
};

const fetchBaseQueryInstance = fetchBaseQuery({baseUrl: environment.METADA_URL});

interface CustomFetchArgs extends FetchArgs {
  queryArg?: Record<string, unknown> | void;
}

export const metadaBaseQuery: BaseQueryFn<
  CustomFetchArgs,
  unknown,
  FetchBaseQueryError,
  BaseQueryExtraOptions
> = async (args, api, extraOptions) => {
  const activeBranchId = selectActiveBranchId(api.getState() as any);

  const tenant = selectTenant(api.getState() as any);
  const tenantData = tenant.data;

  const queryInfo: Omit<ParseZodSchemaQueryInfo, 'schemaType'> = {
    method: args.method,
    endpointName: api.endpoint,
    url: args.url,
  };

  if (isDev && extraOptions && extraOptions.requestSchema) {
    parseZodSchema({
      data: args.queryArg,
      schema: extraOptions.requestSchema,
      queryInfo: {
        ...queryInfo,
        schemaType: 'request',
      },
    });
  }

  let result = await fetchBaseQueryInstance(
    getArgsWithAuthorization(args, activeBranchId, tenantData),
    api,
    extraOptions
  );

  if (isDev && extraOptions && extraOptions.responseSchema && 'data' in result) {
    parseZodSchema({
      data: result.data,
      schema: extraOptions.responseSchema,
      queryInfo: {
        ...queryInfo,
        schemaType: 'response',
      },
    });
  }

  if (result.error && Number(result.error.status) >= 500) {
    captureException(result.error);
  }

  if (
    result.error &&
    (result.error.status === 401 ||
      (isString(result?.error?.data) && result?.error?.data.includes('authorization')) ||
      (isString(result?.error?.data) && result?.error?.data.includes('Unauthorized')))
  ) {
    const getRefreshToken = localStorage.getItem(browserStorageKey.REFRESH_TOKEN);
    const {workspace, shouldRedirectToAppWorkspace} = getWorkspaceFromUri();

    if (!workspace || shouldRedirectToAppWorkspace) {
      window.location.href = buildUrl(loginRoutes.loginWorkspace, appWorkspaceKey);
      return result;
    }

    if (!getRefreshToken) {
      window.location.href = buildUrl(redirectToLoginPage(), undefined, false);
      return result;
    }

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('directLink');

    const refreshTokenArgs: RefreshTokenApiArg = {
      workspace,
      refreshTokenRequestBody: {
        refreshToken: getRefreshToken,
      },
    };

    await api
      .dispatch(publicApi.endpoints.refreshToken.initiate(refreshTokenArgs))
      .unwrap()
      .then(({refreshToken, token}) => {
        sessionStorage.setItem(browserStorageKey.ACCESS_TOKEN, token);
        localStorage.setItem(browserStorageKey.REFRESH_TOKEN, refreshToken);
      })
      .catch(() => {
        sessionStorage.removeItem(browserStorageKey.ACCESS_TOKEN);
        localStorage.removeItem(browserStorageKey.REFRESH_TOKEN);
        flagsmith.logout();

        window.location.href = buildUrl(redirectToLoginPage(), undefined, false);
      });

    result = await fetchBaseQueryInstance(
      getArgsWithAuthorization(args, activeBranchId),
      api,
      extraOptions
    );
  }

  // Status 299 indicates an issue with the client's payload without blocking the flow.
  // Message from BE should be displayed to the client.
  if (result.meta?.response?.status === 299) {
    handleClientNotification(result.meta?.response?.headers);
  }

  return result;
};
