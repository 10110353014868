import {Active, Over} from '@dnd-kit/core';

import {isNil} from 'ramda';

import {parseDndItemId} from './parseDndItemId';

export const computePositionFromCollision = (active: Active, over: Over): number => {
  const {position: droppedOntoItemIndex} = parseDndItemId(over.id);

  if (isNil(droppedOntoItemIndex)) {
    return -1;
  }

  const isLeftOfOverItem =
    over &&
    active.rect.current.translated &&
    active.rect.current.translated.right < over.rect.left + over.rect.width / 2;

  const modifier = isLeftOfOverItem ? 0 : 1;
  const index = droppedOntoItemIndex >= 0 ? droppedOntoItemIndex + modifier : 0;

  return index;
};
