import {createSelector} from '@reduxjs/toolkit';

import type {TeasState} from '../../types/TeasState';

export const selectDocumentRoundingDefinitionsState = (state: TeasState) =>
  state.generalSettings.documentRoundingDefinitions;

export const selectDocumentRoundingDefinitionsList = createSelector(
  selectDocumentRoundingDefinitionsState,
  (state) => state?.items || []
);

export const selectRoundingStepList = createSelector(
  selectDocumentRoundingDefinitionsState,
  (state) => state.roundingStepList
);

export const selectDocumentRoundingDefinitionDetailId = createSelector(
  selectDocumentRoundingDefinitionsState,
  (state) => state.detail?.id
);
export const selectDocumentRoundingDefinitionDetail = createSelector(
  selectDocumentRoundingDefinitionsState,
  (state) => state.detail?.data
);
