import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';

/**
 * @about Hook to manage state via query string in url
 * @param key key for query params
 * @returns [value, setValue, clearValue]
 * @example
 * const [value, setValue, clearValue] = useQueryState("key")
 */
export function useQueryState(key: string) {
  const [searchParams, setSearchParams] = useSearchParams();

  const setValue = useCallback(
    (updatedValue: string) => {
      const params = new URLSearchParams(window.location.search);
      params.set(key, updatedValue);
      setSearchParams(params);
    },
    [key, setSearchParams]
  );

  const clearValue = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    params.delete(key);
    setSearchParams(params);
  }, [key, setSearchParams]);

  return [searchParams.get(key), setValue, clearValue] as const;
}
