import {match, Pattern} from 'ts-pattern';

import {always} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

type PaymentType = 'BANK_TRANSFER' | 'PAYMENT_CARD' | 'CASH' | 'OFFSET';
export const getPaymentTypeFromMethod = (
  paymentMethod: string | Nullish,
  isPurchaseOrder: boolean
): PaymentType =>
  match<[string | Nullish, boolean], PaymentType>([paymentMethod, isPurchaseOrder])
    .with(['OFFSET', true], always('OFFSET'))
    .with(['OFFSET', false], always('BANK_TRANSFER'))
    .with(['CARD', Pattern.boolean], ['PAYMENT_CARD', Pattern.boolean], always('PAYMENT_CARD'))
    .when(([val]) => isNilOrEmpty(val), always('BANK_TRANSFER'))
    .otherwise(always(paymentMethod as PaymentType));
