import {Option} from 'platform/components';
import {Nullish} from 'utility-types';

import {
  GetApiMakeModelWithMakeApiResponse,
  useLazyGetCustomTenantCatalogueQuery,
  useLazyGetMakeModelWithMakeQuery,
  VehicleTypeEnumObject,
} from '@omnetic-dms/api';
import i18n, {FALLBACK_LANGUAGE} from '@omnetic-dms/i18n';

import {vehicleModelParser} from '../utils/vehicleModelParser';

interface DecodeModelProps {
  vehicleType: VehicleTypeEnumObject | Nullish;
  make: string | Nullish;
  unknownModels?: Option[];
}

export function useLazyVehicleModel() {
  const [getVehicleModel, vehicleModelApi] = useLazyGetMakeModelWithMakeQuery();
  const [getCustomTenantCatalogue, customTenantCataloguqApi] =
    useLazyGetCustomTenantCatalogueQuery();

  const isLoading = vehicleModelApi.isLoading || customTenantCataloguqApi.isLoading;
  const isError = vehicleModelApi.isError || customTenantCataloguqApi.isError;

  const decodeModel = async (props: DecodeModelProps) => {
    const unknownModels = props.unknownModels || [];

    let vehicleModelData: GetApiMakeModelWithMakeApiResponse | Nullish = null;

    if (props.vehicleType && props.make) {
      const vehicleModelQuery = await getVehicleModel(
        {
          vehicleType: props.vehicleType,
          make: props.make,
          lang: [i18n.resolvedLanguage ?? FALLBACK_LANGUAGE],
        },
        true
      ).catch(() => null);

      vehicleModelData = vehicleModelQuery?.data;
    }

    const customTenantMakeModelsQuery = await getCustomTenantCatalogue(undefined, true).catch(
      () => null
    );

    return vehicleModelParser({
      vehicleType: props.vehicleType,
      make: props.make,
      unknownModels,
      vehicleModelData,
      customTenantMakeModelData: customTenantMakeModelsQuery?.data,
    });
  };

  return {decodeModel, isLoading, isError};
}
