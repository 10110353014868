import {Card, FormControl, Label, Separator} from 'platform/components';
import {Box, Grid, GridItem, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {Fragment} from 'react';

import {InvoiceV3ResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {cashReceiptItemUnits, PERCENTAGE_SIGN} from '../constants';
import {
  AccountingDocumentFormValues,
  DocumentSummary,
  ExchangeRateRatioDocumentSummary,
} from '../types';
import {AdditionalVatCalculation} from './AdditionalVatCalculation';
import {RoundingSummary} from './RoundingSummary';
import {Summary} from './Summary';
import {VatSummary} from './VatSummary';

interface CreateInvoiceListOfItems extends TestIdProps {
  items: InvoiceV3ResponseBody['items'];
  summary: DocumentSummary;
  exchangeRateRatioCalculation?: ExchangeRateRatioDocumentSummary;
  control: FormControl<AccountingDocumentFormValues>;
  cashRegisterId?: string;
  isCashReceipt?: boolean;
}

export function ListOfItemsDetail(props: CreateInvoiceListOfItems) {
  const formatCurrency = useFormatCurrency();

  const summary = props.exchangeRateRatioCalculation ?? props.summary;

  const displayItems = props.items.map((item, index) => {
    const isFirst = index === 0;
    const isLast = index === props.items.length - 1;
    const nextItem = props.items[index + 1];
    const prevItem = props.items[index - 1];

    const hasBottomSeparator = item.type !== 'standard' && nextItem?.type === 'standard' && !isLast;
    const hasUpperSeparator =
      item.type === 'standard' &&
      nextItem?.type !== 'standard' &&
      prevItem?.type === 'standard' &&
      !isFirst &&
      !isLast;

    return {...item, hasBottomSeparator, hasUpperSeparator};
  });

  return (
    <Card title={i18n.t('general.labels.listOfItems')}>
      <VStack spacing={2}>
        <Box width="100%">
          <Grid columns={35} key="item-labels" align="flex-end" spacing={1}>
            <GridItem span={8}>
              <Label>{i18n.t('general.labels.name')}</Label>
            </GridItem>
            <GridItem span={3}>
              <Label>{i18n.t('entity.order.itemQuantity')}</Label>
            </GridItem>
            <GridItem span={3}>
              <Label>{i18n.t('entity.lineItems.labels.unit')}</Label>
            </GridItem>
            <GridItem span={4}>
              <Label>{i18n.t('general.labels.unitPrice')}</Label>
            </GridItem>
            <GridItem span={4}>
              <Label>{i18n.t('general.labels.withVat')}</Label>
            </GridItem>
            <GridItem span={5}>
              <Label>{i18n.t('entity.lineItems.labels.totalPriceWithoutVat')}</Label>
            </GridItem>
            <GridItem span={3}>
              <Label>{i18n.t('general.labels.vatType')}</Label>
            </GridItem>
            <GridItem span={5}>
              <Label>{i18n.t('entity.lineItems.labels.totalPriceWithVat')}</Label>
            </GridItem>

            {displayItems.map((item, index) => (
              <Fragment key={item.itemId}>
                <Show when={item.hasUpperSeparator}>
                  <GridItem span={35}>
                    <Separator spacing={0} />
                  </GridItem>
                </Show>
                <GridItem span={8}>
                  <Text size="small" data-testid={suffixTestId(`[${index}]-name`, props)}>
                    {item.description}
                  </Text>
                </GridItem>

                <GridItem span={3}>
                  <Text size="small" data-testid={suffixTestId(`[${index}]-quantity`, props)}>
                    {item.type === 'discount' && item.unit === PERCENTAGE_SIGN
                      ? item.discount?.percent
                      : item.quantity}
                  </Text>
                </GridItem>
                <GridItem span={3}>
                  <Text size="small">
                    {props.isCashReceipt
                      ? (cashReceiptItemUnits.find((unit) => unit.value === item.unit)?.label ??
                        i18n.t('general.metric.pcs'))
                      : (item.unit ?? i18n.t('general.metric.pcs'))}
                  </Text>
                </GridItem>
                <GridItem span={4}>
                  <Text size="small" data-testid={suffixTestId(`[${index}]-unitPrice`, props)}>
                    {formatCurrency(
                      parseFloat(item.pricePerUnit.amount),
                      item.pricePerUnit.currency,
                      2
                    )}
                  </Text>
                </GridItem>
                <GridItem span={4}>
                  <Text size="small">
                    {item.isUnitPriceWithVat
                      ? i18n.t('general.labels.yes')
                      : i18n.t('general.labels.no')}
                  </Text>
                </GridItem>
                <GridItem span={5}>
                  <Text
                    size="small"
                    data-testid={suffixTestId(`[${index}]-totalPriceWithoutVAT`, props)}
                  >
                    {formatCurrency(
                      parseFloat(item.totalPrice.base.amount),
                      item.totalPrice.base.currency,
                      2
                    )}
                  </Text>
                </GridItem>
                <GridItem span={3}>
                  <Text size="small" data-testid={suffixTestId(`[${index}]-vatRate`, props)}>
                    {item.totalPrice.vatRate} %
                  </Text>
                </GridItem>
                <GridItem span={5}>
                  <Text
                    size="small"
                    data-testid={suffixTestId(`[${index}]-totalPriceWithVat`, props)}
                  >
                    {formatCurrency(
                      parseFloat(item.totalPrice.total.amount),
                      item.totalPrice.total.currency,
                      2
                    )}
                  </Text>
                </GridItem>
                <Show when={item.hasBottomSeparator}>
                  <GridItem span={35}>
                    <Separator spacing={0} />
                  </GridItem>
                </Show>
              </Fragment>
            ))}
          </Grid>
        </Box>

        <Separator spacing={2} />
        <VStack spacing={4}>
          <AdditionalVatCalculation
            vatTypeSummary={props.summary.vatTypesSummary}
            control={props.control}
            data-testid={suffixTestId('AdditionalVatCalculation', props)}
          />

          <VatSummary vatTypeSummary={props.summary.vatTypesSummary} control={props.control} />

          <RoundingSummary
            roundingItems={summary.summaryItems}
            vatTypeSummary={props.summary.vatTypesSummary}
          />

          <Summary
            summary={props.summary}
            exchangeRateRatioCalculation={props.exchangeRateRatioCalculation}
            data-testid={suffixTestId('summary', props)}
          />
        </VStack>
      </VStack>
    </Card>
  );
}
