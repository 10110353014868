import {PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {showNotification} from 'platform/components';
import {all, debounce, fork, put, select, takeEvery, takeLeading, call} from 'typed-redux-saga';

import {is} from 'ramda';

import {RoleService} from '../../services/RoleService';
import {DeleteRoleType, TCreateUserRoleAction} from '../../types/UserManagementTypes';
import {callApiSaga} from '../../utils/api';
import {getStatusCode, isAxiosError} from '../../utils/customError';
import {getErrorMessages} from '../../utils/getErrorMessage';
import {getStringErrorMessage} from '../../utils/getStringErrorMessage';
import {
  createRoleRequest,
  createRoleSuccess,
  deleteRoleRequest,
  deleteRoleSuccess,
  fetchFullRoleError,
  fetchFullRoleRequest,
  fetchFullRoleSuccess,
  fetchRoleError,
  fetchRoleRequest,
  fetchRoleSuccess,
  updateSingleRoleAction,
  updateSingleRolePermissionListAction,
} from './reducer';
import {selectSingleRoleData} from './selectors';

const isString = is(String);

function* handlingError(error: AxiosError | Error, addInfo?: string): Generator {
  const axiosError = isAxiosError(error);
  const title = axiosError ? String(getStatusCode(error)) : error;
  const subtitle = addInfo ? addInfo : axiosError ? getErrorMessages(error) : error;

  showNotification.error(`${title}: ${subtitle}`);
}

function* fetchRoleList(): Generator {
  // TODO params with [BE]
  try {
    const response = yield* callApiSaga(RoleService.getRolesForRoleManagement, {});
    yield* put(fetchRoleSuccess(response));
  } catch (error: any) {
    yield* put(fetchRoleError(error));
    yield* call(handlingError, error as AxiosError);
  }
}

function* fetchAllACLRoles(): Generator {
  // TODO params with [BE]
  try {
    const response = yield* callApiSaga(RoleService.getRoles, {});
    yield* put(fetchFullRoleSuccess(response));
  } catch (error: any) {
    yield* put(fetchFullRoleError(error));
    yield* call(handlingError, error as AxiosError);
  }
}

function* deleteSingleRoleById({
  payload: {roleId, successMessage},
}: PayloadAction<DeleteRoleType>): Generator {
  try {
    yield* callApiSaga(RoleService.deleteRole, {roleId: roleId ?? ''});
    yield* put(deleteRoleSuccess({roleId}));
    showNotification.success(successMessage);
  } catch (error: any) {
    showNotification.error(getStringErrorMessage(error));
  }
}

function* patchSinglePermissionRole(): Generator {
  try {
    const data = yield* select(selectSingleRoleData);
    yield* callApiSaga(RoleService.patchRole, {roleId: data.id, requestBody: data});
  } catch (error: any) {
    yield* call(handlingError, error as AxiosError);
  }
}

function* debounceAutocomplete(): Generator {
  const duration = 300;
  yield* debounce(duration, updateSingleRolePermissionListAction, patchSinglePermissionRole);
  yield* debounce(duration, updateSingleRoleAction, patchSinglePermissionRole);
}

function* createNewRoleSaga({
  payload: {values, successMessage},
}: PayloadAction<TCreateUserRoleAction>): Generator {
  try {
    yield* callApiSaga(RoleService.createRole, {requestBody: values});
    yield* put(createRoleSuccess({isSuccess: true}));
    yield* put(createRoleSuccess({isSuccess: false}));
    yield* call(() =>
      showNotification.success(isString(successMessage) ? successMessage : undefined)
    );
    yield* call(fetchRoleList);
  } catch (error: any) {
    showNotification.error(getStringErrorMessage(error));
  }
}

export function* userManagementSaga(): Generator {
  yield* all([
    takeLeading(fetchRoleRequest, fetchRoleList),
    takeLeading(fetchFullRoleRequest, fetchAllACLRoles),
    takeEvery(deleteRoleRequest, deleteSingleRoleById),
    takeLeading(createRoleRequest, createNewRoleSaga),
    fork(debounceAutocomplete),
  ]);
}
