import {Checkbox} from 'platform/components';
import {Box, HStack, Show, Text, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {concat} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {AuditParamDefinition, AuditParamValue, useGetCurrentUserInfoQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {StructureSettingsForm} from '../types/StructureSettingsForm';
import {getLabel} from '../utils/getLabel';
import {getTranslation} from '../utils/getTranslation';

interface ParameterProps {
  parameter: AuditParamDefinition;
  isParentActive: boolean;
  formApi: UseFormReturn<StructureSettingsForm>;
}

export function Parameter(props: ParameterProps) {
  const {data: userInfo} = useGetCurrentUserInfoQuery();

  const handleSelectChange = (value: boolean) => {
    props.formApi.setValue(`paramDefinitions.${props.parameter.id}.isChecked`, value);
  };

  const handleMandatoryChange = (value: boolean) => {
    props.formApi.setValue(`paramDefinitions.${props.parameter.id}.isMandatory`, value);
  };

  const getAllParams = (param: AuditParamValue) =>
    concat(param.content?.relatedActions ?? [], param.content?.additionalInformation ?? []);

  const isChecked = props.formApi.watch('paramDefinitions')[props.parameter.id]?.isChecked ?? true;
  const isMandatory =
    props.formApi.watch('paramDefinitions')[props.parameter.id]?.isMandatory ?? false;

  return (
    <>
      <HStack>
        <Box flex={1}>
          <Checkbox
            label={
              getTranslation(props.parameter.label, userInfo?.settings.language) ||
              getLabel(props.parameter.type)
            }
            value={isChecked}
            onChange={handleSelectChange}
            isDisabled={!props.isParentActive}
          />
        </Box>
        <Box flex={1}>
          <Checkbox
            label={i18n.t('page.vehicleSettings.labels.mandatory')}
            value={isMandatory}
            isDisabled={!isChecked}
            onChange={handleMandatoryChange}
          />
        </Box>
      </HStack>
      <Show when={isNotNilOrEmpty(props.parameter.values)}>
        {props.parameter.values?.map((value) => (
          <Show when={isNotNilOrEmpty(value.content)} key={value.value}>
            <Box paddingLeft={4}>
              <VStack spacing={4}>
                <Text size="small" alternative>
                  {getTranslation(value.label, userInfo?.settings.language)}
                </Text>
                {getAllParams(value).map((param) => (
                  <Parameter
                    key={param.id}
                    parameter={param}
                    formApi={props.formApi}
                    isParentActive={true}
                  />
                ))}
              </VStack>
            </Box>
          </Show>
        ))}
      </Show>
    </>
  );
}
