import {configureStore, Middleware, Store} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import axios from 'axios';

import {FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import {publicApi, cachedApi, omneticApi, metadaApi, fileStorageApi} from '@omnetic-dms/api';
import {DEFAULT_LANG, createCallApi, AppDispatch, ThunkExtra} from '@omnetic-dms/teas';

import {reducers} from './reducers';
import {rootSaga} from './rootSaga';

interface AppStore extends Store {
  dispatch: AppDispatch;
}

const initStore = () => {
  const context: Partial<ThunkExtra> = {};

  const setAcceptLanguageMiddleware: Middleware = (store) => (next) => (action) => {
    axios.defaults.headers.common['Accept-Language'] =
      store.getState()?.lang?.value || DEFAULT_LANG;

    return next(action);
  };

  const sagaMiddleware = createSagaMiddleware({
    context,
  });

  const store: AppStore = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => [
      sagaMiddleware,
      setAcceptLanguageMiddleware,
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        immutableCheck: false,
        thunk: {
          extraArgument: context,
        },
      }).concat(
        omneticApi.middleware,
        metadaApi.middleware,
        fileStorageApi.middleware,
        publicApi.middleware,
        cachedApi.middleware
      ),
    ],
  });

  context.callApi = createCallApi(store);

  sagaMiddleware.run(rootSaga);

  return store;
};

export const store = initStore();
export const persistor = persistStore(store);

setupListeners(store.dispatch);
