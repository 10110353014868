import {VehicleWidgetData} from './types/VehicleWidgetData';

export function mapVehicleProps(vehicle: VehicleWidgetData) {
  return {
    ...vehicle,
    vehicleType: vehicle.vehicleType ?? vehicle.type,
    driveType: vehicle.driveType ?? vehicle.drive,
    firstRegistration: vehicle.firstRegistration ?? vehicle.firstRegistrationOn,
  };
}
