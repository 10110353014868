import {object, mixed, string} from 'yup';

import i18n from '@omnetic-dms/i18n';

const MINIMUM_ALLOWED_NUMBER = 0.01;

const $DefaultPricingFormSchema = object({
  reason: string().nullable().default(null),
});

const testNumber = mixed().test(
  'number-value-amount',
  i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
  (val) => !val || parseFloat(val) >= MINIMUM_ALLOWED_NUMBER
);

export const $VatNotDeductibleSchema = $DefaultPricingFormSchema.shape({
  maxBuyingPrice: object().shape({
    priceNotDeductible: object().shape({
      amount: testNumber,
    }),
  }),
  sellingPrice: object().shape({
    priceNotDeductible: object().shape({
      amount: testNumber,
    }),
  }),
});

export const $VatDeductibleSchema = $DefaultPricingFormSchema.shape({
  maxBuyingPrice: object().shape({
    priceWithVat: object().shape({
      amount: testNumber,
    }),
    priceWithoutVat: object().shape({
      amount: testNumber,
    }),
  }),
  sellingPrice: object().shape({
    priceWithVat: object().shape({
      amount: testNumber,
    }),
    priceWithoutVat: object().shape({
      amount: testNumber,
    }),
  }),
});
