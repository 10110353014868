import {Dialog} from 'platform/components';
import {Text} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface UpdateMileageFuelTankConfirmProps extends RequiredTestIdProps {
  dialogId: string;
  isOpen: boolean;
  onResult: (updateMileageFuelTank: boolean) => void;
}

export function UpdateMileageFuelTankConfirm(props: UpdateMileageFuelTankConfirmProps) {
  return (
    <Dialog
      size="small"
      id={props.dialogId}
      isOpen={props.isOpen}
      buttons={[
        {
          variant: 'secondary',
          onClick: () => props.onResult(false),
          title: i18n.t('general.labels.no'),
          'data-testid': suffixTestId('confirmDialog-cancel', props),
        },
        {
          variant: 'primary',
          onClick: () => props.onResult(true),
          title: i18n.t('general.labels.yes'),
          'data-testid': suffixTestId('confirmDialog-confirm', props),
        },
      ]}
      data-testid={suffixTestId('confirmDialog', props)}
    >
      <Text>{i18n.t('entity.order.labels.updateMileageFuelTank')}</Text>
    </Dialog>
  );
}
