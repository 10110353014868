import * as Yup from 'yup';

import {yupString} from 'shared';

export const yupBankAccount = Yup.object().shape({
  id: yupString.optional(),
  bankAccountData: Yup.object().shape({
    name: yupString.optional(),
    countryCode: yupString.optional(),
    ownerName: yupString.optional(),
    iban: yupString.optional(),
    swiftBic: yupString.optional(),
    currency: yupString.optional(),
    number: yupString.optional(),
    bankCode: yupString.optional(),
  }),
});
