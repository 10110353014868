import {openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  usePatchDictionaryWorktypeIsDefaultMutation,
  useDeleteWorkTypeMutation,
  usePostWorkTypeSetAsActiveMutation,
  usePostWorkTypeSetAsInactiveMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function WorkType() {
  const navigate = useNavigate();
  const [patchWorkTypeIsDefault] = usePatchDictionaryWorktypeIsDefaultMutation();
  const [deleteWorkType] = useDeleteWorkTypeMutation();
  const [postWorkTypeSetAsActive] = usePostWorkTypeSetAsActiveMutation();
  const [postWorkTypeSetAsInactive] = usePostWorkTypeSetAsInactiveMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', 'edit', () =>
        navigate(composePath(settingsRoutes.workTypeDetail, {params: {id}}))
      )
      .with('setAsDefault', () =>
        patchWorkTypeIsDefault({workTypeId: id}).unwrap().then(refreshData).catch(handleApiError)
      )
      .with('setActive', () =>
        postWorkTypeSetAsActive({workTypeId: id}).unwrap().then(refreshData).catch(handleApiError)
      )
      .with('setInactive', () =>
        postWorkTypeSetAsInactive({workTypeId: id}).unwrap().then(refreshData).catch(handleApiError)
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteWorkType({workTypeId: id}).unwrap().then(refreshData).catch(handleApiError),
        })
      )
      .otherwise(always(undefined));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.order.labels.workType'),
        actions: [
          {
            type: 'button',
            title: i18n.t('page.settings.actions.newWorkType'),
            onClick: () => navigate(settingsRoutes.workTypeCreate),
          },
        ],
      }}
      data-testid={testIds.settings.workType('template')}
    >
      <DataGrid
        gridCode="service-work-type"
        actionCallback={actionCallback}
        data-testid={testIds.settings.workType('datagrid')}
      />
    </SettingsTemplate>
  );
}
