import {CountryCode} from 'libphonenumber-js';
import {formatPhoneNumber} from 'platform/locale';

import {isEmpty, isNil, reject} from 'ramda';

import {BusinessCaseResponseBody, CustomerResponseBodyV2} from '@omnetic-dms/api';
import {getNaturalPersonFullName} from '@omnetic-dms/shared';

import {EMPTY_PLACEHOLDER, Nullish} from 'shared';

export const getContactPersonData = (
  businessCase: BusinessCaseResponseBody | undefined,
  customer: CustomerResponseBodyV2 | undefined
) => {
  const person =
    customer?.contacts.find((person) => businessCase?.contactPersonId === person.id) ||
    customer?.foundingPerson;

  const phoneNumber = person?.phoneNumbers?.[0] || null;
  const email = person?.emails?.[0]?.email || null;

  const number = `${phoneNumber?.prefix}${phoneNumber?.number}`;

  const name = getNaturalPersonFullName(person);

  const contactPersonPhoneNumber = phoneNumber
    ? formatPhoneNumber(number, phoneNumber.countryCode as CountryCode | Nullish)
    : null;

  const contactInfo = reject(isNil, [contactPersonPhoneNumber, email]);
  const info = isEmpty(contactInfo) ? [EMPTY_PLACEHOLDER] : contactInfo;

  return {
    name,
    info,
  };
};
