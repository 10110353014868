import {Card, DataStatus, Separator} from 'platform/components';
import {Show} from 'platform/foundation';

import {useGetCebiaFeatureDecoderConfigQuery} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {useRouter} from '../../../hooks/useRouter';
import {CebiaAutofillProps, CebiaAutofillWidget} from './CebiaAutofillWidget';

export function Cebia(props: CebiaAutofillProps) {
  const {data: featureDecoderConfig, isLoading} = useGetCebiaFeatureDecoderConfigQuery();
  const router = useRouter();

  return (
    <Card
      isExpandable
      title={i18n.t('entity.vehicle.labels.integrations')}
      actions={[
        {
          variant: 'link',
          type: 'button',
          leftIcon: 'action/settings',
          onClick: () => router.push(settingsRoutes.vehicleServices),
          'data-testid': 'button-features-integrations-settings',
          title: i18n.t('general.labels.settings'),
        },
      ]}
    >
      <DataStatus
        isEmpty={!featureDecoderConfig?.enabled}
        isLoading={isLoading}
        emptyMessage={i18n.t('entity.vehicle.emptyIntegration.noServicesAvailable')}
        emptySubheadline={i18n.t('entity.vehicle.emptyIntegration.enableInSettings')}
      >
        <CebiaAutofillWidget
          {...props}
          data-testid="features"
          image="../../../../assets/images/cebia_logo.png"
        />
        <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_DAT_DECODER}>
          <Separator />
          <CebiaAutofillWidget
            {...props}
            data-testid="features"
            image="../../../../assets/images/logo_inverted.svg"
            decoderType="dat"
          />
        </Show>
      </DataStatus>
    </Card>
  );
}
