import {FlagProps} from 'platform/components';

import {isNil, reject} from 'ramda';
import {isObject} from 'ramda-adjunct';

import {TypedAnyValue} from '@omnetic-dms/api';

export const getAllowedValuesFlagProps = (allowedValues: TypedAnyValue[]): FlagProps[] => {
  const defaultValuesAsStringArray = reject(
    isNil,
    allowedValues.map((allowedValue) => {
      const untypedValue = allowedValue.value;

      if (isNil(untypedValue)) {
        return null;
      }

      if (isCurrency(untypedValue)) {
        return `${untypedValue.amount} ${untypedValue.currency}`;
      }

      return untypedValue.toString();
    })
  );

  return defaultValuesAsStringArray.map((value) => ({label: value}));
};

type Currency = {
  amount: string;
  currency: string;
};

const isCurrency = (value: unknown): value is Currency =>
  isObject(value) && 'amount' in value && 'currency' in value;
