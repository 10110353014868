import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

import {ServiceOrderVariantPayment} from '../../../types/ServiceOrderVariant';

export function getOrderPaymentTypeOptions(paymentType: ServiceOrderVariantPayment | Nullish) {
  if (!paymentType) {
    return [];
  }

  const options = [];

  if (paymentType.bankPayment?.isAllowed) {
    options.push({
      label: i18n.t('entity.invoice.paymentMethod.bankTransfer'),
      value: 'BANK_TRANSFER',
    });
  }

  if (paymentType.cardPayment?.isAllowed) {
    options.push({
      label: i18n.t('entity.invoice.paymentMethod.card'),
      value: 'CARD',
    });
  }

  if (paymentType.cashPayment?.isAllowed) {
    options.push({
      label: i18n.t('entity.invoice.paymentMethod.cash'),
      value: 'CASH',
    });
  }

  if (paymentType.internalPayment?.isAllowed) {
    options.push({
      label: i18n.t('general.labels.internal'),
      value: 'INTERNAL',
    });
  }

  return options;
}
