import {z} from 'zod';

export const HeaderDynamicActionsFlagColoursSchema = z.union([
  z.literal('neutral'),
  z.literal('red'),
  z.literal('magenta'),
  z.literal('purple'),
  z.literal('blue'),
  z.literal('teal'),
  z.literal('green'),
  z.literal('yellow'),
  z.literal('orange'),
  z.literal('black'),
]);
export type HeaderDynamicActionsFlagsColours = z.infer<
  typeof HeaderDynamicActionsFlagColoursSchema
>;

export const HeaderDynamicActionsFlagsSchema = z.array(
  z.object({
    title: z.string(),
    color: HeaderDynamicActionsFlagColoursSchema,
  })
);
export type HeaderDynamicActionsFlags = z.infer<typeof HeaderDynamicActionsFlagsSchema>;
