import {Label} from 'platform/components';
import {GridItem, Grid} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';

import {selectOverallComment} from '../../../store/carAudit/selectors';
import {ParamType} from '../../../types/ParamType';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {useConditionContext} from '../hooks/useConditionContext';
import {getFormFieldName} from '../utils/getFormFieldName';

const ConditionsGeneralComment: FC = () => {
  const overallCommentCategory = useSelector(selectOverallComment);
  const {isDisabledForUser} = useConditionContext();
  const {Field} = useFormRenderer();

  const paramDefinition = overallCommentCategory?.paramDefinitions?.additionalInformation?.find(
    (paramDefinition) => paramDefinition.type === ParamType.ADDITIONAL_COMMENT
  );

  const isMandatory = paramDefinition?.mandatory;

  if (!paramDefinition || !overallCommentCategory) {
    return null;
  }

  const name = getFormFieldName(overallCommentCategory.id, paramDefinition.id);

  return (
    <Grid columns={2}>
      <GridItem>
        <Label>
          {`${isMandatory ? '*' : ''} ${i18n.t('entity.condition.labels.generalNote')}`}
        </Label>
        <Field
          as="text"
          multiline
          parse={(value) => value || ''}
          name={name}
          disabled={isDisabledForUser}
        />
      </GridItem>
    </Grid>
  );
};

export const GeneralComment = ConditionsGeneralComment;
