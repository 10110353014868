import {getYear} from 'date-fns';

const minYear = 1900;
const maxYear = getYear(Date.now());

const options = Array(maxYear - minYear + 1)
  .fill(minYear)
  .map((year, index) => {
    const option = `${year + index}`;
    return {
      value: option,
      label: option,
    };
  });

export const yearOptions = options.sort((a, b) => parseInt(b.value, 10) - parseInt(a.value, 10));
