import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {always} from 'ramda';

import {RequiredTestIdProps} from 'shared';

import {PairDeviceStep} from '../../types/PairDeviceStep';
import {ConnectDeviceStep} from './ConnectDeviceStep';
import {EditDeviceStep} from './EditDeviceStep';
import {SetupDeviceStep} from './SetupDeviceStep';

interface PairDeviceStepperProps extends RequiredTestIdProps {
  state: PairDeviceStep;
  pairedDeviceId?: string;
}

export function PairDeviceStepper(props: PairDeviceStepperProps) {
  const [pairedDeviceId, setPairedDeviceId] = useState<string | null>(props.pairedDeviceId ?? null);
  const [currentStep, setCurrentStep] = useState<PairDeviceStep>(props.state);

  const componentProps = {
    'data-testid': props['data-testid'],
    setCurrentStep,
    pairedDeviceId,
    setPairedDeviceId,
  };

  const activeStep = match(currentStep)
    .with('setup', always(<SetupDeviceStep {...componentProps} />))
    .with('unpaired', always(<ConnectDeviceStep {...componentProps} />))
    .with('paired', always(<EditDeviceStep {...componentProps} />))
    .otherwise(() => {
      throw new Error('Invalid step reached');
    });

  return <Box padding={10}>{activeStep}</Box>;
}
