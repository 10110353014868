import {showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {isNotNil} from 'ramda';

type NotificationType = 'info' | 'warning' | 'success' | 'error';

function hasClientNotification(responseHeaders: Headers) {
  const notificationMessage = responseHeaders.get('X-Notification-Type');
  const notificationType = responseHeaders.get('X-Notification-Message');

  return isNotNil(notificationMessage) && isNotNil(notificationType);
}

export function handleClientNotification(responseHeaders: Headers) {
  if (hasClientNotification(responseHeaders)) {
    const notificationType = responseHeaders.get('X-Notification-Type') as NotificationType;
    const notificationMessage = responseHeaders.get('X-Notification-Message') as string;

    return match(notificationType)
      .with('info', () => showNotification.info(notificationMessage))
      .with('warning', () => showNotification.warning(notificationMessage))
      .with('error', () => showNotification.error(notificationMessage))
      .with('success', () => showNotification.success(notificationMessage))
      .exhaustive();
  } else {
    return;
  }
}
