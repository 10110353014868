import {Decimal} from 'decimal.js';

// Due to problem with floating point number precision in javaScript we need to use Decimal.js library
export const precisionCalculation = {
  add: (a: number, b: number) => new Decimal(a).add(b).toNumber(),
  subtract: (a: number, b: number) => new Decimal(a).minus(b).toNumber(),
  multiply: (a: number, b: number) => new Decimal(a).mul(b).toNumber(),
  divide: (a: number, b: number) => new Decimal(a).div(b).toNumber(),
  modulo: (a: number, b: number) => new Decimal(a).mod(b).toNumber(),
};
