import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query/react';
import {
  Button,
  ButtonGroup,
  Card,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  OptionTypeBase,
  ValidationErrors,
} from 'platform/components';
import {Grid, GridItem, Show, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useRef} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useDeleteLeasingAndCreditCompanyMutation,
  useEnableLeasingAndCreditCompanyMutation,
  useGetParticipationQuery,
  useGetSaleVehicleQuery,
  useLeasingAndCreditCompanyMutation,
  useReadCodelistQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps, useToggle, yupString} from 'shared';

import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {handleApiError} from '../../utils/handleApiError';

interface VehicleFinancingCardProps extends TestIdProps {
  vehicleId?: string;
  isReadonly?: boolean;
  isWithSubmit?: boolean;
}

interface VehicleFinancingFormValues {
  leasingAndCreditCompanyCodeId: string | null;
  financingContractNumber: string | null;
  endOfFinancing: string | null;
  financingNote: string | null;
}

const LEASING_CODELIST_ID = 'leasing_and_credit_company';
const SAVE_DEBOUNCE_TIMEOUT = 500;

export function VehicleFinancingCard(props: VehicleFinancingCardProps) {
  const {
    data: saleVehicle,
    isLoading: isSaleVehicleLoading,
    isError: isSaleVehicleError,
  } = useGetSaleVehicleQuery({vehicleId: props.vehicleId ?? ''}, {skip: isNil(props.vehicleId)});

  const {
    data: leasingAndCreditCompanies,
    isLoading: isCompaniesLoading,
    isError: isCompaniesError,
  } = useReadCodelistQuery({
    codelistId: LEASING_CODELIST_ID,
  });

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId ?? '',
  });

  const [
    hasVehicleEnableLeasingAndCreditCompany,
    hasVehicleDisableLeasingAndCreditCompany,
    hasVehicleSetLeasingAndCreditCompany,
    hasVehicleSetFleetInsurance,
    hasVehicleUpdateStorageSpace,
  ] = usePermissions({
    permissionKeys: [
      'vehicleEnableLeasingAndCreditCompany',
      'vehicleDisableLeasingAndCreditCompany',
      'vehicleSetLeasingAndCreditCompany',
      'vehicleSetFleetInsurance',
      'vehicleUpdateStorageSpace',
    ],
    scopes: {
      vehicleEnableLeasingAndCreditCompany: vehicleParticipation,
      vehicleDisableLeasingAndCreditCompany: vehicleParticipation,
      vehicleSetLeasingAndCreditCompany: vehicleParticipation,
      vehicleSetFleetInsurance: vehicleParticipation,
      vehicleUpdateStorageSpace: vehicleParticipation,
    },
  });

  const canEditSaleVehicle =
    hasVehicleEnableLeasingAndCreditCompany &&
    hasVehicleDisableLeasingAndCreditCompany &&
    hasVehicleSetLeasingAndCreditCompany &&
    hasVehicleSetFleetInsurance &&
    hasVehicleUpdateStorageSpace;

  const [enableLeasingAndCreditCompany] = useEnableLeasingAndCreditCompanyMutation();
  const [deleteLeasingAndCreditCompany] = useDeleteLeasingAndCreditCompanyMutation();
  const [leasingAndCreditCompany] = useLeasingAndCreditCompanyMutation();

  const isEnabled = !!saleVehicle?.leasingAndCreditCompany;
  const [isExpanded, toggleExpanded] = useToggle(isEnabled);

  const saveTimeout = useRef<ReturnType<typeof setTimeout>>();

  const isLoading = isSaleVehicleLoading || isCompaniesLoading;
  const isError = isSaleVehicleError || isCompaniesError;

  const institutionOptions: OptionTypeBase<string>[] =
    leasingAndCreditCompanies?.codes
      .filter((code) => !code.isDisabled)
      .map((code) => ({
        label: code.name,
        value: code.codeId,
      })) ?? [];

  const saveFormAndToggle = (formApi: UseFormReturn<VehicleFinancingFormValues>) => {
    toggleExpanded();
    formApi.setValue('leasingAndCreditCompanyCodeId', null);
    formApi.setValue('endOfFinancing', null);
    formApi.setValue('financingContractNumber', null);
    formApi.setValue('financingNote', null);
  };

  const handleToggle = (formApi: UseFormReturn<VehicleFinancingFormValues>) => {
    (isExpanded
      ? deleteLeasingAndCreditCompany({vehicleId: saleVehicle!.vehicleId})
      : enableLeasingAndCreditCompany({vehicleId: saleVehicle!.vehicleId})
    )
      .unwrap()
      .then(() => (!isExpanded ? saveFormAndToggle(formApi) : toggleExpanded()))
      .catch(handleApiError);
  };

  const onSubmit: FormSubmitHandler<VehicleFinancingFormValues> = async (values) => {
    if (!isExpanded) {
      return deleteLeasingAndCreditCompany({vehicleId: saleVehicle!.vehicleId})
        .unwrap()
        .then(closeCurrentDialog)
        .catch(handleApiError);
    }

    if (!isEnabled) {
      try {
        await enableLeasingAndCreditCompany({vehicleId: saleVehicle!.vehicleId}).unwrap();
        return saveForm(values);
      } catch (error) {
        handleApiError(error as FetchBaseQueryError);
      }
    }

    return saveForm(values);
  };

  const handleChange = (
    values: VehicleFinancingFormValues,
    setErrors: (errors: ValidationErrors | null) => void
  ) => {
    if (isExpanded && isNil(values.leasingAndCreditCompanyCodeId)) {
      setErrors([
        {
          name: 'leasingAndCreditCompanyCodeId',
          message: i18n.t('general.labels.required'),
        },
      ]);

      return;
    }

    setErrors(null);

    clearTimeout(saveTimeout.current);

    saveTimeout.current = setTimeout(() => {
      saveForm(values);
    }, SAVE_DEBOUNCE_TIMEOUT);
  };

  const saveForm = (values: VehicleFinancingFormValues) =>
    leasingAndCreditCompany({
      vehicleId: saleVehicle!.vehicleId,
      codeId: values.leasingAndCreditCompanyCodeId!,
      body: {
        endOfFinancing: values.endOfFinancing,
        financingContractNumber: values.financingContractNumber,
        note: values.financingNote,
      },
    })
      .unwrap()
      .then(() => {
        props.isWithSubmit && closeCurrentDialog();
      })
      .catch(handleApiError);

  const defaultValues: Partial<VehicleFinancingFormValues> = {
    leasingAndCreditCompanyCodeId: saleVehicle?.leasingAndCreditCompanyCodeId ?? undefined,
    endOfFinancing: saleVehicle?.endOfFinancing ?? undefined,
    financingContractNumber: saleVehicle?.financingContractNumber ?? undefined,
    financingNote: saleVehicle?.financingNote ?? undefined,
  };

  const isDisabled = props.isReadonly || !canEditSaleVehicle;

  return (
    <Show when={isNotNil(props.vehicleId)}>
      <DataStatus isLoading={isLoading} isError={isError} isEmpty={isNil(saleVehicle)}>
        <Form<VehicleFinancingFormValues>
          onChange={!props.isWithSubmit ? handleChange : undefined}
          onSubmit={props.isWithSubmit ? onSubmit : undefined}
          defaultValues={defaultValues}
          schema={schema(isExpanded)}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <Card
                variant="inlineGrey"
                title={i18n.t('entity.checkout.labels.financing')}
                control={{
                  type: 'switch',
                  onChange: () => handleToggle(formApi),
                  value: isExpanded,
                  isDisabled,
                }}
                onExpandButtonClick={() =>
                  isDisabled || props.isWithSubmit ? undefined : handleToggle(formApi)
                }
                isExpanded={isExpanded}
              >
                <Grid columns={4}>
                  <FormField
                    label={i18n.t('entity.checkout.labels.financingInstitution')}
                    name="leasingAndCreditCompanyCodeId"
                    type="choice"
                    options={institutionOptions}
                    control={control}
                    isNotClearable
                    isRequired
                    data-testid={suffixTestId('financingInstitution', props)}
                    isDisabled={isDisabled}
                  />
                  <FormField
                    label={i18n.t('entity.checkout.labels.endOfFinancing')}
                    name="endOfFinancing"
                    type="apiDate"
                    control={control}
                    data-testid={suffixTestId('endOfFinancing', props)}
                    isDisabled={isDisabled}
                  />
                  <FormField
                    label={i18n.t('entity.checkout.labels.contractNumber')}
                    name="financingContractNumber"
                    type="text"
                    control={control}
                    data-testid={suffixTestId('contractNumber', props)}
                    isDisabled={isDisabled}
                  />
                  <GridItem span={4}>
                    <FormField
                      label={i18n.t('general.labels.note')}
                      name="financingNote"
                      type="textarea"
                      control={control}
                      data-testid={suffixTestId('note', props)}
                      isDisabled={isDisabled}
                    />
                  </GridItem>
                </Grid>
              </Card>
              <Show when={props.isWithSubmit}>
                <ButtonGroup align="right">
                  <Button
                    title={i18n.t('general.actions.discard')}
                    onClick={closeCurrentDialog}
                    variant="secondary"
                    data-testid={suffixTestId('discard', props)}
                    isDisabled={isDisabled}
                  />
                  <FormButton
                    title={i18n.t('general.actions.save')}
                    type="submit"
                    control={control}
                    data-testid={suffixTestId('save', props)}
                    isDisabled={isDisabled}
                  />
                </ButtonGroup>
              </Show>
            </VStack>
          )}
        </Form>
      </DataStatus>
    </Show>
  );
}

const schema = (isExpanded: boolean) =>
  object({
    leasingAndCreditCompanyCodeId: isExpanded ? yupString.required() : yupString.nullable(),
    endOfFinancing: yupString,
    financingContractNumber: yupString,
    financingNote: yupString,
  });
