import {useCallback} from 'react';

import {Nullish} from 'shared';

import {IFilterParams} from '../types/AgGridTypes';

type Props<T> = {
  defaultValue: T;
  filterChangedCallback: IFilterParams['filterChangedCallback'];
  setFilterValue: (value: T) => void;
};
export const useFilterOnChipsChange = <T,>(props: Props<T>) => {
  const onChipsChange = useCallback(
    (values: string[] | Nullish) => {
      if (values && values.length > 0) {
        props.setFilterValue(values[0] as T);
      } else {
        props.setFilterValue(props.defaultValue);
      }

      props.filterChangedCallback();
    },
    [props.filterChangedCallback, props.setFilterValue, props.defaultValue]
  );

  return {onChipsChange};
};
