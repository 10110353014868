import {DataStatus, FormControl, Separator} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {useWatch} from 'react-hook-form';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetCashRegisterQuery,
  useGetTenantQuery,
  useTaxDocumentCalculationV2Mutation,
} from '@omnetic-dms/api';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {TAX_DOCUMENT_CALCULATION_FIXED_CACHE_KEY} from '../../constants';
import {AccountingDocumentFormValues} from '../../types';
import {AdditionalVatCalculation} from '../AdditionalVatCalculation';
import {RoundingSummary} from '../RoundingSummary';
import {Summary} from '../Summary';
import {VatSummary} from '../VatSummary';

interface CreateInvoiceListOfItems extends RequiredTestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
}

export function ListOfItemsSummary(props: CreateInvoiceListOfItems) {
  const [cashRegisterId] = useWatch({
    control: props.control,
    name: ['cashRegisterId'],
  });

  const {data: tenant} = useGetTenantQuery();
  const {data: cashRegister} = useGetCashRegisterQuery(
    {cashRegisterId: cashRegisterId ?? ''},
    {skip: isNilOrEmpty(cashRegisterId)}
  );

  const [_, {data: summary, isLoading: isLoadingSummary, isError: isSummaryError}] =
    useTaxDocumentCalculationV2Mutation({
      fixedCacheKey: TAX_DOCUMENT_CALCULATION_FIXED_CACHE_KEY,
    });

  const vatTypeSummary =
    isNotNil(tenant) && isNotNil(cashRegister) && tenant.currency !== cashRegister.currency
      ? summary?.exchangeRateRatioCalculation?.vatTypesSummary
      : summary?.calculation?.vatTypesSummary;

  return (
    <DataStatus isLoading={isLoadingSummary} isError={isSummaryError} minHeight={71}>
      <Show when={isNotNil(summary)}>
        <Separator spacing={2} />
        <VStack spacing={4}>
          <AdditionalVatCalculation
            vatTypeSummary={vatTypeSummary}
            control={props.control}
            data-testid={suffixTestId('additionalVatCalculation', props)}
          />

          <VatSummary
            vatTypeSummary={vatTypeSummary}
            control={props.control}
            data-testid={suffixTestId('vatSummary', props)}
          />

          <RoundingSummary
            roundingItems={summary?.calculation.summaryItems}
            vatTypeSummary={vatTypeSummary}
            data-testid={suffixTestId('rounding-summary', props)}
          />

          <Summary
            summary={summary?.calculation}
            exchangeRateRatioCalculation={summary?.exchangeRateRatioCalculation}
            data-testid={suffixTestId('summary', props)}
          />
        </VStack>
      </Show>
    </DataStatus>
  );
}
