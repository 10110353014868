import {
  GetReceiveNoteItemNewRequest,
  GetReceiveNoteItemNewRequestSchema,
  GetReceiveNoteItemNewResponse,
  GetReceiveNoteItemNewResponseSchema,
  GetReceiveNoteItemRefreshedRequest,
  GetReceiveNoteItemRefreshedRequestSchema,
  GetReceiveNoteItemRefreshedResponse,
  GetReceiveNoteItemRefreshedResponseSchema,
  GetReceiveNoteItemRequest,
  GetReceiveNoteItemRequestSchema,
  GetReceiveNoteItemResponse,
  GetReceiveNoteItemResponseSchema,
  GetReceiveNoteItemsCountRequest,
  GetReceiveNoteItemsCountRequestSchema,
  GetReceiveNoteItemsCountResponse,
  GetReceiveNoteItemsCountResponseSchema,
  GetReceiveNoteItemsTotalPriceRequest,
  GetReceiveNoteItemsTotalPriceRequestSchema,
  GetReceiveNoteItemsTotalPriceResponse,
  GetReceiveNoteItemsTotalPriceResponseSchema,
  PatchReceiveNoteItemManualRequest,
  PatchReceiveNoteItemManualRequestSchema,
  PatchReceiveNoteItemManualResponse,
  PatchReceiveNoteItemManualResponseSchema,
  PostReceiveNoteItemManualRequest,
  PostReceiveNoteItemManualRequestSchema,
  PostReceiveNoteItemManualResponse,
  PostReceiveNoteItemManualResponseSchema,
  PostReceiveNoteItemsDeleteRequest,
  PostReceiveNoteItemsDeleteRequestSchema,
  PostReceiveNoteItemsDeleteResponse,
  PostReceiveNoteItemsDeleteResponseSchema,
  PutReceiveNoteItemCalculationRequest,
  PutReceiveNoteItemCalculationRequestSchema,
  PutReceiveNoteItemCalculationResponse,
  PutReceiveNoteItemCalculationResponseSchema,
} from '../types/metadaWarehouseReceiveNoteItem';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseReceiveNoteItemApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getReceiveNoteItemsCount: build.query<
      GetReceiveNoteItemsCountResponse,
      GetReceiveNoteItemsCountRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/items-count`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'receiveNote', id: queryArg.receiveNoteId},
      ],
      extraOptions: {
        requestSchema: GetReceiveNoteItemsCountRequestSchema,
        responseSchema: GetReceiveNoteItemsCountResponseSchema,
      },
    }),
    getReceiveNoteItemsTotalPrice: build.query<
      GetReceiveNoteItemsTotalPriceResponse,
      GetReceiveNoteItemsTotalPriceRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note-item/${queryArg.receiveNoteId}/total-price`,
        queryArg,
      }),
      providesTags: ['receiveNoteTotalPrice'],
      extraOptions: {
        requestSchema: GetReceiveNoteItemsTotalPriceRequestSchema,
        responseSchema: GetReceiveNoteItemsTotalPriceResponseSchema,
      },
    }),
    postReceiveNoteItemManual: build.mutation<
      PostReceiveNoteItemManualResponse,
      PostReceiveNoteItemManualRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note-item/manual`,
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNote', id: queryArg.receiveNoteId},
        'receiveNoteTotalPrice',
      ],
      extraOptions: {
        requestSchema: PostReceiveNoteItemManualRequestSchema,
        responseSchema: PostReceiveNoteItemManualResponseSchema,
      },
    }),
    patchReceiveNoteItemManual: build.mutation<
      PatchReceiveNoteItemManualResponse,
      PatchReceiveNoteItemManualRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note-item/${queryArg.receiveNoteItemId}/manual`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['receiveNoteTotalPrice'],
      extraOptions: {
        requestSchema: PatchReceiveNoteItemManualRequestSchema,
        responseSchema: PatchReceiveNoteItemManualResponseSchema,
      },
    }),
    postReceiveNoteItemsDelete: build.mutation<
      PostReceiveNoteItemsDeleteResponse,
      PostReceiveNoteItemsDeleteRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/items/delete`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNote', id: queryArg.receiveNoteId},
        'receiveNoteTotalPrice',
      ],
      extraOptions: {
        requestSchema: PostReceiveNoteItemsDeleteRequestSchema,
        responseSchema: PostReceiveNoteItemsDeleteResponseSchema,
      },
    }),
    getReceiveNoteItem: build.query<GetReceiveNoteItemResponse, GetReceiveNoteItemRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/item/${queryArg.itemId}`,
        method: 'GET',
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetReceiveNoteItemRequestSchema,
        responseSchema: GetReceiveNoteItemResponseSchema,
      },
    }),
    getReceiveNoteItemNew: build.query<GetReceiveNoteItemNewResponse, GetReceiveNoteItemNewRequest>(
      {
        query: (queryArg) => ({
          url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/item-new`,
          method: 'GET',
          params: {
            articleId: queryArg.articleId,
          },
          queryArg,
        }),
        providesTags: ['receiveNoteItemNew'],
        extraOptions: {
          requestSchema: GetReceiveNoteItemNewRequestSchema,
          responseSchema: GetReceiveNoteItemNewResponseSchema,
        },
      }
    ),
    getReceiveNoteItemRefreshed: build.query<
      GetReceiveNoteItemRefreshedResponse,
      GetReceiveNoteItemRefreshedRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/article-refresh`,
        method: 'GET',
        params: {
          articleId: queryArg.articleId,
        },
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetReceiveNoteItemRefreshedRequestSchema,
        responseSchema: GetReceiveNoteItemRefreshedResponseSchema,
      },
    }),
    putReceiveNoteItemCalculation: build.mutation<
      PutReceiveNoteItemCalculationResponse,
      PutReceiveNoteItemCalculationRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note-item/calculation`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
        signal: queryArg.signal,
      }),
      extraOptions: {
        requestSchema: PutReceiveNoteItemCalculationRequestSchema,
        responseSchema: PutReceiveNoteItemCalculationResponseSchema,
      },
    }),
  }),
});

export const {
  useGetReceiveNoteItemsCountQuery,
  useGetReceiveNoteItemsTotalPriceQuery,
  usePostReceiveNoteItemManualMutation,
  usePostReceiveNoteItemsDeleteMutation,
  usePatchReceiveNoteItemManualMutation,
  useGetReceiveNoteItemQuery,
  useLazyGetReceiveNoteItemQuery,
  useLazyGetReceiveNoteItemNewQuery,
  useLazyGetReceiveNoteItemRefreshedQuery,
  usePutReceiveNoteItemCalculationMutation,
} = metadaWarehouseReceiveNoteItemApi;
