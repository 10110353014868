import {TextProps} from 'platform/foundation';

export function getGapColor(gap: number): TextProps['color'] {
  if (gap > 0) {
    return 'success';
  }
  if (gap < 0) {
    return 'danger';
  }
  return 'secondary';
}
