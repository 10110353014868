import {Severity} from 'platform/foundation';

import {clamp} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {DeterminateProgressBarProps} from './DeterminateProgressBarProps';
import {Bar, BarAnimation, BarContainer} from './ProgressBar.theme';

export type IndeterminateProgressBarProps = {
  percentage?: number;
  indeterminate?: boolean;
  severity?: Severity;
  small?: boolean;
};

export type ProgressBarProps = (DeterminateProgressBarProps | IndeterminateProgressBarProps) &
  TestIdProps;

export function ProgressBar(props: ProgressBarProps) {
  return (
    <BarContainer data-testid={suffixTestId('progressBarWrapper', props)}>
      <Bar
        $small={props.small}
        $severity={props.severity}
        $percentage={clampPercentage(props.percentage ?? 1)}
        data-testid={suffixTestId('progressBar', props)}
      >
        {props.indeterminate && <BarAnimation />}
      </Bar>
    </BarContainer>
  );
}

const clampPercentage = clamp(0, 1);
