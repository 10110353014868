export function Circles() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66 135C101.899 135 131 105.899 131 70C131 34.1015 101.899 5 66 5C30.1015 5 1 34.1015 1 70C1 105.899 30.1015 135 66 135ZM66 136C102.451 136 132 106.451 132 70C132 33.5492 102.451 4 66 4C29.5492 4 0 33.5492 0 70C0 106.451 29.5492 136 66 136Z"
        fill="#EAEEFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66 135C101.899 135 131 105.899 131 70C131 34.1015 101.899 5 66 5C30.1015 5 1 34.1015 1 70C1 105.899 30.1015 135 66 135ZM66 136C102.451 136 132 106.451 132 70C132 33.5492 102.451 4 66 4C29.5492 4 0 33.5492 0 70C0 106.451 29.5492 136 66 136Z"
        fill="#EAEEFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74 131C109.899 131 139 101.899 139 66C139 30.1015 109.899 1 74 1C38.1015 1 9 30.1015 9 66C9 101.899 38.1015 131 74 131ZM74 132C110.451 132 140 102.451 140 66C140 29.5492 110.451 0 74 0C37.5492 0 8 29.5492 8 66C8 102.451 37.5492 132 74 132Z"
        fill="#D3DCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73 139C108.899 139 138 109.899 138 74C138 38.1015 108.899 9 73 9C37.1015 9 8 38.1015 8 74C8 109.899 37.1015 139 73 139ZM73 140C109.451 140 139 110.451 139 74C139 37.5492 109.451 8 73 8C36.5492 8 7 37.5492 7 74C7 110.451 36.5492 140 73 140Z"
        fill="url(#paint0_linear_23415_8278)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_23415_8278"
          x1="73"
          y1="8"
          x2="73"
          y2="140"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1CCFF" stopOpacity="0" />
          <stop offset="1" stopColor="#D1CCFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
