import {AuditStructure} from '../types/AuditStructure';
import type {CancelablePromise} from '../types/CancelablePromise';
import {request as __request} from '../utils/request';

export class CarAuditStructureService {
  /**
   * Get all vehicle condition structures.
   *
   * @returns AuditStructure
   * @throws ApiError
   */
  public static getAuditStructure({
    auditStructureId,
    authorization,
  }: {
    authorization?: string;
    auditStructureId: string;
  }): CancelablePromise<Array<AuditStructure>> {
    return __request({
      method: 'GET',
      url: '/car-audit/v1/audit-structure/{auditStructureId}',
      path: {
        auditStructureId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * Get the newest structure by inspection type.
   *
   * @returns AuditStructure
   * @throws ApiError
   */
  public static getNewestStructureByInspectionType({
    inspectionType,
    authorization,
  }: {
    authorization?: string;
    inspectionType:
      | 'vehicleCondition'
      | 'validationInspection'
      | 'nonValidationInspection'
      | 'autofillTemplate';
  }): CancelablePromise<Array<AuditStructure>> {
    return __request({
      method: 'GET',
      url: '/car-audit/v1/audit-structure/{inspectionType}/newest',
      path: {
        inspectionType,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
}
