import styled, {css} from 'styled-components';
import styledMap from 'styled-map';

import {ButtonProps, IconProps} from './types';

const background = styledMap`
	default: transparent;
	primary: ${({theme}) => theme.colors.palettes.blue[60][100]};
	secondary: ${({theme}) => theme.colors.palettes.neutral[30][100]};
	danger:${({theme}) => theme.colors.palettes.red[60][100]};
	outlined: none;
	transparent: transparent;
`;

const backgroundHover = styledMap`
	default: ${({theme}) => theme.colors.palettes.neutral[30][100]};
	primary: ${({theme}) => theme.colors.palettes.blue[70][100]};
	secondary: ${({theme}) => theme.colors.palettes.neutral[40][100]};
	danger:${({theme}) => theme.colors.palettes.red[70][100]};
	outlined: none;
	transparent: ${({theme}) => theme.colors.palettes.neutral[30][100]};
`;

const backgroundActive = styledMap`
	default: ${({theme}) => theme.colors.palettes.neutral[30][100]};
	primary: ${({theme}) => theme.colors.palettes.blue[70][100]};
	secondary: ${({theme}) => theme.colors.palettes.neutral[40][100]};
	danger:${({theme}) => theme.colors.palettes.red[70][100]};
	outlined: ${({theme}) => theme.colors.palettes.blue[20][100]};
	transparent: ${({theme}) => theme.colors.palettes.neutral[30][100]};
`;

const color = styledMap`
	default: ${({theme}) => theme.colors.palettes.neutral[900][100]};
	primary: ${({theme}) => theme.colors.palettes.white[10][100]};
	secondary: ${({theme}) => theme.colors.palettes.neutral[900][100]};
	danger: ${({theme}) => theme.colors.palettes.white[10][100]};
	outlined: ${({theme}) => theme.colors.palettes.neutral[900][100]};
	transparent: ${({theme}) => theme.colors.palettes.blue[60][100]};
`;

const colorHover = styledMap`
	default: ${({theme}) => theme.colors.palettes.neutral[900][100]};
	primary: ${({theme}) => theme.colors.palettes.white[10][100]};
	secondary: ${({theme}) => theme.colors.palettes.neutral[900][100]};
	danger: ${({theme}) => theme.colors.palettes.white[10][100]};
	outlined: ${({theme}) => theme.colors.palettes.neutral[900][100]};
	transparent: ${({theme}) => theme.colors.palettes.neutral[900][100]};
`;

const border = styledMap`
	default: none;
	primary: 1px solid transparent;
	danger: 1px solid transparent;
	secondary: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
	outlined: 1px solid ${({theme}) => theme.colors.palettes.neutral[50][100]};
	transparent: none;
`;

const borderActive = styledMap`
	default: none;
	primary: 1px solid transparent;
	danger: 1px solid transparent;
	secondary: 1px solid ${({theme}) => theme.colors.palettes.neutral[30][100]};
	outlined: 1px solid ${({theme}) => theme.colors.palettes.blue[60][100]};
	transparent: none;
`;

const borderHover = styledMap`
	default: none;
	primary: 1px solid transparent;
	danger: 1px solid transparent;
	secondary: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
	outlined: 1px solid ${({theme}) => theme.colors.palettes.neutral[90][100]};
	transparent: none;
`;

const cursor = styledMap`
	default: pointer;
	disabled: not-allowed;
`;

const padding = styledMap`
  default: 6px 12px;
	normal: 8px;
	compact: 4px;
`;

export const Brand = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(40%, -50%);
  border: 2px solid ${({theme}) => theme.colors.palettes.white[10][100]};
  border-radius: 50%;
`;

export const ButtonStyled = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  color: ${color};
  background: ${(props) => (props.active ? backgroundActive : background)};
  font-weight: normal;
  border: ${(props) => (props.active ? borderActive : border)};
  box-sizing: border-box;
  cursor: ${cursor};
  transition: color 250ms ease;
  padding: ${padding};
  font-size: ${({theme}) => theme.fontSizes.text.small};
  border-radius: ${({theme}) => theme.radii.small};
  line-height: 1.34;
  opacity: ${({disabled}) => (disabled ? 0.4 : 1)};
  height: fit-content;
  position: relative;
  user-select: none;
  text-decoration: none;

  &:focus {
    box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
  }

  &:active {
    outline: none;
    background: ${(props) =>
      (props.primary && `${props.theme.colors.palettes.blue[80][100]}`) ||
      (props.secondary && `${props.theme.colors.palettes.neutral[50][100]}`)} !important;
    border: ${(props) =>
      (props.primary && `1px solid  ${props.theme.colors.palettes.blue[80][100]}`) ||
      (props.secondary && `1px solid ${props.theme.colors.palettes.neutral[50][100]}`)} !important;
  }

  &:hover:not(:disabled) {
    color: ${colorHover};
    background: ${backgroundHover};
    border: ${borderHover};
  }
`;

export const Icon = styled.span<IconProps>`
  margin-right: 8px;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  display: flex;
  align-items: center;

  svg {
    font-size: 16px;
  }

  ${({iconRight}) =>
    iconRight &&
    css`
      margin-right: 0;
      margin-left: 8px;
    `}

  ${({onlyIcon}) =>
    onlyIcon &&
    css`
      margin: 0;
      font-size: inherit;
    `}
`;
