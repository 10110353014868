import {FC, ChangeEvent, RefObject} from 'react';

import {testIds} from '@omnetic-dms/routes';

interface UploadComponentProps {
  readonly code: string;
  readonly reference: RefObject<HTMLInputElement>;
  readonly handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const UploadComponent: FC<UploadComponentProps> = ({code, reference, handleFileChange}) => (
  <input
    data-testid={testIds.settings.documentTemplates('powerOfAttorneyUpload')}
    type="file"
    name={code}
    accept=".docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ref={reference}
    onChange={handleFileChange}
    style={{display: 'none'}}
  />
);
