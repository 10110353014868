import {useSelector} from 'react-redux';

import {map} from 'ramda';

import {
  useGetEmployeeMechanicsByBranchQuery,
  useGetTireLocationsQuery,
  useGetTireManufacturerQuery,
  useGetTireOptionsQuery,
  selectActiveBranchId,
} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export function useTireSetOptions() {
  const branchId = useSelector(selectActiveBranchId);

  const {data: seasonOptions, isLoading: isSeasonOptionsLoading} = useGetTireOptionsQuery({
    category: 'TIRTIRESEASON',
  });
  const {data: locations, isLoading: isLocationOptionsLoading} = useGetTireLocationsQuery();
  const {data: rimOptions, isLoading: isRimOptionsLoading} = useGetTireOptionsQuery({
    category: 'TIRRIMTYPE',
  });
  const {data: manufacturers, isLoading: isManufacturerOptionsLoading} =
    useGetTireManufacturerQuery(undefined);
  const {data: mechanicOptions, isLoading: isMechanicOptionsLoading} =
    useGetEmployeeMechanicsByBranchQuery({branchId});

  const manufacturerOptions = map(
    (item) => ({label: item?.name ?? '', value: item?.id ?? ''}),
    manufacturers?.filter((item) => item?.isActive) ?? []
  );

  const getManufacturerOptionsWithSelectedValue = (selectedManufacturer: string | Nullish) =>
    map(
      (item) => ({label: item?.name ?? '', value: item?.id ?? ''}),
      manufacturers?.filter((item) => item?.isActive || item?.id === selectedManufacturer) ?? []
    );

  const locationOptions = map(
    (item) => ({label: item?.name ?? '', value: item?.id ?? ''}),
    locations?.filter((item) => item?.isActive) ?? []
  );

  const getLoactionOptionsWithSelectedValue = (selectedLocation: string | Nullish) =>
    map(
      (item) => ({label: item?.name ?? '', value: item?.id ?? ''}),
      locations?.filter((item) => item?.isActive || item?.id === selectedLocation) ?? []
    );

  const defaultManufacturer = manufacturers?.find((manufacturer) => manufacturer?.isDefault);

  const isLoading =
    isSeasonOptionsLoading ||
    isLocationOptionsLoading ||
    isRimOptionsLoading ||
    isManufacturerOptionsLoading ||
    isMechanicOptionsLoading;

  return {
    seasonOptions,
    locationOptions,
    rimOptions,
    manufacturerOptions,
    mechanicOptions,
    defaultManufacturer,
    isLoading,
    getManufacturerOptionsWithSelectedValue,
    getLoactionOptionsWithSelectedValue,
  };
}
