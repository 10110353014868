import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

interface ChildrenProps {
  isDragging: boolean;
  isSorting: boolean;
  handleProps?: React.HTMLAttributes<unknown>;
}

interface SortableProps {
  id: string;
  children: ((props: ChildrenProps) => React.ReactNode) | React.ReactElement;
  disabledDrop?: boolean;
  disabledDrag?: boolean;
  withHandle?: boolean;
}

export function Sortable(props: SortableProps) {
  const {attributes, listeners, setNodeRef, isDragging, transform, transition, isSorting} =
    useSortable({
      id: props.id,
      disabled: {
        droppable: props.disabledDrop ?? false,
        draggable: props.disabledDrag ?? false,
      },
    });

  const style = {
    transition,
    transform: transform ? CSS.Translate.toString(transform) : undefined,
  };

  const dndProps = {
    ...attributes,
    ...listeners,
  };

  return (
    <div ref={setNodeRef} style={style} {...(props.withHandle ? undefined : dndProps)}>
      {typeof props.children === 'function'
        ? props.children({
            isDragging,
            isSorting,
            handleProps: props.withHandle ? dndProps : undefined,
          })
        : props.children}
    </div>
  );
}
