import {useEffect, useState} from 'react';
import {Control, Path, UnpackNestedValue, PathValue} from 'react-hook-form';

import {path} from 'ramda';

type ReturnType<T> = {
  append: (newPersonId: string | unknown[]) => void;
  remove: (index: number) => void;
  reset: (newPersonId: string | unknown[]) => void;
  fields: T;
};
/**
 * @deprecated - use platform instead
 */
export const useFieldArray = <T extends unknown | unknown[] = unknown | unknown[]>(
  control: Control<Record<string, any>>,
  setValue: any,
  name: string | string[],
  initEmpty?: boolean
): ReturnType<T> => {
  const [fields, setFieldArray] = useState(
    Array.isArray(name) ? path(name, control._defaultValues) : control._defaultValues[name]
  );

  const append = (newPersonId: string | unknown[]) => {
    if (Array.isArray(newPersonId)) {
      setFieldArray([...fields, ...newPersonId]);
    } else {
      setFieldArray([...fields, newPersonId]);
    }
  };
  const reset = (newPersonId: string | unknown[]) => {
    if (Array.isArray(newPersonId)) {
      setFieldArray([...newPersonId]);
    } else {
      setFieldArray([newPersonId]);
    }
  };

  const remove = (index: number) => {
    const fieldValue = (fields as string[]).filter((_, i) => i !== index);
    setFieldArray(fieldValue);

    setValue(
      (Array.isArray(name) ? name.join(',') : name) as Path<T>,
      fieldValue as UnpackNestedValue<PathValue<T, Path<T>>>
    );
  };

  useEffect(() => {
    if (
      !(Array.isArray(name) ? path(name, control._defaultValues) : control._defaultValues[name])
        ?.length
    ) {
      if (initEmpty) {
        setFieldArray([{}]);
      }
    } else {
      setFieldArray(
        Array.isArray(name) ? path(name, control._defaultValues) : control._defaultValues[name]
      );
    }
  }, [control._defaultValues]);

  return {
    append,
    remove,
    reset,
    fields,
  };
};
