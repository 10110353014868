import {
  Button,
  Card,
  Choice,
  FormControl,
  FormField,
  Label,
  Separator,
  TextInput,
} from 'platform/components';
import {Grid, HStack, VStack} from 'platform/foundation';

import {defaultTo} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {
  BaseArticle,
  GetWarehousesResponse,
  TenantResponseBody,
  useGetDiscountGroupsQuery,
  useGetManufacturersQuery,
  useGetMarketingCodesQuery,
  useGetSuppliersQuery,
  useGetWarehouseAccountsQuery,
  VatRate,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  getOptionsFromSuppliers,
  getOptionsFromWarehouseAccounts,
  getOptionsFromWarehouses,
  getOptionsFromManufacturers,
} from '@omnetic-dms/shared';

import {Nullish, useNavigate} from 'shared';

import {TreeFolderPath} from '../../../../../components/TreeFolderPath';
import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';
import {getOptionsFromDiscountGroups} from '../../../../../utils/getOptionsFromDiscountGroups';
import {getOptionsFromMarketingCodes} from '../../../../../utils/getOptionsFromMarketingCodes';
import {getOptionsFromVatRates} from '../../../../../utils/getOptionsFromVatRates';
import {getVatRatesByTenantCountry} from '../../../../../utils/getVatRatesByTenantCountry';

interface OverviewGeneralInformationFormProps {
  articleId: string;
  control: FormControl<BaseArticle>;
  manufacturerId: string;
  warehouseNumber: string | Nullish;
  warehouses: GetWarehousesResponse;
  vatRates: VatRate[];
  tenant: TenantResponseBody;
  leafId?: string | null;
  isRemovable: boolean;
  leafIdChange: (leafId?: string) => void;
}

export function OverviewGeneralInformationForm(props: OverviewGeneralInformationFormProps) {
  const navigate = useNavigate();
  const {warehouseId} = useWarehouseParams();

  const {data: suppliers} = useGetSuppliersQuery();
  const {data: marketingCodes} = useGetMarketingCodesQuery();
  const {data: discountGroups} = useGetDiscountGroupsQuery();
  const {data: warehouseAccounts} = useGetWarehouseAccountsQuery();
  const {data: manufacturers} = useGetManufacturersQuery();

  const ratesByTenantCountry = getVatRatesByTenantCountry(
    props.vatRates,
    props.tenant.country
  )?.rates;

  // We want to filter inactive manufacturer, but if our props.manufacturerId is inactive, we want to display it anyway
  const matchingManufacturers = manufacturers?.filter(
    (manufacturer) => manufacturer.isActive || manufacturer.manufacturerId === props.manufacturerId
  );

  const isManufacturerEditable = isTrue(props.isRemovable);

  const handleMarketingCodeRedirect = () => {
    navigate(settingsRoutes.warehousesMarketingCode);
  };

  const handleDiscountGroupRedirect = () => {
    navigate(settingsRoutes.warehousesDiscountGroup);
  };

  const handleAccountRedirect = () => {
    navigate(settingsRoutes.warehousesAccount);
  };

  return (
    <Card title={i18n.t('entity.warehouse.labels.generalInformation')}>
      <VStack spacing={4}>
        <Grid columns={2}>
          <FormField
            control={props.control}
            type="text"
            name="name"
            label={i18n.t('general.labels.name')}
            data-testid={testIds.warehouse.articleDetailOverview('name')}
          />

          <FormField
            control={props.control}
            type="text"
            name="description"
            label={i18n.t('general.labels.additionalDescription')}
            data-testid={testIds.warehouse.articleDetailOverview('additionalDescription')}
          />
        </Grid>
        <Grid columns={4}>
          <FormField
            control={props.control}
            isRequired
            isDisabled={!isManufacturerEditable}
            type="text"
            name="manufacturerNumber"
            label={i18n.t('entity.warehouse.labels.manufacturerNumber')}
            data-testid={testIds.warehouse.articleDetailOverview('manufacturerNumber')}
          />
          <FormField
            isNotClearable
            isRequired
            isDisabled={!isManufacturerEditable}
            control={props.control}
            name="manufacturerId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.manufacturer')}
            options={getOptionsFromManufacturers(matchingManufacturers)}
            data-testid={testIds.warehouse.articleDetailOverview('manufacturer')}
          />
          <FormField
            isDisabled
            control={props.control}
            type="text"
            name="makeCode"
            label={i18n.t('entity.vehicle.labels.make')}
            data-testid={testIds.warehouse.articleDetailOverview('make')}
          />
          <FormField
            control={props.control}
            type="choice"
            name="supplierId"
            label={i18n.t('general.labels.supplier')}
            data-testid={testIds.warehouse.articleDetailOverview('supplier')}
            options={getOptionsFromSuppliers(suppliers)}
          />
        </Grid>
        <Grid columns={4}>
          <VStack>
            <Label>{i18n.t('entity.warehouse.labels.warehouse')}</Label>
            <Choice
              isDisabled
              value={warehouseId}
              options={getOptionsFromWarehouses(props.warehouses)}
              data-testid={testIds.warehouse.articleDetailOverview('warehouseId')}
            />
          </VStack>
          <TextInput
            value={defaultTo(null, props.warehouseNumber)}
            label={i18n.t('entity.warehouse.labels.warehouseNumber')}
            isDisabled
            data-testid={testIds.warehouse.articleDetailOverview('warehouseNumber')}
          />
          <FormField
            control={props.control}
            type="choice"
            options={getOptionsFromVatRates(ratesByTenantCountry)}
            name="vatType"
            label={i18n.t('general.labels.vat')}
            isNotClearable
            data-testid={testIds.warehouse.articleDetailOverview('rates')}
          />
          <FormField
            control={props.control}
            type="text"
            name="storageLocation"
            label={i18n.t('general.warehouse.labels.storageLocation')}
            data-testid={testIds.warehouse.articleDetailOverview('storageLocation')}
          />
        </Grid>
        <TreeFolderPath
          leafId={props.leafId}
          onChange={(activeFolder) => props.leafIdChange(activeFolder?.id)}
          data-testid={testIds.warehouse.articleDetailOverview('treeFolderPath')}
        />
      </VStack>
      <Separator />
      <VStack spacing={4}>
        <Grid columns={4}>
          <VStack>
            <FormField
              control={props.control}
              type="choice"
              name="discountGroup"
              options={getOptionsFromDiscountGroups(discountGroups)}
              label={i18n.t('general.labels.discountGroup')}
              data-testid={testIds.warehouse.articleDetailOverview('rabatGroup')}
            />
            <HStack>
              <Button
                variant="link"
                onClick={handleDiscountGroupRedirect}
                title={i18n.t('general.labels.discountGroups')}
                data-testid={testIds.warehouse.articleDetailOverview(
                  'buttons.discountGroupRedirect'
                )}
              />
            </HStack>
          </VStack>

          <VStack spacing={1}>
            <FormField
              control={props.control}
              type="choice"
              name="marketingCode"
              options={getOptionsFromMarketingCodes(marketingCodes)}
              label={i18n.t('general.labels.marketingCode')}
              data-testid={testIds.warehouse.articleDetailOverview('marketingCode')}
            />
            <HStack>
              <Button
                variant="link"
                onClick={handleMarketingCodeRedirect}
                title={i18n.t('general.labels.marketingCodes')}
                data-testid={testIds.warehouse.articleDetailOverview(
                  'buttons.marketingCodeRedirect'
                )}
              />
            </HStack>
          </VStack>

          <VStack spacing={1}>
            <FormField
              control={props.control}
              type="choice"
              name="warehouseAccount"
              options={getOptionsFromWarehouseAccounts(warehouseAccounts)}
              label={i18n.t('entity.warehouse.labels.warehouseAccount')}
              data-testid={testIds.warehouse.articleDetailOverview('warehouseAccount')}
              isNotClearable
            />
            <HStack>
              <Button
                variant="link"
                onClick={handleAccountRedirect}
                title={i18n.t('entity.warehouse.labels.warehouseAccounts')}
                data-testid={testIds.warehouse.articleDetailOverview('buttons.accountRedirect')}
              />
            </HStack>
          </VStack>
        </Grid>
      </VStack>
    </Card>
  );
}
