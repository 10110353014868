import {FC, ReactNode, HTMLAttributes} from 'react';
import {DragDropContext, DropResult, DraggableLocation} from 'react-beautiful-dnd';

import {noop} from '../../utils/someTeasUtils';

export type DraggedItem = {
  source: DraggableLocation;
  destination?: DraggableLocation;
};

type DragAndDropContextProps = {
  children: ReactNode;
  onDragEnd?: (draggedItem: DraggedItem) => void;
  onScroll?: HTMLAttributes<HTMLDivElement>['onScroll'];
};

export const DragAndDropContext: FC<DragAndDropContextProps> = ({
  children,
  onDragEnd = noop,
  ...rest
}) => {
  const handleDragEnd = (result: DropResult) => {
    const _result = {
      source: result.source,
      destination: result.destination,
    };
    onDragEnd(_result);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div {...rest}>{children}</div>
    </DragDropContext>
  );
};
