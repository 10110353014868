import styled from 'styled-components';

export const Placeholder = styled.div`
  display: inline-flex;
  justify-content: center;
  color: ${({theme}) => theme.colors.text.tertiary};
  font-size: ${({theme}) => theme.fontSizes.text.small};
  position: absolute;
  top: 7px;
  bottom: 0;
  left: 9px; // padding-left + 1px so it looks identical to placeholder
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  pointer-events: none;
`;
