export enum AuditParamType {
  TOGGLE = 'toggle',
  COUNTER = 'counter',
  DROPDOWN = 'dropdown',
  RADIO_BUTTONS = 'radioButtons',
  CHECKBOX_BUTTON = 'checkboxButton',
  PILL_RADIO = 'pillRadio',
  PILL_CHECKBOX = 'pillCheckbox',
  RATING = 'rating',
  PHOTO = 'photo',
  PHOTO_FULLWIDTH = 'photo_fullwidth',
  TEXT = 'text',
  ADDITIONAL_PHOTOS = 'additional_photos',
  ADDITIONAL_COMMENT = 'additional_comment',
  ADDITIONAL_LABEL = 'additional_label',
  NUMBER = 'number',
  DATE = 'date',
  DATE_YEAR = 'date_year',
  YEAR = 'year',
  SELECTABLE_BUTTON = 'selectableButton',
  CATEGORY_STATE = 'categoryState',
  ENHANCED_COMMENT = 'enhancedComment',
  MORE_PHOTOS = 'morePhotos',
  MILEAGE = 'mileage',
  AVERAGE_SPEED = 'averageSpeed',
  MAXIMUM_SPEED = 'maximumSpeed',
  START_TIME = 'startTime',
  TEST_DRIVE_DATE = 'testDriveDate',
  TIME_DURATION = 'timeDuration',
  END_TIME = 'endTime',
  AUTHOR = 'author',
  GENERAL_COMMENT = 'generalComment',
  WEAK_GPS_SIGNAL = 'weakGpsSignal',
  DEFAULT_OPTION = 'defaultOption',
  VIDEO = 'video',
}
