import {Severity} from '../types/Severity';
import {ThemeIconKey} from './foundationTheme';

export function getIconFromSeverity(severity?: Severity): ThemeIconKey {
  switch (severity) {
    case 'danger':
      return 'alert/error';
    case 'warning':
      return 'alert/warning';
    case 'success':
      return 'action/check_circle';
    default:
      return 'action/info';
  }
}
