import {format} from 'date-fns';
import {Grid, VStack} from 'platform/foundation';

import {FC} from 'react';
import {DeepPartial} from 'react-hook-form';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {FormLabel, selectRoundingTypes, selectRoundingStepList} from '@omnetic-dms/teas';

import {parseDate} from 'shared';

import {DocumentRoundingDefinitionFormType} from './types';
import {StyledGreyBox} from './ValiditySection';

interface ValidityItemProps {
  item: DeepPartial<DocumentRoundingDefinitionFormType['validities'][0]>;
}

export const ValidityItemDetail: FC<ValidityItemProps> = ({item}) => {
  const roundingStepList = useSelector(selectRoundingStepList);
  const roundingTypes = useSelector(selectRoundingTypes);

  const validFromString = item.validFrom
    ? format(parseDate(item.validFrom), 'dd / MM / yyyy')
    : '-';

  return (
    <StyledGreyBox>
      <VStack>
        <Grid columns={4} spacing={4}>
          <FormLabel>{i18n.t('entity.accounting.labels.roundingType')}</FormLabel>
          <FormLabel>{i18n.t('entity.accounting.labels.rounding')}</FormLabel>
          <FormLabel>{i18n.t('entity.accounting.labels.validFrom')}</FormLabel>
          <FormLabel>{i18n.t('entity.accounting.labels.taxRounding')}</FormLabel>
        </Grid>
        <Grid columns={4} spacing={4}>
          <div>{roundingTypes.find((i) => i.key === item.roundType)?.value}</div>
          <div>{roundingStepList.find((i) => i.key === item.roundStep)?.value}</div>
          <div>{validFromString}</div>
          <div>{item.taxRounding ? i18n.t('general.labels.yes') : i18n.t('general.labels.no')}</div>
        </Grid>
      </VStack>
    </StyledGreyBox>
  );
};
