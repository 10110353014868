import {Button, ButtonGroup, DialogFooter, FormControl} from 'platform/components';
import {Show} from 'platform/foundation';

import {SubmitHandler, UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {BasicInformation} from '../(sections)/BasicInformation/BasicInformation';
import {ServiceVehicleFormMode} from '../types/ServiceVehicleFormMode';
import {ServiceVehicleFormType} from '../types/ServiceVehicleFormType';
import {ServiceVehicleFormVariant} from '../types/ServiceVehicleFormVariant';

interface SimpleViewProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
  mode: ServiceVehicleFormMode;
  variant: ServiceVehicleFormVariant;
  isVehicleCreating: boolean;
  isVehicleUpdating: boolean;
  onVehicleCreation: SubmitHandler<ServiceVehicleFormType>;
  onVehicleUpdate: SubmitHandler<ServiceVehicleFormType>;
  onDiscard: () => void;
}

export function SimpleView(props: SimpleViewProps) {
  return (
    <>
      <BasicInformation
        control={props.control}
        formApi={props.formApi}
        variant={props.variant}
        data-testid={suffixTestId('basicInformationPage', props)}
      />
      <DialogFooter>
        <ButtonGroup align="right">
          <Button
            title={i18n.t('general.actions.discard')}
            variant="secondary"
            onClick={props.onDiscard}
            data-testid={suffixTestId('discard', props)}
          />
          <Show when={props.mode === ServiceVehicleFormMode.NEW}>
            <Button
              title={i18n.t('general.actions.create')}
              isLoading={props.isVehicleCreating}
              onClick={props.formApi.handleSubmit(props.onVehicleCreation)}
              data-testid={suffixTestId('create', props)}
            />
          </Show>
          <Show when={props.mode === ServiceVehicleFormMode.EDIT}>
            <Button
              title={i18n.t('general.actions.edit')}
              isLoading={props.isVehicleUpdating}
              onClick={props.formApi.handleSubmit(props.onVehicleUpdate)}
              data-testid={suffixTestId('edit', props)}
            />
          </Show>
        </ButtonGroup>
      </DialogFooter>
    </>
  );
}
