import {isFeatureEnabled} from 'feature-flags';
import {Card, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {FC, PropsWithChildren, ReactElement, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {always, groupBy, prop, reduce} from 'ramda';

import {featureFlags} from '@omnetic-dms/feature-flags';
import {
  fetchTemplateGroup,
  getDocumentTemplate,
  selectDocumentKinds,
  selectTemplateGroup,
  DocumentKind,
  DocumentKindGroupEnum,
} from '@omnetic-dms/teas';

import {Container} from '../styles';
import {DocumentTable} from './DocumentTable';

const isDocumentGroupEnabled = (documentGroupKey: string) =>
  match(documentGroupKey)
    .with('warehouse', () => isFeatureEnabled(featureFlags.WAREHOUSE_DOCUMENT_TEMPLATES))
    .with('workshop', () => isFeatureEnabled(featureFlags.WORKSHOP_DOCUMENT_TEMPLATES))
    .with('pneu_hotel', () => isFeatureEnabled(featureFlags.PNEUHOTEL_DOCUMENT_TEMPLATES))
    .otherwise(always(true));

export const DocumentTemplatesLayout: FC<PropsWithChildren<any>> = (): ReactElement => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<Record<string, boolean>>({'0': true});
  const templateGroup = useSelector(selectTemplateGroup);
  const handleCardChange = (panel: number) => () => {
    const obj = {...expanded};
    obj[panel] = !expanded[panel];
    setExpanded(obj);
  };

  const documentKinds = useSelector(selectDocumentKinds);
  const kindsByGroup = useMemo(() => groupBy(prop('group'), documentKinds), [documentKinds]);
  useEffect(() => {
    dispatch(fetchTemplateGroup);
    dispatch(getDocumentTemplate);
  }, [dispatch]);

  return (
    <Container>
      <VStack spacing={4}>
        <DataStatus isLoading={templateGroup?.loading}>
          {templateGroup?.data
            ?.filter(({key}) => isDocumentGroupEnabled(key))
            .map(({key, title}, index) => {
              const kinds = kindsByGroup[key as DocumentKindGroupEnum] ?? [];
              const templates = reduce<DocumentKind, string[]>(
                (aggr, curr) => [...aggr, curr.code],
                [],
                kinds
              );
              return (
                <Card
                  key={title}
                  title={title}
                  isExpanded={expanded[index]}
                  onExpandButtonClick={handleCardChange(index)}
                  isExpandable
                >
                  <DocumentTable templates={templates} groupName={title} />
                </Card>
              );
            })}
        </DataStatus>
      </VStack>
    </Container>
  );
};
