import {isDate} from 'date-fns';

import {isArray, isValidDate} from 'ramda-adjunct';

import {parseDate} from 'shared';

import {DateRangePicker} from '../../DateRangePicker/DateRangePicker';
import {useFieldProps} from '../hooks/useFieldProps';
import {DateRangeRendererResponseBody} from '../types/modelSchema';

export function DateRangeRenderer(props: DateRangeRendererResponseBody) {
  const {onChange, value, isRequired, error} = useFieldProps(props);

  const fromDate = isArray(value) && isDate(value[0]) ? value[0] : parseDate(value?.from);
  const toDate = isArray(value) && isDate(value[1]) ? value[1] : parseDate(value?.to);

  const dateRange: [Date, Date] | null =
    isValidDate(fromDate) && isValidDate(toDate) ? [fromDate, toDate] : null;

  return (
    <DateRangePicker
      label={props.label}
      value={dateRange}
      isRequired={isRequired}
      errorMessage={error?.message}
      onChange={onChange}
      tooltip={props.tooltip}
    />
  );
}
