import {ManagedConfirmDialogProps} from '../../ConfirmDialog/ManagedConfirmDialogProps';
import {DIALOGS_EVENTS} from './dialogsEvents';

/**
 * @about Opens a confirm dialog. Dialog will be
 * closed automatically when confirmed or canceled.
 *
 * Can be closed programmatically with closeCurrentDialog
 * or closeDialog('dialog-id') utilities.
 *
 * @param options Dialog options (question, id, onConfirm)
 *
 * @example
 * openConfirmDialog({ question: 'Are you sure?', onConfirm: () => deleteSomething().unwrap().then(...) })
 */
export function openConfirmDialog(options: ManagedConfirmDialogProps) {
  window.dispatchEvent(new CustomEvent(DIALOGS_EVENTS.openConfirm, {detail: {options}}));
}
