export enum TRANSLATED_LANGUAGES {
  czech = 'cs-CZ',
  english = 'en-GB',
  slovak = 'sk-SK',
  france = 'fr-FR',
  poland = 'pl-PL',
  germany = 'de-DE',
  spain = 'es-ES',
  italy = 'it-IT',
}
