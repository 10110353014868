import {BreadcrumbType} from 'platform/components';

import {useEffect} from 'react';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  getAuditStructureForStructureSettings,
  CreateTypeOfControlSettingsRequestBody,
  useThunkDispatch,
} from '@omnetic-dms/teas';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {AuditSettings} from './components/AuditSettings';

export function VehicleInspectionsNew() {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(getAuditStructureForStructureSettings.action({}));
  }, [dispatch]);

  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('page.vehicleSettings.labels.inspectionsTitle'),
      href: settingsRoutes.vehicleInspections,
    },
  ];

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.vehicleSettings.labels.inspectionsTitle'), breadcrumbs}}
      description={i18n.t('page.vehicleSettings.labels.inspectionDescription')}
      data-testid="integrations-inspections-add-new-settings-modal"
    >
      <AuditSettings
        type={CreateTypeOfControlSettingsRequestBody.inspectionType.VALIDATION_INSPECTION}
        isCreate
      />
    </SettingsTemplate>
  );
}
