export function transformPaste(e: React.ClipboardEvent<HTMLInputElement>) {
  const toBePasted = e.clipboardData.getData('text').toUpperCase();

  e.currentTarget.setRangeText(
    toBePasted,
    e.currentTarget.selectionStart ?? 0,
    e.currentTarget.selectionEnd ?? 0,
    'end'
  );
}
