import type {CancelablePromise} from '../types/CancelablePromise';
import type {VehicleSeriesDefinition} from '../types/VehicleSeriesDefinition';
import type {VehicleSeriesDefinitionRequestBody} from '../types/VehicleSeriesDefinitionRequestBody';
import type {VehicleSeriesSystemDefinitionRequestBody} from '../types/VehicleSeriesSystemDefinitionRequestBody';
import {request as __request} from '../utils/request';

export class VehicleSeriesDefinitionService {
  /**
   * @returns VehicleSeriesDefinition
   * @throws ApiError
   */
  public static createVehicleSeriesDefinition({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: VehicleSeriesDefinitionRequestBody;
  }): CancelablePromise<VehicleSeriesDefinition> {
    return __request({
      method: 'POST',
      url: '/dms/v1/vehicle-series-definition',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns VehicleSeriesDefinition
   * @throws ApiError
   */
  public static listVehicleSeriesDefinition({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Array<VehicleSeriesDefinition>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle-series-definition',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static deleteVehicleSeriesDefinition({
    id,
    authorization,
  }: {
    id: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'DELETE',
      url: '/dms/v1/vehicle-series-definition/{id}',
      path: {
        id,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns VehicleSeriesDefinition
   * @throws ApiError
   */
  public static getVehicleSeriesDefinition({
    id,
    authorization,
  }: {
    id: string;
    authorization?: string;
  }): CancelablePromise<VehicleSeriesDefinition> {
    return __request({
      method: 'GET',
      url: '/dms/v1/vehicle-series-definition/{id}',
      path: {
        id,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns VehicleSeriesDefinition
   * @throws ApiError
   */
  public static updateVehicleSeriesDefinition({
    id,
    authorization,
    requestBody,
  }: {
    id: string;
    authorization?: string;
    requestBody?: VehicleSeriesDefinitionRequestBody;
  }): CancelablePromise<VehicleSeriesDefinition> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/vehicle-series-definition/{id}',
      path: {
        id,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns VehicleSeriesDefinition
   * @throws ApiError
   */
  public static updateVehicleSeriesSystemDefinition({
    id,
    authorization,
    requestBody,
  }: {
    id: string;
    authorization?: string;
    requestBody?: VehicleSeriesSystemDefinitionRequestBody;
  }): CancelablePromise<VehicleSeriesDefinition> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/vehicle-series-system-definition/{id}',
      path: {
        id,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
