import {forwardRef, Ref} from 'react';

import {useDataGridFiltersModel} from '../context/useDataGridFiltersModel';
import {useResetFilterByUser} from '../hooks/useResetFilterByUser';
import {ColumnApi, INoRowsOverlayParams} from '../types/AgGridTypes';
import {FeGridSettings} from '../types/Api';
import {DataGridProps} from '../types/DataGridProps';
import {DefaultEmptyStateRenderer} from './DefaultEmptyStateRenderer';

export interface EmptyStateRendererWrapperProps extends INoRowsOverlayParams {
  gridProps: DataGridProps;
  settings: FeGridSettings;
  columnApi: ColumnApi;
}

export const EmptyStateRendererWrapper = forwardRef<HTMLDivElement, EmptyStateRendererWrapperProps>(
  EmptyStateRendererWrapperComponent
);
export function EmptyStateRendererWrapperComponent(
  props: EmptyStateRendererWrapperProps,
  ref: Ref<HTMLDivElement>
) {
  const {gridProps, api, columnApi} = props;
  const {emptyState} = gridProps;

  const {isFilterActive} = useDataGridFiltersModel();
  const disableFilter = useResetFilterByUser(api, columnApi);

  return (
    // There is style relying on this class
    // eslint-disable-next-line react/forbid-dom-props
    <div ref={ref} className="ag-custom-overlay-wrapper">
      <DefaultEmptyStateRenderer
        emptyStatus={emptyState}
        filterIsActive={isFilterActive}
        disableFilter={disableFilter}
      />
    </div>
  );
}
