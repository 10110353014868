import {createContext} from 'react';

import {Filters} from '../types/Filters';

interface Context {
  filterValues: Filters;
  filterValuesWithoutStaticFilters: Filters;
  isFilterActive: boolean;
  onFiltersChange: (newFilters: Filters) => void;
}

export const DataGridFiltersModelContext = createContext<Context>({} as Context);
