import {TooltipProps} from 'platform/components';

import {isArray, isString} from 'ramda-adjunct';

const isStringArray = (val: unknown): val is Array<string> => isArray(val) && val.every(isString);

export const getItemTooltipProps = (index: number, props: TooltipProps): TooltipProps => ({
  ...props,
  label: isStringArray(props.label) ? props.label[index] : props.label,
});
