import {showNotification} from 'platform/components';

import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {isEmpty, isNil} from 'ramda';

import {
  RotateImageAssetRequestBody,
  useFileOperationListQuery,
  useRotateImageAssetMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {useApiDispatch} from '../../../hooks/useApiDispatch';
import {getAuditAsset} from '../../../store/carAudit/actions';
import {updateAssetSrc} from '../../../store/carAudit/reducer';
import {useConditionUploadContext} from './useConditionUploadContext';

type Props = {
  categoryId: string;
  paramDefinitionId: string;
};

export const useRotateImages = ({categoryId, paramDefinitionId}: Props) => {
  const [rotateImage] = useRotateImageAssetMutation();
  const dispatch = useDispatch();
  const apiDispatch = useApiDispatch();
  const {updateUploadedImage} = useConditionUploadContext();
  const [batchIds, setBatchIds] = useState<{batchId: string; imageId: string}[]>([]);
  const {data} = useFileOperationListQuery(
    {
      batchId: batchIds[0]?.batchId as string,
    },
    {
      skip: isEmpty(batchIds),
      pollingInterval: 1000,
    }
  );

  const handleImageRotate = (imageId: string, angle: RotateImageAssetRequestBody['angle']) => {
    rotateImage({
      rotateImageAssetRequestBody: {
        images: [imageId],
        angle,
      },
    })
      .unwrap()
      .catch(handleApiError)
      .then((data) => data && setBatchIds((prev) => [...prev, {batchId: data.batchId, imageId}]));
  };

  const isImageProcessingInProgress = (imageId: string | null) =>
    batchIds.some((item) => item.imageId === imageId);

  useEffect(() => {
    if (isNil(data) || isNil(batchIds[0])) {
      return;
    }

    const {state, generatedRemoteFileId} =
      data.find((operation) => operation.batchId === batchIds[0]?.batchId) ?? {};
    if (state === 'IN_PROGRESS') {
      return;
    }
    if (state === 'FAILED' || isNil(generatedRemoteFileId)) {
      showNotification.error();
      setBatchIds((prev) => prev.slice(1));
      return;
    }

    apiDispatch(getAuditAsset.action, {
      assetId: generatedRemoteFileId,
    }).then((asset) => {
      dispatch(
        updateAssetSrc({
          categoryId,
          paramDefinitionId,
          imageId: batchIds[0]?.imageId,
          resizeUrl: asset.resizeUrl,
        })
      );
      updateUploadedImage(batchIds[0]?.imageId, {
        src: asset.resizeUrl,
      });
      setBatchIds((prev) => prev.slice(1));
      showNotification.success(i18n.t('entity.photo.notifications.rotationSuccess'));
    });
  }, [data]);

  return {
    handleImageRotate,
    isImageProcessingInProgress,
  };
};
