import {isFeatureEnabled} from 'feature-flags';
import {MenuBadge} from 'platform/components';

import {isPositive} from 'ramda-adjunct';

import {GetDocumentContextCountApiArg, useGetDocumentContextCountQuery} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';

export function useDocumentContextCount(
  contextTarget: GetDocumentContextCountApiArg['contextTarget'],
  contextId: GetDocumentContextCountApiArg['contextId']
): MenuBadge | undefined {
  const canShowDocumentsCount = isFeatureEnabled(featureFlags.CORE_NUMBER_OF_DOCUMENTS);

  const {data: documentsCount} = useGetDocumentContextCountQuery(
    {
      contextTarget,
      contextId,
    },
    {skip: !contextId || !canShowDocumentsCount}
  );

  if (!canShowDocumentsCount || !isPositive(documentsCount?.count)) {
    return undefined;
  }

  return {
    colorScheme: 'neutral',
    value: documentsCount?.count ?? 0,
  };
}
