import type {CancelablePromise} from '../types/CancelablePromise';
import {request as __request} from '../utils/request';

export class DeleteTemplateService {
  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static deleteTemplate({
    templateId,
    authorization,
  }: {
    templateId: string;
    authorization?: string;
  }): CancelablePromise<any> {
    return __request({
      method: 'DELETE',
      url: '/dms/v2/document/template/{templateId}',
      path: {
        templateId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
}
