import type {CancelablePromise} from '../types/CancelablePromise';
import type {FileUri} from '../types/FileUri';
import type {UploadFile} from '../types/UploadFile';
import type {UploadFileRequestBody} from '../types/UploadFileRequestBody';
import {request as __request} from '../utils/request';

export class FileStorageService {
  /**
   * @returns FileUri
   * @throws ApiError
   */
  public static getFileUri({
    fileId,
    authorization,
  }: {
    fileId: string;
    authorization?: string;
  }): CancelablePromise<FileUri> {
    return __request({
      method: 'GET',
      url: '/dms/v1/file-storage/{fileId}',
      path: {
        fileId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns UploadFile
   * @throws ApiError
   */
  public static uploadFile({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: UploadFileRequestBody;
  }): CancelablePromise<UploadFile> {
    return __request({
      method: 'POST',
      url: '/dms/v1/file-storage',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
