import {CardVariantType, FormSubmitHandler} from 'platform/components';

import {
  CreateCommentApiArg,
  DeleteCommentApiArg,
  EntityResourceIds,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useGetCommentsQuery,
} from '@omnetic-dms/api';

import {TestIdProps} from 'shared';

import {useGetUserOptions} from '../../hooks/useGetUserOptions';
import {handleApiError} from '../../utils/handleApiError';
import {CommentsCard} from '../CommentsCard/CommentsCard';
import {CommentCardForm} from '../CommentsCard/components/CommentsCardInput';

interface CommentsWidgetProps extends TestIdProps {
  resourceId: EntityResourceIds;
  recordId: string;
  title?: string;
  cardVariant?: CardVariantType;
  isAddDisabled?: boolean;
  isDeleteDisabled?: boolean;
}

export function CommentsWidget(props: CommentsWidgetProps) {
  const {
    data: comments,
    isLoading: isFetchingCommentsLoading,
    isError: isFetchingCommentsErrored,
  } = useGetCommentsQuery({
    resourceId: props.resourceId,
    recordId: props.recordId,
  });
  const {userOptions} = useGetUserOptions();

  const [deleteComment, {isLoading: isDeleteCommentLoading}] = useDeleteCommentMutation();
  const [createComment, {isLoading: isSendCommentLoading}] = useCreateCommentMutation();

  const onCommentSubmit: FormSubmitHandler<CommentCardForm> = async (values, _, reset) => {
    const arg: CreateCommentApiArg = {
      resourceId: props.resourceId,
      recordId: props.recordId,
      createCommentRequestBody: {
        text: values.comment,
      },
    };
    await createComment(arg)
      .unwrap()
      .then(() => {
        reset();
      })
      .catch(handleApiError);
  };

  const onCommentDelete = async (commentId: string) => {
    const arg: DeleteCommentApiArg = {
      resourceId: props.resourceId,
      recordId: props.recordId,
      commentId,
    };
    await deleteComment(arg).unwrap().catch(handleApiError);
  };

  return (
    <CommentsCard
      cardVariant={props.cardVariant}
      title={props.title}
      comments={comments}
      isSendCommentLoading={isSendCommentLoading}
      isFetchingCommentsLoading={isFetchingCommentsLoading}
      isFetchingCommentsErrored={isFetchingCommentsErrored}
      isDeleteCommentLoading={isDeleteCommentLoading}
      onSubmit={onCommentSubmit}
      onDelete={onCommentDelete}
      userOptions={userOptions}
      isAddDisabled={props.isAddDisabled}
      isDeleteDisabled={props.isDeleteDisabled}
      data-testid={props['data-testid']}
    />
  );
}
