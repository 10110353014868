import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

export const GetServiceOrderCustomerContractDetailsApiResponseSchema = z
  .object({
    id: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    description: z.string().nullable().optional(),
    validityUntil: z.string().nullable().optional(),
    customerTypeForContract: z
      .enum(['OTHER', 'DEFAULT', 'FLEET', 'INSURANCE', 'INVOICE', 'OPERATOR'])
      .nullable()
      .optional(),
    materialDiscountGroup: z
      .object({
        id: z.string().nullable().optional(),
        name: z.string().nullable().optional(),
        code: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
  })
  .nullable();

export type GetServiceOrderCustomerContractDetailsApiResponse = z.infer<
  typeof GetServiceOrderCustomerContractDetailsApiResponseSchema
>;

export const GetServiceOrderCustomerContractDetailsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
});

export type GetServiceOrderCustomerContractDetailsApiArg = z.infer<
  typeof GetServiceOrderCustomerContractDetailsApiArgSchema
>;

export const PostServiceOrderCustomerContractDeleteApiResponseSchema = BaseVoidResponseSchema;

export type PostServiceOrderCustomerContractDeleteApiResponse = z.infer<
  typeof PostServiceOrderCustomerContractDeleteApiResponseSchema
>;

export const PostServiceOrderCustomerContractDeleteApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z
    .object({
      isDiscountRemove: z.boolean(),
    })
    .nullable(),
});

export type PostServiceOrderCustomerContractDeleteApiArg = z.infer<
  typeof PostServiceOrderCustomerContractDeleteApiArgSchema
>;

export const PutServiceOrderCustomerContractChangeApiResponseSchema = BaseVoidResponseSchema;

export type PutServiceOrderCustomerContractChangeApiResponse = z.infer<
  typeof PutServiceOrderCustomerContractChangeApiResponseSchema
>;

export const PutServiceOrderCustomerContractChangeApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z
    .object({
      customerContractId: z.string(),
      isDiscountApply: z.boolean(),
    })
    .nullable(),
});

export type PutServiceOrderCustomerContractChangeApiArg = z.infer<
  typeof PutServiceOrderCustomerContractChangeApiArgSchema
>;
