export const getLocalStorageValuesSize = () => {
  let errorMessage = 'LocalStorage value sizes in bytes:';

  for (const storageKey in localStorage) {
    if (!localStorage.hasOwnProperty(storageKey)) {
      continue;
    }

    const storageValueSize = new Blob([localStorage[storageKey], storageKey]).size;
    errorMessage += `\n${storageKey} = ${storageValueSize}`;
  }

  return errorMessage;
};
