import {Helmet} from 'react-helmet-async';

import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {employeeRoutes, testIds} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

export function EmployeeOverview() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId, sourceSearchParams}) => {
    if (actionKey === 'redirectDetail') {
      const id = isArray(rowId) ? rowId[0] : rowId;

      navigate(composePath(employeeRoutes.detail, {params: {id}, queryParams: sourceSearchParams}));
    }
  };

  return (
    <>
      <Helmet title={i18n.t('page.employees.title')} />
      <Main
        isFullHeight
        actions={[
          {
            type: 'button',
            title: i18n.t('entity.employee.actions.newEmployee'),
            onClick: () => navigate(employeeRoutes.createEmployee),
          },
        ]}
        heading={i18n.t('page.employees.title')}
        data-testid={testIds.employee.overview('overview')}
      >
        <DataGrid
          gridCode="employees"
          actionCallback={actionCallback}
          data-testid={testIds.employee.overview('overview')}
        />
      </Main>
    </>
  );
}
