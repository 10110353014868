import {NumberedMenuItem} from '../NumberedMenuItem';

export function prependItemsWithCounter(items: NumberedMenuItem[]): NumberedMenuItem[] {
  let counter = 0;
  let currentCounter = 0;

  return items.map((item: NumberedMenuItem) => {
    if (item.hasCounter) {
      currentCounter = ++counter;
    }

    if (item.hasSeparator) {
      counter = 0;
    }

    return {
      ...item,
      label: item.hasCounter ? `${currentCounter} - ${item.label}` : item.label,
    };
  });
}
