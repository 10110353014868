import {Day} from 'date-fns';
import {Box, VStack} from 'platform/foundation';

import {TestIdProps} from 'shared';

import {FormControlProps} from '../../../types/FormControlProps';
import {CalendarControl} from '../../Calendar/components/CalendarControl/CalendarControl';
import {CalendarView} from '../../Calendar/components/CalendarView/CalendarView';
import {CalendarFormValue, DisabledDate} from '../../Calendar/types';
import {Separator} from '../../Separator/Separator';

interface DateRangePickerCalendarProps extends FormControlProps<CalendarFormValue>, TestIdProps {
  activeMonth: Date;
  onChangeActiveMonth: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  calendarControlMinDate?: Date;
  calendarControlMaxDate?: Date;
  disabledDates?: DisabledDate[];
  disabledWeekdays?: Day[];
  hoveredDate?: Date | null;
  onHoverDate?: (date: Date) => void;
}

export function DateRangePickerCalendar(props: DateRangePickerCalendarProps) {
  return (
    <VStack grow={1} width={80}>
      <Box padding={4}>
        <CalendarControl
          activeMonth={props.activeMonth}
          onChangeActiveMonth={props.onChangeActiveMonth}
          minDate={props.calendarControlMinDate ?? props.minDate}
          maxDate={props.calendarControlMaxDate ?? props.maxDate}
          data-testid={props['data-testid']}
        />
      </Box>
      <Separator spacing={0} />
      <Box padding={4}>
        <CalendarView
          activeMonth={props.activeMonth}
          onChange={props.onChange}
          value={props.value}
          minDate={props.minDate}
          maxDate={props.maxDate}
          disabledWeekdays={props.disabledWeekdays}
          disabledDates={props.disabledDates}
          hoveredDate={props.hoveredDate}
          onHoverDate={props.onHoverDate}
          isSixWeeksForced
          isRange
          data-testid={props['data-testid']}
        />
      </Box>
    </VStack>
  );
}
