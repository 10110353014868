import {DataStatus, Pagination} from 'platform/components';

import {useRef, useState} from 'react';

import {useOnMount} from 'shared';

import {useHttpCalls} from '../hooks/useHttpCalls';
import {GetDataIdsByQuery} from '../types/Api';

interface ControlledRowControlProps {
  gridCode: string;
  dataQueryId: string;
  onPageChange: (id: string) => void;
  entityId: string;
}

export function ControlledRowControl(props: ControlledRowControlProps) {
  const http = useHttpCalls({gridCode: props.gridCode});
  const ids = useRef<GetDataIdsByQuery[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowIndex, setRowIndex] = useState(1);

  useOnMount(() => {
    http.getDataIdsByQuery(props.dataQueryId, 0, 1000, false).then((response) => {
      ids.current = response ?? [];
      const newRowIndex = response?.find((id) => id.id === props.entityId)?.rowNumber;
      setRowIndex(newRowIndex as number);
      setIsLoading(false);
    });
  });

  const handlePageChange = (newPage: number) => {
    const newEntityId = ids.current.find((id) => id.rowNumber === newPage)?.id;
    setRowIndex(newPage);
    props.onPageChange(newEntityId as string);
  };

  if (isLoading) {
    return <DataStatus isLoading></DataStatus>;
  }

  return (
    <Pagination
      variant="text-only"
      showPrevButton
      showNextButton
      pagesQuantity={ids.current.length}
      onPageChange={handlePageChange}
      page={rowIndex}
    />
  );
}
