import {useFormatCurrency, useFormatPercentage} from 'platform/locale';
import {Pattern, match} from 'ts-pattern';

import {always} from 'ramda';

import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {IndividualPriceType} from '../types/IndividualPriceType';

export const useGetDiscountValue = () => {
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();

  return (data: IndividualPriceType) =>
    match(data)
      .with(
        {fixedPrice: Pattern.not(Pattern.nullish)},
        always(
          formatCurrency(
            data?.fixedPrice?.amount ?? 0,
            data?.fixedPrice?.currency ?? DEFAULT_CURRENCY,
            2
          )
        )
      )
      .with(
        {discountedHourlyRate: Pattern.not(Pattern.nullish)},
        always(
          formatCurrency(
            data?.discountedHourlyRate?.amount ?? 0,
            data?.discountedHourlyRate?.currency ?? DEFAULT_CURRENCY,
            2
          )
        )
      )
      .with(
        {discountOnSalePrice: Pattern.not(Pattern.nullish)},
        always(formatPercentage((data?.discountOnSalePrice ?? 0) / 100))
      )
      .with(
        {marginToPurchasePrice: Pattern.not(Pattern.nullish)},
        always(formatPercentage((data?.marginToPurchasePrice ?? 0) / 100))
      )
      .with(
        {percentageDiscount: Pattern.not(Pattern.nullish)},
        always(formatPercentage((data?.percentageDiscount ?? 0) / 100))
      )
      .otherwise(always(null));
};
