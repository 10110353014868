import {Tooltip, Trend} from 'platform/components';
import {
  Hide,
  HStack,
  Icon,
  Show,
  Space,
  Text,
  ThemeColorTextPath,
  VStack,
} from 'platform/foundation';
import {useCurrencySymbolFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {always, negate} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {CurrencyCodeType, suffixTestId, TestIdProps} from 'shared';

interface PriceColumnProps extends TestIdProps {
  title: string;
  currency?: CurrencyCodeType | null;
  percentage?: number | null;
  difference?: string;
  priceWithVat?: string;
  priceWithoutVat?: string;
  tooltipText?: string;
  colored?: boolean;
  isNonDeductible?: boolean;
  foreignPrice?: string;
  invertTag?: boolean;
}

export function PriceColumn(props: PriceColumnProps) {
  const formatCurrencySymbol = useCurrencySymbolFormatter();
  const formatCurrency = useFormatCurrency();

  const isNegative = Boolean(props.priceWithVat && parseFloat(props.priceWithVat) < 0);

  const isNegativeDifference = Boolean(props.difference && Number(props.difference) < 0);

  const currency = (props.currency as CurrencyCodeType) ?? 'CZK';

  const priceWithVatAsNumber = Number(props.priceWithVat);
  const priceWithVat = props.priceWithVat
    ? `${priceWithVatAsNumber >= 0 && props.colored ? '+' : ''}${formatCurrency(priceWithVatAsNumber, currency)}`
    : `– ${formatCurrencySymbol(currency)}`;
  const priceWithoutVat = props.priceWithoutVat
    ? formatCurrency(Number(props.priceWithoutVat), currency)
    : `– ${formatCurrencySymbol(currency)}`;

  let diffAsNumber = Number(props.difference);

  if (props.invertTag) {
    diffAsNumber = negate(diffAsNumber);
  }

  const textColor = match<[boolean | undefined, boolean], ThemeColorTextPath>([
    props.colored,
    isNegative,
  ])
    .with([true, true], always('danger'))
    .with([true, false], always('success'))
    .otherwise(always('primary'));

  return (
    <VStack grow={1} data-testid={props['data-testid']} wrap>
      <HStack align="center" spacing={1}>
        <Text size="xSmall" color="secondary">
          {i18n.t(props.title)}
        </Text>
        <Show when={isNotNilOrEmpty(props.tooltipText)}>
          <Tooltip label={props.tooltipText}>
            <Icon value="action/help" size={4} color="palettes.black.900.60" />
          </Tooltip>
        </Show>
      </HStack>

      <Space vertical={4} />

      <HStack spacing={3} align="center">
        <Text
          alternative
          size="large"
          color={textColor}
          data-testid={suffixTestId('priceWithVat', props)}
        >
          {priceWithVat}
        </Text>

        <Show when={isNotNilOrEmpty(props.foreignPrice)}>
          <Text size="small" color={textColor} data-testid={suffixTestId('foreignPrice', props)}>
            / {props.foreignPrice}
          </Text>
        </Show>

        <Show when={props.percentage}>
          <Trend
            label={`${Math.round(props?.percentage as number)}%`}
            isPositive={!isNegative}
            isNegative={Boolean(isNegative)}
            isSmall
            isSubtle
            data-testid={suffixTestId('percentage', props)}
          />
        </Show>
        <Show
          when={
            props.difference && !isNaN(Number(props.difference)) && Number(props.difference) !== 0
          }
          onDesktop
        >
          <Trend
            label={formatCurrency(diffAsNumber, currency) ?? '0'}
            isPositive={!isNegativeDifference}
            isNegative={isNegativeDifference}
            isSmall
            isSubtle
            isReverseIcon={props.invertTag}
            data-testid={suffixTestId('difference', props)}
          />
        </Show>
      </HStack>

      <Hide when={props.isNonDeductible}>
        <Text size="xSmall" color="secondary" data-testid={suffixTestId('priceWithoutVat', props)}>
          {priceWithoutVat}&nbsp;{i18n.t('general.labels.withoutVat')}
        </Text>
      </Hide>

      <Show
        when={
          props.difference && !isNaN(Number(props.difference)) && Number(props.difference) !== 0
        }
        onMobile
        onTablet
        onNotebook
      >
        <Space vertical={2} />
        <Trend
          label={formatCurrency(diffAsNumber, currency) ?? '0'}
          isPositive={!isNegativeDifference}
          isNegative={isNegativeDifference}
          isSmall
          isSubtle
          isReverseIcon={props.invertTag}
          data-testid={suffixTestId('difference', props)}
        />
      </Show>
    </VStack>
  );
}
