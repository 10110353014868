interface BaseMenuItem {
  id: string;
}

interface MenuItem<T extends BaseMenuItem> {
  id: string;
  content?: T[];
}

export function flattenMenuItems<TMenuItem extends MenuItem<TMenuItem>>(
  menuItems: TMenuItem[]
): Record<string, TMenuItem> {
  return menuItems.reduce(
    (acc, menuItem) => ({
      ...acc,
      [menuItem.id]: menuItem,
      ...(menuItem.content ? flattenMenuItems(menuItem.content) : {}),
    }),
    {} as Record<TMenuItem['id'], TMenuItem>
  );
}
