import {match} from 'ts-pattern';

import i18n from '@omnetic-dms/i18n';

import {OwnershipType} from '../../CustomerVehicleSelection/types/ownershipType';

export const getOwnershipTitle = (ownershipType: OwnershipType) =>
  match(ownershipType)
    .with('CORVEHICLECUSTOMERTYPE_OPERATOR', () => i18n.t('entity.ownership.label.vehicleOperator'))
    .with('CORVEHICLECUSTOMERTYPE_OWNER', () => i18n.t('entity.ownership.label.vehicleOwner'))
    .with('CORVEHICLECUSTOMERTYPE_USER', () => i18n.t('entity.ownership.label.vehicleUser'))
    .with('CORVEHICLECUSTOMERTYPE_MANAGER', () => i18n.t('entity.ownership.label.vehicleManager'))
    .with('CORVEHICLECUSTOMERTYPE_INVOICE', () => i18n.t('entity.ownership.label.defaultRecepient'))
    .exhaustive();
