import {
  DeleteServiceOrderJobApiArg,
  DeleteServiceOrderJobApiResponse,
  GetOrderJobItemsTotalAmountApiArg,
  GetOrderJobItemsTotalAmountApiResponse,
  GetServiceCaseOrderJobsApiArg,
  GetServiceCaseOrderJobsApiResponse,
  GetServiceOrderJobApiArg,
  GetServiceOrderJobApiResponse,
  PatchServiceOrderJobApiArg,
  PatchServiceOrderJobApiResponse,
  PostServiceOrderJobApiArg,
  PostServiceOrderJobApiResponse,
  PostServiceOrderJobFromPostponeJobApiArg,
  PostServiceOrderJobFromPostponeJobApiResponse,
  PutJobToOtherOrderApiArg,
  PutJobToOtherOrderApiResponse,
  PutServiceOrderJobMechanicsApiResponse,
  PutServiceOrderJobMechanicsApiArg,
  GetServiceJobActionsApiArg,
  GetServiceJobActionsApiResponse,
  PostServiceWorkItemCopyToServiceOrderApiArg,
  PostServiceWorkItemCopyToServiceOrderApiResponse,
  PatchServiceCaseJobCustomPriceApiResponse,
  PatchServiceCaseJobCustomPriceApiArg,
  PostServiceOrderJobApiResponseSchema,
  PostServiceOrderJobApiArgSchema,
  GetServiceOrderJobApiResponseSchema,
  GetServiceOrderJobApiArgSchema,
  DeleteServiceOrderJobApiResponseSchema,
  DeleteServiceOrderJobApiArgSchema,
  PatchServiceOrderJobApiResponseSchema,
  PatchServiceOrderJobApiArgSchema,
  PutServiceOrderJobMechanicsApiResponseSchema,
  PutServiceOrderJobMechanicsApiArgSchema,
  GetOrderJobItemsTotalAmountApiResponseSchema,
  GetOrderJobItemsTotalAmountApiArgSchema,
  PutJobToOtherOrderApiResponseSchema,
  PutJobToOtherOrderApiArgSchema,
  GetServiceCaseOrderJobsApiResponseSchema,
  GetServiceCaseOrderJobsApiArgSchema,
  PostServiceOrderJobFromPostponeJobApiResponseSchema,
  PostServiceOrderJobFromPostponeJobApiArgSchema,
  GetServiceJobActionsApiResponseSchema,
  GetServiceJobActionsApiArgSchema,
  PostServiceWorkItemCopyToServiceOrderApiResponseSchema,
  PostServiceWorkItemCopyToServiceOrderApiArgSchema,
  PatchServiceCaseJobCustomPriceApiResponseSchema,
  PatchServiceCaseJobCustomPriceApiArgSchema,
} from '../types/metadaWorkshopServiceOrderJob';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceOrderJobApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postServiceOrderJob: build.mutation<PostServiceOrderJobApiResponse, PostServiceOrderJobApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderJobApiResponseSchema,
        requestSchema: PostServiceOrderJobApiArgSchema,
      },
    }),
    getServiceOrderJob: build.query<GetServiceOrderJobApiResponse, GetServiceOrderJobApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderJob', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: GetServiceOrderJobApiResponseSchema,
        requestSchema: GetServiceOrderJobApiArgSchema,
      },
    }),
    deleteServiceOrderJob: build.mutation<
      DeleteServiceOrderJobApiResponse,
      DeleteServiceOrderJobApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJob', id: queryArg.serviceJobId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        'checkout',
        'vehicleCampaign',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: DeleteServiceOrderJobApiResponseSchema,
        requestSchema: DeleteServiceOrderJobApiArgSchema,
      },
    }),
    patchServiceOrderJob: build.mutation<
      PatchServiceOrderJobApiResponse,
      PatchServiceOrderJobApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJob', id: queryArg.serviceJobId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
      ],
      extraOptions: {
        responseSchema: PatchServiceOrderJobApiResponseSchema,
        requestSchema: PatchServiceOrderJobApiArgSchema,
      },
    }),
    putServiceOrderJobMechanics: build.mutation<
      PutServiceOrderJobMechanicsApiResponse,
      PutServiceOrderJobMechanicsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/assign-mechanic`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJob', id: queryArg.serviceJobId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceJobId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PutServiceOrderJobMechanicsApiResponseSchema,
        requestSchema: PutServiceOrderJobMechanicsApiArgSchema,
      },
    }),
    getOrderJobItemsTotalAmount: build.query<
      GetOrderJobItemsTotalAmountApiResponse,
      GetOrderJobItemsTotalAmountApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/items-total-amount`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceJobId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: GetOrderJobItemsTotalAmountApiResponseSchema,
        requestSchema: GetOrderJobItemsTotalAmountApiArgSchema,
      },
    }),
    putJobToOtherOrder: build.mutation<PutJobToOtherOrderApiResponse, PutJobToOtherOrderApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/move`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrder', id: queryArg.body.serviceOtherOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.body.serviceOtherOrderId},
        'serviceOrderTotalAmount',
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.body.serviceOtherOrderId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PutJobToOtherOrderApiResponseSchema,
        requestSchema: PutJobToOtherOrderApiArgSchema,
      },
    }),
    getServiceCaseOrderJobs: build.query<
      GetServiceCaseOrderJobsApiResponse,
      GetServiceCaseOrderJobsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/jobs`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: GetServiceCaseOrderJobsApiResponseSchema,
        requestSchema: GetServiceCaseOrderJobsApiArgSchema,
      },
    }),
    postServiceOrderJobFromPostponeJob: build.mutation<
      PostServiceOrderJobFromPostponeJobApiResponse,
      PostServiceOrderJobFromPostponeJobApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/postpone-job/${queryArg.postponeJobId}/job`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'postponeJobs', id: queryArg.serviceCaseId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderJobFromPostponeJobApiResponseSchema,
        requestSchema: PostServiceOrderJobFromPostponeJobApiArgSchema,
      },
    }),
    getServiceJobActions: build.query<GetServiceJobActionsApiResponse, GetServiceJobActionsApiArg>({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/actions`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceJobActions', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: GetServiceJobActionsApiResponseSchema,
        requestSchema: GetServiceJobActionsApiArgSchema,
      },
    }),
    postServiceWorkItemCopyToServiceOrder: build.mutation<
      PostServiceWorkItemCopyToServiceOrderApiResponse,
      PostServiceWorkItemCopyToServiceOrderApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/work-item-copy`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        'serviceOrderTotalAmount',
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PostServiceWorkItemCopyToServiceOrderApiResponseSchema,
        requestSchema: PostServiceWorkItemCopyToServiceOrderApiArgSchema,
      },
    }),
    patchServiceCaseJobCustomPriceApi: build.mutation<
      PatchServiceCaseJobCustomPriceApiResponse,
      PatchServiceCaseJobCustomPriceApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/custom-price`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: PatchServiceCaseJobCustomPriceApiResponseSchema,
        requestSchema: PatchServiceCaseJobCustomPriceApiArgSchema,
      },
    }),
  }),
});

export const {
  usePostServiceOrderJobMutation,
  useGetServiceOrderJobQuery,
  useDeleteServiceOrderJobMutation,
  usePatchServiceOrderJobMutation,
  useGetServiceCaseOrderJobsQuery,
  usePutJobToOtherOrderMutation,
  usePostServiceOrderJobFromPostponeJobMutation,
  useGetOrderJobItemsTotalAmountQuery,
  usePutServiceOrderJobMechanicsMutation,
  useLazyGetServiceOrderJobQuery,
  useGetServiceJobActionsQuery,
  usePostServiceWorkItemCopyToServiceOrderMutation,
  usePatchServiceCaseJobCustomPriceApiMutation,
} = metadaWorkshopServiceOrderJobApi;
