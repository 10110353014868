import {isNotNilOrEmpty} from 'ramda-adjunct';

import {UserSettingsType} from '@omnetic-dms/api';
import {CarFilterType, filterLimits, VehicleCatalogueEnumOption} from '@omnetic-dms/teas';

import {FiltersType} from '../types/FiltersType';
import {SellerType} from '../types/sellerType';
import {SourcingFiltersRequestBodyTypes} from '../types/sourcingFiltersRequestBodyTypes';
import {convertObjectToArray} from './convertObjectToArray';
import {filterLastNodesOfFeaturesTree} from './filterLastNodesOfFeaturesTree';

export const createFilterBody = (
  {
    make,
    model,
    year,
    power,
    fuelType,
    drive: driveType,
    transmission,
    bodyType,
    margin,
    liquidity,
    daysOnDisplay,
    cubicCapacity,
    priceCZK,
    mileageRange,
    sellerCountry,
    sellerType,
    selectedColors,
    sellerServer,
    numberOfSeats,
    reclaimableVat,
    avgNumberAds,
    features,
    featureScore,
    carvagoAvailability,
  }: FiltersType,
  requestParams?: UserSettingsType,
  allFeatures: VehicleCatalogueEnumOption[] = []
): SourcingFiltersRequestBodyTypes => {
  const {country, currency} = requestParams || {};
  const convertedMake = convertObjectToArray(make);
  const convertedModel = convertObjectToArray(model);

  const createCarObjectForServer = (): CarFilterType[] | undefined => {
    const car: CarFilterType[] = [];

    convertedMake.forEach((makeKey) => {
      const modelKeysOfThisMake = convertedModel.filter((modelKey) => {
        const split = modelKey.split('-');
        return split.length && split[0] === makeKey;
      });

      if (!modelKeysOfThisMake.length) {
        car.push({make: makeKey});
        return;
      }

      modelKeysOfThisMake.forEach((modelKey) => car.push({model: modelKey}));
    });

    return car.length ? car : undefined;
  };

  // to get names of items, we need to find whole items and only the last nodes in tree
  const getFeatureItems = filterLastNodesOfFeaturesTree(features, allFeatures);

  const sellerObject: SellerType = {
    country: isNotNilOrEmpty(sellerCountry) ? sellerCountry : undefined,
    type: isNotNilOrEmpty(sellerType) ? sellerType : undefined,
    server: isNotNilOrEmpty(sellerServer) ? sellerServer : undefined,
    avgNumberAds: avgNumberAds && {
      gte: avgNumberAds[0],
      lte: avgNumberAds[1] === filterLimits.AVG_NUMBER_ADS_MAX ? undefined : avgNumberAds[1],
    },
  };

  const requestBody = {
    currency,
    buyer: {
      country,
    },
    carvagoAvailability,
    seller: Object.values(sellerObject).filter((x) => x).length ? sellerObject : undefined,
    car: createCarObjectForServer(),
    year: year && {gte: year[0], lte: year[1]},
    fuelType,
    driveType,
    transmission,
    bodyType,
    colors: selectedColors?.length ? selectedColors.map((x) => ({color: x.key})) : undefined,

    // sliders with max in infinity
    power: power && {
      gte: power[0],
      lte: power[1] === filterLimits.POWER_LIMIT_MAX ? undefined : power[1],
    },
    margin: margin && {
      gte: margin[0],
      lte: margin[1] === filterLimits.MARGIN_LIMIT_MAX ? undefined : margin[1],
    },
    cubicCapacity: cubicCapacity && {
      gte: cubicCapacity[0],
      lte:
        cubicCapacity[1] === filterLimits.CUBIC_CAPACITY_LIMITS[1] ? undefined : cubicCapacity[1],
    },
    //liquidity
    turnover: liquidity && {
      gte: liquidity[0],
      lte: liquidity[1] === filterLimits.LIQUIDITY_MAX ? undefined : liquidity[1],
    },
    mileageRange: mileageRange && {
      gte: mileageRange[0],
      lte: mileageRange[1] === filterLimits.MILEAGE_RANGE_MAX ? undefined : mileageRange[1],
    },
    daysOnStock: daysOnDisplay && {
      gte: daysOnDisplay[0],
      lte: daysOnDisplay[1] === filterLimits.DAYS_ON_DISPLAY ? undefined : daysOnDisplay[1],
    },
    price: priceCZK && {
      gte: priceCZK[0],
      lte: priceCZK[1] === filterLimits.PRICE_MAX ? undefined : priceCZK[1],
    },

    numberOfSeats: numberOfSeats && {
      gte: numberOfSeats[0],
      lte: numberOfSeats[1] === filterLimits.NUMBER_OF_SEATS_MAX ? undefined : numberOfSeats[1],
    },

    featureScore: featureScore && {
      gte: featureScore[0] / 100,
      lte: featureScore[1] / 100,
    },

    vatReclaimable: reclaimableVat ? reclaimableVat : undefined,
    features: getFeatureItems?.length ? getFeatureItems.map((x) => x.value) : undefined,
  };

  return requestBody as SourcingFiltersRequestBodyTypes;
};
