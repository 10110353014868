import {Chips, Separator, Choice, MultiChoice, useTranslationContext} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {ForwardedRef, forwardRef} from 'react';

import {always, intersection} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useChoiceFilter} from '../../hooks/useChoiceFilter';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {IFilter, IFilterParams} from '../../types/AgGridTypes';
import {QuickFilters} from '../../types/Api';

type Option = {value: string; label: string};

export type ChoiceFilterProps = {
  options: Array<Option>;
  isDisabled: boolean;
  isMulti: boolean;
} & IFilterParams &
  QuickFilters &
  TestIdProps;

function ChoiceFilterComponent(
  {
    filterChangedCallback,
    options,
    column,
    isDisabled,
    quickFilters,
    isMulti,
    ...props
  }: ChoiceFilterProps,
  ref: ForwardedRef<IFilter>
) {
  const t = useTranslationContext();
  const [values, setValues] = useChoiceFilter(filterChangedCallback, ref);
  const {onChipsChange} = useFilterOnChipsChange({
    filterChangedCallback,
    setFilterValue: setValues,
    defaultValue: undefined,
  });

  const isQuickFilterValue = intersection(isArray(values) ? values : [values], quickFiltersConst);

  const onChange = (values: string[] | string | number | null) => {
    const correctedValues = match<string[] | string | number | null, string[] | Nullish>(values)
      .with(Pattern.array(Pattern.string), (values) => values)
      .with(Pattern.number, (values) => [String(values)])
      .with(Pattern.string, (values) => [values])
      .otherwise(always([]));

    setValues(correctedValues);

    filterChangedCallback();
  };

  return (
    <VStack>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('choice-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      {isMulti ? (
        <MultiChoice
          options={options}
          value={isArray(values) ? values : [values]}
          onChange={onChange}
          placeholder={t('page.datagrid.filter.choicePlaceholder', {
            field: column.getColDef().headerName,
          })}
          isDisabled={isDisabled}
          data-testid="choice-filter"
        />
      ) : (
        <Choice
          options={options}
          value={isArray(values) ? values[0] : values}
          onChange={onChange}
          placeholder={t('page.datagrid.filter.choicePlaceholder', {
            field: column.getColDef().headerName,
          })}
          isDisabled={isDisabled}
          data-testid={suffixTestId('choice-filter', props)}
        />
      )}
    </VStack>
  );
}

export const ChoiceFilter = forwardRef<IFilter, ChoiceFilterProps>(ChoiceFilterComponent);
