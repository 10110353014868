import {Choice} from 'platform/components';
import {Space} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useRef, useState} from 'react';

import {always, defaultTo, head, isNil, not} from 'ramda';
import {isArray, isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetServiceCaseOrderJobsQuery,
  usePutServiceOrderItemsToOtherOrderJobMutation,
} from '@omnetic-dms/api';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {JobChange} from '../../../types/basket/JobChange';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {getOptionsFromServiceCaseOrderJobs} from '../../../utils/getOptionsFromServiceCaseOrderJobs';
import {handleApiError} from '../../../utils/handleApiError';
import {MaterialBasketItem} from '../types/MaterialBasketItem';

interface AfterSalesMaterialBasketItemJobChangeProps<T extends MaterialBasketItem>
  extends RequiredTestIdProps {
  item: MappedBasketItem<T>;
  jobChange?: JobChange;
}

export function AfterSalesMaterialBasketItemJobChange<T extends MaterialBasketItem>(
  props: AfterSalesMaterialBasketItemJobChangeProps<T>
) {
  const [selectedServiceCaseJobId, setSelectedServiceCaseJobId] = useState<string | Nullish>(
    props.item.serviceCaseJobId
  );
  const {data: serviceCaseOrderJobs, isLoading: isServiceCaseOrderJobsLoading} =
    useGetServiceCaseOrderJobsQuery(
      {
        serviceOrderId: props.jobChange?.serviceOrderId as string,
        serviceCaseId: props.jobChange?.serviceCaseId as string,
      },
      {
        skip:
          not(props.jobChange?.canPerformJobChange) ||
          isNilOrEmpty(props.jobChange?.serviceOrderId) ||
          isNilOrEmpty(props.jobChange?.serviceCaseId),
      }
    );

  const [putServiceOrderItemsToOtherOrderJob, {isLoading: isMoveLoading}] =
    usePutServiceOrderItemsToOtherOrderJobMutation();

  const prevSelectedServiceCaseJobId = useRef<string | Nullish>(null);

  const initiallySelectedServiceCaseJobId = props.item.serviceCaseJobId;

  const serviceCaseJobId = match([
    isNil(selectedServiceCaseJobId),
    isNil(initiallySelectedServiceCaseJobId),
  ])
    .with([true, false], always(initiallySelectedServiceCaseJobId))
    .with([false, false], always(selectedServiceCaseJobId))
    .otherwise(always(selectedServiceCaseJobId));

  const handleJobChange = (serviceCaseJobId: string | number | string[] | null) => {
    if (not(props.jobChange?.canPerformJobChange)) {
      return;
    }

    prevSelectedServiceCaseJobId.current = selectedServiceCaseJobId;

    const parsedValue = isArray(serviceCaseJobId) ? head(serviceCaseJobId) : serviceCaseJobId;

    const selectedJob = serviceCaseOrderJobs?.find(
      (serviceCaseOrderJob) => serviceCaseOrderJob?.id === parsedValue
    );

    if (isNil(selectedJob) || isNil(prevSelectedServiceCaseJobId.current)) {
      return;
    }

    putServiceOrderItemsToOtherOrderJob({
      serviceCaseId: props.jobChange?.serviceCaseId as string,
      serviceOrderId: props.jobChange?.serviceOrderId as string,
      body: {
        serviceMoveToJobId: selectedJob.id as string,
        serviceMoveToOrderId: props.jobChange?.serviceOrderId as string,
        items: [
          {
            serviceJobId: prevSelectedServiceCaseJobId.current,
            serviceItemId: props.item.serviceOrderJobItemId as string,
          },
        ],
      },
    })
      .unwrap()
      .then(() => setSelectedServiceCaseJobId(selectedJob.id))
      .catch((error) => {
        handleApiError(error);
        setSelectedServiceCaseJobId(prevSelectedServiceCaseJobId.current);
      });
  };

  return (
    <>
      <Space vertical={2} />
      <Choice
        isNotClearable
        isDisabled={not(props.item.itemEditingAllowed)}
        isLoading={isServiceCaseOrderJobsLoading || isMoveLoading}
        value={defaultTo('', serviceCaseJobId)}
        onChange={handleJobChange}
        options={getOptionsFromServiceCaseOrderJobs(serviceCaseOrderJobs)}
        data-testid={suffixTestId('serviceCaseJobId', props)}
      />
    </>
  );
}
