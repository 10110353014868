import {EmptyStatus, EmptyStatusProps, useTranslationContext} from 'platform/components';

interface DefaultEmptyStateRendererProps {
  filterIsActive: boolean;
  disableFilter: () => void;
  emptyStatus?: Omit<EmptyStatusProps, 'variant'>;
}

export function DefaultEmptyStateRenderer(props: DefaultEmptyStateRendererProps) {
  const t = useTranslationContext();
  return props.filterIsActive ? (
    <EmptyStatus
      variant="table"
      action={{
        title: t('page.datagrid.labels.clearAllFilters'),
        onClick: props.disableFilter,
      }}
      headline={t('page.datagrid.labels.emptyDueFilter')}
    />
  ) : (
    <EmptyStatus
      variant="table"
      headline={t('page.datagrid.labels.emptyData')}
      {...props.emptyStatus}
    />
  );
}
