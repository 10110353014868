import {
  CreateComplaintApiArg,
  CreateComplaintApiResponse,
  CreateComplaintDocumentApiArg,
  CreateComplaintDocumentApiResponse,
  DeleteComplaintApiArg,
  DeleteComplaintApiResponse,
  GetComplaintApiArg,
  GetComplaintApiResponse,
  GetSaleVehicleComplaintsApiArg,
  GetSaleVehicleComplaintsApiResponse,
  GetVehicleComplaintsApiArg,
  GetVehicleComplaintsApiResponse,
  PatchComplaintApiArg,
  PatchComplaintApiResponse,
  PatchComplaintInspectionApiArg,
  PatchComplaintInspectionApiResponse,
  PatchComplaintResultApiArg,
  PatchComplaintResultApiResponse,
} from '../types/vehicleComplaint';
import {omneticApi} from './baseApi/omneticApi';

export const vehicleComplaintApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getComplaint: build.query<GetComplaintApiResponse, GetComplaintApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/complaint/${queryArg.complaintId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'Complaint', id: queryArg.complaintId}],
    }),
    createComplaint: build.mutation<CreateComplaintApiResponse, CreateComplaintApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/complaint`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'Complaint', id: result?.id},
        {type: 'VehicleComplaints'},
        {type: 'SaleVehicleComplaints'},
      ],
    }),
    patchComplaint: build.mutation<PatchComplaintApiResponse, PatchComplaintApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/complaint/${queryArg.complaintId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Complaint', id: queryArg.complaintId}],
    }),
    patchComplaintResult: build.mutation<
      PatchComplaintResultApiResponse,
      PatchComplaintResultApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/complaint/${queryArg.complaintId}/result`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Complaint', id: queryArg.complaintId}],
    }),
    deleteComplaint: build.mutation<DeleteComplaintApiResponse, DeleteComplaintApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/complaint/${queryArg.complaintId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'Complaint', id: queryArg.complaintId},
        {type: 'VehicleComplaints'},
        {type: 'SaleVehicleComplaints'},
      ],
    }),
    getVehicleComplaints: build.query<GetVehicleComplaintsApiResponse, GetVehicleComplaintsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/complaint/by-vehicle/${queryArg.vehicleId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'VehicleComplaints', id: queryArg.vehicleId},
      ],
    }),
    getSaleVehicleComplaints: build.query<
      GetSaleVehicleComplaintsApiResponse,
      GetSaleVehicleComplaintsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/complaint/by-sale-vehicle/${queryArg.saleVehicleId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'SaleVehicleComplaints', id: queryArg.saleVehicleId},
      ],
    }),
    createComplaintDocument: build.mutation<
      CreateComplaintDocumentApiResponse,
      CreateComplaintDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/complaint/document`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'Complaint', id: queryArg.body.complaintId},
        {type: 'documentsCount'},
      ],
    }),
    patchComplaintInspection: build.mutation<
      PatchComplaintInspectionApiResponse,
      PatchComplaintInspectionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/complaint/${queryArg.complaintId}/inspection`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Complaint', id: queryArg.complaintId}],
    }),
  }),
});

export const {
  useCreateComplaintMutation,
  useGetComplaintQuery,
  usePatchComplaintMutation,
  useDeleteComplaintMutation,
  useGetSaleVehicleComplaintsQuery,
  useGetVehicleComplaintsQuery,
  usePatchComplaintResultMutation,
  useCreateComplaintDocumentMutation,
  usePatchComplaintInspectionMutation,
} = vehicleComplaintApi;
