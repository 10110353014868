import {
  Action,
  Card,
  EmptyStatus,
  closeCurrentDialog,
  openDialog,
  showNotification,
} from 'platform/components';

import {head} from 'ramda';

import {
  EntityResourceIds,
  UpsellResponseBody,
  useGetBusinessCaseQuery,
  useGetParticipationQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {useBusinessCaseActions, usePermissions} from '@omnetic-dms/shared';

import {buildArray, useRequiredParams} from 'shared';

import {AddProductFromCatalog} from '../../../AddProductFromCatalog';
import {BusinessCaseUpsellForm} from '../../../BusinessCaseUpsellForm/BusinessCaseUpsellForm';
import {UpsellSimpleTable} from './UpsellSimpleTable';

export function Upsell() {
  const {id: businessCaseId} = useRequiredParams();

  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const [
    hasBusinessCaseAddUpsellItemFromCataloguePermission,
    hasBusinessCaseRemoveUpsellItemPermission,
    hasBusinessCaseAddUpsellPermission,
    hasBusinessCaseEditUpsellPermission,
  ] = usePermissions({
    permissionKeys: [
      'businessCaseAddUpsellItemFromCatalogue',
      'businessCaseRemoveUpsellItem',
      'businessCaseAddUpsell',
      'businessCaseEditUpsell',
    ],
    scopes: {
      businessCaseAddUpsellItemFromCatalogue: businessCaseParticipation,
      businessCaseRemoveUpsellItem: businessCaseParticipation,
      businessCaseAddUpsell: businessCaseParticipation,
      businessCaseEditUpsell: businessCaseParticipation,
    },
  });

  const canUseUpsellAction =
    hasBusinessCaseAddUpsellItemFromCataloguePermission ||
    hasBusinessCaseRemoveUpsellItemPermission;

  const canEditUpsellPrice =
    hasBusinessCaseAddUpsellPermission || hasBusinessCaseEditUpsellPermission;

  const {isActionEnabled} = useBusinessCaseActions(businessCaseId);

  const offer = head(businessCase?.offers ?? []);
  const saleVehicle = head(offer?.saleVehicles ?? []);

  const handleAddFromCatalog = () => {
    if (!businessCase?.id || !offer?.id || !saleVehicle?.id) {
      return showNotification.error();
    }
    openDialog(
      <AddProductFromCatalog
        offerId={offer?.id}
        businessCaseId={businessCase?.id}
        offerSaleVehicleId={saleVehicle?.id}
        data-testid={testIds.businessCase.selling('upsell-dialog')}
        onCancel={closeCurrentDialog}
        onSubmit={closeCurrentDialog}
      />,
      {
        title: i18n.t('entity.businessCase.actions.addItemFromCatalog'),
        size: 'large',
      }
    );
  };

  const openUpsellDialog = (upsell?: UpsellResponseBody) => {
    openDialog(
      <BusinessCaseUpsellForm
        initialValues={upsell}
        businessCaseId={businessCaseId}
        saleVehicleId={saleVehicle?.id}
        data-testid={testIds.businessCase.selling()}
      />,
      {
        title: upsell
          ? i18n.t('page.businessCase.labels.editUpsellItem')
          : i18n.t('page.businessCase.labels.newUpsellItem'),
        'data-testid': testIds.businessCase.selling('upsell'),
      }
    );
  };

  const upsellActions = buildArray<Action>()
    .when(isActionEnabled('CREATE_UPSELL_ITEM') && canUseUpsellAction, {
      type: 'button',
      variant: 'link',
      leftIcon: 'content/add_circle',
      title: i18n.t('general.actions.addItem'),
      'data-testid': testIds.businessCase.selling('upsell-add'),
      onClick: () => openUpsellDialog(),
    })
    .when(isActionEnabled('ADD_UPSELL_ITEM_FROM_CATALOGUE') && canUseUpsellAction, {
      type: 'button',
      variant: 'link',
      leftIcon: 'content/add_circle',
      title: i18n.t('entity.businessCase.actions.addItemFromCatalog'),
      'data-testid': testIds.businessCase.selling('upsell-add-from-catalog'),
      onClick: handleAddFromCatalog,
    });

  return (
    <>
      <Card
        title={i18n.t('entity.businessCase.labels.upsell')}
        actions={upsellActions}
        variant="inlineWhite"
        data-testid={testIds.businessCase.selling('upsell')}
      >
        {saleVehicle?.upsell?.length ? (
          <UpsellSimpleTable
            businessCaseId={businessCaseId}
            upsells={saleVehicle?.upsell}
            totalUpsell={offer?.totalUpsell}
            onRowClick={(upsell: UpsellResponseBody) =>
              canEditUpsellPrice && openUpsellDialog(upsell)
            }
          />
        ) : (
          <EmptyStatus
            variant="table"
            data-testid={testIds.businessCase.selling('noUpsell')}
            headline={i18n.t('entity.businessCase.notifications.noUpsellProducts')}
          />
        )}
      </Card>
    </>
  );
}
