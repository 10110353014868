import {Box, Heading, Icon} from 'platform/foundation';

import {FC} from 'react';

import {FlexContainer, Ul} from './styles';
import {FeatureListProps} from './types';

export const FeatureList: FC<FeatureListProps> = ({
  className,
  features,
  highlightOnlyPremium = true,
}) => (
  <Ul className={className}>
    {features.map((feature) => {
      const {isPremium, isHighlight, isAvailable} = feature;

      return (
        <li
          key={feature.key}
          title={feature.translation}
          style={{opacity: !isAvailable ? 0.65 : 1}}
        >
          <FlexContainer>
            {isPremium && isAvailable ? (
              <Box paddingRight={2}>
                <Icon value="toggle/star" color="palettes.blue.60.100" size={4} />
              </Box>
            ) : null}

            <Heading
              isSingleLine
              size={6}
              alternative
              color={
                isAvailable && (highlightOnlyPremium ? isPremium && isHighlight : isHighlight)
                  ? 'link'
                  : !isAvailable
                    ? 'tertiary'
                    : 'primary'
              }
            >
              {feature.translation}
            </Heading>
          </FlexContainer>
        </li>
      );
    })}
  </Ul>
);
