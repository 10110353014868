import {clamp} from 'ramda';
import {isPositive} from 'ramda-adjunct';

export const getRankValue = (percentage: number | null) => {
  if (!isPositive(percentage)) {
    return 0;
  }

  const percentageValue = percentage + 1;

  const valueIndex = Math.ceil(percentageValue / 20);

  return clamp(0, 5, valueIndex);
};
