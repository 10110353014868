import {useCallback, useEffect, useState} from 'react';

interface UseVehicleAlbumImageSelectionProps {
  onSelectionChange?: (selectedImages: string[]) => void;
}

export function useVehicleAlbumImageSelection({
  onSelectionChange,
}: UseVehicleAlbumImageSelectionProps = {}) {
  const [selectedImagesIds, setSelectedImagesIds] = useState<Set<string>>(new Set());

  const getSelectedImages = () => Array.from(selectedImagesIds);

  const isImageSelected = (id: string) => selectedImagesIds.has(id);

  const selectImage = (id: string) => {
    setSelectedImagesIds((prev) => new Set(prev).add(id));
  };

  const selectImagesInBulk = (ids: string[]) => {
    setSelectedImagesIds(new Set(ids));
  };

  const deselectImage = (id: string) => {
    if (selectedImagesIds.has(id)) {
      setSelectedImagesIds((prevSet) => {
        const updatedSet = new Set(prevSet);
        updatedSet.delete(id);
        return updatedSet;
      });
    }
  };

  const clearImageSelection = () => {
    setSelectedImagesIds(new Set());
  };

  const toggleImageSelection = (id: string) => {
    setSelectedImagesIds((prevSet) => {
      const updatedSet = new Set(prevSet);
      if (updatedSet.has(id)) {
        updatedSet.delete(id);
      } else {
        updatedSet.add(id);
      }
      return updatedSet;
    });
  };

  /*
   *
   * onSelectionChange changes on every render, but we would need to useCallback
   * on 3 levels of the component tree to avoid unnecessary re-renders.
   * This should be fine, in case of some weird edge cases happening we can refactor.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSelectionChange = useCallback((ids: string[]) => onSelectionChange?.(ids), []);

  useEffect(() => {
    handleSelectionChange(Array.from(selectedImagesIds));
  }, [selectedImagesIds, handleSelectionChange]);

  return {
    getSelectedImages,
    selectImage,
    deselectImage,
    isImageSelected,
    selectImagesInBulk,
    toggleImageSelection,
    clearImageSelection,
  };
}
