import {Tooltip} from 'platform/components';

import {FC} from 'react';

import {VehicleCatalogueColorProperties} from '../../types/VehicleCatalogueColorProperties';
import {VehicleCatalogueEnumOption} from '../../types/VehicleCatalogueEnumOption';
import {noop} from '../../utils/someTeasUtils';
import {ColorCircleStyle, ColorBorder} from './styles';

type ColorCircleProps = {
  className?: string;
  color: VehicleCatalogueEnumOption<VehicleCatalogueColorProperties>;
  needBorder?: boolean;
  large?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
};

export const ColorCircle: FC<ColorCircleProps> = ({
  color,
  onClick = noop,
  isSelected,
  needBorder,
  ...rest
}) => {
  const colorCode = color.properties?.find((property) => property.name === 'color_code');

  return (
    <Tooltip label={color?.label} placement="top">
      <ColorBorder {...rest} isSelected={isSelected}>
        <ColorCircleStyle
          color={colorCode?.value || 'transparent'}
          onClick={onClick}
          needBorder={needBorder}
        />
      </ColorBorder>
    </Tooltip>
  );
};
