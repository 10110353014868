import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, Text, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useState} from 'react';

import {find} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  FileResponseBody,
  TemplateListItemResponseBody,
  useCancelIssueVehicleWarehouseMovementMutation,
  useGetSaleVehicleWarehouseInformationQuery,
  useGetTemplatesQuery,
  useLazyGetVehicleWarehouseMovementQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {getApiDateString, suffixTestId, TestIdProps, yupString} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {DocumentTemplateBox} from '../../DocumentTemplateBox/DocumentTemplateBox';

interface VehicleWarehouseCancelStockOutProps extends TestIdProps {
  vehicleId: string;
  saleVehicleId: string;
  onDocumentCreation: (document: FileResponseBody) => void;
  onMovementComplete?: () => void;
}

interface VehicleWarehouseCancelStockOutFormFields {
  note: string | null;
  cancellationDate: string;
}

const CANCEL_STOCK_OUT_DOCUMENT_TEMPLATES_CODE = 'cancel-issue-vehicle-warehouse-movement';

export function VehicleWarehouseCancelStockOut(props: VehicleWarehouseCancelStockOutProps) {
  const [isDocumentSelected, setIsDocumentSelected] = useState(true);
  const [documentTemplate, setDocumentTemplate] = useState<TemplateListItemResponseBody>();

  const {
    data: stockOutTemplates,
    isLoading: isTemplatesLoading,
    isError: isTemplatesError,
  } = useGetTemplatesQuery({
    documentKindCode: CANCEL_STOCK_OUT_DOCUMENT_TEMPLATES_CODE,
  });

  const {data: warehouseInfo} = useGetSaleVehicleWarehouseInformationQuery({
    vehicleId: props.vehicleId,
    saleVehicleId: props.saleVehicleId,
  });

  const [cancelStockOut] = useCancelIssueVehicleWarehouseMovementMutation();
  const [getMovement] = useLazyGetVehicleWarehouseMovementQuery();

  if (!documentTemplate && isNotNilOrEmpty(stockOutTemplates)) {
    setDocumentTemplate(find((template) => template.primary, stockOutTemplates ?? []));
  }

  const onSubmit: FormSubmitHandler<VehicleWarehouseCancelStockOutFormFields> = (values) =>
    cancelStockOut({
      vehicleId: props.vehicleId,
      saleVehicleId: props.saleVehicleId,
      body: {
        issueCancellationTemplateId: isDocumentSelected ? documentTemplate?.id : undefined,
        note: values.note,
        cancellationDate: values.cancellationDate,
      },
    })
      .unwrap()
      .then((response) => {
        if (!isDocumentSelected) {
          showNotification.success(i18n.t('entity.vehicle.labels.vehicleStockOutCancelled'));
          closeCurrentDialog();

          return;
        }

        return response;
      })
      .then((response) => {
        if (!response) {
          return;
        }

        return getMovement({
          vehicleWarehouseMovementId: response?.id,
        })
          .unwrap()
          .then((response) => {
            response.document && props.onDocumentCreation(response.document);
          });
      })
      .then(props.onMovementComplete)
      .catch(handleApiError);

  return (
    <VStack spacing={4}>
      <Text size="small" color="secondary">
        {i18n.t('entity.vehicleWarehouse.modal.cancelStockOutDescription')}
      </Text>
      <DataStatus isLoading={isTemplatesLoading} isError={isTemplatesError}>
        <Form<VehicleWarehouseCancelStockOutFormFields>
          onSubmit={onSubmit}
          schema={schema}
          defaultValues={{
            cancellationDate: warehouseInfo?.issuedAt ?? getApiDateString(new Date()),
          }}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <Box maxWidth="50%">
                <FormField
                  label={i18n.t('entity.vehicleWarehouse.labels.dateOfStockIn')}
                  name="cancellationDate"
                  type="apiDate"
                  control={control}
                  isRequired
                  data-testid={suffixTestId('cancelStockOut-cancellationDate', props)}
                />
              </Box>
              <DocumentTemplateBox
                title={i18n.t('entity.document.labels.vehicleWarehouseCancelStockOutDocument')}
                templates={stockOutTemplates}
                selectedTemplate={documentTemplate}
                isSelected={isDocumentSelected}
                onSelect={setIsDocumentSelected}
                onTemplateChange={setDocumentTemplate}
                onNoteChange={(value) => formApi.setValue('note', value)}
                noteLabel={i18n.t(
                  'entity.document.labels.vehicleWarehouseCancelStockOutDocumentNote'
                )}
                isWithNote
                isDisabled
              />
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.cancel')}
                  variant="secondary"
                  onClick={closeCurrentDialog}
                  data-testid={suffixTestId('cancelStockOut-cancelBtn', props)}
                />
                <FormButton
                  title={i18n.t('general.actions.confirm')}
                  type="submit"
                  control={control}
                  data-testid={suffixTestId('cancelStockOut-submitBtn', props)}
                />
              </ButtonGroup>
            </VStack>
          )}
        </Form>
      </DataStatus>
    </VStack>
  );
}

const schema = object({
  note: yupString,
});
