import {Attributes, Separator} from 'platform/components';
import {Heading, VStack, Text} from 'platform/foundation';
import {useFormatCurrency, useFormatPercentage} from 'platform/locale';

import {isNotNil, isPositive} from 'ramda-adjunct';

import {GetOrderProfitabilityApiResponse} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';

import {suffixTestId, RequiredTestIdProps} from 'shared';

interface ProfitabilityFinancesProps extends RequiredTestIdProps {
  profitabilityData: GetOrderProfitabilityApiResponse;
}

export function ProfitabilityFinances(props: ProfitabilityFinancesProps) {
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();

  const vatData = props.profitabilityData?.totalVat?.map((vat) => {
    if (isNotNil(vat?.rate)) {
      // If vat.rate is not null or undefined, return an object with a value and no content
      return {
        label: `${vat.rate}% ${i18n.t('general.labels.vat')}`,
        value:
          (vat.amount && vat.currency && formatCurrency(vat?.amount, vat.currency, 2)) ||
          EMPTY_PLACEHOLDER,
      };
    } else {
      // If vat.rate is null or undefined, return an object with content and no value
      return {
        label: i18n.t('entity.order.labels.totalVat'),
        content: (
          <Text alternative size="xSmall">
            {(vat?.amount && vat.currency && formatCurrency(vat.amount, vat.currency, 2)) ||
              EMPTY_PLACEHOLDER}
          </Text>
        ),
      };
    }
  });

  return (
    <>
      <VStack spacing={1}>
        <Text size="xSmall" color="secondary" data-testid={suffixTestId('toBePaid', props)}>
          {i18n.t('entity.order.labels.toBePaid')}
        </Text>
        <Heading size={3}>
          {props.profitabilityData?.restForPayment?.withVat?.amount &&
          props.profitabilityData?.restForPayment?.withVat?.currency
            ? formatCurrency(
                props.profitabilityData?.restForPayment?.withVat?.amount,
                props.profitabilityData?.restForPayment?.withVat?.currency,
                2
              )
            : EMPTY_PLACEHOLDER}
        </Heading>
      </VStack>
      <Separator spacing={0} />
      <Heading size={4}>{i18n.t('entity.order.labels.profitability')}</Heading>
      <Attributes
        rows={[
          {
            label: i18n.t('general.labels.purchasePrice'),
            value:
              props.profitabilityData?.purchasePrice?.amount &&
              props.profitabilityData?.purchasePrice?.currency
                ? formatCurrency(
                    props.profitabilityData?.purchasePrice?.amount,
                    props.profitabilityData?.purchasePrice?.currency,
                    2
                  )
                : null,
          },
          {
            label: i18n.t('entity.order.labels.sellingPriceBeforeDiscount'),
            value:
              props.profitabilityData?.basePrice?.withoutVat?.amount &&
              props.profitabilityData?.basePrice?.withoutVat?.currency
                ? formatCurrency(
                    props.profitabilityData?.basePrice?.withoutVat?.amount,
                    props.profitabilityData?.basePrice?.withoutVat?.currency,
                    2
                  )
                : null,
          },
          {
            label: i18n.t('general.labels.discount'),
            value:
              props.profitabilityData?.discount?.withoutVat?.amount &&
              props.profitabilityData?.discount?.withoutVat?.currency
                ? formatCurrency(
                    props.profitabilityData?.discount?.withoutVat?.amount,
                    props.profitabilityData?.discount?.withoutVat?.currency,
                    2
                  )
                : null,
          },
          {
            label: i18n.t('entity.order.labels.totalSellingPriceWithoutVat'),
            content:
              props.profitabilityData?.totalPrice?.withoutVat?.amount &&
              props.profitabilityData?.totalPrice?.withoutVat?.currency ? (
                <Text
                  alternative
                  size="xSmall"
                  data-testid={suffixTestId('totalSellingPriceWithoutVat', props)}
                >
                  {formatCurrency(
                    props.profitabilityData?.totalPrice?.withoutVat?.amount,
                    props.profitabilityData?.totalPrice?.withoutVat?.currency,
                    2
                  )}
                </Text>
              ) : null,
          },
          {
            label: i18n.t('entity.order.labels.profitAfterDiscount'),
            content:
              props.profitabilityData?.profitAfterDiscount?.amount &&
              props.profitabilityData?.profitAfterDiscount?.currency ? (
                <Text
                  alternative
                  size="xSmall"
                  color={
                    isPositive(props.profitabilityData.profitAfterDiscount.amount)
                      ? 'success'
                      : 'danger'
                  }
                  data-testid={suffixTestId('profitAfterDiscount', props)}
                >
                  {formatCurrency(
                    props.profitabilityData?.profitAfterDiscount?.amount,
                    props.profitabilityData?.profitAfterDiscount?.currency,
                    2
                  )}
                </Text>
              ) : null,
          },
          {
            label: i18n.t('entity.order.labels.percentageProfitAfterDiscount'),
            content: props.profitabilityData?.percentageProfitAfterDiscount ? (
              <Text
                alternative
                size="xSmall"
                color={
                  isPositive(props.profitabilityData?.percentageProfitAfterDiscount)
                    ? 'success'
                    : 'danger'
                }
                data-testid={suffixTestId('percentageProfitAfterDiscount', props)}
              >
                {formatPercentage(props.profitabilityData?.percentageProfitAfterDiscount)}
              </Text>
            ) : null,
          },
        ]}
        data-testid={suffixTestId('profitabilityData', props)}
      />
      <Heading size={4}>{i18n.t('general.labels.vat')}</Heading>
      <Attributes rows={vatData} data-testid={suffixTestId('vatData', props)} />
      <Heading size={4}>{i18n.t('entity.order.labels.billing')}</Heading>
      <Attributes
        rows={[
          {
            label: i18n.t('entity.order.labels.totalSellingPriceWithVat'),
            value:
              props.profitabilityData?.totalPrice?.withVat?.amount &&
              props.profitabilityData?.totalPrice?.withVat?.currency
                ? formatCurrency(
                    props.profitabilityData?.totalPrice?.withVat?.amount,
                    props.profitabilityData?.totalPrice?.withVat?.currency,
                    2
                  )
                : null,
          },
          {
            label: i18n.t('entity.order.labels.deposit'),
            content:
              props.profitabilityData?.deposit?.withVat?.amount &&
              props.profitabilityData?.deposit?.withVat?.currency ? (
                <Text
                  alternative
                  size="xSmall"
                  color={
                    isPositive(props.profitabilityData?.deposit?.withVat?.amount)
                      ? 'success'
                      : 'danger'
                  }
                  data-testid={suffixTestId('deposit', props)}
                >
                  {formatCurrency(
                    props.profitabilityData?.deposit?.withVat?.amount,
                    props.profitabilityData?.deposit?.withVat?.currency,
                    2
                  )}
                </Text>
              ) : null,
          },
          {
            label: i18n.t('entity.order.labels.toBePaid'),
            content:
              props.profitabilityData?.restForPayment?.withVat?.amount &&
              props.profitabilityData?.restForPayment?.withVat?.currency ? (
                <Text alternative size="xSmall">
                  {formatCurrency(
                    props.profitabilityData?.restForPayment?.withVat?.amount,
                    props.profitabilityData?.restForPayment?.withVat?.currency,
                    2
                  )}
                </Text>
              ) : null,
          },
        ]}
        data-testid={suffixTestId('billingData', props)}
      />
    </>
  );
}
