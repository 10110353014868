import {Preview, PreviewAction} from 'platform/components';
import {
  Box,
  getValueByDevice,
  Hide,
  HStack,
  Integer,
  makeStorageUrl,
  Show,
  StorageImage,
  ThemeIconKey,
  useDevice,
} from 'platform/foundation';

import {isNotNilOrEmpty, round} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {IconWithActions} from './IconWithActions';

const RATIO = 4 / 3;

export interface EntityHeaderImageProps extends TestIdProps {
  image?: string;
  imageActions?: PreviewAction[];
  icon?: ThemeIconKey;
  iconActions?: PreviewAction[];
}

export function EntityHeaderImage(props: EntityHeaderImageProps) {
  const device = useDevice();
  const heightInSteps = getValueByDevice(device, 38, 38, 36, 30) as Integer;
  const widthInSteps = (heightInSteps * RATIO) as Integer;
  const iconSize = round(heightInSteps / 2) as Integer;

  if (!props.icon && !props.image) {
    return null;
  }

  return (
    <Box width={widthInSteps} height={heightInSteps}>
      <Box overflow="hidden" borderRadius="medium" backgroundColor="palettes.neutral.30.100">
        <Show when={props.image}>
          <Show when={isNotNilOrEmpty(props.imageActions)}>
            <>
              <Preview
                url={props.image ?? ''}
                makeUrl={makeStorageUrl}
                width={widthInSteps}
                height={heightInSteps}
                fit="cover"
                actions={props.imageActions?.map((action) => ({
                  icon: action.icon,
                  onClick: action.onClick,
                }))}
                data-testid={suffixTestId('entityHeaderImage-image', props)}
              />
            </>
          </Show>
          <Hide when={isNotNilOrEmpty(props.imageActions)}>
            <StorageImage
              url={props.image}
              width={widthInSteps}
              height={heightInSteps}
              fit="cover"
              data-testid={suffixTestId('entityHeaderImage-image', props)}
            />
          </Hide>
        </Show>
        <Show when={props.icon && !props.image}>
          <HStack width="100%" height={heightInSteps} align="center" justify="center">
            <IconWithActions
              width={widthInSteps}
              height={heightInSteps}
              icon={props.icon}
              iconSize={iconSize}
              actions={props.iconActions}
              data-testid={suffixTestId('entityHeaderImage-icon', props)}
            />
          </HStack>
        </Show>
      </Box>
    </Box>
  );
}
