import {Separator, Actions, Action} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

export interface SettingsFooterProps extends TestIdProps {
  actions: Action[] | Nullish;
}

export function SettingsFooter(props: SettingsFooterProps) {
  return (
    <Box position="absolute" bottom={0} left={0} right={0}>
      <VStack>
        <Separator spacing={0} />
        <Box padding={6}>
          <Actions
            actions={props.actions}
            align="right"
            data-testid={suffixTestId('settingsFooter-wrapper', props)}
          />
        </Box>
      </VStack>
    </Box>
  );
}
