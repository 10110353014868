import {CustomRequestOption, Upload, UploadState, isFile, FormControl} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import {useFieldArray} from 'react-hook-form';

import {omit} from 'ramda';

import {useUploadFileMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {DOCX_MIME_TYPE} from 'shared';

import {ConsentTypeFormTemplate, ConsentTypeFormType} from './ConsentTypeFormTemplate';

interface ConsentTypeFormTemplatesProps {
  control: FormControl<ConsentTypeFormType>;
}

export function ConsentTypeFormTemplates(props: ConsentTypeFormTemplatesProps) {
  const [uploadFile, {isLoading}] = useUploadFileMutation();
  const {fields, remove, append, update} = useFieldArray({
    control: props.control,
    name: 'templates',
  });

  const handlePrimaryChange = (primaryIndex: number) => {
    fields.forEach((field, index) => {
      update(index, {...omit(['id'], field), isPrimary: primaryIndex === index});
    });
  };

  const handleUpload = async ({file}: CustomRequestOption) => {
    if (!isFile(file)) {
      return;
    }
    await uploadFile({file})
      .unwrap()
      .then((data) => {
        append({
          title: data.file.name,
          fileId: data.fileId,
          fileUri: data.fileUri,
          fileName: data.file.name,
          isPrimary: false,
        });
      })
      .catch(handleApiError);
  };

  return (
    <VStack>
      {fields.map((field, index) => (
        <ConsentTypeFormTemplate
          key={field.id}
          index={index}
          control={props.control}
          onRemove={remove}
          template={omit(['id'], field)}
          onPrimaryChange={handlePrimaryChange}
        />
      ))}

      <VStack spacing={3}>
        <Text color="secondary" size="xSmall">
          {i18n.t('entity.gdpr.labels.consentType.uploadDocx')}
        </Text>
        <Upload
          isMultiple
          type="button"
          accept={DOCX_MIME_TYPE}
          uploadState={isLoading ? UploadState.Uploading : UploadState.Idle}
          errorIcon="navigation/cancel"
          uploadingIcon="action/hourglass_empty"
          uploadIcon="file/upload"
          data-testid="add-gdpr-consent-type-upload-button"
          customRequest={handleUpload}
        />
      </VStack>
    </VStack>
  );
}
