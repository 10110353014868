import {match} from 'ts-pattern';

import {always} from 'ramda';

import {ImageFileFormat} from './fileFormat';

export function getImageFileFormatFromContentType(contentType: string | null) {
  return match<string | null, ImageFileFormat | undefined>(contentType)
    .with('image/jpeg', always('jpeg'))
    .with('image/png', always('png'))
    .with('image/gif', always('gif'))
    .with('image/webp', always('webp'))
    .with('image/svg+xml', always('svg'))
    .otherwise(always(undefined));
}
