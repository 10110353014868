import flagsmith from 'flagsmith';
import {IFlagsmithFeature, IState} from 'flagsmith/types';
import {Button, Card, Search, showNotification, Switch} from 'platform/components';
import {HStack, Scroll, Space, VStack} from 'platform/foundation';

import {useState} from 'react';

import {pipe} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {browserStorageKey} from '@omnetic-dms/config';

import {getFixedTranslation} from '../utils/getFixedTranslation';

const getFlagsFromState =
  (searchString: string | null) =>
  (state: IState<string, string>): [string, IFlagsmithFeature][] =>
    Object.entries(state?.flags ?? {}).filter(
      ([key]) => !searchString || key.toLowerCase().includes(searchString.toLowerCase())
    ) ?? [];

const sortFlagsAlphabetically = (flags: [string, IFlagsmithFeature][]) =>
  flags.sort(([a], [b]) => a.localeCompare(b));

export function FeatureFlagsMock() {
  const isMocking = localStorage.getItem(browserStorageKey.MOCK_FLAGS_KEY) !== null;
  const [, setCount] = useState(0);
  const rerenderComponent = () => setCount((prev) => prev + 1);
  const [searchString, setSearchString] = useState<string | null>(null);

  const flagsmithState = flagsmith.getState();

  const flags = pipe(getFlagsFromState(searchString), sortFlagsAlphabetically)(flagsmithState);

  const toggleFlag = (key: string, enabled: boolean) => {
    const prevFlagState = flagsmithState.flags?.[key];

    if (!prevFlagState) {
      // TS handle - if somehow we are trying to update flag that doesn't exist
      showNotification.error("Flag doesn't exist");
      return;
    }

    const newFlagsmithState = {
      ...flagsmithState,
      flags: {...flagsmithState.flags, [key]: {...prevFlagState, enabled}},
    };

    flagsmith.setState(newFlagsmithState);

    try {
      localStorage.setItem('BULLET_TRAIN_DB', JSON.stringify(newFlagsmithState));
    } catch (error) {
      // should never happen. If it does, something is very wrong
      showNotification.error('Updating cache failed, contact Martin Slaby for debug');
    }
    rerenderComponent();
  };

  const handleToggleIsMocking = (val: boolean) => {
    if (isTrue(val)) {
      localStorage.setItem(browserStorageKey.MOCK_FLAGS_KEY, 'true');
      rerenderComponent();
      return;
    }

    localStorage.removeItem(browserStorageKey.MOCK_FLAGS_KEY);
    // we need to clear fagsmith native localStorage key that stores adjusted cached flags
    localStorage.removeItem('BULLET_TRAIN_DB');
    window.location.reload();
  };

  return (
    <Card
      control={{
        type: 'switch',
        value: isMocking,
        onChange: handleToggleIsMocking,
      }}
      title={getFixedTranslation('page.settings.labels.featureFlagsMock')}
      isExpanded={isMocking}
      variant="inlineWhite"
      flags={[
        isMocking
          ? {label: getFixedTranslation('general.labels.on'), colorScheme: 'orange', isSubtle: true}
          : {
              label: getFixedTranslation('general.labels.off'),
              colorScheme: 'neutral',
              isSubtle: true,
            },
      ]}
    >
      <HStack spacing={2}>
        <Search value={searchString} onChange={setSearchString} />
        <Button
          title={getFixedTranslation('page.settings.labels.refreshPage')}
          onClick={() => window.location.reload()}
          variant="secondary"
        />
      </HStack>
      <Space vertical={2} />
      <Scroll auto maxHeight={100}>
        <VStack spacing={2}>
          {flags.map(([key, value]) => (
            <HStack key={key}>
              <Switch value={value.enabled} label={key} onChange={(val) => toggleFlag(key, val)} />
            </HStack>
          ))}
        </VStack>
      </Scroll>
    </Card>
  );
}
