import {AuditParamValue} from '../../../types/AuditParamValue';
import {TRANSLATED_LANGUAGES} from '../../../types/CommonAppTypes';
import {getTranslation} from './getTranslation';

export const getDamageTypeLabel = (
  type: string,
  damageValues: AuditParamValue[],
  locale: TRANSLATED_LANGUAGES
) => {
  const paramValue = damageValues.find((value) => value.value === type);
  if (!paramValue?.label) {
    return type;
  }

  return getTranslation(locale, paramValue.label);
};
