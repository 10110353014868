import {showNotification} from 'platform/components';
import {Box} from 'platform/foundation';

import {FC, useMemo, useCallback} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {selectBranchList} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  useRouter,
  selectPaymentTypes,
  selectRoundingTypes,
  createDocumentRoundingDefinition,
  updateDocumentRoundingDefinition,
  selectDocumentRoundingDefinitionDetail,
  selectDocumentRoundingDefinitionDetailId,
  selectRoundingStepList,
  CreateRoundingDefinitionRequestBody,
  useThunkDispatch,
} from '@omnetic-dms/teas';

import {DocumentRoundingDefinition} from './DocumentRoundingDefinition';
import {DocumentRoundingDefinitionFormType} from './types';
import {roundingDefinitionCtx} from './utils';

interface DocumentRoundingDefinitionsAddProps {
  edit?: boolean;
}

export const DocumentRoundingDefinitionsAdd: FC<DocumentRoundingDefinitionsAddProps> = ({edit}) => {
  const dispatch = useThunkDispatch();
  const router = useRouter();

  const {data: {branchListItems: branchList} = {}} = useSelector(selectBranchList);
  const roundingStepList = useSelector(selectRoundingStepList);
  const roundingTypes = useSelector(selectRoundingTypes);
  const paymentTypes = useSelector(selectPaymentTypes);
  const detail = useSelector(selectDocumentRoundingDefinitionDetail);
  const id = useSelector(selectDocumentRoundingDefinitionDetailId);

  const Content = useMemo(
    () => (
      <Box maxWidth={206}>
        <DocumentRoundingDefinition defaultValues={edit ? detail : undefined} />
      </Box>
    ),
    [detail, edit]
  );

  const checkBranchId = useCallback(
    (
      values: DocumentRoundingDefinitionFormType,
      setError: UseFormReturn<DocumentRoundingDefinitionFormType>['setError']
    ): values is CreateRoundingDefinitionRequestBody => {
      if (values.branchId == null) {
        setError('branchId', {
          type: 'required',
          message: i18n.t`general.validations.fieldIsRequired`,
        });
        return false;
      }

      return true;
    },
    []
  );

  const handleSubmit = async (
    values: DocumentRoundingDefinitionFormType,
    setError: UseFormReturn<DocumentRoundingDefinitionFormType>['setError']
  ): Promise<void> => {
    // --- Edit
    if (edit) {
      const {validities} = values;

      if (!id) {
        // Should not happen, but just in case
        showNotification.error('id == null');
        return;
      }

      const res = await dispatch(updateDocumentRoundingDefinition({id, data: {validities}}));

      if (res.meta.requestStatus === 'fulfilled') {
        showNotification.success(
          i18n.t('entity.accounting.notifications.roundingDefinitionUpdated')
        );
        router.push(settingsRoutes.documentRoundingDefinitions);
      }
      return;
    }

    // --- Create
    if (!checkBranchId(values, setError)) {
      return;
    }

    const res = await dispatch(createDocumentRoundingDefinition(values));

    if (res.meta.requestStatus === 'fulfilled') {
      showNotification.success(i18n.t('entity.accounting.notifications.roundingDefinitionCreated'));

      router.push(settingsRoutes.documentRoundingDefinitions);
    }
  };

  return (
    <roundingDefinitionCtx.Provider
      edit={Boolean(edit)}
      branches={branchList?.map((item) => ({key: item.id, value: item.marketingName ?? ''})) || []}
      paymentTypes={paymentTypes}
      roundingTypes={roundingTypes}
      roundings={roundingStepList}
      onSubmit={handleSubmit}
      onCancel={() => router.push(settingsRoutes.documentRoundingDefinitions)}
    >
      {Content}
    </roundingDefinitionCtx.Provider>
  );
};
