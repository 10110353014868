import {Nullish} from 'utility-types';

import {FeatureKey} from '@omnetic-dms/api';

export function hasChildWithSelectedFeature(
  feature: FeatureKey,
  selectedFeature: string | Nullish
): boolean {
  return feature.children.some((item) => {
    if (item.const_key === selectedFeature) {
      return true;
    }

    if (item.children.length) {
      return hasChildWithSelectedFeature(item, selectedFeature);
    }

    return false;
  });
}
