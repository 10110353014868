import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {GetCodeListResponse} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getOptionsFromCodeList = (codeLists: GetCodeListResponse | Nullish): Option[] => {
  if (isNil(codeLists)) {
    return [];
  }

  return codeLists.map((codeLists) => ({
    label: codeLists.name,
    value: codeLists.code,
  }));
};
