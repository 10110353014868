import {string, object, SchemaOf, array} from 'yup';

import {PersonRequestBody} from '../types/PersonRequestBody';
import {$EmailData} from './$EmailData';
import {$IdentityCardRequestBody} from './$IdentityCardRequestBody';
import {$PersonData} from './$PersonData';
import {$PhoneNumberData} from './$PhoneNumberData';

export const $PersonRequestBody: SchemaOf<PersonRequestBody> = object({
  emails: array().defined().of($EmailData),
  identityCards: array().defined().of($IdentityCardRequestBody),
  phoneNumbers: array().defined().of($PhoneNumberData),
  permanentAddressId: string().defined().nullable(),
  personData: $PersonData.defined(),
});
