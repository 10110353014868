import i18n from '@omnetic-dms/i18n';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DocumentTemplatesLayout} from './components/DocumentTemplatesLayout';

export function DocumentTemplates() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.document.labels.documentTemplates')}}
      description={i18n.t('entity.document.labels.documentTemplatesDescription')}
      data-testid="documents-templates-settings-modal"
    >
      <DocumentTemplatesLayout />
    </SettingsTemplate>
  );
}
