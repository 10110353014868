interface MenuItem<T extends {id: string} = {id: string}> {
  id: string;
  content?: T[];
}

export type MenuItemWithPin<T extends MenuItem> = {
  isPinned: boolean;
  legacyPinId?: string;
  content?: MenuItemWithPin<T>[];
} & T;

/**
 * Temporary solution for different logic from previous sidebar.
 * Historically only first level menu items could have been pinned.
 * Currently only menu items without subitems can be pinned.
 */
function getLegacyPin(
  menuItem: {parentId: string | undefined; index: number},
  pinnedMenuItems: string[]
) {
  // First subitem represents the parent item (routes to the same page).
  if (menuItem.index === 0 && menuItem.parentId && pinnedMenuItems.includes(menuItem.parentId)) {
    return menuItem.parentId;
  }

  return undefined;
}

export function addPinsToMenuItems<TMenuItem extends MenuItem<TMenuItem>>(
  menuItems: TMenuItem[],
  pinnedMenuItems: string[],
  parentId?: string
): MenuItemWithPin<TMenuItem>[] {
  return menuItems.map((menuItem, index) => {
    const isPinned = pinnedMenuItems.includes(menuItem.id);

    const legacyPin = getLegacyPin({parentId, index}, pinnedMenuItems);
    const legacyPinId = legacyPin ? {legacyPinId: legacyPin} : {};
    const content = menuItem.content
      ? {content: addPinsToMenuItems(menuItem.content, pinnedMenuItems, menuItem.id)}
      : {};

    return {
      ...menuItem,
      ...legacyPinId,
      ...content,
      isPinned,
    };
  });
}
