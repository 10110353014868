import {isFeatureEnabled} from 'feature-flags';
import {BreadcrumbType} from 'platform/components';

import {useGetRoleQuery} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {composePath, useRequiredParams} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ERouterTabNames} from '../UserManagement/types/ERouterTabNames';
import {RoleDetail} from './components/RoleDetail';

const path = composePath(settingsRoutes.userManagement, {
  queryParams: {tab: ERouterTabNames.roles},
});

export function RoleEdit() {
  const {roleId} = useRequiredParams();
  const {data: role, isLoading, isError} = useGetRoleQuery({roleId});

  const isManagementSplit = isFeatureEnabled(featureFlags.SETTINGS_MANAGEMENT_SPLIT);

  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('page.settings.labels.roleManagement'),
      href: isManagementSplit ? settingsRoutes.roleManagement : path,
    },
  ];

  return (
    <SettingsTemplate
      header={{breadcrumbs, title: role?.title}}
      data-testid="settings-management-role-layout"
      isLoading={isLoading}
      isError={isError}
    >
      <RoleDetail />
    </SettingsTemplate>
  );
}
