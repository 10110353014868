import {compile} from 'path-to-regexp';

import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {isNotEmpty} from 'ramda';

import {findCurrentRoute} from '../utils/findCurrentRoute';
import {DynamicRouteParams, getRouteParams} from '../utils/getRouteParams';
import {useRouterQuery} from './useRouterQuery';

type RouterNavigationProps = {
  params?: Record<string, string | number>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: Record<string, any>;
  dynamic?: Array<string | number>;
  shallow?: boolean;
  hash?: string;
  ignoreUnmountEvents?: boolean;
};

const createUrl = (location: string, props: RouterNavigationProps = {}) => {
  // replace url variables with params
  let url = location;

  if (props?.params && isNotEmpty(props?.params)) {
    url = compile(url)(props.params);
  }

  // concat dynamic parameters
  if (url.endsWith('/*') && props?.dynamic && isNotEmpty(props?.dynamic)) {
    url = url.replace(/\/\*$/, `/${props.dynamic?.join('/')}`);
  }

  // add stringified query
  if (props?.query && isNotEmpty(props.query)) {
    const params = new URLSearchParams(Object.entries(props.query))?.toString();
    url = `${url}?${params}`;
  }

  if (props.hash) {
    url = `${url}#${props.hash}`;
  }

  return url;
};

const setIgnoreInitialPropsRouter = () => {
  window.ignoreInitialPropsRouter = true;
};

/**
 * @deprecated DON'T USE THIS WRAPPER. Use react-router-dom features instead.
 */
export type UseRouterType = {
  hash: string;
  pathname: string;
  search: string;
  asPath: string;
  route: string;
  query: any;
  params: Record<string, unknown> & DynamicRouteParams;
  back: () => void;
  push: (location: string, props?: RouterNavigationProps) => void;
  replace: (location: string, props?: RouterNavigationProps) => void;
  setHash: (hash: string) => void;
};

/**
 * @deprecated DON'T USE THIS WRAPPER. Use react-router-dom features instead.
 */
export const useRouter = (): UseRouterType => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useRouterQuery();
  const params = useParams();

  const routeParams = {...params, ...getRouteParams(location?.pathname)};

  const push = (location: string, props?: RouterNavigationProps) => {
    if (props?.shallow) {
      setIgnoreInitialPropsRouter();
    }

    navigate(createUrl(location, props), {
      state: {ignoreUnmountEvents: props?.ignoreUnmountEvents ?? false},
    });
  };

  const replace = (location: string, props?: RouterNavigationProps) => {
    if (props?.shallow) {
      setIgnoreInitialPropsRouter();
    }
    navigate(createUrl(location, props), {replace: true});
  };
  const back = () => {
    navigate(-1);
  };

  const setHash = (hash: string) => {
    window.location.hash = hash;
  };

  return {
    hash: location.hash,
    pathname: location.pathname,
    search: location.search,
    query,
    params: routeParams,
    back,
    asPath: `${location.pathname}${location.search}`,
    route: findCurrentRoute(location.pathname),
    push,
    replace,
    setHash,
  };
};
