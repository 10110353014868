import {openDeleteDialog, showNotification} from 'platform/components';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil, path} from 'ramda';
import {isArray, isFalse, noop} from 'ramda-adjunct';

import {useDeleteVehicleWarehouseMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError, usePermissions} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

export function VehicleWarehouseGrid() {
  const navigate = useNavigate();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [deleteVehicleWarehouse] = useDeleteVehicleWarehouseMutation();

  const [canCreateVehicleWarehouse, canEditVehicleWarehouse] = usePermissions({
    permissionKeys: ['vehicleWarehouseCreate', 'vehicleWarehouseEdit'],
  });

  const onDelete = (vehicleWarehouseId: string, name: string | undefined) => {
    openDeleteDialog({
      text: i18n.t('entity.vehicleWarehouse.labels.confirmDeleteText', {name}),
      onConfirm: () =>
        deleteVehicleWarehouse({vehicleWarehouseId})
          .unwrap()
          .then(refreshDataGrid)
          .catch(handleApiError),
    });
  };

  const actions: ActionCallback = useCallback(
    ({actionKey, rowId, rowData}) => {
      const id = isArray(rowId) ? head(rowId) : rowId;

      if (isNil(id)) {
        throw new Error('rowId for vehicle warehouse DG not present');
      }

      const name = path<string>(['name', 'value'], rowData);

      match(actionKey)
        .with('detail', 'edit', () => {
          if (isFalse(canEditVehicleWarehouse)) {
            showNotification.error(i18n.t('general.labels.noPermission'));
            return noop();
          }
          return navigate(
            composePath(settingsRoutes.vehicleWarehouseEdit, {
              params: {
                id,
              },
            })
          );
        })
        .with('delete', () => onDelete(id, name))
        .otherwise(noop);
    },
    [navigate]
  );

  return (
    <Box minHeight={40}>
      <DataGrid
        data-testid={testIds.settings.vehicleWarehouseManagement('vehicleWarehouseGrid')}
        gridCode="vehicle-warehouse"
        actionCallback={actions}
        emptyState={{
          headline: i18n.t('page.vehicleWarehouseManagement.noWarehouses'),
          subheadline: i18n.t('page.vehicleWarehouseManagement.labels.createFirstWarehouse'),
          action: canCreateVehicleWarehouse
            ? {
                title: i18n.t('page.vehicleWarehouseManagement.actions.createWarehouse'),
                onClick: () => {
                  navigate(settingsRoutes.vehicleWarehouseCreate);
                },
              }
            : undefined,
        }}
        ref={dataGridRef}
        autoHeight
      />
    </Box>
  );
}
