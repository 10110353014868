import {useEffect, useRef, useState} from 'react';

import {useDebouncedCallback} from './useDebouncedCallback';

/**
 * @about Returns debounced value passed by arg
 * @param value value for debounce
 * @param delay debounced delay in ms
 * @returns debouncedValue
 *
 * @example
 * const debouncedValue = useDebouncedValue(value, 500);
 */
export function useDebouncedValue<T>(initialValue: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);
  const previousValueRef = useRef<T | undefined>(initialValue);

  const updateDebouncedValue = useDebouncedCallback(setDebouncedValue, delay);

  useEffect(() => {
    if (previousValueRef.current === initialValue) {
      return;
    }
    updateDebouncedValue(initialValue);
    previousValueRef.current = initialValue;
  }, [initialValue]);

  return debouncedValue;
}
