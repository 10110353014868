import {match} from 'path-to-regexp';

import {findCurrentRoute} from './findCurrentRoute';

export type DynamicRouteParams = {dynamic: Array<string>};
/**
 * @param pathname current URL from first slash / to query string ?
 * @returns params for given route, f.e. for /customers/1234 return { id: 1234 }
 */
export function getRouteParams<Return = Record<string, any> & DynamicRouteParams>(
  pathname?: string
): Return {
  const route = findCurrentRoute(pathname as string);
  if (route) {
    const matchParams = match<Record<string, any>>(route.split('/*'), {decode: decodeURIComponent});

    const result = matchParams(pathname as string);

    const params: DynamicRouteParams = {
      dynamic: (route.includes('/*')
        ? pathname?.slice(route.indexOf('/*'), pathname?.length).split('/').filter(Boolean)
        : []) as string[],
    };

    if (result) {
      // eslint-disable-next-line no-restricted-syntax
      return {...result.params, ...params} as unknown as Return;
    }
    // eslint-disable-next-line no-restricted-syntax
    return params as unknown as Return;
  }
  // eslint-disable-next-line no-restricted-syntax
  return {dynamic: []} as unknown as Return;
}
