import {ButtonGroup, DropdownTag, Label, openDialog} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import {useGetUserQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useUserActivationDropdownParams} from '../hooks/useUserActivationDropdownParams';
import {EditUserPasswordForm} from './EditUserPasswordForm';

interface UserCredentialsProps extends RequiredTestIdProps {
  userId: string;
}
export function UserCredentials(props: UserCredentialsProps) {
  const {data: user} = useGetUserQuery({id: props.userId});

  const {colorScheme, dropdownItems, label} = useUserActivationDropdownParams(user);

  const handleChangePassword = () =>
    openDialog(
      <EditUserPasswordForm
        userId={props.userId}
        data-testid={suffixTestId('EditUserPasswordForm', props)}
      />,
      {
        title: i18n.t('entity.user.labels.changePassword'),
      }
    );

  return (
    <VStack spacing={4}>
      <DropdownTag
        dropdownItems={dropdownItems}
        label={label}
        colorScheme={colorScheme}
        size="large"
      />
      <VStack>
        <Label>{i18n.t('entity.user.labels.emailUsername')}</Label>
        <Text>{user?.email}</Text>
      </VStack>
      <ButtonGroup
        buttons={[
          {
            title: i18n.t('page.login.actions.resetPassword'),
            variant: 'link',
            leftIcon: 'action/lock',
            onClick: handleChangePassword,
          },
        ]}
      />
    </VStack>
  );
}
