import styled from 'styled-components';

import {Button} from '../Button/Button';

export const BulkWrapper = styled.div`
  font-family: ${({theme}) => theme.fonts.body};
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  width: 100%;
  background-color: ${({theme}) => theme.colors.palettes.blue[60][100]};
  color: ${({theme}) => theme.colors.palettes.white[10][100]};
  display: flex;
  justify-content: space-between;
  padding: 6px ${({theme}) => theme.getSize(4)};
  align-items: center;
`;

export const BulkButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BulkButton = styled(Button)`
  margin: 0 6px;
`;

export const BulkButtonsDivider = styled.div`
	border-left: 1px solid ${({theme}) => theme.colors.palettes.white[10][100]};
	height: ${({theme}) => theme.fontSizes.text.base};
	margin: 0;
	padding: 0'

`;
