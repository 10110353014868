import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';

import {findIndex, filter, propEq, update, find} from 'ramda';

import {ApiException} from '@omnetic-dms/api';

import {DocumentKind} from '../../types/DocumentKind';
import {
  DocumentsSliceType,
  DownloadDocumentTemplate,
  ExtendedTemplate,
  TSetUploadProcessing,
  TSetUploadProgressOfDocumentTemplateFile,
  TUploadDocumentTemplateRequest,
  UpdateDocumentTemplateRequest,
} from '../../types/GeneralSettingsDocuments';
import {TemplateGroupV2 as TemplateGroup} from '../../types/TemplateGroupV2';
import {TemplateV2 as Template} from '../../types/TemplateV2';

const STATE_NAME = 'generalSettings/Documents';

const initialState: DocumentsSliceType = {
  templates: {
    loading: false,
    error: null,
    data: [],
  },
  documentKinds: [],
  uploadingTemplate: null,
  downloading: false,
  templateGroup: {
    loading: false,
    data: [],
    error: null,
  },
};

const setLoadingToCustomTemplate = (
  templates: ExtendedTemplate[],
  isLoading: boolean,
  customTemplateId: string
): ExtendedTemplate[] =>
  templates.map((template): ExtendedTemplate => {
    if (template.id === customTemplateId) {
      return {...template, isLoading};
    }
    return template;
  });

const documentsSlice = createSlice({
  name: STATE_NAME,
  initialState,
  reducers: {
    fetchTemplateGroup(state) {
      state.templateGroup.loading = true;
    },
    fetchTemplateGroupSuccess(
      state,
      {
        payload: {result, documentKinds},
      }: PayloadAction<{result: TemplateGroup[]; documentKinds: DocumentKind[]}>
    ) {
      state.templateGroup.data = result;
      state.documentKinds = documentKinds;
      state.templateGroup.loading = false;
    },
    fetchTemplateGroupError(state) {
      state.templateGroup.loading = false;
    },
    downloadTemplate(state, _action: PayloadAction<DownloadDocumentTemplate>) {
      state.downloading = true;
    },
    downloadTemplateLoaded(state) {
      state.downloading = false;
    },
    getDocumentTemplate(state) {
      state.templates.loading = true;
    },
    getDocumentTemplateError(state) {
      state.templates.loading = false;
    },
    getDocumentTemplateSuccess(state, {payload}: PayloadAction<Template[]>) {
      state.templates.loading = false;
      state.templates.data = payload.map((item: ExtendedTemplate) => ({
        ...item,
        isUploading: false,
        isProgressing: false,
        progress: 0,
      }));
    },
    uploadDocumentTemplateRequest(state, {payload}: PayloadAction<TUploadDocumentTemplateRequest>) {
      state.templates.loading = true;
      const templateId = find(
        propEq(payload.code, 'documentKindCode'),
        filter(propEq(true, 'system'), state.templates.data ?? [])
      )?.id;
      if (templateId) {
        const templateIdx = findIndex(propEq(templateId, 'id'), state.templates.data ?? []);
        if (state.templates?.data?.[templateIdx]) {
          const updatedTemplate: ExtendedTemplate = {
            ...state.templates?.data?.[templateIdx],
            isUploading: true,
            isProcessing: true,
          };
          state.uploadingTemplate = updatedTemplate;
          state.templates.data = update(templateIdx, updatedTemplate, state.templates.data ?? []);
        }
      }
    },
    updateTemplateRequest(state, {payload}: PayloadAction<UpdateDocumentTemplateRequest>) {
      state.templates.data = setLoadingToCustomTemplate(
        state?.templates?.data ?? [],
        true,
        payload.customTemplateId
      );
    },
    templateUpdated(state, {payload}: PayloadAction<{customTemplateId: string}>) {
      state.templates.data = setLoadingToCustomTemplate(
        state.templates.data ?? [],
        false,
        payload.customTemplateId
      );
    },
    uploadDocumentTemplateSuccess(
      state,
      {payload}: PayloadAction<{code: string; fileName: string}>
    ) {
      state.templates.loading = false;
      const template = find(
        propEq(payload.code, 'documentKindCode'),
        filter(propEq(true, 'system'), state.templates.data ?? [])
      );
      if (template) {
        const templateIdx = findIndex(propEq(template.id, 'id'), state.templates.data ?? []);
        const updatedTemplate: ExtendedTemplate = {
          ...template,
          ...payload,
          isUploading: false,
          progress: 0,
        };
        state.templates.data = update(templateIdx, updatedTemplate, state.templates.data ?? []);
        state.uploadingTemplate = updatedTemplate;
      }
    },
    uploadDocumentTemplateError(
      state,
      {payload}: PayloadAction<{error: AxiosError<ApiException> | null; code: string}>
    ) {
      state.templates.loading = false;
      state.templates.data = state.templates.data?.map((item: ExtendedTemplate) =>
        item.documentKindCode === payload.code
          ? {...item, isProcessing: false, isUploading: false, isLoading: false}
          : item
      );
    },
    setUploadProgressOfDocumentTemplateFile(
      state,
      {payload}: PayloadAction<TSetUploadProgressOfDocumentTemplateFile>
    ) {
      const {progress, code} = payload;

      const data = state.templates.data;
      state.templates.data = data?.map((item: ExtendedTemplate) =>
        item.documentKindCode === code ? {...item, progress} : item
      );
    },
    setUploadProcessing(state, {payload}: PayloadAction<TSetUploadProcessing>) {
      const data = state.templates.data;
      state.templates.data = data?.map((item: ExtendedTemplate) =>
        item.documentKindCode === payload.code ? {...item, isProcessing: payload.processing} : item
      );
    },
  },
});

export const deleteFile = createAction<string>(`${STATE_NAME}/deleteFile`);

export const setPrimaryTemplate = createAction<string>(`${STATE_NAME}/setPrimaryTemplate`);

const {reducer, actions} = documentsSlice;

export const {
  getDocumentTemplateSuccess,
  uploadDocumentTemplateRequest,
  updateTemplateRequest,
  templateUpdated,
  uploadDocumentTemplateSuccess,
  uploadDocumentTemplateError,
  setUploadProgressOfDocumentTemplateFile,
  setUploadProcessing,
  downloadTemplate,
  downloadTemplateLoaded,
  fetchTemplateGroup,
  fetchTemplateGroupSuccess,
  fetchTemplateGroupError,
  getDocumentTemplate,
  getDocumentTemplateError,
} = actions;

export const DocumentsReducer = reducer;
