import {FormControl, FormField, Separator} from 'platform/components';
import {GridItem, Grid, Heading, Space, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {isNil} from 'ramda';

import {
  DirectSaleVariantDetail,
  GetTemplatesApiResponse,
  SeriesResponseBody,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, RequiredTestIdProps} from 'shared';

import {SettingsFooter} from '../../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../../components/SettingsSection/SettingsSection';
import {getOptionsFromSeries} from '../../../../utils/getOptionsFromSeries';
import {getOptionsFromTemplates} from '../../../../utils/getOptionsFromTemplates';
import {PaymentCard} from './components/PaymentCard';

interface DocumentsTabProps extends RequiredTestIdProps {
  control: FormControl<DirectSaleVariantDetail>;
  formApi: UseFormReturn<DirectSaleVariantDetail>;
  onDiscard: VoidFunction;
  directSaleVariantId?: string;
  offerTemplates?: GetTemplatesApiResponse;
  seriesList?: SeriesResponseBody[];
  invoiceTemplates?: GetTemplatesApiResponse;
}

export function DocumentsTab(props: DocumentsTabProps) {
  return (
    <>
      <SettingsSection>
        <VStack spacing={4}>
          <Heading size={4}>{i18n.t('general.labels.documents')}</Heading>

          <Grid columns={2}>
            <GridItem span={1}>
              <FormField
                control={props.control}
                name="documents.orderOffer.docSeriesId"
                type="choice"
                label={i18n.t('entity.warehouse.labels.offerSeries')}
                options={getOptionsFromSeries(props.seriesList, 'warehouse/offer')}
                data-testid={suffixTestId('offerSeriesId', props)}
              />
            </GridItem>
            <GridItem span={1}>
              <FormField
                control={props.control}
                name="documents.orderOffer.templateId"
                type="choice"
                label={i18n.t('entity.warehouse.labels.offerTemplate')}
                options={getOptionsFromTemplates(props.offerTemplates)}
                data-testid={suffixTestId('offerTemplateId', props)}
              />
            </GridItem>
          </Grid>

          <Separator />

          <Heading size={4}>{i18n.t('general.labels.checkout')}</Heading>

          <PaymentCard
            control={props.control}
            formApi={props.formApi}
            title={i18n.t('entity.warehouse.labels.cashPayment')}
            paymentType="CASH"
            docSeriesId="documents.cashPayment.docSeriesId"
            invoiceTemplateId="documents.cashPayment.invoiceTemplateId"
            isEnable="documents.cashPayment.isEnable"
            seriesList={props.seriesList}
            invoiceTemplates={props.invoiceTemplates}
            data-testid={suffixTestId('paymentType.cash', props)}
          />
          <PaymentCard
            control={props.control}
            formApi={props.formApi}
            title={i18n.t('entity.warehouse.labels.cardPayment')}
            paymentType="CARD"
            docSeriesId="documents.cardPayment.docSeriesId"
            invoiceTemplateId="documents.cardPayment.invoiceTemplateId"
            isEnable="documents.cardPayment.isEnable"
            seriesList={props.seriesList}
            invoiceTemplates={props.invoiceTemplates}
            data-testid={suffixTestId('paymentType.card', props)}
          />
          <PaymentCard
            control={props.control}
            formApi={props.formApi}
            title={i18n.t('entity.warehouse.labels.bankPayment')}
            paymentType="BANK_TRANSFER"
            docSeriesId="documents.bankPayment.docSeriesId"
            invoiceTemplateId="documents.bankPayment.invoiceTemplateId"
            isEnable="documents.bankPayment.isEnable"
            seriesList={props.seriesList}
            invoiceTemplates={props.invoiceTemplates}
            data-testid={suffixTestId('paymentType.bankTransfer', props)}
          />
          <PaymentCard
            control={props.control}
            formApi={props.formApi}
            title={i18n.t('entity.warehouse.labels.internalPayment')}
            paymentType="INTERNAL"
            docSeriesId="documents.internalPayment.docSeriesId"
            invoiceTemplateId="documents.internalPayment.invoiceTemplateId"
            isEnable="documents.internalPayment.isEnable"
            seriesList={props.seriesList}
            invoiceTemplates={props.invoiceTemplates}
            data-testid={suffixTestId('paymentType.internal', props)}
          />
        </VStack>
      </SettingsSection>

      <Space vertical={18} />

      <SettingsFooter
        actions={[
          {
            type: 'button',
            variant: 'secondary',
            title: isNil(props.directSaleVariantId)
              ? i18n.t('general.actions.discard')
              : i18n.t('general.actions.discardChanges'),
            onClick: props.onDiscard,
            'data-testid': suffixTestId('discard', props),
          },
          {
            type: 'form-button',
            control: props.control,
            buttonType: 'submit',
            variant: 'primary',
            title: isNil(props.directSaleVariantId)
              ? i18n.t('general.actions.save')
              : i18n.t('general.actions.saveChanges'),
            'data-testid': suffixTestId('submit', props),
          },
        ]}
      />
    </>
  );
}
