import {IFilterParams} from '@ag-grid-community/core';
import {Chips, NumberInput, Separator, useTranslationContext} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {ForwardedRef, forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react';

import {equals, isNil} from 'ramda';
import {isArray, isObjLike, isString, toNumber} from 'ramda-adjunct';

import {debounce, suffixTestId, TestIdProps} from 'shared';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {IFilter} from '../../types/AgGridTypes';
import {QuickFilters} from '../../types/Api';

export type RangeFilterType = {
  isDisabled: boolean;
} & IFilterParams &
  QuickFilters &
  TestIdProps;

function RangeFilterComponent(
  {filterChangedCallback, isDisabled, quickFilters, ...props}: RangeFilterType,
  ref: ForwardedRef<IFilter>
) {
  const t = useTranslationContext();
  const [filterValue, _setFilterValue] = useState<[number | null, number | null] | string>([
    null,
    null,
  ]);
  const isActive = useRef<boolean>(false);
  const setFilterValue = (value: [number | null, number | null] | string) => {
    isActive.current = isString(value) || (isArray(value) && (value[0] !== 0 || value[1] !== 0));
    _setFilterValue(value);
  };
  const {onChipsChange} = useFilterOnChipsChange({
    filterChangedCallback,
    setFilterValue,
    defaultValue: [0, 0],
  });

  const debouncedFilterChangedCallback = useMemo(
    () => debounce(filterChangedCallback, 700),
    [filterChangedCallback]
  );

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return isActive.current;
    },

    doesFilterPass() {
      return true;
    },

    getModel() {
      if (isNil(filterValue)) {
        return;
      }

      if (filterValue[0] === 0 && filterValue[1] === 0) {
        isActive.current = false;
        return undefined;
      }

      if (isObjLike(filterValue)) {
        if (isNil(filterValue[0]) && isNil(filterValue[1])) {
          return undefined;
        }

        return {from: filterValue[0], to: filterValue[1]};
      }

      return filterValue;
    },

    setModel(
      model: {from: string; to: string} | [number | null, number | null] | undefined | string
    ) {
      if (equals(model, filterValue)) {
        return;
      }

      if (isNil(model)) {
        setFilterValue([0, 0]);
        filterChangedCallback();
        return;
      }

      if (model && isObjLike(model) && 'from' in model && 'to' in model) {
        setFilterValue([toNumber(model.from) ?? 0, toNumber(model.to)]);
        filterChangedCallback();
        return;
      }

      setFilterValue(model);

      filterChangedCallback();
    },
  }));

  const onFromInputChange = (value: number | null) => {
    if (isString(filterValue) || isNil(filterValue)) {
      setFilterValue([toNumber(value), null]);
    }

    if (isArray(filterValue)) {
      setFilterValue([
        isNil(value) ? value : toNumber(value),
        filterValue?.[1] === 0 ? null : filterValue?.[1],
      ]);
    }

    debouncedFilterChangedCallback();
  };

  const onToInputChange = (value: number | null) => {
    if (isString(filterValue) || isNil(filterValue)) {
      setFilterValue([null, toNumber(value)]);
    }

    if (isArray(filterValue)) {
      setFilterValue([
        filterValue?.[0] === 0 ? null : filterValue?.[0],
        isNil(value) ? value : toNumber(value),
      ]);
    }

    debouncedFilterChangedCallback();
  };

  const isQuickFilterValue = isString(filterValue) ? [filterValue] : null;

  return (
    <VStack>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('range-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <HStack spacing={5}>
        <NumberInput
          label={t('general.labels.from')}
          value={isNil(filterValue?.[0]) ? null : (toNumber(filterValue?.[0]) ?? 0)}
          onChange={onFromInputChange}
          isDisabled={isDisabled}
          data-testid={suffixTestId('range-filter-from', props)}
        />
        <NumberInput
          label={t('general.labels.to')}
          value={isNil(filterValue?.[1]) ? null : (toNumber(filterValue?.[1]) ?? 0)}
          onChange={onToInputChange}
          isDisabled={isDisabled}
          data-testid={suffixTestId('range-filter-to', props)}
        />
      </HStack>
    </VStack>
  );
}

export const RangeFilter = forwardRef<IFilter, RangeFilterType>(RangeFilterComponent);
