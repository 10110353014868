import {
  Chips,
  ChipsOption,
  DataStatus,
  Radio,
  RadioOption,
  Separator,
  closeCurrentDialog,
  openDialog,
} from 'platform/components';
import {Right, Show} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isNil, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  GetLabourCatalogApiResponse,
  useGetDictionaryQuery,
  useGetLabourCatalogQuery,
  usePutTreeFolderRelationsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {LiteralUnionAutocomplete, Nullish, TestIdProps, buildArray, suffixTestId} from 'shared';

import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {handleApiError} from '../../utils/handleApiError';
import {ActiveFolder} from '../MoveToFolder/types/activeFolder';
import {CalculationPriceForm} from './components/CalculationPriceForm';
import {CooperationForm} from './components/CooperationForm';
import {DirectPriceForm} from './components/DirectPriceForm';
import {SelectWorkFolder} from './components/SelectWorkFolder';
import {SubcontractForm} from './components/SubcontractForm';
import {TimeNormForm} from './components/TimeNormForm';
import {CatalogueFolder} from './types/catalogueFolder';
import {FreePositionData} from './types/freePositionData';

interface LabourCatalogItemFromProps extends TestIdProps {
  freePositionData?: FreePositionData;
  editedId?: string;
  onClose: () => void;
  onSubmitted?: () => void;
  catalogueFolder?: CatalogueFolder;
}

type ItemType = LiteralUnionAutocomplete<
  | 'SVCPRICETYPE_TIME_NORM'
  | 'SVCPRICETYPE_CALCULATION_PRICE'
  | 'SVCPRICETYPE_DIRECT_PRICE'
  | 'SVCPRICETYPE_COOPERATION'
  | 'SVCPRICETYPE_SUBCONTRACT'
>[];

type ItemPosition = LiteralUnionAutocomplete<'CATALOG' | 'FREE_POSITION'> | null;

export function LabourCatalogItemFrom(props: LabourCatalogItemFromProps) {
  const {
    data: editedItem,
    isLoading: isEditedItemLoading,
    isError: isEditedItemError,
  } = useGetLabourCatalogQuery(
    {labourCatalogId: props.editedId ?? ''},
    {skip: isNil(props.editedId)}
  );
  const {isLoading: isClassificationOptionsLoading, isError: isClassificationOptionsError} =
    useGetDictionaryQuery({category: 'SVCLABOURCATALOGCLASSIFICATION'});
  const {isLoading: isCategoryOptionsLoading, isError: isCategoryOptionsError} =
    useGetDictionaryQuery({category: 'SVCLABOURCATALOGCATEGORYTYPE'});

  const [initalValues, setInitialValues] = useState<Partial<GetLabourCatalogApiResponse>>({});

  const [putTreeFolderRelation] = usePutTreeFolderRelationsMutation();

  const isCatalogDisabled = !props.editedId && !props.catalogueFolder;

  const [itemType, setItemType] = useState<ItemType | Nullish>(['SVCPRICETYPE_TIME_NORM']);
  const [itemPosition, setItemPosition] = useState<ItemPosition>(
    isCatalogDisabled ? 'FREE_POSITION' : 'CATALOG'
  );
  const [selectedFolder, setSelectedFolder] = useState<ActiveFolder>(props.catalogueFolder);

  const [canEditCatalogueItem] = usePermissions({permissionKeys: ['editCatalogueItem']});

  useEffect(() => {
    if (editedItem?.priceType && editedItem.priceType !== itemType?.[0]) {
      setItemType([editedItem.priceType]);
    }
  }, [editedItem, itemType, setItemType]);

  const handleCatalogueFolderChange = () =>
    openDialog(
      <SelectWorkFolder
        onClose={closeCurrentDialog}
        onSelect={setSelectedFolder}
        serviceCaseId={props.freePositionData?.serviceCaseId}
      />,
      {
        size: 'small',
        title: i18n.t('entity.addWork.labels.selectCategory'),
      }
    );

  const handleFolderChange = async (itemId: string | Nullish) => {
    if (isNotNil(itemId) && isNotNil(selectedFolder)) {
      await putTreeFolderRelation({
        contextTarget: 'labour-catalog',
        contextId: selectedFolder.contextId,
        leafId: selectedFolder.id,
        body: {
          relation: {
            target: 'labour-catalog-item',
            ids: [itemId],
          },
        },
      })
        .unwrap()
        .catch(handleApiError);
    }

    props.onSubmitted?.();
    props.onClose();
  };

  const onInitialValuesChange = (values: Partial<GetLabourCatalogApiResponse>) =>
    setInitialValues({...initalValues, ...values});

  const chipsOptions = buildArray<ChipsOption>()
    .add({
      label: i18n.t('entity.addWork.lables.timeNorm'),
      value: 'SVCPRICETYPE_TIME_NORM',
    })
    .add({
      label: i18n.t('entity.addWork.lables.calculationPrice'),
      value: 'SVCPRICETYPE_CALCULATION_PRICE',
    })
    .add({
      label: i18n.t('entity.addWork.lables.directPrice'),
      value: 'SVCPRICETYPE_DIRECT_PRICE',
    })
    .add({
      label: i18n.t('entity.addWork.lables.cooperation'),
      value: 'SVCPRICETYPE_COOPERATION',
    })
    .add({
      label: i18n.t('entity.addWork.lables.subcontract'),
      value: 'SVCPRICETYPE_SUBCONTRACT',
    });

  const radioOptions = buildArray<RadioOption>()
    .add({
      label: i18n.t('entity.addWork.lables.catalogue'),
      value: 'CATALOG',
      isDisabled: isCatalogDisabled,
    })
    .add({
      label: i18n.t('entity.addWork.lables.freePosition'),
      value: 'FREE_POSITION',
    });

  const selectedItemType = itemType?.[0];

  const isLoading =
    isEditedItemLoading || isClassificationOptionsLoading || isCategoryOptionsLoading;

  const isError = isEditedItemError || isClassificationOptionsError || isCategoryOptionsError;

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={115}>
      <Chips
        options={chipsOptions}
        value={itemType}
        onChange={setItemType}
        isDisabled={!isNil(props.editedId)}
        data-testid={suffixTestId('itemType', props)}
      />
      <Separator />
      <Show when={isNil(props.editedId) && isNotNilOrEmpty(props.freePositionData)}>
        <Right>
          <Radio
            options={radioOptions}
            value={itemPosition}
            onChange={setItemPosition}
            data-testid={suffixTestId('itemPosition', props)}
          />
        </Right>
      </Show>
      <Show when={selectedItemType === 'SVCPRICETYPE_TIME_NORM'}>
        <TimeNormForm
          initalValues={initalValues}
          onInitialValuesChange={onInitialValuesChange}
          freePositionData={props.freePositionData}
          onClose={props.onClose}
          onSubmitted={handleFolderChange}
          editedData={editedItem}
          isFreePosition={itemPosition === 'FREE_POSITION'}
          catalogueFolder={selectedFolder}
          onCatalogueFolderChange={handleCatalogueFolderChange}
          canEditCatalogueItem={canEditCatalogueItem}
          data-testid={suffixTestId('createNewForm', props)}
        />
      </Show>
      <Show when={selectedItemType === 'SVCPRICETYPE_CALCULATION_PRICE'}>
        <CalculationPriceForm
          initalValues={initalValues}
          onInitialValuesChange={onInitialValuesChange}
          freePositionData={props.freePositionData}
          onClose={props.onClose}
          onSubmitted={handleFolderChange}
          editedData={editedItem}
          isFreePosition={itemPosition === 'FREE_POSITION'}
          catalogueFolder={selectedFolder}
          onCatalogueFolderChange={handleCatalogueFolderChange}
          canEditCatalogueItem={canEditCatalogueItem}
          data-testid={suffixTestId('createNewForm', props)}
        />
      </Show>
      <Show when={selectedItemType === 'SVCPRICETYPE_DIRECT_PRICE'}>
        <DirectPriceForm
          initalValues={initalValues}
          onInitialValuesChange={onInitialValuesChange}
          freePositionData={props.freePositionData}
          onClose={props.onClose}
          onSubmitted={handleFolderChange}
          editedData={editedItem}
          isFreePosition={itemPosition === 'FREE_POSITION'}
          catalogueFolder={selectedFolder}
          onCatalogueFolderChange={handleCatalogueFolderChange}
          canEditCatalogueItem={canEditCatalogueItem}
          data-testid={suffixTestId('createNewForm', props)}
        />
      </Show>
      <Show when={selectedItemType === 'SVCPRICETYPE_COOPERATION'}>
        <CooperationForm
          initalValues={initalValues}
          onInitialValuesChange={onInitialValuesChange}
          freePositionData={props.freePositionData}
          onClose={props.onClose}
          onSubmitted={handleFolderChange}
          editedData={editedItem}
          isFreePosition={itemPosition === 'FREE_POSITION'}
          catalogueFolder={selectedFolder}
          onCatalogueFolderChange={handleCatalogueFolderChange}
          canEditCatalogueItem={canEditCatalogueItem}
          data-testid={suffixTestId('createNewForm', props)}
        />
      </Show>
      <Show when={selectedItemType === 'SVCPRICETYPE_SUBCONTRACT'}>
        <SubcontractForm
          initalValues={initalValues}
          onInitialValuesChange={onInitialValuesChange}
          freePositionData={props.freePositionData}
          onClose={props.onClose}
          onSubmitted={handleFolderChange}
          editedData={editedItem}
          isFreePosition={itemPosition === 'FREE_POSITION'}
          catalogueFolder={selectedFolder}
          onCatalogueFolderChange={handleCatalogueFolderChange}
          canEditCatalogueItem={canEditCatalogueItem}
          data-testid={suffixTestId('createNewForm', props)}
        />
      </Show>
    </DataStatus>
  );
}
