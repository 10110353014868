import {Action, Actions, Separator} from 'platform/components';
import {Box, Heading, HStack} from 'platform/foundation';

interface CreateInvoiceHeaderProps {
  actions?: Action[];
  title: string;
}

export function FormHeader(props: CreateInvoiceHeaderProps) {
  return (
    <>
      <Box paddingVertical={3} paddingHorizontal={4}>
        <HStack justify="space-between" align="center">
          <Heading size={3}>{props.title}</Heading>
          <Actions actions={props.actions} />
        </HStack>
      </Box>
      <Separator spacing={0} />
    </>
  );
}
