import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {BillingInformation} from '../../types/BillingInformation';
import {BILLING_INFORMATION_SLICE_NAME} from './constants';

type BillingInformationSliceType = {
  data: Record<string, BillingInformation>;
};

const initialState: BillingInformationSliceType = {
  data: {},
};

const billingInformationSlice = createSlice({
  name: BILLING_INFORMATION_SLICE_NAME,
  initialState,
  reducers: {
    setBillingInformation: (state, {payload}: PayloadAction<BillingInformation>) => {
      state.data[payload.id] = payload;
    },
  },
});

const {reducer, actions} = billingInformationSlice;

const {setBillingInformation} = actions;

export {setBillingInformation};

export const billingInformationReducer = reducer;
