import {FC} from 'react';
import {useSelector} from 'react-redux';

import {useRouter, selectStructureTemplate} from '@omnetic-dms/teas';

import {AuditSettings} from './AuditSettings';

export const InspectionSettings: FC = () => {
  const {params} = useRouter();
  const {id} = params;
  const template = useSelector(selectStructureTemplate(id as string));

  return <AuditSettings type={template?.inspectionType} templateId={template?.id} />;
};
