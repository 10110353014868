import {Menu, MenuItem} from 'platform/components';

import {MutableRefObject, useCallback, useEffect, useState} from 'react';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {FilterBlockEnum} from '../../enums/FilterBlockEnum';

interface FiltersNavigationProps extends TestIdProps {
  tilesWrapperRef: MutableRefObject<HTMLDivElement | null>;
}

export function FiltersNavigation(props: FiltersNavigationProps) {
  const [focusedSectionId, setFocusedSectionId] = useState<string | undefined>();

  const menuItems: MenuItem[] = [
    {
      id: FilterBlockEnum.seller,
      label: i18n.t('page.filters.labels.seller'),
    },
    {
      id: FilterBlockEnum.statistics,
      label: i18n.t('page.filters.labels.statistics'),
    },
    {
      id: FilterBlockEnum.makesAndModels,
      label: i18n.t('page.filters.labels.makeAndModels'),
    },
    {
      id: FilterBlockEnum.specification,
      label: i18n.t('page.filters.labels.specification'),
    },
    {
      id: FilterBlockEnum.features,
      label: i18n.t('general.labels.allFeatures'),
    },
  ];

  const handleItemClick = useCallback((itemId: string) => {
    const query = document.querySelector(`[id=${itemId}]`);
    if (query) {
      query.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    const io = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visibleBlock');
          } else {
            entry.target.classList.remove('visibleBlock');
          }
        });

        const activeNodeId = props.tilesWrapperRef.current?.querySelector('.visibleBlock')?.id;

        setFocusedSectionId(activeNodeId);
      },
      {threshold: [0, 1], root: props.tilesWrapperRef.current}
    );

    Array.from(document.querySelectorAll('#tilesWrapper > [id]')).forEach((n) => {
      io.observe(n);
    });

    return () => {
      io.disconnect();
    };
  }, [props.tilesWrapperRef]);

  return (
    <Menu
      activeItemId={focusedSectionId}
      items={menuItems}
      onItemChange={handleItemClick}
      data-testid={suffixTestId('filtersNavigation', props)}
    />
  );
}
