import {
  FlexboxJustify,
  HStack,
  Hide,
  Icon,
  Show,
  Text,
  TextProps,
  ValueByDevice,
} from 'platform/foundation';

import {Fragment, ReactNode} from 'react';

import {reject} from 'ramda';
import {isNilOrEmpty, isPositive} from 'ramda-adjunct';

import {getRandomId, DOT_CHARACTER, Nullish, suffixTestId, TestIdProps} from 'shared';

import {Tooltip} from '../Tooltip/Tooltip';
import {Parameter} from './types/Parameter';
import {isParameterWithTooltip} from './utils/isParameterWithTooltip';

export interface ParametersProps extends TestIdProps {
  parameters: Parameter[] | Nullish;
  parametersProps?: TextProps;
  color?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'white';
  size?: 'base' | 'small' | 'xSmall';
  alternative?: boolean;
  justify?: FlexboxJustify | ValueByDevice<FlexboxJustify>;
  appendix?: ReactNode;
}

export function Parameters(props: ParametersProps) {
  const size = props.size ?? 'xSmall';
  const filteredParameters = reject(isNilOrEmpty, props.parameters ?? []);
  if (!filteredParameters?.length) {
    return null;
  }

  return (
    <HStack spacing={1} data-testid={props['data-testid']} wrap justify={props.justify}>
      {filteredParameters.map((parameter, index) => {
        const hasTooltip = isParameterWithTooltip(parameter);
        const hasAutoWidth = hasTooltip ? parameter.hasAutoWidth : false;
        const shouldShowTooltipIcon = hasTooltip ? parameter.shouldShowTooltipIcon : false;
        const title = hasTooltip ? parameter.title : parameter;
        const tooltip = hasTooltip ? parameter.tooltip : null;

        return (
          <Fragment key={`${title}_${getRandomId()}`}>
            <Show when={isPositive(index)}>
              <Text color="tertiary" size={size}>
                {DOT_CHARACTER}
              </Text>
            </Show>

            <Hide when={shouldShowTooltipIcon}>
              <Tooltip label={tooltip}>
                <Text
                  data-testid={suffixTestId(`parameter-[${index.toString()}]`, props)}
                  color={props.color}
                  size={size}
                  noWrap
                  alternative={props.alternative}
                  {...props.parametersProps}
                >
                  {title}
                </Text>
              </Tooltip>
            </Hide>

            <Show when={shouldShowTooltipIcon}>
              <HStack align="center" spacing={1}>
                <Text
                  data-testid={suffixTestId(`parameter-[${index.toString()}]`, props)}
                  color={props.color}
                  size={size}
                  noWrap
                  alternative={props.alternative}
                  {...props.parametersProps}
                >
                  {title}
                </Text>

                <Show when={hasTooltip}>
                  <Tooltip placement="top" description={tooltip} hasAutoWidth={hasAutoWidth}>
                    <Icon value="action/help" size={4} color="text.secondary" />
                  </Tooltip>
                </Show>
              </HStack>
            </Show>
          </Fragment>
        );
      })}
      {props.appendix}
    </HStack>
  );
}
