import {createSelector, Selector} from '@reduxjs/toolkit';

import {cachedApi} from '@omnetic-dms/api';

import type {TeasState} from '../../types/TeasState';
import {CommonState} from './reducer';

export const selectCommon: Selector<TeasState, CommonState> = (state) => state.common;

export const selectRoundingTypes = createSelector(
  cachedApi.endpoints.getRoundType.select(),
  ({data}) => data ?? []
);
export const selectCountries = createSelector(
  cachedApi.endpoints.getCountries.select(),
  ({data}) => data ?? []
);
export const selectIdentityCardTypes = createSelector(
  cachedApi.endpoints.getIdentityCardTypes.select(),
  ({data}) => data ?? []
);
export const selectCurrencies = createSelector(
  cachedApi.endpoints.getCurrencies.select(),
  ({data}) => data ?? []
);
export const selectPhoneNumbers = createSelector(
  cachedApi.endpoints.getPhoneNumbers.select(),
  ({data}) => data ?? []
);
export const getVatRates = createSelector(cachedApi.endpoints.getVatRates.select(), ({data}) =>
  data ? data?.map((i) => ({...i, rates: i.rates?.map((r) => ({...r, value: r.type}))})) : []
);
export const selectVatRatesByCountryCode = (countryCode?: string) =>
  createSelector(getVatRates, (vatRates) => vatRates?.find(({code}) => code === countryCode));

export const selectPaymentTypes = createSelector(selectCommon, (state) => state.paymentTypes);
export const selectGenders = createSelector(
  cachedApi.endpoints.getGenders.select(),
  ({data}) => data
);
export const selectCountry = (code?: string | null) =>
  createSelector(selectCountries, (countries) => countries?.find((c) => c.code === code));

export const selectCurrency = (code?: string | null) =>
  createSelector(selectCurrencies, (currencies) => currencies?.find((c) => c.code === code));

export const selectPhoneNumber = (countryCode?: string | null) =>
  createSelector(selectPhoneNumbers, (phoneNumbers) =>
    phoneNumbers?.find((c) => c.code === countryCode)
  );

export const selectGender = (code?: string | null) =>
  createSelector(selectGenders, (genders) => genders?.find((c) => c.code === code));
