import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {VehicleSeriesDefinition} from '../../types/VehicleSeriesDefinition';
import {AsyncThunkState, getAsyncThunkReducer} from '../../utils/reduxThunkUtils';
import {
  createVehicleSeriesDefinition,
  getVehicleSeriesDefinition,
  getVehicleSeriesDefinitionList,
  updateVehicleSeriesDefinition,
} from './actions';
import {VEHICLE_SERIES_DEFINITION_SLICE_NAME} from './constants';

type VehicleSeriesDefinitionSliceType = AsyncThunkState<{
  detail?: VehicleSeriesDefinition;
  list: VehicleSeriesDefinition[];
}>;

const initialState: VehicleSeriesDefinitionSliceType = {
  list: [],
  errors: {},
  loading: {},
};

const {actions, reducer} = createSlice({
  initialState,
  name: VEHICLE_SERIES_DEFINITION_SLICE_NAME,
  reducers: {
    removeListItem: (state, {payload}: PayloadAction<string>) => {
      state.list = state.list.filter((item) => item.id !== payload);
    },
    clearDetail: (state) => {
      delete state.detail;
    },
  },
  extraReducers: (builder) => {
    const {asyncThunkReducer, handleThunkStates} = getAsyncThunkReducer(builder);

    asyncThunkReducer(getVehicleSeriesDefinitionList.action, (state, {payload}) => {
      state.list = payload;
    });

    asyncThunkReducer(getVehicleSeriesDefinition.action, (state, {payload}) => {
      state.detail = payload;
    });
    asyncThunkReducer(createVehicleSeriesDefinition.action, (state, {payload}) => {
      state.detail = payload;
    });
    asyncThunkReducer(updateVehicleSeriesDefinition.action, (state, {payload}) => {
      state.detail = payload;
    });

    handleThunkStates();
  },
});

export const vehicleSeriesDefinitionActions = actions;
export const vehicleSeriesDefinitionReducer = reducer;
