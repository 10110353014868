import {Attributes, AttributesRow, Button, Card, DataStatus, openDialog} from 'platform/components';
import {Box, Heading, HStack, Space, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {RefObject, useCallback, useState} from 'react';

import {defaultTo, head, isNil, mergeAll, not} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  GetServiceOrderIssueNoteResponse,
  useGetCustomerV2Query,
  useGetVehicleQuery,
  usePostServiceOrderItemMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {workshopRoutes} from '@omnetic-dms/routes';
import {catchUnhandledDataGridActions, getCustomerName, handleApiError} from '@omnetic-dms/shared';

import {composePath, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, DataGridRef, QueryFilterObject} from 'features/datagrid';

import {ChangeOrderDialog} from './ChangeOrderDialog';

export interface ServiceOrderTabProp extends RequiredTestIdProps {
  addedSparePartsDatagridRef: RefObject<DataGridRef>;
  serviceOrderIssueNoteId: string | Nullish;
  serviceOrderId: string | Nullish;
  serviceCaseId: string | Nullish;
  serviceOrderAttributes?: GetServiceOrderIssueNoteResponse['serviceOrder'];
  isBasketEmpty: boolean;
}
export function ServiceOrderTab(props: ServiceOrderTabProp) {
  const [serviceOrderId, setServiceOrderId] = useState(props.serviceOrderId);

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: hasVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: defaultTo('', props.serviceOrderAttributes?.vehicleId)},
    {skip: isNil(props.serviceOrderAttributes?.vehicleId)}
  );

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: hasCustomerError,
  } = useGetCustomerV2Query(
    {customerId: defaultTo('', props.serviceOrderAttributes?.customerId)},
    {skip: isNil(props.serviceOrderAttributes?.customerId)}
  );

  const [addItemToBasket] = usePostServiceOrderItemMutation();

  const isLoading = isVehicleLoading || isCustomerLoading;
  const hasError = hasVehicleError || hasCustomerError;

  const customerName = getCustomerName(customer);

  const rows: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderNumber'),
      value: props.serviceOrderAttributes?.number,
    },
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderType'),
      value: props.serviceOrderAttributes?.type,
    },
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderVariant'),
      value: props.serviceOrderAttributes?.variant,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: customerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: vehicle?.title,
    },
  ];

  const handleViewOrderInNewTab = () => {
    window.open(
      composePath(workshopRoutes.serviceCaseDetail, {
        params: {id: props.serviceCaseId},
      }),
      '_blank'
    );
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceOrderId}]),
    [serviceOrderId]
  );

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(id)) {
      throw Error('Row id must be specified');
    }

    match(actionKey)
      .with('add', () => {
        if (props.serviceOrderIssueNoteId) {
          addItemToBasket({
            serviceOrderIssueNoteId: props.serviceOrderIssueNoteId,
            serviceOrderJobItemId: id,
          })
            .unwrap()
            .then(refreshData)
            .catch(handleApiError);
        }
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const openChangeOrderDialog = () => {
    openDialog(
      <ChangeOrderDialog
        serviceOrderIssueNoteId={props.serviceOrderIssueNoteId}
        onOrderChange={setServiceOrderId}
        data-testid={suffixTestId('dialogs.changeOrder', props)}
      />,
      {
        title: i18n.t('entity.warehouse.labels.changeServiceOrder'),
        size: 'large',
      }
    );
  };

  return (
    <VStack spacing={4} height="100%">
      <HStack justify="space-between">
        <Heading size={4}>{i18n.t('entity.warehouse.labels.order')}</Heading>
        <HStack>
          <Button
            title={i18n.t('entity.warehouse.labels.changeOrder')}
            variant="ghostLink"
            leftIcon="action/autorenew"
            isDisabled={not(props.isBasketEmpty)}
            onClick={openChangeOrderDialog}
            data-testid={suffixTestId('actions.changeOrder', props)}
          />
          <Space horizontal={5} />
          <Button
            title={i18n.t('entity.warehouse.labels.viewOrder')}
            variant="link"
            leftIcon="action/open_in_new"
            onClick={handleViewOrderInNewTab}
            data-testid={suffixTestId('actions.viewOrderInNewTab', props)}
          />
        </HStack>
      </HStack>

      <Card variant="inlineGrey">
        <DataStatus isLoading={isLoading} isError={hasError}>
          <Attributes rows={rows} size="quarter" data-testid={suffixTestId('attributes', props)} />
        </DataStatus>
      </Card>

      <Heading size={4}>{i18n.t('entity.warehouse.labels.addedSpareParts')}</Heading>

      <Box flex={1}>
        <DataGrid
          // DG must be re-rendered on folder change to update the query modifier
          // eslint-disable-next-line no-restricted-syntax
          key={'issue-note-service-order-items-' + serviceOrderId}
          ref={props.addedSparePartsDatagridRef}
          gridCode="issue-note-service-order-items"
          actionCallback={actionCallback}
          queryModifier={queryModifier}
          data-testid={suffixTestId('serviceOrderList', props)}
        />
      </Box>
    </VStack>
  );
}
