import {Flag} from 'platform/components';

import {FC, PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';

import {selectAudit} from '../../../store/carAudit/selectors';
import {getStatusColorScheme} from '../utils/getStatusColorScheme';
import {getStatusTranslation} from '../utils/getStatusTranslation';

export const ConditionStatus: FC<PropsWithChildren<any>> = () => {
  const auditData = useSelector(selectAudit);
  const status = auditData?.state;

  if (!status) {
    return null;
  }

  return (
    <Flag
      colorScheme={getStatusColorScheme(status)}
      isSubtle
      label={getStatusTranslation(status)}
    />
  );
};
