import {useContext, useState, ReactNode, ReactElement, ChangeEvent} from 'react';

import {assocPath, path} from 'ramda';

import {inlineEditingContext, noop} from '@omnetic-dms/teas';

export interface InlineEditingProps<R> {
  inlineEditingTransformation: (value: R) => Record<string, unknown>;
  onChangePropPath?: string[];
  validation?: {
    validator: (x: R) => boolean | ReactNode;
    errorPropPath?: string[];
    errorMessagePropPath?: string[];
  };
  // TODO: remove after float | int allowed on the backend
  storeEditingTransformation?: (value: R) => Record<string, unknown>;
  autoOnBlur?: boolean;
  callback?: () => void;
}

type InlineEditingPropsArgument = ChangeEvent<HTMLInputElement> & Date & Record<string, unknown>;

export function withInlineEditing<T = InlineEditingPropsArgument>(
  wrappedComponent: ReactElement,
  {
    validation,
    inlineEditingTransformation,
    onChangePropPath,
    storeEditingTransformation,
    autoOnBlur,
  }: InlineEditingProps<T>
): ReactElement {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isError, setIsError] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inlineEditing = useContext(inlineEditingContext);

  const oldOnChange = path<typeof noop>(onChangePropPath || ['onChange'], wrappedComponent.props);

  const onChange = (value: ChangeEvent<HTMLInputElement>, sendNullData: boolean) => {
    const isInvalid = validation?.validator(value as any);

    if (validation && Boolean(isInvalid)) {
      if (typeof isInvalid !== 'boolean') {
        setErrorMessage(isInvalid);
      }
      setIsError(true);
      oldOnChange && oldOnChange(value);
      return;
    }

    setErrorMessage(null);
    setIsError(false);
    const inlineEditingTransformationValue = inlineEditingTransformation(value as any);

    inlineEditing
      ?.updateMethod(
        sendNullData ? null : inlineEditingTransformationValue,
        storeEditingTransformation
          ? storeEditingTransformation(value as any)
          : inlineEditingTransformationValue
      )
      ?.finally(() => {
        oldOnChange && oldOnChange(value);
      });
  };

  let propsObj = {};
  if (autoOnBlur) {
    propsObj = assocPath(['onChange'], (v: ChangeEvent<HTMLInputElement>) => onChange(v, true), {
      ...wrappedComponent.props,
    });
    propsObj = assocPath(
      ['onBlur'],
      (v: ChangeEvent<HTMLInputElement>) => onChange(v, false),
      propsObj
    );
  } else {
    propsObj = assocPath(
      onChangePropPath || ['onChange'],
      (v: ChangeEvent<HTMLInputElement>) => onChange(v, false),
      {
        ...wrappedComponent.props,
      }
    );
  }

  if (validation) {
    propsObj = assocPath(validation.errorPropPath || ['error'], isError, propsObj);
  }

  if (validation && Boolean(errorMessage)) {
    propsObj = assocPath(validation.errorMessagePropPath || ['helperText'], errorMessage, propsObj);
  }

  return {
    ...wrappedComponent,
    props: propsObj,
  };
}
