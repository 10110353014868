import {Button, ButtonGroup, Chips, Dialog} from 'platform/components';
import {Space} from 'platform/foundation';

import {VFC, useState} from 'react';

import {PurchaseVehicleType} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {Nullish} from 'shared';

import {useSelectVehicleContext} from '../../../hooks/useSelectVehicleContext';

const purchaseVehicleTypeOptions = [
  {
    label: i18n.t`entity.vehicle.labels.typeInStock`,
    value: 'IN_STOCK',
  },
  {
    label: i18n.t`entity.vehicle.labels.typeBrokerage`,
    value: 'BROKERAGE',
  },
];

type BusinessCaseSelectPurchaseVehicleTypeDialogProps = {
  handleClose: () => void;
  isOpen: boolean;
};

export const BusinessCaseSelectPurchaseVehicleTypeDialog: VFC<
  BusinessCaseSelectPurchaseVehicleTypeDialogProps
> = ({handleClose, isOpen}) => {
  const {setCreateVehicleModalOpen} = useSelectVehicleContext();

  const [purchaseVehicleType, setPurchaseVehicleType] = useState<PurchaseVehicleType>('IN_STOCK');

  const onAddVehicleToBuy = () => {
    setCreateVehicleModalOpen(true, purchaseVehicleType);
    handleClose();
  };

  const onChangePurchaseVehicleType = (purchaseVehicleType: string[] | Nullish) => {
    setPurchaseVehicleType(purchaseVehicleType?.[0] as PurchaseVehicleType);
  };

  const closeAndResetState = () => {
    handleClose();
    setCreateVehicleModalOpen(false, 'IN_STOCK');
    setPurchaseVehicleType('IN_STOCK');
  };

  return (
    <Dialog
      data-testid={testIds.businessCase.detail('purchaseVehicleType')}
      isOpen={isOpen}
      onClose={closeAndResetState}
      size="small"
      title={i18n.t('page.businessCase.labels.addPurchaseVehicle')}
    >
      <Chips
        label={i18n.t('page.businessCase.labels.selectPurchaseVehicleType')}
        data-testid={testIds.businessCase.detail('purchaseVehicleType')}
        onChange={onChangePurchaseVehicleType}
        value={[purchaseVehicleType as string]}
        options={purchaseVehicleTypeOptions}
      />

      <Space vertical={4} />
      <ButtonGroup
        align="right"
        data-testid={testIds.businessCase.detail('purchaseVehicleType-actions')}
      >
        <Button
          data-testid={testIds.businessCase.detail('purchaseVehicleType-actions-discard')}
          variant="secondary"
          onClick={closeAndResetState}
          title={i18n.t('general.actions.cancel')}
        />
        <Button
          data-testid={testIds.businessCase.detail('purchaseVehicleType-actions-confirm')}
          variant="primary"
          onClick={onAddVehicleToBuy}
          title={i18n.t('general.actions.confirm')}
        />
      </ButtonGroup>
    </Dialog>
  );
};
