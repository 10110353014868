import {Align, Box, Text} from 'platform/foundation';

interface ConsentsTableHeaderProps {
  text: string;
}

export function ConsentsTableHeader(props: ConsentsTableHeaderProps) {
  return (
    <Box paddingVertical={2} paddingRight={4}>
      <Align left>
        <Text color="secondary" size="xSmall">
          {props.text}
        </Text>
      </Align>
    </Box>
  );
}
