import {ColorSchemeType} from 'platform/components';

import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';

export const getStatusColorScheme = (status: string): ColorSchemeType => {
  switch (status) {
    case LoadAuditDataResponseItemBody.state.IN_PROGRESS: {
      return 'blue';
    }
    case LoadAuditDataResponseItemBody.state.FINISHED_AUDIT: {
      return 'green';
    }
    default: {
      return 'orange';
    }
  }
};
