import styled from 'styled-components';
import {match} from 'ts-pattern';

import {useEffect, useState} from 'react';

import {always} from 'ramda';
import {isNotNil, isPositive} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {IconButton} from '../IconButton/IconButton';
import {InputSegment} from './components/InputSegment';
import {PaginationPageGroup} from './components/PaginationPageGroup';
import {TextOnlySegment} from './components/TextOnlySegment';
import {DEFAULT_PAGE} from './constants/defaultPage';
import {PaginationVariant} from './types/PaginationVariant';

export interface PaginationProps extends TestIdProps {
  pagesQuantity: number;
  onPageChange: (page: number) => void;
  showFirstButton?: boolean;
  showPrevButton?: boolean;
  showNextButton?: boolean;
  showLastButton?: boolean;
  isDisabled?: boolean;
  defaultPage?: number;
  page?: number;
  showFirstPage?: boolean;
  showLastPage?: boolean;
  variant?: PaginationVariant;
  /**
   * @about
   * The siblingCount is number value, which says how many pages will be shown around activePage.
   */
  siblingCount?: number;
  /**
   * @about
   * The boundaryCount is number value, which says how many pages will be shown near first and last page.
   */
  boundaryCount?: number;
}

export function Pagination(props: PaginationProps) {
  const [activePage, setActivePage] = useState<number>(
    props.page ?? props.defaultPage ?? DEFAULT_PAGE
  );

  useEffect(() => {
    if (isNotNil(props.page)) {
      setActivePage(props.page);
    }
  }, [props.page]);

  const changePage = (page: number) => {
    if (!isPositive(page)) {
      setActivePage(page);
    }
    props.onPageChange(page);
  };

  const setFirstPage = () => {
    if (!isPositive(props.page)) {
      setActivePage(1);
    }
    props.onPageChange(1);
  };

  const setPrevPage = () => {
    if (activePage === 1) {
      return;
    }
    if (!isPositive(props.page)) {
      setActivePage(activePage - 1);
    }
    props.onPageChange(activePage - 1);
  };

  const setNextPage = () => {
    if (activePage === props.pagesQuantity) {
      return;
    }
    if (!isPositive(props.page)) {
      setActivePage(activePage + 1);
    }
    props.onPageChange(activePage + 1);
  };

  const setLastPage = () => {
    if (!isPositive(props.page)) {
      setActivePage(props.pagesQuantity);
    }
    props.onPageChange(props.pagesQuantity);
  };

  return (
    <StyledWrapper data-testid={suffixTestId('paginationWrapper', props)}>
      {props.showFirstButton && (
        <IconButton
          size="small"
          priority="secondary"
          icon="navigation/first_page"
          onClick={setFirstPage}
          isDisabled={props.isDisabled}
          data-testid={suffixTestId('paginationFirstButton', props)}
        />
      )}
      {props.showPrevButton && (
        <IconButton
          size="small"
          priority="secondary"
          icon="hardware/keyboard_arrow_left"
          onClick={setPrevPage}
          isDisabled={props.isDisabled}
          data-testid={suffixTestId('paginationPrevButton', props)}
        />
      )}
      {match(props.variant)
        .with(
          'input',
          always(
            <InputSegment
              changePage={changePage}
              activePage={activePage}
              pagesQuantity={props.pagesQuantity}
              isDisabled={props.isDisabled}
              data-testid={suffixTestId('paginationInput', props)}
            />
          )
        )
        .with(
          'text-only',
          always(
            <TextOnlySegment
              activePage={activePage}
              pagesQuantity={props.pagesQuantity}
              data-testid={suffixTestId('paginationTextOnly', props)}
            />
          )
        )
        .otherwise(
          always(
            <PaginationPageGroup
              activePage={activePage}
              changePage={changePage}
              isDisabled={props.isDisabled}
              siblingCount={props.siblingCount}
              boundaryCount={props.boundaryCount}
              pagesQuantity={props.pagesQuantity}
              showFirstPage={props.showFirstPage}
              showLastPage={props.showLastPage}
              data-testid={suffixTestId('paginationPageGroup', props)}
            />
          )
        )}
      {props.showNextButton && (
        <IconButton
          size="small"
          priority="secondary"
          icon="hardware/keyboard_arrow_right"
          onClick={setNextPage}
          isDisabled={props.isDisabled}
          data-testid={suffixTestId('paginationNextButton', props)}
        />
      )}
      {props.showLastButton && (
        <IconButton
          size="small"
          priority="secondary"
          icon="navigation/last_page"
          onClick={setLastPage}
          isDisabled={props.isDisabled}
          data-testid={suffixTestId('paginationLastButton', props)}
        />
      )}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: center;

  > *:not(style) ~ *:not(style) {
    margin-left: 4px;
  }
`;
