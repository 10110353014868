import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {TConditionForm} from '../../../types/ConditionTypes';
import {ParamType} from '../../../types/ParamType';
import {findByParamType} from './findByParamType';
import {getFormFieldName} from './getFormFieldName';

export const hasWheelSetData = (
  formValues: TConditionForm,
  categories?: AuditCategoryOfStructure[]
) => {
  let hasData = false;
  categories?.forEach((category) => {
    category.paramDefinitions?.relatedActions?.forEach((param) => {
      if (Boolean(formValues?.[getFormFieldName(category.id, param.id)])) {
        hasData = true;
      }
    });
    const additionalComment = findByParamType(
      category.paramDefinitions?.additionalInformation,
      ParamType.ADDITIONAL_COMMENT
    );

    if (additionalComment) {
      if (Boolean(formValues?.[getFormFieldName(category.id, additionalComment.id)])) {
        hasData = true;
      }
    }
  });

  return hasData;
};
