import * as Yup from 'yup';

import i18n from '@omnetic-dms/i18n';

import {ResetPasswordTuple} from './types';

const passwordValidation = Yup.string()
  .required(i18n.t('general.notifications.errorPasswordsRequired'))
  .min(8, i18n.t('general.notifications.errorPasswordsLength'))
  .matches(/^(?:[A-Z]|[a-z]|[0-9]|.){8,}$/, {
    message: i18n.t('general.notifications.errorPasswordsFormat'),
  })
  .matches(/[A-Z]/, {
    message: i18n.t('general.notifications.errorPasswordsUppercase'),
  })
  .matches(/[a-z]/, {
    message: i18n.t('general.notifications.errorPasswordsLowercase'),
  })
  .matches(/[0-9]+/, {
    message: i18n.t('general.notifications.errorPasswordsDigit'),
  });

const SPECIFIC_FIELD_REQUIRED_MESSAGE = (fieldName: string) =>
  `${fieldName} ${i18n.t('general.notifications.errorSpecFieldRequired')}`;

const stringRequiredValidation = (field: string): Yup.StringSchema =>
  Yup.string().required(SPECIFIC_FIELD_REQUIRED_MESSAGE(field) as string);

const confirmPassword = stringRequiredValidation('Confirm password').oneOf(
  [Yup.ref('password'), null],
  i18n.t('general.notifications.errorPasswordsNotMatch')
);

export const ResetPasswordValidationSchema = Yup.object().shape({
  password: passwordValidation,
  passwordNewConfirm: confirmPassword,
}) as Yup.SchemaOf<ResetPasswordTuple>;
