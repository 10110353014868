export const DOCX_MIME_TYPE =
  '.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const PDF_MIME_TYPE = 'application/pdf';

export const JPEG_MIME_TYPE = 'image/jpeg';

export const PNG_MIME_TYPE = 'image/png';

export const GIF_MIME_TYPE = 'image/gif';
