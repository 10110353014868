import {isNotNil} from 'ramda-adjunct';

import {getApiDateString} from 'shared';

export const getRegistrationDate = (data: {
  firstRegistrationOnDay?: string | number | null;
  firstRegistrationOnMonth?: string | number | null;
  firstRegistrationOnYear?: string | number | null;
}) =>
  isNotNil(data?.firstRegistrationOnDay) &&
  isNotNil(data?.firstRegistrationOnMonth) &&
  isNotNil(data?.firstRegistrationOnYear)
    ? getApiDateString(
        new Date(
          Number(data.firstRegistrationOnYear),
          Number(data.firstRegistrationOnMonth) - 1,
          Number(data.firstRegistrationOnDay)
        )
      )
    : undefined;
