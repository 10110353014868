import {sub} from 'date-fns';
import {Button, HelperText, IconButton, Label, NumberInput, Tooltip} from 'platform/components';
import {Box, HStack, Icon, Show, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {ChangeEvent} from 'react';
import {DeepMap, FieldError} from 'react-hook-form';

import {defaultTo, remove, update} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {DatePicker, TextField, TextFieldWithValueFormat} from '@omnetic-dms/teas';

import {Nullish} from 'shared';

import {ItemType} from '../types/ItemType';
import {getEmptyItem} from '../utils/getEmptyItem';
import {ButtonWrapper, ItemGrayBox, TooltipWrapper} from './styles';

interface ItemProps {
  item: ItemType;
  nextItem: ItemType | null;
  onCancelClick: () => void;
  onChange: (name: string, value: unknown) => void;
  errors?: DeepMap<ItemType, FieldError>;
  disableDelete?: boolean;
}

function Item({item, onCancelClick, onChange, errors, nextItem, disableDelete}: ItemProps) {
  const formatDateTime = useDateTimeFormatter();

  const handleFromChange = (value: Date) => {
    const name = 'from';
    onChange(name, value);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    onChange(name, value);
  };

  const handleStartingNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = defaultTo('', parseInt(e.target.value));

    onChange(name, value);
  };

  const handlePatternLengthChange2 = (patternLength: number | null) => {
    onChange('length', String(patternLength ?? ''));
  };

  const format = (value: string) => {
    const patternLength = parseInt(item.length, 10);
    const prefix = item.prefix.trim();
    const padLength = patternLength - prefix.length;
    return prefix + `${value}`.padStart(padLength, '0');
  };

  const hasTooltip = !!(nextItem && nextItem.from);

  const getDateRange = (from: Date | Nullish, nextFrom: Date | Nullish) => {
    if (!from || !nextFrom) {
      return null;
    }

    const to = sub(nextFrom, {days: 1});
    return `${formatDateTime('dateShort', from)} - ${formatDateTime('dateShort', to)}`;
  };

  return (
    <Box paddingVertical={1} width="100%">
      <HStack spacing={4}>
        <Box flex={1}>
          <TextField
            name="prefix"
            onChange={handleChange}
            value={item.prefix}
            error={!!errors?.prefix}
            helperText={errors?.prefix?.message}
            disabled={item.state !== 'not_used'}
            data-testid="general-series-document-form-fild-prefix"
          />
        </Box>

        <Box flex={2}>
          <TextFieldWithValueFormat
            {...(errors?.startingNumber
              ? {error: true, helperText: errors.startingNumber.message}
              : {})}
            name="startingNumber"
            onChange={handleStartingNumberChange}
            value={item.startingNumber.toString()}
            format={format}
            disabled={item.state !== 'not_used'}
            data-testid="general-series-document-form-fild-startingNumber"
          />
        </Box>

        <Box flex={1}>
          <VStack>
            <NumberInput
              name="length"
              isStepperVisible
              onChange={handlePatternLengthChange2}
              value={Number(item.length)}
              isDisabled={item.state !== 'not_used'}
              data-testid="general-series-document-form-fild-length"
            />
            <HelperText errorMessage={errors?.length?.message} />
          </VStack>
        </Box>

        <Box flex={2}>
          <HStack spacing={1}>
            <DatePicker
              name="from"
              onChange={handleFromChange}
              value={item.from?.toISOString()}
              disablePast
              validation={
                errors?.from
                  ? {
                      valid: false,
                      messages: [errors?.from?.message],
                    }
                  : undefined
              }
              disabled={item.state !== 'not_used'}
              data-testid="general-series-document-form-fild-from"
            />

            <IconButton
              priority="tertiary"
              icon="action/delete"
              onClick={onCancelClick}
              isDisabled={item.state !== 'not_used' || disableDelete}
              data-testid={testIds.settings.vehicleSeriesDefinition('deleteButton')}
            />
            <Box width={6}>
              <Show when={hasTooltip}>
                <Tooltip label={getDateRange(item.from, nextItem?.from)}>
                  <Box paddingVertical={1}>
                    <TooltipWrapper>
                      <Icon value="action/info_outline" />
                    </TooltipWrapper>
                  </Box>
                </Tooltip>
              </Show>
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
}

interface ItemsEditorProps {
  onChange: (items: ItemType[]) => void;
  value: ItemType[];
  errors: DeepMap<ItemType, FieldError>[];
}

export function ItemsEditor({onChange: setItems, errors, value: items}: ItemsEditorProps) {
  const addItem = () => setItems([...items, getEmptyItem()]);
  const removeItem = (index: number) => {
    if (items.length === 1) {
      setItems([getEmptyItem()]);
      return;
    }

    setItems(remove(index, 1, items));
  };

  const updateItem = (index: number, name: keyof ItemType, value: ItemType[keyof ItemType]) => {
    const item = {...items[index], [name]: value};
    const newItems = update(index, item, items);

    setItems(newItems);
  };

  return (
    <ItemGrayBox>
      <HStack spacing={4}>
        <Box flex={1}>
          <Label>{i18n.t('entity.documentSeries.item.prefix')}</Label>
        </Box>

        <Box flex={2}>
          <Label>{i18n.t('entity.documentSeries.item.startingNumber')}</Label>
        </Box>

        <Box flex={1}>
          <Label>{i18n.t('entity.documentSeries.item.length')}</Label>
        </Box>

        <Box flex={2}>
          <Label>{i18n.t('entity.documentSeries.item.from')}</Label>
        </Box>
      </HStack>
      {items?.map((item, key) => (
        <Item
          item={item}
          nextItem={items[key + 1] || null}
          key={item.id}
          disableDelete={items.length === 1}
          onCancelClick={removeItem.bind(null, key)}
          onChange={updateItem.bind(null, key) as ItemProps['onChange']}
          errors={Array.isArray(errors) ? errors[key] : undefined}
        />
      ))}
      <ButtonWrapper>
        <Button
          data-testid={testIds.settings.vehicleSeriesDefinition('addButton')}
          variant="link"
          leftIcon="content/add_circle"
          onClick={addItem}
          title={i18n.t('entity.documentSeries.items.addSeries')}
        />
      </ButtonWrapper>
    </ItemGrayBox>
  );
}
