import {isNil} from 'ramda';

import {Nullish} from 'shared';

export function formatPercentage(value: number | Nullish) {
  if (isNil(value)) {
    return;
  }

  return `${Math.round(value * 100)} %`;
}
