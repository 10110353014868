import {createUseGesture, dragAction, pinchAction, WebKitGestureEvent} from '@use-gesture/react';
import {useDevice} from 'platform/foundation';
import {css} from 'styled-components';
import {match} from 'ts-pattern';

import {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {always, isNil, isNotNil} from 'ramda';

import {RequiredTestIdProps, useDebouncedCallback} from 'shared';

import {ZoomRef} from '../types/ZoomRef';

const useGesture = createUseGesture([dragAction, pinchAction]);

/**
 * Firefox image drag workaround
 * @see https://bugzilla.mozilla.org/show_bug.cgi?id=1376369
 */
if (window.navigator.userAgent.toLowerCase().includes('firefox')) {
  document.querySelectorAll('img[draggable=false]').forEach((el) => {
    el.addEventListener('mousedown', (event) => event.preventDefault());
  });
}

interface ZoomProps extends RequiredTestIdProps {
  renderSlide: (slideRef: ForwardedRef<HTMLImageElement>) => ReactNode;
}

const ZoomComponent: ForwardRefRenderFunction<ZoomRef, ZoomProps> = (props, ref) => {
  const scaleRef = useRef<number | null>(null);
  const scaleOnPinchStartRef = useRef<number | null>(null);

  const widthRef = useRef<number | null>(null);
  const heightRef = useRef<number | null>(null);

  const originXRef = useRef<number | null>(null);
  const originYRef = useRef<number | null>(null);

  const pointerOriginXRef = useRef<number | null>(null);
  const pointerOriginYRef = useRef<number | null>(null);

  const scrollXRef = useRef<number | null>(null);
  const scrollYRef = useRef<number | null>(null);

  const slideRef = useRef<HTMLImageElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [cursor, setCursor] = useState<'zoom-in' | 'zoom-out'>('zoom-in');

  const debouncedSetZoomInCursor = useDebouncedCallback(
    () => setCursor('zoom-in'),
    DEBOUNCE_DELAY,
    DEBOUNCE_WAIT
  );
  const debouncedSetZoomOutCursor = useDebouncedCallback(
    () => setCursor('zoom-out'),
    DEBOUNCE_DELAY,
    DEBOUNCE_WAIT
  );

  const device = useDevice();

  const onZoomChange = (value: number) => {
    let scale = value;

    if (scale < MIN_SCALE) {
      scale = MIN_SCALE;
    }
    if (scale > MAX_SCALE) {
      scale = MAX_SCALE;
    }
    if (scale === MIN_SCALE && device !== 'mobile' && device !== 'tablet') {
      debouncedSetZoomInCursor();
    }
    if (scale > MIN_SCALE && device !== 'mobile' && device !== 'tablet') {
      debouncedSetZoomOutCursor();
    }

    scaleRef.current = scale;

    if (isNil(widthRef.current)) {
      widthRef.current = slideRef.current?.scrollWidth ?? null;
    }
    if (isNil(heightRef.current)) {
      heightRef.current = slideRef.current?.scrollHeight ?? null;
    }

    if (isNotNil(widthRef.current)) {
      slideRef.current?.style?.setProperty('width', `${scale * widthRef.current}px`);
    }
    if (isNotNil(heightRef.current)) {
      slideRef.current?.style?.setProperty('height', `${scale * heightRef.current}px`);
    }

    const boundingRect = slideRef.current?.getBoundingClientRect();

    const containerScrollXAxis =
      (originXRef.current ?? 0.5) * (boundingRect?.width ?? 0) - (pointerOriginXRef.current ?? 0);
    const containerScrollYAxis =
      (originYRef.current ?? 0.5) * (boundingRect?.height ?? 0) - (pointerOriginYRef.current ?? 0);

    containerRef.current?.scrollTo({
      top: containerScrollYAxis,
      left: containerScrollXAxis,
      behavior: 'instant',
    });
  };

  useImperativeHandle(ref, () => ({
    zoomIn: () => {
      const currentScale = scaleRef.current ?? MIN_SCALE;

      if (currentScale >= MAX_SCALE) {
        return;
      }

      /**
       * First click should center pointer to the middle of the slide.
       */
      if (currentScale === MIN_SCALE) {
        const boundingRect = slideRef.current?.getBoundingClientRect();
        pointerOriginXRef.current = (originXRef.current ?? 0.5) * (boundingRect?.width ?? 0);
        pointerOriginYRef.current = (originYRef.current ?? 0.5) * (boundingRect?.height ?? 0);
      }

      /*
       * If user didn't drag the mouse and doesn't have the axis set, set it to the center.
       */
      if (isNil(originXRef.current)) {
        originXRef.current = 0.5;
      }
      if (isNil(originYRef.current)) {
        originYRef.current = 0.5;
      }

      const scale = match(currentScale)
        .with(MIN_SCALE, always(ZOOM_CLICK_SCALE))
        .with(ZOOM_CLICK_SCALE, always(ZOOM_SECOND_STEP))
        .with(ZOOM_SECOND_STEP, always(MAX_SCALE))
        .otherwise(always(currentScale));

      onZoomChange(scale);
    },
    zoomOut: () => {
      const currentScale = scaleRef.current ?? MIN_SCALE;

      if (currentScale <= MIN_SCALE) {
        return;
      }

      /*
       * If user didn't drag the mouse and doesn't have the axis set, set it to the center.
       */
      if (isNil(originXRef.current)) {
        originXRef.current = 0.5;
      }
      if (isNil(originYRef.current)) {
        originYRef.current = 0.5;
      }

      const scale = match(currentScale)
        .with(MAX_SCALE, always(ZOOM_SECOND_STEP))
        .with(ZOOM_SECOND_STEP, always(ZOOM_CLICK_SCALE))
        .with(ZOOM_CLICK_SCALE, always(MIN_SCALE))
        .otherwise(always(currentScale));

      onZoomChange(scale);
    },
  }));

  useEffect(() => {
    const handler = (e: Event) => e.preventDefault();
    document.addEventListener('gesturestart', handler);
    document.addEventListener('gesturechange', handler);
    document.addEventListener('gestureend', handler);
    return () => {
      document.removeEventListener('gesturestart', handler);
      document.removeEventListener('gesturechange', handler);
      document.removeEventListener('gestureend', handler);
    };
  }, []);

  useGesture(
    {
      onDragStart: ({pinching, cancel}) => {
        if (pinching) {
          return cancel();
        }

        scrollXRef.current = containerRef.current?.scrollLeft ?? 0;
        scrollYRef.current = containerRef.current?.scrollTop ?? 0;
      },
      onDrag: ({pinching, cancel, xy, initial}) => {
        if (pinching) {
          return cancel();
        }
        containerRef.current?.scrollTo(
          (scrollXRef.current ?? 0) - (xy[0] - initial[0]),
          (scrollYRef.current ?? 0) - (xy[1] - initial[1])
        );
      },
      onPinchStart: ({origin: [ox, oy]}) => {
        const rect = slideRef.current?.getBoundingClientRect();

        pointerOriginXRef.current = ox;
        pointerOriginYRef.current = oy;

        const imgX = -1 * (rect?.left ?? 0) + ox;
        const imgY = -1 * (rect?.top ?? 0) + oy;

        const imgXPercentage = imgX / (rect?.width ?? 1);
        const imgYPercentage = imgY / (rect?.height ?? 1);

        originXRef.current = imgXPercentage;
        originYRef.current = imgYPercentage;

        scaleRef.current = scaleOnPinchStartRef.current =
          (rect?.width ?? 0) / (containerRef?.current?.offsetWidth ?? 0);
      },
      onPinch: ({event}) => {
        const currentScale = scaleRef.current ?? MIN_SCALE;

        const scale = isWebKitGestureEvent(event)
          ? (scaleOnPinchStartRef.current ?? 1) * event.scale
          : // @ts-ignore
            currentScale * (1 + (event?.deltaY ?? 0) * -0.01);

        onZoomChange(scale);
      },
      onClick: ({event, moving, dragging, wheeling, pinching, scrolling}) => {
        if (
          moving ||
          dragging ||
          wheeling ||
          pinching ||
          scrolling ||
          device === 'mobile' ||
          device === 'tablet'
        ) {
          return;
        }

        const rect = slideRef.current?.getBoundingClientRect();

        pointerOriginXRef.current = event.clientX;
        pointerOriginYRef.current = event.clientY;

        originXRef.current = event.clientX / (rect?.width ?? 1);
        originYRef.current = event.clientY / (rect?.height ?? 1);

        const scale =
          isNil(scaleRef.current) || scaleRef.current === MIN_SCALE ? ZOOM_CLICK_SCALE : MIN_SCALE;

        onZoomChange(scale);
      },
    },
    {
      target: containerRef,
      pinch: {scaleBounds: {min: MIN_SCALE, max: MAX_SCALE}},
      drag: {filterTaps: true},
    }
  );

  return (
    <div
      ref={containerRef}
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        cursor: ${device !== 'mobile' && device !== 'tablet' ? cursor : 'auto'};

        overflow: scroll;
        inset: 0;

        max-width: none;
        max-height: none;
        will-change: transform;
        user-select: none;
        -webkit-user-select: none;
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        touch-action: none;
        -webkit-touch-action: none;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none; /* Webkit based browsers */
        }
      `}
      draggable={false}
    >
      {props.renderSlide(slideRef)}
    </div>
  );
};

const DEBOUNCE_DELAY = 200;
const DEBOUNCE_WAIT = 1000;
const MIN_SCALE = 1;
const MAX_SCALE = 20;
const ZOOM_CLICK_SCALE = 3;
const ZOOM_SECOND_STEP = 10;

const isWebKitGestureEvent = (event: Event): event is WebKitGestureEvent => 'scale' in event;

export const Zoom = forwardRef(ZoomComponent);
