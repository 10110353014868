import {isTrue} from 'ramda-adjunct';

export const evaluateBranch = (
  scopes: string[],
  evaluatorParams: {myBranches: string[] | undefined; currentBranchId: string}
) => {
  const {currentBranchId, myBranches} = evaluatorParams;

  // scopes is array at maximal size of 2, so this check is fine
  if (scopes.includes('ALL_BRANCHES')) {
    return true;
  }
  if (scopes.includes('MY_BRANCHES')) {
    return isTrue(myBranches?.includes(currentBranchId));
  }

  return false;
};
