import {
  Button,
  ButtonGroup,
  Dialog,
  Attributes,
  AttributesRow,
  showNotification,
} from 'platform/components';
import {Space, Text, Box} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {FC, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';

import {useApiDispatch} from '../../../hooks/useApiDispatch';
import {syncInspectionToCondition} from '../../../store/carAudit/actions';
import {selectActiveAuditId, selectCarAudit} from '../../../store/carAudit/selectors';

const errorCodesFieldNameMap: Record<string, string> = {
  VIN_REGEX_RULE: i18n.t('entity.vehicle.labels.vin'),
  MANUFACTURED_ON_LESS_THAN_OR_EQUAL_FIRST_REGISTRATION_RULE: String(
    i18n.t('entity.vehicle.labels.firstRegistration')
  ),
};

export const UpdateCondition: FC = () => {
  const apiDispatch = useApiDispatch();
  const formatDateTime = useDateTimeFormatter();
  // @ts-ignore
  const [validationErrors, setValidationErrors] = useState([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const auditId = useSelector(selectActiveAuditId);
  const {loading} = useSelector(selectCarAudit);

  const updateConditionData = async (): Promise<void> => {
    try {
      await apiDispatch(syncInspectionToCondition.action, {
        requestBody: {
          inspectionId: auditId ?? '',
        },
      });
      showNotification.success([
        i18n.t('entity.inspection.notifications.conditionWasUpdatedSuccessfully'),
        formatDateTime('timeShort', new Date()),
      ]);
    } catch (e: any) {
      const errors = e?.response?.data?.errors;
      if (errors?.length) {
        setValidationErrors(errors);
      }
    } finally {
      setIsConfirmDialogOpen(false);
    }
  };

  const handleCloseDialog = () => {
    setValidationErrors([]);
  };

  const dialogErrorRows: AttributesRow[] = useMemo(
    () =>
      validationErrors.map(({code, message}) => ({
        label: errorCodesFieldNameMap[code] ?? null,
        content: (
          <Text color="danger" size="xSmall">
            {message}
          </Text>
        ),
      })),
    [validationErrors]
  );

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setIsConfirmDialogOpen(true)}
        title={i18n.t('entity.condition.actions.updateCondition')}
      />

      <Dialog
        title={i18n.t('entity.condition.labels.updateConflicts')}
        isOpen={!!dialogErrorRows.length}
        onClose={handleCloseDialog}
      >
        <Text size="small" color="secondary">
          {i18n.t('entity.condition.notifications.conditionCouldNotBeUpdated')}
        </Text>

        <Box paddingTop={6}>
          <Box borderTop="1px solid" borderColor="palettes.neutral.30.100">
            {dialogErrorRows.length === 1 && !dialogErrorRows[0].label ? (
              <Box paddingTop={2}>
                <Text color="danger">{dialogErrorRows[0].value}</Text>
              </Box>
            ) : (
              <Attributes rows={dialogErrorRows} />
            )}
          </Box>
        </Box>

        <Space vertical={4} />
        <ButtonGroup align="right">
          <Button onClick={handleCloseDialog} title={i18n.t('general.actions.gotIt')} />
        </ButtonGroup>
      </Dialog>

      <Dialog
        size="small"
        isOpen={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        title={i18n.t('entity.condition.labels.conditionUpdateConfirm')}
      >
        <Space vertical={4} />
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            onClick={() => setIsConfirmDialogOpen(false)}
            title={i18n.t('general.actions.cancel')}
          />
          <Button
            isLoading={loading.syncInspectionToCondition}
            onClick={updateConditionData}
            title={i18n.t('general.actions.update')}
          />
        </ButtonGroup>
      </Dialog>
    </>
  );
};
