import {getAllFlags} from './getAllFlags';

export const isFeatureEnabled = (whenEnabled: string) => {
  const flags = getAllFlags();

  if (!flags) {
    return false;
  }

  return flags?.[whenEnabled]?.enabled ?? false;
};
