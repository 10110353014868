import {RoundStepEnum, RoundTypeEnum} from '@omnetic-dms/teas';

import {createDefinitionFormCtx} from './DefinitionFormContext';
import {DocumentRoundingDefinitionFormType} from './types';

type OptionType = {
  key: string;
  value: string;
};

type DocumentRoundingDefinitionFormCtxType = {
  branches: OptionType[];
  paymentTypes: OptionType[];
  roundingTypes: OptionType[];
  roundings: OptionType[];
};

export const roundingDefinitionCtx = createDefinitionFormCtx<
  DocumentRoundingDefinitionFormCtxType,
  DocumentRoundingDefinitionFormType
>({branches: [], paymentTypes: [], roundingTypes: [], roundings: []});

export const emptyValidityItem = (
  date?: string
): DocumentRoundingDefinitionFormType['validities'][0] => ({
  roundStep: RoundStepEnum._0_01,
  roundType: RoundTypeEnum.ALGEBRAIC,
  taxRounding: false,
  validFrom: date ?? new Date().toISOString(),
});
