import {DefaultComponentsTheme} from 'platform/components';

import {StylesConfig} from 'react-select';

type StylesOption<DDOptionType> = DDOptionType & {
  id?: string;
  __isNew__?: boolean;
};

export const customStyles = <DDOptionType, isMultiT extends boolean>(
  theme: DefaultComponentsTheme,
  isMulti?: boolean
): StylesConfig<StylesOption<DDOptionType>, isMultiT> => ({
  control: (provided, state) => ({
    ...provided,
    minHeight: 'auto',
    maxHeight: isMulti ? 'none' : '32px',
    cursor: 'pointer',
    backgroundColor: theme.colors.palettes.white[10][100],
    border: `1px solid ${
      state.isFocused ? theme.colors.palettes.blue[60][100] : theme.colors.palettes.neutral[70][100]
    }`,
    boxShadow: state.isFocused ? `0 0 0 4px ${theme.colors.palettes.blue[20][100]}` : 'none',
    opacity: state.isDisabled ? 0.5 : 1,
    ':hover': {
      backgroundColor: theme.colors.palettes.white[10][100],
      borderColor: theme.colors.palettes.blue[60][100],
    },
    '.react-select__single-value': {
      lineHeight: '25px',
      width: 'calc(100% - 16px)',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '2px 8px',
    lineHeight: '12px',
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: provided.marginLeft,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.colors.palettes.neutral[80][100],
    marginRight: 4,
    marginLeft: 4,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,

    '& > div': {
      transition: 'none',
      padding: '5px 6px 5px',
    },
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: '2px',
    paddingBottom: '2px',
    marginLeft: provided.marginLeft,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: !state.isFocused ? 'transparent' : theme.colors.palettes.neutral[900][100],
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,

    ':hover': {
      color: theme.colors.palettes.neutral[900][100],
    },
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 4,
    border: `1px solid ${theme.colors.palettes.neutral[40][100]}`,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
  }),
  groupHeading: (provided) => ({
    ...provided,
    color: 'initial',
    textTransform: 'none',
    marginBottom: theme.getSize(1),
  }),
  group: (provided) => ({
    ...provided,
    '&:last-child': {
      paddingBottom: 0,
    },
  }),
  option: (provided, state) => {
    let isSelected = state.getValue()[0]?.id === state.data?.id;
    let color = state.isDisabled
      ? theme.colors.palettes.neutral[50][100]
      : theme.colors.palettes.neutral[900][100];

    if (state.data?.id === undefined) {
      isSelected = false;
    }

    if ((!state.isDisabled && isSelected) || state.data.__isNew__) {
      color = theme.colors.palettes.blue[60][100];
    }

    return {
      ...provided,
      padding: '5px 12px',
      cursor: state.isDisabled ? 'auto' : 'pointer',
      color,
      backgroundColor: isSelected ? theme.colors.palettes.blue[20][100] : 'transparent',

      ':hover': {
        backgroundColor: theme.colors.palettes.neutral[20][100],
      },
    };
  },
  noOptionsMessage: (provided) => ({
    ...provided,
    padding: '5px 12px',
  }),
  clearIndicator: (provided, state) => {
    const defaultColor = theme.colors.palettes.neutral[900][100];
    return {
      ...provided,
      color: defaultColor,

      ':hover': {
        color: defaultColor,
      },
    };
  },
});
