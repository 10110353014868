export enum SeriesTypeEnum {
  WORKSHOP_SERVICE_ORDER = 'workshop/service_order',
  WORKSHOP_SERVICE_JOB = 'workshop/service_job',
  NEW_AND_USED_CARS_BUSINESS_CASE = 'new_and_used_cars/business_case',
  ACCOUNTING_INVOICE = 'accounting/invoice',
  ACCOUNTING_CASH_REGISTER_DOCUMENT = 'accounting/cash_register_document',
  ACCOUNTING_CASH_REGISTER_CLOSURE = 'accounting/cash_register_closure',
  WAREHOUSE_RECEIPT = 'warehouse/receipt',
  WAREHOUSE_DISPENSE = 'warehouse/dispense',
  WAREHOUSE_ORDER = 'warehouse/order',
  WAREHOUSE_REQUEST = 'warehouse/request',
  WAREHOUSE_INVENTORY = 'warehouse/inventory',
  GENERAL_COMPLAINT = 'general/complaint',
  GENERAL_CONTRACT = 'general/contract',
  GENERAL_EXPORT_DOCUMENT = 'general/export_document',
  GENERAL_VEHICLE = 'general/vehicle',
  GENERAL_CUSTOMER = 'general/customer',
  INTEREST_INTEREST = 'interest/interest',
}
