import {FormControl} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {Path} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ReceiveNoteCorrectionItemForm} from '../types/ReceiveNoteCorrectionItemForm';
import {ReceiveNoteCorrectionItemAfterRow} from './ReceiveNoteCorrectionItemAfterRow';
import {ReceiveNoteCorrectionItemBeforeRow} from './ReceiveNoteCorrectionItemBeforeRow';
import {ReceiveNoteCorrectionItemDifferenceRow} from './ReceiveNoteCorrectionItemDifferenceRow';

interface ReceiveNoteItemFormProps extends RequiredTestIdProps {
  control: FormControl<ReceiveNoteCorrectionItemForm>;
  receiveUnit: string;
  receiveCurrency: string;
  onQuantityChange: (
    value: number | null,
    changedField: Path<ReceiveNoteCorrectionItemForm>
  ) => boolean;
}

export function ReceiveNoteItemForm(props: ReceiveNoteItemFormProps) {
  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <Heading size={5}>{i18n.t('entity.warehouse.labels.receiveNoteItem')}</Heading>
      <VStack spacing={4}>
        <ReceiveNoteCorrectionItemDifferenceRow
          control={props.control}
          formContext="receiveItem"
          unit={props.receiveUnit}
          currency={props.receiveCurrency}
          data-testid={suffixTestId('rows.difference', props)}
        />
        <ReceiveNoteCorrectionItemAfterRow
          control={props.control}
          formContext="receiveItem"
          unit={props.receiveUnit}
          currency={props.receiveCurrency}
          onQuantityChange={props.onQuantityChange}
          data-testid={suffixTestId('rows.afterCorrection', props)}
        />
        <ReceiveNoteCorrectionItemBeforeRow
          control={props.control}
          formContext="receiveItem"
          unit={props.receiveUnit}
          currency={props.receiveCurrency}
          data-testid={suffixTestId('rows.beforeCorrection', props)}
        />
      </VStack>
    </VStack>
  );
}
