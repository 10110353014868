import {all, equals, has, keys} from 'ramda';

type Obj = Record<string, any>;

/**
 * Checks if every property in the first object is also present in the second object.
 *
 * @param {object} obj1 - The object containing the properties to check for.
 * @param {object} obj2 - The object to check for the presence of the properties.
 * @returns {boolean} Returns `true` if every property in `obj1` is present in `obj2` and its equal, otherwise `false`.
 *
 * @example
 * const obj1 = { a: 1, b: 2 };
 * const obj2 = { a: 1, b: 2, c: 5 };
 * console.log(objectHasEveryPropOfAnother(obj1, obj2));  // true
 *
 * @example
 * const obj1 = { a: 1, b: 2 };
 * const obj2 = { a: 3, b: 4, c: 5 };
 * console.log(objectHasEveryPropOfAnother(obj1, obj2));  // false
 *
 * @example
 * const obj1 = { a: 1, b: 2, d: 4 };
 * const obj2 = { a: 3, b: 4, c: 5 };
 * console.log(objectHasEveryEqualPropOfAnother(obj1, obj2));  // false
 */
export const objectHasEveryEqualPropOfAnother = (obj1: Obj, obj2: Obj): boolean =>
  all((key) => has(key, obj2) && equals(obj1[key], obj2[key]), keys(obj1));
