import {HStack} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {
  selectErrorCategories,
  selectExterior,
  selectExteriorOverallEvaluation,
  selectInterior,
  selectInteriorOverallEvaluation,
  selectMechanicsOverallEvaluation,
} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditParamValue} from '../../../types/AuditParamValue';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {getTranslation} from '../utils/getTranslation';
import {OverallEvaluation, OverallEvaluationProps} from './OverallEvaluation';

export const OverallEvaluations: FC = (props: TestIdProps) => {
  const locale = useSelector(selectUserSelectedLanguage);
  const errorCategories = useSelector(selectErrorCategories);
  const exteriorEvaluation = useSelector(selectExteriorOverallEvaluation);
  const interiorEvaluation = useSelector(selectInteriorOverallEvaluation);
  const mechanicsEvaluation = useSelector(selectMechanicsOverallEvaluation);

  const exterior = useSelector(selectExterior);
  const interior = useSelector(selectInterior);

  const evaluationCategories = [exteriorEvaluation, interiorEvaluation, mechanicsEvaluation];

  const getLabel = (category?: AuditCategoryOfStructure) => {
    const isMandatory = category?.paramDefinitions?.relatedActions?.[0].mandatory;

    switch (category?.uniqueKey) {
      case AuditCategoryUniqueKey.OVERALL_EVALUATION_OF_THE_EXTERIOR:
        return exterior
          ? `${isMandatory ? '*' : ''} ${getTranslation(locale, exterior.name)}`
          : null;
      case AuditCategoryUniqueKey.OVERALL_EVALUATION_OF_THE_INTERIOR:
        return interior
          ? `${isMandatory ? '*' : ''} ${getTranslation(locale, interior.name)}`
          : null;
      case AuditCategoryUniqueKey.OVERALL_EVALUATION_OF_THE_TECHNICAL_CONDITION:
        return `${isMandatory ? '*' : ''} ${i18n.t('entity.condition.labels.mechanics')}`;
      default:
        return null;
    }
  };

  const getOptions = (category?: AuditCategoryOfStructure): AuditParamValue[] | undefined =>
    category?.paramDefinitions?.relatedActions?.[0].values;

  const getName = (category?: AuditCategoryOfStructure) => {
    const paramDefinition = category?.paramDefinitions?.relatedActions?.[0];
    if (!paramDefinition || !category) {
      return null;
    }

    return `${category.id}_${paramDefinition.id}`;
  };

  const overallEvaluations: OverallEvaluationProps[] = [];

  evaluationCategories.forEach((category) => {
    const name = getName(category);
    const label = getLabel(category);
    const options = getOptions(category);
    const hasError = !!category?.id && !!errorCategories?.[category?.id];

    if (name && label && options) {
      overallEvaluations.push({
        label,
        options,
        name,
        hasError,
      });
    }
  });

  return (
    <HStack spacing={3}>
      {overallEvaluations.map((evaluation, index) => (
        <OverallEvaluation
          key={evaluation.label}
          {...evaluation}
          data-testid={suffixTestId(`overallEvaluation-${evaluation.label.trim()}`, props)}
        />
      ))}
    </HStack>
  );
};
