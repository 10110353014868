import {useEffect} from 'react';

import {isNil, reject, uniq} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  getVehicleMakes,
  getCustomTenantCatalogue,
  getVehicleSeriesDefinitionList,
  useThunkDispatch,
} from '@omnetic-dms/teas';

import {useBoolean} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {VehicleSeriesDefinitionList} from './components/VehicleSeriesDefinitionList';
import {vehicleSeriesDefinitionCommonActions} from './utils';

export function VehicleSeriesDefinition() {
  const dispatch = useThunkDispatch();

  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  useEffect(() => {
    startLoading();

    dispatch(getVehicleSeriesDefinitionList.action({}))
      .unwrap()
      .then((definitionList) => {
        const vehicleTypes = reject(
          isNil,
          uniq(
            definitionList
              .map(({vehicleType}) => vehicleType)
              .filter((type) => typeof type === 'string')
          )
        );

        Promise.all([
          dispatch(getCustomTenantCatalogue.action({})),
          ...vehicleSeriesDefinitionCommonActions.map((action) => dispatch(action)),
          ...vehicleTypes?.map((vehicleType) => dispatch(getVehicleMakes.action({vehicleType}))),
        ]).finally(() => {
          stopLoading();
        });
      });
  }, [dispatch, startLoading, stopLoading]);

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{title: i18n.t('page.salesSettings.labels.vehicleSeriesDefinitionTitle')}}
      data-testid={testIds.settings.vehicleSeriesDefinition('modal')}
      description={i18n.t('page.salesSettings.labels.vehicleSeriesDefinitionDescription')}
    >
      <VehicleSeriesDefinitionList />
    </SettingsTemplate>
  );
}
