import {z} from 'zod';

import {BaseIdSchema} from './base';

/**
 * GET
 */

/**
 * POST
 */
export const PostServiceOrderItemRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
  serviceOrderJobItemId: z.string(),
});
export type PostServiceOrderItemRequest = z.infer<typeof PostServiceOrderItemRequestSchema>;

export const PostServiceOrderItemResponseSchema = BaseIdSchema;
export type PostServiceOrderItemResponse = z.infer<typeof PostServiceOrderItemResponseSchema>;

export const PostWarehouseArticleItemRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
  body: z.object({
    articleId: z.string(),
  }),
});
export type PostWarehouseArticleItemRequest = z.infer<typeof PostWarehouseArticleItemRequestSchema>;

export const PostWarehouseArticleItemResponseSchema = BaseIdSchema;
export type PostWarehouseArticleItemResponse = z.infer<
  typeof PostWarehouseArticleItemResponseSchema
>;

/**
 * PATCH
 */

/**
 * DELETE
 */
