import styled from 'styled-components';

import {Button, TypographyCard} from '@omnetic-dms/teas';

export const WrapperCard = styled(TypographyCard)`
  margin: ${({theme}) => theme.getSize(4)} 0;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonSave = styled(Button)`
  margin: ${({theme}) => theme.getSize(2)};
`;
