import {BreadcrumbType, Form, FormSubmitHandler} from 'platform/components';
import {object} from 'yup';

import {isNil, prop} from 'ramda';

import {useGetTemplateQuery, usePatchTemplateMutation} from '@omnetic-dms/api';
import {i18n} from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {useNavigate, yupString} from 'shared';

import {SettingsTemplate} from '../../../components/SettingsTemplate/SettingsTemplate';
import {contextOptions} from '../constants/contextOptions';
import {MessageTemplateFormValues} from '../types/MessageTemplateFormValues';
import {MessageTemplateFields} from './MessageTemplateFields';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.messageTemplates.labels.list'),
    href: settingsRoutes.messageTemplates,
  },
];

type Props = {
  templateType: 'sms' | 'email';
  messageTemplateId: string;
};

export const EditMessageTemplateForm = (props: Props) => {
  const navigate = useNavigate();

  const {
    data: messageTemplate,
    isLoading: isLoadingEditData,
    isError: isErrorEditData,
  } = useGetTemplateQuery(
    {
      templateId: props.messageTemplateId || '',
      templateType: props.templateType,
    },
    {
      skip: isNil(props.messageTemplateId),
    }
  );

  const [patchTemplateMutation, {isLoading: isMutationInProgress}] = usePatchTemplateMutation();
  const redirectToMessageTemplatesOverview = () => navigate(settingsRoutes.messageTemplates);

  const onSubmit: FormSubmitHandler<MessageTemplateFormValues> = async (
    data: MessageTemplateFormValues
  ) => {
    await patchTemplateMutation({
      templateId: props.messageTemplateId,
      templateType: props.templateType,
      patchTemplateRequestBody: {
        ...data,
      },
    })
      .unwrap()
      .catch(handleApiError)
      .then(redirectToMessageTemplatesOverview);
  };

  return (
    <SettingsTemplate
      header={{
        breadcrumbs,
        title: i18n.t('page.messageTemplates.labels.edit', {
          templateType: i18n.t(`page.messageTemplates.labels.${props.templateType}`),
          templateName: messageTemplate?.name,
        }),
      }}
      isLoading={isLoadingEditData}
      isError={isErrorEditData}
      description={i18n.t('page.messageTemplates.labels.newDescription')}
      data-testid={testIds.settings.messageTemplates(`${props.templateType}edit`)}
    >
      <Form<MessageTemplateFormValues>
        schema={props.templateType === 'sms' ? schemaSMS : schemaEmail}
        defaultValues={messageTemplate as MessageTemplateFormValues}
        onSubmit={onSubmit}
      >
        {(control, formApi) => (
          <MessageTemplateFields
            formApi={formApi}
            control={control}
            templateType={props.templateType}
            isMutationInProgress={isMutationInProgress}
          />
        )}
      </Form>
    </SettingsTemplate>
  );
};

const schemaSMS = object({
  name: yupString.required(),
  context: yupString.required().oneOf(contextOptions.map(prop('value'))),
  body: yupString.required(),
});

const schemaEmail = schemaSMS.shape({
  subject: yupString.required(),
});
