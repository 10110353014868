import {Checkbox} from 'platform/components';

import {useGetTenantSettingValuesQuery, useSetTenantSettingValueMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const CANCEL_EXPIRED_RESERVATIONS_SETTINGS_KEY = 'sale_vehicle-cancel_expired_reservations';

export function Reservation() {
  const {data, isLoading} = useGetTenantSettingValuesQuery({
    settingId: CANCEL_EXPIRED_RESERVATIONS_SETTINGS_KEY,
  });
  const [updateUserSettings] = useSetTenantSettingValueMutation();

  const onCheckboxChange = (value: boolean) => {
    updateUserSettings({
      settingId: CANCEL_EXPIRED_RESERVATIONS_SETTINGS_KEY,
      valueRequestBody: {value, force: true},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const value = data?.[0]?.value ?? false;

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.reservationSettings.labels.pageTitle')}}
      description={i18n.t('page.reservationSettings.labels.description')}
      data-testid={testIds.settings.reservation('layout')}
      isLoading={isLoading}
    >
      <Checkbox
        value={value}
        label={i18n.t('page.reservationSettings.labels.checkboxToggle')}
        onChange={onCheckboxChange}
      />
    </SettingsTemplate>
  );
}
