import {FC, useRef, useContext, useEffect} from 'react';

import {ContextMenu} from './ContextMenu';
import {ContextMenuContext} from './ContextMenuProvider';
import {ContextMenuWrapper} from './styles';
import {ContextMenuWithContextProps} from './types';
import {closeMenu} from './utils/closeMenu';
import {openMenu} from './utils/openMenu';

export const ContextMenuWithContext: FC<ContextMenuWithContextProps> = ({
  id,
  children,
  contextMenuProps,
  ...rest
}) => {
  const {onClose, ...menuProps} = contextMenuProps;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const context = useContext(ContextMenuContext);

  const handleContextMenu = (e: MouseEvent) => {
    if (context) {
      openMenu(e, context, id);
    }
  };

  useEffect(() => {
    const _wrapper = wrapperRef.current;
    _wrapper?.addEventListener('contextmenu', handleContextMenu);

    return () => {
      _wrapper?.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <ContextMenuWrapper {...rest} ref={wrapperRef}>
      <ContextMenu
        open={!!context?.menuState && context.menuState?.id === id}
        onClose={() => {
          if (context) {
            closeMenu(context);
          }
          if (onClose) {
            onClose();
          }
        }}
        position={context?.menuState ?? undefined}
        {...menuProps}
      />
      {children}
    </ContextMenuWrapper>
  );
};
