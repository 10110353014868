import {FormControl, FormField} from 'platform/components';
import {Grid} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {AccountingDocumentFormValues} from '../types';

interface InvoiceDetailsProps extends TestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
}

export function PaymentDatesDetail(props: InvoiceDetailsProps) {
  return (
    <Grid columns={4}>
      <FormField
        control={props.control}
        name="issueDate"
        type="apiDate"
        label={i18n.t('entity.accounting.labels.issueDate')}
        isRequired
        isDisabled
        data-testid={suffixTestId('issueDate', props)}
      />
      <FormField
        control={props.control}
        name="dueSinceIssueDateInDays"
        type="number"
        label={i18n.t('entity.accounting.labels.due')}
        isRequired
        isDisabled
        data-testid={suffixTestId('dueSinceIssueDateInDays', props)}
      />
      <FormField
        control={props.control}
        name="dueDate"
        type="apiDate"
        label={i18n.t('entity.accounting.labels.dueDate')}
        isRequired
        isDisabled
        data-testid={suffixTestId('dueDate', props)}
      />
      <FormField
        control={props.control}
        name="dateOfTaxableSupply"
        type="apiDate"
        label={i18n.t('entity.accounting.labels.dots')}
        isRequired
        isDisabled
        data-testid={suffixTestId('dateOfTaxableSupply', props)}
      />
    </Grid>
  );
}
