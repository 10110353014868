import {useSelector} from 'react-redux';

import {filter, keys, last, map, pipe, split, values} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {AuditDataAssetsFilesBody} from '@omnetic-dms/api';

import {AuditAsset, AuditAssets} from '../../../store/carAudit/reducerUtils';
import {selectAudit, selectAuditAssets} from '../../../store/carAudit/selectors';
import {getParsedValue} from '../utils/getParsedValue';
import {useConditionUploadContext} from './useConditionUploadContext';

export function useAuditImages() {
  const {
    uploadImages,
    categoryId,
    paramDefinitionId,
    damageId,
    softDeletedImages,
    uploadedImagesIds,
  } = useConditionUploadContext();

  const audit = useSelector(selectAudit);
  const paramAssets = useSelector(selectAuditAssets(categoryId, paramDefinitionId));

  const lightboxAssets = pipe(
    (category: AuditAssets) =>
      values(category).flatMap((item: AuditAsset, i) =>
        values(item).map((file) => ({
          ...file,
          meta: last(split('_', keys(category)[i] ?? '')) ?? '',
        }))
      ),
    filter((item: AuditDataAssetsFilesBody) => isNotNil(item.imageId)),
    map((asset: AuditDataAssetsFilesBody) => ({
      id: asset.imageId ?? asset.uploadedAssetsId,
      url: asset.url,
      resizeUrl: asset.resizeUrl,
      meta: asset.meta,
    }))
  )(audit?.assets ?? {});

  const lightboxUploadImages = uploadImages.map((image) => ({
    id: image.imageId,
    url: image.src.replace('resize', 'get'),
    meta: image.file.type,
    title: image.title,
    fileName: image.file.name,
  }));

  const assets = paramAssets
    ?.filter((file) => {
      if (file.imageId && softDeletedImages.includes(file.imageId)) {
        return false;
      }

      if (file.imageId && uploadImages?.some((image) => image.imageId === file.imageId)) {
        return false;
      }

      const isUploadedImage = !file.imageId || !uploadedImagesIds.includes(file.imageId);
      if (damageId) {
        const imageMeta = getParsedValue(file.meta);

        return isUploadedImage && imageMeta?.damageId === damageId;
      }

      return isUploadedImage;
    })
    ?.map((asset) => ({...asset, src: asset.url}));

  return {assets, lightboxUploadImages, lightboxAssets};
}
