import {ThemeIconKey, Icon, Show, Hide, Center, getSize, Severity} from 'platform/foundation';
import RcUpload from 'rc-upload';
import {
  Action,
  RcFile,
  UploadProps as RcUploadProps,
  UploadRequestMethod,
  UploadRequestOption,
} from 'rc-upload/es/interface';
import styled, {css} from 'styled-components';
import {match} from 'ts-pattern';

import {useEffect, useState} from 'react';

import {always, defaultTo, map, not, split, trim} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {Button} from '../Button/Button';
import {IconButton, IconButtonPriority} from '../IconButton/IconButton';
import {ProgressBar} from '../ProgressBar/ProgressBar';
import {useTranslationContext} from '../TranslationProvider/TranslationContext';
import {UploadState} from './UploadState';

export type UploadType = 'button' | 'card' | 'iconButton';
export type UploadButtonVariant = 'secondary' | 'ghostLink' | 'errorLink';
export type OnUploadError = NonNullable<RcUploadProps['onError']>;
export type OnUploadSuccess = NonNullable<RcUploadProps['onSuccess']>;
export type OnBeforeUpload = NonNullable<RcUploadProps['beforeUpload']>;
export type CustomRequestOption = UploadRequestOption;

export class UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField extends Error {}

export type File = {
  name: string;
  status: UploadState;
  url: string;
};

export interface UploadProps<T extends UploadType = UploadType> extends TestIdProps {
  /**
   * @about
   * Uploading URL
   */
  action?: Action;
  /**
   * @about
   * Request method
   */
  method?: UploadRequestMethod;
  /**
   * @about
   * File types that can be accepted
   */
  accept?: string;
  /**
   * @about
   * If set true, component will validate file types according to accept property
   * before upload, and if file is not of appropriate file type, it will reject it
   * */
  shouldValidateAccept?: boolean;
  /**
   * @about
   * Whether to support selected multiple file.
   */
  isMultiple?: boolean;
  /**
   * @about
   * The file upload will be disabled.
   */
  isDisabled?: boolean;
  /**
   * @about
   * The current state of upload ("Idle" | "Uploading" | "Success" | "Error").
   */
  uploadState?: UploadState;
  /**
   * @about
   * The type of upload ("button" | "card").
   */
  type?: T;
  uploadText?: string;
  uploadingText?: string;
  name?: string;
  size?: 'default' | 'minHeight';
  uploadIcon?: ThemeIconKey;
  uploadingIcon?: ThemeIconKey;
  errorIcon?: ThemeIconKey;
  buttonVariant?: UploadButtonVariant;
  /**
   * @about
   * Provide an override for the default xhr behavior for additional customization
   */
  customRequest?: (option: CustomRequestOption) => void;
  onStart?: (file: RcFile) => void;
  onError?: OnUploadError;
  onSuccess?: OnUploadSuccess;
  beforeUpload?: OnBeforeUpload;
}

export function Upload<T extends UploadType = 'card'>(props: UploadProps<T>) {
  const t = useTranslationContext();

  const [internalUploadState, setInternalUploadState] = useState<UploadState>(UploadState.Idle);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  useEffect(() => {
    if (props.uploadState) {
      setInternalUploadState(props.uploadState);
    }
  }, [props.uploadState]);

  const isUploading = internalUploadState === UploadState.Uploading;
  const hasError = internalUploadState === UploadState.Error;

  const isCard = props.type === 'card';
  const isButton = props.type === 'button';
  const isIconButton = props.type === 'iconButton';

  const size = props.size ?? 'default';

  const uploadingIcon = defaultTo('action/watch_later', props.uploadingIcon);
  const uploadIcon = defaultTo('file/upload', props.uploadIcon);
  const errorIcon = defaultTo('alert/error', props.errorIcon);

  const uploadText = defaultTo(t('general.actions.upload'), props.uploadText);
  const uploadingText = defaultTo(t('general.labels.uploading'), props.uploadingText);

  const handleMouseEnter = () => setIsHovering(true);

  const handleMouseLeave = () => setIsHovering(false);

  const handleOnStart = (file: RcFile) => {
    setInternalUploadState(UploadState.Uploading);
    props.onStart?.(file);
  };

  const handleOnSuccess: RcUploadProps['onSuccess'] = (response, file, xhr) => {
    props.onSuccess?.(response, file, xhr);
    setInternalUploadState(UploadState.Success);
  };

  const handleOnError: RcUploadProps['onError'] = (error, ret, file) => {
    props.onError?.(error, ret, file);
    setInternalUploadState(UploadState.Error);
  };

  const handleValidateByAccept = (file: RcFile, files: RcFile[]) =>
    validateFileByAccept(props.accept, handleOnError, file)
      ? props.beforeUpload?.(file, files) || file
      : false;

  const beforeUpload = !props.shouldValidateAccept ? props.beforeUpload : handleValidateByAccept;

  return (
    <StyledUploadWrapper
      $isCard={isCard}
      $isDisabled={props.isDisabled}
      $size={size}
      $hasError={hasError}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDragEnter={handleMouseEnter}
      onDragLeave={handleMouseLeave}
      data-testid={suffixTestId('uploadWrapper', props)}
    >
      <RcUpload
        // We need to use style prop here to be able to style the upload component properly
        // eslint-disable-next-line react/forbid-component-props
        style={
          isCard
            ? {
                width: '100%',
                height: '100%',
                minHeight: size === 'minHeight' ? getSize(27) : 'initial',
                minWidth: size === 'minHeight' ? getSize(27) : 'initial',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: size === 'default' ? getSize(2) : '0',
                cursor: props.isDisabled ? 'not-allowed' : 'pointer',
              }
            : {}
        }
        action={props.action}
        method={props.method}
        accept={props.accept}
        multiple={props.isMultiple}
        customRequest={props.customRequest}
        onStart={handleOnStart}
        onSuccess={handleOnSuccess}
        onError={handleOnError}
        beforeUpload={beforeUpload}
        disabled={props.isDisabled}
        data-testid={suffixTestId('rcUpload', props)}
      >
        <Show when={isButton}>
          <Button
            variant={props.buttonVariant}
            leftIcon={isUploading ? uploadingIcon : uploadIcon}
            isDisabled={props.isDisabled || isUploading}
            data-testid={suffixTestId('uploadButton', props)}
            title={isUploading ? uploadingText : uploadText}
          />
        </Show>
        <Show when={isIconButton}>
          <IconButton
            icon={isUploading ? uploadingIcon : uploadIcon}
            priority={match<UploadButtonVariant | undefined, IconButtonPriority>(
              props.buttonVariant
            )
              .with('secondary', always('secondary'))
              .with('ghostLink', always('default'))
              .with('errorLink', always('default'))
              .otherwise(always('primary'))}
            severity={match<UploadButtonVariant | undefined, Severity | undefined>(
              props.buttonVariant
            )
              .with('secondary', always(undefined))
              .with('ghostLink', always('informational'))
              .with('errorLink', always('danger'))
              .otherwise(always('informational'))}
            isDisabled={props.isDisabled || isUploading}
            data-testid={suffixTestId('uploadIconButton', props)}
          />
        </Show>
        <Hide when={isButton || isIconButton}>
          <Show when={isUploading}>
            <div
              css={css`
                width: 100%;
                height: 100%;
                display: flex;
                padding: ${size === 'default' ? '10px 0px' : '0 10px'};
                flex-direction: column;
                justify-content: center;
                align-items: center;
              `}
            >
              <ProgressBar indeterminate small />
            </div>
          </Show>
          <Show when={hasError && not(isHovering)}>
            <Center width="100%" height="100%" justify="center">
              <Icon value={errorIcon} color="severity.danger" size={6} />
            </Center>
          </Show>
          <Hide when={isUploading || (hasError && not(isHovering))}>
            <Center width="100%" height="100%" justify="center">
              <Icon value={uploadIcon} size={6} />
            </Center>
          </Hide>
        </Hide>
      </RcUpload>
    </StyledUploadWrapper>
  );
}

const StyledUploadWrapper = styled.div<{
  $isCard?: boolean;
  $isDisabled?: boolean;
  $hasError?: boolean;
  $size?: 'default' | 'minHeight';
}>`
  ${(props) =>
    props.$isCard &&
    props.$size === 'minHeight' &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: ${({theme}) => theme.getSize(27)};
      min-height: ${({theme}) => theme.getSize(27)};
    `}
  ${(props) =>
    props.$isCard &&
    // Using font-size intentionally, because we need to have the font-size set to all
    // text content, both for uploading state and also for error state
    // eslint-disable-next-line eag/no-css-property
    css`
      width: 100%;
      height: 100%;
      font-size: ${({theme}) => theme.fontSizes.text.xSmall};
      font-weight: ${({theme}) => theme.fontWeights.regular};
      line-height: ${({theme}) => theme.lineHeights.text.xSmall};
      background: ${({theme}) => theme.colors.palettes.white[10][100]};
      border-width: 1px;
      border-style: dashed;
      border-image: initial;
      border-color: ${({theme}) => theme.colors.palettes.neutral[80][100]};
      border-radius: ${({theme}) => theme.radii.xSmall};
    `}

  ${(props) =>
    props.$isCard &&
    not(props.$isDisabled) &&
    css`
      &:hover {
        background: ${({theme}) => theme.colors.palettes.blue[10][100]};
        border-color: ${({theme}) => theme.colors.palettes.blue[60][100]};
        color: ${({theme}) => theme.colors.palettes.blue[80][100]};
      }
    `}

  ${(props) =>
    props.$isDisabled &&
    css`
      border-color: ${({theme}) => theme.colors.palettes.neutral[80][40]};
      color: ${({theme}) => theme.colors.palettes.neutral[80][40]};
    `}

  ${(props) =>
    props.$hasError &&
    css`
      border-style: solid;
      border-color: ${({theme}) => theme.colors.palettes.red[60][100]};
    `}
`;

const validateFileByAccept = (
  accept: string | undefined,
  onError: OnUploadError | undefined,
  file: RcFile
) => {
  if (!accept) {
    return file;
  }

  const acceptedFileTypes = map(trim, split(',', accept));
  if (acceptedFileTypes.includes(file.type)) {
    return file;
  }

  onError?.(new UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField(), {}, file);

  // Stops upload.
  return false;
};
