import {Pagination} from 'platform/components';

import {useDetailPagination} from '../hooks/useDetailPagination';
import {DataGridSearchParams} from '../hooks/useUrlSync';

export type RowControlProps = DataGridSearchParams & {
  onChange: (entityIndex: string, sourceSearchParams: string) => void;
  count: number;
};
export const RowControl = (props: RowControlProps) => {
  const {rowIndex, isInvalid, onPageChange} = useDetailPagination(props.onChange);

  if (isInvalid) {
    return null;
  }

  return (
    <Pagination
      variant="text-only"
      showPrevButton
      showNextButton
      page={rowIndex}
      pagesQuantity={props.count}
      onPageChange={onPageChange}
    />
  );
};
