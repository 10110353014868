import {
  useMemo,
  useRef,
  useContext,
  useEffect,
  createContext,
  useCallback,
  PropsWithChildren,
  ReactElement,
  Context,
} from 'react';
import {FieldValues} from 'react-hook-form';

import {noop} from 'ramda-adjunct';

import {
  DefinitionFormCtxType,
  DefinitionFormMethodsCtxType,
  CreateDefinitionFormCtxReturnType,
} from './types';

export const createDefinitionFormCtx = <T extends Record<string, unknown>, M extends FieldValues>(
  defaults: T
): CreateDefinitionFormCtxReturnType<T, M> => {
  const definitionFormCtx = createContext<DefinitionFormCtxType<T>>({
    edit: false,
    ...defaults,
  });
  const definitionFormMethodsCtx = createContext<DefinitionFormMethodsCtxType<M>>({});

  const useDefinitionCtx: CreateDefinitionFormCtxReturnType<T, M>['useDefinitionCtx'] = () => {
    const createdCtx: Context<DefinitionFormCtxType<T>> = definitionFormCtx;

    const ctx = useContext(createdCtx);

    return ctx;
  };

  const DefinitionFormProvider = ({
    children,
    onCancel,
    onSubmit,
    onSubmitError,
    ...props
  }: PropsWithChildren<
    DefinitionFormCtxType<T> & DefinitionFormMethodsCtxType<M>
  >): ReactElement => {
    const definitionCtx: Context<DefinitionFormCtxType<T>> = definitionFormCtx;
    const definitionMethodsCtx = definitionFormMethodsCtx as Context<
      DefinitionFormMethodsCtxType<M>
    >;
    const _props = props as DefinitionFormCtxType<T>;

    return (
      <definitionCtx.Provider value={{..._props}}>
        <definitionMethodsCtx.Provider value={{onSubmitError, onSubmit, onCancel}}>
          {children}
        </definitionMethodsCtx.Provider>
      </definitionCtx.Provider>
    );
  };

  return {
    Provider: DefinitionFormProvider,
    useDefinitionCtx,
    useDefinitionMethodsCtx: () => {
      const createdMethodsCtx = definitionFormMethodsCtx as Context<
        DefinitionFormMethodsCtxType<M>
      >;

      const methods = useRef<DefinitionFormMethodsCtxType<M>>({});
      const {onSubmitError, onCancel, onSubmit} = useContext(createdMethodsCtx);

      useEffect(() => {
        methods.current.onCancel = onCancel ?? noop;
        methods.current.onSubmit = onSubmit ?? noop;
        methods.current.onSubmitError = onSubmitError ?? noop;
      }, [onCancel, onSubmit, onSubmitError]);

      const handleCancel = useCallback(() => {
        methods.current.onCancel?.();
      }, []);
      const handleSubmit = useCallback<Required<DefinitionFormMethodsCtxType<M>>['onSubmit']>(
        async (...args) => {
          await methods.current.onSubmit?.(...args);
        },
        []
      );
      const handleSubmitError = useCallback<
        Required<DefinitionFormMethodsCtxType<M>>['onSubmitError']
      >((...args) => {
        methods.current.onSubmitError?.(...args);
      }, []);

      return useMemo(
        () => ({
          onCancel: handleCancel,
          onSubmit: handleSubmit,
          onSubmitError: handleSubmitError,
        }),
        [handleCancel, handleSubmit, handleSubmitError]
      );
    },
    useContextWithPath: (path) => {
      const ctx = useDefinitionCtx();

      const memoDependency = ctx[path];

      return useMemo(() => memoDependency, [memoDependency]);
    },
  };
};
