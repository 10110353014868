import {ICellRenderer} from '@ag-grid-community/core';
import {Tooltip} from 'platform/components';
import {Text} from 'platform/foundation';
import {CurrencyFormat, useFormatCurrency} from 'platform/locale';

import {useState, forwardRef, Ref, useImperativeHandle} from 'react';

import {equals, isNil, join, pipe, split} from 'ramda';
import {isNotNilOrEmpty, mapIndexed} from 'ramda-adjunct';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {ICellRendererParams} from '../../types/AgGridTypes';
import {Style} from '../../types/Api';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getCellClassName} from '../../utils/getCellClassName';
import {getStylesToRender} from '../../utils/getStylesToRender';
import {getUnformattedValue} from '../../utils/getUnformattedValue';
import {getValueToRender} from '../../utils/getValueToRender';

type MoneyCellValue = {
  amount: number;
  currency: string;
};

export interface MoneyWithoutDecimalCellRendererProps extends ICellRendererParams {
  getTooltipProps?: TooltipGetter;
}

function MoneyWithoutDecimalCellRendererComponent(
  props: MoneyWithoutDecimalCellRendererProps,
  ref: Ref<ICellRenderer>
) {
  const {getTooltipProps, data, node, colDef} = props;
  const className = getCellClassName(colDef);
  const [cellValue, setCellValue] = useState<MoneyCellValue>(() => getUnformattedValue(props));
  const [cellStyles, setCellStyles] = useState<Style>(() => getStylesToRender(props));
  const formatCurrency = useFormatCurrency();

  useImperativeHandle(ref, () => ({
    refresh: (refreshProps) => {
      const newCellValue = getValueToRender(refreshProps);
      if (newCellValue !== cellValue) {
        setCellValue(newCellValue);
      }
      const newCellStyles = getStylesToRender(refreshProps);
      if (!equals(newCellStyles, cellStyles)) {
        setCellStyles(newCellStyles);
      }
      return true;
    },
  }));

  const tooltipProps = useTooltipProps(data, node, getTooltipProps);

  const value = isNotNilOrEmpty(cellValue?.amount)
    ? formatCurrency(cellValue?.amount, cellValue?.currency, 2)
    : null;

  if (isNil(value)) {
    return null;
  }

  const removeDecimal = (val: string, index: number): string => {
    if (index === 1) {
      return val.slice(2);
    }

    return val;
  };

  const formatMoney = pipe<string[], [string, string], [string, string], string>(
    // @ts-ignore
    split(','),
    mapIndexed(removeDecimal),
    join(' ')
  );

  const formattedMoney = formatMoney(value) as string;

  return (
    <Tooltip {...tooltipProps} label={formattedMoney}>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className={className}>
        <Text
          color={cellStyles.textColor}
          noWrap={!colDef?.wrapText}
          size="small"
          overflowWrap={colDef?.wrapText ? 'anywhere' : undefined}
        >
          <CurrencyFormat currency={cellValue.currency} number={cellValue.amount} decimals={0} />
        </Text>
      </div>
    </Tooltip>
  );
}

export const MoneyWithoutDecimalCellRenderer = forwardRef<
  ICellRenderer,
  MoneyWithoutDecimalCellRendererProps
>(MoneyWithoutDecimalCellRendererComponent);
