import {object, string} from 'yup';

import {ScopeValues} from '@omnetic-dms/api';

export const evaluateInspectionType = (scopes: string[], evaluatorParams: ScopeValues) => {
  const {inspectionType} = validateInspectionEvaluatorParams(evaluatorParams);

  return scopes.includes(inspectionType);
};

export const validateInspectionEvaluatorParams = (evaluatorParams: ScopeValues | null) =>
  inspectionParamsSchema.validateSync(evaluatorParams);

const inspectionParamsSchema = object({
  inspectionType: string().required(),
});
