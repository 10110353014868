export const sortByAsciiNumberLast = (a: string, b: string) => {
  const labelA = a.toLowerCase();
  const labelB = b.toLowerCase();
  const aStartsWithNumber = /^[0-9]/.test(labelA);
  const bStartsWithNumber = /^[0-9]/.test(labelB);
  if (aStartsWithNumber && !bStartsWithNumber) {
    return 1;
  }
  if (!aStartsWithNumber && bStartsWithNumber) {
    return -1;
  }
  return labelA.localeCompare(labelB);
};
