import {addMonths, isAfter, isSameMonth, startOfMonth, startOfToday} from 'date-fns';

export function getInitialActiveMonths(
  value: null | [Date, Date],
  minDate?: Date,
  maxDate?: Date
): [Date, Date] {
  if (!value) {
    const from = minDate ? startOfMonth(minDate) : startOfMonth(startOfToday());

    return [from, maxDate ? startOfMonth(maxDate) : addMonths(from, 1)];
  }

  if (isAfter(value[0], value[1])) {
    // eslint-disable-next-line no-console
    console.warn('Date range is in reverse order.');
  }

  const start = startOfMonth(value[0]);
  return [start, isSameMonth(start, value[1]) ? addMonths(start, 1) : startOfMonth(value[1])];
}
