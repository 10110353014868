import {getRandomId} from 'shared';

import {
  AclScopeOptionResponseBody,
  AclScopeResponseBody,
  GrantActionPermissionApiArg,
  GrantActionPermissionApiResponse,
  GrantAllPermissionApiArg,
  GrantAllPermissionApiRes,
  GrantFieldPermissionApiArg,
  GrantFieldPermissionApiResponse,
  GrantPermissionApiArg,
  GrantPermissionApiResponse,
  GrantResourcePermissionApiArg,
  GrantResourcePermissionApiResponse,
  ListPermissionExpressionApiArg,
  ListPermissionExpressionApiResponse,
  ListPermissionsApiArg,
  ListPermissionsApiResponse,
  ListPermissionsV2ApiArg,
  ListPermissionsV2ApiResponse,
  ListProtectedUnitsApiArg,
  ListProtectedUnitsApiResponse,
  ListResourcesApiArg,
  ListResourcesApiResponse,
  ListResourceScopeOptionsApiArg,
  ListResourceScopeOptionsApiResponse,
  ListUserAllowedRecordActionsApiArg,
  ListUserAllowedRecordActionsApiResponse,
  ListUserAllowedResourceActionsApiArg,
  ListUserAllowedResourceActionsApiResponse,
  PermissionV2ResponseBody,
  RevokeAllPermissionApiArg,
  RevokeAllPermissionApiRes,
  RevokePermissionApiArg,
  RevokePermissionApiResponse,
  RevokeResourcePermissionApiArg,
  RevokeResourcePermissionApiRes,
  TransformedAclScopeResponseBody,
  TransformedListResourcesApiResponse,
  TransformedListResourceScopeOptionsApiResponse,
  TransformedResourceResponseBody,
  UpdateActionPermissionApiArg,
  UpdateActionPermissionApiRes,
  UpdateFieldPermissionApiArg,
  UpdateFieldPermissionApiRes,
  UpdateProtectedUnitPermissionV2ApiArg,
  UpdateProtectedUnitPermissionV2ApiResponse,
} from '../types/accessControlList';
import {omneticApi} from './baseApi/omneticApi';

export const accessControlListApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    listResources: build.query<TransformedListResourcesApiResponse, ListResourcesApiArg>({
      query: () => ({
        url: `/dms/v1/acl/resource`,
      }),
      transformResponse: (
        response: ListResourcesApiResponse
      ): TransformedListResourcesApiResponse => {
        function transformResources(
          resources: ListResourcesApiResponse
        ): TransformedResourceResponseBody[] {
          return resources.map(
            (resource) =>
              ({
                ...resource,
                subResources: transformResources(resource.subResources),

                scopes: resource.scopes.map(
                  (scope: AclScopeResponseBody) =>
                    ({
                      value: scope.id,
                      label: scope.name,
                    }) as TransformedAclScopeResponseBody
                ),
              }) as TransformedResourceResponseBody
          );
        }

        return transformResources(response);
      },
    }),
    listResourceScopeOptions: build.query<
      TransformedListResourceScopeOptionsApiResponse,
      ListResourceScopeOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/acl/resource/${queryArg.resourceId}/scope/${queryArg.scopeId}/options`,
      }),

      transformResponse: (
        response: ListResourceScopeOptionsApiResponse
      ): TransformedListResourceScopeOptionsApiResponse =>
        response.map((option: AclScopeOptionResponseBody) => ({
          value: option.id,
          label: option.name,
        })),
    }),
    listPermissions: build.query<ListPermissionsApiResponse, ListPermissionsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/acl/permission`,
        params: {roleId: queryArg.roleId},
      }),
      providesTags: ['permissions'],
    }),
    grantActionPermission: build.mutation<
      GrantActionPermissionApiResponse,
      GrantActionPermissionApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/acl/permission/action`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({actionId, resourceId, roleId}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          accessControlListApi.util.updateQueryData('listPermissionsV2', {roleId}, (draft) => {
            const additionalItem: PermissionV2ResponseBody = {
              protectedUnit: {id: actionId, name: ''},
              group: {id: resourceId, name: ''},
              roleId,
              scopes: [],
              id: getRandomId(),
            };

            return [...draft, additionalItem];
          })
        );

        await queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: ['permissions'],
    }),
    grantFieldPermission: build.mutation<
      GrantFieldPermissionApiResponse,
      GrantFieldPermissionApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/acl/permission/field`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['permissions'],
    }),
    updateFieldPermission: build.mutation<UpdateFieldPermissionApiRes, UpdateFieldPermissionApiArg>(
      {
        query: (body) => ({
          url: `/dms/v1/acl/permission/field`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['permissions'],
      }
    ),
    updateActionPermission: build.mutation<
      UpdateActionPermissionApiRes,
      UpdateActionPermissionApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/acl/permission/action`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['permissions'],
    }),
    grantResourcePermission: build.mutation<
      GrantResourcePermissionApiResponse,
      GrantResourcePermissionApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/acl/permission/resource`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['permissions'],
    }),
    revokeAllPermission: build.mutation<RevokeAllPermissionApiRes, RevokeAllPermissionApiArg>({
      query: (params) => ({
        url: `/dms/v1/acl/permission/all`,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['permissions'],
    }),
    grantAllPermission: build.mutation<GrantAllPermissionApiRes, GrantAllPermissionApiArg>({
      query: (body) => ({
        url: `/dms/v1/acl/permission/all`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['permissions'],
    }),
    revokeResourcePermission: build.mutation<
      RevokeResourcePermissionApiRes,
      RevokeResourcePermissionApiArg
    >({
      query: (params) => ({
        url: `/dms/v1/acl/permission/resource`,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['permissions'],
    }),
    revokePermission: build.mutation<RevokePermissionApiResponse, RevokePermissionApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/acl/permission/${queryArg.id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({id, roleId}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          accessControlListApi.util.updateQueryData('listPermissionsV2', {roleId}, (draft) =>
            draft.filter((item) => item.id !== id)
          )
        );

        await queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: ['permissions'],
    }),
    listUserAllowedResourceActions: build.query<
      ListUserAllowedResourceActionsApiResponse,
      ListUserAllowedResourceActionsApiArg
    >({
      query: () => ({
        url: `/dms/v1/acl/user/allowed-actions/resource`,
      }),
    }),
    listUserAllowedRecordActions: build.query<
      ListUserAllowedRecordActionsApiResponse,
      ListUserAllowedRecordActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/acl/user/allowed-actions/resource/${queryArg.resourceId}/record/${queryArg.recordId}`,
      }),
    }),
    listPermissionsV2: build.query<ListPermissionsV2ApiResponse, ListPermissionsV2ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/acl/permission`,
        params: {roleId: queryArg.roleId},
      }),
      providesTags: ['permissions'],
    }),
    grantPermission: build.mutation<GrantPermissionApiResponse, GrantPermissionApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/acl/permission`,
        method: 'POST',
        body: queryArg.grantPermissionRequestBody,
      }),
      invalidatesTags: ['permissions'],
    }),
    updateProtectedUnitPermission: build.mutation<
      UpdateProtectedUnitPermissionV2ApiResponse,
      UpdateProtectedUnitPermissionV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/acl/permission/${queryArg.permissionId}`,
        method: 'PUT',
        body: queryArg.updateActionPermissionRequestBody,
      }),
      invalidatesTags: ['permissions'],
    }),
    listPermissionExpression: build.query<
      ListPermissionExpressionApiResponse,
      ListPermissionExpressionApiArg
    >({
      query: () => ({
        url: `/dms/v1/acl/user/permission-expression`,
      }),
      providesTags: ['permissions'],
    }),
    listProtectedUnits: build.query<ListProtectedUnitsApiResponse, ListProtectedUnitsApiArg>({
      query: () => ({
        url: `/dms/v1/acl/protected-unit`,
      }),
      providesTags: ['permissions'],
    }),
  }),
});

export const {
  useListPermissionExpressionQuery,
  useListPermissionsV2Query,
  useListProtectedUnitsQuery,
  useUpdateProtectedUnitPermissionMutation,
  useGrantPermissionMutation,
  useGrantAllPermissionMutation,
  useRevokeAllPermissionMutation,
  useUpdateActionPermissionMutation,
  useUpdateFieldPermissionMutation,
  useListResourcesQuery,
  useListResourceScopeOptionsQuery,
  useLazyListResourceScopeOptionsQuery,
  useListPermissionsQuery,
  useGrantActionPermissionMutation,
  useGrantFieldPermissionMutation,
  useGrantResourcePermissionMutation,
  useRevokeResourcePermissionMutation,
  useRevokePermissionMutation,
  useListUserAllowedResourceActionsQuery,
  useListUserAllowedRecordActionsQuery,
  useLazyListUserAllowedRecordActionsQuery,
} = accessControlListApi;
