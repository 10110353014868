import {object, SchemaOf, string} from 'yup';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {yupString} from 'shared';

import {BusinessInfoData} from '../types/BusinessInfoData';

export const $BusinessInfoData: SchemaOf<BusinessInfoData> = object().shape(
  {
    countryOfRegistrationCode: string().defined().nullable(),
    fileNumber: string().defined().nullable(),
    tradeName: string().required(),
    registrationNumber: yupString
      .when('vatNumber', {
        is: isNilOrEmpty,
        then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
      })
      .defined(),
    vatNumber: yupString
      .when('registrationNumber', {
        is: isNilOrEmpty,
        then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
      })
      .defined(),
  },
  [['registrationNumber', 'vatNumber']]
);
