import {Tooltip} from 'platform/components';
import {Box} from 'platform/foundation';

import {isEmpty} from 'ramda';

import {Nullish} from 'shared';

export interface CarvagoIconProps {
  isAvailableOnCarvago: boolean | Nullish;
  carvagoBlacklist: string[] | Nullish;
}

export function CarvagoIcon(props: CarvagoIconProps) {
  return (
    <Tooltip isDisabled={isEmpty(props.carvagoBlacklist)} label={props.carvagoBlacklist?.join('')}>
      <Box width={6} height={6}>
        <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M31.4745 22.7339C33.8515 22.7339 35.7625 24.6439 35.7625 27.0219C35.7625 29.3989 33.8515 31.2669 31.4745 31.2669C29.0965 31.2669 27.2285 29.3989 27.2285 27.0219C27.2285 24.6439 29.1395 22.7339 31.4745 22.7339Z"
            fill={props.isAvailableOnCarvago ? '#3E47DD' : '#D3D8DA'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5672 21.034C16.6642 22.803 14.9522 23.687 13.0662 23.687C10.3472 23.687 8.61017 21.491 8.65917 18.707C8.72617 14.906 11.2082 12.496 14.0942 12.496C16.0222 12.496 17.4822 13.531 18.1242 15.206L26.0432 12.21C24.2092 7.708 19.8142 5 14.2802 5C6.45817 5 0.144166 10.676 0.00216552 18.868C-0.127834 26.311 5.61217 31.184 12.6582 31.184C17.7462 31.184 22.6712 29.022 25.2012 24.272L17.5672 21.034Z"
            fill={props.isAvailableOnCarvago ? '#3E47DD' : '#D3D8DA'}
          />
        </svg>
      </Box>
    </Tooltip>
  );
}
