import {LoadAsyncSearch} from '../../../types/ConditionTypes';

export const external = 'external';

export type TExternalEvent = {
  modalEvent(value?: boolean): void;
};

export type TAssignmentButtonType = {
  searchValue?: string;
} & LoadAsyncSearch &
  TExternalEvent;
