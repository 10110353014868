import styled from 'styled-components';

const estimatedRowSize = {
  TABLE: 54, // Dataview table row height
  ADVANCED_VIEW: 213, // Dataview extended view row height
};

type CarProps = {
  disabled?: boolean;
  isLoading?: boolean;
};

export const Car = styled.div<CarProps>`
  display: grid;
  grid-template-columns: 240px auto;
  border-radius: ${({theme}) => theme.radii.small};
  border: 2px solid transparent;

  &.active-iframe {
    border-color: ${({theme}) => theme.colors.palettes.blue[60][100]};
  }

  ${({isLoading}) =>
    isLoading &&
    `
		display: block;
	`};

  @media only screen and (max-width: ${({theme}) => theme.breakPoints.MEDIUM_UP}) {
    height: 250px;
  }

  @media only screen and (max-width: ${({theme}) => theme.breakPoints.SMALL_UP}) {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  ${({disabled}) =>
    disabled &&
    `
		opacity: 0.5;
		filter: grayscale(1)
	`}
`;

export const Circle = styled.div`
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
  margin-left: 5px;
  width: 8px;
  height: 8px;
  flex-grow: 0;
  flex-shrink: 0;
`;

type ImgProps = {
  $isAutoHeight?: boolean;
  $zoomThumbnail?: boolean;
};

export const ImgWrapper = styled.div<ImgProps>`
  max-height: 100%;
  max-width: 100%;
  height: ${estimatedRowSize.ADVANCED_VIEW - 10}px;
  width: 240px;
  background-size: cover;
  background-size: ${({$zoomThumbnail}) => ($zoomThumbnail ? 'cover' : 'contain')};
  background-position: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};

  img {
    width: 240px;
    max-height: ${estimatedRowSize.ADVANCED_VIEW - 10}px;
    object-fit: cover;
  }

  @media only screen and (max-width: ${({theme}) => theme.breakPoints.MEDIUM_UP}) {
    height: auto;
  }

  @media only screen and (max-width: ${({theme}) => theme.breakPoints.SMALL_UP}) {
    width: 100%;
    height: 205px;
  }
`;
