import {match as patternMatch, Pattern} from 'ts-pattern';

import {always, map, match, pipe, reject, split} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

export const VARIABLE_REGEX = /\{\{\s*([\w.]+)\s*\}\}/;
const VARIABLE_REGEX_SPLIT = /(\{\{\s*[\w.]+\s*\}\})/g;
type HighlightedText = {
  text?: string;
  type: 'variable' | 'text';
};

export const getVariables = pipe(
  split(VARIABLE_REGEX_SPLIT),
  reject(isNilOrEmpty),
  map((text) =>
    patternMatch<string, HighlightedText>(text)
      .with(
        Pattern.string.regex(VARIABLE_REGEX),
        always({text: match(VARIABLE_REGEX, text)[1], type: 'variable'})
      )
      .otherwise(always({text, type: 'text'}))
  )
);
