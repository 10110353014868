import {isNotNilOrEmpty} from 'ramda-adjunct';

import {FiltersType} from '../types/FiltersType';

export const hasSomeFilters = (filters: FiltersType) =>
  Object.entries(filters || {}).some(([key, value]) => {
    if (['make', 'model'].includes(key) && typeof value === 'object') {
      return Object.values(value)?.some(Boolean);
    }
    return isNotNilOrEmpty(value);
  });
