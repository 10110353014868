import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {always, isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Record, useLazyGetServiceCaseByServiceOrderIdQuery} from '@omnetic-dms/api';
import {
  businessCaseRoutes,
  customerRoutes,
  vehiclesRoutes,
  workshopRoutes,
} from '@omnetic-dms/routes';

import {composePath} from 'shared';

import {ORDER_TABS} from '../constants/orderTabs';
import {queryParams} from '../utils/queryParams';

export const useGetRecordUrlForSignature = () => {
  const [getServiceCaseByServiceOrderId] = useLazyGetServiceCaseByServiceOrderIdQuery();

  const getRecordUrlForSignature = useCallback(
    (record: Record | null) => {
      if (isNil(record)) {
        return null;
      }

      return match(record)
        .with({resourceId: 'VEHICLE'}, () =>
          composePath(vehiclesRoutes.documents, {
            params: {id: record.id},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'CUSTOMER'}, () =>
          composePath(customerRoutes.documents, {
            params: {id: record.id},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'BUSINESS_CASE'}, () =>
          composePath(businessCaseRoutes.documents, {
            params: {id: record.id},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'SERVICE_CASE'}, () =>
          composePath(workshopRoutes.serviceCaseDetail, {
            params: {id: record.id},
            queryParams: {[queryParams.SERVICE_CASE_ORDER_TAB]: ORDER_TABS.DOCUMENTS},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'SERVICE_ORDER'}, async () => {
          const serviceCaseId = await getServiceCaseByServiceOrderId({serviceOrderId: record.id})
            .unwrap()
            .then((data) => data?.id);

          return isNotNilOrEmpty(serviceCaseId)
            ? composePath(workshopRoutes.serviceCaseDetail, {
                params: {
                  id: serviceCaseId,
                },
                queryParams: {
                  [queryParams.SERVICE_CASE_ORDER_ID]: record.id,
                  [queryParams.SERVICE_CASE_ORDER_TAB]: ORDER_TABS.DOCUMENTS,
                },
                isSearchParamsPreserved: false,
              })
            : null;
        })
        .otherwise(always(undefined));
    },
    [getServiceCaseByServiceOrderId]
  );

  return [getRecordUrlForSignature] as const;
};
