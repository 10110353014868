import {AxiosError} from 'axios';

import {ApiException} from '@omnetic-dms/api';

import {ENV_TYPE, DEFAULT_ERROR_MESSAGE} from '../types/CommonAppTypes';

const INTERNAL_SERVER_ERROR_STATUS_CODE = 500;

export class LogicalException extends Error {
  constructor(message?: string) {
    super(message || 'Logical exception');
  }
}

export class CustomError extends Error {
  statusCode: number;
  constructor({message, statusCode}: {message?: string; statusCode?: number}) {
    super(message);
    this.statusCode = statusCode ?? INTERNAL_SERVER_ERROR_STATUS_CODE;
  }
}

export class CancelError extends Error {
  isCancel: boolean;

  constructor() {
    super('Request cancelled');
    this.isCancel = true;
  }
}

export const isAxiosError = (
  err: CustomError | AxiosError<ApiException> | Error
): err is AxiosError<ApiException> => (err as AxiosError)?.isAxiosError;

export const getErrorMessage = (err?: CustomError | AxiosError<ApiException>) => {
  if (ENV_TYPE === 'prod') {
    return DEFAULT_ERROR_MESSAGE;
  }
  let message = '';
  if (err && isAxiosError(err)) {
    message = err?.response?.data?.error?.message || '';
  }
  return message || err?.message || 'Unknown error';
};

export const getStatusCode = (err: CustomError | AxiosError<ApiException>) => {
  if (isAxiosError(err)) {
    return err?.response?.status ?? INTERNAL_SERVER_ERROR_STATUS_CODE;
  }
  return err?.statusCode ?? INTERNAL_SERVER_ERROR_STATUS_CODE;
};
