import {defaultTo} from 'ramda';

import {Nullish, parseDate} from 'shared';

/**
 * @about Utility function to get ISO string from date and time
 * @returns Date in ISO string format
 * @example
 *  const date = Fri Jun 30 2023 00:00:00 GMT+0200 (Central European Summer Time);
 *  const time = '17:30';
 *
 *  getRequestExpiresAtDate(date, time);
 *  /=> '2023-06-30T15:30:00Z'
 */
export const getRequestExpiresAtDate = (date: Date | Nullish, time: string | Nullish) => {
  if (!date) {
    return;
  }

  const minutes = defaultTo(0, parseInt(defaultTo('', time).substring(3)));
  const hours = defaultTo(0, parseInt(defaultTo('', time).substring(0, 2)));

  const expiresAtDate = date && parseDate(date);

  expiresAtDate.setHours(hours);
  expiresAtDate.setMinutes(minutes);

  return expiresAtDate.toISOString();
};
