import {FC, PropsWithChildren} from 'react';

import i18n from '@omnetic-dms/i18n';
import {TextField} from '@omnetic-dms/teas';

import {useFormContextWithSubmit} from '../../Tenant/hooks/useFormContextWithSubmit';

export const BillingNameSection: FC<PropsWithChildren<any>> = () => {
  const {form, onSubmit} = useFormContextWithSubmit<{billingName: string}>();
  const {
    formState: {errors},
    register,
  } = form;

  return (
    <TextField
      placeholder="–"
      error={!!errors?.billingName?.message}
      helperText={errors?.billingName?.message}
      label={<>*{i18n.t('entity.customer.labels.billingName')}</>}
      {...register('billingName')}
      onBlur={() => {
        onSubmit?.();
      }}
      name="billingName"
    />
  );
};
