import {Attributes, Card} from 'platform/components';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {selectTenant} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {selectCountry, selectCurrency} from '@omnetic-dms/teas';

export const SystemInformation: FC = () => {
  const {data: {id: tenantId, country: tenantCountry, currency: tenantCurrency} = {}} =
    useSelector(selectTenant);

  const country = useSelector(selectCountry(tenantCountry));
  const currency = useSelector(selectCurrency(tenantCurrency));

  return (
    <Card title={i18n.t('page.settings.labels.systemInformation')}>
      <Attributes
        size="quarter"
        rows={[
          {
            label: i18n.t('page.settings.labels.tenantLocation'),
            value: country?.name ?? '–',
          },
          {
            label: i18n.t('page.settings.labels.primaryCurrency'),
            value: currency?.name ?? '–',
          },
          {
            label: i18n.t('general.labels.currencySymbol'),
            value: currency?.symbol ?? '–',
          },
          {
            label: i18n.t('page.settings.labels.tenantId'),
            value: tenantId,
          },
        ]}
      />
    </Card>
  );
};
