import {not} from 'ramda';

import {useElementVisibility} from '../hooks/useElementVisibility';
import {FullSchema} from '../types/FullSchema';
import {RendererRegistry} from '../types/RendererRegistry';

export type ElementRendererProps = {
  schema: FullSchema;
  rendererRegistry: RendererRegistry;
};

export function ElementRenderer(props: ElementRendererProps) {
  const isElementVisible = useElementVisibility(props.schema.showWhen);
  const Renderer = props.rendererRegistry[props.schema.renderer];

  if (not(isElementVisible)) {
    return null;
  }

  return <Renderer {...props.schema} rendererRegistry={props.rendererRegistry} />;
}
