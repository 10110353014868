import type {CancelablePromise} from '../types/CancelablePromise';
import type {CebiaAutotracerAndReportConfig} from '../types/CebiaAutotracerAndReportConfig';
import type {CebiaAutotracerAndReportConfigRequestBody} from '../types/CebiaAutotracerAndReportConfigRequestBody';
import type {CebiaFeatureDecoderConfig} from '../types/CebiaFeatureDecoderConfig';
import type {CebiaFeatureDecoderConfigRequestBody} from '../types/CebiaFeatureDecoderConfigRequestBody';
import type {CebiaRokvyConfig} from '../types/CebiaRokvyConfig';
import type {EmailConfig} from '../types/EmailConfig';
import type {EmailConfigRequestBody} from '../types/EmailConfigRequestBody';
import type {KonzultaConfig} from '../types/KonzultaConfig';
import type {KonzultaConfigRequestBody} from '../types/KonzultaConfigRequestBody';
import type {KonzultaSmsSenderRequestBody} from '../types/KonzultaSmsSenderRequestBody';
import {request as __request} from '../utils/request';

export class IntegratedService {
  /**
   * @returns CebiaAutotracerAndReportConfig
   * @throws ApiError
   */
  public static getCebiaAutotracerAndReportConfig({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<CebiaAutotracerAndReportConfig> {
    return __request({
      method: 'GET',
      url: '/dms/v1/tenant/integrated-service/cebia-autotracer-and-report',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static patchCebiaAutotracerAndReportConfig({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: CebiaAutotracerAndReportConfigRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'PATCH',
      url: '/dms/v1/tenant/integrated-service/cebia-autotracer-and-report',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CebiaFeatureDecoderConfig
   * @throws ApiError
   */
  public static getCebiaFeatureDecoderConfig({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<CebiaFeatureDecoderConfig> {
    return __request({
      method: 'GET',
      url: '/dms/v1/tenant/integrated-service/cebia-feature-decoder',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static patchCebiaFeatureDecoderConfig({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: CebiaFeatureDecoderConfigRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'PATCH',
      url: '/dms/v1/tenant/integrated-service/cebia-feature-decoder',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CebiaRokvyConfig
   * @throws ApiError
   */
  public static getCebiaRokvyConfig({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<CebiaRokvyConfig> {
    return __request({
      method: 'GET',
      url: '/dms/v1/tenant/integrated-service/cebia-rokvy',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns EmailConfig
   * @throws ApiError
   */
  public static getEmailConfig({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<EmailConfig> {
    return __request({
      method: 'GET',
      url: '/dms/v1/tenant/integrated-service/email',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static patchEmailConfig({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: EmailConfigRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'PATCH',
      url: '/dms/v1/tenant/integrated-service/email',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns KonzultaConfig
   * @throws ApiError
   */
  public static getKonzultaConfig({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<KonzultaConfig> {
    return __request({
      method: 'GET',
      url: '/dms/v1/tenant/integrated-service/konzulta-sms',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static patchKonzultaConfig({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: KonzultaConfigRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'PATCH',
      url: '/dms/v1/tenant/integrated-service/konzulta-sms',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static postKonzultaSms({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: KonzultaSmsSenderRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'POST',
      url: '/dms/v1/tenant/integrated-service/konzulta-sms',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
