import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

const IdResponseSchema = z.object({
  id: z.string(),
});

export const MeasurementUnitLabourOperationApiResponseSchema = IdResponseSchema.nullable();

export type PostMeasurementUnitLabourOperationApiResponse = z.infer<
  typeof MeasurementUnitLabourOperationApiResponseSchema
>;

export const GetMeasurementUnitLabourOperationByIdApiResponseSchema = z
  .object({
    id: z.string(),
    authorizationProfileId: z.string(),
    mulo: z.string(),
    conversionRatio: z.number(),
  })
  .nullable();

export type GetMeasurementUnitLabourOperationByIdApiResponse = z.infer<
  typeof GetMeasurementUnitLabourOperationByIdApiResponseSchema
>;

export const PostMeasurementUnitLabourOperationApiArgSchema = z.object({
  body: z.object({
    authorizationProfileId: z.string(),
    mulo: z.string(),
    conversionRatio: z.number(),
  }),
});

export type PostMeasurementUnitLabourOperationApiArg = z.infer<
  typeof PostMeasurementUnitLabourOperationApiArgSchema
>;

export const GetMeasurementUnitLabourOperationByIdApiArgSchema = z.object({
  muloId: z.string(),
});

export type GetMeasurementUnitLabourOperationByIdApiArg = z.infer<
  typeof GetMeasurementUnitLabourOperationByIdApiArgSchema
>;

export type DeleteMeasurementUnitLabourOperationApiResponse = z.infer<
  typeof BaseVoidResponseSchema
>;
export type PatchMeasurementUnitLabourOperationApiResponse = z.infer<typeof BaseVoidResponseSchema>;

export const DeleteMeasurementUnitLabourOperationApiArgSchema = z.object({
  muloId: z.string(),
});

export type DeleteMeasurementUnitLabourOperationApiArg = z.infer<
  typeof DeleteMeasurementUnitLabourOperationApiArgSchema
>;

export const PatchMeasurementUnitLabourOperationApiArgSchema = z.object({
  muloId: z.string(),
  body: z.object({
    authorizationProfileId: z.string(),
    mulo: z.string(),
    conversionRatio: z.number(),
  }),
});

export type PatchMeasurementUnitLabourOperationApiArg = z.infer<
  typeof PatchMeasurementUnitLabourOperationApiArgSchema
>;
