import {BreadcrumbType} from 'platform/components';

import {useEffect} from 'react';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {getEmailConfig, useThunkDispatch} from '@omnetic-dms/teas';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {EmailServicesSMTP} from './components/EmailServicesSMTP';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.integrations.labels.emailServices'),
    href: settingsRoutes.emailServices,
  },
];

export function EmailServicesSMTPPage() {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(getEmailConfig.action({}));
  }, [dispatch]);

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.integrations.labels.emailServices'), breadcrumbs}}
      data-testid="integrations-emailServices-settings"
    >
      <EmailServicesSMTP />
    </SettingsTemplate>
  );
}
