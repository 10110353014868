import {
  AddressResponseBodyV2,
  PersonRequestBody,
  PersonResponseBody,
  PersonResponseBodyV2,
} from '@omnetic-dms/api';

import {Nullish} from 'shared';

/**
 ugly workaround due to incomprehensibly complicated data forwarding
 changing the type would mean a change in a lot of places.
 In the future refactor, promise otherwise has no meaning
 **/
export const personRequestToResponse = (
  personId: string,
  addresses: AddressResponseBodyV2[] | Nullish,
  personRequestBody: PersonRequestBody
): PersonResponseBodyV2 => ({
  id: personId,
  ...personRequestBody.personData,
  phoneNumbers: personRequestBody.phoneNumbers.map((i) => ({
    type: i.type,
    countryCode: i.phoneNumber.countryCode,
    prefix: i.phoneNumber.prefix,
    number: i.phoneNumber.number,
  })),
  emails: personRequestBody.emails,
  identityCards: personRequestBody.identityCards.map((i) => ({
    id: i.id ?? '',
    type: i.cardData.type,
    cardNumber: i.cardData.cardNumber,
    issuedOn: i.cardData.issuedOn ?? null,
    validUntil: i.cardData.validUntil,
    issuer: i.cardData.issuer,
    issuedInCountryCode: i.cardData.issuedInCountryCode,
    note: i.cardData.note,
  })),
  permanentAddress: addresses?.find((i) => i.id === personRequestBody.permanentAddressId) ?? null,
});

export const personResponseToResponse = (
  personId: string,
  addresses: AddressResponseBodyV2[] | Nullish,
  personRequestBody: PersonResponseBody
): PersonResponseBodyV2 => ({
  id: personId,
  ...personRequestBody.personData,
  phoneNumbers: personRequestBody.phoneNumbers.map((i) => ({
    type: i.type,
    countryCode: i.phoneNumber.countryCode,
    prefix: i.phoneNumber.prefix,
    number: i.phoneNumber.number,
  })),
  emails: personRequestBody.emails,
  identityCards: personRequestBody.identityCards.map((card) => ({
    id: card.id,
    type: card.identityCardData.type,
    cardNumber: card.identityCardData.cardNumber,
    issuedOn: card.identityCardData.issuedOn ?? null,
    validUntil: card.identityCardData.validUntil,
    issuer: card.identityCardData.issuer,
    issuedInCountryCode: card.identityCardData.issuedInCountryCode,
    note: card.identityCardData.note,
  })),
  permanentAddress: addresses?.find((i) => i.id === personRequestBody.permanentAddress?.id) ?? null,
});
