import {getSize} from 'platform/foundation';
import styled, {css} from 'styled-components';
import styledMap from 'styled-map';

import {FormLabel} from '../Field/components/FormLabel';
import {MappedInputProps} from './types';

const boxShadow = styledMap<MappedInputProps>`
	default: none;
	defaultFocus: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[20][100]};
	extendedFocus: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
`;
const borderColor = styledMap<MappedInputProps>`
	default: ${({theme}) => theme.colors.palettes.neutral[70][100]};
	defaultFocus: ${({theme}) => theme.colors.palettes.blue[60][100]};
`;

export const opacity = styledMap<MappedInputProps>`
	default: 1;
	disabled: 0.6;
`;
export const pointers = styledMap<MappedInputProps>`
	default: all;
	disabled: none;
`;

export const LabelWrapper = styled.div`
  display: flex;
  white-space: nowrap;
`;

export const InputLabel = styled(FormLabel)`
  display: flex;
  align-items: center;
  flex: 1;
`;

const iconWrapperColor = styledMap<{error?: boolean; inherit?: boolean}>`
	default: ${({theme}) => theme.colors.palettes.neutral[700][100]};
	error: ${({theme}) => theme.colors.palettes.red[60][100]};
	inherit: inherit;
`;

interface IconWrapperStylesProps {
  error?: boolean;
  inherit?: boolean;
}

export const IconWrapper = styled.button<IconWrapperStylesProps>`
  display: flex;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background: transparent;
  color: ${iconWrapperColor};
  font-size: ${({theme}) => theme.fontSizes.text.small};
`;

export const HelpIconWrapper = styled.div<IconWrapperStylesProps>`
  display: flex;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background: transparent;
  color: ${iconWrapperColor};
  font-size: ${({theme}) => theme.fontSizes.text.small};
`;

// "default" styles
export const Wrapper = styled.div<MappedInputProps>`
  width: 100%;
  padding: 0;
  pointer-events: ${pointers};
`;

export const InputWrapper = styled.div<MappedInputProps>`
  border: 1px solid;
  border-radius: ${({theme}) => theme.radii.small};
  color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  display: flex;
  position: relative;
  width: 100%;
  max-height: 32px;
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'all')};
  border-color: ${borderColor};
  box-shadow: ${boxShadow};
  overflow: hidden;

  & > .text-field-adornment {
    border-left: 1px solid ${({theme}) => theme.colors.palettes.neutral[70][100]};
    border-right: 1px solid ${({theme}) => theme.colors.palettes.neutral[70][100]};
    margin-right: -1px;

    &:first-child {
      border-bottom-left-radius: ${({theme}) => theme.radii.small};
      border-top-left-radius: ${({theme}) => theme.radii.small};
      border-left: 0;
      margin-left: 0;
    }

    &:last-child {
      border-bottom-right-radius: ${({theme}) => theme.radii.small};
      border-top-right-radius: ${({theme}) => theme.radii.small};
      border-right: 0;
      margin-right: 0;
    }
  }

  ${({disabled, theme}) =>
    !disabled &&
    `
		&:hover {
			border-color: ${theme.colors.palettes.blue[60][100]};
			background-color: ${theme.colors.palettes.white[10][100]};
		}
	`}

  & .text-field-input-adornment-start {
    padding-left: 8px;
  }

  & .text-field-input-adornment-end {
    padding-right: 8px;
    white-space: nowrap;
  }

  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  transition: box-shadow 225ms ease-in-out;
`;

const inputPadding = styledMap<MappedInputProps>`
	default: 5px 7px;
	inline: 1px 3px;
`;

export const Input = styled.input<MappedInputProps>`
  position: relative;
  width: 100%;
  border: 0;
  letter-spacing: 0.16px;
  box-shadow: none;
  line-height: 20px;
  outline: none;
  padding: ${inputPadding};
  font-size: ${({theme}) => theme.fontSizes.text.small};
  background-color: transparent;
  border-radius: ${({theme}) => theme.radii.small};
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: ${opacity};

  &::placeholder {
    color: ${({theme}) => theme.colors.palettes.neutral[80][100]};
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px ${({theme}) => theme.colors.palettes.yellow[20][100]} inset;
  }

  &[type='number'] {
    appearance: textfield;
  }
`;

type InputEndAdornmentType = {
  disabled?: boolean;
  clickable?: boolean;
};

export const InputAdornmentWrapper = styled.div<InputEndAdornmentType>`
  align-items: center;
  display: flex;
  opacity: ${opacity};
  pointer-events: ${pointers};

  ${({clickable}) =>
    clickable &&
    `
		cursor: pointer;

		* {
			cursor: pointer;
		}
	`}
`;

const AdornmentStyles = css<MappedInputProps>`
  place-items: center center;
  display: flex;
  padding: ${({padless}) => (padless ? '0' : getSize(2))};
  outline: none;
  background-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  opacity: ${opacity};
  pointer-events: ${pointers};
  white-space: nowrap;
  border-top: 0;
  border-bottom: 0;
  ${({theme, disabledFocus}) =>
    !disabledFocus &&
    `
			&:focus-within {
				z-index: 1;
				box-shadow: 0 0 0 4px ${theme.colors.palettes.blue[40][100]};
				margin-left: 0;
			}
		`};
  transition:
    background-color 125ms ease-in-out,
    box-shadow 225ms ease-in-out;
`;

export const AdornmentWrapper = styled.div<MappedInputProps>`
  ${AdornmentStyles};
`;

export const AdornmentWrapperButton = styled.button<MappedInputProps>`
  ${AdornmentStyles};

  cursor: pointer;

  &:active {
    background-color: ${({theme}) => theme.colors.palettes.blue[20][100]};
  }
`;
// "default" styles - end

// "inline" styles
const borderColorInline = styledMap<MappedInputProps>`
	default: transparent;
	defaultFocus: ${({theme}) => theme.colors.palettes.blue[60][100]};
	error: ${({theme}) => theme.colors.palettes.red[70][100]};
`;

export const WrapperInline = styled(Wrapper)`
  display: flex;
  align-items: center;

  ${LabelWrapper} {
    margin-right: ${({theme}) => theme.getSize(3)};

    label {
      margin: 0;
    }
  }

  ${InputWrapper} {
    border-color: ${borderColorInline};
    background-color: transparent;
    max-height: 24px;

    &:hover {
      ${({theme, hasFocus}) =>
        !hasFocus && `background-color: ${theme.colors.palettes.neutral[20][100]}`};
    }
  }

  ${Input} {
    padding: 1px 3px;
  }
`;
// "inline" styles - end

// "filled" styles
const borderColorFilled = styledMap<MappedInputProps>`
	default: transparent;
	hasFocus: ${({theme}) => theme.colors.palettes.blue[50][100]};
`;
const backgroundColorFilled = styledMap<MappedInputProps>`
	default: ${({theme}) => theme.colors.palettes.neutral[20][100]};
	defaultFocus: ${({theme}) => theme.colors.palettes.white[10][100]};
`;

export const WrapperFilled = styled(Wrapper)`
  ${InputWrapper} {
    border-color: ${borderColorFilled};
    background-color: ${backgroundColorFilled};

    &:hover {
      border-color: ${({theme}) => theme.colors.palettes.blue[50][100]};
    }
  }
`;
// "filled" styles - end

// "readonly" styles - start
export const WrapperReadonly = styled(Wrapper)`
  ${InputWrapper} {
    border: none;
    background: transparent;
  }

  ${Input} {
    padding: 0;
    color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  }
`;
// "readonly" styles - end
