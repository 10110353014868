import type {PermissionsRecordType} from '../permissions';

export const businessCasePermissions = {
  readCheckoutPaymentInformation: {
    actionId: 'READ_CHECKOUT_PAYMENT_INFORMATION',
    resourceId: 'BUSINESS_CASE',
    scopes: [],
  },
  closePurchaseContract: {
    actionId: 'CLOSE_PURCHASE_CONTRACT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseRead: {
    actionId: 'READ',
    resourceId: 'BUSINESS_CASE',
    scopes: [],
  },
  closeSellingContract: {
    actionId: 'CLOSE_SALE_CONTRACT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  cancelPurchaseContract: {
    actionId: 'CANCEL_PURCHASE_CONTRACT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  closePurchase: {
    actionId: 'CLOSE_PURCHASE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseCustomFields: {
    actionId: 'CUSTOM_FIELDS_PAYLOAD_UPDATE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['BRANCH', 'PARTICIPATION'],
  },
  reopenPurchase: {
    actionId: 'REOPEN_PURCHASE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  reopenSelling: {
    actionId: 'REOPEN_SALE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  closeSelling: {
    actionId: 'CLOSE_SALE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  cancelSellingContract: {
    actionId: 'CANCEL_SALE_CONTRACT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  cancelBusinessCasePurchaseBrokerage: {
    actionId: 'CANCEL_PURCHASE_BROKERAGE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  viewBusinessCasePurchase: {
    actionId: 'READ_PURCHASE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  createBusinessCasePurchase: {
    actionId: 'CREATE_PURCHASE',
    resourceId: 'BUSINESS_CASE',
    scopes: [],
  },
  createBusinessCaseSelling: {
    actionId: 'CREATE_SALE',
    resourceId: 'BUSINESS_CASE',
    scopes: [],
  },
  businessCaseSaleGenerateOfferDocuments: {
    actionId: 'GENERATE_SALE_OFFER_DOCUMENTS',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseSaleGenerateDocuments: {
    actionId: 'GENERATE_SALE_DOCUMENTS',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseAgreementOnFutureContractGenerateDocuments: {
    actionId: 'GENERATE_AGREEMENT_ON_FUTURE_CONTRACT',
    resourceId: 'BUSINESS_CASE',
    scopes: [],
  },
  addVehicleToBuy: {
    actionId: 'ADD_VEHICLE_TO_BUY',
    resourceId: 'BUSINESS_CASE',
    scopes: [],
  },
  addVehicleToSell: {
    actionId: 'ADD_VEHICLE_TO_SELL',
    resourceId: 'BUSINESS_CASE',
    scopes: [],
  },
  viewBusinessCaseSelling: {
    actionId: 'READ_SALE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  generateBusinessCasePurchaseOfferDocument: {
    actionId: 'GENERATE_PURCHASE_OFFER_DOCUMENT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  generateBusinessCasePurchaseDocuments: {
    actionId: 'GENERATE_PURCHASE_DOCUMENTS',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  issueBusinessCasePurchasePayment: {
    actionId: 'ISSUE_PURCHASE_PAYMENT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  issueBusinessCaseSellingPayment: {
    actionId: 'ISSUE_SALE_PAYMENT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  issueBusinessCaseSellingDeposit: {
    actionId: 'ISSUE_SALE_DEPOSIT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  cancelBusinessCaseSellingInvoice: {
    actionId: 'ISSUE_SALE_INVOICE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  issueBusinessCaseSellingCorrection: {
    actionId: 'ISSUE_SALE_CORRECTION',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  confirmBusinessCaseSellingPaymentManually: {
    actionId: 'MANUALLY_CONFIRM_SALE_PAYMENT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  setBusinessCasePurchaseNote: {
    actionId: 'SET_PURCHASE_NOTE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  changeBusinessCasePurchaseSeller: {
    actionId: 'PURCHASE_CHANGE_SELLER',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  changeBusinessCaseSellingBuyer: {
    actionId: 'SALE_CHANGE_BUYER',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  changeBusinessCasePurchaseToBrokerage: {
    actionId: 'CHANGE_TO_BROKERAGE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  changeBusinessCasePurchaseToStock: {
    actionId: 'CHANGE_TO_STOCK',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  removeBusinessCasePurchaseVehicle: {
    actionId: 'REMOVE_PURCHASE_VEHICLE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  changeBusinessCaseSellingVehicle: {
    actionId: 'CHANGE_SALE_VEHICLE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  removeBusinessCaseSellingVehicle: {
    actionId: 'REMOVE_SALE_VEHICLE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  createBusinessCaseSellingTestDrive: {
    actionId: 'CREATE_TEST_DRIVE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  editBusinessCaseSellingTestDrive: {
    actionId: 'EDIT_TEST_DRIVE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCasePurchasePrice: {
    actionId: 'PRICE_PURCHASE_VEHICLE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  selectBusinessCasePurchaseSellerContactPerson: {
    actionId: 'PURCHASE_SELECT_SELLER_CONTACT_PERSON',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  selectBusinessCaseSellingBuyerContactPerson: {
    actionId: 'SALE_SELECT_BUYER_CONTACT_PERSON',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  selectBusinessCasePurchaseCheckoutContractInformation: {
    actionId: 'PURCHASE_CHECKOUT_SELECT_CONTRACT_INFORMATION',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  selectBusinessCasePurchaseCheckoutDeputyPerson: {
    actionId: 'PURCHASE_CHECKOUT_SELECT_DEPUTY_PERSON',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  selectBusinessCaseSellingCheckoutContractInformation: {
    actionId: 'SALE_CHECKOUT_SELECT_CONTRACT_INFORMATION',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  selectBusinessCaseSellingCheckoutDeputyPerson: {
    actionId: 'SALE_CHECKOUT_SELECT_DEPUTY_PERSON',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  editBusinessCasePurchaseBrokerageFees: {
    actionId: 'EDIT_BROKERAGE_FEES',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseTagRead: {
    actionId: 'RECORD_TAG_VALUES_READ_RECORD_TAG_VALUES',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseTagAddToRecord: {
    actionId: 'RECORD_TAG_VALUES_ADD_TAG_VALUE_TO_RECORD',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseTagRemoveFromRecord: {
    actionId: 'RECORD_TAG_VALUES_REMOVE_TAG_VALUE_FROM_RECORD',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseEditSaleDiscount: {
    actionId: 'EDIT_SALE_DISCOUNT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseAddUpsellItemFromCatalogue: {
    actionId: 'ADD_UPSELL_ITEM_FROM_CATALOGUE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseRemoveUpsellItem: {
    actionId: 'REMOVE_UPSELL_ITEM',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseAddUpsell: {
    actionId: 'ADD_UPSELL',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseEditUpsell: {
    actionId: 'EDIT_UPSELL',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseEditSaleDepositPaymentDate: {
    actionId: 'EDIT_SALE_DEPOSIT_PAYMENT_DATE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseEditSalePaymentDate: {
    actionId: 'EDIT_SALE_PAYMENT_DATE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseSaleVehiclePurchasePrice: {
    fieldId: 'BUSINESS_CASE.SALE_VEHICLE_PURCHASE_PRICE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseSaleVehicleSalePrice: {
    fieldId: 'BUSINESS_CASE.SALE_VEHICLE_SELLING_PRICE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseSaleProfit: {
    fieldId: 'BUSINESS_CASE.SALE_PROFIT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseSaleTotalPrice: {
    fieldId: 'BUSINESS_CASE.SALE_TOTAL_PRICE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCasePurchaseVehiclePurchasePrice: {
    fieldId: 'BUSINESS_CASE.PURCHASE_VEHICLE_PURCHASE_PRICE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCasePurchaseVehicleSellingPrice: {
    fieldId: 'BUSINESS_CASE.PURCHASE_VEHICLE_SELLING_PRICE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCasePurchaseExpectedProfit: {
    fieldId: 'BUSINESS_CASE.PURCHASE_EXPECTED_PROFIT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCasePurchaseTotalPrice: {
    fieldId: 'BUSINESS_CASE.PURCHASE_TOTAL_PRICE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseEditMaxPurchasePrice: {
    actionId: 'EDIT_MAX_PURCHASE_PRICE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseTagAdd: {
    actionId: 'ADD_TAG_VALUE',
    resourceId: 'BUSINESS_CASE_TAG',
    scopes: [],
  },
  businessCaseTagUpdate: {
    actionId: 'UPDATE_TAG_VALUE',
    resourceId: 'BUSINESS_CASE_TAG',
    scopes: [],
  },
  businessCaseTagRemove: {
    actionId: 'REMOVE_TAG_VALUE',
    resourceId: 'BUSINESS_CASE_TAG',
    scopes: [],
  },
  businessCaseTagClearExpiration: {
    actionId: 'CLEAR_TAG_VALUE_EXPIRATION',
    resourceId: 'BUSINESS_CASE_TAG',
    scopes: [],
  },
  businessCaseTagSetExpiration: {
    actionId: 'SET_TAG_VALUE_EXPIRATION',
    resourceId: 'BUSINESS_CASE_TAG',
    scopes: [],
  },
  // TODO: this fieldId should ideally get swapped for actionId when BE will be ready
  businessCaseViewBrokerageFees: {
    fieldId: 'BUSINESS_CASE.TOTAL_GROSS_MARGIN',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseCommentRead: {
    actionId: 'COMMENT_READ',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseCommentAdd: {
    actionId: 'COMMENT_ADD',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseCommentUpdate: {
    actionId: 'COMMENT_UPDATE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  businessCaseCommentDelete: {
    actionId: 'COMMENT_DELETE',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  complaintRead: {
    actionId: 'READ_COMPLAINTS',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  complaintCreate: {
    actionId: 'CREATE_COMPLAINT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  complaintUpdate: {
    actionId: 'UPDATE_COMPLAINT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  complaintSetInspection: {
    actionId: 'SET_COMPLAINT_INSPECTION',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  complaintUpdateResult: {
    actionId: 'UPDATE_COMPLAINT_RESULT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  complaintProtocol: {
    actionId: 'GENERATE_COMPLAINT_PROTOCOL',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  complaintDelete: {
    actionId: 'DELETE_COMPLAINT',
    resourceId: 'BUSINESS_CASE',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
} satisfies PermissionsRecordType;
