/* eslint-disable filename-export/match-named-export */
import {format} from 'date-fns';
import {BaseFlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {
  AccountingCustomerResponseBody,
  CashRegisterDocumentItemResponseBody,
  CashRegisterDocumentKindOfPaymentEnum,
  CashRegisterDocumentResponseBody,
  CashRegisterDocumentStateEnum,
  CustomerResponseBodyV2,
  InvoicingDocumentItemResponseBody,
  IssuerResponseBody,
  SupplierResponseBody,
  useGetCashRegisterDocumentKindOfPaymentListQuery,
  useGetCashRegisterQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  composeAddressLineFromCommonAddress,
  EMPTY_PLACEHOLDER,
  getCustomerName,
  isCustomerNaturalPerson,
} from '@omnetic-dms/shared';

import {getRandomId, Nullish, parseDate} from 'shared';

import {DATE_FORMAT} from '../../constants';
import {AccountingDocumentFormValues} from '../../types';

export const getCashReceiptStateFlagProps = (
  state: CashRegisterDocumentStateEnum
): BaseFlagProps => {
  const variableProps = match<CashRegisterDocumentStateEnum, BaseFlagProps>(state)
    .with('taken', always({label: i18n.t('entity.cashRegister.state.taken'), colorScheme: 'green'}))
    .with(
      'cancelled',
      always({label: i18n.t('entity.cashRegister.state.cancelled'), colorScheme: 'red'})
    )
    .with(
      'cancelling',
      always({label: i18n.t('entity.cashRegister.state.cancelling'), colorScheme: 'orange'})
    )
    .exhaustive();

  return {...variableProps, isSubtle: true};
};

export const covertIssuerToSupplier = (
  data: IssuerResponseBody | Nullish
): SupplierResponseBody => ({
  cin: data?.registrationNumber ?? null,
  tin: data?.vatNumber ?? null,
  id: getRandomId(),
  country: data?.country ?? null,
  city: data?.city ?? null,
  name: data?.name ?? null,
  tradeName: null,
  addressLine1: data?.street ?? null,
  zip: data?.zip ?? null,
  addressLine2: null,
  fileNumber: null,
});

export const convertCustomerToAccountingCustomer = (
  customer: CustomerResponseBodyV2 | Nullish
): AccountingCustomerResponseBody | null => {
  if (isNil(customer)) {
    return null;
  }

  const address = isCustomerNaturalPerson(customer)
    ? (customer.foundingPerson?.permanentAddress?.address ?? null)
    : customer.businessInfo?.address?.address || null;

  return {
    printLanguage: '',
    id: customer?.id,
    // @ts-ignore customer version is different
    name: getCustomerName(customer) ?? EMPTY_PLACEHOLDER,
    city: address?.city ?? null,
    zip: address?.zip ?? null,
    country: address?.country ?? null,
    addressLine1: address ? composeAddressLineFromCommonAddress(address) : null,
    addressLine2: null,
    tradeName: customer?.businessInfo?.businessInfo?.tradeName ?? null,
    cin: customer.businessInfo?.businessInfo?.registrationNumber ?? null,
    tin: customer.businessInfo?.businessInfo?.vatNumber ?? null,
  };
};

export const convertCashRegisterItemToInvoiceItem = (
  item: CashRegisterDocumentItemResponseBody
): InvoicingDocumentItemResponseBody => ({
  isUnitPriceWithVat: item.withVat,
  description: item.description,
  currency: item.totalPriceWithVat.currency,
  discount: null,
  generatedItemType: 'standard',
  vatType: item.vatType,
  pricePerUnit: item.unitPrice,
  quantity: item.quantity,
  itemId: item.id,
  relatedItemId: null,
  totalPrice: {
    vatType: item.vatType,
    vatRate: item.vatRate,
    total: item.totalPriceWithVat,
    base: item.totalPriceWithoutVat,
    vat: {amount: '0', currency: 'CZK'},
  },
  unit: item.unit,
  type: 'standard',
});

export const useGetDefaultValuesFromCashReceipt = (
  cashReceipt: CashRegisterDocumentResponseBody | Nullish
): Partial<AccountingDocumentFormValues> | undefined => {
  const {data: kindOfPayment} = useGetCashRegisterDocumentKindOfPaymentListQuery({});
  const {data: cashRegister} = useGetCashRegisterQuery(
    {cashRegisterId: cashReceipt?.cashRegisterId ?? ''},
    {skip: isNil(cashReceipt)}
  );

  if (isNil(cashReceipt) || isNil(cashRegister)) {
    return undefined;
  }

  return {
    cashRegisterId: cashRegister.name,
    issueDate: format(parseDate(cashReceipt.issuedAt), DATE_FORMAT),
    paymentInfo: {
      paymentMethod:
        cashReceipt.paymentMethod === 'CARD'
          ? i18n.t('entity.invoice.paymentMethod.card')
          : i18n.t('entity.invoice.paymentMethod.cash'),
      paymentType:
        (kindOfPayment?.find((item) => item.code === cashReceipt.kindOfPayment)
          ?.name as CashRegisterDocumentKindOfPaymentEnum) ?? null,
      variableSymbol: cashReceipt.variableSymbol,
    },
    reason: cashReceipt.purpose,
    footerNote: cashReceipt.note,
    exchangeRateRatio: {
      amount: cashReceipt.exchangeRateRatio?.amount?.toString() ?? null,
      ratio: cashReceipt.exchangeRateRatio?.ratio ?? null,
      currency: null,
      type: 'recalculate',
    },
  };
};
