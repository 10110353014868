import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {createFieldForReset} from './createFieldForReset';
import {getResetValueByParamType} from './getResetValueByParamType';

export const resetToggleRelatedFields = (
  category: AuditCategoryOfStructure,
  toggleParam: AuditParamDefinition,
  setFieldValue: (name: string, value: string | null) => void
) => {
  const toggleParamValue = toggleParam?.values[0];

  const toggleParamDefinitions = [
    ...(toggleParamValue?.content?.relatedActions ?? []),
    ...(toggleParamValue?.content?.additionalInformation ?? []),
  ];

  toggleParamDefinitions?.forEach((paramDefinition) => {
    const {name, type} = createFieldForReset(category.id, paramDefinition);
    setFieldValue(name, getResetValueByParamType(type));
  });
};
