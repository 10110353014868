import {DataStatus} from 'platform/components';

import {FC, PropsWithChildren, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {any, head, isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {Main} from '@omnetic-dms/shared';
import {
  loadVehicleDetailsData,
  getAudit,
  useThunkDispatch,
  VehicleInspection,
  selectCarAudit,
} from '@omnetic-dms/teas';

import {useFetchAuditStructure} from '../../hooks/useFetchAuditStructure';

export const InspectionDetail: FC<PropsWithChildren<any>> = () => {
  const dispatch = useThunkDispatch();

  const {id: vehicleId, inspectionId} = useParams();

  const {fetchAuditStructure} = useFetchAuditStructure();
  const {loading} = useSelector(selectCarAudit);

  const isAuditLoading = any(isTrue, [
    loading.getAudit,
    loading.getAuditStructure,
    loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
    loading['sales/vehicleDetail/loadPremiumFeatures'],
    loading['vehicleCatalogue/getCustomTenantCatalogue'],
  ]);

  useEffect(() => {
    if (isNotNil(vehicleId) && isNotNil(inspectionId) && !isAuditLoading) {
      loadVehicleDetailsData(vehicleId, dispatch).then(() => {
        dispatch(
          getAudit.action({
            auditId: inspectionId,
          })
        )
          .unwrap()
          .then((response) => {
            if (isNotNil(response)) {
              fetchAuditStructure(head(response));
            }
          });
      });
    }
  }, [vehicleId, inspectionId]);

  return (
    <Main>
      <DataStatus isLoading={isAuditLoading} minHeight={100}>
        <VehicleInspection shouldUseAuditIdForUpdateTemplate={false} />
      </DataStatus>
    </Main>
  );
};
