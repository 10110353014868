import {createAction, createSlice} from '@reduxjs/toolkit';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/api';

import {NAME} from './constants';
import {SubmitSummaryChangeRequestPayload, VehicleDetailState} from './types';

export const submitSourcingVehicleSummaryChange = createAction<SubmitSummaryChangeRequestPayload>(
  `${NAME}/vehicleDetail/submitSourcingVehicleSummaryChange`
);

const initialState: VehicleDetailState = {
  isLoading: true,
  vehicle: {} as SourcingVehicleDetailResponseBody,
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    copyDataForDetail: (_state, {payload}) => ({
      ...initialState,
      vehicle: payload,
    }),
    setIsLoading: (state, {payload}) => {
      state.isLoading = payload;
    },
    updateVehicleDetailCosts: (state, {payload}) => {
      state.vehicle.summary = {...state.vehicle.summary, ...payload};
    },
  },
});

const {
  reducer,
  actions: {setIsLoading, copyDataForDetail, updateVehicleDetailCosts},
} = slice;

export {setIsLoading, copyDataForDetail, updateVehicleDetailCosts};

export const vehicleDetailReducer = reducer;
