import {ThemeIconKey, ThemeColorPath} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {always} from 'ramda';

import {Nullish} from 'shared';

import {TabIconVariant} from '../TabIconVariant';

type FetIconPropsFromVarianReturnType = {key: ThemeIconKey; color?: ThemeColorPath};

export const getIconPropsFromVariant = (variant: TabIconVariant | Nullish) =>
  match<TabIconVariant | Nullish, FetIconPropsFromVarianReturnType | null>(variant)
    .with('success', always({key: 'action/check_circle', color: 'severity.success'}))
    .with('error', always({key: 'alert/error', color: 'severity.danger'}))
    .with('pending', always({key: 'action/watch_later', color: 'severity.warning'}))
    .with('default', always({key: 'toggle/radio_button_unchecked'}))
    .with(Pattern.nullish, always(null))
    .exhaustive();
