import {
  CarkPostInsuranceDraftApiArg,
  CarkPostInsuranceDraftApiResponse,
  CarkPostTipApiArg,
  CarkPostTipApiResponse,
} from '../types/metadaInsurance';
import {metadaApi} from './baseApi/metadaApi';

export const metadaInsuranceNewApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    carkPostInsuranceDraft: build.mutation<
      CarkPostInsuranceDraftApiResponse,
      CarkPostInsuranceDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/insurance/v2/insuranceDraft`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    carkPostTip: build.mutation<CarkPostTipApiResponse, CarkPostTipApiArg>({
      query: (queryArg) => ({url: `/insurance/v2/tip`, method: 'POST', body: queryArg.body}),
    }),
  }),
});

export const {useCarkPostTipMutation, useCarkPostInsuranceDraftMutation} = metadaInsuranceNewApi;
