import {pathToRegexp} from 'path-to-regexp';

export const routes = [
  '/',
  '/accounting/cash-receipt',
  '/accounting/cash-receipt/new/expense',
  '/accounting/cash-receipt/new/income',
  '/accounting/cash-receipt/:id',
  '/accounting/v2/corrective-tax-document/new',
  '/accounting/invoice',
  '/accounting/invoice/new',
  '/add-car',
  '/business-case',
  '/customer',
  '/customer/new-customer',
  '/interest',
  '/interest/concept',
  '/login',
  '/login/change-password',
  '/login/forgot-password',
  '/login/password-message',
  '/my-profile/dashboard',
  '/my-profile/language',
  '/my-profile/personal-information',
  '/my-profile/security',
  '/settings/accounting',
  '/settings/accounting/cash-register',
  '/settings/accounting/cash-register-limits',
  '/settings/accounting/cash-register-limits/add',
  '/settings/accounting/document-rounding-definitions',
  '/settings/accounting/document-rounding-definitions/add',
  '/settings/accounting/invoice-definition',
  '/settings/billing-information',
  '/settings/billing-information/new',
  '/settings/branches',
  '/settings/branches/new',
  '/settings/customer',
  '/settings/customer/customer-definition',
  '/settings/document-templates',
  '/settings/general',
  '/settings/general/series-definition',
  '/settings/general/series-definition/new',
  '/settings/integrations',
  '/settings/integrations/cebia',
  '/settings/integrations/cebia/rokvy',
  '/settings/integrations/features',
  '/settings/integrations/sms-gateways',
  '/settings/integrations/sms-gateways/konzulta',
  '/settings/integrations/email-services',
  '/settings/integrations/email-services/smtp-server',
  '/settings/integrations/vehicle-services',
  '/settings/sales',
  '/settings/sales/business-case',
  '/settings/sales/business-case/business-case-definition',
  '/settings/sales/interest',
  '/settings/sales/interest/interest-definition',
  '/settings/sales/publishing',
  '/settings/tenant',
  '/settings/user-management',
  '/settings/user-management/add-user',
  '/settings/vehicle',
  '/settings/vehicle/top-makes',
  '/settings/vehicle/vehicle-series-definition',
  '/settings/vehicle/vehicle-series-definition/new',
  '/settings/gdpr-consent-types',
  '/settings/gdpr-consent-types/new',
  '/settings/gdpr-consent-types/:id/edit',
  '/settings/warehouses',
  '/settings/warehouses/list',
  '/sign-up',
  '/sourcing',
  '/sourcing/classifieds',
  '/sourcing/classifieds/comparison',
  '/sourcing/classifieds/user-favourite',
  '/sourcing/classifieds/user-hidden',
  '/sourcing/classifieds/user-to-buy',
  '/vehicles',
  '/vehicles/create',
  '/vehicles/clone',
  '/vehicles/:id/vehicle-data',
  '/vehicles/:id/publishing',
  '/vehicles/:id/publishing/edit',
  '/vehicles/:id/publishing/edit-windshield',
  '/vehicles/:id/publishing/:code',
  '/vehicles/:id/publishing/:code/edit',
  '/vehicles/:id/photos/:tab',
  '/vehicles/:id/photos/:tab/:inspectionId',
  '/vehicles/:id',
  '/vehicles/:id/business-cases',
  '/vehicles/:id/documents',
  '/vehicles/:id/features',
  '/vehicles/:id/edit',
  '/vehicles/:id/edit/:tab',
  '/vehicles/:id/details',
  '/vehicles/:id/finances',
  '/vehicles/:id/reservations',
  '/vehicles/:id/conditions/inspections',
  '/vehicles/:id/conditions/inspections/:inspectionId',
  '/vehicles/:id/conditions/inspections/:inspectionId/:inspectionTab',
  '/vehicles/:id/conditions',
  '/vehicles/:id/conditions/condition',
  '/vehicles/:id/conditions/condition/:conditionTab',
  '/vehicles/:id/interests',
  '/vehicles/:id/customers',
  '/vehicles/in-advertisement',
  '/sourcing/vehicle/:subpage/:adId/similar',
  '/sourcing/vehicle/:subpage/:adId',
  '/settings/vehicle/vehicle-series-definition/:id/edit',
  '/settings/user-management/role/:roleId',
  '/settings/user-management/:id',
  '/settings/sales/publishing/platforms',
  '/settings/sales/publishing/platforms/:code',
  '/settings/sales/publishing/promotional-photos',
  '/settings/sales/publishing/promotional-photos/create',
  '/settings/sales/publishing/promotional-photos/:id',
  '/settings/sales/publishing/watermark',
  '/settings/sales/interest/interest-definition/:id',
  '/settings/sales/business-case/business-case-definition/:id',
  '/settings/general/series-definition/:id',
  '/settings/customer/customer-definition/:id',
  '/customer/:id',
  '/customer/:id/business-cases',
  '/customer/:id/documents',
  '/customer/:id/interests-overview',
  '/customer/:id/vehicles-overview',
  '/accounting/tax-document/:id',
  '/accounting/proforma/:id/payments',
  '/accounting/proforma/:id',
  '/accounting/invoice/draft/:invoiceId',
  '/accounting/invoice/:invoiceId/payments',
  '/accounting/invoice/:invoiceId/notes',
  '/accounting/invoice/:invoiceId',
  '/accounting/invoice/:invoiceId/history',
  '/accounting/corrective-tax-document/:id',
  '/accounting/balance-invoice/:id',
  '/accounting/balance-invoice/:id/payments',
  '/interest/*',
  '/business-case/*',
  '/v-case/*',
  '/settings/branches/:id',
  '/settings/billing-information/:id',
  '/settings/accounting/document-rounding-definitions/:id',
  '/settings/accounting/document-rounding-definitions/:id/edit',
  '/settings/accounting/cash-register-limits/:id',
  '/settings/accounting/cash-register/:id',
  '/settings/vehicle/condition',
  '/settings/vehicle/inspections',
  '/settings/vehicle/inspections/:id',
  '/settings/vehicle/inspections/add',
  '/demo/datagrid',
];

/**
 * @param pathname current URL from first slash / to query string ?
 * @returns route based on provided URL, f.e. for /customers/1234 returns route /customer/:id
 */
export const findCurrentRoute = (pathname: string) => {
  const route = routes.find((route) => {
    if (route.includes('/*')) {
      const regexpWithStar = pathToRegexp(route.split('/*')[0]);
      const pathnameWithStar = `${pathname.slice(0, route.indexOf('/*'))}/`;

      return regexpWithStar.test(pathnameWithStar);
    }
    const regexp = pathToRegexp(route);

    return regexp.test(pathname);
  });

  return route ?? '/';
};
