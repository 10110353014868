import {Action} from 'platform/components';

import {matchPath, useLocation, useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {accountingRoutes, testIds} from '@omnetic-dms/routes';
import {Main, PageTab, usePermissions} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

import {CashReceiptsOverview} from '../CashReceiptsOverview/CashReceiptsOverview';
import {InvoiceOverview} from '../InvoiceOverview/InvoiceOverview';

export function AccountingOverview() {
  const navigate = useNavigate();
  const location = useLocation();

  const [canReadCashReceipts, canReadInvoices] = usePermissions({
    permissionKeys: ['readCashRegisterDocument', 'readInvoice'],
  });

  const isCashReceiptTab = matchPath(location.pathname, accountingRoutes.cashReceiptsOverview);

  const actions = buildArray<Action>()
    .whenNot(isCashReceiptTab, {
      type: 'button',
      onClick: () => navigate(accountingRoutes.invoiceNew),
      title: i18n.t('entity.invoice.actions.addInvoice'),
      'data-testid': testIds.accounting.invoiceOverview('createInvoice'),
    })
    .when(isCashReceiptTab, {
      type: 'button',
      variant: 'secondary',
      onClick: () => navigate(accountingRoutes.createCashReceiptExpense),
      title: i18n.t('cashRegister.buttons.newExpenseDocument.title'),
      'data-testid': testIds.accounting.invoiceOverview('createExpense'),
      leftIcon: 'content/unarchive',
    })
    .when(isCashReceiptTab, {
      type: 'button',
      variant: 'secondary',
      onClick: () => navigate(accountingRoutes.createCashReceiptIncome),
      title: i18n.t('cashRegister.buttons.newIncomeDocument.title'),
      'data-testid': testIds.accounting.invoiceOverview('createIncome'),
      leftIcon: 'content/archive',
    });

  const tabs = buildArray<PageTab>()
    .when(canReadInvoices, {
      title: i18n.t('page.accounting.invoiceList.title'),
      id: 'invoice',
      href: accountingRoutes.invoiceOverview,
      content: <InvoiceOverview />,
    })
    .when(canReadCashReceipts, {
      title: i18n.t('page.accounting.cashRegister.title'),
      id: 'cashRegister',
      href: accountingRoutes.cashReceiptsOverview,
      content: <CashReceiptsOverview />,
    });

  return <Main tabs={tabs} actions={actions} isFullHeight />;
}
