import {setIn} from 'final-form';
import {AnyObjectSchema} from 'yup';

// To be passed to React Final Form
export const validateFormValues =
  (schema: AnyObjectSchema) => async (values: Record<string, unknown>) => {
    try {
      await schema.validate(values, {
        abortEarly: false,
        strict: false,
        context: values,
      });
      return {};
    } catch (err: any) {
      if (err.inner) {
        return err.inner.reduce(
          (formError: Record<string, unknown>, innerError: Record<string, string>) =>
            setIn(formError, innerError.path, innerError.message),
          {}
        );
      }
    }
  };
