import {
  Button,
  ButtonGroup,
  DialogFooter,
  closeCurrentDialog,
  showNotification,
} from 'platform/components';
import {Box} from 'platform/foundation';

import {useState} from 'react';

import {head, isNil} from 'ramda';

import {usePatchServiceOrderIssueNoteMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, RowData} from 'features/datagrid';

interface ChangeOrderDialogProps extends RequiredTestIdProps {
  serviceOrderIssueNoteId: string | Nullish;
  onOrderChange?: (serviceOrderId: string) => void;
}

export function ChangeOrderDialog(props: ChangeOrderDialogProps) {
  const [selectedServiceOrderId, setSelectedServiceOrderId] = useState<string | Nullish>(null);
  const [patchServiceOrderIssueNote, {isLoading: isPatching}] =
    usePatchServiceOrderIssueNoteMutation();

  const handleOnRowSelectionChange = (rows: RowData[]) => {
    setSelectedServiceOrderId(head(rows)?.id);
  };

  const handleChangeServiceOrder = () => {
    if (isNil(selectedServiceOrderId)) {
      throw new Error('Cannot change service order. Missing service order id.');
    }

    patchServiceOrderIssueNote({
      serviceOrderIssueNoteId: props.serviceOrderIssueNoteId ?? '',
      body: {serviceOrderId: selectedServiceOrderId},
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.notifications.serviceOrderChanged'));
        props.onOrderChange?.(selectedServiceOrderId ?? '');
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  return (
    <Box paddingBottom={16}>
      <DataGrid
        gridCode="issue-note-service-orders"
        onRowSelectionChange={handleOnRowSelectionChange}
        autoHeight
        data-testid={suffixTestId('serviceOrderList', props)}
      />
      <DialogFooter>
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            title={i18n.t('general.actions.discard')}
            onClick={closeCurrentDialog}
            data-testid={suffixTestId('actions.discard', props)}
          />
          <Button
            title={i18n.t('general.actions.change')}
            isDisabled={isNil(selectedServiceOrderId)}
            isLoading={isPatching}
            onClick={handleChangeServiceOrder}
            data-testid={suffixTestId('actions.submit', props)}
          />
        </ButtonGroup>
      </DialogFooter>
    </Box>
  );
}
