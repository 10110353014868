import {Text} from 'platform/foundation';

import {Nullish, suffixTestId, TestIdProps, useBoolean} from 'shared';

import {Dialog} from '../Dialog/Dialog';
import {useTranslationContext} from '../TranslationProvider/TranslationContext';

export interface DeleteDialogProps extends TestIdProps {
  isOpen: boolean;
  /**
   * @about Use to provide an entity name.
   * @example Are you sure you want to delete {name}?
   */
  name?: string | Nullish;
  id?: string;
  /**
   * @about Or just write what you want to be displayed
   * @example Are you sure you want to delete this example?
   */
  text?: string;
  /**
   * @about You can return a promise from onConfirm. Dialog
   * will wait for the promise to finish and will close then.
   * Also, cancel/submit buttons will be disabled while
   * the promise is pending.
   * @example onConfirm: async () => await deleteSomething()
   */
  onConfirm: () => void | Promise<unknown>;
  onClose?: () => void;
  onCloseComplete?: () => void;
  /**
   * @about overrides z-index of the dialog so that it can be displayed above the lightbox
   */
  isInLightbox?: boolean;
}

export function DeleteDialog(props: DeleteDialogProps) {
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const t = useTranslationContext();

  const handleConfirm = async () => {
    startLoading();
    await props.onConfirm();
    stopLoading();
    props.onClose?.();
  };

  const getConfirmMessage = t(
    props.name
      ? 'general.actions.namedConfirmDeleteQuestion'
      : 'general.actions.confirmDeleteQuestion',
    {name: props.name}
  );

  return (
    <Dialog
      size="small"
      id={props.id}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onCloseComplete={props.onCloseComplete}
      buttons={[
        {
          variant: 'secondary',
          onClick: props.onClose,
          isDisabled: isLoading,
          title: t('general.actions.cancel'),
          'data-testid': suffixTestId('deleteDialog-cancel', props),
        },
        {
          variant: 'danger',
          onClick: handleConfirm,
          isLoading,
          title: t('general.actions.delete'),
          'data-testid': suffixTestId('deleteDialog-confirm', props),
        },
      ]}
      isInLightbox={props.isInLightbox}
      data-testid={suffixTestId('deleteDialog', props)}
    >
      <Text>{props.text ?? getConfirmMessage}</Text>
    </Dialog>
  );
}
