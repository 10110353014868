import {
  showNotification,
  useLocalStorage,
  openDialog,
  closeCurrentDialog,
} from 'platform/components';

import {useCallback, useReducer, useState} from 'react';
import {Helmet} from 'react-helmet-async';

import {add, mergeAll} from 'ramda';

import {browserStorageKey} from '@omnetic-dms/config';
import {environment} from '@omnetic-dms/environment';
import {Main} from '@omnetic-dms/shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {ConnectionFormType, OverrideConnections} from '../../components/OverrideConnections';
import {OverrideQueryModifier} from '../../components/OverrideQueryModifier';

const PAGE_TITLE = 'Demo page - DataGrid';
const DEMO_DATAGRID_LOCAL_SOTRAGE_KEY = 'DATAGRID_LOCAL_SOTRAGE_KEY';
const EXTERNAL_FILTER_ID = 'DEV_DATAGRID_EXTERNAL_FILTER_ID';

export function DataGridDev() {
  const authorizationToken = sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);

  const [key, reloadComponents] = useReducer(add(1), 0);

  const [queryModifierOverrider, setQueryModifierOverrider] = useState<object | null>(null);

  const [isExternalFilterShowed, toggleExternalFilter] = useLocalStorage<boolean>(
    `${DEMO_DATAGRID_LOCAL_SOTRAGE_KEY}_showExternalFilter`,
    false
  );

  const [connectionOverride, setConnectionOverride] = useLocalStorage<ConnectionFormType>(
    `${DEMO_DATAGRID_LOCAL_SOTRAGE_KEY}_connectionOverride`,
    {
      baseUrl: environment.API_URL,
      gridCode: 'vehicle',
      authorizationToken: `Bearer ${authorizationToken}`,
    }
  );

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, queryModifierOverrider ?? {}]);

  const actionCallback: ActionCallback = useCallback(({actionKey}) => {
    showNotification.info(`Action callback - ${actionKey}`);
  }, []);

  const onQueryModifierChange = (value: object | null) => {
    setQueryModifierOverrider(value);
    reloadComponents();
    closeCurrentDialog();
  };

  const onConnectionChange = (data: ConnectionFormType) => {
    setConnectionOverride(data);
    reloadComponents();
    closeCurrentDialog();
  };

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
      </Helmet>

      <Main
        isFullHeight
        heading={PAGE_TITLE}
        actions={[
          {
            type: 'button',
            onClick: () => toggleExternalFilter(!isExternalFilterShowed),
            variant: 'secondary',
            title: isExternalFilterShowed ? 'Hide External Filter' : 'Show External Filter',
          },
          {
            type: 'button',
            onClick: () =>
              openDialog(
                <OverrideConnections
                  defaultValues={connectionOverride}
                  onConnectionChange={onConnectionChange}
                />,
                {title: 'Override Connections Settings'}
              ),
            title: 'Override Connections Settings',
          },
          {
            type: 'button',
            onClick: () =>
              openDialog(<OverrideQueryModifier onQueryModifierChange={onQueryModifierChange} />, {
                title: 'Override Query Modifier',
              }),
            title: 'Override Query Modifier',
          },
        ]}
      >
        <div
          id={EXTERNAL_FILTER_ID}
          style={{minHeight: '50%', display: isExternalFilterShowed ? undefined : 'none'}}
        />
        <DataGrid
          /**
           * Using key to refresh DG is ok in this case
           * We are changing the gridCode, not just reloading the data
           */
          // eslint-disable-next-line no-restricted-syntax
          key={`${connectionOverride.gridCode}-${connectionOverride.baseUrl}-${key}`}
          gridCode={connectionOverride.gridCode}
          externalFilterId={EXTERNAL_FILTER_ID}
          _useAsLastResort_connectionOverride={{
            authorizationToken: connectionOverride.authorizationToken,
            baseUrl: connectionOverride.baseUrl,
          }}
          data-testid="demo-grid"
          queryModifier={queryModifier}
          actionCallback={actionCallback}
        />
      </Main>
    </>
  );
}
