import {
  AdaptiveImage,
  Box,
  Center,
  Clickable,
  Icon,
  Image,
  makeStorageUrl,
  Show,
} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {Fragment} from 'react';

import {isNotNil} from 'ramda';

import {RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {LightboxSlide} from '../types/LightboxSlide';

interface ThumbnailProps extends RequiredTestIdProps {
  isActive: boolean;
  slide: LightboxSlide;
  onClick: () => void;
}

export function ThumbnailItem(props: ThumbnailProps) {
  const theme = useTheme();
  const [isHovered, handleHover, handleLeave] = useBoolean();

  return (
    <Fragment>
      <Clickable
        data-testid={props['data-testid']}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
        onClick={props.onClick}
      >
        <Box position="relative" borderRadius="small" overflow="hidden">
          {props.slide.type === 'image' ? (
            <AdaptiveImage
              data-testid={props['data-testid']}
              url={props.slide.resizeUrl ?? props.slide.url}
              makeUrl={makeStorageUrl}
              ratio="4 / 3"
              fit="cover"
              placeholder={
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  backgroundColor={theme.components.Image.emptyFill}
                  borderRadius="small"
                >
                  <Center width="100%" height="100%">
                    <Icon color="palettes.neutral.70.100" value="image/photo" size={6} />
                  </Center>
                </Box>
              }
            />
          ) : null}
          {props.slide.type === 'video' ? (
            <Box
              backgroundColor="palettes.neutral.10.40"
              borderRadius="medium"
              ratio="4 / 3"
              position="relative"
            >
              <Show when={isNotNil(props.slide.coverImageUrl)}>
                <Box position="absolute">
                  <Image
                    data-testid={props['data-testid']}
                    src={props.slide.coverImageUrl}
                    ratio="4 / 3"
                    fit="cover"
                  />
                </Box>
              </Show>
              <Box position="relative" height="100%">
                <Center width="100%" height="100%" justify="center">
                  <Icon data-testid={props['data-testid']} value="AV/play_arrow" size={8} />
                </Center>
              </Box>
            </Box>
          ) : null}
          {props.isActive && (
            <Box
              data-testid={suffixTestId('active', props)}
              height="100%"
              width="100%"
              position="absolute"
              top={0}
              left={0}
              borderRadius="small"
              border="2px solid white"
            />
          )}
          <Box
            opacity={isHovered ? 1 : 0}
            transition="opacity 0.2s"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            backgroundColor="palettes.black.900.20"
            borderRadius="small"
          />
        </Box>
      </Clickable>
    </Fragment>
  );
}
