import {Pattern, match as patternMatch} from 'ts-pattern';

import {always, map, match, pipe, reject, split} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

const LINK_REGEX = /url\[(.*?)\]/;

const EMAIL_REGEX = /email\[(.*?)\]/;

const USER_REGEX = /@\[([^\]]+)\]\([^)]+\)/;

const COMBINED_REGEX = /(email\[.*?\])|(url\[.*?\])|(@\[[^\]]+\]\([^)]+\))/g;

type HighlightedText = {
  text?: string;
  type: 'link' | 'user' | 'text' | 'email';
};

/**
 * Splits the input string into array of objects with text and type properties
 */
export const getHighlightsFromText = pipe(
  split(COMBINED_REGEX),
  reject(isNilOrEmpty),
  map((text) =>
    patternMatch<string, HighlightedText>(text)
      .with(
        Pattern.string.regex(LINK_REGEX),
        always({text: match(LINK_REGEX, text)[1], type: 'link'})
      )
      .with(
        Pattern.string.regex(EMAIL_REGEX),
        always({text: match(EMAIL_REGEX, text)[1], type: 'email'})
      )
      .with(
        Pattern.string.regex(USER_REGEX),
        always({text: match(USER_REGEX, text)[1], type: 'user'})
      )
      .otherwise(always({text, type: 'text'}))
  )
);
