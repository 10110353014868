// eslint-disable-next-line no-restricted-imports
import {PathParam, createSearchParams, generatePath} from 'react-router-dom';

import {
  defaultTo,
  identity,
  ifElse,
  mapObjIndexed,
  mergeRight,
  pipe,
  reject,
  toPairs,
  tryCatch,
  isNotEmpty,
} from 'ramda';
import {isNotString, isString} from 'ramda-adjunct';

import {Nullish} from '../types/Nullish';

export type ExtractParams<Path extends string> = {[key in PathParam<Path>]: string | Nullish};

type BaseOptions = {
  queryParams?: Record<string, string | Nullish> | string;
  isSearchParamsPreserved?: boolean;
};

export type OptionsType<Path extends string> =
  ExtractParams<Path> extends Record<string, never>
    ? BaseOptions
    : BaseOptions & {params: ExtractParams<Path>};

/**
 * @about Composes a strictly typed URL path based on the provided route and options.
 * @params path: string, options: {params, queryParams}, isSearchParamsPreserved: boolean
 * @returns {string} The composed URL path.
 * @example composePath("/users/:userId", {params: {userId: "123"}, queryParams: {query: "param"}})
 * // returns "/users/123?query=param"
 */
export const composePath = <Path extends string>(
  path: Path,
  options?: OptionsType<Path>
): string => {
  const params = isWithParams(options) ? mapObjIndexed(defaultTo(null), options.params) : undefined;
  const isSearchParamsPreserved = options?.isSearchParamsPreserved ?? true;
  const url = generatePath(path, params);
  const currentSearchParams = new URL(window.location.href).searchParams;
  const currentSearchParamsRecord = Object.fromEntries(currentSearchParams.entries());

  // Convert the string query params to a record
  const queryParams = getQueryParams(options?.queryParams);
  // Overwrite the duplicate current search params with the new ones
  const mergedSearchParams = mergeRight(currentSearchParamsRecord, queryParams);
  const newSearchParams = isSearchParamsPreserved ? mergedSearchParams : queryParams;
  const validSearchParams = getValidSearchParams(newSearchParams);
  const urlSearchParams = createSearchParams(validSearchParams);
  const isSearchNotEmpty = isNotEmpty([...urlSearchParams.entries()]);
  const searchParamsString = isSearchNotEmpty ? `?${urlSearchParams.toString()}` : '';

  return `${url}${searchParamsString}`;
};

const isWithParams = <Path extends string>(
  val: Record<string, unknown> | undefined
): val is {params: ExtractParams<Path>} => 'params' in (val ?? {});

const getValidSearchParams = pipe(
  defaultTo({}),
  toPairs<Record<string, string>>,
  reject(([_, val]) => isNotString(val))
);

const getQueryParams: (a: BaseOptions['queryParams']) => Record<string, string> = pipe(
  defaultTo({}),
  ifElse(
    isString,
    tryCatch(
      pipe((a) => new URLSearchParams(a).entries(), Object.fromEntries),
      () => {}
    ),
    identity
  )
);
