import {
  IconButton,
  NumberInput,
  openDeleteDialog,
  Parameter,
  Parameters,
} from 'platform/components';
import {Box, Heading, HStack, Text, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';

import {
  useDeleteBasketItemApiMutation,
  usePatchBasketItemQuantityApiMutation,
} from '@omnetic-dms/api';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps, useDebouncedValue} from 'shared';

interface BasketItemProps extends TestIdProps {
  id: string;
  name: string;
  quantity: number;
  subtitle: string;
  parameters: Parameter[];
  onEditClick: () => void;
}

export function BasketItem(props: BasketItemProps) {
  const [deleteBasketItem] = useDeleteBasketItemApiMutation();
  const [patchBasketItemQuantity] = usePatchBasketItemQuantityApiMutation();

  const [quantity, setQuantity] = useState<number | null>(props.quantity ?? null);

  const debouncedQuantity = useDebouncedValue(quantity, 500);

  useEffect(() => {
    if (isNil(debouncedQuantity) || props.quantity === debouncedQuantity) {
      return;
    }

    patchBasketItemQuantity({
      basketItemId: props.id,
      body: {
        quantity: debouncedQuantity,
      },
    })
      .unwrap()
      .catch(handleApiError);
    // props.quantity is missing because is handled in different way in useEffect below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuantity]);

  useEffect(() => {
    if (props.quantity !== debouncedQuantity) {
      setQuantity(props.quantity ?? null);
    }
    // debouncedQuantity is missing because it is handled differently in useEffect above
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.quantity]);

  const handleDeleteWorkItem = () =>
    openDeleteDialog({
      name: props.name,
      onConfirm: () => deleteBasketItem({basketItemId: props.id}).unwrap().catch(handleApiError),
      'data-testid': suffixTestId('delete', props),
    });

  return (
    <HStack>
      <VStack grow={1} spacing={1}>
        <Heading size={5} data-testid={suffixTestId('name', props)}>
          {props.name}
        </Heading>
        <Text size="xSmall" color="tertiary">
          {props.subtitle}
        </Text>
        <Parameters
          color="tertiary"
          parameters={props.parameters}
          data-testid={suffixTestId('warehouse', props)}
        />
      </VStack>
      <HStack spacing={2}>
        <Box width={20}>
          <NumberInput
            value={quantity}
            onChange={setQuantity}
            data-testid={suffixTestId('quantity', props)}
          />
        </Box>
        <IconButton
          icon="image/edit"
          priority="tertiary"
          onClick={props.onEditClick}
          data-testid={suffixTestId('edit', props)}
        />
        <IconButton
          icon="navigation/close"
          priority="tertiary"
          severity="danger"
          onClick={handleDeleteWorkItem}
          data-testid={suffixTestId('delete', props)}
        />
      </HStack>
    </HStack>
  );
}
