export enum RoundStepEnum {
  _0_01 = '0.01',
  _0_05 = '0.05',
  _0_1 = '0.1',
  _0_5 = '0.5',
  _1 = '1',
  _5 = '5',
  _10 = '10',
  _100 = '100',
}
