import {
  Attributes,
  AttributesRow,
  Button,
  Card,
  closeCurrentDialog,
  DialogFooter,
} from 'platform/components';
import {Box, Heading, HStack, Icon, Text, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId} from 'shared';

import {StepContentProps} from '../types/StepContentProps';
import {CancelTransactionButton} from './CancelTransactionButton';

export function SendToSignatoriesStep(props: StepContentProps) {
  const [sellerMetadata, customerMetadata] = props.transaction?.metadata ?? [];

  const customerRows: AttributesRow[] = [
    {label: i18n.t('entity.person.labels.firstName'), value: customerMetadata?.name},
    {label: i18n.t('general.labels.email'), value: customerMetadata?.email},
    {label: i18n.t('general.labels.phone'), value: customerMetadata?.phone},
  ];

  const sellerRows: AttributesRow[] = [
    {label: i18n.t('entity.person.labels.firstName'), value: sellerMetadata?.name},
    {label: i18n.t('general.labels.email'), value: sellerMetadata?.email},
    {label: i18n.t('general.labels.phone'), value: sellerMetadata?.phone},
  ];

  return (
    <VStack spacing={8}>
      <VStack spacing={4} align="center">
        <Icon value="action/mail_upload" size={20} color="general.white" />
        <Heading size={3}>{i18n.t('entity.document.labels.sentByMail')}</Heading>

        <Box width={140}>
          <Text size="small" color="secondary" align="center">
            {i18n.t('entity.document.labels.sentByMailDescription')}
          </Text>
        </Box>
      </VStack>

      <VStack spacing={4}>
        <Heading size={3}>{i18n.t('entity.document.labels.signatories')}</Heading>
        <Card variant="inlineGrey" title={i18n.t('entity.customer.labels.customer')}>
          <Attributes
            size="quarter"
            rows={customerRows}
            data-testid={suffixTestId('customer', props)}
          />
        </Card>
        <Card variant="inlineGrey" title={i18n.t('entity.vehicle.labels.seller')}>
          <Attributes
            size="quarter"
            rows={sellerRows}
            data-testid={suffixTestId('seller', props)}
          />
        </Card>
      </VStack>

      <DialogFooter>
        <HStack justify="space-between">
          <CancelTransactionButton
            transactionId={props.transaction?.transactionId}
            data-testid={suffixTestId('seller', props)}
          />
          <Button
            title={i18n.t('general.actions.finish')}
            onClick={closeCurrentDialog}
            data-testid={suffixTestId('finish', props)}
          />
        </HStack>
      </DialogFooter>
    </VStack>
  );
}
