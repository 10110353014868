import {convertToSearchParams, getFormattedSearchParams} from './getSearchParamsByGridCode';
import {getSourceSearchParams} from './getSourceSearchParams';

export const setSearchParamsByGridCode = (gridCode: string, queryId: string, rowIndex?: string) => {
  const URL = window.location;

  const formattedSearchParams = getFormattedSearchParams();

  const currentUrlWithoutSearchParams = URL.origin + URL.pathname;

  formattedSearchParams[gridCode] = {
    gridCode,
    queryId,
    rowIndex: rowIndex ?? '1',
  };

  const sourceSearchParams = getSourceSearchParams();
  const sourceParams = sourceSearchParams ? `&${sourceSearchParams}` : '';

  window.history.replaceState(
    null,
    '',
    currentUrlWithoutSearchParams +
      '?' +
      new URLSearchParams(convertToSearchParams(formattedSearchParams)) +
      sourceParams
  );
};
