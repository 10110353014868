import {Dialog} from 'platform/components';

import {FC, useRef} from 'react';

import i18n from '@omnetic-dms/i18n';

import {UploadDocumentTemplateForm} from './UploadDocumentTemplateForm';

export interface UploadDocumentTemplateFormContainerProps {
  readonly open: boolean;
  readonly onClose: VoidFunction;
  readonly customTemplateId?: string;
  readonly code: string;
}

export const UploadDocumentTemplateFormContainer: FC<UploadDocumentTemplateFormContainerProps> = ({
  open,
  code,
  onClose,
  customTemplateId,
}) => {
  const form = useRef<HTMLFormElement | null>(null);

  return (
    <Dialog
      size="small"
      isOpen={open}
      onClose={onClose}
      title={
        customTemplateId
          ? i18n.t('entity.documents.labels.uploadEditTemplateDialogTitle')
          : i18n.t('entity.documents.labels.uploadNewTemplateDialogTitle')
      }
    >
      <UploadDocumentTemplateForm
        onClose={onClose}
        customTemplateId={customTemplateId}
        code={code}
        ref={form}
      />
    </Dialog>
  );
};
