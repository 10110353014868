import {match} from 'ts-pattern';

import {i18n} from '@omnetic-dms/i18n';

import {VideoUploadError} from 'features/video-upload-queue';

export const getVideoErrorDescription = (error: VideoUploadError) =>
  match(error)
    .with(VideoUploadError.MAX_VIDEO_SIZE, () => i18n.t('entity.inspection.errors.maxVideoSize'))
    .with(VideoUploadError.GET_PRE_SIGN_LINKS, () =>
      i18n.t('entity.inspection.errors.getPreSignLinks')
    )
    .with(VideoUploadError.UNABLE_TO_UPLOAD_CHUNK, () =>
      i18n.t('entity.inspection.errors.unableToUploadChunk')
    )
    .with(VideoUploadError.UNABLE_TO_COMPLETE_TASK, () =>
      i18n.t('entity.inspection.errors.unableToCompleteTask')
    )
    .exhaustive();
