import {Card} from 'platform/components';

import {FC, PropsWithChildren} from 'react';

import i18n from '@omnetic-dms/i18n';

export const VehicleSeriesDefinitionFormDefaultLayout: FC<PropsWithChildren<any>> = ({
  children,
}) => (
  <Card title={i18n.t('page.vehicleSeriesDefinition.labels.seriesDefinition')}>{children}</Card>
);
