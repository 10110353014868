import {MenuGroup, MenuItem, MenuLink} from './types';

export const isGroup = (obj: MenuItem): obj is MenuGroup => Array.isArray((obj as MenuGroup).items);

export const isItem = (obj: MenuItem): obj is MenuLink =>
  !(obj as MenuItem).hasOwnProperty('items');

export interface MenuFlatItem extends Pick<MenuLink, 'id' | 'label'> {
  parentGroupsIds: MenuGroup['id'][];
}

export type MenuFlatList = MenuFlatItem[];
export function menuItemsTreeToFlatList(items: MenuItem[], parentIds: string[] = []): MenuFlatList {
  const flatList: MenuFlatList = [];

  for (const item of items) {
    flatList.push({id: item.id, label: item.label, parentGroupsIds: parentIds});
    if (isGroup(item)) {
      flatList.push(...menuItemsTreeToFlatList(item.items, [...parentIds, item.id]));
    }
  }

  return flatList;
}
