import {useEffect, useState} from 'react';

import {equals, isEmpty, isNil, not} from 'ramda';

import {EitherQuantityOrError} from '../types/basket/EitherQuantityOrError';

type BaseBasketItem<T> = T & {id: string}[];

export function useInvalidBasketItemsIds<T>(basketItems: BaseBasketItem<T>) {
  const [invalidBasketItemsIds, setInvalidBasketItemsIds] = useState<string[]>([]);

  useEffect(() => {
    if (isNil(basketItems) || isEmpty(basketItems)) {
      // Reset invalidItemsIds
      setInvalidBasketItemsIds((prevInvalidBasketItemsIds) => {
        if (not(isEmpty(prevInvalidBasketItemsIds))) {
          return [];
        }

        return prevInvalidBasketItemsIds;
      });
    } else {
      // If basketItems is not empty, filter out invalid ids that are no longer in the basket
      const basketItemIds = basketItems.map((item) => item.id);

      setInvalidBasketItemsIds((prevInvalidBasketItemsIds) => {
        const filteredIds = prevInvalidBasketItemsIds.filter((id) => basketItemIds.includes(id));

        if (not(equals(prevInvalidBasketItemsIds, filteredIds))) {
          return filteredIds;
        }

        return prevInvalidBasketItemsIds;
      });
    }
  }, [basketItems]);

  const setInvalidBasketItemId = (itemId: string, quantity: EitherQuantityOrError) =>
    // Filter out the current item since we don't know if it's invalid yet
    setInvalidBasketItemsIds((prev) => {
      const updatedInvalidBasketItemsIds = prev.filter((item) => item !== itemId);

      if (quantity.hasError) {
        const invalidItem = basketItems?.find((item) => item.id === itemId);

        if (invalidItem) {
          return [...updatedInvalidBasketItemsIds, invalidItem.id];
        }
      }

      return updatedInvalidBasketItemsIds;
    });

  return {setInvalidBasketItemId, invalidBasketItemsIds};
}
