export const replaceValues = <T extends Record<keyof T, unknown>, P>(
  obj: T,
  replace: string | number | boolean,
  value: P
): T => {
  if (typeof obj === 'object') {
    const _obj: Record<string | number, unknown> = Array.isArray(obj)
      ? ([...obj] as Record<number, unknown>)
      : {...obj};
    const pairs = Object.entries(_obj);

    pairs.forEach((pair) => {
      // typeof null is apparently 'object' for some reason -_-
      if (pair[1] === null) {
        return;
      }

      if (typeof pair[1] === 'object') {
        _obj[pair[0]] = replaceValues(pair[1] as Record<string, unknown>, replace, value);
      } else if (pair[1] === replace) {
        _obj[pair[0]] = value;
      }
    });

    return _obj as T;
  } else {
    return obj;
  }
};
