import {OrderAdditionalCustomerResponseBody} from '@omnetic-dms/api';

import {TestIdProps} from 'shared';

import {CheckoutAdditionalCustomerDetail} from './CheckoutAdditionalCustomerDetail';

interface CheckoutAdditionalCustomersDetailProps extends TestIdProps {
  additionalCustomers: OrderAdditionalCustomerResponseBody[];
  counterStartAt: number;
}

export function CheckoutAdditionalCustomersDetail(props: CheckoutAdditionalCustomersDetailProps) {
  return (
    <>
      {props.additionalCustomers.map((additionalCustomer, index) => (
        <CheckoutAdditionalCustomerDetail
          key={additionalCustomer.id}
          additionalCustomer={additionalCustomer}
          counterStartAt={props.counterStartAt}
          index={index}
        />
      ))}
    </>
  );
}
