import {Actions, showNotification} from 'platform/components';
import {Box, Heading, HStack, Image, Space, Text, VStack} from 'platform/foundation';

import {InsurerRole, usePostMailMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError, useUserFullName, useUserPhoneNumber} from '@omnetic-dms/shared';

import {InsuranceData} from '../../../hooks/useInsuranceData';

interface BecomeSellerFormProps {
  insuranceData: InsuranceData;
  onDone: VoidFunction;
  onCancel: VoidFunction;
}

export function BecomeSellerForm(props: BecomeSellerFormProps) {
  const [sendMail, {isLoading}] = usePostMailMutation();
  const userName = useUserFullName();
  const phoneNumber = useUserPhoneNumber();

  const handleSubmit = () => {
    if (!userName) {
      return showNotification.error(i18n.t('entity.insurance.errors.noUsername'));
    }
    if (!phoneNumber) {
      return showNotification.error(i18n.t('entity.insurance.errors.noPhone'));
    }
    if (!props.insuranceData?.insurancePerson?.email) {
      return showNotification.error(i18n.t('entity.insurance.errors.noEmail'));
    }

    const tenantName =
      props.insuranceData.tenantInfo.contactInformation?.companyName ??
      props.insuranceData.tenantInfo.billingInformation?.[0]?.billingName ??
      '';

    sendMail({
      body: {
        tenantName,
        userName,
        phoneNumber,
        tenantId: props.insuranceData.tenantInfo.id,
        email: props.insuranceData.userInfo.email,
        roleToApply: InsurerRole.SELLER,
      },
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.insurance.labels.interestIsSaved')))
      .then(props.onDone)
      .catch(handleApiError);
  };

  return (
    <HStack spacing={6} justify="stretch" align="stretch">
      <Box padding={6} backgroundColor="palettes.neutral.20.100">
        <Image width={58} src="assets/images/insurance_handshake.png" />
      </Box>
      <VStack spacing={6}>
        <Heading size={3}>{i18n.t('entity.insurance.labels.thankYouForYourInterest')}</Heading>
        <Text size="small">{i18n.t('entity.insurance.labels.weWillContactYou')}</Text>
        <Space fillAvailable />
        <Actions
          align="right"
          actions={[
            {
              type: 'button',
              title: i18n.t('general.actions.cancel'),
              onClick: props.onCancel,
              variant: 'secondary',
            },
            {
              type: 'button',
              title: i18n.t('general.actions.confirm'),
              onClick: handleSubmit,
              isLoading,
            },
          ]}
        />
      </VStack>
    </HStack>
  );
}
