import {
  GetServiceCampaignHyundaiApiArg,
  GetServiceCampaignHyundaiApiResponse,
  GetVehicleServiceCampaignApiResponse,
  GetVehicleServiceCampaignApiArg,
  PostServiceCaseJobServiceCampaignApiArg,
  PostServiceCaseJobServiceCampaignApiResponse,
  PostServiceCaseOrderJobServiceCampaignApiArg,
  PostServiceCaseOrderJobServiceCampaignApiResponse,
  GetVehicleServiceCampaignApiResponseSchema,
  GetVehicleServiceCampaignApiArgSchema,
  PostServiceCaseJobServiceCampaignApiResponseSchema,
  PostServiceCaseJobServiceCampaignApiArgSchema,
  PostServiceCaseOrderJobServiceCampaignApiResponseSchema,
  PostServiceCaseOrderJobServiceCampaignApiArgSchema,
  GetServiceCampaignHyundaiApiResponseSchema,
  GetServiceCampaignHyundaiApiArgSchema,
} from '../types/metadaWorkshopServiceCampaign';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceCampaignApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getVehicleServiceCampaign: build.query<
      GetVehicleServiceCampaignApiResponse,
      GetVehicleServiceCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-campaigns`,
        params: {vehicleId: queryArg.vehicleId},
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'vehicleCampaign', id: queryArg.vehicleId},
      ],
      extraOptions: {
        responseSchema: GetVehicleServiceCampaignApiResponseSchema,
        requestSchema: GetVehicleServiceCampaignApiArgSchema,
      },
    }),
    postServiceCaseJobServiceCampaign: build.mutation<
      PostServiceCaseJobServiceCampaignApiResponse,
      PostServiceCaseJobServiceCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/service-campaign`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
        'vehicleCampaign',
      ],
      extraOptions: {
        responseSchema: PostServiceCaseJobServiceCampaignApiResponseSchema,
        requestSchema: PostServiceCaseJobServiceCampaignApiArgSchema,
      },
    }),
    postServiceCaseOrderJobServiceCampaign: build.mutation<
      PostServiceCaseOrderJobServiceCampaignApiResponse,
      PostServiceCaseOrderJobServiceCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/service-campaign`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        'checkout',
        'serviceOrderTotalAmount',
        'vehicleCampaign',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PostServiceCaseOrderJobServiceCampaignApiResponseSchema,
        requestSchema: PostServiceCaseOrderJobServiceCampaignApiArgSchema,
      },
    }),
    getServiceCampaignHyundaiApi: build.query<
      GetServiceCampaignHyundaiApiResponse,
      GetServiceCampaignHyundaiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-campaign-hyundai/${queryArg.manufacturerActionNumber}`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetServiceCampaignHyundaiApiResponseSchema,
        requestSchema: GetServiceCampaignHyundaiApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetVehicleServiceCampaignQuery,
  usePostServiceCaseJobServiceCampaignMutation,
  usePostServiceCaseOrderJobServiceCampaignMutation,
  useGetServiceCampaignHyundaiApiQuery,
} = metadaWorkshopServiceCampaignApi;
