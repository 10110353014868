import {CustomTenantCatalogueService} from '../../services/CustomTenantCatalogueService';
import {VehicleCatalogueService} from '../../services/VehicleCatalogueService';
import {asyncThunkAction} from '../../utils/reduxThunkUtils';

/**
 * Get makes
 */
export const getVehicleMakes = asyncThunkAction(
  'vehicleCatalogue/getVehicleMakes',
  VehicleCatalogueService.getApiMakes
);

/**
 * Get models
 */
export const getVehicleMakeModels = asyncThunkAction(
  'vehicleCatalogue/getVehicleMakeModels',
  VehicleCatalogueService.getApiMakeModelWithMake
);

/**
 * Get custom tenant catalogue
 */
export const getCustomTenantCatalogue = asyncThunkAction(
  'vehicleCatalogue/getCustomTenantCatalogue',
  CustomTenantCatalogueService.getCustomTenantCatalogue
);
