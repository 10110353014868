import {Checkbox, Dialog, Separator, Search} from 'platform/components';
import {Grid, Heading, Space} from 'platform/foundation';

import {FC, useState} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps, useOnMount} from 'shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {updateAudit} from '../../../store/carAudit/actions';
import {updateAuditFields} from '../../../store/carAudit/reducer';
import {AuditFields} from '../../../store/carAudit/reducerUtils';
import {selectAudit} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage, selectUserName} from '../../../store/user/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {getUpdateRequestBodyFromFormData} from '../../../utils/getUpdateRequestBodyFromFormData';
import {useConditionContext} from '../hooks/useConditionContext';
import {getFormFieldName} from '../utils/getFormFieldName';
import {getTranslation} from '../utils/getTranslation';

type AddNewFeatureProps = {
  isOpen: boolean;
  onClose: () => void;
  categories?: AuditCategoryOfStructure[];
  vehicleFeatures: string[];
  addedFeatures: string[];
  onAddFeatures: (addedFeatures: string[]) => void;
};

export const AddNewFeature: FC<AddNewFeatureProps & TestIdProps> = ({
  isOpen,
  onClose,
  categories,
  vehicleFeatures,
  addedFeatures,
  onAddFeatures,
  ...rest
}) => {
  const dispatch = useThunkDispatch();
  const [featuresForUpdate, setFeaturesForUpdate] = useState<AuditFields>({});
  const [checkedFeatures, setCheckedFeatures] = useState<string[]>([]);
  const [newAddedFeatures, setNewAddedFeatures] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const locale = useSelector(selectUserSelectedLanguage);
  const audit = useSelector(selectAudit);
  const {isCreateVehicle} = useConditionContext();
  const userName = useSelector(selectUserName);
  const {isDisabledForUser} = useConditionContext();

  useOnMount(() => {
    setCheckedFeatures(addedFeatures);
  });

  const handleChange = (
    value: string | null,
    isChecked: boolean,
    categoryId: string,
    paramDefinitionId: string
  ) => {
    if (!value) {
      return;
    }

    const name = getFormFieldName(categoryId, paramDefinitionId);
    const _featuresForUpdate = {...featuresForUpdate};
    if (isChecked) {
      _featuresForUpdate[name] = [value];
      setCheckedFeatures([...checkedFeatures, value]);
      setNewAddedFeatures([...newAddedFeatures, value]);
    } else {
      setCheckedFeatures([...checkedFeatures.filter((feature) => feature !== value)]);
      setNewAddedFeatures([...newAddedFeatures.filter((feature) => feature !== value)]);

      if (addedFeatures.includes(value)) {
        _featuresForUpdate[name] = '';
      } else {
        delete _featuresForUpdate[name];
      }
    }

    setFeaturesForUpdate(_featuresForUpdate);
  };

  const resetStates = () => {
    setCheckedFeatures(addedFeatures);
    setFeaturesForUpdate({});
    setNewAddedFeatures([]);
    setSearchValue(null);
  };

  const handleSave = async (): Promise<void> => {
    if (!audit || isDisabledForUser) {
      return;
    }

    onClose();
    onAddFeatures(newAddedFeatures);

    await dispatch(updateAuditFields(featuresForUpdate));

    if (!isCreateVehicle) {
      await dispatch(
        updateAudit.action({
          auditId: audit.id,
          requestBody: getUpdateRequestBodyFromFormData(featuresForUpdate),
          userName,
        })
      );
    }

    resetStates();
  };

  const handleDiscard = () => {
    resetStates();
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleDiscard}
      scrollBehavior="inside"
      title={i18n.t('entity.vehicle.labels.features')}
      data-testid={suffixTestId('addNewFeature', rest)}
      buttons={[
        {
          variant: 'secondary',
          onClick: handleDiscard,
          title: i18n.t('general.actions.discard'),
          'data-testid': suffixTestId('addNewFeature-discard', rest),
        },
        {
          variant: 'primary',
          onClick: handleSave,
          title: i18n.t('general.labels.addSelected'),
          'data-testid': suffixTestId('addNewFeature-addSelected', rest),
        },
      ]}
    >
      <Search
        value={searchValue}
        onChange={(e) => setSearchValue(e)}
        data-testid={suffixTestId('addNewFeature-search', rest)}
      />
      <Space vertical={4} />
      {categories?.map((category, index) => (
        <div key={`addNewFeature-search-${category.id}-${category.name}`}>
          {index !== 0 && <Separator />}
          <Heading size={4} data-testid={suffixTestId(`addNewFeature-search-[${index}]`, rest)}>
            {getTranslation(locale, category.name)}
          </Heading>
          <Space vertical={4} />
          <Grid columns={2}>
            {category.paramDefinitions?.relatedActions?.map((param) => {
              const filteredValues = param.values.filter((value) => {
                if (!value.label) {
                  return null;
                }

                return getTranslation(locale, value.label)
                  ?.toLocaleLowerCase()
                  ?.includes(searchValue?.toLocaleLowerCase() || '');
              });

              return filteredValues.map(({label, featureKey}, index) => {
                if (
                  !label ||
                  !featureKey ||
                  (vehicleFeatures?.includes(featureKey) && !addedFeatures?.includes(featureKey))
                ) {
                  return null;
                }

                return (
                  <Checkbox
                    key={`${label}-${featureKey}-${index}`}
                    label={getTranslation(locale, label)}
                    value={checkedFeatures.includes(featureKey)}
                    onChange={(checked) => handleChange(featureKey, checked, category.id, param.id)}
                    data-testid={suffixTestId(`addNewFeature-${featureKey}`, rest)}
                  />
                );
              });
            })}
          </Grid>
        </div>
      ))}
    </Dialog>
  );
};
