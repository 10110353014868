import {Button, Dropdown, DropdownItem, showNotification} from 'platform/components';
import {Hide, Show} from 'platform/foundation';

import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  getWorkspaceFromUri,
  InspectionType,
  urlBuilder,
  UrlBuilderConfig,
  useCreateAuditMutation,
  useListThemeQuery,
} from '@omnetic-dms/api';
import {environment} from '@omnetic-dms/environment';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {TestIdProps} from 'shared';

import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {DIGITAL_CERTIFICATE_TRANSLATE_KEYS_MAP} from '../../../types/CommonAppTypes';
import {createSearchParamsString} from '../utils/createSearchParamsString';

export const THEME_ID_QUERY_KEY = 'theme';

export function ShareInspection(props: TestIdProps) {
  const {data: themes, isLoading: isLoadingThemes} = useListThemeQuery();
  const locale = useSelector(selectUserSelectedLanguage);
  const {id: vehicleId} = useParams();
  const [createAuditV2] = useCreateAuditMutation();

  const hasThemes = isNotNilOrEmpty(themes);

  const onShareLink = (themeId?: string) => {
    createAuditV2({
      vehicleId: vehicleId || '',
      body: {
        inspectionType: InspectionType.VEHICLE_CONDITION,
        auditStructureId: undefined,
      },
    })
      .unwrap()
      .then((response) => {
        if (isNilOrEmpty(response.id)) {
          showNotification.error();
          return;
        }
        const {workspace} = getWorkspaceFromUri();

        const builderConfig: UrlBuilderConfig = {
          workspace,
          protocol: window.location.protocol,
          domain: environment.DIGITAL_CERTIFICATE_DOMAIN,
          route: `/${response.id}`,
          searchParam: createSearchParamsString({
            lang: DIGITAL_CERTIFICATE_TRANSLATE_KEYS_MAP[locale],
            [THEME_ID_QUERY_KEY]: themeId,
          }),
        };

        const digitalCertificateUrl = urlBuilder(builderConfig);

        window.open(digitalCertificateUrl, '_blank')?.focus();
      })
      .catch(handleApiError);
  };

  return (
    <>
      <Hide when={hasThemes}>
        <Button
          data-testid={props['data-testid']}
          title={i18n.t('general.actions.shareLink')}
          variant="outlined"
          isLoading={isLoadingThemes}
          leftIcon="social/share"
          onClick={() => {
            onShareLink();
          }}
        />
      </Hide>
      <Show when={hasThemes}>
        <Dropdown
          dropdownControl={
            <Button
              data-testid={props['data-testid']}
              title={i18n.t('general.actions.shareLink')}
              variant="outlined"
              leftIcon="social/share"
              rightIcon="hardware/keyboard_arrow_down"
            ></Button>
          }
        >
          <DropdownItem
            onClick={() => {
              onShareLink();
            }}
            label={i18n.t('general.labels.default')}
          />
          {themes?.map((theme) => (
            <DropdownItem
              onClick={() => {
                onShareLink(theme.themeId);
              }}
              key={theme.themeId}
              label={theme.name}
            />
          ))}
        </Dropdown>
      </Show>
    </>
  );
}
