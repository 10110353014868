import {FlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

// TODO T20-25437 unify with Customer2Module utils
export const getBusinessCaseTypeFlag = (type?: Nullish | string) =>
  match<string | Nullish, FlagProps | undefined>(type)
    .with(
      'BUYING',
      always({
        colorScheme: 'green',
        label: i18n.t('page.businessCase.labels.typeBuying'),
      })
    )
    .with(
      'SELLING',
      always({
        colorScheme: 'orange',
        label: i18n.t('page.businessCase.labels.typeSelling'),
      })
    )
    .with(
      'SWAP',
      always({
        colorScheme: 'purple',
        label: i18n.t('page.businessCase.labels.typeSwap'),
      })
    )
    .with(
      'PURCHASE_BROKERAGE',
      always({
        colorScheme: 'teal',
        label: i18n.t('page.businessCase.labels.typeBrokerage'),
      })
    )
    .otherwise(always(undefined));
