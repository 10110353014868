import {Show} from 'platform/foundation';

import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {TenantProfile as TenantProfileV2} from '../../pages/TenantProfile/TenantProfile';
import {TenantProfile} from './components/TenantProfile';

export function Tenant() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('page.settings.labels.tenantProfile')}}
      data-testid="settings-tenant"
    >
      <Show whenFeatureEnabled={featureFlags.TENANT_V2}>
        <TenantProfileV2 />
      </Show>
      <Show whenFeatureDisabled={featureFlags.TENANT_V2}>
        <TenantProfile />
      </Show>
    </SettingsTemplate>
  );
}
