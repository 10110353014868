import {Button, showNotification} from 'platform/components';

import {FC, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, useRequiredParams, TestIdProps} from 'shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {selectAudit, selectCarAudit} from '../../../store/carAudit/selectors';
import {loadCarDetailsVehicleDetailRequest} from '../../../store/carDetails/actions';
import {useConditionContext} from '../hooks/useConditionContext';

export const VerifyCondition: FC<TestIdProps> = (props) => {
  const {id: vehicleId} = useRequiredParams();
  const dispatch = useThunkDispatch();
  const audit = useSelector(selectAudit);
  const {loading} = useSelector(selectCarAudit);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {loadActualData} = useConditionContext();

  const handleVerify = async (): Promise<void> => {
    if (!audit) {
      return;
    }

    setIsLoading(true);

    const promise = Promise.all([
      await dispatch(loadCarDetailsVehicleDetailRequest({vehicleId})),
      loadActualData(audit.id),
    ]);

    await promise.then(() =>
      showNotification.success(i18n.t('entity.inspection.notifications.conditionAreUpToDate'))
    );

    setIsLoading(false);
  };

  const isDisabled = useMemo(() => {
    if (isLoading) {
      return false;
    }

    const {updateAudit, getAuditAsset, imageUpload, deleteAuditAsset} = loading;

    return updateAudit || getAuditAsset || imageUpload || deleteAuditAsset;
  }, [isLoading, loading]);

  return (
    <Button
      onClick={handleVerify}
      isDisabled={isDisabled}
      isLoading={isLoading}
      data-testid={suffixTestId('verifyCondition', props)}
      title={i18n.t('general.actions.refresh')}
      leftIcon="navigation/refresh"
      variant="outlined"
    />
  );
};
