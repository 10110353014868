export enum UserRole {
  TIPPER = 'TIP',
  SELLER = 'DPZ',
  NONE = 'NONE',
}

export enum InsurerRole {
  TIPPER = 'TIP',
  SELLER = 'DPZ',
}

export enum InsuranceType {
  POV = 'POV',
  POVHAV = 'POV/HAV',
}

export type GetInsuranceOfferApiResponse =
  | /** status 200 Standard response */ ({
      insurance?: InsuranceOffer | null;
      userRole?: UserRole | null;
    } | null)
  | /** status 204 Standard response */ undefined;

export type InsuranceOffer = {
  type?: InsuranceType | null;
  insurance?: number | null;
  insuranceWithDiscount?: number | null;
  commissionYearly?: number | null;
  commissionFull?: number | null;
};

export type InsuranceVehicle = {
  sellingPrice?: string;
  firstRegistrationOnDay?: number;
  firstRegistrationOnMonth?: number;
  firstRegistrationOnYear?: number;
  engineVolume?: number | null;
  power?: number | null;
  make?: string | null;
  type?: string;
  fuelType?: string | null;
  mileage?: number | null;
  weight?: number | null;
  vin?: string;
  modelFamily?: string | null;
  registrationPlate?: string | null;
  vtpNumber?: string | null;
  seatCount?: number | null;
};

export type InsurancePerson = {
  firstName?: string | null;
  lastName?: string | null;
  titleBefore?: string | null;
  companyName?: string | null;
  customerDiscriminator?: string | null;
  selfEmployed?: boolean | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  registrationNumber?: string | null;
  personalIdentifier?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
};

export type GetInsuranceOfferApiArg = {
  body: {
    tenantId?: string;
    insurerNumber?: string;
    vehicle?: InsuranceVehicle | null;
  } | null;
};

export type GetInsuranceComparisonUrlApiResponse =
  | /** status 200 Standard response */ ({
      url?: string | null;
    } | null)
  | /** status 204 Standard response */ undefined;

export type GetInsuranceComparisonUrlApiArg = {
  body: {
    tenantId?: string;
    insurerNumber: string;
    insuranceDraftId?: string | null;
    person?: InsurancePerson | null;
    vehicle?: InsuranceVehicle | null;
  } | null;
};

export type PostCustomerTipApiArg = {
  body: {
    tenantId?: string;
    insurerNumber: string;
    insuranceDraftId?: string | null;
    person?: InsurancePerson | null;
    vehicle?: InsuranceVehicle | null;
  } | null;
};

export type PostMailApiArg = {
  body: {
    tenantId: string;
    tenantName: string;
    userName: string;
    phoneNumber: string;
    email: string;
    roleToApply: InsurerRole;
  } | null;
};

export type InsurancePersonV2 = {
  firstName?: string | null;
  lastName?: string | null;
  titleBefore?: string | null;
  companyName?: string | null;
  customerDiscriminator?: string | null;
  selfEmployed?: boolean | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  registrationNumber?: string | null;
  personalIdentifier?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
};

export type InsuranceVehicleV2 = {
  sellingPrice: string;
  type: string;
  engineVolume?: number | null;
  power?: number | null;
  firstRegistrationOnDay: string;
  firstRegistrationOnMonth: string;
  firstRegistrationOnYear: string;
  fuelType?: string | null;
  mileage?: number | null;
  weight?: number | null;
  seatCount?: number | null;
  vin: string;
  registrationPlate?: string | null;
  vtpNumber?: string | null;
  make?: string | null;
  modelFamily?: string | null;
};

export type CarkPostInsuranceDraftApiResponse =
  | /** status 200 Standard response */ ({
      url?: string | null;
    } | null)
  | /** status 204 Standard response */ undefined;
export type CarkPostInsuranceDraftApiArg = {
  body: {
    insuranceDraftId: string;
    person?: InsurancePersonV2 | null;
    vehicle?: InsuranceVehicleV2 | null;
    tenantId?: string | null;
    insurerNumber: string;
  } | null;
};

export type CarkPostTipApiResponse =
  | /** status 200 Standard response */ ({
      url?: string | null;
    } | null)
  | /** status 204 Standard response */ undefined;

export type CarkPostTipApiArg = {
  body: {
    insuranceDraftId: string;
    person?: InsurancePersonV2 | null;
    vehicle?: InsuranceVehicleV2 | null;
    tenantId?: string | null;
    insurerNumber: string;
  } | null;
};
