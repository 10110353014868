import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetDictionaryWorktypesQuery} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export function useWorkTypeOptions() {
  const {data, isLoading, isError} = useGetDictionaryWorktypesQuery();

  const defaultWorkType = data?.find((type) => type?.isActive && type.isDefault);

  const defaultWorkTypeId = defaultWorkType?.id;

  const workTypeOptions = data
    ?.filter((type) => type?.isActive)
    .map((type) => ({
      label: type?.name,
      value: type?.id,
    }));

  const getOptionsWithSelectedValue = (selectedValue: string | Nullish) => {
    if (isNilOrEmpty(selectedValue)) {
      return workTypeOptions;
    }

    return data
      ?.filter((type) => type?.isActive || selectedValue === type?.id)
      .map((type) => ({
        label: type?.name,
        value: type?.id,
      }));
  };

  const getOptionsWithSelectedValues = (selectedValues: (string | Nullish)[] | Nullish) =>
    data
      ?.filter((type) => type?.isActive || selectedValues?.includes(type?.id ?? ''))
      .map((type) => ({
        label: type?.name,
        value: type?.id,
      }));

  return {
    isError,
    isLoading,
    workTypes: data,
    workTypeOptions,
    defaultWorkType,
    defaultWorkTypeId,
    getOptionsWithSelectedValue,
    getOptionsWithSelectedValues,
  };
}
