import {isFeatureEnabled} from 'feature-flags';
import {showNotification} from 'platform/components';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  InvoiceV3RequestBody,
  useCreateInvoiceMutation,
  useDeleteInvoiceDraftMutation,
  useGetDocumentSeriesDefinitionListQuery,
  useGetTenantQuery,
  useInvoicingDocumentRecalculateMutation,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {accountingRoutes} from '@omnetic-dms/routes';
import {handleApiError, useGetCurrentBranch} from '@omnetic-dms/shared';

import {ACCOUNTING_DOCUMENT_CALCULATION_FIXED_CACHE_KEY, PERCENTAGE_SIGN} from '../../../constants';
import {AccountingDocumentFormValues, InvoiceItem} from '../../../types';

export const useSubmitInvoice = () => {
  const navigate = useNavigate();
  const {data: tenant} = useGetTenantQuery();
  const {id: invoiceDraftId} = useParams();
  const {data: documentSeries} = useGetDocumentSeriesDefinitionListQuery();
  const isAdditionalCustomerEnabled = isFeatureEnabled(featureFlags.ACCOUNTING_ADDITIONAL_CUSTOMER);

  const [_, {data: summary}] = useInvoicingDocumentRecalculateMutation({
    fixedCacheKey: ACCOUNTING_DOCUMENT_CALCULATION_FIXED_CACHE_KEY,
  });

  const {activeBranchId, data: currentBranch} = useGetCurrentBranch();

  const [createInvoice] = useCreateInvoiceMutation();
  const [deleteDraftInvoice] = useDeleteInvoiceDraftMutation();

  const currency = tenant?.currency as string;

  const submitInvoice = async (formData: AccountingDocumentFormValues) => {
    if (isNil(tenant) || isNil(activeBranchId || isNil(currentBranch))) {
      return;
    }

    const items = formData.invoiceItems.map((item: InvoiceItem) => {
      const discount =
        summary?.items.find(
          (summaryItem) => summaryItem.itemId === item.itemId && summaryItem.type === 'discount'
        )?.discount ?? null;

      return {
        currency,
        description: item.description,
        isUnitPriceWithVat: item.isUnitPriceWithVat,
        itemId: item.itemId,
        pricePerUnit: item.pricePerUnit?.toString() ?? '0',
        quantity: item.unit === PERCENTAGE_SIGN ? '1' : (item.quantity?.toString() ?? '0'),
        type: item.type,
        unit: item.unit,
        vatType: item.vatType,
        discount: item.type === 'discount' ? discount : null,
        relatedItemId: item.relatedItemId,
      };
    });

    const bankName =
      currentBranch?.billingInformation?.bankAccounts.find(
        (item) => item.accountNumber === formData.paymentInfo.bankAccount
      )?.bankName ?? '';
    const requestBody: InvoiceV3RequestBody = {
      customer: formData.customer,
      documentSeriesId:
        documentSeries?.find((item) => item.id === formData.documentSeriesId)?.documentSeriesId ??
        formData.documentSeriesId,
      issueDate: formData.issueDate,
      dueDate: formData.dueDate,
      paymentInfo: {
        ...formData.paymentInfo,
        variableSymbol: formData.paymentInfo?.variableSymbol || undefined,
        constantSymbol: formData.paymentInfo?.constantSymbol || undefined,
        bankName,
      },
      dateOfTaxableSupply: formData.dateOfTaxableSupply,
      internalNote: formData.internalNote,
      branchId: activeBranchId,
      currency: tenant?.currency,
      customFields: formData.customFieldsPayload,
      markAsSent: formData.sendAfterCreation ?? false,
      paid: false,
      footerNote: formData.footerNote,
      billingInformationId: currentBranch?.billingInformation?.id ?? '',
      additionalCustomer:
        isNotNil(formData.additionalCustomer) && isAdditionalCustomerEnabled
          ? {
              contractInformationId: formData.additionalCustomer.contractInformationId,
              customerId: formData.additionalCustomer.customer.id,
              type: formData.additionalCustomer.type,
            }
          : null,
      items,
      exchangeRateRatio: formData.isExchangeRateRatioEnabled
        ? {
            amount: parseFloat(formData.exchangeRateRatio?.amount ?? ''),
            currency: formData.exchangeRateRatio?.currency ?? '',
            ratio: formData.exchangeRateRatio?.ratio?.toString() ?? '',
          }
        : null,
      invoiceCategory: formData.isInternal ? 'internal' : null,
    };

    await createInvoice({invoiceV2RequestBody: requestBody})
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.invoice.notifications.invoiceCreated')))
      .then(() => navigate(accountingRoutes.invoiceOverview))
      .then(async () => {
        if (invoiceDraftId) {
          await deleteDraftInvoice({invoiceDraftId}).unwrap().catch(handleApiError);
        }
      })
      .catch(handleApiError);
  };

  return [submitInvoice] as const;
};
