import i18n from '@omnetic-dms/i18n';

export const monthOptions = [
  {
    value: '1',
    label: i18n.t('general.months.January'),
  },
  {
    value: '2',
    label: i18n.t('general.months.February'),
  },
  {
    value: '3',
    label: i18n.t('general.months.March'),
  },
  {
    value: '4',
    label: i18n.t('general.months.April'),
  },
  {
    value: '5',
    label: i18n.t('general.months.May'),
  },
  {
    value: '6',
    label: i18n.t('general.months.June'),
  },
  {
    value: '7',
    label: i18n.t('general.months.July'),
  },
  {
    value: '8',
    label: i18n.t('general.months.August'),
  },
  {
    value: '9',
    label: i18n.t('general.months.September'),
  },
  {
    value: '10',
    label: i18n.t('general.months.October'),
  },
  {
    value: '11',
    label: i18n.t('general.months.November'),
  },
  {
    value: '12',
    label: i18n.t('general.months.December'),
  },
];
