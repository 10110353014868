import * as Yup from 'yup';

import {VehicleTypeEnumObject} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {VehicleSeriesSystemDefinitionRequestBody} from '@omnetic-dms/teas';

import {VehicleSeriesDefinitionFormType} from '../types';

export const $VehicleSeriesDefinitionFormValidations =
  (): Yup.SchemaOf<VehicleSeriesDefinitionFormType> =>
    Yup.object({
      branchId: Yup.string().nullable().defined(i18n.t('general.notifications.errorFieldRequired')),
      seriesId: Yup.string()
        .required(i18n.t('general.notifications.errorFieldRequired'))
        .defined(i18n.t('general.notifications.errorFieldRequired')),
      makes: Yup.array(Yup.string().required() as Yup.StringSchema<string>)
        .transform((i) => {
          if (Array.isArray(i)) {
            return i.length === 0 ? null : i;
          }

          return null;
        })
        .nullable()
        .defined(),
      name: Yup.string()
        .required(i18n.t('general.notifications.errorFieldRequired'))
        .defined(i18n.t('general.notifications.errorFieldRequired')),
      vehicleType: Yup.string()
        .required(i18n.t('general.notifications.errorFieldRequired'))
        .defined(
          i18n.t('general.notifications.errorFieldRequired')
        ) as Yup.StringSchema<VehicleTypeEnumObject>,
    });

export const $VehicleSeriesSystemDefinitionFormValidations =
  (): Yup.SchemaOf<VehicleSeriesSystemDefinitionRequestBody> =>
    Yup.object({
      name: Yup.string()
        .required(i18n.t('general.notifications.errorFieldRequired'))
        .defined(i18n.t('general.notifications.errorFieldRequired')),
    });
