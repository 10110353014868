import {Card} from 'platform/components';
import {Grid} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';

import {
  selectBatteryOwnershipTypes,
  selectBatteryTypes,
  selectChargingConnectorTypes,
  selectFuelTypes,
} from '../../../store/vehicleCatalogue/selectors';
import {VehicleType} from '../../../types/VehicleType';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';

interface BatterySectionProps {
  vehicleType: VehicleType;
}

export const BatterySection: FC<BatterySectionProps> = ({vehicleType}) => {
  const {Field} = useFormRenderer<VehicleCreateFormState>();
  const batteryTypes = useSelector(selectBatteryTypes(vehicleType));
  const connectoryTypes = useSelector(selectChargingConnectorTypes(vehicleType));
  const batteryOwnershipTypes = useSelector(selectBatteryOwnershipTypes(vehicleType));
  const fuelOptions = useSelector(selectFuelTypes(vehicleType));

  if (!fuelOptions.length) {
    return null;
  }

  return (
    <Card variant="inlineGrey" title={i18n.t('entity.vehicle.labels.battery')}>
      <Grid columns={4}>
        <Field
          name="battery.chargingAC"
          as="integer"
          suffix={i18n.t('general.metric.kW')}
          label={i18n.t('entity.vehicle.labels.batteryChargingAc')}
        />
        <Field
          name="battery.chargingTimeAC"
          as="integer"
          suffix={i18n.t('general.metric.h')}
          label={i18n.t('entity.vehicle.labels.batteryChargingTimeAc')}
        />
        <Field
          name="battery.chargingDC"
          as="integer"
          suffix={i18n.t('general.metric.kW')}
          label={i18n.t('entity.vehicle.labels.batteryChargingDc')}
        />
        <Field
          name="battery.chargingTimeDC"
          as="integer"
          suffix={i18n.t('general.metric.min')}
          label={i18n.t('entity.vehicle.labels.batteryChargingTimeDc')}
        />
        <Field
          name="battery.batteryType"
          as="select"
          options={batteryTypes}
          label={i18n.t('entity.vehicle.labels.batteryType')}
        />
        <Field
          name="battery.batteryCapacityWh"
          as="integer"
          suffix={i18n.t('general.metric.kWh')}
          label={i18n.t('entity.vehicle.labels.batteryCapacity')}
        />
        <Field
          name="battery.batteryCapacityAh"
          as="integer"
          suffix={i18n.t('general.metric.Ah')}
          label={i18n.t('entity.vehicle.labels.batteryCapacity')}
        />
        <Field
          name="battery.batteryVoltage"
          as="integer"
          suffix={i18n.t('general.metric.V')}
          label={i18n.t('entity.vehicle.labels.batteryVoltage')}
        />
        <Field
          name="battery.batteryCount"
          as="integer"
          numberProps={{isStepperVisible: true}}
          label={i18n.t('entity.vehicle.labels.batteryCount')}
        />
        <Field
          name="battery.chargingConnectorType"
          as="select"
          options={connectoryTypes}
          label={i18n.t('entity.vehicle.labels.batteryChargingConnectoryType')}
        />
        <Field
          name="battery.electricRange"
          as="integer"
          suffix={i18n.t('general.metric.km')}
          label={i18n.t('entity.vehicle.labels.batteryElectricRange')}
        />
        <Field
          name="battery.batteryMileageLimit"
          as="integer"
          suffix={i18n.t('general.metric.km')}
          label={i18n.t('entity.vehicle.labels.batteryMileageLimit')}
        />
        <Field
          name="battery.batteryWarrantyUntil"
          as="date"
          label={i18n.t('entity.vehicle.labels.batteryWarrantyUntil')}
        />
        <Field
          name="battery.batteryOwnershipType"
          as="select"
          options={batteryOwnershipTypes}
          label={i18n.t('entity.vehicle.labels.batteryOwnershipType')}
        />
      </Grid>
      <Grid columns={4}>
        <Field
          name="battery.hasRangeExtender"
          as="checkbox"
          label={i18n.t('entity.vehicle.labels.hasRangeExtender')}
        />
      </Grid>
    </Card>
  );
};
