import {match, Pattern} from 'ts-pattern';

import {always, identity} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

type RedirectId = string;

export const getSubmoduleIdFromQueryString = (query: string): RedirectId | null =>
  match<string | null, RedirectId | null>(new URLSearchParams(query).get('submoduleId'))
    .with(Pattern.when(isNotNilOrEmpty), identity)
    .otherwise(always(null));
