import {suffixTestId, Nullish, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {HTMLAutocomplete} from '../TextInput/HTMLAutocomplete';
import {TextInput} from '../TextInput/TextInput';

export interface EmailInputProps extends FormControlProps<string | null>, TestIdProps {
  autoComplete?: HTMLAutocomplete;
  placeholder?: string | Nullish;
  isRecommended?: boolean;
}

export function EmailInput(props: EmailInputProps) {
  return (
    <TextInput
      {...props}
      type="email"
      placeholder={props.placeholder}
      isRecommended={props.isRecommended}
      data-testid={suffixTestId('emailInput', props)}
    />
  );
}
