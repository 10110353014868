import {Checkbox} from 'platform/components';

import {FC} from 'react';

import {MakeAndChecked} from './types';

export interface TopMakeRowProps {
  readonly make: MakeAndChecked;
  readonly handleRowToggle: (makeCode: string) => void;
}

export const TopMakeRow: FC<TopMakeRowProps> = ({make, handleRowToggle}) => (
  <Checkbox
    label={make.label}
    value={!!make.checked}
    onChange={() => handleRowToggle(make.value)}
  />
);
