import {Icon} from 'platform/foundation';
import styled from 'styled-components';

import {suffixTestId, TestIdProps} from 'shared';

import {Button} from '../../Button/Button';
import {SEPARATOR} from '../constants/separator';

interface PaginationPageProps extends TestIdProps {
  page: number;
  activePage: number;
  changePage: (newPage: number) => void;
  isDisabled?: boolean;
}

export function PaginationPage(props: PaginationPageProps) {
  const isActive = props.activePage === props.page;
  const isNeedSeparator = props.page === SEPARATOR;

  if (isNeedSeparator) {
    return (
      <StyledPageSeparator data-testid={suffixTestId('paginationPageWrapper', props)}>
        <Icon value="navigation/more_horiz" />
      </StyledPageSeparator>
    );
  }

  return (
    <Button
      key={`button_${props.page}`}
      onClick={!props.isDisabled ? () => props.changePage(props.page) : undefined}
      isDisabled={props.isDisabled}
      variant={isActive ? 'primary' : 'ghost'}
      size="small"
      data-testid={suffixTestId('paginationPageButton', props)}
      title={String(props.page)}
    />
  );
}

const StyledPageSeparator = styled.div`
  padding: 4px;

  & svg {
    width: 16px;
  }
`;
