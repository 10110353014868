import {keys} from 'ramda';

import {forceHiddenStyles} from './forceHiddenStyles';
import {SizingData} from './getSizingData';

export type CalculatedNodeHeights = number[];

let hiddenTextarea: HTMLTextAreaElement | null = null;

// If there are no value or placeholder, use a single character as the default to measure height of single row.
const CHARACTER = 'x';

/**
 * @about Calculates the heights needed for a textarea to accurately reflect the provided text.
 * @param {SizingData} sizingData - The object containing padding, border size, and box sizing style.
 * @param {string} value - The value (text content) to measure within the textarea.
 * @param {number} [minRows=1] - The minimum number of rows to display in the textarea.
 * @param {number} [maxRows=Infinity] - The maximum number of rows to display in the textarea.
 * @returns {CalculatedNodeHeights} - An object containing both the calculated height and single row height.
 */
export function calculateNodeHeight(
  sizingData: SizingData,
  value = CHARACTER,
  minRows = 1,
  maxRows = Infinity
): CalculatedNodeHeights {
  if (!hiddenTextarea) {
    hiddenTextarea = document.createElement('textarea');
    hiddenTextarea.setAttribute('tabindex', '-1');
    forceHiddenStyles(hiddenTextarea);
  }

  if (hiddenTextarea.parentNode === null) {
    document.body.appendChild(hiddenTextarea);
  }

  const {paddingSize, borderSize, sizingStyle} = sizingData;
  const {boxSizing} = sizingStyle;

  keys(sizingStyle).forEach((key) => {
    hiddenTextarea!.style[key] = sizingStyle[key];
  });

  forceHiddenStyles(hiddenTextarea);

  hiddenTextarea.value = value;
  let height = getHeight(hiddenTextarea, sizingData);
  // Double set and calc due to Firefox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1795904
  hiddenTextarea.value = value;
  height = getHeight(hiddenTextarea, sizingData);

  // measure height of a textarea with a single row
  hiddenTextarea.value = CHARACTER;
  const rowHeight = hiddenTextarea.scrollHeight - paddingSize;

  let minHeight = rowHeight * minRows;
  if (boxSizing === 'border-box') {
    minHeight = minHeight + paddingSize + borderSize;
  }
  height = Math.max(minHeight, height);

  let maxHeight = rowHeight * maxRows;
  if (boxSizing === 'border-box') {
    maxHeight = maxHeight + paddingSize + borderSize;
  }
  height = Math.min(maxHeight, height);

  return [height, rowHeight];
}

const getHeight = (node: HTMLElement, sizingData: SizingData): number => {
  const height = node.scrollHeight;

  if (sizingData.sizingStyle.boxSizing === 'border-box') {
    // border-box: add border, since height = content + padding + border
    return height + sizingData.borderSize;
  }

  // remove padding, since height = content
  return height - sizingData.paddingSize;
};
