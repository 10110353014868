import {Children, Fragment, isValidElement, ReactNode} from 'react';

export function flattenReactNode(children: ReactNode): Array<ReactNode> {
  const array: Array<ReactNode> = [];
  _flattenReactNode(array, children);
  return array;
}

function _flattenReactNode(target: Array<ReactNode>, children: ReactNode) {
  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      if (child.type === Fragment) {
        _flattenReactNode(target, child.props.children);
      } else {
        target.push(child);
      }
    }
  });
}
