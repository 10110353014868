import {createSelector} from '@reduxjs/toolkit';

import type {TeasState} from '../../types/TeasState';

export const selectAdvertisement = (state: TeasState) => state.sales.advertisement;

export const selectWindshieldTemplates = createSelector(
  selectAdvertisement,
  (state) => state.windshieldTemplates
);
