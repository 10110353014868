import {useAnimatedPopper} from 'platform/components';
import {Icon, Box, Center, Clickable} from 'platform/foundation';

import {MenuItemProps} from '@omnetic-dms/api';

import {MenuItem} from './MenuItem';

interface HamburgerProps {
  menuItems: Array<MenuItemProps>;
  pinnedMenuItems: Array<string | undefined> | undefined;
}

export function Hamburger(props: HamburgerProps) {
  const {togglePopper, openPopper, referenceRef, popperProps, Popper, closePopper, isOpen} =
    useAnimatedPopper({
      placement: 'right-start',
    });

  return (
    <Clickable onClick={togglePopper} onMouseEnter={openPopper} onMouseLeave={closePopper}>
      <section ref={referenceRef}>
        <Box
          width={12}
          height={12}
          backgroundColor={isOpen ? 'palettes.blue.70.100' : 'palettes.neutral.900.100'}
          data-testid="layout-hamburgerButton"
        >
          <Center width="100%" height="100%">
            <Icon value="navigation/menu" size={6} color="palettes.neutral.10.100" />
          </Center>
        </Box>
      </section>
      <Popper {...popperProps}>
        <Box paddingBottom={12} paddingRight={12} data-testid="layout-hamburgerMenuContainer">
          {props.menuItems.map((module) => (
            <MenuItem
              key={`${module.id}_${module.path}_${module.parentId}`}
              isHamburgerMenuItem
              module={module}
              isPinned={props?.pinnedMenuItems?.includes(module.id)}
            />
          ))}
        </Box>
      </Popper>
    </Clickable>
  );
}
