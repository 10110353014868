import {testIds} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {VehicleInterestsCard} from './components/VehicleInterestsCard';

export function Interests() {
  return (
    <Main data-testid={testIds.vehicles.interests('page')}>
      <VehicleInterestsCard />
    </Main>
  );
}
