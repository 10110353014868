import {Card, ColorSchemeType, Flag, FormControl, FormField} from 'platform/components';
import {Box, Heading, Hide, HStack, Scroll, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {always, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useTireSetOptions} from '../../hooks/useTireSetOptions';
import {ExistingWheelType} from '../../types/tires/existingWheelType';
import {TireSetFormType} from '../../types/tires/TireSetFormType';

type WheelKey = keyof TireSetFormType['wheels']['0'];

interface WheelArrayProps extends TestIdProps {
  control: FormControl<TireSetFormType>;
  formApi: UseFormReturn<TireSetFormType>;
  existingWheels?: ExistingWheelType;
  readOnly?: boolean;
}

export function WheelArray(props: WheelArrayProps) {
  const {
    seasonOptions,
    rimOptions,
    getLoactionOptionsWithSelectedValue,
    getManufacturerOptionsWithSelectedValue,
  } = useTireSetOptions();
  const [firstWheel, setFirstWheel] = useState(props.formApi.getValues('wheels')[0]);

  const handlePropagateValue = (key: WheelKey, index: number) => {
    const wheels = props.formApi.getValues('wheels');
    const oldValue = {...firstWheel}[key];
    const value = wheels[0][key];

    if (index !== 0 || wheels.length === 1 || oldValue === value) {
      return;
    }

    wheels.forEach((wheel, wheelIndex) => {
      if (wheelIndex === 0 || wheel[key] !== oldValue) {
        return;
      }

      props.formApi.setValue(`wheels.${wheelIndex}.${key}`, value, {
        shouldTouch: false,
        shouldValidate: true,
      });
    });
    setFirstWheel({...wheels[0]});
  };

  const getWheelStatus = (wheelId: string | Nullish) => {
    if (isNil(wheelId)) {
      return;
    }

    const state = props.existingWheels?.find((wheel) => wheel?.id === wheelId)?.state;

    const label = match(state)
      .with('ARCHIVED', always(i18n.t('entity.tireSet.labels.archived')))
      .with('INSTOCK', always(i18n.t('entity.tireSet.labels.inStock')))
      .with('PICKED', always(i18n.t('entity.tireSet.labels.picked')))
      .otherwise(always(undefined));
    const colorScheme = match<string | Nullish, ColorSchemeType | undefined>(state)
      .with('ARCHIVED', always('neutral'))
      .with('INSTOCK', always('green'))
      .with('PICKED', always('red'))
      .otherwise(always(undefined));

    return {label, colorScheme};
  };

  return (
    <Scroll auto maxWidth="100%">
      <HStack spacing={4}>
        {props.formApi.watch('wheels').map(({id}, index) => {
          const wheelStatus = getWheelStatus(id);
          const locationOptions = getLoactionOptionsWithSelectedValue(
            props.existingWheels?.find((wheel) => wheel?.id === id)?.wheelDetails?.location
          );
          const manufacturerOptions = getManufacturerOptionsWithSelectedValue(
            props.existingWheels?.find((wheel) => wheel?.id === id)?.wheelDetails?.manufacturer
          );

          return (
            <Card key={id} variant="inlineGrey">
              <VStack spacing={4} minWidth={55}>
                <HStack spacing={3}>
                  <FormField
                    control={props.control}
                    name={`wheels.${index}.checked`}
                    type="checkbox"
                    data-testid={suffixTestId(`${index}-selected`, props)}
                  />
                  <Heading size={4}>
                    {`${index + 1}. ${i18n.t('entity.tireSet.labels.wheel')}`}
                  </Heading>
                  <Hide when={isNilOrEmpty(wheelStatus)}>
                    <Flag
                      label={wheelStatus?.label ?? ''}
                      isSubtle
                      colorScheme={wheelStatus?.colorScheme}
                      data-testid={suffixTestId(`${index}-wheelStatus`, props)}
                    />
                  </Hide>
                </HStack>
                <FormField
                  control={props.control}
                  name={`wheels.${index}.manufacturer`}
                  type="choice"
                  label={i18n.t('entity.tireSet.labels.manufacturer')}
                  options={manufacturerOptions}
                  onChange={() => handlePropagateValue('manufacturer', index)}
                  menuInPortal
                  isDisabled={props.readOnly}
                  data-testid={suffixTestId(`${index}-manufacturer`, props)}
                  isRequired
                  isNotClearable
                />
                <HStack spacing={3}>
                  <Box flex={1}>
                    <FormField
                      control={props.control}
                      name={`wheels.${index}.tireWidth`}
                      type="text"
                      label={i18n.t('entity.tireSet.labels.tireWidth')}
                      onBlur={() => handlePropagateValue('tireWidth', index)}
                      isDisabled={props.readOnly}
                      data-testid={suffixTestId(`${index}-tireWidth`, props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={props.control}
                      name={`wheels.${index}.aspectRatio`}
                      type="text"
                      label={i18n.t('entity.tireSet.labels.aspectRatio')}
                      onBlur={() => handlePropagateValue('aspectRatio', index)}
                      isDisabled={props.readOnly}
                      data-testid={suffixTestId(`${index}-aspectRatio`, props)}
                    />
                  </Box>
                </HStack>
                <HStack spacing={3}>
                  <Box flex={1}>
                    <FormField
                      control={props.control}
                      name={`wheels.${index}.rim`}
                      type="text"
                      label={i18n.t('entity.tireSet.labels.rim')}
                      onBlur={() => handlePropagateValue('rim', index)}
                      isDisabled={props.readOnly}
                      data-testid={suffixTestId(`${index}-rim`, props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={props.control}
                      name={`wheels.${index}.tread`}
                      type="text"
                      label={i18n.t('entity.tireSet.labels.tread')}
                      suffix="mm"
                      onBlur={() => handlePropagateValue('tread', index)}
                      isDisabled={props.readOnly}
                      data-testid={suffixTestId(`${index}-tread`, props)}
                    />
                  </Box>
                </HStack>
                <HStack spacing={3}>
                  <Box flex={1}>
                    <FormField
                      control={props.control}
                      name={`wheels.${index}.season`}
                      type="choice"
                      label={i18n.t('entity.tireSet.labels.season')}
                      options={seasonOptions}
                      onChange={() => handlePropagateValue('season', index)}
                      menuInPortal
                      isDisabled={props.readOnly}
                      data-testid={suffixTestId(`${index}-season`, props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={props.control}
                      name={`wheels.${index}.rimType`}
                      type="choice"
                      label={i18n.t('entity.tireSet.labels.rimType')}
                      options={rimOptions}
                      onChange={() => handlePropagateValue('rimType', index)}
                      menuInPortal
                      isDisabled={props.readOnly}
                      data-testid={suffixTestId(`${index}-rimType`, props)}
                    />
                  </Box>
                </HStack>
                <HStack spacing={3}>
                  <Box flex={1}>
                    <FormField
                      control={props.control}
                      name={`wheels.${index}.li`}
                      type="text"
                      label={i18n.t('entity.tireSet.labels.li')}
                      onBlur={() => handlePropagateValue('li', index)}
                      isDisabled={props.readOnly}
                      data-testid={suffixTestId(`${index}-li`, props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={props.control}
                      name={`wheels.${index}.sr`}
                      type="text"
                      label={i18n.t('entity.tireSet.labels.sr')}
                      onBlur={() => handlePropagateValue('sr', index)}
                      isDisabled={props.readOnly}
                      data-testid={suffixTestId(`${index}-sr`, props)}
                    />
                  </Box>
                </HStack>
                <HStack spacing={3}>
                  <Box flex={1}>
                    <FormField
                      control={props.control}
                      name={`wheels.${index}.dot`}
                      type="text"
                      label={i18n.t('entity.tireSet.labels.dot')}
                      onBlur={() => handlePropagateValue('dot', index)}
                      isDisabled={props.readOnly}
                      data-testid={suffixTestId(`${index}-dot`, props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={props.control}
                      name={`wheels.${index}.location`}
                      type="choice"
                      label={i18n.t('entity.tireSet.labels.location')}
                      options={locationOptions}
                      menuInPortal
                      onChange={() => handlePropagateValue('location', index)}
                      isDisabled={props.readOnly}
                      data-testid={suffixTestId(`${index}-location`, props)}
                      isRequired
                      isNotClearable
                    />
                  </Box>
                </HStack>
                <FormField
                  control={props.control}
                  name={`wheels.${index}.note`}
                  type="textarea"
                  label={i18n.t('general.labels.note')}
                  onBlur={() => handlePropagateValue('note', index)}
                  isDisabled={props.readOnly}
                  data-testid={suffixTestId(`${index}-note`, props)}
                />
              </VStack>
            </Card>
          );
        })}
      </HStack>
    </Scroll>
  );
}
