import {Button, ButtonGroup, Card, DataStatus, RadioItem} from 'platform/components';
import {Clickable, Heading, HStack, Icon, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useState} from 'react';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, sortByProp} from 'ramda-adjunct';

import {usePostAvailableXentryOrdersMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {
  suffixTestId,
  RequiredTestIdProps,
  Nullish,
  parseDate,
  useBoolean,
  DOT_CHARACTER,
  LiteralUnionAutocomplete,
  EMPTY_PLACEHOLDER,
} from 'shared';

interface LinkXentryProps extends RequiredTestIdProps {
  linkedXentryId?: string | Nullish;
  serviceCaseId: string;
  onLink: (selectedOrder: string) => void | Promise<unknown>;
  onLinkNew: () => void | Promise<unknown>;
  onClose: () => void;
}

const CREATE_NEW = 'CREATE_NEW';

export function LinkXentry(props: LinkXentryProps) {
  const [, {data, isLoading: isLoadingOrders}] = usePostAvailableXentryOrdersMutation({
    fixedCacheKey: 'open-link-xentry',
  });

  const formatDateTime = useDateTimeFormatter();

  const [selectedOrder, setSelectedOrder] = useState<
    LiteralUnionAutocomplete<typeof CREATE_NEW> | undefined
  >(props.linkedXentryId ?? undefined);
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const handleLinkJob = async () => {
    if (!selectedOrder) {
      return;
    }
    startLoading();
    if (selectedOrder === CREATE_NEW) {
      await props.onLinkNew();
    } else {
      await props.onLink(selectedOrder);
    }
    stopLoading();
  };

  const sortedJobs = sortByProp(
    'createdAtDate',
    data?.xentryJob?.map((job) => ({
      ...job,
      createdAtDate: parseDate(job.createdAt) ?? new Date(),
    })) ?? []
  ).reverse();

  return (
    <VStack spacing={4}>
      <Text color="tertiary" size="small">
        {i18n.t('entity.integration.labels.selectXentryOrderToLink')}
      </Text>
      <VStack spacing={2}>
        <DataStatus isLoading={isLoadingOrders} isEmpty={isNilOrEmpty(data?.xentryJob)}>
          {sortedJobs.map((job, index) => (
            <Clickable
              key={job.id}
              onClick={() => job.id && setSelectedOrder(job.id)}
              data-testid={suffixTestId(`order-[${index}]`, props)}
            >
              <Card variant="inlineGrey">
                <HStack justify="space-between" align="center">
                  <HStack spacing={2} align="flex-start">
                    <RadioItem
                      value={job.id === selectedOrder}
                      data-testid={suffixTestId(`order-[${index}]`, props)}
                    />
                    <VStack spacing={1}>
                      <Heading size={4} data-testid={suffixTestId(`order-[${index}]-id`, props)}>
                        {job.id}
                      </Heading>
                      <HStack spacing={1} align="center">
                        <Text color="secondary" size="small">
                          {`${i18n.t('general.labels.dateCreated')}: ${formatDateTime('dateTimeShort', parseDate(job.createdAt) ?? new Date())}`}
                        </Text>
                        <Show
                          when={job.id === props.linkedXentryId && isNotNilOrEmpty(job.linkedAt)}
                        >
                          <Text color="tertiary" size="small">
                            {DOT_CHARACTER}
                          </Text>
                          <Text color="secondary" size="small">
                            {`${i18n.t('entity.integration.labels.linkedAt')}: ${job.linkedAt ? formatDateTime('dateTimeShort', parseDate(job.linkedAt)) : EMPTY_PLACEHOLDER}`}
                          </Text>
                        </Show>
                        <Show when={job.id === props.linkedXentryId}>
                          <Text color="tertiary" size="small">
                            {DOT_CHARACTER}
                          </Text>
                          <Icon value="content/link" size={4} color="severity.informational" />
                          <Text color="link" size="small">
                            {i18n.t('entity.integration.actions.alreadyLinked')}
                          </Text>
                        </Show>
                      </HStack>
                    </VStack>
                  </HStack>
                  <Button
                    title={i18n.t('general.actions.viewDetail')}
                    variant="link"
                    leftIcon="action/open_in_new"
                    isDisabled={isNilOrEmpty(job.url)}
                    onClick={() => window.open(job.url ?? '', '_blank')}
                    data-testid={suffixTestId(`order-[${index}]-detail`, props)}
                  />
                </HStack>
              </Card>
            </Clickable>
          ))}
          <Clickable
            onClick={() => setSelectedOrder(CREATE_NEW)}
            data-testid={suffixTestId(`order-new`, props)}
          >
            <Card variant="inlineGrey">
              <HStack justify="space-between" align="center">
                <HStack spacing={2} align="flex-start">
                  <RadioItem
                    value={selectedOrder === CREATE_NEW}
                    data-testid={suffixTestId(`order-new`, props)}
                  />
                  <Heading size={4} data-testid={suffixTestId(`order-new-id`, props)}>
                    {i18n.t('entity.integration.labels.newXentryOrder')}
                  </Heading>
                </HStack>
              </HStack>
            </Card>
          </Clickable>
        </DataStatus>
        <ButtonGroup align="right">
          <Button
            title={i18n.t('general.actions.discard')}
            onClick={props.onClose}
            variant="secondary"
            data-testid={suffixTestId('close', props)}
          />
          <Button
            title={i18n.t('entity.integration.actions.link')}
            isDisabled={isNil(selectedOrder) || isLoading || selectedOrder === props.linkedXentryId}
            variant="primary"
            onClick={handleLinkJob}
            isLoading={isLoading}
            data-testid={suffixTestId('link', props)}
          />
        </ButtonGroup>
      </VStack>
    </VStack>
  );
}
