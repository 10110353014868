import {useSelector} from 'react-redux';

import {Nullish} from 'shared';

import {selectVehicleMakeModel} from '../store/vehicleCatalogue/selectors';
import {VehicleCatalogueMakeModelItem} from '../types/VehicleCatalogueMakeModelItem';

export type VehicleTitleProps = {
  make?: VehicleCatalogueMakeModelItem | string | null;
  modelFamily?: string | null;
  trim?: string | null;
  variant?: string | null;
};

const trimItems = (...values: (string | undefined | null)[]) =>
  values.filter((i): i is string => Boolean(i)).join(' ');

export const vehicleTitle = ({make, modelFamily, trim, variant}: VehicleTitleProps) => {
  const makeKey = typeof make === 'string' ? make : make?.value;
  const model =
    typeof make === 'string'
      ? modelFamily
      : (make?.models?.find((model) => model.value === modelFamily)?.label ?? modelFamily);

  return trimItems(makeKey, model, trim, variant);
};

export type VehicleTitleParams = {
  make?: string | null;
  customMake?: string | null;
  modelFamily?: string | null;
  customModelFamily?: string | null;
  trim?: string | null;
  variant?: string | null;
  title?: string | null;
};

export const useVehicleTitle = (vehicle?: VehicleTitleParams | Nullish) => {
  const makeModels = useSelector(selectVehicleMakeModel(vehicle?.make));
  if (vehicle?.title) {
    return vehicle.title;
  }
  return vehicleTitle({
    ...(vehicle ?? {}),
    make: makeModels?.label,
    modelFamily: makeModels?.models.find((model) => model.value === vehicle?.modelFamily)?.label,
  });
};

export const createVehicleTitle = (
  vehicle: VehicleTitleParams,
  makeModels?: VehicleCatalogueMakeModelItem
) =>
  vehicleTitle({
    ...(vehicle ?? {}),
    make: makeModels?.label,
    modelFamily: makeModels?.models?.find((model) => model.value === vehicle?.modelFamily)?.label,
  });
