import styled from 'styled-components';

export const MenuItemActionButton = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({theme}) => theme.getSize(1)};
  &:last-child {
    padding-right: ${({theme}) => theme.getSize(2)};
  }
  &:first-child {
    padding-left: ${({theme}) => theme.getSize(2)};
  }
`;
