import {z} from 'zod';

import {
  AssignedMechanicSchema,
  BasePriceSchema,
  BasketTooltipSchema,
  PriceWithAndWithoutVatSchema,
} from './aftersalesCommon';
import {BaseVoidResponseSchema} from './base';

/**
 * Base
 */
export const ServiceOrderIssueNoteBasketItemSchema = z.object({
  id: z.string(),
  serviceOrderJobItemId: z.string(),
  serviceCaseJobId: z.string(),
  itemEditingAllowed: z.boolean(),
  warehouseId: z.string(),
  warehousArticleId: z.string(),
  warehouseName: z.string(),
  requestType: z.string(),
  name: z.string(),
  number: z.string(),
  quantity: z.number(),
  unit: z.string(),
  dispensingUnit: z.number(),
  quantityEditingAllowed: z.boolean(),
  storage_location: z.string(),
  unitPrice: PriceWithAndWithoutVatSchema,
  totalPrice: PriceWithAndWithoutVatSchema,
  purchasePrice: z.object({
    amount: BasePriceSchema,
  }),
  tooltip: z.array(BasketTooltipSchema),
});
export type ServiceOrderIssueNoteBasketItem = z.infer<typeof ServiceOrderIssueNoteBasketItemSchema>;

/**
 * GET
 */
export const GetServiceOrderIssueNoteBasketRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
});
export type GetServiceOrderIssueNoteBasketRequest = z.infer<
  typeof GetServiceOrderIssueNoteBasketRequestSchema
>;

export const GetServiceOrderIssueNoteBasketResponseSchema = z
  .object({
    sparePartsBasketItem: z.array(ServiceOrderIssueNoteBasketItemSchema).optional(),
    sparePartsBasketTotalPrice: PriceWithAndWithoutVatSchema.optional(),
    assignMechanicId: z.string().optional(),
  })
  .nullish();
export type GetServiceOrderIssueNoteBasketResponse = z.infer<
  typeof GetServiceOrderIssueNoteBasketResponseSchema
>;

/**
 * POST
 */

/**
 * PATCH
 */
export const PatchServiceOrderIssueNoteBasketItemQuantityRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
  itemId: z.string(),
  body: z.object({
    quantity: z.number(),
  }),
});
export type PatchServiceOrderIssueNoteBasketItemQuantityRequest = z.infer<
  typeof PatchServiceOrderIssueNoteBasketItemQuantityRequestSchema
>;

export const PatchServiceOrderIssueNoteBasketItemQuantityResponseSchema = BaseVoidResponseSchema;
export type PatchServiceOrderIssueNoteBasketItemQuantityResponse = z.infer<
  typeof PatchServiceOrderIssueNoteBasketItemQuantityResponseSchema
>;

export const PatchServiceOrderIssueNoteBasketCheckoutRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
  body: z.object({
    mechanic: AssignedMechanicSchema,
  }),
});
export type PatchServiceOrderIssueNoteBasketCheckoutRequest = z.infer<
  typeof PatchServiceOrderIssueNoteBasketCheckoutRequestSchema
>;

export const PatchServiceOrderIssueNoteBasketCheckoutResponseSchema = BaseVoidResponseSchema;
export type PatchServiceOrderIssueNoteBasketCheckoutResponse = z.infer<
  typeof PatchServiceOrderIssueNoteBasketCheckoutResponseSchema
>;

/**
 * DELETE
 */
export const DeleteServiceOrderIssueNoteBasketRequestSchema = z.object({
  serviceOrderIssueNoteId: z.string(),
});

export type DeleteServiceOrderIssueNoteBasketRequest = z.infer<
  typeof DeleteServiceOrderIssueNoteBasketRequestSchema
>;

export const DeleteServiceOrderIssueNoteBasketResponseSchema = BaseVoidResponseSchema;
export type DeleteServiceOrderIssueNoteBasketResponse = z.infer<
  typeof DeleteServiceOrderIssueNoteBasketResponseSchema
>;
