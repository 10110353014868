import {Button, closeCurrentDialog, openDialog} from 'platform/components';
import {Space} from 'platform/foundation';

import {useParams} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';

import {TestIdProps} from 'shared';

import {CustomerVerificationResult} from './components/CustomerVerificationResult';
import {VerificationServicesForm} from './components/VerificationServicesForm';

interface CustomerVerificationProps extends TestIdProps {
  contractInformationId: string;
}
/**
 * @deprecated
 */
export function CustomerVerification(props: CustomerVerificationProps) {
  const {id: businessCaseId} = useParams();

  if (!businessCaseId) {
    throw new Error('Param "businessCaseId" was not found in URL');
  }

  const startVerification = () => {
    openDialog(
      <VerificationServicesForm
        onCancel={closeCurrentDialog}
        onSuccess={closeCurrentDialog}
        contractInformationId={props.contractInformationId}
        businessCaseId={businessCaseId}
      />,
      {
        title: i18n.t('entity.customerVerification.actions.verify'),
      }
    );
  };

  return (
    <>
      <Space vertical={1} />
      <CustomerVerificationResult
        contractInformationId={props.contractInformationId}
        businessCaseId={businessCaseId}
      />
      <Button
        variant="secondary"
        title={i18n.t('entity.customerVerification.actions.verifyCustomer')}
        onClick={startVerification}
        leftIcon="content/how_to_reg"
      />
    </>
  );
}
