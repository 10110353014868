/* eslint-disable dot-notation */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {cloneElement, useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import {findDOMNode} from 'react-dom';

function mapEventPropToEvent(eventProp: any) {
  return eventProp.substring(2).toLowerCase();
}

function clickedRootScrollbar(event: any) {
  return (
    document.documentElement.clientWidth < event.clientX ||
    document.documentElement.clientHeight < event.clientY
  );
}

/**
 * Listen for click events that occur somewhere in the document, outside of the element itself.
 * For instance, if you need to hide a menu when people click anywhere else on your page.
 */
export function ClickAwayListener(props: any) {
  const {children, onClickAway} = props;
  const movedRef = useRef(false);
  const nodeRef = useRef(null);
  const activatedRef = useRef(false);
  const syntheticEventRef = useRef(false);

  useEffect(() => {
    // Ensure that this component is not "activated" synchronously.
    // https://github.com/facebook/react/issues/20074
    setTimeout(() => {
      activatedRef.current = true;
    }, 0);
    return () => {
      activatedRef.current = false;
    };
  }, []);

  // can be removed once we drop support for non ref forwarding class components
  const handleOwnRef = useCallback((instance: any) => {
    // #StrictMode ready
    nodeRef.current = findDOMNode(instance) as any;
  }, []);
  const handleRef = handleOwnRef;

  // The handler doesn't take event.defaultPrevented into account:
  //
  // event.preventDefault() is meant to stop default behaviours like
  // clicking a checkbox to check it, hitting a button to submit a form,
  // and hitting left arrow to move the cursor in a text input etc.
  // Only special HTML elements have these default behaviors.
  const handleClickAway = useEventCallback((event: any) => {
    // Given developers can stop the propagation of the synthetic event,
    // we can only be confident with a positive value.
    const insideReactTree = syntheticEventRef.current;
    syntheticEventRef.current = false;

    // 1. IE 11 support, which trigger the handleClickAway even after the unbind
    // 2. The child might render null.
    // 3. Behave like a blur listener.
    if (!activatedRef.current || !nodeRef.current || clickedRootScrollbar(event)) {
      return;
    }

    // Do not act if user performed touchmove
    if (movedRef.current) {
      movedRef.current = false;
      return;
    }

    let insideDOM;

    // If not enough, can use https://github.com/DieterHolvoet/event-propagation-path/blob/master/propagationPath.js
    if (event.composedPath) {
      insideDOM = event.composedPath().includes(nodeRef.current);
    } else {
      // TODO v6 remove dead logic https://caniuse.com/#search=composedPath.
      const doc = document;
      insideDOM =
        !doc.documentElement.contains(event.target) ||
        (nodeRef.current as HTMLElement).contains(event.target);
    }

    if (!insideDOM && !insideReactTree) {
      onClickAway(event);
    }
  });

  // Keep track of mouse/touch events that bubbled up through the portal.
  const createHandleSynthetic = (handlerName: any) => (event: any) => {
    syntheticEventRef.current = true;

    const childrenPropsHandler = children.props[handlerName];
    if (childrenPropsHandler) {
      childrenPropsHandler(event);
    }
  };

  const childrenProps = {ref: handleRef} as any;

  childrenProps['onTouchEnd'] = createHandleSynthetic('onTouchEnd');

  useEffect(() => {
    const mappedTouchEvent = mapEventPropToEvent('onTouchEnd');
    const doc = document;

    const handleTouchMove = () => {
      movedRef.current = true;
    };

    doc.addEventListener(mappedTouchEvent, handleClickAway);
    doc.addEventListener('touchmove', handleTouchMove);

    return () => {
      doc.removeEventListener(mappedTouchEvent, handleClickAway);
      doc.removeEventListener('touchmove', handleTouchMove);
    };
  }, [handleClickAway]);

  childrenProps.onClick = createHandleSynthetic('onClick');

  useEffect(() => {
    const mappedMouseEvent = mapEventPropToEvent('onClick');
    const doc = document;

    doc.addEventListener(mappedMouseEvent, handleClickAway);

    return () => {
      doc.removeEventListener(mappedMouseEvent, handleClickAway);
    };
  }, [handleClickAway]);

  return <>{cloneElement(children, childrenProps)}</>;
}

function useEventCallback(fn: any) {
  const ref = useRef(fn);
  useLayoutEffect(() => {
    ref.current = fn;
  });
  return useCallback((...args: any) => (0, ref.current)(...args), []);
}
