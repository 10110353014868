import {CountryCode} from 'libphonenumber-js';
import {formatPhoneNumber} from 'platform/locale';

import {useSelector} from 'react-redux';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  GetCurrentUserInfoApiResponse,
  InsuranceOffer,
  InsurancePerson,
  InsurancePersonV2,
  InsuranceVehicle,
  InsuranceVehicleV2,
  selectCurrentUserInfo,
  selectTenant,
  TenantResponseBody,
  useGetBusinessCaseQuery,
  useGetCustomerV2Query,
  useGetInsuranceOfferQuery,
  useGetVehicleQuery,
  UserRole,
} from '@omnetic-dms/api';
import {getNaturalPersonFullName} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export type InsuranceData = {
  role: UserRole;
  insurerNumber: string | undefined;
  insuranceVehicle: InsuranceVehicle | InsuranceVehicleV2 | null;
  insurancePerson: InsurancePerson | InsurancePersonV2 | null;
  insuranceOffer: InsuranceOffer | null;
  vehicleTitle: string | null;
  vehicleId: string | null;
  personName: string | null;
  tenantInfo: TenantResponseBody;
  userInfo: GetCurrentUserInfoApiResponse;
  isLoading: boolean;
  isError: boolean;
  isFinished: boolean;
};

export function useInsuranceData(
  businessCaseId: string,
  skipForNonInsurer?: boolean
): InsuranceData {
  const {data: userInfo} = useSelector(selectCurrentUserInfo);
  const {data: tenantInfo} = useSelector(selectTenant);

  if (!userInfo) {
    throw new Error('User is not loaded');
  }
  if (!tenantInfo) {
    throw new Error('Tenant is not loaded');
  }

  const insurerNumber = userInfo?.settings.insurerNumber;
  const skip = skipForNonInsurer && isNilOrEmpty(insurerNumber);

  const {
    data: businessCase,
    isLoading: isBusinessCaseLoading,
    isError: isBusinessCaseError,
  } = useGetBusinessCaseQuery({businessCaseId}, {skip, refetchOnMountOrArgChange: true});

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: isCustomerError,
  } = useGetCustomerV2Query(
    {customerId: businessCase?.customerId ?? ''},
    {skip: isNilOrEmpty(businessCase?.customerId)}
  );

  const offerVehicle = businessCase?.offers?.[0]?.saleVehicles?.[0];

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: offerVehicle?.vehicleId ?? ''},
    {skip: skip || !offerVehicle?.vehicleId, refetchOnMountOrArgChange: true}
  );

  const insuranceVehicle: InsuranceVehicle = {
    sellingPrice: vehicle?.sellingPrice?.withVat?.amount ?? undefined,
    firstRegistrationOnDay: vehicle?.firstRegistrationOnDay ?? undefined,
    firstRegistrationOnMonth: vehicle?.firstRegistrationOnMonth ?? undefined,
    firstRegistrationOnYear: vehicle?.firstRegistrationOnYear ?? undefined,
    engineVolume: vehicle?.engine?.engineVolume,
    power: vehicle?.power,
    mileage: vehicle?.vehicle?.mileage,
    weight: vehicle?.vehicle?.weight,
    modelFamily: vehicle?.modelFamily,
    fuelType: vehicle?.fuelType,
    make: vehicle?.make,
    type: vehicle?.type,
    vin: vehicle?.vin ?? undefined,
    registrationPlate: vehicle?.vehicle?.registrationPlate,
    vtpNumber: vehicle?.vehicle?.vehicleRegistrationNumber,
    seatCount: vehicle?.vehicle?.seatCount,
  };

  const {
    data: insuranceOffer,
    isLoading: isInsuranceOfferLoading,
    isError: isInsuranceOfferError,
  } = useGetInsuranceOfferQuery(
    {
      body: {
        insurerNumber: userInfo?.settings.insurerNumber,
        tenantId: tenantInfo?.id,
        vehicle: insuranceVehicle,
      },
    },
    {skip: !!skip || isNilOrEmpty(offerVehicle) || isNilOrEmpty(vehicle)}
  );
  const contactPerson =
    customer?.contacts.find((person) => businessCase?.contactPersonId === person.id) ||
    customer?.foundingPerson;

  const personName = getNaturalPersonFullName(contactPerson);
  const personEmail = contactPerson?.emails?.[0]?.email;
  const phoneNumberData = contactPerson?.phoneNumbers?.[0];
  const personPhoneNumber =
    phoneNumberData &&
    formatPhoneNumber(
      `${phoneNumberData?.prefix}${phoneNumberData?.number}`,
      phoneNumberData?.countryCode as CountryCode | Nullish
    );

  const insurancePerson = {
    firstName: contactPerson?.firstName,
    lastName: contactPerson?.lastName,
    titleBefore: contactPerson?.titleBefore,
    email: personEmail,
    phoneNumber: personPhoneNumber,
    personalIdentifier: contactPerson?.personalIdentifier,
    street: contactPerson?.permanentAddress?.address.street,
    descriptiveNumber: contactPerson?.permanentAddress?.address.descriptiveNumber,
    OrientationNumber: contactPerson?.permanentAddress?.address.orientationNumber,
    city: contactPerson?.permanentAddress?.address.city,
    zip: contactPerson?.permanentAddress?.address.zip,
    companyName: customer?.businessInfo?.businessInfo.tradeName,
    registrationNumber: customer?.selfEmployed
      ? customer?.selfEmployedBusinessInfoData?.registrationNumber
      : customer?.businessInfo?.businessInfo.registrationNumber,
    selfEmployed: customer?.selfEmployed,
    customerDiscriminator: customer?.discriminator,
  };

  const role = insuranceOffer?.userRole ?? UserRole.NONE;

  return {
    role,
    insurerNumber,
    insuranceVehicle,
    insurancePerson,
    personName,
    insuranceOffer: insuranceOffer?.insurance ?? null,
    vehicleTitle: vehicle?.title ?? null,
    vehicleId: vehicle?.id ?? null,
    tenantInfo,
    userInfo,
    isLoading:
      isCustomerLoading || isBusinessCaseLoading || isVehicleLoading || isInsuranceOfferLoading,
    isError: isCustomerError || isBusinessCaseError || isVehicleError || isInsuranceOfferError,
    isFinished: Boolean(businessCase?.customerRecommended || businessCase?.insuranceOffered),
  };
}
