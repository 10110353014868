import i18n from '@omnetic-dms/i18n';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {CashRegisterList} from './components/CashRegister';

export function CashRegisters() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('page.accounting.labels.cashRegisters')}}
      data-testid="cashregister-settings-modal"
    >
      <CashRegisterList />
    </SettingsTemplate>
  );
}
