import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ParkingDataGrid} from './components/ParkingDataGrid';

export function VehicleParking() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('page.vehicleSettings.labels.parking')}}
      description={i18n.t('page.vehicleSettings.labels.parkingDescription')}
      data-testid={testIds.settings.vehicleParking('modal')}
    >
      <ParkingDataGrid />
    </SettingsTemplate>
  );
}
