import {Attributes, AttributesProps} from 'platform/components';
import {Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';

import {
  GetCustomerRequestedPriceApiRes,
  useGetBusinessCaseQuery,
  useGetUserQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {Nullish, parseDate, useRequiredParams} from 'shared';

interface RequestedPriceDetailProps {
  requestedPrice: GetCustomerRequestedPriceApiRes | undefined;
}

export function RequestedPriceDetail({requestedPrice}: RequestedPriceDetailProps) {
  const formatDateTime = useDateTimeFormatter();
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const purchaseVehicle =
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE'
      ? (businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0] ?? null)
      : (businessCase?.offers?.[0]?.purchaseVehicles?.[0] ?? null);

  const {data: user} = useGetUserQuery(
    {id: purchaseVehicle?.costs?.coster ?? ''},
    {skip: !purchaseVehicle?.costs?.coster}
  );

  const rows: AttributesProps['rows'] = [
    {
      label: i18n.t('entity.businessCase.labels.customerRequestedPrice'),
      content: (
        <FormatParameterPrice
          priceWithVat={requestedPrice?.customerRequestedPrice?.priceWithVat}
          priceWoVat={requestedPrice?.customerRequestedPrice?.priceWoVat}
          vatDeductible={requestedPrice?.isVatDeductible ?? false}
        />
      ),
      testId: testIds.businessCase.buying('requestedPrice-customerRequestedPrice'),
    },
    {
      label: i18n.t('general.labels.vatDeductible'),
      value:
        requestedPrice?.isVatDeductible && requestedPrice?.vatRate
          ? `${requestedPrice?.vatRate?.vatRate} %`
          : i18n.t('general.labels.no'),
      testId: testIds.businessCase.buying('requestedPrice-vatDeductible'),
    },
    {
      label: i18n.t('general.labels.buyer'),
      value: user?.lastName ? `${user?.firstName ?? ''} ${user?.lastName}` : null,
      testId: testIds.businessCase.buying('requestedPrice-reason'),
    },
    {
      label: i18n.t('entity.businessCase.labels.createdOn'),
      value: purchaseVehicle?.costs?.costsOn
        ? formatDateTime('dateShort', parseDate(purchaseVehicle?.costs?.costsOn ?? ''))
        : null,
      testId: testIds.businessCase.buying('requestedPrice-reason'),
    },
  ];

  return (
    <Attributes
      data-testid={testIds.businessCase.buying('requestedPrice')}
      rows={rows}
      size="third"
    />
  );
}

type FormatParameterPriceProps = {
  priceWithVat: {amount: string | number; currency: string} | Nullish;
  priceWoVat: {amount: string | number | undefined; currency: string} | Nullish;
  vatDeductible: boolean;
};

export function FormatParameterPrice(props: FormatParameterPriceProps) {
  const formatCurrency = useFormatCurrency();
  if (!props.priceWithVat) {
    return <>-</>;
  }

  const {priceWithVat, priceWoVat, vatDeductible} = props;

  return (
    <VStack spacing={0}>
      <Text size="xSmall">
        {formatCurrency(Number(priceWithVat.amount ?? '0'), priceWithVat?.currency)}
      </Text>
      <Show when={vatDeductible && priceWoVat}>
        <Text color="secondary" size="xSmall">
          {formatCurrency(Number(priceWoVat?.amount ?? '0'), priceWoVat?.currency ?? '')}{' '}
          {i18n.t('entity.businessCase.labels.serviceFeeWithoutVAT')}
        </Text>
      </Show>
    </VStack>
  );
}
