/**
 * Determines if a given number is positive.
 * Optionally, inverts the condition.
 *
 * @example
 * // returns true
 * isPositiveValue(5);
 *
 * @example
 * // returns false
 * isPositiveValue(-5);
 *
 * @example
 * // returns true
 * isPositiveValue(-5, true);
 *
 * @example
 * // returns false
 * isPositiveValue(5, true);
 */
export const isPositiveValue = (value: number, invert?: boolean) =>
  invert ? value < 0 : value > 0;
