import {FormControl, FormField} from 'platform/components';

import {FieldValues, Path} from 'react-hook-form';
import {MenuPlacement} from 'react-select';

import {isNilOrEmpty, isTrue} from 'ramda-adjunct';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {useVehicleCatalogue} from '../../hooks/useVehicleCatalogue';

type DriveSelectProps<TFieldValues extends FieldValues = FieldValues> = {
  vehicleType: string | Nullish;
  control: FormControl<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isNotClearable?: boolean;
  isMenuInPortal?: boolean;
  menuPlacement?: MenuPlacement;
  onChange?: (value: string | number | string[] | null) => void;
} & RequiredTestIdProps;

export function DriveSelect<TFieldValues extends FieldValues = FieldValues>(
  props: DriveSelectProps<TFieldValues>
) {
  const [vehicleUtils, {isLoading}] = useVehicleCatalogue(props.vehicleType ?? undefined);

  return (
    <FormField
      data-testid={suffixTestId('drive', props)}
      control={props.control}
      name={props.name}
      label={props.label}
      type={props.isMultiple ? 'multiChoice' : 'choice'}
      options={vehicleUtils.driveOptions ?? []}
      menuPlacement={props.menuPlacement}
      isRequired={props.isRequired}
      isNotClearable={props.isNotClearable}
      menuInPortal={props.isMenuInPortal}
      isLoading={isLoading}
      isDisabled={isTrue(props.isDisabled) || isNilOrEmpty(vehicleUtils.driveOptions)}
      onChange={props.onChange}
    />
  );
}
