import {TextProps, Text} from 'platform/foundation';

import {useState, ReactNode, ReactElement} from 'react';

import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

import {Placement} from '../../../types/Placement';
import {DropdownItem} from './DropdownItem';

export interface DropdownSubmenuProps extends TestIdProps {
  label: string | ReactElement<TextProps, typeof Text>;
  children: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactElement;
  onClick?(): void;
  placement?: Placement;
  strategy?: 'fixed' | 'absolute';
  isLazy?: boolean;
  lazyBehavior?: 'unmount' | 'keepMounted';
  boundary?: 'clippingParents' | 'scrollParent' | HTMLElement;
  isActive?: boolean;
}

export function DropdownSubmenu(props: DropdownSubmenuProps) {
  const {
    onClick,
    label,
    children,
    suffix,
    prefix,
    placement = 'right-start',
    strategy,
    isLazy,
    lazyBehavior,
    boundary = document.body,
  } = props;

  const {item: itemTheme} = useMultiStyleConfig('Menu', {});
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [t, setT] = useState<NodeJS.Timeout | undefined>();

  return (
    <Menu
      data-testid={suffixTestId('dropdownSubmenu', props)}
      placement={placement}
      isLazy={isLazy}
      lazyBehavior={lazyBehavior}
      gutter={0}
      strategy={strategy}
      isOpen={isOpen}
      boundary={boundary}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          phase: 'main',
          options: {
            fallbackPlacements: ['left-start', 'right-end', 'left-end'],
          },
        },
      ]}
    >
      <Box
        onMouseEnter={() => {
          if (t) {
            clearTimeout(t);
            setT(undefined);
          }
          const tt = setTimeout(onOpen, 200);
          setT(tt);
        }}
        onMouseLeave={() => {
          if (t) {
            clearTimeout(t);
            setT(undefined);
          }
          const tt = setTimeout(onClose, 200);
          setT(tt);
        }}
      >
        <MenuButton
          data-testid={suffixTestId('dropdownSubmenuButton', props)}
          as={Box}
          onClick={onClick}
          sx={{
            ...itemTheme,
            padding: 0,
            margin: 0,
          }}
        >
          <DropdownItem isActive={props.isActive} label={label} suffix={suffix} prefix={prefix} />
        </MenuButton>
        <MenuList data-testid={suffixTestId('dropdownSubmenuList', props)}>{children}</MenuList>
      </Box>
    </Menu>
  );
}
