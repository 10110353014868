/* istanbul ignore file */
import type {CancelablePromise} from '../types/CancelablePromise';
import type {ChangeBranchSaleVehicleRequestBody} from '../types/ChangeBranchSaleVehicleRequestBody';
import {request as __request} from '../utils/request';

export class SaleVehicleService {
  /**
   * @returns any Unknown response type
   * @throws ApiError
   */
  public static changeBranchSaleVehicle({
    vehicleId,
    authorization,
    requestBody,
  }: {
    vehicleId: string;
    authorization?: string;
    requestBody?: ChangeBranchSaleVehicleRequestBody;
  }): CancelablePromise<any> {
    return __request({
      method: 'POST',
      url: '/dms/v1/sale-vehicle/{vehicleId}/change-branch',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
