import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

const WorkTypeSchema = z.object({
  id: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  isDefault: z.boolean().nullable().optional(),
  isActive: z.boolean().nullable().optional(),
});

export const PostDictionaryWorktypeApiResponseSchema = WorkTypeSchema.nullable();

export type PostDictionaryWorktypeApiResponse = z.infer<
  typeof PostDictionaryWorktypeApiResponseSchema
>;

export const GetDictionaryWorktypeApiResponseSchema = WorkTypeSchema.nullable();

export type GetDictionaryWorktypeApiResponse = z.infer<
  typeof GetDictionaryWorktypeApiResponseSchema
>;

export const GetDictionaryWorktypesApiResponseSchema = z.array(WorkTypeSchema.nullable());

export type GetDictionaryWorktypesApiResponse = z.infer<
  typeof GetDictionaryWorktypesApiResponseSchema
>;

export const PatchDictionaryWorktypeApiResponseSchema = BaseVoidResponseSchema;

export type PatchDictionaryWorktypeApiResponse = z.infer<
  typeof PatchDictionaryWorktypeApiResponseSchema
>;

export const PatchDictionaryWorktypeIsDefaultApiResponseSchema = BaseVoidResponseSchema;

export type PatchDictionaryWorktypeIsDefaultApiResponse = z.infer<
  typeof PatchDictionaryWorktypeIsDefaultApiResponseSchema
>;

export const DeleteWorkTypeApiResponseSchema = BaseVoidResponseSchema;

export type DeleteWorkTypeApiResponse = z.infer<typeof DeleteWorkTypeApiResponseSchema>;

export const PostWorkTypeSetAsActiveApiResponseSchema = BaseVoidResponseSchema;

export type PostWorkTypeSetAsActiveApiResponse = z.infer<
  typeof PostWorkTypeSetAsActiveApiResponseSchema
>;

export const PostWorkTypeSetAsInactiveApiResponseSchema = BaseVoidResponseSchema;

export type PostWorkTypeSetAsInactiveApiResponse = z.infer<
  typeof PostWorkTypeSetAsInactiveApiResponseSchema
>;

export const PostDictionaryWorktypeApiArgSchema = z.object({
  body: z
    .object({
      name: z.string(),
      isDefault: z.boolean(),
      isActive: z.boolean(),
    })
    .nullable(),
});

export type PostDictionaryWorktypeApiArg = z.infer<typeof PostDictionaryWorktypeApiArgSchema>;

export const GetDictionaryWorktypeApiArgSchema = z.object({
  workTypeId: z.string(),
});

export type GetDictionaryWorktypeApiArg = z.infer<typeof GetDictionaryWorktypeApiArgSchema>;

export const PatchDictionaryWorktypeApiArgSchema = z.object({
  workTypeId: z.string(),
  body: z
    .object({
      name: z.string(),
      isDefault: z.boolean(),
      isActive: z.boolean(),
    })
    .nullable(),
});

export type PatchDictionaryWorktypeApiArg = z.infer<typeof PatchDictionaryWorktypeApiArgSchema>;

export const PatchDictionaryWorktypeIsDefaultApiArgSchema = z.object({
  workTypeId: z.string(),
});

export type PatchDictionaryWorktypeIsDefaultApiArg = z.infer<
  typeof PatchDictionaryWorktypeIsDefaultApiArgSchema
>;

export const GetDictionaryWorktypesApiArgSchema = z.void();

export type GetDictionaryWorktypesApiArg = z.infer<typeof GetDictionaryWorktypesApiArgSchema>;

export const DeleteWorkTypeApiArgSchema = z.object({
  workTypeId: z.string(),
});

export type DeleteWorkTypeApiArg = z.infer<typeof DeleteWorkTypeApiArgSchema>;

export const PostWorkTypeSetAsActiveApiArgSchema = z.object({
  workTypeId: z.string(),
});

export type PostWorkTypeSetAsActiveApiArg = z.infer<typeof PostWorkTypeSetAsActiveApiArgSchema>;

export const PostWorkTypeSetAsInactiveApiArgSchema = z.object({
  workTypeId: z.string(),
});

export type PostWorkTypeSetAsInactiveApiArg = z.infer<typeof PostWorkTypeSetAsInactiveApiArgSchema>;
