import {
  CreateProductApiResponse,
  CreateProductApiArg,
  DeleteProductApiResponse,
  DeleteProductApiArg,
  EditProductApiResponse,
  EditProductApiArg,
  GetProductApiResponse,
  GetProductApiArg,
} from '../types/upsellCatalog';
import {omneticApi} from './baseApi/omneticApi';

export const upsellCatalogApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getProduct: build.query<GetProductApiResponse, GetProductApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/upsell-catalog/product/${queryArg.productId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'upsellProductItem', id: queryArg.productId},
      ],
    }),

    createProduct: build.mutation<CreateProductApiResponse, CreateProductApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/upsell-catalog/product`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),

    deleteProduct: build.mutation<DeleteProductApiResponse, DeleteProductApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/upsell-catalog/product/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'upsellProductItem', id: queryArg.id}],
    }),

    editProduct: build.mutation<EditProductApiResponse, EditProductApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/upsell-catalog/product/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'upsellProductItem', id: queryArg.id}],
    }),
  }),
});

export const {
  useGetProductQuery,
  useCreateProductMutation,
  useDeleteProductMutation,
  useEditProductMutation,
} = upsellCatalogApi;
