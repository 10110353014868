import {FC, useState, ReactNode} from 'react';

import {TestIdProps} from 'shared';

import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {ConditionDamagesContext} from '../hooks/useConditionDamagesContext';
import {AddAndEditDamage} from './AddAndEditDamage';

type DamageCategoryProps = {
  children: ReactNode;
};

export const DamageCategory: FC<DamageCategoryProps & TestIdProps> = ({children, ...rest}) => {
  const [lastOrderNumber, setLastOrderNumber] = useState<number>(0);
  const [_damageId, setDamageId] = useState<string | null>(null);
  const [isDamageDialogOpen, setIsDamageDialogOpen] = useState<boolean>(false);
  const [dialogDamageCategory, setDialogDamageCategory] = useState<AuditCategoryOfStructure | null>(
    null
  );

  const onAddDamage = (category: AuditCategoryOfStructure) => {
    setDamageId(null);
    if (category.id === dialogDamageCategory?.id) {
      setIsDamageDialogOpen(true);
      return;
    }
    setDialogDamageCategory(category);
    setIsDamageDialogOpen(true);
  };

  const onEditDamage = (category: AuditCategoryOfStructure, damageId: string) => {
    if (_damageId === damageId) {
      setIsDamageDialogOpen(true);
      return;
    }
    setDamageId(damageId);
    setDialogDamageCategory(category);
    setIsDamageDialogOpen(true);
  };

  return (
    <ConditionDamagesContext.Provider
      value={{
        onAddDamage,
        onEditDamage,
        lastOrderNumber,
        setLastOrderNumber,
      }}
    >
      {children}

      {dialogDamageCategory && (
        <AddAndEditDamage
          isOpen={isDamageDialogOpen}
          onClose={() => {
            setIsDamageDialogOpen(false);
          }}
          category={dialogDamageCategory}
          damageId={_damageId}
          data-testid={rest['data-testid']}
        />
      )}
    </ConditionDamagesContext.Provider>
  );
};
