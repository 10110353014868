import {QueueVideoTask} from '../types/QueueVideoTask';

export enum TaskActionKind {
  ADD_TASK = 'ADD_TASK',
  REMOVE_TASK = 'REMOVE_TASK',
  UPDATE_TASK_JOB = 'UPDATE_TASK_JOB',
  UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS',
}

type TaskAction =
  | {
      type: TaskActionKind.UPDATE_TASK_STATUS;
      payload: {
        taskConnectionFileId: string;
        status: QueueVideoTask['status'];
      };
    }
  | {
      type: TaskActionKind.UPDATE_TASK_JOB;
      payload: {
        taskConnectionFileId: string;
        job: Partial<QueueVideoTask['jobs'][number]> & {id: string};
      };
    }
  | {
      type: TaskActionKind.ADD_TASK;
      payload: {
        task: QueueVideoTask;
      };
    }
  | {
      type: TaskActionKind.REMOVE_TASK;
      payload: {
        id: string;
      };
    };

export interface TaskState {
  tasks: QueueVideoTask[];
}

export function taskReducer(state: TaskState, action: TaskAction) {
  const {type, payload} = action;

  switch (type) {
    case TaskActionKind.UPDATE_TASK_JOB:
      return {
        ...state,
        tasks: state.tasks.map((t) => ({
          ...t,
          jobs: t.jobs.map((j) => (j.id === payload.job.id ? {...j, ...payload.job} : j)),
        })),
      };
    case TaskActionKind.ADD_TASK:
      return {
        ...state,
        tasks: [...state.tasks, payload.task],
      };
    case TaskActionKind.REMOVE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter((t) => t.id !== payload.id),
      };
    case TaskActionKind.UPDATE_TASK_STATUS:
      return {
        ...state,
        tasks: state.tasks.map((t) =>
          t.connectionFileId === payload.taskConnectionFileId ? {...t, status: payload.status} : t
        ),
      };
    default:
      return state;
  }
}
