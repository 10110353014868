import {isNil} from 'ramda';

import {EntityResourceIds, useGetParticipationQuery} from '@omnetic-dms/api';

import {Nullish} from 'shared';

import {usePermissions} from './usePermissions/usePermissions';

interface PricePermissionsProps {
  businessCaseRecordId: string | Nullish;
  vehicleRecordId: string | Nullish;
}

export const usePricePermissions = (props: PricePermissionsProps) => {
  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      recordId: props?.businessCaseRecordId ?? '',
      resourceId: EntityResourceIds.businessCase,
    },
    {
      skip: isNil(props?.businessCaseRecordId),
    }
  );

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      recordId: props?.vehicleRecordId ?? '',
      resourceId: EntityResourceIds.vehicle,
    },
    {
      skip: isNil(props?.vehicleRecordId),
    }
  );

  const [
    canReadVehicleProfit,
    canReadVehicleSellingPrice,
    canReadVehicleRealSellingPrice,
    canReadVehicleCosts,
    canReadVehicleEarnings,
    canReadVehiclePurchasePrice,
    canEditVehicleSalePrice,
    canEditVehiclePurchasePrice,
    canReadBusinessCaseSaleVehiclePurchasePrice,
    canReadBusinessCaseSaleVehicleSalePrice,
    canReadBusinessCaseSaleProfit,
    canReadBusinessCaseSaleTotalPrice,
    canEditBusinessCasePurchaseBrokerageFees,
    canReadBusinessCasePurchaseVehiclePurchasePrice,
    canReadBusinessCasePurchaseVehicleSellingPrice,
    canReadBusinessCasePurchaseExpectedProfit,
    canReadBusinessCasePurchaseTotalPrice,
    canReadBusinessCaseBrokerageFees,
    canEditBusinessCaseMaxPurchasePrice,
  ] = usePermissions({
    permissionKeys: [
      // vehicle
      'vehicleProfit',
      'vehicleSellingPrice',
      'vehicleRealSellingPrice',
      'vehicleReadCosts',
      'vehicleReadEarnings',
      'vehiclePurchasePrice',
      'vehicleEditSalePrice',
      'vehicleEditPurchasePrice',
      // businessCaseSale
      'businessCaseSaleVehiclePurchasePrice',
      'businessCaseSaleVehicleSalePrice',
      'businessCaseSaleProfit',
      'businessCaseSaleTotalPrice',
      'editBusinessCasePurchaseBrokerageFees',
      // businessCasePurchase
      'businessCasePurchaseVehiclePurchasePrice',
      'businessCasePurchaseVehicleSellingPrice',
      'businessCasePurchaseExpectedProfit',
      'businessCasePurchaseTotalPrice',
      'businessCaseViewBrokerageFees',
      'businessCaseEditMaxPurchasePrice',
    ],
    scopes: {
      businessCaseEditMaxPurchasePrice: businessCaseParticipation,
      businessCaseViewBrokerageFees: businessCaseParticipation,
      businessCaseSaleVehiclePurchasePrice: businessCaseParticipation,
      businessCaseSaleVehicleSalePrice: businessCaseParticipation,
      businessCaseSaleProfit: businessCaseParticipation,
      businessCaseSaleTotalPrice: businessCaseParticipation,
      editBusinessCasePurchaseBrokerageFees: businessCaseParticipation,
      businessCasePurchaseVehiclePurchasePrice: businessCaseParticipation,
      businessCasePurchaseVehicleSellingPrice: businessCaseParticipation,
      businessCasePurchaseExpectedProfit: businessCaseParticipation,
      businessCasePurchaseTotalPrice: businessCaseParticipation,
      vehicleReadCosts: vehicleParticipation,
      vehicleReadEarnings: vehicleParticipation,
      vehicleProfit: vehicleParticipation,
      vehicleSellingPrice: vehicleParticipation,
      vehicleRealSellingPrice: vehicleParticipation,
      vehiclePurchasePrice: vehicleParticipation,
      vehicleEditSalePrice: vehicleParticipation,
      vehicleEditPurchasePrice: vehicleParticipation,
    },
  });

  const fieldAccessMap = {
    canReadVehicleProfit,
    canReadVehicleSellingPrice,
    canReadVehicleRealSellingPrice,
    canReadVehicleCosts,
    canReadVehicleEarnings,
    canReadVehiclePurchasePrice,
    canReadBusinessCaseSaleVehiclePurchasePrice,
    canReadBusinessCaseSaleVehicleSalePrice,
    canReadBusinessCaseSaleProfit,
    canReadBusinessCaseSaleTotalPrice,
    canReadBusinessCasePurchaseVehiclePurchasePrice,
    canReadBusinessCasePurchaseVehicleSellingPrice,
    canReadBusinessCasePurchaseExpectedProfit,
    canReadBusinessCasePurchaseTotalPrice,
    canReadBusinessCaseBrokerageFees,
  } as const;

  const vehiclePriceFields = [
    canReadVehicleProfit,
    canReadVehicleSellingPrice,
    canReadVehicleRealSellingPrice,
    canReadVehicleCosts,
    canReadVehicleEarnings,
    canReadVehiclePurchasePrice,
  ];

  const businessCaseSalePriceFields = [
    canReadBusinessCaseSaleVehiclePurchasePrice,
    canReadBusinessCaseSaleVehicleSalePrice,
    canReadBusinessCaseSaleProfit,
    canReadBusinessCaseSaleTotalPrice,
  ];

  const businessCasePurchasePriceFields = [
    canReadBusinessCasePurchaseVehiclePurchasePrice,
    canReadBusinessCasePurchaseVehicleSellingPrice,
    canReadBusinessCasePurchaseExpectedProfit,
    canReadBusinessCasePurchaseTotalPrice,
  ];

  return {
    canViewAnyOfVehiclePriceFields: vehiclePriceFields.some(Boolean),
    canViewAnyOfSalePriceFields:
      vehiclePriceFields.some(Boolean) || businessCaseSalePriceFields.some(Boolean),
    canViewAnyOfPurchasePriceFields:
      vehiclePriceFields.some(Boolean) || businessCasePurchasePriceFields.some(Boolean),
    ...fieldAccessMap,
    canEditVehicleSalePrice,
    canEditVehiclePurchasePrice,
    canEditBusinessCasePurchaseBrokerageFees,
    canEditBusinessCaseMaxPurchasePrice,
  };
};
