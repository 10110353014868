import {formatPhoneNumber} from 'platform/locale';

import {useEffect, useState} from 'react';

import {isNil, partition} from 'ramda';

import {
  CustomerResponseBodyV2,
  PhoneNumberResponseBody,
  useGetMetadaServiceCaseQuery,
  useGetVehicleCustomerQuery,
  useLazyGetCustomerV2Query,
} from '@omnetic-dms/api';
import {
  getCustomerName,
  getNaturalPersonFullName,
  isCustomerNaturalPerson,
} from '@omnetic-dms/shared';

export type FormContactPerson = {
  label?: string | null;
  value?: string;
  emails?: {
    label: string;
    value: string;
  }[];
  phoneNumbers?: {
    label: string | null;
    value: string;
    phone: PhoneNumberResponseBody;
  }[];
};

const getFormContactPersonFromCustomer = (customer: CustomerResponseBodyV2): FormContactPerson => ({
  label: getCustomerName(customer),
  value: customer.foundingPerson?.id,
  emails: customer.foundingPerson?.emails.map((email) => ({
    label: email.email,
    value: email.email,
  })),
  phoneNumbers: customer.foundingPerson?.phoneNumbers.map((phone) => ({
    label: formatPhoneNumber(`${phone.prefix}${phone.number}`, phone.countryCode),
    value: phone.number,
    phone,
  })),
});

const getFormContactPersonFromContact = (
  contact: CustomerResponseBodyV2['contacts'][0]
): FormContactPerson => ({
  label: getNaturalPersonFullName(contact),
  value: contact.id,
  emails: contact.emails.map((email) => ({
    label: email.email,
    value: email.email,
  })),
  phoneNumbers: contact.phoneNumbers.map((phone) => ({
    label: formatPhoneNumber(`${phone.prefix}${phone.number}`, phone.countryCode),
    value: phone.number,
    phone,
  })),
});

export function useContactPersons(serviceCaseId: string) {
  const [getCustomer] = useLazyGetCustomerV2Query();
  const [contactPersons, setContactPersons] = useState<FormContactPerson[]>([]);
  const [isContactPersonsLoading, setIsContactPersonsLoading] = useState(true);
  const [isContactPersonsError, setIsContactPersonsError] = useState(false);
  const {
    data: serviceCase,
    isLoading: isServiceCaseLoading,
    isError: isServiceCaseError,
  } = useGetMetadaServiceCaseQuery({serviceCaseId});

  const {data: vehicleCustomers} = useGetVehicleCustomerQuery(
    {vehicleId: serviceCase?.vehicleId},
    {skip: isNil(serviceCase)}
  );

  useEffect(() => {
    const contactPersonPromises = vehicleCustomers?.map(async (customer) => {
      const {data} = await getCustomer({customerId: customer?.customerId ?? ''}, true);
      return data;
    });

    Promise.all(contactPersonPromises ?? [])
      .then((customers) => {
        const allContacts: FormContactPerson[] = [];
        const [physical, companies] = partition(
          (customer) => !!customer && isCustomerNaturalPerson(customer),
          customers
        );
        physical.forEach((customer) => {
          customer && allContacts.push(getFormContactPersonFromCustomer(customer));
          customer?.contacts.forEach((contact) =>
            allContacts.push(getFormContactPersonFromContact(contact))
          );
        });

        companies.forEach((company) => {
          company?.contacts.forEach((contact) =>
            allContacts.push(getFormContactPersonFromContact(contact))
          );
        });

        setContactPersons(allContacts);
        setIsContactPersonsLoading(false);
      })
      .catch(() => {
        setIsContactPersonsError(true);
        setIsContactPersonsLoading(false);
      });
  }, [vehicleCustomers, getCustomer]);

  const isLoading = isServiceCaseLoading || isContactPersonsLoading;
  const isError = isServiceCaseError || isContactPersonsError;

  return {isLoading, isError, contactPersons};
}
