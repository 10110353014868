import {
  Action,
  Card,
  closeCurrentDialog,
  openDialog,
  Status,
  Tag,
  TagProps,
} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {buildArray} from 'shared';

import {EditDialog} from './EditDialog';
import {CardTagSelectionTagType} from './types';

type CardTagSelectionProps = {
  tags: CardTagSelectionTagType[];
  predefinedTags: CardTagSelectionTagType[];
  title: string;
  editDialogTitle: string;
  emptyMessage: string;
  tagsColorScheme?: TagProps['colorScheme'];
  onDelete: (opt: CardTagSelectionTagType) => void;
  onCreate: (opt: CardTagSelectionTagType[]) => void;
  isCreationDisabled?: boolean;
  isDisabled?: boolean;
};

export function CardTagSelection(props: CardTagSelectionProps) {
  const actions = buildArray<Action>().whenNot(props.isDisabled, {
    type: 'button',
    variant: 'link',
    leftIcon: 'image/edit',
    title: i18n.t('general.actions.edit'),
    onClick: () =>
      openDialog(
        <EditDialog
          tags={props.tags}
          predefinedTags={props.predefinedTags}
          onCreate={props.onCreate}
          onDelete={props.onDelete}
          onClose={closeCurrentDialog}
          isCreationDisabled={props.isCreationDisabled}
        />,
        {title: props.editDialogTitle, scrollBehavior: 'outside'}
      ),
  });

  return (
    <Card title={props.title} actions={actions}>
      <Show when={props.tags.length === 0}>
        <Box paddingVertical={6}>
          <Status headline={props.emptyMessage} />
        </Box>
      </Show>
      <Show when={props.tags.length > 0}>
        <HStack spacing={1} wrap>
          {props.tags.map((tag) => (
            <Tag
              key={tag.id}
              onDelete={props.isDisabled ? undefined : () => props.onDelete(tag)}
              label={tag.label}
              isSubtle
              colorScheme={props.tagsColorScheme}
              data-testid={testIds.vehicles.detail('cardTagSelection-tag')}
            />
          ))}
        </HStack>
      </Show>
    </Card>
  );
}
