import {showNotification} from 'platform/components';

import {downloadFile} from './downloadFile';

/**
 * @about function to download files provided by storage service, these files cannot be downloaded by ./downloadFile.ts,
 * since storage service thanks to internal redirects would overwrite files name. therefore we need to download file locally first.
 * @param url -> url to the hosted file
 * @param fileName  -> optional file name
 */
export const downloadHostedFile = (url: string, fileName?: string) =>
  fetch(url)
    .then((res) => res.blob())
    .then((file) => {
      const fileUrl = URL.createObjectURL(file);
      downloadFile(fileUrl, {fileName});
    })
    .catch(showNotification.error);
