import {Button, Tooltip} from 'platform/components';
import {Box, HStack, Icon, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {FC, ReactElement, ReactNode} from 'react';
import {DeepPartial, useFormContext} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';
import {useFields} from '@omnetic-dms/teas';

import {LeftSpacer} from './styles';
import {DocumentRoundingDefinitionFormType} from './types';
import {roundingDefinitionCtx} from './utils';

interface ValidityItemProps {
  item: DeepPartial<DocumentRoundingDefinitionFormType['validities'][0]>;
  index: number;
  allowRemove: boolean;
  onRemove?: () => void;
}

export const ValidityItem: FC<ValidityItemProps> = ({index, item, allowRemove, onRemove}) => {
  const form = useFormContext<DocumentRoundingDefinitionFormType>();
  const fields = useFields<DocumentRoundingDefinitionFormType, ['validities', number]>(form);

  const isSystem = form.watch('isSystem');
  const roundingTypes = roundingDefinitionCtx.useContextWithPath('roundingTypes');
  const roundingsList = roundingDefinitionCtx.useContextWithPath('roundings');

  return (
    <VStack spacing={4}>
      <HStack spacing={4}>
        <Box flex={1}>
          {fields.dropdown({
            name: ['validities', index, 'roundType'],
            defaultValue: item.roundType,
            options: roundingTypes,
            preventTrigger: true,
            getOptionLabel: (opt) => opt.value,
            getOptionValue: (opt) => opt?.key ?? '',
            dropdownProps: {
              placeholder: i18n.t('general.labels.selectFromList'),
              label: i18n.t('entity.accounting.labels.roundingType'),
            },
          })}
        </Box>
        <Box flex={1}>
          {fields.dropdown({
            name: ['validities', index, 'roundStep'],
            defaultValue: item.roundStep,
            options: roundingsList,
            preventTrigger: true,
            getOptionLabel: (opt) => opt.value,
            getOptionValue: (opt) => opt?.key ?? '',
            dropdownProps: {
              placeholder: i18n.t('general.labels.selectFromList'),
              label: i18n.t('entity.accounting.labels.rounding'),
            },
          })}
        </Box>
        <Box flex={2}>
          {fields.date({
            name: ['validities', index, 'validFrom'],
            defaultValue: item.validFrom,
            preventTrigger: true,
            inputProps: {
              fullWidth: true,
              disabled: isSystem,
              disablePast: true,
              label: i18n.t('entity.accounting.labels.validFrom'),
            },
          })}
        </Box>
      </HStack>

      <HStack align="center">
        {fields.switcher({
          name: ['validities', index, 'taxRounding'],
          defaultValue: item.taxRounding,
          preventTrigger: true,
          label: i18n.t('entity.accounting.labels.taxRounding'),
        })}
        <LeftSpacer />
        <IconWithTooltip
          Icon={<Icon value="action/help" />}
          tooltipText={i18n.t('entity.accounting.labels.taxRoundingHelperText')}
        />
        <Box flex={1} />
        {allowRemove && (
          <Button
            variant="dangerLink"
            onClick={onRemove}
            title={i18n.t('general.actions.remove')}
          />
        )}
      </HStack>
    </VStack>
  );
};

const IconWrapper = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.palettes.neutral[300][100]};
  font-size: ${({theme}) => theme.fontSizes.text.base};
`;

interface IconWithTooltipProps {
  Icon: ReactNode;
  tooltipText?: string | ReactElement;
}

const IconWithTooltip: FC<IconWithTooltipProps> = ({Icon, tooltipText}) => (
  <Tooltip placement="top" label={tooltipText ?? ''}>
    <IconWrapper>{Icon}</IconWrapper>
  </Tooltip>
);
