import axios from 'axios';

export type Response<T = any> = {
  data: T;
};

export type HttpClient = {
  get: <T>(url: string) => Promise<Response<T>>;
  post?: <T>(url: string, data?: any) => Promise<Response<T>>;
  put?: <T>(url: string, data?: any) => Promise<Response<T>>;
  delete?: <T>(url: string) => Promise<Response<T>>;
};

export const defaultHttpClient: HttpClient = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
