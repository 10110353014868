import {format} from 'date-fns';
import {Button, ColorSchemeType, Flag, Parameter, Parameters} from 'platform/components';
import {
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  Show,
  Space,
  StorageImage,
  VStack,
} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {FC, ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  FullVehicle,
  InterestVehicleData,
  NewOfferPurchaseBrokerageVehicleResponseBody,
  NewOfferPurchaseVehicleResponseBody,
  OfferSaleVehicleResponseBody,
  useGetBusinessCaseQuery,
  useGetSaleVehicleFromSaleVehicleIdQuery,
  VehicleDataResponseBody,
  VehicleTypeEnumObject,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes} from '@omnetic-dms/routes';
import {
  getVehicleCoverPhoto,
  thumbnailUrl,
  usePricePermissions,
  useVehicleConditionEvaluation,
  useVehiclePhotosPermissions,
  VehicleConditionEvaluation,
} from '@omnetic-dms/shared';

import {buildArray, composePath, parseDate, suffixTestId, TestIdProps} from 'shared';

import {useVehicleTitle} from '../../hooks/useVehicleTitle';
import {
  selectBodyStyle,
  selectDrive,
  selectFuelType,
  selectTransmission,
} from '../../store/vehicleCatalogue/selectors';
import {SimilarVehicle} from '../../types/SimilarVehicle';
import {openInNew} from '../../utils/someTeasUtils';
import {PriceBox} from '../PriceBox/PriceBox';

type FlagType = {
  name: string;
  color: ColorSchemeType;
};

export type VehicleWidgetType = {
  // TODO: Create custom interface with only subset of fields
  vehicle?: Partial<FullVehicle | InterestVehicleData | SimilarVehicle | VehicleDataResponseBody>;
  extraControls?: ReactElement;
  flags?: Array<FlagType>;
  shouldShowDetailButton?: boolean;
  shouldShowPrice?: boolean;
  shouldShowCondition?: boolean;
  extraFlags?: ReactElement;
  purchaseVehicle?: NewOfferPurchaseVehicleResponseBody;
  purchaseBrokeraggeVehicle?: NewOfferPurchaseBrokerageVehicleResponseBody;
  selling?: OfferSaleVehicleResponseBody;
  showMileagePurchase?: boolean;
  showMileageSale?: boolean;
  saleVehicleId?: string;
} & TestIdProps;

export const VehicleWidget: FC<VehicleWidgetType> = (props) => {
  const {
    vehicle,
    extraControls,
    flags,
    shouldShowDetailButton = true,
    shouldShowPrice = true,
    shouldShowCondition = true,
    purchaseVehicle,
    purchaseBrokeraggeVehicle,
    selling,
    saleVehicleId,
  } = props;

  const castedVehicle = vehicle as FullVehicle;

  const {vin, id, isArchived} = castedVehicle ?? {};
  const {firstRegistrationOn} = castedVehicle?.vehicle ?? {};

  const registrationPlate =
    castedVehicle?.registrationPlate || castedVehicle?.vehicle?.registrationPlate;

  const [vehicleConditionEvaluation] = useVehicleConditionEvaluation(id);

  const fuelType = useSelector(
    selectFuelType(vehicle?.fuelType, vehicle?.type as VehicleTypeEnumObject)
  );
  const transmission = useSelector(
    selectTransmission(vehicle?.transmission, vehicle?.type as VehicleTypeEnumObject)
  );
  const bodyStyle = useSelector(
    selectBodyStyle(vehicle?.bodyStyle, vehicle?.type as VehicleTypeEnumObject)
  );
  const driveType = useSelector(
    selectDrive(vehicle?.drive, vehicle?.type as VehicleTypeEnumObject)
  );

  const formatNumber = useFormatNumber();
  const getVehicleTitle = useVehicleTitle(vehicle);

  const {canReadVehicleAlbumPhotos} = useVehiclePhotosPermissions({
    vehicleId: id,
  });

  const coverPhoto = useMemo(
    () => getVehicleCoverPhoto((vehicle as FullVehicle)?.photos ?? []),
    [vehicle]
  );
  const {canReadVehicleSellingPrice, canReadVehiclePurchasePrice} = usePricePermissions({
    // TODO: maybe change to vehicleId instead of id
    //  https://carvago.atlassian.net/browse/T20-63748
    //  https://carvago.atlassian.net/browse/T20-63935
    //  https://carvago.atlassian.net/browse/T20-63943
    //  https://carvago.atlassian.net/browse/T20-63938
    //  https://carvago.atlassian.net/browse/T20-63988
    vehicleRecordId: castedVehicle.id,
    businessCaseRecordId: null,
  });
  const canReadPrices = canReadVehicleSellingPrice && canReadVehiclePurchasePrice;
  const params = useParams();

  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: params?.id ?? ''},
    {skip: isNilOrEmpty(params?.id)}
  );
  const businessCaseType = businessCase?.businessCaseInternalType;

  const {data: saleVehicle} = useGetSaleVehicleFromSaleVehicleIdQuery(
    {
      vehicleId: saleVehicleId ?? '',
    },
    {skip: isNilOrEmpty(saleVehicleId)}
  );

  const vehicleInformation = buildArray<Parameter>()
    .when(isNotNilOrEmpty(vin), vin)
    .when(isNilOrEmpty(vin), `${i18n.t('entity.vehicle.notifications.noVIN')}`)
    .when(isNotNilOrEmpty(registrationPlate), registrationPlate)
    .when(
      isNilOrEmpty(registrationPlate),
      `${i18n.t('entity.vehicle.notifications.noLicencePlate')}`
    )
    .when(
      firstRegistrationOn,
      isNotNil(firstRegistrationOn) ? format(parseDate(firstRegistrationOn), 'yyyy') : null
    )
    .when(
      props.showMileagePurchase && isNotNilOrEmpty(saleVehicle?.purchaseMileage),
      `${formatNumber(saleVehicle?.purchaseMileage, 0)} ${i18n.t('general.metric.km')}`
    )
    .when(
      props.showMileageSale && isNotNilOrEmpty(saleVehicle?.saleMileage),
      `${formatNumber(saleVehicle?.saleMileage, 0)} ${i18n.t('general.metric.km')}`
    )
    .when(transmission, transmission?.label)
    .when(fuelType, fuelType?.label)
    .when(bodyStyle, bodyStyle?.label)
    .when(driveType, driveType?.label);

  const foreignPrice =
    businessCaseType === 'SELLING'
      ? saleVehicle?.sellingPriceIsForeignCurrency
      : saleVehicle?.purchasePriceIsForeignCurrency;

  const isNotPurchaseBrokerage = businessCaseType !== 'PURCHASE_BROKERAGE';

  return (
    <Grid columns={12} data-testid={props['data-testid']}>
      <GridItem span={7}>
        <HStack spacing={0}>
          {/* Unsupported property in Box */}
          {/* eslint-disable-next-line react/forbid-dom-props */}
          <div style={{filter: isArchived ? 'grayscale(100%)' : undefined}}>
            <Box
              borderRadius="small"
              overflow="hidden"
              width={25}
              height={18}
              data-testid={suffixTestId(`image`, props)}
            >
              <StorageImage
                shouldUseOriginal={!coverPhoto}
                url={canReadVehicleAlbumPhotos ? (coverPhoto?.url ?? thumbnailUrl) : undefined}
                width={25}
                height={18}
                fit="cover"
              />
            </Box>
          </div>
          <Box flex={1} paddingLeft={4}>
            <HStack data-testid={suffixTestId(`flags`, props)} spacing={1}>
              <Show when={props.extraFlags}>{props.extraFlags}</Show>
              <Show when={flags?.length}>
                {flags?.map((flag, index) => (
                  <Flag
                    key={flag.name}
                    data-testid={suffixTestId(`flag[${index}]`, props)}
                    size="small"
                    colorScheme={flag.color}
                    label={flag.name}
                  />
                ))}
              </Show>
            </HStack>

            <Space vertical={1} />

            <HStack align="center">
              <Heading size={4} data-testid={suffixTestId(`title`, props)}>
                {(vehicle as FullVehicle)?.title ?? getVehicleTitle}
              </Heading>
            </HStack>
            <HStack spacing={1} wrap>
              <Parameters
                parameters={vehicleInformation}
                size="small"
                color="secondary"
                data-testid={suffixTestId(`parameters`, props)}
                appendix={
                  shouldShowCondition && (
                    <VehicleConditionEvaluation
                      size="small"
                      color="secondary"
                      vehicleCondition={vehicleConditionEvaluation}
                      data-testid={suffixTestId(`overallConditions`, props)}
                    />
                  )
                }
              />
            </HStack>
          </Box>
        </HStack>
      </GridItem>
      <GridItem span={5}>
        <VStack height="100%" justify="space-between">
          <HStack justify="flex-end" align="center">
            {extraControls}
            {shouldShowDetailButton && (
              <Box paddingLeft={2}>
                <Button
                  data-testid={suffixTestId(`buttonDetail`, props)}
                  variant="outlined"
                  leftIcon="action/launch"
                  onClick={() => {
                    if (id) {
                      openInNew(composePath(vehiclesRoutes.detail, {params: {id}}));
                    }
                  }}
                  title={i18n.t('entity.vehicle.labels.detail')}
                />
              </Box>
            )}
          </HStack>
          {shouldShowPrice && canReadPrices && (
            <HStack justify="flex-end" align="center">
              <PriceBox
                data-testid={suffixTestId(`sellingPrice`, props)}
                isHorizontal
                align="right"
                isVatDeductible={
                  purchaseVehicle?.buying?.vatDeductible ??
                  purchaseBrokeraggeVehicle?.pricing?.vatDeductible ??
                  selling?.vatDeductible
                }
                price={
                  purchaseVehicle?.buying?.buyingPrice?.priceWithVat ??
                  purchaseVehicle?.buying?.buyingPrice?.priceNotDeductible ??
                  purchaseBrokeraggeVehicle?.pricing?.sellingPrice?.priceWithVat ??
                  purchaseBrokeraggeVehicle?.pricing?.sellingPrice?.priceNotDeductible ??
                  selling?.sellingPrice?.withVat
                }
                priceSub={
                  purchaseVehicle?.buying?.buyingPrice?.priceWithoutVat ??
                  purchaseBrokeraggeVehicle?.pricing?.sellingPrice?.priceWithoutVat ??
                  selling?.sellingPrice?.withoutVat
                }
                foreignPrice={isNotPurchaseBrokerage ? foreignPrice : null}
              />
            </HStack>
          )}
        </VStack>
      </GridItem>
    </Grid>
  );
};
