import {Box} from 'platform/foundation';

import {FC, useCallback, useMemo} from 'react';

import {isArray, isString} from 'ramda-adjunct';

import {vehiclesRoutes} from '@omnetic-dms/routes';

import {composePath, useNavigate} from 'shared';

import {DataGrid, ActionCallback, DataGridProps, DefinitionResponseBody} from 'features/datagrid';

export const SelectVehicleDialogDataGrid: FC<Partial<DataGridProps>> = (props) => {
  const navigateTo = useNavigate();

  const actionCallback: ActionCallback = useCallback(
    ({actionKey, rowId, rowData}) => {
      if (!isString(rowId) || isArray(rowData)) {
        return;
      }

      switch (actionKey) {
        case 'detail':
          navigateTo(composePath(vehiclesRoutes.detail, {params: {id: rowData.id as string}}));
          break;
        default:
          // TODO: Create warn utils T20-17310
          // eslint-disable-next-line no-console
          console.warn(`Action callback was not specified for action ${actionKey}`);
          break;
      }
    },
    [navigateTo]
  );

  const definitionBehaviorOverrides = useMemo<Partial<DefinitionResponseBody['behavior']>>(
    () => ({
      rowSelectMode: 'SINGLE',
    }),
    []
  );

  return (
    <Box height="60vh">
      <DataGrid
        gridCode="business-case-add-vehicle"
        actionCallback={actionCallback}
        onRowSelectionChange={props.onRowSelectionChange}
        _useAsLastResort_definitionBehaviorOverrides={definitionBehaviorOverrides}
      />
    </Box>
  );
};
