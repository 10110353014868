import {z} from 'zod';

export const OrderActionKeySchema = z.enum([
  'offer',
  'order-list',
  'lock',
  'unlock',
  'payment',
  'discount',
  'order-summary',
  'change-order',
  'cancel',
  're-open',
  'send-email-sms',
  'claim-handling-protocol',
  'claim-resolution-protocol',
  'lock-claim',
  'decision-on-claim',
  'unlock-claim',
  'cancel-claim',
  'synchronize-customer',
]);

export type OrderActionKey = z.infer<typeof OrderActionKeySchema>;

export const PostOrderActionApiResponseSchema = z
  .object({
    offer: z
      .object({
        url: z.string().nullable().optional(),
        pdfUrl: z.string().nullable().optional(),
        fileId: z.string().nullable().optional(),
        remoteFileId: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
    'order-list': z
      .object({
        url: z.string().nullable().optional(),
        pdfUrl: z.string().nullable().optional(),
        fileId: z.string().nullable().optional(),
        remoteFileId: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
    document: z
      .object({
        url: z.string().nullable().optional(),
        pdfUrl: z.string().nullable().optional(),
        fileId: z.string().nullable().optional(),
        remoteFileId: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
  })
  .partial()
  .optional();

export type PostOrderActionApiResponse = z.infer<typeof PostOrderActionApiResponseSchema>;

export const PostOrderActionApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  actionKey: OrderActionKeySchema,
  body: z
    .object({
      transferCustomerContract: z.boolean().nullable().optional(),
    })
    .nullable()
    .optional(),
});

export type PostOrderActionApiArg = z.infer<typeof PostOrderActionApiArgSchema>;

export const GetOrderActionsApiResponseSchema = z
  .object({
    orderActions: z
      .object({
        optionClick: OrderActionKeySchema.nullable().optional(),
        options: z
          .array(
            z
              .object({
                type: z.string().nullable().optional(),
                key: OrderActionKeySchema.nullable().optional(),
                style: z.string().nullable().optional(),
                position: z.number().nullable().optional(),
              })
              .nullable()
          )
          .optional(),
      })
      .nullable()
      .optional(),
  })
  .nullable();

export type GetOrderActionsApiResponse = z.infer<typeof GetOrderActionsApiResponseSchema>;

export const GetOrderActionsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
});

export type GetOrderActionsApiArg = z.infer<typeof GetOrderActionsApiArgSchema>;
