import {
  Button,
  ButtonGroup,
  ButtonProps,
  IconButton,
  Separator,
  useTranslationContext,
} from 'platform/components';
import styled from 'styled-components';

import {useMemo, ReactElement} from 'react';

import {BulkActionsPanelRendererProps} from '../types/BulkActionsPanelRenderer';

export function BulkActionsPanelRenderer({
  deselectAll,
  selectedRows,
  bulkActions,
  actionCallback,
}: BulkActionsPanelRendererProps) {
  const t = useTranslationContext();

  const buttons: Array<ReactElement<ButtonProps, typeof Button>> = useMemo(() => {
    const btns = [];
    btns.push(
      ...Object.entries(bulkActions).map(([actionKey, actionDef]) => (
        <Button
          variant="ghost"
          key={actionKey}
          onClick={() => actionCallback(actionKey)}
          leftIcon={actionDef.icon.value}
          data-testid={`button-bulkAction.${actionKey}`}
          title={actionDef.title}
        />
      ))
    );
    return btns;
  }, [bulkActions, actionCallback]);

  return (
    <Wrapper>
      <div>{t('page.datagrid.labels.numberOfSelectedRows', {selectedRows})}</div>
      <div style={{display: 'flex'}}>
        <ButtonGroup>{buttons}</ButtonGroup>
        <div style={{height: '32px'}}>
          <Separator orientation="vertical" spacing={2} />
        </div>
        <IconButton
          onClick={deselectAll}
          icon="navigation/cancel"
          data-testid="button-navigationCancel"
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
