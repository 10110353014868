import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useState} from 'react';

import {find, head, innerJoin, isNil} from 'ramda';
import {isEmptyArray, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  FileResponseBody,
  TemplateListItemResponseBody,
  useGetTemplatesQuery,
  useLazyGetVehicleWarehouseMovementQuery,
  useListAvailableVehicleWarehousesForVehicleMovementQuery,
  useListVehicleWarehousesQuery,
  useTransferVehicleWarehouseMovementMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {buildArray, getApiDateString, suffixTestId, TestIdProps, yupString} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {DocumentTemplateBox} from '../../DocumentTemplateBox/DocumentTemplateBox';

interface VehicleWarehouseTransferProps extends TestIdProps {
  vehicleId: string;
  saleVehicleId: string;
  onDocumentCreation: (documents: FileResponseBody[]) => void;
  onMovementComplete?: () => void;
  branchId?: string;
}

interface VehicleWarehouseTransferFormFields {
  vehicleWarehouseId: string;
  movementAt: string;
  note: string | null;
}

const STOCK_IN_DOCUMENT_TEMPLATES_CODE = 'receive-vehicle-warehouse-movement';
const STOCK_OUT_DOCUMENT_TEMPLATES_CODE = 'issue-vehicle-warehouse-movement';

export function VehicleWarehouseTransfer(props: VehicleWarehouseTransferProps) {
  const isWithBranchId = isNotNilOrEmpty(props.branchId);

  const [isStockInDocumentSelected, setIsStockInDocumentSelected] = useState(true);
  const [isStockOutDocumentSelected, setIsStockOutDocumentSelected] = useState(true);
  const [stockInDocumentTemplate, setStockInDocumentTemplate] =
    useState<TemplateListItemResponseBody>();
  const [stockOutDocumentTemplate, setStockOutDocumentTemplate] =
    useState<TemplateListItemResponseBody>();

  const {
    data: warehouses = [],
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useListAvailableVehicleWarehousesForVehicleMovementQuery({
    vehicleId: props.vehicleId,
  });

  const {data: warehousesByBranch = []} = useListVehicleWarehousesQuery(
    {
      branchId: props.branchId,
    },
    {
      skip: isNil(props.branchId),
    }
  );

  const availableWarehouses = isWithBranchId
    ? innerJoin(
        (warehouseByUser, warehouseByBranch) => warehouseByUser.id === warehouseByBranch.id,
        warehouses,
        warehousesByBranch
      )
    : warehouses;

  const {
    data: stockInTemplates,
    isLoading: isStockInTemplatesLoading,
    isError: isStockInTemplatesError,
  } = useGetTemplatesQuery(
    {
      documentKindCode: STOCK_IN_DOCUMENT_TEMPLATES_CODE,
    },
    {
      skip: isEmptyArray(availableWarehouses),
    }
  );

  const {
    data: stockOutTemplates,
    isLoading: isStockOutTemplatesLoading,
    isError: isStockOutTemplatesError,
  } = useGetTemplatesQuery(
    {
      documentKindCode: STOCK_OUT_DOCUMENT_TEMPLATES_CODE,
    },
    {
      skip: isEmptyArray(availableWarehouses),
    }
  );

  const [transferVehicle] = useTransferVehicleWarehouseMovementMutation();
  const [getMovement] = useLazyGetVehicleWarehouseMovementQuery();

  if (!stockInDocumentTemplate && isNotNilOrEmpty(stockInTemplates)) {
    setStockInDocumentTemplate(find((template) => template.primary, stockInTemplates ?? []));
  }

  if (!stockOutDocumentTemplate && isNotNilOrEmpty(stockOutTemplates)) {
    setStockOutDocumentTemplate(find((template) => template.primary, stockOutTemplates ?? []));
  }

  const warehouseOptions = availableWarehouses.map((warehouse) => ({
    label: warehouse.name,
    value: warehouse.id,
  }));

  const onSubmit: FormSubmitHandler<VehicleWarehouseTransferFormFields> = (values) =>
    transferVehicle({
      vehicleId: props.vehicleId,
      saleVehicleId: props.saleVehicleId,
      body: {
        toVehicleWarehouseId: values.vehicleWarehouseId,
        movementAt: values.movementAt,
        issueTemplateId: isStockOutDocumentSelected ? stockOutDocumentTemplate?.id : undefined,
        receiptTemplateId: isStockInDocumentSelected ? stockInDocumentTemplate?.id : undefined,
        note: values.note,
      },
    })
      .unwrap()
      .then((response) => {
        if (!isStockInDocumentSelected && !isStockOutDocumentSelected) {
          showNotification.success(i18n.t('entity.vehicle.labels.vehicleTransferred'));
          closeCurrentDialog();

          return;
        }

        return response;
      })
      .then((response) => {
        if (!response) {
          return;
        }

        return Promise.all([
          getMovement({vehicleWarehouseMovementId: response.receiptMovementId}).unwrap(),
          getMovement({vehicleWarehouseMovementId: response.issueMovementId}).unwrap(),
        ])
          .then(([stockInDocument, stockOutDocument]) => {
            props.onDocumentCreation(
              // this is already unwrapped RTK query result
              // eslint-disable-next-line no-restricted-syntax
              buildArray<FileResponseBody>()
                .when(stockInDocument.document, stockInDocument.document!)
                .when(stockOutDocument.document, stockOutDocument.document!)
            );
          })
          .catch(handleApiError);
      })
      .catch(handleApiError)
      .finally(() => {
        props.onMovementComplete?.();
      });

  const defaultValues: Partial<VehicleWarehouseTransferFormFields> = {
    vehicleWarehouseId: head(warehouseOptions)?.value,
    movementAt: getApiDateString(new Date()),
  };

  const isLoading = isWarehousesLoading || isStockInTemplatesLoading || isStockOutTemplatesLoading;
  const isError = isWarehousesError || isStockInTemplatesError || isStockOutTemplatesError;

  return (
    <VStack spacing={4}>
      <DataStatus
        isLoading={isLoading}
        isError={isError}
        isEmpty={isWithBranchId && isEmptyArray(availableWarehouses)}
        emptyMessage={i18n.t('entity.vehicleWarehouse.labels.noWarehouseForBranch')}
      >
        <Form<VehicleWarehouseTransferFormFields>
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          schema={schema}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <Grid columns={2}>
                <FormField
                  label={i18n.t('entity.vehicleWarehouse.labels.warehouse')}
                  name="vehicleWarehouseId"
                  type="choice"
                  helperText={
                    isWithBranchId
                      ? i18n.t('entity.vehicleWarehouse.labels.showingWarehousesForBranch')
                      : undefined
                  }
                  options={warehouseOptions}
                  control={control}
                  isNotClearable
                  isRequired
                  data-testid={suffixTestId('vehicleWarehouseId-select', props)}
                />
                <FormField
                  label={i18n.t('entity.vehicleWarehouse.labels.dateOfTransfer')}
                  name="movementAt"
                  type="apiDate"
                  control={control}
                  isRequired
                  data-testid={suffixTestId('movementAt', props)}
                />
              </Grid>
              <DocumentTemplateBox
                title={i18n.t('entity.document.labels.vehicleWarehouseStockOutDocument')}
                templates={stockOutTemplates}
                selectedTemplate={stockOutDocumentTemplate}
                isSelected={isStockOutDocumentSelected}
                onSelect={setIsStockOutDocumentSelected}
                onTemplateChange={setStockOutDocumentTemplate}
                onNoteChange={(value) => formApi.setValue('note', value)}
                noteLabel={i18n.t('entity.document.labels.vehicleWarehouseTransferNote')}
                isWithNote
                isDisabled
              />
              <DocumentTemplateBox
                title={i18n.t('entity.document.labels.vehicleWarehouseStockInDocument')}
                templates={stockInTemplates}
                selectedTemplate={stockInDocumentTemplate}
                isSelected={isStockInDocumentSelected}
                onSelect={setIsStockInDocumentSelected}
                onTemplateChange={setStockInDocumentTemplate}
                isDisabled
              />
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.cancel')}
                  variant="secondary"
                  onClick={closeCurrentDialog}
                  data-testid={suffixTestId('cancel-button', props)}
                />
                <FormButton
                  title={i18n.t('general.actions.confirm')}
                  type="submit"
                  control={control}
                  data-testid={suffixTestId('submit-button', props)}
                />
              </ButtonGroup>
            </VStack>
          )}
        </Form>
      </DataStatus>
    </VStack>
  );
}

const schema = object({
  vehicleWarehouseId: yupString.required(),
  movementAt: yupString.required(),
  note: yupString,
});
