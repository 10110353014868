import {css} from 'styled-components';

import {ReactNode} from 'react';

export type AlignProps = {
  children: ReactNode;
  left?: boolean;
  right?: boolean;
  center?: boolean;
};

export function Align(props: AlignProps) {
  return (
    <div
      css={css`
        text-align: ${props.right ? 'right' : props.center ? 'center' : 'left'};
      `}
    >
      {props.children}
    </div>
  );
}
