import {Alpha3CountryCode} from 'platform/components';

import {map} from 'ramda';

import {useGetPhoneNumbersQuery} from '@omnetic-dms/api';

export function usePhoneNumbers() {
  const {data} = useGetPhoneNumbersQuery();

  const countriesOptions = map(
    (phone) => ({
      countryCode: phone.code as Alpha3CountryCode,
      dialCode: phone.prefix,
      name: phone.name,
    }),
    data ?? []
  );

  return {
    countriesOptions,
  };
}
