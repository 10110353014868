import {Button, ButtonGroup, Dialog} from 'platform/components';
import {Space} from 'platform/foundation';

export type UpdateDialogProps = {
  onConfirm: VoidFunction;
  onDiscard: VoidFunction;
  text: string;
  confirmText: string;
  discardText: string;
  isOpen: boolean;
};

export function UpdateDialog(props: UpdateDialogProps) {
  return (
    <Dialog isOpen={props.isOpen} onClose={props.onDiscard} size="small">
      {props.text}
      <Space vertical={4} />
      <ButtonGroup align="right">
        <Button variant="secondary" onClick={props.onDiscard} title={props.discardText} />
        <Button variant="primary" onClick={props.onConfirm} title={props.confirmText} />
      </ButtonGroup>
    </Dialog>
  );
}
