import {DataStatus} from 'platform/components';

import {Component, ErrorInfo, ReactNode} from 'react';

export class ErrorBoundary extends Component<{children: ReactNode}, {hasError: boolean}> {
  constructor(props: {children: ReactNode}) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <DataStatus minHeight="100%" isError errorMessage="Schema includes invalid properties" />
      );
    }

    return this.props.children;
  }
}
