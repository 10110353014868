import type {CancelablePromise} from '../types/CancelablePromise';
import type {InspectionRequestBody} from '../types/InspectionRequestBody';
import type {RenderedTemplate} from '../types/RenderedTemplate';
import {request as __request} from '../utils/request';

export class DocumentService {
  /**
   * @returns RenderedTemplate
   * @throws ApiError
   */
  public static createInspectionDocument({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: InspectionRequestBody;
  }): CancelablePromise<RenderedTemplate> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/car-audit/document/inspection/render',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns RenderedTemplate
   * @throws ApiError
   */
  public static createVehicleConditionDocument({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: InspectionRequestBody;
  }): CancelablePromise<RenderedTemplate> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/car-audit/document/vehicle-condition/render',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
