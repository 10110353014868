import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import styled, {css} from 'styled-components';

export type ContentEditableProps = {
  $isInvalid?: boolean;
  $isDisabled?: boolean;
};

export const StyledContentEditable = styled(ContentEditable)<ContentEditableProps>`
  display: block;
  width: 100%;
  border: 1px solid;
  border-color: ${({theme, $isInvalid}) =>
    $isInvalid ? theme.colors.severity.danger : theme.colors.palettes.neutral[70][100]};
  border-radius: ${({theme}) => theme.getSize(1)};
  color: ${({theme}) => theme.colors.text.primary};
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  font-size: ${({theme}) => theme.fontSizes.text.small};
  line-height: ${({theme}) => theme.lineHeights.text.small};
  font-weight: ${({theme}) => theme.fontWeights.text.default};
  outline: 0;
  letter-spacing: 0;
  padding: 6px 8px;

  &:hover {
    border: 1px solid;
    border-color: ${({theme}) => theme.colors.severity.informational};
  }

  &:active,
  &:focus {
    border-color: ${({theme}) => theme.colors.severity.informational};
    box-shadow: ${({theme}) => theme.shadows.active};
  }

  ${({$isDisabled}) =>
    $isDisabled &&
    css`
      border-color: ${({theme}) => theme.colors.palettes.neutral[70][40]};
      background-color: ${({theme}) => theme.colors.palettes.white[10][40]};
      cursor: not-allowed;
      color: ${({theme}) => theme.colors.text.primary};
      box-shadow: none;

      &:focus,
      &:hover {
        border-color: ${({theme}) => theme.colors.palettes.neutral[70][40]};
      }

      .editor-link {
        cursor: not-allowed;
      }
    `}

  .mentions {
    font-size: ${({theme}) => theme.fontSizes.text.small};
    background-color: ${({theme}) => theme.colors.palettes.blue[30][100]};
  }

  .editor-link {
    color: ${({theme}) => theme.colors.general.accent};
    text-decoration: none;
  }
`;
