import {IconButton} from 'platform/components';
import {Center} from 'platform/foundation';

import {Link} from 'react-router-dom';

import {MenuItemProps} from '@omnetic-dms/api';

import {composePath, suffixTestId, TestIdProps} from 'shared';

import {HeaderButtonWrapper} from './HeaderButtonWrapper';

interface SettingsProps extends TestIdProps {
  settingsModule: MenuItemProps | undefined;
}

export function Settings(props: SettingsProps) {
  if (!props.settingsModule) {
    return null;
  }

  return (
    <Center height="100%">
      <HeaderButtonWrapper>
        <Link
          to={composePath(props.settingsModule.path, {isSearchParamsPreserved: false})}
          data-testid={suffixTestId('settings-link', props)}
        >
          <IconButton
            icon={props.settingsModule.layoutProps.icon || 'action/settings'}
            size="small"
            data-testid={suffixTestId('settings-button', props)}
          />
        </Link>
      </HeaderButtonWrapper>
    </Center>
  );
}
