import {isFunction} from 'ramda-adjunct';

import {useQueue, getRandomId} from 'shared';

import {NotificationProps} from '../Notification.types';

export function useNotificationsState({limit}: {limit: number}) {
  const {state, queue, update, cleanQueue} = useQueue<NotificationProps>({
    initialValues: [],
    limit,
  });

  /*
   * create a new notification and add it to the queue
   * */
  const showNotification = (notification: NotificationProps) => {
    const id = notification.id || getRandomId();

    update((notifications) => {
      if (notification.id && notifications.some((n) => n.id === notification.id)) {
        return notifications;
      }

      return [...notifications, {...notification, id}];
    });

    return id;
  };

  /*
   *  update a notification in the queue and return the updated notification
   * */
  const updateNotification = (id: string, notification: NotificationProps) =>
    update((notifications) => {
      const index = notifications.findIndex((n) => n.id === id);

      if (index === -1) {
        return notifications;
      }

      const newNotifications = [...notifications];
      newNotifications[index] = notification;

      return newNotifications;
    });

  /*
   * remove a notification from the queue and return the updated queue
   * */
  const hideNotification = (id: string) =>
    update((notifications) =>
      notifications.filter((notification) => {
        if (notification.id === id) {
          if (isFunction(notification.onClose)) {
            notification.onClose(notification);
          }
          return false;
        }

        return true;
      })
    );

  /*
   * clean the queue and return the updated queue
   * */
  const clean = () => update(() => []);

  return {
    notifications: state,
    queue,
    showNotification,
    updateNotification,
    hideNotification,
    cleanQueue,
    clean,
  };
}
