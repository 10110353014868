import {useState} from 'react';

import {PurchaseVehicleType} from '@omnetic-dms/api';

import {Provider} from '../hooks/useSelectVehicleContext';

export function SelectVehicleContextProvider({children}: {children: React.ReactNode}) {
  const [isVehicleModalOpen, setCreateVehicleModalOpen] = useState(false);
  const [isSelectSaleVehicleOpen, setSelectSaleVehicleOpen] = useState(false);
  const [purchaseVehicleType, setPurchaseVehicleType] = useState<PurchaseVehicleType>('IN_STOCK');

  const handleOpenCreteVehicleModal = (
    isOpen: boolean,
    purchaseVehicleType: PurchaseVehicleType
  ) => {
    setCreateVehicleModalOpen(isOpen);
    setPurchaseVehicleType(purchaseVehicleType);
  };

  return (
    <Provider
      value={{
        isVehicleModalOpen,
        setCreateVehicleModalOpen: handleOpenCreteVehicleModal,
        isSelectSaleVehicleOpen,
        setSelectSaleVehicleOpen,
        purchaseVehicleType,
      }}
    >
      {children}
    </Provider>
  );
}
