import {isNil} from 'ramda';
import {isNumber} from 'ramda-adjunct';

import {Nullish} from 'shared';

export const composeRangeArray = (from: number | Nullish, to: number | Nullish) => {
  if (isNil(from) && isNil(to)) {
    return null;
  }

  return [isNumber(from) ? from : null, isNumber(to) ? to : null];
};
