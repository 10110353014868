import {
  GetDatagridSynchronizationServiceItemsExternalHistoryApiArg,
  GetDatagridSynchronizationServiceItemsExternalHistoryApiArgSchema,
  GetDatagridSynchronizationServiceItemsExternalHistoryApiResponse,
  GetDatagridSynchronizationServiceItemsExternalHistoryApiResponseSchema,
  PostDatagridSynchronizeServiceItemsExternalHistoryApiArg,
  PostDatagridSynchronizeServiceItemsExternalHistoryApiArgSchema,
  PostDatagridSynchronizeServiceItemsExternalHistoryApiResponse,
  PostDatagridSynchronizeServiceItemsExternalHistoryApiResponseSchema,
} from '../types/metadaDatagrid';
import {metadaApi} from './baseApi/metadaApi';

export const metadaDatagridApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDatagridSynchronizationServiceItemsExternalHistory: build.query<
      GetDatagridSynchronizationServiceItemsExternalHistoryApiResponse,
      GetDatagridSynchronizationServiceItemsExternalHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/datagrid/v1/datagrid/service-items-external-history/synchronization`,
        params: {vehicleId: queryArg.vehicleId},
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceItemsExternalHistory', id: queryArg.vehicleId},
      ],
      extraOptions: {
        responseSchema: GetDatagridSynchronizationServiceItemsExternalHistoryApiResponseSchema,
        requestSchema: GetDatagridSynchronizationServiceItemsExternalHistoryApiArgSchema,
      },
    }),
    postDatagridSynchronizeServiceItemsExternalHistory: build.mutation<
      PostDatagridSynchronizeServiceItemsExternalHistoryApiResponse,
      PostDatagridSynchronizeServiceItemsExternalHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/datagrid/v1/datagrid/service-items-external-history/synchronize`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceItemsExternalHistory', id: queryArg.body?.vehicleId},
      ],
      extraOptions: {
        responseSchema: PostDatagridSynchronizeServiceItemsExternalHistoryApiResponseSchema,
        requestSchema: PostDatagridSynchronizeServiceItemsExternalHistoryApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetDatagridSynchronizationServiceItemsExternalHistoryQuery,
  usePostDatagridSynchronizeServiceItemsExternalHistoryMutation,
} = metadaDatagridApi;
