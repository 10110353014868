import {ColorSchemeType, Flag} from 'platform/components';
import {Box, HStack} from 'platform/foundation';
import styled from 'styled-components';

import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {
  EVALUATION_UNKNOWN_COLOR,
  EVALUATION_UNKNOWN_FLAG,
  evaluationColors,
} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {selectStructures} from '../../../store/carAudit/selectors';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';

interface InspectionRatingsProps extends TestIdProps {
  inspection: LoadAuditDataResponseItemBody;
}

export function InspectionRatings({inspection, ...rest}: InspectionRatingsProps) {
  const structures = useSelector(selectStructures);
  const structure = structures?.[inspection.controlStructureId];

  const getRatingValue = useCallback(
    (categoryId: string, paramDefinitionId: string) => {
      const categoryData = inspection.categories.find((category) => category.id === categoryId);

      return categoryData?.fields?.find((field) => field.paramDefinitionId === paramDefinitionId)
        ?.value;
    },
    [inspection]
  );

  const getColorScheme = useCallback(
    (value?: string): ColorSchemeType =>
      value
        ? (evaluationColors?.[parseInt(value)] ?? EVALUATION_UNKNOWN_COLOR)
        : EVALUATION_UNKNOWN_COLOR,
    []
  );

  const ratingElement = useMemo(() => {
    if (!structure || !structure.ratings) {
      return null;
    }

    return (
      <HStack spacing={1}>
        {structure.ratings.map(({categoryId, paramDefinitionId}, index) => {
          const value = getRatingValue(categoryId, paramDefinitionId);

          return (
            <Box key={`rating-item-${categoryId}-${paramDefinitionId}`}>
              <Flag
                colorScheme={getColorScheme(value)}
                size="small"
                label={value ?? EVALUATION_UNKNOWN_FLAG}
                data-testid={suffixTestId(`rating-item-[${index}]`, rest)}
              />
            </Box>
          );
        })}
      </HStack>
    );
  }, [structure, getRatingValue, getColorScheme, rest]);

  return (
    <FLexContainer data-testid={suffixTestId('rating-wrapper', rest)}>
      {ratingElement}
    </FLexContainer>
  );
}

const FLexContainer = styled.div`
  display: flex;
`;
