import {Tooltip} from 'platform/components';

import {ReactNode} from 'react';

import i18n from '@omnetic-dms/i18n';

interface NoPermissionTooltipProps {
  shouldShowTooltip: boolean;
  children: ReactNode;
}

export function NoPermissionTooltip(props: NoPermissionTooltipProps) {
  return (
    <Tooltip label={props.shouldShowTooltip ? i18n.t('general.labels.noPermission') : undefined}>
      {props.children}
    </Tooltip>
  );
}
