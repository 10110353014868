import {ManagedDeleteDialogProps} from '../../DeleteDialog/ManagedDeleteDialogProps';
import {DIALOGS_EVENTS} from './dialogsEvents';

/**
 * @about Opens a delete dialog. Dialog will be
 * closed automatically when confirmed or canceled.
 *
 * Can be closed programmatically with closeCurrentDialog
 * or closeDialog('dialog-id') utilities.
 *
 * @param options Dialog options (name, id, onConfirm)
 *
 * @example
 * openDeleteDialog({ onConfirm: () => deleteSomething().unwrap().then(...) })
 */
export function openDeleteDialog(options: ManagedDeleteDialogProps) {
  window.dispatchEvent(new CustomEvent(DIALOGS_EVENTS.openDelete, {detail: {options}}));
}
