import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

const CompetenceSchema = z.object({
  key: z.string().nullable(),
  label: z.string(),
});

const ServicePackageCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
});

const ServicePackageItemTypeSchema = z.object({
  key: z.string().nullable(),
  label: z.string().nullable(),
});

const PriceSchema = z.object({
  amount: z.number().nullable(),
  currency: z.string().nullable(),
  isPriceWithVat: z.boolean().nullable(),
  vatType: z.string().nullable(),
});

const WarehouseSchema = z.object({
  warehouseId: z.string().nullable(),
  warehouseArticleId: z.string().nullable(),
  storageLocation: z.string().nullable(),
  discountGroup: z.string().nullable(),
});

const ServicePackageItemSchema = z.object({
  id: z.string().nullable(),
  type: ServicePackageItemTypeSchema.nullable(),
  number: z.string().nullable(),
  name: z.string().nullable(),
  quantity: z.number().nullable(),
  unit: z.string().nullable(),
});

const BasicServicePackageSchema = z.object({
  id: z.string(),
  name: z.string(),
  number: z.string().nullable(),
  servicePackageCategory: ServicePackageCategorySchema.nullable(),
  competence: CompetenceSchema.nullable(),
  estimatedTime: z.number().nullable(),
  vehicleType: z.string().nullable(),
  vehicleMakes: z.array(z.string()).nullable().optional(),
  vehicleModelFamilies: z.array(z.string().nullable()).nullable(),
  vehicleModels: z.array(z.string().nullable()).nullable(),
  desrciption: z.string().nullable(),
  servicePackageItems: z.array(ServicePackageItemSchema.nullable()).nullable(),
});

export const GetServicePackageHoverInformationApiResponseSchema = z
  .object({
    servicePackage: z
      .object({
        id: z.string(),
        name: z.string(),
        number: z.string().nullable(),
        desrciption: z.string().nullable(),
        competence: CompetenceSchema.nullable(),
        estimatedTime: z.number().nullable(),
      })
      .nullable(),
  })
  .nullable();

export type GetServicePackageHoverInformationApiResponse = z.infer<
  typeof GetServicePackageHoverInformationApiResponseSchema
>;

export const GetServicePackageHoverInformationApiArgSchema = z.object({
  servicePackageId: z.string(),
});

export type GetServicePackageHoverInformationApiArg = z.infer<
  typeof GetServicePackageHoverInformationApiArgSchema
>;

export const GetBasicInformationServicePackageApiResponseSchema = z
  .object({
    servicePackage: BasicServicePackageSchema.nullable(),
  })
  .nullable();

export type GetBasicInformationServicePackageApiResponse = z.infer<
  typeof GetBasicInformationServicePackageApiResponseSchema
>;

export const GetBasicInformationServicePackageApiArgSchema = z.object({
  servicePackageId: z.string(),
});

export type GetBasicInformationServicePackageApiArg = z.infer<
  typeof GetBasicInformationServicePackageApiArgSchema
>;

export const DeleteServicePackageItemsApiResponseSchema = BaseVoidResponseSchema;

export type DeleteServicePackageItemsApiResponse = z.infer<
  typeof DeleteServicePackageItemsApiResponseSchema
>;

export const DeleteServicePackageItemsApiArgSchema = z.object({
  servicePackageId: z.string(),
  body: z.object({
    servicePackageItemIds: z.array(z.string()),
  }),
});

export type DeleteServicePackageItemsApiArg = z.infer<typeof DeleteServicePackageItemsApiArgSchema>;

export const GetServicePackageItemLabourApiResponseSchema = z
  .object({
    servicePackageItem: z
      .object({
        servicePackageId: z.string().nullable(),
        servicePackageItemId: z.string().nullable(),
        labourCatalogId: z.string().nullable(),
        priceType: z
          .enum([
            'SVCPRICETYPE_TIME_NORM',
            'SVCPRICETYPE_CALCULATION_PRICE',
            'SVCPRICETYPE_DIRECT_PRICE',
            'SVCPRICETYPE_COOPERATION',
          ])
          .nullable(),
        name: z.string().nullable(),
        number: z.string().nullable(),
        workType: z.string().nullable(),
        quantity: z.number().nullable(),
        unit: z.string().nullable(),
        isUnitPriceWithVat: z.boolean().nullable(),
        basePriceSource: z.string().nullable(),
        basePriceType: z.string().nullable(),
        isPriceUnitManual: z.boolean().nullable(),
        purchasePriceUnitWithoutVat: z.number().nullable(),
        purchasePriceUnitVat: z.number().nullable(),
        purchasePriceUnitWithVat: z.number().nullable(),
        priceUnitWithoutVat: z.number().nullable(),
        priceUnitVat: z.number().nullable(),
        priceUnitWithVat: z.number().nullable(),
        vatType: z.string().nullable(),
        currency: z.string().nullable(),
        isDoNotApplyDiscount: z.boolean().nullable(),
        isDoNotApplyDiscountEditable: z.boolean().nullable(),
      })
      .nullable(),
  })
  .nullable();

export type GetServicePackageItemLabourApiResponse = z.infer<
  typeof GetServicePackageItemLabourApiResponseSchema
>;

export const GetServicePackageItemLabourApiArgSchema = z.object({
  servicePackageId: z.string(),
  servicePackageItemId: z.string(),
});

export type GetServicePackageItemLabourApiArg = z.infer<
  typeof GetServicePackageItemLabourApiArgSchema
>;

export const PatchServicePackageItemLabourApiResponseSchema = BaseVoidResponseSchema;

export type PatchServicePackageItemLabourApiResponse = z.infer<
  typeof PatchServicePackageItemLabourApiResponseSchema
>;

export const PatchServicePackageItemLabourApiArgSchema = z.object({
  servicePackageId: z.string(),
  servicePackageItemId: z.string(),
  body: z
    .object({
      name: z.string(),
      number: z.string().nullable(),
      workType: z.string(),
      quantity: z.number(),
      unit: z.string(),
      isPriceUnitManual: z.boolean(),
      isUnitPriceWithVat: z.boolean().nullable(),
      purchasePricePerUnit: z.number().nullable(),
      sellingPricePerUnit: z.number().nullable(),
      vatType: z.string().nullable(),
    })
    .nullable(),
});

export type PatchServicePackageItemLabourApiArg = z.infer<
  typeof PatchServicePackageItemLabourApiArgSchema
>;

export const GetServicePackageItemMaterialApiResponseSchema = z
  .object({
    servicePackageItem: z
      .object({
        servicePackageItemId: z.string().nullable(),
        servicePackageId: z.string().nullable(),
        warehouse: WarehouseSchema.nullable(),
        priceType: z.string().nullable(),
        name: z.string().nullable(),
        number: z.string().nullable(),
        workType: z.string().nullable(),
        quantity: z.number().nullable(),
        unit: z.string().nullable(),
        isUnitPriceWithVat: z.boolean().nullable(),
        basePriceSource: z.string().nullable(),
        basePriceType: z.string().nullable(),
        isPriceUnitManual: z.boolean().nullable(),
        purchasePriceUnitWithoutVat: z.number().nullable(),
        purchasePriceUnitVat: z.number().nullable(),
        purchasePriceUnitWithVat: z.number().nullable(),
        priceUnitWithoutVat: z.number().nullable(),
        priceUnitVat: z.number().nullable(),
        priceUnitWithVat: z.number().nullable(),
        vatType: z.string().nullable(),
        currency: z.string().nullable(),
        isDoNotApplyDiscount: z.boolean().nullable(),
        isDoNotApplyDiscountEditable: z.boolean().nullable(),
      })
      .nullable(),
  })
  .nullable();

export type GetServicePackageItemMaterialApiResponse = z.infer<
  typeof GetServicePackageItemMaterialApiResponseSchema
>;

export const GetServicePackageItemMaterialApiArgSchema = z.object({
  servicePackageId: z.string(),
  servicePackageItemId: z.string(),
});

export type GetServicePackageItemMaterialApiArg = z.infer<
  typeof GetServicePackageItemMaterialApiArgSchema
>;

export const PatchServicePackageItemMaterialApiResponseSchema = BaseVoidResponseSchema;

export type PatchServicePackageItemMaterialApiResponse = z.infer<
  typeof PatchServicePackageItemMaterialApiResponseSchema
>;

export const PatchServicePackageItemMaterialApiArgSchema = z.object({
  servicePackageId: z.string(),
  servicePackageItemId: z.string(),
  body: z
    .object({
      quantity: z.number(),
      isPriceUnitManual: z.boolean(),
      sellingPricePerUnit: z.number().nullable(),
      isUnitPriceWithVat: z.boolean(),
      vatType: z.string().nullable(),
    })
    .nullable(),
});

export type PatchServicePackageItemMaterialApiArg = z.infer<
  typeof PatchServicePackageItemMaterialApiArgSchema
>;

export const PostServicePackageApiResponseSchema = z
  .object({
    id: z.string().nullable(),
  })
  .nullable();

export type PostServicePackageApiResponse = z.infer<typeof PostServicePackageApiResponseSchema>;

export const PostServicePackageApiArgSchema = z.object({
  body: z.object({
    name: z.string(),
    isActive: z.boolean(),
    branchIds: z.array(z.string().nullable()).nullable(),
    authorizationProfileIds: z.array(z.string().nullable()).nullable(),
    number: z.string().nullable(),
    servicePackageCategoryId: z.string(),
    vehicleType: z.string().nullable(),
    vehicleMakes: z.array(z.string()).nullable().optional(),
    vehicleModelFamilies: z.array(z.string()).nullable().optional(),
    vehicleModels: z.array(z.string()).nullable().optional(),
    vehicleAgeType: z.enum(['VEHICLE_AGE_UNDECIDED', 'VEHICLE_AGE_DECIDED']),
    vehicleAgeFrom: z.number().nullable(),
    vehicleAgeTo: z.number().nullable(),
    isLockServicePackage: z.boolean(),
    isDoNotApplyDiscount: z.boolean(),
  }),
});

export type PostServicePackageApiArg = z.infer<typeof PostServicePackageApiArgSchema>;

export const GetServicePackageBasicInformationApiResponseSchema = z
  .object({
    name: z.string().nullable(),
    isActive: z.boolean().nullable(),
    branchIds: z.array(z.string()).nullable(),
    authorizationProfileIds: z.array(z.string()).nullable(),
    number: z.string().nullable(),
    servicePackageCategoryId: z.string().nullable(),
    vehicleType: z.string().nullable(),
    vehicleMakes: z.array(z.string()).nullable().optional(),
    vehicleModelFamilies: z.array(z.string()).nullable(),
    vehicleModels: z.array(z.string().nullable()).nullable(),
    vehicleAgeType: z.enum(['VEHICLE_AGE_UNDECIDED', 'VEHICLE_AGE_DECIDED']).nullable(),
    vehicleAgeFrom: z.number().nullable(),
    vehicleAgeTo: z.number().nullable(),
    isLockServicePackage: z.boolean().nullable(),
    isDoNotApplyDiscount: z.boolean().nullable(),
  })
  .nullable();

export type GetServicePackageBasicInformationApiResponse = z.infer<
  typeof GetServicePackageBasicInformationApiResponseSchema
>;

export const GetServicePackageBasicInformationApiArgSchema = z.object({
  servicePackageId: z.string(),
});

export type GetServicePackageBasicInformationApiArg = z.infer<
  typeof GetServicePackageBasicInformationApiArgSchema
>;

export const DeleteServicePackageDeleteApiResponseSchema = BaseVoidResponseSchema;

export type DeleteServicePackageDeleteApiResponse = z.infer<
  typeof DeleteServicePackageDeleteApiResponseSchema
>;

export const DeleteServicePackageDeleteApiArgSchema = z.object({
  servicePackageId: z.string(),
});

export type DeleteServicePackageDeleteApiArg = z.infer<
  typeof DeleteServicePackageDeleteApiArgSchema
>;

export const PatchServicePackageBasicInformationApiResponseSchema = BaseVoidResponseSchema;

export type PatchServicePackageBasicInformationApiResponse = z.infer<
  typeof PatchServicePackageBasicInformationApiResponseSchema
>;

export const PatchServicePackageBasicInformationApiArgSchema = z.object({
  servicePackageId: z.string(),
  body: z
    .object({
      name: z.string(),
      isActive: z.boolean(),
      branchIds: z.array(z.string()).nullable(),
      authorizationProfileIds: z.array(z.string()).nullable(),
      number: z.string().nullable(),
      servicePackageCategoryId: z.string(),
      vehicleType: z.string().nullable(),
      vehicleMakes: z.array(z.string()).nullable().optional(),
      vehicleModelFamilies: z.array(z.string()).nullable(),
      vehicleModels: z.array(z.string()).nullable(),
      vehicleAgeType: z.enum(['VEHICLE_AGE_UNDECIDED', 'VEHICLE_AGE_DECIDED']),
      vehicleAgeFrom: z.number().nullable(),
      vehicleAgeTo: z.number().nullable(),
      isLockServicePackage: z.boolean(),
      isDoNotApplyDiscount: z.boolean(),
    })
    .nullable(),
});

export type PatchServicePackageBasicInformationApiArg = z.infer<
  typeof PatchServicePackageBasicInformationApiArgSchema
>;

export const GetServicePackageRequestDetailApiResponseSchema = z
  .object({
    isFixedPrice: z.boolean().nullable(),
    description: z.string().nullable(),
    solution: z.string().nullable(),
    competence: z.string().nullable(),
    estimatedTime: z.number().nullable(),
    isPartsRequest: z.boolean().nullable(),
    fixedPrice: PriceSchema.nullable(),
  })
  .nullable();

export type GetServicePackageRequestDetailApiResponse = z.infer<
  typeof GetServicePackageRequestDetailApiResponseSchema
>;

export const GetServicePackageRequestDetailApiArgSchema = z.object({
  servicePackageId: z.string(),
});

export type GetServicePackageRequestDetailApiArg = z.infer<
  typeof GetServicePackageRequestDetailApiArgSchema
>;

export const PatchServicePackageRequestDetailApiResponseSchema = BaseVoidResponseSchema;

export type PatchServicePackageRequestDetailApiResponse = z.infer<
  typeof PatchServicePackageRequestDetailApiResponseSchema
>;

export const PatchServicePackageRequestDetailApiArgSchema = z.object({
  servicePackageId: z.string(),
  body: z
    .object({
      isFixedPrice: z.boolean(),
      description: z.string().nullable(),
      solution: z.string().nullable(),
      competence: z.string().nullable(),
      estimatedTime: z.number().nullable(),
      isPartsRequest: z.boolean(),
      fixedPrice: PriceSchema.optional(),
    })
    .nullable(),
});

export type PatchServicePackageRequestDetailApiArg = z.infer<
  typeof PatchServicePackageRequestDetailApiArgSchema
>;

export const PostServicePackageSetActiveApiResponseSchema = BaseVoidResponseSchema;

export type PostServicePackageSetActiveApiResponse = z.infer<
  typeof PostServicePackageSetActiveApiResponseSchema
>;

export const PostServicePackageSetActiveApiArgSchema = z.object({
  servicePackageId: z.string(),
});

export type PostServicePackageSetActiveApiArg = z.infer<
  typeof PostServicePackageSetActiveApiArgSchema
>;

export const PostServicePackageSetInActiveApiResponseSchema = BaseVoidResponseSchema;

export type PostServicePackageSetInActiveApiResponse = z.infer<
  typeof PostServicePackageSetInActiveApiResponseSchema
>;

export const PostServicePackageSetInActiveApiArgSchema = z.object({
  servicePackageId: z.string(),
});

export type PostServicePackageSetInActiveApiArg = z.infer<
  typeof PostServicePackageSetInActiveApiArgSchema
>;
