import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
} from 'date-fns';

import {TranslationInterpreter} from '../../TranslationProvider/TranslationProvider';
import {RelativeDateRange} from '../types';

export function getDefaultRelativeDateRanges(t: TranslationInterpreter): RelativeDateRange[] {
  const today = startOfToday();
  const yesterday = addDays(today, -1);
  const tomorrow = addDays(today, 1);

  const startOfThisWeek = startOfWeek(today, {weekStartsOn: 1});
  const startOfLastWeek = addWeeks(startOfThisWeek, -1);
  const startOfNextWeek = addWeeks(startOfThisWeek, 1);

  const startOfThisMonth = startOfMonth(today);
  const startOfLastMonth = addMonths(startOfThisMonth, -1);
  const startOfNextMonth = addMonths(startOfThisMonth, 1);

  const startOfThisYear = startOfYear(today);
  const startOfLastYear = addYears(startOfThisYear, -1);
  const startOfNextYear = addYears(startOfThisYear, 1);

  return [
    {label: t('general.labels.yesterday'), value: [yesterday, yesterday]},
    {label: t('general.labels.today'), value: [today, today]},
    {label: t('general.labels.tomorrow'), value: [tomorrow, tomorrow]},
    {
      label: t('general.labels.lastWeek'),
      value: [startOfLastWeek, endOfWeek(startOfLastWeek, {weekStartsOn: 1})],
    },
    {
      label: t('general.labels.thisWeek'),
      value: [startOfThisWeek, endOfWeek(startOfThisWeek, {weekStartsOn: 1})],
    },
    {
      label: t('general.labels.nextWeek'),
      value: [startOfNextWeek, endOfWeek(startOfNextWeek, {weekStartsOn: 1})],
    },
    {label: t('general.labels.lastMonth'), value: [startOfLastMonth, endOfMonth(startOfLastMonth)]},
    {label: t('general.labels.thisMonth'), value: [startOfThisMonth, endOfMonth(startOfThisMonth)]},
    {label: t('general.labels.nextMonth'), value: [startOfNextMonth, endOfMonth(startOfNextMonth)]},
    {label: t('general.labels.lastYear'), value: [startOfLastYear, endOfYear(startOfLastYear)]},
    {label: t('general.labels.thisYear'), value: [startOfThisYear, endOfYear(startOfThisYear)]},
    {label: t('general.labels.nextYear'), value: [startOfNextYear, endOfYear(startOfNextYear)]},
  ];
}
