import {ForwardedRef} from 'react';

export enum HintTypeEnum {
  ErrorVin = 'ErrorVin',
  InfoLicense = 'InfoLicense',
}

export type UseScrollToSectionType = {
  refs: Record<string, ForwardedRef<HTMLDivElement>>;
  triggerScroll: () => void;
};

export type HintType = `${HintTypeEnum}` | null;
