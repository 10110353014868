import {createSelector} from '@reduxjs/toolkit';

import {ADMINISTRATOR_ROLE} from '../../types/CommonAppTypes';
import {selectGeneralSettings} from '../generalSettings/selectors';

export const selectRoleList = createSelector(
  selectGeneralSettings,
  (state) => state?.userManagement.rolesListForUserManagement
);
export const loadingRoleList = createSelector(selectRoleList, (state) => state?.loading);
export const rolesListForUserManagement = createSelector(selectRoleList, (state) => state?.data);
export const selectUserList = createSelector(
  selectGeneralSettings,
  (state) => state?.userManagement.userList
);
export const loadingUserList = createSelector(selectUserList, (state) => state?.loading);

export const selectSingleRole = createSelector(
  selectGeneralSettings,
  (state) => state?.userManagement.singleRole
);
export const selectSingleRoleDeletionError = createSelector(
  selectSingleRole,
  (state) => state?.deletion.error
);
export const selectSingleRoleData = createSelector(selectSingleRole, (state) => state?.data);

export const selectAllRoleList = createSelector(
  selectGeneralSettings,
  (state) => state?.userManagement.rolesListFull
);
export const selectAllRoleListData = createSelector(selectAllRoleList, (state) => state?.data);
export const allRolesListWithoutAdmin = createSelector(selectAllRoleListData, (state) =>
  state?.filter((item) => item.title.toUpperCase() !== ADMINISTRATOR_ROLE)
);
export const adminRoleSelector = createSelector(
  selectAllRoleListData,
  (state) => state?.filter((item) => item.title.toUpperCase() === ADMINISTRATOR_ROLE) || []
);
