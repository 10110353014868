import {
  CreateCommentApiArg,
  CreateCommentApiResponse,
  DeleteCommentApiArg,
  DeleteCommentApiResponse,
  GetCommentApiArg,
  GetCommentApiResponse,
  GetCommentsApiArg,
  GetCommentsApiResponse,
  UpdateCommentApiArg,
  UpdateCommentApiResponse,
} from '../types/comment';
import {omneticApi} from './baseApi/omneticApi';

export const commentApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getComments: build.query<GetCommentsApiResponse, GetCommentsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/comment/${queryArg.resourceId}/${queryArg.recordId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'comments', id: queryArg.resourceId + queryArg.recordId},
      ],
    }),
    createComment: build.mutation<CreateCommentApiResponse, CreateCommentApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/comment/${queryArg.resourceId}/${queryArg.recordId}`,
        method: 'POST',
        body: queryArg.createCommentRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'comments', id: queryArg.resourceId + queryArg.recordId},
      ],
    }),
    getComment: build.query<GetCommentApiResponse, GetCommentApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/comment/${queryArg.resourceId}/${queryArg.recordId}/${queryArg.commentId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'comment', id: queryArg.resourceId + queryArg.recordId + queryArg.commentId},
      ],
    }),
    updateComment: build.mutation<UpdateCommentApiResponse, UpdateCommentApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/comment/${queryArg.resourceId}/${queryArg.recordId}/${queryArg.commentId}`,
        method: 'PATCH',
        body: queryArg.updateCommentRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'comment', id: queryArg.resourceId + queryArg.recordId + queryArg.commentId},
        {type: 'comments', id: queryArg.resourceId + queryArg.recordId},
      ],
    }),
    deleteComment: build.mutation<DeleteCommentApiResponse, DeleteCommentApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/comment/${queryArg.resourceId}/${queryArg.recordId}/${queryArg.commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'comment', id: queryArg.resourceId + queryArg.recordId + queryArg.commentId},
        {type: 'comments', id: queryArg.resourceId + queryArg.recordId},
      ],
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useCreateCommentMutation,
  useGetCommentQuery,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
} = commentApi;
