import {z} from 'zod';

import {Nullish} from 'shared';

export const AdditionalFieldsKeysSchema = z.enum(['affiliatedEntity']);

export type AdditionalFieldsKeys = z.infer<typeof AdditionalFieldsKeysSchema>;
export const CustomFieldValueSchema = z.object({
  definitionId: AdditionalFieldsKeysSchema,
  value: z.object({
    type: z.enum(['integer', 'decimal', 'string', 'money', 'date', 'datetime', 'boolean']),
    value: z.any(),
  }),
});
export type CustomFieldValue = z.infer<typeof CustomFieldValueSchema>;

export type additionalFieldFormType = Record<AdditionalFieldsKeys, boolean | Nullish>;
