import {useCallback} from 'react';
import ReactDOM from 'react-dom';

import {GridApi} from '../types/AgGridTypes';
import {ToolpanelType} from '../types/ToolpanelType';
import {ToolPanelHeaderRenderer} from './ToolPanelHeaderRenderer';

export interface ToolPanelHeaderProps {
  toolPanelType: ToolpanelType;
  gridApi: GridApi;
  mountElement: Element | null;
}

export function ToolPanelHeader({toolPanelType, gridApi, mountElement}: ToolPanelHeaderProps) {
  const handleClose = useCallback(() => gridApi.closeToolPanel(), [gridApi]);

  if (!mountElement) {
    return null;
  }

  return ReactDOM.createPortal(
    // There is style relying on this class
    // eslint-disable-next-line react/forbid-dom-props
    <div className="ag-panel-header">
      <ToolPanelHeaderRenderer closeToolpanel={handleClose} toolPanelType={toolPanelType} />
    </div>,
    mountElement
  );
}
