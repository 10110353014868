import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormSubmitHandler,
} from 'platform/components';
import {array, object} from 'yup';

import {head, sum} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  useGetServiceOrderJobItemWorkQuery,
  usePutWorkSplittingBulkMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId, yupNumber, yupString} from 'shared';

import {getRatioFromAmount} from '../../utils/getRatioFromAmount';
import {WorkSplittingMechanics} from './components/WorkSplittingMechanics';
import {WorkSplittingFormType} from './types/WorkSplittingFormType';

const TOTAL_RATIO = 100;

interface WorkSplittingFormProps extends RequiredTestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  items: {serviceJobId: string; serviceItemId: string}[];
  onSubmit: VoidFunction;
  onClose: VoidFunction;
}

export function WorkSplittingForm(props: WorkSplittingFormProps) {
  const {data, isLoading, isError} = useGetServiceOrderJobItemWorkQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      serviceItemId: head(props.items)?.serviceItemId ?? '',
      serviceJobId: head(props.items)?.serviceJobId ?? '',
    },
    {skip: props.items.length > 1}
  );

  const [putWorkSplittingBulk] = usePutWorkSplittingBulkMutation();

  const handleSubmit: FormSubmitHandler<WorkSplittingFormType> = async (data, setErrors) => {
    const ratioSum = sum(data.assignMechanics.map((mechanic) => mechanic?.ratio ?? 0));

    if (ratioSum !== TOTAL_RATIO) {
      return setErrors(
        data.assignMechanics.map((_, index) => ({
          name: `assignMechanics.${index}.ratio`,
          message: i18n.t('entity.order.labels.ratioSumError'),
        }))
      );
    }

    await putWorkSplittingBulk({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: {
        items: props.items.map((item) => ({
          itemId: item.serviceItemId,
          jobId: item.serviceJobId,
        })),
        assignMechanics: data.assignMechanics.map((mechanic, index) => ({
          id: mechanic?.id ?? '',
          costCenterId: mechanic?.costCenterId,
          isDefault: index === 0,
          ratio: isNotNil(mechanic.ratio) ? mechanic.ratio / 100 : 0,
        })),
      },
    })
      .unwrap()
      .then(() => {
        props.onSubmit();
        props.onClose();
      })
      .catch(handleApiError);
  };

  const defaultValues: WorkSplittingFormType = {
    assignMechanics: data?.assignMechanics?.filter(isNotNil).map((mechanic) => ({
      id: mechanic.id,
      costCenterId: mechanic.costCenterId,
      ratio: getRatioFromAmount(mechanic?.amount, data?.quantity?.amount),
    })) ?? [
      {
        id: null,
        costCenterId: null,
        ratio: null,
      },
    ],
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={34}>
      <Form<WorkSplittingFormType>
        schema={FromSchema}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <>
            <WorkSplittingMechanics
              control={control}
              formApi={formApi}
              serviceCaseId={props.serviceCaseId}
              serviceOrderId={props.serviceOrderId}
              data-testid={suffixTestId('mechanics', props)}
            />
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                variant="secondary"
                onClick={props.onClose}
                data-testid={suffixTestId('discardButton', props)}
              />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.save')}
                data-testid={suffixTestId('saveButton', props)}
              />
            </ButtonGroup>
          </>
        )}
      </Form>
    </DataStatus>
  );
}

const FromSchema = object({
  assignMechanics: array().of(
    object({
      id: yupString.required(),
      costCenterId: yupString,
      ratio: yupNumber.required().min(0).max(100),
    })
  ),
});
