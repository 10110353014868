import {ParsedUrlQuery} from 'querystring';

export const getValueFromURL = (
  name: string,
  routerQuery: ParsedUrlQuery,
  defaultResponse?: string | null
) => {
  const result = routerQuery?.[name] as string;
  const returnedResponse = typeof defaultResponse !== 'undefined' ? defaultResponse : 'N/A';

  return result && result !== 'undefined' ? result : returnedResponse;
};
