import {Show, Text} from 'platform/foundation';
import {CurrencyFormat} from 'platform/locale';

import {ReactElement} from 'react';

import {isNotNil} from 'ramda-adjunct';

import {Percentile} from '@omnetic-dms/teas';

import {CurrencyCodeType} from 'shared';

export const renderMeasurePercentage = (
  data: Percentile,
  currency: CurrencyCodeType
): ReactElement => (
  <table>
    {data &&
      Object.values(data)?.map((item, index) => (
        <tr key={item}>
          <td align="right">
            <Text color="white" size="xSmall">
              {index * 10}%
            </Text>
          </td>
          <td> -</td>
          <td>
            <Text color="white" size="xSmall">
              <Show when={isNotNil(item)}>
                <CurrencyFormat currency={currency} number={item ?? 0} />
              </Show>
            </Text>
          </td>
        </tr>
      ))}
  </table>
);
