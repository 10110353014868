import {
  Attributes,
  Button,
  Card,
  DataStatus,
  FormControl,
  HelperText,
  isCurrency,
  openDialog,
} from 'platform/components';
import {Box, HStack, Heading, Hide, Show, Space, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter} from 'platform/locale';

import {UseFormReturn, useFieldArray} from 'react-hook-form';

import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {PostBillingInformationV2ApiArg} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {SettingsSection} from '../../../components/SettingsSection/SettingsSection';
import {BankAccountForm} from './BankAccountForm';

interface DeputyPersonListProps {
  control: FormControl<PostBillingInformationV2ApiArg>;
  formApi: UseFormReturn<PostBillingInformationV2ApiArg>;
}

export function BankAccountList(props: DeputyPersonListProps) {
  const formatCurrencySymbol = useCurrencySymbolFormatter();

  const {fields, prepend, remove, update} = useFieldArray({
    name: 'bankAccounts',
    control: props.control,
  });

  const bankAccountsErrors = props.formApi.formState.errors.bankAccounts;

  const areBankAccountsEmpty = isNilOrEmpty(fields);

  const handleAddBankAccount = () => {
    openDialog(<BankAccountForm handleSubmit={prepend} />, {
      scrollBehavior: 'outside',
      title: i18n.t('general.customer.addBankAccount'),
    });
  };

  const handleUpdateBankAccount = (index: number) => {
    props.formApi.clearErrors(`bankAccounts.${index}`);
    openDialog(
      <BankAccountForm handleSubmit={(val) => update(index, val)} defaultValues={fields[index]} />,
      {
        scrollBehavior: 'outside',
        title: i18n.t('general.customer.addBankAccount'),
      }
    );
  };

  const bankAccountsErrorMessage = props.formApi.formState.errors.bankAccounts?.message;
  const isBankAccountsError = isNotNil(bankAccountsErrorMessage);

  return (
    <>
      <HStack justify="space-between" align="center">
        <Heading size={4}>{i18n.t('general.labels.bankAccount')}</Heading>
        <Hide when={areBankAccountsEmpty && !isBankAccountsError}>
          <Button
            title={i18n.t('general.customer.addBankAccount')}
            leftIcon="content/add_circle"
            variant="link"
            onClick={handleAddBankAccount}
          />
        </Hide>
      </HStack>
      <Space vertical={5} />
      <SettingsSection>
        <DataStatus
          isEmpty={areBankAccountsEmpty}
          spacing={5}
          errorMessage={bankAccountsErrorMessage}
          isError={isBankAccountsError}
          action={{
            title: i18n.t('general.customer.addBankAccount'),
            onClick: handleAddBankAccount,
            leftIcon: 'content/add_circle',
          }}
        >
          <VStack spacing={2}>
            {fields.map((field, index) => (
              <Box key={field.id}>
                <Card
                  title={field.accountName}
                  isClosedByDefault
                  isExpandable
                  variant="inlineGrey"
                  actions={[
                    {
                      type: 'dropdown-iconButton',
                      icon: 'navigation/more_vert',
                      menuItems: [
                        {
                          label: i18n.t('general.actions.edit'),
                          onClick: () => handleUpdateBankAccount(index),
                          leftIcon: 'image/edit',
                        },
                        {
                          label: i18n.t('general.actions.delete'),
                          onClick: () => remove(index),
                          leftIcon: 'action/delete',
                          severity: 'danger',
                        },
                      ],
                    },
                  ]}
                >
                  <Attributes
                    size="quarter"
                    rows={[
                      {
                        label: i18n.t('entity.bank.labels.accountNumber'),
                        value: field.accountNumber,
                      },
                      {
                        label: i18n.t('entity.bank.labels.name'),
                        value: field.bankName,
                      },
                      {
                        label: i18n.t('entity.bank.labels.iban'),
                        value: field.iban,
                      },
                      {
                        label: i18n.t('entity.bank.labels.swift'),
                        value: field.swift,
                      },
                      {
                        label: i18n.t('entity.bank.labels.currency'),
                        value: isCurrency(field.currency)
                          ? formatCurrencySymbol(field.currency)
                          : null,
                      },
                    ]}
                  />
                </Card>
                <Show when={bankAccountsErrors?.[index]}>
                  <HelperText errorMessage={bankAccountsErrors?.[index]?.accountNumber?.message} />
                </Show>
              </Box>
            ))}
          </VStack>
        </DataStatus>
      </SettingsSection>
    </>
  );
}
