import {RequestAuditDataFieldsBody} from '../types/RequestAuditDataFieldsBody';

export const getUpdateRequestBodyFromFormData = (
  formData: Record<string, unknown>
): RequestAuditDataFieldsBody[] => {
  const requestBody: RequestAuditDataFieldsBody[] = [];

  Object.entries(formData).forEach((formValue) => {
    const {0: fieldName, 1: fieldValue} = formValue;
    const {0: categoryId, 1: paramDefinitionId} = fieldName.split('_');

    const value =
      Array.isArray(fieldValue) || typeof fieldValue === 'object'
        ? JSON.stringify(fieldValue)
        : fieldValue
          ? String(fieldValue)
          : '';

    requestBody.push({
      categoryId,
      paramDefinitionId,
      value,
      updatedAt: new Date().toISOString(),
    });
  });

  return requestBody;
};
