import {match, Pattern} from 'ts-pattern';

import {always} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  GetOfferedVehiclesListApiResponse,
  InterestResponseBody,
  MinisaleResponseBody,
} from '@omnetic-dms/api';

import {Nullish} from 'shared';

interface IsCreateBusinessCaseDisabledParams {
  type: InterestResponseBody['type'];
  miniSale?: MinisaleResponseBody | Nullish;
  offeredVehiclesList?: GetOfferedVehiclesListApiResponse | Nullish;
}

export const isCreateBusinessCaseDisabled = (
  params: IsCreateBusinessCaseDisabledParams
): boolean => {
  const hasInvalidMakeAndModel =
    isNilOrEmpty(params.miniSale?.vehicleData?.make) ||
    isNilOrEmpty(params.miniSale?.vehicleData?.modelFamily);

  return match([
    params.type,
    isNilOrEmpty(params.offeredVehiclesList),
    isNilOrEmpty(params.miniSale),
    hasInvalidMakeAndModel,
  ])
    .with(['BUYING', Pattern.any, false, false], always(false))
    .with(['SELLING', false, Pattern.any, Pattern.any], always(false))
    .with(['SWAP', false, false, false], always(false))
    .otherwise(always(true));
};
