import {format} from 'date-fns';
import {convertStringLocaleToDateFns, useLocale} from 'platform/locale';

import {Nullish} from 'shared';

export function useGetManufacturedOn(date: string | Nullish) {
  const {language} = useLocale();

  if (!date) {
    return '';
  }

  return format(date, 'LLLL yyyy', {
    locale: convertStringLocaleToDateFns(language),
  });
}
