import styled from 'styled-components';

import {FC, ReactElement} from 'react';

import {TypographyCard} from '../TypographyCard/TypographyCard';
import {RowRenderer} from './RowRenderer';
import {RowType} from './types/RowType';

const ContentWrapper = styled.div`
  > * {
    margin-bottom: 4px;
  }
`;

export type InfoCardProps = {
  loading?: boolean;
  title?: string | ReactElement;
  headerContent?: ReactElement;
  footerContent?: ReactElement;
  rows: RowType[];
};

const GenerateInfoCardRows: FC<{rows: RowType[]}> = ({rows}) => (
  <>
    {rows.map((row, index) => (
      <RowRenderer key={row.key} row={row} index={index} />
    ))}
  </>
);

export const InfoCard: FC<InfoCardProps> = ({
  title,
  headerContent,
  footerContent,
  rows,
  loading,
  ...rest
}) => (
  <TypographyCard
    {...rest}
    title={title}
    headerContent={headerContent}
    footerContent={footerContent}
  >
    <ContentWrapper data-testid="info-card-content-wrapper">
      <GenerateInfoCardRows rows={rows} />
    </ContentWrapper>
  </TypographyCard>
);
