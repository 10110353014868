import {keys} from 'ramda';

const HIDDEN_TEXTAREA_STYLE = {
  'min-height': '0',
  'max-height': 'none',
  height: '0',
  visibility: 'hidden',
  overflow: 'hidden',
  position: 'absolute',
  'z-index': '-1000',
  top: '0',
  right: '0',
} as const;

/**
 * Applies a set of predefined styles to an HTML element to ensure it is hidden.
 * These styles are marked as 'important' to increase their priority.
 *
 * @param {HTMLElement} node - The HTML element to which the hidden styles will be applied.
 */
export const forceHiddenStyles = (node: HTMLElement) => {
  keys(HIDDEN_TEXTAREA_STYLE).forEach((key) => {
    node.style.setProperty(
      key,
      HIDDEN_TEXTAREA_STYLE[key as keyof typeof HIDDEN_TEXTAREA_STYLE],
      'important'
    );
  });
};
