import type {Assignment} from '../types/Assignment';
import type {CancelablePromise} from '../types/CancelablePromise';
import type {CreateAssignmentRequestBody} from '../types/CreateAssignmentRequestBody';
import {request as __request} from '../utils/request';

export class CreateAssignmentService {
  /**
   * @returns Assignment
   * @throws ApiError
   */
  public static createAssignment({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: CreateAssignmentRequestBody;
  }): CancelablePromise<Assignment> {
    return __request({
      method: 'POST',
      url: '/dms/v1/assignment',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
