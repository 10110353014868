import {useEffect} from 'react';

import {tenantApi} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  getCebiaAutotraceAndReport,
  getCebiaEquipmentCredentials,
  useThunkDispatch,
} from '@omnetic-dms/teas';

import {useBoolean} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {VehicleServicesList} from './components/VehicleServicesList';

export function VehicleServices() {
  const dispatch = useThunkDispatch();

  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  useEffect(() => {
    startLoading();
    Promise.all([
      dispatch(getCebiaEquipmentCredentials()),
      dispatch(getCebiaAutotraceAndReport()),
      dispatch(tenantApi.endpoints.getCebiaRokvyConfig.initiate()),
    ]).finally(() => {
      stopLoading();
    });
  }, [dispatch, startLoading, stopLoading]);

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{title: i18n.t('page.integrations.labels.vehicleServices')}}
      data-testid="integrations-vehicle-settings"
    >
      <VehicleServicesList />
    </SettingsTemplate>
  );
}
