import {Box, Center, HStack, Icon, Text} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {Children, ComponentType, FC} from 'react';
import {components as reactSelectComponents, GroupBase, SelectComponentsConfig} from 'react-select';

import {noop, Nullish, suffixTestId} from 'shared';

import {OptionTypeBase} from '../../../types/OptionTypeBase';
import {Checkbox} from '../../Checkbox/Checkbox';
import {IconButton} from '../../IconButton/IconButton';
import {useChoice} from '../hooks/choiceContext';

export const selectComponents = <ValueType, IsMulti extends boolean>(): SelectComponentsConfig<
  OptionTypeBase<ValueType | Nullish>,
  IsMulti,
  GroupBase<OptionTypeBase<ValueType | Nullish>>
> => ({
  LoadingMessage: function LoadingMessage({children, ...props}) {
    const context = useChoice();

    return (
      <reactSelectComponents.LoadingMessage {...props}>
        <span data-testid={suffixTestId('choiceLoadingMessage', context)}>{children}</span>
      </reactSelectComponents.LoadingMessage>
    );
  },
  Control: function Control({children, innerRef, innerProps, isDisabled}) {
    const context = useChoice();
    const {css: innerCss, ...otherProps} = innerProps;
    return (
      <div
        className="eag--choice__control"
        data-testid={suffixTestId('choiceControl', context)}
        ref={innerRef}
        css={css`
          display: flex;
          max-height: ${({theme}) => theme.getSize(8)};
          background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
          color: ${({theme}) => theme.colors.text.primary};
          font-size: ${({theme}) => theme.fontSizes.text.small};
          line-height: ${({theme}) => theme.lineHeights.text.small};
          font-weight: ${({theme}) => theme.fontWeights.text.default};
          padding: 5px 8px;
          box-sizing: border-box;
          padding-inline: 8px;
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            background-color: ${({theme}) => theme.colors.palettes.yellow[20][100]};
          };
          &[data-focused], &:focus {
            box-shadow: none !important;
          };
          .eag--choice__control {
            border: 1px solid transparent;
            background: none;
            cursor: pointer;
            box-shadow: none;
            border-color: transparent;
            padding: 0;
            &:hover {
              box-shadow: none;
              border-color: transparent;
            };
            &:focus {
              box-shadow: none;
              border-color: transparent;
            };
            &:active {
              box-shadow: none;
              border-color: transparent;
            };
            &:disabled {
              border-color: transparent;
            };
            .eag--choice__value-container {
              flex-direction: row;
              padding-inline-end: 0;
              > * {
                position: relative;
                max-width: none;
                min-width: min-content;
                margin: 0;
                margin-top: 2px;
                margin-bottom: 2px;
                top: auto;
                transform: none;
              };
              > input {
                position: absolute;
                cursor: pointer;
              }
            };
          };
          &::placeholder {
            color: ${({theme}) => theme.colors.text.tertiary};
          };
          &:hover {
           border-color: ${({theme}) => theme.colors.severity.informational};
            :disabled {
              border-color: ${({theme}) => theme.colors.palettes.neutral[70][40]};
            }
          };
          &:focus {
            border-color: ${({theme}) => theme.colors.severity.informational};
            box-shadow: ${({theme}) => theme.shadows.active};
            &:disabled {
              border-color: ${({theme}) => theme.colors.palettes.neutral[70][40]};
              box-shadow: none;
            }
          };
          :disabled {
            border-color: ${({theme}) => theme.colors.palettes.neutral[70][40]};
            background-color: ${({theme}) => theme.colors.palettes.white[10][40]};
            pointer-events: auto;
            cursor: not-allowed;
            box-shadow: none;
            &:focus {
              border-color: ${({theme}) => theme.colors.palettes.neutral[70][40]};
            };
          }
          max-height: none;
          min-height: 0;
          border: 1px solid;
          border-radius: ${({theme}) => theme.radii.small};
          border-color: ${({theme}) => theme.colors.palettes.neutral[70][100]};
          padding-left: 8px;
          .eag--choice__value-container {
            gap: 4px;
          };
          .eag--choice__indicators-container {

            height: 18px;
            align-self: center;
          },
        .eag--choice__menu {
          max-width: 560px;
          min-width: 216px;
        },
        height: auto;
          justify-content: space-between;
        `}
        {...otherProps}
        {...(isDisabled && {disabled: true})}
      >
        {children}
      </div>
    );
  },
  Placeholder: function Placeholder({children, ...props}) {
    const context = useChoice();

    return (
      <reactSelectComponents.Placeholder
        {...props}
        data-testid={suffixTestId('choicePlaceholder', context)}
      >
        <span
          css={css`
            color: ${({theme}) => theme.colors.text.tertiary};
          `}
          className={props.className}
          data-testid={suffixTestId('choicePlaceholderInner', context)}
        >
          {children}
        </span>
      </reactSelectComponents.Placeholder>
    );
  },
  NoOptionsMessage: ({children, ...props}) => {
    const context = useChoice();

    return (
      <reactSelectComponents.NoOptionsMessage
        {...props}
        data-testid={suffixTestId('choiceNoOptions', context)}
      >
        <span
          css={css`
            color: ${({theme}) => theme.colors.text.tertiary};
          `}
          className={props.className}
          data-testid={suffixTestId('choiceNoOptionsInner', context)}
        >
          {children}
        </span>
      </reactSelectComponents.NoOptionsMessage>
    );
  },
  ValueContainer: function ValueContainer({children, ...props}) {
    const context = useChoice();

    return (
      <reactSelectComponents.ValueContainer
        {...props}
        className={`${props.className} eag--choice__value-container`}
        data-testid={suffixTestId('choiceValueContainer', context)}
      >
        {children}
      </reactSelectComponents.ValueContainer>
    );
  },
  MultiValueContainer: function MultiValueContainer({children, innerProps}) {
    const context = useChoice();
    const theme = useTheme();

    return (
      <Box
        minHeight={4}
        paddingLeft={1}
        maxWidth="100%"
        overflow="hidden"
        borderRadius="xSmall"
        backgroundColor={theme.components.Choice.backgroundColor}
        data-testid={suffixTestId('choiceMultiValueContainer', context)}
      >
        <HStack align="center" spacing={1}>
          {children}
        </HStack>
      </Box>
    );
  },
  MultiValueLabel: function MultiValueLabel(props) {
    const context = useChoice();

    return (
      <Text
        data-testid={suffixTestId('choiceMultiValueLabel', context)}
        size="xSmall"
        color="white"
        overflowWrap="anywhere"
      >
        {props.children}
      </Text>
    );
  },
  MultiValueRemove: function MultiValueRemove(props) {
    const context = useChoice();
    return (
      <Center height={3} width={4}>
        <Icon
          size={3}
          color="general.white"
          value="navigation/close"
          onClick={props.innerProps?.onClick}
          data-testid={suffixTestId('choiceMultiValueRemove', context)}
        />
      </Center>
    );
  },
  IndicatorsContainer: function IndicatorsContainer({children, ...props}) {
    const context = useChoice();
    const childArray = Children.toArray(children);

    return (
      <reactSelectComponents.IndicatorsContainer
        {...props}
        data-testid={suffixTestId('choiceIndicatorContainer', context)}
        className={`eag--choice__indicators-container ${props.className}`}
      >
        {childArray}
      </reactSelectComponents.IndicatorsContainer>
    );
  },
  IndicatorSeparator: function IndicatorSeparator() {
    return null;
  },
  ClearIndicator: function ClearIndicator({innerProps}) {
    const context = useChoice();

    return (
      <div
        {...innerProps}
        data-testid={suffixTestId('choiceClearIndicatorClickableWrapper', context)}
      >
        <IconButton
          size="small"
          icon="navigation/close"
          data-testid={suffixTestId('choiceClearIndicator', context)}
        />
      </div>
    );
  },
  DropdownIndicator: function DropdownIndicator({innerProps, isDisabled}) {
    const context = useChoice();

    return (
      <div
        {...innerProps}
        data-testid={suffixTestId('choiceDropdownIndicatorClickableWrapper', context)}
      >
        <IconButton
          size="small"
          icon="navigation/expand_more"
          isDisabled={isDisabled}
          data-testid={suffixTestId('choiceDropdownIndicator', context)}
        />
      </div>
    );
  },
  Menu: function Menu({children, ...props}) {
    const context = useChoice();
    return (
      <reactSelectComponents.Menu
        {...props}
        data-testid={suffixTestId('choiceMenu', context)}
        className={`eag--choice__menu ${props.className}`}
      >
        {children}
      </reactSelectComponents.Menu>
    );
  },
  MenuList: function MenuList({innerRef, children, maxHeight}) {
    const context = useChoice();

    return (
      <div
        css={css`
          background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
          box-shadow: ${({theme}) => theme.shadows.elevation_3};
          color: inherit;
          min-width: 216px;
          padding: 0;
          border-radius: ${({theme}) => theme.radii.medium};
          border-width: 1px;
          padding: ${({theme}) => theme.getSize(2)};
          max-height: ${maxHeight}px;
          overflow-y: auto;
          width: 100%;
          z-index: 999;
        `}
        ref={innerRef}
        data-testid={suffixTestId('choiceMenuList', context)}
      >
        {children}
      </div>
    );
  },
  GroupHeading: function GroupHeading({children}) {
    const context = useChoice();
    return (
      <div
        css={css`
          margin: 2px 4px;
          font-weight: ${({theme}) => theme.fontWeights.regular};
          font-size: ${({theme}) => theme.fontSizes.text.xSmall};
          line-height: ${({theme}) => theme.lineHeights.text.xSmall};
          letter-spacing: 0;
          color: ${({theme}) => theme.colors.text.secondary};
        `}
        data-testid={suffixTestId('choiceGroupHeading', context)}
      >
        {children}
      </div>
    );
  },
  Option: function Option({innerRef, innerProps, children, isFocused, isDisabled, isSelected}) {
    const context = useChoice();
    const {hasOptionCheckbox} = context;
    const {css: innerCss, ...otherProps} = innerProps;

    return (
      <div
        css={css`
          cursor: pointer;
          padding: ${({theme}) => theme.getSize(2)};;
          background-color: ${isFocused || isSelected ? ({theme}) => theme.colors.palettes.blue[10][100] : ({theme}) => theme.colors.palettes.white[10][100]};
          &:focus {
            background-color: ${({theme}) => theme.colors.palettes.blue[10][100]};
            color: ${({theme}) => theme.colors.palettes.blue[80][100]};
          }
          &:active {
            background-color: ${({theme}) => theme.colors.palettes.blue[10][100]};
            color: ${({theme}) => theme.colors.palettes.blue[80][100]};
          }
          &[aria-disabled="true"] {
            opacity: 0.4;
            cursor: not-allowed;
            background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
            color: ${({theme}) => theme.colors.palettes.neutral[900][40]};
            &:hover {
              background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
              color: ${({theme}) => theme.colors.palettes.neutral[900][40]};
            }
            &:focus {
              background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
              color: ${({theme}) => theme.colors.palettes.neutral[80][100]};
            }
            &:active {
              background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
              color: ${({theme}) => theme.colors.palettes.neutral[80][100]};
            }
          }
          font-size: ${({theme}) => theme.fontSizes.text.small};
          line-height: ${({theme}) => theme.lineHeights.text.small};
          font-weight: ${({theme}) => theme.fontWeights.regular};
          border-radius: ${({theme}) => theme.radii.small};
          letter-spacing: 0;
          color: ${({theme}) => theme.colors.text.primary};
          &:hover {
            background-color: ${({theme}) => theme.colors.palettes.blue[10][100]};
            color: ${({theme}) => theme.colors.palettes.blue[80][100]};
          }
          .eag--choice__option {
            cursor: pointer;
            &:disabled {
              cursor: not-allowed;
            }
            ,
            .option-create-new {
              color: ${({theme}) => theme.colors.general.accent};
            }
          }
          svg {
            width: ${({theme}) => theme.getSize(4)};
            height: ${({theme}) => theme.getSize(4)};
            &[data-iconkey^="flags/"] {
              width: ${({theme}) => theme.getSize(7)};
              height: ${({theme}) => theme.getSize(5)};
            },
          }
          width: 100%;
          text-align: left;
        `}
        ref={innerRef}
        {...otherProps}
        {...(isDisabled && {isDisabled: true})}
        data-testid={suffixTestId('choiceOption', context)}
        className="eag--choice__option"
        aria-disabled={isDisabled}
        data-selected={isSelected ? 'true' : 'false'}
        data-focused={isFocused ? 'true' : 'false'}
      >
        {/* Checkbox native onFocus and onBlur events messing up react-select value selection [T20-22340] */}
        <div
          css={css`
            pointer-events: none;
          `}
        >
          {hasOptionCheckbox ? (
            <HStack spacing={3}>
              <Checkbox
                onChange={noop}
                value={isSelected}
                isDisabled={isDisabled}
                data-testid={suffixTestId('option-checkbox', context)}
              />
              {/* @ts-ignore Children is typed as node by react-select - cant change that */}
              <Text size="small">{children}</Text>
            </HStack>
          ) : (
            children
          )}
        </div>
      </div>
    );
  },
  Input: function Input(props) {
    const context = useChoice();
    return (
      <reactSelectComponents.Input
        data-testid={suffixTestId('choiceInput', context)}
        data-name={context['data-name']}
        {...props}
        className={`${props.className} choice-component-input`}
      />
    );
  },
  SelectContainer: function SelectContainer(props) {
    const context = useChoice();
    return (
      <reactSelectComponents.SelectContainer
        data-testid={suffixTestId('choiceComponentContainer', context)}
        {...props}
        className={props.className}
      />
    );
  },
  SingleValue: wrapWithClassName(reactSelectComponents.SingleValue, 'eag--choice__single-value'),
  MultiValue: wrapWithClassName(reactSelectComponents.MultiValue, 'eag--choice__multi-value'),
});

function wrapWithClassName<Props extends {className?: string}>(
  Base: ComponentType<Props>,
  className: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ComponentType<any> {
  const Wrapped: FC<Props> = (props) => (
    <Base {...props} className={`${props.className} ${className}`} />
  );

  return Wrapped;
}
