import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {mapObjIndexed, mergeDeepRight} from 'ramda';

import {
  GetCustomerRequestedPriceApiArg,
  PutPurchaseVehiclePricingCentralizedApiArg,
  selectTenant,
  useGetBusinessCaseQuery,
  useGetCustomerRequestedPriceQuery,
  usePutPurchaseVehiclePricingCentralizedMutation,
  VatTypeEnum,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';
import {DEFAULT_CURRENCY} from '@omnetic-dms/teas';

import {useRequiredParams} from 'shared';

import {addCurrencyToAmount, getPricingFormScheme} from './utils/formGetters';
import {VatDeductibleForm} from './VatDeductibleForm';
import {VatNotDeductibleForm} from './VatNotDeductibleForm';

interface PricingFormProps {
  toggleDetail: () => void;
}

export function PricingForm(props: PricingFormProps) {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const purchaseVehicle =
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE'
      ? (businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0] ?? null)
      : (businessCase?.offers?.[0]?.purchaseVehicles?.[0] ?? null);
  const {data: selectedTenant} = useSelector(selectTenant);

  const currency = selectedTenant?.currency ?? DEFAULT_CURRENCY;

  const [setCentralizedPricing] = usePutPurchaseVehiclePricingCentralizedMutation();

  const requestedPriceArgs: GetCustomerRequestedPriceApiArg = {
    businessCaseId,
    offerId: businessCase?.offers?.[0]?.id ?? '',
    offerPurchaseVehicleId: purchaseVehicle?.id ?? '',
  };

  const {
    data: requestedPrice,
    isError,
    isLoading,
  } = useGetCustomerRequestedPriceQuery(requestedPriceArgs);

  const handleSubmit: FormSubmitHandler<PutPurchaseVehiclePricingCentralizedApiArg> = async (
    data
  ) => {
    if (!requestedPrice) {
      showNotification.error();
      return;
    }

    const partialSubmitData: PutPurchaseVehiclePricingCentralizedApiArg = {
      ...requestedPriceArgs,
      reason: data.reason,
      vatDeductible: requestedPrice.isVatDeductible,
      vatType: requestedPrice.isVatDeductible ? requestedPrice.vatRate.vatType : null,
      maxBuyingPrice: {
        priceNotDeductible: null,
        priceWithoutVat: null,
        priceWithVat: null,
        vatDirection: null,
      },
      sellingPrice: {
        priceNotDeductible: null,
        priceWithoutVat: null,
        priceWithVat: null,
        vatDirection: null,
      },
    };

    const mergedData: PutPurchaseVehiclePricingCentralizedApiArg = mergeDeepRight(
      partialSubmitData,
      data
    );
    const submitBody = mapObjIndexed((val) => addCurrencyToAmount(val, currency), mergedData);

    await setCentralizedPricing(submitBody as PutPurchaseVehiclePricingCentralizedApiArg)
      .unwrap()
      .then(() => {
        showNotification.success();
        props.toggleDetail();
      })
      .catch(handleApiError);
  };

  const defaultValues: Partial<PutPurchaseVehiclePricingCentralizedApiArg> = {
    reason: purchaseVehicle?.pricing.reason ?? undefined,
    maxBuyingPrice: purchaseVehicle?.pricing.maxBuyingPrice,
    sellingPrice: purchaseVehicle?.pricing.sellingPrice,
  };

  const pricingFormProps = {
    vatType: requestedPrice?.vatRate?.vatType ?? VatTypeEnum.S,
    vehicleId: purchaseVehicle?.vehicleId ?? '',
  };

  return (
    <DataStatus isError={isError} isLoading={isLoading}>
      <Form<PutPurchaseVehiclePricingCentralizedApiArg>
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        schema={getPricingFormScheme(requestedPrice?.isVatDeductible)}
      >
        {(control, formApi) => {
          const formControls = {
            control,
            formApi,
          };
          return (
            <VStack spacing={4}>
              {requestedPrice?.isVatDeductible ? (
                <VatDeductibleForm {...pricingFormProps} {...formControls} />
              ) : (
                <VatNotDeductibleForm {...pricingFormProps} {...formControls} />
              )}
              <Separator spacing={0} />
              <FormField
                name="reason"
                type="textarea"
                minRows={2}
                control={control}
                label={i18n.t('general.labels.reason')}
              />
              <ButtonGroup align="right">
                <Button
                  onClick={props.toggleDetail}
                  title={i18n.t('general.labels.dismiss')}
                  variant="secondary"
                />
                <FormButton
                  title={i18n.t('general.actions.save')}
                  control={control}
                  type="submit"
                />
              </ButtonGroup>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}
