import {flip, offset, shift, useFloating} from '@floating-ui/react';
import {Show, Text} from 'platform/foundation';
import styled, {useTheme} from 'styled-components';

import {ReactNode, useEffect, useRef} from 'react';

import {isNil} from 'ramda';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {Placement} from '../../types/Placement';
import {Portal} from '../Portal/components/Portal';
import {PortalManager} from '../Portal/components/PortalManager';

type TooltipPlacement = Exclude<Placement, 'auto'>;

const OFFSET = 10;

export interface TooltipProps extends TestIdProps {
  children: ReactNode;
  label?: ReactNode | string;
  description?: ReactNode;
  placement?: TooltipPlacement;
  overflowWrap?: 'anywhere';
  isOpen?: boolean;
  isDisabled?: boolean;
  openDelay?: number;
  closeDelay?: number;
  hasAutoWidth?: boolean;
  offset?: number;
  onOpen?(): void;
  onClose?(): void;
}

export function Tooltip(props: TooltipProps) {
  const theme = useTheme();

  const [isOpen, setOpen, setClose] = useBoolean(props.isOpen);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const {refs, floatingStyles} = useFloating({
    placement: props.placement,
    middleware: [offset(props.offset ?? OFFSET), shift(), flip()],
    strategy: props.hasAutoWidth ? 'fixed' : 'absolute',
  });

  useEffect(
    () => () => {
      if (isNil(timerRef.current)) {
        return;
      }

      clearTimeout(timerRef.current);
    },
    []
  );

  const handleOpen = () => {
    const shouldOpen = props.isOpen ?? true;

    if (props.isDisabled || !shouldOpen) {
      return;
    }

    if (isNil(props.openDelay)) {
      setOpen();
      props.onOpen?.();
      return;
    }

    timerRef.current = setTimeout(() => {
      setOpen();
      props.onOpen?.();
    }, props.openDelay);
  };

  const handleClose = () => {
    const shouldClose = props.isOpen !== true;

    if (!shouldClose) {
      return;
    }

    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (isNil(props.closeDelay)) {
      setClose();
      props.onClose?.();
      return;
    }

    timerRef.current = setTimeout(() => {
      setClose();
      props.onClose?.();
    }, props.closeDelay);
  };

  if (isNil(props.label) && isNil(props.description)) {
    return <>{props.children}</>;
  }

  return (
    <>
      <div ref={refs.setReference} onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        {props.children}
      </div>
      {isOpen && (
        <PortalManager zIndex={theme.zIndices.TOOLTIP}>
          <Portal>
            <span ref={refs.setFloating} style={floatingStyles}>
              <StyledContentWrapper $hasAutoWidth={props.hasAutoWidth}>
                <Show when={props.label}>
                  <Text size="xSmall" alternative color="white" overflowWrap={props.overflowWrap}>
                    {props.label}
                  </Text>
                </Show>
                <Show when={props.description}>
                  <Text
                    size="xSmall"
                    color="white"
                    data-testid={suffixTestId(`tooltipDescription`, props)}
                    overflowWrap={props.overflowWrap}
                  >
                    {props.description}
                  </Text>
                </Show>
              </StyledContentWrapper>
            </span>
          </Portal>
        </PortalManager>
      )}
    </>
  );
}

type StyledContentWrapperProps = {
  $hasAutoWidth?: TooltipProps['hasAutoWidth'];
};

const StyledContentWrapper = styled.div<StyledContentWrapperProps>`
  background-color: ${(props) => props.theme.colors.palettes.neutral[900][100]};
  border-radius: ${(props) => props.theme.radii.small};
  max-width: ${(props) => (props.$hasAutoWidth ? '50vw' : props.theme.getSize(58))};
  padding: 4px 8px;
`;
