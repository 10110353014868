import {$insertDataTransferForPlainText} from '@lexical/clipboard';
import '@lexical/plain-text';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getSelection, COMMAND_PRIORITY_CRITICAL, PASTE_COMMAND} from 'lexical';

import {useEffect} from 'react';

export const RawPastePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND,
      (event) => {
        event.preventDefault();
        const selection = $getSelection();
        if (event.clipboardData && selection) {
          $insertDataTransferForPlainText(event.clipboardData, selection);
        }
        return true;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor]);

  return null;
};
