import {
  Integer,
  ThemeIconKey,
  Box,
  Center,
  Hide,
  HStack,
  Icon,
  ThemeRadiusPath,
} from 'platform/foundation';

import {suffixTestId} from 'shared';

import {FlagLabel} from '../Flag/components/FlagLabel';
import {BaseFlagProps} from '../Flag/types';
import {getColorsFromScheme, getFlagElementSizes} from '../Flag/utils';
import {TagHoverBackgroundColor} from '../Tag/Tag';

interface DropdownControlProps extends BaseFlagProps {
  isOpen: boolean;
  isDisabled?: boolean;
}

export function DropdownControl(props: DropdownControlProps) {
  const {height, textSize, labelHorizontalPadding} = getFlagElementSizes(props.size);
  const iconSize: Integer = props.size === 'small' ? 3 : 4;
  const iconWrapperWidth = height;
  const borderRadius: ThemeRadiusPath = props.size === 'large' ? 'small' : 'xSmall';
  const {backgroundColor, color, hoverColor} = getColorsFromScheme(
    props.colorScheme,
    props.isSubtle
  );

  const iconPath: ThemeIconKey = props.isOpen ? 'navigation/expand_less' : 'navigation/expand_more';

  return (
    <Box
      borderRadius={borderRadius}
      height={height}
      backgroundColor={backgroundColor}
      overflow="hidden"
      data-testid={suffixTestId('DropdownControl', props)}
    >
      <TagHoverBackgroundColor
        $hoverColorPath={props.isDisabled ? 'general.transparent' : hoverColor}
      >
        <HStack justify="space-between" align="center" height="100%">
          <Box
            paddingHorizontal={labelHorizontalPadding}
            data-testid={suffixTestId('DropdownControl-label', props)}
          >
            <FlagLabel label={props.label} size={textSize} color={color} />
          </Box>

          <Hide when={props.isDisabled}>
            <Center height="100%" width={iconWrapperWidth}>
              <Icon
                value={iconPath}
                size={iconSize}
                color={color}
                data-testid={suffixTestId('DropdownControl-icon', props)}
              />
            </Center>
          </Hide>
        </HStack>
      </TagHoverBackgroundColor>
    </Box>
  );
}
