import {useContext, createContext} from 'react';

export interface InlineEditingContextType<I, O> {
  updateMethod: (
    data: I,
    // TODO: remove after float | int allowed on the backend
    storeData: I,
    callback?: () => void
  ) => Promise<O>;
  editedValue?: O;
}

type InlineEditingContextGlobal = InlineEditingContextType<unknown, unknown>;
/**
 * @deprecated - use platform instead
 */
export type InlineEditingContextGlobalUpdateMethod = InlineEditingContextGlobal['updateMethod'];

export const inlineEditingContext = createContext<InlineEditingContextGlobal>({
  updateMethod: () => Promise.resolve(null),
});
export const InlineEditingProvider = inlineEditingContext.Provider;

export const useInlineEditing = <I, O = I>(): InlineEditingContextType<I, O> =>
  useContext(inlineEditingContext) as InlineEditingContextType<I, O>;
