import {DeepReadonly} from 'utility-types';

import {useCallback} from 'react';

import {ColorCircle, ColorOptionType, KeyNameEntity} from '@omnetic-dms/teas';

import {TestIdProps} from 'shared';

import {BodyColorEnum} from './enums/BodyColorEnum';
import {ColorPickerContainer} from './utils/styles';

type ColorPickerProps = DeepReadonly<{
  name?: string;
  colors?: ColorOptionType[];
  selectedColors?: ColorOptionType[];
  onChange?: (colors: ColorOptionType[]) => void;
}> &
  TestIdProps;

export function ColorPicker({
  name = 'colorPicker',
  selectedColors = [],
  onChange,
  colors = [],
}: ColorPickerProps) {
  const handleChange = useCallback(
    (color: KeyNameEntity) => {
      const _selectedColors = [...selectedColors];
      const existingColorIndex = _selectedColors.findIndex(
        (c: KeyNameEntity) => c.key === color.key
      );

      if (existingColorIndex > -1) {
        _selectedColors.splice(existingColorIndex, 1);
      } else {
        _selectedColors.push(color);
      }

      onChange?.(_selectedColors);
    },
    [selectedColors, onChange]
  );

  return (
    <ColorPickerContainer data-testid={`color-picker-${name}-wrapper`}>
      {colors.map((c) => {
        const isSelected = selectedColors?.findIndex((_color) => _color.key === c.key) > -1;

        // colorCode can end with a semicolon that needs to be removed
        return (
          <ColorCircle
            data-testid={`color-picker-${name}-value-${c.key}`}
            key={c.key}
            color={{
              value: c.key || '',
              label: c.name || '',
              shortLabel: c.nameShort || '',
              properties: [{value: c.colorCode || '', name: 'color_code'}],
            }}
            isSelected={isSelected}
            needBorder={c.key === BodyColorEnum.COLOR_WHITE}
            onClick={() => handleChange(c)}
          />
        );
      })}
    </ColorPickerContainer>
  );
}
