import {Getter} from '../types/Getter';

/**
 *  Replace all of {fieldName} with fieldValue
 *  api.cars/model/{make} => api.cars/model/audi
 */
export const prepareUrl = (url: string, getter: Getter) =>
  url.replace(/{\w+}/g, (replaceValue) => {
    const formatedValue = replaceValue.replace(/{/g, '').replace(/}/g, '');
    // TODO: Investigate what should happen if value is undefined
    // eslint-disable-next-line no-restricted-syntax
    return getter(formatedValue) as unknown as string;
  });
