import {ParsedUrlQuery} from 'querystring';

import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {getRouterQuery} from '../utils/getRouterQuery';

export const useRouterQuery = (): ParsedUrlQuery => {
  const {search} = useLocation();

  const query = useMemo(() => getRouterQuery(search.replace('?', '')), [search]);

  return query;
};
