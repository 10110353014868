import {Button, ButtonGroup, closeCurrentDialog, showNotification} from 'platform/components';
import {Box, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback, useState} from 'react';

import {isNil, mergeAll, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  useGetMetadaServiceCaseQuery,
  usePostServiceOrderAssignPreviousRequestMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {workshopRoutes} from '@omnetic-dms/routes';
import {ORDER_TABS, handleApiError, queryParams, workshopSearchParams} from '@omnetic-dms/shared';

import {RequiredTestIdProps, composePath, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, RowData} from 'features/datagrid';

import {PreviousRequestsRowData} from '../types/previousRequestsRowData';

type PreviousRequest = {
  previousServiceCaseJobId: string;
  previousServiceOrderId: string;
};

interface AssignPreviousRequestsProps extends RequiredTestIdProps {
  serviceCaseId: string;
  orderId: string;
}

export function AssignPreviousRequests(props: AssignPreviousRequestsProps) {
  const {data: serviceCase} = useGetMetadaServiceCaseQuery({serviceCaseId: props.serviceCaseId});

  const [postServiceOrderAssignPreviousRequest] =
    usePostServiceOrderAssignPreviousRequestMutation();

  const [selectedRequests, setSelectedRequests] = useState<PreviousRequest[] | null>(null);

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {vehicleId: serviceCase?.vehicleId}]),
    [serviceCase?.vehicleId]
  );

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    const castedRowData = rowData as PreviousRequestsRowData;
    const requestId = isArray(rowId) ? rowId[0] : rowId;

    const path = composePath(workshopRoutes.serviceCaseDetail, {
      params: {
        id: castedRowData.serviceCaseId.value,
      },
      queryParams: {
        source: 'service-orders',
        section: workshopSearchParams.serviceDetail.orders,
        [queryParams.SERVICE_CASE_ORDER_ID]: castedRowData.serviceOrderId.value,
        [queryParams.SERVICE_CASE_ORDER_TAB]: ORDER_TABS.JOBS,
        [queryParams.SERVICE_CASE_OPEN_JOB_ID]: requestId,
      },
    });

    match(actionKey)
      .with('open', () => window.open(path))
      .otherwise(() =>
        showNotification.error(`Action callback was not specified for action ${actionKey}`)
      );
  };

  const onRowSelectionChange = (data: RowData[]) =>
    setSelectedRequests(
      data.map((row) => {
        const previousServiceOrderId = path(['serviceOrderId', 'value'], row) as string;

        if (isNil(previousServiceOrderId)) {
          throw new Error('Service order id is not defined');
        }

        return {
          previousServiceCaseJobId: row.id,
          previousServiceOrderId,
        };
      })
    );

  const handleSubmit = async () =>
    await postServiceOrderAssignPreviousRequest({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.orderId,
      body: selectedRequests || [],
    })
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);

  return (
    <VStack spacing={4}>
      <Box height={135}>
        <DataGrid
          gridCode="service-order-assign-previous-request"
          actionCallback={actionCallback}
          onRowSelectionChange={onRowSelectionChange}
          queryModifier={queryModifier}
          data-testid={suffixTestId('dataGrid', props)}
        />
      </Box>
      <VStack spacing={4}>
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            title={i18n.t('general.actions.discard')}
            onClick={closeCurrentDialog}
            data-testid={suffixTestId('discard', props)}
          />
          <Button
            variant="primary"
            title={i18n.t('general.actions.save')}
            onClick={handleSubmit}
            data-testid={suffixTestId('submit', props)}
          />
        </ButtonGroup>
      </VStack>
    </VStack>
  );
}
