import {Alert, AlertVariants} from 'platform/components';
import {Box} from 'platform/foundation';

interface ComponentProps {
  colorScheme: AlertVariants;
  message?: string;
  actionText?: string;
  actionCallback?: () => void;
}

export function CebiaWidgetAlert({
  colorScheme,
  message,
  actionCallback,
  actionText,
}: ComponentProps) {
  return (
    <Box>
      <Alert
        type="inline"
        variant={colorScheme}
        message={message}
        hyperlinks={
          actionText && actionCallback ? [{title: actionText, onClick: actionCallback}] : undefined
        }
      />
    </Box>
  );
}
