export function filterKeys(e: React.KeyboardEvent<HTMLInputElement>) {
  if (e.key.length !== 1) {
    return;
  }

  if (e.ctrlKey || e.altKey || e.metaKey) {
    return;
  }

  const input = e.currentTarget;
  const hasSelection = input.selectionStart !== input.selectionEnd;
  const isAtMaxLength = input.value.length >= (input.maxLength || Infinity);

  if (isAtMaxLength && !hasSelection) {
    e.preventDefault();
    return;
  }

  if (!e.key.match(/^[A-HJ-NPR-Z0-9]$/i)) {
    e.preventDefault();
    return;
  }

  input.setRangeText(
    e.key.toUpperCase(),
    input.selectionStart ?? 0,
    input.selectionEnd ?? 0,
    'end'
  );

  input.dispatchEvent(new Event('input', {bubbles: true}));
  e.preventDefault();
}
