import styled from 'styled-components';

import {useEffect, useRef, useCallback} from 'react';

import {isNil} from 'ramda';

import {useBoolean} from 'shared';

import {SliderIcon} from './assets/icons/SliderIcon';
import vehicleAfter from './assets/photos/vehicle-after.jpg';
import vehicleBefore from './assets/photos/vehicle-before.jpg';

export const StyledImage = styled.img`
  width: 338px;
  height: 202px;
  object-fit: cover;
  vertical-align: middle;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
`;

export const ImageContainerAfter = styled.div`
  overflow: hidden;
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  clip-path: inset(0 50% 0 0);
`;

export const ImageContainerBefore = styled.div`
  overflow: hidden;
`;

export const ComparisonContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  border: 5px solid white;
  box-shadow:
    0px 15px 40px 0px rgba(0, 0, 0, 0.15),
    4px 24px 60px 0px rgba(109, 141, 173, 0.25);
`;

export const Handle = styled.div`
  position: absolute;
  width: 2px;
  height: 100%;
  background: white;
  z-index: 3;
  cursor: col-resize;
  top: 0;
  left: 50%;
`;

export const CompareIcon = styled.div`
  position: absolute;
  top: calc(50% - 14px);
  right: calc(50% - 14px);
  svg {
    min-width: unset;
  }
`;

export const ComparisonSlider = () => {
  const [isResizing, startResizing, stopResizing] = useBoolean(false);
  const topImageRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);

  const setPositioning = useCallback((handlePosition: number) => {
    if (isNil(topImageRef.current) || isNil(handleRef.current)) {
      return;
    }

    const {left, width} = topImageRef.current.getBoundingClientRect();
    const handleWidth = handleRef.current.offsetWidth;

    if (handlePosition >= left && handlePosition <= width + left - handleWidth) {
      handleRef.current.style.left = `${((handlePosition - left) / width) * 100}%`;
      topImageRef.current.style.clipPath = `inset(0 ${
        100 - ((handlePosition - left) / width) * 100
      }% 0 0)`;
    }
  }, []);

  const handleResize = useCallback(
    (e: MouseEvent) => {
      setPositioning(e.clientX);
      e.preventDefault();
    },
    [setPositioning]
  );

  const handleResizeEnd = useCallback(() => {
    stopResizing();
    window.removeEventListener('mousemove', handleResize);
    window.removeEventListener('mouseup', handleResizeEnd);
  }, [handleResize, stopResizing]);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleResize);
      window.addEventListener('mouseup', handleResizeEnd);
    }
    return () => {
      window.removeEventListener('mousemove', handleResize);
      window.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [isResizing, handleResize, handleResizeEnd]);

  return (
    <ComparisonContainer>
      <Handle ref={handleRef} onMouseDown={startResizing}>
        <CompareIcon>
          <SliderIcon />
        </CompareIcon>
      </Handle>
      <ImageContainerAfter ref={topImageRef}>
        <StyledImage src={vehicleAfter} alt="topImage" draggable={false} />
      </ImageContainerAfter>
      <ImageContainerBefore>
        <StyledImage src={vehicleBefore} alt="bottomImage" draggable={false} />
      </ImageContainerBefore>
    </ComparisonContainer>
  );
};
