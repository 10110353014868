import {openDialog} from 'platform/components';

import {WatermarkRepublishInfoDialogContent} from '../components/WatermarkRepublishInfoDialogContent';

type OpenWatermarkRepublishInfoDialogType = {
  onCloseComplete?: () => void;
};

export const openWatermarkRepublishInfoDialog = (data?: OpenWatermarkRepublishInfoDialogType) => {
  openDialog(<WatermarkRepublishInfoDialogContent />, {
    id: 'watermarkRepublishDialog',
    'data-testid': 'watermarkRepublishDialog',
    onCloseComplete: data?.onCloseComplete,
  });
};
