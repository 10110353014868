import {FormControl, FormField} from 'platform/components';
import {Grid, Heading, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ServiceVehicleFormType} from '../../../types/ServiceVehicleFormType';

interface AdditionalProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
}

export function Additional(props: AdditionalProps) {
  return (
    <VStack spacing={4}>
      <Heading size={4}>{i18n.t('entity.vehicle.labels.engine')}</Heading>
      <Grid columns={4}>
        <FormField
          control={props.control}
          type="text"
          name="engineName"
          label={i18n.t('entity.vehicle.labels.engineName')}
          data-testid={suffixTestId('engineName', props)}
        />
        <FormField
          control={props.control}
          type="text"
          name="engineNumber"
          label={i18n.t('entity.vehicle.labels.engineNumber')}
          data-testid={suffixTestId('engineNumber', props)}
        />
        <FormField
          control={props.control}
          type="text"
          name="engineCode"
          label={i18n.t('entity.vehicle.labels.engineCode')}
          data-testid={suffixTestId('engineCode', props)}
        />
      </Grid>
      <Grid columns={4}>
        <FormField
          control={props.control}
          type="number"
          name="power"
          suffix={i18n.t('general.metric.kW')}
          label={i18n.t('entity.vehicle.labels.power')}
          data-testid={suffixTestId('power', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name="maximalPower"
          suffix={i18n.t('general.metric.kW')}
          label={i18n.t('entity.vehicle.labels.maximalPower')}
          data-testid={suffixTestId('maximalPower', props)}
        />
        <FormField
          control={props.control}
          type="number"
          name="engineVolume"
          suffix={i18n.t('general.metric.ccm')}
          label={i18n.t('entity.vehicle.labels.engineCapacity')}
          data-testid={suffixTestId('engineVolume', props)}
        />
      </Grid>
    </VStack>
  );
}
