import {FC} from 'react';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {FormLabel} from '@omnetic-dms/teas';

import {FileFieldWrapper} from '../styles';
import {FileProps, File} from './File';

export const FileField: FC<FileProps> = ({name, onRemove}) => (
  <FileFieldWrapper data-testid={testIds.settings.documentTemplates('fileWrapper')}>
    <FormLabel>* {i18n.t('general.labels.uploadedFile')}</FormLabel>
    <File name={name} onRemove={onRemove} />
  </FileFieldWrapper>
);
