import {SliderTick} from '../types';
import {getNonlinearTickValues} from './getNonlinearTickValues';

/**
 * @description
 * Returns percentage value on track for linear and non-linear values
 *
 * @param {number} min - min value for slider
 * @param {number} max - max value for slider
 * @param {number} value - value between min and max
 * @param {SliderTick[]} nonlinearTicks - Non-linear ticks passed as param in Slider
 *
 * @example
 * // return 50
 * toPercentage(0, 10, 5);
 *
 * // return 75
 * toPercentage(0, 10, 5, [{value: 1}, {value: 2}, {value: 5}]);
 */

export const toPercentage = (
  min: number,
  max: number,
  value: number,
  nonlinearTicks?: SliderTick[]
) => {
  if (value >= max) {
    return 100;
  }

  if (value <= min) {
    return 0;
  }

  if (!nonlinearTicks) {
    return (Math.abs(value - min) * 100) / (max - min);
  }

  const tickValues = getNonlinearTickValues({min, max, nonlinearTicks});

  const percentageStep = 100 / (tickValues.length - 1);

  // find current step index
  const positionIndex = tickValues.findIndex((tickValue) => tickValue > value) - 1;

  // calculate range of current step
  const positionRange = tickValues[positionIndex + 1] - tickValues[positionIndex];

  // calculate percentage of position in current step
  const positionPercentage = (value - tickValues[positionIndex]) / positionRange;

  // calculate final percentage by index plus percentage value in range multiplied by step per percentage
  return (positionIndex + positionPercentage) * percentageStep;
};
