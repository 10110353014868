import {FormApi} from 'final-form';

import {Component, ReactElement} from 'react';
// eslint-disable-next-line no-restricted-imports
import {FormSpy, FormSpyProps, FormSpyRenderProps} from 'react-final-form';

import {equals} from 'ramda';

const DEBOUNCED_TIME = 300;

declare type FormValues = Record<string, unknown>;

interface AutoSaveComponentProps extends FormSpyRenderProps {
  save?: (values: FormValues, form: FormApi, triggeredBy?: string) => Promise<void> | void;
  saveOnChange?: boolean;
  partial?: boolean;
  noExternalModifications?: boolean;
  form: FormApi;
  saveOnInactiveBlur?: boolean;
  debouncedTime?: number;
}

interface AutoSaveComponentState {
  values: FormValues;
  submitting: boolean;
}

class AutoSaveComponent extends Component<AutoSaveComponentProps, AutoSaveComponentState> {
  promise: Promise<void> | undefined = undefined;
  timeout: NodeJS.Timeout | undefined = undefined;

  constructor(props: AutoSaveComponentProps) {
    super(props);
    this.state = {values: props.values, submitting: false};
  }

  componentDidUpdate(prevProps: AutoSaveComponentProps) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (
      prevProps.saveOnChange &&
      (!prevProps.noExternalModifications ||
        (this.props.active &&
          prevProps.values[this.props.active] !== this.props.values[this.props.active]))
    ) {
      this.timeout = setTimeout(this.save, prevProps.debouncedTime ?? DEBOUNCED_TIME);
    }

    if (
      !prevProps.saveOnChange &&
      prevProps.active &&
      prevProps.active !== this.props.active &&
      (!prevProps.noExternalModifications ||
        (this.props.active &&
          prevProps.values[this.props.active] !== this.props.values[this.props.active]))
    ) {
      // blur occurred
      this.save();
    } else if (!this.props.active && prevProps.saveOnInactiveBlur) {
      this.timeout = setTimeout(this.save, prevProps.debouncedTime ?? DEBOUNCED_TIME);
    }
  }

  save = async (): Promise<void> => {
    if (this.promise) {
      await this.promise;
    }
    const {values, save, partial} = this.props;

    const getDifference = (object: FormValues, comparedObject: FormValues): FormValues =>
      Object.keys(comparedObject).reduce((diff, key) => {
        if (equals(object[key], comparedObject[key])) {
          return diff;
        }

        return {
          ...diff,
          [key]: comparedObject[key],
        };
      }, {});

    if (save && JSON.stringify(this.state.values) !== JSON.stringify(values)) {
      const oldValues = this.state.values;
      this.setState({submitting: true, values});
      this.promise = Promise.resolve(
        save(
          partial ? getDifference(oldValues, values) : values,
          this.props.form,
          this.props.active
        )
      );
      await this.promise;
      delete this.promise;
      this.setState({submitting: false});
    }
  };

  render() {
    // This component doesn't have to render anything, but it can render
    // submitting state.
    return <>{null}</>;
  }
}

interface AutoSaveProps<T> extends FormSpyProps {
  setFieldData?: (name: string, data: Record<string, unknown>) => void;
  save: (values: T, form: FormApi, triggeredBy?: string) => Promise<void> | void;
  saveOnChange?: boolean;
  partial?: boolean;
  noExternalModifications?: boolean;
  saveOnInactiveBlur?: boolean;
  debouncedTime?: number;
}

/**
 * Provides saving of changes in inputs (calling save method) on blur or on change in any of the fields
 * - Use built-in React lifecycle methods to listen for changes
 * - Maintain state of when we are submitting
 * @param {AutoSaveProps} props takes saveOnChange prop to save inputs on change (saves by blur by default)
 */
export function AutoSave<T = FormValues>(props: AutoSaveProps<T>): ReactElement {
  return (
    <FormSpy {...props} subscription={{active: true, values: true}} component={AutoSaveComponent} />
  );
}
