import {css} from 'styled-components';

import {ReactElement} from 'react';

import {Nullish} from 'shared';

interface OffscreenContentProps {
  children: ReactElement | Nullish;
}

export function OffscreenContent(props: OffscreenContentProps) {
  return (
    <div
      tabIndex={-1}
      aria-hidden="true"
      css={css`
        position: fixed;
        width: 1px;
        height: 1px;
        top: -9000px;
        left: -9000px;
        opacity: 0;
        pointer-events: none;
        clip: rect(0 0 0 0);
        margin: -1px;
        overflow: hidden;
      `}
    >
      {props.children}
    </div>
  );
}
