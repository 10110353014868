import {Button, ButtonGroup, DataStatus} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {filter, includes, isNil, not} from 'ramda';

import {
  useGetServiceCaseOrdersQuery,
  usePutServiceOrderItemsToOtherOrderJobMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {SelectedJobType} from '../../types/SelectedJobType';
import {MoveRequestItemOrder} from '../MoveRequestItemOrder/MoveRequestItemOrder';

interface MoveRequestItemProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  items: {serviceJobId: string; serviceItemId: string}[];
  hiddenServiceJob?: string | Nullish;
  onMoveItems: () => void;
  onClose: () => void;
}

export function MoveRequestItem(props: MoveRequestItemProps) {
  const [selectedJob, setSelectedJob] = useState<SelectedJobType | Nullish>(null);

  const [putServiceOrderItemsToOtherOrderJob, {isLoading: isMoveLoading}] =
    usePutServiceOrderItemsToOtherOrderJobMutation();
  const {
    data: serviceCaseOrders,
    isLoading,
    isFetching,
    isError,
  } = useGetServiceCaseOrdersQuery({
    serviceCaseId: props.serviceCaseId,
  });

  const serviceCaseOrdersToShow = filter(
    (order) => not(includes(order?.state, ['LOCKED', 'ARCHIVED', 'CLOSED', 'CANCELLED'])),
    serviceCaseOrders ?? []
  );

  const handleMoveRequestItem = () => {
    if (isNil(selectedJob)) {
      return;
    }

    putServiceOrderItemsToOtherOrderJob({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: {
        serviceMoveToOrderId: selectedJob.orderId ?? '',
        serviceMoveToJobId: selectedJob.jobId ?? '',
        items: props.items,
      },
    })
      .unwrap()
      .then(() => {
        props.onMoveItems();
        props.onClose();
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isLoading || isFetching} isError={isError} minHeight={23}>
      <VStack spacing={4}>
        <Text color="tertiary" size="small">
          {i18n.t('entity.orderRequest.labels.moveRequestItemDescription')}
        </Text>
        {serviceCaseOrdersToShow?.map(
          (order, index) =>
            order && (
              <MoveRequestItemOrder
                key={order.id}
                serviceCaseId={props.serviceCaseId}
                id={order.id}
                selectedJob={selectedJob}
                onJobSelect={(jobId) => setSelectedJob({jobId, orderId: order.id})}
                hiddenServiceJob={props.hiddenServiceJob}
                data-testid={suffixTestId(`order-[${index}]`, props)}
              />
            )
        )}
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            onClick={props.onClose}
            data-testid={suffixTestId('cancel', props)}
            title={i18n.t('general.actions.cancel')}
          />
          <Button
            onClick={handleMoveRequestItem}
            isDisabled={isNil(selectedJob)}
            isLoading={isMoveLoading}
            data-testid={suffixTestId('move', props)}
            title={i18n.t('entity.order.actions.moveHere')}
          />
        </ButtonGroup>
      </VStack>
    </DataStatus>
  );
}
