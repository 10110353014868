import {IconButton, Separator} from 'platform/components';
import {Heading, HStack, Space} from 'platform/foundation';

import {useLocation, useParams} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {sourcingRoutes} from '@omnetic-dms/routes';

import {composePath, suffixTestId, TestIdProps, useNavigate} from 'shared';

import {PREV_URL_COOKIE} from '../../../types/CommonAppTypes';
import {
  SOURCING_USER_LISTS,
  SourcingSubPageRoute,
  UserListPage,
} from '../../../types/SourcingSomeTypes';

export function TitleSection(props: TestIdProps) {
  const {pathname} = useLocation();
  const {adId} = useParams();
  const navigate = useNavigate();

  const detailRoute = composePath(sourcingRoutes.vehicleDetail, {
    params: {adId: adId ?? ':adId'},
  });

  const pathsWithBackButton = [
    SOURCING_USER_LISTS(SourcingSubPageRoute.classifieds, UserListPage.CART_PAGE),
    SOURCING_USER_LISTS(SourcingSubPageRoute.classifieds, UserListPage.COMPARISON_PAGE),
    SOURCING_USER_LISTS(SourcingSubPageRoute.classifieds, UserListPage.FAVORITES_VEHICLE_PAGE),
    SOURCING_USER_LISTS(SourcingSubPageRoute.classifieds, UserListPage.HIDDEN_VEHICLE_PAGE),
    detailRoute,
  ];

  const handleBackButtonClick = () => {
    const allOffersUrl = SOURCING_USER_LISTS(SourcingSubPageRoute.classifieds);
    const prevUrl = localStorage.getItem(PREV_URL_COOKIE) || allOffersUrl;

    navigate(pathname === detailRoute ? prevUrl : allOffersUrl);
  };

  return (
    <HStack>
      {pathsWithBackButton.includes(pathname) && (
        <>
          <IconButton
            icon="hardware/keyboard_arrow_left"
            priority="primary"
            size="small"
            onClick={handleBackButtonClick}
            data-testid={suffixTestId('backButton', props)}
          />
          <Space horizontal={4} />
        </>
      )}

      <Heading headingLevel={2} size={4}>
        {i18n.t('page.sourcing.labels.sourcing')}
      </Heading>

      <Separator orientation="vertical" />
    </HStack>
  );
}
