import {Dialog} from 'platform/components';

import {FC, useState} from 'react';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {SelectVehicleDialogDataGrid} from './BusinessCaseOffersSelectVehicleDatagrid';

export type SelectVehicleFullScreenProps = {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: (invoiceId: string) => void;
};

export const BusinessCaseOffersSelectVehicleDialog: FC<SelectVehicleFullScreenProps> = ({
  onClose,
  isOpen,
  onSubmit,
}) => {
  const [selectedId, setSelectedId] = useState<string>();

  const handleSubmit = () => {
    if (selectedId) {
      onSubmit(selectedId);
    }
  };

  return (
    <Dialog
      data-testid={testIds.businessCase.detail('addSaleVehicle')}
      size="large"
      isOpen={isOpen}
      onClose={onClose}
      title={i18n.t('page.businessCase.labels.selectVehicle')}
      buttons={[
        {
          title: i18n.t('general.actions.cancel'),
          variant: 'secondary',
          onClick: onClose,
        },

        {
          title: i18n.t('general.labels.select'),
          variant: 'primary',
          onClick: handleSubmit,
          isDisabled: !selectedId,
        },
      ]}
    >
      <SelectVehicleDialogDataGrid
        data-testid={testIds.businessCase.detail('addSaleVehicle')}
        onRowSelectionChange={(rows) => {
          setSelectedId(rows[0]?.id);
        }}
      />
    </Dialog>
  );
};
