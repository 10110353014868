import {useEffect} from 'react';

import i18n from '@omnetic-dms/i18n';
import {
  getPaymentTypes,
  loadDocumentRoundingDefinitionItems,
  useThunkDispatch,
} from '@omnetic-dms/teas';

import {useBoolean} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DocumentRoundingDefinitionList} from './components/DocumentRoundingDefinitionList';

export function DocumentRoundingDefinitions() {
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const dispatch = useThunkDispatch();

  useEffect(() => {
    startLoading();
    Promise.all([
      dispatch(getPaymentTypes.action({})),
      dispatch(loadDocumentRoundingDefinitionItems()),
    ]).finally(() => {
      stopLoading();
    });
  }, [dispatch, startLoading, stopLoading]);

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.accounting.labels.documentRoundingDefinitions')}}
      isLoading={isLoading}
      data-testid="settings-document-rounding-definition-page"
    >
      <DocumentRoundingDefinitionList />
    </SettingsTemplate>
  );
}
