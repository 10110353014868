import {Checkboxes} from '../../Checkboxes/Checkboxes';
import {useFieldProps} from '../hooks/useFieldProps';
import {useOptionsManager} from '../hooks/useOptionsManager';
import {CheckboxesRendererResponseBody} from '../types/modelSchema';

export function CheckboxesRenderer(props: CheckboxesRendererResponseBody) {
  const {defaultOptions, url, dependentOptions, tooltip} = props;
  const {onChange, onBlur, onFocus, value, name, isRequired, error} = useFieldProps(props);
  const options = useOptionsManager(defaultOptions, dependentOptions, url);

  return (
    <Checkboxes
      label={props.label}
      data-testid={name}
      value={value}
      isRequired={isRequired}
      errorMessage={error?.message}
      onChange={onChange}
      tooltip={tooltip}
      onBlur={onBlur}
      onFocus={onFocus}
      options={options}
    />
  );
}
