import {useParams} from 'react-router-dom';

import {defaultTo} from 'ramda';

type CustomerIdParams = {
  id?: string;
};

export function useCustomerUrl() {
  const params = useParams<CustomerIdParams>();
  return {customerId: defaultTo('', params.id)};
}
