import {parseDate} from 'shared';

/**
 * @internal
 */
export const parseDateString = (dateString?: string): Date | null => {
  if (!dateString) {
    return null;
  }
  const res = parseDate(dateString);

  if (isNaN(res.valueOf())) {
    return null;
  }

  return res;
};
