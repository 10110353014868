import {DataStatus} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {useEffect, useReducer, useState} from 'react';

import {add, isNil} from 'ramda';

import {useGetServiceCaseOrdersQuery} from '@omnetic-dms/api';
import {testIds} from '@omnetic-dms/routes';
import {queryParams, Section} from '@omnetic-dms/shared';

import {useQueryState} from 'shared';

import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';
import {AddMaterialRequestModal} from './components/AddMaterialRequestModal';
import {AddWorkModal} from './components/AddWorkModal';
import {OrderCard} from './components/OrderCard';

export function Orders() {
  const {serviceCaseId, addWorkJobId, addMaterialRequestJobId} = useWorkshopUrl();
  const [orderId, setOrderId, clearOrderId] = useQueryState(queryParams.SERVICE_CASE_ORDER_ID);
  const [defaultOpened, setDefaultOpened] = useState(!!orderId);
  const [key, reloadComponents] = useReducer(add(1), 0);

  const {data, isLoading, isError} = useGetServiceCaseOrdersQuery({serviceCaseId});

  useEffect(() => {
    const orderIdFirst = data?.[0]?.id;
    if (!defaultOpened && !orderId && orderIdFirst) {
      setDefaultOpened(true);
      if (data.length === 1) {
        setOrderId(orderIdFirst);
      }
    }
  }, [orderId, data, setDefaultOpened, setOrderId, defaultOpened]);

  const onCardExpand = (id?: string | null) => {
    if (isNil(id)) {
      return;
    }
    if (orderId === id) {
      clearOrderId();
    }
    if (orderId !== id) {
      setOrderId(id);
    }
  };

  return (
    <Section data-testid={testIds.workshop.serviceCaseDetail('orders')}>
      <DataStatus isLoading={isLoading} isError={isError} isEmpty={!data?.length} minHeight={64}>
        <VStack spacing={4}>
          {data?.map(
            (order, index) =>
              order &&
              order.id &&
              order.serviceCaseId && (
                <OrderCard
                  key={order.id}
                  data-testid={testIds.workshop.serviceCaseDetail(`order-[${index}]`)}
                  order={order}
                  isExpanded={orderId === order.id}
                  onExpand={() => onCardExpand(order.id)}
                  dataGridKey={key}
                  onDataChange={reloadComponents}
                />
              )
          )}
        </VStack>
      </DataStatus>
      <Show when={!!addWorkJobId}>
        <AddWorkModal
          onSubmit={reloadComponents}
          data-testid={testIds.workshop.serviceCaseDetail('addWorkModal')}
        />
      </Show>
      <Show when={!!addMaterialRequestJobId}>
        <AddMaterialRequestModal
          onSubmit={reloadComponents}
          data-testid={testIds.workshop.serviceCaseDetail('addMaterialRequestModal')}
        />
      </Show>
    </Section>
  );
}
