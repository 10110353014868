import {defaultTo, find} from 'ramda';

import {useGetGendersQuery, GenderResponseBody} from '@omnetic-dms/api';

import {Nullish} from 'shared';

type Code = string | Nullish;

export function useGender() {
  const {data} = useGetGendersQuery();

  const getGenderByCode = (code: Code): GenderResponseBody | undefined =>
    find((gender) => gender.code === code, data ?? []);

  const genderOptions = data?.map((g) => ({
    label: g.name,
    value: g.code,
  }));

  return {
    genderOptions,
    getGender: (code: Code) => defaultTo(null, getGenderByCode(code)),
    getGenderName: (code: Code) => defaultTo(null, getGenderByCode(code)?.name),
  };
}
