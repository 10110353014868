import {z} from 'zod';

export const BigDecimalSchema = z.string();
export type BigDecimal = z.infer<typeof BigDecimalSchema>;
export const CurrencySchema = z.string();
export type Currency = z.infer<typeof CurrencySchema>;

export type Price = {
  amount: BigDecimal;
  currency: Currency;
};
