import {head} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  SpecificallyAuditDataItemValuesResponseBody,
  useGetSpecificallyAuditDataQuery,
} from '@omnetic-dms/api';

import {Nullish} from 'shared';

const EXTERIOR_CONDITION_PARAM_DEFINITION = 'paramDefinition1243e';
const INTERIOR_CONDITION_PARAM_DEFINITION = 'paramDefinitione4769';
const MECHANICAL_CONDITION_PARAM_DEFINITION = 'paramDefinitionb0dff';

export type VehicleConditionEvaluationType =
  | {
      exteriorState: string | Nullish;
      interiorState: string | Nullish;
      mechanicalState: string | Nullish;
    }
  | Nullish;

export function useVehicleConditionEvaluation(
  vehicleId: string
): [VehicleConditionEvaluationType, {isLoading: boolean; isError: boolean}] {
  const {
    data: auditData,
    isLoading,
    isError,
  } = useGetSpecificallyAuditDataQuery(
    {
      inspectionType: 'vehicleCondition',
      specificallyAuditDataRequestBody: {
        vehicleIds: [vehicleId],
        keys: [
          EXTERIOR_CONDITION_PARAM_DEFINITION,
          INTERIOR_CONDITION_PARAM_DEFINITION,
          MECHANICAL_CONDITION_PARAM_DEFINITION,
        ],
      },
    },
    {skip: isNilOrEmpty(vehicleId)}
  );

  return [
    auditData ? getVehicleConditionFromAuditData(head(auditData ?? [])?.values) : null,
    {isLoading, isError},
  ];
}

function getVehicleConditionFromAuditData(
  data: SpecificallyAuditDataItemValuesResponseBody[] | Nullish
): VehicleConditionEvaluationType {
  return {
    exteriorState: data?.find((param) => param.key === EXTERIOR_CONDITION_PARAM_DEFINITION)?.value,
    interiorState: data?.find((param) => param.key === INTERIOR_CONDITION_PARAM_DEFINITION)?.value,
    mechanicalState: data?.find((param) => param.key === MECHANICAL_CONDITION_PARAM_DEFINITION)
      ?.value,
  };
}
