import {Integer} from 'platform/foundation';

import {Nullish} from 'shared';

import {Separator} from '../../Separator/Separator';
import {DividerRendererResponseBody} from '../types/modelSchema';

export function DividerRenderer(props: DividerRendererResponseBody) {
  return <Separator spacing={props.spacing as Integer | Nullish} orientation={props.orientation} />;
}
