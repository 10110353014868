import {useLayoutEffect, useRef} from 'react';

/**
 * @about React hook  to get access to the latest value of some props or state inside an asynchronous callback,
 * instead of that value at the time the callback was created from.
 * @param {T} value - The value to keep ref updated with.
 * @returns {React.MutableRefObject<T>} A mutable ref object whose `.current` property
 *  is updated with the latest value passed to the hook.
 * @example
 *  const [count, setCount] = React.useState(0);
 *  const latestCount = useLatest(count);
 *
 *  function handleAlertClick() {
 *     setTimeout(() => {
 *       alert(`Latest count value: ${latestCount.current}`);
 *     }, 3000);
 *   }
 *
 *   return (
 *     <div>
 *       <p>You clicked {count} times</p>
 *       <button onClick={() => setCount(count + 1)}>Click me</button>
 *       <button onClick={handleAlertClick}>Show alert</button>
 *     </div>
 *   );
 */
export const useLatest = <T>(value: T) => {
  const ref = useRef(value);

  useLayoutEffect(() => {
    ref.current = value;
  });

  return ref;
};
