import {
  Action,
  DataStatus,
  Form,
  FormSubmitHandler,
  showNotification,
  Wizard,
  WizardStepConfig,
} from 'platform/components';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';
import {date, object, string} from 'yup';

import {useState} from 'react';

import {head, ifElse, isNil, pick} from 'ramda';
import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  CustomerResponseBodyV2,
  PaymentType,
  useAddVehicleReservationDepositMutation,
  useCreateSaleBusinessCaseMutation,
  useGetCashRegisterDocumentQuery,
  useGetCashRegisterListQuery,
  useGetCustomerV2Query,
  useGetPaymentTypesForInvoicePaymentQuery,
  useGetSaleVehicleQuery,
  useGetTenantQuery,
  useGetVehicleQuery,
  useLazyGetBusinessCaseQuery,
  useReserveVehicleMutation,
  useUpdateVehicleReservationMutation,
  useVehicleActiveReservationQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {
  buildArray,
  CurrencyCodeType,
  getApiDateString,
  Nullish,
  parseDate,
  suffixTestId,
  TestIdProps,
} from 'shared';

import {DEFAULT_CURRENCY} from '../../constants/currency';
import {useLazyGetAuthorizationProfilesAndCKKPermissions} from '../../hooks/useLazyGetAuthorizationProfilesAndCKKPermissions';
import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {getNaturalPersonFullName} from '../../utils/getNaturalPersonFullName';
import {handleApiError} from '../../utils/handleApiError';
import {isCustomerNaturalPerson} from '../../utils/isCustomerNaturalPerson';
import {CustomerStep} from './components/CustomerStep';
import {DateStep} from './components/DateStep';
import {DepositStep} from './components/DepositStep';
import {WizardForm} from './types/WizardForm';

interface VehicleReservationWizardProps extends TestIdProps {
  vehicleId: string;
  businessCaseId?: string;
  customerId?: string;
  onComplete: () => void;
  onCancel: () => void;
}

export function VehicleReservationWizard(props: VehicleReservationWizardProps) {
  const [selectedCustomer, selectCustomer] = useState<CustomerResponseBodyV2 | Nullish>(null);
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useFormatCurrency();
  const [hasCashRegisterDocumentReadPermission, hasCreateBusinessCaseSellingPermission] =
    usePermissions({
      permissionKeys: ['readCashRegisterDocument', 'createBusinessCaseSelling'],
    });

  const {data: tenant} = useGetTenantQuery();
  const {data: saleVehicle} = useGetSaleVehicleQuery({vehicleId: props.vehicleId});
  const {data: vehicleData} = useGetVehicleQuery({vehicleId: props.vehicleId});
  const {handleSearchAuthorizationProfiles} = useLazyGetAuthorizationProfilesAndCKKPermissions();

  const initialCustomerId = saleVehicle?.activeReservation?.customerId ?? props.customerId ?? '';

  const currency = (tenant?.currency || DEFAULT_CURRENCY) as CurrencyCodeType;

  const {
    data: cashRegisters = [],
    isLoading: isLoadingCashRegisters,
    isError: isCashRegisterError,
  } = useGetCashRegisterListQuery(
    {
      inactive: false,
      withValidIncomeSeries: true,
      currency,
    },
    {
      skip: !hasCashRegisterDocumentReadPermission,
    }
  );

  const {
    data: paymentTypes = [],
    isLoading: isLoadingPaymentTypes,
    isError: isPaymentTypesError,
  } = useGetPaymentTypesForInvoicePaymentQuery(
    {},
    {
      skip: !hasCashRegisterDocumentReadPermission,
    }
  );

  const {isLoading: isCustomerLoading, isError: isCustomerError} = useGetCustomerV2Query(
    {
      customerId: initialCustomerId,
    },
    {
      skip: isNotNil(selectedCustomer) || isNilOrEmpty(initialCustomerId),
      selectFromResult: (result) => {
        isNotNilOrEmpty(result.data) && selectCustomer(result.data);

        return result;
      },
    }
  );
  const {data: activeReservation} = useVehicleActiveReservationQuery({vehicleId: props.vehicleId});

  const {data: cashRegisterDocument} = useGetCashRegisterDocumentQuery(
    {
      cashRegisterDocumentId:
        saleVehicle?.activeReservation?.paidAdvanceDeposit?.incomeCashReceiptId ?? '',
    },
    {
      skip:
        isNil(saleVehicle?.activeReservation?.paidAdvanceDeposit?.incomeCashReceiptId) ||
        !hasCashRegisterDocumentReadPermission,
    }
  );

  const depositAmount = cashRegisterDocument?.totalPriceWithVat.amount;

  const isLoading = isCustomerLoading || isLoadingCashRegisters || isLoadingPaymentTypes;
  const isError = isCustomerError || isCashRegisterError || isPaymentTypesError;

  const [createSaleBusinessCase] = useCreateSaleBusinessCaseMutation();
  const [reserveVehicle] = useReserveVehicleMutation();
  const [updateReservation] = useUpdateVehicleReservationMutation();
  const [addAdvanceDeposit] = useAddVehicleReservationDepositMutation();

  const cashRegisterOptions = cashRegisters.map((cashRegister) => ({
    value: cashRegister.id,
    label: cashRegister.name,
  }));
  const [getBusinessCase] = useLazyGetBusinessCaseQuery();

  const getPaymentTypeNameByType = (type: PaymentType): string | undefined =>
    paymentTypes.find((paymentType) => paymentType.key === type)?.value;

  const getTradeName = (customer: CustomerResponseBodyV2) =>
    customer.businessInfo?.businessInfo?.tradeName;

  const getFullName = (customer: CustomerResponseBodyV2) =>
    getNaturalPersonFullName(customer.foundingPerson);

  const handleCustomerSelect = (customer: CustomerResponseBodyV2) => {
    selectCustomer(customer);
  };

  const handleResetCustomer = () => {
    selectCustomer(null);
  };

  const getRequestBodyWithoutDeposit = (values: WizardForm) => ({
    reserveUntil: getApiDateString(parseDate(values.reserveUntil)),
    customerId: selectedCustomer?.id || null,
    customer: customerName || null,
    note: values.note || null,
  });

  const getRequestBodyWithDeposit = (values: WizardForm) => ({
    note: values.cashReceiptNote || null,
    customerId: selectedCustomer?.id ?? '',
    businessCaseId: saleVehicle?.activeReservation?.businessCaseId ?? props?.businessCaseId ?? '',
    cashRegisterId: values.cashRegister,
    issuedAt: getApiDateString(parseDate(values.issuedOn)),
    amount: {
      amount: values.amount,
      currency,
    } as const,
  });

  const handleSubmit: FormSubmitHandler<WizardForm> = async (values) => {
    const requestBodyWithDeposit = getRequestBodyWithDeposit(values);
    const requestBodyWithoutDeposit = getRequestBodyWithoutDeposit(values);

    const createOrUpdateReservation = isNil(saleVehicle?.activeReservation)
      ? reserveVehicle
      : updateReservation;

    const isWithAdvanceDeposit = Boolean(values.withAdvanceDeposit);
    let businessCaseId =
      saleVehicle?.activeReservation?.businessCaseId ?? props.businessCaseId ?? null;

    if (
      isNil(businessCaseId) &&
      isNotNil(selectedCustomer) &&
      hasCreateBusinessCaseSellingPermission
    ) {
      const newBusinessCase = await createSaleBusinessCase({
        createSaleBusinessCaseRequestBody: {
          customerId: selectedCustomer.id,
          vehicleToSaleId: props.vehicleId,
        },
      })
        .unwrap()
        .catch(handleApiError);
      if (newBusinessCase) {
        businessCaseId = newBusinessCase.id;
      }
      if (businessCaseId) {
        getBusinessCase({businessCaseId})
          .unwrap()
          .then((businessCase) => {
            handleSearchAuthorizationProfiles(
              {
                vehicleMake: vehicleData?.make ?? '',
                vehicleType: vehicleData?.type ?? null,
                vehicleModelFamily: vehicleData?.vehicle?.modelFamily ?? null,
                vehicleModelFamilyGroup: vehicleData?.vehicle?.modelFamilyGroup ?? null,
              },
              selectedCustomer?.id,
              {bcNumber: businessCase?.code, bcCreatedAt: businessCase?.createdAt}
            );
          })
          .catch(handleApiError);
      } else {
        handleSearchAuthorizationProfiles(
          {
            vehicleMake: vehicleData?.make ?? '',
            vehicleType: vehicleData?.type ?? null,
            vehicleModelFamily: vehicleData?.vehicle?.modelFamily ?? null,
            vehicleModelFamilyGroup: vehicleData?.vehicle?.modelFamilyGroup ?? null,
          },
          selectedCustomer?.id
        );
      }
    }

    if (
      isWithAdvanceDeposit &&
      businessCaseId &&
      isNilOrEmpty(saleVehicle?.activeReservation?.paidAdvanceDeposit) &&
      isNotNilOrEmpty(saleVehicle?.activeReservation)
    ) {
      await updateReservation({
        vehicleId: props.vehicleId,
        body: {
          ...pick(['note', 'customerId'], requestBodyWithDeposit),
          ...pick(['reserveUntil'], requestBodyWithoutDeposit),
          customer: customerName,
          businessCaseId,
        },
      })
        .unwrap()
        .then(() =>
          addAdvanceDeposit({
            vehicleId: props.vehicleId,
            'X-Branch': saleVehicle?.branchId ?? '',
            body: {
              ...requestBodyWithDeposit,
              businessCaseId,
            },
          }).unwrap()
        )
        .then(props.onComplete)
        .then(() => showNotification.success())
        .catch(handleApiError);
      return;
    }

    await createOrUpdateReservation({
      vehicleId: props.vehicleId,
      body: {...requestBodyWithoutDeposit, businessCaseId},
    })
      .unwrap()
      .then(() => {
        if (
          isNil(saleVehicle?.activeReservation) &&
          isNotNilOrEmpty(values.amount) &&
          isNotNil(businessCaseId)
        ) {
          return addAdvanceDeposit({
            vehicleId: props.vehicleId,
            'X-Branch': saleVehicle?.branchId ?? '',
            body: {
              note: values.cashReceiptNote || null,
              customerId: selectedCustomer?.id ?? '',
              businessCaseId,
              cashRegisterId: values.cashRegister,
              issuedAt: getApiDateString(parseDate(values.issuedOn)),
              amount: {
                amount: values.amount,
                currency,
              } as const,
            },
          }).unwrap();
        }
      })
      .then(props.onComplete)
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const handleCancel = () => {
    props.onCancel();
    selectCustomer(null);
  };

  const customerName = isNotNil(selectedCustomer)
    ? (ifElse(isCustomerNaturalPerson, getFullName, getTradeName)(selectedCustomer) ?? null)
    : null;

  const defaultValues: Partial<WizardForm> = {
    reserveUntil: saleVehicle?.activeReservation?.reservedUntil
      ? parseDate(saleVehicle?.activeReservation?.reservedUntil)
      : undefined,
    note: saleVehicle?.activeReservation?.note ?? '',
    issuedOn: new Date(),
    cashRegister: head(cashRegisterOptions)?.value,
    paymentMethod: getPaymentTypeNameByType('cash'),
    withAdvanceDeposit: isNotNilOrEmpty(depositAmount),
    amount: depositAmount,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight="50vh">
      <Form<WizardForm> defaultValues={defaultValues} schema={schema} onSubmit={handleSubmit}>
        {(control, formApi) => {
          const reserveUntil = formApi.watch('reserveUntil');
          const amount = depositAmount ?? formApi.watch('amount');

          const submitButton: Action = {
            'data-testid': suffixTestId('submit', props),
            title: i18n.t('entity.order.actions.reserve'),
            buttonType: 'submit',
            type: 'form-button',
            variant: 'primary',
            control,
          };

          return (
            <Wizard
              data-testid={suffixTestId('steps', props)}
              steps={buildArray<WizardStepConfig>([
                {
                  title: i18n.t('entity.vehicle.labels.reservationDate'),
                  content: (
                    <DateStep
                      control={control}
                      formApi={formApi}
                      data-testid={suffixTestId('dateStep', props)}
                    />
                  ),
                  subtitle: isNotNil(reserveUntil)
                    ? formatDateTime('dateShort', parseDate(reserveUntil))
                    : i18n.t('general.labels.selectedNothing'),
                  severity: isNotNil(reserveUntil) ? 'success' : undefined,
                  actions: [submitButton],
                },
                {
                  title: i18n.t('entity.customer.labels.customer'),
                  content: (
                    <CustomerStep
                      customer={selectedCustomer}
                      customerName={customerName}
                      onCustomerSelect={handleCustomerSelect}
                      onResetCustomer={handleResetCustomer}
                      data-testid={suffixTestId('customerStep', props)}
                      isCustomerResetAllowed={isNil(activeReservation) && isNil(props.customerId)}
                    />
                  ),
                  subtitle: isNotNil(selectedCustomer)
                    ? customerName
                    : i18n.t('general.labels.selectedNothing'),
                  severity: isNotNil(selectedCustomer) ? 'success' : undefined,
                  actions: [submitButton],
                },
              ]).when(
                hasCashRegisterDocumentReadPermission &&
                  hasCreateBusinessCaseSellingPermission &&
                  tenant?.country !== 'POL',
                {
                  title: i18n.t('entity.vehicle.labels.advanceDeposit'),
                  content: (
                    <DepositStep
                      data-testid={suffixTestId('advanceDeposit', props)}
                      customer={selectedCustomer}
                      control={control}
                      formApi={formApi}
                      reservation={activeReservation}
                      vehicleId={props.vehicleId}
                      cashRegisterDocument={cashRegisterDocument}
                    />
                  ),
                  subtitle: isNotNil(amount)
                    ? formatCurrency(Number(amount), currency)
                    : i18n.t('general.labels.selectedNothing'),
                  severity: isNotNil(amount) ? 'success' : undefined,
                  actions: [submitButton],
                }
              )}
              actions={[
                {
                  title: i18n.t('general.actions.cancel'),
                  onClick: handleCancel,
                  type: 'button',
                  variant: 'dangerGhost',
                },
              ]}
              minHeight="60vh"
            />
          );
        }}
      </Form>
    </DataStatus>
  );
}

const schema = object().shape({
  reserveUntil: date().required(),
  note: string().nullable(),
  amount: string().when('withAdvanceDeposit', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  issuedOn: string().when('withAdvanceDeposit', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  cashRegister: string().when('withAdvanceDeposit', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  paymentMethod: string().when('withAdvanceDeposit', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  cashReceiptNote: string().nullable(),
});
