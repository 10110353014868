import {VehicleSeriesDefinitionService} from '../../services/VehicleSeriesDefinitionService';
import {asyncThunkAction} from '../../utils/reduxThunkUtils';
import {VEHICLE_SERIES_DEFINITION_SLICE_NAME} from './constants';

/**
 * Get list of definitions
 */
export const getVehicleSeriesDefinitionList = asyncThunkAction(
  `${VEHICLE_SERIES_DEFINITION_SLICE_NAME}/getVehicleSeriesDefinitionList`,
  VehicleSeriesDefinitionService.listVehicleSeriesDefinition
);

/**
 * Get definition detail
 */
export const getVehicleSeriesDefinition = asyncThunkAction(
  `${VEHICLE_SERIES_DEFINITION_SLICE_NAME}/getVehicleSeriesDefinition`,
  VehicleSeriesDefinitionService.getVehicleSeriesDefinition
);

/**
 * Create new definition
 */
export const createVehicleSeriesDefinition = asyncThunkAction(
  `${VEHICLE_SERIES_DEFINITION_SLICE_NAME}/createVehicleSeriesDefinition`,
  VehicleSeriesDefinitionService.createVehicleSeriesDefinition
);

/**
 * Update existing definition
 */
export const updateVehicleSeriesDefinition = asyncThunkAction(
  `${VEHICLE_SERIES_DEFINITION_SLICE_NAME}/updateVehicleSeriesDefinition`,
  VehicleSeriesDefinitionService.updateVehicleSeriesDefinition
);

/**
 * Update existing system definition
 */
export const updateVehicleSeriesSystemDefinition = asyncThunkAction(
  `${VEHICLE_SERIES_DEFINITION_SLICE_NAME}/updateVehicleSeriesSystemDefinition`,
  VehicleSeriesDefinitionService.updateVehicleSeriesSystemDefinition
);
