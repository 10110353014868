import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import {
  useDeleteServiceOrderVariantMutation,
  usePostServiceOrderVariantSetAsActiveMutation,
  usePostServiceOrderVariantSetAsInactiveMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function ServiceOrderVariants() {
  const navigate = useNavigate();

  const [postServiceOrderVariantSetAsActive] = usePostServiceOrderVariantSetAsActiveMutation();
  const [postServiceOrderVariantSetAsInactive] = usePostServiceOrderVariantSetAsInactiveMutation();
  const [deleteServiceOrderVariant] = useDeleteServiceOrderVariantMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', () =>
        navigate(composePath(settingsRoutes.serviceOrderVariantsDetail, {params: {id}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteServiceOrderVariant({serviceOrderVariantId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('setAsActive', () =>
        postServiceOrderVariantSetAsActive({serviceOrderVariantId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('setAsInactive', () =>
        postServiceOrderVariantSetAsInactive({serviceOrderVariantId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .otherwise(showNotification.error);
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.order.labels.serviceOrderVariants'),
        actions: [
          {
            type: 'button',
            title: i18n.t('entity.order.actions.newServiceOrderVariant'),
            onClick: () => navigate(settingsRoutes.serviceOrderVariantsCreate),
          },
        ],
      }}
      data-testid={testIds.settings.serviceOrderVariants('template')}
    >
      <DataGrid
        gridCode="service-order-variant"
        actionCallback={actionCallback}
        data-testid={testIds.settings.serviceOrderVariants('datagrid')}
      />
    </SettingsTemplate>
  );
}
