import {LocaleConfig} from 'platform/locale';

import {isNil} from 'ramda';

import {omneticApi} from './baseApi/omneticApi';

type GetTenantLocaleConfigApiRes = {
  force: string;
  recordId: string;
  tenantId: string;
  value: LocaleConfig;
}[];

export const localeConfigApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getLocaleConfig: build.query<LocaleConfig, void>({
      query: () => ({
        url: `/dms/v1/settings/localization/effective-value/myself/without-reference`,
      }),
      providesTags: ['LocaleConfig'],
    }),
    getTenantLocaleConfig: build.query<LocaleConfig, void>({
      query: () => ({
        url: `/dms/v1/settings/localization/tenant`,
      }),
      transformResponse: (response: GetTenantLocaleConfigApiRes) => {
        const valueWithoutReference = response.find((settingsValue) =>
          isNil(settingsValue.recordId)
        );
        return valueWithoutReference?.value ?? response[0].value;
      },
      providesTags: ['LocaleConfig'],
    }),
    putTenantLocaleConfig: build.mutation<void, LocaleConfig>({
      query: (queryArgs) => ({
        url: `/dms/v1/settings/localization/tenant`,
        method: 'POST',
        body: {value: queryArgs, force: false},
      }),
      invalidatesTags: ['LocaleConfig'],
    }),
  }),
});

export const {
  useGetLocaleConfigQuery,
  useGetTenantLocaleConfigQuery,
  usePutTenantLocaleConfigMutation,
} = localeConfigApi;
