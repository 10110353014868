import {BaseVoidResponseSchema} from '../types/base';
import {
  BulkDeleteDirectSaleVariantRequest,
  BulkDeleteDirectSaleVariantRequestSchema,
  BulkDeleteDirectSaleVariantResponse,
  PostDirectSaleVariantRequest,
  PostDirectSaleVariantRequestSchema,
  PostDirectSaleVariantResponse,
  PostDirectSaleVariantResponseSchema,
  GetDirectSaleTypesRequest,
  GetDirectSaleTypesResponse,
  GetDirectSaleTypesResponseSchema,
  GetDirectSaleVariantRequest,
  GetDirectSaleVariantRequestSchema,
  GetDirectSaleVariantResponse,
  GetDirectSaleVariantResponseSchema,
  GetDirectSaleVariantsRequest,
  GetDirectSaleVariantsResponse,
  GetDirectSaleVariantsResponseSchema,
  PatchDirectSaleVariantRequest,
  PatchDirectSaleVariantRequestSchema,
  PatchDirectSaleVariantResponse,
  GetDirectSaleTypesRequestSchema,
  GetDirectSaleVariantsRequestSchema,
  PatchDirectSaleVariantResponseSchema,
} from '../types/metadaWarehouseDirectSaleVariant';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseDirectSaleVariantApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDirectSaleTypes: build.query<GetDirectSaleTypesResponse, GetDirectSaleTypesRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale-types`,
        method: 'GET',
        queryArg,
      }),
      providesTags: ['directSaleTypes'],
      extraOptions: {
        requestSchema: GetDirectSaleTypesRequestSchema,
        responseSchema: GetDirectSaleTypesResponseSchema,
      },
    }),
    getDirectSaleVariants: build.query<GetDirectSaleVariantsResponse, GetDirectSaleVariantsRequest>(
      {
        query: (queryArg) => ({
          url: `warehouse/v1/direct-sale-variants`,
          method: 'GET',
          queryArg,
        }),
        providesTags: ['directSaleVariants'],
        extraOptions: {
          requestSchema: GetDirectSaleVariantsRequestSchema,
          responseSchema: GetDirectSaleVariantsResponseSchema,
        },
      }
    ),
    getDirectSaleVariant: build.query<GetDirectSaleVariantResponse, GetDirectSaleVariantRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale-variant/${queryArg.directSaleVariantId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'directSaleVariants', id: queryArg.directSaleVariantId},
      ],
      extraOptions: {
        requestSchema: GetDirectSaleVariantRequestSchema,
        responseSchema: GetDirectSaleVariantResponseSchema,
      },
    }),
    postDirectSaleVariant: build.mutation<
      PostDirectSaleVariantResponse,
      PostDirectSaleVariantRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale-variant`,
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: ['directSaleVariants'],
      extraOptions: {
        requestSchema: PostDirectSaleVariantRequestSchema,
        responseSchema: PostDirectSaleVariantResponseSchema,
      },
    }),
    patchDirectSaleVariant: build.mutation<
      PatchDirectSaleVariantResponse,
      PatchDirectSaleVariantRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale-variant/${queryArg.directSaleVariantId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'directSaleVariants', id: queryArg.directSaleVariantId},
      ],
      extraOptions: {
        requestSchema: PatchDirectSaleVariantRequestSchema,
        responseSchema: PatchDirectSaleVariantResponseSchema,
      },
    }),
    bulkDeleteDirectSaleVariant: build.mutation<
      BulkDeleteDirectSaleVariantResponse,
      BulkDeleteDirectSaleVariantRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale-variant/delete`,
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: ['directSaleVariants'],
      extraOptions: {
        requestSchema: BulkDeleteDirectSaleVariantRequestSchema,
        responseSchema: BaseVoidResponseSchema,
      },
    }),
  }),
});

export const {
  useGetDirectSaleTypesQuery,
  useGetDirectSaleVariantsQuery,
  useGetDirectSaleVariantQuery,
  usePostDirectSaleVariantMutation,
  usePatchDirectSaleVariantMutation,
  useBulkDeleteDirectSaleVariantMutation,
} = metadaWarehouseDirectSaleVariantApi;
