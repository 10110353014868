import {AdaptiveImage, Box, Clickable, Hide, HStack, Show, VStack} from 'platform/foundation';

import {MouseEvent, useEffect, useState} from 'react';

import {isNil} from 'ramda';
import {isEmptyArray} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {EmptyStatus} from '../EmptyStatus/EmptyStatus';
import {Label} from '../Label/Label';
import {Upload, UploadProps} from '../Upload/Upload';
import {GridPhoto} from './types/GridPhoto';

const ITEM_WIDTH = 58;
const ITEM_HEIGHT = 43;

export interface PhotoGridProps extends TestIdProps {
  images: GridPhoto[];
  /**
   * When provided, upload card will be rendered as the last item in the grid.
   */
  uploadProps?: Omit<UploadProps, 'accept' | 'type' | 'buttonVariant' | 'data-testid'>;
  onContextMenu?: (event: MouseEvent, photos: GridPhoto[]) => void;
  onSlideClick: (image: GridPhoto, index: number) => void;
  isBulkSelectActive?: boolean;
}

export function PhotoGrid(props: PhotoGridProps) {
  const [selectedImages, setSelectedImages] = useState<GridPhoto[]>([]);

  useEffect(() => {
    setSelectedImages([]);
  }, [props.isBulkSelectActive]);

  const handleContextMenu = (image: GridPhoto) => (event: MouseEvent) => {
    if (isNil(props.onContextMenu)) {
      return;
    }
    event.preventDefault();
    props.onContextMenu(event, isEmptyArray(selectedImages) ? [image] : selectedImages);
  };

  const handleSelect = (image: GridPhoto, isSelected: boolean) =>
    isSelected
      ? setSelectedImages(selectedImages.filter((selectedImage) => selectedImage !== image))
      : setSelectedImages([...selectedImages, image]);

  const isEmpty = !props.images?.length && !props.uploadProps;

  return (
    <>
      <Show when={isEmpty}>
        <Box padding={4}>
          <EmptyStatus variant="image" data-testid={suffixTestId('emptyStatus', props)} />
        </Box>
      </Show>
      <Hide when={isEmpty}>
        <HStack wrap spacing={4}>
          {props.images.map((image, index) => {
            const isSelected = selectedImages.includes(image);
            return (
              <VStack spacing={1} key={image.id}>
                <Clickable
                  onClick={
                    props.isBulkSelectActive
                      ? () => handleSelect(image, isSelected)
                      : () => props.onSlideClick(image, index)
                  }
                  onContextMenu={handleContextMenu(image)}
                  data-testid={suffixTestId(`image-${index}`, props)}
                >
                  <Box
                    border="1px solid"
                    borderColor="palettes.neutral.30.100"
                    borderRadius="small"
                    overflow="hidden"
                    position="relative"
                  >
                    <AdaptiveImage
                      width={ITEM_WIDTH}
                      height={ITEM_HEIGHT}
                      fit="contain"
                      url={image.url}
                      makeUrl={image.makeUrl}
                      data-testid={suffixTestId(`image-${index}`, props)}
                    />
                    <Show when={props.isBulkSelectActive}>
                      <Box
                        position="absolute"
                        backgroundColor={
                          isSelected ? 'palettes.white.10.80' : 'palettes.white.10.40'
                        }
                        border={isSelected ? '3px solid' : undefined}
                        borderColor="palettes.blue.60.100"
                        borderRadius="small"
                        top={0}
                        bottom={0}
                        left={0}
                        right={0}
                      />
                    </Show>
                  </Box>
                </Clickable>
                <Label data-testid={suffixTestId(`image-${index}-label`, props)}>
                  {image.title}
                </Label>
              </VStack>
            );
          })}
          <Show when={props.uploadProps}>
            <Box width={ITEM_WIDTH} height={ITEM_HEIGHT}>
              <Upload
                accept="image/png, image/jpg, image/jpeg"
                type="card"
                size="minHeight"
                uploadIcon="image/camera_alt"
                {...props.uploadProps}
                data-testid={suffixTestId('upload', props)}
              />
            </Box>
          </Show>
        </HStack>
      </Hide>
    </>
  );
}
