import {Box, Center, HStack, Icon, Show} from 'platform/foundation';
import {css, useTheme} from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {always, defaultTo, path, pipe} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {ColorSchemeType} from '../Flag/types';
import {getColorsFromScheme, getFlagElementSizes} from '../Flag/utils';

export interface TrendProps extends TestIdProps {
  label: string;
  isNegative?: boolean;
  isPositive?: boolean;
  isSubtle?: boolean;
  isTransparent?: boolean;
  isSmall?: boolean;
  isReverseIcon?: boolean;
}

export function Trend(props: TrendProps) {
  const theme = useTheme();

  const colorScheme = match<[boolean | undefined, boolean | undefined], ColorSchemeType>([
    props.isPositive,
    props.isNegative,
  ])
    .with([true, Pattern.any], always('green'))
    .with([Pattern.any, true], always('red'))
    .otherwise(always('neutral'));

  const {backgroundColor, color} = getColorsFromScheme(
    colorScheme,
    props.isSubtle || props.isTransparent
  );
  const {height, textSize, labelHorizontalPadding} = getFlagElementSizes(
    props.isSmall ? 'small' : 'default'
  );

  const isPositive = props.isReverseIcon ? props.isNegative : props.isPositive;
  const isNegative = props.isReverseIcon ? props.isPositive : props.isNegative;

  return (
    <HStack justify="flex-start">
      <Box
        borderRadius="small"
        height={height}
        backgroundColor={props.isTransparent ? undefined : backgroundColor}
        paddingHorizontal={labelHorizontalPadding}
        data-testid={suffixTestId('trend', props)}
      >
        <Center height="100%">
          <HStack justify="space-between" align="center" height="100%" spacing={1}>
            <Show when={isPositive}>
              <Icon value="navigation/arrow_upward" size={3} color={color} />
            </Show>
            <Show when={isNegative}>
              <Icon value="navigation/arrow_downward" size={3} color={color} />
            </Show>
            <span
              css={css`
                color: ${pipe(
                  path<string>(['colors', ...color?.split('.')]),
                  defaultTo('transparent')
                )(theme)};
                font-size: ${theme.fontSizes.text[textSize]};
                line-height: ${theme.lineHeights.text[textSize]};
                white-space: nowrap;
                overflow: hidden;
                letter-spacing: 0;
              `}
            >
              {props.label}
            </span>
          </HStack>
        </Center>
      </Box>
    </HStack>
  );
}
