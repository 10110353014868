import {
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Text, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {
  CreateCostPresetRequestBody,
  useCreateCostPresetMutation,
  useCreateEarningPresetMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {handleApiError} from '../../utils/handleApiError';

interface CreateVehiclePresetFormProps extends TestIdProps {
  vehicleId: string;
  entity: 'earning' | 'cost';
  onCancel: VoidFunction;
  onSuccess: VoidFunction;
}

export function CreateVehiclePresetForm(props: CreateVehiclePresetFormProps) {
  const [createCostPreset] = useCreateCostPresetMutation();
  const [createEarningPreset] = useCreateEarningPresetMutation();

  const onSubmit: FormSubmitHandler<CreateCostPresetRequestBody> = async (body) => {
    const createPreset = 'cost' === props.entity ? createCostPreset : createEarningPreset;
    await createPreset({body, vehicleId: props.vehicleId})
      .unwrap()
      .then(() => {
        showNotification.success(
          i18n.t('entity.earningsCosts.notifications.presetCreated', {name: body.name})
        );
        props.onSuccess();
      })
      .catch(handleApiError);
  };

  return (
    <Form onSubmit={onSubmit} schema={schema}>
      {(control) => (
        <VStack spacing={4}>
          <FormField
            type="text"
            name="name"
            control={control}
            label={i18n.t('entity.earningsCosts.labels.preset')}
            data-testid={suffixTestId('name', props)}
          />
          <Text size="small" color="tertiary">
            {i18n.t('entity.earningsCosts.labels.createPresetHelper')}
          </Text>
          <ButtonGroup align="right">
            <FormButton
              variant="secondary"
              title={i18n.t('general.actions.cancel')}
              onClick={props.onCancel}
              control={control}
              data-testid={suffixTestId('cancel', props)}
            />
            <FormButton
              type="submit"
              variant="primary"
              control={control}
              title={i18n.t('general.actions.save')}
              data-testid={suffixTestId('submit', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = Yup.object().shape({
  name: Yup.string().required().max(150),
});
