import {Box, Show, Text} from 'platform/foundation';
import {css} from 'styled-components';

import {ReactElement} from 'react';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

type TPlatformLogoProps = {
  src: string;
  name: string;
};

export const PlatformLogo = ({src, name}: TPlatformLogoProps): ReactElement => (
  <>
    <Show when={isNotNil(src)}>
      <Box height={4} overflow="hidden">
        <img
          src={src}
          alt={name}
          css={css`
            height: 100%;
            object-fit: contain;
          `}
          data-testid={`logo-advertisement-platforms-${name}`}
        />
      </Box>
    </Show>
    <Show when={isNil(src)}>
      <Text data-testid={`logo-advertisement-platforms-${name}`}>{name}</Text>
    </Show>
  </>
);
