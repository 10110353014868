import {
  BreadcrumbType,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {object} from 'yup';

import {isNil} from 'ramda';
import {isFalse} from 'ramda-adjunct';

import {
  PaymentImportMode,
  useDeleteConfigurationPaymentImportModeMutation,
  useGetConfigurationPaymentImportModeQuery,
  usePatchConfigurationPaymentImportModeMutation,
  usePostConfigurationPaymentImportModeMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError, useBranches} from '@omnetic-dms/shared';

import {NullishBoolean, useNavigate, useRequiredParams, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {importTypeOptions} from '../TaxDocumentForPaymentImportTypeNew/utils/importTypeOptions';

type FormValues = {
  branchId: string | null;
  paymentImportMode: PaymentImportMode;
};

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.accountingSettings.labels.importType'),
    href: settingsRoutes.taxDocumentImportType,
  },
];

const SYSTEM_BRANCH_ID = 'undefinedBranches';

export function TaxDocumentForPaymentImportTypeDetail() {
  const {branchId} = useRequiredParams();
  const navigate = useNavigate();

  const isSystem = branchId === SYSTEM_BRANCH_ID;

  const {
    data: importTypeDefinition,
    isLoading: isLoadingDefinition,
    isError: isDefinitionError,
  } = useGetConfigurationPaymentImportModeQuery({branchId});

  const [updateImportTypeDefinition] = usePatchConfigurationPaymentImportModeMutation();
  const [createImportTypeDefinition] = usePostConfigurationPaymentImportModeMutation();
  const [deleteImportTypeDefinition] = useDeleteConfigurationPaymentImportModeMutation();

  const {
    data: branches,
    branchOptions,
    isLoading: isLoadingBranches,
    isError: isBranchesError,
  } = useBranches();

  const handleCreateNewDefinition = async (data: FormValues) => {
    const {branchId: formBranchId, paymentImportMode} = data;
    if (isNil(formBranchId)) {
      showNotification.error('invalid branch id');
      return;
    }

    await createImportTypeDefinition({
      body: {branchId: formBranchId, paymentImportMode},
    })
      .unwrap()
      .then(() => deleteImportTypeDefinition({branchId}))
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.taxDocumentImportType))
      .catch(handleApiError);
  };

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    if (data.branchId !== branchId) {
      await handleCreateNewDefinition(data);
    } else {
      await updateImportTypeDefinition({
        branchId,
        body: {paymentImportMode: data.paymentImportMode},
      })
        .unwrap()
        .then(() => showNotification.success())
        .then(() => navigate(settingsRoutes.taxDocumentImportType))
        .catch(handleApiError);
    }
  };

  const isLoading = isLoadingBranches || isLoadingDefinition;
  const isError = isBranchesError || isDefinitionError;

  const branchName =
    branches?.branchListItems.find((branch) => branch.id === branchId)?.marketingName ??
    i18n.t('page.accounting.labels.tenantDefinition');

  const title = `${branchName} - ${i18n.t(`page.accountingSettings.labels.${importTypeDefinition?.paymentImportMode}`)}`;

  const defaultValues = importTypeDefinition
    ? {
        branchId: importTypeDefinition.branchId ?? undefined,
        paymentImportMode: importTypeDefinition.paymentImportMode ?? undefined,
      }
    : undefined;

  return (
    <SettingsTemplate
      header={{
        title,
        breadcrumbs,
      }}
      data-testid={testIds.settings.taxDocumentIssuedTypeDetail('page')}
      isLoading={isLoading}
      isError={isError}
    >
      <Form<FormValues>
        onSubmit={handleSubmit}
        schema={getSchema(isSystem)}
        defaultValues={defaultValues}
      >
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  isNotClearable
                  options={branchOptions}
                  label={i18n.t('entity.cashRegister.parameters.branch')}
                  data-testid={testIds.settings.taxDocumentImportTypeDetail('branchId')}
                  isRequired
                  isDisabled={isSystem}
                  placeholder={
                    isSystem ? i18n.t('page.accounting.labels.tenantDefinition') : undefined
                  }
                />
                <FormField
                  control={control}
                  type="choice"
                  options={importTypeOptions}
                  name="paymentImportMode"
                  data-testid={testIds.settings.taxDocumentImportTypeDetail('paymentImportMode')}
                  isNotClearable
                  isRequired
                  label={i18n.t('page.accountingSettings.labels.importType')}
                />
              </Grid>
            </SettingsSection>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.taxDocumentImportType),
                  variant: 'secondary',
                  'data-testid': testIds.settings.taxDocumentImportTypeDetail('discard'),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                  'data-testid': testIds.settings.taxDocumentImportTypeDetail('save'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const getSchema = (isSystem: NullishBoolean) =>
  object({
    branchId: yupString.when([], {
      is: () => isFalse(isSystem),
      then: (schema) => schema.required(),
    }),
    paymentImportMode: yupString.required(),
  });
