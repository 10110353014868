import {Card, Segment, SegmentProps, Separator, DataStatus} from 'platform/components';
import {Box, HStack, VStack, Heading, Text, Display, Space, Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState, Fragment} from 'react';

import {always} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetPurchaseFunnelQuery, useGetSaleFunnelQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {TestIdProps} from 'shared';

import {SALES_FUNNEL_GRAPH_BAR_HEIGHT} from '../consts/salesFunnelGraphBarHeight';
import {SEGMENT_MIN_WIDTH} from '../consts/segmentMinWidth';
import {createOpportunitiesFunnelColumns} from '../utils/createOpportunitiesFunnelColumns';

interface OpportunitiesFunnelProps extends TestIdProps {
  branchId: string | undefined;
  isFunnelInterestsEnabled: boolean;
  isFunnelBusinessCasesEnabled: boolean;
}

export function OpportunitiesFunnel(props: OpportunitiesFunnelProps) {
  const [ownerSegment, setOwnerSegment] = useState<OwnerSegment>('total');
  const [typeSegment, setTypeSegment] = useState<TypeSegment>('selling');

  const {
    data: purchaseFunnelData,
    isLoading: isPurchaseFunnelLoading,
    isError: isPurchaseFunnelError,
  } = useGetPurchaseFunnelQuery(
    {
      branchId: props.branchId,
      onlyMine: ownerSegment === 'my',
    },
    {skip: typeSegment === 'selling', refetchOnMountOrArgChange: true}
  );

  const {
    data: saleFunnelData,
    isLoading: isSaleFunnelLoading,
    isError: isSaleFunnelError,
  } = useGetSaleFunnelQuery(
    {
      branchId: props.branchId,
      onlyMine: ownerSegment === 'my',
    },
    {skip: typeSegment === 'buying', refetchOnMountOrArgChange: true}
  );

  const isLoading = match(typeSegment)
    .with('selling', always(isSaleFunnelLoading))
    .with('buying', always(isPurchaseFunnelLoading))
    .otherwise(always(false));

  const isError = match(typeSegment)
    .with('selling', always(isSaleFunnelError))
    .with('buying', always(isPurchaseFunnelError))
    .otherwise(always(false));

  let columns: ReturnType<typeof createOpportunitiesFunnelColumns> = [];

  if (!isLoading && !isError) {
    columns = match(typeSegment)
      .with(
        'selling',
        always(
          createOpportunitiesFunnelColumns(
            saleFunnelData,
            props.isFunnelInterestsEnabled,
            props.isFunnelBusinessCasesEnabled
          )
        )
      )
      .with(
        'buying',
        always(
          createOpportunitiesFunnelColumns(
            purchaseFunnelData,
            props.isFunnelInterestsEnabled,
            props.isFunnelBusinessCasesEnabled
          )
        )
      )
      .otherwise(always([]));
  }

  return (
    <Card>
      <VStack spacing={4}>
        <HStack>
          <Heading size={4}>{i18n.t('page.homepage.labels.currentOpportunities')}</Heading>
          <Space fillAvailable />
          <HStack spacing={2}>
            <Segment
              value={ownerSegment}
              options={ownerSegments}
              onChange={(segment) => setOwnerSegment(segment)}
              minWidth={SEGMENT_MIN_WIDTH}
            />
            <Segment
              value={typeSegment}
              options={typeSegments}
              onChange={(segment) => setTypeSegment(segment)}
              minWidth={SEGMENT_MIN_WIDTH}
            />
          </HStack>
        </HStack>

        <DataStatus
          minHeight="100%"
          isLoading={isLoading}
          isError={isError}
          isEmpty={isNilOrEmpty(columns)}
        >
          <HStack>
            {columns.map<React.ReactNode>((column, index) => {
              const isLast = index !== columns.length - 1;
              return (
                <Fragment key={column.title}>
                  <VStack basis="100%" align="stretch" spacing={4}>
                    <VStack spacing={4} justify="space-between" grow={1}>
                      <Text color="secondary" size="xSmall">
                        {column.title}
                      </Text>
                      <Display size={1}>{String(column.value)}</Display>
                    </VStack>
                    <HStack height={SALES_FUNNEL_GRAPH_BAR_HEIGHT} grow={0} align="flex-end">
                      <Box flex={1} height={column.height} backgroundColor={column.color} />
                    </HStack>
                  </VStack>
                  <Show when={isLast}>
                    <Separator spacing={4} orientation="vertical" />
                  </Show>
                </Fragment>
              );
            })}
          </HStack>
        </DataStatus>
      </VStack>
    </Card>
  );
}

type OwnerSegment = 'total' | 'my';
type TypeSegment = 'selling' | 'buying';

const ownerSegments: NonNullable<SegmentProps<OwnerSegment>['options']> = [
  {
    label: i18n.t('page.homepage.labels.total'),
    value: 'total',
  },
  {
    label: i18n.t('page.homepage.labels.my'),
    value: 'my',
  },
];

const typeSegments: NonNullable<SegmentProps<TypeSegment>['options']> = [
  {
    label: i18n.t('page.homepage.labels.selling'),
    value: 'selling',
  },
  {
    label: i18n.t('page.homepage.labels.buying'),
    value: 'buying',
  },
];
