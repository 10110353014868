import {getMonth, getYear} from 'date-fns';
import {SchemaOf, string, object, mixed, boolean, number, array} from 'yup';

import i18n from '@omnetic-dms/i18n';

import {parseDate} from 'shared';

import {$AdditionalInformationRequestBody} from '../../../utils/$AdditionalInformationRequestBody';
import {$DimensionsRequestBody} from '../../../utils/$DimensionsRequestBody';
import {$EngineRequestBody} from '../../../utils/$EngineRequestBody';
import {$ModelSpecificationRequestBody} from '../../../utils/$ModelSpecificationRequestBody';
import {$StateRequestBody} from '../../../utils/$StateRequestBody';
import {$VehiclePhotoRequestBody} from '../../../utils/$VehiclePhotoRequestBody';
import {VehicleCreateFormState} from './VehicleCreateFormState';

const transformEmptyToNull = (str: string) => {
  if (str) {
    return str;
  }
  return null;
};

const vinRegex = /^[A-Ha-hJ-Nj-nPpR-Zr-z0-9]{17}$/;

export const $VehicleBatterySection: SchemaOf<VehicleCreateFormState['battery']> = object().shape({
  chargingAC: number().nullable().default(null),
  chargingTimeAC: number().nullable().default(null),
  chargingDC: number().nullable().default(null),
  chargingTimeDC: number().nullable().default(null),
  batteryType: string().nullable().default(null),
  batteryCapacityWh: number().nullable().default(null),
  batteryCount: number().nullable().default(null),
  chargingConnectorType: string().nullable().default(null),
  electricRange: number().nullable().default(null),
  batteryMileageLimit: number().nullable().default(null),
  batteryWarrantyUntil: mixed()
    .test(
      'date',
      i18n.t('general.notifications.invalidDate'),
      (value) => !value || parseDate(value) !== undefined
    )
    .default(null),
  batteryOwnershipType: string().nullable().default(null),
  batteryCapacityAh: number().nullable().default(null),
  batteryVoltage: number().nullable().default(null),
  hasRangeExtender: boolean().nullable().default(null),
});

// eslint-disable-next-line no-restricted-syntax
export const $VehicleCreateFormState: SchemaOf<VehicleCreateFormState> = object()
  .shape({
    additionalInformation: $AdditionalInformationRequestBody.nullable(),
    bodyStyle: string().nullable(),
    currencyCode: string().nullable(),
    customMake: string().nullable(),
    customModelFamily: string().nullable(),
    dimensions: $DimensionsRequestBody.defined(),
    doorCountCategory: string().nullable(),
    drive: string().nullable(),
    engine: $EngineRequestBody.defined(),
    externalId: string().nullable(),
    features: array().of(mixed()),
    firstRegistrationDate: mixed()
      .test(
        'date',
        i18n.t('general.notifications.invalidDate'),
        (value) => !value || parseDate(value) !== undefined
      )
      .nullable(),
    fuelType: string().nullable(),
    handDriveType: string().nullable(),
    isArchived: boolean().nullable(),
    isVatDeductible: boolean().nullable(),
    make: string().nullable().required(i18n.t('general.notifications.requiredMake')),
    manufacturedOnMonth: number().nullable(),
    manufacturedOnYear: number().nullable(),
    modelFamily: string().nullable().required(i18n.t('general.notifications.requiredModel')),
    modelSpecification: $ModelSpecificationRequestBody.defined(),
    photos: array().defined().of($VehiclePhotoRequestBody.required()),
    power: number().nullable(),
    purchasePrice: string().nullable(),
    purchasePriceVatRate: string().nullable(),
    purchasePriceWithVat: string().nullable(),
    secondaryFuelType: string().nullable(),
    axleCount: number().nullable(),
    sellingPrice: string().nullable(),
    sellingPriceVatRate: string().nullable(),
    sellingPriceWithVat: string().nullable(),
    source: string().nullable(),
    state: $StateRequestBody.defined(),
    transmission: string().nullable(),
    trim: string().trim().nullable(),
    type: mixed().required(),
    variant: string().trim().nullable(),
    vin: string()
      .nullable()
      .matches(vinRegex, {
        excludeEmptyString: true,
        message: i18n.t('general.notifications.vinValidationMessage'),
      }),
    title: string()
      .transform(transformEmptyToNull)
      .max(100, i18n.t('general.validations.maximumLength', {codeMaxLength: 100}))
      .trim()
      .nullable(),
    battery: $VehicleBatterySection.nullable(),
  })
  .transform((values, origin, schema) => {
    const {firstRegistrationDate, manufacturedOnYear} = origin;

    if (firstRegistrationDate) {
      const registrationDate = parseDate(firstRegistrationDate);
      const firsRegistrationMonth = getMonth(registrationDate) + 1;
      const firsRegistrationYear = getYear(registrationDate);

      schema.fields.manufacturedOnYear = number().max(
        firsRegistrationYear,
        i18n.t('general.notifications.manufacturedYearHigherThanRegistrationYear')
      );

      if (firsRegistrationYear <= Number(manufacturedOnYear)) {
        schema.fields.manufacturedOnMonth = number().max(
          firsRegistrationMonth,
          i18n.t('general.notifications.manufacturedMonthHigherThanRegistrationMonth')
        );
      } else {
        schema.fields.manufacturedOnMonth = number().nullable();
      }
      return values;
    }
    schema.fields.manufacturedOnMonth = number();
    schema.fields.manufacturedOnYear = number();
    return values;
  }) as unknown as SchemaOf<VehicleCreateFormState>;
