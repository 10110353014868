import {TRANSLATED_LANGUAGES} from '../types/CommonAppTypes';

export const getNameAccordingToLocale = (
  locale: TRANSLATED_LANGUAGES,
  name: Record<string, string>
) => {
  switch (locale) {
    case TRANSLATED_LANGUAGES.czech:
      return name.cs;
    case TRANSLATED_LANGUAGES.slovak:
      return name.sk;
    case TRANSLATED_LANGUAGES.germany:
      return name.de;
    case TRANSLATED_LANGUAGES.france:
      return name.fr;
    case TRANSLATED_LANGUAGES.spain:
      return name.es;
    case TRANSLATED_LANGUAGES.poland:
      return name.pl;
    case TRANSLATED_LANGUAGES.italy:
      return name.it;
    default:
      return name.en;
  }
};
