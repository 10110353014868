import {Flag, ColorSchemeType} from 'platform/components';

import {FC, useMemo} from 'react';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {CashRegisterStateEnum} from '../types/CashRegisterStateEnum';

export type CashRegisterStateTagProps = {
  state: `${CashRegisterStateEnum}` | CashRegisterStateEnum;
};

export const CashRegisterStateTag: FC<CashRegisterStateTagProps> = ({state}) => {
  let label;
  const colorScheme = useMemo<ColorSchemeType>(() => getCashRegisterStateFlagColor(state), [state]);

  switch (state) {
    case CashRegisterStateEnum.ACTIVE:
      label = i18n.t('entity.cashRegister.state.active');
      break;
    case CashRegisterStateEnum.NOT_ACTIVE:
      label = i18n.t('entity.cashRegister.state.notActive');
      break;
    case CashRegisterStateEnum.DELETED:
      label = i18n.t('general.labels.deleted');
      break;
    default:
      return null;
  }

  return (
    <Flag
      isSubtle
      colorScheme={colorScheme}
      label={label}
      data-testid={testIds.settings.cashRegisters('cashRegisterFlag')}
    />
  );
};

const getCashRegisterStateFlagColor = (
  state: `${CashRegisterStateEnum}` | CashRegisterStateEnum
): ColorSchemeType => {
  switch (state) {
    case CashRegisterStateEnum.ACTIVE:
      return 'green';
    case CashRegisterStateEnum.NOT_ACTIVE:
      return 'neutral';
    case CashRegisterStateEnum.DELETED:
      return 'red';
    default:
      return 'neutral';
  }
};
