import {useState} from 'react';

import {isNegative, isNilOrEmpty, isNonEmptyString, isNumber} from 'ramda-adjunct';

import {Nullish, useQueryState} from 'shared';

import {platformLightboxQueryParams} from '../consts/platformLightboxQueryParams';
import {InternalLightboxControls} from '../types/InternalLightboxControls';
import {LightboxSlide} from '../types/LightboxSlide';
import {useApplicationScrollLock} from './useApplicationScrollLock';

/**
 * @description These props are only for controlling the Lightbox component from the outside.
 * We recommend using them for manipulating with the Lightbox component from application.
 */
type LightboxControls = {
  /**
   * @description onOpen opens the lightbox at the first step
   * @param {number} openAtIndex - sets the active slide index in the array where the lightbox is to be opened
   * @param {boolean} isOpenInGridView - determines whether the lightbox should be opened in grid view
   */
  onOpen: (openAtIndex?: number | Nullish, isOpenInGridView?: boolean) => void;
  /**
   * @description This handler serves as a utility for opening lightbox to a specific slide
   * @param {LightboxSlide} slide - the slide on which we want to open the lightbox
   * @param {LightboxSlide[] | Nullish} slides - the list of slides in which we will search for the index at which the lightbox will be opened
   * @param {boolean} isOpenInGridView - determines whether the lightbox should be opened in grid view
   */
  onOpenBySlide: (
    slide: LightboxSlide,
    slides?: LightboxSlide[] | Nullish,
    isOpenInGridView?: boolean
  ) => void;
  /**
   * @description onDetailOpen opens the lightbox at the second step on the detail
   * @param {number} openAtIndex - sets the active slide index in the array where the lightbox detail is to be opened
   * @param {boolean} isOpenInGridView - determines whether the lightbox should be opened in grid view
   */
  onDetailOpen: (index: number, isOpenInGridView?: boolean) => void;
  /**
   * @description This handler serves as a utility for opening lightbox detail to a specific slide
   * @param {LightboxSlide} slide - the slide on which we want to open the lightbox detail
   * @param {LightboxSlide[] | Nullish} slides - the list of slides in which we will search for the index at which the lightbox will be opened
   * @param {boolean} isOpenInGridView - determines whether the lightbox should be opened in grid view
   */
  onDetailOpenBySlide: (
    slide: LightboxSlide,
    slides?: LightboxSlide[] | Nullish,
    isOpenInGridView?: boolean
  ) => void;
  onClose: () => void;
};

export type UseLightboxReturnType = [InternalLightboxControls, LightboxControls];

/**
 * @description This hook is used for controlling the lightbox.
 * The main input is an ID, which tells us which lightbox to open.
 * Additionally, there are controls through which metadata is passed directly to the lightbox component.
 * The entire hook is controlled through queryParams, which are then sent down to the Lightbox component.
 * @param {string} id - registration id that identifies a specific lightbox and is only required param of the useLightbox hook
 * @example
 *  const [lightboxControls, {onOpen, onOpenBySlide, onDetailOpen, onDetailOpenBySlide, onClose}] = useLightbox('platformLightbox');
 *
 *  <Lightbox data-testid={testIds('lightbox')} controls={lightboxControls} images={images} videos={videos} />
 */
export function useLightbox(id: string): UseLightboxReturnType {
  if (isNilOrEmpty(id)) {
    throw new Error("Lightbox can't work without id");
  }

  const [lightboxId, setLightboxId, clearLightboxId] = useQueryState(
    platformLightboxQueryParams.LIGHTBOX_ID
  );

  const [activeDetailIndex, setActiveDetailIndex, clearActiveDetailIndex] = useQueryState(
    platformLightboxQueryParams.LIGHTBOX_DETAIL_INDEX
  );

  const [isGridViewEnabled, setGridViewEnabled, clearGridView] = useQueryState(
    platformLightboxQueryParams.LIGHTBOX_GRID_VIEW
  );

  const [gridScale, setGridScale, clearGridScale] = useQueryState(
    platformLightboxQueryParams.LIGHTBOX_GRID_SCALE
  );

  const [initialIndex, setInitialIndex] = useState(Number(activeDetailIndex) ?? 0);

  const isForceLocked = isNonEmptyString(activeDetailIndex) || id === lightboxId;
  const {lockApplicationScroll, unlockApplicationScroll} = useApplicationScrollLock(isForceLocked);

  const onOpen = (openAtIndex?: number | Nullish, isOpenInGridView?: boolean) => {
    setLightboxId(id);
    /**
     * Double check that openAtIndex is indeed a number. This check protects against scenarios where an event object,
     * for example from an onClick handler, is inserted into the first param
     */
    const index = isNumber(openAtIndex) ? openAtIndex : 0;
    setInitialIndex(index);
    if (isOpenInGridView) {
      setGridViewEnabled('true');
    }
    lockApplicationScroll();
  };

  const onDetailOpen = (openAtIndex: number, isOpenInGridView?: boolean) => {
    /**
     * Double check that openAtIndex is indeed a number. This check protects against scenarios where an event object,
     * for example from an onClick handler, is inserted into the first param
     */
    const index = isNumber(openAtIndex) ? openAtIndex : 0;
    onOpen(index);
    setActiveDetailIndex(index.toString());
    if (isOpenInGridView) {
      setGridViewEnabled('true');
    }
  };

  const onClose = () => {
    clearLightboxId();
    clearActiveDetailIndex();
    clearGridView();
    clearGridScale();
    setInitialIndex(0);
    unlockApplicationScroll();
  };

  const onViewChange = () => {
    if (isGridViewEnabled === 'true') {
      clearGridView();
    } else {
      setGridViewEnabled('true');
    }
  };

  const onGridScaleChange = (scale: number) => {
    setGridScale(scale.toString());
  };

  const onInternalDetailOpen = (index: number) => {
    setActiveDetailIndex(index.toString());
  };

  const onDetailClose = (initialIndex: number) => {
    setInitialIndex(initialIndex);
    clearActiveDetailIndex();
  };

  const onOpenBySlide = (
    slide: LightboxSlide,
    slides: LightboxSlide[] | Nullish,
    isOpenInGridView?: boolean
  ) => {
    const index = slides?.findIndex((s) => s.id === slide?.id) ?? 0;
    onOpen(isNegative(index) ? 0 : index);
    if (isOpenInGridView) {
      setGridViewEnabled('true');
    }
  };

  const onDetailOpenBySlide = (
    slide: LightboxSlide,
    slides: LightboxSlide[] | Nullish,
    isOpenInGridView?: boolean
  ) => {
    const index = slides?.findIndex((s) => s.id === slide?.id) ?? 0;
    onDetailOpen(isNegative(index) ? 0 : index);
    if (isOpenInGridView) {
      setGridViewEnabled('true');
    }
  };

  const internalLightboxControls = {
    lightboxId,
    passedLightboxId: id,
    initialIndex,
    activeDetailIndex: isNonEmptyString(activeDetailIndex) ? Number(activeDetailIndex) : null,
    isGridViewEnabled: isGridViewEnabled === 'true',
    gridScale: isNonEmptyString(gridScale) ? Number(gridScale) : 0,
    onViewChange,
    onGridScaleChange,
    onDetailOpen: onInternalDetailOpen,
    onDetailClose,
    onClose,
  } as const;

  const lightboxPublicControls = {
    onOpen,
    onOpenBySlide,
    onDetailOpen,
    onDetailOpenBySlide,
    onClose,
  } as const;

  return [internalLightboxControls, lightboxPublicControls];
}
