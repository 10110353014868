import * as Yup from 'yup';

import {isArray, isNotNilOrEmpty} from 'ramda-adjunct';

import {yupString} from 'shared';

export const yupIdentityCardSchema = Yup.object().shape({
  id: yupString.optional(),
  cardData: Yup.object().shape({
    type: yupString.optional(),
    issuedOn: yupString.optional(),
    validUntil: yupString.optional(),
    issuer: yupString.optional(),
    issuedInCountryCode: yupString.optional(),
    note: yupString.optional(),
    cardNumber: yupString.when(
      [
        'type',
        'issuedOn',
        'note',
        'issuedInCountryCode',
        'issuer',
        'validUntil',
        'issuedOn',
        'type',
      ],
      {
        is: (values: string[]) =>
          isArray(values) ? values?.some(isNotNilOrEmpty) : isNotNilOrEmpty(values),
        then: (schema) => schema.required().min(3),
        otherwise: (schema) => schema.optional(),
      }
    ),
  }),
});
