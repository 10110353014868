import {Button, ButtonGroup, Card, FormControl, FormField, IconButton} from 'platform/components';
import {Grid, GridItem, Hide, VStack} from 'platform/foundation';

import {UseFormReturn, useFieldArray, useWatch} from 'react-hook-form';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {TestIdProps, suffixTestId} from 'shared';

import type {ProtectedUnitWithPermission} from '../hooks/useGetACLData';
import {EMPTY_SCOPE} from '../utils/consts';

export type FormValues = {
  scopes: {
    scopeId: string;
    optionIds: string[];
  }[];
};
interface ScopeListProps extends TestIdProps {
  control: FormControl<FormValues>;
  formApi: UseFormReturn<FormValues>;
  protectedUnit: ProtectedUnitWithPermission;
  resourceId: string;
}

export function ScopeList(props: ScopeListProps) {
  const {fields, append, remove} = useFieldArray({control: props.control, name: 'scopes'});

  const currentScopes = useWatch({control: props.control, name: 'scopes'});

  const getAvailableScopes = (index: number) =>
    props.protectedUnit.scopes
      .filter(
        (scope) =>
          !currentScopes.some((currentScope, i) => currentScope.scopeId === scope.id && index !== i)
      )
      .map((item) => ({label: item.name, value: item.id}));

  const isRowScopeIdNotSelected = (index: number) => isNilOrEmpty(currentScopes[index]?.scopeId);

  const scopeOptionsForScopeId = (index: number) => {
    const scopeId = currentScopes?.[index]?.scopeId;
    if (isNilOrEmpty(scopeId)) {
      return [];
    }

    const scope = props.protectedUnit.scopes.find((item) => item.id === scopeId);
    if (isNil(scope)) {
      return [];
    }

    return scope.options.map((option) => ({label: option.name, value: option.id}));
  };

  return (
    <Card variant="inlineGrey">
      <VStack spacing={2}>
        {fields.map((field, index) => (
          <Grid columns={17} key={field.id} align="flex-end">
            <GridItem span={8}>
              <FormField
                control={props.control}
                name={`scopes.${index}.scopeId`}
                type="choice"
                label={i18n.t('acl.dialog.label.scopeType')}
                isRequired
                isNotClearable
                options={getAvailableScopes(index)}
                data-testid={suffixTestId(`[${index}]-scopeId`, props)}
              />
            </GridItem>
            <GridItem span={8}>
              <FormField
                control={props.control}
                name={`scopes.${index}.optionIds`}
                type="multiChoice"
                label={i18n.t('acl.dialog.label.scopeValue')}
                isRequired
                isDisabled={isRowScopeIdNotSelected(index)}
                options={scopeOptionsForScopeId(index)}
                data-testid={suffixTestId(`[${index}]-optionIds`, props)}
              />
            </GridItem>
            <Hide when={fields.length === 1}>
              <GridItem span={1}>
                <IconButton
                  icon="action/delete"
                  onClick={() => remove(index)}
                  severity="danger"
                  data-testid={suffixTestId(`[${index}]-deleteButton`, props)}
                />
              </GridItem>
            </Hide>
          </Grid>
        ))}
        <Hide when={fields.length === props.protectedUnit.scopes.length}>
          <ButtonGroup>
            <Button
              variant="link"
              leftIcon="content/add_circle"
              title={i18n.t('general.actions.add')}
              onClick={() => append(EMPTY_SCOPE)}
              data-testid={suffixTestId(`submit`, props)}
            />
          </ButtonGroup>
        </Hide>
      </VStack>
    </Card>
  );
}
