import {GridOptions} from '@ag-grid-community/core';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {TransformedDefinitionApiResponse} from '../types/Api';

export const getRowSelection = (definition: TransformedDefinitionApiResponse) =>
  match<TransformedDefinitionApiResponse['behavior']['rowSelectMode'], GridOptions['rowSelection']>(
    definition.behavior.rowSelectMode
  )
    .with('SINGLE', always('single'))
    .with('MULTIPLE', always('multiple'))
    .otherwise(always(undefined));
