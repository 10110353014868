import {number, object, SchemaOf} from 'yup';

import {DimensionsSpecificationRequestBody} from '../types/DimensionsSpecificationRequestBody';

export const $DimensionsSpecificationRequestBody: SchemaOf<DimensionsSpecificationRequestBody> =
  object().shape({
    height: number().nullable(),
    length: number().nullable(),
    width: number().nullable(),
  });
