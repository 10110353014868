import {FC, useRef, PropsWithChildren, MutableRefObject} from 'react';

import {validationCheckerContext} from './validationCheckerContext';

export interface ValidationCheckerProviderProps {
  onChange: (isValid: boolean) => void;
}

export const ValidationCheckerProvider: FC<PropsWithChildren<ValidationCheckerProviderProps>> = ({
  onChange,
  children,
}) => {
  const state = useRef<MutableRefObject<HTMLElement> | Record<string, unknown>>({});

  const handleSetValue = (key: string, value: boolean) => {
    (state.current as Record<string, boolean>)[key] = value;
    checkState();
  };

  const checkState = () => {
    const isFalse = Object.values(state.current).some((value) => !value);

    onChange(!isFalse);
  };

  return (
    <validationCheckerContext.Provider value={{setValue: handleSetValue}}>
      {children}
    </validationCheckerContext.Provider>
  );
};
