import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  OptionType,
  Switch,
  closeCurrentDialog,
  openDialog,
} from 'platform/components';
import {Box, Heading, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  useGetMetadaServiceCaseQuery,
  useGetServiceOrderItemsDiscountQuery,
  usePutServiceOrderCustomerContractChangeMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId, useToggle} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, RowData} from 'features/datagrid';

import {DeactivateDiscounts} from './DeactivateDiscounts';

type FormType = {discount: 'apply' | 'dontApply'};

const options: OptionType<FormType['discount']>[] = [
  {
    label: i18n.t('entity.customerContract.labels.applyDiscounts'),
    value: 'apply',
  },
  {
    label: i18n.t('entity.customerContract.labels.dontApplyDiscounts'),
    value: 'dontApply',
  },
];

interface AssignContractProps extends TestIdProps {
  serviceCaseId: string;
  orderId: string;
  onClose: VoidFunction;
  isChange?: true;
  onContractSelect: (customerContractId: string) => void;
}

export function AssignContract(props: AssignContractProps) {
  const {data: serviceCase} = useGetMetadaServiceCaseQuery({serviceCaseId: props.serviceCaseId});
  const {data: orderDiscount} = useGetServiceOrderItemsDiscountQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.orderId,
  });

  const [putServiceOrderCustomerContractChange] =
    usePutServiceOrderCustomerContractChangeMutation();

  const [selectedContractId, setSelectedContractId] = useState<string | null>(null);
  const [isShowingAllContracts, toggleIsShowingAllContracts] = useToggle();

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {customerId: isShowingAllContracts ? undefined : serviceCase?.customerId}]);

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    if (actionKey !== 'detail') {
      return;
    }

    const id = isArray(rowId) ? rowId[0] : rowId;

    props.onContractSelect(id);
  };

  const handleContractChange = (data: FormType) =>
    putServiceOrderCustomerContractChange({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.orderId,
      body: {
        customerContractId: selectedContractId ?? '',
        isDiscountApply: data.discount === 'apply',
      },
    })
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);

  const handleSubmit: FormSubmitHandler<FormType> = async (data) => {
    if (isNil(orderDiscount)) {
      return await handleContractChange(data);
    }

    props.onClose();
    openDialog(
      <DeactivateDiscounts
        onClose={closeCurrentDialog}
        onSubmitted={() => handleContractChange(data)}
        serviceCaseId={props.serviceCaseId}
        serviceOrderId={props.orderId}
        data-testid={suffixTestId('deactivateCustomDiscount', props)}
      />,
      {
        title: i18n.t('entity.order.labels.deactivateCustomDiscounts'),
        size: 'small',
      }
    );
  };

  return (
    <VStack spacing={4}>
      <Switch
        value={isShowingAllContracts}
        onChange={toggleIsShowingAllContracts}
        label={i18n.t('entiry.customerContract.labels.allCustomerContracts')}
        data-testid={suffixTestId('allContracts', props)}
      />
      <Box height={135}>
        <DataGrid
          // DG must be re-rendered on state change to update the query modifier
          // eslint-disable-next-line no-restricted-syntax
          key={isShowingAllContracts.toString()}
          gridCode="customer-contract-selection"
          actionCallback={actionCallback}
          onRowSelectionChange={(data: RowData[]) => setSelectedContractId(data[0]?.id ?? null)}
          queryModifier={queryModifier}
          data-testid={suffixTestId('dataGrid', props)}
        />
      </Box>
      <Heading size={4}>{i18n.t('entity.customerContract.labels.assignDescription')}</Heading>
      <Form<FormType> defaultValues={{discount: 'apply'}} onSubmit={handleSubmit}>
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="discount"
              type="radio"
              options={options}
              data-testid={suffixTestId('discount', props)}
            />
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                onClick={props.onClose}
              />
              <FormButton
                control={control}
                type="submit"
                title={
                  props.isChange
                    ? i18n.t('entity.order.actions.changeContract')
                    : i18n.t('entity.order.actions.assignContract')
                }
                isDisabled={isNil(selectedContractId)}
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </VStack>
  );
}
