import {Avatar, Dropdown, IconButton} from 'platform/components';
import {Box, Center, Scroll, Show} from 'platform/foundation';

import {featureFlags} from '@omnetic-dms/feature-flags';
import {NotificationsWidget, useNotifications} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {HeaderButtonWrapper} from '../HeaderButtonWrapper/HeaderButtonWrapper';
import {HeaderButtonWrapper as HeaderButtonWrapperV2} from '../LayoutV2/components/Topbar/HeaderButtonWrapper';

const NOTIFICATION_PAGE_SIZE = 20;

interface NotificationsPopupProps extends TestIdProps {}

export function NotificationsPopup(props: NotificationsPopupProps) {
  const [isOpen, setOpen, setClosed] = useBoolean();

  const {
    data: notificationData,
    isLoading,
    isFetching,
    isError,
    hasUnreadNotifications,
  } = useNotifications(NOTIFICATION_PAGE_SIZE);

  return (
    <Dropdown
      closeOnSelect
      closeOnBlur
      onClose={setClosed}
      onOpen={setOpen}
      dropdownControl={
        <>
          <Show whenFeatureDisabled={featureFlags.SIDEBAR}>
            <HeaderButtonWrapper
              $isOpen={isOpen}
              data-testid={suffixTestId('notificationsPopup-button', props)}
            >
              <Center width="100%" height="100%">
                <Box position="relative">
                  <Avatar variant="circle" size="small" icon="social/notifications" />
                  <Show when={hasUnreadNotifications}>
                    <Box
                      position="absolute"
                      top={0}
                      right={0}
                      backgroundColor="severity.danger"
                      width={2}
                      height={2}
                      borderRadius="circular"
                    />
                  </Show>
                </Box>
              </Center>
            </HeaderButtonWrapper>
          </Show>
          <Show whenFeatureEnabled={featureFlags.SIDEBAR}>
            <Center height={12}>
              <HeaderButtonWrapperV2 $isOpen={isOpen}>
                <IconButton
                  size="small"
                  icon="social/notifications"
                  data-testid={suffixTestId('notificationsPopup-button', props)}
                />
                <Show when={hasUnreadNotifications}>
                  <Box
                    position="absolute"
                    top={1}
                    right={1}
                    backgroundColor="severity.danger"
                    width={2}
                    height={2}
                    borderRadius="circular"
                  />
                </Show>
              </HeaderButtonWrapperV2>
            </Center>
          </Show>
        </>
      }
      placement="bottom-end"
      isLazy
      lazyBehavior="unmount"
      data-testid={suffixTestId('notificationsPopup', props)}
    >
      <Scroll maxHeight="calc(100vh - 100px)" auto>
        <Box width={120}>
          <NotificationsWidget
            notificationData={notificationData}
            isLoading={isLoading}
            isError={isError}
            isFetching={isFetching}
            pageSize={NOTIFICATION_PAGE_SIZE}
          />
        </Box>
      </Scroll>
    </Dropdown>
  );
}
