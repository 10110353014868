import styled, {css} from 'styled-components';
import styledMap from 'styled-map';

import {forwardRef, ForwardRefRenderFunction, MouseEvent, ReactNode} from 'react';
import {Link} from 'react-router-dom';

import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

export type HyperlinkProps = {
  icon?: ReactNode;
  onlyIcon?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  type?: 'a' | 'button';
  href?: string;
};

const fontSize = styledMap`
	default: ${({theme}) => theme.fontSizes.text.small};
  styleF300: ${({theme}) => theme.fontSizes.text.small};
  styleF200: ${({theme}) => theme.fontSizes.text.xSmall};
  styleF100: ${({theme}) => theme.fontSizes.text.xxSmall};
`;

const cursor = styledMap`
	default: pointer;
	disabled: not-allowed;
`;

const HyperlinCommonCSS = css<HyperlinkProps>`
  display: inline-flex;
  text-decoration: none;
  color: ${({theme}) => theme.colors.palettes.blue[60][100]};
  font-weight: normal;
  box-sizing: border-box;
  cursor: ${cursor};
  font-size: ${fontSize};
  line-height: 1.34;
  height: fit-content;
  position: relative;
  align-items: center;

  &:hover {
    &:before {
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0;
      border-bottom: ${({theme}) => `1px solid ${theme.colors.palettes.blue[60][100]}`};
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const HyperlinkStyled = styled.a<HyperlinkProps>`
  ${HyperlinCommonCSS};
`;

const HyperlinkButtonStyled = styled.button<HyperlinkProps>`
  ${HyperlinCommonCSS};
  background-color: transparent;
  padding: 0;
  border: none;

  &:focus,
  &:active {
    outline: none;
  }
`;

type IconProps = {
  icon?: boolean;
  onlyIcon?: boolean;
};

const Icon = styled.span<IconProps>`
  margin-right: 5px;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  display: flex;
  align-items: center;

  svg {
    font-size: 16px;
  }

  margin-right: 5px;

  ${({onlyIcon}) =>
    onlyIcon &&
    css`
      margin: 0;
    `}
`;

const HyperlinkBase: ForwardRefRenderFunction<HTMLAnchorElement, HyperlinkProps> = (
  {icon, children, disabled, type = 'a', onClick, ...rest},
  ref
) => {
  const onlyIcon = isNotNilOrEmpty(icon) && isNilOrEmpty(children);
  const HyperlinkComponent: JSX.ElementType =
    type === 'a' || rest.href ? HyperlinkStyled : HyperlinkButtonStyled;

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    onClick?.(event);
  };

  const hyperlink = (
    <HyperlinkComponent
      {...rest}
      ref={ref}
      onlyIcon={onlyIcon}
      disabled={disabled}
      onClick={handleClick}
    >
      {isNotNil(icon) && <Icon onlyIcon={onlyIcon}>{icon}</Icon>}
      {children}
    </HyperlinkComponent>
  );

  if (!rest.href) {
    return hyperlink;
  }
  return <Link to={rest.href}>{hyperlink}</Link>;
};

export const Hyperlink = forwardRef(HyperlinkBase);
