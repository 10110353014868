import {diff} from 'deep-object-diff';
import {
  ButtonGroup,
  Form,
  FormButton,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Space, VStack} from 'platform/foundation';
import {DeepPartial} from 'utility-types';

import {useRef, useState} from 'react';
import {UseFormReturn} from 'react-hook-form/dist/types/form';

import {assocPath, clone, concat, defaultTo, flip, head, isEmpty, last, path, pipe} from 'ramda';
import {isFunction, isNilOrEmpty, isString} from 'ramda-adjunct';

import {
  ArticleTreeFolder,
  BaseArticle,
  ChangedFieldValue,
  GetArticleResponse,
  GetWarehouseAccountResponse,
  GetWarehouseResponse,
  GetWarehousesResponse,
  PatchArticleRequest,
  TenantResponseBody,
  useGetCodeListOptionsQuery,
  usePatchArticleMutation,
  usePutArticleCalculationMutation,
  VatRate,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';
import {handleApiError, Section} from '@omnetic-dms/shared';

import {Nullish, sanitizeObject, suffixTestId, TestIdProps, useNavigate} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {CatalogFilter} from '../../../../types/CatalogFilter';
import {filterElementsBySuffixMatch} from '../../../../utils/filterElementsBySuffixMatch';
import {objectPaths} from '../../../../utils/objectPaths';
import {removeKeys} from '../../../../utils/removeKeys';
import {OverviewCatalogsInformation} from './components/OverviewCatalogsInformation';
import {OverviewGeneralInformationForm} from './components/OverviewGeneralInformationForm';
import {OverviewPricesForm} from './components/OverviewPricesForm';
import {OverviewPricesSettingsForm} from './components/OverviewPricesSettingsForm';
import {OverviewUnitsForm} from './components/OverviewUnitsForm';

const ABORT_ERROR = 'AbortError';

interface OverviewProps extends TestIdProps {
  article: GetArticleResponse;
  warehouse: GetWarehouseResponse;
  warehouses: GetWarehousesResponse;
  vatRates: VatRate[];
  tenant: TenantResponseBody;
  warehouseAccount: GetWarehouseAccountResponse;
}

export function Overview(props: OverviewProps) {
  const {articleId, warehouseId} = useWarehouseParams();
  const navigate = useNavigate();

  const [leafId, setLeafId] = useState<string | Nullish>(props.article.treeFolder.leafId);

  const {data: presetDispensingUnits, isLoading: arePresetDispensingUnitsLoading} =
    useGetCodeListOptionsQuery({
      category: 'WRH-ARTICLE-DISPENSING-UNIT',
    });

  const [articleCalculate] = usePutArticleCalculationMutation();
  const [patchArticle, {isLoading: isPatchingArticle}] = usePatchArticleMutation();

  const defaultValues: DeepPartial<BaseArticle> = {
    ...props.article,
    dispensingPrices: {
      ...props.article.dispensingPrices,
      saleBaseMarginPercent: defaultTo(
        props.warehouse.salePriceMarginPercent,
        props.article.dispensingPrices?.saleBaseMarginPercent
      ),
      saleBaseMarkUpPercent: defaultTo(
        props.warehouse.salePriceMarkupPercent,
        props.article.dispensingPrices?.saleBaseMarkUpPercent
      ),
      saleBaseProfit: defaultTo(
        props.warehouse.salePriceProfitWithoutVat,
        props.article.dispensingPrices?.saleBaseProfit
      ),
    },
  };

  const prevFormValues = useRef<DeepPartial<BaseArticle>>(defaultValues);
  const abortControllerInstance = useRef<AbortController | Nullish>();

  const manufacturerId = props.article.manufacturerId;

  const catalogFilter: CatalogFilter = {
    branchId: props.warehouse.branchId,
    supplierId: props.article.supplierId,
    manufacturerId,
    manufacturerNumber: props.article.manufacturerNumber,
  };

  const handleNavigateBack = () => {
    navigate(warehouseRoutes.articleList);
  };

  const handleSubmit: FormSubmitHandler<BaseArticle> = async (data) => {
    const modifiedArticle: PatchArticleRequest['body'] = {
      warehouseId: data.warehouseId,
      supplierId: data.supplierId,
      manufacturerId: data.manufacturerId,
      makeCode: data.makeCode,
      name: data.name,
      description: data.description,
      stockNumber: data.stockNumber,
      manufacturerNumber: data.manufacturerNumber,
      handlingUnit: data.handlingUnit,
      dispensingUnit: data.dispensingUnit,
      vatType: data.vatType,
      storageLocation: data.storageLocation,
      discountGroup: data.discountGroup,
      marketingCode: data.marketingCode,
      warehouseAccount: data.warehouseAccount,
      treeFolder: {leafId: leafId ?? ''},
      pricesSettings: data.pricesSettings,
      dispensingPrices: data.dispensingPrices,
    };

    await patchArticle({articleId, warehouseId, body: modifiedArticle})
      .unwrap()
      .then(() => showNotification.success(i18n.t('page.warehouse.notification.articleUpdated')))
      .then(handleNavigateBack)
      .catch(handleApiError);
  };

  const handleChange = (
    values: DeepPartial<BaseArticle>,
    change: any,
    formApi: UseFormReturn<BaseArticle>
  ) => {
    const copiedValues = JSON.parse(JSON.stringify(values));
    const {asString} = pipe(
      defaultTo({}),
      flip(diff)(sanitizeObject(copiedValues)),
      objectPaths
    )(prevFormValues.current);

    // Name of the changed field
    const changedField = last<string>(change.name?.split('.') ?? []); // => saleBasePriceWithoutVat
    // Indicate if the changed field is allowed to trigger recalculation
    const shouldTriggerRecalculation = ALLOWED_VALUES.includes(changedField);
    // Get the difference between the previous and current form values
    const difference = diff(sanitizeObject(prevFormValues.current), sanitizeObject(copiedValues));
    // Difference that contains only allowed values
    const allowedDifference = removeKeys(
      difference,
      concat(ALLOWED_VALUES, ALLOWED_VALUES_IN_OBJECT)
    );

    const isDispensingPricesDiffEmpty = allowedDifference?.dispensingPrices
      ? isEmpty(allowedDifference?.dispensingPrices)
      : false;

    const isPricesSettingsDiffEmpty = allowedDifference?.pricesSettings
      ? isEmpty(allowedDifference?.pricesSettings)
      : false;

    const isDiffEmpty = isDispensingPricesDiffEmpty && isPricesSettingsDiffEmpty;

    if (!shouldTriggerRecalculation || isNilOrEmpty(allowedDifference) || isDiffEmpty) {
      prevFormValues.current = copiedValues;
      return;
    }

    const arrayOfChangedValues = asString.includes('.') ? asString.split(',') : asString;
    const pathToChangedValues = head(
      filterElementsBySuffixMatch(arrayOfChangedValues, ALLOWED_VALUES)
    )?.split('.') as string[];
    const changedValue = path(pathToChangedValues ?? [], values);
    const newPrevFormValues = assocPath(
      pathToChangedValues ?? [],
      changedValue,
      prevFormValues.current
    );

    prevFormValues.current = newPrevFormValues;

    // If the changed field is saleBasePriceWithoutVat, then we need to recalculate purchasePrice BE requires purchasePrice to be sent as changed field
    const field = changedField === 'basePriceWithoutVat' ? 'purchasePrice' : changedField;

    if (abortControllerInstance.current && isFunction(abortControllerInstance.current.abort)) {
      abortControllerInstance.current.abort();
    }

    const articleCalculateController = new AbortController();

    abortControllerInstance.current = articleCalculateController;

    // TODO https://carvago.atlassian.net/browse/T20-57859
    articleCalculate({
      articleId,
      warehouseId,
      body: {
        changedFieldValue: {
          field: field as ChangedFieldValue['field'],
          value: String(changedValue),
        },
        currentFeFieldsValues: {
          supplierId: values.supplierId,
          manufacturerId: values.manufacturerId,
          makeCode: values.makeCode,
          name: values.name,
          description: values.description,
          stockNumber: values.stockNumber,
          manufacturerNumber: values.manufacturerNumber,
          manufacturerName: values.manufacturerName,
          handlingUnit: values.handlingUnit,
          dispensingUnit: values.dispensingUnit,
          vatType: values.vatType,
          storageLocation: values.storageLocation,
          discountGroup: values.discountGroup,
          treeFolder: values.treeFolder as ArticleTreeFolder,
          marketingCode: values.marketingCode,
          warehouseAccount: values.warehouseAccount,
          pricesSettings: values.pricesSettings,
          dispensingPrices: values.dispensingPrices,
        },
      },
      signal: articleCalculateController.signal,
    })
      .unwrap()
      .then((response) => {
        const newValues = clone(
          sanitizeObject({
            ...values,
            pricesSettings: response.pricesSettings,
            dispensingPrices: response.dispensingPrices,
          })
        );

        prevFormValues.current = newValues;
        formApi.reset(newValues);
      })
      .catch((e) => {
        // If the request is aborted, then we don't want to show the error notification
        if (e && e.error && isString(e.error) && e.error.includes(ABORT_ERROR)) {
          return;
        }

        handleApiError(e);
      });
  };

  return (
    <Section>
      <Form<BaseArticle>
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        shouldWatchForUnsavedChanges
      >
        {(control, formApi) => {
          formApi.watch((data, test) => handleChange(data, test, formApi));

          return (
            <VStack spacing={4} width="100%">
              <OverviewGeneralInformationForm
                articleId={articleId}
                control={control}
                manufacturerId={manufacturerId}
                warehouseNumber={props.warehouse.warehouseNumber}
                warehouses={props.warehouses}
                vatRates={props.vatRates}
                tenant={props.tenant}
                isRemovable={props.article.isRemovable}
                leafId={leafId}
                leafIdChange={setLeafId}
              />

              <OverviewUnitsForm
                formApi={formApi}
                control={control}
                article={props.article}
                presetDispensingUnits={presetDispensingUnits}
                arePresetDispensingUnitsLoading={arePresetDispensingUnitsLoading}
                data-testid={suffixTestId('units', props)}
              />

              <OverviewPricesSettingsForm control={control} />

              <OverviewPricesForm
                control={control}
                article={props.article}
                vatRates={props.vatRates}
                tenant={props.tenant}
                warehouseAccount={props.warehouseAccount}
              />

              <OverviewCatalogsInformation
                catalogFilter={catalogFilter}
                data-testid={suffixTestId('articleCatalogs', props)}
              />

              <Space vertical={20} />

              <ButtonGroup align="right">
                <FormButton
                  variant="secondary"
                  control={control}
                  title={i18n.t('general.actions.discardChanges')}
                  onClick={handleNavigateBack}
                  data-testid={suffixTestId('actions.discard', props)}
                />
                <FormButton
                  isLoading={isPatchingArticle}
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.saveChanges')}
                  data-testid={suffixTestId('actions.submit', props)}
                />
              </ButtonGroup>
            </VStack>
          );
        }}
      </Form>
    </Section>
  );
}

const ALLOWED_VALUES_IN_OBJECT = ['pricesSettings', 'dispensingPrices'];

const ALLOWED_VALUES = [
  'vatType',
  'supplierId',
  'warehouseAccount',
  'marketingCode',
  'purchasePrice',
  'recommendedPriceWithOutVat',
  'recommendedPriceWithVat',
  'saleBasePriceWithoutVat',
  'saleBasePriceWithVat',
  'saleBaseMarkUpPercent',
  'saleBaseProfit',
  'saleBaseMarginPercent',
  'warrantyPriceWithoutVat',
  'warrantyPriceWithVat',
  'warrantyMarkUpPercent',
  'warrantyProfit',
  'warrantyMarginPercent',
  'basePriceWithoutVat',
];
