import {useIntersectionObserver} from 'platform/components';
import {getPixelsFromRem} from 'platform/foundation';
import {css} from 'styled-components';

import {ReactNode} from 'react';

import {useBoolean} from 'shared';

interface SlideshowActionWrapperProps {
  children: ReactNode;
}

export function SlideshowActionsWrapper(props: SlideshowActionWrapperProps) {
  const [isVisible, setVisible, setHidden] = useBoolean(true);
  const observedElementsRefs = useIntersectionObserver(
    (info) => {
      if (info[0].isIntersecting) {
        setVisible();
      } else {
        setHidden();
      }
    },
    {threshold: 1, rootMargin: `-${getPixelsFromRem(2)}px`}
  );

  return (
    <div
      css={css`
        height: 100%;
        position: absolute;
        top: 0;
        right: ${({theme}) => theme.getSize(24)};
        z-index: ${({theme}) => theme.zIndices.LIGHTBOX_ACTIONS};
      `}
    >
      <div
        ref={(ref) => {
          observedElementsRefs.current[0] = ref;
        }}
        css={css`
          position: sticky;
          top: ${({theme}) => theme.getSize(2)};
          opacity: ${isVisible ? 1 : 0};
          transition: opacity 0.15s ease-out;
          padding-top: ${({theme}) => theme.getSize(2)};
          padding-bottom: ${({theme}) => theme.getSize(2)};
        `}
      >
        {props.children}
      </div>
    </div>
  );
}
