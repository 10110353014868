import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TopMake} from '../../types/TopMake';
import {VehicleStatisticsResponse} from '../../types/VehicleStatisticsResponse';
import {AsyncThunkState, getAsyncThunkReducer} from '../../utils/reduxThunkUtils';
import {getTopMakes} from './actions';

export interface CarDetailsState extends AsyncThunkState {
  topMakes?: TopMake[];
  vehicleStatistics?: VehicleStatisticsResponse;
}

const initialState: CarDetailsState = {
  loading: {},
  errors: {},
  topMakes: [],
  vehicleStatistics: undefined,
};

// TBD: move here all slice from carDetails (v1)
const name = 'sales/vehicleDetailsV2';
const carDetailsV2 = createSlice({
  name,
  initialState,
  reducers: {
    updateTopMakeList: (state, {payload}: PayloadAction<{topMakes: TopMake[]}>) => {
      state.topMakes = payload.topMakes;
    },
  },
  extraReducers(builder) {
    const {asyncThunkReducer, handleThunkStates} = getAsyncThunkReducer(builder);

    asyncThunkReducer(getTopMakes.action, (state, {payload}) => {
      state.topMakes = payload;
    });

    handleThunkStates();
  },
});

const {
  reducer,
  actions: {updateTopMakeList},
} = carDetailsV2;

export {updateTopMakeList};

export const carDetailsV2Reducer = reducer;
