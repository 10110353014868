import {
  useRef,
  forwardRef,
  ChangeEvent,
  ForwardedRef,
  ForwardRefRenderFunction,
  FocusEvent,
} from 'react';
import ReactNumberFormat, {NumberFormatProps, NumberFormatValues} from 'react-number-format';

import {isNotNil} from 'ramda-adjunct';

import {noop} from '../../utils/someTeasUtils';

/**
 * @deprecated - use platform instead
 */
const NumberFormatWithRef: ForwardRefRenderFunction<
  HTMLInputElement,
  Omit<NumberFormatProps, 'value' | 'defaultValue'> & {
    value?: string | number | readonly string[];
    defaultValue?: string | number | readonly string[];
    removeTrailingZeroes?: boolean;
    'data-testid'?: string;
  }
> = (
  {
    name,
    type,
    value,
    defaultValue,
    format,
    customInput,
    decimalScale = 2,
    thousandSeparator = '',
    removeTrailingZeroes,
    onBlur = noop,
    onChange = noop,
    ...rest
  },
  ref: ForwardedRef<HTMLInputElement>
) => {
  const values = useRef<NumberFormatValues | null>(null);

  return (
    <ReactNumberFormat
      {...rest}
      type={type}
      name={name}
      value={isNotNil(value) ? String(value) : ''}
      defaultValue={isNotNil(defaultValue) ? String(defaultValue) : ''}
      format={format}
      getInputRef={ref}
      customInput={customInput}
      decimalScale={decimalScale}
      thousandSeparator={thousandSeparator}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        event.target.value = values.current?.value ?? '';

        onChange(event);
      }}
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        event.target.value = values.current?.value ?? '';

        onBlur(event);
      }}
      onValueChange={(_values) => {
        values.current = {
          ..._values,
          value: removeTrailingZeroes ? _values.value.replace(/\.0+$/, '') : _values.value,
        };
      }}
    />
  );
};

export const NumberFormat = forwardRef(NumberFormatWithRef);
