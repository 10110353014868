import {isFeatureEnabled} from 'feature-flags';
import {closeCurrentDialog, openConfirmDialog, showNotification} from 'platform/components';

import {
  AdmPostAuthorizationProfileHasIntegrationAdvancedSearchApiApiArgBody,
  useAdmPostAuthorizationProfileHasIntegrationAdvancedSearchApiMutation,
  useCustPostCkkConsentMutation,
  useLazyCustGetCkkConsentStateQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';

import {handleApiError} from '../utils/handleApiError';
import {useTenant} from './useTenant';

const CKK = 'CKK';

export function useLazyGetAuthorizationProfilesAndCKKPermissions() {
  const {tenant} = useTenant();

  const [searchAuthorizationProfiles] =
    useAdmPostAuthorizationProfileHasIntegrationAdvancedSearchApiMutation();
  const [postCustomerCKKConsent] = useCustPostCkkConsentMutation();
  const [getCustomerCKKConsent] = useLazyCustGetCkkConsentStateQuery();

  const handleSearchAuthorizationProfiles = (
    vehicleInfo: AdmPostAuthorizationProfileHasIntegrationAdvancedSearchApiApiArgBody,
    customerId?: string,
    businessCaseInfo?: {bcNumber?: string | null; bcCreatedAt?: string | null}
  ) => {
    if (isFeatureEnabled(featureFlags.CKK_CONSENT_STATUS_FLOW)) {
      searchAuthorizationProfiles({
        body: vehicleInfo,
        'x-tenant': tenant?.id,
      })
        .unwrap()
        .then((data) => {
          const authProfile = data?.integration?.find((i) => i?.code === CKK);
          if (!authProfile || !customerId) {
            return;
          }
          getCustomerCKKConsent({
            vehiclemake: vehicleInfo?.vehicleMake,
            vehicleType: vehicleInfo?.vehicleType,
            customerId,
            ckkId: authProfile.id,
            'x-tenant': tenant?.id ?? null,
            bcNumber: businessCaseInfo?.bcNumber,
            bcCreatedAt: businessCaseInfo?.bcCreatedAt,
          })
            .unwrap()
            .then((data) => {
              if (data?.consentState === 0) {
                openConfirmDialog({
                  onConfirm: () =>
                    postCustomerCKKConsent({
                      customerId,
                      vehicleMake: vehicleInfo?.vehicleMake,
                      vehicleType: vehicleInfo?.vehicleType,
                      externalId: data?.externalId,
                      'x-tenant': tenant?.id,
                    })
                      .unwrap()
                      .then((data) => {
                        if (data?.url) {
                          window.open(data?.url, '_blank');
                        }
                        closeCurrentDialog();
                      })
                      .catch(handleApiError),
                  text: i18n.t('entity.authorizationProfiles.labels.modal'),
                  'data-testid': 'authorizatinoProfileModal',
                });
              }
              if (data?.consentState === 1) {
                showNotification.success(
                  i18n.t('entity.authorizationProfiles.labels.successMessage')
                );
              }
              if (data?.consentState === 2) {
                showNotification.warning(
                  i18n.t('entity.authorizationProfiles.labels.warningMessage')
                );
              }
            })
            .catch(handleApiError);
        })
        .catch(handleApiError);
    }
  };
  return {handleSearchAuthorizationProfiles};
}
