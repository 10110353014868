import {Locale, addMonths, getMonth, getYear, subMonths} from 'date-fns';

import {FC, useState} from 'react';

import {TestIdProps} from 'shared';

import {CalendarHandles} from './CalendarHandles';
import {Month} from './Month';
import {CalendarWrapper} from './styles';

export interface CalendarProps extends TestIdProps {
  value: Date | null;
  onChange: (date: Date) => void;
  locale?: Locale;
  disablePast?: boolean;
  disableTo?: Date;
}

export const Calendar: FC<CalendarProps> = ({value, onChange, locale, disablePast, disableTo}) => {
  const [displayDate, setDisplayDate] = useState(value || new Date());
  const [valueDate, setValueDate] = useState(value);

  const goToPreviousMonth = () => {
    setDisplayDate(subMonths(displayDate, 1));
  };

  const goToNextMonth = () => {
    setDisplayDate(addMonths(displayDate, 1));
  };

  const handleDayClick = (date: Date) => {
    setValueDate(date);
    onChange(date);
  };

  return (
    <CalendarWrapper>
      <CalendarHandles onPreviousClicked={goToPreviousMonth} onNextClicked={goToNextMonth} />
      <Month
        locale={locale}
        month={getMonth(displayDate)}
        year={getYear(displayDate)}
        onDayClick={handleDayClick}
        selected={valueDate ?? undefined}
        disablePast={disablePast}
        disableTo={disableTo}
      />
    </CalendarWrapper>
  );
};
