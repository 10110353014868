import {ButtonGroup, Card, IconButton} from 'platform/components';
import {Box} from 'platform/foundation';

import {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {selectBranchList} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  SimpleTable,
  ColumnType,
  useRouter,
  selectSeries,
  selectInterestSeriesDefinitionList,
  InterestSeriesDefinition,
} from '@omnetic-dms/teas';

import {composePath} from 'shared';

export const InterestSeriesDefinitionList: FC = () => {
  const router = useRouter();
  const definitions = useSelector(selectInterestSeriesDefinitionList);
  const {data} = useSelector(selectBranchList);
  const branchList = data?.branchListItems;
  const series = useSelector(selectSeries);

  const columns = useMemo<ColumnType<InterestSeriesDefinition>[]>(
    () => [
      {
        Header: i18n.t('entity.seriesDefinition.labels.definitionName'),
        id: 'name',
      },
      {
        Header: i18n.t('entity.branch.labels.branch'),
        id: 'branch',
        Cell: ({row}) => {
          if (!row.branchId) {
            return i18n.t('general.labels.all');
          }

          return branchList?.find(({id}) => id === row.branchId)?.marketingName ?? '';
        },
      },
      {
        Header: i18n.t('entity.seriesDefinition.labels.interestDefinitionSeries'),
        id: 'definitionSeries',
        Cell: ({row}) => series.find(({id}) => id === row.seriesId)?.name ?? row.seriesId,
      },
      {
        Header: '',
        id: 'actions',
        width: 100,
        Cell: ({row}) => (
          <Box paddingHorizontal={3}>
            <ButtonGroup align="right">
              <IconButton
                key="edit"
                size="small"
                icon="image/edit"
                onClick={() =>
                  router.push(
                    composePath(settingsRoutes.interestDefinitionDetail, {params: {id: row.id}})
                  )
                }
              />
            </ButtonGroup>
          </Box>
        ),
      },
    ],
    [router, branchList, series]
  );
  return (
    <Card title={i18n.t('entity.seriesDefinition.labels.definitions')}>
      <SimpleTable
        noZebra
        showRowDivider
        tdPadding="default"
        highlightRowOnHover
        rows={[
          {
            data: definitions,
          },
        ]}
        getRowId={({id}) => id}
        columns={columns}
      />
    </Card>
  );
};
