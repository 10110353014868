import {z} from 'zod';

export const GetServiceOrderGroupApiResponseSchema = z.union([
  z.array(
    z
      .object({
        serviceOrderGroupId: z.string(),
        name: z.string(),
        code: z.string().optional(),
        isActive: z.boolean(),
      })
      .nullable()
  ),
  z.void(),
]);

export type GetServiceOrderGroupApiResponse = z.infer<typeof GetServiceOrderGroupApiResponseSchema>;

export const GetServiceOrderGroupApiArgSchema = z.object({
  isActive: z.boolean().optional(),
  validityFrom: z.string().nullable().optional(),
  validityTo: z.string().nullable().optional(),
  authorizationProfileId: z.string().nullable().optional(),
  serviceOrderTypeId: z.string().nullable().optional(),
  serviceOrderVariantId: z.string().nullable().optional(),
});

export type GetServiceOrderGroupApiArg = z.infer<typeof GetServiceOrderGroupApiArgSchema>;

export const PostServiceOrderGroupApiResponseSchema = z.union([
  z
    .object({
      serviceOrderGroupId: z.string(),
    })
    .nullable(),
  z.void(),
]);

export type PostServiceOrderGroupApiResponse = z.infer<
  typeof PostServiceOrderGroupApiResponseSchema
>;

export const PostServiceOrderGroupApiArgSchema = z.object({
  body: z
    .object({
      name: z.string(),
      code: z.string().nullable().optional(),
      validityFrom: z.string().nullable().optional(),
      validityTo: z.string().nullable().optional(),
      authorizationProfileIds: z.array(z.string().nullable()).optional(),
      serviceOrderTypeIds: z.array(z.string().nullable()).optional(),
      serviceOrderVariantIds: z.array(z.string().nullable()).optional(),
    })
    .nullable(),
});

export type PostServiceOrderGroupApiArg = z.infer<typeof PostServiceOrderGroupApiArgSchema>;

export const GetServiceOrderGroupByIdApiResponseSchema = z.union([
  z
    .object({
      serviceOrderGroupId: z.string(),
      name: z.string(),
      code: z.string().nullable().optional(),
      validityFrom: z.string().nullable().optional(),
      validityTo: z.string().nullable().optional(),
      authorizationProfileIds: z.array(z.string().optional()).optional(),
      serviceOrderTypeIds: z.array(z.string().optional()).optional(),
      serviceOrderVariantIds: z.array(z.string().optional()).optional(),
      isActive: z.boolean(),
    })
    .nullable(),
  z.void(),
]);

export type GetServiceOrderGroupByIdApiResponse = z.infer<
  typeof GetServiceOrderGroupByIdApiResponseSchema
>;

export const GetServiceOrderGroupByIdApiArgSchema = z.object({
  serviceOrderGroupId: z.string(),
});

export type GetServiceOrderGroupByIdApiArg = z.infer<typeof GetServiceOrderGroupByIdApiArgSchema>;

export const DeleteServiceOrderGroupApiResponseSchema = z.void();

export type DeleteServiceOrderGroupApiResponse = z.infer<
  typeof DeleteServiceOrderGroupApiResponseSchema
>;

export const DeleteServiceOrderGroupApiArgSchema = z.object({
  serviceOrderGroupId: z.string(),
});

export type DeleteServiceOrderGroupApiArg = z.infer<typeof DeleteServiceOrderGroupApiArgSchema>;

export const PatchServiceOrderGroupApiResponseSchema = z.void();

export type PatchServiceOrderGroupApiResponse = z.infer<
  typeof PatchServiceOrderGroupApiResponseSchema
>;

export const PatchServiceOrderGroupApiArgSchema = z.object({
  serviceOrderGroupId: z.string(),
  body: z
    .object({
      name: z.string(),
      code: z.string().nullable().optional(),
      validityFrom: z.string().nullable().optional(),
      validityTo: z.string().nullable().optional(),
      authorizationProfileIds: z.array(z.string().nullable()).optional(),
      serviceOrderTypeIds: z.array(z.string().nullable()).optional(),
      serviceOrderVariantIds: z.array(z.string().nullable()).optional(),
    })
    .nullable(),
});

export type PatchServiceOrderGroupApiArg = z.infer<typeof PatchServiceOrderGroupApiArgSchema>;

export const PostServiceOrderGroupSetAsActiveApiResponseSchema = z.void();

export type PostServiceOrderGroupSetAsActiveApiResponse = z.infer<
  typeof PostServiceOrderGroupSetAsActiveApiResponseSchema
>;

export const PostServiceOrderGroupSetAsActiveApiArgSchema = z.object({
  serviceOrderGroupId: z.string(),
});

export type PostServiceOrderGroupSetAsActiveApiArg = z.infer<
  typeof PostServiceOrderGroupSetAsActiveApiArgSchema
>;

export const PostServiceOrderGroupSetAsInactiveApiResponseSchema = z.void();

export type PostServiceOrderGroupSetAsInactiveApiResponse = z.infer<
  typeof PostServiceOrderGroupSetAsInactiveApiResponseSchema
>;

export const PostServiceOrderGroupSetAsInactiveApiArgSchema = z.object({
  serviceOrderGroupId: z.string(),
});

export type PostServiceOrderGroupSetAsInactiveApiArg = z.infer<
  typeof PostServiceOrderGroupSetAsInactiveApiArgSchema
>;
