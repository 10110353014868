export enum BusinessCaseTabItem {
  OVERVIEW = 'overview',
  CUSTOMER = 'customer',
  BUYING = 'buying',
  SELLING = 'selling',
  TEST_DRIVE = 'test_drive',
  CHECKOUT = 'checkout',
  DOCUMENTS = 'documents',
  RESERVATIONS = 'reservations',
  INSURANCE = 'insurance',
}
