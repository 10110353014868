import {Control} from 'react-hook-form';

import {omit} from 'ramda';

import {TestIdProps} from 'shared';

import {FormButton, FormButtonProps} from '../../FormButton/FormButton';

/**
 * @about
 * https://github.com/react-hook-form/react-hook-form/issues/4965
 * This is the only way how to type RHF control prop, that would accept control with 'any' as the generic parameter
 *
 * Only better way to do this is to make Action type as generic and also make every component that uses Actions as a prop as generic
 *
 * @deprecated
 */
// @ts-expect-error
type UntypedControl = Control<UnresolvedAny, any> & {
  formId: string;
  onChange: VoidFunction;
};
export interface FormButtonActionProps
  extends Omit<FormButtonProps, 'type' | 'control'>,
    TestIdProps {
  type: 'form-button';
  control: UntypedControl;
  buttonType: FormButtonProps['type'];
}

export function FormButtonAction(props: FormButtonActionProps) {
  const buttonProps = omit(['type'], props);

  return <FormButton {...buttonProps} data-testid={props['data-testid']} type={props.buttonType} />;
}
