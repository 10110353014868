import type {CancelablePromise} from '../types/CancelablePromise';
import {request as __request} from '../utils/request';

export class DeleteAssignmentService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static deleteAssignment({
    assignmentId,
    authorization,
  }: {
    assignmentId: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'DELETE',
      url: '/dms/v1/assignment/{assignmentId}',
      path: {
        assignmentId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
}
