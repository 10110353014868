import {createSelector} from '@reduxjs/toolkit';

import {selectGeneralSettings} from '../generalSettings/selectors';

export const selectDocumentTemplates = createSelector(
  selectGeneralSettings,
  (state) => state?.documents.templates.data
);

export const selectCustomTemplate = (customTemplateId: string) =>
  createSelector(selectDocumentTemplates, (state) =>
    state?.find(({id}) => id === customTemplateId)
  );

export const selectUploadingTemplate = createSelector(
  selectGeneralSettings,
  (state) => state.documents.uploadingTemplate
);

export const uploadingTemplate = (templateCode: string) =>
  createSelector(
    selectGeneralSettings,
    (state) =>
      state?.documents?.templates?.data?.find(
        ({documentKindCode}) => documentKindCode === templateCode
      )?.isUploading
  );

export const documentDownloadingSelector = createSelector(
  selectGeneralSettings,
  (state) => state?.documents.downloading
);

export const selectDocumentKinds = createSelector(
  selectGeneralSettings,
  (state) => state?.documents.documentKinds
);

export const selectTemplateGroup = createSelector(
  selectGeneralSettings,
  (state) => state?.documents.templateGroup
);
