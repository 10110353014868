export const openFile = (urlData: string) => {
  const url = new URL(urlData);
  const hasInlineTypeInUrl = url.searchParams.get('type') === 'inline';

  if (!hasInlineTypeInUrl) {
    url.searchParams.append('type', 'inline');
  }

  window.open(url);
};
