import {CSSDimension, Integer, Box, HStack, Scroll, Show, Space, VStack} from 'platform/foundation';

import {useState} from 'react';

import {concat, equals} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {Action, Actions} from '../Actions/Actions';
import {useTranslationContext} from '../TranslationProvider/TranslationContext';
import {WizardStep} from './components/WizardStep';
import {getFirstEnabledStepPosition} from './utils/getFirstEnabledStepPosition';
import {getNextStep} from './utils/getNextStep';
import {getPreviousStep} from './utils/getPreviousStep';
import {WizardStepConfig} from './WizardStepConfig';

interface WizardProps extends TestIdProps {
  steps: WizardStepConfig[];
  width?: CSSDimension | Integer;
  height?: CSSDimension | Integer;
  minHeight?: CSSDimension | Integer;
  actions?: Action[];
}

export function Wizard(props: WizardProps) {
  const t = useTranslationContext();

  const [currentStep, setCurrentStep] = useState(
    () => getFirstEnabledStepPosition(props.steps) ?? 0
  );

  const previousStep = getPreviousStep(props.steps, currentStep);
  const nextStep = getNextStep(props.steps, currentStep);

  const currentActions: Action[] = buildArray<Action>()
    .when(isNotNil(previousStep), {
      type: 'button',
      variant: 'secondary',
      title: t('general.actions.back'),
      'data-testid': suffixTestId('backButton', props),
      onClick: () => setCurrentStep(previousStep!),
    })
    .when(isNotNil(nextStep), {
      type: 'button',
      variant: 'secondary',
      title: t('general.actions.continue'),
      onClick: () => setCurrentStep(nextStep!),
    });

  const currentConfig = props.steps.at(currentStep);
  const actions = concat(currentActions, currentConfig?.actions ?? []);
  const hasActions = isNotNilOrEmpty(props.actions) && isNotNilOrEmpty(actions);

  return (
    <Box
      width={props.width}
      height={props.height}
      minHeight={props.minHeight}
      data-testid={props['data-testid']}
    >
      <HStack height="100%" minHeight={props.minHeight}>
        <Scroll width={70} auto>
          <Box backgroundColor="palettes.neutral.20.100" minHeight="100%" role="tablist">
            <VStack>
              {props.steps.map((step, index) => (
                <WizardStep
                  {...step}
                  position={index}
                  onClick={setCurrentStep}
                  isCurrent={equals(currentStep, index)}
                  key={`wizardStep-${index}`}
                  data-testid={props['data-testid']}
                />
              ))}
            </VStack>
          </Box>
        </Scroll>
        <Box
          flex={1}
          paddingVertical={6}
          backgroundColor="text.white"
          height="100%"
          role="tabpanel"
        >
          <VStack justify="space-between" height="100%" minHeight={props.minHeight} spacing={6}>
            <Scroll auto flex={1}>
              <Box
                paddingHorizontal={6}
                key={`wizardContent-${currentStep}`}
                data-testid={suffixTestId('wizardContent', props)}
              >
                {currentConfig?.content}
              </Box>
            </Scroll>
            <Show when={hasActions}>
              <Box paddingHorizontal={6}>
                <HStack justify="space-between">
                  <Actions
                    size="default"
                    actions={props.actions}
                    data-testid={suffixTestId('actions', props)}
                  />
                  <Space fillAvailable />
                  <Actions size="default" actions={actions} />
                </HStack>
              </Box>
            </Show>
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
}
