import styled from 'styled-components';

export const ScrollWithoutScrollbar = styled.div<{$direction: 'x' | 'y'}>`
  overflow-${(props) => props.$direction}: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Webkit based browsers */
    width: 0;
    height: 0;
  }
`;
