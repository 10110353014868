import {z} from 'zod';

import {BaseVoidRequestSchema, BaseVoidResponseSchema} from './base';

export const PostServiceCaseTypeApiResponseSchema = z
  .object({
    id: z.string().nullable().optional(),
  })
  .nullable();

export type PostServiceCaseTypeApiResponse = z.infer<typeof PostServiceCaseTypeApiResponseSchema>;

export const PostServiceCaseTypeApiArgSchema = z.object({
  body: z
    .object({
      authorizationProfileId: z.string().nullable(),
      docSeriesServiceCaseId: z.string(),
      docSeriesServiceOrderId: z.string(),
      name: z.string(),
      isDefaultServiceOrderVariant: z.boolean(),
    })
    .nullable(),
});

export type PostServiceCaseTypeApiArg = z.infer<typeof PostServiceCaseTypeApiArgSchema>;

export const GetServiceCaseTypeApiResponseSchema = z
  .object({
    id: z.string().nullable().optional(),
    authorizationProfileId: z.string().nullable().optional(),
    docSeriesServiceCaseId: z.string().nullable().optional(),
    docSeriesServiceOrderId: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    isDefaultServiceOrderVariant: z.boolean().nullable().optional(),
  })
  .nullable();

export type GetServiceCaseTypeApiResponse = z.infer<typeof GetServiceCaseTypeApiResponseSchema>;

export const GetServiceCaseTypeApiArgSchema = z.object({
  serviceCaseTypeId: z.string(),
});

export type GetServiceCaseTypeApiArg = z.infer<typeof GetServiceCaseTypeApiArgSchema>;

export const PatchServiceCaseTypeApiResponseSchema = BaseVoidResponseSchema;

export type PatchServiceCaseTypeApiResponse = z.infer<typeof PatchServiceCaseTypeApiResponseSchema>;

export const PatchServiceCaseTypeApiArgSchema = z.object({
  serviceCaseTypeId: z.string(),
  body: z
    .object({
      authorizationProfileId: z.string().nullable(),
      docSeriesServiceCaseId: z.string(),
      docSeriesServiceOrderId: z.string(),
      name: z.string(),
      isDefaultServiceOrderVariant: z.boolean(),
    })
    .nullable(),
});

export type PatchServiceCaseTypeApiArg = z.infer<typeof PatchServiceCaseTypeApiArgSchema>;

export const PatchServiceCaseTypeSetAsActiveApiResponseSchema = BaseVoidResponseSchema;

export type PatchServiceCaseTypeSetAsActiveApiResponse = z.infer<
  typeof PatchServiceCaseTypeSetAsActiveApiResponseSchema
>;

export const PatchServiceCaseTypeSetAsActiveApiArgSchema = z.object({
  serviceCaseTypeId: z.string(),
});

export type PatchServiceCaseTypeSetAsActiveApiArg = z.infer<
  typeof PatchServiceCaseTypeSetAsActiveApiArgSchema
>;

export const PatchServiceCaseTypeSetAsInactiveApiResponseSchema = BaseVoidResponseSchema;

export type PatchServiceCaseTypeSetAsInactiveApiResponse = z.infer<
  typeof PatchServiceCaseTypeSetAsInactiveApiResponseSchema
>;

export const PatchServiceCaseTypeSetAsInactiveApiArgSchema = z.object({
  serviceCaseTypeId: z.string(),
});

export type PatchServiceCaseTypeSetAsInactiveApiArg = z.infer<
  typeof PatchServiceCaseTypeSetAsInactiveApiArgSchema
>;

export const GetServiceCaseTypesApiResponseSchema = z.array(
  z.object({
    name: z.string(),
    id: z.string(),
    isActive: z.boolean(),
  })
);

export type GetServiceCaseTypesApiResponse = z.infer<typeof GetServiceCaseTypesApiResponseSchema>;

export const GetServiceCaseTypesApiArgSchema = BaseVoidRequestSchema;

export type GetServiceCaseTypesApiArg = z.infer<typeof GetServiceCaseTypesApiArgSchema>;
