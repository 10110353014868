import {MouseEventHandler} from 'react';

import {TestIdProps} from 'shared';

import {IconButton, IconButtonProps} from '../../IconButton/IconButton';
import {Tooltip, TooltipProps} from '../../Tooltip/Tooltip';

export interface IconButtonWithTooltipActionProps extends IconButtonProps, TestIdProps {
  type: 'iconButtonWithTooltip';
  onClick: MouseEventHandler<HTMLButtonElement>;
  tooltip: Omit<TooltipProps, 'children'>;
}

export function IconButtonWithTooltipAction(props: IconButtonWithTooltipActionProps) {
  return (
    <Tooltip data-testid={props['data-testid']} {...props.tooltip}>
      <IconButton
        icon={props.icon}
        isDisabled={props.isDisabled}
        severity={props.severity}
        size={props.size}
        priority={props.priority}
        onClick={props.onClick}
        data-testid={props['data-testid']}
      />
    </Tooltip>
  );
}
