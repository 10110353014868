import {isFeatureEnabled} from 'feature-flags';

import {useSelector} from 'react-redux';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  EntityResourceIds,
  selectSaleVehicle,
  useGetGeneralSettingsQuery,
  useGetParticipationQuery,
  useGetVehicleV2Query,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  EntityHeaderFlag,
  getVehicleAdvertisementTypeFlagProps,
  getVehicleBuyingStateFlagProps,
  getVehiclePhotographedFlagProps,
  getVehicleSaleTypeFlagProps,
  getVehicleSellingStateFlagProps,
  usePermissions,
  useVehiclePhotosPermissions,
} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

export const useGetVehicleHeaderFlags = (vehicleId: string) => {
  const {data: vehicle} = useGetVehicleV2Query({vehicleId});
  const {data: saleVehicle} = useSelector(selectSaleVehicle(vehicleId));
  const {data: generalSettings} = useGetGeneralSettingsQuery({vehicleId});

  const saleTypeFlagProps = getVehicleSaleTypeFlagProps(saleVehicle?.type);
  const sellingStateFlagProps = getVehicleSellingStateFlagProps(saleVehicle?.sellingState);
  const buyingStateFlagProps = getVehicleBuyingStateFlagProps(saleVehicle?.buyingState);
  const advertisementStateFlagProps = getVehicleAdvertisementTypeFlagProps(generalSettings?.inSale);
  const photographedFlagProps = getVehiclePhotographedFlagProps(saleVehicle?.isPhotographed);

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: vehicleId,
  });

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: saleVehicle?.saleBusinessCaseId ?? '',
    },
    {skip: isNil(saleVehicle?.saleBusinessCaseId)}
  );

  const [
    canReadReservations,
    canReadReservationsHistory,
    hasReadAdvertisingSettingsPermission,
    canReadBusinessCase,
    canReadSellingBusinessCase,
    canReadBuyingBusinessCase,
  ] = usePermissions({
    permissionKeys: [
      'vehicleReadActiveReservation',
      'vehicleReadReservationHistory',
      'readAdvertisingSettings',
      'businessCaseRead',
      'viewBusinessCaseSelling',
      'viewBusinessCasePurchase',
    ],
    scopes: {
      vehicleReadActiveReservation: vehicleParticipation,
      vehicleReadReservationHistory: vehicleParticipation,
      readAdvertisingSettings: vehicleParticipation,
      viewBusinessCasePurchase: businessCaseParticipation,
      viewBusinessCaseSelling: businessCaseParticipation,
    },
  });

  const {canReadVehicleAlbumPhotos} = useVehiclePhotosPermissions({
    vehicleId: vehicleId,
  });

  const isSalesModuleEnabled = isFeatureEnabled(featureFlags.ACL_SALES) && canReadBusinessCase;

  const shouldDisplaySellingStateFlag =
    isSalesModuleEnabled &&
    canReadSellingBusinessCase &&
    saleVehicle?.sellingState &&
    isNotNil(sellingStateFlagProps);

  const shouldDisplayBuyingStateFlag =
    isSalesModuleEnabled &&
    canReadBuyingBusinessCase &&
    saleVehicle?.buyingState &&
    isNotNil(buyingStateFlagProps);

  const shouldDisplayAdvertisementFlag =
    isSalesModuleEnabled &&
    hasReadAdvertisingSettingsPermission &&
    (sellingStateFlagProps || buyingStateFlagProps) &&
    isNotNil(advertisementStateFlagProps);
  const shouldDisplayPhotographedFlag =
    canReadVehicleAlbumPhotos && isNotNil(photographedFlagProps);

  return buildArray<EntityHeaderFlag>()
    .when(saleVehicle?.activeReservation && canReadReservations && canReadReservationsHistory, {
      label: i18n.t('entity.vehicle.labels.reserved'),
      colorScheme: 'yellow',
      'data-testid': testIds.vehicles.detail('vehicleHeader-reserved'),
    })
    .when(vehicle?.isArchived, {
      label: i18n.t('entity.vehicle.labels.archived'),
      colorScheme: 'neutral',
      'data-testid': testIds.vehicles.detail('vehicleHeader-archived'),
    })
    .when(
      isNotNil(saleTypeFlagProps) && !vehicle?.isArchived && isSalesModuleEnabled,
      saleTypeFlagProps!
    )
    .when(shouldDisplaySellingStateFlag, sellingStateFlagProps!)
    .when(shouldDisplayBuyingStateFlag, buyingStateFlagProps!)
    .when(shouldDisplayAdvertisementFlag, advertisementStateFlagProps!)
    .when(shouldDisplayPhotographedFlag, photographedFlagProps!);
};
