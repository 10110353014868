import {Nullish} from 'shared';

const FALLBACK_LANGUAGE = 'en';

export const getTranslation = (
  translatable: Record<string, string> | Nullish,
  locale: string | Nullish
) => {
  const language = locale ?? FALLBACK_LANGUAGE;
  return translatable?.[language] ?? translatable?.[0];
};
