import {path} from 'ramda';

import {useGetWindshieldUrlQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {QrCodeDefinitionForm} from './components/QrCodeDefinitionForm';

export function QrCodeDefinition() {
  const {error, isError} = useGetWindshieldUrlQuery();
  const isUrlEmpty = path(['status'], error) === 404;

  /**
   * Loading needs to be handled inside <QrCodeDefinitionForm/> to avoid cache sharing issues
   */
  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.salesSettings.labels.qrCodeDefinitionTitle'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
        ],
      }}
      isError={isError && !isUrlEmpty}
      data-testid="settings-advertising-qrCodeDefinition"
    >
      <QrCodeDefinitionForm />
    </SettingsTemplate>
  );
}
