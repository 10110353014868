import {useMemo} from 'react';

import {CSSWithMultiValues, RecursiveCSSObject, useMultiStyleConfig} from '@chakra-ui/react';

import {FeGridSettings, TransformedDefinitionApiResponse} from '../types/Api';
import {LineHeightMappingToPx} from '../utils/lineHeightToPx';

export const useGridTheme = (
  settings: FeGridSettings,
  definition: TransformedDefinitionApiResponse
): Record<string, RecursiveCSSObject<CSSWithMultiValues> | undefined> => {
  const {
    grid: gridTheme,
    gridZebra: zebraTheme,
    gridColBorder: colTheme,
    gridRowBorder: rowTheme,
    header: headerTheme,
    footer: footerTheme,
    inlineWrapper: inlineWrapperTheme,
    cardWrapper: cardWrapperTheme,
    aside: asideTheme,
  } = useMultiStyleConfig('DataGrid', {});

  const wrapperTheme = useMemo(() => {
    if (definition.behavior.wrapperStyle === 'INLINE') {
      return inlineWrapperTheme;
    }
    return cardWrapperTheme;
  }, [definition.behavior.wrapperStyle, cardWrapperTheme, inlineWrapperTheme]);

  const finalHeaderTheme = useMemo(() => {
    if (definition.behavior.wrapperStyle === 'INLINE') {
      return {
        ...headerTheme,
        paddingLeft: 0,
        paddingRight: 0,
      };
    }
    return headerTheme;
  }, [definition.behavior.wrapperStyle, headerTheme]);

  const finalFooterTheme = useMemo(() => {
    if (definition.behavior.wrapperStyle === 'INLINE') {
      return {
        ...footerTheme,
        paddingLeft: 0,
        paddingRight: 0,
      };
    }
    return footerTheme;
  }, [definition.behavior.wrapperStyle, footerTheme]);

  const finalGridTheme = useMemo(
    () =>
      Object.assign(
        {},
        gridTheme,
        settings.verticalSeparatorsVisible ? colTheme : {},
        settings.horizontalSeparatorsVisible ? rowTheme : {},
        settings.stripedRows ? zebraTheme : {},
        {
          '.ag-cell, .ag-row-loading, .ag-loading': {
            minH: `${LineHeightMappingToPx[settings.rowHeight]}px`,
            marginBottom: '1px',
          },
          '.ag-row-loading': {
            height: `${LineHeightMappingToPx[settings.rowHeight]}px`,
          },

          // is fixed exactly like this with ag-grid v31.1
          // see:
          // https://github.com/ag-grid/ag-grid/commit/1ddcca838ca0cb95e83022da45bdeb08054930fa
          '.ag-body-viewport::-webkit-scrollbar, .ag-center-cols-viewport::-webkit-scrollbar': {
            display: 'none',
          },
          '.ag-body-viewport, .ag-center-cols-viewport': {
            scrollbarWidth: 'none !important',
          },
        }
      ),
    [
      gridTheme,
      zebraTheme,
      colTheme,
      rowTheme,
      settings.rowHeight,
      settings.verticalSeparatorsVisible,
      settings.horizontalSeparatorsVisible,
      settings.stripedRows,
    ]
  );

  return {
    gridTheme: finalGridTheme,
    headerTheme: finalHeaderTheme,
    footerTheme: finalFooterTheme,
    wrapperTheme,
    asideTheme,
  };
};
