import {Box, ThemeZIndexPath} from 'platform/foundation';
import {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {ReactNode} from 'react';

import {always} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

export type OverlayVariant = 'white' | 'neutral';
export type OverlayDensity = 'default' | 'high';

interface OverlayProps extends TestIdProps {
  variant: OverlayVariant;
  density?: OverlayDensity;
  zIndex?: ThemeZIndexPath;
  children: ReactNode;
}

export function Overlay(props: OverlayProps) {
  const theme = useTheme();

  return (
    <div
      data-testid={suffixTestId('overlay', props)}
      css={css`
        position: absolute;
        inset: 0;
        overflow: hidden;
        z-index: ${props.zIndex ? theme.zIndices[props.zIndex] : theme.zIndices.OVERLAY};
      `}
    >
      <Box width="100%" height="100%" position="relative" overflow="hidden">
        <div
          css={css`
            position: absolute;
            inset: 0;
            z-index: 1;
          `}
          data-testid={suffixTestId('overlayContent', props)}
        >
          {props.children}
        </div>
        <div
          css={css`
            position: absolute;
            inset: 0;
            background: ${match<[OverlayVariant, OverlayDensity], string>([
              props.variant,
              props.density ?? 'default',
            ])
              .with(['white', 'default'], always(theme.colors.general.white))
              .with(['white', 'high'], always(theme.colors.general.white))
              .with(['neutral', 'default'], always(theme.colors.palettes.neutral[900][100]))
              .with(['neutral', 'high'], always(theme.colors.palettes.neutral[900][100]))
              .exhaustive()};
            opacity: ${match<[OverlayVariant, OverlayDensity]>([
              props.variant,
              props.density ?? 'default',
            ])
              .with(['white', 'default'], always(0.2))
              .with(['white', 'high'], always(0.6))
              .with(['neutral', 'default'], always(0.2))
              .with(['neutral', 'high'], always(0.6))
              .exhaustive()};
          `}
          data-testid={suffixTestId('overlayBackground', props)}
        />
      </Box>
    </div>
  );
}
