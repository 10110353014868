import styled from 'styled-components';

import {forwardRef} from 'react';

import {ContextMenuOptionPropsType} from './types';

export const Option = forwardRef<HTMLDivElement, ContextMenuOptionPropsType>((props, ref) => (
  <StyledOption as="div" {...props} ref={ref} />
));

const StyledOption = styled.div<ContextMenuOptionPropsType>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 280px;
  height: 40px;
  padding: 10px;
  background-color: ${({theme, active}) =>
    active ? theme.colors.palettes.neutral[700][100] : theme.colors.palettes.neutral[900][100]};
  color: inherit;
  text-decoration: none;

  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[700][100]};
  }
`;
