import {Image, Box, Center} from 'platform/foundation';

interface ComponentProps {
  alt?: string;
  image?: string;
}

export function CebiaWidgetLogo({alt, image}: ComponentProps) {
  return (
    <Box
      backgroundColor="palettes.blue.10.100"
      minHeight="100%"
      paddingLeft={9}
      paddingRight={9}
      minWidth={39}
    >
      <Center height="100%">
        <Image src={image ?? '../../../../assets/images/cebia_logo.png'} alt={alt} maxWidth={20} />
      </Center>
    </Box>
  );
}
