import {Alert, Attributes, AttributesRow} from 'platform/components';
import {Image, GridItem, Text, Grid, Heading, Space} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import styled from 'styled-components';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {selectAudit, selectTestDrive} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {TConditionFormValue} from '../../../types/ConditionTypes';
import {getNameAccordingToLocale} from '../../../utils/getNameAccordingToLocale';
import {AuditParamType} from '../types/AuditParamType';
import {findByParamType} from '../utils/findByParamType';
import {getFormFieldName} from '../utils/getFormFieldName';
import {CategoryGeneralComment} from './CategoryGeneralComment';

const BannerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90.29deg, #f0fdf6 0%, #ddf7f6 100%);
  border-radius: var(--eag-small);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0 16px 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 8px;
  }
`;

const AlertWrapper = styled.div`
  margin-bottom: 16px;
`;

const ImageWrapper = styled.div`
  max-width: 130px;
`;

const HeadingWrapper = styled.div`
  max-width: 170px;
  margin-top: 1.5rem;
`;

const PhotoWrapper = styled.div`
  margin: 16px 4% 0 0;
`;

const timeParams: string[] = [AuditParamType.START_TIME, AuditParamType.END_TIME];

export const TestDrive: FC<TestIdProps> = (props) => {
  const locale = useSelector(selectUserSelectedLanguage);
  const testDriveStructure = useSelector(selectTestDrive);
  const audit = useSelector(selectAudit);
  const formatDateTime = useDateTimeFormatter();

  const commentParamDefinition = findByParamType(
    testDriveStructure?.paramDefinitions?.additionalInformation,
    AuditParamType.ADDITIONAL_COMMENT
  );

  const rowsParam: AttributesRow[] = [];

  let dateValue;
  let isGpsSignalWeak = false;
  let isNotTested = true;

  const getRowValue = (paramDefinition: AuditParamDefinition, fieldValue?: TConditionFormValue) => {
    if (!Boolean(fieldValue)) {
      return EMPTY_PLACEHOLDER;
    }

    if (Boolean(fieldValue) && timeParams.includes(paramDefinition.type)) {
      return formatDateTime('timeShort', parseDate(String(fieldValue)));
    }

    if (paramDefinition.unit) {
      return `${fieldValue} ${paramDefinition.unit}`;
    }

    return String(fieldValue);
  };

  testDriveStructure?.paramDefinitions?.relatedActions?.forEach((paramDefinition) => {
    if (paramDefinition.type === AuditParamType.CATEGORY_STATE) {
      return;
    }

    const fieldName = getFormFieldName(testDriveStructure.id, paramDefinition.id);
    const fieldValue = audit?.fields?.[fieldName];

    if (paramDefinition.type === AuditParamType.TEST_DRIVE_DATE) {
      if (Boolean(fieldValue) && fieldValue !== '') {
        dateValue = formatDateTime('dateShort', parseDate(String(fieldValue)));
      }

      return;
    }

    if (paramDefinition.type === AuditParamType.WEAK_GPS_SIGNAL) {
      isGpsSignalWeak = Boolean(fieldValue);
      return;
    }

    if (Boolean(fieldValue) && isNotTested) {
      isNotTested = false;
    }

    rowsParam.push({
      label: paramDefinition.label ? getNameAccordingToLocale(locale, paramDefinition.label) : '',
      value: getRowValue(paramDefinition, fieldValue),
    });
  });

  return (
    <>
      {isGpsSignalWeak && (
        <AlertWrapper data-testid={suffixTestId('alertWrapper', props)}>
          <Alert
            data-testid={suffixTestId('warning', props)}
            type="inline"
            variant="warning"
            title={i18n.t('entity.condition.notifications.finishedWithWarnings')}
            message={i18n.t('entity.condition.notifications.lowGpsSignal')}
          />
        </AlertWrapper>
      )}
      <Grid columns={2}>
        <GridItem>
          {isNotTested ? (
            <Text alternative color="danger" data-testid={suffixTestId('vehicleNotTested', props)}>
              {i18n.t('entity.condition.labels.vehicleNotTested')}
            </Text>
          ) : (
            <Text alternative data-testid={suffixTestId('testDate', props)}>
              {dateValue}
            </Text>
          )}
          <Space vertical={2} />
          <Attributes rows={rowsParam} data-testid={props['data-testid']} />
        </GridItem>
        <GridItem>
          <BannerWrapper>
            <ContentWrapper>
              <div>
                <ImageWrapper>
                  <Image src="/assets/images/ca_logo_test_drive.svg" />
                </ImageWrapper>
                <HeadingWrapper>
                  <Heading size={4}>{i18n.t('entity.condition.labels.testDriveApp')}</Heading>
                </HeadingWrapper>
              </div>
              <ButtonWrapper data-testid={props['data-testid']}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://apps.apple.com/sk/app/caraudit/id1486564011"
                >
                  <Image src="/assets/images/app_store_button.png" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=app.caraudit"
                >
                  <Image src="/assets/images/google_play_button.png" />
                </a>
              </ButtonWrapper>
            </ContentWrapper>
            <PhotoWrapper>
              <Image src="/assets/images/teas_phone.png" />
            </PhotoWrapper>
          </BannerWrapper>
        </GridItem>
      </Grid>
      <CategoryGeneralComment
        category={testDriveStructure}
        paramDefinitionId={commentParamDefinition?.id}
      />
    </>
  );
};
