import {boolean, number, object, SchemaOf, string} from 'yup';

import {ModelSpecificationRequestBody} from '../types/ModelSpecificationRequestBody';

export const $ModelSpecificationRequestBody: SchemaOf<ModelSpecificationRequestBody> =
  object().shape({
    seatCount: number().nullable(),
    firstRegistrationCountry: string().nullable(),
    isRegistered: boolean().nullable(),
    lastRegistrationCountry: string().nullable(),
    originCountry: string().nullable(),
    serviceContractMileageLimit: number().nullable(),
    serviceContractNote: string().nullable(),
    serviceContractUntil: string().nullable(),
    warrantyDate: string().nullable(),
    warrantyMileage: number().nullable(),
  });
