import {createSelector} from '@reduxjs/toolkit';

import {selectGeneralSettings} from '../generalSettings/selectors';

export const selectGeneralSettingsAd = createSelector(
  selectGeneralSettings,
  (state) => state?.advertisementSettings
);
export const selectPlatforms = createSelector(selectGeneralSettingsAd, (state) => state?.platforms);
export const listSelector = createSelector(selectPlatforms, (state) => state?.list);
export const orderSelector = createSelector(selectPlatforms, (state) => state?.order);

export const getAdPlatformsSettingsSelector = createSelector(
  [listSelector, orderSelector],
  (list, order) => order.map((i) => list[i])
);
