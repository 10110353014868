import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {selectAudit} from '../../../store/carAudit/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditCategoriesWithError, TConditionFormValue} from '../../../types/ConditionTypes';
import {AuditParamType} from '../types/AuditParamType';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {findByParamType} from '../utils/findByParamType';
import {getAuditFieldData} from '../utils/getAuditFieldData';

const SECOND_SET_KEY_PARAM_VALUE_MAP: Record<string, string> = {
  secondSetComplete: 'COMPLETE_SET',
  secondSetOnlyRims: 'RIMS_ONLY',
  secondSetOnlyPneus: 'TIRES_ONLY',
};

export const useSecondSetValidation = (categoryStructure?: AuditCategoryOfStructure) => {
  const auditData = useSelector(selectAudit);

  const secondSetCategory = useMemo(
    () =>
      categoryStructure?.childCategories?.filter(
        (relatedCategory) => relatedCategory?.uniqueKey === AuditCategoryUniqueKey.THE_SECOND_SET
      ),
    [categoryStructure?.childCategories]
  );

  const keyFromSecondSetValue = (value: string) =>
    Object.keys(SECOND_SET_KEY_PARAM_VALUE_MAP).find(
      (key) => SECOND_SET_KEY_PARAM_VALUE_MAP[key] === value
    );

  const wheelAndTyresErrorExist = (errors?: AuditCategoriesWithError) => {
    const wheelAndTyresFields: string[] = [];
    if (categoryStructure) {
      categoryStructure?.childCategories?.forEach((childCategory) =>
        wheelAndTyresFields.push(childCategory.id)
      );
    }
    return errors ? wheelAndTyresFields.some((id) => Object.keys(errors).includes(id)) : false;
  };

  const mapDataToArray = (
    idsToNotValidate: string[],
    category: AuditCategoryOfStructure,
    option?: AuditCategoryOfStructure
  ) => {
    option?.childCategories?.forEach((childCategory) => {
      idsToNotValidate.push(option.id);
      idsToNotValidate.push(category.id);
      idsToNotValidate.push(childCategory.id);

      childCategory?.paramDefinitions?.relatedActions?.forEach((paramDefinition) => {
        idsToNotValidate.push(paramDefinition.id);
      });
    });
  };

  const mapDataBySecondSetValue = (
    idsToValidate: string[],
    idsToNotValidate: string[],
    value?: TConditionFormValue,
    category?: AuditCategoryOfStructure,
    option?: AuditCategoryOfStructure
  ) => {
    // Second Set Toggle Off - Add Nested Fields To Remove From Validation
    if ((!Boolean(value) || value === 'NO') && !!category) {
      mapDataToArray(idsToNotValidate, category, option);
    }

    // Second Set Toggle On - Add Only Fields To Remove From Validation Whose Parent Isn't Checked
    if (value !== 'NO' && !!category) {
      if (option?.key !== keyFromSecondSetValue(value as string)) {
        mapDataToArray(idsToNotValidate, category, option);
      } else {
        option?.childCategories?.forEach((childCategory) => idsToValidate.push(childCategory.id));
      }
    }
  };

  const parseRadioButtonValue = (category?: AuditCategoryOfStructure) => {
    const secondSetToggleParam = findByParamType(
      category?.paramDefinitions?.relatedActions,
      AuditParamType.TOGGLE
    );

    const secondSetToggleValue = secondSetToggleParam?.values?.[0];

    const secondSetRadioButtonsParam = findByParamType(
      secondSetToggleValue?.content?.relatedActions,
      AuditParamType.RADIO_BUTTONS
    );

    const radioButtonFieldId = secondSetRadioButtonsParam?.id;

    return getAuditFieldData(category?.id as string, radioButtonFieldId, auditData ?? undefined);
  };

  const validateOnlySelectedRadioField = () => {
    const idsToNotValidate: string[] = [];
    const idsToValidate: string[] = [];
    if (secondSetCategory?.length) {
      const category = secondSetCategory[0];

      if (category) {
        const value = parseRadioButtonValue(category);
        category?.childCategories?.forEach((option) =>
          mapDataBySecondSetValue(idsToValidate, idsToNotValidate, value, category, option)
        );
      }
    }
    return {
      idsToNotValidate,
      idsToValidate,
    };
  };

  const filterSecondSetValidateError = (errors: AuditCategoriesWithError) => {
    const validateOnlySelectedOption = validateOnlySelectedRadioField();
    if (Object.keys(errors).length > 0) {
      if (validateOnlySelectedOption.idsToNotValidate.length > 0) {
        validateOnlySelectedOption.idsToNotValidate.filter(
          (categoryIdToRemove) => delete errors[categoryIdToRemove]
        );
      }

      const existAtLeastOneWheelAndTyresError = wheelAndTyresErrorExist(errors);

      if (validateOnlySelectedOption.idsToValidate.length > 0) {
        const existAtLeastOneSecondSetCategoryToValidate =
          validateOnlySelectedOption.idsToValidate.some((id) => Object.keys(errors).includes(id));
        if (
          !existAtLeastOneWheelAndTyresError &&
          !existAtLeastOneSecondSetCategoryToValidate &&
          categoryStructure?.id
        ) {
          delete errors[categoryStructure.id];
        }
      }

      if (
        !existAtLeastOneWheelAndTyresError &&
        validateOnlySelectedOption.idsToValidate.length === 0 &&
        categoryStructure?.id
      ) {
        delete errors[categoryStructure.id];
      }
    }
    return errors;
  };

  return {
    filterSecondSetValidateError,
  };
};
