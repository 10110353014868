import {useFormatCurrency, useFormatNumber} from 'platform/locale';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {MinipurchaseFilteredSpecifications} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {useTenant, useVehicleCatalogue, useVehicleMake, useVehicleModel} from '@omnetic-dms/shared';

import {isNotNilOrZero, Nullish} from 'shared';

export function useRequestSpecification(
  filteredSpecifications: MinipurchaseFilteredSpecifications | Nullish
) {
  const {tenantCurrency} = useTenant();
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  const [vehicleUtils] = useVehicleCatalogue(filteredSpecifications?.type);
  const [{makeOptions}] = useVehicleMake(filteredSpecifications?.type);
  const [{modelOptions}] = useVehicleModel(
    filteredSpecifications?.type,
    filteredSpecifications?.make
  );

  const type = vehicleUtils.getVehicleType(filteredSpecifications?.type);
  const make = makeOptions?.filter((option) =>
    filteredSpecifications?.make?.includes(option.value)
  );
  const models = modelOptions?.filter((option) =>
    filteredSpecifications?.modelFamily?.includes(option.value)
  );
  const transmissions = vehicleUtils.transmissionOptions?.filter((option) =>
    filteredSpecifications?.transmission?.includes(option.value)
  );
  const cubicCapacity = match([
    isNotNilOrZero(filteredSpecifications?.cubicCapacity?.[0]),
    isNotNilOrZero(filteredSpecifications?.cubicCapacity?.[1]),
  ])
    .with(
      [true, true],
      always(
        `${i18n.t('general.labels.from')} ${filteredSpecifications?.cubicCapacity?.[0]} ${i18n.t('general.metric.ccm')} - ${i18n.t('general.labels.to')} ${filteredSpecifications?.cubicCapacity?.[1]} ${i18n.t('general.metric.ccm')}`
      )
    )
    .with(
      [false, true],
      always(
        `${i18n.t('general.labels.to')} ${filteredSpecifications?.cubicCapacity?.[1]} ${i18n.t('general.metric.ccm')}`
      )
    )
    .with(
      [true, false],
      always(
        `${i18n.t('general.labels.from')} ${filteredSpecifications?.cubicCapacity?.[0]} ${i18n.t('general.metric.ccm')}`
      )
    )
    .with([false, false], always(null))
    .exhaustive();
  const power = match([
    isNotNilOrZero(filteredSpecifications?.power?.[0]),
    isNotNilOrZero(filteredSpecifications?.power?.[1]),
  ])
    .with(
      [true, true],
      always(
        `${i18n.t('general.labels.from')} ${filteredSpecifications?.power?.[0]} ${i18n.t('general.metric.kW')} - ${i18n.t('general.labels.to')} ${filteredSpecifications?.power?.[1]} ${i18n.t('general.metric.kW')}`
      )
    )
    .with(
      [false, true],
      always(
        `${i18n.t('general.labels.to')} ${filteredSpecifications?.power?.[1]} ${i18n.t('general.metric.kW')}`
      )
    )
    .with(
      [true, false],
      always(
        `${i18n.t('general.labels.from')} ${filteredSpecifications?.power?.[0]} ${i18n.t('general.metric.kW')}`
      )
    )
    .with([false, false], always(null))
    .exhaustive();
  const mileage = match([
    isNotNilOrZero(filteredSpecifications?.mileage?.[0]),
    isNotNilOrZero(filteredSpecifications?.mileage?.[1]),
  ])
    .with(
      [true, true],
      always(
        `${i18n.t('general.labels.from')} ${formatNumber(filteredSpecifications?.mileage?.[0], 0)} ${i18n.t('general.metric.km')} - ${i18n.t('general.labels.to')} ${formatNumber(filteredSpecifications?.mileage?.[1], 0)} ${i18n.t('general.metric.km')}`
      )
    )
    .with(
      [false, true],
      always(
        `${i18n.t('general.labels.to')} ${formatNumber(filteredSpecifications?.mileage?.[1], 0)} ${i18n.t('general.metric.km')}`
      )
    )
    .with(
      [true, false],
      always(
        `${i18n.t('general.labels.from')} ${formatNumber(filteredSpecifications?.mileage?.[0], 0)} ${i18n.t('general.metric.km')}`
      )
    )
    .with([false, false], always(null))
    .exhaustive();
  const price = match([
    isNotNilOrZero(filteredSpecifications?.price?.[0]),
    isNotNilOrZero(filteredSpecifications?.price?.[1]),
  ])
    .with(
      [true, true],
      always(
        `${i18n.t('general.labels.from')} ${formatCurrency(filteredSpecifications?.price?.[0] ?? 0, tenantCurrency, 0)} - ${i18n.t('general.labels.to')} ${formatCurrency(filteredSpecifications?.price?.[1] ?? 0, tenantCurrency, 0)}`
      )
    )
    .with(
      [false, true],
      always(
        `${i18n.t('general.labels.to')} ${formatCurrency(filteredSpecifications?.price?.[1] ?? 0, tenantCurrency, 0)}`
      )
    )
    .with(
      [true, false],
      always(
        `${i18n.t('general.labels.from')} ${formatCurrency(filteredSpecifications?.price?.[0] ?? 0, tenantCurrency, 0)}`
      )
    )
    .with([false, false], always(null))
    .exhaustive();
  const year = match([
    isNotNilOrZero(filteredSpecifications?.year?.[0]),
    isNotNilOrZero(filteredSpecifications?.year?.[1]),
  ])
    .with(
      [true, true],
      always(
        `${i18n.t('general.labels.from')} ${filteredSpecifications?.year?.[0] ?? 0} - ${i18n.t('general.labels.to')} ${filteredSpecifications?.year?.[1] ?? 0}`
      )
    )
    .with(
      [false, true],
      always(`${i18n.t('general.labels.to')} ${filteredSpecifications?.year?.[1] ?? 0}`)
    )
    .with(
      [true, false],
      always(`${i18n.t('general.labels.from')} ${filteredSpecifications?.year?.[0] ?? 0}`)
    )
    .with([false, false], always(null))
    .exhaustive();
  const fuelTypes = vehicleUtils.fuelTypeOptions?.filter((option) =>
    filteredSpecifications?.fuelType?.includes(option.value)
  );
  const drives = vehicleUtils.driveOptions?.filter((option) =>
    filteredSpecifications?.drive?.includes(option.value)
  );
  const features = vehicleUtils.featureOptions?.filter((option) =>
    filteredSpecifications?.features?.includes(option.value)
  );
  const bodyStyles = vehicleUtils.vehicleStyleOptions?.filter((option) =>
    filteredSpecifications?.bodyStyle?.includes(option.value)
  );
  const bodyColors = vehicleUtils.bodyColorOptions?.filter((option) =>
    filteredSpecifications?.bodyColor?.includes(option.value)
  );
  const vatDeductible = filteredSpecifications?.vatDeductible;

  return {
    type,
    make,
    models,
    transmissions,
    cubicCapacity,
    power,
    mileage,
    price,
    year,
    fuelTypes,
    drives,
    features,
    bodyStyles,
    bodyColors,
    vatDeductible,
  };
}
