import {useLayoutEffect} from 'react';

import {AnyFunction, useLatest} from 'shared';

type InferEventType<TTarget> = TTarget extends {
  // we infer from 2 overloads which are super common for event targets in the DOM lib
  // we "prioritize" the first one as the first one is always more specific
  addEventListener(type: infer P, ...args: any): void;
  // we can ignore the second one as it's usually just a fallback that allows bare `string` here
  // we use `infer P2` over `any` as we really don't care about this type value
  // and we don't want to accidentally fail a type assignability check, remember that `any` isn't assignable to `never`
  addEventListener(type: infer P2, ...args: any): void;
}
  ? P & string
  : never;

type InferEvent<TTarget, TType extends string> = `on${TType}` extends keyof TTarget
  ? Parameters<Extract<TTarget[`on${TType}`], AnyFunction>>[0]
  : Event;

/**
 * @about React hook to handle adding and removing event listeners.
 * @param {TTarget} target - The target to which the event listener will be attached.
 * @param {TType} type - The type of event to listen for.
 * @param {Function} listener - The callback to be invoked when the event is triggered.
 *  * @example
 *  * useEventListener(window, 'resize', callback);
 */
export function useEventListener<
  TTarget extends EventTarget,
  TType extends InferEventType<TTarget>,
>(target: TTarget, type: TType, listener: (event: InferEvent<TTarget, TType>) => void) {
  const latestListener = useLatest(listener);
  useLayoutEffect(() => {
    const handler: typeof listener = (ev) => latestListener.current(ev);

    if (!target) {
      return;
    }

    target.addEventListener(type, handler);
    return () => target.removeEventListener(type, handler);
  }, []);
}
