import {Separator} from 'platform/components';
import {Box, CSSDimension, Integer, Show, VStack, ValueByDevice} from 'platform/foundation';

import {ArticleFolderTree} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface ExternalFilterWithFolderTreeProps extends RequiredTestIdProps {
  externalFilterId: string;
  folderTreeRef?: (node: unknown) => void;
  width?: CSSDimension | Integer | ValueByDevice<CSSDimension | Integer>;
  externalFilterHeight?: CSSDimension | Integer | ValueByDevice<CSSDimension | Integer>;
}

export function ExternalFilterWithFolderTree(props: ExternalFilterWithFolderTreeProps) {
  return (
    <Box width={props.width} data-testid={props['data-testid']}>
      <VStack height="100%">
        <Box minHeight={props.externalFilterHeight}>
          <div id={props.externalFilterId} />
        </Box>
        <Show when={props.folderTreeRef}>
          <Separator />
          <ArticleFolderTree
            ref={props.folderTreeRef}
            data-testid={suffixTestId('articleFolderTree', props)}
          />
        </Show>
      </VStack>
    </Box>
  );
}
