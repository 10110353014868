import styled, {css, useTheme} from 'styled-components';

import {Fragment} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {getColorByPath} from '../../utils/getColorByPath';
import {getSize} from '../../utils/getSize';
import {SpinnerColor} from './types/SpinnerColor';
import {SpinnerSize} from './types/SpinnerSize';
import {SpinnerVariant} from './types/SpinnerVariant';

export interface SpinnerProps extends TestIdProps {
  size?: SpinnerSize;
  variant?: SpinnerVariant;
  color?: SpinnerColor;
}

export function Spinner(props: SpinnerProps) {
  const theme = useTheme();

  const cssSize = getSize(theme.components.Spinner.size[props.size ?? 'default']);
  const Overlay = props.variant === 'overlay' ? SpinnerOverlay : Fragment;
  const overlayProps =
    props.variant === 'overlay' ? {'data-testid': suffixTestId('spinner-overlay', props)} : {};

  return (
    <Overlay {...overlayProps}>
      <svg
        id="spinner"
        css={css`
          display: block;
          width: ${cssSize};
          height: ${cssSize};
          animation: rotate 1s linear infinite;

          circle {
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
            stroke: ${getColorByPath(theme.components.Spinner.color[props.color ?? 'dark'], theme)};
          }

          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }

          @keyframes dash {
            0% {
              stroke-dasharray: 1, 150;
              stroke-dashoffset: 0;
            }

            50% {
              stroke-dasharray: 90, 150;
              stroke-dashoffset: -35;
            }

            100% {
              stroke-dasharray: 90, 150;
              stroke-dashoffset: -124;
            }
          }
        `}
        viewBox="0 0 50 50"
        data-testid={suffixTestId('spinner', props)}
      >
        <circle
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth={theme.components.Spinner.thickness[props.size ?? 'default']}
        />
      </svg>
    </Overlay>
  );
}

const SpinnerOverlay = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({theme}) => theme.colors.palettes.white[10][80]};
`;
