import {Form, FormField, FormSubmitHandler, Label, showNotification} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {DeepPartial} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {
  useCreateBusinessCaseProformaInvoiceSettingMutation,
  useGetBusinessCaseProformaInvoiceSettingQuery,
  useUpdateBusinessCaseProformaInvoiceSettingMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError, useBranches} from '@omnetic-dms/shared';

import {NullishBoolean, useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type FormValues = {
  branchId: string;
  shouldGenerate: boolean;
};

const breadcrumbs = [
  {
    label: i18n.t('page.accountingSettings.labels.proformaCreation'),
    href: settingsRoutes.ProformaCreationOverview,
  },
];

export function ProformaCreationDetail() {
  const {id} = useParams();
  const isCreating = isNil(id);
  const navigate = useNavigate();
  const [updateProformaCreation] = useUpdateBusinessCaseProformaInvoiceSettingMutation();
  const [createProformaCreation] = useCreateBusinessCaseProformaInvoiceSettingMutation();

  const {
    data: proformaCreation,
    isLoading: isProformaCreationLoading,
    isError: isProformaCreationError,
  } = useGetBusinessCaseProformaInvoiceSettingQuery({id: id ?? ''}, {skip: isCreating});

  const {
    data: branches,
    branchOptions,
    isLoading: isBranchesLoading,
    isError: isBranchesError,
  } = useBranches();

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    const submitAction = isCreating ? createProformaCreation : updateProformaCreation;
    const proformaSettings = data.shouldGenerate
      ? 'with-proforma-invoice'
      : 'without-proforma-invoice';

    await submitAction({
      id: id ?? '',
      branchId: data.branchId,
      proformaInvoiceSettings: proformaSettings,
    })
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.ProformaCreationOverview))
      .catch(handleApiError);
  };

  const isLoading = isProformaCreationLoading || isBranchesLoading;
  const isError = isProformaCreationError || isBranchesError;

  const branchName =
    branches?.branchListItems.find((branch) => branch.id === proformaCreation?.branchId)
      ?.marketingName ?? i18n.t('page.accounting.labels.tenantDefinition');

  const defaultValues: DeepPartial<FormValues> = {
    shouldGenerate: proformaCreation?.proformaInvoiceSettings === 'with-proforma-invoice',
    branchId: proformaCreation?.branchId ?? undefined,
  };

  return (
    <SettingsTemplate
      header={{
        title: branchName,
        breadcrumbs,
      }}
      data-testid={testIds.settings.proformaGeneration('page')}
      isLoading={isLoading}
      isError={isError}
    >
      <Form<FormValues>
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        schema={getSchema(proformaCreation?.isSystem)}
      >
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  isNotClearable
                  options={branchOptions}
                  isDisabled={proformaCreation?.isSystem}
                  label={i18n.t('entity.cashRegister.parameters.branch')}
                  data-testid={testIds.settings.ProformaCreationCreate('branch')}
                  placeholder={
                    proformaCreation?.isSystem
                      ? i18n.t('page.accounting.labels.tenantDefinition')
                      : undefined
                  }
                  isRequired
                />
              </Grid>
            </SettingsSection>
            <VStack spacing={1}>
              <Label>{i18n.t('page.accountingSettings.labels.displayType')}</Label>
              <FormField
                control={control}
                type="checkbox"
                data-testid={testIds.settings.ProformaCreationCreate('shouldGenerate')}
                name="shouldGenerate"
                label={i18n.t('general.labels.yes')}
              />
            </VStack>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.ProformaCreationOverview),
                  variant: 'secondary',
                  'data-testid': testIds.settings.ProformaCreationCreate('discard'),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                  'data-testid': testIds.settings.ProformaCreationCreate('save'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const getSchema = (isSystem: NullishBoolean) =>
  object({
    branchId: yupString.when([], {
      is: isTrue(isSystem),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.default(null),
    }),
    shouldGenerate: boolean().default(false),
  });
