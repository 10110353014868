import {Flag, Tooltip} from 'platform/components';
import {HStack, Icon, Show} from 'platform/foundation';
import styled, {css} from 'styled-components';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {CountryFlag} from '@omnetic-dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {PlaceholderVehicleImage} from '../../../components/PlaceholderVehicleImage/PlaceholderVehicleImage';
import {detailFirstLine} from '../../../utils/detailFirstLine';
import {ComparisonTableActionButtons} from './ComparisonTableActionButtons';

const HeaderCellContent = styled.div`
  padding: 0 8px;
`;

const VehicleInfo = styled.div`
  min-height: 50px;
  display: flex;

  > span {
    margin-top: 2px;
    position: absolute;
  }
`;

type CarTitleProps = {
  soldCar?: boolean;
};

const CarTitle = styled.div<CarTitleProps>`
  font-size: ${({theme}) => theme.fontSizes.text.small};
  line-height: 1.34;
  font-weight: 700;
  text-align: left;
  padding: 0;
  margin: 0;
  letter-spacing: 0;
  color: ${({theme}) => theme.colors.palettes.neutral[600][100]};
  word-break: break-word;

  ${({soldCar, theme}) =>
    soldCar &&
    `
		color: ${theme.colors.palettes.neutral[400][100]};
	`}
`;

type CarHeaderImageType = {
  $soldCar?: boolean;
};

const CarHeaderImage = styled.div<CarHeaderImageType>`
  height: 100px;
  overflow: hidden;

  ${({$soldCar}) =>
    $soldCar &&
    `
		opacity: 0.6;
		filter: grayscale(1);
	`}

  .img-wrapper {
    height: 100%;
    width: 100%;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const SoldCar = styled.span`
  margin-right: 6px;
  padding: 2px 4px;
  font-size: ${({theme}) => theme.fontSizes.text.xxSmall};
  background: ${({theme}) => theme.colors.palettes.neutral[60][100]};
  color: ${({theme}) => theme.colors.palettes.white[10][100]};
  border-radius: ${({theme}) => theme.radii.small};
  line-height: 14px;
  letter-spacing: 0.32px;
  vertical-align: middle;
  white-space: nowrap;
`;

interface ColumnHeaderProps extends TestIdProps {
  vehicle: SourcingVehicleDetailResponseBody;
}

export function ColumnHeader(props: ColumnHeaderProps) {
  const {sourcingVehicle, vehicleSummary} = props.vehicle;
  const originalCar = sourcingVehicle.adId.includes('_DUPLICATE');
  return (
    <div>
      <CarHeaderImage $soldCar={!sourcingVehicle?.isAvailable}>
        <PlaceholderVehicleImage
          zoomThumbnail
          src={sourcingVehicle?.thumbnailUrl?.full}
          alt={sourcingVehicle?.make?.translation}
        />
      </CarHeaderImage>
      <HeaderCellContent>
        <HStack justify="flex-end" align="center" height={10} spacing={1}>
          <Show when={originalCar}>
            <Flag
              label={i18n.t('entity.vehicle.labels.original')}
              colorScheme="blue"
              size="small"
              data-testid={suffixTestId('originalCar', props)}
            />
          </Show>
          <ComparisonTableActionButtons
            vehicle={props.vehicle}
            adId={sourcingVehicle?.adId}
            data-testid={suffixTestId('comparisonTableActionButtons', props)}
          />
        </HStack>
        <VehicleInfo>
          {vehicleSummary?.comment ? (
            <Tooltip placement="top" label={vehicleSummary?.comment}>
              <Icon size={4} value="communication/comment" />
            </Tooltip>
          ) : null}
          <CarTitle
            soldCar={!sourcingVehicle?.isAvailable}
            css={css`
              text-indent: ${vehicleSummary?.comment ? '18px' : '0'};
            `}
          >
            {!sourcingVehicle?.isAvailable && (
              <SoldCar>{i18n.t('entity.vehicle.labels.sold')}</SoldCar>
            )}
            {detailFirstLine(sourcingVehicle)}
            <CountryFlag country={sourcingVehicle?.seller?.country?.key} />
          </CarTitle>
        </VehicleInfo>
      </HeaderCellContent>
    </div>
  );
}
