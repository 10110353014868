import {useWatch} from 'react-hook-form';

import {Nullish} from 'shared';

import {ShowWhenResponseBody} from '../types/modelSchema';

export function useElementVisibility(showWhen?: ShowWhenResponseBody | Nullish) {
  const value = useWatch({name: showWhen?.field ?? '', disabled: !showWhen});
  if (!showWhen) {
    return true;
  }

  return value === showWhen.isEqualTo;
}
