import {pick} from 'ramda';

import {SIZING_STYLE} from '../constants/sizingStyle';

type SizingProps = Extract<(typeof SIZING_STYLE)[number], keyof CSSStyleDeclaration>;

type SizingStyle = Pick<CSSStyleDeclaration, SizingProps>;

export type SizingData = {
  sizingStyle: SizingStyle;
  paddingSize: number;
  borderSize: number;
};

/**
 * @about Retrieves sizing data from the provided HTML element, including computed styles and specific measurements
 * for padding and border widths. This function is useful for exact size calculations, especially when
 * dealing with resizable text areas or inputs in a UI.
 * @param {HTMLElement} node - The HTML element from which to extract sizing data.
 * @returns {(SizingData | null)} An object containing the relevant sizing styles and measurements
 * if the element's computed style can be accessed and it is not detached from the DOM; otherwise, `null`.
 *
 * @example
 * const textArea = document.getElementById('myTextArea');
 * const sizingInfo = getSizingData(textArea);
 * if (sizingInfo) {
 *   console.log(`Padding size: ${sizingInfo.paddingSize}, Border size: ${sizingInfo.borderSize}`);
 * }
 */
export const getSizingData = (node: HTMLElement): SizingData | null => {
  const style = window.getComputedStyle(node);

  if (style === null) {
    return null;
  }

  const sizingStyle = pick(SIZING_STYLE, style);
  const {boxSizing} = sizingStyle;

  // probably node is detached from DOM, can't read computed dimensions
  if (boxSizing === '') {
    return null;
  }

  const paddingSize = parseFloat(sizingStyle.paddingBottom!) + parseFloat(sizingStyle.paddingTop!);

  const borderSize =
    parseFloat(sizingStyle.borderBottomWidth!) + parseFloat(sizingStyle.borderTopWidth!);

  return {
    sizingStyle,
    paddingSize,
    borderSize,
  };
};
