import {isEmpty} from 'ramda';

export const combineAccountNumberAndBankCode = (
  accountNumber: string,
  bankCode: string
): string => {
  if (isEmpty(accountNumber)) {
    return '';
  }
  if (isEmpty(bankCode)) {
    return accountNumber;
  }

  // Check if accountNumber contains a slash
  const hasSlash = /\//.test(accountNumber);

  // If accountNumber contains a slash, replace whatever is behind the slash with the bank code
  if (hasSlash) {
    return accountNumber.replace(/\/.*$/, `/${bankCode}`).trim();
  }

  // If accountNumber does not contain a slash, combine it with the bank code
  return `${accountNumber.trim()}/${bankCode}`;
};
