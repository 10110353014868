import {
  AttributesRow,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {defaultTo, isNil, isNotNil} from 'ramda';

import {
  PatchReservationServiceOrderRequest,
  useGetCustomerV2Query,
  useGetReservationServiceOrderQuery,
  useGetVehicleQuery,
  usePatchReservationServiceOrderMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  getCustomerName,
  handleApiError,
  isCustomerNaturalPerson,
  useAddress,
} from '@omnetic-dms/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {reservationFormSchema} from '../../../../../constants/reservationFormSchema';
import {getDefaultRequestExpiresAtTime} from '../../../../../utils/getDefaultRequestExpiresAtTime';
import {getRequestExpiresAtDate} from '../../../../../utils/getRequestExpiresAtDate';
import {ReservationFooter} from './ReservationFooter';
import {ReservationForm, ReservationInfo} from './ReservationInfo';
import {ServiceOrderIssueDetails} from './ServiceOrderIssueDetails';

interface ReservationServiceOrderIssueProps extends TestIdProps {
  articleId: string;
  reservationItemId: string;
  dispensingUnit: number;
  onSubmit: () => void;
}

export function ReservationServiceOrderIssue(props: ReservationServiceOrderIssueProps) {
  const {composeAddress} = useAddress();

  const {
    data: reservation,
    isLoading: isReservationLoading,
    isError: hasReservationError,
  } = useGetReservationServiceOrderQuery({
    articleId: props.articleId,
    requestId: props.reservationItemId,
  });

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: hasVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: defaultTo('', reservation?.vehicleId)},
    {skip: isNil(reservation)}
  );

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: hasCustomerError,
  } = useGetCustomerV2Query(
    {customerId: defaultTo('', reservation?.customerId)},
    {skip: isNil(reservation)}
  );

  const [patchReservation] = usePatchReservationServiceOrderMutation();

  const isLoading = isReservationLoading || isVehicleLoading || isCustomerLoading;
  const isError = hasReservationError || hasVehicleError || hasCustomerError;

  const handleSubmit: FormSubmitHandler<ReservationForm> = async (data) => {
    const reservationData: PatchReservationServiceOrderRequest['body'] = {
      requestExpiresAtDate: getRequestExpiresAtDate(
        data.requestExpiresAtDate,
        data.requestExpiresAtTime
      ),
      note: data.note,
    };

    await patchReservation({
      articleId: props.articleId,
      requestId: props.reservationItemId,
      body: reservationData,
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('entity.warehouse.notifications.reservationUpdated'))
      )
      .then(closeCurrentDialog)
      .then(props.onSubmit)
      .catch(handleApiError);
  };

  const customerName = getCustomerName(customer);

  const customerAddress =
    isNotNil(customer) && isCustomerNaturalPerson(customer)
      ? composeAddress(customer?.foundingPerson?.permanentAddress?.address)
      : composeAddress(customer?.businessInfo?.address?.address);

  const reservationDetails: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderIssueNumber'),
      value: reservation?.serviceOrderIssue?.number,
    },
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderNumber'),
      value: reservation?.serviceOrder.number,
    },
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderType'),
      value: reservation?.serviceOrder.type,
    },
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderVariant'),
      value: reservation?.serviceOrder.variant,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: vehicle?.title,
    },
    {
      label: i18n.t('entity.warehouse.labels.vin'),
      value: vehicle?.vin,
    },
    {
      label: i18n.t('entity.warehouse.labels.licencePlate'),
      value: vehicle?.state?.registrationPlate,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: customerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.address'),
      value: customerAddress,
    },
  ];

  const defaultValues: ReservationForm = {
    quantity: reservation?.quantity ?? props.dispensingUnit,
    requestExpiresAtDate: reservation?.requestExpiresAtDate
      ? parseDate(reservation.requestExpiresAtDate)
      : null,
    requestExpiresAtTime: reservation?.requestExpiresAtDate
      ? getDefaultRequestExpiresAtTime(reservation.requestExpiresAtDate)
      : null,
    note: reservation?.note,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<ReservationForm>
        defaultValues={defaultValues}
        schema={reservationFormSchema(false, props.dispensingUnit)}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <>
            <HStack>
              <Box flex={1}>
                <ReservationInfo
                  formApi={formApi}
                  control={control}
                  reservationItemId={props.reservationItemId}
                  dispensingUnit={props.dispensingUnit}
                  isQuantityDisabled={isNotNil(props.reservationItemId)}
                  data-testid={suffixTestId('info', props)}
                />
              </Box>
              <Separator orientation="vertical" />
              <Box flex={2}>
                <ServiceOrderIssueDetails
                  serviceOrderIssueId={defaultTo(
                    '',
                    reservation?.serviceOrderIssue?.serviceOrderIssueId
                  )}
                  caseId={defaultTo('', reservation?.serviceOrder.caseId)}
                  orderId={defaultTo('', reservation?.serviceOrder.orderId)}
                  rows={reservationDetails}
                  data-testid={suffixTestId('serviceOrderIssueInformation', props)}
                />
              </Box>
            </HStack>
            <ReservationFooter
              control={control}
              reservationItemId={props.reservationItemId}
              data-testid={suffixTestId('footer', props)}
            />
          </>
        )}
      </Form>
    </DataStatus>
  );
}
