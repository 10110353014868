import {Box, Text} from 'platform/foundation';

import {TestIdProps} from 'shared';

import {useTranslationContext} from '../../TranslationProvider/TranslationContext';

interface TextOnlySegmentProps extends TestIdProps {
  activePage: number;
  pagesQuantity: number;
}

export function TextOnlySegment(props: TextOnlySegmentProps) {
  const t = useTranslationContext();

  return (
    <Box paddingHorizontal={1}>
      <Text
        size="small"
        noWrap
      >{`${props.activePage} ${t('general.labels.of')} ${props.pagesQuantity}`}</Text>
    </Box>
  );
}
