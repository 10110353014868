import {
  DeleteWorkTypeApiArg,
  DeleteWorkTypeApiArgSchema,
  DeleteWorkTypeApiResponse,
  DeleteWorkTypeApiResponseSchema,
  GetDictionaryWorktypeApiArg,
  GetDictionaryWorktypeApiArgSchema,
  GetDictionaryWorktypeApiResponse,
  GetDictionaryWorktypeApiResponseSchema,
  GetDictionaryWorktypesApiArg,
  GetDictionaryWorktypesApiArgSchema,
  GetDictionaryWorktypesApiResponse,
  GetDictionaryWorktypesApiResponseSchema,
  PatchDictionaryWorktypeApiArg,
  PatchDictionaryWorktypeApiArgSchema,
  PatchDictionaryWorktypeApiResponse,
  PatchDictionaryWorktypeApiResponseSchema,
  PatchDictionaryWorktypeIsDefaultApiArg,
  PatchDictionaryWorktypeIsDefaultApiArgSchema,
  PatchDictionaryWorktypeIsDefaultApiResponse,
  PatchDictionaryWorktypeIsDefaultApiResponseSchema,
  PostDictionaryWorktypeApiArg,
  PostDictionaryWorktypeApiArgSchema,
  PostDictionaryWorktypeApiResponse,
  PostDictionaryWorktypeApiResponseSchema,
  PostWorkTypeSetAsActiveApiArg,
  PostWorkTypeSetAsActiveApiArgSchema,
  PostWorkTypeSetAsActiveApiResponse,
  PostWorkTypeSetAsActiveApiResponseSchema,
  PostWorkTypeSetAsInactiveApiArg,
  PostWorkTypeSetAsInactiveApiArgSchema,
  PostWorkTypeSetAsInactiveApiResponse,
  PostWorkTypeSetAsInactiveApiResponseSchema,
} from '../types/metadaWorkshopWorkType';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopWorkTypeApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postDictionaryWorktype: build.mutation<
      PostDictionaryWorktypeApiResponse,
      PostDictionaryWorktypeApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/codelist/work-type`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['workTypeList'],
      extraOptions: {
        responseSchema: PostDictionaryWorktypeApiResponseSchema,
        requestSchema: PostDictionaryWorktypeApiArgSchema,
      },
    }),
    getDictionaryWorktype: build.query<
      GetDictionaryWorktypeApiResponse,
      GetDictionaryWorktypeApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/codelist/work-type/${queryArg.workTypeId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'workType', id: queryArg.workTypeId}],
      extraOptions: {
        responseSchema: GetDictionaryWorktypeApiResponseSchema,
        requestSchema: GetDictionaryWorktypeApiArgSchema,
      },
    }),
    patchDictionaryWorktype: build.mutation<
      PatchDictionaryWorktypeApiResponse,
      PatchDictionaryWorktypeApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/codelist/work-type/${queryArg.workTypeId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'workTypeList',
        {type: 'workType', id: queryArg.workTypeId},
      ],
      extraOptions: {
        responseSchema: PatchDictionaryWorktypeApiResponseSchema,
        requestSchema: PatchDictionaryWorktypeApiArgSchema,
      },
    }),
    patchDictionaryWorktypeIsDefault: build.mutation<
      PatchDictionaryWorktypeIsDefaultApiResponse,
      PatchDictionaryWorktypeIsDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/codelist/work-type/${queryArg.workTypeId}/is-default`,
        method: 'PATCH',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'workTypeList',
        {type: 'workType', id: queryArg.workTypeId},
      ],
      extraOptions: {
        responseSchema: PatchDictionaryWorktypeIsDefaultApiResponseSchema,
        requestSchema: PatchDictionaryWorktypeIsDefaultApiArgSchema,
      },
    }),
    getDictionaryWorktypes: build.query<
      GetDictionaryWorktypesApiResponse,
      GetDictionaryWorktypesApiArg
    >({
      query: () => ({
        url: `/service/v1/codelist/work-types`,
      }),
      providesTags: ['workTypeList'],
      extraOptions: {
        responseSchema: GetDictionaryWorktypesApiResponseSchema,
        requestSchema: GetDictionaryWorktypesApiArgSchema,
      },
    }),
    deleteWorkType: build.mutation<DeleteWorkTypeApiResponse, DeleteWorkTypeApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/codelist/work-type/${queryArg.workTypeId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'workTypeList',
        {type: 'workType', id: queryArg.workTypeId},
      ],
      extraOptions: {
        responseSchema: DeleteWorkTypeApiResponseSchema,
        requestSchema: DeleteWorkTypeApiArgSchema,
      },
    }),
    postWorkTypeSetAsActive: build.mutation<
      PostWorkTypeSetAsActiveApiResponse,
      PostWorkTypeSetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/codelist/work-type/${queryArg.workTypeId}/set-as-active`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'workTypeList',
        {type: 'workType', id: queryArg.workTypeId},
      ],
      extraOptions: {
        responseSchema: PostWorkTypeSetAsActiveApiResponseSchema,
        requestSchema: PostWorkTypeSetAsActiveApiArgSchema,
      },
    }),
    postWorkTypeSetAsInactive: build.mutation<
      PostWorkTypeSetAsInactiveApiResponse,
      PostWorkTypeSetAsInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/codelist/work-type/${queryArg.workTypeId}/set-as-inactive`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'workTypeList',
        {type: 'workType', id: queryArg.workTypeId},
      ],
      extraOptions: {
        responseSchema: PostWorkTypeSetAsInactiveApiResponseSchema,
        requestSchema: PostWorkTypeSetAsInactiveApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetDictionaryWorktypeQuery,
  usePostDictionaryWorktypeMutation,
  usePatchDictionaryWorktypeMutation,
  usePatchDictionaryWorktypeIsDefaultMutation,
  useDeleteWorkTypeMutation,
  usePostWorkTypeSetAsActiveMutation,
  usePostWorkTypeSetAsInactiveMutation,
  useGetDictionaryWorktypesQuery,
} = metadaWorkshopWorkTypeApi;
