import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {GetManufacturersResponse} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getOptionsFromManufacturers = (
  manufacturers: GetManufacturersResponse | Nullish
): Option[] => {
  if (isNil(manufacturers)) {
    return [];
  }

  return manufacturers.map((manufacturer) => ({
    label: manufacturer.name ?? '',
    value: manufacturer.manufacturerId ?? '',
  }));
};
