import {clone, indexOf, last} from 'ramda';

import {DrillDownResponse, DrillDownLevelName} from '../../types/drillDown';

// TODO T20-30972 - fix response on BE
export function fixAlfaBugsInDrillDownResponse(
  requestLevelNames: DrillDownLevelName[],
  response: DrillDownResponse
): DrillDownResponse {
  const clonedResponse = clone(response);

  // this is bug in Alfa (levels in response are not sorted by request levels)
  clonedResponse.statisticsAggregated.forEach((item) => {
    item.levels.sort(
      (a, b) => indexOf(a.name, requestLevelNames) - indexOf(b.name, requestLevelNames)
    );
  });

  // this is bug in Alfa (there is missing level in response against request)
  clonedResponse.statisticsAggregated = clonedResponse.statisticsAggregated.filter(
    (item) => last(requestLevelNames) === last(item.levels)?.name
  );

  return clonedResponse;
}
