import {CheckboxTreeOptionType} from 'platform/components';

import {append} from 'ramda';
import {ceil} from 'ramda-adjunct';

import {sortByAsciiNumberLast} from 'shared';

import {getFeaturesPickerOptionsCount} from './getFeaturesPickerOptionsCount';

export const getFeaturesPickerRowOptions = (items: CheckboxTreeOptionType[]) => {
  const alphabeticallySortedItems = items.sort((a, b) => sortByAsciiNumberLast(a.label, b.label));
  const columnOptionsCount = ceil(getFeaturesPickerOptionsCount(alphabeticallySortedItems) / 2);

  let firstRow: CheckboxTreeOptionType[] = [];
  let secondRow: CheckboxTreeOptionType[] = [];
  let currentCount = 0;

  alphabeticallySortedItems.forEach((item) => {
    if (currentCount < columnOptionsCount) {
      firstRow = append(item, firstRow);
    } else {
      secondRow = append(item, secondRow);
    }
    currentCount = currentCount + 1 + getFeaturesPickerOptionsCount(item.options ?? []);
  });

  return [firstRow, secondRow] as const;
};
