import {IToolPanelComp} from '../types/AgGridTypes';

export class SettingsToolPanelWrapper implements IToolPanelComp {
  public eGui: HTMLElement;

  constructor() {
    this.eGui = document.createElement('div');
    this.eGui.className = 'ag-custom-settings-toolpanel';
  }

  refresh() {
    return;
  }

  init() {
    this.eGui = document.createElement('div');
    this.eGui.className = 'ag-custom-settings-toolpanel';
  }

  getGui(): HTMLElement {
    return this.eGui;
  }
}
