import {ButtonProps, closeCurrentDialog, openDialog, showNotification} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {equals, isNil, not} from 'ramda';
import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  BaseDirectSaleLabourItem,
  GetDirectSaleLabourItemResponse,
  useDeleteDirectSaleLabourBasketItemMutation,
  useDeleteDirectSaleLabourBasketItemsMutation,
  useGetDirectSaleLabourBasketItemsQuery,
  useLazyGetDirectSaleLabourItemQuery,
  usePatchDirectSaleLabourBasketItemQuantityMutation,
  usePostDirectSaleLabourBasketCheckoutMutation,
  usePostDirectSaleLabourItemToBasketMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  AfterSalesLabourBasket,
  EitherQuantityOrError,
  FullScreenModal,
  handleApiError,
  LabourBasketItemEditingDetails,
  useBasketMechanic,
  useInvalidBasketItemsIds,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabLabourItemEdit} from './RequestTabLabourItemEdit';
import {RequestTabLabourList} from './RequestTabLabourList';

interface AddLabourModalProps extends RequiredTestIdProps {
  directSaleId: string;
  authorizationProfileId?: string;
  onDiscard: () => void;
}

export function RequestTabLabourModal(props: AddLabourModalProps) {
  const [getDirectSaleLabourItem] = useLazyGetDirectSaleLabourItemQuery();

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: hasBasketError,
  } = useGetDirectSaleLabourBasketItemsQuery({directSaleId: props.directSaleId});

  const basketItems = basket?.workBasketItem ?? [];
  const basketTotalPrice = basket?.workBasketTotalPrice;
  const basketMechanicId = basket?.assignMechanicId;

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  const {mechanics, areMechanicsLoading, mechanicPayload, inputMechanicId, handleMechanicChange} =
    useBasketMechanic({
      authorizationProfileId: props.authorizationProfileId,
      assignedMechanicId: basketMechanicId,
    });

  const [checkoutLabourBasket, {isLoading: isCheckingOutBasket}] =
    usePostDirectSaleLabourBasketCheckoutMutation();

  const [emptyBasket, {isLoading: isDiscarding}] = useDeleteDirectSaleLabourBasketItemsMutation();

  const [addItemToBasket] = usePostDirectSaleLabourItemToBasketMutation();

  const [changeLabourBasketItemQuantity] = usePatchDirectSaleLabourBasketItemQuantityMutation();

  const [deleteLabourBasketItem, {isLoading: isDeletingItem}] =
    useDeleteDirectSaleLabourBasketItemMutation();

  const handleAdd = async () => {
    if (isNotNil(mechanicPayload)) {
      return await checkoutLabourBasket({
        directSaleId: props.directSaleId,
        body: {
          assignMechanics: mechanicPayload,
        },
      })
        .unwrap()
        .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.labourAdded')))
        .then(props.onDiscard)
        .catch(handleApiError);
    }

    await checkoutLabourBasket({
      directSaleId: props.directSaleId,
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.labourAdded')))
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleDiscard = async () => {
    await emptyBasket({directSaleId: props.directSaleId})
      .unwrap()
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleAddItemToBasket = async (labourCatalogId: string) => {
    await addItemToBasket({directSaleId: props.directSaleId, body: {labourCatalogId}})
      .unwrap()
      .catch(handleApiError);
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await changeLabourBasketItemQuantity({
      directSaleId: props.directSaleId,
      itemId,
      body: {quantity: quantity.newQuantity!},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleEdit = async (editingDetails: LabourBasketItemEditingDetails) => {
    if (isNil(editingDetails.itemId)) {
      return;
    }

    const labourItem = (await getDirectSaleLabourItem({
      itemId: editingDetails.itemId,
      directSaleId: props.directSaleId,
    })
      .unwrap()
      .catch(handleApiError)) as GetDirectSaleLabourItemResponse;

    const itemName = labourItem ? `${labourItem?.name} (${labourItem?.number})` : '';

    openDialog(
      <RequestTabLabourItemEdit
        directSaleId={props.directSaleId}
        item={labourItem}
        isEditingDisabled={false}
        authorizationProfileId={props.authorizationProfileId}
        onAfterSubmit={closeCurrentDialog}
        onClose={closeCurrentDialog}
        data-testid={suffixTestId('dialog.editLabour', props)}
      />,
      {
        title: itemName,
      }
    );
  };

  const handleDelete = async (itemId: string) => {
    await deleteLabourBasketItem({directSaleId: props.directSaleId, itemId})
      .unwrap()
      .catch(handleApiError);
  };

  const actions: ButtonProps[] = [
    {
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleDiscard,
      isLoading: isDiscarding,
      'data-testid': suffixTestId('headerActions.discard', props),
    },
    {
      title: i18n.t('general.actions.add'),
      variant: 'primary',
      onClick: handleAdd,
      isLoading: isCheckingOutBasket,
      isDisabled: isNilOrEmpty(basketItems) || isNotNilOrEmpty(invalidBasketItemsIds),
      'data-testid': suffixTestId('headerActions.add', props),
    },
  ];

  return (
    <FullScreenModal headline={i18n.t('general.actions.addWork')} actions={actions}>
      <Box padding={4} height="100%">
        <HStack spacing={4} height="100%">
          <Box flex={5}>
            <RequestTabLabourList
              directSaleId={props.directSaleId}
              onAddItemToBasket={handleAddItemToBasket}
              data-testid={suffixTestId('itemList', props)}
            />
          </Box>
          <Box flex={1} minWidth={86}>
            <AfterSalesLabourBasket<BaseDirectSaleLabourItem>
              basket={{
                items: basketItems,
                totalPrice: basketTotalPrice,
                isLoading: isBasketLoading,
                hasError: hasBasketError,
                hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                isDeletingItems: isDeletingItem,
              }}
              mechanic={{
                mechanics,
                areMechanicsLoading,
                selectedMechanicId: inputMechanicId,
                onMechanicChange: handleMechanicChange,
              }}
              onQuantityChange={handleQuantityChange}
              onEdit={handleEdit}
              onDelete={handleDelete}
              data-testid={suffixTestId('basket', props)}
            />
          </Box>
        </HStack>
      </Box>
    </FullScreenModal>
  );
}
