import {Day} from 'date-fns';

import {omit} from 'ramda';

import {getApiDateString, parseDate, suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {Placement} from '../../types/Placement';
import {CalendarFormValue, DisabledDate} from '../Calendar/types';
import {DatePicker} from '../DatePicker/DatePicker';
import {RelativeDate} from '../DatePicker/RelativeDate';

export interface APIDatePickerProps extends FormControlProps<string | null>, TestIdProps {
  disabledWeekdays?: Day[];
  disabledDates?: DisabledDate[];
  minDate?: Date;
  maxDate?: Date;
  placement?: Placement;
  relativeDates?: RelativeDate[];
  isRelativeDatesHidden?: boolean;
  isRecommended?: boolean;
  placeholder?: string;
}

export function APIDatePicker(props: APIDatePickerProps) {
  const onChange = (value: CalendarFormValue) => {
    if (Array.isArray(value)) {
      return;
    }
    props.onChange?.(value ? getApiDateString(parseDate(value)) : value);
  };
  return (
    <DatePicker
      {...omit(['onChange', 'data-testid', 'value'], props)}
      onChange={onChange}
      value={props.value ? parseDate(props.value) : null}
      data-testid={suffixTestId('apiDate', props)}
    />
  );
}
