import {FormControlProps} from 'platform/components';
import {Icon, Box, HStack, Text, getSize} from 'platform/foundation';
import {css} from 'styled-components';

import {DragDropContext, DragDropContextProps, Draggable, Droppable} from 'react-beautiful-dnd';

import {isNil, move} from 'ramda';

import {LevelConfiguration} from '../types';

type DrillDownLevelsConfiguratorProps = FormControlProps<LevelConfiguration[]>;

export function DrillDownLevelsConfigurator(props: DrillDownLevelsConfiguratorProps) {
  const onDragEnd: DragDropContextProps['onDragEnd'] = (result) => {
    if (isNil(result.destination)) {
      return;
    }

    props.onChange?.(move(result.source.index, result.destination.index, props.value));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(providedDroppable) => (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: ${getSize(1)};
              flex-wrap: wrap;
            `}
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
          >
            {props.value?.map((levelConfiguration, index) => (
              <Draggable
                key={levelConfiguration.name}
                draggableId={levelConfiguration.name}
                index={index}
              >
                {(providedDraggable) => (
                  <div
                    ref={providedDraggable.innerRef}
                    {...providedDraggable.draggableProps}
                    {...providedDraggable.dragHandleProps}
                  >
                    <Box
                      backgroundColor="palettes.neutral.40.100"
                      borderRadius="small"
                      paddingHorizontal={3}
                    >
                      <HStack height={8} align="center" spacing={1}>
                        <Icon value="action/drag_indicator" size={5} />
                        <Text size="small">{levelConfiguration.title}</Text>
                      </HStack>
                    </Box>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
