import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {BasicCategoryOfStructure} from '../../../types/BasicCategoryOfStructure';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';

const overallEvaluationCategories: string[] = [
  AuditCategoryUniqueKey.OVERALL_EVALUATION_OF_THE_TECHNICAL_CONDITION,
  AuditCategoryUniqueKey.OVERALL_EVALUATION_OF_THE_EXTERIOR,
  AuditCategoryUniqueKey.OVERALL_EVALUATION_OF_THE_INTERIOR,
];

export const isOverallEvaluationCategory = (
  category: AuditCategoryOfStructure | BasicCategoryOfStructure
) => overallEvaluationCategories.includes(category.uniqueKey);
