import {Card} from 'platform/components';
import {Grid} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';

import {
  selectBatteryTypes,
  selectHybridTypes,
  selectFuelTypes,
} from '../../../store/vehicleCatalogue/selectors';
import {VehicleType} from '../../../types/VehicleType';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';

interface HybridFullSectionProps {
  vehicleType: VehicleType;
}

export const HybridFull: FC<HybridFullSectionProps> = ({vehicleType}) => {
  const {Field} = useFormRenderer<VehicleCreateFormState>();
  const batteryTypes = useSelector(selectBatteryTypes(vehicleType));
  const fuelOptions = useSelector(selectFuelTypes(vehicleType));
  const hybridOptions = useSelector(selectHybridTypes(vehicleType));

  if (!fuelOptions.length) {
    return null;
  }
  if (!hybridOptions.length) {
    return null;
  }

  return (
    <>
      <Card variant="inlineGrey" title={i18n.t('entity.vehicle.labels.battery')}>
        <Grid columns={4}>
          <Field
            name="battery.batteryType"
            as="select"
            options={batteryTypes}
            label={i18n.t('entity.vehicle.labels.batteryType')}
          />
          <Field
            name="battery.batteryCapacityWh"
            as="integer"
            suffix={i18n.t('general.metric.kWh')}
            label={i18n.t('entity.vehicle.labels.batteryCapacity')}
          />
          <Field
            name="battery.batteryCapacityAh"
            as="integer"
            suffix={i18n.t('general.metric.Ah')}
            label={i18n.t('entity.vehicle.labels.batteryCapacity')}
          />
          <Field
            name="battery.batteryVoltage"
            as="integer"
            suffix={i18n.t('general.metric.V')}
            label={i18n.t('entity.vehicle.labels.batteryVoltage')}
          />
          <Field
            name="battery.batteryCount"
            as="integer"
            numberProps={{isStepperVisible: true}}
            label={i18n.t('entity.vehicle.labels.batteryCount')}
          />
          <Field
            name="battery.electricRange"
            as="integer"
            suffix={i18n.t('general.metric.km')}
            label={i18n.t('entity.vehicle.labels.batteryElectricRange')}
          />
          <Field
            name="battery.batteryMileageLimit"
            as="integer"
            suffix={i18n.t('general.metric.km')}
            label={i18n.t('entity.vehicle.labels.batteryMileageLimit')}
          />
          <Field
            name="battery.batteryWarrantyUntil"
            as="date"
            label={i18n.t('entity.vehicle.labels.batteryWarrantyUntil')}
          />
        </Grid>
        <Grid columns={4}>
          <Field
            name="battery.hasRangeExtender"
            as="checkbox"
            label={i18n.t('entity.vehicle.labels.hasRangeExtender')}
          />
        </Grid>
      </Card>
    </>
  );
};
