import {z} from 'zod';

import {BaseVoidResponseSchema, CreatedAndUpdatedBySchema, PaymentTypeSchema} from './base';
import {HeaderDynamicActionsFlagsSchema} from './metadaWarehouseHeaderDynamicFlags';

/**
 * GET requests
 */
export const GetDeliveryNoteRequestSchema = z.object({
  deliveryNoteId: z.string(),
});
export type GetDeliveryNoteRequest = z.infer<typeof GetDeliveryNoteRequestSchema>;

const DeliveryNoteStateSchema = z.enum(['PENDING', 'RECEIVED', 'COMPLETED']);

export const GetDeliveryNoteResponseSchema = z
  .object({
    deliveryNoteId: z.string(),
    supplierId: z.string(),
    warehouseId: z.string().optional(),
    authorizationProfileId: z.string(),
    state: DeliveryNoteStateSchema,
    deliveryNoteNumber: z.string().optional(),
    invoiceNumber: z.string().optional(),
    paymentType: PaymentTypeSchema.optional(),
    currency: z.string().optional(),
    exchangeRate: z.number().optional(),
    referenceNumber: z.string().optional(),
    ncConversion: z.string().optional(),
    issuedAt: z.string().optional(),
    isFromReceiveNote: z.boolean(),
    totalValue: z.number(),
    receiveNote: z.object({
      receiveNoteId: z.string().optional(),
      warehouseId: z.string().optional(),
      deliveryNoteId: z.string().optional(),
      supplierId: z.string().optional(),
      receiveNoteNumber: z.string().optional(),
      state: z.enum(['PENDING', 'COMPLETED']),
      paymentType: z.string().optional(),
      currency: z.string().optional(),
      exchangeRate: z.number().optional(),
      ncConversion: z.string().optional(),
      note: z.string().optional(),
      created: z.string().optional(),
      createdBy: z.string().optional(),
      updated: z.string().optional(),
      updatedBy: z.string().optional(),
      authorizationProfileId: z.string().optional(),
      invoiceNumber: z.string().optional(),
      invoiceDate: z.string().optional(),
      issuedAt: z.string().optional(),
      isFromReceiveNote: z.boolean().optional(),
      invoiceIssuedAt: z.string().optional(),
      invoiceIssueDate: z.string().optional(),
    }),
    markers: HeaderDynamicActionsFlagsSchema,
  })
  .merge(CreatedAndUpdatedBySchema);
export type GetDeliveryNoteResponse = z.infer<typeof GetDeliveryNoteResponseSchema>;

export const GetDeliveryNoteManualDownloadRequestSchema = z.object({
  params: z.object({dateFrom: z.string()}),
});
export type GetDeliveryNoteManualDownloadRequest = z.infer<
  typeof GetDeliveryNoteManualDownloadRequestSchema
>;

export const GetDeliveryNoteManualDownloadResponseSchema = BaseVoidResponseSchema;
export type GetDeliveryNoteManualDownloadResponse = z.infer<
  typeof GetDeliveryNoteManualDownloadResponseSchema
>;

/**
 * POST requests
 */
export const BulkPostReceiveNoteRequestSchema = z.object({
  deliveryNoteId: z.array(z.string()),
});
export type BulkPostReceiveNoteRequest = z.infer<typeof BulkPostReceiveNoteRequestSchema>;

export const BulkPostReceiveNoteResponseSchema = BaseVoidResponseSchema;
export type BulkPostReceiveNoteResponse = z.infer<typeof BulkPostReceiveNoteResponseSchema>;

/**
 * PATCH requests
 */
const PatchDeliveryNoteRequestBodySchema = z.object({
  warehouseId: z.string(),
  supplierId: z.string().nullable(),
  invoiceNumber: z.string(),
  paymentType: PaymentTypeSchema,
  currency: z.string(),
  exchangeRate: z.number(),
  ncConversion: z.string(),
});

export const PatchDeliveryNoteRequestSchema = z.object({
  deliveryNoteId: z.string(),
  body: PatchDeliveryNoteRequestBodySchema,
});
export type PatchDeliveryNoteRequest = z.infer<typeof PatchDeliveryNoteRequestSchema>;

export const PatchDeliveryNoteResponseSchema = BaseVoidResponseSchema;
export type PatchDeliveryNoteResponse = z.infer<typeof PatchDeliveryNoteResponseSchema>;

export const BulkPatchDeliveryNoteRequestSchema = z.object({
  deliveryNoteId: z.array(z.string()),
  warehouseId: z.string(),
  currency: z.string(),
});
export type BulkPatchDeliveryNoteRequest = z.infer<typeof BulkPatchDeliveryNoteRequestSchema>;

export const BulkPatchDeliveryNoteResponseSchema = BaseVoidResponseSchema;
export type BulkPatchDeliveryNoteResponse = z.infer<typeof BulkPatchDeliveryNoteResponseSchema>;

/**
 * DELETE requests
 */
export const BulkDeleteDeliveryNoteRequestSchema = z.object({
  deliveryNoteId: z.array(z.string()),
});
export type BulkDeleteDeliveryNoteRequest = z.infer<typeof BulkDeleteDeliveryNoteRequestSchema>;

export const BulkDeleteDeliveryNoteResponseSchema = BaseVoidResponseSchema;
export type BulkDeleteDeliveryNoteResponse = z.infer<typeof BulkDeleteDeliveryNoteResponseSchema>;
