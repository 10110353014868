import {
  FormControl,
  FormField,
  openDialog,
  TableRow,
  TableRowAction,
  Tooltip,
} from 'platform/components';
import {Box, HStack, Integer, Show, Space, Text, ThemeColorPath} from 'platform/foundation';

import {FieldValues, useFormContext} from 'react-hook-form';

import {isNilOrEmpty, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {
  PermissionScopeResponseBody,
  ProtectedGroupResponseBody,
  ProtectedUnitResponseBody,
  useGetRoleQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {buildArray, Nullish, suffixTestId, TestIdProps, useRequiredParams} from 'shared';

import {NEXT_LINE_OFFSET} from '../utils/consts';
import {createScopeString} from '../utils/createScopeString';
import type {Scope} from './ScopeForm/hooks/useScopes';
import {ScopeForm} from './ScopeForm/ScopeForm';

interface ActionItemProps extends TestIdProps {
  protectedUnit: ProtectedUnitResponseBody;
  resource: ProtectedGroupResponseBody;
  level?: number;
  actionState:
    | {
        value: boolean | Nullish;
        scopes: PermissionScopeResponseBody[] | Nullish;
      }
    | Nullish;
}

export function ActionItem(props: ActionItemProps) {
  const {roleId} = useRequiredParams();
  const {control, setValue} = useFormContext();

  const formControl = control as FormControl<FieldValues>;

  const scope = createScopeString(props.actionState?.scopes);

  const level = props.level ?? 0;

  const {data: role} = useGetRoleQuery({roleId});
  const isSystem = role?.system ?? false;

  const handleRemoveScope = () => {
    setValue(`protectedUnits.${props.protectedUnit.id}.scopes`, []);
  };

  const handleSaveScopes = (scopes: Scope[]) => {
    const submitScopes = scopes.map((scope) => {
      const matchedScope = props.protectedUnit.scopes.find((item) => item.id === scope.scopeId);

      return {
        id: scope.scopeId,
        name: matchedScope?.name,
        values: scope.optionIds.map((optionId) => ({
          id: optionId,
          name: matchedScope?.options.find((option) => option.id === optionId)?.name,
        })),
      };
    });

    setValue(`protectedUnits.${props.protectedUnit.id}.scopes`, submitScopes);
    setValue(`protectedUnits.${props.protectedUnit.id}.value`, true);
  };

  const rowActions = {
    primary: buildArray<TableRowAction>()
      .when(isNotNilOrEmpty(props.protectedUnit.scopes) && !isSystem, {
        title: isNilOrEmpty(props.actionState?.scopes)
          ? i18n.t('acl.dialog.button.submit')
          : i18n.t('general.actions.edit'),
        icon: 'image/edit',
        onClick: () =>
          openDialog(
            <ScopeForm
              protectedUnit={props.protectedUnit}
              resource={props.resource}
              roleId={roleId}
              defaultScopes={props.actionState?.scopes}
              onSubmit={handleSaveScopes}
            />,
            {
              scrollBehavior: 'outside',
              title: i18n.t('acl.dialog.headline.withConditions'),
            }
          ),
      })
      .when(isNotNilOrEmpty(props.actionState?.scopes) && !isSystem, {
        title: i18n.t('acl.dialog.button.removeScope'),
        icon: 'content/remove_circle',
        onClick: handleRemoveScope,
      }),
  };

  const rowColor: ThemeColorPath | undefined = isTrue(props.actionState?.value)
    ? undefined
    : 'palettes.red.10.100';

  return (
    <TableRow
      actions={rowActions}
      data-testid={suffixTestId('actionRow', props)}
      key={props.protectedUnit.id}
      color={rowColor}
    >
      <Box paddingHorizontal={2} paddingLeft={(1 + level * NEXT_LINE_OFFSET) as Integer}>
        <HStack spacing={8} align="center">
          <Space horizontal={8} />
          <FormField
            control={formControl}
            name={`protectedUnits.${props.protectedUnit.id}.value`}
            type="checkbox"
            isDisabled={isSystem}
            data-testid={suffixTestId('grant', props)}
          />
          <Box minWidth={50}>
            <Text size="small">{props.protectedUnit.name}</Text>
          </Box>
        </HStack>
      </Box>
      <Box>
        <Text size="small">
          {i18n.t(`acl.${props.resource.id.toLowerCase()}.${props.protectedUnit.id.toLowerCase()}`)}
        </Text>
      </Box>
      <Box
        paddingHorizontal={2}
        paddingVertical={1}
        data-testid={suffixTestId('permissionScope', props)}
      >
        <Show when={scope}>
          <Tooltip description={scope}>
            <Text size="small">{scope}</Text>
          </Tooltip>
        </Show>
      </Box>
    </TableRow>
  );
}
