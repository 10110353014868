import {Module} from '@omnetic-dms/api';
import {moduleKeys} from '@omnetic-dms/config';
import {featureFlags} from '@omnetic-dms/feature-flags';
import {sourcingRoutes} from '@omnetic-dms/routes';
import {permissions} from '@omnetic-dms/shared';

import {SourcingPageWithFiltersContext} from './components/SourcingPageWithFiltersContext/SourcingPageWithFiltersContext';
import {ClassifiedsPage} from './pages/Classifieds/ClassifiedsPage';
import {UserComparisonPage} from './pages/UserComparison/UserComparisonPage';
import {UserFavouritePage} from './pages/UserFavourite/UserFavouritePage';
import {UserHiddenPage} from './pages/UserHidden/UserHiddenPage';
import {UserToBuyPage} from './pages/UserToBuy/UserToBuyPage';
import {VehicleDetailPage} from './pages/VehicleDetail/VehicleDetailPage';

export const SourcingModule: Module = {
  id: moduleKeys.sourcing,
  requiredFeatureFlag: featureFlags.ACL_SOURCING,
  requiredPermission: permissions.sourcingOffersRead,
  layoutProps: {
    title: 'module.sourcing.title',
    icon: 'action/shopping_cart',
    iconV2: 'sidebar/shopping_cart',
  },
  routerProps: {
    path: sourcingRoutes.classifieds,
  },
  content: [
    {
      id: 'sourcing-classifieds',
      routerProps: {
        path: sourcingRoutes.classifieds,
        element: (
          <SourcingPageWithFiltersContext>
            <ClassifiedsPage />
          </SourcingPageWithFiltersContext>
        ),
      },
      layoutProps: {
        title: 'general.actions.allOffers',
        icon: 'sidebar/fiber_manual_record',
      },
    },
    {
      id: 'sourcing-classifieds_comparison',
      routerProps: {
        path: sourcingRoutes.classifieds_comparison,
        element: <UserComparisonPage />,
      },
      layoutProps: {
        title: 'entity.vehicle.labels.comparisionPage',
        icon: 'sidebar/fiber_manual_record_swap',
      },
    },
    {
      id: 'sourcing-classifieds_userToBuy',
      routerProps: {
        path: sourcingRoutes.classifieds_userToBuy,
        element: <UserToBuyPage />,
      },
      layoutProps: {
        title: 'entity.vehicle.labels.toBuyPage',
        icon: 'sidebar/fiber_manual_record_add',
      },
    },
    {
      id: 'sourcing-classifieds_userHidden',
      routerProps: {
        path: sourcingRoutes.classifieds_userHidden,
        element: <UserHiddenPage />,
      },
      layoutProps: {
        title: 'entity.vehicle.labels.hiddenPage',
        icon: 'sidebar/fiber_manual_record_hidden',
      },
    },
    {
      id: 'sourcing-classifieds_userFavourite',
      routerProps: {
        path: sourcingRoutes.classifieds_userFavourite,
        element: <UserFavouritePage />,
      },
      layoutProps: {
        title: 'general.actions.favourites',
        icon: 'sidebar/fiber_manual_record_favorite',
      },
    },
    {
      id: 'sourcing-vehicleDetail',
      routerProps: {
        path: sourcingRoutes.vehicleDetail,
        element: <VehicleDetailPage />,
      },
    },
  ],
};
