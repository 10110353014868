import {v4 as uuid} from 'uuid';

import {useRef, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {Store} from 'redux';
import {SagaReturnType, call, getContext} from 'redux-saga/effects';

import {browserStorageKey} from '@omnetic-dms/config';

import {selectActiveBranch} from '../store/user/selectors';
import {CancelError} from './customError';
import {noop} from './someTeasUtils';

export type RequiredParams = {
  authorization?: string;
  xBranch?: string;
};

export type ApiConfig = {
  // send X-Branch header in request
  sendBranch?: boolean;
  // cancel request when new one was called
  cancellable?: boolean;
};

export type ApiFuncParams<Params> = Omit<Params, 'authorization' | 'xBranch'> & RequiredParams;

export type ApiFunc<Params, Returned> = (params: Params) => Promise<Returned>;

export type CallApiFunc = <Returned, Params>(
  func: ApiFunc<Params, Returned>,
  params?: ApiFuncParams<Params>,
  config?: ApiConfig
) => Promise<Returned>;

type CallApiHook<Returned, Params> = (params?: ApiFuncParams<Params>) => Promise<Returned>;

const getAccessTokenFromCookie = () => sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);

export const createCallApi =
  (store: Store): CallApiFunc =>
  (func, params, config = {}) => {
    const state = store.getState();
    const authorization = getAccessTokenFromCookie();

    const sendBranch = config.sendBranch ?? true;
    const xBranch = sendBranch ? selectActiveBranch(state) : undefined;

    // eslint-disable-next-line no-restricted-syntax
    const allParams = {
      ...params,
      xBranch,
      authorization,
    } as unknown as Parameters<typeof func>[number];

    return func(allParams);
  };

type SagaCallApi = {
  // Context really can be any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any;
  fn: typeof noop;
} & CallApiFunc;

export function* callApiSaga<Returned, Params extends RequiredParams>(
  func: ApiFunc<Params, Returned>,
  args?: ApiFuncParams<Params>,
  config?: ApiConfig
): Generator<unknown, Returned> {
  const callApi = (yield getContext('callApi')) as SagaCallApi;
  const response = (yield call(callApi, func, args, config)) as SagaReturnType<typeof func>;
  return response;
}

/**
 * Wrap async function to be able to cancel pending promise if new one is called
 * This doesn't use axios cancelToken because our api definitions would need to accept the token
 * as a parameter.
 */
const useCancelRequest = <R, P>(func: (params: P) => Promise<R>): ((params: P) => Promise<R>) => {
  const requestId = useRef<string | null>(null);

  return useCallback(
    async (params: P) => {
      const currentRequestId = uuid();
      requestId.current = currentRequestId;

      const response = await func(params);

      if (requestId.current !== currentRequestId) {
        throw new CancelError();
      }
      return response;
    },
    [func]
  );
};

export const useCallApi = <Returned, Params>(
  func: ApiFunc<Params, Returned>,
  apiConfig: ApiConfig = {}
): CallApiHook<Returned, Params> => {
  const {sendBranch = true, cancellable = false} = apiConfig;
  const authorization = getAccessTokenFromCookie();
  const xBranch = useSelector(selectActiveBranch);

  const callApi = useCallback(
    (params?: ApiFuncParams<Params>) => {
      // eslint-disable-next-line no-restricted-syntax
      const allParams = {
        ...params,
        authorization,
        xBranch: sendBranch ? xBranch : undefined,
      } as unknown as Parameters<typeof func>[number];

      return func(allParams);
    },
    [authorization, sendBranch, func, xBranch]
  );
  const cancellableCallApi = useCancelRequest(callApi);
  return cancellable ? cancellableCallApi : callApi;
};
