import {useEffect} from 'react';

import {GridApi} from '../types/AgGridTypes';
import {FeGridSettings} from '../types/Api';
import {LineHeightMappingToPx} from '../utils/lineHeightToPx';

export const useHeaderHeight = (settings: FeGridSettings, gridApi?: GridApi) =>
  useEffect(() => {
    if (gridApi) {
      if (settings.columnGroupsVisible) {
        gridApi.setGroupHeaderHeight(LineHeightMappingToPx[settings.headerHeight]);
      } else {
        // first we have to set height to zero to hide content then to -1 to hide border, reasons can be found in ag-grid source code
        gridApi.setGroupHeaderHeight(0);
        gridApi.setGroupHeaderHeight(-1);
      }
      gridApi.setHeaderHeight(LineHeightMappingToPx[settings.headerHeight]);
      gridApi.refreshHeader();
    }
  }, [gridApi, settings.columnGroupsVisible, settings.headerHeight]);
