import {useSubscription} from '@apollo/client';
import {showNotification} from 'platform/components';

import {useState} from 'react';

import {isNil} from 'ramda';

import {
  BatchDownloadEventIdArg,
  BatchDownloadSaleVehicleEventIdArg,
  BatchDownloadServiceGalleryEventIdArg,
  BulkDownloadApiResponse,
  SEND_NOTIFICATION_SUBSCRIPTION,
  useDownloadSaleVehicleGalleryMutation,
  useDownloadServiceGalleryMutation,
  useLazyDownloadGalleryQuery,
  useLazyGetEventQuery,
  useLazyGetSentBackgroundNotificationQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {notificationTypes} from '../constants/notificationTypes';
import {downloadFile} from '../utils/downloadFile';
import {handleApiError} from '../utils/handleApiError';

export function useBatchDownload() {
  const [eventData, setEventData] = useState<BulkDownloadApiResponse | undefined>();

  const [triggerDownloadEvent, {isLoading: isDownloadTriggerLoading, isError: hasDownloadError}] =
    useLazyDownloadGalleryQuery();

  const [
    triggerSaleVehicleDownloadEvent,
    {isLoading: isSaleVehicleDownloadTriggerLoading, isError: hasSaleVehicleDownloadError},
  ] = useDownloadSaleVehicleGalleryMutation();

  const [
    triggerServiceAlbumDownloadEvent,
    {isLoading: isServiceVehicleAlbumTriggerLoading, isError: hasServiceAlbumDownloadError},
  ] = useDownloadServiceGalleryMutation();

  const [getDownloadEvent, {isLoading: isDownloadEventLoading}] = useLazyGetEventQuery();
  const [getBackgroundNotification] = useLazyGetSentBackgroundNotificationQuery();

  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data: subscription}) => {
      const backgroundNotificationId: string | null =
        subscription.data?.onSendNotification?.backgroundNotificationId;

      if (isNil(backgroundNotificationId)) {
        return;
      }

      getBackgroundNotification({
        id: backgroundNotificationId,
      })
        .unwrap()
        .then((notification) => {
          if (notification.type !== notificationTypes.BATCH_DOWNLOAD_STATE_CHANGED) {
            return;
          }

          getDownloadEvent({eventId: notification.payload.eventId})
            .unwrap()
            .then((downloadEvent) => {
              const filename = downloadEvent.data.bulkName ?? 'batch.zip';

              downloadFile(`${notification.payload.bulkObjectDownloadUrl}?filename=${filename}`);

              setEventData(downloadEvent);
            })
            .catch(handleApiError);
        })
        .catch(handleApiError);
    },
    fetchPolicy: 'no-cache',
    shouldResubscribe: false,
  });

  const handleDownload = (downloadArgs: BatchDownloadEventIdArg) => {
    setEventData(undefined);

    return triggerDownloadEvent(downloadArgs)
      .unwrap()
      .then((triggeredEvent) => {
        if (isNil(triggeredEvent?.eventId)) {
          showNotification.error(i18n.t('general.notifications.notFound'));
          return;
        }

        getDownloadEvent({eventId: triggeredEvent.eventId})
          .unwrap()
          .then((downloadEvent) => {
            setEventData(downloadEvent);
          })
          .catch(handleApiError);
      })
      .catch(handleApiError);
  };

  const handleSaleVehicleDownload = (downloadArgs: BatchDownloadSaleVehicleEventIdArg) => {
    setEventData(undefined);

    return triggerSaleVehicleDownloadEvent(downloadArgs)
      .unwrap()
      .then((triggeredEvent) => {
        if (isNil(triggeredEvent?.eventId)) {
          showNotification.error(i18n.t('general.notifications.notFound'));
          return;
        }

        return getDownloadEvent({eventId: triggeredEvent.eventId})
          .unwrap()
          .then((downloadEvent) => {
            setEventData(downloadEvent);
            return downloadEvent;
          })
          .catch(handleApiError);
      })
      .catch((error) => {
        if (error?.data?.errors?.[0]?.code === 'DMS-SV-AL-0013') {
          showNotification.error(i18n.t('entity.photo.notifications.noPhotosInAlbums'));
          return;
        }
        handleApiError(error);
      });
  };

  const handleServiceAlbumDownload = (downloadArgs: BatchDownloadServiceGalleryEventIdArg) => {
    setEventData(undefined);

    return triggerServiceAlbumDownloadEvent(downloadArgs)
      .unwrap()
      .then((triggeredEvent) => {
        if (isNil(triggeredEvent?.eventId)) {
          showNotification.error(i18n.t('general.notifications.notFound'));
          return;
        }

        return getDownloadEvent({eventId: triggeredEvent.eventId})
          .unwrap()
          .then((downloadEvent) => {
            setEventData(downloadEvent);
            return downloadEvent;
          })
          .catch(handleApiError);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  return {
    handleDownload,
    handleSaleVehicleDownload,
    handleServiceAlbumDownload,
    eventData,
    isLoading:
      isDownloadTriggerLoading ||
      isDownloadEventLoading ||
      isSaleVehicleDownloadTriggerLoading ||
      isServiceVehicleAlbumTriggerLoading,
    isError: hasDownloadError || hasSaleVehicleDownloadError || hasServiceAlbumDownloadError,
    isStatePending: eventData?.state === 'PENDING',
  };
}
