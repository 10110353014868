import {useGetUsersQuery} from '@omnetic-dms/api';

import {getUserName} from '../utils/getUserName';

export function useGetUserOptions() {
  const {data: users, isLoading} = useGetUsersQuery();

  return {
    userOptions: users
      ?.filter((user) => !user.blocked)
      .map((user) => ({
        label: getUserName(user) ?? '',
        value: user.id,
      })),
    isLoading,
  };
}
