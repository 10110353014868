import {Action, Card, closeCurrentDialog, openDialog} from 'platform/components';

import {isNotNil} from 'ramda';

import {FullVehicleResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes} from '@omnetic-dms/routes';

import {composePath, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ServiceVehicleForm} from '../ServiceVehicleForm/ServiceVehicleForm';
import {ServiceVehicleFormMode} from '../ServiceVehicleForm/types/ServiceVehicleFormMode';
import {ServiceVehicleFormVariant} from '../ServiceVehicleForm/types/ServiceVehicleFormVariant';
import {AftersaleVehicleOverview} from './components/AftersaleVehicleOverview';
import {VehicleMatchOrCreate} from './components/VehicleMatchOrCreate';

interface AftersalesVehicleWidgetCardProps extends RequiredTestIdProps {
  vehicle?: FullVehicleResponseBody | Nullish;
  onChange?: (vehicleId: string | null) => Promise<void> | void;
}

export function AftersalesVehicleWidgetCard(props: AftersalesVehicleWidgetCardProps) {
  const handleVehicleEdit = () => {
    if (!props.vehicle?.id) {
      return;
    }

    openDialog(
      <ServiceVehicleForm
        mode={ServiceVehicleFormMode.EDIT}
        variant={ServiceVehicleFormVariant.SIMPLE}
        vehicleId={props.vehicle.id}
        onSuccess={closeCurrentDialog}
        onDiscard={closeCurrentDialog}
        data-testid={suffixTestId('edit', props)}
      />,
      {title: i18n.t('entity.vehicle.labels.editVehicle'), withAdditionalFooter: true}
    );
  };

  const handleVehicleChange = () => {
    openDialog(
      <VehicleMatchOrCreate
        onVehicleSelect={(vehicleId) => {
          closeCurrentDialog();
          props.onChange?.(vehicleId);
        }}
        data-testid={suffixTestId('finder', props)}
      />,
      {title: i18n.t('entity.vehicle.labels.vehicle')}
    );
  };

  const actions: Action[] = [
    {
      title: i18n.t('general.labels.detail'),
      leftIcon: 'action/open_in_new',
      variant: 'link',
      type: 'button',
      onClick: () =>
        window.open(
          composePath(vehiclesRoutes.detail, {
            params: {id: props.vehicle?.id},
            isSearchParamsPreserved: false,
          })
        ),
    },
    {
      variant: 'link',
      type: 'button',
      title: i18n.t('general.actions.change'),
      leftIcon: 'action/autorenew',
      onClick: handleVehicleChange,
    },
    {
      variant: 'link',
      type: 'button',
      title: i18n.t('general.actions.edit'),
      leftIcon: 'image/edit',
      onClick: handleVehicleEdit,
    },
  ];

  return (
    <Card
      actions={isNotNil(props.vehicle) ? actions : undefined}
      title={i18n.t('entity.vehicle.labels.vehicle')}
      data-testid={suffixTestId('header', props)}
    >
      {props.vehicle ? (
        <AftersaleVehicleOverview
          vehicle={props.vehicle}
          data-testid={suffixTestId('overview', props)}
        />
      ) : (
        <VehicleMatchOrCreate
          onVehicleSelect={props.onChange}
          data-testid={suffixTestId('finder', props)}
        />
      )}
    </Card>
  );
}
