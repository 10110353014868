import {Dropdown, DropdownItem, IconButton, Tooltip} from 'platform/components';
import {HStack, Icon, Integer, Show, Space, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {MouseEvent, useEffect} from 'react';

import {any, isNil, range} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {TreeFolderAction, TreeFolderContextTarget} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {TestIdProps, suffixTestId, useBoolean} from 'shared';

import {useFolderItemActions} from '../hooks/useFolderItemActions';
import {useIsElementOverflowing} from '../hooks/useIsElementOverflowing';
import {FolderCallback} from '../types/FolderCallback';
import {getAllFolders} from '../utils/getAllFolders';

const BASE_LEVEL = 0;
const LEVEL_OFFSET = 1;

interface FolderItemProps extends TestIdProps {
  id?: string | null;
  label?: string | null;
  level?: Integer | null;
  actions?: (TreeFolderAction | null)[];
  subfolders?: (FolderItemProps | null)[];
  contextId?: string;
  contextTarget?: TreeFolderContextTarget;
  activeId?: string;
  isAllExpanded?: boolean;
  isLast?: boolean;
  isRoot?: boolean;
  onClick?: FolderCallback;
}

export const FolderItem = (props: FolderItemProps) => {
  const actions = useFolderItemActions(props);

  const [textRef, isTooltipVisible] = useIsElementOverflowing();

  const [isExpanded, expand, , toggleExpanded] = useBoolean(props.isAllExpanded || props.isRoot);
  const [isAllExpanded, expandAll, collapseAll] = useBoolean();

  useEffect(() => {
    const isAnySubfolderActive = any(
      (folder) => folder.id === props.activeId,
      getAllFolders(props.subfolders ?? [])
    );

    if (props.isAllExpanded || isAnySubfolderActive) {
      expand();
    }
  }, [props.isAllExpanded, expand, props.subfolders, props.activeId]);

  const handleItemClick = () =>
    props.onClick?.(props.id ?? undefined, props.contextId ?? undefined);

  const handleExpandClick = (event: MouseEvent) => {
    event.stopPropagation();
    toggleExpanded();
    if (isExpanded) {
      collapseAll();
    }
  };

  const handleExpandAllClick = (event: MouseEvent) => {
    event.stopPropagation();
    expandAll();
    expand();
  };

  const hasSubfolders = isNotNilOrEmpty(props.subfolders);
  const level = isNil(props.level) ? BASE_LEVEL : props.level + LEVEL_OFFSET;
  const isActive = props.id === props.activeId;

  return (
    <>
      <Tooltip label={isTooltipVisible ? props.label : undefined}>
        <Item onClick={handleItemClick} $isActive={isActive} data-testid={props['data-testid']}>
          {range(0, level).map((index) => (
            <HStack key={index} align="center" justify="flex-end" width={4} shrink={0}>
              <VStack>
                <VerticalLine />
                <VerticalLine $isHidden={index === level - 1 && !isExpanded && props.isLast} />
              </VStack>
              <HorizontalLine $isHidden={index < level - 1} />
            </HStack>
          ))}
          <Icon value={level === 0 ? 'file/folder' : 'file/folder_open'} size={4} />
          <Text ref={textRef} size="xSmall" noWrap>
            {props.label}
          </Text>
          <Space fillAvailable />
          <Show when={hasSubfolders}>
            <IconButton
              icon={isExpanded ? 'navigation/expand_less' : 'navigation/expand_more'}
              size="small"
              onClick={handleExpandClick}
              data-testid={suffixTestId('expandToggle', props)}
            />
          </Show>
          <Show when={isNotNilOrEmpty(actions) || hasSubfolders}>
            <Dropdown
              onButtonClick={(event) => event.stopPropagation()}
              dropdownControl={
                <IconButton
                  size="small"
                  icon="navigation/more_vert"
                  data-testid={suffixTestId('actions', props)}
                />
              }
            >
              <Show when={isNotNilOrEmpty(props.subfolders)}>
                <DropdownItem
                  label={i18n.t('general.actions.expandAll')}
                  onClick={handleExpandAllClick}
                />
              </Show>
              {actions?.map((item) => (
                <DropdownItem
                  key={item.label.toString()}
                  {...item}
                  data-testid={suffixTestId(item.label.toString(), props)}
                />
              ))}
            </Dropdown>
          </Show>
        </Item>
      </Tooltip>
      <Show when={isExpanded}>
        {props.subfolders?.map((folder, index) => (
          <FolderItem
            key={folder?.id}
            contextTarget={props.contextTarget}
            contextId={props.contextId ?? folder?.contextId}
            isLast={index === (props.subfolders?.length ?? 0) - 1}
            isAllExpanded={
              (isAllExpanded || props.isAllExpanded) && isNotNilOrEmpty(folder?.subfolders)
            }
            actions={folder?.actions}
            id={folder?.id}
            label={folder?.label}
            level={folder?.level}
            subfolders={folder?.subfolders}
            activeId={props.activeId}
            onClick={props.onClick}
            data-testid={suffixTestId(folder?.label ?? '', props)}
          />
        ))}
      </Show>
    </>
  );
};

const Item = styled.div<{$isActive?: boolean}>`
  min-height: ${({theme}) => theme.getSize(8)};
  padding-left: ${({theme}) => theme.getSize(2)};
  padding-right: ${({theme}) => theme.getSize(2)};
  border-radius: ${({theme}) => theme.radii.small};
  display: flex;
  align-items: center;
  gap: ${({theme}) => theme.getSize(1)};
  overflow: hidden;
  flex: 1;
  cursor: pointer;
  &:hover {
    background-color: ${({$isActive, theme}) =>
      !$isActive ? theme.colors.palettes.neutral[20][100] : undefined};
  }
  background-color: ${({$isActive, theme}) =>
    $isActive ? theme.colors.palettes.blue[20][100] : undefined};
`;

const VerticalLine = styled.div<{$isHidden?: boolean}>`
  height: ${({theme}) => theme.getSize(4)};
  border-right: 1px solid ${({theme}) => theme.colors.general.separator};
  opacity: ${({$isHidden}) => ($isHidden ? 0 : 1)};
`;

const HorizontalLine = styled.div<{$isHidden: boolean}>`
  width: ${({theme}) => theme.getSize(2)};
  border-bottom: 1px solid ${({theme}) => theme.colors.general.separator};
  opacity: ${({$isHidden}) => ($isHidden ? 0 : 1)};
`;
