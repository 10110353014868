import {z} from 'zod';

import {BaseVoidRequestSchema, BaseVoidResponseSchema} from './base';

/**
 * Base
 */
const ServiceOrderIssueVariantSchema = z.object({
  serviceOrderIssueVariantId: z.string(),
  name: z.string(),
});
export type ServiceOrderIssueVariant = z.infer<typeof ServiceOrderIssueVariantSchema>;

const ServiceOrderIssueDetailSchema = z.object({
  name: z.string(),
  branchId: z.string(),
  authorizationProfileId: z.string(),
  docSeriesId: z.string(),
  documentTemplateId: z.string(),
  isDefault: z.boolean(),
});
export type ServiceOrderIssueDetail = z.infer<typeof ServiceOrderIssueDetailSchema>;

const ServiceOrderIssueSetDefaultSchema = z.object({
  isDefault: z.boolean(),
});
export type ServiceOrderIssueSetDefault = z.infer<typeof ServiceOrderIssueSetDefaultSchema>;

/**
 * GET
 */
export const GetServiceOrderIssueVariantsRequestSchema = BaseVoidRequestSchema;
export type GetServiceOrderIssueVariantsRequest = z.infer<
  typeof GetServiceOrderIssueVariantsRequestSchema
>;

export const GetServiceOrderIssueVariantsResponseSchema = z.array(ServiceOrderIssueVariantSchema);
export type GetServiceOrderIssueVariantsResponse = z.infer<
  typeof GetServiceOrderIssueVariantsResponseSchema
>;

export const GetServiceOrderIssueVariantRequestSchema = z.object({
  serviceOrderIssueVariantId: z.string(),
});
export type GetServiceOrderIssueVariantRequest = z.infer<
  typeof GetServiceOrderIssueVariantRequestSchema
>;

export const GetServiceOrderIssueVariantResponseSchema = ServiceOrderIssueDetailSchema.merge(
  z.object({serviceOrderIssueVariantId: z.string()})
);
export type GetServiceOrderIssueVariantResponse = z.infer<
  typeof GetServiceOrderIssueVariantResponseSchema
>;

/**
 * POST
 */
export const PostServiceOrderIssueVariantRequestSchema = ServiceOrderIssueDetailSchema;
export type PostServiceOrderIssueVariantRequest = z.infer<
  typeof PostServiceOrderIssueVariantRequestSchema
>;

export const PostServiceOrderIssueVariantResponseSchema = z.object({
  serviceOrderIssueVariantId: z.string(),
});
export type PostServiceOrderIssueVariantResponse = z.infer<
  typeof PostServiceOrderIssueVariantResponseSchema
>;

/**
 * PATCH
 */
export const PatchServiceOrderIssueVariantRequestSchema = z.object({
  serviceOrderIssueVariantId: z.string(),
  body: ServiceOrderIssueDetailSchema,
});
export type PatchServiceOrderIssueVariantRequest = z.infer<
  typeof PatchServiceOrderIssueVariantRequestSchema
>;

export const PatchServiceOrderIssueVariantResponseSchema = BaseVoidResponseSchema;
export type PatchServiceOrderIssueVariantResponse = z.infer<
  typeof PatchServiceOrderIssueVariantResponseSchema
>;

export const PatchServiceOrderIssueVariantSetDefaultRequestSchema = z.object({
  serviceOrderIssueVariantId: z.string(),
  body: ServiceOrderIssueSetDefaultSchema,
});
export type PatchServiceOrderIssueVariantSetDefaultRequest = z.infer<
  typeof PatchServiceOrderIssueVariantSetDefaultRequestSchema
>;

export const PatchServiceOrderIssueVariantSetDefaultResponseSchema = BaseVoidResponseSchema;
export type PatchServiceOrderIssueVariantSetDefaultResponse = z.infer<
  typeof PatchServiceOrderIssueVariantSetDefaultResponseSchema
>;

/**
 * DELETE
 */
export const BulkDeleteServiceOrderIssueVariantRequestSchema = z.object({
  serviceOrderIssueVariantId: z.array(z.string()),
});
export type BulkDeleteServiceOrderIssueVariantRequest = z.infer<
  typeof BulkDeleteServiceOrderIssueVariantRequestSchema
>;

export const BulkDeleteServiceOrderIssueVariantResponseSchema = BaseVoidResponseSchema;
export type BulkDeleteServiceOrderIssueVariantResponse = z.infer<
  typeof BulkDeleteServiceOrderIssueVariantResponseSchema
>;
