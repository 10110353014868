import {Parameter, closeDialog, openDialog} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {Helmet} from 'react-helmet-async';

import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useGetCustomerV2Query,
  useGetDocumentContextCountQuery,
  useGetTireOrderQuery,
  useGetUserQuery,
  useGetVehicleQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  DetailTemplate,
  DetailTemplateHeader,
  EMPTY_PLACEHOLDER,
  SectionItem,
  getCustomerName,
  getUserName,
} from '@omnetic-dms/shared';

import {buildArray, parseDate} from 'shared';

import {useRefreshDataGrid} from 'features/datagrid';

import {tireInventorySearchParams} from '../../utils/tireInventorySearchParams';
import {Customer} from './(sections)/Customer/Customer';
import {TireOrderDocuments} from './(sections)/Documents/TireOrderDocuments';
import {SetOfTires} from './(sections)/SetOfTires/SetOfTires';
import {Vehicle} from './(sections)/Vehicle/Vehicle';
import {TireSetForm} from './components/TireSetForm';
import {useTiresInventoryUrl} from './hooks/useTiresInventoryUrl';

export function TireOrderDetail() {
  const {orderId} = useTiresInventoryUrl();
  const formatDateTime = useDateTimeFormatter();
  const [setDGRef, refreshSetDG] = useRefreshDataGrid();
  const [movementDGRef, refreshMovementDG] = useRefreshDataGrid();

  const {
    data: tireOrder,
    isLoading: isTireOrderLoading,
    isError: isTireOrderError,
  } = useGetTireOrderQuery({orderId});
  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: tireOrder?.vehicleId ?? ''},
    {skip: isNilOrEmpty(tireOrder?.vehicleId)}
  );
  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: isCustomerError,
  } = useGetCustomerV2Query(
    {customerId: tireOrder?.customerId ?? ''},
    {skip: isNilOrEmpty(tireOrder?.customerId)}
  );
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useGetUserQuery({id: tireOrder?.createdBy ?? ''}, {skip: isNilOrEmpty(tireOrder?.createdBy)});
  const {data: documentCount, isLoading: isDocumentCountLoading} = useGetDocumentContextCountQuery({
    contextTarget: 'tire-order',
    contextId: orderId,
  });

  const header: DetailTemplateHeader = {
    icon: 'modules/pneu_hotel',
    title: `${i18n.t('page.tiresInventory.labels.tireOrder')} ${
      tireOrder?.number ?? EMPTY_PLACEHOLDER
    }`,
    parameters: buildArray<Parameter>()
      .when(isNotNilOrEmpty(getCustomerName(customer)), getCustomerName(customer))
      .when(
        isNotNilOrEmpty(vehicle?.vehicle?.registrationPlate),
        vehicle?.vehicle?.registrationPlate
      )
      .when(isNotNilOrEmpty(vehicle?.vin), vehicle?.vin)
      .when(isNotNilOrEmpty(vehicle?.title), vehicle?.title)
      .when(
        isNotNilOrEmpty(getUserName(user)),
        `${i18n.t('general.labels.createdBy')}: ${getUserName(user)}`
      )
      .when(
        isNotNilOrEmpty(tireOrder?.createdAt),
        () =>
          `${i18n.t('general.labels.generated')}: ${formatDateTime(
            'dateShort',
            parseDate(tireOrder?.createdAt ?? '')
          )}`
      ),
    controls: ['ASSIGNEE'],
    recordId: orderId,
    resourceId: EntityResourceIds.tireOrder,
    actions: [
      {
        type: 'button',
        title: i18n.t('page.tiresInventory.labels.newTireSet'),
        onClick: () =>
          openDialog(
            <TireSetForm
              tireOrderId={orderId}
              onClose={() => closeDialog('newTireSet')}
              onCreate={() => {
                if (isNotNil(setDGRef.current)) {
                  refreshSetDG();
                }
                if (isNotNil(movementDGRef.current)) {
                  refreshMovementDG();
                }
              }}
              data-testid={testIds.tiresInventory.tireOrderDetail('tire-set-dialog')}
            />,
            {
              title: i18n.t('page.tiresInventory.labels.newTireSet'),
              size: 'large',
              id: 'newTireSet',
            }
          ),
      },
    ],
  };

  const sections = buildArray<SectionItem>([
    {
      id: tireInventorySearchParams.tireOrderDetial.setOfTires,
      label: i18n.t('page.tiresInventory.labels.setOfTires'),
      content: <SetOfTires movementDGRef={movementDGRef} setDGRef={setDGRef} />,
      'data-testid': testIds.tiresInventory.tireOrderDetail('navigation-setOfTires'),
    },
    {
      id: tireInventorySearchParams.tireOrderDetial.customer,
      label: i18n.t('page.tiresInventory.labels.customer'),
      content: <Customer />,
      hasSeparator: isNilOrEmpty(tireOrder?.vehicleId),
      'data-testid': testIds.tiresInventory.tireOrderDetail('navigation-customer'),
    },
  ])
    .when(isNotNilOrEmpty(tireOrder?.vehicleId), {
      id: tireInventorySearchParams.tireOrderDetial.vehicle,
      label: i18n.t('page.tiresInventory.labels.vehicle'),
      content: <Vehicle />,
      hasSeparator: true,
      'data-testid': testIds.tiresInventory.tireOrderDetail('navigation-vehicle'),
    })
    .add({
      id: tireInventorySearchParams.tireOrderDetial.documents,
      label: i18n.t('page.tiresInventory.labels.documents'),
      content: <TireOrderDocuments />,
      badge: documentCount?.count
        ? {value: documentCount.count, colorScheme: 'neutral'}
        : undefined,
      'data-testid': testIds.tiresInventory.tireOrderDetail('navigation-documents'),
    });

  const isLoading =
    isTireOrderLoading ||
    isVehicleLoading ||
    isCustomerLoading ||
    isUserLoading ||
    isDocumentCountLoading;
  const isError = isTireOrderError || isVehicleError || isCustomerError || isUserError;

  return (
    <>
      <Helmet title={i18n.t('page.tiresInventory.labels.tireOrderDetail')} />
      <DetailTemplate
        isLoading={isLoading}
        isError={isError}
        header={header}
        sections={sections}
        data-testid={testIds.tiresInventory.tireOrderDetail('page')}
      />
    </>
  );
}
