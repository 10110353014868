import {createSelector, Selector} from '@reduxjs/toolkit';

import {uniqBy} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import type {TeasState} from '../../types/TeasState';
import {VehicleCatalogueMakeModelItem} from '../../types/VehicleCatalogueMakeModelItem';
import {VehicleType} from '../../types/VehicleType';
import {getVehicleTypeEnumOptions} from '../../utils/getVehicleCatalogueEnumItems';
import {VehicleCatalogueState} from './reducer';

const selectNull: Selector<TeasState, null> = () => null;

export const selectVehicleCatalogue: Selector<TeasState, VehicleCatalogueState> = (state) =>
  state.vehicleCatalogue;

export const selectBatteryOwnershipTypes = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.batteryOwnershipTypes, vehicleType)
  );

export const selectBatteryOwnershipType = (
  value?: string | null,
  vehicleType?: VehicleType | null
) => {
  if (!value) {
    return selectNull;
  }

  return createSelector(
    selectBatteryOwnershipTypes(vehicleType),
    (items) => items?.find((item) => item.value === value) || {value, label: value}
  );
};

export const selectBatteryTypes = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.batteryTypes, vehicleType)
  );

export const selectBatteryType = (value?: string | null, vehicleType?: VehicleType | null) => {
  if (!value) {
    return selectNull;
  }

  return createSelector(
    selectBatteryTypes(vehicleType),
    (items) => items?.find((item) => item.value === value) || {value, label: value}
  );
};

export const selectBusStyles = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.busStyles, vehicleType)
  );

export const selectCarStyles = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.carStyles, vehicleType)
  );

export const selectCaravanStyles = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.caravanStyles, vehicleType)
  );

export const selectChargingConnectorTypes = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.chargingConnectorTypes, vehicleType)
  );

export const selectChargingConnectorType = (
  value?: string | null,
  vehicleType?: VehicleType | null
) => {
  if (!value) {
    return selectNull;
  }

  return createSelector(
    selectChargingConnectorTypes(vehicleType),
    (items) => items?.find((item) => item.value === value) || {value, label: value}
  );
};

export const selectMotorcycleStyles = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.motorcycleStyles, vehicleType)
  );

export const selectTruckStyles = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.truckStyles, vehicleType)
  );

export const selectSemiTruckStyles = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.semiTruckStyles, vehicleType)
  );

export const selectSemiTrailerStyles = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.semiTrailerStyles, vehicleType)
  );

export const selectTrailerStyles = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.trailerStyles, vehicleType)
  );

export const selectConstructionMachineStyles = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.constructionMachineStyles, vehicleType)
  );

export const selectAgriculturalMachineStyles = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.agriculturalMachineStyles, vehicleType)
  );

export const selectVanStyles = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.vanStyles, vehicleType)
  );

export const selectBodyColors = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.bodyColors, vehicleType)
  );

export const selectBodyColor = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectBodyColors(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectColorTypes = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.colorTypes, vehicleType)
  );

export const selectColorType = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectColorTypes(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectConditions = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.conditions, vehicleType)
  );

export const selectCondition = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectConditions(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectDoorCounts = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.doorCounts, vehicleType)
  );

export const selectDoorCount = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectDoorCounts(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectDrives = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.drives, vehicleType)
  );

export const selectDrive = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectDrives(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectEmissionClasses = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.emissionClasses, vehicleType)
  );

export const selectEmissionClass = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectEmissionClasses(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectFuelTypes = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.fuelTypes, vehicleType)
  );

export const selectFuelType = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectFuelTypes(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectHandDriveTypes = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.handDriveTypes, vehicleType)
  );

export const selectHandDriveType = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectHandDriveTypes(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectHybridTypes = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.hybridTypes, vehicleType)
  );

export const selectHybridType = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectHybridTypes(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectInteriorColors = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.interiorColors, vehicleType)
  );

export const selectInteriorColor = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectInteriorColors(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectInteriorMaterials = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.interiorMaterials, vehicleType)
  );

export const selectInteriorMaterial = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectInteriorMaterials(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectServiceBooks = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.serviceBooks, vehicleType)
  );

export const selectServiceBook = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectServiceBooks(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectServiceBookState = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.serviceBookStates, vehicleType)
  );

export const selectServiceBookStat = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectServiceBookState(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectTransmissions = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.transmissions, vehicleType)
  );

export const selectTransmission = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectTransmissions(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectVehicleFeatures = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.features, vehicleType)
  );

export const selectFeatureCategories = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) =>
    getVehicleTypeEnumOptions(state.featureCategories, vehicleType)
  );

export const selectVehicleTypes = createSelector(
  selectVehicleCatalogue,
  (state) => state.vehicleTypes
);

export const selectVehicleType = (value?: string | null) =>
  createSelector(selectVehicleTypes, (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectBodyStyles = (vehicleType?: VehicleType | null) => {
  if (vehicleType === 'VEHICLETYPE_PASSENGER_CAR') {
    return selectCarStyles(vehicleType);
  } else if (vehicleType === 'VEHICLETYPE_BUS') {
    return selectBusStyles(vehicleType);
  } else if (vehicleType === 'VEHICLETYPE_CARAVAN') {
    return selectCaravanStyles(vehicleType);
  } else if (vehicleType === 'VEHICLETYPE_MOTORCYCLE') {
    return selectMotorcycleStyles(vehicleType);
  } else if (vehicleType === 'VEHICLETYPE_TRUCK') {
    return selectTruckStyles(vehicleType);
  } else if (vehicleType === 'VEHICLETYPE_SEMI_TRUCK') {
    return selectSemiTruckStyles(vehicleType);
  } else if (vehicleType === 'VEHICLETYPE_VAN') {
    return selectVanStyles(vehicleType);
  } else if (vehicleType === 'VEHICLETYPE_SEMI_TRAILER') {
    return selectSemiTrailerStyles(vehicleType);
  } else if (vehicleType === 'VEHICLETYPE_TRAILER') {
    return selectTrailerStyles(vehicleType);
  } else if (vehicleType === 'VEHICLETYPE_CONSTRUCTION_MACHINE') {
    return selectConstructionMachineStyles(vehicleType);
  } else if (vehicleType === 'VEHICLETYPE_AGRICULTURAL_MACHINE') {
    return selectAgriculturalMachineStyles(vehicleType);
  } else {
    return selectCarStyles(vehicleType);
  }
};

export const selectBodyStyle = (value?: string | null, vehicleType?: VehicleType | null) =>
  createSelector(selectBodyStyles(vehicleType), (items) => {
    if (!value) {
      return undefined;
    }
    return items?.find((item) => item.value === value) || {value, label: value};
  });

export const selectVehicleMakes = (vehicleType?: VehicleType | null) =>
  createSelector(selectVehicleCatalogue, (state) => {
    const makes = vehicleType && state.makes[vehicleType] ? [...state.makes[vehicleType]] : [];

    const customMakes =
      vehicleType && state.customMakes[vehicleType] ? [...state.customMakes[vehicleType]] : [];

    const makesWithCustom = [
      // FIXME: Mess in database
      ...customMakes.filter((custom) => !makes.find((make) => make.value === custom.value)),
      ...makes,
    ].sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return makesWithCustom;
  });

export const selectVehicleMakeModels = createSelector(selectVehicleCatalogue, (state) => {
  const makeModels: Record<string, VehicleCatalogueMakeModelItem> = {};

  // merge makes and custom make's models
  Object.keys(state.makeModels).forEach((key) => {
    makeModels[key] = {
      ...state.makeModels[key],
      models: [...(state.makeModels[key]?.models || [])],
    };
  });

  Object.keys(state.customMakeModels).forEach((key) => {
    if (makeModels[key]) {
      makeModels[key].models = uniqBy(
        (model) => model.value,
        [...(makeModels[key]?.models || []), ...(state.customMakeModels[key]?.models || [])]
      );
    } else {
      makeModels[key] = {
        ...state.customMakeModels[key],
        models: [...(state.customMakeModels[key]?.models || [])],
      };
    }
  });

  // if make has no model, add custom model "Other"
  Object.keys(makeModels).forEach((key) => {
    if (!makeModels[key]?.models?.length) {
      makeModels[key].models.push({
        value: i18n.t('entity.vehicle.labels.otherLabel'),
        label: i18n.t('entity.vehicle.labels.otherLabel'),
        isCustom: true,
      });
    }
  });

  return makeModels;
});

export const selectVehicleMakeModel = (make?: string | null) =>
  createSelector(selectVehicleMakeModels, (makeModels) => {
    if (!make) {
      return undefined;
    }
    return makeModels[make];
  });

export const selectVehicleModels = createSelector(selectVehicleMakeModels, (makeModels) =>
  Object.values(makeModels).flatMap((makeModel) => makeModel.models)
);
