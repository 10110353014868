import styled from 'styled-components';

interface CounterContainerProps {
  $color: string;
  $extended?: boolean;
}

export const CounterContainer = styled.div<CounterContainerProps>`
  width: 1.143rem;
  height: 1.143rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({$color}) => $color};
  color: ${({theme}) => theme.colors.palettes.white[10][100]};
  border-radius: 50%;
  font-style: normal;
  font-weight: bold;
  font-size: 0.714rem;
  line-height: 0.5rem;
  margin-left: 0.571rem;

  ${({$extended, theme}) =>
    $extended &&
    `
		width: 100%;
		border-radius: ${theme.radii.medium};
		padding: 0 ${theme.getSize(1)};
	`}
`;
