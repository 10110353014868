import {
  FC,
  useMemo,
  useEffect,
  useState,
  useCallback,
  ReactNode,
  ReactElement,
  SetStateAction,
  Dispatch,
  MouseEventHandler,
} from 'react';

import {TestIdProps, suffixTestId} from 'shared';

import {expandContext} from './expandContext';
import {ExpandTd, Td, Tr} from './styles';
import {ColumnType, DataType, ExpandContext, ExpandType} from './types';

export type SimpleRowProps<TDataType extends DataType = DataType> = {
  index: number;
  children?: never;
  // Handler that is calling, whenever row's being toggled
  onRowExpanded?: (row: TDataType, newExpanded: boolean) => void;
  onRowClick?: MouseEventHandler<HTMLTableRowElement>;
  columns: ColumnType<TDataType>[];
  row: TDataType;
  ExpandContent?: FC<ExpandType<TDataType>>;
  className?: string;
  showCellDivider?: boolean;
  noZebra?: boolean;
  showRowDivider?: boolean;
  // Based on expand icon if the icon removed expand table will be hidden
  canExpand?: boolean;
} & TestIdProps;

export const SimpleRow = <TDataType extends DataType = DataType>(
  props: SimpleRowProps<TDataType>
): ReactElement => {
  const initialExpanded = Boolean(props.row.expanded) ?? false;
  const [expanded, setExpanded] = useState<boolean>(initialExpanded);

  const notifyOutside = useCallback(
    (currentExpanded: boolean) => {
      if (initialExpanded !== currentExpanded) {
        props?.onRowExpanded?.(props.row, currentExpanded);
      }
    },
    [initialExpanded, props]
  );

  const setExpandedNotify = useCallback<Dispatch<SetStateAction<boolean>>>(
    (newValue) => {
      if (typeof newValue === 'function') {
        setExpanded(newValue(expanded));
        notifyOutside(newValue(expanded));
      }
    },
    [notifyOutside, setExpanded, expanded]
  );

  useEffect(() => {
    setExpanded(initialExpanded);
  }, [initialExpanded]);

  const context = useMemo<ExpandContext>(
    () => [expanded, setExpandedNotify],
    [expanded, setExpandedNotify]
  );

  return (
    <expandContext.Provider value={context}>
      <SimpleRowImpl canExpand={Boolean(props.row.expand)} expanded={expanded} {...props} />
    </expandContext.Provider>
  );
};

const SimpleRowImpl = <TDataType extends DataType = DataType>({
  index,
  onRowClick,
  columns,
  row,
  ExpandContent,
  expanded,
  canExpand,
  className,
  showCellDivider,
  noZebra,
  showRowDivider,
  onRowExpanded,
  ...rest
}: SimpleRowProps<TDataType> & {expanded: boolean}): ReactElement => (
  <>
    <Tr
      hasBackground={noZebra !== true && Boolean(index % 2)}
      key={index}
      className={classNames(['simple-table-row', className])}
      onClick={onRowClick}
      data-testid={rest['data-testid']}
      {...rest}
    >
      {columns.map((column) => (
        <Td
          className="simple-table-td"
          key={column.id}
          showCellDivider={showCellDivider}
          showRowDivider={showRowDivider}
          align={column.align}
          data-testid={suffixTestId(column.id, rest)}
        >
          {
            (typeof column.Cell === 'function'
              ? column.Cell({row, column, index})
              : row[column.id]) as ReactNode
          }
        </Td>
      ))}
    </Tr>

    {ExpandContent && canExpand && (
      <Tr data-testid={suffixTestId(`expandedContent-tr`, rest)}>
        <ExpandTd
          data-testid={suffixTestId(`expandedContent-td`, rest)}
          colSpan={columns.length}
          className={expanded ? 'expanded' : 'collapsed'}
        >
          {expanded && (
            <ExpandContent
              data-testid={suffixTestId(`expandedContent`, rest)}
              index={index}
              row={row}
            />
          )}
        </ExpandTd>
      </Tr>
    )}
  </>
);

const classNames = (arr: Array<string | undefined>) => arr.filter(Boolean).join(' ');
