import {Link, Text} from 'platform/foundation';

import {useInRouterContext, Link as ReactLink} from 'react-router-dom';

import {suffixTestId, TestIdProps} from 'shared';

export interface BreadcrumbLinkProps extends TestIdProps {
  href?: string;
  children: string;
  isCurrentPage?: boolean;
}

export function BreadcrumbLink(props: BreadcrumbLinkProps) {
  const isInsideReactRouter = useInRouterContext();

  if (props.isCurrentPage) {
    return (
      <Text size="xSmall" data-testid={suffixTestId('breadcrumbLink', props)}>
        {props.children}
      </Text>
    );
  } else if (isInsideReactRouter) {
    // navigating inside react-router (with <Link>) is way faster then with classic <a href>
    return (
      <ReactLink to={props.href ?? '/'} data-testid={suffixTestId('breadcrumbLink', props)}>
        <Text size="xSmall" color="link">
          {props.children}
        </Text>
      </ReactLink>
    );
  }
  return (
    <Link
      size="xSmall"
      href={props.href ?? '/'}
      data-testid={suffixTestId('breadcrumbLink', props)}
      title={props.children}
    />
  );
}
