import type {CancelablePromise} from '../types/CancelablePromise';
import type {CreateRoleRequestBody} from '../types/CreateRoleRequestBody';
import type {FullRole} from '../types/FullRole';
import type {PatchRoleRequestBody} from '../types/PatchRoleRequestBody';
import type {RoleForRoleManagement} from '../types/RoleForRoleManagement';
import type {ShortRole} from '../types/ShortRole';
import {request as __request} from '../utils/request';

export class RoleService {
  /**
   * @returns FullRole
   * @throws ApiError
   */
  public static createRole({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: CreateRoleRequestBody;
  }): CancelablePromise<FullRole> {
    return __request({
      method: 'POST',
      url: '/dms/v1/acl/role',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ShortRole
   * @throws ApiError
   */
  public static getRoles({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Array<ShortRole>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/acl/role',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static deleteRole({
    roleId,
    authorization,
  }: {
    roleId: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'DELETE',
      url: '/dms/v1/acl/role/{roleId}',
      path: {
        roleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns FullRole
   * @throws ApiError
   */
  public static getRole({
    roleId,
    authorization,
  }: {
    roleId: string;
    authorization?: string;
  }): CancelablePromise<FullRole> {
    return __request({
      method: 'GET',
      url: '/dms/v1/acl/role/{roleId}',
      path: {
        roleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns FullRole
   * @throws ApiError
   */
  public static patchRole({
    roleId,
    authorization,
    requestBody,
  }: {
    roleId: string;
    authorization?: string;
    requestBody?: PatchRoleRequestBody;
  }): CancelablePromise<FullRole> {
    return __request({
      method: 'PATCH',
      url: '/dms/v1/acl/role/{roleId}',
      path: {
        roleId,
      },
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns RoleForRoleManagement
   * @throws ApiError
   */
  public static getRolesForRoleManagement({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Array<RoleForRoleManagement>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/user-management/roles',
      headers: {
        Authorization: authorization,
      },
    });
  }
}
