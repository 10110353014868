import {ActionReducerMapBuilder, AnyAction, CaseReducer, PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';

import {Selector, useSelector} from 'react-redux';

import type {Thunk} from '../hooks/useThunkDispatch';
import type {ApiException} from '../types/ApiException';
import type {TeasState} from '../types/TeasState';
import type {ApiConfig} from './api';
import {createAsyncThunk} from './createAsyncThunk';

type ApiFuncParams<Params> = Omit<Params, 'authorization' | 'xBranch'>;

/**
 * Base interface to inherit when using async api calls in store
 * Provides loading and error interface
 */
export type AsyncThunkState<T = unknown> = T & {
  loading: Record<string, boolean>;
  errors: Record<string, ApiException['error'] | undefined>;
};

/**
 * Provides thunk state information - error and loading status
 */
export interface ThunkState {
  loading?: boolean;
  error?: ApiException['error'];
}

/**
 * Returns thunk action which provides same interface as API call and passes
 * everything down returning result as payload. Also provides name of the
 * action and function that will return thunk state from provided store
 *
 * @param func Api call function to be called
 * @param onError On error callback (for showing toast - needs refactor)
 * @param apiConfig Api client config
 */
export const asyncThunkAction = <Returned, Params>(
  name: string,
  func: (params: Params) => Promise<Returned>,
  apiConfig?: ApiConfig
): {
  /**asyncThunkReducer
   * Action itself to use in extra reducers or
   */
  action: Thunk<Returned, ApiFuncParams<Params>>;
  /**
   * Name of the action used as a key for error and loading object
   */
  name: string;
  /**
   * Function that will return thunk state from provided store - error and loading status
   */
  useThunkState: (slice: Selector<TeasState, AsyncThunkState>) => ThunkState;
} => {
  const action = createAsyncThunk<Returned, ApiFuncParams<Params>>(
    name,
    async (params, {extra, rejectWithValue}) =>
      await extra.callApi(func, params, apiConfig || {}).catch((error) => rejectWithValue(error))
  );

  const useThunkState = (slice: Selector<TeasState, AsyncThunkState>): ThunkState => {
    const state = useSelector(slice);

    return {
      error: state.errors?.[name],
      loading: state.loading?.[name] || false,
    };
  };

  return {
    useThunkState,
    name,
    action,
  };
};

type AsyncThunkReducer<T> = <R, P>(
  thunk: Thunk<R, P>,
  onFulfilled?: CaseReducer<
    T,
    PayloadAction<R, string, {arg: P; requestId: string; requestStatus: 'fulfilled'}>
  >,
  onRejected?: CaseReducer<
    T,
    PayloadAction<unknown, string, {arg: P; requestId: string; requestStatus: 'rejected'}>
  >,
  onPending?: CaseReducer<T, AnyAction>
) => void;

type GenericAsyncThunk = Thunk<unknown, unknown>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith('/pending');
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('/rejected');
}

function isFulfilledAction(action: AnyAction): action is FulfilledAction {
  return action.type.endsWith('/fulfilled');
}

/**
 * Returns reducers for all async thunk states if provided - aggregation tool
 * @param builder Redux toolkit builder object from extra reducers
 */
export const getAsyncThunkReducer = <T extends AsyncThunkState>(
  builder: ActionReducerMapBuilder<T>
): {asyncThunkReducer: AsyncThunkReducer<T>; handleThunkStates: () => void} => {
  const handleThunkStates = () => {
    builder.addMatcher(isPendingAction, (state, action) => {
      state.loading[action.type.replace('/pending', '')] = true;
      state.errors[action.type.replace('/pending', '')] = undefined;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.loading[action.type.replace('/rejected', '')] = false;
      const error = action?.payload as AxiosError<ApiException>;
      if (error?.response?.data.validationErrors?.length) {
        state.errors[action.type.replace('/rejected', '')] = error.response.data.error;
      }
    });
    builder.addMatcher(isFulfilledAction, (state, action) => {
      state.loading[action.type.replace('/fulfilled', '')] = false;
    });
  };

  /**
   * Returns reducers for all async thunk states if provided - aggregation tool
   * @param thunk Thunk action
   * @param onFulfilled Success reducer
   * @param onRejected ApiError reducer
   * @param onPending Pending reducer
   */
  const asyncThunkReducer = <R, P>(
    thunk: Thunk<R, P>,
    onFulfilled?: CaseReducer<
      T,
      PayloadAction<R, string, {arg: P; requestId: string; requestStatus: 'fulfilled'}>
    >,
    onRejected?: CaseReducer<
      T,
      PayloadAction<unknown, string, {arg: P; requestId: string; requestStatus: 'rejected'}>
    >,
    onPending?: CaseReducer<T, AnyAction>
  ) => {
    if (onFulfilled) {
      builder.addCase(thunk.fulfilled, onFulfilled);
    }
    if (onRejected) {
      builder.addCase(thunk.rejected, onRejected);
    }
    if (onPending) {
      builder.addCase(thunk.pending, onPending);
    }
  };

  return {
    asyncThunkReducer,
    handleThunkStates,
  };
};
