import {useContext} from 'react';

import {ConfirmationDialogCtxProps} from '../types/ConfirmationDialogCtxProps';
import {ConfirmationDialogCtx} from '../utils/ConfirmationDialogCtx';

const useConfirmationDialogContext = (): ConfirmationDialogCtxProps =>
  useContext(ConfirmationDialogCtx);

type UseConfirmationDialog = {
  getConfirmation: () => Promise<void>;
};

export const useConfirmationDialog = (): UseConfirmationDialog => {
  const {openDialog} = useConfirmationDialogContext();

  const getConfirmation = (): Promise<void> =>
    new Promise((resolve, reject) => {
      openDialog({resolve, reject});
    });

  return {getConfirmation};
};
