/* eslint-disable no-nested-ternary */

/* eslint-disable no-restricted-syntax */
import {ReactNode, ReactElement} from 'react';
// eslint-disable-next-line no-restricted-imports

/**
 * @deprecated - use platform instead
 */
import {FieldArrayProps, FieldArray, FieldArrayRenderProps} from 'react-final-form-arrays';

import {Paths} from '../../../types/Paths';
import {PossibleObject} from '../../../types/PossibleObject';
import {Condition, ConditionProps} from './Condition';
import {FormField, FormFieldProps} from './FormField';
import {Subscribe, SubscribeProps} from './Subscribe';

/**
 * @deprecated - use platform instead
 */
export type FormFieldArrayRenderProps<
  T extends PossibleObject = undefined,
  K extends PossibleObject = undefined,
> = FieldArrayRenderProps<T, HTMLElement> & {
  /** Field component with property names in the objects within the array */
  Field: <V = any, Option = any, Multiple extends boolean = false>(
    props: FormFieldProps<T, K, Multiple>
  ) => ReactElement<FormFieldProps<T, K, Multiple>>;
  /** Subscribe component is used to listen to other fields value */
  Subscribe: <V = any>(props: SubscribeProps<T, K, V>) => ReactElement<SubscribeProps<T, K, V>>;
  /** Condition component with property names in the objects within the array */
  Condition: (props: ConditionProps<T, K>) => ReactElement<ConditionProps<T, K>>;
};

export type FormFieldArrayProps<
  /** Type of child items object to take names from */
  T extends PossibleObject = undefined,
  /** Type of object (passed down by Form component) to take name of this array field from */
  K extends PossibleObject = undefined,
> = Omit<FieldArrayProps<T, HTMLElement>, 'name' | 'label'> & {
  /** Name property in of the array in FormValues object */
  name: T extends undefined ? string : K extends undefined ? string : keyof K | Paths<K>;
  /** Used for defining field structure as a return function in place */
  children?: (props: FormFieldArrayRenderProps<T, K>) => ReactNode;
  /** Used for defining field structure as a standalone component */
  component?: () => (props: FormFieldArrayRenderProps<T, K>) => ReactElement;
};

/**
 * @deprecated - use platform FormField instead
 * Used for rendering arrays of object fields
 * It provides interface for defining name of the field property
 * Expect type of objects within the array for providing their field names
 * *Provides own Field component in render method*
 */
export function FormFieldArray<
  T extends PossibleObject = undefined,
  K extends PossibleObject = undefined,
>({name, children, component, ...rest}: FormFieldArrayProps<T, K>): ReactElement {
  const fieldArrayRenderer = (p: FieldArrayProps<T, HTMLElement>): ReactNode =>
    children
      ? children({
          ...p,
          Field: FormField,
          Condition,
          Subscribe,
        } as unknown as FormFieldArrayRenderProps<T, K>)
      : component
        ? [
            component()({
              ...p,
              Field: FormField,
              Condition,
              Subscribe,
            } as unknown as FormFieldArrayRenderProps<T, K>),
          ]
        : [null];
  return (
    <FieldArray
      key={name as string}
      name={name as string}
      {...rest}
      subscription={rest.subscription || {}}
    >
      {fieldArrayRenderer as any}
    </FieldArray>
  );
}
