import {Button, Choice, DataStatus, FormControl} from 'platform/components';
import {HStack, Heading, VStack} from 'platform/foundation';

import {UseFormReturn, useWatch} from 'react-hook-form';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {GetTenantV2ApiRes} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {useNavigate} from 'shared';

import {SettingsSection} from '../../../components/SettingsSection/SettingsSection';
import {FormValues} from '../types';

interface BillingInformationSelectProps {
  formApi: UseFormReturn<FormValues>;
  control: FormControl<FormValues>;
}

export function BillingInformationSelect(props: BillingInformationSelectProps) {
  const navigate = useNavigate();

  const billingInformation = useWatch({name: 'billingInformation', control: props.control});
  const primaryBillingInformation = billingInformation?.find((item) => item.isPrimary);
  const billingInformationOptions = billingInformation?.map(billingInformationToOption) ?? [];

  const handleSelectPrimaryBillingInformation = (billingInfoId: string | null) => {
    if (isNil(billingInfoId)) {
      return;
    }

    const primaryId = billingInformation?.findIndex((item) => item.id === billingInfoId);
    const prevPrimaryId = billingInformation?.findIndex((item) => item.isPrimary);

    if (prevPrimaryId >= 0) {
      props.formApi.setValue(`billingInformation.${prevPrimaryId}.isPrimary`, false);
    }

    props.formApi.setValue(`billingInformation.${primaryId}.isPrimary`, true);
  };

  const handleCreateNewBillingInformation = () => navigate(settingsRoutes.billingInformationNew);

  return (
    <VStack spacing={5}>
      <HStack justify="space-between" align="center">
        <Heading size={4}>{i18n.t('entity.customer.labels.billingInformation')}</Heading>
        <Button
          title={i18n.t('entity.customer.actions.addNewBillingInformation')}
          leftIcon="content/add_circle"
          variant="link"
          onClick={handleCreateNewBillingInformation}
        />
      </HStack>
      <SettingsSection>
        <DataStatus isEmpty={isNilOrEmpty(billingInformationOptions)}>
          <Choice
            options={billingInformationOptions}
            value={primaryBillingInformation?.id ?? null}
            onChange={handleSelectPrimaryBillingInformation}
            isNotClearable
          />
        </DataStatus>
      </SettingsSection>
    </VStack>
  );
}

const billingInformationToOption = (
  billingInformation: GetTenantV2ApiRes['billingInformation'][number]
) => ({
  label: billingInformation.contactInformation.companyName,
  value: billingInformation.id,
});
