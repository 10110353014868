import {ButtonGroup, DialogFooter} from 'platform/components';
import {Box, Heading, Icon, Show, Text, VStack} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useSignDocumentLocallyMutation, useSignDocumentRemotelyMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId} from 'shared';

import {StepContentProps} from '../types/StepContentProps';
import {getFirstErrorMessage} from '../utils/getFirstErrorMessage';

export function ErrorStep(props: StepContentProps) {
  const isCurrentProcessOnSite = props.signingStep === 'OnSite_error';

  const [, {error: remoteError}] = useSignDocumentRemotelyMutation({fixedCacheKey: 'signRemotely'});
  const [, {error: localError}] = useSignDocumentLocallyMutation({
    fixedCacheKey: 'singLocally',
  });

  const error = isCurrentProcessOnSite ? localError : remoteError;
  const firstErrorMessage = getFirstErrorMessage(error);

  const handleRedirect = () => {
    const redirectStep = isCurrentProcessOnSite
      ? 'OnSite_deviceSelect'
      : 'Remote_signatoriesSelect';

    props.setSigningStep(redirectStep);
  };

  return (
    <VStack spacing={4} align="center">
      <Icon value="content/alert-circle" size={20} color="general.white" />
      <Heading size={3}>{i18n.t('entity.settings.labels.connectDeviceError')}</Heading>

      <Show when={isNotNilOrEmpty(firstErrorMessage)}>
        <Box width={140}>
          <Text size="small" align="center">
            {firstErrorMessage}
          </Text>
        </Box>
      </Show>

      <Box width={140}>
        <Text size="small" color="secondary" align="center">
          {i18n.t('entity.settings.labels.connectDeviceDescriptionError')}
        </Text>
      </Box>

      <DialogFooter>
        <ButtonGroup
          align="right"
          buttons={[
            {
              variant: 'secondary',
              onClick: handleRedirect,
              title: i18n.t('general.actions.retry'),
              'data-testid': suffixTestId('retry', props),
            },
          ]}
        />
      </DialogFooter>
    </VStack>
  );
}
