import {Card} from 'platform/components';
import {VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {CommentsList} from './components/CommentsList';
import {NewComment} from './components/NewComment';

type CommentsTabProps = {
  vehicleId: string;
};

export function CommentsTab({vehicleId}: CommentsTabProps) {
  return (
    <Card title={i18n.t('page.comments.labels.comments')}>
      <VStack spacing={4}>
        <CommentsList vehicleId={vehicleId} />
        <NewComment vehicleId={vehicleId} />
      </VStack>
    </Card>
  );
}
