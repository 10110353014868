import {useParams} from 'react-router-dom';

import {useGetPromotionalPhotoQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {PromoPhotosForm} from '../../components/PromoPhotosForm/PromoPhotosForm';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function PromoPhotoDetail() {
  const {id} = useParams();
  const {data, isLoading} = useGetPromotionalPhotoQuery({promotionalPhotoId: id ?? ''});

  return (
    <SettingsTemplate
      header={{
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
          {
            label: i18n.t('page.salesSettings.labels.promotionalPhotos'),
            href: settingsRoutes.advertisingPromoPhotos,
          },
        ],
      }}
      isLoading={isLoading}
      data-testid="settings-advertising-promoPhotos-detail"
    >
      <PromoPhotosForm editData={data} />
    </SettingsTemplate>
  );
}
