export const segmentItemTheme = {
  parts: ['selected', 'disabled'],
  baseStyle: {
    cursor: 'pointer',
    marginLeft: 1,
    marginRight: 1,
    flexGrow: 1,
    alignContent: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    padding: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    m: 0,
    py: '3px',
    px: '7px',
    color: 'text.primary',
    fontSize: 'text.xSmall',
    fontWeight: 'regular',
    lineHeight: 'text.xSmall',
    letterSpacing: 0,
    bg: 'palettes.neutral.20.100',
    borderRadius: 'small',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'transparent',

    '& ~ *:not(style)': {
      ms: '4px',
    },

    _hover: {
      bg: 'palettes.neutral.40.100',
    },

    selected: {
      backgroundColor: 'palettes.white.10.100',
      boxShadow: 'elevation_2',
      color: 'text.primary',
      cursor: 'default',
      borderColor: 'palettes.neutral.40.100',

      _hover: {
        backgroundColor: 'palettes.white.10.100',
      },
    },

    disabled: {
      cursor: 'not-allowed',
      opacity: '60%',
    },
  },
  defaultProps: {
    size: 'default',
    variant: 'default',
  },
  sizes: {
    default: {},
  },
  variants: {
    default: {},
  },
};
