import type {PermissionsRecordType} from '../permissions';

export const workshopPermissions = {
  createCatalogueItem: {
    resourceId: 'LABOUR_CATALOGUE_ITEM',
    actionId: 'CREATE',
    scopes: [],
  },
  editCatalogueItem: {
    resourceId: 'LABOUR_CATALOGUE_ITEM',
    actionId: 'EDIT',
    scopes: [],
  },
} satisfies PermissionsRecordType;
