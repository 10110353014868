import {forwardRef, ForwardRefRenderFunction, MouseEvent} from 'react';
import {Link} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Brand, ButtonStyled, Icon} from './styles';
import {ButtonProps} from './types';

/**
 * @deprecated - use platform instead
 */
const ButtonWithRef: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {icon, iconRight, children, brand, href, disabled, onClick, ...rest},
  ref
) => {
  const onlyIcon = isNotNilOrEmpty(icon) && isNil(children);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
  };

  const DatButton = (
    <ButtonStyled
      {...rest}
      ref={ref}
      onlyIcon={onlyIcon}
      disabled={disabled}
      normal={onlyIcon && !rest.compact}
      as={href ? 'a' : 'button'}
      onClick={handleClick}
      data-testid={rest['data-testid']}
    >
      {Boolean(icon) && !iconRight && (
        <Icon data-testid="button-icon" onlyIcon={onlyIcon}>
          {icon}
        </Icon>
      )}
      {children}
      {Boolean(icon) && iconRight && (
        <Icon data-testid="button-icon" iconRight onlyIcon={onlyIcon}>
          {icon}
        </Icon>
      )}
      {Boolean(brand) && <Brand>{brand}</Brand>}
    </ButtonStyled>
  );

  return href ? (
    <Link to={href} data-testid={rest['data-testid']}>
      {DatButton}
    </Link>
  ) : (
    DatButton
  );
};

export const Button = forwardRef(ButtonWithRef);
