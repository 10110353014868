import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditParamValue} from '../../../types/AuditParamValue';
import {AuditParamType} from '../types/AuditParamType';
import {findByParamType} from './findByParamType';

export const getDamageValues = (category?: AuditCategoryOfStructure): AuditParamValue[] => {
  const damageValues: AuditParamValue[] = [];

  category?.childCategories?.forEach((category) => {
    if (!category.paramDefinitions?.relatedActions) {
      return;
    }

    const param = findByParamType(
      category.paramDefinitions.relatedActions,
      AuditParamType.SELECTABLE_BUTTON
    );

    param?.values.forEach((paramValue) => {
      if (damageValues.findIndex((damageValue) => damageValue.value === paramValue.value) >= 0) {
        return;
      }

      damageValues.push(paramValue);
    });
  });

  return damageValues;
};
