import {FormControl, Separator} from 'platform/components';
import {Grid, Heading, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {defaultTo, filter, indexBy, isNil, map, pipe} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {MoneyResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useInvoiceVatRatesContext} from '../context/useInvoiceVatRatesContext';
import {AccountingDocumentFormValues, VatTypeSummary} from '../types';
import {typedToPairs} from './utils';

interface AdditionalVatCalculationProps extends TestIdProps {
  vatTypeSummary: VatTypeSummary | Nullish;
  control: FormControl<AccountingDocumentFormValues>;
}

export function AdditionalVatCalculation(props: AdditionalVatCalculationProps) {
  const formatCurrency = useFormatCurrency();
  const {rateOptions} = useInvoiceVatRatesContext();

  const formatPrice = (money: MoneyResponseBody | Nullish) =>
    formatCurrency(parseFloat(money?.amount ?? ''), money?.currency ?? '', 2);

  if (isNil(props.vatTypeSummary)) {
    return null;
  }

  const vatRatesByValue = indexBy((item) => item.value, rateOptions);

  const additionalVatCalculation = pipe(
    defaultTo({}),
    typedToPairs,
    filter(([_, item]) => isNotNil(item.additionalCalculation)),
    map(([key, item]) => ({
      ...item,
      vatType: key,
      vatLabel: vatRatesByValue?.[key]?.label,
    }))
  )(props.vatTypeSummary);

  return (
    <Show when={isNotNilOrEmpty(additionalVatCalculation)}>
      <Heading size={4}>{i18n.t('entity.accounting.labels.additionalVatCalculation')}</Heading>
      <VStack spacing={2}>
        <Grid columns={4} key="labels-calculation" align="flex-end">
          <Text size="xSmall" color="tertiary">
            {i18n.t('general.labels.vatRate')}
          </Text>
          <Text size="xSmall" color="tertiary">
            {i18n.t('entity.lineItems.labels.totalPriceWithoutVat')}
          </Text>
          <Text size="xSmall" color="tertiary">
            {i18n.t('general.labels.vat')}
          </Text>
          <Text size="xSmall" color="tertiary">
            {i18n.t('entity.lineItems.labels.totalPriceWithVat')}
          </Text>
        </Grid>
        {additionalVatCalculation.map((calc, index) => (
          <>
            <Separator spacing={0} />
            <Grid columns={4} key={`${calc.vatType}-calculation`}>
              <Text
                size="xSmall"
                color="secondary"
                key={`${calc}-calculation`}
                data-testid={suffixTestId('label', props)}
              >
                {calc.vatLabel}
              </Text>
              <Text
                size="xSmall"
                color="secondary"
                data-testid={suffixTestId('priceWithoutVat', props)}
              >
                {formatPrice(calc.additionalCalculation?.priceWithoutVat)}
              </Text>
              <Text size="xSmall" color="secondary" data-testid={suffixTestId('vat', props)}>
                {formatPrice(calc.additionalCalculation?.vat)}
              </Text>
              <Text
                size="xSmall"
                color="secondary"
                data-testid={suffixTestId('priceWithVat', props)}
              >
                {formatPrice(calc.additionalCalculation?.priceWithVat)}
              </Text>
            </Grid>
          </>
        ))}
      </VStack>
    </Show>
  );
}
