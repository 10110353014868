import {Box} from 'platform/foundation';

import {useEffect, useRef} from 'react';

import {SourcingTableDispatch} from '@omnetic-dms/teas';

export const INFINITE_SCROLL_ELEMENT_ID = 'sourcing-table--scroll';

export function useInfiniteScroll(
  dispatch: SourcingTableDispatch,
  isLoading: boolean,
  isEnabled: boolean,
  nextPage: number
) {
  const loaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    function callback(entries: IntersectionObserverEntry[]) {
      if (!isLoading && entries[0].isIntersecting && entries[0].intersectionRatio >= 0) {
        dispatch({type: 'changePage', page: nextPage});
      }
    }

    const observer = new IntersectionObserver(callback, {
      root: document.getElementById(INFINITE_SCROLL_ELEMENT_ID),
      threshold: 0,
    });
    loaderRef.current && observer.observe(loaderRef.current);

    return () => observer.disconnect();
    // We need loaderRef.current to be a dependency, otherwise it doesn't work
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isEnabled, isLoading, loaderRef.current]);

  return () => <Box ref={loaderRef} />;
}
