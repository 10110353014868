import {AuditParamType} from '../types/AuditParamType';

export const getResetValueByParamType = (type: string) => {
  switch (type) {
    case AuditParamType.DATE:
    case AuditParamType.DROPDOWN:
      return null;

    case AuditParamType.COUNTER:
      return '0';

    default:
      return '';
  }
};
