import {ReactElement} from 'react';
// eslint-disable-next-line no-restricted-imports
import {Field} from 'react-final-form';

import {TestIdProps, suffixTestId} from 'shared';

import {PossibleObject} from '../../../types/PossibleObject';
import {FieldName} from '../types/FieldName';

export interface ConditionProps<T extends PossibleObject, K extends PossibleObject = undefined> {
  when: FieldName<T, K>;
  is?: unknown;
  not?: unknown;
  children: ReactElement;
}

/**
 * @deprecated - use platform instead
 */
export function Condition<
  /** Type of object (passed down by Form component) to take names from */
  T extends PossibleObject = undefined,
  /** Type of parent (passed down by Form component) the array is in */
  K extends PossibleObject = undefined,
>({when, is, not, children, ...rest}: ConditionProps<T, K> & TestIdProps): ReactElement {
  return (
    <Field
      name={when as string}
      subscription={{value: true}}
      data-testid={suffixTestId('conditionField', rest)}
    >
      {({input: {value}}) =>
        (is === undefined || value === is) && (not === undefined || value !== not) ? children : null
      }
    </Field>
  );
}
