import {Tooltip, Flag} from 'platform/components';
import {HStack} from 'platform/foundation';

import {useMemo, useState, forwardRef, Ref, useImperativeHandle} from 'react';

import {is, isNil} from 'ramda';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {ICellRenderer, ICellRendererParams} from '../../types/AgGridTypes';
import {DataGridFlagProps, FlagPropsGetter} from '../../types/FlagPropsGetter';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getItemTooltipProps} from './utils/getItemTooltipProps';

export interface FlagCellRendererProps extends ICellRendererParams {
  getTooltipProps?: TooltipGetter;
  getFlagProps?: FlagPropsGetter;
}

type ValueProps = Pick<DataGridFlagProps, 'colorScheme' | 'label' | 'children'>;

const isArray = is(Array);

function FlagCellRendererComponent(
  {getValue, value, getTooltipProps, data, node, getFlagProps}: FlagCellRendererProps,
  ref: Ref<ICellRenderer>
) {
  const [cellValue, setCellValue] = useState<ValueProps | ValueProps[]>(() =>
    getValue ? getValue() : value
  );

  useImperativeHandle(ref, () => ({
    refresh: ({getValue: getNewValue, value: newValue}) => {
      const newCellValue = getNewValue ? getNewValue() : newValue;
      if (newCellValue !== newValue) {
        setCellValue(newCellValue);
      }
      return true;
    },
  }));

  const tooltipProps = useTooltipProps(data, node, getTooltipProps);
  const flagRendererProps = useMemo(() => {
    if (getFlagProps && data) {
      return getFlagProps({data});
    }
    return {};
  }, [getFlagProps, data]);

  // @ts-ignore
  if (isNil(cellValue?.value)) {
    return null;
  }

  // @ts-ignore
  if (!isArray(cellValue?.value)) {
    // @ts-ignore
    const flagColor = cellValue?.style?.flagColor;
    // @ts-ignore
    const flagIsSubtle = cellValue?.style?.flagSubtle;
    // @ts-ignore
    const flagLabel = cellValue?.value?.label;

    return (
      <Tooltip {...tooltipProps}>
        {cellValue && (
          <Flag
            {...flagRendererProps}
            isSubtle={flagIsSubtle}
            colorScheme={flagColor}
            label={flagLabel}
          />
        )}
      </Tooltip>
    );
  }

  return (
    <HStack spacing={2}>
      {/* @ts-ignore */}
      {cellValue?.value?.map((cellValueItem, index) => {
        // @ts-ignore
        const flagColor = cellValue?.style[index]?.flagColor;
        // @ts-ignore
        const flagIsSubtle = cellValue?.style[index]?.flagSubtle;
        // @ts-ignore
        const flagLabel = cellValueItem?.label;

        return (
          <Tooltip
            key={`${cellValueItem?.label}-${index}`}
            {...getItemTooltipProps(index, tooltipProps)}
          >
            {cellValueItem && (
              <Flag
                {...flagRendererProps}
                isSubtle={flagIsSubtle}
                colorScheme={flagColor}
                label={flagLabel}
              />
            )}
          </Tooltip>
        );
      })}
    </HStack>
  );
}

export const FlagCellRenderer = forwardRef<ICellRenderer, FlagCellRendererProps>(
  FlagCellRendererComponent
);
