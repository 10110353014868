import {getPaymentTypes, loadRoundingStepList, AppDispatch, Thunk} from '@omnetic-dms/teas';

type ActionRT<T extends Thunk<any, any>> = ReturnType<ReturnType<T>>;

export const dispatchRoundingDefinitionActions = (
  dispatch: AppDispatch
): [ActionRT<(typeof getPaymentTypes)['action']>, ActionRT<typeof loadRoundingStepList>] => [
  dispatch(getPaymentTypes.action({})),
  dispatch(loadRoundingStepList()),
];
