import {css} from 'styled-components';

import {AriaRole, ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

export interface InlineProps extends TestIdProps {
  children?: ReactNode;
  role?: AriaRole;
}

export function Inline(props: InlineProps) {
  return (
    <span
      role={props.role}
      css={css`
        display: inline;
      `}
      data-testid={suffixTestId('inline', props)}
    >
      {props.children}
    </span>
  );
}
