import {isNil, any} from 'ramda';

import {ImageType} from '@omnetic-dms/teas';

type ImageTypeWithPosition = ImageType & {position?: number};

export const getPromoPhotosPositions = (
  albumPhotos: ImageTypeWithPosition[],
  movedPhotos: ImageTypeWithPosition[],
  index: number | null
) => {
  let movedSortedPhotos: (ImageTypeWithPosition & {movedPosition: number})[] = [];

  if (!isNil(index)) {
    movedSortedPhotos = albumPhotos.reduce(
      (memo, photo, currIndex) => {
        let toReturn: (ImageTypeWithPosition & {movedPosition?: number})[] = memo;

        if (currIndex === index) {
          toReturn = [...toReturn, ...movedPhotos];
        }
        if (!any((movedPhoto) => movedPhoto.id === photo.id, movedPhotos)) {
          toReturn = [...toReturn, photo];
        }

        return toReturn.map((photo, photoI) => ({...photo, movedPosition: photoI + 1}));
      },
      [] as (ImageTypeWithPosition & {movedPosition: number})[]
    );
  } else {
    movedSortedPhotos = [
      ...albumPhotos.filter(
        (photo) => !any((movedPhoto) => movedPhoto.id === photo.id, movedPhotos)
      ),
      ...movedPhotos,
    ].map((photo, photoI) => ({...photo, movedPosition: photoI + 1}));
  }

  const changedPromoPhotos = movedSortedPhotos.filter(
    (photo) => !!(photo.isPromoPhoto && photo.movedPosition !== photo.position)
  );

  return changedPromoPhotos.map((promoPhoto) => ({
    position: promoPhoto.movedPosition,
    vehiclePromoPhotoId: promoPhoto.id,
  }));
};
