import {Tooltip} from 'platform/components';

import {FC, useMemo} from 'react';
import {StylesConfig} from 'react-select';

import {isNotNil} from 'ramda-adjunct';

import {
  CreatableDropdown,
  CreatableDropdownProps,
  Dropdown,
  DropdownComponentEnhancedProps,
} from '../Dropdown/Dropdown';
import {useTextFieldContext} from './hooks/useTextFieldContext';
import {AdornmentWrapper, AdornmentWrapperButton, HelpIconWrapper} from './styles';
import {TextFieldContextProvider} from './TextFieldContext';
import {AdornmentOption} from './types';

const prefixDropdownStyles: StylesConfig<Record<string, unknown>, false> = {
  container: (provided) => ({
    ...provided,
    fontSize: 15,
  }),
  control: (provided) => ({
    ...provided,
    width: 80,
    minHeight: 'auto',
    border: 0,
    maxHeight: 30,
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
  }),
  menu: (provided) => ({
    ...provided,
    width: 'auto',
  }),
};

const StyledDropdown: FC<DropdownComponentEnhancedProps> = (props) => {
  const {disabled} = useTextFieldContext();
  return <Dropdown isDisabled={disabled} placeholder="" styles={prefixDropdownStyles} {...props} />;
};
const StyledCreatableDropdown: FC<CreatableDropdownProps> = (props) => {
  const {disabled} = useTextFieldContext();
  return (
    <CreatableDropdown
      isDisabled={disabled}
      placeholder=""
      styles={prefixDropdownStyles}
      {...props}
    />
  );
};

interface AdornmentComponentProps extends Omit<AdornmentOption, 'key'> {
  inputName?: string;
}

const AdornmentComponent: FC<AdornmentComponentProps> = ({
  name = 'endAdornment',
  disabled,
  inputName,
  Icon,
  render,
  disableFocusToInput,
  disableFocus,
  padless,
  onClick,
  dropdownProps,
  creatableDropdownProps,
  CustomDropdownRender,
  CustomCreatableDropdownRender,
  tooltipText,
  variant = 'default',
}) => {
  const {disabled: inputDisabled, focusInput} = useTextFieldContext();

  const isDisabled = disabled ?? inputDisabled;
  const isDropdown = useMemo(
    () => variant === 'dropdown' || variant === 'creatableDropdown',
    [variant]
  );

  const commonProps = useMemo(
    () => ({
      'data-testid': `text-field-${inputName}-adornment-${name}`,
      disabled: isDisabled,
      padless: padless || isDropdown,
      disabledFocus: disableFocus,
      tabIndex: disableFocus || isDisabled ? -1 : 0,
      onClick: () => {
        if (!disableFocusToInput && variant !== 'dropdown' && variant !== 'creatableDropdown') {
          focusInput();
        }

        onClick?.();
      },
      className: 'text-field-adornment',
    }),
    [
      variant,
      disableFocusToInput,
      onClick,
      isDisabled,
      padless,
      disableFocus,
      focusInput,
      isDropdown,
      name,
      inputName,
    ]
  );

  const finalRender = useMemo(() => {
    if (isNotNil(render)) {
      return render;
    }

    if (isNotNil(Icon)) {
      return <HelpIconWrapper inherit>{Icon}</HelpIconWrapper>;
    }

    return null;
  }, [render, Icon]);

  if (variant === 'button') {
    return (
      <Tooltip label={tooltipText || ''}>
        <AdornmentWrapperButton type="button" {...commonProps}>
          {finalRender}
        </AdornmentWrapperButton>
      </Tooltip>
    );
  }

  if (variant === 'dropdown') {
    return (
      <AdornmentWrapper {...commonProps} tabIndex={-1}>
        <TextFieldContextProvider focusInput={focusInput} disabled={isDisabled}>
          {CustomDropdownRender ? (
            CustomDropdownRender(StyledDropdown)
          ) : (
            <StyledDropdown {...dropdownProps} />
          )}
        </TextFieldContextProvider>
      </AdornmentWrapper>
    );
  }

  if (variant === 'creatableDropdown') {
    return (
      <AdornmentWrapper {...commonProps} tabIndex={-1}>
        <TextFieldContextProvider focusInput={focusInput} disabled={isDisabled}>
          {CustomCreatableDropdownRender ? (
            CustomCreatableDropdownRender(StyledCreatableDropdown)
          ) : (
            <StyledCreatableDropdown {...creatableDropdownProps} />
          )}
        </TextFieldContextProvider>
      </AdornmentWrapper>
    );
  }

  // Default variant
  return <AdornmentWrapper {...commonProps}>{finalRender}</AdornmentWrapper>;
};

export const Adornment = AdornmentComponent;
