import type {LoadAuditDataCategories} from './LoadAuditDataCategories';

export type LoadAuditDataResponseItemBody = {
  id: string;
  state: LoadAuditDataResponseItemBody.state;
  user: string | null;
  finishedAt: string | null;
  controlStructureId: string;
  controlStructureName?: string;
  vehicleId: string;
  updateAt: string | null;
  categories: Array<LoadAuditDataCategories>;
  inspectionType: LoadAuditDataResponseItemBody.inspectionType | null;
  inspectionNote: string | null;
};

export namespace LoadAuditDataResponseItemBody {
  export enum state {
    FOR_CHECK = 'for_check',
    IN_PROGRESS = 'in_progress',
    FINISHED_AUDIT = 'finished_audit',
    TO_REVISION = 'to_revision',
    TO_FINISHING = 'to_finishing',
    IN_REVISION = 'in_revision',
  }

  export enum inspectionType {
    VEHICLE_CONDITION = 'vehicleCondition',
    VALIDATION_INSPECTION = 'validationInspection',
    NON_VALIDATION_INSPECTION = 'nonValidationInspection',
    COMPLAINT = 'complaint',
  }
}
