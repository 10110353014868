import {useFormatNumber} from 'platform/locale';
import {Text} from 'recharts';

interface TickLabelProps {
  x: number;
  y: number;
  minValue: number;
  payload: {
    value: number;
  };
  currency: string;
}

export function MileageTickLabel({x, y, payload, minValue, ...rest}: TickLabelProps) {
  const formatNumber = useFormatNumber();

  return (
    <Text {...rest} x={x} y={y} textAnchor="middle">
      {`${formatNumber(payload.value === 0 ? minValue / 2 : payload.value)} km`}
    </Text>
  );
}
