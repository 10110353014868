import {Form, FormField, FormSubmitHandler} from 'platform/components';
import * as Yup from 'yup';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

export type JobInputForm = {
  job: string;
};

interface CustomJobProps extends TestIdProps {
  onSubmit: FormSubmitHandler<JobInputForm>;
}

export function JobInput(props: CustomJobProps) {
  const handleSubmit: FormSubmitHandler<JobInputForm> = (values, setErrors, reset) => {
    reset();
    return props.onSubmit(values, setErrors, reset);
  };

  return (
    <Form<JobInputForm>
      schema={schema}
      onSubmit={handleSubmit}
      data-testid={suffixTestId('job', props)}
    >
      {(control, formApi) => (
        <FormField
          control={control}
          name="job"
          type="text"
          isDisabled={formApi.formState.isSubmitting}
          placeholder={i18n.t('entity.orderRequest.labels.customRequestPlaceholder')}
          data-testid={suffixTestId('job', props)}
        />
      )}
    </Form>
  );
}

const schema = Yup.object().shape({
  job: Yup.string().nullable().required(),
});
