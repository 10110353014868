import {FC, useEffect} from 'react';
import {useWatch} from 'react-hook-form';

import {useFormContextWithSubmit} from '../hooks/useFormContextWithSubmit';

interface FormWatcherProps {
  name: string;
}

export const FormWatcher: FC<FormWatcherProps> = ({name}) => {
  const {form, onSubmit} = useFormContextWithSubmit();
  const field = useWatch({name});

  useEffect(() => {
    // waiting form fields to unregister

    if (form.formState.isDirty) {
      setTimeout(() => {
        onSubmit();
      }, 50);
    }
  }, [field]);

  return <></>;
};
