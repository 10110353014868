import {CSSObject} from '@chakra-ui/react';

import {AgGridTheme} from './AgGrid.theme';
import {dgIcons} from './dgIcons.theme';

export const dataGridGlobalTheme: CSSObject = {
  ...AgGridTheme,
  '.ag-dnd-ghost': {
    padding: '8px 16px',
    border: '1px solid',
    borderColor: 'gray.200',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.12)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '0',
    transform: 'translateY(18px) translateX(10px)',
    height: 'auto !important',
    position: 'absolute',
    zIndex: '9',
    display: 'flex',
  },
  '.ag-dnd-ghost-icon': {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  '.ag-column-drop-cell-ghost': {opacity: 0.5},
  '.ag-panel.ag-default-panel.ag-dialog.ag-ltr.ag-popup-child': {
    border: '1px solid',
    borderColor: '#DFE1E6',
    borderRadius: '8px',
    boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.16)',
  },
  ...dgIcons,
};
