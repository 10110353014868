import {isFeatureEnabled} from 'feature-flags';
import {Flag, Parameters, Tooltip} from 'platform/components';
import {HStack, Integer, Show, Space, Text, VStack} from 'platform/foundation';
import {
  CurrencyFormatter,
  NumberFormatter,
  PercentageFormat,
  useFormatNumber,
} from 'platform/locale';

import {FC, ReactElement, useRef} from 'react';

import {isNotNil} from 'ramda-adjunct';

import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  CarsCell,
  CountryFlag,
  decimalToPercentRank,
  Rank,
  SoldCar,
  TRANSLATED_LANGUAGES,
  VehicleTitle,
  VehicleTooltipWrapper,
} from '@omnetic-dms/teas';

import {buildArray, CurrencyCodeType} from 'shared';

import {detailSecondLine} from '../../../utils/detailSecondLine';
import {getLID} from '../../../utils/getLID';
import {getVehicleDetailPosition} from '../../../utils/getVehicleDetailPosition';
import {getVehicleSellerCountry} from '../../../utils/getVehicleSellerCountry';
import {renderMeasurePercentage} from '../../../utils/renderMeasurePercentage';
import {roundCubicCapacity} from '../../../utils/roundCubicCapacity';
import {CarvagoIcon} from '../../CarvagoIcon/CarvagoIcon';
import {HeaderCellTooltip} from '../components/HeaderCellTooltip';

export type Column = {
  id: string;
  Header: ReactElement;
  Cell: FC<CarsCell>;
  width?: Integer;
};

export type GetSourcingTableColumnsParams = {
  locale: TRANSLATED_LANGUAGES;
  formatNumber: NumberFormatter;
  formatCurrency: CurrencyFormatter;
  isInComparison?: boolean;
  currency: string | undefined;
};

/*
Similarity page for auction fixed selected auction vehicle with classifieds vehicles
 */
export const getSourcingTableColumns = (params: GetSourcingTableColumnsParams) =>
  buildArray<Column>()
    .add({
      id: 'MAKE_MODEL_VARIANT_SPEC',
      Header: (
        <HeaderCellTooltip label={i18n.t('entity.vehicle.labels.tooltipMakeModelVariant')}>
          <Text size="xxSmall" color="secondary">
            {i18n.t('entity.vehicle.labels.makeModelVariantSpec')}
          </Text>
        </HeaderCellTooltip>
      ),
      Cell: (props) => {
        const formatNumber = useFormatNumber();
        const vehicleData = props.row.original.sourcingVehicle;
        const isDisabled = !props.row.original.sourcingVehicle.isAvailable;
        const sellerCountry = getVehicleSellerCountry(vehicleData);
        const vehicleInfoRef = useRef<HTMLDivElement>(null);
        const vehicleTitleRef = useRef<HTMLDivElement>(null);

        const vehicleSourcingContent = (
          <>
            {vehicleData?.make?.translation} {vehicleData?.model?.translation} {vehicleData?.year}{' '}
            {'cubicCapacity' in vehicleData && // auction vehicle has no cubicCapacity
              vehicleData?.cubicCapacity &&
              roundCubicCapacity(vehicleData?.cubicCapacity)}{' '}
            {vehicleData?.carStyle?.translation
              ? vehicleData?.carStyle.translation.toLowerCase()
              : ''}
          </>
        );
        const originalCar = props.row.original?.sourcingVehicle?.adId.includes('_DUPLICATE');

        const carvagoAvailability =
          'carvagoAvailability' in vehicleData ? vehicleData.carvagoAvailability : null;
        const carvagoBlacklists =
          'carvagoBlacklists' in vehicleData ? vehicleData.carvagoBlacklists : null;

        return (
          <div>
            <VehicleTitle $disabled={isDisabled}>
              <Show when={originalCar}>
                <Flag
                  label={i18n.t('entity.vehicle.labels.original')}
                  colorScheme="blue"
                  data-testid={testIds.sourcing.classifieds('originalCar-flag')}
                />
                <Space horizontal={1} />
              </Show>
              {isDisabled && <SoldCar>{i18n.t('entity.vehicle.labels.sold')}</SoldCar>}
              <Tooltip
                placement="top"
                isDisabled={!ellipsisTooltip(vehicleTitleRef?.current || {})}
                label={
                  <Text color="white" size="small">
                    {vehicleSourcingContent}
                  </Text>
                }
              >
                <Text size="small" alternative>
                  {vehicleSourcingContent}
                </Text>
              </Tooltip>
              <HStack align="center">
                {sellerCountry && (
                  <>
                    <Space horizontal={1} />
                    <CountryFlag country={sellerCountry} />
                  </>
                )}
                <Show whenFeatureEnabled={featureFlags.SOURCING_CARVAGO_AVAILABILITY}>
                  <Space horizontal={1} />
                  <CarvagoIcon
                    isAvailableOnCarvago={carvagoAvailability}
                    carvagoBlacklist={carvagoBlacklists}
                  />
                </Show>
              </HStack>
            </VehicleTitle>
            <Tooltip
              placement="top"
              isDisabled={!ellipsisTooltip(vehicleInfoRef?.current || {})}
              label={
                <Parameters
                  size="xSmall"
                  color="white"
                  parameters={detailSecondLine(formatNumber, {...vehicleData})}
                  data-testid={testIds.sourcing.classifieds('vehicleInfo-tooltip-parameters')}
                />
              }
            >
              <VehicleTooltipWrapper ref={vehicleInfoRef}>
                <Parameters
                  size="xSmall"
                  color="secondary"
                  parameters={detailSecondLine(formatNumber, {...vehicleData})}
                  data-testid={testIds.sourcing.classifieds('vehicleInfo-parameters')}
                />
              </VehicleTooltipWrapper>
            </Tooltip>
          </div>
        );
      },
    })
    .add({
      id: 'POSITION',
      Header: (
        <HeaderCellTooltip label={i18n.t('entity.vehicle.labels.tooltipPosition')}>
          <Text size="xxSmall" color="secondary" align="center">
            {i18n.t('entity.vehicle.labels.position')}
          </Text>
        </HeaderCellTooltip>
      ),
      Cell: (props) => {
        const vehiclePosition = getVehicleDetailPosition(
          props.row.original.sourcingVehicle?.extraData?.marketPosition,
          props.row.original.sourcingVehicle?.extraData?.marketPositionCount
        );
        return (
          <VStack align="center">
            <Text size="xSmall" noWrap>
              {vehiclePosition[0]}
            </Text>
            <Text size="xxSmall" color="secondary">
              {vehiclePosition[1]}
            </Text>
          </VStack>
        );
      },
      width: 12,
    })
    .add({
      id: 'ORIGINAL_CZK',
      Header: (
        <HeaderCellTooltip label={i18n.t('entity.vehicle.labels.tooltipOriginalPrice')}>
          <Text size="xxSmall" color="secondary" align="right">
            {i18n.t('entity.vehicle.labels.originalPrice')}
          </Text>
        </HeaderCellTooltip>
      ),
      Cell: (props) => {
        const {originalWithVat, originalCurrency} = props.row.original.sourcingVehicle?.price || {};
        const currencyCode = originalCurrency?.key as CurrencyCodeType;
        return (
          <Tooltip
            label={
              isNotNil(originalWithVat) && params.formatCurrency(originalWithVat, currencyCode)
            }
          >
            <Text size="small" align="right" alternative={!params.isInComparison}>
              {params.formatNumber(props.row.original.summary?.original, 0)}
            </Text>
          </Tooltip>
        );
      },
      width: 18,
    })
    .add({
      id: 'MARGIN',
      Header: (
        <HeaderCellTooltip label={i18n.t('entity.vehicle.labels.margin')}>
          <Text size="xxSmall" color="secondary" align="right">
            {i18n.t('entity.vehicle.labels.margin')}
          </Text>
        </HeaderCellTooltip>
      ),
      Cell: (props) => {
        const {vehicleCosts} = props.row.original?.summary;
        const maximalPossibleMargin =
          props.row.original?.sourcingVehicle?.extraData?.maximalPossibleMargin;
        const marginWithoutCost = params.formatNumber(
          (maximalPossibleMargin ?? 0) - (vehicleCosts ?? 0),
          0
        );

        return (
          <Tooltip
            label={i18n.t('entity.vehicle.labels.marginWithoutTotalCosts', {
              marginWithoutCost,
            })}
          >
            <Text size="xSmall" align="right">
              {params.formatNumber(maximalPossibleMargin, 0)}
            </Text>
          </Tooltip>
        );
      },
      width: 14,
    })
    .add({
      id: 'SELECTED_CZK',
      Header: (
        <HeaderCellTooltip label={i18n.t('entity.vehicle.labels.tooltipRecommendedPrice')}>
          <Text size="xxSmall" color="secondary" align="right">
            {i18n.t('entity.vehicle.labels.recommendedPrice')}
          </Text>
        </HeaderCellTooltip>
      ),
      Cell: (props) => (
        <Show when={props.row.original.sourcingVehicle?.extraData?.predictedPrice}>
          <Tooltip
            isDisabled={!isFeatureEnabled(featureFlags.SOURCING_SHOW_UNCERTAINTY_PERCENTAGE)}
            label={
              isNotNil(props.row.original.sourcingVehicle?.extraData?.predictedPricePercentile)
                ? renderMeasurePercentage(
                    props.row.original.sourcingVehicle?.extraData?.predictedPricePercentile,
                    params.currency as CurrencyCodeType
                  )
                : ''
            }
          >
            <VStack align="flex-end">
              <Text size="xSmall">
                {params.formatNumber(
                  props.row.original.sourcingVehicle?.extraData?.predictedPrice,
                  0
                )}
              </Text>
              <Show whenFeatureEnabled={featureFlags.SOURCING_SHOW_UNCERTAINTY_PERCENTAGE}>
                {isNotNil(props.row.original.sourcingVehicle?.fairPriceUncertainty) && (
                  <Text size="xxSmall" color="secondary">
                    <PercentageFormat
                      number={props.row.original.sourcingVehicle.fairPriceUncertainty}
                      roundingType="down"
                    />
                  </Text>
                )}
              </Show>
            </VStack>
          </Tooltip>
        </Show>
      ),
      width: 15,
    })
    .add({
      id: 'LID',
      Header: (
        <HeaderCellTooltip label={i18n.t('entity.vehicle.labels.tooltipLID')}>
          <Text size="xxSmall" color="secondary" align="right">
            {i18n.t('entity.vehicle.labels.LID')}
          </Text>
        </HeaderCellTooltip>
      ),
      Cell: (props) => {
        const lid = getLID(props.row.original.summary?.lid);
        return (
          <Text size="xSmall" align="right">
            {isNotNil(lid) ? params.formatNumber(lid, 0) : 'N/A'}
          </Text>
        );
      },
      width: 9,
    })
    .add({
      id: 'DOD',
      Header: (
        <HeaderCellTooltip label={i18n.t('entity.vehicle.labels.tooltipDOD')}>
          <Text size="xxSmall" color="secondary" align="right">
            {i18n.t('entity.vehicle.labels.DOD')}
          </Text>
        </HeaderCellTooltip>
      ),
      Cell: (props) => (
        <Text size="xSmall" align="right">
          {params.formatNumber(props.row.original.sourcingVehicle?.daysOnStock, 0)}
        </Text>
      ),
      width: 9,
    })
    .add({
      id: 'RANK',
      Header: (
        <HeaderCellTooltip label={i18n.t('entity.vehicle.labels.tooltipRank')}>
          <Text size="xxSmall" color="secondary" align="right">
            {i18n.t('entity.vehicle.labels.features')}
          </Text>
        </HeaderCellTooltip>
      ),
      Cell: (props) => (
        <Tooltip label={decimalToPercentRank(props.row.original.summary?.rank ?? undefined)}>
          <HStack justify="flex-end">
            <Rank
              rank={props.row.original.summary?.rank ?? undefined}
              data-testid={testIds.sourcing.classifieds('rank-tooltip')}
            />
          </HStack>
        </Tooltip>
      ),
      width: 10,
    });

/**
 * Function for test if is content longer than wrapper, ideal use with css text-overflow: ellipsis to test if are three dots diplayed. With this boolean value you can  invoke a tooltip when is some content hidden.
 * Example - ellipsisTooltip(vehicleInfoRef?.current || {})
 */

const ellipsisTooltip = ({clientWidth, scrollWidth}: EllipsisTooltipType) =>
  (scrollWidth ?? 0) > (clientWidth ?? 0);

type EllipsisTooltipType = {
  scrollWidth?: number;
  clientWidth?: number;
};
