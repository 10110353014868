import {Card, Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {Grid, Right, Space, Text} from 'platform/foundation';

import {MercedesNaSkladeCredentials} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

import {advertisingPlatformNames} from '../../consts/advertisingPlatformNames';

export type MercedesNaSkladeAuthenticationFormData = {
  dealerId: string;
};

export type PlatformAuthenticationFormMercedesNaSkladeProps = {
  defaultValues: MercedesNaSkladeCredentials | null;
  onSubmit: FormSubmitHandler<MercedesNaSkladeAuthenticationFormData>;
};

const DEFAULT_VALUES: MercedesNaSkladeAuthenticationFormData = {
  dealerId: '',
};

export function PlatformAuthenticationFormMercedesNaSklade(
  props: PlatformAuthenticationFormMercedesNaSkladeProps
) {
  return (
    <Card
      title={`${i18n.t('general.labels.authentication')} – ${advertisingPlatformNames[AdvertisingPlatformCodeEnum.MERCEDES_NA_SKLADE]}`}
    >
      <Text size="small" color="secondary">
        {i18n.t('page.settings.labels.authenticationDescription')}
      </Text>
      <Space vertical={4} />
      <Form<MercedesNaSkladeAuthenticationFormData>
        defaultValues={props.defaultValues ?? DEFAULT_VALUES}
        onSubmit={props.onSubmit}
      >
        {(control) => (
          <>
            <Grid columns={2}>
              <FormField
                control={control}
                name="dealerId"
                type="text"
                label={i18n.t('page.settings.labels.dealerId')}
              />
            </Grid>
            <Space vertical={4} />
            <Right>
              <FormButton control={control} type="submit" title={i18n.t('general.actions.save')} />
            </Right>
          </>
        )}
      </Form>
    </Card>
  );
}
