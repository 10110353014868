import {
  Button,
  Card,
  closeCurrentDialog,
  closeDialog,
  DataStatus,
  openDialog,
  TabProps,
  TabsHeader,
} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';

import {useRef, useState} from 'react';

import {isNilOrEmpty, isNotNilOrEmpty, isString} from 'ramda-adjunct';

import {GetSupplierOrderResponse, useGetSupplierOrderQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {Filters} from 'features/datagrid';

import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';
import {getSingleOrUndefined} from '../../../../../utils/getSingleOrUndefined';
import {ArticleCreate, ArticleCreateDefaultValues} from '../../../../ArticleCreate/ArticleCreate';
import {MaterialPriceListTab} from './MaterialPriceListTab';
import {MaterialPurchaseListTab} from './MaterialPurchaseListTab';
import {MaterialWarehouseTab} from './MaterialWarehouseTab';

interface MaterialListProps extends TestIdProps {
  onAddMaterialToBasket: (articleId: string) => Promise<void>;
  onAddMaterialFromPurchaseListToBasket: (requestItemId: string[]) => Promise<void>;
}

const TABS_IDS = {
  warehouseTab: 'supplierOrderBasket-warehouseTab',
  purchaseListTab: 'supplierOrderBasket-purchaseListTab',
  priceListTab: 'supplierOrderBasket-priceListTab',
};

export function MaterialList(props: MaterialListProps) {
  const {supplierOrderId} = useWarehouseParams();
  const [activeTabId, setActiveTabId] = useState(TABS_IDS.warehouseTab);
  const [activeFolderId, setActiveFolderId] = useState<string>();
  const filters = useRef<ArticleCreateDefaultValues>({});

  const {data: supplierOrder, isLoading: isSupplierOrderLoading} = useGetSupplierOrderQuery(
    {orderId: supplierOrderId},
    {skip: isNilOrEmpty(supplierOrderId)}
  );

  const warehouseId = supplierOrder?.order.warehouseId;

  const handleCreateArticle = () => {
    openDialog(
      <ArticleCreate
        defaultValues={{
          activeFolderId,
          manufacturerNumber: filters.current?.manufacturerNumber,
          manufacturerId: filters.current?.manufacturerId,
          name: filters.current?.name,
          warehouseId: filters.current?.warehouseId,
        }}
        onArticleCreate={(createdArticleData) => {
          props.onAddMaterialToBasket(createdArticleData.articleId);
          closeCurrentDialog();
        }}
        submitButtonTitle={i18n.t('general.actions.createAndAdd')}
        onClose={() => closeDialog('createNewArticleDialog')}
        data-testid={suffixTestId('createNewArticle', props)}
      />,
      {
        id: 'createNewArticleDialog',
        title: i18n.t('general.labels.createNew'),
        scrollBehavior: 'outside',
        withAdditionalFooter: true,
      }
    );
  };

  const handleFiltersChange = (newFilters: Filters) => {
    const articleCreateDefaultValues: ArticleCreateDefaultValues = {};

    if (isString(newFilters.catalogNumber)) {
      articleCreateDefaultValues.manufacturerNumber = newFilters.catalogNumber;
    }

    if (isNotNilOrEmpty(newFilters.manufacturerId)) {
      articleCreateDefaultValues.manufacturerId = getSingleOrUndefined(newFilters.manufacturerId);
    }

    if (isNotNilOrEmpty(newFilters.warehouseId)) {
      articleCreateDefaultValues.warehouseId = getSingleOrUndefined(newFilters.warehouseId);
    }

    if (isString(newFilters.name)) {
      articleCreateDefaultValues.name = newFilters.name;
    }

    filters.current = articleCreateDefaultValues;
  };

  const tabs: TabProps[] = [
    {
      id: TABS_IDS.warehouseTab,
      title: i18n.t('entity.warehouse.labels.warehouse'),
      content: (
        <MaterialWarehouseTab
          onAddMaterialToBasket={props.onAddMaterialToBasket}
          onActiveFolderIdChange={setActiveFolderId}
          onExternalFilterChange={handleFiltersChange}
          data-testid={suffixTestId('tabs.warehouse', props)}
        />
      ),

      'data-testid': suffixTestId('tab.warehouse', props),
    },
    {
      id: TABS_IDS.priceListTab,
      title: i18n.t('entity.warehouse.labels.priceList'),
      content: (
        <MaterialPriceListTab
          warehouseId={warehouseId as string}
          onAddMaterialToBasket={props.onAddMaterialToBasket}
          data-testid={suffixTestId('tabs.priceList', props)}
        />
      ),
      'data-testid': suffixTestId('tab.priceList', props),
    },
    {
      id: TABS_IDS.purchaseListTab,
      title: i18n.t('entity.warehouse.labels.purchaseList'),
      content: (
        <MaterialPurchaseListTab
          supplierOrder={supplierOrder as GetSupplierOrderResponse}
          onAddMaterialFromPurchaseListToBasket={props.onAddMaterialFromPurchaseListToBasket}
          onActiveFolderIdChange={setActiveFolderId}
          onFilterChange={handleFiltersChange}
          data-testid={suffixTestId('tabs.warehouse', props)}
        />
      ),
      'data-testid': suffixTestId('tab.purchaseList', props),
    },
  ];

  const activeTabContent = tabs.find(
    (tab) => tab.id === (activeTabId ?? TABS_IDS.warehouseTab)
  )?.content;

  return (
    <DataStatus isLoading={isSupplierOrderLoading}>
      <VStack spacing={4} height="100%" maxHeight="100%">
        <HStack justify="space-between">
          <TabsHeader
            variant="condensed"
            tabs={tabs}
            activeTabId={activeTabId}
            onChange={setActiveTabId}
            data-testid={suffixTestId('tabs', props)}
          />
          <Show when={activeTabId === TABS_IDS.warehouseTab}>
            <Button
              variant="secondary"
              leftIcon="content/add_circle"
              onClick={handleCreateArticle}
              title={i18n.t('entity.warehouse.labels.createNewArticle')}
              data-testid={suffixTestId('button.createNewArticle', props)}
            />
          </Show>
        </HStack>
        <Box height="100%" flex={1}>
          <Card key={activeTabId} isFullHeight>
            {activeTabContent}
          </Card>
        </Box>
      </VStack>
    </DataStatus>
  );
}
