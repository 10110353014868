import {flatten} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {OptionWithProperties} from '../../../hooks/useVehicleCatalogue';

export function getSecondaryFuelTypeOptionsByFuelType(
  fuelTypeOptions: OptionWithProperties<string, string>[] | Nullish,
  fuelType: string | Nullish
) {
  if (isNilOrEmpty(fuelTypeOptions) || isNilOrEmpty(fuelType)) {
    return null;
  }

  const secondaryFuelTypes = fuelTypeOptions!.reduce((options: string[], option) => {
    if (option.value !== fuelType) {
      return options;
    }
    return flatten([
      ...options,
      ...(option.properties
        ?.filter((property) => property.label === 'secondary_fuel_type')
        ?.map((property) => property.value) ?? []),
    ]);
  }, []);

  const options = fuelTypeOptions!.filter((option) => secondaryFuelTypes.includes(option.value));

  return isNotNilOrEmpty(options) ? options : null;
}
