import i18n from '@omnetic-dms/i18n';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {AutostacjaSettingsList} from './components/AutostacjaSettingsList';

export function AutostacjaSettings() {
  return (
    <SettingsTemplate
      data-testid="integrations-autostacja-settings"
      header={{title: i18n.t('page.integrations.labels.autostacja')}}
    >
      <AutostacjaSettingsList />
    </SettingsTemplate>
  );
}
