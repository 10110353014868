import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Text, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

interface CustomIdFormProps extends TestIdProps {
  customCode?: string | null;
  text: string;
  onClose: VoidFunction;
  onSubmit: FormSubmitHandler<FormType>;
}

export function CustomIdForm(props: CustomIdFormProps) {
  return (
    <Form<FormType>
      schema={formSchema}
      defaultValues={{customCode: props.customCode ?? null}}
      onSubmit={props.onSubmit}
    >
      {(control) => (
        <VStack spacing={4}>
          <Text size="small" color="tertiary">
            {props.text}
          </Text>
          <FormField
            type="text"
            name="customCode"
            control={control}
            data-testid={suffixTestId('customCode', props)}
          />
          <ButtonGroup align="right">
            <Button
              variant="secondary"
              onClick={props.onClose}
              title={i18n.t('general.actions.discard')}
              data-testid={suffixTestId('discard', props)}
            />
            <FormButton
              type="submit"
              variant="primary"
              control={control}
              title={i18n.t('general.actions.save')}
              data-testid={suffixTestId('save', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

type FormType = {
  customCode: string | null;
};

const formSchema = Yup.object().shape({
  customCode: Yup.string().required(),
});
