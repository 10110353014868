import {ApiMakeModel} from '../types/ApiMakeModel';
import {ApiMakeObject} from '../types/ApiMakeObject';
import type {CancelablePromise} from '../types/CancelablePromise';
import {request as __request} from '../utils/request';

export class VehicleCatalogueService {
  /**
   * Retrieves makes
   * Retrieves list of makes and related models if required
   * @returns any The request is successfully performed.
   * @throws ApiError
   */
  public static getApiMakes({
    vehicleType,
    lang,
    q,
    size,
  }: {
    /** Vehicle type such as VEHICLETYPE_PASSENGER_CAR, VEHICLETYPE_MOTORCYCLE, etc. **/
    vehicleType: string;
    /** List of ISO 693-1 languages of labels to retrieve. **/
    lang?: string;
    /** TBD **/
    q?: string;
    /** Size of returned data. **/
    size?: number;
  }): CancelablePromise<Array<ApiMakeObject>> {
    return __request({
      method: 'GET',
      url: `/vehicle-catalogue/api/make-model/{vehicleType}`,
      path: {vehicleType},
      query: {
        lang,
        q,
        size,
      },
    });
  }

  /**
   * Retrieves makes
   * Retrieves list of makes and related models if required
   * @returns any The request is successfully performed.
   * @throws ApiError
   */
  public static getApiMakeModelWithMake({
    vehicleType,
    lang,
    make,
  }: {
    /** Vehicle type such as VEHICLETYPE_PASSENGER_CAR, VEHICLETYPE_MOTORCYCLE, etc. **/
    vehicleType: string;
    /** List of ISO 693-1 languages of labels to retrieve. **/
    lang?: string;
    /** Car make. If provided then the endpoint returns list of related models. If not provided then the endpoint returns the list of all possible makes.
     *  **/
    make?: string;
  }): CancelablePromise<Array<ApiMakeModel>> {
    return __request({
      method: 'GET',
      url: `/vehicle-catalogue/api/make-model/{vehicleType}/{make}`,
      path: {vehicleType, make},
      query: {
        lang,
      },
    });
  }
}
