import {PhoneInput} from '../../PhoneInput/PhoneInput';
import {useFieldProps} from '../hooks/useFieldProps';
import {PhoneRendererResponseBody} from '../types/modelSchema';

export function PhoneRenderer(props: PhoneRendererResponseBody) {
  const {name, tooltip} = props;
  const {onChange, onBlur, onFocus, value, isRequired, error} = useFieldProps(props);

  return (
    <PhoneInput
      label={props.label}
      data-testid={name}
      onChange={onChange}
      isRequired={isRequired}
      errorMessage={error?.message}
      tooltip={tooltip}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value || {number: ''}}
    />
  );
}
