import i18n from 'i18next';

import {createTransform} from 'redux-persist';

// Transform state on its way to being serialized and persisted
export const inboundTransform = (inboundState: unknown) => {
  if (typeof inboundState !== 'object') {
    return inboundState;
  }

  const currentLang = i18n.language;
  return {...inboundState, lang: currentLang};
};

// Transform state being rehydrated
export const outboundTransform = (outboundState: object | undefined) => {
  if (typeof outboundState !== 'object' || !('lang' in outboundState)) {
    return outboundState;
  }

  const currentLang = i18n.language;
  const {lang, ...state} = outboundState;
  if (lang === currentLang) {
    return state;
  }

  return {}; // Reset state if stored data has different language
};

/**
 * A redux-persist transform that ensures the language of the persisted state matches the current app language.
 *
 * This issue arises when the app language is switched, and the DMS is opened in another browser tab. This can overwrite
 * the current data in local storage with data in the previously used language. The data from cachedApi then differs from
 * the language set by the user.
 *
 * This transform modifies the state during the serialization and rehydration processes. It checks the language of the
 * persisted state against the current language of the app. If they don't match, it resets the state. This ensures that
 * the persisted state is always in the same language as the app.
 *
 * @example
 * // Usage in persistReducer transforms
 * persistReducer({
 *   ...
 *   transforms: [languageCheckTransform],
 *   ...
 * })
 */
export const languageCheckTransform = createTransform(inboundTransform, outboundTransform);
