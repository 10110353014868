import {Card, FormControl, FormField, Label} from 'platform/components';
import {GridItem, Grid, Link, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {UseFormReturn} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {always} from 'ramda';

import {GetCorrectiveTaxDocumentApiResponse} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {accountingRoutes} from '@omnetic-dms/routes';
import {EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';

import {suffixTestId, composePath, TestIdProps} from 'shared';

import {AccountingDocumentFormValues} from '../../types';

interface CorrectiveTaxDocumentDetailsProps extends TestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
  formApi: UseFormReturn<AccountingDocumentFormValues>;
  correctiveTaxDocument: GetCorrectiveTaxDocumentApiResponse | undefined;
}

export function CorrectiveTaxDocumentDetails(props: CorrectiveTaxDocumentDetailsProps) {
  const navigate = useNavigate();

  const {description} = props.formApi.getValues();

  const handleNavigateToInvoice = () => {
    const path = match(props.correctiveTaxDocument?.invoicingDocumentType)
      .with('invoice', always(accountingRoutes.invoiceDetail))
      .with('balance_invoice', always(accountingRoutes.balanceInvoiceDetail))
      .with('invoice_proforma', always(accountingRoutes.proformaInvoiceDetail))
      .with('tax_document_for_payment', always(accountingRoutes.taxDocumentForPaymentDetail))
      .otherwise(always(accountingRoutes.invoiceDetail));

    navigate(composePath(path, {params: {id: props.correctiveTaxDocument?.invoicingDocumentId}}));
  };

  return (
    <Card title={i18n.t('entity.correctiveTaxDocument.labels.taxDocumentDetails')}>
      <Grid columns={4}>
        <VStack>
          <Label>{i18n.t('entity.warehouse.labels.invoiceNumber')}</Label>
          <Link
            title={props.correctiveTaxDocument?.invoicingDocumentNumber}
            onClick={handleNavigateToInvoice}
            data-testid={suffixTestId('invoice-number', props)}
          />
        </VStack>

        <GridItem span={3}>
          <VStack>
            <Label>{i18n.t('general.labels.description')}</Label>
            <Text size="small">{description || EMPTY_PLACEHOLDER}</Text>
          </VStack>
        </GridItem>

        <FormField
          name="dateOfReception"
          control={props.control}
          type="apiDate"
          isRequired
          isDisabled
          label={i18n.t('entity.correctiveTaxDocument.labels.dateOfReception')}
        />

        <GridItem span={3}>
          <FormField
            name="reason"
            control={props.control}
            type="textarea"
            isRequired
            isDisabled
            label={i18n.t('general.labels.reason')}
          />
        </GridItem>
      </Grid>
    </Card>
  );
}
