import {createSlice} from '@reduxjs/toolkit';

import {Series} from '../../types/Series';
import {loadDocumentSeriesList} from './actions';
import {NAME} from './constants';

const initialState = {
  series: [] as Series[],
};

export type DocumentSeriesState = typeof initialState;

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadDocumentSeriesList.fulfilled, (state, action) => {
      state.series = action.payload;
    });
  },
});

const {reducer} = slice;

export const documentSeriesReducer = reducer;
