export enum BodyColorEnum {
  COLOR_WHITE = 'COLOR_WHITE',
  COLOR_BLACK = 'COLOR_BLACK',
  COLOR_GREY = 'COLOR_GREY',
  COLOR_SILVER = 'COLOR_SILVER',
  COLOR_BLUE = 'COLOR_BLUE',
  COLOR_RED = 'COLOR_RED',
  COLOR_BROWN = 'COLOR_BROWN',
  COLOR_BEIGE = 'COLOR_BEIGE',
  COLOR_GOLD = 'COLOR_GOLD',
  COLOR_ORANGE = 'COLOR_ORANGE',
  COLOR_GREEN = 'COLOR_GREEN',
  COLOR_PURPLE = 'COLOR_PURPLE',
  COLOR_YELLOW = 'COLOR_YELLOW',
}
