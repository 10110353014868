import {PriceRequestBody} from '../../../types/PriceRequestBody';
import {PriceValue} from '../../../types/PriceValue';
import {isPriceFromWithVat} from './isPriceFromWithVat';

type ReturnedPriceRequestBody<T extends PriceValue | null | undefined> = T extends null | undefined
  ? PriceRequestBody | null
  : PriceRequestBody;

export const getPriceRequestBody = <T extends PriceValue | null | undefined>(
  price: T
): ReturnedPriceRequestBody<T> => {
  if (!price) {
    return null as ReturnedPriceRequestBody<T>;
  }

  const usedPrice = isPriceFromWithVat(price) ? price.withVat : price.withoutVat;

  return {
    price: usedPrice
      ? {
          amount: String(usedPrice.amount),
          currency: usedPrice.currency,
        }
      : null,
    isWithVat: isPriceFromWithVat(price),
    vatType: price.vatType,
  } as ReturnedPriceRequestBody<T>;
};
