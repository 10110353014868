import {IconProps} from 'platform/foundation';

import {ActionDefinitionObject} from '../types/ActionDefinition';
import {TransformedDefinitionApiResponse} from '../types/Api';

export const getActions = (
  actions: TransformedDefinitionApiResponse['actions']
): ActionDefinitionObject =>
  actions.reduce((accumulator, action) => {
    accumulator[action.key] = {
      title: action.title,
      description: action.description,
      variant: action.variant || 'primary',
      icon: {
        value: (action.icon ?? '') as IconProps['value'],
      },
      bulkAction: action.bulk,
      singleAction: action.single,
      confirm: false,
      isLink: action.isLink,
    };
    return accumulator;
  }, {} as ActionDefinitionObject);
