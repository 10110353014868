import {useEffect, useRef} from 'react';

import {isNotNil} from 'ramda';

/**
 * Hook to lock and unlock the application's scroll behavior by manipulating the `overflow` style of
 * the document and body elements. It also listens to the browser's navigation (back/forward) events to
 * reset the scroll when navigating.
 *
 * @param {boolean} isForceLocked - If true, the scroll will be locked automatically when the hook is first run.
 *
 * @returns {Object} - An object containing two functions: `lockApplicationScroll` and `unlockApplicationScroll`.
 *
 * @property {Function} lockApplicationScroll - Locks the application's scroll by setting `overflow` to `hidden`
 *   for both the `document.documentElement` and `document.body`.
 *
 * @property {Function} unlockApplicationScroll - Unlocks the application's scroll by restoring the original
 *   `overflow` values for both the `document.documentElement` and `document.body`.
 */
export function useApplicationScrollLock(isForceLocked: boolean = false): {
  lockApplicationScroll: VoidFunction;
  unlockApplicationScroll: VoidFunction;
} {
  // Storing the original overflow values for document and body when is instance of this hook created.
  const originOverflow = useRef({
    document: document.documentElement.style.getPropertyValue('overflow'),
    body: document.body.style.getPropertyValue('overflow'),
  });

  // Ref to track whether the scroll is currently locked
  const isLocked = useRef(false);

  const lockApplicationScroll = () => {
    if (!isLocked.current) {
      originOverflow.current = {
        document: document.documentElement.style.getPropertyValue('overflow'),
        body: document.body.style.getPropertyValue('overflow'),
      };

      document.documentElement.style.setProperty('overflow', 'hidden');
      document.body.style.setProperty('overflow', 'hidden');
      isLocked.current = true;
    }
  };

  const unlockApplicationScroll = () => {
    if (isLocked.current) {
      if (isNotNil(originOverflow.current.document)) {
        document.documentElement.style.setProperty('overflow', originOverflow.current.document);
      } else {
        document.documentElement.style.removeProperty('overflow');
      }
      if (isNotNil(originOverflow.current.body)) {
        document.body.style.setProperty('overflow', originOverflow.current.body);
      } else {
        document.body.style.removeProperty('overflow');
      }
      isLocked.current = false;
    }
  };

  useEffect(() => {
    // Lock scroll automatically only on the first load if isForceLocked is true
    if (isForceLocked) {
      lockApplicationScroll();
    }
    /**
     * Add a popstate event listener to unlock scrolling for navigation events.
     * Important when user clicks back in the browser.
     */
    window.addEventListener('popstate', unlockApplicationScroll);
    return () => {
      window.removeEventListener('popstate', unlockApplicationScroll);
      unlockApplicationScroll();
    };
  }, [isForceLocked]);

  return {lockApplicationScroll, unlockApplicationScroll};
}
