export type ExportQueryParamsBuilderType = {
  decimalDelimiter?: string;
  columnDelimiter?: string;
  instanceId?: string;
};

/**
 * Constructs a query string for exporting data based on the specified configuration.
 * The function handles optional delimiters for decimal, column separation and instance.
 */
export const buildExportQueryParams = (config: ExportQueryParamsBuilderType): string => {
  const queryParams = [];

  if (config.decimalDelimiter) {
    queryParams.push(`decimalDelimiter=${config.decimalDelimiter}`);
  }

  if (config.columnDelimiter) {
    queryParams.push(`columnDelimiter=${config.columnDelimiter}`);
  }

  if (config.instanceId) {
    queryParams.push(`instance=${config.instanceId}`);
  }

  // Construct and return the full query string
  return '?' + queryParams.join('&');
};
