import {Nullish, isDefined} from 'shared';

import {QueueVideoTaskJob} from '../types/QueueVideoTaskJob';
import {UploadApi} from '../types/UploadApi';

function getUploadBodyParts(jobs: QueueVideoTaskJob[]) {
  return jobs.map((j) => ({etag: j.etag, partNumber: j.partNumber}));
}

function checkIfAllPartsHaveEtag(
  parts: {etag: string | Nullish; partNumber: number}[]
): parts is {etag: string; partNumber: number}[] {
  return parts.every((part) => isDefined(part.etag));
}

export function useVideoCompleteManager(
  sendMultipartUploadCompleted: UploadApi['sendMultipartUploadCompleted']
) {
  const handleTaskCompleted = async (connectionFileId: string, jobs: QueueVideoTaskJob[]) => {
    const bodyParts = getUploadBodyParts(jobs);
    if (!checkIfAllPartsHaveEtag(bodyParts)) {
      throw new Error('Missing etag value');
    }
    return await sendMultipartUploadCompleted({
      fileId: connectionFileId,
      body: {parts: bodyParts},
    }).unwrap();
  };

  return {handleTaskCompleted};
}
