import {DropdownItem, FormControl, showNotification} from 'platform/components';

import {UseFormReturn} from 'react-hook-form';

import {
  ContextTemplateResponseBody,
  GetContextTemplateListApiResponse,
  useRenderCustomerSmsTemplateMutation,
} from '@omnetic-dms/api';
import {i18n} from '@omnetic-dms/i18n';

import {VARIABLE_REGEX} from '../../../utils/getVariables';
import {handleApiError} from '../../../utils/handleApiError';
import {SendSmsFormValues} from '../types/SendSmsFormValues';

type Props = {
  formApi: UseFormReturn<SendSmsFormValues>;
  control: FormControl<SendSmsFormValues>;
  customerId?: string | null;
  renderTemplate: ReturnType<typeof useRenderCustomerSmsTemplateMutation>[0];
  templates: GetContextTemplateListApiResponse | undefined;
};

export const TemplatesSmsDropdownContent = (props: Props) => {
  const onTemplateClick = async (template: ContextTemplateResponseBody) => {
    const response = await props
      .renderTemplate({
        renderCustomerSmsTemplateRequestBody: {
          template: template.body,
          customerId: props.customerId ?? '',
        },
      })
      .unwrap()
      .catch(handleApiError);
    if (!response) {
      return;
    }
    props.formApi.setValue(
      'text',
      response.body.replaceAll(new RegExp(VARIABLE_REGEX, 'g'), '____')
    );

    if (VARIABLE_REGEX.test(response.body)) {
      showNotification.warning(i18n.t('general.notifications.notAllPlaceholdersFilled'));
    }
  };

  return (
    <>
      {props.templates?.map((template) => (
        <DropdownItem
          label={template.name}
          key={template.id}
          onClick={() => onTemplateClick(template)}
        />
      ))}
    </>
  );
};
