import i18n from 'i18next';
import {copyToClipboard, DataStatus, Dialog, DialogProps, TextInput} from 'platform/components';

import {useEffect} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {useSharePreset} from '../../hooks/useSharePreset';
import {SharePresetStateEnum} from '../../types/SharePresetState';

const SHARE_PRESET_DIALOG_DELAY = 2000;

export function SharePresetDialog(props: Omit<DialogProps, 'children'> & TestIdProps) {
  const {shareUrl, sharePresetState, sharePreset, onClose} = useSharePreset(props.onClose);

  const onCopyToClipboard = () => {
    shareUrl && copyToClipboard(shareUrl);
  };

  useEffect(() => {
    if (props.isOpen && sharePresetState === SharePresetStateEnum.LOADING) {
      const timeoutId = setTimeout(() => sharePreset(), SHARE_PRESET_DIALOG_DELAY);
      return () => clearInterval(timeoutId);
    }
  }, [props.isOpen]);

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={onClose}
      title={i18n.t('page.datagrid.labels.sharePresetDialogTitle')}
      buttons={[
        {
          title: i18n.t('general.actions.close'),
          variant: 'primary',
          onClick: onClose,
          'data-testid': suffixTestId('close', props),
        },
      ]}
      data-testid={props['data-testid']}
    >
      <DataStatus
        isLoading={sharePresetState === SharePresetStateEnum.LOADING}
        isError={sharePresetState === SharePresetStateEnum.ERROR}
      >
        <TextInput
          label={i18n.t('page.datagrid.labels.sharePresetDialogUrl')}
          type="text"
          value={shareUrl}
          labelActions={[
            {
              title: i18n.t('general.actions.copyToClipboard'),
              leftIcon: 'content/copy',
              onClick: onCopyToClipboard,
            },
          ]}
          data-testid={suffixTestId('url', props)}
        />
      </DataStatus>
    </Dialog>
  );
}
