type DataGridExportFileType = 'csv' | 'xlsx';

type DownloadFileAsBlobConfig = {
  fileName: string;
  fileType: DataGridExportFileType;
};

export const downloadBlobFile = (file: Blob, config: DownloadFileAsBlobConfig) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${config.fileName}.${config.fileType}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
