import i18n from 'i18next';
import {Button, Dropdown, DropdownItem} from 'platform/components';

import {FC, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useApiDispatch} from '../../../hooks/useApiDispatch';
import {useRouter} from '../../../hooks/useRouter';
import {
  createInspectionDocument,
  createVehicleConditionDocument,
  getAuditExportTemplates,
} from '../../../store/carAudit/actions';
import {
  selectActiveAuditId,
  selectAuditExportTemplates,
  selectCarAudit,
} from '../../../store/carAudit/selectors';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';

type ExportPDFProps = TestIdProps & {
  inspectionType?: LoadAuditDataResponseItemBody.inspectionType | Nullish;
};

export const ExportPDF: FC<ExportPDFProps> = (props) => {
  const {loading} = useSelector(selectCarAudit);
  const auditId = useSelector(selectActiveAuditId);
  const auditExportTemplates = useSelector(selectAuditExportTemplates);
  const apiDispatch = useApiDispatch();
  const router = useRouter();
  const isInspection =
    Boolean(router.params?.inspectionId) &&
    props.inspectionType !== LoadAuditDataResponseItemBody.inspectionType.COMPLAINT;

  const handleExport = async (templateId: string): Promise<void> => {
    const createAction = isInspection
      ? createInspectionDocument.action
      : createVehicleConditionDocument.action;

    const {pdfUrl} = await apiDispatch(createAction, {
      requestBody: {
        inspectionId: auditId ?? '',
        templateId,
      },
    });
    window?.open(pdfUrl, '_blank')?.focus();
  };

  useEffect(() => {
    apiDispatch(getAuditExportTemplates.action, {});
  }, [apiDispatch]);

  return (
    <Dropdown
      data-testid={suffixTestId('exportPDF', props)}
      placement="bottom-end"
      dropdownControl={
        <Button
          variant="outlined"
          rightIcon="hardware/keyboard_arrow_down"
          isLoading={loading.createInspectionDocument || loading.createVehicleConditionDocument}
          data-testid={suffixTestId('exportPDF-action', props)}
          title={String(i18n.t`general.actions.exportPDF`)}
        />
      }
    >
      {auditExportTemplates.map(({id, title}, index) => (
        <DropdownItem
          key={`exportPDF-${id}-${title}`}
          label={title}
          onClick={() => handleExport(id)}
          data-testid={suffixTestId(`exportPDF-[${index}]`, props)}
        />
      ))}
    </Dropdown>
  );
};
