import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {NullishBoolean} from 'shared';

export const getVehicleAdvertisementTypeFlagProps = (inSale: NullishBoolean) => {
  if (isNil(inSale)) {
    return null;
  }

  return inSale
    ? ({
        colorScheme: 'green',
        label: i18n.t('entity.vehicle.labels.publishingStateTrue'),
      } as const)
    : ({
        colorScheme: 'orange',
        label: i18n.t('entity.vehicle.labels.publishingStateFalse'),
      } as const);
};
