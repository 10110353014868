import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {indexBy, map, prop} from 'ramda';

import {AdvertisementSettingsSliceType} from '../../types/AdvertisementSettings';
import {Platform} from '../../types/Platform';

const STATE_NAME = 'generalSettings/AdvertisementSettings';

const initialState: AdvertisementSettingsSliceType = {
  platforms: {
    list: {},
    order: [],
    loading: false,
  },
  detailedPlatforms: {},
};

const advertisementSlice = createSlice({
  name: STATE_NAME,
  initialState,
  reducers: {
    fetchAdSettingsRequest(state, _action: PayloadAction<{branchId?: string}>) {
      state.platforms.loading = true;
    },
    fetchAdSettingsSuccess(state, {payload}: PayloadAction<Platform[]>) {
      const newList = indexBy(prop('code'), payload);
      const newOrder = map((order) => order.code, payload);

      state.platforms.loading = false;
      state.platforms.list = newList;
      state.platforms.order = newOrder;
    },
  },
});

const {reducer, actions} = advertisementSlice;

export const {fetchAdSettingsRequest, fetchAdSettingsSuccess} = actions;

export const AdvertisementSettingsReducer = reducer;
