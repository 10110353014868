import {array, boolean, mixed, number, object, string} from 'yup';

import {isNil} from 'ramda';
import {isNotNaN, isPositive} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {POSITIVE_NUMBER_REGEX, yupString} from 'shared';

// TODO: https://carvago.atlassian.net/browse/T20-30755
const $VariableAndSpecificSymbol = mixed()
  .test(
    'integer',
    i18n.t('general.errors.number.integer'),
    (val) => isNil(val) || isNotNaN(parseInt(val))
  )
  .test(
    'isPositive',
    i18n.t('general.errors.number.positive'),
    (val) => isNil(val) || isPositive(parseInt(val))
  );

const $ConstantSymbol = mixed()
  .test(
    'integer',
    i18n.t('general.errors.number.integer'),
    (val) => isNil(val) || isNotNaN(parseInt(val))
  )
  .test(
    'isPositive',
    i18n.t('general.errors.number.positive'),
    (val) => isNil(val) || isPositive(parseInt(val))
  )
  .test('min', i18n.t('general.errors.number.min', {min: 4}), (val) => !val || val.length >= 4)
  .test('max', i18n.t('general.errors.number.max', {max: 4}), (val) => !val || val.length <= 4);

export const $CreateInvoiceOrDraftSchema = object({
  customer: mixed().when('submitType', {
    is: 'CREATE_DRAFT',
    then: object().nullable(),
    otherwise: object().nullable().required(),
  }),
  dateOfTaxableSupply: mixed().when('submitType', {
    is: 'CREATE_DRAFT',
    then: yupString,
    otherwise: yupString.required(),
  }),
  dueDate: mixed().when('submitType', {
    is: 'CREATE_DRAFT',
    then: yupString,
    otherwise: yupString.required(),
  }),
  dueSinceIssueDateInDays: mixed().when('submitType', {
    is: 'CREATE_DRAFT',
    then: number().min(0).nullable(),
    otherwise: number().min(0).nullable().required(),
  }),
  issueDate: mixed().when('submitType', {
    is: 'CREATE_DRAFT',
    then: yupString,
    otherwise: yupString.required(),
  }),
  documentSeriesId: mixed().when('submitType', {
    is: 'CREATE_DRAFT',
    then: yupString,
    otherwise: yupString.required(),
  }),
  paymentInfo: mixed().when('submitType', {
    is: 'CREATE_DRAFT',
    then: object({
      paymentMethod: string().oneOf(['BANK_TRANSFER', 'CARD', 'CASH', 'OFFSET', 'FREE_OF_CHARGE']),
      bankAccount: string(),
      variableSymbol: $VariableAndSpecificSymbol,
      constantSymbol: $ConstantSymbol,
      specificSymbol: $VariableAndSpecificSymbol,
      iban: yupString,
      swift: yupString,
    }),
    otherwise: object({
      paymentMethod: string().oneOf(['BANK_TRANSFER', 'CARD', 'CASH', 'OFFSET', 'FREE_OF_CHARGE']),
      bankAccount: string().when('paymentMethod', {
        is: 'BANK_TRANSFER',
        then: string().required(),
        otherwise: string(),
      }),
      variableSymbol: $VariableAndSpecificSymbol,
      constantSymbol: $ConstantSymbol,
      specificSymbol: $VariableAndSpecificSymbol,
      iban: yupString,
      swift: yupString,
    }),
  }),
  invoiceItems: mixed().when('submitType', {
    is: 'CREATE_DRAFT',
    then: array().nullable(),
    otherwise: array().of(
      object({
        pricePerUnit: string().nullable().required(),
        quantity: string()
          .matches(POSITIVE_NUMBER_REGEX, i18n.t('general.notifications.numberPositive'))
          .nullable()
          .required(),
        vatType: mixed()
          .required()
          .oneOf([['S', 'R', 'MR', 'E', 'Z', 'SR', 'P']]),
        description: yupString.required(),
      })
    ),
  }),
  exchangeRateRatio: mixed().when('isExchangeRateRatioEnabled', {
    is: true,
    then: mixed().when('submitType', {
      is: 'CREATE_DRAFT',
      then: object({
        amount: string()
          .oneOf(['1', '100', '1000'], i18n.t('general.notifications.ratioAmountError'))
          .nullable(),
        currency: string(),
        ratio: number().positive().nullable(),
      }),
      otherwise: object({
        amount: string()
          .oneOf(['1', '100', '1000'], i18n.t('general.notifications.ratioAmountError'))
          .nullable(),
        currency: string().required(),
        ratio: number().positive().nullable().required(),
      }),
    }),
    otherwise: mixed(),
  }),
  internalNote: yupString,
  note: yupString,
  sendAfterCreation: boolean().nullable(),
});
