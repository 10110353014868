import {showNotification} from 'platform/components';

import i18n from '@omnetic-dms/i18n';

import {RoundingDefinitionService} from '../../services/RoundingDefinitionService';
import {CreateRoundingDefinitionRequestBody} from '../../types/CreateRoundingDefinitionRequestBody';
import {RoundingDefinition} from '../../types/RoundingDefinition';
import {UpdateRoundingDefinitionRequestBody} from '../../types/UpdateRoundingDefinitionRequestBody';
import {createAsyncThunk} from '../../utils/createAsyncThunk';
import {getErrorMessages} from '../../utils/getErrorMessage';
import {roundingDefinitionActions} from './reducer';

const DOCUMENT_ROUDNING_DEFINITIONS_SLICE_NAME = 'documentRoundingDefinitions';

export const loadRoundingStepList = createAsyncThunk(
  `${DOCUMENT_ROUDNING_DEFINITIONS_SLICE_NAME}/loadRoundingStepList`,
  async (_, {extra, dispatch, rejectWithValue}) => {
    try {
      const items = await extra.callApi(RoundingDefinitionService.getRoundStepEnum);

      dispatch(roundingDefinitionActions.setRoundingStepList(items));

      return items;
    } catch (error: any) {
      showNotification.error(getErrorMessages(error).toString());

      return rejectWithValue(error);
    }
  }
);

export const loadDocumentRoundingDefinitionItems = createAsyncThunk(
  `${DOCUMENT_ROUDNING_DEFINITIONS_SLICE_NAME}/loadDocumentRoundingDefinitionItems`,
  async (_, {rejectWithValue, dispatch, extra}) => {
    try {
      const items = await extra.callApi(RoundingDefinitionService.getRoundingDefinitionList);

      dispatch(roundingDefinitionActions.setDocumentRoundingDefinitionItems(items));

      return items;
    } catch (error: any) {
      showNotification.error(getErrorMessages(error).toString());

      return rejectWithValue(error);
    }
  }
);

export const deleteDocumentRoundingDefinition = createAsyncThunk(
  `${DOCUMENT_ROUDNING_DEFINITIONS_SLICE_NAME}/deleteDocumentRoundingDefinition`,
  async (definitionId: string, {rejectWithValue, dispatch, extra}) => {
    try {
      dispatch(roundingDefinitionActions.deleteDocumentRoundingDefinitionItem(definitionId));

      await extra.callApi(RoundingDefinitionService.deleteRoundingDefinition, {
        id: definitionId,
      });
      showNotification.success(i18n.t('general.notifications.success'));
    } catch (error: any) {
      showNotification.error(getErrorMessages(error).toString());

      return rejectWithValue(error);
    }
  }
);

export const loadDocumentRoundingDefinitionDetail = createAsyncThunk<RoundingDefinition, string>(
  `${DOCUMENT_ROUDNING_DEFINITIONS_SLICE_NAME}/loadDocumentRoundingDefinitionDetail`,
  async (definitionId, {dispatch, rejectWithValue, extra}) => {
    try {
      const response = await extra.callApi(RoundingDefinitionService.getRoundingDefinition, {
        id: definitionId,
      });

      const {id, ...data} = response;

      dispatch(roundingDefinitionActions.setDetail({id, data}));

      return response;
    } catch (error: any) {
      showNotification.error(getErrorMessages(error).toString());

      return rejectWithValue(error);
    }
  }
);

export const createDocumentRoundingDefinition = createAsyncThunk<
  RoundingDefinition,
  CreateRoundingDefinitionRequestBody
>(
  `${DOCUMENT_ROUDNING_DEFINITIONS_SLICE_NAME}/createDocumentRoundingDefinition`,
  async (data, {extra, rejectWithValue, dispatch}) => {
    try {
      const response = await extra.callApi(RoundingDefinitionService.createRoundingDefinition, {
        requestBody: data,
      });

      const {id: _id, ...definition} = response;
      dispatch(roundingDefinitionActions.setDetail({id: _id, data: definition}));

      return response;
    } catch (error: any) {
      showNotification.error(getErrorMessages(error).toString());

      return rejectWithValue(error);
    }
  }
);

export const updateDocumentRoundingDefinition = createAsyncThunk<
  RoundingDefinition,
  {id: string; data: UpdateRoundingDefinitionRequestBody}
>(
  `${DOCUMENT_ROUDNING_DEFINITIONS_SLICE_NAME}/updateDocumentRoundingDefinition`,
  async ({id, data}, {extra, dispatch, rejectWithValue}) => {
    try {
      const response = await extra.callApi(RoundingDefinitionService.updateRoundingDefinition, {
        requestBody: data,
        id,
      });

      const {id: _id, ...definition} = response;
      dispatch(roundingDefinitionActions.setDetail({id: _id, data: definition}));

      return response;
    } catch (error: any) {
      showNotification.error(getErrorMessages(error).toString());

      return rejectWithValue(error);
    }
  }
);
