import styled, {css} from 'styled-components';
import {match} from 'ts-pattern';

import {ComponentProps, forwardRef} from 'react';

import {always} from 'ramda';

import {TestIdProps} from 'shared';

type ButtonVariant = 'default' | 'primary';

interface SidebarButtonProps extends ComponentProps<'button'>, TestIdProps {
  isActive: boolean;
  variant?: ButtonVariant;
}

export const SidebarButton = forwardRef<HTMLButtonElement, SidebarButtonProps>((props, ref) => (
  <StyledButton $variant={props.variant} ref={ref} {...props}>
    <StyledButtonContent $variant={props.variant} $isActive={props.isActive}>
      {props.children}
    </StyledButtonContent>
  </StyledButton>
));

const getVariantStyles = (
  props: {variant?: ButtonVariant; isActive?: boolean},
  applyToChildren?: boolean
) => {
  const childSelector = applyToChildren ? ' > *' : '';

  return match(props.variant)
    .with(
      'primary',
      always(css`
        & ${childSelector} {
          background: ${({theme}) =>
            props.isActive ? theme.colors.palettes.blue[80][100] : theme.colors.general.accent};
          color: ${({theme}) => theme.colors.general.white};
        }
        &:hover ${childSelector} {
          background: ${({theme}) => theme.colors.palettes.blue[70][100]};
        }
        &:active ${childSelector} {
          background: ${({theme}) => theme.colors.palettes.blue[80][100]};
        }
        &:disabled ${childSelector} {
          background: ${({theme}) => theme.colors.general.accent};
        }
      `)
    )
    .otherwise(
      always(css`
        background: ${({theme}) =>
          props.isActive
            ? theme.colors.palettes.neutral[500][100]
            : theme.colors.palettes.neutral[900][100]};
        &:hover ${childSelector} {
          background: ${({theme}) => theme.colors.palettes.neutral[500][100]};
        }
      `)
    );
};

const StyledButton = styled.button<{$variant?: ButtonVariant}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({theme}) => theme.getSize(2)};
  &:hover > * {
    background-color: ${(props) => props.theme.colors.palettes.neutral[500][100]};
  }
  ${({$variant}) => getVariantStyles({variant: $variant}, true)};
`;

const StyledButtonContent = styled.span<{$isActive: boolean; $variant?: ButtonVariant}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({theme}) => theme.getSize(8)};
  height: ${({theme}) => theme.getSize(8)};
  border-radius: ${({theme}) => theme.getSize(1)};
  ${({$variant, $isActive}) => getVariantStyles({variant: $variant, isActive: $isActive})};
`;
