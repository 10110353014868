import {DropdownItem} from 'platform/components';
import {Text} from 'platform/foundation';

import {browserStorageKey} from '@omnetic-dms/config';
import i18n from '@omnetic-dms/i18n';

import {CI_MODE, CI_MODE_OFF, CI_MODE_ON} from 'shared';

import {getFixedTranslation} from '../utils/getFixedTranslation';

export function TranslationDebug() {
  const lastKnownLanguage = localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE);
  const areTranslationsShown = i18n.language === CI_MODE;

  const toggleTranslationVisibility = () => {
    if (areTranslationsShown) {
      localStorage.setItem(browserStorageKey.CI_MODE, CI_MODE_OFF);
      i18n.changeLanguage(lastKnownLanguage ?? 'en');
    } else {
      localStorage.setItem(browserStorageKey.CI_MODE, CI_MODE_ON);
      i18n.changeLanguage(CI_MODE);
    }

    window.location.reload();
  };

  return (
    <DropdownItem
      label={
        <Text size="small" color={areTranslationsShown ? 'warning' : undefined}>
          {areTranslationsShown
            ? getFixedTranslation('page.settings.labels.hideTranslations')
            : getFixedTranslation('page.settings.labels.showTranslations')}
        </Text>
      }
      onClick={toggleTranslationVisibility}
      severity={areTranslationsShown ? 'warning' : undefined}
      data-testid="layout-header-devSettings-showTranslation"
      leftIcon={areTranslationsShown ? 'action/visibility_off' : 'action/visibility'}
    />
  );
}
