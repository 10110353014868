export const getVolumeByDimensions = (
  dimensionA: number,
  dimensionB: number,
  dimensionC: number
) => {
  if (
    dimensionA < 0 ||
    isNaN(dimensionA) ||
    dimensionB < 0 ||
    isNaN(dimensionB) ||
    dimensionC < 0 ||
    isNaN(dimensionC)
  ) {
    return null;
  }
  return dimensionA * dimensionB * dimensionC;
};
