import {css, useTheme} from 'styled-components';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useResponsivePropValue} from '../../hooks/useResponsivePropValue';
import {ValueByDevice} from '../../types/ValueByDevice';
import {ThemeColorTextPath} from '../../utils/foundationTheme';
import {useDevice} from '../DeviceProvider/useDevice';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
export type HeadingAlign = 'left' | 'center' | 'right';

export interface HeadingProps extends TestIdProps {
  size: HeadingLevel;
  isSingleLine?: boolean;
  alternative?: true;
  headingLevel?: HeadingLevel;
  children?: Nullish | string;
  color?: ThemeColorTextPath;
  overflowWrap?: 'anywhere';
  align?: HeadingAlign | ValueByDevice<HeadingAlign>;
  isInline?: boolean;
}

export function Heading(props: HeadingProps) {
  const theme = useTheme();
  const device = useDevice();
  const align = useResponsivePropValue(props.align);

  return (
    <div
      role="heading"
      aria-level={props.headingLevel ?? props.size}
      css={css`
        color: ${theme.colors.text[props.color ?? 'primary']};
        font-family: ${theme.fonts.heading};
        font-size: ${theme.fontSizes.heading[device][props.size]};
        line-height: ${theme.lineHeights.heading[device][props.size]};
        font-weight: ${theme.fontWeights.heading[props.alternative ? 'alternative' : 'default']};
        text-overflow: ${props.isSingleLine ? 'ellipsis' : 'initial'};
        white-space: ${props.isSingleLine ? 'nowrap' : 'initial'};
        overflow: ${props.isSingleLine ? 'hidden' : 'initial'};
        overflow-wrap: ${props.overflowWrap ?? 'initial'};
        letter-spacing: ${theme.letterSpacing.heading[device][props.size]};
        text-align: ${align ?? 'unset'};
        display: ${props.isInline ? 'inline' : 'block'};
      `}
      data-testid={suffixTestId('heading', props)}
    >
      {props.children}
    </div>
  );
}
