import {string, object, SchemaOf} from 'yup';

import {AdditionalInformationRequestBody} from '../types/AdditionalInformationRequestBody';

export const $AdditionalInformationRequestBody: SchemaOf<AdditionalInformationRequestBody> =
  object().shape({
    exteriorColor: string().nullable(),
    exteriorColorSpecification: string().nullable(),
    interiorColor: string().nullable(),
    interiorMaterial: string().nullable(),
    manufacturerColorCode: string().nullable(),
    manufacturerColorName: string().nullable(),
  });
