import {z} from 'zod';

export const GetServiceCaseActionsApiResponseSchema = z
  .object({
    caseActions: z
      .object({
        optionClick: z.string().nullable().optional(),
        options: z
          .array(
            z
              .object({
                type: z.enum(['list-item', 'divider']).nullable(),
                key: z.enum(['cancel', 'reOpen']).nullable(),
                style: z.enum(['hidden', 'disabled', 'enable']).nullable(),
                position: z.number().nullable(),
              })
              .nullable()
          )
          .optional(),
      })
      .nullable(),
  })
  .nullable();

export type GetServiceCaseActionsApiResponse = z.infer<
  typeof GetServiceCaseActionsApiResponseSchema
>;

export const GetServiceCaseActionsApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type GetServiceCaseActionsApiArg = z.infer<typeof GetServiceCaseActionsApiArgSchema>;

export const PostServiceCaseActionCancelApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type PostServiceCaseActionCancelApiArg = z.infer<
  typeof PostServiceCaseActionCancelApiArgSchema
>;

export const PostServiceCaseActionCancelResponseSchema = z.void();

export type PostServiceCaseActionCancelResponse = z.infer<
  typeof PostServiceCaseActionCancelResponseSchema
>;

export const PostServiceCaseActionReOpenApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type PostServiceCaseActionReOpenApiArg = z.infer<
  typeof PostServiceCaseActionReOpenApiArgSchema
>;

export const PostServiceCaseActionReOpenResponseSchema = z.void();

export type PostServiceCaseActionReOpenResponse = z.infer<
  typeof PostServiceCaseActionReOpenResponseSchema
>;
