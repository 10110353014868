import {differenceInCalendarDays} from 'date-fns';
import {Button, DataStatus, FormField, Tooltip} from 'platform/components';
import {Grid, HStack, Heading, Show, Text, VStack} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {head, isNotNil} from 'ramda';
import {isNotNaN} from 'ramda-adjunct';

import {
  ClaimedSolution,
  ComplaintResponseBody,
  useGetSaleVehicleQuery,
  useGetTenantQuery,
  useGetVehicleQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, parseDate, suffixTestId} from 'shared';

import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {EMPTY_PLACEHOLDER} from '../../../constants/placeholders';
import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import {PredefinedTextArea} from '../../PredefinedTextArea/PredefinedTextArea';
import {ComplaintForm} from '../types/complaintForm';
import {ComplaintStepProps} from '../types/complaintStepProps';

interface ComplaintReasonProps extends ComplaintStepProps {
  vehicleId: string;
  complaint: ComplaintResponseBody | Nullish;
  onInspectionClick: (complaint: ComplaintResponseBody) => void;
  isInspectionButtonLoading: boolean;
}

interface SolutionOption {
  label: string;
  value: ClaimedSolution;
}

const solutions: SolutionOption[] = [
  {
    label: i18n.t('entity.vehicleComplaint.labels.discount'),
    value: 'DISCOUNT',
  },
  {
    label: i18n.t('entity.vehicleComplaint.labels.repair'),
    value: 'REPAIR',
  },
  {
    label: i18n.t('entity.vehicleComplaint.labels.return'),
    value: 'RETURN',
  },
];

export function ComplaintReason(props: ComplaintReasonProps) {
  const formatNumber = useFormatNumber();
  const [canReadInspection, canCreateInspection] = usePermissions({
    permissionKeys: ['readInspection', 'createInspection'],
    scopes: {
      // TODO: fix inspection scope
      readInspection: {inspectionType: ''},
    },
  });

  const {data: tenant, isLoading: isTenantLoading, isError: isTenantError} = useGetTenantQuery();

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleQuery({vehicleId: props.vehicleId});

  const {
    data: saleVehicle,
    isLoading: isSaleVehicleLoading,
    isError: isSaleVehicleError,
  } = useGetSaleVehicleQuery({vehicleId: props.vehicleId});

  const isLoading = isTenantLoading || isVehicleLoading || isSaleVehicleLoading;
  const isError = isTenantError || isVehicleError || isSaleVehicleError;

  const currency = tenant?.currency ?? DEFAULT_CURRENCY;
  const dateSold = parseDate(saleVehicle?.closedSellingBusinessCaseDate ?? '');
  const dateReceivedAt = parseDate(props.formApi.getValues('receiveAt'));
  const daysSinceSold = differenceInCalendarDays(dateReceivedAt, dateSold);
  const claimedSolution = head(props.formApi.watch('claimedSolution') ?? []);

  const isInspectionButtonAvailable = isNotNil(props.complaint?.inspectionId)
    ? canReadInspection
    : canCreateInspection;
  const inspectionButtonTooltip = !isInspectionButtonAvailable
    ? i18n.t('general.labels.noPermission')
    : undefined;

  return (
    <VStack spacing={4}>
      <Heading size={3}>{i18n.t('entity.vehicleComplaint.labels.complaintReason')}</Heading>

      <DataStatus isLoading={isLoading} isError={isError}>
        <FormField
          name="reason"
          label={i18n.t('entity.vehicleComplaint.labels.primaryClaimReason')}
          type="text"
          isRequired
          control={props.control}
          data-testid={suffixTestId('reason', props)}
        />

        <Grid columns={2}>
          <FormField
            name="receiveAt"
            label={i18n.t('entity.vehicleComplaint.labels.dateReceived')}
            type="apiDate"
            control={props.control}
            isRequired
            data-testid={suffixTestId('receiveAt', props)}
          />
          <VStack spacing={2}>
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.vehicleComplaint.labels.daysSinceSold')}
            </Text>
            <Text alternative>
              {isNotNaN(daysSinceSold) ? formatNumber(daysSinceSold) : EMPTY_PLACEHOLDER}
            </Text>
          </VStack>
        </Grid>

        <Grid columns={2}>
          <FormField
            name="currentMileage"
            label={i18n.t('entity.vehicle.labels.currentMileage')}
            type="integer"
            isRequired
            suffix={i18n.t('general.metric.km')}
            control={props.control}
            data-testid={suffixTestId('currentMileage', props)}
          />
          <VStack spacing={2}>
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.vehicle.labels.sellingMileage')}
            </Text>
            <Text alternative>
              {isNotNil(vehicle?.vehicle.mileage)
                ? `${formatNumber(vehicle?.vehicle.mileage)} ${i18n.t('general.metric.km')}`
                : EMPTY_PLACEHOLDER}
            </Text>
          </VStack>
        </Grid>

        <FormField
          name="problem"
          label={i18n.t('entity.vehicleComplaint.labels.problem')}
          type="textarea"
          isResizable
          control={props.control}
          data-testid={suffixTestId('problem', props)}
        />

        <Grid columns={2}>
          <FormField
            name="claimedSolution"
            type="chips"
            label={i18n.t('entity.vehicleComplaint.labels.claimedSolution')}
            options={solutions}
            isRequired
            control={props.control}
            data-testid={suffixTestId('claimedSolution', props)}
          />
          <Show when={claimedSolution === 'DISCOUNT'}>
            <FormField
              name="claimedDiscount"
              label={i18n.t('entity.vehicleComplaint.labels.claimedDiscount')}
              type="currency"
              currency={currency}
              control={props.control}
              data-testid={suffixTestId('claimedDiscount', props)}
            />
          </Show>
        </Grid>

        <PredefinedTextArea<ComplaintForm>
          name="note"
          label={i18n.t('general.labels.note')}
          context="reason-note"
          resource="COMPLAINT"
          isResizable
          formApi={props.formApi}
        />

        <Show when={isNotNil(props.complaint)}>
          <HStack>
            <Tooltip label={inspectionButtonTooltip}>
              <Button
                title={i18n.t('entity.vehicleComplaint.labels.carAuditInspection')}
                leftIcon="action/youtube_searched_for"
                variant="secondary"
                onClick={() => props.onInspectionClick(props.complaint!)}
                isLoading={props.isInspectionButtonLoading}
                isDisabled={!isInspectionButtonAvailable}
                data-testid={suffixTestId('carAuditInspection', props)}
              />
            </Tooltip>
          </HStack>
        </Show>
      </DataStatus>
    </VStack>
  );
}
