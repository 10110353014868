import {Box, Center, HStack, Show, ThemeIconKey, VStack} from 'platform/foundation';
import {RcFile, UploadRequestError} from 'rc-upload/es/interface';

import {MouseEventHandler, MouseEvent as ReactMouseEvent} from 'react';

import {isNotNil} from 'ramda';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {HelperText} from '../HelperText/HelperText';
import {Preview, PreviewAction} from '../Preview/Preview';
import {OnBeforeUpload, OnUploadError, OnUploadSuccess, Upload} from '../Upload/Upload';

export type UploadImageValue = {
  url: string;
  id: string;
};

export interface UploadImageProps extends FormControlProps<UploadImageValue | null>, TestIdProps {
  helperText?: string;
  uploadFileRequest: (file: File) => Promise<UploadImageValue>;
  srcValueFormatter?: (url: string) => string;
  linkValueFormatter?: (url: string) => string;
  onPreviewClick?: (url: string, event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDeleteClick?: MouseEventHandler<HTMLButtonElement>;
  onUploadError?: OnUploadError;
  onUploadStart?: (file: RcFile) => void;
  onUploadSuccess?: OnUploadSuccess;
  beforeUpload?: OnBeforeUpload;
  uploadText?: string;
  uploadingText?: string;
  uploadIcon?: ThemeIconKey;
  uploadingIcon?: ThemeIconKey;
  uploadErrorIcon?: ThemeIconKey;
  size?: 'minHeight' | 'default';
}

export function UploadImage(props: UploadImageProps) {
  const {value, onChange, uploadFileRequest} = props;

  const handleChange = (uploadData?: UploadImageValue) => onChange?.(uploadData ?? null);

  const handleDeleteClick = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.onDeleteClick) {
      return props.onDeleteClick(event);
    }
    handleChange();
  };

  const handlePreviewClick = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.onPreviewClick && value) {
      props.onPreviewClick(value.url, event);
    }
  };

  const handleRequest = async (file: File, onError?: (error: UploadRequestError) => void) => {
    await uploadFileRequest(file).then(handleChange).catch(onError);
  };

  return (
    <VStack>
      <HStack>
        <Box data-testid={suffixTestId('uploadImageWrapper', props)} width="100%" height="100%">
          <Center height="100%">
            {value ? (
              <HStack align="center" height={27} width={27}>
                <Preview
                  data-testid={suffixTestId('uploadImagePreview', props)}
                  url={props.srcValueFormatter?.(value.url) ?? value.url}
                  width={27}
                  height={27}
                  makeUrl={() => props.srcValueFormatter?.(value.url) ?? value.url}
                  shouldUseOriginal
                  fit="contain"
                  actions={buildArray<PreviewAction>()
                    .when(isNotNil(props.onPreviewClick), {
                      icon: 'action/visibility',
                      onClick: handlePreviewClick,
                    })
                    .add({
                      icon: 'action/delete',
                      isDisabled: props.isDisabled,
                      onClick: handleDeleteClick,
                    })}
                />
              </HStack>
            ) : (
              <Upload
                data-testid={suffixTestId('upload', props)}
                type="card"
                customRequest={({file, onError}) => handleRequest(file as File, onError)}
                isDisabled={props.isDisabled}
                accept="image/png, image/jpg, image/jpeg"
                shouldValidateAccept
                size={props.size}
                onStart={props.onUploadStart}
                onSuccess={props.onUploadSuccess}
                onError={props.onUploadError}
                beforeUpload={props.beforeUpload}
                uploadText={props.uploadText}
                uploadingText={props.uploadingText}
                uploadIcon={props.uploadIcon}
                uploadingIcon={props.uploadingIcon}
                errorIcon={props.uploadErrorIcon}
              />
            )}
          </Center>
        </Box>
      </HStack>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
