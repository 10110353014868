import {Option} from 'platform/components';

import {defaultTo, isNil} from 'ramda';

import {GetWarehouseAccountsResponse} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getOptionsFromWarehouseAccounts = (
  warehouseAccounts: GetWarehouseAccountsResponse | Nullish
): Option[] => {
  if (isNil(warehouseAccounts)) {
    return [];
  }

  return warehouseAccounts.map((warehouseAccount) => ({
    label: defaultTo('', warehouseAccount.name),
    value: warehouseAccount.id,
  }));
};
