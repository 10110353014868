import {z} from 'zod';

export const GetServiceCaseServicePackagesResponseSchema = z
  .object({
    servicePackageCategory: z
      .array(
        z
          .object({
            id: z.string(),
            name: z.string(),
            servicePackage: z
              .array(
                z
                  .object({
                    id: z.string(),
                    name: z.string(),
                    number: z.string().nullable().optional(),
                  })
                  .nullable()
              )
              .optional(),
          })
          .nullable()
      )
      .optional(),
  })
  .nullable();

export type GetServiceCaseServicePackagesResponse = z.infer<
  typeof GetServiceCaseServicePackagesResponseSchema
>;

export const GetServiceCaseServicePackagesArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type GetServiceCaseServicePackagesArg = z.infer<
  typeof GetServiceCaseServicePackagesArgSchema
>;

export const PostServiceCaseAddServicePackageApiResponseSchema = z.unknown();

export type PostServiceCaseAddServicePackageApiResponse = z.infer<
  typeof PostServiceCaseAddServicePackageApiResponseSchema
>;

export const PostServiceCaseAddServicePackageApiArgSchema = z.object({
  serviceCaseId: z.string(),
  body: z.object({
    servicePackage: z.object({
      servicePackageId: z.string(),
    }),
  }),
});

export type PostServiceCaseAddServicePackageApiArg = z.infer<
  typeof PostServiceCaseAddServicePackageApiArgSchema
>;

export const PostServiceOrderAddServicePackageApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  servicePackageId: z.string(),
});

export type PostServiceOrderAddServicePackageApiArg = z.infer<
  typeof PostServiceOrderAddServicePackageApiArgSchema
>;
