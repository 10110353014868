import {Positions} from '../Notification.types';

export const getAnimationStyle = (position: Positions) => {
  switch (position) {
    case 'bottom-right':
    case 'top-right':
      return {
        initial: {
          x: 100,
        },
        animate: {
          x: 0,
        },
        exit: {
          x: 100,
        },
      };
    case 'bottom-left':
      return {
        initial: {
          x: -100,
          y: 100,
        },
        animate: {
          x: 0,
          y: 100,
        },
        exit: {
          x: -100,
          y: 100,
        },
      };
    case 'top-left':
      return {
        initial: {
          x: -100,
        },
        animate: {
          x: 0,
        },
        exit: {
          x: -100,
        },
      };
    default:
      return {
        initial: {
          x: 100,
        },
        animate: {
          x: 0,
        },
        exit: {
          x: 100,
        },
      };
  }
};
