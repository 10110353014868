// eslint-disable-next-line no-restricted-imports
import {ComponentType} from 'react';
import {Field, FieldRenderProps} from 'react-final-form';

import {PossibleObject} from '../../../types/PossibleObject';
import {FieldName} from '../types/FieldName';

export interface SubscribeProps<
  T extends PossibleObject = undefined,
  K extends PossibleObject = undefined,
  V = any,
> {
  name: FieldName<T, K>;
  component: ComponentType<FieldRenderProps<V>>;
}
/**
 * @deprecated - use platform instead
 */
export function Subscribe<
  /** Type of object (passed down by Form component) to take names from */
  T extends PossibleObject = undefined,
  /** Type of parent (passed down by Form component) the array is in */
  K extends PossibleObject = undefined,
  V = any,
>({name, component}: SubscribeProps<T, K, V>) {
  // eslint-disable-next-line no-restricted-syntax
  const _name = name as unknown as string | string[];
  const fullName = typeof _name === 'string' ? _name : _name.join('.');

  return <Field name={fullName}>{component as any}</Field>;
}
