import {object, SchemaOf, string, mixed} from 'yup';

import i18n from '@omnetic-dms/i18n';

import {EmailData} from '../types/EmailData';
import {PersonData} from '../types/PersonData';
import {PhoneNumberData} from '../types/PhoneNumberData';

type PhoneNumber = {
  number: string;
  countryCode: string;
  prefix: string;
};

const isEmptyPhone = (value: PhoneNumber | null) =>
  !value || !value.number || value.number.length === 0;

const isFieldRequired = (emails: EmailData[], phoneNumbers: PhoneNumberData[], name: string) => {
  if (name && name.length > 0) {
    return false;
  }

  if (emails.some((emailData: EmailData) => emailData.email && emailData.email.length > 0)) {
    return true;
  }

  if (
    phoneNumbers.some(
      (phoneNumberData: PhoneNumberData) => isEmptyPhone(phoneNumberData.phoneNumber) === false
    )
  ) {
    return true;
  }

  return false;
};

export const $PersonData: SchemaOf<PersonData> = object({
  birthdate: string().defined().nullable(),
  citizenshipCode: string().defined().nullable(),
  firstName: string().defined().nullable(),
  genderKey: string().defined().nullable(),
  lastName: mixed().test(
    'fieldIsRequired',
    i18n.t('general.validations.fieldIsRequired'),
    function (value) {
      if (value) {
        return true;
      }

      return isFieldRequired(
        this.options?.context?.emails,
        this.options?.context?.phoneNumbers,
        this.options?.context?.personData.firstName
      );
    }
  ),
  middleName: string().defined().nullable(),
  personalIdentifier: string().defined().nullable(),
  roles: string().defined().nullable(),
  titleAfter: string().defined().nullable(),
  titleBefore: string().defined().nullable(),
});
