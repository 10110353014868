import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {PromoPhotosDataGrid} from './components/PromoPhotosDataGrid';

export function PromoPhotos() {
  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.salesSettings.labels.promotionalPhotos'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
        ],
      }}
      description={i18n.t('page.salesSettings.labels.promotionalPhotosDescription')}
      data-testid="settings-advertising-promoPhotos"
    >
      <PromoPhotosDataGrid />
    </SettingsTemplate>
  );
}
