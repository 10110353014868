import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Text, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

interface KeyLocationFormProps extends TestIdProps {
  keyLocation?: string | null;
  onClose: VoidFunction;
  onSubmit: FormSubmitHandler<FormType>;
}

export function KeyLocationForm(props: KeyLocationFormProps) {
  return (
    <Form<FormType>
      schema={formSchema}
      defaultValues={{keyLocation: props.keyLocation ?? null}}
      onSubmit={props.onSubmit}
    >
      {(control) => (
        <VStack spacing={4}>
          <Text size="small" color="tertiary">
            {i18n.t('entity.vehicle.labels.keyLocationInfoText')}
          </Text>
          <FormField
            type="text"
            name="keyLocation"
            control={control}
            data-testid={suffixTestId('keyLocation', props)}
          />
          <ButtonGroup align="right">
            <Button
              variant="secondary"
              onClick={props.onClose}
              title={i18n.t('general.actions.discard')}
              data-testid={suffixTestId('discard', props)}
            />
            <FormButton
              type="submit"
              variant="primary"
              control={control}
              title={i18n.t('general.actions.save')}
              data-testid={suffixTestId('save', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

type FormType = {
  keyLocation: string | null;
};

const formSchema = Yup.object().shape({
  keyLocation: Yup.string().required(),
});
