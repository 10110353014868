import {Tooltip} from 'platform/components';

import {FC, useMemo, useCallback, PropsWithChildren} from 'react';

import {isNotNil} from 'ramda-adjunct';

import {useTextFieldContext} from './hooks/useTextFieldContext';
import {IconWrapper, InputAdornmentWrapper} from './styles';
import {InputAdornmentOption} from './types';

export interface InputAdornmentProps extends InputAdornmentOption {
  classNameSuffix?: string;
  error?: boolean;
}

export const InputAdornment: FC<InputAdornmentProps> = ({
  Icon,
  render,
  disabled,
  onClick,
  error,
  classNameSuffix,
  wrapperCss,
  clickable,
  tooltipText,
  disableFocusToInput,
}) => {
  const {disabled: fieldDisabled, focusInput} = useTextFieldContext();

  const isDisabled = disabled ?? fieldDisabled;

  const className = useMemo(
    () => `text-field-input-adornment-${classNameSuffix ?? ''}`,
    [classNameSuffix]
  );

  const Result: FC<PropsWithChildren<any>> = useCallback(
    // @ts-ignore
    ({children}) => (
      <Tooltip placement="top" label={isDisabled ? '' : (tooltipText ?? '')}>
        <InputAdornmentWrapper
          css={wrapperCss}
          disabled={isDisabled}
          clickable={clickable}
          onClick={() => {
            if (!disableFocusToInput) {
              focusInput();
            }
            onClick?.();
          }}
          className={className}
        >
          {children}
        </InputAdornmentWrapper>
      </Tooltip>
    ),
    [
      className,
      onClick,
      focusInput,
      wrapperCss,
      clickable,
      disableFocusToInput,
      isDisabled,
      tooltipText,
    ]
  );

  if (isNotNil(render)) {
    return <Result>{render}</Result>;
  }

  if (isNotNil(Icon)) {
    return (
      <Result>
        <IconWrapper
          type="button"
          className="inputAdornment"
          tabIndex={clickable ? 0 : -1}
          disabled={isDisabled}
          error={error}
        >
          {Icon}
        </IconWrapper>
      </Result>
    );
  }

  return null;
};
