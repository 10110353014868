import {DIALOGS_EVENTS} from './dialogsEvents';

/**
 * @about Closes a dialog opened by openDialog().
 *
 * @param id id of the dialog
 *
 * @example
 * openDialog('Dialog Content', {id: 'dialog-id'});
 * ...
 * closeDialog('dialog-id')
 */
export function closeDialog(id?: string) {
  window.dispatchEvent(new CustomEvent(DIALOGS_EVENTS.close, {detail: {id}}));
}
