import {isNumber} from 'ramda-adjunct';

export function getAutoClose(
  autoClose: boolean | number,
  notificationAutoClose?: boolean | number
) {
  if (isNumber(notificationAutoClose)) {
    return notificationAutoClose;
  }

  if (notificationAutoClose === false || autoClose === false) {
    return false;
  }

  return autoClose;
}
