import {createAsyncThunk as createAsyncThunkDefault} from '@reduxjs/toolkit';

import type {AppDispatch, ThunkExtra} from '../hooks/useThunkDispatch';
import type {TeasState} from '../types/TeasState';

/**
 * @deprecated You should use RTK query
 */
export const createAsyncThunk = createAsyncThunkDefault.withTypes<{
  state: TeasState;
  dispatch: AppDispatch;
  rejectValue: unknown;
  extra: ThunkExtra;
}>();
