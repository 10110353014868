import {Alert, Button, Card, Choice, DataStatus, Separator} from 'platform/components';
import {HStack, Hide, Scroll, Show, VStack} from 'platform/foundation';

import {defaultTo} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {Either, RequiredTestIdProps, suffixTestId} from 'shared';

import {useBasketItems} from '../../hooks/useBasketItems';
import {Basket} from '../../types/basket/Basket';
import {BasketMechanic} from '../../types/basket/BasketMechanic';
import {EitherQuantityOrError} from '../../types/basket/EitherQuantityOrError';
import {HiddenMechanic} from '../../types/basket/HiddenMechanic';
import {getOptionsFromMechanics} from '../../utils/getOptionsFromMechanics';
import {BasketSummary} from '../BasketSummary/BasketSummary';
import {AfterSalesCorrectionBasketItem} from './components/AfterSalesCorrectionBasketItem';
import {CorrectionBasketItem} from './types/CorrectionBasketItem';
import {CorrectionBasketItemEditingDetails} from './types/CorrectionBasketItemEditingDetails';

interface AfterSalesCorrectionBasketProps<T extends CorrectionBasketItem>
  extends RequiredTestIdProps {
  basket: Basket<T>;
  mechanic: Either<BasketMechanic, HiddenMechanic>;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
  onEdit: (editingDetails: CorrectionBasketItemEditingDetails) => void;
  onDelete: (itemsIds: string[]) => Promise<void>;
}

export function AfterSalesCorrectionBasket<T extends CorrectionBasketItem>(
  props: AfterSalesCorrectionBasketProps<T>
) {
  const {
    basketItems,
    basketItemsCount,
    hasSomeBasketItemsSelected,
    hasAllBasketItemsSelected,
    onSelectBasketItem,
    onSelectAllBasketItems,
    onBulkDeleteBasketItems,
  } = useBasketItems({basketItems: props.basket?.items, onDeleteBasketItems: props.onDelete});

  const basketTitle = `${i18n.t('general.labels.addedItems')} (${basketItemsCount})`;

  const basketTotalPrice = props.basket?.totalPrice;

  return (
    <Card title={basketTitle} isFullHeight data-testid={suffixTestId('wrapper', props)}>
      <VStack height="100%" spacing={3}>
        <Hide when={props.mechanic?.isMechanicHidden}>
          <Choice
            label={i18n.t('entity.order.labels.mechanic')}
            value={defaultTo('', props.mechanic?.selectedMechanicId)}
            options={defaultTo([], getOptionsFromMechanics(props.mechanic?.mechanics))}
            onChange={props.mechanic?.onMechanicChange}
            errorMessage={props.mechanic?.mechanicError}
            isRequired={props.mechanic?.isMechanicRequired}
            isLoading={props.mechanic?.areMechanicsLoading}
            data-testid={suffixTestId('assignedMechanic', props)}
          />
        </Hide>

        <Show when={props.basket.hasInvalidItems}>
          <Alert
            type="inline"
            variant="error"
            title={i18n.t('general.notifications.basketHasInvalidItems')}
            data-testid={suffixTestId('alert.hasInvalidItems', props)}
          />
        </Show>

        <HStack justify="space-between">
          <Hide when={isNilOrEmpty(basketItems)}>
            <Button
              type="button"
              variant="link"
              leftIcon="action/done_all"
              title={
                hasAllBasketItemsSelected
                  ? i18n.t('general.actions.unselectAll')
                  : i18n.t('general.actions.selectAll')
              }
              onClick={onSelectAllBasketItems}
              data-testid={suffixTestId('actions.toggleSelect', props)}
            />
          </Hide>

          <Show when={hasSomeBasketItemsSelected}>
            <Button
              type="button"
              variant="dangerLink"
              leftIcon="navigation/close"
              title={i18n.t('general.actions.remove')}
              isLoading={props.basket.isDeletingItems}
              onClick={onBulkDeleteBasketItems}
              data-testid={suffixTestId('actions.remove', props)}
            />
          </Show>
        </HStack>

        <Scroll flex={1} auto>
          <VStack height="100%" spacing={4}>
            <DataStatus
              isLoading={props.basket.isLoading}
              isError={props.basket.hasError}
              isEmpty={basketItemsCount === 0}
              emptyMessage={i18n.t('general.notifications.noItemsSelected')}
              minHeight="100%"
            >
              {basketItems?.map((basketItem, index) => (
                <AfterSalesCorrectionBasketItem
                  key={basketItem.id}
                  item={basketItem}
                  onSelect={onSelectBasketItem}
                  onQuantityChange={props.onQuantityChange}
                  onEdit={props.onEdit}
                  onDelete={props.onDelete}
                  data-testid={suffixTestId(`basketItem-[${index}]`, props)}
                />
              ))}
            </DataStatus>
          </VStack>
        </Scroll>

        <Separator />

        <BasketSummary totalPrice={basketTotalPrice} data-testid={suffixTestId('summary', props)} />
      </VStack>
    </Card>
  );
}
