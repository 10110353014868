export const getMakeOptions = (
  makes: {
    default_label: string;
    make: string;
  }[]
) =>
  makes.map((make) => ({
    label: make.default_label,
    value: make.make,
  }));
