import {
  Button,
  ButtonGroup,
  Card,
  Choice,
  closeCurrentDialog,
  IconButton,
  MultiChoice,
} from 'platform/components';
import {Box, Grid, GridItem, Hide, Space, Text, VStack} from 'platform/foundation';

import {
  PermissionScopeResponseBody,
  ProtectedGroupResponseBody,
  ProtectedUnitResponseBody,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {Scope, useScopes} from './hooks/useScopes';

interface ScopeFormProps extends TestIdProps {
  protectedUnit: ProtectedUnitResponseBody;
  roleId: string;
  resource: ProtectedGroupResponseBody;
  onSubmit: (scopes: Scope[]) => void;
  defaultScopes: PermissionScopeResponseBody[] | Nullish;
}

export function ScopeForm(props: ScopeFormProps) {
  const roleAndActionTitle = ` ${props.resource.name} / ${props.protectedUnit.name}`;

  const {
    scopes,
    validateScopes,
    getAvailableScopesForIndex,
    isRowScopeIdNotSelectedForIndex,
    scopeOptionsForIndex,
    handleUpdateScopeId,
    handleUpdateOptionIds,
    appendEmptyScope,
    handleRemoveScope,
  } = useScopes(props.defaultScopes, props.protectedUnit);

  const handleSubmit = () => {
    const areScopeValuesValid = validateScopes();

    if (areScopeValuesValid) {
      props.onSubmit(scopes);
      closeCurrentDialog();
    }
  };

  return (
    <>
      <VStack spacing={4}>
        <Box>
          <Text size="small" inline>
            {i18n.t('acl.dialog.grantAccessFor')}
          </Text>
          <Text size="small" inline alternative>
            {roleAndActionTitle}
          </Text>
        </Box>
        <Card variant="inlineGrey">
          <VStack spacing={2}>
            {scopes.map((scope, index) => (
              <Grid columns={17} key={scope.scopeId} align="flex-end">
                <GridItem span={8}>
                  <Choice
                    label={i18n.t('acl.dialog.label.scopeType')}
                    value={scope.scopeId}
                    onChange={handleUpdateScopeId(scope)}
                    isRequired
                    isNotClearable
                    errorMessage={scope.errorMessages?.scopeIdMessage}
                    options={getAvailableScopesForIndex(index)}
                    data-testid={suffixTestId(`[${index}]-scopeId`, props)}
                  />
                </GridItem>
                <GridItem span={8}>
                  <MultiChoice
                    value={scope.optionIds}
                    label={i18n.t('acl.dialog.label.scopeValue')}
                    isRequired
                    onChange={handleUpdateOptionIds(scope)}
                    isDisabled={isRowScopeIdNotSelectedForIndex(index)}
                    errorMessage={scope.errorMessages?.optionIdsMessage}
                    options={scopeOptionsForIndex(index)}
                    data-testid={suffixTestId(`[${index}]-optionIds`, props)}
                  />
                </GridItem>
                <Hide when={scopes.length === 1}>
                  <GridItem span={1}>
                    <IconButton
                      icon="action/delete"
                      onClick={() => handleRemoveScope(scope.scopeId)}
                      severity="danger"
                      data-testid={suffixTestId(`[${index}]-delete`, props)}
                    />
                  </GridItem>
                </Hide>
              </Grid>
            ))}
            <Hide when={scopes.length === props.protectedUnit.scopes.length}>
              <ButtonGroup>
                <Button
                  variant="link"
                  leftIcon="content/add_circle"
                  title={i18n.t('general.actions.add')}
                  onClick={appendEmptyScope}
                  data-testid={suffixTestId(`submit`, props)}
                />
              </ButtonGroup>
            </Hide>
          </VStack>
        </Card>
      </VStack>
      <Space vertical={4} />
      <ButtonGroup align="right">
        <Button
          title={i18n.t('general.actions.cancel')}
          variant="secondary"
          onClick={closeCurrentDialog}
          data-testid={suffixTestId('cancel', props)}
        />
        <Button
          title={i18n.t('acl.dialog.button.submit')}
          variant="primary"
          onClick={handleSubmit}
          data-testid={suffixTestId('submit', props)}
        />
      </ButtonGroup>
    </>
  );
}
