import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {Advertisement} from '../../types/Advertisement';
import {ResponseStateType} from '../../types/CommonTypes';
import {TemplateListItem} from '../../types/TemplateListItem';
import {VehicleAdvertisingSettings} from '../../types/VehicleAdvertisingSettings';
import {STATE_NAME} from './constants';

type AdvertisementStateCommon = {
  isLoading: boolean;
};

export type AdvertisementState = {
  adPlatforms: ResponseStateType<Advertisement[]>; // List of platforms
  adGeneralSettings: VehicleAdvertisingSettings; // General tab
  inSale: AdvertisementStateCommon; // In Sale state
  windshieldTemplates: TemplateListItem[];
};

const initialState: AdvertisementState = {
  adPlatforms: {
    loading: true,
    data: [],
    error: null,
  },
  adGeneralSettings: {
    inSale: false,
    vehicleId: '',
    platforms: [],
    general: {
      attributes: [],
      updatedAt: null,
      updatedBy: null,
    },
  },
  inSale: {
    isLoading: false,
  },
  windshieldTemplates: [],
};

const advertisementSlice = createSlice({
  name: STATE_NAME,
  initialState,
  reducers: {
    adPlatformsSuccess(state, action: PayloadAction<Advertisement[]>) {
      state.adPlatforms.loading = false;
      state.adPlatforms.data = action.payload;
    },
    windshieldTemplateSuccess(state, action: PayloadAction<TemplateListItem[]>) {
      state.windshieldTemplates = action.payload;
    },
    adPlatformsError(state, action) {
      state.adPlatforms.loading = false;
      state.adPlatforms.error = action.payload;
    },
  },
});

const {reducer, actions} = advertisementSlice;

export const {adPlatformsSuccess, adPlatformsError, windshieldTemplateSuccess} = actions;

export const advertisementReducer = reducer;
