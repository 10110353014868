import {isNotNil} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {VehicleCatalogueEnumOption} from '../../../types/VehicleCatalogueEnumOption';
import {findTopMostItem} from '../../../utils/findTopMostItem';
import {getAllTreeKeys} from '../../../utils/getAllTreeKeys';

type HandleCheckBoxSelectType = {
  value: string;
  selected: Nullish | string[] | '';
  onFeatureSelect: (selected: string[]) => void;
  allowSubItemMultiselect: boolean;
  features: VehicleCatalogueEnumOption[];
};
/**
 * Function to check or uncheck Checkbox in tree
 * When i unchecked las node, all parents are unchecked too
 * @param value key of checked/unchecked node
 * @param selected array of keys - checked nodes
 * @param onFeatureSelect function witch should change the store, it gets new array of selected keys
 * @param allowSubItemMultiselect used for filters, where is possible to check more subNotes of the same item
 * @param features list of all features from catalogue
 */
export const handleCheckboxTreeSelect = (props: HandleCheckBoxSelectType) => {
  const selected = props.selected || [];

  // value is already selected - deselect it
  if (selected.includes(props.value)) {
    props.onFeatureSelect(selected.filter((item) => item !== props.value));
    return;
  }

  if (props.allowSubItemMultiselect) {
    props.onFeatureSelect(selected.concat([props.value]));
    return;
  }

  // first deselect all the relatives of the newly selected feature
  const topMostItem = findTopMostItem(props.value, props.features);
  const treeKeys = isNotNil(topMostItem) ? getAllTreeKeys(topMostItem) : [];
  const newSelection = selected.filter((item) => !treeKeys.includes(item));

  // then add the feature to the selection
  props.onFeatureSelect(newSelection.concat([props.value]));
};
