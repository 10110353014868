import {Grid, ValueByDevice} from 'platform/foundation';

import {Nullish} from 'shared';

import {ImageItem} from './ImageItem';

type BackgroundSelectorType = {
  options: {name: string; backgroundId: string; id: string}[] | Nullish;
  columns: number | ValueByDevice<number>;
  activeOptionIndex: number;
  onClick: (selectedIndex: number) => void;
};

export const BackgroundSelector = (props: BackgroundSelectorType) => (
  <Grid columns={props.columns}>
    {props.options?.map((option, index) => (
      <ImageItem
        key={option.name}
        value={option.backgroundId}
        isActive={props.activeOptionIndex === index}
        onClick={() => {
          props.onClick(index);
        }}
        name={option.name}
      />
    ))}
  </Grid>
);
