import {AppDispatch} from '../../hooks/useThunkDispatch';
import {
  loadPremiumFeatures,
  loadCarDetailsHighlightsRequest,
  loadCarDetailsShortcomingsRequest,
  loadCarDetailsVehicleDetailRequest,
} from '../carDetails/actions';
import {getCustomTenantCatalogue} from '../vehicleCatalogue/actions';

export const loadVehicleDetailsData = (vehicleId: string, dispatch: AppDispatch) =>
  Promise.all([
    dispatch(loadCarDetailsVehicleDetailRequest({vehicleId})),
    dispatch(getCustomTenantCatalogue.action({})),
    dispatch(loadCarDetailsHighlightsRequest({vehicleId})),
    dispatch(loadCarDetailsShortcomingsRequest({vehicleId})),
    dispatch(loadPremiumFeatures()),
  ]);
