import {
  Card,
  DataStatus,
  FormSubmitHandler,
  openDialog,
  showNotification,
} from 'platform/components';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  PatchVehicleWindshieldAdvertisingSettingsRequestBody,
  useEditWindshieldSettingsMutation,
  useGetParticipationQuery,
  useGetWindshieldSettingsQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError, usePermissions} from '@omnetic-dms/shared';

import {TestIdProps, useRequiredParams} from 'shared';

import {WindshieldEdit} from './WindshieldEdit';
import {WindshieldSettingsForm, WindshieldSettingsFormValues} from './WindshieldSettingsForm';

export function WindshieldTab(props: TestIdProps) {
  const {id: vehicleId} = useRequiredParams();

  const {
    data: settings,
    isLoading,
    isError,
  } = useGetWindshieldSettingsQuery(
    {
      vehicleId,
    },
    {
      skip: isNilOrEmpty(vehicleId),
    }
  );

  const [editWindshield] = useEditWindshieldSettingsMutation();

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: vehicleId,
  });

  const [hasEditGeneralSettingsPermission] = usePermissions({
    permissionKeys: ['editGeneralSettings'],
    scopes: {
      editGeneralSettings: vehicleParticipation,
    },
  });

  const handleSubmit: FormSubmitHandler<WindshieldSettingsFormValues> = (values, _, reset) => {
    const body: PatchVehicleWindshieldAdvertisingSettingsRequestBody = {
      attributes: [
        {
          code: 'description',
          value: values.description,
          override: isNotNilOrEmpty(values.description),
          publish: true,
        },
        {
          code: 'title_extension',
          value: values.title,
          override: isNotNilOrEmpty(values.title),
          publish: true,
        },
      ],
    };

    return editWindshield({vehicleId, patchVehicleWindshieldAdvertisingSettingsRequestBody: body})
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.advertisement.dataEditSubtitle'));
        reset(undefined, {keepValues: true});
      })
      .catch(handleApiError);
  };

  const handleEdit = () => {
    openDialog(
      <WindshieldEdit vehicleId={vehicleId} isDisabled={!hasEditGeneralSettingsPermission} />,
      {
        title: i18n.t('entity.vehicle.labels.windshieldAttributes'),
      }
    );
  };

  return (
    <Card title={i18n.t('entity.vehicle.labels.windshieldAttributes')}>
      <DataStatus isLoading={isLoading} isError={isError}>
        <WindshieldSettingsForm
          data={settings}
          vehicleId={vehicleId}
          descriptionLabel={i18n.t('page.advertisement.labels.windshieldDescription')}
          onSubmit={handleSubmit}
          onHandleEdit={handleEdit}
          isDisabled={!hasEditGeneralSettingsPermission}
          data-testid={props['data-testid']}
        />
      </DataStatus>
    </Card>
  );
}
