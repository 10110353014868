import {CustomFiltersPanelWrapper} from '../components/CustomFiltersPanelWrapper';
import {SettingsToolPanelWrapper} from '../components/SettingsToolPanelWrapper';
import {SideBarDef} from '../types/AgGridTypes';

export const sideBarOptions: SideBarDef = {
  hiddenByDefault: false,
  toolPanels: [
    {
      id: 'columns',
      labelDefault: '',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressSyncLayoutWithGrid: true,
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnMove: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filters',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressSyncLayoutWithGrid: true,
      },
    },
    {
      id: 'settings',
      labelDefault: 'Settings',
      labelKey: 'settings',
      iconKey: 'columns',
      toolPanel: SettingsToolPanelWrapper,
    },
    {
      id: 'customFilters',
      iconKey: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filter',
      toolPanel: CustomFiltersPanelWrapper,
    },
  ],
};
