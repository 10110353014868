const rKeys = (o: Record<string, any>, path = ''): any => {
  if (!o || typeof o !== 'object') {
    return path;
  }
  return Object.keys(o).map((key) => rKeys(o[key], path ? [path, key].join('.') : key));
};

/**
 * @about Utility function to get all possible paths from object
 * @returns Object paths as string or as array
 * @example
 *  const object = {a: {b: {c: 1}}
 *
 *  const paths = objectPaths(object);
 *
 *  paths.asString // 'a.b.c'
 *  paths.asArray // ['a', 'b', 'c']
 */
export const objectPaths = (o: Record<string, any>) => ({
  asString: rKeys(o).toString(),
  asArray: rKeys(o).toString().split('.').join(',').split(','),
});
