import {isNil} from 'ramda';

import {GetManufacturersResponse} from '@omnetic-dms/api';

export const getActiveManufacturerOptions = (
  manufacturers: GetManufacturersResponse | undefined
) => {
  if (isNil(manufacturers)) {
    return [];
  }

  return manufacturers
    ?.filter((manufacturer) => manufacturer.isActive)
    .map((manufacturer) => ({
      label: manufacturer.name,
      value: manufacturer.manufacturerId,
    }));
};
