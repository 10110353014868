import i18n from '@omnetic-dms/i18n';

import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';

export const getStatusTranslation = (status: string) => {
  switch (status) {
    case LoadAuditDataResponseItemBody.state.IN_PROGRESS:
      return i18n.t('general.labels.inProgress');
    case LoadAuditDataResponseItemBody.state.FINISHED_AUDIT:
      return i18n.t('general.labels.complete');
    case LoadAuditDataResponseItemBody.state.TO_FINISHING:
      return i18n.t('general.labels.toFinishing');
    case LoadAuditDataResponseItemBody.state.TO_REVISION:
      return i18n.t('general.labels.toRevision');
    case LoadAuditDataResponseItemBody.state.IN_REVISION:
      return i18n.t('general.labels.inRevision');
    default:
      return i18n.t('general.labels.toCheck');
  }
};
