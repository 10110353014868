import {Box} from 'platform/foundation';

import {ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

interface DialogFooterProps extends TestIdProps {
  children: ReactNode;
}

export function DialogFooter(props: DialogFooterProps) {
  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      padding={4}
      borderTop="1px solid"
      borderColor="general.separator"
      backgroundColor="general.white"
      data-testid={suffixTestId('dialogFooter', props)}
    >
      {props.children}
    </Box>
  );
}
