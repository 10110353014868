import {
  GetServiceOrderItemsDiscountApiArg,
  GetServiceOrderItemsDiscountApiResponse,
  PutServiceOrderItemsDiscountApiArg,
  PutServiceOrderItemsDiscountApiResponse,
  PostServiceOrderCustomDiscountsDeleteApiArg,
  PostServiceOrderCustomDiscountsDeleteApiResponse,
  GetServiceOrderItemsDiscountApiResponseSchema,
  GetServiceOrderItemsDiscountApiArgSchema,
  PutServiceOrderItemsDiscountApiResponseSchema,
  PutServiceOrderItemsDiscountApiArgSchema,
  PostServiceOrderCustomDiscountsDeleteApiResponseSchema,
  PostServiceOrderCustomDiscountsDeleteApiArgSchema,
} from '../types/metadaWorkshopServiceOrderDiscount';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceOrderDiscountApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceOrderItemsDiscount: build.query<
      GetServiceOrderItemsDiscountApiResponse,
      GetServiceOrderItemsDiscountApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/discount`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'orderDiscount', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: GetServiceOrderItemsDiscountApiResponseSchema,
        requestSchema: GetServiceOrderItemsDiscountApiArgSchema,
      },
    }),
    putServiceOrderItemsDiscount: build.mutation<
      PutServiceOrderItemsDiscountApiResponse,
      PutServiceOrderItemsDiscountApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/discount`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'orderDiscount', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PutServiceOrderItemsDiscountApiResponseSchema,
        requestSchema: PutServiceOrderItemsDiscountApiArgSchema,
      },
    }),
    postServiceOrderCustomDiscountsDelete: build.mutation<
      PostServiceOrderCustomDiscountsDeleteApiResponse,
      PostServiceOrderCustomDiscountsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/discount/delete`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'orderDiscount', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderCustomDiscountsDeleteApiResponseSchema,
        requestSchema: PostServiceOrderCustomDiscountsDeleteApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetServiceOrderItemsDiscountQuery,
  usePutServiceOrderItemsDiscountMutation,
  usePostServiceOrderCustomDiscountsDeleteMutation,
} = metadaWorkshopServiceOrderDiscountApi;
