import {Actions, ButtonGroup, IconButtonActionProps, Overlay} from 'platform/components';
import {Box, Center, Clickable, Icon, Integer, Show, ThemeIconKey} from 'platform/foundation';

import {Nullish, RequiredTestIdProps, suffixTestId, TestIdProps, useBoolean} from 'shared';

export type PreviewAction = Pick<IconButtonActionProps, 'icon' | 'isDisabled' | 'onClick'> &
  TestIdProps;

type PreviewProps = {
  actions?: PreviewAction[] | Nullish;
  icon?: ThemeIconKey;
  iconSize: Integer;
  width: Integer;
  height: Integer;
} & RequiredTestIdProps;

export function IconWithActions(props: PreviewProps) {
  const [isMouseEnter, setIsMouseEnter, setIsMouseLeave] = useBoolean(false);

  return (
    <Clickable
      onMouseEnter={setIsMouseEnter}
      onMouseLeave={setIsMouseLeave}
      width={props.width}
      height={props.height}
    >
      <Box
        data-testid={suffixTestId('preview', props)}
        width="100%"
        height="100%"
        position="relative"
      >
        <Center width="100%" height="100%" justify="center">
          <Icon
            value={props.icon}
            size={props.iconSize}
            color="palettes.neutral.800.100"
            data-testid={suffixTestId('icon', props)}
          />
        </Center>
        <Show when={isMouseEnter}>
          <Overlay variant="neutral" density="high">
            <Center width="100%" height="100%" justify="center">
              <ButtonGroup data-testid={suffixTestId('preview', props)}>
                <Actions
                  data-testid={suffixTestId('preview', props)}
                  actions={props.actions?.map((action) => ({
                    'data-testid':
                      action['data-testid'] ?? suffixTestId(`preview-action-${action.icon}`, props),
                    type: 'iconButton',
                    size: 'default',
                    priority: 'secondary',
                    icon: action.icon,
                    isDisabled: action.isDisabled,
                    onClick: action.onClick,
                  }))}
                />
              </ButtonGroup>
            </Center>
          </Overlay>
        </Show>
      </Box>
    </Clickable>
  );
}
