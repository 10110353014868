import {ComponentStyleConfig} from '@chakra-ui/react';

export const segmentTheme: ComponentStyleConfig = {
  baseStyle: (props) => ({
    display: 'flex',
    paddingTop: 1,
    paddingBottom: 1,
    backgroundColor: 'gray.100',
    borderRadius: 'small',
    p: '4px',
    bg: 'palettes.neutral.20.100',
    w: props.isFullWidth ? '100%' : 'min-content',

    segmentItem: {
      cursor: 'pointer',
      borderRadius: 'sm',
      marginLeft: 1,
      marginRight: 1,
      flexGrow: 1,
      alignContent: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      padding: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    selectedItem: {
      backgroundColor: 'blue.100',
      color: 'blue.500',
    },

    disabledItem: {
      cursor: 'not-allowed',
      opacity: '60%',
    },
  }),
  variants: {},
  sizes: {
    default: {},
  },
  defaultProps: {
    items: [],
    size: 'default',
    variant: 'default',
  },
};
