import {Menu, MenuProps} from 'platform/components';

import {NumberedMenuItem} from './NumberedMenuItem';
import {prependItemsWithCounter} from './utils/prependItemsWithCounter';

type NumberedMenuProps = MenuProps & {
  items: NumberedMenuItem[];
};

export function NumberedMenu(props: NumberedMenuProps) {
  const items = prependItemsWithCounter(props.items);

  return (
    <Menu
      items={items}
      activeItemId={props.activeItemId}
      onItemChange={props.onItemChange}
      data-testid={props['data-testid']}
    />
  );
}
