import {Slider, Separator, Chips, NumberInput} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {equals, isNil} from 'ramda';
import {isObjectLike, isArray, isString} from 'ramda-adjunct';

import {debounce, suffixTestId, TestIdProps} from 'shared';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {useSliderTicks} from '../../hooks/useSliderTicks';
import {IFilter, IFilterParams} from '../../types/AgGridTypes';
import {QuickFilters} from '../../types/Api';

export type NumberSliderRangeProps = {
  min: number;
  max: number;
  minOpen: boolean;
  maxOpen: boolean;
  unit: string | null;
  stepSize: number | null;
  steps: number[];
  markerSize: number | null;
  markers: number[];
  decimalsAllowed: boolean;
  inputsVisible: boolean;
  isDisabled: boolean;
} & IFilterParams &
  QuickFilters &
  TestIdProps;

function NumberSliderRangeFilterComponent(
  {
    filterChangedCallback,
    min,
    max,
    minOpen,
    maxOpen,
    unit,
    stepSize,
    steps,
    markerSize,
    markers,
    decimalsAllowed,
    inputsVisible,
    isDisabled,
    quickFilters,
    ...props
  }: NumberSliderRangeProps,
  ref: ForwardedRef<IFilter>
) {
  const ticks = useSliderTicks(min, max, markerSize, markers);
  const valueFormatter = useCallback(
    (value: number) => (unit ? `${value} [${unit}]` : `${value}`),
    [unit]
  );

  const [filterValue, _setFilterValue] = useState<
    [number | null, number | null] | string | undefined
  >();

  const isActive = useRef<boolean>(false);
  const setFilterValue = (value: typeof filterValue) => {
    isActive.current = Array.isArray(value) || isString(value);
    _setFilterValue(value);
  };
  const {onChipsChange} = useFilterOnChipsChange({
    filterChangedCallback,
    setFilterValue,
    defaultValue: undefined,
  });

  const debouncedFilterChangedCallback = useMemo(
    () => debounce(filterChangedCallback, 700),
    [filterChangedCallback]
  );

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return isActive.current;
    },

    doesFilterPass() {
      return true;
    },

    getModel() {
      if (!filterValue) {
        return undefined;
      }

      if (isArray(filterValue)) {
        return {from: filterValue[0] ?? min, to: filterValue[1] ?? max};
      }

      return filterValue;
    },

    setModel(
      model: {from: number; to: number} | [number | null, number | null] | string | undefined
    ) {
      if (equals(model, filterValue)) {
        return;
      }

      if (isNil(model)) {
        setFilterValue(undefined);
      }

      if (model && isObjectLike(model) && 'from' in model && 'to' in model) {
        setFilterValue([model.from, model.to]);
      }

      if (isString(model)) {
        setFilterValue(model);
      }

      filterChangedCallback();
    },
  }));

  const onChange = (value: [number, number]) => {
    setFilterValue(value);
    debouncedFilterChangedCallback();
  };

  const onFromInputChange = (value: number | null) => {
    const valueToSet = value === null || isNaN(value) ? null : value;

    if (isNil(filterValue)) {
      setFilterValue([valueToSet, null]);
    }

    if (isArray(filterValue)) {
      setFilterValue([valueToSet, filterValue?.[1] === 0 ? null : filterValue?.[1]]);
    }

    if (isString(filterValue)) {
      setFilterValue([valueToSet, max]);
    }

    debouncedFilterChangedCallback();
  };

  const onToInputChange = (value: number | null) => {
    const valueToSet = value === null || isNaN(value) ? null : value;

    if (isNil(filterValue)) {
      setFilterValue([null, valueToSet]);
    }

    if (isArray(filterValue)) {
      setFilterValue([filterValue?.[0] === 0 ? null : filterValue?.[0], valueToSet]);
    }

    if (isString(filterValue)) {
      setFilterValue([min, valueToSet]);
    }

    debouncedFilterChangedCallback();
  };

  const isQuickFilterValue = isString(filterValue) ? [filterValue] : undefined;

  const isSliderValue = isArray(filterValue)
    ? [filterValue?.[0] ?? min, filterValue?.[1] ?? max]
    : [min, max];

  const isFromValue = isArray(filterValue) ? filterValue?.[0] : null;
  const isToValue = isArray(filterValue) ? filterValue?.[1] : null;

  return (
    <VStack spacing={5}>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('number-range-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <Slider
        isDisabled={isDisabled}
        isRange
        min={min}
        max={max}
        step={stepSize ?? steps ?? 1}
        value={isSliderValue as [number, number]}
        allowMoveStart
        onChange={onChange}
        formatValue={valueFormatter}
        ticks={ticks}
        onlyEdges={true}
        displayValue="auto"
        data-testid={suffixTestId('number-range-filter-slider', props)}
      />
      <HStack spacing={2}>
        <NumberInput
          value={isFromValue}
          onChange={onFromInputChange}
          isStepperVisible
          minStepperValue={min}
          maxStepperValue={max}
          isDisabled={isDisabled}
          data-testid={suffixTestId('number-range-filter-from', props)}
        />
        <NumberInput
          value={isToValue}
          onChange={onToInputChange}
          isStepperVisible
          minStepperValue={min}
          maxStepperValue={max}
          isDisabled={isDisabled}
          data-testid={suffixTestId('number-range-filter-to', props)}
        />
      </HStack>
    </VStack>
  );
}

export const NumberSliderRangeFilter = forwardRef<IFilter, NumberSliderRangeProps>(
  NumberSliderRangeFilterComponent
);
