import {ComponentStyleConfig} from '@chakra-ui/theme';

export const dropdownTheme: ComponentStyleConfig = {
  parts: ['item', 'command', 'list', 'button', 'groupTitle', 'divider'],
  baseStyle: {
    button: {
      '> span': {
        display: 'flex',
        pointerEvents: 'auto',
      },
    },
    groupTitle: {
      fontSize: 'text.xSmall',
      fontWeight: 'regular',
      lineHeight: 'text.xSmall',
      letterSpacing: 0,
      m: 0,
      px: '8px',
      py: '4px',
      color: 'text.secondary',
    },
    list: {
      p: '8px',
      boxShadow: 'elevation_3',
      borderRadius: 'medium',
      zIndex: 'TOAST_NOTIFICATION',
    },
    item: {
      p: '8px',
      fontSize: 'text.small',
      fontWeight: 'regular',
      lineHeight: 'text.small',
      borderRadius: 'small',
      letterSpacing: 0,
      bg: 'palettes.white.10.100',
      color: 'text.primary',
      _disabled: {
        bg: 'palettes.white.10.100',
        color: 'palettes.neutral.900.40',
        _hover: {
          bg: 'palettes.white.10.100',
          color: 'palettes.neutral.900.40',
        },
        _focus: {
          bg: 'palettes.white.10.100',
          color: 'palettes.neutral.900.40',
        },
        _active: {
          bg: 'palettes.white.10.100',
          color: 'palettes.neutral.900.40',
        },
      },
      _hover: {
        bg: 'palettes.blue.10.100',
        color: 'palettes.blue.80.100',
      },
      _focus: {
        bg: 'palettes.blue.10.100',
        color: 'palettes.blue.80.100',
      },
      _active: {
        bg: 'palettes.blue.10.100',
        color: 'palettes.blue.80.100',
      },
      '.chakra-menu__icon-wrapper': {
        me: '12px',
        '> img, > svg': {
          maxW: '100%',
          maxH: '100%',
        },
      },
      '.chakra-menu__command': {
        opacity: 1,
      },
      svg: {
        w: '16px',
        h: '16px',
        '&[data-iconkey^="flags/"]': {
          w: '28px',
          h: '20px',
        },
      },
      '&.eag--choice__option': {
        cursor: 'pointer',
        _disabled: {
          cursor: 'not-allowed',
        },
        '&.option-create-new': {
          color: 'general.accent',
        },
      },
    },
    divider: {
      my: '8px',
      borderColor: 'general.separator',
    },
  },
  variants: {
    default: {},
    dark: {
      list: {
        bg: 'palettes.neutral.900.100',
      },
      groupTitle: {
        color: 'palettes.neutral.30.100',
      },
      divider: {
        borderColor: 'palettes.neutral.500.100',
      },
      command: {
        opacity: 1,
        color: 'palettes.neutral.60.100',
      },
      item: {
        bg: 'transparent',
        color: 'palettes.white.10.100',

        _hover: {
          color: 'palettes.white.10.100',
          bg: 'palettes.neutral.700.100',

          _active: {
            color: 'palettes.white.10.100',
            bg: 'palettes.neutral.600.100',
          },
        },

        _active: {
          color: 'palettes.white.10.100',
          bg: 'palettes.neutral.600.100',
        },

        _focus: {
          bg: 'palettes.neutral.700.100',
          color: 'palettes.white.10.100',
        },

        _disabled: {
          color: 'palettes.white.10.40',
          bg: 'palettes.neutral.900.40',
          opacity: 1,

          '.chakra-menu__command': {
            color: 'palettes.neutral.60.40',
          },

          _hover: {
            color: 'palettes.white.10.40',
            bg: 'palettes.neutral.900.40',

            _active: {
              color: 'palettes.white.10.40',
              bg: 'palettes.neutral.900.40',
            },
          },

          _active: {
            color: 'palettes.white.10.40',
            bg: 'palettes.neutral.900.40',
          },

          _focus: {
            color: 'palettes.white.10.40',
            bg: 'palettes.neutral.900.40',
          },
        },
      },
    },
  },
  sizes: {
    default: {
      list: {
        minW: '216px',
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'default',
  },
};
