import type {CancelablePromise} from '../types/CancelablePromise';
import type {RenderedTemplate} from '../types/RenderedTemplate';
import type {TemplateListItem} from '../types/TemplateListItem';
import type {WindshieldPaperRequestBody} from '../types/WindshieldPaperRequestBody';
import {request as __request} from '../utils/request';

export class SalesDocumentService {
  /**
   * @returns TemplateListItem
   * @throws ApiError
   */
  public static getWindshieldPaperTemplates({
    authorization,
  }: {
    authorization?: string;
  }): CancelablePromise<Array<TemplateListItem>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sales/document/windshield-paper/templates',
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns RenderedTemplate
   * @throws ApiError
   */
  public static renderWindshieldPaperDocument({
    authorization,
    requestBody,
  }: {
    authorization?: string;
    requestBody?: WindshieldPaperRequestBody;
  }): CancelablePromise<RenderedTemplate> {
    return __request({
      method: 'PUT',
      url: '/dms/v1/sales/document/windshield-paper',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
