import {Box, Show, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {ChangeEvent, useId} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {getDataAttribute} from '../../utils/getDataAttribute';
import {HelperText} from '../HelperText/HelperText';

export interface SwitchProps extends FormControlProps<boolean>, TestIdProps {}

export function Switch(props: SwitchProps) {
  const id = useId();

  const isInvalid = props.isInvalid || !!props.errorMessage;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.isDisabled) {
      event.preventDefault();
      return;
    }

    props.onChange?.(!props.value);
  };

  const rootProps = {
    'data-disabled': getDataAttribute(props.isDisabled),
    'data-checked': getDataAttribute(props.value),
    'data-invalid': getDataAttribute(isInvalid),
  };

  const checkboxProps = {
    'data-checked': getDataAttribute(props.value),
    'data-disabled': getDataAttribute(props.isDisabled),
    'data-invalid': getDataAttribute(isInvalid),
  };

  return (
    <VStack>
      <SwitchWrapper {...rootProps} data-testid={suffixTestId('switchWrapper', props)}>
        <SwitchInput
          id={id}
          type="checkbox"
          checked={props.value}
          onChange={handleChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          disabled={props.isDisabled}
          required={props.isRequired}
          data-testid={suffixTestId('switchInput', props)}
        />
        <SwitchTrack {...checkboxProps} data-testid={suffixTestId('switchTrack', props)}>
          <SwitchThumb
            data-checked={getDataAttribute(props.value)}
            data-testid={suffixTestId('switchThumb', props)}
          />
        </SwitchTrack>
        {props.label && (
          <Box paddingLeft={3}>
            <Text size="small" data-testid={suffixTestId('label', props)}>
              {props.label}
            </Text>
          </Box>
        )}
      </SwitchWrapper>

      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}

const SwitchWrapper = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;

  &[data-disabled='true'] {
    cursor: not-allowed;
  }
`;

const SwitchInput = styled.input`
  display: none;
`;

// Disabled warning for px units because we can't get 2px from theme.getSize. Minimum padding we can get is 4px.
// eslint-disable-next-line eag/no-css-property
const SwitchTrack = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  justify-content: flex-start;
  box-sizing: content-box;
  cursor: pointer;
  border-radius: ${({theme}) => theme.radii.large};
  padding: 2px;
  width: ${({theme}) => theme.getSize(8)};
  height: ${({theme}) => theme.getSize(4)};
  transition: 150ms;
  background-color: ${({theme}) => theme.components.Switch.trackStyle.backgroundColor};

  &:hover {
    box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[20][100]};
  }

  &:active {
    box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[30][100]};
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
  }

  &[data-checked='true'] {
    background-color: ${({theme}) => theme.colors.general.accent};
  }

  &[data-disabled='true'] {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

const SwitchThumb = styled.span`
  background: ${({theme}) => theme.colors.general.white};
  transition: transform 200ms;
  width: ${({theme}) => theme.getSize(4)};
  height: ${({theme}) => theme.getSize(4)};
  border-radius: ${({theme}) => theme.radii.large};
  box-shadow: ${({theme}) => theme.shadows.elevation_1};

  &[data-checked='true'] {
    transform: translateX(${({theme}) => theme.getSize(4)});
  }
`;
