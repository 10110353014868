import {
  Action,
  Attributes,
  Card,
  DataStatus,
  openDeleteDialog,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, HStack, Show, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';

import {useDispatch} from 'react-redux';

import {isNil, isNotNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  interestApi,
  useDeleteMinisaleMutation,
  useGetParticipationQuery,
  useInterestCatalogueQuery,
  useReadCodeQuery,
  useUpdateMinisaleMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  CommentsWidget,
  EntityCustomFields,
  getNaturalPersonFullName,
  handleApiError,
  NoteWidget,
  Section,
  usePermissions,
  usePricePermissions,
  VehicleWidgetCard,
} from '@omnetic-dms/shared';

import {buildArray, hasNonNullishValuesInObjectExcept, Nullish, parseDate} from 'shared';

import {OfferedVehicles} from '../../components/OfferedVehicles';
import {RequestSpecification} from '../../components/RequestSpecification';
import {useInterestData} from '../../hooks/useInterestData';

interface OverviewProps {
  interestId: string;
}

export function Overview(props: OverviewProps) {
  const dispatch = useDispatch();
  const formatDateTime = useDateTimeFormatter();
  const formatPrice = useFormatCurrency();

  const {data: interestParticipation} = useGetParticipationQuery({
    recordId: props.interestId,
    resourceId: EntityResourceIds.interest,
  });

  const [canEditCustomFields] = usePermissions({
    permissionKeys: ['interestCustomFields'],
    scopes: {
      interestCustomFields: interestParticipation,
    },
  });

  const {interest, miniSale, offeredVehiclesList, miniPurchase, isLoading, isError} =
    useInterestData(props.interestId);

  const {data: interestCatalogue} = useInterestCatalogueQuery();

  const {
    data: closeReason,
    isLoading: isReadCodeLoading,
    isError: isReactCodeErrored,
  } = useReadCodeQuery(
    {codelistId: 'unsuccessful_interest_reason', codeId: interest?.reasonCloseCodeId ?? ''},
    {skip: isNil(interest?.reasonCloseCodeId)}
  );

  const {canViewAnyOfVehiclePriceFields: canReadPrices} = usePricePermissions({
    vehicleRecordId: null,
    businessCaseRecordId: null,
  });

  const [deleteMiniSale, {isLoading: isDeleteMinisaleLoading}] = useDeleteMinisaleMutation();
  const [updateMiniSale] = useUpdateMinisaleMutation();

  const onUpdateInterestRate = (values: string[] | Nullish) => {
    if (!miniSale) {
      showNotification.error('missing miniSale object');
      return;
    }

    updateMiniSale({
      updateMinisaleRequestBody: {
        ...miniSale,
        customerBuyingStatement: miniSale.customerBuyingStatement ?? false,
        isVatDeductible: miniSale.isVatDeductible ?? false,
        vehicleData: {
          ...miniSale.vehicleData,
          type: miniSale.vehicleData?.type,
        },
        minisaleId: miniSale.id,
        interestId: props.interestId,
        interestRate: isNotNilOrEmpty(values) ? parseInt(String(values![0]), 10) : null,
      },
    })
      .unwrap()
      .then(() =>
        dispatch(
          interestApi.util.invalidateTags([
            {type: 'Interest', id: props.interestId},
            {type: 'miniSale'},
          ])
        )
      )
      .catch(handleApiError);
  };
  return (
    <Section>
      <DataStatus isLoading={isLoading} isError={isError} minHeight={100}>
        {interest && (
          <Grid columns={2}>
            <Show when={interest.state === 'UNSUCCESSFUL'}>
              <GridItem span={1}>
                <HStack spacing={4}>
                  <DataStatus
                    isLoading={isReadCodeLoading}
                    isError={isReactCodeErrored}
                    minHeight={25}
                  >
                    <Attributes
                      data-testid={testIds.interest.detail('overview-reason')}
                      size="quarter"
                      rows={[
                        {label: i18n.t('general.labels.reason'), value: closeReason?.name},
                        {
                          label: i18n.t('general.label.closedBy'),
                          value: getNaturalPersonFullName(interest.closedBy),
                        },
                      ]}
                    />
                  </DataStatus>
                  <Attributes
                    data-testid={testIds.interest.detail('overview-reasonCloseNote')}
                    size="quarter"
                    rows={[
                      {label: i18n.t('general.labels.note'), value: interest.reasonCloseNote},
                      {
                        label: i18n.t('general.label.closedOn'),
                        value:
                          interest.closedAt &&
                          formatDateTime('dateTimeLong', parseDate(interest.closedAt)),
                      },
                    ]}
                  />
                </HStack>
              </GridItem>
            </Show>
            <Show when={isNotNilOrEmpty(offeredVehiclesList)}>
              <GridItem span={2}>
                <OfferedVehicles
                  data-testid={testIds.interest.detail('overview-selling')}
                  interestId={props.interestId}
                />
              </GridItem>
            </Show>
            <Show
              when={hasNonNullishValuesInObjectExcept(miniPurchase?.filteredSpecifications, 'type')}
            >
              {miniPurchase?.filteredSpecifications && (
                <GridItem span={2}>
                  <Card title={i18n.t('entity.interest.labels.requestSpecification')}>
                    <RequestSpecification
                      data-testid={testIds.interest.detail('overview-requestSpecification')}
                      filteredSpecifications={miniPurchase.filteredSpecifications}
                    />
                  </Card>
                </GridItem>
              )}
            </Show>
            <Show when={isNotNil(miniSale)}>
              {miniSale && (
                <GridItem span={2}>
                  <Card title={i18n.t('entity.interest.labels.buyingTab')}>
                    <VehicleWidgetCard
                      data-testid={testIds.interest.detail('overview-buying')}
                      vehicles={[
                        {
                          id: miniSale.id,
                          vin: miniSale.vehicleData?.vin,
                          licencePlate: miniSale.vehicleData?.licencePlate,
                          firstRegistration: miniSale.vehicleData?.firstRegistration,
                          make: miniSale.vehicleData?.make,
                          vehicleType: miniSale.vehicleData?.type?.toString(),
                          modelFamily: miniSale.vehicleData?.modelFamily,
                          trim: miniSale.vehicleData?.trim,
                          variant: miniSale.vehicleData?.variant,
                          mileage: miniSale.vehicleData?.mileage,
                          transmission: miniSale.vehicleData?.transmission,
                          fuelType: miniSale.vehicleData?.fuelType,
                          bodyStyle: miniSale.vehicleData?.bodyStyle,
                          driveType: miniSale.vehicleData?.drive,
                          firstParameter:
                            // TODO: this ACL condition is not tied to recordId, should we abstract it?
                            canReadPrices &&
                            miniSale.priceOfferedWithoutVat &&
                            miniSale.isVatDeductible
                              ? formatPrice(
                                  Number(miniSale.priceOfferedWithoutVat.amount),
                                  miniSale.priceOfferedWithoutVat.currency,
                                  0
                                )
                              : null,
                          secondParameter:
                            canReadPrices && miniSale.priceOfferedWithVat
                              ? formatPrice(
                                  Number(miniSale.priceOfferedWithVat.amount),
                                  miniSale.priceOfferedWithVat.currency,
                                  0
                                )
                              : null,
                          actions: buildArray<Action>()
                            .when(
                              isNotNilOrEmpty(interestCatalogue?.interestRate) &&
                                isNotNilOrEmpty(miniSale),
                              {
                                'data-testid': testIds.interest.detail(
                                  `overview-buying-updateInterestRate`
                                ),
                                type: 'chips',
                                value: [String(miniSale?.interestRate ?? '')],
                                isDisabled: isNilOrEmpty(miniSale),
                                options:
                                  interestCatalogue?.interestRate?.map((catalogueItem) => ({
                                    label: catalogueItem.name,
                                    value: catalogueItem.key,
                                  })) ?? [],
                                isDeselectable: true,
                                onChange: onUpdateInterestRate,
                              }
                            )
                            .add({
                              'data-testid': testIds.interest.detail('buying-delete'),
                              type: 'button',
                              leftIcon: 'action/delete',
                              variant: 'outlined',
                              title: i18n.t('general.labels.delete'),
                              isDisabled: isDeleteMinisaleLoading || !miniSale,
                              onClick: () =>
                                openDeleteDialog({
                                  'data-testid': testIds.interest.detail('buying-delete'),
                                  onConfirm: () =>
                                    deleteMiniSale({
                                      minisaleId: miniSale!.id!,
                                      interestId: props.interestId,
                                    })
                                      .unwrap()
                                      .then(() =>
                                        showNotification.info(
                                          i18n.t('entity.interest.actions.removeBuyingVehicle')
                                        )
                                      )
                                      .catch(handleApiError),
                                }),
                            }),
                        },
                      ]}
                    />
                  </Card>
                </GridItem>
              )}
            </Show>
            <GridItem span={1}>
              <VStack spacing={4}>
                <EntityCustomFields
                  recordId={props.interestId}
                  resourceId={EntityResourceIds.interest}
                  isEditable={canEditCustomFields}
                  data-testid={testIds.interest.detail('interest-custom-fields')}
                />
                <CommentsWidget
                  title={i18n.t('entity.interest.labels.interestComments')}
                  resourceId={EntityResourceIds.interest}
                  recordId={props.interestId}
                />
              </VStack>
            </GridItem>
            <GridItem span={1}>
              <NoteWidget
                isEditable
                resourceId={EntityResourceIds.interest}
                recordId={props.interestId}
                context={EntityResourceIds.interest}
              />
            </GridItem>
          </Grid>
        )}
      </DataStatus>
    </Section>
  );
}
