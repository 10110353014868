import {showNotification} from 'platform/components';

import {defaultTo, find, propEq} from 'ramda';

import {
  useChangeBranchSaleVehicleMutation,
  useGetCurrentUserInfoQuery,
  useGetVehicleQuery,
} from '@omnetic-dms/api';

import {TestIdProps} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {BranchChanger} from './BranchChanger';

export interface LocationProps extends TestIdProps {
  recordId: string;
}

export function Location(props: LocationProps) {
  const {data: vehicle} = useGetVehicleQuery({vehicleId: props.recordId});
  const {data: currentUserInfo} = useGetCurrentUserInfoQuery();
  const [changeBranchSaleVehicle] = useChangeBranchSaleVehicleMutation();

  const handleUpdateVehicleBranch = (branchId: string) =>
    changeBranchSaleVehicle({
      vehicleId: props.recordId,
      changeBranchSaleVehicleRequestBody: {branchId},
    })
      .unwrap()
      .then(() => {
        showNotification.success();
      })
      .catch(handleApiError);

  const activeBranch = find(
    propEq(vehicle?.branchId, 'id'),
    defaultTo([], currentUserInfo?.branches)
  );

  return (
    <BranchChanger
      recordId={props.recordId}
      activeBranch={activeBranch}
      onChange={handleUpdateVehicleBranch}
      data-testid={props['data-testid']}
    />
  );
}
