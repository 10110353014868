import {openDeleteDialog} from 'platform/components';

import {FC, useState, ReactNode, CSSProperties} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {deleteAuditAsset} from '../../../store/carAudit/actions';
import {deleteAssets} from '../../../store/carAudit/reducer';
import {selectUserName} from '../../../store/user/selectors';
import {AuditDataAssetsFilesBody} from '../../../types/AuditDataAssetsFilesBody';
import {useVehicleCreateContext} from '../../VehicleCreateContext/hooks/useVehicleCreateContext';
import {ConditionUploadContext} from '../ConditionUploadContext';
import {useConditionContext} from '../hooks/useConditionContext';
import {TConditionUploadContext} from '../types/TConditionUploadContext';
import {TUploadImage} from '../types/TUploadImage';

type UploadContextProps = {
  children: ReactNode;
  categoryId: string;
  paramDefinitionId: string;
  type: string;
  damageId?: string;
  onUpload?: (asset: AuditDataAssetsFilesBody) => void;
  onDelete?: (imageId: string) => void;
  ratio?: CSSProperties['aspectRatio'];
  softDelete?: boolean;
};

export const UploadContext: FC<UploadContextProps> = ({
  children,
  categoryId,
  paramDefinitionId,
  type,
  damageId,
  onUpload,
  onDelete,
  ratio,
  softDelete,
}) => {
  const dispatch = useThunkDispatch();
  const [uploadImages, setUploadImages] = useState<TUploadImage[]>([]);
  const [softDeletedImages, setSoftDeletedImages] = useState<string[]>([]);
  const [uploadedImagesIds, setUploadedImagesIds] = useState<string[]>([]);
  const userName = useSelector(selectUserName);
  const {handleChangeCategory} = useConditionContext();
  const {deleteConditionAsset} = useVehicleCreateContext();

  const updateUploadedImage: TConditionUploadContext['updateUploadedImage'] = (imageId, props) => {
    const image = uploadImages?.find(({imageId: id}) => id === imageId);
    if (image) {
      setUploadImages((prevState) =>
        prevState.map((img) => (img.imageId === imageId ? {...image, ...props} : img))
      );
    }
  };

  const pushToUploadImages = (image: TUploadImage) => {
    if (uploadImages?.some((uploadImage) => uploadImage.src === image.src)) {
      return;
    }
    setUploadImages((prevValue) => [...prevValue, image]);
  };

  const removeFromUploadedImages = (imageId: string) => {
    setUploadImages(uploadImages?.filter((image) => image.imageId !== imageId));
  };

  const handleDeleteImage = (imageId: string, isUploaded?: boolean) =>
    openDeleteDialog({
      onConfirm: async () => {
        onDelete?.(imageId);

        if (isUploaded) {
          removeFromUploadedImages(imageId);
        }

        if (softDelete) {
          setSoftDeletedImages([...softDeletedImages, imageId]);
          return;
        }

        dispatch(
          deleteAssets({
            categoryId,
            paramDefinitionId,
            imageIds: [imageId],
          })
        );
        await dispatch(
          deleteAuditAsset.action({
            imageId,
            userName,
            withoutReducer: Boolean(damageId),
          })
        );
        deleteConditionAsset?.(imageId);

        if (!damageId) {
          await handleChangeCategory();
        }
      },
      text: i18n.t('general.actions.deletePhotoConfirmation'),
    });

  const setUploadedImage = (imageId: string) => {
    setUploadedImagesIds([...uploadedImagesIds, imageId]);
  };

  return (
    <ConditionUploadContext.Provider
      value={{
        uploadImages,
        pushToUploadImages,
        handleDeleteImage,
        categoryId,
        paramDefinitionId,
        type,
        damageId,
        onUpload,
        softDeletedImages,
        uploadedImagesIds,
        setUploadedImage,
        ratio,
        updateUploadedImage,
      }}
    >
      {children}
    </ConditionUploadContext.Provider>
  );
};
