import {Show, Space, Text, VStack} from 'platform/foundation';
import {useLocale} from 'platform/locale';

import {useId, useState} from 'react';

import {isNil, replace} from 'ramda';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {DECIMAL_DOT} from '../../constants/numberConstants';
import {FormControlProps} from '../../types/FormControlProps';
import {getDecrementedNumber} from '../../utils/getDecrementedNumber';
import {getIncrementedNumber} from '../../utils/getIncrementedNumber';
import {HelperText} from '../HelperText/HelperText';
import {IconButton} from '../IconButton/IconButton';
import {Label} from '../Label/Label';
import {PlatformInputWrapper} from '../TextInput/components/PlatformInputWrapper';
import {PlatformNumberInputElement} from './components/PlatformNumberInputElement';
import {getDecimalPlaces} from './utils/getDecimalPlaces';

export type NumberInputProps = {
  minStepperValue?: number;
  maxStepperValue?: number;
  /**
   * @about
   * The number of allowed decimal places
   */
  decimalPlaces?: number;
  /**
   * @about
   * The step used to increment or decrement the value
   * @default 1
   */
  step?: number;
  /**
   * @about
   * Will increment or decrement to the next valid step based on the difference between
   * the current value and the step value.
   *
   * If value is 2.1, the next valid increment step based on the step value is 4, so the
   * increment action will calculate the difference between 2.1 and 4, and will increment
   * only by the difference to match the next valid step.
   *
   * If value is 2.1, the next valid decrement step based on the step value is 2, so the
   * decrement action will calculate the difference between 2.1 and 2, and will decrement
   * only by the difference to match the next valid step.
   *
   * @example
   * // Returns 4
   * const result = getIncrementedNumber(2.1, props.minStepperValue, props.maxStepperValue, 2, true);
   *
   * @example
   * // Returns 2
   * const result = getDecrementedNumber(2.1, props.minStepperValue, 2, true);
   * */
  shouldRoundStepsByDifference?: boolean;
  isStepperVisible?: boolean;
  isRecommended?: boolean;
} & (
  | {
      suffix?: string | Nullish;
      isStepperVisible?: never;
    }
  | {
      isStepperVisible?: boolean;
      suffix?: never;
    }
) &
  FormControlProps<number | null, HTMLInputElement, [string | null]> &
  TestIdProps;

export function NumberInput(props: NumberInputProps) {
  const {localeConfig} = useLocale();

  const id = useId();

  const numberLocale = localeConfig.number;

  const [inputValue, setInputValue] = useState<string | Nullish>();

  const handleIncrement = () => {
    const decimalPlaces = getDecimalPlaces(numberLocale.decimalSeparator, inputValue, props.step);

    const newValue = getIncrementedNumber(
      props.value,
      props.minStepperValue,
      props.maxStepperValue,
      props.step,
      props.shouldRoundStepsByDifference
    ).toFixed(decimalPlaces);

    setInputValue(replace(DECIMAL_DOT, numberLocale.decimalSeparator, newValue));
    props.onChange?.(parseFloat(newValue), undefined, String(newValue));
  };

  const handleDecrement = () => {
    const decimalPlaces = getDecimalPlaces(numberLocale.decimalSeparator, inputValue, props.step);

    const newValue = getDecrementedNumber(
      props.value,
      props.minStepperValue,
      props.step,
      props.shouldRoundStepsByDifference
    ).toFixed(decimalPlaces);

    setInputValue(replace(DECIMAL_DOT, numberLocale.decimalSeparator, newValue));
    props.onChange?.(parseFloat(newValue), undefined, String(newValue));
  };

  const isInvalid = props.isInvalid || !!props.errorMessage;

  return (
    <VStack>
      <Label
        id={id}
        isRequired={props.isRequired}
        isRecommended={props.isRecommended}
        tooltip={props.tooltip}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <PlatformInputWrapper
        isDisabled={props.isDisabled}
        isInvalid={isInvalid}
        isRecommended={props.isRecommended}
      >
        <PlatformNumberInputElement
          {...props}
          id={id}
          isInvalid={isInvalid}
          inputValue={inputValue}
          setInputValue={setInputValue}
          step={props.step}
          data-testid={suffixTestId('numberInput', props)}
        />
        <Show when={props.suffix && !props.isStepperVisible}>
          <Space horizontal={2} />
          <Text size="small" color="secondary">
            {props.suffix}
          </Text>
        </Show>
        <Show when={props.isStepperVisible && isNil(props.suffix)}>
          <Space horizontal={2} />
          <IconButton
            onClick={handleDecrement}
            size="small"
            icon="content/remove"
            isDisabled={props.isDisabled}
            data-testid={suffixTestId('numberButtonMinus', props)}
          />
          <Space horizontal={1} />
          <IconButton
            onClick={handleIncrement}
            size="small"
            icon="content/add"
            isDisabled={props.isDisabled}
            data-testid={suffixTestId('numberButtonPlus', props)}
          />
        </Show>
      </PlatformInputWrapper>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
