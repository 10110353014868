import {z} from 'zod';

const GoodwillOrderSchema = z.object({
  goodwillOrderId: z.string(),
  goodwillRatio: z.number().nullable(),
});

const ItemSchema = z.object({
  serviceJobId: z.string(),
  serviceItemId: z.string(),
});

const PutServiceOrderItemsToGoodwillBodySchema = z
  .object({
    goodwillOrders: z.array(GoodwillOrderSchema.nullable()).optional(),
    items: z.array(ItemSchema.nullable()).optional(),
  })
  .nullable();

export const PutServiceOrderItemsToGoodwillApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: PutServiceOrderItemsToGoodwillBodySchema,
});

export type PutServiceOrderItemsToGoodwillApiArg = z.infer<
  typeof PutServiceOrderItemsToGoodwillApiArgSchema
>;

export const PutServiceOrderItemsToGoodwillApiResponseSchema = z.unknown();
export type PutServiceOrderItemsToGoodwillApiResponse = z.infer<
  typeof PutServiceOrderItemsToGoodwillApiResponseSchema
>;

const GoodwillBulkRatioOrderSchema = z.object({
  serviceOrderId: z.string().nullable().optional(),
  serviceOrderVariantId: z.string().nullable().optional(),
  serviceOrderNo: z.string().nullable().optional(),
  serviceJobName: z.string().nullable().optional(),
  isServiceOrderParent: z.boolean().nullable().optional(),
  goodwillRatio: z.number().nullable().optional(),
});

export const GetGoodwillBulkRatioApiResponseSchema = z
  .object({
    isGoodwillChangeAllowed: z.boolean().nullable().optional(),
    goodwillOrders: z.array(GoodwillBulkRatioOrderSchema.nullable()).optional(),
  })
  .nullable();

export type GetGoodwillBulkRatioApiResponse = z.infer<typeof GetGoodwillBulkRatioApiResponseSchema>;

export const GetGoodwillBulkRatioApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string().optional(),
});

export type GetGoodwillBulkRatioApiArg = z.infer<typeof GetGoodwillBulkRatioApiArgSchema>;

export const GetGoodwillItemRatioApiResponseSchema = GetGoodwillBulkRatioApiResponseSchema;
export type GetGoodwillItemRatioApiResponse = z.infer<typeof GetGoodwillItemRatioApiResponseSchema>;

export const GetGoodwillItemRatioApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  serviceItemId: z.string(),
});

export type GetGoodwillItemRatioApiArg = z.infer<typeof GetGoodwillItemRatioApiArgSchema>;
