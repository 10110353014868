import {
  Integer,
  ThemeIconKey,
  Box,
  Clickable,
  HStack,
  Icon,
  Show,
  Space,
  Text,
} from 'platform/foundation';
import styled, {css} from 'styled-components';

import {MouseEvent, ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {Actions} from '../../Actions/Actions';
import {Badge} from '../../Badge/Badge';
import {Separator} from '../../Separator/Separator';
import {Tooltip} from '../../Tooltip/Tooltip';
import {MenuAction, MenuBadge} from '../types';

interface MenuItemProps extends TestIdProps {
  id: string;
  label: string;
  hasSeparator?: boolean;
  leftIcon?: ThemeIconKey;
  rightIcon?: ThemeIconKey;
  actions?: MenuAction[];
  isDisabled?: boolean;
  isActive?: boolean;
  depth?: Integer;
  isDark?: boolean;
  isGroup?: boolean;
  badge?: MenuBadge;
  tooltip?: ReactNode;
  shouldTruncateLongText?: boolean;
  onClick: () => void;
}

export function MenuItem(props: MenuItemProps) {
  const depth: Integer = props.depth || 0;

  const actions: MenuAction[] | undefined = props.actions?.map((action) =>
    action.type === 'iconButton'
      ? {
          ...action,
          onClick: (event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            action.onClick(event);
          },
        }
      : {
          ...action,
          menuItems: action.menuItems.map((item) => ({
            ...item,
            onClick: (event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              item.onClick(event);
            },
          })),
        }
  );

  return (
    <>
      <Clickable onClick={props.onClick} isDisabled={props.isDisabled}>
        <MenuItemEffects
          $isDark={props.isDark}
          $isDisabled={props.isDisabled}
          $isActive={!props.isGroup && props.isActive}
          data-testid={props['data-testid']}
        >
          <Box
            opacity={props.isDisabled ? 0.5 : 1}
            flex={1}
            overflow={props.shouldTruncateLongText ? 'hidden' : undefined}
          >
            <HStack align="center">
              <Box overflow={props.shouldTruncateLongText ? 'hidden' : undefined}>
                <Tooltip label={props.tooltip}>
                  <HStack align="center">
                    <Space horizontal={(depth * 4) as Integer} />
                    <Show when={!props.leftIcon}>
                      <Space horizontal={2} />
                    </Show>
                    <Show when={props.leftIcon}>
                      <Icon
                        size={4}
                        value={props.leftIcon}
                        color={props.isDark ? 'text.white' : 'text.primary'}
                      />
                      <Space horizontal={2} />
                    </Show>
                    <Text
                      alternative={props.isGroup && props.isActive}
                      size="small"
                      color={props.isDark ? 'white' : 'primary'}
                      overflowWrap="anywhere"
                      noWrap={props.shouldTruncateLongText}
                      data-testid={props['data-testid']}
                    >
                      {props.label}
                    </Text>
                    {props.badge && (
                      <>
                        <Space horizontal={2} />
                        <Show when={props.badge.value}>
                          <Badge
                            size="small"
                            variant="bold"
                            colorScheme={props.badge.colorScheme}
                            value={props.badge.value as string | number}
                          />
                        </Show>
                        {props.badge.icon && (
                          <Badge
                            size="small"
                            variant="bold"
                            colorScheme={props.badge.colorScheme}
                            icon={props.badge.icon}
                          />
                        )}
                      </>
                    )}
                  </HStack>
                </Tooltip>
              </Box>
              <Space fillAvailable />
              <Show when={props.actions || props.rightIcon}>
                <HStack align="center" spacing={2}>
                  <Actions
                    size="small"
                    actions={actions}
                    data-testid={suffixTestId(`actions`, props)}
                  />
                  <Show when={props.rightIcon}>
                    <Icon
                      size={4}
                      value={props.rightIcon}
                      color={
                        props.isDark
                          ? 'text.white'
                          : props.isGroup
                            ? 'text.primary'
                            : 'general.accent'
                      }
                    />
                  </Show>
                </HStack>
              </Show>
            </HStack>
          </Box>
        </MenuItemEffects>
      </Clickable>
      <Show when={props.hasSeparator}>
        <Box opacity={props.isDark ? 0.2 : 1}>
          <Separator spacing={1} />
        </Box>
      </Show>
    </>
  );
}

export const MenuItemEffects = styled.div<{
  $isDisabled?: boolean;
  $isActive?: boolean;
  $isDark?: boolean;
}>`
  min-height: ${({theme}) => theme.getSize(9)};
  display: flex;
  align-items: center;
  padding-left: ${({theme}) => theme.getSize(2)};
  padding-right: ${({theme}) => theme.getSize(2)};
  border-radius: ${({theme}) => theme.radii.small};

  ${({$isActive, $isDisabled, $isDark}) =>
    !$isActive &&
    !$isDisabled &&
    css`
      /* first div is Clickable second is Box */
      &:hover {
        background: ${({theme}) =>
          $isDark
            ? theme.colors.palettes.neutral[500][100]
            : theme.colors.palettes.neutral[20][100]};
      }
    `}

  ${({$isActive, $isDark}) =>
    $isActive &&
    css`
      background: ${({theme}) =>
        $isDark ? theme.colors.palettes.blue[70][100] : theme.colors.palettes.blue[20][100]};
    `}
`;
