import {parseDate} from 'shared';

export const parseDateFilter = (value: string | null): Date | undefined => {
  if (!value) {
    return undefined;
  }
  const date = parseDate(value);
  const timestamp = date.getTime();
  if (isNaN(timestamp)) {
    return undefined;
  }
  return date;
};
