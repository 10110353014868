import {defaultTo, filter, find, join, map, pipe} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {Institution} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

type InstitutionOption = {
  label: string;
  value: Institution;
};

export const institutionOptions: InstitutionOption[] = [
  {label: i18n.t('entity.businessInfo.labels.institutions.insurance'), value: 'INSURANCE'},
  {label: i18n.t('entity.businessInfo.labels.institutions.supplier'), value: 'SUPPLIER'},
  {
    label: i18n.t('entity.businessInfo.labels.institutions.fleet'),
    value: 'FLEET',
  },
  {label: i18n.t('entity.businessInfo.labels.institutions.loan'), value: 'LOAN'},
  {label: i18n.t('entity.businessInfo.labels.institutions.leasing'), value: 'LEASING'},
];

export function useInstitution() {
  const getInstitutions = (institutions: Institution[] | Nullish): string | Nullish => {
    if (!institutions || !institutions?.length) {
      return null;
    }
    return pipe(
      filter((i: InstitutionOption) =>
        isNotNil(find((institution) => institution === i.value)(institutions))
      ),
      map((i: InstitutionOption) => i.label),
      join(', ')
    )(institutionOptions);
  };

  const getInstitutionById = (institution: Institution): InstitutionOption | undefined =>
    find((i) => i.value === institution, institutionOptions);

  return {
    institutionOptions,
    getInstitutions,
    getInstitution: (institution: Institution) => defaultTo(null, getInstitutionById(institution)),
    getInstitutionLabel: (institution: Institution) =>
      defaultTo(null, getInstitutionById(institution)?.label),
  };
}
