import {endOfToday, isAfter, min} from 'date-fns';
import {object, SchemaOf, date, string, mixed} from 'yup';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {Nullish, parseDate} from 'shared';

import {IdentityCardData} from '../types/IdentityCardData';

// @ts-expect-error Yup date() works with string inputs, but types dont reflect that and expect only Date type
export const $IdentityCardData: SchemaOf<IdentityCardData> = object({
  cardNumber: mixed()
    .when('type', {
      is: (value: string | Nullish) => isNotNilOrEmpty(value),
      then: string().defined().required().nullable(),
      otherwise: string().default(null).nullable(),
    })
    .defined()
    .nullable(),
  note: string().defined().nullable(),
  issuedInCountryCode: string().defined().nullable(),
  issuer: string().defined().nullable(),
  type: string().defined().nullable(),
  validUntil: date().defined().nullable(),
  issuedOn: date()
    .nullable()
    .test('isNotGreater', {}, (value, options) => {
      if (!value) {
        return true;
      }

      const today = endOfToday();
      const validUntil = options.parent.validUntil;
      const maxDate = min([today, parseDate(validUntil ?? today)]);

      if (isAfter(value, maxDate)) {
        return options.createError({
          message: i18n.t('general.errors.date.max', {
            max: maxDate.toLocaleDateString(i18n.language),
          }),
        });
      }

      return true;
    }),
});
