import {Button, closeCurrentDialog} from 'platform/components';
import {Right, Text, VStack} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {Trans} from 'react-i18next';

import i18n from '@omnetic-dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

interface MileageValidationDialogProps extends RequiredTestIdProps {
  highestMileage: number | Nullish;
}

export function MileageValidationDialog(props: MileageValidationDialogProps) {
  const formatNumber = useFormatNumber();

  return (
    <VStack spacing={4}>
      <Text data-testid={suffixTestId('message', props)}>
        <Trans
          i18nKey="entity.order.labels.checkinHandoverMileageValidation"
          components={{bold: <Text inline alternative />}}
          values={{mileage: formatNumber(props.highestMileage ?? 0)}}
        />
      </Text>
      <Right>
        <Button
          title={i18n.t('general.actions.close')}
          onClick={closeCurrentDialog}
          variant="secondary"
          data-testid={suffixTestId('close', props)}
        />
      </Right>
    </VStack>
  );
}
