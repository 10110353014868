import {Card} from 'platform/components';
import {Heading, Space, VStack} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {BillingInformationResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {generateInfoCardRows, selectCurrencies, BankAccount} from '@omnetic-dms/teas';

import {FormContainerN20} from '../styles';

export type BillingInformationCardProps = {
  profileIndex: number;
  billingInfo: BillingInformationResponseBody;
  expandedBankAccount: number | null;
  accountRadio: boolean | undefined;
  selectedBankAccounts: number[];
  handleChangePrimaryAccount: (profileIndex: number, accountIndex: number) => void;
  handleExpandAccount: (index: number) => void;
};

export const BillingInformationCard: FC<BillingInformationCardProps> = ({
  profileIndex,
  billingInfo,
  expandedBankAccount,
  accountRadio,
  selectedBankAccounts,
  handleChangePrimaryAccount,
  handleExpandAccount,
}) => {
  const currencies = useSelector(selectCurrencies);

  return (
    <VStack>
      <Heading size={4}>{i18n.t('general.labels.bankAccount')}</Heading>
      <Space vertical={4} />
      <VStack spacing={4}>
        {billingInfo.bankAccounts?.map((bankAccount: BankAccount, accountIndex: number) => {
          const showDetail = expandedBankAccount === accountIndex;
          return (
            <Card
              variant="inlineGrey"
              key={accountIndex}
              isExpandable
              isExpanded={showDetail}
              onExpandButtonClick={() => handleExpandAccount(accountIndex)}
              title={bankAccount.bankName}
              subtitle={bankAccount.accountNumber}
              control={
                accountRadio
                  ? {
                      type: 'radio',
                      value: accountIndex === selectedBankAccounts[profileIndex],
                      tooltip: i18n.t('entity.customer.labels.primaryBankAccountHelperText'),
                      onChange: () => {
                        handleChangePrimaryAccount(profileIndex, accountIndex);
                      },
                    }
                  : undefined
              }
            >
              <FormContainerN20>
                {generateInfoCardRows([
                  {
                    key: 'entity.bank.labels.name',
                    label: i18n.t('entity.bank.labels.name'),
                    value: bankAccount?.bankName,
                  },
                  {
                    key: 'entity.bank.labels.ownerName',
                    label: i18n.t('entity.bank.labels.ownerName'),
                    value: bankAccount?.accountName,
                  },
                  {
                    key: 'entity.bank.labels.accountNumber',
                    label: i18n.t('entity.bank.labels.accountNumber'),
                    value: bankAccount?.accountNumber,
                  },
                  {
                    key: 'entity.bank.labels.currency',
                    label: i18n.t('entity.bank.labels.currency'),
                    value:
                      bankAccount?.currency &&
                      currencies?.find((c) => c.code === bankAccount?.currency)?.name,
                  },
                  {
                    key: 'entity.bank.labels.swiftBic',
                    label: i18n.t('entity.bank.labels.swiftBic'),
                    value: bankAccount?.swift,
                  },
                  {
                    key: 'entity.bank.labels.iban',
                    label: i18n.t('entity.bank.labels.iban'),
                    render: bankAccount?.iban,
                  },
                ])}
              </FormContainerN20>
            </Card>
          );
        })}
      </VStack>
    </VStack>
  );
};
