import {Button} from 'platform/components';
import {Hide, Text, VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

interface VehicleDescriptionProps extends RequiredTestIdProps {
  description: string | Nullish;
}

const TRUNCATED_DESCRIPTION_LENGTH = 400;

export function VehicleDescription(props: VehicleDescriptionProps) {
  const [isShowAll, setShowAll] = useBoolean();

  if (isNil(props.description)) {
    return null;
  }

  const isShortText = props.description.length < TRUNCATED_DESCRIPTION_LENGTH;

  return (
    <VStack spacing={3}>
      <Text overflowWrap="anywhere">
        {isShortText || isShowAll
          ? props.description
          : `${props.description.substring(0, TRUNCATED_DESCRIPTION_LENGTH)}...`}
      </Text>
      <Hide when={isShortText || isShowAll}>
        <Button
          variant="link"
          title={i18n.t('entity.sourcing.vehiclePreview.actions.showMore')}
          rightIcon="navigation/arrow_downward"
          onClick={setShowAll}
          data-testid={suffixTestId('showMore', props)}
        />
      </Hide>
    </VStack>
  );
}
