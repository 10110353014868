import {TestIdProps} from 'shared';

import {ButtonProps} from '../Button/Button';
import {Status} from '../Status/Status';
import {useTranslationContext} from '../TranslationProvider/TranslationContext';

interface ErrorStatusProps extends TestIdProps {
  headline?: string;
  subheadline?: string;
  action?: Pick<ButtonProps, 'onClick' | 'title' | 'isDisabled' | 'leftIcon' | 'variant'>;
}

export function ErrorStatus(props: ErrorStatusProps) {
  const t = useTranslationContext();

  return (
    <Status
      icon="alert/error_outline"
      headline={props.headline ?? t('general.labels.error')}
      subheadline={props.subheadline}
      action={props.action}
      severity="danger"
      data-testid={props['data-testid']}
    />
  );
}
