import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  FormButton,
  FormField,
  FormSubmitHandler,
  Form,
  DataStatus,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {UpdateInterestRequestBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {useCodeList} from '@omnetic-dms/shared';

import {suffixTestId, RequiredTestIdProps} from 'shared';

interface InterestCloseFormProps extends RequiredTestIdProps {
  isLoading?: boolean;
  onSubmit: FormSubmitHandler<UpdateInterestRequestBody>;
  onClose?: () => void;
}

export function InterestCloseForm(props: InterestCloseFormProps) {
  const [reasonOptions, {isLoading, isError}] = useCodeList('unsuccessful_interest_reason');

  const defaultValues: UpdateInterestRequestBody = {
    state: 'UNSUCCESSFUL',
  };

  const reasonChoiceOptions = reasonOptions?.map((reason) => ({
    value: reason.codeId,
    label: reason.name,
  }));

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={20}>
      <Form<UpdateInterestRequestBody> onSubmit={props.onSubmit} defaultValues={defaultValues}>
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="reasonCloseCodeId"
              type="choice"
              options={reasonChoiceOptions}
              label={i18n.t('general.labels.reason')}
              menuInPortal
              data-testid={suffixTestId('reasonCloseNote', props)}
            />
            <FormField
              control={control}
              name="reasonCloseNote"
              type="textarea"
              minRows={3}
              label={i18n.t('general.labels.note')}
              data-testid={suffixTestId('reasonCloseNote', props)}
            />
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.cancel')}
                onClick={props.onClose ?? closeCurrentDialog}
                variant="secondary"
                isDisabled={props.isLoading}
                data-testid={suffixTestId('cancel', props)}
              />
              <FormButton
                control={control}
                title={i18n.t('general.actions.endCase')}
                variant="primary"
                type="submit"
                isLoading={props.isLoading}
                data-testid={suffixTestId('endCase', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
