import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

interface AvailabilitySegmentProps extends RequiredTestIdProps {
  articleId: string | Nullish;
  manufacturerNumber: string | Nullish;
  manufacturerId: string | Nullish;
}

export function AvailabilitySegment(props: AvailabilitySegmentProps) {
  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        {
          articleId: props.articleId,
          manufacturerNumber: props.manufacturerNumber,
          manufacturer: props.manufacturerId,
        },
      ]),
    [props.articleId, props.manufacturerNumber, props.manufacturerId]
  );

  return (
    <DataGrid
      gridCode="warehouse-availability"
      queryModifier={queryModifier}
      autoHeight
      data-testid={suffixTestId('availability', props)}
    />
  );
}
