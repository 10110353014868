import {useMemo} from 'react';

import {FieldEvent} from '../types/EventAction';
import {FullSchema} from '../types/FullSchema';
import {Getter} from '../types/Getter';
import {Setter} from '../types/Setter';

export function useEventActions(schema: FullSchema, getter: Getter, setter: Setter) {
  return useMemo(
    () => ({
      onChange: (_value?: unknown) => {
        handleEvent(schema, getter, setter, 'onChange');
      },
      onBlur: (_value?: unknown) => {
        handleEvent(schema, getter, setter, 'onBlur');
      },
      onFocus: () => {
        handleEvent(schema, getter, setter, 'onFocus');
      },
    }),
    [schema, getter, setter]
  );
}

export const handleEvent = (
  schema: FullSchema,
  getter: Getter,
  setter: Setter,
  event: FieldEvent
) => {
  const actionsForCurrentEvent = schema[event];
  actionsForCurrentEvent?.forEach((action) => {
    if (action.action === 'reset') {
      setter(action.field, undefined);
    }
  });
};
