import {
  Attributes,
  Button,
  Card,
  DataStatus,
  DropdownActionMenuItem,
  FormControl,
  FormField,
  openDialog,
} from 'platform/components';
import {HStack, Heading, Hide, Space, VStack} from 'platform/foundation';
import {formatPhoneNumber} from 'platform/locale';

import {UseFormReturn, useFieldArray} from 'react-hook-form';

import {isNotNil} from 'ramda';
import {isNilOrEmpty, isTrue} from 'ramda-adjunct';

import {PutBranchV2ApiArg} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {getUserName} from '@omnetic-dms/shared';

import {EMPTY_PLACEHOLDER, buildArray} from 'shared';

import {ContactPersonForm} from '../../../components/ContactPersonForm/ContactPersonForm';
import {SettingsSection} from '../../../components/SettingsSection/SettingsSection';

interface ContactPersonListProps {
  control: FormControl<PutBranchV2ApiArg>;
  formApi: UseFormReturn<PutBranchV2ApiArg>;
}

export function ContactPersonList(props: ContactPersonListProps) {
  const {fields, remove, append, update} = useFieldArray({
    control: props.control,
    name: 'contactPersons',
  });

  const handleUpdatePrimaryContactPerson = (index: number) => (val: boolean) => {
    const oldPrimaryIndex = fields.findIndex((field) => field.isPrimary);
    update(oldPrimaryIndex, {...fields[oldPrimaryIndex], isPrimary: false});
    update(index, {...fields[index], isPrimary: val});
  };

  const handleEditContactPerson = (index: number) => {
    const updateField = props.formApi.getValues(`contactPersons.${index}`);
    openDialog(
      <ContactPersonForm defaultValues={updateField} onSubmit={(val) => update(index, val)} />,
      {scrollBehavior: 'outside', title: i18n.t('entity.customer.labels.editContactPerson')}
    );
  };

  const createContactPerson = () => {
    openDialog(<ContactPersonForm onSubmit={append} />, {
      scrollBehavior: 'outside',
      title: i18n.t('entity.customer.labels.createContactPerson'),
    });
  };

  const areContactsEmpty = isNilOrEmpty(fields);

  return (
    <VStack spacing={5}>
      <HStack justify="space-between" align="center">
        <Heading size={4}>{i18n.t('entity.customer.labels.contactPersons')}</Heading>
        <Hide when={areContactsEmpty}>
          <Button
            title={i18n.t('entity.customer.labels.createContactPerson')}
            onClick={createContactPerson}
            leftIcon="content/add_circle"
            variant="link"
          />
        </Hide>
      </HStack>
      <SettingsSection>
        <DataStatus
          isEmpty={areContactsEmpty}
          spacing={5}
          action={{
            title: i18n.t('entity.customer.labels.createContactPerson'),
            onClick: createContactPerson,
            leftIcon: 'content/add_circle',
          }}
        >
          <VStack spacing={2}>
            {fields.map((field, index) => (
              <Card
                key={field.id}
                variant="inlineGrey"
                isClosedByDefault={!field.isPrimary}
                title={getUserName(field) ?? i18n.t('entity.customer.labels.noContactPersonName')}
                isExpandable={!field.isPrimary}
                actions={[
                  {
                    type: 'dropdown-iconButton',
                    icon: 'navigation/more_vert',
                    menuItems: buildArray<DropdownActionMenuItem>()
                      .whenNot(field.isPrimary, {
                        label: i18n.t('general.labels.delete'),
                        onClick: () => remove(index),
                        leftIcon: 'action/delete',
                        severity: 'danger',
                      })
                      .add({
                        label: i18n.t('general.labels.edit'),
                        onClick: () => handleEditContactPerson(index),
                        leftIcon: 'image/edit',
                      }),
                  },
                ]}
              >
                <FormField
                  control={props.control}
                  name={`contactPersons.${index}.isPrimary`}
                  isDisabled={isTrue(field.isPrimary)}
                  type="switch"
                  onChange={handleUpdatePrimaryContactPerson(index)}
                  label={i18n.t('entity.customer.labels.contactPersonsHelperText')}
                />

                <Space vertical={4} />
                <Attributes
                  size="quarter"
                  rows={[
                    {
                      label: i18n.t('entity.person.labels.role'),
                      value: field.role,
                    },
                    {
                      label: i18n.t('entity.person.labels.phoneNumber'),
                      value: isNotNil(field.phoneNumber)
                        ? formatPhoneNumber(
                            `${field.phoneNumber.prefix}${field.phoneNumber.number}`,
                            field.phoneNumber?.countryCode
                          )
                        : EMPTY_PLACEHOLDER,
                    },
                    {
                      label: i18n.t('general.labels.email'),
                      value: field.emailAddress,
                    },
                  ]}
                />
              </Card>
            ))}
          </VStack>
        </DataStatus>
      </SettingsSection>
    </VStack>
  );
}
