import {addDays, compareAsc} from 'date-fns';
import {match} from 'ts-pattern';

import {Nullish} from 'shared';

export function getIsExpired(postponedUntil: string | Nullish) {
  if (!postponedUntil) {
    return false;
  }

  const result = compareAsc(addDays(postponedUntil, 1), new Date());

  return match(result)
    .with(-1, () => true)
    .with(0, () => false)
    .with(1, () => false)
    .otherwise(() => false);
}
