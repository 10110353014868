import {all, fork} from 'typed-redux-saga';

import {advertisementSettingsSaga} from '../advertisementSettings/saga';
import {documentsSaga} from '../documents/saga';
import {integrationsSettingsSaga} from '../integrationsSettings/saga';
import {userManagementSaga} from '../userManagement/saga';

export function* generalSettingsSaga(): Generator {
  yield* all([
    fork(advertisementSettingsSaga),
    fork(documentsSaga),
    fork(userManagementSaga),
    fork(integrationsSettingsSaga),
  ]);
}
