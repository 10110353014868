import {Box, HStack, Space, Text} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {EMPTY_PLACEHOLDER, RequiredTestIdProps, suffixTestId} from 'shared';

interface OverviewTotalPriceProps extends RequiredTestIdProps {
  totalPriceWithoutVat: string | null;
  totalPriceWithVat: string | null;
}

export function OverviewTotalprice(props: OverviewTotalPriceProps) {
  return (
    <Box height={14} padding={2} data-testid={props['data-testid']}>
      <HStack height={10} align="center">
        <Text alternative>{i18n.t('general.labels.totalPrice')}</Text>
        <Space fillAvailable />
        <Text color="tertiary" data-testid={suffixTestId('withoutVat', props)}>
          {`${props.totalPriceWithoutVat ?? EMPTY_PLACEHOLDER} ${i18n.t('general.labels.w/oVat')}`}
        </Text>
        <Space horizontal={2} />
        <Text alternative data-testid={suffixTestId('withVat', props)}>
          {props.totalPriceWithVat ?? EMPTY_PLACEHOLDER}
        </Text>
      </HStack>
    </Box>
  );
}
