import {appWorkspaceKey} from '@omnetic-dms/config';
import {environment} from '@omnetic-dms/environment';
import {loginRoutes} from '@omnetic-dms/routes';

type GetWorkspaceFromUri = {
  workspace?: string;
  domain: string;
  shouldRedirectToAppWorkspace: boolean;
  isReviewApp: boolean;
};

const DEFAULT_REVIEW_TENANT = 'metada';
const REVIEW_APP = 'review';

const POSSIBLE_WORKSPACES = ['localhost', 'localhost:4200', 'dev', 'stage', 'review'];

/**
 * Get OMNETIC workspace from current hostname
 * The list of possible urls looks like this
 * 1. localhost:3000
 * 2. carvago.localhost:3000
 * 3. carvago.dev.teasdev.com
 * 4. develop.review.teasdev.com
 * 5. app.stage.teasdev.com
 */
export const getWorkspaceFromUri = (url?: string): GetWorkspaceFromUri => {
  const [wsp, ...rest] = (url || window.location.host).split('.');
  const isReviewApp = window.location.href.includes(REVIEW_APP);
  const isNoWorkspaceFound = POSSIBLE_WORKSPACES.includes(wsp);

  if (environment.workspace) {
    return {
      workspace: environment.workspace,
      domain: window.location.host,
      shouldRedirectToAppWorkspace: false,
      isReviewApp,
    };
  }

  if (isNoWorkspaceFound) {
    return {
      domain: window.location.host,
      shouldRedirectToAppWorkspace: true,
      isReviewApp,
    };
  }

  const domain = rest.join('.');

  if (
    (wsp === appWorkspaceKey && window.location.pathname !== loginRoutes.loginWorkspace) ||
    (wsp !== appWorkspaceKey && window.location.pathname === loginRoutes.loginWorkspace)
  ) {
    return {
      workspace: wsp,
      domain,
      isReviewApp,
      shouldRedirectToAppWorkspace: true,
    };
  }

  return {
    workspace: isReviewApp ? DEFAULT_REVIEW_TENANT : wsp,
    domain,
    isReviewApp,
    shouldRedirectToAppWorkspace: false,
  };
};
