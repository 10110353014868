import {indexBy} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {BranchListResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export const getBranchesNames = (
  branches: BranchListResponseBody | Nullish,
  selectedBranches: string[]
) => {
  const branchesById = indexBy((branch) => branch.id, branches?.branchListItems ?? []);

  if (isNilOrEmpty(selectedBranches)) {
    return EMPTY_PLACEHOLDER;
  }

  const branchesNames = selectedBranches?.map(
    (id) => branchesById[id]?.marketingName ?? i18n.t('entity.settings.labels.unknownBranch')
  );

  const sortedBranchesNames = branchesNames.sort((a, b) => a.localeCompare(b));
  return sortedBranchesNames.join(', ');
};
