import {FlexboxDirection, Integer, Show, Stack, VStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {FormControlProps, OptionType} from '../../types/FormControlProps';
import {HelperText} from '../HelperText/HelperText';
import {Label} from '../Label/Label';
import {RadioItem} from './components/RadioItem';

export type RadioOption = OptionType & {isDisabled?: boolean};

export interface RadioProps extends FormControlProps<string | null>, TestIdProps {
  options: RadioOption[];
  direction?: FlexboxDirection;
  spacing?: Integer;
}

export function Radio(props: RadioProps) {
  return (
    <VStack>
      <Label
        isRequired={props.isRequired}
        tooltip={props.tooltip}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <Stack
        direction={props.direction ?? 'row'}
        spacing={props.spacing ?? 8}
        data-testid={suffixTestId('radioWrapper', props)}
      >
        {props.options.map(({label, value, isDisabled}) => (
          <RadioItem
            onChange={() => props.onChange?.(value)}
            value={value === props.value}
            key={value}
            isDisabled={props.isDisabled || isDisabled}
            data-testid={suffixTestId(value, props)}
          >
            {label}
          </RadioItem>
        ))}
      </Stack>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
