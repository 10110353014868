import {DataStatus, showNotification} from 'platform/components';
import {Show} from 'platform/foundation';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {useGetMyWebPlatformQuery, useMyWebAuthenticateMutation} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import {handleApiError} from '@omnetic-dms/shared';
import {AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

import {useBranchId} from '../../../hooks/useBranchId';
import {PlatformAuthenticationFormProps} from './components/PlatformAuthenticationForm';
import {PlatformAuthenticationFormMyWeb} from './components/PlatformAuthenticationFormMyWeb';
import {PlatformPhotoSettings} from './components/PlatformPhotoSettings';

const paramsTenant = undefined;

export function PlatformSettingsMyWeb() {
  const branchId = useBranchId();
  const params = {branchId};
  const {data: dataGeneral, isLoading, isError} = useGetMyWebPlatformQuery({params});
  const isPublishingOnTenant = isNotNil(branchId) && !!dataGeneral?.publishOnTenant;
  const {data: dataTenant} = useGetMyWebPlatformQuery(paramsTenant, {skip: isNil(branchId)});
  const [saveAuthentication] = useMyWebAuthenticateMutation();

  const onSubmitCredentials: PlatformAuthenticationFormProps['onSubmit'] = (values) =>
    saveAuthentication({
      body: values,
      params: isPublishingOnTenant ? paramsTenant : params,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
      <DataStatus isLoading={isLoading} isError={isError}>
        <PlatformAuthenticationFormMyWeb
          defaultValues={(isPublishingOnTenant ? dataTenant : dataGeneral)?.credentials}
          isPublishingOnTenant={isPublishingOnTenant}
          onSubmit={onSubmitCredentials}
        />
        <PlatformPhotoSettings
          platformCode={AdvertisingPlatformCodeEnum.MYWEB}
          isPublishingOnTenant={isPublishingOnTenant}
        />
      </DataStatus>
    </Show>
  );
}
