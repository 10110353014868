import {nanoid} from '@reduxjs/toolkit';
import {
  Choice,
  Dialog,
  Separator,
  Switch,
  closeCurrentDialog,
  openDialog,
  showNotification,
  useDialog,
  useLocalStorage,
} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {useCallback, useMemo, useReducer, useState} from 'react';
import {Helmet} from 'react-helmet-async';

import {add, mergeAll} from 'ramda';
import {isString} from 'ramda-adjunct';

import {useGetGridCodesListQuery} from '@omnetic-dms/api';
import {vehiclesRoutes} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {Nullish, composePath, useBoolean} from 'shared';

import {
  ActionCallback,
  DataGrid,
  DataGridBehavior,
  DataGridSettings,
  QueryFilterObject,
} from 'features/datagrid';

import {OverrideQueryModifier} from '../../components/OverrideQueryModifier';

const PAGE_TITLE = 'Demo page - DataGrid';
const DEMO_DATAGRID_LOCAL_SOTRAGE_KEY = 'METADA_DATAGRID_LOCAL_SOTRAGE_KEY';

const codeToOptions = (codes: string[] | Nullish) =>
  codes?.map((code) => ({value: code, label: code}));

export function DataGridPlayground() {
  const [key, reloadComponents] = useReducer(add(1), 0);
  const [isOverridesOpen, openOverrides, closeOverrides] = useDialog();
  const [isGridOpen, openGrid, closeGrid] = useDialog();
  const [isOverridesEnabled, _on, _off, toggleOverridesEnabled] = useBoolean(false);
  const [settingsOverrides, _setSettingsOverrides] = useState<DataGridBehavior>(FullDGSettings);
  const [queryModifierOverrider, setQueryModifierOverrider] = useState<object | null>(null);

  const {data: codeListData} = useGetGridCodesListQuery();

  const overrides = isOverridesEnabled ? settingsOverrides : {};

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, queryModifierOverrider ?? {}]);

  const setSettingsOverrides = useCallback(
    (name: keyof DataGridSettings, value: any) => {
      _setSettingsOverrides({
        ...settingsOverrides,
        [name]: value,
      });
    },
    [settingsOverrides, _setSettingsOverrides]
  );

  const [gridCode, setGridCode] = useLocalStorage<string | null>(
    `${DEMO_DATAGRID_LOCAL_SOTRAGE_KEY}_gridCode`,
    'vehicle'
  );

  const actionCallback: ActionCallback = useCallback(({actionKey, rowId}) => {
    switch (actionKey) {
      case 'detail':
        if (isString(rowId)) {
          const path = composePath(vehiclesRoutes.detail, {params: {id: rowId}});
          window.open(path, '_blank')?.focus();
        }
        break;
      default:
        showNotification.error(`Action callback was not specified for action ${actionKey}`);
        break;
    }
  }, []);

  const onQueryModifierChange = (value: any) => {
    setQueryModifierOverrider(value);
    reloadComponents();
    closeCurrentDialog();
  };

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
      </Helmet>

      <Main
        isFullHeight
        heading={PAGE_TITLE}
        actions={[
          {
            type: 'button',
            onClick: openGrid,
            title: 'Open Simple DG in Dialog',
          },
          {
            type: 'button',
            onClick: () =>
              openDialog(<OverrideQueryModifier onQueryModifierChange={onQueryModifierChange} />, {
                title: 'Override Query Modifier',
              }),
            title: 'Override Query Modifier',
          },
          {
            type: 'button',
            onClick: openOverrides,
            title: 'Override Server Settings',
          },
        ]}
      >
        <HStack spacing={4} align="center">
          GridCode:
          <Box width={50}>
            <Choice
              options={codeToOptions(codeListData) ?? []}
              value={gridCode}
              onChange={(value) => setGridCode(value as string)}
            />
          </Box>
        </HStack>
        <Separator />
        <DataGrid
          /**
           * Using key to refresh DG is ok in this case
           * We are changing the gridCode, not just reloading the data
           */
          // eslint-disable-next-line no-restricted-syntax
          key={`${gridCode}-${key}`}
          gridCode={gridCode as string}
          data-testid="demo-grid"
          _useAsLastResort_definitionBehaviorOverrides={overrides}
          queryModifier={queryModifier}
          actionCallback={actionCallback}
        />
      </Main>

      <Dialog
        title="DGv4 in Dialog"
        scrollBehavior="inside"
        isOpen={isGridOpen}
        onClose={closeGrid}
      >
        <div style={{height: 450}}>
          <DataGrid
            /**
             * Using key to refresh DG is ok in this case
             * We are changing the gridCode, not just reloading the data
             */
            // eslint-disable-next-line no-restricted-syntax
            key={`${gridCode}-${key}`}
            gridCode={gridCode as string}
            _useAsLastResort_definitionBehaviorOverrides={SimpleDGSettings}
            queryModifier={queryModifier}
          />
        </div>
      </Dialog>

      <Dialog
        title="Override Server Settings"
        scrollBehavior="inside"
        isOpen={isOverridesOpen}
        onClose={closeOverrides}
      >
        Disclaimer: This dialog is for demo page purposes only and will not be part of any future
        datagrid.
        <hr style={{marginTop: '1rem', borderColor: '#c1c1c1'}} />
        <div style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-around'}}>
          <button
            style={{padding: '5px 10px', border: '1px solid #c1c1c1', borderRadius: '4px'}}
            onClick={() => _setSettingsOverrides(SimpleDGSettings)}
          >
            Simple Grid
          </button>
          <button
            style={{padding: '5px 10px', border: '1px solid #c1c1c1', borderRadius: '4px'}}
            onClick={() => _setSettingsOverrides(FullDGSettings)}
          >
            Full Grid
          </button>
          <Switch label="Overrides" value={isOverridesEnabled} onChange={toggleOverridesEnabled} />
        </div>
        <hr style={{marginTop: '1rem', borderColor: '#c1c1c1'}} />
        <Checkbox
          label="Enable Column Pinning"
          value={settingsOverrides.columnPinningAllowed}
          onChange={(v) => setSettingsOverrides('columnPinning', v)}
        />
        <Checkbox
          label="Enable Summary Row"
          value={settingsOverrides.summaryRowEnabled}
          onChange={(v) => setSettingsOverrides('columnPinning', v)}
        />
        <Checkbox
          label="Enable Column Moving"
          value={settingsOverrides.columnMovingAllowed}
          onChange={(v) => setSettingsOverrides('columnMoving', v)}
        />
        <Checkbox
          label="Enable Column Visibility Change"
          value={settingsOverrides.columnHidingAllowed}
          onChange={(v) => setSettingsOverrides('columnHiding', v)}
        />
        <Checkbox
          label="Enable Context Menu"
          value={settingsOverrides.contextMenuEnabled}
          onChange={(v) => setSettingsOverrides('contextMenu', v)}
        />
        <Checkbox
          label="Enable Column Menu"
          value={settingsOverrides.columnMenuEnabled}
          onChange={(v) => setSettingsOverrides('columnMenu', v)}
        />
        <Select
          label="Column Sorting"
          value={settingsOverrides.columnSortMode}
          onChange={(v) => setSettingsOverrides('columnSorting', v)}
          options={[
            {label: 'Multiple', value: 'MULTIPLE_ROWS'},
            {label: 'Single', value: 'SINGLE_SORT'},
            {label: 'None', value: 'NONE'},
          ]}
        />
        <Checkbox
          label="Enable Column Auto Resizing"
          value={settingsOverrides.columnAutoSizingAllowed}
          onChange={(v) => setSettingsOverrides('columnAutoResizing', v)}
        />
        <Checkbox
          label="Enable Fit To Screen"
          value={settingsOverrides.fitToScreenAllowed}
          onChange={(v) => setSettingsOverrides('columnFitToScreen', v)}
        />
        <Checkbox
          label="Show Filter Toolpanel"
          value={settingsOverrides.filterToolPanelVisible}
          onChange={(v) => setSettingsOverrides('toolPanelFilter', v)}
        />
        <Checkbox
          label="Show Preset Panel"
          value={settingsOverrides.presetsEnabled}
          onChange={(v) => setSettingsOverrides('presets', v)}
        />
        <Checkbox
          label="Show Smart Search"
          value={settingsOverrides.smartSearchEnabled}
          onChange={(v) => setSettingsOverrides('smartSearch', v)}
        />
        <Checkbox
          label="Show Settings Toolpanel"
          value={settingsOverrides.settingsToolPanelVisible}
          onChange={(v) => setSettingsOverrides('toolPanelSettings', v)}
        />
        <Checkbox
          label="Show Column Toolpanel"
          value={settingsOverrides.columnToolPanelVisible}
          onChange={(v) => setSettingsOverrides('toolPanelColumn', v)}
        />
        <Checkbox
          label="Enable Cell Resizing"
          value={settingsOverrides.columnResizingAllowed}
          onChange={(v) => setSettingsOverrides('columnResizing', v)}
        />
        <Checkbox
          label="Enable Range Selection"
          value={settingsOverrides.rangeSelectAllowed}
          onChange={(v) => setSettingsOverrides('rangeSelection', v)}
        />
        <Select
          label="Wrapper Style"
          value={settingsOverrides.wrapperStyle}
          onChange={(v) => setSettingsOverrides('datagridWrapperStyle', v)}
          options={[
            {label: 'Inline', value: 'INLINE'},
            {label: 'Card', value: 'CARD'},
          ]}
        />
        <Select
          label="Row Selection"
          value={settingsOverrides.rowSelectMode}
          onChange={(v) => setSettingsOverrides('rowSelection', v)}
          options={[
            {label: 'Single', value: 'SINGLE'},
            {label: 'Multiple', value: 'MULTIPLE'},
            {label: 'None', value: 'NONE'},
          ]}
        />
        <Checkbox
          label="Enable Pagination"
          value={settingsOverrides.paginationEnabled}
          onChange={(v) => setSettingsOverrides('pagination', v)}
        />
        <Checkbox
          label="Enable Actions"
          value={settingsOverrides.actionColumnEnabled}
          onChange={(v) => setSettingsOverrides('actions', v)}
        />
        <Select
          label="Filter Type"
          value={settingsOverrides.filterMode}
          onChange={(v) => setSettingsOverrides('filterType', v)}
          options={[
            {label: 'Native', value: 'NATIVE'},
            {label: 'Custom', value: 'CUSTOM'},
          ]}
        />
      </Dialog>
    </>
  );
}

type CheckboxProps = {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

const Checkbox = (props: CheckboxProps) => {
  const id = useMemo<string>(() => nanoid(), []);
  return (
    <div style={{marginTop: '1rem'}}>
      <label style={{display: 'inline-block', width: '30%', minWidth: '100px'}} htmlFor={id}>
        {props.label}
      </label>
      <div style={{display: 'inline-block', width: '49%'}}>
        <input
          type="checkbox"
          name={id}
          id={id}
          checked={props.value}
          onChange={(e) => props.onChange(e.target.checked)}
        />
      </div>
    </div>
  );
};

type SelectProps = {
  label: string;
  value: string;
  options: Array<{label: string; value: string}>;
  onChange: (value: string) => void;
};

const Select = (props: SelectProps) => {
  const id = useMemo<string>(() => nanoid(), []);
  return (
    <div style={{marginTop: '1rem'}}>
      <label style={{display: 'inline-block', width: '30%', minWidth: '100px'}} htmlFor={id}>
        {props.label}
      </label>
      <div style={{display: 'inline-block', width: '49%'}}>
        <select name={id} id={id} onChange={(e) => props.onChange(e.target.value)}>
          {props.options.map((option) => (
            <option key={option.value} selected={option.value === props.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const SimpleDGSettings: DataGridBehavior = {
  summaryRowEnabled: false,
  columnResizingAllowed: false,
  rangeSelectAllowed: false,
  rowSelectMode: 'NONE',
  columnToolPanelVisible: false,
  settingsToolPanelVisible: false,
  filterToolPanelVisible: false,
  presetsEnabled: false,
  wrapperStyle: 'INLINE',
  smartSearchEnabled: false,
  columnPinningAllowed: false,
  columnMovingAllowed: false,
  columnMenuEnabled: false,
  fitToScreenAllowed: false,
  clipboardExportAllowed: false,
  columnSortMode: 'NONE',
  filterMode: 'CUSTOM',
  statusBarVisible: false,
  contextMenuEnabled: false,
  columnAutoSizingAllowed: false,
  columnHidingAllowed: false,
  paginationEnabled: false,
  actionColumnEnabled: false,
};

const FullDGSettings: DataGridBehavior = {
  summaryRowEnabled: true,
  columnResizingAllowed: true,
  rangeSelectAllowed: true,
  rowSelectMode: 'MULTIPLE',
  columnToolPanelVisible: true,
  settingsToolPanelVisible: true,
  filterToolPanelVisible: true,
  presetsEnabled: true,
  wrapperStyle: 'CARD',
  smartSearchEnabled: true,
  columnPinningAllowed: true,
  columnMovingAllowed: true,
  columnMenuEnabled: true,
  fitToScreenAllowed: true,
  clipboardExportAllowed: true,
  columnSortMode: 'MULTI_SORT',
  filterMode: 'CUSTOM',
  statusBarVisible: true,
  contextMenuEnabled: true,
  columnAutoSizingAllowed: true,
  columnHidingAllowed: true,
  paginationEnabled: true,
  actionColumnEnabled: true,
};
