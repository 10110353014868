import {Button, Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {Box, Center, Heading, HStack, Icon, Space, Text, VStack} from 'platform/foundation';
import {array, object} from 'yup';

import {usePairDeviceMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError, useBranches} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId, yupString} from 'shared';

import {PairDeviceStep} from '../../types/PairDeviceStep';

type FormValues = {
  name: string;
  branches: string[];
};

interface SetupDeviceStepProps extends RequiredTestIdProps {
  setCurrentStep: (step: PairDeviceStep) => void;
  setPairedDeviceId: (pairedDeviceId: string) => void;
}

export function SetupDeviceStep(props: SetupDeviceStepProps) {
  const {branchOptions} = useBranches();

  const [pairDevice] = usePairDeviceMutation();

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) =>
    await pairDevice({pairDeviceRequestBody: values})
      .unwrap()
      .then(({id}) => props.setPairedDeviceId(id))
      .then(() => props.setCurrentStep('unpaired'))
      .catch(handleApiError);

  return (
    <>
      <VStack spacing={4} align="center" justify="center">
        <Icon size={20} value="content/computer_home_sync" color="text.white" />
        <Heading size={3}>{i18n.t('entity.settings.labels.addDevice')}</Heading>
        <Box width={140}>
          <Text size="small" align="center">
            {i18n.t('entity.settings.labels.createDeviceDescription')}
          </Text>
        </Box>
      </VStack>

      <Space vertical={10} />

      <Form<FormValues> schema={schema} onSubmit={handleSubmit}>
        {(control, formApi) => (
          <>
            <VStack spacing={4}>
              <FormField
                control={control}
                type="text"
                label={i18n.t('entity.settings.labels.deviceName')}
                name="name"
                isRequired
                data-testid={suffixTestId('deviceName', props)}
              />

              <HStack justify="space-between" align="flex-end" spacing={2}>
                <Box flexGrow={1}>
                  <FormField
                    control={control}
                    type="multiChoice"
                    label={i18n.t('entity.branch.labels.branches')}
                    name="branches"
                    options={branchOptions}
                    menuInPortal
                    isRequired
                    data-testid={suffixTestId('branches', props)}
                  />
                </Box>
                <Button
                  variant="secondary"
                  title={i18n.t('entity.document.actions.addAllBranches')}
                  data-testid={suffixTestId('addAllBranches', props)}
                  onClick={() =>
                    formApi.setValue(
                      'branches',
                      branchOptions.map((item) => item.value)
                    )
                  }
                />
              </HStack>
            </VStack>

            <Space vertical={10} />

            <Center>
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.continue')}
                data-testid={suffixTestId('continue', props)}
              />
            </Center>
          </>
        )}
      </Form>
    </>
  );
}

const schema = object({
  name: yupString.required(),
  branches: array().of(yupString).min(1, i18n.t('general.errors.mixed.required')).required(),
});
