import {GetDocument360RedirectUrlApiResponse, SAmlApiArg, SAmlApiResponse} from '../types/api';
import {GetDocument360RedirectUrlApiRes, GetDocument360RedirectUrlApiArg} from '../types/authApi';
import {omneticApi} from './baseApi/omneticApi';

export const authApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getDocument360RedirectUrl: build.query<
      GetDocument360RedirectUrlApiRes,
      GetDocument360RedirectUrlApiArg
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBaseQuery) {
        const doc360auth = await fetchWithBaseQuery({url: '/dms/v1/auth/doc360'});

        if (doc360auth.error) {
          throw doc360auth.error;
        }

        const authUrl = doc360auth.data as GetDocument360RedirectUrlApiResponse;
        const authAndRedirectUrl = `${authUrl.redirectUrl}&redirectUrl=${_arg.redirectTo}`;

        return {data: {redirectUrl: authAndRedirectUrl}};
      },
      providesTags: ['CurrentUser'],
    }),
    sAml: build.mutation<SAmlApiResponse, SAmlApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/auth/saml`,
        method: 'POST',
        body: queryArg.samlRequestBody,
      }),
    }),
  }),
});

export const {useLazyGetDocument360RedirectUrlQuery, useSAmlMutation} = authApi;
