import {Action, Card, DataStatus, Label, Separator, Tooltip} from 'platform/components';
import {
  Box,
  Center,
  Grid,
  HStack,
  Heading,
  Icon,
  Text,
  ThemeColorTextPath,
  VStack,
} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {Pattern, match} from 'ts-pattern';

import {useCallback} from 'react';

import {always, mergeAll} from 'ramda';
import {isNegative, isNotNilOrEmpty, isPositive} from 'ramda-adjunct';

import {GetArticleResponse, useGetArticleStockAvailabilityOverviewQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {Section} from '@omnetic-dms/shared';

import {TestIdProps, parseDate, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

type AvailableTextColor = Extract<ThemeColorTextPath, 'primary' | 'danger' | 'success'>;

interface AvailabilityProps extends TestIdProps {
  article: GetArticleResponse;
  handlingUnitLabel: string | null;
}

export function Availability(props: AvailabilityProps) {
  const params = useWarehouseParams();
  const dateFormatter = useDateTimeFormatter();

  const {
    data: articleAvailabilityOverview,
    isLoading: isArticleAvailabilityOverviewLoading,
    isError: isArticleAvailabilityOverviewError,
  } = useGetArticleStockAvailabilityOverviewQuery({
    warehouseId: params.warehouseId,
    articleId: params.articleId,
  });

  const isLoading = isArticleAvailabilityOverviewLoading;
  const isError = isArticleAvailabilityOverviewError;

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {articleId: params.articleId}]),
    [params]
  );

  if (!articleAvailabilityOverview) {
    return (
      <Center width="100%">
        <DataStatus isLoading={isLoading} isError={isError} minHeight="100vh" />
      </Center>
    );
  }

  const lastOrderDate = isNotNilOrEmpty(articleAvailabilityOverview.lastOrder)
    ? dateFormatter('dateShort', parseDate(articleAvailabilityOverview.lastOrder))
    : '-';

  const lastReceptionDate = isNotNilOrEmpty(articleAvailabilityOverview.lastReception)
    ? dateFormatter('dateShort', parseDate(articleAvailabilityOverview.lastReception))
    : '-';

  const lastDispatchDate = isNotNilOrEmpty(articleAvailabilityOverview.lastDispatch)
    ? dateFormatter('dateShort', parseDate(articleAvailabilityOverview.lastDispatch))
    : '-';

  const availabilityTextColor = match<number, AvailableTextColor>(
    articleAvailabilityOverview.availableQuantity
  )
    .with(Pattern.when(isPositive), always('success'))
    .with(Pattern.when(isNegative), always('danger'))
    .otherwise(always('primary'));

  const availabilityOverviewActions: Action[] = [
    {
      type: 'button',
      variant: 'link',
      leftIcon: 'image/remove_red_eye',
      title: i18n.t('entity.warehouse.labels.inventories'),
      onClick: () => {},
    },
  ];

  const transitTooltip = (
    <VStack spacing={4}>
      <Text size="xSmall" color="white">
        {i18n.t('entity.warehouse.labels.transit.description')}
      </Text>
      <VStack>
        <Text size="xSmall" alternative color="white">
          {i18n.t('entity.warehouse.labels.transit.forRequests')}
        </Text>
        <Text size="xSmall" color="white">
          {i18n.t('entity.warehouse.labels.transit.forRequestsDescription')}
        </Text>
      </VStack>
      <VStack>
        <Text size="xSmall" alternative color="white">
          {i18n.t('entity.warehouse.labels.transit.free')}
        </Text>
        <Text size="xSmall" color="white">
          {i18n.t('entity.warehouse.labels.transit.freeDescription')}
        </Text>
      </VStack>
    </VStack>
  );

  const inTransitQuantity = `${articleAvailabilityOverview.inTransitForRequestsQuantity ?? 0} ${
    props.handlingUnitLabel
  } / ${articleAvailabilityOverview.inTransitOnStockQuantity ?? 0} ${props.handlingUnitLabel}`;

  return (
    <Section>
      <VStack spacing={4}>
        <Card
          title={i18n.t('entity.warehouse.labels.availabilityOverview')}
          actions={availabilityOverviewActions}
        >
          <VStack>
            <Grid columns={5}>
              <VStack>
                <Label>{i18n.t('entity.warehouse.labels.available')}</Label>
                <Heading color={availabilityTextColor} size={1}>{`${
                  articleAvailabilityOverview.availableQuantity ?? 0
                } ${props.handlingUnitLabel}`}</Heading>
              </VStack>
              <VStack>
                <Label>{i18n.t('entity.warehouse.labels.stock')}</Label>
                <Heading size={1}>
                  {`${articleAvailabilityOverview.stockQuantity ?? 0} ${props.handlingUnitLabel}`}
                </Heading>
              </VStack>
              <VStack>
                <Label>{i18n.t('entity.warehouse.labels.reserved')}</Label>
                <Heading size={1}>
                  {`${articleAvailabilityOverview.reservedQuantity ?? 0} ${
                    props.handlingUnitLabel
                  }`}
                </Heading>
              </VStack>
              <VStack>
                <Label>{i18n.t('entity.warehouse.labels.pendingReturnToSupplier')}</Label>
                <Heading size={1}>
                  {`${articleAvailabilityOverview.pendingCreditNoteQuantity ?? 0} ${
                    props.handlingUnitLabel
                  }`}
                </Heading>
              </VStack>
              <VStack>
                <HStack spacing={1}>
                  <Label>{i18n.t('entity.warehouse.labels.inTransit')}</Label>
                  <Tooltip description={transitTooltip}>
                    <Icon value="action/help" color="text.secondary" size={4} />
                  </Tooltip>
                </HStack>
                <Heading size={1}>{inTransitQuantity}</Heading>
              </VStack>
            </Grid>
            <Separator />
            <VStack spacing={4}>
              <Grid columns={4}>
                <VStack>
                  <Label>{i18n.t('entity.warehouse.labels.averageDeliveryTime')}</Label>
                  <Text>TODO</Text>
                </VStack>
                <VStack>
                  <Label>{i18n.t('entity.warehouse.labels.lastOrder')}</Label>
                  <Text>{lastOrderDate}</Text>
                </VStack>
                <VStack>
                  <Label>{i18n.t('entity.warehouse.labels.lastReception')}</Label>
                  <Text>{lastReceptionDate}</Text>
                </VStack>
                <VStack>
                  <Label>{i18n.t('entity.warehouse.labels.lastDispatch')}</Label>
                  <Text>{lastDispatchDate}</Text>
                </VStack>
              </Grid>
              <Grid columns={4}>
                <VStack>
                  <Label>{i18n.t('entity.warehouse.labels.minimumStockLevels')}</Label>
                  <Text>TODO</Text>
                </VStack>
                <VStack>
                  <Label>{i18n.t('entity.warehouse.labels.maximumStockLevels')}</Label>
                  <Text>TODO</Text>
                </VStack>
                <VStack>
                  <Label>{i18n.t('entity.warehouse.labels.stockAnalysis')}</Label>
                  <Text>TODO</Text>
                </VStack>
              </Grid>
            </VStack>
          </VStack>
        </Card>
        <Card title={i18n.t('entity.warehouse.labels.otherWarehouses')}>
          <Box height="38vh">
            <DataGrid
              gridCode="warehouse-other-availability"
              queryModifier={queryModifier}
              data-testid={suffixTestId('otherAvailability', props)}
            />
          </Box>
        </Card>
      </VStack>
    </Section>
  );
}
