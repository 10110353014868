import {showNotification} from 'platform/components';
import {Box, HStack, Space, Text} from 'platform/foundation';

import {FC, PropsWithChildren, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {clone, mergeDeepRight} from 'ramda';

import {selectTenant, usePutTenantMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {CommonSkeleton} from '@omnetic-dms/shared';
import {InlineEditingProvider, PutTenantRequestBody, TypographyCard} from '@omnetic-dms/teas';

import {BillingInformation} from './BillingInformation';
import {ContactInfoForm} from './ContactInfoForm';
import {ContactPersons} from './ContactPersons';
import {PublicContacts} from './PublicContacts';
import {SystemInformation} from './SystemInformation';

export const TenantProfile: FC<PropsWithChildren<any>> = () => {
  const {data: tenant} = useSelector(selectTenant);
  const [putTenant] = usePutTenantMutation();

  const onTenantChange = useCallback(
    (values: PutTenantRequestBody) => {
      const putTenantRequestBody = mergeDeepRight(clone(tenant || {}), {...values});

      putTenant({putTenantRequestBody}).catch((error) =>
        showNotification.error(error.data?.error?.message || error.toString())
      );
    },
    [putTenant, tenant]
  );

  if (!tenant) {
    return <CommonSkeleton />;
  }

  return (
    <section>
      <InlineEditingProvider
        value={{updateMethod: onTenantChange as any, editedValue: {data: tenant, tenant}}}
      >
        <Text color="secondary" size="small">
          {i18n.t('page.settings.labels.tenantProfileDescription')}
        </Text>
        <Space vertical={4} />
        <HStack spacing={4}>
          <Box flex={1}>
            <TypographyCard title={i18n.t('entity.person.labels.contactInformation')}>
              <ContactInfoForm />
              <PublicContacts />
              <Space vertical={8} />
              <ContactPersons />
            </TypographyCard>
          </Box>
          <Box flex={1}>
            <BillingInformation redirectQuery="from=tenant" />

            <Box paddingTop={4}>
              <SystemInformation />
            </Box>
          </Box>
        </HStack>
      </InlineEditingProvider>
    </section>
  );
};
