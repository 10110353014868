import {useSubscription} from '@apollo/client';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {captureMessage} from '@sentry/browser';
import {
  DeleteDialog,
  ProgressBar,
  showNotification,
  Upload,
  UploadState,
  useDialog,
} from 'platform/components';
import {Box, Center, Grid} from 'platform/foundation';
import {Lightbox, LightboxVideo, useLightbox} from 'platform/lightbox';
import {RcFile} from 'rc-upload/lib/interface';

import {CSSProperties, useState} from 'react';
import {useSelector} from 'react-redux';

import {isNil, isNotNil} from 'ramda';

import {
  AuditDataVideoAssetsFilesBody,
  SEND_NOTIFICATION_SUBSCRIPTION,
  useDeleteVideoAssetMutation,
  useLazyGetSentBackgroundNotificationQuery,
  useLazyGetVideoAssetQuery,
  useUploadVideoAssetMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {downloadFile, handleApiError, notificationTypes} from '@omnetic-dms/shared';

import {Nullish, randomLowerCaseUUID, suffixTestId, TestIdProps} from 'shared';

import {
  QueueVideoTask,
  RawFile,
  useVideoUploadQueueAction,
  useVideoUploadQueueState,
} from 'features/video-upload-queue';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {
  addVideoAssets,
  deleteVideoAssets,
  updateAuditState,
  updateVideoAssetData,
} from '../../../store/carAudit/reducer';
import {selectActiveAuditId} from '../../../store/carAudit/selectors';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {useAuditVideos} from '../hooks/useAuditVideos';
import {useConditionContext} from '../hooks/useConditionContext';
import {isFileVideo} from '../utils/isFileVideo';
import {AuditVideo} from './AuditVideo';

interface AuditVideosProps extends TestIdProps {
  paramDefinitionId: string;
  categoryId: string;
  ratio?: CSSProperties['aspectRatio'];
  isMandatory?: boolean;
}

type PendingVideo = {
  videoId: string;
  vehicleAuditVideoAssetId: string;
  filename: string;
  updatedAt: string;
};

const isVideoPlayable = (videoAsset: AuditDataVideoAssetsFilesBody) =>
  isNotNil(videoAsset.originUrl) ||
  (videoAsset.variants?.filter((variant) => isNotNil(variant.url)).length ?? 0) > 0;

const precedingPlayableVideos = (videoAssets: AuditDataVideoAssetsFilesBody[], index: number) =>
  videoAssets.slice(0, index).filter(isVideoPlayable).length;

export function AuditVideos(props: AuditVideosProps) {
  const {videoAssets} = useAuditVideos(props.categoryId, props.paramDefinitionId);
  const {isDisabledForUser, handleChangeCategory} = useConditionContext();
  const auditId = useSelector(selectActiveAuditId);
  const dispatch = useThunkDispatch();
  const [uploadState, setUploadState] = useState<UploadState>(UploadState.Idle);
  const [pendingVideos, setPendingVideos] = useState<PendingVideo[]>([]);
  const {tasks, uploadProgress} = useVideoUploadQueueState();
  const {handleMultiMultiPartUpload} = useVideoUploadQueueAction();
  const [getVideoAsset] = useLazyGetVideoAssetQuery();
  const [uploadVideoAsset] = useUploadVideoAssetMutation();
  const [deleteVideoAsset] = useDeleteVideoAssetMutation();
  const [getBackgroundNotification] = useLazyGetSentBackgroundNotificationQuery();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, {storage: videoToDelete}] =
    useDialog<LightboxVideo>();

  const [lightboxUploadImagesGalleryControls, {onOpen: onUploadImagesGalleryOpen}] = useLightbox(
    `auditVideos-${props.categoryId}-${props.paramDefinitionId}`
  );

  const lightboxVideoAssets: LightboxVideo[] | Nullish = videoAssets
    .filter(isVideoPlayable)
    .map((asset) => ({
      ...asset,
      actions: [
        {
          id: 'deleteBtn',
          icon: 'action/delete',
          onClick: () => openDeleteDialog(asset),
        },
        {
          id: 'downloadBtn',
          icon: 'file/download',
          onClick: () => {
            if (isNil(asset.originUrl)) {
              showNotification.error(i18n.t('entity.inspection.errors.downloadFailed'));
              captureMessage('Error downloading video asset – originUrl is not defined', (scope) =>
                scope.setExtras({asset})
              );
            } else {
              downloadFile(asset.originUrl);
            }
          },
        },
      ],
    }));

  // Subscribe to notifications to know when to assign videos to audits and fetch generated variants of uploaded videos
  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data: subscription}) => {
      const backgroundNotificationId: string | null =
        subscription.data?.onSendNotification?.backgroundNotificationId;

      if (isNotNil(backgroundNotificationId)) {
        getBackgroundNotification({id: backgroundNotificationId})
          .unwrap()
          .then((notification) => {
            // Detect video processed successfully, so we can assign it to the audit
            if (notification.type === notificationTypes.FILE_STORAGE_FILE_UPLOADED) {
              const {fileId, fileStatus, fileType} = notification.payload;
              const pendingVideo = pendingVideos?.find(
                (pendingVideo) => pendingVideo.videoId === fileId
              );

              if (isNotNil(pendingVideo)) {
                onVideoProcessed(pendingVideo, fileStatus, fileType);
              }
            }

            // Detect a new video variant was generated, so we can update the cover and/or the preview
            if (notification.type === notificationTypes.FILE_STORAGE_FILE_VARIANT_UPLOADED) {
              const videoAssetId = videoAssets?.find(
                (asset) => asset.videoId === notification.payload.originalFileId
              )?.id;

              if (isNotNil(videoAssetId) && notification.payload.fileStatus === 'success') {
                onVideoVariantGenerated(videoAssetId);
              }
            }
          })
          .catch(handleApiError);
      }
    },
  });

  const onVideoProcessed = (pendingVideo: PendingVideo, fileStatus: string, fileType: string) => {
    if (isNil(auditId)) {
      throw new Error('Audit ID is not defined');
    }

    if (!(fileStatus === 'success' && fileType === 'video')) {
      showNotification.error(
        i18n.t('entity.inspection.errors.processingFailed', {
          filename: pendingVideo.filename,
        })
      );
      setPendingVideos((videos) =>
        videos.filter((video) => video.videoId !== pendingVideo.videoId)
      );
    }

    const options = {
      auditId,
      categoryId: props.categoryId,
      paramDefinitionId: props.paramDefinitionId,
      updatedAt: pendingVideo.updatedAt,
      vehicleAuditVideoAssetId: pendingVideo.vehicleAuditVideoAssetId,
      videoId: pendingVideo.videoId,
    };

    uploadVideoAsset({auditId, body: options})
      .unwrap()
      .then((response) => {
        // Update redux state
        dispatch(
          addVideoAssets({
            categoryId: props.categoryId,
            paramDefinitionId: props.paramDefinitionId,
            assets: [{id: response.id, videoId: pendingVideo.videoId}],
          })
        );
        onVideoUploadedOrDeleted();
        showNotification.success(
          i18n.t('entity.inspection.labels.uploadCompleted', {
            filename: pendingVideo.filename,
          })
        );
      })
      .catch((error: FetchBaseQueryError) => {
        showNotification.error(
          i18n.t('entity.inspection.errors.uploadFailed', {
            filename: pendingVideo.filename,
          })
        );
        handleApiError(error, {silent: true});
      })
      .finally(() => {
        setPendingVideos((videos) =>
          videos.filter((video) => video.videoId !== pendingVideo.videoId)
        );
      });
  };

  const onVideoVariantGenerated = (videoAssetId: string) => {
    if (isNil(auditId)) {
      throw new Error('Audit ID is not defined');
    }

    getVideoAsset({auditId, videoAssetId})
      .unwrap()
      .then((response) => {
        dispatch(
          updateVideoAssetData({
            categoryId: props.categoryId,
            paramDefinitionId: props.paramDefinitionId,
            videoId: response.videoId,
            asset: response,
          })
        );
      })
      .catch(handleApiError);
  };

  // Uploading or deleting the video the status of the audit should be changed to in progress
  const onVideoUploadedOrDeleted = () => {
    // This is not RTK Query – unwrap() is not available
    // eslint-disable-next-line no-restricted-syntax
    handleChangeCategory()
      .then(() => {
        dispatch(
          updateAuditState({
            state: LoadAuditDataResponseItemBody.state.IN_PROGRESS,
          })
        );
      })
      .catch(handleApiError);
  };

  const handleUploadFinished = (params: QueueVideoTask) => {
    const pendingVideo = {
      videoId: params.connectionFileId,
      vehicleAuditVideoAssetId: params.id,
      updatedAt: new Date().toISOString(),
      filename: params.name,
    };

    // We must wait for success notification from BE to be able to assign it to the audit
    setPendingVideos((videos) => [...videos, pendingVideo]);
  };

  const handleStartUpload = async (file: RcFile) => {
    if (!isFileVideo(file)) {
      showNotification.error(i18n.t('entity.inspection.errors.invalidFileType'));
      return;
    }

    setUploadState(UploadState.Uploading);

    const rawFile: RawFile = {
      id: randomLowerCaseUUID(),
      uri: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
      mimeType: file.type,
      connectionFileId: null,
    };

    await handleMultiMultiPartUpload(rawFile, handleUploadFinished);
    setUploadState(UploadState.Success);
  };

  const handleDeleteVideo = (videoAsset: {id: string; videoId: string}) => {
    if (isNil(auditId)) {
      throw new Error('Audit ID is not defined');
    }

    deleteVideoAsset({auditId, videoAssetId: videoAsset.id})
      .unwrap()
      .then(() => {
        dispatch(
          deleteVideoAssets({
            categoryId: props.categoryId,
            paramDefinitionId: props.paramDefinitionId,
            videoIds: [videoAsset.videoId],
          })
        );
        onVideoUploadedOrDeleted();
        showNotification.success(i18n.t('entity.inspection.labels.deleteVideoAssetCompleted'));
      })
      .catch(handleApiError);
  };

  return (
    <Grid columns={8}>
      <Lightbox
        data-testid={suffixTestId('audit-uploadVideosGallery', props)}
        controls={lightboxUploadImagesGalleryControls}
        videos={lightboxVideoAssets}
      />
      {videoAssets?.map((videoAsset, index) => (
        <AuditVideo
          key={videoAsset.id}
          videoAsset={videoAsset}
          ratio={props.ratio}
          isLoading={!isVideoPlayable(videoAsset)}
          isDisabled={isDisabledForUser}
          onOpenPreview={() =>
            onUploadImagesGalleryOpen(precedingPlayableVideos(videoAssets, index))
          }
          onDeleteVideo={handleDeleteVideo}
          data-testid={suffixTestId('auditVideo', props)}
        />
      ))}

      {tasks.map((task) => {
        const progress =
          (uploadProgress.find((progress) => (progress.id = task.id))?.progress ?? 0) / 100;

        return (
          <Box
            key={task.id}
            width="100%"
            height="100%"
            ratio={props.ratio}
            padding={2}
            border="1px dashed"
            borderColor="palettes.neutral.80.100"
            borderRadius="xSmall"
          >
            <Center height="100%">
              <ProgressBar
                percentage={progress || undefined}
                indeterminate={progress === 0}
                small
              />
            </Center>
          </Box>
        );
      })}

      {pendingVideos.map((pendingVideo) => (
        <Box
          key={pendingVideo.videoId}
          width="100%"
          height="100%"
          ratio={props.ratio}
          padding={2}
          border="1px dashed"
          borderColor="palettes.neutral.80.100"
          borderRadius="xSmall"
        >
          <Center height="100%">
            <ProgressBar indeterminate small />
          </Center>
        </Box>
      ))}

      <Box width="100%" height="100%" ratio={props.ratio} position="relative" overflow="hidden">
        <Upload
          size="default"
          accept="video/*"
          type="card"
          uploadIcon="image/video_call"
          uploadText={`${props.isMandatory ? '*' : ''} ${i18n.t('entity.video.labels.add')}`}
          errorIcon="navigation/cancel"
          customRequest={({onSuccess}) => {
            onSuccess?.('', new XMLHttpRequest());
          }}
          onStart={handleStartUpload}
          uploadState={uploadState}
          isMultiple
          isDisabled={isDisabledForUser}
          data-testid={suffixTestId('uploadControl', props)}
        />
      </Box>

      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={() => handleDeleteVideo(videoToDelete!)}
        isInLightbox
      />
    </Grid>
  );
}
