import {Action, Actions, Separator} from 'platform/components';
import {Box, Heading, HStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

export interface InvoiceDetailHeaderProps extends TestIdProps {
  title: string;
  actions?: Action[];
}

export function InvoiceDetailHeader(props: InvoiceDetailHeaderProps) {
  return (
    <>
      <Box paddingVertical={3} paddingHorizontal={4}>
        <HStack justify="space-between" align="center">
          <Heading size={3} data-testid={suffixTestId('heading', props)}>
            {props.title}
          </Heading>
          <Actions actions={props.actions} data-testid={suffixTestId('actions', props)} />
        </HStack>
      </Box>
      <Separator spacing={0} />
    </>
  );
}
