import {Card, DataStatus, Table, TableRow, Column} from 'platform/components';
import {Align, Box, Show, Text, VStack} from 'platform/foundation';

import {ReactNode} from 'react';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetCustomerContractIndividualPricesQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, RequiredTestIdProps, buildArray, suffixTestId} from 'shared';

import {useGetDiscountValue} from '../hooks/useGetDiscountValue';

interface ContractIndividualPricesTabProps extends RequiredTestIdProps {
  customerContractId: string;
}

export function ContractIndividualPricesTab(props: ContractIndividualPricesTabProps) {
  const getDiscountValue = useGetDiscountValue();

  const {data, isLoading, isError} = useGetCustomerContractIndividualPricesQuery({
    customerContractId: props.customerContractId,
  });

  return (
    <DataStatus
      isLoading={isLoading}
      isError={isError}
      isEmpty={
        isNilOrEmpty(data?.individualPriceLabour) && isNilOrEmpty(data?.individualPriceMaterial)
      }
      minHeight={100}
    >
      <VStack spacing={4}>
        <Show when={isNotNilOrEmpty(data?.individualPriceLabour)}>
          <Card title={i18n.t('entity.order.labels.work')}>
            <PriceTable data-testid={suffixTestId('work_table', props)}>
              {data?.individualPriceLabour?.map((price) => (
                <TableRow key={price?.id} data-testid={suffixTestId(`work_${price?.id}`, props)}>
                  <Cell text={price?.name} />
                  <Cell text={price?.positionNumber} />
                  <Cell text={price?.discountSettingsType?.name} />
                  <Cell text={getDiscountValue(price)} isRightAligned />
                </TableRow>
              ))}
            </PriceTable>
          </Card>
        </Show>
        <Show when={isNotNilOrEmpty(data?.individualPriceMaterial)}>
          <Card title={i18n.t('entity.order.labels.material')}>
            <PriceTable isMaterial data-testid={suffixTestId('material_table', props)}>
              {data?.individualPriceMaterial?.map((price) => (
                <TableRow
                  key={price?.id}
                  data-testid={suffixTestId(`material_${price?.id}`, props)}
                >
                  <Cell text={price?.name} />
                  <Cell text={price?.warehouse} />
                  <Cell text={price?.manufacturer} />
                  <Cell text={price?.manufacturerNumber} />
                  <Cell text={price?.discountSettingsType?.name} />
                  <Cell text={getDiscountValue(price)} isRightAligned />
                </TableRow>
              ))}
            </PriceTable>
          </Card>
        </Show>
      </VStack>
    </DataStatus>
  );
}

const HeaderCell = (props: {text: string; isLast?: boolean}) => (
  <Box
    height={14}
    backgroundColor="palettes.neutral.10.100"
    borderTop="1px solid"
    borderColor="general.separator"
  >
    <VStack justify="center" height="100%">
      <Box
        borderRight={props.isLast ? undefined : '1px solid'}
        borderColor="general.separator"
        paddingHorizontal={4}
      >
        <Align right={props.isLast}>
          <Text alternative color="secondary" size="small">
            {props.text}
          </Text>
        </Align>
      </Box>
    </VStack>
  </Box>
);

const Cell = (props: {text: string | Nullish; isRightAligned?: boolean}) => (
  <Box padding={4} height={10}>
    <VStack justify="center" height="100%">
      <Align right={props.isRightAligned}>
        <Text color="secondary" size="small">
          {props.text}
        </Text>
      </Align>
    </VStack>
  </Box>
);

interface PriceTableProps extends RequiredTestIdProps {
  isMaterial?: boolean;
  children: ReactNode;
}

const PriceTable = (props: PriceTableProps) => (
  <Table
    tableLayout="fixed"
    columns={buildArray<Column>([
      {
        element: <HeaderCell text={i18n.t('general.labels.name')} />,
      },
    ])
      .when(props.isMaterial, {
        element: <HeaderCell text={i18n.t('entity.warehouse.labels.warehouse')} />,
      })
      .when(props.isMaterial, {
        element: <HeaderCell text={i18n.t('entity.warehouse.labels.manufacturer')} />,
      })
      .add({
        element: <HeaderCell text={i18n.t('entity.customerContract.labels.positionNumber')} />,
      })
      .add({
        element: <HeaderCell text={i18n.t('entity.customerContract.labels.discountType')} />,
      })
      .add({
        element: (
          <HeaderCell text={i18n.t('entity.customerContract.labels.discountValue')} isLast />
        ),
        width: 49 as const,
      })}
    data-testid={suffixTestId('price-table', props)}
  >
    {props.children}
  </Table>
);
