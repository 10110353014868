import {getPixelsFromRem, Show, VStack} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {ReactNode, useMemo} from 'react';
import {OnChangeValue} from 'react-select';
import CreatableReactSelect from 'react-select/creatable';

import {defaultTo, isNil, reject} from 'ramda';
import {isNotArray} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../../types/FormControlProps';
import {OptionTypeBase} from '../../../types/OptionTypeBase';
import {HelperText} from '../../HelperText/HelperText';
import {Label} from '../../Label/Label';
import {useTranslationContext} from '../../TranslationProvider/TranslationContext';
import {ChoiceCommonProps} from '../types';
import {selectComponents} from '../utils/components';
import {formatOptionFieldLabel} from '../utils/formatOptionFieldLabel';
import {getChoiceCSS} from '../utils/getChoiceCSS';
import {getFlattenedOptions} from '../utils/getFlattenedOptions';
import {reactSelectStyles} from '../utils/reactSelectStyles';
import {ChoiceProvider} from './ChoiceProvider';

export interface CreatableMultiChoiceProps<ValueType>
  extends FormControlProps<ValueType[] | null>,
    TestIdProps,
    ChoiceCommonProps<ValueType[], ValueType> {
  onCreateOption?: (inputValue: string) => void;
  formatCreateLabel?: (inputValue: string) => ReactNode;
}

const rejectNil = reject(isNil);

export function CreatableMultiChoice<ValueType>(props: CreatableMultiChoiceProps<ValueType>) {
  const t = useTranslationContext();
  const theme = useTheme();

  const isInvalid = props.isInvalid || !!props.errorMessage;

  const handleChange = (options: OnChangeValue<OptionTypeBase<ValueType | Nullish>, true>) => {
    if (!options) {
      props.onChange?.(null);
      return;
    }

    props.onChange?.(rejectNil(options.map((v) => v.value)));
  };

  const getOptionsByValue = (value: ValueType[] | Nullish) => {
    if (isNotArray(value) || isNil(value)) {
      return null;
    }

    const flatOptions = getFlattenedOptions(props.options);

    return rejectNil(value.map((v) => flatOptions?.find((o) => o.value === v)));
  };

  // This is a workaround for an issue in react-select, when using multiselect with custom components
  // https://github.com/JedWatson/react-select/issues/5489
  const components = useMemo(() => selectComponents<ValueType, true>(), []);

  return (
    <VStack>
      <Label
        isRequired={props.isRequired}
        tooltip={props.tooltip}
        isRecommended={props.isRecommended}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <ChoiceProvider
        options={{
          'data-testid': props['data-testid'],
          hasOptionCheckbox: props.hasOptionCheckbox,
        }}
      >
        <CreatableReactSelect
          isMulti
          formatOptionLabel={props.formatOptionLabel ?? formatOptionFieldLabel}
          defaultValue={getOptionsByValue(props.defaultValue)}
          menuPortalTarget={props.menuInPortal ? document.body : undefined}
          value={getOptionsByValue(props.value)}
          name={props.name}
          placeholder={defaultTo(t('general.labels.select'), props.placeholder)}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onChange={handleChange}
          isDisabled={props.isDisabled}
          options={props.options}
          isClearable={!props.isNotClearable}
          isSearchable={props.isSearchable ?? true}
          components={components}
          menuPlacement={props.menuPlacement ?? 'auto'}
          maxMenuHeight={getPixelsFromRem(props.maxMenuHeight)}
          styles={reactSelectStyles<ValueType, true>(theme)}
          noOptionsMessage={props.noOptionsMessage}
          closeMenuOnScroll={props.closeMenuOnScroll}
          closeMenuOnSelect={props.closeMenuOnSelect}
          isLoading={props.isLoading}
          onCreateOption={props.onCreateOption}
          formatCreateLabel={props.formatCreateLabel}
          filterOption={props.filterOption}
          // Styling ReactSelect is problematic so we need to use the css prop
          // eslint-disable-next-line react/forbid-component-props
          css={getChoiceCSS(isInvalid, props.isDisabled, props.isRecommended)}
        />
      </ChoiceProvider>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
