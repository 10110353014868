import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {Alert, Button, DataStatus, Dropdown, DropdownItem, Separator} from 'platform/components';
import {Box, Heading, Hide, HStack, Link, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useState} from 'react';

import {isNil, isNotNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isPositive} from 'ramda-adjunct';

import {
  getApiErrorMessages,
  useCreatePriceReportRequestMutation,
  useGetTenantQuery,
  useGetUserQuery,
  useGetVehiclePriceReportsQuery,
  useSourcingAllowedCountryAndCurrencyQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, parseDate, suffixTestId, TestIdProps} from 'shared';

import {DEFAULT_COUNTRY} from '../../constants/country';
import {useCountry} from '../../hooks/useCountry';
import {getNaturalPersonFullName} from '../../utils/getNaturalPersonFullName';
import {handleApiError} from '../../utils/handleApiError';
import {PriceReportLine} from './components/PriceReportLine';
import {PriceReportWidgetLogo} from './components/PriceReportWidgetLogo';

interface PriceReportRequestWidgetProps extends TestIdProps {
  vehicleId: string | Nullish;
}

export function PriceReportRequestWidget(props: PriceReportRequestWidgetProps) {
  const formatDateTime = useDateTimeFormatter();
  const [error, setError] = useState<string | string[]>([]);
  const [createPriceReportRequest, {isLoading: isCreateLoading}] =
    useCreatePriceReportRequestMutation();
  const {data} = useSourcingAllowedCountryAndCurrencyQuery();
  const {data: tenant} = useGetTenantQuery();
  const [country, setCountry] = useState<string>(tenant?.country ?? DEFAULT_COUNTRY);
  const {getCountryName} = useCountry();

  const {data: priceReports = [], isLoading: isDataLoading} = useGetVehiclePriceReportsQuery(
    {vehicleId: props.vehicleId!},
    {skip: isNil(props.vehicleId)}
  );

  const [newestReport, ...olderReports] = priceReports;

  const {data: user} = useGetUserQuery(
    {id: newestReport?.createdBy ?? ''},
    {skip: isNilOrEmpty(newestReport?.createdBy) || isNil(props.vehicleId)}
  );

  if (isNil(props.vehicleId)) {
    return null;
  }

  const userFullName = getNaturalPersonFullName(user);

  const errors = Array.isArray(error) ? error : [error];

  const onCreateReport = () =>
    createPriceReportRequest({vehicleId: props.vehicleId!, body: {country}})
      .unwrap()
      .then((data) => window.open(data.url))
      .catch((e) =>
        handleApiError(e, {
          silent: true,
          callback: ({error}: {error: FetchBaseQueryError}) => setError(getApiErrorMessages(error)),
        })
      );

  return (
    <VStack spacing={2}>
      <Box backgroundColor="palettes.neutral.10.100" borderRadius="medium" overflow="hidden">
        <HStack>
          <PriceReportWidgetLogo data-testid={props['data-testid']} />
          <Box padding={4} flex={1}>
            <VStack spacing={3}>
              <VStack spacing={2}>
                <HStack align="center" justify="space-between">
                  <Heading size={4}>{`${i18n.t('entity.priceReport.title')} - V2`}</Heading>
                  <HStack align="center" spacing={2}>
                    <Text size="xSmall" color="secondary">
                      {i18n.t('entity.priceReport.market')}
                    </Text>
                    <Dropdown
                      data-testid={suffixTestId('priceReportWidget-country', props)}
                      dropdownControl={
                        <Link
                          data-testid={suffixTestId('priceReportWidget-country', props)}
                          size="small"
                          title={getCountryName(country)}
                          rightIcon="hardware/keyboard_arrow_down"
                        />
                      }
                    >
                      {data?.countries?.map((code, index) => (
                        <DropdownItem
                          key={code}
                          data-testid={suffixTestId(`priceReportWidget-[${index}]`, props)}
                          label={getCountryName(code)}
                          onClick={() => setCountry(code)}
                        />
                      ))}
                    </Dropdown>
                  </HStack>
                </HStack>
                <Hide when={newestReport}>
                  <Text size="xSmall" color="secondary">
                    {i18n.t('entity.priceReport.description')}
                  </Text>
                </Hide>
              </VStack>
              <DataStatus isLoading={isDataLoading}>
                <VStack spacing={2}>
                  <Show when={newestReport}>
                    <HStack spacing={2}>
                      <Button
                        data-testid={suffixTestId('priceReportWidget-viewResults', props)}
                        variant="secondary"
                        rightIcon="action/launch"
                        onClick={() => window.open(newestReport!.url)}
                        title={i18n.t('entity.priceReport.viewResults')}
                      />
                      <Button
                        data-testid={suffixTestId('priceReportWidget-newReport', props)}
                        variant="secondary"
                        onClick={onCreateReport}
                        isLoading={isCreateLoading}
                        title={i18n.t('entity.priceReport.newReport')}
                      />
                    </HStack>
                    <HStack spacing={3}>
                      <Text size="xSmall" color="tertiary">
                        {`${i18n.t('entity.priceReport.recentReport')} ${
                          newestReport?.createdAt
                            ? formatDateTime('dateShort', parseDate(newestReport.createdAt))
                            : ''
                        }`}
                        {isNotNilOrEmpty(userFullName) ? `, ${userFullName}` : ''}
                      </Text>
                      <Show when={isNotNil(newestReport?.country)}>
                        <Text size="xSmall" color="secondary">
                          ({newestReport?.country})
                        </Text>
                      </Show>
                    </HStack>
                  </Show>
                  <Show when={isPositive(olderReports.length)}>
                    <Separator spacing={1} />
                    <VStack spacing={3}>
                      {olderReports.map((report, index) => (
                        <PriceReportLine
                          data-testid={suffixTestId(`priceReportWidget-[${index}]`, props)}
                          key={report.id ?? report.requestId}
                          report={report}
                        />
                      ))}
                    </VStack>
                  </Show>
                  <Hide when={newestReport}>
                    {/* Box to prevent button to go full width */}
                    <Box>
                      <Button
                        data-testid={suffixTestId('priceReportWidget-getReport', props)}
                        variant="secondary"
                        onClick={onCreateReport}
                        isLoading={isCreateLoading}
                        title={i18n.t('entity.priceReport.getReport')}
                      />
                    </Box>
                  </Hide>
                </VStack>
              </DataStatus>
            </VStack>
          </Box>
        </HStack>
      </Box>
      {errors.map((error, index) => (
        <Alert
          data-testid={suffixTestId(`priceReportWidget-errorAlert-[${index}]`, props)}
          key={error}
          type="inline"
          variant="warning"
          message={error}
        />
      ))}
    </VStack>
  );
}
