import {useMemo} from 'react';

import {uniq} from 'ramda';
import {concatAll} from 'ramda-adjunct';

import {ActionDefinitionObject} from '../types/ActionDefinition';
import {RowActions} from '../types/RowData';

export const useBulkActions = (
  actionDefinitions: ActionDefinitionObject,
  selectedRowsActions: Array<RowActions>
): ActionDefinitionObject =>
  useMemo(() => {
    // just filter all action definitions that are marked as bulk action by property ActionDefinition.bulkAction
    const bulkActionDefs = Object.entries(actionDefinitions).reduce(
      (acc, [actionKey, actionDef]) => {
        if (actionDef.bulkAction) {
          acc[actionKey] = actionDef;
        }
        return acc;
      },
      {} as ActionDefinitionObject
    );

    // we need array of action keys, but only actions that are common for all selected rows
    const actionKeys = selectedRowsActions.map((a) => [...a.primary, ...a.secondary]);
    const rowActionUnion = uniq(concatAll(actionKeys) ?? []);
    const filteredRowActionUnion = rowActionUnion.filter((actionKey) =>
      actionKeys.every((a) => a.includes(actionKey))
    );

    // filter actions definitions based on common action rows
    return Object.entries(bulkActionDefs).reduce((acc, [actionKey, actionDef]) => {
      if (filteredRowActionUnion.includes(actionKey)) {
        acc[actionKey] = actionDef;
      }
      return acc;
    }, {} as ActionDefinitionObject);
  }, [actionDefinitions, selectedRowsActions]);
