import {FC} from 'react';

import {VehicleSeriesDefinitionFormProps} from '../types';
import {VehicleSeriesDefinitionContextProvider} from './VehicleSeriesDefinitionContextProvider';
import {VehicleSeriesDefinitionFormWrapper} from './VehicleSeriesDefinitionFormWrapper';

export const VehicleSeriesDefinitionForm: FC<VehicleSeriesDefinitionFormProps> = ({
  vehicleTypes,
  seriesList,
  branchList,
  isSystem,
  ...rest
}) => (
  <VehicleSeriesDefinitionContextProvider
    branchList={branchList}
    seriesList={seriesList}
    vehicleTypes={vehicleTypes}
    isSystem={isSystem}
  >
    <VehicleSeriesDefinitionFormWrapper {...rest} />
  </VehicleSeriesDefinitionContextProvider>
);
