import {Heading, Space} from 'platform/foundation';

import {FC, useCallback, useRef, useState} from 'react';

import {useOnMount} from 'shared';

import {
  MuiGridStyled,
  ProgressLine,
  ProgressWrapper,
  UploadingImageContentWrapper,
  UploadingImageStyle,
  UploadingImageWrapper,
} from './styles';
import {ProgressBarProps, UploadingFileType, UploadingImageProps} from './types';

const reloadTimer = 125;

export const UploadingImage: FC<UploadingImageProps> = ({refImages}) => {
  const latestImages = useRef<UploadingFileType[]>([]);
  const [upImages, setUpImages] = useState<(UploadingFileType & {id: string})[]>([]);

  const handleSetImages = useCallback(() => {
    const newImages = Object.entries(refImages.current.photos).map((im) => ({
      ...im[1],
      id: im[0],
    }));

    setUpImages(newImages);
    latestImages.current = newImages;
  }, []);

  useOnMount(() => {
    const update = throttle(handleSetImages, reloadTimer);

    refImages.current.updateCb = () => {
      if (Object.values(refImages.current.photos).length !== latestImages.current.length) {
        handleSetImages();
      } else {
        update();
      }
    };
  });

  return (
    <>
      {upImages.map((im) => (
        <SingleImage {...im} key={im.id} />
      ))}
    </>
  );
};

const SingleImage: FC<UploadingFileType> = ({name, progress}) => (
  <MuiGridStyled>
    <UploadingImageWrapper>
      <UploadingImageStyle>
        <UploadingImageContentWrapper>
          <Heading isSingleLine size={6} alternative>
            {name}
          </Heading>
          <Space vertical={1} />
          <ProgressBar percentage={progress} />
        </UploadingImageContentWrapper>
      </UploadingImageStyle>
    </UploadingImageWrapper>
  </MuiGridStyled>
);

const ProgressBar: FC<ProgressBarProps> = ({percentage}) => (
  <ProgressWrapper>
    <ProgressLine percentage={percentage} />
  </ProgressWrapper>
);

/**
 * Executes function once per defined period - should be used to minimize function calling
 * This function is not capable of returning values
 * @param func - function that should be called
 * @param timer - throttle timer - how long it should wait until next call is made
 */
const throttle = <T extends unknown[]>(
  func: (...params: T) => void,
  timer: number
): ((...args: T) => void) => {
  let latestArgs: T;
  let throttled = false;
  let repeat = false;

  const _throttle = () => {
    throttled = true;
    repeat = false;

    func(...latestArgs);

    setTimeout(() => {
      if (repeat) {
        _throttle();
      } else {
        throttled = false;
      }
    }, timer);
  };

  return (...args) => {
    latestArgs = args;
    if (!throttled) {
      _throttle();
    } else {
      repeat = true;
    }
  };
};
