import {closeCurrentDialog, openConfirmDialog, openDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray, noop} from 'ramda-adjunct';

import {
  usePatchTireManufacturerSetActiveMutation,
  usePatchTireManufacturerSetDefaultMutation,
  usePatchTireManufacturerSetInactiveMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {BrandForm} from './components/BrandForm';

type ReceiveTireManufacturerRowData = {
  name?: {value?: string};
  isActive?: {value?: boolean};
  isDefault?: {value?: boolean};
};

export function TireInventoryManufacturers() {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [patchTireManufacturerSetActive] = usePatchTireManufacturerSetActiveMutation();
  const [patchTireManufacturerSetDefault] = usePatchTireManufacturerSetDefaultMutation();
  const [patchTireManufacturerSetInactive] = usePatchTireManufacturerSetInactiveMutation();

  const handleAddBrand = () => {
    openDialog(
      <BrandForm
        onDiscard={closeCurrentDialog}
        onSave={() => {
          closeCurrentDialog();
          refreshDataGrid();
        }}
        data-testid={testIds.settings.tireManufacturers('addBrandForm')}
      />,
      {
        title: i18n.t('entity.tiresInventory.labels.newBrand'),
        size: 'small',
      }
    );
  };

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    const castedRowData = rowData as ReceiveTireManufacturerRowData;

    match(actionKey)
      .with('edit', 'redirectDetail', () =>
        openDialog(
          <BrandForm
            onDiscard={closeCurrentDialog}
            onSave={() => {
              closeCurrentDialog();
              refreshData();
            }}
            defaultValue={{
              id,
              name: castedRowData.name?.value ?? '',
              isActive: !!castedRowData.isActive?.value,
              isDefault: !!castedRowData.isDefault?.value,
            }}
            data-testid={testIds.settings.tireManufacturers('addBrandForm')}
          />,
          {
            title: i18n.t('entity.tiresInventory.labels.editBrand'),
            size: 'small',
          }
        )
      )
      .with('setInactive', () =>
        openConfirmDialog({
          text: i18n.t('entity.tiresInventory.labels.setInactive'),
          onConfirm: () =>
            patchTireManufacturerSetInactive({codelistId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('setActive', () =>
        openConfirmDialog({
          text: i18n.t('entity.tiresInventory.labels.setActive'),
          onConfirm: () =>
            patchTireManufacturerSetActive({codelistId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('setDefault', () =>
        openConfirmDialog({
          text: i18n.t('entity.tiresInventory.labels.setDefault'),
          onConfirm: () =>
            patchTireManufacturerSetDefault({codelistId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .otherwise(noop);
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.tiresInventory.labels.tireManufacturers'),
        actions: [
          {
            type: 'button',
            title: i18n.t('entity.tiresInventory.labels.newBrand'),
            onClick: handleAddBrand,
          },
        ],
      }}
      description={i18n.t('entity.tiresInventory.labels.tireManufacturersDescription')}
      data-testid={testIds.settings.tireManufacturers('template')}
    >
      <DataGrid
        ref={dataGridRef}
        gridCode="tire-manufacturer"
        actionCallback={actionCallback}
        data-testid={testIds.settings.tireManufacturers('datagrid')}
      />
    </SettingsTemplate>
  );
}
