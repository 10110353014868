import styled from 'styled-components';
import styledMap from 'styled-map';

const inputBorder = styledMap`
	default: 1px solid ${({theme}) => theme.colors.palettes.neutral[70][100]};
	inline: 1px solid transparent;
	invalid: 1px solid ${({theme}) => theme.colors.palettes.red[70][100]} !important;
`;

const wrapperWidth = styledMap`
	default: 200px;
	fullWidth: 100%;
`;

const inputRadius = styledMap`
	default: 0px 4px 4px 0px;
	inline: ${({theme}) => theme.radii.small};
`;

const inputPadding = styledMap`
	default: 6px 8px;
	inline: 2px 4px
`;

const inputHeight = styledMap`
	default: 32px;
	inline: 22px;
`;

type StyleProps = {
  invalid?: boolean;
  inline?: boolean;
  isFocused?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
};

export const Icon = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -1px;

  /* Neutrals/N10 */
  background: ${({theme}) => theme.colors.palettes.neutral[10][100]};

  /* Neutrals/N70 */
  border: ${inputBorder};
  border-radius: 4px 0 0 4px;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};

  &:hover {
    border: 1px solid ${({theme}) => theme.colors.palettes.blue[60][100]};
    z-index: ${({theme}) => theme.zIndices.CONTENT_LOADER};
  }
`;

type StyledInputProps = {
  inline: boolean;
  invalid: boolean;
  fullWidth: boolean;
};

export const StyledInput = styled.input<StyledInputProps>`
  letter-spacing: 0.16px;
  border: ${inputBorder};
  border-radius: ${inputRadius};
  padding: ${inputPadding};
  background: ${({inline, theme}) =>
    inline ? 'transparent' : theme.colors.palettes.white[10][100]};
  width: 100%;

  &:hover {
    border: 1px solid
      ${({theme, inline}) => (inline ? 'transparent' : theme.colors.palettes.blue[60][100])};
    ${({theme, inline}) =>
      inline &&
      `
			background-color: ${theme.colors.palettes.neutral[20][100]};
		`}
  }

  &:focus {
    background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
    outline: none;
    ${({theme, inline}) =>
      inline &&
      `
			border: 1px solid ${theme.colors.palettes.blue[60][100]};
		`}
  }

  &::placeholder {
    color: ${({theme}) => theme.colors.palettes.neutral[80][100]};
  }
`;

export const DatePickerInputContainer = styled.div<Pick<StyledInputProps, 'fullWidth'>>`
  width: ${wrapperWidth};
`;

interface WrapperProps extends Pick<StyledInputProps, 'inline'> {
  disabled?: boolean;
}

export const opacity = styledMap`
	default: 1;
	disabled: 0.6;
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  height: ${inputHeight};
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'all')};
  opacity: ${opacity};
`;
