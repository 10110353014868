import {isNotNil} from 'ramda';

import {GetVehicleInsuranceApiResponse} from '@omnetic-dms/api';

import {getApiDateString, parseDate} from 'shared';

import {VehicleInsuranceFormValues} from '../types/VehicleInsuranceFormValues';

export const mapFormValues = (
  data?: GetVehicleInsuranceApiResponse
): VehicleInsuranceFormValues => ({
  isCustomerInsuranceEnabled: isNotNil(data?.customerInsurance),
  liabilityInsuranceCompany: data?.customerInsurance?.liabilityInsurance?.institutionId ?? null,
  liabilityInsuranceDate: parseDate(data?.customerInsurance?.liabilityInsurance?.date),
  liabilityInsuranceNumber: data?.customerInsurance?.liabilityInsurance?.contractNumber ?? null,
  collisionInsuranceCompany: data?.customerInsurance?.collisionInsurance?.institutionId ?? null,
  collisionInsuranceDate: parseDate(data?.customerInsurance?.collisionInsurance?.date),
  collisionInsuranceNumber: data?.customerInsurance?.collisionInsurance?.contractNumber ?? null,
  customerInsuranceNote: data?.customerInsurance?.note ?? null,
  isFleetInsuranceEnabled: isNotNil(data?.fleetInsurance),
  fleetInsuranceNote: data?.fleetInsurance?.note ?? null,
  fleetInsuranceDate: parseDate(data?.fleetInsurance?.date),
});

export const mapApiValues = (data: VehicleInsuranceFormValues): GetVehicleInsuranceApiResponse => ({
  customerInsurance: data.isCustomerInsuranceEnabled
    ? {
        liabilityInsurance: {
          institutionId: data.liabilityInsuranceCompany,
          date: isNotNil(data.liabilityInsuranceDate)
            ? getApiDateString(data.liabilityInsuranceDate)
            : null,
          contractNumber: data.liabilityInsuranceNumber,
        },
        collisionInsurance: {
          institutionId: data.collisionInsuranceCompany,
          date: isNotNil(data.collisionInsuranceDate)
            ? getApiDateString(data.collisionInsuranceDate)
            : null,
          contractNumber: data.collisionInsuranceNumber,
        },
        note: data.customerInsuranceNote,
      }
    : null,
  fleetInsurance: data.isFleetInsuranceEnabled
    ? {
        note: data.fleetInsuranceNote,
        date: isNotNil(data.fleetInsuranceDate) ? getApiDateString(data.fleetInsuranceDate) : null,
      }
    : null,
});
