import {z} from 'zod';

import {FileDataSchema} from '../types/common/FileData';
import {Money, MoneySchema} from '../types/common/Money';
import {NoteRequestBody, NoteResponseBody} from '../types/common/Note';
import {BigDecimal, BigDecimalSchema, Currency} from '../types/common/Price';
import {TemplateListItemResponseBody, TemplateListItemResponseBodySchema} from './api';
import {
  ContractInformationResponseBodySchema,
  IdentityCardResponseBodySchema,
  PersonResponseBodySchema,
} from './customer';

export const TemplatesListItemResponseBodySchema = z.object({
  type: z.string(),
  templates: z.array(TemplateListItemResponseBodySchema),
});
export type TemplatesListItemResponseBody = z.infer<typeof TemplatesListItemResponseBodySchema>;
export type SplitCheckoutOrderApiResponse = /** status 200  */ CheckoutResponseBody;
export type SplitCheckoutOrderApiArg = {
  checkoutId: string;
  orderId: string;
  orderSplitRequestBody: OrderSplitRequestBody;
};
export type AddCheckoutOrderPurchasePaymentApiResponse = /** status 201  */ CheckoutResponseBody;
export type AddCheckoutOrderPurchasePaymentApiArg = {
  checkoutId: string;
  orderId: string;
};
export type DeleteCheckoutOrderPurchasePaymentApiResponse = /** status 200  */ CheckoutResponseBody;
export type DeleteCheckoutOrderPurchasePaymentApiArg = {
  checkoutId: string;
  orderId: string;
  paymentId: string;
};
export const OrderDiscriminatorSchema = z.enum([
  'PURCHASE',
  'SALE',
  'PURCHASE_BROKERAGE_SALE',
  'PURCHASE_BROKERAGE_FEES',
]);

export type OrderDiscriminator = z.infer<typeof OrderDiscriminatorSchema>;
export const CheckoutDeputyPersonResponseBodySchema = z.object({
  customerPerson: PersonResponseBodySchema,
  selectedIdentityCards: z.array(IdentityCardResponseBodySchema),
});
export type CheckoutDeputyPersonResponseBody = z.infer<
  typeof CheckoutDeputyPersonResponseBodySchema
>;
export const CheckoutContractInformationBodySchema = z.object({
  customerContractInformation: ContractInformationResponseBodySchema,
  selectedIdentityCards: z.array(IdentityCardResponseBodySchema),
  deputyPersons: z.array(CheckoutDeputyPersonResponseBodySchema),
});
export type CheckoutContractInformationBody = z.infer<typeof CheckoutContractInformationBodySchema>;
export const PaymentDiscriminatorSchema = z.enum(['DEPOSIT', 'BALANCE', 'PURCHASE']);

export type PaymentDiscriminator = z.infer<typeof PaymentDiscriminatorSchema>;
export const PaymentMethodSchema = z.enum(['CASH', 'BANK_TRANSFER', 'PAYMENT_CARD', 'OFFSET']);

export type PaymentMethod = z.infer<typeof PaymentMethodSchema>;
export const TenantBankAccountSchema = z.object({
  bankName: z.string(),
  accountName: z.string(),
  accountNumber: z.string(),
  isPrimary: z.boolean(),
  currencyCode: z.string().nullable(),
  swiftBic: z.string().nullable(),
  iban: z.string().nullable(),
});

export type TenantBankAccount = z.infer<typeof TenantBankAccountSchema>;
export const PaymentStateSchema = z.enum(['CONCEPT', 'PENDING', 'PAID']);

export type PaymentState = z.infer<typeof PaymentStateSchema>;
export const OrderPaymentResponseBodySchema = z.object({
  id: z.string(),
  paymentDiscriminator: PaymentDiscriminatorSchema,
  paymentMethod: PaymentMethodSchema,
  amount: MoneySchema.nullable(),
  payDate: z.string().nullable(),
  issuedOn: z.string().nullable(),
  dueDate: z.string().nullable(),
  customerBankAccountId: z.string().nullable(),
  tenantBankAccount: TenantBankAccountSchema.nullable(),
  cashRegisterId: z.string().nullable(),
  paymentState: PaymentStateSchema,
  files: z.array(FileDataSchema),
  comment: z.string().nullable(),
  invoices: z.array(FileDataSchema),
  proformaInvoices: z.array(FileDataSchema),
  taxableDocuments: z.array(FileDataSchema),
  cashReceipts: z.array(FileDataSchema),
  correctiveTaxDocuments: z.array(FileDataSchema),
  invoiceId: z.string().nullable(),
  proformaInvoiceId: z.string().nullable(),
  taxDocumentId: z.string().nullable(),
  internalInvoiceDocuments: z.array(FileDataSchema),
  internalCorrectiveTaxDocuments: z.array(FileDataSchema),
  createCashRegisterDocument: z.boolean().nullable(),
  cashRegisterDocumentId: z.string().nullable(),
  exchangeRate: z.string().nullable(),
  foreignCurrencyAmount: MoneySchema.nullable(),
  supplierInvoiceNumber: z.string().nullable(),
  dateOfTaxableSupply: z.string().nullable(),
});
export type OrderPaymentResponseBody = z.infer<typeof OrderPaymentResponseBodySchema>;
export const CheckoutPriceResponseBodySchema = z.object({
  withVat: MoneySchema,
  withoutVat: MoneySchema.nullish(),
  vat: MoneySchema.nullish(),
  vatRate: BigDecimalSchema.nullish(),
});
export type CheckoutPriceResponseBody = z.infer<typeof CheckoutPriceResponseBodySchema>;
export const OrderItemResponseBodySchema = z.object({
  id: z.string(),
  name: z.string(),
  price: CheckoutPriceResponseBodySchema,
  deductible: z.boolean(),
  itemContextId: z.string().nullable(),
});
export type OrderItemResponseBody = z.infer<typeof OrderItemResponseBodySchema>;
export const TypeOfSaleSchema = z.enum(['inland', 'inEurope', 'outOfEurope']);

export type TypeOfSale = z.infer<typeof TypeOfSaleSchema>;
export const OrderAdditionalCustomerResponseBodySchema = z.object({
  id: z.string(),
  customerId: z.string(),
  additionalCustomerType: z.enum(['operator', 'recipient']),
  contractInformationId: z.string(),
});

export type OrderAdditionalCustomerResponseBody = z.infer<
  typeof OrderAdditionalCustomerResponseBodySchema
>;
export const OrderResponseBodySchema = z.object({
  id: z.string(),
  orderDiscriminator: OrderDiscriminatorSchema,
  depositAllowed: z.boolean(),
  contractInformation: CheckoutContractInformationBodySchema,
  payments: z.array(OrderPaymentResponseBodySchema),
  items: z.array(OrderItemResponseBodySchema),
  totalPrice: CheckoutPriceResponseBodySchema,
  files: z.array(FileDataSchema),
  typeOfSale: TypeOfSaleSchema,
  addPurchasePaymentAllowed: z.boolean(),
  additionalCustomers: z.array(OrderAdditionalCustomerResponseBodySchema),
  foreignCurrencyPayment: z.boolean(),
  currency: z.string().nullable(),
  exchangeRateRatioAmount: z.number().nullable(),
});
export type OrderResponseBody = z.infer<typeof OrderResponseBodySchema>;
export const ItemsContextSchema = z.object({
  orderId: z.string().optional(),
  itemId: z.string().optional(),
  entityId: z.string().optional(),
  entityClass: z.string().optional(),
});

const TaxDocumentSchema = z.object({
  taxDocumentId: z.string(),
  taxDocumentType: z.string(),
  orderId: z.string(),
  paymentId: z.string(),
  itemsContext: z.array(ItemsContextSchema),
});

export type TaxDocument = z.infer<typeof TaxDocumentSchema>;
export const CheckoutResponseBodySchema = z.object({
  id: z.string(),
  orders: z.array(OrderResponseBodySchema),
  editContractInformationAllowed: z.boolean(),
  taxDocuments: z.array(TaxDocumentSchema),
});
export type CheckoutResponseBody = z.infer<typeof CheckoutResponseBodySchema>;
export type OrderSplitRequestBody = {
  itemContextIds: string[];
};
export type MergeCheckoutOrderApiResponse = /** status 200  */ CheckoutResponseBody;
export type MergeCheckoutOrderApiArg = {
  checkoutId: string;
  orderId: string;
  targetOrderId: string;
};
export type PatchCheckoutOrderForeignCurrencyPaymentApiResponse =
  /** status 200  */ OrderResponseBody;
export type PatchCheckoutOrderForeignCurrencyPaymentApiArg = {
  checkoutId: string;
  orderId: string;
  orderForeignCurrencyPaymentRequestBody: OrderForeignCurrencyPaymentRequestBody;
};
export type OrderForeignCurrencyPaymentRequestBody = {
  foreignCurrencyPayment: boolean;
  currency: Currency | null;
  exchangeRateRatioAmount: number | null;
};
export type PatchCheckoutOrderPaymentApiResponse = /** status 200  */ OrderResponseBody;
export type PatchCheckoutOrderPaymentApiArg = {
  checkoutId: string;
  orderId: string;
  paymentId: string;
  orderPaymentRequestBody: OrderPaymentRequestBody;
};
export type IssueCheckoutOrderPaymentApiResponse = /** status 200  */ OrderPaymentResponseBody;
export type IssueCheckoutOrderPaymentApiArg = {
  checkoutId: string;
  orderId: string;
  paymentId: string;
  issueCheckoutOrderPaymentRequestBody: IssueCheckoutOrderPaymentRequestBody;
  businessCaseId: string;
};
export type RecordPaidCheckoutOrderPaymentApiResponse = /** status 200  */ OrderPaymentResponseBody;
export type RecordPaidCheckoutOrderPaymentApiArg = {
  checkoutId: string;
  orderId: string;
  paymentId: string;
  orderPaymentRecordPaidRequestBody: OrderPaymentRecordPaidRequestBody;
};
export type IssueCheckoutOrderPaymentRequestBody = {
  issuedOn: string;
};
export type OrderPaymentRecordPaidRequestBody = {
  payDate: string | null;
};
export type CreateAdditionalCustomerRequestBody = {
  customerId: string;
  additionalCustomerType: 'operator' | 'recipient';
};
export type CreateCheckoutOrderAdditionalCustomerApiResponse =
  /** status 201  */ CheckoutResponseBody;
export type CreateCheckoutOrderAdditionalCustomerApiArg = {
  checkoutId: string;
  orderId: string;
  createAdditionalCustomerRequestBody: CreateAdditionalCustomerRequestBody;
};
export type AllowDepositApiArg = {checkoutId: string; orderId: string};
export type ForbitDepositApiArg = {checkoutId: string; orderId: string};
export type AllowDepositApiRes = {
  id: string;
  orders: OrderResponseBody[];
  editContractInformationAllowed: boolean;
  taxDocuments: TaxDocument[];
};
export type ForbitDepositApiRes = {
  id: string;
  orders: OrderResponseBody[];
  editContractInformationAllowed: boolean;
  taxDocuments: TaxDocument[];
};

export type DeleteDepositApiRes = unknown;
export type DeleteDepositApiArg = {
  checkoutId: string;
  orderId: string;
  depositId: string;
};

export type WithdrawCheckoutOrderPaymentByCashRegisterDocumentApiResponse =
  /** status 200  */ OrderPaymentResponseBody;
export type WithdrawCheckoutOrderPaymentByCashRegisterDocumentApiArg = {
  cashRegisterDocumentId: string;
};
export type DeleteCheckoutOrderAdditionalCustomerApiResponse = unknown;
export type DeleteCheckoutOrderAdditionalCustomerApiArg = {
  checkoutId: string;
  orderId: string;
  additionalCustomerId: string;
};
export type PatchCheckoutOrderAdditionalCustomerApiResponse =
  /** status 200  */ CheckoutResponseBody;
export type PatchCheckoutOrderAdditionalCustomerApiArg = {
  checkoutId: string;
  orderId: string;
  additionalCustomerId: string;
  patchAdditionalCustomerRequestBody: PatchAdditionalCustomerRequestBody;
};
export type PatchAdditionalCustomerRequestBody = {
  additionalCustomerType: AdditionalCustomerType;
  contractInformationId: string;
};
export type GetInternalInvoiceDocumentTemplatesApiArg = void;
export type GetInternalInvoiceDocumentWithVatTemplatesApiArg = void;
export type GetBrokerageInternalInvoiceDocumentTemplatesApiResponse =
  /** status 200  */ TemplateListItemResponseBody[];
export type GetBrokerageInternalInvoiceDocumentTemplatesApiArg = void;
export type GetBrokerageInternalInvoiceDocumentWithVatTemplatesApiResponse =
  /** status 200  */ TemplateListItemResponseBody[];
export type GetBrokerageInternalInvoiceDocumentWithVatTemplatesApiArg = void;
export type GetInternalInvoiceDocumentTemplatesApiResponse =
  /** status 200  */ TemplateListItemResponseBody[];
export type GetInternalInvoiceDocumentWithVatTemplatesApiResponse =
  /** status 200  */ TemplateListItemResponseBody[];
export type CreateBrokerageInternalInvoiceDocumentDocumentApiResponse =
  /** status 200  */ CheckoutResponseBody;
export type CreateBrokerageInternalInvoiceDocumentDocumentApiArg = {
  internalInvoiceDocumentRequestBody: InternalInvoiceDocumentRequestBody;
};
export type CreateBrokerageInternalInvoiceDocumentWithVatDocumentApiResponse =
  /** status 200  */ CheckoutResponseBody;
export type CreateBrokerageInternalInvoiceDocumentWithVatDocumentApiArg = {
  internalInvoiceDocumentRequestBody: InternalInvoiceDocumentRequestBody;
};
export type CreateInternalInvoiceDocumentDocumentApiResponse =
  /** status 200  */ CheckoutResponseBody;
export type CreateInternalInvoiceDocumentWithVatDocumentApiResponse =
  /** status 200  */ CheckoutResponseBody;
export type CreateInternalInvoiceDocumentDocumentApiArg = {
  internalInvoiceDocumentRequestBody: InternalInvoiceDocumentRequestBody;
};
export type CreateInternalInvoiceDocumentWithVatDocumentApiArg = {
  internalInvoiceDocumentRequestBody: InternalInvoiceDocumentRequestBody;
};
export type InternalInvoiceDocumentRequestBody = {
  templateId: string;
  checkoutId: string;
  orderId: string;
  paymentId: string;
};
export type AdditionalCustomerType = 'operator' | 'recipient';
export type PurchaseCashPaymentRequestBody = {
  paymentDiscriminator: 'PURCHASE';
  paymentMethod: 'CASH';
  dueDate: string;
  cashRegisterId: string;
  amount?: BigDecimal;
  exchangeRate?: string | null;
  dateOfTaxableSupply: string | null;
};
export type PurchaseBankTransferPaymentRequestBody = {
  paymentDiscriminator: 'PURCHASE';
  paymentMethod: 'BANK_TRANSFER';
  dueDate: string;
  customerBankAccountId: string;
  exchangeRate?: string | null;
  dateOfTaxableSupply: string | null;
};
export type PurchasePaymentCardPaymentRequestBody = {
  paymentDiscriminator: 'PURCHASE';
  paymentMethod: 'PAYMENT_CARD';
  dueDate: string;
  createCashRegisterDocument?: boolean | null;
  cashRegisterId?: string | null;
  exchangeRate?: string | null;
  dateOfTaxableSupply: string | null;
};
export type DepositCashPaymentRequestBody = {
  paymentDiscriminator: 'DEPOSIT';
  paymentMethod: 'CASH';
  dueDate: string;
  cashRegisterId: string;
  amount: BigDecimal;
  comment: string;
  exchangeRate?: string | null;
  dateOfTaxableSupply: string | null;
};
export type DepositBankTransferPaymentRequestBody = {
  paymentDiscriminator: 'DEPOSIT';
  paymentMethod: 'BANK_TRANSFER';
  dueDate: string;
  tenantBankAccount: TenantBankAccount | null;
  amount: BigDecimal;
  comment: string;
  exchangeRate?: string | null;
  dateOfTaxableSupply: string | null;
};
export type DepositPaymentCardPaymentRequestBody = {
  paymentDiscriminator: 'DEPOSIT';
  paymentMethod: 'PAYMENT_CARD';
  dueDate: string;
  amount: BigDecimal;
  comment: string;
  tenantBankAccount?: TenantBankAccount | null;
  createCashRegisterDocument?: boolean | null;
  cashRegisterId?: string | null;
  exchangeRate?: string | null;
  dateOfTaxableSupply: string | null;
};
export type BalanceCashPaymentRequestBody = {
  paymentDiscriminator: 'BALANCE';
  paymentMethod: 'CASH';
  dueDate: string;
  cashRegisterId: string;
  comment: string;
  exchangeRate?: string | null;
  dateOfTaxableSupply: string | null;
};
export type BalanceBankTransferPaymentRequestBody = {
  paymentDiscriminator: 'BALANCE';
  paymentMethod: 'BANK_TRANSFER';
  dueDate: string;
  tenantBankAccount: TenantBankAccount | null;
  comment: string;
  exchangeRate?: string | null;
  dateOfTaxableSupply: string | null;
};
export type BalancePaymentCardPaymentRequestBody = {
  paymentDiscriminator: 'BALANCE';
  paymentMethod: 'PAYMENT_CARD';
  dueDate: string;
  comment: string;
  tenantBankAccount?: TenantBankAccount | null;
  createCashRegisterDocument?: boolean | null;
  cashRegisterId?: string | null;
  exchangeRate?: string | null;
  dateOfTaxableSupply: string | null;
};

export type BalanceOffsetPaymentRequestBody = {
  paymentDiscriminator: 'BALANCE';
  paymentMethod: 'OFFSET';
  dueDate: string;
  comment: string;
  tenantBankAccount?: TenantBankAccount | null;
  exchangeRate?: string | null;
  supplierInvoiceNumber: string | null;
  dateOfTaxableSupply: string | null;
};

export type DepositOffsetPaymentRequestBody = {
  paymentDiscriminator: 'DEPOSIT';
  paymentMethod: 'OFFSET';
  dueDate: string;
  amount: BigDecimal;
  comment: string;
  tenantBankAccount?: TenantBankAccount | null;
  exchangeRate?: string | null;
  supplierInvoiceNumber: string | null;
  dateOfTaxableSupply: string | null;
};

export type PurchaseOffsetPaymentRequestBody = {
  paymentDiscriminator: 'PURCHASE';
  paymentMethod: 'OFFSET';
  dueDate: string;
  customerBankAccountId: string;
  exchangeRate?: string | null;
  supplierInvoiceNumber: string | null;
  dateOfTaxableSupply: string | null;
};

export type OrderPaymentRequestBody =
  | PurchaseCashPaymentRequestBody
  | PurchaseBankTransferPaymentRequestBody
  | PurchasePaymentCardPaymentRequestBody
  | DepositCashPaymentRequestBody
  | DepositBankTransferPaymentRequestBody
  | DepositPaymentCardPaymentRequestBody
  | BalanceCashPaymentRequestBody
  | BalanceBankTransferPaymentRequestBody
  | BalancePaymentCardPaymentRequestBody
  | BalanceOffsetPaymentRequestBody
  | DepositOffsetPaymentRequestBody
  | PurchaseOffsetPaymentRequestBody;

export type ProformaInvoiceSettingsEnum = 'without-proforma-invoice' | 'with-proforma-invoice';
export type BusinessCaseProformaInvoiceSettingResponseBody = {
  id: string;
  branchId: string | null;
  proformaInvoiceSettings: ProformaInvoiceSettingsEnum;
  isSystem: boolean;
};

export type GetCheckoutApiResponse = /** status 200  */ CheckoutResponseBody;
export type GetCheckoutApiArg = {
  checkoutId: string;
};
export type GetBusinessCaseProformaInvoiceSettingApiArg = {id: string};
export type GetListBusinessCaseProformaInvoiceSettingApiArg = void;
export type GetListBusinessCaseProformaInvoiceSettingApiResponse =
  BusinessCaseProformaInvoiceSettingResponseBody[];
export type UpdateBusinessCaseInvoiceSettingApiResponse =
  BusinessCaseProformaInvoiceSettingResponseBody;
export type GetBusinessCaseProformaInvoiceSettingApiResponse =
  BusinessCaseProformaInvoiceSettingResponseBody;
export type DeleteBusinessCaseProformaInvoiceSettingApiResponse = void;
export type CreateBusinessCaseProformaInvoiceSettingApiResponse =
  BusinessCaseProformaInvoiceSettingResponseBody;
export type CreateBusinessCaseProformaInvoiceSettingApiArg = {
  branchId: string;
  proformaInvoiceSettings: ProformaInvoiceSettingsEnum;
};
export type UpdateBusinessCaseInvoiceSettingApiArg = {
  id: string;
  branchId: string;
  proformaInvoiceSettings: ProformaInvoiceSettingsEnum;
};
export type DeleteBusinessCaseProformaInvoiceSettingApiArg = {
  id: string;
};
export type ChangeTypeOfSaleCheckoutOrderApiResponse = /** status 200  */ CheckoutResponseBody;
export type ChangeTypeOfSaleCheckoutOrderApiArg = {
  checkoutId: string;
  orderId: string;
  orderTypeOfSaleRequestBody: {
    typeOfSale: TypeOfSale;
  };
};

export type PutCheckoutDocumentsApiRes = /** status 200  */ CheckoutResponseBody;
export type PutCheckoutDocumentsApiArg = {
  checkoutId: string;
  orderId: string;
  templateIds: string[];
};
export type GetCheckoutOffsettingOfLiabilitiesAndReceivablesApiResponse =
  /** status 200  */ OffsettingOfLiabilitiesAndReceivablesResponseBody;
export type GetCheckoutOffsettingOfLiabilitiesAndReceivablesApiArg = {
  checkoutId: string;
};

export type OffsettingOfLiabilitiesAndReceivablesResponseBody = {
  claim: OrderItemResponseBody[];
  liabilities: OrderItemResponseBody[];
  credit: Money;
};
export type GetOffsettingOfLiabilitiesAndReceivablesTemplatesApiResponse =
  /** status 200  */ TemplateListItemResponseBody[];
export type GetOffsettingOfLiabilitiesAndReceivablesTemplatesApiArg = void;

export type GetPurchaseTemplatesApiArg = void;
export type GetSaleTemplatesApiArg = void;

export type GetCheckoutDocumentNoteApiResponse = /** status 200  */ NoteResponseBody;
export type CheckoutSubEntityClass =
  | 'DEFAULT'
  | 'PURCHASE_CONTRACT'
  | 'SALE_CONTRACT'
  | 'PURCHASE_BROKERAGE_CONTRACT'
  | 'SALE_BROKERAGE_CONTRACT';

export type GetCheckoutDocumentNoteApiArg = {
  orderId: string;
  subEntityClass: CheckoutSubEntityClass;
};
export type PutCheckoutDocumentNoteApiResponse = /** status 200  */ NoteResponseBody;
export type PutCheckoutDocumentNoteApiArg = {
  orderId: string;
  subEntityClass: CheckoutSubEntityClass;
  noteRequestBody: NoteRequestBody;
};

export type GetCheckoutSaleBrokerageContractTemplatesApiResponse =
  /** status 200  */ TemplateListItemResponseBody[];
export type GetCheckoutSaleBrokerageContractTemplatesApiArg = void;

export type GetCheckoutPurchaseTemplatesApiResponse =
  /** status 200  */ TemplatesListItemResponseBody[];

export type GetCheckoutPurchaseBrokerageTemplatesApiResponse =
  /** status 200  */ TemplatesListItemResponseBody[];
export type GetCheckoutPurchaseBrokerageTemplatesApiArg = void;
export type GetCheckoutSaleTemplatesApiResponse =
  /** status 200  */ TemplatesListItemResponseBody[];
export type CreateInternalCorrectiveTaxDocumentDocumentApiResponse =
  /** status 200  */ CheckoutResponseBodyRead;
export type CheckoutResponseBodyRead = {
  id: string;
  orders: OrderResponseBody[];
  editContractInformationAllowed: boolean;
  taxDocuments: TaxDocument[];
};
export type CreateInternalCorrectiveTaxDocumentDocumentApiArg = {
  internalCorrectiveTaxDocumentRequestBody: InternalCorrectiveTaxDocumentRequestBody;
};
export type InternalCorrectiveTaxDocumentRequestBody = {
  templateId: string;
  checkoutId: string;
  orderId: string;
  paymentId: string;
};
export type GetInternalCorrectiveTaxDocumentTemplatesApiResponse =
  /** status 200  */ TemplateListItemResponseBody[];

export type CreateDueDateSettingApiResponse = /** status 201  */ DueDateSettingResponseBody;
export type CreateDueDateSettingApiArg = {
  branchId: string;
  proformaInvoiceDueDate: string;
  invoiceDueDate: string;
};
export type DeleteDueDateSettingApiResponse = unknown;
export type DeleteDueDateSettingApiArg = {
  id: string;
};
export type GetDueDateSettingApiResponse = /** status 200  */ DueDateSettingResponseBody;
export type GetDueDateSettingApiArg = {
  id: string;
};
export type UpdateDueDateSettingApiResponse = /** status 200  */ DueDateSettingResponseBody;
export type UpdateDueDateSettingApiArg = {
  id: string;
  branchId: string | null;
  proformaInvoiceDueDate: string;
  invoiceDueDate: string;
};
export type GetListDueDateSettingApiResponse = /** status 200  */ DueDateSettingResponseBody[];
export type GetListDueDateSettingApiArg = void;
export type DueDateSettingResponseBody = {
  id: string;
  branchId: string | null;
  proformaInvoiceDueDate: string;
  invoiceDueDate: string;
  isSystem: boolean;
};
