import {Icon, Show} from 'platform/foundation';
import styled, {css} from 'styled-components';

import {ReactNode} from 'react';

import {isNotNil} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {Action} from '../Actions/Actions';
import {TabIconVariant} from './TabIconVariant';
import {getIconPropsFromVariant} from './utils/getIconPropsFromVariant';

export type TabVariants = 'container' | 'condensed' | 'default';

export type TabProps = TestIdProps & {
  id: string;
  title: string;
  content: ReactNode;
  isDisabled?: boolean;
  icon?: TabIconVariant;
  actions?: Action[];
  onClick?: (id: string) => void;
  isSelected?: boolean;
  variant?: TabVariants;
  isFullWidth?: boolean;
};

/**
 * @internal
 */
export function Tab(props: Omit<TabProps, 'content' | 'actions'>) {
  const tabIcon = getIconPropsFromVariant(props.icon);

  const handleClick = () => {
    if (props.isDisabled) {
      return;
    }
    props.onClick?.(props.id);
  };

  return (
    <StyledTab
      onClick={handleClick}
      $isDisabled={props.isDisabled}
      $variant={props.variant}
      $isSelected={props.isSelected}
      $isFullWidth={props.isFullWidth}
      data-testid={suffixTestId('tab', props)}
      aria-selected={props.isSelected}
      aria-disabled={props.isDisabled}
      disabled={props.isDisabled}
      type="button"
    >
      <Show when={isNotNil(tabIcon)}>
        <StyledIconWrapper>
          <Icon
            value={tabIcon?.key}
            color={tabIcon?.color}
            data-testid={suffixTestId('tabIcon', props)}
          />
        </StyledIconWrapper>
      </Show>
      {props.title}
    </StyledTab>
  );
}

const getVariantStyles = ($variant?: TabProps['variant']) => {
  switch ($variant) {
    case 'container':
      return css<StyledTabProps>`
        padding-top: 14px;
        padding-bottom: 16px;
        padding-inline-start: 16px;
        padding-inline-end: 16px;
        background-color: ${({theme, $isSelected}) =>
          $isSelected
            ? theme.colors.palettes.white[10][100]
            : theme.colors.palettes.neutral[10][100]};
        border-top-width: 2px;
        border-top-color: ${({theme, $isSelected}) =>
          $isSelected ? theme.colors.palettes.blue[60][100] : 'transparent'};
        color: ${({theme}) => theme.colors.text.primary};

        &:hover {
          background-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
        }

        &:disabled {
          background-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
          border-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
        }
      `;
    case 'condensed':
      return css<StyledTabProps>`
        padding-top: 0;
        padding-bottom: 6px;
        border-bottom-width: ${({$isDisabled}) => ($isDisabled ? 0 : '2px')};
        border-color: ${({theme, $isSelected}) =>
          $isSelected ? theme.colors.general.accent : 'transparent'};
        cursor: ${({$isDisabled}) => ($isDisabled ? 'not-allowed' : 'pointer')};
        &:hover {
          border-color: ${({theme, $isSelected}) =>
            $isSelected ? theme.colors.general.accent : theme.colors.palettes.neutral[50][100]};

          &:active {
            border-bottom: ${({theme, $isDisabled}) =>
              !$isDisabled ? `2px solid ${theme.colors.palettes.neutral[100][100]}` : 'unset'};
          }
        }
      `;
    default:
      return css<StyledTabProps>`
        padding-top: 16px;
        padding-bottom: 14px;
        border-bottom: 2px solid
          ${({theme, $isSelected}) => ($isSelected ? theme.colors.general.accent : 'transparent')};
        &:hover {
          border-bottom: 2px solid
            ${({theme, $isSelected}) =>
              $isSelected ? theme.colors.general.accent : theme.colors.palettes.neutral[50][100]};

          &:active {
            border-bottom: ${({theme, $isDisabled}) =>
              `2px solid ${theme.colors.palettes.neutral[$isDisabled ? 50 : 100][100]}`};
          }
      `;
  }
};

type StyledTabProps = {
  $isSelected?: TabProps['isSelected'];
  $variant?: TabProps['variant'];
  $isDisabled?: TabProps['isDisabled'];
  $isFullWidth?: TabProps['isFullWidth'];
};

const StyledTab = styled.button<StyledTabProps>`
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: ${({theme, $isSelected}) =>
    $isSelected ? theme.colors.general.accent : theme.colors.text.primary};
  font-size: ${({theme}) => theme.fontSizes.text.base};
  line-height: ${({theme}) => theme.lineHeights.text.base};
  font-weight: ${({theme}) => theme.fontWeights.medium};
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
  flex: ${({$isFullWidth}) => ($isFullWidth ? '100%' : 'none')};

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    color: ${({theme}) => theme.colors.palettes.neutral[900][40]};
    border-color: transparent;
    cursor: not-allowed;

    &:active {
      border-color: transparent;
    }
  }

  ${({$variant}) => getVariantStyles($variant)}
`;

const StyledIconWrapper = styled.span`
  margin-right: 12px;
`;
