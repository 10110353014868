import {Textarea, Button, ButtonGroup, Chips, Dialog, Label} from 'platform/components';
import {GridItem, Grid, Show, Space, Right} from 'platform/foundation';
import {v4 as uuidV4} from 'uuid';

import {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {compact} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {deleteAuditAssets} from '../../../store/carAudit/actions';
import {addAssets, deleteAssets} from '../../../store/carAudit/reducer';
import {selectAuditAssets} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditDataAssetsFilesBody} from '../../../types/AuditDataAssetsFilesBody';
import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {AuditDamageValueBody, TConditionForm} from '../../../types/ConditionTypes';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {useVehicleCreateContext} from '../../VehicleCreateContext/hooks/useVehicleCreateContext';
import {useConditionDamagesContext} from '../hooks/useConditionDamagesContext';
import {AuditParamType} from '../types/AuditParamType';
import {getFormFieldName} from '../utils/getFormFieldName';
import {getParamFromCategory} from '../utils/getParamFromCategory';
import {getParsedValue} from '../utils/getParsedValue';
import {getTranslation} from '../utils/getTranslation';
import {CardImageUpload, CardImageUploadProps} from './CardImageUpload';

type AddAndEditDamageProps = {
  isOpen: boolean;
  onClose: () => void;
  category: AuditCategoryOfStructure;
  damageId?: string | null;
};

enum ProcessAction {
  confirm = 'confirm',
  cancel = 'cancel',
  delete = 'delete',
}

export const AddAndEditDamage: FC<AddAndEditDamageProps & TestIdProps> = ({
  isOpen,
  onClose,
  category,
  ...rest
}) => {
  const dispatch = useThunkDispatch();
  const locale = useSelector(selectUserSelectedLanguage);

  const {
    Field: FormField,
    form: {
      getFieldState,
      mutators: {setFieldValue},
    },
  } = useFormRenderer<TConditionForm>();
  const {lastOrderNumber} = useConditionDamagesContext();
  const {deleteConditionAsset} = useVehicleCreateContext();

  const [locationPhotoParam, detailPhotoParam] =
    category.paramDefinitions?.relatedActions?.filter(
      (param) => param.type === AuditParamType.PHOTO
    ) ?? [];

  const locationAssets = useSelector(selectAuditAssets(category.id, locationPhotoParam?.id));
  const detailAssets = useSelector(selectAuditAssets(category.id, detailPhotoParam?.id));

  const [damageId, setDamageId] = useState<string>(() => rest.damageId ?? uuidV4());

  const isEdit = Boolean(rest.damageId);

  const typeOfDamageParam = getParamFromCategory(category, AuditParamType.SELECTABLE_BUTTON);
  const commentOfDamageParam = getParamFromCategory(category, AuditParamType.ENHANCED_COMMENT);

  const typeFieldName = getFormFieldName(category.id, typeOfDamageParam?.id);
  const commentFieldName = getFormFieldName(category.id, commentOfDamageParam?.id);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [processAction, setProcessAction] = useState<ProcessAction | null>(null);

  const [assetsForSave, setAssetsForSave] = useState<Record<string, AuditDataAssetsFilesBody[]>>(
    {}
  );
  const [assetsForDelete, setAssetsForDelete] = useState<Record<string, string[]>>({});

  const addAssetForSave = (paramDefinitionId: string, asset: AuditDataAssetsFilesBody) => {
    const _assetsForSave = assetsForSave;
    _assetsForSave[paramDefinitionId] = [...(assetsForSave?.[paramDefinitionId] ?? []), asset];
    setAssetsForSave(_assetsForSave);
  };

  const deleteAssetForSave = (paramDefinitionId: string, imageId: string) => {
    assetsForSave[paramDefinitionId] = assetsForSave[paramDefinitionId]?.filter(
      (asset) => asset.imageId !== imageId
    );

    setAssetsForSave(assetsForSave);
  };

  const addAssetForDelete = (paramDefinitionId: string, imageId: string) => {
    const _assetsForDelete = assetsForDelete;
    _assetsForDelete[paramDefinitionId] = [
      ...(assetsForDelete?.[paramDefinitionId] ?? []),
      imageId,
    ];
    setAssetsForDelete(_assetsForDelete);
  };

  const getDamageValue = (fieldName: string): AuditDamageValueBody[] => {
    const fieldState = getFieldState(fieldName);
    const value = fieldState?.value;

    if (!Boolean(value) || !Array.isArray(value)) {
      return [];
    }
    return value as AuditDamageValueBody[];
  };

  const getInitialValue = (fieldName: string) => {
    if (!isEdit) {
      return null;
    }

    const damageValue = getDamageValue(fieldName);
    const damage = damageValue?.find((damage) => damage.damageId === damageId);

    return damage?.type ?? null;
  };

  const getDamageTypeInitialValue = () => {
    if (!isEdit) {
      return typeOfDamageParam?.values?.[0]?.value ?? null;
    }

    return getInitialValue(typeFieldName);
  };

  const [damageType, setDamageType] = useState<string | null>(() => getDamageTypeInitialValue());
  const [damageComment, setDamageComment] = useState<string | null>(() =>
    getInitialValue(commentFieldName)
  );

  useEffect(() => {
    setDamageType(getDamageTypeInitialValue());
    setDamageComment(getInitialValue(commentFieldName));

    setAssetsForSave({});
    setAssetsForDelete({});
    setDamageId(rest.damageId ?? uuidV4());
  }, [isOpen, rest.damageId, category]);

  const updateDamageValue = (name: string, value: string) => {
    const damageValue = getDamageValue(name).slice();
    if (!damageValue || !Array.isArray(damageValue)) {
      return;
    }

    const damageIndex = damageValue.findIndex((damage) => damage.damageId === damageId);

    if (damageIndex < 0) {
      damageValue.push({
        damageId,
        type: value,
        order: lastOrderNumber + 1,
      });
    } else {
      damageValue[damageIndex] = {
        ...damageValue[damageIndex],
        type: value,
      };
    }

    setFieldValue(name, damageValue);
  };

  const deleteDamageValue = (fieldName: string, paramDefinitionId?: string) => {
    const damageValue = getDamageValue(fieldName);
    if (!damageValue || !paramDefinitionId) {
      return;
    }

    setFieldValue(
      fieldName,
      damageValue.filter((damage) => damage.damageId !== damageId)
    );
  };

  const addAssetsForParam = (paramDefinitionId: string, assets?: AuditDataAssetsFilesBody[]) => {
    if (!assets) {
      return;
    }

    dispatch(
      addAssets({
        categoryId: category.id,
        paramDefinitionId,
        assets,
      })
    );
  };

  const deleteAssetsForParam = async (
    paramDefinitionId: string,
    imageIds?: string[]
  ): Promise<void> => {
    if (!imageIds) {
      return;
    }

    dispatch(
      deleteAssets({
        categoryId: category.id,
        paramDefinitionId,
        imageIds,
      })
    );

    await dispatch(deleteAuditAssets.action({requestBody: {imageIds}}));
  };

  const handleConfirmDamage = async (): Promise<void> => {
    if (!damageType) {
      return;
    }

    setProcessAction(ProcessAction.confirm);

    if (typeOfDamageParam) {
      updateDamageValue(typeFieldName, damageType);
    }
    if (damageComment && damageComment !== '' && commentOfDamageParam) {
      updateDamageValue(commentFieldName, damageComment);
    }

    for await (const paramDefinition of [locationPhotoParam, detailPhotoParam]) {
      if (!paramDefinition) {
        continue;
      }

      const {id: paramId} = paramDefinition;
      const imageIdsForDelete = assetsForDelete?.[paramId];

      await addAssetsForParam(paramId, assetsForSave?.[paramId]);
      await deleteAssetsForParam(paramId, imageIdsForDelete);

      deleteAssetsOfVehicleCreate(imageIdsForDelete);
    }

    setProcessAction(null);
    onClose();
  };

  const handleCancelDamage = async (): Promise<void> => {
    setProcessAction(ProcessAction.cancel);

    for await (const paramDefinition of [locationPhotoParam, detailPhotoParam]) {
      if (!paramDefinition) {
        continue;
      }

      const paramAssetsForSave = assetsForSave?.[paramDefinition.id];
      if (!paramAssetsForSave?.length) {
        continue;
      }

      const imageIdsForDelete = paramAssetsForSave.map((asset) => asset.imageId);

      await deleteAssetsForParam(paramDefinition.id, compact(imageIdsForDelete));
      deleteAssetsOfVehicleCreate(compact(imageIdsForDelete));
    }

    setProcessAction(null);
    onClose();
  };

  const handleDamageTypeChange = (chipValue: string[] | Nullish) => {
    const [value] = chipValue ?? [];
    setDamageType(String(value));
  };

  const deleteAssetsOfVehicleCreate = (imageIds?: string[]) => {
    if (!deleteConditionAsset || !imageIds) {
      return;
    }

    for (const imageId of imageIds) {
      deleteConditionAsset(imageId);
    }
  };

  const handleDeleteDamage = async (): Promise<void> => {
    setProcessAction(ProcessAction.delete);

    deleteDamageValue(typeFieldName, typeOfDamageParam?.id);
    deleteDamageValue(commentFieldName, commentOfDamageParam?.id);

    const imageIds: string[] = [];
    [...locationAssets, ...detailAssets]?.forEach((file) => {
      const meta = getParsedValue(file?.meta);
      if (meta?.damageId !== damageId) {
        return;
      }
      if (file.imageId !== null) {
        imageIds.push(file.imageId);
      }
    });

    if (imageIds.length) {
      for await (const paramDefinition of [locationPhotoParam, detailPhotoParam]) {
        if (!paramDefinition) {
          continue;
        }

        dispatch(
          deleteAssets({
            categoryId: category.id,
            paramDefinitionId: paramDefinition.id,
            imageIds,
          })
        );

        deleteAssetsOfVehicleCreate(imageIds);
      }
    }

    setProcessAction(null);
    setIsDeleteDialogOpen(false);
    onClose();

    if (imageIds.length) {
      await dispatch(deleteAuditAssets.action({requestBody: {imageIds}}));
    }
  };

  const handleDeleteImage = (paramDefinitionId: string, imageId: string) => {
    if (isEdit) {
      addAssetForDelete(paramDefinitionId, imageId);
      return;
    }

    deleteAssetForSave(paramDefinitionId, imageId);
  };
  const getCardUploadProps = (paramDefinition: AuditParamDefinition): CardImageUploadProps => ({
    paramDefinitionId: paramDefinition.id,
    categoryId: category.id,
    damageId,
    softDelete: isEdit,
    onUpload: (asset: AuditDataAssetsFilesBody) => {
      addAssetForSave(paramDefinition.id, asset);
    },
    onDelete: (imageId: string) => {
      handleDeleteImage(paramDefinition.id, imageId);
    },
  });

  return (
    <>
      <FormField
        data-testid={suffixTestId(`addAndEditDamage-${typeFieldName}`, rest)}
        name={typeFieldName}
        component={() => <></>}
      />
      <FormField
        data-testid={suffixTestId(`addAndEditDamage-${commentFieldName}`, rest)}
        name={commentFieldName}
        component={() => <></>}
      />

      <Dialog
        isOpen={isOpen}
        onClose={handleCancelDamage}
        title={getTranslation(locale, category.name)}
        data-testid={suffixTestId('addAndEditDamage', rest)}
      >
        <Label>{i18n.t('entity.condition.labels.typeOfDamage')}</Label>
        <Space vertical={1} />
        <Grid columns={1}>
          <Show when={typeOfDamageParam}>
            <Chips
              value={damageType ? [damageType] : []}
              options={
                typeOfDamageParam?.values.map((paramValue) => ({
                  ...paramValue,
                  label: paramValue.label ? getTranslation(locale, paramValue.label) : '',
                })) ?? []
              }
              onChange={handleDamageTypeChange}
              data-testid={suffixTestId('addAndEditDamage-typeOfDamage', rest)}
            />
          </Show>

          <GridItem>
            <Grid columns={6}>
              <Show when={locationPhotoParam}>
                <GridItem span={1}>
                  <Label>{i18n.t('general.labels.location')}</Label>
                  <CardImageUpload
                    ratio="16 / 10"
                    {...getCardUploadProps(locationPhotoParam)}
                    data-testid={suffixTestId('addAndEditDamage-location', rest)}
                  />
                </GridItem>
              </Show>
              <Show when={detailPhotoParam}>
                <GridItem span={5}>
                  <Label>{i18n.t('general.labels.detail')}</Label>
                  <CardImageUpload
                    {...getCardUploadProps(detailPhotoParam)}
                    columns={5}
                    ratio="16 / 10"
                    isAlwaysShowUpload
                    multiple
                    data-testid={suffixTestId('addAndEditDamage-detail', rest)}
                  />
                </GridItem>
              </Show>
            </Grid>
          </GridItem>

          <GridItem>
            <Label>{i18n.t('general.labels.note')}</Label>
            <Textarea
              value={damageComment ?? ''}
              minRows={1}
              onChange={(value) => setDamageComment(value)}
              data-testid={suffixTestId('addAndEditDamage-note', rest)}
            />
          </GridItem>
          <Show when={isEdit}>
            <Right>
              <Button
                variant="dangerGhost"
                onClick={() => setIsDeleteDialogOpen(true)}
                leftIcon="action/delete"
                data-testid={suffixTestId('addAndEditDamage-deleteAction', rest)}
                title={i18n.t('general.actions.delete')}
              />
            </Right>
          </Show>
        </Grid>

        <Dialog
          size="small"
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          title={i18n.t('entity.condition.labels.damageDeleteConfirmationDialogText')}
          data-testid={suffixTestId('addAndEditDamage-conditionTypeDamageDeleteConfirmation', rest)}
        >
          <ButtonGroup align="right">
            <Button
              onClick={() => setIsDeleteDialogOpen(false)}
              variant="secondary"
              isDisabled={processAction === ProcessAction.delete}
              data-testid={suffixTestId(
                'addAndEditDamage-conditionTypeDamageDeleteConfirmation-cancel',
                rest
              )}
              title={i18n.t('general.actions.cancel')}
            />
            <Button
              variant="danger"
              onClick={handleDeleteDamage}
              isLoading={processAction === ProcessAction.delete}
              data-testid={suffixTestId(
                'addAndEditDamage-conditionTypeDamageDeleteConfirmation-delete',
                rest
              )}
              title={i18n.t('general.actions.delete')}
            />
          </ButtonGroup>
        </Dialog>

        <Space vertical={4} />
        <ButtonGroup
          align="right"
          data-testid={suffixTestId(
            `addAndEditDamage-conditionTypeDamageDeleteConfirmation-actions`,
            rest
          )}
        >
          <Button
            variant="secondary"
            onClick={handleCancelDamage}
            isDisabled={processAction === ProcessAction.confirm}
            isLoading={processAction === ProcessAction.cancel}
            data-testid={suffixTestId(
              `addAndEditDamage-conditionTypeDamageDeleteConfirmation-${
                isEdit ? 'cancel' : 'discard'
              }`,
              rest
            )}
            title={isEdit ? i18n.t('general.actions.cancel') : i18n.t('general.actions.discard')}
          />
          <Button
            variant="primary"
            onClick={handleConfirmDamage}
            isDisabled={processAction === ProcessAction.cancel}
            isLoading={processAction === ProcessAction.confirm}
            data-testid={suffixTestId(
              `addAndEditDamage-conditionTypeDamageDeleteConfirmation-${
                isEdit ? 'saveDamage' : 'addDamage'
              }`,
              rest
            )}
            title={
              isEdit
                ? i18n.t('entity.condition.actions.saveDamage')
                : i18n.t('entity.condition.actions.addDamage')
            }
          />
        </ButtonGroup>
      </Dialog>
    </>
  );
};
