import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

export type InvoiceId =
  | {invoiceId: string}
  | {proformaId: string}
  | {correctiveTaxDocumentId: string};

const hasInvoiceId = (val: unknown): val is {invoiceId: string} =>
  isNotNilOrEmpty((val as {invoiceId: string}).invoiceId);
const hasProformaId = (val: unknown): val is {proformaId: string} =>
  isNotNilOrEmpty((val as {proformaId: string}).proformaId);
const hasCorrectiveTaxDocumentId = (val: unknown): val is {correctiveTaxDocumentId: string} =>
  isNotNilOrEmpty((val as {correctiveTaxDocumentId: string}).correctiveTaxDocumentId);

export const getInvoiceIdFromProps = <T extends InvoiceId>(props: T) => {
  const invoiceDetails = match<
    InvoiceId,
    {id: string; type: 'ISSUED' | 'PROFORMA' | 'CORRECTIVE_TAX_DOCUMENT'} | null
  >(props)
    .when(hasInvoiceId, ({invoiceId}) => ({id: invoiceId, type: 'ISSUED'}))
    .when(hasProformaId, ({proformaId}) => ({
      id: proformaId,
      type: 'PROFORMA',
    }))
    .when(hasCorrectiveTaxDocumentId, ({correctiveTaxDocumentId}) => ({
      id: correctiveTaxDocumentId,
      type: 'CORRECTIVE_TAX_DOCUMENT',
    }))
    .otherwise(always(null));

  if (isNil(invoiceDetails)) {
    throw new Error('AccountingDocumentId is not valid');
  }

  return invoiceDetails;
};
