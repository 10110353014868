import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  useTranslationContext,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import * as Yup from 'yup';
import {array, string} from 'yup';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useSharePresetToUser} from '../../hooks/useSharePresetToUser';
import {SharePresetToUseRequestBody} from '../../types/Api';
import {UserOptionsQuery} from '../../types/UserOptionsQuery';

interface SharePresetForUserDialogProps extends RequiredTestIdProps {
  presetId: string;
  gridCode: string;
  useGetUserOptionsQuery: () => UserOptionsQuery;
}

const schema = Yup.object({
  userIds: array().of(string()).required().min(1),
});

export function SharePresetForUserDialog(props: SharePresetForUserDialogProps) {
  const {userOptions, isLoading} = props.useGetUserOptionsQuery();
  const t = useTranslationContext();
  const {onSubmit} = useSharePresetToUser(props.presetId, props.gridCode);

  return (
    <Form<SharePresetToUseRequestBody> onSubmit={onSubmit} schema={schema}>
      {(control) => (
        <VStack spacing={4}>
          <FormField
            data-testid={suffixTestId('userIds', props)}
            control={control}
            type="multiChoice"
            name="userIds"
            options={userOptions}
            placeholder={t('general.presets.userShare')}
            isLoading={isLoading}
          />
          <ButtonGroup align="right">
            <Button
              data-testid={suffixTestId('discardButton', props)}
              variant="secondary"
              title={t('general.actions.discard')}
              onClick={closeCurrentDialog}
            />
            <FormButton
              data-testid={suffixTestId('submitButton', props)}
              control={control}
              type="submit"
              title={t('general.actions.share')}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
