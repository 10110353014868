import {Box, Text, VStack, Space} from 'platform/foundation';

import {useRef, useEffect} from 'react';

import {inc} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {LightboxSlide} from '../types/LightboxSlide';
import {ScrollWithoutScrollbar} from './ScrollWithoutScrollbar';
import {ThumbnailItem} from './ThumbnailItem';

interface LightboxThumbnailsProps extends RequiredTestIdProps {
  slides: LightboxSlide[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

export function Thumbnails(props: LightboxThumbnailsProps) {
  const thumbnailsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handle = requestAnimationFrame(() => {
      if (thumbnailsRef.current?.children?.[props.activeIndex]) {
        thumbnailsRef.current.children[props.activeIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    });

    return () => cancelAnimationFrame(handle);
  }, [props.activeIndex]);

  return (
    <VStack justify="center">
      <Box padding={4} paddingBottom={2} paddingRight={2}>
        <Text
          data-testid={suffixTestId('thumbnails', props)}
          size="xSmall"
          align="center"
          color="white"
        >
          {inc(props.activeIndex)} / {props.slides.length}
        </Text>
      </Box>
      <ScrollWithoutScrollbar $direction="y">
        <Box ref={thumbnailsRef} width={41} paddingBottom={2} paddingLeft={4} paddingRight={2}>
          {props.slides.map((slide, index) => (
            <Box key={slide.id}>
              <Space vertical={2} />
              <ThumbnailItem
                data-testid={suffixTestId(`thumbnail-[${index}]`, props)}
                slide={slide}
                isActive={props.activeIndex === index}
                onClick={() => props.setActiveIndex(index)}
              />
            </Box>
          ))}
        </Box>
      </ScrollWithoutScrollbar>
    </VStack>
  );
}
