import {DataStatus} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {isEmpty} from 'ramda';

import {
  MakeFieldDefinitionResponseBody,
  useReorderFieldDefinitionsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError, usePermissions} from '@omnetic-dms/shared';

import {isLastIndexInArray, RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {getReorderedDefinitionIds} from '../utils/getReorderedDefinitionIds';
import {CreateDefinitionForm} from './CreateDefinitionForm';
import {DefinitionFormRow} from './DefinitionFormRow';

interface ResourceDefinitionListProps extends RequiredTestIdProps {
  definitions: MakeFieldDefinitionResponseBody[];
  resourceId: string;
}

export function ResourceDefinitionList(props: ResourceDefinitionListProps) {
  const [isCreating, startCreating, cancelCreating] = useBoolean(false);
  const [reorderDefinitions] = useReorderFieldDefinitionsMutation();

  const [canCreateDefinition] = usePermissions({permissionKeys: ['addCustomFieldsDefinitions']});

  const areDefinitionsEmpty = isEmpty(props.definitions);

  const handleReorderItems = (index: number) => (direction: 'up' | 'down') => {
    const movedDefinition = props.definitions[index];
    const reorderedItems = getReorderedDefinitionIds(props.definitions, movedDefinition, direction);

    reorderDefinitions({items: reorderedItems.map((item) => item.id), resourceId: props.resourceId})
      .unwrap()
      .catch(handleApiError);
  };

  return (
    <>
      <DataStatus
        isEmpty={areDefinitionsEmpty && !isCreating}
        action={{
          title: i18n.t('page.generalSettings.labels.addField'),
          leftIcon: 'content/add_circle',
          onClick: startCreating,
        }}
      >
        <VStack spacing={4}>
          {props.definitions.map((definition, index) => (
            <DefinitionFormRow
              definition={definition}
              data-testid={suffixTestId(`[${index}]`, props)}
              key={definition.id}
              handleMoveItem={handleReorderItems(index)}
              isFirstDefinition={index === 0}
              isLastDefinition={isLastIndexInArray(props.definitions, index)}
            />
          ))}

          <Show when={canCreateDefinition}>
            <CreateDefinitionForm
              data-testid={testIds.settings.customFields(`addDefinitionForm-${props.resourceId}`)}
              resourceId={props.resourceId}
              onAfterSubmit={cancelCreating}
              areDefinitionsEmpty={areDefinitionsEmpty}
            />
          </Show>
        </VStack>
      </DataStatus>
    </>
  );
}
