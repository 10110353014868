import {Tooltip} from 'platform/components';
import {Icon, ThemeIconKey} from 'platform/foundation';

import {Link} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';

import {composePath, RequiredTestIdProps} from 'shared';

import {SidebarButton} from './SidebarButton';

interface SidebarItemProps extends RequiredTestIdProps {
  path: string;
  isActive: boolean;
  title: string;
  icon?: ThemeIconKey;
}

export function SidebarLink(props: SidebarItemProps) {
  return (
    <Tooltip offset={8} placement="right" label={i18n.t(props.title)}>
      <Link
        to={composePath(props.path, {isSearchParamsPreserved: false})}
        data-testid={props['data-testid']}
      >
        <SidebarButton isActive={props.isActive}>
          <Icon size={5} value={props.icon} color="palettes.neutral.10.100" />
        </SidebarButton>
      </Link>
    </Tooltip>
  );
}
