import {useState} from 'react';
import {useLocation} from 'react-router-dom';

import {buildSharePresetUrl} from '../components/SharePresetButton/utils/buildSharePresetUrl';
import {useDataGridConfig} from '../context/useDatagridConfig';
import {useDataGridContext} from '../context/useDataGridContext';
import {SharePresetStateEnum} from '../types/SharePresetState';
import {useHttpCalls} from './useHttpCalls';

export const useSharePreset = (onCloseCallback?: () => void) => {
  const http = useHttpCalls();
  const {gridCode} = useDataGridContext();
  const location = useLocation();
  const {buildAbsoluteUrl} = useDataGridConfig();
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const [sharePresetState, setSharePresetState] = useState<SharePresetStateEnum>(
    SharePresetStateEnum.LOADING
  );

  const onClose = () => {
    onCloseCallback?.();
    setSharePresetState(SharePresetStateEnum.LOADING);
    setShareUrl(null);
  };

  const sharePreset = () => {
    http
      .sharePreset({
        gridSettings: true,
        columnSettings: true,
        filters: true,
        order: true,
      })
      .then((res) => {
        setSharePresetState(SharePresetStateEnum.FINISHED);
        res?.sharedPresetId &&
          buildAbsoluteUrl &&
          setShareUrl(
            buildSharePresetUrl(buildAbsoluteUrl, gridCode, res.sharedPresetId, location.pathname)
          );
      })
      .catch(() => {
        setSharePresetState(SharePresetStateEnum.ERROR);
      });
  };

  return {
    shareUrl,
    sharePresetState,
    sharePreset,
    onClose,
  };
};
