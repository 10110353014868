import {z} from 'zod';

import {BasePriceSchema} from './aftersalesCommon';
import {BaseVoidResponseSchema} from './base';

/**
 * Base
 */
const IssueNoteItemSchema = z.object({
  warehouseId: z.string(),
  originItemId: z.string(),
  articleId: z.string(),
  requestItemOriginEntityType: z.enum([
    'svc_job_item',
    'wrh_sale_item',
    'warehouse',
    'customer',
    'other',
    'supplier_order_item',
    'receive_note_item',
  ]),
});

const IssueNoteSchema = z.object({
  originDocId: z.string(),
  originDocType: z.enum(['service-case-order', 'direct-sale', 'service-order-issue-note']),
  originDocNumber: z.string(),
  receivedBy: z.string().optional(),
  authorizationProfileId: z.string(),
  customerName: z.string().optional(),
  note: z.string().optional(),
  items: z.array(IssueNoteItemSchema),
});

const DocumentInformationSchema = z.object({
  id: z.string(),
  customerId: z.string().optional(),
  vehicleId: z.string().optional(),
  number: z.string(),
  type: z.string(),
  variant: z.string(),
  serviceOrderVariantId: z.string().optional(),
});

/**
 * GET
 */
export const GetIssueNoteRequestSchema = z.object({
  issueNoteId: z.string(),
});
export type GetIssueNoteRequest = z.infer<typeof GetIssueNoteRequestSchema>;

export const GetIssueNoteResponseSchema = z.object({
  issueNoteInformation: z.object({
    number: z.string(),
    issuedAt: z.string(),
    issuedBy: z.string(),
    createdAt: z.string(),
    receivedBy: z.string(),
  }),
  originDocTypeInformation: z.object({
    directSale: DocumentInformationSchema.optional(),
    serviceOrder: DocumentInformationSchema.optional(),
    serviceOrderIssue: DocumentInformationSchema.optional(),
  }),
  summaryInformation: z.object({
    totalValue: BasePriceSchema,
  }),
});
export type GetIssueNoteResponse = z.infer<typeof GetIssueNoteResponseSchema>;

/**
 * POST
 */
export const PostIssueNoteRequestSchema = z.object({
  directSaleId: z.string(),
  body: IssueNoteSchema,
});
export type PostIssueNoteRequest = z.infer<typeof PostIssueNoteRequestSchema>;

export const PostIssueNoteResponseSchema = BaseVoidResponseSchema;
export type PostIssueNoteResponse = z.infer<typeof PostIssueNoteResponseSchema>;

/**
 * PATCH
 */

/**
 * DELETE
 */
