import {Button, DataStatus} from 'platform/components';
import {Box, Heading, Hide, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {isNil, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  CebiaUrlResponseBody,
  FullVehicle,
  FullVehicleResponseBody,
  useGetCebiaAutotracerAndReportConfigQuery,
  useGetLastAutotracerReportQuery,
  useLazyGetAutoTracerUrlQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, vehiclesRoutes} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, suffixTestId, TestIdProps} from 'shared';

import {openInNew} from '../../utils/someTeasUtils';
import {CebiaSmartCodeUrlPublishSwitch} from './components/CebiaSmartCodeUrlPublishSwitch';
import {CebiaWidgetAlert} from './components/CebiaWidgetAlert';
import {CebiaWidgetLogo} from './components/CebiaWidgetLogo';

interface CebiaAutotracerVehicleParams {
  id?: string;
  vin?: string;
  make?: string;
  modelFamily?: string;
}

export interface CebiaAutotracerWidgetProps extends TestIdProps {
  vehicle: FullVehicle | CebiaAutotracerVehicleParams | FullVehicleResponseBody;
}

export function CebiaAutotracerWidget(props: CebiaAutotracerWidgetProps) {
  const navigate = useNavigate();
  const [getAutoTracerUrl, {isLoading}] = useLazyGetAutoTracerUrlQuery();

  const {vin, make, modelFamily, id: vehicleId} = props.vehicle;

  const {data: newestReport, isLoading: isLastReportLoading} = useGetLastAutotracerReportQuery(
    {vehicleId: vehicleId!},
    {skip: isNil(vehicleId) || isNil(vin)}
  );

  const isVehicleValid = vin && make && modelFamily;

  const {data: cebiaAutotracerAndReportConfig} = useGetCebiaAutotracerAndReportConfigQuery();

  const isCredentialsValid = isNotNilOrEmpty(cebiaAutotracerAndReportConfig?.credentials?.username);

  const getNewReport = () => {
    getAutoTracerUrl({
      vin: vin!,
      vehicleMark: make,
      vehicleModel: modelFamily,
    })
      .unwrap()
      .then((response: CebiaUrlResponseBody) => {
        openInNew(response.url);
      })
      .catch(handleApiError);
  };

  if (!cebiaAutotracerAndReportConfig?.enabled) {
    return null;
  }

  return (
    <VStack spacing={2}>
      <Box backgroundColor="palettes.neutral.10.100" borderRadius="medium" overflow="hidden">
        <HStack>
          <CebiaWidgetLogo alt={i18n.t('page.integrations.labels.cebiaAutotraceTitle')} />
          <Box padding={4} backgroundColor="palettes.neutral.10.100">
            <VStack spacing={3} align="flex-start">
              <VStack spacing={2}>
                <Heading size={4}>{i18n.t('page.integrations.labels.cebiaAutotraceTitle')}</Heading>
                <Text size="xSmall">
                  {i18n.t('page.integrations.labels.cebiaAutotraceWidgetDescription')}
                </Text>
              </VStack>
              <DataStatus isLoading={isLastReportLoading}>
                <VStack spacing={2}>
                  <Show when={isNotNil(newestReport)}>
                    <Show
                      whenFeatureEnabled={featureFlags.SALES_CEBIA_SMART_CODE_URL_PUBLISHING_SWITCH}
                    >
                      <CebiaSmartCodeUrlPublishSwitch
                        vehicleId={vehicleId!}
                        label={i18n.t(
                          'page.integrations.labels.cebiaSmartCodeUrlPublishInAdvertisement'
                        )}
                      />
                      <Space vertical={3} />
                    </Show>
                    <HStack spacing={2}>
                      <Button
                        data-testid={suffixTestId('cebiaAutotracerWidget-viewReport', props)}
                        variant="secondary"
                        rightIcon="action/launch"
                        onClick={() => window.open(newestReport!.url)}
                        title={i18n.t('page.integrations.labels.cebiaAutotracerViewReport')}
                      />
                      <Button
                        data-testid={suffixTestId('cebiaAutotracerWidget-newReport', props)}
                        isDisabled={!isCredentialsValid || !isVehicleValid}
                        isLoading={isLoading}
                        onClick={getNewReport}
                        variant="secondary"
                        title={i18n.t('page.integrations.labels.cebiaAutotracerNewReport')}
                      />
                    </HStack>
                  </Show>
                  <Hide when={isNotNil(newestReport)}>
                    {/* Box to prevent button to go full width */}
                    <Box>
                      <Button
                        data-testid={suffixTestId('cebiaAutotracerWidget-getReport', props)}
                        isDisabled={!isCredentialsValid || !isVehicleValid}
                        isLoading={isLoading}
                        onClick={getNewReport}
                        variant="secondary"
                        title={i18n.t('page.integrations.labels.cebiaAutotraceGetReport')}
                      />
                    </Box>
                  </Hide>
                </VStack>
              </DataStatus>
            </VStack>
          </Box>
        </HStack>
      </Box>
      <Show when={!isVehicleValid}>
        <CebiaWidgetAlert
          colorScheme="warning"
          message={i18n.t('page.integrations.labels.cebiaAutotraceRequiredFields')}
          actionText={i18n.t('entity.vehicle.labels.editVehicle')}
          actionCallback={
            vehicleId
              ? () => navigate(composePath(vehiclesRoutes.edit, {params: {id: vehicleId}}))
              : undefined
          }
        />
      </Show>
      <Show when={!isCredentialsValid}>
        <CebiaWidgetAlert
          colorScheme="error"
          message={i18n.t('general.errors.invalidCredentials')}
          actionText={i18n.t('general.labels.settings')}
          actionCallback={() => navigate(settingsRoutes.vehicleServicesCebia)}
        />
      </Show>
    </VStack>
  );
}
