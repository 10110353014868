import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {head} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteWarehouseMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, noop, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function Warehouses() {
  const navigate = useNavigate();

  const [deleteWarehouse] = useDeleteWarehouseMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    match(actionKey)
      .with('redirectDetail', 'edit', () => {
        const id = isArray(rowId) ? head(rowId) : rowId;
        navigate(composePath(settingsRoutes.warehousesDetail, {params: {id}}));
      })
      .with('delete', () => {
        const ids = isArray(rowId) ? rowId : [rowId];
        const requestBody = {warehouseId: ids};

        openDeleteDialog({
          text: i18n.t('entity.warehouse.labels.deleteSelectedItemsPrompt'),
          onConfirm: () =>
            deleteWarehouse({body: requestBody})
              .unwrap()
              .then(() => showNotification.success())
              .then(refreshData)
              .catch(handleApiError),
        });
      })
      .otherwise(noop);
  };

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.warehouse.labels.warehouses'),
    actions: [
      {
        type: 'button',
        title: i18n.t('entity.warehouse.actions.newWarehouse'),
        onClick: () => navigate(settingsRoutes.warehousesCreate),
        'data-testid': testIds.settings.warehousesList('actions.new'),
      },
    ],
  };

  return (
    <SettingsTemplate header={header} data-testid="settings-warehouses">
      <DataGrid
        gridCode="warehouse"
        actionCallback={actionCallback}
        emptyState={{
          headline: i18n.t('page.warehouseDetail.labels.emptyAssortment'),
        }}
        data-testid={testIds.settings.warehousesList('warehouses')}
      />
    </SettingsTemplate>
  );
}
