import {Action, Actions, IconButton, TabIconVariant, TabsHeader} from 'platform/components';
import {Heading, HStack, Show, Space} from 'platform/foundation';

import {ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

export type SectionTab = {
  queryId: string;
  title: string;
  content: ReactNode;
  isDisabled?: boolean;
  icon?: TabIconVariant;
};

export interface SectionHeadingProps extends TestIdProps {
  title?: string;
  actions?: Action[];
  activeTabIndex?: number;
  tabs?: SectionTab[];
  onBackClick?: () => void;
  onTabChange?: (index: number) => void;
}

export function SectionHeading(props: SectionHeadingProps) {
  if (!props.title && !props.tabs?.length && !props.actions?.length) {
    return null;
  }

  const activeTabId = props.activeTabIndex?.toString();
  const handleOnChange = (val: string) => props.onTabChange?.(parseFloat(val));

  const tabs = (props.tabs ?? [])?.map((item, i) => ({
    id: i.toString(),
    title: item.title,
    icon: item.icon,
    isDisabled: item.isDisabled,
    'data-testid': suffixTestId(item.queryId, props),
  }));

  return (
    <HStack spacing={4} justify="space-between" align="center">
      <Show when={props.onBackClick}>
        <IconButton
          priority="tertiary"
          size="small"
          icon="navigation/arrow_back_ios"
          onClick={props.onBackClick}
        />
      </Show>
      <Show when={props.title}>
        <Heading size={3} headingLevel={2} alternative data-testid={props['data-testid']}>
          {props.title}
        </Heading>
      </Show>
      <Show when={props.tabs?.length}>
        <TabsHeader
          tabs={tabs}
          activeTabId={activeTabId}
          onChange={handleOnChange}
          variant="condensed"
        />
      </Show>
      <Space fillAvailable />
      <Show when={props.actions?.length}>
        <Actions actions={props.actions} data-testid={props['data-testid']} />
      </Show>
    </HStack>
  );
}
