import {Helmet} from 'react-helmet-async';

import i18n from '@omnetic-dms/i18n';
import {testIds, workshopRoutes} from '@omnetic-dms/routes';
import {Main} from '@omnetic-dms/shared';

import {ServiceCampaingsHyundai} from './components/ServiceCampaingsHyundai';

export function ServiceCampaignsOverview() {
  const tabs = [
    {
      id: 'hyundai',
      title: i18n.t('page.workshop.labels.hyundai'),
      content: (
        <ServiceCampaingsHyundai
          key="hyundai"
          gridCode="service-campaign-hyundai"
          data-testid={testIds.workshop.serviceCampaigns()}
        />
      ),
      href: workshopRoutes.serviceCampaigns,
    },
    {
      id: 'hyundai-unfinished',
      title: i18n.t('page.workshop.labels.hyundaiUnfinished'),
      content: (
        <ServiceCampaingsHyundai
          key="hyundai-unfinished"
          gridCode="service-campaign-hyundai-unfinished"
          data-testid={testIds.workshop.serviceCampaignsUnfinished()}
        />
      ),
      href: workshopRoutes.serviceCampaignsUnfinished,
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('page.workshop.labels.serviceCampaigns')} />
      <Main isFullHeight tabs={tabs} data-testid={testIds.workshop.serviceCampaigns()} />
    </>
  );
}
