import {UnifiedAlbumImage} from '../types/UnifiedAlbumImage';

const getId = () => (Math.random() + 1).toString(36).substring(8);

export function makeUnifiedAlbumImage(data?: Partial<UnifiedAlbumImage>): UnifiedAlbumImage {
  const FILE_ID = getId();
  return {
    id: getId(),
    fileId: FILE_ID,
    remoteFileId: FILE_ID,
    url: '',
    latestFileOperation: null,
    isCoverPhoto: false,
    isPromoPhoto: false,
    isGhost: false,
    weight: 100,
    ...data,
  };
}
