import {Attributes, Card, DataStatus} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import {useGetVehicleV2Query} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, vehiclesRoutes} from '@omnetic-dms/routes';

import {parseDate, composePath} from 'shared';

type ModelSpecificationCardProps = {
  vehicleId: string;
  canEditVehicle: boolean;
};

const ModelSpecificationCard: FC<ModelSpecificationCardProps> = ({vehicleId, canEditVehicle}) => {
  const navigate = useNavigate();
  const {data: vehicle, isLoading, isError} = useGetVehicleV2Query({vehicleId});

  const formatDateTime = useDateTimeFormatter();

  const handleOnClick = () =>
    navigate(
      composePath(vehiclesRoutes.editDetailTab, {params: {id: vehicleId, tab: 'vehicle-data'}})
    );

  return (
    <Card
      title={i18n.t('entity.vehicle.labels.serviceInspection')}
      actions={
        canEditVehicle
          ? [
              {
                variant: 'link',
                type: 'button',
                leftIcon: 'image/edit',
                onClick: handleOnClick,
                'data-testid': testIds.vehicles.vehicleData('serviceInspections-edit'),
                title: i18n.t('general.actions.edit'),
              },
            ]
          : undefined
      }
      data-testid={testIds.vehicles.vehicleData('serviceInspections')}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Attributes
          data-testid={testIds.vehicles.vehicleData('serviceInspections-parameters')}
          rows={[
            {
              label: i18n.t('entity.vehicle.labels.lastService'),
              value:
                vehicle?.lastServiceDate &&
                formatDateTime('dateShort', parseDate(vehicle?.lastServiceDate)),
              testId: 'lastServiceDate',
            },
            {
              label: `${i18n.t('entity.vehicle.labels.lastService')} (${String(
                i18n.t('general.metric.km')
              )})`,
              value: vehicle?.lastServiceMileage,
              testId: 'lastServiceMileage',
            },
            {
              label: i18n.t('entity.vehicle.labels.nextService'),
              value:
                vehicle?.nextServiceDate &&
                formatDateTime('dateShort', parseDate(vehicle?.nextServiceDate)),
              testId: 'nextServiceDate',
            },
            {
              label: `${i18n.t('entity.vehicle.labels.nextService')} (${i18n.t(
                'general.metric.km'
              )})`,
              value: vehicle?.nextServiceMileage,
              testId: 'nextServiceMileage',
            },
          ]}
        />
      </DataStatus>
    </Card>
  );
};

export const ServiceInspectionsCard = ModelSpecificationCard;
