import {Icon} from 'platform/foundation';
import styled, {css} from 'styled-components';
import {v4 as uuidv4} from 'uuid';

import {FC, useEffect, useState, PropsWithChildren} from 'react';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  Hyperlink,
  useInlineEditing,
  Switcher,
  CustomerPersonIdentificationTypeCodeEnum,
  DeputyContactPerson,
  DeputyPersonRequestBody,
  PutBillingInformationRequestBody,
} from '@omnetic-dms/teas';

import {parseDate, getApiDateString} from 'shared';

import {DeputyPersonForm} from '../../Tenant/components/DeputyPersonForm';
import {BillingInfoCache} from '../types';

const Wrapper = styled.div`
  background-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
`;

const PersonsWrapper = styled.div`
  padding: 0 ${({theme}) => theme.getSize(4)};
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
`;

const Padder = styled.div`
  padding: ${({theme}) => theme.getSize(4)};
`;

const fixDate = (x: string | null) => (x ? getApiDateString(parseDate(x)) : null);

type DeputyPersonResponseWithId = {uuid: string} & DeputyPersonRequestBody;

export const DeputyPersons: FC<PropsWithChildren<any>> = () => {
  const {editedValue, updateMethod} = useInlineEditing<
    Partial<PutBillingInformationRequestBody>,
    BillingInfoCache
  >();
  const billingInformation = editedValue?.data;
  const [persons, setPersons] = useState<DeputyPersonResponseWithId[]>(
    billingInformation?.deputyPersons?.map((p) => ({...p, uuid: uuidv4()})) ?? []
  );
  const transformDeputyToFormObj = ({
    dateOfBirth,
    identificationNumber,
    ...p
  }: DeputyPersonResponseWithId): DeputyContactPerson => ({
    default: false,
    middleName: '',
    titleBefore: '',
    titleAfter: '',
    positionTitle: '',
    citizenship: '',
    personalNumber: identificationNumber,
    note: '',
    genderCode: '',
    emails: [],
    personIdentifications: [
      {
        typeCode:
          CustomerPersonIdentificationTypeCodeEnum[
            p.personalIdType as keyof typeof CustomerPersonIdentificationTypeCodeEnum
          ],
        uuid: uuidv4(),
        code: p.personalIdNumber,
        validTill: p.validUntil,
        identificationIssuer: p.issuedBy,
        note: null,
      },
    ],
    phoneNumbers: [],
    dateOfBirth: dateOfBirth ?? '',
    ...p,
    // validUntil: `${fixDate(p.validUntil)}`, outdated
  });

  const transformFormObjToDeputy = (p: DeputyContactPerson): DeputyPersonRequestBody => ({
    isPrimary: false,
    firstName: p.firstName,
    lastName: p.lastName,
    dateOfBirth: p.dateOfBirth,
    identificationNumber: p.personalNumber,
    // eslint-disable-next-line no-restricted-syntax
    personalIdType: p.personIdentifications[0]
      ?.typeCode as unknown as DeputyPersonRequestBody.personalIdType,
    personalIdNumber: p.personIdentifications[0]?.code,
    validUntil: p.personIdentifications[0]?.validTill,
    issuedBy: p.personIdentifications[0]?.identificationIssuer,
  });

  const [showDeputyPerson, setShowDeputyPerson] = useState(
    (billingInformation?.deputyPersons?.length ?? 0) > 0
  );
  const toggleDeputyPersonForm = () => {
    setShowDeputyPerson(!showDeputyPerson);
  };
  const handleAddDeputyPerson = () => {
    const newPerson: DeputyPersonRequestBody = {
      isPrimary: false,
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      identificationNumber: null,
      personalIdType: null,
      personalIdNumber: null,
      validUntil: null,
      issuedBy: null,
    };
    setPersons(persons.concat({...newPerson, uuid: uuidv4()}));
    billingInformation?.deputyPersons.push(newPerson);

    if (billingInformation) {
      updateMethod(billingInformation, billingInformation);
    }
  };

  const setChangedContactPerson = (index: number, contactPerson: DeputyContactPerson) => {
    // @ts-expect-error Property 'selfEmployedInformation' does not exist on type 'ContactInformation'.
    delete billingInformation?.contactInformation?.selfEmployedInformation;

    if (billingInformation) {
      billingInformation.deputyPersons[index] = {
        firstName: contactPerson.firstName,
        lastName: contactPerson.lastName,
        validUntil: fixDate(contactPerson.personIdentifications[0].validTill),
        issuedBy: contactPerson.personIdentifications[0].identificationIssuer,
        identificationNumber: contactPerson.personalNumber,
        // eslint-disable-next-line no-restricted-syntax
        personalIdType: (contactPerson.personIdentifications[0].typeCode ||
          null) as unknown as DeputyPersonRequestBody.personalIdType,
        personalIdNumber: emptyStringToNull(contactPerson.personIdentifications[0].code ?? ''),
        isPrimary: false,
        dateOfBirth: fixDate(contactPerson.dateOfBirth),
      };
    }

    const changedState = persons.map((p, i) =>
      i === index ? {...transformFormObjToDeputy(contactPerson), uuid: p.uuid} : p
    );
    setPersons(changedState);

    const _billingInfo = {
      deputyPersons: billingInformation?.deputyPersons ?? [],
    };

    updateMethod(_billingInfo, _billingInfo);
  };

  const handleRemoveDeputyPerson = (index: number) => () => {
    setPersons(persons.filter((_, i) => i !== index));

    if (!billingInformation) {
      return;
    }

    billingInformation.deputyPersons.splice(index, 1);
    updateMethod(billingInformation, billingInformation);
  };

  useEffect(() => {
    if (showDeputyPerson) {
      const _persons = billingInformation?.deputyPersons.map((p) => ({...p, uuid: uuidv4()})) ?? [];

      setPersons(_persons);

      if (_persons.length === 0) {
        handleAddDeputyPerson();
      }
    } else {
      if (persons.length) {
        const billingInformation: Partial<PutBillingInformationRequestBody> = {
          deputyPersons: [],
        };
        updateMethod(billingInformation, billingInformation);
      }
      setPersons([]);
    }
  }, [showDeputyPerson]);

  return (
    <Wrapper>
      <Padder>
        <Switcher
          name="deputy-person-switcher"
          label={i18n.t('entity.person.labels.deputyPerson')}
          data-testid={testIds.settings.billingInformationEdit('deputyPersonSwitcher')}
          onChange={toggleDeputyPersonForm}
          value={showDeputyPerson}
        />
      </Padder>
      <Divider
        css={css`
          width: 100%;
        `}
      />

      <PersonsWrapper>
        {persons.map((person, index) => {
          const p = transformDeputyToFormObj(person);

          return (
            <DeputyPersonForm
              defaultValues={p}
              index={index}
              length={persons.length}
              singlePersonIdentification={true}
              onChangeContactPerson={(p) => setChangedContactPerson(index, p)}
              handleRemoveDeputyPerson={handleRemoveDeputyPerson}
              key={p.uuid}
            />
          );
        })}
      </PersonsWrapper>

      {showDeputyPerson && (
        <Padder>
          <Hyperlink
            data-testid={testIds.settings.billingInformationEdit('createDeputyPerson')}
            type="button"
            icon={<Icon value="content/add_circle" />}
            onClick={handleAddDeputyPerson}
          >
            {i18n.t('page.businessCase.labels.newDeputyPerson')}
          </Hyperlink>
        </Padder>
      )}
    </Wrapper>
  );
};

const emptyStringToNull = (x: string) => (!x || x.length === 0 ? null : x);
