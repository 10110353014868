interface FileChunkReadParams {
  uri: string;
  index: number;
  fileSize: number;
  chunkSize: number;
}

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;
    reader.onloadend = () => {
      resolve((reader.result as string).split(',')[1]);
    };

    reader.readAsDataURL(blob);
  });
}

export function getWebBase64(params: FileChunkReadParams): Promise<string> {
  const start = params.index * params.chunkSize;
  // The `end` is the calculated last byte of the current chunk.
  // We subtract 1 because `Range` is inclusive, so we need to specify
  // the last byte position explicitly. For example, if the chunk size
  // is 26,214,400, and the next chunk starts at byte 26,214,400, we
  // subtract 1 to set the range as 0-26,214,399 for the first chunk.
  const end = Math.min((params.index + 1) * params.chunkSize, params.fileSize) - 1;

  return fetch(params.uri, {headers: {Range: `bytes=${start}-${end}`}})
    .then((response) => response.blob())
    .then((blob) => blobToBase64(blob));
}
