import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {always, isNotNil, not} from 'ramda';

import {
  BusinessCaseInternalType,
  BusinessCaseResponseBody,
  SaleVehiclePriceResponseBody,
  SaleVehicleResponseBody,
  useGetTenantQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {DEFAULT_CURRENCY, EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export function useGetPrimaryAndSecondaryParameters(
  businessCase: BusinessCaseResponseBody | undefined,
  saleVehicle: SaleVehicleResponseBody | undefined
) {
  const formatPrice = useFormatCurrency();
  const isSaleVehicle = isNotNil(saleVehicle);

  const {data: tenant} = useGetTenantQuery();

  const isPurchaseBrokerage = businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE';
  const purchaseVehicleOffer = businessCase?.offers[0]?.purchaseVehicles?.[0];
  const saleVehicleOffer = businessCase?.offers[0]?.saleVehicles?.[0];
  const tenantCurrency = tenant?.currency ?? DEFAULT_CURRENCY;

  const offerPrice = match<
    BusinessCaseInternalType | Nullish,
    SaleVehiclePriceResponseBody | Nullish
  >(businessCase?.businessCaseInternalType)
    .with('SELLING', always(businessCase?.offers[0]?.offerTotalPrice))
    .with('BUYING', always(saleVehicle?.purchasePrice))
    .with('PURCHASE_BROKERAGE', always(saleVehicle?.sellingPrice))
    .otherwise(always(null));

  const primaryParamPrice =
    not(isPurchaseBrokerage) && purchaseVehicleOffer
      ? (purchaseVehicleOffer?.buying?.buyingPrice?.priceWithVat?.amount ??
        purchaseVehicleOffer?.buying?.buyingPrice?.priceNotDeductible?.amount)
      : offerPrice?.withVat?.amount;

  const primaryParameter = isNotNil(primaryParamPrice)
    ? (formatPrice(
        Number(primaryParamPrice),
        purchaseVehicleOffer?.buying?.buyingPrice?.priceWithVat?.currency ??
          offerPrice?.withVat?.currency ??
          tenantCurrency
      ) ?? EMPTY_PLACEHOLDER)
    : EMPTY_PLACEHOLDER;

  const secondaryParamPrice =
    not(isPurchaseBrokerage) && purchaseVehicleOffer
      ? (purchaseVehicleOffer?.buying?.buyingPrice?.priceWithoutVat?.amount ??
        purchaseVehicleOffer?.buying?.buyingPrice?.priceNotDeductible?.amount)
      : offerPrice?.withoutVat?.amount;
  const secondaryParameter = match<[boolean, boolean, boolean, boolean], string>([
    Boolean(
      purchaseVehicleOffer?.buying?.vatDeductible ??
        saleVehicleOffer?.vatDeductible ??
        saleVehicle?.isVatDeductible
    ),
    isNotNil(secondaryParamPrice),
    isNotNil(saleVehicle?.purchasePriceIsForeignCurrency?.priceWithoutVat) &&
      (businessCase?.businessCaseInternalType === 'BUYING' ||
        businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE'),
    isNotNil(saleVehicle?.sellingPriceIsForeignCurrency?.priceWithoutVat) &&
      businessCase?.businessCaseInternalType === 'SELLING',
  ])
    .with(
      [true, true, true, false],
      always(`
       ${formatPrice(
         Number(secondaryParamPrice),
         offerPrice?.withoutVat?.currency ?? tenantCurrency
       )} / ${formatPrice(
         parseFloat(saleVehicle?.purchasePriceIsForeignCurrency?.priceWithoutVat ?? '0'),
         saleVehicle?.purchasePriceIsForeignCurrency?.foreignCurrencyCode ?? tenantCurrency
       )} ${i18n.t('general.labels.withoutVat')}`)
    )
    .with(
      [true, true, false, true],
      always(`
       ${formatPrice(
         Number(secondaryParamPrice),
         offerPrice?.withoutVat?.currency ?? tenantCurrency
       )} / ${formatPrice(
         parseFloat(saleVehicle?.sellingPriceIsForeignCurrency?.priceWithoutVat ?? '0'),
         saleVehicle?.sellingPriceIsForeignCurrency?.foreignCurrencyCode ?? tenantCurrency
       )} ${i18n.t('general.labels.withoutVat')}`)
    )
    .with(
      [true, true, false, false],
      always(`
    ${formatPrice(
      Number(secondaryParamPrice),
      offerPrice?.withoutVat?.currency ?? tenantCurrency
    )} ${i18n.t('general.labels.withoutVat')}`)
    )
    .with([true, false, false, false], always(i18n.t('entity.businessCase.labels.VATDeductible')))
    .otherwise(always(i18n.t('entity.vehicle.labels.nondeductible')));

  const foreignPrice = match<BusinessCaseInternalType | Nullish, string | Nullish>(
    businessCase?.businessCaseInternalType
  )
    .with('SELLING', always(saleVehicle?.sellingPriceIsForeignCurrency?.priceWithVat))
    .with('BUYING', always(saleVehicle?.purchasePriceIsForeignCurrency?.priceWithVat))
    .with('PURCHASE_BROKERAGE', always(saleVehicle?.purchasePriceIsForeignCurrency?.priceWithVat))
    .otherwise(always(null));

  const foreignCurrencyCode = match<BusinessCaseInternalType | Nullish, string | Nullish>(
    businessCase?.businessCaseInternalType
  )
    .with('SELLING', always(saleVehicle?.sellingPriceIsForeignCurrency?.foreignCurrencyCode))
    .with('BUYING', always(saleVehicle?.purchasePriceIsForeignCurrency?.foreignCurrencyCode))
    .with(
      'PURCHASE_BROKERAGE',
      always(saleVehicle?.purchasePriceIsForeignCurrency?.foreignCurrencyCode)
    )
    .otherwise(always(null));

  const tertiaryParameter = match([isSaleVehicle, isNotNil(foreignPrice)])
    .with(
      [true, true],
      () =>
        formatPrice(parseFloat(foreignPrice ?? '0'), foreignCurrencyCode ?? DEFAULT_CURRENCY) ??
        EMPTY_PLACEHOLDER
    )
    .otherwise(always(undefined));

  return {primaryParameter, secondaryParameter, tertiaryParameter};
}
