import {isNil} from 'ramda';

import {VatRate} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getVatRatesByTenantCountry = (
  vatRates: VatRate[] | Nullish,
  tenantCountry: string | Nullish
): VatRate | Nullish => {
  if (isNil(vatRates)) {
    return null;
  }
  return vatRates.find((vatRate) => vatRate.code === tenantCountry) as VatRate;
};
