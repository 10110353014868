import {CLOSE_DROPDOWN_EVENT} from './closeDropdownEvent';

/**
 * @about Closes dropdown with assigned Id
 *
 * @param id string - unique identifier for the dropdown
 *
 * @example
 * openDropdown(
   'image-actions'
 * );
 */
export function closeDropdown(id: string) {
  window.dispatchEvent(
    new CustomEvent(CLOSE_DROPDOWN_EVENT, {
      detail: {id},
    })
  );
}
