import {DeepPath, DeepPathArray} from '@hookform/strictly-typed';

import {ReactElement} from 'react';
import {Control, Controller, FieldValues, UseFormReturn} from 'react-hook-form';

import {path} from 'ramda';
import {isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {DatePicker, DatePickerProps} from '../../DatePickers/DatePicker';
import {FieldProps} from '../FieldProps';
import {FieldLabel, ValidationMessage} from '../styles';

export interface DatePickerFieldProps<
  TValues extends FieldValues,
  TPath = DeepPath<TValues, DeepPathArray<TValues, keyof TValues>>,
> extends FieldProps<TValues, TPath> {
  onChange?: (values: any) => void;
  defaultValue?: string extends keyof TValues ? TValues[keyof TValues & string] : unknown;
  inputProps?: Omit<DatePickerProps, 'onChange' | 'value'>;
}
/**
 * @deprecated - use platform instead
 */
export function useDatePickerFields<
  TValues extends Record<string, string | undefined>,
  TPath = DeepPath<TValues, DeepPathArray<TValues, keyof TValues>>,
>({
  control,
  formState: {errors},
  getValues,
  trigger,
}: UseFormReturn<TValues>): (fieldProps: DatePickerFieldProps<TValues, TPath>) => ReactElement {
  return ({
    label,
    name,
    onChange,
    inputProps,
    defaultValue,
    preventTrigger,
  }: DatePickerFieldProps<TValues, TPath>) => {
    const stringifiedName = Array.isArray(name) ? name.join('.') : String(name);
    return (
      <>
        {label && <FieldLabel className="label">{i18n.t(label)}</FieldLabel>}
        <Controller
          name={stringifiedName}
          control={control as Control<Record<string, any>>}
          defaultValue={defaultValue as any}
          render={(props) => {
            const handleChange = (value: Date) => {
              props.field.onChange(value?.toDateString());
              !preventTrigger && trigger();

              if (onChange) {
                const values = getValues();
                onChange(values);
              }
            };

            return (
              <DatePicker
                {...props.field}
                name={stringifiedName}
                {...inputProps}
                onChange={handleChange}
              />
            );
          }}
        />
        {
          // eslint-disable-next-line no-restricted-syntax
          path(isString(name) ? [name] : Array.from(name as unknown as string[]), errors) && (
            <ValidationMessage data-testid={`datepicker-${stringifiedName}-error-text`}>
              {path(
                isString(name)
                  ? [name, 'message']
                  : // eslint-disable-next-line no-restricted-syntax
                    [...Array.from(name as unknown as string[]), 'message'],
                errors
              )}
            </ValidationMessage>
          )
        }
      </>
    );
  };
}
