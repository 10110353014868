import {isNotNil} from 'ramda-adjunct';

import {Nullish} from '../types/Nullish';

/**
 * @about Utility function to get check if number is not null undefined or zero
 * @returns boolean
 * @example
 */
export const isNotNilOrZero = (number: number | Nullish): number is number =>
  isNotNil(number) && number !== 0;
