import {isNil} from 'ramda';

import {Integer} from '../types/Integer';
import {foundationTheme} from './foundationTheme';

// Inspired by this: https://stackoverflow.com/a/42769683
const baseFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

export function getPixelsFromRem(size?: Integer): number | undefined {
  if (isNil(size)) {
    return undefined;
  }
  if (size === 0) {
    return 0;
  }
  return foundationTheme.space.step * size * baseFontSize;
}
