/**
 * @deprecated
 */
export enum CustomerPersonIdentificationTypeCodeEnum {
  ID_TYPE_ID = 'ID_TYPE_ID',
  ID_TYPE_PASSPORT = 'ID_TYPE_PASSPORT',
  ID_TYPE_DRIVING_LICENSE = 'ID_TYPE_DRIVING_LICENSE',
  ID_TYPE_OTHER = 'ID_TYPE_OTHER',
  CUSTOMER_COMPANY_REGISTRATION_ID = 'CUSTOMER_COMPANY_REGISTRATION_ID',
  CUSTOMER_COMPANY_TAX_ID = 'CUSTOMER_COMPANY_TAX_ID',
  CUSTOMER_COMPANY_NAME = 'CUSTOMER_COMPANY_NAME',
  CUSTOMER_CONTACT_CARD_ID = 'CUSTOMER_CONTACT_CARD_ID',
  CUSTOMER_CONTACT_PERSONAL_ID = 'CUSTOMER_CONTACT_PERSONAL_ID',
}
