import {Dropdown} from 'platform/components';
import {Icon} from 'platform/foundation';
import styled from 'styled-components';

import {FC, useRef, useEffect, useState, Children, PropsWithChildren} from 'react';

import {debounce} from 'shared';

type MenuItemProps = {
  isActive?: boolean;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Ul = styled.ul`
  width: 100%;
  height: 100%;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;

  .show-more {
    position: relative;

    & > ul {
      display: none;
    }

    &:hover {
      & > ul {
        display: block;
      }
    }
  }
`;

const CloneUl = styled(Ul)`
  position: absolute;
  z-index: -1;
  visibility: hidden;
`;

const Submenu = styled.ul`
  & > li {
    width: 100%;
    display: flex;
    padding: 6px 12px;
    margin-bottom: 4px;
    border-radius: ${({theme}) => theme.radii.small};

    &:before {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};

      &:before {
        display: none !important;
      }
    }
  }
`;

export const MenuItem = styled.li<MenuItemProps>`
  height: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
  flex: 0 1 auto;
  white-space: nowrap;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:before {
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    display: none;
  }

  &:not(.show-more):hover:before {
    display: block;
    background-color: ${({theme, isActive}) =>
      isActive ? theme.colors.palettes.blue[60][100] : theme.colors.palettes.neutral[30][100]};
  }

  ${({theme, isActive}) =>
    isActive
      ? `
				&:before {
					display: block;
					background-color: ${theme.colors.palettes.blue[60][100]};
				}
			`
      : null}
`;

const MoreButton = styled.button`
  width: 40px;
  height: 32px;
  border: 0;
  outline: 0;
  background-color: transparent;
  border-radius: ${({theme}) => theme.radii.small};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  }
`;

type MenuProps = {
  className?: string;
};
/**
 * @deprecated - use platform instead
 */
export const Menu: FC<PropsWithChildren<MenuProps>> = ({children, className}) => {
  const itemsWidth = useRef(0);
  const moreBtn = useRef<HTMLLIElement>(null);
  const containerRef = useRef<HTMLUListElement>(null);
  const anchorEl = useRef<null | HTMLButtonElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [hiddenItems, setHiddenItems] = useState<number[]>([]);

  const childrenArray = Children.toArray(children);

  const onResize = () => {
    let stopWidth = moreBtn.current?.offsetWidth ?? 0;

    const hiddenItems: number[] = [];
    const moreButtonWidth = moreBtn.current?.offsetWidth ?? 0;
    const primaryWidth = containerRef.current?.offsetWidth ?? 0;
    const primaryItems =
      containerRef.current?.querySelectorAll<HTMLLIElement>('li:not(.show-more)');

    primaryItems?.forEach((item: HTMLLIElement, i) => {
      const style = getComputedStyle(item);
      const totalWidth = item.offsetWidth + parseInt(style.marginRight || '0', 10);

      if (primaryWidth - moreButtonWidth >= stopWidth + totalWidth) {
        stopWidth += totalWidth;
      } else {
        hiddenItems.push(i);
      }
    });

    setHiddenItems(hiddenItems);
  };

  const debouncedOnResize = debounce(onResize, 100);

  useEffect(() => {
    window.addEventListener('resize', debouncedOnResize);

    return () => {
      window.removeEventListener('resize', debouncedOnResize);
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current?.childNodes?.forEach((node) => {
        const style = getComputedStyle(node as Element);
        const totalWidth =
          (node as HTMLLIElement).offsetWidth + parseInt(style.marginRight || '0', 10);

        return (itemsWidth.current += totalWidth);
      });

      onResize();
    }
  }, [containerRef.current]);

  const handleMoreClick = () => {
    setIsOpen(!isOpen);
  };

  const moreButton = (
    <MoreButton aria-describedby="show-more-button" ref={anchorEl} onClick={handleMoreClick}>
      <Icon value="navigation/more_horiz" />
    </MoreButton>
  );

  return (
    <Container className={className}>
      <CloneUl ref={containerRef}>
        {children}

        <MenuItem ref={moreBtn} className="show-more">
          {moreButton}
        </MenuItem>
      </CloneUl>

      <Ul>
        {hiddenItems.length
          ? childrenArray.map((item, i) => (hiddenItems.includes(i) ? null : item))
          : childrenArray}

        {hiddenItems.length > 0 ? (
          <MenuItem className="show-more">
            <Dropdown
              dropdownControl={
                <MoreButton>
                  <Icon value="navigation/more_horiz" />
                </MoreButton>
              }
            >
              <Submenu>
                {childrenArray.map((item, i) => (hiddenItems.includes(i) ? item : null))}
              </Submenu>
            </Dropdown>
          </MenuItem>
        ) : null}
      </Ul>
    </Container>
  );
};
