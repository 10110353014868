import {Button, CheckboxTree, FormControl, Label, Search} from 'platform/components';
import {Box, Hide, Show, Stack, VStack} from 'platform/foundation';

import {FieldValues, Path, useController} from 'react-hook-form';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {i18n} from '@omnetic-dms/i18n';
import {useVehicleFeatures} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {getTopMostItem, getAllChildKeys} from '../utils/checkboxTreeHelpers';
import {getFeaturesPickerRowOptions} from '../utils/getFeaturesPickerRowOptions';

type FeaturesPickerProps<TFieldValues extends FieldValues = FieldValues> = {
  control: FormControl<TFieldValues>;
  name: Path<TFieldValues>;
  vehicleType: string | Nullish;
  isFeaturesOpen: boolean;
  isLabelHidden?: boolean;
  isDisabled?: boolean;
  toggleFeaturesOpen: () => void;
} & RequiredTestIdProps;

export function FeaturesPicker<TFieldValues extends FieldValues = FieldValues>(
  props: FeaturesPickerProps<TFieldValues>
) {
  const {setFeatureSearchValue, featureSearchValue, filteredFeatureOptions} = useVehicleFeatures(
    props.vehicleType ?? 'VEHICLETYPE_PASSENGER_CAR'
  );

  const {field} = useController({control: props.control, name: props.name});

  const [firstOptionsRow, secondOptionsRow] = getFeaturesPickerRowOptions(
    filteredFeatureOptions ?? []
  );

  const handleCheckboxTreeSelect = (value: string, optionsRow: 'first' | 'second') => {
    const workingOptionsRow = optionsRow === 'first' ? firstOptionsRow : secondOptionsRow;
    const selectedValues = field.value;

    if (selectedValues?.includes(value)) {
      // value is already selected, just deselect it
      field.onChange(selectedValues.filter((selectedValue: string) => selectedValue !== value));
      return;
    }
    const topMostItem = getTopMostItem(value, workingOptionsRow);
    if (topMostItem) {
      // deselect all child keys of this parent
      const treeKeys = getAllChildKeys(topMostItem);
      const newSelection = selectedValues.filter((item: string) => !treeKeys.includes(item));
      field.onChange([...newSelection, value]);
      return;
    }
    // just add item to collection
    field.onChange([...selectedValues, value]);
  };

  const onSearchChange = (value: string | null) => {
    if (!props.isFeaturesOpen) {
      props.toggleFeaturesOpen();
    }
    setFeatureSearchValue(value);
  };

  return (
    <VStack spacing={3} width="100%" align="flex-start">
      <Box width="100%">
        <Hide when={props.isLabelHidden}>
          <Label>{i18n.t('entity.vehicle.labels.features')}</Label>
        </Hide>
        <Search
          data-testid={suffixTestId('featuresPicker', props)}
          value={featureSearchValue}
          onChange={onSearchChange}
        />
      </Box>
      <Button
        data-testid={suffixTestId('featuresPicker-showOrHide', props)}
        title={
          props.isFeaturesOpen
            ? i18n.t('entity.vehicle.actions.hideAllFeatures')
            : i18n.t('entity.vehicle.actions.showAllFeatures')
        }
        variant="link"
        rightIcon={props.isFeaturesOpen ? 'navigation/expand_less' : 'navigation/expand_more'}
        onClick={props.toggleFeaturesOpen}
        isDisabled={isNilOrEmpty(filteredFeatureOptions)}
      />
      <Show when={props.isFeaturesOpen && isNotNilOrEmpty(filteredFeatureOptions)}>
        <Stack width="100%" direction={['column', 'column', 'row', 'row']} spacing={[2, 2, 4, 4]}>
          <Show when={isNotNilOrEmpty(firstOptionsRow)}>
            <Box flex={1}>
              <CheckboxTree
                data-testid={suffixTestId('featuresPicker-firstColumn', props)}
                key={featureSearchValue}
                value={field.value ?? null}
                onSingleValueChange={(value) => {
                  handleCheckboxTreeSelect(value, 'first');
                }}
                options={firstOptionsRow}
              />
            </Box>
          </Show>
          <Show when={isNotNilOrEmpty(secondOptionsRow)}>
            <Box flex={1}>
              <CheckboxTree
                data-testid={suffixTestId('featuresPicker-secondColumn', props)}
                key={featureSearchValue}
                value={field.value ?? null}
                onSingleValueChange={(value) => {
                  handleCheckboxTreeSelect(value, 'second');
                }}
                options={secondOptionsRow}
              />
            </Box>
          </Show>
        </Stack>
      </Show>
    </VStack>
  );
}
