import * as Yup from 'yup';

import i18n from '@omnetic-dms/i18n';
import {CebiaEquipmentCredentials} from '@omnetic-dms/teas';

export const cebiaEquipmentAuthValidations = (): Yup.SchemaOf<CebiaEquipmentCredentials> =>
  Yup.object().shape({
    username: Yup.string().required(i18n.t('general.validations.loginIsInvalid')),
    password: Yup.string().required(i18n.t('general.validations.passwordIsInvalid')),
  });
