import {
  foundationTheme,
  ThemeColorPath,
  ThemeRadiusPath,
  ThemeShadowsPath,
} from 'platform/foundation';

import {dropdownTheme} from '../components/Dropdown/Dropdown.theme';
import {segmentTheme} from '../components/Segment/Segment.theme';
import {segmentItemTheme} from '../components/Segment/SegmentItem.theme';
import {dataGridTheme} from '../styles/DataGrid/DataGrid.theme';
import {dataGridGlobalTheme} from '../styles/DataGrid/Global.theme';

type ChoiceTheme = {
  backgroundColor: ThemeColorPath;
};

const Choice: ChoiceTheme = {
  backgroundColor: 'palettes.neutral.900.100',
};

type CardTheme = {
  elevation: ThemeShadowsPath;
  borderRadius: ThemeRadiusPath;
};

const Card: CardTheme = {
  elevation: 'elevation_1',
  borderRadius: 'small',
};

type SwitchTheme = {
  trackStyle: {backgroundColor: string};
};

const Switch: SwitchTheme = {
  trackStyle: {backgroundColor: foundationTheme.colors.palettes.neutral[200][100]},
};

type ButtonTheme = {
  variants: {
    ghost: {hover: {backgroundColor: string}; active: {backgroundColor: string}};
    link: {color: string};
  };
};

const Button: ButtonTheme = {
  variants: {
    ghost: {
      hover: {backgroundColor: foundationTheme.colors.palettes.neutral[30][100]},
      active: {backgroundColor: foundationTheme.colors.palettes.neutral[40][100]},
    },
    link: {color: foundationTheme.colors.text.link},
  },
};

const htmlStyle: {overflowX: string} = {
  overflowX: 'hidden',
};

export const componentsTheme = {
  ...foundationTheme,
  config: {
    cssVarPrefix: 'eag',
  },
  styles: {
    global: {
      ...dataGridGlobalTheme,
      html: {
        ...htmlStyle,
      },
    },
  },
  components: {
    ...foundationTheme.components,
    Card,
    DataGrid: dataGridTheme,
    Segment: segmentTheme,
    SegmentItem: segmentItemTheme,
    Button,
    Choice,
    Switch,
    Dropdown: dropdownTheme,
    Table: {},
    Status: {},
    Password: {},
    Form: {},
  },
} as const;

export type DefaultComponentsTheme = typeof componentsTheme;
