import {isNil} from 'ramda';

import {Nullish} from 'shared';

/**
 * @about A utility function to check if a number is null, undefined, or zero
 * @returns boolean
 * @example
 */
export const isNilOrZero = (number: number | Nullish) => isNil(number) || number === 0;
