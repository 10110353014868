import {AggregationStatusBar} from '../components/AggregationStatusBar/AggregationStatusBar';
import {TransformedDefinitionApiResponse} from '../types/Api';

const statusBarConfig = {
  statusPanels: [
    {
      statusPanel: 'agAggregationComponent',
      statusPanelParams: {
        aggFuncs: ['count'],
      },
    },
    {statusPanel: AggregationStatusBar},
  ],
};

export const getStatusBar = (definition: TransformedDefinitionApiResponse) =>
  definition.behavior.statusBarVisible ? statusBarConfig : undefined;
