import {AppLanguage, LocaleConfig} from 'platform/locale';

import {TestIdProps} from 'shared';

import {ColDef, ColGroupDef} from '../types/AgGridTypes';
import {ActivePreset, GetDataQueryResponse, TransformedDefinitionApiResponse} from '../types/Api';
import {EntityResourceResolver} from '../types/EntityResourceResolver';
import {columnToBasicColDefs} from './columnToBasicColDefs';
import {columnToFilterColDefs} from './columnToFilterColDefs';
import {columnToTypeColDefs} from './columnToTypeColDefs';

export type ColOrColGroupDef = ColDef | ColGroupDef;

export type TransformDefinitionProps = TestIdProps & {
  definition: TransformedDefinitionApiResponse;
  colDefOverrides?: Record<string, ColDef>;
  activePreset?: ActivePreset;
  entityResourceResolver?: EntityResourceResolver;
  dataQuery: GetDataQueryResponse;
  localeConfig: LocaleConfig;
  language: AppLanguage;
};

export const transformDefinition = (props: TransformDefinitionProps): ColOrColGroupDef[] => {
  const {definition, colDefOverrides, activePreset, entityResourceResolver} = props;

  if (!definition) {
    return [];
  }

  const {columns, groups} = definition;

  const colDefsArr: ColOrColGroupDef[] = [];

  [...columns]
    .sort((a, b) => {
      if (!activePreset) {
        return 0;
      }
      const aPresetColIndex = activePreset.columnsSettings.findIndex(
        (col) => col.columnKey === a.key
      );
      const bPresetColIndex = activePreset.columnsSettings.findIndex(
        (col) => col.columnKey === b.key
      );
      if (aPresetColIndex === -1) {
        return 1;
      }
      if (bPresetColIndex === -1) {
        return -1;
      }
      return aPresetColIndex - bPresetColIndex;
    })
    .forEach((column) => {
      const colOverride = (colDefOverrides ?? {})[column.key];
      const defaultPresetCol = activePreset?.columnsSettings?.find(
        (presetCol) => presetCol.columnKey === column.key
      );

      // @ts-ignore
      const defaultPresetSort = activePreset?.sort?.find((sort) => sort.column === column.key);
      // @ts-ignore
      const defaultPresetSortIndex = activePreset?.sort?.findIndex(
        // @ts-ignore
        (sort) => sort.column === column.key
      );

      const colDef: ColDef = {
        ...columnToBasicColDefs(
          props.dataQuery,
          definition,
          column,
          // @ts-ignore
          defaultPresetCol,
          defaultPresetSort,
          defaultPresetSortIndex === -1 ? undefined : defaultPresetSortIndex
        ),
        ...columnToTypeColDefs(column, props.localeConfig, props.language, entityResourceResolver),
        ...columnToFilterColDefs({
          column,
          ...props,
        }),
        ...(colOverride ?? {}),
      };

      if (!column.group || !groups) {
        colDefsArr.push(colDef);
      } else {
        const group = groups[column.group];

        if (!group) {
          colDefsArr.push(colDef);
          return;
        }

        const lastItemIndex = colDefsArr.length - 1;
        const lastItem = colDefsArr[lastItemIndex] as ColGroupDef;
        if (lastItem && lastItem.groupId === column.group) {
          colDefsArr[lastItemIndex] = {
            ...lastItem,
            children: [...lastItem.children, colDef],
          };
        } else {
          const item = {...group};
          item.children = [colDef];
          colDefsArr.push({
            ...group,
            children: [colDef],
          });
        }
      }
    });

  return colDefsArr;
};
