import {isNil, isNotNil} from 'ramda';

import {useGetMakeModelWithMakeQuery} from '@omnetic-dms/api';
import i18n, {FALLBACK_LANGUAGE} from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

import {getVehicleTitle} from '../utils/getVehicleTitle';

type Vehicle = {
  make?: string | null;
  vehicleType?: string;
  modelFamily?: string | null;
  trim?: string | null;
  variant?: string | null;
  title?: string | null;
};

export function useGetVehicleTitle(
  vehicle: Vehicle | Nullish
): [string | Nullish, {isLoading: boolean; isError: boolean}] {
  const {data, isLoading, isError} = useGetMakeModelWithMakeQuery(
    {
      vehicleType: vehicle?.vehicleType ?? '',
      make: vehicle?.make ?? '',
      lang: [i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE],
    },
    {skip: isNil(vehicle?.vehicleType)}
  );

  return [isNotNil(vehicle) ? getVehicleTitle(vehicle, data) : null, {isLoading, isError}];
}
