import {Button, closeCurrentDialog, openDialog, showNotification} from 'platform/components';
import {Box, Grid, GridItem, Show, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {FC, useMemo, useState} from 'react';

import {always, converge, equals, findIndex, identity, isNil, move, propEq} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  ContractInformationResponseBodyV2,
  CustomerResponseBodyV2,
  IdentityCardResponseBodyV2,
  LegalForm,
  PersonRequestBody,
  PersonResponseBodyV2,
  useCreateCheckoutOrderAdditionalCustomerMutation,
  useGetCustomerV2Query,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {
  CHECKOUT_ADDITIONAL_CUSTOMER_DEFAULT_ROLE,
  composeAddressLineFromCommonAddress,
  CustomerMatchOrCreate,
  handleApiError,
  VehicleFinancingCard,
} from '@omnetic-dms/shared';
import {
  IdentityCardData,
  isApiException,
  OrderDiscriminatorEnum,
  WithValidationErrors,
} from '@omnetic-dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {CheckoutOrderBillingProps} from '../types/CheckoutOrderBillingProps';
import {CheckoutAdditionalCustomers} from './CheckoutAdditionalCustomers';
import {CheckoutContractDeputyPersonFormState} from './CheckoutContractDeputyPerson';
import {CheckoutContractInformationFormState} from './CheckoutContractInformationForm';
import {CheckoutContractInformationItem} from './CheckoutContractInformationItem';
import {CheckoutOrderBillingAbroad} from './CheckoutOrderBillingAbroad';
import {CheckoutPersonContractInformationFormState} from './CheckoutPersonContractInformationForm';

const MAX_ADDITIONAL_CUSTOMERS = 1;

export const CheckoutSelectOrderBilling: FC<CheckoutOrderBillingProps & TestIdProps> = ({
  checkoutId,
  checkoutContractsInformation,
  order,
  refreshBusinessCaseCheckoutInfo,
  handlePersonSubmit,
  handleAdditionalPersonSubmit,
  handleAddressSubmit,
  handleAdditionalPersonAddressSubmit,
  handleCreateCheckoutContractInformation,
  handleCreateAdditionalCheckoutContractInformation,
  handleEditCheckoutContractInformation,
  handleSubmitDeputyPerson,
  handleSelectDeputyPerson,
  handleSelectContract,
  handleChangeTypeOfSaleVehicle,
  customerContractInformation,
  ...props
}) => {
  const {data: customer} = useGetCustomerV2Query(
    {customerId: props.customerId},
    {skip: isNilOrEmpty(props.customerId)}
  );

  const checkoutSelectedEntityId = match<string, string | null>(
    checkoutContractsInformation.customerContractInformation.legalForm
  )
    .with(
      'NATURAL_PERSON',
      always(checkoutContractsInformation.customerContractInformation.person?.id ?? null)
    )
    .with(
      'SELF_EMPLOYED',
      always(checkoutContractsInformation.customerContractInformation.businessInfo?.id ?? null)
    )
    .with(
      'LEGAL_ENTITY',
      always(checkoutContractsInformation.customerContractInformation.businessInfo?.id ?? null)
    )
    .otherwise(always(null));

  const filteredCustomerContractInformation = customerContractInformation.filter((contract) => {
    const contractSelectedEntityId = match(contract.legalForm)
      .with('NATURAL_PERSON', always(contract.person?.id ?? null))
      .with('SELF_EMPLOYED', always(contract.businessInfo?.id ?? null))
      .with('LEGAL_ENTITY', always(contract.businessInfo?.id ?? null))
      .otherwise(always(null));
    return !contract.isDeleted || contractSelectedEntityId === checkoutSelectedEntityId;
  });

  const isSoftDeleted = useMemo(
    () =>
      isNil(
        filteredCustomerContractInformation?.find(
          (contract) => contract.id === checkoutContractsInformation.customerContractInformation.id
        )
      ),
    [
      checkoutContractsInformation.customerContractInformation.id,
      filteredCustomerContractInformation,
    ]
  );

  const [createAdditionalCustomer] = useCreateCheckoutOrderAdditionalCustomerMutation();

  const checkoutContractInformation = reorderContracts(
    customer?.discriminator === 'BUSINESS' ? 'LEGAL_ENTITY' : 'SELF_EMPLOYED'
  )(filteredCustomerContractInformation ?? []).map((contract) => ({
    customerContractInformation: {
      id: contract.id,
      person: contract.person?.id
        ? {
            id: contract.person.id,
            personData: {
              firstName: contract.person.firstName,
              lastName: contract.person.lastName,
              middleName: contract.person.middleName,
              titleBefore: contract.person.titleBefore,
              titleAfter: contract.person.titleAfter,
              genderKey: contract.person.genderKey,
              roles: contract.person.roles,
              citizenshipCode: contract.person.citizenshipCode,
              birthdate: contract.person.birthdate,
              personalIdentifier: contract.person.personalIdentifier,
            },
            phoneNumbers: contract.person.phoneNumbers.map((phoneNumber) => ({
              type: phoneNumber.type,
              phoneNumber: {
                countryCode: phoneNumber.countryCode,
                prefix: phoneNumber.prefix,
                number: phoneNumber.number,
              },
            })),
            emails: contract.person.emails,
            identityCards: contract.person.identityCards.map((identityCard) => ({
              id: identityCard.id,
              identityCardData: {
                type: identityCard.type,
                cardNumber: identityCard.cardNumber,
                issuedOn: identityCard.issuedOn,
                validUntil: identityCard.validUntil,
                issuer: identityCard.issuer,
                issuedInCountryCode: identityCard.issuedInCountryCode,
                note: identityCard.note,
              },
            })),
            permanentAddress: contract.person.permanentAddress
              ? {
                  id: contract.person.permanentAddress.id,
                  addressData: {
                    city: contract.person.permanentAddress.address.city,
                    postalCode: contract.person.permanentAddress.address.zip,
                    countryCode: contract.person.permanentAddress.address.country,
                    addressLine1: contract.person.permanentAddress.address
                      ? composeAddressLineFromCommonAddress(
                          contract.person.permanentAddress.address
                        )
                      : null,
                    addressLine2: null,
                    type: contract.person.permanentAddress.type,
                    province: null,
                    invalid: contract.person.permanentAddress.invalid,
                  },
                }
              : null,
          }
        : null,
      permanent: contract.permanent,
      legalForm: contract.legalForm,
      bankAccounts: contract.bankAccounts.map((bankAccount) => ({
        id: bankAccount.id,
        bankAccountData: {
          name: bankAccount.name,
          countryCode: bankAccount.countryCode,
          ownerName: bankAccount.ownerName,
          iban: bankAccount.iban,
          swiftBic: bankAccount.swiftBic,
          currency: bankAccount.currency,
          number: bankAccount.number,
          bankCode: bankAccount.bankCode,
        },
      })),
      businessInfo: contract.businessInfo
        ? {
            id: contract.businessInfo.id,
            businessAddress: contract.businessInfo.address
              ? {
                  id: contract.businessInfo.address.id,
                  addressData: {
                    city: contract.businessInfo.address.address.city,
                    postalCode: contract.businessInfo.address.address.zip,
                    countryCode: contract.businessInfo.address.address.country,
                    addressLine1: contract.businessInfo.address.address
                      ? composeAddressLineFromCommonAddress(contract.businessInfo.address.address)
                      : null,
                    addressLine2: null,
                    type: contract.businessInfo.address.type,
                    province: null,
                    invalid: contract.businessInfo.address.invalid,
                  },
                }
              : null,
            businessInfoData: contract.businessInfo.businessInfo,
          }
        : null,
      customFieldsPayload: contract.customFieldsPayload,
      isDeleted: contract.isDeleted,
    },
    deputyPersons:
      contract.id === checkoutContractsInformation?.customerContractInformation.id
        ? checkoutContractsInformation?.deputyPersons
        : [],
    selectedIdentityCards:
      contract.id === checkoutContractsInformation?.customerContractInformation.id
        ? checkoutContractsInformation?.selectedIdentityCards
        : [],
  }));

  const [checked, setChecked] = useState<string>(
    checkoutContractsInformation?.customerContractInformation.id ??
      checkoutContractInformation?.[0]?.customerContractInformation?.id
  );

  const permanentPersonId = customerContractInformation?.find((contract) => contract.permanent)
    ?.person?.id;

  const [editOpen, setEditOpen] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([checked]);
  const [createOpen, setCreateOpen] = useState(false);

  const toggleCreateOpen = () => {
    setCreateOpen(!createOpen);
  };

  const toggleExpand = (contactPersonId: string) => () => {
    if (expanded.includes(contactPersonId)) {
      setExpanded(expanded.filter((id) => id !== contactPersonId));
    } else {
      setExpanded([...expanded, contactPersonId]);
    }
  };

  const toggleEdit = (contactPersonId: string) => () => {
    if (editOpen.includes(contactPersonId)) {
      setEditOpen(editOpen.filter((id) => id !== contactPersonId));
    } else {
      setEditOpen([...editOpen, contactPersonId]);
    }
  };

  const createContractInformation = async (
    values: CheckoutContractInformationFormState
  ): Promise<WithValidationErrors<void>> => {
    try {
      const contract = await handleCreateCheckoutContractInformation(order.id)(values);
      if (!contract) {
        return;
      }
      setCreateOpen(false);
      setChecked(contract.id);
      toggleExpand(contract.id)();
    } catch (e: any) {
      if (isApiException(e) && e.validationErrors) {
        return e.validationErrors;
      }
      throw e;
    }
  };

  const editContractInformation =
    (contractInformationId: string) =>
    async (values: CheckoutContractInformationFormState): Promise<WithValidationErrors<void>> => {
      try {
        await handleEditCheckoutContractInformation(
          props.customerId,
          contractInformationId,
          order.id
        )(values);
        toggleEdit(contractInformationId)();
      } catch (e: any) {
        if (isApiException(e) && e.validationErrors) {
          return e.validationErrors;
        }
        throw e;
      }
    };

  const submitDeputyPerson =
    (contractInformationId: string) =>
    async (
      values: CheckoutPersonContractInformationFormState
    ): Promise<WithValidationErrors<void>> => {
      try {
        await handleSubmitDeputyPerson(order.id, contractInformationId)(values);
      } catch (e: any) {
        if (isApiException(e) && e.validationErrors) {
          return e.validationErrors;
        }
        throw e;
      }
    };

  const selectDeputyPerson =
    (contractInformationId: string) =>
    async (values: CheckoutContractDeputyPersonFormState): Promise<void> => {
      await handleSelectDeputyPerson(order.id, contractInformationId)(values);
    };

  const handleIdentityCardSubmit =
    (person: PersonResponseBodyV2) =>
    async (
      cardData: IdentityCardData,
      cardIdentityId: string | null
    ): Promise<IdentityCardResponseBodyV2 | null> => {
      const requestBody = updatePersonRequestBody(cardIdentityId, cardData, person);

      if (cardIdentityId) {
        await handlePersonSubmit(requestBody, person.id);
        return (
          person.identityCards.find((identityCard) => identityCard.id === cardIdentityId) ?? null
        );
      } else {
        const updatedRequestBody: PersonRequestBody = requestBody;
        const _cardData = {
          type: cardData.type,
          cardNumber: cardData.cardNumber,
          issuedOn: cardData.issuedOn ?? null,
          validUntil: cardData.validUntil,
          issuer: cardData.issuer,
          issuedInCountryCode: cardData.issuedInCountryCode,
          note: cardData.note,
        };
        updatedRequestBody.identityCards.push({
          id: cardIdentityId,
          cardData: _cardData,
        });
        const updatedPerson = await handlePersonSubmit(updatedRequestBody, person.id);
        return (
          updatedPerson?.identityCards.find((identityCard) =>
            equals(_cardData, {
              type: identityCard.type,
              cardNumber: identityCard.cardNumber,
              issuedOn: identityCard.issuedOn,
              validUntil: identityCard.validUntil,
              issuer: identityCard.issuer,
              issuedInCountryCode: identityCard.issuedInCountryCode,
              note: identityCard.note,
            })
          ) ?? null
        );
      }
    };

  const handleAdditionalPersonIdentityCardSubmit = async (
    customerId: string,
    person: PersonResponseBodyV2,
    cardData: IdentityCardData,
    id: string | null
  ): Promise<IdentityCardResponseBodyV2 | null> => {
    const requestBody = updatePersonRequestBody(id, cardData, person);
    if (id) {
      await handleAdditionalPersonSubmit(customerId, requestBody, person.id);

      refreshBusinessCaseCheckoutInfo(null);

      return person.identityCards.find((identityCard) => identityCard.id === id) ?? null;
    } else {
      const updatedRequestBody: PersonRequestBody = requestBody;
      const _cardData = {
        type: cardData.type,
        cardNumber: cardData.cardNumber,
        issuedOn: cardData.issuedOn,
        validUntil: cardData.validUntil,
        issuer: cardData.issuer,
        issuedInCountryCode: cardData.issuedInCountryCode,
        note: cardData.note,
      };
      updatedRequestBody.identityCards.push({
        id,
        cardData: _cardData,
      });
      const updatedPerson = await handlePersonSubmit(updatedRequestBody, person.id);

      refreshBusinessCaseCheckoutInfo(null);

      return (
        updatedPerson?.identityCards.find((identityCard) =>
          equals(_cardData, {
            type: identityCard.type,
            cardNumber: identityCard.cardNumber,
            issuedOn: identityCard.issuedOn,
            validUntil: identityCard.validUntil,
            issuer: identityCard.issuer,
            issuedInCountryCode: identityCard.issuedInCountryCode,
            note: identityCard.note,
          })
        ) ?? null
      );
    }
  };

  const handleSelectCustomer = (customer: CustomerResponseBodyV2) =>
    createAdditionalCustomer({
      checkoutId,
      orderId: order.id,
      createAdditionalCustomerRequestBody: {
        customerId: customer.id,
        additionalCustomerType: CHECKOUT_ADDITIONAL_CUSTOMER_DEFAULT_ROLE,
      },
    })
      .unwrap()
      .then(() => refreshBusinessCaseCheckoutInfo(null))
      .then(closeCurrentDialog)
      .then(() => showNotification.success())
      .catch(handleApiError);

  const openCustomerSelector = () => {
    openDialog(
      <CustomerMatchOrCreate
        secondStepComponentType="BUSINESS_CASE"
        onCustomer={handleSelectCustomer}
        data-testid={props['data-testid']}
      />,
      {title: i18n.t('entity.customer.labels.customer')}
    );
  };

  return (
    <Grid columns={1}>
      <Text
        data-testid="text-checkout.order.selectBillingInformation"
        size="small"
        color="tertiary"
      >
        {i18n.t('general.labels.selectBillingInformation')}
      </Text>
      <VStack spacing={4}>
        {checkoutContractInformation?.map((contract, i) => (
          <GridItem key={contract?.customerContractInformation.id}>
            <CheckoutContractInformationItem
              customerId={props.customerId}
              data-testid={suffixTestId(`[${i}]`, props)}
              disableDeputyPerson={props.hasDeputyPersonSelectDisabled}
              isRadioDisabled={props.hasContractSelectDisabled}
              isEditDisabled={props.hasContractSelectDisabled}
              permanentPersonId={permanentPersonId}
              isSoftDeleted={
                isSoftDeleted &&
                contract.customerContractInformation.id ===
                  checkoutContractsInformation?.customerContractInformation.id
              }
              checkoutContractInformation={contract}
              checked={checked === contract?.customerContractInformation.id}
              toggleEdit={toggleEdit(contract?.customerContractInformation.id)}
              toggleExpand={toggleExpand(contract?.customerContractInformation.id)}
              editOpen={editOpen.includes(contract?.customerContractInformation.id)}
              expanded={expanded.includes(contract?.customerContractInformation.id)}
              onControlChange={(checked) => {
                if (checked) {
                  setCreateOpen(false);
                  setEditOpen([]);
                  setChecked(contract?.customerContractInformation.id);
                  setExpanded([contract?.customerContractInformation.id]);
                  handleSelectContract(order.id, contract?.customerContractInformation.id);
                }
              }}
              handleIdentityCardSubmit={handleIdentityCardSubmit}
              handlePersonSubmit={handlePersonSubmit}
              handleAddressSubmit={handleAddressSubmit}
              onSubmit={editContractInformation(contract?.customerContractInformation.id)}
              handleSubmitDeputyPerson={submitDeputyPerson(
                contract?.customerContractInformation.id
              )}
              handleSelectDeputyPerson={selectDeputyPerson(
                contract?.customerContractInformation.id
              )}
            />
          </GridItem>
        ))}
      </VStack>
      <CheckoutContractInformationItem
        disableDeputyPerson
        isRadioDisabled={props.hasContractSelectDisabled}
        isExpandable={!props.hasContractSelectDisabled}
        customerId={props.customerId}
        data-testid={suffixTestId(`createNew`, props)}
        id="createNewContract"
        toggleEdit={toggleCreateOpen}
        toggleExpand={toggleCreateOpen}
        checked={checked === 'create'}
        editOpen
        expanded={createOpen}
        onControlChange={(checked) => {
          if (checked) {
            setChecked('create');
            setExpanded([]);
            setCreateOpen(true);
          }
        }}
        permanentPersonId={permanentPersonId}
        handleIdentityCardSubmit={handleIdentityCardSubmit}
        handlePersonSubmit={handlePersonSubmit}
        handleAddressSubmit={handleAddressSubmit}
        onSubmit={createContractInformation}
      />
      <Show
        when={isNotNilOrEmpty(order.additionalCustomers)}
        whenFeatureEnabled={featureFlags.ACCOUNTING_ADDITIONAL_CUSTOMER}
      >
        <CheckoutAdditionalCustomers
          customerId={props.customerId}
          additionalCustomers={order.additionalCustomers}
          counterStartAt={2}
          order={order}
          checkoutId={checkoutId}
          permanentPersonId={permanentPersonId}
          refreshBusinessCaseCheckoutInfo={refreshBusinessCaseCheckoutInfo}
          handleAdditionalPersonIdentityCardSubmit={handleAdditionalPersonIdentityCardSubmit}
          handlePersonSubmit={handlePersonSubmit}
          handleAdditionalPersonSubmit={handleAdditionalPersonSubmit}
          handleAdditionalPersonAddressSubmit={handleAdditionalPersonAddressSubmit}
          handleEditCheckoutContractInformation={handleEditCheckoutContractInformation}
          handleCreateAdditionalCheckoutContractInformation={
            handleCreateAdditionalCheckoutContractInformation
          }
          data-testid={props['data-testid']}
        />
      </Show>
      <Show
        when={
          order.orderDiscriminator === OrderDiscriminatorEnum.SALE &&
          order.additionalCustomers.length < MAX_ADDITIONAL_CUSTOMERS
        }
        whenFeatureEnabled={featureFlags.ACCOUNTING_ADDITIONAL_CUSTOMER}
      >
        <Box>
          <Button
            title={i18n.t('entity.accounting.actions.addCustomerToInvoice')}
            data-testid={suffixTestId('addCustomerToInvoice', props)}
            onClick={openCustomerSelector}
            leftIcon="content/add_circle"
            variant="link"
            size="small"
          />
        </Box>
      </Show>
      <Show whenFeatureEnabled={featureFlags.CORE_FINANCING_INFORMATION_V2}>
        <VehicleFinancingCard
          vehicleId={props.saleVehicle?.vehicleId}
          isReadonly={props.readonly}
          data-testid={suffixTestId('financing', props)}
        />
      </Show>
      <Show when={order.orderDiscriminator === OrderDiscriminatorEnum.SALE}>
        <CheckoutOrderBillingAbroad
          handleChangeTypeOfSaleVehicle={handleChangeTypeOfSaleVehicle}
          checkoutId={checkoutId}
          orderId={order.id}
          typeOfSale={order.typeOfSale}
          data-testid={suffixTestId('billing', props)}
        />
      </Show>
    </Grid>
  );
};

const updatePersonRequestBody = (
  cardIdentityId: string | null,
  cardData: IdentityCardData,
  person: PersonResponseBodyV2
): PersonRequestBody => ({
  personData: {
    firstName: person.firstName,
    lastName: person.lastName,
    middleName: person.middleName,
    titleBefore: person.titleBefore,
    titleAfter: person.titleAfter,
    genderKey: person.genderKey,
    roles: person.roles,
    citizenshipCode: person.citizenshipCode,
    birthdate: person.birthdate,
    personalIdentifier: person.personalIdentifier,
  },
  phoneNumbers: person.phoneNumbers.map((item) => ({
    type: item.type,
    phoneNumber: {
      countryCode: item.countryCode,
      prefix: item.prefix,
      number: item.number,
    },
  })),
  emails: person.emails,
  permanentAddressId: person.permanentAddress?.id ?? null,
  identityCards: person.identityCards.map((identityCard) =>
    identityCard.id === cardIdentityId
      ? {
          id: cardIdentityId,
          cardData: {
            type: cardData.type,
            cardNumber: cardData.cardNumber,
            issuedOn: cardData.issuedOn,
            validUntil: cardData.validUntil,
            issuer: cardData.issuer,
            issuedInCountryCode: cardData.issuedInCountryCode,
            note: cardData.note,
          },
        }
      : {
          id: identityCard.id,
          cardData: {
            type: identityCard.type,
            cardNumber: identityCard.cardNumber,
            issuedOn: identityCard.issuedOn,
            validUntil: identityCard.validUntil,
            issuer: identityCard.issuer,
            issuedInCountryCode: identityCard.issuedInCountryCode,
            note: identityCard.note,
          },
        }
  ),
});

const reorderContracts = (form: LegalForm) =>
  converge(
    (index: number, list: ContractInformationResponseBodyV2[]) =>
      index > 0 ? move(index, 0, list) : list,
    [findIndex(propEq(form, 'legalForm')), identity]
  );
