import {ReactNode, useMemo, useState} from 'react';

import {keys, omit} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {sanitizeObject} from 'shared';

import {Filters} from '../types/Filters';
import {QueryFilterObject} from '../types/QueryFilterObject';
import {DataGridFiltersModelContext} from './DataGridFiltersModelContext';

export const DatagridFiltersModelProvider = ({
  children,
  initialValues,
  staticFilters,
}: {
  children: ReactNode;
  initialValues: Filters;
  staticFilters: QueryFilterObject;
}) => {
  const [state, setState] = useState<Filters>(initialValues);

  const value = useMemo(() => {
    const onFiltersChange = (newFilters: Filters) => {
      setState(newFilters);
    };

    const filterValuesWithoutStaticFilters = omit(keys(staticFilters), state);

    const isFilterActive = isNotNilOrEmpty(sanitizeObject(filterValuesWithoutStaticFilters));

    return {
      filterValues: state,
      isFilterActive,
      onFiltersChange,
      filterValuesWithoutStaticFilters,
    };
  }, [state]);

  return (
    <DataGridFiltersModelContext.Provider value={value}>
      {children}
    </DataGridFiltersModelContext.Provider>
  );
};
