import {FormControl, FormField} from 'platform/components';

import {UseFormReturn} from 'react-hook-form';

import {head, isNil} from 'ramda';

import {useGetMakeModelWithMakeQuery, useGetAllApiEnumsQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {DiscountFormType} from '../types/DiscountFormType';
import {getMakeOptions} from '../utils/getMakeOptions';
import {getModeFamilyOptions} from '../utils/getModelFamilyOptions';
import {getModelOptions} from '../utils/getModelOptions';
import {getTypeOptions} from '../utils/getTypeOptions';

const VEHICLE_TYPE_PROPERTY = 'vehicle_type';

interface VehicleFieldsProps extends TestIdProps {
  control: FormControl<DiscountFormType>;
  formApi: UseFormReturn<DiscountFormType>;
}

export function VehicleFields(props: VehicleFieldsProps) {
  const vehicleType = props.formApi.watch('vehicleType');
  const vehicleMake = props.formApi.watch('vehicleMake');

  const {data: makes, isLoading: isMakesLoading} = useGetMakeModelWithMakeQuery(
    {
      vehicleType: vehicleType ?? '',
      lang: [i18n.language],
    },
    {
      skip: isNil(vehicleType),
    }
  );
  const {data: models, isLoading: isModelsLoading} = useGetMakeModelWithMakeQuery(
    {
      vehicleType: vehicleType ?? '',
      make: vehicleMake ?? undefined,
      lang: [i18n.language],
    },
    {
      skip: isNil(vehicleType) || isNil(vehicleMake),
      selectFromResult: (result) => ({
        ...result,
        data: head(result?.data ?? [])?.models,
      }),
    }
  );
  const {data: vehicleTypes, isLoading: isVehicleTypesLoading} = useGetAllApiEnumsQuery(
    {lang: [i18n.language]},
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.find((item) => item.property_name === VEHICLE_TYPE_PROPERTY)?.keys,
      }),
    }
  );

  const handleMakeChange = () => {
    props.formApi.setValue('vehicleModels', []);
    props.formApi.setValue('vehicleModelFamilies', []);
  };

  const handleTypeChange = () => {
    props.formApi.setValue('vehicleMake', null);
    handleMakeChange();
  };

  return (
    <>
      <FormField
        control={props.control}
        name="vehicleType"
        type="choice"
        label={i18n.t('entity.vehicle.labels.type')}
        options={getTypeOptions(vehicleTypes ?? [])}
        isLoading={isVehicleTypesLoading}
        onChange={handleTypeChange}
        data-testid={suffixTestId('vehicleType', props)}
      />
      <FormField
        control={props.control}
        name="vehicleMake"
        type="choice"
        label={i18n.t('entity.vehicle.labels.make')}
        options={getMakeOptions(makes ?? [])}
        isLoading={isMakesLoading}
        onChange={handleMakeChange}
        isDisabled={isNil(props.formApi.watch('vehicleType'))}
        data-testid={suffixTestId('vehicleMake', props)}
      />
      <FormField
        control={props.control}
        name="vehicleModelFamilies"
        type="multiChoice"
        label={i18n.t('entity.vehicle.labels.modelFamily')}
        options={getModeFamilyOptions(models ?? [])}
        isLoading={isModelsLoading}
        isDisabled={isNil(props.formApi.watch('vehicleMake'))}
        data-testid={suffixTestId('vehicleModelFamilies', props)}
        isNotClearable
      />
      <FormField
        control={props.control}
        name="vehicleModels"
        type="multiChoice"
        label={i18n.t('entity.vehicle.labels.model')}
        options={getModelOptions(models ?? [])}
        isLoading={isModelsLoading}
        isDisabled={isNil(props.formApi.watch('vehicleMake'))}
        data-testid={suffixTestId('vehicleModels', props)}
        isNotClearable
      />
    </>
  );
}
