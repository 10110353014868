import {defaultTo, last, pipe, prop, sortBy} from 'ramda';

import {InterestActiveBusinessCaseResponseBody} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getLastActiveBusinessCase = pipe<
  [InterestActiveBusinessCaseResponseBody[] | Nullish],
  InterestActiveBusinessCaseResponseBody[],
  InterestActiveBusinessCaseResponseBody[],
  InterestActiveBusinessCaseResponseBody | undefined
>(defaultTo([]), sortBy(prop('createdAt')), last);
