import {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {TestIdProps} from 'shared';

import {useRouter} from '../../../hooks/useRouter';
import {selectAudit} from '../../../store/carAudit/selectors';
import {AssignmentIdentity} from '../../../types/AssignmentIdentity';
import {AssignmentContainer} from './AssignmentContainer';
import {AssignmentContext} from './AssignmentContext';

type AssignmentComponentType = {type: AssignmentIdentity['type']; external: boolean | null};

export const Assignment: FC<AssignmentComponentType & TestIdProps> = ({
  type,
  external,
  ...rest
}) => {
  const router = useRouter();
  const {params} = router;
  const audit = useSelector(selectAudit);

  // For each assignmentIdentityType should be used
  // individual id
  // (for vehicle - vehicleId, for audit - auditId)
  const pickId = (
    type: AssignmentIdentity['type'],
    queryId?: string | string[],
    auditId?: string
  ) => {
    switch (type) {
      case 'vehicle': {
        if (typeof queryId !== 'string') {
          throw Error('Missing parameter "id" in router.params');
          return undefined;
        }
        return queryId;
      }
      case 'vehicle_audit': {
        return auditId;
      }
      default:
        return undefined;
    }
  };

  const assignmentIdentityId = useMemo(
    () => pickId(type, String(params.id) || '', audit?.id),
    [type, params?.id, audit?.id]
  );

  if (!assignmentIdentityId) {
    return null;
  }

  return (
    <AssignmentContext.Provider value={{type, external, assignmentIdentityId}}>
      <AssignmentContainer data-testid={rest['data-testid']} />
    </AssignmentContext.Provider>
  );
};
