import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {createFieldForReset} from './createFieldForReset';
import {getResetValueByParamType} from './getResetValueByParamType';

export const resetCategoryFields = (
  category: AuditCategoryOfStructure,
  setFieldValue: (name: string, value: string | null) => void
) => {
  const categoryParamDefinitions = [
    ...(category.paramDefinitions?.relatedActions ?? []),
    ...(category.paramDefinitions?.additionalInformation ?? []),
  ];

  categoryParamDefinitions?.forEach((paramDefinition) => {
    const {name, type} = createFieldForReset(category.id, paramDefinition);
    setFieldValue(name, getResetValueByParamType(type));
  });

  category.childCategories?.forEach((category) => resetCategoryFields(category, setFieldValue));
};
