import {FormControl, FormField, Separator} from 'platform/components';
import {Box, HStack, Heading, Show, Space, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNumber} from 'ramda-adjunct';

import {
  useGetDictionaryQuery,
  useGetServiceCaseTypesQuery,
  useGetServiceOrderTypesQuery,
  useGetSettingsServiceOrderVariantsAllQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, settingsRoutes} from '@omnetic-dms/routes';

import {DefaultCustomer} from '../../../../components/DefaultCustomer/DefaultCustomer';
import {SettingsFooter} from '../../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../../components/SettingsSection/SettingsSection';
import {FormType} from '../../types/ServiceOrderVariant';

interface GeneralTabProps {
  control: FormControl<FormType>;
  formApi: UseFormReturn<FormType>;
}

export function GeneralTab(props: GeneralTabProps) {
  const {id} = useParams();
  const navigate = useNavigate();

  const {data: serviceOrderVariants} = useGetSettingsServiceOrderVariantsAllQuery();

  const {data: priceTypes, isLoading: isPriceTypesLoading} = useGetDictionaryQuery({
    category: 'SVCPRICETYPEMATERIAL',
  });
  const {data: rateTypes, isLoading: isRateTypesLoading} = useGetDictionaryQuery({
    category: 'SVCRATETYPE',
  });
  const {data: orderTypes, isLoading: isOrderTypesLoading} = useGetServiceOrderTypesQuery();
  const {data: serviceCaseTypes, isLoading: isServiceCaseTypeLoading} =
    useGetServiceCaseTypesQuery();

  const getDefaultServiceVariantByServiceCaseType = (serviceCaseTypeId: string) =>
    serviceOrderVariants?.serviceOrderVariants?.serviceOrderVariant?.find(
      (variant) =>
        variant.serviceCaseTypeId === serviceCaseTypeId && variant.isDefaultForServiceCaseType
    );

  const defaultServiceVariant = getDefaultServiceVariantByServiceCaseType(
    props.formApi.watch('general.serviceCaseTypeId') ?? ''
  );

  const isDefaultServiceVariant = !!(id && defaultServiceVariant?.id === id);

  const handleServiceCaseTypeChange = (value: string | number | null) => {
    if (isNumber(value) || isNil(value)) {
      return;
    }

    const selectedDefaultServiceVariant = getDefaultServiceVariantByServiceCaseType(value);

    props.formApi.setValue(
      'general.isDefaultForServiceCaseType',
      !!(!selectedDefaultServiceVariant || (id && selectedDefaultServiceVariant.id === id))
    );
  };

  return (
    <>
      <SettingsSection>
        <VStack spacing={4}>
          <FormField
            control={props.control}
            label={i18n.t('general.labels.name')}
            name="general.name"
            type="text"
            isRequired
            data-testid={testIds.settings.serviceOrderVariantsDetail('name')}
          />
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={props.control}
                label={i18n.t('entity.settings.labels.serviceCaseType')}
                name="general.serviceCaseTypeId"
                type="choice"
                isLoading={isServiceCaseTypeLoading}
                options={serviceCaseTypes?.map((profile) => ({
                  label: profile?.name,
                  value: profile?.id,
                }))}
                onChange={handleServiceCaseTypeChange}
                isRequired
                isNotClearable
                data-testid={testIds.settings.serviceOrderVariantsDetail('serviceCaseTypeId')}
              />
            </Box>
            <Box flex={1}>
              <Space vertical={6} />
              <FormField
                control={props.control}
                label={i18n.t('entity.settings.labels.defaultServiceCaseType')}
                name="general.isDefaultForServiceCaseType"
                type="switch"
                isDisabled={!defaultServiceVariant || isDefaultServiceVariant}
                data-testid={testIds.settings.serviceOrderVariantsDetail('defaultServiceCaseType')}
              />
            </Box>
          </HStack>
          <Separator spacing={0} />
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={props.control}
                label={i18n.t('entity.serviceOrder.labels.serviceOrderType')}
                name="general.serviceOrderTypeId"
                type="choice"
                isLoading={isOrderTypesLoading}
                options={orderTypes?.map((orderType) => ({
                  label: orderType?.name,
                  value: orderType?.id,
                }))}
                isRequired
                data-testid={testIds.settings.serviceOrderVariantsDetail('orderType')}
              />
            </Box>
            <Box flex={1}>
              <Space vertical={6} />
              <FormField
                control={props.control}
                label={i18n.t('general.labels.unitPriceWithVat')}
                name="general.isUnitPriceWithVat"
                type="switch"
                data-testid={testIds.settings.serviceOrderVariantsDetail('unitPriceWithVat')}
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={props.control}
                label={i18n.t('entity.serviceOrder.labels.materialPriceType')}
                name="general.priceType"
                type="choice"
                options={priceTypes}
                isLoading={isPriceTypesLoading}
                isRequired
                data-testid={testIds.settings.serviceOrderVariantsDetail('materialPriceType')}
              />
            </Box>
            <Box flex={1}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={props.control}
                    label={i18n.t('entity.serviceOrder.labels.materialPriceRatio')}
                    name="general.priceTypeRatio"
                    type="integer"
                    data-testid={testIds.settings.serviceOrderVariantsDetail('materialRatio')}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={props.control}
                    label={i18n.t('entity.serviceOrder.labels.minimumMaterialMargin')}
                    name="general.minimalMaterialMargin"
                    type="integer"
                    data-testid={testIds.settings.serviceOrderVariantsDetail('materialMargin')}
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={props.control}
                label={i18n.t('entity.order.labels.workRateType')}
                name="general.rateType"
                type="choice"
                options={rateTypes}
                isLoading={isRateTypesLoading}
                isRequired
                data-testid={testIds.settings.serviceOrderVariantsDetail('workRateType')}
              />
            </Box>
            <Box flex={1}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={props.control}
                    label={i18n.t('entity.serviceOrder.labels.workRatio')}
                    name="general.rateTypeRatio"
                    type="integer"
                    data-testid={testIds.settings.serviceOrderVariantsDetail('workRatio')}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={props.control}
                    label={i18n.t('entity.serviceOrder.labels.minimumWorkMargin')}
                    name="general.minimalWorkMargin"
                    type="integer"
                    data-testid={testIds.settings.serviceOrderVariantsDetail('workMargin')}
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={props.control}
                label={i18n.t('general.labels.suffix')}
                name="general.suffix"
                type="text"
                isRequired
                data-testid={testIds.settings.serviceOrderVariantsDetail('suffix')}
              />
            </Box>
            <Space fillAvailable />
          </HStack>
          <FormField
            control={props.control}
            label={i18n.t('general.labels.note')}
            name="general.note"
            type="textarea"
            data-testid={testIds.settings.serviceOrderVariantsDetail('note')}
          />
        </VStack>
      </SettingsSection>
      <Separator spacing={6} />
      <VStack spacing={6}>
        <DefaultCustomer
          customerId={props.formApi.watch('general.defaultCustomerId')}
          onCustomerChange={(customerId) =>
            props.formApi.setValue('general.defaultCustomerId', customerId)
          }
          data-testid={testIds.settings.serviceOrderVariantsDetail('defaultCustomer')}
        />
        <Show when={props.formApi.watch('general.defaultCustomerId')}>
          <FormField
            control={props.control}
            label={i18n.t('entity.serviceOrder.labels.changeDefaultCustomerIsAllowed')}
            name="general.isChangeableDefaultCustomer"
            type="switch"
            data-testid={testIds.settings.serviceOrderVariantsDetail('isChangeableDefaultCustomer')}
          />
        </Show>
      </VStack>
      <Separator spacing={6} />
      <VStack spacing={6}>
        <Heading size={4}>{i18n.t('entity.serviceOrder.labels.integration')}</Heading>
        <FormField
          control={props.control}
          label={i18n.t('entity.serviceOrder.labels.automaticallySendToChronos')}
          name="general.isAutomaticSendToChronos"
          type="switch"
          data-testid={testIds.settings.serviceOrderVariantsDetail('isAutomaticSendToChronos')}
        />
      </VStack>
      <Space vertical={20} />
      <SettingsFooter
        actions={[
          {
            type: 'button',
            variant: 'secondary',
            title: i18n.t('general.actions.discardChanges'),
            onClick: () => navigate(settingsRoutes.serviceOrderVariants),
          },
          {
            type: 'form-button',
            control: props.control,
            buttonType: 'submit',
            variant: 'primary',
            title: i18n.t('general.actions.saveChanges'),
          },
        ]}
        data-testid={testIds.settings.serviceOrderVariantsDetail('footer')}
      />
    </>
  );
}
