import * as Yup from 'yup';

import i18n from '@omnetic-dms/i18n';
import {CebiaAutotracerAndReportCredentials} from '@omnetic-dms/teas';

export const cebiaAuthValidations = (): Yup.SchemaOf<CebiaAutotracerAndReportCredentials> =>
  Yup.object().shape({
    username: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
    password: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
  });
