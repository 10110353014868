import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CreateRoundingDefinitionRequestBody} from '../../types/CreateRoundingDefinitionRequestBody';
import {Enum} from '../../types/Enum';
import {RoundingDefinition} from '../../types/RoundingDefinition';

export type DocumentRoundingDefinitionFormType = Omit<
  CreateRoundingDefinitionRequestBody,
  'branchId'
> & {
  branchId: string | null;
  isSystem?: boolean;
};

type DocumentRoundingDefitionsState = {
  items?: RoundingDefinition[];
  detail?: DocumentRoundingDefitionsDetailState;
  roundingStepList: Enum[];
};

interface DocumentRoundingDefitionsDetailState {
  id: string;
  data: DocumentRoundingDefinitionFormType;
}

const initialState: DocumentRoundingDefitionsState = {
  items: [],
  roundingStepList: [],
};

const DOCUMENT_ROUDNING_DEFINITIONS_SLICE_NAME = 'documentRoundingDefinitions';

const {actions, reducer} = createSlice({
  initialState,
  name: DOCUMENT_ROUDNING_DEFINITIONS_SLICE_NAME,
  reducers: {
    setDocumentRoundingDefinitionItems(state, {payload}: PayloadAction<Array<RoundingDefinition>>) {
      state.items = payload;
    },
    deleteDocumentRoundingDefinitionItem(state, {payload}: PayloadAction<string>) {
      const index = state.items?.findIndex(({id}) => id === payload) ?? -1;

      if (index > -1) {
        state.items?.splice(index, 1);
      }
    },
    setDetail(state, {payload}: PayloadAction<DocumentRoundingDefitionsState['detail']>) {
      state.detail = payload;
    },
    setRoundingStepList(
      state,
      {payload}: PayloadAction<DocumentRoundingDefitionsState['roundingStepList']>
    ) {
      state.roundingStepList = payload;
    },
  },
});

export const roundingDefinitionActions = actions;
export const documentRoundingDefinitionsReducer = reducer;
