import {DataStatus} from 'platform/components';
import {Show} from 'platform/foundation';

import {find} from 'ramda';

import {useGetServiceCaseOrderVariantsQuery, useGetServiceOrderQuery} from '@omnetic-dms/api';

import {suffixTestId, TestIdProps} from 'shared';

import {OrderCommercialForm} from './OrderCommercialForm';
import {OrderComplaintForm} from './OrderComplaintForm';
import {OrderContractForm} from './OrderContractForm';
import {OrderInsuranceForm} from './OrderInsuranceForm';
import {OrderInternalForm} from './OrderInternalForm';
import {OrderWarrantyForm} from './OrderWarrantyForm';

interface OrderDetailProps extends TestIdProps {
  orderId: string;
  serviceCaseId: string;
  isReadOnly?: boolean;
}

export function OrderDetail(props: OrderDetailProps) {
  const {
    data: serviceOrder,
    isError: isServiceOrderError,
    isLoading: isServiceOrderLoading,
  } = useGetServiceOrderQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.orderId,
  });
  const {
    data: orderVariants,
    isLoading: isOrderVariantsLoading,
    isError: isOrderVariantsError,
  } = useGetServiceCaseOrderVariantsQuery({
    serviceCaseId: props.serviceCaseId,
  });

  const type = find(
    (variant) => serviceOrder?.serviceOrderVariantId === variant?.id,
    orderVariants ?? []
  )?.type;

  const isLoading = isServiceOrderLoading || isOrderVariantsLoading;
  const isError = isServiceOrderError || isOrderVariantsError;

  return (
    <DataStatus isLoading={isLoading} isError={isError} isEmpty={!serviceOrder} minHeight={144}>
      <>
        <Show when={type === 'COM'}>
          <OrderCommercialForm
            serviceOrderId={props.orderId}
            serviceCaseId={props.serviceCaseId}
            isReadOnly={props.isReadOnly}
            data-testid={suffixTestId('detailForm', props)}
          />
        </Show>
        <Show when={type === 'INS'}>
          <OrderInsuranceForm
            serviceOrderId={props.orderId}
            serviceCaseId={props.serviceCaseId}
            isReadOnly={props.isReadOnly}
            data-testid={suffixTestId('detailForm', props)}
          />
        </Show>
        <Show when={type === 'INT'}>
          <OrderInternalForm
            serviceOrderId={props.orderId}
            serviceCaseId={props.serviceCaseId}
            isReadOnly={props.isReadOnly}
            data-testid={suffixTestId('detailForm', props)}
          />
        </Show>
        <Show when={type === 'CON'}>
          <OrderContractForm
            serviceOrderId={props.orderId}
            serviceCaseId={props.serviceCaseId}
            isReadOnly={props.isReadOnly}
            data-testid={suffixTestId('detailForm', props)}
          />
        </Show>
        <Show when={type === 'WAR'}>
          <OrderWarrantyForm
            serviceOrderId={props.orderId}
            serviceCaseId={props.serviceCaseId}
            isReadOnly={props.isReadOnly}
            data-testid={suffixTestId('detailForm', props)}
          />
        </Show>
        <Show when={type === 'CLA'}>
          <OrderComplaintForm
            serviceOrderId={props.orderId}
            serviceCaseId={props.serviceCaseId}
            isReadOnly={props.isReadOnly}
            data-testid={suffixTestId('detailForm', props)}
          />
        </Show>
      </>
    </DataStatus>
  );
}
