import {Alert} from 'platform/components';
import {Box} from 'platform/foundation';

import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {usePermissions} from '@omnetic-dms/shared';

import {selectAudit, selectAuditState} from '../../../store/carAudit/selectors';
import {useCheckPermissions} from '../hooks/useCheckPermissions';

export function ToFinishingComment() {
  const audit = useSelector(selectAudit);
  const auditState = useSelector(selectAuditState);

  const [finishInspection, closeInspection, updateInspection] = usePermissions({
    permissionKeys: ['finishInspection', 'closeInspection', 'updateInspection'],
    scopes: {
      closeInspection: {inspectionType: audit?.inspectionType},
      finishInspection: {inspectionType: audit?.inspectionType},
      updateInspection: {inspectionType: audit?.inspectionType},
    },
  });

  const {canSeeToFinishingNote} = useCheckPermissions({
    closeInspection,
    finishInspection,
    updateInspection,
  });
  const auditToFinishingNote = audit?.inspectionNote;

  if (!audit || !auditState || !auditToFinishingNote || !canSeeToFinishingNote(auditState)) {
    return null;
  }

  return (
    <Box paddingBottom={4}>
      <Alert
        type="inline"
        title={i18n.t('entity.inspection.notifications.toFinishing.title')}
        message={auditToFinishingNote}
      />
    </Box>
  );
}
