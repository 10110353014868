import {IconProps} from 'platform/foundation';

import {ActionDefinitionObject} from '../types/ActionDefinition';

export const transformActions = (actions: any): ActionDefinitionObject =>
  // @ts-ignore
  actions?.reduce((accumulator, action) => {
    accumulator[action.key] = {
      title: action.title,
      description: action.description,
      variant: action.variant || 'primary',
      icon: {
        value: (action.icon ?? '') as IconProps['value'],
      },
      bulkAction: action.bulk,
      singleAction: action.single,
      confirm: false,
    };
    return accumulator;
  }, {} as ActionDefinitionObject);
