import {Grid} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {IdentityCardResponseBodyV2} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  AutoSave,
  Form,
  FormRenderProps,
  selectIdentityCardTypes,
  IdentityCardData,
  $IdentityCardData,
  noop,
} from '@omnetic-dms/teas';

import {appendSuffix, suffixTestId, TestIdProps} from 'shared';

export interface IdentityCardFormProps {
  initialValues?: IdentityCardData;
  onSubmit?: (values: IdentityCardResponseBodyV2) => void;
  saveOnChange?: boolean;
  noValidate?: boolean;
  WrapperComponent?: FC<FormRenderProps<IdentityCardData>>;
}

export const IdentityCardForm: FC<IdentityCardFormProps & TestIdProps> = ({
  initialValues,
  onSubmit,
  saveOnChange,
  WrapperComponent,
  ...rest
}) => {
  const identityCardTypeList = useSelector(selectIdentityCardTypes);
  return (
    <Form<IdentityCardData>
      formId={appendSuffix('form', rest['data-testid'])}
      onSubmit={onSubmit}
      schema={$IdentityCardData}
      initialValues={initialValues}
      render={(formRendererProps) => {
        const {Field} = formRendererProps;

        const form = (
          <>
            {saveOnChange && <AutoSave save={onSubmit || noop} saveOnChange={saveOnChange} />}
            <Grid columns={2}>
              <Field
                as="select"
                name="type"
                label={i18n.t('entity.identityCard.labels.type')}
                options={identityCardTypeList}
                getOptionLabel={(identityCardType) => `${identityCardType.name}`}
                getOptionValue={(identityCardType) => `${identityCardType.code}`}
              />
              <Field name="cardNumber" label={i18n.t('entity.identityCard.labels.cardNumber')} />
            </Grid>
            <Grid columns={2}>
              <Field as="date" name="issuedOn" label={i18n.t('general.labels.issuedOn')} />
              <Field
                as="date"
                name="validUntil"
                label={i18n.t('entity.identityCard.labels.validUntil')}
              />
            </Grid>
            <Grid columns={2}>
              <Field name="issuer" label={i18n.t('entity.identityCard.labels.issuer')} />
              <Field
                as="country-select"
                name="issuedInCountryCode"
                label={i18n.t('entity.identityCard.labels.issuedInCountryCode')}
              />
            </Grid>
            <Grid columns={1}>
              <Field name="note" label={i18n.t('entity.identityCard.labels.note')} />
            </Grid>
          </>
        );

        if (WrapperComponent) {
          return (
            <WrapperComponent
              {...formRendererProps}
              data-testid={suffixTestId('identityCardWrapper', rest)}
            >
              {form}
            </WrapperComponent>
          );
        }

        return form;
      }}
    />
  );
};
