import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import i18n from '@omnetic-dms/i18n';

import {CostsMapType, Preset, PresetItem} from '../../types/CommonTypes';
import {COSTS_SLICE_NAME} from './constants';

type SetPresetLoading = {
  vehicleId: string;
  isLoading: boolean;
};

export type VehicleId = {
  vehicleId: string;
};

export type LoadNote = {
  note: string;
} & VehicleId;

export type SaveDefaultPreset = {
  defaultPreset: Preset;
} & VehicleId;

export type LoadPreset = {
  preset: Preset;
} & VehicleId;

export type HandleCostChange = {
  value: string | number | boolean;
  updatedKey: string;
} & VehicleAndPresetIds;

export type HandleNoteChange = {
  note: string;
} & VehicleId;

export type VehicleAndPresetIds = {
  id: string;
} & VehicleId;

export type CostStateType = {
  note?: string;
  isLoading?: boolean;
  isSaving?: boolean;
  totalPrice?: number;
  defaultPreset?: Preset;
  hasNoteChanged?: boolean;
  presetItems?: Preset['presetItems'];
};

export type UpdateTotalVehicleCostsPayload = {
  id: string;
  totalCosts?: number;
};

export const DEFAULT_PRESET_ITEM: PresetItem = {
  title: '',
  type: 'other',
  cost: '',
  currency: '',
  note: '',
  uuid: '',
};

export const INITIAL_STATE: CostsMapType<CostStateType> = {};

export const COST_INITIAL_STATE: CostStateType = {
  note: '',
  totalPrice: 0,
  isLoading: true,
  isSaving: false,
  hasNoteChanged: false,
};

export type CostsStateType = CostsMapType<CostStateType>;

export const costsSlice = createSlice({
  name: COSTS_SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setDefaultPreset(
      state,
      {payload: {vehicleId, defaultPreset}}: PayloadAction<SaveDefaultPreset>
    ) {
      const cost = state[vehicleId] ?? COST_INITIAL_STATE;
      state[vehicleId] = {
        ...cost,
        defaultPreset,
      };
    },
    loadNote(state, action: PayloadAction<LoadNote>) {
      const cost = state[action.payload.vehicleId] ?? COST_INITIAL_STATE;
      state[action.payload.vehicleId] = {
        ...cost,
        note: action.payload.note,
      };
    },
    loadPreset(state, {payload: {vehicleId, preset}}: PayloadAction<LoadPreset>) {
      const cost = state[vehicleId] ?? COST_INITIAL_STATE;
      state[vehicleId] = {
        ...cost,
        ...preset,
        note: cost.note ?? preset.note ?? undefined,
        isLoading: false,
      };
    },
    handleCostChange(
      state,
      {payload: {id, vehicleId, updatedKey, value}}: PayloadAction<HandleCostChange>
    ) {
      const cost = state[vehicleId] ?? COST_INITIAL_STATE;
      const presetItems = cost.presetItems || {};
      const presetItem = presetItems[id] || DEFAULT_PRESET_ITEM;

      presetItems[id] = {
        ...presetItem,
        __dirty: true,
        [updatedKey]: value,
      };

      state[vehicleId] = {
        ...cost,
        presetItems,
      };
    },
    handleNoteChange(state, {payload: {vehicleId, note}}: PayloadAction<HandleNoteChange>) {
      const cost = state[vehicleId] ?? COST_INITIAL_STATE;
      state[vehicleId] = {
        ...cost,
        note,
        hasNoteChanged: true,
      };
    },
    submitNoteChange(state, {payload: {vehicleId}}: PayloadAction<VehicleId>) {
      const cost = state[vehicleId] ?? COST_INITIAL_STATE;
      state[vehicleId] = {
        ...cost,
        hasNoteChanged: false,
      };
    },
    submitRowChange(state, {payload: {vehicleId, id}}: PayloadAction<VehicleAndPresetIds>) {
      const cost = state[vehicleId] ?? COST_INITIAL_STATE;

      if (cost.presetItems) {
        cost.presetItems[id] = {
          ...cost?.presetItems?.[id],
          __dirty: false,
        };
      }

      state[vehicleId] = {
        ...cost,
        presetItems: cost.presetItems,
      };
    },
    addCost(state, {payload: {vehicleId}}: PayloadAction<VehicleId>) {
      const cost = state[vehicleId] ?? COST_INITIAL_STATE;

      const tempId = `temp_${Date.now().toString()}`;

      if (cost.presetItems) {
        cost.presetItems[tempId] = {
          title: i18n.t('entity.vehicle.labels.costItemTitleOther'),
          type: 'other',
          cost: '0',
          currency: 'CZK',
          note: '',
          enabled: true,
          uuid: tempId,
        };
      }

      state[vehicleId] = {
        ...cost,
        presetItems: cost.presetItems,
      };
    },
    resetCosts(state, {payload: {vehicleId}}: PayloadAction<VehicleId>) {
      state[vehicleId] = COST_INITIAL_STATE;
    },
    removeCost(state, {payload: {vehicleId, id}}: PayloadAction<VehicleAndPresetIds>) {
      const cost = state[vehicleId] ?? COST_INITIAL_STATE;

      delete cost?.presetItems?.[id];

      state[vehicleId] = {
        ...cost,
        presetItems: cost.presetItems,
      };
    },
    setCostsTotalPrice(state, {payload: {vehicleId}}: PayloadAction<VehicleId>) {
      const cost = state[vehicleId] ?? COST_INITIAL_STATE;

      const presetItems = Object.keys(cost?.presetItems ?? {}).length
        ? Object.values(cost.presetItems ?? {})
        : [];

      const totalPrice = presetItems
        .filter(({enabled}) => enabled)
        .reduce((prev, current) => Number(current.cost) + prev, 0);

      state[vehicleId] = {
        ...cost,
        totalPrice,
      };
    },
    setIsSaving(state, {payload: {vehicleId, isLoading}}: PayloadAction<SetPresetLoading>) {
      const cost = state[vehicleId] ?? COST_INITIAL_STATE;
      state[vehicleId] = {
        ...cost,
        isSaving: isLoading,
      };
    },
  },
});

const {reducer, actions} = costsSlice;

export const {
  setDefaultPreset,
  loadNote,
  loadPreset,
  handleCostChange,
  handleNoteChange,
  submitNoteChange,
  submitRowChange,
  addCost,
  resetCosts,
  removeCost,
  setCostsTotalPrice,
  setIsSaving,
} = actions;

export const costsReducer = reducer;
