import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {userApi} from '../api/userApi';
import {BranchState} from './BranchState';

const initialState = {} as BranchState;

export const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    setActiveBranchId: {
      reducer: (state, action: PayloadAction<string>) => {
        state.activeBranchId = action.payload;
      },
      prepare: (value?: string) => ({payload: value || '-'}),
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getCurrentUserInfo.matchFulfilled, (state, {payload}) => {
      state.activeBranchId = payload.settings?.branch || payload.defaultBranch?.id || '';
      state.defaultBranchId = payload.defaultBranch?.id;
      state.userOptions = payload.branches?.map(({id, name}) => ({
        label: name,
        value: id,
      }));
    });
  },
});

export const {setActiveBranchId} = branchSlice.actions;
