import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {BackgroundRemovalFormOld} from '../BackgroundRemovalDetail/components/BackgroundRemovalFormOld';

export function BackgroundRemovalOld() {
  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.salesSettings.labels.backgroundRemoval'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
        ],
      }}
      data-testid="settings-backgroundRemoval"
      description={i18n.t('page.salesSettings.backgroundRemoval.common.description')}
    >
      <BackgroundRemovalFormOld data-testid="settings-backgroundRemovalFormOld" />
    </SettingsTemplate>
  );
}
