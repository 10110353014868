import {Text} from 'platform/foundation';

import {indexBy, isEmpty} from 'ramda';

import {useReadCodelistQuery} from '@omnetic-dms/api';

import {EMPTY_PLACEHOLDER} from '../constants/placeholders';

export const CUSTOMER_GROUPS_CODELIST_ID = 'customer_group';

export function useCustomerGroups() {
  const {data: codelist} = useReadCodelistQuery({codelistId: CUSTOMER_GROUPS_CODELIST_ID});

  const codes = indexBy((item) => item.codeId, codelist?.codes ?? []);

  const customerGroupOptions = codelist?.codes
    .filter((code) => !code.isDisabled)
    .map((code) => ({
      label: code.name,
      value: code.codeId,
    }));

  const getCustomerGroupsAttribute = (groupIds?: string[]) =>
    isEmpty(groupIds)
      ? EMPTY_PLACEHOLDER
      : groupIds?.map((groupId) => (
          <Text key={groupId} size="xSmall">
            {codes[groupId]?.name}
          </Text>
        ));

  return {customerGroupOptions, getCustomerGroupsAttribute};
}
