import {Dropdown} from 'platform/components';
import {Box, Heading, Space, VStack} from 'platform/foundation';

import {useGetCurrentUserInfoQuery, useGetRolesQuery} from '@omnetic-dms/api';
import {browserStorageKey} from '@omnetic-dms/config';
import {environment} from '@omnetic-dms/environment';
import i18n from '@omnetic-dms/i18n';

import {CI_MODE, suffixTestId, TestIdProps, useBoolean} from 'shared';

import {AclDebugCard} from './components/AclDebugCard';
import {ApiValidation} from './components/ApiValidation';
import {CacheCleanup} from './components/CacheCleanup';
import {ContentEditable} from './components/ContentEditable';
import {DevSettingsHeaderButton} from './components/DevSettingsHeaderButton';
import {FeatureFlagsMock} from './components/FeatureFlagsMock';
import {TranslationDebug} from './components/TranslationDebug';
import {DEBUG_ROLE_REFIX} from './utils/const';
import {getFixedTranslation} from './utils/getFixedTranslation';

const isDev = environment.ENV_TYPE === 'dev';

interface DevSettingsProps extends TestIdProps {}

export function DevSettings(props: DevSettingsProps) {
  const [isOpen, setOpen, setClosed] = useBoolean();
  const [isValidatingApiTraffic, startApiTrafficValidation, stopApiTrafficValidation] =
    useBoolean();
  const {data: currentUser} = useGetCurrentUserInfoQuery();
  const {data: roles} = useGetRolesQuery({});

  const isMockingFlagsSettingsOn = localStorage.getItem(browserStorageKey.MOCK_FLAGS_KEY) !== null;
  const isContentEditableSettingsOn = document.body.contentEditable === 'true';
  const isTranslationsSettingsOn = i18n.language === CI_MODE;
  const isRoleDebuggingOn = roles?.some(
    (role) => role.title === `${DEBUG_ROLE_REFIX}-${currentUser?.id}`
  );

  if (!isDev) {
    return null;
  }

  const isAnyDevSettingEnabled =
    isRoleDebuggingOn ||
    isTranslationsSettingsOn ||
    isContentEditableSettingsOn ||
    isValidatingApiTraffic ||
    isMockingFlagsSettingsOn;

  return (
    <Dropdown
      onOpen={setOpen}
      onClose={setClosed}
      dropdownControl={
        <DevSettingsHeaderButton
          isOpen={isOpen}
          isAnyDevSettingEnabled={isAnyDevSettingEnabled}
          data-testid={props['data-testid']}
        />
      }
      strategy="fixed"
      data-testid={suffixTestId('devSettings-dropdown', props)}
    >
      <Box padding={2} width={250}>
        <Heading data-testid={suffixTestId('devSettings-activeBranch', props)} size={2}>
          {getFixedTranslation('page.settings.labels.devSettings')}
        </Heading>

        <Space vertical={4} />

        <VStack spacing={2}>
          <TranslationDebug />
          <ContentEditable />
          <CacheCleanup />
          <FeatureFlagsMock />
          <ApiValidation
            isValidating={isValidatingApiTraffic}
            startParsingValidation={startApiTrafficValidation}
            stopParsingValidation={stopApiTrafficValidation}
          />
          <AclDebugCard />
        </VStack>
      </Box>
    </Dropdown>
  );
}
