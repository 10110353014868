import {moduleKeys} from '@omnetic-dms/config';

import * as routes from './routes';

type StronglyKeyedMap<T, K extends keyof T, V> = {[k in K]: V};
type TestIdArg<T extends Record<string, string>> = StronglyKeyedMap<
  T,
  keyof T,
  (customTestId?: string) => string
>;

const buildTestId = <T extends Record<string, string>>(moduleId: string, moduleRoutes: T) =>
  Object.keys(moduleRoutes).reduce((memo, key) => {
    memo = {
      ...memo,
      [key]: (id: string) => assignCustomId(moduleId, key, id),
    };
    return memo;
  }, {} as TestIdArg<T>);

const assignCustomId = (moduleId: string, routePath: string, id: string) =>
  `module=${moduleId};routePath=${routePath};id=${id}`;

/**
 * Utility for generating Ids for `e2e` and `unit` testing
 * The final result is componsed from moduleId, routePath of the page and the customTestId
 * @see docs/cypress/testIds.md - for further information.
 * @example
 * testIds.settings.userManagementAddUser('username')
 * @returns {string} module=core;routePath=/settings/user-management/add-user;id=username
 */
export const testIds = {
  login: buildTestId<typeof routes.loginRoutes>('login', routes.loginRoutes),
  settings: buildTestId<typeof routes.settingsRoutes>(moduleKeys.core, routes.settingsRoutes),
  myProfile: buildTestId<typeof routes.myProfileRoutes>(moduleKeys.core, routes.myProfileRoutes),
  vehicles: buildTestId<typeof routes.vehiclesRoutes>(moduleKeys.vehicles, routes.vehiclesRoutes),
  sourcing: buildTestId<typeof routes.sourcingRoutes>(moduleKeys.sourcing, routes.sourcingRoutes),
  employee: buildTestId<typeof routes.employeeRoutes>(moduleKeys.employee, routes.employeeRoutes),
  customer: buildTestId<typeof routes.customerRoutes>(moduleKeys.customers, routes.customerRoutes),
  businessCase: buildTestId<typeof routes.businessCaseRoutes>(
    moduleKeys.businessCase,
    routes.businessCaseRoutes
  ),
  accounting: buildTestId<typeof routes.accountingRoutes>(
    moduleKeys.accounting,
    routes.accountingRoutes
  ),
  workshop: buildTestId<typeof routes.workshopRoutes>(moduleKeys.workshop, routes.workshopRoutes),
  warehouse: buildTestId<typeof routes.warehouseRoutes>(
    moduleKeys.warehouse,
    routes.warehouseRoutes
  ),
  tiresInventory: buildTestId<typeof routes.tiresInventoryRoutes>(
    moduleKeys.tiresInventory,
    routes.tiresInventoryRoutes
  ),
  dashboard: buildTestId<typeof routes.dashboardRoutes>(moduleKeys.core, routes.dashboardRoutes),
  interest: buildTestId<typeof routes.interestRoutes>(moduleKeys.interest, routes.interestRoutes),
};
