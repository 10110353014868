import {useDndMonitor} from '@dnd-kit/core';
import {arrayMove} from '@dnd-kit/sortable';

import {useCallback, useRef} from 'react';

import {isEmpty, isNil, isNotNil, not} from 'ramda';

import {UnifiedAlbumImage} from '../types/UnifiedAlbumImage';
import {makeUnifiedAlbumImage} from '../utils/makeUnifiedAlbumImage';
import {computePositionFromCollision} from './utils/computePositionFromCollision';
import {getSortableContainerId} from './utils/getSortableContainerId';
import {parseDndItemId} from './utils/parseDndItemId';

interface useDndManagerProps {
  containerId: string;
  onUpdate: (images: UnifiedAlbumImage[]) => void;
  onEnd: (images: UnifiedAlbumImage[], droppingContainerId: string) => void;
  onSelectedItemsMove: () => void;
  selectedItemIds: string[];
}

export function useVehicleAlbumDndManager(props: useDndManagerProps) {
  const itemsRef = useRef<UnifiedAlbumImage[]>([]);
  const clonedItemsRef = useRef<UnifiedAlbumImage[]>([]);

  const images = itemsRef.current;

  const init = useCallback((state: UnifiedAlbumImage[]) => {
    itemsRef.current = [...state];
    clonedItemsRef.current = [...state];
  }, []);

  const updateItems = (updateFn: (images: UnifiedAlbumImage[]) => UnifiedAlbumImage[]) => {
    itemsRef.current = updateFn(itemsRef.current);
    props.onUpdate(itemsRef.current);
  };

  const revertToOriginalDraggingState = () => {
    itemsRef.current = clonedItemsRef.current;
    props.onUpdate(clonedItemsRef.current ?? []);
  };

  const isCurrentContainer = (containerId: string | null) => props.containerId === containerId;

  const doesContainerContainImage = (imageId: string) =>
    images.some((image) => image.id === imageId);

  const filterOutImageById = (imageId: string) => {
    updateItems((prevImages) => prevImages.filter((image) => image.id !== imageId));
  };

  const filterOutAllSelectedImages = () => {
    updateItems((prevImages) =>
      prevImages.filter((image) => !props.selectedItemIds.includes(image.id))
    );
  };

  const resetGhostImages = () => {
    updateItems((prevImages) => prevImages.map((image) => ({...image, isGhost: false})));
  };

  useDndMonitor({
    onDragCancel: () => {
      revertToOriginalDraggingState();
    },
    onDragOver: ({active, over}) => {
      if (isNil(over)) {
        // handling edge case of dragging from undroppable container and cancelling drag
        const isActiveGhost = images.find((image) => image.isGhost);
        if (isActiveGhost) {
          revertToOriginalDraggingState();
        }
        return;
      }

      const draggingFromContainerId = getSortableContainerId(active);
      let droppingOntoContainerId = getSortableContainerId(over);

      // container dragging is not supported, so we are sure that we are dealing with items
      const {id: draggedItemId, url: activeUrl} = parseDndItemId(active.id);

      const {
        // if we are dropping into empty zone, over event contains container
        id: droppedOntoContainerOrItemId,
        position: droppedOntoItemIndex,
        // only items are sortable
        isSortable: isItem,
      } = parseDndItemId(over.id);

      const isContainer = not(isItem);

      droppingOntoContainerId = isContainer
        ? droppedOntoContainerOrItemId
        : droppingOntoContainerId;

      if (isNil(droppingOntoContainerId)) {
        return;
      }

      const isSameContainer = droppingOntoContainerId === draggingFromContainerId;

      // **DROPPING ONTO EMPTY ZONE**

      // add ghost image to new container when dropping onto empty zone
      if (isContainer && not(isSameContainer) && isCurrentContainer(droppingOntoContainerId)) {
        if (doesContainerContainImage(draggedItemId)) {
          return;
        }

        updateItems((prevImages) => [
          ...prevImages,
          makeUnifiedAlbumImage({
            id: draggedItemId,
            url: activeUrl,
            isGhost: true,
            isDirty: true,
          }),
        ]);
      }

      // filter out image from old container when dropping onto new container
      if (not(isCurrentContainer(droppingOntoContainerId))) {
        filterOutImageById(draggedItemId);
      }

      if (isContainer || (isCurrentContainer(draggingFromContainerId) && isSameContainer)) {
        // DnD inside current container is handled by DragOverlay and dragEnd events
        return;
      }

      // handle dropping onto images, not empty zone
      if (isNil(droppedOntoItemIndex)) {
        return;
      }

      if (doesContainerContainImage(draggedItemId) && isCurrentContainer(droppingOntoContainerId)) {
        updateItems((prevImages) => {
          const active = prevImages.findIndex((image) => image.id === draggedItemId);
          const over = prevImages.findIndex((image) => image.id === droppedOntoContainerOrItemId);
          prevImages[active].isDirty = true;
          const images = arrayMove(prevImages, active, over) ?? prevImages;
          return images;
        });
        return;
      }

      // **DROPPING ONTO ITEM (not empty zone)**

      if (isCurrentContainer(droppingOntoContainerId)) {
        const index = computePositionFromCollision(active, over);

        updateItems((prevImages) => [
          ...prevImages.slice(0, index),
          makeUnifiedAlbumImage({
            id: draggedItemId,
            url: activeUrl,
            isGhost: true,
            position: index,
            isDirty: true,
          }),
          ...prevImages.slice(index, prevImages.length),
        ]);
      }
    },
    onDragEnd: ({active, over}) => {
      if (isNil(over)) {
        revertToOriginalDraggingState();
        return;
      }

      const draggingFromContainerId = getSortableContainerId(active);
      let droppingOntoContainerId = getSortableContainerId(over);

      const {
        id: draggedItemId,
        position: draggedItemIndex,
        containerId: activeItemContainerId,
      } = parseDndItemId(active.id);
      const {
        id: droppedOntoContainerOrItemId,
        position: droppedOntoItemIndex,
        isSortable: isItem,
      } = parseDndItemId(over.id);

      const isContainer = not(isItem);
      droppingOntoContainerId = isContainer
        ? droppedOntoContainerOrItemId
        : droppingOntoContainerId;

      if (isNil(droppingOntoContainerId)) {
        return;
      }

      if (isNil(draggedItemIndex) || (isNil(droppedOntoItemIndex) && isItem)) {
        resetGhostImages();
        return;
      }

      if (isNil(draggingFromContainerId) && isNil(droppingOntoContainerId)) {
        return;
      }

      const isSameItem = draggedItemId === droppedOntoContainerOrItemId;
      const isSameContainer = droppingOntoContainerId === draggingFromContainerId;

      // Handles reordering of images within the same album
      if (not(isSameItem) && isSameContainer && isCurrentContainer(draggingFromContainerId)) {
        if (props.selectedItemIds.length === 0 && isNotNil(droppedOntoItemIndex)) {
          updateItems((prevImages) => {
            if (isNil(prevImages[draggedItemIndex]) || isNil(prevImages[droppedOntoItemIndex])) {
              return prevImages;
            }
            prevImages[draggedItemIndex].isDirty = true;
            const images =
              arrayMove(prevImages, draggedItemIndex, droppedOntoItemIndex) ?? prevImages;
            return images;
          });
        } else if (props.selectedItemIds.length > 0 && isNotNil(droppedOntoItemIndex)) {
          updateItems((prevImages) => {
            const selectedItems = prevImages
              .filter((image) => props.selectedItemIds.includes(image.id))
              .map((image) => ({...image, isDirty: true}));

            const filteredImages = prevImages.filter((image) =>
              not(props.selectedItemIds.includes(image.id))
            );

            return [
              ...filteredImages.slice(0, droppedOntoItemIndex),
              ...selectedItems,
              ...filteredImages.slice(droppedOntoItemIndex),
            ];
          });
        }
      }

      // Handles moving image between albums
      if (
        not(isSameItem) &&
        not(isSameContainer) &&
        not(isCurrentContainer(droppingOntoContainerId))
      ) {
        filterOutImageById(draggedItemId);
        props.onSelectedItemsMove();
      }

      // multiple images are selected and dropped onto another container
      if (
        isCurrentContainer(droppingOntoContainerId) &&
        props.selectedItemIds.length > 1 &&
        not(isSameContainer)
      ) {
        const selectedItems = props.selectedItemIds.map((id) =>
          makeUnifiedAlbumImage({
            id,
            isGhost: true,
            isDirty: true,
          })
        );
        updateItems((prevImages) => {
          const images = [
            ...prevImages.slice(0, droppedOntoItemIndex),
            ...selectedItems,
            ...prevImages.slice(droppedOntoItemIndex),
          ].filter((image) => image.id !== draggedItemId || isEmpty(image.url));
          return images;
        });
      }

      // Handles moving multiple images between albums
      if (not(isSameContainer) && not(isCurrentContainer(droppingOntoContainerId))) {
        filterOutAllSelectedImages();
        props.onSelectedItemsMove();
      }

      // Prevent ghost images after drop
      resetGhostImages();

      if (
        activeItemContainerId &&
        (isCurrentContainer(droppingOntoContainerId) ||
          (isContainer && props.selectedItemIds.length > 1))
      ) {
        props.onEnd(itemsRef.current, activeItemContainerId);
      }
    },
  });

  return init;
}
