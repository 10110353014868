import {Option} from 'platform/components';

import {Nullish} from 'shared';

import {ColGroupDef} from '../types/AgGridTypes';
import {
  DefinitionResponseBody,
  GetSmartSearchResponse,
  SuggestionsOptions,
  SuggestionsResponseBody,
  TransformedDefinitionApiResponse,
} from '../types/Api';

const transformDefinitionResponse = (
  definition: DefinitionResponseBody
): TransformedDefinitionApiResponse => ({
  ...definition,
  groups: definition.columnGroups.reduce(
    (accumulator, group) => {
      accumulator[group.key] = {
        groupId: group.key,
        headerName: group.title,
        children: [],
      };
      return accumulator;
    },
    {} as Record<string, ColGroupDef>
  ),
});
const transformSuggestionOptionsResponse = (data: SuggestionsResponseBody): SuggestionsOptions =>
  data.suggestions.map((option) => ({label: option.label, value: option.key}));

const transformSuggestionLabelsResponse = (data: SuggestionsResponseBody): SuggestionsOptions =>
  data.suggestions.map((option) => ({label: option.label, value: option.key}));

const transformSmartSearchResponse = (data: Nullish | GetSmartSearchResponse): Option[] =>
  data?.queries
    ? data.queries?.map(({query}) => ({
        label: query,
        value: query,
      }))
    : [];

export const transformations = {
  transformDefinitionResponse,
  transformSuggestionOptionsResponse,
  transformSuggestionLabelsResponse,
  transformSmartSearchResponse,
};
