import {__, divide, multiply, pipe} from 'ramda';

/**
 * Calculates the upload progress as a percentage rounded to one decimal place.
 *
 * @param {number} value - The upload progress value between 0 and 1.
 * @returns {number} - The upload progress percentage rounded to one decimal place.
 *
 * @example
 * getUploadProgress(0.283456); // returns 28.3
 * getUploadProgress(0.5); // returns 50.0
 * getUploadProgress(1); // returns 100.0
 */
export function getUploadProgress(value: number): number {
  return pipe(
    // Round to 4 digits
    multiply(1000),
    Math.round,
    divide(__, 10),
    // Round to 1 decimal, to prevent: 28.0000000004%
    (result) => Number(result.toFixed(1))
  )(value);
}
