import {isNil} from 'ramda';

export const getValidatedCheckoutSearchParams = (searchParams: URLSearchParams) => {
  const invoiceType = searchParams.get('invoiceType');
  const checkoutId = searchParams.get('checkoutId');
  const orderId = searchParams.get('orderId');
  const paymentId = searchParams.get('paymentId');
  const businessCaseId = searchParams.get('businessCaseId');
  const type = searchParams.get('type');

  if ([invoiceType, checkoutId, orderId, paymentId, businessCaseId].some(isNil)) {
    throw new Error('missing required search parameters');
  }
  if (type !== 'full' && type !== 'partial') {
    throw new Error('type must be full or partial');
  }

  return {
    invoiceType: invoiceType!,
    checkoutId: checkoutId!,
    orderId: orderId!,
    businessCaseId: businessCaseId!,
    paymentId: paymentId!,
    type,
  } as const;
};
