import {showNotification} from 'platform/components';

import {SeriesService} from '../../services/SeriesService';
import {Series} from '../../types/Series';
import {ApiConfig} from '../../utils/api';
import {createAsyncThunk} from '../../utils/createAsyncThunk';
import {NAME} from './constants';

type ActionApiConfig = {
  config?: ApiConfig;
};

export type LoadDocumentSeriesListType = Omit<
  Parameters<typeof SeriesService.getSeriesList>[0],
  'authorization' | 'xBranch'
> &
  ActionApiConfig;
export const loadDocumentSeriesList = createAsyncThunk<Series[], LoadDocumentSeriesListType>(
  `${NAME}/loadDocumentSeriesList`,
  ({search, type, config}, {extra, rejectWithValue}) => {
    try {
      return extra.callApi(
        SeriesService.getSeriesList,
        {
          search,
          type,
        },
        config
      );
    } catch (error: any) {
      showNotification.error([`Error ${error.response.status}`, error.response.data.error.message]);

      return rejectWithValue(error);
    }
  }
);
