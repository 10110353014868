import {FormControl, FormField, isCurrency} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import {not} from 'ramda';

import {TenantResponseBody, VatRate} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {FALLBACK_HANDLING_UNIT} from '../../../../../constants/fallbackHandlingUnit';
import {ReceiveNoteItemFormType} from '../../../../../types/ReceiveNote';
import {getOptionsFromVatRates} from '../../../../../utils/getOptionsFromVatRates';
import {getVatRatesByTenantCountry} from '../../../../../utils/getVatRatesByTenantCountry';

export interface DeliveryItemFormProps extends RequiredTestIdProps {
  control: FormControl<ReceiveNoteItemFormType>;
  deliveryNoteQuantity: number | Nullish;
  vatRates: VatRate[] | Nullish;
  tenant: TenantResponseBody | Nullish;
  handlingUnit: string | Nullish;
  currency: string | Nullish;
  isChecked: boolean;
  isEditingDisabled: boolean;
}

export function DeliveryItemForm(props: DeliveryItemFormProps) {
  const deliveryNoteQuantityHelperText = `${i18n.t('entity.warehouse.labels.deliveryNoteQuantity')}: ${props.deliveryNoteQuantity ?? 0} ${props.handlingUnit?.toLowerCase() ?? FALLBACK_HANDLING_UNIT.toLowerCase()}`;

  const ratesByTenantCountry = getVatRatesByTenantCountry(
    props.vatRates,
    props.tenant?.country
  )?.rates;

  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <Heading size={5}>{i18n.t('entity.warehouse.labels.deliveryItem')}</Heading>
      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            type="number"
            name="deliveryItem.quantityVerified"
            label={i18n.t('general.labels.verifiedQuantity')}
            control={props.control}
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            suffix={props.handlingUnit ?? FALLBACK_HANDLING_UNIT}
            helperText={deliveryNoteQuantityHelperText}
            data-testid={suffixTestId('inputs.quantityVerified', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            type="currency"
            name="deliveryItem.unitPrice"
            label={i18n.t('general.labels.unitPrice')}
            control={props.control}
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            currency={isCurrency(props.currency) ? props.currency : undefined}
            data-testid={suffixTestId('inputs.unitPrice', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            type="currency"
            name="deliveryItem.totalPrice"
            label={i18n.t('general.labels.totalPrice')}
            control={props.control}
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            currency={isCurrency(props.currency) ? props.currency : undefined}
            data-testid={suffixTestId('inputs.totalPrice', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            type="choice"
            name="deliveryItem.vatType"
            label={i18n.t('general.labels.vat')}
            options={getOptionsFromVatRates(ratesByTenantCountry)}
            control={props.control}
            isNotClearable
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            menuInPortal
            data-testid={suffixTestId('inputs.vatType', props)}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
}
