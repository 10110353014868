import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object} from 'yup';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, yupString, TestIdProps} from 'shared';

type FormType = {
  name: string;
};

interface FolderTreeFormProps extends TestIdProps {
  defaultName?: string | null;
  onClose: VoidFunction;
  onSubmit: FormSubmitHandler<FormType>;
  isCreate?: boolean;
}

export function FolderTreeForm(props: FolderTreeFormProps) {
  return (
    <Form<FormType>
      defaultValues={{name: props.defaultName ?? undefined}}
      onSubmit={props.onSubmit}
      schema={FormSchema}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            name="name"
            type="text"
            isAutoFocused
            data-testid={suffixTestId('name', props)}
          />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.labels.discard')}
              onClick={props.onClose}
              variant="secondary"
            />
            <FormButton
              control={control}
              type="submit"
              title={
                props.isCreate ? i18n.t('general.actions.create') : i18n.t('general.actions.save')
              }
              data-testid={suffixTestId('submit', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const FormSchema = object({
  name: yupString.required(),
});
