import {
  ButtonGroup,
  closeDialog,
  DialogFooter,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {
  usePostPostponeJobMutation,
  usePostPostponeJobPostponeUntilMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {handleApiError} from '../../utils/handleApiError';
import {PredefinedTextArea} from '../PredefinedTextArea/PredefinedTextArea';
import {PostponeRequestForm} from './types/PostponeRequestForm';

interface PostponeDialogProps extends RequiredTestIdProps {
  dialogId: string;
  jobsIds: string | string[];
  refreshDataGrid?: () => void;
  reason: string | Nullish;
  postponeUntil: string | Nullish;
  vehicleId?: string;
  serviceCaseId?: string;
  serviceOrderId?: string;
  switchJobToPostponed?: boolean;
}

export function PostponeDialog(props: PostponeDialogProps) {
  const [postponeJobsMutation] = usePostPostponeJobPostponeUntilMutation();
  const [switchJobToPostponedMutation] = usePostPostponeJobMutation();

  const handleSuccess = () => {
    closeDialog(props.dialogId);
    showNotification.success();
    props.refreshDataGrid?.();
    return Promise.resolve();
  };

  const handleFormSubmit: FormSubmitHandler<PostponeRequestForm> = async (formData) => {
    if (props.switchJobToPostponed) {
      return await switchJobToPostponedMutation({
        serviceJobId: props.jobsIds as string,
        serviceCaseId: props.serviceCaseId || '',
        serviceOrderId: props.serviceOrderId || '',
        body: {
          postponedUntil: formData.postponeUntil || null,
          postponeReason: formData.reason || '',
        },
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleApiError);
    }

    return await postponeJobsMutation({
      vehicleId: props.vehicleId,
      serviceCaseId: props.serviceCaseId,
      body: {
        postponeJobId: Array.isArray(props.jobsIds) ? props.jobsIds : [props.jobsIds],
        postponedUntil: formData.postponeUntil,
        postponeReason: formData.reason,
      },
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleApiError);
  };

  return (
    <Form<PostponeRequestForm>
      defaultValues={{reason: props.reason || '', postponeUntil: props.postponeUntil || undefined}}
      onSubmit={handleFormSubmit}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            type="apiDate"
            name="postponeUntil"
            label={i18n.t('entity.serviceCase.labels.postponeUntil')}
            isDisabled={formApi.formState.isSubmitting}
            data-testid={suffixTestId('postponeUntil', props)}
          />
          <PredefinedTextArea
            formApi={formApi}
            name="reason"
            context="postopned_job_reason"
            resource="SERVICE_CASE"
            label={i18n.t('general.labels.reason')}
            data-testid={suffixTestId('reason', props)}
            isResizable
          />
          <DialogFooter>
            <ButtonGroup
              align="right"
              buttons={[
                {
                  title: i18n.t('general.labels.discard'),
                  variant: 'secondary',
                  onClick: () => closeDialog(props.dialogId),
                  isDisabled: formApi.formState.isSubmitting,
                  'data-testid': suffixTestId('discard', props),
                },
                {
                  title: i18n.t('general.actions.confirm'),
                  isLoading: formApi.formState.isSubmitting,
                  type: 'submit',
                  'data-testid': suffixTestId('confirm', props),
                },
              ]}
            />
          </DialogFooter>
        </VStack>
      )}
    </Form>
  );
}
