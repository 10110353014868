import {
  DeleteServiceOrderItemsApiArg,
  DeleteServiceOrderItemsApiResponse,
  GetOrderItemsTotalAmountApiArg,
  GetOrderItemsTotalAmountApiResponse,
  GetServiceOrderApiArg,
  GetServiceOrderApiResponse,
  GetServiceOrderCommercialApiArg,
  GetServiceOrderCommercialApiResponse,
  GetServiceOrderComplaintApiArg,
  GetServiceOrderComplaintApiResponse,
  GetServiceOrderContractApiArg,
  GetServiceOrderContractApiResponse,
  GetServiceOrderInsuranceApiArg,
  GetServiceOrderInsuranceApiResponse,
  GetServiceOrderInternalApiArg,
  GetServiceOrderInternalApiResponse,
  GetServiceOrderWarrantyApiArg,
  GetServiceOrderWarrantyApiResponse,
  PatchServiceOrderApiArg,
  PatchServiceOrderApiResponse,
  PatchServiceOrderCommercialApiArg,
  PatchServiceOrderCommercialApiResponse,
  PatchServiceOrderComplaintApiArg,
  PatchServiceOrderComplaintApiResponse,
  PatchServiceOrderContractApiArg,
  PatchServiceOrderContractApiResponse,
  PatchServiceOrderInsuranceApiArg,
  PatchServiceOrderInsuranceApiResponse,
  PatchServiceOrderInternalApiArg,
  PatchServiceOrderInternalApiResponse,
  PatchServiceOrderStateApiArg,
  PatchServiceOrderStateApiResponse,
  PatchServiceOrderWarrantyApiArg,
  PatchServiceOrderWarrantyApiResponse,
  PostServiceOrderApiArg,
  PostServiceOrderApiResponse,
  PostServiceOrderChangeVariantApiArg,
  PostServiceOrderChangeVariantApiResponse,
  PostServiceOrderChangeVariantCheckApiArg,
  PostServiceOrderChangeVariantCheckApiResponse,
  GetOrderProfitabilityApiArg,
  GetOrderProfitabilityApiResponse,
  PutServiceOrderIntegrationApiResponse,
  PutServiceOrderIntegrationApiArg,
  GetServiceOrderIntegrationsApiResponse,
  GetServiceOrderIntegrationsApiArg,
  GetServiceOrderTimeTrackingApiResponse,
  GetServiceOrderTimeTrackingApiArg,
  GetServiceOrderMandatoryFieldsApiArg,
  GetServiceOrderMandatoryFieldsApiResponse,
  PostServiceRequestCopyToServiceOrderApiArg,
  PostServiceRequestCopyToServiceOrderApiResponse,
  PostServiceItemsLabourSettingsApiArg,
  PostServiceItemsLabourSettingsApiResponse,
  PostServiceItemsMaterialPartRequestApiResponse,
  PostServiceItemsMaterialPartRequestApiArg,
  PostServiceItemsMaterialPartIssuedApiResponse,
  PostServiceItemsMaterialPartIssuedApiArg,
  GetServiceOrderTypesApiResponse,
  GetServiceOrderTypesApiArg,
  GetServiceOrderCheckoutRestrictionApiResponse,
  GetServiceOrderCheckoutRestrictionApiArg,
} from '../types/metadaWorkshopServiceOrder';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceOrderApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postServiceOrder: build.mutation<PostServiceOrderApiResponse, PostServiceOrderApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
      ],
    }),
    getServiceOrder: build.query<GetServiceOrderApiResponse, GetServiceOrderApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrder', id: 'ALL'},
      ],
    }),
    patchServiceOrder: build.mutation<PatchServiceOrderApiResponse, PatchServiceOrderApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'orderCustomerContract', id: queryArg.serviceOrderId},
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
      ],
    }),

    getServiceOrderCommercial: build.query<
      GetServiceOrderCommercialApiResponse,
      GetServiceOrderCommercialApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/commercial`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderCommercial', id: queryArg.serviceOrderId},
      ],
    }),
    patchServiceOrderCommercial: build.mutation<
      PatchServiceOrderCommercialApiResponse,
      PatchServiceOrderCommercialApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/commercial`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderCommercial', id: queryArg.serviceOrderId},
      ],
    }),
    getServiceOrderComplaint: build.query<
      GetServiceOrderComplaintApiResponse,
      GetServiceOrderComplaintApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/complaint`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderComplaint', id: queryArg.serviceOrderId},
      ],
    }),
    patchServiceOrderComplaint: build.mutation<
      PatchServiceOrderComplaintApiResponse,
      PatchServiceOrderComplaintApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/complaint`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderComplaint', id: queryArg.serviceOrderId},
      ],
    }),
    getServiceOrderContract: build.query<
      GetServiceOrderContractApiResponse,
      GetServiceOrderContractApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/contract`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderContract', id: queryArg.serviceOrderId},
      ],
    }),
    patchServiceOrderContract: build.mutation<
      PatchServiceOrderContractApiResponse,
      PatchServiceOrderContractApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/contract`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderContract', id: queryArg.serviceOrderId},
        {type: 'orderCustomerContract', id: queryArg.serviceOrderId},
      ],
    }),
    deleteServiceOrderItems: build.mutation<
      DeleteServiceOrderItemsApiResponse,
      DeleteServiceOrderItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/delete-items`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        'serviceOrderJobTotalAmount',
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        'checkout',
        'serviceJobActions',
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        {type: 'orderActions', id: queryArg.serviceOrderId},
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
    }),
    getServiceOrderInsurance: build.query<
      GetServiceOrderInsuranceApiResponse,
      GetServiceOrderInsuranceApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/insurance`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderInsurance', id: queryArg.serviceOrderId},
      ],
    }),
    patchServiceOrderInsurance: build.mutation<
      PatchServiceOrderInsuranceApiResponse,
      PatchServiceOrderInsuranceApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/insurance`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderInsurance', id: queryArg.serviceOrderId},
        {type: 'orderCustomerContract', id: queryArg.serviceOrderId},
      ],
    }),
    putServiceOrderIntegration: build.mutation<
      PutServiceOrderIntegrationApiResponse,
      PutServiceOrderIntegrationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/integration`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'orderIntegration', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        'serviceOrderJobTotalAmount',
        'checkout',
      ],
    }),
    getServiceOrderIntegrations: build.query<
      GetServiceOrderIntegrationsApiResponse,
      GetServiceOrderIntegrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/integrations`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'orderIntegration', id: queryArg.serviceOrderId},
      ],
    }),
    getServiceOrderInternal: build.query<
      GetServiceOrderInternalApiResponse,
      GetServiceOrderInternalApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/internal`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderInternal', id: queryArg.serviceOrderId},
      ],
    }),
    patchServiceOrderInternal: build.mutation<
      PatchServiceOrderInternalApiResponse,
      PatchServiceOrderInternalApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/internal`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderInternal', id: queryArg.serviceOrderId},
      ],
    }),
    getOrderItemsTotalAmount: build.query<
      GetOrderItemsTotalAmountApiResponse,
      GetOrderItemsTotalAmountApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/items-total-amount`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
      ],
    }),
    getOrderProfitability: build.query<
      GetOrderProfitabilityApiResponse,
      GetOrderProfitabilityApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/profitability`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
      ],
    }),
    patchServiceOrderState: build.mutation<
      PatchServiceOrderStateApiResponse,
      PatchServiceOrderStateApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/state`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
    }),
    getServiceOrderTimeTracking: build.query<
      GetServiceOrderTimeTrackingApiResponse,
      GetServiceOrderTimeTrackingApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/time-tracking`,
        params: {serviceCaseJobId: queryArg.serviceCaseJobId},
      }),
    }),
    postServiceOrderChangeVariant: build.mutation<
      PostServiceOrderChangeVariantApiResponse,
      PostServiceOrderChangeVariantApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/variant-change`,
        method: 'POST',
        params: {orderVariantId: queryArg.orderVariantId},
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'orderDiscount', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        'checkout',
        {type: 'serviceOrderCommercial', id: queryArg.serviceOrderId},
        {type: 'serviceOrderComplaint', id: queryArg.serviceOrderId},
        {type: 'serviceOrderContract', id: queryArg.serviceOrderId},
        {type: 'serviceOrderInsurance', id: queryArg.serviceOrderId},
        {type: 'serviceOrderInternal', id: queryArg.serviceOrderId},
        {type: 'serviceOrderWarranty', id: queryArg.serviceOrderId},
        {type: 'orderCustomerContract', id: queryArg.serviceOrderId},
        {type: 'orderActions', id: queryArg.serviceOrderId},
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
      ],
    }),
    postServiceOrderChangeVariantCheck: build.query<
      PostServiceOrderChangeVariantCheckApiResponse,
      PostServiceOrderChangeVariantCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/variant-change-allowed`,
      }),
    }),
    getServiceOrderWarranty: build.query<
      GetServiceOrderWarrantyApiResponse,
      GetServiceOrderWarrantyApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/warranty`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderWarranty', id: queryArg.serviceOrderId},
      ],
    }),
    patchServiceOrderWarranty: build.mutation<
      PatchServiceOrderWarrantyApiResponse,
      PatchServiceOrderWarrantyApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/warranty`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderWarranty', id: queryArg.serviceOrderId},
      ],
    }),
    getServiceOrderMandatoryFields: build.query<
      GetServiceOrderMandatoryFieldsApiResponse,
      GetServiceOrderMandatoryFieldsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceOrderId}/mandatory-fields`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderMandatoryFields', id: queryArg.serviceOrderId},
      ],
    }),
    postServiceRequestCopyToServiceOrder: build.mutation<
      PostServiceRequestCopyToServiceOrderApiResponse,
      PostServiceRequestCopyToServiceOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/service-request-copy`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        'checkout',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
    }),
    postServiceItemsLabourSettings: build.mutation<
      PostServiceItemsLabourSettingsApiResponse,
      PostServiceItemsLabourSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/item-settings/labour`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postServiceItemsMaterialPartRequest: build.mutation<
      PostServiceItemsMaterialPartRequestApiResponse,
      PostServiceItemsMaterialPartRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/material-items/request`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        'serviceOrderJobTotalAmount',
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        'checkout',
        'serviceJobActions',
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        {type: 'orderActions', id: queryArg.serviceOrderId},
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
    }),
    postServiceItemsMaterialPartIssued: build.mutation<
      PostServiceItemsMaterialPartIssuedApiResponse,
      PostServiceItemsMaterialPartIssuedApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/material-items/issue-note`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        'serviceOrderJobTotalAmount',
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        'checkout',
        'serviceJobActions',
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        {type: 'orderActions', id: queryArg.serviceOrderId},
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
    }),
    getServiceOrderTypes: build.query<GetServiceOrderTypesApiResponse, GetServiceOrderTypesApiArg>({
      query: () => ({
        url: `/service/v1/service-order-types`,
      }),
    }),
    getServiceOrderCheckoutRestriction: build.query<
      GetServiceOrderCheckoutRestrictionApiResponse,
      GetServiceOrderCheckoutRestrictionApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/checkout-restriction`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
      ],
    }),
  }),
});

export const {
  usePostServiceOrderMutation,
  useGetServiceOrderQuery,
  usePatchServiceOrderMutation,
  useGetServiceOrderCommercialQuery,
  usePatchServiceOrderCommercialMutation,
  useGetServiceOrderComplaintQuery,
  usePatchServiceOrderComplaintMutation,
  useGetServiceOrderContractQuery,
  usePatchServiceOrderContractMutation,
  useGetServiceOrderInsuranceQuery,
  usePatchServiceOrderInsuranceMutation,
  useGetServiceOrderInternalQuery,
  usePatchServiceOrderInternalMutation,
  usePatchServiceOrderStateMutation,
  useGetServiceOrderWarrantyQuery,
  usePatchServiceOrderWarrantyMutation,
  usePostServiceOrderChangeVariantCheckQuery,
  usePostServiceOrderChangeVariantMutation,
  useGetOrderItemsTotalAmountQuery,
  useDeleteServiceOrderItemsMutation,
  useGetOrderProfitabilityQuery,
  usePutServiceOrderIntegrationMutation,
  useGetServiceOrderIntegrationsQuery,
  useGetServiceOrderTimeTrackingQuery,
  useGetServiceOrderMandatoryFieldsQuery,
  usePostServiceRequestCopyToServiceOrderMutation,
  usePostServiceItemsLabourSettingsMutation,
  usePostServiceItemsMaterialPartRequestMutation,
  usePostServiceItemsMaterialPartIssuedMutation,
  useGetServiceOrderTypesQuery,
  useGetServiceOrderCheckoutRestrictionQuery,
} = metadaWorkshopServiceOrderApi;
