import {yupResolver} from '@hookform/resolvers/yup';
import {ButtonGroup, Dialog, showNotification} from 'platform/components';
import {Icon} from 'platform/foundation';

import {FC, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';

import {PatchUserApiArg, usePatchUserMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {getStringErrorMessage, patchUserSuccess, User} from '@omnetic-dms/teas';

import {ResetPasswordValidationSchema} from './passwordValidation';
import {StyledTextField, Wrapper} from './styles';
import {ResetPasswordTuple, TSetPasswordProps} from './types';

export const SetPasswordModal: FC<TSetPasswordProps> = ({onClose, userId, open, title}) => {
  const dispatch = useDispatch();

  const [showPassword, setViewPassword] = useState(false);

  const [patchUser, {isLoading: isUserLoading}] = usePatchUserMutation();

  const {
    formState: {errors},
    handleSubmit,
    control,
  } = useForm<ResetPasswordTuple>({
    resolver: yupResolver(ResetPasswordValidationSchema),
    reValidateMode: 'onBlur',
    defaultValues: {password: '', passwordNewConfirm: ''},
  });

  const submit = async ({password}: ResetPasswordTuple): Promise<void> => {
    try {
      const arg: PatchUserApiArg = {userId, updateUserRequestBody: {password}};
      const res = await patchUser(arg).unwrap();

      if (res && res?.id) {
        // eslint-disable-next-line no-restricted-syntax
        dispatch(patchUserSuccess(res as unknown as User));
      }

      showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'), {
        'data-testid': testIds.settings.userManagement('passwordResetSuccess'),
      });
      onClose?.();
    } catch (error: any) {
      showNotification.error(getStringErrorMessage(error));
    }
  };

  return (
    <Dialog
      data-testid={testIds.settings.userManagement('setPasswordModal')}
      title={title ? title : i18n.t('general.labels.setPassword')}
      isOpen={!!open}
      onClose={onClose}
      size="small"
    >
      <Wrapper onSubmit={handleSubmit(submit)}>
        <Controller
          name="password"
          control={control}
          render={({field: {onChange, ...rest}}) => (
            <StyledTextField
              {...rest}
              data-testid={testIds.settings.userManagement('resetPassword')}
              type={showPassword ? 'text' : 'password'}
              placeholder={i18n.t('general.labels.password')}
              label={i18n.t('page.settings.labels.userPassword')}
              error={!!errors.password?.message}
              helperText={errors.password?.message}
              endAdornment={{
                variant: 'button',
                disableFocusToInput: true,
                Icon: showPassword ? (
                  <Icon size={4} value="action/visibility" />
                ) : (
                  <Icon size={4} value="action/visibility_off" />
                ),
                onClick: () => setViewPassword(!showPassword),
              }}
              onChange={(e) => onChange((e.target.value || '').trim())}
            />
          )}
        />

        <Controller
          name="passwordNewConfirm"
          control={control}
          render={({field: {onChange, ...rest}}) => (
            <StyledTextField
              {...rest}
              data-testid={testIds.settings.userManagement('resetPasswordConfirm')}
              type={showPassword ? 'text' : 'password'}
              placeholder={i18n.t('page.settings.labels.setUserPassword')}
              label={i18n.t('page.settings.labels.setUserPassword')}
              error={!!errors.passwordNewConfirm?.message}
              helperText={errors.passwordNewConfirm?.message}
              endAdornment={{
                variant: 'button',
                disableFocusToInput: true,
                Icon: showPassword ? (
                  <Icon size={4} value="action/visibility" />
                ) : (
                  <Icon size={4} value="action/visibility_off" />
                ),
                onClick: () => setViewPassword(!showPassword),
              }}
              onChange={(e) => onChange((e.target.value || '').trim())}
            />
          )}
        />

        <ButtonGroup
          align="right"
          buttons={[
            {
              title: i18n.t('general.actions.back'),
              'data-testid': testIds.settings.userManagement('close'),
              variant: 'secondary',
              onClick: onClose,
              isLoading: isUserLoading,
            },
            {
              title: i18n.t('general.labels.setPassword'),
              'data-testid': testIds.settings.userManagement('setPassword'),
              variant: 'primary',
              type: 'submit',
              isLoading: isUserLoading,
            },
          ]}
        />
      </Wrapper>
    </Dialog>
  );
};
