import {Image, CSSDimension, Integer} from 'platform/foundation';

interface AdServerLogoProps {
  server: string;
  maxWidth?: Integer | CSSDimension;
  maxHeight?: Integer | CSSDimension;
}

export function AdServerLogo(props: AdServerLogoProps) {
  // TODO T20-24902: use assets webpack plugin
  return (
    <Image
      src={`/assets/images/ad-server-logo/${props.server}.svg`}
      maxWidth={props.maxWidth}
      maxHeight={props.maxHeight}
    />
  );
}
