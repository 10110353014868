import {MouseEventHandler} from 'react';

import {TestIdProps} from 'shared';

import {IconButton, IconButtonProps} from '../../IconButton/IconButton';

export interface IconButtonActionProps extends IconButtonProps, TestIdProps {
  type: 'iconButton';
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export function IconButtonAction(props: IconButtonActionProps) {
  return (
    <IconButton
      icon={props.icon}
      isDisabled={props.isDisabled}
      severity={props.severity}
      size={props.size}
      priority={props.priority}
      onClick={props.onClick}
      data-testid={props['data-testid']}
    />
  );
}
