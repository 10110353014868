import {useEffect, useState} from 'react';

const getWindowDimensions = () => {
  const {innerWidth, innerHeight} = window;
  return {
    width: innerWidth,
    height: innerHeight,
  };
};

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}
