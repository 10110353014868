import {array, mixed, object, string} from 'yup';

import {LegalFormEnum} from '../types/LegalFormEnum';
import {$BankAccountRequestBody} from './$BankAccountRequestBody';
import {$BusinessInfoRequestBody} from './$BusinessInfoRequestBody';

export const $ContractInformationRequestBody = object({
  legalForm: mixed().oneOf(Object.values(LegalFormEnum)).required(),
  personId: string()
    .defined()
    .nullable()
    .when('legalForm', {
      is: (value: LegalFormEnum) => value !== LegalFormEnum.NATURAL_PERSON,
      then: string().defined().nullable(),
      otherwise: string().nullable().required(),
    }),
  businessInfo: $BusinessInfoRequestBody.defined().nullable(),
  bankAccounts: array().of($BankAccountRequestBody).defined(),
  customFieldsPayload: array().of(
    object({
      definitionId: mixed(),
      value: object({
        value: mixed(),
        type: mixed().oneOf([
          'integer',
          'decimal',
          'string',
          'money',
          'date',
          'datetime',
          'boolean',
        ]),
      }),
    })
  ),
});
