import styled, {css} from 'styled-components';
import styledMap from 'styled-map';

import {ContextMenuWithContext} from '../ContextMenu/ContextMenuWithContext';
import {HoverPositions} from '../CustomDragAndDrop/types';
import {Tag} from '../Tag/Tag';
import {TypographyCard} from '../TypographyCard/TypographyCard';
import {EmptyWrapperProps, SelectionProps, ImageProps, ImagesWrapperProps} from './types';

export const CardStyled = styled(TypographyCard)`
  .card-header h4 {
    font-size: ${({theme}) => theme.fontSizes.text.base};
    font-weight: 500;
  }

  .card-content {
    padding: 8px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
`;

export const ViewWrapper = styled(ContentWrapper)`
  padding: 6px;
`;

export const Droppable = styled.div`
  position: absolute;
  inset: 0;
  background: ${({theme}) => theme.colors.palettes.blue[70][100]};
  border: 4px dashed ${({theme}) => theme.colors.palettes.blue[80][100]};
  border-radius: ${({theme}) => theme.radii.small};
  opacity: 0.8;
  z-index: ${({theme}) => theme.zIndices.CONTENT};
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    pointer-events: none;
  }
`;

export const EmptyWrapper = styled.div<EmptyWrapperProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${({infoText}) => (infoText ? '8px' : '0')};
  padding: 16px;
  border-radius: ${({theme}) => theme.radii.small};
  transition: background 250ms ease-in-out;
  background: ${({theme, active}) =>
    active ? theme.colors.palettes.neutral[30][100] : theme.colors.palettes.neutral[20][100]};
  width: 100%;
`;

export const ImagesWrapper = styled.div<ImagesWrapperProps>`
  width: 100%;
  padding: 8px;
  background-color: ${({theme, active}) =>
    active ? theme.colors.palettes.neutral[20][100] : 'transparent'};
  transition: background-color 250ms ease-in-out;
`;

export const MuiGridStyled = styled.div`
  display: flex;
  height: 180px;
  width: 240px !important;
`;

export const AddTile = styled.span`
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  color: ${({theme}) => theme.colors.palettes.neutral[800][100]};
  border: 1px dashed ${({theme}) => theme.colors.palettes.neutral[80][100]};
  border-radius: ${({theme}) => theme.radii.small};
  background: none;
  outline: 0;
  width: 240px !important;
  height: 180px;
`;

export const SelectionBorder = styled.div<SelectionProps>`
  margin-right: 2px;
  display: flex;
  flex: 1 1;
  border: 3px solid
    ${({theme, selectionEnabled, isSelected}) =>
      selectionEnabled && isSelected ? theme.colors.palettes.blue[60][100] : `transparent`};
  border-radius: ${({theme}) => theme.radii.medium};
  width: 240px !important;
  height: 180px;

  ${({borderSide, theme, isDragging}) =>
    borderSide &&
    isDragging &&
    css`
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 4px;
        background: ${theme.colors.palettes.blue[60][100]};
        top: -4px;
        bottom: -4px;
        border-radius: ${theme.radii.small};
        ${borderSide === HoverPositions.Left ? `left` : 'right'}: -6px;
      }
    `}
`;

export const ImageOpacity = styledMap`
	default: 0.5;
	isSelected: 1;
	isDragged: 0.5;
	isChecked: 1;
`;

export const TooltipNote = styled.div`
  color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
`;

export const StyledContextMenu = styled(ContextMenuWithContext)`
  position: relative;
  display: flex;
  flex: 1 1;
  border: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  border-radius: ${({theme}) => theme.radii.small};
`;

export const Image = styled.div<ImageProps>`
  background-image: url(${({src}) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: ${ImageOpacity};
  width: 100%;

  & > div {
    margin: 2px 2px 0 auto;
  }
`;

export const ImageViewGrid = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;

  span {
    top: 8px;
    right: 9px;
  }

  .image {
    border: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
    border-radius: ${({theme}) => theme.radii.small};
    height: 180px;
    width: 240px !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  }
`;

export const UploadingImageWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 3px;
`;
export const UploadingImageContentWrapper = styled.div`
  padding: ${({theme}) => theme.getSize(1)};
  width: 100%;
`;
export const UploadingImageStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  border: 1px solid ${({theme}) => theme.colors.palettes.neutral[30][100]};
  border-radius: ${({theme}) => theme.radii.small};
`;

export const FileOperationStatus = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const UploadSizeWrapper = styled.div`
  margin-top: 4px;
  display: flex;
`;

export const UploadButton = styled.span<{isDisabled?: boolean}>`
  margin-top: ${({theme}) => theme.getSize(4)};
  display: flex;
  cursor: pointer;
  color: ${({theme}) => theme.colors.palettes.white[10][100]};
  border-radius: 4px;
  padding: 6px ${({theme}) => theme.getSize(3)};
  background-color: ${({theme}) => theme.colors.palettes.blue[60][100]};

  opacity: ${({isDisabled}) => (isDisabled ? 0.4 : 1)};
  cursor: ${({isDisabled}) => (isDisabled ? 'not-allowed' : 'pointer')};

  svg {
    margin-right: 8px;
    margin-top: -2px;
  }
`;

export const CoverPhotoLabelWrapper = styled(Tag)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  height: 6px;
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  border-radius: 3px;
  overflow: hidden;
`;

type ProgressLineProps = {
  percentage: number;
};

export const ProgressLine = styled.div<ProgressLineProps>`
  flex: 1 1;
  width: ${({percentage}) => percentage}%;
  height: inherit;
  background-color: ${({theme}) => theme.colors.palettes.blue[60][100]};
  transition: width 225ms ease-in-out;
`;
