import * as Yup from 'yup';

import i18n from '@omnetic-dms/i18n';

import {BankAccountsFormType} from '../types';

export const bankAccountsValidations = (): Yup.SchemaOf<BankAccountsFormType> =>
  // eslint-disable-next-line no-restricted-syntax
  Yup.object().shape({
    bankAccounts: Yup.array(
      Yup.object().shape({
        accountName: Yup.string()
          .nullable()
          .required(i18n.t('general.validations.fieldIsRequired')),
        accountNumber: Yup.string()
          .nullable()
          .required(i18n.t('general.validations.fieldIsRequired')),
        bankName: Yup.string().nullable().required(i18n.t('general.validations.fieldIsRequired')),
        iban: Yup.string().nullable(),
        swift: Yup.string().nullable(),
        currency: Yup.string().nullable(),
        isPrimary: Yup.bool().nullable(),
      })
    ),
  }) as unknown as Yup.SchemaOf<BankAccountsFormType>;
