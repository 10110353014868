type DownloadFileConfig = {
  fileName: string;
  fileType: 'attachment' | 'inline';
};

type ConfigType = Partial<DownloadFileConfig>;

export const downloadFile = (urlData: string, config?: ConfigType) => {
  const link = document.createElement('a');
  const event = new MouseEvent('click');

  const url = new URL(urlData);
  if (config?.fileType) {
    url.searchParams.append('type', config.fileType);
  }

  link.download = config?.fileName ?? 'file';
  link.href = url.toString();

  link.dispatchEvent(event);
  link.remove();
};
