import {
  ButtonProps,
  DataStatus,
  closeCurrentDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {defaultTo, equals, isNil, not} from 'ramda';
import {isArray, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  ServiceOrderIssueNoteBasketItem,
  useBulkDeleteServiceOrderIssueNoteItemsMutation,
  useDeleteServiceOrderIssueNoteBasketMutation,
  useGetServiceOrderIssueNoteBasketQuery,
  useGetServiceOrderIssueNoteQuery,
  useGetServiceOrderIssueVariantQuery,
  usePatchServiceOrderIssueNoteBasketCheckoutMutation,
  usePatchServiceOrderIssueNoteBasketItemQuantityMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';
import {
  AfterSalesMaterialBasket,
  EditMaterialItem,
  EitherQuantityOrError,
  FullScreenModal,
  MaterialBasketItemEditingDetails,
  handleApiError,
  useBasketMechanic,
  useInvalidBasketItemsIds,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, composePath, suffixTestId, useNavigate} from 'shared';

import {useRefreshDataGrid} from 'features/datagrid';

import {MISSING_EDITING_DETAILS_MESSAGE} from '../../../../../constants/missingEditingDetailsMessage';
import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';
import {MaterialList} from '../../../../ServiceOrderIssueNew/components/MaterialList';

interface ServiceOrderIssueAddMaterialModalProps extends RequiredTestIdProps {
  onDiscard: () => void;
  onSubmit: () => void;
}

export function ServiceOrderIssueAddMaterialModal(props: ServiceOrderIssueAddMaterialModalProps) {
  const {serviceOrderIssueNoteId} = useWarehouseParams();

  const navigate = useNavigate();

  const [addedSparePartsDatagridRef, refreshAddedSparePartsDatagrid] = useRefreshDataGrid();

  const {
    data: serviceOrderIssueNote,
    isLoading: isServiceOrderIssueNoteLoading,
    isError: hasServiceOrderIssueNoteError,
  } = useGetServiceOrderIssueNoteQuery({
    serviceOrderIssueNoteId,
  });

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: hasBasketError,
  } = useGetServiceOrderIssueNoteBasketQuery({
    serviceOrderIssueNoteId,
  });

  const {
    data: serviceOrderIssueVariant,
    isLoading: isServiceOrderIssueVariantLoading,
    isError: hasServiceOrderIssueVariantError,
  } = useGetServiceOrderIssueVariantQuery(
    {serviceOrderIssueVariantId: defaultTo('', serviceOrderIssueNote?.serviceOrderIssueVariantId)},
    {skip: isNil(serviceOrderIssueNote)}
  );

  const [discardBasket, {isLoading: isDiscardLoading}] =
    useDeleteServiceOrderIssueNoteBasketMutation();

  const [patchServiceOrderIssueNoteBasketCheckout, {isLoading: isUpdateLoading}] =
    usePatchServiceOrderIssueNoteBasketCheckoutMutation();

  const [patchServiceOrderIssueNoteBasketItemQuantity] =
    usePatchServiceOrderIssueNoteBasketItemQuantityMutation();

  const [deleteBasketItems, {isLoading: isDeletingBasketItems}] =
    useBulkDeleteServiceOrderIssueNoteItemsMutation();

  const serviceCaseId = defaultTo('', serviceOrderIssueNote?.serviceCaseId);

  const serviceOrderId = defaultTo('', serviceOrderIssueNote?.serviceOrderId);

  const basketItems = basket?.sparePartsBasketItem ?? [];

  const basketTotalPrice = basket?.sparePartsBasketTotalPrice;

  const basketMechanicId = basket?.assignMechanicId;

  const isLoading = isServiceOrderIssueNoteLoading || isServiceOrderIssueVariantLoading;

  const hasError = hasServiceOrderIssueNoteError || hasServiceOrderIssueVariantError;

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  const {
    mechanics,
    areMechanicsLoading,
    mechanicPayload,
    inputMechanicId,
    mechanicError,
    handleMechanicChange,
    setMechanicError,
  } = useBasketMechanic({
    authorizationProfileId: serviceOrderIssueVariant?.authorizationProfileId,
    assignedMechanicId: basketMechanicId,
  });

  const handleAdd = async () => {
    if (isNil(mechanicPayload)) {
      return setMechanicError(i18n.t('general.notifications.fieldIsRequired'));
    }

    await patchServiceOrderIssueNoteBasketCheckout({
      serviceOrderIssueNoteId,
      body: {
        mechanic: mechanicPayload,
      },
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.itemsAdded')))
      .then(() =>
        navigate(
          composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
            params: {id: serviceOrderIssueNoteId},
          })
        )
      )
      .then(props.onSubmit)
      .catch(handleApiError);
  };

  const handleDiscard = async () => {
    await discardBasket({serviceOrderIssueNoteId})
      .unwrap()
      .then(() =>
        navigate(
          composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
            params: {id: serviceOrderIssueNoteId},
          })
        )
      )
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await patchServiceOrderIssueNoteBasketItemQuantity({
      serviceOrderIssueNoteId,
      itemId,
      body: {quantity: quantity.newQuantity!},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleEdit = (editingDetails: MaterialBasketItemEditingDetails) => {
    if (
      isNil(editingDetails?.itemId) ||
      isNil(editingDetails?.serviceCaseId) ||
      isNil(editingDetails?.serviceOrderId) ||
      isNil(editingDetails?.serviceJobId)
    ) {
      throw new Error(MISSING_EDITING_DETAILS_MESSAGE);
    }

    openDialog(
      <EditMaterialItem
        itemId={editingDetails.itemId}
        serviceCaseId={editingDetails.serviceCaseId}
        serviceOrderId={editingDetails.serviceOrderId}
        serviceJobId={editingDetails.serviceJobId}
        onClose={closeCurrentDialog}
      />,
      {title: editingDetails.itemName}
    );
  };

  const handleDelete = async (itemsIds: string | string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody = {
      serviceOrderIssueNoteItemIds: ids,
    };

    await deleteBasketItems({
      serviceOrderIssueNoteId,
      body: requestBody,
    })
      .unwrap()
      .then(refreshAddedSparePartsDatagrid)
      .catch(handleApiError);
  };

  const actions: ButtonProps[] = [
    {
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleDiscard,
      isLoading: isDiscardLoading,
    },
    {
      title: i18n.t('general.actions.add'),
      variant: 'primary',
      onClick: handleAdd,
      isLoading: isUpdateLoading,
      isDisabled: isNilOrEmpty(basketItems) || isNotNilOrEmpty(invalidBasketItemsIds),
      'data-testid': suffixTestId('headerActions.add', props),
    },
  ];

  return (
    <FullScreenModal headline={i18n.t('general.actions.addMaterial')} actions={actions}>
      <DataStatus isLoading={isLoading} isError={hasError} minHeight="100%">
        <Box padding={4} height="100%">
          <HStack spacing={4} height="100%">
            <Box flex={5}>
              <MaterialList
                serviceOrderIssueNote={serviceOrderIssueNote}
                addedSparePartsDatagridRef={addedSparePartsDatagridRef}
                isBasketEmpty={isNilOrEmpty(basketItems)}
                data-testid={suffixTestId('list', props)}
              />
            </Box>
            <Box flex={1} minWidth={86}>
              <AfterSalesMaterialBasket<ServiceOrderIssueNoteBasketItem>
                basket={{
                  items: basketItems,
                  totalPrice: basketTotalPrice,
                  isLoading: isBasketLoading,
                  hasError: hasBasketError,
                  hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                  isDeletingItems: isDeletingBasketItems,
                }}
                mechanic={{
                  mechanics,
                  areMechanicsLoading,
                  isMechanicRequired: true,
                  selectedMechanicId: inputMechanicId,
                  mechanicError,
                  onMechanicChange: handleMechanicChange,
                }}
                jobChange={{
                  canPerformJobChange: true,
                  serviceCaseId,
                  serviceOrderId,
                }}
                onQuantityChange={handleQuantityChange}
                onEdit={handleEdit}
                onDelete={handleDelete}
                data-testid={suffixTestId('basket', props)}
              />
            </Box>
          </HStack>
        </Box>
      </DataStatus>
    </FullScreenModal>
  );
}
