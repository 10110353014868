import {Box, Heading, HStack, Show} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {Rank} from '../Rank/Rank';

type PercentageSizeType = 'default' | 'small';
interface PercentageProps extends TestIdProps {
  /**
   * @about Accepting values in range 0 - 100
   */
  percentage: number | null;
  size?: PercentageSizeType;
}

export function Percentage(props: PercentageProps) {
  const {percentage, size = 'default'} = props;

  return (
    <Box paddingTop={4}>
      <HStack spacing={2} align="center">
        <Show when={isNotNil(percentage) && percentage >= 0 && percentage <= 100}>
          <Heading
            size={size === 'default' ? 1 : 3}
            data-testid={suffixTestId('batteryPercentage', props)}
          >
            {`${Math.floor(percentage ?? 0)} %`}
          </Heading>
        </Show>
        <Box paddingVertical={1}>
          <Rank percentage={percentage} />
        </Box>
      </HStack>
    </Box>
  );
}
