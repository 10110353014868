import {DialogTypes} from '../components/PromoPhotosForm/types/DialogTypes';
import {PromoPhotosFormType} from '../types/PromoPhotosFormType';
import {PromoPhotosType} from '../types/PromoPhotosType';

export const getDialogTypeByValues = (editData: PromoPhotosType, data: PromoPhotosFormType) => {
  if (editData.fileId !== data.fileId.id && editData.position !== data.position) {
    return DialogTypes.ORDER_PHOTO_CHANGE;
  }

  if (editData.fileId !== data.fileId.id) {
    return DialogTypes.PHOTO_CHANGE;
  }

  if (editData.position !== data.position) {
    return DialogTypes.ORDER_CHANGE;
  }
};
