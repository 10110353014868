import {PayloadAction} from '@reduxjs/toolkit';
import {showNotification} from 'platform/components';
import {all, put, takeLeading, takeEvery} from 'typed-redux-saga';

import {CreateAssignmentService} from '../../services/CreateAssignmentService';
import {DeleteAssignmentService} from '../../services/DeleteAssignmentService';
import {GetAssignmentsByEntityService} from '../../services/GetAssignmentsByEntityService';
import {Assignment} from '../../types/Assignment';
import {CreateAssignmentRequestBody} from '../../types/CreateAssignmentRequestBody';
import {callApiSaga} from '../../utils/api';
import {
  assignUsersError,
  assignUsersRequest,
  assignUsersSuccess,
  fetchAssignmentsRequest,
  fetchAssignmentsSuccess,
  fetchUnAssignRequest,
  fetchUnAssignSuccess,
} from './reducer';
import {UsersRequestType} from './types';

function* unAssignUser({payload}: PayloadAction<Assignment>): Generator {
  try {
    const {id} = payload;
    yield* callApiSaga(DeleteAssignmentService.deleteAssignment, {
      assignmentId: id,
    });

    yield* put(fetchUnAssignSuccess(payload));
    showNotification.success('User Unassigned');
  } catch (error: any) {
    showNotification.error(error?.response?.data?.error?.message);
  }
}

function* assignUsersGen({payload}: PayloadAction<CreateAssignmentRequestBody>): Generator {
  try {
    const response = yield* callApiSaga(CreateAssignmentService.createAssignment, {
      requestBody: payload,
    });

    yield* put(
      fetchAssignmentsRequest({
        assignmentIdentityId: payload.identity.id,
        assignmentIdentityType: payload.identity.type,
      })
    );
    //@ts-expect-error invalid type
    yield* put(assignUsersSuccess(response));
  } catch (error: any) {
    showNotification.error(error?.response?.data?.error?.message);
    yield* put(assignUsersError(error));
  }
}

function* getAssignmentsGen({payload}: PayloadAction<UsersRequestType>): Generator {
  try {
    const {assignmentIdentityId, assignmentIdentityType} = payload;
    const response = yield* callApiSaga(GetAssignmentsByEntityService.getAssignmentsByEntity, {
      assignmentIdentityId,
      assignmentIdentityType,
    });

    yield* put(fetchAssignmentsSuccess({assignmentIdentityType, assignmentIdentityId, response}));
  } catch (error: any) {
    showNotification.error(error?.response?.data?.error?.message);
  }
}

export function* assignmentSaga(): Generator {
  yield* all([
    takeLeading(assignUsersRequest, assignUsersGen),
    takeEvery(fetchAssignmentsRequest, getAssignmentsGen),
    takeLeading(fetchUnAssignRequest, unAssignUser),
  ]);
}
