import {ButtonGroup, closeCurrentDialog, DataStatus, DialogFooter} from 'platform/components';
import {getCssSize} from 'platform/foundation';
import styled from 'styled-components';

import {usePreviewEmailNotificationQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {useBoolean} from 'shared';

interface EmailExamplePreviewProps {
  notificationId: string;
}

const DEFAULT_IFRAME_HEIGHT = 203;

export function EmailExamplePreview(props: EmailExamplePreviewProps) {
  const [isIframeLoaded, loadIframe] = useBoolean(false);

  const {data, isLoading, isError} = usePreviewEmailNotificationQuery({
    id: props.notificationId,
    locale: i18n.language,
  });

  return (
    <>
      <DataStatus isLoading={isLoading} isError={isError} minHeight={DEFAULT_IFRAME_HEIGHT}>
        <IFrame isIframeLoaded={isIframeLoaded} srcDoc={data} loading="eager" onLoad={loadIframe} />
      </DataStatus>

      <DialogFooter>
        <ButtonGroup
          align="right"
          buttons={[{title: i18n.t('general.actions.close'), onClick: closeCurrentDialog}]}
        />
      </DialogFooter>
    </>
  );
}

const IFrame = styled.iframe<{isIframeLoaded: boolean}>`
  width: 100%;
  height: ${getCssSize(DEFAULT_IFRAME_HEIGHT)};
  visibility: ${({isIframeLoaded}) => (isIframeLoaded ? 'visible' : 'hidden')};
`;
