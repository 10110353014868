import {
  GetDocumentKindsApiArg,
  GetDocumentKindsApiResponse,
  GetTemplateGroupsApiResponse,
  GetTemplatesApiArg,
  GetTemplatesApiResponse,
} from '../types/document';
import {omneticApi} from './baseApi/omneticApi';

export const documentApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getDocumentKinds: build.query<GetDocumentKindsApiResponse, GetDocumentKindsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/document/document-kinds`,
        params: {documentKindGroup: queryArg.documentKindGroup},
      }),
    }),
    getTemplateGroups: build.query<GetTemplateGroupsApiResponse, void>({
      query: () => ({
        url: `/dms/v2/document/template-group`,
      }),
    }),
    getTemplates: build.query<GetTemplatesApiResponse, GetTemplatesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/document/templates`,
        params: {documentKindCode: queryArg.documentKindCode},
      }),
    }),
  }),
});

export const {useGetDocumentKindsQuery, useGetTemplateGroupsQuery, useGetTemplatesQuery} =
  documentApi;
