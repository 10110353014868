import {useMemo, ReactNode} from 'react';

import {identity} from 'ramda';

import {TranslationContextProvider} from './TranslationContext';

export type TranslationInterpreter = (key: string, ...args: unknown[]) => string;

interface TranslationContextProps {
  t?: TranslationInterpreter;
  children: ReactNode;
}

export function TranslationProvider(props: TranslationContextProps) {
  const contextValue = useMemo(() => props.t ?? identity, [props.t]);

  return (
    <TranslationContextProvider value={contextValue}>{props.children}</TranslationContextProvider>
  );
}
