import {
  BreadcrumbType,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, Heading, HStack, Space, Text, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useCallback, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {
  equals,
  indexBy,
  lensPath,
  mergeDeepLeft,
  mergeDeepRight,
  over,
  path,
  pipe,
  prop,
  reject,
} from 'ramda';

import {
  CalculateFromWithVatApiArg,
  CreateCommissionRuleArg,
  selectTenant,
  useCreateCommissionRuleMutation,
  useGetCommissionRuleQuery,
  useUpdateCommissionRuleMutation,
  VatPrice,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {CalculatePrice, handleApiError} from '@omnetic-dms/shared';
import {DEFAULT_CURRENCY, selectVatRatesByCountryCode} from '@omnetic-dms/teas';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const breadcrumbsLinks: BreadcrumbType[] = [
  {
    label: i18n.t('page.salesSettingsBusinessCase.labels.businessCase'),
    href: settingsRoutes.businessCase,
  },
  {
    label: i18n.t('page.salesSettingsBusinessCase.labels.commissionFeeTitle'),
    href: settingsRoutes.businessCaseCommissionFee,
  },
];

const FORM_DEFAULT_VALUES = {minimumAmount: {vatType: 'S'}, fixedAmount: {vatType: 'S'}};
const MINIMUM_ALLOWED_NUMBER = 0.01;
const MAXIMUM_PERCENTAGE_NUMBER = 100;

function CreateCommissionFee() {
  const {id} = useParams();

  const {
    data: commissionRule,
    isLoading: isCommissionRuleLoading,
    isFetching: isFetchingCommissionRule,
    isError: isErrorCommissionRule,
  } = useGetCommissionRuleQuery({commissionRuleId: id ?? ''}, {skip: !id});

  const [createCommissionRule] = useCreateCommissionRuleMutation();
  const [updateCommissionRule] = useUpdateCommissionRuleMutation();

  const {data: selectedTenant} = useSelector(selectTenant);
  const vatRates = useSelector(selectVatRatesByCountryCode(selectedTenant?.country))?.rates?.map(
    (v) => ({
      ...v,
      value: v.type,
      label: `${v.rate} % ${v.name}`,
      fieldLabel: `${v.rate} %`,
    })
  );

  const vatRatesByType = vatRates ? indexBy(prop('type'), vatRates) : {};

  const [lastTouchedMinField, setLastTouchedMinField] = useState<'withVat' | 'withoutVat'>();
  const [lastTouchedFixedField, setLastTouchedFixedField] = useState<'withVat' | 'withoutVat'>();

  const navigate = useNavigate();

  const {data: tenant} = useSelector(selectTenant);

  const handleSubmit: FormSubmitHandler<CreateCommissionRuleArg> = async (data) => {
    if (isFetchingCommissionRule) {
      showNotification.error();
      return;
    }
    const currency = tenant?.currency ?? DEFAULT_CURRENCY;
    const bodyMetadata = {
      priceUpTo: {
        currency,
      },
      fixedAmount: {
        priceWithVat: {
          currency,
        },
        priceWoVat: {
          currency,
        },
        vatRate: vatRatesByType[path(['fixedAmount', 'vatType'], data)]?.rate,
      },
      minimumAmount: {
        priceWithVat: {
          currency,
        },
        priceWoVat: {
          currency,
        },
        vatRate: vatRatesByType[path(['minimumAmount', 'vatType'], data)]?.rate,
      },
    };

    const commissionRuleValues = mergeDeepRight(bodyMetadata, data);

    const commissionRuleRequestBody = pipe(
      over(lensPath(['fixedAmount']), amountToVatPriceOrNull),
      over(lensPath(['minimumAmount']), amountToVatPriceOrNull),
      over(lensPath(['priceUpTo', 'amount']), String),
      over(lensPath(['percentage']), (val) => (val ? String(val) : null))
    )(commissionRuleValues);

    await (
      commissionRule?.id
        ? updateCommissionRule({id: commissionRule.id, ...commissionRuleRequestBody})
        : createCommissionRule(commissionRuleRequestBody)
    )
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.businessCaseCommissionFee))
      .catch(handleApiError);
  };

  const handleChangeVatCode = () => {
    if (!lastTouchedMinField) {
      setLastTouchedMinField('withVat');
    }
    if (!lastTouchedFixedField) {
      setLastTouchedFixedField('withVat');
    }
  };

  const handleCalculationChange = useCallback(
    (formApi: UseFormReturn<CreateCommissionRuleArg>, name: 'fixedAmount' | 'minimumAmount') =>
      (res: {withVat?: string} | {withoutVat?: string}) => {
        if ('withVat' in res) {
          formApi.setValue(`${name}.priceWithVat.amount`, res?.withVat ?? '');
        }
        if ('withoutVat' in res) {
          formApi.setValue(`${name}.priceWoVat.amount`, res?.withoutVat ?? '');
        }
      },
    []
  );
  const formDefaultValues = mergeDeepLeft(
    reject(equals(null), commissionRule ?? {}),
    FORM_DEFAULT_VALUES
  );

  return (
    <DataStatus isLoading={isCommissionRuleLoading} isError={isErrorCommissionRule}>
      <Form<CreateCommissionRuleArg>
        onSubmit={handleSubmit}
        schema={commissionFeeSchema}
        defaultValues={formDefaultValues as CreateCommissionRuleArg}
      >
        {(control, formApi) => {
          const minPriceVatType = formApi.watch('minimumAmount.vatType');
          const fixedPriceVatType = formApi.watch('fixedAmount.vatType');

          const [minPriceWithVat, minPriceWithoutVat, fixedPriceWithVat, fixedPriceWithoutVat] =
            formApi.watch([
              'minimumAmount.priceWithVat.amount',
              'minimumAmount.priceWoVat.amount',
              'fixedAmount.priceWithVat.amount',
              'fixedAmount.priceWoVat.amount',
            ]);

          return (
            /**
             * TODO: Refactor this part - [T20-17714]
             */
            <SettingsTemplate
              header={{
                title: commissionRule
                  ? `${i18n.t('page.salesSettingsBusinessCase.labels.newCommissionSettings')}`
                  : i18n.t('page.salesSettingsBusinessCase.labels.newCommissionSettings'),
                breadcrumbs: breadcrumbsLinks,
                actions: [
                  {
                    type: 'button',
                    'data-testid': testIds.settings.businessCaseCommissionFee(
                      'create-commission-fee-discard-button'
                    ),
                    variant: 'secondary',
                    onClick: () => navigate(settingsRoutes.businessCaseCommissionFee),
                    title: i18n.t('general.actions.discard'),
                  },
                  {
                    type: 'form-button',
                    'data-testid': testIds.settings.businessCaseCommissionFee(
                      'create-commission-fee-confirm-button'
                    ),
                    variant: 'primary',
                    buttonType: 'submit',
                    control,
                    title: i18n.t('general.actions.saveChanges'),
                  },
                ],
              }}
              data-testid={testIds.settings.businessCaseCommissionFee('create-commission-fee')}
            >
              <Heading size={4}>
                {i18n.t('page.salesSettingsBusinessCase.labels.priceTitle')}
              </Heading>
              <Space vertical={4} />
              <VStack spacing={4}>
                <Box width={90}>
                  <FormField
                    control={control}
                    type="number"
                    name="priceUpTo.amount"
                    label={i18n.t('general.labels.sellingPrice')}
                    data-testid={testIds.settings.businessCaseCommissionFee('sellingPrice')}
                  />
                </Box>

                <Box maxWidth={180}>
                  <Text size="small" color="secondary">
                    {i18n.t('page.salesSettingsBusinessCase.labels.newSettingDescription')}
                  </Text>
                </Box>

                <Space vertical={4} />
                <Heading size={4}>{i18n.t('entity.businessCase.labels.salesCommission')}</Heading>

                <Box width={90}>
                  <FormField
                    control={control}
                    type="number"
                    decimalPlaces={2}
                    name="percentage"
                    label={i18n.t('page.businessCase.labels.percentage')}
                    data-testid={testIds.settings.businessCaseCommissionFee('percentage')}
                  />
                </Box>

                {/* MIN PRICE CALCULATION */}
                <CalculatePrice
                  amount={lastTouchedMinField === 'withVat' ? minPriceWithVat : minPriceWithoutVat}
                  countryCode={selectedTenant?.country ?? ''}
                  type={lastTouchedMinField}
                  vatCode={minPriceVatType as CalculateFromWithVatApiArg['vatRateType']}
                  onChange={handleCalculationChange(formApi, 'minimumAmount')}
                />

                {/* FIXED PRICE CALCULATION */}
                <CalculatePrice
                  amount={
                    lastTouchedFixedField === 'withVat' ? fixedPriceWithVat : fixedPriceWithoutVat
                  }
                  countryCode={selectedTenant?.country ?? ''}
                  type={lastTouchedFixedField}
                  vatCode={fixedPriceVatType as CalculateFromWithVatApiArg['vatRateType']}
                  onChange={handleCalculationChange(formApi, 'fixedAmount')}
                />

                <Box maxWidth={180}>
                  <HStack spacing={3}>
                    <Box flex={1}>
                      <FormField
                        type="number"
                        decimalPlaces={2}
                        control={control}
                        name="minimumAmount.priceWoVat.amount"
                        label={i18n.t(
                          'page.salesSettingsBusinessCase.labels.minimalPriceWithoutVat'
                        )}
                        onChange={() => setLastTouchedMinField('withoutVat')}
                        data-testid={testIds.settings.businessCaseCommissionFee(
                          'minimumAmount-priceWoVat'
                        )}
                      />
                    </Box>

                    <Box width={25}>
                      <FormField
                        type="choice"
                        name="minimumAmount.vatType"
                        options={vatRates}
                        control={control}
                        placeholder=""
                        label={i18n.t('general.labels.vat')}
                        onChange={handleChangeVatCode}
                        isNotClearable
                        data-testid={testIds.settings.businessCaseCommissionFee(
                          'minimumAmount-vatType'
                        )}
                      />
                    </Box>

                    <Box flex={1}>
                      <FormField
                        type="number"
                        decimalPlaces={2}
                        control={control}
                        name="minimumAmount.priceWithVat.amount"
                        label={i18n.t('page.salesSettingsBusinessCase.labels.minimalPriceWithVat')}
                        onChange={() => setLastTouchedMinField('withVat')}
                        data-testid={testIds.settings.businessCaseCommissionFee(
                          'minimumAmount-priceWithVat'
                        )}
                      />
                    </Box>
                  </HStack>
                </Box>

                <Box maxWidth={180}>
                  <HStack spacing={3}>
                    <Box flex={1}>
                      <FormField
                        type="number"
                        decimalPlaces={2}
                        control={control}
                        name="fixedAmount.priceWoVat.amount"
                        label={i18n.t('page.salesSettingsBusinessCase.labels.fixedPriceWithoutVat')}
                        onChange={() => setLastTouchedFixedField('withoutVat')}
                        data-testid={testIds.settings.businessCaseCommissionFee(
                          'fixedAmount-priceWoVat'
                        )}
                      />
                    </Box>

                    <Box width={25}>
                      <FormField
                        type="choice"
                        name="fixedAmount.vatType"
                        options={vatRates}
                        control={control}
                        placeholder=""
                        label={i18n.t('general.labels.vat')}
                        onChange={handleChangeVatCode}
                        isNotClearable
                        data-testid={testIds.settings.businessCaseCommissionFee(
                          'fixedAmount-vatType'
                        )}
                      />
                    </Box>

                    <Box flex={1}>
                      <FormField
                        type="number"
                        decimalPlaces={2}
                        control={control}
                        name="fixedAmount.priceWithVat.amount"
                        label={i18n.t('page.salesSettingsBusinessCase.labels.fixedPriceWithVat')}
                        onChange={() => setLastTouchedFixedField('withVat')}
                        data-testid={testIds.settings.businessCaseCommissionFee(
                          'fixedAmount-priceWithVat'
                        )}
                      />
                    </Box>
                  </HStack>
                </Box>
              </VStack>
            </SettingsTemplate>
          );
        }}
      </Form>
    </DataStatus>
  );
}

/**
 * @about checks if all parameters are set and valid or return null
 * @param vatPrice
 * @return VatPrice | null
 */
const amountToVatPriceOrNull = (vatPrice: Partial<VatPrice> | null): VatPrice | null => {
  if (
    !(
      vatPrice?.priceWithVat?.amount &&
      vatPrice?.priceWoVat?.amount &&
      vatPrice.vatRate &&
      vatPrice.vatType
    )
  ) {
    return null;
  }

  const formattedVatPrice = pipe(
    over(lensPath(['priceWithVat', 'amount']), String),
    over(lensPath(['priceWoVat', 'amount']), String)
  )(vatPrice);

  return formattedVatPrice;
};

const amountYupTest = (name: string) =>
  Yup.mixed().test(
    name,
    i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
    (value) => !value || parseFloat(value) >= MINIMUM_ALLOWED_NUMBER
  );

const commissionFeeSchema = Yup.object().shape(
  {
    priceUpTo: Yup.object().shape({
      amount: Yup.mixed()
        .required()
        .test(
          'priceUpTo-type',
          i18n.t('general.errors.mixed.required'),
          (val) => !Number.isNaN(parseFloat(val))
        )
        .test(
          'priceUpTo-amount',
          i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
          (value) => parseFloat(value) >= MINIMUM_ALLOWED_NUMBER
        ),
    }),
    vatCode: Yup.string(),
    percentage: Yup.mixed()
      .test(
        'percentage-min',
        i18n.t('general.notifications.percentageMin', {min: MINIMUM_ALLOWED_NUMBER}),
        (value) => !value || parseFloat(value) >= MINIMUM_ALLOWED_NUMBER
      )
      .test(
        'percentage-max',
        i18n.t('general.notifications.percentageMax', {max: MAXIMUM_PERCENTAGE_NUMBER}),
        (value) => !value || parseFloat(value) <= MAXIMUM_PERCENTAGE_NUMBER
      ),
    minimumAmount: Yup.object().shape({
      vatType: Yup.string(),
      priceWithVat: Yup.object().shape({
        amount: amountYupTest('priceWithVat'),
      }),
      priceWoVat: Yup.object().shape({
        amount: amountYupTest('priceWoVat'),
      }),
    }),
    fixedAmount: Yup.object().shape({
      vatType: Yup.string(),
      priceWithVat: Yup.object().shape({
        amount: amountYupTest('priceWithVat'),
      }),
      priceWoVat: Yup.object().shape({
        amount: amountYupTest('priceWoVat'),
      }),
    }),
  },
  [['purchasePrice', 'sellingPrice']]
);

export const BussinessCaseCommissionFeeDetail = CreateCommissionFee;
