import styled from 'styled-components';

export const MonthWrapper = styled.div``;

export const MonthName = styled.div`
  text-align: center;
  font-weight: bold;
  margin-bottom: ${({theme}) => theme.getSize(3)};
  text-transform: capitalize;
`;

export const DaysWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DayContent = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0 auto;
  border: 1px solid transparent;
  border-radius: ${({theme}) => theme.radii.small};

  &.today {
    color: ${({theme}) => theme.colors.palettes.blue[60][100]};
  }

  &.past {
    pointer-events: none;
    color: ${({theme}) => theme.colors.palettes.neutral[300][100]};
  }

  &:hover {
    border: 1px solid ${({theme}) => theme.colors.palettes.neutral[300][100]};
  }

  &.selected {
    color: ${({theme}) => theme.colors.palettes.white[10][100]};
    background-color: ${({theme}) => theme.colors.palettes.blue[60][100]};
  }
`;

export const DayWrapper = styled.div`
  flex-basis: ${100 / 7}%;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  margin-bottom: ${({theme}) => theme.getSize(3)};

  &.header {
    font-weight: bold;
    text-transform: capitalize;
  }

  &.within-interval {
    background-color: ${({theme}) => theme.colors.palettes.blue[20][100]};
  }

  &.range-start {
    ${DayContent} {
      border: 1px solid ${({theme}) => theme.colors.palettes.blue[60][100]};
      background-color: ${({theme}) => theme.colors.palettes.blue[20][100]};
    }

    clip-path: polygon(18% 0%, 120% 0%, 120% 120%, 18% 120%);
  }

  &.range-end {
    ${DayContent} {
      border: 1px solid ${({theme}) => theme.colors.palettes.blue[60][100]};
      color: ${({theme}) => theme.colors.palettes.white[10][100]};
      background-color: ${({theme}) => theme.colors.palettes.blue[60][100]};
    }

    clip-path: polygon(-20% 0%, 80% 0%, 80% 120%, -20% 120%);
  }

  &.range-start.range-end {
    background-color: transparent;
  }
`;

export const CalendarWrapper = styled.div<{double?: boolean}>`
  width: ${({double, theme}) => (double ? 2 * 273 + 4 * parseInt(theme.getSize(3)) : 273)}px;

  ${MonthWrapper} {
    position: relative;
    top: -20px;
  }
`;

export const CalendarHandle = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  border-radius: ${({theme}) => theme.radii.small};
  cursor: pointer;
  z-index: 1;
`;

export const CalendarHandlesWrapper = styled.div`
  height: 24px;
  position: relative;

  ${CalendarHandle} {
    position: absolute;

    &.previous {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }
`;
