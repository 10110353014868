import {Tooltip} from 'platform/components';
import {Icon} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {selectCountry} from '../../store/common/selectors';

type CountryFlagType = {
  country?: string;
  disableTooltip?: boolean;
};

export const CountryFlag: FC<CountryFlagType> = ({country: countryCode, disableTooltip}) => {
  const country = useSelector(selectCountry(countryCode));

  return (
    <Tooltip label={disableTooltip ? '' : country?.name || ''}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <Icon value={`flags/${countryCode}` as any} size={5} />
    </Tooltip>
  );
};
