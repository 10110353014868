import {filter, isNotNil, omit} from 'ramda';

/**
 * Removes specified properties from an object.
 *
 * This function creates a new object that excludes the properties whose keys are specified
 * in the `keysToRemove` array. The original object remains unchanged.
 *
 * @param {} obj - The object from which properties will be removed.
 * @param {string[]} keysToRemove - An array of strings representing the keys of properties to remove.
 *
 * @example
 * const obj = { name: 'Alice', age: 25, country: 'USA', profession: 'Engineer' };
 * const keys = ['age', 'country'];
 * const result = removeProperties(obj, keys);
 * console.log(result); // { name: 'Alice', profession: 'Engineer' }
 */
export const removeProperties = (obj: Record<string, unknown>, keysToRemove: string[]) =>
  omit(filter(isNotNil, keysToRemove), obj);
