import {VehicleCatalogueEnumOption} from '../types/VehicleCatalogueEnumOption';

export const getAllTreeKeys = (feature: VehicleCatalogueEnumOption): string[] => {
  const keys: string[] = [];

  const traverseAndCollectKeys = (currentNode: VehicleCatalogueEnumOption) => {
    if (!currentNode) {
      return;
    }
    if (currentNode.value) {
      keys.push(currentNode.value);
    }
    currentNode.children?.forEach(traverseAndCollectKeys);
  };

  traverseAndCollectKeys(feature);
  return keys;
};
