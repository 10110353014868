import {captureMessage} from '@sentry/browser';
import {showNotification} from 'platform/components';

import {environment} from '@omnetic-dms/environment';

const isDev = environment.ENV_TYPE === 'dev';

/**
 * Handles unhandled data grid actions by either showing a notification in development mode or
 * capturing an error message in production mode.
 */
export const catchUnhandledDataGridActions = (actionKey: string) => {
  if (isDev) {
    return showNotification.error(`Action callback was not specified for action ${actionKey}`);
  }

  captureMessage('unhandledDataGridAction', (scope) =>
    scope.setLevel('error').setExtras({
      userDescription: `Action callback was not specified for action ${actionKey}`,
    })
  );
};
