import {getDaysInMonth} from 'date-fns';
import {getDateValueFromDayMonth} from 'platform/locale';

import {isNil, range} from 'ramda';

import {Nullish} from 'shared';

const defaultDaysInMonth = 31;

/**
 * Returns list of days for selected or default month
 */
export const getDropdownDays = (selectedMonth: number | Nullish) => {
  const daysInMonth = isNil(selectedMonth)
    ? defaultDaysInMonth
    : getDaysInMonth(getDateValueFromDayMonth(selectedMonth));

  const days = range(1, daysInMonth + 1);

  return days;
};
