import {FC, PropsWithChildren} from 'react';

import {testIds} from '@omnetic-dms/routes';

import {ProfileLayout} from '../../../components/ProfileLayout/ProfileLayout';
import {AutoLogout} from './components/AutoLogout';
import {MyProfilePassword} from './components/MyProfilePassword';

export const Security: FC<PropsWithChildren<any>> = () => (
  <ProfileLayout data-testid={testIds.myProfile.security('wrapper')}>
    <MyProfilePassword />
    <AutoLogout />
  </ProfileLayout>
);
