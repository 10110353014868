export enum AdvertisingPlatformCodeEnum {
  SAUTO = 'sauto',
  TIPCARS = 'tipcars',
  MOBILE_DE = 'mobile.de',
  MYWEB = 'myweb',
  FACEBOOK = 'facebook',
  AUTOSOFT = 'autosoft',
  AUTOCARIS = 'autocaris',
  OTOMOTO = 'otomoto',
  OLX = 'olx',
  SKODA_PLUS = 'skoda_plus',
  DAS_WELT_AUTO = 'das_weltauto',
  MERCEDES_NA_SKLADE = 'mercedes_na_sklade',
  HYUNDAI_PROMISE = 'hyundai_promise',
  FORD_UZYWANE = 'ford_uzywane',
  AUTOPLAC_PL = 'autoplac_pl',
  VEHIS = 'vehis',
  MAMGO = 'mamgo',
  FORD_GERONIMO = 'ford_geronimo',
}
