import {
  PostServiceOrderItemRequest,
  PostServiceOrderItemRequestSchema,
  PostServiceOrderItemResponse,
  PostServiceOrderItemResponseSchema,
  PostWarehouseArticleItemRequest,
  PostWarehouseArticleItemRequestSchema,
  PostWarehouseArticleItemResponse,
  PostWarehouseArticleItemResponseSchema,
} from '../types/metadaWarehouseServiceOrderIssueNoteItem';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseServiceOrderIssueNoteItemApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postServiceOrderItem: build.mutation<PostServiceOrderItemResponse, PostServiceOrderItemRequest>(
      {
        query: (queryArg) => ({
          url: `warehouse/v1/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}/item/service-order`,
          method: 'POST',
          body: queryArg,
          queryArg,
        }),
        invalidatesTags: ['serviceOrderIssueNoteBasket'],
        extraOptions: {
          requestSchema: PostServiceOrderItemRequestSchema,
          responseSchema: PostServiceOrderItemResponseSchema,
        },
      }
    ),
    postWarehouseArticleItem: build.mutation<
      PostWarehouseArticleItemResponse,
      PostWarehouseArticleItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/service-order-issue-note/${queryArg.serviceOrderIssueNoteId}/item/warehouse-article`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['serviceOrderIssueNoteBasket'],
      extraOptions: {
        requestSchema: PostWarehouseArticleItemRequestSchema,
        responseSchema: PostWarehouseArticleItemResponseSchema,
      },
    }),
  }),
});

export const {usePostServiceOrderItemMutation, usePostWarehouseArticleItemMutation} =
  metadaWarehouseServiceOrderIssueNoteItemApi;
