import {reverse, sortWith, descend} from 'ramda';

import {Country} from '../types/Country';
import {Alpha3CountryCode} from './countryCodes';

/**
 * @about Returns sorted countries with preferred countries on top.
 * @param preferredCountries
 * @param countries
 */
export const sortCountries = (
  preferredCountries: Alpha3CountryCode[] | undefined,
  countries: Country[] | undefined
): Country[] | undefined => {
  if (!preferredCountries || !countries) {
    return countries;
  }

  const preferredCountriesReversed = reverse(preferredCountries);

  const sortByCountryCode = sortWith([
    descend((a: Country) => preferredCountriesReversed.indexOf(a.countryCode)),
  ]);

  return sortByCountryCode(countries);
};
