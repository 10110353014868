import {getColorByPath, CSSDimension, Integer, Show, ThemeColorPath} from 'platform/foundation';
import styled, {css} from 'styled-components';

import {Children, isValidElement, ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {TableVariant} from '../types/TableVariant';
import {useTableContext} from '../useTableContext';
import {TableRowActions, TableRowActionsData} from './TableRowActions';

export interface TableRowProps extends TestIdProps {
  children?: ReactNode;
  actions?: TableRowActionsData;
  level?: number;
  actionsWidth?: CSSDimension | Integer;
  onClick?: () => void;
  color?: ThemeColorPath;
  hoverColor?: ThemeColorPath;
}

export function TableRow(props: TableRowProps) {
  const context = useTableContext();

  return (
    <TRStyled
      $variant={context.variant}
      $color={props.color}
      $hoverColor={props.hoverColor}
      data-testid={suffixTestId('tableRow', props)}
    >
      {Children.map(props.children, (child) => {
        if (!isValidElement(child)) {
          return;
        }

        return (
          <td key={child.key} data-testid={suffixTestId('cell', props)} onClick={props.onClick}>
            {child}
          </td>
        );
      })}
      <Show when={props.actions}>
        <TableRowActions
          actions={props.actions}
          actionsWidth={props.actionsWidth}
          data-testid={props['data-testid']}
        />
      </Show>
    </TRStyled>
  );
}

interface TRStyledProps {
  $variant: TableVariant;
  $color?: ThemeColorPath;
  $hoverColor?: ThemeColorPath;
}

const TRStyled = styled.tr<TRStyledProps>(
  (props) => css`
    ${props.$variant === 'bordered' &&
    css`
      td {
        border-top: 1px solid ${props.theme.colors.general.separator};
      }

      &:first-child td {
        border-top: 0;
      }
    `}

    ${props.$variant === 'stripped' &&
    css`
      &:nth-child(even) td {
        background-color: ${getColorByPath(props.$color, props.theme) ??
        props.theme.colors.palettes.neutral[10][100]};
      }
    `}

  td {
      background-color: ${getColorByPath(props.$color, props.theme)};
      cursor: ${props.onClick ? 'pointer' : undefined};
    }

    &:hover {
      td {
        background-color: ${getColorByPath(props.$hoverColor, props.theme) ??
        props.theme.colors.palettes.neutral[20][100]};
      }
    }
  `
);
