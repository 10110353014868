import {Alert} from 'platform/components';

import {not} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useXentryIntegration} from '../../../../../hooks/useXentryIntegration';

interface XentryIntegrationProps extends RequiredTestIdProps {
  serviceCaseId: string;
}

export function XentryIntegration(props: XentryIntegrationProps) {
  const {xentryAlert} = useXentryIntegration(props);

  if (not(xentryAlert)) {
    return null;
  }

  return <Alert {...xentryAlert} data-testid={suffixTestId('linkedXentry', props)} />;
}
