import {AlertVariants} from '../../Alert/Alert';
import {TranslationInterpreter} from '../../TranslationProvider/TranslationProvider';

export const getDefaultMessage = (
  variant: AlertVariants | undefined,
  t: TranslationInterpreter
): string | undefined => {
  switch (variant) {
    case 'success':
      return t(`general.notifications.success`);
      break;
    case 'warning':
      return t(`general.actions.warning`);
      break;
    case 'error':
      return t(`general.notifications.error`);
      break;
    case 'info':
      return t(`general.labels.information`);
      break;
    default:
      return undefined;
  }
};
