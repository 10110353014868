export const serviceIntervalsDefault = {
  serviceInspection: {
    lastServiceInspectionDate: null,
    lastServiceInspectionMileage: null,
    nextServiceInspectionDate: null,
    nextServiceInspectionMileage: null,
    replacementAfterMileage: null,
    replacementAfterMonths: null,
  },
  engineOil: {
    lastReplacementDate: null,
    lastReplacementMileage: null,
    nextReplacementDate: null,
    nextReplacementMileage: null,
    replacementAfterMonths: null,
    replacementAfterMileage: null,
  },
  gearboxOil: {
    lastReplacementDate: null,
    lastReplacementMileage: null,
    nextReplacementDate: null,
    nextReplacementMileage: null,
    replacementAfterMonths: null,
    replacementAfterMileage: null,
  },
  fourByFourOilDistributor: {
    lastReplacementDate: null,
    lastReplacementMileage: null,
    nextReplacementDate: null,
    nextReplacementMileage: null,
    replacementAfterMonths: null,
    replacementAfterMileage: null,
  },
  timingBelt: {
    lastReplacementDate: null,
    lastReplacementMileage: null,
    nextReplacementDate: null,
    nextReplacementMileage: null,
    replacementAfterMonths: null,
    replacementAfterMileage: null,
  },
  brakeFluid: {
    lastReplacementDate: null,
    lastReplacementMileage: null,
    nextReplacementDate: null,
    nextReplacementMileage: null,
  },
  coolantFluid: {
    lastReplacementDate: null,
    lastReplacementMileage: null,
    nextReplacementDate: null,
    nextReplacementMileage: null,
  },
  annualMileage: {
    calculatedFromServiceVisits: null,
  },
};
