import {createContext} from 'shared';

import {DataGridContext} from './types';

const [DataGridContextProvider, useDataGridContext] = createContext<DataGridContext>({
  strict: false,
  name: 'DatagridProvider',
});

export {DataGridContextProvider, useDataGridContext};
