import {IconButton, FormField, RadioItem, Separator, Label, FormControl} from 'platform/components';
import {Icon, HStack, Link, VStack, Space, Box} from 'platform/foundation';

import {ConsentTemplate, ConsentSubject, ConsentChannel, ConsentDuration} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {downloadHostedFile} from '@omnetic-dms/shared';

export type ConsentTypeFormType = {
  name: string;
  subject: ConsentSubject;
  branchId: string | null;
  duration: ConsentDuration;
  channels: ConsentChannel[];
  templates: ConsentTemplate[];
};

interface ConsentTypeFormTemplateProps {
  index: number;
  template: ConsentTemplate;
  control: FormControl<ConsentTypeFormType>;
  onPrimaryChange: (index: number) => void;
  onRemove: (index: number) => void;
}

export function ConsentTypeFormTemplate(props: ConsentTypeFormTemplateProps) {
  const handlePrimary = () => props.onPrimaryChange(props.index);
  const handleRemove = () => props.onRemove(props.index);

  return (
    <VStack data-testid={`consent-type-form-template.${props.index}`}>
      <Label>{i18n.t('entity.gdpr.labels.template.title')}</Label>
      <HStack spacing={4} align="center" justify="space-between">
        <Box width="50%">
          <FormField
            type="text"
            data-testid="title"
            control={props.control}
            name={`templates.${props.index}.title`}
            maxLength={100}
          />
        </Box>
        <Box width="45%">
          <RadioItem
            name="isPrimary"
            data-testid="isPrimary"
            value={props.template.isPrimary}
            onChange={handlePrimary}
          >
            {i18n.t('entity.gdpr.labels.defaultTemplate')}
          </RadioItem>
        </Box>
      </HStack>
      <Space vertical={4} />
      <HStack spacing={4} align="center" justify="space-between">
        <HStack align="center" spacing={3}>
          <Icon value="files_default/file_type_DOC" size={8} />
          <Link
            onClick={() => downloadHostedFile(props.template.fileUri, props.template.fileName)}
            title={props.template.fileName}
            data-testid="download-hosted-file"
          />
        </HStack>
        <IconButton icon="action/delete" data-testid="remove" onClick={handleRemove} />
      </HStack>
      <Space vertical={2} />

      <Separator />
    </VStack>
  );
}
