import {ReactNode} from 'react';

import {FlexboxAlign} from '../../types/Flexbox';
import {HStack} from '../HStack/HStack';

interface RightProps {
  align?: FlexboxAlign;
  children: ReactNode;
}

export function Right(props: RightProps) {
  return <HStack justify="flex-end" {...props} />;
}
