import {Attributes, AttributesRow} from 'platform/components';
import {HStack, Heading, Link, Space, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes, workshopRoutes} from '@omnetic-dms/routes';
import {queryParams} from '@omnetic-dms/shared';

import {TestIdProps, composePath, suffixTestId} from 'shared';

import {warehouseSearchParams} from '../../../../../utils/warehouseSearchParams';

interface ServiceOrderIssueDetailsProps extends TestIdProps {
  serviceOrderIssueId: string;
  caseId: string;
  orderId: string;
  rows: AttributesRow[];
}

export function ServiceOrderIssueDetails(props: ServiceOrderIssueDetailsProps) {
  const serviceOrderIssueUrl = composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
    params: {id: props.serviceOrderIssueId},
  });

  const serviceOrderUrl = composePath(workshopRoutes.serviceCaseDetail, {
    params: {id: props.caseId},
    queryParams: {
      section: warehouseSearchParams.serviceDetail.orders,
      [queryParams.SERVICE_CASE_ORDER_ID]: props.orderId,
    },
  });

  return (
    <VStack spacing={3}>
      <Heading size={5}>{i18n.t('page.warehouse.labels.serviceOrderIssue')}</Heading>
      <Attributes
        size="quarter"
        rows={props.rows}
        data-testid={suffixTestId('information', props)}
      />
      <HStack>
        <Link
          size="small"
          rightIcon="action/launch"
          href={serviceOrderIssueUrl}
          target="_blank"
          title={i18n.t('entity.warehouse.actions.viewServiceOrderIssue')}
          data-testid={suffixTestId('viewServiceOrderIssue', props)}
        />
        <Space horizontal={4} />
        <Link
          size="small"
          rightIcon="action/launch"
          href={serviceOrderUrl}
          target="_blank"
          title={i18n.t('entity.warehouse.actions.viewServiceOrder')}
          data-testid={suffixTestId('viewServiceOrder', props)}
        />
      </HStack>
    </VStack>
  );
}
