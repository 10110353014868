import {IntegratedService} from '../../services/IntegratedService';
import {asyncThunkAction} from '../../utils/reduxThunkUtils';
import {INTEGRATIONS_SMS_GATEWAY, INTEGRATIONS_EMAIL_SERVICES} from './constants';

/**
 * Get Konzulta credentials
 */
export const getKonzultaConfig = asyncThunkAction(
  `${INTEGRATIONS_SMS_GATEWAY}/getKonzultaConfig`,
  IntegratedService.getKonzultaConfig
);

/**
 * Update Konzulta credentials
 */
export const patchKonzultaConfig = asyncThunkAction(
  `${INTEGRATIONS_SMS_GATEWAY}/patchKonzultaConfig`,
  IntegratedService.patchKonzultaConfig
);

/**
 * Send sms with Konzulta
 */
export const sendKonzultaSms = asyncThunkAction(
  `${INTEGRATIONS_SMS_GATEWAY}/sendKonzultaSms`,
  IntegratedService.postKonzultaSms
);

/**
 * Get Konzulta credentials
 */
export const getEmailConfig = asyncThunkAction(
  `${INTEGRATIONS_EMAIL_SERVICES}/getEmailConfig`,
  IntegratedService.getEmailConfig
);

/**
 * Update Konzulta credentials
 */
export const patchEmailConfig = asyncThunkAction(
  `${INTEGRATIONS_EMAIL_SERVICES}/patchEmailConfig`,
  IntegratedService.patchEmailConfig
);
