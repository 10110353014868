import {CardVariantType, DataStatus} from 'platform/components';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';

import {EntityResourceIds, useGetNoteQuery, useUpdateNoteMutation} from '@omnetic-dms/api';

import {Nullish, TestIdProps, useDebouncedValue} from 'shared';

import {handleApiError} from '../../utils/handleApiError';
import {NoteCard} from '../NoteCard/NoteCard';

interface NoteWidgetProps extends TestIdProps {
  resourceId: EntityResourceIds;
  recordId: string;
  context: string;
  title?: string;
  cardVariant?: CardVariantType;
  isEditable?: boolean;
}

export function NoteWidget(props: NoteWidgetProps) {
  const [changeNote, setChangeNote] = useState<string | Nullish>();
  const debouncedChangeNote = useDebouncedValue(changeNote, 400);

  const {data: note, isLoading: isNoteLoading} = useGetNoteQuery({
    resourceId: props.resourceId,
    recordId: props.recordId,
    context: props.context,
  });

  const [updateNote, {isLoading: isUpdateLoading}] = useUpdateNoteMutation();

  useEffect(() => {
    if (isNil(debouncedChangeNote)) {
      return;
    }

    updateNote({
      resourceId: props.resourceId,
      recordId: props.recordId,
      context: props.context,
      updateNoteRequestBody: {
        text: debouncedChangeNote,
      },
    })
      .unwrap()
      .then()
      .catch(handleApiError);
  }, [debouncedChangeNote, props.resourceId, props.recordId, props.context, updateNote]);

  return (
    <DataStatus isLoading={isNoteLoading}>
      <NoteCard
        cardVariant={props.cardVariant}
        note={note?.text}
        authorId={note?.updatedBy}
        datetime={note?.updatedAt}
        title={props.title}
        onChange={(values) => setChangeNote(values?.note)}
        isLoading={isUpdateLoading}
        data-testid={props['data-testid']}
        isDisabled={!props.isEditable}
      />
    </DataStatus>
  );
}
