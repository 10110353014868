import {Box} from 'platform/foundation';

import {ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

export interface FormFieldWrapperProps extends TestIdProps {
  children: ReactNode;
}

export function FormFieldWrapper(props: FormFieldWrapperProps) {
  return (
    <Box
      width="100%"
      paddingBottom={4}
      position="relative"
      data-testid={suffixTestId('field', props)}
    >
      {props.children}
    </Box>
  );
}
