import {useEffect, useRef} from 'react';

import {isNotNil} from 'ramda';

/**
 * Hook wrapper for IntersectionObserver interface
 * @returns Ref to be used on observed elements
 **/
export const useIntersectionObserver = (
  callback: IntersectionObserverCallback,
  options?: IntersectionObserverInit
) => {
  const elementsRef = useRef<(Element | null)[]>([]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(callback, options);

    elementsRef.current?.forEach((el) => {
      if (isNotNil(el)) {
        intersectionObserver.observe(el);
      }
    });

    return () => intersectionObserver.disconnect();
  }, [callback, options]);

  return elementsRef;
};
