import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {GetOperationalItemsResponse} from '@omnetic-dms/api';

import {Nullish} from 'shared';

export const getOptionsFromOperationalItems = (
  operationalItems: GetOperationalItemsResponse[] | Nullish
): Option[] => {
  if (isNil(operationalItems)) {
    return [];
  }

  return operationalItems.map((operationalItem) => ({
    label: operationalItem.itemCodeName,
    value: operationalItem.itemCode,
  }));
};
