import {
  Alert,
  ButtonGroup,
  Dialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  useTranslationContext,
} from 'platform/components';
import {Box, Show, Space, Spinner, VStack} from 'platform/foundation';

import {head} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {ExportState} from '../types/ExportOptionsRenderer';

export type ExportFormType = {
  fileType: string[];
  columnSeparator: string;
  decimalSeparator: string;
};

const FILE_TYPE_OPTIONS = [
  {value: 'excel', label: 'Excel'},
  {value: 'csv', label: 'CSV'},
];

const FORM_DEFAULT_VALUES = {
  fileType: ['csv'],
  columnSeparator: 'comma',
  decimalSeparator: 'dot',
};

export interface ExportOptionsRendererProps extends TestIdProps {
  isOpen: boolean;
  onClose: () => void;
  exportToFile: (config: ExportFormType) => void;
  exportState: ExportState;
  totalRows: number;
  isPresetChanged: boolean;
}

const MAX_COUNT_LIMIT = 1000;

export function DefaultExportOptionsRenderer(props: ExportOptionsRendererProps) {
  const t = useTranslationContext();

  const DECIMAL_DELIMITERS = [
    {value: 'comma', label: t('page.dataGrid.export.decimalDelimiter.comma')},
    {value: 'dot', label: t('page.dataGrid.export.decimalDelimiter.dot')},
  ];

  const COLUMN_DELIMITERS = [
    {value: 'comma', label: t('page.dataGrid.export.columnDelimiter.comma')},
    {value: 'semicolon', label: t('page.dataGrid.export.columnDelimiter.semicolon')},
  ];
  const onHandleSubmit: FormSubmitHandler<ExportFormType> = async (values) => {
    props.exportToFile(values as ExportFormType);

    return await Promise.resolve();
  };

  return (
    <Dialog
      title={t('page.datagrid.export.optionsTitle')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="small"
      data-testid={suffixTestId('exportOptionsDialog', props)}
    >
      <Form<ExportFormType> onSubmit={onHandleSubmit} defaultValues={FORM_DEFAULT_VALUES}>
        {(control, formApi) => {
          const fileType = formApi.watch('fileType');

          return (
            <>
              <VStack spacing={5}>
                <Show when={props.isPresetChanged}>
                  <Alert
                    type="inline"
                    variant="warning"
                    title={t('page.datagrid.export.presetChangedWarning')}
                    data-testid={suffixTestId('exportOptionsDialog-presetChangedWarning', props)}
                  />
                </Show>
                <Show when={props.totalRows > MAX_COUNT_LIMIT}>
                  <Alert
                    type="inline"
                    variant="warning"
                    title={t('page.datagrid.export.LimitWarning')}
                    data-testid={suffixTestId('exportOptionsDialog-limitWarning', props)}
                  />
                </Show>
                <Box>
                  <VStack spacing={4}>
                    <FormField
                      control={control}
                      name="fileType"
                      type="chips"
                      options={FILE_TYPE_OPTIONS}
                      label={t('page.datagrid.exprt.fileType')}
                      data-testid={suffixTestId('exportOptionsDialog-fileType', props)}
                    />
                    <FormField
                      control={control}
                      name="decimalSeparator"
                      isNotClearable
                      menuInPortal
                      type="choice"
                      options={DECIMAL_DELIMITERS}
                      label={t('page.datagrid.exprt.decimalSeparator')}
                      data-testid={suffixTestId('exportOptionsDialog-decimalSeparator', props)}
                    />
                    <Show when={head(fileType) === 'csv'}>
                      <FormField
                        control={control}
                        name="columnSeparator"
                        isNotClearable
                        menuInPortal
                        type="choice"
                        options={COLUMN_DELIMITERS}
                        label={t('page.datagrid.exprt.columnSeparator')}
                        data-testid={suffixTestId('exportOptionsDialog-columnSeparator', props)}
                      />
                    </Show>
                  </VStack>
                </Box>
              </VStack>
              {props.exportState === 'exporting' && <Spinner variant="overlay" />}

              {props.exportState !== 'exporting' && (
                <>
                  <Space vertical={4} />
                  <ButtonGroup align="right">
                    <FormButton
                      control={control}
                      type="submit"
                      title={t('page.datagrid.button.export')}
                      data-testid={suffixTestId('exportOptionsDialog-submitButton', props)}
                    />
                  </ButtonGroup>
                </>
              )}
            </>
          );
        }}
      </Form>
    </Dialog>
  );
}
