import {DefaultService as CommonService} from '../../services/DefaultService';
import {RoundingDefinitionService} from '../../services/RoundingDefinitionService';
import {asyncThunkAction} from '../../utils/reduxThunkUtils';

/**
 * Get payment types
 */
export const getPaymentTypes = asyncThunkAction(
  'common/getPaymentTypeEnum',
  RoundingDefinitionService.getPaymentTypeEnum
);

/**
 * Get ad servers
 */
export const getSellerServers = asyncThunkAction(
  'common/sourcingServers',
  CommonService.sourcingServers
);

/**
 * Calculate from with VAT
 */
export const calculateFromWithVat = asyncThunkAction(
  'common/calculateFromWithVat',
  CommonService.calculateFromWithVat
);

/**
 * Calculate from without VAT
 */
export const calculateFromWithoutVat = asyncThunkAction(
  'common/calculateFromWithoutVat',
  CommonService.calculateFromWithoutVat
);
