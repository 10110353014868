import {equals, isNil, join, pipe, reject, trim} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  CompGetInfoV2ApiResponse,
  AddressData,
  AddressResponseBodyV2,
  CommonAddressResponseBody,
} from '@omnetic-dms/api';

import {Nullish, PartialWithNull} from 'shared';

import {useCountry} from './useCountry';

type GetAddressById = {
  addressId: string | Nullish;
  addresses: AddressResponseBodyV2[] | Nullish;
};

interface AddressResponseBodyV2OptionalAddress extends Omit<AddressResponseBodyV2, 'address'> {
  address: PartialWithNull<CommonAddressResponseBody>;
}

export function useAddress() {
  const {getCountryName} = useCountry();

  const getAddressAttributesV1 = (addressData: AddressData | Nullish) => [
    addressData?.addressLine1,
    addressData?.addressLine2,
    addressData?.city,
    addressData?.postalCode,
    getCountryName(addressData?.countryCode),
  ];

  const getStreetAddress = (addressData: PartialWithNull<CommonAddressResponseBody> | Nullish) => {
    if (isNilOrEmpty(addressData)) {
      return null;
    }
    if (isNilOrEmpty(addressData?.street)) {
      return null;
    }
    if (isNotNilOrEmpty(addressData?.street) && isNilOrEmpty(addressData?.descriptiveNumber)) {
      return addressData?.street;
    }
    if (isNilOrEmpty(addressData?.orientationNumber)) {
      return `${addressData?.street} ${addressData?.descriptiveNumber}`;
    }
    return `${addressData?.street} ${addressData?.descriptiveNumber}/${addressData?.orientationNumber}`;
  };

  const getAddressAttributesV2 = (addressData: CommonAddressResponseBody | Nullish) => [
    getStreetAddress(addressData),
    addressData?.city,
    addressData?.zip,
    getCountryName(addressData?.country),
  ];

  const composeAddress = (addressData: CommonAddressResponseBody | Nullish): string | null => {
    if (isNilOrEmpty(addressData)) {
      return null;
    }
    return pipe(getAddressAttributesV2, reject(isNilOrEmpty), join(', '))(addressData);
  };

  const composeAddressV1 = (addressData: AddressData | Nullish): string | null => {
    if (isNilOrEmpty(addressData)) {
      return null;
    }
    return pipe(getAddressAttributesV1, reject(isNilOrEmpty), join(', '))(addressData);
  };

  const composeShortAddress = (
    addressData: AddressResponseBodyV2OptionalAddress | Nullish
  ): string | null => {
    if (isNilOrEmpty(addressData)) {
      return null;
    }
    return pipe(
      reject(isNilOrEmpty),
      join(', '),
      trim
    )([getStreetAddress(addressData?.address), addressData?.address?.city]);
  };

  const getAddressOptions = (addressResponseBody?: AddressResponseBodyV2[]) =>
    addressResponseBody?.map((addressData) => ({
      label: composeAddress(addressData.address),
      value: addressData.id,
    }));

  const getAddressById = ({addressId, addresses}: GetAddressById) =>
    addresses?.find((a) => equals(a.id, addressId));

  const transformToCommonAddress = (
    data: CompGetInfoV2ApiResponse | Nullish
  ): CommonAddressResponseBody | Nullish => {
    if (isNil(data)) {
      return null;
    }

    return {
      street: data?.street ?? '',
      descriptiveNumber: data?.descriptiveNumber ?? null,
      orientationNumber: data?.orientationNumber ?? null,
      city: data?.city ?? '',
      zip: data?.zipCode ?? '',
      state: data?.state ?? null,
      country: data?.country ?? '',
      addressComplement: null,
      prefix: data?.prefix ?? null,
      district: null,
    };
  };

  return {
    composeAddress,
    composeAddressV1,
    composeShortAddress,
    getAddressOptions,
    getAddressById,
    transformToCommonAddress,
  };
}
