import {FeaturesType} from '../../../types/CommonTypes';
import {Highlight} from '../../../types/Highlight';
import {Shortcoming} from '../../../types/Shortcoming';
import {HighlightItemType} from '../types/HighlightItemType';

export const convertVehicleTagsToVehicleSnippetHighlights = (
  highlights: Highlight[],
  shortcomings: Shortcoming[],
  features: FeaturesType[]
): HighlightItemType[] => {
  const sortedHighlightes: HighlightItemType[] = highlights
    .map((item) => ({
      key: item.id,
      label: item.title,
      size: 'small' as const,
      colorScheme: 'neutral' as const,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const sortedShortcomings: HighlightItemType[] = shortcomings
    .map((item) => ({
      key: item.id,
      label: item.title,
      size: 'small' as const,
      colorScheme: 'red' as const,
      isSubtle: true,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const sortedFeatures: HighlightItemType[] = features
    .map((item) => ({
      key: item.key,
      label: item.translation,
      size: 'small' as const,
      colorScheme: 'blue' as const,
      isSubtle: true,
      // icon: item.isPremium ? <Icon value="toggle/star" /> : undefined,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return [...sortedHighlightes, ...sortedShortcomings, ...sortedFeatures];
};
