import {useEffect, useRef} from 'react';
// eslint-disable-next-line no-restricted-imports
import {NavigateOptions, useNavigate as useReactRouterNavigate} from 'react-router-dom';

import {includes} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

const DG_ROW_CLICK_DELAY = 400;

/**
 * A hook that returns a function to navigate to a given path.
 *
 * If the control or command key is pressed when the function is called, the path will be opened in a new tab.
 *
 * When options parameter is passed to the returned function, the path will always be opened in the same tab.
 */
export const useNavigate = () => {
  const navigate = useReactRouterNavigate();

  const isControlOrCommandPressed = useRef<boolean>(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (includes(event.key, ['Control', 'Meta'])) {
        isControlOrCommandPressed.current = true;
      }
    };
    const handleKeyUp = (event: KeyboardEvent) =>
      // We need to have a timeout here to compensate for the row click delay in DataGrid
      setTimeout(() => {
        if (includes(event.key, ['Control', 'Meta'])) {
          isControlOrCommandPressed.current = false;
        }
      }, DG_ROW_CLICK_DELAY);
    const handleBlur = () => (isControlOrCommandPressed.current = false);

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  return (path: string, options?: NavigateOptions) => {
    isControlOrCommandPressed.current && isNilOrEmpty(options)
      ? window.open(path)
      : navigate(path, options);
  };
};
