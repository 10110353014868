import {
  Alert,
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormSubmitHandler,
} from 'platform/components';
import {Show, Text, VStack} from 'platform/foundation';
import {array, number, object, string} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {
  clamp,
  concat,
  filter,
  find,
  findIndex,
  includes,
  isEmpty,
  isNil,
  map,
  not,
  prop,
  propEq,
  reject,
  sum,
} from 'ramda';
import {isPositive} from 'ramda-adjunct';

import {
  GetGoodwillItemRatioApiResponse,
  GetServiceCaseOrdersApiResponse,
  useGetGoodwillItemRatioQuery,
  useGetServiceCaseOrdersQuery,
  usePostServiceOrderMutation,
  usePutServiceOrderItemsToGoodwillMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {GoodwillOrderItem} from './components/GoodwillOrderItem';
import {NewGoodwillOrderItems} from './components/NewGoodwillOrderItems';
import {GoodwillFormType} from './types/GoodwillFormType';

interface GoodwillFormProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  requestName: string | Nullish;
  isWithMultipleGoodwill: boolean;
  items: {serviceJobId: string; serviceItemId: string}[];
  onSaved: () => void;
  onClose: () => void;
}

const TOTAL_RATIO = 100;

export function GoodwillForm(props: GoodwillFormProps) {
  const [putServiceOrderItemsToGoodwill, {isLoading: isPutServiceOrderItemsToGoodwillLoading}] =
    usePutServiceOrderItemsToGoodwillMutation();
  const [postServiceOrder, {isLoading: isPostServiceOrderLoading}] = usePostServiceOrderMutation();
  const {
    data: serviceCaseOrders,
    isLoading: isServiceCaseOrdersLoading,
    isError,
  } = useGetServiceCaseOrdersQuery({
    serviceCaseId: props.serviceCaseId,
  });
  const {data: goodwillItemRatio, isLoading: isGoodwillItemRatioLoading} =
    useGetGoodwillItemRatioQuery(
      {
        serviceCaseId: props.serviceCaseId,
        serviceItemId: props.items[0].serviceItemId,
        serviceJobId: props.items[0].serviceJobId,
        serviceOrderId: props.serviceOrderId,
      },
      {skip: props.items.length !== 1}
    );

  const serviceCaseOrdersToShow = filter(
    (order) => not(includes(order?.state, ['LOCKED', 'ARCHIVED', 'CLOSED', 'CANCELLED'])),
    serviceCaseOrders ?? []
  );

  const handleSubmit: FormSubmitHandler<GoodwillFormType> = async (data, setErrors) => {
    const calculatedRatio =
      sum(data.orders.map(({ratio}) => ratio)) + sum(data.newOrders.map(({ratio}) => ratio));

    if (calculatedRatio !== TOTAL_RATIO) {
      const orderValiadtions = data.orders.map((order, index) => ({
        name: `orders.${index}.ratio`,
        message: isPositive(order.ratio)
          ? i18n.t('entity.orderRequest.labels.goodwill100RatioRequired')
          : '',
      }));

      const newOrderValiadtions = data.orders.map((order, index) => ({
        name: `newOrders.${index}.ratio`,
        message: isPositive(order.ratio)
          ? i18n.t('entity.orderRequest.labels.goodwill100RatioRequired')
          : '',
      }));

      return setErrors(reject(isEmpty, concat(orderValiadtions, newOrderValiadtions)));
    }

    const ordersToCreate = filter((order) => isPositive(order.ratio), data.newOrders);

    const createdOrders = await Promise.all(
      ordersToCreate.map((order) =>
        postServiceOrder({
          body: {orderVariantId: order.variant},
          serviceCaseId: props.serviceCaseId,
        }).unwrap()
      )
    );

    const goodwillOrders = [
      ...data.orders?.map((order) => ({
        goodwillOrderId: order.id,
        goodwillRatio: order.ratio / 100,
      })),
      ...createdOrders.map((order, index) => ({
        goodwillOrderId: order?.id ?? '',
        goodwillRatio: ordersToCreate[index].ratio / 100,
      })),
    ];

    await putServiceOrderItemsToGoodwill({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: {goodwillOrders, items: props.items},
    })
      .unwrap()
      .then(() => {
        props.onClose();
        props.onSaved();
      })
      .catch(handleApiError);
  };

  const onRatioChange = (formApi: UseFormReturn<GoodwillFormType>) => () => {
    const formData = formApi.getValues();

    const ordersWithouCurrent = reject(propEq(props.serviceOrderId, 'id'), formData.orders);
    const totalNew = sum(map(prop('ratio'), formData.newOrders));
    const totalOtherOrder = sum(map(prop('ratio'), ordersWithouCurrent));

    const currentOrderRatio = clamp(0, TOTAL_RATIO, TOTAL_RATIO - totalNew - totalOtherOrder);

    const currentOrderIndex = findIndex(propEq(props.serviceOrderId, 'id'), formData.orders);

    if (currentOrderIndex === -1) {
      return;
    }

    formApi.setValue(`orders.${currentOrderIndex}.ratio`, currentOrderRatio);
  };

  const isLoading =
    isServiceCaseOrdersLoading ||
    isPutServiceOrderItemsToGoodwillLoading ||
    isPostServiceOrderLoading ||
    isGoodwillItemRatioLoading;

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={23}>
      <Form<GoodwillFormType>
        defaultValues={{
          orders: getGoddwillOrders(
            serviceCaseOrdersToShow,
            props.serviceOrderId,
            goodwillItemRatio,
            props.items.length !== 1
          ),
          newOrders: [{ratio: 0, variant: undefined}],
        }}
        schema={schema}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <Text color="tertiary" size="small">
              {i18n.t('entity.orderRequest.labels.goodwillDescription')}
            </Text>
            <Show when={props.isWithMultipleGoodwill}>
              <Alert
                type="inline"
                variant="warning"
                title={i18n.t('entity.orderRequest.labels.goodwillMultipleExistsTitle')}
                message={i18n.t('entity.orderRequest.labels.goodwillMultipleExistsMessage')}
                data-testid={suffixTestId('multipleGoodwillAlert', props)}
              />
            </Show>
            {formApi
              .watch('orders')
              ?.map(
                (order, index) =>
                  order && (
                    <GoodwillOrderItem
                      key={order.id}
                      serviceCaseId={props.serviceCaseId}
                      id={order.id}
                      isCurrentOrder={order.id === props.serviceOrderId}
                      control={control}
                      formApi={formApi}
                      index={index}
                      requestName={props.requestName}
                      onRatioChange={onRatioChange(formApi)}
                      data-testid={suffixTestId(`order-[${index}]`, props)}
                    />
                  )
              )}
            <NewGoodwillOrderItems
              control={control}
              formApi={formApi}
              serviceCaseId={props.serviceCaseId}
              requestName={props.requestName}
              onRatioChange={onRatioChange(formApi)}
              data-testid={suffixTestId('newOrders', props)}
            />
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                variant="secondary"
                onClick={props.onClose}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                type="submit"
                control={control}
                title={i18n.t('general.actions.save')}
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const schema = object({
  orders: array().of(
    object({
      ratio: number().nullable().optional().max(100).min(0),
    })
  ),
  newOrders: array().of(
    object({
      ratio: number().nullable().optional().max(100).min(0),
      variant: string().when('ratio', {
        is: isPositive,
        then: string().required(),
        otherwise: string().optional(),
      }),
    })
  ),
});

const getGoddwillOrders = (
  orders: GetServiceCaseOrdersApiResponse,
  serviceOrderId: string,
  goodwillItemRatio: GetGoodwillItemRatioApiResponse | Nullish,
  isMultiple: boolean
) => {
  const getDefaultRatio = (orderId: string | Nullish) => (orderId === serviceOrderId ? 100 : 0);

  const getGoodwillRatio = (orderId: string | Nullish) => {
    if (isMultiple) {
      return;
    }

    const goodwillRatio = find(
      (item) => item?.serviceOrderId === orderId,
      goodwillItemRatio?.goodwillOrders ?? []
    )?.goodwillRatio;

    if (isNil(goodwillRatio)) {
      return;
    }

    return goodwillRatio * 100;
  };

  return orders?.map((order) => ({
    id: order?.id ?? undefined,
    ratio: getGoodwillRatio(order?.id) ?? getDefaultRatio(order?.id),
  }));
};
