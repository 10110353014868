import {Card} from 'platform/components';
import {Box, Grid, GridItem, HStack} from 'platform/foundation';

import {FC} from 'react';
import {FormSpy} from 'react-final-form';
import {useSelector} from 'react-redux';

import {lensPath, set} from 'ramda';

import {
  AddressRequestBodyV2,
  AddressResponseBodyV2,
  CompGetInfoV3ApiResponse,
  selectTenant,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {DecodeBusinessIdButton} from '@omnetic-dms/shared';
import {
  $BusinessInfoRequestBody,
  AutoSave,
  BusinessInfoData,
  BusinessInfoRequestBody,
  Form,
} from '@omnetic-dms/teas';

import {appendSuffix, noop, suffixTestId, TestIdProps} from 'shared';

import {AddressSelect} from '../Address/AddressSelect';

export interface BusinessInfoFormProps {
  businessInfo?: BusinessInfoRequestBody;
  onSubmit?: (values: BusinessInfoRequestBody) => void;
  handleAddressSubmit: (
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ) => Promise<string | null>;
  saveOnChange?: boolean;
  address?: AddressResponseBodyV2[];
  selectedBusinessAddress?: AddressResponseBodyV2 | null;
  addressGreyBox?: boolean;
  compactSelect?: boolean;
}

export const BusinessInfoForm: FC<BusinessInfoFormProps & TestIdProps> = ({
  addressGreyBox,
  businessInfo,
  onSubmit,
  saveOnChange,
  address,
  selectedBusinessAddress,
  compactSelect = false,
  handleAddressSubmit,
  ...rest
}) => {
  const {data: {country: countryCode} = {}} = useSelector(selectTenant);

  const handleDecodeBusinessIdForm =
    (values: BusinessInfoRequestBody) => (company: CompGetInfoV3ApiResponse) => {
      let formValues = values;

      const businessInfoData: BusinessInfoData = {
        countryOfRegistrationCode: company?.[0]?.country ?? null,
        registrationNumber: company?.[0]?.businessId ?? null,
        vatNumber: company?.[0]?.taxId ?? null,
        fileNumber: company?.[0]?.fileNumber ?? null,
        tradeName: company?.[0]?.businessName ?? null,
      };

      const addressData: AddressRequestBodyV2 = {
        address: {
          street: company?.[0]?.street ?? '',
          descriptiveNumber: company?.[0]?.descriptiveNumber ?? null,
          orientationNumber: company?.[0]?.orientationNumber ?? null,
          city: company?.[0]?.city ?? '',
          zip: company?.[0]?.zipCode ?? '',
          state: company?.[0]?.state,
          country: company?.[0]?.country ?? '',
          addressComplement: null,
          prefix: company?.[0]?.prefix ?? null,
          district: null,
        },
        type: i18n.t('entity.person.permanentAddressId'),
        invalid: null,
      };

      formValues = set(lensPath(['businessInfoData']), businessInfoData, formValues);

      const alreadyCreatedAddress = address?.find(
        (_address) =>
          _address.address.street === addressData.address.street &&
          _address.address.orientationNumber === addressData.address.orientationNumber &&
          _address.address.descriptiveNumber === addressData.address.descriptiveNumber
      );

      if (!alreadyCreatedAddress) {
        handleAddressSubmit(addressData)
          .then((addressId) => {
            formValues.businessAddressId = addressId;
          })
          .catch((e: any) => {
            if (e.addressId) {
              formValues.businessAddressId = e.addressId || null;
            }
          });
      } else {
        formValues.businessAddressId = alreadyCreatedAddress.id;
      }

      onSubmit?.(formValues);
    };

  return (
    <Form<BusinessInfoRequestBody>
      formId={appendSuffix('businessInfoForm', rest['data-testid'])}
      onSubmit={onSubmit}
      restartOnSubmit={false}
      schema={$BusinessInfoRequestBody}
      initialValues={businessInfo}
      defaultValues={{
        businessInfoData: {
          countryOfRegistrationCode: countryCode,
        },
      }}
      render={({Field}) => (
        <>
          {saveOnChange && <AutoSave save={onSubmit || noop} saveOnChange={saveOnChange} />}
          <HStack spacing={4}>
            <Box flex={1}>
              <Field
                name="businessInfoData.registrationNumber"
                label={i18n.t('entity.businessInfo.labels.registrationNumber')}
              />
            </Box>
            <Box flex={1}>
              <Field
                name="businessInfoData.vatNumber"
                label={i18n.t('entity.businessInfo.labels.vatNumber')}
              />
            </Box>
            <HStack height={13} align="flex-end">
              <FormSpy<BusinessInfoRequestBody>
                subscription={{values: true}}
                render={({values}) => (
                  <DecodeBusinessIdButton
                    data-testid={suffixTestId('businessInfoForm-decode', rest)}
                    businessId={values?.businessInfoData?.registrationNumber}
                    vatId={values?.businessInfoData?.vatNumber}
                    countryCode={values?.businessInfoData?.countryOfRegistrationCode ?? countryCode}
                    onSuccess={handleDecodeBusinessIdForm(values)}
                  />
                )}
              />
            </HStack>
          </HStack>
          <Grid columns={1}>
            <Field
              name="businessInfoData.tradeName"
              label={i18n.t('entity.businessInfo.labels.tradeName')}
            />
          </Grid>
          <Grid columns={2}>
            <Field
              name="businessInfoData.fileNumber"
              label={i18n.t('entity.businessInfo.labels.fileNumber')}
            />
            <Field
              as="country-select"
              name="businessInfoData.countryOfRegistrationCode"
              label={i18n.t('entity.businessInfo.labels.countryOfRegistrationCode')}
            />
          </Grid>
          <Grid columns={compactSelect ? 2 : 1}>
            <GridItem>
              {addressGreyBox ? (
                <Card variant="inlineGrey">
                  <AddressSelect<BusinessInfoRequestBody>
                    name="businessAddressId"
                    label={i18n.t('contractInformation.labels.businessAddressId')}
                    address={address}
                    handleAddressSubmit={handleAddressSubmit}
                    initialValue={selectedBusinessAddress}
                    data-testid={suffixTestId('businessInfoForm', rest)}
                  />
                </Card>
              ) : (
                <>
                  {!compactSelect ? (
                    <AddressSelect<BusinessInfoRequestBody>
                      name="businessAddressId"
                      label={i18n.t('contractInformation.labels.businessAddressId')}
                      address={address}
                      handleAddressSubmit={handleAddressSubmit}
                      initialValue={selectedBusinessAddress}
                      data-testid={suffixTestId('businessInfoForm', rest)}
                    />
                  ) : (
                    <div style={{marginRight: '-100px'}}>
                      <AddressSelect<BusinessInfoRequestBody>
                        name="businessAddressId"
                        label={i18n.t('contractInformation.labels.businessAddressId')}
                        address={address}
                        handleAddressSubmit={handleAddressSubmit}
                        initialValue={selectedBusinessAddress}
                        data-testid={suffixTestId('businessInfoForm', rest)}
                      />
                    </div>
                  )}
                </>
              )}
            </GridItem>
          </Grid>
        </>
      )}
    />
  );
};
