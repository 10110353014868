import {forwardRef, ForwardRefRenderFunction} from 'react';

import {or} from 'ramda';

import {suffixTestId} from 'shared';

import {Label, Input, Wrapper} from './styles';
import {CheckboxProps} from './types';

/**
 * @deprecated - use platform instead
 */
export const RenderCheckbox: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = (
  props,
  ref
) => {
  const {
    id,
    name,
    label,
    disabled,
    className = '',
    onClick,
    margin,
    isAllRowsSelected,
    ...rest
  } = props;

  return (
    <Wrapper
      data-testid={suffixTestId(`checkboxWrapper`, props)}
      className={`checkbox-wrapper ${className}`}
      margin={margin}
      disabled={disabled}
      onClick={onClick}
    >
      <Input
        ref={ref}
        id={id || name}
        name={name}
        type="checkbox"
        disabled={disabled}
        isAllRowsSelected={isAllRowsSelected}
        data-testid={suffixTestId(`checkboxInput`, props)}
        {...rest}
      />
      <Label
        checkbox
        htmlFor={id || name}
        data-testid={suffixTestId(`checkboxLabel`, props)}
        disabled={disabled}
        hasLabel={Boolean(label)}
      >
        {or(label, ' ')}
      </Label>
    </Wrapper>
  );
};

export const Checkbox = forwardRef(RenderCheckbox);
