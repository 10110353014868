import {format} from 'date-fns';
import {
  Actions,
  DataStatus,
  Flag,
  FlagProps,
  Parameter,
  Parameters,
  Preview,
  PreviewAction,
  Separator,
} from 'platform/components';
import {
  Box,
  HStack,
  Image,
  makeStorageUrl,
  Show,
  Heading,
  VStack,
  Hide,
  Space,
  Text,
} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';
import {useFormatNumber} from 'platform/locale';

import {Fragment} from 'react';

import {any, isNil, isNotNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';

import {
  buildArray,
  Nullish,
  parseDate,
  RequiredTestIdProps,
  suffixTestId,
  isLastIndexInArray,
  DOT_CHARACTER,
} from 'shared';

import {EMPTY_PLACEHOLDER} from '../../constants/placeholders';
import {thumbnailUrl} from '../../constants/thumbnailUrl';
import {useGetVehicleTitle} from '../../hooks/useGetVehicleTitle';
import {useVehicleCatalogue} from '../../hooks/useVehicleCatalogue';
import {useVehicleConditionEvaluation} from '../../hooks/useVehicleConditionEvaluation';
import {useVehicleCoverPhoto} from '../../hooks/useVehicleCoverPhoto';
import {VehicleConditionEvaluation} from '../EntityHeader/components/VehicleConditionEvaluation';
import {mapVehicleProps} from './mapVehicleProps';
import {VehicleWidgetData} from './types/VehicleWidgetData';

interface VehicleWidgetProps extends RequiredTestIdProps {
  vehicle: VehicleWidgetData;
  flags?: Pick<FlagProps, 'colorScheme' | 'label'>[];
}

export function VehicleWidget(props: VehicleWidgetProps) {
  const vehicle = mapVehicleProps(props.vehicle);
  const formatNumber = useFormatNumber();

  const {photos, coverPhoto} = useVehicleCoverPhoto(vehicle.id);

  const [vehicleConditionEvaluation] = useVehicleConditionEvaluation(vehicle.id);
  const [vehicleUtils, {isLoading: isVehicleCatalogueLoading, isError: isVehicleCatalogueErrored}] =
    useVehicleCatalogue(vehicle.vehicleType);

  const [lightboxControls, {onOpen}] = useLightbox(vehicle.id);
  const [vehicleTitle, {isLoading: isVehicleTitleLoading, isError: isVehicleTitleError}] =
    useGetVehicleTitle({
      make: vehicle.make,
      vehicleType: vehicle.vehicleType,
      modelFamily: vehicle.modelFamily,
      variant: vehicle.variant,
      trim: vehicle.trim,
    });

  const lightboxSlides = photos?.map((photo) => ({
    id: photo.id ?? photo.fileId,
    url: photo.url.replace('resize', 'get'),
    resizeUrl: photo.url,
  }));

  const onPreviewClick = () => {
    const index = lightboxSlides?.findIndex((slide) => slide.url === coverPhoto?.url);
    onOpen(index ?? 0);
  };

  return (
    <>
      <HStack
        spacing={3}
        align="flex-start"
        height="100%"
        width="100%"
        data-testid={suffixTestId('vehicleWidget', props)}
      >
        <Box maxHeight={26} borderRadius="small" overflow="hidden">
          <Show when={isNil(coverPhoto?.url)}>
            <Image
              data-testid={suffixTestId('vehicleWidget', props)}
              src={thumbnailUrl}
              fit="cover"
              height={21}
              width={30}
            />
          </Show>
          <Show when={isNotNil(coverPhoto?.url)}>
            <Preview
              data-testid={suffixTestId('vehicleWidget', props)}
              url={coverPhoto?.url}
              fit="cover"
              makeUrl={makeStorageUrl}
              width={30}
              height={21}
              actions={buildArray<PreviewAction>().when(isNotNilOrEmpty(photos), {
                icon: 'action/visibility',
                onClick: onPreviewClick,
              })}
            />
          </Show>
        </Box>
        <VStack spacing={2}>
          <Show when={props.flags?.length}>
            <HStack spacing={1} data-testid={suffixTestId('flags', props)}>
              {props.flags?.map((flag, index) => (
                <Flag
                  key={flag.label}
                  data-testid={suffixTestId(`flag[${index}]`, props)}
                  size="small"
                  colorScheme={flag.colorScheme}
                  label={flag.label}
                />
              ))}
            </HStack>
          </Show>
          <DataStatus isLoading={isVehicleTitleLoading} isError={isVehicleTitleError}>
            <Heading size={4} data-testid={suffixTestId('vehicleWidget', props)}>
              {vehicleTitle ?? EMPTY_PLACEHOLDER}
            </Heading>
          </DataStatus>
          <HStack spacing={1} align="center">
            <DataStatus isLoading={isVehicleCatalogueLoading} isError={isVehicleCatalogueErrored}>
              <Parameters
                parameters={buildArray<Parameter>()
                  .when(isNotNilOrEmpty(vehicle.vin), vehicle.vin)
                  .when(
                    isNilOrEmpty(vehicle.vin),
                    `${i18n.t('entity.vehicle.notifications.noVIN')}`
                  )
                  .when(isNotNilOrEmpty(vehicle.licencePlate), vehicle.licencePlate)
                  .when(
                    isNilOrEmpty(vehicle.licencePlate),
                    `${i18n.t('entity.vehicle.notifications.noLicencePlate')}`
                  )}
                size="small"
                color="secondary"
                data-testid={suffixTestId('vehicleWidget-[0]', props)}
              />
            </DataStatus>
            <Show when={isNotNil(vehicle.vin) || isNotNil(vehicle.licencePlate)}>
              <Text color="tertiary" size="small">
                {DOT_CHARACTER}
              </Text>
            </Show>
            <Parameters
              parameters={buildArray<Parameter>()
                .when(
                  vehicle.firstRegistration,
                  isNotNil(vehicle.firstRegistration)
                    ? format(parseDate(vehicle.firstRegistration), 'yyyy')
                    : null
                )
                .when(
                  isNotNil(vehicle.mileage),
                  `${formatNumber(vehicle.mileage, 0)} ${i18n.t('general.metric.km')}`
                )
                .when(vehicle.transmission, vehicleUtils.getTransmission(vehicle.transmission))
                .when(vehicle.fuelType, vehicleUtils.getFuelType(vehicle.fuelType))
                .when(vehicle.bodyStyle, vehicleUtils.getBodyStyle(vehicle.bodyStyle))
                .when(vehicle.driveType, vehicleUtils.getDrive(vehicle.driveType))}
              size="small"
              color="secondary"
              data-testid={suffixTestId('vehicleWidget-[1]', props)}
            />
            <Show
              when={any(isNotNilOrEmpty, [
                vehicleConditionEvaluation?.exteriorState,
                vehicleConditionEvaluation?.interiorState,
                vehicleConditionEvaluation?.mechanicalState,
              ])}
            >
              <VehicleConditionEvaluation
                size="small"
                color="secondary"
                vehicleCondition={vehicleConditionEvaluation}
                data-testid={suffixTestId(`vehicleWidget`, props)}
              />
            </Show>
          </HStack>
        </VStack>
        <Space fillAvailable />
        <VStack align="flex-end" justify="space-between" height="100%" spacing={6}>
          <Actions data-testid={suffixTestId('vehicleWidget', props)} actions={vehicle.actions} />
          <Show
            when={
              isNotNilOrEmpty(vehicle.firstParameter) || isNotNilOrEmpty(vehicle.secondParameter)
            }
          >
            <VStack justify="flex-end" height="100%">
              <HStack align="center" justify="flex-end" spacing={3}>
                <Text
                  size="small"
                  color="tertiary"
                  data-testid={suffixTestId('vehicleWidget-parameter', props)}
                >
                  {vehicle.firstParameter}
                </Text>
                <Heading
                  size={3}
                  color="primary"
                  data-testid={suffixTestId('vehicleWidget-parameter', props)}
                >
                  {vehicle.secondParameter}
                </Heading>
              </HStack>
            </VStack>
          </Show>
        </VStack>
      </HStack>
      <Lightbox
        data-testid={suffixTestId('vehicleWidget', props)}
        controls={lightboxControls}
        images={lightboxSlides}
      />
    </>
  );
}

interface VehicleWidgetCardProps extends RequiredTestIdProps {
  vehicles: VehicleWidgetData[] | Nullish;
}

export function VehicleWidgetCard(props: VehicleWidgetCardProps) {
  return (
    <>
      {props.vehicles?.map((vehicle, index) => (
        <Fragment key={vehicle.id}>
          <VehicleWidget
            data-testid={suffixTestId(`vehicleWidget-[${index}]`, props)}
            vehicle={vehicle}
          />
          <Hide when={isLastIndexInArray(props.vehicles, index)}>
            <Separator />
          </Hide>
        </Fragment>
      ))}
    </>
  );
}
