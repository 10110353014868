import {Card, DataStatus} from 'platform/components';
import {Grid, Hide, Show, Space} from 'platform/foundation';

import {isNil} from 'ramda';

import {
  NewOfferPurchaseBrokerageVehicleResponseBody,
  useGetPurchaseBusinessCasePricesOverviewQuery,
  useGetSaleBusinessCasePricesOverviewQuery,
  useGetSaleVehicleQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {PriceIndicator, usePricePermissions} from '@omnetic-dms/shared';

import {useBusinessCase} from '../../../hooks/useBusinessCase';

type FinancialOverviewProps = {
  businessCaseId: string;
};

export const FinancialOverview = ({businessCaseId}: FinancialOverviewProps) => {
  const {sellingVehicleId, state, offer, isSelling, isBrokerage, isBuying, purchaseVehicles} =
    useBusinessCase(businessCaseId);

  const {data: saleVehicleData} = useGetSaleVehicleQuery(
    {vehicleId: sellingVehicleId ?? ''},
    {skip: isNil(sellingVehicleId)}
  );

  const purchaseVehicle = isBrokerage
    ? (purchaseVehicles[0] as NewOfferPurchaseBrokerageVehicleResponseBody)
    : undefined;
  const {
    data: purchaseVehiclePrices,
    isLoading: isLoadingPurchaseVehiclePrices,
    isError: isPurchaseVehiclePricesError,
  } = useGetPurchaseBusinessCasePricesOverviewQuery(
    {businessCaseId},
    {skip: state === 'CONCEPT' || isSelling}
  );
  const {
    data: saleVehiclePrices,
    isLoading: isLoadingSaleVehiclePrices,
    isError: isSaleVehiclePricesError,
  } = useGetSaleBusinessCasePricesOverviewQuery(
    {businessCaseId},
    {skip: state === 'CONCEPT' || isBuying}
  );

  const {
    canReadVehicleProfit,
    canReadVehicleSellingPrice,
    canReadBusinessCaseSaleVehiclePurchasePrice,
    canReadBusinessCaseSaleVehicleSalePrice,
    canReadBusinessCaseSaleProfit,
    canReadBusinessCaseSaleTotalPrice,
    canReadVehiclePurchasePrice,
    canReadBusinessCasePurchaseVehiclePurchasePrice,
    canReadBusinessCasePurchaseExpectedProfit,
    canReadVehicleCosts,
    canReadVehicleEarnings,
    canReadBusinessCaseBrokerageFees,
  } = usePricePermissions({
    // TODO: maybe change to vehicleId instead of id
    //  https://carvago.atlassian.net/browse/T20-63748
    //  https://carvago.atlassian.net/browse/T20-63935
    //  https://carvago.atlassian.net/browse/T20-63943
    //  https://carvago.atlassian.net/browse/T20-63938
    //  https://carvago.atlassian.net/browse/T20-63988
    vehicleRecordId: sellingVehicleId || purchaseVehicle?.id,
    businessCaseRecordId: businessCaseId,
  });

  const canReadSellingPrice = canReadVehicleSellingPrice && canReadBusinessCaseSaleVehicleSalePrice;
  const canReadPurchasePrice =
    canReadVehiclePurchasePrice && canReadBusinessCasePurchaseVehiclePurchasePrice;

  const isVatDeductible = Boolean(
    saleVehicleData?.isVatDeductible ?? purchaseVehicle?.pricing?.vatDeductible
  );

  return (
    <Card title={i18n.t('entity.order.labels.finances')}>
      <Grid columns={4}>
        {/* case for selling + selling brokerage BC*/}
        <Show when={isSelling}>
          <Show whenFeatureDisabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <PriceIndicator
              size="large"
              data-testid={testIds.businessCase.overview('totalAmount')}
              label={i18n.t('entity.order.labels.totalAmount')}
              value={offer?.offerTotalPrice?.withVat}
              valueWithoutVat={offer?.offerTotalPrice?.withoutVat}
            />
            <PriceIndicator
              size="large"
              data-testid={testIds.businessCase.overview('totalUpsell')}
              label={i18n.t('entity.order.labels.totalUpsell')}
              value={offer?.totalUpsell?.withVat}
              valueWithoutVat={offer?.totalUpsell?.withoutVat}
              tag={Number(offer?.totalMarginPrice?.percentage)}
              tagType="percent"
            />
            <Hide when={isBrokerage}>
              <PriceIndicator
                size="large"
                data-testid={testIds.businessCase.overview('totalDiscount')}
                label={i18n.t('entity.order.labels.totalDiscount')}
                value={offer?.totalDiscountPrice?.withVat}
                valueWithoutVat={offer?.totalDiscountPrice?.withoutVat}
                tag={Number(offer?.totalDiscountPercentage)}
                tagType="percent"
                areInvertedNumbers
              />
            </Hide>
            <PriceIndicator
              size="large"
              data-testid={testIds.businessCase.overview('totalMargin')}
              label={i18n.t('entity.order.labels.totalMargin')}
              isHighligted
              value={offer?.totalMarginPrice?.withVat}
              valueWithoutVat={offer?.totalMarginPrice?.withoutVat}
              tag={Number(offer?.totalMarginPercentage)}
              tagType="percent"
            />
          </Show>
          <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <DataStatus
              isEmpty={isNil(saleVehiclePrices)}
              isLoading={isLoadingSaleVehiclePrices}
              isError={isSaleVehiclePricesError}
              minHeight={10}
            >
              <Show when={canReadSellingPrice}>
                <PriceIndicator
                  size="large"
                  label={i18n.t`entity.businessCase.labels.vehicleSellingPrice`}
                  data-testid={testIds.businessCase.overview('sellingPrice')}
                  value={saleVehiclePrices?.vehicleSellingPrice?.withVat}
                  valueWithoutVat={saleVehiclePrices?.vehicleSellingPrice?.withoutVat}
                  alwaysShowWithoutVatValue={isVatDeductible}
                />
              </Show>
              <Hide when={canReadSellingPrice}>
                <Space fillAvailable />
              </Hide>
              <PriceIndicator
                size="large"
                data-testid={testIds.businessCase.overview('summary-upsell')}
                value={saleVehiclePrices?.upsell?.withVat}
                valueWithoutVat={saleVehiclePrices?.upsell?.withoutVat}
                label={i18n.t('entity.businessCase.labels.upsell')}
              />
              <Hide when={isBrokerage || saleVehicleData?.type === 'BROKERAGE'}>
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.overview('summary-discount')}
                  value={saleVehiclePrices?.discount?.withVat}
                  valueWithoutVat={saleVehiclePrices?.discount?.withoutVat}
                  areInvertedNumbers
                  label={i18n.t('general.labels.discount')}
                  alwaysShowWithoutVatValue={isVatDeductible}
                />
              </Hide>
              <Show when={isBrokerage || saleVehicleData?.type === 'BROKERAGE'}>
                <Space fillAvailable />
              </Show>
              <Show when={canReadSellingPrice && canReadBusinessCaseSaleTotalPrice}>
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.overview('totalPrice')}
                  label={i18n.t`entity.businessCase.labels.totalPrice`}
                  value={saleVehiclePrices?.totalPrice?.withVat}
                  valueWithoutVat={saleVehiclePrices?.totalPrice?.withoutVat}
                />
              </Show>
              <Hide when={canReadSellingPrice && canReadBusinessCaseSaleTotalPrice}>
                <Space fillAvailable />
              </Hide>
              <Show when={canReadPurchasePrice && canReadVehicleCosts}>
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.overview('summary-realCosts')}
                  label={i18n.t('entity.businessCase.labels.realCosts')}
                  labelTooltip={i18n.t('entity.businessCase.labels.realCostsTooltip')}
                  value={saleVehiclePrices?.realCosts}
                />
              </Show>
              <Hide when={canReadPurchasePrice && canReadVehicleCosts}>
                <Space fillAvailable />
              </Hide>
              <Show when={canReadVehicleEarnings}>
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.overview('summary-totalExpectedEarnings')}
                  value={saleVehiclePrices?.expectedEarnings}
                  label={i18n.t('entity.businessCase.labels.totalExpectedEarnings')}
                  labelTooltip={i18n.t('entity.businessCase.labels.totalExpectedEarningsTooltip')}
                />
              </Show>
              <Hide when={canReadVehicleEarnings}>
                <Space fillAvailable />
              </Hide>
              <Show
                when={
                  canReadVehicleCosts &&
                  canReadVehicleEarnings &&
                  canReadBusinessCaseSaleProfit &&
                  canReadSellingPrice &&
                  canReadBusinessCaseSaleVehiclePurchasePrice &&
                  canReadVehiclePurchasePrice &&
                  canReadBusinessCaseBrokerageFees
                }
              >
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.overview('summary-totalGrossMargin')}
                  label={i18n.t('entity.businessCase.labels.grossMarginOnVehicle')}
                  value={saleVehiclePrices?.vehicleMargin?.amount}
                  tag={Number(saleVehiclePrices?.vehicleMargin?.percentage)}
                  tagType="percent"
                  isHighligted
                />
              </Show>
              <Hide
                when={
                  canReadVehicleCosts &&
                  canReadVehicleEarnings &&
                  canReadBusinessCaseSaleProfit &&
                  canReadSellingPrice &&
                  canReadBusinessCaseSaleVehiclePurchasePrice &&
                  canReadVehiclePurchasePrice &&
                  canReadBusinessCaseBrokerageFees
                }
              >
                <Space fillAvailable />
              </Hide>
              <Show
                when={
                  canReadVehicleCosts &&
                  canReadVehicleEarnings &&
                  canReadBusinessCaseSaleProfit &&
                  canReadSellingPrice &&
                  canReadBusinessCaseSaleVehiclePurchasePrice &&
                  canReadVehiclePurchasePrice &&
                  canReadBusinessCaseBrokerageFees
                }
              >
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.overview('summary-grossMarginTotal')}
                  label={i18n.t('entity.businessCase.labels.grossMarginTotal')}
                  isHighligted
                  value={saleVehiclePrices?.totalMargin?.amount}
                  tag={Number(saleVehiclePrices?.totalMargin?.percentage)}
                  tagType="percent"
                />
              </Show>
              <Hide
                when={
                  canReadVehicleCosts &&
                  canReadVehicleEarnings &&
                  canReadBusinessCaseSaleProfit &&
                  canReadSellingPrice &&
                  canReadBusinessCaseSaleVehiclePurchasePrice &&
                  canReadVehiclePurchasePrice &&
                  canReadBusinessCaseBrokerageFees
                }
              >
                <Space fillAvailable />
              </Hide>
            </DataStatus>
          </Show>
        </Show>
        {/* case only for buying BC*/}
        <Show when={isBuying}>
          <Show whenFeatureDisabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <PriceIndicator
              size="large"
              data-testid={testIds.businessCase.overview('totalAmount')}
              label={i18n.t('entity.order.labels.totalAmount')}
              value={offer?.offerTotalPrice?.withVat}
              valueWithoutVat={offer?.offerTotalPrice?.withoutVat}
            />
            <PriceIndicator
              size="large"
              data-testid={testIds.businessCase.overview('totalExpectedMargin')}
              label={i18n.t('page.businessCase.labels.totalExpectedMargin')}
              value={offer?.totalMarginPrice?.withVat}
              tag={Number(offer?.totalMarginPercentage)}
              tagType="percent"
              valueWithoutVat={offer?.totalMarginPrice?.withoutVat}
            />
          </Show>
          <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <DataStatus
              isEmpty={isNil(purchaseVehiclePrices)}
              isLoading={isLoadingPurchaseVehiclePrices}
              isError={isPurchaseVehiclePricesError}
              minHeight={10}
            >
              <Show when={canReadPurchasePrice}>
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.buying('summary-buyingPrice')}
                  label={i18n.t('entity.businessCase.labels.vehicleBuyingPrice')}
                  value={purchaseVehiclePrices?.vehiclePurchasePrice?.withVat}
                  valueWithoutVat={purchaseVehiclePrices?.vehiclePurchasePrice?.withoutVat}
                  alwaysShowWithoutVatValue={isVatDeductible}
                />
              </Show>
              <Hide when={canReadVehiclePurchasePrice}>
                <Space fillAvailable />
              </Hide>
              <Show when={canReadVehicleCosts}>
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.buying('summary-costs')}
                  value={purchaseVehiclePrices?.additionalCosts}
                  label={i18n.t('entity.businessCase.labels.additionalExpectedCosts')}
                  labelTooltip={i18n.t('entity.businessCase.labels.additionalExpectedCostsTooltip')}
                />
              </Show>
              <Hide when={canReadVehicleCosts}>
                <Space fillAvailable />
              </Hide>
              <Show
                when={
                  canReadVehicleCosts &&
                  canReadBusinessCaseSaleVehiclePurchasePrice &&
                  canReadVehiclePurchasePrice
                }
              >
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.buying('summary-totalExpectedCosts')}
                  value={purchaseVehiclePrices?.totalCosts}
                  label={i18n.t('entity.businessCase.labels.totalExpectedCosts')}
                />
              </Show>
              <Hide
                when={
                  canReadVehicleCosts && canReadVehicleSellingPrice && canReadVehiclePurchasePrice
                }
              >
                <Space fillAvailable />
              </Hide>
              <Show when={canReadVehicleProfit}>
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.buying('summary-vehicleGrossMargin')}
                  isHighligted
                  tag={Number(purchaseVehiclePrices?.vehicleMargin?.percentage)}
                  tagType="percent"
                  value={purchaseVehiclePrices?.vehicleMargin?.amount}
                  label={i18n.t('entity.businessCase.labels.vehicleGrossMargin')}
                  labelTooltip={i18n.t('entity.businessCase.labels.vehicleGrossMarginTooltip')}
                />
              </Show>
              <Hide when={canReadVehicleProfit}>
                <Space fillAvailable />
              </Hide>
              <Show when={canReadVehicleSellingPrice}>
                <PriceIndicator
                  size="large"
                  label={i18n.t('entity.businessCase.labels.vehicleSellingPrice')}
                  data-testid={testIds.businessCase.buying('summary-vehicleSellingPrice')}
                  value={purchaseVehiclePrices?.vehicleSellingPrice?.withVat}
                  valueWithoutVat={purchaseVehiclePrices?.vehicleSellingPrice?.withoutVat}
                  alwaysShowWithoutVatValue={isVatDeductible}
                />
              </Show>
              <Hide when={canReadVehicleSellingPrice}>
                <Space fillAvailable />
              </Hide>
              <Show when={canReadVehicleEarnings && canReadBusinessCasePurchaseExpectedProfit}>
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.buying('summary-expectedEarnings')}
                  label={i18n.t('entity.businessCase.labels.expectedEarnings')}
                  labelTooltip={i18n.t('entity.businessCase.labels.expectedEarningsTooltip')}
                  value={purchaseVehiclePrices?.additionalEarnings}
                />
              </Show>
              <Hide when={canReadVehicleEarnings && canReadBusinessCasePurchaseExpectedProfit}>
                <Space fillAvailable />
              </Hide>
              <Show
                when={
                  canReadVehicleEarnings &&
                  canReadVehicleSellingPrice &&
                  canReadVehiclePurchasePrice
                }
              >
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.buying('summary-realEarnings')}
                  label={i18n.t('entity.businessCase.labels.totalExpectedEarnings')}
                  value={purchaseVehiclePrices?.totalEarnings}
                />
              </Show>
              <Hide
                when={
                  canReadVehicleEarnings &&
                  canReadVehicleSellingPrice &&
                  canReadVehiclePurchasePrice
                }
              >
                <Space fillAvailable />
              </Hide>
              <Show
                when={
                  canReadVehicleEarnings &&
                  canReadVehicleSellingPrice &&
                  canReadVehiclePurchasePrice
                }
              >
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.buying('summary-totalGrossMargin')}
                  label={i18n.t('entity.businessCase.labels.totalGrossMargin')}
                  labelTooltip={i18n.t('entity.businessCase.labels.totalGrossMarginTooltip')}
                  value={purchaseVehiclePrices?.totalMargin?.amount}
                  isHighligted
                  tag={Number(purchaseVehiclePrices?.totalMargin?.percentage)}
                  tagType="percent"
                />
              </Show>
              <Hide
                when={
                  canReadVehicleEarnings &&
                  canReadVehicleSellingPrice &&
                  canReadVehiclePurchasePrice
                }
              >
                <Space fillAvailable />
              </Hide>
            </DataStatus>
          </Show>
        </Show>
        {/* case only for buying brokerage BC*/}
        <Show when={isBrokerage && !isSelling && !isBuying}>
          <Show whenFeatureDisabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <PriceIndicator
              size="large"
              data-testid={testIds.businessCase.overview('totalAmount')}
              label={i18n.t('entity.order.labels.totalAmount')}
              value={offer?.offerTotalPrice?.withVat}
              valueWithoutVat={offer?.offerTotalPrice?.withoutVat}
            />
            <PriceIndicator
              size="large"
              data-testid={testIds.businessCase.overview('totalExpectedMargin')}
              label={i18n.t('page.businessCase.labels.totalExpectedMargin')}
              value={purchaseVehicle?.brokerageFees?.profit?.withVat}
              valueWithoutVat={purchaseVehicle?.brokerageFees?.profit?.withoutVat}
              tag={Number(purchaseVehicle?.brokerageFees?.profit?.percentage)}
              tagType="percent"
            />
          </Show>
          <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
            <DataStatus
              isEmpty={isNil(purchaseVehiclePrices)}
              isLoading={isLoadingPurchaseVehiclePrices}
              isError={isPurchaseVehiclePricesError}
              minHeight={10}
            >
              <Show when={canReadSellingPrice}>
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.buying('summary-vehicleSellingPrice')}
                  value={purchaseVehiclePrices?.vehicleSellingPrice?.withVat}
                  valueWithoutVat={purchaseVehiclePrices?.vehicleSellingPrice?.withoutVat}
                  label={i18n.t('entity.businessCase.labels.vehicleSellingPrice')}
                />
              </Show>
              <Hide when={canReadSellingPrice}>
                <Space fillAvailable />
              </Hide>
              <Show when={canReadVehicleCosts}>
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.buying('summary-totalExpectedCosts')}
                  value={purchaseVehiclePrices?.totalCosts}
                  label={i18n.t('entity.businessCase.labels.totalExpectedCosts')}
                />
              </Show>
              <Hide when={canReadVehicleCosts}>
                <Space fillAvailable />
              </Hide>
              <Show
                when={
                  canReadPurchasePrice &&
                  canReadSellingPrice &&
                  canReadVehicleEarnings &&
                  canReadBusinessCaseBrokerageFees
                }
              >
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.buying('summary-vehicleGrossMargin')}
                  tag={Number(purchaseVehiclePrices?.vehicleMargin?.percentage)}
                  tagType="percent"
                  value={purchaseVehiclePrices?.vehicleMargin?.amount}
                  label={i18n.t('entity.businessCase.labels.vehicleGrossMargin')}
                  labelTooltip={i18n.t('entity.businessCase.labels.vehicleGrossMarginTooltip')}
                />
                <PriceIndicator
                  size="large"
                  data-testid={testIds.businessCase.buying('summary-totalGrossMargin')}
                  tag={Number(purchaseVehiclePrices?.totalMargin?.percentage)}
                  tagType="percent"
                  value={purchaseVehiclePrices?.totalMargin?.amount}
                  label={i18n.t('entity.businessCase.labels.totalGrossMargin')}
                  labelTooltip={i18n.t('entity.businessCase.labels.totalGrossMarginTooltip')}
                />
              </Show>
              <Hide
                when={
                  canReadPurchasePrice &&
                  canReadSellingPrice &&
                  canReadVehicleEarnings &&
                  canReadBusinessCaseBrokerageFees
                }
              >
                <Space fillAvailable />
                <Space fillAvailable />
              </Hide>
            </DataStatus>
          </Show>
        </Show>
      </Grid>
    </Card>
  );
};
