import {
  DeleteDirectSaleMaterialBasketItemsRequest,
  DeleteDirectSaleMaterialBasketItemsRequestSchema,
  DeleteDirectSaleMaterialBasketItemsResponse,
  DeleteDirectSaleMaterialBasketItemsResponseSchema,
  GetDirectSaleMaterialBasketItemsRequest,
  GetDirectSaleMaterialBasketItemsRequestSchema,
  GetDirectSaleMaterialBasketItemsResponse,
  GetDirectSaleMaterialBasketItemsResponseSchema,
  PatchDirectSaleMaterialBasketItemQuantityRequest,
  PatchDirectSaleMaterialBasketItemQuantityRequestSchema,
  PatchDirectSaleMaterialBasketItemQuantityResponse,
  PatchDirectSaleMaterialBasketItemQuantityResponseSchema,
  PostDirectSaleMaterialItemsMechanicRequest,
  PostDirectSaleMaterialItemsMechanicRequestSchema,
  PostDirectSaleMaterialItemsMechanicResponse,
  PostDirectSaleMaterialItemsMechanicResponseSchema,
  PostDirectSaleMaterialItemToBasketRequest,
  PostDirectSaleMaterialItemToBasketRequestSchema,
  PostDirectSaleMaterialItemToBasketResponse,
  PostDirectSaleMaterialItemToBasketResponseSchema,
} from '../types/metadaWarehouseDirectSaleSparePartsCart';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseDirectSaleSparePartsBasketApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDirectSaleMaterialBasketItems: build.query<
      GetDirectSaleMaterialBasketItemsResponse,
      GetDirectSaleMaterialBasketItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart`,
        method: 'GET',
        queryArg,
      }),
      providesTags: ['directSaleMaterialBasket'],
      extraOptions: {
        requestSchema: GetDirectSaleMaterialBasketItemsRequestSchema,
        responseSchema: GetDirectSaleMaterialBasketItemsResponseSchema,
      },
    }),
    deleteDirectSaleMaterialBasketItems: build.mutation<
      DeleteDirectSaleMaterialBasketItemsResponse,
      DeleteDirectSaleMaterialBasketItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'directSaleMaterialBasket',
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: DeleteDirectSaleMaterialBasketItemsRequestSchema,
        responseSchema: DeleteDirectSaleMaterialBasketItemsResponseSchema,
      },
    }),
    postDirectSaleMaterialBasketCheckout: build.mutation<
      PostDirectSaleMaterialItemsMechanicResponse,
      PostDirectSaleMaterialItemsMechanicRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart/checkout`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'directSaleMaterialBasket',
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: PostDirectSaleMaterialItemsMechanicRequestSchema,
        responseSchema: PostDirectSaleMaterialItemsMechanicResponseSchema,
      },
    }),
    postDirectSaleMaterialDispatch: build.mutation<
      PostDirectSaleMaterialItemToBasketResponse,
      PostDirectSaleMaterialItemToBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart/item/dispatch`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleMaterialBasket'],
      extraOptions: {
        requestSchema: PostDirectSaleMaterialItemToBasketRequestSchema,
        responseSchema: PostDirectSaleMaterialItemToBasketResponseSchema,
      },
    }),
    postDirectSaleMaterialRequest: build.mutation<
      PostDirectSaleMaterialItemToBasketResponse,
      PostDirectSaleMaterialItemToBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart/item/request`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleMaterialBasket'],
      extraOptions: {
        requestSchema: PostDirectSaleMaterialItemToBasketRequestSchema,
        responseSchema: PostDirectSaleMaterialItemToBasketResponseSchema,
      },
    }),
    postDirectSaleMaterialNonBinding: build.mutation<
      PostDirectSaleMaterialItemToBasketResponse,
      PostDirectSaleMaterialItemToBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart/item/nonbinding`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleMaterialBasket'],
      extraOptions: {
        requestSchema: PostDirectSaleMaterialItemToBasketRequestSchema,
        responseSchema: PostDirectSaleMaterialItemToBasketResponseSchema,
      },
    }),
    patchDirectSaleMaterialBasketItemQuantity: build.mutation<
      PatchDirectSaleMaterialBasketItemQuantityResponse,
      PatchDirectSaleMaterialBasketItemQuantityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/spare-parts-cart/item/${queryArg.itemId}/quantity`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSaleMaterialBasket'],
      extraOptions: {
        requestSchema: PatchDirectSaleMaterialBasketItemQuantityRequestSchema,
        responseSchema: PatchDirectSaleMaterialBasketItemQuantityResponseSchema,
      },
    }),
  }),
});

export const {
  useGetDirectSaleMaterialBasketItemsQuery,
  useDeleteDirectSaleMaterialBasketItemsMutation,
  usePostDirectSaleMaterialBasketCheckoutMutation,
  usePostDirectSaleMaterialDispatchMutation,
  usePostDirectSaleMaterialRequestMutation,
  usePostDirectSaleMaterialNonBindingMutation,
  usePatchDirectSaleMaterialBasketItemQuantityMutation,
} = metadaWarehouseDirectSaleSparePartsBasketApi;
