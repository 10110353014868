import {DeepPartial} from 'utility-types';
import {AnyObjectSchema} from 'yup';

import {AnyObject} from '../../../types/AnyObject';
import {PossibleObject} from '../../../types/PossibleObject';
import {getDefaultValuesFromSchema} from './getDefaultValuesFromSchema';
import {mergeFormValues} from './mergeFormValues';

export const composeInitialValues = <
  FormValues extends DeepPartial<AnyObject>,
  InitialFormValues extends PossibleObject = undefined,
>(
  schema?: AnyObjectSchema,
  defaultValues?: DeepPartial<FormValues>,
  initialValues?: InitialFormValues,
  getFormValues?: (initialValue: InitialFormValues) => FormValues
): FormValues => {
  const composedDefaultValues = schema
    ? getDefaultValuesFromSchema(schema, defaultValues as AnyObject)
    : defaultValues || {};

  if (initialValues) {
    if (getFormValues) {
      return mergeFormValues(
        composedDefaultValues as AnyObject,
        getFormValues(initialValues)
      ) as FormValues;
    } else {
      return mergeFormValues(composedDefaultValues as AnyObject, initialValues) as FormValues;
    }
  } else {
    return composedDefaultValues as FormValues;
  }
};
