import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

const AbroadSaleSchema = z.object({
  isAbroadSale: z.boolean().nullable().optional(),
  abroadSale: z.string().nullable().optional(),
});

export const GetServiceOrderAbroadSalesApiResponseSchema = AbroadSaleSchema.optional();

export type GetServiceOrderAbroadSalesApiResponse = z.infer<
  typeof GetServiceOrderAbroadSalesApiResponseSchema
>;

export const GetServiceOrderAbroadSalesApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
});

export type GetServiceOrderAbroadSalesApiArg = z.infer<
  typeof GetServiceOrderAbroadSalesApiArgSchema
>;

export const PutServiceOrderAbroadSalesApiResponseSchema = BaseVoidResponseSchema;

export type PutServiceOrderAbroadSalesApiResponse = z.infer<
  typeof PutServiceOrderAbroadSalesApiResponseSchema
>;

const PutServiceOrderAbroadSalesBodySchema = z
  .object({
    isAbroadSale: z.boolean(),
    abroadSale: z.enum(['SALE_TO_EU', 'SALE_OUTSIDE_EU']).nullable().optional(),
  })
  .nullable();

export const PutServiceOrderAbroadSalesApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: PutServiceOrderAbroadSalesBodySchema,
});

export type PutServiceOrderAbroadSalesApiArg = z.infer<
  typeof PutServiceOrderAbroadSalesApiArgSchema
>;
