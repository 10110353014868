import {DataGridSearchParams, SOURCE_DATA_GRID} from '../../hooks/useUrlSync';

export const getFormattedSourceSearchParams = (): DataGridSearchParams => {
  const searchParams = new URL(window.location.href).searchParams;
  const sourceDataGridSearchParams = searchParams.get(SOURCE_DATA_GRID)?.split(',');

  return {
    gridCode: sourceDataGridSearchParams?.[0] as string,
    queryId: sourceDataGridSearchParams?.[1] as string,
    rowIndex: sourceDataGridSearchParams?.[2] as string,
  };
};
