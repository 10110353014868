import {Card} from 'platform/components';
import {Icon, Hide, VStack} from 'platform/foundation';

import {useFieldArray, useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {selectTenant} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {CurrencyCodeType} from 'shared';

import {RestrictionSectionItem} from './RestrictionSectionItem';
import {LinkButtonNoPadding} from './styles';
import {DefinitionFormType} from './types';
import {emptyRestrictionItem} from './utils';

export function RestrictionSection() {
  const {data: {currency} = {}} = useSelector(selectTenant);

  const {append, remove, fields} = useFieldArray({
    name: 'restrictions',
    // eslint-disable-next-line no-restricted-syntax
    keyName: 'arrayId' as unknown as never,
  });
  const {watch} = useFormContext<DefinitionFormType>();
  const {system} = watch();

  const handleCreateNewItem = () => {
    append(emptyRestrictionItem(currency as CurrencyCodeType));
  };

  const handleRemoveItem = (index: number) => () => {
    remove(index);
  };

  return (
    <VStack spacing={4}>
      {fields.map((item, index) => (
        <Card variant="inlineGrey" key={`RestrictionSection_index_${index}`}>
          <RestrictionSectionItem
            disableRemove={system || fields.length === 1}
            partlyChangeable={index === 0 ? system : false}
            defaultValue={item}
            index={index}
            onRemove={handleRemoveItem(index)}
          />
        </Card>
      ))}
      <Hide when={system}>
        <LinkButtonNoPadding
          onClick={handleCreateNewItem}
          Icon={<Icon value="content/add_circle" />}
          data-testid={testIds.settings.cashLimits('addValidity')}
        >
          {i18n.t('entity.accounting.actions.addValidity')}
        </LinkButtonNoPadding>
      </Hide>
    </VStack>
  );
}
