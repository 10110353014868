import {BaseFlagProps} from 'platform/components';

import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

type DocumentWithCanceledAtAndSendAt = {
  canceledAt?: string | Nullish;
  sentAt?: string | Nullish;
  cancelledDocumentId?: string | Nullish;
  [key: string]: unknown;
};

export function getInvoiceStateFlagProps<T extends DocumentWithCanceledAtAndSendAt>(
  invoice: T | Nullish
) {
  let flagProps: BaseFlagProps = {
    label: i18n.t('entity.invoice.state.created'),
    colorScheme: 'blue',
  };

  if (invoice?.cancelledAt) {
    flagProps = {label: i18n.t('entity.invoice.state.cancelled'), colorScheme: 'red'};
  } else if (invoice?.cancelledDocumentId) {
    flagProps = {
      label: i18n.t('entity.cashRegister.state.cancelling'),
      colorScheme: 'orange',
    };
  } else if (invoice?.sentAt) {
    flagProps = {
      label: i18n.t('entity.invoice.state.sent'),
      colorScheme: 'green',
    };
  }

  return flagProps;
}
