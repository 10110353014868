import {Checkbox} from 'platform/components';

import {suffixTestId} from 'shared';

import {RowSelectCheckboxRendererProps} from '../types/RowSelectCheckboxRenderer';

export function DefaultRowSelectCheckboxRenderer(props: RowSelectCheckboxRendererProps) {
  return (
    <Checkbox
      value={!!props.selected}
      isIndeterminate={props.selected === undefined}
      onChange={props.changeHandler}
      data-testid={suffixTestId('checkbox', props)}
    />
  );
}
