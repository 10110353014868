import {Button, ButtonGroup, closeCurrentDialog, IconButton} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

export function WatermarkRepublishInfoDialogContent() {
  return (
    <>
      <VStack align="flex-end">
        <IconButton
          data-testid="watermarkRepublishInfoDialog"
          icon="navigation/close"
          onClick={closeCurrentDialog}
        />
      </VStack>
      <VStack spacing={1}>
        <Text>{i18n.t('entity.watermark.republishDialogInfo.description')}</Text>
      </VStack>
      <ButtonGroup align="right">
        <Button
          data-testid="watermark-republichAds-dialog-confirm"
          variant="primary"
          onClick={closeCurrentDialog}
          title={i18n.t('general.actions.confirm')}
        />
      </ButtonGroup>
    </>
  );
}
