import i18n from 'i18next';
import {IconButton, Tooltip, useDialog} from 'platform/components';

import {suffixTestId, TestIdProps} from 'shared';

import {SharePresetDialog} from './SharePresetDialog';

export function SharePresetButton(props: TestIdProps) {
  const [isOpen, onOpen, onClose] = useDialog(false);

  const onClick = () => {
    onOpen();
  };

  return (
    <>
      <Tooltip label={i18n.t('page.datagrid.labels.sharePresetDialogTitle')}>
        <IconButton
          icon="social/share"
          onClick={onClick}
          aria-label={i18n.t('page.datagrid.labels.sharePresetDialogTitle')}
          data-testid={suffixTestId('sharePreset', props)}
        />
      </Tooltip>
      <SharePresetDialog
        isOpen={isOpen}
        onClose={onClose}
        data-testid={suffixTestId('sharePreset', props)}
      />
    </>
  );
}
