import {useSelector} from 'react-redux';

import {filter, pipe, values} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {AuditDataVideoAssetsFilesBody} from '@omnetic-dms/api';

import {AuditVideoAssets} from '../../../store/carAudit/reducerUtils';
import {selectAudit, selectVideoAuditAssets} from '../../../store/carAudit/selectors';

export function useAuditVideos(categoryId: string, paramDefinitionId: string) {
  const audit = useSelector(selectAudit);
  const videoAssets = useSelector(selectVideoAuditAssets(categoryId, paramDefinitionId));

  const allVideoAssets = pipe(
    (category: AuditVideoAssets) => values(category).flatMap((item) => values(item)),
    filter((item: AuditDataVideoAssetsFilesBody) => isNotNil(item.videoId))
  )(audit?.videoAssets ?? {});

  return {videoAssets, allVideoAssets};
}
