import {match, Pattern} from 'ts-pattern';

import {FileOperationResponseBody, FileOperationStateHttpBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

export const isImageProcessing = (
  isLoading: boolean,
  fileOperation?: FileOperationResponseBody | null
) =>
  match([fileOperation?.type, fileOperation?.state])
    .returnType<
      | {
          state: FileOperationStateHttpBody;
          message: string;
        }
      | undefined
    >()
    .when(
      () => isLoading,
      () => ({
        state: 'IN_PROGRESS',
        message: i18n.t('entity.photo.labels.image.processing'),
      })
    )
    .with([Pattern.any, 'IN_PROGRESS'], () => ({
      state: 'IN_PROGRESS',
      message: i18n.t('entity.photo.labels.image.processing'),
    }))
    .with(['ROTATE', 'FAILED'], () => ({
      state: 'FAILED',
      message: i18n.t('entity.photo.labels.rotation.error'),
    }))
    .with(['COPY', 'FAILED'], () => ({
      state: 'FAILED',
      message: i18n.t('entity.photo.labels.copy.error'),
    }))
    .otherwise(() => undefined);
