import {
  Action,
  Card,
  DataStatus,
  EmptyStatus,
  openDialog,
  showNotification,
} from 'platform/components';
import {Hide, Show, VStack} from 'platform/foundation';

import {defaultTo, isNil, omit} from 'ramda';
import {isFalse, isNotNil} from 'ramda-adjunct';

import {
  InspectionType,
  PostServiceHandOverApiArg,
  useCreateInspectionMutation,
  useGetMetadaServiceCaseQuery,
  useGetServiceCheckInQuery,
  useGetServiceHandOverQuery,
  useGetVehicleQuery,
  usePartialUpdateVehicleMutation,
  usePatchServiceHandOverMutation,
  usePostServiceHandOverMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {buildArray, getApiDateString, parseDate, useBoolean} from 'shared';

import {CheckInHandOverForm} from '../../../../../components/CheckInHandOverForm/CheckInHandOverForm';
import {CheckInHandOverParameter} from '../../../../../components/CheckInHandOverParameter/CheckInHandOverParameter';
import {EmailSmsNotificationBoundary} from '../../../../../components/EmailSmsNotifications/EmailSmsNotificationBoundary';
import {NotificationCategory} from '../../../../../components/EmailSmsNotifications/types/EmailSmsNotificationForm';
import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';
import {MileageValidationDialog} from '../../../components/MileageValidationDialog';
import {Inspection} from './Inspection';

interface HandOverProps {
  vehicleId: string;
}

export function HandOver(props: HandOverProps) {
  const {serviceCaseId: id} = useWorkshopUrl();
  const {
    data: handOver,
    isLoading: isHandOverLoading,
    isError: isHandOverError,
  } = useGetServiceHandOverQuery({serviceCaseId: id});
  const {
    data: checkIn,
    isLoading: isCheckInLoading,
    isError: isCheckInError,
  } = useGetServiceCheckInQuery({serviceCaseId: id});
  const {data: serviceCase} = useGetMetadaServiceCaseQuery({serviceCaseId: id});
  const {data: vehicle} = useGetVehicleQuery(
    {vehicleId: defaultTo('', serviceCase?.vehicleId)},
    {skip: !serviceCase?.vehicleId}
  );
  const [postServiceHandOver] = usePostServiceHandOverMutation();
  const [patchServiceHandOver, patchServiceHandOverQuery] = usePatchServiceHandOverMutation();
  const [partialUpdateVehicle] = usePartialUpdateVehicleMutation();
  const [createInspection, {isLoading: isCreatingInspection, isError: isInspectionError}] =
    useCreateInspectionMutation();

  const [isEditing, setEditing, cancelEditing] = useBoolean();
  const inspectionId = handOver?.inspectionId?.[0];

  const handleSaveHandOver = async (data: PostServiceHandOverApiArg['body']) => {
    if (isNotNil(data) && inspectionId) {
      data.inspectionId = [inspectionId];
    }

    if (data?.technicalInspection && vehicle) {
      partialUpdateVehicle({
        vehicleId: vehicle.id,
        patchVehicleRequestBody: {
          state: {
            technicalInspectionValidUntil: getApiDateString(parseDate(data?.technicalInspection)),
          },
        },
      })
        .unwrap()
        .catch(handleApiError);
    }
    const request = handOver
      ? patchServiceHandOver({body: data, serviceCaseId: id})
      : postServiceHandOver({body: data, serviceCaseId: id});

    await request
      .unwrap()
      .then((response) => {
        if (isFalse(response?.isMileageValid)) {
          openDialog(
            <MileageValidationDialog
              highestMileage={response?.highestMileage}
              data-testid={testIds.workshop.serviceCaseDetail('mileageValidation')}
            />,
            {
              size: 'small',
            }
          );
        }

        showNotification.success();
        cancelEditing();
      })
      .catch(handleApiError);
  };

  const isLoading = isHandOverLoading || isCheckInLoading;
  const isError = isHandOverError || isCheckInError;
  const isFormView = !handOver || isEditing;

  const cardActions = buildArray<Action>().whenNot(isFormView, {
    type: 'button',
    variant: 'link',
    leftIcon: 'image/edit',
    title: i18n.t('general.actions.edit'),
    onClick: setEditing,
    'data-testid': testIds.workshop.serviceCaseDetail('handOverEdit'),
  });

  return (
    <VStack spacing={4}>
      <Card title={i18n.t('general.labels.basicInformation')} actions={cardActions}>
        <DataStatus
          isLoading={isLoading}
          isError={isError}
          data-testid={testIds.workshop.serviceCaseDetail('handOverstatus')}
        >
          <Show when={isFormView}>
            <CheckInHandOverForm
              data-testid={testIds.workshop.serviceCaseDetail('handOvereditForm')}
              defaultValues={
                handOver ??
                omit(
                  ['scheduledDate', 'completedDate', 'consentToTestDrive', 'inspectionId'],
                  checkIn ?? {}
                )
              }
              onSubmit={handleSaveHandOver}
              isEditing={isEditing}
              onEditDiscard={cancelEditing}
            />
          </Show>
          <Hide when={isFormView}>
            <CheckInHandOverParameter
              data={handOver}
              data-testid={testIds.workshop.serviceCaseDetail('handOverlist')}
            />
          </Hide>
        </DataStatus>
      </Card>
      <Show when={isNil(inspectionId)}>
        <Card
          title={i18n.t('page.Inspection.labels.title')}
          actions={[
            {
              type: 'button',
              variant: 'link',
              leftIcon: 'content/add_circle',
              title: i18n.t('general.labels.createNew'),
              isDisabled: isNil(handOver),
              isLoading: isCreatingInspection || patchServiceHandOverQuery.isLoading,
              onClick: () => {
                createInspection({
                  vehicleId: props.vehicleId,
                  createAuditRequestBody: {
                    inspectionType: InspectionType.HANDOVER,
                  },
                })
                  .unwrap()
                  .then(({id}) =>
                    patchServiceHandOver({
                      body: {
                        ...handOver,
                        inspectionId: [id],
                      },
                      serviceCaseId: serviceCase?.id || '',
                    }).unwrap()
                  )
                  .then(() => showNotification.success())
                  .catch(handleApiError);
              },
            },
          ]}
        >
          <EmptyStatus headline={i18n.t('entity.order.actions.handOverEmpty')} />
        </Card>
      </Show>
      <Show when={!!handOver && !!inspectionId}>
        <Inspection
          inspectionId={inspectionId || ''}
          vehicleId={props.vehicleId}
          isCompletionDisabled={isNil(handOver?.inspectionId?.[0])}
          isError={isInspectionError}
          data-testid={testIds.workshop.serviceCaseDetail('handoverInspection')}
          onInspectionDeleted={() => {
            if (!handOver) {
              return;
            }

            const {inspectionId: _, ...rest} = handOver;

            patchServiceHandOver({serviceCaseId: id, body: rest})
              .unwrap()
              .then(() => showNotification.success())
              .catch(handleApiError);
          }}
          onSendEmailSmsNotification={() => {
            openDialog(
              <EmailSmsNotificationBoundary
                serviceCaseId={id}
                dialogId="email-sms-notification-dialog-handover"
                data-testid={testIds.workshop.serviceCaseDetail('emailSmsNotificationHandover')}
                defaultCategory={NotificationCategory.HANDOVER}
              />,
              {
                id: 'email-sms-notification-dialog-handover',
                size: 'large',
                title: i18n.t('entity.order.actions.sendEmailSms'),
              }
            );
          }}
        />
      </Show>
    </VStack>
  );
}
