import {useSearchParams} from 'react-router-dom';

import {queryParams} from '../../utils/queryParams';

export function useSectionNavigation(defaultSectionId = '') {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeSectionId =
    searchParams.get(queryParams.COMPONENT_SECTIONS_SECTION) ?? defaultSectionId;

  const onSectionChange = (sectionId: string) => {
    searchParams.set(queryParams.COMPONENT_SECTIONS_SECTION, sectionId);
    setSearchParams(searchParams, {replace: true});
  };

  return {onSectionChange, activeSectionId};
}
