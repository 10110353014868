import {Button, ColorSchemeType, DataStatus, Flag, Tooltip} from 'platform/components';
import {Box, Heading, HStack, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {UseFormReturn} from 'react-hook-form';

import {always, defaultTo, isNil, isNotNil, not} from 'ramda';
import {isNegative} from 'ramda-adjunct';

import {
  useGetCalculatedItemPriceForCalculationPriceQuery,
  useGetCalculatedItemPriceForCooperationQuery,
  useGetCalculatedItemPriceForDirectPriceQuery,
  useGetCalculatedItemPriceForSubcontractQuery,
  useGetCalculatedItemPriceForTimeNormQuery,
  useGetOrderItemCalculatePriceQuery,
  useLazyGetOrderItemCalculatePriceQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {
  EMPTY_PLACEHOLDER,
  isNotNilOrZero,
  Nullish,
  precisionCalculation,
  suffixTestId,
  TestIdProps,
  useDebouncedValue,
} from 'shared';

import {getDecimalFromPercentage} from '../../utils/getDecimalFromPercentage';
import {handleApiError} from '../../utils/handleApiError';
import {EditMaterialItemFormType} from '../EditMaterialItem/types/EditMaterialItemFormType';

interface ItemPriceProps extends TestIdProps {
  itemId: string;
  serviceCaseId: string;
  serviceOrderId: string;
  serviceJobId: string;
  amount: number | Nullish;
  purchasePricePerUnit: number | Nullish;
  purchaseCurrency: string | Nullish;
  sellingPricePerUnit: number | Nullish;
  sellingCurrency: string | Nullish;
  discountRate: number | Nullish;
  vatType: string | Nullish;
  dispensingUnit?: number;
  workType?: string | Nullish;
  priceType?: string;
  assignMechanics?:
    | {id: string | null; amount: number; ratio: number; costCenterId?: string | null}[]
    | Nullish;
  formApi?: UseFormReturn<EditMaterialItemFormType>;
  isEditMaterialItem?: boolean;
}

export function ItemPrice(props: ItemPriceProps) {
  const formatCurrency = useFormatCurrency();
  const [calculatePrice, calculatePriceQuery] = useLazyGetOrderItemCalculatePriceQuery();

  const debouncedPurchasePricePerUnit = useDebouncedValue(
    props.priceType === 'SVCPRICETYPE_TIME_NORM' ? null : props.purchasePricePerUnit,
    300
  );
  const debouncedSellingPricePerUnit = useDebouncedValue(props.sellingPricePerUnit, 300);
  const debouncedVatType = useDebouncedValue(props.vatType, 300);
  const debouncedAmount = useDebouncedValue(props.amount, 300);
  const debouncedDiscount = useDebouncedValue(props.discountRate, 300);
  const debouncedWorkType = useDebouncedValue(props.workType, 300);
  const debouncedMechanics = useDebouncedValue(props.assignMechanics, 300);

  const isAmountDivisibleByDispensingUnit =
    precisionCalculation.modulo(
      defaultTo(0, debouncedAmount),
      defaultTo(1, props.dispensingUnit)
    ) === 0;

  const isAmountValid = isNil(props.dispensingUnit)
    ? isNotNilOrZero(debouncedAmount)
    : isNotNilOrZero(debouncedAmount) && isAmountDivisibleByDispensingUnit;

  const requestArgs = {
    serviceCaseId: props.serviceCaseId,
    serviceItemId: props.itemId,
    serviceJobId: props.serviceJobId,
    serviceOrderId: props.serviceOrderId,
    body: {
      quantity: debouncedAmount ?? 0,
      sellingPricePerUnit: {
        amount: debouncedSellingPricePerUnit ?? 0,
        currency: props.sellingCurrency ?? '',
      },
      purchasePricePerUnit: {
        amount: debouncedPurchasePricePerUnit ?? 0,
        currency: props.purchaseCurrency ?? '',
      },
      vatType: debouncedVatType ?? '',
      discountRate: getDecimalFromPercentage(debouncedDiscount),
    },
  };

  const timeNormPrices = useGetCalculatedItemPriceForTimeNormQuery(
    {
      ...requestArgs,
      body: {
        ...requestArgs.body,
        workType: debouncedWorkType ?? '',
        assignMechanics:
          debouncedMechanics
            ?.filter((mechanic) => mechanic.id)
            ?.map((mechanic) => ({
              ...mechanic,
              id: mechanic.id ?? '',
              ratio: mechanic.ratio / 100,
            })) ?? [],
      },
    },
    {skip: props.priceType !== 'SVCPRICETYPE_TIME_NORM' || not(isAmountValid)}
  );
  const calculationPrices = useGetCalculatedItemPriceForCalculationPriceQuery(requestArgs, {
    skip: props.priceType !== 'SVCPRICETYPE_CALCULATION_PRICE' || not(isAmountValid),
  });
  const directPrices = useGetCalculatedItemPriceForDirectPriceQuery(requestArgs, {
    skip: props.priceType !== 'SVCPRICETYPE_DIRECT_PRICE' || not(isAmountValid),
  });
  const cooperationPrices = useGetCalculatedItemPriceForCooperationQuery(requestArgs, {
    skip: props.priceType !== 'SVCPRICETYPE_COOPERATION' || not(isAmountValid),
  });
  const subcontractPrices = useGetCalculatedItemPriceForSubcontractQuery(requestArgs, {
    skip: props.priceType !== 'SVCPRICETYPE_SUBCONTRACT' || not(isAmountValid),
  });
  const materialPrices = useGetOrderItemCalculatePriceQuery(requestArgs, {
    skip: isNotNil(props.priceType) || not(isAmountValid),
  });

  const customCurrencyFormat = (amount: number | Nullish, currency: string | Nullish) => {
    if (isNil(amount) || isNil(currency)) {
      return EMPTY_PLACEHOLDER;
    }

    return formatCurrency(amount, currency, 2);
  };

  const pricesByType = match(props.priceType)
    .with('SVCPRICETYPE_TIME_NORM', () => timeNormPrices)
    .with('SVCPRICETYPE_CALCULATION_PRICE', () => calculationPrices)
    .with('SVCPRICETYPE_DIRECT_PRICE', () => directPrices)
    .with('SVCPRICETYPE_COOPERATION', () => cooperationPrices)
    .with('SVCPRICETYPE_SUBCONTRACT', () => subcontractPrices)
    .otherwise(() => materialPrices);

  const articlePriceStatusIndicatorTooltip = match(materialPrices?.data?.articlePriceIndicator)
    .with('EQUAL', always(i18n.t('entity.warehouse.labels.equalPriceTooltip')))
    .with('HIGHER', always(i18n.t('entity.warehouse.labels.highPriceTooltip')))
    .with('LOWER', always(i18n.t('entity.warehouse.labels.lowPriceTooltip')))
    .with('NO_PRICE', always(i18n.t('entity.warehouse.labels.noPriceTooltip')))
    .otherwise(always(null));

  const articlePriceStatusIndicatorLabel = match(materialPrices?.data?.articlePriceIndicator)
    .with('EQUAL', always(i18n.t('entity.warehouse.labels.equal')))
    .with('HIGHER', always(i18n.t('entity.warehouse.labels.high')))
    .with('LOWER', always(i18n.t('entity.warehouse.labels.low')))
    .with('NO_PRICE', always(i18n.t('entity.warehouse.labels.noPrice')))
    .otherwise(always(''));

  const articlePriceStatusIndicatorColor = match(materialPrices?.data?.articlePriceIndicator)
    .returnType<ColorSchemeType | undefined>()
    .with('EQUAL', always('green'))
    .with('HIGHER', always('orange'))
    .with('LOWER', always('red'))
    .with('NO_PRICE', always('neutral'))
    .otherwise(always(undefined));

  const marginPercentage = Math.floor(
    (pricesByType?.data?.marginTotal?.marginPercentage ?? 0) * 100
  );

  return (
    <DataStatus isLoading={pricesByType?.isLoading} isError={pricesByType?.isError} minHeight={15}>
      <HStack spacing={4}>
        <Box flex={1}>
          <VStack spacing={1}>
            <Text color="secondary" size="xSmall">
              {i18n.t('general.labels.purchasePrice')}
            </Text>
            <Heading
              size={5}
              color="secondary"
              data-testid={suffixTestId('purchaseWithoutVat', props)}
            >
              {customCurrencyFormat(
                pricesByType?.data?.purchaseTotalPrice?.withoutVat?.amount,
                pricesByType?.data?.purchaseTotalPrice?.withoutVat?.currency
              )}
            </Heading>
            <Text
              color="secondary"
              size="xSmall"
              data-testid={suffixTestId('purchaseWithVat', props)}
            >
              {`${customCurrencyFormat(
                pricesByType?.data?.purchaseTotalPrice?.withVat?.amount,
                pricesByType?.data?.purchaseTotalPrice?.withVat?.currency
              )} ${i18n.t('general.labels.withVat')}`}
            </Text>
          </VStack>
        </Box>
        <Show when={!props.isEditMaterialItem}>
          <Box flex={1} />
        </Show>
        <Show when={props.isEditMaterialItem}>
          <Box flex={1}>
            <VStack spacing={1}>
              <Text color="secondary" size="xSmall">
                {i18n.t('entity.warehouse.labels.articleSalePrice')}
              </Text>

              <HStack spacing={2} align="center">
                <Heading size={5} data-testid={suffixTestId('articlePriceWithoutVat', props)}>
                  {customCurrencyFormat(
                    materialPrices?.data?.articleSalePrice?.withoutVat?.amount,
                    materialPrices?.data?.articleSalePrice?.withoutVat?.currency
                  )}
                </Heading>
                <Tooltip placement="top" label={articlePriceStatusIndicatorTooltip}>
                  <Flag
                    label={articlePriceStatusIndicatorLabel}
                    colorScheme={articlePriceStatusIndicatorColor}
                    size="small"
                    data-testid={suffixTestId('articleSalePriceStatus', props)}
                  />
                </Tooltip>
              </HStack>

              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('articlePriceWithVat', props)}
              >
                {`${customCurrencyFormat(
                  materialPrices?.data?.articleSalePrice?.withVat?.amount,
                  materialPrices?.data?.articleSalePrice?.withVat?.currency
                )} ${i18n.t('general.labels.withVat')}`}
              </Text>
            </VStack>
          </Box>
        </Show>
        <Box flex={1}>
          <VStack spacing={1} align={props.isEditMaterialItem ? 'flex-start' : 'flex-end'}>
            <Text color="secondary" size="xSmall">
              {i18n.t('entity.addWork.lables.margin')}
            </Text>
            <HStack align="center" spacing={2}>
              <Heading
                size={5}
                color={
                  isNegative(pricesByType?.data?.marginTotal?.withoutVat?.amount ?? 0)
                    ? 'danger'
                    : 'success'
                }
                data-testid={suffixTestId('marginWithoutVat', props)}
              >
                {customCurrencyFormat(
                  pricesByType?.data?.marginTotal?.withoutVat?.amount,
                  pricesByType?.data?.marginTotal?.withoutVat?.currency
                )}
              </Heading>
              <Flag
                label={`${marginPercentage}%`}
                colorScheme={isNegative(marginPercentage) ? 'red' : 'green'}
                isSubtle
                size="small"
                data-testid={suffixTestId('marginPercentage', props)}
              />
            </HStack>
            <Text
              color="secondary"
              size="xSmall"
              data-testid={suffixTestId('marginWithVat', props)}
            >
              {`${customCurrencyFormat(
                pricesByType?.data?.marginTotal?.withVat?.amount,
                pricesByType?.data?.marginTotal?.withVat?.currency
              )} ${i18n.t('general.labels.withVat')}`}
            </Text>
          </VStack>
        </Box>
        <Box flex={1}>
          <VStack spacing={1} align={props.isEditMaterialItem ? 'flex-start' : 'flex-end'}>
            <Text color="secondary" size="xSmall">
              {i18n.t('general.labels.sellingPrice')}
            </Text>
            <Heading size={5} data-testid={suffixTestId('sellingWithoutVat', props)}>
              {customCurrencyFormat(
                pricesByType?.data?.sellingTotalPrice?.withoutVat?.amount,
                pricesByType?.data?.sellingTotalPrice?.withoutVat?.currency
              )}
            </Heading>
            <Text
              color="secondary"
              size="xSmall"
              data-testid={suffixTestId('sellingWithVat', props)}
            >
              {`${customCurrencyFormat(
                pricesByType?.data?.sellingTotalPrice?.withVat?.amount,
                pricesByType?.data?.sellingTotalPrice?.withVat?.currency
              )} ${i18n.t('general.labels.withVat')}`}
            </Text>
          </VStack>
        </Box>
      </HStack>
      <Show when={props.isEditMaterialItem}>
        <HStack>
          <Button
            variant="link"
            leftIcon="navigation/refresh"
            title={i18n.t('entity.warehouse.actions.getCurrentPrice')}
            isLoading={calculatePriceQuery.isLoading}
            onClick={() => {
              calculatePrice({
                ...requestArgs,
                body: {
                  ...requestArgs.body,
                  getCurrentPrice: true,
                },
              })
                .unwrap()
                .then((data) => {
                  if (!data?.articleSalePrice?.withoutVat?.amount || !props.formApi) {
                    return;
                  }

                  props.formApi.setValue(
                    'sellingPricePerUnit',
                    data?.articleSalePrice?.withoutVat?.amount
                  );
                })
                .catch(handleApiError);
            }}
            data-testid={suffixTestId('actions.getCurrentPrice', props)}
          />
        </HStack>
      </Show>
    </DataStatus>
  );
}
