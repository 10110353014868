import {Highlight} from '../../types/Highlight';
import {Shortcoming} from '../../types/Shortcoming';

export const LOAD_HIGHLIGHTS_LIST_REQUEST = 'LOAD_HIGHLIGHTS_LIST_REQUEST';
export const LOAD_HIGHLIGHTS_LIST_ERROR = 'LOAD_HIGHLIGHTS_LIST_ERROR';
export const LOAD_HIGHLIGHTS_LIST = 'LOAD_HIGHLIGHTS_LIST';

export const LOAD_SHORTCOMINGS_LIST_REQUEST = 'LOAD_SHORTCOMINGS_LIST_REQUEST';
export const LOAD_SHORTCOMINGS_LIST_ERROR = 'LOAD_SHORTCOMINGS_LIST_ERROR';
export const LOAD_SHORTCOMINGS_LIST = 'LOAD_SHORTCOMINGS_LIST';

export type LoadHighlightsListRequestAction = {
  type: typeof LOAD_HIGHLIGHTS_LIST_REQUEST;
  page?: number;
  data?: Highlight[];
};
export type LoadHighlightsListErrorAction = {
  type: typeof LOAD_HIGHLIGHTS_LIST_ERROR;
};
export type LoadHighlightsListAction = {
  type: typeof LOAD_HIGHLIGHTS_LIST;
  highlights: Highlight[];
};

export type LoadShortcomingsListRequestAction = {
  type: typeof LOAD_SHORTCOMINGS_LIST_REQUEST;
  page?: number;
  data?: Shortcoming[];
};
export type LoadShortcomingsListErrorAction = {
  type: typeof LOAD_SHORTCOMINGS_LIST_ERROR;
};
export type LoadShortcomingsListAction = {
  type: typeof LOAD_SHORTCOMINGS_LIST;
  shortcomings: Shortcoming[];
};

export type ListsReducerActions =
  | LoadHighlightsListRequestAction
  | LoadHighlightsListErrorAction
  | LoadHighlightsListAction
  | LoadShortcomingsListRequestAction
  | LoadShortcomingsListErrorAction
  | LoadShortcomingsListAction;

type LoadHighlightsListType = () => LoadHighlightsListRequestAction;
type LoadShortcomingsListType = () => LoadShortcomingsListRequestAction;

export const loadHighlightsList: LoadHighlightsListType = () => ({
  type: LOAD_HIGHLIGHTS_LIST_REQUEST,
});

export const loadShortcomingsList: LoadShortcomingsListType = () => ({
  type: LOAD_SHORTCOMINGS_LIST_REQUEST,
});

export const loadShortcomingsListWithData = (
  data: Shortcoming[],
  page: number
): LoadShortcomingsListRequestAction => ({
  type: LOAD_SHORTCOMINGS_LIST_REQUEST,
  data,
  page,
});

export const loadShortcomingsListAction = (
  shortcomings: Shortcoming[]
): LoadShortcomingsListAction => ({
  type: LOAD_SHORTCOMINGS_LIST,
  shortcomings,
});

export const loadShortcomingsListErrorAction = (): LoadShortcomingsListErrorAction => ({
  type: LOAD_SHORTCOMINGS_LIST_ERROR,
});

export const loadHighlightsListWithData = (
  data: Highlight[],
  page: number
): LoadHighlightsListRequestAction => ({
  type: LOAD_HIGHLIGHTS_LIST_REQUEST,
  data,
  page,
});

export const loadHighlightsListAction = (highlights: Highlight[]): LoadHighlightsListAction => ({
  type: LOAD_HIGHLIGHTS_LIST,
  highlights,
});

export const loadHighlightsListErrorAction = (): LoadHighlightsListErrorAction => ({
  type: LOAD_HIGHLIGHTS_LIST_ERROR,
});

export interface ListsReducerStateType {
  highlights: {
    error?: boolean;
    isLoading: boolean;
    data?: Highlight[];
  };
  shortcomings: {
    error?: boolean;
    isLoading: boolean;
    data?: Shortcoming[];
  };
}

export const initState: ListsReducerStateType = {
  highlights: {
    isLoading: true,
    data: [],
  },
  shortcomings: {
    isLoading: true,
    data: [],
  },
};

type ListsReducerType = (
  state: ListsReducerStateType,
  action: ListsReducerActions
) => ListsReducerStateType;

export const listsReducer: ListsReducerType = (state = initState, action) => {
  switch (action.type) {
    case LOAD_HIGHLIGHTS_LIST_REQUEST: {
      return {...state, highlights: {isLoading: true, data: state.highlights.data}};
    }
    case LOAD_HIGHLIGHTS_LIST_ERROR: {
      return {...state, highlights: {...state.highlights, error: true, isLoading: false}};
    }
    case LOAD_HIGHLIGHTS_LIST: {
      return {...state, highlights: {isLoading: false, data: action.highlights}};
    }
    case LOAD_SHORTCOMINGS_LIST_REQUEST: {
      return {...state, shortcomings: {isLoading: true, data: state.shortcomings.data}};
    }
    case LOAD_SHORTCOMINGS_LIST_ERROR: {
      return {
        ...state,
        shortcomings: {...state.shortcomings, error: true, isLoading: false},
      };
    }
    case LOAD_SHORTCOMINGS_LIST: {
      return {...state, shortcomings: {isLoading: false, data: action.shortcomings}};
    }
    default: {
      return {...state};
    }
  }
};
