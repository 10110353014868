import {Dialog, useDialog} from 'platform/components';

import {useEffect} from 'react';

import i18n from '@omnetic-dms/i18n';

export function OfflineDetector() {
  const [isOpen, open, close] = useDialog();

  useEffect(() => {
    const handleOnline = () => {
      if (isOpen) {
        close();
      }
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', open);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', open);
    };
  }, [close, isOpen, open]);

  return (
    <Dialog
      isOpen={isOpen}
      size="small"
      onClose={close}
      title={i18n.t('general.notifications.noInternet')}
    >
      {i18n.t('general.notifications.noInternetSubText')}
    </Dialog>
  );
}
