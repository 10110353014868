import {Nullish} from 'shared';

export function sortByLabel<T extends {label: string}>(data: T[] | Nullish) {
  return (
    data?.sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    }) ?? null
  );
}
