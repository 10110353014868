import {AxiosError} from 'axios';
import {sub} from 'date-fns';
import {ButtonGroup} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';
import {SchemaOf, ValidationError, array, boolean, date, number, object, string} from 'yup';

import {FC, MouseEvent, useEffect, useState} from 'react';
import {
  Controller,
  DeepMap,
  FieldError,
  FieldPath,
  NestedValue,
  useForm,
  useWatch,
} from 'react-hook-form';

import {isNotNil} from 'ramda';
import {isNonEmptyArray, isTrue} from 'ramda-adjunct';

import {ApiException, SeriesResponseBody, useGetSeriesTypeListQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  Button,
  Checkbox,
  Dropdown,
  FormLabel,
  SeriesPatternStateEnum,
  TextField,
} from '@omnetic-dms/teas';

import {getRandomId, parseDate} from 'shared';

import {ItemType} from '../types/ItemType';
import {parsePatternErrors, zipItemErrors} from '../utils';
import {getEmptyItem} from '../utils/getEmptyItem';
import {ItemsEditor} from './ItemsEditor';
import {CheckboxWrapper, StyledCard, Wrapper} from './styles';

type IOptionType = {
  label: string;
  value: string;
};

const formValuesDefault = {
  name: '',
  // eslint-disable-next-line no-restricted-syntax
  type: null as unknown as IOptionType,
  withPrefix: false,
  items: [{...getEmptyItem(), from: new Date()}],
  unique: true,
};

export interface DocumentSeriesFormValues {
  name: string;
  type: IOptionType;
  items: ItemType[];
  unique: boolean;
  withPrefix: boolean;
}

type FormValueWithNested = Omit<DocumentSeriesFormValues, 'type'> & {
  type: NestedValue<DocumentSeriesFormValues['type']>;
};

const createDefaultValues = (
  series: SeriesResponseBody | null,
  typeOptions: IOptionType[]
): DocumentSeriesFormValues => {
  if (!series) {
    return formValuesDefault;
  }

  const type = typeOptions.find(({value}) => series.type === value);

  const items = series.patterns
    ?.map(({prefix, startingNumber, length, from, state}) => ({
      prefix,
      startingNumber,
      length: length.toString(),
      from: isNotNil(from) ? parseDate(from) : null,
      state,
    }))
    .sort(sortByDateFrom);

  return {
    name: series.name,
    type: type as IOptionType,
    items: items.map((item) => ({...item, id: getRandomId()})),
    unique: series.unique,
    withPrefix: series.withPrefix,
  };
};

interface DocumentSeriesFormProps {
  series?: SeriesResponseBody;
  onSubmit: (values: DocumentSeriesFormValues) => Promise<AxiosError<ApiException> | null>;
  onCancel: () => void;
}

type FormErrors = DeepMap<ItemType, FieldError>;

export const DocumentSeriesForm: FC<DocumentSeriesFormProps> = ({series, onSubmit, onCancel}) => {
  const {data: documentSeriesTypes} = useGetSeriesTypeListQuery();
  const [documentTypeOptions, setDocumentTypeOptions] = useState<IOptionType[] | undefined>([]);
  const [itemErrors, setItemErrors] = useState<FormErrors[]>([]);
  const {
    control,
    handleSubmit,
    formState: {errors},
    formState,
    reset,
    setError,
  } = useForm<FormValueWithNested>({
    defaultValues: formValuesDefault as any,
  });

  const isSelectedAccountingInvoice =
    useWatch({control, name: 'type'})?.value === 'accounting/invoice';

  const submitHandler = handleSubmit(async (values) => {
    try {
      await formValuesSchema.validate(values, {
        abortEarly: false,
      });

      const itemErrors: FormErrors[] = [];
      for (const index in values.items) {
        const item = values.items[index];

        if (item.state === SeriesPatternStateEnum.NOT_USED) {
          const errors: any = {};
          try {
            itemSchema.validateSync(item, {
              abortEarly: false,
            });
          } catch (error: any) {
            error.inner.forEach(({path, message}: ValidationError) => {
              errors[path as string] = {message};
            });
            itemErrors[index] = errors;
          }
        }
      }

      if (itemErrors.length > 0) {
        setItemErrors(itemErrors);
      } else {
        const _values = {
          ...values,
          name: values?.name?.trim(),
          items: values?.items
            ?.map((item) => ({
              ...item,
              prefix: item?.prefix?.trim(),
            }))
            .sort(sortByDateFrom),
        };

        const error = await onSubmit(_values);

        if (error?.response?.data.validationErrors) {
          const errors = parsePatternErrors(error.response?.data.validationErrors);
          setItemErrors(errors);
        }
      }
    } catch (error: any) {
      if (error.inner) {
        error.inner.forEach((error: ValidationError) => {
          setError(error.path as FieldPath<FormValueWithNested>, {message: error.message});
        });
      }
    }
    return;
  });

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onCancel();
  };

  useEffect(() => {
    setDocumentTypeOptions(
      documentSeriesTypes?.map(({code, name}) => ({
        label: name,
        value: code,
      }))
    );
  }, [documentSeriesTypes]);

  useEffect(() => {
    if (series && isNonEmptyArray(documentTypeOptions)) {
      const defaultValues = createDefaultValues(series, documentTypeOptions);
      reset(defaultValues as any);
    }
  }, [series, documentTypeOptions, reset]);

  const {isDirty} = formState;

  const isSubmitEnabled = Object.keys(errors).length === 0 && isDirty;

  return (
    <Wrapper>
      <form onSubmit={submitHandler}>
        <StyledCard
          title={
            series
              ? i18n.t('entity.documentSeries.actions.updateSeries')
              : i18n.t('entity.documentSeries.actions.newSeries')
          }
          footerContent={
            <ButtonGroup align="right">
              <Button
                secondary
                onClick={handleCancel}
                data-testid={testIds.settings.seriesDefinitionEdit('cancel')}
              >
                {i18n.t('general.actions.cancel')}
              </Button>
              <Button
                primary
                type="submit"
                disabled={!isSubmitEnabled}
                data-testid={testIds.settings.seriesDefinitionEdit('save')}
              >
                {series ? i18n.t('general.actions.update') : i18n.t('general.actions.create')}
              </Button>
            </ButtonGroup>
          }
        >
          <HStack spacing={4}>
            <Box flex={1}>
              <FormLabel>{i18n.t('entity.documentSeries.name')}</FormLabel>
              <Controller
                name="name"
                control={control}
                render={({field: {onChange, value}}) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    error={!!errors?.name?.message}
                    helperText={errors?.name?.message}
                    disabled={series?.system}
                    data-testid={testIds.settings.vehicleEditSeriesDefinition('name')}
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <FormLabel>{i18n.t('entity.documentSeries.type')}</FormLabel>
              <Controller
                name="type"
                control={control}
                render={({field: {onChange, value}}) => (
                  <Dropdown
                    onChange={onChange}
                    options={documentTypeOptions}
                    value={value}
                    validation={
                      errors.type
                        ? {
                            valid: false,
                            messages: [errors?.type?.message],
                          }
                        : undefined
                    }
                    isDisabled={!!series}
                    data-testid={testIds.settings.vehicleEditSeriesDefinition('type')}
                  />
                )}
              />
            </Box>

            <Show when={isSelectedAccountingInvoice}>
              <Box flex={1}>
                <FormLabel>{i18n.t('entity.documentSeries.variableSymbol.title')}</FormLabel>

                <Controller
                  name="withPrefix"
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <Dropdown
                      onChange={(option) => onChange(option?.value === 'prefixWithStartingNumber')}
                      options={withPrefixOptions}
                      required={isSelectedAccountingInvoice}
                      value={withPrefixOptions.find(
                        (option) =>
                          option?.value ===
                          (isTrue(value) ? 'prefixWithStartingNumber' : 'onlyStartingNumber')
                      )}
                      validation={
                        errors.type
                          ? {
                              valid: false,
                              messages: [errors?.type?.message],
                            }
                          : undefined
                      }
                      data-testid={testIds.settings.vehicleEditSeriesDefinition('generationType')}
                    />
                  )}
                />
              </Box>
            </Show>

            <Box flex={1}>
              <CheckboxWrapper>
                <Controller
                  name="unique"
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <Checkbox
                      checked={value}
                      onClick={() => onChange(!value)}
                      onChange={onChange}
                      disabled={!!series}
                      label={i18n.t('entity.documentSeries.unique')}
                      data-testid={testIds.settings.vehicleEditSeriesDefinition('unique')}
                    />
                  )}
                />
              </CheckboxWrapper>
            </Box>
          </HStack>

          <Controller
            name="items"
            control={control}
            render={({field: {onChange, value}}) => (
              <ItemsEditor
                onChange={onChange}
                value={value}
                errors={zipItemErrors(errors?.items as any, itemErrors)}
              />
            )}
          />
        </StyledCard>
      </form>
    </Wrapper>
  );
};

const sortByDateFrom = (a: {from: Date | null}, b: {from: Date | null}) => {
  if (!a.from || !b.from) {
    return 0;
  }
  return a.from.valueOf() - b.from.valueOf();
};

const itemSchema: SchemaOf<ItemType> = // eslint-disable-next-line no-restricted-syntax
  object().shape({
    prefix: string().trim().required(i18n.t('entity.documentSeries.itemValidations.prefix')),
    startingNumber: number()
      .min(1)
      .required(i18n.t('entity.documentSeries.itemValidations.startingNumber')),
    length: string().required(i18n.t('entity.documentSeries.itemValidations.length')),
    from: date()
      .min(sub(new Date(), {days: 1}), (minDate) =>
        i18n.t('entity.documentSeries.itemValidations.fromMin', {minDate})
      )
      .required(i18n.t('entity.documentSeries.itemValidations.from'))
      .nullable(),
    state: string(),
  }) as unknown as SchemaOf<ItemType>;

// eslint-disable-next-line no-restricted-syntax
const formValuesSchema: SchemaOf<DocumentSeriesFormValues> = object().shape({
  name: string().trim().required(i18n.t('entity.documentSeries.validations.name')),
  type: object().required(i18n.t('entity.documentSeries.validations.type')).nullable(),
  branches: array().of(object()).min(1, i18n.t('entity.documentSeries.validations.branch')),
  items: array().min(1, i18n.t('entity.documentSeries.validations.items')),
  withPrefix: boolean().when('type', {
    is: 'accounting/invoice',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.default(false),
  }),
}) as unknown as SchemaOf<DocumentSeriesFormValues>;

const withPrefixOptions = [
  {
    label: i18n.t('entity.documentSeries.variableSymbol.onlyStartingNumber'),
    value: 'onlyStartingNumber',
  },
  {
    label: i18n.t('entity.documentSeries.variableSymbol.prefixWithStartingNumber'),
    value: 'prefixWithStartingNumber',
  },
];
