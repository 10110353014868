import {parseISO, startOfToday} from 'date-fns';
import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  closeCurrentDialog,
} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {object} from 'yup';

import {head} from 'ramda';

import {DeputyPersonV2, IdentityCardTypesResponseBody, PersonalIdType} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {yupDate, yupString} from 'shared';

import {useGetPersonalIdTypes} from '../hooks/useGetPersonalIdTypes';

type FormValues = {
  isPrimary: boolean;
  firstName: string;
  lastName: string | null;
  dateOfBirth: Date | null;
  identificationNumber: string | null;
  personalIdType: PersonalIdType | null;
  personalIdNumber: string | null;
  validUntil: Date | null;
  issuedBy: string | null;
};

interface DeputyPersonFormProps {
  handleSubmit: (values: DeputyPersonV2) => void;
  defaultValues?: DeputyPersonV2;
}

export function DeputyPersonForm(props: DeputyPersonFormProps) {
  const {types} = useGetPersonalIdTypes();

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    const submitValues: DeputyPersonV2 = {
      ...values,
      isPrimary: false,
      dateOfBirth: values.dateOfBirth?.toISOString() ?? null,
      validUntil: values.validUntil?.toISOString() ?? null,
    };

    await props.handleSubmit(submitValues);
    closeCurrentDialog();
  };

  const defaultValues: Partial<FormValues> = {
    ...props.defaultValues,
    dateOfBirth: props.defaultValues?.dateOfBirth
      ? parseISO(props.defaultValues.dateOfBirth)
      : null,
    validUntil: props.defaultValues?.validUntil ? parseISO(props.defaultValues.validUntil) : null,
    personalIdType: props.defaultValues?.personalIdType ?? head(types)?.value,
  };

  return (
    <Form<FormValues> defaultValues={defaultValues} onSubmit={handleSubmit} schema={schema}>
      {(control) => (
        <VStack spacing={5}>
          <Grid columns={2}>
            <FormField
              type="text"
              control={control}
              name="firstName"
              label={i18n.t('entity.person.labels.firstName')}
              isRequired
            />
            <FormField
              type="text"
              control={control}
              name="lastName"
              label={i18n.t('entity.person.labels.lastName')}
              isRequired
            />
            <FormField
              type="date"
              control={control}
              name="dateOfBirth"
              maxDate={startOfToday()}
              label={i18n.t('entity.person.labels.birthdate')}
            />
            <FormField
              type="text"
              control={control}
              name="identificationNumber"
              label={i18n.t('entity.customer.labels.registrationNumber')}
            />
            <FormField
              type="choice"
              control={control}
              isNotClearable
              name="personalIdType"
              options={types}
              label={i18n.t('entity.person.labels.personalIdType')}
            />
            <FormField
              type="text"
              control={control}
              name="personalIdNumber"
              label={i18n.t('entity.person.labels.idNumber')}
            />
            <FormField
              type="date"
              control={control}
              name="validUntil"
              label={i18n.t('general.labels.validUntil')}
              minDate={startOfToday()}
            />
            <FormField
              type="text"
              control={control}
              name="issuedBy"
              label={i18n.t('general.labels.issuedBy')}
            />
          </Grid>

          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discard')}
              onClick={closeCurrentDialog}
              variant="secondary"
            />
            <FormButton control={control} type="submit" title={i18n.t('general.actions.save')} />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = object({
  firstName: yupString.required(),
  lastName: yupString.required(),
  dateOfBirth: yupDate.default(null),
  identificationNumber: yupString.default(null),
  personalIdType: yupString.default(null),
  personalIdNumber: yupString.default(null),
  validUntil: yupDate.default(null),
  issuedBy: yupString.default(null),
});

const identityTypeToOption = (identityType: IdentityCardTypesResponseBody) => ({
  label: identityType.name,
  value: identityType.code,
});
