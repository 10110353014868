import {TestIdProps} from 'shared';

import {DropdownItem} from '../../Dropdown/components/DropdownItem';
import {Dropdown} from '../../Dropdown/Dropdown';
import {IconButton} from '../../IconButton/IconButton';
import {TableRowAction} from '../types/TableRowAction';

interface TableRowActionsDropdownProps extends TestIdProps {
  actions?: TableRowAction[];
}

export function TableRowActionsDropdown(props: TableRowActionsDropdownProps) {
  return (
    <Dropdown dropdownControl={<DropdownControl />} data-testid={props['data-testid']}>
      {props.actions?.map((action) => (
        <DropdownItem
          leftIcon={action.icon}
          severity={action.severity}
          label={action.title}
          onClick={action.onClick}
          isDisabled={action.isDisabled}
          key={action.title}
          data-testid={props['data-testid']}
        />
      ))}
    </Dropdown>
  );
}

function DropdownControl() {
  return <IconButton icon="navigation/more_vert" />;
}
