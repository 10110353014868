import styled from 'styled-components';

export const MultiDefaultWrapper = styled.div`
  height: auto !important;
  width: 102px !important;
  border-radius: 6px;
  box-shadow: 2px 2px 0 ${({theme}) => theme.colors.palettes.blue[80][100]};
`;

export const MultiSelectionDefaultComponent = styled.div`
  padding: 8px;
  border: 1px solid ${({theme}) => theme.colors.palettes.white[10][100]};
  border-radius: ${({theme}) => theme.radii.small};
  background: ${({theme}) => theme.colors.palettes.blue[60][100]};
`;
