import {ThemeColorPath} from 'platform/foundation';

import {TestIdProps} from 'shared';

export type FlagSizeType = 'default' | 'small' | 'large';

/**
 * @about Shared core props for Tag, Flag and Status
 */
export interface BaseFlagProps extends TestIdProps {
  label: string;
  size?: FlagSizeType;
  isSubtle?: boolean;
  colorScheme?: ColorSchemeType;
}

type ColorSchemeValueType = {
  backgroundColor: ThemeColorPath;
  hoverColor: ThemeColorPath;
  color: ThemeColorPath;
};

/**
 * @About DO NOT EDIT,
 * ColorSchemeType is in sync with BE values, adding or removing colors would
 * get BE out of sync
 */
export type ColorSchemeType =
  | 'neutral'
  | 'red'
  | 'magenta'
  | 'purple'
  | 'blue'
  | 'teal'
  | 'green'
  | 'yellow'
  | 'orange'
  | 'black';

/**
 * Taken from:
 * https://www.figma.com/file/eSnsGSreTT5Jxa5zO44aNW/Omnetic%E2%84%A2---Elements?node-id=9082%3A98123
 */
export const FlagColorScheme: {
  primary: Record<ColorSchemeType, ColorSchemeValueType>;
  subtle: Record<ColorSchemeType, ColorSchemeValueType>;
} = {
  primary: {
    neutral: {
      backgroundColor: 'palettes.neutral.300.100',
      hoverColor: 'palettes.neutral.500.100',
      color: 'palettes.white.10.100',
    },
    red: {
      backgroundColor: 'palettes.red.60.100',
      hoverColor: 'palettes.red.70.100',
      color: 'palettes.white.10.100',
    },
    magenta: {
      backgroundColor: 'palettes.magenta.60.100',
      hoverColor: 'palettes.magenta.70.100',
      color: 'palettes.white.10.100',
    },
    purple: {
      backgroundColor: 'palettes.purple.60.100',
      hoverColor: 'palettes.purple.70.100',
      color: 'palettes.white.10.100',
    },
    blue: {
      backgroundColor: 'palettes.blue.70.100',
      hoverColor: 'palettes.blue.80.100',
      color: 'palettes.white.10.100',
    },
    teal: {
      backgroundColor: 'palettes.teal.60.100',
      hoverColor: 'palettes.teal.70.100',
      color: 'palettes.white.10.100',
    },
    green: {
      backgroundColor: 'palettes.green.60.100',
      hoverColor: 'palettes.green.70.100',
      color: 'palettes.white.10.100',
    },
    yellow: {
      backgroundColor: 'palettes.yellow.60.100',
      hoverColor: 'palettes.yellow.70.100',
      color: 'palettes.neutral.900.100',
    },
    orange: {
      backgroundColor: 'palettes.orange.60.100',
      hoverColor: 'palettes.orange.70.100',
      color: 'palettes.white.10.100',
    },
    black: {
      backgroundColor: 'palettes.neutral.900.100',
      hoverColor: 'palettes.neutral.600.100',
      color: 'palettes.white.10.100',
    },
  },
  subtle: {
    neutral: {
      backgroundColor: 'palettes.neutral.20.100',
      hoverColor: 'palettes.neutral.40.100',
      color: 'palettes.neutral.800.100',
    },
    red: {
      backgroundColor: 'palettes.red.20.100',
      hoverColor: 'palettes.red.30.100',
      color: 'palettes.red.80.100',
    },
    magenta: {
      backgroundColor: 'palettes.magenta.20.100',
      hoverColor: 'palettes.magenta.30.100',
      color: 'palettes.magenta.80.100',
    },
    purple: {
      backgroundColor: 'palettes.purple.20.100',
      hoverColor: 'palettes.purple.30.100',
      color: 'palettes.purple.80.100',
    },
    blue: {
      backgroundColor: 'palettes.blue.20.100',
      hoverColor: 'palettes.blue.30.100',
      color: 'palettes.blue.80.100',
    },
    teal: {
      backgroundColor: 'palettes.teal.20.100',
      hoverColor: 'palettes.teal.30.100',
      color: 'palettes.teal.90.100',
    },
    green: {
      backgroundColor: 'palettes.green.20.100',
      hoverColor: 'palettes.green.30.100',
      color: 'palettes.green.80.100',
    },
    yellow: {
      backgroundColor: 'palettes.yellow.20.100',
      hoverColor: 'palettes.yellow.30.100',
      color: 'palettes.neutral.900.100',
    },
    orange: {
      backgroundColor: 'palettes.orange.20.100',
      hoverColor: 'palettes.orange.30.100',
      color: 'palettes.orange.80.100',
    },
    black: {
      backgroundColor: 'palettes.neutral.20.100',
      hoverColor: 'palettes.neutral.40.100',
      color: 'palettes.neutral.800.100',
    },
  },
};
