import {Card, DataStatus, Flag, Table, TableRow, openDialog} from 'platform/components';
import {Box, Icon, Link, Show, Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isEmpty, isNil} from 'ramda';

import {
  ConsentChannel,
  ConsentResponseBody,
  useGetConsentListQuery,
  useGetCustomerV2Query,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {parseDate} from 'shared';

import {AddConsentForm} from './components/AddConsentForm';
import {ConsentsTableHeader} from './components/ConsentsTableHeader';
import {useGetConsentListActions} from './hooks/useGetConsentListActions';
import {getConsentStatusColor} from './utils/getConsentStatusColor';

interface GDPRConsentsProps {
  customerId: string;
  businessCaseId?: string;
}

export function GdprConsentsList(props: GDPRConsentsProps) {
  const formatDateTime = useDateTimeFormatter();
  const [getConsentActions] = useGetConsentListActions(props.customerId);

  const {isLoading: isCustomerLoading, isError: isCustomerErrored} = useGetCustomerV2Query({
    customerId: props.customerId,
  });
  const {
    data: consents,
    isLoading,
    isError,
  } = useGetConsentListQuery({customerId: props.customerId ?? ''});

  const handleAddConsent = () =>
    openDialog(
      <AddConsentForm
        businessCaseId={props.businessCaseId ?? null}
        customerId={props.customerId}
      />,
      {
        title: i18n.t('entity.gdpr.labels.addConsent'),
        scrollBehavior: 'outside',
      }
    );

  return (
    <Card
      title={i18n.t('entity.gdpr.labels.consents')}
      actions={[
        {
          type: 'button',
          variant: 'link',
          isDisabled: isNil(props.customerId),
          onClick: handleAddConsent,
          leftIcon: 'content/add_circle',
          title: i18n.t('entity.gdpr.actions.addNewConsent'),
          'data-testid': testIds.businessCase.customer('gdpr-consent-create-button'),
        },
      ]}
    >
      <DataStatus
        spacing={4}
        isEmpty={isEmpty(consents)}
        isLoading={isLoading || isCustomerLoading}
        isError={isError || isCustomerErrored}
      >
        <Table
          data-testid={testIds.businessCase.customer('gdpr-consents-list-table')}
          columns={columns}
        >
          {consents?.map((consent: ConsentResponseBody, index) => {
            const actions = getConsentActions(consent);

            return (
              <TableRow
                data-testid={testIds.businessCase.customer(`gdpr-consent-row-${index}`)}
                key={consent.id}
                actions={actions}
                hoverColor="general.transparent"
              >
                <Box paddingHorizontal={2} paddingVertical={3}>
                  <Text size="small">{consent.name}</Text>
                </Box>

                <Flag
                  colorScheme={getConsentStatusColor(consent.status)}
                  label={i18n.t(`entity.gdpr.labels.constent.status.${consent.status}`)}
                  data-testid={testIds.businessCase.customer(`gdpr-consent-status-${index}`)}
                />

                <Text size="small">
                  {formatDateTime('dateShort', parseDate(consent.createdAt))}
                </Text>

                <Text size="small">
                  {consent.validUntil && formatDateTime('dateShort', parseDate(consent.validUntil))}
                </Text>

                <Link
                  size="small"
                  href={consent.fileUri}
                  download={consent.fileName}
                  title={consent.fileName}
                  data-testid={testIds.businessCase.customer(`gdpr-consent-download-link-${index}`)}
                />

                <Show when={consent.channels.includes(ConsentChannel.PHONE)}>
                  <Icon value="AV/fiber_manual_record" size={4} />
                </Show>
                <Show when={consent.channels.includes(ConsentChannel.SMS)}>
                  <Icon value="AV/fiber_manual_record" size={4} />
                </Show>
                <Show when={consent.channels.includes(ConsentChannel.EMAIL)}>
                  <Icon value="AV/fiber_manual_record" size={4} />
                </Show>
                <Show when={consent.channels.includes(ConsentChannel.MAIL)}>
                  <Icon value="AV/fiber_manual_record" size={4} />
                </Show>
              </TableRow>
            );
          })}
        </Table>
      </DataStatus>
    </Card>
  );
}

const columns = [
  {
    element: (
      <Box paddingLeft={2}>
        <ConsentsTableHeader text={i18n.t('entity.gdpr.labels.consent.name')} />
      </Box>
    ),
  },
  {
    element: <ConsentsTableHeader text={i18n.t('entity.gdpr.labels.consent.status')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('entity.gdpr.labels.consent.created')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('entity.gdpr.labels.consent.validUntil')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('entity.gdpr.labels.consent.document')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('general.labels.phone')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('general.labels.sms')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('general.labels.email')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('general.labels.mail')} />,
  },
];
