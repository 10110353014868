import {
  Card,
  closeCurrentDialog,
  closeDialog,
  DataStatus,
  openDialog,
  useDialog,
} from 'platform/components';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {isNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds, workshopRoutes} from '@omnetic-dms/routes';
import {
  catchUnhandledDataGridActions,
  ProfitabilityHistory,
  queryParams,
  Section,
  UploadDocumentsDialog,
  useGetDocumentDatagridActions,
  WorkItems,
  workshopSearchParams,
} from '@omnetic-dms/shared';

import {Nullish, composePath} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';
import {CopyHistoryItem} from './components/CopyHistoryItem';
import {CopyRequest} from './components/CopyRequest';

type JobRow = {
  id: string;
  serviceCaseId: {value: string};
  serviceOrderId: {value: string};
  jobName: {value: string};
};

type ReceiveJobRowData = JobRow | JobRow[] | Nullish;

type ReceiveOrderRowData =
  | {
      serviceCaseId?: {value?: string};
    }
  | Nullish;

type ReceiveCaseRowData =
  | {
      caseState?: {value?: {key?: string}};
    }
  | Nullish;

interface HistoryOfRepairsProps {
  vehicleId: string | Nullish;
}

export function HistoryOfRepairs(props: HistoryOfRepairsProps) {
  const {serviceCaseId} = useWorkshopUrl();

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();
  const [documentsActionCallback] = useGetDocumentDatagridActions(refreshDataGrid);

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {vehicleId: props.vehicleId ?? null}]);

  const actionOrderCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    const castedRowData = rowData as ReceiveOrderRowData;

    match(actionKey)
      .with('redirectDetail', 'redirectDetail_newTab', () => {
        const orderId = isArray(rowId) ? rowId[0] : rowId;

        window.open(
          composePath(workshopRoutes.serviceCaseDetail, {
            params: {
              id: castedRowData?.serviceCaseId?.value ?? '',
            },
            queryParams: {
              [queryParams.COMPONENT_SECTIONS_SECTION]: workshopSearchParams.serviceDetail.orders,
              [queryParams.SERVICE_CASE_ORDER_ID]: orderId,
            },
          })
        );
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const actionServiceRequestsCallback: ActionCallback = ({actionKey, rowData}) => {
    const castedRowData = rowData as ReceiveJobRowData;

    if (actionKey === 'copy' && castedRowData) {
      const items = isArray(castedRowData)
        ? castedRowData.map((row) => ({
            serviceJobId: row.id,
            serviceOrderId: row.serviceOrderId?.value,
          }))
        : [
            {
              serviceJobId: castedRowData.id,
              serviceOrderId: castedRowData.serviceOrderId.value,
            },
          ];

      openDialog(
        <CopyRequest
          serviceCaseId={serviceCaseId}
          items={items}
          onClose={() => closeDialog('copyJobDialog')}
          data-testid={testIds.workshop.serviceCaseDetail('historyOfRepairs-copyJobDialog')}
        />,
        {
          id: 'copyJobDialog',
          title: `${i18n.t('entity.order.labels.copyToServiceOrder')}`,
        }
      );

      return;
    }

    if (!isArray(castedRowData)) {
      const path = composePath(workshopRoutes.serviceCaseDetail, {
        params: {
          id: castedRowData?.serviceCaseId?.value,
        },
        queryParams: {
          [queryParams.COMPONENT_SECTIONS_SECTION]: workshopSearchParams.serviceDetail.orders,
          [queryParams.SERVICE_CASE_ORDER_ID]: castedRowData?.serviceOrderId?.value ?? '',
          [queryParams.SERVICE_CASE_OPEN_JOB_ID]: castedRowData?.id,
        },
      });

      match(actionKey)
        .with('open', () => window.open(path))
        .otherwise(() => catchUnhandledDataGridActions(actionKey));
    }
  };

  const actionCaseCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    const castedRowData = rowData as ReceiveCaseRowData;

    match(actionKey)
      .with('redirectDetail', 'redirectDetail_newTab', () => {
        const serviceCaseState = castedRowData?.caseState?.value?.key;
        const serviceCaseId = isArray(rowId) ? rowId[0] : rowId;

        let path;

        if (serviceCaseState === 'DRAFT') {
          path = composePath(workshopRoutes.draftServiceCase, {
            params: {id: serviceCaseId},
            queryParams: {
              [queryParams.COMPONENT_SECTIONS_TAB]: workshopSearchParams.create.serviceJobs,
            },
          });
        } else {
          path = composePath(workshopRoutes.serviceCaseDetail, {
            params: {
              id: serviceCaseId,
            },
            queryParams: {
              [queryParams.COMPONENT_SECTIONS_SECTION]: workshopSearchParams.serviceDetail.orders,
            },
          });
        }

        window.open(path);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const historyItemsCallback: ActionCallback = ({actionKey, rowId}) => {
    if (actionKey !== 'copy') {
      catchUnhandledDataGridActions(actionKey);
    }

    openDialog(
      <CopyHistoryItem
        serviceCaseId={serviceCaseId}
        itemIds={isArray(rowId) ? rowId : [rowId]}
        onClose={closeCurrentDialog}
        data-testid={testIds.workshop.serviceCaseDetail('historyOfRepairs-copyDialog')}
      />,
      {
        title: i18n.t('entity.order.labels.copyToServiceOrder'),
      }
    );
  };

  return (
    <Section
      data-testid={testIds.workshop.serviceCaseDetail('historyOfRepairs')}
      tabs={[
        {
          content: (
            <DataStatus
              isLoading={isNil(props.vehicleId)}
              key="historyOfRepairs-service-cases"
              minHeight={175}
            >
              <Box height={175}>
                <DataGrid
                  gridCode="service-history-service-case"
                  actionCallback={actionCaseCallback}
                  queryModifier={queryModifier}
                  data-testid={testIds.workshop.serviceCaseDetail('historyOfRepairs-serviceCases')}
                />
              </Box>
            </DataStatus>
          ),
          queryId: 'serviceCases',
          title: i18n.t('page.workshop.labels.serviceCases'),
        },
        {
          content: (
            <DataStatus
              isLoading={isNil(props.vehicleId)}
              key="historyOfRepairs-service-order"
              minHeight={175}
            >
              <Box height={175}>
                <DataGrid
                  gridCode="service-history-service-order"
                  actionCallback={actionOrderCallback}
                  queryModifier={queryModifier}
                  data-testid={testIds.workshop.serviceCaseDetail('historyOfRepairs-serviceOrders')}
                />
              </Box>
            </DataStatus>
          ),
          queryId: 'serviceOrders',
          title: i18n.t('page.workshop.labels.serviceOrders'),
        },
        {
          content: (
            <DataStatus
              isLoading={isNil(props.vehicleId)}
              key="historyOfRepairs-service-requests"
              minHeight={175}
            >
              <Box height={175}>
                <DataGrid
                  gridCode="service-history-order-requests"
                  actionCallback={actionServiceRequestsCallback}
                  queryModifier={queryModifier}
                  data-testid={testIds.workshop.serviceCaseDetail(
                    'historyOfRepairs-serviceRequests'
                  )}
                />
              </Box>
            </DataStatus>
          ),
          queryId: 'serviceRequests',
          title: i18n.t('page.workshop.labels.serviceRequests'),
        },
        {
          content: (
            <WorkItems
              localHistoryDatagridCode="service-history-order-items"
              externalHistoryDatagridCode="service-items-external-history"
              vehicleId={props.vehicleId}
              actionCallback={historyItemsCallback}
              data-testid={testIds.workshop.serviceCaseDetail('historyOfRepairs-workItems')}
            />
          ),
          queryId: 'workItems',
          title: i18n.t('entity.orderRequest.labels.workItems'),
        },
        {
          content: (
            <ProfitabilityHistory
              vehicleId={props.vehicleId}
              data-testid={testIds.workshop.serviceCaseDetail(
                'historyOfRepairs-profitabilityHistory'
              )}
            />
          ),
          queryId: 'profitability',
          title: i18n.t('entity.order.labels.profitability'),
        },
        {
          content: (
            <DataStatus
              isLoading={isNil(props.vehicleId)}
              key="historyOfRepairs-documents"
              minHeight={175}
            >
              <Card
                title={i18n.t('page.vehicle.documents.title')}
                actions={[
                  {
                    variant: 'link',
                    type: 'button',
                    leftIcon: 'action/backup',
                    onClick: openUploadDialog,
                    title: i18n.t('entity.document.actions.uploadDocument'),
                  },
                ]}
              >
                <Box minHeight={40}>
                  <DataGrid
                    ref={dataGridRef}
                    gridCode="document-context-list-vehicle"
                    emptyState={{
                      headline: i18n.t('page.vehicle.notifications.noDocuments'),
                      subheadline: i18n.t('page.vehicle.notifications.documentListWillAppearHere'),
                    }}
                    actionCallback={documentsActionCallback}
                    autoHeight
                    queryModifier={queryModifier}
                    data-testid={testIds.workshop.serviceCaseDetail('vehicle-documents')}
                  />
                </Box>
              </Card>
              <UploadDocumentsDialog
                isOpen={isUploadDialogOpen}
                handleClose={closeUploadDialog}
                onDocumentCreated={refreshDataGrid}
                contextTarget="vehicle"
                contextId={props.vehicleId ?? ''}
                data-testid={testIds.workshop.serviceCaseDetail('upload-document')}
              />
            </DataStatus>
          ),
          queryId: 'documents',
          title: i18n.t('page.vehicle.documents.title'),
        },
      ]}
    />
  );
}
