import {FormControl, FormField} from 'platform/components';
import {Hide} from 'platform/foundation';

import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {isNotNil} from 'ramda';

import {OrderResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {useBank, useGetCurrentBranch} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {FormValuesType} from '../types/FormValuesType';

interface TenantBankAccountFieldProps extends TestIdProps {
  formApi: UseFormReturn<FormValuesType>;
  control: FormControl<FormValuesType>;
  order: OrderResponseBody;
}

export function TenantBankAccountField(props: TenantBankAccountFieldProps) {
  const {bankAccountOptions} = useBank();
  const {data: branchData} = useGetCurrentBranch();

  const orderCurrency = props.order.currency;

  useEffect(() => {
    const orderCurrencyBankAccount = branchData?.billingInformation?.bankAccounts.find(
      (bankAccount) => bankAccount.currency === orderCurrency
    );

    if (isNotNil(orderCurrencyBankAccount)) {
      props.formApi.setValue('tenantBankAccount', orderCurrencyBankAccount?.accountNumber);
    }
  }, [branchData?.billingInformation?.bankAccounts, orderCurrency, props.formApi]);

  return (
    <Hide
      when={
        props.order.orderDiscriminator === 'PURCHASE' ||
        props.order.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE'
      }
    >
      <FormField
        name="tenantBankAccount"
        type="choice"
        control={props.control}
        options={bankAccountOptions}
        isNotClearable
        label={i18n.t('entity.checkout.labels.dealerBankAccount')}
        isRequired
        data-testid={suffixTestId('dealerBankAccount', props)}
      />
    </Hide>
  );
}
