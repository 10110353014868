import {useParams} from 'react-router-dom';

import {defaultTo} from 'ramda';

import {queryParams} from '@omnetic-dms/shared';

import {useQueryState} from 'shared';

type WorkshopIdParams = {
  id?: string;
};

export function useWorkshopUrl() {
  const params = useParams<WorkshopIdParams>();
  const [addWorkJobId] = useQueryState(queryParams.SERVICE_CASE_ADD_WORK_JOB_ID);
  const [addMaterialRequestJobId] = useQueryState(
    queryParams.SERVICE_CASE_ADD_MATERIAL_REQUEST_JOB_ID
  );
  const [orderId] = useQueryState(queryParams.SERVICE_CASE_ORDER_ID);

  return {
    serviceCaseId: defaultTo('', params.id),
    addWorkJobId: defaultTo('', addWorkJobId),
    addMaterialRequestJobId: defaultTo('', addMaterialRequestJobId),
    orderId: defaultTo('', orderId),
  };
}
