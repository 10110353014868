import {showNotification} from 'platform/components';
import {Box} from 'platform/foundation';
import styled from 'styled-components';

import {FC, useState, PropsWithChildren, ReactElement} from 'react';
import {useSelector} from 'react-redux';

import {
  InactivityPeriodType,
  SetUserSettingsItemApiArg,
  useSetUserSettingsItemMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {Dropdown, selectUserSettings, TypographyCard} from '@omnetic-dms/teas';

const Label = styled.p`
  margin: 0 0 ${({theme}) => theme.getSize(4)} 0;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  line-height: 1.34;
  color: ${({theme}) => theme.colors.palettes.neutral[300][100]};
`;

type InactivityOption = {label: string; value: string};

const minutes = [15, 30];
const hours = [2, 6, 24, 48];

export const AutoLogout: FC<PropsWithChildren<any>> = (): ReactElement => {
  const {inactivityPeriod} = useSelector(selectUserSettings);
  const [period, setPeriod] = useState<InactivityPeriodType>(inactivityPeriod);
  const [updateUserSettings] = useSetUserSettingsItemMutation();

  const neverOption: InactivityOption[] = [
    {label: i18n.t('entity.user.labels.never'), value: 'INACTIVITY_PERIOD_NEVER'},
  ];
  const minutesOptions: InactivityOption[] = minutes.map((val) => ({
    label: i18n.t(`entity.user.labels.minutes`, {val}),
    value: `INACTIVITY_PERIOD_${val}_MINUTES`,
  }));
  const hourOption: InactivityOption[] = [
    {label: i18n.t('entity.user.labels.oneHour'), value: 'INACTIVITY_PERIOD_1_HOUR'},
  ];
  const hoursOptions: InactivityOption[] = hours.map((val) => ({
    label: i18n.t(`entity.user.labels.hours`, {val}),
    value: `INACTIVITY_PERIOD_${val}_HOURS`,
  }));

  const timeOptions: InactivityOption[] = minutesOptions
    .concat(hourOption)
    .concat(hoursOptions)
    .concat(neverOption);

  const selectedOption = timeOptions.find((option) => option.value === period);

  const onSelect = (newPeriod: any) => {
    if (newPeriod?.value !== period) {
      setPeriod(newPeriod?.value);

      const arg = {
        settingKey: 'inactivity_period',
        userSettingRequestBody: {value: newPeriod?.value},
      } as SetUserSettingsItemApiArg;

      updateUserSettings(arg)
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
        });
    }
  };

  return (
    <ProfileCard
      data-testid={testIds.myProfile.security('logout-card')}
      title={i18n.t('entity.user.labels.autoLogout')}
    >
      <Label>{i18n.t('entity.user.labels.autoLogoutTooltip')}</Label>
      <Box width="60%">
        <Dropdown
          label={i18n.t('entity.user.labels.period')}
          options={timeOptions}
          value={selectedOption}
          onChange={onSelect}
        />
      </Box>
    </ProfileCard>
  );
};

const ProfileCard = styled(TypographyCard)`
  width: 583px;
  margin-bottom: ${({theme}) => theme.getSize(4)};
`;
