import {BreadcrumbType} from 'platform/components';

import {useGetBranchListQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {AddUserForm} from './components/AddUserForm';

export type AddUserPageProps = {
  name?: string; // TODO with BE data and extract to new file
};

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.settings.labels.userManagement'),
    href: settingsRoutes.userManagement,
  },
];

export function AddUser() {
  const {data, isLoading} = useGetBranchListQuery();

  return (
    <SettingsTemplate
      isLoading={!data || isLoading}
      header={{breadcrumbs}}
      data-testid="settings-management-settings-layout"
    >
      <AddUserForm />
    </SettingsTemplate>
  );
}
