import {Alert, Form, FormSubmitHandler, Separator, showNotification} from 'platform/components';
import {Grid, Show, Space, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';
import {array, boolean, object} from 'yup';

import {useEffect, useState} from 'react';

import {always, defaultTo, head, isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {
  BaseAuthorizationProfile,
  BaseServiceOrderVariant,
  BaseSupplier,
  GetBranchListApiResponse,
  GetCodeListOptionsResponse,
  GetCodeListResponse,
  GetCurrenciesApiResponse,
  GetDirectSaleVariantsResponse,
  GetSeriesListApiResponse,
  GetTemplatesApiResponse,
  GetWarehouseAccountsResponse,
  GetWarehouseResponse,
  TenantResponseBody,
  useLazyGetAuthorizationProfileQuery,
  usePatchWarehouseMutation,
  usePostWarehouseMutation,
  WarehousePricingEnum,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError, useDuplicateErrorHandler} from '@omnetic-dms/shared';

import {
  Nullish,
  RequiredTestIdProps,
  suffixTestId,
  useNavigate,
  yupNumber,
  yupString,
} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {WarehouseSettingsForm} from '../types/WarehouseSettingsForm';
import {AccountingForm} from './AccountingForm';
import {DocumentsForm} from './DocumentsForm';
import {GeneralForm} from './GeneralForm';
import {PricingForm} from './PricingForm';
import {SuppliersForm} from './SuppliersForm';

export interface WarehouseDetailFormProps extends RequiredTestIdProps {
  tenant: TenantResponseBody | Nullish;
  warehouse: GetWarehouseResponse | Nullish;
  authorizationProfiles: BaseAuthorizationProfile[] | Nullish;
  branches: GetBranchListApiResponse | Nullish;
  warehousePricing: GetCodeListResponse | Nullish;
  currencies: GetCurrenciesApiResponse | Nullish;
  warehouseAccounts: GetWarehouseAccountsResponse | Nullish;
  salesPriceCalculationOptions: GetCodeListOptionsResponse | Nullish;
  baseSourcePriceOptions: GetCodeListOptionsResponse | Nullish;
  series: GetSeriesListApiResponse | Nullish;
  creditNoteTemplates: GetTemplatesApiResponse | Nullish;
  receiveNoteTemplates: GetTemplatesApiResponse | Nullish;
  issueNoteTemplates: GetTemplatesApiResponse | Nullish;
  issueNoteCorrectionTemplates: GetTemplatesApiResponse | Nullish;
  serviceOrderVariants: BaseServiceOrderVariant[] | Nullish;
  directSaleVariants: GetDirectSaleVariantsResponse | Nullish;
  suppliers: BaseSupplier[] | Nullish;
}

export function WarehouseDetailForm(props: WarehouseDetailFormProps) {
  const navigate = useNavigate();

  const {duplicateError: isDuplicate, duplicateErrorHandler} = useDuplicateErrorHandler();

  const [selectedAuthorizationProfile, setSelectedAuthorizationProfile] = useState<
    BaseAuthorizationProfile | Nullish
  >(null);

  const [postWarehouse, {isLoading: isCreatingWarehouse}] = usePostWarehouseMutation();

  const [patchWarehouse, {isLoading: isPatchingWarehouse}] = usePatchWarehouseMutation();

  const [getAuthorizationProfile, {isFetching: isAuthorizationProfileFetching}] =
    useLazyGetAuthorizationProfileQuery();

  useEffect(() => {
    if (isNil(props.warehouse?.authorizationProfileId) || isNil(props.tenant?.id)) {
      return;
    }

    getAuthorizationProfile({
      authorizationProfileId: props.warehouse.authorizationProfileId,
      'x-tenant': props.tenant.id,
    })
      .unwrap()
      .then(setSelectedAuthorizationProfile)
      .catch(handleApiError);
  }, [props.warehouse?.authorizationProfileId, props.tenant?.id, getAuthorizationProfile]);

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesList);
  };
  const handleSubmit: FormSubmitHandler<WarehouseSettingsForm> = async (data) => {
    const isAllSuppliersEnabled = isTrue(data.allSuppliers);
    const salesPriceCalculation = data.salesPriceCalculation;

    const body = {
      ...data,
      warehousePricing: head(data.warehousePricing) as WarehousePricingEnum,
      allowedOrderVariants:
        isTrue(data.allowServiceOrderIssue) && isNilOrEmpty(data.allowedOrderVariants)
          ? []
          : data.allowedOrderVariants,
      suppliers: isAllSuppliersEnabled
        ? []
        : data.suppliers?.filter((supplier) => isNotNilOrEmpty(supplier.id)),
    };

    // We need to clear previously set sale price values and send just the one of selected type of price
    const requestBody = match(salesPriceCalculation)
      .with(
        'MARGIN_ON_BASE',
        always({
          ...body,
          salePriceMarkupPercent: null,
          salePriceProfitWithoutVat: null,
        })
      )
      .with(
        'MARKUP_ON_BASE',
        always({
          ...body,
          salePriceMarginPercent: null,
          salePriceProfitWithoutVat: null,
        })
      )
      .with(
        'PROFIT_ON_BASE',
        always({...body, salePriceMarginPercent: null, salePriceMarkupPercent: null})
      )
      .otherwise(
        always({
          ...body,
          salePriceMarginPercent: null,
          salePriceMarkupPercent: null,
          salePriceProfitWithoutVat: null,
        })
      );

    const mutation = props.warehouse?.id
      ? patchWarehouse({body: requestBody, warehouseId: props.warehouse.id})
      : postWarehouse(requestBody);

    await mutation
      .unwrap()
      // We need to explicitely set the success message, otherwise it's not testable in jest
      .then(() => showNotification.success(i18n.t('general.notifications.success')))
      .then(handleNavigateBack)
      .catch(duplicateErrorHandler);
  };

  const defaultValues: Partial<WarehouseSettingsForm> = {
    name: props.warehouse?.name,
    warehouseNumber: props.warehouse?.warehouseNumber,
    branchId: defaultTo(props.warehouse?.branchId, selectedAuthorizationProfile?.branchId),
    companyBillingInfoId: props.warehouse?.companyBillingInfoId,
    authorizationProfileId: props.warehouse?.authorizationProfileId,
    supportedMake: props.warehouse?.supportedMake,
    warehousePricing: [
      defaultTo(
        props.warehousePricing?.[0].code,
        props.warehouse?.warehousePricing
      ) as WarehousePricingEnum,
    ],
    currency: defaultTo(props.currencies?.[0].code, props.warehouse?.currency),
    commercialOrderMarginMinimum: props.warehouse?.commercialOrderMarginMinimum,
    defaultAccountId: props.warehouse?.defaultAccountId,
    receiveNoteSeriesId: props.warehouse?.receiveNoteSeriesId,
    receiveNoteTemplateId: props.warehouse?.receiveNoteTemplateId,
    issueNoteSeriesId: props.warehouse?.issueNoteSeriesId,
    issueNoteTemplateId: props.warehouse?.issueNoteTemplateId,
    salesPriceCalculation: props.warehouse?.salesPriceCalculation,
    basePriceSource: props.warehouse?.basePriceSource,
    salePriceProfitWithoutVat: props.warehouse?.salePriceProfitWithoutVat,
    salePriceMarginPercent: props.warehouse?.salePriceMarginPercent,
    salePriceMarkupPercent: props.warehouse?.salePriceMarkupPercent,
    blockPricingSettingOnArticle: defaultTo(false, props.warehouse?.blockPricingSettingOnArticle),
    unitSalesPriceWithVat: defaultTo(false, props.warehouse?.unitSalesPriceWithVat),
    allowServiceOrderIssue: defaultTo(true, props.warehouse?.allowServiceOrderIssue),
    allowedOrderVariants: props.warehouse?.allowedOrderVariants,
    allowDirectSaleIssue: defaultTo(true, props.warehouse?.allowDirectSaleIssue),
    allowedDirectSaleVariants: props.warehouse?.allowedDirectSaleVariants,
    defaultSupplierId: defaultTo(
      props.warehouse?.defaultSupplierId,
      props.warehouse?.suppliers?.[0]?.id
    ),
    suppliers: props.warehouse?.suppliers,
    dispatchToMinus: defaultTo(false, props.warehouse?.dispatchToMinus),
    dummyWarehouse: defaultTo(false, props.warehouse?.dummyWarehouse),
    allSuppliers: defaultTo(false, props.warehouse?.allSuppliers),
    creditNoteSeriesId: props.warehouse?.creditNoteSeriesId,
    creditNoteTemplateId: props.warehouse?.creditNoteTemplateId,
    issueNoteCorrectionSeriesId: props.warehouse?.issueNoteCorrectionSeriesId,
    issueNoteCorrectionTemplateId: props.warehouse?.issueNoteCorrectionTemplateId,
  };

  return (
    <Form<WarehouseSettingsForm>
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      schema={warehouseFormSchema}
    >
      {(control, formApi) => {
        const handleAuthorizationProfileChange = (
          newAuthorizationProfileId: string | number | null
        ) => {
          if (isNil(newAuthorizationProfileId)) {
            return;
          }

          getAuthorizationProfile({
            authorizationProfileId: newAuthorizationProfileId as string,
            'x-tenant': props.tenant?.id as string,
          })
            .unwrap()
            .then((authorizationProfile) => {
              formApi.setValue('branchId', authorizationProfile?.branchId, {shouldValidate: true});
            });
        };

        return (
          <VStack spacing={4} data-testid={props['data-testid']}>
            <Show when={isDuplicate}>
              <Alert
                variant="error"
                title={i18n.t('entity.warehouse.labels.warehouseAlreadyExists')}
                data-testid={testIds.settings.warehousesDetail('duplicityAlert')}
              />
            </Show>

            <GeneralForm
              control={control}
              branches={props.branches}
              tenant={props.tenant}
              authorizationProfiles={props.authorizationProfiles}
              onAuthorizationProfileChange={handleAuthorizationProfileChange}
              isAuthorizationProfileFetching={isAuthorizationProfileFetching}
              data-testid={suffixTestId('general', props)}
            />

            <Grid columns={2}>
              <Separator />
            </Grid>

            <AccountingForm
              control={control}
              warehousePricing={props.warehousePricing}
              currencies={props.currencies}
              warehouseAccounts={props.warehouseAccounts}
              data-testid={suffixTestId('accounting', props)}
            />

            <Grid columns={2}>
              <Separator />
            </Grid>

            <PricingForm
              control={control}
              formApi={formApi}
              salesPriceCalculationOptions={props.salesPriceCalculationOptions}
              baseSourcePriceOptions={props.baseSourcePriceOptions}
              data-testid={suffixTestId('pricing', props)}
            />

            <Grid columns={2}>
              <Separator />
            </Grid>

            <DocumentsForm
              control={control}
              series={props.series}
              receiveNoteTemplates={props.receiveNoteTemplates}
              creditNoteTemplates={props.creditNoteTemplates}
              issueNoteTemplates={props.issueNoteTemplates}
              issueNoteCorrectionTemplates={props.issueNoteCorrectionTemplates}
              serviceOrderVariants={props.serviceOrderVariants}
              directSaleVariants={props.directSaleVariants}
              data-testid={suffixTestId('documents', props)}
            />

            <Grid columns={2}>
              <Separator />
            </Grid>

            <SuppliersForm
              control={control}
              formApi={formApi}
              suppliers={props.suppliers}
              data-testid={suffixTestId('suppliers', props)}
            />

            <Space vertical={18} />

            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  variant: 'secondary',
                  title: isNil(props.warehouse?.id)
                    ? i18n.t('general.actions.discard')
                    : i18n.t('general.actions.discardChanges'),
                  onClick: handleNavigateBack,
                  isLoading: isCreatingWarehouse || isPatchingWarehouse,
                  'data-testid': suffixTestId('actions.discard', props),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  variant: 'primary',
                  title: isNil(props.warehouse?.id)
                    ? i18n.t('general.actions.save')
                    : i18n.t('general.actions.saveChanges'),
                  isLoading: isCreatingWarehouse || isPatchingWarehouse,
                  'data-testid': suffixTestId('actions.submit', props),
                },
              ]}
            />
          </VStack>
        );
      }}
    </Form>
  );
}

const supplierSchema = object({
  id: yupString,
});

const atLeastOneSupplierRequired = array()
  .of(supplierSchema)
  .test({
    name: 'atLeastOneSupplierRequired',
    message: i18n.t('entity.warehouse.validations.atLeastOneSupplierMustBeSelected'),
    test: (suppliers, schema) => {
      // when all suppliers are enabled, we don't want to validate
      if (isTrue(schema.parent.allSuppliers)) {
        return true;
      }

      return suppliers!.some((supplier) => isNotNilOrEmpty(supplier.id));
    },
  });

const requiredIfEnabled = yupString.when('allSuppliers', {
  is: true,
  then: yupString.required(),
  otherwise: yupString.optional(),
});

const warehouseFormSchema = object({
  name: yupString.required(),
  warehouseNumber: yupString.max(50),
  branchId: yupString.required(),
  companyBillingInfoId: yupString.required(),
  authorizationProfileId: yupString.required(),
  currency: yupString.required(),
  warehousePricing: array()
    .of(yupString.oneOf(['AVG', 'FIFO']))
    .min(1)
    .max(1)
    .required(),
  salesPriceCalculation: yupString
    .oneOf([
      'MARGIN_ON_BASE',
      'MARKUP_ON_BASE',
      'PROFIT_ON_BASE',
      'MANUAL',
      'RECOMMENDED_PRICE',
      'MARKETING_CODE',
    ])
    .required(),
  basePriceSource: yupString
    .oneOf(['AVG_PURCHASE', 'LAST_PURCHASE', 'RECOMMENDED', 'MANUAL'])
    .required(),
  receiveNoteSeriesId: yupString.required(),
  receiveNoteTemplateId: yupString.required(),
  creditNoteSeriesId: yupString.required(),
  creditNoteTemplateId: yupString.required(),
  issueNoteSeriesId: yupString.required(),
  issueNoteTemplateId: yupString.required(),
  issueNoteCorrectionSeriesId: yupString.required(),
  issueNoteCorrectionTemplateId: yupString.required(),
  allowServiceOrderIssue: boolean().default(true),
  allowedServiceOrderVariants: array(yupString),
  allowDirectSaleIssue: boolean().default(true),
  allowedDirectSaleVariants: array(yupString),
  defaultAccountId: yupString.required(),
  unitSalesPriceWithVat: boolean().default(false),
  salePriceProfitWithoutVat: yupNumber,
  salePriceMarginPercent: yupNumber,
  salePriceMarkupPercent: yupNumber,
  blockPricingSettingOnArticle: boolean(),
  suppliers: atLeastOneSupplierRequired,
  dispatchToMinus: boolean().default(false),
  dummyWarehouse: boolean().default(false),
  allSuppliers: boolean().default(false),
  defaultSupplierId: requiredIfEnabled,
});
