import {FC, PropsWithChildren} from 'react';

import {VehicleSeriesDefinitionContextType} from '../types';
import {VehicleSeriesDefinitionFormContext} from './FormContext';

export const VehicleSeriesDefinitionContextProvider: FC<
  PropsWithChildren<VehicleSeriesDefinitionContextType>
> = ({children, ...rest}) => (
  <VehicleSeriesDefinitionFormContext.Provider value={rest}>
    {children}
  </VehicleSeriesDefinitionFormContext.Provider>
);
