import {Box} from 'platform/foundation';

import {levelColors} from './levelColors';

interface LevelProps {
  value?: number;
}

export function Level(props: LevelProps) {
  const bgColor = levelColors[props.value ?? 0];

  return <Box width={1} height={4} borderRadius="xSmall" backgroundColor={bgColor} />;
}
