import {DeepPartial} from 'utility-types';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Money} from '@omnetic-dms/api';

export const isValidPhone = (value: unknown): boolean => {
  if (!value) {
    return false;
  }
  return (
    Object.prototype.hasOwnProperty.call(value, 'number') &&
    Object.prototype.hasOwnProperty.call(value, 'countryCode') &&
    Object.prototype.hasOwnProperty.call(value, 'prefix') &&
    (value as any)?.number?.length !== 0
  );
};

export const isMoney = (value: unknown): value is Partial<Money> => {
  if (!value) {
    return false;
  }
  return (
    Object.prototype.hasOwnProperty.call(value, 'currency') ||
    Object.prototype.hasOwnProperty.call(value, 'amount')
  );
};

const filterEmptyObject = (ob: Record<string, unknown>): boolean =>
  !Object.keys(ob).every((k) => {
    // If there's a phone number with only default country selected, omit it
    if (k === 'phoneNumber' && !isValidPhone(ob[k])) {
      return true;
    }
    if (typeof ob[k] === 'object' && ob[k] !== undefined && ob[k] !== null) {
      const res = filterEmptyObject(ob[k] as Record<string, unknown>);
      return !res;
    }
    return !ob[k];
  });

const cleanEmpty = (obj: unknown): unknown => {
  if (Array.isArray(obj)) {
    const array = obj.filter(isNotNilOrEmpty);
    return array.filter(filterEmptyObject);
  } else if (typeof obj === 'object' && obj !== undefined && obj !== null) {
    return Object.entries(obj)
      ?.map(([k, v]) => [
        k,
        v && typeof v === 'object'
          ? isMoney(v)
            ? // Money can only exist with both amount can currency but the currency has default value
              v.amount && v.currency
              ? v
              : null
            : k === 'phoneNumber'
              ? isValidPhone(v)
                ? v
                : null
              : v instanceof Date
                ? v
                : cleanEmpty(v as any)
          : v,
      ])
      .reduce((a, [k, v]) => ((((a as any)[k as any] as any) = v), a), {});
  }

  return;
};

export const removeEmptyItems = <T extends Record<string, unknown> | Array<unknown>>(
  values: T
): DeepPartial<T> => cleanEmpty(values) as DeepPartial<T>;
