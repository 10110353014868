import {ReactNode} from 'react';

import {MenuGroup} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

export interface DropdownGroupProps extends TestIdProps {
  label?: string;
  children: ReactNode;
}

export function DropdownGroup(props: DropdownGroupProps) {
  return (
    <MenuGroup title={props.label} data-testid={suffixTestId('dropdownGroup', props)}>
      {props.children}
    </MenuGroup>
  );
}
