import {
  Button,
  ButtonGroup,
  FormButton,
  FormControl,
  openConfirmDialog,
  showNotification,
} from 'platform/components';
import {HStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';
import {useDispatch} from 'react-redux';

import {isNil, not, reject} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {
  documentContextApi,
  GetTireSetApiResponse,
  usePostTiresArchiveMutation,
  usePostTiresIssueMutation,
  usePostTiresReceiptMutation,
  usePostTiresRestoreMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {TireSetFormType} from '../../../types/tires/TireSetFormType';
import {handleApiError} from '../../../utils/handleApiError';
import {printFile} from '../../../utils/printFile';

interface EditTireSetFooterButtonsProps extends TestIdProps {
  tireOrderId: string;
  setId: string;
  control: FormControl<TireSetFormType>;
  formApi: UseFormReturn<TireSetFormType>;
  onClose: () => void;
  onEdit: () => void;
  saveTires: (data: TireSetFormType) => Promise<void>;
  tireSet: GetTireSetApiResponse;
}

export function EditTireSetFooterButtons(props: EditTireSetFooterButtonsProps) {
  const dispatch = useDispatch();

  const [postTiresArchive] = usePostTiresArchiveMutation();
  const [postTiresReceipt, {isLoading: isTiresReceiptLoading}] = usePostTiresReceiptMutation();
  const [postTiresIssue, {isLoading: isTiresIssueLoading}] = usePostTiresIssueMutation();
  const [postTiresRestore] = usePostTiresRestoreMutation();

  const refreshDocumentCount = () =>
    dispatch(
      documentContextApi.util.invalidateTags([{type: 'documentsCount', id: props.tireOrderId}])
    );

  const selectedWheelIds = reject(
    isNil,
    props.formApi
      .getValues('wheels')
      .filter((wheel) => isTrue(wheel.checked))
      .map((wheel) => wheel.id)
  );

  const receiptOfTireSet = async () => {
    const assignMechanic = props.formApi.getValues('assignMechanic');

    await props.saveTires(props.formApi.getValues());

    postTiresReceipt({
      orderId: props.tireOrderId,
      setId: props.setId,
      body: {assignMechanic, wheels: selectedWheelIds.map((id) => ({id}))},
    })
      .unwrap()
      .then((response) => {
        props.onEdit();
        props.onClose();
        showNotification.success();
        printFile(response?.printout?.pdfUrl ?? '');
      })
      .then(refreshDocumentCount)
      .catch(handleApiError);
  };

  const issueOfTireSet = async () => {
    const assignMechanic = props.formApi.getValues('assignMechanic');

    await props.saveTires(props.formApi.getValues());

    postTiresIssue({
      orderId: props.tireOrderId,
      setId: props.setId,
      body: {assignMechanic, wheels: selectedWheelIds.map((id) => ({id}))},
    })
      .unwrap()
      .then((response) => {
        props.onEdit();
        props.onClose();
        showNotification.success();
        printFile(response?.printout?.pdfUrl ?? '');
      })
      .then(refreshDocumentCount)
      .catch(handleApiError);
  };

  const archive = async () => {
    const assignMechanic = props.formApi.getValues('assignMechanic');

    await props.saveTires(props.formApi.getValues());

    openConfirmDialog({
      text: i18n.t('page.tiresInventory.labels.archiveTire'),
      onConfirm: () =>
        postTiresArchive({
          orderId: props.tireOrderId,
          setId: props.setId,
          body: {assignMechanic, wheels: selectedWheelIds.map((id) => ({id}))},
        })
          .unwrap()
          .then(() => {
            props.onClose();
            props.onEdit();
            showNotification.success();
          })
          .then(refreshDocumentCount)
          .catch(handleApiError),
      'data-testid': suffixTestId('archive', props),
    });
  };

  const restore = () => {
    const assignMechanic = props.formApi.getValues('assignMechanic');

    openConfirmDialog({
      text: i18n.t('page.tiresInventory.labels.restoreTire'),
      onConfirm: () =>
        postTiresRestore({
          orderId: props.tireOrderId,
          setId: props.setId,
          body: {assignMechanic, wheels: selectedWheelIds.map((id) => ({id}))},
        })
          .unwrap()
          .then(() => {
            props.onClose();
            props.onEdit();
            showNotification.success();
          })
          .then(refreshDocumentCount)
          .catch(handleApiError),
    });
  };

  const hasDifferentWheelState = (state: string) => {
    const wheelIds = props.formApi
      .getValues('wheels')
      .filter((wheel) => isTrue(wheel.checked))
      .map(({id}) => id);

    const selectedWheels = props.tireSet?.wheels?.filter(
      (wheel) => !!wheel?.id && wheelIds.includes(wheel.id)
    );

    return not(selectedWheels?.every((wheel) => wheel?.state === state));
  };

  const isEveryWheelState = (state: string) =>
    props.tireSet?.wheels?.every((wheel) => wheel?.state === state);

  const isReceiptPrimary = isEveryWheelState('PICKED');
  const isIssuePrimary = isEveryWheelState('INSTOCK');
  const isRestorePrimary = isEveryWheelState('ARCHIVED');

  return (
    <HStack justify="space-between">
      <Button
        leftIcon="action/delete"
        title={i18n.t('general.actions.discard')}
        variant="dangerLink"
        onClick={props.onClose}
        data-testid={suffixTestId('discard', props)}
      />
      <ButtonGroup>
        <Button
          title={i18n.t('entity.tireSet.actions.receiptOfTireSet')}
          variant={isReceiptPrimary ? 'primary' : 'secondary'}
          onClick={receiptOfTireSet}
          isDisabled={hasDifferentWheelState('PICKED')}
          isLoading={isTiresReceiptLoading}
          data-testid={suffixTestId('receiptOfTireSet', props)}
        />
        <Button
          title={i18n.t('entity.tireSet.actions.issueOfTireSet')}
          variant={isIssuePrimary ? 'primary' : 'secondary'}
          onClick={issueOfTireSet}
          isDisabled={hasDifferentWheelState('INSTOCK')}
          isLoading={isTiresIssueLoading}
          data-testid={suffixTestId('issueOfTireSet', props)}
        />
        <Button
          title={i18n.t('general.actions.archive')}
          variant="secondary"
          onClick={archive}
          isDisabled={not(hasDifferentWheelState('ARCHIVED'))}
          data-testid={suffixTestId('archive', props)}
        />
        <Button
          title={i18n.t('entity.tireSet.actions.restore')}
          variant={isRestorePrimary ? 'primary' : 'secondary'}
          onClick={restore}
          isDisabled={hasDifferentWheelState('ARCHIVED')}
          data-testid={suffixTestId('restore', props)}
        />
        <FormButton
          control={props.control}
          title={i18n.t('general.actions.saveAndClose')}
          variant={isRestorePrimary || isIssuePrimary || isReceiptPrimary ? 'secondary' : 'primary'}
          type="submit"
          isDisabled={not(hasDifferentWheelState('ARCHIVED'))}
          data-testid={suffixTestId('stockingTires', props)}
        />
      </ButtonGroup>
    </HStack>
  );
}
